import axios from "axios";
import {
  ADD_PRODUCTS_LIST,
  DELETE_PRODUCTS_LIST,
  DETAILS_PRODUCTS_LIST,
  EDIT_PRODUCTS_LIST,
  MODULE_ACCESS_LIST,
  MODULE_LIST,
  ORG_MENU_PAGE_LIST_ENABLE_DESABLE,
  ORG_PAGE_LIST_ENABLE_DESABLE,
  PRODUCTS_LIST,
} from "../../../constant/API constant";

export const ProductsList = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${PRODUCTS_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

// export const ModuleList = (paramsObj) => {
//   const jwt_token = localStorage.getItem("token");
//   return axios.get(`${process.env.REACT_APP_API_URL}${MODULE_LIST}`, {
//     headers: { Authorization: jwt_token },
//     params: paramsObj,
//   });
// };

export const ModuleAccessList = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${MODULE_ACCESS_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const Add_Products_List = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${ADD_PRODUCTS_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Edit_Products_List = (formData, id) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${EDIT_PRODUCTS_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { menuProductId: id },
    }
  );
};

export const Delete_Products_List = (menuProductId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(
    `${process.env.REACT_APP_API_URL}${DELETE_PRODUCTS_LIST}`,
    {
      headers: { Authorization: jwt_token },
      params: { menuProductId },
    }
  );
};

export const Ditails_Products_List = (id) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${DETAILS_PRODUCTS_LIST}`, {
    headers: { Authorization: jwt_token },
    params: { menuProductId: id },
  });
};

export const MenuPageList_Enable_Desable = (formData, menuProductId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${ORG_MENU_PAGE_LIST_ENABLE_DESABLE}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { menuProductId },
    }
  );
};
