import * as React from "react";
import { useState } from "react";
import AdminDeshboardContext from "./AdminDeshboardContext";

const AdminDeshboardState = (props) => {
  const [val, setVal] = useState("");

  return (
    <>
      <AdminDeshboardContext.Provider value={{ val, setVal }}>
        {props.children}
      </AdminDeshboardContext.Provider>
    </>
  );
};

export default AdminDeshboardState;
