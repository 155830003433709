import { Switch } from "@material-ui/core";
import React from "react";
import { Col, Container, Input, Label, Row } from "reactstrap";

const CardThemeSelectPopup = ({ themeSelect, setThemeSelect }) => {
  const handleSelectTheme = (e) => {
    const { name, value, checked } = e.target;
    if (name === "isSlider") {
      setThemeSelect((prev) => {
        return {
          ...prev,
          [name]: checked,
        };
      });
    } else {
      setThemeSelect((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    }
  };

  return (
    <Container>
      <Row className="justify-content-center mb-4">
        <Col
          md={"3 mb-3"}
          className=" d-flex flex-column align-items-center   justify-content-center "
        >
          <Label>Theme Color</Label>
          <Input
            className="form-control-color"
            type="color"
            name="themeColor"
            value={themeSelect.themeColor}
            onChange={(e) => handleSelectTheme(e)}
          />
        </Col>

        <Col
          md={"3 mb-3"}
          className="d-flex flex-column  align-items-center  justify-content-center "
        >
          <Label>Font Color</Label>
          <Input
            className="form-control-color"
            type="color"
            name="fontColor"
            value={themeSelect.fontColor}
            onChange={(e) => handleSelectTheme(e)}
          />
        </Col>
        {/* <Col
          md={"3 mb-3"}
          className="d-flex flex-column  align-items-center  justify-content-center "
        >
          <Label>Card View  / Slider View</Label>
          <Switch
            checked={themeSelect.isSlider}
            inputProps={{
              "aria-label": "controlled",
            }}
            name="isSlider"
            color="primary"
            onChange={(e) => handleSelectTheme(e)}
          />
        </Col> */}
      </Row>

      <Row className="row-items">
        <Col md="6" className="md-responsive">
          <div className="flip-wrapper">
            <div className="flip-card">
              <div className="flip-card-inner position-relative">
                <div
                  className="flip-card-front d-sm-flex align-items-center overflow-hidden "
                  style={{
                    background: themeSelect.themeColor,
                  }}
                >
                  <div className="flip-logo h-100 d-flex align-items-center position-relative">
                    <img
                      src={require("./Your-Logo.png")}
                      alt=""
                      className="mx-auto position-relative"
                    />
                  </div>
                  <div className="flip-dtl position-relative pe-5">
                    <ul>
                      <li>
                        <i
                          className="fa-solid fa-user"
                          style={{
                            color: themeSelect.themeColor,
                            borderColor: themeSelect.themeColor,
                          }}
                        ></i>
                        <div>
                          <h4
                            style={{
                              color: themeSelect.fontColor,
                            }}
                          >
                            Your name
                          </h4>
                          <h5
                            style={{
                              color: themeSelect.fontColor,
                            }}
                          >
                            designation
                          </h5>
                        </div>
                      </li>
                      <li>
                        <i
                          className="fa-solid fa-phone"
                          style={{
                            color: themeSelect.themeColor,
                            borderColor: themeSelect.themeColor,
                          }}
                        ></i>
                        <p>
                          <a
                            href="#"
                            style={{
                              color: themeSelect.fontColor,
                            }}
                          >
                            +91 1234567890
                          </a>
                        </p>
                      </li>
                      <li>
                        <i
                          className="fa-solid fa-envelope"
                          style={{
                            color: themeSelect.themeColor,
                            borderColor: themeSelect.themeColor,
                          }}
                        ></i>
                        <p>
                          <a
                            href="#"
                            style={{
                              color: themeSelect.fontColor,
                            }}
                          >
                            demo@gmail.com
                          </a>
                        </p>
                      </li>
                      <li>
                        <i
                          className="fa-solid fa-globe"
                          style={{
                            color: themeSelect.themeColor,
                            borderColor: themeSelect.themeColor,
                          }}
                        ></i>
                        <p>
                          <a
                            href="#"
                            style={{
                              color: themeSelect.fontColor,
                            }}
                          >
                            www.your-website.com
                          </a>
                        </p>
                      </li>
                      <li>
                        <i
                          className="fa-solid fa-location-dot"
                          style={{
                            color: themeSelect.themeColor,
                            borderColor: themeSelect.themeColor,
                          }}
                        ></i>
                        <p
                          style={{
                            color: themeSelect.fontColor,
                          }}
                        >
                          Your address
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col md="6" className="md-responsive">
          <div className="flip-wrapper">
            <div className="flip-card">
              <div className="flip-card-inner position-relative">
                <div className="flip-card-back d-flex flex-column justify-content-between overflow-hidden">
                  <h4
                    className="back-header d-table mx-auto"
                    style={{
                      background: themeSelect.themeColor,
                      color: themeSelect.fontColor,
                    }}
                  >
                    Our Services
                  </h4>
                  <div className="back-body h-100">
                    <ul className="list">
                      <li className="d-flex align-items-center ">
                        <div
                          className="card-theme-dot"
                          style={{
                            background: themeSelect.themeColor,
                            marginRight: "12px",
                            width: "10px",
                            height: "10px",
                          }}
                        ></div>{" "}
                        <span>your service 1</span>
                      </li>
                      <li className="d-flex align-items-center ">
                        <div
                          className="card-theme-dot"
                          style={{
                            background: themeSelect.themeColor,
                            marginRight: "12px",
                            width: "10px",
                            height: "10px",
                          }}
                        ></div>{" "}
                        <span>your service 2</span>
                      </li>
                      <li className="d-flex align-items-center ">
                        <div
                          className="card-theme-dot"
                          style={{
                            background: themeSelect.themeColor,
                            marginRight: "12px",
                            width: "10px",
                            height: "10px",
                          }}
                        ></div>{" "}
                        <span>your service 3</span>
                      </li>
                      <li className="d-flex align-items-center ">
                        <div
                          className="card-theme-dot"
                          style={{
                            background: themeSelect.themeColor,
                            marginRight: "12px",
                            width: "10px",
                            height: "10px",
                          }}
                        ></div>{" "}
                        <span>your service 4</span>
                      </li>
                      <li className="d-flex align-items-center ">
                        <div
                          className="card-theme-dot"
                          style={{
                            background: themeSelect.themeColor,
                            marginRight: "12px",
                            width: "10px",
                            height: "10px",
                          }}
                        ></div>{" "}
                        <span>your service 5</span>
                      </li>
                      <li className="d-flex align-items-center ">
                        <div
                          className="card-theme-dot"
                          style={{
                            background: themeSelect.themeColor,
                            marginRight: "12px",
                            width: "10px",
                            height: "10px",
                          }}
                        ></div>{" "}
                        <span>your service 6</span>
                      </li>
                    </ul>
                  </div>
                  <p
                    className="back-footer text-center"
                    style={{
                      background: themeSelect.themeColor,
                      color: themeSelect.fontColor,
                    }}
                  >
                    Your tagline
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CardThemeSelectPopup;
