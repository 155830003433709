import React, { useContext } from "react";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import {
  Border,
  TestimonialThemeThreeSet,
  fontWeight,
} from "../../../../constant";
import TestimonialMultipageContext from "../../../context/TestimonialMultipageContext/TestimonialMultipageContext";
import { Close } from "@material-ui/icons";
import { UploadCloud } from "react-feather";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import { Cases, DELETE_CONFIRM } from "../../../constant";
import { Zoom, toast } from "react-toastify";
import { delete_Multi_Image_Permanent } from "../apis/SliderApi/SliderApi";
import { Switch } from "@material-ui/core";
import Dividers from "../../../common/divider";
import { OpenModalCommonFunc } from "../../../utils";
import { IconButton } from "@material-ui/core";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import PaginationButtonStyle from "../../../common/paginationButtonStyle";
import CommonColor from "../../../common/CommonColor";
import CustomStyle from "../../../common/CustomStyle";

const TestimonialTheme2 = (props) => {
  const {
    isEdit,
    settingFormData,
    setSettingFormData,
    backImageToggle,
    setBackImageToggle,
    boxSection,
    setBoxSection,
    isBackgroundColor,
    setIsBackgroundColor,
    setImageIs,
  } = useContext(TestimonialMultipageContext);
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const { imageUpload, handleSubmitSetting } = props;
  const { setLoaderShow } = useContext(LoaderContext);
  /**
   * input value change
   * @param {event} e
   */

  const handleChangeTestimonialSection = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".zig-zag-section": {
          ...prev[".zig-zag-section"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeTestimonialSectionDiv = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".zig-zag-section .testimonial-divs .testimonial": {
          ...prev[".zig-zag-section .testimonial-divs .testimonial"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeTestimonialSectionImage = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".zig-zag-section .testimonial-divs .avatar-img": {
          ...prev[".zig-zag-section .testimonial-divs .avatar-img"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeTestimonialSectionTitleAreaTitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".zig-zag-section .testimonial-divs .client-name": {
          ...prev[".zig-zag-section .testimonial-divs .client-name"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeTestimonialSectionDescription = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".zig-zag-section .testimonial-divs .desq": {
          ...prev[".zig-zag-section .testimonial-divs .desq"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeTestimonialSectionTitleAreaPosition = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".zig-zag-section .testimonial-divs .client-position": {
          ...prev[".zig-zag-section .testimonial-divs .client-position"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeTestimonialSliderRating = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".zig-zag-section .star-rating i": {
          ...prev[".zig-zag-section .star-rating i"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeCustomStyle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const resetHandler = () => {
    setSettingFormData({
      ...TestimonialThemeThreeSet,
      testimonialId: "",
    });
    setBoxSection(false);
    setBackImageToggle(false);
    setIsBackgroundColor(true);
  };

  /**
   * delete image permanently
   */
  const deleteImagePermanent = async () => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData = {
          fileName: settingFormData?.[".zig-zag-section.bg-image"]?.[
            "background-image"
          ]
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
            .pop(),
        };
        const { data } = await delete_Multi_Image_Permanent(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            position: "top-center",
          });
          setSettingFormData({
            ...settingFormData,
            ".zig-zag-section.bg-image": { "background-image": "" },
          });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };

  /**
   * image reupload
   */
  const reupload = () => {
    setSettingFormData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };
  return (
    <>
      <Row className="mt-4">
        <h4 className="mb-3">Make change in main testimonial section</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (default : 100)"}
          </Label>
          <Input
            className="form-control"
            name="padding"
            type="number"
            placeholder="padding"
            value={settingFormData?.[".zig-zag-section"]?.["padding"]
              ?.split(" ")[0]
              ?.replace(/px/g, "")}
            onChange={(e) => handleChangeTestimonialSection(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>

        <Col md="4 mb-3">
          <div className="d-flex align-items-center">
            <Label htmlFor="validationCustom03">
              {isBackgroundColor
                ? "Add Background Color"
                : "Remove Background Color"}
            </Label>
            <Switch
              checked={isBackgroundColor}
              inputProps={{ "aria-label": "controlled" }}
              onChange={(e) => {
                setIsBackgroundColor(e.target.checked);
                setSettingFormData((prev) => {
                  if (e.target.checked) {
                    if (boxSection) {
                      return {
                        ...prev,
                        ".zig-zag-section": {
                          "background-color": "#f9f9f9",
                          padding:
                            settingFormData?.[".zig-zag-section"]?.["padding"],
                          width: settingFormData?.[".zig-zag-section"]?.[
                            "width"
                          ]?.replace("rem)", ""),
                          margin: settingFormData?.[".zig-zag-section"]?.[
                            "margin"
                          ]
                            ?.split(" ")[0]
                            ?.replace(/px/g, ""),
                          "border-radius": settingFormData?.[
                            ".zig-zag-section"
                          ]?.["border-radius"]?.replace(/rem/g, ""),
                        },
                      };
                    } else {
                      return {
                        ...prev,
                        ".zig-zag-section": {
                          "background-color": "#fbfcfd",
                          padding:
                            settingFormData?.[".zig-zag-section"]?.["padding"],
                        },
                      };
                    }
                  } else {
                    if (boxSection) {
                      return {
                        ...prev,
                        ".zig-zag-section": {
                          padding:
                            settingFormData?.[".zig-zag-section"]?.["padding"],
                          width: settingFormData?.[".zig-zag-section"]?.[
                            "width"
                          ]?.replace("rem)", ""),
                          margin: settingFormData?.[".zig-zag-section"]?.[
                            "margin"
                          ]
                            ?.split(" ")[0]
                            ?.replace(/px/g, ""),
                          "border-radius": settingFormData?.[
                            ".zig-zag-section"
                          ]?.["border-radius"]?.replace(/rem/g, ""),
                        },
                      };
                    } else {
                      return {
                        ...prev,
                        ".zig-zag-section": {
                          padding:
                            settingFormData?.[".zig-zag-section"]?.["padding"],
                        },
                      };
                    }
                  }
                });
              }}
            />
          </div>
          {isBackgroundColor && (
            <CommonColor
              color={
                settingFormData?.[".zig-zag-section"]?.["background-color"]
              }
              setSettingFormData={setSettingFormData}
              className={".zig-zag-section"}
              classProperty={"background-color"}
              label={"Select Background Color"}
            />
          )}
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <Col md="12 mb-3">
          <h4 className="mb-3">Add Background Image in section</h4>

          <p>do you want to upload Background image</p>
          <Switch
            checked={backImageToggle}
            inputProps={{ "aria-label": "controlled" }}
            onChange={(e) => {
              setBackImageToggle(e.target.checked);
              setSettingFormData((prev) => {
                if (e.target.checked) {
                  return {
                    ...prev,
                    image: "",
                    ".zig-zag-section.bg-image": {},
                    ".zig-zag-section.bg-image::before": {
                      "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
                    },
                  };
                } else {
                  return {
                    ...prev,
                    image: "",
                    ".zig-zag-section.bg-image": {},
                    ".zig-zag-section.bg-image::before": {},
                  };
                }
              });
            }}
          />
          {backImageToggle && (
            <>
              {" "}
              <Col md="6 mb-3">
                <Dividers divide="IMAGE" />
                <p className="mb-4">
                  File type required JPG, PNG (1905px X 744px)
                </p>
              </Col>
              <Row>
                <Col md="4 mb-3">
                  <div
                    className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    {!settingFormData?.[".zig-zag-section.bg-image"]?.[
                      "background-image"
                    ] && (
                      <span
                        onClick={() => {
                          OpenModalCommonFunc(
                            Cases.PRODUCT_UPLOAD,
                            setAllPopupState
                          );
                          setImageIs("testimonialImage2");
                        }}
                      >
                        <UploadCloud />
                      </span>
                    )}

                    {settingFormData?.[".zig-zag-section.bg-image"]?.[
                      "background-image"
                    ] && (
                      <img
                        src={
                          settingFormData?.[".zig-zag-section.bg-image"]?.[
                            "background-image"
                          ] || ""
                        }
                        alt=""
                        style={{
                          width: "85%",
                          height: "90px",
                          objectFit: "contain",
                          position: "absolute",
                        }}
                        onClick={() => deleteImagePermanent()}
                      />
                    )}
                    {settingFormData?.[".zig-zag-section.bg-image"]?.[
                      "background-image"
                    ] && (
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="close"
                        style={{
                          color: "red",
                          position: "absolute",
                          top: "-22px",
                          left: "1px",
                        }}
                        onClick={() => deleteImagePermanent()}
                      >
                        <Close
                          style={{
                            fontSize: "25px",
                            fontWeight: "bold",
                          }}
                        />
                      </IconButton>
                    )}
                  </div>
                </Col>
                <Col md="6 mb-3">
                  <h4 className="mb-3">Add Background color in section</h4>
                  <Col md="6 mb-3">
                    <CommonColor
                      color={
                        settingFormData?.[
                          ".zig-zag-section.bg-image::before"
                        ]?.["background-color"]
                      }
                      setSettingFormData={setSettingFormData}
                      className={".zig-zag-section.bg-image::before"}
                      classProperty={"background-color"}
                      label={"Select Background Color"}
                    />
                  </Col>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>

      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make Change in Testimonial Div</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Padding (Default : 30)"}</Label>
          <Input
            className="form-control"
            name="padding"
            type="number"
            placeholder="padding"
            value={settingFormData?.[
              ".zig-zag-section .testimonial-divs .testimonial"
            ]?.["padding"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTestimonialSectionDiv(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border Radius (Default : 5)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[
              ".zig-zag-section .testimonial-divs .testimonial"
            ]?.["border-radius"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTestimonialSectionDiv(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Border"}</Label>
          <div className="d-flex">
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="borderPx"
                type="number"
                placeholder="px"
                value={settingFormData?.[
                  ".zig-zag-section .testimonial-divs .testimonial"
                ]?.["borderPx"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeTestimonialSectionDiv(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <select
                name="borderThik"
                id="borderThik"
                className="form-control"
                value={
                  settingFormData?.[
                    ".zig-zag-section .testimonial-divs .testimonial"
                  ]?.["borderThik"]
                }
                onChange={(e) => handleChangeTestimonialSectionDiv(e)}
                style={{ borderRadius: "0", color: "black" }}
              >
                <option value="">--select--</option>
                {Border.map((elem) => {
                  return (
                    <option value={elem.border} key={elem.border}>
                      {elem.border}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <CommonColor
                color={
                  settingFormData?.[
                    ".zig-zag-section .testimonial-divs .testimonial"
                  ]?.["borderColor"]
                }
                setSettingFormData={setSettingFormData}
                className={".zig-zag-section .testimonial-divs .testimonial"}
                classProperty={"borderColor"}
                label={"Border Color"}
              />
            </div>
          </div>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Box Shadow"}</Label>

          <div className="d-flex justify-content-between">
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="bs1"
                type="number"
                placeholder="px"
                value={settingFormData?.[
                  ".zig-zag-section .testimonial-divs .testimonial"
                ]?.["bs1"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeTestimonialSectionDiv(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="bs2"
                type="number"
                placeholder="px"
                value={settingFormData?.[
                  ".zig-zag-section .testimonial-divs .testimonial"
                ]?.["bs2"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeTestimonialSectionDiv(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="bs3"
                type="number"
                placeholder="px"
                value={settingFormData?.[
                  ".zig-zag-section .testimonial-divs .testimonial"
                ]?.["bs3"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeTestimonialSectionDiv(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div>
              <CommonColor
                color={
                  settingFormData?.[
                    ".zig-zag-section .testimonial-divs .testimonial"
                  ]?.["shadowColor"]
                }
                setSettingFormData={setSettingFormData}
                className={".zig-zag-section .testimonial-divs .testimonial"}
                classProperty={"shadowColor"}
                label={"Shadow Color"}
              />
            </div>
          </div>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".zig-zag-section .testimonial-divs .testimonial"
              ]?.["background-color"]
            }
            setSettingFormData={setSettingFormData}
            className={".zig-zag-section .testimonial-divs .testimonial"}
            classProperty={"background-color"}
            label={"Select Background"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in section Image</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border Radius (Default : 5)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[
              ".zig-zag-section .testimonial-divs .avatar-img"
            ]?.["border-radius"]?.replace(/%/g, "")}
            onChange={(e) => handleChangeTestimonialSectionImage(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Border"}</Label>
          <div className="d-flex">
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="borderPx"
                type="number"
                placeholder="px"
                value={settingFormData?.[
                  ".zig-zag-section .testimonial-divs .avatar-img"
                ]?.["borderPx"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeTestimonialSectionImage(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <select
                name="borderThik"
                id="borderThik"
                className="form-control"
                value={
                  settingFormData?.[
                    ".zig-zag-section .testimonial-divs .avatar-img"
                  ]?.["borderThik"]
                }
                onChange={(e) => handleChangeTestimonialSectionImage(e)}
                style={{ borderRadius: "0", color: "black" }}
              >
                <option value="">--select--</option>
                {Border.map((elem) => {
                  return (
                    <option value={elem.border} key={elem.border}>
                      {elem.border}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <CommonColor
                color={
                  settingFormData?.[
                    ".zig-zag-section .testimonial-divs .avatar-img"
                  ]?.["borderColor"]
                }
                setSettingFormData={setSettingFormData}
                className={".zig-zag-section .testimonial-divs .avatar-img"}
                classProperty={"borderColor"}
                label={"Border Color"}
              />
            </div>
          </div>
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in section Client Name</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 18)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".zig-zag-section .testimonial-divs .client-name"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTestimonialSectionTitleAreaTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Line Height (Default : 1.2)"}
          </Label>
          <Input
            className="form-control"
            name="line-height"
            type="number"
            placeholder="Line Height"
            value={settingFormData?.[
              ".zig-zag-section .testimonial-divs .client-name"
            ]?.["line-height"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTestimonialSectionTitleAreaTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[
                ".zig-zag-section .testimonial-divs .client-name"
              ]?.["font-weight"]
            }
            onChange={(e) => handleChangeTestimonialSectionTitleAreaTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".zig-zag-section .testimonial-divs .client-name"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".zig-zag-section .testimonial-divs .client-name"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in section Client Position</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 18)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".zig-zag-section .testimonial-divs .client-position"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTestimonialSectionTitleAreaPosition(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[
                ".zig-zag-section .testimonial-divs .client-position"
              ]?.["font-weight"]
            }
            onChange={(e) => handleChangeTestimonialSectionTitleAreaPosition(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".zig-zag-section .testimonial-divs .client-position"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".zig-zag-section .testimonial-divs .client-position"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in section Client Description</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 18)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".zig-zag-section .testimonial-divs .desq"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTestimonialSectionDescription(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Line Height (Default : 1.2)"}
          </Label>
          <Input
            className="form-control"
            name="line-height"
            type="number"
            placeholder="Line Height"
            value={settingFormData?.[
              ".zig-zag-section .testimonial-divs .desq"
            ]?.["line-height"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTestimonialSectionDescription(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Margin (default : 10)"}</Label>
          <Input
            className="form-control"
            name="margin"
            type="number"
            placeholder="Margin"
            value={settingFormData?.[
              ".zig-zag-section .testimonial-divs .desq"
            ]?.["margin"]
              ?.split(" ")[0]
              ?.replace(/px/g, "")}
            onChange={(e) => handleChangeTestimonialSection(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[".zig-zag-section .testimonial-divs .desq"]?.[
                "font-weight"
              ]
            }
            onChange={(e) => handleChangeTestimonialSectionDescription(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".zig-zag-section .testimonial-divs .desq"]?.[
                "color"
              ]
            }
            setSettingFormData={setSettingFormData}
            className={".zig-zag-section .testimonial-divs .desq"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in section Client Rating</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 18)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[".zig-zag-section .star-rating i"]?.[
              "font-size"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTestimonialSliderRating(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".zig-zag-section .star-rating i"]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".zig-zag-section .star-rating i"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
      </Row>
      <hr />
      <PaginationButtonStyle
        mainClass={".zig-zag-section"}
        settingFormData={settingFormData}
        setSettingFormData={setSettingFormData}
      />
      <hr />
      <CustomStyle
        handleChange={handleChangeCustomStyle}
        value={settingFormData?.custom}
      />
      <ProductImageUploadPopup
        allPopupState={allPopupState}
        setAllPopupState={setAllPopupState}
        crop={crop}
        setCrop={setCrop}
        completedCrop={completedCrop}
        setCompletedCrop={setCompletedCrop}
        scale={scale}
        rotate={rotate}
        image={settingFormData?.image}
        setImage={setSettingFormData}
        imageUpload={imageUpload}
        isEdit={isEdit}
        reupload={reupload}
        // aspect={2.56 / 1}
      />
    </>
  );
};

export default TestimonialTheme2;
