import axios from "axios";
import {
  ADD_FONT_LIST_LIST,
  DELETE__FONT_LIST_LIST,
  DETAILS_FONT_LIST_LIST,
  EDIT_FONT_LIST_LIST,
  FONT_LIST_LIST,
  ORG_FONT_LIST_LIST_ENABLE_DESABLE,
} from "../../constant/API constant";

export const FontList_List = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${FONT_LIST_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const Add_FontList = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${ADD_FONT_LIST_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Edit_FontList = (formData, id) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${EDIT_FONT_LIST_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { fontId: id },
    }
  );
};

export const Delete_FontList = (fontId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(
    `${process.env.REACT_APP_API_URL}${DELETE__FONT_LIST_LIST}`,
    {
      headers: { Authorization: jwt_token },
      params: { fontId },
    }
  );
};

export const Details_FontList = (id) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${DETAILS_FONT_LIST_LIST}`,
    {
      headers: { Authorization: jwt_token },
      params: { fontId: id },
    }
  );
};

export const FontList_Enable_Disable = (formData, fontId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${ORG_FONT_LIST_LIST_ENABLE_DESABLE}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { fontId },
    }
  );
};
