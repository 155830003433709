import React, { useContext, useState } from "react";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { Cases } from "../../../constant";
import { OpenModalCommonFunc } from "../../../utils";
import {
  Button,
  Col,
  Container,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import ItemsDragAndDrops from "../../../common/ItemsDragAndDrops";
import { Close } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { UploadCloud } from "react-feather";
import Dividers from "../../../common/divider";

const ServicesPopup = () => {
  const { setAllPopupState } = useContext(ByteCardBuilderContext);
  const dragAndDropData = [
    {
      id: "card-1",
      name: "Service 1",
    },
    {
      id: "card-2",
      name: "Service 2",
    },
    {
      id: "card-3",
      name: "Service 3",
    },
    {
      id: "card-4",
      name: "Service 4",
    },
    {
      id: "card-5",
      name: "Service 5",
    },
  ];
  const [characters, updateCharacters] = useState(dragAndDropData);
  const [activeTab2, setActiveTab2] = useState("1");

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(characters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    updateCharacters(items);
  }
  return (
    <>
      <Container className="mb-5">
        <Nav className="border-tab" tabs>
          <NavItem>
            <NavLink
              className={activeTab2 === "1" ? "active" : ""}
              onClick={() => setActiveTab2("1")}
            >
              <i className="icofont icofont-people"></i> {"Service"}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab2 === "2" ? "active" : ""}
              onClick={() => setActiveTab2("2")}
            >
              <i className="icofont icofont-list"></i>
              {"Details"}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab2}>
          <TabPane className="fade show" tabId="1">
            <Row>
              {/* <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-around",
                  marginBottom: "3rem",
                }}
              > */}
              <Col sm="12" xl="6">
                <div>
                  <h6>Services</h6>
                  <Row>
                    <Col md="6">
                      <Input
                        className="form-control mt-3 "
                        name="search"
                        type="text"
                        placeholder="search"
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </Col>
                    <Col
                      md="3"
                      className="d-flex justify-content-center  align-items-center "
                    ></Col>
                  </Row>
                  <div className="mt-4">
                    <ItemsDragAndDrops
                      handleOnDragEnd={handleOnDragEnd}
                      characters={characters}
                    />
                  </div>
                </div>
              </Col>
              <Col sm="12" xl="6">
                <div className="mt-4 d-flex flex-column justify-content-center ">
                  <h6>Add New Service</h6>
                  <Row className="mt-4">
                    <Col md="12 mb-3">
                      <Label htmlFor="validationCustom03">{"Title "}</Label>
                      <Input
                        className="form-control"
                        name="title"
                        type="text"
                        placeholder="Title *"
                        style={{ borderRadius: "0", color: "black" }}
                        required
                      />
                    </Col>
                    <Col md="12 mb-3">
                      <Label htmlFor="validationCustom03">
                        {"Head Line *"}
                      </Label>
                      <Input
                        className="form-control"
                        name="title"
                        type="text"
                        placeholder="Head Line *"
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </Col>
                    <Col md="12 mb-3">
                      <Label htmlFor="validationCustom03">
                        {"Short Description"}
                      </Label>
                      <textarea
                        className="form-control"
                        name="title"
                        type="text"
                        rows={5}
                        placeholder="Short Description"
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>

              <div className="d-flex justify-content-between ">
                <div>
                  <Button
                    color="danger"
                    onClick={() =>
                      OpenModalCommonFunc(
                        Cases.SERVICE,
                        setAllPopupState,
                        false
                      )
                    }
                  >
                    Cancel
                  </Button>
                </div>
                <div>
                  <Button color="success">Submit</Button>
                </div>
              </div>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col sm="12" xl="3">
                <div>
                  <h6>Service</h6>
                  <Row>
                    <Col md="12">
                      <Input
                        className="form-control mt-3 "
                        name="search"
                        type="text"
                        placeholder="search"
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </Col>
                  </Row>
                  <div className="mt-4">
                    <ItemsDragAndDrops
                      handleOnDragEnd={handleOnDragEnd}
                      characters={characters}
                    />
                  </div>
                </div>
              </Col>
              <Col sm="12" xl="3">
                <div>
                  <h6>Service Category</h6>
                  <Row>
                    <Col md="12">
                      <Input
                        className="form-control mt-3 "
                        name="search"
                        type="text"
                        placeholder="search"
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </Col>
                  </Row>
                  <div className="mt-4">
                    <ItemsDragAndDrops
                      handleOnDragEnd={handleOnDragEnd}
                      characters={characters}
                    />
                  </div>
                </div>
              </Col>
              <Col sm="12" xl="6">
                <div className="mt-4 d-flex flex-column justify-content-center ">
                  <h6>Add New</h6>
                  <Row className="mt-4">
                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom03">
                        {"Service Category"}
                      </Label>
                      <Input
                        className="form-control"
                        name="title"
                        type="text"
                        placeholder="Service Category"
                        style={{ borderRadius: "0", color: "black" }}
                        required
                      />
                    </Col>
                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom03">{"Title"}</Label>
                      <Input
                        className="form-control"
                        name="title"
                        type="text"
                        placeholder="Title"
                        style={{ borderRadius: "0", color: "black" }}
                        required
                      />
                    </Col>
                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom03">
                        {"Short Description "}
                      </Label>
                      <textarea
                        className="form-control"
                        name="title"
                        type="text"
                        rows={5}
                        placeholder="Short Description"
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </Col>
                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom03">
                        {"Long Description "}
                      </Label>
                      <textarea
                        className="form-control"
                        name="title"
                        type="text"
                        rows={5}
                        placeholder="Long Description"
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </Col>
                  </Row>
                  <Dividers divide="IMAGES" />
                  <p className="mb-4">File type required JPG, PNG (Max 1MB)</p>
                  <Row>
                    <Col md="6 mb-3">
                      <div
                        className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                        style={{ cursor: "pointer" }}
                      >
                        <span>
                          <UploadCloud />
                        </span>
                        <img
                          src={
                            "https://api.bytecard.in/images/uploads/w-200/84ce7bf0be6b3e9d6cfe48f02038aa2e.jpg"
                          }
                          alt=""
                          style={{
                            width: "80%",
                            height: "98%",
                            objectFit: "contain",
                            position: "absolute",
                          }}
                          onClick={() =>
                            OpenModalCommonFunc(
                              Cases.PRODUCT_UPLOAD,
                              setAllPopupState
                            )
                          }
                        />
                        <IconButton
                          edge="start"
                          color="inherit"
                          aria-label="close"
                          style={{
                            color: "red",
                            position: "absolute",
                            top: "-22px",
                            left: "1px",
                          }}
                        >
                          <Close
                            style={{ fontSize: "25px", fontWeight: "bold" }}
                          />
                        </IconButton>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
        <ProductImageUploadPopup />
      </Container>
    </>
  );
};

export default ServicesPopup;
