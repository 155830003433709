import React, { useState } from "react";
import ContactUsMultipageContaxt from "./ContactUsMultipageContaxt";
import { randomStr } from "../../../utils/helper";

const ContactUsMultipageState = (props) => {
  const [apiData, setApiData] = useState({ data: [], isLoading: false });
  const [isBackgroundColor, setIsBackgroundColor] = useState(false);
  const [countOfList, setCountOfList] = useState("");
  const [fromData, setFormData] = useState({
    name: "",
    title: "",
    isTitleShow: true,
    subTitle: "",
    addLine1: "",
    addLine2: "",
    area: "",
    country: "",
    state: "",
    city: "",
    pinCode: "",
    mapLink: "",
    phone: "",
    email: "",
    latitude: "",
    longitude: "",
    iframe: "",
    theme: 1,
    navMapId: null,
    style: {
      [`.contact-section${countOfList}`]: {
        padding: "100px 0",
        "background-color": "rgb(255 255 255 / 100)",
      },
      image: "",
      [`.contact-section${countOfList}.bg-image`]: {
        "background-image": "",
      },
      [`.contact-section${countOfList}.bg-image::before`]: {
        "background-color": "",
      },
      [`.contact-section${countOfList} .section-title-area`]: {
        "margin-bottom": "50px",
      },
      [`.contact-section${countOfList} .section-title-area .section-subtitle`]:
        {
          "font-size": "16px",
          "font-weight": "600",
          color: "rgb(255 73 124 / 100)",
          "background-color": "rgb(255 73 124 / 0.2)",
          padding: "5px 20px",
          p1: "5px",
          p2: "20px",
          "border-radius": "15px",
          "margin-bottom": "15px",
          display: "inline-block",
        },
      [`.contact-section${countOfList} .section-title-area .section-title`]: {
        "font-size": "44px",
        "font-weight": "600",
        color: "rgb(0 0 0 / 100)",
      },
      [`.contact-section${countOfList} .inquiry-box`]: {
        "background-color": "rgb(255 255 255 / 100)",
        padding: "40px 40px",
        p1: "40px",
        p2: "40px",
        margin: "10px 0",
        "border-radius": "15px",
        border: "1px solid rgb(255 255 255 / 100)",
        borderPx: "1px",
        borderThik: "solid",
        borderColor: "rgb(255 255 255 / 100)",
        "border-top": "3px solid rgb(255 73 124 / 100)",
        borderTopPx: "3px",
        borderTopThik: "solid",
        borderTopColor: "rgb(255 73 124 / 100)",
        "box-shadow": "5px 5px 15px rgb(0 0 0 / 0.094)",
        bs1: "5px",
        bs2: "5px",
        bs3: "15px",
        shadowColor: "rgb(0 0 0 / 0.094)",
        "text-align": "center",
      },
      [`.contact-section${countOfList} .inquiry-box img`]: {
        "margin-bottom": "20px",
        "max-width": "60px",
      },
      [`.contact-section${countOfList} .inquiry-box h4`]: {
        color: "rgb(0 0 0 / 100)",
        "font-weight": "600",
        "font-size": "22px",
        "margin-bottom": "15px",
      },
      [`.contact-section${countOfList} .inquiry-box p`]: {
        color: "rgb(0 0 0 / 100)",
        "font-weight": "400",
        "font-size": "14px",
        "line-height": "1.6",
      },
      [`.contact-section${countOfList} .contactbox`]: {
        "background-color": "rgb(255 255 255 / 100)",
        padding: "40px 40px",
        p1: "40px",
        p2: "40px",
        "border-radius": "15px",
        border: "1px solid rgb(255 255 255 / 100)",
        borderPx: "1px",
        borderThik: "solid",
        borderColor: "rgb(255 255 255 / 100)",
        "border-top": "3px solid rgb(255 73 124 / 100)",
        borderTopPx: "3px",
        borderTopThik: "solid",
        borderTopColor: "rgb(255 73 124 / 100)",
        "box-shadow": "5px 5px 15px rgb(0 0 0 / 0.094)",
        bs1: "5px",
        bs2: "5px",
        bs3: "15px",
        shadowColor: "rgb(0 0 0 / 0.094)",
      },
      [`.contact-section${countOfList} .contactbox iframe`]: {
        border: "2px solid rgb(221 221 221 / 100)",
        borderPx: "2px",
        borderThik: "solid",
        borderColor: "rgb(221 221 221 / 100)",
      },
      contactUsId: "",
    },
    workingHour:
      "<p><strong>Working Hours</strong></p>\n\n<p><strong>Sunday</strong> : 09:00 AM - 01:00 PM<br />\n<strong>Monday</strong> : 09:00 AM - 01:00 PM<br />\n<strong>Tuesday</strong> :&nbsp;09:00 AM - 01:00 PM<br />\n<strong>Wednesday</strong> : 09:00 AM - 01:00 PM<br />\n<strong>Thursday</strong> :&nbsp;09:00 AM - 01:00 PM<br />\n<strong>Friday</strong> : 09:00 AM - 01:00 PM<br />\n<strong>Saturday</strong> : 09:00 AM - 01:00 PM</p>\n",
    webId: localStorage.getItem("webId"),
  });
  const [addFields, setAddFields] = useState({
    phoneInput: [
      {
        id: randomStr(2),
        phoneValue: "",
        dialCode: { value: "+91", label: "+91" },
      },
    ],
    emailInput: [{ id: randomStr(2), emailValue: "" }],
  });
  const [defaultSet, setDefaultSet] = useState(false);
  const [id, setId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [filter, setFilter] = useState("");
  const [settingFormData, setSettingFormData] = useState({
    [`.contact-section${countOfList}`]: {
      padding: "100px 0",
      "background-color": "rgb(255 255 255 / 100)",
    },
    image: "",
    [`.contact-section${countOfList}.bg-image`]: {
      "background-image": "",
    },
    [`.contact-section${countOfList}.bg-image::before`]: {
      "background-color": "",
    },
    [`.contact-section${countOfList} .section-title-area`]: {
      "margin-bottom": "50px",
    },
    [`.contact-section${countOfList} .section-title-area .section-subtitle`]: {
      "font-size": "16px",
      "font-weight": "600",
      color: "rgb(255 73 124 / 100)",
      "background-color": "rgb(255 73 124 / 0.2)",
      padding: "5px 20px",
      p1: "5px",
      p2: "20px",
      "border-radius": "15px",
      "margin-bottom": "15px",
      display: "inline-block",
    },
    [`.contact-section${countOfList} .section-title-area .section-title`]: {
      "font-size": "44px",
      "font-weight": "600",
      color: "rgb(0 0 0 / 100)",
    },
    [`.contact-section${countOfList} .inquiry-box`]: {
      "background-color": "rgb(255 255 255 / 100)",
      padding: "40px 40px",
      p1: "40px",
      p2: "40px",
      margin: "10px 0",
      "border-radius": "15px",
      border: "1px solid rgb(255 255 255 / 100)",
      borderPx: "1px",
      borderThik: "solid",
      borderColor: "rgb(255 255 255 / 100)",
      "border-top": "3px solid rgb(255 73 124 / 100)",
      borderTopPx: "3px",
      borderTopThik: "solid",
      borderTopColor: "rgb(255 73 124 / 100)",
      "box-shadow": "5px 5px 15px rgb(0 0 0 / 0.094)",
      bs1: "5px",
      bs2: "5px",
      bs3: "15px",
      shadowColor: "rgb(0 0 0 / 0.094)",
      "text-align": "center",
    },
    [`.contact-section${countOfList} .inquiry-box img`]: {
      "margin-bottom": "20px",
      "max-width": "60px",
    },
    [`.contact-section${countOfList} .inquiry-box h4`]: {
      color: "rgb(0 0 0 / 100)",
      "font-weight": "600",
      "font-size": "22px",
      "margin-bottom": "15px",
    },
    [`.contact-section${countOfList} .inquiry-box p`]: {
      color: "rgb(0 0 0 / 100)",
      "font-weight": "400",
      "font-size": "14px",
      "line-height": "1.6",
    },
    [`.contact-section${countOfList} .contactbox`]: {
      "background-color": "rgb(255 255 255 / 100)",
      padding: "40px 40px",
      p1: "40px",
      p2: "40px",
      "border-radius": "15px",
      border: "1px solid rgb(255 255 255 / 100)",
      borderPx: "1px",
      borderThik: "solid",
      borderColor: "rgb(255 255 255 / 100)",
      "border-top": "3px solid rgb(255 73 124 / 100)",
      borderTopPx: "3px",
      borderTopThik: "solid",
      borderTopColor: "rgb(255 73 124 / 100)",
      "box-shadow": "5px 5px 15px rgb(0 0 0 / 0.094)",
      bs1: "5px",
      bs2: "5px",
      bs3: "15px",
      shadowColor: "rgb(0 0 0 / 0.094)",
    },
    [`.contact-section${countOfList} .contactbox iframe`]: {
      border: "2px solid rgb(221 221 221 / 100)",
      borderPx: "2px",
      borderThik: "solid",
      borderColor: "rgb(221 221 221 / 100)",
    },
    contactUsId: "",
  });
  const [collId, setCollId] = useState();
  const [boxSection, setBoxSection] = useState(false);
  const [backImageToggle, setBackImageToggle] = useState(false);

  return (
    <ContactUsMultipageContaxt.Provider
      value={{
        apiData,
        setApiData,
        fromData,
        setFormData,
        id,
        setId,
        isEdit,
        setIsEdit,
        filter,
        setFilter,
        addFields,
        setAddFields,
        defaultSet,
        setDefaultSet,
        settingFormData,
        setSettingFormData,
        backImageToggle,
        setBackImageToggle,
        boxSection,
        setBoxSection,
        collId,
        setCollId,
        isBackgroundColor,
        setIsBackgroundColor,
        countOfList,
        setCountOfList,
      }}
    >
      {props.children}
    </ContactUsMultipageContaxt.Provider>
  );
};

export default ContactUsMultipageState;
