import axios from "axios";
import {
  GET_IMAGE_BANNER,
  GET_IMAGE_BANNER_DETAILS,
  POST_IMAGE_BANNER,
  PUT_IMAGE_BANNER_ORDER,
  PUT_STATUS_IMAGE_BANNER,
} from "..";

/**
 * get image banner
 * @param {Parameters} paramObj
 * @returns
 */
export const getImageBanner = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_IMAGE_BANNER}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * enable-disable image banner
 * @param {body} body
 * @returns
 */
export const updateStatus = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_STATUS_IMAGE_BANNER}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * create image banner
 * @param {body} body
 * @returns
 */
export const postImageBanner = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${POST_IMAGE_BANNER}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * update image banner
 * @param {body} body
 * @returns
 */
export const putUpdateImageBanner = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${POST_IMAGE_BANNER}`,
    body,
    {
      headers: { Authorization: jwt_token },
      params: { imageBannerId: body.imageBannerId },
    }
  );
};

/**
 * delete image banner
 * @param {id} imageBannerId
 * @returns
 */
export const deleteImageBanner = (imageBannerId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${POST_IMAGE_BANNER}`, {
    headers: { Authorization: jwt_token },
    params: { imageBannerId },
  });
};

/**
 * get image banner
 * @param {id} imageBannerId
 * @returns
 */
export const getImageBannerDetails = (imageBannerId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${GET_IMAGE_BANNER_DETAILS}`,
    {
      headers: { Authorization: jwt_token },
      params: { imageBannerId },
    }
  );
};

/**
 * short order image banner
 * @param {body} updateOrder
 * @returns
 */
export const sort_image_banner = (updateOrder) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_IMAGE_BANNER_ORDER}`,
    { updateOrder: updateOrder },
    {
      headers: { Authorization: jwt_token },
    }
  );
};
