import {
  addUrlImage,
  convertRGBA,
  removeUrlImage,
  removeUrlKeyInBGI,
  removeUrlKeyInPath,
  rgbColorCreate,
} from "../../../../utils/helper";

export const BlogStyleThemeOneReset = {
  ".blog-section": {
    padding: "100px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".blog-section.bg-image": {},
  ".blog-section.bg-image::before": {
    // "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
  },
  ".blog-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".blog-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: { r: 255, g: 73, b: 124, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 0.2 },
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".blog-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: { r: 0, g: 0, b: 0, a: 100 },
  },
  ".blog_items .blog_thumb": {
    "border-radius": "5px",
    "margin-bottom": "20px",
  },
  ".blog_items .blog_thumb img": {
    "border-radius": "5px",
  },
  ".blog_items .blog_content .blog_meta": {
    "margin-bottom": "15px",
  },
  ".blog_items .blog_content .blog_meta .date": {
    color: { r: 76, g: 84, b: 98, a: 100 },
  },
  ".blog_items .blog_content .blog_meta .posted": {
    color: { r: 255, g: 73, b: 124, a: 100 },
    "font-size": "14px",
    "font-weight": "500",
    padding: "5px 10px",
    p1: "5px",
    p2: "10px",
    "border-radius": "5px",
    "background-color": { r: 255, g: 73, b: 124, a: 0.2 },
  },
  ".blog_items .blog_content .blog_title": {
    "font-size": "20px",
    "font-weight": "600",
    "margin-bottom": "15px",
  },
  ".blog_items .blog_content .blog_title a": {
    color: { r: 0, g: 0, b: 0, a: 100 },
  },
  ".blog_items .blog_content .blog_title a:hover": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".blog_items .blog_content .blog_desq": {
    color: { r: 76, g: 84, b: 98, a: 100 },
    "font-size": "16px",
    "font-weight": "400",
    "line-height": "1.6",
    "margin-bottom": "20px",
    "text-align": "justify",
  },
  ".blog_items .blog_content .link-btn": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".blog-section .more-btn": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".blog-section .more-btn:hover": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".pagination .page-item.active .page-link": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    "border-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".service-detail-section": {
    padding: "60px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".service-detail-section.bg-image": {},
  ".service-detail-section.bg-image::before": {},
  ".detail-sidebar": {
    display: "inline-block",
    width: "100%",
  },
  ".personal-details .details-title": {
    "font-weight": 600,
    "font-size": "24px",
  },
  ".personal-details p": {
    "margin-bottom": "20px",
    "line-height": 1.6,
    "font-size": "18px",
  },
  ".personal-details .info-list": {
    "margin-bottom": "30px",
  },
  ".personal-details .info-list li": {
    "margin-bottom": "15px",
    "font-size": "18px",
  },
  ".personal-details .order-list": {
    "margin-bottom": "30px",
  },
  ".personal-details .order-list li": {
    "padding-left": "25px",
    "margin-bottom": "15px",
    "font-size": "18px",
  },
  ".personal-details .order-list li:before": {
    "font-family": "FontAwesome",
    top: "3px",
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".side_thumb img": {
    height: "90px",
    width: "90px",
    "border-radius": "4px",
  },
  ".side_content .side_meta": {
    "font-size": "14px",
    color: { r: 76, g: 84, b: 98, a: 100 },
    "font-weight": 600,
    "margin-bottom": "3px",
  },
  ".side_content .side_title": {
    "font-size": "16px",
    "font-weight": 700,
    color: { r: 0, g: 0, b: 0, a: 100 },
    "line-height": 1.5,
  },
  ".side_content .side_title a": {
    color: { r: 0, g: 0, b: 0, a: 100 },
  },
  blogCategoryId: "",
  showItem: 3,
  custom: "",
};

export const BlogThemeOneFunction = (payload) => {
  return {
    ".blog-section": {
      padding: payload.style?.[".blog-section"]?.["padding"],
      "background-color": payload.style?.[".blog-section"]?.["background-color"]
        ? convertRGBA({
            rgbString: payload.style?.[".blog-section"]?.["background-color"],
          })
        : "",
      width: payload.style?.[".blog-section"]?.["width"]?.replace(
        "calc(100% - ",
        ""
      ),
      margin: payload.style?.[".blog-section"]?.["margin"],
      "border-radius": payload.style?.[".blog-section"]?.["border-radius"],
    },
    ".blog-section.bg-image": {
      "background-image": payload.style?.[".blog-section.bg-image"]?.[
        "background-image"
      ]
        ? removeUrlKeyInPath({
            urlString:
              payload.style?.[".blog-section.bg-image"]?.["background-image"],
          })
        : "",
    },
    ".blog-section.bg-image::before": {
      "background-color": payload.style?.[".blog-section.bg-image::before"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".blog-section.bg-image::before"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".blog-section .section-title-area": {
      "margin-bottom":
        payload.style?.[".blog-section .section-title-area"]?.["margin-bottom"],
    },
    ".blog-section .section-title-area .section-subtitle": {
      "font-size":
        payload.style?.[
          ".blog-section .section-title-area .section-subtitle"
        ]?.["font-size"],
      "font-weight":
        payload.style?.[
          ".blog-section .section-title-area .section-subtitle"
        ]?.["font-weight"],
      // color:
      //   payload.style?.[
      //     ".blog-section .section-title-area .section-subtitle"
      //   ]?.["color"],
      color: payload.style?.[
        ".blog-section .section-title-area .section-subtitle"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".blog-section .section-title-area .section-subtitle"
              ]?.["color"],
          })
        : "",
      // "background-color":
      //   payload.style?.[
      //     ".blog-section .section-title-area .section-subtitle"
      //   ]?.["background-color"],
      "background-color": payload.style?.[
        ".blog-section .section-title-area .section-subtitle"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".blog-section .section-title-area .section-subtitle"
              ]?.["background-color"],
          })
        : "",
      padding:
        payload.style?.[
          ".blog-section .section-title-area .section-subtitle"
        ]?.["padding"],
      p1: payload.style?.[
        ".blog-section .section-title-area .section-subtitle"
      ]?.["padding"]?.split(" ")[0],
      p2: payload.style?.[
        ".blog-section .section-title-area .section-subtitle"
      ]?.["padding"]?.split(" ")[1],
      "border-radius":
        payload.style?.[
          ".blog-section .section-title-area .section-subtitle"
        ]?.["border-radius"],
      "margin-bottom":
        payload.style?.[
          ".blog-section .section-title-area .section-subtitle"
        ]?.["margin-bottom"],
    },
    ".blog-section .section-title-area .section-title": {
      "font-size":
        payload.style?.[".blog-section .section-title-area .section-title"]?.[
          "font-size"
        ],
      "font-weight":
        payload.style?.[".blog-section .section-title-area .section-title"]?.[
          "font-weight"
        ],
      color: payload.style?.[
        ".blog-section .section-title-area .section-title"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".blog-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".blog_items .blog_thumb": {
      "border-radius":
        payload.style?.[".blog_items .blog_thumb"]?.["border-radius"],
      "margin-bottom":
        payload.style?.[".blog_items .blog_thumb"]?.["margin-bottom"],
    },
    ".blog_items .blog_thumb img": {
      "border-radius":
        payload.style?.[".blog_items .blog_thumb img"]?.["border-radius"],
    },
    ".blog_items .blog_content .blog_meta": {
      "margin-bottom":
        payload.style?.[".blog_items .blog_content .blog_meta"]?.[
          "margin-bottom"
        ],
    },
    ".blog_items .blog_content .blog_meta .date": {
      color: payload.style?.[".blog_items .blog_content .blog_meta .date"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".blog_items .blog_content .blog_meta .date"]?.[
                "color"
              ],
          })
        : "",
    },
    ".blog_items .blog_content .blog_meta .posted": {
      color: payload.style?.[".blog_items .blog_content .blog_meta .posted"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".blog_items .blog_content .blog_meta .posted"]?.[
                "color"
              ],
          })
        : "",
      "font-size":
        payload.style?.[".blog_items .blog_content .blog_meta .posted"]?.[
          "font-size"
        ],
      "font-weight":
        payload.style?.[".blog_items .blog_content .blog_meta .posted"]?.[
          "font-weight"
        ],
      padding:
        payload.style?.[".blog_items .blog_content .blog_meta .posted"]?.[
          "padding"
        ],
      p1: payload.style?.[".blog_items .blog_content .blog_meta .posted"]?.[
        "padding"
      ]?.split(" ")[0],
      p2: payload.style?.[".blog_items .blog_content .blog_meta .posted"]?.[
        "padding"
      ]?.split(" ")[1],
      "border-radius":
        payload.style?.[".blog_items .blog_content .blog_meta .posted"]?.[
          "border-radius"
        ],
      "background-color": payload.style?.[
        ".blog_items .blog_content .blog_meta .posted"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".blog_items .blog_content .blog_meta .posted"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".blog_items .blog_content .blog_title": {
      "font-size":
        payload.style?.[".blog_items .blog_content .blog_title"]?.["font-size"],
      "font-weight":
        payload.style?.[".blog_items .blog_content .blog_title"]?.[
          "font-weight"
        ],
      "margin-bottom":
        payload.style?.[".blog_items .blog_content .blog_title"]?.[
          "margin-bottom"
        ],
    },
    ".blog_items .blog_content .blog_title a": {
      color: payload.style?.[".blog_items .blog_content .blog_title a"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".blog_items .blog_content .blog_title a"]?.[
                "color"
              ],
          })
        : "",
    },
    ".blog_items .blog_content .blog_title a:hover": {
      color: payload.style?.[".blog_items .blog_content .blog_title a:hover"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".blog_items .blog_content .blog_title a:hover"
              ]?.["color"],
          })
        : "",
    },
    ".blog_items .blog_content .blog_desq": {
      color: payload.style?.[".blog_items .blog_content .blog_desq"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".blog_items .blog_content .blog_desq"]?.[
                "color"
              ],
          })
        : "",
      "font-size":
        payload.style?.[".blog_items .blog_content .blog_desq"]?.["font-size"],
      "font-weight":
        payload.style?.[".blog_items .blog_content .blog_desq"]?.[
          "font-weight"
        ],
      "line-height":
        payload.style?.[".blog_items .blog_content .blog_desq"]?.[
          "line-height"
        ],
      "margin-bottom":
        payload.style?.[".blog_items .blog_content .blog_desq"]?.[
          "margin-bottom"
        ],
      "text-align":
        payload.style?.[".blog_items .blog_content .blog_desq"]?.["text-align"],
    },
    ".blog_items .blog_content .link-btn": {
      color: payload.style?.[".blog_items .blog_content .link-btn"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".blog_items .blog_content .link-btn"]?.["color"],
          })
        : "",
      "background-color": payload.style?.[
        ".blog_items .blog_content .link-btn"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".blog_items .blog_content .link-btn"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".service-detail-section": {
      padding: payload.style?.[".service-detail-section"]?.["padding"],
      "background-color": payload.style?.[".service-detail-section"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".service-detail-section"]?.["background-color"],
          })
        : "",
      width: payload.style?.[".service-detail-section"]?.["width"]?.replace(
        "calc(100% - ",
        ""
      ),
      margin: payload.style?.[".service-detail-section"]?.["margin"],
      "border-radius":
        payload.style?.[".service-detail-section"]?.["border-radius"],
    },
    ".service-detail-section.bg-image": {
      "background-image":
        payload.style?.[".service-detail-section.bg-image"]?.[
          "background-image"
        ] !== "" &&
        payload.style?.[".service-detail-section.bg-image"]?.[
          "background-image"
        ] !== null
          ? removeUrlKeyInBGI({
              urlString:
                payload.style?.[".service-detail-section.bg-image"]?.[
                  "background-image"
                ],
            })
          : "",
    },
    ".service-detail-section.bg-image::before": {
      "background-color": payload.style?.[
        ".service-detail-section.bg-image::before"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".service-detail-section.bg-image::before"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".personal-details .details-title": {
      "font-weight":
        payload.style?.[".personal-details .details-title"]?.["font-weight"],
      "font-size":
        payload.style?.[".personal-details .details-title"]?.["font-size"],
    },
    ".personal-details p": {
      "margin-bottom":
        payload.style?.[".personal-details p"]?.["margin-bottom"],
      "line-height": payload.style?.[".personal-details p"]?.["line-height"],
      "font-size": payload.style?.[".personal-details p"]?.["font-size"],
    },
    ".personal-details .info-list": {
      "margin-bottom":
        payload.style?.[".personal-details .info-list"]?.["margin-bottom"],
    },
    ".personal-details .info-list li": {
      "margin-bottom":
        payload.style?.[".personal-details .info-list li"]?.["margin-bottom"],
      "font-size":
        payload.style?.[".personal-details .info-list li"]?.["font-size"],
    },
    ".personal-details .order-list": {
      "margin-bottom":
        payload.style?.[".personal-details .order-list"]?.["margin-bottom"],
    },
    ".personal-details .order-list li": {
      "padding-left":
        payload.style?.[".personal-details .order-list li"]?.["padding-left"],
      "margin-bottom":
        payload.style?.[".personal-details .order-list li"]?.["margin-bottom"],
      "font-size":
        payload.style?.[".personal-details .order-list li"]?.["font-size"],
    },
    ".personal-details .order-list li:before": {
      "font-family":
        payload.style?.[".personal-details .order-list li:before"]?.[
          "font-family"
        ],
      top: payload.style?.[".personal-details .order-list li:before"]?.["top"],
      color: payload.style?.[".personal-details .order-list li:before"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".personal-details .order-list li:before"]?.[
                "color"
              ],
          })
        : "",
    },
    ".side_thumb img": {
      height: payload.style?.[".side_thumb img"]?.["height"],
      width: payload.style?.[".side_thumb img"]?.["width"],
      "border-radius": payload.style?.[".side_thumb img"]?.["border-radius"],
    },
    ".side_content .side_meta": {
      "font-size": payload.style?.[".side_content .side_meta"]?.["font-size"],
      color: payload.style?.[".side_content .side_meta"]?.["color"]
        ? convertRGBA({
            rgbString: payload.style?.[".side_content .side_meta"]?.["color"],
          })
        : "",
      "font-weight":
        payload.style?.[".side_content .side_meta"]?.["font-weight"],
      "margin-bottom":
        payload.style?.[".side_content .side_meta"]?.["margin-bottom"],
    },
    ".side_content .side_title": {
      "font-size": payload.style?.[".side_content .side_title"]?.["font-size"],
      "font-weight":
        payload.style?.[".side_content .side_title"]?.["font-weight"],
      color: payload.style?.[".side_content .side_title"]?.["color"]
        ? convertRGBA({
            rgbString: payload.style?.[".side_content .side_title"]?.["color"],
          })
        : "",
      "line-height":
        payload.style?.[".side_content .side_title"]?.["line-height"],
    },
    ".side_content .side_title a": {
      color: payload.style?.[".side_content .side_title a"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".side_content .side_title a"]?.["color"],
          })
        : "",
    },
    custom: payload?.style?.custom,
  };
};

export const BlogStyleOneSubFun = ({
  settingFormData,
  boxSection,
  boxSectionDetail,
}) => {
  let newData = {
    ...settingFormData,
    ".blog-section": boxSection
      ? {
          ...settingFormData?.[".blog-section"],
          padding: `${settingFormData?.[".blog-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
          width: `calc(100% - ${settingFormData?.[".blog-section"]?.[
            "width"
          ]?.replace("rem)", "")}rem)`,
          margin: `${settingFormData?.[".blog-section"]?.["margin"]
            ?.split(" ")[0]
            ?.replace("px", "")}px auto`,
          "border-radius": `${settingFormData?.[".blog-section"]?.[
            "border-radius"
          ]?.replace("rem", "")}rem`,
        }
      : {
          ...settingFormData?.[".blog-section"],
          padding: `${settingFormData?.[".blog-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
        },
    ".blog-section.bg-image": settingFormData?.[".blog-section.bg-image"]?.[
      "background-image"
    ]
      ? {
          ...settingFormData?.[".blog-section.bg-image"],
          "background-image": settingFormData?.[".blog-section.bg-image"]?.[
            "background-image"
          ]
            ? addUrlImage({
                urlString: removeUrlImage({
                  urlString:
                    settingFormData?.[".blog-section.bg-image"]?.[
                      "background-image"
                    ],
                }),
              })
            : "",
        }
      : {},
    ".blog-section.bg-image::before": settingFormData[
      ".blog-section.bg-image::before"
    ]?.["background-color"]
      ? {
          ...settingFormData?.[".blog-section.bg-image::before"],
          "background-color": settingFormData?.[
            ".blog-section.bg-image::before"
          ]?.["background-color"]
            ? rgbColorCreate({
                rgbString:
                  settingFormData?.[".blog-section.bg-image::before"]?.[
                    "background-color"
                  ],
              })
            : "",
        }
      : {},
    ".blog-section .section-title-area": {
      ...settingFormData?.[".blog-section .section-title-area"],
      "margin-bottom": `${settingFormData[
        ".blog-section .section-title-area"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".blog-section .section-title-area .section-subtitle": {
      ...settingFormData[".blog-section .section-title-area .section-subtitle"],
      "font-size": `${settingFormData[
        ".blog-section .section-title-area .section-subtitle"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".blog-section .section-title-area .section-subtitle"]?.["font-weight"]}`,
      // color: `${settingFormData?.[".blog-section .section-title-area .section-subtitle"]?.["color"]}`,
      color: settingFormData[
        ".blog-section .section-title-area .section-subtitle"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".blog-section .section-title-area .section-subtitle"
              ]?.["color"],
          })
        : "",
      // "background-color": `${settingFormData?.[".blog-section .section-title-area .section-subtitle"]?.["background-color"]}`,
      "background-color": settingFormData[
        ".blog-section .section-title-area .section-subtitle"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".blog-section .section-title-area .section-subtitle"
              ]?.["background-color"],
          })
        : "",
      padding: `${settingFormData[
        ".blog-section .section-title-area .section-subtitle"
      ]?.["p1"]?.replace("px", "")}px ${settingFormData?.[
        ".blog-section .section-title-area .section-subtitle"
      ]?.["p2"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData[
        ".blog-section .section-title-area .section-subtitle"
      ]?.["border-radius"]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData[
        ".blog-section .section-title-area .section-subtitle"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".blog-section .section-title-area .section-title": {
      ...settingFormData[".blog-section .section-title-area .section-title"],
      "font-size": `${settingFormData[
        ".blog-section .section-title-area .section-title"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".blog-section .section-title-area .section-title"]?.["font-weight"]}`,
      color: settingFormData[
        ".blog-section .section-title-area .section-title"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".blog-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".blog_items .blog_thumb": {
      "border-radius": `${settingFormData?.[".blog_items .blog_thumb"]?.[
        "border-radius"
      ]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData?.[".blog_items .blog_thumb"]?.[
        "margin-bottom"
      ]?.replace("px", "")}px`,
    },
    ".blog_items .blog_thumb img": {
      ...settingFormData?.[".blog_items .blog_thumb img"],
      "border-radius": `${settingFormData?.[".blog_items .blog_thumb img"]?.[
        "border-radius"
      ]?.replace("px", "")}px`,
    },
    ".blog_items .blog_content .blog_meta": {
      ...settingFormData?.[".blog_items .blog_content .blog_meta"],
      "margin-bottom": `${settingFormData?.[
        ".blog_items .blog_content .blog_meta"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".blog_items .blog_content .blog_meta .date": {
      ...settingFormData?.[".blog_items .blog_content .blog_meta .date"],
      color: settingFormData[".blog_items .blog_content .blog_meta .date"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".blog_items .blog_content .blog_meta .date"]?.[
                "color"
              ],
          })
        : "",
    },
    ".blog_items .blog_content .blog_meta .posted": {
      ...settingFormData?.[".blog_items .blog_content .blog_meta .posted"],
      color: settingFormData[".blog_items .blog_content .blog_meta .posted"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".blog_items .blog_content .blog_meta .posted"]?.[
                "color"
              ],
          })
        : "",
      "font-size": `${settingFormData?.[
        ".blog_items .blog_content .blog_meta .posted"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[
        ".blog_items .blog_content .blog_meta .posted"
      ]?.["font-weight"]?.replace("px", "")}`,
      padding: `${settingFormData?.[
        ".blog_items .blog_content .blog_meta .posted"
      ]?.["p1"]?.replace("px", "")}px ${settingFormData?.[
        ".blog_items .blog_content .blog_meta .posted"
      ]?.["p2"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData?.[
        ".blog_items .blog_content .blog_meta .posted"
      ]?.["border-radius"]?.replace("px", "")}px`,

      "background-color": settingFormData[
        ".blog_items .blog_content .blog_meta .posted"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".blog_items .blog_content .blog_meta .posted"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".blog_items .blog_content .blog_title": {
      ...settingFormData?.[".blog_items .blog_content .blog_title"],
      "font-size": `${settingFormData[
        ".blog_items .blog_content .blog_title"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".blog_items .blog_content .blog_title"]?.["font-weight"]}`,
      "margin-bottom": `${settingFormData[
        ".blog_items .blog_content .blog_title"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".blog_items .blog_content .blog_title a": {
      ...settingFormData?.[".blog_items .blog_content .blog_title a"],
      color: settingFormData[".blog_items .blog_content .blog_title a"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".blog_items .blog_content .blog_title a"]?.[
                "color"
              ],
          })
        : "",
    },
    ".blog_items .blog_content .blog_title a:hover": {
      ...settingFormData?.[".blog_items .blog_content .blog_title a:hover"],
      color: settingFormData[".blog_items .blog_content .blog_title a:hover"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".blog_items .blog_content .blog_title a:hover"
              ]?.["color"],
          })
        : "",
    },
    ".blog_items .blog_content .blog_desq": {
      ...settingFormData?.[".blog_items .blog_content .blog_desq"],
      color: settingFormData[".blog_items .blog_content .blog_desq"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".blog_items .blog_content .blog_desq"]?.[
                "color"
              ],
          })
        : "",

      "font-size": `${settingFormData[".blog_items .blog_content .blog_desq"]?.[
        "font-size"
      ]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".blog_items .blog_content .blog_desq"]?.["font-weight"]}`,
      "line-height": `${settingFormData?.[".blog_items .blog_content .blog_desq"]?.["line-height"]}`,
      "margin-bottom": `${settingFormData[
        ".blog_items .blog_content .blog_desq"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
      "text-align": `${settingFormData?.[".blog_items .blog_content .blog_desq"]?.["text-align"]}`,
    },
    ".blog_items .blog_content .link-btn": {
      ...settingFormData?.[".blog_items .blog_content .link-btn"],
      color: settingFormData[".blog_items .blog_content .link-btn"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".blog_items .blog_content .link-btn"]?.["color"],
          })
        : "",
      "background-color": settingFormData[
        ".blog_items .blog_content .link-btn"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".blog_items .blog_content .link-btn"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".service-detail-section": boxSectionDetail
      ? {
          ...settingFormData?.[".service-detail-section"],
          padding: `${settingFormData?.[".service-detail-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
          width: `calc(100% - ${settingFormData?.[".service-detail-section"]?.[
            "width"
          ]?.replace("rem)", "")}rem)`,
          margin: `${settingFormData?.[".service-detail-section"]?.["margin"]
            ?.split(" ")[0]
            ?.replace("px", "")}px auto`,
          "border-radius": `${settingFormData?.[".service-detail-section"]?.[
            "border-radius"
          ]?.replace("rem", "")}rem`,
        }
      : {
          ...settingFormData?.[".service-detail-section"],
          padding: `${settingFormData?.[".service-detail-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
        },
    ".service-detail-section.bg-image": settingFormData?.[
      ".service-detail-section.bg-image"
    ]?.["background-image"]
      ? {
          ...settingFormData?.[".service-detail-section.bg-image"],
          "background-image":
            settingFormData?.[".service-detail-section.bg-image"]?.[
              "background-image"
            ] !== ""
              ? addUrlImage({
                  urlString:
                    settingFormData?.[".service-detail-section.bg-image"]?.[
                      "background-image"
                    ],
                })
              : "",
          position: "relative",
          "background-size": "cover",
          "background-repeat": "no-repeat",
          "background-position": "center center",
        }
      : {},
    ".service-detail-section.bg-image::before": settingFormData?.[
      ".service-detail-section.bg-image::before"
    ]?.["background-color"]
      ? {
          ...settingFormData?.[".service-detail-section.bg-image::before"],
          content: "",
          "background-color": settingFormData?.[
            ".service-detail-section.bg-image::before"
          ]?.["background-color"]
            ? rgbColorCreate({
                rgbString:
                  settingFormData?.[
                    ".service-detail-section.bg-image::before"
                  ]?.["background-color"],
              })
            : "",
          position: "absolute",
          top: "0",
          bottom: "0",
          right: "0",
          left: "0",
        }
      : {},
    ".personal-details .details-title": {
      ...settingFormData?.[".personal-details .details-title"],
      "font-weight": `${settingFormData?.[".personal-details .details-title"]?.["font-weight"]}`,
      "font-size": `${settingFormData?.[".personal-details .details-title"]?.[
        "font-size"
      ]?.replace("px", "")}px`,
    },
    ".personal-details p": {
      ...settingFormData?.[".personal-details p"],
      "margin-bottom": `${settingFormData?.[".personal-details p"]?.[
        "margin-bottom"
      ]?.replace("px", "")}px`,
      "line-height": `${settingFormData?.[".personal-details p"]?.["line-height"]}`,
      "font-size": `${settingFormData?.[".personal-details p"]?.[
        "font-size"
      ]?.replace("px", "")}px`,
    },
    ".personal-details .info-list": {
      "margin-bottom": `${settingFormData?.[".personal-details .info-list"]?.[
        "margin-bottom"
      ]?.replace("px", "")}px`,
    },
    ".personal-details .info-list li": {
      ...settingFormData?.[".personal-details .info-list li"],
      "margin-bottom": `${settingFormData?.[
        ".personal-details .info-list li"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
      "font-size": `${settingFormData?.[".personal-details .info-list li"]?.[
        "font-size"
      ]?.replace("px", "")}px`,
    },
    ".personal-details .order-list": {
      "margin-bottom": `${settingFormData?.[".personal-details .order-list"]?.[
        "margin-bottom"
      ]?.replace("px", "")}px`,
    },
    ".personal-details .order-list li": {
      ...settingFormData?.[".personal-details .order-list li"],
      "padding-left": `${settingFormData?.[
        ".personal-details .order-list li"
      ]?.["padding-left"]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData?.[
        ".personal-details .order-list li"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
      "font-size": `${settingFormData?.[".personal-details .order-list li"]?.[
        "font-size"
      ]?.replace("px", "")}px`,
    },
    ".personal-details .order-list li:before": {
      ...settingFormData?.[".personal-details .order-list li:before"],
      "font-family": `${settingFormData?.[".personal-details .order-list li:before"]?.["font-family"]}`,
      top: `${settingFormData?.[".personal-details .order-list li:before"]?.[
        "top"
      ]?.replace("px", "")}px`,
      color: settingFormData?.[".personal-details .order-list li:before"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".personal-details .order-list li:before"]?.[
                "color"
              ],
          })
        : "",
    },
    ".side_thumb img": {
      ...settingFormData?.[".side_thumb img"],
      height: `${settingFormData?.[".side_thumb img"]?.["height"]?.replace(
        "px",
        ""
      )}px`,
      width: `${settingFormData?.[".side_thumb img"]?.["width"]?.replace(
        "px",
        ""
      )}px`,
      "border-radius": `${settingFormData?.[".side_thumb img"]?.[
        "border-radius"
      ]?.replace("px", "")}px`,
    },
    ".side_content .side_meta": {
      ...settingFormData?.[".side_content .side_meta"],
      "font-size": `${settingFormData?.[".side_content .side_meta"]?.[
        "font-size"
      ]?.replace("px", "")}px`,
      color: settingFormData?.[".side_content .side_meta"]?.["color"]
        ? rgbColorCreate({
            rgbString: settingFormData?.[".side_content .side_meta"]?.["color"],
          })
        : "",
      "font-weight": `${settingFormData?.[".side_content .side_meta"]?.["font-weight"]}`,
      "margin-bottom": `${settingFormData?.[".side_content .side_meta"]?.[
        "margin-bottom"
      ]?.replace("px", "")}px`,
    },
    ".side_content .side_title": {
      ...settingFormData?.[".side_content .side_title"],
      "font-size": `${settingFormData?.[".side_content .side_title"]?.[
        "font-size"
      ]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".side_content .side_title"]?.["font-weight"]}`,
      color: settingFormData?.[".side_content .side_title"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".side_content .side_title"]?.["color"],
          })
        : "",
      "line-height": `${settingFormData?.[".side_content .side_title"]?.["line-height"]}`,
    },
    ".side_content .side_title a": {
      ...settingFormData?.[".side_content .side_title a"],
      color: settingFormData?.[".side_content .side_title a"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".side_content .side_title a"]?.["color"],
          })
        : "",
    },
  };
  return newData;
};
