import React, { useContext } from "react";
import { Col, Input, Label, Row } from "reactstrap";
import { Border, TextAlign, fontWeight } from "../../../../constant";
import ServiceAndCategoryContext from "../../../context/ServiceAndCatagoryContext/ServiceAndCategoryContext";
import { Cases, DELETE_CONFIRM } from "../../../constant";
import { delete_Multi_Image_Permanent } from "../apis/SliderApi/SliderApi";
import { Zoom, toast } from "react-toastify";
import Switch from "@material-ui/core/Switch";
import { Close } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { UploadCloud } from "react-feather";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import Dividers from "../../../common/divider";
import { OpenModalCommonFunc } from "../../../utils";
import ServiceCategoryDetailStyle from "./ServiceCategoryDetailStyle";
import PaginationButtonStyle from "../../../common/paginationButtonStyle";
import CommonColor from "../../../common/CommonColor";
import CustomStyle from "../../../common/CustomStyle";

const ServiceThemeFourteen = (props) => {
  const {
    isEdit,
    settingFormData,
    setSettingFormData,
    backImageToggle,
    setBackImageToggle,
    boxSection,
    setBoxSection,
    setIsImageUpload,
    isBackgroundColor,
    setIsBackgroundColor,
  } = useContext(ServiceAndCategoryContext);
  const { setLoaderShow } = useContext(LoaderContext);
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);

  const { imageUpload } = props;

  const handleChangeServiceSection = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".service-section": {
          ...prev[".service-section"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeTableListHead = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".table-list-wrapper .table-list .list-head": {
          ...prev[".table-list-wrapper .table-list .list-head"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeSectionTitleAreaSubtitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".table-list-wrapper .table-list .list-head h2": {
          ...prev[".table-list-wrapper .table-list .list-head h2"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeSectionTitleAreaSectionTitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".table-list-wrapper .table-list .body-ttl": {
          ...prev[".table-list-wrapper .table-list .body-ttl"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeSectionListBodyUlLi = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".table-list-wrapper .table-list .list-body ul li": {
          ...prev[".table-list-wrapper .table-list .list-body ul li"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeSectionListBody = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".table-list-wrapper .table-list .list-body": {
          ...prev[".table-list-wrapper .table-list .list-body"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeSectionLIstBodyUl = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".table-list-wrapper .table-list .list-body ul": {
          ...prev[".table-list-wrapper .table-list .list-body ul"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeCustomStyle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  /**
   * delete image permanently
   */
  const deleteImagePermanent = async () => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData = {
          fileName: settingFormData?.[".service-section.bg-image"]?.[
            "background-image"
          ]
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
            .pop(),
        };
        const { data } = await delete_Multi_Image_Permanent(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            position: "top-center",
          });
          setSettingFormData({
            ...settingFormData,
            ".latest-news-section.bg-image": { "background-image": "" },
          });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };
  /**
   * image reupload
   */
  const reupload = () => {
    setSettingFormData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };

  return (
    <>
      <Row className="mt-2">
        <h4 className="mb-3">Make Change in List head</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin Bottom (Default : 30)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin Bottom"
            value={settingFormData?.[
              ".table-list-wrapper .table-list .list-head"
            ]?.["margin-bottom"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTableListHead(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Padding (Default : 10)"}</Label>
          <Input
            className="form-control"
            name="padding"
            type="number"
            placeholder="Margin Bottom"
            value={settingFormData?.[
              ".table-list-wrapper .table-list .list-head"
            ]?.["padding"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTableListHead(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Border"}</Label>
          <div className="d-flex ">
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="borderPx"
                type="number"
                placeholder="px"
                value={settingFormData?.[
                  ".table-list-wrapper .table-list .list-head"
                ]?.["borderPx"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeTableListHead(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <select
                name="borderThik"
                id="borderThik"
                className="form-control"
                value={
                  settingFormData?.[
                    ".table-list-wrapper .table-list .list-head"
                  ]?.["borderThik"]
                }
                onChange={(e) => handleChangeTableListHead(e)}
                style={{ borderRadius: "0", color: "black" }}
              >
                <option value="">--select--</option>
                {Border.map((elem) => {
                  return <option value={elem.border}>{elem.border}</option>;
                })}
              </select>
            </div>
            <div>
              <CommonColor
                color={
                  settingFormData?.[
                    ".table-list-wrapper .table-list .list-head"
                  ]?.["borderColor"]
                }
                setSettingFormData={setSettingFormData}
                className={".table-list-wrapper .table-list .list-head"}
                classProperty={"borderColor"}
                label={"Border Color"}
              />
            </div>
          </div>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".table-list-wrapper .table-list .list-head"]?.[
                "background-color"
              ]
            }
            setSettingFormData={setSettingFormData}
            className={".table-list-wrapper .table-list .list-head"}
            classProperty={"background-color"}
            label={"Select background-color"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in section List Head h2</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 36)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".table-list-wrapper .table-list .list-head h2"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeSectionTitleAreaSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".table-list-wrapper .table-list .list-head h2"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".table-list-wrapper .table-list .list-head h2"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in section Body Title</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"padding (Default : 10)"}</Label>
          <Input
            className="form-control"
            name="padding"
            type="number"
            placeholder="padding"
            value={settingFormData?.[
              ".table-list-wrapper .table-list .body-ttl"
            ]?.["padding"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeSectionTitleAreaSectionTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin-bottom (Default : 16)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="margin-bottom"
            value={settingFormData?.[
              ".table-list-wrapper .table-list .body-ttl"
            ]?.["margin-bottom"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeSectionTitleAreaSectionTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Border"}</Label>
          <div className="d-flex ">
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="borderPx"
                type="number"
                placeholder="px"
                value={settingFormData?.[
                  ".table-list-wrapper .table-list .body-ttl"
                ]?.["borderPx"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeSectionTitleAreaSectionTitle(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <select
                name="borderThik"
                id="borderThik"
                className="form-control"
                value={
                  settingFormData?.[
                    ".table-list-wrapper .table-list .body-ttl"
                  ]?.["borderThik"]
                }
                onChange={(e) => handleChangeSectionTitleAreaSectionTitle(e)}
                style={{ borderRadius: "0", color: "black" }}
              >
                <option value="">--select--</option>
                {Border.map((elem) => {
                  return <option value={elem.border}>{elem.border}</option>;
                })}
              </select>
            </div>
            <div>
              <CommonColor
                color={
                  settingFormData?.[
                    ".table-list-wrapper .table-list .body-ttl"
                  ]?.["borderColor"]
                }
                setSettingFormData={setSettingFormData}
                className={".table-list-wrapper .table-list .body-ttl"}
                classProperty={"borderColor"}
                label={"Border Color"}
              />
            </div>
          </div>
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in Table list body</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Border"}</Label>
          <div className="d-flex ">
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="borderPx"
                type="number"
                placeholder="px"
                value={settingFormData?.[
                  ".table-list-wrapper .table-list .list-body"
                ]?.["borderPx"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeSectionListBody(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <select
                name="borderThik"
                id="borderThik"
                className="form-control"
                value={
                  settingFormData?.[
                    ".table-list-wrapper .table-list .list-body"
                  ]?.["borderThik"]
                }
                onChange={(e) => handleChangeSectionListBody(e)}
                style={{ borderRadius: "0", color: "black" }}
              >
                <option value="">--select--</option>
                {Border.map((elem) => {
                  return <option value={elem.border}>{elem.border}</option>;
                })}
              </select>
            </div>
            <div>
              <CommonColor
                color={
                  settingFormData?.[
                    ".table-list-wrapper .table-list .list-body"
                  ]?.["borderColor"]
                }
                setSettingFormData={setSettingFormData}
                className={".table-list-wrapper .table-list .list-body"}
                classProperty={"borderColor"}
                label={"Border Color"}
              />
            </div>
          </div>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 8 - 16 - 24)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="p1"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".table-list-wrapper .table-list .list-body"
              ]?.["p1"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeSectionListBody(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p2"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".table-list-wrapper .table-list .list-body"
              ]?.["p2"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeSectionListBody(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p3"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".table-list-wrapper .table-list .list-body"
              ]?.["p3"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeSectionListBody(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in body list ul</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font-size (Default : 18)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="font-size"
            value={settingFormData?.[
              ".table-list-wrapper .table-list .list-body ul"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeSectionLIstBodyUl(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding-left (Default : 20)"}
          </Label>
          <Input
            className="form-control"
            name="padding-left"
            type="number"
            placeholder="padding-left"
            value={settingFormData?.[
              ".table-list-wrapper .table-list .list-body ul"
            ]?.["padding-left"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeSectionLIstBodyUl(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in body list ul li</h4>
        <Col md="6 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 8 - 16 - 24)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="p1"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".table-list-wrapper .table-list .list-body ul li"
              ]?.["p1"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeSectionListBodyUlLi(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p2"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".table-list-wrapper .table-list .list-body ul li"
              ]?.["p2"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeSectionListBodyUlLi(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p3"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".table-list-wrapper .table-list .list-body ul li"
              ]?.["p3"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeSectionListBodyUlLi(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p4"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".table-list-wrapper .table-list .list-body ul li"
              ]?.["p4"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeSectionListBodyUlLi(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in Body list ul li a</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".table-list-wrapper .table-list .list-body ul li a"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".table-list-wrapper .table-list .list-body ul li a"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
      </Row>
      <hr />
      <ServiceCategoryDetailStyle />
      <hr />
      <PaginationButtonStyle
        mainClass={".table-list-wrapper"}
        settingFormData={settingFormData}
        setSettingFormData={setSettingFormData}
      />
      <CustomStyle
        handleChange={handleChangeCustomStyle}
        value={settingFormData?.custom}
      />
      <ProductImageUploadPopup
        allPopupState={allPopupState}
        setAllPopupState={setAllPopupState}
        crop={crop}
        setCrop={setCrop}
        completedCrop={completedCrop}
        setCompletedCrop={setCompletedCrop}
        scale={scale}
        rotate={rotate}
        image={settingFormData?.image}
        setImage={setSettingFormData}
        imageUpload={imageUpload}
        isEdit={isEdit}
        reupload={reupload}
        // aspect={1.93 / 1}
      />
    </>
  );
};

export default ServiceThemeFourteen;
