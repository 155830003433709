import axios from "axios";
import {
  GET_ITEM_FEATURE,
  GET_ITEM_FEATURE_DETAILS,
  POST_ITEM_FEATURE,
  PUT_ITEM_FEATURE_ORDER,
  PUT_STATUS_ITEM_FEATURE,
} from "../../apiConstants";

/**
 * get item feature
 * @param {Parameters} paramObj
 * @returns
 */
export const getItemFeature = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_ITEM_FEATURE}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * enable-disable item feature
 * @param {body} body
 * @returns
 */
export const updateStatus = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_STATUS_ITEM_FEATURE}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * create item feature
 * @param {body} body
 * @returns
 */
export const postItemFeature = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${POST_ITEM_FEATURE}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * update item feature
 * @param {body} body
 * @returns
 */
export const putUpdateItemFeature = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${POST_ITEM_FEATURE}`,
    body,
    {
      headers: { Authorization: jwt_token },
      params: { itemFeatureId: body.itemFeatureId },
    }
  );
};

/**
 * delete item feature
 * @param {id} itemFeatureId
 * @returns
 */
export const deleteItemFeature = (itemFeatureId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${POST_ITEM_FEATURE}`, {
    headers: { Authorization: jwt_token },
    params: { itemFeatureId },
  });
};

/**
 * get item feature
 * @param {id} itemFeatureId
 * @returns
 */
export const getItemFeatureDetails = (itemFeatureId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${GET_ITEM_FEATURE_DETAILS}`,
    {
      headers: { Authorization: jwt_token },
      params: { itemFeatureId },
    }
  );
};

/**
 * short order item feature
 * @param {body} updateOrder
 * @returns
 */
export const sort_order_item_feature = (updateOrder) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_ITEM_FEATURE_ORDER}`,
    { updateOrder: updateOrder },
    {
      headers: { Authorization: jwt_token },
    }
  );
};
