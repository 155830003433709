import { IconButton } from "@mui/material";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import StarIcon from "@mui/icons-material/Star";
import { Switch } from "@material-ui/core";
import { Settings, Trash } from "react-feather";

const ContactUSCart = (props) => {
  const { name, address } = props;
  return (
    <div className="prooduct-details-box">
      <div className="media">
        <div className="media-body ms-3">
          <div className="product-name">
            <h6>
              <a href="#javascript">{name}</a>
            </h6>
          </div>
          <div className="price d-flex">
            <div className="text-muted me-2">{address}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUSCart;

export const ContactUsAddressCart = (props) => {
  const {
    name,
    address,
    phone,
    email,
    handleEdit,
    handleDelete,
    defaultStar,
    defaultSet,
    checked,
    handleChangeStatus,
    handleChangeSettings,
    isMulti = false,
  } = props;
  return (
    <div className="prooduct-details-box">
      <div className="media">
        <div className="media-body ms-3">
          <div className="product-name mb-1 d-flex justify-content-between align-items-center ">
            <h6>
              <a href="#javascript" style={{ color: "black" }}>
                {name}
              </a>
            </h6>
            <IconButton
              edge="start"
              color="inherit"
              style={{
                marginLeft: "5px",
                color: defaultSet ? "#ff9800" : "#c1c1c1",
              }}
              onClick={defaultStar}
            >
              <StarIcon />
            </IconButton>
          </div>
          <div className="price d-flex">
            <div className="text-muted me-2">{address}</div>
          </div>
          <div className="price d-flex mt-3 mb-1 ">
            <div className="text-muted me-2">
              <i className="icofont icofont-ui-call"></i>
            </div>
            <div className="text-muted me-2">
              <p>{phone}</p>
            </div>
          </div>
          <div className="price d-flex">
            <div className="text-muted me-2">
              <i className="icofont icofont-ui-email"></i>
            </div>
            <div className="text-muted me-2">
              <p>{email}</p>
            </div>
          </div>
          <div className="price d-flex justify-content-end align-items-center ">
            <Switch
              checked={checked === true}
              inputProps={{
                "aria-label": "controlled",
              }}
              color="primary"
              onChange={handleChangeStatus}
            />
            <IconButton
              edge="start"
              color="inherit"
              style={{ marginLeft: "5px" }}
              onClick={handleDelete}
            >
              <Trash />
            </IconButton>
            <IconButton
              edge="start"
              color="inherit"
              style={{ marginLeft: "5px" }}
              onClick={handleEdit}
            >
              <EditIcon />
            </IconButton>
            {isMulti && (
              <IconButton
                edge="start"
                color="inherit"
                style={{ marginLeft: "5px" }}
                onClick={handleChangeSettings}
              >
                <Settings />
              </IconButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
