import React, { useContext, useMemo, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import { Cases, btnName, modalsName } from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import CommonPopup from "../../../Modals/commonPopup";
import DownloadPopup from "./downloadPopup";
import ByteMiniWebDashboardContext from "../../../context/ByteMiniWeb_Dashboard_Context/ByteMiniWeb_Dashboard_Context";
import DownloadsContext from "../../../context/DownloadesContext/DownloadsContext";
import {
  getDetailsDownload,
  getDownload,
  postDownload,
  putUpdateDownload,
  updateStatus,
} from "../../../apis/DownloadsApi/DownloadApi";
import { FILE_UPLOAD_API } from "../../../apis/SliderApi/SliderApi";
import { FILE_TYPE, titleUpdateKey } from "../../../../constant";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import SimpleReactValidator from "simple-react-validator";

const Downloads = (props) => {
  const { webMasterDetail, navbarData } = props;
  const { apiData } = useContext(ByteMiniWebDashboardContext);
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );
  const { setLoaderShow } = useContext(LoaderContext);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const {
    apiData: downloadData,
    setApiData,
    fromData,
    setFormData,
    id,
    setId,
    setIsEdit,
    isEdit,
    filter,
  } = useContext(DownloadsContext);
  const [open, setOpen] = useState(false);

  /**
   * get slider list
   */
  const getDownloadList = async (status) => {
    try {
      if (!filter.length && status) {
        setApiData((prev) => {
          return { ...prev, isLoading: true };
        });
      }
      let paramObj = {
        search: { title: filter },
        showAll: true,
      };
      const { data } = await getDownload(paramObj);
      if (data.status === 200) {
        setApiData({
          ...downloadData,
          isLoading: false,
          data: data.payload.data.map((elem) => {
            return {
              id: elem.title + elem.downloadId,
              Id: elem.downloadId,
              name: elem.title,
              filePath: elem.filePath,
              webId: elem.webId,
              createdAt: elem.createdAt,
              isActive: elem.isActive,
            };
          }),
        });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  useMemo(() => {
    if (allPopupState?.downloadsPopup) {
      getDownloadList();
    }
  }, [filter, allPopupState?.downloadsPopup]);

  /**
   * enable disable api call
   * @param {download id} downloadId
   * @param {status} isActive
   */

  const handleChangeStatus = async (downloadId, isActive) => {
    try {
      let body = {
        downloadId,
        isActive: isActive ? false : true,
        navMapId: navbarData[0].navMapId,
      };
      const { data } = await updateStatus(body);
      if (data.status === 200) {
        getDownloadList(false);
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * upload image
   * @param {file} file
   */
  const imageUpload = async (file) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.DOWNLOADE);
    formData.append("file", file);
    setLoaderShow(true);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        setLoaderShow(false);
        return {
          imageUrl: data.payload.imageUrl,
        };
      }
    } catch (error) {
      setLoaderShow(false);
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * slider create and update
   */

  const handleSubmit = async (fileInput) => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      if (id >= -1 && isEdit) {
        setLoaderShow(true);
        try {
          let newObj = { ...fromData, navMapId: navbarData[0].navMapId };
          if (fromData.filePath && fromData?.filePath?.name) {
            let { imageUrl } = await imageUpload(fromData.filePath);
            setFormData((prev) => {
              return {
                ...prev,
                filePath: imageUrl,
              };
            });
            newObj["filePath"] = imageUrl;
          } else {
            newObj["filePath"] = fromData.filePath
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
              .pop();
          }
          const { data } = await putUpdateDownload(newObj);
          if (data.status === 200) {
            getDownloadList();
            emptyData(fileInput);
            TostMessage({ message: data, type: "success" });
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      } else {
        try {
          setLoaderShow(true);
          let newObj = {
            ...fromData,
            navMapId: navbarData[0].navMapId,
            order: downloadData.data.length + 1,
          };
          if (fromData.filePath) {
            let { imageUrl } = await imageUpload(fromData.filePath);
            setFormData((prev) => {
              return {
                ...prev,
                filePath: imageUrl,
                order: downloadData.data.length + 1,
              };
            });
            newObj["filePath"] = imageUrl;
          }

          const { data } = await postDownload(newObj);
          if (data.status === 200) {
            getDownloadList();
            emptyData(fileInput);
            TostMessage({ message: data, type: "success" });
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      }
    }
  };

  /**
   * get slider details
   * @param {id} downloadId
   */
  const handleEdit = async (downloadId) => {
    try {
      setLoaderShow(true);
      setId(downloadId);
      const { data } = await getDetailsDownload(downloadId);
      if (data.status === 200) {
        let payload = data.payload.data;
        setFormData({
          title: payload.title,
          filePath: payload.filePath,
          downloadId: downloadId,
          webId: localStorage.getItem("webId"),
        });
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * data clear
   */
  const emptyData = (fileInput) => {
    setId("");
    setIsEdit(false);
    setFormData({
      title: "",
      filePath: "",
      webId: localStorage.getItem("webId"),
    });
    setLoaderShow(false);
    if (fileInput) {
      fileInput.value = null;
    }
  };

  const filterValue = () => {
    let [value] = navbarData.filter(
      (elem) => elem.navbarDetail.label === titleUpdateKey.Downloads
    );
    return value;
  };

  const value = useMemo(() => filterValue(), [navbarData]);

  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody className="support-ticket-font box-border-div">
            <Row>
              <CardBuilderHeaderSection
                name={`${apiData?.data?.download?.length} ${
                  value?.label || "Downloads"
                }`}
              />
              {apiData?.data?.download.slice(0, 4).map((elem) => {
                return (
                  <Col xl="3 xl-50 box-col-6" md="6" key={elem.title}>
                    <Card className="ecommerce-widget">
                      <CardBody className="support-ticket-font">
                        <div className="col-12">
                          <CardBuilderHeaderSection name={` ${elem.title}`} />
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => setOpen(!open)}
                          >
                            <div
                              style={{
                                objectFit: "contain",
                                maxWidth: "125px",
                                height: "83px",
                                border: "1px solid #d3d3d3",
                                width: "100%",
                                display: "inline-block",
                                verticalAlign: "middle",
                                overflow: "hidden",
                                position: "relative",
                              }}
                            >
                              <div
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  right: "0",
                                  bottom: "0",
                                  left: "0",
                                  borderRadius: "inherit",
                                }}
                              >
                                <img
                                  src={require("./downloadImg.6c152e67.png")}
                                  alt=""
                                  style={{
                                    objectPosition: "50% 50%",
                                    objectFit: "contain",
                                    opacity: "1",
                                    transition: "opacity 0.28s ease-in",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
              <OpenPopupBtn
                openPopup={() =>
                  OpenModalCommonFunc(Cases.DOWNLOADS, setAllPopupState)
                }
                name={btnName.DOWNLOADS}
              />
            </Row>
          </CardBody>
        </Card>
      </Col>

      <CommonPopup
        open={allPopupState.downloadsPopup}
        close={() =>
          OpenModalCommonFunc(Cases.DOWNLOADS, setAllPopupState, false)
        }
        isMini={true}
        modalName={modalsName.DOWNLOADS}
        modalCase={Cases.DOWNLOADS}
        edit={true}
        oldTitle={titleUpdateKey.isDownload}
        label={titleUpdateKey.Downloads}
        handleSubmit={() => handleSubmit(document.getElementById("fileInput"))}
        emptyData={emptyData}
        inputData={fromData}
        content={
          <DownloadPopup
            handleChangeStatus={handleChangeStatus}
            handleSubmit={handleSubmit}
            getData={getDownloadList}
            handleEdit={handleEdit}
            imageUpload={imageUpload}
            emptyData={emptyData}
            simpleValidator={simpleValidator}
            webMasterDetail={webMasterDetail}
          />
        }
      />
    </>
  );
};

export default Downloads;
