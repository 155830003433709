import React, { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const SettingMultiWebAddChildItem = ({
  navMap,
  handleUp,
  handleDown,
  intoUpItem,
  intoDownItem,
  isChild,
  parentIndex,
  isLast = false,
}) => {
  const [activeNav, setActiveNav] = useState("");

  const hasMoreThanThreeLevels = (child, depth = 1) => {
    if (depth > 2) {
      return true;
    }
    if (child?.childIds.length === 0) {
      return false;
    }
    return child?.childIds.some((grandChild) =>
      hasMoreThanThreeLevels(grandChild, depth + 1)
    );
  };

  return (
    <>
      {navMap?.map((ele, index) => {
        const isThirdLevel = hasMoreThanThreeLevels(ele);
        return (
          <div
            key={index}
            className={`border p-2 m-1 ${
              activeNav === ele?.navMapId ? "bg-secondary" : ""
            }`}
            onClick={() => setActiveNav(ele?.navMapId)}
          >
            <div className="d-flex justify-content-between">
              <div>{ele?.label}</div>

              <div className="d-flex gap-2 justify-content-between">
                {index > 0 && (
                  <div
                    onClick={() => {
                      handleUp({
                        index,
                        navId: ele.navId,
                        isChild,
                        parentIndex,
                      });
                      setActiveNav(ele.navMapId);
                    }}
                  >
                    <ArrowDropUpIcon />
                  </div>
                )}
                {index < navMap.length - 1 && (
                  <div
                    onClick={() => {
                      handleDown({
                        index,
                        navId: ele.navId,
                        isChild,
                        parentIndex,
                      });
                      setActiveNav(ele.navMapId);
                    }}
                  >
                    <ArrowDropDownIcon />
                  </div>
                )}
                {/* index != 0 */}
                {index !== 0 && (
                  <div
                    onClick={() => {
                      if (!isThirdLevel) {
                        intoUpItem({
                          index,
                          navId: ele.navId,
                          isChild,
                          parentIndex,
                          isLast,
                        });
                        setActiveNav(ele.navMapId);
                      }
                    }}
                  >
                    {!isThirdLevel && <ArrowUpwardIcon />}
                  </div>
                )}

                {isChild && (
                  <div
                    onClick={() => {
                      intoDownItem({
                        index,
                        navId: ele.navId,
                        isChild,
                        parentIndex,
                      });
                      setActiveNav(ele.navMapId);
                    }}
                  >
                    <ArrowDownwardIcon />
                  </div>
                )}
              </div>
            </div>
            {isThirdLevel
              ? ele?.childIds?.length > 0 && (
                  <SettingMultiWebAddChildItem
                    navMap={ele.childIds}
                    handleUp={handleUp}
                    handleDown={handleDown}
                    intoUpItem={intoUpItem}
                    intoDownItem={intoDownItem}
                    isChild={true}
                    parentIndex={index}
                    isLast={isThirdLevel}
                  />
                  // <p>--</p>
                )
              : ele?.childIds?.length > 0 && (
                  <SettingMultiWebAddChildItem
                    navMap={ele.childIds}
                    handleUp={handleUp}
                    handleDown={handleDown}
                    intoUpItem={intoUpItem}
                    intoDownItem={intoDownItem}
                    isChild={true}
                    parentIndex={index}
                  />
                )}
          </div>
        );
      })}
    </>
  );
};

export default SettingMultiWebAddChildItem;
