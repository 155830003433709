import { IconButton, Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import { Edit } from "react-feather";
import { Zoom, toast } from "react-toastify";
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { WebNavBarConfigListUpdate } from "../components/MultipageByteCardBuilder/apis/NavApi/NavApi";

const EditSectionName = ({ pageId, ByteMiniWeb_Dashboard }) => {
  const [editPopup, setEditPopup] = useState(false);
  const [newValue, setNewValue] = useState("");

  const CloseModel = () => {
    setNewValue("");
    setEditPopup(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let paramObj = {
        label: newValue,
      };
      const { data } = await WebNavBarConfigListUpdate(paramObj, pageId);
      if (data.status === 200) {
        ByteMiniWeb_Dashboard();
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        CloseModel();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setNewValue(value);
  };

  return (
    <>
      <div>
        <Tooltip title="Edit Section Name">
          <IconButton
            edge="start"
            color="inherit"
            style={{ margin: "5px" }}
            onClick={() => setEditPopup(true)}
          >
            <Edit style={{ color: "green" }} />
          </IconButton>
        </Tooltip>
      </div>
      {editPopup && (
        <Modal
          size="sm"
          isOpen={editPopup}
          toggle={() => CloseModel()}
          centered
        >
          <ModalHeader toggle={() => CloseModel()}>{"Edit Label"}</ModalHeader>
          <ModalBody>
            <Container fluid={true} className="p-0">
              <Row>
                <Form className="theme-form" onSubmit={(e) => handleSubmit(e)}>
                  <Row>
                    <Col md="12 mb-12">
                      <Label>{"Label *"}</Label>
                      <Input
                        className="form-control"
                        type={"text"}
                        style={{ color: "black" }}
                        name="label"
                        onChange={(e) => handleChange(e)}
                        value={newValue}
                        placeholder="Title"
                        required
                      />
                    </Col>
                  </Row>
                  <Button
                    color="primary"
                    type="submit"
                    value="submit"
                    className="mt-2 w-100"
                  >
                    {"Submit"}
                  </Button>
                </Form>
              </Row>
            </Container>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default EditSectionName;
