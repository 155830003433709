import axios from "axios";
import {
  GET_SERVICE_,
  GET_SERVICE_CATEGORY,
  GET_SERVICE_CATEGORY_DETAILS,
  GET_SERVICE__DETAILS,
  POST_SERVICE_,
  POST_SERVICE_CATEGORY,
  PUT_SERVICE_CATEGORY_ORDER,
  PUT_SERVICE__ORDER,
  PUT_STATUS_SERVICE_,
  PUT_STATUS_SERVICE_CATEGORY,
} from "..";

/**
 * get service data
 * @param {Parameters} paramObj
 * @returns
 */
export const getServiceApi = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_SERVICE_}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * enable-disable service
 * @param {body} body
 * @returns
 */
export const updateStatus = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_STATUS_SERVICE_}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * create service
 * @param {body} body
 * @returns
 */
export const postService = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(`${process.env.REACT_APP_API_URL}${POST_SERVICE_}`, body, {
    headers: { Authorization: jwt_token },
  });
};

/**
 * update service
 * @param {body} body
 * @returns
 */
export const putUpdateService = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(`${process.env.REACT_APP_API_URL}${POST_SERVICE_}`, body, {
    headers: { Authorization: jwt_token },
    params: { serviceId: body.serviceId },
  });
};

/**
 * delete service
 * @param {id} serviceId
 * @returns
 */
export const deleteService = (serviceId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${POST_SERVICE_}`, {
    headers: { Authorization: jwt_token },
    params: { serviceId },
  });
};

/**
 * get service details
 * @param {id} serviceCategoryId
 * @returns
 */
export const getServiceDetails = (serviceId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_SERVICE__DETAILS}`, {
    headers: { Authorization: jwt_token },
    params: { serviceId },
  });
};

/**
 * short order service
 * @param {body} updateOrder
 * @returns
 */
export const sort_order_service = (updateOrder) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_SERVICE__ORDER}`,
    { updateOrder: updateOrder },
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * get service category data
 * @param {Parameters} paramObj
 * @returns
 */
export const getServiceCategoryApi = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_SERVICE_CATEGORY}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * enable-disable service category
 * @param {body} body
 * @returns
 */
export const updateStatusServiceCategory = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_STATUS_SERVICE_CATEGORY}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * create service category
 * @param {body} body
 * @returns
 */
export const postServiceCategory = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${POST_SERVICE_CATEGORY}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * update service category
 * @param {body} body
 * @returns
 */
export const putUpdateServiceCategory = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${POST_SERVICE_CATEGORY}`,
    body,
    {
      headers: { Authorization: jwt_token },
      params: { serviceCategoryId: body.serviceCategoryId },
    }
  );
};

/**
 * delete service category
 * @param {id} serviceCategoryId
 * @returns
 */
export const deleteServiceCategory = (serviceCategoryId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(
    `${process.env.REACT_APP_API_URL}${POST_SERVICE_CATEGORY}`,
    {
      headers: { Authorization: jwt_token },
      params: { serviceCategoryId },
    }
  );
};

/**
 * get service category details
 * @param {id} serviceCategoryId
 * @returns
 */
export const getServiceCategoryDetails = (serviceCategoryId, navMapId) => {
  const jwt_token = localStorage.getItem("token");
  let paramObj = {
    serviceCategoryId,
  };
  if (navMapId) {
    paramObj[`navMapId`] = navMapId;
  }
  return axios.get(
    `${process.env.REACT_APP_API_URL}${GET_SERVICE_CATEGORY_DETAILS}`,
    {
      headers: { Authorization: jwt_token },
      params: paramObj,
    }
  );
};

/**
 * short order service category
 * @param {body} updateOrder
 * @returns
 */
export const sort_order_service_category = (updateOrder) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_SERVICE_CATEGORY_ORDER}`,
    { updateOrder: updateOrder },
    {
      headers: { Authorization: jwt_token },
    }
  );
};
