import React from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

const SetPasswordItem = (props) => {
  const {
    modal,
    setModal,
    onSubmit,
    togglePassword,
    setTogglePassword,
    setTogglePassword2,
    setPassword,
    togglePassword2,
    setConfirmPassword,
    password,
    confirmPassword,
    togglePassword1,
    setOtp,
    otp,
    formatTime,
    isActive,
    timeLeft,
    reSendOtp,
    closeModal,
  } = props;

  return (
    <Modal size="lg" isOpen={modal} toggle={() => closeModal()}>
      <ModalHeader toggle={() => closeModal()}>Set Password</ModalHeader>
      <ModalBody>
        <Container fluid={true} className="p-0">
          <Row>
            <Col xs="12">
              <div className="img">
                <div>
                  <div className="login-main login-tab">
                    <Form className="theme-form" onSubmit={(e) => onSubmit(e)}>
                      <h4>Set Password</h4>
                      <p>{"Enter Password and Conform Password"}</p>

                      <div className="mb-3 position-relative">
                        <Label className="col-form-label">{"Otp"}</Label>
                        <div className="d-flex gap-2 ">
                          <Input
                            className="form-control"
                            type={togglePassword1 ? "text" : "password"}
                            onChange={(e) => setOtp(e.target.value)}
                            defaultValue={otp}
                            required="Enter a Valid Conform Password"
                            placeholder="Otp"
                          />
                          <Button
                            color="info"
                            type="button"
                            disabled={isActive}
                            style={{ marginRight: "12px" }}
                            onClick={() => reSendOtp()}
                          >
                            {"Send"}
                          </Button>
                        </div>
                      </div>
                      <div className="mb-3 position-relative">
                        <Label className="col-form-label">{"Password"}</Label>
                        <Input
                          className="form-control"
                          type={togglePassword2 ? "text" : "password"}
                          onChange={(e) => setPassword(e.target.value)}
                          defaultValue={password}
                          required="Enter a Valid Password"
                          placeholder="*********"
                        />
                        <div
                          className="show-hide"
                          onClick={() => setTogglePassword2(!togglePassword2)}
                        >
                          <span
                            className={togglePassword2 ? "" : "show"}
                          ></span>
                        </div>
                      </div>

                      <div className="mb-3 position-relative">
                        <Label className="col-form-label">
                          {"Conform Password"}
                        </Label>
                        <Input
                          className="form-control"
                          type={togglePassword ? "text" : "password"}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          defaultValue={confirmPassword}
                          required="Enter a Valid Change Password"
                          placeholder="*********"
                        />
                        <div
                          className="show-hide"
                          onClick={() => setTogglePassword(!togglePassword)}
                        >
                          <span className={togglePassword ? "" : "show"}></span>
                        </div>
                      </div>

                      <div className="login-btn mb-0">
                        {password !== confirmPassword &&
                          password?.length > 0 &&
                          confirmPassword?.length > 0 && (
                            <div className="">
                              <Label className="text-danger " for="checkbox1">
                                {
                                  "Password and confirm password does not match."
                                }
                              </Label>
                            </div>
                          )}
                        {isActive && (
                          <p>OTP resend in: {formatTime(timeLeft)}</p>
                        )}

                        <Button
                          color="primary"
                          type="submit"
                          value="submit"
                          disabled={password !== confirmPassword}
                        >
                          {"Submit"}
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </ModalBody>
    </Modal>
  );
};

export default SetPasswordItem;
