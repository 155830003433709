import React, { useMemo, useState } from "react";
import { Accordion, Card, CardBody, CardHeader, Collapse } from "reactstrap";

const Settings = (props) => {
  const { content, isEdit } = props;
  const [isOpen, setIsOpen] = useState(null);

  useMemo(() => {
    if (isEdit) {
      setIsOpen(1);
    }
  }, [isEdit]);
  const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
  return (
    <Accordion defaultActiveKey="0">
      <div className="default-according">
        <Card>
          <CardHeader className="bg-primary">
            <div
              as={Card.Header}
              lassName="txt-white"
              color="primary"
              onClick={() => toggle(1)}
              style={{ cursor: "pointer" }}
            >
              <i
                className="icofont icofont-wheel position-static  "
                style={{ paddingRight: "12px" }}
              ></i>
              Settings
            </div>
          </CardHeader>
          <Collapse isOpen={isOpen === 1}>
            <CardBody>{content}</CardBody>
          </Collapse>
        </Card>
      </div>
    </Accordion>
  );
};

export default Settings;
