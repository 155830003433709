import React from "react";
import { Col, Row } from "reactstrap";

const CustomStyle = ({ value, handleChange }) => {
  return (
    <Row className="mt-4">
      <h4 className="mb-3">Add custom style in currant classes</h4>
      <Col md="6 mb-3">
        <textarea
          className="form-control"
          name="custom"
          type="text"
          placeholder="Custom Style"
          value={value}
          onChange={handleChange}
          style={{ borderRadius: "0", color: "black" }}
          rows={10}
        />
      </Col>
    </Row>
  );
};

export default CustomStyle;
