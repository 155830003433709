import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { TagsList } from "../../../../api/MenuMasterApi/TagsApi/TagsApi";
import {
  OpenModalCommonFunc,
  TostMessage,
} from "../../../../components ByteCard/utils";
import {
  Cases,
  DELETE_CONFIRM,
} from "../../../../components ByteCard/constant";
import { UploadCloud } from "react-feather";
import { Close } from "@mui/icons-material";
import ByteCardBuilderContext from "../../../../components ByteCard/context/BytecardBilderContext/ByteCardBuilderContext";
import { IconButton } from "@mui/material";
import { delete_Image_Permanent } from "../../../../components ByteCard/apis/AboutApi/AboutUsApi";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import ProductImageUploadPopup from "../../../../components ByteCard/Modals/imageUploade/productImageUploadPopup";
import AddOnProductContext from "../../../../context/MenuMasterContext/AddOnProductContext/AddOnProductContext";
import { ProductsList } from "../../../../api/MenuMasterApi/ProductsApi/ProductsApi";

const AddOnProductAddPopup = (props) => {
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);

  const { handleSubmit, simpleValidator, imageUpload, QpiId, emptyData } =
    props;
  const { addModel, setAddModel, formData, setFormData, isEdit, formValue } =
    useContext(AddOnProductContext);
  const { setLoaderShow } = useContext(LoaderContext);

  const CloseModel = () => {
    emptyData();
    setAddModel(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const handleChangeSelect = (selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      tags: selectedOption,
    }));
  };
  const [tagsData, setTagsData] = useState([]);
  const getTags = async () => {
    try {
      const { data } = await TagsList();

      let allTags = data?.payload?.data?.map((elem) => {
        return {
          value: elem.tag,
          label: elem.tag,
        };
      });
      setTagsData(allTags);
    } catch (error) {}
  };

  useEffect(() => {
    getTags();
  }, []);

  /**
   * reupload image
   */
  const reupload = () => {
    setFormData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };

  const deleteImagePermanent = async (imgName) => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formDatas = {
          fileName: formData[`${imgName}`]
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
            .pop(),
        };
        const { data } = await delete_Image_Permanent(formDatas);
        if (data.status === 200) {
          TostMessage({ message: data, type: "success", position: "center" });
          setFormData({ ...formData, image: "" });
          setFormData((prev) => {
            return {
              ...prev,
              [`${imgName}`]: "",
              image: "",
            };
          });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const [menuProductsIds, setMenuProductsIds] = useState([]);
  const getProducts = async () => {
    try {
      let paramObj = {
        qpiId: QpiId,
        showAll: true,
      };
      const { data } = await ProductsList(paramObj);
      setMenuProductsIds(
        data.payload.data.map((ele) => {
          return {
            key: ele.name,
            value: ele.menuProductId,
          };
        })
      );
    } catch (error) {}
  };
  useEffect(() => {
    getProducts();
  }, []);
  return (
    <>
      <Modal size="xl" isOpen={addModel} toggle={() => CloseModel()}>
        <ModalHeader toggle={() => CloseModel()}>
          {isEdit ? "Edit" : ""} Add On Product
        </ModalHeader>
        <ModalBody>
          <Container fluid={true} className="p-0">
            <Row>
              <Form className="theme-form" onSubmit={(e) => handleSubmit(e)}>
                <Row>
                  <Col md="6 mb-4">
                    <Label>{"Name"}</Label>
                    <Input
                      className="form-control"
                      type={"text"}
                      style={{ color: "black" }}
                      name="name"
                      onChange={(e) => handleChange(e)}
                      value={formData?.name}
                      placeholder="Name"
                    />
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "name",
                        formData?.name,
                        "required"
                      )}
                    </p>
                  </Col>
                  <Col md="6 mb-4">
                    <Label>{"Price"}</Label>
                    <Input
                      className="form-control"
                      type={"number"}
                      name="price"
                      style={{ color: "black" }}
                      onChange={(e) => handleChange(e)}
                      value={formData?.price}
                      placeholder="Price"
                    />
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "price",
                        formData?.price,
                        "required"
                      )}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md="6 mb-4">
                    <Label>{"Button Name"}</Label>
                    <Input
                      className="form-control"
                      type={"text"}
                      style={{ color: "black" }}
                      name="buttonName"
                      onChange={(e) => handleChange(e)}
                      value={formData?.buttonName}
                      placeholder="Button Name"
                    />
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "Button Name",
                        formData?.buttonName,
                        "required"
                      )}
                    </p>
                  </Col>
                  <Col md="6 mb-4">
                    <Label htmlFor="validationCustom03">
                      {"menuProductId"}
                    </Label>
                    <select
                      name="menuProductId"
                      id="font-weight"
                      className="form-select"
                      value={formData?.menuProductId}
                      onChange={(e) => handleChange(e)}
                      style={{ color: "black" }}
                    >
                      <option value="">--select--</option>
                      {menuProductsIds.map((elem) => {
                        return (
                          <option value={elem["value"]} key={elem["key"]}>
                            {elem["key"]}
                          </option>
                        );
                      })}
                    </select>
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "menuProductId",
                        formData?.menuProductId,
                        "required"
                      )}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md="6 mb-3">
                    <label htmlFor="">{"Thumb Image"}</label>
                    <div
                      className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                      style={{ cursor: "pointer" }}
                    >
                      <span
                        onClick={() => {
                          OpenModalCommonFunc(
                            Cases.PRODUCT_UPLOAD,
                            setAllPopupState
                          );
                          setFormData((prev) => {
                            return {
                              ...prev,
                              imageName: "thumbImage",
                            };
                          });
                        }}
                      >
                        <UploadCloud />
                      </span>
                      {formData?.thumbImage && (
                        <img
                          src={formData?.thumbImage ? formData?.thumbImage : ""}
                          alt=""
                          style={{
                            width: "85%",
                            height: "90px",
                            objectFit: "contain",
                            position: "absolute",
                          }}
                          onClick={() => {
                            OpenModalCommonFunc(
                              Cases.PRODUCT_UPLOAD,
                              setAllPopupState
                            );
                            setFormData((prev) => {
                              return {
                                ...prev,
                                imageName: "thumbImage",
                              };
                            });
                          }}
                        />
                      )}
                      {formData?.thumbImage && (
                        <IconButton
                          edge="start"
                          color="inherit"
                          aria-label="close"
                          style={{
                            color: "red",
                            position: "absolute",
                            top: "5px",
                            left: "1px",
                          }}
                          onClick={() => deleteImagePermanent("thumbImage")}
                        >
                          <Close
                            style={{ fontSize: "25px", fontWeight: "bold" }}
                          />
                        </IconButton>
                      )}
                    </div>
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "Thumb Image",
                        formData?.thumbImage,
                        "required"
                      )}
                    </p>
                  </Col>
                </Row>

                <div className="login-btn mt-2">
                  <Button color="primary" type="submit" value="submit">
                    {"Submit"}
                  </Button>
                </div>
              </Form>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
      <ProductImageUploadPopup
        allPopupState={allPopupState}
        setAllPopupState={setAllPopupState}
        crop={crop}
        setCrop={setCrop}
        completedCrop={completedCrop}
        setCompletedCrop={setCompletedCrop}
        scale={scale}
        rotate={rotate}
        image={formData?.image}
        setImage={setFormData}
        imageUpload={imageUpload}
        // isEdit={isEdit}
        reupload={reupload}
        // aspect={22.68 / 1}
      />
    </>
  );
};

export default AddOnProductAddPopup;
