import axios from "axios";
import {
  GET_BLOG,
  GET_BLOG_DETAIL,
  GET_BLOG_DETAILS,
  GET_BLOG_DETAIL_DETAILS,
  POST_BLOG,
  POST_BLOG_DETAIL,
  PUT_BLOG_DETAIL_ORDER,
  PUT_BLOG_ORDER,
  PUT_STATUS_BLOG,
  PUT_STATUS_BLOG_DETAIL,
} from "..";

/**
 * get blog detail data
 * @param {Parameters} paramObj
 * @returns
 */
export const getBlogDetailApi = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_BLOG_DETAIL}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * enable-disable blog detail
 * @param {body} body
 * @returns
 */
export const updateStatus = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_STATUS_BLOG_DETAIL}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * create blog detail
 * @param {body} body
 * @returns
 */
export const postBlogDetail = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${POST_BLOG_DETAIL}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * update blog detail
 * @param {body} body
 * @returns
 */
export const putUpdateBlogDetail = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${POST_BLOG_DETAIL}`,
    body,
    {
      headers: { Authorization: jwt_token },
      params: { blogDetailId: body.blogDetailId },
    }
  );
};

/**
 * delete blog detail
 * @param {id} blogDetailId
 * @returns
 */
export const deleteBlogDetail = (blogDetailId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${POST_BLOG_DETAIL}`, {
    headers: { Authorization: jwt_token },
    params: { blogDetailId },
  });
};

/**
 * get our blog detail details
 * @param {id} blogCategoryId
 * @returns
 */
export const getBlogDetailDetails = (blogDetailId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${GET_BLOG_DETAIL_DETAILS}`,
    {
      headers: { Authorization: jwt_token },
      params: { blogDetailId },
    }
  );
};

/**
 * short order blog detail
 * @param {body} updateOrder
 * @returns
 */
export const sort_order_blog_detail = (updateOrder) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_BLOG_DETAIL_ORDER}`,
    { updateOrder: updateOrder },
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * get blog data
 * @param {Parameters} paramObj
 * @returns
 */
export const getBlog = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_BLOG}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * enable-disable blog
 * @param {body} body
 * @returns
 */
export const updateStatusBlog = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(`${process.env.REACT_APP_API_URL}${PUT_STATUS_BLOG}`, body, {
    headers: { Authorization: jwt_token },
  });
};

/**
 * create blog
 * @param {body} body
 * @returns
 */
export const postBlog = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(`${process.env.REACT_APP_API_URL}${POST_BLOG}`, body, {
    headers: { Authorization: jwt_token },
  });
};

/**
 * update blog
 * @param {body} body
 * @returns
 */
export const putUpdateBlog = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(`${process.env.REACT_APP_API_URL}${POST_BLOG}`, body, {
    headers: { Authorization: jwt_token },
    params: { blogCategoryId: body.blogCategoryId },
  });
};

/**
 * delete blog
 * @param {id} blogCategoryId
 * @returns
 */
export const deleteBlog = (blogCategoryId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${POST_BLOG}`, {
    headers: { Authorization: jwt_token },
    params: { blogCategoryId },
  });
};

/**
 * get blog details
 * @param {id} blogCategoryId
 * @returns
 */
export const getBlogDetails = (blogCategoryId, navMapId) => {
  const jwt_token = localStorage.getItem("token");
  let paramObj = {
    blogCategoryId,
  };
  if (navMapId) {
    paramObj["navMapId"] = navMapId;
  }
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_BLOG_DETAILS}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * short order blog
 * @param {body} updateOrder
 * @returns
 */
export const sort_order_blog = (updateOrder) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_BLOG_ORDER}`,
    { updateOrder: updateOrder },
    {
      headers: { Authorization: jwt_token },
    }
  );
};
