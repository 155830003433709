import axios from "axios";
import {
  ADD_ORG_PLAN_LIST,
  ADD_PLAN_MASTER_LIST,
  DELETE_ORG_PLAN_LIST,
  DELETE_PLAN_MASTER_LIST,
  DETAILS_ORG_PLAN_LIST,
  DETAILS_PLAN_MASTER_LIST,
  EDIT_ORG_PLAN_LIST,
  EDIT_PLAN_MASTER_LIST,
  ORG_PLAN_LIST,
  ORG_PLAN_PAGE_LIST_ENABLE_DESABLE,
  PLAN_MASTER_LIST,
  PLAN_MASTER_PAGE_LIST_ENABLE_DESABLE,
} from "../../constant/API constant";

export const OrgPlan_List = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${ORG_PLAN_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

export const Add_OrgPlan_List = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${ADD_ORG_PLAN_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Edit_OrgPlan_List = (formData, id) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${EDIT_ORG_PLAN_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { planId: id },
    }
  );
};

export const Delete_OrgPlan_List = (planId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(
    `${process.env.REACT_APP_API_URL}${DELETE_ORG_PLAN_LIST}`,
    {
      headers: { Authorization: jwt_token },
      params: { planId },
    }
  );
};

export const Details_OrgPlan_List = (id) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${DETAILS_ORG_PLAN_LIST}`, {
    headers: { Authorization: jwt_token },
    params: { planId: id },
  });
};

export const OrgPlan_Enable_Desable = (formData, planId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${ORG_PLAN_PAGE_LIST_ENABLE_DESABLE}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { planId },
    }
  );
};
