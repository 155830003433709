import axios from "axios";
import {
  ADD_ADD_ON_PRODUCT_LIST,
  ADD_ON_PRODUCT_LIST,
  ADD_PRODUCTS_LIST,
  ADD_TAGS_LIST,
  DELETE_ADD_ON_PRODUCT_LIST,
  DELETE_PRODUCTS_LIST,
  DELETE_TAGS_LIST,
  DETAILS_ADD_ON_PRODUCT_LIST,
  DETAILS_PRODUCTS_LIST,
  DETAILS_TAGS_LIST,
  EDIT_ADD_ON_PRODUCT_LIST,
  EDIT_PRODUCTS_LIST,
  EDIT_TAGS_LIST,
  MODULE_ACCESS_LIST,
  MODULE_LIST,
  ORG_ADD_ON_PRODUCT_MENU_PAGE_LIST_ENABLE_DESABLE,
  ORG_MENU_PAGE_LIST_ENABLE_DESABLE,
  ORG_TAGS_MENU_PAGE_LIST_ENABLE_DESABLE,
  PRODUCTS_LIST,
  TAGS_LIST,
} from "../../../constant/API constant";

export const AddOnProductList = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${ADD_ON_PRODUCT_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const Add_AddOnProduct_List = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${ADD_ADD_ON_PRODUCT_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Edit_AddOnProduct_List = (formData, id) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${EDIT_ADD_ON_PRODUCT_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { addOnProductId: id },
    }
  );
};

export const Delete_AddOnProduct_List = (addOnProductId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(
    `${process.env.REACT_APP_API_URL}${DELETE_ADD_ON_PRODUCT_LIST}`,
    {
      headers: { Authorization: jwt_token },
      params: { addOnProductId },
    }
  );
};

export const Ditails_AddOnProduct_List = (id) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${DETAILS_ADD_ON_PRODUCT_LIST}`,
    {
      headers: { Authorization: jwt_token },
      params: { addOnProductId: id },
    }
  );
};

export const AddOnProduct_MenuPageList_Enable_Desable = (
  formData,
  addOnProductId
) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${ORG_ADD_ON_PRODUCT_MENU_PAGE_LIST_ENABLE_DESABLE}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { addOnProductId },
    }
  );
};
