import React, { useContext } from "react";
import { Settings } from "react-feather";
import { Card, CardHeader, Col, Row } from "reactstrap";
import SettingMultiWebContext from "../../context/SettingMultiWebContext/SettingMultiWebContext";
import { Style } from "../../constant";

const ManageWebsiteMenu = () => {
  const { setModalChild, WebNavBarMap_List: navbarMap_List } = useContext(
    SettingMultiWebContext
  );

  const AddChild = () => {
    setModalChild(true);
    navbarMap_List({
      showAll: true,
      isChild: true,
      sortOrder: "ASC",
      sortBy: "order",
    });
  };
  return (
    <Row>
      <Col sm="12">
        <Card>
          <CardHeader
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <h3>{"Manage Website Menu"}</h3>
            <div className="d-flex gap-2">
              <div
                aria-label="add"
                onClick={() => AddChild()}
                className="btn btn-secondary"
                style={Style}
              >
                <Settings />
              </div>
            </div>
          </CardHeader>
        </Card>
      </Col>
    </Row>
  );
};

export default ManageWebsiteMenu;
