import React, { useState } from "react";
import ProductMultipageContext from "./ProductMultipageContext";
import { randomStr } from "../../../utils/helper";

const ProductMultipageState = (props) => {
  const [apiData, setApiData] = useState({ data: [], isLoading: false });
  const [apiDataOurTeam, setApiDataOurTeam] = useState({
    isLoading: false,
    data: [],
  });
  const [skill, setSkill] = useState([
    { id: randomStr(2), skill: "", parentageOfSkill: "" },
  ]);
  const [isBackgroundColor, setIsBackgroundColor] = useState(false);
  const [collId, setCollId] = useState();
  const [backImageToggle, setBackImageToggle] = useState(false);
  const [fromData, setFormData] = useState({
    title: "",
    slugUrl: "",
    buttonText: "",
    itemLimit: "",
    navMapId: "",
    theme: "",
    webId: localStorage.getItem("webId"),
    subTitle: "",
    isTitleShow: true,
    style: {
      ".products-section": {
        padding: "100px 0",
        "background-color": "rgb(255 255 255 / 100)",
      },
      ".products-section.bg-image": {
        "background-image": "",
      },
      ".products-section.bg-image::before": {
        "background-color": "",
      },
      ".products-section .section-title-area": {
        "margin-bottom": "50px",
      },
      ".products-section .section-title-area .section-subtitle": {
        "font-size": "16px",
        "font-weight": "600",
        color: "rgb(255 73 124 / 100)",
        "background-color": "rgb(255 73 124 / 0.2)",
        padding: "5px 20px",
        p1: "5px",
        p2: "20px",
        "border-radius": "15px",
        "margin-bottom": "15px",
      },
      ".products-section .section-title-area .section-title": {
        "font-size": "44px",
        "font-weight": "600",
        color: "rgb(0 0 0 / 100)",
      },
      ".products-section .product-catlist": {
        "text-align": "center",
        "margin-bottom": "30px",
      },
      ".products-section .product-catlist .btn": {
        border: "2px solid rgb(255 73 124 / 100)",
        borderColor: "rgb(255 73 124 / 100)",
        color: "rgb(255 73 124 / 100)",
        padding: "6px 20px",
        p1: "6px",
        p2: "20px",
        margin: "5px",
        "box-shadow": "none",
      },
      ".products-section .product-catlist .active": {
        "background-color": "rgb(255 73 124 / 100)",
        color: "rgb(255 255 255 / 100)",
      },
      ".products-section .product-catdata .box": {
        "margin-bottom": "15px",
        outline: "none",
        "background-color": "rgb(255 255 255 / 100)",
        "box-shadow": "0 5px 10px 0 rgb(7 28 31 / 0.098)",
        bs1: "0px",
        bs2: "5px",
        bs3: "10px",
        bs4: "0px",
        shedowColor: "rgb(7 28 31 / 0.098)",
        "border-radius": "0px",
      },
      ".products-section .product-catdata .product-img .taglabel": {
        "background-color": "rgb(0 138 73 / 100)",
        color: "rgb(255 255 255 / 100)",
        "font-size": "14px",
        "font-weight": "400",
        padding: "8px 15px",
        p1: "8px",
        p2: "15px",
        "border-radius": "5px",
        top: "10px",
        left: "10px",
      },
      ".products-section .product-catdata .product-img .post-date": {
        "background-color": "rgb(255 255 255 / 100)",
        "border-radius": "5px",
        color: "rgb(255 73 124 / 100)",
        "font-size": "14px",
        "font-weight": "400",
        padding: "8px 15px",
        p1: "8px",
        p2: "15px",
        "text-align": "center",
        top: "10px",
        right: "10px",
      },
      ".products-section .product-catdata .product-content": {
        padding: "20px",
        float: "left",
        width: "100%",
        "text-align": "center",
      },
      ".products-section .product-catdata .product-content .shop a": {
        color: "rgb(0 0 0 / 100)",
        "font-size": "20px",
      },
      ".products-section .product-catdata .product-content .shop a:hover": {
        color: "rgb(255 73 124 / 100)",
      },
      ".products-section .product-catdata .product-content .price": {
        color: "rgb(51 51 51 / 100)",
        margin: "15px 5px 10px 5px",
        m1: "15px",
        m2: "5px",
        m3: "10px",
        m4: "5px",
      },
      ".products-section .product-catdata .product-content .price del": {
        color: "rgb(153 153 153 / 100)",
        "margin-right": "15px",
      },
      ".products-section .product-catdata .product-content .price ins": {
        "text-decoration": "none",
        color: "rgb(0 0 0 / 100)",
      },
      ".products-section .product-catdata .product-content .add-cart": {
        border: "2px solid rgb(255 73 124 / 100)",
        borderColor: "rgb(255 73 124 / 100)",
        "background-color": "rgb(255 73 124 / 100)",
        color: "rgb(255 255 255 / 100)",
        "font-size": "14px",
        "font-weight": "500",
        padding: "4px 15px",
        p1: "4px",
        p2: "15px",
        "box-shadow": "none",
      },
      ".products-section .product-catdata .product-content .add-cart:hover": {
        opacity: "0.8",
      },
      ".products-section .more-btn": {
        color: "rgb(255 255 255 / 100)",
        "font-size": "16px",
        "font-weight": "normal",
        "border-radius": "15px",
        padding: "5px 20px",
        "background-color": "rgb(255 73 124 / 100)",
      },
      ".products-section .more-btn:hover": {
        "background-color": "rgb(255 73 124 / 100)",
        color: "rgb(255 255 255 / 100)",
      },
      ".pagination-positions": {
        "justify-content": "center",
      },
      ".pagination .page-link": {
        color: "rgb(255 73 124 / 100)",
      },
      ".pagination .page-item.active .page-link": {
        "background-color": "rgb(255 73 124 / 100)",
        "border-color": "rgb(255 73 124 / 100)",
      },
    },
  });
  const [images, setImages] = useState([
    { path: "", isDefault: true },
    { path: "", isDefault: false },
    { path: "", isDefault: false },
    { path: "", isDefault: false },
  ]);

  const [formDataChild, setFormDataChild] = useState({
    title: "",
    description: "",
    image: "",
    price: "",
    isTitleShow: true,
    currencySymbol: "$",
    order: 1,
    webId: localStorage.getItem("webId"),
  });
  const [index, setIndex] = useState("");
  const [settingFormData, setSettingFormData] = useState({
    ".products-section": {
      padding: "100px 0",
      "background-color": "rgb(255 255 255 / 100)",
    },
    ".products-section.bg-image": {
      "background-image": "",
    },
    ".products-section.bg-image::before": {
      "background-color": "",
    },
    ".products-section .section-title-area": {
      "margin-bottom": "50px",
    },
    ".products-section .section-title-area .section-subtitle": {
      "font-size": "16px",
      "font-weight": "600",
      color: "rgb(255 73 124 / 100)",
      "background-color": "rgb(255 73 124 / 0.2)",
      padding: "5px 20px",
      p1: "5px",
      p2: "20px",
      "border-radius": "15px",
      "margin-bottom": "15px",
    },
    ".products-section .section-title-area .section-title": {
      "font-size": "44px",
      "font-weight": "600",
      color: "rgb(0 0 0 / 100)",
    },
    ".products-section .product-catlist": {
      "text-align": "center",
      "margin-bottom": "30px",
    },
    ".products-section .product-catlist .btn": {
      border: "2px solid rgb(255 73 124 / 100)",
      borderColor: "rgb(255 73 124 / 100)",
      color: "rgb(255 73 124 / 100)",
      padding: "6px 20px",
      p1: "6px",
      p2: "20px",
      margin: "5px",
      "box-shadow": "none",
    },
    ".products-section .product-catlist .active": {
      "background-color": "rgb(255 73 124 / 100)",
      color: "rgb(255 255 255 / 100)",
    },
    ".products-section .product-catdata .box": {
      "margin-bottom": "15px",
      outline: "none",
      "background-color": "rgb(255 255 255 / 100)",
      "box-shadow": "0 5px 10px 0 rgb(7 28 31 / 0.098)",
      bs1: "0px",
      bs2: "5px",
      bs3: "10px",
      bs4: "0px",
      shedowColor: "rgb(7 28 31 / 0.098)",
      "border-radius": "0px",
    },
    ".products-section .product-catdata .product-img .taglabel": {
      "background-color": "rgb(0 138 73 / 100)",
      color: "rgb(255 255 255 / 100)",
      "font-size": "14px",
      "font-weight": "400",
      padding: "8px 15px",
      p1: "8px",
      p2: "15px",
      "border-radius": "5px",
      top: "10px",
      left: "10px",
    },
    ".products-section .product-catdata .product-img .post-date": {
      "background-color": "rgb(255 255 255 / 100)",
      "border-radius": "5px",
      color: "rgb(255 73 124 / 100)",
      "font-size": "14px",
      "font-weight": "400",
      padding: "8px 15px",
      p1: "8px",
      p2: "15px",
      "text-align": "center",
      top: "10px",
      right: "10px",
    },
    ".products-section .product-catdata .product-content": {
      padding: "20px",
      float: "left",
      width: "100%",
      "text-align": "center",
    },
    ".products-section .product-catdata .product-content .shop a": {
      color: "rgb(0 0 0 / 100)",
      "font-size": "20px",
    },
    ".products-section .product-catdata .product-content .shop a:hover": {
      color: "rgb(255 73 124 / 100)",
    },
    ".products-section .product-catdata .product-content .price": {
      color: "rgb(51 51 51 / 100)",
      margin: "15px 5px 10px 5px",
      m1: "15px",
      m2: "5px",
      m3: "10px",
      m4: "5px",
    },
    ".products-section .product-catdata .product-content .price del": {
      color: "rgb(153 153 153 / 100)",
      "margin-right": "15px",
    },
    ".products-section .product-catdata .product-content .price ins": {
      "text-decoration": "none",
      color: "rgb(0 0 0 / 100)",
    },
    ".products-section .product-catdata .product-content .add-cart": {
      border: "2px solid rgb(255 73 124 / 100)",
      borderColor: "rgb(255 73 124 / 100)",
      "background-color": "rgb(255 73 124 / 100)",
      color: "rgb(255 255 255 / 100)",
      "font-size": "14px",
      "font-weight": "500",
      padding: "4px 15px",
      p1: "4px",
      p2: "15px",
      "box-shadow": "none",
    },
    ".products-section .product-catdata .product-content .add-cart:hover": {
      opacity: "0.8",
    },
    ".products-section .more-btn": {
      color: "rgb(255 255 255 / 100)",
      "font-size": "16px",
      "font-weight": "normal",
      "border-radius": "15px",
      padding: "5px 20px",
      "background-color": "rgb(255 73 124 / 100)",
    },
    ".products-section .more-btn:hover": {
      "background-color": "rgb(255 73 124 / 100)",
      color: "rgb(255 255 255 / 100)",
    },
    ".pagination-positions": {
      "justify-content": "center",
    },
    ".pagination .page-link": {
      color: "rgb(255 73 124 / 100)",
    },
    ".pagination .page-item.active .page-link": {
      "background-color": "rgb(255 73 124 / 100)",
      "border-color": "rgb(255 73 124 / 100)",
    },
  });
  const [boxSection, setBoxSection] = useState(false);
  const [id, setId] = useState("");
  const [childId, setChildId] = useState("");
  const [navMapId, setNavMapId] = useState("");
  const [galleryImgId, setGalleryImgId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isParent, setIsParent] = useState(false);
  const [filter, setFilter] = useState("");
  const [theme, setTheme] = useState(false);
  const [imgShow, setImgShow] = useState(false);
  const [sectionPageName, setSectionPageName] = useState("");
  return (
    <ProductMultipageContext.Provider
      value={{
        apiData,
        setApiData,
        apiDataOurTeam,
        setApiDataOurTeam,
        fromData,
        setFormData,
        id,
        setId,
        isEdit,
        setIsEdit,
        filter,
        setFilter,
        skill,
        galleryImgId,
        setGalleryImgId,
        setSkill,
        settingFormData,
        setSettingFormData,
        formDataChild,
        setFormDataChild,
        isParent,
        setIsParent,
        childId,
        setChildId,
        navMapId,
        setNavMapId,
        boxSection,
        setBoxSection,
        collId,
        setCollId,
        images,
        setImages,
        index,
        setIndex,
        backImageToggle,
        setBackImageToggle,
        isBackgroundColor,
        setIsBackgroundColor,
        theme,
        setTheme,
        imgShow,
        setImgShow,
        sectionPageName,
        setSectionPageName,
      }}
    >
      {props.children}
    </ProductMultipageContext.Provider>
  );
};

export default ProductMultipageState;
