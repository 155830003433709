import React, { useState } from "react";
import LatestNewsContext from "./LatestNewsContext";
import { randomStr } from "../../../utils/helper";

const LatestNewsState = (props) => {
  const [apiData, setApiData] = useState({ data: [], isLoading: false });
  const [apiDataOurTeam, setApiDataOurTeam] = useState({
    isLoading: false,
    data: [],
  });
  const [skill, setSkill] = useState([
    { id: randomStr(2), skill: "", parentageOfSkill: "" },
  ]);
  const [isBackgroundColor, setIsBackgroundColor] = useState(false);
  const [collId, setCollId] = useState();
  const [fromData, setFormData] = useState({
    title: "",
    subTitle: "",
    isTitleShow: true,
    style: {
      ".latest-news-section": {
        padding: "30px 0",
        "background-color": "rgb(33 37 41 / 100)",
      },
      ".latest-news-section.bg-image": {
        "background-image": "",
      },
      ".latest-news-section.bg-image::before": {
        "background-color": "",
      },
      ".latest-news-section .news-label": {
        color: "rgb(255 255 255 / 100)",
        "font-size": "20px",
        "font-weight": 500,
        "margin-right": "15px",
      },
      ".latest-news-section marquee": {
        color: "rgb(255 255 255 / 100)",
      },
      ".latest-news-section marquee a": {
        color: "rgb(255 73 124 / 100)",
      },
      latestNewsId: "",
    },
  });
  const [boxSection, setBoxSection] = useState(false);
  const [backImageToggle, setbackImageToggle] = useState(false);

  const [formDataChild, setFormDataChild] = useState({
    title: "",
    subTitle: "",
    startDate: "",
    endDate: "",
    link: "",
    isTitleShow: true,
    order: 1,
  });

  const [settingFormData, setSettingFormData] = useState({
    ".latest-news-section": {
      padding: "30px 0",
      "background-color": "rgb(33 37 41 / 100)",
    },
    ".latest-news-section.bg-image": {
      "background-image": "",
    },
    ".latest-news-section.bg-image::before": {
      "background-color": "",
    },
    ".latest-news-section .news-label": {
      color: "rgb(255 255 255 / 100)",
      "font-size": "20px",
      "font-weight": 500,
      "margin-right": "15px",
    },
    ".latest-news-section marquee": {
      color: "rgb(255 255 255 / 100)",
    },
    ".latest-news-section marquee a": {
      color: "rgb(255 73 124 / 100)",
    },
    latestNewsId: "",
  });

  const [id, setId] = useState("");
  const [childId, setChildId] = useState("");
  const [galleryImgId, setGalleryImgId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isParent, setIsParent] = useState(false);
  const [filter, setFilter] = useState("");
  const [theme, setTheme] = useState(false);
  const [imgShow, setImgShow] = useState(false);
  const [sectionPageName, setSectionPageName] = useState("");
  return (
    <LatestNewsContext.Provider
      value={{
        apiData,
        setApiData,
        apiDataOurTeam,
        setApiDataOurTeam,
        fromData,
        setFormData,
        id,
        setId,
        isEdit,
        setIsEdit,
        filter,
        setFilter,
        skill,
        galleryImgId,
        setGalleryImgId,
        setSkill,
        settingFormData,
        setSettingFormData,
        formDataChild,
        setFormDataChild,
        isParent,
        setIsParent,
        childId,
        setChildId,
        backImageToggle,
        setbackImageToggle,
        boxSection,
        setBoxSection,
        collId,
        setCollId,
        isBackgroundColor,
        setIsBackgroundColor,
        theme,
        setTheme,
        imgShow,
        setImgShow,
        sectionPageName,
        setSectionPageName,
      }}
    >
      {props.children}
    </LatestNewsContext.Provider>
  );
};

export default LatestNewsState;
