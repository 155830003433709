import React, { useContext, useMemo, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import { Cases, btnName, modalsName } from "../../../constant";
import CommonPopup from "../../../Modals/commonPopup";
import CardThemeSelectPopup from "./CardThemeSelectPopup";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import { putUpdateTheme } from "../../../apis/selectColorApi/SelectColorApi";

const CardThemeColorSelect = ({ dataTheme, ByteMiniWeb_Dashboard }) => {
  const { setAllPopupState, allPopupState } = useContext(
    ByteCardBuilderContext
  );
  const { setLoaderShow } = useContext(LoaderContext);

  const [themeSelect, setThemeSelect] = useState({
    themeColor: "#f07f1b",
    fontColor: "#ffffff",
    isSlider: false,
  });

  useMemo(() => {
    if (dataTheme?.otherSetting !== "") {
      setThemeSelect({
        themeColor: dataTheme?.otherSetting?.themeColor,
        fontColor: dataTheme?.otherSetting?.fontColor,
        isSlider: dataTheme?.otherSetting?.isSlider,
      });
    }
  }, [dataTheme]);

  const handleSubmit = async () => {
    try {
      setLoaderShow(true);

      const { data } = await putUpdateTheme(themeSelect);
      if (data.status === 200) {
        OpenModalCommonFunc(Cases.THEME, setAllPopupState, false);
        ByteMiniWeb_Dashboard();
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
        ByteMiniWeb_Dashboard();
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget cardHover">
          <CardBody className="support-ticket-font box-border-div">
            <Row>
              <div className="col-5">
                <h5 className="total-num counter">Color</h5>
              </div>
              <div className="col-7">
                <div className="text-md-end">
                  <ul>
                    <li className="d-flex justify-content-end flex-wrap ">
                      <div
                        className="cercel-color"
                        style={{
                          background: dataTheme?.otherSetting?.themeColor,
                        }}
                      ></div>
                    </li>
                  </ul>
                </div>
              </div>
            </Row>
            <OpenPopupBtn
              openPopup={() =>
                OpenModalCommonFunc(Cases.THEME, setAllPopupState)
              }
              name={btnName.THEME}
            />
          </CardBody>
        </Card>
      </Col>
      {allPopupState.colorPopup && (
        <CommonPopup
          isMini={true}
          open={allPopupState.colorPopup}
          close={() =>
            OpenModalCommonFunc(Cases.THEME, setAllPopupState, false)
          }
          modalName={modalsName.SELECT_THEME}
          modalCase={Cases.THEME}
          handleSubmit={handleSubmit}
          edit={false}
          content={
            <CardThemeSelectPopup
              themeSelect={themeSelect}
              setThemeSelect={setThemeSelect}
            />
          }
        />
      )}
    </>
  );
};

export default CardThemeColorSelect;
