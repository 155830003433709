import React, { useContext, useMemo, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import { Cases, DELETE_CONFIRM, modalsName } from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import CommonPopup from "../../../Modals/commonPopup";
import VideoBannerPopup from "./videoBannerPopup";
import EditIcon from "@mui/icons-material/Edit";
import VideoBannerParentPopup from "./videoBannerParentPopup";
import { FILE_UPLOAD_API } from "../apis/SliderApi/SliderApi";
import {
  addUrlImage,
  convertRGBA,
  isCheckValue,
  randomStr,
  removeUrlImage,
  removeUrlKeyInPath,
  rgbColorCreate,
} from "../../../../utils/helper";
import { FILE_TYPE, titleUpdateKey } from "../../../../constant";
import { IconButton } from "@material-ui/core";
import { Trash } from "react-feather";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import SimpleReactValidator from "simple-react-validator";
import VideoBannerContext from "../../../context/VideoBannerContext/VideoBannerContext";
import {
  deleteVideoBanner,
  getVideoBanner,
  getVideoBannerDetails,
  postVideoBanner,
  putUpdateVideoBanner,
  updateStatus,
} from "../apis/VideoBannerApi/VideoBannerApi";
import SettingVideoBannerParentPopup from "./SettingVideoBannerParentPopup";
import DeleteSection from "../../../common/DeleteSection";
import SettingMultiWebContext from "../../../../context/SettingMultiWebContext/SettingMultiWebContext";

const VideoBanner = (props) => {
  const {
    pageConfigDetails,
    webMasterDetail,
    contentMapId,
    navbarData,
    getData,
    videoBannerDetail,
    ByteMiniWeb_Dashboard,
    contentDetail,
  } = props;
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );
  const { setCloneAlignModal, setAlignFormData, setCloneFormData } = useContext(
    SettingMultiWebContext
  );
  const parentValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const childValidator = useRef(new SimpleReactValidator());
  const {
    fromData,
    setFormData,
    formDataChild,
    setFormDataChild,
    id,
    setId,
    setIsEdit,
    isEdit,
    setFilter,
    apiDataOurTeam: apiDataImageParent,
    setApiDataOurTeam: setApiDataImageParent,
    setSkill,
    setGalleryImgId,
    setSettingFormData,
    settingFormData,
    isParent,
    setIsParent,
    childId,
    setChildId,
    navMapId,
    setNavMapId,
    setBackImageToggle,
    boxSection,
    setBoxSection,
    collId,
    setCollId,
    isBackgroundColor,
    setIsBackgroundColor,
    countOfList,
    setCountOfList,
    sectionPageName,
    setSectionPageName,
  } = useContext(VideoBannerContext);
  const { setLoaderShow } = useContext(LoaderContext);
  const [parentId, setParentId] = useState();
  const [videoBannerId, setVideoBannerId] = useState();

  /**
   * get video banner parent list
   * @param {status} status
   */
  const getVideoBannerParentList = async ({
    parentId = null,
    isClose = false,
    filter,
  }) => {
    try {
      let paramObj = {};
      if (
        ((parentId && parentId !== null) ||
          (id && isClose === false && isParent === false)) &&
        isParent === false
      ) {
        paramObj["search"] = { parentId: parentId || id, contentMapId };
        setId(parentId || id);
        if (filter?.length) {
          paramObj["search"] = { ...paramObj.search, title: filter };
        }
      } else {
        paramObj["search"] = { parentId: null, contentMapId };
      }
      const { data } = await getVideoBanner(paramObj);
      if (isCheckValue({ value: parentId })) {
        setChildId(parentId);
      }
      if (data.status === 200) {
        if (
          isParent === false &&
          ((parentId && parentId !== null) || id) &&
          isClose === false
        ) {
          setApiDataImageParent({
            ...apiDataImageParent,
            isLoading: false,
            data: data.payload.data.map((elem) => {
              return {
                id: elem?.title + elem?.videoBannerId,
                Id: elem?.videoBannerId,
                name: elem?.title,
                image: "",
                webId: elem?.webId,
                createdAt: elem?.createdAt,
                isActive: elem?.isActive,
                parentId: elem?.parentId,
              };
            }),
          });
          OpenModalCommonFunc(Cases.VIDEO_BANNER, setAllPopupState);
        } else if (parentId && parentId !== null) {
          OpenModalCommonFunc(Cases.VIDEO_BANNER, setAllPopupState);
          setApiDataImageParent({
            ...apiDataImageParent,
            isLoading: false,
            data: data.payload.data.map((elem) => {
              return {
                id: elem?.title + elem?.videoBannerId,
                Id: elem?.videoBannerId,
                name: elem?.title,
                image: elem?.image,
                webId: elem?.webId,
                createdAt: elem?.createdAt,
                isActive: elem?.isActive,
                parentId: elem?.parentId,
              };
            }),
          });
        } else {
          setApiDataImageParent({
            ...apiDataImageParent,
            isLoading: false,
            data: data.payload.data,
          });
        }
        if (isClose && isClose !== false) {
          setIsParent(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  useMemo(() => {
    let countOfListValue = apiDataImageParent.data.length + 1;
    setCountOfList(countOfListValue);
    setSettingFormData({
      [`.video-banner-section${countOfListValue}`]: {
        padding: "200px 0",
        "background-color": "rgb(0 0 0 / 100)",
      },
      [`.video-banner-section${countOfListValue}.bg-image`]: {
        "background-image": "",
      },
      [`.video-banner-section${countOfListValue}.bg-image::before`]: {
        "background-color": "",
        // "background-color": "rgb(0 0 0 / 0.8)",
      },
      [`.video-banner-section${countOfListValue} .video-play`]: {
        height: "80px",
        width: "80px",
        "background-color": "rgb(255 255 255 / 100)",
        color: "rgb(255 73 124 / 100)",
        "text-align": "center",
        padding: "25px 5px",
        p1: "25px",
        p2: "5px",
        "font-size": "25px",
        "border-radius": "100%",
        display: "inline-block",
      },
      ".videomodal .framediv iframe": {
        "box-shadow": "0 0 8px rgb(0 0 0 / 0.6)",
        bs1: "0px",
        bs2: "0px",
        bs3: "8px",
        shadowColor: "rgb(0 0 0 / 0.6)",
        "background-color": "rgb(0 0 0 / 100)",
      },
      custom: "",
      videoBannerId: "",
    });
    // return contactUsData.data.length + 1;
  }, [apiDataImageParent]);

  const submitStyleValue = () => {
    let countOfListValue = settingFormData?.countOfList || countOfList;
    let newData = {
      ...settingFormData,
      countOfList: countOfListValue,
      [`.video-banner-section${countOfListValue}`]: boxSection
        ? {
            ...settingFormData[`.video-banner-section${countOfListValue}`],
            padding: `${settingFormData[
              `.video-banner-section${countOfListValue}`
            ]["padding"]
              ?.split(" ")[0]
              ?.replace("px", "")}px 0`,
            width: `calc(100% - ${settingFormData[
              `.video-banner-section${countOfListValue}`
            ]["width"]?.replace("rem)", "")}rem)`,
            margin: `${settingFormData[
              `.video-banner-section${countOfListValue}`
            ]["margin"]
              ?.split(" ")[0]
              ?.replace("px", "")}px auto`,
            "border-radius": `${settingFormData[
              `.video-banner-section${countOfListValue}`
            ]["border-radius"]?.replace("rem", "")}rem`,
          }
        : {
            ...settingFormData[`.video-banner-section${countOfListValue}`],
            padding: `${settingFormData[
              `.video-banner-section${countOfListValue}`
            ]["padding"]
              ?.split(" ")[0]
              ?.replace("px", "")}px 0`,
          },
      [`.video-banner-section${countOfListValue}.bg-image`]: settingFormData[
        [`.video-banner-section${countOfListValue}.bg-image`]
      ]["background-image"]
        ? {
            ...settingFormData[
              `.video-banner-section${countOfListValue}.bg-image`
            ],
            "background-image": settingFormData[
              [`.video-banner-section${countOfListValue}.bg-image`]
            ]["background-image"]
              ? addUrlImage({
                  urlString: removeUrlImage({
                    urlString:
                      settingFormData[
                        `.video-banner-section${countOfListValue}.bg-image`
                      ]["background-image"],
                  }),
                })
              : "",
            "background-repeat": "no-repeat",
            "background-size": "cover",
          }
        : {},
      [`.video-banner-section${countOfListValue}.bg-image::before`]:
        settingFormData[
          `.video-banner-section${countOfListValue}.bg-image::before`
        ]["background-color"]
          ? {
              "background-color": settingFormData[
                `.video-banner-section${countOfListValue}.bg-image::before`
              ]["background-color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[
                        `.video-banner-section${countOfListValue}.bg-image::before`
                      ]["background-color"],
                  })
                : "",
            }
          : {},
      [`.video-banner-section${countOfListValue} .video-play`]: {
        ...settingFormData[
          `.video-banner-section${countOfListValue} .video-play`
        ],
        height: `${settingFormData[
          `.video-banner-section${countOfListValue} .video-play`
        ]["height"]?.replace("px", "")}px`,
        width: `${settingFormData[
          `.video-banner-section${countOfListValue} .video-play`
        ]["width"]?.replace("px", "")}px`,
        "background-color": settingFormData[
          `.video-banner-section${countOfListValue} .video-play`
        ]?.["background-color"]
          ? rgbColorCreate({
              rgbString:
                settingFormData[
                  `.video-banner-section${countOfListValue} .video-play`
                ]?.["background-color"],
            })
          : "",
        color: settingFormData[
          `.video-banner-section${countOfListValue} .video-play`
        ]?.["color"]
          ? rgbColorCreate({
              rgbString:
                settingFormData[
                  `.video-banner-section${countOfListValue} .video-play`
                ]?.["color"],
            })
          : "",
        "text-align": `${
          settingFormData[
            `.video-banner-section${countOfListValue} .video-play`
          ]["text-align"]
        }`,
        padding: `${settingFormData[
          `.video-banner-section${countOfListValue} .video-play`
        ]["p1"]?.replace("px", "")}px ${settingFormData[
          `.video-banner-section${countOfListValue} .video-play`
        ]["p2"]?.replace("px", "")}px`,
        "font-size": `${settingFormData[
          `.video-banner-section${countOfListValue} .video-play`
        ]["font-size"]?.replace("px", "")}px`,
        "border-radius": `${settingFormData[
          `.video-banner-section${countOfListValue} .video-play`
        ]["border-radius"]?.replace("%", "")}%`,
      },
      ".videomodal .framediv iframe": {
        ...settingFormData[".videomodal .framediv iframe"],
        "box-shadow": `${settingFormData[".videomodal .framediv iframe"][
          "bs1"
        ]?.replace("px", "")}px ${settingFormData[
          ".videomodal .framediv iframe"
        ]["bs2"]?.replace("px", "")}px ${settingFormData[
          ".videomodal .framediv iframe"
        ]["bs3"]?.replace("px", "")}px ${rgbColorCreate({
          rgbString:
            settingFormData[".videomodal .framediv iframe"]?.["shadowColor"],
        })}`,
        "background-color": settingFormData[".videomodal .framediv iframe"]?.[
          "background-color"
        ]
          ? rgbColorCreate({
              rgbString:
                settingFormData[".videomodal .framediv iframe"]?.[
                  "background-color"
                ],
            })
          : "",
      },
    };
    if (isBackgroundColor) {
      newData[`.video-banner-section${countOfListValue}`] = {
        ...newData?.[`.video-banner-section${countOfListValue}`],
        "background-color": settingFormData[
          `.video-banner-section${countOfListValue}`
        ]?.["background-color"]
          ? rgbColorCreate({
              rgbString:
                settingFormData[`.video-banner-section${countOfListValue}`]?.[
                  "background-color"
                ],
            })
          : "",
      };
    }
    if (boxSection) {
      newData[`.video-banner-section${countOfListValue}.bg-image::before`] = {
        ...newData[`.video-banner-section${countOfListValue}.bg-image::before`],
        "border-radius": `${settingFormData[
          `.video-banner-section${countOfListValue}`
        ]["border-radius"]?.replace("rem", "")}rem`,
      };
    }

    return newData;
  };

  /**
   * item video banner enable disable api call
   * @param {videoBannerId} videoBannerId
   * @param {status} isActive
   */

  const handleChangeVideoBanner = async (
    videoBannerId,
    isActive,
    isChild,
    parentId
  ) => {
    try {
      let body = {
        videoBannerId,
        isActive: isActive ? false : true,
        parentId,
      };
      const { data } = await updateStatus(body);
      if (data.status === 200) {
        if (isChild) {
          getVideoBannerParentList({ parentId: id });
        } else {
          ByteMiniWeb_Dashboard();
        }
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * file upload
   * @param {file} file
   */
  const imageUploadImages = async (file) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.VIDEO_BANNER);
    formData.append("file", file);
    setLoaderShow(true);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        setSettingFormData((prev) => {
          return {
            ...prev,
            [`.video-banner-section${countOfList}.bg-image`]: {
              "background-image": data.payload.baseUrl + data.payload.imageUrl,
            },
          };
        });

        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * create and update video banner
   */
  const submitForm = async () => {
    if (
      (isParent === false && childId >= -1 && isEdit) ||
      (id >= -1 && isEdit)
    ) {
      setLoaderShow(true);
      try {
        let formData = {};
        if (isParent && isParent !== false) {
          formData = { ...fromData, videoBannerId: id, contentMapId: collId };
        } else {
          formData = {
            ...formDataChild,
            parentId: id,
            navMapId,
            videoBannerId: childId,
            contentMapId: collId,
            style: await submitStyleValue(),
          };
        }
        const { data } = await putUpdateVideoBanner(formData);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            ByteMiniWeb_Dashboard();
          } else {
            getVideoBannerParentList({ parentId: id });
          }
          emptyData();
          OpenModalCommonFunc(
            Cases.VIDEO_BANNER_PARENT,
            setAllPopupState,
            false
          );
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    } else {
      try {
        setLoaderShow(true);
        let formData = {};
        if (isParent && isParent !== false) {
          formData = { ...fromData, contentMapId: collId };
        } else {
          formData = {
            ...formDataChild,
            parentId: id,
            navMapId,
            order: apiDataImageParent.data.length + 1,
            contentMapId: collId,
            style: await submitStyleValue(),
          };
        }
        const { data } = await postVideoBanner(formData);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            ByteMiniWeb_Dashboard();
          } else {
            getVideoBannerParentList({ parentId: id });
          }
          emptyData();
          OpenModalCommonFunc(
            Cases.VIDEO_BANNER_PARENT,
            setAllPopupState,
            false
          );
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };
  const handleSubmit = async (formValidationCondition) => {
    let validatorFields;
    if (formValidationCondition === "parentForm") {
      validatorFields = parentValidator.current;
    } else if (formValidationCondition === "childForm") {
      validatorFields = childValidator.current;
    }
    validatorFields.showMessages();

    if (validatorFields.allValid()) {
      try {
        if (formValidationCondition === "parentForm") {
          submitForm();
        } else if (formValidationCondition === "childForm") {
          submitForm();
        }
        validatorFields.hideMessages();
      } catch (error) {
        // Handle error for form submission
      }
    } else {
      forceUpdate({});
    }
  };

  /**
   * create and update video banner
   */
  const handleSubmitSetting = async (isRestore = false, isClose = true) => {
    if (id >= -1 && isEdit) {
      setLoaderShow(true);
      try {
        let newData;
        if (isRestore) {
          const isConfirm = window.confirm(
            "Are you sure want to restore style ?"
          );
          if (isConfirm) {
            newData = {
              styleRestore: true,
              videoBannerId,
              parentId,
              childId,
            };
          } else {
            setLoaderShow(false);
            return;
          }
        } else {
          newData = await submitStyleValue();
          const keysToRemove = [
            "bs1",
            "bs2",
            "bs3",
            "borderPx",
            "borderThik",
            "p1",
            "p2",
            "image",
          ];

          function removeKeys(obj, keys) {
            for (const key in obj) {
              if (typeof obj[key] === "object") {
                removeKeys(obj[key], keys);
              } else if (keys.includes(key)) {
                delete obj[key];
              }
            }
          }
          removeKeys(newData, keysToRemove);

          newData = {
            style: newData,
            videoBannerId,
            parentId,
            childId,
          };
        }
        const { data } = await putUpdateVideoBanner(newData);
        if (data.status === 200) {
          if (isClose) {
            OpenModalCommonFunc(
              Cases.SETTING_VIDEO_BANNER,
              setAllPopupState,
              false
            );
          }
          setLoaderShow(false);
          setBackImageToggle(false);
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  /**
   * get data video banner
   * @param {id} videoBannerId
   */
  const handleEditVideoBanner = async (videoBannerId, isParent) => {
    try {
      setLoaderShow(true);
      const { data } = await getVideoBannerDetails(videoBannerId);

      if (data.status === 200) {
        let payload = data.payload.data;

        if (isParent) {
          setSectionPageName(payload?.title);
          setIsParent(true);
          setId(videoBannerId);
          setFormData({
            title: payload?.title || "",
            isTitleShow: payload?.isTitleShow ? payload?.isTitleShow : false,
            subTitle: payload?.subTitle || "",
            navMapId: payload?.navMapId || "",
            webId: localStorage.getItem("webId"),
            style: payload?.style || {
              [`.video-banner-section${countOfList}`]: {
                padding: "200px 0",
                "background-color": "#000000",
                width: "",
                margin: "",
                "border-radius": "",
              },
              image: "",
              [`.video-banner-section${countOfList}.bg-image`]: {
                "background-image": "",
              },
              [`.video-banner-section${countOfList}.bg-image::before`]: {
                "background-color": "rgba(0, 0, 0, 0.8)",
              },
              [`.video-banner-section${countOfList} .video-play`]: {
                height: "80px",
                width: "80px",
                "background-color": "#ffffff",
                color: "#ff497c",
                "text-align": "center",
                padding: "25px 5px",
                "font-size": "25px",
                "border-radius": "100%",
                display: "inline-block",
              },
              ".videomodal .framediv iframe": {
                "box-shadow": "0 0 8px #00000099",
                bs1: "0px",
                bs2: "0px",
                bs3: "8px",
                shadowColor: "#00000099",
                "background-color": "#000",
              },
              videoBannerId: "",
            },
          });
          OpenModalCommonFunc(Cases.VIDEO_BANNER_PARENT, setAllPopupState);
        } else {
          setChildId(videoBannerId);
          setIsParent(false);
          setFormDataChild({
            title: payload?.title || "",
            subTitle: payload?.subTitle || "",
            videLink: payload?.videLink || "",
            buttonText: payload?.buttonText || "",
            isTitleShow: payload?.isTitleShow ? payload?.isTitleShow : false,
            buttonValue: payload?.buttonValue || "",
            description: payload?.description || "",
            parentId: payload?.parentId || "",
            order: payload?.order || 1,
            webId: localStorage.getItem("webId"),
          });
          OpenModalCommonFunc(Cases.VIDEO_BANNER, setAllPopupState);
        }
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * get setting data
   * @param {id} videoBannerId
   */
  const handleChangeSettings = async (videoBannerId) => {
    try {
      setLoaderShow(true);
      // setId(videoBannerId);
      setVideoBannerId(videoBannerId);
      setSettingFormData((prev) => {
        return {
          ...prev,
          videoBannerId,
        };
      });
      const { data } = await getVideoBannerDetails(videoBannerId);

      if (data.status === 200) {
        let payload = data.payload.data;
        setCountOfList(payload?.style?.countOfList);
        setChildId(videoBannerId);
        if (
          payload.style?.[
            `.video-banner-section${payload?.style?.countOfList}`
          ]?.["width"] ||
          payload.style?.[
            `.video-banner-section${payload?.style?.countOfList}`
          ]?.["margin"] ||
          payload.style?.[
            `.video-banner-section${payload?.style?.countOfList}`
          ]?.["border-radius"]
        ) {
          setBoxSection(true);
        }
        if (
          payload.style?.[
            `.video-banner-section${payload?.style?.countOfList}.bg-image`
          ]?.["background-image"]
        ) {
          setBackImageToggle(true);
        }
        if (
          payload.style?.[
            `.video-banner-section${payload?.style?.countOfList}`
          ]?.["background-color"]
        ) {
          setIsBackgroundColor(true);
        }
        setParentId(payload.parentId);
        setSettingFormData((prev) => {
          return {
            ...prev,
            [`.video-banner-section${payload?.style?.countOfList}`]: {
              padding:
                payload.style?.[
                  `.video-banner-section${payload?.style?.countOfList}`
                ]?.["padding"],
              "background-color": payload.style?.[
                `.video-banner-section${payload?.style?.countOfList}`
              ]?.["background-color"]
                ? convertRGBA({
                    rgbString:
                      payload.style?.[
                        `.video-banner-section${payload?.style?.countOfList}`
                      ]?.["background-color"],
                  })
                : "",
              width: payload.style?.[
                `.video-banner-section${payload?.style?.countOfList}`
              ]?.["width"]?.replace("calc(100% - ", ""),
              margin:
                payload.style?.[
                  `.video-banner-section${payload?.style?.countOfList}`
                ]?.["margin"],
              "border-radius":
                payload.style?.[
                  `.video-banner-section${payload?.style?.countOfList}`
                ]?.["border-radius"],
            },
            [`.video-banner-section${payload?.style?.countOfList}.bg-image`]: {
              "background-image": payload.style?.[
                [`.video-banner-section${payload?.style?.countOfList}.bg-image`]
              ]?.["background-image"]
                ? removeUrlKeyInPath({
                    urlString:
                      payload.style?.[
                        `.video-banner-section${payload?.style?.countOfList}.bg-image`
                      ]?.["background-image"],
                  })
                : "",
            },
            [`.video-banner-section${payload?.style?.countOfList}.bg-image::before`]:
              {
                "background-color": payload.style?.[
                  `.video-banner-section${payload?.style?.countOfList}.bg-image::before`
                ]?.["background-color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          `.video-banner-section${payload?.style?.countOfList}.bg-image::before`
                        ]?.["background-color"],
                    })
                  : "",
              },
            [`.video-banner-section${payload?.style?.countOfList} .video-play`]:
              {
                height:
                  payload.style?.[
                    `.video-banner-section${payload?.style?.countOfList} .video-play`
                  ]?.["height"],
                width:
                  payload.style?.[
                    `.video-banner-section${payload?.style?.countOfList} .video-play`
                  ]?.["width"],
                "background-color": payload.style?.[
                  `.video-banner-section${payload?.style?.countOfList} .video-play`
                ]?.["background-color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          `.video-banner-section${payload?.style?.countOfList} .video-play`
                        ]?.["background-color"],
                    })
                  : "",
                color: payload.style?.[
                  `.video-banner-section${payload?.style?.countOfList} .video-play`
                ]?.["color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          `.video-banner-section${payload?.style?.countOfList} .video-play`
                        ]?.["color"],
                    })
                  : "",
                "text-align":
                  payload.style?.[
                    `.video-banner-section${payload?.style?.countOfList} .video-play`
                  ]?.["text-align"],
                padding:
                  payload.style?.[
                    `.video-banner-section${payload?.style?.countOfList} .video-play`
                  ]?.["padding"],
                p1: payload.style?.[
                  `.video-banner-section${payload?.style?.countOfList} .video-play`
                ]?.["padding"]?.split(" ")[0],
                p2: payload.style?.[
                  `.video-banner-section${payload?.style?.countOfList} .video-play`
                ]?.["padding"]?.split(" ")[1],
                "font-size":
                  payload.style?.[
                    `.video-banner-section${payload?.style?.countOfList} .video-play`
                  ]?.["font-size"],
                "border-radius":
                  payload.style?.[
                    `.video-banner-section${payload?.style?.countOfList} .video-play`
                  ]?.["border-radius"],
                display: "inline-block",
                " -webkit-animation": "pulse11 1s infinite",
                animation: "pulse11 1s infinite",
              },
            ".videomodal .framediv iframe": {
              "box-shadow":
                payload.style?.[".videomodal .framediv iframe"]?.["box-shadow"],
              bs1: payload.style?.[".videomodal .framediv iframe"]?.[
                "box-shadow"
              ]?.split(" ")[0],
              bs2: payload.style?.[".videomodal .framediv iframe"]?.[
                "box-shadow"
              ]?.split(" ")[1],
              bs3: payload.style?.[".videomodal .framediv iframe"]?.[
                "box-shadow"
              ]?.split(" ")[2],
              shadowColor: payload.style?.[".videomodal .framediv iframe"]?.[
                "shadowColor"
              ]
                ? convertRGBA({
                    rgbString:
                      payload.style?.[".videomodal .framediv iframe"]?.[
                        "shadowColor"
                      ],
                  })
                : "",
              "background-color": payload.style?.[
                ".videomodal .framediv iframe"
              ]?.["background-color"]
                ? convertRGBA({
                    rgbString:
                      payload.style?.[".videomodal .framediv iframe"]?.[
                        "background-color"
                      ],
                  })
                : "",
            },
            custom: payload.style.custom,
          };
        });
        OpenModalCommonFunc(Cases.SETTING_VIDEO_BANNER, setAllPopupState);
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * delete video banner
   * @param {id} videoBannerId
   */
  const handleDelete = async (videoBannerId, isParent = false) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await deleteVideoBanner(videoBannerId);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            ByteMiniWeb_Dashboard();
            // getVideoBannerParentList(null, true);
          } else {
            getVideoBannerParentList({ parentId: childId });
          }
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * clear data
   */
  const emptyData = () => {
    setVideoBannerId();
    setIsEdit(false);
    setIsParent(false);
    setFormData({
      title: "",
      subTitle: "",
      navMapId: "",
      isTitleShow: true,
      theme: 1,
      webId: localStorage.getItem("webId"),
      style: {
        [`.video-banner-section${countOfList}`]: {
          padding: "200px 0",
          "background-color": "rgb(0 0 0 / 100)",
        },
        [`.video-banner-section${countOfList}.bg-image`]: {
          "background-image": "",
        },
        [`.video-banner-section${countOfList}.bg-image::before`]: {
          "background-color": "",
          // "background-color": "rgb(0 0 0 / 0.8)",
        },
        [`.video-banner-section${countOfList} .video-play`]: {
          height: "80px",
          width: "80px",
          "background-color": "rgb(255 255 255 / 100)",
          color: "rgb(255 73 124 / 100)",
          "text-align": "center",
          padding: "25px 5px",
          "font-size": "25px",
          "border-radius": "100%",
          display: "inline-block",
        },
        ".videomodal .framediv iframe": {
          "box-shadow": "0 0 8px rgb(0 0 0 / 0.6)",
          "background-color": "rgb(0 0 0 / 100)",
          bs1: "0px",
          bs2: "0px",
          bs3: "8px",
          shadowColor: "rgb(0 0 0 / 0.6)",
        },
        videoBannerId: "",
      },
    });
    setSkill([{ id: randomStr(2), skill: "", parentageOfSkill: "" }]);
    setGalleryImgId("");
    setFormDataChild({
      title: "",
      subTitle: "",
      videLink: "",
      buttonText: "",
      buttonValue: "",
      isTitleShow: true,
      description: "",
      order: 1,
      webId: localStorage.getItem("webId"),
      navMapId: "",
      style: {
        [`.video-banner-section${countOfList}`]: {
          padding: "200px 0",
          "background-color": "rgb(0 0 0 / 100)",
        },
        [`.video-banner-section${countOfList}.bg-image`]: {
          "background-image": "",
        },
        [`.video-banner-section${countOfList}.bg-image::before`]: {
          "background-color": "",
          // "background-color": "rgb(0 0 0 / 0.8)",
        },
        [`.video-banner-section${countOfList} .video-play`]: {
          height: "80px",
          width: "80px",
          "background-color": "rgb(255 255 255 / 100)",
          color: "rgb(255 73 124 / 100)",
          "text-align": "center",
          padding: "25px 5px",
          "font-size": "25px",
          "border-radius": "100%",
          display: "inline-block",
        },
        ".videomodal .framediv iframe": {
          "box-shadow": "0 0 8px rgb(0 0 0 / 0.6)",
          bs1: "0px",
          bs2: "0px",
          bs3: "8px",
          shadowColor: "rgb(0 0 0 / 0.6)",
          "background-color": "rgb(0 0 0 / 100)",
        },
        videoBannerId: "",
      },
    });
    setLoaderShow(false);
    setSettingFormData({
      [`.video-banner-section${childId}`]: {
        padding: "200px 0",
        "background-color": "rgb(0 0 0 / 100)",
      },
      [`.video-banner-section${childId}.bg-image`]: {
        "background-image": "",
      },
      [`.video-banner-section${childId}.bg-image::before`]: {
        "background-color": "rgb(0 0 0 / 0.8)",
      },
      [`.video-banner-section${childId} .video-play`]: {
        height: "80px",
        width: "80px",
        "background-color": "rgb(255 255 255 / 100)",
        color: "rgb(255 73 124 / 100)",
        "text-align": "center",
        padding: "25px 5px",
        p1: "25px",
        p2: "5px",
        "font-size": "25px",
        "border-radius": "100%",
      },
      ".videomodal .framediv iframe": {
        "box-shadow": "0 0 8px rgb(0 0 0 / 0.6)",
        bs1: "0px",
        bs2: "0px",
        bs3: "8px",
        shadowColor: "rgb(0 0 0 / 0.6)",
        "background-color": "rgb(0 0 0 / 100)",
      },
      videoBannerId: "",
    });
    setChildId("");
  };

  const value = useMemo(() => {
    let [label] = navbarData.filter(
      (elem) => elem.navbarDetail.label === titleUpdateKey.Certificates
    );
    return label;
  }, [navbarData]);

  const handleAlignAndCloneSection = ({ isClone = false }) => {
    if (isClone) {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          cloneModal: true,
        };
      });
      setCloneFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          pageConfigDetailsLabel: value?.label || pageConfigDetails[0]?.label,
          contentDetailName: contentDetail.name,
        };
      });
    } else {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          alignModal: true,
        };
      });
      setAlignFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          label: value?.label || pageConfigDetails[0]?.label,
        };
      });
    }
  };

  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody
            className="support-ticket-font box-border-div"
            style={{ padding: "20px" }}
          >
            <Row>
              <CardBuilderHeaderSection
                addBtnTitle={pageConfigDetails[0]?.label}
                name={value?.label || pageConfigDetails[0]?.label}
                isAdd={!videoBannerDetail && true}
                mainEditSection={true}
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
                openModal={() => {
                  OpenModalCommonFunc(
                    Cases.VIDEO_BANNER_PARENT,
                    setAllPopupState
                  );
                  setSectionPageName(modalsName.VIDEO_BANNER_PARENT);
                  emptyData();
                  setIsParent(true);
                  setCollId(contentMapId);
                }}
                isCloneSection={true}
                isAlignSection={true}
                handleAlignAndCloneSection={handleAlignAndCloneSection}
              />
              {videoBannerDetail && (
                <Col xl="12 xl-50 box-col-6" md="6">
                  <Card className="ecommerce-widget mb-0">
                    <CardBody
                      className="support-ticket-font"
                      style={{ padding: "20px" }}
                    >
                      <div className="col-12">
                        <CardBuilderHeaderSection
                          name={videoBannerDetail?.title}
                          checked={videoBannerDetail?.isActive}
                          switchToggle={true}
                          id={videoBannerDetail?.videoBannerId}
                          handleChange={handleChangeVideoBanner}
                        />
                        <OpenPopupBtn
                          openPopup={() => {
                            getVideoBannerParentList({
                              parentId: videoBannerDetail.videoBannerId,
                            });
                            setSectionPageName(videoBannerDetail?.title);
                            setNavMapId(videoBannerDetail?.navMapId);
                            setFilter("");
                            setCollId(contentMapId);
                          }}
                          name={`Add or View ${videoBannerDetail?.title}`}
                        />
                      </div>
                      <div className="d-flex justify-content-end ">
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleEditVideoBanner(
                              videoBannerDetail.videoBannerId,
                              true
                            );
                            setCollId(contentMapId);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() =>
                            handleDelete(videoBannerDetail.videoBannerId, true)
                          }
                        >
                          <Trash />
                        </IconButton>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
              <DeleteSection
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
              />
            </Row>
          </CardBody>
        </Card>
      </Col>
      <CommonPopup
        open={allPopupState.videoBannerChildPopup}
        close={() => {
          OpenModalCommonFunc(Cases.VIDEO_BANNER, setAllPopupState, false);
          setIsParent(false);
        }}
        // apiCall={getVideoBannerParentList}
        modalName={sectionPageName ? sectionPageName : modalsName.VIDEO_BANNER}
        modalCase={Cases.VIDEO_BANNER}
        handleSubmit={handleSubmit}
        validationForm={"childForm"}
        emptyData={emptyData}
        inputData={fromData}
        edit={false}
        oldTitle={titleUpdateKey.isGallery}
        label={titleUpdateKey.Certificates}
        getData={getData}
        content={
          <VideoBannerPopup
            getVideoBannerParentList={getVideoBannerParentList}
            handleChangeStatus={handleChangeVideoBanner}
            handleEdit={handleEditVideoBanner}
            imageUpload={imageUploadImages}
            emptyData={emptyData}
            simpleValidator={childValidator}
            webMasterDetail={webMasterDetail}
            handleDelete={handleDelete}
            handleChangeSettings={handleChangeSettings}
          />
        }
      />

      {/* item video parent popup */}
      <CommonPopup
        open={allPopupState.videoBannerPopup}
        close={() => {
          OpenModalCommonFunc(
            Cases.VIDEO_BANNER_PARENT,
            setAllPopupState,
            false
          );
          setIsParent(false);
        }}
        modalName={
          sectionPageName ? sectionPageName : modalsName.VIDEO_BANNER_PARENT
        }
        modalCase={Cases.VIDEO_BANNER_PARENT}
        handleSubmit={handleSubmit}
        validationForm={"parentForm"}
        emptyData={emptyData}
        inputData={formDataChild}
        edit={false}
        content={<VideoBannerParentPopup simpleValidator={parentValidator} />}
      />

      {/* setting Service Counter parent popup */}
      <CommonPopup
        open={allPopupState.videoBannerSettingPopup}
        close={() =>
          OpenModalCommonFunc(
            Cases.SETTING_VIDEO_BANNER,
            setAllPopupState,
            false
          )
        }
        modalName={
          sectionPageName ? sectionPageName : modalsName.SETTING_VIDEO_BANNER
        }
        modalCase={Cases.SETTING_VIDEO_BANNER}
        handleSubmit={handleSubmitSetting}
        emptyData={emptyData}
        inputData={formDataChild}
        edit={false}
        content={
          <SettingVideoBannerParentPopup
            imageUpload={imageUploadImages}
            dataLength={apiDataImageParent?.data?.length}
            handleSubmitSetting={handleSubmitSetting}
          />
        }
      />
    </>
  );
};

export default VideoBanner;
