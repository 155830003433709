import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import {
  OpenModalCommonFunc,
  TostMessage,
} from "../../../../components ByteCard/utils";
import {
  Cases,
  DELETE_CONFIRM,
} from "../../../../components ByteCard/constant";
import Select from "react-select";
import { UploadCloud } from "react-feather";
import { Close } from "@mui/icons-material";
import ByteCardBuilderContext from "../../../../components ByteCard/context/BytecardBilderContext/ByteCardBuilderContext";
import { IconButton } from "@mui/material";
import { delete_Image_Permanent } from "../../../../components ByteCard/apis/AboutApi/AboutUsApi";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import ProductImageUploadPopup from "../../../../components ByteCard/Modals/imageUploade/productImageUploadPopup";
import CategoryContext from "../../../../context/MenuMasterContext/CategoryContext/CategoryContext";
import Products from "../../../../components ByteCard/components/MultipageByteCardBuilder/products/Products";
import { ProductsList } from "../../../../api/MenuMasterApi/ProductsApi/ProductsApi";

const CategoryAddPopup = (props) => {
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const { handleSubmit, simpleValidator, imageUpload, QpiId, emptyData } =
    props;
  const { addModel, setAddModel, formData, setFormData, isEdit } =
    useContext(CategoryContext);
  const { setLoaderShow } = useContext(LoaderContext);

  const CloseModel = () => {
    emptyData();
    setAddModel(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const handleChangeSelect = (selectedOption, name) => {
    setFormData((prev) => ({
      ...prev,
      [name]: selectedOption,
    }));
  };

  /**
   * reupload image
   */
  const reupload = () => {
    setFormData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };

  const deleteImagePermanent = async (imgName) => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formDatas = {
          fileName: formData[`${imgName}`]
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
            .pop(),
        };
        const { data } = await delete_Image_Permanent(formDatas);
        if (data.status === 200) {
          TostMessage({ message: data, type: "success", position: "center" });
          setFormData({ ...formData, image: "" });
          setFormData((prev) => {
            return {
              ...prev,
              [`${imgName}`]: "",
              image: "",
            };
          });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };
  // menuProductIds
  const [Products, setProducts] = useState([]);
  const getProductsList = async () => {
    try {
      let paramObj = {
        showAll: true,
        qpiId: QpiId,
      };
      const { data } = await ProductsList(paramObj);

      if (data.status === 200) {
        setProducts(
          data.payload.data.map((ele) => {
            return {
              value: ele.menuProductId,
              label: ele.name,
            };
          })
        );
      } else {
      }
    } catch (error) {}
  };
  useEffect(() => {
    getProductsList();
  }, []);

  return (
    <>
      <Modal size="xl" isOpen={addModel} toggle={() => CloseModel()}>
        <ModalHeader toggle={() => CloseModel()}>
          {isEdit ? "Edit" : "Add"} Category
        </ModalHeader>
        <ModalBody>
          <Container fluid={true} className="p-0">
            <Row>
              <Form className="theme-form" onSubmit={(e) => handleSubmit(e)}>
                <Row>
                  <Col md="6 mb-4">
                    <Label>{"Name"}</Label>
                    <Input
                      className="form-control"
                      type={"text"}
                      style={{ color: "black" }}
                      name="name"
                      onChange={(e) => handleChange(e)}
                      value={formData.name}
                      placeholder="Name"
                    />
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "name",
                        formData.name,
                        "required"
                      )}
                    </p>
                  </Col>
                  <Col md="6 mb-4">
                    <Label>{"Menu Order"}</Label>
                    <Input
                      className="form-control"
                      type={"number"}
                      name="menuOrder"
                      style={{ color: "black" }}
                      onChange={(e) => handleChange(e)}
                      value={formData.menuOrder}
                      placeholder="Menu Order"
                    />
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "menuOrder",
                        formData.menuOrder,
                        "required"
                      )}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md="6 mb-3">
                    <label htmlFor="">{"Image"}</label>
                    <div
                      className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                      style={{ cursor: "pointer" }}
                    >
                      <span
                        onClick={() => {
                          OpenModalCommonFunc(
                            Cases.PRODUCT_UPLOAD,
                            setAllPopupState
                          );
                          setFormData((prev) => {
                            return {
                              ...prev,
                              imageName: "image",
                            };
                          });
                        }}
                      >
                        <UploadCloud />
                      </span>
                      {formData?.image && (
                        <img
                          src={formData?.image ? formData?.image : ""}
                          alt=""
                          style={{
                            width: "85%",
                            height: "90px",
                            objectFit: "contain",
                            position: "absolute",
                          }}
                          onClick={() => {
                            OpenModalCommonFunc(
                              Cases.PRODUCT_UPLOAD,
                              setAllPopupState
                            );
                            setFormData((prev) => {
                              return {
                                ...prev,
                                imageName: "image",
                              };
                            });
                          }}
                        />
                      )}
                      {formData?.image && (
                        <IconButton
                          edge="start"
                          color="inherit"
                          aria-label="close"
                          style={{
                            color: "red",
                            position: "absolute",
                            top: "5px",
                            left: "1px",
                          }}
                          onClick={() => deleteImagePermanent("image")}
                        >
                          <Close
                            style={{ fontSize: "25px", fontWeight: "bold" }}
                          />
                        </IconButton>
                      )}
                    </div>
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "image",
                        formData.image,
                        "required"
                      )}
                    </p>
                  </Col>
                  <Col md="6 mb-4">
                    <Label htmlFor="validationCustom03">
                      {"Select Products"}
                    </Label>
                    <Select
                      isMulti
                      name="menuProductIds"
                      closeMenuOnSelect={false}
                      options={Products}
                      value={formData.menuProductIds}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => handleChangeSelect(e, "menuProductIds")}
                    />
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "Products",
                        formData.menuProductIds,
                        "required"
                      )}
                    </p>
                  </Col>
                </Row>

                <div className="login-btn mt-2">
                  <Button color="primary" type="submit" value="submit">
                    {"Submit"}
                  </Button>
                </div>
              </Form>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
      <ProductImageUploadPopup
        allPopupState={allPopupState}
        setAllPopupState={setAllPopupState}
        crop={crop}
        setCrop={setCrop}
        completedCrop={completedCrop}
        setCompletedCrop={setCompletedCrop}
        scale={scale}
        rotate={rotate}
        image={formData?.image}
        setImage={setFormData}
        imageUpload={imageUpload}
        // isEdit={isEdit}
        reupload={reupload}
        // aspect={22.68 / 1}
      />
    </>
  );
};

export default CategoryAddPopup;
