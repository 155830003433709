import React, { useState } from "react";
import SocialMediaContext from "./SocialMediaContext";

const SocialMediaState = (props) => {
  const [apiData, setApiData] = useState({ data: [], isLoading: false });
  const [socialMediaMaster, setSocialMediaMaster] = useState([]);

  const [fromData, setFormData] = useState({
    label: "",
    value: "",
    itemId: "",
    image: "",
    type: "",
    dialCode: { value: "+91", label: "+91" },
    webId: localStorage.getItem("webId"),
  });

  const [images, setImages] = useState([
    { path: "", isDefault: true },
    { path: "", isDefault: false },
    { path: "", isDefault: false },
    { path: "", isDefault: false },
  ]);
  const [index, setIndex] = useState("");
  const [id, setId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [filter, setFilter] = useState("");
  return (
    <SocialMediaContext.Provider
      value={{
        apiData,
        setApiData,
        socialMediaMaster,
        setSocialMediaMaster,
        fromData,
        setFormData,
        images,
        setImages,
        id,
        setId,
        isEdit,
        setIsEdit,
        filter,
        setFilter,
        index,
        setIndex,
      }}
    >
      {props.children}
    </SocialMediaContext.Provider>
  );
};

export default SocialMediaState;
