import React, { useContext, useMemo, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import { Cases, DELETE_CONFIRM, modalsName } from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import Lightbox from "react-image-lightbox";
import CommonPopup from "../../../Modals/commonPopup";
import OurTeamMemberPopup from "./OurTeamMemberPopup";
import { Settings } from "react-feather";
import {
  deleteOurTeam,
  getOurTeamDetails,
  getOurTeamMemberDetails,
  getOutTeam,
  getOutTeamMember,
  postOurTeam,
  postOurTeamMember,
  putUpdateOurTeam,
  putUpdateOurTeamMember,
  updateStatus,
  updateStatusOurTeam,
} from "../apis/OurTeamApi/OurTeamApi";
import { FILE_TYPE, ThemeNo } from "../../../../constant";
import { FILE_UPLOAD_API } from "../apis/SliderApi/SliderApi";
import OurTeamPopup from "./OurTeamPopup";
import { IconButton } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import { Trash } from "react-feather";
import {
  convertRGBA,
  generateDynamicStyles,
  generateDynamicStylesSet,
  isEmptyObject,
  randomStr,
  removeUrlKeyInPath,
  rgbColorCreate,
} from "../../../../utils/helper";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import SimpleReactValidator from "simple-react-validator";
import Multipage_OurTeamContext from "../../../context/multipage_OurTeamContext/Multipage_OurTeamContext";
import SettingOurTeamPerentPopup from "./SettingOurTeamPerentPopup";
import {
  OurTeamOneSubFun,
  OurTeamThemeOneFunction,
  OurTeamThemeTwoFunction,
  OurTeamTwoSubFun,
  ourTeamStyleOneReset,
  ourTeamStyleTwoReset,
} from "./OurTeamStyleFunction";
import DeleteSection from "../../../common/DeleteSection";
import SettingMultiWebContext from "../../../../context/SettingMultiWebContext/SettingMultiWebContext";

const OurTeam = (props) => {
  const {
    pageConfigDetails,
    contentMapId,
    ourTeamDetail,
    ByteMiniWeb_Dashboard,
    navMapId: navMapIds,
    contentDetail,
  } = props;
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );
  const { setCloneAlignModal, setAlignFormData, setCloneFormData } = useContext(
    SettingMultiWebContext
  );
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const simpleValidatorTeamMember = useRef(new SimpleReactValidator());
  const [, forceUpdate1] = useState();
  const { setLoaderShow } = useContext(LoaderContext);
  const {
    apiData: ourTeamData,
    setApiData,
    fromData,
    setFormData,
    formDataOurTeam,
    setFormDataOurData,
    id,
    setId,
    setIsEdit,
    isEdit,
    filter,
    apiDataOurTeam,
    setApiDataOurTeam,
    skill,
    setSkill,
    teamMemberId,
    setTeamMemberId,
    collId,
    setCollId,
    settingFormData,
    boxSection,
    setBoxSection,
    setSettingFormData,
    setBackImageToggle,
    setBackImageToggle1,
    setBoxSection1,
    boxSection1,
    backImgNo,
    setBackImgNo,
    isBackgroundColor,
    setIsBackgroundColor,
    isBackgroundColor2,
    setIsBackgroundColor2,
    theme,
    setTheme,
    setImgShow,
    sectionPageName,
    setSectionPageName,
  } = useContext(Multipage_OurTeamContext);
  const [open, setOpen] = useState(false);
  const [imageSet] = useState("");

  /**
   * get our team member list
   */
  const getOurTeamMember = async (status) => {
    try {
      if (!filter.length && status) {
        setApiData((prev) => {
          return { ...prev, isLoading: true };
        });
      }
      let paramObj = {
        search: { teamId: id, contentMapId },
        showAll: true,
      };

      if (filter.length) {
        paramObj.search = { ...paramObj.search, title: filter };
      }
      const { data } = await getOutTeamMember(paramObj);
      if (data.status === 200) {
        setApiData({
          ...ourTeamData,
          isLoading: false,
          data: data.payload.data.map((elem) => {
            return {
              id: elem?.title + elem?.teamMemberId,
              Id: elem?.teamMemberId,
              name: elem?.title,
              image: elem?.image,
              webId: elem?.webId,
              headLine: elem?.headLine,
              createdAt: elem?.createdAt,
              isActive: elem?.isActive,
              parentId: elem?.parentId,
            };
          }),
        });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };
  /**
   * get our team list
   * @param {status} status
   */
  const getOurTeamList = async (status) => {
    try {
      if (!filter?.length && status) {
        setApiDataOurTeam((prev) => {
          return { ...prev, isLoading: true };
        });
      }

      let paramObj = {
        search: { contentMapId },
      };
      if (filter?.length) {
        paramObj["search"] = { title: filter };
      }
      const { data } = await getOutTeam(paramObj);
      if (data.status === 200) {
        setApiDataOurTeam({
          ...apiDataOurTeam,
          isLoading: false,
          data: data.payload.data,
        });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  useMemo(() => {
    if (allPopupState?.ourteamMemberPopup) {
      getOurTeamMember();
    }
  }, [filter, allPopupState?.ourteamMemberPopup]);

  /**
   * our team member enable disable api call
   * @param {teamMember id} teamMemberId
   * @param {status} isActive
   */

  const handleChangeStatus = async (
    teamMemberId,
    isActive,
    isChild,
    parentId
  ) => {
    try {
      let body = {
        teamMemberId,
        isActive: isActive ? false : true,
        parentId,
      };
      const { data } = await updateStatus(body);
      if (data.status === 200) {
        if (isChild) {
          getOurTeamMember(false);
        } else {
          ByteMiniWeb_Dashboard();
        }
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };
  /**
   * our team enable disable api call
   * @param {team id} teamId
   * @param {status} isActive
   */

  const handleChangeOurTeamStatus = async (teamId, isActive) => {
    try {
      let body = {
        teamId,
        isActive: isActive ? false : true,
      };
      const { data } = await updateStatusOurTeam(body);
      if (data.status === 200) {
        ByteMiniWeb_Dashboard();
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * file upload
   * @param {file} file
   */
  const imageUpload = async (file, name) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.BYTE_SLIDER);
    formData.append("file", file);
    setLoaderShow(true);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        setFormDataOurData((prev) => {
          return {
            ...prev,
            image: data.payload.baseUrl + data.payload.imageUrl,
          };
        });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const backImageUploadImages = async (file) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.OUR_TEAM);
    formData.append("file", file);
    setLoaderShow(true);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        setSettingFormData((prev) => {
          if (backImgNo === ".team-section.bg-image") {
            return {
              ...prev,
              ".team-section.bg-image": {
                "background-image":
                  data.payload.baseUrl + data.payload.imageUrl,
              },
            };
          } else if (backImgNo === ".ourteam-section.bg-image") {
            return {
              ...prev,
              ".ourteam-section.bg-image": {
                "background-image":
                  data.payload.baseUrl + data.payload.imageUrl,
              },
            };
          }
          if (backImgNo === ".team-detail-section.bg-image") {
            return {
              ...prev,
              ".team-detail-section.bg-image": {
                "background-image":
                  data.payload.baseUrl + data.payload.imageUrl,
              },
            };
          }
        });

        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * file upload
   * @param {file} file
   */
  const imageUploadTeamMember = async (file) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.OUR_TEAM);
    formData.append("file", file);
    setLoaderShow(true);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        setFormData((prev) => {
          return {
            ...prev,
            image: data.payload.baseUrl + data.payload.imageUrl,
          };
        });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * create and update our team member
   */
  const handleSubmit = async () => {
    const formValid = simpleValidatorTeamMember.current.allValid();
    if (!formValid) {
      simpleValidatorTeamMember.current.showMessages();
      forceUpdate1(1);
    } else {
      if (teamMemberId >= -1 && isEdit) {
        setLoaderShow(true);
        let newData = {
          ...fromData,
          teamId: id,
          skill: skill.filter(
            (item) => item.skill !== "" || item.parentageOfSkill !== ""
          ),
          socialMedia: fromData.socialMedia.filter(
            (item) => item.name !== "" || item.link !== ""
          ),
          teamMemberId,
          contentMapId: collId,
        };
        delete newData.order;
        if (fromData.image.length) {
          setFormData(newData);
          newData["image"] = fromData.image
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
            .pop();
        }
        try {
          const { data } = await putUpdateOurTeamMember(newData);
          if (data.status === 200) {
            getOurTeamMember();
            emptyData();
            TostMessage({ message: data, type: "success" });
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      } else {
        try {
          setLoaderShow(true);
          let newData = {
            ...fromData,
            teamId: id,
            skill: skill.filter(
              (item) => item.skill !== "" || item.parentageOfSkill !== ""
            ),
            socialMedia: fromData.socialMedia.filter(
              (item) => item.name !== "" || item.link !== ""
            ),
            order: ourTeamData.data.length + 1,
            contentMapId: collId,
          };
          if (fromData.image.length) {
            setFormData(newData);
            newData["image"] = fromData.image
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
              .pop();
          }
          const { data } = await postOurTeamMember(newData);
          if (data.status === 200) {
            getOurTeamMember();
            emptyData();
            TostMessage({ message: data, type: "success" });
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      }
    }
  };

  /**
   * create and update our team
   */
  const handleSubmitOurTeam = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      if (id >= -1 && isEdit) {
        setLoaderShow(true);
        let newData = {
          ...formDataOurTeam,
          contentMapId: collId,
          navMapId: +navMapIds,
        };
        if (fromData.image.length) {
          setFormDataOurData((prev) => {
            return {
              ...prev,
              image: prev.image
                .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
                .pop(),
              contentMapId: collId,
            };
          });
          newData["image"] = formDataOurTeam.image
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
            .pop();
        }
        try {
          const { data } = await putUpdateOurTeam(newData);
          if (data.status === 200) {
            getOurTeamList();
            emptyData();
            OpenModalCommonFunc(Cases.OUR_TEAM, setAllPopupState, false);
            TostMessage({ message: data, type: "success" });
            ByteMiniWeb_Dashboard();
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      } else {
        try {
          setLoaderShow(true);
          let newData = {
            ...formDataOurTeam,
            contentMapId: collId,
            navMapId: +navMapIds,
          };
          if (fromData.image.length) {
            setFormDataOurData((prev) => {
              return {
                ...prev,
                image: prev.image
                  .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
                  .pop(),
                contentMapId: collId,
              };
            });
            newData["image"] = formDataOurTeam.image
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
              .pop();
          }
          const { data } = await postOurTeam(newData);
          if (data.status === 200) {
            getOurTeamList();
            emptyData();
            OpenModalCommonFunc(Cases.OUR_TEAM, setAllPopupState, false);
            TostMessage({ message: data, type: "success" });
            ByteMiniWeb_Dashboard();
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      }
    }
  };

  /**
   * get our team member details
   * @param {id} teamMemberId
   */
  const handleEdit = async (teamMemberId) => {
    try {
      setLoaderShow(true);
      setTeamMemberId(teamMemberId);
      const { data } = await getOurTeamMemberDetails(teamMemberId);
      if (data.status === 200) {
        let payload = data.payload.data;
        setFormData({
          title: payload.title || "",
          subTitle: payload.subTitle || "",
          isTitleShow: payload.isTitleShow || true,
          description: payload.description || "",
          slugUrl: payload.slugUrl,
          image: payload.image || "",
          hyperLink: payload.hyperLink || "",
          socialMedia: payload.socialMedia?.length
            ? payload.socialMedia
            : [{ id: randomStr(2), name: "", link: "" }],
          teamId: payload.teamId,
          webId: localStorage.getItem("webId"),
        });
        setSkill(
          payload?.skill?.length
            ? payload?.skill
            : [{ id: randomStr(2), skill: "", parentageOfSkill: "" }]
        );
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * get data our team
   * @param {id} teamId
   */
  const handleEditOurTeam = async (teamId) => {
    try {
      setLoaderShow(true);
      setId(teamId);
      let formData = {
        teamId,
        navMapId: +navMapIds,
      };
      const { data } = await getOurTeamDetails(formData);
      if (data.status === 200) {
        let payload = data.payload.data;
        setSectionPageName(payload.title);
        setFormDataOurData({
          title: payload.title || "",
          isTitleShow: payload?.isTitleShow ? payload?.isTitleShow : false,
          headLine: payload.headLine || "",
          itemLimit: payload.itemLimit || "",
          buttonText: payload.buttonText || "",
          navMapId: payload.navMapId || "",
          image: "" || "",
          description: payload.description || "",
          theme: payload.theme || 1,
          teamId: teamId,
          slugUrl: payload?.slugUrl || "",
          webId: localStorage.getItem("webId"),
        });
        OpenModalCommonFunc(Cases.OUR_TEAM, setAllPopupState);
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(true);
    }
  };

  /**
   * delete our team
   * @param {id} teamId
   */
  const handleDelete = async (teamId, isParent = false) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await deleteOurTeam(teamId);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            ByteMiniWeb_Dashboard();
          } else {
            getOurTeamList();
          }

          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * create and update images
   */
  const handleSubmitSetting = async (isRestore = false, isClose = true) => {
    if (id >= -1 && isEdit) {
      setLoaderShow(true);
      try {
        let newData;
        if (isRestore) {
          const isConfirm = window.confirm(
            "Are you sure want to restore style ?"
          );
          if (isConfirm) {
            newData = {
              styleRestore: true,
              teamId: id,
            };
          } else {
            setLoaderShow(false);
            return;
          }
        } else {
          if (+theme === 1) {
            newData = OurTeamOneSubFun({
              settingFormData,
              boxSection,
              boxSection1,
            });
            if (isBackgroundColor) {
              newData[".team-section"] = {
                ...newData?.[".team-section"],
                "background-color": settingFormData?.[".team-section"]?.[
                  "background-color"
                ]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[".team-section"]?.[
                          "background-color"
                        ],
                    })
                  : "",
              };
            }
            if (isBackgroundColor2) {
              newData[".team-detail-section"] = {
                ...newData?.[".team-detail-section"],
                "background-color": settingFormData[".team-detail-section"]?.[
                  "background-color"
                ]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData[".team-detail-section"]?.[
                          "background-color"
                        ],
                    })
                  : "",
              };
            }
            if (boxSection) {
              newData[".team-section.bg-image::before"] = {
                ...newData[".team-section.bg-image::before"],
                "border-radius": `${settingFormData?.[".team-section"]?.[
                  "border-radius"
                ]?.replace("rem", "")}rem`,
              };
            }
            if (boxSection1) {
              newData[".team-detail-section.bg-image::before"] = {
                ...newData[".team-detail-section.bg-image::before"],
                "border-radius": `${settingFormData?.[".team-detail-section"]?.[
                  "border-radius"
                ]?.replace("rem", "")}rem`,
              };
            }
          } else if (+theme === 2) {
            newData = OurTeamTwoSubFun({
              settingFormData,
              boxSection,
              boxSection1,
            });
            if (isBackgroundColor) {
              newData[".ourteam-section"] = {
                ...newData?.[".ourteam-section"],
                "background-color": settingFormData?.[".ourteam-section"]?.[
                  "background-color"
                ]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[".ourteam-section"]?.[
                          "background-color"
                        ],
                    })
                  : "",
              };
            }
            if (isBackgroundColor2) {
              newData[".team-detail-section"] = {
                ...newData?.[".team-detail-section"],
                "background-color": settingFormData[".team-detail-section"]?.[
                  "background-color"
                ]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData[".team-detail-section"]?.[
                          "background-color"
                        ],
                    })
                  : "",
              };
            }
            if (boxSection) {
              newData[".ourteam-section.bg-image::before"] = {
                ...newData[".ourteam-section.bg-image::before"],
                "border-radius": `${settingFormData?.[".team-section"]?.[
                  "border-radius"
                ]?.replace("rem", "")}rem`,
              };
            }
            if (boxSection1) {
              newData[".team-detail-section.bg-image::before"] = {
                ...newData[".team-detail-section.bg-image::before"],
                "border-radius": `${settingFormData?.[".team-detail-section"]?.[
                  "border-radius"
                ]?.replace("rem", "")}rem`,
              };
            }
          }
          let menuBtnStyle;
          if (+theme === 1) {
            newData = { ...newData, ...menuBtnStyle };
            menuBtnStyle = generateDynamicStyles(
              settingFormData,
              ".team-section"
            );
          } else if (+theme === 2) {
            newData = { ...newData, ...menuBtnStyle };
            menuBtnStyle = generateDynamicStyles(
              settingFormData,
              ".ourteam-section"
            );
          }

          const keysToRemove = ["image"];
          function removeKeys(obj, keys) {
            for (const key in obj) {
              if (typeof obj[key] === "object") {
                removeKeys(obj[key], keys);
              } else if (keys.includes(key)) {
                delete obj[key];
              }
            }
          }
          removeKeys(newData, keysToRemove);
          newData = {
            theme,
            style: newData,
            teamId: id,
          };
        }

        const { data } = await putUpdateOurTeam(newData);
        if (data.status === 200) {
          getOurTeamMember(null, true);
          if (isClose) {
            OpenModalCommonFunc(
              Cases.SETTING_OUR_TEAM_PARENT,
              setAllPopupState,
              false
            );
            emptyData();
          }
          setLoaderShow(false);
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  /**
   * get setting data
   * @param {id} teamId
   */
  const handleChangeSettings = async (teamId, isThemeEdit = false) => {
    try {
      setLoaderShow(true);
      setId(teamId);
      setSettingFormData((prev) => {
        return {
          ...prev,
          teamId,
        };
      });
      let formData = {
        teamId: teamId,
      };
      //  isBackgroundColor,
      // setIsBackgroundColor,
      const { data } = await getOurTeamDetails(formData);

      if (data.status === 200) {
        const themeCount = data?.payload?.data?.theme;
        setTheme(themeCount);
        setImgShow(true);
        let payload = data.payload.data;
        setSectionPageName(payload.title);
        let find = [".team-section", ".ourteam-section"];
        let section = Object.keys(payload.style);
        let foundItem = null;
        for (const key in find) {
          if (section.includes(find[key])) {
            foundItem = find[key];
            break; // If found, exit the loop
          }
        }
        if (
          +themeCount === ThemeNo.ThemeOne &&
          isEmptyObject(data?.payload?.data?.style)
        ) {
          setSettingFormData({ ...ourTeamStyleOneReset });
          setBoxSection(false);
          setBackImageToggle(false);
          setIsBackgroundColor(false);
        } else if (
          +themeCount === ThemeNo.ThemeTwo &&
          isEmptyObject(data?.payload?.data?.style)
        ) {
          setSettingFormData({ ...ourTeamStyleTwoReset });
          setBoxSection(false);
          setBackImageToggle(false);
          setIsBackgroundColor(false);
        } else {
          if (
            payload.style?.[foundItem]?.["width"] ||
            payload.style?.[foundItem]?.["margin"] ||
            payload.style?.[foundItem]?.["border-radius"]
          ) {
            setBoxSection(true);
          }
          if (
            payload.style?.[".team-detail-section"]?.["width"] ||
            payload.style?.[".team-detail-section"]?.["margin"] ||
            payload.style?.[".team-detail-section"]?.["border-radius"]
          ) {
            setBoxSection1(true);
          }
          if (payload.style?.[`${foundItem}.bg-image`]?.["background-image"]) {
            setBackImageToggle(true);
          }
          if (
            payload.style?.[".team-detail-section.bg-image"]?.[
              "background-image"
            ]
          ) {
            setBackImageToggle1(true);
          }
          if (payload.style?.[foundItem]?.["background-color"]) {
            setIsBackgroundColor(true);
          }
          if (payload.style?.[".team-detail-section"]?.["background-color"]) {
            setIsBackgroundColor2(true);
          }
          let getMenuBtnStyle;
          let getStyle;
          if (+themeCount === 1) {
            getStyle = OurTeamThemeOneFunction(payload);
            getMenuBtnStyle = generateDynamicStylesSet(
              payload,
              ".team-section"
            );
          } else if (+themeCount === 2) {
            getStyle = OurTeamThemeTwoFunction(payload);
            getMenuBtnStyle = generateDynamicStylesSet(
              payload,
              ".ourteam-section"
            );
          }
          setSettingFormData((prev) => {
            return {
              [foundItem]:
                payload.style?.[foundItem]?.["width"] ||
                payload.style?.[foundItem]?.["margin"] ||
                payload.style?.[foundItem]?.["border-radius"]
                  ? {
                      padding: payload.style?.[foundItem]?.["padding"],
                      "background-color": payload.style?.[foundItem]?.[
                        "background-color"
                      ]
                        ? convertRGBA({
                            rgbString:
                              payload.style?.[foundItem]?.["background-color"],
                          })
                        : "",
                      width: payload.style?.[foundItem]?.["width"]?.replace(
                        "calc(100% - ",
                        ""
                      ),
                      margin: payload.style?.[foundItem]?.["margin"],
                      "border-radius":
                        payload.style?.[foundItem]?.["border-radius"],
                    }
                  : {
                      padding: payload.style?.[foundItem]?.["padding"],
                      "background-color": payload.style?.[foundItem]?.[
                        "background-color"
                      ]
                        ? convertRGBA({
                            rgbString:
                              payload.style?.[foundItem]?.["background-color"],
                          })
                        : "",
                    },
              [`${foundItem}.bg-image`]: payload.style?.[
                `${foundItem}.bg-image`
              ]?.["background-image"]
                ? {
                    "background-image": payload.style?.[
                      `${foundItem}.bg-image`
                    ]?.["background-image"]
                      ? removeUrlKeyInPath({
                          urlString:
                            payload.style?.[`${foundItem}.bg-image`]?.[
                              "background-image"
                            ],
                        })
                      : "",
                  }
                : {},
              [`${foundItem}.bg-image::before`]: payload.style?.[
                `${foundItem}.bg-image::before`
              ]?.["background-color"]
                ? {
                    "background-color": payload.style?.[
                      `${foundItem}.bg-image::before`
                    ]?.["background-color"]
                      ? convertRGBA({
                          rgbString:
                            payload.style?.[`${foundItem}.bg-image::before`]?.[
                              "background-color"
                            ],
                        })
                      : "",
                  }
                : {},

              custom: payload?.style?.custom,
              ...getMenuBtnStyle,
              ...getStyle,
              // ".personal-details .info-list": {
              //   "margin-bottom":
              //     payload.style?.[".personal-details .info-list"]?.[
              //       "margin-bottom"
              //     ],
              // },
              // ".personal-details .info-list li": {
              //   "margin-bottom":
              //     payload.style?.[".personal-details .info-list li"]?.[
              //       "margin-bottom"
              //     ],
              //   "font-size":
              //     payload.style?.[".personal-details .info-list li"]?.[
              //       "font-size"
              //     ],
              // },
              // ".personal-details .order-list": {
              //   "margin-bottom":
              //     payload.style?.[".personal-details .order-list"]?.[
              //       "margin-bottom"
              //     ],
              // },
              // ".personal-details .order-list li": {
              //   "padding-left":
              //     payload.style?.[".personal-details .order-list li"]?.[
              //       "padding-left"
              //     ],
              //   "margin-bottom":
              //     payload.style?.[".personal-details .order-list li"]?.[
              //       "margin-bottom"
              //     ],
              //   "font-size":
              //     payload.style?.[".personal-details .order-list li"]?.[
              //       "font-size"
              //     ],
              // },
              // ".personal-details .order-list li:before": {
              //   "font-family":
              //     payload.style?.[".personal-details .order-list li:before"]?.[
              //       "font-family"
              //     ],
              //   top: payload.style?.[".personal-details .order-list li:before"]?.[
              //     "top"
              //   ],
              //   color:
              //     payload.style?.[".personal-details .order-list li:before"]?.[
              //       "color"
              //     ],
              // },
            };
          });
        }

        if (!isThemeEdit) {
          OpenModalCommonFunc(Cases.SETTING_OUR_TEAM_PARENT, setAllPopupState);
          TostMessage({ message: data, type: "success" });
        }

        setIsEdit(true);
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * clear data
   */
  const emptyData = () => {
    setBackImgNo("");
    setIsEdit(false);
    setFormData({
      title: "",
      subTitle: "",
      isTitleShow: true,
      skill: "",
      slugUrl: "",
      buttonText: "",
      itemLimit: "",
      navMapId: "",
      image: "",
      description: "",
      hyperLink: "",
      socialMedia: [{ id: randomStr(2), name: "", link: "" }],
      webId: localStorage.getItem("webId"),
    });
    setSettingFormData({
      ".team-section": {
        padding: "100px 0",
        "background-color": "rgb(255 255 255 / 100)",
        width: "",
        margin: "",
        "border-radius": "",
      },
      ".team-section.bg-image": {
        "background-image": "",
      },
      ".team-section.bg-image::before": {
        "background-color": "",
      },
      ".team-section .section-title-area": {
        "margin-bottom": "50px",
      },
      ".team-section .section-title-area .section-subtitle": {
        "font-size": "16px",
        "font-weight": "600",
        color: "rgb(255 73 124 / 100)",
        "background-color": "rgb(255 73 124 / 0.2)",
        padding: "5px 20px",
        p1: "5px",
        p2: "20px",
        "border-radius": "15px",
        "margin-bottom": "15px",
      },
      ".team-section .section-title-area .section-title": {
        "font-size": "44px",
        "font-weight": "600",
        color: "rgb(0 0 0 / 100)",
      },
      ".team-section .member-data": {
        margin: "10px 0",
        "background-color": "rgb(255 255 255 / 100)",
        "box-shadow": "0 5px 10px 0 rgb(7 28 31 / 0.098)",
        bs1: "0",
        bs2: "5px",
        bs3: "10px",
        bs4: "0",
        shadowColor: "rgb(7 28 31 / 0.098)",
        "border-radius": "0px",
      },
      ".team-section .member-data .member-img": {
        "background-color": "rgb(7 28 31 / 0.098)",
      },
      ".team-section .member-data .member-info": {
        padding: "30px 15px",
        p1: "30px",
        p2: "15px",
      },
      ".team-section .member-data .name a": {
        color: "rgb(0 0 0 / 100)",
        "font-size": "20px",
        "font-weight": "500",
      },
      ".team-section .member-data .name a:hover": {
        color: "rgb(255 73 124 / 100)",
      },
      ".team-section .member-data .position": {
        color: "rgb(255 73 124 / 100)",
        "font-size": "18px",
        "font-weight": "600",
        margin: "10px 0",
      },
      ".team-section .member-data .socia-list a": {
        padding: "8px",
        margin: "1px",
        "text-align": "center",
        color: "rgb(102 102 102 / 100)",
        "font-size": "20px",
        border: "2px solid rgb(255 73 124 / 100)",
        borderPx: "2px",
        borderThik: "solid",
        borderColor: "rgb(255 73 124 / 100)",
        "border-radius": "20px",
      },
      ".team-section .member-data .socia-list a:hover": {
        color: "rgb(255 255 255 / 100)",
        "background-color": "rgb(255 73 124 / 100)",
        "border-color": "rgb(255 73 124 / 100)",
      },

      ".team-detail-section": {
        padding: "100px 0",
        "background-color": "rgb(255 255 255 / 100)",
        width: "",
        margin: "",
        "border-radius": "",
      },
      ".team-detail-section.bg-image": {
        "background-image": "",
      },
      ".team-detail-section.bg-image::before": {
        "background-color": "",
      },
      ".team-detail-section .single-team-img": {
        "background-color": "rgb(244 244 245 / 100)",
        "margin-bottom": "20px",
      },
      ".team-detail-section .team-member-info .team-title": {
        "font-weight": 600,
        "font-size": "24px",
      },
      ".team-detail-section .team-member-info .designation": {
        color: "rgb(164 164 170 / 100)",
      },
      ".team-detail-section .team-member-info .social-list a": {
        padding: "9px",
        margin: "1px",
        width: "40px",
        height: "40px",
        "text-align": "center",
        color: "rgb(255 255 255 / 100)",
        "font-size": "20px",
        "border-radius": "20px",
        "background-color": "#1f2732",
      },
      ".team-detail-section .team-member-info .social-list a:hover": {
        color: "rgb(255 255 255 / 100)",
        "background-color": "rgb(255 73 124 / 100)",
      },
      ".personal-details .details-title": {
        "font-weight": 600,
        "font-size": "24px",
      },
      ".personal-details p": {
        "margin-bottom": "20px",
        "line-height": "1.6",
        "font-size": "18px",
      },
      ".personal-details .info-list": {
        "margin-bottom": "30px",
      },
      ".personal-details .info-list li": {
        "margin-bottom": "15px",
        "font-size": "18px",
      },
      ".personal-details .order-list": {
        "margin-bottom": "30px",
      },
      ".personal-details .order-list li": {
        "padding-left": "25px",
        "margin-bottom": "15px",
        "font-size": "18px",
      },
      ".personal-details .order-list li:before": {
        "font-family": "FontAwesome",
        top: "3px",
        color: "rgb(255 73 124 / 100)",
      },
      ".team-section .more-btn": {
        color: "rgb(255 255 255 / 100)",
        "font-size": "16px",
        "font-weight": "normal",
        "border-radius": "15px",
        padding: "5px 20px",
        "background-color": "rgb(255 73 124 / 100)",
      },
      ".team-section .more-btn:hover": {
        "background-color": "rgb(223 42 93 / 100)",
        color: "rgb(255 255 255 / 100)",
      },
      ".pagination-positions": {
        "justify-content": "center",
      },
      ".pagination .page-link": {
        color: "rgb(255 73 124 / 100)",
      },
      ".pagination .page-item.active .page-link": {
        "background-color": "rgb(255 73 124 / 100)",
        "border-color": "rgb(255 73 124 / 100)",
      },
    });
    setSkill([{ id: randomStr(2), skill: "", parentageOfSkill: "" }]);
    setTeamMemberId("");
    setFormDataOurData({
      title: "",
      headLine: "",
      image: "",
      itemLimit: "",
      buttonText: "",
      navMapId: "",
      slugUrl: "",
      isTitleShow: true,
      description: "",
      webId: localStorage.getItem("webId"),
      style: {
        ".team-section": {
          padding: "100px 0",
          "background-color": "rgb(255 255 255 / 100)",
          width: "",
          margin: "",
          "border-radius": "",
        },
        ".team-section.bg-image": {
          "background-image": "",
        },
        ".team-section.bg-image::before": {
          "background-color": "",
        },
        ".team-section .section-title-area": {
          "margin-bottom": "50px",
        },
        ".team-section .section-title-area .section-subtitle": {
          "font-size": "16px",
          "font-weight": "600",
          color: "rgb(255 73 124 / 100)",
          "background-color": "rgb(255 73 124 / 0.2)",
          padding: "5px 20px",
          p1: "5px",
          p2: "20px",
          "border-radius": "15px",
          "margin-bottom": "15px",
        },
        ".team-section .section-title-area .section-title": {
          "font-size": "44px",
          "font-weight": "600",
          color: "rgb(0 0 0 / 100)",
        },
        ".team-section .member-data": {
          margin: "10px 0",
          "background-color": "rgb(255 255 255 / 100)",
          "box-shadow": "0 5px 10px 0 rgb(7 28 31 / 0.098)",
          bs1: "0",
          bs2: "5px",
          bs3: "10px",
          bs4: "0",
          shadowColor: "rgb(7 28 31 / 0.098)",
          "border-radius": "0px",
        },
        ".team-section .member-data .member-img": {
          "background-color": "rgb(7 28 31 / 0.098)",
        },
        ".team-section .member-data .member-info": {
          padding: "30px 15px",
          p1: "30px",
          p2: "15px",
        },
        ".team-section .member-data .name a": {
          color: "rgb(0 0 0 / 100)",
          "font-size": "20px",
          "font-weight": "500",
        },
        ".team-section .member-data .name a:hover": {
          color: "rgb(255 73 124 / 100)",
        },
        ".team-section .member-data .position": {
          color: "rgb(255 73 124 / 100)",
          "font-size": "18px",
          "font-weight": "600",
          margin: "10px 0",
        },
        ".team-section .member-data .socia-list a": {
          padding: "8px",
          margin: "1px",
          "text-align": "center",
          color: "rgb(102 102 102 / 100)",
          "font-size": "20px",
          border: "2px solid rgb(255 73 124 / 100)",
          borderPx: "2px",
          borderThik: "solid",
          borderColor: "rgb(255 73 124 / 100)",
          "border-radius": "20px",
        },
        ".team-section .member-data .socia-list a:hover": {
          color: "rgb(255 255 255 / 100)",
          "background-color": "rgb(255 73 124 / 100)",
          "border-color": "rgb(255 73 124 / 100)",
        },

        ".team-detail-section": {
          padding: "100px 0",
          "background-color": "rgb(255 255 255 / 100)",
          width: "",
          margin: "",
          "border-radius": "",
        },
        ".team-detail-section.bg-image": {
          "background-image": "",
        },
        ".team-detail-section.bg-image::before": {
          "background-color": "",
        },
        ".team-detail-section .single-team-img": {
          "background-color": "rgb(244 244 245 / 100)",
          "margin-bottom": "20px",
        },
        ".team-detail-section .team-member-info .team-title": {
          "font-weight": 600,
          "font-size": "24px",
        },
        ".team-detail-section .team-member-info .designation": {
          color: "rgb(164 164 170 / 100)",
        },
        ".team-detail-section .team-member-info .social-list a": {
          padding: "9px",
          margin: "1px",
          width: "40px",
          height: "40px",
          "text-align": "center",
          color: "rgb(255 255 255 / 100)",
          "font-size": "20px",
          "border-radius": "20px",
          "background-color": "#1f2732",
        },
        ".team-detail-section .team-member-info .social-list a:hover": {
          color: "rgb(255 255 255 / 100)",
          "background-color": "rgb(255 73 124 / 100)",
        },
        ".personal-details .details-title": {
          "font-weight": 600,
          "font-size": "24px",
        },
        ".personal-details p": {
          "margin-bottom": "20px",
          "line-height": "1.6",
          "font-size": "18px",
        },
        ".personal-details .info-list": {
          "margin-bottom": "30px",
        },
        ".personal-details .info-list li": {
          "margin-bottom": "15px",
          "font-size": "18px",
        },
        ".personal-details .order-list": {
          "margin-bottom": "30px",
        },
        ".personal-details .order-list li": {
          "padding-left": "25px",
          "margin-bottom": "15px",
          "font-size": "18px",
        },
        ".personal-details .order-list li:before": {
          "font-family": "FontAwesome",
          top: "3px",
          color: "rgb(255 73 124 / 100)",
        },
        ".team-section .more-btn": {
          color: "rgb(255 255 255 / 100)",
          "font-size": "16px",
          "font-weight": "normal",
          "border-radius": "15px",
          padding: "5px 20px",
          "background-color": "rgb(255 73 124 / 100)",
        },
        ".team-section .more-btn:hover": {
          "background-color": "rgb(223 42 93 / 100)",
          color: "rgb(255 255 255 / 100)",
        },
        ".pagination-positions": {
          "justify-content": "center",
        },
        ".pagination .page-link": {
          color: "rgb(255 73 124 / 100)",
        },
        ".pagination .page-item.active .page-link": {
          "background-color": "rgb(255 73 124 / 100)",
          "border-color": "rgb(255 73 124 / 100)",
        },
      },
    });
    setLoaderShow(false);
  };

  const handleAlignAndCloneSection = ({ isClone = false }) => {
    if (isClone) {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          cloneModal: true,
        };
      });
      setCloneFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          pageConfigDetailsLabel: pageConfigDetails[0]?.label,
          contentDetailName: contentDetail.name,
        };
      });
    } else {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          alignModal: true,
        };
      });
      setAlignFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          label: pageConfigDetails[0]?.label,
        };
      });
    }
  };

  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody
            className="support-ticket-font box-border-div"
            style={{ padding: "20px" }}
          >
            <Row>
              <CardBuilderHeaderSection
                addBtnTitle={pageConfigDetails[0]?.label}
                name={pageConfigDetails[0]?.label}
                isAdd={!ourTeamDetail && true}
                mainEditSection={true}
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
                openModal={() => {
                  OpenModalCommonFunc(Cases.OUR_TEAM, setAllPopupState);
                  setSectionPageName(modalsName.OUR_TEAM);
                  setCollId(contentMapId);
                }}
                isCloneSection={true}
                isAlignSection={true}
                handleAlignAndCloneSection={handleAlignAndCloneSection}
              />
              {ourTeamDetail && (
                <Col xl="12 xl-50 box-col-6" md="6">
                  <Card className="ecommerce-widget mb-0">
                    <CardBody
                      className="support-ticket-font"
                      style={{ padding: "20px" }}
                    >
                      <div className="col-12">
                        <CardBuilderHeaderSection
                          name={ourTeamDetail?.title}
                          checked={ourTeamDetail?.isActive}
                          switchToggle={true}
                          id={ourTeamDetail?.teamId}
                          handleChange={handleChangeOurTeamStatus}
                        />
                        <OpenPopupBtn
                          openPopup={() => {
                            OpenModalCommonFunc(
                              Cases.OUR_TEAM_MEMBER,
                              setAllPopupState
                            );
                            setSectionPageName(ourTeamDetail?.title);
                            setId(ourTeamDetail.teamId);
                            setCollId(contentMapId);
                          }}
                          name={`Add or View ${ourTeamDetail?.title}`}
                        />
                      </div>
                      <div className="d-flex justify-content-end ">
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleEditOurTeam(ourTeamDetail.teamId);
                            setCollId(contentMapId);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() =>
                            handleDelete(ourTeamDetail.teamId, true)
                          }
                        >
                          <Trash />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleChangeSettings(ourTeamDetail.teamId);
                          }}
                        >
                          <Settings />
                        </IconButton>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
              <DeleteSection
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
              />
            </Row>
          </CardBody>
        </Card>
      </Col>

      {open && (
        <Lightbox mainSrc={imageSet} onCloseRequest={() => setOpen(false)} />
      )}
      {/* team member popup */}
      <CommonPopup
        open={allPopupState.ourteamMemberPopup}
        close={() =>
          OpenModalCommonFunc(Cases.OUR_TEAM_MEMBER, setAllPopupState, false)
        }
        modalName={
          sectionPageName ? sectionPageName : modalsName.OUR_TEAM_MEMBER
        }
        modalCase={Cases.OUR_TEAM_MEMBER}
        handleSubmit={handleSubmit}
        emptyData={emptyData}
        inputData={fromData}
        edit={false}
        content={
          <OurTeamMemberPopup
            handleChangeStatus={handleChangeStatus}
            getData={getOurTeamMember}
            handleEdit={handleEdit}
            imageUpload={imageUploadTeamMember}
            emptyData={emptyData}
            simpleValidator={simpleValidatorTeamMember}
          />
        }
      />

      {/* our team popup */}
      <CommonPopup
        open={allPopupState.ourteamPopup}
        close={() =>
          OpenModalCommonFunc(Cases.OUR_TEAM, setAllPopupState, false)
        }
        modalName={sectionPageName ? sectionPageName : modalsName.OUR_TEAM}
        modalCase={Cases.OUR_TEAM}
        handleSubmit={handleSubmitOurTeam}
        emptyData={emptyData}
        inputData={formDataOurTeam}
        edit={false}
        content={
          <OurTeamPopup
            imageUpload={imageUpload}
            simpleValidator={simpleValidator}
          />
        }
      />

      {/* setting images parent popup */}
      <CommonPopup
        open={allPopupState.ourTeamSettingPopup}
        close={() =>
          OpenModalCommonFunc(
            Cases.SETTING_OUR_TEAM_PARENT,
            setAllPopupState,
            false
          )
        }
        modalName={
          sectionPageName ? sectionPageName : modalsName.SETTING_OUR_TEAM_PARENT
        }
        modalCase={Cases.SETTING_OUR_TEAM_PARENT}
        handleSubmit={handleSubmitSetting}
        emptyData={emptyData}
        inputData={settingFormData}
        edit={false}
        content={
          <SettingOurTeamPerentPopup
            imageUpload={backImageUploadImages}
            handleSubmitSetting={handleSubmitSetting}
            handleChangeSettings={handleChangeSettings}
          />
        }
      />
    </>
  );
};

export default OurTeam;
