import axios from "axios";
import {
  ADD_THEME_MAP_LIST,
  DELETE_THEME_MAP_LIST,
  DETAILS_THEME_MAP_LIST,
  EDIT_THEME_MAP_LIST,
  ORG_THEME_MAP_PAGE_LIST_ENABLE_DESABLE,
  THEME_MAP_LIST,
} from "../../constant/API constant";

export const ThemeMap_List = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${THEME_MAP_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const Add_ThemeMap_List = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${ADD_THEME_MAP_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Edit_ThemeMap_List = (formData, id) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${EDIT_THEME_MAP_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { templatePageItemMapId: id },
    }
  );
};

export const Delete_ThemeMap_List = (templatePageItemMapId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(
    `${process.env.REACT_APP_API_URL}${DELETE_THEME_MAP_LIST}`,
    {
      headers: { Authorization: jwt_token },
      params: { templatePageItemMapId },
    }
  );
};

export const Ditails_ThemeMap_List = (id) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${DETAILS_THEME_MAP_LIST}`,
    {
      headers: { Authorization: jwt_token },
      params: { templatePageItemMapId: id },
    }
  );
};

export const ThemeMap_Enable_Desable = (formData, templatePageItemMapId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${ORG_THEME_MAP_PAGE_LIST_ENABLE_DESABLE}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { templatePageItemMapId },
    }
  );
};
