import axios from "axios";
import {
  ADD_THEME_LIST,
  DELETE_THEME_LIST,
  DETAILS_THEME_LIST,
  EDIT_THEME_LIST,
  ORG_THEME_PAGE_LIST_ENABLE_DESABLE,
  THEME_LIST,
} from "../../constant/API constant";

export const Theme_List = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${THEME_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const Add_Theme_List = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${ADD_THEME_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Edit_Theme_List = (formData, id) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${EDIT_THEME_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { templateId: id },
    }
  );
};

export const Delete_Theme_List = (templateId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${DELETE_THEME_LIST}`, {
    headers: { Authorization: jwt_token },
    params: { templateId },
  });
};

export const Ditails_Theme_List = (id) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${DETAILS_THEME_LIST}`, {
    headers: { Authorization: jwt_token },
    params: { templateId: id },
  });
};

export const Theme_Enable_Desable = (formData, templateId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${ORG_THEME_PAGE_LIST_ENABLE_DESABLE}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { templateId },
    }
  );
};
