import { IconButton, Tooltip } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { DataGrid } from "@mui/x-data-grid";
import React, { useContext } from "react";
import { Card, CardHeader, Col, Container, Row } from "reactstrap";
import { MOMENT_SETTING, Style } from "../../constant";
import DataNotFound from "../../DataNoteFound/DataNotFound";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import { TabelAnimation } from "../../Spinner";
import { MuiTabelStyle } from "../../utils/MuiTabelStyle";
import moment from "moment-timezone";
import SettingMiniWebContext from "../../context/SettingMiniWebContext/SettingMiniWebContext";
import { Trash2 } from "react-feather";
import { WebContentMapListDelete } from "../../api/SupperAdminOrganizationApi/Organization";
import { Zoom, toast } from "react-toastify";

const SettingMiniWebItemContentWeb = (props) => {
  const {
    formvalue,
    record,
    pageSize,
    page,
    setPageSize,
    setPage,
    sorting,
    setModal,
    setToggleSubmit,
  } = useContext(SettingMiniWebContext);

  const { tabelAnimation } = useContext(LoaderContext);
  const { WebContentMap_List, orgId } = props;

  /**
   * Add Profile Function call
   */

  const AddProfile = () => {
    setToggleSubmit(false);
    setModal(true);
  };

  const handleDelete = async (contentMapId) => {
    let confirmation = window.confirm("Are you sure you want to delete?");
    if (confirmation) {
      try {
        let paramObj = {
          contentMapId,
        };
        const { data } = await WebContentMapListDelete(paramObj);
        if (data.status === 200) {
          WebContentMap_List(orgId);
          toast.success(data.message, {
            autoClose: 1000,
            transition: Zoom,
          });
        }
      } catch (error) {
        toast.error(error?.response?.data?.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    }
  };

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 100,
      field: "sNo",
      headerName: "S.No.",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      flex: 0.2,
      minWidth: 250,
      field: "Section Name",
      headerName: "name",
      renderCell: (params) => (
        <>{params.row?.contentDetail ? params.row?.contentDetail?.name : "-"}</>
      ),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "createdAt",
      headerName: "Date",
      renderCell: (params) =>
        params.row.createdAt &&
        moment.utc(params.row.createdAt).format(MOMENT_SETTING.FORMATE),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "edit",
      headerName: "Edit",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <>
          <Tooltip title="Delete">
            <IconButton
              aria-label="edit"
              style={{ color: "red" }}
              onClick={() => handleDelete(params.row.contentMapId)}
            >
              <Trash2 />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <Container fluid={true}>
      <Row>
        <Col sm="12">
          <Card>
            <CardHeader
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h3>{"Content Map"}</h3>
              <div
                aria-label="add"
                onClick={() => AddProfile()}
                className="btn btn-primary"
                style={Style}
              >
                <Add />
              </div>
            </CardHeader>
            {tabelAnimation === true ? (
              <TabelAnimation />
            ) : formvalue.length ? (
              <DataGrid
                sx={MuiTabelStyle}
                autoHeight
                rows={formvalue}
                getRowId={(row) => row.contentId}
                columns={defaultColumns}
                rowsPerPageOptions={[5, 10, 25, 50]}
                pagination
                rowCount={record}
                pageSize={pageSize}
                page={page - 1}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                onPageChange={(newPageNo) => setPage(newPageNo + 1)}
                paginationMode="server"
                disableSelectionOnClick
                checkboxSelection={false}
                sortingMode="server"
                onSortModelChange={sorting}
              />
            ) : (
              <DataNotFound />
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SettingMiniWebItemContentWeb;
