import axios from "axios";
import {
  CHECK_MULTI_SUB_DOMAIN,
  UPDATE_MULTI_DOMAIN_NAME,
  UPDATE_MULTI_NAV_STYLE,
} from "..";

/**
 * get check domain
 * @param {Parameters} paramObj
 * @returns
 */
export const Check_Multi_Sub_Domain = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${CHECK_MULTI_SUB_DOMAIN}`,
    {
      headers: { Authorization: jwt_token },
      params: paramObj,
    }
  );
};

/**
 * update domain name
 * @param {Parameters} paramObj
 * @returns
 */
export const Update_Multi_Domain_Name = (formData) => {
  const jwt_token = localStorage.getItem("token");
  const { domain, ...restForm } = formData;
  let domainParam = {
    domain,
  };
  if ("subDomain" in formData) {
    domainParam["subDomain"] = formData.subDomain;
  }
  return axios.put(
    `${process.env.REACT_APP_API_URL}${UPDATE_MULTI_DOMAIN_NAME}`,
    { ...restForm, domain },
    {
      headers: { Authorization: jwt_token },
      // params: domainParam,
    }
  );
};

/**
 * update domain name
 * @param {Parameters} paramObj
 * @returns
 */
export const Update_Nav_Style = (formData) => {
  const jwt_token = localStorage.getItem("token");

  return axios.put(
    `${process.env.REACT_APP_API_URL}${UPDATE_MULTI_NAV_STYLE}`,
    { otherSetting: formData },
    {
      headers: { Authorization: jwt_token },
    }
  );
};
