import React, { useContext } from "react";
import { Col, Input, Label, Row } from "reactstrap";
import { TextAlign, fontWeight } from "../../../../constant";
import StepsContext from "../../../context/StepsContext/StepsContext";
import { Cases, DELETE_CONFIRM } from "../../../constant";
import { delete_Multi_Image_Permanent } from "../apis/SliderApi/SliderApi";
import { Zoom, toast } from "react-toastify";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import { Switch } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { UploadCloud } from "react-feather";
import { IconButton } from "@material-ui/core";

import Dividers from "../../../common/divider";
import { OpenModalCommonFunc } from "../../../utils";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import CommonColor from "../../../common/CommonColor";
import CustomStyle from "../../../common/CustomStyle";

const StepsThemeOne = (props) => {
  const {
    isEdit,
    settingFormData,
    setSettingFormData,
    backImageToggle,
    setBackImageToggle,
    boxSection,
    setBoxSection,
    isBackgroundColor,
    setIsBackgroundColor,
  } = useContext(StepsContext);
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const { imageUpload, handleSubmitSetting } = props;
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * input value change
   * @param {event} e
   */

  const handleChangeStepByStepSection = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".step-by-step-section"]: {
          ...prev[".step-by-step-section"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeStepByStepBgSectionBgImage = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".step-by-step-section.bg-image::before"]: {
          ...prev[".step-by-step-section.bg-image::before"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeStepByStepSectionTitleArea = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".step-by-step-section .section-title-area"]: {
          ...prev[".step-by-step-section .section-title-area"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeStepByStepSectionTitleAreaSubTitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".step-by-step-section .section-title-area .section-subtitle"]: {
          ...prev[
            ".step-by-step-section .section-title-area .section-subtitle"
          ],
          [name]: value,
        },
      };
    });
  };

  const handleChangeStepByStepSectionTitleAreaTitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".step-by-step-section .section-title-area .section-title"]: {
          ...prev[".step-by-step-section .section-title-area .section-title"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeStepByStepSectionStepDiv = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".step-by-step-section .stepdiv"]: {
          ...prev[".step-by-step-section .stepdiv"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeStepByStepSectionStepDivCount = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".step-by-step-section .stepdiv .step-count"]: {
          ...prev[".step-by-step-section .stepdiv .step-count"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeStepByStepSectionStepDivH4 = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".step-by-step-section .stepdiv h4"]: {
          ...prev[".step-by-step-section .stepdiv h4"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeStepByStepSectionStepDivP = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".step-by-step-section .stepdiv p"]: {
          ...prev[".step-by-step-section .stepdiv p"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeCustomStyle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  /**
   * delete image permanently
   */
  const deleteImagePermanent = async () => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData = {
          fileName: settingFormData?.[".step-by-step-section.bg-image"]?.[
            "background-image"
          ]
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
            .pop(),
        };
        const { data } = await delete_Multi_Image_Permanent(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            position: "top-center",
          });
          setSettingFormData({
            ...settingFormData,
            ".step-by-step-section.bg-image": { "background-image": "" },
          });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };

  /**
   * image reupload
   */
  const reupload = () => {
    setSettingFormData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };

  return (
    <>
      <Row className="mt-4">
        <h4 className="mb-3">Make change in Main section</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (default : 100)"}
          </Label>
          <Input
            className="form-control"
            name="padding"
            type="number"
            placeholder="Padding"
            value={settingFormData[".step-by-step-section"]["padding"]
              ?.split(" ")[0]
              ?.replace(/px/g, "")}
            onChange={(e) => handleChangeStepByStepSection(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>

        <Col md="4 mb-3">
          <div className="d-flex align-items-center">
            <Label htmlFor="validationCustom03">
              {isBackgroundColor
                ? "Add Background Color"
                : "Remove Background Color"}
            </Label>
            <Switch
              checked={isBackgroundColor}
              inputProps={{ "aria-label": "controlled" }}
              onChange={(e) => {
                setIsBackgroundColor(e.target.checked);
                setSettingFormData((prev) => {
                  if (e.target.checked) {
                    if (boxSection) {
                      return {
                        ...prev,
                        [".step-by-step-section"]: {
                          "background-color": "#f9f9f9",
                          padding:
                            settingFormData?.[".step-by-step-section"]?.[
                              "padding"
                            ],
                          width: settingFormData?.[".step-by-step-section"]?.[
                            "width"
                          ]?.replace("rem)", ""),
                          margin: settingFormData?.[".step-by-step-section"]?.[
                            "margin"
                          ]
                            ?.split(" ")[0]
                            ?.replace(/px/g, ""),
                          "border-radius": settingFormData?.[
                            ".step-by-step-section"
                          ]?.["border-radius"]?.replace(/rem/g, ""),
                        },
                      };
                    } else {
                      return {
                        ...prev,
                        [".step-by-step-section"]: {
                          "background-color": "#f9f9f9",
                          padding:
                            settingFormData?.[".step-by-step-section"]?.[
                              "padding"
                            ],
                        },
                      };
                    }
                  } else {
                    if (boxSection) {
                      return {
                        ...prev,
                        [".step-by-step-section"]: {
                          padding:
                            settingFormData?.[".step-by-step-section"]?.[
                              "padding"
                            ],
                          width: settingFormData?.[".step-by-step-section"]?.[
                            "width"
                          ]?.replace("rem)", ""),
                          margin: settingFormData?.[".step-by-step-section"]?.[
                            "margin"
                          ]
                            ?.split(" ")[0]
                            ?.replace(/px/g, ""),
                          "border-radius": settingFormData?.[
                            ".step-by-step-section"
                          ]?.["border-radius"]?.replace(/rem/g, ""),
                        },
                      };
                    } else {
                      return {
                        ...prev,
                        [".step-by-step-section"]: {
                          padding:
                            settingFormData?.[".step-by-step-section"]?.[
                              "padding"
                            ],
                        },
                      };
                    }
                  }
                });
              }}
            />
          </div>
          {isBackgroundColor && (
            <CommonColor
              color={
                settingFormData?.[".step-by-step-section"]?.["background-color"]
              }
              setSettingFormData={setSettingFormData}
              className={".step-by-step-section"}
              classProperty={"background-color"}
              label={"Select Background Color"}
            />
          )}
        </Col>
        <p>{"Box Section : convert your section to Box section"}</p>
        <div>
          <Switch
            checked={boxSection}
            inputProps={{ "aria-label": "controlled" }}
            onChange={(e) => {
              setBoxSection(e.target.checked);
              setSettingFormData((prev) => {
                return {
                  ...prev,
                  ".step-by-step-section": {
                    padding: settingFormData?.[".step-by-step-section"]?.[
                      "padding"
                    ]
                      ?.split(" ")[0]
                      ?.replace(/px/g, ""),
                    "background-color":
                      settingFormData?.[".step-by-step-section"]?.[
                        "background-color"
                      ],
                    width: e.target.checked ? "10" : "",
                    margin: e.target.checked ? "0" : "",
                    "border-radius": e.target.checked ? "3" : "",
                  },
                };
              });
            }}
          />
        </div>
        {boxSection && (
          <>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Width (Default : 10)"}
              </Label>
              <Input
                className="form-control"
                name="width"
                type="number"
                placeholder="width"
                value={settingFormData?.[".step-by-step-section"]?.[
                  "width"
                ]?.replace("rem)", "")}
                onChange={(e) => handleChangeStepByStepSection(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Margin (Default : 0)"}
              </Label>
              <Input
                className="form-control"
                name="margin"
                type="number"
                placeholder="margin"
                value={settingFormData?.[".step-by-step-section"]?.["margin"]
                  ?.split(" ")[0]
                  ?.replace(/px/g, "")}
                onChange={(e) => handleChangeStepByStepSection(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Border Radius (Default : 3)"}
              </Label>
              <Input
                className="form-control"
                name="border-radius"
                type="number"
                placeholder="Border Radius"
                value={settingFormData?.[".step-by-step-section"]?.[
                  "border-radius"
                ]?.replace(/rem/g, "")}
                onChange={(e) => handleChangeStepByStepSection(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
          </>
        )}
      </Row>
      <hr />

      <Row className="mt-4">
        <Col md="12 mb-3">
          <h4 className="mb-3">Add Background Image in section</h4>

          <p>do you want to upload Background image</p>
          <Switch
            checked={backImageToggle}
            inputProps={{ "aria-label": "controlled" }}
            onChange={(e) => {
              setBackImageToggle(e.target.checked);
              setSettingFormData((prev) => {
                if (e.target.checked) {
                  return {
                    ...prev,
                    image: "",
                    ".step-by-step-section.bg-image": {},
                    ".step-by-step-section.bg-image::before": {
                      "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
                    },
                  };
                } else {
                  return {
                    ...prev,
                    image: "",
                    ".step-by-step-section.bg-image": {},
                    ".step-by-step-section.bg-image::before": {
                      // "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
                    },
                  };
                }
              });
            }}
          />
          {backImageToggle && (
            <>
              {" "}
              <Col md="6 mb-3">
                <Dividers divide="IMAGE" />
                <p className="mb-4">
                  File type required JPG, PNG (1905px X 607px)
                </p>
              </Col>
              <Row>
                <Col md="4 mb-3">
                  <div
                    className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    {!settingFormData?.[".step-by-step-section.bg-image"]?.[
                      "background-image"
                    ] && (
                      <span
                        onClick={() =>
                          OpenModalCommonFunc(
                            Cases.PRODUCT_UPLOAD,
                            setAllPopupState
                          )
                        }
                      >
                        <UploadCloud />
                      </span>
                    )}

                    {settingFormData?.[".step-by-step-section.bg-image"]?.[
                      "background-image"
                    ] && (
                      <img
                        src={
                          settingFormData?.[".step-by-step-section.bg-image"]?.[
                            "background-image"
                          ] || ""
                        }
                        alt=""
                        style={{
                          width: "85%",
                          height: "90px",
                          objectFit: "contain",
                          position: "absolute",
                        }}
                        onClick={() => deleteImagePermanent()}
                      />
                    )}
                    {settingFormData?.[".step-by-step-section.bg-image"]?.[
                      "background-image"
                    ] && (
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="close"
                        style={{
                          color: "red",
                          position: "absolute",
                          top: "-22px",
                          left: "1px",
                        }}
                        onClick={() => deleteImagePermanent()}
                      >
                        <Close
                          style={{
                            fontSize: "25px",
                            fontWeight: "bold",
                          }}
                        />
                      </IconButton>
                    )}
                  </div>
                </Col>
                <Col md="6 mb-3">
                  <h4 className="mb-3">Add Background color in section</h4>
                  <Col md="6 mb-3">
                    <CommonColor
                      color={
                        settingFormData?.[
                          ".step-by-step-section.bg-image::before"
                        ]?.["background-color"]
                      }
                      setSettingFormData={setSettingFormData}
                      className={".step-by-step-section.bg-image::before"}
                      classProperty={"background-color"}
                      label={"Select Background Color"}
                    />
                  </Col>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make space in Title bottom</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin bottom (Default : 50)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin bottom"
            value={settingFormData[".step-by-step-section .section-title-area"][
              "margin-bottom"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeStepByStepSectionTitleArea(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in section subtitle</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 16)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData[
              ".step-by-step-section .section-title-area .section-subtitle"
            ]["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeStepByStepSectionTitleAreaSubTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-control"
            value={
              settingFormData[
                ".step-by-step-section .section-title-area .section-subtitle"
              ]["font-weight"]
            }
            onChange={(e) => handleChangeStepByStepSectionTitleAreaSubTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".step-by-step-section .section-title-area .section-subtitle"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".step-by-step-section .section-title-area .section-subtitle"
            }
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".step-by-step-section .section-title-area .section-subtitle"
              ]?.["background-color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".step-by-step-section .section-title-area .section-subtitle"
            }
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>{" "}
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 5 - 20)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="p1"
              type="number"
              placeholder="Padding"
              value={settingFormData[
                ".step-by-step-section .section-title-area .section-subtitle"
              ]["p1"]?.replace(/px/g, "")}
              onChange={(e) =>
                handleChangeStepByStepSectionTitleAreaSubTitle(e)
              }
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p2"
              type="number"
              placeholder="Padding"
              value={settingFormData[
                ".step-by-step-section .section-title-area .section-subtitle"
              ]["p2"]?.replace(/px/g, "")}
              onChange={(e) =>
                handleChangeStepByStepSectionTitleAreaSubTitle(e)
              }
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border Radius (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="Border Radius"
            value={settingFormData[
              ".step-by-step-section .section-title-area .section-subtitle"
            ]["border-radius"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeStepByStepSectionTitleAreaSubTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin bottom (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin bottom"
            value={settingFormData[
              ".step-by-step-section .section-title-area .section-subtitle"
            ]["margin-bottom"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeStepByStepSectionTitleAreaSubTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in section Title</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 44)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData[
              ".step-by-step-section .section-title-area .section-title"
            ]["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeStepByStepSectionTitleAreaTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-control"
            value={
              settingFormData[
                ".step-by-step-section .section-title-area .section-title"
              ]["font-weight"]
            }
            onChange={(e) => handleChangeStepByStepSectionTitleAreaTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".step-by-step-section .section-title-area .section-title"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".step-by-step-section .section-title-area .section-title"
            }
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in section step div</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Padding (Default : 60)"}</Label>
          <Input
            className="form-control"
            name="padding"
            type="number"
            placeholder="Padding"
            value={settingFormData[".step-by-step-section .stepdiv"][
              "padding"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeStepByStepSectionStepDiv(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Text Align (Default : center)"}
          </Label>
          <select
            name="text-align"
            id="text-align"
            className="form-control"
            value={
              settingFormData[".step-by-step-section .stepdiv"]["text-align"]
            }
            onChange={(e) => handleChangeStepByStepSectionStepDiv(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {TextAlign.map((elem) => {
              return (
                <option value={elem["text-align"]}>{elem["text-align"]}</option>
              );
            })}
          </select>
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in step div count</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Width (Default : 60)"}</Label>
          <Input
            className="form-control"
            name="width"
            type="number"
            placeholder="Width"
            value={settingFormData[
              ".step-by-step-section .stepdiv .step-count"
            ]["width"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeStepByStepSectionStepDivCount(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"height (Default : 60)"}</Label>
          <Input
            className="form-control"
            name="height"
            type="number"
            placeholder="Height"
            value={settingFormData[
              ".step-by-step-section .stepdiv .step-count"
            ]["height"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeStepByStepSectionStepDivCount(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".step-by-step-section .stepdiv .step-count"]?.[
                "color"
              ]
            }
            setSettingFormData={setSettingFormData}
            className={".step-by-step-section .stepdiv .step-count"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 30)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData[
              ".step-by-step-section .stepdiv .step-count"
            ]["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeStepByStepSectionStepDivCount(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-control"
            value={
              settingFormData[".step-by-step-section .stepdiv .step-count"][
                "font-weight"
              ]
            }
            onChange={(e) => handleChangeStepByStepSectionStepDivCount(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 12 - 5)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="p1"
              type="number"
              placeholder="Padding"
              value={settingFormData[
                ".step-by-step-section .stepdiv .step-count"
              ]["p1"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeStepByStepSectionStepDivCount(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p2"
              type="number"
              placeholder="Padding"
              value={settingFormData[
                ".step-by-step-section .stepdiv .step-count"
              ]["p2"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeStepByStepSectionStepDivCount(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin bottom (Default : 30)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin bottom"
            value={settingFormData[
              ".step-by-step-section .stepdiv .step-count"
            ]["margin-bottom"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeStepByStepSectionStepDivCount(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".step-by-step-section .stepdiv .step-count"]?.[
                "background-color"
              ]
            }
            setSettingFormData={setSettingFormData}
            className={".step-by-step-section .stepdiv .step-count"}
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border Radius (Default : 50)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="Border Radius"
            value={settingFormData[
              ".step-by-step-section .stepdiv .step-count"
            ]["border-radius"]?.replace(/%/g, "")}
            onChange={(e) => handleChangeStepByStepSectionStepDivCount(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in step div heading</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".step-by-step-section .stepdiv h4"]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".step-by-step-section .stepdiv h4"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>

        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-control"
            value={
              settingFormData[".step-by-step-section .stepdiv h4"][
                "font-weight"
              ]
            }
            onChange={(e) => handleChangeStepByStepSectionStepDivH4(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 20)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData[".step-by-step-section .stepdiv h4"][
              "font-size"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeStepByStepSectionStepDivH4(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin bottom (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin bottom"
            value={settingFormData[".step-by-step-section .stepdiv h4"][
              "margin-bottom"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeStepByStepSectionStepDivH4(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in step div paragraph</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".step-by-step-section .stepdiv p"]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".step-by-step-section .stepdiv p"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 300)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-control"
            value={
              settingFormData[".step-by-step-section .stepdiv p"]["font-weight"]
            }
            onChange={(e) => handleChangeStepByStepSectionStepDivP(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 14)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData[".step-by-step-section .stepdiv p"][
              "font-size"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeStepByStepSectionStepDivP(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="2 mb-3">
          <Label htmlFor="validationCustom03">
            {"Line Height (Default : 1.6)"}
          </Label>
          <Input
            className="form-control"
            name="line-height"
            type="text"
            placeholder="Line Height"
            value={
              settingFormData[".step-by-step-section .stepdiv p"]["line-height"]
            }
            onChange={(e) => handleChangeStepByStepSectionStepDivP(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <CustomStyle
        handleChange={handleChangeCustomStyle}
        value={settingFormData?.custom}
      />
      <ProductImageUploadPopup
        allPopupState={allPopupState}
        setAllPopupState={setAllPopupState}
        crop={crop}
        setCrop={setCrop}
        completedCrop={completedCrop}
        setCompletedCrop={setCompletedCrop}
        scale={scale}
        rotate={rotate}
        image={settingFormData?.image}
        setImage={setSettingFormData}
        imageUpload={imageUpload}
        isEdit={isEdit}
        reupload={reupload}
        // aspect={3.14 / 1}
      />
    </>
  );
};

export default StepsThemeOne;
