import axios from "axios";
import {
  BYTE_WEB_DELETE_FILE,
  BYTE_WEB_FILE_UPLOAD,
  GET_ABOUT_US,
  GET_ABOUT_US_DETAILS,
  GET_NAVBAR,
  POST_ABOUT_US,
  PUT_ABOUT_US_ORDER,
  PUT_LABEL,
  PUT_STATUS_ABOUT_US,
} from "../../apiConstants";

/**
 * about us list
 * @param {parameters} paramObj
 * @returns
 */

export const getAboutUs = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_ABOUT_US}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * enable-disable
 * @param {body} body
 * @returns
 */
export const updateStatus = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_STATUS_ABOUT_US}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * create about us
 * @param {body} body
 * @returns
 */
export const postAboutUs = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(`${process.env.REACT_APP_API_URL}${POST_ABOUT_US}`, body, {
    headers: { Authorization: jwt_token },
  });
};

/**
 * update label
 * @param {body} body
 * @returns
 */
export const putLabel = (oldTitle, body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(`${process.env.REACT_APP_API_URL}${PUT_LABEL}`, body, {
    headers: { Authorization: jwt_token },
    params: { oldTitle: oldTitle },
  });
};

/**
 * navbar list
 * @param {parameters} paramObj
 * @returns
 */

export const getNavbarList = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_NAVBAR}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * update about us
 * @param {body} body
 * @returns
 */
export const putUpdateAboutUs = ({ formDataImageParent, id }) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${POST_ABOUT_US}`,
    formDataImageParent,
    {
      headers: { Authorization: jwt_token },
      params: { id: id },
    }
  );
};

export const putUpdateAboutUsParent = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${POST_ABOUT_US}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { id: formData.aboutUsId },
    }
  );
};

/**
 * delete about us
 * @param {id} id
 * @returns
 */
export const deleteAboutUs = (id) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${POST_ABOUT_US}`, {
    headers: { Authorization: jwt_token },
    params: { id },
  });
};

/**
 * get about us details
 * @param {id} id
 * @returns
 */
export const getAboutUsDetails = (id) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_ABOUT_US_DETAILS}`, {
    headers: { Authorization: jwt_token },
    params: { id },
  });
};

/**
 * file upload
 * @param {file upload} formData
 * @returns
 */
export const FILE_UPLOAD_API = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${BYTE_WEB_FILE_UPLOAD}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * delete image permanently
 * @param {parameters} formData
 * @returns
 */
export const delete_Image_Permanent = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(
    `${process.env.REACT_APP_API_URL}${BYTE_WEB_DELETE_FILE}`,
    {
      headers: { Authorization: jwt_token },
      params: formData,
    }
  );
};

/**
 * short order about us
 * @param {body} updateOrder
 * @returns
 */
export const sort_order_About_Us = (updateOrder) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_ABOUT_US_ORDER}`,
    { updateOrder: updateOrder },
    {
      headers: { Authorization: jwt_token },
    }
  );
};
