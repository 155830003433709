import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Select from "react-select";
import CloneCategoryContext from "../../../context/CloneCategoryContext/CloneCategoryContext";
import { CLONE_CATEGORY, SINGLE_PAGE } from "../../../constant";
import { webMaster_List } from "../../../api/SupperAdminOrganizationApi/Organization";
import {
  OpenModalCommonFunc,
  TostMessage,
} from "../../../components ByteCard/utils";
import { Cases, DELETE_CONFIRM } from "../../../components ByteCard/constant";
import {
  delete_Image_Permanent,
  delete_Multi_Image_Permanent,
} from "../../../components ByteCard/components/MultipageByteCardBuilder/apis/SliderApi/SliderApi";
import ByteCardBuilderContext from "../../../components ByteCard/context/BytecardBilderContext/ByteCardBuilderContext";
import LoaderContext from "../../../context/LoaderContext/LoaderContext";
import { UploadCloud } from "react-feather";
import { Close } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import ProductImageUploadPopup from "../../../components ByteCard/Modals/imageUploade/productImageUploadPopup";

const CloneCategoryAddPopup = (props) => {
  const { handleSubmit, simpleValidator, emptyData, imageUpload, pageName } =
    props;
  const { addModel, setAddModel, formData, setFormData, isEdit } =
    useContext(CloneCategoryContext);
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const { setLoaderShow } = useContext(LoaderContext);

  const CloseModel = () => {
    emptyData();
    setAddModel(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const handleChangeSelectAll = (e, i) => {
    const { name, value } = e.target;
    let old = [...formData.allItem];
    old[i][name] = value;
    setFormData((prev) => {
      return {
        ...prev,
        allItem: old,
      };
    });
  };

  const handleChangeSelect = (selectedOption, actionMeta) => {
    if (
      actionMeta.action === "remove-value" ||
      actionMeta.action === "pop-value"
    ) {
      const removedOption = actionMeta.removedValue;
      const domName = selectedOption[selectedOption.length - 1];
      const dod = [...formData.allItem];
      const setValue = dod.filter((ele) => ele.webId != removedOption.value);
      setFormData((prev) => ({
        ...prev,
        ["webIds"]: selectedOption,
        allItem: setValue,
      }));
    } else if (selectedOption.length === 0) {
      setFormData((prev) => ({
        ...prev,
        ["webIds"]: [],
        allItem: [],
      }));
    } else {
      const domName = selectedOption[selectedOption.length - 1];
      setFormData((prev) => ({
        ...prev,
        ["webIds"]: selectedOption,
        allItem: [
          ...prev?.allItem,
          {
            image: "",
            title: "",
            videoLink: "",
            itemOrder: "",
            label: domName?.label,
            webId: domName?.value,
          },
        ],
      }));
    }
  };

  const [allItems, setAllItems] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const getWebMasterList = useCallback(
    async (page) => {
      try {
        let paramObj = {
          showAll: false,
          pageNumber: page,
          recordsPerPage: 10,
        };
        const { data } = await webMaster_List(paramObj, pageName);

        if (data.status === 200) {
          setAllItems((prevItems) => [
            ...prevItems,
            ...data.payload.data.map((ele) => ({
              value: ele.webId,
              label: ele.domain,
            })),
          ]);
          if (data.payload.data.length === 0) {
            setHasMore(false);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [pageName]
  );
  console.log("allItems >> ", allItems);

  useEffect(() => {
    getWebMasterList(page);
  }, [page]);

  const handleScrollToBottom = () => {
    if (!isLoading && hasMore) {
      setIsLoading(true);
      setPage((prevPage) => prevPage + 1);
    }
  };

  const deleteImagePermanent = async (index) => {
    const imagePathToDelete =
      pageName == SINGLE_PAGE
        ? process.env.REACT_APP_BUCKET_PRO_FOLDER
        : process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI;
    const ImageDeleteApi =
      pageName == SINGLE_PAGE
        ? delete_Image_Permanent
        : delete_Multi_Image_Permanent;
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formValue = {
          fileName: formData?.allItem[index]?.image
            ?.split(`${imagePathToDelete}/`)
            .pop(),
        };
        const { data } = await ImageDeleteApi(formValue);
        if (data.status === 200) {
          TostMessage({ message: data, type: "success", position: "center" });
          const setData = [...formData.allItem];
          setData[index]["image"] = "";
          setFormData((prev) => {
            return {
              ...prev,
              image: "",
              allItem: setData,
            };
          });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * image reupload
   */
  const reupload = () => {
    setFormData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };

  return (
    <>
      <Modal size="xl" isOpen={addModel} toggle={() => CloseModel()}>
        <ModalHeader toggle={() => CloseModel()}>
          {isEdit
            ? `${CLONE_CATEGORY.EDIT_THEME}`
            : `${CLONE_CATEGORY.ADD_THEME}`}
        </ModalHeader>
        <ModalBody>
          <Container fluid={true} className="p-0">
            <Row>
              <Form className="theme-form" onSubmit={(e) => handleSubmit(e)}>
                <Row>
                  <Col md="12 mb-4">
                    <Label>{"Name"}</Label>
                    <Input
                      className="form-control"
                      type={"text"}
                      style={{ color: "black" }}
                      name="name"
                      onChange={(e) => handleChange(e)}
                      value={formData?.name}
                      placeholder="Template Name"
                    />
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "Name",
                        formData?.name,
                        "required"
                      )}
                    </p>
                  </Col>
                  <Col md="12 mb-4">
                    <Label htmlFor="validationCustom03">{"Select Web"}</Label>
                    <Select
                      isMulti
                      name="webIds"
                      closeMenuOnSelect={false}
                      options={allItems}
                      value={formData?.webIds}
                      onMenuScrollToBottom={handleScrollToBottom}
                      isLoading={isLoading}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={handleChangeSelect}
                    />
                    {/* <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "webIds",
                        formData?.webIds,
                        "required"
                      )}
                    </p> */}
                  </Col>
                </Row>

                {formData?.allItem?.map((ele, index) => {
                  return (
                    <>
                      <Row key={index}>
                        <p>
                          <Label>{ele.label}</Label>
                        </p>
                        <Col md="1 mb-3">
                          <div
                            className="aj-uploaders d-flex flex-wrap  justify-content-center  align-items-center"
                            style={{
                              cursor: "pointer",
                              height: "60px !important",
                            }}
                          >
                            {!ele.image && (
                              <span
                                onClick={() => {
                                  {
                                    OpenModalCommonFunc(
                                      Cases.PRODUCT_UPLOAD,
                                      setAllPopupState
                                    );
                                    setFormData((prev) => {
                                      return {
                                        ...prev,
                                        imageNo: index,
                                      };
                                    });
                                  }
                                }}
                              >
                                <UploadCloud />
                              </span>
                            )}

                            {ele.image && (
                              <img
                                src={ele.image}
                                alt=""
                                style={{
                                  width: "85%",
                                  height: "60px",
                                  objectFit: "contain",
                                  position: "absolute",
                                }}
                                onClick={() => {
                                  deleteImagePermanent(index);
                                }}
                              />
                            )}
                            {ele.image && (
                              <>
                                {" "}
                                <IconButton
                                  edge="start"
                                  color="inherit"
                                  aria-label="close"
                                  style={{
                                    color: "red",
                                    position: "absolute",
                                    top: "-22px",
                                    left: "1px",
                                  }}
                                  onClick={() => deleteImagePermanent(index)}
                                >
                                  <Close
                                    style={{
                                      fontSize: "25px",
                                      fontWeight: "bold",
                                    }}
                                  />
                                </IconButton>
                              </>
                            )}
                          </div>
                        </Col>
                        <Col md="4 mb-4">
                          <Input
                            className="form-control"
                            type={"text"}
                            style={{ color: "black" }}
                            name="title"
                            onChange={(e) => handleChangeSelectAll(e, index)}
                            value={ele?.title}
                            placeholder="Title"
                          />
                        </Col>
                        <Col md="5 mb-4">
                          <Input
                            className="form-control"
                            type={"text"}
                            style={{ color: "black" }}
                            name="videoLink"
                            onChange={(e) => handleChangeSelectAll(e, index)}
                            value={ele?.videoLink}
                            placeholder="videoLink"
                          />
                        </Col>
                        <Col md="2 mb-4">
                          <Input
                            className="form-control"
                            type={"number"}
                            style={{ color: "black" }}
                            name="itemOrder"
                            onChange={(e) => handleChangeSelectAll(e, index)}
                            value={ele?.itemOrder}
                            placeholder="itemOrder"
                          />
                        </Col>
                      </Row>
                    </>
                  );
                })}
                <div className="login-btn mt-2">
                  <Button color="primary" type="submit" value="submit">
                    {"Submit"}
                  </Button>
                </div>
              </Form>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
      <ProductImageUploadPopup
        allPopupState={allPopupState}
        setAllPopupState={setAllPopupState}
        crop={crop}
        setCrop={setCrop}
        completedCrop={completedCrop}
        setCompletedCrop={setCompletedCrop}
        scale={scale}
        rotate={rotate}
        image={formData.image}
        setImage={setFormData}
        imageUpload={imageUpload}
        isEdit={isEdit}
        reupload={reupload}
        // aspect={1 / 1}
      />
    </>
  );
};

export default CloneCategoryAddPopup;
