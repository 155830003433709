import React, { Fragment, useContext, useEffect } from "react";
import { X } from "react-feather";
import { useNavigate, useParams } from "react-router";
import { toast, Zoom } from "react-toastify";
import { Card, CardHeader, Col, Container, Media, Row } from "reactstrap";
import { Org_PageList_Details } from "../../api/Organization Api/OrganizationApi";
import { Style } from "../../constant";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import PersonalPageContext from "../../context/PersonalPageContext/PersonalPageContaxt";
import { classes } from "../../data/layouts";
import Breadcrumbs from "../../layout/breadcrumb";
import TopLoadingBar from "../../utils/topLoadingBar";

const PersonalPageShow = () => {
  const { orgId, progress, setProgress, allDetails, setAllDetails } =
    useContext(PersonalPageContext);

  const { loaderShow, setLoaderShow } = useContext(LoaderContext);

  const history = useNavigate();
  let { hwId } = useParams();

  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  /**
   * get organization details function call and useEffect to use call function and toploading call
   */

  const Show = async () => {
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Org_PageList_Details(orgId);
      if (data.status === 200) {
        setAllDetails(data.payload.data);
        setLoaderShow(false);
      } else {
      }
    } catch (error) {
      toast.error(error.response, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };

  useEffect(() => {
    Show();
    setProgress(100);
  }, []);

  /**
   * Click to privius button to 1 step to back
   */

  const Previus = () => {
    history(`/${hwId}/PersonalPage/PersonalPage/${layout}`);
  };
  return (
    <Fragment>
      <Breadcrumbs parent="User Profile" />
      <TopLoadingBar progress={progress} setProgress={setProgress} />
      <Container fluid={true}>
        <div className="user-profile">
          <Row>
            <Col sm="12">
              <Card className="card hovercard text-center">
                <CardHeader></CardHeader>
                <div className="user-image">
                  <div className="avatar">
                    <Media
                      body
                      alt=""
                      src={
                        allDetails.orgLogo === null ? "" : allDetails.orgLogo
                      }
                      data-intro="This is Profile image"
                    />
                  </div>
                </div>
                <div className="info">
                  <Row>
                    <Col sm="6" lg="4" className="order-sm-1 order-xl-0">
                      <Row>
                        <Col md="6">
                          <div className="ttl-info text-start">
                            <h6>
                              <i className="fa fa-envelope me-2"></i> {"Email"}
                            </h6>
                            <span>{allDetails.email}</span>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="ttl-info text-start ttl-sm-mb-0">
                            <h6>
                              <i className="fa fa-calendar"></i>   
                              {"Page Title"}
                            </h6>
                            <span>{allDetails.pageTitle}</span>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm="12" lg="4" className="order-sm-0 order-xl-1">
                      <div className="user-designation">
                        <div className="title">
                          <a target="_blank" href="#javascript">
                            {allDetails.orgName}
                          </a>
                        </div>
                        <div className="desc mt-2">
                          {allDetails.designation}
                        </div>
                      </div>
                    </Col>
                    <Col sm="6" lg="4" className="order-sm-2 order-xl-2">
                      <Row>
                        <Col md="6">
                          <div className="ttl-info text-start ttl-xs-mt">
                            <h6>
                              <i className="fa fa-phone"></i>   {"ContactUs"}
                            </h6>
                            <span>{allDetails.mobile}</span>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="ttl-info text-start ttl-sm-mb-0">
                            <h6>
                              <i className="fa fa-location-arrow"></i>   
                              {"Location"}
                            </h6>
                            <span>{allDetails.address}</span>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6" lg="4" className="order-sm-1 order-xl-0">
                      <Row>
                        <Col md="6">
                          <div className="ttl-info text-start">
                            <h6>
                              <i className="fa fa-envelope me-2"></i> {"Email"}
                            </h6>
                            <span>{allDetails.userEmail}</span>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="ttl-info text-start ttl-sm-mb-0">
                            <h6>
                              <i className="fa fa-bank"></i>   
                              {"Country"}
                            </h6>
                            <span>{allDetails.country}</span>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm="12" lg="4" className="order-sm-0 order-xl-1">
                      <div className="user-designation">
                        <div className="title">
                          <a target="_blank" href="#javascript">
                            {allDetails.fname} {allDetails.lname}
                          </a>
                        </div>
                        <div className="desc mt-2">{allDetails.pageTitle}</div>
                      </div>
                    </Col>
                    <Col sm="6" lg="4" className="order-sm-2 order-xl-2">
                      <Row>
                        <Col md="6">
                          <div className="ttl-info text-start ttl-xs-mt">
                            <h6>
                              <i className="fa fa-bank"></i>   {"State"}
                            </h6>
                            <span>{allDetails.state}</span>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="ttl-info text-start ttl-sm-mb-0">
                            <h6>
                              <i className="icofont icofont-building-alt"></i>
                                 
                              {"City"}
                            </h6>
                            <span>{allDetails.city}</span>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr />
                  <div>
                    <div
                      aria-label="add"
                      className="btn btn-danger"
                      onClick={() => Previus()}
                      style={Style}
                    >
                      <X />
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

export default PersonalPageShow;
