import React, { useContext } from "react";
import { Add } from "@material-ui/icons";
import { DataGrid } from "@mui/x-data-grid";
import { Card, CardHeader, Col, Container, Row } from "reactstrap";
import { IconButton, Tooltip } from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import Switch from "react-js-switch";
import moment from "moment";
import ThemeContext from "../../context/ThemeContext/ThemeContext";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import { TostMessage } from "../../components ByteCard/utils";
import DataNotFound from "../../DataNoteFound/DataNotFound";
import { MuiTabelStyle, headerStyle } from "../../utils/MuiTabelStyle";
import { MOMENT_SETTING, Style, THEME, THEME_SELECT } from "../../constant";
import { TabelAnimation } from "../../Spinner";
import { Ditails_Theme_List } from "../../api/ThemeApi/ThemeApi";

const ThemeItem = (props) => {
  const { sorting, switch_onChange_handle, handleDelete } = props;
  const { setLoaderShow } = useContext(LoaderContext);
  const {
    formValue,
    record,
    pageSize,
    page,
    setPageSize,
    setPage,
    setAddModel,
    setFormData,
    setIsEdit,
    setId,
  } = useContext(ThemeContext);

  const EditHandle = async (templateId) => {
    try {
      setLoaderShow(true);
      setId(templateId);
      const { data } = await Ditails_Theme_List(templateId);
      if (data.status === 200) {
        let payload = data.payload.data;
        const itemThemeAll = payload?.pageItemThemeMapDetails?.map((ele) => {
          return {
            value: ele.itemId,
            label: ele.itemMasterDetail?.label,
          };
        });
        setFormData({
          templateName: payload?.templateName || "",
          code: payload?.code
            ? THEME_SELECT.find((elem) => elem.value == payload?.code)
            : "",
          itemIds: itemThemeAll || [],
          isSystem: +payload?.isSystem === 0 ? false : true,
          demoLink: payload?.demoLink || "",
          image: payload?.image || "",
          title: payload?.title || "",
          videLink: payload?.videLink || "",
        });
        setIsEdit(true);
        setLoaderShow(false);
        setAddModel(true);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 200,
      field: "templateName",
      headerName: "Template Name",
      renderCell: (params) =>
        params.row.templateName ? params.row.templateName : "-",
    },
    {
      flex: 0.1,
      minWidth: 200,
      field: "isSystem",
      headerName: "isSystem",
      renderCell: (params) =>
        params.row.isSystem === 1 ? (
          <p style={{ color: "green" }}>Yes</p>
        ) : (
          <p style={{ color: "red" }}>No</p>
        ),
    },
    {
      flex: 0.1,
      minWidth: 150,
      field: "createdAt",
      headerName: "Created Date",
      renderCell: (params) =>
        params.row.createdAt
          ? moment.utc(params.row.createdAt).format(MOMENT_SETTING.FORMATE)
          : "-",
    },
    {
      field: "isActive",
      headerName: "Status",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Switch
          value={params.row.isActive ? true : false}
          onChange={() =>
            switch_onChange_handle(params.row.templateId, params.row.isActive)
          }
        />
      ),
    },
    {
      flex: 0.01,
      minWidth: 130,
      field: "edit",
      headerName: "Edit",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Tooltip title="Edit">
          <IconButton
            aria-label="edit"
            style={{ color: "green" }}
            onClick={() => EditHandle(params.row.templateId)}
          >
            <ModeEditIcon />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      flex: 0.01,
      minWidth: 100,
      field: "delete",
      headerName: "Delete",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Tooltip title="Delete">
          <IconButton
            aria-label="delete"
            style={{ color: "red" }}
            onClick={() => handleDelete(params.row.templateId)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  const AddProducts = () => {
    setAddModel(true);
  };
  return (
    <>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <h3 style={headerStyle}>{THEME.THEME_NAME}</h3>

                <div
                  aria-label="add"
                  onClick={() => AddProducts()}
                  className="btn btn-primary"
                  style={Style}
                >
                  <Add />
                </div>
              </CardHeader>
              {TabelAnimation === true ? (
                <TabelAnimation />
              ) : formValue.length ? (
                <DataGrid
                  autoHeight
                  sx={MuiTabelStyle}
                  pagination
                  rows={formValue}
                  getRowId={(row) => row.templateId}
                  columns={defaultColumns}
                  rowsPerPageOptions={[7, 10, 25, 50]}
                  rowCount={record}
                  pageSize={pageSize}
                  page={page - 1}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  onPageChange={(newPageNo) => setPage(newPageNo + 1)}
                  paginationMode="server"
                  disableSelectionOnClick
                  checkboxSelection={false}
                  sortingMode="server"
                  onSortModelChange={sorting}
                />
              ) : (
                <DataNotFound />
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ThemeItem;
