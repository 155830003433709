import React, { useContext } from "react";
import {
  AboutUsTheme,
  Cases,
  DELETE_CONFIRM,
  SinglePage,
} from "../../../constant";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import Editor from "../../../common/editor";
import { IconButton } from "@material-ui/core";
import ItemsDragAndDrops from "../../../common/ItemsDragAndDrops";
import { Close } from "@material-ui/icons";
import AboutUsContext from "../../../context/AboutUsContext/AboutUsContext";
import {
  deleteAboutUs,
  sort_order_About_Us,
} from "../apis/AboutApi/AboutUsApi";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import Dividers from "../../../common/divider";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import { UploadCloud } from "react-feather";
import {
  delete_Image_Permanent,
  delete_Multi_Image_Permanent,
} from "../apis/SliderApi/SliderApi";
import InputFileUpload from "../../../Modals/imageUploade/InputFileUpload";
import AllExportsImage from "../../../Modals/imageUploade/InputFileUpload";
import IsShowTitleORNot from "../../../common/isShowTitleORNot";
import { maxLengthCheck } from "../../../../utils/helper";

const AboutUsPopup = (props) => {
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const {
    handleSubmit,
    handleChangeStatus,
    getData,
    handleEdit,
    emptyData,
    simpleValidator,
    webMasterDetail,
    imageUpload,
    getAboutUsList,
  } = props;
  const {
    apiData,
    setApiData,
    fromData,
    setFormData,
    isEdit,
    setIsEdit,
    setFilter,
    filter,
    id: aboutID,
    apiDataImageParent,
    childId,
  } = useContext(AboutUsContext);
  const { setLoaderShow } = useContext(LoaderContext);
  /**
   * drag and drop
   * @param {drag data} result
   * @returns
   */
  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;
    const items = Array.from(apiData.data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setApiData({ ...apiData, data: items });
    try {
      let itemOrder = [];
      for (const item in items) {
        itemOrder.push({ id: items[item].Id, order: +item + 1 });
      }
      const { data } = await sort_order_About_Us(itemOrder);
      if (data.status === 200) {
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };
  const deleteImagePermanent = async () => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData = {
          fileName: fromData.image
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
            .pop(),
        };
        const { data } = await delete_Multi_Image_Permanent(formData);
        if (data.status === 200) {
          TostMessage({ message: data, type: "success", position: "center" });
          setFormData({ ...fromData, image: "" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * reupload image
   */
  const reupload = () => {
    setFormData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };

  /**
   * change input value
   * @param {Event} e
   */
  const changeFormValue = (e) => {
    const { value, name, checked } = e.target;
    setFormData((prev) => {
      if (name === "isTitleShow") {
        return {
          ...prev,
          [name]: checked,
        };
      } else {
        return {
          ...prev,
          [name]: value,
        };
      }
    });
  };
  const handleAboutus = (event) => {
    setFormData((prev) => {
      return {
        ...prev,
        aboutUs: event,
      };
    });
  };

  /**
   * delete about us
   * @param {id} id
   */
  const handleDelete = async (id) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await deleteAboutUs(id);
        if (data.status === 200) {
          getData({ parentId: childId });
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * create new about us
   */
  const hanldenewCreate = () => {
    setFormData({
      title: "",
      aboutUs: "",
      order: 1,
      subTitle: "",
      theme: 1,
      buttonText: "",
      buttonLink: "",
      isTitleShow: true,
    });
    setIsEdit(false);
  };

  return (
    <Container>
      <Row>
        <Col sm="12" xl="6">
          <div>
            <h6>Total Count : {apiData?.data.length}</h6>
            {/* <p className="pt-2">You can add upto 25</p> */}
            <Row>
              <Col md="6">
                <Input
                  className="form-control mt-3 "
                  name="search"
                  type="text"
                  placeholder="search"
                  style={{ borderRadius: "0", color: "black" }}
                  value={filter}
                  onChange={(e) => {
                    getAboutUsList({ filter: e.target.value });
                    setFilter(e.target.value);
                  }}
                />
              </Col>
              <Col
                md="6"
                className="d-flex justify-content-center align-items-center mt-3"
              >
                <Button
                  color="primary"
                  className="rounded-0"
                  onClick={hanldenewCreate}
                >
                  Add New
                </Button>
              </Col>
            </Row>
            {apiData.isLoading ? (
              <div className="mt-4">
                <h6>Loading ...</h6>
              </div>
            ) : (
              <div className="mt-4">
                <ItemsDragAndDrops
                  handleOnDragEnd={handleOnDragEnd}
                  characters={apiData?.data}
                  handleChangeStatus={handleChangeStatus}
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                />
              </div>
            )}
          </div>
        </Col>
        <Col sm="12" xl="6">
          <div className="mt-2">
            <h6>{isEdit ? "Edit" : "Add New"}</h6>
            <Row className="mt-4">
              <Col md="12 mb-3">
                <Label htmlFor="validationCustom03">
                  Title *{" "}
                  <IsShowTitleORNot
                    checked={fromData?.isTitleShow}
                    onChange={changeFormValue}
                  />{" "}
                </Label>
                <Input
                  className="form-control"
                  name="title"
                  type="text"
                  placeholder="Title *"
                  style={{ borderRadius: "0", color: "black" }}
                  value={fromData.title}
                  onChange={(e) => changeFormValue(e)}
                />
                <p style={{ color: "red" }}>
                  {simpleValidator.current.message(
                    "Title",
                    fromData.title,
                    "required"
                  )}
                </p>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md="12 mb-3">
                <Label htmlFor="validationCustom03">
                  {"Short Description"}
                </Label>
                <Input
                  className="form-control"
                  name="subTitle"
                  type="text"
                  placeholder="Short Description"
                  style={{ borderRadius: "0", color: "black" }}
                  value={fromData.subTitle}
                  onChange={(e) => changeFormValue(e)}
                  min={0}
                  maxLength={250}
                  onInput={maxLengthCheck}
                />
                <div className="text-end mt-3 ">
                  <span>{fromData.subTitle.length} / 250</span>
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md="12 mb-3">
                <Label htmlFor="validationCustom03">{"Button Text"}</Label>
                <Input
                  className="form-control"
                  name="buttonText"
                  type="text"
                  placeholder="Button Text"
                  style={{ borderRadius: "0", color: "black" }}
                  value={fromData.buttonText}
                  onChange={(e) => changeFormValue(e)}
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md="12 mb-3">
                <Label htmlFor="validationCustom03">{"Button Link"}</Label>
                <Input
                  className="form-control"
                  name="buttonLink"
                  type="text"
                  placeholder="Button Link"
                  style={{ borderRadius: "0", color: "black" }}
                  value={fromData.buttonLink}
                  onChange={(e) => changeFormValue(e)}
                />
              </Col>
            </Row>
            <Row>
              <Col md="12 mb-4">
                <Editor value={fromData.aboutUs} onChange={handleAboutus} />
                <p style={{ color: "red" }}>
                  {simpleValidator.current.message(
                    "About us",
                    fromData.aboutUs,
                    "required"
                  )}
                </p>
              </Col>
            </Row>
            <Dividers divide="IMAGE" />
            <p className="mb-4">
              {AllExportsImage.fileUploadMessage} (567px X 414px)
            </p>
            <Row>
              <Col md="6 mb-3">
                <div
                  className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                  style={{ cursor: "pointer" }}
                >
                  {!fromData?.image && (
                    <span
                      onClick={() => {
                        OpenModalCommonFunc(
                          Cases.PRODUCT_UPLOAD,
                          setAllPopupState
                        );
                      }}
                    >
                      <UploadCloud />
                    </span>
                  )}

                  {fromData?.image && (
                    <img
                      src={fromData?.image || ""}
                      alt=""
                      style={{
                        width: "80%",
                        height: "98%",
                        objectFit: "contain",
                        position: "absolute",
                      }}
                      onClick={() => {
                        deleteImagePermanent();
                      }}
                    />
                  )}
                  {fromData.image && (
                    <>
                      {" "}
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="close"
                        style={{
                          color: "red",
                          position: "absolute",
                          top: "-22px",
                          left: "1px",
                        }}
                        onClick={() => deleteImagePermanent()}
                      >
                        <Close
                          style={{ fontSize: "25px", fontWeight: "bold" }}
                        />
                      </IconButton>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <ProductImageUploadPopup
        allPopupState={allPopupState}
        setAllPopupState={setAllPopupState}
        crop={crop}
        setCrop={setCrop}
        completedCrop={completedCrop}
        setCompletedCrop={setCompletedCrop}
        scale={scale}
        rotate={rotate}
        image={fromData.image}
        setImage={setFormData}
        imageUpload={imageUpload}
        isEdit={isEdit}
        reupload={reupload}
        // aspect={1.37 / 1}
      />
    </Container>
  );
};

export default AboutUsPopup;
