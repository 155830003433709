import axios from "axios";
import {
  GET_OUR_TEAM,
  GET_OUR_TEAM_DETAILS,
  GET_OUR_TEAM_MEMBER,
  GET_OUR_TEAM_MEMBER_DETAILS,
  POST_OUR_TEAM,
  POST_OUR_TEAM_MEMBER,
  PUT_OUR_TEAM_MEMBER_ORDER,
  PUT_OUR_TEAM_ORDER,
  PUT_STATUS_OUR_TEAM,
  PUT_STATUS_OUR_TEAM_MEMBER,
} from "../../apiConstants";

/**
 * get our team data member
 * @param {Parameters} paramObj
 * @returns
 */
export const getOutTeamMember = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_OUR_TEAM_MEMBER}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * enable-disable member
 * @param {body} body
 * @returns
 */
export const updateStatus = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_STATUS_OUR_TEAM_MEMBER}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * create our team member
 * @param {body} body
 * @returns
 */
export const postOurTeamMember = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${POST_OUR_TEAM_MEMBER}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * update our team member
 * @param {body} body
 * @returns
 */
export const putUpdateOurTeamMember = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${POST_OUR_TEAM_MEMBER}`,
    body,
    {
      headers: { Authorization: jwt_token },
      params: { teamMemberId: body.teamMemberId },
    }
  );
};

/**
 * delete our team member
 * @param {id} teamMemberId
 * @returns
 */
export const deleteOurTeamMember = (teamMemberId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(
    `${process.env.REACT_APP_API_URL}${POST_OUR_TEAM_MEMBER}`,
    {
      headers: { Authorization: jwt_token },
      params: { teamMemberId },
    }
  );
};

/**
 * get our team member details member
 * @param {id} teamId
 * @returns
 */
export const getOurTeamMemberDetails = (teamMemberId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${GET_OUR_TEAM_MEMBER_DETAILS}`,
    {
      headers: { Authorization: jwt_token },
      params: { teamMemberId },
    }
  );
};

/**
 * short order our team member
 * @param {body} updateOrder
 * @returns
 */
export const sort_order_Our_Team_Member = (updateOrder) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_OUR_TEAM_MEMBER_ORDER}`,
    { updateOrder: updateOrder },
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * get our team data
 * @param {Parameters} paramObj
 * @returns
 */
export const getOutTeam = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_OUR_TEAM}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * enable-disable
 * @param {body} body
 * @returns
 */
export const updateStatusOurTeam = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_STATUS_OUR_TEAM}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * create our team
 * @param {body} body
 * @returns
 */
export const postOurTeam = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(`${process.env.REACT_APP_API_URL}${POST_OUR_TEAM}`, body, {
    headers: { Authorization: jwt_token },
  });
};

/**
 * update our team
 * @param {body} body
 * @returns
 */
export const putUpdateOurTeam = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(`${process.env.REACT_APP_API_URL}${POST_OUR_TEAM}`, body, {
    headers: { Authorization: jwt_token },
    params: { teamId: body.teamId },
  });
};

/**
 * delete our team
 * @param {id} teamId
 * @returns
 */
export const deleteOurTeam = (teamId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${POST_OUR_TEAM}`, {
    headers: { Authorization: jwt_token },
    params: { teamId },
  });
};

/**
 * get our team details
 * @param {id} teamId
 * @returns
 */
export const getOurTeamDetails = (teamId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_OUR_TEAM_DETAILS}`, {
    headers: { Authorization: jwt_token },
    params: { teamId },
  });
};

/**
 * short order our team
 * @param {body} updateOrder
 * @returns
 */
export const sort_order_Our_Team = (updateOrder) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_OUR_TEAM_ORDER}`,
    { updateOrder: updateOrder },
    {
      headers: { Authorization: jwt_token },
    }
  );
};
