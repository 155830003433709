import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Select from "react-select";
import ThemeContext from "../../context/ThemeContext/ThemeContext";
import { THEME, THEME_SELECT } from "../../constant";
import { Switch } from "@material-ui/core";
import { DropDownPageItemList } from "../../api/Organization Api/OrganizationApi";
import { UploadCloud } from "react-feather";
import { Close } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import {
  OpenModalCommonFunc,
  TostMessage,
} from "../../components ByteCard/utils";
import { Cases, DELETE_CONFIRM } from "../../components ByteCard/constant";
import ByteCardBuilderContext from "../../components ByteCard/context/BytecardBilderContext/ByteCardBuilderContext";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import {
  delete_Image_Permanent,
  delete_Multi_Image_Permanent,
} from "../../components ByteCard/components/MultipageByteCardBuilder/apis/SliderApi/SliderApi";
import ProductImageUploadPopup from "../../components ByteCard/Modals/imageUploade/productImageUploadPopup";

const ThemeAddPopup = (props) => {
  const { handleSubmit, simpleValidator, emptyData, imageUpload } = props;
  const { addModel, setAddModel, formData, setFormData, isEdit } =
    useContext(ThemeContext);
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const [allItems, setAllItems] = useState([]);
  const { setLoaderShow } = useContext(LoaderContext);

  const CloseModel = () => {
    emptyData();
    setAddModel(false);
  };
  const handleChange = (e, selectedName) => {
    const selectName = selectedName ? selectedName.name : "";
    if (selectName === "code") {
      setFormData((prev) => {
        return {
          ...prev,
          [selectName]: e,
        };
      });
    } else {
      const { name, value, checked } = e.target;
      setFormData((prev) => {
        if (name === "isSystem") {
          return {
            ...prev,
            isSystem: checked,
          };
        } else {
          return {
            ...prev,
            [name]: value,
          };
        }
      });
    }
  };
  const handleChangeSelect = (selectedOption, name) => {
    setFormData((prev) => ({
      ...prev,
      [name]: selectedOption,
    }));
  };

  const getItemsList = async () => {
    try {
      let paramObj = {
        showAll: true,
      };
      const { data } = await DropDownPageItemList(paramObj);

      if (data.status === 200) {
        setAllItems(
          data.payload.data.map((ele) => {
            return {
              value: ele.itemId,
              label: ele.label,
            };
          })
        );
      } else {
      }
    } catch (error) {}
  };
  useEffect(() => {
    getItemsList();
  }, []);

  const isSystemValue = formData?.isSystem ?? false;
  const deleteImagePermanent = async () => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formValue = {
          fileName: formData.image
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
            .pop(),
        };
        const { data } = await delete_Image_Permanent(formValue);
        if (data.status === 200) {
          TostMessage({ message: data, type: "success", position: "center" });
          setFormData((prev) => {
            return {
              ...prev,
              image: "",
            };
          });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };
  /**
   * reupload image
   */
  const reupload = () => {
    setFormData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };
  return (
    <>
      <Modal
        size="xl"
        isOpen={addModel}
        toggle={() => CloseModel()}
        modalClassName="custom-modal-zIndex"
      >
        <ModalHeader toggle={() => CloseModel()}>
          {isEdit ? `${THEME.EDIT_THEME}` : `${THEME.ADD_THEME}`}
        </ModalHeader>
        <ModalBody>
          <Container fluid={true} className="p-0">
            <Row>
              <Form className="theme-form" onSubmit={(e) => handleSubmit(e)}>
                <Row>
                  <Col md="6 mb-4">
                    <Label>{"Template Name *"}</Label>
                    <Input
                      className="form-control"
                      type={"text"}
                      style={{ color: "black" }}
                      name="templateName"
                      onChange={(e) => handleChange(e)}
                      value={formData?.templateName}
                      placeholder="Template Name"
                    />
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "Template Name",
                        formData?.templateName,
                        "required"
                      )}
                    </p>
                  </Col>
                  <Col md="6 mb-4">
                    <Label>{"Code *"}</Label>
                    <Select
                      name="code"
                      closeMenuOnSelect
                      options={THEME_SELECT}
                      value={formData?.code}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={handleChange}
                      isClearable
                    />
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "code",
                        formData?.code,
                        "required"
                      )}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md="6 mb-4">
                    <Label htmlFor="validationCustom03">{"Select items"}</Label>
                    <Select
                      isMulti
                      name="itemIds"
                      closeMenuOnSelect={false}
                      options={allItems}
                      value={formData?.itemIds}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => handleChangeSelect(e, "itemIds")}
                    />
                    {/* <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "itemIds",
                        formData?.itemIds,
                        "required"
                      )}
                    </p> */}
                  </Col>
                  <Col md="6 mb-4">
                    <Label>{"Demo Link"}</Label>
                    <Input
                      className="form-control"
                      type={"text"}
                      style={{ color: "black" }}
                      name="demoLink"
                      onChange={(e) => handleChange(e)}
                      value={formData?.demoLink}
                      placeholder="Demo Link"
                    />
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "Demo Link",
                        formData?.templateName,
                        "required"
                      )}
                    </p>
                  </Col>
                  <Col md="6 mb-4">
                    <Label>{"Title"}</Label>
                    <Input
                      className="form-control"
                      type={"text"}
                      style={{ color: "black" }}
                      name="title"
                      onChange={(e) => handleChange(e)}
                      value={formData?.title}
                      placeholder="Title"
                    />
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "Title",
                        formData?.templateName,
                        "required"
                      )}
                    </p>
                  </Col>
                  <Col md="6 mb-4">
                    <Label>{"Video Link"}</Label>
                    <Input
                      className="form-control"
                      type={"text"}
                      style={{ color: "black" }}
                      name="videLink"
                      onChange={(e) => handleChange(e)}
                      value={formData?.videLink}
                      placeholder="Video Link"
                    />
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "Video Link",
                        formData?.templateName,
                        "required"
                      )}
                    </p>
                  </Col>
                  <Col md="4 mb-3">
                    <Label>{"Image"}</Label>
                    <div
                      className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                      style={{
                        cursor: "pointer",
                        height: "60px !important",
                      }}
                    >
                      {!formData.image && (
                        <span
                          onClick={() => {
                            {
                              OpenModalCommonFunc(
                                Cases.PRODUCT_UPLOAD,
                                setAllPopupState
                              );
                            }
                          }}
                        >
                          <UploadCloud />
                        </span>
                      )}

                      {formData.image && (
                        <img
                          src={formData.image}
                          alt=""
                          style={{
                            width: "85%",
                            height: "90px",
                            objectFit: "contain",
                            position: "absolute",
                          }}
                          onClick={() => {
                            deleteImagePermanent();
                          }}
                        />
                      )}
                      {formData.image && (
                        <>
                          {" "}
                          <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="close"
                            style={{
                              color: "red",
                              position: "absolute",
                              top: "-22px",
                              left: "1px",
                            }}
                            onClick={() => deleteImagePermanent()}
                          >
                            <Close
                              style={{
                                fontSize: "25px",
                                fontWeight: "bold",
                              }}
                            />
                          </IconButton>
                        </>
                      )}
                    </div>
                  </Col>
                  <Col md="6 mb-4">
                    <Label htmlFor="validationCustom03">{"isSystem"}</Label>
                    <div>
                      <Switch
                        checked={isSystemValue}
                        onChange={(e) => handleChange(e)}
                        name="isSystem"
                        color="primary"
                      />
                    </div>
                  </Col>
                </Row>
                <div className="login-btn mt-2">
                  <Button color="primary" type="submit" value="submit">
                    {"Submit"}
                  </Button>
                </div>
              </Form>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
      <ProductImageUploadPopup
        allPopupState={allPopupState}
        setAllPopupState={setAllPopupState}
        crop={crop}
        setCrop={setCrop}
        completedCrop={completedCrop}
        setCompletedCrop={setCompletedCrop}
        scale={scale}
        rotate={rotate}
        image={formData.image}
        setImage={setFormData}
        imageUpload={imageUpload}
        isEdit={isEdit}
        reupload={reupload}
        // aspect={1 / 1}
      />
    </>
  );
};

export default ThemeAddPopup;
