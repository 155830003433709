import React, { useContext, useMemo } from "react";
import { Cases, btnName, modalsName } from "../../../constant";
import { Card, CardBody, Col, Row } from "reactstrap";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import CommonPopup from "../../../Modals/commonPopup";
import CompantLogoAndServicesPopup from "./CompantLogoAndServicesPopup";
import ByteMiniWebDashboardContext from "../../../context/ByteMiniWeb_Dashboard_Context/ByteMiniWeb_Dashboard_Context";
import { FILE_UPLOAD_API } from "../../../apis/SliderApi/SliderApi";
import { FILE_TYPE } from "../../../../constant";
import {
  getCompanyLogo,
  postCompanyLogo,
  putCompanyLogo,
} from "../../../apis/CompanyLogoApi/CompanyLogoApi";
import CompanyLogoContext from "../../../context/CompanyLogoContext/CompanyLogoContext";
import { splitPathWeb } from "../../../apiConstants";
import { randomStr } from "../../../../utils/helper";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";

const CompanyLogo = (props) => {
  const { ByteMiniWeb_Dashboard } = props;
  const { setAllPopupState, allPopupState, ourServices, setOverServices } =
    useContext(ByteCardBuilderContext);
  const { apiData } = useContext(ByteMiniWebDashboardContext);
  const { fromData, setFormData, id, setId, setIsEdit, isEdit } =
    useContext(CompanyLogoContext);
  const { setLoaderShow } = useContext(LoaderContext);

  const getCompanyLogoData = async () => {
    try {
      const { data } = await getCompanyLogo();
      if (data.status === 200) {
        if (data.payload.data.length) {
          setFormData({
            logo: data?.payload?.data[0]?.logo,
            image: data?.payload?.data[0]?.logo,
            tagLine: data?.payload?.data[0]?.tagLine,
            name: data?.payload?.data[0]?.name,
            designation: data?.payload?.data[0]?.designation,
            services: [],
            webId: localStorage.getItem("webId"),
          });
          setOverServices(
            data?.payload?.data[0]?.services
              ? JSON.parse(data.payload.data[0].services)
              : []
          );
          setIsEdit(true);
          setId(data?.payload?.data[0]?.id && data?.payload?.data[0]?.id);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  useMemo(() => {
    if (allPopupState.companyPopup) {
      getCompanyLogoData();
    }
  }, [allPopupState.companyPopup]);

  /**
   * file upload
   * @param {file} file
   */
  const imageUpload = async (file) => {
    setLoaderShow(true);
    let formData = new FormData();
    formData.append("type", FILE_TYPE.BYTE_SLIDER);
    formData.append("file", file);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        setFormData((prev) => {
          return {
            ...prev,
            image: data.payload.baseUrl + data.payload.imageUrl,
            logo: data.payload.baseUrl + data.payload.imageUrl,
          };
        });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const handleSubmit = async () => {
    if (id >= -1 && isEdit) {
      setLoaderShow(true);
      let newData = {};
      if (fromData.image.length) {
        setFormData((prev) => {
          return {
            ...prev,
            logo: prev.image
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
              .pop(),
            services: ourServices,
            id: id,
          };
        });
        newData = {
          ...fromData,
          logo: fromData.image
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
            .pop(),
          services: ourServices,
          id: id,
        };
      } else {
        newData = {
          ...fromData,
          services: ourServices,
          id: id,
          logo: fromData.image
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
            .pop(),
        };
      }
      try {
        const { data } = await putCompanyLogo(newData);
        if (data.status === 200) {
          getCompanyLogoData();
          emptyData();
          TostMessage({ message: data, type: "success" });
          ByteMiniWeb_Dashboard();
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    } else {
      try {
        setLoaderShow(true);
        let newData = {};
        if (fromData.image.length) {
          setFormData((prev) => {
            return {
              ...prev,
              logo: prev?.image
                ? prev.image
                    .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
                    .pop()
                : "",
              services: ourServices,
            };
          });
          newData = {
            ...fromData,
            logo: fromData?.image
              ? fromData.image
                  .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
                  .pop()
              : "",
            services: ourServices,
          };
        } else {
          newData = { ...fromData, services: ourServices };
        }
        const { data } = await postCompanyLogo(newData);
        if (data.status === 200) {
          getCompanyLogoData();
          emptyData();
          TostMessage({ message: data, type: "success" });
          ByteMiniWeb_Dashboard();
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  const emptyData = () => {
    setIsEdit(false);
    setFormData({
      logo: "",
      image: "",
      tagLine: "",
      name: "",
      designation: "",
      services: [],
      webId: localStorage.getItem("webId"),
    });
    setLoaderShow(false);
    setOverServices([{ id: randomStr(2), name: "" }]);
  };

  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody className="support-ticket-font box-border-div">
            <Row>
              <div className="col-12">
                <h5 className="total-num counter mt-1 ">
                  Company Logo, Tagline & Services
                </h5>
                <span className="d-flex align-items-center gap-2 mt-1">
                  <div>
                    This will be used for <b>Back view</b> of Digital Card
                  </div>
                </span>
                <div
                  className="aj-card-illustration mt-4 mb-3 "
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={
                      apiData?.data?.companyLogoTaglineService?.logo ||
                      require("./no-img.jpg")
                    }
                    alt=""
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "100%",
                      objectFit: "contain",
                      border: "1px solid lightgray",
                    }}
                  />
                </div>
              </div>
            </Row>
            <OpenPopupBtn
              openPopup={() =>
                OpenModalCommonFunc(Cases.COMPANY, setAllPopupState)
              }
              name={btnName.COMPANY}
            />
          </CardBody>
        </Card>
      </Col>
      {allPopupState.companyPopup && (
        <CommonPopup
          isMini={true}
          open={allPopupState.companyPopup}
          close={() =>
            OpenModalCommonFunc(Cases.COMPANY, setAllPopupState, false)
          }
          modalName={modalsName.COMPANY_LOGO}
          modalCase={Cases.COMPANY}
          handleSubmit={handleSubmit}
          emptyData={emptyData}
          inputData={fromData}
          edit={true}
          content={
            <CompantLogoAndServicesPopup
              handleSubmit={handleSubmit}
              getData={getCompanyLogoData}
              imageUpload={imageUpload}
              emptyData={emptyData}
            />
          }
        />
      )}
    </>
  );
};

export default CompanyLogo;
