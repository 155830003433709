import axios from "axios";
import {
  BYTE_WEB_DASHBOARD,
  CHECK_SUB_DOMAIN,
  UPDATE_DOMAIN_NAME,
} from "../../apiConstants";

/**
 * get dashboard data
 * @param {Parameters} paramObj
 * @returns
 */
export const Byte_Mini_Web_Dashboard = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${BYTE_WEB_DASHBOARD}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * get check domain
 * @param {Parameters} paramObj
 * @returns
 */
export const Check_Sub_Domain = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${CHECK_SUB_DOMAIN}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * update domain name
 * @param {Parameters} paramObj
 * @returns
 */
export const Update_Domain_Name = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${UPDATE_DOMAIN_NAME}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: formData,
    }
  );
};
