import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import {
  Cases,
  DELETE_CONFIRM,
  SinglePage,
  btnName,
  modalsName,
} from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import Lightbox from "react-image-lightbox";
import CommonPopup from "../../../Modals/commonPopup";
import { FILE_TYPE, titleUpdateKey } from "../../../../constant";
import { splitPathWeb } from "../../../apiConstants";
import { FILE_UPLOAD_API } from "../../../apis/SliderApi/SliderApi";
import { IconButton } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import { Trash } from "react-feather";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import {
  deletePayment,
  getPayment,
  getPaymentDetails,
  getPaymentItems,
  getPaymentItemsDetails,
  postPayment,
  postPaymentItems,
  putUpdatePayment,
  putUpdatePaymentItems,
  updateStatus,
  updateStatusPayment,
} from "../../../apis/PaymentApi/PaymentApi";
import PaymentContext from "../../../context/PaymentContext/PaymentContext";
import PaymentItemPopup from "./PaymentItemPopup";
import PaymentPopup from "./PaymentPopup";
import SimpleReactValidator from "simple-react-validator";

const Payment = (props) => {
  const { webMasterDetail, navbarData, getData } = props;
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const simpleValidatorPaymentItem = useRef(new SimpleReactValidator());
  const [, forceUpdate1] = useState();
  const { setLoaderShow } = useContext(LoaderContext);
  const {
    apiData: ourTeamData,
    setApiData,
    fromData,
    setFormData,
    formDataPayment,
    setFormDataPayment,
    id,
    setId,
    setIsEdit,
    isEdit,
    filter,
    apiDataPayment,
    setApiDataPayment,
    paymentItemId,
    setPaymentItemId,
  } = useContext(PaymentContext);
  const [open, setOpen] = useState(false);
  const [imageSet, setImageSet] = useState("");

  /**
   * get payment items list
   */
  const getPaymentItemsList = async (status) => {
    try {
      if (!filter.length && status) {
        setApiData((prev) => {
          return { ...prev, isLoading: true };
        });
      }
      let paramObj = {
        search: { title: filter, paymentId: id },
        showAll: true,
        paymentId: id,
      };
      const { data } = await getPaymentItems(paramObj);
      if (data.status === 200) {
        setApiData({
          ...ourTeamData,
          isLoading: false,
          data: data.payload.data.map((elem) => {
            return {
              id: elem?.title + elem?.paymentItemId,
              Id: elem?.paymentItemId,
              paymentId: elem?.paymentId,
              name: elem?.title,
              image: elem?.image,
              createdAt: elem?.createdAt,
              isActive: elem?.isActive,
            };
          }),
        });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };
  /**
   * get payment list
   * @param {status} status
   */
  const getPaymentList = async (status) => {
    try {
      if (!filter.length && status) {
        setApiDataPayment((prev) => {
          return { ...prev, isLoading: true };
        });
      }

      let paramObj = {
        search: { title: filter },
      };
      const { data } = await getPayment(paramObj);
      if (data.status === 200) {
        setApiDataPayment({
          ...apiDataPayment,
          isLoading: false,
          data: data.payload.data,
        });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  useMemo(() => {
    if (allPopupState?.paymentItemsPopup) {
      getPaymentItemsList();
    }
  }, [filter, allPopupState?.paymentItemsPopup]);

  useEffect(() => {
    getPaymentList();
  }, []);

  /**
   * payment items enable disable api call
   * @param {paymentItem id} paymentItemId
   * @param {status} isActive
   */

  const handleChangeStatus = async (paymentItemId, isActive) => {
    try {
      let body = {
        paymentItemId,
        isActive: isActive ? false : true,
        navMapId: navbarData[0].navMapId,
      };
      const { data } = await updateStatus(body);
      if (data.status === 200) {
        getPaymentItemsList(false);
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };
  /**
   * payment enable disable api call
   * @param {payment Id} teamId
   * @param {status} isActive
   */

  const handleChangePaymentStatus = async (paymentId, isActive) => {
    try {
      let body = {
        paymentId,
        isActive: isActive ? false : true,
        navMapId: navbarData[0].navMapId,
      };
      const { data } = await updateStatusPayment(body);
      if (data.status === 200) {
        getPaymentList();
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * file upload
   * @param {file} file
   */
  const imageUpload = async (file, name) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.BYTE_SLIDER);
    formData.append("file", file);
    setLoaderShow(true);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        setFormDataPayment((prev) => {
          return {
            ...prev,
            image: data.payload.baseUrl + data.payload.imageUrl,
          };
        });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };
  /**
   * file upload
   * @param {file} file
   */
  const imageUploadTeamMember = async (file) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.BYTE_SLIDER);
    formData.append("file", file);
    setLoaderShow(true);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        setFormData((prev) => {
          return {
            ...prev,
            image: data.payload.baseUrl + data.payload.imageUrl,
          };
        });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * create and update payment items
   */
  const handleSubmit = async () => {
    const formValid = simpleValidatorPaymentItem.current.allValid();
    if (!formValid) {
      simpleValidatorPaymentItem.current.showMessages();
      forceUpdate1(1);
    } else {
      if (paymentItemId >= -1 && isEdit) {
        setLoaderShow(true);
        let newData = {
          ...fromData,
          navMapId: navbarData[0].navMapId,
          paymentId: id,
          paymentItemId,
        };
        if (fromData.image.length) {
          setFormData((prev) => {
            return {
              ...prev,
              image: prev.image
                .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
                .pop(),
              paymentId: id,
              paymentItemId,
            };
          });
          newData["image"] = fromData.image
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
            .pop();
        }
        try {
          const { data } = await putUpdatePaymentItems(newData);
          if (data.status === 200) {
            getPaymentItemsList();
            emptyData();
            TostMessage({ message: data, type: "success" });
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      } else {
        try {
          setLoaderShow(true);
          let newData = {
            ...fromData,
            navMapId: navbarData[0].navMapId,
            paymentId: id,
            order: ourTeamData.data.length + 1,
          };
          if (fromData.image.length) {
            setFormData((prev) => {
              return {
                ...prev,
                image: prev.image
                  .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
                  .pop(),
                paymentId: id,
                order: ourTeamData.data.length + 1,
              };
            });
            newData["image"] = fromData.image
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
              .pop();
          }

          const { data } = await postPaymentItems(newData);
          if (data.status === 200) {
            getPaymentItemsList();
            emptyData();
            TostMessage({ message: data, type: "success" });
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      }
    }
  };

  /**
   * create and update payment
   */
  const handleSubmitPayment = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      if (id >= -1 && isEdit) {
        setLoaderShow(true);
        setFormDataPayment((prev) => {
          return {
            ...prev,
            image: prev.image
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
              .pop(),
            navMapId: navbarData[0].navMapId,
          };
        });
        let newData = {
          ...formDataPayment,
          image: formDataPayment.image
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
            .pop(),
          navMapId: navbarData[0].navMapId,
        };
        try {
          const { data } = await putUpdatePayment(newData);
          if (data.status === 200) {
            getPaymentList();
            emptyData();
            OpenModalCommonFunc(Cases.PAYMENT, setAllPopupState, false);
            TostMessage({ message: data, type: "success" });
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      } else {
        try {
          setLoaderShow(true);
          setFormDataPayment((prev) => {
            return {
              ...prev,
              image: prev.image
                .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
                .pop(),
              navMapId: navbarData[0].navMapId,
            };
          });
          let newData = {
            ...formDataPayment,
            image: formDataPayment.image
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
              .pop(),
            navMapId: navbarData[0].navMapId,
          };
          const { data } = await postPayment(newData);
          if (data.status === 200) {
            getPaymentList();
            emptyData();
            OpenModalCommonFunc(Cases.PAYMENT, setAllPopupState, false);
            TostMessage({ message: data, type: "success" });
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      }
    }
  };

  /**
   * get payment items details
   * @param {id} paymentItemId
   */
  const handleEdit = async (paymentItemId) => {
    try {
      setLoaderShow(true);
      setPaymentItemId(paymentItemId);
      const { data } = await getPaymentItemsDetails(paymentItemId);
      if (data.status === 200) {
        let payload = data.payload.data;
        setFormData({
          title: payload.title,
          subTitle: payload.subTitle,
          image: payload.image,
          teamId: payload.teamId,
          webId: localStorage.getItem("webId"),
        });
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * get data payment
   * @param {id} paymentId
   */
  const handleEditPayment = async (paymentId) => {
    try {
      setLoaderShow(true);
      setId(paymentId);
      const { data } = await getPaymentDetails(paymentId);
      if (data.status === 200) {
        let payload = data.payload.data;
        setFormDataPayment({
          title: payload.title,
          image: payload.image,
          paymentId: paymentId,
          webId: localStorage.getItem("webId"),
        });
        OpenModalCommonFunc(Cases.PAYMENT, setAllPopupState);
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(true);
    }
  };

  /**
   * delete payment
   * @param {id} paymentId
   */
  const handleDelete = async (paymentId) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await deletePayment(paymentId);
        if (data.status === 200) {
          getPaymentList();
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * clear data
   */
  const emptyData = () => {
    setIsEdit(false);
    setFormData({
      title: "",
      subTitle: "",
      image: "",
      webId: localStorage.getItem("webId"),
    });
    setPaymentItemId("");
    setFormDataPayment({
      title: "",
      description: "",
      image: "",
      webId: localStorage.getItem("webId"),
    });
    setLoaderShow(false);
  };

  const filterValue = () => {
    let [value] = navbarData.filter(
      (elem) => elem.navbarDetail.label === titleUpdateKey.Payments
    );
    return value;
  };

  const value = useMemo(() => filterValue(), [navbarData]);
  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody className="support-ticket-font box-border-div">
            <Row>
              <CardBuilderHeaderSection
                name={`${apiDataPayment?.data?.length} ${
                  value?.label || "Payment"
                }`}
                isAdd={
                  webMasterDetail?.webCategory?.name ===
                    SinglePage?.WebCategory &&
                  apiDataPayment?.data?.length !==
                    SinglePage?.Payment_Parent_Limit &&
                  true
                }
                addBtnTitle={"Payment Section"}
                openModal={() =>
                  OpenModalCommonFunc(Cases.PAYMENT, setAllPopupState)
                }
              />
              {apiDataPayment?.data?.map((elem) => {
                return (
                  <Col xl="3 xl-50 box-col-6" md="6" key={elem.paymentId}>
                    <Card className="ecommerce-widget ">
                      <CardBody className="support-ticket-font">
                        <div className="col-12">
                          <CardBuilderHeaderSection
                            name={elem?.title}
                            checked={elem?.isActive}
                            id={elem?.paymentId}
                            switchToggle={true}
                            handleChange={handleChangePaymentStatus}
                          />
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setOpen(!open);
                              setImageSet(elem.image);
                            }}
                          >
                            <div
                              style={{
                                objectFit: "contain",
                                maxWidth: "125px",
                                height: "83px",
                                border: "1px solid #d3d3d3",
                                width: "100%",
                                display: "inline-block",
                                verticalAlign: "middle",
                                overflow: "hidden",
                                position: "relative",
                              }}
                            >
                              <div
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  right: "0",
                                  bottom: "0",
                                  left: "0",
                                  borderRadius: "inherit",
                                }}
                              >
                                <img
                                  src={elem.image}
                                  alt=""
                                  style={{
                                    objectPosition: "50% 50%",
                                    objectFit: "contain",
                                    opacity: "1",
                                    transition: "opacity 0.28s ease-in",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <OpenPopupBtn
                            openPopup={() => {
                              OpenModalCommonFunc(
                                Cases.PAYMENT_ITEM,
                                setAllPopupState
                              );
                              setId(elem.paymentId);
                            }}
                            name={btnName.PAYMENT_ITEMS}
                          />
                        </div>
                        <div className="d-flex justify-content-end ">
                          <IconButton
                            edge="start"
                            color="inherit"
                            style={{ marginLeft: "5px" }}
                            onClick={() => handleEditPayment(elem.paymentId)}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            edge="start"
                            color="inherit"
                            style={{ marginLeft: "5px" }}
                            onClick={() => handleDelete(elem.paymentId)}
                          >
                            <Trash />
                          </IconButton>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </CardBody>
        </Card>
      </Col>

      {open && (
        <Lightbox mainSrc={imageSet} onCloseRequest={() => setOpen(false)} />
      )}
      {/* payment items popup */}
      <CommonPopup
        open={allPopupState.paymentItemsPopup}
        close={() =>
          OpenModalCommonFunc(Cases.PAYMENT_ITEM, setAllPopupState, false)
        }isMini={true}
        modalName={modalsName.PAYMENT_ITEMS}
        modalCase={Cases.PAYMENT_ITEM}
        handleSubmit={handleSubmit}
        emptyData={emptyData}
        inputData={fromData}
        edit={true}
        oldTitle={titleUpdateKey.isPayment}
        label={titleUpdateKey.Payments}
        getData={getData}
        content={
          <PaymentItemPopup
            handleChangeStatus={handleChangeStatus}
            getData={getPaymentItemsList}
            handleEdit={handleEdit}
            imageUpload={imageUploadTeamMember}
            emptyData={emptyData}
            simpleValidator={simpleValidatorPaymentItem}
            webMasterDetail={webMasterDetail}
          />
        }
      />

      {/* payment popup */}
      <CommonPopup
        open={allPopupState.paymentPopup}
        close={() =>
          OpenModalCommonFunc(Cases.PAYMENT, setAllPopupState, false)
        }isMini={true}
        modalName={modalsName.PAYMENT}
        modalCase={Cases.PAYMENT}
        handleSubmit={handleSubmitPayment}
        emptyData={emptyData}
        inputData={formDataPayment}
        edit={false}
        content={
          <PaymentPopup
            imageUpload={imageUpload}
            simpleValidator={simpleValidator}
          />
        }
      />
    </>
  );
};

export default Payment;
