import React, { useContext } from "react";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import ProductBuyContext from "../../../context/ProductBuyContext/ProductBuyContext";
import { Border, fontWeight } from "../../../../constant";
import CommonColor from "../../../common/CommonColor";
import CustomStyle from "../../../common/CustomStyle";

const SettingCartPage = (props) => {
  const { handleSubmitSetting } = props;
  const { isEdit, settingFormData, setSettingFormData } =
    useContext(ProductBuyContext);

  const handleChangeSideBarTop = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".sidebar-panel .sidebar-top"]: {
          ...prev[".sidebar-panel .sidebar-top"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeSideBarProList = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".sidebar-panel .sidebar-midd .product-list"]: {
          ...prev[".sidebar-panel .sidebar-midd .product-list"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeSideBarProListImg = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".sidebar-panel .sidebar-midd .product-list .product-img"]: {
          ...prev[".sidebar-panel .sidebar-midd .product-list .product-img"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeSideBarProListName = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".sidebar-panel .sidebar-midd .product-list .name"]: {
          ...prev[".sidebar-panel .sidebar-midd .product-list .name"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeSideBottonCount = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".sidebar-panel .sidebar-bottom .count"]: {
          ...prev[".sidebar-panel .sidebar-bottom .count"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeSideBottonCHeckBtn = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".sidebar-panel .sidebar-bottom .checkout-btn"]: {
          ...prev[".sidebar-panel .sidebar-bottom .checkout-btn"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeCustomStyle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const resetHandler = () => {
    setSettingFormData({
      ".sidebar-panel .sidebar-top": {
        "background-color": { r: 31, g: 39, b: 50, a: 100 },
        padding: "15px",
      },
      ".sidebar-panel": {
        "background-color": { r: 255, g: 255, b: 255, a: 100 },
      },
      ".sidebar-panel .sidebar-midd .product-list": {
        padding: "12px",
        "border-bottom": "1px solid rgba(238, 238, 238, 1)",
        borderPx: "1px",
        borderThik: "solid",
        borderColor: { r: 238, g: 238, b: 238, a: 100 },
      },
      ".sidebar-panel .sidebar-midd .product-list .product-img": {
        // width: "90px",
        border: "1px solid rgba(238, 238, 238, 1)",
        borderPx: "1px",
        borderThik: "solid",
        borderColor: { r: 238, g: 238, b: 238, a: 100 },
        "border-radius": "15px",
        padding: "5px",
      },
      ".sidebar-panel .sidebar-midd .product-list .name": {
        color: { r: 0, g: 0, b: 0, a: 100 },
        "font-size": "16px",
        "font-weight": "600",
      },
      ".sidebar-panel .sidebar-bottom": {
        "background-color": { r: 31, g: 39, b: 50, a: 100 },
      },
      ".sidebar-panel .sidebar-bottom .count": {
        color: { r: 255, g: 255, b: 255, a: 100 },
        "font-size": "16px",
      },
      ".sidebar-panel .sidebar-bottom .checkout-btn": {
        "background-color": { r: 59, g: 183, b: 126, a: 100 },
        padding: "8px 10px",
        p1: "8px",
        p2: "10px",
        "border-radius": "5px",
        color: { r: 255, g: 255, b: 255, a: 100 },
        "font-size": "16px",
        "font-weight": "500",
      },
    });
  };

  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="12">
            <div className="mt-4 d-flex flex-column justify-content-center ">
              <div className="d-flex justify-content-between">
                <h6>{isEdit ? "Edit" : "Add New"}</h6>
                <div className="d-flex gap-2">
                  <Button onClick={resetHandler}>Reset</Button>
                  <Button onClick={() => handleSubmitSetting(true)}>
                    Restore
                  </Button>
                  <Button
                    onClick={() => handleSubmitSetting(false, false)}
                    color="success"
                  >
                    Save & View
                  </Button>
                </div>
              </div>
              <Row className="mt-4">
                <h4 className="mb-3">.sidebar-panel .sidebar-top</h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding (Default : 15)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="padding"
                    type="number"
                    placeholder="Padding"
                    value={settingFormData?.[".sidebar-panel .sidebar-top"]?.[
                      "padding"
                    ]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeSideBarTop(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[".sidebar-panel .sidebar-top"]?.[
                        "background-color"
                      ]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".sidebar-panel .sidebar-top"}
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">.sidebar-panel</h4>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[".sidebar-panel"]?.["background-color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".sidebar-panel"}
                    classProperty={"background-color"}
                    label={"Select Background color"}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">
                  .sidebar-panel .sidebar-midd .product-list
                </h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding (Default : 15)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="padding"
                    type="number"
                    placeholder="Padding"
                    value={settingFormData?.[
                      ".sidebar-panel .sidebar-midd .product-list"
                    ]?.["padding"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeSideBarProList(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border bottom (Default : 1 solid white)"}
                  </Label>
                  <div className="d-flex ">
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="borderPx"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[
                          ".sidebar-panel .sidebar-midd .product-list"
                        ]?.["borderPx"]?.replace(/px/g, "")}
                        onChange={(e) => handleChangeSideBarProList(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <select
                        name="borderThik"
                        id="borderThik"
                        className="form-control"
                        value={
                          settingFormData?.[
                            ".sidebar-panel .sidebar-midd .product-list"
                          ]?.["borderThik"]
                        }
                        onChange={(e) => handleChangeSideBarProList(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      >
                        <option value="">--select--</option>
                        {Border.map((elem) => {
                          return (
                            <option value={elem.border}>{elem.border}</option>
                          );
                        })}
                      </select>
                    </div>
                    <div>
                      <CommonColor
                        color={
                          settingFormData?.[
                            ".sidebar-panel .sidebar-midd .product-list"
                          ]?.["borderColor"]
                        }
                        setSettingFormData={setSettingFormData}
                        className={".sidebar-panel .sidebar-midd .product-list"}
                        classProperty={"borderColor"}
                        label={"Select Color"}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">
                  .sidebar-panel .sidebar-midd .product-list .product-img
                </h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border (Default : 1 solid white)"}
                  </Label>
                  <div className="d-flex ">
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="borderPx"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[
                          ".sidebar-panel .sidebar-midd .product-list .product-img"
                        ]?.["borderPx"]?.replace(/px/g, "")}
                        onChange={(e) => handleChangeSideBarProListImg(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <select
                        name="borderThik"
                        id="borderThik"
                        className="form-control"
                        value={
                          settingFormData?.[
                            ".sidebar-panel .sidebar-midd .product-list .product-img"
                          ]?.["borderThik"]
                        }
                        onChange={(e) => handleChangeSideBarProListImg(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      >
                        <option value="">--select--</option>
                        {Border.map((elem) => {
                          return (
                            <option value={elem.border}>{elem.border}</option>
                          );
                        })}
                      </select>
                    </div>
                    <div>
                      <CommonColor
                        color={
                          settingFormData?.[
                            ".sidebar-panel .sidebar-midd .product-list .product-img"
                          ]?.["borderColor"]
                        }
                        setSettingFormData={setSettingFormData}
                        className={
                          ".sidebar-panel .sidebar-midd .product-list .product-img"
                        }
                        classProperty={"borderColor"}
                        label={"Select Color"}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border Radius (Default : 15)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="border-radius"
                    type="number"
                    placeholder="border-radius"
                    value={settingFormData?.[
                      ".sidebar-panel .sidebar-midd .product-list .product-img"
                    ]?.["border-radius"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeSideBarProListImg(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"padding (Default : 5)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="padding"
                    type="number"
                    placeholder="padding"
                    value={settingFormData?.[
                      ".sidebar-panel .sidebar-midd .product-list .product-img"
                    ]?.["padding"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeSideBarProListImg(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">
                  .sidebar-panel .sidebar-midd .product-list .name
                </h4>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".sidebar-panel .sidebar-midd .product-list .name"
                      ]?.["color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={
                      ".sidebar-panel .sidebar-midd .product-list .name"
                    }
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Size (Default : 16)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="font-size"
                    type="number"
                    placeholder="font-size"
                    value={settingFormData?.[
                      ".sidebar-panel .sidebar-midd .product-list .name"
                    ]?.["font-size"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeSideBarProListName(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Weight (Default : 600)"}
                  </Label>
                  <select
                    name="font-weight"
                    id="font-weight"
                    className="form-select"
                    value={
                      settingFormData?.[
                        ".sidebar-panel .sidebar-midd .product-list .name"
                      ]?.["font-weight"]
                    }
                    onChange={(e) => handleChangeSideBarProListName(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {fontWeight.map((elem) => {
                      return (
                        <option value={elem["font-weight"]}>
                          {elem["font-weight"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">.sidebar-panel .sidebar-bottom</h4>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[".sidebar-panel .sidebar-bottom"]?.[
                        "background-color"
                      ]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".sidebar-panel .sidebar-bottom"}
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">.sidebar-panel .sidebar-bottom .count</h4>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".sidebar-panel .sidebar-bottom .count"
                      ]?.["color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".sidebar-panel .sidebar-bottom .count"}
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Size (Default : 16)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="font-size"
                    type="number"
                    placeholder="font-size"
                    value={settingFormData?.[
                      ".sidebar-panel .sidebar-bottom .count"
                    ]?.["font-size"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeSideBottonCount(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">
                  .sidebar-panel .sidebar-bottom .checkout-btn
                </h4>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".sidebar-panel .sidebar-bottom .checkout-btn"
                      ]?.["background-color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".sidebar-panel .sidebar-bottom .checkout-btn"}
                    classProperty={"background-color"}
                    label={"Select Background color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".sidebar-panel .sidebar-bottom .checkout-btn"
                      ]?.["color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".sidebar-panel .sidebar-bottom .checkout-btn"}
                    classProperty={"color"}
                    label={"Select color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border Radius (Default : 5)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="border-radius"
                    type="number"
                    placeholder="border-radius"
                    value={settingFormData?.[
                      ".sidebar-panel .sidebar-bottom .checkout-btn"
                    ]?.["border-radius"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeSideBottonCHeckBtn(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding (Default : 8 - 10)"}
                  </Label>
                  <div className="d-flex justify-content-between">
                    <Input
                      className="form-control"
                      name="p1"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        ".sidebar-panel .sidebar-bottom .checkout-btn"
                      ]?.["p1"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangeSideBottonCHeckBtn(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                    <Input
                      className="form-control"
                      name="p2"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        ".sidebar-panel .sidebar-bottom .checkout-btn"
                      ]?.["p2"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangeSideBottonCHeckBtn(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                  </div>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Size (Default : 14)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="font-size"
                    type="number"
                    placeholder="font-size"
                    value={settingFormData?.[
                      ".sidebar-panel .sidebar-bottom .checkout-btn"
                    ]?.["font-size"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeSideBottonCHeckBtn(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Weight (Default : 500)"}
                  </Label>
                  <select
                    name="font-weight"
                    id="font-weight"
                    className="form-select"
                    value={
                      settingFormData?.[
                        ".sidebar-panel .sidebar-bottom .checkout-btn"
                      ]?.["font-weight"]
                    }
                    onChange={(e) => handleChangeSideBottonCHeckBtn(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {fontWeight.map((elem) => {
                      return (
                        <option value={elem["font-weight"]}>
                          {elem["font-weight"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
              </Row>
              <hr />
              <CustomStyle
                handleChange={handleChangeCustomStyle}
                value={settingFormData?.custom}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SettingCartPage;
