import React, { useContext } from "react";
import { Col, Container, Input, Label, Row } from "reactstrap";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import {
  Cases,
  CustomFormTheme,
  CustomFormType,
  DELETE_CONFIRM,
} from "../../../constant";
import { slugUrlConverter } from "../../../../utils/helper";
import { checkSlugUrl } from "../apis/OurTeamApi/OurTeamApi";
import { Zoom, toast } from "react-toastify";
import CustomFormContext from "../../../context/CustomFormContext/CustomFormContext";
import Dividers from "../../../common/divider";
import { UploadCloud } from "react-feather";
import { IconButton } from "@material-ui/core";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import { delete_Multi_Image_Permanent } from "../apis/SliderApi/SliderApi";
import { Close } from "@material-ui/icons";

const CustomFormParentPopup = (props) => {
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const { setLoaderShow } = useContext(LoaderContext);
  const { setFormData, fromData, isEdit } = useContext(CustomFormContext);
  const { imageUpload } = props;

  /**
   * input value change
   * @param {event} e
   */
  const changeFormValue = (e) => {
    const { value, name, checked } = e.target;
    setFormData((prev) => {
      let updatedData =
        name === "isTitleShow"
          ? {
              ...prev,
              [name]: checked,
            }
          : {
              ...prev,
              [name]: value,
            };
      if (name === "title" || name === "slugUrl") {
        updatedData.slugUrl = slugUrlConverter({ value: value });
      }
      return updatedData;
    });
  };

  /**
   * reupload image
   */
  const reupload = () => {
    setFormData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };

  const deleteImagePermanent = async () => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData = {
          fileName: fromData.image
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
            .pop(),
        };
        const { data } = await delete_Multi_Image_Permanent(formData);
        if (data.status === 200) {
          TostMessage({ message: data, type: "success", position: "center" });
          setFormData((prev) => {
            return {
              ...prev,
              image: "",
              image: "",
            };
          });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="12">
            <div className="mt-4 d-flex flex-column justify-content-center ">
              <h6>{isEdit ? "Edit" : "Add New"}</h6>
              <Row className="mt-4">
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Title *"}</Label>
                  <Input
                    className="form-control"
                    name="title"
                    type="text"
                    placeholder="Title *"
                    value={fromData?.title}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    required
                  />
                  {/* <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "Title",
                      fromData?.title,
                      "required"
                    )}
                  </p> */}
                </Col>

                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Sub Title *"}</Label>
                  <Input
                    className="form-control"
                    name="subTitle"
                    type="text"
                    placeholder="Sub Title *"
                    value={fromData?.subTitle}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    required
                  />
                  {/* <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "Sub Title",
                      fromData?.subTitle,
                      "required"
                    )}
                  </p> */}
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Theme"}</Label>
                  <select
                    name="theme"
                    id="theme"
                    className="form-select"
                    onChange={(e) => changeFormValue(e)}
                    value={fromData?.theme}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    {CustomFormTheme.map((ele) => {
                      return (
                        <option value={ele.theme} key={ele.theme}>
                          {ele.themeName}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Type"}</Label>
                  <select
                    name="type"
                    id="type"
                    className="form-select"
                    onChange={(e) => changeFormValue(e)}
                    value={fromData?.type}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    {CustomFormType.map((ele) => {
                      return (
                        <option value={ele.value} key={ele.value}>
                          {ele.themeName}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Dividers divide="IMAGE" />
                <Row>
                  <Col md="6 mb-3">
                    <div
                      className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                      style={{ cursor: "pointer" }}
                    >
                      {!fromData.image && (
                        <span
                          onClick={() => {
                            OpenModalCommonFunc(
                              Cases.PRODUCT_UPLOAD,
                              setAllPopupState
                            );
                          }}
                        >
                          <UploadCloud />
                        </span>
                      )}

                      {fromData.image && (
                        <img
                          src={fromData.image}
                          alt=""
                          style={{
                            width: "80%",
                            height: "98%",
                            objectFit: "contain",
                            position: "absolute",
                          }}
                          onClick={() => {
                            deleteImagePermanent();
                          }}
                        />
                      )}
                      {fromData.image && (
                        <>
                          {" "}
                          <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="close"
                            style={{
                              color: "red",
                              position: "absolute",
                              top: "-22px",
                              left: "1px",
                            }}
                            onClick={() => deleteImagePermanent()}
                          >
                            <Close
                              style={{ fontSize: "25px", fontWeight: "bold" }}
                            />
                          </IconButton>
                        </>
                      )}
                    </div>
                    {/* <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "FEATURE IMAGE",
                        fromData.image,
                        "required"
                      )}
                    </p> */}
                  </Col>
                </Row>
              </Row>
            </div>
          </Col>
        </Row>
        <ProductImageUploadPopup
          allPopupState={allPopupState}
          setAllPopupState={setAllPopupState}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
          image={fromData.image}
          setImage={setFormData}
          imageUpload={imageUpload}
          isEdit={isEdit}
          reupload={reupload}
          // aspect={1 / 1}
        />
      </Container>
    </>
  );
};

export default CustomFormParentPopup;
