import React, { useContext } from "react";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { IconButton } from "@material-ui/core";
import { Cases, Currency, DELETE_CONFIRM, SinglePage } from "../../../constant";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import { Close } from "@material-ui/icons";
import { Col, Container, Input, Label, Row } from "reactstrap";
import Dividers from "../../../common/divider";
import { UploadCloud } from "react-feather";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import StarIcon from "@mui/icons-material/Star";
import ItemsDragAndDrops from "../../../common/ItemsDragAndDrops";
import {
  deleteProduct,
  sort_order_product,
} from "../../../apis/ProductApi/ProductApi";
import Select from "react-select";
import { delete_Image_Permanent } from "../../../apis/SliderApi/SliderApi";
import ProductContext from "../../../context/ProductContext/ProductContext";
import { splitPathWeb } from "../../../apiConstants";
import { maxLengthCheck } from "../../../../utils/helper";
import AddNewButton from "../../../common/AddNewButton";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";

const ProductsPopup = (props) => {
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const {
    handleChangeStatus,
    getData,
    handleEdit,
    imageUpload,
    emptyData,
    simpleValidator,
    webMasterDetail,
  } = props;
  const {
    apiData,
    setApiData,
    fromData,
    setFormData,
    isEdit,
    setFilter,
    filter,
    images,
    setImages,
    setIndex,
  } = useContext(ProductContext);
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * drag and drop
   * @param {drag value} result
   * @returns
   */
  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;
    const items = Array.from(apiData.data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setApiData({ ...apiData, data: items });
    try {
      let itemOrder = [];
      for (const item in items) {
        itemOrder.push({ productId: items[item].Id, order: +item + 1 });
      }
      const { data } = await sort_order_product(itemOrder);
      if (data.status === 200) {
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * input value change
   * @param {Event} e
   */
  const changeFormValue = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const changeCurrency = (name, e) => {
    setFormData((prev) => {
      return {
        ...prev,
        [e.name]: name,
      };
    });
  };

  /**
   * delete product
   * @param {Id} productId
   */
  const handleDelete = async (productId) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await deleteProduct(productId);
        if (data.status === 200) {
          getData();
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * new product create
   */
  const hanldenewCreate = () => {
    emptyData();
  };

  /**
   * image reupload
   */
  const reupload = () => {
    setFormData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };

  /**
   * permanently delete image
   */
  const deleteImagePermanent = async (image, index) => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData = {
          fileName: image
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
            .pop(),
        };
        const { data } = await delete_Image_Permanent(formData);
        if (data.status === 200) {
          TostMessage({ message: data, type: "success", position: "center" });
          setImages((prev) => {
            let newData = [...prev];
            newData[index] = { image: "", isDefault: prev[index].isDefault };
            return newData;
          });
          setFormData({ ...fromData, image: "" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * change default value
   * @param {index} index
   */
  const changeIsDefault = (index) => {
    setImages((prev) => {
      let value = prev.map((elem, i) => {
        if (i === index) {
          return {
            path: elem.path,
            isDefault: true,
          };
        } else {
          return {
            path: elem.path,
            isDefault: false,
          };
        }
      });
      return value;
    });
  };
  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="6">
            <div>
              <h6>{apiData?.data?.length} Product</h6>
              <p className="pt-2">You can add upto 25 Products</p>
              <Row>
                <Col md="6">
                  <Input
                    className="form-control mt-3 "
                    name="search"
                    type="text"
                    placeholder="search"
                    style={{ borderRadius: "0", color: "black" }}
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                </Col>
                {webMasterDetail?.webCategory?.name ===
                  SinglePage.WebCategory &&
                  apiData?.data?.length !== webMasterDetail?.itemLimit && (
                    <Col
                      md="6"
                      className="d-flex justify-content-center align-items-center mt-3"
                    >
                      <AddNewButton click={() => hanldenewCreate()} />
                    </Col>
                  )}
              </Row>
              {apiData.isLoading ? (
                <div className="mt-4">
                  <h6>Loading ...</h6>
                </div>
              ) : (
                <div className="mt-4">
                  <ItemsDragAndDrops
                    handleOnDragEnd={handleOnDragEnd}
                    characters={apiData?.data}
                    handleChangeStatus={handleChangeStatus}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                  />
                </div>
              )}
            </div>
          </Col>
          {(webMasterDetail?.webCategory?.name === SinglePage.WebCategory &&
            apiData?.data?.length === webMasterDetail?.itemLimit &&
            isEdit) ||
          (webMasterDetail?.webCategory?.name === SinglePage.WebCategory &&
            apiData?.data?.length !== webMasterDetail?.itemLimit) ? (
            <Col sm="12" xl="6">
              <div className="mt-4">
                <h6>{isEdit ? "Edit" : "Add New"}</h6>
                <Row className="mt-4">
                  <Col md="12 mb-1">
                    <Label htmlFor="validationCustom03">{"Title *"}</Label>
                    <Input
                      className="form-control"
                      name="title"
                      type="text"
                      placeholder="Title *"
                      style={{ borderRadius: "0", color: "black" }}
                      min={0}
                      maxLength={50}
                      onInput={maxLengthCheck}
                      value={fromData.title}
                      onChange={(e) => changeFormValue(e)}
                    />
                    <div className="text-end mt-3 ">
                      <span>{fromData?.title?.length} / 50</span>
                    </div>
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "title",
                        fromData.title,
                        "required"
                      )}
                    </p>
                  </Col>
                  <Col md="12 mb-3">
                    <Label htmlFor="validationCustom03">{"Price"}</Label>
                    <Input
                      className="form-control"
                      name="price"
                      type="number"
                      placeholder="Price"
                      style={{ borderRadius: "0", color: "black" }}
                      value={fromData.price}
                      onChange={(e) => changeFormValue(e)}
                    />
                  </Col>
                  <Col md="12 mb-3">
                    <Label htmlFor="validationCustom03">{"Currency"}</Label>
                    <Select
                      options={Currency}
                      name="currencySymbol"
                      value={fromData.currencySymbol}
                      onChange={(prev, e) => changeCurrency(prev, e)}
                      isClearable
                    />
                    <p style={{ color: "red" }}>
                      {fromData.price &&
                      (fromData.currencySymbol == "" ||
                        fromData.currencySymbol == null)
                        ? "The currency field is required."
                        : ""}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md="12 mb-1">
                    <Label htmlFor="validationCustom03">{"Description"}</Label>
                    <textarea
                      className="form-control"
                      name="description"
                      type="text"
                      rows={5}
                      min={0}
                      maxLength={1000}
                      onInput={maxLengthCheck}
                      placeholder="Description"
                      style={{ borderRadius: "0", color: "black" }}
                      value={fromData.description}
                      onChange={(e) => changeFormValue(e)}
                    />
                    <div className="text-end mt-3 ">
                      <span>{fromData?.description?.length}</span>
                    </div>
                  </Col>
                </Row>
                <Dividers divide="IMAGES" />
                <p className="mb-4">
                  File type required JPG, PNG (1080px X 1080px)
                </p>
                <Row>
                  <Col md="6 mb-3">
                    <div
                      className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                      style={{ cursor: "pointer" }}
                    >
                      {!images[0]?.path && (
                        <span
                          onClick={() => {
                            OpenModalCommonFunc(
                              Cases.PRODUCT_UPLOAD,
                              setAllPopupState
                            );
                            setIndex(0);
                          }}
                        >
                          <UploadCloud />
                        </span>
                      )}

                      {images?.length && images[0]?.path && (
                        <img
                          src={images?.length ? images[0]?.path : ""}
                          alt=""
                          style={{
                            width: "80%",
                            height: "98%",
                            objectFit: "contain",
                            position: "absolute",
                          }}
                          onClick={() => {
                            deleteImagePermanent(images[0]?.path, 0);
                          }}
                        />
                      )}
                      {images?.length && images[0]?.path && (
                        <>
                          {" "}
                          <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="close"
                            style={{
                              color: "red",
                              position: "absolute",
                              top: "-22px",
                              left: "1px",
                            }}
                            onClick={() =>
                              deleteImagePermanent(images[0]?.path, 0)
                            }
                          >
                            <Close
                              style={{ fontSize: "25px", fontWeight: "bold" }}
                            />
                          </IconButton>
                          <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="close"
                            style={{
                              color: images[0]?.isDefault
                                ? "#ff9800"
                                : "#c1c1c1",
                              position: "absolute",
                              top: "-22px",
                              right: "-10px",
                            }}
                            onClick={() => changeIsDefault(0)}
                          >
                            <StarIcon
                              style={{ fontSize: "25px", fontWeight: "bold" }}
                            />
                          </IconButton>
                        </>
                      )}
                    </div>
                  </Col>
                  <Col md="6 mb-3">
                    <div
                      className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center "
                      style={{ cursor: "pointer" }}
                    >
                      {!images[1]?.path && (
                        <span
                          onClick={() => {
                            OpenModalCommonFunc(
                              Cases.PRODUCT_UPLOAD,
                              setAllPopupState
                            );
                            setIndex(1);
                          }}
                        >
                          <UploadCloud />
                        </span>
                      )}{" "}
                      {images?.length && images[1]?.path && (
                        <img
                          src={images?.length ? images[1]?.path : ""}
                          alt=""
                          style={{
                            width: "80%",
                            height: "98%",
                            objectFit: "contain",
                            position: "absolute",
                          }}
                          onClick={() => {
                            deleteImagePermanent(images[1]?.path, 1);
                          }}
                        />
                      )}
                      {images?.length && images[1]?.path && (
                        <>
                          {" "}
                          <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="close"
                            style={{
                              color: "red",
                              position: "absolute",
                              top: "-22px",
                              left: "1px",
                            }}
                            onClick={() =>
                              deleteImagePermanent(images[1]?.path, 1)
                            }
                          >
                            <Close
                              style={{ fontSize: "25px", fontWeight: "bold" }}
                            />
                          </IconButton>
                          <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="close"
                            style={{
                              color: images[1]?.isDefault
                                ? "#ff9800"
                                : "#c1c1c1",
                              position: "absolute",
                              top: "-22px",
                              right: "-10px",
                            }}
                            onClick={() => changeIsDefault(1)}
                          >
                            <StarIcon
                              style={{ fontSize: "25px", fontWeight: "bold" }}
                            />
                          </IconButton>
                        </>
                      )}
                    </div>
                  </Col>
                  <Col md="6 mb-3">
                    <div
                      className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center "
                      style={{ cursor: "pointer" }}
                    >
                      {!images[2]?.path && (
                        <span
                          onClick={() => {
                            OpenModalCommonFunc(
                              Cases.PRODUCT_UPLOAD,
                              setAllPopupState
                            );
                            setIndex(2);
                          }}
                        >
                          <UploadCloud />
                        </span>
                      )}{" "}
                      {images?.length && images[2]?.path && (
                        <img
                          src={images?.length ? images[2]?.path : ""}
                          alt=""
                          style={{
                            width: "80%",
                            height: "98%",
                            objectFit: "contain",
                            position: "absolute",
                          }}
                          onClick={() => {
                            deleteImagePermanent(images[2]?.path, 2);
                          }}
                        />
                      )}
                      {images?.length && images[2]?.path && (
                        <>
                          <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="close"
                            style={{
                              color: "red",
                              position: "absolute",
                              top: "-22px",
                              left: "1px",
                            }}
                            onClick={() =>
                              deleteImagePermanent(images[2]?.path, 2)
                            }
                          >
                            <Close
                              style={{ fontSize: "25px", fontWeight: "bold" }}
                            />
                          </IconButton>
                          <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="close"
                            style={{
                              color: images[2]?.isDefault
                                ? "#ff9800"
                                : "#c1c1c1",
                              position: "absolute",
                              top: "-22px",
                              right: "-10px",
                            }}
                            onClick={() => changeIsDefault(2)}
                          >
                            <StarIcon
                              style={{ fontSize: "25px", fontWeight: "bold" }}
                            />
                          </IconButton>
                        </>
                      )}
                    </div>
                  </Col>
                  <Col md="6 mb-3">
                    <div
                      className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center "
                      style={{ cursor: "pointer" }}
                    >
                      {!images[3]?.path && (
                        <span
                          onClick={() => {
                            OpenModalCommonFunc(
                              Cases.PRODUCT_UPLOAD,
                              setAllPopupState
                            );
                            setIndex(3);
                          }}
                        >
                          <UploadCloud />
                        </span>
                      )}{" "}
                      {images?.length && images[3]?.path && (
                        <img
                          src={images?.length ? images[3]?.path : ""}
                          alt=""
                          style={{
                            width: "80%",
                            height: "98%",
                            objectFit: "contain",
                            position: "absolute",
                          }}
                          onClick={() => {
                            deleteImagePermanent(images[3]?.path, 3);
                          }}
                        />
                      )}
                      {images?.length && images[3]?.path && (
                        <>
                          <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="close"
                            style={{
                              color: "red",
                              position: "absolute",
                              top: "-22px",
                              left: "1px",
                            }}
                            onClick={() =>
                              deleteImagePermanent(images[3]?.path, 3)
                            }
                          >
                            <Close
                              style={{ fontSize: "25px", fontWeight: "bold" }}
                            />
                          </IconButton>
                          <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="close"
                            style={{
                              color: images[3]?.isDefault
                                ? "#ff9800"
                                : "#c1c1c1",
                              position: "absolute",
                              top: "-22px",
                              right: "-10px",
                            }}
                            onClick={() => changeIsDefault(3)}
                          >
                            <StarIcon
                              style={{ fontSize: "25px", fontWeight: "bold" }}
                            />
                          </IconButton>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          ) : (
            ""
          )}
        </Row>
        <ProductImageUploadPopup
          allPopupState={allPopupState}
          setAllPopupState={setAllPopupState}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
          image={fromData?.image}
          setImage={setFormData}
          imageUpload={imageUpload}
          isEdit={isEdit}
          reupload={reupload}
          // aspect={1 / 1}
        />
      </Container>
    </>
  );
};

export default ProductsPopup;
