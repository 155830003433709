import axios from "axios";
import {
  GET_FAQS,
  GET_FAQS_DETAILS,
  POST_FAQS,
  PUT_FAQS_ORDER,
  PUT_STATUS_FAQS,
} from "..";

/**
 * get faqs
 * @param {Parameters} paramObj
 * @returns
 */
export const getFaqs = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_FAQS}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * enable-disable faqs
 * @param {body} body
 * @returns
 */
export const updateStatus = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(`${process.env.REACT_APP_API_URL}${PUT_STATUS_FAQS}`, body, {
    headers: { Authorization: jwt_token },
  });
};

/**
 * create faqs
 * @param {body} body
 * @returns
 */
export const postFaqs = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(`${process.env.REACT_APP_API_URL}${POST_FAQS}`, body, {
    headers: { Authorization: jwt_token },
  });
};

/**
 * update faqs
 * @param {body} body
 * @returns
 */
export const putUpdateFaqs = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(`${process.env.REACT_APP_API_URL}${POST_FAQS}`, body, {
    headers: { Authorization: jwt_token },
    params: { faqsId: body.faqsId },
  });
};

/**
 * delete faqs
 * @param {id} faqsId
 * @returns
 */
export const deleteFaqs = (faqsId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${POST_FAQS}`, {
    headers: { Authorization: jwt_token },
    params: { faqsId },
  });
};

/**
 * get faqs
 * @param {id} faqsId
 * @returns
 */
export const getFaqsDetails = (faqsId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_FAQS_DETAILS}`, {
    headers: { Authorization: jwt_token },
    params: { faqsId },
  });
};

/**
 * short order faqs
 * @param {body} updateOrder
 * @returns
 */
export const sort_faqs = (updateOrder) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_FAQS_ORDER}`,
    { updateOrder: updateOrder },
    {
      headers: { Authorization: jwt_token },
    }
  );
};
