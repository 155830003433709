import React, { useContext } from "react";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import AboutUsContext from "../../../context/AboutUsContext/AboutUsContext";
import { Border, Display, TextAlign, fontWeight } from "../../../../constant";
import Switch from "@material-ui/core/Switch";
import { Cases, DELETE_CONFIRM } from "../../../constant";
import {
  delete_Image_Permanent,
  delete_Multi_Image_Permanent,
} from "../apis/SliderApi/SliderApi";
import { Zoom, toast } from "react-toastify";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import Dividers from "../../../common/divider";
import { OpenModalCommonFunc } from "../../../utils";
import { IconButton } from "@material-ui/core";
import { UploadCloud } from "react-feather";
import { Close } from "@material-ui/icons";
import { SketchPicker } from "react-color";
import CommonColor from "../../../common/CommonColor";
import CustomStyle from "../../../common/CustomStyle";

const AboutUsThemeOne = (props) => {
  const {
    isEdit,
    settingFormData,
    setSettingFormData,
    boxSection,
    setBoxSection,
    backImageToggle,
    setBackImageToggle,
    isBackgroundColor,
    setIsBackgroundColor,
  } = useContext(AboutUsContext);
  const { setLoaderShow } = useContext(LoaderContext);
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const { imageUpload, handleSubmitSetting } = props;

  const handleChangeAboutSection = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".about-section"]: {
          ...prev[".about-section"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeAboutSectionBgImageBefore = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".about-section.bg-image::before"]: {
          ...prev[".about-section.bg-image::before"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeAboutSectionTitleArea = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".about-section .section-title-area"]: {
          ...prev[".about-section .section-title-area"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeAboutSectionSubTitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".about-section .section-title-area .section-subtitle"]: {
          ...prev[".about-section .section-title-area .section-subtitle"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeAboutSectionTitle = (e) => {
    const { name, value } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".about-section .section-title-area .section-title"]: {
          ...prev[".about-section .section-title-area .section-title"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeSectionTitleArea = (e) => {
    const { name, value } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".section-title-area"]: {
          ...prev[".section-title-area"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeAboutImg = (e) => {
    const { name, value } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".about-section .about-img"]: {
          ...prev[".about-section .about-img"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeAboutDetails = (e) => {
    const { name, value } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".about-section .about-details"]: {
          ...prev[".about-section .about-details"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeSectionDetailsH6 = (e) => {
    const { name, value } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".about-section .about-details h6"]: {
          ...prev[".about-section .about-details h6"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeSectionDetailsH5 = (e) => {
    const { name, value } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".about-section .about-details h5"]: {
          ...prev[".about-section .about-details h5"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeSectionDetailsP = (e) => {
    const { name, value } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".about-section .about-details p"]: {
          ...prev[".about-section .about-details p"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeSectionDetailsUl = (e) => {
    const { name, value } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".about-section .about-details ul"]: {
          ...prev[".about-section .about-details ul"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeSectionDetailsUlLi = (e) => {
    const { name, value } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".about-section .about-details ul li"]: {
          ...prev[".about-section .about-details ul li"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeSectionDetailsUlLiBefore = (e) => {
    const { name, value } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".about-section .about-details ul li:before"]: {
          ...prev[".about-section .about-details ul li:before"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeSectionDetailsUlLiFirstChild = (e) => {
    const { name, value } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".about-section .about-details ul li:first-child"]: {
          ...prev[".about-section .about-details ul li:first-child"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeSectionDetailsBtnSection = (e) => {
    const { name, value } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".about-section .about-details .btn-section"]: {
          ...prev[".about-section .about-details .btn-section"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeAboutSectionDetailsBtn = (e) => {
    const { name, value } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".about-section .about-details .btn"]: {
          ...prev[".about-section .about-details .btn"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeCustomStyle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  /**
   * delete image permanently
   */
  const deleteImagePermanent = async () => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData = {
          fileName: settingFormData?.[".about-section.bg-image"]?.[
            "background-image"
          ]
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
            .pop(),
        };
        const { data } = await delete_Multi_Image_Permanent(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            position: "top-center",
          });
          setSettingFormData({
            ...settingFormData,
            ".about-section.bg-image": { "background-image": "" },
          });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };
  /**
   * image reupload
   */
  const reupload = () => {
    setSettingFormData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };

  return (
    <>
      <Row className="mt-4">
        <h4 className="mb-3">Make change in Main section</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (default : 100)"}
          </Label>
          <Input
            className="form-control"
            name="padding"
            type="number"
            value={settingFormData?.[".about-section"]?.["padding"]
              ?.split(" ")[0]
              ?.replace(/px/g, "")}
            onChange={(e) => handleChangeAboutSection(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <div className="d-flex align-items-center">
            <Label htmlFor="validationCustom03">
              {isBackgroundColor
                ? "Add Background Color"
                : "Remove Background Color"}
            </Label>
            <Switch
              checked={isBackgroundColor}
              inputProps={{ "aria-label": "controlled" }}
              onChange={(e) => {
                setIsBackgroundColor(e.target.checked);
                setSettingFormData((prev) => {
                  if (e.target.checked) {
                    if (boxSection) {
                      return {
                        ...prev,
                        [".about-section"]: {
                          "background-color": "#fdfdfe",
                          padding:
                            settingFormData?.[".about-section"]?.["padding"],
                          width: settingFormData?.[".about-section"]?.[
                            "width"
                          ]?.replace("rem)", ""),
                          margin: settingFormData?.[".about-section"]?.[
                            "margin"
                          ]
                            ?.split(" ")[0]
                            ?.replace(/px/g, ""),
                          "border-radius": settingFormData?.[
                            ".about-section"
                          ]?.["border-radius"]?.replace(/rem/g, ""),
                        },
                      };
                    } else {
                      return {
                        ...prev,
                        [".about-section"]: {
                          "background-color": "#fdfdfe",
                          padding:
                            settingFormData?.[".about-section"]?.["padding"],
                        },
                      };
                    }
                  } else {
                    if (boxSection) {
                      return {
                        ...prev,
                        [".about-section"]: {
                          padding:
                            settingFormData?.[".about-section"]?.["padding"],
                          width: settingFormData?.[".about-section"]?.[
                            "width"
                          ]?.replace("rem)", ""),
                          margin: settingFormData?.[".about-section"]?.[
                            "margin"
                          ]
                            ?.split(" ")[0]
                            ?.replace(/px/g, ""),
                          "border-radius": settingFormData?.[
                            ".about-section"
                          ]?.["border-radius"]?.replace(/rem/g, ""),
                        },
                      };
                    } else {
                      return {
                        ...prev,
                        [".about-section"]: {
                          padding:
                            settingFormData?.[".about-section"]?.["padding"],
                        },
                      };
                    }
                  }
                });
              }}
            />
          </div>
          {isBackgroundColor && (
            <CommonColor
              color={settingFormData?.[".about-section"]?.["background-color"]}
              setSettingFormData={setSettingFormData}
              className={".about-section"}
              classProperty={"background-color"}
              label={"Select Background Color"}
            />
          )}
        </Col>
        <p>{"Box Section : convert your section to Box section"}</p>
        <div>
          <Switch
            checked={boxSection}
            inputProps={{ "aria-label": "controlled" }}
            onChange={(e) => {
              setBoxSection(e.target.checked);
              setSettingFormData((prev) => {
                return {
                  ...prev,
                  ".about-section": {
                    padding: settingFormData?.[".about-section"]?.["padding"]
                      ?.split(" ")[0]
                      ?.replace(/px/g, ""),
                    "background-color":
                      settingFormData?.[".about-section"]?.["background-color"],
                    width: e.target.checked ? "10" : "",
                    margin: e.target.checked ? "0" : "",
                    "border-radius": e.target.checked ? "3" : "",
                  },
                };
              });
            }}
          />
        </div>
        {boxSection && (
          <>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Width (Default : 10)"}
              </Label>
              <Input
                className="form-control"
                name="width"
                type="number"
                placeholder="width"
                value={settingFormData?.[".about-section"]?.["width"]?.replace(
                  "rem)",
                  ""
                )}
                onChange={(e) => handleChangeAboutSection(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Margin (Default : 0)"}
              </Label>
              <Input
                className="form-control"
                name="margin"
                type="number"
                placeholder="margin"
                value={settingFormData?.[".about-section"]?.["margin"]
                  ?.split(" ")[0]
                  ?.replace(/px/g, "")}
                onChange={(e) => handleChangeAboutSection(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Border Radius (Default : 3)"}
              </Label>
              <Input
                className="form-control"
                name="border-radius"
                type="number"
                placeholder="Border Radius"
                value={settingFormData?.[".about-section"]?.[
                  "border-radius"
                ]?.replace(/rem/g, "")}
                onChange={(e) => handleChangeAboutSection(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
          </>
        )}
      </Row>
      <hr />
      <Row className="mt-4">
        <Col md="12 mb-3">
          <h4 className="mb-3">Add Background Image in section</h4>

          <p>do you want to upload Background image</p>
          <Switch
            checked={backImageToggle}
            inputProps={{ "aria-label": "controlled" }}
            onChange={(e) => {
              setBackImageToggle(e.target.checked);
              setSettingFormData((prev) => {
                if (e.target.checked) {
                  return {
                    ...prev,
                    image: "",
                    ".about-section.bg-image": {},
                    ".about-section.bg-image::before": {
                      "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
                    },
                  };
                } else {
                  return {
                    ...prev,
                    image: "",
                    ".about-section.bg-image": {},
                    ".about-section.bg-image::before": {},
                  };
                }
              });
            }}
          />
          {backImageToggle && (
            <>
              {" "}
              <Col md="6 mb-3">
                <Dividers divide="IMAGE" />
                <p className="mb-4">
                  File type required JPG, PNG (1905px X 879px)
                </p>
              </Col>
              <Row>
                <Col md="4 mb-3">
                  <div
                    className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    {!settingFormData?.[".about-section.bg-image"]?.[
                      "background-image"
                    ] && (
                      <span
                        onClick={() =>
                          OpenModalCommonFunc(
                            Cases.PRODUCT_UPLOAD,
                            setAllPopupState
                          )
                        }
                      >
                        <UploadCloud />
                      </span>
                    )}

                    {settingFormData?.[".about-section.bg-image"]?.[
                      "background-image"
                    ] && (
                      <img
                        src={
                          settingFormData?.[".about-section.bg-image"]?.[
                            "background-image"
                          ] || ""
                        }
                        alt=""
                        style={{
                          width: "85%",
                          height: "90px",
                          objectFit: "contain",
                          position: "absolute",
                        }}
                        onClick={() => deleteImagePermanent()}
                      />
                    )}
                    {settingFormData?.[".about-section.bg-image"]?.[
                      "background-image"
                    ] && (
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="close"
                        style={{
                          color: "red",
                          position: "absolute",
                          top: "-22px",
                          left: "1px",
                        }}
                        onClick={() => deleteImagePermanent()}
                      >
                        <Close
                          style={{
                            fontSize: "25px",
                            fontWeight: "bold",
                          }}
                        />
                      </IconButton>
                    )}
                  </div>
                </Col>
                <Col md="6 mb-3">
                  <h4 className="mb-3">Add Background color in section</h4>
                  <h4 className="mb-3"></h4>
                  <Col md="6 mb-3">
                    <CommonColor
                      color={
                        settingFormData?.[".about-section.bg-image::before"]?.[
                          "background-color"
                        ]
                      }
                      setSettingFormData={setSettingFormData}
                      className={".about-section.bg-image::before"}
                      classProperty={"background-color"}
                      label={"Select Background Color"}
                    />
                  </Col>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make space in Title bottom</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin Bottom (Default : 50)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin Bottom"
            value={settingFormData?.[".about-section .section-title-area"]?.[
              "margin-bottom"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeAboutSectionTitleArea(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in section subtitle</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 16)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".about-section .section-title-area .section-subtitle"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeAboutSectionSubTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-control"
            value={
              settingFormData?.[
                ".about-section .section-title-area .section-subtitle"
              ]?.["font-weight"]
            }
            onChange={(e) => handleChangeAboutSectionSubTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]} key={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".about-section .section-title-area .section-subtitle"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".about-section .section-title-area .section-subtitle"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".about-section .section-title-area .section-subtitle"
              ]?.["background-color"]
            }
            setSettingFormData={setSettingFormData}
            className={".about-section .section-title-area .section-subtitle"}
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 5 - 20)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="p1"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".about-section .section-title-area .section-subtitle"
              ]?.["p1"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeAboutSectionSubTitle(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p2"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".about-section .section-title-area .section-subtitle"
              ]?.["p2"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeAboutSectionSubTitle(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>

        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border Radius (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[
              ".about-section .section-title-area .section-subtitle"
            ]?.["border-radius"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeAboutSectionSubTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin Bottom (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin Bottom"
            value={settingFormData?.[
              ".about-section .section-title-area .section-subtitle"
            ]?.["margin-bottom"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeAboutSectionSubTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in section Title</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 44)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".about-section .section-title-area .section-title"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeAboutSectionTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-control"
            value={
              settingFormData?.[
                ".about-section .section-title-area .section-title"
              ]?.["font-weight"]
            }
            onChange={(e) => handleChangeAboutSectionTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]} key={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".about-section .section-title-area .section-title"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".about-section .section-title-area .section-title"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in about section image</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Margin (Default : 0)"}</Label>
          <Input
            className="form-control"
            name="margin"
            type="number"
            placeholder="Margin"
            value={settingFormData?.[".about-section .about-img"]?.["margin"]
              ?.split(" ")[0]
              ?.replace(/px/g, "")}
            onChange={(e) => handleChangeAboutImg(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Display (Default : block)"}
          </Label>
          <select
            name="display"
            id="display"
            className="form-control"
            value={settingFormData?.[".about-section .about-img"]?.["display"]}
            onChange={(e) => handleChangeAboutImg(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {Display.map((elem) => {
              return (
                <option value={elem["display"]} key={elem["display"]}>
                  {elem["display"]}
                </option>
              );
            })}
          </select>
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in about section details</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Padding (Default : 20)"}</Label>
          <Input
            className="form-control"
            name="padding"
            type="number"
            placeholder="padding"
            value={settingFormData?.[".about-section .about-details"]?.[
              "padding"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeAboutDetails(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in about section details heading</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 24)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[".about-section .about-details h5"]?.[
              "font-size"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeSectionDetailsH5(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-control"
            value={
              settingFormData?.[".about-section .about-details h5"]?.[
                "font-weight"
              ]
            }
            onChange={(e) => handleChangeSectionDetailsH5(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]} key={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".about-section .about-details h5"]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".about-section .about-details h5"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Line Height (Default : 1.4)"}
          </Label>
          <Input
            className="form-control"
            name="line-height"
            type="number"
            placeholder="Line Height"
            value={
              settingFormData?.[".about-section .about-details h5"]?.[
                "line-height"
              ]
            }
            onChange={(e) => handleChangeSectionDetailsH5(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin Bottom (Default : 20)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin Bottom"
            value={settingFormData?.[".about-section .about-details h5"]?.[
              "margin-bottom"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeSectionDetailsH5(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Text Align (Default : justify)"}
          </Label>
          <select
            name="text-align"
            id="text-align"
            className="form-control"
            value={
              settingFormData?.[".about-section .about-details h5"]?.[
                "text-align"
              ]
            }
            onChange={(e) => handleChangeSectionDetailsH5(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {TextAlign.map((elem) => {
              return (
                <option value={elem["text-align"]} key={elem["text-align"]}>
                  {elem["text-align"]}
                </option>
              );
            })}
          </select>
        </Col>
      </Row>
      <hr />
      {/* <Row className="mt-2">
                <h4 className="mb-3">
                  Make change in about section details sub heading
                </h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Size (Default : 20)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="font-size"
                    type="number"
                    placeholder="Font Size"
                    value={settingFormData?.[
                      ".about-section .about-details h6"
                    ]?.["font-size"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeSectionDetailsH6(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Weight (Default : 600)"}
                  </Label>
                  <select
                    name="font-weight"
                    id="font-weight"
                    className="form-control"
                    value={
                      settingFormData?.[".about-section .about-details h6"]?.[
                        "font-weight"
                      ]
                    }
                    onChange={(e) => handleChangeSectionDetailsH6(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {fontWeight.map((elem) => {
                      return (
                        <option
                          value={elem["font-weight"]}
                          key={elem["font-weight"]}
                        >
                          {elem["font-weight"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[".about-section .about-details h6"]?.[
                        "color"
                      ]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".about-section .about-details h6"}
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Line Height (Default : 1.4)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="line-height"
                    type="number"
                    placeholder="Line Height"
                    value={
                      settingFormData?.[".about-section .about-details h6"]?.[
                        "line-height"
                      ]
                    }
                    onChange={(e) => handleChangeSectionDetailsH6(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Margin Bottom (Default : 20)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="margin-bottom"
                    type="number"
                    placeholder="Margin Bottom"
                    value={settingFormData?.[
                      ".about-section .about-details h6"
                    ]?.["margin-bottom"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeSectionDetailsH6(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Text Align (Default : justify)"}
                  </Label>
                  <select
                    name="text-align"
                    id="text-align"
                    className="form-control"
                    value={
                      settingFormData?.[".about-section .about-details h6"]?.[
                        "text-align"
                      ]
                    }
                    onChange={(e) => handleChangeSectionDetailsH6(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {TextAlign.map((elem) => {
                      return (
                        <option
                          value={elem["text-align"]}
                          key={elem["text-align"]}
                        >
                          {elem["text-align"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
              </Row>
              <hr /> */}
      <Row className="mt-2">
        <h4 className="mb-3">Make change in about section details paragraph</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 16)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[".about-section .about-details p"]?.[
              "font-size"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeSectionDetailsP(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 500)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-control"
            value={
              settingFormData?.[".about-section .about-details p"]?.[
                "font-weight"
              ]
            }
            onChange={(e) => handleChangeSectionDetailsP(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]} key={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".about-section .about-details p"]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".about-section .about-details p"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Line Height (Default : 1.6)"}
          </Label>
          <Input
            className="form-control"
            name="line-height"
            type="number"
            placeholder="Line Height"
            value={
              settingFormData?.[".about-section .about-details p"]?.[
                "line-height"
              ]
            }
            onChange={(e) => handleChangeSectionDetailsP(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin Bottom (Default : 20)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin Bottom"
            value={settingFormData?.[".about-section .about-details p"]?.[
              "margin-bottom"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeSectionDetailsP(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Text Align (Default : justify)"}
          </Label>
          <select
            name="text-align"
            id="text-align"
            className="form-control"
            value={
              settingFormData?.[".about-section .about-details p"]?.[
                "text-align"
              ]
            }
            onChange={(e) => handleChangeSectionDetailsP(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {TextAlign.map((elem) => {
              return (
                <option value={elem["text-align"]} key={elem["text-align"]}>
                  {elem["text-align"]}
                </option>
              );
            })}
          </select>
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in about section details list</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin Bottom (Default : 30)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin Bottom"
            value={settingFormData?.[".about-section .about-details ul"]?.[
              "margin-bottom"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeSectionDetailsUl(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2 mb-2">
        <h4 className="mb-3">Make change in about section details list item</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 16)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[".about-section .about-details ul li"]?.[
              "font-size"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeSectionDetailsUlLi(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 500)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-control"
            value={
              settingFormData?.[".about-section .about-details ul li"]?.[
                "font-weight"
              ]
            }
            onChange={(e) => handleChangeSectionDetailsUlLi(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]} key={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".about-section .about-details ul li"]?.[
                "color"
              ]
            }
            setSettingFormData={setSettingFormData}
            className={".about-section .about-details ul li"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Margin (Default : 14)"}</Label>
          <Input
            className="form-control"
            name="margin"
            type="number"
            placeholder="Margin"
            value={settingFormData?.[".about-section .about-details ul li"]?.[
              "margin"
            ]
              ?.split(" ")[0]
              ?.replace(/px/g, "")}
            onChange={(e) => handleChangeSectionDetailsUlLi(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2 mb-2">
        <h4 className="mb-3">
          Make change in about section details list : before
        </h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".about-section .about-details ul li:before"]?.[
                "color"
              ]
            }
            setSettingFormData={setSettingFormData}
            className={".about-section .about-details ul li:before"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin right (Default : 10)"}
          </Label>
          <Input
            className="form-control"
            name="margin-right"
            type="number"
            placeholder="margin-right"
            value={settingFormData?.[
              ".about-section .about-details ul li:before"
            ]?.["margin-right"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeSectionDetailsUlLiBefore(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2 mb-2">
        <h4 className="mb-3">
          Make change in about section details list : first-item
        </h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin top (Default : 0)"}
          </Label>
          <Input
            className="form-control"
            name="margin-top"
            type="number"
            placeholder="margin-top"
            value={settingFormData?.[
              ".about-section .about-details ul li:first-child"
            ]?.["margin-top"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeSectionDetailsUlLiFirstChild(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2 mb-2">
        <h4 className="mb-3">About button Place</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Justify Content (Default : Start)"}
          </Label>{" "}
          <select
            name="justify-content"
            id="justify-content"
            className="form-select"
            value={
              settingFormData?.[".about-section .about-details .btn-section"]?.[
                "justify-content"
              ]
            }
            onChange={(e) => handleChangeSectionDetailsBtnSection(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            <option value="start">{"Start"}</option>
            <option value="center">{"Center"}</option>
            <option value="end">{"End"}</option>
          </select>
        </Col>
      </Row>
      <hr />
      <Row className="mt-2 mb-2">
        <h4 className="mb-3">Make change in about section details button</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".about-section .about-details .btn"]?.[
                "background-color"
              ]
            }
            setSettingFormData={setSettingFormData}
            className={".about-section .about-details .btn"}
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border (Default : 2 solid pink)"}
          </Label>
          <div className="d-flex">
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="borderPx"
                type="number"
                placeholder="px"
                value={settingFormData?.[
                  ".about-section .about-details .btn"
                ]?.["borderPx"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeAboutSectionDetailsBtn(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <select
                name="borderThik"
                id="borderThik"
                className="form-control"
                value={
                  settingFormData?.[".about-section .about-details .btn"]?.[
                    "borderThik"
                  ]
                }
                onChange={(e) => handleChangeAboutSectionDetailsBtn(e)}
                style={{ borderRadius: "0", color: "black" }}
              >
                <option value="">--select--</option>
                {Border.map((elem) => {
                  return <option value={elem.border}>{elem.border}</option>;
                })}
              </select>
            </div>

            <div>
              <CommonColor
                color={
                  settingFormData?.[".about-section .about-details .btn"]?.[
                    "borderColor"
                  ]
                }
                setSettingFormData={setSettingFormData}
                className={".about-section .about-details .btn"}
                classProperty={"borderColor"}
                label={"Border Color"}
              />
            </div>
          </div>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".about-section .about-details .btn"]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".about-section .about-details .btn"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 8 - 25)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="p1"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[".about-section .about-details .btn"]?.[
                "p1"
              ]?.replace(/px/g, "")}
              onChange={(e) => handleChangeAboutSectionDetailsBtn(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p2"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[".about-section .about-details .btn"]?.[
                "p2"
              ]?.replace(/px/g, "")}
              onChange={(e) => handleChangeAboutSectionDetailsBtn(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
        <CustomStyle
          handleChange={handleChangeCustomStyle}
          value={settingFormData?.custom}
        />
      </Row>
      <ProductImageUploadPopup
        allPopupState={allPopupState}
        setAllPopupState={setAllPopupState}
        crop={crop}
        setCrop={setCrop}
        completedCrop={completedCrop}
        setCompletedCrop={setCompletedCrop}
        scale={scale}
        rotate={rotate}
        image={settingFormData?.image}
        setImage={setSettingFormData}
        imageUpload={imageUpload}
        isEdit={isEdit}
        reupload={reupload}
        // aspect={2.17 / 1}
      />
    </>
  );
};

export default AboutUsThemeOne;
