import React, { useContext, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import { Cases, DELETE_CONFIRM, modalsName } from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import CommonPopup from "../../../Modals/commonPopup";
import OurClientPopup from "./OurClientPopup";
import EditIcon from "@mui/icons-material/Edit";
import OurClientParentPopup from "./OurClientParentPopup";
import { FILE_UPLOAD_API } from "../apis/SliderApi/SliderApi";
import {
  convertRGBA,
  isCheckValue,
  isEmptyObject,
  randomStr,
  removeUrlKeyInPath,
  rgbColorCreate,
} from "../../../../utils/helper";
import { FILE_TYPE, ThemeNo, titleUpdateKey } from "../../../../constant";
import { IconButton } from "@material-ui/core";
import { Settings, Trash } from "react-feather";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import SimpleReactValidator from "simple-react-validator";
import OurClientContext from "../../../context/OurClientContext/OurClientContext";
import {
  deleteOurClient,
  getOurClient,
  getOurClientDetails,
  postOurClient,
  putUpdateOurClient,
  updateStatus,
} from "../apis/OurClientApi/OurClientApi";
import SettingOurClientParentPopup from "./SettingOurClientParentPopup";
import {
  OurClientOneSubFun,
  OurClientThemeOneFunction,
  OurClientThemeTwoFunction,
  OurClientTwoSubFun,
  ourClientStyleOne,
  ourClientStyleTwo,
} from "./ourClientStyleFunction";
import DeleteSection from "../../../common/DeleteSection";
import SettingMultiWebContext from "../../../../context/SettingMultiWebContext/SettingMultiWebContext";

const OurClient = (props) => {
  const {
    pageConfigDetails,
    webMasterDetail,
    getData,
    ourClientDetail,
    contentMapId,
    ByteMiniWeb_Dashboard,
    navMapId: navMapIds,
    contentDetail,
  } = props;
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );
  const { setCloneAlignModal, setAlignFormData, setCloneFormData } = useContext(
    SettingMultiWebContext
  );
  const parentValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const childValidator = useRef(new SimpleReactValidator());
  const {
    fromData,
    setFormData,
    formDataChild,
    setFormDataChild,
    id,
    setId,
    setIsEdit,
    isEdit,
    setFilter,
    apiDataOurTeam: apiDataImageParent,
    setApiDataOurTeam: setApiDataImageParent,
    setSkill,
    setGalleryImgId,
    setSettingFormData,
    settingFormData,
    isParent,
    setIsParent,
    childId,
    setChildId,
    navMapId,
    setNavMapId,
    setBackImageToggle,
    setBoxSection,
    boxSection,
    collId,
    setCollId,
    isBackgroundColor,
    setIsBackgroundColor,
    theme,
    setTheme,
    imageIs,
    setImageIs,
    setImgShow,
    sectionPageName,
    setSectionPageName,
  } = useContext(OurClientContext);
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * get our client parent list
   * @param {status} status
   */
  const getOurClientParentList = async ({
    parentId = null,
    isClose = false,
    filter,
  }) => {
    try {
      let paramObj = {
        showAll: true,
      };
      if (
        ((parentId && parentId !== null) ||
          (id && isClose === false && isParent === false)) &&
        isParent === false
      ) {
        paramObj["search"] = { parentId: parentId || id };
        setId(parentId || id);
        if (filter?.length) {
          paramObj["search"] = { ...paramObj.search, title: filter };
        }
      } else {
        paramObj["search"] = { parentId: null };
      }
      const { data } = await getOurClient(paramObj);
      if (isCheckValue({ value: parentId })) {
        setChildId(parentId);
      }
      if (data.status === 200) {
        if (
          isParent === false &&
          ((parentId && parentId !== null) || id) &&
          isClose === false
        ) {
          setApiDataImageParent({
            ...apiDataImageParent,
            isLoading: false,
            data: data.payload.data.map((elem) => {
              return {
                id: elem?.title + elem?.ourClientId,
                Id: elem?.ourClientId,
                name: elem?.title,
                image: elem?.image,
                webId: elem?.webId,
                createdAt: elem?.createdAt,
                isActive: elem?.isActive,
                parentId: elem?.parentId,
              };
            }),
          });
          OpenModalCommonFunc(Cases.OUR_CLIENT, setAllPopupState);
        } else if (parentId && parentId !== null) {
          OpenModalCommonFunc(Cases.OUR_CLIENT, setAllPopupState);
          setApiDataImageParent({
            ...apiDataImageParent,
            isLoading: false,
            data: data.payload.data.map((elem) => {
              return {
                id: elem?.title + elem?.ourClientId,
                Id: elem?.ourClientId,
                name: elem?.title,
                image: elem?.image,
                webId: elem?.webId,
                createdAt: elem?.createdAt,
                isActive: elem?.isActive,
                parentId: elem?.parentId,
              };
            }),
          });
        } else {
          setApiDataImageParent({
            ...apiDataImageParent,
            isLoading: false,
            data: data.payload.data,
          });
        }
        if (isClose && isClose !== false) {
          setIsParent(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * item our client enable disable api call
   * @param {ourClientId} ourClientId
   * @param {status} isActive
   */

  const handleChangeOurClient = async (
    ourClientId,
    isActive,
    isChild,
    parentId
  ) => {
    try {
      let body = {
        ourClientId,
        isActive: isActive ? false : true,
      };
      if (parentId) {
        body["parentId"] = parentId;
      }
      const { data } = await updateStatus(body);
      if (data.status === 200) {
        if (isChild) {
          getOurClientParentList({ parentId: parentId });
        } else {
          ByteMiniWeb_Dashboard();
        }
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  const imageUpload = async (file) => {
    setLoaderShow(true);
    let formData = new FormData();
    formData.append("type", FILE_TYPE.OUR_CLIENT);
    formData.append("file", file);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        const image = data.payload.baseUrl + data.payload.imageUrl;
        setLoaderShow(false);
        return image;
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * file upload
   * @param {file} file
   */
  const imageUploadImages = async (file) => {
    setLoaderShow(true);
    try {
      const image = await imageUpload(file);
      setSettingFormData((prev) => {
        if (imageIs === "ourClient1") {
          return {
            ...prev,
            ".clients-section.bg-image": {
              "background-image": image,
            },
          };
        } else if (imageIs === "ourClient2") {
          return {
            ...prev,
            ".partner-section.bg-image": {
              "background-image": image,
            },
          };
        }
      });
      setLoaderShow(false);
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };
  /**
   * file upload for our client
   * @param {file} file
   */
  const imageUploadOurClient = async (file) => {
    setLoaderShow(true);
    try {
      const image = await imageUpload(file);
      setFormDataChild((prev) => {
        return {
          ...prev,
          image,
        };
      });
      setLoaderShow(false);
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * create and update our client
   */
  const submitForm = async () => {
    if (
      (isParent === false && childId >= -1 && isEdit) ||
      (id >= -1 && isEdit)
    ) {
      setLoaderShow(true);
      try {
        let formData = {};
        if (isParent && isParent !== false) {
          formData = {
            ...fromData,
            ourClientId: id,
            contentMapId: collId,
            navMapId: +navMapIds,
          };
          delete formData.style;
        } else {
          formData = {
            ...formDataChild,
            parentId: id,
            navMapId,
            ourClientId: childId,
            contentMapId: collId,
          };
          delete formData.image;
          if (formDataChild.image.length) {
            formData["image"] = formDataChild.image
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
              .pop();
          }
        }
        const { data } = await putUpdateOurClient(formData);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            ByteMiniWeb_Dashboard();
          } else {
            getOurClientParentList({ parentId: id });
          }
          emptyData();
          OpenModalCommonFunc(Cases.OUR_CLIENT_PARENT, setAllPopupState, false);
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    } else {
      try {
        setLoaderShow(true);
        let formData = {};
        if (isParent && isParent !== false) {
          formData = { ...fromData, contentMapId: collId };
        } else {
          formData = {
            ...formDataChild,
            parentId: id,
            navMapId,
            order: apiDataImageParent.data.length + 1,
            contentMapId: collId,
          };
          delete formData.image;
          if (formDataChild.image.length) {
            formData["image"] = formDataChild.image
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
              .pop();
          }
        }
        const { data } = await postOurClient(formData);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            ByteMiniWeb_Dashboard();
          } else {
            getOurClientParentList({ parentId: id });
          }
          emptyData();
          OpenModalCommonFunc(Cases.OUR_CLIENT_PARENT, setAllPopupState, false);
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };
  const handleSubmit = async (formValidationCondition) => {
    let validatorFields;
    if (formValidationCondition === "parentForm") {
      validatorFields = parentValidator.current;
    } else if (formValidationCondition === "childForm") {
      validatorFields = childValidator.current;
    }
    validatorFields.showMessages();

    if (validatorFields.allValid()) {
      try {
        // Process form data based on the formType
        if (formValidationCondition === "parentForm") {
          submitForm();
        } else if (formValidationCondition === "childForm") {
          submitForm();
        }
        validatorFields.hideMessages();
      } catch (error) {
        // Handle error for form submission
      }
    } else {
      // Force update to display validation messages
      forceUpdate({});
    }
  };

  /**
   * create and update our client
   */
  const handleSubmitSetting = async (isRestore = false, isClose = true) => {
    if (id >= -1 && isEdit) {
      setLoaderShow(true);
      try {
        let newData;
        if (isRestore) {
          const isConfirm = window.confirm(
            "Are you sure want to restore style ?"
          );
          if (isConfirm) {
            newData = {
              styleRestore: true,
              ourClientId: id,
            };
          } else {
            setLoaderShow(false);
            return;
          }
        } else {
          if (+theme === 1) {
            newData = OurClientOneSubFun({
              settingFormData,
              boxSection,
            });
            if (isBackgroundColor) {
              newData[".clients-section"] = {
                ...newData?.[".clients-section"],
                "background-color": settingFormData?.[".clients-section"]?.[
                  "background-color"
                ]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[".clients-section"]?.[
                          "background-color"
                        ],
                    })
                  : "",
              };
            }
            if (boxSection) {
              newData[".clients-section.bg-image::before"] = {
                ...newData[".clients-section.bg-image::before"],
                "border-radius": `${settingFormData?.[".clients-section"]?.[
                  "border-radius"
                ]?.replace("rem", "")}rem`,
              };
            }
          } else if (+theme === 2) {
            newData = OurClientTwoSubFun({
              settingFormData,
              boxSection,
            });
            if (isBackgroundColor) {
              newData[".partner-section"] = {
                ...newData?.[".partner-section"],
                "background-color": settingFormData?.[".partner-section"]?.[
                  "background-color"
                ]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[".partner-section"]?.[
                          "background-color"
                        ],
                    })
                  : "",
              };
            }
            if (boxSection) {
              newData[".partner-section.bg-image::before"] = {
                ...newData[".partner-section.bg-image::before"],
                "border-radius": `${settingFormData?.[".partner-section"]?.[
                  "border-radius"
                ]?.replace("rem", "")}rem`,
              };
            }
          }
          delete newData?.image;
          newData = {
            style: newData,
            ourClientId: id,
          };
        }

        const { data } = await putUpdateOurClient(newData);
        if (data.status === 200) {
          getOurClientParentList({ isClose: true });
          if (isClose) {
            OpenModalCommonFunc(
              Cases.SETTING_OUR_CLIENT_PARENT,
              setAllPopupState,
              false
            );
            emptyData();
            setBackImageToggle(false);
          }
          setLoaderShow(false);
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  /**
   * get data our client
   * @param {id} ourClientId
   */
  const handleEditOurClient = async (ourClientId, isParent) => {
    try {
      setLoaderShow(true);
      const { data } = await getOurClientDetails(ourClientId, navMapIds);
      if (data.status === 200) {
        let payload = data.payload.data;
        if (isParent) {
          setSectionPageName(payload?.title);
          setId(ourClientId);
          setIsParent(true);
          setFormData({
            title: payload?.title || "",
            subTitle: payload?.subTitle || "",
            itemLimit: payload?.itemLimit || "",
            isTitleShow: payload?.isTitleShow ? payload?.isTitleShow : false,
            theme: payload?.theme || 1,
            navMapId: payload?.navMapId || "",
            webId: localStorage.getItem("webId"),
            style: payload?.style || {},
          });
          OpenModalCommonFunc(Cases.OUR_CLIENT_PARENT, setAllPopupState);
        } else {
          setChildId(ourClientId);
          setIsParent(false);
          setFormDataChild({
            title: payload?.title || "",
            subTitle: payload?.subTitle || "",
            isTitleShow: payload?.isTitleShow ? payload?.isTitleShow : false,
            image: payload?.image || "",
            parentId: payload?.parentId || "",
            order: payload?.order || 1,
            webId: localStorage.getItem("webId"),
          });
          OpenModalCommonFunc(Cases.OUR_CLIENT, setAllPopupState);
        }
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * get setting data
   * @param {id} ourClientId
   */

  const handleChangeSettings = async (ourClientId, isThemeEdit = false) => {
    try {
      setLoaderShow(true);
      setId(ourClientId);
      setSettingFormData((prev) => {
        return {
          ...prev,
          ourClientId,
        };
      });
      const { data } = await getOurClientDetails(ourClientId);
      if (data.status === 200) {
        const themeCount = data?.payload?.data?.theme;
        setTheme(themeCount);
        setImgShow(true);
        let payload = data.payload.data;
        setSectionPageName(payload.title);
        let find = [".clients-section", ".partner-section"];
        let section = Object.keys(payload.style);
        let foundItem = null;
        for (const key in find) {
          if (section.includes(find[key])) {
            foundItem = find[key];
            break; // If found, exit the loop
          }
        }

        if (
          +themeCount === ThemeNo.ThemeOne &&
          isEmptyObject(data?.payload?.data?.style)
        ) {
          setSettingFormData({ ...ourClientStyleOne });
          setBoxSection(false);
          setBackImageToggle(false);
          setIsBackgroundColor(false);
        } else if (
          +themeCount === ThemeNo.ThemeTwo &&
          isEmptyObject(data?.payload?.data?.style)
        ) {
          setSettingFormData({ ...ourClientStyleTwo });
          setBoxSection(false);
          setBackImageToggle(false);
          setIsBackgroundColor(false);
        } else {
          if (
            payload.style?.[foundItem]?.["width"] ||
            payload.style?.[foundItem]?.["margin"] ||
            payload.style?.[foundItem]?.["border-radius"]
          ) {
            setBoxSection(true);
          }
          if (payload.style?.[`${foundItem}.bg-image`]?.["background-image"]) {
            setBackImageToggle(true);
          }
          if (payload.style?.[foundItem]?.["background-color"]) {
            setIsBackgroundColor(true);
          }
          let getStyle;
          if (+themeCount === 1) {
            getStyle = OurClientThemeOneFunction(payload);
          } else if (+themeCount === 2) {
            getStyle = OurClientThemeTwoFunction(payload);
          }
          setSettingFormData((prev) => {
            return {
              [foundItem]: {
                padding: payload.style?.[foundItem]?.["padding"],
                "background-color": payload.style?.[foundItem]?.[
                  "background-color"
                ]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[foundItem]?.["background-color"],
                    })
                  : "",
                width: payload.style?.[foundItem]?.["width"]?.replace(
                  "calc(100% - ",
                  ""
                ),
                margin: payload.style?.[foundItem]?.["margin"],
                "border-radius": payload.style?.[foundItem]?.["border-radius"],
              },
              [`${foundItem}.bg-image`]: {
                "background-image": payload.style?.[`${foundItem}.bg-image`]?.[
                  "background-image"
                ]
                  ? removeUrlKeyInPath({
                      urlString:
                        payload.style?.[`${foundItem}.bg-image`]?.[
                          "background-image"
                        ],
                    })
                  : "",
              },
              [`${foundItem}.bg-image::before`]: {
                "background-color": payload.style?.[
                  `${foundItem}.bg-image::before`
                ]?.["background-color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[`${foundItem}.bg-image::before`]?.[
                          "background-color"
                        ],
                    })
                  : "",
              },
              ...getStyle,
              custom: payload.style.custom,
            };
          });
        }
        if (!isThemeEdit) {
          OpenModalCommonFunc(
            Cases.SETTING_OUR_CLIENT_PARENT,
            setAllPopupState
          );
          TostMessage({ message: data, type: "success" });
        }
        setIsEdit(true);
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * delete our client
   * @param {id} ourClientId
   */
  const handleDelete = async (ourClientId, isParent = false) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await deleteOurClient(ourClientId);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            ByteMiniWeb_Dashboard();
          } else {
            getOurClientParentList({ packageId: childId });
          }
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * clear data
   */
  const emptyData = () => {
    setImageIs("");
    setIsBackgroundColor(false);
    setBoxSection(false);
    setBackImageToggle(false);
    setIsEdit(false);
    setIsParent(false);
    setChildId("");
    setFormData({
      title: "",
      subTitle: "",
      navMapId: "",
      isTitleShow: true,
      theme: 1,
      webId: localStorage.getItem("webId"),
      style: {
        ".clients-section": {
          padding: "100px 0",
          "background-color": "rgb(255 255 255 / 100)",
        },
        ".clients-section.bg-image": {
          "background-image": "",
        },
        ".clients-section.bg-image::before": {
          "background-color": "",
          // rgb(0 0 0 / 0.8)
        },
        ".clients-section .section-title-area .section-subtitle": {
          "font-size": "16px",
          "font-weight": "600",
          color: "rgb(255 73 124 / 100)",
          "background-color": "rgb(255 73 124 / 0.2)",
          padding: "5px 20px",
          p1: "5px",
          p2: "20px",
          "border-radius": "15px",
          "margin-bottom": "15px",
        },
        ".clients-section .section-title-area .section-title": {
          "font-size": "44px",
          "font-weight": "600",
          color: "rgb(0 0 0 / 100)",
          "margin-bottom": "10px",
        },
        ".clients-slider .slick-arrow:before": {
          color: "rgb(255 73 124 / 100)",
        },
        ".clients-slider .client-img": {
          padding: "10px",
          "max-width": "300px",
          "max-height": "120px",
        },
      },
    });
    setSkill([{ id: randomStr(2), skill: "", parentageOfSkill: "" }]);
    setGalleryImgId("");
    setFormDataChild({
      title: "",
      subTitle: "",
      isTitleShow: true,
      image: "",
      order: 1,
      webId: localStorage.getItem("webId"),
      navMapId: "",
    });
    setLoaderShow(false);
    setSettingFormData({
      ".clients-section": {
        padding: "100px 0",
        "background-color": "rgb(255 255 255 / 100)",
      },
      ".clients-section.bg-image": {
        "background-image": "",
      },
      ".clients-section.bg-image::before": {
        "background-color": "",
        // rgb(0 0 0 / 0.8)
      },
      ".clients-section .section-title-area .section-subtitle": {
        "font-size": "16px",
        "font-weight": "600",
        color: "rgb(255 73 124 / 100)",
        "background-color": "rgb(255 73 124 / 0.2)",
        padding: "5px 20px",
        p1: "5px",
        p2: "20px",
        "border-radius": "15px",
        "margin-bottom": "15px",
      },
      ".clients-section .section-title-area .section-title": {
        "font-size": "44px",
        "font-weight": "600",
        color: "rgb(0 0 0 / 100)",
        "margin-bottom": "10px",
      },
      ".clients-slider .slick-arrow:before": {
        color: "rgb(255 73 124 / 100)",
      },
      ".clients-slider .client-img": {
        padding: "10px",
        "max-width": "300px",
        "max-height": "120px",
      },
      ourClientId: "",
    });
  };

  const handleAlignAndCloneSection = ({ isClone = false }) => {
    if (isClone) {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          cloneModal: true,
        };
      });
      setCloneFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          pageConfigDetailsLabel: pageConfigDetails[0]?.label,
          contentDetailName: contentDetail.name,
        };
      });
    } else {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          alignModal: true,
        };
      });
      setAlignFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          label: pageConfigDetails[0]?.label,
        };
      });
    }
  };
  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody
            className="support-ticket-font box-border-div"
            style={{ padding: "20px" }}
          >
            <Row>
              <CardBuilderHeaderSection
                addBtnTitle={pageConfigDetails[0]?.label}
                name={pageConfigDetails[0]?.label}
                isAdd={!ourClientDetail && true}
                mainEditSection={true}
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
                openModal={() => {
                  OpenModalCommonFunc(
                    Cases.OUR_CLIENT_PARENT,
                    setAllPopupState
                  );
                  setSectionPageName(modalsName.OUR_CLIENT_PARENT);
                  emptyData();
                  setIsParent(true);
                  setCollId(contentMapId);
                }}
                isCloneSection={true}
                isAlignSection={true}
                handleAlignAndCloneSection={handleAlignAndCloneSection}
              />
              {ourClientDetail && (
                <Col xl="12 xl-50 box-col-6" md="6">
                  <Card className="ecommerce-widget mb-0">
                    <CardBody
                      className="support-ticket-font"
                      style={{ padding: "20px" }}
                    >
                      <div className="col-12">
                        <CardBuilderHeaderSection
                          name={ourClientDetail?.title}
                          checked={ourClientDetail?.isActive}
                          switchToggle={true}
                          id={ourClientDetail?.ourClientId}
                          handleChange={handleChangeOurClient}
                        />
                        <OpenPopupBtn
                          openPopup={() => {
                            setSectionPageName(ourClientDetail?.title);
                            getOurClientParentList({
                              parentId: ourClientDetail.ourClientId,
                            });
                            setCollId(contentMapId);
                            setNavMapId(ourClientDetail?.navMapId);
                            setFilter("");
                          }}
                          name={`Add or View ${ourClientDetail?.title}`}
                        />
                      </div>
                      <div className="d-flex justify-content-end ">
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleEditOurClient(
                              ourClientDetail.ourClientId,
                              true
                            );
                            setCollId(contentMapId);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() =>
                            handleDelete(ourClientDetail.ourClientId, true)
                          }
                        >
                          <Trash />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleChangeSettings(ourClientDetail.ourClientId);
                          }}
                        >
                          <Settings />
                        </IconButton>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
              <DeleteSection
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
              />
            </Row>
          </CardBody>
        </Card>
      </Col>
      <CommonPopup
        open={allPopupState.ourClientChildPopup}
        close={() => {
          OpenModalCommonFunc(Cases.OUR_CLIENT, setAllPopupState, false);
          setIsParent(false);
        }}
        // apiCall={getOurClientParentList}
        modalName={sectionPageName ? sectionPageName : modalsName.OUR_CLIENT}
        modalCase={Cases.OUR_CLIENT}
        handleSubmit={handleSubmit}
        validationForm={"childForm"}
        emptyData={emptyData}
        inputData={fromData}
        edit={false}
        oldTitle={titleUpdateKey.isGallery}
        label={titleUpdateKey.Certificates}
        getData={getData}
        content={
          <OurClientPopup
            handleChangeStatus={handleChangeOurClient}
            getOurClientParentList={getOurClientParentList}
            handleEdit={handleEditOurClient}
            imageUpload={imageUploadOurClient}
            emptyData={emptyData}
            simpleValidator={childValidator}
            webMasterDetail={webMasterDetail}
            handleDelete={handleDelete}
          />
        }
      />

      {/* item Service Counter parent popup */}
      <CommonPopup
        open={allPopupState.ourClientPopup}
        close={() => {
          OpenModalCommonFunc(Cases.OUR_CLIENT_PARENT, setAllPopupState, false);
          setIsParent(false);
        }}
        modalName={
          sectionPageName ? sectionPageName : modalsName.OUR_CLIENT_PARENT
        }
        modalCase={Cases.OUR_CLIENT_PARENT}
        handleSubmit={handleSubmit}
        validationForm={"parentForm"}
        emptyData={emptyData}
        inputData={formDataChild}
        edit={false}
        content={<OurClientParentPopup simpleValidator={parentValidator} />}
      />

      {/* setting Service Counter parent popup */}
      <CommonPopup
        open={allPopupState.ourClientParentSetting}
        close={() =>
          OpenModalCommonFunc(
            Cases.SETTING_OUR_CLIENT_PARENT,
            setAllPopupState,
            false
          )
        }
        modalName={
          sectionPageName
            ? sectionPageName
            : modalsName.SETTING_OUR_CLIENT_PARENT
        }
        modalCase={Cases.SETTING_OUR_CLIENT_PARENT}
        handleSubmit={handleSubmitSetting}
        emptyData={emptyData}
        inputData={formDataChild}
        edit={false}
        content={
          <SettingOurClientParentPopup
            simpleValidator={parentValidator}
            imageUpload={imageUploadImages}
            handleSubmitSetting={handleSubmitSetting}
            handleChangeSettings={handleChangeSettings}
          />
        }
      />
    </>
  );
};

export default OurClient;
