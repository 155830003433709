import React, { useContext, useEffect, useState } from "react";
import OrganizationItem from "./ModualManagerItem";
import OrganizationDetailsModel from "./ModualManagerDetailsModel";
import OrganizationAddModel from "./ModualManagerAddModel";

import { toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "react-phone-number-input/style.css";

import { useForm } from "react-hook-form";
import ModualManagerContext from "../../context/ModuleManagerContext/ModualManagerContext";
import {
  Add_ModualList,
  Delete_ModualList,
  Detail_ModualList,
  Edit_ModualList,
  ModualList,
} from "../../api/ModualMasterApi/ModualMasterApi";
import { randomStr } from "../../utils/helper";
import TopLoadingBar from "../../utils/topLoadingBar";

const Organization = () => {
  const {
    setformvalue,
    setDropDownData,
    setahoeDataModel,
    setModal,
    modal,
    sortOrder,
    setSortOrder,
    sortBy,
    setSortBy,
    filter,
    setFilter,
    editIndex,
    seteditIndex,
    uuid,
    setUuid,

    setName,
    name,
    setPerentId,
    perentId,
    setQty,

    setIsAddHandle,
    progress,
    setProgress,
    togglesubmit,
    setToggle,
    val,

    setViewName,
    setPerent,
    setModualAccess,
    setPerentName,
  } = useContext(ModualManagerContext);

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);
  const [roleId, setRoleId] = useState("");
  const [dropDownFilter, setDropDownFilter] = useState("");

  const [access, setFilds] = useState([
    {
      id1: randomStr(5),
      id: Number(""),
      roleIds: [],
    },
  ]);

  //<<<<<<<<<<<<<<<--------------------TABLE ORG DATA API START-------------------------->>>>>>>>>>>>>>>
  const getData = async () => {
    try {
      let paramsObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
        search: val,
      };
      if (filter.length) {
        paramsObj["search"] = { name: filter };
      }
      const { data } = await ModualList(paramsObj);
      if (data.status === 200) {
        setformvalue(data.payload.data);
        setRecord(data.pager.totalRecords);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  useEffect(() => {
    getData();
  }, [page, pageSize, sortOrder, sortBy, filter]);

  const getDropDownData = async () => {
    try {
      let paramsObj = {
        recordsPerPage: pageSize,
        search: { parentId: null, name: dropDownFilter },
      };
      // if (dropDownFilter.length) {
      //   paramsObj["search"] = { name: dropDownFilter };
      // }
      const { data } = await ModualList(paramsObj);
      if (data.status === 200) {
        setDropDownData(data.payload.data);
      } else {
        setDropDownData([]);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  useEffect(() => {
    getDropDownData();
  }, [sortOrder, dropDownFilter]);

  useEffect(() => {
    setProgress(100);
  }, []);

  const AddModel = () => {
    setModal(true);
    setToggle(true);
  };

  const handleChange = (perentId) => {
    setPerentId(perentId.value);
  };
  const handleInputChange = (perentId) => {
    setDropDownFilter(perentId);
  };
  const handleInputChange1 = (i, e) => {
    const values = [...access];
    if ("id".includes(e.target.name)) {
      values[i][e.target.name] = Number(e.target.value);
    } else {
      values[i][e.target.name] = e.target.value;
    }
    setFilds(values);
  };
  //<<<<<<<<<<<<<<<--------------------TABLE DATA API END-------------------------->>>>>>>>>>>>>>>
  //<<<<<<<<<<<<<<<<<<<<<<------------------EDIT ORG HANDLE START ---------------------->>>>>>>>>>>>>>>>>>>
  const editHandle = async (id) => {
    setUuid(id);
    try {
      const { data } = await Detail_ModualList(id);
      if (data.status === 200) {
        setToggle(false);
        setIsAddHandle(false);
        seteditIndex(uuid);
        setName(data.payload.data.name);
        if (
          data.payload.data.moduleAccess &&
          data.payload.data.moduleAccess.length
        ) {
          setFilds(
            data.payload.data.moduleAccess.map((ma) => {
              return {
                id1: 1,
                id: Number(ma.accessId),
                roleIds: ma.roleModuleAccess.map((rma) => rma.roleId),
              };
            })
          );
        } else {
          setFilds([
            {
              id1: randomStr(5),
              id: Number(""),
              roleIds: [],
            },
          ]);
        }
        setRoleId(
          data.payload.data.moduleAccess.map((elem) => ({
            label: elem.roleModuleAccess.map((e) => e.role.name),
            value: elem.roleModuleAccess.map((e) => +e.roleId),
          }))
        );
        if (data.payload.data.parent) {
          setPerentId(data.payload.data.parent.id);
          setPerentName(data.payload.data.parent.name);
        }
        setModal(true);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      toast.error(error.response, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  //<<<<<<<<<<<<<<<<<<<<<<------------------EDIT ORG HANDLE END ---------------------->>>>>>>>>>>>>>>>>>>

  const { register } = useForm();

  //<<<<<<<<<<<<<<<-----------------ADD DATA SUBMIT *OR* EDIT DATA SUBMIT START ------------------>>>>>>>>>>
  const onSubmit = async (e) => {
    e.preventDefault();
    let formData;
    if (editIndex !== -1 && !togglesubmit) {
      formData = {
        moduleId: uuid,
        name: name,
        parentId: perentId,
        access: access,
      };
      // <<<<<<<<<<<<<<----------EDIT ORG API----------------->>>>>>>>>>>>
      try {
        const { data } = await Edit_ModualList(formData, uuid);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
          });
          setModal(false);
          getData();
          setName("");
          setQty("");
          setPerentId("");
          setFilds([
            {
              id1: 1,
              id: Number(""),
              roleIds: [],
            },
          ]);
        } else {
        }
      } catch (error) {
        toast.error(error.response.data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } else {
      if (perentId === "") {
        formData = {
          name: name,
        };
      } else {
        formData = {
          name: name,
          parentId: perentId,
          access: access,
        };
      }

      //<<<<<<<<------ADD ORG API --------------->>>>>>>>
      try {
        const { data } = await Add_ModualList(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            transition: Zoom,
          });
          setModal(false);
          getData();
          setName("");
          setQty("");
          setPerentId("");
          setFilds([]);
        } else {
        }
      } catch (error) {
        toast.error(error.response.data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    }
  };
  //<<<<<<<<<<<<<<<-----------------ADD DATA SUBMIT *OR* EDIT DATA SUBMIT END ------------------>>>>>>>>>>
  //<<<<<<<<<<<<<<<<---------------------SHOW ORG DETAILS *OR* SHOW USER DETAILS START ----------->>>>>>>>>>>>>>>>
  const showHandle = async (id) => {
    setUuid(id);
    // <<<<-----ORG DETAILS SHOW API------>>>>>>
    try {
      const { data } = await Detail_ModualList(id);
      if (data.status === 200) {
        setahoeDataModel(true);
        setViewName(data.payload.data.name);
        setPerent(
          data.payload.data.parent ? data.payload.data.parent.name : ""
        );
        setPerentId(data.payload.data.parentId || "");
        setModualAccess(data.payload.data.moduleAccess);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      toast.error(error.response, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  const DeleteHandle = async (id) => {
    let formData = {
      moduleId: id,
    };
    try {
      if (window.confirm("Are you sure you want to delete modual?")) {
        const { data } = await Delete_ModualList(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
          });
          getData();
        } else {
        }
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };
  //<<<<<<<<<<<<<<<<---------------------SHOW ORG DETAILS *OR* SHOW USER DETAILS END ----------->>>>>>>>>>>>>>>>

  //<<<<<<<<<<<-------------SORTING START----------------->>>>>>>>>>>.
  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };
  //<<<<<<<<<<<-------------SORTING END----------------->>>>>>>>>>>.

  //<<<<<<<<<<<<<<----------------SEARCH FILTER START ------------->>>>>>>>>>>>>
  const handleFilter = (e) => {
    if (e.target.value === "") {
      setFilter(e.target.value);
    } else {
      setFilter(e.target.value);
    }
  };
  //<<<<<<<<<<<<<<----------------SEARCH FILTER END------------->>>>>>>>>>>>>
  const handleAdd = (e) => {
    e.preventDefault();
    setFilds([
      ...access,
      {
        id1: randomStr(5),
        id: Number(),
        roleIds: [],
      },
    ]);
  };

  const hanldeRemove = (i, e) => {
    e.preventDefault();
    const values = [...access];
    values.splice(i, 1);
    setFilds(values);
  };
  const handleChange1 = (param, index) => {
    access[index].roleIds = param.map((elem) => elem.value);
  };

  const close = () => {
    setModal(!modal);
    setName("");
    setFilds("");
    setRoleId("");
    setPerentId("");
    setPerentName("");
    setFilds([
      {
        id1: 1,
        id: Number(""),
        roleIds: [],
      },
    ]);
  };

  return (
    <>
      <div>
        <TopLoadingBar progress={progress} setProgress={setProgress} />
        <OrganizationAddModel
          onSubmit={onSubmit}
          register={register}
          handleChange={handleChange}
          handleInputChange={handleInputChange}
          access={access}
          setFilds={setFilds}
          hanldeRemove={hanldeRemove}
          handleAdd={handleAdd}
          handleInputChange1={handleInputChange1}
          handleChange1={handleChange1}
          roleId={roleId}
          close={close}
        />
        <OrganizationDetailsModel />
        <OrganizationItem
          handleFilter={handleFilter}
          sorting={sorting}
          editHandle={editHandle}
          showHandle={showHandle}
          AddModel={AddModel}
          setPage={setPage}
          setPageSize={setPageSize}
          pageSize={pageSize}
          page={page}
          record={record}
          DeleteHandle={DeleteHandle}
        />
      </div>
    </>
  );
};
export default Organization;
