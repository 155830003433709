import React, { useContext } from "react";
import { TostMessage } from "../../../utils";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import ItemsDragAndDrops from "../../../common/ItemsDragAndDrops";
import { maxLengthCheck } from "../../../../utils/helper";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { DELETE_CONFIRM } from "../../../constant";
import { delete_Image_Permanent } from "../apis/SliderApi/SliderApi";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import DownloadMultipageContext from "../../../context/DownloadMultipageContext/DownloadMultipageContext";
import { sort_order_Download } from "../apis/DownloadsApi/DownloadApi";
import IsShowTitleORNot from "../../../common/isShowTitleORNot";

const DownloadPopup = (props) => {
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const {
    handleChangeStatus,
    imageUpload,
    emptyData,
    simpleValidator,
    handleDelete,
    handleEdit,
    getDownloadParentList,
  } = props;
  const {
    formDataChild,
    setFormDataChild,
    setFilter,
    filter,
    isEdit,
    setApiDataOurTeam: setApiDataImageParent,
    apiDataOurTeam: apiDataImageParent,
  } = useContext(DownloadMultipageContext);
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * drag and drop
   * @param {drag data} result
   * @returns
   */
  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;
    const items = Array.from(apiDataImageParent.data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setApiDataImageParent({ ...formDataChild, data: items });
    try {
      let itemOrder = [];
      for (const item in items) {
        itemOrder.push({ downloadId: items[item].Id, order: +item + 1 });
      }
      const { data } = await sort_order_Download(itemOrder);
      if (data.status === 200) {
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * create new career
   */
  const hanldenewCreate = () => {
    const fileInput = document.getElementById("fileInput");
    emptyData(fileInput);
  };

  /**
   * reupload image
   */
  const reupload = () => {
    setFormDataChild((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };

  /**
   * input value change
   * @param {event} e
   */
  const InputValueChange = (e) => {
    const { value, name, checked } = e.target;
    setFormDataChild((prev) => {
      if (name === "isTitleShow") {
        return {
          ...prev,
          [name]: checked,
        };
      } else {
        return {
          ...prev,
          [name]: value,
        };
      }
    });
  };

  const deleteImagePermanent = async () => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData = {
          fileName: formDataChild.image
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
            .pop(),
        };
        const { data } = await delete_Image_Permanent(formData);
        if (data.status === 200) {
          TostMessage({ message: data, type: "success", position: "center" });
          setFormDataChild({ ...formDataChild, image: "", imageLink: "" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const allowedExtensions = ["jpg", "png", "pdf", "doc", "txt", "xls"];
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const extension = file.name.split(".").pop().toLowerCase();
    if (allowedExtensions.includes(extension)) {
      setFormDataChild((prev) => {
        return {
          ...prev,
          filePath: file,
        };
      });
    } else {
      const fileInput = document.getElementById("fileInput");
      if (fileInput) {
        fileInput.value = null;
      }
      TostMessage({
        message: "file type required only .jpg, .png, .pdf, .doc, .txt, .xls",
        type: "error",
        position: "custome",
      });
    }
  };

  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="6">
            <div>
              <h6>Total Count : {apiDataImageParent?.data?.length}</h6>
              {/* <p className="pt-2">You can add upto 25 Images</p> */}
              <Row>
                <Col md="6">
                  <Input
                    className="form-control mt-3 "
                    name="search"
                    type="text"
                    placeholder="search"
                    style={{ borderRadius: "0", color: "black" }}
                    value={filter}
                    onChange={(e) => {
                      getDownloadParentList({ filter: e.target.value });
                      setFilter(e.target.value);
                    }}
                  />
                </Col>

                <Col
                  md="6"
                  className="d-flex justify-content-center align-items-center mt-3"
                >
                  <Button
                    color="primary"
                    className="rounded-0"
                    onClick={() => hanldenewCreate()}
                  >
                    Add New
                  </Button>
                </Col>
              </Row>
              {apiDataImageParent.isLoading ? (
                <div className="mt-4">
                  <h6>Loading ...</h6>
                </div>
              ) : (
                <div className="mt-4">
                  <ItemsDragAndDrops
                    handleOnDragEnd={handleOnDragEnd}
                    characters={apiDataImageParent?.data}
                    handleChangeStatus={handleChangeStatus}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                  />
                </div>
              )}
            </div>
          </Col>
          <Col sm="12" xl="6">
            <div className="mt-4 d-flex flex-column justify-content-center ">
              <h6>{isEdit ? "Edit" : " Add New"}</h6>
              <Row className="mt-4">
                <Col md="12 mb-1">
                  <Label htmlFor="validationCustom03">
                    Title *{" "}
                    <IsShowTitleORNot
                      checked={formDataChild?.isTitleShow}
                      onChange={InputValueChange}
                    />{" "}
                  </Label>
                  <Input
                    className="form-control"
                    name="title"
                    type="text"
                    placeholder="Title *"
                    value={formDataChild.title}
                    onChange={(e) => InputValueChange(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    min={0}
                    maxLength={50}
                    onInput={maxLengthCheck}
                  />
                  <div className="text-end mt-3 ">
                    <span>{formDataChild.title.length} / 50</span>
                  </div>
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "title",
                      formDataChild.title,
                      "required"
                    )}
                  </p>
                </Col>
                <Col md="12 mb-1">
                  <Label htmlFor="validationCustom03">{"Sub Title"}</Label>
                  <Input
                    className="form-control"
                    name="subTitle"
                    type="text"
                    placeholder="Sub Title"
                    value={formDataChild.subTitle}
                    onChange={(e) => InputValueChange(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                  {/* <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "Sub Title",
                      formDataChild.subTitle,
                      "required"
                    )}
                  </p> */}
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"File * "}</Label>
                  <p>File type required JPG, PNG, PDF, DOC, TXT, XLS</p>
                  <Input
                    className="form-control"
                    name="url"
                    type="file"
                    accept=".jpg, .png, .pdf, .doc, .txt, .xls"
                    placeholder="Enter URL *"
                    style={{ borderRadius: "0", color: "black" }}
                    onChange={handleFileChange}
                    id="fileInput"
                  />
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "file",
                      formDataChild.filePath,
                      "required"
                    )}
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <ProductImageUploadPopup
          allPopupState={allPopupState}
          setAllPopupState={setAllPopupState}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
          image={formDataChild.image}
          setImage={setFormDataChild}
          imageUpload={imageUpload}
          isEdit={isEdit}
          reupload={reupload}
          // aspect={1.41/1}
        />
      </Container>
    </>
  );
};

export default DownloadPopup;
