import {
  addUrlImage,
  convertRGBA,
  removeUrlImage,
  removeUrlKeyInPath,
  rgbColorCreate,
} from "../../../../utils/helper";

export const SocialMediaTHmeOne = {
  ".custom-footer": {
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },

  ".custom-footer .foot-social": {
    padding: "70px 0",
    "background-color": { r: 31, g: 39, b: 50, a: 100 },
  },
  ".custom-footer .foot-social:before": {
    "background-image": "",
  },
  ".custom-footer .foot-social:after": {
    "background-image": "",
  },
  ".custom-footer .foot-social .social_buttons a": {
    "background-color": { r: 18, g: 119, b: 217, a: 0.2 },
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  socialMediaId: "",
};

export const SocialMediaThemeOneFunction = (payload) => {
  return {
    ".custom-footer": {
      "background-color": payload.style?.[".custom-footer"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString: payload.style?.[".custom-footer"]?.["background-color"],
          })
        : "",
    },
    ".custom-footer .foot-social": {
      padding: payload.style?.[".custom-footer .foot-social"]?.["padding"],
      "background-color": payload.style?.[".custom-footer .foot-social"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".custom-footer .foot-social"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".custom-footer .foot-social:before": {
      "background-image": removeUrlKeyInPath({
        urlString:
          payload.style?.[".custom-footer .foot-social:before"]?.[
            "background-image"
          ],
      }),
    },
    ".custom-footer .foot-social:after": {
      "background-image": removeUrlKeyInPath({
        urlString:
          payload.style?.[".custom-footer .foot-social:after"]?.[
            "background-image"
          ],
      }),
    },
    ".custom-footer .foot-social .social_buttons a": {
      "background-color": payload.style?.[
        ".custom-footer .foot-social .social_buttons a"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".custom-footer .foot-social .social_buttons a"
              ]?.["background-color"],
          })
        : "",
      color: payload.style?.[".custom-footer .foot-social .social_buttons a"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".custom-footer .foot-social .social_buttons a"
              ]?.["color"],
          })
        : "",
    },
    custom: payload.style?.custom,
  };
};

export const SocialMediaStyleOneSubFun = ({ settingFormData }) => {
  let newData = {
    ...settingFormData,
    ".custom-footer": {
      ...settingFormData[".custom-footer"],
    },
    ".custom-footer .foot-social": {
      ...settingFormData[".custom-footer .foot-social"],
      padding: `${settingFormData?.[".custom-footer .foot-social"]?.[
        "padding"
      ]?.replace("px", "")}px`,
      "background-color": settingFormData[".custom-footer .foot-social"]?.[
        "background-color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".custom-footer .foot-social"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".custom-footer .foot-social:before": settingFormData[
      ".custom-footer .foot-social:before"
    ]["background-image"]
      ? {
          "background-image": addUrlImage({
            urlString: removeUrlImage({
              urlString:
                settingFormData[".custom-footer .foot-social:before"][
                  "background-image"
                ],
            }),
          }),
        }
      : "",
    ".custom-footer .foot-social:after": settingFormData[
      ".custom-footer .foot-social:after"
    ]["background-image"]
      ? {
          "background-image": addUrlImage({
            urlString: removeUrlImage({
              urlString:
                settingFormData[".custom-footer .foot-social:after"][
                  "background-image"
                ],
            }),
          }),
        }
      : "",
    ".custom-footer .foot-social .social_buttons a": {
      ...settingFormData[".custom-footer .foot-social .social_buttons a"],
      "background-color": settingFormData[
        ".custom-footer .foot-social .social_buttons a"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".custom-footer .foot-social .social_buttons a"
              ]?.["background-color"],
          })
        : "",
      color: settingFormData[".custom-footer .foot-social .social_buttons a"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".custom-footer .foot-social .social_buttons a"
              ]?.["color"],
          })
        : "",
    },
  };
  return newData;
};
