import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import FooterContext from "../../../context/FooterContext/FooterContext";
import Dividers from "../../../common/divider";
import { SocialTypes } from "../../../../constant";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import { Cases, DELETE_CONFIRM, options } from "../../../constant";
import { Trash2, UploadCloud } from "react-feather";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import { IconButton } from "@mui/material";
import { delete_Multi_Image_Permanent } from "../apis/SliderApi/SliderApi";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import { randomStr } from "../../../../utils/helper";
import { getSocialMediaMaster } from "../apis/SocialMediaApi/SocialMediaApi";
import Select from "react-select";

const FooterTopHeader = (props) => {
  const { imageUpload } = props;
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const { setLoaderShow } = useContext(LoaderContext);
  const {
    isEdit,
    topHeaderForm,
    setIsSectionImage,
    setTopHeaderForm,
    setCustomeIndex,
  } = useContext(FooterContext);
  const [socialMediaMaster, setSocialMediaMaster] = useState([]);

  /**
   * get social media master list
   */
  const getSocialMediaMasterApi = async () => {
    try {
      let paramObj = {
        showAll: true,
      };
      const { data } = await getSocialMediaMaster(paramObj);
      if (data.status === 200) {
        setSocialMediaMaster(data.payload.data);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  useEffect(() => {
    getSocialMediaMasterApi();
  }, []);

  /**
   * input value change
   * @param {event} e
   */
  const changeFormValue = (e) => {
    const { value, name } = e.target;
    setTopHeaderForm((prev) => {
      let updatedData = {
        ...prev,
        [name]: value,
      };
      return updatedData;
    });
  };

  const handleSocialMediaChange = (e, i) => {
    const { name, value, options, selectedIndex } = e.target;
    let old = [...topHeaderForm.socialMedia];
    old[i][name] = value;
    if (name == "type") {
      old[i]["name"] = options[selectedIndex].text;
    }
    setTopHeaderForm((prev) => {
      return {
        ...prev,
        socialMedia: old,
      };
    });
  };
  const handleSocialMediaChangeDialCode = (e, i) => {
    let old = [...topHeaderForm.socialMedia];
    old[i]["dialCode"] = e;
    setTopHeaderForm((prev) => {
      return {
        ...prev,
        socialMedia: old,
      };
    });
  };

  const deleteImagePermanent = async (index) => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData;
        let social = [...topHeaderForm.socialMedia];
        let imageName = social[index];
        formData = {
          fileName: imageName.image
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
            .pop(),
        };
        const { data } = await delete_Multi_Image_Permanent(formData);
        if (data.status === 200) {
          TostMessage({ message: data, type: "success", position: "center" });
          setTopHeaderForm((prev) => {
            social[index]["image"] = "";
            return {
              ...prev,
              image: "",
              socialMedia: social,
            };
          });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const addMoreInput = () => {
    setTopHeaderForm((prev) => {
      return {
        ...prev,
        socialMedia: [
          ...prev.socialMedia,
          { name: "", value: "", id: randomStr(2) },
        ],
      };
    });
  };
  const deleteMoreInput = (id) => {
    setTopHeaderForm((prev) => {
      return {
        ...prev,
        socialMedia: prev.socialMedia.filter((item) => item.id !== id),
      };
    });
  };
  /**
   * reupload image
   */
  const reupload = () => {
    setTopHeaderForm((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };
  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="12">
            <div className="mt-4 d-flex flex-column justify-content-center ">
              <h6>{isEdit ? "Edit" : "Add New"}</h6>
              <Row className="mt-4">
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom03">DialCode</Label>
                  <Select
                    style={{ width: "125px" }}
                    options={options}
                    name="dialCode"
                    isSearchable={true}
                    value={topHeaderForm?.dialCode}
                    onChange={(prev) =>
                      setTopHeaderForm((prevValue) => {
                        return {
                          ...prevValue,
                          dialCode: prev,
                        };
                      })
                    }
                  />
                </Col>
                <Col md="9 mb-3">
                  <Label htmlFor="validationCustom03">Phone</Label>
                  <Input
                    className="form-control"
                    name="phone"
                    type="number"
                    placeholder="Phone"
                    value={topHeaderForm?.phone}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    required
                  />
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">Email</Label>
                  <Input
                    className="form-control"
                    name="email"
                    type="email"
                    placeholder="Email"
                    value={topHeaderForm?.email}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    required
                  />
                </Col>
                <Dividers divide={"Social Media"} />
                {topHeaderForm?.socialMedia?.map((elem, i) => {
                  return (
                    <Row key={elem.id}>
                      {elem.type == SocialTypes.custom && (
                        <Col md="3 mb-3">
                          <div
                            className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                            style={{ cursor: "pointer" }}
                          >
                            <span
                              onClick={() => {
                                OpenModalCommonFunc(
                                  Cases.PRODUCT_UPLOAD,
                                  setAllPopupState
                                );
                                setIsSectionImage(null);
                                setCustomeIndex(i);
                              }}
                            >
                              <UploadCloud />
                            </span>
                            {elem?.image && (
                              <img
                                src={
                                  `${process.env.REACT_APP_BUCKET_URL}/${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/${elem?.image}` ||
                                  ""
                                }
                                alt=""
                                style={{
                                  width: "80%",
                                  height: "60%",
                                  objectFit: "contain",
                                  position: "absolute",
                                }}
                                onClick={() => {
                                  deleteImagePermanent(i);
                                }}
                              />
                            )}
                          </div>
                        </Col>
                      )}
                      <Col md="3 mb-3">
                        <Label htmlFor="validationCustom03">{"Type *"}</Label>
                        <select
                          className="form-control"
                          style={{ borderRadius: "0", color: "black" }}
                          value={elem.type}
                          onChange={(e) => handleSocialMediaChange(e, i)}
                          name="type"
                        >
                          <option value="">--select--</option>
                          {socialMediaMaster.map((social) => {
                            return (
                              <option
                                value={social.type}
                                disabled={topHeaderForm?.socialMedia.some(
                                  (elem) => elem.type == social.type
                                )}
                              >
                                {social.label}
                              </option>
                            );
                          })}
                        </select>
                      </Col>
                      <Col md="3 mb-3">
                        <Label htmlFor="validationCustom03">{"Name"}</Label>
                        <Input
                          className="form-control"
                          name="name"
                          type="text"
                          placeholder="Name"
                          value={elem.name}
                          onChange={(e) => handleSocialMediaChange(e, i)}
                          style={{ borderRadius: "0", color: "black" }}
                        />
                      </Col>
                      {elem.type === SocialTypes.whatsapp && (
                        <Col md="3 mb-3">
                          <Label htmlFor="validationCustom03">
                            {"Dial Code*"}
                          </Label>
                          <Select
                            style={{ width: "125px" }}
                            options={options}
                            name="dialCode"
                            value={elem.dialCode}
                            onChange={(e) =>
                              handleSocialMediaChangeDialCode(e, i)
                            }
                          />
                        </Col>
                      )}
                      <Col md="3 mb-3">
                        <Label htmlFor="validationCustom03">{"Value"}</Label>
                        <div className="d-flex ">
                          <Input
                            className="form-control"
                            name="value"
                            type="text"
                            placeholder="Value"
                            value={elem.value}
                            onChange={(e) => handleSocialMediaChange(e, i)}
                            style={{ borderRadius: "0", color: "black" }}
                          />
                          {topHeaderForm.socialMedia.length >= 2 && (
                            <IconButton
                              edge="start"
                              color="inherit"
                              style={{ marginLeft: "5px", color: "red" }}
                              onClick={() => deleteMoreInput(elem.id)}
                            >
                              <Trash2 />
                            </IconButton>
                          )}
                        </div>
                      </Col>
                    </Row>
                  );
                })}
                <div className="d-flex justify-content-center ">
                  <Button onClick={() => addMoreInput()} color="primary">
                    + Add More
                  </Button>
                </div>
              </Row>
            </div>
          </Col>
        </Row>
        <ProductImageUploadPopup
          allPopupState={allPopupState}
          setAllPopupState={setAllPopupState}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
          image={topHeaderForm.image}
          setImage={setTopHeaderForm}
          imageUpload={imageUpload}
          isEdit={isEdit}
          reupload={reupload}
          // aspect={1 / 1}
        />
      </Container>
    </>
  );
};

export default FooterTopHeader;
