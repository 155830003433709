import { IconButton, Tooltip } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { DataGrid } from "@mui/x-data-grid";
import React, { useContext, useMemo } from "react";
import { Card, CardHeader, Col, Container, Row } from "reactstrap";
import { MOMENT_SETTING, SORTING_ORDER, Style } from "../../constant";
import DataNotFound from "../../DataNoteFound/DataNotFound";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import { TabelAnimation } from "../../Spinner";
import { MuiTabelStyle } from "../../utils/MuiTabelStyle";
import moment from "moment-timezone";
import SettingMiniWebContext from "../../context/SettingMiniWebContext/SettingMiniWebContext";
import { tableId } from "../../utils/helper";
import { WebNavBarConfigListDelete } from "../../api/SupperAdminOrganizationApi/Organization";
import { Zoom, toast } from "react-toastify";
import { Trash2 } from "react-feather";

const SettingMiniWebItemWebConfigPage = (props) => {
  const {
    pageNumberConfig,
    pageSizeConfig,
    sortOrderConfig,
    setPageNumberConfig,
    setPageSizeConfig,
    setSortOrderConfig,
    sortByConfig,
    formvalueConfig,
    recordConfig,
    setModalConfig,
    setToggleSubmitConfig,
  } = useContext(SettingMiniWebContext);

  const { tabelAnimation } = useContext(LoaderContext);
  const { WebConfigPage_List, orgId } = props;

  /**
   * Add Profile Function call
   */

  const AddProfile = () => {
    setToggleSubmitConfig(false);
    setModalConfig(true);
  };


  useMemo(() => {
    tableId(pageNumberConfig, pageSizeConfig, formvalueConfig);
  }, [pageNumberConfig, pageSizeConfig, formvalueConfig]);

  const handleDelete = async (pageId) => {
    let confirmation = window.confirm("Are you sure you want to delete?");
    if (confirmation) {
      try {
        let paramObj = {
          pageId,
        };
        const { data } = await WebNavBarConfigListDelete(paramObj);
        if (data.status === 200) {
          WebConfigPage_List(orgId);
          toast.success(data.message, {
            autoClose: 1000,
            transition: Zoom,
          });
        }
      } catch (error) {
        toast.error(error?.response?.data?.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    }
  };

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 100,
      field: "sNo",
      headerName: "S.No.",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      flex: 0.2,
      minWidth: 250,
      field: "contentMapDetails",
      headerName: "Content Map",
      renderCell: (params) => (
        <>
          {params.row?.contentMapDetail?.contentDetail
            ? params.row?.contentMapDetail?.contentDetail?.name
            : "-"}
        </>
      ),
    },
    {
      flex: 0.2,
      minWidth: 250,
      field: "nabMapDetail",
      headerName: "Nav Name",
      renderCell: (params) => (
        <>
          {params.row?.nabMapDetail?.navbarDetail
            ? params.row?.nabMapDetail?.navbarDetail?.label
            : "-"}
        </>
      ),
    },
    {
      flex: 0.2,
      minWidth: 250,
      field: "path",
      headerName: "Nav Path",
      renderCell: (params) => (
        <>
          {params.row?.nabMapDetail?.navbarDetail
            ? params.row?.nabMapDetail?.navbarDetail?.path
            : "-"}
        </>
      ),
    },

    {
      flex: 0.1,
      minWidth: 100,
      field: "createdAt",
      headerName: "Date",
      renderCell: (params) =>
        params.row.createdAt &&
        moment.utc(params.row.createdAt).format(MOMENT_SETTING.FORMATE),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "edit",
      headerName: "Edit",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <>
          <Tooltip title="Delete">
            <IconButton
              aria-label="edit"
              style={{ color: "red" }}
              onClick={() => handleDelete(params.row.pageId)}
            >
              <Trash2 />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  const sorting = (col) => {
    if (sortOrderConfig === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      sortByConfig(found.field);
      setSortOrderConfig(SORTING_ORDER.ASC);
    }
    if (sortOrderConfig === SORTING_ORDER.ASC) {
      let found = col.find((obj) => {
        return obj.field;
      });
      sortByConfig(found.field);
      setSortOrderConfig(SORTING_ORDER.DSC);
    }
    if (sortOrderConfig === SORTING_ORDER.DSC) {
      sortByConfig();
      setSortOrderConfig("");
    }
  };

  return (
    <Container fluid={true}>
      <Row>
        <Col sm="12">
          <Card>
            <CardHeader
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h3>{"Page Config"}</h3>
              <div
                aria-label="add"
                onClick={() => AddProfile()}
                className="btn btn-primary"
                style={Style}
              >
                <Add />
              </div>
            </CardHeader>
            {tabelAnimation === true ? (
              <TabelAnimation />
            ) : formvalueConfig.length ? (
              <DataGrid
                sx={MuiTabelStyle}
                autoHeight
                rows={formvalueConfig}
                getRowId={(row) => row.pageId}
                columns={defaultColumns}
                rowsPerPageOptions={[5, 10, 25, 50]}
                pagination
                rowCount={recordConfig}
                pageSize={pageSizeConfig}
                page={pageNumberConfig - 1}
                onPageSizeChange={(newPageSize) =>
                  setPageSizeConfig(newPageSize)
                }
                onPageChange={(newPageNo) => setPageNumberConfig(newPageNo + 1)}
                paginationMode="server"
                disableSelectionOnClick
                checkboxSelection={false}
                sortingMode="server"
                onSortModelChange={sorting}
              />
            ) : (
              <DataNotFound />
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SettingMiniWebItemWebConfigPage;
