import React, { useContext, useEffect } from "react";
import BrokerIncentiveItem from "./BrokerIncentiveItem";
import BrokerIncentiveAddModel from "./BrokerIncentiveAddModel";
import BrokerIncentiveContext from "../../context/BrokerIncentiveContext/BrokerIncentiveContext";
import { brokerIncentiveList } from "../../api/Organization Api/OrganizationApi";
import TopLoadingBar from "../../utils/topLoadingBar";

const BrokerIncentive = () => {
  // http://localhost:3001/param/BrokerPage/BrokerPage/QR_Web
  // http://localhost:3000/p0zxoH38/PersonalPage/PersonalPage/QR_Web

  const {
    setBrokerIncentiveLists,
    page,
    pageSize,
    setRecord,
    progress,
    setProgress,
    modal,
  } = useContext(BrokerIncentiveContext);

  const getBrokerIncentiveList = async () => {
    try {
      let formData = {
        pageNumber: page,
        recordsPerPage: pageSize,
      };
      const { data } = await brokerIncentiveList(formData);
      setBrokerIncentiveLists(data.payload.data);
      setRecord(data.pager.totalRecords);
    } catch (error) {}
  };

  useEffect(() => {
    getBrokerIncentiveList();
    setProgress(100);
  }, [page, pageSize]);

  return (
    <>
      <TopLoadingBar progress={progress} setProgress={setProgress} />
      {modal && (
        <BrokerIncentiveAddModel
          getBrokerIncentiveList={getBrokerIncentiveList}
        />
      )}

      <BrokerIncentiveItem getBrokerIncentiveList={getBrokerIncentiveList} />
    </>
  );
};

export default BrokerIncentive;
