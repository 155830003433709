import React, { useState } from "react";
import FaqsContext from "./FaqsContext";
import { randomStr } from "../../../utils/helper";

const FaqsState = (props) => {
  const [apiData, setApiData] = useState({ data: [], isLoading: false });
  const [apiDataOurTeam, setApiDataOurTeam] = useState({
    isLoading: false,
    data: [],
  });
  const [skill, setSkill] = useState([
    { id: randomStr(2), skill: "", parentageOfSkill: "" },
  ]);
  const [collId, setCollId] = useState();
  const [isBackgroundColor, setIsBackgroundColor] = useState(false);
  const [backImageToggle, setBackImageToggle] = useState(false);
  const [theme, setTheme] = useState();
  const [fromData, setFormData] = useState({
    title: "",
    subTitle: "",
    navMapId: "",
    slugUrl: "",
    buttonText: "",
    itemLimit: "",
    webId: localStorage.getItem("webId"),
    theme: 1,
    isTitleShow: true,
    style: {
      ".faqs-section": {
        padding: "100px 0",
        "background-color": "rgb(255 255 255 / 100)",
      },
      ".faqs-section.bg-image": {
        "background-image": "",
      },
      ".faqs-section.bg-image::before": {
        "background-color": "",
      },
      ".faqs-section .section-title-area": {
        "margin-bottom": "50px",
      },
      ".faqs-section .section-title-area .section-subtitle": {
        "font-size": "16px",
        "font-weight": "600",
        color: "rgb(255 73 124 / 100)",
        "background-color": "rgb(255 73 124 / 0.2)",
        padding: "5px 20px",
        p1: "5px",
        p2: "20px",
        "border-radius": "15px",
        "margin-bottom": "15px",
      },
      ".faqs-section .section-title-area .section-title": {
        "font-size": "44px",
        "font-weight": "600",
        color: "rgb(0 0 0 / 100)",
      },
      ".faq-wrapper": {
        "background-color": "rgb(255 255 255 / 100)",
        padding: "35px",
        "border-top": "3px solid rgb(255 73 124 / 100)",
        borderPx: "3px",
        borderThik: "solid",
        borderColor: "rgb(255 73 124 / 100)",
        "box-shadow": "0px 30px 70px 0px rgb(0 0 0 / 0.094)",
        bs1: "0px",
        bs2: "30px",
        bs3: "70px",
        bs4: "0px",
        shadowColor: "rgb(0 0 0 / 0.094)",
        "border-radius": "20px",
      },
      ".faq-wrapper .accordion-item": {
        "padding-top": "20px",
        "padding-bottom": "20px",
        border: "0px solid rgb(255 255 255 / 100)",
        borderPx: "0px",
        borderThik: "solid",
        borderColor: "rgb(255 255 255 / 100)",
        "border-bottom": "1px solid rgb(255 73 124 / 100)",
        borderBtPx: "1px",
        borderBtThik: "solid",
        borderBtColor: "rgb(255 73 124 / 100)",
      },
      ".faq-wrapper .accordion-item:last-child": {
        border: "0px solid rgb(255 255 255 / 100)",
        borderPx: "0px",
        borderThik: "solid",
        borderColor: "rgb(255 255 255 / 100)",
      },
      ".faq-wrapper .accordion-button": {
        outline: "0px solid rgb(255 255 255 / 100)",
        outPx: "0px",
        outThik: "solid",
        outColor: "rgb(255 255 255 / 100)",
        "box-shadow": "0px 0px 0px 0px rgb(0 0 0 / 0.094)",
        bs1: "0px",
        bs2: "0px",
        bs3: "0px",
        bs4: "0px",
        shadowColor: "rgb(0 0 0 / 0.094)",
        "font-weight": "500",
      },
      ".faq-wrapper .accordion-button:not(.collapsed)": {
        color: "rgb(255 255 255 / 100)",
        "background-color": "rgb(255 73 124 / 100)",
      },
      ".faq-wrapper .accordion-body": {
        "line-height": "1.4",
      },
      ".faqs-section .more-btn": {
        color: "rgb(255 255 255 / 100)",
        "font-size": "16px",
        "font-weight": "normal",
        "border-radius": "15px",
        padding: "5px 20px",
        "background-color": "rgb(255 73 124 / 100)",
      },
      ".faqs-section .more-btn:hover": {
        "background-color": "rgb(255 73 124 / 100)",
        color: "rgb(255 255 255 / 100)",
      },
      ".pagination-positions": {
        "justify-content": "center",
      },
      ".pagination .page-link": {
        color: "rgb(255 73 124 / 100)",
      },
      ".pagination .page-item.active .page-link": {
        "background-color": "rgb(255 73 124 / 100)",
        "border-color": "rgb(255 73 124 / 100)",
      },
      faqsId: "",
    },
  });

  const [formDataChild, setFormDataChild] = useState({
    title: "",
    subTitle: "",
    description: "",
    order: 1,
    isTitleShow: true,
    webId: localStorage.getItem("webId"),
    navMapId: "",
  });

  const [settingFormData, setSettingFormData] = useState({
    ".faqs-section": {
      padding: "100px 0",
      "background-color": "rgb(255 255 255 / 100)",
    },
    ".faqs-section.bg-image": {
      "background-image": "",
    },
    ".faqs-section.bg-image::before": {
      "background-color": "",
    },
    ".faqs-section .section-title-area": {
      "margin-bottom": "50px",
    },
    ".faqs-section .section-title-area .section-subtitle": {
      "font-size": "16px",
      "font-weight": "600",
      color: "rgb(255 73 124 / 100)",
      "background-color": "rgb(255 73 124 / 0.2)",
      padding: "5px 20px",
      p1: "5px",
      p2: "20px",
      "border-radius": "15px",
      "margin-bottom": "15px",
    },
    ".faqs-section .section-title-area .section-title": {
      "font-size": "44px",
      "font-weight": "600",
      color: "rgb(0 0 0 / 100)",
    },
    ".faq-wrapper": {
      "background-color": "rgb(255 255 255 / 100)",
      padding: "35px",
      "border-top": "3px solid rgb(255 73 124 / 100)",
      borderPx: "3px",
      borderThik: "solid",
      borderColor: "rgb(255 73 124 / 100)",
      "box-shadow": "0px 30px 70px 0px rgb(0 0 0 / 0.094)",
      bs1: "0px",
      bs2: "30px",
      bs3: "70px",
      bs4: "0px",
      shadowColor: "rgb(0 0 0 / 0.094)",
      "border-radius": "20px",
    },
    ".faq-wrapper .accordion-item": {
      "padding-top": "20px",
      "padding-bottom": "20px",
      border: "0px solid rgb(255 255 255 / 100)",
      borderPx: "0px",
      borderThik: "solid",
      borderColor: "rgb(255 255 255 / 100)",
      "border-bottom": "1px solid rgb(255 73 124 / 100)",
      borderBtPx: "1px",
      borderBtThik: "solid",
      borderBtColor: "rgb(255 73 124 / 100)",
    },
    ".faq-wrapper .accordion-item:last-child": {
      border: "0px solid rgb(255 255 255 / 100)",
      borderPx: "0px",
      borderThik: "solid",
      borderColor: "rgb(255 255 255 / 100)",
    },
    ".faq-wrapper .accordion-button": {
      outline: "0px solid rgb(255 255 255 / 100)",
      outPx: "0px",
      outThik: "solid",
      outColor: "rgb(255 255 255 / 100)",
      "box-shadow": "0px 0px 0px 0px rgb(0 0 0 / 0.094)",
      bs1: "0px",
      bs2: "0px",
      bs3: "0px",
      bs4: "0px",
      shadowColor: "rgb(0 0 0 / 0.094)",
      "font-weight": "500",
    },
    ".faq-wrapper .accordion-button:not(.collapsed)": {
      color: "rgb(255 255 255 / 100)",
      "background-color": "rgb(255 73 124 / 100)",
    },
    ".faq-wrapper .accordion-body": {
      "line-height": "1.4",
    },
    ".faqs-section .more-btn": {
      color: "rgb(255 255 255 / 100)",
      "font-size": "16px",
      "font-weight": "normal",
      "border-radius": "15px",
      padding: "5px 20px",
      "background-color": "rgb(255 73 124 / 100)",
    },
    ".faqs-section .more-btn:hover": {
      "background-color": "rgb(255 73 124 / 100)",
      color: "rgb(255 255 255 / 100)",
    },
    ".pagination-positions": {
      "justify-content": "center",
    },
    ".pagination .page-link": {
      color: "rgb(255 73 124 / 100)",
    },
    ".pagination .page-item.active .page-link": {
      "background-color": "rgb(255 73 124 / 100)",
      "border-color": "rgb(255 73 124 / 100)",
    },
    faqsId: "",
  });
  const [boxSection, setBoxSection] = useState(false);
  const [id, setId] = useState("");
  const [childId, setChildId] = useState("");
  const [navMapId, setNavMapId] = useState("");
  const [galleryImgId, setGalleryImgId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isParent, setIsParent] = useState(false);
  const [filter, setFilter] = useState("");
  const [backImageToggle2, setBackImageToggle2] = useState(false);
  const [isImageUpload, setIsImageUpload] = useState({
    isList: false,
    isDetails: false,
  });
  const [imgShow, setImgShow] = useState(false);
  const [sectionPageName, setSectionPageName] = useState("");
  return (
    <FaqsContext.Provider
      value={{
        apiData,
        setApiData,
        apiDataOurTeam,
        setApiDataOurTeam,
        fromData,
        setFormData,
        id,
        setId,
        isEdit,
        setIsEdit,
        filter,
        setFilter,
        skill,
        galleryImgId,
        setGalleryImgId,
        setSkill,
        settingFormData,
        setSettingFormData,
        formDataChild,
        setFormDataChild,
        isParent,
        setIsParent,
        childId,
        setChildId,
        navMapId,
        setNavMapId,
        backImageToggle,
        setBackImageToggle,
        boxSection,
        setBoxSection,
        collId,
        setCollId,
        isBackgroundColor,
        setIsBackgroundColor,
        theme,
        setTheme,
        backImageToggle2,
        setBackImageToggle2,
        isImageUpload,
        setIsImageUpload,
        imgShow,
        setImgShow,
        sectionPageName,
        setSectionPageName,
      }}
    >
      {props.children}
    </FaqsContext.Provider>
  );
};

export default FaqsState;
