import axios from "axios";
import {
  GET_PAYMENT,
  GET_PAYMENT_DETAILS,
  GET_PAYMENT_ITEMS,
  GET_PAYMENT_ITEMS_DETAILS,
  POST_PAYMENT,
  POST_PAYMENT_ITEMS,
  PUT_PAYMENT_ITEMS_ORDER,
  PUT_PAYMENT_ORDER,
  PUT_STATUS_PAYMENT,
  PUT_STATUS_PAYMENT_ITEMS,
} from "../../apiConstants";

/**
 * get payment items
 * @param {Parameters} paramObj
 * @returns
 */
export const getPaymentItems = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_PAYMENT_ITEMS}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * enable-disable payment items
 * @param {body} body
 * @returns
 */
export const updateStatus = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_STATUS_PAYMENT_ITEMS}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * create payment items
 * @param {body} body
 * @returns
 */
export const postPaymentItems = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${POST_PAYMENT_ITEMS}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * update payment items
 * @param {body} body
 * @returns
 */
export const putUpdatePaymentItems = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${POST_PAYMENT_ITEMS}`,
    body,
    {
      headers: { Authorization: jwt_token },
      params: { paymentItemId: body.paymentItemId },
    }
  );
};

/**
 * delete payment items
 * @param {id} paymentItemId
 * @returns
 */
export const deletePaymentItems = (paymentItemId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${POST_PAYMENT_ITEMS}`, {
    headers: { Authorization: jwt_token },
    params: { paymentItemId },
  });
};

/**
 * get details payment items
 * @param {id} teamId
 * @returns
 */
export const getPaymentItemsDetails = (paymentItemId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${GET_PAYMENT_ITEMS_DETAILS}`,
    {
      headers: { Authorization: jwt_token },
      params: { paymentItemId },
    }
  );
};

/**
 * short order payment items
 * @param {body} updateOrder
 * @returns
 */
export const sort_order_payment_items = (updateOrder) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_PAYMENT_ITEMS_ORDER}`,
    { updateOrder: updateOrder },
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * get payment data
 * @param {Parameters} paramObj
 * @returns
 */
export const getPayment = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_PAYMENT}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * enable-disable payment
 * @param {body} body
 * @returns
 */
export const updateStatusPayment = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_STATUS_PAYMENT}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * create payment
 * @param {body} body
 * @returns
 */
export const postPayment = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(`${process.env.REACT_APP_API_URL}${POST_PAYMENT}`, body, {
    headers: { Authorization: jwt_token },
  });
};

/**
 * update payment
 * @param {body} body
 * @returns
 */
export const putUpdatePayment = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(`${process.env.REACT_APP_API_URL}${POST_PAYMENT}`, body, {
    headers: { Authorization: jwt_token },
    params: { paymentId: body.paymentId },
  });
};

/**
 * delete payment
 * @param {id} paymentId
 * @returns
 */
export const deletePayment = (paymentId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${POST_PAYMENT}`, {
    headers: { Authorization: jwt_token },
    params: { paymentId },
  });
};

/**
 * get payment details
 * @param {id} paymentId
 * @returns
 */
export const getPaymentDetails = (paymentId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_PAYMENT_DETAILS}`, {
    headers: { Authorization: jwt_token },
    params: { paymentId },
  });
};

/**
 * short order payment
 * @param {body} updateOrder
 * @returns
 */
export const sort_order_payment = (updateOrder) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_PAYMENT_ORDER}`,
    { updateOrder: updateOrder },
    {
      headers: { Authorization: jwt_token },
    }
  );
};
