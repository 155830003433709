import React, { useContext, useEffect, useState } from "react";
import { getImagesParent } from "../apis/ImageApi/ImageApi";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import { Card, CardBody, Col, Row } from "reactstrap";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import { IconButton } from "@mui/material";
import { Cases, btnName } from "../../../constant";
import GalleryMultipageContext from "../../../context/GalleryMultipageContext/GalleryMultipageContext";
import { Boy } from "@mui/icons-material";
import { ArrowLeft, Settings, Trash } from "react-feather";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { CountUp } from "react-countup";

const ChildShow = ({
  handleChangeImageParent,
  handleEditImagesParent,
  EditIcon,
  handleDelete,
  handleChangeSettings,
  childId,
  contentMapId,
  emptyData,
  getImagesParentList,
  childData,
  setChildData,
  CountUp,
  setCountUp,
  setIdCopy,
  isBack,
}) => {
  const {
    setId,
    setCollId,
    setParentId,
    setFormDataImageParent,
    setIsChildForFormSubmit,
    id,
    setIsImageParent,
    ids,
    setIds,
  } = useContext(GalleryMultipageContext);
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );

  // const getImagesParentList = async (id, prev) => {
  //   try {
  //     let paramObj = {};
  //     if (prev === "prev") {
  //       paramObj["search"] = { parentId: ids[id] };
  //       let obj = { ...ids };
  //       if (ids[id]) {
  //         delete obj[id];
  //       }
  //       setIds(obj);
  //     } else {
  //       paramObj["search"] = { parentId: id || childId };
  //     }
  //     const { data } = await getImagesParent(paramObj);
  //     if (data.status === 200) {
  //       setChildData(data.payload.data);
  //     }
  //   } catch (error) {
  //     TostMessage({ message: error, type: "error" });
  //   }
  // };
  useEffect(() => {
    getImagesParentList({ isChild: true });
  }, [CountUp]);
  return (
    <div>
      <div className="container">
        {Object.keys(ids)?.length > 0 ? (
          <div className="mb-3 ">
            {" "}
            <IconButton
              edge="start"
              color="inherit"
              style={{ marginLeft: "5px" }}
              onClick={() => {
                getImagesParentList({
                  id: childData?.length
                    ? childData[0]?.parentId
                    : isBack[0]?.parentId,
                  prev: "prev",
                  isChild: true,
                });
                setIdCopy("");
              }}
            >
              <ArrowLeft />
            </IconButton>
          </div>
        ) : (
          ""
        )}
        <Row>
          {childData.length == 0 ? (
            <h2>No child found</h2>
          ) : (
            childData.map((elem) => {
              return (
                <Col xl="3 xl-50 box-col-6" md="6">
                  <Card className="ecommerce-widget ">
                    <CardBody className="support-ticket-font">
                      <div className="col-12">
                        <CardBuilderHeaderSection
                          name={elem?.title}
                          checked={elem?.isActive}
                          switchToggle={true}
                          isAdd={Object.keys(ids).length == 0 && true}
                          id={elem?.galleryId}
                          handleChange={() => {
                            handleChangeImageParent(
                              elem?.galleryId,
                              elem?.isActive,
                              true,
                              elem?.parentId
                            );
                            setCountUp((prev) => {
                              return prev + 1;
                            });
                          }}
                          openModal={() => {
                            OpenModalCommonFunc(
                              Cases.IMAGES_PARENT,
                              setAllPopupState
                            );
                            setIsChildForFormSubmit(true);
                            setIsImageParent(true);
                            setCollId(contentMapId);
                            setFormDataImageParent((prev) => {
                              return {
                                ...prev,
                                parentId: elem.galleryId,
                              };
                            });
                          }}
                        />
                        <OpenPopupBtn
                          openPopup={() => {
                            OpenModalCommonFunc(Cases.IMAGES, setAllPopupState);
                            setId(elem.galleryId);
                            emptyData();
                            setIsChildForFormSubmit(true);
                          }}
                          name={btnName.IMAGES}
                        />
                      </div>
                      <div className="d-flex justify-content-end ">
                        {elem?.galleryDetails?.length ? (
                          <IconButton
                            edge="start"
                            color="inherit"
                            style={{ marginLeft: "5px" }}
                            onClick={() => {
                              getImagesParentList({
                                id: elem?.galleryId,
                                isChild: true,
                              });
                              setIdCopy(elem?.galleryId);
                              setIds((prev) => {
                                return {
                                  ...prev,
                                  [elem?.galleryId]: elem?.parentId,
                                };
                              });
                            }}
                          >
                            <Boy />
                          </IconButton>
                        ) : (
                          ""
                        )}
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleEditImagesParent(
                              elem.galleryId,
                              elem.parentId ? true : false
                            );
                            setIsChildForFormSubmit(true);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleDelete(
                              elem.galleryId,
                              false,
                              childData.length == 1 ? true : false
                            );
                          }}
                        >
                          <Trash />
                        </IconButton>
                        {/* <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleChangeSettings(elem.galleryId);
                            setIsChildForFormSubmit(true);
                          }}
                        >
                          <Settings />
                        </IconButton> */}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              );
            })
          )}
        </Row>
      </div>
    </div>
  );
};

export default ChildShow;
