import React, { useState } from "react";
import BlogContext from "./BlogContext";
import { randomStr } from "../../../utils/helper";

const BlogState = (props) => {
  const [apiData, setApiData] = useState({ data: [], isLoading: false });
  const [apiDataOurTeam, setApiDataOurTeam] = useState({
    isLoading: false,
    data: [],
  });
  const [isBackgroundColor, setIsBackgroundColor] = useState(false);
  const [collId, setCollId] = useState();
  const [skill, setSkill] = useState([
    { id: randomStr(2), skill: "", parentageOfSkill: "" },
  ]);
  const [fromData, setFormData] = useState({
    title: "",
    shortDescription: "",
    description: "",
    image: "",
    slugUrl: "",
    isTitleShow: true,
    author: "",
    buttonText: "",
    buttonValue: "",
    webId: localStorage.getItem("webId"),
  });
  const [backImageToggle, setBackImageToggle] = useState(false);

  const [formDataOurTeam, setFormDataOurData] = useState({
    title: "",
    subTitle: "",
    theme: 1,
    navMapId: "",
    isTitleShow: true,
    buttonText: "",
    itemLimit: "",
    slugUrl: "",
    webId: localStorage.getItem("webId"),
    style: {
      ".blog-section": {
        padding: "100px 0",
        "background-color": "rgb(255 255 255 / 100)",
      },
      ".blog-section.bg-image": {
        "background-image": "",
      },
      ".blog-section.bg-image::before": {
        "background-color": "",
      },
      ".blog-section .section-title-area": {
        "margin-bottom": "50px",
      },
      ".blog-section .section-title-area .section-subtitle": {
        "font-size": "16px",
        "font-weight": "600",
        color: "rgb(255 73 124 / 100)",
        "background-color": "rgb(255 73 124 / 0.2)",
        padding: "5px 20px",
        p1: "5px",
        p2: "20px",
        "border-radius": "15px",
        "margin-bottom": "15px",
      },
      ".blog-section .section-title-area .section-title": {
        "font-size": "44px",
        "font-weight": "600",
        color: "rgb(0 0 0 / 100)",
      },
      ".blog_items .blog_thumb": {
        "border-radius": "5px",
        "margin-bottom": "20px",
      },
      ".blog_items .blog_thumb img": {
        "border-radius": "5px",
      },
      ".blog_items .blog_content .blog_meta": {
        "margin-bottom": "15px",
      },
      ".blog_items .blog_content .blog_meta .date": {
        color: "rgb(76 84 98 / 100)",
      },
      ".blog_items .blog_content .blog_meta .posted": {
        color: "rgb(255 73 124 / 100)",
        "font-size": "14px",
        "font-weight": "500",
        padding: "5px 10px",
        p1: "5px",
        p2: "10px",
        "border-radius": "5px",
        "background-color": "rgb(255 73 124 / 0.2)",
      },
      ".blog_items .blog_content .blog_title": {
        "font-size": "20px",
        "font-weight": "600",
        "margin-bottom": "15px",
      },
      ".blog_items .blog_content .blog_title a": {
        color: "rgb(0 0 0 / 100)",
      },
      ".blog_items .blog_content .blog_title a:hover": {
        color: "rgb(255 73 124 / 100)",
      },
      ".blog_items .blog_content .blog_desq": {
        color: "rgb(76 84 98 / 100)",
        "font-size": "16px",
        "font-weight": "400",
        "line-height": "1.6",
        "margin-bottom": "20px",
        "text-align": "justify",
      },
      ".blog_items .blog_content .link-btn": {
        color: "rgb(255 255 255 / 100)",
        "background-color": "rgb(255 73 124 / 100)",
      },
      ".blog-section .more-btn": {
        color: "#ffffff",
        "font-size": "16px",
        "font-weight": "normal",
        "border-radius": "15px",
        padding: "5px 20px",
        "background-color": "#ff497c",
      },
      ".blog-section .more-btn:hover": {
        "background-color": "#df2a5d",
        color: "#ffffff",
      },
      ".pagination-positions": {
        "justify-content": "center",
      },
      ".pagination .page-link": {
        color: "#ff497c",
      },
      ".pagination .page-item.active .page-link": {
        "background-color": "#ff497c",
        "border-color": "#ff497c",
      },
      "service-detail-section": {
        padding: "60px 0",
        "background-color": "#ffffff",
      },
      ".personal-details .details-title": {
        "font-weight": 600,
        "font-size": "24px",
      },
      ".personal-details p": {
        "margin-bottom": "20px",
        "line-height": 1.6,
        "font-size": "18px",
      },
      ".personal-details .info-list": {
        "margin-bottom": "30px",
      },
      ".personal-details .info-list li": {
        "margin-bottom": "15px",
        "font-size": "18px",
      },
      ".personal-details .order-list": {
        "margin-bottom": "30px",
      },
      ".personal-details .order-list li": {
        "padding-left": "25px",
        "margin-bottom": "15px",
        "font-size": "18px",
      },
      ".personal-details .order-list li:before": {
        "font-family": "FontAwesome",
        top: "3px",
        color: "rgb(76 84 98 / 100)",
      },
      ".side_thumb img": {
        height: "90px",
        width: "90px",
        "border-radius": "4px",
      },
      ".side_content .side_meta": {
        "font-size": "14px",
        color: "#6a727f",
        "font-weight": 600,
        "margin-bottom": "3px",
      },
      ".side_content .side_title": {
        "font-size": "16px",
        "font-weight": 700,
        color: "rgb(0 0 0 / 100)",
        "line-height": 1.5,
      },
      ".side_content .side_title a": {
        color: "rgb(0 0 0 / 100)",
      },
      blogCategoryId: "",
      showItem: 3,
    },
  });

  const [settingFormData, setSettingFormData] = useState({
    ".blog-section": {
      padding: "100px 0",
      "background-color": "rgb(255 255 255 / 100)",
    },
    ".blog-section.bg-image": {
      "background-image": "",
    },
    ".blog-section.bg-image::before": {
      "background-color": "",
      // "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
    },
    ".blog-section .section-title-area": {
      "margin-bottom": "50px",
    },
    ".blog-section .section-title-area .section-subtitle": {
      "font-size": "16px",
      "font-weight": "600",
      color: "rgb(255 73 124 / 100)",
      "background-color": "rgb(255 73 124 / 0.2)",
      padding: "5px 20px",
      p1: "5px",
      p2: "20px",
      "border-radius": "15px",
      "margin-bottom": "15px",
    },
    ".blog-section .section-title-area .section-title": {
      "font-size": "44px",
      "font-weight": "600",
      color: "rgb(0 0 0 / 100)",
    },
    ".blog_items .blog_thumb": {
      "border-radius": "5px",
      "margin-bottom": "20px",
    },
    ".blog_items .blog_thumb img": {
      "border-radius": "5px",
    },
    ".blog_items .blog_content .blog_meta": {
      "margin-bottom": "15px",
    },
    ".blog_items .blog_content .blog_meta .date": {
      color: "rgb(76 84 98 / 100)",
    },
    ".blog_items .blog_content .blog_meta .posted": {
      color: "rgb(255 73 124 / 100)",
      "font-size": "14px",
      "font-weight": "500",
      padding: "5px 10px",
      p1: "5px",
      p2: "10px",
      "border-radius": "5px",
      "background-color": "rgb(255 73 124 / 0.2)",
    },
    ".blog_items .blog_content .blog_title": {
      "font-size": "20px",
      "font-weight": "600",
      "margin-bottom": "15px",
    },
    ".blog_items .blog_content .blog_title a": {
      color: "rgb(0 0 0 / 100)",
    },
    ".blog_items .blog_content .blog_title a:hover": {
      color: "rgb(255 73 124 / 100)",
    },
    ".blog_items .blog_content .blog_desq": {
      color: "rgb(76 84 98 / 100)",
      "font-size": "16px",
      "font-weight": "400",
      "line-height": "1.6",
      "margin-bottom": "20px",
      "text-align": "justify",
    },
    ".blog_items .blog_content .link-btn": {
      color: "rgb(255 255 255 / 100)",
      "background-color": "rgb(255 73 124 / 100)",
    },
    ".more-btn": {
      color: "#ffffff",
      "font-size": "16px",
      "font-weight": "normal",
      "border-radius": "15px",
      padding: "5px 20px",
      "background-color": "#ff497c",
    },
    ".more-btn:hover": {
      "background-color": "#df2a5d",
      color: "#ffffff",
    },
    ".pagination-positions": {
      "justify-content": "center",
    },
    ".pagination .page-link": {
      color: "#ff497c",
    },
    ".pagination .page-item.active .page-link": {
      "background-color": "#ff497c",
      "border-color": "#ff497c",
    },
    "service-detail-section": {
      padding: "60px 0",
      "background-color": "#ffffff",
    },
    ".personal-details .details-title": {
      "font-weight": 600,
      "font-size": "24px",
    },
    ".personal-details p": {
      "margin-bottom": "20px",
      "line-height": 1.6,
      "font-size": "18px",
    },
    ".personal-details .info-list": {
      "margin-bottom": "30px",
    },
    ".personal-details .info-list li": {
      "margin-bottom": "15px",
      "font-size": "18px",
    },
    ".personal-details .order-list": {
      "margin-bottom": "30px",
    },
    ".personal-details .order-list li": {
      "padding-left": "25px",
      "margin-bottom": "15px",
      "font-size": "18px",
    },
    ".personal-details .order-list li:before": {
      "font-family": "FontAwesome",
      top: "3px",
      color: "rgb(76 84 98 / 100)",
    },
    ".side_thumb img": {
      height: "90px",
      width: "90px",
      "border-radius": "4px",
    },
    ".side_content .side_meta": {
      "font-size": "14px",
      color: "#6a727f",
      "font-weight": 600,
      "margin-bottom": "3px",
    },
    ".side_content .side_title": {
      "font-size": "16px",
      "font-weight": 700,
      color: "rgb(0 0 0 / 100)",
      "line-height": 1.5,
    },
    ".side_content .side_title a": {
      color: "rgb(0 0 0 / 100)",
    },
    blogCategoryId: "",
    showItem: 3,
  });
  const [boxSection, setBoxSection] = useState(false);
  const [isBackgroundColor2, setIsBackgroundColor2] = useState(false);
  const [id, setId] = useState("");
  const [boxSectionDetail, setBoxSectionDetail] = useState(false);
  const [backImageToggleDetail, setBackImageToggleDetail] = useState(false);
  const [teamMemberId, setTeamMemberId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [filter, setFilter] = useState("");
  const [isImageUpload, setIsImageUpload] = useState({
    isList: false,
    isDetails: false,
  });
  const [theme, setTheme] = useState(1);
  const [imgShow, setImgShow] = useState(false);
  const [sectionPageName, setSectionPageName] = useState("");
  return (
    <BlogContext.Provider
      value={{
        apiData,
        setApiData,
        apiDataOurTeam,
        setApiDataOurTeam,
        fromData,
        setFormData,
        formDataOurTeam,
        setFormDataOurData,
        id,
        setId,
        isEdit,
        setIsEdit,
        filter,
        setFilter,
        skill,
        teamMemberId,
        setTeamMemberId,
        setSkill,
        settingFormData,
        setSettingFormData,
        backImageToggle,
        setBackImageToggle,
        boxSection,
        setBoxSection,
        collId,
        setCollId,
        isBackgroundColor,
        setIsBackgroundColor,
        isBackgroundColor2,
        setIsBackgroundColor2,
        boxSectionDetail,
        setBoxSectionDetail,
        backImageToggleDetail,
        setBackImageToggleDetail,
        isImageUpload,
        setIsImageUpload,
        theme,
        setTheme,
        imgShow,
        setImgShow,
        sectionPageName,
        setSectionPageName,
      }}
    >
      {props.children}
    </BlogContext.Provider>
  );
};

export default BlogState;
