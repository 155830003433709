import React, { useState } from "react";
import HeaderContext from "./HeaderContext";

const HeaderState = (props) => {
  const [settingFormData, setSettingFormData] = useState({
    image: "",
    theme: "",
  });
  const [theme, setTheme] = useState(1);
  const [allStyle, setAllStyle] = useState({
    ".section-title-area .section-subtitle": {
      color: {
        r: 255,
        g: 73,
        b: 124,
        a: 1,
      },
      "background-color": {
        r: 191,
        g: 52,
        b: 105,
        a: 0.2,
      },
    },
    ".section-title-area .section-title": {
      color: {
        r: 0,
        g: 0,
        b: 0,
        a: 1,
      },
    },
    ".more-btn": {
      color: {
        r: 255,
        g: 255,
        b: 255,
        a: 1,
      },
      "background-color": {
        r: 255,
        g: 73,
        b: 124,
        a: 1,
      },
    },
    ".slick-arrow:before": {
      color: {
        r: 255,
        g: 73,
        b: 124,
        a: 1,
      },
    },
    ".slick-dots li.slick-active button:before": {
      color: {
        r: 255,
        g: 73,
        b: 124,
        a: 1,
      },
    },
    ".slick-dots li button:before": {
      color: {
        r: 255,
        g: 73,
        b: 124,
        a: 1,
      },
    },
  });
  const [isEdit, setIsEdit] = useState(false);
  const [isBackgroundColor, setIsBackgroundColor] = useState(false);
  const [isStyleChange, setIsStyleChange] = useState(false);
  const [imgShow, setImgShow] = useState(false);
  const [checkImageType, setCheckImageType] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  return (
    <HeaderContext.Provider
      value={{
        settingFormData,
        setSettingFormData,
        isEdit,
        setIsEdit,
        isBackgroundColor,
        setIsBackgroundColor,
        allStyle,
        setAllStyle,
        isStyleChange,
        setIsStyleChange,
        theme,
        setTheme,
        imgShow,
        setImgShow,
        checkImageType,
        setCheckImageType,
        isLoader,
        setIsLoader,
      }}
    >
      {props.children}
    </HeaderContext.Provider>
  );
};

export default HeaderState;
