import axios from "axios";
import {
  GET_TESTIMONIAL,
  GET_TESTIMONIAL_DETAILS,
  POST_TESTIMONIAL,
  PUT_STATUS_TESTIMONIAL,
  PUT_TESTIMONIAL_ORDER,
} from "..";

/**
 * get testimonial data
 * @param {Parameters} paramObj
 * @returns
 */
export const getTestimonial = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_TESTIMONIAL}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * enable-disable
 * @param {body} body
 * @returns
 */
export const updateStatus = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_STATUS_TESTIMONIAL}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * create testimonial
 * @param {body} body
 * @returns
 */
export const postTestimonial = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${POST_TESTIMONIAL}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * update testimonial
 * @param {body} body
 * @returns
 */
export const putUpdateTestimonial = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${POST_TESTIMONIAL}`,
    body,
    {
      headers: { Authorization: jwt_token },
      params: { testimonialId: body.testimonialId },
    }
  );
};

/**
 * delete testimonial
 * @param {id} testimonialId
 * @returns
 */
export const deleteTestimonial = (testimonialId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${POST_TESTIMONIAL}`, {
    headers: { Authorization: jwt_token },
    params: { testimonialId },
  });
};

/**
 * get testimonial details
 * @param {id} testimonialId
 * @returns
 */
export const getTestimonialDetails = (testimonialId, navMapId) => {
  const jwt_token = localStorage.getItem("token");
  let paramObj = {
    testimonialId,
  };
  if (navMapId) {
    paramObj["navMapId"] = navMapId;
  }
  return axios.get(
    `${process.env.REACT_APP_API_URL}${GET_TESTIMONIAL_DETAILS}`,
    {
      headers: { Authorization: jwt_token },
      params: paramObj,
    }
  );
};

/**
 * sort order testimonial
 * @param {body} updateOrder
 * @returns
 */
export const sort_order_Testimonial = (updateOrder) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_TESTIMONIAL_ORDER}`,
    { updateOrder: updateOrder },
    {
      headers: { Authorization: jwt_token },
    }
  );
};
