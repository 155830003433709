import React from "react";
import { Button } from "reactstrap";

const AddNewButton = ({ click }) => {
  return (
    <Button color="primary" className="rounded-0" onClick={click}>
      Add New
    </Button>
  );
};

export default AddNewButton;
