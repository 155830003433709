import axios from "axios";
import {
  ADD_PRODUCTS_LIST,
  ADD_TAGS_LIST,
  DELETE_PRODUCTS_LIST,
  DELETE_TAGS_LIST,
  DETAILS_PRODUCTS_LIST,
  DETAILS_TAGS_LIST,
  EDIT_PRODUCTS_LIST,
  EDIT_TAGS_LIST,
  MODULE_ACCESS_LIST,
  MODULE_LIST,
  ORG_MENU_PAGE_LIST_ENABLE_DESABLE,
  ORG_TAGS_MENU_PAGE_LIST_ENABLE_DESABLE,
  PRODUCTS_LIST,
  TAGS_LIST,
} from "../../../constant/API constant";

export const TagsList = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${TAGS_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const Add_Tags_List = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${ADD_TAGS_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Edit_Tags_List = (formData, id) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${EDIT_TAGS_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { tagMasterId: id },
    }
  );
};

export const Delete_Tags_List = (tagMasterId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${DELETE_TAGS_LIST}`, {
    headers: { Authorization: jwt_token },
    params: { tagMasterId },
  });
};

export const Ditails_Tags_List = (id) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${DETAILS_TAGS_LIST}`, {
    headers: { Authorization: jwt_token },
    params: { tagMasterId: id },
  });
};

export const Tags_MenuPageList_Enable_Desable = (formData, tagMasterId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${ORG_TAGS_MENU_PAGE_LIST_ENABLE_DESABLE}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { tagMasterId },
    }
  );
};
