import React, { useContext } from "react";
import { Col, Input, Label, Row } from "reactstrap";
import {
  Border,
  Float,
  TextAlign,
  fontWeight,
  textDecoration,
} from "../../../../constant";
import ProductBuyContext from "../../../context/ProductBuyContext/ProductBuyContext";
import CommonColor from "../../../common/CommonColor";
import CustomStyle from "../../../common/CustomStyle";
import { convertRGBA } from "../../../../utils/helper";

const ProductBuyThemeOne = (props) => {
  const { settingFormData, setSettingFormData } = useContext(ProductBuyContext);

  /**
   * input value change
   * @param {event} e
   */
  const handleChangeProductsSectionCatList = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".ecom-products-section .product-catlist"]: {
          ...prev[".ecom-products-section .product-catlist"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeProductsSectionCatListBtn = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".ecom-products-section .product-catlist .btn"]: {
          ...prev[".ecom-products-section .product-catlist .btn"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeProductsSectionCatDataBox = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".ecom-products-section .product-catdata .box"]: {
          ...prev[".ecom-products-section .product-catdata .box"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeProductsSectionCatDataImgTalLabel = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".ecom-products-section .product-catdata .product-img .taglabel"]: {
          ...prev[
            ".ecom-products-section .product-catdata .product-img .taglabel"
          ],
          [name]: value,
        },
      };
    });
  };
  const handleChangeProductsSectionCatDataImgPostDate = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".ecom-products-section .product-catdata .product-img .post-date"]: {
          ...prev[
            ".ecom-products-section .product-catdata .product-img .post-date"
          ],
          [name]: value,
        },
      };
    });
  };
  const handleChangeProductsSectionCatDataContent = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".ecom-products-section .product-catdata .product-content"]: {
          ...prev[".ecom-products-section .product-catdata .product-content"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeProductsSectionCatDataImgPostDateContentShopA = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".ecom-products-section .product-catdata .product-content .shop a"]: {
          ...prev[
            ".ecom-products-section .product-catdata .product-content .shop a"
          ],
          [name]: value,
        },
      };
    });
  };
  const handleChangeProductsSectionCatDataImgPostDateContentPrice = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".ecom-products-section .product-catdata .product-content .price"]: {
          ...prev[
            ".ecom-products-section .product-catdata .product-content .price"
          ],
          [name]: value,
        },
      };
    });
  };
  const handleChangeProductsSectionCatDataImgPostDateContentPriceDel = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".ecom-products-section .product-catdata .product-content .price del"]:
          {
            ...prev[
              ".ecom-products-section .product-catdata .product-content .price del"
            ],
            [name]: value,
          },
      };
    });
  };
  const handleChangeProductsSectionCatDataImgPostDateContentPriceIns = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".ecom-products-section .product-catdata .product-content .price ins"]:
          {
            ...prev[
              ".ecom-products-section .product-catdata .product-content .price ins"
            ],
            [name]: value,
          },
      };
    });
  };
  const handleChangeProductsSectionCatDataImgPostDateContentPriceAddCart = (
    e
  ) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".ecom-products-section .product-catdata .product-content .add-cart"]:
          {
            ...prev[
              ".ecom-products-section .product-catdata .product-content .add-cart"
            ],
            [name]: value,
          },
      };
    });
  };
  const handleChangeProductsSectionPriceAddCartHover = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".ecom-products-section .product-catdata .product-content .add-cart:hover"]:
          {
            ...prev[
              ".ecom-products-section .product-catdata .product-content .add-cart:hover"
            ],
            [name]: value,
          },
      };
    });
  };
  const moreBtnSection = (e) => {
    const { name, value } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".ecom-products-section .more-btn"]: {
          ...prev[".ecom-products-section .more-btn"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeCustomStyle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  return (
    <>
      <Row className="mt-4">
        <h4 className="mb-3">Make change in products section category list</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Text Align (Default : center)"}
          </Label>
          <select
            name="text-align"
            id="text-align"
            className="form-select"
            value={
              settingFormData?.[".ecom-products-section .product-catlist"]?.[
                "text-align"
              ]
            }
            onChange={(e) => handleChangeProductsSectionCatList(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {TextAlign.map((elem) => {
              return (
                <option value={elem["text-align"]}>{elem["text-align"]}</option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin bottom (Default : 30)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin bottom"
            value={settingFormData?.[
              ".ecom-products-section .product-catlist"
            ]?.["margin-bottom"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductsSectionCatList(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">
          Make change in products section category list button
        </h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Margin (Default : 5)"}</Label>
          <Input
            className="form-control"
            name="margin"
            type="number"
            placeholder="Margin"
            value={settingFormData?.[
              ".ecom-products-section .product-catlist .btn"
            ]?.["margin"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductsSectionCatListBtn(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border (Default : 2 solid pink)"}
          </Label>
          <div className="d-flex">
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="borderPx"
                type="number"
                placeholder="px"
                value={settingFormData?.[
                  ".ecom-products-section .product-catlist .btn"
                ]?.["borderPx"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeProductsSectionCatListBtn(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <select
                name="borderThik"
                id="borderThik"
                className="form-control"
                value={
                  settingFormData?.[
                    ".ecom-products-section .product-catlist .btn"
                  ]?.["borderThik"]
                }
                onChange={(e) => handleChangeProductsSectionCatListBtn(e)}
                style={{ borderRadius: "0", color: "black" }}
              >
                <option value="">--select--</option>
                {Border.map((elem) => {
                  return <option value={elem.border}>{elem.border}</option>;
                })}
              </select>
            </div>
            <div>
              <CommonColor
                color={
                  settingFormData?.[
                    ".ecom-products-section .product-catlist .btn"
                  ]?.["borderColor"]
                }
                setSettingFormData={setSettingFormData}
                className={".ecom-products-section .product-catlist .btn"}
                classProperty={"borderColor"}
                label={"Select Color"}
              />
            </div>
          </div>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".ecom-products-section .product-catlist .btn"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".ecom-products-section .product-catlist .btn"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 6 - 20)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="p1"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".ecom-products-section .product-catlist .btn"
              ]?.["p1"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeProductsSectionCatListBtn(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p2"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".ecom-products-section .product-catlist .btn"
              ]?.["p2"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeProductsSectionCatListBtn(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Margin (Default : 5)"}</Label>
          <Input
            className="form-control"
            name="margin"
            type="number"
            placeholder="margin"
            value={settingFormData?.[
              ".ecom-products-section .product-catlist .btn"
            ]?.["margin"]
              ?.split(" ")[0]
              ?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductsSectionCatListBtn(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">
          Make change in products section category list : active
        </h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".ecom-products-section .product-catlist .active"
              ]?.["background-color"]
            }
            setSettingFormData={setSettingFormData}
            className={".ecom-products-section .product-catlist .active"}
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".ecom-products-section .product-catlist .active"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".ecom-products-section .product-catlist .active"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">
          Make change in products section category data box
        </h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin bottom (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin bottom"
            value={settingFormData?.[
              ".ecom-products-section .product-catdata .box"
            ]?.["margin-bottom"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductsSectionCatDataBox(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".ecom-products-section .product-catdata .box"
              ]?.["background-color"]
            }
            setSettingFormData={setSettingFormData}
            className={".ecom-products-section .product-catdata .box"}
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
        <Col md="6 mb-3">
          <Label htmlFor="validationCustom03">
            {"Box Shadow 0 5 10 0 black"}
          </Label>
          <div className="d-flex ">
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="bs1"
                type="number"
                placeholder="px"
                value={settingFormData?.[
                  ".ecom-products-section .product-catdata .box"
                ]?.["bs1"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeProductsSectionCatDataBox(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="bs2"
                type="number"
                placeholder="px"
                value={settingFormData?.[
                  ".ecom-products-section .product-catdata .box"
                ]?.["bs2"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeProductsSectionCatDataBox(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="bs3"
                type="number"
                placeholder="px"
                value={settingFormData?.[
                  ".ecom-products-section .product-catdata .box"
                ]?.["bs3"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeProductsSectionCatDataBox(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="bs4"
                type="number"
                placeholder="px"
                value={settingFormData?.[
                  ".ecom-products-section .product-catdata .box"
                ]?.["bs4"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeProductsSectionCatDataBox(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div>
              <CommonColor
                color={
                  settingFormData?.[
                    ".ecom-products-section .product-catdata .box"
                  ]?.["shedowColor"]
                }
                setSettingFormData={setSettingFormData}
                className={".ecom-products-section .product-catdata .box"}
                classProperty={"shedowColor"}
                label={"Select Color"}
              />
            </div>
          </div>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border radius (Default : 0)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="border-radius"
            value={settingFormData?.[
              ".ecom-products-section .product-catdata .box"
            ]?.["border-radius"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductsSectionCatDataBox(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in product box image tag label</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".ecom-products-section .product-catdata .product-img .taglabel"
              ]?.["background-color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".ecom-products-section .product-catdata .product-img .taglabel"
            }
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".ecom-products-section .product-catdata .product-img .taglabel"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".ecom-products-section .product-catdata .product-img .taglabel"
            }
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 14)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".ecom-products-section .product-catdata .product-img .taglabel"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductsSectionCatDataImgTalLabel(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 400)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[
                ".ecom-products-section .product-catdata .product-img .taglabel"
              ]?.["font-weight"]
            }
            onChange={(e) => handleChangeProductsSectionCatDataImgTalLabel(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 8 - 15)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="p1"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".ecom-products-section .product-catdata .product-img .taglabel"
              ]?.["p1"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeProductsSectionCatDataImgTalLabel(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p2"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".ecom-products-section .product-catdata .product-img .taglabel"
              ]?.["p2"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeProductsSectionCatDataImgTalLabel(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border Radius (Default : 5)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[
              ".ecom-products-section .product-catdata .product-img .taglabel"
            ]?.["border-radius"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductsSectionCatDataImgTalLabel(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Top (Default : 10)"}</Label>
          <Input
            className="form-control"
            name="top"
            type="number"
            placeholder="top"
            value={settingFormData?.[
              ".ecom-products-section .product-catdata .product-img .taglabel"
            ]?.["top"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductsSectionCatDataImgTalLabel(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Left (Default : 10)"}</Label>
          <Input
            className="form-control"
            name="left"
            type="number"
            placeholder="left"
            value={settingFormData?.[
              ".ecom-products-section .product-catdata .product-img .taglabel"
            ]?.["left"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductsSectionCatDataImgTalLabel(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in product box post date</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".ecom-products-section .product-catdata .product-img .post-date"
              ]?.["background-color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".ecom-products-section .product-catdata .product-img .post-date"
            }
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border Radius (Default : 5)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[
              ".ecom-products-section .product-catdata .product-img .post-date"
            ]?.["border-radius"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductsSectionCatDataImgPostDate(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".ecom-products-section .product-catdata .product-img .post-date"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".ecom-products-section .product-catdata .product-img .post-date"
            }
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 14)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".ecom-products-section .product-catdata .product-img .post-date"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductsSectionCatDataImgPostDate(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 400)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[
                ".ecom-products-section .product-catdata .product-img .post-date"
              ]?.["font-weight"]
            }
            onChange={(e) => handleChangeProductsSectionCatDataImgPostDate(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 8 - 15)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="p1"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".ecom-products-section .product-catdata .product-img .post-date"
              ]?.["p1"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeProductsSectionCatDataImgPostDate(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p2"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".ecom-products-section .product-catdata .product-img .post-date"
              ]?.["p2"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeProductsSectionCatDataImgPostDate(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"text-align (Default : center)"}
          </Label>
          <select
            name="text-align"
            id="text-align"
            className="form-select"
            value={
              settingFormData?.[
                ".ecom-products-section .product-catdata .product-img .post-date"
              ]?.["text-align"]
            }
            onChange={(e) => handleChangeProductsSectionCatDataImgPostDate(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {TextAlign.map((elem) => {
              return (
                <option value={elem["text-align"]}>{elem["text-align"]}</option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Top (Default : 10)"}</Label>
          <Input
            className="form-control"
            name="top"
            type="number"
            placeholder="top"
            value={settingFormData?.[
              ".ecom-products-section .product-catdata .product-img .post-date"
            ]?.["top"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductsSectionCatDataImgPostDate(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Right (Default : 10)"}</Label>
          <Input
            className="form-control"
            name="right"
            type="number"
            placeholder="right"
            value={settingFormData?.[
              ".ecom-products-section .product-catdata .product-img .post-date"
            ]?.["right"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductsSectionCatDataImgPostDate(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in product box content</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Padding (Default : 20)"}</Label>
          <Input
            className="form-control"
            name="padding"
            type="number"
            placeholder="padding"
            value={settingFormData?.[
              ".ecom-products-section .product-catdata .product-content"
            ]?.["padding"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductsSectionCatDataContent(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"width (Default : 100)"}</Label>
          <Input
            className="form-control"
            name="width"
            type="number"
            placeholder="width"
            value={settingFormData?.[
              ".ecom-products-section .product-catdata .product-content"
            ]?.["width"]?.replace(/%/g, "")}
            onChange={(e) => handleChangeProductsSectionCatDataContent(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Float (Default : left)"}</Label>
          <select
            name="float"
            id="float"
            className="form-select"
            value={
              settingFormData?.[
                ".ecom-products-section .product-catdata .product-content"
              ]?.["float"]
            }
            onChange={(e) => handleChangeProductsSectionCatDataContent(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {Float.map((elem) => {
              return <option value={elem["float"]}>{elem["float"]}</option>;
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"text-align (Default : center)"}
          </Label>
          <select
            name="text-align"
            id="text-align"
            className="form-select"
            value={
              settingFormData?.[
                ".ecom-products-section .product-catdata .product-content"
              ]?.["text-align"]
            }
            onChange={(e) => handleChangeProductsSectionCatDataContent(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {TextAlign.map((elem) => {
              return (
                <option value={elem["text-align"]}>{elem["text-align"]}</option>
              );
            })}
          </select>
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in product box content shop link</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".ecom-products-section .product-catdata .product-content .shop a"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".ecom-products-section .product-catdata .product-content .shop a"
            }
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 20)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".ecom-products-section .product-catdata .product-content .shop a"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) =>
              handleChangeProductsSectionCatDataImgPostDateContentShopA(e)
            }
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">
          Make change in product box content shop link : hover
        </h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".ecom-products-section .product-catdata .product-content .shop a:hover"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".ecom-products-section .product-catdata .product-content .shop a:hover"
            }
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in product box content price</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".ecom-products-section .product-catdata .product-content .price"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".ecom-products-section .product-catdata .product-content .price"
            }
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin (Default : 15 5 10 5)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="m1"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".ecom-products-section .product-catdata .product-content .price"
              ]?.["m1"]?.replace(/px/g, "")}
              onChange={(e) =>
                handleChangeProductsSectionCatDataImgPostDateContentPrice(e)
              }
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="m2"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".ecom-products-section .product-catdata .product-content .price"
              ]?.["m2"]?.replace(/px/g, "")}
              onChange={(e) =>
                handleChangeProductsSectionCatDataImgPostDateContentPrice(e)
              }
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="m3"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".ecom-products-section .product-catdata .product-content .price"
              ]?.["m3"]?.replace(/px/g, "")}
              onChange={(e) =>
                handleChangeProductsSectionCatDataImgPostDateContentPrice(e)
              }
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="m4"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".ecom-products-section .product-catdata .product-content .price"
              ]?.["m4"]?.replace(/px/g, "")}
              onChange={(e) =>
                handleChangeProductsSectionCatDataImgPostDateContentPrice(e)
              }
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">
          Make change in product box content shop link price del
        </h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".ecom-products-section .product-catdata .product-content .price del"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".ecom-products-section .product-catdata .product-content .price del"
            }
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin Right (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="margin-right"
            type="number"
            placeholder="Margin Right"
            value={settingFormData?.[
              ".ecom-products-section .product-catdata .product-content .price del"
            ]?.["margin-right"]?.replace(/px/g, "")}
            onChange={(e) =>
              handleChangeProductsSectionCatDataImgPostDateContentPriceDel(e)
            }
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in product box price text</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".ecom-products-section .product-catdata .product-content .price ins"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".ecom-products-section .product-catdata .product-content .price ins"
            }
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Text Decoration (Default : none)"}
          </Label>
          <select
            name="text-decoration"
            id="text-decoration"
            className="form-select"
            value={
              settingFormData?.[
                ".ecom-products-section .product-catdata .product-content .price ins"
              ]?.["text-decoration"]
            }
            onChange={(e) =>
              handleChangeProductsSectionCatDataImgPostDateContentPriceIns(e)
            }
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {textDecoration.map((elem) => {
              return (
                <option value={elem["text-decoration"]}>
                  {elem["text-decoration"]}
                </option>
              );
            })}
          </select>
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in product box add cart</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".ecom-products-section .product-catdata .product-content .add-cart"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".ecom-products-section .product-catdata .product-content .add-cart"
            }
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>

        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border (Default : 2 solid pink)"}
          </Label>
          <div className="d-flex">
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="borderPx"
                type="number"
                placeholder="px"
                value={settingFormData?.[
                  ".ecom-products-section .product-catdata .product-content .add-cart"
                ]?.["borderPx"]?.replace(/px/g, "")}
                onChange={(e) =>
                  handleChangeProductsSectionCatDataImgPostDateContentPriceAddCart(
                    e
                  )
                }
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <select
                name="borderThik"
                id="borderThik"
                className="form-control"
                value={
                  settingFormData?.[
                    ".ecom-products-section .product-catdata .product-content .add-cart"
                  ]?.["borderThik"]
                }
                onChange={(e) =>
                  handleChangeProductsSectionCatDataImgPostDateContentPriceAddCart(
                    e
                  )
                }
                style={{ borderRadius: "0", color: "black" }}
              >
                <option value="">--select--</option>
                {Border.map((elem) => {
                  return <option value={elem.border}>{elem.border}</option>;
                })}
              </select>
            </div>
            <div>
              <CommonColor
                color={
                  settingFormData?.[
                    ".ecom-products-section .product-catdata .product-content .add-cart"
                  ]?.["borderColor"]
                }
                setSettingFormData={setSettingFormData}
                className={
                  ".ecom-products-section .product-catdata .product-content .add-cart"
                }
                classProperty={"borderColor"}
                label={"Select Color"}
              />
            </div>
          </div>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".ecom-products-section .product-catdata .product-content .add-cart"
              ]?.["background-color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".ecom-products-section .product-catdata .product-content .add-cart"
            }
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 14)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".ecom-products-section .product-catdata .product-content .add-cart"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) =>
              handleChangeProductsSectionCatDataImgPostDateContentPriceAddCart(
                e
              )
            }
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 500)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[
                ".ecom-products-section .product-catdata .product-content .add-cart"
              ]?.["font-weight"]
            }
            onChange={(e) =>
              handleChangeProductsSectionCatDataImgPostDateContentPriceAddCart(
                e
              )
            }
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 4 - 15)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="p1"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".ecom-products-section .product-catdata .product-content .add-cart"
              ]?.["p1"]?.replace(/px/g, "")}
              onChange={(e) =>
                handleChangeProductsSectionCatDataImgPostDateContentPriceAddCart(
                  e
                )
              }
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p2"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".ecom-products-section .product-catdata .product-content .add-cart"
              ]?.["p2"]?.replace(/px/g, "")}
              onChange={(e) =>
                handleChangeProductsSectionCatDataImgPostDateContentPriceAddCart(
                  e
                )
              }
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in product box add cart : hover</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Opacity (Default : 0.8)"}
          </Label>
          <Input
            className="form-control"
            name="opacity"
            type="number"
            placeholder="Opacity"
            value={settingFormData?.[
              ".ecom-products-section .product-catdata .product-content .add-cart:hover"
            ]?.["opacity"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductsSectionPriceAddCartHover(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">More button section</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={convertRGBA({
              rgbString:
                settingFormData?.[".ecom-products-section .more-btn"]?.[
                  "color"
                ],
            })}
            setSettingFormData={setSettingFormData}
            className={".ecom-products-section .more-btn"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 16)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[".ecom-products-section .more-btn"]?.[
              "font-size"
            ]?.replace(/px/g, "")}
            onChange={(e) => moreBtnSection(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : normal)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[".ecom-products-section .more-btn"]?.[
                "font-weight"
              ]
            }
            onChange={(e) => moreBtnSection(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]} key={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border Radius (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[".ecom-products-section .more-btn"]?.[
              "border-radius"
            ]?.replace(/px/g, "")}
            onChange={(e) => moreBtnSection(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 5 - 20)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="p1"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[".ecom-products-section .more-btn"]?.[
                "p1"
              ]?.replace(/px/g, "")}
              onChange={(e) => moreBtnSection(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p2"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[".ecom-products-section .more-btn"]?.[
                "p2"
              ]?.replace(/px/g, "")}
              onChange={(e) => moreBtnSection(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={convertRGBA({
              rgbString:
                settingFormData?.[".ecom-products-section .more-btn"]?.[
                  "background-color"
                ],
            })}
            setSettingFormData={setSettingFormData}
            className={".ecom-products-section .more-btn"}
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">change More button section on hover</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={convertRGBA({
              rgbString:
                settingFormData?.[".ecom-products-section .more-btn:hover"]?.[
                  "color"
                ],
            })}
            setSettingFormData={setSettingFormData}
            className={".ecom-products-section .more-btn:hover"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={convertRGBA({
              rgbString:
                settingFormData?.[".ecom-products-section .more-btn:hover"]?.[
                  "background-color"
                ],
            })}
            setSettingFormData={setSettingFormData}
            className={".ecom-products-section .more-btn:hover"}
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
      </Row>
      <CustomStyle
        handleChange={handleChangeCustomStyle}
        value={settingFormData?.custom}
      />
    </>
  );
};

export default ProductBuyThemeOne;
