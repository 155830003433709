import {
  addUrlImage,
  convertRGBA,
  getColorFromString,
  removeUrlImage,
  removeUrlKeyInBGI,
  rgbColorCreate,
} from "../../../../utils/helper";

export const ourTeamStyleOneReset = {
  ".team-section": {
    padding: "100px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".team-section.bg-image": {},
  ".team-section.bg-image::before": {
    // "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
  },
  ".team-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".team-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: { r: 255, g: 73, b: 124, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 0.2 },
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".team-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: "#000000",
  },
  ".team-section .member-data": {
    margin: "10px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
    "box-shadow": { r: 7, g: 28, b: 31, a: 0.098 },
    bs1: "0",
    bs2: "5px",
    bs3: "10px",
    bs4: "0",
    shadowColor: { r: 7, g: 28, b: 31, a: 0.098 },
    "border-radius": "0px",
  },
  ".team-section .member-data .member-img": {
    "background-color": { r: 7, g: 28, b: 31, a: 0.098 },
  },
  ".team-section .member-data .member-info": {
    padding: "30px 15px",
    p1: "30px",
    p2: "15px",
  },
  ".team-section .member-data .name a": {
    color: { r: 0, g: 0, b: 0, a: 100 },
    "font-size": "20px",
    "font-weight": "500",
  },
  ".team-section .member-data .name a:hover": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".team-section .member-data .position": {
    color: { r: 255, g: 73, b: 124, a: 100 },
    "font-size": "18px",
    "font-weight": "600",
    margin: "10px 0",
  },
  ".team-section .member-data .socia-list a": {
    padding: "8px",
    margin: "1px",
    "text-align": "center",
    color: { r: 102, g: 102, b: 102, a: 100 },
    "font-size": "20px",
    border: "2px solid { r: 255, g: 73, b: 124, a: 100 }",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: { r: 255, g: 73, b: 124, a: 100 },
    "border-radius": "20px",
  },
  ".team-section .member-data .socia-list a:hover": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    "border-color": { r: 255, g: 73, b: 124, a: 100 },
  },

  ".team-detail-section": {
    padding: "100px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".team-detail-section.bg-image": {},
  ".team-detail-section.bg-image::before": {
    // "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
  },
  ".team-detail-section .single-team-img": {
    "background-color": { r: 244, g: 244, b: 245, a: 100 },
    "margin-bottom": "20px",
  },
  ".team-detail-section .team-member-info .team-title": {
    "font-weight": 600,
    "font-size": "24px",
  },
  ".team-detail-section .team-member-info .designation": {
    color: { r: 164, g: 164, b: 170, a: 100 },
  },
  ".team-detail-section .team-member-info .social-list a": {
    padding: "9px",
    margin: "1px",
    width: "40px",
    height: "40px",
    "text-align": "center",
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "20px",
    "border-radius": "20px",
    "background-color": { r: 31, g: 39, b: 50, a: 100 },
  },
  ".team-detail-section .team-member-info .social-list a:hover": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".personal-details .details-title": {
    "font-weight": 600,
    "font-size": "24px",
  },
  ".personal-details p": {
    "margin-bottom": "20px",
    "line-height": "1.6",
    "font-size": "18px",
  },
  ".personal-details .info-list": {
    "margin-bottom": "30px",
  },
  ".personal-details .info-list li": {
    "margin-bottom": "15px",
    "font-size": "18px",
  },
  ".personal-details .order-list": {
    "margin-bottom": "30px",
  },
  ".personal-details .order-list li": {
    "padding-left": "25px",
    "margin-bottom": "15px",
    "font-size": "18px",
  },
  ".personal-details .order-list li:before": {
    "font-family": "FontAwesome",
    top: "3px",
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".team-section .more-btn": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".team-section .more-btn:hover": {
    "background-color": { r: 223, g: 42, b: 93, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".pagination .page-item.active .page-link": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    "border-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  custom: "",
};

export const ourTeamStyleTwoReset = {
  ".ourteam-section": {
    padding: "100px 0",
    "background-color": { r: 249, g: 249, b: 249, a: 100 },
  },
  ".ourteam-section.bg-image": {},
  ".ourteam-section.bg-image::before": {
    // "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
  },
  ".ourteam-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".ourteam-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: { r: 255, g: 73, b: 124, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 0.2 },
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".ourteam-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: { r: 0, g: 0, b: 0, a: 100 },
  },
  ".ourteam-section .member-data": {
    border: "10px solid rgb(255 255 255 / 100)",
    borderPx: "10px",
    borderThik: "solid",
    borderColor: { r: 255, g: 255, b: 255, a: 100 },
    margin: "10px 0",
    "border-radius": "5px",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
    "box-shadow": "0 5px 10px 0 rgba(7, 28, 31, 0.1)",
    bs1: "0px",
    bs2: "5px",
    bs3: "10px",
    bs4: "0px",
    shadowColor: { r: 7, g: 28, b: 31, a: 0.1 },
  },
  ".ourteam-section .member-data:hover": {
    "border-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".ourteam-section .member-data .member-img": {
    "border-radius": "5px",
    "background-color": { r: 238, g: 238, b: 238, a: 100 },
  },
  ".ourteam-section .member-data .member-info": {
    padding: "30px 15px",
    p1: "30px",
    p2: "15px",
  },
  ".ourteam-section .member-data .name a": {
    color: { r: 0, g: 0, b: 0, a: 100 },
    "font-size": "20px",
    "font-weight": "600",
  },
  ".ourteam-section .member-data .position": {
    color: { r: 153, g: 153, b: 153, a: 100 },
    "font-size": "18px",
    "font-weight": "500",
    margin: "10px 0",
  },
  ".ourteam-section .member-data .socia-list a": {
    padding: "8px",
    margin: "1px",
    width: "40px",
    height: "40px",
    color: { r: 102, g: 102, b: 102, a: 100 },
    "font-size": "20px",
    border: "2px solid rgb(238 238 238 / 100)",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: { r: 238, g: 238, b: 238, a: 100 },
    "border-radius": "20px",
  },
  ".ourteam-section .member-data .socia-list a:hover": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    "border-color": { r: 255, g: 73, b: 124, a: 100 },
  },

  ".team-detail-section": {
    padding: "100px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".team-detail-section.bg-image": {},
  ".team-detail-section.bg-image::before": {
    // "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
  },
  ".team-detail-section .single-team-img": {
    "background-color": { r: 244, g: 244, b: 245, a: 100 },
    "margin-bottom": "20px",
  },
  ".team-detail-section .team-member-info .team-title": {
    "font-weight": 600,
    "font-size": "24px",
  },
  ".team-detail-section .team-member-info .designation": {
    color: { r: 164, g: 164, b: 170, a: 100 },
  },
  ".team-detail-section .team-member-info .social-list a": {
    padding: "9px",
    margin: "1px",
    width: "40px",
    height: "40px",
    "text-align": "center",
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "20px",
    "border-radius": "20px",
    "background-color": { r: 31, g: 39, b: 50, a: 100 },
  },
  ".team-detail-section .team-member-info .social-list a:hover": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".personal-details .details-title": {
    "font-weight": 600,
    "font-size": "24px",
  },
  ".personal-details p": {
    "margin-bottom": "20px",
    "line-height": "1.6",
    "font-size": "18px",
  },
  ".personal-details .info-list": {
    "margin-bottom": "30px",
  },
  ".personal-details .info-list li": {
    "margin-bottom": "15px",
    "font-size": "18px",
  },
  ".personal-details .order-list": {
    "margin-bottom": "30px",
  },
  ".personal-details .order-list li": {
    "padding-left": "25px",
    "margin-bottom": "15px",
    "font-size": "18px",
  },
  ".personal-details .order-list li:before": {
    "font-family": "FontAwesome",
    top: "3px",
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".ourteam-section .more-btn": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".ourteam-section .more-btn:hover": {
    "background-color": { r: 223, g: 42, b: 93, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".pagination .page-item.active .page-link": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    "border-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  custom: "",
};

export const OurTeamThemeOneFunction = (payload) => {
  return {
    ".team-section .section-title-area": {
      "margin-bottom":
        payload.style?.[".team-section .section-title-area"]?.["margin-bottom"],
    },
    ".team-section .section-title-area .section-subtitle": {
      "font-size":
        payload.style?.[
          ".team-section .section-title-area .section-subtitle"
        ]?.["font-size"],
      "font-weight":
        payload.style?.[
          ".team-section .section-title-area .section-subtitle"
        ]?.["font-weight"],
      color: payload.style?.[
        ".team-section .section-title-area .section-subtitle"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".team-section .section-title-area .section-subtitle"
              ]?.["color"],
          })
        : "",
      "background-color": payload.style?.[
        ".team-section .section-title-area .section-subtitle"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".team-section .section-title-area .section-subtitle"
              ]?.["background-color"],
          })
        : "",
      padding:
        payload.style?.[
          ".team-section .section-title-area .section-subtitle"
        ]?.["padding"],
      p1: payload.style?.[
        ".team-section .section-title-area .section-subtitle"
      ]?.["padding"]?.split(" ")[0],
      p2: payload.style?.[
        ".team-section .section-title-area .section-subtitle"
      ]?.["padding"]?.split(" ")[1],
      "border-radius":
        payload.style?.[
          ".team-section .section-title-area .section-subtitle"
        ]?.["border-radius"],
      "margin-bottom":
        payload.style?.[
          ".team-section .section-title-area .section-subtitle"
        ]?.["margin-bottom"],
    },
    ".team-section .section-title-area .section-title": {
      "font-size":
        payload.style?.[".team-section .section-title-area .section-title"]?.[
          "font-size"
        ],
      "font-weight":
        payload.style?.[".team-section .section-title-area .section-title"]?.[
          "font-weight"
        ],
      color: payload.style?.[
        ".team-section .section-title-area .section-title"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".team-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".team-section .member-data": {
      margin: payload.style?.[".team-section .member-data"]?.["margin"],
      "background-color": payload.style?.[".team-section .member-data"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".team-section .member-data"]?.[
                "background-color"
              ],
          })
        : "",
      "box-shadow":
        payload.style?.[".team-section .member-data"]?.["box-shadow"],
      bs1: payload.style?.[".team-section .member-data"]?.["box-shadow"]?.split(
        " "
      )[0],
      bs2: payload.style?.[".team-section .member-data"]?.["box-shadow"]?.split(
        " "
      )[1],
      bs3: payload.style?.[".team-section .member-data"]?.["box-shadow"]?.split(
        " "
      )[2],
      bs4: payload.style?.[".team-section .member-data"]?.["box-shadow"]?.split(
        " "
      )[3],
      shadowColor: convertRGBA({
        rgbString: getColorFromString({
          colorString:
            payload.style?.[".team-section .member-data"]?.["box-shadow"],
        }),
      }),
      "border-radius":
        payload.style?.[".team-section .member-data"]?.["border-radius"],
    },
    ".team-section .member-data .member-img": {
      "background-color": payload.style?.[
        ".team-section .member-data .member-img"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".team-section .member-data .member-img"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".team-section .member-data .member-info": {
      padding:
        payload.style?.[".team-section .member-data .member-info"]?.["padding"],
      p1: payload.style?.[".team-section .member-data .member-info"]?.[
        "padding"
      ]?.split(" ")[0],
      p2: payload.style?.[".team-section .member-data .member-info"]?.[
        "padding"
      ]?.split(" ")[1],
    },
    ".team-section .member-data .name a": {
      color: payload.style?.[".team-section .member-data .name a"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".team-section .member-data .name a"]?.["color"],
          })
        : "",
      "font-size":
        payload.style?.[".team-section .member-data .name a"]?.["font-size"],
      "font-weight":
        payload.style?.[".team-section .member-data .name a"]?.["font-weight"],
    },
    ".team-section .member-data .name a:hover": {
      color: payload.style?.[".team-section .member-data .name a:hover"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".team-section .member-data .name a:hover"]?.[
                "color"
              ],
          })
        : "",
    },
    ".team-section .member-data .position": {
      color: payload.style?.[".team-section .member-data .position"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".team-section .member-data .position"]?.[
                "color"
              ],
          })
        : "",
      "font-size":
        payload.style?.[".team-section .member-data .position"]?.["font-size"],
      "font-weight":
        payload.style?.[".team-section .member-data .position"]?.[
          "font-weight"
        ],
      margin:
        payload.style?.[".team-section .member-data .position"]?.["margin"],
    },
    ".team-section .member-data .socia-list a": {
      padding:
        payload.style?.[".team-section .member-data .socia-list a"]?.[
          "padding"
        ],
      margin:
        payload.style?.[".team-section .member-data .socia-list a"]?.["margin"],
      "text-align":
        payload.style?.[".team-section .member-data .socia-list a"]?.[
          "text-align"
        ],
      color: payload.style?.[".team-section .member-data .socia-list a"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".team-section .member-data .socia-list a"]?.[
                "color"
              ],
          })
        : "",
      "font-size":
        payload.style?.[".team-section .member-data .socia-list a"]?.[
          "font-size"
        ],
      border:
        payload.style?.[".team-section .member-data .socia-list a"]?.["border"],
      borderPx:
        payload.style?.[".team-section .member-data .socia-list a"]?.[
          "border"
        ]?.split(" ")[0],
      borderThik:
        payload.style?.[".team-section .member-data .socia-list a"]?.[
          "border"
        ]?.split(" ")[1],
      borderColor: convertRGBA({
        rgbString: getColorFromString({
          colorString:
            payload.style?.[".team-section .member-data .socia-list a"]?.[
              "border"
            ],
        }),
      }),
      "border-radius":
        payload.style?.[".team-section .member-data .socia-list a"]?.[
          "border-radius"
        ],
    },
    ".team-section .member-data .socia-list a:hover": {
      color: payload.style?.[
        ".team-section .member-data .socia-list a:hover"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".team-section .member-data .socia-list a:hover"
              ]?.["color"],
          })
        : "",
      "background-color": payload.style?.[
        ".team-section .member-data .socia-list a:hover"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".team-section .member-data .socia-list a:hover"
              ]?.["background-color"],
          })
        : "",
      "border-color": payload.style?.[
        ".team-section .member-data .socia-list a:hover"
      ]?.["border-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".team-section .member-data .socia-list a:hover"
              ]?.["border-color"],
          })
        : "",
    },

    ".team-detail-section":
      payload.style?.[".team-detail-section"]?.["width"] ||
      payload.style?.[".team-detail-section"]?.["margin"] ||
      payload.style?.[".team-detail-section"]?.["border-radius"]
        ? {
            padding: payload.style?.[".team-detail-section"]?.["padding"],
            "background-color": payload.style?.[".team-detail-section"]?.[
              "background-color"
            ]
              ? convertRGBA({
                  rgbString:
                    payload.style?.[".team-detail-section"]?.[
                      "background-color"
                    ],
                })
              : "",
            width: payload.style?.[".team-detail-section"]?.["width"]?.replace(
              "calc(100% - ",
              ""
            ),
            margin: payload.style?.[".team-detail-section"]?.["margin"],
            "border-radius":
              payload.style?.[".team-detail-section"]?.["border-radius"],
          }
        : {
            padding: payload.style?.[".team-detail-section"]?.["padding"],
            "background-color": payload.style?.[".team-detail-section"]?.[
              "background-color"
            ]
              ? convertRGBA({
                  rgbString:
                    payload.style?.[".team-detail-section"]?.[
                      "background-color"
                    ],
                })
              : "",
          },
    ".team-detail-section.bg-image": payload.style?.[
      ".team-detail-section.bg-image"
    ]?.["background-image"]
      ? {
          "background-image": payload.style?.[
            ".team-detail-section.bg-image"
          ]?.["background-image"]
            ? removeUrlKeyInBGI({
                urlString:
                  payload.style?.[".team-detail-section.bg-image"]?.[
                    "background-image"
                  ],
              })
            : "",
        }
      : {},
    ".team-detail-section.bg-image::before": payload.style?.[
      ".team-detail-section.bg-image::before"
    ]?.["background-color"]
      ? {
          "background-color": payload.style?.[
            ".team-detail-section.bg-image::before"
          ]?.["background-color"]
            ? convertRGBA({
                rgbString:
                  payload.style?.[".team-detail-section.bg-image::before"]?.[
                    "background-color"
                  ],
              })
            : "",
        }
      : {},
    ".team-detail-section .single-team-img": {
      "background-color": payload.style?.[
        ".team-detail-section .single-team-img"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".team-detail-section .single-team-img"]?.[
                "background-color"
              ],
          })
        : "",
      "margin-bottom":
        payload.style?.[".team-detail-section .single-team-img"]?.[
          "margin-bottom"
        ],
    },
    ".team-detail-section .team-member-info .team-title": {
      "font-weight":
        payload.style?.[".team-detail-section .team-member-info .team-title"]?.[
          "font-weight"
        ],
      "font-size":
        payload.style?.[".team-detail-section .team-member-info .team-title"]?.[
          "font-size"
        ],
    },
    ".team-detail-section .team-member-info .designation": {
      color: payload.style?.[
        ".team-detail-section .team-member-info .designation"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".team-detail-section .team-member-info .designation"
              ]?.["color"],
          })
        : "",
    },
    ".team-detail-section .team-member-info .social-list a": {
      padding:
        payload.style?.[
          ".team-detail-section .team-member-info .social-list a"
        ]?.["padding"],
      margin:
        payload.style?.[
          ".team-detail-section .team-member-info .social-list a"
        ]?.["margin"],
      width:
        payload.style?.[
          ".team-detail-section .team-member-info .social-list a"
        ]?.["width"],
      height:
        payload.style?.[
          ".team-detail-section .team-member-info .social-list a"
        ]?.["height"],
      "text-align":
        payload.style?.[
          ".team-detail-section .team-member-info .social-list a"
        ]?.["text-align"],
      color: payload.style?.[
        ".team-detail-section .team-member-info .social-list a"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".team-detail-section .team-member-info .social-list a"
              ]?.["color"],
          })
        : "",
      "font-size":
        payload.style?.[
          ".team-detail-section .team-member-info .social-list a"
        ]?.["font-size"],
      "border-radius":
        payload.style?.[
          ".team-detail-section .team-member-info .social-list a"
        ]?.["border-radius"],
      "background-color": payload.style?.[
        ".team-detail-section .team-member-info .social-list a"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".team-detail-section .team-member-info .social-list a"
              ]?.["background-color"],
          })
        : "",
    },
    ".team-detail-section .team-member-info .social-list a:hover": {
      color: payload.style?.[
        ".team-detail-section .team-member-info .social-list a:hover"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".team-detail-section .team-member-info .social-list a:hover"
              ]?.["color"],
          })
        : "",
      "background-color": payload.style?.[
        ".team-detail-section .team-member-info .social-list a:hover"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".team-detail-section .team-member-info .social-list a:hover"
              ]?.["background-color"],
          })
        : "",
    },
    ".personal-details .details-title": {
      "font-weight":
        payload.style?.[".personal-details .details-title"]?.["font-weight"],
      "font-size":
        payload.style?.[".personal-details .details-title"]?.["font-size"],
    },
    ".personal-details p": {
      "margin-bottom":
        payload.style?.[".personal-details p"]?.["margin-bottom"],
      "line-height": payload.style?.[".personal-details p"]?.["line-height"],
      "font-size": payload.style?.[".personal-details p"]?.["font-size"],
    },
  };
};
export const OurTeamThemeTwoFunction = (payload) => {
  return {
    ".ourteam-section .section-title-area": {
      "margin-bottom":
        payload.style?.[".ourteam-section .section-title-area"]?.[
          "margin-bottom"
        ],
    },
    ".ourteam-section .section-title-area .section-subtitle": {
      "font-size":
        payload.style?.[
          ".ourteam-section .section-title-area .section-subtitle"
        ]?.["font-size"],
      "font-weight":
        payload.style?.[
          ".ourteam-section .section-title-area .section-subtitle"
        ]?.["font-weight"],
      color: payload.style?.[
        ".ourteam-section .section-title-area .section-subtitle"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".ourteam-section .section-title-area .section-subtitle"
              ]?.["color"],
          })
        : "",
      "background-color": payload.style?.[
        ".ourteam-section .section-title-area .section-subtitle"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".ourteam-section .section-title-area .section-subtitle"
              ]?.["background-color"],
          })
        : "",
      padding:
        payload.style?.[
          ".ourteam-section .section-title-area .section-subtitle"
        ]?.["padding"],
      p1: payload.style?.[
        ".ourteam-section .section-title-area .section-subtitle"
      ]?.["padding"]?.split(" ")[0],
      p2: payload.style?.[
        ".ourteam-section .section-title-area .section-subtitle"
      ]?.["padding"]?.split(" ")[1],
      "border-radius":
        payload.style?.[
          ".ourteam-section .section-title-area .section-subtitle"
        ]?.["border-radius"],
      "margin-bottom":
        payload.style?.[
          ".ourteam-section .section-title-area .section-subtitle"
        ]?.["margin-bottom"],
    },
    ".ourteam-section .section-title-area .section-title": {
      "font-size":
        payload.style?.[
          ".ourteam-section .section-title-area .section-title"
        ]?.["font-size"],
      "font-weight":
        payload.style?.[
          ".ourteam-section .section-title-area .section-title"
        ]?.["font-weight"],
      color: payload.style?.[
        ".ourteam-section .section-title-area .section-title"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".ourteam-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".ourteam-section .member-data": {
      margin: payload.style?.[".ourteam-section .member-data"]?.["margin"],
      "background-color": payload.style?.[".ourteam-section .member-data"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".ourteam-section .member-data"]?.[
                "background-color"
              ],
          })
        : "",
      "box-shadow":
        payload.style?.[".ourteam-section .member-data"]?.["box-shadow"],
      bs1: payload.style?.[".ourteam-section .member-data"]?.["bs1"],
      bs2: payload.style?.[".ourteam-section .member-data"]?.["bs2"],
      bs3: payload.style?.[".ourteam-section .member-data"]?.["bs3"],
      bs4: payload.style?.[".ourteam-section .member-data"]?.["bs4"],
      shadowColor: payload.style?.[".ourteam-section .member-data"]?.[
        "shadowColor"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".ourteam-section .member-data"]?.["shadowColor"],
          })
        : "",
      border: payload.style?.[".ourteam-section .member-data"]?.["border"],
      borderPx: payload.style?.[".ourteam-section .member-data"]?.["borderPx"],
      borderThik:
        payload.style?.[".ourteam-section .member-data"]?.["borderThik"],
      borderColor: payload.style?.[".ourteam-section .member-data"]?.[
        "borderColor"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".ourteam-section .member-data"]?.["borderColor"],
          })
        : "",
      "border-radius":
        payload.style?.[".ourteam-section .member-data"]?.["border-radius"],
    },
    ".ourteam-section .member-data:hover": {
      "border-color": payload.style?.[".ourteam-section .member-data:hover"]?.[
        "border-color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".ourteam-section .member-data:hover"]?.[
                "border-color"
              ],
          })
        : "",
    },
    ".ourteam-section .member-data .member-img": {
      "border-radius":
        payload.style?.[".ourteam-section .member-data .member-img"]?.[
          "border-radius"
        ],
      "background-color": payload.style?.[
        ".ourteam-section .member-data .member-img"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".ourteam-section .member-data .member-img"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".ourteam-section .member-data .member-info": {
      padding:
        payload.style?.[".ourteam-section .member-data .member-info"]?.[
          "padding"
        ],
      p1: payload.style?.[".ourteam-section .member-data .member-info"]?.[
        "padding"
      ]?.split(" ")[0],
      p2: payload.style?.[".ourteam-section .member-data .member-info"]?.[
        "padding"
      ]?.split(" ")[1],
    },
    ".ourteam-section .member-data .name a": {
      color: payload.style?.[".ourteam-section .member-data .name a"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".ourteam-section .member-data .name a"]?.[
                "color"
              ],
          })
        : "",
      "font-size":
        payload.style?.[".ourteam-section .member-data .name a"]?.["font-size"],
      "font-weight":
        payload.style?.[".ourteam-section .member-data .name a"]?.[
          "font-weight"
        ],
    },
    ".ourteam-section .member-data .position": {
      color: payload.style?.[".ourteam-section .member-data .position"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".ourteam-section .member-data .position"]?.[
                "color"
              ],
          })
        : "",
      "font-size":
        payload.style?.[".ourteam-section .member-data .position"]?.[
          "font-size"
        ],
      "font-weight":
        payload.style?.[".ourteam-section .member-data .position"]?.[
          "font-weight"
        ],
      margin:
        payload.style?.[".ourteam-section .member-data .position"]?.["margin"],
    },
    ".ourteam-section .member-data .socia-list a": {
      width:
        payload.style?.[".ourteam-section .member-data .socia-list a"]?.[
          "width"
        ],
      height:
        payload.style?.[".ourteam-section .member-data .socia-list a"]?.[
          "height"
        ],
      padding:
        payload.style?.[".ourteam-section .member-data .socia-list a"]?.[
          "padding"
        ],
      margin:
        payload.style?.[".ourteam-section .member-data .socia-list a"]?.[
          "margin"
        ],
      "text-align":
        payload.style?.[".ourteam-section .member-data .socia-list a"]?.[
          "text-align"
        ],
      color: payload.style?.[".ourteam-section .member-data .socia-list a"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".ourteam-section .member-data .socia-list a"]?.[
                "color"
              ],
          })
        : "",
      "font-size":
        payload.style?.[".ourteam-section .member-data .socia-list a"]?.[
          "font-size"
        ],
      border:
        payload.style?.[".ourteam-section .member-data .socia-list a"]?.[
          "border"
        ],
      borderPx:
        payload.style?.[".ourteam-section .member-data .socia-list a"]?.[
          "border"
        ]?.split(" ")[0],
      borderThik:
        payload.style?.[".ourteam-section .member-data .socia-list a"]?.[
          "border"
        ]?.split(" ")[1],
      borderColor: convertRGBA({
        rgbString: getColorFromString({
          colorString:
            payload.style?.[".ourteam-section .member-data .socia-list a"]?.[
              "border"
            ],
        }),
      }),
      "border-radius":
        payload.style?.[".ourteam-section .member-data .socia-list a"]?.[
          "border-radius"
        ],
    },
    ".ourteam-section .member-data .socia-list a:hover": {
      color: payload.style?.[
        ".ourteam-section .member-data .socia-list a:hover"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".ourteam-section .member-data .socia-list a:hover"
              ]?.["color"],
          })
        : "",
      "background-color": payload.style?.[
        ".ourteam-section .member-data .socia-list a:hover"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".ourteam-section .member-data .socia-list a:hover"
              ]?.["background-color"],
          })
        : "",
      "border-color": payload.style?.[
        ".ourteam-section .member-data .socia-list a:hover"
      ]?.["border-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".ourteam-section .member-data .socia-list a:hover"
              ]?.["border-color"],
          })
        : "",
    },

    ".team-detail-section":
      payload.style?.[".team-detail-section"]?.["width"] ||
      payload.style?.[".team-detail-section"]?.["margin"] ||
      payload.style?.[".team-detail-section"]?.["border-radius"]
        ? {
            padding: payload.style?.[".team-detail-section"]?.["padding"],
            "background-color": payload.style?.[".team-detail-section"]?.[
              "background-color"
            ]
              ? convertRGBA({
                  rgbString:
                    payload.style?.[".team-detail-section"]?.[
                      "background-color"
                    ],
                })
              : "",
            width: payload.style?.[".team-detail-section"]?.["width"]?.replace(
              "calc(100% - ",
              ""
            ),
            margin: payload.style?.[".team-detail-section"]?.["margin"],
            "border-radius":
              payload.style?.[".team-detail-section"]?.["border-radius"],
          }
        : {
            padding: payload.style?.[".team-detail-section"]?.["padding"],
            "background-color": payload.style?.[".team-detail-section"]?.[
              "background-color"
            ]
              ? convertRGBA({
                  rgbString:
                    payload.style?.[".team-detail-section"]?.[
                      "background-color"
                    ],
                })
              : "",
          },
    ".team-detail-section.bg-image": payload.style?.[
      ".team-detail-section.bg-image"
    ]?.["background-image"]
      ? {
          "background-image": payload.style?.[
            ".team-detail-section.bg-image"
          ]?.["background-image"]
            ? removeUrlKeyInBGI({
                urlString:
                  payload.style?.[".team-detail-section.bg-image"]?.[
                    "background-image"
                  ],
              })
            : "",
        }
      : {},
    ".team-detail-section.bg-image::before": payload.style?.[
      ".team-detail-section.bg-image::before"
    ]?.["background-color"]
      ? {
          "background-color": payload.style?.[
            ".team-detail-section.bg-image::before"
          ]?.["background-color"]
            ? convertRGBA({
                rgbString:
                  payload.style?.[".team-detail-section.bg-image::before"]?.[
                    "background-color"
                  ],
              })
            : "",
        }
      : {},
    ".team-detail-section .single-team-img": {
      "background-color": payload.style?.[
        ".team-detail-section .single-team-img"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".team-detail-section .single-team-img"]?.[
                "background-color"
              ],
          })
        : "",
      "margin-bottom":
        payload.style?.[".team-detail-section .single-team-img"]?.[
          "margin-bottom"
        ],
    },
    ".team-detail-section .team-member-info .team-title": {
      "font-weight":
        payload.style?.[".team-detail-section .team-member-info .team-title"]?.[
          "font-weight"
        ],
      "font-size":
        payload.style?.[".team-detail-section .team-member-info .team-title"]?.[
          "font-size"
        ],
    },
    ".team-detail-section .team-member-info .designation": {
      color: payload.style?.[
        ".team-detail-section .team-member-info .designation"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".team-detail-section .team-member-info .designation"
              ]?.["color"],
          })
        : "",
    },
    ".team-detail-section .team-member-info .social-list a": {
      padding:
        payload.style?.[
          ".team-detail-section .team-member-info .social-list a"
        ]?.["padding"],
      margin:
        payload.style?.[
          ".team-detail-section .team-member-info .social-list a"
        ]?.["margin"],
      width:
        payload.style?.[
          ".team-detail-section .team-member-info .social-list a"
        ]?.["width"],
      height:
        payload.style?.[
          ".team-detail-section .team-member-info .social-list a"
        ]?.["height"],
      "text-align":
        payload.style?.[
          ".team-detail-section .team-member-info .social-list a"
        ]?.["text-align"],
      color: payload.style?.[
        ".team-detail-section .team-member-info .social-list a"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".team-detail-section .team-member-info .social-list a"
              ]?.["color"],
          })
        : "",
      "font-size":
        payload.style?.[
          ".team-detail-section .team-member-info .social-list a"
        ]?.["font-size"],
      "border-radius":
        payload.style?.[
          ".team-detail-section .team-member-info .social-list a"
        ]?.["border-radius"],
      "background-color": payload.style?.[
        ".team-detail-section .team-member-info .social-list a"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".team-detail-section .team-member-info .social-list a"
              ]?.["background-color"],
          })
        : "",
    },
    ".team-detail-section .team-member-info .social-list a:hover": {
      color: payload.style?.[
        ".team-detail-section .team-member-info .social-list a:hover"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".team-detail-section .team-member-info .social-list a:hover"
              ]?.["color"],
          })
        : "",
      "background-color": payload.style?.[
        ".team-detail-section .team-member-info .social-list a:hover"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".team-detail-section .team-member-info .social-list a:hover"
              ]?.["background-color"],
          })
        : "",
    },
    ".personal-details .details-title": {
      "font-weight":
        payload.style?.[".personal-details .details-title"]?.["font-weight"],
      "font-size":
        payload.style?.[".personal-details .details-title"]?.["font-size"],
    },
    ".personal-details p": {
      "margin-bottom":
        payload.style?.[".personal-details p"]?.["margin-bottom"],
      "line-height": payload.style?.[".personal-details p"]?.["line-height"],
      "font-size": payload.style?.[".personal-details p"]?.["font-size"],
    },
  };
};

export const OurTeamOneSubFun = ({
  settingFormData,
  boxSection,
  boxSection1,
}) => {
  let newData = {
    ...settingFormData,
    ".team-section": boxSection
      ? {
          ...settingFormData?.[".team-section"],
          padding: `${settingFormData?.[".team-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
          width: `calc(100% - ${settingFormData?.[".team-section"]?.[
            "width"
          ]?.replace("rem)", "")}rem)`,
          margin: `${settingFormData?.[".team-section"]?.["margin"]
            ?.split(" ")[0]
            ?.replace("px", "")}px auto`,
          "border-radius": `${settingFormData?.[".team-section"]?.[
            "border-radius"
          ]?.replace("rem", "")}rem`,
        }
      : {
          ...settingFormData?.[".team-section"],
          padding: `${settingFormData?.[".team-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
        },

    ".team-section.bg-image": settingFormData?.[".team-section.bg-image"]?.[
      "background-image"
    ]
      ? {
          "background-image": settingFormData?.[".team-section.bg-image"]?.[
            "background-image"
          ]
            ? addUrlImage({
                urlString: removeUrlImage({
                  urlString:
                    settingFormData[".team-section.bg-image"][
                      "background-image"
                    ],
                }),
              })
            : "",
        }
      : {},
    ".team-section.bg-image::before": settingFormData?.[
      ".team-section.bg-image::before"
    ]?.["background-color"]
      ? {
          "background-color": settingFormData?.[
            ".team-section.bg-image::before"
          ]?.["background-color"]
            ? rgbColorCreate({
                rgbString:
                  settingFormData?.[".team-section.bg-image::before"]?.[
                    "background-color"
                  ],
              })
            : "",
        }
      : {},
    ".team-section .section-title-area": {
      "margin-bottom": `${settingFormData?.[
        ".team-section .section-title-area"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".team-section .section-title-area .section-subtitle": {
      ...settingFormData[".team-section .section-title-area .section-subtitle"],
      "font-size": `${settingFormData?.[
        ".team-section .section-title-area .section-subtitle"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".team-section .section-title-area .section-subtitle"]?.["font-weight"]}`,
      color: settingFormData[
        ".team-section .section-title-area .section-subtitle"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".team-section .section-title-area .section-subtitle"
              ]?.["color"],
          })
        : "",
      "background-color": settingFormData?.[
        ".team-section .section-title-area .section-subtitle"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".team-section .section-title-area .section-subtitle"
              ]?.["background-color"],
          })
        : "",
      padding: `${settingFormData?.[
        ".team-section .section-title-area .section-subtitle"
      ]?.["p1"]?.replace("px", "")}px ${settingFormData?.[
        ".team-section .section-title-area .section-subtitle"
      ]?.["p2"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData?.[
        ".team-section .section-title-area .section-subtitle"
      ]?.["border-radius"]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData?.[
        ".team-section .section-title-area .section-subtitle"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".team-section .section-title-area .section-title": {
      ...settingFormData[".team-section .section-title-area .section-title"],
      "font-size": `${settingFormData?.[
        ".team-section .section-title-area .section-title"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".team-section .section-title-area .section-title"]?.["font-weight"]}`,
      color: settingFormData[
        ".team-section .section-title-area .section-title"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".team-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".team-section .member-data": {
      ...settingFormData?.[".team-section .member-data"],
      margin: `${settingFormData?.[".team-section .member-data"]?.["margin"]
        ?.split(" ")[0]
        ?.replace("px", "")}px 0`,
      "background-color": settingFormData[".team-section .member-data"]?.[
        "background-color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".team-section .member-data"]?.[
                "background-color"
              ],
          })
        : "",
      "box-shadow": `${settingFormData?.[".team-section .member-data"]?.[
        "bs1"
      ]?.replace("px", "")}px ${settingFormData?.[
        ".team-section .member-data"
      ]?.["bs2"]?.replace("px", "")}px ${settingFormData?.[
        ".team-section .member-data"
      ]?.["bs3"]?.replace("px", "")}px ${settingFormData?.[
        ".team-section .member-data"
      ]?.["bs4"]?.replace("px", "")}px ${rgbColorCreate({
        rgbString:
          settingFormData[".team-section .member-data"]?.["shadowColor"],
      })}`,
      "border-radius": `${settingFormData?.[".team-section .member-data"]?.[
        "border-radius"
      ]?.replace("px", "")}px`,
      overflow: "hidden",
    },
    ".team-section .member-data .member-img": {
      "background-color": settingFormData[
        ".team-section .member-data .member-img"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".team-section .member-data .member-img"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".team-section .member-data .member-info": {
      ...settingFormData?.[".team-section .member-data .member-info"],
      padding: `${settingFormData?.[
        ".team-section .member-data .member-info"
      ]?.["p1"]?.replace("px", "")}px ${settingFormData[
        ".team-section .member-data .member-info"
      ]?.["p2"]?.replace("px", "")}px`,
    },
    ".team-section .member-data .name a": {
      ...settingFormData?.[".team-section .member-data .name a"],
      color: settingFormData[".team-section .member-data .name a"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".team-section .member-data .name a"]?.["color"],
          })
        : "",
      "font-size": `${settingFormData?.[".team-section .member-data .name a"]?.[
        "font-size"
      ]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".team-section .member-data .name a"]?.["font-weight"]}`,
    },
    ".team-section .member-data .name a:hover": {
      color: settingFormData[".team-section .member-data .name a:hover"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".team-section .member-data .name a:hover"]?.[
                "color"
              ],
          })
        : "",
    },
    ".team-section .member-data .position": {
      ...settingFormData?.[".team-section .member-data .position"],
      color: settingFormData[".team-section .member-data .position"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".team-section .member-data .position"]?.[
                "color"
              ],
          })
        : "",
      "font-size": `${settingFormData[".team-section .member-data .position"]?.[
        "font-size"
      ]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".team-section .member-data .position"]?.["font-weight"]}`,
      margin: `${settingFormData?.[".team-section .member-data .position"]?.[
        "margin"
      ]
        ?.split(" ")[0]
        ?.replace("px", "")}px 0`,
    },
    ".team-section .member-data .socia-list a": {
      ...settingFormData?.[".team-section .member-data .socia-list a"],
      padding: `${settingFormData?.[
        ".team-section .member-data .socia-list a"
      ]?.["padding"]?.replace("px", "")}px`,
      margin: `${settingFormData?.[
        ".team-section .member-data .socia-list a"
      ]?.["margin"]?.replace("px", "")}px`,
      "text-align": `${settingFormData?.[".team-section .member-data .socia-list a"]?.["text-align"]}`,
      color: settingFormData[".team-section .member-data .socia-list a"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".team-section .member-data .socia-list a"]?.[
                "color"
              ],
          })
        : "",
      "font-size": `${settingFormData?.[
        ".team-section .member-data .socia-list a"
      ]?.["font-size"]?.replace("px", "")}px`,
      border: `${settingFormData?.[
        ".team-section .member-data .socia-list a"
      ]?.["borderPx"]?.replace("px", "")}px ${
        settingFormData?.[".team-section .member-data .socia-list a"]?.[
          "borderThik"
        ]
      } ${rgbColorCreate({
        rgbString:
          settingFormData[".team-section .member-data .socia-list a"]?.[
            "borderColor"
          ],
      })}`,
      "border-radius": `${settingFormData?.[
        ".team-section .member-data .socia-list a"
      ]?.["border-radius"]?.replace("px", "")}px`,
    },
    ".team-section .member-data .socia-list a:hover": {
      ...settingFormData[".team-section .member-data .socia-list a:hover"],
      color: settingFormData[
        ".team-section .member-data .socia-list a:hover"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".team-section .member-data .socia-list a:hover"
              ]?.["color"],
          })
        : "",
      "background-color": settingFormData[
        ".team-section .member-data .socia-list a:hover"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".team-section .member-data .socia-list a:hover"
              ]?.["background-color"],
          })
        : "",
      "border-color": settingFormData[
        ".team-section .member-data .socia-list a:hover"
      ]?.["border-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".team-section .member-data .socia-list a:hover"
              ]?.["border-color"],
          })
        : "",
    },

    ".team-detail-section": boxSection1
      ? {
          ...settingFormData?.[".team-detail-section"],
          padding: `${settingFormData?.[".team-detail-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
          width: `calc(100% - ${settingFormData?.[".team-detail-section"]?.[
            "width"
          ]?.replace("rem)", "")}rem)`,
          margin: `${settingFormData?.[".team-detail-section"]?.["margin"]
            ?.split(" ")[0]
            ?.replace("px", "")}px auto`,
          "border-radius": `${settingFormData?.[".team-detail-section"]?.[
            "border-radius"
          ]?.replace("rem", "")}rem`,
        }
      : {
          ...settingFormData?.[".team-detail-section"],
          padding: `${settingFormData?.[".team-detail-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
        },
    ".team-detail-section.bg-image": settingFormData?.[
      ".team-detail-section.bg-image"
    ]?.["background-image"]
      ? {
          "background-image": settingFormData?.[
            ".team-detail-section.bg-image"
          ]?.["background-image"]
            ? addUrlImage({
                urlString:
                  settingFormData?.[".team-detail-section.bg-image"]?.[
                    "background-image"
                  ],
              })
            : "",
        }
      : {},
    ".team-detail-section.bg-image::before": settingFormData?.[
      ".team-detail-section.bg-image::before"
    ]?.["background-color"]
      ? {
          "background-color": settingFormData?.[
            ".team-detail-section.bg-image::before"
          ]?.["background-color"]
            ? rgbColorCreate({
                rgbString:
                  settingFormData?.[".team-detail-section.bg-image::before"]?.[
                    "background-color"
                  ],
              })
            : "",
        }
      : {},
    ".team-detail-section .single-team-img": {
      ...settingFormData?.[".team-detail-section .single-team-img"],
      "background-color": settingFormData[
        ".team-detail-section .single-team-img"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".team-detail-section .single-team-img"]?.[
                "background-color"
              ],
          })
        : "",
      "margin-bottom": `${settingFormData?.[
        ".team-detail-section .single-team-img"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".team-detail-section .team-member-info .team-title": {
      ...settingFormData?.[
        ".team-detail-section .team-member-info .team-title"
      ],
      "font-weight": `${settingFormData?.[".team-detail-section .team-member-info .team-title"]?.["font-weight"]}`,
      "font-size": `${settingFormData?.[
        ".team-detail-section .team-member-info .team-title"
      ]?.["font-size"]?.replace("px", "")}px`,
    },
    ".team-detail-section .team-member-info .designation": {
      color: settingFormData[
        ".team-detail-section .team-member-info .designation"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".team-detail-section .team-member-info .designation"
              ]?.["color"],
          })
        : "",
    },
    ".team-detail-section .team-member-info .social-list a": {
      ...settingFormData?.[
        ".team-detail-section .team-member-info .social-list a"
      ],
      padding: `${settingFormData?.[
        ".team-detail-section .team-member-info .social-list a"
      ]?.["padding"]?.replace("px", "")}px`,
      margin: `${settingFormData?.[
        ".team-detail-section .team-member-info .social-list a"
      ]?.["margin"]?.replace("px", "")}px`,
      width: `${settingFormData?.[
        ".team-detail-section .team-member-info .social-list a"
      ]?.["width"]?.replace("px", "")}px`,
      height: `${settingFormData?.[
        ".team-detail-section .team-member-info .social-list a"
      ]?.["height"]?.replace("px", "")}px`,
      "text-align": "center",
      color: settingFormData[
        ".team-detail-section .team-member-info .social-list a"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".team-detail-section .team-member-info .social-list a"
              ]?.["color"],
          })
        : "",
      "font-size": `${settingFormData?.[
        ".team-detail-section .team-member-info .social-list a"
      ]?.["font-size"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData?.[
        ".team-detail-section .team-member-info .social-list a"
      ]?.["border-radius"]?.replace("px", "")}px`,
      "background-color": settingFormData[
        ".team-detail-section .team-member-info .social-list a"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".team-detail-section .team-member-info .social-list a"
              ]?.["background-color"],
          })
        : "",
    },
    ".team-detail-section .team-member-info .social-list a:hover": {
      ...settingFormData?.[
        ".team-detail-section .team-member-info .social-list a:hover"
      ],
      color: settingFormData[
        ".team-detail-section .team-member-info .social-list a:hover"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".team-detail-section .team-member-info .social-list a:hover"
              ]?.["color"],
          })
        : "",
      "background-color": settingFormData[
        ".team-detail-section .team-member-info .social-list a:hover"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".team-detail-section .team-member-info .social-list a:hover"
              ]?.["background-color"],
          })
        : "",
    },
    ".personal-details .details-title": {
      ...settingFormData?.[".personal-details .details-title"],
      "font-weight": `${settingFormData?.[".personal-details .details-title"]?.["font-weight"]}`,
      "font-size": `${settingFormData?.[".personal-details .details-title"]?.[
        "font-size"
      ]?.replace("px", "")}px`,
    },
    ".personal-details p": {
      ...settingFormData?.[".personal-details p"],
      "margin-bottom": `${settingFormData?.[".personal-details p"]?.[
        "margin-bottom"
      ]?.replace("px", "")}px`,
      "line-height": `${settingFormData?.[".personal-details p"]?.["line-height"]}`,
      "font-size": `${settingFormData?.[".personal-details p"]?.[
        "font-size"
      ]?.replace("px", "")}px`,
    },
    // ".personal-details .info-list": {
    //   "margin-bottom": `${settingFormData?.[
    //     ".personal-details .info-list"
    //   ]?.["margin-bottom"]?.replace("px", "")}px`,
    // },
    // ".personal-details .info-list li": {
    //   ...settingFormData?.[".personal-details .info-list li"],
    //   "margin-bottom": `${settingFormData?.[
    //     ".personal-details .info-list li"
    //   ]?.["margin-bottom"]?.replace("px", "")}px`,
    //   "font-size": `${settingFormData?.[
    //     ".personal-details .info-list li"
    //   ]?.["font-size"]?.replace("px", "")}px`,
    // },
    // ".personal-details .order-list": {
    //   "margin-bottom": `${settingFormData?.[
    //     ".personal-details .order-list"
    //   ]?.["margin-bottom"]?.replace("px", "")}px`,
    // },
    // ".personal-details .order-list li": {
    //   ...settingFormData?.[".personal-details .order-list li"],
    //   "padding-left": `${settingFormData?.[
    //     ".personal-details .order-list li"
    //   ]?.["padding-left"]?.replace("px", "")}px`,
    //   "margin-bottom": `${settingFormData?.[
    //     ".personal-details .order-list li"
    //   ]?.["margin-bottom"]?.replace("px", "")}px`,
    //   "font-size": `${settingFormData?.[
    //     ".personal-details .order-list li"
    //   ]?.["font-size"]?.replace("px", "")}px`,
    // },
    // ".personal-details .order-list li:before": {
    //   ...settingFormData?.[".personal-details .order-list li:before"],
    //   "font-family": `${settingFormData?.[".personal-details .order-list li:before"]?.["font-family"]}`,
    //   top: `${settingFormData?.[
    //     ".personal-details .order-list li:before"
    //   ]?.["top"]?.replace("px", "")}px`,
    //   color: `${settingFormData?.[".personal-details .order-list li:before"]?.["color"]}`,
    // },
  };
  return newData;
};
export const OurTeamTwoSubFun = ({
  settingFormData,
  boxSection,
  boxSection1,
}) => {
  let newData = {
    ...settingFormData,
    ".ourteam-section": boxSection
      ? {
          ...settingFormData?.[".ourteam-section"],
          padding: `${settingFormData?.[".ourteam-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
          width: `calc(100% - ${settingFormData?.[".ourteam-section"]?.[
            "width"
          ]?.replace("rem)", "")}rem)`,
          margin: `${settingFormData?.[".ourteam-section"]?.["margin"]
            ?.split(" ")[0]
            ?.replace("px", "")}px auto`,
          "border-radius": `${settingFormData?.[".ourteam-section"]?.[
            "border-radius"
          ]?.replace("rem", "")}rem`,
        }
      : {
          ...settingFormData?.[".ourteam-section"],
          padding: `${settingFormData?.[".ourteam-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
        },

    ".ourteam-section.bg-image": settingFormData?.[
      ".ourteam-section.bg-image"
    ]?.["background-image"]
      ? {
          "background-image": settingFormData?.[".ourteam-section.bg-image"]?.[
            "background-image"
          ]
            ? addUrlImage({
                urlString: removeUrlImage({
                  urlString:
                    settingFormData[".ourteam-section.bg-image"][
                      "background-image"
                    ],
                }),
              })
            : "",
        }
      : {},
    ".ourteam-section.bg-image::before": settingFormData?.[
      ".ourteam-section.bg-image::before"
    ]?.["background-color"]
      ? {
          "background-color": settingFormData?.[
            ".ourteam-section.bg-image::before"
          ]?.["background-color"]
            ? rgbColorCreate({
                rgbString:
                  settingFormData?.[".ourteam-section.bg-image::before"]?.[
                    "background-color"
                  ],
              })
            : "",
        }
      : {},
    ".ourteam-section .section-title-area": {
      "margin-bottom": `${settingFormData?.[
        ".ourteam-section .section-title-area"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".ourteam-section .section-title-area .section-subtitle": {
      ...settingFormData[
        ".ourteam-section .section-title-area .section-subtitle"
      ],
      "font-size": `${settingFormData?.[
        ".ourteam-section .section-title-area .section-subtitle"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".ourteam-section .section-title-area .section-subtitle"]?.["font-weight"]}`,
      color: settingFormData[
        ".ourteam-section .section-title-area .section-subtitle"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".ourteam-section .section-title-area .section-subtitle"
              ]?.["color"],
          })
        : "",
      "background-color": settingFormData?.[
        ".ourteam-section .section-title-area .section-subtitle"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".ourteam-section .section-title-area .section-subtitle"
              ]?.["background-color"],
          })
        : "",
      padding: `${settingFormData?.[
        ".ourteam-section .section-title-area .section-subtitle"
      ]?.["p1"]?.replace("px", "")}px ${settingFormData?.[
        ".ourteam-section .section-title-area .section-subtitle"
      ]?.["p2"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData?.[
        ".ourteam-section .section-title-area .section-subtitle"
      ]?.["border-radius"]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData?.[
        ".ourteam-section .section-title-area .section-subtitle"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".ourteam-section .section-title-area .section-title": {
      ...settingFormData[".ourteam-section .section-title-area .section-title"],
      "font-size": `${settingFormData?.[
        ".ourteam-section .section-title-area .section-title"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".ourteam-section .section-title-area .section-title"]?.["font-weight"]}`,
      color: settingFormData[
        ".ourteam-section .section-title-area .section-title"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".ourteam-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".ourteam-section .member-data": {
      ...settingFormData?.[".ourteam-section .member-data"],
      border: `${settingFormData?.[".ourteam-section .member-data"]?.[
        "borderPx"
      ]?.replace("px", "")}px ${
        settingFormData?.[".ourteam-section .member-data"]?.["borderThik"]
      } ${rgbColorCreate({
        rgbString:
          settingFormData[".ourteam-section .member-data"]?.["borderColor"],
      })}`,
      margin: `${settingFormData?.[".ourteam-section .member-data"]?.["margin"]
        ?.split(" ")[0]
        ?.replace("px", "")}px 0`,
      "background-color": settingFormData[".ourteam-section .member-data"]?.[
        "background-color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".ourteam-section .member-data"]?.[
                "background-color"
              ],
          })
        : "",
      "box-shadow": `${settingFormData?.[".ourteam-section .member-data"]?.[
        "bs1"
      ]?.replace("px", "")}px ${settingFormData?.[
        ".ourteam-section .member-data"
      ]?.["bs2"]?.replace("px", "")}px ${settingFormData?.[
        ".ourteam-section .member-data"
      ]?.["bs3"]?.replace("px", "")}px ${settingFormData?.[
        ".ourteam-section .member-data"
      ]?.["bs4"]?.replace("px", "")}px ${rgbColorCreate({
        rgbString:
          settingFormData[".ourteam-section .member-data"]?.["shadowColor"],
      })}`,
      "border-radius": `${settingFormData?.[".ourteam-section .member-data"]?.[
        "border-radius"
      ]?.replace("px", "")}px`,
      overflow: "hidden",
    },
    ".ourteam-section .member-data:hover": {
      "border-color": settingFormData[".ourteam-section .member-data:hover"]?.[
        "border-color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".ourteam-section .member-data:hover"]?.[
                "border-color"
              ],
          })
        : "",
    },
    ".ourteam-section .member-data .member-info": {
      ...settingFormData?.[".ourteam-section .member-data .member-info"],
      padding: `${settingFormData?.[
        ".ourteam-section .member-data .member-info"
      ]?.["p1"]?.replace("px", "")}px ${settingFormData[
        ".ourteam-section .member-data .member-info"
      ]?.["p2"]?.replace("px", "")}px`,
    },
    ".ourteam-section .member-data .name a": {
      ...settingFormData?.[".ourteam-section .member-data .name a"],
      color: settingFormData[".ourteam-section .member-data .name a"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".ourteam-section .member-data .name a"]?.[
                "color"
              ],
          })
        : "",
      "font-size": `${settingFormData?.[
        ".ourteam-section .member-data .name a"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".ourteam-section .member-data .name a"]?.["font-weight"]}`,
    },
    ".ourteam-section .member-data .position": {
      ...settingFormData?.[".ourteam-section .member-data .position"],
      color: settingFormData[".ourteam-section .member-data .position"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".ourteam-section .member-data .position"]?.[
                "color"
              ],
          })
        : "",
      "font-size": `${settingFormData[
        ".ourteam-section .member-data .position"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".ourteam-section .member-data .position"]?.["font-weight"]}`,
      margin: `${settingFormData?.[".ourteam-section .member-data .position"]?.[
        "margin"
      ]
        ?.split(" ")[0]
        ?.replace("px", "")}px 0`,
    },
    ".ourteam-section .member-data .socia-list a": {
      ...settingFormData?.[".ourteam-section .member-data .socia-list a"],
      width: `${settingFormData?.[
        ".ourteam-section .member-data .socia-list a"
      ]?.["width"]?.replace("px", "")}px`,
      height: `${settingFormData?.[
        ".ourteam-section .member-data .socia-list a"
      ]?.["height"]?.replace("px", "")}px`,
      padding: `${settingFormData?.[
        ".ourteam-section .member-data .socia-list a"
      ]?.["padding"]?.replace("px", "")}px`,
      margin: `${settingFormData?.[
        ".ourteam-section .member-data .socia-list a"
      ]?.["margin"]?.replace("px", "")}px`,
      "text-align": `${settingFormData?.[".ourteam-section .member-data .socia-list a"]?.["text-align"]}`,
      color: settingFormData[".ourteam-section .member-data .socia-list a"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".ourteam-section .member-data .socia-list a"]?.[
                "color"
              ],
          })
        : "",
      "font-size": `${settingFormData?.[
        ".ourteam-section .member-data .socia-list a"
      ]?.["font-size"]?.replace("px", "")}px`,
      border: `${settingFormData?.[
        ".ourteam-section .member-data .socia-list a"
      ]?.["borderPx"]?.replace("px", "")}px ${
        settingFormData?.[".ourteam-section .member-data .socia-list a"]?.[
          "borderThik"
        ]
      } ${rgbColorCreate({
        rgbString:
          settingFormData[".ourteam-section .member-data .socia-list a"]?.[
            "borderColor"
          ],
      })}`,
      "border-radius": `${settingFormData?.[
        ".ourteam-section .member-data .socia-list a"
      ]?.["border-radius"]?.replace("px", "")}px`,
    },
    ".ourteam-section .member-data .socia-list a:hover": {
      ...settingFormData[".ourteam-section .member-data .socia-list a:hover"],
      color: settingFormData[
        ".ourteam-section .member-data .socia-list a:hover"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".ourteam-section .member-data .socia-list a:hover"
              ]?.["color"],
          })
        : "",
      "background-color": settingFormData[
        ".ourteam-section .member-data .socia-list a:hover"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".ourteam-section .member-data .socia-list a:hover"
              ]?.["background-color"],
          })
        : "",
      "border-color": settingFormData[
        ".ourteam-section .member-data .socia-list a:hover"
      ]?.["border-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".ourteam-section .member-data .socia-list a:hover"
              ]?.["border-color"],
          })
        : "",
    },

    ".team-detail-section": boxSection1
      ? {
          ...settingFormData?.[".team-detail-section"],
          padding: `${settingFormData?.[".team-detail-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
          width: `calc(100% - ${settingFormData?.[".team-detail-section"]?.[
            "width"
          ]?.replace("rem)", "")}rem)`,
          margin: `${settingFormData?.[".team-detail-section"]?.["margin"]
            ?.split(" ")[0]
            ?.replace("px", "")}px auto`,
          "border-radius": `${settingFormData?.[".team-detail-section"]?.[
            "border-radius"
          ]?.replace("rem", "")}rem`,
        }
      : {
          ...settingFormData?.[".team-detail-section"],
          padding: `${settingFormData?.[".team-detail-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
        },
    ".team-detail-section.bg-image": settingFormData?.[
      ".team-detail-section.bg-image"
    ]?.["background-image"]
      ? {
          "background-image": settingFormData?.[
            ".team-detail-section.bg-image"
          ]?.["background-image"]
            ? addUrlImage({
                urlString:
                  settingFormData?.[".team-detail-section.bg-image"]?.[
                    "background-image"
                  ],
              })
            : "",
        }
      : {},
    ".team-detail-section.bg-image::before": settingFormData?.[
      ".team-detail-section.bg-image::before"
    ]?.["background-color"]
      ? {
          "background-color": settingFormData?.[
            ".team-detail-section.bg-image::before"
          ]?.["background-color"]
            ? rgbColorCreate({
                rgbString:
                  settingFormData?.[".team-detail-section.bg-image::before"]?.[
                    "background-color"
                  ],
              })
            : "",
        }
      : {},
    ".team-detail-section .single-team-img": {
      ...settingFormData?.[".team-detail-section .single-team-img"],
      "background-color": settingFormData[
        ".team-detail-section .single-team-img"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".team-detail-section .single-team-img"]?.[
                "background-color"
              ],
          })
        : "",
      "margin-bottom": `${settingFormData?.[
        ".team-detail-section .single-team-img"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".team-detail-section .team-member-info .team-title": {
      ...settingFormData?.[
        ".team-detail-section .team-member-info .team-title"
      ],
      "font-weight": `${settingFormData?.[".team-detail-section .team-member-info .team-title"]?.["font-weight"]}`,
      "font-size": `${settingFormData?.[
        ".team-detail-section .team-member-info .team-title"
      ]?.["font-size"]?.replace("px", "")}px`,
    },
    ".team-detail-section .team-member-info .designation": {
      color: settingFormData[
        ".team-detail-section .team-member-info .designation"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".team-detail-section .team-member-info .designation"
              ]?.["color"],
          })
        : "",
    },
    ".team-detail-section .team-member-info .social-list a": {
      ...settingFormData?.[
        ".team-detail-section .team-member-info .social-list a"
      ],
      padding: `${settingFormData?.[
        ".team-detail-section .team-member-info .social-list a"
      ]?.["padding"]?.replace("px", "")}px`,
      margin: `${settingFormData?.[
        ".team-detail-section .team-member-info .social-list a"
      ]?.["margin"]?.replace("px", "")}px`,
      width: `${settingFormData?.[
        ".team-detail-section .team-member-info .social-list a"
      ]?.["width"]?.replace("px", "")}px`,
      height: `${settingFormData?.[
        ".team-detail-section .team-member-info .social-list a"
      ]?.["height"]?.replace("px", "")}px`,
      "text-align": "center",
      color: settingFormData[
        ".team-detail-section .team-member-info .social-list a"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".team-detail-section .team-member-info .social-list a"
              ]?.["color"],
          })
        : "",
      "font-size": `${settingFormData?.[
        ".team-detail-section .team-member-info .social-list a"
      ]?.["font-size"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData?.[
        ".team-detail-section .team-member-info .social-list a"
      ]?.["border-radius"]?.replace("px", "")}px`,
      "background-color": settingFormData[
        ".team-detail-section .team-member-info .social-list a"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".team-detail-section .team-member-info .social-list a"
              ]?.["background-color"],
          })
        : "",
    },
    ".team-detail-section .team-member-info .social-list a:hover": {
      ...settingFormData?.[
        ".team-detail-section .team-member-info .social-list a:hover"
      ],
      color: settingFormData[
        ".team-detail-section .team-member-info .social-list a:hover"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".team-detail-section .team-member-info .social-list a:hover"
              ]?.["color"],
          })
        : "",
      "background-color": settingFormData[
        ".team-detail-section .team-member-info .social-list a:hover"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".team-detail-section .team-member-info .social-list a:hover"
              ]?.["background-color"],
          })
        : "",
    },
    ".personal-details .details-title": {
      ...settingFormData?.[".personal-details .details-title"],
      "font-weight": `${settingFormData?.[".personal-details .details-title"]?.["font-weight"]}`,
      "font-size": `${settingFormData?.[".personal-details .details-title"]?.[
        "font-size"
      ]?.replace("px", "")}px`,
    },
    ".personal-details p": {
      ...settingFormData?.[".personal-details p"],
      "margin-bottom": `${settingFormData?.[".personal-details p"]?.[
        "margin-bottom"
      ]?.replace("px", "")}px`,
      "line-height": `${settingFormData?.[".personal-details p"]?.["line-height"]}`,
      "font-size": `${settingFormData?.[".personal-details p"]?.[
        "font-size"
      ]?.replace("px", "")}px`,
    },
    // ".personal-details .info-list": {
    //   "margin-bottom": `${settingFormData?.[
    //     ".personal-details .info-list"
    //   ]?.["margin-bottom"]?.replace("px", "")}px`,
    // },
    // ".personal-details .info-list li": {
    //   ...settingFormData?.[".personal-details .info-list li"],
    //   "margin-bottom": `${settingFormData?.[
    //     ".personal-details .info-list li"
    //   ]?.["margin-bottom"]?.replace("px", "")}px`,
    //   "font-size": `${settingFormData?.[
    //     ".personal-details .info-list li"
    //   ]?.["font-size"]?.replace("px", "")}px`,
    // },
    // ".personal-details .order-list": {
    //   "margin-bottom": `${settingFormData?.[
    //     ".personal-details .order-list"
    //   ]?.["margin-bottom"]?.replace("px", "")}px`,
    // },
    // ".personal-details .order-list li": {
    //   ...settingFormData?.[".personal-details .order-list li"],
    //   "padding-left": `${settingFormData?.[
    //     ".personal-details .order-list li"
    //   ]?.["padding-left"]?.replace("px", "")}px`,
    //   "margin-bottom": `${settingFormData?.[
    //     ".personal-details .order-list li"
    //   ]?.["margin-bottom"]?.replace("px", "")}px`,
    //   "font-size": `${settingFormData?.[
    //     ".personal-details .order-list li"
    //   ]?.["font-size"]?.replace("px", "")}px`,
    // },
    // ".personal-details .order-list li:before": {
    //   ...settingFormData?.[".personal-details .order-list li:before"],
    //   "font-family": `${settingFormData?.[".personal-details .order-list li:before"]?.["font-family"]}`,
    //   top: `${settingFormData?.[
    //     ".personal-details .order-list li:before"
    //   ]?.["top"]?.replace("px", "")}px`,
    //   color: `${settingFormData?.[".personal-details .order-list li:before"]?.["color"]}`,
    // },
  };
  return newData;
};
