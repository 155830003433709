import React, { useContext, useMemo, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import { Cases, DELETE_CONFIRM, modalsName } from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import CommonPopup from "../../../Modals/commonPopup";
import ItemFeaturePopup from "./ItemFeaturePopup";
import EditIcon from "@mui/icons-material/Edit";
import ItemFeatureParentPopup from "./ItemFeatureParentPopup";
import { FILE_UPLOAD_API } from "../apis/SliderApi/SliderApi";
import {
  addUrlImage,
  convertRGBA,
  isCheckValue,
  isEmptyObject,
  randomStr,
  removeUrlImage,
  removeUrlKeyInPath,
  rgbColorCreate,
} from "../../../../utils/helper";
import { FILE_TYPE, ThemeNo, titleUpdateKey } from "../../../../constant";
import { IconButton } from "@material-ui/core";
import { Settings, Trash } from "react-feather";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import SimpleReactValidator from "simple-react-validator";
import SettingImageParentPopup from "./SettingImagesPerentPopup";
import ItemFeatureContext from "../../../context/ItemFeatureContext/ItemFeatureContext";
import {
  deleteItemFeature,
  getItemFeature,
  getItemFeatureDetails,
  postItemFeature,
  putUpdateItemFeature,
  updateStatus,
} from "../apis/ItemFeatureApi/ItemFeatureApi";
import DeleteSection from "../../../common/DeleteSection";
import SettingMultiWebContext from "../../../../context/SettingMultiWebContext/SettingMultiWebContext";
import {
  ItemFeatureStyleOneSubFun,
  ItemFeatureThemeOneFunction,
  ItemFeatureThemeOneReset,
} from "./ItemFeatureStyleFunction";

const ItemFeature = (props) => {
  const {
    pageConfigDetails,
    webMasterDetail,
    navbarData,
    getData,
    contentMapId,
    itemFeatureDetail,
    ByteMiniWeb_Dashboard,
    contentDetail,
  } = props;
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );
  const { setCloneAlignModal, setAlignFormData, setCloneFormData } = useContext(
    SettingMultiWebContext
  );
  const parentValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const childValidator = useRef(new SimpleReactValidator());
  const {
    fromData,
    setFormData,
    formDataChild,
    setFormDataChild,
    id,
    setId,
    setIsEdit,
    isEdit,
    setFilter,
    apiDataOurTeam: apiDataImageParent,
    setApiDataOurTeam: setApiDataImageParent,
    setSkill,
    setGalleryImgId,
    setSettingFormData,
    settingFormData,
    isParent,
    setIsParent,
    childId,
    setChildId,
    navMapId,
    setNavMapId,
    boxSection,
    setBackImageToggle,
    setBoxSection,
    collId,
    setCollId,
    isBackgroundColor,
    setIsBackgroundColor,
    theme,
    setTheme,
    imgShow,
    setImgShow,
    sectionPageName,
    setSectionPageName,
  } = useContext(ItemFeatureContext);
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * get item feature parent list
   * @param {status} status
   */
  const getItemFeatureParentList = async ({
    parentId = null,
    isClose = false,
    filter,
  }) => {
    try {
      let paramObj = {
        showAll: true,
      };
      if (
        ((isParent === false && parentId && parentId !== null) ||
          (id && isClose === false)) &&
        isParent === false
      ) {
        paramObj["search"] = { parentId: parentId || id };
        setId(parentId || id);
        if (filter?.length) {
          paramObj["search"] = { ...paramObj.search, title: filter };
        }
      } else {
        paramObj["search"] = { parentId: null };
      }
      const { data } = await getItemFeature(paramObj);
      if (isCheckValue({ value: parentId })) {
        setChildId(parentId);
      }
      if (data.status === 200) {
        if (
          isParent === false &&
          ((parentId && parentId !== null) || id) &&
          isClose === false
        ) {
          setApiDataImageParent({
            ...apiDataImageParent,
            isLoading: false,
            data: data.payload.data.map((elem) => {
              return {
                id: elem?.title + elem?.itemFeatureId,
                Id: elem?.itemFeatureId,
                name: elem?.title,
                image: "",
                webId: elem?.webId,
                createdAt: elem?.createdAt,
                isActive: elem?.isActive,
                parentId: elem?.parentId,
              };
            }),
          });
          OpenModalCommonFunc(Cases.ITEM_FEATURE, setAllPopupState);
        } else if (parentId && parentId !== null) {
          OpenModalCommonFunc(Cases.ITEM_FEATURE, setAllPopupState);
          setApiDataImageParent({
            ...apiDataImageParent,
            isLoading: false,
            data: data.payload.data.map((elem) => {
              return {
                id: elem?.title + elem?.itemFeatureId,
                Id: elem?.itemFeatureId,
                name: elem?.title,
                image: "",
                webId: elem?.webId,
                createdAt: elem?.createdAt,
                isActive: elem?.isActive,
                parentId: elem?.parentId,
              };
            }),
          });
        } else {
          setApiDataImageParent({
            ...apiDataImageParent,
            isLoading: false,
            data: data.payload.data,
          });
        }
        if (isClose && isClose !== false) {
          setIsParent(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * item feature enable disable api call
   * @param {itemFeatureId} itemFeatureId
   * @param {status} isActive
   */

  const handleChangeItemFeature = async (
    itemFeatureId,
    isActive,
    isChild,
    parentId
  ) => {
    try {
      let body = {
        itemFeatureId,
        isActive: isActive ? false : true,
        parentId,
      };
      const { data } = await updateStatus(body);
      if (data.status === 200) {
        if (isChild) {
          getItemFeatureParentList({ parentId: id });
        } else {
          ByteMiniWeb_Dashboard();
        }
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * file upload
   * @param {file} file
   */
  const imageUploadImages = async (file) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.ITEM_FEATURE);
    formData.append("file", file);
    setLoaderShow(true);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        setFormDataChild((prev) => {
          return {
            ...prev,
            image: data.payload.baseUrl + data.payload.imageUrl,
            featureImage: data.payload.baseUrl + data.payload.imageUrl,
          };
        });
        setFormData((prev) => {
          return {
            ...prev,
            image: data.payload.baseUrl + data.payload.imageUrl,
            featureImage: data.payload.baseUrl + data.payload.imageUrl,
          };
        });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const backImageUploadImages = async (file) => {
    setLoaderShow(true);
    try {
      const Image = await imageUpload(file);
      setSettingFormData((prev) => {
        return {
          ...prev,
          ".product-features-section.bg-image": {
            "background-image": Image,
          },
        };
      });
      setLoaderShow(false);
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };
  const imageUpload = async (file) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.ITEM_FEATURE);
    formData.append("file", file);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        return data.payload.baseUrl + data.payload.imageUrl;
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };
  /**
   * create and update item feature
   */

  const submitForm = async () => {
    if (
      (isParent === false && childId >= -1 && isEdit) ||
      (id >= -1 && isEdit)
    ) {
      setLoaderShow(true);
      try {
        let formData = {};
        if (isParent && isParent !== false) {
          formData = { ...fromData, itemFeatureId: id, contentMapId: collId };
          delete formData.image;
          if (formData?.featureImage?.length) {
            formData["featureImage"] = formData.featureImage
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
              .pop();
          }
        } else {
          formData = {
            ...formDataChild,
            parentId: id,
            navMapId,
            itemFeatureId: childId,
            contentMapId: collId,
          };
          delete formData.image;
          if (formDataChild?.featureImage?.length) {
            formData["featureImage"] = formDataChild.featureImage
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
              .pop();
          }
        }
        const { data } = await putUpdateItemFeature(formData);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            ByteMiniWeb_Dashboard();
          } else {
            getItemFeatureParentList({ parentId: id });
          }
          emptyData();
          OpenModalCommonFunc(
            Cases.ITEM_FEATURE_PARENT,
            setAllPopupState,
            false
          );
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    } else {
      try {
        setLoaderShow(true);
        let formData = {};
        if (isParent && isParent !== false) {
          formData = { ...fromData, contentMapId: collId };
          delete formData.image;
          if (formData.featureImage?.length) {
            formData["featureImage"] = formData.featureImage
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
              .pop();
          }
        } else {
          formData = {
            ...formDataChild,
            parentId: id,
            navMapId,
            order: apiDataImageParent.data.length + 1,
            contentMapId: collId,
          };
          delete formData.image;
          if (formDataChild.featureImage?.length) {
            formData["featureImage"] = formDataChild.featureImage
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
              .pop();
          }
        }
        const { data } = await postItemFeature(formData);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            ByteMiniWeb_Dashboard();
          } else {
            getItemFeatureParentList({ parentId: id });
          }
          emptyData();
          OpenModalCommonFunc(
            Cases.ITEM_FEATURE_PARENT,
            setAllPopupState,
            false
          );
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  const handleSubmit = async (formValidationCondition) => {
    let validatorFields;
    if (formValidationCondition === "parentForm") {
      validatorFields = parentValidator.current;
    } else if (formValidationCondition === "childForm") {
      validatorFields = childValidator.current;
    }
    validatorFields.showMessages();

    if (validatorFields.allValid()) {
      try {
        // Process form data based on the formType
        if (formValidationCondition === "parentForm") {
          submitForm();
        } else if (formValidationCondition === "childForm") {
          submitForm();
        }
        validatorFields.hideMessages();
      } catch (error) {
        // Handle error for form submission
      }
    } else {
      // Force update to display validation messages
      forceUpdate({});
    }
  };

  /**
   * create and update images
   */
  const handleSubmitSetting = async (isRestore = false, isClose = true) => {
    if (id >= -1 && isEdit) {
      setLoaderShow(true);
      try {
        let newData;
        if (isRestore) {
          const isConfirm = window.confirm(
            "Are you sure want to restore style ?"
          );
          if (isConfirm) {
            newData = {
              styleRestore: true,
              itemFeatureId: id,
            };
          } else {
            setLoaderShow(false);
            return;
          }
        } else {
          if (+theme === ThemeNo.ThemeOne) {
            newData = ItemFeatureStyleOneSubFun({
              settingFormData,
              boxSection,
            });
            if (isBackgroundColor) {
              newData[".product-features-section"] = {
                ...newData?.[".product-features-section"],
                "background-color": settingFormData[
                  ".product-features-section"
                ]?.["background-color"]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData[".product-features-section"]?.[
                          "background-color"
                        ],
                    })
                  : "",
              };
            }
            if (boxSection) {
              newData[".product-features-section.bg-image::before"] = {
                ...newData[".product-features-section.bg-image::before"],
                "border-radius": `${settingFormData[
                  ".product-features-section"
                ]["border-radius"]?.replace("rem", "")}rem`,
              };
            }
          }

          const keysToRemove = ["bs1", "ps2", "ps3", "p1", "p2", "image"];
          function removeKeys(obj, keys) {
            for (const key in obj) {
              if (typeof obj[key] === "object") {
                removeKeys(obj[key], keys);
              } else if (keys.includes(key)) {
                delete obj[key];
              }
            }
          }
          removeKeys(newData, keysToRemove);
          newData = {
            style: newData,
            itemFeatureId: id,
          };
        }

        const { data } = await putUpdateItemFeature(newData);
        if (data.status === 200) {
          setIsParent(true);
          if (isClose) {
            OpenModalCommonFunc(
              Cases.SETTING_ITEM_PARENT,
              setAllPopupState,
              false
            );
          }
          setLoaderShow(false);
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  /**
   * get data item feature
   * @param {id} itemFeatureId
   */
  const handleEditItemFeature = async (itemFeatureId, isParent) => {
    try {
      setLoaderShow(true);
      const { data } = await getItemFeatureDetails(itemFeatureId);
      if (data.status === 200) {
        let payload = data.payload.data;
        if (isParent) {
          setSectionPageName(payload?.title);
          setId(itemFeatureId);
          setIsParent(true);
          setFormData({
            title: payload?.title || "",
            subTitle: payload?.subTitle || "",
            navMapId: payload?.navMapId || "",
            image: payload?.featureImage || "",
            isTitleShow: payload?.isTitleShow ? payload?.isTitleShow : false,
            featureImage: payload?.featureImage || "",
            style: payload?.style || "",
          });
          OpenModalCommonFunc(Cases.ITEM_FEATURE_PARENT, setAllPopupState);
        } else {
          setChildId(itemFeatureId);
          setIsParent(false);
          setFormDataChild({
            title: payload?.title || "",
            subTitle: payload?.subTitle || "",
            isTitleShow: payload?.isTitleShow ? payload?.isTitleShow : false,
            order: payload?.order || 1,
            image: payload?.featureImage || "",
            featureImage: payload?.featureImage || "",
          });
          OpenModalCommonFunc(Cases.ITEM_FEATURE, setAllPopupState);
        }
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * get setting data
   * @param {id} itemFeatureId
   */
  const handleChangeSettings = async (itemFeatureId, isThemeEdit = false) => {
    try {
      setLoaderShow(true);
      setId(itemFeatureId);
      setSettingFormData((prev) => {
        return {
          ...prev,
          itemFeatureId,
        };
      });
      const { data } = await getItemFeatureDetails(itemFeatureId);
      if (data.status === 200) {
        const themeCount = data?.payload?.data?.theme;
        setTheme(themeCount);
        setImgShow(true);
        let payload = data.payload.data;
        setSectionPageName(payload.title);
        if (
          +themeCount === ThemeNo.ThemeOne &&
          isEmptyObject(data?.payload?.data?.style)
        ) {
          setSettingFormData({ ...ItemFeatureThemeOneReset });
          setBoxSection(false);
          setIsBackgroundColor(false);
          setBackImageToggle(false);
        } else {
          if (
            payload.style?.[".product-features-section"]?.["width"] ||
            payload.style?.[".product-features-section"]?.["margin"] ||
            payload.style?.[".product-features-section"]?.["border-radius"]
          ) {
            setBoxSection(true);
          }
          if (
            payload.style?.[".product-features-section.bg-image"]?.[
              "background-image"
            ]
          ) {
            setBackImageToggle(true);
          }
          if (
            payload.style?.[".product-features-section"]?.["background-color"]
          ) {
            setIsBackgroundColor(true);
          }
          let getStyle;
          if (+themeCount === ThemeNo.ThemeOne) {
            getStyle = ItemFeatureThemeOneFunction(payload);
          } else {
            getStyle = ItemFeatureThemeOneFunction(payload);
          }
          setSettingFormData((prev) => {
            return {
              ...prev,
              ...getStyle,
            };
          });
        }
        if (!isThemeEdit) {
          OpenModalCommonFunc(Cases.SETTING_ITEM_PARENT, setAllPopupState);
          TostMessage({ message: data, type: "success" });
        }
        setIsEdit(true);
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * delete item feature
   * @param {id} itemFeatureId
   */
  const handleDelete = async (itemFeatureId, isParent = false) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await deleteItemFeature(itemFeatureId);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            ByteMiniWeb_Dashboard();
          } else {
            getItemFeatureParentList({ parentId: childId });
          }

          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * clear data
   */
  const emptyData = () => {
    setIsBackgroundColor(false);
    setBoxSection(false);
    setBackImageToggle(false);
    setIsEdit(false);
    setIsParent(false);
    setChildId("");
    setFormData({
      title: "",
      subTitle: "",
      isTitleShow: true,
      navMapId: "",
      image: "",
      featureImage: "",
      webId: localStorage.getItem("webId"),
      style: {
        ".product-features-section": {
          padding: "100px 0",
          "background-color": "rgb(255 255 255 / 100)",
        },
        ".product-features-section.bg-image": {
          /* "background-image": "url(../images/bg-1.jpg)" */
        },
        ".product-features-section.bg-image::before": {
          /* background-color: "rgba(0, 0, 0, 0.8)" */
        },
        ".product-features-section .section-title-area": {
          "margin-bottom": "50px",
        },
        ".product-features-section .section-title-area .section-subtitle": {
          "font-size": "16px",
          "font-weight": "600",
          color: "rgb(255 73 124 / 100)",
          "background-color": "rgba(255, 73, 124, 0.2)",
          padding: "5px 20px",
          "border-radius": "15px",
          "margin-bottom": "15px",
        },
        ".product-features-section .section-title-area .section-title": {
          "font-size": "44px",
          "font-weight": "600",
          color: "rgb(0 0 0 / 100)",
        },
        ".product-features-section .feature-list": {
          padding: "20px",
          margin: "10px 0",
          "background-color": "rgb(255 255 255 / 100)",
          "box-shadow": "0 0px 15px rgba(0, 0, 0, 0.094)",
          shadowColor: "rgba(0, 0, 0, 0.094)",
          "border-radius": "10px",
        },
        ".product-features-section .feature-list h5": {
          "font-size": "20px",
          "font-weight": "600",
          color: "rgb(255 73 124 / 100)",
          "margin-bottom": "10px",
        },
        ".product-features-section .feature-list p": {
          "font-size": "16px",
          "font-weight": "normal",
          color: "rgb(102 102 102 / 100)",
          "line-height": "1.4",
          "text-align": "justify",
        },
      },
    });
    setSkill([{ id: randomStr(2), skill: "", parentageOfSkill: "" }]);
    setGalleryImgId("");
    setFormDataChild({
      featureImage: "",
      image: "",
      title: "",
      subTitle: "",
      isTitleShow: true,
      order: 1,
      webId: localStorage.getItem("webId"),
    });
    setLoaderShow(false);
    setSettingFormData({
      ".product-features-section": {
        padding: "100px 0",
        "background-color": "rgb(255 255 255 / 100)",
      },
      ".product-features-section.bg-image": {
        /* "background-image": "url(../images/bg-1.jpg)" */
      },
      ".product-features-section.bg-image::before": {
        /* background-color: "rgba(0, 0, 0, 0.8)" */
      },
      ".product-features-section .section-title-area": {
        "margin-bottom": "50px",
      },
      ".product-features-section .section-title-area .section-subtitle": {
        "font-size": "16px",
        "font-weight": "600",
        color: "rgb(255 73 124 / 100)",
        "background-color": "rgba(255, 73, 124, 0.2)",
        padding: "5px 20px",
        "border-radius": "15px",
        "margin-bottom": "15px",
      },
      ".product-features-section .section-title-area .section-title": {
        "font-size": "44px",
        "font-weight": "600",
        color: "rgb(0 0 0 / 100)",
      },
      ".product-features-section .feature-list": {
        padding: "20px",
        margin: "10px 0",
        "background-color": "rgb(255 255 255 / 100)",
        "box-shadow": "0 0px 15px rgba(0, 0, 0, 0.094)",
        shadowColor: "rgba(0, 0, 0, 0.094)",
        "border-radius": "10px",
      },
      ".product-features-section .feature-list h5": {
        "font-size": "20px",
        "font-weight": "600",
        color: "rgb(255 73 124 / 100)",
        "margin-bottom": "10px",
      },
      ".product-features-section .feature-list p": {
        "font-size": "16px",
        "font-weight": "normal",
        color: "rgb(102 102 102 / 100)",
        "line-height": "1.4",
        "text-align": "justify",
      },
      itemFeatureId: "",
    });
  };

  let value = useMemo(() => {
    let [label] = navbarData.filter(
      (elem) => elem.navbarDetail.label === titleUpdateKey.Certificates
    );
    return label;
  }, [navbarData]);

  const handleAlignAndCloneSection = ({ isClone = false }) => {
    if (isClone) {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          cloneModal: true,
        };
      });
      setCloneFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          pageConfigDetailsLabel: value?.label || pageConfigDetails[0]?.label,
          contentDetailName: contentDetail.name,
        };
      });
    } else {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          alignModal: true,
        };
      });
      setAlignFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          label: value?.label || pageConfigDetails[0]?.label,
        };
      });
    }
  };

  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody
            className="support-ticket-font box-border-div"
            style={{ padding: "20px" }}
          >
            <Row>
              <CardBuilderHeaderSection
                addBtnTitle={pageConfigDetails[0]?.label}
                name={value?.label || pageConfigDetails[0]?.label}
                isAdd={!itemFeatureDetail && true}
                mainEditSection={true}
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
                openModal={() => {
                  OpenModalCommonFunc(
                    Cases.ITEM_FEATURE_PARENT,
                    setAllPopupState
                  );
                  setSectionPageName(modalsName.ITEM_FEATURE_PARENT);
                  emptyData();
                  setIsParent(true);
                  setCollId(contentMapId);
                }}
                isCloneSection={true}
                isAlignSection={true}
                handleAlignAndCloneSection={handleAlignAndCloneSection}
              />
              {itemFeatureDetail && (
                <Col xl="12 xl-50 box-col-6" md="6">
                  <Card className="ecommerce-widget mb-0">
                    <CardBody
                      className="support-ticket-font"
                      style={{ padding: "20px" }}
                    >
                      <div className="col-12">
                        <CardBuilderHeaderSection
                          name={itemFeatureDetail?.title}
                          checked={itemFeatureDetail?.isActive}
                          switchToggle={true}
                          id={itemFeatureDetail?.itemFeatureId}
                          handleChange={handleChangeItemFeature}
                        />
                        <OpenPopupBtn
                          openPopup={() => {
                            setSectionPageName(itemFeatureDetail.title);
                            getItemFeatureParentList({
                              parentId: itemFeatureDetail.itemFeatureId,
                            });
                            setFilter("");
                            setNavMapId(itemFeatureDetail?.navMapId);
                            setCollId(contentMapId);
                          }}
                          name={`Add or View ${itemFeatureDetail?.title}`}
                        />
                      </div>
                      <div className="d-flex justify-content-end ">
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleEditItemFeature(
                              itemFeatureDetail.itemFeatureId,
                              true
                            );
                            setCollId(contentMapId);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() =>
                            handleDelete(itemFeatureDetail.itemFeatureId, true)
                          }
                        >
                          <Trash />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleChangeSettings(
                              itemFeatureDetail.itemFeatureId
                            );
                          }}
                        >
                          <Settings />
                        </IconButton>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
              <DeleteSection
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
              />
            </Row>
          </CardBody>
        </Card>
      </Col>
      <CommonPopup
        open={allPopupState.itemFeatureChildPopup}
        close={() => {
          OpenModalCommonFunc(Cases.ITEM_FEATURE, setAllPopupState, false);
          setIsParent(false);
        }}
        // apiCall={getItemFeatureParentList}
        modalName={sectionPageName ? sectionPageName : modalsName.ITEM_FEATURE}
        modalCase={Cases.ITEM_FEATURE}
        handleSubmit={handleSubmit}
        validationForm={"childForm"}
        emptyData={emptyData}
        inputData={fromData}
        edit={false}
        oldTitle={titleUpdateKey.isGallery}
        label={titleUpdateKey.Certificates}
        getData={getData}
        content={
          <ItemFeaturePopup
            getItemFeatureParentList={getItemFeatureParentList}
            handleChangeStatus={handleChangeItemFeature}
            handleEdit={handleEditItemFeature}
            imageUpload={imageUploadImages}
            emptyData={emptyData}
            simpleValidator={childValidator}
            webMasterDetail={webMasterDetail}
            handleDelete={handleDelete}
          />
        }
      />

      {/* item feature parent popup */}
      <CommonPopup
        open={allPopupState.itemFeaturePopup}
        close={() => {
          OpenModalCommonFunc(
            Cases.ITEM_FEATURE_PARENT,
            setAllPopupState,
            false
          );
          setIsParent(false);
        }}
        modalName={
          sectionPageName ? sectionPageName : modalsName.ITEM_FEATURE_PARENT
        }
        modalCase={Cases.ITEM_FEATURE_PARENT}
        handleSubmit={handleSubmit}
        validationForm={"parentForm"}
        emptyData={emptyData}
        inputData={formDataChild}
        edit={false}
        content={
          <ItemFeatureParentPopup
            simpleValidator={parentValidator}
            imageUpload={imageUploadImages}
          />
        }
      />

      {/* setting images parent popup */}
      <CommonPopup
        open={allPopupState.itemSettingPopup}
        close={() =>
          OpenModalCommonFunc(
            Cases.SETTING_ITEM_PARENT,
            setAllPopupState,
            false
          )
        }
        modalName={
          sectionPageName ? sectionPageName : modalsName.SETTING_ITEM_PARENT
        }
        modalCase={Cases.SETTING_ITEM_PARENT}
        handleSubmit={handleSubmitSetting}
        emptyData={emptyData}
        inputData={formDataChild}
        edit={false}
        content={
          <SettingImageParentPopup
            imageUpload={backImageUploadImages}
            handleSubmitSetting={handleSubmitSetting}
            handleChangeSettings={handleChangeSettings}
          />
        }
      />
    </>
  );
};

export default ItemFeature;
