import { convertRGBA } from "../../../../utils/helper";

export const getProductStyle = (payload) => {
  return {
    ".ecom-products-section .product-catlist": {
      "text-align":
        payload?.[".ecom-products-section .product-catlist"]?.["text-align"],
      "margin-bottom":
        payload?.[".ecom-products-section .product-catlist"]?.["margin-bottom"],
    },
    ".ecom-products-section .product-catlist .btn": {
      border:
        payload?.[".ecom-products-section .product-catlist .btn"]?.["border"],
      borderPx:
        payload?.[".ecom-products-section .product-catlist .btn"]?.[
          "border"
        ]?.split(" ")[0],
      borderThik:
        payload?.[".ecom-products-section .product-catlist .btn"]?.[
          "border"
        ]?.split(" ")[1],
      borderColor: payload?.[".ecom-products-section .product-catlist .btn"]?.[
        "borderColor"
      ]
        ? convertRGBA({
            rgbString:
              payload?.[".ecom-products-section .product-catlist .btn"]?.[
                "borderColor"
              ],
          })
        : "",
      color: payload?.[".ecom-products-section .product-catlist .btn"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString:
              payload?.[".ecom-products-section .product-catlist .btn"]?.[
                "color"
              ],
          })
        : "",
      padding:
        payload?.[".ecom-products-section .product-catlist .btn"]?.["padding"],
      p1: payload?.[".ecom-products-section .product-catlist .btn"]?.[
        "padding"
      ]?.split(" ")[0],
      p2: payload?.[".ecom-products-section .product-catlist .btn"]?.[
        "padding"
      ]?.split(" ")[1],
      margin:
        payload?.[".ecom-products-section .product-catlist .btn"]?.["margin"],
    },
    ".ecom-products-section .product-catlist .active": {
      "background-color": payload?.[
        ".ecom-products-section .product-catlist .active"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload?.[".ecom-products-section .product-catlist .active"]?.[
                "background-color"
              ],
          })
        : "",
      color: payload?.[".ecom-products-section .product-catlist .active"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString:
              payload?.[".ecom-products-section .product-catlist .active"]?.[
                "color"
              ],
          })
        : "",
    },
    ".ecom-products-section .product-catdata .box": {
      "margin-bottom":
        payload?.[".ecom-products-section .product-catdata .box"]?.[
          "margin-bottom"
        ],
      outline:
        payload?.[".ecom-products-section .product-catdata .box"]?.["outline"],
      "background-color": payload?.[
        ".ecom-products-section .product-catdata .box"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload?.[".ecom-products-section .product-catdata .box"]?.[
                "background-color"
              ],
          })
        : "",
      "box-shadow":
        payload?.[".ecom-products-section .product-catdata .box"]?.[
          "box-shadow"
        ],
      bs1: payload?.[".ecom-products-section .product-catdata .box"]?.[
        "box-shadow"
      ]?.split(" ")[0],
      bs2: payload?.[".ecom-products-section .product-catdata .box"]?.[
        "box-shadow"
      ]?.split(" ")[1],
      bs3: payload?.[".ecom-products-section .product-catdata .box"]?.[
        "box-shadow"
      ]?.split(" ")[2],
      bs4: payload?.[".ecom-products-section .product-catdata .box"]?.[
        "box-shadow"
      ]?.split(" ")[3],
      shedowColor: payload?.[".ecom-products-section .product-catdata .box"]?.[
        "shedowColor"
      ]
        ? convertRGBA({
            rgbString:
              payload?.[".ecom-products-section .product-catdata .box"]?.[
                "shedowColor"
              ],
          })
        : "",
      "border-radius":
        payload?.[".ecom-products-section .product-catdata .box"]?.[
          "border-radius"
        ],
    },
    ".ecom-products-section .product-catdata .product-img .taglabel": {
      "background-color": payload?.[
        ".ecom-products-section .product-catdata .product-img .taglabel"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload?.[
                ".ecom-products-section .product-catdata .product-img .taglabel"
              ]?.["background-color"],
          })
        : "",
      color: payload?.[
        ".ecom-products-section .product-catdata .product-img .taglabel"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload?.[
                ".ecom-products-section .product-catdata .product-img .taglabel"
              ]?.["color"],
          })
        : "",
      "font-size":
        payload?.[
          ".ecom-products-section .product-catdata .product-img .taglabel"
        ]?.["font-size"],
      "font-weight":
        payload?.[
          ".ecom-products-section .product-catdata .product-img .taglabel"
        ]?.["font-weight"],
      padding:
        payload?.[
          ".ecom-products-section .product-catdata .product-img .taglabel"
        ]?.["padding"],
      p1: payload?.[
        ".ecom-products-section .product-catdata .product-img .taglabel"
      ]?.["padding"]?.split(" ")[0],
      p2: payload?.[
        ".ecom-products-section .product-catdata .product-img .taglabel"
      ]?.["padding"]?.split(" ")[1],
      "border-radius": "5px",
      top: payload?.[
        ".ecom-products-section .product-catdata .product-img .taglabel"
      ]?.["top"],
      left: payload?.[
        ".ecom-products-section .product-catdata .product-img .taglabel"
      ]?.["left"],
    },
    ".ecom-products-section .product-catdata .product-img .post-date": {
      "background-color": payload?.[
        ".ecom-products-section .product-catdata .product-img .post-date"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload?.[
                ".ecom-products-section .product-catdata .product-img .post-date"
              ]?.["background-color"],
          })
        : "",
      color: payload?.[
        ".ecom-products-section .product-catdata .product-img .post-date"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload?.[
                ".ecom-products-section .product-catdata .product-img .post-date"
              ]?.["color"],
          })
        : "",
      "border-radius":
        payload?.[
          ".ecom-products-section .product-catdata .product-img .post-date"
        ]?.["border-radius"],
      "font-size":
        payload?.[
          ".ecom-products-section .product-catdata .product-img .post-date"
        ]?.["font-size"],
      "font-weight":
        payload?.[
          ".ecom-products-section .product-catdata .product-img .post-date"
        ]?.["font-weight"],
      padding:
        payload?.[
          ".ecom-products-section .product-catdata .product-img .post-date"
        ]?.["padding"],
      p1: payload?.[
        ".ecom-products-section .product-catdata .product-img .post-date"
      ]?.["padding"]?.split(" ")[0],
      p2: payload?.[
        ".ecom-products-section .product-catdata .product-img .post-date"
      ]?.["padding"]?.split(" ")[1],
      "text-align":
        payload?.[
          ".ecom-products-section .product-catdata .product-img .post-date"
        ]?.["text-align"],
      top: payload?.[
        ".ecom-products-section .product-catdata .product-img .post-date"
      ]?.["top"],
      right:
        payload?.[
          ".ecom-products-section .product-catdata .product-img .post-date"
        ]?.["right"],
    },
    ".ecom-products-section .product-catdata .product-content": {
      padding:
        payload?.[".ecom-products-section .product-catdata .product-content"]?.[
          "padding"
        ],
      float:
        payload?.[".ecom-products-section .product-catdata .product-content"]?.[
          "float"
        ],
      width:
        payload?.[".ecom-products-section .product-catdata .product-content"]?.[
          "width"
        ],
      "text-align":
        payload?.[".ecom-products-section .product-catdata .product-content"]?.[
          "text-align"
        ],
    },
    ".ecom-products-section .product-catdata .product-content .shop a": {
      color: payload?.[
        ".ecom-products-section .product-catdata .product-content .shop a"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload?.[
                ".ecom-products-section .product-catdata .product-content .shop a"
              ]?.["color"],
          })
        : "",
      "font-size":
        payload?.[
          ".ecom-products-section .product-catdata .product-content .shop a"
        ]?.["font-size"],
    },
    ".ecom-products-section .product-catdata .product-content .shop a:hover": {
      color: payload?.[
        ".ecom-products-section .product-catdata .product-content .shop a:hover"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload?.[
                ".ecom-products-section .product-catdata .product-content .shop a:hover"
              ]?.["color"],
          })
        : "",
    },
    ".ecom-products-section .product-catdata .product-content .price": {
      color: payload?.[
        ".ecom-products-section .product-catdata .product-content .price"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload?.[
                ".ecom-products-section .product-catdata .product-content .price"
              ]?.["color"],
          })
        : "",
      margin:
        payload?.[
          ".ecom-products-section .product-catdata .product-content .price"
        ]?.["margin"],
      m1: payload?.[
        ".ecom-products-section .product-catdata .product-content .price"
      ]?.["margin"]?.split(" ")[0],
      m2: payload?.[
        ".ecom-products-section .product-catdata .product-content .price"
      ]?.["margin"]?.split(" ")[1],
      m3: payload?.[
        ".ecom-products-section .product-catdata .product-content .price"
      ]?.["margin"]?.split(" ")[2],
      m4: payload?.[
        ".ecom-products-section .product-catdata .product-content .price"
      ]?.["margin"]?.split(" ")[3],
    },
    ".ecom-products-section .product-catdata .product-content .price del": {
      color: payload?.[
        ".ecom-products-section .product-catdata .product-content .price del"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload?.[
                ".ecom-products-section .product-catdata .product-content .price del"
              ]?.["color"],
          })
        : "",
      "margin-right":
        payload?.[
          ".ecom-products-section .product-catdata .product-content .price del"
        ]?.["margin-right"],
    },
    ".ecom-products-section .product-catdata .product-content .price ins": {
      "text-decoration":
        payload?.[
          ".ecom-products-section .product-catdata .product-content .price ins"
        ]?.["text-decoration"],
      color: payload?.[
        ".ecom-products-section .product-catdata .product-content .price ins"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload?.[
                ".ecom-products-section .product-catdata .product-content .price ins"
              ]?.["color"],
          })
        : "",
    },
    ".ecom-products-section .product-catdata .product-content .add-cart": {
      border:
        payload?.[
          ".ecom-products-section .product-catdata .product-content .add-cart"
        ]?.["border"],
      borderPx:
        payload?.[
          ".ecom-products-section .product-catdata .product-content .add-cart"
        ]?.["border"]?.split(" ")[0],
      borderThik:
        payload?.[
          ".ecom-products-section .product-catdata .product-content .add-cart"
        ]?.["border"]?.split(" ")[1],
      borderColor: payload?.[
        ".ecom-products-section .product-catdata .product-content .add-cart"
      ]?.["borderColor"]
        ? convertRGBA({
            rgbString:
              payload?.[
                ".ecom-products-section .product-catdata .product-content .add-cart"
              ]?.["borderColor"],
          })
        : "",
      "background-color": payload?.[
        ".ecom-products-section .product-catdata .product-content .add-cart"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload?.[
                ".ecom-products-section .product-catdata .product-content .add-cart"
              ]?.["background-color"],
          })
        : "",
      color: payload?.[
        ".ecom-products-section .product-catdata .product-content .add-cart"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload?.[
                ".ecom-products-section .product-catdata .product-content .add-cart"
              ]?.["color"],
          })
        : "",
      "font-size":
        payload?.[
          ".ecom-products-section .product-catdata .product-content .add-cart"
        ]?.["font-size"],
      "font-weight":
        payload?.[
          ".ecom-products-section .product-catdata .product-content .add-cart"
        ]?.["font-weight"],
      padding:
        payload?.[
          ".ecom-products-section .product-catdata .product-content .add-cart"
        ]?.["padding"],
      p1: payload?.[
        ".ecom-products-section .product-catdata .product-content .add-cart"
      ]?.["padding"]?.split(" ")[0],
      p2: payload?.[
        ".ecom-products-section .product-catdata .product-content .add-cart"
      ]?.["padding"]?.split(" ")[1],
    },
    ".ecom-products-section .product-catdata .product-content .add-cart:hover":
      {
        opacity:
          payload?.[
            ".ecom-products-section .product-catdata .product-content .add-cart:hover"
          ]?.["opacity"],
      },
    ".shop-section": {
      "background-color": payload?.[
        ".ecom-products-section .product-catdata .product-content .add-cart"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload?.[
                ".ecom-products-section .product-catdata .product-content .add-cart"
              ]?.["background-color"],
          })
        : "",
    },
    ".shop-section .filter-switch": {
      "border-bottom":
        payload?.[".shop-section .filter-switch"]?.["border-bottom"],
      borderPx:
        payload?.[".shop-section .filter-switch"]?.["border-bottom"]?.split(
          " "
        )[0],
      borderThik:
        payload?.[".shop-section .filter-switch"]?.["border-bottom"]?.split(
          " "
        )[1],
      borderColor:
        payload?.[".shop-section .filter-switch"]?.["border-bottom"]?.split(
          " "
        )[2],
      borderColor: payload?.[".shop-section .filter-switch"]?.["borderColor"]
        ? convertRGBA({
            rgbString:
              payload?.[".shop-section .filter-switch"]?.["borderColor"],
          })
        : "",
    },
    ".shop-section .filter-switch .switch-text": {
      color: payload?.[".shop-section .filter-switch .switch-text"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload?.[".shop-section .filter-switch .switch-text"]?.["color"],
          })
        : "",
      "font-size":
        payload?.[".shop-section .filter-switch .switch-text"]?.["font-size"],
      "font-weight":
        payload?.[".shop-section .filter-switch .switch-text"]?.["font-weight"],
    },
    ".shop-section .filter-switch .switch-toggle": {
      "background-color": payload?.[
        ".shop-section .filter-switch .switch-toggle"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload?.[".shop-section .filter-switch .switch-toggle"]?.[
                "background-color"
              ],
          })
        : "",
      "border-radius":
        payload?.[".shop-section .filter-switch .switch-toggle"]?.[
          "border-radius"
        ],
    },
    ".shop-section .filter-switch .switch-toggle.opened": {
      "background-color": payload?.[
        ".shop-section .filter-switch .switch-toggle.opened"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload?.[".shop-section .filter-switch .switch-toggle.opened"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".ecom-products-section .more-btn": {
      color: payload?.[".ecom-products-section .more-btn"]?.["color"]
        ? convertRGBA({
            rgbString: payload?.[".ecom-products-section .more-btn"]?.["color"],
          })
        : "",
      "background-color": payload?.[".ecom-products-section .more-btn"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload?.[".ecom-products-section .more-btn"]?.[
                "background-color"
              ],
          })
        : "",
      "font-size": payload?.[".ecom-products-section .more-btn"]?.["font-size"],
      "font-weight":
        payload?.[".ecom-products-section .more-btn"]?.["font-weight"],
      "border-radius":
        payload?.[".ecom-products-section .more-btn"]?.["border-radius"],
      padding: payload?.[".ecom-products-section .more-btn"]?.["padding"],
      p1: payload?.[".ecom-products-section .more-btn"]?.[
        "padding"
      ]?.split(" ")[0],
      p2: payload?.[".ecom-products-section .more-btn"]?.[
        "padding"
      ]?.split(" ")[1],
    },
    ".ecom-products-section .more-btn:hover": {
      color: payload?.[".ecom-products-section .more-btn:hover"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload?.[".ecom-products-section .more-btn:hover"]?.["color"],
          })
        : "",
      "background-color": payload?.[".ecom-products-section .more-btn:hover"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload?.[".ecom-products-section .more-btn:hover"]?.[
                "background-color"
              ],
          })
        : "",
    },
    custom: payload.custom,
  };
};
export const getProductPageStyle = (payload) => {
  return {
    ".single-product-section": {
      padding: payload?.[".single-product-section"]?.["padding"],
      "background-color": payload?.[".single-product-section"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload?.[".single-product-section"]?.["background-color"],
          })
        : "",
    },
    ".single-product-section .subbox-div": {
      padding: payload?.[".single-product-section .subbox-div"]?.["padding"],
      "background-color": payload?.[".single-product-section .subbox-div"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload?.[".single-product-section .subbox-div"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".single-product-section .subbox-div .taglabel": {
      "background-color": payload?.[
        ".single-product-section .subbox-div .taglabel"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload?.[".single-product-section .subbox-div .taglabel"]?.[
                "background-color"
              ],
          })
        : "",
      color: payload?.[".single-product-section .subbox-div .taglabel"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString:
              payload?.[".single-product-section .subbox-div .taglabel"]?.[
                "color"
              ],
          })
        : "",
      "font-size":
        payload?.[".single-product-section .subbox-div .taglabel"]?.[
          "font-size"
        ],
      "font-weight":
        payload?.[".single-product-section .subbox-div .taglabel"]?.[
          "font-weight"
        ],
      padding:
        payload?.[".single-product-section .subbox-div .taglabel"]?.["padding"],
      p1: payload?.[".single-product-section .subbox-div .taglabel"]?.[
        "padding"
      ]?.split(" ")[0],
      p2: payload?.[".single-product-section .subbox-div .taglabel"]?.[
        "padding"
      ]?.split(" ")[1],
      "border-radius":
        payload?.[".single-product-section .subbox-div .taglabel"]?.[
          "border-radius"
        ],
    },
    ".single-product-section .preview-thumbnail": {
      margin:
        payload?.[".single-product-section .preview-thumbnail"]?.["margin"],
    },
    ".single-product-section .preview-thumbnail li .active": {
      border:
        payload?.[".single-product-section .preview-thumbnail li .active"]?.[
          "border"
        ],
      borderPx:
        payload?.[".single-product-section .preview-thumbnail li .active"]?.[
          "border"
        ]?.split(" ")[0],
      borderThik:
        payload?.[".single-product-section .preview-thumbnail li .active"]?.[
          "border"
        ]?.split(" ")[1],
      borderColor: payload?.[
        ".single-product-section .preview-thumbnail li .active"
      ]?.["borderColor"]
        ? convertRGBA({
            rgbString:
              payload?.[
                ".single-product-section .preview-thumbnail li .active"
              ]?.["borderColor"],
          })
        : "",
    },
    ".single-product-section .single-proinfo .product-title": {
      "margin-bottom":
        payload?.[".single-product-section .single-proinfo .product-title"]?.[
          "margin-bottom"
        ],
    },
    ".single-product-section .single-proinfo .product-description": {
      "margin-bottom":
        payload?.[
          ".single-product-section .single-proinfo .product-description"
        ]?.["margin-bottom"],
    },
    ".single-product-section .single-proinfo .price": {
      color: payload?.[".single-product-section .single-proinfo .price"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString:
              payload?.[".single-product-section .single-proinfo .price"]?.[
                "color"
              ],
          })
        : "",
      "margin-bottom":
        payload?.[".single-product-section .single-proinfo .price"]?.[
          "margin-bottom"
        ],
    },
    ".single-product-section .single-proinfo .add-to-cart": {
      border:
        payload?.[".single-product-section .single-proinfo .add-to-cart"]?.[
          "border"
        ],
      borderPx:
        payload?.[".single-product-section .single-proinfo .add-to-cart"]?.[
          "border"
        ]?.split(" ")[0],
      borderThik:
        payload?.[".single-product-section .single-proinfo .add-to-cart"]?.[
          "border"
        ]?.split(" ")[1],
      borderColor: payload?.[
        ".single-product-section .single-proinfo .add-to-cart"
      ]?.["borderColor"]
        ? convertRGBA({
            rgbString:
              payload?.[
                ".single-product-section .single-proinfo .add-to-cart"
              ]?.["borderColor"],
          })
        : "",
      "background-color": payload?.[
        ".single-product-section .single-proinfo .add-to-cart"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload?.[
                ".single-product-section .single-proinfo .add-to-cart"
              ]?.["background-color"],
          })
        : "",
      color: payload?.[
        ".single-product-section .single-proinfo .add-to-cart"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload?.[
                ".single-product-section .single-proinfo .add-to-cart"
              ]?.["color"],
          })
        : "",
      padding:
        payload?.[".single-product-section .single-proinfo .add-to-cart"]?.[
          "padding"
        ],
      p1: payload?.[".single-product-section .single-proinfo .add-to-cart"]?.[
        "padding"
      ]?.split(" ")[0],
      p2: payload?.[".single-product-section .single-proinfo .add-to-cart"]?.[
        "padding"
      ]?.split(" ")[1],
    },
    ".single-product-section .single-proinfo .add-inquiry": {
      border:
        payload?.[".single-product-section .single-proinfo .add-inquiry"]?.[
          "border"
        ],
      borderPx:
        payload?.[".single-product-section .single-proinfo .add-inquiry"]?.[
          "border"
        ]?.split(" ")[0],
      borderThik:
        payload?.[".single-product-section .single-proinfo .add-inquiry"]?.[
          "border"
        ]?.split(" ")[1],
      borderColor: payload?.[
        ".single-product-section .single-proinfo .add-inquiry"
      ]?.["borderColor"]
        ? convertRGBA({
            rgbString:
              payload?.[
                ".single-product-section .single-proinfo .add-inquiry"
              ]?.["borderColor"],
          })
        : "",
      "background-color": payload?.[
        ".single-product-section .single-proinfo .add-inquiry"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload?.[
                ".single-product-section .single-proinfo .add-inquiry"
              ]?.["background-color"],
          })
        : "",
      color: payload?.[
        ".single-product-section .single-proinfo .add-inquiry"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload?.[
                ".single-product-section .single-proinfo .add-inquiry"
              ]?.["color"],
          })
        : "",
      padding:
        payload?.[".single-product-section .single-proinfo .add-inquiry"]?.[
          "padding"
        ],
      p1: payload?.[".single-product-section .single-proinfo .add-inquiry"]?.[
        "padding"
      ]?.split(" ")[0],
      p2: payload?.[".single-product-section .single-proinfo .add-inquiry"]?.[
        "padding"
      ]?.split(" ")[1],
    },
    ".single-product-section .product-info-tab .nav-tabs .active": {
      "background-color": payload?.[
        ".single-product-section .product-info-tab .nav-tabs .active"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload?.[
                ".single-product-section .product-info-tab .nav-tabs .active"
              ]?.["background-color"],
          })
        : "",
      "border-color": payload?.[
        ".single-product-section .product-info-tab .nav-tabs .active"
      ]?.["border-color"]
        ? convertRGBA({
            rgbString:
              payload?.[
                ".single-product-section .product-info-tab .nav-tabs .active"
              ]?.["border-color"],
          })
        : "",
      color: payload?.[
        ".single-product-section .product-info-tab .nav-tabs .active"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload?.[
                ".single-product-section .product-info-tab .nav-tabs .active"
              ]?.["color"],
          })
        : "",
    },
    ".single-product-section .product-info-tab .tab-content": {
      "background-color": payload?.[
        ".single-product-section .product-info-tab .tab-content"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload?.[
                ".single-product-section .product-info-tab .tab-content"
              ]?.["background-color"],
          })
        : "",
      color: payload?.[
        ".single-product-section .product-info-tab .tab-content"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload?.[
                ".single-product-section .product-info-tab .tab-content"
              ]?.["color"],
          })
        : "",
      padding:
        payload?.[".single-product-section .product-info-tab .tab-content"]?.[
          "padding"
        ],
    },
    custom: payload.custom,
  };
};
export const getCartPageStyle = (payload) => {
  return {
    ".sidebar-panel .sidebar-top": {
      "background-color": payload?.[".sidebar-panel .sidebar-top"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload?.[".sidebar-panel .sidebar-top"]?.["background-color"],
          })
        : "",
      padding: payload?.[".sidebar-panel .sidebar-top"]?.["padding"],
    },
    ".sidebar-panel": {
      "background-color": payload?.[".sidebar-panel"]?.["background-color"]
        ? convertRGBA({
            rgbString: payload?.[".sidebar-panel"]?.["background-color"],
          })
        : "",
    },
    ".sidebar-panel .sidebar-midd .product-list": {
      padding:
        payload?.[".sidebar-panel .sidebar-midd .product-list"]?.["padding"],
      "border-bottom":
        payload?.[".sidebar-panel .sidebar-midd .product-list"]?.[
          "border-bottom"
        ],
      borderPx:
        payload?.[".sidebar-panel .sidebar-midd .product-list"]?.[
          "border-bottom"
        ]?.split(" ")[0],
      borderThik:
        payload?.[".sidebar-panel .sidebar-midd .product-list"]?.[
          "border-bottom"
        ]?.split(" ")[1],
      borderColor: payload?.[".sidebar-panel .sidebar-midd .product-list"]?.[
        "borderColor"
      ]
        ? convertRGBA({
            rgbString:
              payload?.[".sidebar-panel .sidebar-midd .product-list"]?.[
                "borderColor"
              ],
          })
        : "",
    },
    ".sidebar-panel .sidebar-midd .product-list .product-img": {
      border:
        payload?.[".sidebar-panel .sidebar-midd .product-list .product-img"]?.[
          "border"
        ],
      borderPx:
        payload?.[".sidebar-panel .sidebar-midd .product-list .product-img"]?.[
          "border"
        ]?.split(" ")[0],
      borderThik:
        payload?.[".sidebar-panel .sidebar-midd .product-list .product-img"]?.[
          "border"
        ]?.split(" ")[1],
      borderColor: payload?.[
        ".sidebar-panel .sidebar-midd .product-list .product-img"
      ]?.["borderColor"]
        ? convertRGBA({
            rgbString:
              payload?.[
                ".sidebar-panel .sidebar-midd .product-list .product-img"
              ]?.["borderColor"],
          })
        : "",
      "border-radius":
        payload?.[".sidebar-panel .sidebar-midd .product-list .product-img"]?.[
          "border-radius"
        ],
      padding:
        payload?.[".sidebar-panel .sidebar-midd .product-list .product-img"]?.[
          "padding"
        ],
    },
    ".sidebar-panel .sidebar-midd .product-list .name": {
      color: payload?.[".sidebar-panel .sidebar-midd .product-list .name"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString:
              payload?.[".sidebar-panel .sidebar-midd .product-list .name"]?.[
                "color"
              ],
          })
        : "",
      "font-size":
        payload?.[".sidebar-panel .sidebar-midd .product-list .name"]?.[
          "font-size"
        ],
      "font-weight":
        payload?.[".sidebar-panel .sidebar-midd .product-list .name"]?.[
          "font-weight"
        ],
    },
    ".sidebar-panel .sidebar-bottom": {
      "background-color": payload?.[".sidebar-panel .sidebar-bottom"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload?.[".sidebar-panel .sidebar-bottom"]?.["background-color"],
          })
        : "",
    },
    ".sidebar-panel .sidebar-bottom .count": {
      color: payload?.[".sidebar-panel .sidebar-bottom .count"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload?.[".sidebar-panel .sidebar-bottom .count"]?.["color"],
          })
        : "",
      "font-size":
        payload?.[".sidebar-panel .sidebar-bottom .count"]?.["font-size"],
    },
    ".sidebar-panel .sidebar-bottom .checkout-btn": {
      "background-color": payload?.[
        ".sidebar-panel .sidebar-bottom .checkout-btn"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload?.[".sidebar-panel .sidebar-bottom .checkout-btn"]?.[
                "background-color"
              ],
          })
        : "",
      padding:
        payload?.[".sidebar-panel .sidebar-bottom .checkout-btn"]?.["padding"],
      p1: payload?.[".sidebar-panel .sidebar-bottom .checkout-btn"]?.[
        "padding"
      ]?.split(" ")[0],
      p2: payload?.[".sidebar-panel .sidebar-bottom .checkout-btn"]?.[
        "padding"
      ]?.split(" ")[1],
      "border-radius":
        payload?.[".sidebar-panel .sidebar-bottom .checkout-btn"]?.[
          "border-radius"
        ],
      color: payload?.[".sidebar-panel .sidebar-bottom .checkout-btn"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString:
              payload?.[".sidebar-panel .sidebar-bottom .checkout-btn"]?.[
                "color"
              ],
          })
        : "",
      "font-size":
        payload?.[".sidebar-panel .sidebar-bottom .checkout-btn"]?.[
          "font-size"
        ],
      "font-weight":
        payload?.[".sidebar-panel .sidebar-bottom .checkout-btn"]?.[
          "font-weight"
        ],
    },
    custom: payload.custom,
  };
};
export const getCheckOutStyle = (payload) => {
  return {
    ".step-checkout-section": {
      padding: payload?.[".step-checkout-section"]?.["padding"],
      "background-color": payload?.[".step-checkout-section"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload?.[".step-checkout-section"]?.["background-color"],
          })
        : "",
    },
    ".step-checkout-div .steps .icondiv": {
      "background-color": payload?.[".step-checkout-div .steps .icondiv"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload?.[".step-checkout-div .steps .icondiv"]?.[
                "background-color"
              ],
          })
        : "",
      color: payload?.[".step-checkout-div .steps .icondiv"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload?.[".step-checkout-div .steps .icondiv"]?.["color"],
          })
        : "",
    },
    ".step-checkout-div .steps.activestep .icondiv, .step-checkout-div .steps.donestep .icondiv":
      {
        color: payload?.[
          ".step-checkout-div .steps.activestep .icondiv, .step-checkout-div .steps.donestep .icondiv"
        ]?.["color"]
          ? convertRGBA({
              rgbString:
                payload?.[
                  ".step-checkout-div .steps.activestep .icondiv, .step-checkout-div .steps.donestep .icondiv"
                ]?.["color"],
            })
          : "",
        "background-color": payload?.[
          ".step-checkout-div .steps.activestep .icondiv, .step-checkout-div .steps.donestep .icondiv"
        ]?.["background-color"]
          ? convertRGBA({
              rgbString:
                payload?.[
                  ".step-checkout-div .steps.activestep .icondiv, .step-checkout-div .steps.donestep .icondiv"
                ]?.["background-color"],
            })
          : "",
      },
    ".step-checkout-div .steps .step-ttl": {
      color: payload?.[".step-checkout-div .steps .step-ttl"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload?.[".step-checkout-div .steps .step-ttl"]?.["color"],
          })
        : "",
      "font-size":
        payload?.[".step-checkout-div .steps .step-ttl"]?.["font-size"],
    },
    ".step-checkout-div .steps .btn-login": {
      "background-color": payload?.[".step-checkout-div .steps .btn-login"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload?.[".step-checkout-div .steps .btn-login"]?.[
                "background-color"
              ],
          })
        : "",
      color: payload?.[".step-checkout-div .steps .btn-login"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload?.[".step-checkout-div .steps .btn-login"]?.["color"],
          })
        : "",
      border: payload?.[".step-checkout-div .steps .btn-login"]?.["border"],
      borderPx:
        payload?.[".step-checkout-div .steps .btn-login"]?.["border"]?.split(
          " "
        )[0],
      borderThik:
        payload?.[".step-checkout-div .steps .btn-login"]?.["border"]?.split(
          " "
        )[1],
      borderColor: payload?.[".step-checkout-div .steps .btn-login"]?.[
        "borderColor"
      ]
        ? convertRGBA({
            rgbString:
              payload?.[".step-checkout-div .steps .btn-login"]?.[
                "borderColor"
              ],
          })
        : "",
      "font-size":
        payload?.[".step-checkout-div .steps .btn-login"]?.["font-size"],

      padding: payload?.[".step-checkout-div .steps .btn-login"]?.["padding"],
      p1: payload?.[".step-checkout-div .steps .btn-login"]?.["padding"]?.split(
        " "
      )[0],
      p2: payload?.[".step-checkout-div .steps .btn-login"]?.["padding"]?.split(
        " "
      )[1],
    },
    ".step-checkout-div .steps .btn-green": {
      "background-color": payload?.[".step-checkout-div .steps .btn-green"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload?.[".step-checkout-div .steps .btn-green"]?.[
                "background-color"
              ],
          })
        : "",
      color: payload?.[".step-checkout-div .steps .btn-green"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload?.[".step-checkout-div .steps .btn-green"]?.["color"],
          })
        : "",
    },
    ".step-checkout-div .steps .addresslist p": {
      color: payload?.[".step-checkout-div .steps .addresslist p"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload?.[".step-checkout-div .steps .addresslist p"]?.["color"],
          })
        : "",
      "font-size":
        payload?.[".step-checkout-div .steps .addresslist p"]?.["font-size"],
      "font-weight":
        payload?.[".step-checkout-div .steps .addresslist p"]?.["font-weight"],
    },
    ".step-checkout-div .steps .addresslist .btn": {
      color: payload?.[".step-checkout-div .steps .addresslist .btn"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload?.[".step-checkout-div .steps .addresslist .btn"]?.[
                "color"
              ],
          })
        : "",
      "background-color": payload?.[
        ".step-checkout-div .steps .addresslist .btn"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload?.[".step-checkout-div .steps .addresslist .btn"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".step-checkout-total .table-responsive .text-brand": {
      color: payload?.[".step-checkout-total .table-responsive .text-brand"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString:
              payload?.[".step-checkout-total .table-responsive .text-brand"]?.[
                "color"
              ],
          })
        : "",
    },
    custom: payload.custom,
  };
};
export const getMyAccountStyle = (payload) => {
  return {
    ".my-order-section": {
      padding: payload?.[".my-order-section"]?.["padding"],
      "background-color": payload?.[".my-order-section"]?.["background-color"]
        ? convertRGBA({
            rgbString: payload?.[".my-order-section"]?.["background-color"],
          })
        : "",
    },
    ".my-order-section .nav-pills .nav-link": {
      color: payload?.[".my-order-section .nav-pills .nav-link"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload?.[".my-order-section .nav-pills .nav-link"]?.["color"],
          })
        : "",
      "font-size":
        payload?.[".my-order-section .nav-pills .nav-link"]?.["font-size"],
      padding: payload?.[".my-order-section .nav-pills .nav-link"]?.["padding"],
      "font-weight":
        payload?.[".my-order-section .nav-pills .nav-link"]?.["font-weight"],
      border: payload?.[".my-order-section .nav-pills .nav-link"]?.["border"],
      borderPx:
        payload?.[".my-order-section .nav-pills .nav-link"]?.["border"]?.split(
          " "
        )[0],
      borderThik:
        payload?.[".my-order-section .nav-pills .nav-link"]?.["border"]?.split(
          " "
        )[1],
      borderColor: payload?.[".my-order-section .nav-pills .nav-link"]?.[
        "borderColor"
      ]
        ? convertRGBA({
            rgbString:
              payload?.[".my-order-section .nav-pills .nav-link"]?.[
                "borderColor"
              ],
          })
        : "",
      "border-radius":
        payload?.[".my-order-section .nav-pills .nav-link"]?.["border-radius"],
    },
    ".my-order-section .nav-pills .nav-link.active": {
      color: payload?.[".my-order-section .nav-pills .nav-link.active"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString:
              payload?.[".my-order-section .nav-pills .nav-link.active"]?.[
                "color"
              ],
          })
        : "",
      "background-color": payload?.[
        ".my-order-section .nav-pills .nav-link.active"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload?.[".my-order-section .nav-pills .nav-link.active"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".my-order-section .tab-content a": {
      color: payload?.[".my-order-section .tab-content a"]?.["color"]
        ? convertRGBA({
            rgbString: payload?.[".my-order-section .tab-content a"]?.["color"],
          })
        : "",
    },
    ".my-order-section .tab-content .form-control": {
      border:
        payload?.[".my-order-section .tab-content .form-control"]?.["border"],
      borderPx:
        payload?.[".my-order-section .tab-content .form-control"]?.[
          "border"
        ]?.split(" ")[0],
      borderThik:
        payload?.[".my-order-section .tab-content .form-control"]?.[
          "border"
        ]?.split(" ")[1],
      borderColor: payload?.[".my-order-section .tab-content .form-control"]?.[
        "borderColor"
      ]
        ? convertRGBA({
            rgbString:
              payload?.[".my-order-section .tab-content .form-control"]?.[
                "borderColor"
              ],
          })
        : "",
      "border-radius":
        payload?.[".my-order-section .tab-content .form-control"]?.[
          "border-radius"
        ],
      "font-size":
        payload?.[".my-order-section .tab-content .form-control"]?.[
          "font-size"
        ],
    },
    ".my-order-section .tab-content .btn-primary": {
      "border-color": payload?.[
        ".my-order-section .tab-content .btn-primary"
      ]?.["border-color"]
        ? convertRGBA({
            rgbString:
              payload?.[".my-order-section .tab-content .btn-primary"]?.[
                "border-color"
              ],
          })
        : "",
      "background-color": payload?.[
        ".my-order-section .tab-content .btn-primary"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload?.[".my-order-section .tab-content .btn-primary"]?.[
                "background-color"
              ],
          })
        : "",
    },
    custom: payload.custom,
  };
};
