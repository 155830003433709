import React, { useContext, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import { Cases, Currency, DELETE_CONFIRM, modalsName } from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import CommonPopup from "../../../Modals/commonPopup";
import PackagesPopup from "./PackagesPopup";
import EditIcon from "@mui/icons-material/Edit";
import PackagesParentPopup from "./PackagesParentPopup";
import { FILE_UPLOAD_API } from "../apis/SliderApi/SliderApi";
import {
  addUrlImage,
  convertRGBA,
  generateDynamicStyles,
  generateDynamicStylesSet,
  isCheckValue,
  isEmptyObject,
  randomStr,
  removeUrlImage,
  removeUrlKeyInPath,
  rgbColorCreate,
} from "../../../../utils/helper";
import {
  FILE_TYPE,
  PackagesTheme,
  ThemeNo,
  titleUpdateKey,
} from "../../../../constant";
import { IconButton } from "@material-ui/core";
import { Settings, Trash } from "react-feather";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import SimpleReactValidator from "simple-react-validator";
import PackagesContext from "../../../context/PackagesContext/PackagesContext";
import {
  deletePackages,
  getPackages,
  getPackagesDetails,
  postPackages,
  putUpdatePackages,
  updateStatus,
} from "../apis/PackagesApi/PackagesApi";
import SettingPackagesParentPopup from "./SettingPackagesParentPopup";
import DeleteSection from "../../../common/DeleteSection";
import SettingMultiWebContext from "../../../../context/SettingMultiWebContext/SettingMultiWebContext";

const Packages = (props) => {
  const {
    pageConfigDetails,
    webMasterDetail,
    getData,
    packagesDetail,
    contentMapId,
    ByteMiniWeb_Dashboard,
    navMapId: navMapIds,
    contentDetail,
  } = props;
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );
  const { setCloneAlignModal, setAlignFormData, setCloneFormData } = useContext(
    SettingMultiWebContext
  );
  const parentValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const childValidator = useRef(new SimpleReactValidator());
  const {
    fromData,
    setFormData,
    formDataChild,
    setFormDataChild,
    id,
    setId,
    setIsEdit,
    isEdit,
    setFilter,
    apiDataOurTeam: apiDataImageParent,
    setApiDataOurTeam: setApiDataImageParent,
    setSkill,
    setGalleryImgId,
    setSettingFormData,
    settingFormData,
    isParent,
    setIsParent,
    childId,
    setChildId,
    navMapId,
    setNavMapId,
    setBackImageToggle,
    boxSection,
    setBoxSection,
    collId,
    setCollId,
    storeIndex,
    isBackgroundColor,
    setIsBackgroundColor,
    setTheme,
    imgShow,
    setImgShow,
    sectionPageName,
    setSectionPageName,
  } = useContext(PackagesContext);
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * get packages parent list
   * @param {status} status
   */
  const getPackagesParentList = async ({
    parentId = null,
    isClose = false,
    filter,
  }) => {
    try {
      let paramObj = {};
      if (
        ((parentId && parentId !== null) ||
          (id && isClose === false && isParent === false)) &&
        isParent === false
      ) {
        paramObj["search"] = { parentId: parentId || id };
        setId(parentId || id);
        if (filter?.length) {
          paramObj["search"] = { ...paramObj.search, title: filter };
        }
      } else {
        paramObj["search"] = { parentId: null };
      }
      const { data } = await getPackages(paramObj);
      if (isCheckValue({ value: parentId })) {
        setChildId(parentId);
      }
      if (data.status === 200) {
        if (
          isParent === false &&
          ((parentId && parentId !== null) || id) &&
          isClose === false
        ) {
          setApiDataImageParent({
            ...apiDataImageParent,
            isLoading: false,
            data: data.payload.data.map((elem) => {
              return {
                id: elem?.title + elem?.packageId,
                Id: elem?.packageId,
                name: elem?.title,
                image: "",
                webId: elem?.webId,
                createdAt: elem?.createdAt,
                isActive: elem?.isActive,
                parentId: elem?.parentId,
              };
            }),
          });
          OpenModalCommonFunc(Cases.PACKAGES, setAllPopupState);
        } else if (parentId && parentId !== null) {
          OpenModalCommonFunc(Cases.PACKAGES, setAllPopupState);
          setApiDataImageParent({
            ...apiDataImageParent,
            isLoading: false,
            data: data.payload.data.map((elem) => {
              return {
                id: elem?.title + elem?.packageId,
                Id: elem?.packageId,
                name: elem?.title,
                image: "",
                webId: elem?.webId,
                createdAt: elem?.createdAt,
                isActive: elem?.isActive,
                parentId: elem?.parentId,
              };
            }),
          });
        } else {
          setApiDataImageParent({
            ...apiDataImageParent,
            isLoading: false,
            data: data.payload.data,
          });
        }
        if (isClose && isClose !== false) {
          setIsParent(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * item feature enable disable api call
   * @param {packageId} packageId
   * @param {status} isActive
   */

  const handleChangePackages = async (
    packageId,
    isActive,
    isChild,
    parentId
  ) => {
    try {
      let body = {
        packageId,
        isActive: isActive ? false : true,
        parentId,
      };
      const { data } = await updateStatus(body);
      if (data.status === 200) {
        if (isChild) {
          getPackagesParentList({ parentId: id });
        } else {
          ByteMiniWeb_Dashboard();
        }
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * file upload
   * @param {file} file
   */
  const imageUploadImages = async (file) => {
    setLoaderShow(true);
    try {
      const Image = await imageUpload(file);
      let old = [...formDataChild.featureObject];
      old[storeIndex]["icon"] = Image;
      setFormDataChild((prev) => {
        return {
          ...prev,
          image: Image,
          featureObject: old,
        };
      });
      setLoaderShow(false);
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const imageUploadSetting = async (file) => {
    setLoaderShow(true);
    try {
      const Image = await imageUpload(file);
      setSettingFormData((prev) => {
        return {
          ...prev,
          ".packages-section.bg-image": {
            "background-image": Image,
          },
        };
      });
      setLoaderShow(false);
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const imageUpload = async (file) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.PACKAGE);
    formData.append("file", file);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        return data.payload.baseUrl + data.payload.imageUrl;
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * create and update packages
   */
  const submitForm = async () => {
    if (
      (isParent === false && childId >= -1 && isEdit) ||
      (id >= -1 && isEdit)
    ) {
      setLoaderShow(true);
      try {
        let formData = {};
        if (isParent && isParent !== false) {
          formData = {
            ...fromData,
            packageId: id,
            contentMapId: collId,
            navMapId: +navMapIds,
          };
          delete formData?.style;
        } else {
          formData = {
            ...formDataChild,
            valueText: `${formDataChild?.valueText[0]?.value} ${
              formDataChild?.planDuration ? formDataChild?.planDuration : ""
            }`,
            parentId: id,
            navMapId,
            packageId: childId,
            contentMapId: collId,
          };
          delete formData.image;
          if (formData?.featureObject?.length) {
            formData["featureObject"] = formData.featureObject.map((ele) => {
              return {
                ...ele,
                icon: ele.icon
                  .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
                  .pop(),
              };
            });
          }
        }
        const { data } = await putUpdatePackages(formData);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            ByteMiniWeb_Dashboard();
          } else {
            getPackagesParentList({ parentId: id });
          }
          emptyData();
          OpenModalCommonFunc(Cases.PACKAGES_PARENT, setAllPopupState, false);
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    } else {
      try {
        setLoaderShow(true);
        let formData = {};
        if (isParent && isParent !== false) {
          formData = {
            ...fromData,
            contentMapId: collId,
            navMapId: +navMapIds,
          };
        } else {
          formData = {
            ...formDataChild,
            valueText: `${formDataChild.valueText?.value} ${formDataChild.planDuration}`,
            parentId: id,
            navMapId,
            order: apiDataImageParent.data.length + 1,
            contentMapId: collId,
          };
          delete formData.image;
          if (formData?.featureObject?.length) {
            formData["featureObject"] = formData.featureObject.map((ele) => {
              return {
                ...ele,
                icon: ele.icon
                  .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
                  .pop(),
              };
            });
          }
        }
        const { data } = await postPackages(formData);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            ByteMiniWeb_Dashboard();
          } else {
            getPackagesParentList({ parentId: id });
          }
          emptyData();
          OpenModalCommonFunc(Cases.PACKAGES_PARENT, setAllPopupState, false);
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  const handleSubmit = async (formValidationCondition) => {
    let validatorFields;
    if (formValidationCondition === "parentForm") {
      validatorFields = parentValidator.current;
    } else if (formValidationCondition === "childForm") {
      validatorFields = childValidator.current;
    }
    validatorFields.showMessages();

    if (validatorFields.allValid()) {
      try {
        // Process form data based on the formType
        if (formValidationCondition === "parentForm") {
          submitForm();
        } else if (formValidationCondition === "childForm") {
          submitForm();
        }
        validatorFields.hideMessages();
      } catch (error) {
        // Handle error for form submission
      }
    } else {
      // Force update to display validation messages
      forceUpdate({});
    }
  };

  /**
   * create and update images
   */
  const handleSubmitSetting = async (isRestore = false, isClose = true) => {
    if (id >= -1 && isEdit) {
      setLoaderShow(true);
      try {
        let newData;
        if (isRestore) {
          const isConfirm = window.confirm(
            "Are you sure want to restore style ?"
          );
          if (isConfirm) {
            newData = {
              styleRestore: true,
              packageId: id,
            };
          } else {
            setLoaderShow(false);
            return;
          }
        } else {
          newData = {
            ...settingFormData,
            ".packages-section": boxSection
              ? {
                  ...settingFormData[".packages-section"],
                  padding: `${settingFormData[".packages-section"]["padding"]
                    ?.split(" ")[0]
                    ?.replace("px", "")}px 0`,
                  width: `calc(100% - ${settingFormData[".packages-section"][
                    "width"
                  ]?.replace("rem)", "")}rem)`,
                  margin: `${settingFormData[".packages-section"]["margin"]
                    ?.split(" ")[0]
                    ?.replace("px", "")}px auto`,
                  "border-radius": `${settingFormData[".packages-section"][
                    "border-radius"
                  ]?.replace("rem", "")}rem`,
                }
              : {
                  ...settingFormData[".packages-section"],
                  padding: `${settingFormData[".packages-section"]["padding"]
                    ?.split(" ")[0]
                    ?.replace("px", "")}px 0`,
                },
            ".packages-section.bg-image": settingFormData[
              ".packages-section.bg-image"
            ]["background-image"]
              ? {
                  "background-image": addUrlImage({
                    urlString: removeUrlImage({
                      urlString:
                        settingFormData[".packages-section.bg-image"][
                          "background-image"
                        ],
                    }),
                  }),
                }
              : {},
            ".packages-section.bg-image::before": settingFormData[
              ".packages-section.bg-image::before"
            ]["background-color"]
              ? {
                  "background-color": rgbColorCreate({
                    rgbString:
                      settingFormData[".packages-section.bg-image::before"][
                        "background-color"
                      ],
                  }),
                }
              : {},
            ".packages-section .section-title-area": {
              ...settingFormData[".packages-section .section-title-area"],
              "margin-bottom": `${settingFormData[
                ".packages-section .section-title-area"
              ]["margin-bottom"]?.replace("px", "")}px`,
            },
            ".packages-section .section-title-area .section-subtitle": {
              ...settingFormData[
                ".packages-section .section-title-area .section-subtitle"
              ],
              "font-size": `${settingFormData[
                ".packages-section .section-title-area .section-subtitle"
              ]["font-size"]?.replace("px", "")}px`,
              "font-weight": `${settingFormData[".packages-section .section-title-area .section-subtitle"]["font-weight"]}`,

              color: settingFormData[
                ".packages-section .section-title-area .section-subtitle"
              ]["color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[
                        ".packages-section .section-title-area .section-subtitle"
                      ]["color"],
                  })
                : "",
              "background-color": settingFormData[
                ".packages-section .section-title-area .section-subtitle"
              ]["background-color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[
                        ".packages-section .section-title-area .section-subtitle"
                      ]["background-color"],
                  })
                : "",
              padding: `${settingFormData[
                ".packages-section .section-title-area .section-subtitle"
              ]["p1"]?.replace("px", "")}px ${settingFormData[
                ".packages-section .section-title-area .section-subtitle"
              ]["p2"]?.replace("px", "")}px`,
              "border-radius": `${settingFormData[
                ".packages-section .section-title-area .section-subtitle"
              ]["border-radius"]?.replace("px", "")}px`,
              "margin-bottom": `${settingFormData[
                ".packages-section .section-title-area .section-subtitle"
              ]["margin-bottom"]?.replace("px", "")}px`,
            },
            ".packages-section .section-title-area .section-title": {
              ...settingFormData[
                ".packages-section .section-title-area .section-title"
              ],
              "font-size": `${settingFormData[
                ".packages-section .section-title-area .section-title"
              ]["font-size"]?.replace("px", "")}px`,
              "font-weight": `${settingFormData[".packages-section .section-title-area .section-title"]["font-weight"]}`,
              color: settingFormData[
                ".packages-section .section-title-area .section-title"
              ]["color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[
                        ".packages-section .section-title-area .section-title"
                      ]["color"],
                  })
                : "",
            },
            ".planswitch": {
              ...settingFormData[".planswitch"],
              "align-items": `${settingFormData[".planswitch"]["align-items"]}`,
              "justify-content": `${settingFormData[".planswitch"]["justify-content"]}`,
              "margin-bottom": `${settingFormData[".planswitch"][
                "margin-bottom"
              ]?.replace("px", "")}px`,
            },
            ".planswitch .switch-text": {
              ...settingFormData[".planswitch .switch-text"],
              color: settingFormData[".planswitch .switch-text"]["color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[".planswitch .switch-text"]["color"],
                  })
                : "",
              "font-size": `${settingFormData[".planswitch .switch-text"][
                "font-size"
              ]?.replace("px", "")}px`,
              "font-weight": `${settingFormData[".planswitch .switch-text"]["font-weight"]}`,
            },
            ".switch .slider": {
              ...settingFormData[".switch .slider"],
              "border-radius": `${settingFormData[".switch .slider"][
                "border-radius"
              ]?.replace("px", "")}px`,
              "background-color": settingFormData[".switch .slider"][
                "background-color"
              ]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[".switch .slider"]["background-color"],
                  })
                : "",
            },
            ".switch .slider:before": {
              ...settingFormData[".switch .slider:before"],
              bottom: `${settingFormData[".switch .slider:before"][
                "bottom"
              ]?.replace("px", "")}px`,
              "border-radius": `${settingFormData[".switch .slider:before"][
                "border-radius"
              ]?.replace("%", "")}%`,
              "background-color": settingFormData[".switch .slider:before"][
                "background-color"
              ]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[".switch .slider:before"][
                        "background-color"
                      ],
                  })
                : "",
            },
            ".switch input:checked + .slider": {
              ...settingFormData[".switch input:checked + .slider"],
              "background-color": settingFormData[
                ".switch input:checked + .slider"
              ]["background-color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[".switch input:checked + .slider"][
                        "background-color"
                      ],
                  })
                : "",
            },
            ".packagelist-1 .package": {
              ...settingFormData[".packagelist-1 .package"],
              border: `${settingFormData[".packagelist-1 .package"][
                "borderPx"
              ]?.replace("px", "")}px ${
                settingFormData[".packagelist-1 .package"]["borderThik"]
              } ${rgbColorCreate({
                rgbString:
                  settingFormData[".packagelist-1 .package"]["borderColor"],
              })}`,
              "border-radius": `${settingFormData[".packagelist-1 .package"][
                "border-radius"
              ]?.replace("px", "")}px`,
            },
            ".packages-section .packagelist-1 .package .title": {
              color: settingFormData[
                ".packages-section .packagelist-1 .package .title"
              ]["color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[
                        ".packages-section .packagelist-1 .package .title"
                      ]["color"],
                  })
                : "",
              padding: `${settingFormData[
                ".packages-section .packagelist-1 .package .title"
              ]["p1"]?.replace("px", "")}px ${settingFormData[
                ".packages-section .packagelist-1 .package .title"
              ]["p2"]?.replace("px", "")}px ${settingFormData[
                ".packages-section .packagelist-1 .package .title"
              ]["p3"]?.replace("px", "")}px`,
              "text-align": `${settingFormData[".packages-section .packagelist-1 .package .title"]["text-align"]}`,
              "text-transform": `${settingFormData[".packages-section .packagelist-1 .package .title"]["text-transform"]}`,
              "background-color": settingFormData[
                ".packages-section .packagelist-1 .package .title"
              ]["background-color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[
                        ".packages-section .packagelist-1 .package .title"
                      ]["background-color"],
                  })
                : "",
            },
            ".packagelist-1 .package .title:before": {
              ...settingFormData[".packagelist-1 .package .title:before"],
              "background-color": settingFormData[
                ".packagelist-1 .package .title:before"
              ]["background-color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[".packagelist-1 .package .title:before"][
                        "background-color"
                      ],
                  })
                : "",
            },
            ".packagelist-1 .package .title h3": {
              ...settingFormData[".packagelist-1 .package .title h3"],
              "font-weight": `${settingFormData[".packagelist-1 .package .title h3"]["font-weight"]}`,
              "font-size": `${settingFormData[
                ".packagelist-1 .package .title h3"
              ]["font-size"]?.replace("px", "")}px`,
            },
            ".packagelist-1 .package .title p": {
              ...settingFormData[".packagelist-1 .package .title p"],
              "font-weight": `${settingFormData[".packagelist-1 .package .title p"]["font-weight"]}`,
            },
            ".packagelist-1 .package .cost": {
              ...settingFormData[".packagelist-1 .package .cost"],
              "background-color": settingFormData[
                ".packagelist-1 .package .cost"
              ]["background-color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[".packagelist-1 .package .cost"][
                        "background-color"
                      ],
                  })
                : "",
              width: `${settingFormData[".packagelist-1 .package .cost"][
                "width"
              ]?.replace("%", "")}%`,
              "text-align": `${settingFormData[".packagelist-1 .package .cost"]["text-align"]}`,
              padding: `${settingFormData[".packagelist-1 .package .cost"][
                "p1"
              ]?.replace("px", "")}px ${settingFormData[
                ".packagelist-1 .package .cost"
              ]["p2"]?.replace("px", "")}px ${settingFormData[
                ".packagelist-1 .package .cost"
              ]["p3"]?.replace("px", "")}px`,
              "font-size": `${settingFormData[".packagelist-1 .package .cost"][
                "font-size"
              ]?.replace("px", "")}px`,
              "font-weight": `${settingFormData[".packagelist-1 .package .cost"]["font-weight"]}`,
            },
            ".packagelist-1 .package .cost:before": {
              ...settingFormData[".packagelist-1 .package .cost:before"],
              "background-color": settingFormData[
                ".packagelist-1 .package .cost:before"
              ]["background-color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[".packagelist-1 .package .cost:before"][
                        "background-color"
                      ],
                  })
                : "",
            },
            ".packagelist-1 .package .cost .price": {
              ...settingFormData[".packagelist-1 .package .cost .price"],
              "font-size": `${settingFormData[
                ".packagelist-1 .package .cost .price"
              ]["font-size"]?.replace("px", "")}px`,
            },
            ".packagelist-1 .package .desq": {
              ...settingFormData[".packagelist-1 .package .desq"],
              "background-color": settingFormData[
                ".packagelist-1 .package .desq"
              ]["background-color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[".packagelist-1 .package .desq"][
                        "background-color"
                      ],
                  })
                : "",
              padding: `${settingFormData[".packagelist-1 .package .desq"][
                "p1"
              ]?.replace("px", "")}px ${settingFormData[
                ".packagelist-1 .package .desq"
              ]["p2"]?.replace("px", "")}px ${settingFormData[
                ".packagelist-1 .package .desq"
              ]["p3"]?.replace("px", "")}px`,
            },
            ".packagelist-1 .package .desq li": {
              ...settingFormData[".packagelist-1 .package .desq li"],
              "font-size": `${settingFormData[
                ".packagelist-1 .package .desq li"
              ]["font-size"]?.replace("px", "")}px`,
              "font-weight": `${settingFormData[".packagelist-1 .package .desq li"]["font-weight"]}`,
              margin: `${settingFormData[".packagelist-1 .package .desq li"]["margin"]}`,
              padding: `0 ${settingFormData[".packagelist-1 .package .desq li"][
                "p1"
              ]?.replace("px", "")}px 0 ${settingFormData[
                ".packagelist-1 .package .desq li"
              ]["p2"]?.replace("px", "")}px`,
            },
            ".packagelist-1 .package .desq li:before": {
              ...settingFormData[".packagelist-1 .package .desq li:before"],
              color: settingFormData[".packagelist-1 .package .desq li:before"][
                "color"
              ]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[
                        ".packagelist-1 .package .desq li:before"
                      ]["color"],
                  })
                : "",
            },
            ".packages-section .packagelist-1 .package .selectbtn": {
              ...settingFormData[
                ".packages-section .packagelist-1 .package .selectbtn"
              ],
              "background-color": settingFormData[
                ".packages-section .packagelist-1 .package .selectbtn"
              ]["background-color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[
                        ".packages-section .packagelist-1 .package .selectbtn"
                      ]["background-color"],
                  })
                : "",
              padding: `${settingFormData[
                ".packages-section .packagelist-1 .package .selectbtn"
              ]["p1"]?.replace("px", "")}px ${settingFormData[
                ".packages-section .packagelist-1 .package .selectbtn"
              ]["p2"]?.replace("px", "")}px ${settingFormData[
                ".packages-section .packagelist-1 .package .selectbtn"
              ]["p3"]?.replace("px", "")}px`,
              "font-size": `${settingFormData[
                ".packages-section .packagelist-1 .package .selectbtn"
              ]["font-size"]?.replace("px", "")}px`,
              color: settingFormData[
                ".packages-section .packagelist-1 .package .selectbtn"
              ]["color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[
                        ".packages-section .packagelist-1 .package .selectbtn"
                      ]["color"],
                  })
                : "",
              "font-weight": `${settingFormData[".packages-section .packagelist-1 .package .selectbtn"]["font-weight"]}`,
              "text-transform": `${settingFormData[".packages-section .packagelist-1 .package .selectbtn"]["text-transform"]}`,
              "text-align": `${settingFormData[".packages-section .packagelist-1 .package .selectbtn"]["text-align"]}`,
            },
            ".packagelist-1 .package .selectbtn:before": {
              ...settingFormData[".packagelist-1 .package .selectbtn:before"],
              "background-color": settingFormData[
                ".packagelist-1 .package .selectbtn:before"
              ]["background-color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[
                        ".packagelist-1 .package .selectbtn:before"
                      ]["background-color"],
                  })
                : "",
            },
            ".packagelist-2 .package": {
              ...settingFormData[".packagelist-2 .package"],
              border: `${settingFormData[".packagelist-2 .package"][
                "borderPx"
              ]?.replace("px", "")}px ${
                settingFormData[".packagelist-2 .package"]["borderThik"]
              } ${rgbColorCreate({
                rgbString:
                  settingFormData[".packagelist-2 .package"]["borderColor"],
              })}`,
              "border-radius": `${settingFormData[".packagelist-2 .package"][
                "border-radius"
              ]?.replace("px", "")}px`,
            },
            ".packages-section .packagelist-2 .package .title": {
              ...settingFormData[
                ".packages-section .packagelist-2 .package .title"
              ],
              padding: `${settingFormData[
                ".packages-section .packagelist-2 .package .title"
              ]["padding"]?.replace("px", "")}px`,
              color: settingFormData[
                ".packages-section .packagelist-2 .package .title"
              ]["color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[
                        ".packages-section .packagelist-2 .package .title"
                      ]["color"],
                  })
                : "",
              "font-size": `${settingFormData[
                ".packages-section .packagelist-2 .package .title"
              ]["font-size"]?.replace("px", "")}px`,
              "font-weight": `${settingFormData[".packages-section .packagelist-2 .package .title"]["font-weight"]}`,
              "background-color": settingFormData[
                ".packages-section .packagelist-2 .package .title"
              ]["background-color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[
                        ".packages-section .packagelist-2 .package .title"
                      ]["background-color"],
                  })
                : "",
              "text-align": `${settingFormData[".packages-section .packagelist-2 .package .title"]["text-align"]}`,
              "text-transform": `${settingFormData[".packages-section .packagelist-2 .package .title"]["text-transform"]}`,
            },
            ".packagelist-2 .package .cost": {
              ...settingFormData[".packagelist-2 .package .cost"],
              padding: `${settingFormData[".packagelist-2 .package .cost"][
                "p1"
              ]?.replace("px", "")}px ${settingFormData[
                ".packagelist-2 .package .cost"
              ]["p2"]?.replace("px", "")}px`,
              "text-align": `${settingFormData[".packagelist-2 .package .cost"]["text-align"]}`,
              "background-color": settingFormData[
                ".packagelist-2 .package .cost"
              ]["background-color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[".packagelist-2 .package .cost"][
                        "background-color"
                      ],
                  })
                : "",
            },
            ".packagelist-2 .package .cost span": {
              ...settingFormData[".packagelist-2 .package .cost span"],
              border: `${settingFormData[".packagelist-2 .package .cost span"][
                "borderPx"
              ]?.replace("px", "")}px ${
                settingFormData[".packagelist-2 .package .cost span"][
                  "borderThik"
                ]
              } ${rgbColorCreate({
                rgbString:
                  settingFormData[".packagelist-2 .package .cost span"][
                    "borderColor"
                  ],
              })}`,
              "border-radius": `${settingFormData[
                ".packagelist-2 .package .cost span"
              ]["border-radius"]?.replace("%", "")}%`,
              "line-height": `${settingFormData[
                ".packagelist-2 .package .cost span"
              ]["line-height"]?.replace("px", "")}px`,
              "text-align": `${settingFormData[".packagelist-2 .package .cost span"]["text-align"]}`,
              display: `${settingFormData[".packagelist-2 .package .cost span"]["display"]}`,
              color: settingFormData[".packagelist-2 .package .cost span"][
                "color"
              ]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[".packagelist-2 .package .cost span"][
                        "color"
                      ],
                  })
                : "",
              "box-shadow": `0 ${settingFormData[
                ".packagelist-2 .package .cost span"
              ]["bs1"]?.replace("px", "")}px ${settingFormData[
                ".packagelist-2 .package .cost span"
              ]["bs2"]?.replace("px", "")}px ${settingFormData[
                ".packagelist-2 .package .cost span"
              ]["bs3"]?.replace("px", "")}px ${rgbColorCreate({
                rgbString:
                  settingFormData[".packagelist-2 .package .cost span"][
                    "shadowColor"
                  ],
              })}`,
            },
            ".packagelist-2 .package .subtitle": {
              ...settingFormData[".packagelist-2 .package .subtitle"],
              padding: `${settingFormData[".packagelist-2 .package .subtitle"][
                "p1"
              ]?.replace("px", "")}px ${settingFormData[
                ".packagelist-2 .package .subtitle"
              ]["p2"]?.replace("px", "")}px`,
              color: settingFormData[".packagelist-2 .package .subtitle"][
                "color"
              ]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[".packagelist-2 .package .subtitle"][
                        "color"
                      ],
                  })
                : "",
              "background-color": settingFormData[
                ".packagelist-2 .package .subtitle"
              ]["background-color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[".packagelist-2 .package .subtitle"][
                        "background-color"
                      ],
                  })
                : "",
              "text-align": `${settingFormData[".packagelist-2 .package .subtitle"]["text-align"]}`,
              "font-weight": `${settingFormData[".packagelist-2 .package .subtitle"]["font-weight"]}`,
              "font-size": `${settingFormData[
                ".packagelist-2 .package .subtitle"
              ]["font-size"]?.replace("px", "")}px`,
            },
            ".packagelist-2 .package .desq": {
              ...settingFormData[".packagelist-2 .package .desq"],
              "background-color": settingFormData[
                ".packagelist-2 .package .desq"
              ]["background-color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[".packagelist-2 .package .desq"][
                        "background-color"
                      ],
                  })
                : "",
              padding: `${settingFormData[".packagelist-2 .package .desq"][
                "padding"
              ]?.replace("px", "")}px`,
            },
            ".packagelist-2 .package .desq li": {
              ...settingFormData[".packagelist-2 .package .desq li"],
              margin: `${settingFormData[".packagelist-2 .package .desq li"][
                "margin"
              ]
                ?.split(" ")[0]
                ?.replace("px", "")}px 0`,
              "padding-left": `${settingFormData[
                ".packagelist-2 .package .desq li"
              ]["padding-left"]?.replace("px", "")}px`,
              "font-size": `${settingFormData[
                ".packagelist-2 .package .desq li"
              ]["font-size"]?.replace("px", "")}px`,
              color: settingFormData[".packagelist-2 .package .desq li"][
                "color"
              ]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[".packagelist-2 .package .desq li"][
                        "color"
                      ],
                  })
                : "",
            },
            ".packagelist-2 .package .desq li:before": {
              ...settingFormData[".packagelist-2 .package .desq li:before"],
              color: settingFormData[".packagelist-2 .package .desq li:before"][
                "color"
              ]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[
                        ".packagelist-2 .package .desq li:before"
                      ]["color"],
                  })
                : "",
            },
            ".packages-section .packagelist-2 .package .selectbtn": {
              ...settingFormData[
                ".packages-section .packagelist-2 .package .selectbtn"
              ],
              "text-align": `${settingFormData[".packages-section .packagelist-2 .package .selectbtn"]["text-align"]}`,
              padding: `${settingFormData[
                ".packages-section .packagelist-2 .package .selectbtn"
              ]["padding"]?.replace("px", "")}px`,
              display: `${settingFormData[".packages-section .packagelist-2 .package .selectbtn"]["display"]}`,
              width: `${settingFormData[
                ".packages-section .packagelist-2 .package .selectbtn"
              ]["width"]?.replace("%", "")}%`,
              "background-color": settingFormData[
                ".packages-section .packagelist-2 .package .selectbtn"
              ]["background-color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[
                        ".packages-section .packagelist-2 .package .selectbtn"
                      ]["background-color"],
                  })
                : "",
            },
            ".packagelist-2 .package .selectbtn a": {
              ...settingFormData[".packagelist-2 .package .selectbtn a"],
              border: `${settingFormData[
                ".packagelist-2 .package .selectbtn a"
              ]["borderPx"]?.replace("px", "")}px ${
                settingFormData[".packagelist-2 .package .selectbtn a"][
                  "borderThik"
                ]
              } ${rgbColorCreate({
                rgbString:
                  settingFormData[".packagelist-2 .package .selectbtn a"][
                    "borderColor"
                  ],
              })}`,
              padding: `${settingFormData[
                ".packagelist-2 .package .selectbtn a"
              ]["p1"]?.replace("px", "")}px ${settingFormData[
                ".packagelist-2 .package .selectbtn a"
              ]["p2"]?.replace("px", "")}px`,
              "font-size": `${settingFormData[
                ".packagelist-2 .package .selectbtn a"
              ]["font-size"]?.replace("px", "")}px`,
              "text-transform": `${settingFormData[".packagelist-2 .package .selectbtn a"]["text-transform"]}`,
              "letter-spacing": `${settingFormData[
                ".packagelist-2 .package .selectbtn a"
              ]["letter-spacing"]?.replace("px", "")}px`,
              color: settingFormData[".packagelist-2 .package .selectbtn a"][
                "color"
              ]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[".packagelist-2 .package .selectbtn a"][
                        "color"
                      ],
                  })
                : "",
              "border-radius": `${settingFormData[
                ".packagelist-2 .package .selectbtn a"
              ]["border-radius"]?.replace("px", "")}px`,
            },
            ".packagelist-2 .package .selectbtn a:hover": {
              ...settingFormData[".packagelist-2 .package .selectbtn a:hover"],
              color: settingFormData[
                ".packagelist-2 .package .selectbtn a:hover"
              ]["color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[
                        ".packagelist-2 .package .selectbtn a:hover"
                      ]["color"],
                  })
                : "",
              "background-color": settingFormData[
                ".packagelist-2 .package .selectbtn a:hover"
              ]["background-color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[
                        ".packagelist-2 .package .selectbtn a:hover"
                      ]["background-color"],
                  })
                : "",
            },
          };
          const menuBtnStyle = generateDynamicStyles(
            settingFormData,
            ".packages-section"
          );
          newData = { ...newData, ...menuBtnStyle };
          if (isBackgroundColor) {
            newData[".packages-section"] = {
              ...newData?.[".packages-section"],
              "background-color": settingFormData[".packages-section"][
                "background-color"
              ]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[".packages-section"]["background-color"],
                  })
                : "",
            };
          }
          if (boxSection) {
            newData[".packages-section.bg-image::before"] = {
              ...newData[".packages-section.bg-image::before"],
              "border-radius": `${settingFormData[".packages-section"][
                "border-radius"
              ]?.replace("rem", "")}rem`,
            };
          }
          const keysToRemove = [
            "bs1",
            "bs2",
            "bs3",
            "borderPx",
            "borderThik",
            "p1",
            "p2",
            "p3",
            "image",
          ];
          function removeKeys(obj, keys) {
            for (const key in obj) {
              if (typeof obj[key] === "object") {
                removeKeys(obj[key], keys);
              } else if (keys.includes(key)) {
                delete obj[key];
              }
            }
          }
          removeKeys(newData, keysToRemove);
          newData = {
            style: newData,
            packageId: id,
          };
        }

        const { data } = await putUpdatePackages(newData);
        if (data.status === 200) {
          getPackagesParentList({ parentId: null, isClose: true });
          if (isClose) {
            OpenModalCommonFunc(
              Cases.SETTING_PACKAGES_PARENT,
              setAllPopupState,
              false
            );
            emptyData();
            setBackImageToggle(false);
          }
          setLoaderShow(false);
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  /**
   * get data packages
   * @param {id} packageId
   */
  const handleEditPackages = async (packageId, isParent) => {
    try {
      setLoaderShow(true);
      const { data } = await getPackagesDetails(packageId, navMapIds);
      if (data.status === 200) {
        let payload = data.payload.data;
        if (isParent) {
          setSectionPageName(payload?.title);
          setId(packageId);
          setIsParent(true);
          setFormData({
            title: payload?.title || "",
            subTitle: payload?.subTitle || "",
            slugUrl: payload?.slugUrl || "",
            buttonText: payload?.buttonText || "",
            itemLimit: payload?.itemLimit || "",
            isTitleShow: payload?.isTitleShow ? payload?.isTitleShow : false,
            navMapId: payload?.navMapId || "",
            theme: payload?.theme || "",
            webId: localStorage.getItem("webId"),
            style: payload?.style || {},
          });
          OpenModalCommonFunc(Cases.PACKAGES_PARENT, setAllPopupState);
        } else {
          setChildId(packageId);
          setIsParent(false);
          let currencyValue = payload?.valueText?.split(" ")[0];
          let durationPlan = payload?.valueText?.split(" ")[1];
          let filterCurrency = "";
          if (currencyValue && currencyValue !== "" && currencyValue !== null) {
            filterCurrency = Currency?.filter(
              (elem) => elem?.value === currencyValue
            );
          }
          setFormDataChild({
            title: payload?.title || "",
            subTitle: payload?.subTitle || "",
            buttonText: payload?.buttonText || "",
            isTitleShow: payload?.isTitleShow ? payload?.isTitleShow : false,
            valueText: filterCurrency || "",
            planDuration: durationPlan || "",
            value: payload?.value || "",
            buttonValue: payload?.buttonValue || "",
            featureObject: payload?.featureObject || [
              {
                id: randomStr(2),
                icon: "",
                description: "",
              },
            ],
            image: payload?.featureObject?.icon,
            parentId: payload?.parentId || "",
            order: payload?.order || 1,
            webId: localStorage.getItem("webId"),
          });
          OpenModalCommonFunc(Cases.PACKAGES, setAllPopupState);
        }
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * get setting data
   * @param {id} packageId
   */
  const handleChangeSettings = async (packageId, isThemeEdit = false) => {
    try {
      setLoaderShow(true);
      setId(packageId);
      setSettingFormData((prev) => {
        return {
          ...prev,
          packageId,
        };
      });
      const { data } = await getPackagesDetails(packageId);
      if (data.status === 200) {
        let payload = data.payload.data;
        setSectionPageName(payload.title);
        const themeCount = data?.payload?.data?.theme;
        setImgShow(true);
        setTheme(themeCount);
        if (
          (+themeCount === ThemeNo.ThemeOne ||
            +themeCount === ThemeNo.ThemeTwo ||
            +themeCount === ThemeNo.ThemeThree) &&
          isEmptyObject(payload.style)
        ) {
          setSettingFormData({ ...PackagesTheme });
          setBoxSection(false);
          setBackImageToggle(false);
          setIsBackgroundColor(false);
        } else {
          if (
            payload.style?.[".packages-section"]?.["width"] ||
            payload.style?.[".packages-section"]?.["margin"] ||
            payload.style?.[".packages-section"]?.["border-radius"]
          ) {
            setBoxSection(true);
          }
          if (
            payload.style?.[".packages-section.bg-image"]?.[
              "background-image"
            ] === "url()" ||
            "" ||
            undefined
          ) {
            setBackImageToggle(false);
          } else if (
            payload.style?.[".packages-section.bg-image"]?.["background-image"]
          ) {
            setBackImageToggle(true);
          }
          if (payload.style?.[".packages-section"]?.["background-color"]) {
            setIsBackgroundColor(true);
          }
          const getMenuBtnStyle = generateDynamicStylesSet(
            payload,
            ".packages-section"
          );
          setSettingFormData((prev) => {
            return {
              ...prev,
              ".packages-section": {
                padding: payload.style?.[".packages-section"]?.["padding"],
                "background-color": payload.style?.[".packages-section"]?.[
                  "background-color"
                ]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[".packages-section"]?.[
                          "background-color"
                        ],
                    })
                  : "",
                width: payload.style?.[".packages-section"]?.["width"]?.replace(
                  "calc(100% - ",
                  ""
                ),
                margin: payload.style?.[".packages-section"]?.["margin"],
                "border-radius":
                  payload.style?.[".packages-section"]?.["border-radius"],
              },
              ".packages-section.bg-image": {
                "background-image": removeUrlKeyInPath({
                  urlString:
                    payload.style?.[".packages-section.bg-image"]?.[
                      "background-image"
                    ],
                }),
              },
              ".packages-section.bg-image::before": {
                "background-color": convertRGBA({
                  rgbString:
                    payload.style?.[".packages-section.bg-image::before"]?.[
                      "background-color"
                    ],
                }),
              },
              ".packages-section .section-title-area": {
                "margin-bottom":
                  payload.style?.[".packages-section .section-title-area"]?.[
                    "margin-bottom"
                  ],
              },
              ".packages-section .section-title-area .section-subtitle": {
                "font-size":
                  payload.style?.[
                    ".packages-section .section-title-area .section-subtitle"
                  ]?.["font-size"],
                "font-weight":
                  payload.style?.[
                    ".packages-section .section-title-area .section-subtitle"
                  ]?.["font-weight"],
                color: payload.style?.[
                  ".packages-section .section-title-area .section-subtitle"
                ]?.["color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          ".packages-section .section-title-area .section-subtitle"
                        ]?.["color"],
                    })
                  : "",
                "background-color": payload.style?.[
                  ".packages-section .section-title-area .section-subtitle"
                ]?.["background-color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          ".packages-section .section-title-area .section-subtitle"
                        ]?.["background-color"],
                    })
                  : "",
                padding:
                  payload.style?.[
                    ".packages-section .section-title-area .section-subtitle"
                  ]?.["padding"],
                p1: payload.style?.[
                  ".packages-section .section-title-area .section-subtitle"
                ]?.["padding"]?.split(" ")[0],
                p2: payload.style?.[
                  ".packages-section .section-title-area .section-subtitle"
                ]?.["padding"]?.split(" ")[1],
                "border-radius":
                  payload.style?.[
                    ".packages-section .section-title-area .section-subtitle"
                  ]?.["border-radius"],
                "margin-bottom":
                  payload.style?.[
                    ".packages-section .section-title-area .section-subtitle"
                  ]?.["margin-bottom"],
              },
              ".packages-section .section-title-area .section-title": {
                "font-size":
                  payload.style?.[
                    ".packages-section .section-title-area .section-title"
                  ]?.["font-size"],
                "font-weight":
                  payload.style?.[
                    ".packages-section .section-title-area .section-title"
                  ]?.["font-weight"],
                color: payload.style?.[
                  ".packages-section .section-title-area .section-title"
                ]?.["color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          ".packages-section .section-title-area .section-title"
                        ]?.["color"],
                    })
                  : "",
              },
              ".planswitch": {
                "align-items": payload.style?.[".planswitch"]?.["align-items"],
                "justify-content":
                  payload.style?.[".planswitch"]?.["justify-content"],
                "margin-bottom":
                  payload.style?.[".planswitch"]?.["margin-bottom"],
              },
              ".planswitch .switch-text": {
                color: payload.style?.[".planswitch .switch-text"]?.["color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[".planswitch .switch-text"]?.["color"],
                    })
                  : "",
                "font-size":
                  payload.style?.[".planswitch .switch-text"]?.["font-size"],
                "font-weight":
                  payload.style?.[".planswitch .switch-text"]?.["font-weight"],
              },
              ".switch .slider": {
                "border-radius":
                  payload.style?.[".switch .slider"]?.["border-radius"],
                "background-color": payload.style?.[".switch .slider"]?.[
                  "background-color"
                ]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[".switch .slider"]?.[
                          "background-color"
                        ],
                    })
                  : "",
              },
              ".switch .slider:before": {
                bottom: payload.style?.[".switch .slider:before"]?.["bottom"],
                "border-radius":
                  payload.style?.[".switch .slider:before"]?.["border-radius"],
                "background-color": payload.style?.[".switch .slider:before"]?.[
                  "background-color"
                ]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[".switch .slider:before"]?.[
                          "background-color"
                        ],
                    })
                  : "",
              },
              ".switch input:checked + .slider": {
                "background-color": payload.style?.[
                  ".switch input:checked + .slider"
                ]?.["background-color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[".switch input:checked + .slider"]?.[
                          "background-color"
                        ],
                    })
                  : "",
              },
              ".packagelist-1 .package": {
                border: payload.style?.[".packagelist-1 .package"]?.["border"],
                borderPx:
                  payload.style?.[".packagelist-1 .package"]?.["border"]?.split(
                    " "
                  )[0],
                borderThik:
                  payload.style?.[".packagelist-1 .package"]?.["border"]?.split(
                    " "
                  )[1],
                borderColor: payload.style?.[".packagelist-1 .package"]?.[
                  "borderColor"
                ]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[".packagelist-1 .package"]?.[
                          "borderColor"
                        ],
                    })
                  : "",
                "border-radius":
                  payload.style?.[".packagelist-1 .package"]?.["border-radius"],
              },
              ".packages-section .packagelist-1 .package .title": {
                color: payload.style?.[
                  ".packages-section .packagelist-1 .package .title"
                ]?.["color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          ".packages-section .packagelist-1 .package .title"
                        ]?.["color"],
                    })
                  : "",
                padding:
                  payload.style?.[
                    ".packages-section .packagelist-1 .package .title"
                  ]?.["padding"],
                p1: payload.style?.[
                  ".packages-section .packagelist-1 .package .title"
                ]?.["padding"]?.split(" ")[0],
                p2: payload.style?.[
                  ".packages-section .packagelist-1 .package .title"
                ]?.["padding"]?.split(" ")[1],
                p3: payload.style?.[
                  ".packages-section .packagelist-1 .package .title"
                ]?.["padding"]?.split(" ")[2],
                "text-align":
                  payload.style?.[
                    ".packages-section .packagelist-1 .package .title"
                  ]?.["text-align"],
                "text-transform":
                  payload.style?.[
                    ".packages-section .packagelist-1 .package .title"
                  ]?.["text-transform"],
                "background-color": payload.style?.[
                  ".packages-section .packagelist-1 .package .title"
                ]?.["background-color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          ".packages-section .packagelist-1 .package .title"
                        ]?.["background-color"],
                    })
                  : "",
              },
              ".packagelist-1 .package .title:before": {
                "background-color": payload.style?.[
                  ".packagelist-1 .package .title:before"
                ]?.["background-color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          ".packagelist-1 .package .title:before"
                        ]?.["background-color"],
                    })
                  : "",
              },
              ".packagelist-1 .package .title h3": {
                "font-weight":
                  payload.style?.[".packagelist-1 .package .title h3"]?.[
                    "font-weight"
                  ],
                "font-size":
                  payload.style?.[".packagelist-1 .package .title h3"]?.[
                    "font-size"
                  ],
              },
              ".packagelist-1 .package .title p": {
                "font-weight":
                  payload.style?.[".packagelist-1 .package .title p"]?.[
                    "font-weight"
                  ],
              },
              ".packagelist-1 .package .cost": {
                "background-color": payload.style?.[
                  ".packagelist-1 .package .cost"
                ]?.["background-color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[".packagelist-1 .package .cost"]?.[
                          "background-color"
                        ],
                    })
                  : "",
                width:
                  payload.style?.[".packagelist-1 .package .cost"]?.["width"],
                "text-align":
                  payload.style?.[".packagelist-1 .package .cost"]?.[
                    "text-align"
                  ],
                padding:
                  payload.style?.[".packagelist-1 .package .cost"]?.["padding"],
                p1: payload.style?.[".packagelist-1 .package .cost"]?.[
                  "padding"
                ]?.split(" ")[0],
                p2: payload.style?.[".packagelist-1 .package .cost"]?.[
                  "padding"
                ]?.split(" ")[1],
                p3: payload.style?.[".packagelist-1 .package .cost"]?.[
                  "padding"
                ]?.split(" ")[2],
                "font-size":
                  payload.style?.[".packagelist-1 .package .cost"]?.[
                    "font-size"
                  ],
                "font-weight":
                  payload.style?.[".packagelist-1 .package .cost"]?.[
                    "font-weight"
                  ],
              },
              ".packagelist-1 .package .cost:before": {
                "background-color": payload.style?.[
                  ".packagelist-1 .package .cost:before"
                ]?.["background-color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          ".packagelist-1 .package .cost:before"
                        ]?.["background-color"],
                    })
                  : "",
              },
              ".packagelist-1 .package .cost .price": {
                "font-size":
                  payload.style?.[".packagelist-1 .package .cost .price"]?.[
                    "font-size"
                  ],
              },
              ".packagelist-1 .package .desq": {
                "background-color": payload.style?.[
                  ".packagelist-1 .package .desq"
                ]?.["background-color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[".packagelist-1 .package .desq"]?.[
                          "background-color"
                        ],
                    })
                  : "",
                padding:
                  payload.style?.[".packagelist-1 .package .desq"]?.["padding"],
                p1: payload.style?.[".packagelist-1 .package .desq"]?.[
                  "padding"
                ]?.split(" ")[0],
                p2: payload.style?.[".packagelist-1 .package .desq"]?.[
                  "padding"
                ]?.split(" ")[1],
                p3: payload.style?.[".packagelist-1 .package .desq"]?.[
                  "padding"
                ]?.split(" ")[2],
              },
              ".packagelist-1 .package .desq li": {
                "font-size":
                  payload.style?.[".packagelist-1 .package .desq li"]?.[
                    "font-size"
                  ],
                "font-weight":
                  payload.style?.[".packagelist-1 .package .desq li"]?.[
                    "font-weight"
                  ],
                color: payload.style?.[".packagelist-1 .package .desq li"]?.[
                  "color"
                ]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[".packagelist-1 .package .desq li"]?.[
                          "color"
                        ],
                    })
                  : "",
                margin:
                  payload.style?.[".packagelist-1 .package .desq li"]?.[
                    "margin"
                  ],
                padding:
                  payload.style?.[".packagelist-1 .package .desq li"]?.[
                    "padding"
                  ],
                p1: payload.style?.[".packagelist-1 .package .desq li"]?.[
                  "padding"
                ]?.split(" ")[1],
                p2: payload.style?.[".packagelist-1 .package .desq li"]?.[
                  "padding"
                ]?.split(" ")[3],
              },
              ".packagelist-1 .package .desq li:before": {
                color: payload.style?.[
                  ".packagelist-1 .package .desq li:before"
                ]?.["color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          ".packagelist-1 .package .desq li:before"
                        ]?.["color"],
                    })
                  : "",
              },
              ".packages-section .packagelist-1 .package .selectbtn": {
                "background-color": payload.style?.[
                  ".packages-section .packagelist-1 .package .selectbtn"
                ]?.["background-color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          ".packages-section .packagelist-1 .package .selectbtn"
                        ]?.["background-color"],
                    })
                  : "",
                padding:
                  payload.style?.[
                    ".packages-section .packagelist-1 .package .selectbtn"
                  ]?.["padding"],
                p1: payload.style?.[
                  ".packages-section .packagelist-1 .package .selectbtn"
                ]?.["padding"]?.split(" ")[0],
                p2: payload.style?.[
                  ".packages-section .packagelist-1 .package .selectbtn"
                ]?.["padding"]?.split(" ")[1],
                p3: payload.style?.[
                  ".packages-section .packagelist-1 .package .selectbtn"
                ]?.["padding"]?.split(" ")[2],
                "font-size":
                  payload.style?.[
                    ".packages-section .packagelist-1 .package .selectbtn"
                  ]?.["font-size"],
                color: payload.style?.[
                  ".packages-section .packagelist-1 .package .selectbtn"
                ]?.["color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          ".packages-section .packagelist-1 .package .selectbtn"
                        ]?.["color"],
                    })
                  : "",
                "font-weight":
                  payload.style?.[
                    ".packages-section .packagelist-1 .package .selectbtn"
                  ]?.["font-weight"],
                "text-transform":
                  payload.style?.[
                    ".packages-section .packagelist-1 .package .selectbtn"
                  ]?.["text-transform"],
                "text-align":
                  payload.style?.[
                    ".packages-section .packagelist-1 .package .selectbtn"
                  ]?.["text-align"],
              },
              ".packagelist-1 .package .selectbtn:before": {
                "background-color": payload.style?.[
                  ".packagelist-1 .package .selectbtn:before"
                ]?.["background-color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          ".packagelist-1 .package .selectbtn:before"
                        ]?.["background-color"],
                    })
                  : "",
              },
              ".packagelist-2 .package": {
                border: payload.style?.[".packagelist-2 .package"]?.["border"],
                borderPx:
                  payload.style?.[".packagelist-2 .package"]?.["border"]?.split(
                    " "
                  )[0],
                borderThik:
                  payload.style?.[".packagelist-2 .package"]?.["border"]?.split(
                    " "
                  )[1],
                borderColor: payload.style?.[".packagelist-2 .package"]?.[
                  "borderColor"
                ]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[".packagelist-2 .package"]?.[
                          "borderColor"
                        ],
                    })
                  : "",
                "border-radius":
                  payload.style?.[".packagelist-2 .package"]?.["border-radius"],
              },
              ".packages-section .packagelist-2 .package .title": {
                padding:
                  payload.style?.[
                    ".packages-section .packagelist-2 .package .title"
                  ]?.["padding"],
                color: payload.style?.[
                  ".packages-section .packagelist-2 .package .title"
                ]?.["color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          ".packages-section .packagelist-2 .package .title"
                        ]?.["color"],
                    })
                  : "",
                "font-size":
                  payload.style?.[
                    ".packages-section .packagelist-2 .package .title"
                  ]?.["font-size"],
                "font-weight":
                  payload.style?.[
                    ".packages-section .packagelist-2 .package .title"
                  ]?.["font-weight"],
                "background-color": payload.style?.[
                  ".packages-section .packagelist-2 .package .title"
                ]?.["background-color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          ".packages-section .packagelist-2 .package .title"
                        ]?.["background-color"],
                    })
                  : "",
                "text-align":
                  payload.style?.[
                    ".packages-section .packagelist-2 .package .title"
                  ]?.["text-align"],
                "text-transform":
                  payload.style?.[
                    ".packages-section .packagelist-2 .package .title"
                  ]?.["text-transform"],
              },
              ".packagelist-2 .package .cost": {
                padding:
                  payload.style?.[".packagelist-2 .package .cost"]?.[
                    "text-transform"
                  ],
                p1: payload.style?.[".packagelist-2 .package .cost"]?.[
                  "padding"
                ]?.split(" ")[0],
                p2: payload.style?.[".packagelist-2 .package .cost"]?.[
                  "padding"
                ]?.split(" ")[1],
                "text-align":
                  payload.style?.[".packagelist-2 .package .cost"]?.[
                    "text-align"
                  ],
                "background-color": payload.style?.[
                  ".packagelist-2 .package .cost"
                ]?.["background-color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[".packagelist-2 .package .cost"]?.[
                          "background-color"
                        ],
                    })
                  : "",
              },
              ".packagelist-2 .package .cost span": {
                border:
                  payload.style?.[".packagelist-2 .package .cost span"]?.[
                    "border"
                  ],
                borderPx:
                  payload.style?.[".packagelist-2 .package .cost span"]?.[
                    "border"
                  ]?.split(" ")[0],
                borderThik:
                  payload.style?.[".packagelist-2 .package .cost span"]?.[
                    "border"
                  ]?.split(" ")[1],
                borderColor: payload.style?.[
                  ".packagelist-2 .package .cost span"
                ]?.["borderColor"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[".packagelist-2 .package .cost span"]?.[
                          "borderColor"
                        ],
                    })
                  : "",
                "border-radius":
                  payload.style?.[".packagelist-2 .package .cost span"]?.[
                    "border-radius"
                  ],
                "line-height":
                  payload.style?.[".packagelist-2 .package .cost span"]?.[
                    "line-height"
                  ],
                "text-align":
                  payload.style?.[".packagelist-2 .package .cost span"]?.[
                    "text-align"
                  ],
                display:
                  payload.style?.[".packagelist-2 .package .cost span"]?.[
                    "display"
                  ],
                color: payload.style?.[".packagelist-2 .package .cost span"]?.[
                  "color"
                ]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[".packagelist-2 .package .cost span"]?.[
                          "color"
                        ],
                    })
                  : "",
                "box-shadow":
                  payload.style?.[".packagelist-2 .package .cost span"]?.[
                    "box-shadow"
                  ],
                bs1: payload.style?.[".packagelist-2 .package .cost span"]?.[
                  "box-shadow"
                ]?.split(" ")[1],
                bs2: payload.style?.[".packagelist-2 .package .cost span"]?.[
                  "box-shadow"
                ]?.split(" ")[2],
                bs3: payload.style?.[".packagelist-2 .package .cost span"]?.[
                  "box-shadow"
                ]?.split(" ")[3],
                shadowColor: payload.style?.[
                  ".packagelist-2 .package .cost span"
                ]?.["shadowColor"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[".packagelist-2 .package .cost span"]?.[
                          "shadowColor"
                        ],
                    })
                  : "",
              },
              ".packagelist-2 .package .subtitle": {
                padding:
                  payload.style?.[".packagelist-2 .package .subtitle"]?.[
                    "padding"
                  ],
                p1: payload.style?.[".packagelist-2 .package .subtitle"]?.[
                  "padding"
                ]?.split(" ")[0],
                p2: payload.style?.[".packagelist-2 .package .subtitle"]?.[
                  "padding"
                ]?.split(" ")[1],
                color: payload.style?.[".packagelist-2 .package .subtitle"]?.[
                  "color"
                ]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[".packagelist-2 .package .subtitle"]?.[
                          "color"
                        ],
                    })
                  : "",
                "background-color": payload.style?.[
                  ".packagelist-2 .package .subtitle"
                ]?.["background-color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[".packagelist-2 .package .subtitle"]?.[
                          "background-color"
                        ],
                    })
                  : "",
                "text-align":
                  payload.style?.[".packagelist-2 .package .subtitle"]?.[
                    "text-align"
                  ],
                "font-weight":
                  payload.style?.[".packagelist-2 .package .subtitle"]?.[
                    "font-weight"
                  ],
                "font-size":
                  payload.style?.[".packagelist-2 .package .subtitle"]?.[
                    "font-size"
                  ],
              },
              ".packagelist-2 .package .desq": {
                "background-color": payload.style?.[
                  ".packagelist-2 .package .desq"
                ]?.["background-color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[".packagelist-2 .package .desq"]?.[
                          "background-color"
                        ],
                    })
                  : "",
                padding:
                  payload.style?.[".packagelist-2 .package .desq"]?.["padding"],
              },
              ".packagelist-2 .package .desq li": {
                margin:
                  payload.style?.[".packagelist-2 .package .desq li"]?.[
                    "margin"
                  ],
                "padding-left":
                  payload.style?.[".packagelist-2 .package .desq li"]?.[
                    "padding-left"
                  ],
                "font-size":
                  payload.style?.[".packagelist-2 .package .desq li"]?.[
                    "font-size"
                  ],
                color: payload.style?.[".packagelist-2 .package .desq li"]?.[
                  "color"
                ]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[".packagelist-2 .package .desq li"]?.[
                          "color"
                        ],
                    })
                  : "",
              },
              ".packagelist-2 .package .desq li:before": {
                color: payload.style?.[
                  ".packagelist-2 .package .desq li:before"
                ]?.["color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          ".packagelist-2 .package .desq li:before"
                        ]?.["color"],
                    })
                  : "",
              },
              ".packages-section .packagelist-2 .package .selectbtn": {
                "text-align":
                  payload.style?.[
                    ".packages-section .packagelist-2 .package .selectbtn"
                  ]?.["text-align"],
                padding:
                  payload.style?.[
                    ".packages-section .packagelist-2 .package .selectbtn"
                  ]?.["padding"],
                display:
                  payload.style?.[
                    ".packages-section .packagelist-2 .package .selectbtn"
                  ]?.["display"],
                width:
                  payload.style?.[
                    ".packages-section .packagelist-2 .package .selectbtn"
                  ]?.["width"],
                "background-color": payload.style?.[
                  ".packages-section .packagelist-2 .package .selectbtn"
                ]?.["background-color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          ".packages-section .packagelist-2 .package .selectbtn"
                        ]?.["background-color"],
                    })
                  : "",
              },
              ".packagelist-2 .package .selectbtn a": {
                border:
                  payload.style?.[".packagelist-2 .package .selectbtn a"]?.[
                    "border"
                  ],
                borderPx:
                  payload.style?.[".packagelist-2 .package .selectbtn a"]?.[
                    "border"
                  ]?.split(" ")[0],
                borderThik:
                  payload.style?.[".packagelist-2 .package .selectbtn a"]?.[
                    "border"
                  ]?.split(" ")[1],
                borderColor: payload.style?.[
                  ".packagelist-2 .package .selectbtn a"
                ]?.["borderColor"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          ".packagelist-2 .package .selectbtn a"
                        ]?.["borderColor"],
                    })
                  : "",
                padding:
                  payload.style?.[".packagelist-2 .package .selectbtn a"]?.[
                    "padding"
                  ],
                p1: payload.style?.[".packagelist-2 .package .selectbtn a"]?.[
                  "padding"
                ]?.split(" ")[0],
                p2: payload.style?.[".packagelist-2 .package .selectbtn a"]?.[
                  "padding"
                ]?.split(" ")[1],
                "font-size":
                  payload.style?.[".packagelist-2 .package .selectbtn a"]?.[
                    "font-size"
                  ],
                "text-transform":
                  payload.style?.[".packagelist-2 .package .selectbtn a"]?.[
                    "text-transform"
                  ],
                "letter-spacing":
                  payload.style?.[".packagelist-2 .package .selectbtn a"]?.[
                    "letter-spacing"
                  ],
                color: payload.style?.[
                  ".packagelist-2 .package .selectbtn a"
                ]?.["color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          ".packagelist-2 .package .selectbtn a"
                        ]?.["color"],
                    })
                  : "",
                "border-radius":
                  payload.style?.[".packagelist-2 .package .selectbtn a"]?.[
                    "border-radius"
                  ],
              },
              ".packagelist-2 .package .selectbtn a:hover": {
                "background-color": payload.style?.[
                  ".packagelist-2 .package .selectbtn a:hover"
                ]?.["background-color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          ".packagelist-2 .package .selectbtn a:hover"
                        ]?.["background-color"],
                    })
                  : "",
                color: payload.style?.[
                  ".packagelist-2 .package .selectbtn a:hover"
                ]?.["color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          ".packagelist-2 .package .selectbtn a:hover"
                        ]?.["color"],
                    })
                  : "",
              },
              custom: payload?.style?.custom,
              ...getMenuBtnStyle,
            };
          });
        }
        if (!isThemeEdit) {
          OpenModalCommonFunc(Cases.SETTING_PACKAGES_PARENT, setAllPopupState);
          TostMessage({ message: data, type: "success" });
        }
        setIsEdit(true);
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * delete packages
   * @param {id} packageId
   */
  const handleDelete = async (packageId, isParent = false) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await deletePackages(packageId);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            // getPackagesParentList(null, true);
            ByteMiniWeb_Dashboard();
          } else {
            getPackagesParentList({ packageId: childId });
          }
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * clear data
   */
  const emptyData = () => {
    setIsBackgroundColor(false);
    setBoxSection(false);
    setIsEdit(false);
    setIsParent(false);
    setChildId("");
    setFormData({
      title: "",
      subTitle: "",
      slugUrl: "",
      buttonText: "",
      itemLimit: "",
      navMapId: "",
      webId: localStorage.getItem("webId"),
      theme: 1,
      isTitleShow: true,
      style: {
        ".packages-section": {
          padding: "100px 0",
          "background-color": "rgb(255 255 255 / 100)",
        },
        ".packages-section.bg-image": {
          " background-image": "",
        },
        ".packages-section.bg-image::before": {
          "background-color": "",
        },
        ".packages-section .section-title-area": {
          "margin-bottom": "50px",
        },
        ".packages-section .section-title-area .section-subtitle": {
          "font-size": "16px",
          "font-weight": "600",
          color: "rgb(255 73 124 / 100)",
          "background-color": "rgb(255 73 124 / 20%)",
          padding: "5px 20px",
          "border-radius": "15px",
          "margin-bottom": "15px",
        },
        ".packages-section .section-title-area .section-title": {
          "font-size": "44px",
          "font-weight": "600",
          color: "rgb(0 0 0 / 100)",
        },
        ".planswitch": {
          "align-items": "center",
          "justify-content": "center",
          "margin-bottom": "20px",
        },
        ".planswitch .switch-text": {
          color: "rgb(0 0 0 / 100)",
          "font-size": "16px",
          "font-weight": "600",
        },
        ".switch .slider": {
          "border-radius": "34px",
          "background-color": "rgb(204 204 204 / 100)",
        },
        ".switch .slider:before": {
          bottom: "4px",
          "border-radius": "50%",
          "background-color": "rgb(255 255 255 / 100)",
        },
        ".switch input:checked + .slider": {
          "background-color": "rgb(255 73 124 / 100)",
        },
        ".packagelist-1 .package": {
          border: "1px solid rgb(238 238 238 / 100)",
          borderColor: "rgb(238 238 238 / 100)",
          "border-radius": "5px",
        },
        ".packages-section .packagelist-1 .package .title": {
          color: "rgb(255 255 255 / 100)",
          padding: "30px 10px 20px",
          "text-align": "center",
          "text-transform": "uppercase",
          "background-color": "rgb(255 73 124 / 100)",
        },
        ".packagelist-1 .package .title:before": {
          "background-color": "rgb(238 238 238 / 100)",
        },
        ".packagelist-1 .package .title h3": {
          "font-weight": "700",
          "font-size": "20px",
        },
        ".packagelist-1 .package .title p": {
          "font-weight": "400",
        },
        ".packagelist-1 .package .cost": {
          "background-color": "rgb(238 238 238 / 100)",
          width: "100%",
          "text-align": "center",
          padding: "15px 10px 25px",
          "font-size": "18px",
          "font-weight": "700",
        },
        ".packagelist-1 .package .cost:before": {
          "background-color": "rgb(255 255 255 / 100)",
        },
        ".packagelist-1 .package .cost .price": {
          "font-size": "35px",
        },
        ".packagelist-1 .package .desq": {
          "background-color": "rgb(255 255 255 / 100)",
          padding: "10px 10px 20px",
        },
        ".packagelist-1 .package .desq li": {
          "font-size": "14px",
          "font-weight": "400",
          color: "rgb(102 102 102 / 100)",
          margin: "15px 0",
          padding: "0 5px 0 20px",
        },
        ".packagelist-1 .package .desq li:before": {
          color: "rgb(255 73 124 / 100)",
        },
        ".packages-section .packagelist-1 .package .selectbtn": {
          "background-color": "rgb(255 73 124 / 100)",
          padding: "10px 10px 15px",
          "font-size": "16px",
          color: "rgb(255 255 255 / 100)",
          "font-weight": "400",
          "text-transform": "uppercase",
          "text-align": "center",
        },
        ".packagelist-1 .package .selectbtn:before": {
          "background-color": "rgb(255 73 124 / 100)",
        },
        ".packagelist-2 .package": {
          border: "1px solid rgb(238 238 238 / 100)",
          borderColor: "rgb(238 238 238 / 100)",
          "border-radius": "5px",
        },
        ".packages-section .packagelist-2 .package .title": {
          padding: "15px",
          color: "rgb(255 255 255 / 100)",
          "font-size": "20px",
          "font-weight": "500",
          "background-color": "rgb(255 73 124 / 100)",
          "text-align": "center",
          "text-transform": "uppercase",
        },
        ".packagelist-2 .package .cost": {
          padding: "20px 10px",
          "text-align": "center",
          "background-color": "rgb(31 39 50 / 100)",
        },
        ".packagelist-2 .package .cost span": {
          border: "2px solid rgb(255 255 255 / 100)",
          borderColor: "rgb(255 255 255 / 100)",
          "border-radius": "50%",
          "line-height": "70px",
          "text-align": "center",
          display: "inline-block",
          color: "rgb(255 255 255 / 100)",
          "box-shadow": "0 2px 2px 1px rgb(0 0 0 / 0.188)",
          shadowColor: "rgb(0 0 0 / 0.188)",
        },
        ".packagelist-2 .package .subtitle": {
          padding: "15px 10px",
          color: "rgb(255 255 255 / 100)",
          "background-color": "rgb(117 127 137 / 100)",
          "text-align": "center",
          "font-weight": "400",
          "font-size": "14px",
        },
        ".packagelist-2 .package .desq": {
          "background-color": "rgb(255 255 255 / 100)",
          padding: "13px",
        },
        ".packagelist-2 .package .desq li": {
          margin: "5px 0",
          "padding-left": "22px",
          "font-size": "12px",
          color: "rgb(102 102 102 / 100)",
        },
        ".packagelist-2 .package .desq li:before": {
          color: "rgb(255 192 203 / 100)",
        },
        ".packages-section .packagelist-2 .package .selectbtn": {
          "text-align": "center",
          padding: "10px",
          display: "inline-block",
          width: "100%",
          "background-color": "rgb(255 73 124 / 100)",
        },
        ".packagelist-2 .package .selectbtn a": {
          border: "2px solid rgb(255 255 255 / 100)",
          borderColor: "rgb(255 255 255 / 100)",
          padding: "8px 25px",
          "font-size": "12px",
          "text-transform": "uppercase",
          "letter-spacing": "1px",
          color: "rgb(255 255 255 / 100)",
          "border-radius": "3px",
        },
        ".packagelist-2 .package .selectbtn a:hover": {
          "background-color": "rgb(255 255 255 / 100)",
          color: "rgb(102 102 102 / 100)",
        },
        ".packages-section .more-btn": {
          color: "rgb(255 255 255 / 100)",
          "font-size": "16px",
          "font-weight": "normal",
          "border-radius": "15px",
          padding: "5px 20px",
          "background-color": "rgb(255 73 124 / 100)",
        },
        ".packages-section .more-btn:hover": {
          "background-color": "rgb(255 73 124 / 100)",
          color: "rgb(255 255 255 / 100)",
        },
        ".pagination-positions": {
          "justify-content": "center",
        },
        ".pagination .page-link": {
          color: "rgb(255 73 124 / 100)",
        },
        ".pagination .page-item.active .page-link": {
          "background-color": "rgb(255 73 124 / 100)",
          "border-color": "rgb(255 73 124 / 100)",
        },
      },
    });
    setSkill([{ id: randomStr(2), skill: "", parentageOfSkill: "" }]);
    setGalleryImgId("");
    setFormDataChild({
      image: "",
      title: "",
      subTitle: "",
      buttonText: "",
      valueText: "",
      planDuration: "",
      value: "",
      buttonValue: "",
      isTitleShow: true,
      featureObject: [
        {
          id: randomStr(2),
          icon: "",
          description: "",
        },
      ],
      order: 1,
      webId: localStorage.getItem("webId"),
    });
    setLoaderShow(false);
    setSettingFormData({
      ".packages-section": {
        padding: "100px 0",
        "background-color": "rgb(255 255 255 / 100)",
      },
      ".packages-section.bg-image": {
        " background-image": "",
      },
      ".packages-section.bg-image::before": {
        "background-color": "",
      },
      ".packages-section .section-title-area": {
        "margin-bottom": "50px",
      },
      ".packages-section .section-title-area .section-subtitle": {
        "font-size": "16px",
        "font-weight": "600",
        color: "rgb(255 73 124 / 100)",
        "background-color": "rgb(255 73 124 / 20%)",
        padding: "5px 20px",
        "border-radius": "15px",
        "margin-bottom": "15px",
      },
      ".packages-section .section-title-area .section-title": {
        "font-size": "44px",
        "font-weight": "600",
        color: "rgb(0 0 0 / 100)",
      },
      ".planswitch": {
        "align-items": "center",
        "justify-content": "center",
        "margin-bottom": "20px",
      },
      ".planswitch .switch-text": {
        color: "rgb(0 0 0 / 100)",
        "font-size": "16px",
        "font-weight": "600",
      },
      ".switch .slider": {
        "border-radius": "34px",
        "background-color": "rgb(204 204 204 / 100)",
      },
      ".switch .slider:before": {
        bottom: "4px",
        "border-radius": "50%",
        "background-color": "rgb(255 255 255 / 100)",
      },
      ".switch input:checked + .slider": {
        "background-color": "rgb(255 73 124 / 100)",
      },
      ".packagelist-1 .package": {
        border: "1px solid rgb(238 238 238 / 100)",
        borderColor: "rgb(238 238 238 / 100)",
        "border-radius": "5px",
      },
      ".packages-section .packagelist-1 .package .title": {
        color: "rgb(255 255 255 / 100)",
        padding: "30px 10px 20px",
        "text-align": "center",
        "text-transform": "uppercase",
        "background-color": "rgb(255 73 124 / 100)",
      },
      ".packagelist-1 .package .title:before": {
        "background-color": "rgb(238 238 238 / 100)",
      },
      ".packagelist-1 .package .title h3": {
        "font-weight": "700",
        "font-size": "20px",
      },
      ".packagelist-1 .package .title p": {
        "font-weight": "400",
      },
      ".packagelist-1 .package .cost": {
        "background-color": "rgb(238 238 238 / 100)",
        width: "100%",
        "text-align": "center",
        padding: "15px 10px 25px",
        "font-size": "18px",
        "font-weight": "700",
      },
      ".packagelist-1 .package .cost:before": {
        "background-color": "rgb(255 255 255 / 100)",
      },
      ".packagelist-1 .package .cost .price": {
        "font-size": "35px",
      },
      ".packagelist-1 .package .desq": {
        "background-color": "rgb(255 255 255 / 100)",
        padding: "10px 10px 20px",
      },
      ".packagelist-1 .package .desq li": {
        "font-size": "14px",
        "font-weight": "400",
        color: "rgb(102 102 102 / 100)",
        margin: "15px 0",
        padding: "0 5px 0 20px",
      },
      ".packagelist-1 .package .desq li:before": {
        color: "rgb(255 73 124 / 100)",
      },
      ".packages-section .packagelist-1 .package .selectbtn": {
        "background-color": "rgb(255 73 124 / 100)",
        padding: "10px 10px 15px",
        "font-size": "16px",
        color: "rgb(255 255 255 / 100)",
        "font-weight": "400",
        "text-transform": "uppercase",
        "text-align": "center",
      },
      ".packagelist-1 .package .selectbtn:before": {
        "background-color": "rgb(255 73 124 / 100)",
      },
      ".packagelist-2 .package": {
        border: "1px solid rgb(238 238 238 / 100)",
        borderColor: "rgb(238 238 238 / 100)",
        "border-radius": "5px",
      },
      ".packages-section .packagelist-2 .package .title": {
        padding: "15px",
        color: "rgb(255 255 255 / 100)",
        "font-size": "20px",
        "font-weight": "500",
        "background-color": "rgb(255 73 124 / 100)",
        "text-align": "center",
        "text-transform": "uppercase",
      },
      ".packagelist-2 .package .cost": {
        padding: "20px 10px",
        "text-align": "center",
        "background-color": "rgb(31 39 50 / 100)",
      },
      ".packagelist-2 .package .cost span": {
        border: "2px solid rgb(255 255 255 / 100)",
        borderColor: "rgb(255 255 255 / 100)",
        "border-radius": "50%",
        "line-height": "70px",
        "text-align": "center",
        display: "inline-block",
        color: "rgb(255 255 255 / 100)",
        "box-shadow": "0 2px 2px 1px rgb(0 0 0 / 0.188)",
        shadowColor: "rgb(0 0 0 / 0.188)",
      },
      ".packagelist-2 .package .subtitle": {
        padding: "15px 10px",
        color: "rgb(255 255 255 / 100)",
        "background-color": "rgb(117 127 137 / 100)",
        "text-align": "center",
        "font-weight": "400",
        "font-size": "14px",
      },
      ".packagelist-2 .package .desq": {
        "background-color": "rgb(255 255 255 / 100)",
        padding: "13px",
      },
      ".packagelist-2 .package .desq li": {
        margin: "5px 0",
        "padding-left": "22px",
        "font-size": "12px",
        color: "rgb(102 102 102 / 100)",
      },
      ".packagelist-2 .package .desq li:before": {
        color: "rgb(255 192 203 / 100)",
      },
      ".packages-section .packagelist-2 .package .selectbtn": {
        "text-align": "center",
        padding: "10px",
        display: "inline-block",
        width: "100%",
        "background-color": "rgb(255 73 124 / 100)",
      },
      ".packagelist-2 .package .selectbtn a": {
        border: "2px solid rgb(255 255 255 / 100)",
        borderColor: "rgb(255 255 255 / 100)",
        padding: "8px 25px",
        "font-size": "12px",
        "text-transform": "uppercase",
        "letter-spacing": "1px",
        color: "rgb(255 255 255 / 100)",
        "border-radius": "3px",
      },
      ".packagelist-2 .package .selectbtn a:hover": {
        "background-color": "rgb(255 255 255 / 100)",
        color: "rgb(102 102 102 / 100)",
      },
      ".packages-section .more-btn": {
        color: "rgb(255 255 255 / 100)",
        "font-size": "16px",
        "font-weight": "normal",
        "border-radius": "15px",
        padding: "5px 20px",
        "background-color": "rgb(255 73 124 / 100)",
      },
      ".packages-section .more-btn:hover": {
        "background-color": "rgb(255 73 124 / 100)",
        color: "rgb(255 255 255 / 100)",
      },
      ".pagination-positions": {
        "justify-content": "center",
      },
      ".pagination .page-link": {
        color: "rgb(255 73 124 / 100)",
      },
      ".pagination .page-item.active .page-link": {
        "background-color": "rgb(255 73 124 / 100)",
        "border-color": "rgb(255 73 124 / 100)",
      },
      packageId: "",
    });
  };

  const handleAlignAndCloneSection = ({ isClone = false }) => {
    if (isClone) {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          cloneModal: true,
        };
      });
      setCloneFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          pageConfigDetailsLabel: pageConfigDetails[0]?.label,
          contentDetailName: contentDetail.name,
        };
      });
    } else {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          alignModal: true,
        };
      });
      setAlignFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          label: pageConfigDetails[0]?.label,
        };
      });
    }
  };
  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody
            className="support-ticket-font box-border-div"
            style={{ padding: "20px" }}
          >
            <Row>
              <CardBuilderHeaderSection
                addBtnTitle={pageConfigDetails[0]?.label}
                name={pageConfigDetails[0]?.label}
                isAdd={!packagesDetail && true}
                mainEditSection={true}
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
                openModal={() => {
                  OpenModalCommonFunc(Cases.PACKAGES_PARENT, setAllPopupState);
                  setSectionPageName(modalsName.PACKAGES_PARENT);
                  emptyData();
                  setIsParent(true);
                  setCollId(contentMapId);
                }}
                isCloneSection={true}
                isAlignSection={true}
                handleAlignAndCloneSection={handleAlignAndCloneSection}
              />
              {packagesDetail && (
                <Col xl="12 xl-50 box-col-6" md="6">
                  <Card className="ecommerce-widget mb-0">
                    <CardBody
                      className="support-ticket-font"
                      style={{ padding: "20px" }}
                    >
                      <div className="col-12">
                        <CardBuilderHeaderSection
                          name={packagesDetail?.title}
                          checked={packagesDetail?.isActive}
                          switchToggle={true}
                          id={packagesDetail?.packageId}
                          handleChange={handleChangePackages}
                        />
                        <OpenPopupBtn
                          openPopup={() => {
                            getPackagesParentList({
                              parentId: packagesDetail.packageId,
                            });
                            setSectionPageName(packagesDetail?.title);
                            setCollId(contentMapId);
                            setNavMapId(packagesDetail?.navMapId);
                            setFilter("");
                          }}
                          name={`Add or View ${packagesDetail?.title}`}
                        />
                      </div>
                      <div className="d-flex justify-content-end ">
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleEditPackages(packagesDetail.packageId, true);
                            setCollId(contentMapId);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() =>
                            handleDelete(packagesDetail.packageId, true)
                          }
                        >
                          <Trash />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleChangeSettings(packagesDetail.packageId);
                          }}
                        >
                          <Settings />
                        </IconButton>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
              <DeleteSection
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
              />
            </Row>
          </CardBody>
        </Card>
      </Col>
      <CommonPopup
        open={allPopupState.packagesChildPopup}
        close={() => {
          OpenModalCommonFunc(Cases.PACKAGES, setAllPopupState, false);
          setIsParent(false);
          getPackagesParentList({ parentId: null, isClose: true });
        }}
        apiCall={getPackagesParentList}
        modalName={sectionPageName ? sectionPageName : modalsName.PACKAGES}
        modalCase={Cases.PACKAGES}
        handleSubmit={handleSubmit}
        validationForm={"childForm"}
        emptyData={emptyData}
        inputData={fromData}
        edit={false}
        oldTitle={titleUpdateKey.isGallery}
        label={titleUpdateKey.Certificates}
        getData={getData}
        content={
          <PackagesPopup
            handleChangeStatus={handleChangePackages}
            handleEdit={handleEditPackages}
            imageUpload={imageUploadImages}
            emptyData={emptyData}
            simpleValidator={childValidator}
            webMasterDetail={webMasterDetail}
            getPackagesParentList={getPackagesParentList}
            handleDelete={handleDelete}
          />
        }
      />

      {/* item feature parent popup */}
      <CommonPopup
        open={allPopupState.packagesPopup}
        close={() => {
          OpenModalCommonFunc(Cases.PACKAGES_PARENT, setAllPopupState, false);
          setIsParent(false);
        }}
        modalName={
          sectionPageName ? sectionPageName : modalsName.PACKAGES_PARENT
        }
        modalCase={Cases.PACKAGES_PARENT}
        handleSubmit={handleSubmit}
        validationForm={"parentForm"}
        emptyData={emptyData}
        inputData={formDataChild}
        edit={false}
        content={<PackagesParentPopup simpleValidator={parentValidator} />}
      />

      {/* setting images parent popup */}
      <CommonPopup
        open={allPopupState.packagesSettingPopup}
        close={() =>
          OpenModalCommonFunc(
            Cases.SETTING_PACKAGES_PARENT,
            setAllPopupState,
            false
          )
        }
        modalName={
          sectionPageName ? sectionPageName : modalsName.SETTING_PACKAGES_PARENT
        }
        modalCase={Cases.SETTING_PACKAGES_PARENT}
        handleSubmit={handleSubmitSetting}
        emptyData={emptyData}
        inputData={formDataChild}
        edit={false}
        content={
          <SettingPackagesParentPopup
            imageUpload={imageUploadSetting}
            handleSubmitSetting={handleSubmitSetting}
            handleChangeSettings={handleChangeSettings}
          />
        }
      />
    </>
  );
};

export default Packages;
