import React, { useContext, useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import PersonalPageContext from "../../context/PersonalPageContext/PersonalPageContaxt";
import { PERSONAL_PAGE_INCENTIVE } from "../../constant";
import { Tooltip } from "@mui/material";
import { IconButton } from "@material-ui/core";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { MuiTabelStyle } from "../../utils/MuiTabelStyle";
import {
  DeleteBrokerIncentive,
  EditBrokerIncentive,
  brokerIncentiveList,
} from "../../api/Organization Api/OrganizationApi";
import { toast } from "react-toastify";
import BrokerIncentiveContext from "../../context/BrokerIncentiveContext/BrokerIncentiveContext";
import BrokerIncentiveAddModel from "../BrokerIncentive/BrokerIncentiveAddModel";
import { TabelAnimation1 } from "../../Spinner";

const PersonalPageprevieaw = () => {
  const {
    setPersonalPageIncentive,
    personalPageIncentive,
    personalPageId,
    setPersonalPageId,
    setBrokerIncentiveListsPre,
    setIncentiveRecord,
    incentivePage,
    incentivePageSize,
    brokerIncentiveListsPre,
    incentiveRecord,
    setIncentivePageSize,
    setIncentivePage,
  } = useContext(PersonalPageContext);

  const [loader, setLoader] = useState(false);

  const {
    setBrokerIncentiveValue,
    setEditId,
    setIsEdit,
    setModal,
    personalPageIncentiveEdit,
    setPersonalPageIncentiveEdit,
  } = useContext(BrokerIncentiveContext);

  const toggle = () => {
    setPersonalPageIncentive(!personalPageIncentive);
    setPersonalPageId(null);
    setEditId();
  };

  const handleIncentive = async () => {
    try {
      setLoader(true);
      let formData = {
        search: JSON.stringify(personalPageId),
        pageNumber: incentivePage,
        recordsPerPage: incentivePageSize,
      };
      const { data } = await brokerIncentiveList(formData);
      if (data.status === 200) {
        setBrokerIncentiveListsPre(data.payload.data);
        setIncentiveRecord(data.pager.totalRecords);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };
  useEffect(() => {
    handleIncentive();
  }, [incentivePage, incentivePageSize]);

  const defaultColumns = [
    {
      field: "brokerIncentiveId",
      headerName: "ID",
      width: 50,
      editable: true,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "pageTitle",
      headerName: "Broker",
      flex: 0.1,
      minWidth: 100,
      renderCell: (params) =>
        params.row.pageDetail
          ? params.row.brokerDetail.pageTitle
          : params.row.brokerDetail,
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      flex: 0.1,
      minWidth: 100,
      editable: true,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "propertyDetail",
      headerName: "Property Detail",
      flex: 0.1,
      minWidth: 100,
      editable: true,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "customerNumber",
      headerName: "Customer Number",
      flex: 0.1,
      minWidth: 100,
      editable: true,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "incentive",
      headerName: "Incentive",
      flex: 0.1,
      minWidth: 100,
      editable: true,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Tooltip title="Edit">
          <IconButton
            aria-label="edit"
            style={{ color: "green" }}
            onClick={() => EditHandle(params.row.brokerIncentiveId)}
          >
            <ModeEditIcon />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Tooltip title="Edit">
          <IconButton
            aria-label="edit"
            style={{ color: "red" }}
            onClick={() => DeleteHandle(params.row.brokerIncentiveId)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  const EditHandle = async (ele) => {
    let formData = { brokerIncentiveId: ele };
    try {
      const { data } = await EditBrokerIncentive(formData);
      if (data.status === 200) {
        const prevData = data.payload.data;
        setBrokerIncentiveValue({
          pageId: prevData.pageId,
          brokerId: prevData.brokerId,
          customerName: prevData.customerName,
          customerNumber: prevData.customerNumber,
          propertyDetail: prevData.propertyDetail,
          incentive: prevData.incentive,
        });
        setEditId(ele);
        setIsEdit(true);
        setModal(true);
        setPersonalPageIncentiveEdit(true);
      }
    } catch (error) {}
  };

  const DeleteHandle = async (ele) => {
    const result = window.confirm(
      "Are you sure you want to delete the broker page?"
    );
    if (result === true) {
      try {
        let formData = {
          brokerIncentiveId: ele,
        };
        const { data } = await DeleteBrokerIncentive(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
          });
          handleIncentive(personalPageId);
        }
      } catch (error) {
        toast.error(error.response.data.message, {
          autoClose: 1000,
        });
      }
    }
  };

  return (
    <>
      {personalPageIncentiveEdit && (
        <BrokerIncentiveAddModel handleIncentive={handleIncentive} />
      )}

      <Modal size="xl" isOpen={personalPageIncentive} toggle={toggle}>
        {/* <TableSkeleton /> */}
        <ModalHeader toggle={toggle}>
          {PERSONAL_PAGE_INCENTIVE.TOTAL_INCENTIVE}
        </ModalHeader>
        <ModalBody>
          {loader ? (
            <>
              <TabelAnimation1 />
            </>
          ) : (
            <>
              <Box sx={{ width: "100%" }}>
                <DataGrid
                  autoHeight
                  pagination
                  sx={MuiTabelStyle}
                  rows={brokerIncentiveListsPre}
                  getRowId={(rows) => rows.brokerIncentiveId}
                  columns={defaultColumns}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  rowCount={incentiveRecord}
                  page={incentivePage - 1}
                  pageSize={incentivePageSize}
                  onPageSizeChange={(newPageSize) =>
                    setIncentivePageSize(newPageSize)
                  }
                  onPageChange={(newPageNo) => setIncentivePage(newPageNo + 1)}
                  paginationMode="server"
                  disableSelectionOnClick
                />
              </Box>
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default PersonalPageprevieaw;
