import React, { useContext } from "react";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { IconButton } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import { Cases, DELETE_CONFIRM } from "../../../constant";
import Dividers from "../../../common/divider";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Col, Container, Input, Media, Row } from "reactstrap";
import { Divider } from "antd";
import MuiInput from "@mui/material/Input";
import { maxLengthCheck, randomStr } from "../../../../utils/helper";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import CompanyLogoContext from "../../../context/CompanyLogoContext/CompanyLogoContext";
import { delete_Image_Permanent } from "../../../apis/SliderApi/SliderApi";
import { XCircle } from "react-feather";
import { splitPathWeb } from "../../../apiConstants";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";

const CompantLogoAndServicesPopup = (props) => {
  const { imageUpload } = props;
  const { fromData, setFormData, isEdit } = useContext(CompanyLogoContext);
  const {
    allPopupState,
    setAllPopupState,
    ourServices,
    setOverServices,
    show,
    setShow,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const { setLoaderShow } = useContext(LoaderContext);

  const handleAddMoreFeilds = () => {
    setOverServices((prev) => {
      return [
        ...prev,
        {
          id: randomStr(2),
          name: "",
        },
      ];
    });
  };

  const handleRemoveFeilds = (id) => {
    setOverServices((prev) => prev.filter((item) => item.id !== id));
  };

  const handleChange = (e, i) => {
    const { name, value } = e.target;
    let old = [...ourServices];
    old[i][name] = value;
    setOverServices(old);
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const reupload = () => {
    setFormData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };

  const deleteImagePermanent = async () => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData = {
          fileName: fromData.logo
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
            .pop(),
        };
        const { data } = await delete_Image_Permanent(formData);
        if (data.status === 200) {
          TostMessage({ message: data, type: "success", position: "center" });
          setFormData({ ...fromData, image: "", logo: "" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };
  return (
    <Container>
      <Dividers divide="COMPANY LOGO " />
      <Row>
        <Col md="12 mb-3">
          <div
            className="d-flex justify-content-center align-items-center flex-wrap"
            style={{ gap: "35px" }}
          >
            <div className="user-image">
              <div className="avatar position-relative ">
                <div className=".aj-avatar-upload">
                  <Media
                    body
                    alt=""
                    src={
                      fromData.logo
                        ? fromData.logo
                        : fromData.image
                        ? fromData.image
                        : require("./no-img.jpg")
                    }
                    data-intro="This is Profile image"
                    className="aj-avatar-upload"
                    onClick={() =>
                      !fromData.logo
                        ? OpenModalCommonFunc(
                            Cases.PRODUCT_UPLOAD,
                            setAllPopupState
                          )
                        : deleteImagePermanent()
                    }
                  />
                  {fromData.logo && fromData.image && (
                    <div
                      className="position-absolute "
                      style={{ top: "-7px", left: "75px" }}
                    >
                      <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => deleteImagePermanent()}
                      >
                        <XCircle style={{ color: "red" }} />
                      </IconButton>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <p>Image size should be 512px x 512px, File type: PNG or JPG</p>
          </div>
        </Col>
      </Row>
      <Dividers divide="COMPANY DETAILS " />
      <Row>
        <Col md="12 mb-3">
          <Input
            className="form-control"
            name="name"
            type="text"
            placeholder="Name"
            value={fromData.name}
            onChange={(e) => onInputChange(e)}
            min={0}
            maxLength={80}
            onInput={maxLengthCheck}
          />
        </Col>
        <Col md="12 mb-3">
          <Input
            className="form-control"
            name="designation"
            type="text"
            placeholder="Designation"
            value={fromData.designation}
            onChange={(e) => onInputChange(e)}
            min={0}
            maxLength={80}
            onInput={maxLengthCheck}
          />
        </Col>
      </Row>
      <Dividers divide="COMPANY TAGLINE " />
      <Row>
        <Col md="12 mb-3">
          <Input
            className="form-control"
            name="tagLine"
            type="text"
            placeholder="Company Tagline"
            value={fromData.tagLine}
            onChange={(e) => onInputChange(e)}
            min={0}
            maxLength={80}
            onInput={maxLengthCheck}
          />
        </Col>
      </Row>
      <Divider>
        <div className="d-flex align-items-center ">
          {show === false && (
            <>
              Our Services
              {/* <IconButton
                edge="start"
                color="inherit"
                style={{ marginLeft: "5px" }}
                onClick={() => setShow(true)}
              >
                <EditIcon />
              </IconButton> */}
            </>
          )}
          {show && (
            <>
              <MuiInput placeholder="Placeholder" />{" "}
              <IconButton
                edge="start"
                color="inherit"
                style={{ marginLeft: "5px", height: "40px" }}
              >
                <span style={{ fontSize: "20px" }}>✓</span>
              </IconButton>
              <IconButton
                edge="start"
                color="inherit"
                style={{ marginLeft: "5px", height: "40px" }}
                onClick={() => setShow(false)}
              >
                <span style={{ fontSize: "20px" }}>✕</span>
              </IconButton>
            </>
          )}
        </div>
      </Divider>

      <Row>
        <p className="mb-4">
          If you will add your service, we will show in your Card (Backside)
          Image else Card (Backside) will be blank with logo. For example, you
          can check Example Image
        </p>
        {ourServices.map((elem, i) => {
          return (
            <Col md="12 mb-3" className="d-flex" key={i}>
              <Input
                className="form-control"
                name="name"
                type="text"
                placeholder="Services"
                value={elem.name}
                onChange={(e) => handleChange(e, i)}
                min={0}
                maxLength={25}
                onInput={maxLengthCheck}
              />
              {ourServices.length > 1 && (
                <IconButton
                  edge="start"
                  color="inherit"
                  style={{ marginLeft: "5px", height: "40px" }}
                  onClick={() => handleRemoveFeilds(elem.id)}
                >
                  <span style={{ fontSize: "20px", color: "red" }}>✕</span>
                </IconButton>
              )}
            </Col>
          );
        })}
      </Row>
      {ourServices.length !== 14 && (
        <Button
          edge="start"
          color="primary"
          onClick={() => handleAddMoreFeilds()}
        >
          <span style={{ display: "flex", alignItems: "center" }}>
            <Add /> Add new
          </span>
        </Button>
      )}
      <ProductImageUploadPopup
        allPopupState={allPopupState}
        setAllPopupState={setAllPopupState}
        crop={crop}
        setCrop={setCrop}
        completedCrop={completedCrop}
        setCompletedCrop={setCompletedCrop}
        scale={scale}
        rotate={rotate}
        image={fromData.image}
        setImage={setFormData}
        imageUpload={imageUpload}
        isEdit={isEdit}
        reupload={reupload}
        // aspect={1 / 1}
      />
    </Container>
  );
};

export default CompantLogoAndServicesPopup;
