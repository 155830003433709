import {
  addUrlImage,
  convertRGBA,
  removeUrlImage,
  removeUrlKeyInPath,
  rgbColorCreate,
} from "../../../../utils/helper";

export const InquiryThemeOneReset = {
  ".inquiry-section": {
    padding: "100px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".inquiry-section.bg-image": {},
  ".inquiry-section.bg-image::before": {
    // "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
  },
  ".inquiry-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".inquiry-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: { r: 255, g: 73, b: 124, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 0.2 },
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".inquiry-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: { r: 0, g: 0, b: 0, a: 100 },
  },
  ".inquirybox": {
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
    padding: "40px 40px",
    p1: "40px",
    p2: "40px",
    "border-radius": "15px",
    border: "1px solid { r: 255, g: 255, b: 255, a: 100 }",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: { r: 255, g: 255, b: 255, a: 100 },
    "border-top": "3px solid { r: 255, g: 73, b: 124, a: 100 }",
    borderTPx: "3px",
    borderTThik: "solid",
    borderTColor: { r: 255, g: 73, b: 124, a: 100 },
    "box-shadow": "5px 5px 15px { r: 0, g: 0, b: 0, a: 0.09 }",
    bs1: "5px",
    bs2: "5px",
    bs3: "15px",
    shedowColor: { r: 0, g: 0, b: 0, a: 0.09 },
  },
  ".inquirybox .contact_form .form-control": {
    width: "100%",
    "margin-bottom": "10px",
    "min-height": "50px",
    padding: "10px 20px",
    p1: "10px",
    p2: "20px",
    "background-color": { r: 242, g: 247, b: 255, a: 100 },
    border: "1px solid { r: 206, g: 212, b: 218, a: 100 }",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: { r: 206, g: 212, b: 218, a: 100 },
    "border-radius": "4px",
    "font-size": "16px",
  },
  ".inquirybox .capchabox": {
    "background-color": { r: 246, g: 246, b: 246, a: 100 },
    padding: "15px",
  },
  ".inquirybox .capchabox .capchatitle": {
    "margin-bottom": "10px",
  },
  ".inquirybox .contact_form .btn": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  custom: "",
  inquiryId: "",
};
export const InquiryThemeOneFunction = (payload) => {
  return {
    ".inquiry-section": {
      padding: payload.style?.[".inquiry-section"]?.["padding"],
      "background-color": payload.style?.[".inquiry-section"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".inquiry-section"]?.["background-color"],
          })
        : "",
      width: payload.style?.[".inquiry-section"]?.["width"]?.replace(
        "calc(100% - ",
        ""
      ),
      margin: payload.style?.[".inquiry-section"]?.["margin"],
      "border-radius": payload.style?.[".inquiry-section"]?.["border-radius"],
    },
    ".inquiry-section.bg-image": {
      "background-image": payload.style?.[".inquiry-section.bg-image"]?.[
        "background-image"
      ]
        ? removeUrlKeyInPath({
            urlString:
              payload.style?.[".inquiry-section.bg-image"]?.[
                "background-image"
              ],
          })
        : "",
    },
    ".inquiry-section.bg-image::before": {
      "background-color": payload.style?.[
        ".inquiry-section.bg-image::before"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".inquiry-section.bg-image::before"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".inquiry-section .section-title-area": {
      "margin-bottom":
        payload.style?.[".inquiry-section .section-title-area"]?.[
          "margin-bottom"
        ],
    },
    ".inquiry-section .section-title-area .section-subtitle": {
      "font-size":
        payload.style?.[
          ".inquiry-section .section-title-area .section-subtitle"
        ]?.["font-size"],
      "font-weight":
        payload.style?.[
          ".inquiry-section .section-title-area .section-subtitle"
        ]?.["font-weight"],
      color: payload.style?.[
        ".inquiry-section .section-title-area .section-subtitle"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".inquiry-section .section-title-area .section-subtitle"
              ]?.["color"],
          })
        : "",
      "background-color": payload.style?.[
        ".inquiry-section .section-title-area .section-subtitle"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".inquiry-section .section-title-area .section-subtitle"
              ]?.["background-color"],
          })
        : "",
      padding:
        payload.style?.[
          ".inquiry-section .section-title-area .section-subtitle"
        ]?.["padding"],
      p1: payload.style?.[
        ".inquiry-section .section-title-area .section-subtitle"
      ]?.["padding"]?.split(" ")[0],
      p2: payload.style?.[
        ".inquiry-section .section-title-area .section-subtitle"
      ]?.["padding"]?.split(" ")[1],
      "border-radius":
        payload.style?.[
          ".inquiry-section .section-title-area .section-subtitle"
        ]?.["border-radius"],
      "margin-bottom":
        payload.style?.[
          ".inquiry-section .section-title-area .section-subtitle"
        ]?.["margin-bottom"],
    },
    ".inquiry-section .section-title-area .section-title": {
      "font-size":
        payload.style?.[
          ".inquiry-section .section-title-area .section-title"
        ]?.["font-size"],
      "font-weight":
        payload.style?.[
          ".inquiry-section .section-title-area .section-title"
        ]?.["font-weight"],
      color: payload.style?.[
        ".inquiry-section .section-title-area .section-title"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".inquiry-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
      "background-color": payload.style?.[
        ".inquiry-section .section-title-area .section-title"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".inquiry-section .section-title-area .section-title"
              ]?.["background-color"],
          })
        : "",
    },
    ".inquirybox": {
      "background-color": payload.style?.[".inquirybox"]?.["background-color"]
        ? convertRGBA({
            rgbString: payload.style?.[".inquirybox"]?.["background-color"],
          })
        : "",
      padding: payload.style?.[".inquirybox"]?.["padding"],
      p1: "40px",
      p2: "40px",
      "border-radius": payload.style?.[".inquirybox"]?.["border-radius"],
      border: payload.style?.[".inquirybox"]?.["border"],
      borderPx: payload.style?.[".inquirybox"]?.["border"]?.split(" ")[0],
      borderThik: payload.style?.[".inquirybox"]?.["border"]?.split(" ")[1],
      borderColor: payload.style?.[".inquirybox"]?.["borderColor"]
        ? convertRGBA({
            rgbString: payload.style?.[".inquirybox"]?.["borderColor"],
          })
        : "",
      "border-top": payload.style?.[".inquirybox"]?.["border-top"],
      borderTPx: payload.style?.[".inquirybox"]?.["border-top"]?.split(" ")[0],
      borderTThik:
        payload.style?.[".inquirybox"]?.["border-top"]?.split(" ")[1],
      borderTColor: payload.style?.[".inquirybox"]?.["borderTColor"]
        ? convertRGBA({
            rgbString: payload.style?.[".inquirybox"]?.["borderTColor"],
          })
        : "",
      "box-shadow": payload.style?.[".inquirybox"]?.["box-shadow"],

      bs1: payload.style?.[".inquirybox"]?.["box-shadow"]?.split(" ")[0],
      bs2: payload.style?.[".inquirybox"]?.["box-shadow"]?.split(" ")[1],
      bs3: payload.style?.[".inquirybox"]?.["box-shadow"]?.split(" ")[2],
      shedowColor: payload.style?.[".inquirybox"]?.["shedowColor"]
        ? convertRGBA({
            rgbString: payload.style?.[".inquirybox"]?.["shedowColor"],
          })
        : "",
    },
    ".inquirybox .contact_form .form-control": {
      width:
        payload.style?.[".inquirybox .contact_form .form-control"]?.["width"],
      "margin-bottom":
        payload.style?.[".inquirybox .contact_form .form-control"]?.[
          "margin-bottom"
        ],
      "min-height":
        payload.style?.[".inquirybox .contact_form .form-control"]?.[
          "min-height"
        ],
      padding:
        payload.style?.[".inquirybox .contact_form .form-control"]?.["padding"],
      p1: payload.style?.[".inquirybox .contact_form .form-control"]?.[
        "padding"
      ]?.split(" ")[0],
      p2: payload.style?.[".inquirybox .contact_form .form-control"]?.[
        "padding"
      ]?.split(" ")[1],
      "background-color": payload.style?.[
        ".inquirybox .contact_form .form-control"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".inquirybox .contact_form .form-control"]?.[
                "background-color"
              ],
          })
        : "",
      border:
        payload.style?.[".inquirybox .contact_form .form-control"]?.["border"],
      borderPx:
        payload.style?.[".inquirybox .contact_form .form-control"]?.[
          "border"
        ]?.split(" ")[0],
      borderThik:
        payload.style?.[".inquirybox .contact_form .form-control"]?.[
          "border"
        ]?.split(" ")[1],
      borderColor: payload.style?.[".inquirybox .contact_form .form-control"]?.[
        "borderColor"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".inquirybox .contact_form .form-control"]?.[
                "borderColor"
              ],
          })
        : "",
      "border-radius":
        payload.style?.[".inquirybox .contact_form .form-control"]?.[
          "border-radius"
        ],
      "font-size":
        payload.style?.[".inquirybox .contact_form .form-control"]?.[
          "font-size"
        ],
    },
    ".inquirybox .capchabox": {
      "background-color": payload.style?.[".inquirybox .capchabox"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".inquirybox .capchabox"]?.["background-color"],
          })
        : "",
      padding: payload.style?.[".inquirybox .capchabox"]?.["padding"],
    },
    ".inquirybox .capchabox .capchatitle": {
      "margin-bottom":
        payload.style?.[".inquirybox .capchabox .capchatitle"]?.[
          "margin-bottom"
        ],
    },
    ".inquirybox .contact_form .btn": {
      color: payload.style?.[".inquirybox .contact_form .btn"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".inquirybox .contact_form .btn"]?.["color"],
          })
        : "",
      "background-color": payload.style?.[".inquirybox .contact_form .btn"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".inquirybox .contact_form .btn"]?.[
                "background-color"
              ],
          })
        : "",
    },
    custom: payload?.style?.custom,
  };
};
export const InquiryStyleOneSubFun = ({ settingFormData, boxSection }) => {
  let newData = {
    ...settingFormData,
    ".inquiry-section": boxSection
      ? {
          ...settingFormData[".inquiry-section"],
          padding: `${settingFormData[".inquiry-section"]["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px`,
          width: `calc(100% - ${settingFormData[".inquiry-section"][
            "width"
          ]?.replace("rem)", "")}rem)`,
          margin: `${settingFormData[".inquiry-section"]["margin"]
            ?.split(" ")[0]
            ?.replace("px", "")}px auto`,
          "border-radius": `${settingFormData[".inquiry-section"][
            "border-radius"
          ]?.replace("rem", "")}rem`,
        }
      : {
          ...settingFormData[".inquiry-section"],
          padding: `${settingFormData[".inquiry-section"]["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px`,
        },
    ".inquiry-section.bg-image": settingFormData[".inquiry-section.bg-image"][
      "background-image"
    ]
      ? {
          "background-image": settingFormData[".inquiry-section.bg-image"][
            "background-image"
          ]
            ? addUrlImage({
                urlString: removeUrlImage({
                  urlString:
                    settingFormData?.[".inquiry-section.bg-image"]?.[
                      "background-image"
                    ],
                }),
              })
            : "",
        }
      : {},
    ".inquiry-section.bg-image::before": settingFormData[
      ".inquiry-section.bg-image::before"
    ]["background-color"]
      ? {
          "background-color": settingFormData[
            ".inquiry-section.bg-image::before"
          ]["background-color"]
            ? rgbColorCreate({
                rgbString:
                  settingFormData[".inquiry-section.bg-image::before"][
                    "background-color"
                  ],
              })
            : "",
        }
      : {},
    ".inquiry-section .section-title-area": {
      ...settingFormData[".inquiry-section .section-title-area"],
      "margin-bottom": `${settingFormData[
        ".inquiry-section .section-title-area"
      ]["margin-bottom"]?.replace("px", "")}px`,
    },
    ".inquiry-section .section-title-area .section-subtitle": {
      ...settingFormData[
        ".inquiry-section .section-title-area .section-subtitle"
      ],
      "font-size": `${settingFormData[
        ".inquiry-section .section-title-area .section-subtitle"
      ]["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".inquiry-section .section-title-area .section-subtitle"]["font-weight"]}`,
      color: settingFormData[
        ".inquiry-section .section-title-area .section-subtitle"
      ]["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".inquiry-section .section-title-area .section-subtitle"
              ]["color"],
          })
        : "",
      "background-color": settingFormData[
        ".inquiry-section .section-title-area .section-subtitle"
      ]["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".inquiry-section .section-title-area .section-subtitle"
              ]["background-color"],
          })
        : "",
      padding: `${settingFormData[
        ".inquiry-section .section-title-area .section-subtitle"
      ]["p1"]?.replace("px", "")}px ${settingFormData[
        ".inquiry-section .section-title-area .section-subtitle"
      ]["p2"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData[
        ".inquiry-section .section-title-area .section-subtitle"
      ]["border-radius"]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData[
        ".inquiry-section .section-title-area .section-subtitle"
      ]["border-radius"]?.replace("px", "")}px`,
    },
    ".inquiry-section .section-title-area .section-title": {
      ...settingFormData[".inquiry-section .section-title-area .section-title"],
      "font-size": `${settingFormData[
        ".inquiry-section .section-title-area .section-title"
      ]["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".inquiry-section .section-title-area .section-title"]["font-weight"]}`,
      color: settingFormData[
        ".inquiry-section .section-title-area .section-title"
      ]["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".inquiry-section .section-title-area .section-title"
              ]["color"],
          })
        : "",
    },
    ".inquirybox": {
      ...settingFormData[".inquirybox"],
      "background-color": settingFormData[".inquirybox"]["background-color"]
        ? rgbColorCreate({
            rgbString: settingFormData[".inquirybox"]["background-color"],
          })
        : "",
      padding: `${settingFormData[".inquirybox"]["p1"]?.replace(
        "px",
        ""
      )}px ${settingFormData[".inquirybox"]["p2"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData[".inquirybox"][
        "border-radius"
      ]?.replace("px", "")}px`,
      border: `${settingFormData[".inquirybox"]["borderPx"]?.replace(
        "px",
        ""
      )}px ${settingFormData[".inquirybox"]["borderThik"]} ${rgbColorCreate({
        rgbString: settingFormData[".inquirybox"]["borderColor"],
      })}`,
      "border-top": `${settingFormData[".inquirybox"]["borderTPx"]?.replace(
        "px",
        ""
      )}px ${settingFormData[".inquirybox"]["borderTThik"]} ${rgbColorCreate({
        rgbString: settingFormData[".inquirybox"]["borderTColor"],
      })}`,
      "box-shadow": `${settingFormData[".inquirybox"]["bs1"]?.replace(
        "px",
        ""
      )}px ${settingFormData[".inquirybox"]["bs2"]?.replace(
        "px",
        ""
      )}px ${settingFormData[".inquirybox"]["bs3"]?.replace(
        "px",
        ""
      )}px ${rgbColorCreate({
        rgbString: settingFormData[".inquirybox"]["shedowColor"],
      })}`,
    },
    ".inquirybox .contact_form .form-control": {
      ...settingFormData[".inquirybox .contact_form .form-control"],
      width: `${settingFormData[".inquirybox .contact_form .form-control"][
        "width"
      ]?.replace("%", "")}%`,
      "margin-bottom": `${settingFormData[
        ".inquirybox .contact_form .form-control"
      ]["margin-bottom"]?.replace("px", "")}px`,
      "min-height": `${settingFormData[
        ".inquirybox .contact_form .form-control"
      ]["min-height"]?.replace("px", "")}px`,
      padding: `${settingFormData[".inquirybox .contact_form .form-control"][
        "p1"
      ]?.replace("px", "")}px ${settingFormData[
        ".inquirybox .contact_form .form-control"
      ]["p2"]?.replace("px", "")}px`,
      "background-color": settingFormData[
        ".inquirybox .contact_form .form-control"
      ]["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".inquirybox .contact_form .form-control"][
                "background-color"
              ],
          })
        : "",
      border: `${settingFormData[".inquirybox .contact_form .form-control"][
        "borderPx"
      ]?.replace("px", "")}px ${
        settingFormData[".inquirybox .contact_form .form-control"]["borderThik"]
      } ${rgbColorCreate({
        rgbString:
          settingFormData[".inquirybox .contact_form .form-control"][
            "borderColor"
          ],
      })}`,
      "border-radius": `${settingFormData[
        ".inquirybox .contact_form .form-control"
      ]["border-radius"]?.replace("px", "")}px`,
      "font-size": `${settingFormData[
        ".inquirybox .contact_form .form-control"
      ]["font-size"]?.replace("px", "")}px`,
    },
    ".inquirybox .capchabox": {
      ...settingFormData[".inquirybox .capchabox"],
      "background-color": settingFormData[".inquirybox .capchabox"][
        "background-color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".inquirybox .capchabox"]["background-color"],
          })
        : "",
      padding: `${settingFormData[".inquirybox .capchabox"]["padding"]?.replace(
        "px",
        ""
      )}px`,
    },
    ".inquirybox .capchabox .capchatitle": {
      ...settingFormData[".inquirybox .capchabox .capchatitle"],
      "margin-bottom": `${settingFormData[
        ".inquirybox .capchabox .capchatitle"
      ]["margin-bottom"]?.replace("px", "")}px`,
    },
    ".inquirybox .contact_form .btn": {
      ...settingFormData[".inquirybox .contact_form .btn"],
      "background-color": settingFormData[".inquirybox .contact_form .btn"][
        "background-color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".inquirybox .contact_form .btn"][
                "background-color"
              ],
          })
        : "",
      color: settingFormData[".inquirybox .contact_form .btn"]["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".inquirybox .contact_form .btn"]["color"],
          })
        : "",
    },
  };
  return newData;
};
