import React from "react";
import { Col, Input, Label, Row } from "reactstrap";
import { fontWeight } from "../../constant";
import CommonColor from "./CommonColor";
import { convertRGBA } from "../../utils/helper";

const paginationButtonStyle = (props) => {
  const { settingFormData, setSettingFormData, mainClass } = props;
  const moreBtnSection = (e) => {
    const { name, value } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [`${mainClass} .more-btn`]: {
          ...prev[`${mainClass} .more-btn`],
          [name]: value,
        },
      };
    });
  };
  const moreBtnHoverSection = (e) => {
    const { name, value } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [`${mainClass} .more-btn:hover`]: {
          ...prev[`${mainClass} .more-btn:hover`],
          [name]: value,
        },
      };
    });
  };
  const paginationSectionHandler = (e) => {
    const { name, value } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".pagination .page-link"]: {
          ...prev[".pagination .page-link"],
          [name]: value,
        },
      };
    });
  };
  const paginationSectionActiveHandler = (e) => {
    const { name, value } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".pagination .page-item.active .page-link"]: {
          ...prev[".pagination .page-item.active .page-link"],
          [name]: value,
        },
      };
    });
  };
  const paginationSectionPositionHandler = (e) => {
    const { name, value } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".pagination-positions"]: {
          ...prev[".pagination-positions"],
          [name]: value,
        },
      };
    });
  };
  return (
    <>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">More button section</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={convertRGBA({
              rgbString: settingFormData?.[`${mainClass} .more-btn`]?.["color"],
            })}
            setSettingFormData={setSettingFormData}
            className={`${mainClass} .more-btn`}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 16)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[`${mainClass} .more-btn`]?.[
              "font-size"
            ]?.replace(/px/g, "")}
            onChange={(e) => moreBtnSection(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : normal)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={settingFormData?.[`${mainClass} .more-btn`]?.["font-weight"]}
            onChange={(e) => moreBtnSection(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]} key={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border Radius (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[`${mainClass} .more-btn`]?.[
              "border-radius"
            ]?.replace(/px/g, "")}
            onChange={(e) => moreBtnSection(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 5 - 20)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="p1"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[`${mainClass} .more-btn`]?.[
                "p1"
              ]?.replace(/px/g, "")}
              onChange={(e) => moreBtnSection(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p2"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[`${mainClass} .more-btn`]?.[
                "p2"
              ]?.replace(/px/g, "")}
              onChange={(e) => moreBtnSection(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={convertRGBA({
              rgbString:
                settingFormData?.[`${mainClass} .more-btn`]?.[
                  "background-color"
                ],
            })}
            setSettingFormData={setSettingFormData}
            className={`${mainClass} .more-btn`}
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">change More button section on hover</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={convertRGBA({
              rgbString:
                settingFormData?.[`${mainClass} .more-btn:hover`]?.["color"],
            })}
            setSettingFormData={setSettingFormData}
            className={`${mainClass} .more-btn:hover`}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={convertRGBA({
              rgbString:
                settingFormData?.[`${mainClass} .more-btn:hover`]?.[
                  "background-color"
                ],
            })}
            setSettingFormData={setSettingFormData}
            className={`${mainClass} .more-btn:hover`}
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Pagination Section</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={convertRGBA({
              rgbString: settingFormData?.[".pagination .page-link"]?.["color"],
            })}
            setSettingFormData={setSettingFormData}
            className={".pagination .page-link"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={convertRGBA({
              rgbString:
                settingFormData?.[".pagination .page-item.active .page-link"]?.[
                  "background-color"
                ],
            })}
            setSettingFormData={setSettingFormData}
            className={".pagination .page-item.active .page-link"}
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={convertRGBA({
              rgbString:
                settingFormData?.[".pagination .page-item.active .page-link"]?.[
                  "border-color"
                ],
            })}
            setSettingFormData={setSettingFormData}
            className={".pagination .page-item.active .page-link"}
            classProperty={"border-color"}
            label={"Select Border Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Pagination position (Default : center)"}
          </Label>
          <select
            name="justify-content"
            id="justify-content"
            className="form-select"
            value={
              settingFormData?.[".pagination-positions"]?.["justify-content"]
            }
            onChange={(e) => paginationSectionPositionHandler(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            <option value="start">{"Start"}</option>
            <option value="end">{"end"}</option>
            <option value="center">{"center"}</option>
          </select>
        </Col>
      </Row>
    </>
  );
};

export default paginationButtonStyle;
