import React from "react";
import { Button, Col, Row } from "reactstrap";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { IconButton } from "@material-ui/core";

const ThemeImageShow = (props) => {
  const { themeImg, handleClose } = props;
  return (
    <Row className="mt-2">
      <Col className="my-3 d-flex justify-content-center">
        <div
          className="position-relative border"
          border
          style={{ maxWidth: "50rem" }}
        >
          <img
            src={themeImg}
            class="img-fluid"
            alt="theme-image"
            style={{ width: "100%" }}
          ></img>
          <Button
            onClick={() => handleClose()}
            color="primary"
            className="mt-2"
            style={{
              position: "absolute",
              top: "-9px",
              left: "0px",
            }}
          >
            <ExpandLessIcon
              style={{
                fontSize: "25px",
                fontWeight: "bold",
              }}
              Hide
            />
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default ThemeImageShow;
