import {
  addUrlImage,
  convertRGBA,
  removeUrlImage,
  removeUrlKeyInPath,
  rgbColorCreate,
} from "../../../../utils/helper";

export const ItemFeatureThemeOneReset = {
  ".product-features-section": {
    padding: "100px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  image: "",
  ".product-features-section.bg-image": {},
  ".product-features-section.bg-image::before": {
    // "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
  },
  ".product-features-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".product-features-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: { r: 255, g: 73, b: 124, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 0.2 },
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".product-features-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: { r: 0, g: 0, b: 0, a: 100 },
  },
  ".product-features-section .feature-list": {
    padding: "20px",
    margin: "10px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
    "box-shadow": "0 0px 15px { r: 0, g: 0, b: 0, a: 0.094 }",
    bs1: "0",
    bs2: "0px",
    bs3: "15px",
    shadowColor: { r: 0, g: 0, b: 0, a: 0.094 },
    "border-radius": "10px",
  },
  ".product-features-section .feature-list h5": {
    "font-size": "20px",
    "font-weight": "600",
    color: { r: 255, g: 73, b: 124, a: 100 },
    "margin-bottom": "10px",
  },
  ".product-features-section .feature-list p": {
    "font-size": "16px",
    "font-weight": "normal",
    color: { r: 102, g: 102, b: 102, a: 100 },
    "line-height": "1.4",
    "text-align": "justify",
  },
  custom: "",
};
export const ItemFeatureThemeOneFunction = (payload) => {
  return {
    ".product-features-section":
      payload.style?.[".product-features-section"]?.["width"] ||
      payload.style?.[".product-features-section"]?.["margin"] ||
      payload.style?.[".product-features-section"]?.["border-radius"]
        ? {
            padding: payload.style?.[".product-features-section"]?.["padding"],
            "background-color": payload.style?.[".product-features-section"]?.[
              "background-color"
            ]
              ? convertRGBA({
                  rgbString:
                    payload.style?.[".product-features-section"]?.[
                      "background-color"
                    ],
                })
              : "",
            width: payload.style?.[".product-features-section"]?.[
              "width"
            ]?.replace("calc(100% - ", ""),
            margin: payload.style?.[".product-features-section"]?.["margin"],
            "border-radius":
              payload.style?.[".product-features-section"]?.["border-radius"],
          }
        : {
            padding: payload.style?.[".product-features-section"]?.["padding"],
            "background-color": payload.style?.[".product-features-section"]?.[
              "background-color"
            ]
              ? convertRGBA({
                  rgbString:
                    payload.style?.[".product-features-section"]?.[
                      "background-color"
                    ],
                })
              : "",
          },
    ".product-features-section.bg-image": payload.style?.[
      ".product-features-section.bg-image"
    ]?.["background-image"]
      ? {
          "background-image": payload.style?.[
            ".product-features-section.bg-image"
          ]?.["background-image"]
            ? removeUrlKeyInPath({
                urlString:
                  payload.style?.[".product-features-section.bg-image"]?.[
                    "background-image"
                  ],
              })
            : "",
        }
      : {},
    ".product-features-section.bg-image::before": payload.style?.[
      ".product-features-section.bg-image::before"
    ]?.["background-color"]
      ? {
          "background-color": convertRGBA({
            rgbString:
              payload.style?.[".product-features-section.bg-image::before"]?.[
                "background-color"
              ],
          }),
        }
      : {},
    ".product-features-section .section-title-area": {
      "margin-bottom":
        payload.style?.[".product-features-section .section-title-area"]?.[
          "margin-bottom"
        ],
    },
    ".product-features-section .section-title-area .section-subtitle": {
      "font-size":
        payload.style?.[
          ".product-features-section .section-title-area .section-subtitle"
        ]?.["font-size"],
      "font-weight":
        payload.style?.[
          ".product-features-section .section-title-area .section-subtitle"
        ]?.["font-weight"],
      color: payload.style?.[
        ".product-features-section .section-title-area .section-subtitle"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".product-features-section .section-title-area .section-subtitle"
              ]?.["color"],
          })
        : "",
      "background-color": payload.style?.[
        ".product-features-section .section-title-area .section-subtitle"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".product-features-section .section-title-area .section-subtitle"
              ]?.["background-color"],
          })
        : "",
      padding:
        payload.style?.[
          ".product-features-section .section-title-area .section-subtitle"
        ]?.["padding"],
      p1: payload.style?.[
        ".product-features-section .section-title-area .section-subtitle"
      ]?.["padding"]?.split(" ")[0],
      p2: payload.style?.[
        ".product-features-section .section-title-area .section-subtitle"
      ]?.["padding"]?.split(" ")[0],
      "border-radius":
        payload.style?.[
          ".product-features-section .section-title-area .section-subtitle"
        ]?.["border-radius"],
      "margin-bottom":
        payload.style?.[
          ".product-features-section .section-title-area .section-subtitle"
        ]?.["margin-bottom"],
    },
    ".product-features-section .section-title-area .section-title": {
      "font-size":
        payload.style?.[
          ".product-features-section .section-title-area .section-title"
        ]?.["font-size"],
      "font-weight":
        payload.style?.[
          ".product-features-section .section-title-area .section-title"
        ]?.["font-weight"],
      color: payload.style?.[
        ".product-features-section .section-title-area .section-title"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".product-features-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".product-features-section .feature-list": {
      padding:
        payload.style?.[".product-features-section .feature-list"]?.["padding"],
      margin:
        payload.style?.[".product-features-section .feature-list"]?.["margin"],
      "background-color": payload.style?.[
        ".product-features-section .feature-list"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".product-features-section .feature-list"]?.[
                "background-color"
              ],
          })
        : "",
      "box-shadow":
        payload.style?.[".product-features-section .feature-list"]?.[
          "box-shadow"
        ],
      bs1: payload.style?.[".product-features-section .feature-list"]?.[
        "box-shadow"
      ]?.split(" ")[0],
      bs2: payload.style?.[".product-features-section .feature-list"]?.[
        "box-shadow"
      ]?.split(" ")[1],
      bs3: payload.style?.[".product-features-section .feature-list"]?.[
        "box-shadow"
      ]?.split(" ")[2],
      shadowColor: payload.style?.[".product-features-section .feature-list"]?.[
        "shadowColor"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".product-features-section .feature-list"]?.[
                "shadowColor"
              ],
          })
        : "",
      "border-radius":
        payload.style?.[".product-features-section .feature-list"]?.[
          "border-radius"
        ],
    },
    ".product-features-section .feature-list h5": {
      "font-size":
        payload.style?.[".product-features-section .feature-list h5"]?.[
          "font-size"
        ],
      "font-weight":
        payload.style?.[".product-features-section .feature-list h5"]?.[
          "font-weight"
        ],
      color: payload.style?.[".product-features-section .feature-list h5"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".product-features-section .feature-list h5"]?.[
                "color"
              ],
          })
        : "",
      "margin-bottom":
        payload.style?.[".product-features-section .feature-list h5"]?.[
          "margin-bottom"
        ],
    },
    ".product-features-section .feature-list p": {
      "font-size":
        payload.style?.[".product-features-section .feature-list p"]?.[
          "font-size"
        ],
      "font-weight":
        payload.style?.[".product-features-section .feature-list p"]?.[
          "font-weight"
        ],
      color: payload.style?.[".product-features-section .feature-list p"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".product-features-section .feature-list p"]?.[
                "color"
              ],
          })
        : "",
      "line-height":
        payload.style?.[".product-features-section .feature-list p"]?.[
          "line-height"
        ],
      "text-align":
        payload.style?.[".product-features-section .feature-list p"]?.[
          "text-align"
        ],
    },
    custom: payload?.style?.custom,
  };
};
export const ItemFeatureStyleOneSubFun = ({ settingFormData, boxSection }) => {
  let newData = {
    ...settingFormData,

    ".product-features-section": boxSection
      ? {
          ...settingFormData[".product-features-section"],
          padding: `${settingFormData[".product-features-section"]["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
          width: `calc(100% - ${settingFormData[".product-features-section"][
            "width"
          ]?.replace("rem)", "")}rem)`,
          margin: `${settingFormData[".product-features-section"]["margin"]
            ?.split(" ")[0]
            ?.replace("px", "")}px auto`,
          "border-radius": `${settingFormData[".product-features-section"][
            "border-radius"
          ]?.replace("rem", "")}rem`,
        }
      : {
          ...settingFormData[".product-features-section"],
          padding: `${settingFormData[".product-features-section"]["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
        },

    ".product-features-section.bg-image": {
      "background-image": settingFormData[".product-features-section.bg-image"][
        "background-image"
      ]
        ? addUrlImage({
            urlString: removeUrlImage({
              urlString:
                settingFormData[".product-features-section.bg-image"][
                  "background-image"
                ],
            }),
          })
        : "",
    },
    ".product-features-section.bg-image::before": {
      "background-color": settingFormData[
        ".product-features-section.bg-image::before"
      ]["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".product-features-section.bg-image::before"][
                "background-color"
              ],
          })
        : "",
    },
    ".product-features-section .section-title-area": {
      "margin-bottom": `${settingFormData[
        ".product-features-section .section-title-area"
      ]["margin-bottom"]?.replace("px", "")}px`,
    },
    ".product-features-section .section-title-area .section-subtitle": {
      ...settingFormData[
        ".product-features-section .section-title-area .section-subtitle"
      ],
      "font-size": `${settingFormData[
        ".product-features-section .section-title-area .section-subtitle"
      ]["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".product-features-section .section-title-area .section-subtitle"]["font-weight"]}`,
      color: settingFormData[
        ".product-features-section .section-title-area .section-subtitle"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".product-features-section .section-title-area .section-subtitle"
              ]?.["color"],
          })
        : "",
      "background-color": settingFormData[
        ".product-features-section .section-title-area .section-subtitle"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".product-features-section .section-title-area .section-subtitle"
              ]?.["background-color"],
          })
        : "",
      padding: `${settingFormData[
        ".product-features-section .section-title-area .section-subtitle"
      ]["p1"]?.replace("px", "")}px ${settingFormData[
        ".product-features-section .section-title-area .section-subtitle"
      ]["p2"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData[
        ".product-features-section .section-title-area .section-subtitle"
      ]["border-radius"]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData[
        ".product-features-section .section-title-area .section-subtitle"
      ]["margin-bottom"]?.replace("px", "")}px`,
    },
    ".product-features-section .section-title-area .section-title": {
      ...settingFormData[
        ".product-features-section .section-title-area .section-title"
      ],
      "font-size": `${settingFormData[
        ".product-features-section .section-title-area .section-title"
      ]["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".product-features-section .section-title-area .section-title"]["font-weight"]}`,
      color: settingFormData[
        ".product-features-section .section-title-area .section-title"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".product-features-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".product-features-section .feature-list": {
      ...settingFormData[".product-features-section .feature-list"],
      padding: `${settingFormData[".product-features-section .feature-list"][
        "padding"
      ]?.replace("px", "")}px`,
      margin: `${settingFormData[".product-features-section .feature-list"][
        "margin"
      ]
        ?.split(" ")[0]
        ?.replace("px", "")}px 0`,
      "background-color": settingFormData[
        ".product-features-section .feature-list"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".product-features-section .feature-list"]?.[
                "background-color"
              ],
          })
        : "",
      "box-shadow": `${settingFormData[
        ".product-features-section .feature-list"
      ]["bs1"]?.replace("px", "")}px ${settingFormData[
        ".product-features-section .feature-list"
      ]["bs2"]?.replace("px", "")}px ${settingFormData[
        ".product-features-section .feature-list"
      ]["bs3"]?.replace("px", "")}px ${rgbColorCreate({
        rgbString:
          settingFormData[".product-features-section .feature-list"]?.[
            "shadowColor"
          ],
      })}`,
      "border-radius": `${settingFormData[
        ".product-features-section .feature-list"
      ]["border-radius"]?.replace("px", "")}px`,
    },
    ".product-features-section .feature-list h5": {
      ...settingFormData[".product-features-section .feature-list h5"],
      "font-size": `${settingFormData[
        ".product-features-section .feature-list h5"
      ]["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".product-features-section .feature-list h5"]["font-weight"]}`,
      color: settingFormData[".product-features-section .feature-list h5"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".product-features-section .feature-list h5"]?.[
                "color"
              ],
          })
        : "",
      "margin-bottom": `${settingFormData[
        ".product-features-section .feature-list h5"
      ]["margin-bottom"]?.replace("px", "")}px`,
    },
    ".product-features-section .feature-list p": {
      ...settingFormData[".product-features-section .feature-list p"],
      "font-size": `${settingFormData[
        ".product-features-section .feature-list p"
      ]["font-size"]?.replace("px", "")}px`,
      color: settingFormData[".product-features-section .feature-list p"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".product-features-section .feature-list p"]?.[
                "color"
              ],
          })
        : "",
      "font-weight": `${settingFormData[".product-features-section .feature-list p"]["font-weight"]}`,
      "line-height": `${settingFormData[".product-features-section .feature-list p"]["line-height"]}`,
      "text-align": `${settingFormData[".product-features-section .feature-list p"]["text-align"]}`,
    },
  };
  return newData;
};
