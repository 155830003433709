import axios from "axios";
import {
  GET_INQUIRY,
  GET_INQUIRY_DETAILS,
  POST_INQUIRY,
  PUT_INQUIRY_ORDER,
  PUT_STATUS_INQUIRY,
} from "..";

/**
 * get inquiry
 * @param {Parameters} paramObj
 * @returns
 */
export const getInquiry = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_INQUIRY}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * enable-disable inquiry
 * @param {body} body
 * @returns
 */
export const updateStatus = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_STATUS_INQUIRY}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * create inquiry
 * @param {body} body
 * @returns
 */
export const postInquiry = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(`${process.env.REACT_APP_API_URL}${POST_INQUIRY}`, body, {
    headers: { Authorization: jwt_token },
  });
};

/**
 * update inquiry
 * @param {body} body
 * @returns
 */
export const putUpdateInquiry = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(`${process.env.REACT_APP_API_URL}${POST_INQUIRY}`, body, {
    headers: { Authorization: jwt_token },
    params: { inquiryId: body.inquiryId },
  });
};

/**
 * delete inquiry
 * @param {id} inquiryId
 * @returns
 */
export const deleteInquiry = (inquiryId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${POST_INQUIRY}`, {
    headers: { Authorization: jwt_token },
    params: { inquiryId },
  });
};

/**
 * get inquiry
 * @param {id} inquiryId
 * @returns
 */
export const getInquiryDetails = (inquiryId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_INQUIRY_DETAILS}`, {
    headers: { Authorization: jwt_token },
    params: { inquiryId },
  });
};

/**
 * short order inquiry
 * @param {body} updateOrder
 * @returns
 */
export const sort_inquiry = (updateOrder) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_INQUIRY_ORDER}`,
    { updateOrder: updateOrder },
    {
      headers: { Authorization: jwt_token },
    }
  );
};
