import React, { useContext, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import { Cases, modalsName } from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import CommonPopup from "../../../Modals/commonPopup";
import DetailsPopup from "./DetailsPopup";
import DetailsContext from "../../../context/DetailsContext/DetailsContext";
import { Settings } from "react-feather";
import { IconButton } from "@material-ui/core";
import {
  getDetails,
  getDetailsDetails,
  postDetails,
  putUpdateDetails,
  updateStatus,
} from "../apis/DetailsApi/DetailsApi";
import { FILE_TYPE } from "../../../../constant";
import { FILE_UPLOAD_API } from "../apis/SliderApi/SliderApi";
import {
  Truncate,
  addUrlImage,
  convertRGBA,
  removeUrlImage,
  removeUrlKeyInPath,
  rgbColorCreate,
} from "../../../../utils/helper";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import SimpleReactValidator from "simple-react-validator";
import SettingDetailsPerentPopup from "./SettingDetailsPerentPopup";
import DeleteSection from "../../../common/DeleteSection";
import SettingMultiWebContext from "../../../../context/SettingMultiWebContext/SettingMultiWebContext";

const Details = (props) => {
  const {
    pageConfigDetails,
    detailSectionDetail,
    contentMapId,
    ByteMiniWeb_Dashboard,
    contentDetail,
  } = props;
  const { setAllPopupState, allPopupState } = useContext(
    ByteCardBuilderContext
  );
  const { setCloneAlignModal, setAlignFormData, setCloneFormData } = useContext(
    SettingMultiWebContext
  );
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const {
    apiData: details,
    setApiData,
    fromData,
    setFormData,
    id,
    setId,
    setIsEdit,
    isEdit,
    filter,
    settingFormData,
    setSettingFormData,
    setBoxSection,
    setBackImageToggle,
    boxSection,
    collId,
    setCollId,
    isBackgroundColor,
    setIsBackgroundColor,
  } = useContext(DetailsContext);
  // const [open, setOpen] = useState(false);
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * get details section list
   */
  const getDetailsList = async ({ status, contentMapId }) => {
    try {
      if (status !== false && !filter.length) {
        setApiData((prev) => {
          return { ...prev, isLoading: true };
        });
      }
      let paramObj = {};
      paramObj["search"] = { contentMapId };
      if (filter?.length) {
        paramObj = {
          search: { title: filter, contentMapId },
        };
      }

      const { data } = await getDetails(paramObj);
      if (data.status === 200) {
        setApiData({
          ...details,
          isLoading: false,
          data: data.payload.data.map((elem) => {
            return {
              id: elem.title + elem.detailsSectionId,
              Id: elem.detailsSectionId,
              name: elem.title,
              image: elem.image,
              webId: elem.webId,
              des: elem.description,
              buttonCaption: elem.buttonCaption,
              buttonLink: elem.buttonLink,
              createdAt: elem.createdAt,
              isActive: elem.isActive,
              displaySetting: elem.displaySetting,
              shortDescription: elem.shortDescription,
            };
          }),
        });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * enable disable api call
   * @param {detailsSection id} detailsSectionId
   * @param {status} isActive
   */
  const handleChangeStatus = async (detailsSectionId, isActive) => {
    try {
      let body = {
        detailsSectionId,
        isActive: isActive ? false : true,
      };
      const { data } = await updateStatus(body);
      if (data.status === 200) {
        getDetailsList({ contentMapId: contentMapId });
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * upload image
   * @param {file} file
   */
  const imageUpload = async (file) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.DETAIL_SECTION);
    formData.append("file", file);
    setLoaderShow(true);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        setFormData((prev) => {
          return {
            ...prev,
            image: data.payload.baseUrl + data.payload.imageUrl,
          };
        });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };
  const imageUploadSetting = async (file) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.DETAIL_SECTION);
    formData.append("file", file);
    setLoaderShow(true);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        setSettingFormData((prev) => {
          return {
            ...prev,
            ".only-text-section.bg-image": {
              "background-image": data.payload.baseUrl + data.payload.imageUrl,
            },
          };
        });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * create and update details section
   */
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      if (id >= -1 && isEdit) {
        setLoaderShow(true);
        let newData = {};
        if (fromData?.image?.length) {
          setFormData((prev) => {
            return {
              ...prev,
              image: prev.image
                .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
                .pop(),
              contentMapId: collId,
            };
          });
          newData = {
            ...fromData,
            contentMapId: collId,
            image: fromData.image
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
              .pop(),
          };
        } else {
          newData = { ...fromData, contentMapId: collId };
        }
        try {
          const { data } = await putUpdateDetails(newData || fromData);
          if (data.status === 200) {
            getDetailsList({ contentMapId: collId });
            emptyData();
            ByteMiniWeb_Dashboard();
            TostMessage({ message: data, type: "success" });
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      } else {
        try {
          setLoaderShow(true);
          let newData = {};
          if (fromData?.image?.length) {
            setFormData((prev) => {
              return {
                ...prev,
                image: prev.image
                  .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
                  .pop(),
                contentMapId: collId,
              };
            });
            newData = {
              ...fromData,
              image: fromData.image
                .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
                .pop(),
              order: details.data.length + 1,
              contentMapId: collId,
            };
          } else {
            newData = {
              ...fromData,
              order: details.data.length + 1,
              contentMapId: collId,
            };
          }
          const { data } = await postDetails(newData);
          if (data.status === 200) {
            getDetailsList({ contentMapId: collId });
            emptyData();
            ByteMiniWeb_Dashboard();
            TostMessage({ message: data, type: "success" });
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      }
    }
  };

  /**
   * create and update details
   */
  const handleSubmitSetting = async (isRestore = false, isClose = true) => {
    if (id >= -1 && isEdit) {
      setLoaderShow(true);
      try {
        let newData;
        if (isRestore) {
          const isConfirm = window.confirm(
            "Are you sure want to restore style ?"
          );
          if (isConfirm) {
            newData = {
              styleRestore: true,
              detailsSectionId: id,
            };
          } else {
            setLoaderShow(false);
            return;
          }
        } else {
          newData = {
            ...settingFormData,
            ".only-text-section": boxSection
              ? {
                  ...settingFormData[".only-text-section"],
                  padding: `${settingFormData[".only-text-section"]["padding"]
                    ?.split(" ")[0]
                    ?.replace("px", "")}px`,
                  width: `calc(100% - ${settingFormData[".only-text-section"][
                    "width"
                  ]?.replace("rem)", "")}rem)`,
                  margin: `${settingFormData[".only-text-section"]["margin"]
                    ?.split(" ")[0]
                    ?.replace("px", "")}px auto`,
                  "border-radius": `${settingFormData[".only-text-section"][
                    "border-radius"
                  ]?.replace("rem", "")}rem`,
                }
              : {
                  ...settingFormData[".only-text-section"],
                  padding: `${settingFormData[".only-text-section"]["padding"]
                    ?.split(" ")[0]
                    ?.replace("px", "")}px`,
                },
            ".only-text-section.bg-image": settingFormData[
              ".only-text-section.bg-image"
            ]["background-image"]
              ? {
                  "background-image": settingFormData[
                    ".only-text-section.bg-image"
                  ]["background-image"]
                    ? addUrlImage({
                        urlString: removeUrlImage({
                          urlString:
                            settingFormData?.[".only-text-section.bg-image"]?.[
                              "background-image"
                            ],
                        }),
                      })
                    : "",
                }
              : {},
            ".only-text-section.bg-image::before": settingFormData[
              ".only-text-section.bg-image::before"
            ]["background-color"]
              ? {
                  "background-color": settingFormData[
                    ".only-text-section.bg-image::before"
                  ]["background-color"]
                    ? rgbColorCreate({
                        rgbString:
                          settingFormData[
                            ".only-text-section.bg-image::before"
                          ]["background-color"],
                      })
                    : "",
                }
              : {},
            ".only-text-section .section-title-area": {
              ...settingFormData[".only-text-section .section-title-area"],
              "margin-bottom": `${settingFormData[
                ".only-text-section .section-title-area"
              ]["margin-bottom"]?.replace("px", "")}px`,
            },
            ".only-text-section .section-title-area .section-subtitle": {
              ...settingFormData[
                ".only-text-section .section-title-area .section-subtitle"
              ],
              "font-size": `${settingFormData[
                ".only-text-section .section-title-area .section-subtitle"
              ]["font-size"]?.replace("px", "")}px`,
              "font-weight": `${settingFormData[".only-text-section .section-title-area .section-subtitle"]["font-weight"]}`,
              color: settingFormData[
                ".only-text-section .section-title-area .section-subtitle"
              ]["color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[
                        ".only-text-section .section-title-area .section-subtitle"
                      ]["color"],
                  })
                : "",
              "background-color": settingFormData[
                ".only-text-section .section-title-area .section-subtitle"
              ]["background-color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[
                        ".only-text-section .section-title-area .section-subtitle"
                      ]["background-color"],
                  })
                : "",
              padding: `${settingFormData[
                ".only-text-section .section-title-area .section-subtitle"
              ]["p1"]?.replace("px", "")}px ${settingFormData[
                ".only-text-section .section-title-area .section-subtitle"
              ]["p2"]?.replace("px", "")}px`,
              "border-radius": `${settingFormData[
                ".only-text-section .section-title-area .section-subtitle"
              ]["border-radius"]?.replace("px", "")}px`,
              "margin-bottom": `${settingFormData[
                ".only-text-section .section-title-area .section-subtitle"
              ]["border-radius"]?.replace("px", "")}px`,
            },
            ".only-text-section .section-title-area .section-title": {
              ...settingFormData[
                ".only-text-section .section-title-area .section-title"
              ],
              "font-size": `${settingFormData[
                ".only-text-section .section-title-area .section-title"
              ]["font-size"]?.replace("px", "")}px`,
              "font-weight": `${settingFormData[".only-text-section .section-title-area .section-title"]["font-weight"]}`,
              color: settingFormData[
                ".only-text-section .section-title-area .section-title"
              ]["color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[
                        ".only-text-section .section-title-area .section-title"
                      ]["color"],
                  })
                : "",
            },
            ".only-text-section .only-text-content p": {
              ...settingFormData[".only-text-section .only-text-content p"],
              "margin-bottom": `${settingFormData[
                ".only-text-section .only-text-content p"
              ]["margin-bottom"]?.replace("px", "")}px`,
              "line-height": `${settingFormData[".only-text-section .only-text-content p"]["line-height"]}`,
              "font-size": `${settingFormData[
                ".only-text-section .only-text-content p"
              ]["font-size"]?.replace("px", "")}px`,
            },
            ".only-text-section .only-text-content.info-list": {
              ...settingFormData[
                ".only-text-section .only-text-content.info-list"
              ],
              "margin-bottom": `${settingFormData[
                ".only-text-section .only-text-content.info-list"
              ]["margin-bottom"]?.replace("px", "")}px`,
            },
            ".only-text-section .only-text-content .info-list li": {
              ...settingFormData[
                ".only-text-section .only-text-content .info-list li"
              ],
              "margin-bottom": `${settingFormData[
                ".only-text-section .only-text-content .info-list li"
              ]["margin-bottom"]?.replace("px", "")}px`,
              "font-size": `${settingFormData[
                ".only-text-section .only-text-content .info-list li"
              ]["font-size"]?.replace("px", "")}px`,
            },
            ".only-text-section .only-text-content .order-list": {
              ...settingFormData[
                ".only-text-section .only-text-content .order-list"
              ],
              "margin-bottom": `${settingFormData[
                ".only-text-section .only-text-content .order-list"
              ]["margin-bottom"]?.replace("px", "")}px`,
            },
            ".only-text-section .only-text-content .order-list li": {
              ...settingFormData[
                ".only-text-section .only-text-content .order-list li"
              ],
              "padding-left": `${settingFormData[
                ".only-text-section .only-text-content .order-list li"
              ]["padding-left"]?.replace("px", "")}px`,
              "margin-bottom": `${settingFormData[
                ".only-text-section .only-text-content .order-list li"
              ]["margin-bottom"]?.replace("px", "")}px`,
              "font-size": `${settingFormData[
                ".only-text-section .only-text-content .order-list li"
              ]["font-size"]?.replace("px", "")}px`,
            },
            ".only-text-section .only-text-content .order-list li:before": {
              ...settingFormData[
                ".only-text-section .only-text-content .order-list li:before"
              ],
              "font-family": `${settingFormData[".only-text-section .only-text-content .order-list li:before"]["font-family"]}`,
              top: `${settingFormData[
                ".only-text-section .only-text-content .order-list li:before"
              ]["font-size"]?.replace("px", "")}px`,
              color: settingFormData[
                ".only-text-section .only-text-content .order-list li:before"
              ]["color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[
                        ".only-text-section .only-text-content .order-list li:before"
                      ]["color"],
                  })
                : "",
            },
          };
          if (isBackgroundColor) {
            newData[".only-text-section"] = {
              ...newData?.[".only-text-section"],
              "background-color": settingFormData[".only-text-section"][
                "background-color"
              ]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[".only-text-section"]["background-color"],
                  })
                : "",
            };
          }
          if (boxSection) {
            newData[".only-text-section.bg-image::before"] = {
              ...newData[".only-text-section.bg-image::before"],
              "border-radius": `${settingFormData[".only-text-section"][
                "border-radius"
              ]?.replace("rem", "")}rem`,
            };
          }
          const keysToRemove = ["p1", "p2", "image"];
          function removeKeys(obj, keys) {
            for (const key in obj) {
              if (typeof obj[key] === "object") {
                removeKeys(obj[key], keys);
              } else if (keys.includes(key)) {
                delete obj[key];
              }
            }
          }
          removeKeys(newData, keysToRemove);
          newData = {
            style: newData,
            detailsSectionId: id,
          };
        }

        const { data } = await putUpdateDetails(newData);
        if (data.status === 200) {
          if (isClose) {
            OpenModalCommonFunc(
              Cases.SETTING_DETAILS_PARENT,
              setAllPopupState,
              false
            );
            setBackImageToggle(false);
            emptyData();
          }
          setLoaderShow(false);
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  /**
   * get setting data
   * @param {id} detailsSectionId
   */
  const handleChangeSettings = async (detailsSectionId) => {
    try {
      setLoaderShow(true);
      setId(detailsSectionId);
      setSettingFormData((prev) => {
        return {
          ...prev,
          detailsSectionId,
        };
      });
      const { data } = await getDetailsDetails(detailsSectionId);
      if (data.status === 200) {
        let payload = data.payload.data;
        if (
          payload.style?.[".only-text-section"]?.["width"] ||
          payload.style?.[".only-text-section"]?.["margin"] ||
          payload.style?.[".only-text-section"]?.["border-radius"]
        ) {
          setBoxSection(true);
        }
        if (
          payload.style?.[".only-text-section.bg-image"]?.["background-image"]
        ) {
          setBackImageToggle(true);
        }
        if (payload.style?.[".only-text-section"]?.["background-color"]) {
          setIsBackgroundColor(true);
        }
        setSettingFormData((prev) => {
          return {
            ...prev,
            ".only-text-section": {
              padding: payload.style?.[".only-text-section"]?.["padding"],
              "background-color": payload.style?.[".only-text-section"]?.[
                "background-color"
              ]
                ? convertRGBA({
                    rgbString:
                      payload.style?.[".only-text-section"]?.[
                        "background-color"
                      ],
                  })
                : "",
              width: payload.style?.[".only-text-section"]?.["width"]?.replace(
                "calc(100% - ",
                ""
              ),
              margin: payload.style?.[".only-text-section"]?.["margin"],
              "border-radius":
                payload.style?.[".only-text-section"]?.["border-radius"],
            },
            ".only-text-section.bg-image": {
              "background-image": payload.style?.[
                ".only-text-section.bg-image"
              ]?.["background-image"]
                ? removeUrlKeyInPath({
                    urlString:
                      payload.style?.[".only-text-section.bg-image"]?.[
                        "background-image"
                      ],
                  })
                : "",
            },
            ".only-text-section.bg-image::before": {
              "background-color": payload.style?.[
                ".only-text-section.bg-image::before"
              ]?.["background-color"]
                ? convertRGBA({
                    rgbString:
                      payload.style?.[".only-text-section.bg-image::before"]?.[
                        "background-color"
                      ],
                  })
                : "",
            },
            ".only-text-section .section-title-area": {
              "margin-bottom":
                payload.style?.[".only-text-section .section-title-area"]?.[
                  "margin-bottom"
                ],
            },
            ".only-text-section .section-title-area .section-subtitle": {
              "font-size":
                payload.style?.[
                  ".only-text-section .section-title-area .section-subtitle"
                ]?.["font-size"],
              "font-weight":
                payload.style?.[
                  ".only-text-section .section-title-area .section-subtitle"
                ]?.["font-weight"],
              color: payload.style?.[
                ".only-text-section .section-title-area .section-subtitle"
              ]?.["color"]
                ? convertRGBA({
                    rgbString:
                      payload.style?.[
                        ".only-text-section .section-title-area .section-subtitle"
                      ]?.["color"],
                  })
                : "",
              "background-color": payload.style?.[
                ".only-text-section .section-title-area .section-subtitle"
              ]?.["background-color"]
                ? convertRGBA({
                    rgbString:
                      payload.style?.[
                        ".only-text-section .section-title-area .section-subtitle"
                      ]?.["background-color"],
                  })
                : "",
              padding:
                payload.style?.[
                  ".only-text-section .section-title-area .section-subtitle"
                ]?.["padding"],
              p1: payload.style?.[
                ".only-text-section .section-title-area .section-subtitle"
              ]?.["padding"]?.split(" ")[0],
              p2: payload.style?.[
                ".only-text-section .section-title-area .section-subtitle"
              ]?.["padding"]?.split(" ")[1],
              "border-radius":
                payload.style?.[
                  ".only-text-section .section-title-area .section-subtitle"
                ]?.["border-radius"],
              "margin-bottom":
                payload.style?.[
                  ".only-text-section .section-title-area .section-subtitle"
                ]?.["margin-bottom"],
            },
            ".only-text-section .section-title-area .section-title": {
              "font-size":
                payload.style?.[
                  ".only-text-section .section-title-area .section-title"
                ]?.["font-size"],
              "font-weight":
                payload.style?.[
                  ".only-text-section .section-title-area .section-title"
                ]?.["font-weight"],
              color: payload.style?.[
                ".only-text-section .section-title-area .section-title"
              ]?.["color"]
                ? convertRGBA({
                    rgbString:
                      payload.style?.[
                        ".only-text-section .section-title-area .section-title"
                      ]?.["color"],
                  })
                : "",
            },
            ".only-text-section .only-text-content p": {
              "margin-bottom":
                payload.style?.[".only-text-section .only-text-content p"]?.[
                  "margin-bottom"
                ],
              "line-height":
                payload.style?.[".only-text-section .only-text-content p"]?.[
                  "line-height"
                ],
              "font-size":
                payload.style?.[".only-text-section .only-text-content p"]?.[
                  "font-size"
                ],
            },
            ".only-text-section .only-text-content.info-list": {
              "margin-bottom":
                payload.style?.[
                  ".only-text-section .only-text-content.info-list"
                ]?.["margin-bottom"],
            },
            ".only-text-section .only-text-content .info-list li": {
              "margin-bottom":
                payload.style?.[
                  ".only-text-section .only-text-content .info-list li"
                ]?.["margin-bottom"],
              "font-size":
                payload.style?.[
                  ".only-text-section .only-text-content .info-list li"
                ]?.["font-size"],
            },
            ".only-text-section .only-text-content .order-list": {
              "margin-bottom":
                payload.style?.[
                  ".only-text-section .only-text-content .order-list"
                ]?.["margin-bottom"],
            },
            ".only-text-section .only-text-content .order-list li": {
              "padding-left":
                payload.style?.[
                  ".only-text-section .only-text-content .order-list li"
                ]?.["padding-left"],
              "margin-bottom":
                payload.style?.[
                  ".only-text-section .only-text-content .order-list li"
                ]?.["margin-bottom"],
              "font-size":
                payload.style?.[
                  ".only-text-section .only-text-content .order-list li"
                ]?.["font-size"],
            },
            ".only-text-section .only-text-content .order-list li:before": {
              "font-family":
                payload.style?.[
                  ".only-text-section .only-text-content .order-list li:before"
                ]?.["font-family"],
              top: payload.style?.[
                ".only-text-section .only-text-content .order-list li:before"
              ]?.["top"],
              color: payload.style?.[
                ".only-text-section .only-text-content .order-list li:before"
              ]?.["color"]
                ? convertRGBA({
                    rgbString:
                      payload.style?.[
                        ".only-text-section .only-text-content .order-list li:before"
                      ]?.["color"],
                  })
                : "",
            },
            custom: payload?.style?.custom,
          };
        });
        OpenModalCommonFunc(Cases.SETTING_DETAILS_PARENT, setAllPopupState);
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * get details for details section
   * @param {id} detailsSectionId
   */
  const handleEdit = async (detailsSectionId) => {
    setLoaderShow(true);
    try {
      setId(detailsSectionId);
      const { data } = await getDetailsDetails(detailsSectionId);
      if (data.status === 200) {
        let payload = data.payload.data;
        setFormData({
          title: payload?.title,
          headLine: payload?.headLine,
          shortDescription: payload?.shortDescription,
          description: payload?.description,
          isTitleShow: payload?.isTitleShow ? payload?.isTitleShow : false,
          buttonCaption: payload?.buttonCaption,
          image: payload?.image,
          displaySetting: payload?.displaySetting,
          detailsSectionId: detailsSectionId,
          webId: localStorage.getItem("webId"),
        });
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * clear data
   */
  const emptyData = () => {
    setIsBackgroundColor(false);
    setBoxSection(false);
    setId("");
    setIsEdit(false);
    setFormData({
      title: "",
      headLine: "",
      shortDescription: "",
      description: "",
      buttonCaption: "",
      image: "",
      displaySetting: "",
      isTitleShow: true,
      webId: localStorage.getItem("webId"),
      style: {
        ".only-text-section": {
          padding: "100px 0",
          "background-color": "rgb(255 255 255 / 100)",
        },
        image: "",
        ".only-text-section.bg-image": {
          "background-image": "",
        },
        ".only-text-section.bg-image::before": {
          "background-color": "",
        },
        ".only-text-section .section-title-area": {
          "margin-bottom": "50px",
        },
        ".only-text-section .section-title-area .section-subtitle": {
          "font-size": "16px",
          "font-weight": "600",
          color: "rgb(255 73 124 / 100)",
          "background-color": "rgb(255 73 124 / 0.2)",
          padding: "5px 20px",
          p1: "5px",
          p2: "20px",
          "border-radius": "15px",
          "margin-bottom": "15px",
        },
        ".only-text-section .section-title-area .section-title": {
          "font-size": "44px",
          "font-weight": "600",
          color: "rgb(0 0 0 / 100)",
        },
        ".only-text-section .only-text-content p": {
          "margin-bottom": "20px",
          "line-height": "1.6",
          "font-size": "18px",
        },
        ".only-text-section .only-text-content.info-list": {
          "margin-bottom": "30px",
        },
        ".only-text-section .only-text-content .info-list li": {
          "margin-bottom": "15px",
          "font-size": "18px",
        },
        ".only-text-section .only-text-content .order-list": {
          "margin-bottom": "30px",
        },
        ".only-text-section .only-text-content .order-list li": {
          "padding-left": "25px",
          "margin-bottom": "15px",
          "font-size": "18px",
        },
        ".only-text-section .only-text-content .order-list li:before": {
          "font-family": "FontAwesome",
          top: "3px",
          color: "rgb(255 73 124 / 100)",
        },
      },
    });
    setSettingFormData({
      ".only-text-section": {
        padding: "100px 0",
        "background-color": "rgb(255 255 255 / 100)",
      },
      image: "",
      ".only-text-section.bg-image": {
        "background-image": "",
      },
      ".only-text-section.bg-image::before": {
        "background-color": "",
      },
      ".only-text-section .section-title-area": {
        "margin-bottom": "50px",
      },
      ".only-text-section .section-title-area .section-subtitle": {
        "font-size": "16px",
        "font-weight": "600",
        color: "rgb(255 73 124 / 100)",
        "background-color": "rgb(255 73 124 / 0.2)",
        padding: "5px 20px",
        p1: "5px",
        p2: "20px",
        "border-radius": "15px",
        "margin-bottom": "15px",
      },
      ".only-text-section .section-title-area .section-title": {
        "font-size": "44px",
        "font-weight": "600",
        color: "rgb(0 0 0 / 100)",
      },
      ".only-text-section .only-text-content p": {
        "margin-bottom": "20px",
        "line-height": "1.6",
        "font-size": "18px",
      },
      ".only-text-section .only-text-content.info-list": {
        "margin-bottom": "30px",
      },
      ".only-text-section .only-text-content .info-list li": {
        "margin-bottom": "15px",
        "font-size": "18px",
      },
      ".only-text-section .only-text-content .order-list": {
        "margin-bottom": "30px",
      },
      ".only-text-section .only-text-content .order-list li": {
        "padding-left": "25px",
        "margin-bottom": "15px",
        "font-size": "18px",
      },
      ".only-text-section .only-text-content .order-list li:before": {
        "font-family": "FontAwesome",
        top: "3px",
        color: "rgb(255 73 124 / 100)",
      },
    });
    setLoaderShow(false);
  };

  const handleAlignAndCloneSection = ({ isClone = false }) => {
    if (isClone) {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          cloneModal: true,
        };
      });
      setCloneFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          pageConfigDetailsLabel: pageConfigDetails[0]?.label,
          contentDetailName: contentDetail.name,
        };
      });
    } else {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          alignModal: true,
        };
      });
      setAlignFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          label: pageConfigDetails[0]?.label,
        };
      });
    }
  };
  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody
            className="support-ticket-font box-border-div"
            style={{ padding: "20px" }}
          >
            <Row>
              <CardBuilderHeaderSection
                addBtnTitle={pageConfigDetails[0]?.label}
                name={pageConfigDetails[0]?.label}
                mainEditSection={true}
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
                isCloneSection={true}
                isAlignSection={true}
                handleAlignAndCloneSection={handleAlignAndCloneSection}
              />
              {detailSectionDetail && (
                <Col xl="12 xl-50 box-col-6" md="6">
                  <Card className="ecommerce-widget">
                    <CardBody className="support-ticket-font">
                      <div className="col-12">
                        <CardBuilderHeaderSection
                          name={
                            detailSectionDetail?.title &&
                            Truncate(detailSectionDetail?.title, 14)
                          }
                        />
                        <div className="pt-4">
                          <p style={{ fontSize: "17px", fontWeight: "500" }}>
                            {detailSectionDetail.headLine &&
                              Truncate(detailSectionDetail.headLine, 20)}
                          </p>
                          <p
                            className="pt-1"
                            style={{
                              fontWeight: "400",
                              color: "#666666",
                              fontSize: "15px",
                            }}
                          >
                            {detailSectionDetail?.description &&
                              Truncate(detailSectionDetail?.description, 30)}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end ">
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleChangeSettings(
                              detailSectionDetail.detailsSectionId
                            );
                          }}
                        >
                          <Settings />
                        </IconButton>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
              <OpenPopupBtn
                openPopup={() => {
                  getDetailsList({ contentMapId });
                  setCollId(contentMapId);
                  OpenModalCommonFunc(Cases.DETAILS, setAllPopupState);
                }}
                name={`Add or View ${pageConfigDetails[0]?.label ?? ""}`}
              />
              <DeleteSection
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
              />
            </Row>
          </CardBody>
        </Card>
      </Col>
      <CommonPopup
        open={allPopupState.detailsPopup}
        close={() =>
          OpenModalCommonFunc(Cases.DETAILS, setAllPopupState, false)
        }
        modalName={modalsName.DETAILS}
        modalCase={Cases.DETAILS}
        handleSubmit={handleSubmit}
        emptyData={emptyData}
        inputData={fromData}
        content={
          <DetailsPopup
            getData={getDetailsList}
            contentMapId={contentMapId}
            handleEdit={handleEdit}
            imageUpload={imageUpload}
            emptyData={emptyData}
            handleChangeStatus={handleChangeStatus}
            handleSubmit={handleSubmit}
            simpleValidator={simpleValidator}
            detailSectionDetail={detailSectionDetail}
          />
        }
      />
      {/* setting details parent popup */}
      <CommonPopup
        open={allPopupState.detailsSettingPopup}
        close={() =>
          OpenModalCommonFunc(
            Cases.SETTING_DETAILS_PARENT,
            setAllPopupState,
            false
          )
        }
        modalName={modalsName.SETTING_DETAILS_PARENT}
        modalCase={Cases.SETTING_DETAILS_PARENT}
        handleSubmit={handleSubmitSetting}
        emptyData={emptyData}
        // inputData={formDataChild}
        edit={false}
        content={
          <SettingDetailsPerentPopup
            imageUpload={imageUploadSetting}
            handleSubmitSetting={handleSubmitSetting}
          />
        }
      />
    </>
  );
};

export default Details;
