import { convertRGBA, rgbColorCreate } from "../../../../utils/helper";

export const PopupStyleOneReset = {
  ".popup .modal-content": {
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".popup .modal-header": {
    "justify-content": "space-between",
  },
  ".popup .modal-title": {
    color: { r: 0, g: 0, b: 0, a: 100 },
    "font-size": "20px",
  },
  ".popup .modal-body": {
    padding: "1rem",
  },
  ".popup .modal-btn": {
    "font-size": "16px",
    "font-weight": "600",
    color: { r: 255, g: 255, b: 255, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".popup .modal-shortDescription": {
    "font-size": "16px",
    "font-weight": "600",
    color: { r: 0, g: 0, b: 0, a: 100 },
    "padding-top": "12px",
  },
  ".popup .modal-description": {
    "font-size": "16px",
    "font-weight": "600",
    color: { r: 0, g: 0, b: 0, a: 100 },
    "padding-top": "12px",
  },
  custom: "",
};

export const PopupDialogThemeOneFunction = (payload) => {
  return {
    ".popup .modal-content": {
      "background-color": payload.style?.[".popup .modal-content"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".popup .modal-content"]?.["background-color"],
          })
        : "",
    },
    ".popup .modal-header": {
      "justify-content":
        payload.style?.[".popup .modal-header"]?.["justify-content"],
    },
    ".popup .modal-title": {
      color: payload.style?.[".popup .modal-title"]?.["color"]
        ? convertRGBA({
            rgbString: payload.style?.[".popup .modal-title"]?.["color"],
          })
        : "",
      "font-size": payload.style?.[".popup .modal-title"]?.["font-size"],
    },
    ".popup .modal-body": {
      padding: payload.style?.[".popup .modal-body"]?.["padding"],
    },
    ".popup .modal-btn": {
      "font-size": payload.style?.[".popup .modal-btn"]?.["font-size"],
      "font-weight": payload.style?.[".popup .modal-btn"]?.["font-weight"],
      color: payload.style?.[".popup .modal-btn"]?.["color"]
        ? convertRGBA({
            rgbString: payload.style?.[".popup .modal-btn"]?.["color"],
          })
        : "",
      "background-color": payload.style?.[".popup .modal-btn"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".popup .modal-btn"]?.["background-color"],
          })
        : "",
      padding: payload.style?.[".popup .modal-btn"]?.["padding"],
      p1: payload.style?.[".popup .modal-btn"]?.["padding"]?.split(" ")[0],
      p2: payload.style?.[".popup .modal-btn"]?.["padding"]?.split(" ")[0],
      "border-radius": payload.style?.[".popup .modal-btn"]?.["border-radius"],
      "margin-bottom": payload.style?.[".popup .modal-btn"]?.["margin-bottom"],
    },
    ".popup .modal-shortDescription": {
      "font-size":
        payload.style?.[".popup .modal-shortDescription"]?.["font-size"],
      "font-weight":
        payload.style?.[".popup .modal-shortDescription"]?.["font-weight"],
      color: payload.style?.[".popup .modal-shortDescription"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".popup .modal-shortDescription"]?.["color"],
          })
        : "",
      "padding-top":
        payload.style?.[".popup .modal-shortDescription"]?.["padding-top"],
    },
    ".popup .modal-description": {
      "font-size": payload.style?.[".popup .modal-description"]?.["font-size"],
      "font-weight":
        payload.style?.[".popup .modal-description"]?.["font-weight"],
      color: payload.style?.[".popup .modal-description"]?.["color"]
        ? convertRGBA({
            rgbString: payload.style?.[".popup .modal-description"]?.["color"],
          })
        : "",
      "padding-top":
        payload.style?.[".popup .modal-description"]?.["padding-top"],
    },
    custom: payload?.style?.custom,
  };
};

export const ItemFeatureStyleOneSubFun = ({ settingFormData, boxSection }) => {
  let newData = {
    ...settingFormData,
    ".popup .modal-content": {
      "background-color": settingFormData[".popup .modal-content"]?.[
        "background-color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".popup .modal-content"]?.["background-color"],
          })
        : "",
    },
    ".popup .modal-header": {
      "justify-content": `${settingFormData[".popup .modal-header"]["justify-content"]}`,
    },
    ".popup .modal-title": {
      ...settingFormData[".popup .modal-title"],
      color: settingFormData[".popup .modal-title"]?.["color"]
        ? rgbColorCreate({
            rgbString: settingFormData[".popup .modal-title"]?.["color"],
          })
        : "",
      "font-size": `${settingFormData[".popup .modal-title"][
        "font-size"
      ]?.replace("px", "")}px`,
    },
    ".popup .modal-body": {
      padding: `${settingFormData[".popup .modal-body"]["padding"]?.replace(
        "rem",
        ""
      )}rem`,
    },
    ".popup .modal-btn": {
      ...settingFormData[".popup .modal-btn"],
      "font-size": `${settingFormData[".popup .modal-btn"][
        "font-size"
      ]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".popup .modal-btn"]["font-weight"]}`,
      color: settingFormData[".popup .modal-btn"]?.["color"]
        ? rgbColorCreate({
            rgbString: settingFormData[".popup .modal-btn"]?.["color"],
          })
        : "",
      "background-color": settingFormData[".popup .modal-btn"]?.[
        "background-color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".popup .modal-btn"]?.["background-color"],
          })
        : "",
      padding: `${settingFormData[".popup .modal-btn"]["p1"]?.replace(
        "px",
        ""
      )}px ${settingFormData[".popup .modal-btn"]["p2"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData[".popup .modal-btn"][
        "border-radius"
      ]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData[".popup .modal-btn"][
        "margin-bottom"
      ]?.replace("px", "")}px`,
    },
    ".popup .modal-shortDescription": {
      ...settingFormData[".popup .modal-shortDescription"],
      "font-size": `${settingFormData[".popup .modal-shortDescription"][
        "font-size"
      ]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".popup .modal-shortDescription"]["font-weight"]}`,
      color: settingFormData[".popup .modal-shortDescription"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".popup .modal-shortDescription"]?.["color"],
          })
        : "",
      "padding-top": `${settingFormData[".popup .modal-shortDescription"][
        "padding-top"
      ]?.replace("px", "")}px`,
    },
    ".popup .modal-description": {
      ...settingFormData[".popup .modal-description"],
      "font-size": `${settingFormData[".popup .modal-description"][
        "font-size"
      ]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".popup .modal-description"]["font-weight"]}`,
      color: settingFormData[".popup .modal-description"]?.["color"]
        ? rgbColorCreate({
            rgbString: settingFormData[".popup .modal-description"]?.["color"],
          })
        : "",
      "padding-top": `${settingFormData[".popup .modal-description"][
        "padding-top"
      ]?.replace("px", "")}px`,
    },
  };
  return newData;
};
