import React, { useState } from "react";
import ImageBannerContext from "./ImageBannerContext";
import { randomStr } from "../../../utils/helper";

const ImageBannerState = (props) => {
  const [apiData, setApiData] = useState({ data: [], isLoading: false });
  const [apiDataOurTeam, setApiDataOurTeam] = useState({
    isLoading: false,
    data: [],
  });
  const [skill, setSkill] = useState([
    { id: randomStr(2), skill: "", parentageOfSkill: "" },
  ]);
  const [collId, setCollId] = useState();
  const [isBackgroundColor, setIsBackgroundColor] = useState(false);
  const [fromData, setFormData] = useState({
    title: "",
    navMapId: "",
    isTitleShow: true,
    webId: localStorage.getItem("webId"),
    theme: 1,
    style: {
      ".call-action-section": {
        padding: "80px 0",
        "background-color": "rgb(33 37 41 / 100)",
      },
      ".call-action-section.bg-image": {
        "background-image": "",
      },
      ".call-action-section.bg-image::before": {
        "background-color": "",
      },
      ".call-action-section .btn-pink": {
        "background-color": "rgb(255 73 124 / 100)",
        border: "2px solid rgb(255 73 124 / 100)",
        borderPx: "2px",
        borderThik: "solid",
        borderColor: "rgb(255 73 124 / 100)",
        color: "rgb(255 255 255 / 100)",
        padding: "8px 25px",
        p1: "8px",
        p2: "25px",
      },
    },
  });
  const [countOfList, setCountOfList] = useState("");
  const [formDataChild, setFormDataChild] = useState({
    title: "",
    subTitle: "",
    imageLink: "",
    image: "",
    buttonText: "",
    buttonValue: "",
    description: "",
    isTitleShow: true,
    order: 1,
    webId: localStorage.getItem("webId"),
    navMapId: "",
    style: {
      ".call-action-section": {
        padding: "80px 0",
        "background-color": "rgb(33 37 41 / 100)",
      },
      ".call-action-section.bg-image": {
        "background-image": "",
      },
      ".call-action-section.bg-image::before": {
        "background-color": "",
      },
      ".call-action-section .btn-pink": {
        "background-color": "rgb(255 73 124 / 100)",
        border: "2px solid rgb(255 73 124 / 100)",
        borderPx: "2px",
        borderThik: "solid",
        borderColor: "rgb(255 73 124 / 100)",
        color: "rgb(255 255 255 / 100)",
        padding: "8px 25px",
        p1: "8px",
        p2: "25px",
      },
    },
  });

  const [settingFormData, setSettingFormData] = useState({
    ".call-action-section": {
      padding: "80px 0",
      "background-color": "rgb(33 37 41 / 100)",
    },
    ".call-action-section.bg-image": {
      "background-image": "",
    },
    ".call-action-section.bg-image::before": {
      "background-color": "",
    },
    ".call-action-section .btn-pink": {
      "background-color": "rgb(255 73 124 / 100)",
      border: "2px solid rgb(255 73 124 / 100)",
      borderPx: "2px",
      borderThik: "solid",
      borderColor: "rgb(255 73 124 / 100)",
      color: "rgb(255 255 255 / 100)",
      padding: "8px 25px",
      p1: "8px",
      p2: "25px",
    },
    imageBannerId: "",
  });
  const [boxSection, setBoxSection] = useState(false);
  const [backImageToggle, setBackImageToggle] = useState(false);

  const [id, setId] = useState("");
  const [imageBannerId, setImageBannerId] = useState("");
  const [childId, setChildId] = useState("");
  const [navMapId, setNavMapId] = useState("");
  const [galleryImgId, setGalleryImgId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isParent, setIsParent] = useState(false);
  const [filter, setFilter] = useState("");
  const [theme, setTheme] = useState(1);
  const [parentId, setParentId] = useState("");
  const [modalTheme, setModalTheme] = useState(false);
  const [mainThemeModal, setMainThemeModal] = useState(1);
  const [imgShow, setImgShow] = useState(false);
  const [sectionPageName, setSectionPageName] = useState("");
  return (
    <ImageBannerContext.Provider
      value={{
        apiData,
        setApiData,
        apiDataOurTeam,
        setApiDataOurTeam,
        fromData,
        setFormData,
        id,
        setId,
        isEdit,
        setIsEdit,
        filter,
        setFilter,
        skill,
        galleryImgId,
        setGalleryImgId,
        setSkill,
        settingFormData,
        setSettingFormData,
        formDataChild,
        setFormDataChild,
        isParent,
        setIsParent,
        childId,
        setChildId,
        navMapId,
        setNavMapId,
        backImageToggle,
        setBackImageToggle,
        boxSection,
        setBoxSection,
        collId,
        setCollId,
        isBackgroundColor,
        setIsBackgroundColor,
        countOfList,
        setCountOfList,
        theme,
        setTheme,
        parentId,
        setParentId,
        modalTheme,
        setModalTheme,
        imageBannerId,
        setImageBannerId,
        mainThemeModal,
        setMainThemeModal,
        imgShow,
        setImgShow,
        sectionPageName,
        setSectionPageName,
      }}
    >
      {props.children}
    </ImageBannerContext.Provider>
  );
};

export default ImageBannerState;
