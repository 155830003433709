import axios from "axios";
import {
  GET_PAGE_TITLE,
  PUT_STATUS_PAGE_TITLE,
  POST_PAGE_TITLE,
  GET_PAGE_TITLE_DETAILS,
  PUT_PAGE_TITLE_ORDER,
} from "..";
import {
  BYTE_WEB_DELETE_FILE,
  BYTE_WEB_FILE_UPLOAD,
} from "../../../../apiConstants";

/**
 * get page title data
 * @param {Parameters} paramObj
 * @returns
 */
export const getPageTitle = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_PAGE_TITLE}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * enable-disable
 * @param {body} body
 * @returns
 */
export const updateStatus = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_STATUS_PAGE_TITLE}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * create page title
 * @param {body} body
 * @returns
 */
export const postPageTitle = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${POST_PAGE_TITLE}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * update page title
 * @param {body} body
 * @returns
 */
export const putUpdatePageTitle = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(`${process.env.REACT_APP_API_URL}${POST_PAGE_TITLE}`, body, {
    headers: { Authorization: jwt_token },
    params: { pageTitleId: body.pageTitleId },
  });
};

/**
 * delete page title
 * @param {id} pageTitleId
 * @returns
 */
export const deletePageTitle = (pageTitleId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${POST_PAGE_TITLE}`, {
    headers: { Authorization: jwt_token },
    params: { pageTitleId },
  });
};

/**
 * get page title details
 * @param {id} pageTitleId
 * @returns
 */
export const getPageTitleDetails = (pageTitleId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${GET_PAGE_TITLE_DETAILS}`,
    {
      headers: { Authorization: jwt_token },
      params: { pageTitleId },
    }
  );
};

/**
 * upload file
 * @param {body} formData
 * @returns
 */
export const FILE_UPLOAD_API = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${BYTE_WEB_FILE_UPLOAD}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * delete image permanently
 * @param {Parameters} formData
 * @returns
 */
export const delete_Image_Permanent = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(
    `${process.env.REACT_APP_API_URL}${BYTE_WEB_DELETE_FILE}`,
    {
      headers: { Authorization: jwt_token },
      params: formData,
    }
  );
};

/**
 * sort order page title
 * @param {body} updateOrder
 * @returns
 */
export const sort_order_page_title = (updateOrder) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_PAGE_TITLE_ORDER}`,
    { updateOrder: updateOrder },
    {
      headers: { Authorization: jwt_token },
    }
  );
};
