import React, { useState } from "react";
import { Col, Container, Input, Label, Row } from "reactstrap";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import ItemsDragAndDrops from "../../../common/ItemsDragAndDrops";

const PackagesPopup = () => {
  const dragAndDropData = [
    {
      id: "img-1",
      name: "Image 1",
    },
  ];
  const [characters, updateCharacters] = useState(dragAndDropData);

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(characters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    updateCharacters(items);
  }

  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="6">
            <div>
              <h6>Packages</h6>
              <Row>
                <Col md="10">
                  <Input
                    className="form-control mt-3 "
                    name="search"
                    type="text"
                    placeholder="search"
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="10">
                  <div className="mt-4">
                    <ItemsDragAndDrops
                      handleOnDragEnd={handleOnDragEnd}
                      characters={characters}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col sm="12" xl="6">
            <div className="mt-4">
              <h6>Add New Packages</h6>
              <Row className="mt-4">
                <Col md="6 mb-3">
                  <Label htmlFor="validationCustom03">{"Title "}</Label>
                  <Input
                    className="form-control"
                    name="title"
                    type="text"
                    placeholder="Title "
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="6 mb-3">
                  <Label htmlFor="validationCustom03">{"Headline *"}</Label>
                  <Input
                    className="form-control"
                    name="title"
                    type="text"
                    placeholder="Headline *"
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="6 mb-3">
                  <Label htmlFor="validationCustom03">{"Payment"}</Label>
                  <Input
                    className="form-control"
                    name="payment"
                    type="number"
                    placeholder="Payment"
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="6 mb-3">
                  <Label htmlFor="validationCustom03">{"Number"}</Label>
                  <Input
                    className="form-control"
                    name="title"
                    type="number"
                    placeholder="Number"
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Button Name"}</Label>
                  <Input
                    className="form-control"
                    name="title"
                    type="text"
                    placeholder="Button Name"
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <ProductImageUploadPopup />
      </Container>
    </>
  );
};

export default PackagesPopup;
