import React from "react";

const InputFileUpload = ({ imageUpload, isTrue = false }) => {
  return (
    <input
      type="file"
      style={{
        opacity: "0",
        position: "absolute",
        height: "82%",
        zIndex: "99",
      }}
      onChange={(e) => imageUpload(e, isTrue)}
    />
  );
};

const fileUploadMessage =
  "File type required JPG, PNG, JPEG, png, jpg, jpeg, gif and 1mb max upload size";

const AllExportsImage = {
  InputFileUpload,
  fileUploadMessage,
};

export default AllExportsImage;
