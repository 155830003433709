import React, { useContext, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import DeleteSection from "../../../common/DeleteSection";
import { IconButton } from "@material-ui/core";
import { Settings, Trash } from "react-feather";
import {
  CUSTOM_FILED_TYPE_CHECK,
  Cases,
  DELETE_CONFIRM,
  modalsName,
} from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import CustomFormContext from "../../../context/CustomFormContext/CustomFormContext";
import EditIcon from "@mui/icons-material/Edit";
import CommonPopup from "../../../Modals/commonPopup";
import CustomFormParentPopup from "./CustomFormParentPopup";
import SimpleReactValidator from "simple-react-validator";
import {
  deleteCustomForm,
  getCustomFormDetails,
  postCustomForm,
  putUpdateCustomForm,
  updateStatus,
} from "../apis/CustomFormApi/CustomFormApi";
import { FILE_TYPE } from "../../../../constant";
import { FILE_UPLOAD_API } from "../apis/SliderApi/SliderApi";
import CustomFormPopup from "./CustomFormPopup";
import {
  deleteCustomFormFiled,
  getCustomFormFiled,
  getCustomFormFiledDetails,
  postCustomFormFiled,
  putUpdateCustomFormFiled,
  updateStatusFormFiled,
} from "../apis/CustomFormFieldApi /CustomFormApi";
import { randomStr } from "../../../../utils/helper";

const CustomForm = (props) => {
  const {
    pageConfigDetails,
    webMasterDetail,
    customFormDetail,
    contentMapId,
    ByteMiniWeb_Dashboard,
    navMapId: navMapIds,
  } = props;
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );
  const {
    fromData,
    setFormData,
    formDataChild,
    setFormDataChild,
    id,
    setId,
    setIsEdit,
    isEdit,
    apiDataOurTeam: apiDataImageParent,
    setApiDataOurTeam: setApiDataImageParent,
    setIsParent,
    setCollId,
  } = useContext(CustomFormContext);
  const { setLoaderShow } = useContext(LoaderContext);
  const parentValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const childValidator = useRef(new SimpleReactValidator());

  const getCustomFormChildList = async ({ filter }) => {
    try {
      let paramObj = {
        showAll: true,
      };
      if (filter?.length) {
        paramObj["search"] = { name: filter };
      }
      const { data } = await getCustomFormFiled(paramObj);
      if (data.status === 200) {
        setApiDataImageParent({
          ...apiDataImageParent,
          isLoading: false,
          data: data.payload.data.map((elem) => {
            return {
              id: elem?.name + elem?.customFieldId,
              Id: elem?.customFieldId,
              name: elem?.name,
              webId: elem?.webId,
              createdAt: elem?.createdAt,
              isActive: elem?.isActive,
              parentId: elem?.parentId,
            };
          }),
        });
        OpenModalCommonFunc(Cases.CUSTOM_FORM_POPUP, setAllPopupState);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * file upload
   * @param {file} file
   */
  const imageUploadImages = async (file) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.CUSTOM_FORM);
    formData.append("file", file);
    setLoaderShow(true);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        setFormDataChild((prev) => {
          return {
            ...prev,
            image: data.payload.baseUrl + data.payload.imageUrl,
            featureImage: data.payload.baseUrl + data.payload.imageUrl,
          };
        });
        setFormData((prev) => {
          return {
            ...prev,
            image: data.payload.baseUrl + data.payload.imageUrl,
          };
        });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const handleChangeCustomForm = async (customFormId, isActive) => {
    try {
      let body = {
        customFormId,
        isActive: isActive ? false : true,
      };
      const { data } = await updateStatus(body);
      if (data.status === 200) {
        ByteMiniWeb_Dashboard();
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };
  const handleChangeCustomFormField = async (customFieldId, isActive) => {
    try {
      let body = {
        customFieldId,
        isActive: isActive ? false : true,
      };
      const { data } = await updateStatusFormFiled(body);
      if (data.status === 200) {
        getCustomFormChildList({ filter: "" });
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  const submitForm = async () => {
    if (isEdit) {
      try {
        let formData = {};
        formData = {
          ...fromData,
          customFormId: id,
          contentMapId,
        };
        if (formData.image?.length) {
          formData["image"] = formData.image
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
            .pop();
        }
        const { data } = await putUpdateCustomForm(formData);
        if (data.status === 200) {
          ByteMiniWeb_Dashboard();
          emptyData();
          OpenModalCommonFunc(
            Cases.CUSTOM_FORM_PARENT,
            setAllPopupState,
            false
          );
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    } else {
      try {
        let formData = {};
        formData = {
          ...fromData,
          customFormId: id,
          contentMapId,
        };
        if (formData.image?.length) {
          formData["image"] = formData.image
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
            .pop();
        }
        const { data } = await postCustomForm(formData);
        if (data.status === 200) {
          ByteMiniWeb_Dashboard();
          emptyData();
          OpenModalCommonFunc(
            Cases.CUSTOM_FORM_PARENT,
            setAllPopupState,
            false
          );
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };
  const submitFormChild = async () => {
    if (isEdit) {
      try {
        let formData = {};
        formData = {
          ...formDataChild,
          contentMapId,
        };
        if (CUSTOM_FILED_TYPE_CHECK.includes(formDataChild?.type)) {
          formData["options"] = formData.options.map((ele) => {
            return {
              name: ele.name,
              order: ele.order,
            };
          });
        } else {
          delete formData?.options;
        }
        const { data } = await putUpdateCustomFormFiled(formData);
        if (data.status === 200) {
          getCustomFormChildList({ filter: "" });
          emptyData();
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    } else {
      try {
        let formData = {};
        formData = {
          ...formDataChild,
          customFormId: id,
          contentMapId,
        };
        if (CUSTOM_FILED_TYPE_CHECK.includes(formDataChild?.type)) {
          formData["options"] = formData.options.map((ele) => {
            return {
              name: ele.name,
              order: ele.order,
            };
          });
        } else {
          delete formData?.options;
        }
        const { data } = await postCustomFormFiled(formData);
        if (data.status === 200) {
          getCustomFormChildList({ filter: "" });
          emptyData();
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };
  const handleSubmit = async (formValidationCondition) => {
    let validatorFields;
    if (formValidationCondition === "parentForm") {
      validatorFields = parentValidator.current;
    } else if (formValidationCondition === "childForm") {
      validatorFields = childValidator.current;
    }
    validatorFields.showMessages();

    if (validatorFields.allValid()) {
      try {
        // Process form data based on the formType
        if (formValidationCondition === "parentForm") {
          submitForm();
        } else if (formValidationCondition === "childForm") {
          submitFormChild();
        }
        validatorFields.hideMessages();
      } catch (error) {
        // Handle error for form submission
      }
    } else {
      // Force update to display validation messages
      forceUpdate({});
    }
  };

  /**
   * delete custom form
   * @param {id} customFormId
   */
  const handleDelete = async (customFormId) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await deleteCustomForm(customFormId);
        if (data.status === 200) {
          ByteMiniWeb_Dashboard();
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };
  const handleDeleteChild = async (customFieldId) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await deleteCustomFormFiled(customFieldId);
        if (data.status === 200) {
          getCustomFormChildList({ filter: "" });
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * get data customFormId
   * @param {id} customFormId
   */
  const handleEditCustomForm = async (customFormId, isParent) => {
    try {
      setLoaderShow(true);
      const { data } = await getCustomFormDetails(customFormId);
      if (data.status === 200) {
        let payload = data.payload.data;

        setIsParent(false);
        setId(payload?.customFormId);
        setFormData({
          title: payload?.title || "",
          subTitle: payload?.subTitle || "",
          image: payload?.image
            ? `${process.env.REACT_APP_BUCKET_URL}/${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/${payload?.image}`
            : "",
          contentMapId: payload?.contentMapId,
        });
        OpenModalCommonFunc(Cases.CUSTOM_FORM_PARENT, setAllPopupState);

        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };
  const handleEditCustomFormChild = async (customFieldId, isParent) => {
    try {
      setLoaderShow(true);
      const { data } = await getCustomFormFiledDetails(customFieldId);
      if (data.status === 200) {
        let payload = data.payload.data;
        setIsParent(false);
        setFormDataChild({
          name: payload?.name || "",
          order: payload?.order || "",
          type: payload?.type || "",
          isRequired: payload?.isRequired || false,
          customFieldId: payload?.customFieldId || "",
          options: payload?.options || [
            { id: randomStr(2), skill: "", parentageOfSkill: "" },
          ],
        });
        OpenModalCommonFunc(Cases.CUSTOM_FORM_POPUP, setAllPopupState);

        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const emptyData = () => {
    setFormData({
      title: "",
      subTitle: "",
      image: "",
      type: "popup",
      theme: 1,
    });
    setFormDataChild({
      name: "",
      type: "",
      options: [
        {
          id: randomStr(2),
          name: "",
          order: "",
        },
      ],
      isRequired: false,
      order: "",
      customFormId: "",
    });
    setIsEdit(false);
  };
  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody
            className="support-ticket-font box-border-div"
            style={{ padding: "20px" }}
          >
            <Row>
              <CardBuilderHeaderSection
                addBtnTitle={pageConfigDetails[0]?.label}
                name={pageConfigDetails[0]?.label}
                isAdd={!customFormDetail && true}
                mainEditSection={true}
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
                openModal={() => {
                  OpenModalCommonFunc(
                    Cases.CUSTOM_FORM_PARENT,
                    setAllPopupState
                  );
                  emptyData();
                  // setIsParent(true);
                  setCollId(contentMapId);
                }}
                isCloneSection={true}
                isAlignSection={true}
                // handleAlignAndCloneSection={handleAlignAndCloneSection}
              />
              {customFormDetail && (
                <Col xl="12 xl-50 box-col-6" md="6">
                  <Card className="ecommerce-widget mb-0">
                    <CardBody
                      className="support-ticket-font"
                      style={{ padding: "20px" }}
                    >
                      <div className="col-12">
                        <CardBuilderHeaderSection
                          name={customFormDetail?.title}
                          checked={customFormDetail?.isActive}
                          switchToggle={true}
                          id={customFormDetail?.customFormId}
                          handleChange={handleChangeCustomForm}
                        />
                        <OpenPopupBtn
                          openPopup={() => {
                            getCustomFormChildList({ filter: "" });
                            setId(customFormDetail?.customFormId);
                            setCollId(contentMapId);
                          }}
                          name={`Add or View ${customFormDetail?.title}`}
                        />
                      </div>
                      <div className="d-flex justify-content-end ">
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleEditCustomForm(
                              customFormDetail.customFormId,
                              true
                            );
                            setCollId(contentMapId);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() =>
                            handleDelete(customFormDetail.customFormId)
                          }
                        >
                          <Trash />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            // handleChangeSettings(customFormDetail.customFormId);
                            // setTheme(customFormDetail?.theme || "");
                          }}
                        >
                          <Settings />
                        </IconButton>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
              <DeleteSection
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
              />
            </Row>
          </CardBody>
        </Card>
      </Col>
      {/* item faqs popup */}
      <CommonPopup
        open={allPopupState.customFormPopup}
        close={() => {
          OpenModalCommonFunc(
            Cases.CUSTOM_FORM_PARENT,
            setAllPopupState,
            false
          );
          setIsParent(false);
        }}
        modalName={
          customFormDetail?.title
            ? customFormDetail?.title
            : modalsName.CUSTOM_FORM_PARENT
        }
        modalCase={Cases.CUSTOM_FORM_PARENT}
        handleSubmit={handleSubmit}
        validationForm={"parentForm"}
        emptyData={emptyData}
        inputData={fromData}
        edit={false}
        content={<CustomFormParentPopup imageUpload={imageUploadImages} />}
      />
      <CommonPopup
        open={allPopupState.customFormChildPopup}
        close={() => {
          OpenModalCommonFunc(Cases.CUSTOM_FORM_POPUP, setAllPopupState, false);
          setIsParent(false);
        }}
        // apiCall={getFaqsParentList}
        modalName={
          customFormDetail?.title
            ? customFormDetail?.title
            : modalsName.CUSTOM_FORM_POPUP
        }
        modalCase={Cases.CUSTOM_FORM_POPUP}
        handleSubmit={handleSubmit}
        validationForm={"childForm"}
        emptyData={emptyData}
        inputData={formDataChild}
        edit={false}
        content={
          <CustomFormPopup
            getCustomFormChildList={getCustomFormChildList}
            handleChangeStatus={handleChangeCustomFormField}
            handleEdit={handleEditCustomFormChild}
            imageUpload={imageUploadImages}
            emptyData={emptyData}
            simpleValidator={childValidator}
            webMasterDetail={webMasterDetail}
            handleDelete={handleDeleteChild}
          />
        }
      />
    </>
  );
};

export default CustomForm;
