import * as React from "react";
import Breadcrumbs from "../../layout/breadcrumb";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { TITLES } from "../../constant";
import { headerStyle } from "../../utils/MuiTabelStyle";
import { useNavigate, useParams } from "react-router";
import { X } from "react-feather";
import { Tooltip } from "@material-ui/core";
import { classes } from "../../data/layouts";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Outlet, Route, Routes } from "react-router-dom";
import Category from "./Components/Category/Category";
import Tags from "./Components/Tags/Tags";
import AddOnProducts from "./Components/AddOnProducts/AddOnProducts";
import Products from "./Components/Products/Products";
import { useEffect } from "react";
import { useState } from "react";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const MenuMaster = () => {
  let { hwId, QpiId, productId } = useParams();
  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  const history = useNavigate();

  const handleSelectedValueSet = () => {
    switch (productId) {
      case "category":
        return 0;
      case "product":
        return 1;
      case "add-on-product":
        return 2;
      case "tags":
        return 3;
      default:
        return;
    }
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [value, setValue] = useState(handleSelectedValueSet());

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const Previous = () => {
    localStorage.removeItem("pageLimit");
    history(`/${hwId}/PageItem/AddPageItem/${layout}`);
  };

  const RenderComponents = (props) => {
    switch (productId) {
      case "category":
        return <Category />;
      case "product":
        return <Products />;
      case "add-on-product":
        return <AddOnProducts />;
      case "tags":
        return <Tags />;
      default:
        return <div>Not found</div>;
    }
  };

  const TabsData = [
    {
      path: "/",
      element: <RenderComponents />,
      index: 0,
    },
    {
      path: "/",
      element: <RenderComponents />,
      index: 1,
    },
    {
      path: "/",
      element: <RenderComponents />,
      index: 2,
    },
    {
      path: "/",
      element: <RenderComponents />,
      index: 3,
    },
  ];

  return (
    <>
      <Breadcrumbs parent="Menu" />
      <Container fluid={true}>
        <Row>
          <Col>
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h5 style={headerStyle}>{TITLES.MENU_PAGE} </h5>
                <div style={{ display: "flex" }}>
                  <div style={{ paddingRight: "20px" }}>
                    <Tooltip title="Close">
                      <div
                        className="btn btn-light"
                        style={{
                          borderRadius: "100%",
                          width: "50px",
                          height: "55px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => Previous()}
                      >
                        <X style={{ color: "#968989" }} />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <Box
                  sx={{
                    flexGrow: 1,
                    bgcolor: "background.paper",
                    // height: 224,
                    display: windowWidth >= 1000 ? "flex" : "",
                  }}
                >
                  {" "}
                  <Tabs
                    orientation={
                      windowWidth >= 1000 ? "vertical" : "horizontal"
                    }
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{ borderRight: 1, borderColor: "divider" }}
                    style={
                      windowWidth >= 1000 ? { width: "20%" } : { width: "100%" }
                    }
                  >
                    <Tab
                      label="Category"
                      {...a11yProps(0)}
                      onClick={() =>
                        history(
                          `/${hwId}/MenuMaster/${QpiId}/category/${layout}`
                        )
                      }
                    />
                    <Tab
                      label="Products"
                      {...a11yProps(1)}
                      onClick={() =>
                        history(
                          `/${hwId}/MenuMaster/${QpiId}/product/${layout}`
                        )
                      }
                    />
                    <Tab
                      label="Added on Product"
                      {...a11yProps(2)}
                      onClick={() =>
                        history(
                          `/${hwId}/MenuMaster/${QpiId}/add-on-product/${layout}`
                        )
                      }
                    />
                    <Tab
                      label="Tags"
                      {...a11yProps(3)}
                      onClick={() =>
                        history(`/${hwId}/MenuMaster/${QpiId}/tags/${layout}`)
                      }
                    />
                  </Tabs>{" "}
                  {productId ? (
                    <>
                      <Outlet />
                      {TabsData?.map((elem) => {
                        return (
                          <TabPanel
                            value={value}
                            index={elem.index}
                            style={
                              windowWidth >= 1000
                                ? { width: "80%" }
                                : { width: "100%" }
                            }
                          >
                            <Routes>
                              <Route path={elem.path} element={elem.element} />
                            </Routes>
                          </TabPanel>
                        );
                      })}
                      {/* <TabPanel value={value} index={1}>
                        <Routes>
                          <Route path={`/`} element={<RenderComponents />} />
                        </Routes>
                      </TabPanel>
                      <TabPanel value={value} index={2}>
                        <Routes>
                          <Route path={`/`} element={<RenderComponents />} />
                        </Routes>
                      </TabPanel>
                      <TabPanel value={value} index={3}>
                        <Routes>
                          <Route path={`/`} element={<RenderComponents />} />
                        </Routes>
                      </TabPanel> */}
                    </>
                  ) : (
                    ""
                  )}
                </Box>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default MenuMaster;
