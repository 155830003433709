import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import { Cases, DELETE_CONFIRM, btnName, modalsName } from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import Lightbox from "react-image-lightbox";
import CommonPopup from "../../../Modals/commonPopup";
import OurTeamMemberPopup from "./OurTeamMemberPopup";
import OurTeamContext from "../../../context/OurTeamContext/OurTeamContext";
import {
  deleteOurTeam,
  getOurTeamDetails,
  getOurTeamMemberDetails,
  getOutTeam,
  getOutTeamMember,
  postOurTeam,
  postOurTeamMember,
  putUpdateOurTeam,
  putUpdateOurTeamMember,
  updateStatus,
  updateStatusOurTeam,
} from "../../../apis/OurTeamApi/OurTeamApi";
import { FILE_TYPE } from "../../../../constant";
import { FILE_UPLOAD_API } from "../../../apis/SliderApi/SliderApi";
import OurTeamPopup from "./OurTeamPopup";
import { IconButton } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import { Trash } from "react-feather";
import { randomStr } from "../../../../utils/helper";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import SimpleReactValidator from "simple-react-validator";

const OurTeam = () => {
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const simpleValidatorTeamMember = useRef(new SimpleReactValidator());
  const [, forceUpdate1] = useState();
  const { setLoaderShow } = useContext(LoaderContext);
  const {
    apiData: ourTeamData,
    setApiData,
    fromData,
    setFormData,
    formDataOurTeam,
    setFormDataOurData,
    id,
    setId,
    setIsEdit,
    isEdit,
    filter,
    apiDataOurTeam,
    setApiDataOurTeam,
    skill,
    setSkill,
    teamMemberId,
    setTeamMemberId,
  } = useContext(OurTeamContext);
  const [open, setOpen] = useState(false);
  const [imageSet, setImageSet] = useState("");

  /**
   * get our team member list
   */
  const getOurTeamMember = async (status) => {
    try {
      if (!filter.length && status) {
        setApiData((prev) => {
          return { ...prev, isLoading: true };
        });
      }
      let paramObj = {
        search: { title: filter, teamId: id },
        showAll: true,
      };
      const { data } = await getOutTeamMember(paramObj);
      if (data.status === 200) {
        setApiData({
          ...ourTeamData,
          isLoading: false,
          data: data.payload.data.map((elem) => {
            return {
              id: elem?.title + elem?.teamMemberId,
              Id: elem?.teamMemberId,
              name: elem?.title,
              image: elem?.image,
              webId: elem?.webId,
              headLine: elem?.headLine,
              createdAt: elem?.createdAt,
              isActive: elem?.isActive,
            };
          }),
        });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };
  /**
   * get our team list
   * @param {status} status
   */
  const getOurTeamList = async (status) => {
    try {
      if (!filter.length && status) {
        setApiDataOurTeam((prev) => {
          return { ...prev, isLoading: true };
        });
      }

      let paramObj = {
        search: { title: filter },
      };
      const { data } = await getOutTeam(paramObj);
      if (data.status === 200) {
        setApiDataOurTeam({
          ...apiDataOurTeam,
          isLoading: false,
          data: data.payload.data,
        });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  useMemo(() => {
    if (allPopupState?.ourteamMemberPopup) {
      getOurTeamMember();
    }
  }, [filter, allPopupState?.ourteamMemberPopup]);

  useEffect(() => {
    getOurTeamList();
  }, []);

  /**
   * our team member enable disable api call
   * @param {teamMember id} teamMemberId
   * @param {status} isActive
   */

  const handleChangeStatus = async (teamMemberId, isActive) => {
    try {
      let body = {
        teamMemberId,
        isActive: isActive ? false : true,
      };
      const { data } = await updateStatus(body);
      if (data.status === 200) {
        getOurTeamMember(false);
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };
  /**
   * our team enable disable api call
   * @param {team id} teamId
   * @param {status} isActive
   */

  const handleChangeOurTeamStatus = async (teamId, isActive) => {
    try {
      let body = {
        teamId,
        isActive: isActive ? false : true,
      };
      const { data } = await updateStatusOurTeam(body);
      if (data.status === 200) {
        getOurTeamList();
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * file upload
   * @param {file} file
   */
  const imageUpload = async (file, name) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.BYTE_SLIDER);
    formData.append("file", file);
    setLoaderShow(true);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        setFormDataOurData((prev) => {
          return {
            ...prev,
            image: data.payload.baseUrl + data.payload.imageUrl,
          };
        });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };
  /**
   * file upload
   * @param {file} file
   */
  const imageUploadTeamMember = async (file) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.BYTE_SLIDER);
    formData.append("file", file);
    setLoaderShow(true);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        setFormData((prev) => {
          return {
            ...prev,
            image: data.payload.baseUrl + data.payload.imageUrl,
          };
        });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * create and update our team member
   */
  const handleSubmit = async () => {
    const formValid = simpleValidatorTeamMember.current.allValid();
    if (!formValid) {
      simpleValidatorTeamMember.current.showMessages();
      forceUpdate1(1);
    } else {
      if (teamMemberId >= -1 && isEdit) {
        setLoaderShow(true);
        let newData = {
          ...fromData,
          teamId: id,
          skill: JSON.stringify(skill),
          teamMemberId,
        };
        if (fromData.image.length) {
          setFormData((prev) => {
            return {
              ...prev,
              image: prev.image
                .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
                .pop(),
              teamId: id,
              skill: JSON.stringify(skill),
              teamMemberId,
            };
          });
          newData["image"] = fromData.image
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
            .pop();
        }
        try {
          const { data } = await putUpdateOurTeamMember(newData);
          if (data.status === 200) {
            getOurTeamMember();
            emptyData();
            TostMessage({ message: data, type: "success" });
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      } else {
        try {
          setLoaderShow(true);
          let newData = {
            ...fromData,
            teamId: id,
            skill: JSON.stringify(skill),
            order: ourTeamData.data.length + 1,
          };
          if (fromData.image.length) {
            setFormData((prev) => {
              return {
                ...prev,
                image: prev.image
                  .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
                  .pop(),
                teamId: id,
                skill: JSON.stringify(skill),
                order: ourTeamData.data.length + 1,
              };
            });
            newData["image"] = fromData.image
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
              .pop();
          }
          const { data } = await postOurTeamMember(newData);
          if (data.status === 200) {
            getOurTeamMember();
            emptyData();
            TostMessage({ message: data, type: "success" });
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      }
    }
  };

  /**
   * create and update our team
   */
  const handleSubmitOurTeam = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      if (id >= -1 && isEdit) {
        setLoaderShow(true);
        let newData = { ...formDataOurTeam };
        if (fromData.image.length) {
          setFormDataOurData((prev) => {
            return {
              ...prev,
              image: prev.image
                .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
                .pop(),
            };
          });
          newData["image"] = formDataOurTeam.image
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
            .pop();
        }
        try {
          const { data } = await putUpdateOurTeam(newData);
          if (data.status === 200) {
            getOurTeamList();
            emptyData();
            OpenModalCommonFunc(Cases.OUR_TEAM, setAllPopupState, false);
            TostMessage({ message: data, type: "success" });
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      } else {
        try {
          setLoaderShow(true);
          let newData = { ...formDataOurTeam };
          if (fromData.image.length) {
            setFormDataOurData((prev) => {
              return {
                ...prev,
                image: prev.image
                  .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
                  .pop(),
              };
            });
            newData["image"] = formDataOurTeam.image
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
              .pop();
          }
          const { data } = await postOurTeam(newData);
          if (data.status === 200) {
            getOurTeamList();
            emptyData();
            OpenModalCommonFunc(Cases.OUR_TEAM, setAllPopupState, false);
            TostMessage({ message: data, type: "success" });
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      }
    }
  };

  /**
   * get our team member details
   * @param {id} teamMemberId
   */
  const handleEdit = async (teamMemberId) => {
    try {
      setLoaderShow(true);
      setTeamMemberId(teamMemberId);
      const { data } = await getOurTeamMemberDetails(teamMemberId);
      if (data.status === 200) {
        let payload = data.payload.data;
        setFormData({
          title: payload.title,
          subTitle: payload.subTitle,
          description: payload.description,
          image: payload.image,
          teamId: payload.teamId,
          webId: localStorage.getItem("webId"),
        });
        setSkill(payload?.skill && JSON.parse(payload?.skill));
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * get data our team
   * @param {id} teamId
   */
  const handleEditOurTeam = async (teamId) => {
    try {
      setLoaderShow(true);
      setId(teamId);
      const { data } = await getOurTeamDetails(teamId);
      if (data.status === 200) {
        let payload = data.payload.data;
        setFormDataOurData({
          title: payload.title,
          headLine: payload.headLine,
          image: payload.image,
          teamId: teamId,
          webId: localStorage.getItem("webId"),
        });
        OpenModalCommonFunc(Cases.OUR_TEAM, setAllPopupState);
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(true);
    }
  };

  /**
   * delete our team
   * @param {id} teamId
   */
  const handleDelete = async (teamId) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await deleteOurTeam(teamId);
        if (data.status === 200) {
          getOurTeamList();
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * clear data
   */
  const emptyData = () => {
    setIsEdit(false);
    setFormData({
      title: "",
      description: "",
      subTitle: "",
      image: "",
      webId: localStorage.getItem("webId"),
    });
    setSkill([{ id: randomStr(2), skill: "", parentageOfSkill: "" }]);
    setTeamMemberId("");
    setFormDataOurData({
      title: "",
      headLine: "",
      image: "",
      webId: localStorage.getItem("webId"),
    });
    setLoaderShow(false);
  };

  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody className="support-ticket-font box-border-div">
            <Row>
              <CardBuilderHeaderSection
                name={`${apiDataOurTeam?.data?.length} Our Team`}
                isAdd={true}
                openModal={() =>
                  OpenModalCommonFunc(Cases.OUR_TEAM, setAllPopupState)
                }
              />
              {apiDataOurTeam?.data?.map((elem) => {
                return (
                  <Col xl="3 xl-50 box-col-6" md="6">
                    <Card className="ecommerce-widget ">
                      <CardBody className="support-ticket-font">
                        <div className="col-12">
                          <CardBuilderHeaderSection
                            name={elem?.title}
                            checked={elem?.isActive}
                            switchToggle={true}
                            id={elem?.teamId}
                            handleChange={handleChangeOurTeamStatus}
                          />
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setOpen(!open);
                              setImageSet(elem.image);
                            }}
                          >
                            <div
                              style={{
                                objectFit: "contain",
                                maxWidth: "125px",
                                height: "83px",
                                border: "1px solid #d3d3d3",
                                width: "100%",
                                display: "inline-block",
                                verticalAlign: "middle",
                                overflow: "hidden",
                                position: "relative",
                              }}
                            >
                              <div
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  right: "0",
                                  bottom: "0",
                                  left: "0",
                                  borderRadius: "inherit",
                                }}
                              >
                                <img
                                  src={elem.image}
                                  alt=""
                                  style={{
                                    objectPosition: "50% 50%",
                                    objectFit: "contain",
                                    opacity: "1",
                                    transition: "opacity 0.28s ease-in",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <OpenPopupBtn
                            openPopup={() => {
                              OpenModalCommonFunc(
                                Cases.OUR_TEAM_MEMBER,
                                setAllPopupState
                              );
                              setId(elem.teamId);
                            }}
                            name={btnName.OUR_TEAM}
                          />
                        </div>
                        <div className="d-flex justify-content-end ">
                          <IconButton
                            edge="start"
                            color="inherit"
                            style={{ marginLeft: "5px" }}
                            onClick={() => handleEditOurTeam(elem.teamId)}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            edge="start"
                            color="inherit"
                            style={{ marginLeft: "5px" }}
                            onClick={() => handleDelete(elem.teamId)}
                          >
                            <Trash />
                          </IconButton>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </CardBody>
        </Card>
      </Col>

      {open && (
        <Lightbox mainSrc={imageSet} onCloseRequest={() => setOpen(false)} />
      )}
      {/* team member popup */}
      <CommonPopup
        open={allPopupState.ourteamMemberPopup}
        close={() =>
          OpenModalCommonFunc(Cases.OUR_TEAM_MEMBER, setAllPopupState, false)
        }
        modalName={modalsName.OUR_TEAM_MEMBER}
        modalCase={Cases.OUR_TEAM_MEMBER}
        handleSubmit={handleSubmit}
        emptyData={emptyData}
        inputData={fromData}
        edit={false}
        content={
          <OurTeamMemberPopup
            handleChangeStatus={handleChangeStatus}
            getData={getOurTeamMember}
            handleEdit={handleEdit}
            imageUpload={imageUploadTeamMember}
            emptyData={emptyData}
            simpleValidator={simpleValidatorTeamMember}
          />
        }
      />

      {/* our team popup */}
      <CommonPopup
        open={allPopupState.ourteamPopup}
        close={() =>
          OpenModalCommonFunc(Cases.OUR_TEAM, setAllPopupState, false)
        }
        modalName={modalsName.OUR_TEAM}
        modalCase={Cases.OUR_TEAM}
        handleSubmit={handleSubmitOurTeam}
        emptyData={emptyData}
        inputData={formDataOurTeam}
        edit={false}
        content={
          <OurTeamPopup
            imageUpload={imageUpload}
            simpleValidator={simpleValidator}
          />
        }
      />
    </>
  );
};

export default OurTeam;
