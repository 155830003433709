import axios from "axios";
import {
  GET_OUR_CLIENT,
  GET_OUR_CLIENT_DETAILS,
  POST_OUR_CLIENT,
  PUT_OUR_CLIENT_ORDER,
  PUT_STATUS_OUR_CLIENT,
} from "..";

/**
 * get our client
 * @param {Parameters} paramObj
 * @returns
 */
export const getOurClient = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_OUR_CLIENT}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * enable-disable our client
 * @param {body} body
 * @returns
 */
export const updateStatus = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_STATUS_OUR_CLIENT}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * create our client
 * @param {body} body
 * @returns
 */
export const postOurClient = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${POST_OUR_CLIENT}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * update our client
 * @param {body} body
 * @returns
 */
export const putUpdateOurClient = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(`${process.env.REACT_APP_API_URL}${POST_OUR_CLIENT}`, body, {
    headers: { Authorization: jwt_token },
    params: { ourClientId: body.ourClientId },
  });
};

/**
 * delete our client
 * @param {id} ourClientId
 * @returns
 */
export const deleteOurClient = (ourClientId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${POST_OUR_CLIENT}`, {
    headers: { Authorization: jwt_token },
    params: { ourClientId },
  });
};

/**
 * get our client
 * @param {id} ourClientId
 * @returns
 */
export const getOurClientDetails = (ourClientId, navMapId) => {
  const jwt_token = localStorage.getItem("token");
  let paramObj = {
    ourClientId,
  };
  if (navMapId) {
    paramObj["navMapId"] = navMapId;
  }
  return axios.get(
    `${process.env.REACT_APP_API_URL}${GET_OUR_CLIENT_DETAILS}`,
    {
      headers: { Authorization: jwt_token },
      params: paramObj,
    }
  );
};

/**
 * short order our client
 * @param {body} updateOrder
 * @returns
 */
export const sort_our_client = (updateOrder) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_OUR_CLIENT_ORDER}`,
    { updateOrder: updateOrder },
    {
      headers: { Authorization: jwt_token },
    }
  );
};
