import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Zoom, toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import LoaderContext from "../../../context/LoaderContext/LoaderContext";
import { TostMessage } from "../../../components ByteCard/utils";
import { DELETE_CONFIRM } from "../../../components ByteCard/constant";
import { FILE_TYPE, SINGLE_PAGE, SORTING_ORDER } from "../../../constant";
import TopLoadingBar from "../../../utils/topLoadingBar";
import Breadcrumbs from "../../../layout/breadcrumb";
import CloneCategoryAddPopup from "./CloneCategoryAddPopup";
import CloneCategoryItem from "./CloneCategoryItem";
import {
  Add_cloneCategory_List,
  Delete_cloneCategory_List,
  Edit_cloneCategory_List,
  cloneCategory_Enable_Disable,
  cloneCategory_List,
} from "../../../api/CloneCategoryApi/CloneCategoryApi";
import CloneCategoryContext from "../../../context/CloneCategoryContext/CloneCategoryContext";
import { useParams } from "react-router";
import { FILE_API } from "../../../api/Organization Api/OrganizationApi";
import { FILE_UPLOAD_API } from "../../../components ByteCard/components/MultipageByteCardBuilder/apis/SliderApi/SliderApi";

const CloneCategory = () => {
  let { pageName } = useParams();
  const {
    setFormValue,
    formData,
    pageSize,
    sortOrder,
    setSortOrder,
    page,
    sortBy,
    setSortBy,
    setPageLimit,
    setRecord,
    isSwitchOn,
    setIsSwitchOn,
    addModel,
    setFormData,
    setAddModel,
    isEdit,
    setIsEdit,
    id,
    setId,
    progress,
    setProgress,
  } = useContext(CloneCategoryContext);
  const { setLoaderShow } = useContext(LoaderContext);
  const { tabelAnimation, setTableAnimation } = useContext(LoaderContext);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();

  const getCloneCategoryList = async (desabledStatusLoader) => {
    if (
      sortOrder === "ASC" ||
      sortOrder === "DSC" ||
      desabledStatusLoader === false
    ) {
    } else {
      setTableAnimation(!tabelAnimation);
    }
    try {
      let paramObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
      };
      const { data } = await cloneCategory_List(paramObj, pageName);

      if (data.status === 200) {
        setFormValue(data.payload.data);
        setPageLimit(data.payload.pageLimit);
        setRecord(data.pager.totalRecords);

        setTableAnimation(false);
      } else {
      }
    } catch (error) {
      setTableAnimation(false);
    }
  };

  useEffect(() => {
    getCloneCategoryList();
  }, [page, pageSize, sortBy, sortOrder, pageName]);

  /**
   * Enable and Desable Category
   */
  const switch_onChange_handle = async (cloneCategoryId, isActive) => {
    let formData = {
      cloneCategoryId: cloneCategoryId,
      isActive: isActive ? false : true,
      // type: TYPES.LANDING_PAGE,
    };
    try {
      const { data } = await cloneCategory_Enable_Disable(
        formData,
        cloneCategoryId
      );
      if (data.status === 200) {
        await setIsSwitchOn(!isSwitchOn);
        const desabledStatusLoader = false;
        await getCloneCategoryList(desabledStatusLoader);
        isActive
          ? toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            })
          : toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            });
      } else {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * Sorting category Table
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder(SORTING_ORDER.ASC);
    }
    if (sortOrder === SORTING_ORDER.ASC) {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder(SORTING_ORDER.DSC);
    }
    if (sortOrder === SORTING_ORDER.DSC) {
      setSortBy();
      setSortOrder("");
    }
  };
  const ImageUploadImages = async (file, name) => {
    const fileType =
      pageName == SINGLE_PAGE
        ? FILE_TYPE.CLONE_CATEGORY_MINI
        : FILE_TYPE.CLONE_CATEGORY_MULTI;
    const ImageUploadApi = pageName == SINGLE_PAGE ? FILE_API : FILE_UPLOAD_API;
    let formValue = new FormData();
    formValue.append("type", fileType);
    formValue.append("file", file);
    setLoaderShow(true);
    try {
      const { data } = await ImageUploadApi(formValue);
      const setData = [...formData.allItem];
      const index = formData.imageNo;
      setData[index]["image"] = data.payload.baseUrl + data.payload.imageUrl;
      if (data.status === 200) {
        setFormData((prev) => {
          return {
            ...prev,
            allItem: setData,
            image: "",
          };
        });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const imagePathToDelete =
      pageName == SINGLE_PAGE
        ? process.env.REACT_APP_BUCKET_PRO_FOLDER
        : process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI;
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        if (isEdit && id >= -1) {
          const allWebIds = formData?.allItem?.map((ele) => {
            return {
              image: ele.image?.split(`${imagePathToDelete}/`).pop(),
              title: ele.title,
              videoLink: ele.videoLink,
              webId: ele.webId,
              itemOrder: ele.itemOrder,
            };
          });
          let addFromData = {
            ...formData,
            webIds: allWebIds,
          };
          delete addFromData.allItem;
          delete addFromData.imageNo;
          delete addFromData.image;
          if (formData?.webIds.length <= 0 || allWebIds.length <= 0) {
            addFromData["removeAllWebIs"] = true;
            delete addFromData.webIds;
          }
          const { data } = await Edit_cloneCategory_List(
            addFromData,
            id,
            pageName
          );
          if (data.status === 200) {
            getCloneCategoryList();
            emptyData();
            TostMessage({ message: data, type: "success" });
          }
        } else {
          const allWebIds = formData?.allItem?.map((ele) => {
            return {
              image: ele.image?.split(`${imagePathToDelete}/`).pop(),
              title: ele.title,
              videoLink: ele.videoLink,
              webId: ele.webId,
              itemOrder: ele.itemOrder,
            };
          });
          let addFromData = {
            ...formData,
            webIds: allWebIds,
          };
          delete addFromData.allItem;
          delete addFromData.imageNo;
          delete addFromData.image;
          if (formData?.webIds.length <= 0 || allWebIds.length <= 0) {
            delete addFromData.webIds;
          }
          const { data } = await Add_cloneCategory_List(addFromData, pageName);
          if (data.status === 200) {
            getCloneCategoryList();
            emptyData();
            TostMessage({ message: data, type: "success" });
          }
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  /**
   * delete category
   * @param {id} cloneCategoryId
   */
  const handleDelete = async (cloneCategoryId) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await Delete_cloneCategory_List(
          cloneCategoryId,
          pageName
        );
        if (data.status === 200) {
          getCloneCategoryList();
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const emptyData = () => {
    setFormData({
      name: "",
      webIds: [],
      allItem: [],
    });
    setAddModel(false);
    setIsEdit(false);
    setId("");
  };
  return (
    <Fragment>
      <Breadcrumbs parent="Clone Category" />
      <TopLoadingBar progress={progress} setProgress={setProgress} />
      {addModel && (
        <CloneCategoryAddPopup
          handleSubmit={handleSubmit}
          simpleValidator={simpleValidator}
          emptyData={emptyData}
          imageUpload={ImageUploadImages}
          pageName={pageName}
        />
      )}

      <CloneCategoryItem
        sorting={sorting}
        switch_onChange_handle={switch_onChange_handle}
        handleDelete={handleDelete}
        pageName={pageName}
      />
    </Fragment>
  );
};

export default CloneCategory;
