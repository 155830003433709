import axios from "axios";
import {
  GET_CAREER,
  GET_CAREER_DETAILS,
  POST_CAREER,
  PUT_CAREER_ORDER,
  PUT_STATUS_CAREER,
} from "..";

/**
 * get career
 * @param {Parameters} paramObj
 * @returns
 */
export const getCareer = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_CAREER}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * enable-disable career
 * @param {body} body
 * @returns
 */
export const updateStatus = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_STATUS_CAREER}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * create career
 * @param {body} body
 * @returns
 */
export const postCareer = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(`${process.env.REACT_APP_API_URL}${POST_CAREER}`, body, {
    headers: { Authorization: jwt_token },
  });
};

/**
 * update career
 * @param {body} body
 * @returns
 */
export const putUpdateCareer = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(`${process.env.REACT_APP_API_URL}${POST_CAREER}`, body, {
    headers: { Authorization: jwt_token },
    params: { careerId: body.careerId },
  });
};

/**
 * delete career
 * @param {id} careerId
 * @returns
 */
export const deleteCareer = (careerId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${POST_CAREER}`, {
    headers: { Authorization: jwt_token },
    params: { careerId },
  });
};

/**
 * get career
 * @param {id} careerId
 * @returns
 */
export const getCareerDetails = (careerId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_CAREER_DETAILS}`, {
    headers: { Authorization: jwt_token },
    params: { careerId },
  });
};

/**
 * short order career
 * @param {body} updateOrder
 * @returns
 */
export const sort_career = (updateOrder) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_CAREER_ORDER}`,
    { updateOrder: updateOrder },
    {
      headers: { Authorization: jwt_token },
    }
  );
};
