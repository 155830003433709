import React, { useState } from "react";
import OurTeamContext from "./OurTeamContext";
import { randomStr } from "../../../utils/helper";

const OurTeamState = (props) => {
  const [apiData, setApiData] = useState({ data: [], isLoading: false });
  const [apiDataOurTeam, setApiDataOurTeam] = useState({
    isLoading: false,
    data: [],
  });
  const [skill, setSkill] = useState([
    { id: randomStr(2), skill: "", parentageOfSkill: "" },
  ]);
  const [fromData, setFormData] = useState({
    title: "",
    subTitle: "",
    skill: "",
    image: "",
    description: "",
    webId: localStorage.getItem("webId"),
  });

  const [formDataOurTeam, setFormDataOurData] = useState({
    title: "",
    headLine: "",
    image: "",
    webId: localStorage.getItem("webId"),
  });
  const [id, setId] = useState("");
  const [teamMemberId, setTeamMemberId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [filter, setFilter] = useState("");
  return (
    <OurTeamContext.Provider
      value={{
        apiData,
        setApiData,
        apiDataOurTeam,
        setApiDataOurTeam,
        fromData,
        setFormData,
        formDataOurTeam,
        setFormDataOurData,
        id,
        setId,
        isEdit,
        setIsEdit,
        filter,
        setFilter,
        skill,
        teamMemberId,
        setTeamMemberId,
        setSkill,
      }}
    >
      {props.children}
    </OurTeamContext.Provider>
  );
};

export default OurTeamState;
