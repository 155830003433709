import React, { useState } from "react";
import { TopHeaderStyle, footerStyle1, footerStyle2 } from "../../constant";
import FooterContext from "./FooterContext";
import { randomStr } from "../../../utils/helper";

const FooterState = (props) => {
  const [formData, setFormData] = useState({
    title: "",
    contentMapId: "",
    topHeaderStyle: TopHeaderStyle,
    footer1Style: footerStyle1,
    footer2Style: footerStyle2,
  });
  const [formDataChild, setFormDataChild] = useState({
    title: "",
    webId: localStorage.getItem("webId"),
    navMapId: "",
  });
  const [isSectionImage, setIsSectionImage] = useState("");
  const [customeIndex, setCustomeIndex] = useState("");
  /**top header form data */
  const [topHeaderForm, setTopHeaderForm] = useState({
    phone: "",
    email: "",
    image: "",
    socialMedia: [{ name: "", value: "", id: randomStr(2) }],
  });

  /**footer one form data */
  const [footerOneForm, setFooterOneForm] = useState({
    content: "",
    address: "",
    locationLink: "",
    phone: "",
    email: "",
    image: "",
    socialMedia: [{ name: "", value: "", id: randomStr(2) }],
  });

  /**footer Two form data */
  const [footerTwoForm, setFooterTwoForm] = useState({
    phone1: "",
    phone2: "",
    email1: "",
    email2: "",
    address: "",
  });

  const [collId, setCollId] = useState();
  const [settingFormData, setSettingFormData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [isParent, setIsParent] = useState(false);
  const [filter, setFilter] = useState("");
  const [navMapId, setNavMapId] = useState("");
  const [id, setId] = useState("");
  const [nameOfStyle, setNameOfStyle] = useState("");
  const [theme, setTheme] = useState(1);
  const [imgShow, setImgShow] = useState(false);
  return (
    <FooterContext.Provider
      value={{
        isEdit,
        setIsEdit,
        isParent,
        setIsParent,
        filter,
        setFilter,
        settingFormData,
        setSettingFormData,
        formDataChild,
        setFormDataChild,
        formData,
        setFormData,
        collId,
        setCollId,
        navMapId,
        setNavMapId,
        id,
        setId,
        nameOfStyle,
        setNameOfStyle,
        topHeaderForm,
        setTopHeaderForm,
        footerOneForm,
        setFooterOneForm,
        footerTwoForm,
        setFooterTwoForm,
        customeIndex,
        setCustomeIndex,
        isSectionImage,
        setIsSectionImage,
        theme,
        setTheme,
        imgShow,
        setImgShow,
      }}
    >
      {props.children}
    </FooterContext.Provider>
  );
};

export default FooterState;
