// dashbaord
import Ecommerce from "../components/dashboard/ecommerce";
import PrivillageManager from "../components/PrivillageManager/PrivillageManager";
import ModualManager from "../components/ModualManager/ModualManager";
import PersonalPage from "../components/PersonalPage/PersonalPage";
import ProfileComponent from "../components/ProfileComponent";
import InquiryPage from "../components/InquiryPage/InquiryPage";
import PersonalPageShow from "../components/PersonalPage/PersonalPageShow";
import AddPageItem from "../components/PageItem/AddPageItem";
import Organization from "../components/Organization/Organization";
import BrokerPage from "../components/BrokerPage/BrokerPage";
import BrokerComponent from "../components/BrokerComponent";
import BrokerAddmodal from "../components/BrokerPageItem/AddBrokerPageItem";
import BrokerPageShow from "../components/BrokerPage/BrokerPageShow";
import PageItemMaster from "../components/PageItemMaster/PageItemMaster";
import ByteCardBuilder from "../components ByteCard/components/ByteCardBuilder/ByteCardBuilder";
import SettingMiniWeb from "../components/SettingsMiniWeb/SettingMiniWeb";
import WebInquiryPage from "../components ByteCard/components/InquiryPage/InquiryPage";
import WebOrderPage from "../components ByteCard/components/OrderPage/InquiryPage";
import BrokerIncentive from "../components/BrokerIncentive/BrokerIncentive";
import MultipageByteCardBuilder from "../components ByteCard/components/MultipageByteCardBuilder/MultipageByteCardBuilder";
import SettingMultiWeb from "../components/SettingsMultiWeb/SettingMultiWeb";
import PlanMaster from "../components/PlanMaster/PlanMaster";
import Theme from "../components/ThemeMaster/Theme";
import ThemeMap from "../components/ThemeMaster/ThemeMap";
import CloneCategory from "../components/SingleWeb/CloneCategory/CloneCategory";
import WebCategoryMap from "../components/SingleWeb/WebCategoryMap/WebCategoryMap";
import OrgPlan from "../components/OrgPlan/OrgPlan";
import FontList from "../components/FontList/FontList";

export const routes = [
  {
    path: `${process.env.PUBLIC_URL}/:hwId/dashboard/ecommerce/:layout/`,
    Component: <Ecommerce />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/PersonalPage/PersonalPage/:layout/`,
    Component: <PersonalPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/BrokerPage/BrokerPageShow/:layout/`,
    Component: <BrokerPageShow />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/ProfileComponent/:layout/`,
    Component: <ProfileComponent />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/PageItem/AddPageItem/:layout/`,
    Component: <AddPageItem />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/PersonalPage/PersonalPageShow/:layout/`,
    Component: <PersonalPageShow />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/InquiryPage/InquiryPage/:layout/`,
    Component: <InquiryPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/Organization/Organization/:layout/`,
    Component: <Organization />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/PlanMaster/PlanMaster/:layout/`,
    Component: <PlanMaster />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/OrgPlan/OrgPlan/:layout/`,
    Component: <OrgPlan />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/:orgId/Setting/:layout/`,
    Component: <SettingMiniWeb />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/:orgId/SettingMulti/:layout/`,
    Component: <SettingMultiWeb />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/PageItemMaster/PageItemMaster/:layout/`,
    Component: <PageItemMaster />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/BrokerPage/BrokerPage/:layout/`,
    Component: <BrokerPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/BrokerPageItem/AddBrokerPageItem/:layout/`,
    Component: <BrokerAddmodal />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/BrokerComponent/:layout/`,
    Component: <BrokerComponent />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/PrivillageManager/PrivillageManager/:layout/`,
    Component: <PrivillageManager />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/ModualManager/ModualManager/:layout/`,
    Component: <ModualManager />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/Theme/Theme/:layout/`,
    Component: <Theme />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/ThemeMap/ThemeMap/:layout/`,
    Component: <ThemeMap />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/:pageName/WebCategoryMap/:layout/`,
    Component: <WebCategoryMap />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/:pageName/CloneCategory/:layout/`,
    Component: <CloneCategory />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/:pageName/WebCategoryMap/:layout/`,
    Component: <WebCategoryMap />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/:pageName/FontList/:layout/`,
    Component: <FontList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/:pageName/CloneCategory/:layout/`,
    Component: <CloneCategory />,
  },
  //byte card routes
  {
    path: `${process.env.PUBLIC_URL}/:hwId/ByteCardBuilder/:layout/`,
    Component: <ByteCardBuilder />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/multipage-ByteCardBuilder/:layout/`,
    Component: <MultipageByteCardBuilder />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/webInquiry/:layout/`,
    Component: <WebInquiryPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/webOrder/:layout/`,
    Component: <WebOrderPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/BrokerIncentive/BrokerIncentive/:layout/`,
    Component: <BrokerIncentive />,
  },
  // {
  //   path: `${process.env.PUBLIC_URL}/:hwId/MenuMaster/:QpiId/:layout/`,
  //   Component: (
  //     <Route element={<MenuMaster />}>
  //       <Route
  //         path={`${process.env.PUBLIC_URL}/:hwId/MenuMaster/:QpiId/Category/:layout/`}
  //         element={<Category />}
  //       />
  //       <Route
  //         path={`${process.env.PUBLIC_URL}/:hwId/MenuMaster/:QpiId/Products/:layout/`}
  //         element={<h1>Products</h1>}
  //       />
  //       <Route
  //         path={`${process.env.PUBLIC_URL}/:hwId/MenuMaster/:QpiId/AddedOnProduct/:layout/`}
  //         element={<h1>Added On Product</h1>}
  //       />
  //       <Route
  //         path={`${process.env.PUBLIC_URL}/:hwId/MenuMaster/:QpiId/Tags/:layout/`}
  //         element={<h1>Tags</h1>}
  //       />
  //     </Route>
  //   ),
  // },
];
