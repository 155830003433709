import React, { useState } from "react";
import ByteMiniWebDashboardContext from "./ByteMiniWeb_Dashboard_Context";

const ByteMiniWebDashboardState = (props) => {
  const [apiData, setApiData] = useState({ data: [], isLoading: false });
  return (
    <ByteMiniWebDashboardContext.Provider value={{ apiData, setApiData }}>
      {props.children}
    </ByteMiniWebDashboardContext.Provider>
  );
};

export default ByteMiniWebDashboardState;
