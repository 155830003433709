import React, { useContext } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import GetWhatsAppLinkContext from "../../../context/GetWhatsAppLinkContext/GetWhatsAppLinkContext";
import { THEME_UPDATE_MESSAGE, ThemeNo } from "../../../../constant";
import { GetWhatsAppLinkOneReset } from "./GetWhatsAppLinkStyleFunction";
import { putUpdateGetWhatsAppLink } from "../apis/GetWhatsAppLinkApi/GetWhatsAppLinkApi";
import { TostMessage } from "../../../utils";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import GetWhatsAppLinkTheme1 from "../ScreenShorts/GetWhatsAppLink/GetWhatsAppLinkTheme1.png";
import ThemeImageShow from "../../../common/ThemeImageShow";
import GetWhatsAppLinkThemeOne from "./GetWhatsAppLinkThemeOne";

const SettingGetWhatsAppParentPopup = (props) => {
  const {
    isEdit,
    setSettingFormData,
    setBoxSection,
    theme,
    setTheme,
    id,
    setIsBackgroundColor,
    imgShow,
    setImgShow,
  } = useContext(GetWhatsAppLinkContext);
  const { setLoaderShow } = useContext(LoaderContext);
  const { handleSubmitSetting, handleChangeSettings } = props;

  const handleTheme = async (e) => {
    setLoaderShow(true);
    const { value } = e.target;
    setTheme(value);
    try {
      let formData = {
        theme: value,
        blogCategoryId: id,
      };
      const { data } = await putUpdateGetWhatsAppLink(formData);
      if (data.status === 200) {
        await handleChangeSettings(id, true);
        TostMessage({
          message: { message: THEME_UPDATE_MESSAGE },
          type: "success",
        });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    } finally {
      setLoaderShow(false);
    }
  };

  const resetHandler = () => {
    let setStyle;
    if (theme === ThemeNo.ThemeOne) {
      setStyle = GetWhatsAppLinkOneReset;
    } else {
      setStyle = GetWhatsAppLinkOneReset;
    }
    setSettingFormData({ ...setStyle });
    setBoxSection(false);
    setIsBackgroundColor(true);
  };
  const handleClose = () => {
    setImgShow(false);
  };
  const themeImg = {
    1: GetWhatsAppLinkTheme1,
  };

  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="12">
            <div className="mt-4 d-flex flex-column justify-content-center position-relative">
              <div
                className="d-flex justify-content-between position-sticky"
                style={{ top: "87px", background: "white", zIndex: 999 }}
              >
                <h6>{isEdit ? "Edit" : "Add New"}</h6>
                <div className="d-flex gap-2">
                  <Button onClick={resetHandler}>Reset</Button>
                  <Button onClick={() => handleSubmitSetting(true)}>
                    Restore
                  </Button>
                  <Button
                    onClick={() => handleSubmitSetting(null, false)}
                    color="success"
                  >
                    Save & View
                  </Button>
                </div>
              </div>
              <Row>
                <Col>
                  <label htmlFor="">Theme</label>
                  <select
                    name="theme"
                    id="font-weight"
                    className="form-select"
                    value={theme}
                    onChange={(e) => handleTheme(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value={1}>Theme 1</option>
                  </select>
                </Col>
              </Row>
              {!imgShow && (
                <div>
                  <Button
                    onClick={() => setImgShow(true)}
                    color="primary"
                    className="mt-2"
                  >
                    Show Theme Image
                  </Button>
                </div>
              )}
              {imgShow && (
                <ThemeImageShow
                  themeImg={themeImg[theme]}
                  handleClose={handleClose}
                />
              )}
              {+theme === ThemeNo.ThemeOne && (
                <GetWhatsAppLinkThemeOne
                  handleSubmitSetting={handleSubmitSetting}
                />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SettingGetWhatsAppParentPopup;
