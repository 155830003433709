import React, { useContext } from "react";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import ProductBuyContext from "../../../context/ProductBuyContext/ProductBuyContext";
import { Border } from "../../../../constant";
import CommonColor from "../../../common/CommonColor";
import CustomStyle from "../../../common/CustomStyle";

const SettingCheckOutStyle = (props) => {
  const { handleSubmitSetting } = props;
  const { isEdit, settingFormData, setSettingFormData } =
    useContext(ProductBuyContext);

  const handleChangeCheckOutSection = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".step-checkout-section"]: {
          ...prev[".step-checkout-section"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeCheckOutStepTTL = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".step-checkout-div .steps .step-ttl"]: {
          ...prev[".step-checkout-div .steps .step-ttl"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeCheckOutBtnLogin = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".step-checkout-div .steps .btn-login"]: {
          ...prev[".step-checkout-div .steps .btn-login"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeCustomStyle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const resetHandler = () => {
    setSettingFormData({
      ".step-checkout-section": {
        padding: "100px 0",
        "background-color": { r: 255, g: 255, b: 255, a: 100 },
      },
      ".step-checkout-div .steps .icondiv": {
        "background-color": { r: 255, g: 255, b: 255, a: 100 },
        color: { r: 153, g: 153, b: 153, a: 100 },
      },
      ".step-checkout-div .steps.activestep .icondiv, .step-checkout-div .steps.donestep .icondiv":
        {
          color: { r: 255, g: 255, b: 255, a: 100 },
          "background-color": { r: 59, g: 183, b: 126, a: 100 },
        },
      ".step-checkout-div .steps .step-ttl": {
        color: { r: 0, g: 0, b: 0, a: 100 },
        "font-size": "22px",
      },
      ".step-checkout-div .steps .btn-login": {
        "background-color": "transparent",
        border: "1px solid { r: 59, g: 183, b: 126, a: 100 }",
        borderPx: "1px",
        borderThik: "solid",
        borderColor: { r: 59, g: 183, b: 126, a: 100 },
        "font-size": "14px",
        color: { r: 59, g: 183, b: 126, a: 100 },
        padding: "6px 20px",
        p1: "6px",
        p2: "20px",
      },
      ".step-checkout-div .steps .btn-green": {
        "background-color": { r: 59, g: 183, b: 126, a: 100 },
        color: { r: 255, g: 255, b: 255, a: 100 },
      },
      ".step-checkout-div .steps .addresslist p": {
        color: { r: 0, g: 0, b: 0, a: 100 },
        "font-size": "16px",
        "font-weight": "normal",
      },
      ".step-checkout-div .steps .addresslist .btn": {
        color: { r: 255, g: 255, b: 255, a: 100 },
        "background-color": { r: 59, g: 183, b: 126, a: 100 },
      },
      ".step-checkout-total .table-responsive .text-brand": {
        color: { r: 59, g: 183, b: 126, a: 100 },
      },
    });
  };

  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="12">
            <div className="mt-4 d-flex flex-column justify-content-center ">
              <div className="d-flex justify-content-between">
                <h6>{isEdit ? "Edit" : "Add New"}</h6>
                <div className="d-flex gap-2">
                  <Button onClick={resetHandler}>Reset</Button>
                  <Button onClick={() => handleSubmitSetting(true)}>
                    Restore
                  </Button>
                  <Button
                    onClick={() => handleSubmitSetting(false, false)}
                    color="success"
                  >
                    Save & View
                  </Button>
                </div>
              </div>
              <Row className="mt-4">
                <h4 className="mb-3">Make change in Main section</h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding (Default : 100)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="padding"
                    type="number"
                    placeholder="Padding"
                    value={settingFormData?.[".step-checkout-section"]?.[
                      "padding"
                    ]
                      ?.split(" ")[0]
                      ?.replace(/px/g, "")}
                    onChange={(e) => handleChangeCheckOutSection(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[".step-checkout-section"]?.[
                        "background-color"
                      ]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".step-checkout-section"}
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">step-checkout-div icondiv</h4>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[".step-checkout-div .steps .icondiv"]?.[
                        "background-color"
                      ]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".step-checkout-div .steps .icondiv"}
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[".step-checkout-div .steps .icondiv"]?.[
                        "color"
                      ]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".step-checkout-div .steps .icondiv"}
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">step-checkout-div activestap icondiv </h4>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".step-checkout-div .steps.activestep .icondiv, .step-checkout-div .steps.donestep .icondiv"
                      ]?.["background-color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={
                      ".step-checkout-div .steps.activestep .icondiv, .step-checkout-div .steps.donestep .icondiv"
                    }
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".step-checkout-div .steps.activestep .icondiv, .step-checkout-div .steps.donestep .icondiv"
                      ]?.["color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={
                      ".step-checkout-div .steps.activestep .icondiv, .step-checkout-div .steps.donestep .icondiv"
                    }
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">step-checkout-div title color</h4>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".step-checkout-div .steps .step-ttl"
                      ]?.["color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".step-checkout-div .steps .step-ttl"}
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Size (Default : 22)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="font-size"
                    type="number"
                    placeholder="Font Size"
                    value={settingFormData?.[
                      ".step-checkout-div .steps .step-ttl"
                    ]?.["font-size"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeCheckOutStepTTL(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">step-checkout-div btn-login</h4>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".step-checkout-div .steps .btn-login"
                      ]?.["background-color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".step-checkout-div .steps .btn-login"}
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border (Default : 1 solid green)"}
                  </Label>
                  <div className="d-flex">
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="borderPx"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[
                          ".step-checkout-div .steps .btn-login"
                        ]?.["borderPx"]?.replace(/px/g, "")}
                        onChange={(e) => handleChangeCheckOutBtnLogin(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <select
                        name="borderThik"
                        id="borderThik"
                        className="form-control"
                        value={
                          settingFormData?.[
                            ".step-checkout-div .steps .btn-login"
                          ]?.["borderThik"]
                        }
                        onChange={(e) => handleChangeCheckOutBtnLogin(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      >
                        <option value="">--select--</option>
                        {Border.map((elem) => {
                          return (
                            <option value={elem.border}>{elem.border}</option>
                          );
                        })}
                      </select>
                    </div>
                    <div>
                      <CommonColor
                        color={
                          settingFormData?.[
                            ".step-checkout-div .steps .btn-login"
                          ]?.["borderColor"]
                        }
                        setSettingFormData={setSettingFormData}
                        className={".step-checkout-div .steps .btn-login"}
                        classProperty={"borderColor"}
                        label={"Select Color"}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".step-checkout-div .steps .btn-login"
                      ]?.["color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".step-checkout-div .steps .btn-login"}
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Size (Default : 14)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="font-size"
                    type="number"
                    placeholder="Font Size"
                    value={settingFormData?.[
                      ".step-checkout-div .steps .btn-login"
                    ]?.["font-size"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeCheckOutBtnLogin(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding (Default : 6 - 20)"}
                  </Label>
                  <div className="d-flex justify-content-between">
                    <Input
                      className="form-control"
                      name="p1"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        ".step-checkout-div .steps .btn-login"
                      ]?.["p1"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangeCheckOutBtnLogin(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                    <Input
                      className="form-control"
                      name="p2"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        ".step-checkout-div .steps .btn-login"
                      ]?.["p2"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangeCheckOutBtnLogin(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                  </div>
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">step-checkout-div btn-green</h4>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".step-checkout-div .steps .btn-green"
                      ]?.["background-color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".step-checkout-div .steps .btn-green"}
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".step-checkout-div .steps .btn-green"
                      ]?.["color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".step-checkout-div .steps .btn-green"}
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">step-checkout-div addresslist btn</h4>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".step-checkout-div .steps .addresslist .btn"
                      ]?.["background-color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".step-checkout-div .steps .addresslist .btn"}
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".step-checkout-div .steps .addresslist .btn"
                      ]?.["color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".step-checkout-div .steps .addresslist .btn"}
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">step-checkout-total text-brand</h4>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".step-checkout-total .table-responsive .text-brand"
                      ]?.["color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={
                      ".step-checkout-total .table-responsive .text-brand"
                    }
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
              </Row>{" "}
              <CustomStyle
                handleChange={handleChangeCustomStyle}
                value={settingFormData?.custom}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SettingCheckOutStyle;
