import * as React from "react";
import { useState } from "react";
import ModualManagerContext from "./ModualManagerContext";

const ModualManagerState = (props) => {
  const [formvalue, setformvalue] = useState([]); //API -->
  const [dropDownData, setDropDownData] = useState([]); //API -->

  const [name, setName] = useState("");
  const [perentId, setPerentId] = useState("");

  const [viewName, setViewName] = useState("");
  const [perent, setPerent] = useState("");
  const [perentName, setPerentName] = useState("");
  const [modualAccess, setModualAccess] = useState([]);
  const [rolemodualAccess, setRoleModualAccess] = useState([]);

  const [uuid, setUuid] = useState("");

  const [ahoeDataModel, setahoeDataModel] = useState(false); //SHOW ORG DETAILS MODEL -->
  const [modal, setModal] = useState(false); //ADD DATA MODEL -->
  const [addbel, setAddBel] = useState(false); //ADD ORG BEL -->
  const [pageNumber, setpageNumber] = useState(1); //PAGINATION -->
  const [recordsPerPage, setRecordsPerPage] = useState(10); //PAGINATION -->
  const [sortOrder, setSortOrder] = useState(""); //SORTING -->
  const [sortBy, setSortBy] = useState("createdAt"); //SORTING -->
  const [filter, setFilter] = useState(""); //SEARCHING FILTER -->
  const [isHovering, setIsHovering] = useState(false); //HOVWER SORTING -->
  const [isSwitchOn, setIsSwitchOn] = useState(false); //TOGGLE SWITCH -->
  const [url, setUrl] = useState("");
  const [editIndex, seteditIndex] = useState(-1); //EDIT -->
  const [totalRecord, setTotalRecord] = useState(); //TOTAL RECORD -->
  const [isAddHandle, setIsAddHandle] = useState(true); //PASS HIDE OR UNHIDE -->
  const [progress, setProgress] = useState(0);
  const [togglesubmit, setToggle] = useState(false);
  const [val, setVal] = useState("");

  return (
    <>
      <ModualManagerContext.Provider
        value={{
          formvalue,
          setformvalue,
          setName,
          name,
          perentId,
          setPerentId,

          ahoeDataModel,
          setahoeDataModel,
          modal,
          setModal,
          addbel,
          setAddBel,
          pageNumber,
          setpageNumber,
          recordsPerPage,
          setRecordsPerPage,
          sortOrder,
          setSortOrder,
          sortBy,
          setSortBy,
          filter,
          setFilter,
          isHovering,
          setIsHovering,
          isSwitchOn,
          setIsSwitchOn,
          url,
          setUrl,
          editIndex,
          seteditIndex,
          totalRecord,
          setTotalRecord,
          isAddHandle,
          setIsAddHandle,
          progress,
          setProgress,
          togglesubmit,
          setToggle,
          uuid,
          setUuid,
          val,
          setVal,

          viewName,
          setViewName,
          perent,
          setPerent,
          modualAccess,
          setModualAccess,
          rolemodualAccess,
          setRoleModualAccess,
          dropDownData,
          setDropDownData,
          perentName,
          setPerentName,
        }}
      >
        {props.children}
      </ModualManagerContext.Provider>
    </>
  );
};

export default ModualManagerState;
