import React, { useContext, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Form,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { ORG_ADD } from "../../constant";
import SettingMiniWebContext from "../../context/SettingMiniWebContext/SettingMiniWebContext";
import Select from "react-select";
import { Zoom, toast } from "react-toastify";
import { CreateWebConfigPage } from "../../api/SupperAdminOrganizationApi/Organization";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import { useParams } from "react-router";

const SettingMiniWebAddWebConfigPage = ({}) => {
  const {
    closeModel,
    setFormDataWebConfig,
    WebContentMap_List,
    modalConfig,
    WebNavBarMaster_List,
    formDataWebConfig,
    WebNavBarMap_List,

    formvalue,
    formvalueNav,
    WebConfigPage_List,
  } = useContext(SettingMiniWebContext);
  const { loaderShow, setLoaderShow } = useContext(LoaderContext);
  let { orgId } = useParams();

  useEffect(() => {
    WebNavBarMaster_List(orgId);
  }, []);
  useEffect(() => {
    WebContentMap_List(orgId, true);
    WebNavBarMap_List({ orgId: orgId, showAll: true });
  }, []);

  const handleChange = (value) => {
    setFormDataWebConfig((prev) => {
      return {
        ...prev,
        orgId,
        configs: value,
      };
    });
  };
  const handleChangeNav = (value) => {
    setFormDataWebConfig((prev) => {
      return {
        ...prev,
        navBar: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (togglesubmit === true) {
    //   setLoaderShow(!loaderShow);
    //   let formdata = {
    //     pageLimit,
    //     isBrokerAllowed: check,
    //     brokerLimit: +broker,
    //     mainPageName,
    //     subPageName,
    //   };
    //   try {
    //     const { data } = await EditPageLimit(formdata, orgUuid);

    //     if (data.status === 200) {
    //       toast.success(data.message, {
    //         autoClose: 1000,
    //         transition: Zoom,
    //       });
    //       WebContentMap_List();
    //       closeModel();
    //     } else {
    //       setPageLimit(pageLimit);
    //     }
    //   } catch (error) {
    //     toast.error(error.response.data.message, {
    //       autoClose: 1000,
    //       transition: Zoom,
    //     });
    //     setLoaderShow(false);
    //   }
    // } else {
    setLoaderShow(!loaderShow);
    let formdata = {
      orgId: formDataWebConfig?.orgId,
      configs:
        formDataWebConfig?.configs?.length &&
        formDataWebConfig?.configs?.map((elem) => {
          return {
            contentMapId: elem?.value,
            navMapId: formDataWebConfig?.navBar?.value,
          };
        }),
    };
    try {
      const { data } = await CreateWebConfigPage(formdata);

      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        WebConfigPage_List(orgId);
        closeModel();
      }
    } catch (error) {
      setLoaderShow(false);
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
    // }
  };
  return (
    <Modal size="lg" isOpen={modalConfig}>
      {/* <LoaderResponse /> */}
      <ModalHeader toggle={() => closeModel()}>
        <span>Add Navbar Map</span>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form onSubmit={(e) => handleSubmit(e)}>
                  <>
                    <Row>
                      <Col md="12 mb-4">
                        <Label htmlFor="validationCustom14">{"Navbar"}</Label>
                        <Select
                          aria-labelledby="aria-label"
                          inputId="aria-example-input"
                          defaultValue={formDataWebConfig.navBar}
                          options={formvalueNav.map((elem, i) => {
                            return {
                              value: elem.navMapId,
                              label: elem?.navbarDetail?.label,
                            };
                          })}
                          onChange={(opt) => {
                            handleChangeNav(opt);
                          }}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid Admin Name."}
                        </div>
                      </Col>
                      <Col md="12 mb-4">
                        <Label htmlFor="validationCustom14">
                          {"Content Map"}
                        </Label>
                        <Select
                          aria-labelledby="aria-label"
                          inputId="aria-example-input"
                          defaultValue={formDataWebConfig.configs}
                          options={formvalue.map((elem, i) => {
                            return {
                              value: elem?.contentMapId,
                              label: elem?.contentDetail?.name,
                            };
                          })}
                          isMulti
                          onChange={(opt) => {
                            handleChange(opt);
                          }}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid Admin Name."}
                        </div>
                      </Col>
                    </Row>
                  </>
                  <Row style={{ paddingTop: "30px" }}>
                    <button
                      className="btn btn-primary"
                      type="submit"
                      value="Submit"
                      // onClick={createhwId}
                    >
                      {ORG_ADD.SUBMIT}
                    </button>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default SettingMiniWebAddWebConfigPage;
