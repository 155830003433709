import React, { useContext, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Form,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { ORG_ADD } from "../../constant";
import SettingMiniWebContext from "../../context/SettingMiniWebContext/SettingMiniWebContext";
import Select from "react-select";
import { Zoom, toast } from "react-toastify";
import { CreateWebContentMaster } from "../../api/SupperAdminOrganizationApi/Organization";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import { useParams } from "react-router";

const SettingMiniWebAddContentWeb = () => {
  const {
    modal,
    closeModel,
    formDataWebContent,
    setFormDataWebContent,
    WebContentMaster_List,
    formvalueWebContentMaster,
    WebContentMap_List,
  } = useContext(SettingMiniWebContext);
  const { loaderShow, setLoaderShow } = useContext(LoaderContext);

  useEffect(() => {
    WebContentMaster_List();
  }, []);
  let { orgId } = useParams();
  const handleChange = (value) => {
    setFormDataWebContent((prev) => {
      return {
        ...prev,
        orgId,
        contents: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (togglesubmit === true) {
    //   setLoaderShow(!loaderShow);
    //   let formdata = {
    //     pageLimit,
    //     isBrokerAllowed: check,
    //     brokerLimit: +broker,
    //     mainPageName,
    //     subPageName,
    //   };
    //   try {
    //     const { data } = await EditPageLimit(formdata, orgUuid);

    //     if (data.status === 200) {
    //       toast.success(data.message, {
    //         autoClose: 1000,
    //         transition: Zoom,
    //       });
    //       WebContentMap_List();
    //       closeModel();
    //     } else {
    //       setPageLimit(pageLimit);
    //     }
    //   } catch (error) {
    //     toast.error(error.response.data.message, {
    //       autoClose: 1000,
    //       transition: Zoom,
    //     });
    //     setLoaderShow(false);
    //   }
    // } else {
    setLoaderShow(!loaderShow);
    let formdata = {
      ...formDataWebContent,
      contents: formDataWebContent.contents.map((elem) => {
        return {
          contentId: elem.value,
        };
      }),
    };
    try {
      const { data } = await CreateWebContentMaster(formdata);

      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        WebContentMap_List(orgId);
        closeModel();
      }
    } catch (error) {
      setLoaderShow(false);
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
    // }
  };
  return (
    <Modal size="lg" isOpen={modal}>
      {/* <LoaderResponse /> */}
      <ModalHeader toggle={() => closeModel()}>
        <span>Add Content Map</span>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form onSubmit={(e) => handleSubmit(e)}>
                  <>
                    <Row>
                      <Col md="12 mb-4">
                        <Label htmlFor="validationCustom14">
                          {ORG_ADD.ORG_NAME}
                        </Label>
                        <Select
                          aria-labelledby="aria-label"
                          inputId="aria-example-input"
                          defaultValue={formDataWebContent.contents}
                          options={formvalueWebContentMaster.map((elem, i) => {
                            return {
                              value: elem.contentId,
                              label: elem.name,
                            };
                          })}
                          isMulti
                          onChange={(opt) => {
                            handleChange(opt);
                          }}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid Admin Name."}
                        </div>
                      </Col>
                    </Row>
                  </>
                  <Row style={{ paddingTop: "30px" }}>
                    <button
                      className="btn btn-primary"
                      type="submit"
                      value="Submit"
                      // onClick={createhwId}
                    >
                      {ORG_ADD.SUBMIT}
                    </button>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default SettingMiniWebAddContentWeb;
