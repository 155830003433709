import React, { useState } from "react";
import SliderMultipageContext from "./SliderMultipageContext";
import { randomStr } from "../../../utils/helper";

const SliderMultipageState = (props) => {
  const [apiData, setApiData] = useState({ data: [], isLoading: false });
  const [apiDataOurTeam, setApiDataOurTeam] = useState({
    isLoading: false,
    data: [],
  });
  const [skill, setSkill] = useState([
    { id: randomStr(2), skill: "", parentageOfSkill: "" },
  ]);
  const [isBackgroundColor, setIsBackgroundColor] = useState(false);
  const [collId, setCollId] = useState();
  const [fromData, setFormData] = useState({
    title: "",
    navMapId: "",
    isTitleShow: true,
    webId: localStorage.getItem("webId"),
    image: "slider",
    style: {
      ".top-slider": {
        "background-color": "rgb(255 255 255 / 100)",
      },
      ".top-slider .carousel-item:before": {
        // "background-color": "rgb(0 0 0 / 0.8)",
      },
      // ".top-slider .slider-content": {
      //   padding: "200px 0",
      //   "max-width": "500px",
      // },
      ".top-slider .slider-content .slide-title": {
        color: "rgb(255 255 255 / 100)",
        "font-size": "40px",
        "font-weight": "600",
        "margin-bottom": "20px",
      },
      ".top-slider .slider-content .slide-desq": {
        color: "rgb(255 255 255 / 100)",
        "font-size": "16px",
        "font-weight": "500",
        "margin-bottom": "20px",
      },
      ".top-slider .slider-content .btn": {
        color: "rgb(255 255 255 / 100)",
        border: "2px solid rgb(255 73 124 / 100)",
        borderColor: "rgb(255 73 124 / 100)",
        "font-size": "16px",
        "font-weight": "600",
        padding: "8px 25px",
        margin: "10px 10px 10px 0",
      },
      ".top-slider .slider-content .slide-btn1": {
        "background-color": "rgb(255 73 124 / 100)",
      },
      ".top-slider .carousel-control-next-icon, .top-slider .carousel-control-prev-icon":
        {
          "background-color": "rgb(0 0 0 / 0)",
          "border-radius": "0px",
        },
    },
  });

  const [formDataChild, setFormDataChild] = useState({
    image: "",
    title: "",
    description: "",
    buttonCaption: "",
    isTitleShow: true,
    buttonLink: "",
    order: 1,
    webId: localStorage.getItem("webId"),
    navMapId: "",
  });

  const [settingFormData, setSettingFormData] = useState({
    ".top-slider": {
      "background-color": "rgb(255 255 255 / 100)",
    },
    ".top-slider .carousel-item:before": {
      // "background-color": "rgb(0 0 0 / 0.8)",
    },
    // ".top-slider .slider-content": {
    //   padding: "200px 0",
    //   "max-width": "500px",
    // },
    ".top-slider .slider-content .slide-title": {
      color: "rgb(255 255 255 / 100)",
      "font-size": "40px",
      "font-weight": "600",
      "margin-bottom": "20px",
    },
    ".top-slider .slider-content .slide-desq": {
      color: "rgb(255 255 255 / 100)",
      "font-size": "16px",
      "font-weight": "500",
      "margin-bottom": "20px",
    },
    ".top-slider .slider-content .btn": {
      color: "rgb(255 255 255 / 100)",
      border: "2px solid rgb(255 73 124 / 100)",
      borderColor: "rgb(255 73 124 / 100)",
      "font-size": "16px",
      "font-weight": "600",
      padding: "8px 25px",
      margin: "10px 10px 10px 0",
    },
    ".top-slider .slider-content .slide-btn1": {
      "background-color": "rgb(255 73 124 / 100)",
    },
    ".top-slider .carousel-control-next-icon, .top-slider .carousel-control-prev-icon":
      {
        "background-color": "rgb(0 0 0 / 0)",
        "border-radius": "0px",
      },
    sliderId: "",
  });

  const [id, setId] = useState("");
  const [childId, setChildId] = useState("");
  const [navMapId, setNavMapId] = useState("");
  const [galleryImgId, setGalleryImgId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isParent, setIsParent] = useState(false);
  const [filter, setFilter] = useState("");
  const [boxSection, setBoxSection] = useState(false);
  const [theme, setTheme] = useState(false);
  const [imgShow, setImgShow] = useState(false);
  const [sectionPageName, setSectionPageName] = useState("");
  return (
    <SliderMultipageContext.Provider
      value={{
        apiData,
        setApiData,
        apiDataOurTeam,
        setApiDataOurTeam,
        fromData,
        setFormData,
        id,
        setId,
        isEdit,
        setIsEdit,
        filter,
        setFilter,
        skill,
        galleryImgId,
        setGalleryImgId,
        setSkill,
        settingFormData,
        setSettingFormData,
        formDataChild,
        setFormDataChild,
        isParent,
        setIsParent,
        childId,
        setChildId,
        navMapId,
        setNavMapId,
        collId,
        setCollId,
        isBackgroundColor,
        setIsBackgroundColor,
        boxSection,
        setBoxSection,
        theme,
        setTheme,
        imgShow,
        setImgShow,
        sectionPageName,
        setSectionPageName,
      }}
    >
      {props.children}
    </SliderMultipageContext.Provider>
  );
};

export default SliderMultipageState;
