import {
  addUrlImage,
  convertRGBA,
  removeUrlImage,
  rgbColorCreate,
} from "../../../../utils/helper";

export const themeOneFunction = ({
  settingFormData,
  boxSection,
  boxSectionDetail,
}) => {
  let newData = {
    ...settingFormData,
    ".service-section": boxSection
      ? {
          ...settingFormData?.[".service-section"],
          padding: `${settingFormData?.[".service-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
          width: `calc(100% - ${settingFormData?.[".service-section"]?.[
            "width"
          ]?.replace("rem)", "")}rem)`,
          margin: `${settingFormData?.[".service-section"]?.["margin"]
            ?.split(" ")[0]
            ?.replace("px", "")}px auto`,
          "border-radius": `${settingFormData?.[".service-section"]?.[
            "border-radius"
          ]?.replace("rem", "")}rem`,
        }
      : {
          ...settingFormData?.[".service-section"],
          padding: `${settingFormData?.[".service-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
        },
    ".service-section.bg-image": settingFormData?.[
      ".service-section.bg-image"
    ]?.["background-image"]
      ? {
          "background-image":
            settingFormData?.[".service-section.bg-image"]?.[
              "background-image"
            ] !== ""
              ? addUrlImage({
                  urlString: removeUrlImage({
                    urlString:
                      settingFormData[".service-section.bg-image"][
                        "background-image"
                      ],
                  }),
                })
              : "",
        }
      : {},
    ".service-section.bg-image::before": settingFormData?.[
      ".service-section.bg-image::before"
    ]?.["background-color"]
      ? {
          "background-color": settingFormData?.[
            ".service-section.bg-image::before"
          ]?.["background-color"]
            ? rgbColorCreate({
                rgbString:
                  settingFormData?.[".service-section.bg-image::before"]?.[
                    "background-color"
                  ],
              })
            : "",
        }
      : {},
    ".service-section .section-title-area": {
      ...settingFormData?.[".service-section .section-title-area"],
      "margin-bottom": `${settingFormData?.[
        ".service-section .section-title-area"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".service-section .section-title-area .section-subtitle": {
      ...settingFormData?.[
        ".service-section .section-title-area .section-subtitle"
      ],
      "font-size": `${settingFormData?.[
        ".service-section .section-title-area .section-subtitle"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".service-section .section-title-area .section-subtitle"]?.["font-weight"]}`,
      color: settingFormData?.[
        ".service-section .section-title-area .section-subtitle"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".service-section .section-title-area .section-subtitle"
              ]?.["color"],
          })
        : "",
      "background-color": settingFormData?.[
        ".service-section .section-title-area .section-subtitle"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".service-section .section-title-area .section-subtitle"
              ]?.["background-color"],
          })
        : "",
      padding: `${settingFormData?.[
        ".service-section .section-title-area .section-subtitle"
      ]?.["p1"]?.replace("px", "")}px ${settingFormData[
        ".service-section .section-title-area .section-subtitle"
      ]?.["p2"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData?.[
        ".service-section .section-title-area .section-subtitle"
      ]?.["border-radius"]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData?.[
        ".service-section .section-title-area .section-subtitle"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".service-section .section-title-area .section-title": {
      ...settingFormData?.[
        ".service-section .section-title-area .section-title"
      ],
      "font-size": `${settingFormData?.[
        ".service-section .section-title-area .section-title"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".service-section .section-title-area .section-title"]?.["font-weight"]}`,
      color: settingFormData?.[
        ".service-section .section-title-area .section-title"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".service-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".service-section .servicediv": {
      ...settingFormData?.[".service-section .servicediv"],
      margin: `${settingFormData?.[".service-section .servicediv"]?.[
        "margin"
      ]?.replace("px", "")}px`,
      padding: `${settingFormData?.[".service-section .servicediv"]?.[
        "padding"
      ]?.replace("px", "")}px`,
      outline: `${settingFormData?.[".service-section .servicediv"]?.[
        "outPx"
      ]?.replace("px", "")}px ${
        settingFormData?.[".service-section .servicediv"]?.["outThik"]
      } ${rgbColorCreate({
        rgbString:
          settingFormData?.[".service-section .servicediv"]?.["outColor"],
      })}`,
      "background-color": settingFormData?.[".service-section .servicediv"]?.[
        "background-color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".service-section .servicediv"]?.[
                "background-color"
              ],
          })
        : "",
      "box-shadow": `${settingFormData?.[".service-section .servicediv"]?.[
        "bs1"
      ]?.replace("px", "")}px ${settingFormData?.[
        ".service-section .servicediv"
      ]?.["bs2"]?.replace("px", "")}px ${settingFormData?.[
        ".service-section .servicediv"
      ]?.["bs3"]?.replace("px", "")}px ${settingFormData?.[
        ".service-section .servicediv"
      ]?.["bs4"]?.replace("px", "")}px ${rgbColorCreate({
        rgbString:
          settingFormData?.[".service-section .servicediv"]?.["shadowColor"],
      })}`,
      "border-radius": `${settingFormData?.[".service-section .servicediv"]?.[
        "border-radius"
      ]?.replace("px", "")}px`,
    },
    ".service-section .servicediv .service-icon": {
      ...settingFormData?.[".service-section .servicediv .service-icon"],
      "margin-bottom": `${settingFormData?.[
        ".service-section .servicediv .service-icon"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
      "max-width": `${settingFormData?.[
        ".service-section .servicediv .service-icon"
      ]?.["max-width"]?.replace("px", "")}px`,
    },
    ".service-section .servicediv h4": {
      ...settingFormData?.[".service-section .servicediv h4"],
      color: settingFormData?.[".service-section .servicediv h4"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".service-section .servicediv h4"]?.["color"],
          })
        : "",
      "font-weight": `${settingFormData?.[".service-section .servicediv h4"]?.["font-weight"]}`,
      "font-size": `${settingFormData?.[".service-section .servicediv h4"]?.[
        "font-size"
      ]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData?.[
        ".service-section .servicediv h4"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
      "text-align": `${settingFormData?.[".service-section .servicediv h4"]?.["text-align"]}`,
    },
    ".service-section .servicediv p": {
      ...settingFormData?.[".service-section .servicediv p"],
      color: settingFormData?.[".service-section .servicediv p"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".service-section .servicediv p"]?.["color"],
          })
        : "",
      "font-weight": `${settingFormData?.[".service-section .servicediv p"]?.["font-weight"]}`,
      "font-size": `${settingFormData?.[".service-section .servicediv p"]?.[
        "font-size"
      ]?.replace("px", "")}px`,
      "line-height": `${settingFormData?.[".service-section .servicediv p"]?.["line-height"]}`,
      "margin-bottom": `${settingFormData?.[".service-section .servicediv p"]?.[
        "margin-bottom"
      ]?.replace("px", "")}px`,
      "text-align": `${settingFormData?.[".service-section .servicediv p"]?.["text-align"]}`,
    },
    ".service-section .servicediv .links": {
      ...settingFormData?.[".service-section .servicediv .links"],
      "font-size": `${settingFormData?.[
        ".service-section .servicediv .links"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".service-section .servicediv .links"]?.["font-weight"]}`,
      color: settingFormData?.[".service-section .servicediv .links"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".service-section .servicediv .links"]?.[
                "color"
              ],
          })
        : "",
    },
    ".service-slider .slick-arrow:before": {
      ...settingFormData?.[".service-slider .slick-arrow:before"],
      color: settingFormData?.[".service-slider .slick-arrow:before"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".service-slider .slick-arrow:before"]?.[
                "color"
              ],
          })
        : "",
    },
    ".service-section .slick-dots li button:before": {
      ...settingFormData?.[".service-section .slick-dots li button:before"],
      // color: `${settingFormData?.[
      //   ".service-section .slick-dots li button:before"
      // ]?.["color"]?.replace("!important", "")} !important`,
      color: settingFormData?.[
        ".service-section .slick-dots li button:before"
      ]?.["color"]
        ? `${rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".service-section .slick-dots li button:before"
              ]?.["color"],
          })?.replace("!important", "")} !important`
        : "",
      "font-size": `${settingFormData?.[
        ".service-section .slick-dots li button:before"
      ]?.["font-size"]?.replace("px !important", "")}px !important`,
      opacity: `${settingFormData?.[
        ".service-section .slick-dots li button:before"
      ]?.["opacity"]?.replace("!important", "")} !important`,
    },
    ".service-section .slick-dots li.slick-active button:before": {
      color: settingFormData?.[
        ".service-section .slick-dots li.slick-active button:before"
      ]?.["color"]
        ? `${rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".service-section .slick-dots li.slick-active button:before"
              ]?.["color"],
          })?.replace("!important", "")} !important`
        : "",
      opacity: "1 !important",
    },
  };
  return newData;
};

export const themeTwoFunction = ({ settingFormData, boxSection }) => {
  let newData = {
    ...settingFormData,
    ".service2-section": boxSection
      ? {
          ...settingFormData?.[".service2-section"],
          padding: `${settingFormData?.[".service2-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
          width: `calc(100% - ${settingFormData?.[".service2-section"]?.[
            "width"
          ]?.replace("rem)", "")}rem)`,
          margin: `${settingFormData?.[".service2-section"]?.["margin"]
            ?.split(" ")[0]
            ?.replace("px", "")}px auto`,
          "border-radius": `${settingFormData?.[".service2-section"]?.[
            "border-radius"
          ]?.replace("rem", "")}rem`,
        }
      : {
          ...settingFormData?.[".service2-section"],
          padding: `${settingFormData?.[".service2-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
        },
    ".service2-section.bg-image": settingFormData?.[
      ".service2-section.bg-image"
    ]?.["background-image"]
      ? {
          "background-image":
            settingFormData?.[".service2-section.bg-image"]?.[
              "background-image"
            ] !== ""
              ? addUrlImage({
                  urlString: removeUrlImage({
                    urlString:
                      settingFormData[".service2-section.bg-image"][
                        "background-image"
                      ],
                  }),
                })
              : "",
        }
      : {},
    ".service2-section.bg-image::before": settingFormData?.[
      ".service2-section.bg-image::before"
    ]?.["background-color"]
      ? {
          "background-color": settingFormData?.[
            ".service2-section.bg-image::before"
          ]?.["background-color"]
            ? rgbColorCreate({
                rgbString:
                  settingFormData?.[".service2-section.bg-image::before"]?.[
                    "background-color"
                  ],
              })
            : "",
        }
      : {},
    ".service2-section .section-title-area": {
      ...settingFormData?.[".service2-section .section-title-area"],
      "margin-bottom": `${settingFormData?.[
        ".service2-section .section-title-area"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
      position: "relative",
    },
    ".service2-section .section-title-area .section-subtitle": {
      ...settingFormData?.[
        ".service2-section .section-title-area .section-subtitle"
      ],
      "font-size": `${settingFormData?.[
        ".service2-section .section-title-area .section-subtitle"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".service2-section .section-title-area .section-subtitle"]?.["font-weight"]}`,

      "background-color": settingFormData?.[
        ".service2-section .section-title-area .section-subtitle"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".service2-section .section-title-area .section-subtitle"
              ]?.["background-color"],
          })
        : "",
      color: settingFormData?.[
        ".service2-section .section-title-area .section-subtitle"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".service2-section .section-title-area .section-subtitle"
              ]?.["color"],
          })
        : "",
      padding: `${settingFormData?.[
        ".service2-section .section-title-area .section-subtitle"
      ]?.["p1"]?.replace("px", "")}px ${settingFormData?.[
        ".service2-section .section-title-area .section-subtitle"
      ]?.["p2"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData?.[
        ".service2-section .section-title-area .section-subtitle"
      ]?.["border-radius"]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData?.[
        ".service2-section .section-title-area .section-subtitle"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".service2-section .section-title-area .section-title": {
      ...settingFormData?.[
        ".service2-section .section-title-area .section-title"
      ],
      "font-size": `${settingFormData?.[
        ".service2-section .section-title-area .section-title"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".service2-section .section-title-area .section-title"]?.["font-weight"]}`,
      color: settingFormData?.[
        ".service2-section .section-title-area .section-title"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".service2-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".service2-section .servicediv": {
      ...settingFormData?.[".service2-section .servicediv"],
      margin: `${settingFormData?.[".service2-section .servicediv"]?.[
        "margin"
      ]?.replace("px", "")}px`,
      padding: `${settingFormData?.[".service2-section .servicediv"]?.[
        "padding"
      ]?.replace("px", "")}px`,
      outline: `${settingFormData?.[".service2-section .servicediv"]?.[
        "outPx"
      ]?.replace("px", "")}px ${
        settingFormData?.[".service2-section .servicediv"]?.["outThik"]
      } ${rgbColorCreate({
        rgbString:
          settingFormData?.[".service2-section .servicediv"]?.["outColor"],
      })}`,
      "background-color": settingFormData?.[".service2-section .servicediv"]?.[
        "background-color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".service2-section .servicediv"]?.[
                "background-color"
              ],
          })
        : "",
      "box-shadow": `${settingFormData?.[".service2-section .servicediv"]?.[
        "bs1"
      ]?.replace("px", "")}px ${settingFormData?.[
        ".service2-section .servicediv"
      ]?.["bs2"]?.replace("px", "")}px ${settingFormData?.[
        ".service2-section .servicediv"
      ]?.["bs3"]?.replace("px", "")}px ${settingFormData?.[
        ".service2-section .servicediv"
      ]?.["bs4"]?.replace("px", "")}px ${rgbColorCreate({
        rgbString:
          settingFormData?.[".service2-section .servicediv"]?.["shadowColor"],
      })}`,
    },
    // ".service2-section .servicediv .service-icon": {
    //   ...settingFormData?.[".service2-section .servicediv .service-icon"],
    //   "margin-bottom": `${settingFormData?.[
    //     ".service2-section .servicediv .service-icon"
    //   ]?.["margin-bottom"]?.replace("px", "")}px`,
    //   "max-width": `${settingFormData?.[
    //     ".service2-section .servicediv .service-icon"
    //   ]?.["max-width"]?.replace("px", "")}px`,
    // },
    ".service2-section .servicediv h4": {
      ...settingFormData?.[".service2-section .servicediv h4"],
      color: settingFormData?.[".service2-section .servicediv h4"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".service2-section .servicediv h4"]?.["color"],
          })
        : "",
      "font-weight": `${settingFormData?.[".service2-section .servicediv h4"]?.["font-weight"]}`,
      "font-size": `${settingFormData?.[".service2-section .servicediv h4"]?.[
        "font-size"
      ]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData?.[
        ".service2-section .servicediv h4"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
      "text-align": `${settingFormData?.[".service2-section .servicediv h4"]?.["text-align"]}`,
    },
    ".service2-section .servicediv p": {
      ...settingFormData?.[".service2-section .servicediv p"],
      color: settingFormData?.[".service2-section .servicediv p"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".service2-section .servicediv p"]?.["color"],
          })
        : "",
      "font-weight": `${settingFormData?.[".service2-section .servicediv p"]?.["font-weight"]}`,
      "font-size": `${settingFormData?.[".service2-section .servicediv p"]?.[
        "font-size"
      ]?.replace("px", "")}px`,
      "line-height": `${settingFormData?.[".service2-section .servicediv p"]?.["line-height"]}`,
      "margin-bottom": `${settingFormData?.[
        ".service2-section .servicediv p"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
      "text-align": `${settingFormData?.[".service2-section .servicediv p"]?.["text-align"]}`,
    },
    ".service2-section .servicediv .links": {
      ...settingFormData?.[".service2-section .servicediv .links"],
      "font-size": `${settingFormData?.[
        ".service2-section .servicediv .links"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".service2-section .servicediv .links"]?.["font-weight"]}`,
      color: settingFormData?.[".service2-section .servicediv .links"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".service2-section .servicediv .links"]?.[
                "color"
              ],
          })
        : "",
    },
    ".service-slider .slick-arrow::before": {
      ...settingFormData?.[".service-slider .slick-arrow::before"],
      color: settingFormData?.[".service-slider .slick-arrow::before"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".service-slider .slick-arrow::before"]?.[
                "color"
              ],
          })
        : "",
    },
    // ".service-slider .slick-arrow::before": {
    //   color: "red",
    // },
    ".service-slider .service-box": {
      ...settingFormData?.[".service-slider .service-box"],
      position: `${settingFormData?.[".service-slider .service-box"]?.["position"]}`,
      "border-radius": `${settingFormData?.[".service-slider .service-box"]?.[
        "border-radius"
      ]?.replace("px", "")}px`,
      margin: `${settingFormData?.[".service-slider .service-box"]?.[
        "margin"
      ]?.replace("px", "")}px`,
      overflow: "hidden",
    },
    ".service-slider .service-box .servicediv": {
      ...settingFormData?.[".service-slider .service-box .servicediv"],
      "background-color": settingFormData?.[
        ".service-slider .service-box .servicediv"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".service-slider .service-box .servicediv"]?.[
                "background-color"
              ],
          })
        : "",
      "border-radius": `${settingFormData?.[
        ".service-slider .service-box .servicediv"
      ]?.["border-radius"]?.replace("px", "")}px`,
    },
    ".service-slider .service-box .service-img": {
      ...settingFormData?.[".service-slider .service-box .service-img"],
      "border-radius": `${settingFormData?.[
        ".service-slider .service-box .service-img"
      ]?.["border-radius"]?.replace("px", "")}px`,
    },
    ".service2-section .slick-dots li button:before": {
      ...settingFormData?.[".service2-section .slick-dots li button:before"],
      color: settingFormData?.[
        ".service2-section .slick-dots li button:before"
      ]?.["color"]
        ? `${rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".service2-section .slick-dots li button:before"
              ]?.["color"],
          })} !important`
        : "",
      "font-size": `${settingFormData?.[
        ".service2-section .slick-dots li button:before"
      ]?.["font-size"]?.replace("px !important", "")}px !important`,
      opacity: `${settingFormData?.[
        ".service2-section .slick-dots li button:before"
      ]?.["opacity"]?.replace("!important", "")} !important`,
    },
    ".service2-section .slick-dots li.slick-active button:before": {
      color: settingFormData?.[
        ".service2-section .slick-dots li.slick-active button:before"
      ]?.["color"]
        ? `${rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".service2-section .slick-dots li.slick-active button:before"
              ]?.["color"],
          })} !important`
        : "",
      opacity: "1 !important",
    },
    // ".service-slider .service-box": {
    //   ...settingFormData?.[".service-slider .service-box"],
    //   position: `${settingFormData?.[".service-slider .service-box"]?.["position"]}`,
    //   "border-radius": `${settingFormData?.[".service-slider .service-box"]?.[
    //     "border-radius"
    //   ]?.replace("px", "")}px`,
    //   margin: `${settingFormData?.[".service-slider .service-box"]?.[
    //     "margin"
    //   ]?.replace("px", "")}px`,
    // },
    // ".service-slider .service-box .servicediv": {
    //   ...settingFormData?.[".service-slider .service-box .servicediv"],
    //   "background-color": `${settingFormData?.[".service-slider .service-box .servicediv"]?.["background-color"]}`,
    //   "border-radius": `${settingFormData?.[
    //     ".service-slider .service-box .servicediv"
    //   ]?.["border-radius"]?.replace("px", "")}px`,
    // },
    // ".service-slider .service-box .service-img": {
    //   ...settingFormData?.[".service-slider .service-box .service-img"],
    //   "border-radius": `${settingFormData?.[
    //     ".service-slider .service-box .service-img"
    //   ]?.["border-radius"]?.replace("px", "")}px`,
    // },
    // ".service-slider .service-box": {
    //   position: "relative",
    //   "border-radius": "10px",
    //   margin: "10px",
    // },
    // ".service-slider .service-box .servicediv": {
    //   "background-color": "rgba(255, 255, 255, 0.9)",
    //   "border-radius": "10px",
    // },
  };
  return newData;
};
export const themEighthFunction = ({ settingFormData, boxSection }) => {
  let newData = {
    ...settingFormData,
    ".boxes2-section": boxSection
      ? {
          ...settingFormData?.[".boxes2-section"],
          padding: `${settingFormData?.[".boxes2-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
          width: `calc(100% - ${settingFormData?.[".boxes2-section"]?.[
            "width"
          ]?.replace("rem)", "")}rem)`,
          margin: `${settingFormData?.[".boxes2-section"]?.["margin"]
            ?.split(" ")[0]
            ?.replace("px", "")}px auto`,
          "border-radius": `${settingFormData?.[".boxes2-section"]?.[
            "border-radius"
          ]?.replace("rem", "")}rem`,
        }
      : {
          ...settingFormData?.[".boxes2-section"],
          padding: `${settingFormData?.[".boxes2-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
        },
    ".boxes2-section.bg-image": settingFormData?.[".boxes2-section.bg-image"]?.[
      "background-image"
    ]
      ? {
          "background-image":
            settingFormData?.[".boxes2-section.bg-image"]?.[
              "background-image"
            ] !== ""
              ? addUrlImage({
                  urlString: removeUrlImage({
                    urlString:
                      settingFormData[".boxes2-section.bg-image"][
                        "background-image"
                      ],
                  }),
                })
              : "",
        }
      : {},
    ".boxes2-section.bg-image::before": settingFormData?.[
      ".boxes2-section.bg-image::before"
    ]?.["background-color"]
      ? {
          "background-color": settingFormData?.[
            ".boxes2-section.bg-image::before"
          ]?.["background-color"]
            ? rgbColorCreate({
                rgbString:
                  settingFormData?.[".boxes2-section.bg-image::before"]?.[
                    "background-color"
                  ],
              })
            : "",
        }
      : {},
    ".boxes2-section .section-title-area": {
      ...settingFormData?.[".boxes2-section .section-title-area"],
      "margin-bottom": `${settingFormData?.[
        ".boxes2-section .section-title-area"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".boxes2-section .section-title-area .section-subtitle": {
      ...settingFormData?.[
        ".boxes2-section .section-title-area .section-subtitle"
      ],
      "font-size": `${settingFormData?.[
        ".boxes2-section .section-title-area .section-subtitle"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".boxes2-section .section-title-area .section-subtitle"]?.["font-weight"]}`,

      "background-color": settingFormData?.[
        ".boxes2-section .section-title-area .section-subtitle"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".boxes2-section .section-title-area .section-subtitle"
              ]?.["background-color"],
          })
        : "",
      color: settingFormData?.[
        ".boxes2-section .section-title-area .section-subtitle"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".boxes2-section .section-title-area .section-subtitle"
              ]?.["color"],
          })
        : "",
      padding: `${settingFormData?.[
        ".boxes2-section .section-title-area .section-subtitle"
      ]?.["p1"]?.replace("px", "")}px ${settingFormData?.[
        ".boxes2-section .section-title-area .section-subtitle"
      ]?.["p2"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData?.[
        ".boxes2-section .section-title-area .section-subtitle"
      ]?.["border-radius"]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData?.[
        ".boxes2-section .section-title-area .section-subtitle"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".boxes2-section .section-title-area .section-title": {
      ...settingFormData?.[
        ".boxes2-section .section-title-area .section-title"
      ],
      "font-size": `${settingFormData?.[
        ".boxes2-section .section-title-area .section-title"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".boxes2-section .section-title-area .section-title"]?.["font-weight"]}`,
      color: settingFormData?.[
        ".boxes2-section .section-title-area .section-title"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".boxes2-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".boxes2-section .cus-boxes": {
      ...settingFormData?.[".boxes2-section .cus-boxes"],
      "border-radius": `${settingFormData?.[".boxes2-section .cus-boxes"]?.[
        "border-radius"
      ]?.replace("px", "")}px`,
      padding: `${settingFormData?.[".boxes2-section .cus-boxes"]?.[
        "p1"
      ]?.replace("px", "")}px ${settingFormData?.[
        ".boxes2-section .cus-boxes"
      ]?.["p2"]?.replace("px", "")}px`,
      margin: `${settingFormData?.[".boxes2-section .cus-boxes"]?.["margin"]
        ?.split(" ")[0]
        ?.replace("px", "")}px 0`,
    },
    ".boxes2-section .cus-boxes:hover": {
      ...settingFormData?.[".boxes2-section .cus-boxes:hover"],
      "background-color": settingFormData?.[
        ".boxes2-section .cus-boxes:hover"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".boxes2-section .cus-boxes:hover"]?.[
                "background-color"
              ],
          })
        : "",
      "box-shadow": `${settingFormData?.[".boxes2-section .cus-boxes:hover"]?.[
        "bs1"
      ]?.replace("px", "")}px ${settingFormData?.[
        ".boxes2-section .cus-boxes:hover"
      ]?.["bs2"]?.replace("px", "")}px ${settingFormData?.[
        ".boxes2-section .cus-boxes:hover"
      ]?.["bs3"]?.replace("px", "")}px ${rgbColorCreate({
        rgbString:
          settingFormData?.[".boxes2-section .cus-boxes:hover"]?.[
            "shadowColor"
          ],
      })}`,
    },
    ".boxes2-section .flaticon": {
      "max-width": `${settingFormData?.[".boxes2-section .flaticon"]?.[
        "max-width"
      ]?.replace("%", "")}%`,
    },
    ".boxes2-section .subtitle": {
      ...settingFormData?.[".boxes2-section .subtitle"],
      color: settingFormData?.[".boxes2-section .subtitle"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".boxes2-section .subtitle"]?.["color"],
          })
        : "",
      "font-size": `${settingFormData?.[".boxes2-section .subtitle"]?.[
        "font-size"
      ]?.replace("px", "")}px`,
      "line-height": `${settingFormData?.[".boxes2-section .subtitle"]?.["line-height"]}`,
      "font-weight": `${settingFormData?.[".boxes2-section .subtitle"]?.["font-weight"]}`,
      margin: `${settingFormData?.[".boxes2-section .subtitle"]?.[
        "m1"
      ]?.replace("px", "")}px ${settingFormData?.[
        ".boxes2-section .subtitle"
      ]?.["m2"]?.replace("px", "")}px ${settingFormData?.[
        ".boxes2-section .subtitle"
      ]?.["m3"]?.replace("px", "")}px`,
    },
  };
  return newData;
};
export const themNineFunction = ({ settingFormData, boxSection }) => {
  let newData = {
    ...settingFormData,
    ".boxes3-section": boxSection
      ? {
          ...settingFormData?.[".boxes3-section"],
          padding: `${settingFormData?.[".boxes3-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
          width: `calc(100% - ${settingFormData?.[".boxes3-section"]?.[
            "width"
          ]?.replace("rem)", "")}rem)`,
          margin: `${settingFormData?.[".boxes3-section"]?.["margin"]
            ?.split(" ")[0]
            ?.replace("px", "")}px auto`,
          "border-radius": `${settingFormData?.[".boxes3-section"]?.[
            "border-radius"
          ]?.replace("rem", "")}rem`,
        }
      : {
          ...settingFormData?.[".boxes3-section"],
          padding: `${settingFormData?.[".boxes3-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
        },
    ".boxes3-section.bg-image": settingFormData?.[".boxes3-section.bg-image"]?.[
      "background-image"
    ]
      ? {
          "background-image":
            settingFormData?.[".boxes3-section.bg-image"]?.[
              "background-image"
            ] !== ""
              ? addUrlImage({
                  urlString: removeUrlImage({
                    urlString:
                      settingFormData[".boxes3-section.bg-image"][
                        "background-image"
                      ],
                  }),
                })
              : "",
        }
      : {},
    ".boxes3-section.bg-image::before": settingFormData?.[
      ".boxes3-section.bg-image::before"
    ]?.["background-color"]
      ? {
          "background-color": settingFormData?.[
            ".boxes3-section.bg-image::before"
          ]?.["background-color"]
            ? rgbColorCreate({
                rgbString:
                  settingFormData?.[".boxes3-section.bg-image::before"]?.[
                    "background-color"
                  ],
              })
            : "",
        }
      : {},
    ".boxes3-section .section-title-area": {
      ...settingFormData?.[".boxes3-section .section-title-area"],
      "margin-bottom": `${settingFormData?.[
        ".boxes3-section .section-title-area"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".boxes3-section .section-title-area .section-subtitle": {
      ...settingFormData?.[
        ".boxes3-section .section-title-area .section-subtitle"
      ],
      "font-size": `${settingFormData?.[
        ".boxes3-section .section-title-area .section-subtitle"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".boxes3-section .section-title-area .section-subtitle"]?.["font-weight"]}`,

      "background-color": settingFormData?.[
        ".boxes3-section .section-title-area .section-subtitle"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".boxes3-section .section-title-area .section-subtitle"
              ]?.["background-color"],
          })
        : "",
      color: settingFormData?.[
        ".boxes3-section .section-title-area .section-subtitle"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".boxes3-section .section-title-area .section-subtitle"
              ]?.["color"],
          })
        : "",
      padding: `${settingFormData?.[
        ".boxes3-section .section-title-area .section-subtitle"
      ]?.["p1"]?.replace("px", "")}px ${settingFormData?.[
        ".boxes3-section .section-title-area .section-subtitle"
      ]?.["p2"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData?.[
        ".boxes3-section .section-title-area .section-subtitle"
      ]?.["border-radius"]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData?.[
        ".boxes3-section .section-title-area .section-subtitle"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".boxes3-section .section-title-area .section-title": {
      ...settingFormData?.[
        ".boxes3-section .section-title-area .section-title"
      ],
      "font-size": `${settingFormData?.[
        ".boxes3-section .section-title-area .section-title"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".boxes3-section .section-title-area .section-title"]?.["font-weight"]}`,
      color: settingFormData?.[
        ".boxes3-section .section-title-area .section-title"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".boxes3-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".boxes3-section .cus-boxes": {
      ...settingFormData?.[".boxes3-section .cus-boxes"],
      "background-color": settingFormData?.[".boxes3-section .cus-boxes"]?.[
        "background-color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".boxes3-section .cus-boxes"]?.[
                "background-color"
              ],
          })
        : "",
      "border-radius": `${settingFormData?.[".boxes3-section .cus-boxes"]?.[
        "border-radius"
      ]?.replace("px", "")}px`,
      padding: `${settingFormData?.[".boxes3-section .cus-boxes"]?.[
        "p1"
      ]?.replace("px", "")}px ${settingFormData?.[
        ".boxes3-section .cus-boxes"
      ]?.["p2"]?.replace("px", "")}px`,
      margin: `${settingFormData?.[".boxes3-section .cus-boxes"]?.["margin"]
        ?.split(" ")[0]
        ?.replace("px", "")}px 0`,
      border: `${settingFormData?.[".boxes3-section .cus-boxes"]?.[
        "borderPx"
      ]?.replace("px", "")}px ${
        settingFormData?.[".boxes3-section .cus-boxes"]?.["borderThik"]
      } ${rgbColorCreate({
        rgbString:
          settingFormData?.[".boxes3-section .cus-boxes"]?.["borderColor"],
      })}`,
    },
    ".boxes3-section .flaticon": {
      "max-width": `${settingFormData?.[".boxes3-section .flaticon"]?.[
        "max-width"
      ]?.replace("%", "")}%`,
    },
    ".boxes3-section .subtitle": {
      ...settingFormData?.[".boxes3-section .subtitle"],
      color: settingFormData?.[".boxes3-section .subtitle"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".boxes3-section .subtitle"]?.["color"],
          })
        : "",
      "font-size": `${settingFormData?.[".boxes3-section .subtitle"]?.[
        "font-size"
      ]?.replace("px", "")}px`,
      "line-height": `${settingFormData?.[".boxes3-section .subtitle"]?.["line-height"]}`,
      "font-weight": `${settingFormData?.[".boxes3-section .subtitle"]?.["font-weight"]}`,
      margin: `${settingFormData?.[".boxes3-section .subtitle"]?.[
        "m1"
      ]?.replace("px", "")}px ${settingFormData?.[
        ".boxes3-section .subtitle"
      ]?.["m2"]?.replace("px", "")}px ${settingFormData?.[
        ".boxes3-section .subtitle"
      ]?.["m3"]?.replace("px", "")}px`,
    },
  };
  return newData;
};
export const themTenFunction = ({ settingFormData, boxSection }) => {
  let newData = {
    ...settingFormData,
    ".boxes4-section": boxSection
      ? {
          ...settingFormData?.[".boxes4-section"],
          padding: `${settingFormData?.[".boxes4-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
          width: `calc(100% - ${settingFormData?.[".boxes4-section"]?.[
            "width"
          ]?.replace("rem)", "")}rem)`,
          margin: `${settingFormData?.[".boxes4-section"]?.["margin"]
            ?.split(" ")[0]
            ?.replace("px", "")}px auto`,
          "border-radius": `${settingFormData?.[".boxes4-section"]?.[
            "border-radius"
          ]?.replace("rem", "")}rem`,
        }
      : {
          ...settingFormData?.[".boxes4-section"],
          padding: `${settingFormData?.[".boxes4-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
        },
    ".boxes4-section.bg-image": settingFormData?.[".boxes4-section.bg-image"]?.[
      "background-image"
    ]
      ? {
          "background-image":
            settingFormData?.[".boxes4-section.bg-image"]?.[
              "background-image"
            ] !== ""
              ? addUrlImage({
                  urlString: removeUrlImage({
                    urlString:
                      settingFormData[".boxes4-section.bg-image"][
                        "background-image"
                      ],
                  }),
                })
              : "",
        }
      : {},
    ".boxes4-section.bg-image::before": settingFormData?.[
      ".boxes4-section.bg-image::before"
    ]?.["background-color"]
      ? {
          "background-color": settingFormData?.[
            ".boxes4-section.bg-image::before"
          ]?.["background-color"]
            ? rgbColorCreate({
                rgbString:
                  settingFormData?.[".boxes4-section.bg-image::before"]?.[
                    "background-color"
                  ],
              })
            : "",
        }
      : {},
    ".boxes4-section .section-title-area": {
      ...settingFormData?.[".boxes4-section .section-title-area"],
      "margin-bottom": `${settingFormData?.[
        ".boxes4-section .section-title-area"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".boxes4-section .section-title-area .section-subtitle": {
      ...settingFormData?.[
        ".boxes4-section .section-title-area .section-subtitle"
      ],
      "font-size": `${settingFormData?.[
        ".boxes4-section .section-title-area .section-subtitle"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".boxes4-section .section-title-area .section-subtitle"]?.["font-weight"]}`,

      "background-color": settingFormData?.[
        ".boxes4-section .section-title-area .section-subtitle"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".boxes4-section .section-title-area .section-subtitle"
              ]?.["background-color"],
          })
        : "",
      color: settingFormData?.[
        ".boxes4-section .section-title-area .section-subtitle"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".boxes4-section .section-title-area .section-subtitle"
              ]?.["color"],
          })
        : "",
      padding: `${settingFormData?.[
        ".boxes4-section .section-title-area .section-subtitle"
      ]?.["p1"]?.replace("px", "")}px ${settingFormData?.[
        ".boxes4-section .section-title-area .section-subtitle"
      ]?.["p2"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData?.[
        ".boxes4-section .section-title-area .section-subtitle"
      ]?.["border-radius"]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData?.[
        ".boxes4-section .section-title-area .section-subtitle"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".boxes4-section .section-title-area .section-title": {
      ...settingFormData?.[
        ".boxes4-section .section-title-area .section-title"
      ],
      "font-size": `${settingFormData?.[
        ".boxes4-section .section-title-area .section-title"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".boxes4-section .section-title-area .section-title"]?.["font-weight"]}`,
      color: settingFormData?.[
        ".boxes4-section .section-title-area .section-title"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".boxes4-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".boxes4-section .cus-boxes": {
      ...settingFormData?.[".boxes4-section .cus-boxes"],
      background: `linear-gradient(145deg, ${
        settingFormData?.[".boxes4-section .cus-boxes"]?.["backColorOne"]
          ? rgbColorCreate({
              rgbString:
                settingFormData?.[".boxes4-section .cus-boxes"]?.[
                  "backColorOne"
                ],
            })
          : "rgb(226 232 236 / 100)"
      }, ${
        settingFormData?.[".boxes4-section .cus-boxes"]?.["backColorTwo"]
          ? rgbColorCreate({
              rgbString:
                settingFormData?.[".boxes4-section .cus-boxes"]?.[
                  "backColorTwo"
                ],
            })
          : "rgb(255 255 255 / 100)"
      })`,
      "border-radius": `${settingFormData?.[".boxes4-section .cus-boxes"]?.[
        "border-radius"
      ]?.replace("px", "")}px`,
      padding: `${settingFormData?.[".boxes4-section .cus-boxes"]?.[
        "p1"
      ]?.replace("px", "")}px ${settingFormData?.[
        ".boxes4-section .cus-boxes"
      ]?.["p2"]?.replace("px", "")}px`,
      margin: `${settingFormData?.[".boxes4-section .cus-boxes"]?.["margin"]
        ?.split(" ")[0]
        ?.replace("px", "")}px 0`,
      "box-shadow": `${settingFormData?.[".boxes4-section .cus-boxes"]?.[
        "bs1"
      ]?.replace("px", "")}px ${settingFormData?.[
        ".boxes4-section .cus-boxes"
      ]?.["bs2"]?.replace("px", "")}px ${settingFormData?.[
        ".boxes4-section .cus-boxes"
      ]?.["bs3"]?.replace("px", "")}px ${rgbColorCreate({
        rgbString:
          settingFormData?.[".boxes4-section .cus-boxes"]?.["shadowColor"],
      })}`,
    },
    ".boxes4-section .flaticon": {
      "max-width": `${settingFormData?.[".boxes4-section .flaticon"]?.[
        "max-width"
      ]?.replace("%", "")}%`,
    },
    ".boxes4-section .subtitle": {
      ...settingFormData?.[".boxes4-section .subtitle"],
      color: settingFormData?.[".boxes4-section .subtitle"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".boxes4-section .subtitle"]?.["color"],
          })
        : "",
      "font-size": `${settingFormData?.[".boxes4-section .subtitle"]?.[
        "font-size"
      ]?.replace("px", "")}px`,
      "line-height": `${settingFormData?.[".boxes4-section .subtitle"]?.["line-height"]}`,
      "font-weight": `${settingFormData?.[".boxes4-section .subtitle"]?.["font-weight"]}`,
      margin: `${settingFormData?.[".boxes4-section .subtitle"]?.[
        "m1"
      ]?.replace("px", "")}px ${settingFormData?.[
        ".boxes4-section .subtitle"
      ]?.["m2"]?.replace("px", "")}px ${settingFormData?.[
        ".boxes4-section .subtitle"
      ]?.["m3"]?.replace("px", "")}px`,
    },
  };
  return newData;
};
export const themElevenFunction = ({ settingFormData, boxSection }) => {
  let newData = {
    ...settingFormData,
    ".left-boxes1-section": boxSection
      ? {
          ...settingFormData?.[".left-boxes1-section"],
          padding: `${settingFormData?.[".left-boxes1-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
          width: `calc(100% - ${settingFormData?.[".left-boxes1-section"]?.[
            "width"
          ]?.replace("rem)", "")}rem)`,
          margin: `${settingFormData?.[".left-boxes1-section"]?.["margin"]
            ?.split(" ")[0]
            ?.replace("px", "")}px auto`,
          "border-radius": `${settingFormData?.[".left-boxes1-section"]?.[
            "border-radius"
          ]?.replace("rem", "")}rem`,
        }
      : {
          ...settingFormData?.[".left-boxes1-section"],
          padding: `${settingFormData?.[".left-boxes1-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
        },
    ".left-boxes1-section.bg-image": settingFormData?.[
      ".left-boxes1-section.bg-image"
    ]?.["background-image"]
      ? {
          "background-image":
            settingFormData?.[".left-boxes1-section.bg-image"]?.[
              "background-image"
            ] !== ""
              ? addUrlImage({
                  urlString: removeUrlImage({
                    urlString:
                      settingFormData[".left-boxes1-section.bg-image"][
                        "background-image"
                      ],
                  }),
                })
              : "",
        }
      : {},
    ".left-boxes1-section.bg-image::before": settingFormData?.[
      ".left-boxes1-section.bg-image::before"
    ]?.["background-color"]
      ? {
          "background-color": settingFormData?.[
            ".left-boxes1-section.bg-image::before"
          ]?.["background-color"]
            ? rgbColorCreate({
                rgbString:
                  settingFormData?.[".left-boxes1-section.bg-image::before"]?.[
                    "background-color"
                  ],
              })
            : "",
        }
      : {},
    ".left-boxes1-section .section-title-area": {
      ...settingFormData?.[".left-boxes1-section .section-title-area"],
      "margin-bottom": `${settingFormData?.[
        ".left-boxes1-section .section-title-area"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".left-boxes1-section .section-title-area .section-subtitle": {
      ...settingFormData?.[
        ".left-boxes1-section .section-title-area .section-subtitle"
      ],
      "font-size": `${settingFormData?.[
        ".left-boxes1-section .section-title-area .section-subtitle"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".left-boxes1-section .section-title-area .section-subtitle"]?.["font-weight"]}`,
      color: settingFormData?.[
        ".left-boxes1-section .section-title-area .section-subtitle"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".left-boxes1-section .section-title-area .section-subtitle"
              ]?.["color"],
          })
        : "",
      "background-color": settingFormData?.[
        ".left-boxes1-section .section-title-area .section-subtitle"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".left-boxes1-section .section-title-area .section-subtitle"
              ]?.["background-color"],
          })
        : "",
      padding: `${settingFormData?.[
        ".left-boxes1-section .section-title-area .section-subtitle"
      ]?.["p1"]?.replace("px", "")}px ${settingFormData?.[
        ".left-boxes1-section .section-title-area .section-subtitle"
      ]?.["p2"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData?.[
        ".left-boxes1-section .section-title-area .section-subtitle"
      ]?.["border-radius"]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData?.[
        ".left-boxes1-section .section-title-area .section-subtitle"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".left-boxes1-section .section-title-area .section-title": {
      ...settingFormData?.[
        ".left-boxes1-section .section-title-area .section-title"
      ],
      "font-size": `${settingFormData?.[
        ".left-boxes1-section .section-title-area .section-title"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".left-boxes1-section .section-title-area .section-title"]?.["font-weight"]}`,
      color: settingFormData?.[
        ".left-boxes1-section .section-title-area .section-title"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".left-boxes1-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".left-boxes1-section .cus-boxes": {
      ...settingFormData?.[".left-boxes1-section .cus-boxes"],
      "background-color": settingFormData?.[
        ".left-boxes1-section .cus-boxes"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".left-boxes1-section .cus-boxes"]?.[
                "background-color"
              ],
          })
        : "",
      "border-radius": `${settingFormData?.[
        ".left-boxes1-section .cus-boxes"
      ]?.["border-radius"]?.replace("px", "")}px`,
      padding: `${settingFormData?.[".left-boxes1-section .cus-boxes"]?.[
        "p1"
      ]?.replace("px", "")}px ${settingFormData?.[
        ".left-boxes1-section .cus-boxes"
      ]?.["p2"]?.replace("px", "")}px`,
      margin: `${settingFormData?.[".left-boxes1-section .cus-boxes"]?.[
        "margin"
      ]
        ?.split(" ")[0]
        ?.replace("px", "")}px 0`,
      "box-shadow": `${settingFormData?.[".left-boxes1-section .cus-boxes"]?.[
        "bs1"
      ]?.replace("px", "")}px ${settingFormData?.[
        ".left-boxes1-section .cus-boxes"
      ]?.["bs2"]?.replace("px", "")}px ${settingFormData?.[
        ".left-boxes1-section .cus-boxes"
      ]?.["bs3"]?.replace("px", "")}px ${settingFormData?.[
        ".left-boxes1-section .cus-boxes"
      ]?.["bs4"]?.replace("px", "")}px ${rgbColorCreate({
        rgbString:
          settingFormData?.[".left-boxes1-section .cus-boxes"]?.["shadowColor"],
      })}`,
      border: `${settingFormData?.[".left-boxes1-section .cus-boxes"]?.[
        "borderPx"
      ]?.replace("px", "")}px ${
        settingFormData?.[".left-boxes1-section .cus-boxes"]?.["borderThik"]
      } ${rgbColorCreate({
        rgbString:
          settingFormData?.[".left-boxes1-section .cus-boxes"]?.["borderColor"],
      })}`,
    },
    ".left-boxes1-section .flaticon": {
      ...settingFormData?.[".left-boxes1-section .flaticon"],
      width: `${settingFormData?.[".left-boxes1-section .flaticon"]?.[
        "width"
      ]?.replace("px", "")}px`,
      height: `${settingFormData?.[".left-boxes1-section .flaticon"]?.[
        "height"
      ]?.replace("px", "")}px`,
      "margin-right": `${settingFormData?.[".left-boxes1-section .flaticon"]?.[
        "margin-right"
      ]?.replace("px", "")}px`,
      "border-radius": `${settingFormData?.[".left-boxes1-section .flaticon"]?.[
        "border-radius"
      ]?.replace("%", "")}%`,
      "background-color": settingFormData?.[".left-boxes1-section .flaticon"]?.[
        "background-color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".left-boxes1-section .flaticon"]?.[
                "background-color"
              ],
          })
        : "",
      border: `${settingFormData?.[".left-boxes1-section .flaticon"]?.[
        "borderPx"
      ]?.replace("px", "")}px ${
        settingFormData?.[".left-boxes1-section .flaticon"]?.["borderThik"]
      } ${rgbColorCreate({
        rgbString:
          settingFormData?.[".left-boxes1-section .flaticon"]?.["borderColor"],
      })}`,
    },
    ".left-boxes1-section .subdiv h4": {
      ...settingFormData?.[".left-boxes1-section .subdiv h4"],
      color: settingFormData?.[".left-boxes1-section .subdiv h4"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".left-boxes1-section .subdiv h4"]?.["color"],
          })
        : "",
      "font-size": `${settingFormData?.[".left-boxes1-section .subdiv h4"]?.[
        "font-size"
      ]?.replace("px", "")}px`,
      "line-height": `${settingFormData?.[".left-boxes1-section .subdiv h4"]?.["line-height"]}`,
      "font-weight": `${settingFormData?.[".left-boxes1-section .subdiv h4"]?.["font-weight"]}`,
    },
    ".left-boxes1-section .subdiv p": {
      ...settingFormData?.[".left-boxes1-section .subdiv p"],
      color: settingFormData?.[".left-boxes1-section .subdiv p"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".left-boxes1-section .subdiv p"]?.["color"],
          })
        : "",
      "font-size": `${settingFormData?.[".left-boxes1-section .subdiv p"]?.[
        "font-size"
      ]?.replace("px", "")}px`,
      "line-height": `${settingFormData?.[".left-boxes1-section .subdiv p"]?.["line-height"]}`,
      "font-weight": `${settingFormData?.[".left-boxes1-section .subdiv p"]?.["font-weight"]}`,
    },
  };
  return newData;
};
export const themTwelveFunction = ({ settingFormData, boxSection }) => {
  let newData = {
    ...settingFormData,
    ".left-boxes2-section": boxSection
      ? {
          ...settingFormData?.[".left-boxes2-section"],
          padding: `${settingFormData?.[".left-boxes2-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
          width: `calc(100% - ${settingFormData?.[".left-boxes2-section"]?.[
            "width"
          ]?.replace("rem)", "")}rem)`,
          margin: `${settingFormData?.[".left-boxes2-section"]?.["margin"]
            ?.split(" ")[0]
            ?.replace("px", "")}px auto`,
          "border-radius": `${settingFormData?.[".left-boxes2-section"]?.[
            "border-radius"
          ]?.replace("rem", "")}rem`,
        }
      : {
          ...settingFormData?.[".left-boxes2-section"],
          padding: `${settingFormData?.[".left-boxes2-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
        },
    ".left-boxes2-section.bg-image": settingFormData?.[
      ".left-boxes2-section.bg-image"
    ]?.["background-image"]
      ? {
          "background-image":
            settingFormData?.[".left-boxes2-section.bg-image"]?.[
              "background-image"
            ] !== ""
              ? addUrlImage({
                  urlString: removeUrlImage({
                    urlString:
                      settingFormData[".left-boxes2-section.bg-image"][
                        "background-image"
                      ],
                  }),
                })
              : "",
        }
      : {},
    ".left-boxes2-section.bg-image::before": settingFormData?.[
      ".left-boxes2-section.bg-image::before"
    ]?.["background-color"]
      ? {
          "background-color": settingFormData?.[
            ".left-boxes2-section.bg-image::before"
          ]?.["background-color"]
            ? rgbColorCreate({
                rgbString:
                  settingFormData?.[".left-boxes2-section.bg-image::before"]?.[
                    "background-color"
                  ],
              })
            : "",
        }
      : {},
    ".left-boxes2-section .cus-boxes": {
      ...settingFormData?.[".left-boxes2-section .cus-boxes"],
      padding: `${settingFormData?.[".left-boxes2-section .cus-boxes"]?.[
        "padding"
      ]?.replace("px", "")}px`,
      margin: `${settingFormData?.[".left-boxes2-section .cus-boxes"]?.[
        "margin"
      ]
        ?.split(" ")[0]
        ?.replace("px", "")}px 0`,
      "border-right": `${settingFormData?.[".left-boxes2-section .cus-boxes"]?.[
        "borderPx"
      ]?.replace("px", "")}px ${
        settingFormData?.[".left-boxes2-section .cus-boxes"]?.["borderThik"]
      } ${rgbColorCreate({
        rgbString:
          settingFormData?.[".left-boxes2-section .cus-boxes"]?.["borderColor"],
      })}`,
    },
    ".left-boxes2-section .flaticon": {
      ...settingFormData?.[".left-boxes2-section .flaticon"],
      width: `${settingFormData?.[".left-boxes2-section .flaticon"]?.[
        "width"
      ]?.replace("px", "")}px`,
      "margin-right": `${settingFormData?.[".left-boxes2-section .flaticon"]?.[
        "margin-right"
      ]?.replace("px", "")}px`,
    },
    ".left-boxes2-section .subdiv h4": {
      ...settingFormData?.[".left-boxes2-section .subdiv h4"],
      color: settingFormData?.[".left-boxes2-section .subdiv h4"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".left-boxes2-section .subdiv h4"]?.["color"],
          })
        : "",
      "font-size": `${settingFormData?.[".left-boxes2-section .subdiv h4"]?.[
        "font-size"
      ]?.replace("px", "")}px`,
      "line-height": `${settingFormData?.[".left-boxes2-section .subdiv h4"]?.["line-height"]}`,
      "margin-bottom": `${settingFormData?.[
        ".left-boxes2-section .subdiv h4"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".left-boxes2-section .subdiv h4"]?.["font-weight"]}`,
    },
    ".left-boxes2-section .subdiv p": {
      ...settingFormData?.[".left-boxes2-section .subdiv p"],
      color: settingFormData?.[".left-boxes2-section .subdiv p"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".left-boxes2-section .subdiv p"]?.["color"],
          })
        : "",
      "font-size": `${settingFormData?.[".left-boxes2-section .subdiv p"]?.[
        "font-size"
      ]?.replace("px", "")}px`,
      "line-height": `${settingFormData?.[".left-boxes2-section .subdiv p"]?.["line-height"]}`,
      "font-weight": `${settingFormData?.[".left-boxes2-section .subdiv p"]?.["font-weight"]}`,
    },
  };
  return newData;
};
export const themThirteenFunction = ({ settingFormData, boxSection }) => {
  let newData = {
    ...settingFormData,
    ".left-boxes3-section": boxSection
      ? {
          ...settingFormData?.[".left-boxes3-section"],
          padding: `${settingFormData?.[".left-boxes3-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
          width: `calc(100% - ${settingFormData?.[".left-boxes3-section"]?.[
            "width"
          ]?.replace("rem)", "")}rem)`,
          margin: `${settingFormData?.[".left-boxes3-section"]?.["margin"]
            ?.split(" ")[0]
            ?.replace("px", "")}px auto`,
          "border-radius": `${settingFormData?.[".left-boxes3-section"]?.[
            "border-radius"
          ]?.replace("rem", "")}rem`,
        }
      : {
          ...settingFormData?.[".left-boxes3-section"],
          padding: `${settingFormData?.[".left-boxes3-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
        },
    ".left-boxes3-section.bg-image": settingFormData?.[
      ".left-boxes3-section.bg-image"
    ]?.["background-image"]
      ? {
          "background-image":
            settingFormData?.[".left-boxes3-section.bg-image"]?.[
              "background-image"
            ] !== ""
              ? addUrlImage({
                  urlString: removeUrlImage({
                    urlString:
                      settingFormData[".left-boxes3-section.bg-image"][
                        "background-image"
                      ],
                  }),
                })
              : "",
        }
      : {},
    ".left-boxes3-section.bg-image::before": settingFormData?.[
      ".left-boxes3-section.bg-image::before"
    ]?.["background-color"]
      ? {
          "background-color": settingFormData?.[
            ".left-boxes3-section.bg-image::before"
          ]?.["background-color"]
            ? rgbColorCreate({
                rgbString:
                  settingFormData?.[".left-boxes3-section.bg-image::before"]?.[
                    "background-color"
                  ],
              })
            : "",
        }
      : {},
    ".left-boxes3-section .boxdiv": {
      ...settingFormData?.[".left-boxes3-section .boxdiv"],
      padding: `${settingFormData?.[".left-boxes3-section .boxdiv"]?.[
        "p1"
      ]?.replace("px", "")}px ${settingFormData?.[
        ".left-boxes3-section .boxdiv"
      ]?.["p2"]?.replace("px", "")}px`,
      margin: `${settingFormData?.[".left-boxes2-section .cus-boxes"]?.[
        "margin"
      ]
        ?.split(" ")[0]
        ?.replace("px", "")}px 0`,
      border: `${settingFormData?.[".left-boxes3-section .boxdiv"]?.[
        "borderPx"
      ]?.replace("px", "")}px ${
        settingFormData?.[".left-boxes3-section .boxdiv"]?.["borderThik"]
      } ${rgbColorCreate({
        rgbString:
          settingFormData?.[".left-boxes3-section .boxdiv"]?.["borderColor"],
      })}`,
      "background-color": settingFormData?.[".left-boxes3-section .boxdiv"]?.[
        "background-color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".left-boxes3-section .boxdiv"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".left-boxes3-section .boxdiv:hover": {
      "background-color": settingFormData?.[
        ".left-boxes3-section .boxdiv:hover"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".left-boxes3-section .boxdiv:hover"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".left-boxes3-section .boxdiv h5": {
      ...settingFormData?.[".left-boxes3-section .boxdiv h5"],
      color: settingFormData?.[".left-boxes3-section .boxdiv h5"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".left-boxes3-section .boxdiv h5"]?.["color"],
          })
        : "",
      "font-size": `${settingFormData?.[".left-boxes3-section .boxdiv h5"]?.[
        "font-size"
      ]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData?.[
        ".left-boxes3-section .boxdiv h5"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".left-boxes3-section .boxdiv h5"]?.["font-weight"]}`,
    },
    ".left-boxes3-section .boxdiv:hover h5": {
      color: settingFormData?.[".left-boxes3-section .boxdiv:hover h5"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".left-boxes3-section .boxdiv:hover h5"]?.[
                "color"
              ],
          })
        : "",
    },
    ".left-boxes3-section .boxdiv p": {
      ...settingFormData?.[".left-boxes3-section .boxdiv p"],
      color: settingFormData?.[".left-boxes3-section .boxdiv p"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".left-boxes3-section .boxdiv p"]?.["color"],
          })
        : "",
      "margin-bottom": `${settingFormData?.[".left-boxes3-section .boxdiv p"]?.[
        "margin-bottom"
      ]?.replace("px", "")}px`,
      "font-size": `${settingFormData?.[".left-boxes3-section .boxdiv p"]?.[
        "font-size"
      ]?.replace("px", "")}px`,
      "line-height": `${settingFormData?.[".left-boxes3-section .boxdiv p"]?.["line-height"]}`,
      "font-weight": `${settingFormData?.[".left-boxes3-section .boxdiv p"]?.["font-weight"]}`,
    },
    ".left-boxes3-section .boxdiv:hover p": {
      color: settingFormData?.[".left-boxes3-section .boxdiv:hover p"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".left-boxes3-section .boxdiv:hover p"]?.[
                "color"
              ],
          })
        : "",
    },
    ".left-boxes3-section .boxdiv .links": {
      ...settingFormData?.[".left-boxes3-section .boxdiv .links"],
      color: settingFormData?.[".left-boxes3-section .boxdiv .links"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".left-boxes3-section .boxdiv .links"]?.[
                "color"
              ],
          })
        : "",
      "font-size": `${settingFormData?.[
        ".left-boxes3-section .boxdiv .links"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".left-boxes3-section .boxdiv .links"]?.["font-weight"]}`,
    },
    ".left-boxes3-section .boxdiv:hover .links": {
      color: settingFormData?.[".left-boxes3-section .boxdiv:hover .links"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".left-boxes3-section .boxdiv:hover .links"]?.[
                "color"
              ],
          })
        : "",
    },
  };
  return newData;
};
export const themFourteenFunction = ({ settingFormData }) => {
  let newData = {
    ...settingFormData,
    ".table-list-wrapper .table-list .list-head": {
      ...settingFormData?.[".table-list-wrapper .table-list .list-head"],
      "background-color": settingFormData?.[
        ".table-list-wrapper .table-list .list-head"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".table-list-wrapper .table-list .list-head"]?.[
                "background-color"
              ],
          })
        : "",
      border: `${settingFormData?.[
        ".table-list-wrapper .table-list .list-head"
      ]?.["borderPx"]?.replace("px", "")}px ${
        settingFormData?.[".table-list-wrapper .table-list .list-head"]?.[
          "borderThik"
        ]
      } ${rgbColorCreate({
        rgbString:
          settingFormData?.[".table-list-wrapper .table-list .list-head"]?.[
            "borderColor"
          ],
      })}`,
      padding: `${settingFormData?.[
        ".table-list-wrapper .table-list .list-head"
      ]?.["padding"]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData?.[
        ".table-list-wrapper .table-list .list-head"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".table-list-wrapper .table-list .list-head h2": {
      ...settingFormData?.[".table-list-wrapper .table-list .list-head h2"],
      color: settingFormData?.[
        ".table-list-wrapper .table-list .list-head h2"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".table-list-wrapper .table-list .list-head h2"
              ]?.["color"],
          })
        : "",
      "font-size": `${settingFormData?.[
        ".table-list-wrapper .table-list .list-head h2"
      ]?.["font-size"]?.replace("px", "")}px`,
    },
    ".table-list-wrapper .table-list .body-ttl": {
      ...settingFormData?.[".table-list-wrapper .table-list .body-ttl"],
      border: `${settingFormData?.[
        ".table-list-wrapper .table-list .body-ttl"
      ]?.["borderPx"]?.replace("px", "")}px ${
        settingFormData?.[".table-list-wrapper .table-list .body-ttl"]?.[
          "borderThik"
        ]
      } ${rgbColorCreate({
        rgbString:
          settingFormData?.[".table-list-wrapper .table-list .body-ttl"]?.[
            "borderColor"
          ],
      })}`,
      padding: `${settingFormData?.[
        ".table-list-wrapper .table-list .body-ttl"
      ]?.["padding"]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData?.[
        ".table-list-wrapper .table-list .body-ttl"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".table-list-wrapper .table-list .list-body": {
      ...settingFormData?.[".table-list-wrapper .table-list .list-body"],
      border: `${settingFormData?.[
        ".table-list-wrapper .table-list .list-body"
      ]?.["borderPx"]?.replace("px", "")}px ${
        settingFormData?.[".table-list-wrapper .table-list .list-body"]?.[
          "borderThik"
        ]
      } ${rgbColorCreate({
        rgbString:
          settingFormData?.[".table-list-wrapper .table-list .list-body"]?.[
            "borderColor"
          ],
      })}`,
      padding: `${settingFormData?.[
        ".table-list-wrapper .table-list .list-body"
      ]?.["p1"]?.replace("px", "")}px ${settingFormData?.[
        ".table-list-wrapper .table-list .list-body"
      ]?.["p2"]?.replace("px", "")}px ${settingFormData?.[
        ".table-list-wrapper .table-list .list-body"
      ]?.["p3"]?.replace("px", "")}px`,
    },
    ".table-list-wrapper .table-list .list-body ul": {
      ...settingFormData?.[".table-list-wrapper .table-list .list-body ul"],
      "font-size": `${settingFormData?.[
        ".table-list-wrapper .table-list .list-body ul"
      ]?.["font-size"]?.replace("px", "")}px`,
      "padding-left": `${settingFormData?.[
        ".table-list-wrapper .table-list .list-body ul"
      ]?.["padding-left"]?.replace("px", "")}px`,
    },
    ".table-list-wrapper .table-list .list-body ul li": {
      ...settingFormData?.[".table-list-wrapper .table-list .list-body ul li"],
      padding: `${settingFormData?.[
        ".table-list-wrapper .table-list .list-body ul li"
      ]?.["p1"]?.replace("px", "")}px ${settingFormData?.[
        ".table-list-wrapper .table-list .list-body ul li"
      ]?.["p2"]?.replace("px", "")}px ${settingFormData?.[
        ".table-list-wrapper .table-list .list-body ul li"
      ]?.["p3"]?.replace("px", "")}px ${settingFormData?.[
        ".table-list-wrapper .table-list .list-body ul li"
      ]?.["p4"]?.replace("px", "")}px`,
    },
    ".table-list-wrapper .table-list .list-body ul li a": {
      ...settingFormData?.[
        ".table-list-wrapper .table-list .list-body ul li a"
      ],
      color: settingFormData?.[
        ".table-list-wrapper .table-list .list-body ul li a"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".table-list-wrapper .table-list .list-body ul li a"
              ]?.["color"],
          })
        : "",
    },
  };
  return newData;
};

export const themeThreeFunction = ({ settingFormData, boxSection }) => {
  let newData = {
    ...settingFormData,
    ".vision-section": boxSection
      ? {
          ...settingFormData?.[".vision-section"],
          padding: `${settingFormData?.[".vision-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
          width: `calc(100% - ${settingFormData?.[".vision-section"]?.[
            "width"
          ]?.replace("rem)", "")}rem)`,
          margin: `${settingFormData?.[".vision-section"]?.["margin"]
            ?.split(" ")[0]
            ?.replace("px", "")}px auto`,
          "border-radius": `${settingFormData?.[".vision-section"]?.[
            "border-radius"
          ]?.replace("rem", "")}rem`,
        }
      : {
          ...settingFormData?.[".vision-section"],
          padding: `${settingFormData?.[".vision-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
        },
    ".vision-section.bg-image": settingFormData?.[".vision-section.bg-image"]?.[
      "background-image"
    ]
      ? {
          "background-image":
            settingFormData?.[".vision-section.bg-image"]?.[
              "background-image"
            ] !== ""
              ? addUrlImage({
                  urlString: removeUrlImage({
                    urlString:
                      settingFormData[".vision-section.bg-image"][
                        "background-image"
                      ],
                  }),
                })
              : "",
        }
      : {},
    ".vision-section.bg-image::before": settingFormData?.[
      ".vision-section.bg-image::before"
    ]?.["background-color"]
      ? {
          "background-color": settingFormData?.[
            ".vision-section.bg-image::before"
          ]?.["background-color"]
            ? rgbColorCreate({
                rgbString:
                  settingFormData?.[".vision-section.bg-image::before"]?.[
                    "background-color"
                  ],
              })
            : "",
        }
      : {},
    ".vision-section .section-title-area": {
      ...settingFormData?.[".vision-section .section-title-area"],
      "margin-bottom": `${settingFormData?.[
        ".vision-section .section-title-area"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".vision-section .section-title-area .section-subtitle": {
      ...settingFormData?.[
        ".vision-section .section-title-area .section-subtitle"
      ],
      "font-size": `${settingFormData?.[
        ".vision-section .section-title-area .section-subtitle"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".vision-section .section-title-area .section-subtitle"]?.["font-weight"]}`,
      color: settingFormData?.[
        ".vision-section .section-title-area .section-subtitle"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".vision-section .section-title-area .section-subtitle"
              ]?.["color"],
          })
        : "",
      "background-color": settingFormData?.[
        ".vision-section .section-title-area .section-subtitle"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".vision-section .section-title-area .section-subtitle"
              ]?.["background-color"],
          })
        : "",
      padding: `${settingFormData?.[
        ".vision-section .section-title-area .section-subtitle"
      ]?.["p1"]?.replace("px", "")}px ${settingFormData?.[
        ".vision-section .section-title-area .section-subtitle"
      ]?.["p2"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData?.[
        ".vision-section .section-title-area .section-subtitle"
      ]?.["border-radius"]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData?.[
        ".vision-section .section-title-area .section-subtitle"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".vision-section .section-title-area .section-title": {
      ...settingFormData?.[
        ".vision-section .section-title-area .section-title"
      ],
      "font-size": `${settingFormData?.[
        ".vision-section .section-title-area .section-title"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".vision-section .section-title-area .section-title"]?.["font-weight"]}`,
      color: settingFormData?.[
        ".vision-section .section-title-area .section-title"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".vision-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".vision-section .visiondiv1": {
      ...settingFormData?.[".vision-section .visiondiv1"],
      "background-color": settingFormData?.[".vision-section .visiondiv1"]?.[
        "background-color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".vision-section .visiondiv1"]?.[
                "background-color"
              ],
          })
        : "",
      padding: `${settingFormData?.[".vision-section .visiondiv1"]?.[
        "p1"
      ]?.replace("px", "")}px ${settingFormData?.[
        ".vision-section .visiondiv1"
      ]?.["p2"]?.replace("px", "")}px`,
      margin: `${settingFormData?.[".vision-section .visiondiv1"]?.["margin"]
        ?.split(" ")[0]
        ?.replace("px", "")}px 0`,
      "border-radius": `${settingFormData?.[".vision-section .visiondiv1"]?.[
        "border-radius"
      ]?.replace("px", "")}px`,
      border: `${settingFormData?.[".vision-section .visiondiv1"]?.[
        "borderPx"
      ]?.replace("px", "")}px ${
        settingFormData?.[".vision-section .visiondiv1"]?.["borderThik"]
      } ${rgbColorCreate({
        rgbString:
          settingFormData?.[".vision-section .visiondiv1"]?.["borderColor"],
      })}`,
      "box-shadow": `${settingFormData?.[".vision-section .visiondiv1"]?.[
        "bs1"
      ]?.replace("px", "")}px ${settingFormData?.[
        ".vision-section .visiondiv1"
      ]?.["bs2"]?.replace("px", "")}px ${settingFormData?.[
        ".vision-section .visiondiv1"
      ]?.["bs3"]?.replace("px", "")}px ${rgbColorCreate({
        rgbString:
          settingFormData?.[".vision-section .visiondiv1"]?.["shadowColor"],
      })}`,
      "text-align": `${settingFormData?.[".vision-section .visiondiv1"]?.["text-align"]}`,
    },
    ".vision-section .visiondiv1:hover": {
      ...settingFormData?.[".vision-section .visiondiv1:hover"],
      "box-shadow": `${settingFormData?.[".vision-section .visiondiv1:hover"]?.[
        "bs1"
      ]?.replace("px", "")}px ${settingFormData?.[
        ".vision-section .visiondiv1:hover"
      ]?.["bs2"]?.replace("px", "")}px ${settingFormData?.[
        ".vision-section .visiondiv1:hover"
      ]?.["bs3"]?.replace("px", "")}px ${settingFormData?.[
        ".vision-section .visiondiv1:hover"
      ]?.["bs4"]?.replace("px", "")}px ${rgbColorCreate({
        rgbString:
          settingFormData?.[".vision-section .visiondiv1:hover"]?.[
            "shadowColor"
          ],
      })}`,
    },
    ".vision-section .visiondiv1 img": {
      ...settingFormData?.[".vision-section .visiondiv1 img"],
      "margin-bottom": `${settingFormData?.[
        ".vision-section .visiondiv1 img"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
      "max-width": `${settingFormData?.[".vision-section .visiondiv1 img"]?.[
        "max-width"
      ]?.replace("px", "")}px`,
    },
    ".vision-section .visiondiv1 h4": {
      ...settingFormData?.[".vision-section .visiondiv1 h4"],
      color: settingFormData?.[".vision-section .visiondiv1 h4"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".vision-section .visiondiv1 h4"]?.["color"],
          })
        : "",
      "font-weight": `${settingFormData?.[".vision-section .visiondiv1 h4"]?.["font-weight"]}`,
      "font-size": `${settingFormData?.[".vision-section .visiondiv1 h4"]?.[
        "font-size"
      ]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData?.[".vision-section .visiondiv1 h4"]?.[
        "margin-bottom"
      ]?.replace("px", "")}px`,
      "text-align": `${settingFormData?.[".vision-section .visiondiv1 h4"]?.["text-align"]}`,
    },
    ".vision-section .visiondiv1 p": {
      ...settingFormData?.[".vision-section .visiondiv1 p"],
      color: settingFormData?.[".vision-section .visiondiv1 p"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".vision-section .visiondiv1 p"]?.["color"],
          })
        : "",
      "font-weight": `${settingFormData?.[".vision-section .visiondiv1 p"]?.["font-weight"]}`,
      "font-size": `${settingFormData?.[".vision-section .visiondiv1 p"]?.[
        "font-size"
      ]?.replace("px", "")}px`,
      "line-height": `${settingFormData?.[".vision-section .visiondiv1 p"]?.["line-height"]}`,
    },
  };
  return newData;
};
export const themeSixFunction = ({ settingFormData, boxSection }) => {
  let newData = {
    ...settingFormData,
    ".vision-section6": boxSection
      ? {
          ...settingFormData?.[".vision-section6"],
          padding: `${settingFormData?.[".vision-section6"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
          width: `calc(100% - ${settingFormData?.[".vision-section6"]?.[
            "width"
          ]?.replace("rem)", "")}rem)`,
          margin: `${settingFormData?.[".vision-section6"]?.["margin"]
            ?.split(" ")[0]
            ?.replace("px", "")}px auto`,
          "border-radius": `${settingFormData?.[".vision-section6"]?.[
            "border-radius"
          ]?.replace("rem", "")}rem`,
        }
      : {
          ...settingFormData?.[".vision-section6"],
          padding: `${settingFormData?.[".vision-section6"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
        },
    ".vision-section6.bg-image": settingFormData?.[
      ".vision-section6.bg-image"
    ]?.["background-image"]
      ? {
          "background-image":
            settingFormData?.[".vision-section6.bg-image"]?.[
              "background-image"
            ] !== ""
              ? addUrlImage({
                  urlString: removeUrlImage({
                    urlString:
                      settingFormData[".vision-section6.bg-image"][
                        "background-image"
                      ],
                  }),
                })
              : "",
        }
      : {},
    ".vision-section6.bg-image::before": settingFormData?.[
      ".vision-section6.bg-image::before"
    ]?.["background-color"]
      ? {
          "background-color": settingFormData?.[
            ".vision-section6.bg-image::before"
          ]?.["background-color"]
            ? rgbColorCreate({
                rgbString:
                  settingFormData?.[".vision-section6.bg-image::before"]?.[
                    "background-color"
                  ],
              })
            : "",
        }
      : {},
    ".vision-section6 .section-title-area": {
      ...settingFormData?.[".vision-section6 .section-title-area"],
      "margin-bottom": `${settingFormData?.[
        ".vision-section6 .section-title-area"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".vision-section6 .section-title-area .section-subtitle": {
      ...settingFormData?.[
        ".vision-section6 .section-title-area .section-subtitle"
      ],
      "font-size": `${settingFormData?.[
        ".vision-section6 .section-title-area .section-subtitle"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".vision-section6 .section-title-area .section-subtitle"]?.["font-weight"]}`,
      color: settingFormData?.[
        ".vision-section6 .section-title-area .section-subtitle"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".vision-section6 .section-title-area .section-subtitle"
              ]?.["color"],
          })
        : "",
      "background-color": settingFormData?.[
        ".vision-section6 .section-title-area .section-subtitle"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".vision-section6 .section-title-area .section-subtitle"
              ]?.["background-color"],
          })
        : "",
      padding: `${settingFormData?.[
        ".vision-section6 .section-title-area .section-subtitle"
      ]?.["p1"]?.replace("px", "")}px ${settingFormData?.[
        ".vision-section6 .section-title-area .section-subtitle"
      ]?.["p2"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData?.[
        ".vision-section6 .section-title-area .section-subtitle"
      ]?.["border-radius"]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData?.[
        ".vision-section6 .section-title-area .section-subtitle"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".vision-section6 .section-title-area .section-title": {
      ...settingFormData?.[
        ".vision-section6 .section-title-area .section-title"
      ],
      "font-size": `${settingFormData?.[
        ".vision-section6 .section-title-area .section-title"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".vision-section6 .section-title-area .section-title"]?.["font-weight"]}`,
      color: settingFormData?.[
        ".vision-section6 .section-title-area .section-title"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".vision-section6 .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".vision-section6 .visiondiv1": {
      ...settingFormData?.[".vision-section6 .visiondiv1"],
      "background-color": settingFormData?.[".vision-section6 .visiondiv1"]?.[
        "background-color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".vision-section6 .visiondiv1"]?.[
                "background-color"
              ],
          })
        : "",
      margin: `${settingFormData?.[".vision-section6 .visiondiv1"]?.["margin"]
        ?.split(" ")[0]
        ?.replace("px", "")}px 0`,
      "border-radius": `${settingFormData?.[".vision-section6 .visiondiv1"]?.[
        "border-radius"
      ]?.replace("px", "")}px`,
      border: `${settingFormData?.[".vision-section6 .visiondiv1"]?.[
        "borderPx"
      ]?.replace("px", "")}px ${
        settingFormData?.[".vision-section6 .visiondiv1"]?.["borderThik"]
      } ${rgbColorCreate({
        rgbString:
          settingFormData?.[".vision-section6 .visiondiv1"]?.["borderColor"],
      })}`,
      "box-shadow": `${settingFormData?.[".vision-section6 .visiondiv1"]?.[
        "bs1"
      ]?.replace("px", "")}px ${settingFormData?.[
        ".vision-section6 .visiondiv1"
      ]?.["bs2"]?.replace("px", "")}px ${settingFormData?.[
        ".vision-section6 .visiondiv1"
      ]?.["bs3"]?.replace("px", "")}px ${rgbColorCreate({
        rgbString:
          settingFormData?.[".vision-section6 .visiondiv1"]?.["shadowColor"],
      })}`,
      "text-align": `${settingFormData?.[".vision-section6 .visiondiv1"]?.["text-align"]}`,
    },
    ".vision-section6 .visiondiv1:hover": {
      ...settingFormData?.[".vision-section6 .visiondiv1:hover"],
      "box-shadow": `${settingFormData?.[
        ".vision-section6 .visiondiv1:hover"
      ]?.["bs1"]?.replace("px", "")}px ${settingFormData?.[
        ".vision-section6 .visiondiv1:hover"
      ]?.["bs2"]?.replace("px", "")}px ${settingFormData?.[
        ".vision-section6 .visiondiv1:hover"
      ]?.["bs3"]?.replace("px", "")}px ${settingFormData?.[
        ".vision-section6 .visiondiv1:hover"
      ]?.["bs4"]?.replace("px", "")}px ${rgbColorCreate({
        rgbString:
          settingFormData?.[".vision-section6 .visiondiv1:hover"]?.[
            "shadowColor"
          ],
      })}`,
    },
    ".vision-section6 .visiondiv_imgs .visiondiv_sub": {
      ...settingFormData?.[".vision-section6 .visiondiv_imgs .visiondiv_sub"],
      padding: `${settingFormData?.[
        ".vision-section6 .visiondiv_imgs .visiondiv_sub"
      ]?.["p1"]?.replace("px", "")}px ${settingFormData?.[
        ".vision-section6 .visiondiv_imgs .visiondiv_sub"
      ]?.["p2"]?.replace("px", "")}px`,
    },
    ".vision-section6 .visiondiv1 h4": {
      ...settingFormData?.[".vision-section6 .visiondiv1 h4"],
      color: settingFormData?.[".vision-section6 .visiondiv1 h4"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".vision-section6 .visiondiv1 h4"]?.["color"],
          })
        : "",
      "font-weight": `${settingFormData?.[".vision-section6 .visiondiv1 h4"]?.["font-weight"]}`,
      "font-size": `${settingFormData?.[".vision-section6 .visiondiv1 h4"]?.[
        "font-size"
      ]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData?.[
        ".vision-section6 .visiondiv1 h4"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
      "text-align": `${settingFormData?.[".vision-section6 .visiondiv1 h4"]?.["text-align"]}`,
    },
    ".vision-section6 .visiondiv1 p": {
      ...settingFormData?.[".vision-section6 .visiondiv1 p"],
      color: settingFormData?.[".vision-section6 .visiondiv1 p"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".vision-section6 .visiondiv1 p"]?.["color"],
          })
        : "",
      "font-weight": `${settingFormData?.[".vision-section6 .visiondiv1 p"]?.["font-weight"]}`,
      "font-size": `${settingFormData?.[".vision-section6 .visiondiv1 p"]?.[
        "font-size"
      ]?.replace("px", "")}px`,
      "line-height": `${settingFormData?.[".vision-section6 .visiondiv1 p"]?.["line-height"]}`,
    },
    ".vision-section6 .visiondiv_sub .btn": {
      ...settingFormData?.[".vision-section6 .visiondiv_sub .btn"],
      color: settingFormData?.[".vision-section6 .visiondiv_sub .btn"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".vision-section6 .visiondiv_sub .btn"]?.[
                "color"
              ],
          })
        : "",
      "font-weight": `${settingFormData?.[".vision-section6 .visiondiv_sub .btn"]?.["font-weight"]}`,
      "font-size": `${settingFormData?.[
        ".vision-section6 .visiondiv_sub .btn"
      ]?.["font-size"]?.replace("px", "")}px`,
    },
  };
  return newData;
};
export const themeSevenFunction = ({ settingFormData, boxSection }) => {
  let newData = {
    ...settingFormData,
    ".boxes1-section": boxSection
      ? {
          ...settingFormData?.[".boxes1-section"],
          padding: `${settingFormData?.[".boxes1-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
          width: `calc(100% - ${settingFormData?.[".boxes1-section"]?.[
            "width"
          ]?.replace("rem)", "")}rem)`,
          margin: `${settingFormData?.[".boxes1-section"]?.["margin"]
            ?.split(" ")[0]
            ?.replace("px", "")}px auto`,
          "border-radius": `${settingFormData?.[".boxes1-section"]?.[
            "border-radius"
          ]?.replace("rem", "")}rem`,
        }
      : {
          ...settingFormData?.[".boxes1-section"],
          padding: `${settingFormData?.[".boxes1-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
        },
    ".boxes1-section.bg-image": settingFormData?.[".boxes1-section.bg-image"]?.[
      "background-image"
    ]
      ? {
          "background-image":
            settingFormData?.[".boxes1-section.bg-image"]?.[
              "background-image"
            ] !== ""
              ? addUrlImage({
                  urlString: removeUrlImage({
                    urlString:
                      settingFormData[".boxes1-section.bg-image"][
                        "background-image"
                      ],
                  }),
                })
              : "",
        }
      : {},
    ".boxes1-section.bg-image::before": settingFormData?.[
      ".boxes1-section.bg-image::before"
    ]?.["background-color"]
      ? {
          "background-color": settingFormData?.[
            ".boxes1-section.bg-image::before"
          ]?.["background-color"]
            ? rgbColorCreate({
                rgbString:
                  settingFormData?.[".boxes1-section.bg-image::before"]?.[
                    "background-color"
                  ],
              })
            : "",
        }
      : {},
    ".boxes1-section .section-title-area": {
      ...settingFormData?.[".boxes1-section .section-title-area"],
      "margin-bottom": `${settingFormData?.[
        ".boxes1-section .section-title-area"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".boxes1-section .section-title-area .section-subtitle": {
      ...settingFormData?.[
        ".boxes1-section .section-title-area .section-subtitle"
      ],
      "font-size": `${settingFormData?.[
        ".boxes1-section .section-title-area .section-subtitle"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".boxes1-section .section-title-area .section-subtitle"]?.["font-weight"]}`,
      color: settingFormData?.[
        ".boxes1-section .section-title-area .section-subtitle"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".boxes1-section .section-title-area .section-subtitle"
              ]?.["color"],
          })
        : "",
      "background-color": settingFormData?.[
        ".boxes1-section .section-title-area .section-subtitle"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".boxes1-section .section-title-area .section-subtitle"
              ]?.["background-color"],
          })
        : "",
      padding: `${settingFormData?.[
        ".boxes1-section .section-title-area .section-subtitle"
      ]?.["p1"]?.replace("px", "")}px ${settingFormData?.[
        ".boxes1-section .section-title-area .section-subtitle"
      ]?.["p2"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData?.[
        ".boxes1-section .section-title-area .section-subtitle"
      ]?.["border-radius"]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData?.[
        ".boxes1-section .section-title-area .section-subtitle"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".boxes1-section .section-title-area .section-title": {
      ...settingFormData?.[
        ".boxes1-section .section-title-area .section-title"
      ],
      "font-size": `${settingFormData?.[
        ".boxes1-section .section-title-area .section-title"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".boxes1-section .section-title-area .section-title"]?.["font-weight"]}`,
      color: settingFormData?.[
        ".boxes1-section .section-title-area .section-title"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".boxes1-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".boxes1-section .cus-boxes": {
      ...settingFormData?.[".boxes1-section .cus-boxes"],
      background: settingFormData?.[".boxes1-section .cus-boxes"]?.[
        "background"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".boxes1-section .cus-boxes"]?.["background"],
          })
        : "",
      padding: `${settingFormData?.[".boxes1-section .cus-boxes"]?.[
        "p1"
      ]?.replace("px", "")}px ${settingFormData?.[
        ".boxes1-section .cus-boxes"
      ]?.["p2"]?.replace("px", "")}px ${settingFormData?.[
        ".boxes1-section .cus-boxes"
      ]?.["p3"]?.replace("px", "")}px`,
      margin: `${settingFormData?.[".boxes1-section .cus-boxes"]?.[
        "m1"
      ]?.replace("px", "")}px ${settingFormData?.[
        ".boxes1-section .cus-boxes"
      ]?.["m2"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData?.[".boxes1-section .cus-boxes"]?.[
        "border-radius"
      ]?.replace("px", "")}px`,
    },
    ".boxes1-section .cus-boxes:before": {
      ...settingFormData?.[".boxes1-section .cus-boxes:before"],
      height: `${settingFormData?.[".boxes1-section .cus-boxes:before"]?.[
        "height"
      ]?.replace("px", "")}px`,
      "background-color": settingFormData?.[
        ".boxes1-section .cus-boxes:before"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".boxes1-section .cus-boxes:before"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".boxes1-section .flaticon": {
      ...settingFormData?.[".boxes1-section .flaticon"],
      width: `${settingFormData?.[".boxes1-section .flaticon"]?.[
        "width"
      ]?.replace("px", "")}px`,
      height: `${settingFormData?.[".boxes1-section .flaticon"]?.[
        "height"
      ]?.replace("px", "")}px`,
      "border-radius": `${settingFormData?.[".boxes1-section .flaticon"]?.[
        "border-radius"
      ]?.replace("%", "")}%`,
      margin: `${settingFormData?.[".boxes1-section .flaticon"]?.["margin"]
        ?.split(" ")[0]
        ?.replace("px", "")}px auto`,
      background: settingFormData?.[".boxes1-section .flaticon"]?.["background"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".boxes1-section .flaticon"]?.["background"],
          })
        : "",
    },
    ".boxes1-section .cus-boxes:hover .flaticon": {
      ...settingFormData?.[".boxes1-section .cus-boxes:hover .flaticon"],
      background: settingFormData?.[
        ".boxes1-section .cus-boxes:hover .flaticon"
      ]?.["background"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".boxes1-section .cus-boxes:hover .flaticon"]?.[
                "background"
              ],
          })
        : "",
    },
    ".boxes1-section .subtitle": {
      ...settingFormData?.[".boxes1-section .subtitle"],
      color: settingFormData?.[".boxes1-section .subtitle"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".boxes1-section .subtitle"]?.["color"],
          })
        : "",
      "font-weight": `${settingFormData?.[".boxes1-section .subtitle"]?.["font-weight"]}`,
      "line-height": `${settingFormData?.[".boxes1-section .subtitle"]?.["line-height"]}`,
      "font-size": `${settingFormData?.[".boxes1-section .subtitle"]?.[
        "font-size"
      ]?.replace("px", "")}px`,
      margin: `${settingFormData?.[".boxes1-section .subtitle"]?.[
        "m1"
      ]?.replace("px", "")}px ${settingFormData?.[
        ".boxes1-section .subtitle"
      ]?.["m2"]?.replace("px", "")}px ${settingFormData?.[
        ".boxes1-section .subtitle"
      ]?.["m3"]?.replace("px", "")}px`,
    },
    ".boxes1-section .cus-boxes:hover .subtitle": {
      ...settingFormData?.[".boxes1-section .cus-boxes:hover .subtitle"],
      color: settingFormData?.[".boxes1-section .cus-boxes:hover .subtitle"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".boxes1-section .cus-boxes:hover .subtitle"]?.[
                "color"
              ],
          })
        : "",
    },
  };
  return newData;
};

export const themeFourFunction = ({ settingFormData, boxSection }) => {
  let newData = {
    ...settingFormData,
    ".vision2-section": boxSection
      ? {
          ...settingFormData?.[".vision2-section"],
          padding: `${settingFormData?.[".vision2-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
          width: `calc(100% - ${settingFormData?.[".vision2-section"]?.[
            "width"
          ]?.replace("rem)", "")}rem)`,
          margin: `${settingFormData?.[".vision2-section"]?.["margin"]
            ?.split(" ")[0]
            ?.replace("px", "")}px auto`,
          "border-radius": `${settingFormData?.[".vision2-section"]?.[
            "border-radius"
          ]?.replace("rem", "")}rem`,
        }
      : {
          ...settingFormData?.[".vision2-section"],
          padding: `${settingFormData?.[".vision2-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
        },
    ".vision2-section.bg-image": settingFormData?.[
      ".vision2-section.bg-image"
    ]?.["background-image"]
      ? {
          "background-image":
            settingFormData?.[".vision2-section.bg-image"]?.[
              "background-image"
            ] !== ""
              ? addUrlImage({
                  urlString: removeUrlImage({
                    urlString:
                      settingFormData[".vision2-section.bg-image"][
                        "background-image"
                      ],
                  }),
                })
              : "",
        }
      : {},
    ".vision2-section.bg-image::before": settingFormData?.[
      ".vision2-section.bg-image::before"
    ]?.["background-color"]
      ? {
          "background-color": settingFormData?.[
            ".vision2-section.bg-image::before"
          ]?.["background-color"]
            ? rgbColorCreate({
                rgbString:
                  settingFormData?.[".vision2-section.bg-image::before"]?.[
                    "background-color"
                  ],
              })
            : "",
        }
      : {},
    ".vision2-section .section-title-area": {
      ...settingFormData?.[".vision2-section .section-title-area"],
      "margin-bottom": `${settingFormData?.[
        ".vision2-section .section-title-area"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".vision2-section .section-title-area .section-subtitle": {
      ...settingFormData?.[
        ".vision2-section .section-title-area .section-subtitle"
      ],
      "font-size": `${settingFormData?.[
        ".vision2-section .section-title-area .section-subtitle"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".vision2-section .section-title-area .section-subtitle"]?.["font-weight"]}`,
      color: settingFormData?.[
        ".vision2-section .section-title-area .section-subtitle"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".vision2-section .section-title-area .section-subtitle"
              ]?.["color"],
          })
        : "",
      "background-color": settingFormData?.[
        ".vision2-section .section-title-area .section-subtitle"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".vision2-section .section-title-area .section-subtitle"
              ]?.["background-color"],
          })
        : "",
      padding: `${settingFormData?.[
        ".vision2-section .section-title-area .section-subtitle"
      ]?.["p1"]?.replace("px", "")}px ${settingFormData?.[
        ".vision2-section .section-title-area .section-subtitle"
      ]?.["p2"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData?.[
        ".vision2-section .section-title-area .section-subtitle"
      ]?.["border-radius"]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData?.[
        ".vision2-section .section-title-area .section-subtitle"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".vision2-section .section-title-area .section-title": {
      ...settingFormData?.[
        ".vision2-section .section-title-area .section-title"
      ],
      "font-size": `${settingFormData?.[
        ".vision2-section .section-title-area .section-title"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".vision2-section .section-title-area .section-title"]?.["font-weight"]}`,
      color: settingFormData?.[
        ".vision2-section .section-title-area .section-title"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".vision2-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".vision2-section .visiondiv2": {
      ...settingFormData?.[".vision2-section .visiondiv2"],
      padding: `${settingFormData?.[".vision2-section .visiondiv2"]?.[
        "padding"
      ]?.replace("px", "")}px`,
      "border-radius": `${settingFormData?.[".vision2-section .visiondiv2"]?.[
        "border-radius"
      ]?.replace("px", "")}px`,
      "text-align": `${settingFormData?.[".vision2-section .visiondiv2"]?.["text-align"]}`,
    },
    ".vision2-section .visiondiv2:hover": {
      ...settingFormData?.[".vision2-section .visiondiv2:hover"],
      transform: `translateY(${settingFormData?.[
        ".vision2-section .visiondiv2:hover"
      ]?.["transform"]?.replace("px)", "")}px)`,
    },
    ".vision2-section .visiondiv2 img": {
      ...settingFormData?.[".vision2-section .visiondiv2 img"],
      "margin-bottom": `${settingFormData?.[
        ".vision2-section .visiondiv2 img"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
      "max-width": `${settingFormData?.[".vision2-section .visiondiv2 img"]?.[
        "max-width"
      ]?.replace("px", "")}px`,
    },
    ".vision2-section .visiondiv2 h4": {
      ...settingFormData?.[".vision2-section .visiondiv2 h4"],
      color: settingFormData?.[".vision2-section .visiondiv2 h4"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".vision2-section .visiondiv2 h4"]?.["color"],
          })
        : "",
      "font-weight": `${settingFormData?.[".vision2-section .visiondiv2 h4"]?.["font-weight"]}`,
      "font-size": `${settingFormData?.[".vision2-section .visiondiv2 h4"]?.[
        "font-size"
      ]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData?.[
        ".vision2-section .visiondiv2 h4"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".vision2-section .visiondiv2 p": {
      ...settingFormData?.[".vision2-section .visiondiv2 p"],
      color: settingFormData?.[".vision2-section .visiondiv2 p"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".vision2-section .visiondiv2 p"]?.["color"],
          })
        : "",
      "font-weight": `${settingFormData?.[".vision2-section .visiondiv2 p"]?.["font-weight"]}`,
      "font-size": `${settingFormData?.[".vision2-section .visiondiv2 p"]?.[
        "font-size"
      ]?.replace("px", "")}px`,
      "line-height": `${settingFormData?.[".vision2-section .visiondiv2 p"]?.["line-height"]}`,
    },
  };
  return newData;
};

export const serviceStyleOneSet = (payload, mainClass) => {
  return {
    ".service-section .section-title-area .section-title": {
      "font-size":
        payload.style?.[
          ".service-section .section-title-area .section-title"
        ]?.["font-size"],
      "font-weight":
        payload.style?.[
          ".service-section .section-title-area .section-title"
        ]?.["font-weight"],
      color: payload.style?.[
        ".service-section .section-title-area .section-title"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".service-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".service-section .servicediv": {
      margin: payload.style?.[".service-section .servicediv"]?.["margin"],
      padding: payload.style?.[".service-section .servicediv"]?.["padding"],
      outline: payload.style?.[".service-section .servicediv"]?.["outline"],
      outPx: payload.style?.[".service-section .servicediv"]?.["outPx"],
      outThik: payload.style?.[".service-section .servicediv"]?.["outThik"],
      outColor: payload.style?.[".service-section .servicediv"]?.["outColor"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".service-section .servicediv"]?.["outColor"],
          })
        : "",
      "background-color": payload.style?.[".service-section .servicediv"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".service-section .servicediv"]?.[
                "background-color"
              ],
          })
        : "",
      "box-shadow":
        payload.style?.[".service-section .servicediv"]?.["box-shadow"],
      bs1: payload.style?.[".service-section .servicediv"]?.["bs1"],
      bs2: payload.style?.[".service-section .servicediv"]?.["bs2"],
      bs3: payload.style?.[".service-section .servicediv"]?.["bs3"],
      bs4: payload.style?.[".service-section .servicediv"]?.["bs4"],
      shadowColor: payload.style?.[".service-section .servicediv"]?.[
        "shadowColor"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".service-section .servicediv"]?.["shadowColor"],
          })
        : "",
      "border-radius":
        payload.style?.[".service-section .servicediv"]?.["border-radius"],
    },
    ".service-section .servicediv h4": {
      color: payload.style?.[".service-section .servicediv h4"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".service-section .servicediv h4"]?.["color"],
          })
        : "",
      "font-weight":
        payload.style?.[".service-section .servicediv h4"]?.["font-weight"],
      "font-size":
        payload.style?.[".service-section .servicediv h4"]?.["font-size"],
      "margin-bottom":
        payload.style?.[".service-section .servicediv h4"]?.["margin-bottom"],
      "text-align":
        payload.style?.[".service-section .servicediv h4"]?.["text-align"],
    },
    ".service-section .servicediv p": {
      color: payload.style?.[".service-section .servicediv p"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".service-section .servicediv p"]?.["color"],
          })
        : "",
      "font-weight":
        payload.style?.[".service-section .servicediv p"]?.["font-weight"],
      "font-size":
        payload.style?.[".service-section .servicediv p"]?.["font-size"],
      "line-height":
        payload.style?.[".service-section .servicediv p"]?.["line-height"],
      "margin-bottom":
        payload.style?.[".service-section .servicediv p"]?.["margin-bottom"],
      "text-align":
        payload.style?.[".service-section .servicediv p"]?.["text-align"],
    },
    ".service-section .servicediv .links": {
      "font-size":
        payload.style?.[".service-section .servicediv .links"]?.["font-size"],
      "font-weight":
        payload.style?.[".service-section .servicediv .links"]?.["font-weight"],
      color: payload.style?.[".service-section .servicediv .links"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".service-section .servicediv .links"]?.["color"],
          })
        : "",
    },
    ".service-slider .slick-arrow:before": {
      color: payload.style?.[".service-slider .slick-arrow:before"]?.["color"]
        ? convertRGBA({
            rgbString: payload.style?.[".service-slider .slick-arrow:before"]?.[
              "color"
            ]?.replace(" !important", ""),
          })
        : "",
    },
    ".service-slider .service-box .servicediv": {
      "background-color": payload.style?.[
        ".service-slider .service-box .servicediv"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".service-slider .service-box .servicediv"]?.[
                "background-color"
              ],
          })
        : "",
      "border-radius":
        payload.style?.[".service-slider .service-box .servicediv"]?.[
          "border-radius"
        ],
    },
    ".service-section .slick-dots li button:before": {
      "font-size":
        payload.style?.[".service-section .slick-dots li button:before"]?.[
          "font-size"
        ],
      color: payload.style?.[".service-section .slick-dots li button:before"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString: payload.style?.[
              ".service-section .slick-dots li button:before"
            ]?.["color"]?.replace(" !important", ""),
          })
        : "",
      opacity:
        payload.style?.[".service-section .slick-dots li button:before"]?.[
          "opacity"
        ],
    },
    ".service-section .slick-dots li.slick-active button:before": {
      color: payload.style?.[
        ".service-section .slick-dots li.slick-active button:before"
      ]?.["color"]
        ? convertRGBA({
            rgbString: payload.style?.[
              ".service-section .slick-dots li.slick-active button:before"
            ]?.["color"]?.replace(" !important", ""),
          })
        : "",
    },
  };
};
export const serviceStyleTwoSet = (payload, mainClass) => {
  return {
    ".service2-section .section-title-area .section-title": {
      "font-size":
        payload.style?.[
          ".service2-section .section-title-area .section-title"
        ]?.["font-size"],
      "font-weight":
        payload.style?.[
          ".service2-section .section-title-area .section-title"
        ]?.["font-weight"],
      color: payload.style?.[
        ".service2-section .section-title-area .section-title"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".service2-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".service2-section .servicediv": {
      margin: payload.style?.[".service2-section .servicediv"]?.["margin"],
      padding: payload.style?.[".service2-section .servicediv"]?.["padding"],
      outline: payload.style?.[".service2-section .servicediv"]?.["outline"],
      outPx: payload.style?.[".service2-section .servicediv"]?.["outPx"],
      outThik: payload.style?.[".service2-section .servicediv"]?.["outThik"],
      outColor: payload.style?.[".service2-section .servicediv"]?.["outColor"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".service2-section .servicediv"]?.["outColor"],
          })
        : "",
      "background-color": payload.style?.[".service2-section .servicediv"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".service2-section .servicediv"]?.[
                "background-color"
              ],
          })
        : "",
      "box-shadow":
        payload.style?.[".service2-section .servicediv"]?.["box-shadow"],
      bs1: payload.style?.[".service2-section .servicediv"]?.["bs1"],
      bs2: payload.style?.[".service2-section .servicediv"]?.["bs2"],
      bs3: payload.style?.[".service2-section .servicediv"]?.["bs3"],
      bs4: payload.style?.[".service2-section .servicediv"]?.["bs4"],
      shadowColor: payload.style?.[".service2-section .servicediv"]?.[
        "shadowColor"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".service2-section .servicediv"]?.["shadowColor"],
          })
        : "",
      "border-radius":
        payload.style?.[".service2-section .servicediv"]?.["border-radius"],
    },
    ".service2-section .servicediv h4": {
      color: payload.style?.[".service2-section .servicediv h4"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".service2-section .servicediv h4"]?.["color"],
          })
        : "",
      "font-weight":
        payload.style?.[".service2-section .servicediv h4"]?.["font-weight"],
      "font-size":
        payload.style?.[".service2-section .servicediv h4"]?.["font-size"],
      "margin-bottom":
        payload.style?.[".service2-section .servicediv h4"]?.["margin-bottom"],
      "text-align":
        payload.style?.[".service2-section .servicediv h4"]?.["text-align"],
    },
    ".service2-section .servicediv p": {
      color: payload.style?.[".service2-section .servicediv p"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".service2-section .servicediv p"]?.["color"],
          })
        : "",
      "font-weight":
        payload.style?.[".service2-section .servicediv p"]?.["font-weight"],
      "font-size":
        payload.style?.[".service2-section .servicediv p"]?.["font-size"],
      "line-height":
        payload.style?.[".service2-section .servicediv p"]?.["line-height"],
      "margin-bottom":
        payload.style?.[".service2-section .servicediv p"]?.["margin-bottom"],
      "text-align":
        payload.style?.[".service2-section .servicediv p"]?.["text-align"],
    },
    ".service2-section .servicediv .links": {
      "font-size":
        payload.style?.[".service2-section .servicediv .links"]?.["font-size"],
      "font-weight":
        payload.style?.[".service2-section .servicediv .links"]?.[
          "font-weight"
        ],
      color: payload.style?.[".service2-section .servicediv .links"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".service2-section .servicediv .links"]?.[
                "color"
              ],
          })
        : "",
    },
    ".service-slider .slick-arrow::before": {
      color: payload.style?.[".service-slider .slick-arrow::before"]?.["color"]
        ? convertRGBA({
            rgbString: payload.style?.[
              ".service-slider .slick-arrow::before"
            ]?.["color"]?.replace(" !important", ""),
          })
        : "",
    },
    ".service-slider .service-box .servicediv": {
      "background-color": payload.style?.[
        ".service-slider .service-box .servicediv"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".service-slider .service-box .servicediv"]?.[
                "background-color"
              ],
          })
        : "",
      "border-radius":
        payload.style?.[".service-slider .service-box .servicediv"]?.[
          "border-radius"
        ],
    },
    ".service2-section .slick-dots li button:before": {
      "font-size": payload.style?.[
        ".service2-section .slick-dots li button:before"
      ]?.["font-size"]?.replace(" !important", ""),
      color: payload.style?.[
        ".service2-section .slick-dots li button:before"
      ]?.["color"]
        ? convertRGBA({
            rgbString: payload.style?.[
              ".service2-section .slick-dots li button:before"
            ]?.["color"]?.replace(" !important", ""),
          })
        : "",
      opacity: payload.style?.[
        ".service2-section .slick-dots li button:before"
      ]?.["opacity"]?.replace(" !important", ""),
    },
    ".service2-section .slick-dots li.slick-active button:before": {
      color: payload.style?.[
        ".service2-section .slick-dots li.slick-active button:before"
      ]?.["color"]
        ? convertRGBA({
            rgbString: payload.style?.[
              ".service2-section .slick-dots li.slick-active button:before"
            ]?.["color"]?.replace(" !important", ""),
          })
        : "",
    },
  };
};
export const serviceStyleEighthSet = (payload, mainClass) => {
  return {
    ".boxes2-section .section-title-area .section-title": {
      "font-size":
        payload.style?.[".boxes2-section .section-title-area .section-title"]?.[
          "font-size"
        ],
      "font-weight":
        payload.style?.[".boxes2-section .section-title-area .section-title"]?.[
          "font-weight"
        ],
      color: payload.style?.[
        ".boxes2-section .section-title-area .section-title"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".boxes2-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".boxes2-section .cus-boxes": {
      "border-radius":
        payload.style?.[".boxes2-section .cus-boxes"]?.["border-radius"],
      padding: payload.style?.[".boxes2-section .cus-boxes"]?.["padding"],
      p1: payload.style?.[".boxes2-section .cus-boxes"]?.["p1"],
      p2: payload.style?.[".boxes2-section .cus-boxes"]?.["p2"],
      margin: payload.style?.[".boxes2-section .cus-boxes"]?.["margin"],
    },
    ".boxes2-section .cus-boxes:hover": {
      "background-color": payload.style?.[".boxes2-section .cus-boxes:hover"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".boxes2-section .cus-boxes:hover"]?.[
                "background-color"
              ],
          })
        : "",
      "box-shadow":
        payload.style?.[".boxes2-section .cus-boxes:hover"]?.["box-shadow"],
      bs1: payload.style?.[".boxes2-section .cus-boxes:hover"]?.["bs1"],
      bs2: payload.style?.[".boxes2-section .cus-boxes:hover"]?.["bs2"],
      bs3: payload.style?.[".boxes2-section .cus-boxes:hover"]?.["bs3"],
      shadowColor: payload.style?.[".boxes2-section .cus-boxes:hover"]?.[
        "shadowColor"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".boxes2-section .cus-boxes:hover"]?.[
                "shadowColor"
              ],
          })
        : "",
    },
    ".boxes2-section .flaticon": {
      "max-width": payload.style?.[".boxes2-section .flaticon"]?.["max-width"],
    },
    ".boxes2-section .subtitle": {
      color: payload.style?.[".boxes2-section .subtitle"]?.["color"]
        ? convertRGBA({
            rgbString: payload.style?.[".boxes2-section .subtitle"]?.["color"],
          })
        : "",
      "font-size": payload.style?.[".boxes2-section .subtitle"]?.["font-size"],
      "line-height":
        payload.style?.[".boxes2-section .subtitle"]?.["line-height"],
      "font-weight":
        payload.style?.[".boxes2-section .subtitle"]?.["font-weight"],
      margin: payload.style?.[".boxes2-section .subtitle"]?.["margin"],
      m1: payload.style?.[".boxes2-section .subtitle"]?.["m1"],
      m2: payload.style?.[".boxes2-section .subtitle"]?.["m2"],
      m3: payload.style?.[".boxes2-section .subtitle"]?.["m3"],
    },
  };
};
export const serviceStyleNineSet = (payload, mainClass) => {
  return {
    ".boxes3-section .section-title-area .section-title": {
      "font-size":
        payload.style?.[".boxes3-section .section-title-area .section-title"]?.[
          "font-size"
        ],
      "font-weight":
        payload.style?.[".boxes3-section .section-title-area .section-title"]?.[
          "font-weight"
        ],
      color: payload.style?.[
        ".boxes3-section .section-title-area .section-title"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".boxes3-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".boxes3-section .cus-boxes": {
      "background-color": payload.style?.[".boxes3-section .cus-boxes"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".boxes3-section .cus-boxes"]?.[
                "background-color"
              ],
          })
        : "",
      "border-radius":
        payload.style?.[".boxes3-section .cus-boxes"]?.["border-radius"],
      padding: payload.style?.[".boxes3-section .cus-boxes"]?.["padding"],
      p1: payload.style?.[".boxes3-section .cus-boxes"]?.["p1"],
      p2: payload.style?.[".boxes3-section .cus-boxes"]?.["p2"],
      margin: payload.style?.[".boxes3-section .cus-boxes"]?.["margin"],
      border: payload.style?.[".boxes3-section .cus-boxes"]?.["border"],
      borderPx: payload.style?.[".boxes3-section .cus-boxes"]?.["borderPx"],
      borderThik: payload.style?.[".boxes3-section .cus-boxes"]?.["borderThik"],
      borderColor:
        payload.style?.[".boxes3-section .cus-boxes"]?.["borderColor"],
    },
    ".boxes3-section .flaticon": {
      "max-width": payload.style?.[".boxes3-section .flaticon"]?.["max-width"],
    },
    ".boxes3-section .subtitle": {
      color: payload.style?.[".boxes3-section .subtitle"]?.["color"]
        ? convertRGBA({
            rgbString: payload.style?.[".boxes3-section .subtitle"]?.["color"],
          })
        : "",
      "font-size": payload.style?.[".boxes3-section .subtitle"]?.["font-size"],
      "line-height":
        payload.style?.[".boxes3-section .subtitle"]?.["line-height"],
      "font-weight":
        payload.style?.[".boxes3-section .subtitle"]?.["font-weight"],
      margin: payload.style?.[".boxes3-section .subtitle"]?.["margin"],
      m1: payload.style?.[".boxes3-section .subtitle"]?.["m1"],
      m2: payload.style?.[".boxes3-section .subtitle"]?.["m2"],
      m3: payload.style?.[".boxes3-section .subtitle"]?.["m3"],
    },
  };
};
export const serviceStyleTenSet = (payload, mainClass) => {
  return {
    ".boxes4-section .section-title-area .section-title": {
      "font-size":
        payload.style?.[".boxes4-section .section-title-area .section-title"]?.[
          "font-size"
        ],
      "font-weight":
        payload.style?.[".boxes4-section .section-title-area .section-title"]?.[
          "font-weight"
        ],
      color: payload.style?.[
        ".boxes4-section .section-title-area .section-title"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".boxes4-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".boxes4-section .cus-boxes": {
      backColorOne: payload.style?.[".boxes4-section .cus-boxes"]?.[
        "backColorOne"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".boxes4-section .cus-boxes"]?.["backColorOne"],
          })
        : "",
      backColorTwo: payload.style?.[".boxes4-section .cus-boxes"]?.[
        "backColorTwo"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".boxes4-section .cus-boxes"]?.["backColorTwo"],
          })
        : "",
      "border-radius":
        payload.style?.[".boxes4-section .cus-boxes"]?.["border-radius"],
      padding: payload.style?.[".boxes4-section .cus-boxes"]?.["padding"],
      p1: payload.style?.[".boxes4-section .cus-boxes"]?.["p1"],
      p2: payload.style?.[".boxes4-section .cus-boxes"]?.["p2"],
      margin: payload.style?.[".boxes4-section .cus-boxes"]?.["margin"],
      "box-shadow":
        payload.style?.[".boxes4-section .cus-boxes"]?.["box-shadow"],
      bs1: payload.style?.[".boxes4-section .cus-boxes"]?.["bs1"],
      bs2: payload.style?.[".boxes4-section .cus-boxes"]?.["bs2"],
      bs3: payload.style?.[".boxes4-section .cus-boxes"]?.["bs3"],
      shadowColor:
        payload.style?.[".boxes4-section .cus-boxes"]?.["shadowColor"],
    },
    ".boxes4-section .flaticon": {
      "max-width": payload.style?.[".boxes4-section .flaticon"]?.["max-width"],
    },
    ".boxes4-section .subtitle": {
      color: payload.style?.[".boxes4-section .subtitle"]?.["color"]
        ? convertRGBA({
            rgbString: payload.style?.[".boxes4-section .subtitle"]?.["color"],
          })
        : "",
      "font-size": payload.style?.[".boxes4-section .subtitle"]?.["font-size"],
      "line-height":
        payload.style?.[".boxes4-section .subtitle"]?.["line-height"],
      "font-weight":
        payload.style?.[".boxes4-section .subtitle"]?.["font-weight"],
      margin: payload.style?.[".boxes4-section .subtitle"]?.["margin"],
      m1: payload.style?.[".boxes4-section .subtitle"]?.["m1"],
      m2: payload.style?.[".boxes4-section .subtitle"]?.["m2"],
      m3: payload.style?.[".boxes4-section .subtitle"]?.["m3"],
    },
  };
};
export const serviceStyleElevenSet = (payload, mainClass) => {
  return {
    ".left-boxes1-section .cus-boxes": {
      "background-color": payload.style?.[".left-boxes1-section .cus-boxes"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".left-boxes1-section .cus-boxes"]?.[
                "background-color"
              ],
          })
        : "",
      "border-radius":
        payload.style?.[".left-boxes1-section .cus-boxes"]?.["border-radius"],
      padding: payload.style?.[".left-boxes1-section .cus-boxes"]?.["padding"],
      p1: payload.style?.[".left-boxes1-section .cus-boxes"]?.["p1"],
      p2: payload.style?.[".left-boxes1-section .cus-boxes"]?.["p2"],
      margin: payload.style?.[".left-boxes1-section .cus-boxes"]?.["margin"],
      "box-shadow":
        payload.style?.[".left-boxes1-section .cus-boxes"]?.["box-shadow"],
      bs1: payload.style?.[".left-boxes1-section .cus-boxes"]?.["bs1"],
      bs2: payload.style?.[".left-boxes1-section .cus-boxes"]?.["bs2"],
      bs3: payload.style?.[".left-boxes1-section .cus-boxes"]?.["bs3"],
      bs4: payload.style?.[".left-boxes1-section .cus-boxes"]?.["bs4"],
      shadowColor: payload.style?.[".left-boxes1-section .cus-boxes"]?.[
        "shadowColor"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".left-boxes1-section .cus-boxes"]?.[
                "shadowColor"
              ],
          })
        : "",
      border: payload.style?.[".left-boxes1-section .cus-boxes"]?.["border"],
      borderPx:
        payload.style?.[".left-boxes1-section .cus-boxes"]?.["borderPx"],
      borderThik:
        payload.style?.[".left-boxes1-section .cus-boxes"]?.["borderThik"],
      borderColor: payload.style?.[".left-boxes1-section .cus-boxes"]?.[
        "borderColor"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".left-boxes1-section .cus-boxes"]?.[
                "borderColor"
              ],
          })
        : "",
    },
    ".left-boxes1-section .flaticon": {
      width: payload.style?.[".left-boxes1-section .flaticon"]?.["width"],
      height: payload.style?.[".left-boxes1-section .flaticon"]?.["height"],
      "margin-right":
        payload.style?.[".left-boxes1-section .flaticon"]?.["margin-right"],
      "border-radius":
        payload.style?.[".left-boxes1-section .flaticon"]?.["border-radius"],
      "background-color": payload.style?.[".left-boxes1-section .flaticon"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".left-boxes1-section .flaticon"]?.[
                "background-color"
              ],
          })
        : "",
      border: payload.style?.[".left-boxes1-section .flaticon"]?.["border"],
      borderPx: payload.style?.[".left-boxes1-section .flaticon"]?.["borderPx"],
      borderThik:
        payload.style?.[".left-boxes1-section .flaticon"]?.["borderThik"],
      borderColor: payload.style?.[".left-boxes1-section .flaticon"]?.[
        "borderColor"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".left-boxes1-section .flaticon"]?.[
                "borderColor"
              ],
          })
        : "",
    },
    ".left-boxes1-section .subdiv h4": {
      color: payload.style?.[".left-boxes1-section .subdiv h4"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".left-boxes1-section .subdiv h4"]?.["color"],
          })
        : "",
      "font-size":
        payload.style?.[".left-boxes1-section .subdiv h4"]?.["font-size"],
      "line-height":
        payload.style?.[".left-boxes1-section .subdiv h4"]?.["line-height"],
      "font-weight":
        payload.style?.[".left-boxes1-section .subdiv h4"]?.["font-weight"],
    },
    ".left-boxes1-section .subdiv p": {
      color: payload.style?.[".left-boxes1-section .subdiv p"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".left-boxes1-section .subdiv p"]?.["color"],
          })
        : "",
      "font-size":
        payload.style?.[".left-boxes1-section .subdiv p"]?.["font-size"],
      "line-height":
        payload.style?.[".left-boxes1-section .subdiv p"]?.["line-height"],
      "font-weight":
        payload.style?.[".left-boxes1-section .subdiv p"]?.["font-weight"],
    },
  };
};
export const serviceStyleTwelveSet = (payload, mainClass) => {
  return {
    ".left-boxes2-section .cus-boxes": {
      padding: payload.style?.[".left-boxes2-section .cus-boxes"]?.["padding"],
      margin: payload.style?.[".left-boxes2-section .cus-boxes"]?.["margin"],
      "border-right":
        payload.style?.[".left-boxes2-section .cus-boxes"]?.["border-right"],
      borderPx:
        payload.style?.[".left-boxes2-section .cus-boxes"]?.["borderPx"],
      borderThik:
        payload.style?.[".left-boxes2-section .cus-boxes"]?.["borderThik"],
      borderColor: payload.style?.[".left-boxes2-section .cus-boxes"]?.[
        "borderColor"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".left-boxes2-section .cus-boxes"]?.[
                "borderColor"
              ],
          })
        : "",
    },
    ".left-boxes2-section .flaticon": {
      width: payload.style?.[".left-boxes2-section .flaticon"]?.["width"],
      "margin-right":
        payload.style?.[".left-boxes2-section .flaticon"]?.["margin-right"],
    },
    ".left-boxes2-section .subdiv h4": {
      color: payload.style?.[".left-boxes2-section .subdiv h4"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".left-boxes2-section .subdiv h4"]?.["color"],
          })
        : "",
      "font-size":
        payload.style?.[".left-boxes2-section .subdiv h4"]?.["font-size"],
      "line-height":
        payload.style?.[".left-boxes2-section .subdiv h4"]?.["line-height"],
      "font-weight":
        payload.style?.[".left-boxes2-section .subdiv h4"]?.["font-weight"],
      "margin-bottom":
        payload.style?.[".left-boxes2-section .subdiv h4"]?.["margin-bottom"],
    },
    ".left-boxes2-section .subdiv p": {
      color: payload.style?.[".left-boxes2-section .subdiv p"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".left-boxes2-section .subdiv p"]?.["color"],
          })
        : "",
      "font-size":
        payload.style?.[".left-boxes2-section .subdiv p"]?.["font-size"],
      "line-height":
        payload.style?.[".left-boxes2-section .subdiv p"]?.["line-height"],
      "font-weight":
        payload.style?.[".left-boxes2-section .subdiv p"]?.["font-weight"],
    },
  };
};
export const serviceStyleThirteenSet = (payload, mainClass) => {
  return {
    ".left-boxes3-section .boxdiv": {
      padding: payload.style?.[".left-boxes3-section .boxdiv"]?.["padding"],
      p1: payload.style?.[".left-boxes3-section .boxdiv"]?.["p1"],
      p2: payload.style?.[".left-boxes3-section .boxdiv"]?.["p2"],
      border: payload.style?.[".left-boxes3-section .boxdiv"]?.["border"],
      borderPx: payload.style?.[".left-boxes3-section .boxdiv"]?.["borderPx"],
      borderThik:
        payload.style?.[".left-boxes3-section .boxdiv"]?.["borderThik"],
      borderColor: payload.style?.[".left-boxes3-section .boxdiv"]?.[
        "borderColor"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".left-boxes3-section .boxdiv"]?.["borderColor"],
          })
        : "",
      "background-color": payload.style?.[".left-boxes3-section .boxdiv"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".left-boxes3-section .boxdiv"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".left-boxes3-section .boxdiv:hover": {
      "background-color": payload.style?.[
        ".left-boxes3-section .boxdiv:hover"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".left-boxes3-section .boxdiv:hover"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".left-boxes3-section .boxdiv h5": {
      color: payload.style?.[".left-boxes3-section .boxdiv h5"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".left-boxes3-section .boxdiv h5"]?.["color"],
          })
        : "",
      "font-size":
        payload.style?.[".left-boxes3-section .boxdiv h5"]?.["font-size"],
      "font-weight":
        payload.style?.[".left-boxes3-section .boxdiv h5"]?.["font-weight"],
      "margin-bottom":
        payload.style?.[".left-boxes3-section .boxdiv h5"]?.["margin-bottom"],
    },
    ".left-boxes3-section .boxdiv:hover h5": {
      color: payload.style?.[".left-boxes3-section .boxdiv:hover h5"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".left-boxes3-section .boxdiv:hover h5"]?.[
                "color"
              ],
          })
        : "",
    },
    ".left-boxes3-section .boxdiv p": {
      color: payload.style?.[".left-boxes3-section .boxdiv p"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".left-boxes3-section .boxdiv p"]?.["color"],
          })
        : "",
      "font-size":
        payload.style?.[".left-boxes3-section .boxdiv p"]?.["font-size"],
      "line-height":
        payload.style?.[".left-boxes3-section .boxdiv p"]?.["line-height"],
      "font-weight":
        payload.style?.[".left-boxes3-section .boxdiv p"]?.["font-weight"],
      "margin-bottom":
        payload.style?.[".left-boxes3-section .boxdiv p"]?.["margin-bottom"],
    },
    ".left-boxes3-section .boxdiv:hover p": {
      color: payload.style?.[".left-boxes3-section .boxdiv:hover p"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".left-boxes3-section .boxdiv:hover p"]?.[
                "color"
              ],
          })
        : "",
    },
    ".left-boxes3-section .boxdiv .links": {
      color: payload.style?.[".left-boxes3-section .boxdiv .links"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".left-boxes3-section .boxdiv .links"]?.["color"],
          })
        : "",
      "font-size":
        payload.style?.[".left-boxes3-section .boxdiv .links"]?.["font-size"],
      "font-weight":
        payload.style?.[".left-boxes3-section .boxdiv .links"]?.["font-weight"],
    },
    ".left-boxes3-section .boxdiv:hover .links": {
      color: payload.style?.[".left-boxes3-section .boxdiv:hover .links"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".left-boxes3-section .boxdiv:hover .links"]?.[
                "color"
              ],
          })
        : "",
    },
  };
};
export const serviceStyleFourteenSet = (payload, mainClass) => {
  return {
    ".table-list-wrapper .table-list .list-head": {
      "background-color": payload.style?.[
        ".table-list-wrapper .table-list .list-head"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".table-list-wrapper .table-list .list-head"]?.[
                "background-color"
              ],
          })
        : "",
      border:
        payload.style?.[".table-list-wrapper .table-list .list-head"]?.[
          "border"
        ],
      borderPx:
        payload.style?.[".table-list-wrapper .table-list .list-head"]?.[
          "borderPx"
        ],
      borderThik:
        payload.style?.[".table-list-wrapper .table-list .list-head"]?.[
          "borderThik"
        ],
      borderColor: payload.style?.[
        ".table-list-wrapper .table-list .list-head"
      ]?.["borderColor"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".table-list-wrapper .table-list .list-head"]?.[
                "borderColor"
              ],
          })
        : "",
      padding:
        payload.style?.[".table-list-wrapper .table-list .list-head"]?.[
          "padding"
        ],
      "margin-bottom":
        payload.style?.[".table-list-wrapper .table-list .list-head"]?.[
          "margin-bottom"
        ],
    },
    ".table-list-wrapper .table-list .list-head h2": {
      color: payload.style?.[".table-list-wrapper .table-list .list-head h2"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".table-list-wrapper .table-list .list-head h2"
              ]?.["color"],
          })
        : "",
      "font-size":
        payload.style?.[".table-list-wrapper .table-list .list-head h2"]?.[
          "font-size"
        ],
    },
    ".table-list-wrapper .table-list .body-ttl": {
      border:
        payload.style?.[".table-list-wrapper .table-list .body-ttl"]?.[
          "border"
        ],
      borderPx:
        payload.style?.[".table-list-wrapper .table-list .body-ttl"]?.[
          "borderPx"
        ],
      borderThik:
        payload.style?.[".table-list-wrapper .table-list .body-ttl"]?.[
          "borderThik"
        ],
      borderColor: payload.style?.[
        ".table-list-wrapper .table-list .body-ttl"
      ]?.["borderColor"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".table-list-wrapper .table-list .body-ttl"]?.[
                "borderColor"
              ],
          })
        : "",
      padding:
        payload.style?.[".table-list-wrapper .table-list .body-ttl"]?.[
          "padding"
        ],
      "margin-bottom":
        payload.style?.[".table-list-wrapper .table-list .body-ttl"]?.[
          "margin-bottom"
        ],
    },
    ".table-list-wrapper .table-list .list-body": {
      border:
        payload.style?.[".table-list-wrapper .table-list .list-body"]?.[
          "border"
        ],
      borderPx:
        payload.style?.[".table-list-wrapper .table-list .list-body"]?.[
          "borderPx"
        ],
      borderThik:
        payload.style?.[".table-list-wrapper .table-list .list-body"]?.[
          "borderThik"
        ],
      borderColor: payload.style?.[
        ".table-list-wrapper .table-list .list-body"
      ]?.["borderColor"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".table-list-wrapper .table-list .list-body"]?.[
                "borderColor"
              ],
          })
        : "",
      padding:
        payload.style?.[".table-list-wrapper .table-list .list-body"]?.[
          "padding"
        ],
      p1: payload.style?.[".table-list-wrapper .table-list .list-body"]?.["p1"],
      p2: payload.style?.[".table-list-wrapper .table-list .list-body"]?.["p2"],
      p3: payload.style?.[".table-list-wrapper .table-list .list-body"]?.["p3"],
    },
    ".table-list-wrapper .table-list .list-body ul": {
      "font-size":
        payload.style?.[".table-list-wrapper .table-list .list-body ul"]?.[
          "font-size"
        ],
      "padding-left":
        payload.style?.[".table-list-wrapper .table-list .list-body ul"]?.[
          "padding-left"
        ],
    },
    ".table-list-wrapper .table-list .list-body ul li": {
      padding:
        payload.style?.[".table-list-wrapper .table-list .list-body ul li"]?.[
          "padding"
        ],
      p1: payload.style?.[".table-list-wrapper .table-list .list-body ul li"]?.[
        "p1"
      ],
      p2: payload.style?.[".table-list-wrapper .table-list .list-body ul li"]?.[
        "p2"
      ],
      p3: payload.style?.[".table-list-wrapper .table-list .list-body ul li"]?.[
        "p3"
      ],
      p4: payload.style?.[".table-list-wrapper .table-list .list-body ul li"]?.[
        "p4"
      ],
    },
    ".table-list-wrapper .table-list .list-body ul li a": {
      color: payload.style?.[
        ".table-list-wrapper .table-list .list-body ul li a"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".table-list-wrapper .table-list .list-body ul li a"
              ]?.["color"],
          })
        : "",
    },
  };
};
export const serviceStyleThreeSet = (payload, mainClass) => {
  return {
    ".vision-section .section-title-area .section-title": {
      "font-size":
        payload.style?.[".vision-section .section-title-area .section-title"]?.[
          "font-size"
        ],
      "font-weight":
        payload.style?.[".vision-section .section-title-area .section-title"]?.[
          "font-weight"
        ],
      color: payload.style?.[
        ".vision-section .section-title-area .section-title"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".vision-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".vision-section .visiondiv1": {
      margin: payload.style?.[".vision-section .visiondiv1"]?.["margin"],
      padding: payload.style?.[".vision-section .visiondiv1"]?.["padding"],
      p1: payload.style?.[".vision-section .visiondiv1"]?.["p1"],
      p2: payload.style?.[".vision-section .visiondiv1"]?.["p2"],
      border: payload.style?.[".vision-section .visiondiv1"]?.["border"],
      borderPx: payload.style?.[".vision-section .visiondiv1"]?.["borderPx"],
      borderThik:
        payload.style?.[".vision-section .visiondiv1"]?.["borderThik"],
      borderColor: payload.style?.[".vision-section .visiondiv1"]?.[
        "borderColor"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".vision-section .visiondiv1"]?.["borderColor"],
          })
        : "",
      "background-color": payload.style?.[".vision-section .visiondiv1"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".vision-section .visiondiv1"]?.[
                "background-color"
              ],
          })
        : "",
      "box-shadow":
        payload.style?.[".vision-section .visiondiv1"]?.["box-shadow"],
      bs1: payload.style?.[".vision-section .visiondiv1"]?.["bs1"],
      bs2: payload.style?.[".vision-section .visiondiv1"]?.["bs2"],
      bs3: payload.style?.[".vision-section .visiondiv1"]?.["bs3"],
      shadowColor: payload.style?.[".vision-section .visiondiv1"]?.[
        "shadowColor"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".vision-section .visiondiv1"]?.["shadowColor"],
          })
        : "",
      "border-radius":
        payload.style?.[".vision-section .visiondiv1"]?.["border-radius"],
      "text-align":
        payload.style?.[".vision-section .visiondiv1"]?.["text-align"],
    },
    ".vision-section .visiondiv1:hover": {
      "box-shadow":
        payload.style?.[".vision-section .visiondiv1:hover"]?.["box-shadow"],
      bs1: payload.style?.[".vision-section .visiondiv1:hover"]?.["bs1"],
      bs2: payload.style?.[".vision-section .visiondiv1:hover"]?.["bs2"],
      bs3: payload.style?.[".vision-section .visiondiv1:hover"]?.["bs3"],
      bs4: payload.style?.[".vision-section .visiondiv1:hover"]?.["bs4"],
      shadowColor: payload.style?.[".vision-section .visiondiv1:hover"]?.[
        "shadowColor"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".vision-section .visiondiv1:hover"]?.[
                "shadowColor"
              ],
          })
        : "",
    },
    ".vision-section .visiondiv1 h4": {
      color: payload.style?.[".vision-section .visiondiv1 h4"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".vision-section .visiondiv1 h4"]?.["color"],
          })
        : "",
      "font-weight":
        payload.style?.[".vision-section .visiondiv1 h4"]?.["font-weight"],
      "font-size":
        payload.style?.[".vision-section .visiondiv1 h4"]?.["font-size"],
      "margin-bottom":
        payload.style?.[".vision-section .visiondiv1 h4"]?.["margin-bottom"],
    },
    ".vision-section .visiondiv1 p": {
      color: payload.style?.[".vision-section .visiondiv1 p"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".vision-section .visiondiv1 p"]?.["color"],
          })
        : "",
      "font-weight":
        payload.style?.[".vision-section .visiondiv1 p"]?.["font-weight"],
      "font-size":
        payload.style?.[".vision-section .visiondiv1 p"]?.["font-size"],
      "line-height":
        payload.style?.[".vision-section .visiondiv1 p"]?.["line-height"],
    },
  };
};
export const serviceStyleSixSet = (payload, mainClass) => {
  return {
    ".vision-section6 .section-title-area .section-title": {
      "font-size":
        payload.style?.[
          ".vision-section6 .section-title-area .section-title"
        ]?.["font-size"],
      "font-weight":
        payload.style?.[
          ".vision-section6 .section-title-area .section-title"
        ]?.["font-weight"],
      color: payload.style?.[
        ".vision-section6 .section-title-area .section-title"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".vision-section6 .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".vision-section6 .visiondiv1": {
      margin: payload.style?.[".vision-section6 .visiondiv1"]?.["margin"],
      border: payload.style?.[".vision-section6 .visiondiv1"]?.["border"],
      borderPx: payload.style?.[".vision-section6 .visiondiv1"]?.["borderPx"],
      borderThik:
        payload.style?.[".vision-section6 .visiondiv1"]?.["borderThik"],
      borderColor: payload.style?.[".vision-section6 .visiondiv1"]?.[
        "borderColor"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".vision-section6 .visiondiv1"]?.["borderColor"],
          })
        : "",
      "background-color": payload.style?.[".vision-section6 .visiondiv1"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".vision-section6 .visiondiv1"]?.[
                "background-color"
              ],
          })
        : "",
      "box-shadow":
        payload.style?.[".vision-section6 .visiondiv1"]?.["box-shadow"],
      bs1: payload.style?.[".vision-section6 .visiondiv1"]?.["bs1"],
      bs2: payload.style?.[".vision-section6 .visiondiv1"]?.["bs2"],
      bs3: payload.style?.[".vision-section6 .visiondiv1"]?.["bs3"],
      shadowColor: payload.style?.[".vision-section6 .visiondiv1"]?.[
        "shadowColor"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".vision-section6 .visiondiv1"]?.["shadowColor"],
          })
        : "",
      "border-radius":
        payload.style?.[".vision-section6 .visiondiv1"]?.["border-radius"],
      "text-align":
        payload.style?.[".vision-section6 .visiondiv1"]?.["text-align"],
    },
    ".vision-section6 .visiondiv1:hover": {
      "box-shadow":
        payload.style?.[".vision-section6 .visiondiv1:hover"]?.["box-shadow"],
      bs1: payload.style?.[".vision-section6 .visiondiv1:hover"]?.["bs1"],
      bs2: payload.style?.[".vision-section6 .visiondiv1:hover"]?.["bs2"],
      bs3: payload.style?.[".vision-section6 .visiondiv1:hover"]?.["bs3"],
      bs4: payload.style?.[".vision-section6 .visiondiv1:hover"]?.["bs4"],
      shadowColor: payload.style?.[".vision-section6 .visiondiv1:hover"]?.[
        "shadowColor"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".vision-section6 .visiondiv1:hover"]?.[
                "shadowColor"
              ],
          })
        : "",
    },
    ".vision-section6 .visiondiv1 h4": {
      color: payload.style?.[".vision-section6 .visiondiv1 h4"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".vision-section6 .visiondiv1 h4"]?.["color"],
          })
        : "",
      "font-weight":
        payload.style?.[".vision-section6 .visiondiv1 h4"]?.["font-weight"],
      "font-size":
        payload.style?.[".vision-section6 .visiondiv1 h4"]?.["font-size"],
      "margin-bottom":
        payload.style?.[".vision-section6 .visiondiv1 h4"]?.["margin-bottom"],
    },
    ".vision-section6 .visiondiv1 p": {
      color: payload.style?.[".vision-section6 .visiondiv1 p"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".vision-section6 .visiondiv1 p"]?.["color"],
          })
        : "",
      "font-weight":
        payload.style?.[".vision-section6 .visiondiv1 p"]?.["font-weight"],
      "font-size":
        payload.style?.[".vision-section6 .visiondiv1 p"]?.["font-size"],
      "line-height":
        payload.style?.[".vision-section6 .visiondiv1 p"]?.["line-height"],
    },
    ".vision-section6 .visiondiv_sub .btn": {
      color: payload.style?.[".vision-section6 .visiondiv_sub .btn"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".vision-section6 .visiondiv_sub .btn"]?.[
                "color"
              ],
          })
        : "",
      "font-weight":
        payload.style?.[".vision-section6 .visiondiv_sub .btn"]?.[
          "font-weight"
        ],
      "font-size":
        payload.style?.[".vision-section6 .visiondiv_sub .btn"]?.["font-size"],
    },
  };
};
export const serviceStyleFourSet = (payload, mainClass) => {
  return {
    ".vision2-section .section-title-area .section-title": {
      "font-size":
        payload.style?.[
          ".vision2-section .section-title-area .section-title"
        ]?.["font-size"],
      "font-weight":
        payload.style?.[
          ".vision2-section .section-title-area .section-title"
        ]?.["font-weight"],
      color: payload.style?.[
        ".vision2-section .section-title-area .section-title"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".vision2-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".vision2-section .visiondiv2": {
      padding: payload.style?.[".vision2-section .visiondiv2"]?.["padding"],
      "border-radius":
        payload.style?.[".vision2-section .visiondiv2"]?.["border-radius"],
      "text-align":
        payload.style?.[".vision2-section .visiondiv2"]?.["text-align"],
    },
    ".vision2-section .visiondiv2:hover": {
      "box-shadow":
        payload.style?.[".vision2-section .visiondiv2:hover"]?.["box-shadow"],
      bs1: payload.style?.[".vision2-section .visiondiv2:hover"]?.["bs1"],
      bs2: payload.style?.[".vision2-section .visiondiv2:hover"]?.["bs2"],
      bs3: payload.style?.[".vision2-section .visiondiv2:hover"]?.["bs3"],
      bs4: payload.style?.[".vision2-section .visiondiv2:hover"]?.["bs4"],
      shadowColor: payload.style?.[".vision2-section .visiondiv2:hover"]?.[
        "shadowColor"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".vision2-section .visiondiv2:hover"]?.[
                "shadowColor"
              ],
          })
        : "",
    },
    ".vision2-section .visiondiv2 h4": {
      color: payload.style?.[".vision2-section .visiondiv2 h4"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".vision2-section .visiondiv2 h4"]?.["color"],
          })
        : "",
      "font-weight":
        payload.style?.[".vision2-section .visiondiv2 h4"]?.["font-weight"],
      "font-size":
        payload.style?.[".vision2-section .visiondiv2 h4"]?.["font-size"],
      "margin-bottom":
        payload.style?.[".vision2-section .visiondiv2 h4"]?.["margin-bottom"],
    },
    ".vision2-section .visiondiv2 p": {
      color: payload.style?.[".vision2-section .visiondiv2 p"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".vision2-section .visiondiv2 p"]?.["color"],
          })
        : "",
      "font-weight":
        payload.style?.[".vision2-section .visiondiv2 p"]?.["font-weight"],
      "font-size":
        payload.style?.[".vision2-section .visiondiv2 p"]?.["font-size"],
      "line-height":
        payload.style?.[".vision2-section .visiondiv2 p"]?.["line-height"],
    },
  };
};
export const serviceStyleSevenSet = (payload, mainClass) => {
  return {
    ".boxes1-section .section-title-area .section-title": {
      "font-size":
        payload.style?.[".boxes1-section .section-title-area .section-title"]?.[
          "font-size"
        ],
      "font-weight":
        payload.style?.[".boxes1-section .section-title-area .section-title"]?.[
          "font-weight"
        ],
      color: payload.style?.[
        ".boxes1-section .section-title-area .section-title"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".boxes1-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".boxes1-section .cus-boxes": {
      "border-radius":
        payload.style?.[".boxes1-section .cus-boxes"]?.["border-radius"],
      padding: payload.style?.[".boxes1-section .cus-boxes"]?.["padding"],
      p1: payload.style?.[".boxes1-section .cus-boxes"]?.["p1"],
      p2: payload.style?.[".boxes1-section .cus-boxes"]?.["p2"],
      p3: payload.style?.[".boxes1-section .cus-boxes"]?.["p3"],
      margin: payload.style?.[".boxes1-section .cus-boxes"]?.["margin"],
      m1: payload.style?.[".boxes1-section .cus-boxes"]?.["m1"],
      m2: payload.style?.[".boxes1-section .cus-boxes"]?.["m2"],
      background: payload.style?.[".boxes1-section .cus-boxes"]?.["background"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".boxes1-section .cus-boxes"]?.["background"],
          })
        : "",
    },
    ".boxes1-section .cus-boxes:before": {
      height: payload.style?.[".boxes1-section .cus-boxes:before"]?.["height"],
      "background-color": payload.style?.[
        ".boxes1-section .cus-boxes:before"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".boxes1-section .cus-boxes:before"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".boxes1-section .flaticon": {
      width: payload.style?.[".boxes1-section .flaticon"]?.["height"],
      height: payload.style?.[".boxes1-section .flaticon"]?.["height"],
      "border-radius":
        payload.style?.[".boxes1-section .flaticon"]?.["border-radius"],
      margin: payload.style?.[".boxes1-section .flaticon"]?.["margin"],
      background: payload.style?.[".boxes1-section .flaticon"]?.["background"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".boxes1-section .flaticon"]?.["background"],
          })
        : "",
    },
    ".boxes1-section .cus-boxes:hover .flaticon": {
      background: payload.style?.[
        ".boxes1-section .cus-boxes:hover .flaticon"
      ]?.["background"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".boxes1-section .cus-boxes:hover .flaticon"]?.[
                "background"
              ],
          })
        : "",
    },
    ".boxes1-section .subtitle": {
      color: payload.style?.[".boxes1-section .subtitle"]?.["color"]
        ? convertRGBA({
            rgbString: payload.style?.[".boxes1-section .subtitle"]?.["color"],
          })
        : "",
      "font-size": payload.style?.[".boxes1-section .subtitle"]?.["font-size"],
      "line-height":
        payload.style?.[".boxes1-section .subtitle"]?.["line-height"],
      "font-weight":
        payload.style?.[".boxes1-section .subtitle"]?.["font-weight"],
      margin: payload.style?.[".boxes1-section .subtitle"]?.["margin"],
      m1: payload.style?.[".boxes1-section .subtitle"]?.["m1"],
      m2: payload.style?.[".boxes1-section .subtitle"]?.["m2"],
      m3: payload.style?.[".boxes1-section .subtitle"]?.["font-size"],
      // "-webkit-transition": "all 0.4s ease",
      // transition: "all 0.4s ease",
    },
    ".boxes1-section .cus-boxes:hover .subtitle": {
      color: payload.style?.[".boxes1-section .cus-boxes:hover .subtitle"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".boxes1-section .cus-boxes:hover .subtitle"]?.[
                "color"
              ],
          })
        : "",
    },
  };
};

export const serviceDetailsExtraStyle = (payload) => {
  return {
    ".service-detail-section": {
      padding: "60px 0",
      "background-color": payload.style?.[".service-detail-section"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".service-detail-section"]?.["background-color"],
          })
        : "",
    },
    ".detail-sidebar .other-link li a": {
      "background-color": payload.style?.[".detail-sidebar .other-link li a"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".detail-sidebar .other-link li a"]?.[
                "background-color"
              ],
          })
        : "",
      color: payload.style?.[".detail-sidebar .other-link li a"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".detail-sidebar .other-link li a"]?.["color"],
          })
        : "",
      padding: payload.style?.[".detail-sidebar .other-link li a"]?.["padding"],
      p1: payload.style?.[".detail-sidebar .other-link li a"]?.["p1"],
      p2: payload.style?.[".detail-sidebar .other-link li a"]?.["p2"],
      "font-size":
        payload.style?.[".detail-sidebar .other-link li a"]?.["font-size"],
      "font-weight":
        payload.style?.[".detail-sidebar .other-link li a"]?.["font-weight"],
      border: payload.style?.[".detail-sidebar .other-link li a"]?.["border"],
      borderPx:
        payload.style?.[".detail-sidebar .other-link li a"]?.["borderPx"],
      borderThik:
        payload.style?.[".detail-sidebar .other-link li a"]?.["borderThik"],
      borderColor: payload.style?.[".detail-sidebar .other-link li a"]?.[
        "borderColor"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".detail-sidebar .other-link li a"]?.[
                "borderColor"
              ],
          })
        : "",
    },
    ".detail-sidebar .other-link li .fa": {
      "margin-right":
        payload.style?.[".detail-sidebar .other-link li .fa"]?.["margin-right"],
    },
    ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a":
      {
        color: payload.style?.[
          ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a"
        ]?.["color"]
          ? convertRGBA({
              rgbString:
                payload.style?.[
                  ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a"
                ]?.["color"],
            })
          : "",
        "border-left":
          payload.style?.[
            ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a"
          ]?.["border-left"],
        borderLfPx:
          payload.style?.[
            ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a"
          ]?.["borderLfPx"],
        borderLfThik:
          payload.style?.[
            ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a"
          ]?.["borderLfThik"],
        borderLfColor: payload.style?.[
          ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a"
        ]?.["borderLfColor"]
          ? convertRGBA({
              rgbString:
                payload.style?.[
                  ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a"
                ]?.["borderLfColor"],
            })
          : "",
      },
    ".detail-sidebar .widget-form": {
      display: payload.style?.[".detail-sidebar .widget-form"]?.["display"],
      width: payload.style?.[".detail-sidebar .widget-form"]?.["width"],
      padding: payload.style?.[".detail-sidebar .widget-form"]?.["padding"],
      p1: payload.style?.[".detail-sidebar .widget-form"]?.["p1"],
      p2: payload.style?.[".detail-sidebar .widget-form"]?.["p2"],
      "background-color": payload.style?.[".detail-sidebar .widget-form"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".detail-sidebar .widget-form"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".detail-sidebar .widget-form .form-control": {
      padding:
        payload.style?.[".detail-sidebar .widget-form .form-control"]?.[
          "padding"
        ],
    },
    ".detail-sidebar .widget-form .btn-submit": {
      "background-color": payload.style?.[
        ".detail-sidebar .widget-form .btn-submit"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".detail-sidebar .widget-form .btn-submit"]?.[
                "background-color"
              ],
          })
        : "",
      border:
        payload.style?.[".detail-sidebar .widget-form .btn-submit"]?.["border"],
      borderPx:
        payload.style?.[".detail-sidebar .widget-form .btn-submit"]?.[
          "borderPx"
        ],
      borderThik:
        payload.style?.[".detail-sidebar .widget-form .btn-submit"]?.[
          "borderThik"
        ],
      borderColor: payload.style?.[
        ".detail-sidebar .widget-form .btn-submit"
      ]?.["borderColor"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".detail-sidebar .widget-form .btn-submit"]?.[
                "borderColor"
              ],
          })
        : "",
      color: payload.style?.[".detail-sidebar .widget-form .btn-submit"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".detail-sidebar .widget-form .btn-submit"]?.[
                "color"
              ],
          })
        : "",
      "font-size":
        payload.style?.[".detail-sidebar .widget-form .btn-submit"]?.[
          "font-size"
        ],
      "font-weight":
        payload.style?.[".detail-sidebar .widget-form .btn-submit"]?.[
          "font-weight"
        ],
      padding:
        payload.style?.[".detail-sidebar .widget-form .btn-submit"]?.[
          "padding"
        ],
      p1: payload.style?.[".detail-sidebar .widget-form .btn-submit"]?.["p1"],
      p2: payload.style?.[".detail-sidebar .widget-form .btn-submit"]?.["p2"],
      width:
        payload.style?.[".detail-sidebar .widget-form .btn-submit"]?.["width"],
    },
  };
};

export const serviceCategoryStyleOne = {
  ".service-section": {
    padding: "100px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".service-section.bg-image": {},
  ".service-section.bg-image::before": {
    // "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
  },
  ".service-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".service-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: { r: 255, g: 73, b: 124, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 0.2 },
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".service-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: { r: 0, g: 0, b: 0, a: 100 },
  },
  ".service-section .servicediv": {
    margin: "15px",
    padding: "20px",
    outline: "none",
    outPx: "1px",
    outThik: "solid",
    outColor: { r: 255, g: 255, b: 255, a: 100 },
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
    "box-shadow": "0 5px 10px 0 { r: 7, g: 28, b: 26, a: 0.1 }",
    bs1: "0px",
    bs2: "5px",
    bs3: "10px",
    bs4: "0px",
    shadowColor: { r: 7, g: 28, b: 26, a: 0.1 },
    "border-radius": "0px",
  },
  ".service-section .servicediv .service-icon": {
    "margin-bottom": "20px",
    "max-width": "70px",
  },
  ".service-section .servicediv h4": {
    color: { r: 0, g: 0, b: 0, a: 100 },
    "font-weight": "600",
    "font-size": "20px",
    "margin-bottom": "15px",
    "text-align": "justify",
  },
  ".service-section .servicediv p": {
    color: { r: 0, g: 0, b: 0, a: 100 },
    "font-weight": "400",
    "font-size": "14px",
    "line-height": "1.6",
    "margin-bottom": "15px",
    "text-align": "justify",
  },
  ".service-section .servicediv .links": {
    "font-size": "16px",
    "font-weight": "500",
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".service-slider .slick-arrow:before": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".service-slider .service-box": {
    position: "relative",
    "border-radius": "10px",
    margin: "10px",
  },
  ".service-slider .service-box .servicediv": {
    "background-color": { r: 255, g: 255, b: 255, a: 0.9 },
    "border-radius": "10px",
  },
  ".service-slider .service-box .service-img": {
    "border-radius": "10px",
  },
  ".service-detail-section": {
    padding: "60px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".service-detail-section.bg-image": {},
  ".service-detail-section.bg-image::before": {},
  ".detail-sidebar": {
    display: "inline-block",
    width: "100%",
  },
  ".detail-sidebar .other-link li a": {
    "background-color": { r: 244, g: 244, b: 245, a: 100 },
    color: { r: 31, g: 39, b: 50, a: 100 },
    padding: "15px 20px",
    p1: "15px",
    p2: "20px",
    "font-size": "16px",
    "font-weight": "normal",
    border: "1px solid { r: 215, g: 226, b: 230, a: 100 }",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: { r: 215, g: 226, b: 230, a: 100 },
  },
  ".detail-sidebar .other-link li .fa": {
    "margin-right": "10px",
  },
  ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a":
    {
      color: { r: 255, g: 73, b: 124, a: 100 },
      "border-left": "5px solid { r: 255, g: 73, b: 124, a: 100 }",
      borderLfPx: "5px",
      borderLfThik: "solid",
      borderLfColor: { r: 255, g: 73, b: 124, a: 100 },
    },
  ".detail-sidebar .widget-form": {
    display: "inline-block",
    width: "100%",
    padding: "25px 35px",
    p1: "25px",
    p2: "35px",
    "background-color": { r: 31, g: 39, b: 50, a: 100 },
  },
  ".detail-sidebar .widget-form .form-control": {
    padding: "13px",
  },
  ".detail-sidebar .widget-form .btn-submit": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    border: "2px solid { r: 255, g: 73, b: 124, a: 100 }",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "600",
    padding: "8px 25px",
    p1: "8px",
    p2: "25px",
    width: "100%",
  },
  ".service-section .more-btn": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".service-section .more-btn:hover": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".pagination .page-item.active .page-link": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    "border-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".service-section .slick-dots li button:before": {
    "font-size": "12px",
    color: { r: 255, g: 73, b: 124, a: 100 },
    opacity: "0.25",
  },
  ".service-section .slick-dots li.slick-active button:before": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  serviceId: "",
  custom: "",
};

export const ServiceCategoryStyleTwo = {
  ".service2-section": {
    padding: "100px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".service2-section.bg-image": {},
  ".service2-section.bg-image::before": {
    // "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
  },
  ".service2-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".service2-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: { r: 255, g: 73, b: 124, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 0.2 },
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".service2-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: { r: 0, g: 0, b: 0, a: 100 },
  },
  ".service2-section .servicediv": {
    margin: "15px",
    padding: "20px",
    outline: "none",
    outPx: "1px",
    outThik: "solid",
    outColor: { r: 255, g: 255, b: 255, a: 100 },
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
    "box-shadow": "0 5px 10px 0 { r: 7, g: 28, b: 26, a: 0.1 }",
    bs1: "0px",
    bs2: "5px",
    bs3: "10px",
    bs4: "0px",
    shadowColor: { r: 7, g: 28, b: 26, a: 0.1 },
  },
  ".service2-section .servicediv h4": {
    color: { r: 0, g: 0, b: 0, a: 100 },
    "font-weight": "600",
    "font-size": "20px",
    "margin-bottom": "15px",
    "text-align": "justify",
  },
  ".service2-section .servicediv p": {
    color: { r: 0, g: 0, b: 0, a: 100 },
    "font-weight": "400",
    "font-size": "14px",
    "line-height": "1.6",
    "margin-bottom": "15px",
    "text-align": "justify",
  },
  ".service2-section .servicediv .links": {
    "font-size": "16px",
    "font-weight": "500",
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".service-slider .slick-arrow::before": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".service-slider .service-box": {
    position: "relative",
    "border-radius": "10px",
    margin: "10px",
  },
  ".service-slider .service-box .servicediv": {
    "background-color": { r: 255, g: 255, b: 255, a: 0.9019607843137255 },
    "border-radius": "10px",
  },
  ".service-slider .service-box .service-img": {
    "border-radius": "10px",
  },
  ".service-detail-section": {
    padding: "60px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".service-detail-section.bg-image": {},
  ".service-detail-section.bg-image::before": {},
  ".detail-sidebar": {
    display: "inline-block",
    width: "100%",
  },
  ".detail-sidebar .other-link li a": {
    "background-color": { r: 244, g: 244, b: 245, a: 100 },
    color: { r: 31, g: 39, b: 50, a: 100 },
    padding: "15px 20px",
    p1: "15px",
    p2: "20px",
    "font-size": "16px",
    "font-weight": "normal",
    border: "1px solid { r: 215, g: 226, b: 230, a: 100 }",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: { r: 215, g: 226, b: 230, a: 100 },
  },
  ".detail-sidebar .other-link li .fa": {
    "margin-right": "10px",
  },
  ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a":
    {
      color: { r: 255, g: 73, b: 124, a: 100 },
      "border-left": "5px solid { r: 255, g: 73, b: 124, a: 100 }",
      borderLfPx: "5px",
      borderLfThik: "solid",
      borderLfColor: { r: 255, g: 73, b: 124, a: 100 },
    },
  ".detail-sidebar .widget-form": {
    display: "inline-block",
    width: "100%",
    padding: "25px 35px",
    p1: "25px",
    p2: "35px",
    "background-color": { r: 31, g: 39, b: 50, a: 100 },
  },
  ".detail-sidebar .widget-form .form-control": {
    padding: "13px",
  },
  ".detail-sidebar .widget-form .btn-submit": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    border: "2px solid { r: 255, g: 73, b: 124, a: 100 }",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "600",
    padding: "8px 25px",
    p1: "8px",
    p2: "25px",
    width: "100%",
  },
  ".service2-section .more-btn": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".service2-section .more-btn:hover": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".pagination .page-item.active .page-link": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    "border-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".service2-section .slick-dots li button:before": {
    "font-size": "12px",
    color: { r: 255, g: 73, b: 124, a: 100 },
    opacity: "0.25",
  },
  ".service2-section .slick-dots li.slick-active button:before": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  serviceId: "",
  custom: "",
};

export const serviceCategoryStyleThree = {
  ".vision-section": {
    padding: "100px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".vision-section.bg-image": {},
  ".vision-section.bg-image::before": {
    // "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
  },
  ".vision-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".vision-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: { r: 255, g: 73, b: 124, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 0.2 },
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".vision-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: "#000000",
  },
  ".vision-section .visiondiv1": {
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
    padding: "60px 40px",
    p1: "60px",
    p2: "40px",
    margin: "15px 0",
    "border-radius": "15px",
    border: "1px solid { r: 255, g: 255, b: 255, a: 100 }",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: { r: 255, g: 255, b: 255, a: 100 },
    "box-shadow": "5px 5px 15px { r: 0, g: 0, b: 0, a: 0.08 }",
    bs1: "5px",
    bs2: "5px",
    bs3: "15px",
    shadowColor: { r: 0, g: 0, b: 0, a: 0.08 },
    "text-align": "center",
  },
  ".vision-section .visiondiv1:hover": {
    "box-shadow": "0 10px 20px 0 { r: 19, g: 19, b: 19, a: 0.102 }",
    bs1: "0px",
    bs2: "10px",
    bs3: "20px",
    bs4: "0px",
    shadowColor: "{ r: 19, g: 19, b: 19, a: 0.102 }",
  },
  ".vision-section .visiondiv1 img": {
    "margin-bottom": "30px",
    "max-width": "70px",
  },
  ".vision-section .visiondiv1 h4": {
    color: { r: 0, g: 0, b: 0, a: 100 },
    "font-weight": "600",
    "font-size": "22px",
    "margin-bottom": "15px",
  },
  ".vision-section .visiondiv1 p": {
    color: { r: 0, g: 0, b: 0, a: 100 },
    "font-weight": "400",
    "font-size": "14px",
    "line-height": "1.6",
  },
  ".service-detail-section": {
    padding: "60px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".service-detail-section.bg-image": {},
  ".service-detail-section.bg-image::before": {},
  ".detail-sidebar": {
    display: "inline-block",
    width: "100%",
  },
  ".detail-sidebar .other-link li a": {
    "background-color": { r: 244, g: 244, b: 245, a: 100 },
    color: { r: 31, g: 39, b: 50, a: 100 },
    padding: "15px 20px",
    p1: "15px",
    p2: "20px",
    "font-size": "16px",
    "font-weight": "normal",
    border: "1px solid { r: 215, g: 226, b: 230, a: 100 }",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: { r: 215, g: 226, b: 230, a: 100 },
  },
  ".detail-sidebar .other-link li .fa": {
    "margin-right": "10px",
  },
  ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a":
    {
      color: { r: 255, g: 73, b: 124, a: 100 },
      "border-left": "5px solid { r: 255, g: 73, b: 124, a: 100 }",
      borderLfPx: "5px",
      borderLfThik: "solid",
      borderLfColor: { r: 255, g: 73, b: 124, a: 100 },
    },
  ".detail-sidebar .widget-form": {
    display: "inline-block",
    width: "100%",
    padding: "25px 35px",
    p1: "25px",
    p2: "35px",
    "background-color": { r: 31, g: 39, b: 50, a: 100 },
  },
  ".detail-sidebar .widget-form .form-control": {
    padding: "13px",
  },
  ".detail-sidebar .widget-form .btn-submit": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    border: "2px solid { r: 255, g: 73, b: 124, a: 100 }",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "600",
    padding: "8px 25px",
    p1: "8px",
    p2: "25px",
    width: "100%",
  },
  ".vision-section .more-btn": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".vision-section .more-btn:hover": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".pagination .page-item.active .page-link": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    "border-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  serviceId: "",
  custom: "",
};

export const ServiceCategoryStyleFour = {
  ".vision2-section": {
    padding: "100px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".vision2-section.bg-image": {},
  ".vision2-section.bg-image::before": {
    // "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
  },
  ".vision2-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".vision2-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: { r: 255, g: 73, b: 124, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 0.2 },
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".vision2-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: { r: 0, g: 0, b: 0, a: 100 },
  },
  ".vision2-section .visiondiv2": {
    padding: "25px",
    "border-radius": "15px",
    "text-align": "center",
  },
  ".vision2-section .visiondiv2:hover": {
    transform: "-8px)",
  },
  ".vision2-section .visiondiv2 img": {
    "margin-bottom": "20px",
    "max-width": "70px",
  },
  ".vision2-section .visiondiv2 h4": {
    color: { r: 0, g: 0, b: 0, a: 100 },
    "font-weight": "600",
    "font-size": "24px",
    "margin-bottom": "15px",
  },
  ".vision2-section .visiondiv2 p": {
    color: { r: 0, g: 0, b: 0, a: 100 },
    "font-weight": "300",
    "font-size": "16px",
    "line-height": "1.8",
  },
  ".service-detail-section": {
    padding: "60px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".service-detail-section.bg-image": {},
  ".service-detail-section.bg-image::before": {},
  ".detail-sidebar": {
    display: "inline-block",
    width: "100%",
  },
  ".detail-sidebar .other-link li a": {
    "background-color": { r: 244, g: 244, b: 245, a: 100 },
    color: { r: 31, g: 39, b: 50, a: 100 },
    padding: "15px 20px",
    p1: "15px",
    p2: "20px",
    "font-size": "16px",
    "font-weight": "normal",
    border: "1px solid { r: 215, g: 226, b: 230, a: 100 }",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: { r: 215, g: 226, b: 230, a: 100 },
  },
  ".detail-sidebar .other-link li .fa": {
    "margin-right": "10px",
  },
  ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a":
    {
      color: { r: 255, g: 73, b: 124, a: 100 },
      "border-left": "5px solid { r: 255, g: 73, b: 124, a: 100 }",
      borderLfPx: "5px",
      borderLfThik: "solid",
      borderLfColor: { r: 255, g: 73, b: 124, a: 100 },
    },
  ".detail-sidebar .widget-form": {
    display: "inline-block",
    width: "100%",
    padding: "25px 35px",
    p1: "25px",
    p2: "35px",
    "background-color": { r: 31, g: 39, b: 50, a: 100 },
  },
  ".detail-sidebar .widget-form .form-control": {
    padding: "13px",
  },
  ".detail-sidebar .widget-form .btn-submit": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    border: "2px solid { r: 255, g: 73, b: 124, a: 100 }",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "600",
    padding: "8px 25px",
    p1: "8px",
    p2: "25px",
    width: "100%",
  },
  ".vision2-section .more-btn": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".vision2-section .more-btn:hover": {
    "background-color": "#df2a5d",
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".pagination .page-item.active .page-link": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    "border-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  serviceId: "",
  custom: "",
};

export const serviceCategoryStyleSix = {
  ".vision-section6": {
    padding: "100px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
    width: "",
    margin: "",
    "border-radius": "",
  },
  ".vision-section6.bg-image": {
    "background-image": "",
  },
  ".vision-section6.bg-image::before": {
    "background-color": "",
  },
  ".vision-section6 .section-title-area": {
    "margin-bottom": "50px",
  },
  ".vision-section6 .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: { r: 255, g: 73, b: 124, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 0.2 },
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".vision-section6 .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: { r: 0, g: 0, b: 0, a: 100 },
  },
  ".vision-section6 .visiondiv1": {
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
    margin: "15px 0",
    "border-radius": "15px",
    border: "1px solid { r: 255, g: 255, b: 255, a: 100 }",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: { r: 255, g: 255, b: 255, a: 100 },
    "box-shadow": "5px 5px 15px { r: 0, g: 0, b: 0, a: 0.08 }",
    bs1: "5px",
    bs2: "5px",
    bs3: "15px",
    shadowColor: { r: 0, g: 0, b: 0, a: 0.08 },
    "text-align": "center",
  },
  ".vision-section6 .visiondiv1:hover": {
    "box-shadow": "0 10px 20px 0 { r: 19, g: 19, b: 19, a: 0.102 }",
    bs1: "0px",
    bs2: "10px",
    bs3: "20px",
    bs4: "0px",
    shadowColor: { r: 19, g: 19, b: 19, a: 0.102 },
  },
  ".vision-section6 .visiondiv_imgs": {
    padding: 0,
    overflow: "hidden",
  },
  ".vision-section6 .visiondiv_imgs img": {
    "max-width": "100%",
    "margin-bottom": 0,
  },
  ".vision-section6 .visiondiv_imgs .visiondiv_sub": {
    padding: "20px 25px",
    p1: "20px",
    p2: "25px",
  },
  ".vision-section6 .visiondiv1 h4": {
    color: { r: 0, g: 0, b: 0, a: 100 },
    "font-weight": "600",
    "font-size": "22px",
    "margin-bottom": "15px",
  },
  ".vision-section6 .visiondiv1 p": {
    color: { r: 0, g: 0, b: 0, a: 100 },
    "font-weight": "400",
    "font-size": "15px",
    "line-height": "1.6",
  },
  ".vision-section6 .visiondiv_sub .btn": {
    color: { r: 255, g: 73, b: 124, a: 100 },
    "font-weight": "400",
    "font-size": "16px",
  },
  ".service-detail-section": {
    padding: "60px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".service-detail-section.bg-image": {
    "background-image": "",
  },
  ".service-detail-section.bg-image::before": {
    "background-color": "",
  },
  ".detail-sidebar": {
    display: "inline-block",
    width: "100%",
  },
  ".detail-sidebar .other-link li a": {
    "background-color": { r: 244, g: 244, b: 245, a: 100 },
    color: { r: 31, g: 39, b: 50, a: 100 },
    padding: "15px 20px",
    p1: "15px",
    p2: "20px",
    "font-size": "16px",
    "font-weight": "normal",
    border: "1px solid { r: 215, g: 226, b: 230, a: 100 }",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: { r: 215, g: 226, b: 230, a: 100 },
  },
  ".detail-sidebar .other-link li .fa": {
    "margin-right": "10px",
  },
  ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a":
    {
      color: { r: 255, g: 73, b: 124, a: 100 },
      "border-left": "5px solid { r: 255, g: 73, b: 124, a: 100 }",
      borderLfPx: "5px",
      borderLfThik: "solid",
      borderLfColor: { r: 255, g: 73, b: 124, a: 100 },
    },
  ".detail-sidebar .widget-form": {
    display: "inline-block",
    width: "100%",
    padding: "25px 35px",
    p1: "25px",
    p2: "35px",
    "background-color": { r: 31, g: 39, b: 50, a: 100 },
  },
  ".detail-sidebar .widget-form .form-control": {
    padding: "13px",
  },
  ".detail-sidebar .widget-form .btn-submit": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    border: "2px solid { r: 255, g: 73, b: 124, a: 100 }",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "600",
    padding: "8px 25px",
    p1: "8px",
    p2: "25px",
    width: "100%",
  },
  ".vision-section6 .more-btn": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".vision-section6 .more-btn:hover": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".pagination .page-item.active .page-link": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    "border-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  serviceId: "",
  custom: "",
};

export const serviceCategoryStyleSeven = {
  ".boxes1-section": {
    padding: "60px 0",
    "background-color": { r: 35, g: 50, b: 83, a: 100 },
  },
  ".boxes1-section.bg-image": {
    "background-image": "",
  },
  ".boxes1-section.bg-image::before": {
    "background-color": "",
  },
  ".boxes1-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".boxes1-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: { r: 255, g: 73, b: 124, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 0.2 },
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".boxes1-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: { r: 0, g: 0, b: 0, a: 100 },
  },
  ".boxes1-section .cus-boxes": {
    background: { r: 255, g: 255, b: 255, a: 100 },
    "border-radius": "4px",
    padding: "42px 15px 45px",
    p1: "42px",
    p2: "15px",
    p3: "45px",
    margin: "10px 0",
    m1: "10px",
    m2: "0px",
  },
  ".boxes1-section .cus-boxes:before": {
    height: "4px",
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".boxes1-section .flaticon": {
    width: "90px",
    height: "90px",
    "border-radius": "50%",
    margin: "0 auto",
    background: { r: 83, g: 97, b: 132, a: 100 },
  },
  ".boxes1-section .cus-boxes:hover .flaticon": {
    background: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".boxes1-section .subtitle": {
    color: { r: 35, g: 50, b: 83, a: 100 },
    "font-size": "18px",
    "line-height": "1.5",
    "font-weight": 600,
    margin: "20px 0 0",
    m1: "20px",
    m2: "0px",
    m3: "0px",
  },
  ".boxes1-section .cus-boxes:hover .subtitle": {
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".service-detail-section": {
    padding: "60px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".service-detail-section.bg-image": {
    "background-image": "",
  },
  ".service-detail-section.bg-image::before": {
    "background-color": "",
  },
  ".detail-sidebar": {
    display: "inline-block",
    width: "100%",
  },
  ".detail-sidebar .other-link li a": {
    "background-color": { r: 244, g: 244, b: 245, a: 100 },
    color: { r: 31, g: 39, b: 50, a: 100 },
    padding: "15px 20px",
    p1: "15px",
    p2: "20px",
    "font-size": "16px",
    "font-weight": "normal",
    border: "1px solid { r: 215, g: 226, b: 230, a: 100 }",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: { r: 215, g: 226, b: 230, a: 100 },
  },
  ".detail-sidebar .other-link li .fa": {
    "margin-right": "10px",
  },
  ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a":
    {
      color: { r: 255, g: 73, b: 124, a: 100 },
      "border-left": "5px solid { r: 255, g: 73, b: 124, a: 100 }",
      borderLfPx: "5px",
      borderLfThik: "solid",
      borderLfColor: { r: 255, g: 73, b: 124, a: 100 },
    },
  ".detail-sidebar .widget-form": {
    display: "inline-block",
    width: "100%",
    padding: "25px 35px",
    p1: "25px",
    p2: "35px",
    "background-color": { r: 31, g: 39, b: 50, a: 100 },
  },
  ".detail-sidebar .widget-form .form-control": {
    padding: "13px",
  },
  ".detail-sidebar .widget-form .btn-submit": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    border: "2px solid { r: 255, g: 73, b: 124, a: 100 }",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "600",
    padding: "8px 25px",
    p1: "8px",
    p2: "25px",
    width: "100%",
  },
  ".boxes1-section .more-btn": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".boxes1-section .more-btn:hover": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".pagination .page-item.active .page-link": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    "border-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  serviceId: "",
  custom: "",
};

export const serviceCategoryStyleEight = {
  ".boxes2-section": {
    padding: "60px 0",
    "background-color": { r: 249, g: 249, b: 249, a: 100 },
  },
  ".boxes2-section.bg-image": {
    "background-image": "",
  },
  ".boxes2-section.bg-image::before": {
    "background-color": "",
  },
  ".boxes2-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".boxes2-section .section-title-area .section-subtitle": {
    display: "inline-block",
    "font-size": "16px",
    "font-weight": "600",
    color: { r: 255, g: 73, b: 124, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 0.2 },
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".boxes2-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: { r: 0, g: 0, b: 0, a: 100 },
  },
  ".boxes2-section .cus-boxes": {
    "border-radius": "4px",
    padding: "30px 15px",
    p1: "30px",
    p2: "15px",
    margin: "10px 0",
  },
  ".boxes2-section .cus-boxes:hover": {
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
    "box-shadow": "0 0 15px rgba(121, 115, 115, 0.348)",
    bs1: "0px",
    bs2: "0px",
    bs3: "15px",
    shadowColor: { r: 121, g: 115, b: 115, a: 0.348 },
  },
  ".boxes2-section .flaticon": {
    "max-width": "100%",
  },
  ".boxes2-section .subtitle": {
    color: { r: 35, g: 50, b: 83, a: 100 },
    "font-size": "18px",
    "line-height": "1.5",
    "font-weight": "600",
    margin: "10px 0 0",
    m1: "10px",
    m2: "0px",
    m3: "0px",
  },
  ".service-detail-section": {
    padding: "60px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".service-detail-section.bg-image": {
    "background-image": "",
  },
  ".service-detail-section.bg-image::before": {
    "background-color": "",
  },
  ".detail-sidebar": {
    display: "inline-block",
    width: "100%",
  },
  ".detail-sidebar .other-link li a": {
    "background-color": { r: 244, g: 244, b: 245, a: 100 },
    color: { r: 31, g: 39, b: 50, a: 100 },
    padding: "15px 20px",
    p1: "15px",
    p2: "20px",
    "font-size": "16px",
    "font-weight": "normal",
    border: "1px solid { r: 215, g: 226, b: 230, a: 100 }",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: { r: 215, g: 226, b: 230, a: 100 },
  },
  ".detail-sidebar .other-link li .fa": {
    "margin-right": "10px",
  },
  ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a":
    {
      color: { r: 255, g: 73, b: 124, a: 100 },
      "border-left": "5px solid { r: 255, g: 73, b: 124, a: 100 }",
      borderLfPx: "5px",
      borderLfThik: "solid",
      borderLfColor: { r: 255, g: 73, b: 124, a: 100 },
    },
  ".detail-sidebar .widget-form": {
    display: "inline-block",
    width: "100%",
    padding: "25px 35px",
    p1: "25px",
    p2: "35px",
    "background-color": { r: 31, g: 39, b: 50, a: 100 },
  },
  ".detail-sidebar .widget-form .form-control": {
    padding: "13px",
  },
  ".detail-sidebar .widget-form .btn-submit": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    border: "2px solid { r: 255, g: 73, b: 124, a: 100 }",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "600",
    padding: "8px 25px",
    p1: "8px",
    p2: "25px",
    width: "100%",
  },
  ".boxes2-section .more-btn": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".boxes2-section .more-btn:hover": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".pagination .page-item.active .page-link": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    "border-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  serviceId: "",
  custom: "",
};

export const serviceCategoryStyleNine = {
  ".boxes3-section": {
    padding: "60px 0",
    "background-color": { r: 249, g: 249, b: 249, a: 100 },
  },
  ".boxes3-section.bg-image": {
    "background-image": "",
  },
  ".boxes3-section.bg-image::before": {
    "background-color": "",
  },
  ".boxes3-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".boxes3-section .section-title-area .section-subtitle": {
    display: "inline-block",
    "font-size": "16px",
    "font-weight": "600",
    color: { r: 255, g: 73, b: 124, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 0.2 },
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".boxes3-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: { r: 0, g: 0, b: 0, a: 100 },
  },
  ".boxes3-section .cus-boxes": {
    "border-radius": "4px",
    padding: "40px 15px",
    p1: "40px",
    p2: "15px",
    margin: "10px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
    border: "1px solid { r: 238, g: 238, b: 238, a: 100 }",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: { r: 238, g: 238, b: 238, a: 100 },
  },
  ".boxes3-section .flaticon": {
    "max-width": "100%",
  },
  ".boxes3-section .subtitle": {
    color: { r: 35, g: 50, b: 83, a: 100 },
    "font-size": "18px",
    "line-height": "1.5",
    "font-weight": "600",
    margin: "10px 0 0",
    m1: "10px",
    m2: "0px",
    m3: "0px",
  },
  ".service-detail-section": {
    padding: "60px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".service-detail-section.bg-image": {
    "background-image": "",
  },
  ".service-detail-section.bg-image::before": {
    "background-color": "",
  },
  ".detail-sidebar": {
    display: "inline-block",
    width: "100%",
  },
  ".detail-sidebar .other-link li a": {
    "background-color": { r: 244, g: 244, b: 245, a: 100 },
    color: { r: 31, g: 39, b: 50, a: 100 },
    padding: "15px 20px",
    p1: "15px",
    p2: "20px",
    "font-size": "16px",
    "font-weight": "normal",
    border: "1px solid { r: 215, g: 226, b: 230, a: 100 }",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: { r: 215, g: 226, b: 230, a: 100 },
  },
  ".detail-sidebar .other-link li .fa": {
    "margin-right": "10px",
  },
  ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a":
    {
      color: { r: 255, g: 73, b: 124, a: 100 },
      "border-left": "5px solid { r: 255, g: 73, b: 124, a: 100 }",
      borderLfPx: "5px",
      borderLfThik: "solid",
      borderLfColor: { r: 255, g: 73, b: 124, a: 100 },
    },
  ".detail-sidebar .widget-form": {
    display: "inline-block",
    width: "100%",
    padding: "25px 35px",
    p1: "25px",
    p2: "35px",
    "background-color": { r: 31, g: 39, b: 50, a: 100 },
  },
  ".detail-sidebar .widget-form .form-control": {
    padding: "13px",
  },
  ".detail-sidebar .widget-form .btn-submit": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    border: "2px solid { r: 255, g: 73, b: 124, a: 100 }",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "600",
    padding: "8px 25px",
    p1: "8px",
    p2: "25px",
    width: "100%",
  },
  ".boxes2-section .more-btn": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".boxes2-section .more-btn:hover": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".pagination .page-item.active .page-link": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    "border-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  serviceId: "",
  custom: "",
};

export const serviceCategoryStyleTen = {
  ".boxes4-section": {
    padding: "60px 0",
    "background-color": { r: 249, g: 249, b: 249, a: 100 },
  },
  ".boxes4-section.bg-image": {
    "background-image": "",
  },
  ".boxes4-section.bg-image::before": {
    "background-color": "",
  },
  ".boxes4-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".boxes4-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: { r: 255, g: 73, b: 124, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 0.2 },
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".boxes4-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: { r: 0, g: 0, b: 0, a: 100 },
  },
  ".boxes4-section .cus-boxes": {
    "border-radius": "4px",
    padding: "40px 15px",
    p1: "40px",
    p2: "15px",
    margin: "10px 0",
    background:
      "linear-gradient(145deg, rgb(226 232 236 / 100), rgb(255 255 255 / 100))",
    backColorOne: { r: 226, g: 232, b: 236, a: 100 },
    backColorTwo: { r: 255, g: 255, b: 255, a: 100 },
    "box-shadow": "5px 5px 15px rgb(209 217 230 / 100)",
    bs1: "5px",
    bs2: "5px",
    bs3: "15px",
    shadowColor: { r: 209, g: 217, b: 230, a: 100 },
  },
  ".boxes4-section .flaticon": {
    "max-width": "100%",
  },
  ".boxes4-section .subtitle": {
    color: { r: 35, g: 50, b: 83, a: 100 },
    "font-size": "18px",
    "line-height": "1.5",
    "font-weight": "600",
    margin: "10px 0 0",
    m1: "10px",
    m2: "0px",
    m3: "0px",
  },
  ".service-detail-section": {
    padding: "60px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".service-detail-section.bg-image": {
    "background-image": "",
  },
  ".service-detail-section.bg-image::before": {
    "background-color": "",
  },
  ".detail-sidebar": {
    display: "inline-block",
    width: "100%",
  },
  ".detail-sidebar .other-link li a": {
    "background-color": { r: 244, g: 244, b: 245, a: 100 },
    color: { r: 31, g: 39, b: 50, a: 100 },
    padding: "15px 20px",
    p1: "15px",
    p2: "20px",
    "font-size": "16px",
    "font-weight": "normal",
    border: "1px solid { r: 215, g: 226, b: 230, a: 100 }",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: { r: 215, g: 226, b: 230, a: 100 },
  },
  ".detail-sidebar .other-link li .fa": {
    "margin-right": "10px",
  },
  ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a":
    {
      color: { r: 255, g: 73, b: 124, a: 100 },
      "border-left": "5px solid { r: 255, g: 73, b: 124, a: 100 }",
      borderLfPx: "5px",
      borderLfThik: "solid",
      borderLfColor: { r: 255, g: 73, b: 124, a: 100 },
    },
  ".detail-sidebar .widget-form": {
    display: "inline-block",
    width: "100%",
    padding: "25px 35px",
    p1: "25px",
    p2: "35px",
    "background-color": { r: 31, g: 39, b: 50, a: 100 },
  },
  ".detail-sidebar .widget-form .form-control": {
    padding: "13px",
  },
  ".detail-sidebar .widget-form .btn-submit": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    border: "2px solid { r: 255, g: 73, b: 124, a: 100 }",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "600",
    padding: "8px 25px",
    p1: "8px",
    p2: "25px",
    width: "100%",
  },
  ".boxes4-section .more-btn": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".boxes4-section .more-btn:hover": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".pagination .page-item.active .page-link": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    "border-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  serviceId: "",
  custom: "",
};

export const serviceCategoryStyleEleven = {
  ".left-boxes1-section": {
    padding: "60px 0",
    "background-color": { r: 243, g: 243, b: 243, a: 100 },
  },
  ".left-boxes1-section.bg-image": {
    "background-image": "",
  },
  ".left-boxes1-section.bg-image::before": {
    "background-color": "",
  },
  ".left-boxes1-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".left-boxes1-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: { r: 255, g: 73, b: 124, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 0.2 },
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".left-boxes1-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: { r: 0, g: 0, b: 0, a: 100 },
  },
  ".left-boxes1-section .cus-boxes": {
    margin: "10px 0",
    padding: "25px 15px",
    p1: "25px",
    p2: "15px",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
    "box-shadow": "0px 14px 70px 0px rgb(244 245 253 / 100)",
    bs1: "0px",
    bs2: "14px",
    bs3: "70px",
    bs4: "0px",
    shadowColor: { r: 244, g: 245, b: 253, a: 100 },
    border: "1px solid rgb(238 238 238 / 100)",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: { r: 238, g: 238, b: 238, a: 100 },
    "border-radius": "10px",
  },
  ".left-boxes1-section .flaticon": {
    width: "70px",
    height: "70px",
    "margin-right": "10px",
    "border-radius": "50%",
    "background-color": { r: 255, g: 73, b: 124, a: 0.2 },
    border: "1px solid rgb(255 73 124 / 100)",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".left-boxes1-section .subdiv h4": {
    color: { r: 255, g: 73, b: 124, a: 100 },
    "font-size": "22px",
    "line-height": "1.3",
    "font-weight": "600",
  },
  ".left-boxes1-section .subdiv p": {
    color: { r: 0, g: 0, b: 0, a: 100 },
    "font-size": "16px",
    "line-height": "1.4",
    "font-weight": "normal",
  },
  ".service-detail-section": {
    padding: "60px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".service-detail-section.bg-image": {
    "background-image": "",
  },
  ".service-detail-section.bg-image::before": {
    "background-color": "",
  },
  ".detail-sidebar": {
    display: "inline-block",
    width: "100%",
  },
  ".detail-sidebar .other-link li a": {
    "background-color": { r: 244, g: 244, b: 245, a: 100 },
    color: { r: 31, g: 39, b: 50, a: 100 },
    padding: "15px 20px",
    p1: "15px",
    p2: "20px",
    "font-size": "16px",
    "font-weight": "normal",
    border: "1px solid { r: 215, g: 226, b: 230, a: 100 }",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: { r: 215, g: 226, b: 230, a: 100 },
  },
  ".detail-sidebar .other-link li .fa": {
    "margin-right": "10px",
  },
  ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a":
    {
      color: { r: 255, g: 73, b: 124, a: 100 },
      "border-left": "5px solid { r: 255, g: 73, b: 124, a: 100 }",
      borderLfPx: "5px",
      borderLfThik: "solid",
      borderLfColor: { r: 255, g: 73, b: 124, a: 100 },
    },
  ".detail-sidebar .widget-form": {
    display: "inline-block",
    width: "100%",
    padding: "25px 35px",
    p1: "25px",
    p2: "35px",
    "background-color": { r: 31, g: 39, b: 50, a: 100 },
  },
  ".detail-sidebar .widget-form .form-control": {
    padding: "13px",
  },
  ".detail-sidebar .widget-form .btn-submit": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    border: "2px solid { r: 255, g: 73, b: 124, a: 100 }",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "600",
    padding: "8px 25px",
    p1: "8px",
    p2: "25px",
    width: "100%",
  },
  ".left-boxes1-section .more-btn": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".left-boxes1-section .more-btn:hover": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".pagination .page-item.active .page-link": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    "border-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  serviceId: "",
  custom: "",
};

export const serviceCategoryStyleTwelve = {
  ".left-boxes2-section": {
    padding: "60px 0",
    "background-color": { r: 255, g: 248, b: 239, a: 100 },
  },
  ".left-boxes2-section.bg-image": {
    "background-image": "",
  },
  ".left-boxes2-section.bg-image::before": {
    "background-color": "",
  },
  ".left-boxes2-section .cus-boxes": {
    margin: "10px 0",
    padding: "15px",
    "border-right": "1px solid rgb(238 238 238 / 100)",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: { r: 238, g: 238, b: 238, a: 100 },
  },
  ".left-boxes2-section .flaticon": {
    width: "70px",
    "margin-right": "10px",
  },
  ".left-boxes2-section .subdiv h4": {
    color: { r: 255, g: 73, b: 124, a: 100 },
    "font-size": "22px",
    "line-height": "1.3",
    "font-weight": "600",
    "margin-bottom": "5px",
  },
  ".left-boxes2-section .subdiv p": {
    color: { r: 0, g: 0, b: 0, a: 100 },
    "font-size": "16px",
    "line-height": "1.4",
    "font-weight": "normal",
  },
  ".service-detail-section": {
    padding: "60px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".service-detail-section.bg-image": {
    "background-image": "",
  },
  ".service-detail-section.bg-image::before": {
    "background-color": "",
  },
  ".detail-sidebar": {
    display: "inline-block",
    width: "100%",
  },
  ".detail-sidebar .other-link li a": {
    "background-color": { r: 244, g: 244, b: 245, a: 100 },
    color: { r: 31, g: 39, b: 50, a: 100 },
    padding: "15px 20px",
    p1: "15px",
    p2: "20px",
    "font-size": "16px",
    "font-weight": "normal",
    border: "1px solid { r: 215, g: 226, b: 230, a: 100 }",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: { r: 215, g: 226, b: 230, a: 100 },
  },
  ".detail-sidebar .other-link li .fa": {
    "margin-right": "10px",
  },
  ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a":
    {
      color: { r: 255, g: 73, b: 124, a: 100 },
      "border-left": "5px solid { r: 255, g: 73, b: 124, a: 100 }",
      borderLfPx: "5px",
      borderLfThik: "solid",
      borderLfColor: { r: 255, g: 73, b: 124, a: 100 },
    },
  ".detail-sidebar .widget-form": {
    display: "inline-block",
    width: "100%",
    padding: "25px 35px",
    p1: "25px",
    p2: "35px",
    "background-color": { r: 31, g: 39, b: 50, a: 100 },
  },
  ".detail-sidebar .widget-form .form-control": {
    padding: "13px",
  },
  ".detail-sidebar .widget-form .btn-submit": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    border: "2px solid { r: 255, g: 73, b: 124, a: 100 }",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "600",
    padding: "8px 25px",
    p1: "8px",
    p2: "25px",
    width: "100%",
  },
  ".left-boxes2-section .more-btn": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".left-boxes2-section .more-btn:hover": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".pagination .page-item.active .page-link": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    "border-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  serviceId: "",
  custom: "",
};

export const serviceCategoryStyleThirteen = {
  ".left-boxes3-section": {
    padding: "60px 0",
    "background-color": { r: 249, g: 249, b: 249, a: 100 },
  },
  ".left-boxes3-section.bg-image": {
    "background-image": "",
  },
  ".left-boxes3-section.bg-image::before": {
    "background-color": "",
  },
  ".left-boxes3-section .boxdiv": {
    padding: "35px 30px",
    p1: "35px",
    p2: "30px",
    border: "1px solid rgb(221 221 221 / 100)",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: { r: 221, g: 221, b: 221, a: 100 },
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".left-boxes3-section .boxdiv:hover": {
    "background-color": { r: 44, g: 63, b: 105, a: 100 },
  },
  ".left-boxes3-section .boxdiv h5": {
    color: { r: 0, g: 0, b: 0, a: 100 },
    "font-weight": "600",
    "font-size": "20px",
    "margin-bottom": "15px",
  },
  ".left-boxes3-section .boxdiv:hover h5": {
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".left-boxes3-section .boxdiv p": {
    color: { r: 0, g: 0, b: 0, a: 100 },
    "font-weight": "400",
    "font-size": "14px",
    "line-height": "1.6",
    "margin-bottom": "15px",
  },
  ".left-boxes3-section .boxdiv:hover p": {
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".left-boxes3-section .boxdiv .links": {
    "font-size": "16px",
    "font-weight": "500",
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".left-boxes3-section .boxdiv:hover .links": {
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".service-detail-section": {
    padding: "60px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".service-detail-section.bg-image": {
    "background-image": "",
  },
  ".service-detail-section.bg-image::before": {
    "background-color": "",
  },
  ".detail-sidebar": {
    display: "inline-block",
    width: "100%",
  },
  ".detail-sidebar .other-link li a": {
    "background-color": { r: 244, g: 244, b: 245, a: 100 },
    color: { r: 31, g: 39, b: 50, a: 100 },
    padding: "15px 20px",
    p1: "15px",
    p2: "20px",
    "font-size": "16px",
    "font-weight": "normal",
    border: "1px solid { r: 215, g: 226, b: 230, a: 100 }",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: { r: 215, g: 226, b: 230, a: 100 },
  },
  ".detail-sidebar .other-link li .fa": {
    "margin-right": "10px",
  },
  ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a":
    {
      color: { r: 255, g: 73, b: 124, a: 100 },
      "border-left": "5px solid { r: 255, g: 73, b: 124, a: 100 }",
      borderLfPx: "5px",
      borderLfThik: "solid",
      borderLfColor: { r: 255, g: 73, b: 124, a: 100 },
    },
  ".detail-sidebar .widget-form": {
    display: "inline-block",
    width: "100%",
    padding: "25px 35px",
    p1: "25px",
    p2: "35px",
    "background-color": { r: 31, g: 39, b: 50, a: 100 },
  },
  ".detail-sidebar .widget-form .form-control": {
    padding: "13px",
  },
  ".detail-sidebar .widget-form .btn-submit": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    border: "2px solid { r: 255, g: 73, b: 124, a: 100 }",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "600",
    padding: "8px 25px",
    p1: "8px",
    p2: "25px",
    width: "100%",
  },
  ".left-boxes3-section .more-btn": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".left-boxes3-section .more-btn:hover": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".pagination .page-item.active .page-link": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    "border-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  serviceId: "",
  custom: "",
};

export const serviceCategoryStyleFourteen = {
  ".table-list-wrapper .table-list .list-head": {
    "background-color": { r: 112, g: 48, b: 160, a: 100 },
    border: "6px solid rgb(255 217 102 / 100)",
    borderPx: "6px",
    borderThik: "solid",
    borderColor: { r: 255, g: 217, b: 102, a: 100 },
    padding: "10px",
    "margin-bottom": "20px",
  },
  ".table-list-wrapper .table-list .list-head h2": {
    color: { r: 255, g: 217, b: 102, a: 100 },
    "font-size": "36px",
  },
  ".table-list-wrapper .table-list .body-ttl": {
    border: "4px solid rgb(0 0 0 / 100)",
    borderPx: "4px",
    borderThik: "solid",
    borderColor: { r: 0, g: 0, b: 0, a: 100 },
    padding: "10px",
    "margin-bottom": "16px",
  },
  ".table-list-wrapper .table-list .list-body": {
    border: "4px solid #000000",
    borderPx: "4px",
    borderThik: "solid",
    borderColor: { r: 0, g: 0, b: 0, a: 100 },
    padding: "8px 16px 24px",
    p1: "8px",
    p2: "16px",
    p3: "24px",
  },
  ".table-list-wrapper .table-list .list-body ul": {
    "font-size": "18px",
    "padding-left": "20px",
  },
  ".table-list-wrapper .table-list .list-body ul li": {
    padding: "6px 20px 6px 6px",
    p1: "6px",
    p2: "20px",
    p3: "6px",
    p4: "6px",
  },
  ".table-list-wrapper .table-list .list-body ul li a": {
    color: { r: 0, g: 0, b: 0, a: 100 },
  },
  ".service-detail-section": {
    padding: "60px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".service-detail-section.bg-image": {
    "background-image": "",
  },
  ".service-detail-section.bg-image::before": {
    "background-color": "",
  },
  ".detail-sidebar": {
    display: "inline-block",
    width: "100%",
  },
  ".detail-sidebar .other-link li a": {
    "background-color": { r: 244, g: 244, b: 245, a: 100 },
    color: { r: 31, g: 39, b: 50, a: 100 },
    padding: "15px 20px",
    p1: "15px",
    p2: "20px",
    "font-size": "16px",
    "font-weight": "normal",
    border: "1px solid { r: 215, g: 226, b: 230, a: 100 }",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: { r: 215, g: 226, b: 230, a: 100 },
  },
  ".detail-sidebar .other-link li .fa": {
    "margin-right": "10px",
  },
  ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a":
    {
      color: { r: 255, g: 73, b: 124, a: 100 },
      "border-left": "5px solid { r: 255, g: 73, b: 124, a: 100 }",
      borderLfPx: "5px",
      borderLfThik: "solid",
      borderLfColor: { r: 255, g: 73, b: 124, a: 100 },
    },
  ".detail-sidebar .widget-form": {
    display: "inline-block",
    width: "100%",
    padding: "25px 35px",
    p1: "25px",
    p2: "35px",
    "background-color": { r: 31, g: 39, b: 50, a: 100 },
  },
  ".detail-sidebar .widget-form .form-control": {
    padding: "13px",
  },
  ".detail-sidebar .widget-form .btn-submit": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    border: "2px solid { r: 255, g: 73, b: 124, a: 100 }",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "600",
    padding: "8px 25px",
    p1: "8px",
    p2: "25px",
    width: "100%",
  },
  ".table-list-wrapper .more-btn": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".table-list-wrapper .more-btn:hover": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".pagination .page-item.active .page-link": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    "border-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  serviceId: "",
  custom: "",
};
