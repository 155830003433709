import React, { useContext } from "react";
import { Col, Container, Input, Label, Row } from "reactstrap";
import FaqsContext from "../../../../context/FaqsContext/FaqsContext";

const FooterForm = (props) => {
  const { isEdit } = useContext(FaqsContext);
  const { settingFormData, setSettingFormData, otherSetting } = props;

  /**
   * input value change
   * @param {event} e
   */

  const changeFormValue = (e) => {
    const { name, value } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        Footer: {
          ...prev.Footer,
          [name]: value,
        },
      };
    });
  };
  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="12">
            <div className="mt-4 d-flex flex-column justify-content-center ">
              <div className="d-flex justify-content-between">
                <h6>{isEdit ? "Edit" : "Add New"}</h6>
              </div>
              <Row className="mt-4">
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">Copy Right</Label>
                  <Input
                    className="form-control"
                    name="copyRight"
                    type="text"
                    placeholder="Copy Right"
                    value={settingFormData?.Footer?.copyRight}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    required
                  />
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">Developed By</Label>
                  <Input
                    className="form-control"
                    name="developedBy"
                    type="text"
                    placeholder="Developed By"
                    value={settingFormData?.Footer?.developedBy}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    required
                  />
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">Developed Link</Label>
                  <Input
                    className="form-control"
                    name="developedByLink"
                    type="text"
                    placeholder="Developed By Link"
                    value={settingFormData?.Footer?.developedByLink}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    required
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default FooterForm;
