import React, { useState } from "react";
import AboutUsContext from "./AboutUsContext";

const AboutUsState = (props) => {
  const [apiData, setApiData] = useState({ data: [], isLoading: false });
  const [isBackgroundColor, setIsBackgroundColor] = useState(false);
  const [fromData, setFormData] = useState({
    title: "",
    aboutUs: "",
    order: 1,
    image: "",
    subTitle: "",
    isTitleShow: true,
    theme: 1,
    buttonText: "",
    buttonLink: "",
  });
  const [isParent, setIsParent] = useState(false);
  const [formDataImageParent, setFormDataImageParent] = useState({
    title: "",
    subTitle: "",
    theme: 1,
    navMapId: "",
    isTitleShow: true,
    style: {
      ".about-section": {
        padding: "100px 0",
        "background-color": "rgb(255 255 255 / 100)",
      },
      ".about-section.bg-image": {
        "background-image": "",
      },
      ".about-section.bg-image::before": {
        "background-color": "",
      },
      ".about-section .section-title-area": {
        "margin-bottom": "50px",
      },
      ".about-section .section-title-area .section-subtitle": {
        "font-size": "16px",
        "font-weight": "600",
        color: "#ff497c",
        "background-color": "rgb(255 73 124 / 0.2)",
        padding: "5px 20px",
        p1: "5px",
        p2: "20px",
        "border-radius": "15px",
        "margin-bottom": "15px",
      },
      ".about-section .section-title-area .section-title": {
        "font-size": "44px",
        "font-weight": "600",
        color: "rgb(0 0 0 / 100)",
      },
      ".about-section .about-img": {
        margin: "0 auto",
        display: "block",
      },
      ".about-section .about-details": {
        padding: "20px",
      },
      ".about-section .about-details h5": {
        "font-size": "24px",
        "font-weight": "600",
        color: "rgb(0 0 0 / 100)",
        "line-height": "1.4",
        "margin-bottom": "20px",
        "text-align": "justify",
      },
      ".about-section .about-details h6": {
        "font-size": "20px",
        "font-weight": "600",
        color: "rgb(0 0 0 / 100)",
        "line-height": "1.4",
        "margin-bottom": "20px",
        "text-align": "justify",
      },
      ".about-section .about-details p": {
        "font-size": "16px",
        "font-weight": "500",
        color: "rgb(0 0 0 / 100)",
        "line-height": "1.6",
        "margin-bottom": "20px",
        "text-align": "justify",
      },
      ".about-section .about-details ul": {
        "margin-bottom": "30px",
      },
      ".about-section .about-details ul li": {
        "font-size": "16px",
        "font-weight": "500",
        color: "rgb(0 0 0 / 100)",
        margin: "14px 0",
      },
      ".about-section .about-details ul li:before": {
        color: "rgb(255 73 124 / 100)",
        "margin-right": "10px",
      },
      ".about-section .about-details ul li:first-child": {
        "margin-top": "0",
      },
      ".about-section .about-details .btn": {
        "background-color": "rgb(255 73 124 / 100)",
        border: "2px solid rgb(255 73 124 / 100)",
        borderPx: "2px",
        borderThik: "solid",
        borderColor: "rgb(255 73 124 / 100)",
        color: "rgb(255 255 255 / 100)",
        padding: "8px 25px",
        p1: "8px",
        p2: "25px",
      },
    },
  });
  const [collId, setCollId] = useState();
  const [backImageToggle, setBackImageToggle] = useState(false);
  const [boxSection, setBoxSection] = useState(false);
  const [id, setId] = useState("");
  const [childId, setChildId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [filter, setFilter] = useState("");
  const [navMenuId, setNavMenuId] = useState([]);
  const [theme, setTheme] = useState(false);
  const [settingFormData, setSettingFormData] = useState({});
  const [imgShow, setImgShow] = useState(false);
  const [sectionPageName, setSectionPageName] = useState("");

  return (
    <AboutUsContext.Provider
      value={{
        apiData,
        setApiData,
        fromData,
        setFormData,
        id,
        setId,
        isEdit,
        setIsEdit,
        filter,
        setFilter,
        settingFormData,
        setSettingFormData,
        formDataImageParent,
        setFormDataImageParent,
        navMenuId,
        setNavMenuId,
        fromData,
        setFormData,
        childId,
        setChildId,
        theme,
        setTheme,
        boxSection,
        setBoxSection,
        backImageToggle,
        setBackImageToggle,
        collId,
        setCollId,
        isParent,
        setIsParent,
        isBackgroundColor,
        setIsBackgroundColor,
        imgShow,
        setImgShow,
        sectionPageName,
        setSectionPageName,
      }}
    >
      {props.children}
    </AboutUsContext.Provider>
  );
};

export default AboutUsState;
