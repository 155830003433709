import React, { Fragment, useContext, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row } from "reactstrap";

import SupperAdminDeshboard from "./supperAdminDeshboard/supperAdminDeshboard";
import LoadingBar from "react-top-loading-bar";
import { useNavigate, useParams } from "react-router";
import { GetNfc } from "../../api/Login Api/LoginApi";
import {
  DeshBoard,
  UpdateOrganization,
} from "../../api/Organization Api/OrganizationApi";
import { toast, Zoom } from "react-toastify";
import { LogOut } from "../../utils/Logout";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import { AdminSolutionDetails } from "../../api/Admin Deshboard/AdminDeshboardApi";
import SingleSolution from "./SingleSolution";
import { USER_ROLE } from "../../constant";
import { encryptNumber, setCookie } from "../../utils/helper";

const Ecommerce = () => {
  const [progress, setProgress] = useState(0);
  const [deshData, setDashData] = useState([]);
  const [solutionDetailData, setSolutionDetailData] = useState({});

  const { setDeshboardAnimation, deshboardAnimation } =
    useContext(LoaderContext);

  const history = useNavigate();
  let { hwId } = useParams();

  /**
   * Public Api Call Function
   */

  const nfcGet = async () => {
    let formData = {
      hwId: hwId,
    };
    try {
      if (!hwId) {
        history(`/${hwId}/404`);
      }
      const { data } = await GetNfc(formData);
      if (data.status === 200) {
        localStorage.setItem("pageUuid", data.payload.orgPageDetail.pageUuid);
      } else {
        history(`/${hwId}/404`);
      }
    } catch (error) {}
  };

  useEffect(() => {
    nfcGet();
  }, []);

  /**
   * Deshboard Api Function
   */

  const Deshboard = async () => {
    setDeshboardAnimation(!deshboardAnimation);
    try {
      const { data } = await DeshBoard();

      if (data.status === 200) {
        setDashData(data.payload.data);
        setDeshboardAnimation(false);
      } else {
      }
    } catch (error) {
      setDeshboardAnimation(false);
    }
  };

  /**
   * solution details api call
   */
  const getSolutionDetails = async () => {
    setDeshboardAnimation(!deshboardAnimation);
    try {
      const { data } = await AdminSolutionDetails();

      if (data.status === 200) {
        setSolutionDetailData(data.payload.data);
        setDeshboardAnimation(false);
      } else {
      }
    } catch (error) {
      setDeshboardAnimation(false);
    }
  };

  useEffect(() => {
    Deshboard();
    getSolutionDetails();
  }, []);

  /**
   * Update Org Default Page
   */

  const UpdateOrg = async (pageId, orgUuid) => {
    let formData = {
      pageId: pageId,
    };
    try {
      const { data } = await UpdateOrganization(formData, orgUuid);

      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        Deshboard();
      } else {
        toast.error(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } catch (error) {}
  };

  /**
   * if hwId and localsorage store (hwId) are not sem to logout function call
   */

  if (hwId !== localStorage.getItem("hwId")) {
    LogOut();
  }

  const handleSetCookie = () => {
    setCookie(
      "token",
      localStorage.getItem("token"),
      7,
      +process.env.REACT_APP_IS_LOCAL_URL
        ? "localhost"
        : `.${localStorage.getItem("domain")}`
    );
    setCookie(
      "domain",
      localStorage.getItem("domain"),
      7,
      +process.env.REACT_APP_IS_LOCAL_URL
        ? "localhost"
        : `.${localStorage.getItem("domain")}`
    );
    setCookie(
      "roleId",
      localStorage.getItem("roleId"),
      7,
      +process.env.REACT_APP_IS_LOCAL_URL
        ? "localhost"
        : `.${localStorage.getItem("domain")}`
    );
    setCookie(
      "hwId",
      localStorage.getItem("hwId"),
      7,
      +process.env.REACT_APP_IS_LOCAL_URL
        ? "localhost"
        : `.${localStorage.getItem("domain")}`
    );
  };

  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" />
      <Container fluid={true}>
        <LoadingBar progress={progress} setProgress={setProgress} />
        <Row>
          {solutionDetailData?.solution_access?.isMenu && (
            <>
              <SingleSolution
                name1={"View Restaurent Menu"}
                link1={`${process.env.REACT_APP_RESTAURANT_WEB}${solutionDetailData?.userDetail?.orgDetail?.orgWhiteLabel?.domain}/${solutionDetailData?.userDetail?.orgDetail?.hwId}`}
                target1={{ target: "_blank" }}
                isShow={+localStorage.getItem("roleId") !== USER_ROLE.SUP_ADMIN}
                // cart 2
                name2={"Manage Restaurent Menu"}
                link2={`${process.env.REACT_APP_RESTAURANT_ADMIN}${
                  solutionDetailData?.userDetail?.orgDetail?.orgWhiteLabel
                    ?.domain
                }/${hwId}/${encryptNumber(
                  +localStorage.getItem("orgId"),
                  +process.env.REACT_APP_ENCRYPT_KEY
                )}`}
                target2={{ target: "_blank", onClick: handleSetCookie }}
              />
            </>
          )}
          {+localStorage.getItem("roleId") !== USER_ROLE.SUP_ADMIN &&
            solutionDetailData?.solution_access?.isQr && (
              <>
                <SingleSolution
                  name1={"View Business QR"}
                  link1={`https://qr.${solutionDetailData?.userDetail?.orgDetail?.orgWhiteLabel?.domain}/${solutionDetailData?.userDetail?.orgDetail?.hwId}`}
                  target1={{ target: "_blank" }}
                  // cart 2
                  name2={"Manage Business QR"}
                  link2={`/${solutionDetailData?.userDetail?.orgDetail?.hwId}/PersonalPage/PersonalPage/QR_Web`}
                />
              </>
            )}
          {solutionDetailData?.solution_access?.isMiniWeb &&
            +localStorage.getItem("roleId") !== USER_ROLE.SUP_ADMIN && (
              <>
                <SingleSolution
                  name1={"View Single Page Website"}
                  link1={`https://${solutionDetailData?.webData?.domain}/`}
                  target1={{ target: "_blank" }}
                  isShow={
                    +localStorage.getItem("roleId") !== USER_ROLE.SUP_ADMIN
                  }
                  // cart 2
                  name2={"Manage Single Page Website"}
                  link2={`/${solutionDetailData?.userDetail?.orgDetail?.hwId}/ByteCardBuilder/QR_Web`}
                  isShow1={
                    +localStorage.getItem("roleId") !== USER_ROLE.SUP_ADMIN
                  }
                />
              </>
            )}
          {solutionDetailData?.solution_access?.isMultiWeb &&
            +localStorage.getItem("roleId") !== USER_ROLE.SUP_ADMIN && (
              <>
                <SingleSolution
                  name1={"View Multi Page Website"}
                  link1={`https://${solutionDetailData?.multiWebData?.domain}/`}
                  target1={{ target: "_blank" }}
                  isShow={
                    +localStorage.getItem("roleId") !== USER_ROLE.SUP_ADMIN
                  }
                  // cart 2
                  name2={"Manage Multi Page Website"}
                  link2={`/${solutionDetailData?.userDetail?.orgDetail?.hwId}/multipage-ByteCardBuilder/QR_Web`}
                />
              </>
            )}
        </Row>
        <SupperAdminDeshboard deshData={deshData} UpdateOrg={UpdateOrg} />
      </Container>
    </Fragment>
  );
};

export default Ecommerce;
