import * as React from "react";
import { useState } from "react";
import { PageItemList } from "../../api/Organization Api/OrganizationApi";
import BrokertmContext from "./BrokertmContext";

const BrokerItemState = (props) => {
  const [formvalue, setformValue] = useState([]);
  const [pageUuid, setPageUuid] = useState("");
  const [page, setPage] = useState("");
  const [modal, setModal] = useState(false);
  const [title, setTitle] = useState(false);
  const [pageItemViewPop, setPageItemViewPop] = useState(false);
  const [pageItemData, setPageItemData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [brokerId, setBrokerId] = useState("");
  const [chartTitle, setChartTitle] = useState("");
  const nfcGet = async () => {
    try {
      const { data } = await PageItemList(pageUuid);

      if (data.status === 200) {
        setformValue(data.payload.data);
      } else {
      }
    } catch (error) {}
  };

  return (
    <>
      <BrokertmContext.Provider
        value={{
          formvalue,
          setformValue,
          pageUuid,
          setPageUuid,
          nfcGet,
          modal,
          setModal,
          title,
          setTitle,
          page,
          setPage,
          pageItemViewPop,
          setPageItemViewPop,
          pageItemData,
          setPageItemData,
          brokerId,
          setBrokerId,
          categories,
          setCategories,
          chartData,
          setChartData,
          chartTitle,
          setChartTitle,
        }}
      >
        {props.children}
      </BrokertmContext.Provider>
    </>
  );
};

export default BrokerItemState;
