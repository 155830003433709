import {
  addUrlImage,
  convertRGBA,
  removeUrlImage,
  removeUrlKeyInPath,
  rgbColorCreate,
} from "../../../../utils/helper";

export const StepsStyleOneReset = {
  ".step-by-step-section": {
    padding: "100px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".step-by-step-section.bg-image": {},
  ".step-by-step-section.bg-image::before": {
    // "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
  },
  ".step-by-step-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".step-by-step-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: { r: 255, g: 73, b: 124, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 0.2 },
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".step-by-step-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: { r: 0, g: 0, b: 0, a: 100 },
  },
  ".step-by-step-section .stepdiv": {
    padding: "10px",
    "text-align": "center",
  },
  ".step-by-step-section .stepdiv .step-count": {
    width: "60px",
    height: "60px",
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "30px",
    "font-weight": "600",
    padding: "12px 5px",
    p1: "12px",
    p2: "5px",
    "margin-bottom": "30px",
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    "border-radius": "50%",
  },
  ".step-by-step-section .stepdiv h4": {
    color: { r: 0, g: 0, b: 0, a: 100 },
    "font-weight": "600",
    "font-size": "20px",
    "margin-bottom": "15px",
  },
  ".step-by-step-section .stepdiv p": {
    color: { r: 127, g: 127, b: 127, a: 100 },
    "font-weight": "300",
    "font-size": "14px",
    "line-height": "1.6",
  },
  custom: "",
  stepId: "",
};

export const StepsThemeOneFunction = (payload) => {
  return {
    ".step-by-step-section": {
      padding: payload.style?.[".step-by-step-section"]?.["padding"],
      "background-color": payload.style?.[".step-by-step-section"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".step-by-step-section"]?.["background-color"],
          })
        : "",
      width: payload.style?.[".step-by-step-section"]?.["width"]?.replace(
        "calc(100% - ",
        ""
      ),
      margin: payload.style?.[".step-by-step-section"]?.["margin"],
      "border-radius":
        payload.style?.[".step-by-step-section"]?.["border-radius"],
    },
    ".step-by-step-section.bg-image": {
      "background-image": payload.style?.[".step-by-step-section.bg-image"]?.[
        "background-image"
      ]
        ? removeUrlKeyInPath({
            urlString:
              payload.style?.[".step-by-step-section.bg-image"]?.[
                "background-image"
              ],
          })
        : "",
    },
    ".step-by-step-section.bg-image::before": {
      "background-color": payload.style?.[
        ".step-by-step-section.bg-image::before"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".step-by-step-section.bg-image::before"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".step-by-step-section .section-title-area": {
      "margin-bottom":
        payload.style?.[".step-by-step-section .section-title-area"]?.[
          "margin-bottom"
        ],
    },
    ".step-by-step-section .section-title-area .section-subtitle": {
      "font-size":
        payload.style?.[
          ".step-by-step-section .section-title-area .section-subtitle"
        ]?.["font-size"],
      "font-weight":
        payload.style?.[
          ".step-by-step-section .section-title-area .section-subtitle"
        ]?.["font-weight"],
      color: payload.style?.[
        ".step-by-step-section .section-title-area .section-subtitle"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".step-by-step-section .section-title-area .section-subtitle"
              ]?.["color"],
          })
        : "",
      "background-color": payload.style?.[
        ".step-by-step-section .section-title-area .section-subtitle"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".step-by-step-section .section-title-area .section-subtitle"
              ]?.["background-color"],
          })
        : "",
      padding:
        payload.style?.[
          ".step-by-step-section .section-title-area .section-subtitle"
        ]?.["padding"],
      p1: payload.style?.[
        ".step-by-step-section .section-title-area .section-subtitle"
      ]?.["padding"]?.split(" ")[0],
      p2: payload.style?.[
        ".step-by-step-section .section-title-area .section-subtitle"
      ]?.["padding"]?.split(" ")[1],
      "border-radius":
        payload.style?.[
          ".step-by-step-section .section-title-area .section-subtitle"
        ]?.["border-radius"],
      "margin-bottom":
        payload.style?.[
          ".step-by-step-section .section-title-area .section-subtitle"
        ]?.["margin-bottom"],
    },
    ".step-by-step-section .section-title-area .section-title": {
      "font-size":
        payload.style?.[
          ".step-by-step-section .section-title-area .section-title"
        ]?.["font-size"],
      "font-weight":
        payload.style?.[
          ".step-by-step-section .section-title-area .section-title"
        ]?.["font-weight"],
      color: payload.style?.[
        ".step-by-step-section .section-title-area .section-title"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".step-by-step-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".step-by-step-section .stepdiv": {
      padding: payload.style?.[".step-by-step-section .stepdiv"]?.["padding"],
      "text-align":
        payload.style?.[".step-by-step-section .stepdiv"]?.["text-align"],
    },
    ".step-by-step-section .stepdiv .step-count": {
      width:
        payload.style?.[".step-by-step-section .stepdiv .step-count"]?.[
          "width"
        ],
      height:
        payload.style?.[".step-by-step-section .stepdiv .step-count"]?.[
          "height"
        ],
      color: payload.style?.[".step-by-step-section .stepdiv .step-count"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".step-by-step-section .stepdiv .step-count"]?.[
                "color"
              ],
          })
        : "",
      "font-size":
        payload.style?.[".step-by-step-section .stepdiv .step-count"]?.[
          "font-size"
        ],
      "font-weight":
        payload.style?.[".step-by-step-section .stepdiv .step-count"]?.[
          "font-weight"
        ],
      padding:
        payload.style?.[".step-by-step-section .stepdiv .step-count"]?.[
          "padding"
        ],
      p1: payload.style?.[".step-by-step-section .stepdiv .step-count"]?.[
        "padding"
      ]?.split(" ")[0],
      p2: payload.style?.[".step-by-step-section .stepdiv .step-count"]?.[
        "padding"
      ]?.split(" ")[1],
      "margin-bottom":
        payload.style?.[".step-by-step-section .stepdiv .step-count"]?.[
          "margin-bottom"
        ],
      "background-color": payload.style?.[
        ".step-by-step-section .stepdiv .step-count"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".step-by-step-section .stepdiv .step-count"]?.[
                "background-color"
              ],
          })
        : "",
      "border-radius":
        payload.style?.[".step-by-step-section .stepdiv .step-count"]?.[
          "border-radius"
        ],
    },
    ".step-by-step-section .stepdiv h4": {
      color: payload.style?.[".step-by-step-section .stepdiv h4"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".step-by-step-section .stepdiv h4"]?.["color"],
          })
        : "",
      "font-weight":
        payload.style?.[".step-by-step-section .stepdiv h4"]?.["font-weight"],
      "font-size":
        payload.style?.[".step-by-step-section .stepdiv h4"]?.["font-size"],
      "margin-bottom":
        payload.style?.[".step-by-step-section .stepdiv h4"]?.["margin-bottom"],
    },
    ".step-by-step-section .stepdiv p": {
      color: payload.style?.[".step-by-step-section .stepdiv p"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".step-by-step-section .stepdiv p"]?.["color"],
          })
        : "",
      "font-weight":
        payload.style?.[".step-by-step-section .stepdiv p"]?.["font-weight"],
      "font-size":
        payload.style?.[".step-by-step-section .stepdiv p"]?.["font-size"],
      "line-height":
        payload.style?.[".step-by-step-section .stepdiv p"]?.["line-height"],
    },
    custom: payload.style?.custom,
  };
};
export const StepsStyleOneSubFun = ({ settingFormData, boxSection }) => {
  let newData = {
    ...settingFormData,
    ".step-by-step-section": boxSection
      ? {
          ...settingFormData[".step-by-step-section"],
          padding: `${settingFormData[".step-by-step-section"]["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
          width: `calc(100% - ${settingFormData[".step-by-step-section"][
            "width"
          ]?.replace("rem)", "")}rem)`,
          margin: `${settingFormData[".step-by-step-section"]["margin"]
            ?.split(" ")[0]
            ?.replace("px", "")}px auto`,
          "border-radius": `${settingFormData[".step-by-step-section"][
            "border-radius"
          ]?.replace("rem", "")}rem`,
        }
      : {
          ...settingFormData[".step-by-step-section"],
          padding: `${settingFormData[".step-by-step-section"]["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
        },
    ".step-by-step-section.bg-image": settingFormData[
      ".step-by-step-section.bg-image"
    ]["background-image"]
      ? {
          "background-image": settingFormData[".step-by-step-section.bg-image"][
            "background-image"
          ]
            ? addUrlImage({
                urlString: removeUrlImage({
                  urlString:
                    settingFormData[".step-by-step-section.bg-image"][
                      "background-image"
                    ],
                }),
              })
            : "",
        }
      : {},
    ".step-by-step-section.bg-image::before": settingFormData[
      ".step-by-step-section.bg-image::before"
    ]["background-color"]
      ? {
          "background-color": settingFormData[
            ".step-by-step-section.bg-image::before"
          ]["background-color"]
            ? rgbColorCreate({
                rgbString:
                  settingFormData[".step-by-step-section.bg-image::before"][
                    "background-color"
                  ],
              })
            : "",
        }
      : {},
    ".step-by-step-section .section-title-area": {
      ...settingFormData[".step-by-step-section .section-title-area"],
      "margin-bottom": `${settingFormData[
        ".step-by-step-section .section-title-area"
      ]["margin-bottom"]?.replace("px", "")}px`,
    },
    ".step-by-step-section .section-title-area .section-subtitle": {
      ...settingFormData[
        ".step-by-step-section .section-title-area .section-subtitle"
      ],
      "font-size": `${settingFormData[
        ".step-by-step-section .section-title-area .section-subtitle"
      ]["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".step-by-step-section .section-title-area .section-subtitle"]["font-weight"]}`,

      color: settingFormData[
        ".step-by-step-section .section-title-area .section-subtitle"
      ]["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".step-by-step-section .section-title-area .section-subtitle"
              ]["color"],
          })
        : "",
      "background-color": settingFormData[
        ".step-by-step-section .section-title-area .section-subtitle"
      ]["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".step-by-step-section .section-title-area .section-subtitle"
              ]["background-color"],
          })
        : "",
      padding: `${settingFormData[
        ".step-by-step-section .section-title-area .section-subtitle"
      ]["p1"]?.replace("px", "")}px ${settingFormData[
        ".step-by-step-section .section-title-area .section-subtitle"
      ]["p2"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData[
        ".step-by-step-section .section-title-area .section-subtitle"
      ]["border-radius"]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData[
        ".step-by-step-section .section-title-area .section-subtitle"
      ]["margin-bottom"]?.replace("px", "")}px`,
    },
    ".step-by-step-section .section-title-area .section-title": {
      ...settingFormData[
        ".step-by-step-section .section-title-area .section-title"
      ],
      "font-size": `${settingFormData[
        ".step-by-step-section .section-title-area .section-title"
      ]["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".step-by-step-section .section-title-area .section-title"]["font-weight"]}`,
      color: settingFormData[
        ".step-by-step-section .section-title-area .section-title"
      ]["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".step-by-step-section .section-title-area .section-title"
              ]["color"],
          })
        : "",
    },
    ".step-by-step-section .stepdiv": {
      ...settingFormData[".step-by-step-section .stepdiv"],
      padding: `${settingFormData[".step-by-step-section .stepdiv"][
        "padding"
      ]?.replace("px", "")}px`,
      "text-align": `${settingFormData[".step-by-step-section .stepdiv"]["text-align"]}`,
    },
    ".step-by-step-section .stepdiv .step-count": {
      ...settingFormData[".step-by-step-section .stepdiv .step-count"],
      width: `${settingFormData[".step-by-step-section .stepdiv .step-count"][
        "width"
      ]?.replace("px", "")}px`,
      height: `${settingFormData[".step-by-step-section .stepdiv .step-count"][
        "height"
      ]?.replace("px", "")}px`,
      color: settingFormData[".step-by-step-section .stepdiv .step-count"][
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".step-by-step-section .stepdiv .step-count"][
                "color"
              ],
          })
        : "",
      "font-size": `${settingFormData[
        ".step-by-step-section .stepdiv .step-count"
      ]["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".step-by-step-section .stepdiv .step-count"]["font-weight"]}`,
      padding: `${settingFormData[".step-by-step-section .stepdiv .step-count"][
        "p1"
      ]?.replace("px", "")}px ${settingFormData[
        ".step-by-step-section .stepdiv .step-count"
      ]["p2"]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData[
        ".step-by-step-section .stepdiv .step-count"
      ]["margin-bottom"]?.replace("px", "")}px`,
      "background-color": settingFormData[
        ".step-by-step-section .stepdiv .step-count"
      ]["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".step-by-step-section .stepdiv .step-count"][
                "background-color"
              ],
          })
        : "",
      "border-radius": `${settingFormData[
        ".step-by-step-section .stepdiv .step-count"
      ]["border-radius"]?.replace("%", "")}%`,
    },
    ".step-by-step-section .stepdiv h4": {
      ...settingFormData[".step-by-step-section .stepdiv h4"],
      color: settingFormData[".step-by-step-section .stepdiv h4"]["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".step-by-step-section .stepdiv h4"]["color"],
          })
        : "",
      "font-weight": `${settingFormData[".step-by-step-section .stepdiv h4"]["font-weight"]}`,
      "font-size": `${settingFormData[".step-by-step-section .stepdiv h4"][
        "font-size"
      ]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData[".step-by-step-section .stepdiv h4"][
        "margin-bottom"
      ]?.replace("px", "")}px`,
    },
    ".step-by-step-section .stepdiv p": {
      ...settingFormData[".step-by-step-section .stepdiv p"],
      color: settingFormData[".step-by-step-section .stepdiv p"]["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".step-by-step-section .stepdiv p"]["color"],
          })
        : "",
      "font-weight": `${settingFormData[".step-by-step-section .stepdiv p"]["font-weight"]}`,
      "font-size": `${settingFormData[".step-by-step-section .stepdiv p"][
        "font-size"
      ]?.replace("px", "")}px`,
      "line-height": `${settingFormData[".step-by-step-section .stepdiv p"]["line-height"]}`,
    },
  };
  return newData;
};
