import { convertRGBA, rgbColorCreate } from "../../../../utils/helper";

export const SliderStyleOneReset = {
  ".top-slider": {
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".top-slider .carousel-item:before": {
    // "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
  },
  ".top-slider .slider-content .slide-title": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "40px",
    "font-weight": "600",
    "margin-bottom": "20px",
  },
  ".top-slider .slider-content .slide-desq": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "500",
    "margin-bottom": "20px",
  },
  ".top-slider .slider-content .btn": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    border: "2px solid { r: 255, g: 73, b: 124, a: 100 }",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: { r: 255, g: 73, b: 124, a: 100 },
    "font-size": "16px",
    "font-weight": "600",
    padding: "8px 25px",
    p1: "8px",
    p2: "25px",
    margin: "10px 10px 10px 0",
    m1: "10px",
    m2: "10px",
    m3: "10px",
  },
  ".top-slider .slider-content .slide-btn1": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".top-slider .carousel-control-next-icon, .top-slider .carousel-control-prev-icon":
    {
      "background-color": { r: 0, g: 0, b: 0, a: 0 },
      "border-radius": "0px",
    },
  sliderId: "",
};

export const NavBarThemeOneFunction = (payload) => {
  return {
    ".top-slider": {
      "background-color": payload.style?.[".top-slider"]?.["background-color"]
        ? convertRGBA({
            rgbString: payload.style?.[".top-slider"]?.["background-color"],
          })
        : "",
    },
    ".top-slider .carousel-item:before": {
      "background-color": payload.style?.[
        ".top-slider .carousel-item:before"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".top-slider .carousel-item:before"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".top-slider .slider-content .slide-title": {
      color: payload.style?.[".top-slider .slider-content .slide-title"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".top-slider .slider-content .slide-title"]?.[
                "color"
              ],
          })
        : "",
      "font-size":
        payload.style?.[".top-slider .slider-content .slide-title"]?.[
          "font-size"
        ],
      "font-weight":
        payload.style?.[".top-slider .slider-content .slide-title"]?.[
          "font-weight"
        ],
      "margin-bottom":
        payload.style?.[".top-slider .slider-content .slide-title"]?.[
          "margin-bottom"
        ],
    },
    ".top-slider .slider-content .slide-desq": {
      color: payload.style?.[".top-slider .slider-content .slide-desq"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".top-slider .slider-content .slide-desq"]?.[
                "color"
              ],
          })
        : "",
      "font-size":
        payload.style?.[".top-slider .slider-content .slide-desq"]?.[
          "font-size"
        ],
      "font-weight":
        payload.style?.[".top-slider .slider-content .slide-desq"]?.[
          "font-weight"
        ],
      "margin-bottom":
        payload.style?.[".top-slider .slider-content .slide-desq"]?.[
          "margin-bottom"
        ],
    },
    ".top-slider .slider-content .btn": {
      color: payload.style?.[".top-slider .slider-content .btn"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".top-slider .slider-content .btn"]?.["color"],
          })
        : "",
      border: payload.style?.[".top-slider .slider-content .btn"]?.["border"],
      borderPx:
        payload.style?.[".top-slider .slider-content .btn"]?.["border"]?.split(
          " "
        )[0],
      borderThik:
        payload.style?.[".top-slider .slider-content .btn"]?.["border"]?.split(
          " "
        )[1],
      borderColor: payload.style?.[".top-slider .slider-content .btn"]?.[
        "borderColor"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".top-slider .slider-content .btn"]?.[
                "borderColor"
              ],
          })
        : "",
      "font-size":
        payload.style?.[".top-slider .slider-content .btn"]?.["font-size"],
      "font-weight":
        payload.style?.[".top-slider .slider-content .btn"]?.["font-weight"],
      padding: payload.style?.[".top-slider .slider-content .btn"]?.["padding"],
      p1: payload.style?.[".top-slider .slider-content .btn"]?.[
        "padding"
      ]?.split(" ")[0],
      p2: payload.style?.[".top-slider .slider-content .btn"]?.[
        "padding"
      ]?.split(" ")[0],
      margin: payload.style?.[".top-slider .slider-content .btn"]?.["margin"],
      m1: payload.style?.[".top-slider .slider-content .btn"]?.[
        "margin"
      ]?.split(" ")[0],
      m2: payload.style?.[".top-slider .slider-content .btn"]?.[
        "margin"
      ]?.split(" ")[1],
      m3: payload.style?.[".top-slider .slider-content .btn"]?.[
        "margin"
      ]?.split(" ")[2],
    },
    ".top-slider .slider-content .slide-btn1": {
      "background-color": payload.style?.[
        ".top-slider .slider-content .slide-btn1"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".top-slider .slider-content .slide-btn1"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".top-slider .carousel-control-next-icon, .top-slider .carousel-control-prev-icon":
      {
        "background-color": payload.style?.[
          ".top-slider .carousel-control-next-icon, .top-slider .carousel-control-prev-icon"
        ]?.["background-color"]
          ? convertRGBA({
              rgbString:
                payload.style?.[
                  ".top-slider .carousel-control-next-icon, .top-slider .carousel-control-prev-icon"
                ]?.["background-color"],
            })
          : "",
        "border-radius":
          payload.style?.[
            ".top-slider .carousel-control-next-icon, .top-slider .carousel-control-prev-icon"
          ]?.["border-radius"],
      },
    custom: payload.style.custom,
  };
};

export const NavbarStyleOneSubFun = ({
  settingFormData,
  boxSection,
  isBackgroundColor,
}) => {
  let newData = {
    ...settingFormData,
    ".top-slider": isBackgroundColor
      ? {
          ...settingFormData[".top-slider"],
          "background-color": settingFormData[".top-slider"]?.[
            "background-color"
          ]
            ? rgbColorCreate({
                rgbString: settingFormData[".top-slider"]?.["background-color"],
              })
            : "",
        }
      : {},
    ".top-slider .carousel-item:before": boxSection
      ? {
          ...settingFormData[".top-slider .carousel-item:before"],
          "background-color": settingFormData[
            ".top-slider .carousel-item:before"
          ]["background-color"]
            ? rgbColorCreate({
                rgbString:
                  settingFormData[".top-slider .carousel-item:before"][
                    "background-color"
                  ],
              })
            : "",
        }
      : {},
    ".top-slider .slider-content .slide-title": {
      ...settingFormData[".top-slider .slider-content .slide-title"],
      color: settingFormData[".top-slider .slider-content .slide-title"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".top-slider .slider-content .slide-title"]?.[
                "color"
              ],
          })
        : "",
      "font-size": `${settingFormData[
        ".top-slider .slider-content .slide-title"
      ]["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".top-slider .slider-content .slide-title"]["font-weight"]}`,
      "margin-bottom": `${settingFormData[
        ".top-slider .slider-content .slide-title"
      ]["margin-bottom"]?.replace("px", "")}px`,
    },
    ".top-slider .slider-content .slide-desq": {
      ...settingFormData[".top-slider .slider-content .slide-desq"],
      color: settingFormData[".top-slider .slider-content .slide-desq"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".top-slider .slider-content .slide-desq"]?.[
                "color"
              ],
          })
        : "",
      "font-size": `${settingFormData[
        ".top-slider .slider-content .slide-desq"
      ]["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".top-slider .slider-content .slide-desq"]["font-weight"]}`,
      "margin-bottom": `${settingFormData[
        ".top-slider .slider-content .slide-desq"
      ]["margin-bottom"]?.replace("px", "")}px`,
    },
    ".top-slider .slider-content .btn": {
      ...settingFormData[".top-slider .slider-content .btn"],
      color: settingFormData[".top-slider .slider-content .btn"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".top-slider .slider-content .btn"]?.["color"],
          })
        : "",
      border: `${settingFormData[".top-slider .slider-content .btn"][
        "borderPx"
      ]?.replace("px", "")}px ${
        settingFormData[".top-slider .slider-content .btn"]["borderThik"]
      } ${rgbColorCreate({
        rgbString:
          settingFormData[".top-slider .slider-content .btn"]?.["borderColor"],
      })}`,
      "font-size": `${settingFormData[".top-slider .slider-content .btn"][
        "font-size"
      ]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".top-slider .slider-content .btn"]["font-weight"]}`,
      padding: `${settingFormData[".top-slider .slider-content .btn"][
        "p1"
      ]?.replace("px", "")}px ${settingFormData[
        ".top-slider .slider-content .btn"
      ]["p2"]?.replace("px", "")}px`,
      margin: `${settingFormData[".top-slider .slider-content .btn"][
        "m1"
      ]?.replace("px", "")}px ${settingFormData[
        ".top-slider .slider-content .btn"
      ]["m2"]?.replace("px", "")}px ${settingFormData[
        ".top-slider .slider-content .btn"
      ]["m3"]?.replace("px", "")}px`,
    },
    ".top-slider .slider-content .slide-btn1": {
      ...settingFormData[".top-slider .slider-content .slide-btn1"],
      "background-color": settingFormData[
        ".top-slider .slider-content .slide-btn1"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".top-slider .slider-content .slide-btn1"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".top-slider .carousel-control-next-icon, .top-slider .carousel-control-prev-icon":
      {
        ...settingFormData[
          ".top-slider .carousel-control-next-icon, .top-slider .carousel-control-prev-icon"
        ],
        "background-color": settingFormData[
          ".top-slider .carousel-control-next-icon, .top-slider .carousel-control-prev-icon"
        ]?.["background-color"]
          ? rgbColorCreate({
              rgbString:
                settingFormData[
                  ".top-slider .carousel-control-next-icon, .top-slider .carousel-control-prev-icon"
                ]?.["background-color"],
            })
          : "",
        "border-radius": `${settingFormData[
          ".top-slider .carousel-control-next-icon, .top-slider .carousel-control-prev-icon"
        ]["border-radius"]?.replace("px", "")}px`,
      },
  };
  return newData;
};
