import React, { useState } from "react";
import SeoDetailsContext from "./SeoDetailsContext";

const SeoDetailsState = (props) => {
  const [apiData, setApiData] = useState({ data: [], isLoading: false });

  const [fromData, setFormData] = useState({
    name: "",
    description: "",
    keywords: "",
    canonical: "",
    bing: "",
    googleSiteVerification: "",
    webSiteSchema: "",
    breadCrumbSchema: "",
    facebookPixel: "",
    author: "",
    title: "",
    url: "",
    siteName: "",
    googleTagManage: "",
    webId: localStorage.getItem("webId"),
  });
  const [id, setId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [filter, setFilter] = useState("");
  return (
    <SeoDetailsContext.Provider
      value={{
        apiData,
        setApiData,
        fromData,
        setFormData,
        id,
        setId,
        isEdit,
        setIsEdit,
        filter,
        setFilter,
      }}
    >
      {props.children}
    </SeoDetailsContext.Provider>
  );
};

export default SeoDetailsState;
