import { Add } from "@material-ui/icons";
import { IconButton, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import { useContext } from "react";
import avtar from "../../assets/images1/avtar.jpg";
import call from "../../assets/images1/call.png";
import emailIcon from "../../assets/images1/email.png";
import web from "../../assets/images1/web.png";
import whatsapp from "../../assets/images1/whatsapp.png";
import rupee from "../../assets/images1/rupee.png";
import placeholder from "../../assets/images1/placeholder.png";
import profile from "../../assets/images1/profile-user.png";
import gallery from "../../assets/images1/gallery.png";
import qrcode from "../../assets/images1/qr-code.png";
import adduser from "../../assets/images1/add-user.png";
import review from "../../assets/images1/review.png";
import DigitalCard from "../../assets/images1/credit-card.png";
import { Settings } from "react-feather";
import noPhoto from "../../assets/images/no-photo.webp";
import "../../assets/main.css";
import "../../assets/slick.css";

import { useNavigate, useParams } from "react-router";
import {
  Card,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import PersonalPageContext from "../../context/PersonalPageContext/PersonalPageContaxt";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import OpacityIcon from "@mui/icons-material/Opacity";
import { classes } from "../../data/layouts";
import Switch from "react-js-switch";
import {
  LIMIT,
  MOMENT_SETTING,
  Style,
  THEME_SELECT,
  TITLES,
  TMPLATE,
  TYPES,
  USER_ROLE,
} from "../../constant";
import { Check, X, Droplet, Edit3, Plus } from "react-feather";
import ProfileComponentContext from "../../context/ProfilecomponentContext/ProfilecomponetContext";
import { SketchPicker } from "react-color";
import {
  Edit_OrgPageList,
  Org_PageList_Details,
  PageItemList,
  PagePriviewList,
  getThemeList,
} from "../../api/Organization Api/OrganizationApi";
import { toast, Zoom } from "react-toastify";
import PageItemContext from "../../context/PageItemContext/PageItmContext";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import { TabelAnimation } from "../../Spinner";
import DataNotFound from "../../DataNoteFound/DataNotFound";
import { headerStyle, MuiTabelStyle } from "../../utils/MuiTabelStyle";
import moment from "moment-timezone";
import PreviewPopup from "./preview";
import CallToActionIcon from "@mui/icons-material/CallToAction";
import PersonalPageprevieaw from "./PersonalPageprevieaw";
import { Organization_Detail } from "../../api/SupperAdminOrganizationApi/Organization";
import { TostMessage } from "../../components ByteCard/utils";
import MainOrganizationUpdate from "../../auth/MainOrganizationUpdate";

const PersonalPageItem = (props) => {
  const {
    EditHandle,
    sorting,
    switch_onChange_handle,
    ShowPersonalPage,
    AddPageItem,
  } = props;

  const {
    formvalue,
    setToggle,
    record,
    pageSize,
    page,
    setPageSize,
    setPage,
    setClone,
    pageLimit,
    setPreviewPopup,
    setPreviewArr,
    setCategories,
    setChartData,
    setChartTitle,
    setCalltoActionPopup,
    setLandingName,
    setCalltoActionData,
    personalPageIncentive,
    setPersonalPageIncentive,
    setPersonalPageId,
    setCompanySlugModal,
    companySlugModal,
    setFormDataOrg,
  } = useContext(PersonalPageContext);
  const {
    currentColor,
    setCurrentColor,
    email,
    setEmail,
    fname,
    setFname,
    lname,
    setLname,
    pagetitle,
    setPageTitle,
    orgName,
    setOrgName,
    designation,
    setDesignation,
    address,
    setAddress,
    conuntry,
    setConuntry,
    state,
    setState,
    city,
    setCity,
    logo,
    setLogo,
    userEmail,
    setUserEmail,
    mobile,
    setMobile,
    seoTitle,
    setSeoTitle,
    seoDescription,
    setSeoDescription,
    seoKey,
    setSeoKey,
    setIsAddHandle,
    seteditIndex,
    setPassword,
    setConfirmPassword,
    setData,
    setCheck,
    setType,
    setActionQpiId,
    setIsDialog,
    setIsFource,
    setIsBranchLabel,
    setIsShowBranch,
    setIsLabel,
    isDialog,
    isFource,
    isLabel,
    userPhoto,
    setUserPhoto,
    filter,
    setFilter,
  } = useContext(ProfileComponentContext);
  const { setPageUuid } = useContext(PageItemContext);

  const { loaderShow, setLoaderShow, tabelAnimation } =
    useContext(LoaderContext);

  const [color, setColor] = useState(false);
  const [theme, setTheme] = useState(false);
  const [themeFont, setThemeFont] = useState(false);
  const [themePassUuuid, setThemePassUuid] = useState("");
  const [fontTheme, setFontColor] = useState("#fff");
  const [pageId, setPageId] = useState("");
  const [startDate, setStartData] = useState("");
  const [endDate, setEndData] = useState("");

  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();
  const history = useNavigate();
  let { hwId } = useParams();

  /**
   *  empty feild function
   */

  const emptyFeild = () => {
    setMobile("");
    setUserEmail("");
    setEmail("");
    setFname("");
    setLname("");
    setPageTitle("");
    setOrgName("");
    setDesignation("");
    setAddress("");
    setConuntry("");
    setState("");
    setCity("");
    setLogo("");
    setCurrentColor("");
    setFontColor("");
    setSeoDescription("");
    setSeoKey("");
    setSeoTitle("");
    setPassword("");
    setConfirmPassword("");
    setData([]);
    setIsDialog(false);
    setIsFource(false);
    setIsLabel("");
    setIsBranchLabel("");
    setIsShowBranch("");
    setUserPhoto("");
  };

  /**
   * Add User
   */

  const AddProfile = () => {
    if (pageLimit === record) {
      toast.error("You Have Reached Maximum Limit ", {
        autoClose: 1000,
        transition: Zoom,
      });
    } else {
      setClone(true);
      setToggle(false);
      setIsAddHandle(true);
      setCheck(true);
      seteditIndex(-1);
      emptyFeild();
      history(`/${hwId}/ProfileComponent/${layout}`);
    }
  };

  const getThemeListData = async () => {
    try {
      let paramObj = {
        isThemeSellerAccess: true,
        showAll: true,
        sortBy: "label",
        sortOrder: "ASC",
      };
      const { data } = await getThemeList(paramObj);
      if (data.status === 200) {
        return data.payload.data;
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * Clone User
   */

  const cloneUser = async (pageUuid, orgId) => {
    if (pageLimit === record) {
      toast.error("You Have Reached Maximum Limit ", {
        autoClose: 1000,
        transition: Zoom,
      });
    } else {
      emptyFeild();
      setLoaderShow(!loaderShow);
      try {
        const { data } = await Org_PageList_Details(pageUuid);
        if (data.status === 200) {
          const theme = await getThemeListData();
          setClone(false);
          setToggle(false);
          setIsAddHandle(true);
          setCheck(true);
          setPageUuid(pageUuid);
          const payload = data.payload.data;
          setEmail(payload.email);
          setFname(payload.fname);
          setLname(payload.lname);
          setPageTitle(payload.pageTitle);
          setOrgName(payload.orgDetail.orgName);
          setDesignation(payload.designation);
          setAddress(payload.address);
          setConuntry(payload.country);
          setState(payload.state);
          setCity(payload.city);
          setLogo(payload.orgLogo);
          setUserPhoto(payload.userPhoto);
          setSeoTitle(payload.seoTitle);
          setSeoDescription(payload.scoDesc);
          setSeoKey(payload.scoKeyword);
          setData(
            theme
              .filter((elem) => payload.templateId === elem.code)
              .map((item) => ({
                value: item.code,
                label: item.templateName,
              }))
          );
          setIsDialog(payload.config.isDialog);
          setIsFource(payload.config.isFource);
          setIsLabel(payload.config.isLabel);
          setTheme(payload.pageThemeConfig.themeColor);
          setColor(payload.pageThemeConfig.fontColor);
          setLoaderShow(false);
          history(`/${hwId}/ProfileComponent/${layout}`);
        } else {
        }
      } catch (error) {
        toast.error(error.response, {
          autoClose: 1000,
          transition: Zoom,
        });
        setLoaderShow(false);
      }
    }
  };

  /**
   * Theme color and font color get details
   */

  const OpenThemePopup = async (pageUuid, orgId) => {
    setThemePassUuid(pageUuid);
    emptyFeild();
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Org_PageList_Details(pageUuid);
      if (data.status === 200) {
        const payload = data.payload.data;
        const theme = await getThemeListData();
        setEmail(payload.email);
        setFname(payload.fname);
        setLname(payload.lname);
        setPageTitle(payload.pageTitle);
        setOrgName(payload.orgDetail.orgName);
        setDesignation(payload.designation);
        setAddress(payload.address);
        setConuntry(payload.country);
        setState(payload.state);
        setCity(payload.city);
        setLogo(payload.orgLogo);
        setSeoTitle(payload.seoTitle);
        setSeoDescription(payload.scoDesc);
        setSeoKey(payload.scoKeyword);

        setUserEmail(payload.userEmail);
        setMobile(payload.mobile);
        setUserPhoto(payload.userPhoto);
        setIsDialog(payload.config ? payload.config.isDialog : false);
        setIsFource(payload.config ? payload.config.isFource : false);
        setIsLabel(payload.config ? payload.config.isLabel : "");
        pageThemeConfig(payload.pageThemeConfig);
        setData(
          theme
            .filter((elem) => payload.templateId === elem.code)
            .map((item) => ({
              value: item.code,
              label: item.templateName,
            }))
        );
        setLoaderShow(false);
        setColor(true);
      } else {
      }
    } catch (error) {
      toast.error(error.response, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };

  /**
   * Object return data to set data in state function call
   */

  function pageThemeConfig(data) {
    setCurrentColor(data ? data.themeColor : "");
    setFontColor(data ? data.fontColor : "");
  }

  /**
   * Theme color and font color submit
   */

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoaderShow(!loaderShow);
    let logoUrl = logo
      .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
      .pop();
    let userLogoUrl =
      userPhoto &&
      userPhoto?.split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`).pop();
    let formData = {
      seoTitle: seoTitle,
      seoKeyword: seoKey,
      seoDesc: seoDescription,
      mobile,
      userEmail,
      email,
      fname,
      lname,
      pageTitle: pagetitle,
      orgName,
      designation,
      address,
      country: conuntry,
      state,
      city,
      templateId: 1,
      orgLogo: logoUrl,
      userPhoto: userLogoUrl,
      pageThemeConfig: {
        themeColor: currentColor,
        fontColor: fontTheme,
      },
      config: {
        isDialog,
        isFource,
        isLabel,
      },
      type: TYPES.LANDING_PAGE,
    };
    try {
      const { data } = await Edit_OrgPageList(formData, themePassUuuid);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
        });
        setColor(false);
        setLoaderShow(false);
        emptyFeild();
      } else {
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };

  /**
   * Open Popup to close popup to empty feild
   */

  const close = () => {
    emptyFeild();
    setColor(!color);
  };

  /**
   * Home pagr Theme Color and Font Color Select
   */

  const handleOnChange = (color) => {
    setCurrentColor(color.hex);
  };
  const handleOnChangeFont = (color) => {
    setFontColor(color.hex);
  };

  const SelectTheme = () => {
    if (theme === false) {
      setTheme(true);
      setThemeFont(false);
    } else if (theme === true) {
      setTheme(false);
    }
  };
  const SelectFontTheme = () => {
    if (themeFont === false) {
      setThemeFont(true);
      setTheme(false);
    } else if (themeFont === true) {
      setThemeFont(false);
    }
  };

  const appStyle = {
    background: { backgroundColor: currentColor },
    color: { color: fontTheme },
  };

  const handlePreview = async (pageId, start, end, value, pageTitle) => {
    localStorage.setItem("title", pageTitle);
    setLoaderShow(!loaderShow);
    setChartTitle(pageTitle);
    setPageId(pageId);
    setChartData([]);
    setCategories([]);

    try {
      let paramObj = {
        pageId,
        sortOrder: "ASC",
      };
      if (start !== undefined || end !== undefined) {
        if (start !== undefined) {
          if (start) {
            paramObj["startDate"] = start;
            paramObj["showAll"] = true;
          }
        }
        if (end !== undefined) {
          if (end) {
            paramObj["endDate"] = end;
            paramObj["showAll"] = true;
          }
        }
      }
      if (value === "chart") {
        paramObj["sortOrder"] = "ASC";
        paramObj["startDate"] = startDate;
        paramObj["endDate"] = endDate;
        if (startDate && endDate) {
          paramObj["showAll"] = true;
        }
      } else if (value === "list") {
        paramObj["sortOrder"] = "DESC";
        paramObj["startDate"] = startDate;
        paramObj["endDate"] = endDate;
        if (startDate && endDate) {
          paramObj["showAll"] = true;
        }
      }
      const { data } = await PagePriviewList(paramObj);

      if (data.status === 200) {
        setPreviewPopup(true);
        setPreviewArr(data.payload.data);
        for (let i of data.payload.data) {
          setCategories((oldArray) => [
            ...oldArray,
            moment.utc(i.createdAt).format(MOMENT_SETTING.FORMATE),
          ]);
          setChartData((oldArray) => [...oldArray, i.views]);
        }
        setLoaderShow(false);
        if (!data.payload.data.length) {
          setChartData([]);
          setCategories([]);
        }
      } else {
        setLoaderShow(false);
      }
    } catch (error) {
      setLoaderShow(false);
    }
  };

  const nfcGet = async (pageUuid) => {
    setLoaderShow(true);
    let search = {
      isCallToAction: true,
    };
    try {
      const { data } = await PageItemList(pageUuid, search);
      if (data.status === 200) {
        setCalltoActionData(data.payload.data);
        setLoaderShow(false);
      } else {
        setLoaderShow(false);
      }
    } catch (error) {
      setLoaderShow(false);
    }
  };

  const showCallToAcionPopup = (pageUuid, pageTitle, type, actionQpiId) => {
    setCalltoActionPopup(true);
    setLandingName(pageTitle);
    nfcGet(pageUuid);
    setPageUuid(pageUuid);
    setType(type);
    setActionQpiId(actionQpiId);
  };

  /**
   * Table coloums array
   */
  const defaultColumns =
    +localStorage.getItem("isBrokerAllowed") !== 0
      ? [
          {
            flex: 0.01,
            minWidth: 100,
            field: "sNo",
            headerName: "S.No.",
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
          },
          {
            flex: 0.01,
            minWidth: 130,
            field: "edit",
            headerName: <div>Page (Step-1)</div>,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
              <Tooltip title="Edit">
                <IconButton
                  aria-label="edit"
                  style={{ color: "green" }}
                  onClick={() =>
                    EditHandle(params.row.pageUuid, params.row.orgId)
                  }
                >
                  <ModeEditIcon />
                </IconButton>
              </Tooltip>
            ),
          },
          {
            flex: 0.01,
            minWidth: 130,
            field: "item",
            headerName: <div>Item (Step-2)</div>,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
              <Tooltip title="Item">
                <IconButton
                  aria-label="item"
                  onClick={() =>
                    AddPageItem(
                      params.row.pageUuid,
                      params.row.pageTitle,
                      pageLimit
                    )
                  }
                >
                  <Plus style={{ color: "green" }} />
                </IconButton>
              </Tooltip>
            ),
          },
          {
            flex: 0.01,
            minWidth: 130,
            field: "default",
            headerName: <div>Action (Step-3)</div>,
            renderCell: (params) => {
              return (
                <>
                  <Tooltip title="call to action">
                    <IconButton
                      aria-label="detail"
                      // style={{ color: "green" }}
                      onClick={() =>
                        showCallToAcionPopup(
                          params.row.pageUuid,
                          params.row.pageTitle,
                          params.row.type,
                          params.row.actionQpiId
                        )
                      }
                    >
                      <CallToActionIcon />
                    </IconButton>
                  </Tooltip>
                </>
              );
            },
          },
          {
            field: "home",
            headerName: "View",
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
              <Tooltip title="View">
                <IconButton aria-label="view" style={{ color: "yellowgreen" }}>
                  <a
                    href={`https://${localStorage.getItem(
                      "qrDomain"
                    )}.${localStorage.getItem("domain")}/${hwId}${
                      params.row.roleId !== USER_ROLE.PAGE_ADMIN
                        ? `?p=${params.row.pageId}`
                        : ""
                    }`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ display: "flex" }}
                  >
                    <VisibilityIcon />
                  </a>
                </IconButton>
              </Tooltip>
            ),
          },
          {
            field: "qr",
            headerName: "QR",
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
              <Tooltip title="QR">
                <IconButton aria-label="qr">
                  <a
                    href={params.row.qrCode}
                    target="_blank"
                    rel="noreferrer"
                    style={{ display: "flex" }}
                  >
                    <QrCodeScannerIcon />
                  </a>
                </IconButton>
              </Tooltip>
            ),
          },
          {
            field: "menuQrCode",
            headerName: "Menu QR",
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) =>
              params.row.menuQrCode ? (
                <Tooltip title="QR">
                  <IconButton aria-label="qr">
                    <a
                      href={params.row.menuQrCode}
                      target="_blank"
                      rel="noreferrer"
                      style={{ display: "flex" }}
                    >
                      <QrCodeScannerIcon />
                    </a>
                  </IconButton>
                </Tooltip>
              ) : (
                "--"
              ),
          },
          {
            field: "menuHomeQr",
            headerName: "Home QR",
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) =>
              params.row.menuHomeQr ? (
                <Tooltip title="QR">
                  <IconButton aria-label="qr">
                    <a
                      href={params.row.menuHomeQr}
                      target="_blank"
                      rel="noreferrer"
                      style={{ display: "flex" }}
                    >
                      <QrCodeScannerIcon />
                    </a>
                  </IconButton>
                </Tooltip>
              ) : (
                "--"
              ),
          },
          {
            minWidth: 100,
            flex: 0.1,
            field: "orgLogo",
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            headerName: "Logo",
            renderCell: (params) =>
              params.row.orgLogo ? (
                <img
                  src={params.row.orgLogo}
                  alt=""
                  style={{ width: "70px" }}
                />
              ) : (
                <img src={noPhoto} alt="" style={{ width: "70px" }} />
              ),
          },
          {
            flex: 0.1,
            minWidth: 200,
            field: "pageTitle",
            headerName: "Page Title",
            renderCell: (params) =>
              params.row.pageTitle ? params.row.pageTitle : "-",
          },
          {
            flex: 0.1,
            minWidth: 140,
            field: "mobile",
            headerName: "Mobile",
            renderCell: (params) =>
              params.row.mobile ? params.row.mobile : "-",
          },
          {
            flex: 0.1,
            minWidth: 100,
            field: "views",
            headerName: "View Count",
            renderCell: (params) =>
              params.row.views ? (
                <p
                  style={{ color: "#7366ff", cursor: "pointer" }}
                  onClick={() =>
                    handlePreview(
                      params.row.pageId,
                      null,
                      null,
                      null,
                      params.row.pageTitle
                    )
                  }
                >
                  {params.row.views}
                </p>
              ) : (
                "0"
              ),
          },
          {
            flex: 0.1,
            minWidth: 100,
            field: "totalIncentive",
            headerName: "Incentive",
            renderCell: (params) =>
              params.row.totalProjectIncentive ? (
                <p
                  style={{ color: "#7366ff", cursor: "pointer" }}
                  onClick={() => {
                    setPersonalPageIncentive(true);
                    setPersonalPageId({ pageId: params.row.pageId });
                  }}
                >
                  {params.row.totalProjectIncentive}
                </p>
              ) : (
                "-"
              ),
          },
          {
            field: "isActive",
            headerName: "Status",
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
              <Switch
                value={params.row.isActive ? true : false}
                onChange={() =>
                  switch_onChange_handle(
                    params.row.pageUuid,
                    params.row.isActive
                  )
                }
              />
            ),
          },

          {
            field: "view",
            headerName: "Detail",
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
              <Tooltip title="Detail">
                <IconButton
                  aria-label="detail"
                  // style={{ color: "green" }}
                  onClick={() => ShowPersonalPage(params.row.pageUuid)}
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
            ),
          },

          {
            field: "copy",
            headerName: "Copy",
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) =>
              USER_ROLE.PAGE_USER === +localStorage.getItem("roleId") ? (
                "--"
              ) : (
                <Tooltip title="Copy">
                  <IconButton
                    aria-label="copy"
                    style={{ color: "pink" }}
                    onClick={() =>
                      cloneUser(params.row.pageUuid, params.row.orgId)
                    }
                  >
                    <FileCopyIcon />
                  </IconButton>
                </Tooltip>
              ),
          },

          {
            field: "theme",
            headerName: "Theme",
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) =>
              params.row.templateId === TMPLATE.old ? (
                <Tooltip title="Theme">
                  <IconButton
                    aria-label="theme"
                    onClick={() =>
                      OpenThemePopup(params.row.pageUuid, params.row.orgId)
                    }
                    style={
                      params.row.templateId === TMPLATE.new
                        ? { color: "#c8d3b2" }
                        : { color: "yellowgreen" }
                    }
                    disabled={params.row.templateId === TMPLATE.new}
                  >
                    <OpacityIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                "-"
              ),
          },
          // {
          //   field: "delete",
          //   headerName: "Delete",
          //   sortable: false,
          //   filterable: false,
          //   disableColumnMenu: true,
          //   renderCell: (params) => (
          //     // params.row.roleId === USER_ROLE.PAGE_ADMIN ? (
          //     //   "--"
          //     //   ) :
          //     // USER_ROLE.PAGE_USER === +localStorage.getItem("roleId") ? (
          //     //   "--"
          //     // ) :
          //     <Tooltip title="Delete">
          //       <IconButton
          //         aria-label="delete"
          //         style={
          //           params.row.roleId === USER_ROLE.PAGE_ADMIN ||
          //           USER_ROLE.PAGE_USER === +localStorage.getItem("roleId")
          //             ? { color: DELETE.color_disabled }
          //             : { color: DELETE.color }
          //         }
          //         onClick={() => DeleteHandle(params.row.pageUuid)}
          //         disabled={
          //           params.row.roleId === USER_ROLE.PAGE_ADMIN ||
          //           USER_ROLE.PAGE_USER === +localStorage.getItem("roleId")
          //         }
          //       >
          //         <DeleteIcon />
          //       </IconButton>
          //     </Tooltip>
          //   ),
          // },
          {
            flex: 0.1,
            minWidth: 150,
            field: "createdAt",
            headerName: "Created Date",
            renderCell: (params) =>
              params.row.createdAt
                ? moment
                    .utc(params.row.createdAt)
                    .format(MOMENT_SETTING.FORMATE)
                : "-",
          },
        ]
      : [
          {
            flex: 0.01,
            minWidth: 100,
            field: "sNo",
            headerName: "S.No.",
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
          },
          {
            flex: 0.01,
            minWidth: 130,
            field: "edit",
            headerName: <div>Page (Step-1)</div>,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
              <Tooltip title="Edit">
                <IconButton
                  aria-label="edit"
                  style={{ color: "green" }}
                  onClick={() =>
                    EditHandle(params.row.pageUuid, params.row.orgId)
                  }
                >
                  <ModeEditIcon />
                </IconButton>
              </Tooltip>
            ),
          },
          {
            flex: 0.01,
            minWidth: 130,
            field: "item",
            headerName: <div>Item (Step-2)</div>,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
              <Tooltip title="Item">
                <IconButton
                  aria-label="item"
                  onClick={() =>
                    AddPageItem(
                      params.row.pageUuid,
                      params.row.pageTitle,
                      pageLimit
                    )
                  }
                >
                  <Plus style={{ color: "green" }} />
                </IconButton>
              </Tooltip>
            ),
          },
          {
            flex: 0.01,
            minWidth: 130,
            field: "default",
            headerName: <div>Action (Step-3)</div>,
            renderCell: (params) => {
              return (
                <>
                  <Tooltip title="call to action">
                    <IconButton
                      aria-label="detail"
                      // style={{ color: "green" }}
                      onClick={() =>
                        showCallToAcionPopup(
                          params.row.pageUuid,
                          params.row.pageTitle,
                          params.row.type,
                          params.row.actionQpiId
                        )
                      }
                    >
                      <CallToActionIcon />
                    </IconButton>
                  </Tooltip>
                </>
              );
            },
          },
          {
            field: "home",
            headerName: "View",
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
              <Tooltip title="View">
                <IconButton aria-label="view" style={{ color: "yellowgreen" }}>
                  <a
                    href={`https://${localStorage.getItem(
                      "qrDomain"
                    )}.${localStorage.getItem("domain")}/${hwId}${
                      params.row.roleId !== USER_ROLE.PAGE_ADMIN
                        ? `?p=${params.row.pageId}`
                        : ""
                    }`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ display: "flex" }}
                  >
                    <VisibilityIcon />
                  </a>
                </IconButton>
              </Tooltip>
            ),
          },
          {
            field: "qr",
            headerName: "QR",
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
              <Tooltip title="QR">
                <IconButton aria-label="qr">
                  <a
                    href={params.row.qrCode}
                    target="_blank"
                    rel="noreferrer"
                    style={{ display: "flex" }}
                  >
                    <QrCodeScannerIcon />
                  </a>
                </IconButton>
              </Tooltip>
            ),
          },
          {
            field: "menuQrCode",
            headerName: "Menu QR",
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) =>
              params.row.menuQrCode ? (
                <Tooltip title="QR">
                  <IconButton aria-label="qr">
                    <a
                      href={params.row.menuQrCode}
                      target="_blank"
                      rel="noreferrer"
                      style={{ display: "flex" }}
                    >
                      <QrCodeScannerIcon />
                    </a>
                  </IconButton>
                </Tooltip>
              ) : (
                "--"
              ),
          },
          {
            field: "menuHomeQr",
            headerName: "Home QR",
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) =>
              params.row.menuHomeQr ? (
                <Tooltip title="QR">
                  <IconButton aria-label="qr">
                    <a
                      href={params.row.menuHomeQr}
                      target="_blank"
                      rel="noreferrer"
                      style={{ display: "flex" }}
                    >
                      <QrCodeScannerIcon />
                    </a>
                  </IconButton>
                </Tooltip>
              ) : (
                "--"
              ),
          },
          {
            minWidth: 100,
            flex: 0.1,
            field: "orgLogo",
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            headerName: "Logo",
            renderCell: (params) =>
              params.row.orgLogo ? (
                <img
                  src={params.row.orgLogo}
                  alt=""
                  style={{ width: "70px" }}
                />
              ) : (
                <img src={noPhoto} alt="" style={{ width: "70px" }} />
              ),
          },
          {
            flex: 0.1,
            minWidth: 200,
            field: "pageTitle",
            headerName: "Page Title",
            renderCell: (params) =>
              params.row.pageTitle ? params.row.pageTitle : "-",
          },
          {
            flex: 0.1,
            minWidth: 140,
            field: "mobile",
            headerName: "Mobile",
            renderCell: (params) =>
              params.row.mobile ? params.row.mobile : "-",
          },
          {
            flex: 0.1,
            minWidth: 100,
            field: "views",
            headerName: "View Count",
            renderCell: (params) =>
              params.row.views ? (
                <p
                  style={{ color: "#7366ff", cursor: "pointer" }}
                  onClick={() =>
                    handlePreview(
                      params.row.pageId,
                      null,
                      null,
                      null,
                      params.row.pageTitle
                    )
                  }
                >
                  {params.row.views}
                </p>
              ) : (
                "0"
              ),
          },
          {
            field: "isActive",
            headerName: "Status",
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
              <Switch
                value={params.row.isActive ? true : false}
                onChange={() =>
                  switch_onChange_handle(
                    params.row.pageUuid,
                    params.row.isActive
                  )
                }
              />
            ),
          },

          {
            field: "view",
            headerName: "Detail",
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
              <Tooltip title="Detail">
                <IconButton
                  aria-label="detail"
                  // style={{ color: "green" }}
                  onClick={() => ShowPersonalPage(params.row.pageUuid)}
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
            ),
          },

          {
            field: "copy",
            headerName: "Copy",
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) =>
              USER_ROLE.PAGE_USER === +localStorage.getItem("roleId") ? (
                "--"
              ) : (
                <Tooltip title="Copy">
                  <IconButton
                    aria-label="copy"
                    style={{ color: "pink" }}
                    onClick={() =>
                      cloneUser(params.row.pageUuid, params.row.orgId)
                    }
                  >
                    <FileCopyIcon />
                  </IconButton>
                </Tooltip>
              ),
          },

          {
            field: "theme",
            headerName: "Theme",
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) =>
              params.row.templateId === TMPLATE.old ? (
                <Tooltip title="Theme">
                  <IconButton
                    aria-label="theme"
                    onClick={() =>
                      OpenThemePopup(params.row.pageUuid, params.row.orgId)
                    }
                    style={
                      params.row.templateId === TMPLATE.new
                        ? { color: "#c8d3b2" }
                        : { color: "yellowgreen" }
                    }
                    disabled={params.row.templateId === TMPLATE.new}
                  >
                    <OpacityIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                "-"
              ),
          },
          {
            flex: 0.1,
            minWidth: 150,
            field: "createdAt",
            headerName: "Created Date",
            renderCell: (params) =>
              params.row.createdAt
                ? moment
                    .utc(params.row.createdAt)
                    .format(MOMENT_SETTING.FORMATE)
                : "-",
          },
        ];
  const getCompanySlugUrl = async () => {
    try {
      let formData = {
        orgId: localStorage.getItem("orgId"),
      };
      const { data } = await Organization_Detail(formData);
      setFormDataOrg((prev) => {
        return {
          ...prev,
          slugUrl: data.payload.data.slugUrl,
          orgName: data.payload.data.orgName,
        };
      });
      setCompanySlugModal(true);
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  return (
    <>
      {personalPageIncentive && <PersonalPageprevieaw />}

      {/** Landing Page Table */}
      <Container fluid={true}>
        <Row>
          {+localStorage.getItem("roleId") === USER_ROLE.PAGE_ADMIN && (
            <div
              style={{
                paddingLeft: "26px",
                paddingBottom: "12px",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <p style={{ fontSize: "16px", paddingRight: "40px" }}>
                {LIMIT.TOTAL_PAGE_LIMIT} : {pageLimit}
              </p>
              <p style={{ fontSize: "16px" }}>
                {LIMIT.AVAILABLE_PAGE_LIMIT} : {pageLimit - record}
              </p>
              {/* <Tooltip title="Edit Company Slug Url" className="px-2">
                <IconButton
                  edge="start"
                  color="inherit"
                  style={{ marginLeft: "5px" }}
                  onClick={() => getCompanySlugUrl()}
                >
                  <Settings />
                </IconButton>
              </Tooltip> */}
            </div>
          )}

          <Col sm="12">
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <h3 style={headerStyle}>{TITLES.LANDING_PAGE}</h3>
                {USER_ROLE.PAGE_USER ===
                +localStorage.getItem("roleId") ? null : pageLimit ===
                  record ? (
                  <div
                    aria-label="add"
                    onClick={() => AddProfile()}
                    className="btn btn-primary"
                    style={Style}
                  >
                    <Add />
                  </div>
                ) : (
                  <div
                    aria-label="add"
                    onClick={() => AddProfile()}
                    className="btn btn-primary"
                    style={Style}
                  >
                    <Add />
                  </div>
                )}
              </CardHeader>
              <Row>
                <Col md={"2 my-3 mx-2"}>
                  <Input
                    placeholder="Search ..."
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                </Col>
              </Row>
              {tabelAnimation === true ? (
                <TabelAnimation />
              ) : formvalue.length ? (
                <DataGrid
                  autoHeight
                  sx={MuiTabelStyle}
                  pagination
                  rows={formvalue}
                  getRowId={(row) => row.pageUuid}
                  columns={defaultColumns}
                  rowsPerPageOptions={[7, 10, 25, 50]}
                  rowCount={record}
                  pageSize={pageSize}
                  page={page - 1}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  onPageChange={(newPageNo) => setPage(newPageNo + 1)}
                  paginationMode="server"
                  disableSelectionOnClick
                  checkboxSelection={false}
                  sortingMode="server"
                  onSortModelChange={sorting}
                />
              ) : (
                <DataNotFound />
              )}
            </Card>
          </Col>
        </Row>
      </Container>
      {/* /** Home Page Theme Select and Font Color Select */}
      <Modal size="lg" isOpen={color}>
        {/* <LoaderResponse /> */}
        <ModalHeader>
          <span>Select Your Home Page Theme</span>
        </ModalHeader>
        <ModalBody>
          <ModalHeader
            style={{ display: "block", justifyContent: "space-between" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ paddingRight: "1rem" }}>
                  <div
                    aria-label="add"
                    className="btn
                    "
                    onClick={() => SelectTheme()}
                    style={{
                      color: "white",
                      width: "4rem",
                      height: "4rem",
                      borderRadius: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: currentColor,
                      border: "1px solid black",
                    }}
                  >
                    <Droplet style={{ width: "19px", color: "black" }} />
                  </div>
                  <Label
                    htmlFor="validationCustom4"
                    style={{ fontSize: "15px", paddingLeft: "7px" }}
                  >
                    {"Theme"}
                  </Label>
                </div>
                <div style={{ paddingRight: "1rem" }}>
                  <div
                    aria-label="add"
                    className="btn
                    "
                    onClick={() => SelectFontTheme()}
                    style={{
                      color: "white",
                      width: "4rem",
                      height: "4rem",
                      borderRadius: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: fontTheme,
                      border: "1px solid black",
                    }}
                  >
                    <Edit3 style={{ width: "19px", color: "black" }} />
                  </div>
                  <Label
                    htmlFor="validationCustom4"
                    style={{ fontSize: "15px", paddingLeft: "16px" }}
                  >
                    {"Font"}
                  </Label>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ paddingRight: "1rem" }}>
                  <div
                    aria-label="add"
                    className="btn btn-danger"
                    // onClick={() => setColor(!color)}
                    onClick={() => close()}
                    style={Style}
                  >
                    <X />
                  </div>
                </div>
                <div style={{ paddingRight: "1rem" }}>
                  <button
                    aria-label="add"
                    className="btn btn-success"
                    style={Style}
                    onClick={(e) => onSubmit(e)}
                    disabled={loaderShow === true}
                  >
                    <Check />
                  </button>
                </div>
              </div>
            </div>
          </ModalHeader>
          <div style={{ display: "flex", position: "absolute" }}>
            <div>
              {theme && (
                <SketchPicker
                  color={currentColor}
                  onChangeComplete={(color) => handleOnChange(color)}
                />
              )}
            </div>
            <div>
              {themeFont && (
                <SketchPicker
                  color={fontTheme}
                  onChangeComplete={(color) => handleOnChangeFont(color)}
                />
              )}
            </div>
          </div>
          <div className="card-wrapper">
            <div className="card-container" style={appStyle.background}>
              <div className="card-dtl text-center">
                <div className="businessmen-img">
                  <img src={avtar} className="img-fluid" alt="avtar" />
                </div>
                <h2 className="businessman-name" style={appStyle.color}>
                  john doe
                </h2>
                <h6 className="business-name" style={appStyle.color}>
                  Businesscard Pvt Ltd
                </h6>
                <h6 className="business-category" style={appStyle.color}>
                  ( UI UX Designer )
                </h6>
              </div>
              <div className="card-contact">
                <div className="contact-items">
                  <a href="/" className="card-item">
                    <div className="icon-img">
                      <img src={call} className="img-fluid" alt="call" />
                    </div>
                    <p style={appStyle.color}>call</p>
                  </a>
                  <a href="/" className="card-item">
                    <div className="icon-img">
                      <img src={emailIcon} className="img-fluid" alt="email" />
                    </div>
                    <p style={appStyle.color}>email</p>
                  </a>
                  <a href="/" className="card-item">
                    <div className="icon-img">
                      <img
                        src={placeholder}
                        className="img-fluid"
                        alt="placeholder"
                      />
                    </div>
                    <p style={appStyle.color}>location</p>
                  </a>
                  <a href="/" className="card-item">
                    <div className="icon-img">
                      <img src={web} className="img-fluid" alt="web" />
                    </div>
                    <p style={appStyle.color}>website</p>
                  </a>
                  <a href="/" className="card-item">
                    <div className="icon-img">
                      <img
                        src={whatsapp}
                        className="img-fluid"
                        alt="whatsapp"
                      />
                    </div>
                    <p style={appStyle.color}>whatsapp</p>
                  </a>
                  <a href="/" className="card-item">
                    <div className="icon-img">
                      <img src={rupee} className="img-fluid" alt="rupee" />
                    </div>
                    <p style={appStyle.color}>pay me</p>
                  </a>
                  <div className="card-item about">
                    <div className="icon-img">
                      <img
                        src={profile}
                        className="img-fluid"
                        alt="profile-user"
                      />
                    </div>
                    <p style={appStyle.color}>about me</p>
                  </div>
                  <div className="card-item gallery">
                    <div className="icon-img">
                      <img src={gallery} className="img-fluid" alt="gallery" />
                    </div>
                    <p style={appStyle.color}>gallery</p>
                  </div>
                  <a href="/" className="card-item">
                    <div className="icon-img">
                      <img src={qrcode} className="img-fluid" alt="qr-code" />
                    </div>
                    <p style={appStyle.color}>QR code</p>
                  </a>
                  <div
                    className="card-item"
                    data-bs-toggle="modal"
                    data-bs-target="#inquiry-form"
                  >
                    <div className="icon-img">
                      <img src={adduser} className="img-fluid" alt="add-user" />
                    </div>
                    <p style={appStyle.color}>save contact</p>
                  </div>
                  <a href="/" className="card-item">
                    <div className="icon-img">
                      <img src={review} className="img-fluid" alt="review" />
                    </div>
                    <p style={appStyle.color}>review</p>
                  </a>
                  <a href="/" className="card-item">
                    <div className="icon-img">
                      <img
                        src={DigitalCard}
                        className="img-fluid"
                        alt="credit-card"
                      />
                    </div>
                    <p style={appStyle.color}>digital card</p>
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- overlay --> */}
          <div className="overlay"></div>
        </ModalBody>
      </Modal>

      <PreviewPopup
        handlePreview={handlePreview}
        pageId={pageId}
        setStartData={setStartData}
        setEndData={setEndData}
      />
      <MainOrganizationUpdate
        orgUpdateModal={companySlugModal}
        setOrgUpdateModal={setCompanySlugModal}
      />
    </>
  );
};

export default PersonalPageItem;
