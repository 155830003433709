import React, { useContext } from "react";
import CommonStylesChangesContext from "../../../context/CommonStylesChangesContext/CommonStylesChangesContext";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import { SketchPicker } from "react-color";
import { rgbColorCreate } from "../../../../utils/helper";

const SettingCommonStylesChanges = ({ handleSubmitSetting }) => {
  const { allStyle, setAllStyle } = useContext(CommonStylesChangesContext);
  const resetHandler = () => {
    setAllStyle((prev) => {
      return {
        ".section-title-area .section-subtitle": {
          color: {
            r: 255,
            g: 73,
            b: 124,
            a: 1,
          },
          "background-color": {
            r: 191,
            g: 52,
            b: 105,
            a: 0.2,
          },
        },
        ".section-title-area .section-title": {
          color: {
            r: 0,
            g: 0,
            b: 0,
            a: 100,
          },
          "font-size": "44px",
        },
        ".more-btn": {
          color: {
            r: 255,
            g: 255,
            b: 255,
            a: 1,
          },
          "background-color": {
            r: 255,
            g: 73,
            b: 124,
            a: 1,
          },
        },
        ".more-btn:hover": {
          color: {
            r: 255,
            g: 255,
            b: 255,
            a: 1,
          },
          "background-color": {
            r: 226,
            g: 18,
            b: 76,
            a: 100,
          },
        },
        ".slick-arrow::before": {
          color: {
            r: 255,
            g: 73,
            b: 124,
            a: 1,
          },
        },
        ".slick-dots li.slick-active button:before": {
          color: {
            r: 255,
            g: 73,
            b: 124,
            a: 1,
          },
        },
        ".slick-dots li button:before": {
          color: {
            r: 255,
            g: 73,
            b: 124,
            a: 1,
          },
        },
        mainDefValue: {
          colorOne: {
            r: 255,
            g: 73,
            b: 124,
            a: 1,
          },
          colorTwo: {
            r: 255,
            g: 255,
            b: 255,
            a: 1,
          },
          mainPadding: "100px",
          titleBottomMargin: "50px",
        },
      };
    });
  };

  const handleChangeMainSectionSpace = (e) => {
    const { name, value } = e.target;
    setAllStyle((prev) => {
      return {
        ...prev,
        ["mainDefValue"]: {
          ...prev["mainDefValue"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeTitleBottom = (e) => {
    const { name, value } = e.target;
    setAllStyle((prev) => {
      return {
        ...prev,
        ["mainDefValue"]: {
          ...prev["mainDefValue"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeTitleFont = (e) => {
    const { name, value } = e.target;
    setAllStyle((prev) => {
      return {
        ...prev,
        [".section-title-area .section-title"]: {
          ...prev[".section-title-area .section-title"],
          [name]: value,
        },
      };
    });
  };

  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="12">
            <div className="mt-4 d-flex flex-column justify-content-center ">
              <div className="d-flex justify-content-between">
                <h6>{"Edit"}</h6>
                <div className="d-flex gap-2">
                  <Button onClick={resetHandler}>Reset</Button>
                  <Button
                    onClick={() => handleSubmitSetting(false)}
                    color="success"
                  >
                    Save & View
                  </Button>
                </div>
              </div>
              <Row className="mt-2">
                <div className="d-flex align-items-center gap-4 mb-3">
                  {" "}
                  <h4>Make change in Sub Title</h4>
                  <h5
                    style={{
                      color: rgbColorCreate({
                        rgbString:
                          allStyle?.[".section-title-area .section-subtitle"]?.[
                            "color"
                          ],
                      }),
                      backgroundColor: rgbColorCreate({
                        rgbString:
                          allStyle?.[".section-title-area .section-subtitle"]?.[
                            "background-color"
                          ],
                      }),
                      padding: "10px 20px",
                      fontSize: "18px",
                      borderRadius: "15px",
                      fontWeight: "500",
                      display: "inline-block",
                    }}
                  >
                    Sub Title
                  </h5>
                </div>
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Color (Default : Pink)"}
                  </Label>
                  <SketchPicker
                    color={
                      allStyle?.[".section-title-area .section-subtitle"]?.[
                        "color"
                      ]
                    }
                    onChangeComplete={(e) =>
                      setAllStyle((prev) => {
                        return {
                          ...prev,
                          ".section-title-area .section-subtitle": {
                            ...prev[".section-title-area .section-subtitle"],
                            color: e.rgb,
                          },
                        };
                      })
                    }
                    onChange={(e) =>
                      setAllStyle((prev) => {
                        return {
                          ...prev,
                          ".section-title-area .section-subtitle": {
                            ...prev[".section-title-area .section-subtitle"],
                            color: e.rgb,
                          },
                        };
                      })
                    }
                  />
                </Col>
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Background Color (Default : Light Pink)"}
                  </Label>
                  <SketchPicker
                    color={
                      allStyle?.[".section-title-area .section-subtitle"]?.[
                        "background-color"
                      ]
                    }
                    onChangeComplete={(e) =>
                      setAllStyle((prev) => {
                        return {
                          ...prev,
                          ".section-title-area .section-subtitle": {
                            ...prev[".section-title-area .section-subtitle"],
                            "background-color": e.rgb,
                          },
                        };
                      })
                    }
                    onChange={(e) =>
                      setAllStyle((prev) => {
                        return {
                          ...prev,
                          ".section-title-area .section-subtitle": {
                            ...prev[".section-title-area .section-subtitle"],
                            "background-color": e.rgb,
                          },
                        };
                      })
                    }
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <div className="d-flex align-items-center gap-4 mb-3">
                  {" "}
                  <h4>Make change in Main Title</h4>
                  <h3
                    style={{
                      color: rgbColorCreate({
                        rgbString:
                          allStyle?.[".section-title-area .section-title"]?.[
                            "color"
                          ],
                      }),
                      backgroundColor: rgbColorCreate({
                        rgbString:
                          allStyle?.[".section-title-area .section-title"]?.[
                            "background-color"
                          ],
                      }),
                      padding: "10px 20px",
                      fontSize: "44px",
                      borderRadius: "15px",
                      fontWeight: "600",
                      display: "inline-block",
                    }}
                  >
                    Main Title
                  </h3>
                </div>
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Color (Default : Black)"}
                  </Label>
                  <SketchPicker
                    color={
                      allStyle?.[".section-title-area .section-title"]?.[
                        "color"
                      ]
                    }
                    onChangeComplete={(e) =>
                      setAllStyle((prev) => {
                        return {
                          ...prev,
                          ".section-title-area .section-title": {
                            ...prev[".section-title-area .section-title"],
                            color: e.rgb,
                          },
                        };
                      })
                    }
                    onChange={(e) =>
                      setAllStyle((prev) => {
                        return {
                          ...prev,
                          ".section-title-area .section-title": {
                            ...prev[".section-title-area .section-title"],
                            color: e.rgb,
                          },
                        };
                      })
                    }
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"font-size (Default : 44)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="font-size"
                    type="number"
                    placeholder="font-size"
                    value={allStyle?.[".section-title-area .section-title"]?.[
                      "font-size"
                    ]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeTitleFont(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <div className="d-flex align-items-center gap-4 mb-3">
                  {" "}
                  <h4>Make change in More Button</h4>
                  <a
                    style={{
                      color: rgbColorCreate({
                        rgbString: allStyle?.[".more-btn"]?.["color"],
                      }),
                      backgroundColor: rgbColorCreate({
                        rgbString:
                          allStyle?.[".more-btn"]?.["background-color"],
                      }),
                      padding: "5px 20px",
                      fontSize: "16px",
                      borderRadius: "15px",
                      fontWeight: "normal",
                      display: "inline-block",
                    }}
                  >
                    More
                  </a>
                </div>
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Color (Default : White)"}
                  </Label>
                  <SketchPicker
                    color={allStyle?.[".more-btn"]?.["color"]}
                    onChangeComplete={(e) =>
                      setAllStyle((prev) => {
                        return {
                          ...prev,
                          ".more-btn": {
                            ...prev[".more-btn"],
                            color: e.rgb,
                          },
                        };
                      })
                    }
                    onChange={(e) =>
                      setAllStyle((prev) => {
                        return {
                          ...prev,
                          ".more-btn": {
                            ...prev[".more-btn"],
                            color: e.rgb,
                          },
                        };
                      })
                    }
                  />
                </Col>
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Background Color (Default : Pink)"}
                  </Label>
                  <SketchPicker
                    color={allStyle?.[".more-btn"]?.["background-color"]}
                    onChangeComplete={(e) =>
                      setAllStyle((prev) => {
                        return {
                          ...prev,
                          ".more-btn": {
                            ...prev[".more-btn"],
                            "background-color": e.rgb,
                          },
                        };
                      })
                    }
                    onChange={(e) =>
                      setAllStyle((prev) => {
                        return {
                          ...prev,
                          ".more-btn": {
                            ...prev[".more-btn"],
                            "background-color": e.rgb,
                          },
                        };
                      })
                    }
                  />
                </Col>
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Color Hover (Default : White)"}
                  </Label>
                  <SketchPicker
                    color={allStyle?.[".more-btn:hover"]?.["color"]}
                    onChangeComplete={(e) =>
                      setAllStyle((prev) => {
                        return {
                          ...prev,
                          ".more-btn:hover": {
                            ...prev[".more-btn:hover"],
                            color: e.rgb,
                          },
                        };
                      })
                    }
                    onChange={(e) =>
                      setAllStyle((prev) => {
                        return {
                          ...prev,
                          ".more-btn:hover": {
                            ...prev[".more-btn:hover"],
                            color: e.rgb,
                          },
                        };
                      })
                    }
                  />
                </Col>
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Background Color Hover (Default : Dark Pink)"}
                  </Label>
                  <SketchPicker
                    color={allStyle?.[".more-btn:hover"]?.["background-color"]}
                    onChangeComplete={(e) =>
                      setAllStyle((prev) => {
                        return {
                          ...prev,
                          ".more-btn:hover": {
                            ...prev[".more-btn:hover"],
                            "background-color": e.rgb,
                          },
                        };
                      })
                    }
                    onChange={(e) =>
                      setAllStyle((prev) => {
                        return {
                          ...prev,
                          ".more-btn:hover": {
                            ...prev[".more-btn:hover"],
                            "background-color": e.rgb,
                          },
                        };
                      })
                    }
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <div className="d-flex align-items-center gap-4 mb-3">
                  {" "}
                  <h4>Make change in Slider Arrow</h4>
                  <h3
                    className="d-flex justify-content-center  align-items-center "
                    style={{
                      color: "white",
                      backgroundColor: rgbColorCreate({
                        rgbString:
                          allStyle?.[".slick-arrow::before"]?.["color"],
                      }),
                      width: "20px",
                      height: "20px",
                      fontSize: "20px",
                      borderRadius: "50%",
                      display: "inline-block",
                    }}
                  >
                    {"<"}
                  </h3>
                  <h3
                    className="d-flex justify-content-center  align-items-center "
                    style={{
                      color: "white",
                      backgroundColor: rgbColorCreate({
                        rgbString:
                          allStyle?.[".slick-arrow::before"]?.["color"],
                      }),
                      width: "20px",
                      height: "20px",
                      fontSize: "20px",
                      borderRadius: "50%",
                      display: "inline-block",
                    }}
                  >
                    {">"}
                  </h3>
                </div>
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Color (Default : Pink)"}
                  </Label>
                  <SketchPicker
                    color={allStyle?.[".slick-arrow::before"]?.["color"]}
                    onChangeComplete={(e) =>
                      setAllStyle((prev) => {
                        return {
                          ...prev,
                          ".slick-arrow::before": {
                            ...prev[".slick-arrow::before"],
                            color: e.rgb,
                          },
                        };
                      })
                    }
                    onChange={(e) =>
                      setAllStyle((prev) => {
                        return {
                          ...prev,
                          ".slick-arrow::before": {
                            ...prev[".slick-arrow::before"],
                            color: e.rgb,
                          },
                        };
                      })
                    }
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <div className="d-flex align-items-center gap-4 mb-3">
                  {" "}
                  <h4>Make change in Slider Slick Dots</h4>
                  <div className="d-flex align-items-center gap-2 ">
                    <p
                      className="d-flex justify-content-center  align-items-center "
                      style={{
                        color: "white",
                        backgroundColor: rgbColorCreate({
                          rgbString:
                            allStyle?.[
                              ".slick-dots li.slick-active button:before"
                            ]?.["color"],
                        }),
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        display: "inline-block",
                        opacity: "1",
                      }}
                    >
                      {""}
                    </p>
                    <p
                      className="d-flex justify-content-center  align-items-center "
                      style={{
                        color: "white",
                        backgroundColor: rgbColorCreate({
                          rgbString:
                            allStyle?.[".slick-dots li button:before"]?.[
                              "color"
                            ],
                        }),
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        display: "inline-block",
                        opacity: "0.25",
                      }}
                    >
                      {""}
                    </p>
                  </div>
                </div>
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Active Color (Default : Pink)"}
                  </Label>
                  <SketchPicker
                    color={
                      allStyle?.[".slick-dots li.slick-active button:before"]?.[
                        "color"
                      ]
                    }
                    onChangeComplete={(e) =>
                      setAllStyle((prev) => {
                        return {
                          ...prev,
                          ".slick-dots li.slick-active button:before": {
                            ...prev[
                              ".slick-dots li.slick-active button:before"
                            ],
                            color: e.rgb,
                          },
                        };
                      })
                    }
                    onChange={(e) =>
                      setAllStyle((prev) => {
                        return {
                          ...prev,
                          ".slick-dots li.slick-active button:before": {
                            ...prev[
                              ".slick-dots li.slick-active button:before"
                            ],
                            color: e.rgb,
                          },
                        };
                      })
                    }
                  />
                </Col>
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Not Active Color (Default : Pink)"}
                  </Label>
                  <SketchPicker
                    color={
                      allStyle?.[".slick-dots li button:before"]?.["color"]
                    }
                    onChangeComplete={(e) =>
                      setAllStyle((prev) => {
                        return {
                          ...prev,
                          ".slick-dots li button:before": {
                            ...prev[".slick-dots li button:before"],
                            color: e.rgb,
                          },
                        };
                      })
                    }
                    onChange={(e) =>
                      setAllStyle((prev) => {
                        return {
                          ...prev,
                          ".slick-dots li button:before": {
                            ...prev[".slick-dots li button:before"],
                            color: e.rgb,
                          },
                        };
                      })
                    }
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <Col md="4 mb-3">
                  <div className="d-flex align-items-center gap-4 mb-3">
                    {" "}
                    <h4>Make change in Color One</h4>
                    <h3
                      className="d-flex justify-content-center  align-items-center "
                      style={{
                        color: "white",
                        backgroundColor: rgbColorCreate({
                          rgbString: allStyle?.["mainDefValue"]?.["colorOne"],
                        }),
                        width: "40px",
                        height: "20px",
                        fontSize: "20px",
                        display: "inline-block",
                        border: "1px solid black",
                      }}
                    >
                      {""}
                    </h3>
                  </div>
                  <Label htmlFor="validationCustom03">
                    {"Font Color (Default : Pink)"}
                  </Label>
                  <SketchPicker
                    color={allStyle?.["mainDefValue"]?.["colorOne"]}
                    onChangeComplete={(e) =>
                      setAllStyle((prev) => {
                        return {
                          ...prev,
                          mainDefValue: {
                            ...prev["mainDefValue"],
                            colorOne: e.rgb,
                          },
                        };
                      })
                    }
                    onChange={(e) =>
                      setAllStyle((prev) => {
                        return {
                          ...prev,
                          mainDefValue: {
                            ...prev["mainDefValue"],
                            colorOne: e.rgb,
                          },
                        };
                      })
                    }
                  />
                </Col>
                <Col md="4 mb-3">
                  <div className="d-flex align-items-center gap-4 mb-3">
                    {" "}
                    <h4>Make change in Color Two</h4>
                    <h3
                      className="d-flex justify-content-center  align-items-center "
                      style={{
                        color: "white",
                        backgroundColor: rgbColorCreate({
                          rgbString: allStyle?.["mainDefValue"]?.["colorTwo"],
                        }),
                        width: "40px",
                        height: "20px",
                        fontSize: "20px",
                        display: "inline-block",
                        border: "1px solid black",
                      }}
                    >
                      {""}
                    </h3>
                  </div>
                  <Label htmlFor="validationCustom03">
                    {"Font Color (Default : Pink)"}
                  </Label>
                  <SketchPicker
                    color={allStyle?.["mainDefValue"]?.["colorTwo"]}
                    onChangeComplete={(e) =>
                      setAllStyle((prev) => {
                        return {
                          ...prev,
                          mainDefValue: {
                            ...prev["mainDefValue"],
                            colorTwo: e.rgb,
                          },
                        };
                      })
                    }
                    onChange={(e) =>
                      setAllStyle((prev) => {
                        return {
                          ...prev,
                          mainDefValue: {
                            ...prev["mainDefValue"],
                            colorTwo: e.rgb,
                          },
                        };
                      })
                    }
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <div className="d-flex align-items-center gap-4 mb-3">
                  {" "}
                  <h4>Manege Top Bottom Space</h4>
                </div>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding (Default : 100)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="mainPadding"
                    type="number"
                    placeholder="Padding"
                    value={allStyle?.["mainDefValue"]?.["mainPadding"]?.replace(
                      /px/g,
                      ""
                    )}
                    onChange={(e) => handleChangeMainSectionSpace(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <div className="d-flex align-items-center gap-4 mb-3">
                  {" "}
                  <h4>Make Space in Title Bottom</h4>
                </div>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding (Default : 50)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="titleBottomMargin"
                    type="number"
                    placeholder="Padding"
                    value={allStyle?.["mainDefValue"]?.[
                      "titleBottomMargin"
                    ]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeTitleBottom(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SettingCommonStylesChanges;
