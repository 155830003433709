import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Form,
} from "reactstrap";
import BrokerIncentiveContext from "../../context/BrokerIncentiveContext/BrokerIncentiveContext";
import {
  AddBrokerIncentiveApi,
  UpdateBrokerIncentive,
  brokerIncentivetype,
  brokerProjrctIncentivetype,
} from "../../api/Organization Api/OrganizationApi";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import { maxLengthCheck } from "../../utils/helper";
import { INCENTIVE } from "../../constant";

const BrokerIncentiveAddModel = (props) => {
  const { getBrokerIncentiveList, handleIncentive } = props;
  const {
    modal,
    setModal,
    projectId,
    setProjectId,
    brokerId,
    setBrokerId,
    BrokerIncentiveValue,
    setBrokerIncentiveValue,
    isEdit,
    setIsEdit,
    editId,
    BrokerIncentiveIsEdit,
    setBrokerIncentiveIsEdit,
    personalPageIncentiveEdit,
    setPersonalPageIncentiveEdit,
  } = useContext(BrokerIncentiveContext);

  const simpleValidator = useRef(
    new SimpleReactValidator({
      element: (message) => <div className="text-danger">{message}</div>,
    })
  );
  const [, forceUpdate] = useState();

  // to get project Id
  const getProjectId = async () => {
    let formData = {
      search: JSON.stringify({ type: 1 }),
    };
    const { data } = await brokerProjrctIncentivetype(formData);

    setProjectId(
      data.payload.data.map((ele) => ({
        id: ele.pageId,
        projectName: ele.pageTitle,
      }))
    );
  };

  //  to get broker Id
  const getBrokerId = async () => {
    let formData = {
      search: JSON.stringify({ type: 2 }),
    };
    const { data } = await brokerIncentivetype(formData);
    setBrokerId(
      data.payload.data.map((ele) => ({
        id: ele.pageId,
        projectName: ele.pageTitle,
      }))
    );
  };

  useEffect(() => {
    getProjectId();
    getBrokerId();
  }, []);

  /**
   * Popup close to empty feild
   */
  const close = () => {
    setBrokerIncentiveValue({
      pageId: "",
      brokerId: "",
      customerName: "",
      customerNumber: "",
      propertyDetail: "",
      incentive: "",
    });
    simpleValidator.current.hideMessages();
    forceUpdate(2);
    setModal(false);
    setIsEdit(false);
    setBrokerIncentiveIsEdit(false);
    setPersonalPageIncentiveEdit(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBrokerIncentiveValue({ ...BrokerIncentiveValue, [name]: value });
  };

  const handleBrokerIncentiveSubmit = async (e) => {
    e.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      if (isEdit === true) {
        try {
          let formData = {
            ...BrokerIncentiveValue,
            projectId: +BrokerIncentiveValue.projectId,
            BrokerId: +BrokerIncentiveValue.BrokerId,
            CustomerNumber: +BrokerIncentiveValue.CustomerNumber,
            Incentive: +BrokerIncentiveValue.Incentive,
          };
          const { data } = await UpdateBrokerIncentive({ formData, editId });
          if (data.status === 200) {
            close();
            toast.success(data.message, {
              autoClose: 1000,
            });
            if (BrokerIncentiveIsEdit || personalPageIncentiveEdit === true) {
              handleIncentive();
            } else {
              getBrokerIncentiveList();
            }
          }
        } catch (error) {}
      } else {
        try {
          let formData = {
            ...BrokerIncentiveValue,
            projectId: +BrokerIncentiveValue.projectId,
            BrokerId: +BrokerIncentiveValue.BrokerId,
            CustomerNumber: +BrokerIncentiveValue.CustomerNumber,
            Incentive: +BrokerIncentiveValue.Incentive,
          };
          const { data } = await AddBrokerIncentiveApi(formData);
          if (data.status === 200) {
            close();
            toast.success(data.message, {
              autoClose: 1000,
            });
            getBrokerIncentiveList();
          }
        } catch (error) {}
      }
    }
  };

  return (
    <>
      <Modal size="lg" isOpen={modal} toggle={() => close()}>
        <ModalHeader toggle={() => close()}>
          {isEdit ? (
            <span>{INCENTIVE.EDIT_BROKER_INCENTIVE}</span>
          ) : (
            <span>{INCENTIVE.ADD_BROKER_INCENTIVE}</span>
          )}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleBrokerIncentiveSubmit}>
            <Row>
              {!personalPageIncentiveEdit && (
                <Col md={BrokerIncentiveIsEdit ? 12 : 6}>
                  <FormGroup>
                    <Label for="exampleEmail">Project</Label>
                    <select
                      className="form-select"
                      style={{ marginBottom: "12px" }}
                      name="pageId"
                      value={BrokerIncentiveValue.pageId}
                      onChange={(e) => handleChange(e)}
                    >
                      <option value="select">--select--</option>
                      {projectId.map((elem) => {
                        return (
                          <option
                            key={elem.id}
                            value={elem.id}
                            name={elem.projectName}
                          >
                            {elem.projectName}
                          </option>
                        );
                      })}
                    </select>
                    {simpleValidator.current.message(
                      "project",
                      BrokerIncentiveValue.pageId,
                      "required"
                    )}
                  </FormGroup>
                </Col>
              )}

              {!BrokerIncentiveIsEdit && (
                <Col md={personalPageIncentiveEdit ? 12 : 6}>
                  <FormGroup>
                    <Label for="examplePassword">Broker</Label>
                    <select
                      className="form-select"
                      style={{ marginBottom: "12px" }}
                      name="brokerId"
                      value={BrokerIncentiveValue.brokerId}
                      onChange={(e) => handleChange(e)}
                    >
                      <option value="select">--select--</option>
                      {brokerId.map((elem) => {
                        return (
                          <option
                            key={elem.id}
                            value={elem.id}
                            name={elem.projectName}
                          >
                            {elem.projectName}
                          </option>
                        );
                      })}
                    </select>
                    {simpleValidator.current.message(
                      "broker",
                      BrokerIncentiveValue.brokerId,
                      "required"
                    )}
                  </FormGroup>
                </Col>
              )}
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="exampleAddress">Customer Name</Label>
                  <Input
                    id="exampleAddress"
                    name="customerName"
                    value={BrokerIncentiveValue.customerName}
                    onChange={(e) => handleChange(e)}
                  />
                  {simpleValidator.current.message(
                    "customerName",
                    BrokerIncentiveValue.customerName,
                    "required"
                  )}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="exampleAddress2">Customer Number</Label>
                  <Input
                    id="exampleCity"
                    name="customerNumber"
                    type="number"
                    value={BrokerIncentiveValue.customerNumber}
                    min={"0"}
                    maxLength={10}
                    onInput={maxLengthCheck}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  {simpleValidator.current.message(
                    "customerNumber",
                    BrokerIncentiveValue.customerNumber,
                    "required|min:10"
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="exampleCity">Property Detail</Label>
                  <Input
                    id="exampleCity"
                    name="propertyDetail"
                    type="text"
                    value={BrokerIncentiveValue.propertyDetail}
                    onChange={(e) => handleChange(e)}
                  />
                  {simpleValidator.current.message(
                    "propertyDetail",
                    BrokerIncentiveValue.propertyDetail,
                    "required"
                  )}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="exampleCity">Incentive</Label>
                  <Input
                    id="exampleCity"
                    name="incentive"
                    type="number"
                    value={BrokerIncentiveValue.incentive}
                    onChange={(e) => handleChange(e)}
                  />
                  {simpleValidator.current.message(
                    "incentive",
                    BrokerIncentiveValue.incentive,
                    "required"
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Button type="submit">Submit</Button>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default BrokerIncentiveAddModel;
