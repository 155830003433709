import React, { useContext, useMemo, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import { Cases, btnName, modalsName } from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import ContactUSCart from "../../../common/contactUSCart";
import ContactUsPopup from "./ContactUsPopup";
import CommonPopup from "../../../Modals/commonPopup";
import ByteMiniWebDashboardContext from "../../../context/ByteMiniWeb_Dashboard_Context/ByteMiniWeb_Dashboard_Context";
import ContactUsContext from "../../../context/ContactUsContaxt/ContactUsContaxt";
import {
  getContactUs,
  getDetailsContactUs,
  postContactUs,
  putUpdateContactUs,
} from "../../../apis/ContactUsApi/ContactUsApi";
import { randomStr } from "../../../../utils/helper";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import SimpleReactValidator from "simple-react-validator";
import { titleUpdateKey } from "../../../../constant";

const ContactUs = (props) => {
  const { webMasterDetail, navbarData, getData } = props;
  const { apiData } = useContext(ByteMiniWebDashboardContext);
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const {
    apiData: contactUsData,
    setApiData,
    fromData,
    setFormData,
    id,
    setId,
    setIsEdit,
    isEdit,
    filter,
    addFields,
    setAddFields,
  } = useContext(ContactUsContext);
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * get contact us
   */
  const getContactUsList = async (status) => {
    try {
      if (!filter.length && status) {
        setApiData((prev) => {
          return { ...prev, isLoading: true };
        });
      }
      let paramObj = {
        showAll: true,
        search: { title: filter },
      };
      const { data } = await getContactUs(paramObj);
      if (data.status === 200) {
        setApiData({
          ...contactUsData,
          isLoading: false,
          data: data.payload.data,
        });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  useMemo(() => {
    if (allPopupState?.contactusPopup) {
      getContactUsList();
    }
  }, [filter, allPopupState?.contactusPopup]);

  /**
   * contact us create and update
   */
  const handleSubmit = async ({
    contactUsId,
    isPrimary,
    defaultSetStar,
    isEditStar,
  }) => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      if (id > -1 && (isEdit || isEditStar)) {
        let newObj = {};
        if (
          contactUsId > -1 &&
          (isEdit || isEditStar) &&
          defaultSetStar === "star"
        ) {
          newObj = {
            isPrimary,
            contactUsId,
            navMapId: navbarData[0].navMapId,
          };
          setFormData((prev) => {
            return {
              isPrimary,
              contactUsId,
              navMapId: navbarData[0].navMapId,
            };
          });
        } else {
          newObj = {
            ...fromData,
            phone: addFields.phoneInput,
            email: addFields.emailInput,
            mapLink: `${fromData.latitude}, ${fromData.longitude}`,
            navMapId: navbarData[0].navMapId,
          };
          setFormData((prev) => {
            return {
              ...prev,
              phone: addFields.phoneInput,
              email: addFields.emailInput,
              mapLink: `${fromData.latitude}, ${fromData.longitude}`,
              navMapId: navbarData[0].navMapId,
            };
          });
        }
        try {
          const { data } = await putUpdateContactUs(newObj);
          if (data.status === 200) {
            getContactUsList();
            emptyData();
            TostMessage({ message: data, type: "success" });
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      } else {
        try {
          setLoaderShow(true);
          let newObj = {
            ...fromData,
            phone: addFields.phoneInput,
            email: addFields.emailInput,
            mapLink: `${fromData.latitude}, ${fromData.longitude}`,
            isPrimary: contactUsData.data.length === 0 ? true : false,
            navMapId: navbarData[0].navMapId,
          };
          setFormData((prev) => {
            return {
              ...prev,
              phone: addFields.phoneInput,
              email: addFields.emailInput,
              mapLink: `${fromData.latitude}, ${fromData.longitude}`,
              isPrimary: contactUsData.data.length === 0 ? true : false,
              navMapId: navbarData[0].navMapId,
            };
          });
          const { data } = await postContactUs(newObj);
          if (data.status === 200) {
            getContactUsList();
            emptyData();
            TostMessage({ message: data, type: "success" });
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      }
    }
  };

  /**
   * get contact us details
   * @param {id} contactUsId
   */
  const handleEdit = async (contactUsId, InputfocusId) => {
    try {
      setLoaderShow(true);
      setId(contactUsId);
      const { data } = await getDetailsContactUs(contactUsId);
      if (data.status === 200) {
        setIsEdit(true);
        let payload = data.payload.data;
        let lat_lon = payload.mapLink.split(",");
        setFormData({
          name: payload.name,
          addLine1: payload.addLine1,
          addLine2: payload.addLine2,
          area: payload.area,
          country: payload.country,
          state: payload.state,
          city: payload.city,
          pinCode: payload.pinCode,
          mapLink: payload.mapLink,
          workingHour: payload?.workingHour,
          contactUsId: contactUsId,
          latitude: lat_lon[0],
          longitude: lat_lon[1],
          webId: localStorage.getItem("webId"),
        });

        setAddFields({
          phoneInput: JSON.parse(payload.phone),
          emailInput: JSON.parse(payload.email),
        });

        InputfocusId.focus();
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * data clear
   */
  const emptyData = () => {
    setId("");
    setIsEdit(false);
    setFormData({
      name: "",
      addLine1: "",
      addLine2: "",
      area: "",
      country: "",
      state: "",
      city: "",
      pinCode: "",
      mapLink: "",
      phone: "",
      email: "",
      latitude: "",
      longitude: "",
      workingHour:
        "<p><strong>Working Hours</strong></p>\n\n<p><strong>Sunday</strong> : 09:00 AM - 01:00 PM<br />\n<strong>Monday</strong> : 09:00 AM - 01:00 PM<br />\n<strong>Tuesday</strong> :&nbsp;09:00 AM - 01:00 PM<br />\n<strong>Wednesday</strong> : 09:00 AM - 01:00 PM<br />\n<strong>Thursday</strong> :&nbsp;09:00 AM - 01:00 PM<br />\n<strong>Friday</strong> : 09:00 AM - 01:00 PM<br />\n<strong>Saturday</strong> : 09:00 AM - 01:00 PM</p>\n",
      webId: localStorage.getItem("webId"),
    });
    setLoaderShow(false);
    setAddFields({
      phoneInput: [
        {
          id: randomStr(2),
          phoneValue: "",
          dialCode: { value: "+91", label: "+91" },
        },
      ],
      emailInput: [{ id: randomStr(2), emailValue: "" }],
    });
  };

  const filterValue = () => {
    let [value] = navbarData.filter(
      (elem) => elem.navbarDetail.label === titleUpdateKey.Contact_Details
    );
    return value;
  };

  const value = useMemo(() => filterValue(), [navbarData]);
  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody className="support-ticket-font box-border-div">
            <Row>
              <div className="col-12">
                <CardBuilderHeaderSection
                  name={value?.label || "Contact Us"}
                  subName="Address"
                />
              </div>
              {apiData?.data?.contactUs?.map((elem) => {
                return (
                  <Col xl="6" md="6" key={elem.name}>
                    <ContactUSCart
                      name={elem?.name}
                      address={`${elem?.addLine1}, ${elem?.addLine2}, ${elem?.area}, ${elem?.city}, ${elem?.state}, ${elem?.country}, ${elem?.pinCode} `}
                    />
                  </Col>
                );
              })}

              <OpenPopupBtn
                openPopup={() =>
                  OpenModalCommonFunc(Cases.CONTACT_US, setAllPopupState)
                }
                name={btnName.CONTACT_US}
              />
            </Row>
          </CardBody>
        </Card>
      </Col>

      <CommonPopup
        isMini={true}
        open={allPopupState.contactusPopup}
        close={() =>
          OpenModalCommonFunc(Cases.CONTACT_US, setAllPopupState, false)
        }
        handleSubmit={() =>
          handleSubmit({
            contactUsId: -1,
            isPrimary: null,
            defaultSetStar: "",
            isEditStar: false,
          })
        }
        modalName={modalsName.CONATCTUS}
        modalCase={Cases.CONTACT_US}
        emptyData={emptyData}
        inputData={fromData}
        edit={true}
        oldTitle={titleUpdateKey.isContactUs}
        label={titleUpdateKey.Contact_Details}
        getData={getData}
        content={
          <ContactUsPopup
            handleEdit={handleEdit}
            getData={getContactUsList}
            handleSubmit={handleSubmit}
            emptyData={emptyData}
            simpleValidator={simpleValidator}
            webMasterDetail={webMasterDetail}
            navbarData={navbarData}
          />
        }
      />
    </>
  );
};

export default ContactUs;
