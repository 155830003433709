import React, { useContext } from "react";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { Cases, DELETE_CONFIRM } from "../../../constant";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import ItemsDragAndDrops from "../../../common/ItemsDragAndDrops";
import Editor from "../../../common/editor";
import OurTeamContext from "../../../context/OurTeamContext/OurTeamContext";
import {
  deleteOurTeamMember,
  sort_order_Our_Team_Member,
} from "../../../apis/OurTeamApi/OurTeamApi";
import { splitPathWeb } from "../../../apiConstants";
import { delete_Image_Permanent } from "../../../apis/SliderApi/SliderApi";
import Dividers from "../../../common/divider";
import { Trash, UploadCloud } from "react-feather";
import { Close } from "@material-ui/icons";
import { IconButton } from "@mui/material";
import { maxLengthCheck, randomStr } from "../../../../utils/helper";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";

const OurTeamMemberPopup = (props) => {
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const {
    handleChangeStatus,
    getData,
    imageUpload,
    handleEdit,
    emptyData,
    simpleValidator,
  } = props;
  const {
    apiData,
    setApiData,
    fromData,
    setFormData,
    setFilter,
    filter,
    skill,
    setSkill,
    isEdit,
  } = useContext(OurTeamContext);
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * drag and drop
   * @param {drag data} result
   * @returns
   */
  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;
    const items = Array.from(apiData.data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setApiData({ ...apiData, data: items });
    try {
      let itemOrder = [];
      for (const item in items) {
        itemOrder.push({ teamMemberId: items[item].Id, order: +item + 1 });
      }
      const { data } = await sort_order_Our_Team_Member(itemOrder);
      if (data.status === 200) {
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * delete oue team member
   * @param {id} teamMemberId
   */
  const handleDelete = async (teamMemberId) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await deleteOurTeamMember(teamMemberId);
        if (data.status === 200) {
          getData();
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * create new our team member
   */
  const hanldenewCreate = () => {
    emptyData();
  };

  /**
   * reupload image
   */
  const reupload = () => {
    setFormData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };

  /**
   * delete image permanently
   */
  const deleteImagePermanent = async () => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData = {
          fileName: fromData.image
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
            .pop(),
        };
        const { data } = await delete_Image_Permanent(formData);
        if (data.status === 200) {
          TostMessage({ message: data, type: "success", position: "center" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * add more skill fields
   */
  const handleAddMoreSkill = () => {
    setSkill((prev) => {
      return [...prev, { id: randomStr(2), skill: "", parentageOfSkill: "" }];
    });
  };

  /**
   * delete fields
   * @param {id} id
   */
  const handleDeleteSkill = (id) => {
    setSkill((prev) => prev.filter((item) => item.id !== id));
  };

  /**
   * skill input value change and input value change
   * @param {event} e
   * @param {i} i
   */
  const handleChange = (e, i) => {
    const { name, value } = e.target;
    let old = [...skill];
    old[i][name] = value;
    setSkill(old);
  };

  const handleAboutus = (event) => {
    setFormData((prev) => {
      return {
        ...prev,
        description: event,
      };
    });
  };

  const InputValueChange = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="6">
            <div>
              <h6>{apiData?.data?.length} Our Team</h6>
              <Row>
                <Col md="6">
                  <Input
                    className="form-control mt-3 "
                    name="search"
                    type="text"
                    placeholder="search"
                    style={{ borderRadius: "0", color: "black" }}
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                </Col>
                <Col
                  md="6"
                  className="d-flex justify-content-center align-items-center mt-3"
                >
                  <Button
                    color="primary"
                    className="rounded-0"
                    onClick={() => hanldenewCreate()}
                  >
                    Add New
                  </Button>
                </Col>
              </Row>
              {apiData.isLoading ? (
                <div className="mt-4">
                  <h6>Loading ...</h6>
                </div>
              ) : (
                <div className="mt-4">
                  <ItemsDragAndDrops
                    handleOnDragEnd={handleOnDragEnd}
                    characters={apiData?.data}
                    handleChangeStatus={handleChangeStatus}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                  />
                </div>
              )}
            </div>
          </Col>
          <Col sm="12" xl="6">
            <div className="mt-4 d-flex flex-column justify-content-center ">
              <h6>Add New</h6>
              <Row className="mt-4">
                <Col md="6 mb-1">
                  <Label htmlFor="validationCustom03">{"Title *"}</Label>
                  <Input
                    className="form-control"
                    name="title"
                    type="text"
                    placeholder="Title *"
                    value={fromData.title}
                    onChange={(e) => InputValueChange(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    min={0}
                    maxLength={50}
                    onInput={maxLengthCheck}
                  />
                  <div className="text-end mt-3 ">
                    <span>{fromData.title.length} / 50</span>
                  </div>
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "title",
                      fromData.title,
                      "required"
                    )}
                  </p>
                </Col>
                <Col md="6 mb-3">
                  <Label htmlFor="validationCustom03">{"Sub Title "}</Label>
                  <Input
                    className="form-control"
                    name="subTitle"
                    type="text"
                    value={fromData.subTitle}
                    onChange={(e) => InputValueChange(e)}
                    placeholder="Sub Title"
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                {skill.map((elem, i) => {
                  return (
                    <>
                      <Row key={elem.id}>
                        <Col md="6 mb-3">
                          <Label htmlFor="validationCustom03">{"Skill "}</Label>
                          <Input
                            className="form-control"
                            name="skill"
                            type="text"
                            value={elem.skill}
                            onChange={(e) => handleChange(e, i)}
                            placeholder="Skill"
                            style={{ borderRadius: "0", color: "black" }}
                          />
                        </Col>
                        <Col md="5 mb-3">
                          <Label htmlFor="validationCustom03">
                            {"Percentage Of Skill "}
                          </Label>
                          <Input
                            className="form-control"
                            name="parentageOfSkill"
                            type="number"
                            value={elem.parentageOfSkill}
                            onChange={(e) => handleChange(e, i)}
                            placeholder="Percentage Of Skill"
                            style={{ borderRadius: "0", color: "black" }}
                          />
                        </Col>
                        {skill.length >= 2 && (
                          <Col
                            md="1 mb-3"
                            className="d-flex justify-content-center align-items-center pt-4"
                          >
                            <IconButton
                              edge="start"
                              color="inherit"
                              style={{ marginLeft: "5px", color: "red" }}
                              onClick={() => handleDeleteSkill(elem.id)}
                            >
                              <Trash />
                            </IconButton>
                          </Col>
                        )}
                      </Row>
                    </>
                  );
                })}
                {skill.length < 4 && (
                  <Col md="12 mb-3">
                    <Button onClick={() => handleAddMoreSkill()}>
                      Add More Skill
                    </Button>
                  </Col>
                )}
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Description "}</Label>
                  <Editor
                    value={fromData.description}
                    onChange={handleAboutus}
                  />
                </Col>
              </Row>
              <Dividers divide="IMAGES" />
              <p className="mb-4">
                File type required JPG, PNG (512px X 512px)
              </p>
              <Row>
                <Col md="6 mb-3">
                  <div
                    className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    <span
                      onClick={() =>
                        OpenModalCommonFunc(
                          Cases.PRODUCT_UPLOAD,
                          setAllPopupState
                        )
                      }
                    >
                      <UploadCloud />
                    </span>
                    {fromData.image && (
                      <img
                        src={fromData.image ? fromData.image : ""}
                        alt=""
                        style={{
                          width: "80%",
                          height: "98%",
                          objectFit: "contain",
                          position: "absolute",
                        }}
                        onClick={() =>
                          OpenModalCommonFunc(
                            Cases.PRODUCT_UPLOAD,
                            setAllPopupState
                          )
                        }
                      />
                    )}
                    {fromData.image && (
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="close"
                        style={{
                          color: "red",
                          position: "absolute",
                          top: "-22px",
                          left: "1px",
                        }}
                        onClick={() => deleteImagePermanent()}
                      >
                        <Close
                          style={{ fontSize: "25px", fontWeight: "bold" }}
                        />
                      </IconButton>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <ProductImageUploadPopup
          allPopupState={allPopupState}
          setAllPopupState={setAllPopupState}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
          image={fromData.image}
          setImage={setFormData}
          imageUpload={imageUpload}
          isEdit={isEdit}
          reupload={reupload}
          aspect={1 / 1}
        />
      </Container>
    </>
  );
};

export default OurTeamMemberPopup;
