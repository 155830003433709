import React, { useContext, useMemo, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import { Cases, DELETE_CONFIRM, modalsName } from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import Lightbox from "react-image-lightbox";
import CommonPopup from "../../../Modals/commonPopup";
import BlogDetailPopup from "./BlogDetailPopup";
import { FILE_TYPE, ThemeNo } from "../../../../constant";
import BlogPopup from "./BlogPopup";
import { IconButton } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import { Trash } from "react-feather";
import {
  randomStr,
  rgbColorCreate,
  generateDynamicStyles,
  generateDynamicStylesSet,
  isEmptyObject,
} from "../../../../utils/helper";
import { Settings } from "react-feather";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import SimpleReactValidator from "simple-react-validator";
import BlogContext from "../../../context/BlogContext/BlogContext";
import {
  deleteBlog,
  getBlogDetailApi,
  getBlogDetailDetails,
  getBlogDetails,
  postBlog,
  postBlogDetail,
  putUpdateBlog,
  putUpdateBlogDetail,
  updateStatus,
  updateStatusBlog,
} from "../apis/BlogApi/BlogApi";
import SettingBlogParent from "./SettingBlogParent";
import { FILE_UPLOAD_API } from "../apis/SliderApi/SliderApi";
import DeleteSection from "../../../common/DeleteSection";
import SettingMultiWebContext from "../../../../context/SettingMultiWebContext/SettingMultiWebContext";
import {
  BlogStyleOneSubFun,
  BlogStyleThemeOneReset,
  BlogThemeOneFunction,
} from "./BlogStyleFunction";

const Blog = (props) => {
  const {
    pageConfigDetails,
    blogCategoryDetail,
    contentMapId,
    ByteMiniWeb_Dashboard,
    navMapId: navMapIds,
    contentDetail,
  } = props;
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );
  const { setCloneAlignModal, setAlignFormData, setCloneFormData } = useContext(
    SettingMultiWebContext
  );
  const parentValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const childValidator = useRef(new SimpleReactValidator());
  const { setLoaderShow } = useContext(LoaderContext);
  const {
    apiData: ourTeamData,
    setApiData,
    fromData,
    setFormData,
    formDataOurTeam,
    setFormDataOurData,
    id,
    setId,
    setIsEdit,
    isEdit,
    filter,
    setSkill,
    teamMemberId,
    setTeamMemberId,
    settingFormData,
    setSettingFormData,
    setBackImageToggle,
    boxSection,
    setBoxSection,
    collId,
    setCollId,
    isBackgroundColor,
    setIsBackgroundColor,
    isBackgroundColor2,
    setIsBackgroundColor2,
    boxSectionDetail,
    setBoxSectionDetail,
    backImageToggleDetail,
    setBackImageToggleDetail,
    isImageUpload,
    setIsImageUpload,
    backImageToggle,
    setTheme,
    theme,
    setImgShow,
    sectionPageName,
    setSectionPageName,
  } = useContext(BlogContext);
  const [open, setOpen] = useState(false);
  const [imageSet] = useState("");

  /**
   * get blog detail list
   */
  const getBlogDetail = async (parentId = null, status) => {
    try {
      if (!filter.length && status) {
        setApiData((prev) => {
          return { ...prev, isLoading: true };
        });
      }
      let paramObj = {
        search: { blogCategoryId: parentId || id, contentMapId: collId },
        showAll: true,
      };

      if (filter.length) {
        paramObj.search = { ...paramObj.search, title: filter };
      }
      const { data } = await getBlogDetailApi(paramObj);
      if (data.status === 200) {
        setApiData({
          ...ourTeamData,
          isLoading: false,
          data: data.payload.data.map((elem) => {
            return {
              id: elem?.title + elem?.blogDetailId,
              Id: elem?.blogDetailId,
              name: elem?.title,
              image: elem?.image,
              webId: elem?.webId,
              headLine: elem?.headLine,
              createdAt: elem?.createdAt,
              isActive: elem?.isActive,
              parentId: elem?.blogCategoryId,
            };
          }),
        });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  useMemo(() => {
    if (allPopupState?.blogDetailPopup) {
      getBlogDetail();
    }
  }, [filter, allPopupState?.blogDetailPopup]);

  /**
   * blog detail enable disable api call
   * @param {teamMember id} blogDetailId
   * @param {status} isActive
   */

  const handleChangeStatus = async (
    blogDetailId,
    isActive,
    isChild,
    parentId
  ) => {
    try {
      let body = {
        blogDetailId,
        isActive: isActive ? false : true,
        parentId,
      };
      const { data } = await updateStatus(body);
      if (data.status === 200) {
        if (isChild) {
          getBlogDetail(false);
        } else {
          ByteMiniWeb_Dashboard();
        }

        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };
  /**
   * blog enable disable api call
   * @param {team id} blogCategoryId
   * @param {status} isActive
   */

  const handleChangeBlogStatus = async (blogCategoryId, isActive) => {
    try {
      let body = {
        blogCategoryId,
        isActive: isActive ? false : true,
      };
      const { data } = await updateStatusBlog(body);
      if (data.status === 200) {
        ByteMiniWeb_Dashboard();
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * file upload
   * @param {file} file
   */
  const imageUpload = async (file) => {
    setLoaderShow(true);
    try {
      const Image = await imageUploadFunc(file);
      setFormDataOurData((prev) => {
        return {
          ...prev,
          image: Image,
        };
      });
      setLoaderShow(false);
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const imageUploadBlog = async (file) => {
    setLoaderShow(true);
    try {
      const Image = await imageUploadFunc(file);
      setFormData((prev) => {
        return {
          ...prev,
          image: Image,
        };
      });
      setLoaderShow(false);
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const imageUploadBlogSetting = async (file) => {
    setLoaderShow(true);
    try {
      const Image = await imageUploadFunc(file);
      // setSettingFormData((prev) => {
      //   return {
      //     ...prev,
      //     ".blog-section.bg-image": {
      //       "background-image": Image,
      //     },
      //   };
      // });
      const ObjForImageSet = {
        theme: () =>
          setSettingFormData((prev) => {
            return {
              ...prev,
              ".blog-section.bg-image": {
                "background-image": Image,
              },
            };
          }),
        detail: () =>
          setSettingFormData((prev) => {
            return {
              ...prev,
              ".service-detail-section.bg-image": {
                "background-image": Image,
              },
            };
          }),
      };

      if (
        backImageToggle &&
        isImageUpload?.isList &&
        "isList" in isImageUpload
      ) {
        ObjForImageSet.theme();
      } else if (
        backImageToggleDetail &&
        isImageUpload?.isDetails &&
        "isDetails" in isImageUpload
      ) {
        ObjForImageSet.detail();
      }

      setLoaderShow(false);
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const imageUploadFunc = async (file) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.BLOG);
    formData.append("file", file);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        return data.payload.baseUrl + data.payload.imageUrl;
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * create and update blog detail
   */
  const handleSubmitSet = async () => {
    if (teamMemberId >= -1 && isEdit) {
      setLoaderShow(true);
      let newData = {
        ...fromData,
        blogCategoryId: id,
        blogDetailId: teamMemberId,
        contentMapId: collId,
      };
      if (fromData.image.length) {
        setFormData((prev) => {
          return {
            ...prev,
            image: prev.image
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
              .pop(),
            // image: prev.image,
            blogCategoryId: id,
            blogDetailId: teamMemberId,
            contentMapId: collId,
          };
        });

        newData["image"] = fromData.image
          .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
          .pop();
        // newData["image"] = fromData.image;
      }
      try {
        const { data } = await putUpdateBlogDetail(newData);
        if (data.status === 200) {
          getBlogDetail();
          emptyData();
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    } else {
      try {
        setLoaderShow(true);
        let newData = {
          ...fromData,
          blogCategoryId: id,
          order: ourTeamData.data.length + 1,
          contentMapId: collId,
        };
        if (fromData.image.length) {
          setFormData((prev) => {
            return {
              ...prev,
              image: prev.image
                .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
                .pop(),
              blogCategoryId: id,
              order: ourTeamData.data.length + 1,
              contentMapId: collId,
            };
          });
          // newData["image"] = fromData.image;
          newData["image"] = fromData.image
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
            .pop();
        }
        const { data } = await postBlogDetail(newData);
        if (data.status === 200) {
          getBlogDetail();
          emptyData();
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  /**
   * create and update blog
   */
  const handleSubmitBlog = async () => {
    if (id >= -1 && isEdit) {
      setLoaderShow(true);
      let newData = {
        ...formDataOurTeam,
        contentMapId: collId,
        navMapId: +navMapIds,
      };
      try {
        const { data } = await putUpdateBlog(newData);
        if (data.status === 200) {
          ByteMiniWeb_Dashboard();
          emptyData();
          OpenModalCommonFunc(Cases.BLOG, setAllPopupState, false);
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    } else {
      try {
        setLoaderShow(true);
        let newData = {
          ...formDataOurTeam,
          contentMapId: collId,
          navMapId: +navMapIds,
        };
        const { data } = await postBlog(newData);
        if (data.status === 200) {
          ByteMiniWeb_Dashboard();
          emptyData();
          OpenModalCommonFunc(Cases.BLOG, setAllPopupState, false);
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  const handleSubmit = async (formValidationCondition) => {
    let validatorFields;
    if (formValidationCondition === "parentForm") {
      validatorFields = parentValidator.current;
    } else if (formValidationCondition === "childForm") {
      validatorFields = childValidator.current;
    }
    validatorFields.showMessages();

    if (validatorFields.allValid()) {
      try {
        // Process form data based on the formType
        if (formValidationCondition === "parentForm") {
          handleSubmitBlog();
        } else if (formValidationCondition === "childForm") {
          handleSubmitSet();
        }
        validatorFields.hideMessages();
      } catch (error) {
        // Handle error for form submission
      }
    } else {
      // Force update to display validation messages
      forceUpdate({});
    }
  };

  /**
   * get blog detail details
   * @param {id} blogDetailId
   */
  const handleEdit = async (blogDetailId) => {
    try {
      setLoaderShow(true);
      setTeamMemberId(blogDetailId);
      const { data } = await getBlogDetailDetails(blogDetailId);
      if (data.status === 200) {
        let payload = data.payload.data;
        setFormData({
          title: payload?.title || "",
          shortDescription: payload?.shortDescription || "",
          description: payload?.description || "",
          image: payload?.image || "",
          slugUrl: payload?.slugUrl || "",
          isTitleShow: payload?.isTitleShow ? payload?.isTitleShow : false,
          author: payload?.author || "",
          buttonText: payload?.buttonText || "",
          buttonValue: payload?.buttonValue || "",
          blogCategoryId: payload?.blogCategoryId,
          webId: localStorage?.getItem("webId"),
        });
        setSkill(payload?.skill && payload?.skill);
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * create and update steps
   */
  const handleSubmitSetting = async (isRestore = false, isClose = true) => {
    if (id >= -1 && isEdit) {
      setLoaderShow(true);
      try {
        let newData;
        if (isRestore) {
          const isConfirm = window.confirm(
            "Are you sure want to restore style ?"
          );
          if (isConfirm) {
            newData = {
              styleRestore: true,
              blogCategoryId: id,
            };
          } else {
            setLoaderShow(false);
            return;
          }
        } else {
          if (+theme === 1) {
            newData = BlogStyleOneSubFun({
              settingFormData,
              boxSection,
              boxSectionDetail,
            });
            const menuBtnStyle = generateDynamicStyles(
              settingFormData,
              ".blog-section"
            );
            newData = { ...newData, ...menuBtnStyle };
            if (isBackgroundColor) {
              newData[".blog-section"] = {
                ...newData?.[".blog-section"],
                "background-color": settingFormData[".blog-section"]?.[
                  "background-color"
                ]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData[".blog-section"]?.["background-color"],
                    })
                  : "",
              };
            }
            if (isBackgroundColor2) {
              newData[".service-detail-section"] = {
                ...newData?.[".service-detail-section"],
                "background-color": settingFormData[
                  ".service-detail-section"
                ]?.["background-color"]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData[".service-detail-section"]?.[
                          "background-color"
                        ],
                    })
                  : "",
              };
            }
            if (boxSection) {
              newData[".blog-section.bg-image::before"] = {
                ...newData[".blog-section.bg-image::before"],
                "border-radius": `${settingFormData[".blog-section"][
                  "border-radius"
                ]?.replace("rem", "")}rem`,
              };
            }
          } else {
          }

          const keysToRemove = ["p1", "p2", "image"];
          function removeKeys(obj, keys) {
            for (const key in obj) {
              if (typeof obj[key] === "object") {
                removeKeys(obj[key], keys);
              } else if (keys.includes(key)) {
                delete obj[key];
              }
            }
          }
          removeKeys(newData, keysToRemove);
          newData = {
            style: newData,
            blogCategoryId: id,
          };
        }
        const { data } = await putUpdateBlog(newData);
        if (data.status === 200) {
          // getBlogList();
          if (isClose) {
            OpenModalCommonFunc(
              Cases.SETTING_BLOG_PARENT,
              setAllPopupState,
              false
            );
            setBackImageToggle(false);
          }
          setLoaderShow(false);
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  /**
   * get data blog
   * @param {id} blogCategoryId
   */
  const handleEditOurTeam = async (blogCategoryId) => {
    try {
      setLoaderShow(true);
      setId(blogCategoryId);
      const { data } = await getBlogDetails(blogCategoryId, navMapIds);
      if (data.status === 200) {
        let payload = data.payload.data;
        setSectionPageName(payload?.title);
        setFormDataOurData({
          title: payload?.title || "",
          subTitle: payload?.subTitle || "",
          buttonText: payload?.buttonText || "",
          itemLimit: payload?.itemLimit || "",
          theme: payload?.theme || 1,
          isTitleShow: payload?.isTitleShow ? payload?.isTitleShow : false,
          navMapId: payload?.navMapId || "",
          blogCategoryId: blogCategoryId,
          webId: localStorage?.getItem("webId"),
          slugUrl: payload?.slugUrl || "",
        });
        OpenModalCommonFunc(Cases.BLOG, setAllPopupState);
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(true);
    }
  };

  /**
   * get setting data
   * @param {id} blogCategoryId
   */
  const handleChangeSettings = async (blogCategoryId, isThemeEdit = false) => {
    try {
      setLoaderShow(true);
      setId(blogCategoryId);
      setSettingFormData((prev) => {
        return {
          ...prev,
          blogCategoryId,
        };
      });
      const { data } = await getBlogDetails(blogCategoryId);
      if (data.status === 200) {
        const themeCount = data?.payload?.data?.theme;
        setTheme(themeCount);
        setImgShow(true);
        let payload = data.payload.data;
        setSectionPageName(payload.title);
        if (
          +themeCount === ThemeNo.ThemeOne &&
          isEmptyObject(data?.payload?.data?.style)
        ) {
          setSettingFormData({ ...BlogStyleThemeOneReset });
          setBoxSection(false);
          setBackImageToggle(false);
          setIsBackgroundColor(false);
          setBoxSectionDetail(false);
          setBackImageToggleDetail(false);
          setIsBackgroundColor2(false);
        } else {
          if (
            payload.style?.[".blog-section"]?.["width"] ||
            payload.style?.[".blog-section"]?.["margin"] ||
            payload.style?.[".blog-section"]?.["border-radius"]
          ) {
            setBoxSection(true);
          }
          if (payload.style?.[".blog-section.bg-image"]?.["background-image"]) {
            setBackImageToggle(true);
          }
          if (payload.style?.[".blog-section"]?.["background-color"]) {
            setIsBackgroundColor(true);
          }
          if (
            payload.style?.[".service-detail-section"]?.["width"] ||
            payload.style?.[".service-detail-section"]?.["margin"] ||
            payload.style?.[".service-detail-section"]?.["border-radius"]
          ) {
            setBoxSectionDetail(true);
          }
          if (
            payload.style?.[".service-detail-section.bg-image"]?.[
              "background-image"
            ] !== "" &&
            payload.style?.[".service-detail-section.bg-image"]?.[
              "background-image"
            ] !== null &&
            payload.style?.[".service-detail-section.bg-image"]?.[
              "background-image"
            ] !== undefined &&
            payload.style?.[".service-detail-section.bg-image"]?.[
              "background-image"
            ] !== "url()"
          ) {
            setBackImageToggleDetail(true);
          }
          if (
            payload.style?.[".service-detail-section"]?.["background-color"]
          ) {
            setIsBackgroundColor2(true);
          }
          const getMenuBtnStyle = generateDynamicStylesSet(
            payload,
            ".blog-section"
          );
          let getStyle;
          if (+themeCount === 1) {
            getStyle = BlogThemeOneFunction(payload);
          } else {
            getStyle = BlogThemeOneFunction(payload);
          }
          setSettingFormData((prev) => {
            return {
              ...prev,
              showItem: payload.style.showItem,
              ...getStyle,
              ...getMenuBtnStyle,
            };
          });
        }
        if (!isThemeEdit) {
          OpenModalCommonFunc(Cases.SETTING_BLOG_PARENT, setAllPopupState);
          TostMessage({ message: data, type: "success" });
        }
        setIsEdit(true);
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * delete blog
   * @param {id} blogCategoryId
   */
  const handleDelete = async (blogCategoryId, isParent = false) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await deleteBlog(blogCategoryId);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            ByteMiniWeb_Dashboard();
          } else {
            // getBlogList();
          }
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * clear data
   */
  const emptyData = () => {
    setBackImageToggle(false);
    setIsEdit(false);
    setTheme(1);
    setFormData({
      title: "",
      shortDescription: "",
      description: "",
      image: "",
      slugUrl: "",
      author: "",
      isTitleShow: true,
      buttonText: "",
      buttonValue: "",
      webId: localStorage.getItem("webId"),
    });
    setSkill([{ id: randomStr(2), skill: "", parentageOfSkill: "" }]);
    setTeamMemberId("");
    setFormDataOurData({
      title: "",
      subTitle: "",
      theme: 1,
      slugUrl: "",
      navMapId: "",
      buttonText: "",
      itemLimit: "",
      isTitleShow: true,
      webId: localStorage.getItem("webId"),
      style: {
        ".blog-section": {
          padding: "100px 0",
          "background-color": "rgb(255 255 255 / 100)",
        },
        ".blog-section.bg-image": {
          "background-image": "",
        },
        ".blog-section.bg-image::before": {
          "background-color": "",
        },
        ".blog-section .section-title-area": {
          "margin-bottom": "50px",
        },
        ".blog-section .section-title-area .section-subtitle": {
          "font-size": "16px",
          "font-weight": "600",
          color: "rgb(255 73 124 / 100)",
          "background-color": "rgb(255 73 124 / 0.2)",
          padding: "5px 20px",
          p1: "5px",
          p2: "20px",
          "border-radius": "15px",
          "margin-bottom": "15px",
        },
        ".blog-section .section-title-area .section-title": {
          "font-size": "44px",
          "font-weight": "600",
          color: "rgb(0 0 0 / 100)",
        },
        ".blog_items .blog_thumb": {
          "border-radius": "5px",
          "margin-bottom": "20px",
        },
        ".blog_items .blog_thumb img": {
          "border-radius": "5px",
        },
        ".blog_items .blog_content .blog_meta": {
          "margin-bottom": "15px",
        },
        ".blog_items .blog_content .blog_meta .date": {
          color: "rgb(76 84 98 / 100)",
        },
        ".blog_items .blog_content .blog_meta .posted": {
          color: "rgb(255 73 124 / 100)",
          "font-size": "14px",
          "font-weight": "500",
          padding: "5px 10px",
          p1: "5px",
          p2: "10px",
          "border-radius": "5px",
          "background-color": "rgb(255 73 124 / 0.2)",
        },
        ".blog_items .blog_content .blog_title": {
          "font-size": "20px",
          "font-weight": "600",
          "margin-bottom": "15px",
        },
        ".blog_items .blog_content .blog_title a": {
          color: "rgb(0 0 0 / 100)",
        },
        ".blog_items .blog_content .blog_title a:hover": {
          color: "rgb(255 73 124 / 100)",
        },
        ".blog_items .blog_content .blog_desq": {
          color: "rgb(76 84 98 / 100)",
          "font-size": "16px",
          "font-weight": "400",
          "line-height": "1.6",
          "margin-bottom": "20px",
          "text-align": "justify",
        },
        ".blog_items .blog_content .link-btn": {
          color: "rgb(255 255 255 / 100)",
          "background-color": "rgb(255 73 124 / 100)",
        },
        ".blog-section .more-btn": {
          color: "rgb(255 255 255 / 100)",
          "font-size": "16px",
          "font-weight": "normal",
          "border-radius": "15px",
          padding: "5px 20px",
          "background-color": "rgb(255 73 124 / 100)",
        },
        ".blog-section .more-btn:hover": {
          "background-color": "rgb(255 73 124 / 100)",
          color: "rgb(255 255 255 / 100)",
        },
        ".pagination-positions": {
          "justify-content": "center",
        },
        ".pagination .page-link": {
          color: "rgb(255 73 124 / 100)",
        },
        ".pagination .page-item.active .page-link": {
          "background-color": "rgb(255 73 124 / 100)",
          "border-color": "rgb(255 73 124 / 100)",
        },
        "service-detail-section": {
          padding: "60px 0",
          "background-color": "rgb(255 255 255 / 100)",
        },
        ".personal-details .details-title": {
          "font-weight": 600,
          "font-size": "24px",
        },
        ".personal-details p": {
          "margin-bottom": "20px",
          "line-height": 1.6,
          "font-size": "18px",
        },
        ".personal-details .info-list": {
          "margin-bottom": "30px",
        },
        ".personal-details .info-list li": {
          "margin-bottom": "15px",
          "font-size": "18px",
        },
        ".personal-details .order-list": {
          "margin-bottom": "30px",
        },
        ".personal-details .order-list li": {
          "padding-left": "25px",
          "margin-bottom": "15px",
          "font-size": "18px",
        },
        ".personal-details .order-list li:before": {
          "font-family": "FontAwesome",
          top: "3px",
          color: "rgb(76 84 98 / 100)",
        },
        ".side_thumb img": {
          height: "90px",
          width: "90px",
          "border-radius": "4px",
        },
        ".side_content .side_meta": {
          "font-size": "14px",
          color: "#6a727f",
          "font-weight": 600,
          "margin-bottom": "3px",
        },
        ".side_content .side_title": {
          "font-size": "16px",
          "font-weight": 700,
          color: "rgb(0 0 0 / 100)",
          "line-height": 1.5,
        },
        ".side_content .side_title a": {
          color: "rgb(0 0 0 / 100)",
        },
        blogCategoryId: "",
        showItem: 3,
      },
    });

    setSettingFormData({
      ".blog-section": {
        padding: "100px 0",
        "background-color": "rgb(255 255 255 / 100)",
      },
      ".blog-section.bg-image": {
        "background-image": "",
      },
      ".blog-section.bg-image::before": {
        "background-color": "",
      },
      ".blog-section .section-title-area": {
        "margin-bottom": "50px",
      },
      ".blog-section .section-title-area .section-subtitle": {
        "font-size": "16px",
        "font-weight": "600",
        color: "rgb(255 73 124 / 100)",
        "background-color": "rgb(255 73 124 / 0.2)",
        padding: "5px 20px",
        p1: "5px",
        p2: "20px",
        "border-radius": "15px",
        "margin-bottom": "15px",
      },
      ".blog-section .section-title-area .section-title": {
        "font-size": "44px",
        "font-weight": "600",
        color: "rgb(0 0 0 / 100)",
      },
      ".blog_items .blog_thumb": {
        "border-radius": "5px",
        "margin-bottom": "20px",
      },
      ".blog_items .blog_thumb img": {
        "border-radius": "5px",
      },
      ".blog_items .blog_content .blog_meta": {
        "margin-bottom": "15px",
      },
      ".blog_items .blog_content .blog_meta .date": {
        color: "rgb(76 84 98 / 100)",
      },
      ".blog_items .blog_content .blog_meta .posted": {
        color: "rgb(255 73 124 / 100)",
        "font-size": "14px",
        "font-weight": "500",
        padding: "5px 10px",
        p1: "5px",
        p2: "10px",
        "border-radius": "5px",
        "background-color": "rgb(255 73 124 / 0.2)",
      },
      ".blog_items .blog_content .blog_title": {
        "font-size": "20px",
        "font-weight": "600",
        "margin-bottom": "15px",
      },
      ".blog_items .blog_content .blog_title a": {
        color: "rgb(0 0 0 / 100)",
      },
      ".blog_items .blog_content .blog_title a:hover": {
        color: "rgb(255 73 124 / 100)",
      },
      ".blog_items .blog_content .blog_desq": {
        color: "rgb(76 84 98 / 100)",
        "font-size": "16px",
        "font-weight": "400",
        "line-height": "1.6",
        "margin-bottom": "20px",
        "text-align": "justify",
      },
      ".blog_items .blog_content .link-btn": {
        color: "rgb(255 255 255 / 100)",
        "background-color": "rgb(255 73 124 / 100)",
      },
      ".blog-section .more-btn": {
        color: "rgb(255 255 255 / 100)",
        "font-size": "16px",
        "font-weight": "normal",
        "border-radius": "15px",
        padding: "5px 20px",
        "background-color": "rgb(255 73 124 / 100)",
      },
      ".blog-section .more-btn:hover": {
        "background-color": "rgb(255 73 124 / 100)",
        color: "rgb(255 255 255 / 100)",
      },
      ".pagination-positions": {
        "justify-content": "center",
      },
      ".pagination .page-link": {
        color: "rgb(255 73 124 / 100)",
      },
      ".pagination .page-item.active .page-link": {
        "background-color": "rgb(255 73 124 / 100)",
        "border-color": "rgb(255 73 124 / 100)",
      },
      "service-detail-section": {
        padding: "60px 0",
        "background-color": "rgb(255 255 255 / 100)",
      },
      ".personal-details .details-title": {
        "font-weight": 600,
        "font-size": "24px",
      },
      ".personal-details p": {
        "margin-bottom": "20px",
        "line-height": 1.6,
        "font-size": "18px",
      },
      ".personal-details .info-list": {
        "margin-bottom": "30px",
      },
      ".personal-details .info-list li": {
        "margin-bottom": "15px",
        "font-size": "18px",
      },
      ".personal-details .order-list": {
        "margin-bottom": "30px",
      },
      ".personal-details .order-list li": {
        "padding-left": "25px",
        "margin-bottom": "15px",
        "font-size": "18px",
      },
      ".personal-details .order-list li:before": {
        "font-family": "FontAwesome",
        top: "3px",
        color: "rgb(76 84 98 / 100)",
      },
      ".side_thumb img": {
        height: "90px",
        width: "90px",
        "border-radius": "4px",
      },
      ".side_content .side_meta": {
        "font-size": "14px",
        color: "#6a727f",
        "font-weight": 600,
        "margin-bottom": "3px",
      },
      ".side_content .side_title": {
        "font-size": "16px",
        "font-weight": 700,
        color: "rgb(0 0 0 / 100)",
        "line-height": 1.5,
      },
      ".side_content .side_title a": {
        color: "rgb(0 0 0 / 100)",
      },
      blogCategoryId: "",
      showItem: 3,
    });
    setIsImageUpload({ isList: false, isDetails: false });
    setLoaderShow(false);
  };

  const handleAlignAndCloneSection = ({ isClone = false }) => {
    if (isClone) {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          cloneModal: true,
        };
      });
      setCloneFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          pageConfigDetailsLabel: pageConfigDetails[0]?.label,
          contentDetailName: contentDetail.name,
        };
      });
    } else {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          alignModal: true,
        };
      });
      setAlignFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          label: pageConfigDetails[0]?.label,
        };
      });
    }
  };

  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody
            className="support-ticket-font box-border-div"
            style={{ padding: "20px" }}
          >
            <Row>
              <CardBuilderHeaderSection
                addBtnTitle={pageConfigDetails[0]?.label}
                name={pageConfigDetails[0]?.label}
                isAdd={!blogCategoryDetail && true}
                mainEditSection={true}
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
                openModal={() => {
                  OpenModalCommonFunc(Cases.BLOG, setAllPopupState);
                  setSectionPageName(modalsName.BLOG);
                  emptyData();
                  setCollId(contentMapId);
                }}
                isCloneSection={true}
                isAlignSection={true}
                handleAlignAndCloneSection={handleAlignAndCloneSection}
              />
              {blogCategoryDetail && (
                <Col xl="12 xl-50 box-col-6" md="6">
                  <Card className="ecommerce-widget mb-0">
                    <CardBody
                      className="support-ticket-font"
                      style={{ padding: "20px" }}
                    >
                      <div className="col-12">
                        <CardBuilderHeaderSection
                          name={blogCategoryDetail?.title}
                          checked={blogCategoryDetail?.isActive}
                          switchToggle={true}
                          id={blogCategoryDetail?.blogCategoryId}
                          handleChange={handleChangeBlogStatus}
                        />
                        <OpenPopupBtn
                          openPopup={() => {
                            setSectionPageName(blogCategoryDetail.title);
                            getBlogDetail(blogCategoryDetail.blogCategoryId);
                            OpenModalCommonFunc(
                              Cases.BLOG_DETAIL,
                              setAllPopupState
                            );
                            setId(blogCategoryDetail.blogCategoryId);
                            setCollId(contentMapId);
                          }}
                          name={`Add or View ${blogCategoryDetail?.title}`}
                        />
                      </div>
                      <div className="d-flex justify-content-end ">
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleEditOurTeam(
                              blogCategoryDetail.blogCategoryId
                            );
                            setCollId(contentMapId);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() =>
                            handleDelete(
                              blogCategoryDetail.blogCategoryId,
                              true
                            )
                          }
                        >
                          <Trash />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleChangeSettings(
                              blogCategoryDetail.blogCategoryId
                            );
                          }}
                        >
                          <Settings />
                        </IconButton>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
              <DeleteSection
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
              />
            </Row>
          </CardBody>
        </Card>
      </Col>

      {open && (
        <Lightbox mainSrc={imageSet} onCloseRequest={() => setOpen(false)} />
      )}
      {/* blog detail popup */}
      <CommonPopup
        open={allPopupState.blogDetailPopup}
        close={() =>
          OpenModalCommonFunc(Cases.BLOG_DETAIL, setAllPopupState, false)
        }
        modalName={sectionPageName ? sectionPageName : modalsName.BLOG_DETAIL}
        modalCase={Cases.BLOG_DETAIL}
        handleSubmit={handleSubmit}
        validationForm={"childForm"}
        emptyData={emptyData}
        inputData={fromData}
        edit={false}
        content={
          <BlogDetailPopup
            handleChangeStatus={handleChangeStatus}
            getData={getBlogDetail}
            handleEdit={handleEdit}
            imageUpload={imageUploadBlog}
            emptyData={emptyData}
            simpleValidator={childValidator}
          />
        }
      />

      {/* blog popup */}
      <CommonPopup
        open={allPopupState.blogPopup}
        close={() => OpenModalCommonFunc(Cases.BLOG, setAllPopupState, false)}
        modalName={sectionPageName ? sectionPageName : modalsName.BLOG}
        modalCase={Cases.BLOG}
        handleSubmit={handleSubmit}
        emptyData={emptyData}
        inputData={formDataOurTeam}
        validationForm={"parentForm"}
        edit={false}
        content={
          <BlogPopup
            imageUpload={imageUpload}
            simpleValidator={parentValidator}
          />
        }
      />
      {/* setting Service Counter parent popup */}
      <CommonPopup
        open={allPopupState.blogSettingPopup}
        close={() =>
          OpenModalCommonFunc(
            Cases.SETTING_BLOG_PARENT,
            setAllPopupState,
            false
          )
        }
        modalName={
          sectionPageName ? sectionPageName : modalsName.SETTING_BLOG_PARENT
        }
        modalCase={Cases.SETTING_BLOG_PARENT}
        handleSubmit={handleSubmitSetting}
        emptyData={emptyData}
        // inputData={formDataChild}
        edit={false}
        content={
          <SettingBlogParent
            imageUpload={imageUploadBlogSetting}
            handleSubmitSetting={handleSubmitSetting}
            handleChangeSettings={handleChangeSettings}
          />
        }
      />
    </>
  );
};

export default Blog;
