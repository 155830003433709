import axios from "axios";
import { POST_THEME } from "../../apiConstants";

/**
 * update theme
 * @param {body} body
 * @returns
 */
export const putUpdateTheme = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${POST_THEME}`,
    { otherSetting: body },
    {
      headers: { Authorization: jwt_token },
    }
  );
};
