import React, { useContext } from "react";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import { TopHeaderStyleReset } from "../../../constant";
import FooterContext from "../../../context/FooterContext/FooterContext";
import { SketchPicker } from "react-color";
import CustomStyle from "../../../common/CustomStyle";
import { Border } from "../../../../constant";

const TopHeaderStylePopup = (props) => {
  const { isEdit, setFormData, formData } = useContext(FooterContext);
  const { submitForm } = props;

  /**
   * input value change
   * @param {event} e
   */
  const handleChangeTextColor = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        topHeaderStyle: {
          ...prev.topHeaderStyle,
          ".top-header-section .top-left a": {
            ...prev.topHeaderStyle[".top-header-section .top-left a"],
            [name]: value,
          },
        },
      };
    });
  };
  const handleChangeSocialGap = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        topHeaderStyle: {
          ...prev.topHeaderStyle,
          ".top-header-section .top-social a": {
            ...prev.topHeaderStyle[".top-header-section .top-social a"],
            [name]: value,
          },
        },
      };
    });
  };
  const handleChangeSelectLanguage = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        topHeaderStyle: {
          ...prev.topHeaderStyle,
          ".goog-te-gadget": {
            ...prev.topHeaderStyle[".goog-te-gadget"],
            [name]: value,
          },
        },
      };
    });
  };
  const HandleComplete = ({ event, classIS, className }) => {
    setFormData((prev) => {
      return {
        ...prev,
        topHeaderStyle: {
          ...prev.topHeaderStyle,
          [classIS]: {
            ...prev.topHeaderStyle[classIS],
            [className]: event.rgb,
          },
        },
      };
    });
  };
  const HandleOnChange = ({ event, classIS, className }) => {
    setFormData((prev) => {
      return {
        ...prev,
        topHeaderStyle: {
          ...prev.topHeaderStyle,
          [classIS]: {
            ...prev.topHeaderStyle[classIS],
            [className]: event.rgb,
          },
        },
      };
    });
  };

  const handleChangeCustomStyle = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        topHeaderStyle: {
          ...prev.topHeaderStyle,
          [name]: value,
        },
      };
    });
  };
  const resetHandler = () => {
    setFormData({
      topHeaderStyle: TopHeaderStyleReset,
    });
  };
  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="12">
            <div className="mt-4 d-flex flex-column justify-content-center position-relative">
              <div
                className="d-flex justify-content-between position-sticky"
                style={{ top: "100px", background: "white", zIndex: 999 }}
              >
                <h6>{isEdit ? "Edit" : "Add New"}</h6>
                <div className="d-flex gap-2">
                  <Button onClick={resetHandler}>Reset</Button>
                  <Button
                    onClick={() =>
                      submitForm({
                        formValidationCondition: "topHeaderStyleForm",
                        isRestore: true,
                      })
                    }
                  >
                    Restore
                  </Button>
                  <Button
                    onClick={() =>
                      submitForm({
                        formValidationCondition: "topHeaderStyleForm",
                        isClose: false,
                      })
                    }
                    color="success"
                  >
                    Save & View
                  </Button>
                </div>
              </div>
              <Row className="mt-4">
                <h4 className="mb-3">Header Background Color</h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"background Color "}
                  </Label>
                  <SketchPicker
                    color={
                      formData?.topHeaderStyle?.[".top-header-section"]?.[
                        "background-color"
                      ]
                    }
                    onChangeComplete={(e) =>
                      HandleComplete({
                        event: e,
                        classIS: ".top-header-section",
                        className: "background-color",
                      })
                    }
                    onChange={(e) =>
                      HandleOnChange({
                        event: e,
                        classIS: ".top-header-section",
                        className: "background-color",
                      })
                    }
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">Text Color and gap maintain</h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">{"Color "}</Label>
                  <SketchPicker
                    color={
                      formData?.topHeaderStyle?.[
                        ".top-header-section .top-left a"
                      ]?.["color"]
                    }
                    onChangeComplete={(e) =>
                      HandleComplete({
                        event: e,
                        classIS: ".top-header-section .top-left a",
                        className: "color",
                      })
                    }
                    onChange={(e) =>
                      HandleOnChange({
                        event: e,
                        classIS: ".top-header-section .top-left a",
                        className: "color",
                      })
                    }
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">{"Gap"}</Label>
                  <div className="d-flex justify-content-between">
                    <Input
                      className="form-control"
                      name="ml"
                      type="number"
                      placeholder="Gap"
                      value={formData?.topHeaderStyle?.[
                        ".top-header-section .top-left a"
                      ]?.["ml"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangeTextColor(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                  </div>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">{"Font Size"}</Label>
                  <div className="d-flex justify-content-between">
                    <Input
                      className="form-control"
                      name="font-size"
                      type="number"
                      placeholder="Font Size"
                      value={formData?.topHeaderStyle?.[
                        ".top-header-section .top-left a"
                      ]?.["font-size"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangeTextColor(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                  </div>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">{"Letter Spacing"}</Label>
                  <div className="d-flex justify-content-between">
                    <Input
                      className="form-control"
                      name="letter-spacing"
                      type="number"
                      placeholder="Letter Spacing"
                      value={formData?.topHeaderStyle?.[
                        ".top-header-section .top-left a"
                      ]?.["letter-spacing"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangeTextColor(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                  </div>
                </Col>
              </Row>
              {/* <hr />
              <Row className="mt-4">
                <h4 className="mb-3">Text Color and gap maintain</h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">{"Color "}</Label>
                  <SketchPicker
                    color={
                      formData?.topHeaderStyle?.[
                        ".top-header-section .marquee-link a"
                      ]?.["color"]
                    }
                    onChangeComplete={(e) =>
                      HandleComplete({
                        event: e,
                        classIS: ".top-header-section .marquee-link a",
                        className: "color",
                      })
                    }
                    onChange={(e) =>
                      HandleOnChange({
                        event: e,
                        classIS: ".top-header-section .marquee-link a",
                        className: "color",
                      })
                    }
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">{"Gap"}</Label>
                  <div className="d-flex justify-content-between">
                    <Input
                      className="form-control"
                      name="ml"
                      type="number"
                      placeholder="Gap"
                      value={formData?.topHeaderStyle?.[
                        ".top-header-section .marquee-link a"
                      ]?.["ml"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangeTextColor(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                  </div>
                </Col>
              
              </Row> */}
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">Social Gap</h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">{"Gap"}</Label>
                  <Input
                    className="form-control"
                    name="ml"
                    type="number"
                    placeholder="Gap"
                    value={formData?.topHeaderStyle?.[
                      ".top-header-section .top-social a"
                    ]?.["ml"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeSocialGap(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">{"Font-size"}</Label>
                  <Input
                    className="form-control"
                    name="font-size"
                    type="number"
                    placeholder="font-size"
                    value={formData?.topHeaderStyle?.[
                      ".top-header-section .top-social a"
                    ]?.["font-size"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeSocialGap(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">{"Color "}</Label>
                  <SketchPicker
                    color={
                      formData?.topHeaderStyle?.[
                        ".top-header-section .top-social a"
                      ]?.["color"]
                    }
                    onChangeComplete={(e) =>
                      HandleComplete({
                        event: e,
                        classIS: ".top-header-section .top-social a",
                        className: "color",
                      })
                    }
                    onChange={(e) =>
                      HandleOnChange({
                        event: e,
                        classIS: ".top-header-section .top-social a",
                        className: "color",
                      })
                    }
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">Make change in select language</h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">{"Border-radius"}</Label>
                  <Input
                    className="form-control"
                    name="border-radius"
                    type="number"
                    placeholder="Gap"
                    value={formData?.topHeaderStyle?.[".goog-te-gadget"]?.[
                      "border-radius"
                    ]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeSelectLanguage(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border (Default : 1 solid white)"}
                  </Label>
                  <div className="d-flex">
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="borderPx"
                        type="number"
                        placeholder="px"
                        value={formData?.topHeaderStyle?.[".goog-te-gadget"]?.[
                          "borderPx"
                        ]?.replace(/px/g, "")}
                        onChange={(e) => handleChangeSelectLanguage(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <select
                        name="borderThik"
                        id="borderThik"
                        className="form-control"
                        value={
                          formData?.topHeaderStyle?.[".goog-te-gadget"]?.[
                            "borderThik"
                          ]
                        }
                        onChange={(e) => handleChangeSelectLanguage(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      >
                        <option value="">--select--</option>
                        {Border.map((elem) => {
                          return (
                            <option value={elem.border} key={elem.border}>
                              {elem.border}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div>
                      <SketchPicker
                        color={
                          formData?.topHeaderStyle?.[".goog-te-gadget"]?.[
                            "borderColor"
                          ]
                        }
                        onChangeComplete={(e) =>
                          HandleComplete({
                            event: e,
                            classIS: ".goog-te-gadget",
                            className: "borderColor",
                          })
                        }
                        onChange={(e) =>
                          HandleOnChange({
                            event: e,
                            classIS: ".goog-te-gadget",
                            className: "borderColor",
                          })
                        }
                      />
                    </div>
                  </div>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">{"Font Color "}</Label>
                  <SketchPicker
                    color={
                      formData?.topHeaderStyle?.[
                        ".goog-te-gadget .goog-te-combo"
                      ]?.["color"]
                    }
                    onChangeComplete={(e) =>
                      HandleComplete({
                        event: e,
                        classIS: ".goog-te-gadget .goog-te-combo",
                        className: "color",
                      })
                    }
                    onChange={(e) =>
                      HandleOnChange({
                        event: e,
                        classIS: ".goog-te-gadget .goog-te-combo",
                        className: "color",
                      })
                    }
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">{"Background"}</Label>
                  <SketchPicker
                    color={
                      formData?.topHeaderStyle?.[
                        ".goog-te-gadget .goog-te-combo"
                      ]?.["background"]
                    }
                    onChangeComplete={(e) =>
                      HandleComplete({
                        event: e,
                        classIS: ".goog-te-gadget .goog-te-combo",
                        className: "background",
                      })
                    }
                    onChange={(e) =>
                      HandleOnChange({
                        event: e,
                        classIS: ".goog-te-gadget .goog-te-combo",
                        className: "background",
                      })
                    }
                  />
                </Col>
              </Row>
              <CustomStyle
                handleChange={handleChangeCustomStyle}
                value={formData?.topHeaderStyle?.custom}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default TopHeaderStylePopup;
