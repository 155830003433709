import React, { useContext, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import { Cases, DELETE_CONFIRM, modalsName } from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import CommonPopup from "../../../Modals/commonPopup";
import TestimonialMultipagePopup from "./TestimonialMultipagePopup";
import EditIcon from "@mui/icons-material/Edit";
import TestimonialMultipageParentPopup from "./TestimonialMultipageParentPopup";
import { FILE_UPLOAD_API } from "../apis/SliderApi/SliderApi";
import {
  generateDynamicStyles,
  generateDynamicStylesSet,
  isCheckValue,
  isEmptyObject,
  randomStr,
  rgbColorCreate,
} from "../../../../utils/helper";
import {
  FILE_TYPE,
  TestimonialThemeResetOne,
  TestimonialThemeResetTwo,
  TestimonialThemeThreeSet,
  ThemeNo,
  titleUpdateKey,
} from "../../../../constant";
import { IconButton } from "@material-ui/core";
import { Settings, Trash } from "react-feather";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import SimpleReactValidator from "simple-react-validator";
import TestimonialMultipageContext from "../../../context/TestimonialMultipageContext/TestimonialMultipageContext";
import {
  deleteTestimonial,
  getTestimonial,
  getTestimonialDetails,
  postTestimonial,
  putUpdateTestimonial,
  updateStatus,
} from "../apis/TestimonialApi/TestimonialApi";
import SettingTestimonialPerentPopup from "./SettingTestimonialPerentPopup";
import {
  TestimonialOneSubFun,
  TestimonialThemeOneFunction,
  TestimonialThemeTwoFunction,
  TestimonialTwoSubFun,
} from "./TestimonialStyleFunction";
import DeleteSection from "../../../common/DeleteSection";
import SettingMultiWebContext from "../../../../context/SettingMultiWebContext/SettingMultiWebContext";

const TestimonialMultipage = (props) => {
  const {
    pageConfigDetails,
    webMasterDetail,
    testimonialDetail,
    contentMapId,
    ByteMiniWeb_Dashboard,
    navMapId: navMapIds,
    contentDetail,
  } = props;
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );
  const { setCloneAlignModal, setAlignFormData, setCloneFormData } = useContext(
    SettingMultiWebContext
  );
  const parentValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const childValidator = useRef(new SimpleReactValidator());
  const {
    fromData,
    setFormData,
    formDataChild,
    setFormDataChild,
    id,
    setId,
    setIsEdit,
    isEdit,
    setFilter,
    apiDataOurTeam: apiDataImageParent,
    setApiDataOurTeam: setApiDataImageParent,
    setSkill,
    setGalleryImgId,
    setSettingFormData,
    settingFormData,
    isParent,
    setIsParent,
    childId,
    setChildId,
    navMapId,
    setNavMapId,
    setBackImageToggle,
    boxSection,
    setBoxSection,
    collId,
    setCollId,
    isBackgroundColor,
    setIsBackgroundColor,
    theme,
    setTheme,
    imageIs,
    setImgShow,
    sectionPageName,
    setSectionPageName,
  } = useContext(TestimonialMultipageContext);
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * get testimonial list
   * @param {status} status
   */

  const getTestimonialParentList = async ({
    parentId = null,
    isClose = false,
    filter,
    contentMapId,
  }) => {
    try {
      let paramObj = {};
      if (
        ((parentId && parentId !== null) ||
          (id && isClose === false && isParent === false)) &&
        isParent === false
      ) {
        paramObj["search"] = { parentId: parentId || id };
        setId(parentId || id);
        if (filter?.length) {
          paramObj["search"] = { ...paramObj.search, title: filter };
        }
      } else {
        paramObj["search"] = { parentId: null, contentMapId };
        paramObj["showAll"] = true;
      }
      const { data } = await getTestimonial(paramObj);

      if (data.status === 200) {
        if (isCheckValue({ value: contentMapId })) {
          setCollId(contentMapId);
        }
        if (
          isParent === false &&
          ((parentId && parentId !== null) || id) &&
          isClose === false
        ) {
          setApiDataImageParent({
            ...apiDataImageParent,
            isLoading: false,
            data: data.payload.data.map((elem) => {
              return {
                id: elem?.title + elem?.testimonialId,
                Id: elem?.testimonialId,
                name: elem?.title,
                image: elem?.image,
                webId: elem?.webId,
                createdAt: elem?.createdAt,
                isActive: elem?.isActive,
                parentId: elem?.parentId,
              };
            }),
          });
          OpenModalCommonFunc(Cases.TESTIMONIAL_MULTIPAGE, setAllPopupState);
        } else if (parentId && parentId !== null) {
          OpenModalCommonFunc(Cases.TESTIMONIAL_MULTIPAGE, setAllPopupState);
          setApiDataImageParent({
            ...apiDataImageParent,
            isLoading: false,
            data: data.payload.data.map((elem) => {
              return {
                id: elem?.title + elem?.testimonialId,
                Id: elem?.testimonialId,
                name: elem?.title,
                image: elem?.image,
                webId: elem?.webId,
                createdAt: elem?.createdAt,
                isActive: elem?.isActive,
                parentId: elem?.parentId,
              };
            }),
          });
        } else {
          setApiDataImageParent({
            ...apiDataImageParent,
            isLoading: false,
            data: data.payload.data,
          });
        }
        if (isClose && isClose !== false) {
          setIsParent(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * testimonial enable disable api call
   * @param {testimonialId} testimonialId
   * @param {status} isActive
   */

  const handleChangeTestimonial = async (
    testimonialId,
    isActive,
    isChild,
    parentId
  ) => {
    try {
      let body = {
        testimonialId,
        isActive: isActive ? false : true,
        parentId,
      };
      const { data } = await updateStatus(body);
      if (data.status === 200) {
        if (isChild) {
          getTestimonialParentList({ parentId: id });
        } else {
          ByteMiniWeb_Dashboard();
        }
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * file upload
   * @param {file} file
   */
  const imageUploadImages = async (file) => {
    setLoaderShow(true);
    try {
      const Image = await imageUpload(file);
      setFormDataChild((prev) => {
        return {
          ...prev,
          image: Image,
        };
      });
      setLoaderShow(false);
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const imageUploadSetting = async (file) => {
    setLoaderShow(true);
    try {
      const Image = await imageUpload(file);
      setSettingFormData((prev) => {
        if (imageIs === "testimonialImage1") {
          return {
            ...prev,
            ".testimonial-section.bg-image": {
              "background-image": Image,
            },
          };
        } else if (imageIs === "testimonialImage2") {
          return {
            ...prev,
            ".zig-zag-section.bg-image": {
              "background-image": Image,
            },
          };
        }
      });
      setLoaderShow(false);
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const imageUpload = async (file) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.TESTIMONIAL);
    formData.append("file", file);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        return data.payload.baseUrl + data.payload.imageUrl;
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * create and update testimonial
   */
  const submitForm = async () => {
    if (
      (isParent === false && childId >= -1 && isEdit) ||
      (id >= -1 && isEdit)
    ) {
      setLoaderShow(true);
      try {
        let formData = {};
        if (isParent && isParent !== false) {
          formData = {
            ...fromData,
            testimonialId: id,
            contentMapId: collId,
            navMapId: +navMapIds,
          };
          delete formData.image;
          delete formData.style;
          setId("");
        } else {
          formData = {
            ...formDataChild,
            parentId: id,
            navMapId,
            testimonialId: childId,
            contentMapId: collId,
          };
          delete formData.image;
          // delete formData.subTitle;
          if (formDataChild.image?.length) {
            formData["image"] = formDataChild.image
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
              .pop();
          }
        }
        const { data } = await putUpdateTestimonial(formData);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            ByteMiniWeb_Dashboard();
          } else {
            getTestimonialParentList({ parentId: id });
          }
          emptyData();
          OpenModalCommonFunc(
            Cases.TESTIMONIAL_MULTIPAGE_PARENT,
            setAllPopupState,
            false
          );
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    } else {
      try {
        setLoaderShow(true);
        let formData = {};
        if (isParent && isParent !== false) {
          formData = {
            ...fromData,
            contentMapId: collId,
            navMapId: +navMapIds,
          };
          // delete formData.subTitle;
        } else {
          formData = {
            ...formDataChild,
            parentId: id,
            navMapId,
            order: apiDataImageParent.data.length + 1,
            contentMapId: collId,
          };
          // delete formData.subTitle;
          delete formData.image;
          if (formDataChild.image?.length) {
            formData["image"] = formDataChild.image
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
              .pop();
          }
        }
        const { data } = await postTestimonial(formData);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            ByteMiniWeb_Dashboard();
          } else {
            getTestimonialParentList({ parentId: id });
          }
          emptyData();
          OpenModalCommonFunc(
            Cases.TESTIMONIAL_MULTIPAGE_PARENT,
            setAllPopupState,
            false
          );
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };
  const handleSubmit = async (formValidationCondition) => {
    let validatorFields;
    if (formValidationCondition === "parentForm") {
      validatorFields = parentValidator.current;
    } else if (formValidationCondition === "childForm") {
      validatorFields = childValidator.current;
    }
    validatorFields.showMessages();

    if (validatorFields.allValid()) {
      try {
        // Process form data based on the formType
        if (formValidationCondition === "parentForm") {
          submitForm();
        } else if (formValidationCondition === "childForm") {
          submitForm();
        }
        validatorFields.hideMessages();
      } catch (error) {
        // Handle error for form submission
      }
    } else {
      // Force update to display validation messages
      forceUpdate({});
    }
  };

  /**
   * create and update testimonial
   */
  const handleSubmitSetting = async (isRestore = false, isClose = true) => {
    if (id >= -1 && isEdit) {
      setLoaderShow(true);
      try {
        let newData;
        if (isRestore) {
          const isConfirm = window.confirm(
            "Are you sure want to restore style ?"
          );
          if (isConfirm) {
            newData = {
              styleRestore: true,
              testimonialId: id,
            };
          } else {
            setLoaderShow(false);
            return;
          }
        } else {
          if (+theme === ThemeNo.ThemeOne || +theme === ThemeNo.ThemeTwo) {
            newData = TestimonialOneSubFun({
              settingFormData,
              boxSection,
              theme,
            });
            if (isBackgroundColor) {
              newData[".testimonial-section"] = {
                ...newData?.[".testimonial-section"],
                "background-color": settingFormData?.[".testimonial-section"]?.[
                  "background-color"
                ]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[".testimonial-section"]?.[
                          "background-color"
                        ],
                    })
                  : "",
              };
            }
            if (boxSection) {
              newData[".testimonial-section.bg-image::before"] = {
                ...newData[".testimonial-section.bg-image::before"],
                "border-radius": `${settingFormData?.[".testimonial-section"]?.[
                  "border-radius"
                ]?.replace("rem", "")}rem`,
              };
            }
          } else if (+theme === ThemeNo.ThemeThree) {
            newData = TestimonialTwoSubFun({
              settingFormData,
            });
            if (isBackgroundColor) {
              newData[".zig-zag-section"] = {
                ...newData?.[".zig-zag-section"],
                "background-color": settingFormData?.[".zig-zag-section"]?.[
                  "background-color"
                ]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[".zig-zag-section"]?.[
                          "background-color"
                        ],
                    })
                  : "",
              };
            }
          }
          let menuBtnStyle;
          if (+theme === ThemeNo.ThemeOne || +theme === ThemeNo.ThemeTwo) {
            menuBtnStyle = generateDynamicStyles(
              settingFormData,
              ".testimonial-section"
            );
            newData = { ...newData, ...menuBtnStyle };
          } else if (+theme === ThemeNo.ThemeThree) {
            menuBtnStyle = generateDynamicStyles(
              settingFormData,
              ".zig-zag-section"
            );
            newData = { ...newData, ...menuBtnStyle };
          }
          delete newData.image;
          newData = {
            style: newData,
            testimonialId: id,
          };
        }
        const { data } = await putUpdateTestimonial(newData);
        if (data.status === 200) {
          getTestimonialParentList({ isClose: true });
          if (isClose) {
            OpenModalCommonFunc(
              Cases.SETTING_TESTIMONIAL_PARENT,
              setAllPopupState,
              false
            );
            emptyData();
            setBackImageToggle(false);
          }
          setLoaderShow(false);
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  /**
   * get data testimonial
   * @param {id} testimonialId
   */
  const handleEditTestimonial = async (testimonialId, isParent) => {
    try {
      setLoaderShow(true);
      const { data } = await getTestimonialDetails(testimonialId, navMapIds);
      if (data.status === 200) {
        let payload = data.payload.data;
        if (isParent) {
          setSectionPageName(payload?.title);
          setId(testimonialId);
          setIsParent(true);
          setFormData({
            title: payload?.title || "",
            isTitleShow: payload?.isTitleShow ? payload?.isTitleShow : false,
            subTitle: payload?.subTitle || "",
            slugUrl: payload?.slugUrl || "",
            buttonText: payload?.buttonText || "",
            itemLimit: payload?.itemLimit || "",
            image: payload?.image || "",
            theme: payload?.theme || "",
            navMapId: payload?.navMapId || "",
            webId: localStorage.getItem("webId"),
            style: payload?.style || "",
          });
          OpenModalCommonFunc(
            Cases.TESTIMONIAL_MULTIPAGE_PARENT,
            setAllPopupState
          );
        } else {
          setChildId(testimonialId);
          setIsParent(false);
          setFormDataChild({
            title: payload?.title || "",
            isTitleShow: payload?.isTitleShow ? payload?.isTitleShow : false,
            subTitle: payload?.subTitle || "",
            rating: payload?.rating || "",
            image: payload?.image || "",
            description: payload?.description || "",
            designation: payload?.designation || "",
            companyName: payload?.companyName || "",
            parentId: payload?.parentId || "",
            order: payload?.order || 1,
            webId: localStorage.getItem("webId"),
          });
          OpenModalCommonFunc(Cases.TESTIMONIAL_MULTIPAGE, setAllPopupState);
        }
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * get setting data
   * @param {id} testimonialId
   */
  const handleChangeSettings = async (testimonialId, isThemeEdit = false) => {
    try {
      setLoaderShow(true);
      setId(testimonialId);
      setSettingFormData((prev) => {
        return {
          ...prev,
          testimonialId,
        };
      });
      const { data } = await getTestimonialDetails(testimonialId);
      if (data.status === 200) {
        let payload = data.payload.data;
        setSectionPageName(payload.title);
        const themeCount = data?.payload?.data?.theme;
        setId(data?.payload?.data?.testimonialId);
        setTheme(themeCount);
        setImgShow(true);
        if (
          +themeCount === ThemeNo.ThemeThree &&
          isEmptyObject(data?.payload?.data?.style)
        ) {
          setSettingFormData({ ...TestimonialThemeThreeSet });
          setBoxSection(false);
          setBackImageToggle(false);
          setIsBackgroundColor(false);
        } else if (
          +themeCount === ThemeNo.ThemeTwo &&
          isEmptyObject(data?.payload?.data?.style)
        ) {
          setSettingFormData({ ...TestimonialThemeResetTwo });
          setBoxSection(false);
          setBackImageToggle(false);
          setIsBackgroundColor(false);
        } else if (
          +themeCount === ThemeNo.ThemeOne &&
          isEmptyObject(data?.payload?.data?.style)
        ) {
          setSettingFormData({ ...TestimonialThemeResetOne });
          setBoxSection(false);
          setBackImageToggle(false);
          setIsBackgroundColor(false);
        } else {
          if (
            payload.style?.[".testimonial-section"]?.["width"] ||
            payload.style?.[".testimonial-section"]?.["margin"] ||
            payload.style?.[".testimonial-section"]?.["border-radius"]
          ) {
            setBoxSection(true);
          } else if (
            payload.style?.[".zig-zag-section"]?.["width"] ||
            payload.style?.[".zig-zag-section"]?.["margin"] ||
            payload.style?.[".zig-zag-section"]?.["border-radius"]
          ) {
            setBoxSection(true);
          } else {
            setBoxSection(false);
          }
          if (
            payload.style?.[".testimonial-section.bg-image"]?.[
              "background-image"
            ]
          ) {
            setBackImageToggle(true);
          } else if (
            payload.style?.[".zig-zag-section.bg-image"]?.["background-image"]
          ) {
            setBackImageToggle(true);
          } else {
            setBackImageToggle(false);
          }
          if (payload.style?.[".testimonial-section"]?.["background-color"]) {
            setIsBackgroundColor(true);
          } else if (
            payload.style?.[".zig-zag-section"]?.["background-color"]
          ) {
            setIsBackgroundColor(true);
          } else {
            setIsBackgroundColor(false);
          }
          let getMenuBtnStyle;
          let getStyle;
          if (
            +themeCount === ThemeNo.ThemeOne ||
            +themeCount === ThemeNo.ThemeTwo
          ) {
            getStyle = TestimonialThemeOneFunction(payload);
            getMenuBtnStyle = generateDynamicStylesSet(
              payload,
              ".testimonial-section"
            );
          } else if (+themeCount === ThemeNo.ThemeThree) {
            getStyle = TestimonialThemeTwoFunction(payload);
            getMenuBtnStyle = generateDynamicStylesSet(
              payload,
              ".zig-zag-section"
            );
          }
          setSettingFormData((prev) => {
            return {
              ...getStyle,
              custom: payload.style.custom,
              ...getMenuBtnStyle,
            };
          });
        }
        if (!isThemeEdit) {
          OpenModalCommonFunc(
            Cases.SETTING_TESTIMONIAL_PARENT,
            setAllPopupState
          );
          TostMessage({ message: data, type: "success" });
        }
        setIsEdit(true);
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * delete testimonial
   * @param {id} testimonialId
   */
  const handleDelete = async (testimonialId, isParent = false) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await deleteTestimonial(testimonialId);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            // getTestimonialParentList(null, true);
            ByteMiniWeb_Dashboard();
          } else {
            getTestimonialParentList({ parentId: id });
          }
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * clear data
   */
  const emptyData = () => {
    // setCollId();
    setBoxSection(false);
    setIsBackgroundColor(false);
    setIsEdit(false);
    setIsParent(false);
    setChildId("");
    setFormData({
      title: "",
      subTitle: "",
      slugUrl: "",
      buttonText: "",
      itemLimit: "",
      navMapId: "",
      theme: "1",
      isTitleShow: true,
      webId: localStorage.getItem("webId"),
      style: {
        ".testimonial-section": {
          padding: "100px 0",
          "background-color": "rgb(255 255 255 / 100)",
        },
        ".testimonial-section.bg-image": {},
        ".testimonial-section.bg-image::before": {
          "background-color": "",
          // "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
        },
        ".testimonial-section .section-title-area": {
          "margin-bottom": "50px",
        },
        ".testimonial-section .section-title-area .section-subtitle": {
          "font-size": "16px",
          "font-weight": "600",
          color: "rgb(255 73 124 / 100)",
          "background-color": "rgb(255 73 124 / 0.2)",
          padding: "5px 20px",
          p1: "5px",
          p2: "20px",
          "border-radius": "15px",
          "margin-bottom": "15px",
        },
        ".testimonial-section .section-title-area .section-title": {
          "font-size": "44px",
          "font-weight": "600",
          color: "rgb(0 0 0 / 100)",
        },
        ".testimonial-slider .slick-arrow::before": {
          color: "rgb(255 73 124 / 100)",
        },
        ".testimonial-slider .testimonial-content": {
          "box-shadow": "0px 0px 20px 0px rgb(0 0 0 / 0.051)",
          bs1: "0px",
          bs2: "0px",
          bs3: "20px",
          bs4: "0px",
          shadowColor: "rgb(0 0 0 / 0.051)",
          "background-color": "rgb(255 255 255 / 100)",
          margin: "15px",
          padding: "80px 50px 40px 50px",
          p1: "80px",
          p2: "50px",
          p3: "40px",
          p4: "50px",
          "border-radius": "10px",
          "text-align": "center",
        },
        ".testimonial-slider .testimonial-content:before": {
          "font-family": "FontAwesome",
          color: "rgb(255 73 124 / 100)",
          "font-size": "40px",
          opacity: "0.6",
          top: "10px",
        },
        ".testimonial-slider .testimonial-content .avatar-img": {
          width: "110px",
          height: "110px",
          "border-radius": "100%",
          border: "5px solid rgb(255 73 124 / 100)",
          borderPx: "5px",
          borderThik: "solid",
          borderColor: "rgb(255 73 124 / 100)",
        },
        ".testimonial-slider .testimonial-content .comments": {
          color: "rgb(129 136 153 / 100)",
          "font-size": "16px",
          "font-weight": "400",
          "line-height": "1.6",
          margin: "20px 0",
        },
        ".testimonial-slider .testimonial-content .client-name": {
          color: "rgb(31 39 50 / 100)",
          "font-size": "22px",
          "font-weight": "600",
          "margin-bottom": "10px",
        },
        ".testimonial-slider .testimonial-content .client-position": {
          color: "rgb(129 136 153 / 100)",
          "font-size": "16px",
          "font-weight": "400",
          "margin-bottom": "12px",
        },
        ".testimonial-slider .testimonial-content .star-rating": {
          "text-align": "center",
        },
        ".testimonial-slider .testimonial-content .star-rating i": {
          color: "rgb(255 73 124 / 100)",
          "font-size": "20px",
        },
        ".testimonial-section .more-btn": {
          color: "rgb(255 255 255 / 100)",
          "font-size": "16px",
          "font-weight": "normal",
          "border-radius": "15px",
          padding: "5px 20px",
          p1: "5px",
          p2: "20px",
          "background-color": "rgb(255 73 124 / 100)",
        },
        ".testimonial-section .more-btn:hover": {
          "background-color": "rgb(255 73 124 / 100)",
          color: "rgb(255 255 255 / 100)",
        },
        ".pagination-positions": {
          "justify-content": "center",
        },
        ".pagination .page-link": {
          color: "rgb(255 73 124 / 100)",
        },
        ".pagination .page-item.active .page-link": {
          "background-color": "rgb(255 73 124 / 100)",
          "border-color": "rgb(255 73 124 / 100)",
        },
        ".testimonial-slider .slick-dots li button:before": {
          "font-size": "12px",
          color: "rgb(255 73 124 / 100)",
          opacity: "0.25",
        },
        ".testimonial-slider .slick-dots li.slick-active button:before": {
          color: "rgb(255 73 124 / 100)",
        },
        testimonialId: "",
      },
    });
    setSkill([{ id: randomStr(2), skill: "", parentageOfSkill: "" }]);
    setGalleryImgId("");
    setFormDataChild({
      title: "",
      subTitle: "",
      rating: "",
      description: "",
      designation: "",
      companyName: "",
      isTitleShow: true,
      image: "",
      order: 1,
      webId: localStorage.getItem("webId"),
    });
    setLoaderShow(false);
    setSettingFormData({
      ".testimonial-section": {
        padding: "100px 0",
        "background-color": "rgb(255 255 255 / 100)",
      },
      ".testimonial-section.bg-image": {},
      ".testimonial-section.bg-image::before": {
        "background-color": "",
        // "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
      },
      ".testimonial-section .section-title-area": {
        "margin-bottom": "50px",
      },
      ".testimonial-section .section-title-area .section-subtitle": {
        "font-size": "16px",
        "font-weight": "600",
        color: "rgb(255 73 124 / 100)",
        "background-color": "rgb(255 73 124 / 0.2)",
        padding: "5px 20px",
        p1: "5px",
        p2: "20px",
        "border-radius": "15px",
        "margin-bottom": "15px",
      },
      ".testimonial-section .section-title-area .section-title": {
        "font-size": "44px",
        "font-weight": "600",
        color: "rgb(0 0 0 / 100)",
      },
      ".testimonial-slider .slick-arrow::before": {
        color: "rgb(255 73 124 / 100)",
      },
      ".testimonial-slider .testimonial-content": {
        "box-shadow": "0px 0px 20px 0px rgb(0 0 0 / 0.051)",
        bs1: "0px",
        bs2: "0px",
        bs3: "20px",
        bs4: "0px",
        shadowColor: "rgb(0 0 0 / 0.051)",
        "background-color": "rgb(255 255 255 / 100)",
        margin: "15px",
        padding: "80px 50px 40px 50px",
        p1: "80px",
        p2: "50px",
        p3: "40px",
        p4: "50px",
        "border-radius": "10px",
        "text-align": "center",
      },
      ".testimonial-slider .testimonial-content:before": {
        "font-family": "FontAwesome",
        color: "rgb(255 73 124 / 100)",
        "font-size": "40px",
        opacity: "0.6",
        top: "10px",
      },
      ".testimonial-slider .testimonial-content .avatar-img": {
        width: "110px",
        height: "110px",
        "border-radius": "100%",
        border: "5px solid rgb(255 73 124 / 100)",
        borderPx: "5px",
        borderThik: "solid",
        borderColor: "rgb(255 73 124 / 100)",
      },
      ".testimonial-slider .testimonial-content .comments": {
        color: "rgb(129 136 153 / 100)",
        "font-size": "16px",
        "font-weight": "400",
        "line-height": "1.6",
        margin: "20px 0",
      },
      ".testimonial-slider .testimonial-content .client-name": {
        color: "rgb(31 39 50 / 100)",
        "font-size": "22px",
        "font-weight": "600",
        "margin-bottom": "10px",
      },
      ".testimonial-slider .testimonial-content .client-position": {
        color: "rgb(129 136 153 / 100)",
        "font-size": "16px",
        "font-weight": "400",
        "margin-bottom": "12px",
      },
      ".testimonial-slider .testimonial-content .star-rating": {
        "text-align": "center",
      },
      ".testimonial-slider .testimonial-content .star-rating i": {
        color: "rgb(255 73 124 / 100)",
        "font-size": "20px",
      },
      ".testimonial-section .more-btn": {
        color: "rgb(255 255 255 / 100)",
        "font-size": "16px",
        "font-weight": "normal",
        "border-radius": "15px",
        padding: "5px 20px",
        p1: "5px",
        p2: "20px",
        "background-color": "rgb(255 73 124 / 100)",
      },
      ".testimonial-section .more-btn:hover": {
        "background-color": "rgb(255 73 124 / 100)",
        color: "rgb(255 255 255 / 100)",
      },
      ".pagination-positions": {
        "justify-content": "center",
      },
      ".pagination .page-link": {
        color: "rgb(255 73 124 / 100)",
      },
      ".pagination .page-item.active .page-link": {
        "background-color": "rgb(255 73 124 / 100)",
        "border-color": "rgb(255 73 124 / 100)",
      },
      ".testimonial-slider .slick-dots li button:before": {
        "font-size": "12px",
        color: "rgb(255 73 124 / 100)",
        opacity: "0.25",
      },
      ".testimonial-slider .slick-dots li.slick-active button:before": {
        color: "rgb(255 73 124 / 100)",
      },
      testimonialId: "",
    });
  };

  const handleAlignAndCloneSection = ({ isClone = false }) => {
    if (isClone) {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          cloneModal: true,
        };
      });
      setCloneFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          pageConfigDetailsLabel: pageConfigDetails[0]?.label,
          contentDetailName: contentDetail.name,
        };
      });
    } else {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          alignModal: true,
        };
      });
      setAlignFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          label: pageConfigDetails[0]?.label,
        };
      });
    }
  };
  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody
            className="support-ticket-font box-border-div"
            style={{ padding: "20px" }}
          >
            <Row>
              <CardBuilderHeaderSection
                addBtnTitle={pageConfigDetails[0]?.label}
                name={pageConfigDetails[0]?.label}
                isAdd={!testimonialDetail && true}
                mainEditSection={true}
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
                openModal={() => {
                  OpenModalCommonFunc(
                    Cases.TESTIMONIAL_MULTIPAGE_PARENT,
                    setAllPopupState
                  );
                  setSectionPageName(modalsName.TESTIMONIAL_MULTIPAGE_PARENT);
                  emptyData();
                  setIsParent(true);
                  setCollId(contentMapId);
                }}
                isCloneSection={true}
                isAlignSection={true}
                handleAlignAndCloneSection={handleAlignAndCloneSection}
              />
              {testimonialDetail && (
                <Col xl="12 xl-50 box-col-6" md="6">
                  <Card className="ecommerce-widget mb-0">
                    <CardBody
                      className="support-ticket-font"
                      style={{ padding: "20px" }}
                    >
                      <div className="col-12">
                        <CardBuilderHeaderSection
                          name={testimonialDetail?.title}
                          checked={testimonialDetail?.isActive}
                          switchToggle={true}
                          id={testimonialDetail?.testimonialId}
                          handleChange={handleChangeTestimonial}
                        />
                        <OpenPopupBtn
                          openPopup={() => {
                            getTestimonialParentList({
                              parentId: testimonialDetail.testimonialId,
                              contentMapId,
                            });
                            setSectionPageName(testimonialDetail?.title);
                            setNavMapId(testimonialDetail?.navMapId);
                            setFilter("");
                          }}
                          name={`Add or View ${testimonialDetail?.title}`}
                        />
                      </div>
                      <div className="d-flex justify-content-end ">
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleEditTestimonial(
                              testimonialDetail.testimonialId,
                              true
                            );
                            setCollId(contentMapId);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() =>
                            handleDelete(testimonialDetail.testimonialId, true)
                          }
                        >
                          <Trash />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleChangeSettings(
                              testimonialDetail.testimonialId
                            );
                          }}
                        >
                          <Settings />
                        </IconButton>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
              <DeleteSection
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
              />
            </Row>
          </CardBody>
        </Card>
      </Col>
      <CommonPopup
        open={allPopupState.testimonialMultipageChildPopup}
        close={() => {
          OpenModalCommonFunc(
            Cases.TESTIMONIAL_MULTIPAGE,
            setAllPopupState,
            false
          );
          setIsParent(false);
        }}
        // apiCall={getTestimonialParentList}
        isObjectParams={true}
        modalName={
          sectionPageName ? sectionPageName : modalsName.TESTIMONIAL_MULTIPAGE
        }
        modalCase={Cases.TESTIMONIAL_MULTIPAGE}
        handleSubmit={handleSubmit}
        validationForm={"childForm"}
        emptyData={emptyData}
        inputData={fromData}
        edit={false}
        oldTitle={titleUpdateKey.isGallery}
        label={titleUpdateKey.Certificates}
        // getData={getData}
        content={
          <TestimonialMultipagePopup
            handleChangeStatus={handleChangeTestimonial}
            handleEdit={handleEditTestimonial}
            imageUpload={imageUploadImages}
            emptyData={emptyData}
            simpleValidator={childValidator}
            webMasterDetail={webMasterDetail}
            handleDelete={handleDelete}
            getTestimonialParentList={getTestimonialParentList}
          />
        }
      />

      {/* testimonial popup */}
      <CommonPopup
        open={allPopupState.testimonialMultipagePopup}
        close={() => {
          OpenModalCommonFunc(
            Cases.TESTIMONIAL_MULTIPAGE_PARENT,
            setAllPopupState,
            false
          );
          setIsParent(false);
        }}
        modalName={
          sectionPageName
            ? sectionPageName
            : modalsName.TESTIMONIAL_MULTIPAGE_PARENT
        }
        modalCase={Cases.TESTIMONIAL_MULTIPAGE_PARENT}
        handleSubmit={handleSubmit}
        validationForm={"parentForm"}
        emptyData={emptyData}
        inputData={formDataChild}
        edit={false}
        content={
          <TestimonialMultipageParentPopup simpleValidator={parentValidator} />
        }
      />

      {/* setting Service Counter parent popup */}
      <CommonPopup
        open={allPopupState.testimonialSettingPopup}
        close={() =>
          OpenModalCommonFunc(
            Cases.SETTING_TESTIMONIAL_PARENT,
            setAllPopupState,
            false
          )
        }
        modalName={
          sectionPageName
            ? sectionPageName
            : modalsName.SETTING_TESTIMONIAL_PARENT
        }
        modalCase={Cases.SETTING_TESTIMONIAL_PARENT}
        handleSubmit={handleSubmitSetting}
        emptyData={emptyData}
        inputData={formDataChild}
        edit={false}
        content={
          <SettingTestimonialPerentPopup
            imageUpload={imageUploadSetting}
            handleSubmitSetting={handleSubmitSetting}
            navMapIds={navMapIds}
            contentMapId={contentMapId}
            handleChangeSettings={handleChangeSettings}
          />
        }
      />
    </>
  );
};

export default TestimonialMultipage;
