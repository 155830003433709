import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

const SingleSolution = ({
  name1,
  link1,
  target1,
  name2,
  link2,
  target2 = {},
  xl = 3,
  isShow = true,
  isShow1 = true,
}) => {
  return (
    <Col xl={`${xl} xl-50 box-col-6`} md="3">
      <Card className="ecommerce-widget">
        <CardBody className="support-ticket-font">
          <Row>
            {isShow && (
              <div className="col-12">
                <Link to={link1} {...target1} className="wrapped-link">
                  {name1 ? name1 : link1}
                </Link>
              </div>
            )}
            {isShow1 && (
              <div className="col-12">
                <Link to={link2} {...target2} className="wrapped-link">
                  {name2 ? name2 : link2}
                </Link>
              </div>
            )}
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default SingleSolution;
