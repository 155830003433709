import React, { useContext, useEffect, useState } from "react";
import { toast, Zoom } from "react-toastify";
import { setPasswordApi } from "../../api/SellerPasswordChangeApi/PasswordChangeApi";
import SetPasswordItem from "./SetPasswordItem";
import LoaderContext from "../../context/LoaderContext/LoaderContext";

const SetPassword = (props) => {
  const {
    modal,
    setModal,
    timeLeft,
    setTimeLeft,
    sendOtp,
    isActive,
    setIsActive,
  } = props;
  const { setLoaderShow } = useContext(LoaderContext);
  const [togglePassword, setTogglePassword] = useState(false);
  const [togglePassword1, setTogglePassword1] = useState(false);
  const [togglePassword2, setTogglePassword2] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");

  useEffect(() => {
    let intervalId;
    if (isActive && timeLeft > 0) {
      intervalId = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft <= 0) {
      setIsActive(false);
    }
    return () => clearInterval(intervalId);
  }, [isActive, timeLeft]);

  const reSendOtp = async () => {
    await sendOtp();
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoaderShow(true);
    let formData = {
      otp: otp,
      password: password,
      confirmPassword: confirmPassword,
    };

    try {
      const { data } = await setPasswordApi(formData);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        setModal(false);
        setPassword("");
        setConfirmPassword("");
        setOtp("");
        setLoaderShow(false);
      }
    } catch (error) {
      setLoaderShow(false);
      toast.error(error?.response?.data?.message || error?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  const closeModal = () => {
    setModal(false);
    setPassword("");
    setConfirmPassword("");
    setOtp("");
  };

  return (
    <>
      <SetPasswordItem
        modal={modal}
        setModal={setModal}
        onSubmit={onSubmit}
        togglePassword={togglePassword}
        setTogglePassword={setTogglePassword}
        setTogglePassword2={setTogglePassword2}
        setPassword={setPassword}
        togglePassword2={togglePassword2}
        setConfirmPassword={setConfirmPassword}
        password={password}
        confirmPassword={confirmPassword}
        togglePassword1={togglePassword1}
        setTogglePassword1={setTogglePassword1}
        setOtp={setOtp}
        otp={otp}
        formatTime={formatTime}
        isActive={isActive}
        timeLeft={timeLeft}
        reSendOtp={reSendOtp}
        closeModal={closeModal}
      />
    </>
  );
};

export default SetPassword;
