import React, { Fragment, useContext, useEffect, useMemo } from "react";
import OrganizationContext from "../../context/OrganizationContext/OrganizationContext";
import Breadcrumbs from "../../layout/breadcrumb";
import { tableId } from "../../utils/helper";
import TopLoadingBar from "../../utils/topLoadingBar";
import OrganizationAddPopup from "./OrganizationAddPopup";
import Organizationitem from "./Organizationitem";

const Organization = () => {
  const {
    sortOrder,
    pageSize,
    page,
    sortBy,
    progress,
    setProgress,
    filter,
    OrganizationList,
    formvalue,
  } = useContext(OrganizationContext);

  /**useEffect to use of Organization() function call and toploading call to use  */

  useEffect(() => {
    OrganizationList();
    setProgress(100);
  }, [page, pageSize, sortOrder, sortBy, filter]);


  useMemo(() => {
    tableId(page, pageSize, formvalue);
  }, [page, pageSize, formvalue]);

  return (
    <Fragment>
      <Breadcrumbs parent="Organization" />
      <TopLoadingBar progress={progress} setProgress={setProgress} />
      <Organizationitem />
      <OrganizationAddPopup />
    </Fragment>
  );
};

export default Organization;
