import React, { useContext } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import PrivillageMasterContext from "../../context/PrivillageMasterContext/PrivillageMasterContext";

const OrganizationDetailsModel = (props) => {
  const { ahoeDataModel, setahoeDataModel, name, urls, method, showModule } =
    useContext(PrivillageMasterContext);
  return (
    <>
      <Modal
        size="lg"
        isOpen={ahoeDataModel}
        toggle={() => setahoeDataModel(!ahoeDataModel)}
      >
        <ModalHeader toggle={() => setahoeDataModel(!ahoeDataModel)}>
          View Privillage
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody style={{ padding: "0" }}>
                  <Card>
                    <div className="table-responsive">
                      <Table>
                        <tbody>
                          <tr>
                            <th scope="row">{"Name"}</th>
                            <td>{name}</td>
                          </tr>
                          <tr>
                            <th scope="row">{"Urls"}</th>
                            <td>{urls}</td>
                          </tr>
                          <tr>
                            <th scope="row">{"Method"}</th>
                            <td>{method}</td>
                          </tr>
                        </tbody>
                      </Table>
                      <CardHeader>
                        <h4>Modual Access</h4>
                      </CardHeader>
                      <Table>
                        <tbody>
                          {showModule.map((elem, index) => {
                            return (
                              <tr key={index}>
                                <th scope="row">{"Module name"}</th>
                                <td>{elem.parent.name}</td>
                                <th scope="row">{"Module AccessName"}</th>
                                <td>
                                  {elem.moduleAccess.map((param) => {
                                    return param.accessName;
                                  })}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </Card>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default OrganizationDetailsModel;
