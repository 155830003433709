import * as React from "react";
import { useContext } from "react";
import { useState } from "react";
import { PageItemList } from "../../api/Organization Api/OrganizationApi";
import LoaderContext from "../LoaderContext/LoaderContext";
import PageItmContext from "./PageItmContext";

const PageItemState = (props) => {
  const [formvalue, setformValue] = useState([]);
  const [pageUuid, setPageUuid] = useState("");
  const [page, setPage] = useState("");
  const [modal, setModal] = useState(false);
  const [title, setTitle] = useState(false);
  const [pageItemViewPop, setPageItemViewPop] = useState(false);
  const [pageItemData, setPageItemData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [pageId, setPageId] = useState("");
  const [chartTitle, setChartTitle] = useState("");

  const { tabelAnimation, setTableAnimation } = useContext(LoaderContext);

  const nfcGet = async (enableLoader) => {
    if (enableLoader === false) {
    } else {
      setTableAnimation(!tabelAnimation);
    }
    try {
      const { data } = await PageItemList(pageUuid);
      if (data.status === 200) {
        setformValue(data.payload.data);
        setTableAnimation(false);
      } else {
      }
    } catch (error) {
      setTableAnimation(false);
    }
  };
  return (
    <>
      <PageItmContext.Provider
        value={{
          formvalue,
          setformValue,
          pageUuid,
          setPageUuid,
          nfcGet,
          modal,
          setModal,
          title,
          setTitle,
          page,
          setPage,
          pageItemViewPop,
          setPageItemViewPop,
          pageItemData,
          setPageItemData,
          pageId,
          setPageId,
          categories,
          setCategories,
          chartData,
          setChartData,
          chartTitle,
          setChartTitle,
        }}
      >
        {props.children}
      </PageItmContext.Provider>
    </>
  );
};

export default PageItemState;
