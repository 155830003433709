import React, { useContext } from "react";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import { Border, TextAlign, fontWeight } from "../../../../constant";
import DownloadMultipageContext from "../../../context/DownloadMultipageContext/DownloadMultipageContext";
import PaginationButtonStyle from "../../../common/paginationButtonStyle";

import { Switch } from "@material-ui/core";
import { SketchPicker } from "react-color";
import CommonColor from "../../../common/CommonColor";
import CustomStyle from "../../../common/CustomStyle";

const DownloadThemeOne = (props) => {
  const {
    isEdit,
    settingFormData,
    setSettingFormData,
    boxSection,
    setBoxSection,
    isBackgroundColor,
    setIsBackgroundColor,
  } = useContext(DownloadMultipageContext);
  const { simpleValidator, handleSubmitSetting } = props;

  /**
   * input value change
   * @param {event} e
   */

  const handleChangeBrochureSection = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".brochure-section"]: {
          ...prev[".brochure-section"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeBrochureSectionTitleArea = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".brochure-section .section-title-area"]: {
          ...prev[".brochure-section .section-title-area"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeBrochureSectionSubtitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".brochure-section .section-title-area .section-subtitle"]: {
          ...prev[".brochure-section .section-title-area .section-subtitle"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeBrochureSectionSubtitleArea = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".brochure-section .section-title-area .section-title"]: {
          ...prev[".brochure-section .section-title-area .section-title"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeBrochureSectionListText = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".brochure-section .brochure-list .link-text"]: {
          ...prev[".brochure-section .brochure-list .link-text"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeCustomStyle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  return (
    <>
      <Row className="mt-4">
        <h4 className="mb-3">Make change in main Section</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 100)"}
          </Label>
          <Input
            className="form-control"
            name="padding"
            type="number"
            placeholder="padding"
            value={settingFormData?.[".brochure-section"]?.["padding"]
              ?.split(" ")[0]
              ?.replace(/px/g, "")}
            onChange={(e) => handleChangeBrochureSection(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <div className="d-flex align-items-center">
            <Label htmlFor="validationCustom03">
              {isBackgroundColor
                ? "Add Background Color"
                : "Remove Background Color"}
            </Label>
            <Switch
              checked={isBackgroundColor}
              inputProps={{ "aria-label": "controlled" }}
              onChange={(e) => {
                setIsBackgroundColor(e.target.checked);
                setSettingFormData((prev) => {
                  if (e.target.checked) {
                    if (boxSection) {
                      return {
                        ...prev,
                        [".brochure-section"]: {
                          "background-color": "#f9f9f9",
                          padding:
                            settingFormData?.[".brochure-section"]?.["padding"],
                          width: settingFormData?.[".brochure-section"]?.[
                            "width"
                          ]?.replace("rem)", ""),
                          margin: settingFormData?.[".brochure-section"]?.[
                            "margin"
                          ]
                            ?.split(" ")[0]
                            ?.replace(/px/g, ""),
                          "border-radius": settingFormData?.[
                            ".brochure-section"
                          ]?.["border-radius"]?.replace(/rem/g, ""),
                        },
                      };
                    } else {
                      return {
                        ...prev,
                        [".brochure-section"]: {
                          "background-color": "#fbfcfd",
                          padding:
                            settingFormData?.[".brochure-section"]?.["padding"],
                        },
                      };
                    }
                  } else {
                    if (boxSection) {
                      return {
                        ...prev,
                        [".brochure-section"]: {
                          padding:
                            settingFormData?.[".brochure-section"]?.["padding"],
                          width: settingFormData?.[".brochure-section"]?.[
                            "width"
                          ]?.replace("rem)", ""),
                          margin: settingFormData?.[".brochure-section"]?.[
                            "margin"
                          ]
                            ?.split(" ")[0]
                            ?.replace(/px/g, ""),
                          "border-radius": settingFormData?.[
                            ".brochure-section"
                          ]?.["border-radius"]?.replace(/rem/g, ""),
                        },
                      };
                    } else {
                      return {
                        ...prev,
                        [".brochure-section"]: {
                          padding:
                            settingFormData?.[".brochure-section"]?.["padding"],
                        },
                      };
                    }
                  }
                });
              }}
            />
          </div>
          {isBackgroundColor && (
            <CommonColor
              color={
                settingFormData?.[".brochure-section"]?.["background-color"]
              }
              setSettingFormData={setSettingFormData}
              className={".brochure-section"}
              classProperty={"background-color"}
              label={"Select Background Color"}
            />
          )}
        </Col>
        <p>{"Box Section : convert your section to Box section"}</p>
        <div>
          <Switch
            checked={boxSection}
            inputProps={{ "aria-label": "controlled" }}
            onChange={(e) => {
              setBoxSection(e.target.checked);
              setSettingFormData((prev) => {
                return {
                  ...prev,
                  ".brochure-section": {
                    padding: settingFormData?.[".brochure-section"]?.["padding"]
                      ?.split(" ")[0]
                      ?.replace(/px/g, ""),
                    "background-color":
                      settingFormData?.[".brochure-section"]?.[
                        "background-color"
                      ],
                    width: e.target.checked ? "10" : "",
                    margin: e.target.checked ? "0" : "",
                    "border-radius": e.target.checked ? "3" : "",
                  },
                };
              });
            }}
          />
        </div>
        {boxSection && (
          <>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Width (Default : 10)"}
              </Label>
              <Input
                className="form-control"
                name="width"
                type="number"
                placeholder="width"
                value={settingFormData?.[".brochure-section"]?.[
                  "width"
                ]?.replace("rem)", "")}
                onChange={(e) => handleChangeBrochureSection(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Margin (Default : 0)"}
              </Label>
              <Input
                className="form-control"
                name="margin"
                type="number"
                placeholder="margin"
                value={settingFormData?.[".brochure-section"]?.["margin"]
                  ?.split(" ")[0]
                  ?.replace(/px/g, "")}
                onChange={(e) => handleChangeBrochureSection(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Border Radius (Default : 3)"}
              </Label>
              <Input
                className="form-control"
                name="border-radius"
                type="number"
                placeholder="Border Radius"
                value={settingFormData?.[".brochure-section"]?.[
                  "border-radius"
                ]?.replace(/rem/g, "")}
                onChange={(e) => handleChangeBrochureSection(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
          </>
        )}
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make space in Title bottom</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin bottom (Default : 50)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin bottom"
            value={settingFormData?.[".brochure-section .section-title-area"]?.[
              "margin-bottom"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeBrochureSectionTitleArea(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in section subtitle</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 16)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".brochure-section .section-title-area .section-subtitle"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeBrochureSectionSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[
                ".brochure-section .section-title-area .section-subtitle"
              ]?.["font-weight"]
            }
            onChange={(e) => handleChangeBrochureSectionSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".brochure-section .section-title-area .section-subtitle"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".brochure-section .section-title-area .section-subtitle"
            }
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".brochure-section .section-title-area .section-subtitle"
              ]?.["background-color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".brochure-section .section-title-area .section-subtitle"
            }
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 5 - 20)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="p1"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".brochure-section .section-title-area .section-subtitle"
              ]?.["p1"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeBrochureSectionSubtitle(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p2"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".brochure-section .section-title-area .section-subtitle"
              ]?.["p2"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeBrochureSectionSubtitle(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border Radius (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[
              ".brochure-section .section-title-area .section-subtitle"
            ]?.["border-radius"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeBrochureSectionSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin bottom (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin bottom"
            value={settingFormData?.[
              ".brochure-section .section-title-area .section-subtitle"
            ]?.["margin-bottom"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeBrochureSectionSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in section Title</h4>
        <Col md="2 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 44)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".brochure-section .section-title-area .section-title"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeBrochureSectionSubtitleArea(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[
                ".brochure-section .section-title-area .section-title"
              ]?.["font-weight"]
            }
            onChange={(e) => handleChangeBrochureSectionSubtitleArea(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".brochure-section .section-title-area .section-title"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".brochure-section .section-title-area .section-title"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in brochure-list | link-text</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".brochure-section .brochure-list .link-text"
              ]?.["background-color"]
            }
            setSettingFormData={setSettingFormData}
            className={".brochure-section .brochure-list .link-text"}
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
        <Col md="2 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 18)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".brochure-section .brochure-list .link-text"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeBrochureSectionListText(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[
                ".brochure-section .brochure-list .link-text"
              ]?.["font-weight"]
            }
            onChange={(e) => handleChangeBrochureSectionListText(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".brochure-section .brochure-list .link-text"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".brochure-section .brochure-list .link-text"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Text Align (Default : center)"}
          </Label>
          <select
            name="text-align"
            id="text-align"
            className="form-select"
            value={
              settingFormData?.[
                ".brochure-section .brochure-list .link-text"
              ]?.["text-align"]
            }
            onChange={(e) => handleChangeBrochureSectionListText(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {TextAlign.map((elem) => {
              return (
                <option value={elem["text-align"]}>{elem["text-align"]}</option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Padding (Default : 15)"}</Label>
          <Input
            className="form-control"
            name="padding"
            type="number"
            placeholder="padding"
            value={settingFormData?.[
              ".brochure-section .brochure-list .link-text"
            ]?.["padding"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeBrochureSectionListText(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin bottom (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin bottom"
            value={settingFormData?.[
              ".brochure-section .brochure-list .link-text"
            ]?.["margin-bottom"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeBrochureSectionListText(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <PaginationButtonStyle
        mainClass={".brochure-section"}
        settingFormData={settingFormData}
        setSettingFormData={setSettingFormData}
      />
      <CustomStyle
        handleChange={handleChangeCustomStyle}
        value={settingFormData?.custom}
      />
    </>
  );
};

export default DownloadThemeOne;
