import React, { useState } from "react";
import ProductsContext from "./ProductsContext";

const ProductsState = (props) => {
  const [formValue, setFormValue] = useState([]);

  const [formData, setFormData] = useState({
    name: "",
    price: "",
    type: "",
    tags: "",
    shortDescription: "",
    longDescription: "",
    imageRectangle: "",
    imageSquare: "",
    qpiId: "",
    productOrder: "",
  });

  const [sortOrder, setSortOrder] = useState(""); //SORTING -->
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState("createdAt"); //SORTING -->
  const [pageLimit, setPageLimit] = useState("");
  const [record, setRecord] = useState(0);
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [addModel, setAddModel] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [id, setId] = useState("");
  return (
    <>
      <ProductsContext.Provider
        value={{
          formValue,
          setFormValue,
          pageSize,
          setPageSize,
          sortOrder,
          setSortOrder,
          page,
          setPage,
          sortBy,
          setSortBy,
          pageLimit,
          setPageLimit,
          record,
          setRecord,
          isSwitchOn,
          setIsSwitchOn,
          addModel,
          setAddModel,
          formData,
          setFormData,
          isEdit,
          setIsEdit,
          id,
          setId,
        }}
      >
        {props.children}
      </ProductsContext.Provider>
    </>
  );
};

export default ProductsState;
