import axios from "axios";
import {
  GET_PRODUCT,
  GET_PRODUCT_DETAILS,
  POST_PRODUCT,
  PUT_PRODUCT_ORDER,
  PUT_STATUS_PRODUCT,
} from "../../apiConstants";

/**
 * get product data
 * @param {Parameters} paramObj
 * @returns
 */
export const getProduct = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_PRODUCT}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * enable-disable
 * @param {body} body
 * @returns
 */
export const updateStatus = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_STATUS_PRODUCT}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * create product
 * @param {body} body
 * @returns
 */
export const postProduct = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(`${process.env.REACT_APP_API_URL}${POST_PRODUCT}`, body, {
    headers: { Authorization: jwt_token },
  });
};

/**
 * update product
 * @param {body} body
 * @returns
 */
export const putUpdateProduct = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(`${process.env.REACT_APP_API_URL}${POST_PRODUCT}`, body, {
    headers: { Authorization: jwt_token },
    params: { productId: body.productId },
  });
};

/**
 * delete product
 * @param {id} productId
 * @returns
 */
export const deleteProduct = (productId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${POST_PRODUCT}`, {
    headers: { Authorization: jwt_token },
    params: { productId },
  });
};

/**
 * get product details
 * @param {id} productId
 * @returns
 */
export const getProductDetails = (productId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_PRODUCT_DETAILS}`, {
    headers: { Authorization: jwt_token },
    params: { productId },
  });
};

/**
 * sort order products
 * @param {body} updateOrder
 * @returns
 */
export const sort_order_product = (updateOrder) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_PRODUCT_ORDER}`,
    { updateOrder: updateOrder },
    {
      headers: { Authorization: jwt_token },
    }
  );
};
