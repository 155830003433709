import React, { useContext } from "react";
import { Col, Container, Input, Label, Row } from "reactstrap";
import GalleryMultipageContext from "../../../context/GalleryMultipageContext/GalleryMultipageContext";
import { ImagesMultiPageTheme } from "../../../constant";
import { themeForImages } from "../../../../constant";
import { slugUrlConverter } from "../../../../utils/helper";
import IsShowTitleORNot from "../../../common/isShowTitleORNot";

const ImageMultipageParentPopup = (props) => {
  const {
    setFormDataImageParent,
    formDataImageParent,
    isEdit,
    parentId,
    isImageParent,
  } = useContext(GalleryMultipageContext);
  const { simpleValidator, sectionTitle } = props;

  /**
   * input value change
   * @param {event} e
   */
  const changeFormValue = (e) => {
    const { value, name, checked } = e.target;
    setFormDataImageParent((prev) => {
      if (name === "isTitleShow") {
        return {
          ...prev,
          [name]: checked,
        };
      } else if (name === "isAlbum") {
        return {
          ...prev,
          [name]: checked,
          parentId,
          navMapId: "",
        };
      } else if (name === "theme") {
        return {
          ...prev,
          [name]: value,
          style: themeForImages[value],
        };
      } else if (name === "title") {
        return {
          ...prev,
          [name]: value,
          slugUrl: slugUrlConverter({ value }),
        };
      } else if (name === "slugUrl") {
        return {
          ...prev,
          [name]: slugUrlConverter({ value }),
        };
      } else {
        return {
          ...prev,
          [name]: value,
        };
      }
    });
  };

  return (
    <>
      <Container className="mb-5">
        <h2>{sectionTitle}</h2>
        <Row>
          <Col sm="12" xl="12">
            <div className="mt-4 d-flex flex-column justify-content-center ">
              <h6>{isEdit ? "Edit" : "Add New"}</h6>
              <Row className="mt-4">
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">
                    Title *{" "}
                    <IsShowTitleORNot
                      checked={formDataImageParent?.isTitleShow}
                      onChange={changeFormValue}
                    />{" "}
                  </Label>
                  <Input
                    className="form-control"
                    name="title"
                    type="text"
                    placeholder="Title *"
                    value={formDataImageParent?.title}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "Title",
                      formDataImageParent?.title,
                      "required"
                    )}
                  </p>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">{"Slug Url *"}</Label>
                  <Input
                    className="form-control"
                    name="slugUrl"
                    type="text"
                    placeholder="Slug Url *"
                    value={formDataImageParent.slugUrl}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "headline",
                      formDataImageParent.slugUrl,
                      "required"
                    )}
                  </p>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">{"Sub Title"}</Label>
                  <Input
                    className="form-control"
                    name="subTitle"
                    type="text"
                    placeholder="Sub Title"
                    value={formDataImageParent?.subTitle}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                  {/* <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "Sub Title",
                      formDataImageParent?.subTitle,
                      "required"
                    )}
                  </p> */}
                </Col>
                {!isImageParent && (
                  <>
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom03">{"Theme"}</Label>
                      <select
                        name="theme"
                        id="theme"
                        className="form-select"
                        style={{ borderRadius: "0", color: "black" }}
                        value={formDataImageParent.theme}
                        onChange={(e) => changeFormValue(e)}
                      >
                        {ImagesMultiPageTheme.map((ele) => {
                          return (
                            <option value={ele.theme} key={ele.theme}>
                              {ele.themeName}
                            </option>
                          );
                        })}
                      </select>
                    </Col>
                    <Col md="12 mb-3">
                      <Label htmlFor="validationCustom03">
                        {"Button text"}
                      </Label>
                      <Input
                        className="form-control"
                        name="buttonText"
                        type="text"
                        placeholder="Button text"
                        value={formDataImageParent?.buttonText}
                        onChange={(e) => changeFormValue(e)}
                        style={{ borderRadius: "0", color: "black" }}
                        required
                      />
                    </Col>
                    <Col md="12 mb-3">
                      <Label htmlFor="validationCustom03">{"Item Limit"}</Label>
                      <Input
                        className="form-control"
                        name="itemLimit"
                        type="number"
                        placeholder="Item Limit"
                        value={formDataImageParent?.itemLimit}
                        onChange={(e) => changeFormValue(e)}
                        style={{ borderRadius: "0", color: "black" }}
                        required
                      />
                    </Col>
                  </>
                )}
                {/* <Col md="12 mb-3">
                  <Label className="d-block" for="edo-ani1">
                    <Switch
                      checked={formDataImageParent.isAlbum}
                      inputProps={{
                        "aria-label": "controlled",
                      }}
                      onChange={(e) => changeFormValue(e)}
                      color="primary"
                      name="isAlbum"
                      id="edo-ani1"
                      size="medium"
                    />
                    {"Is Album "}
                  </Label>
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "isAlbum",
                      formDataImageParent?.isAlbum,
                      "required"
                    )}
                  </p>
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Description"}</Label>
                  <textarea
                    className="form-control"
                    name="description"
                    type="text"
                    placeholder="Description"
                    value={formDataImageParent?.description}
                    rows={5}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "Description",
                      formDataImageParent?.description,
                      "required"
                    )}
                  </p>
                </Col> */}
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ImageMultipageParentPopup;
