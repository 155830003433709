import { IconButton } from "@mui/material";
import React, { useContext, useState } from "react";
import { Copy, Settings, Edit2 } from "react-feather";
import { Alert } from "reactstrap";
import copy from "copy-to-clipboard";
import { OpenModalCommonFunc, TostMessage } from "../../../../utils";
import SettingHeadersPerentPopup from "../SettingHeaderPopup";
import CommonPopup from "../../../../Modals/commonPopup";
import { Cases, modalsName } from "../../../../constant";
import ByteCardBuilderContext from "../../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { FILE_TYPE } from "../../../../../constant";
import { FILE_UPLOAD_API } from "../../apis/SliderApi/SliderApi";
import LoaderContext from "../../../../../context/LoaderContext/LoaderContext";
import { Update_Nav_Style } from "../../apis/DomainChangeApi/DomainChangeApi";
import SettingFooterHeaderPopup from "./SettingFooterHeaderPopup";
import FooterForm from "./FooterForm";
import { rgbColorCreate } from "../../../../../utils/helper";

const FooterHeader = ({
  domainShow,
  ByteMiniWeb_Dashboard,
  webCategoryDetails,
}) => {
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );
  const { setLoaderShow } = useContext(LoaderContext);

  const [settingFormData, setSettingFormData] = useState({
    ...webCategoryDetails[0]?.otherSetting,
    Footer: {
      copyRight: webCategoryDetails[0]?.otherSetting?.Footer?.copyRight || "",
      developedBy:
        webCategoryDetails[0]?.otherSetting?.Footer?.developedBy || "",
      style: webCategoryDetails[0]?.otherSetting?.Footer?.style || {
        ".custom-footer .foot-copright": {
          "background-color": { r: 255, g: 73, b: 124, a: 100 },
          padding: "15px 0",
          p1: "15px",
        },
        ".custom-footer .foot-copright p": {
          color: { r: 255, g: 255, b: 255, a: 100 },
          "font-size": "14px",
          "font-weight": "500",
        },
        ".custom-footer .foot-copright a": {
          "text-decoration": "underline",
          color: { r: 255, g: 255, b: 255, a: 100 },
        },
      },
    },
  });

  const handleSubmitSetting = async (isClose = true) => {
    try {
      let paramObj = {
        ...settingFormData,
        Footer: {
          ...settingFormData.Footer,
          style: {
            ...settingFormData.Footer.style,
            ".custom-footer .foot-copright": {
              ...settingFormData.Footer.style[".custom-footer .foot-copright"],
              "background-color": settingFormData.Footer.style[
                ".custom-footer .foot-copright"
              ]?.["background-color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData.Footer.style[
                        ".custom-footer .foot-copright"
                      ]?.["background-color"],
                  })
                : "",
              padding: `${settingFormData.Footer.style[
                ".custom-footer .foot-copright"
              ]["p1"]?.replace("px", "")}px 0`,
            },
            ".custom-footer .foot-copright p": {
              ...settingFormData.Footer.style[
                ".custom-footer .foot-copright p"
              ],
              color: settingFormData.Footer.style[
                ".custom-footer .foot-copright p"
              ]?.["color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData.Footer.style[
                        ".custom-footer .foot-copright p"
                      ]?.["color"],
                  })
                : "",

              "font-weight": `${settingFormData.Footer.style[".custom-footer .foot-copright p"]["font-weight"]}`,
              "font-size": `${settingFormData.Footer.style[
                ".custom-footer .foot-copright p"
              ]["font-size"]?.replace("px", "")}px`,
            },
            ".custom-footer .foot-copright a": {
              ...settingFormData.Footer.style[
                ".custom-footer .foot-copright a"
              ],
              color: settingFormData.Footer.style[
                ".custom-footer .foot-copright a"
              ]?.["color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData.Footer.style[
                        ".custom-footer .foot-copright a"
                      ]?.["color"],
                  })
                : "",
              "text-decoration": `${settingFormData.Footer.style[".custom-footer .foot-copright a"]["text-decoration"]}`,
            },
          },
        },
      };
      const { data } = await Update_Nav_Style(paramObj);
      if (data.status === 200) {
        TostMessage({ message: data, type: "success" });
        if (isClose) {
          OpenModalCommonFunc(Cases.SETTING_FOOTER, setAllPopupState, false);
          emptyData();
        }
        ByteMiniWeb_Dashboard();
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  const handleSubmitForm = async () => {
    try {
      const { data } = await Update_Nav_Style(settingFormData);
      if (data.status === 200) {
        TostMessage({ message: data, type: "success" });
        OpenModalCommonFunc(Cases.FOOTER_FORM, setAllPopupState, false);
        ByteMiniWeb_Dashboard();
        emptyData();
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  const emptyData = () => {
    setSettingFormData({
      ...webCategoryDetails[0]?.otherSetting,
      Footer: {
        copyRight: webCategoryDetails[0]?.otherSetting?.Footer?.copyRight || "",
        developedBy:
          webCategoryDetails[0]?.otherSetting?.Footer?.developedBy || "",
        style: webCategoryDetails[0]?.otherSetting?.Footer?.style || {
          ".custom-footer .foot-copright": {
            "background-color": "rgb(255 73 124 / 100)",
            padding: "15px 0",
            p1: "15px",
          },
          ".custom-footer .foot-copright p": {
            color: "rgb(255 255 255 / 100)",
            "font-size": "14px",
            "font-weight": "500",
          },
          ".custom-footer .foot-copright a": {
            "text-decoration": "underline",
            color: "rgb(255 255 255 / 100)",
          },
        },
      },
    });
  };

  return (
    <>
      <Alert
        color=" dark"
        style={{
          backgroundColor: "#e3f6fd",
          borderLeft: "3px solid #00abec",
        }}
        className="mb-5"
      >
        <div
          className="d-flex justify-content-between flex-wrap "
          style={{ padding: "0px 21px 0px 10px " }}
        >
          <div>
            <div className="text-blue">{` © Copyright 2023 ${domainShow}`}</div>
            <p>{`Developed by `}</p>
          </div>
          <div className="d-flex justify-content-center  align-items-center gap-2">
            <div>
              <IconButton
                aria-label="copy"
                onClick={() => {
                  setSettingFormData((prev) => {
                    return { ...prev, ...webCategoryDetails[0]?.otherSetting };
                  });
                  OpenModalCommonFunc(Cases.FOOTER_FORM, setAllPopupState);
                }}
              >
                <Edit2
                  style={{
                    width: "20px",
                    height: "20px",
                    position: "inherit",
                    top: "0",
                  }}
                  className="text-blue"
                />
              </IconButton>
              <IconButton
                aria-label="copy"
                onClick={() => {
                  setSettingFormData((prev) => {
                    return { ...prev, ...webCategoryDetails[0]?.otherSetting };
                  });
                  OpenModalCommonFunc(Cases.SETTING_FOOTER, setAllPopupState);
                }}
              >
                <Settings
                  style={{
                    width: "20px",
                    height: "20px",
                    position: "inherit",
                    top: "0",
                  }}
                  className="text-blue"
                />
              </IconButton>
            </div>
          </div>
        </div>
      </Alert>
      <CommonPopup
        open={allPopupState.settingFooter}
        close={() =>
          OpenModalCommonFunc(Cases.SETTING_FOOTER, setAllPopupState, false)
        }
        modalName={modalsName.SETTING_POPUP_DIALOG_PARENT}
        modalCase={Cases.SETTING_FOOTER}
        handleSubmit={handleSubmitSetting}
        emptyData={emptyData}
        inputData={settingFormData}
        edit={false}
        content={
          <SettingFooterHeaderPopup
            settingFormData={settingFormData}
            setSettingFormData={setSettingFormData}
            otherSetting={webCategoryDetails[0]?.otherSetting}
            handleSubmitSetting={handleSubmitSetting}
          />
        }
      />
      <CommonPopup
        open={allPopupState.footerFormPopup}
        close={() =>
          OpenModalCommonFunc(Cases.FOOTER_FORM, setAllPopupState, false)
        }
        modalName={modalsName.FOOTER_POPUP}
        modalCase={Cases.FOOTER_FORM}
        handleSubmit={handleSubmitForm}
        emptyData={emptyData}
        inputData={settingFormData}
        edit={false}
        content={
          <FooterForm
            settingFormData={settingFormData}
            setSettingFormData={setSettingFormData}
            otherSetting={webCategoryDetails[0]?.otherSetting}
          />
        }
      />
    </>
  );
};

export default FooterHeader;
