import axios from "axios";
import {
  GET_DETAILS,
  GET_DETAILS_DETAILS,
  POST_DETAILS,
  PUT_DETAILS_ORDER,
  PUT_STATUS_DETAILS,
} from "../../apiConstants";

/**
 * get details section data
 * @param {Parameters} paramObj
 * @returns
 */
export const getDetails = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_DETAILS}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * enable-disable
 * @param {body} body
 * @returns
 */
export const updateStatus = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_STATUS_DETAILS}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * create details section
 * @param {body} body
 * @returns
 */
export const postDetails = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(`${process.env.REACT_APP_API_URL}${POST_DETAILS}`, body, {
    headers: { Authorization: jwt_token },
  });
};

/**
 * update details section
 * @param {body} body
 * @returns
 */
export const putUpdateDetails = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(`${process.env.REACT_APP_API_URL}${POST_DETAILS}`, body, {
    headers: { Authorization: jwt_token },
    params: { detailsSectionId: body.detailsSectionId },
  });
};

/**
 * delete details section
 * @param {id} detailsSectionId
 * @returns
 */
export const deleteDetails = (detailsSectionId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${POST_DETAILS}`, {
    headers: { Authorization: jwt_token },
    params: { detailsSectionId },
  });
};

/**
 * get details details section
 * @param {id} detailsSectionId
 * @returns
 */
export const getDetailsDetails = (detailsSectionId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_DETAILS_DETAILS}`, {
    headers: { Authorization: jwt_token },
    params: { detailsSectionId },
  });
};

/**
 * short order details section
 * @param {body} updateOrder
 * @returns
 */
export const sort_order_Details = (updateOrder) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_DETAILS_ORDER}`,
    { updateOrder: updateOrder },
    {
      headers: { Authorization: jwt_token },
    }
  );
};
