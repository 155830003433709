import * as React from "react";
import { useContext } from "react";
import { useState } from "react";
import { toast, Zoom } from "react-toastify";
import { Org_PageList_Details } from "../../api/Organization Api/OrganizationApi";
import { THEME_SELECT } from "../../constant";
import LoaderContext from "../LoaderContext/LoaderContext";
import ModualManagerContext from "./ProfilecomponetContext";

const ProfileComponentState = (props) => {
  const [email, setEmail] = useState(null);
  const [fname, setFname] = useState(null);
  const [lname, setLname] = useState(null);
  const [pagetitle, setPageTitle] = useState(null);
  const [orgName, setOrgName] = useState(null);
  const [designation, setDesignation] = useState(null);
  const [address, setAddress] = useState(null);
  const [conuntry, setConuntry] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [logo, setLogo] = useState(null);
  const [userPhoto, setUserPhoto] = useState(null);
  const [seoTitle, setSeoTitle] = useState(null);
  const [seoDescription, setSeoDescription] = useState(null);
  const [seoKey, setSeoKey] = useState(null);
  const [dialCode, setDialCode] = useState("+91");
  const [slugUrl, setSlugUrl] = useState("");
  const [blockerSlugUrl, setBlockerSlugUrl] = useState("");
  const [actionQpiId, setActionQpiId] = useState(0);
  const [type, setType] = useState("");
  const [isDialog, setIsDialog] = useState(false);
  const [isFource, setIsFource] = useState(false);
  const [isLabel, setIsLabel] = useState("");
  const [isShowBranch, setIsShowBranch] = useState(false);
  const [isBranchLabel, setIsBranchLabel] = useState("");
  const [filter, setFilter] = useState("");

  const [theme, setTheme] = useState(null);
  const [color, setColor] = useState(null);
  /** user State */

  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [mobile, setMobile] = useState(null);

  const [check, setCheck] = useState(true);

  const [editIndex, seteditIndex] = useState(-1); //EDIT -->
  const [isAddHandle, setIsAddHandle] = useState(true);
  const [pageUuid, setPageUuid] = useState("");
  const [formvalue, setformValue] = useState([]);
  const [brokerDropdownData, setBrokerDopdownData] = useState([]);
  const [data, setData] = useState([]);

  const [togglesubmit, setToggleSubmit] = useState(false);
  const [modal, setModal] = useState(false);

  const [currentColor, setCurrentColor] = useState("#ff6");
  const appStyle = {
    backgroundColor: currentColor,
  };

  const { loaderShow, setLoaderShow } = useContext(LoaderContext);

  const FillFeild = async (pageUuid, orgId) => {
    setPageUuid(pageUuid);
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Org_PageList_Details(pageUuid);
      if (data.status === 200) {
        seteditIndex(orgId);
        setIsAddHandle(false);
        const payload = data.payload.data;
        setData(
          THEME_SELECT.filter((elem) => payload.templateId === elem.value)
        );
        setEmail(payload.email ? payload.email : null);
        setFname(payload.fname ? payload.fname : null);
        setLname(payload.lname ? payload.lname : null);
        setPageTitle(payload.pageTitle ? payload.pageTitle : null);
        setOrgName(payload.orgName ? payload.orgName : null);
        setDesignation(payload.designation ? payload.designation : null);
        setAddress(payload.address ? payload.address : null);
        setConuntry(payload.country ? payload.country : null);
        setState(payload.state ? payload.state : null);
        setCity(payload.city ? payload.city : null);
        setLogo(payload.orgLogo ? payload.orgLogo : null);
        setUserPhoto(payload.userPhoto ? payload.userPhoto : null);
        setSeoDescription(payload.seoDesc ? payload.seoDesc : null);
        setSeoKey(payload.seoKeyword ? payload.seoKeyword : null);
        setSeoTitle(payload.seoTitle ? payload.seoTitle : null);
        setUserEmail(payload.userEmail ? payload.userEmail : null);
        setMobile(payload.mobile ? payload.mobile : null);
        setSlugUrl(
          payload.slugUrl ? payload.slugUrl.replace(/\s+/g, "-") : null
        );
        setDialCode(payload.dialCode ? payload.dialCode : "+91");
        setIsDialog(payload.config.isDialog);
        setIsFource(payload.config.isFource);
        setIsLabel(payload.config.isLabel);
        setIsShowBranch(payload.config.is_show_branch);
        setIsBranchLabel(payload.config.branch_label);
        setTheme(payload.pageThemeConfig.themeColor);
        setColor(payload.pageThemeConfig.fontColor);
        setLoaderShow(false);
      } else {
      }
    } catch (error) {
      toast.error(error.response, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };

  return (
    <>
      <ModualManagerContext.Provider
        value={{
          email,
          setEmail,
          fname,
          setFname,
          lname,
          setLname,
          pagetitle,
          setPageTitle,
          orgName,
          setOrgName,
          designation,
          setDesignation,
          address,
          setAddress,
          conuntry,
          setConuntry,
          state,
          setState,
          city,
          setCity,
          logo,
          setLogo,
          seoTitle,
          setSeoTitle,
          seoDescription,
          setSeoDescription,
          seoKey,
          setSeoKey,
          check,
          setCheck,
          brokerDropdownData,
          setBrokerDopdownData,
          data,
          setData,

          password,
          setPassword,
          confirmPassword,
          setConfirmPassword,
          userEmail,
          setUserEmail,
          mobile,
          setMobile,

          FillFeild,
          editIndex,
          seteditIndex,
          isAddHandle,
          setIsAddHandle,
          formvalue,
          setformValue,
          togglesubmit,
          setToggleSubmit,
          modal,
          setModal,
          pageUuid,
          currentColor,
          setCurrentColor,
          appStyle,
          theme,
          color,
          dialCode,
          setDialCode,
          slugUrl,
          setSlugUrl,
          blockerSlugUrl,
          setBlockerSlugUrl,
          actionQpiId,
          setActionQpiId,
          type,
          setType,
          isDialog,
          setIsDialog,
          isFource,
          setIsFource,
          isLabel,
          setIsLabel,

          isShowBranch,
          setIsShowBranch,
          isBranchLabel,
          setIsBranchLabel,
          userPhoto,
          setUserPhoto,
          filter,
          setFilter,
        }}
      >
        {props.children}
      </ModualManagerContext.Provider>
    </>
  );
};

export default ProfileComponentState;
