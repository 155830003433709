import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { THEME } from "../../constant";
import { Switch } from "@mui/material";
import ThemeMapContext from "../../context/ThemeMapContext/ThemeMapContext";
import { Theme_List } from "../../api/ThemeApi/ThemeApi";
import { DropDownPageItemList } from "../../api/Organization Api/OrganizationApi";

const ThemeMapAddPopup = (props) => {
  const { handleSubmit, emptyData } = props;
  const { addModel, setAddModel, formData, setFormData, isEdit } =
    useContext(ThemeMapContext);
  const [allTheme, setAllTheme] = useState([]);
  const [allItems, setAllItems] = useState([]);

  const CloseModel = () => {
    emptyData();
    setAddModel(false);
  };
  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setFormData((prev) => {
      if (name === "isPremium") {
        return {
          ...prev,
          isPremium: checked,
        };
      } else {
        return {
          ...prev,
          [name]: value,
        };
      }
    });
  };

  const getThemeMasterList = async () => {
    try {
      let paramObj = {
        showAll: true,
      };
      const { data } = await Theme_List(paramObj);

      if (data.status === 200) {
        setAllTheme(
          data.payload.data.map((ele) => {
            return {
              value: ele.templateId,
              key: ele.templateName,
            };
          })
        );
      } else {
      }
    } catch (error) {}
  };

  const getItemMasterList = async () => {
    try {
      let paramObj = {
        showAll: true,
      };
      const { data } = await DropDownPageItemList(paramObj);

      if (data.status === 200) {
        setAllItems(
          data.payload.data.map((ele) => {
            return {
              value: ele.itemId,
              key: ele.label,
            };
          })
        );
      } else {
      }
    } catch (error) {}
  };

  useEffect(() => {
    getThemeMasterList();
    getItemMasterList();
  }, []);

  let isSystemValue = useMemo(() => {
    return formData?.isPremium ?? false;
  }, [formData?.isPremium]);

  return (
    <>
      <Modal size="xl" isOpen={addModel} toggle={() => CloseModel()}>
        <ModalHeader toggle={() => CloseModel()}>
          {isEdit ? `${THEME.EDIT_THEME_MAP}` : `${THEME.ADD_THEME_MAP}`}
        </ModalHeader>
        <ModalBody>
          <Container fluid={true} className="p-0">
            <Row>
              <Form className="theme-form" onSubmit={(e) => handleSubmit(e)}>
                <Row>
                  <Col md="6 mb-4">
                    <Label htmlFor="validationCustom03">{"templateId"}</Label>
                    <select
                      name="templateId"
                      id="font-weight"
                      className="form-select"
                      value={formData?.templateId}
                      onChange={(e) => handleChange(e)}
                      style={{ color: "black" }}
                    >
                      <option value="">--select--</option>
                      {allTheme.map((elem) => {
                        return (
                          <option value={elem["value"]} key={elem["key"]}>
                            {elem["key"]}
                          </option>
                        );
                      })}
                    </select>
                  </Col>
                  <Col md="6 mb-4">
                    <Label htmlFor="validationCustom03">{"itemId"}</Label>
                    <select
                      name="itemId"
                      id="font-weight"
                      className="form-select"
                      value={formData?.itemId}
                      onChange={(e) => handleChange(e)}
                      style={{ color: "black" }}
                    >
                      <option value="">--select--</option>
                      {allItems.map((elem) => {
                        return (
                          <option value={elem["value"]} key={elem["key"]}>
                            {elem["key"]}
                          </option>
                        );
                      })}
                    </select>
                  </Col>
                </Row>
                <Row>
                  <Col md="2 mb-4">
                    <Label htmlFor="validationCustom03">{"isPremium"}</Label>
                    <div>
                      <Switch
                        checked={isSystemValue}
                        onChange={(e) => handleChange(e)}
                        name="isPremium"
                        color="primary"
                      />
                    </div>
                  </Col>
                </Row>
                <div className="login-btn mt-2">
                  <Button color="primary" type="submit" value="submit">
                    {"Submit"}
                  </Button>
                </div>
              </Form>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ThemeMapAddPopup;
