import React, { useContext, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import { FILE_TYPE, ThemeNo, titleUpdateKey } from "../../../../constant";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import { Cases, DELETE_CONFIRM, modalsName } from "../../../constant";
import CommonPopup from "../../../Modals/commonPopup";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import PopupDialogParentPopup from "./PopupDialogParentPopup";
import {
  deletePopupDialog,
  getPopupDialog,
  getPopupDialogDetails,
  postPopupDialog,
  putUpdatePopupDialog,
  updateStatus,
} from "../apis/PopupDialogApi/PopupDialogApi";
import PopupDialogContext from "../../../context/PopupDialogContext/PopupDialogContext";
import PopupDialogPopup from "./PopupDialogPopup";
import { FILE_UPLOAD_API } from "../apis/SliderApi/SliderApi";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import SettingPopupDialogParentPopup from "./SettingPopupDialogParentPopup";
import SimpleReactValidator from "simple-react-validator";
import { isEmptyObject } from "../../../../utils/helper";
import DeleteSection from "../../../common/DeleteSection";
import SettingMultiWebContext from "../../../../context/SettingMultiWebContext/SettingMultiWebContext";
import {
  ItemFeatureStyleOneSubFun,
  PopupDialogThemeOneFunction,
  PopupStyleOneReset,
} from "./PopupDialogStyleFunction";

const PopupDialog = (props) => {
  const {
    pageConfigDetails,
    webMasterDetail,
    navbarData,
    getData,
    packagesDetail,
    contentMapId,
    ByteMiniWeb_Dashboard,
    contentDetail,
  } = props;
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );
  const { setCloneAlignModal, setAlignFormData, setCloneFormData } = useContext(
    SettingMultiWebContext
  );
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const { setLoaderShow } = useContext(LoaderContext);
  const {
    id,
    setId,
    setIsParent,
    apiDataOurTeam: apiDataImageParent,
    setApiDataOurTeam: setApiDataImageParent,
    setCollId,
    setFilter,
    fromData,
    setFormDataChild,
    formDataChild,
    isEdit,
    setIsEdit,
    setSettingFormData,
    settingFormData,
    theme,
    setTheme,
    setImgShow,
  } = useContext(PopupDialogContext);
  /**
   * get packages parent list
   * @param {status} status
   */
  const getPopupDialogParentList = async (filter) => {
    try {
      let paramObj = {};
      if (filter?.length) {
        paramObj["search"] = { title: filter };
      }
      const { data } = await getPopupDialog(paramObj);
      if (data.status === 200) {
        setApiDataImageParent({
          ...apiDataImageParent,
          isLoading: false,
          data: data.payload.data.map((elem) => {
            return {
              id: elem?.title + elem?.popupDialogId,
              Id: elem?.popupDialogId,
              name: elem?.title,
              image: "",
              webId: elem?.webId,
              createdAt: elem?.createdAt,
              isActive: elem?.isActive,
              parentId: elem?.parentId,
            };
          }),
        });
        OpenModalCommonFunc(Cases.POPUP_DIALOG, setAllPopupState);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  const handleChangeStatus = async (popupDialogId, isActive) => {
    try {
      let body = {
        popupDialogId,
        isActive: isActive ? false : true,
      };
      const { data } = await updateStatus(body);
      if (data.status === 200) {
        getPopupDialogParentList();

        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * file upload
   * @param {file} file
   */
  const imageUploadTeamMember = async (file) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.POPUP_DIALOG);
    formData.append("file", file);
    setLoaderShow(true);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        setFormDataChild((prev) => {
          return {
            ...prev,
            image: data.payload.baseUrl + data.payload.imageUrl,
          };
        });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      if (isEdit) {
        try {
          let formData = {};
          formData = {
            ...formDataChild,
            popupDialogId: id,
          };
          const { data } = await putUpdatePopupDialog(formData);
          if (data.status === 200) {
            getPopupDialogParentList();

            emptyData();
            OpenModalCommonFunc(Cases.POPUP_DIALOG, setAllPopupState, false);
            TostMessage({ message: data, type: "success" });
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      } else {
        try {
          let formData = {};
          formData = {
            ...formDataChild,
            contentMapId,
          };
          const { data } = await postPopupDialog(formData);
          if (data.status === 200) {
            getPopupDialogParentList();

            emptyData();
            OpenModalCommonFunc(Cases.POPUP_DIALOG, setAllPopupState, false);
            TostMessage({ message: data, type: "success" });
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      }
    }
  };

  /**
   * delete packages
   * @param {id} popupDialogId
   */
  const handleDelete = async (popupDialogId, isParent = false) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await deletePopupDialog(popupDialogId);
        if (data.status === 200) {
          getPopupDialogParentList();
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * get data packages
   * @param {id} popupDialogId
   */
  const handleEditPackages = async (popupDialogId, isParent) => {
    try {
      setLoaderShow(true);
      const { data } = await getPopupDialogDetails(popupDialogId);
      if (data.status === 200) {
        let payload = data.payload.data;

        setIsParent(false);
        setId(payload?.popupDialogId);
        setFormDataChild({
          title: payload?.title || "",
          subTitle: payload?.subTitle || "",
          buttonText: payload?.buttonText || "",
          link: payload?.link || "",
          shortDescription: payload?.shortDescription || "",
          description: payload?.description || "",
          image: payload?.image || "",
          style: payload?.style || "",
          contentMapId: payload?.contentMapId,
          theme: payload?.theme || "",
          webId: localStorage.getItem("webId"),
        });
        OpenModalCommonFunc(Cases.POPUP_DIALOG, setAllPopupState);

        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  let [value] = navbarData.filter(
    (elem) => elem.navbarDetail.label === titleUpdateKey.isPopupDialog
  );

  const handleSubmitSetting = async (isRestore = false, isClose = true) => {
    if (id >= -1) {
      setLoaderShow(true);
      try {
        let newData;
        if (isRestore) {
          const isConfirm = window.confirm(
            "Are you sure want to restore style ?"
          );
          if (isConfirm) {
            newData = {
              styleRestore: true,
              popupDialogId: id,
            };
          } else {
            setLoaderShow(false);
            return;
          }
        } else {
          if (+theme === ThemeNo.ThemeOne) {
            newData = ItemFeatureStyleOneSubFun({
              settingFormData,
            });
          }
          newData = {
            style: newData,
            popupDialogId: id,
          };
        }

        const { data } = await putUpdatePopupDialog(newData);
        if (data.status === 200) {
          setIsParent(true);
          if (isClose) {
            OpenModalCommonFunc(
              Cases.SETTING_POPUP_DIALOG_PARENT,
              setAllPopupState,
              false
            );
          }
          setLoaderShow(false);
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  const handleChangeSettings = async (popupDialogId, isThemeEdit = false) => {
    try {
      setLoaderShow(true);
      setId(popupDialogId);
      setSettingFormData((prev) => {
        return {
          ...prev,
          popupDialogId,
        };
      });
      const { data } = await getPopupDialogDetails(popupDialogId);
      if (data.status === 200) {
        const themeCount = data?.payload?.data?.theme;
        setTheme(themeCount ? themeCount : 1);
        setImgShow(true);
        let payload = data.payload.data;
        if (
          +themeCount === ThemeNo.ThemeOne &&
          isEmptyObject(data?.payload?.data?.style)
        ) {
          setSettingFormData({ ...PopupStyleOneReset });
        } else {
          let getStyle;
          if (+themeCount === ThemeNo.ThemeOne) {
            getStyle = PopupDialogThemeOneFunction(payload);
          } else {
            getStyle = PopupDialogThemeOneFunction(payload);
          }
          setSettingFormData((prev) => {
            return {
              ...prev,
              ...getStyle,
            };
          });
        }
        if (!isThemeEdit) {
          OpenModalCommonFunc(
            Cases.SETTING_POPUP_DIALOG_PARENT,
            setAllPopupState
          );
          TostMessage({ message: data, type: "success" });
        }
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const emptyData = () => {
    setIsEdit(false);
    setId("");
    setFormDataChild({
      title: "",
      subTitle: "",
      buttonText: "",
      link: "",
      shortDescription: "",
      description: "",
      image: "",
      style: {
        ".popup .modal-content": {
          "background-color": "rgb(255 255 255 / 100)",
        },
        ".popup .modal-header": {
          "justify-content": "space-between",
        },
        ".popup .modal-title": {
          color: "rgb(0 0 0 / 100)",
          "font-size": "20px",
        },
        ".popup .modal-body": {
          padding: "1rem",
        },
        ".popup .modal-btn": {
          "font-size": "16px",
          "font-weight": "600",
          color: "rgb(255 255 255 / 100)",
          "background-color": "rgb(255 73 124 / 100)",
          padding: "5px 20px",
          p1: "5px",
          p2: "20px",
          "border-radius": "15px",
          "margin-bottom": "15px",
        },
        ".popup .modal-shortDescription": {
          "font-size": "16px",
          "font-weight": "600",
          color: "rgb(0 0 0 / 100)",
          "padding-top": "12px",
        },
        ".popup .modal-description": {
          "font-size": "16px",
          "font-weight": "600",
          color: "rgb(0 0 0 / 100)",
          "padding-top": "12px",
        },
      },
      webId: localStorage.getItem("webId"),
    });
    setSettingFormData({
      ".popup .modal-content": {
        "background-color": "rgb(255 255 255 / 100)",
      },
      ".popup .modal-header": {
        "justify-content": "space-between",
      },
      ".popup .modal-title": {
        color: "rgb(0 0 0 / 100)",
        "font-size": "20px",
      },
      ".popup .modal-body": {
        padding: "1rem",
      },
      ".popup .modal-btn": {
        "font-size": "16px",
        "font-weight": "600",
        color: "rgb(255 255 255 / 100)",
        "background-color": "rgb(255 73 124 / 100)",
        padding: "5px 20px",
        p1: "5px",
        p2: "20px",
        "border-radius": "15px",
        "margin-bottom": "15px",
      },
      ".popup .modal-shortDescription": {
        "font-size": "16px",
        "font-weight": "600",
        color: "rgb(0 0 0 / 100)",
        "padding-top": "12px",
      },
      ".popup .modal-description": {
        "font-size": "16px",
        "font-weight": "600",
        color: "rgb(0 0 0 / 100)",
        "padding-top": "12px",
      },
    });
  };

  const handleAlignAndCloneSection = ({ isClone = false }) => {
    if (isClone) {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          cloneModal: true,
        };
      });
      setCloneFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          pageConfigDetailsLabel: pageConfigDetails[0]?.label,
          contentDetailName: contentDetail.name,
        };
      });
    } else {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          alignModal: true,
        };
      });
      setAlignFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          label: pageConfigDetails[0]?.label,
        };
      });
    }
  };
  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody
            className="support-ticket-font box-border-div"
            style={{ padding: "20px" }}
          >
            <Row>
              <CardBuilderHeaderSection
                addBtnTitle={pageConfigDetails[0]?.label}
                name={value?.label || pageConfigDetails[0]?.label}
                isAdd={!packagesDetail && false}
                openModal={() => {
                  OpenModalCommonFunc(
                    Cases.POPUP_DIALOG_PARENT,
                    setAllPopupState
                  );
                  emptyData();
                }}
                isCloneSection={true}
                isAlignSection={true}
                handleAlignAndCloneSection={handleAlignAndCloneSection}
              />
              <OpenPopupBtn
                openPopup={() => {
                  getPopupDialogParentList();
                  setCollId(contentMapId);
                  setFilter("");
                }}
                name={`Add or View ${pageConfigDetails[0]?.label ?? ""}`}
              />
              <DeleteSection
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
              />
            </Row>
          </CardBody>
        </Card>
      </Col>

      <CommonPopup
        open={allPopupState.popupDialogChildPopup}
        close={() => {
          OpenModalCommonFunc(Cases.POPUP_DIALOG, setAllPopupState, false);
          setIsParent(false);
        }}
        // apiCall={getPopupDialogParentList}
        modalName={modalsName.POPUP_DIALOG}
        modalCase={Cases.POPUP_DIALOG}
        handleSubmit={handleSubmit}
        validationForm={"childForm"}
        emptyData={emptyData}
        inputData={fromData}
        edit={false}
        oldTitle={titleUpdateKey.isGallery}
        label={titleUpdateKey.Certificates}
        getData={getData}
        content={
          <PopupDialogPopup
            handleChangeStatus={handleChangeStatus}
            handleEdit={handleEditPackages}
            imageUpload={imageUploadTeamMember}
            emptyData={emptyData}
            simpleValidator={simpleValidator}
            webMasterDetail={webMasterDetail}
            getPopupDialogParentList={getPopupDialogParentList}
            handleDelete={handleDelete}
            handleChangeSettings={handleChangeSettings}
          />
        }
      />
      {/* item feature parent popup */}
      <CommonPopup
        open={allPopupState.popupDialogPopup}
        close={() => {
          OpenModalCommonFunc(
            Cases.POPUP_DIALOG_PARENT,
            setAllPopupState,
            false
          );
          setIsParent(false);
        }}
        modalName={modalsName.POPUP_DIALOG_PARENT}
        modalCase={Cases.POPUP_DIALOG_PARENT}
        handleSubmit={handleSubmit}
        validationForm={"parentForm"}
        emptyData={emptyData}
        // inputData={formDataChild}
        edit={false}
        content={<PopupDialogParentPopup />}
      />

      {/* setting images parent popup */}
      <CommonPopup
        open={allPopupState.popupDialogSettingPopup}
        close={() =>
          OpenModalCommonFunc(
            Cases.SETTING_POPUP_DIALOG_PARENT,
            setAllPopupState,
            false
          )
        }
        modalName={modalsName.SETTING_POPUP_DIALOG_PARENT}
        modalCase={Cases.SETTING_POPUP_DIALOG_PARENT}
        handleSubmit={handleSubmitSetting}
        emptyData={emptyData}
        // inputData={settingFormData}
        edit={false}
        content={
          <SettingPopupDialogParentPopup
            handleSubmitSetting={handleSubmitSetting}
            handleChangeSettings={handleChangeSettings}
          />
        }
      />
    </>
  );
};

export default PopupDialog;
