import React, { useContext, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import { Cases, DELETE_CONFIRM, modalsName } from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import CommonPopup from "../../../Modals/commonPopup";
import ServiceCounterPopup from "./ServiceCounterPopup";
import EditIcon from "@mui/icons-material/Edit";
import ServiceCounterParentPopup from "./ServiceCounterParentPopup";
import { FILE_UPLOAD_API } from "../apis/SliderApi/SliderApi";
import {
  convertRGBA,
  isCheckValue,
  isEmptyObject,
  randomStr,
  removeUrlKeyInPath,
  rgbColorCreate,
} from "../../../../utils/helper";
import {
  FILE_TYPE,
  ServiceCounterTheme1,
  ServiceCounterTheme2,
  ThemeNo,
  titleUpdateKey,
} from "../../../../constant";
import { IconButton } from "@material-ui/core";
import { Settings, Trash } from "react-feather";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import SimpleReactValidator from "simple-react-validator";
import SettingImageParentPopup from "./SettingServicePerentPopup";
import {
  deleteServiceCounter,
  getServiceCounter,
  getServiceCounterDetails,
  postServiceCounter,
  putUpdateServiceCounter,
  updateStatus,
} from "../apis/ServiceCounterApi/ServiceCounterApi";
import ServiceCounterContext from "../../../context/ServiceCounterContext/ServiceCounterContext";
import {
  SerCountOneFunction,
  SerCountThemeOneFunction,
  SerCountThemeTwoFunction,
  SerCountTwoFunction,
} from "./ServiceCounterFunction";
import DeleteSection from "../../../common/DeleteSection";
import SettingMultiWebContext from "../../../../context/SettingMultiWebContext/SettingMultiWebContext";

const ServiceCounter = (props) => {
  const {
    pageConfigDetails,
    webMasterDetail,
    getData,
    serviceCounterDetail,
    contentMapId,
    ByteMiniWeb_Dashboard,
    contentDetail,
  } = props;
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );
  const { setCloneAlignModal, setAlignFormData, setCloneFormData } = useContext(
    SettingMultiWebContext
  );
  const parentValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const childValidator = useRef(new SimpleReactValidator());
  const {
    fromData,
    setFormData,
    formDataChild,
    setFormDataChild,
    id,
    setId,
    setIsEdit,
    isEdit,
    setFilter,
    apiDataOurTeam: apiDataImageParent,
    setApiDataOurTeam: setApiDataImageParent,
    setSkill,
    setGalleryImgId,
    setSettingFormData,
    settingFormData,
    isParent,
    setIsParent,
    childId,
    setChildId,
    navMapId,
    setNavMapId,
    collId,
    setCollId,
    boxSection,
    setBoxSection,
    setBackImageToggle,
    isBackgroundColor,
    setIsBackgroundColor,
    theme,
    setTheme,
    imageIs,
    setImageIs,
    setImgShow,
    sectionPageName,
    setSectionPageName,
  } = useContext(ServiceCounterContext);
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * get service counter parent list
   * @param {status} status
   */
  const getServiceCounterParentList = async ({
    parentId = null,
    isClose = false,
    filter,
  }) => {
    try {
      let paramObj = {};
      if (
        ((parentId && parentId !== null) ||
          (id && isClose === false && isParent === false)) &&
        isParent === false
      ) {
        paramObj["search"] = { parentId: parentId || id };
        setId(parentId || id);
        if (filter?.length) {
          paramObj["search"] = { ...paramObj.search, title: filter };
        }
      } else {
        paramObj["search"] = { parentId: null };
      }
      const { data } = await getServiceCounter(paramObj);
      if (isCheckValue({ value: parentId })) {
        setChildId(parentId);
      }
      if (data.status === 200) {
        if (
          isParent === false &&
          ((parentId && parentId !== null) || id) &&
          isClose === false
        ) {
          setApiDataImageParent({
            ...apiDataImageParent,
            isLoading: false,
            data: data.payload.data.map((elem) => {
              return {
                id: elem?.title + elem?.serviceCounterId,
                Id: elem?.serviceCounterId,
                name: elem?.title,
                image: "",
                webId: elem?.webId,
                createdAt: elem?.createdAt,
                isActive: elem?.isActive,
                parentId: elem?.parentId,
              };
            }),
          });
          OpenModalCommonFunc(Cases.SERVICE_COUNTER, setAllPopupState);
        } else if (parentId && parentId !== null) {
          OpenModalCommonFunc(Cases.SERVICE_COUNTER, setAllPopupState);
          setApiDataImageParent({
            ...apiDataImageParent,
            isLoading: false,
            data: data.payload.data.map((elem) => {
              return {
                id: elem?.title + elem?.serviceCounterId,
                Id: elem?.serviceCounterId,
                name: elem?.title,
                image: "",
                webId: elem?.webId,
                createdAt: elem?.createdAt,
                isActive: elem?.isActive,
                parentId: elem?.parentId,
              };
            }),
          });
        } else {
          setApiDataImageParent({
            ...apiDataImageParent,
            isLoading: false,
            data: data.payload.data,
          });
        }
        if (isClose && isClose !== false) {
          setIsParent(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * item service counter enable disable api call
   * @param {serviceCounterId} serviceCounterId
   * @param {status} isActive
   */

  const handleChangeServiceCounter = async (
    serviceCounterId,
    isActive,
    isChild,
    parentId
  ) => {
    try {
      let body = {
        serviceCounterId,
        isActive: isActive ? false : true,
      };
      if (parentId) {
        body["parentId"] = parentId;
      }
      const { data } = await updateStatus(body);
      if (data.status === 200) {
        if (isChild) {
          getServiceCounterParentList({ parentId: parentId });
        } else {
          ByteMiniWeb_Dashboard();
        }
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * file upload
   * @param {file} file
   */
  const imageUploadImages = async (file) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.SERVICE_COUNTER);
    formData.append("file", file);
    setLoaderShow(true);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        setFormDataChild((prev) => {
          return {
            ...prev,
            image: data.payload.baseUrl + data.payload.imageUrl,
            featureObject: {
              ...prev.featureObject,
              icon: data.payload.baseUrl + data.payload.imageUrl,
            },
          };
        });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const backImageUpload = async (file) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.SERVICE_COUNTER);
    formData.append("file", file);
    setLoaderShow(true);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        setSettingFormData((prev) => {
          if (imageIs === "serImage1") {
            return {
              ...prev,
              ".counter-section.bg-image": {
                "background-image":
                  data.payload.baseUrl + data.payload.imageUrl,
              },
            };
          } else if (imageIs === "serImage2") {
            return {
              ...prev,
              ".counter1-section.bg-image": {
                "background-image":
                  data.payload.baseUrl + data.payload.imageUrl,
              },
            };
          }
        });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * create and update service counter
   */

  const submitForm = async () => {
    if (
      (isParent === false && childId >= -1 && isEdit) ||
      (id >= -1 && isEdit)
    ) {
      setLoaderShow(true);
      try {
        let formData = {};
        if (isParent && isParent !== false) {
          formData = {
            ...fromData,
            contentMapId: collId,
            serviceCounterId: id,
          };
          delete formData?.style;
        } else {
          formData = {
            ...formDataChild,
            value: `${formDataChild.value} ${formDataChild.onlyValue}`,
            parentId: id,
            navMapId,
            contentMapId: collId,
            serviceCounterId: childId,
          };
          delete formData.image;
          if (formDataChild.image.length) {
            formData["image"] = formDataChild.image
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
              .pop();
          }
        }
        const { data } = await putUpdateServiceCounter(formData);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            ByteMiniWeb_Dashboard();
          } else {
            getServiceCounterParentList({ parentId: id });
          }
          emptyData();
          OpenModalCommonFunc(
            Cases.SERVICE_COUNTER_PARENT,
            setAllPopupState,
            false
          );
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    } else {
      try {
        setLoaderShow(true);
        let formData = {};
        if (isParent && isParent !== false) {
          formData = { ...fromData, contentMapId: collId };
        } else {
          formData = {
            ...formDataChild,
            value: `${formDataChild.value} ${formDataChild.onlyValue}`,
            parentId: id,
            navMapId,
            order: apiDataImageParent.data.length + 1,
            contentMapId: collId,
          };
          delete formData.image;
          if (formDataChild.image.length) {
            formData["image"] = formDataChild.image
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
              .pop();
          }
        }
        delete formData.onlyValue;
        const { data } = await postServiceCounter(formData);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            ByteMiniWeb_Dashboard();
          } else {
            getServiceCounterParentList({ parentId: id });
          }
          emptyData();
          OpenModalCommonFunc(
            Cases.SERVICE_COUNTER_PARENT,
            setAllPopupState,
            false
          );
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  const handleSubmit = async (formValidationCondition) => {
    let validatorFields;
    if (formValidationCondition === "parentForm") {
      validatorFields = parentValidator.current;
    } else if (formValidationCondition === "childForm") {
      validatorFields = childValidator.current;
    }
    validatorFields.showMessages();

    if (validatorFields.allValid()) {
      try {
        // Process form data based on the formType
        if (formValidationCondition === "parentForm") {
          submitForm();
        } else if (formValidationCondition === "childForm") {
          submitForm();
        }
        validatorFields.hideMessages();
      } catch (error) {
        // Handle error for form submission
      }
    } else {
      // Force update to display validation messages
      forceUpdate({});
    }
  };

  /**
   * create and update service counter
   */
  const handleSubmitSetting = async (isRestore = false, isClose = true) => {
    if (id >= -1 && isEdit) {
      setLoaderShow(true);
      try {
        let newData;
        if (isRestore) {
          const isConfirm = window.confirm(
            "Are you sure want to restore style ?"
          );
          if (isConfirm) {
            newData = {
              styleRestore: true,
              serviceCounterId: id,
            };
          } else {
            setLoaderShow(false);
            return;
          }
        } else {
          if (+theme === 1) {
            newData = SerCountOneFunction({
              settingFormData,
              boxSection,
            });
            newData = { ...newData };
            if (isBackgroundColor) {
              newData[".counter-section"] = {
                ...newData?.[".counter-section"],
                "background-color": settingFormData?.[".counter-section"]?.[
                  "background-color"
                ]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[".counter-section"]?.[
                          "background-color"
                        ],
                    })
                  : "",
              };
            }
            if (boxSection) {
              newData[".counter-section.bg-image::before"] = {
                ...newData[".counter-section.bg-image::before"],
                "border-radius": `${settingFormData?.[".counter-section"]?.[
                  "border-radius"
                ]?.replace("rem", "")}rem`,
              };
            }
          } else if (+theme === 2) {
            newData = SerCountTwoFunction({
              settingFormData,
              boxSection,
            });
            newData = { ...newData };
            if (isBackgroundColor) {
              newData[".counter1-section"] = {
                ...newData?.[".counter1-section"],
                "background-color": settingFormData?.[".counter1-section"]?.[
                  "background-color"
                ]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[".counter1-section"]?.[
                          "background-color"
                        ],
                    })
                  : "",
              };
            }
            if (boxSection) {
              newData[".counter1-section.bg-image::before"] = {
                ...newData[".counter1-section.bg-image::before"],
                "border-radius": `${settingFormData?.[".counter1-section"]?.[
                  "border-radius"
                ]?.replace("rem", "")}rem`,
              };
            }
          }
          const keysToRemove = ["image"];
          function removeKeys(obj, keys) {
            for (const key in obj) {
              if (typeof obj[key] === "object") {
                removeKeys(obj[key], keys);
              } else if (keys.includes(key)) {
                delete obj[key];
              }
            }
          }
          removeKeys(newData, keysToRemove);
          newData = {
            style: newData,
            serviceCounterId: id,
          };
        }

        const { data } = await putUpdateServiceCounter(newData);
        if (data.status === 200) {
          getServiceCounterParentList({ parentId: null, isClose: true });
          if (isClose) {
            OpenModalCommonFunc(
              Cases.SETTING_SERVICECOUNTER_PARENT,
              setAllPopupState,
              false
            );
            emptyData();
          }
          setLoaderShow(false);
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  /**
   * get data service counter
   * @param {id} serviceCounterId
   */
  const handleEditServiceCounter = async (serviceCounterId, isParent) => {
    try {
      setLoaderShow(true);
      const { data } = await getServiceCounterDetails(serviceCounterId);
      if (data.status === 200) {
        let payload = data.payload.data;
        if (isParent) {
          setSectionPageName(payload?.title);
          setId(serviceCounterId);
          setIsParent(true);
          setFormData({
            title: payload?.title || "",
            subTitle: payload?.subTitle || "",
            navMapId: payload?.navMapId || "",
            isTitleShow: payload?.isTitleShow ? payload?.isTitleShow : false,
            webId: localStorage.getItem("webId"),
            theme: payload?.theme || 1,
            style: payload?.style || "",
          });
          OpenModalCommonFunc(Cases.SERVICE_COUNTER_PARENT, setAllPopupState);
        } else {
          setChildId(serviceCounterId);
          setIsParent(false);
          const split2Values = payload?.value.split(" ");
          setFormDataChild({
            title: payload?.title || "",
            subTitle: payload?.subTitle || "",
            value: split2Values[0] || "",
            onlyValue: split2Values[1] || "",
            parentId: payload?.parentId || "",
            isTitleShow: payload?.isTitleShow ? payload?.isTitleShow : false,
            image: payload?.image || "",
            order: payload?.order || 1,
            webId: localStorage.getItem("webId"),
          });
          OpenModalCommonFunc(Cases.SERVICE_COUNTER, setAllPopupState);
        }
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * get setting data
   * @param {id} serviceCounterId
   */
  const handleChangeSettings = async (
    serviceCounterId,
    isThemeEdit = false
  ) => {
    try {
      setLoaderShow(true);
      setId(serviceCounterId);
      setSettingFormData((prev) => {
        return {
          ...prev,
          serviceCounterId,
        };
      });
      const { data } = await getServiceCounterDetails(serviceCounterId);
      if (data.status === 200) {
        let payload = data.payload.data;
        setSectionPageName(payload.title);
        const themeCount = data?.payload?.data?.theme;
        setTheme(themeCount);
        setImgShow(true);
        if (+themeCount === ThemeNo.ThemeOne && isEmptyObject(payload.style)) {
          setSettingFormData({ ...ServiceCounterTheme1 });
          setBoxSection(false);
          setBackImageToggle(false);
          setIsBackgroundColor(false);
        } else if (
          +themeCount === ThemeNo.ThemeTwo &&
          isEmptyObject(payload.style)
        ) {
          setSettingFormData({ ...ServiceCounterTheme2 });
          setBoxSection(false);
          setBackImageToggle(false);
          setIsBackgroundColor(false);
        } else {
          let find = [".counter-section", ".counter1-section"];
          let section = Object.keys(payload.style);
          let foundItem = null;
          for (const key in find) {
            if (section.includes(find[key])) {
              foundItem = find[key];
              break; // If found, exit the loop
            }
          }
          if (
            payload.style?.[foundItem]?.["width"] ||
            payload.style?.[foundItem]?.["margin"] ||
            payload.style?.[foundItem]?.["border-radius"]
          ) {
            setBoxSection(true);
          }
          if (payload.style?.[`${foundItem}.bg-image`]?.["background-image"]) {
            setBackImageToggle(true);
          }
          if (payload.style?.[foundItem]?.["background-color"]) {
            setIsBackgroundColor(true);
          }
          let getStyle;
          if (+data.payload.data.theme === 1) {
            getStyle = SerCountThemeOneFunction(payload);
          } else if (+data.payload.data.theme === 2) {
            getStyle = SerCountThemeTwoFunction(payload);
          }
          setSettingFormData((prev) => {
            return {
              [foundItem]: {
                padding: payload.style?.[foundItem]?.["padding"],
                "background-color": payload.style?.[foundItem]?.[
                  "background-color"
                ]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[foundItem]?.["background-color"],
                    })
                  : "",
                width: payload.style?.[foundItem]?.["width"]?.replace(
                  "calc(100% - ",
                  ""
                ),
                margin: payload.style?.[foundItem]?.["margin"],
                "border-radius": payload.style?.[foundItem]?.["border-radius"],
              },
              [`${foundItem}.bg-image`]: {
                "background-image": payload.style?.[`${foundItem}.bg-image`]?.[
                  "background-image"
                ]
                  ? removeUrlKeyInPath({
                      urlString:
                        payload.style?.[`${foundItem}.bg-image`]?.[
                          "background-image"
                        ],
                    })
                  : "",
              },
              [`${foundItem}.bg-image::before`]: {
                "background-color": payload.style?.[
                  `${foundItem}.bg-image::before`
                ]?.["background-color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[`${foundItem}.bg-image::before`]?.[
                          "background-color"
                        ],
                    })
                  : "",
              },
              ...getStyle,
              custom: payload.style.custom,
            };
          });
        }
        if (!isThemeEdit) {
          OpenModalCommonFunc(
            Cases.SETTING_SERVICECOUNTER_PARENT,
            setAllPopupState
          );
          TostMessage({ message: data, type: "success" });
        }

        setIsEdit(true);
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * delete service counter
   * @param {id} serviceCounterId
   */
  const handleDelete = async (serviceCounterId, isParent = false) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await deleteServiceCounter(serviceCounterId);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            ByteMiniWeb_Dashboard();
          } else {
            getServiceCounterParentList({ packageId: childId });
          }
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * clear data
   */
  const emptyData = () => {
    setImageIs("");
    setIsBackgroundColor(false);
    setBoxSection(false);
    setIsEdit(false);
    setIsParent(false);
    setChildId("");
    setFormData({
      title: "",
      subTitle: "",
      navMapId: "",
      isTitleShow: true,
      theme: 1,
      webId: localStorage.getItem("webId"),
      style: {
        ".counter-section": {
          padding: "100px 0",
          "background-color": "rgb(0 0 0 / 100)",
        },
        ".counter-section.bg-image": {
          "background-image": "",
        },
        ".counter-section.bg-image::before": {
          "background-color": "",
        },
        ".counter-section .section-title-area": {
          "margin-bottom": "50px",
        },
        ".counter-section .section-title-area .section-subtitle": {
          "font-size": "16px",
          "font-weight": "600",
          color: "rgb(255 73 124 / 100)",
          "background-color": "rgb(255 73 124 / 0.2)",
          padding: "5px 20px",
          p1: "5px",
          p2: "20px",
          "border-radius": "15px",
          "margin-bottom": "15px",
        },
        ".counter-section .section-title-area .section-title": {
          "font-size": "44px",
          "font-weight": "600",
          color: "rgb(255 255 255 / 100)",
        },
        ".counter-section .countdiv": {
          "text-align": "center",
          "margin-bottom": "30px",
        },
        ".counter-section .countdiv .counter": {
          color: "rgb(255 73 124 / 100)",
          "font-size": "40px",
          "font-weight": "600",
        },
        ".counter-section .countdiv .divider": {
          width: "80px",
          height: "4px",
          margin: "15px auto",
          "border-radius": "3px",
          "background-color": "rgb(255 255 255 / 100)",
        },
        ".counter-section .countdiv .counter-title": {
          color: "rgb(255 255 255 / 100)",
          "font-size": "22px",
          "font-weight": "500",
        },
        serviceCounterId: "",
      },
    });
    setSkill([{ id: randomStr(2), skill: "", parentageOfSkill: "" }]);
    setGalleryImgId("");
    setFormDataChild({
      title: "",
      subTitle: "",
      value: "",
      onlyValue: "",
      image: "",
      order: 1,
      isTitleShow: true,
      webId: localStorage.getItem("webId"),
      navMapId: "",
    });
    setLoaderShow(false);
    setSettingFormData({
      ".counter-section": {
        padding: "100px 0",
        "background-color": "rgb(0 0 0 / 100)",
      },
      ".counter-section.bg-image": {
        "background-image": "",
      },
      ".counter-section.bg-image::before": {
        "background-color": "",
      },
      ".counter-section .section-title-area": {
        "margin-bottom": "50px",
      },
      ".counter-section .section-title-area .section-subtitle": {
        "font-size": "16px",
        "font-weight": "600",
        color: "rgb(255 73 124 / 100)",
        "background-color": "rgb(255 73 124 / 0.2)",
        padding: "5px 20px",
        p1: "5px",
        p2: "20px",
        "border-radius": "15px",
        "margin-bottom": "15px",
      },
      ".counter-section .section-title-area .section-title": {
        "font-size": "44px",
        "font-weight": "600",
        color: "rgb(255 255 255 / 100)",
      },
      ".counter-section .countdiv": {
        "text-align": "center",
        "margin-bottom": "30px",
      },
      ".counter-section .countdiv .counter": {
        color: "rgb(255 73 124 / 100)",
        "font-size": "40px",
        "font-weight": "600",
      },
      ".counter-section .countdiv .divider": {
        width: "80px",
        height: "4px",
        margin: "15px auto",
        "border-radius": "3px",
        "background-color": "rgb(255 255 255 / 100)",
      },
      ".counter-section .countdiv .counter-title": {
        color: "rgb(255 255 255 / 100)",
        "font-size": "22px",
        "font-weight": "500",
      },
      serviceCounterId: "",
    });
  };

  const handleAlignAndCloneSection = ({ isClone = false }) => {
    if (isClone) {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          cloneModal: true,
        };
      });
      setCloneFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          pageConfigDetailsLabel: pageConfigDetails[0]?.label,
          contentDetailName: contentDetail.name,
        };
      });
    } else {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          alignModal: true,
        };
      });
      setAlignFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          label: pageConfigDetails[0]?.label,
        };
      });
    }
  };
  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody
            className="support-ticket-font box-border-div"
            style={{ padding: "20px" }}
          >
            <Row>
              <CardBuilderHeaderSection
                addBtnTitle={pageConfigDetails[0]?.label}
                name={pageConfigDetails[0]?.label}
                isAdd={!serviceCounterDetail && true}
                mainEditSection={true}
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
                openModal={() => {
                  OpenModalCommonFunc(
                    Cases.SERVICE_COUNTER_PARENT,
                    setAllPopupState
                  );
                  setSectionPageName(modalsName.SERVICE_COUNTER_PARENT);
                  emptyData();
                  setIsParent(true);
                  setCollId(contentMapId);
                }}
                isCloneSection={true}
                isAlignSection={true}
                handleAlignAndCloneSection={handleAlignAndCloneSection}
              />
              {serviceCounterDetail && (
                <Col xl="12 xl-50 box-col-6" md="6">
                  <Card className="ecommerce-widget mb-0">
                    <CardBody
                      className="support-ticket-font"
                      style={{ padding: "20px" }}
                    >
                      <div className="col-12">
                        <CardBuilderHeaderSection
                          name={serviceCounterDetail?.title}
                          checked={serviceCounterDetail?.isActive}
                          switchToggle={true}
                          id={serviceCounterDetail?.serviceCounterId}
                          handleChange={handleChangeServiceCounter}
                        />
                        <OpenPopupBtn
                          openPopup={() => {
                            getServiceCounterParentList({
                              parentId: serviceCounterDetail.serviceCounterId,
                            });
                            setSectionPageName(serviceCounterDetail?.title);
                            setNavMapId(serviceCounterDetail?.navMapId);
                            setFilter("");
                            setNavMapId(serviceCounterDetail?.navMapId);
                            setCollId(contentMapId);
                          }}
                          name={`Add or View ${serviceCounterDetail?.title}`}
                        />
                      </div>
                      <div className="d-flex justify-content-end ">
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleEditServiceCounter(
                              serviceCounterDetail.serviceCounterId,
                              true
                            );
                            setCollId(contentMapId);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() =>
                            handleDelete(
                              serviceCounterDetail.serviceCounterId,
                              true
                            )
                          }
                        >
                          <Trash />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleChangeSettings(
                              serviceCounterDetail.serviceCounterId
                            );
                            setTheme(serviceCounterDetail?.theme || "");
                          }}
                        >
                          <Settings />
                        </IconButton>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
              <DeleteSection
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
              />
            </Row>
          </CardBody>
        </Card>
      </Col>
      <CommonPopup
        open={allPopupState.serviceCounterChildPopup}
        close={() => {
          OpenModalCommonFunc(Cases.SERVICE_COUNTER, setAllPopupState, false);
          setIsParent(false);
          getServiceCounterParentList(null, true);
        }}
        apiCall={getServiceCounterParentList}
        modalName={
          sectionPageName ? sectionPageName : modalsName.SERVICE_COUNTER
        }
        modalCase={Cases.SERVICE_COUNTER}
        handleSubmit={handleSubmit}
        validationForm={"childForm"}
        emptyData={emptyData}
        inputData={fromData}
        edit={false}
        oldTitle={titleUpdateKey.isGallery}
        label={titleUpdateKey.Certificates}
        getData={getData}
        content={
          <ServiceCounterPopup
            getServiceCounterParentList={getServiceCounterParentList}
            handleChangeStatus={handleChangeServiceCounter}
            handleEdit={handleEditServiceCounter}
            imageUpload={imageUploadImages}
            emptyData={emptyData}
            simpleValidator={childValidator}
            webMasterDetail={webMasterDetail}
            handleDelete={handleDelete}
            theme={serviceCounterDetail?.theme}
          />
        }
      />

      {/* item Service Counter parent popup */}
      <CommonPopup
        open={allPopupState.serviceCounterPopup}
        close={() => {
          OpenModalCommonFunc(
            Cases.SERVICE_COUNTER_PARENT,
            setAllPopupState,
            false
          );
          setIsParent(false);
        }}
        modalName={
          sectionPageName ? sectionPageName : modalsName.SERVICE_COUNTER_PARENT
        }
        modalCase={Cases.SERVICE_COUNTER_PARENT}
        handleSubmit={handleSubmit}
        validationForm={"parentForm"}
        emptyData={emptyData}
        inputData={formDataChild}
        edit={false}
        content={
          <ServiceCounterParentPopup simpleValidator={parentValidator} />
        }
      />

      {/* setting Service Counter parent popup */}
      <CommonPopup
        open={allPopupState.serviceCOunterSettingPopup}
        close={() =>
          OpenModalCommonFunc(
            Cases.SETTING_SERVICECOUNTER_PARENT,
            setAllPopupState,
            false
          )
        }
        modalName={
          sectionPageName
            ? sectionPageName
            : modalsName.SETTING_SERVICECOUNTER_PARENT
        }
        modalCase={Cases.SETTING_SERVICECOUNTER_PARENT}
        handleSubmit={handleSubmitSetting}
        emptyData={emptyData}
        inputData={formDataChild}
        edit={false}
        content={
          <SettingImageParentPopup
            imageUpload={backImageUpload}
            handleSubmitSetting={handleSubmitSetting}
            handleChangeSettings={handleChangeSettings}
          />
        }
      />
    </>
  );
};

export default ServiceCounter;
