import React, { useContext, useMemo, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import { Cases, Currency, btnName, modalsName } from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import Lightbox from "react-image-lightbox";
import CommonPopup from "../../../Modals/commonPopup";
import ProductsPopup from "./productsPopup";
import {
  getProduct,
  getProductDetails,
  postProduct,
  putUpdateProduct,
  updateStatus,
} from "../../../apis/ProductApi/ProductApi";
import { FILE_UPLOAD_API } from "../../../apis/SliderApi/SliderApi";
import { splitPathWeb } from "../../../apiConstants";
import ProductContext from "../../../context/ProductContext/ProductContext";
import { FILE_TYPE, titleUpdateKey } from "../../../../constant";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import SimpleReactValidator from "simple-react-validator";

const Products = ({ dataProduct, webMasterDetail, navbarData, getData }) => {
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const {
    apiData: productData,
    setApiData,
    fromData,
    setFormData,
    id,
    setId,
    setIsEdit,
    isEdit,
    filter,
    images,
    setImages,
    index,
  } = useContext(ProductContext);
  const { setLoaderShow } = useContext(LoaderContext);
  const [open, setOpen] = useState(false);

  /**
   * get product list
   */
  const getSliderList = async (status) => {
    try {
      if (!filter.length && status) {
        setApiData((prev) => {
          return { ...prev, isLoading: true };
        });
      }
      let paramObj = {
        search: { title: filter },
        showAll: true,
      };
      const { data } = await getProduct(paramObj);
      if (data.status === 200) {
        setApiData({
          ...productData,
          isLoading: false,
          data: data.payload.data.map((elem) => {
            return {
              id: elem?.title + elem?.productId,
              Id: elem?.productId,
              name: elem?.title,
              image: elem?.defaultImage?.path,
              webId: elem?.webId,
              des: elem?.description,
              createdAt: elem?.createdAt,
              isActive: elem?.isActive,
            };
          }),
        });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };
  useMemo(() => {
    if (allPopupState?.productsPopup) {
      getSliderList();
    }
  }, [filter, allPopupState?.productsPopup]);

  /**
   * enable disable api call
   * @param {product id} productId
   * @param {status} isActive
   */

  const handleChangeStatus = async (productId, isActive) => {
    try {
      let body = {
        productId,
        isActive: isActive ? false : true,
      };
      const { data } = await updateStatus(body);
      if (data.status === 200) {
        getSliderList(false);
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * upload image
   * @param {file} file
   */
  const imageUpload = async (file) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.BYTE_SLIDER);
    formData.append("file", file);
    setLoaderShow(true);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        setImages((prev) => {
          let newData = [...prev];
          newData[index] = {
            path: data.payload.baseUrl + data.payload.imageUrl,
            isDefault: prev[index].isDefault,
          };
          return newData;
        });
        setFormData((prev) => {
          return {
            ...prev,
            image: "",
          };
        });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * product create and update
   */
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else if (images.some((item) => item.path !== "")) {
      if (
        (fromData.price && fromData.currencySymbol) ||
        ((fromData.price == "" || fromData.price == null) &&
          (fromData.currencySymbol == "" || fromData.currencySymbol == null))
      ) {
        if (id >= -1 && isEdit) {
          setLoaderShow(true);
          let newData = {};
          setFormData(newData);
          newData = {
            ...fromData,
            images: images.map((elem) => {
              return {
                path: elem.path
                  ?.split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
                  .pop(),
                isDefault: elem.isDefault,
              };
            }),
            currencySymbol: fromData?.currencySymbol?.value || "",
          };
          try {
            const { data } = await putUpdateProduct(newData);
            if (data.status === 200) {
              getSliderList();
              emptyData();
              TostMessage({ message: data, type: "success" });
            }
          } catch (error) {
            TostMessage({ message: error, type: "error" });
            setLoaderShow(false);
          }
        } else {
          try {
            setLoaderShow(true);
            let newData = {};
            setFormData(newData);
            newData = {
              ...fromData,
              images: images.map((elem, i) => {
                return {
                  path: elem.path
                    .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
                    .pop(),
                  isDefault: elem.isDefault,
                  order: i + 1,
                };
              }),
              currencySymbol: fromData?.currencySymbol?.value,
              order: productData.data.length + 1,
            };
            if (newData.price == "" || newData.price == null) {
              delete newData.price;
            }
            if (
              newData.currencySymbol == "" ||
              newData.currencySymbol == null
            ) {
              delete newData.currencySymbol;
            }
            const { data } = await postProduct(newData);
            if (data.status === 200) {
              getSliderList();
              emptyData();
              TostMessage({ message: data, type: "success" });
            }
          } catch (error) {
            TostMessage({ message: error, type: "error" });
            setLoaderShow(false);
          }
        }
      } else {
        TostMessage({
          message: "Please enter value price and currency",
          type: "error",
          position: "custome",
        });
      }
    } else {
      TostMessage({
        message: "Please upload the image",
        type: "error",
        position: "custome",
      });
    }
  };

  /**
   * get product details
   * @param {id} productId
   */
  const handleEdit = async (productId) => {
    try {
      setLoaderShow(true);
      setId(productId);
      const { data } = await getProductDetails(productId);
      if (data.status === 200) {
        let payload = data.payload.data;
        let value = Currency.filter(
          (elem) => elem.value === payload.currencySymbol
        );
        setFormData({
          title: payload.title,
          description: payload.description,
          image: payload.image,
          price: payload.price,
          productId: productId,
          currencySymbol: value.length && value[0],
          webId: localStorage.getItem("webId"),
        });
        if (payload.products.length < 4) {
          setImages((prev) => [
            ...payload.products.map((elem) => {
              return {
                path: elem.path,
                isDefault: elem.isDefault,
              };
            }),
            ...prev.slice(payload.products.length),
          ]);
        } else {
          setImages(
            payload.products.map((elem) => {
              return {
                path: elem.path,
                isDefault: elem.isDefault,
              };
            })
          );
        }
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * data clear
   */
  const emptyData = () => {
    setId("");
    setIsEdit(false);
    setFormData({
      title: "",
      description: "",
      image: "",
      price: "",
      currencySymbol: "",
      webId: localStorage.getItem("webId"),
    });
    setImages([
      { path: "", isDefault: true },
      { path: "", isDefault: false },
      { path: "", isDefault: false },
      { path: "", isDefault: false },
    ]);
    setLoaderShow(false);
  };

  const filterValue = () => {
    let [value] = navbarData.filter(
      (elem) => elem.navbarDetail.label === titleUpdateKey.Product
    );
    return value;
  };
  const value = useMemo(() => filterValue(), [navbarData]);
  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody className="support-ticket-font box-border-div">
            <Row>
              <div className="col-12">
                {/* <CardBuilderHeaderSection name={"Products"} /> */}
                <CardBuilderHeaderSection name={value?.label || "Products"} />
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => setOpen(!open)}
                >
                  <div
                    style={{
                      objectFit: "contain",
                      maxWidth: "125px",
                      height: "83px",
                      border: "1px solid #d3d3d3",
                      width: "100%",
                      display: "inline-block",
                      verticalAlign: "middle",
                      overflow: "hidden",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        bottom: "0",
                        left: "0",
                        borderRadius: "inherit",
                      }}
                    >
                      <img
                        src={dataProduct?.defaultImage?.path}
                        alt=""
                        style={{
                          objectPosition: "50% 50%",
                          objectFit: "contain",
                          opacity: "1",
                          transition: "opacity 0.28s ease-in",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <OpenPopupBtn
                openPopup={() =>
                  OpenModalCommonFunc(Cases.PRODUCTS, setAllPopupState)
                }
                name={btnName.PRODUCTS}
              />
            </Row>
          </CardBody>
        </Card>
      </Col>

      {open && (
        <Lightbox
          mainSrc={dataProduct?.defaultImage?.path}
          onCloseRequest={() => setOpen(false)}
        />
      )}

      <CommonPopup
        open={allPopupState.productsPopup}
        close={() =>
          OpenModalCommonFunc(Cases.PRODUCTS, setAllPopupState, false)
        }
        isMini={true}
        modalName={modalsName.PRODUCTS}
        modalCase={Cases.PRODUCTS}
        handleSubmit={handleSubmit}
        emptyData={emptyData}
        inputData={fromData}
        edit={true}
        oldTitle={titleUpdateKey.isProduct}
        label={titleUpdateKey.Product}
        getData={getData}
        content={
          <ProductsPopup
            handleChangeStatus={handleChangeStatus}
            getData={getSliderList}
            handleEdit={handleEdit}
            imageUpload={imageUpload}
            emptyData={emptyData}
            simpleValidator={simpleValidator}
            webMasterDetail={webMasterDetail}
          />
        }
      />
    </>
  );
};

export default Products;
