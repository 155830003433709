import React, { useContext } from "react";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { Cases, DELETE_CONFIRM } from "../../../constant";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import ItemsDragAndDrops from "../../../common/ItemsDragAndDrops";
import Editor from "../../../common/editor";
import { maxLengthCheck, slugUrlConverter } from "../../../../utils/helper";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import { delete_Multi_Image_Permanent } from "../apis/SliderApi/SliderApi";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import Dividers from "../../../common/divider";
import { UploadCloud } from "react-feather";
import {
  deleteService,
  sort_order_service,
} from "../apis/ServiceAndCategoryApi/ServiceAndCategoryApi";
import ServiceAndCategoryContext from "../../../context/ServiceAndCatagoryContext/ServiceAndCategoryContext";
import { serviceTheme } from "../../../../constant";

const ServicePopup = (props) => {
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const {
    handleChangeStatus,
    getService,
    imageUpload,
    handleEdit,
    emptyData,
    simpleValidator,
    setIsUpload,
  } = props;
  const {
    apiData,
    setApiData,
    fromData,
    setFormData,
    setFilter,
    filter,
    isEdit,
    theme,
    serviceSearchId,
  } = useContext(ServiceAndCategoryContext);
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * drag and drop
   * @param {drag data} result
   * @returns
   */
  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;
    const items = Array.from(apiData.data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setApiData({ ...apiData, data: items });
    try {
      let itemOrder = [];
      for (const item in items) {
        itemOrder.push({ serviceId: items[item].Id, order: +item + 1 });
      }
      const { data } = await sort_order_service(itemOrder);
      if (data.status === 200) {
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * delete service
   * @param {id} blogDetailId
   */
  const handleDelete = async (serviceId) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await deleteService(serviceId);
        if (data.status === 200) {
          getService({
            serviceCategoryId: serviceSearchId,
          });
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * create new blog detail
   */
  const hanldenewCreate = () => {
    emptyData();
  };

  /**
   * reupload image
   */
  const reupload = () => {
    setFormData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };

  const handleAboutus = (event) => {
    setFormData((prev) => {
      return {
        ...prev,
        description: event,
      };
    });
  };

  const InputValueChange = (e) => {
    const { value, name, checked } = e.target;
    setFormData((prev) => {
      if (name === "isTitleShow") {
        return {
          ...prev,
          [name]: checked,
        };
      } else if (name === "title") {
        return {
          ...prev,
          [name]: value,
          slugUrl: slugUrlConverter({ value }),
        };
      } else if (name === "slugUrl") {
        return {
          ...prev,
          [name]: slugUrlConverter({ value }),
        };
      } else {
        return {
          ...prev,
          [name]: value,
        };
      }
    });
  };

  /**
   * delete image permanently
   */
  const deleteImagePermanent = async (imgName) => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData = {
          fileName: fromData[`${imgName}`]
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
            .pop(),
        };
        const { data } = await delete_Multi_Image_Permanent(formData);
        if (data.status === 200) {
          setFormData((prev) => {
            return {
              ...prev,
              [`${imgName}`]: "",
              image: "",
            };
          });
          TostMessage({ message: data, type: "success", position: "center" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="6">
            <div>
              <h6>Total Count : {apiData?.data?.length}</h6>
              <Row>
                <Col md="6">
                  <Input
                    className="form-control mt-3 "
                    name="search"
                    type="text"
                    placeholder="search"
                    style={{ borderRadius: "0", color: "black" }}
                    value={filter}
                    onChange={(e) => {
                      getService({
                        filter: e.target.value,
                        serviceCategoryId: serviceSearchId,
                      });
                      setFilter(e.target.value);
                    }}
                  />
                </Col>
                <Col
                  md="6"
                  className="d-flex justify-content-center align-items-center mt-3"
                >
                  <Button
                    color="primary"
                    className="rounded-0"
                    onClick={() => hanldenewCreate()}
                  >
                    Add New
                  </Button>
                </Col>
              </Row>
              {apiData.isLoading ? (
                <div className="mt-4">
                  <h6>Loading ...</h6>
                </div>
              ) : (
                <div className="mt-4">
                  <ItemsDragAndDrops
                    handleOnDragEnd={handleOnDragEnd}
                    characters={apiData?.data}
                    handleChangeStatus={handleChangeStatus}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                  />
                </div>
              )}
            </div>
          </Col>
          <Col sm="12" xl="6">
            <div className="mt-4 d-flex flex-column justify-content-center ">
              <h6>Add New</h6>
              <Row className="mt-4">
                <Col md="6 mb-1">
                  <Label htmlFor="validationCustom03">Title * </Label>
                  <Input
                    className="form-control"
                    name="title"
                    type="text"
                    placeholder="Title *"
                    value={fromData.title}
                    onChange={(e) => InputValueChange(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    min={0}
                    maxLength={50}
                    onInput={maxLengthCheck}
                  />
                  <div className="text-end mt-3 ">
                    <span>{fromData.title.length} / 50</span>
                  </div>
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "title",
                      fromData.title,
                      "required"
                    )}
                  </p>
                </Col>
                <Col md="6 mb-1">
                  <Label htmlFor="validationCustom03">{"Slug Url *"}</Label>
                  <Input
                    className="form-control"
                    name="slugUrl"
                    type="text"
                    placeholder="Slug Url *"
                    value={fromData.slugUrl}
                    onChange={(e) => InputValueChange(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "slugUrl",
                      fromData.slugUrl,
                      "required"
                    )}
                  </p>
                </Col>
                <Col md="6 mb-3">
                  <Label htmlFor="validationCustom03">{"HeadLine "}</Label>
                  <Input
                    className="form-control"
                    name="headLine"
                    type="text"
                    value={fromData.headLine}
                    onChange={(e) => InputValueChange(e)}
                    placeholder="HeadLine"
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="6 mb-3">
                  <Label htmlFor="validationCustom03">{"Button Text "}</Label>
                  <Input
                    className="form-control"
                    name="buttonText"
                    type="text"
                    value={fromData.buttonText}
                    onChange={(e) => InputValueChange(e)}
                    placeholder="Button Text"
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Short Description "}
                  </Label>
                  <textarea
                    className="form-control"
                    name="shortDescription"
                    type="text"
                    value={fromData.shortDescription}
                    onChange={(e) => InputValueChange(e)}
                    placeholder="Short Description"
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Description "}</Label>
                  <Editor
                    value={fromData.description}
                    onChange={handleAboutus}
                  />
                </Col>
              </Row>
              <Dividers divide="IMAGES" />
              <p className="mb-4">
                File type required JPG, PNG{" "}
                {theme === serviceTheme.theme2
                  ? "(304px X 383px)"
                  : "(512px X 512px)"}
              </p>
              <Row>
                <Col md="6 mb-3">
                  <span>Thumbnail Image *</span>
                  <div
                    className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    {!fromData.imageThumbnail && (
                      <span
                        onClick={() => {
                          OpenModalCommonFunc(
                            Cases.PRODUCT_UPLOAD,
                            setAllPopupState
                          );
                          setIsUpload(true);
                          setFormData((prev) => {
                            return {
                              ...prev,
                              image: "",
                            };
                          });
                        }}
                      >
                        <UploadCloud />
                      </span>
                    )}

                    {fromData.imageThumbnail && (
                      <img
                        src={
                          fromData.imageThumbnail ? fromData.imageThumbnail : ""
                        }
                        alt=""
                        style={{
                          width: "80%",
                          height: "80%",
                          objectFit: "contain",
                          position: "absolute",
                        }}
                        onClick={() => {
                          deleteImagePermanent("imageThumbnail");
                        }}
                      />
                    )}
                    {fromData.imageThumbnail && (
                      <>
                        {" "}
                        <IconButton
                          edge="start"
                          color="inherit"
                          aria-label="close"
                          style={{
                            color: "red",
                            position: "absolute",
                            top: "-3px",
                            left: "1px",
                          }}
                          onClick={() => {
                            deleteImagePermanent("imageThumbnail");
                          }}
                        >
                          <Close
                            style={{ fontSize: "25px", fontWeight: "bold" }}
                          />
                        </IconButton>
                      </>
                    )}
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "Image Thumbnail",
                        fromData.imageThumbnail,
                        "required"
                      )}
                    </p>
                  </div>
                </Col>
                <Col md="6 mb-3">
                  <span>Details Image </span>
                  <div
                    className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    {!fromData.logo && (
                      <span
                        onClick={() => {
                          OpenModalCommonFunc(
                            Cases.PRODUCT_UPLOAD,
                            setAllPopupState
                          );
                          setIsUpload(false);
                          setFormData((prev) => {
                            return {
                              ...prev,
                              image: "",
                            };
                          });
                        }}
                      >
                        <UploadCloud />
                      </span>
                    )}

                    {fromData.logo && (
                      <img
                        src={fromData.logo ? fromData.logo : ""}
                        alt=""
                        style={{
                          width: "80%",
                          height: "80%",
                          objectFit: "contain",
                          position: "absolute",
                        }}
                        onClick={() => {
                          deleteImagePermanent("logo");
                        }}
                      />
                    )}
                    {fromData.logo && (
                      <>
                        {" "}
                        <IconButton
                          edge="start"
                          color="inherit"
                          aria-label="close"
                          style={{
                            color: "red",
                            position: "absolute",
                            top: "-3px",
                            left: "1px",
                          }}
                          onClick={() => {
                            deleteImagePermanent("logo");
                          }}
                        >
                          <Close
                            style={{ fontSize: "25px", fontWeight: "bold" }}
                          />
                        </IconButton>
                      </>
                    )}
                    {/* <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "image",
                        fromData.logo,
                        "required"
                      )}
                    </p> */}
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <ProductImageUploadPopup
          allPopupState={allPopupState}
          setAllPopupState={setAllPopupState}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
          image={fromData.image}
          setImage={setFormData}
          imageUpload={imageUpload}
          isEdit={isEdit}
          reupload={reupload}
          // aspect={
          //   theme == serviceTheme.theme3 ||
          //   theme == serviceTheme.theme4 ||
          //   theme == serviceTheme.theme1
          //     ? 1 / 1
          //     : theme == serviceTheme.theme2
          //     ? 0.8 / 1
          //     : ""
          // }
        />
      </Container>
    </>
  );
};

export default ServicePopup;
