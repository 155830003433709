import {
  addUrlImage,
  convertRGBA,
  removeUrlImage,
  removeUrlKeyInPath,
  rgbColorCreate,
} from "../../../../utils/helper";

export const CareerStyleOneReset = {
  ".career-section": {
    padding: "100px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".career-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".career-section.bg-image": {},
  ".career-section.bg-image::before": {
    // "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
  },
  ".career-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: { r: 255, g: 73, b: 124, a: 100 },
    display: "inline-block",
    "background-color": { r: 255, g: 73, b: 124, a: 0.2 },
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".career-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: { r: 0, g: 0, b: 0, a: 100 },
  },
  ".career-section .feature-list": {
    padding: "20px",
    margin: "10px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
    "box-shadow": "0 0px 15px { r: 0, g: 0, b: 0, a: 0.188 }",
    bs1: "0px",
    bs2: "0px",
    bs3: "15px",
    shadowColor: { r: 0, g: 0, b: 0, a: 0.188 },
    "border-radius": "0px",
  },
  ".career-section .feature-list h5": {
    "font-size": "20px",
    "font-weight": "600",
    color: { r: 255, g: 73, b: 124, a: 100 },
    "margin-bottom": "10px",
  },
  ".career-section .feature-list p": {
    "font-size": "16px",
    "font-weight": "normal",
    color: { r: 102, g: 102, b: 102, a: 100 },
    "line-height": "1.4",
    "text-align": "justify",
  },
  ".contact-form-div .btn.btn-submit": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    "border-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  careerId: "",
};

export const CareerThemeOneFunction = (payload) => {
  return {
    ".career-section": {
      padding: payload.style?.[".career-section"]?.["padding"],
      "background-color": payload.style?.[".career-section"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString: payload.style?.[".career-section"]?.["background-color"],
          })
        : "",
      width: payload.style?.[".career-section"]?.["width"]?.replace(
        "calc(100% - ",
        ""
      ),
      margin: payload.style?.[".career-section"]?.["margin"],
      "border-radius": payload.style?.[".career-section"]?.["border-radius"],
    },
    ".career-section .section-title-area": {
      "margin-bottom":
        payload.style?.[".career-section .section-title-area"]?.[
          "margin-bottom"
        ],
    },
    ".career-section.bg-image": {
      "background-image": payload.style?.[".career-section.bg-image"]?.[
        "background-image"
      ]
        ? removeUrlKeyInPath({
            urlString:
              payload.style?.[".career-section.bg-image"]?.["background-image"],
          })
        : "",
    },
    ".career-section.bg-image::before": {
      "background-color": payload.style?.[".career-section.bg-image::before"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".career-section.bg-image::before"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".career-section .section-title-area .section-subtitle": {
      "font-size":
        payload.style?.[
          ".career-section .section-title-area .section-subtitle"
        ]?.["font-size"],
      "font-weight":
        payload.style?.[
          ".career-section .section-title-area .section-subtitle"
        ]?.["font-weight"],
      color: payload.style?.[
        ".career-section .section-title-area .section-subtitle"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".career-section .section-title-area .section-subtitle"
              ]?.["color"],
          })
        : "",
      display:
        payload.style?.[
          ".career-section .section-title-area .section-subtitle"
        ]?.["display"],
      "background-color": payload.style?.[
        ".career-section .section-title-area .section-subtitle"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".career-section .section-title-area .section-subtitle"
              ]?.["background-color"],
          })
        : "",
      padding:
        payload.style?.[
          ".career-section .section-title-area .section-subtitle"
        ]?.["padding"],
      p1: payload.style?.[
        ".career-section .section-title-area .section-subtitle"
      ]?.["padding"]?.split(" ")[0],
      p2: payload.style?.[
        ".career-section .section-title-area .section-subtitle"
      ]?.["padding"]?.split(" ")[1],
      "border-radius":
        payload.style?.[
          ".career-section .section-title-area .section-subtitle"
        ]?.["border-radius"],
      "margin-bottom":
        payload.style?.[
          ".career-section .section-title-area .section-subtitle"
        ]?.["margin-bottom"],
    },
    ".career-section .section-title-area .section-title": {
      "font-size":
        payload.style?.[".career-section .section-title-area .section-title"]?.[
          "font-size"
        ],
      "font-weight":
        payload.style?.[".career-section .section-title-area .section-title"]?.[
          "font-weight"
        ],
      color: payload.style?.[
        ".career-section .section-title-area .section-title"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".career-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".career-section .feature-list": {
      padding: payload.style?.[".career-section .feature-list"]?.["padding"],
      margin: payload.style?.[".career-section .feature-list"]?.["margin"],
      "background-color": payload.style?.[".career-section .feature-list"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".career-section .feature-list"]?.[
                "background-color"
              ],
          })
        : "",
      "box-shadow":
        payload.style?.[".career-section .feature-list"]?.["box-shadow"],
      bs1: payload.style?.[".career-section .feature-list"]?.[
        "box-shadow"
      ]?.split(" ")[0],
      bs2: payload.style?.[".career-section .feature-list"]?.[
        "box-shadow"
      ]?.split(" ")[1],
      bs3: payload.style?.[".career-section .feature-list"]?.[
        "box-shadow"
      ]?.split(" ")[2],
      shadowColor: payload.style?.[".career-section .feature-list"]?.[
        "shadowColor"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".career-section .feature-list"]?.["shadowColor"],
          })
        : "",
      "border-radius":
        payload.style?.[".career-section .feature-list"]?.["border-radius"],
    },
    ".career-section .feature-list h5": {
      "font-size":
        payload.style?.[".career-section .feature-list h5"]?.["font-size"],
      "font-weight":
        payload.style?.[".career-section .feature-list h5"]?.["font-weight"],
      "margin-bottom":
        payload.style?.[".career-section .feature-list h5"]?.["margin-bottom"],
      color: payload.style?.[".career-section .feature-list h5"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".career-section .feature-list h5"]?.["color"],
          })
        : "",
    },
    ".career-section .feature-list p": {
      "font-size":
        payload.style?.[".career-section .feature-list p"]?.["font-size"],
      "font-weight":
        payload.style?.[".career-section .feature-list p"]?.["font-weight"],
      color: payload.style?.[".career-section .feature-list p"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".career-section .feature-list p"]?.["color"],
          })
        : "",
      "line-height":
        payload.style?.[".career-section .feature-list p"]?.["line-height"],
      "text-align":
        payload.style?.[".career-section .feature-list p"]?.["text-align"],
    },
    ".contact-form-div .btn.btn-submit": {
      color: payload.style?.[".contact-form-div .btn.btn-submit"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".contact-form-div .btn.btn-submit"]?.["color"],
          })
        : "",
      "background-color": payload.style?.[
        ".contact-form-div .btn.btn-submit"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".contact-form-div .btn.btn-submit"]?.[
                "background-color"
              ],
          })
        : "",
      "border-color": payload.style?.[".contact-form-div .btn.btn-submit"]?.[
        "border-color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".contact-form-div .btn.btn-submit"]?.[
                "border-color"
              ],
          })
        : "",
    },
    custom: payload?.style?.custom,
  };
};

export const CareerStyleOneSubFun = ({ settingFormData, boxSection }) => {
  let newData = {
    ...settingFormData,
    ".career-section": boxSection
      ? {
          ...settingFormData[".career-section"],
          padding: `${settingFormData?.[".career-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
          width: `calc(100% - ${settingFormData?.[".career-section"]?.[
            "width"
          ]?.replace("rem)", "")}rem)`,
          margin: `${settingFormData?.[".career-section"]?.["margin"]
            ?.split(" ")[0]
            ?.replace("px", "")}px auto`,
          "border-radius": `${settingFormData?.[".career-section"]?.[
            "border-radius"
          ]?.replace("rem", "")}rem`,
        }
      : {
          ...settingFormData?.[".career-section"],
          padding: `${settingFormData?.[".career-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
        },
    ".career-section .section-title-area": {
      ...settingFormData[".career-section .section-title-area"],
      "margin-bottom": `${settingFormData?.[
        ".career-section .section-title-area"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".career-section.bg-image": settingFormData[".career-section.bg-image"][
      "background-image"
    ]
      ? {
          ...settingFormData[".career-section.bg-image"],
          "background-image": settingFormData?.[".career-section.bg-image"]?.[
            "background-image"
          ]
            ? addUrlImage({
                urlString: removeUrlImage({
                  urlString:
                    settingFormData[".career-section.bg-image"][
                      "background-image"
                    ],
                }),
              })
            : "",
          "background-repeat": "no-repeat",
          "background-size": "cover",
        }
      : {},
    ".career-section.bg-image::before": settingFormData?.[
      ".career-section.bg-image::before"
    ]?.["background-color"]
      ? {
          ...settingFormData?.[".career-section.bg-image::before"],
          "background-color": settingFormData?.[
            ".career-section.bg-image::before"
          ]?.["background-color"]
            ? rgbColorCreate({
                rgbString:
                  settingFormData[".career-section.bg-image::before"][
                    "background-color"
                  ],
              })
            : "",
        }
      : {},
    ".career-section .section-title-area .section-subtitle": {
      ...settingFormData[
        ".career-section .section-title-area .section-subtitle"
      ],
      "font-size": `${settingFormData?.[
        ".career-section .section-title-area .section-subtitle"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".career-section .section-title-area .section-subtitle"]?.["font-weight"]}`,
      color: settingFormData[
        ".career-section .section-title-area .section-subtitle"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".career-section .section-title-area .section-subtitle"
              ]?.["color"],
          })
        : "",
      display: `${settingFormData?.[".career-section .section-title-area .section-subtitle"]?.["display"]}`,
      "background-color": settingFormData?.[
        ".career-section .section-title-area .section-subtitle"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".career-section .section-title-area .section-subtitle"
              ]?.["background-color"],
          })
        : "",
      padding: `${settingFormData?.[
        ".career-section .section-title-area .section-subtitle"
      ]?.["p1"]?.replace("px", "")}px ${settingFormData?.[
        ".career-section .section-title-area .section-subtitle"
      ]?.["p2"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData?.[
        ".career-section .section-title-area .section-subtitle"
      ]?.["border-radius"]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData?.[
        ".career-section .section-title-area .section-subtitle"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".career-section .section-title-area .section-title": {
      ...settingFormData[".career-section .section-title-area .section-title"],
      "font-size": `${settingFormData?.[
        ".career-section .section-title-area .section-title"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".career-section .section-title-area .section-title"]?.["font-weight"]}`,
      color: settingFormData?.[
        ".career-section .section-title-area .section-title"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".career-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".career-section .feature-list": {
      ...settingFormData[".career-section .feature-list"],
      padding: `${settingFormData?.[".career-section .feature-list"]?.[
        "padding"
      ]?.replace("px", "")}px`,
      margin: `${settingFormData?.[".career-section .feature-list"]?.["margin"]
        ?.split(" ")[0]
        ?.replace("px", "")}px`,
      "background-color": settingFormData?.[".career-section .feature-list"]?.[
        "background-color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".career-section .feature-list"]?.[
                "background-color"
              ],
          })
        : "",
      "box-shadow": `${settingFormData?.[".career-section .feature-list"]?.[
        "bs1"
      ]?.replace("px", "")}px ${settingFormData?.[
        ".career-section .feature-list"
      ]?.["bs2"]?.replace("px", "")}px ${settingFormData?.[
        ".career-section .feature-list"
      ]?.["bs3"]?.replace("px", "")}px ${rgbColorCreate({
        rgbString:
          settingFormData?.[".career-section .feature-list"]?.["shadowColor"],
      })}`,
      "border-radius": `${settingFormData?.[".career-section .feature-list"]?.[
        "border-radius"
      ]?.replace("px", "")}px`,
    },
    ".career-section .feature-list h5": {
      ...settingFormData[".career-section .feature-list h5"],
      "font-size": `${settingFormData?.[".career-section .feature-list h5"]?.[
        "font-size"
      ]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".career-section .feature-list h5"]?.["font-weight"]}`,
      color: settingFormData?.[".career-section .feature-list h5"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".career-section .feature-list h5"]?.["color"],
          })
        : "",
      "margin-bottom": `${settingFormData?.[
        ".career-section .feature-list h5"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".career-section .feature-list p": {
      ...settingFormData?.[".career-section .feature-list p"],
      "font-size": `${settingFormData?.[".career-section .feature-list p"]?.[
        "font-size"
      ]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".career-section .feature-list p"]?.["font-weight"]}`,
      color: settingFormData?.[".career-section .feature-list p"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".career-section .feature-list p"]?.["color"],
          })
        : "",
      "line-height": `${settingFormData?.[".career-section .feature-list p"]?.["line-height"]}`,
      "text-align": `${settingFormData?.[".career-section .feature-list p"]?.["text-align"]}`,
    },
    ".contact-form-div .btn.btn-submit": {
      ...settingFormData?.[".contact-form-div .btn.btn-submit"],
      color: settingFormData?.[".contact-form-div .btn.btn-submit"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".contact-form-div .btn.btn-submit"]?.["color"],
          })
        : "",
      "background-color": settingFormData?.[
        ".contact-form-div .btn.btn-submit"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".contact-form-div .btn.btn-submit"]?.[
                "background-color"
              ],
          })
        : "",
      "border-color": settingFormData?.[".contact-form-div .btn.btn-submit"]?.[
        "border-color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".contact-form-div .btn.btn-submit"]?.[
                "border-color"
              ],
          })
        : "",
    },
  };
  return newData;
};
