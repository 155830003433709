import React, { useContext, useState } from "react";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { IconButton } from "@material-ui/core";
import { Cases } from "../../../constant";
import { OpenModalCommonFunc } from "../../../utils";
import { Close } from "@material-ui/icons";
import {
  Button,
  Col,
  Container,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import Dividers from "../../../common/divider";
import { UploadCloud } from "react-feather";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import ItemsDragAndDrops from "../../../common/ItemsDragAndDrops";
import Editor from "../../../common/editor";

const PaymentDetailsQrPopup = () => {
  const { setAllPopupState, image } = useContext(ByteCardBuilderContext);
  const dragAndDropData = [
    {
      id: "card-1",
      name: "QR 1",
    },
    {
      id: "card-2",
      name: "QR 2",
    },
    {
      id: "card-3",
      name: "QR 3",
    },
    {
      id: "card-4",
      name: "QR 4",
    },
    {
      id: "card-5",
      name: "QR 5",
    },
  ];
  const [characters, updateCharacters] = useState(dragAndDropData);
  const [activeTab2, setActiveTab2] = useState("1");

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(characters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    updateCharacters(items);
  }
  return (
    <>
      <Container className="mb-5">
        <Nav className="border-tab" tabs>
          <NavItem>
            <NavLink
              className={activeTab2 === "1" ? "active" : ""}
              onClick={() => setActiveTab2("1")}
            >
              <i className="icofont icofont-coins"></i> {"Payment Details"}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab2 === "2" ? "active" : ""}
              onClick={() => setActiveTab2("2")}
            >
              <i className="icofont icofont-barcode"></i>
              {"QR Code"}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab2}>
          <TabPane className="fade show" tabId="1">
            <Row>
              <Editor />
            </Row>
            <div className="d-flex justify-content-between ">
              <div>
                <Button
                  color="danger"
                  onClick={() =>
                    OpenModalCommonFunc(
                      Cases.PAYMETDETAILS_QR,
                      setAllPopupState,
                      false
                    )
                  }
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button color="success">Submit</Button>
              </div>
            </div>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-around",
                  marginBottom: "3rem",
                }}
              >
                <div>
                  <h6>1 QR Codes</h6>
                  <p className="pt-2">You can add upto 25 Products</p>
                  <Row>
                    <Col md="6">
                      <Input
                        className="form-control mt-3 "
                        name="search"
                        type="text"
                        placeholder="search"
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </Col>
                    <Col
                      md="3"
                      className="d-flex justify-content-center  align-items-center "
                    ></Col>
                  </Row>
                  <div className="mt-4">
                    <ItemsDragAndDrops
                      handleOnDragEnd={handleOnDragEnd}
                      characters={characters}
                    />
                  </div>
                </div>
                <div className="mt-4 d-flex flex-column justify-content-center ">
                  <h6>Add New</h6>
                  <Row className="mt-4">
                    <Col md="12 mb-1">
                      <Label htmlFor="validationCustom03">{"Title *"}</Label>
                      <Input
                        className="form-control"
                        name="title"
                        type="text"
                        placeholder="Title *"
                        style={{ borderRadius: "0", color: "black" }}
                      />
                      <div className="text-end mt-3 ">
                        <span>0 / 50</span>
                      </div>
                    </Col>
                  </Row>
                  <Dividers divide="IMAGES" />
                  <p className="mb-4">File type required JPG, PNG (Max 1MB)</p>
                  <Row>
                    <Col md="6 mb-3">
                      <div
                        className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                        style={{ cursor: "pointer" }}
                      >
                        <span>
                          <UploadCloud />
                        </span>
                        <img
                          src={
                            image
                              ? image
                              : "https://api.bytecard.in/images/uploads/w-200/84ce7bf0be6b3e9d6cfe48f02038aa2e.jpg"
                          }
                          alt=""
                          style={{
                            width: "80%",
                            height: "98%",
                            objectFit: "contain",
                            position: "absolute",
                          }}
                          onClick={() =>
                            OpenModalCommonFunc(
                              Cases.PRODUCT_UPLOAD,
                              setAllPopupState
                            )
                          }
                        />
                        <IconButton
                          edge="start"
                          color="inherit"
                          aria-label="close"
                          style={{
                            color: "red",
                            position: "absolute",
                            top: "-22px",
                            left: "1px",
                          }}
                        >
                          <Close
                            style={{ fontSize: "25px", fontWeight: "bold" }}
                          />
                        </IconButton>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Row>
          </TabPane>
        </TabContent>
        <ProductImageUploadPopup />
      </Container>
    </>
  );
};

export default PaymentDetailsQrPopup;
