import axios from "axios";
import {
  ADD_CATEGORY_LIST,
  CATEGORY_LIST,
  DELETE_CATEGORY_LIST,
  DETAILS_CATEGORY_LIST,
  EDIT_CATEGORY_LIST,
  ORG_CATEGORY_MENU_PAGE_LIST_ENABLE_DESABLE,
} from "../../../constant/API constant";

export const CategoryList = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${CATEGORY_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const Add_Category_List = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${ADD_CATEGORY_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Edit_Category_List = (formData, id) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${EDIT_CATEGORY_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { menuCategoryId: id },
    }
  );
};

export const Delete_Category_List = (menuCategoryId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(
    `${process.env.REACT_APP_API_URL}${DELETE_CATEGORY_LIST}`,
    {
      headers: { Authorization: jwt_token },
      params: { menuCategoryId },
    }
  );
};

export const Ditails_Category_List = (id) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${DETAILS_CATEGORY_LIST}`, {
    headers: { Authorization: jwt_token },
    params: { menuCategoryId: id },
  });
};

export const Category_MenuPageList_Enable_Desable = (
  formData,
  menuCategoryId
) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${ORG_CATEGORY_MENU_PAGE_LIST_ENABLE_DESABLE}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { menuCategoryId },
    }
  );
};
