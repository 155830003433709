import * as React from "react";
import { useState } from "react";
import PrivillageMasterContext from "./PrivillageMasterContext";

const PrivillageMasterState = (props) => {
  const [formvalue, setformvalue] = useState([]); //API -->
  const [moduleList, setModuleList] = useState([]); //API -->
  const [moduleChaidList, setModuleChaildList] = useState([]); //API -->
  const [moduleAccessList, setModuleAccessList] = useState([]); //API -->

  const [name, setName] = useState("");
  const [method, setMethod] = useState("");
  const [perModule, setPerModule] = useState("");
  const [perModuleName, setPerModuleName] = useState("");
  const [chiModule, setChiModule] = useState("");
  const [chiModuleName, setChiModuleName] = useState("");
  const [chialdperModule, setChialdPerModule] = useState("");
  const [modualeName, setmodualName] = useState("");
  const [modualeId, setmodualId] = useState("");
  const [moduleAccess, setModualAccess] = useState([]);
  const [urls, setUrls] = useState("");

  const [uuid, setUuid] = useState("");

  const [ahoeDataModel, setahoeDataModel] = useState(false); //SHOW ORG DETAILS MODEL -->
  const [modal, setModal] = useState(false); //ADD DATA MODEL -->
  const [addbel, setAddBel] = useState(false); //ADD ORG BEL -->
  const [pageNumber, setpageNumber] = useState(1); //PAGINATION -->
  const [recordsPerPage, setRecordsPerPage] = useState(10); //PAGINATION -->
  const [sortOrder, setSortOrder] = useState(""); //SORTING -->
  const [sortBy, setSortBy] = useState("createdAt"); //SORTING -->
  const [filter, setFilter] = useState(""); //SEARCHING FILTER -->
  const [isHovering, setIsHovering] = useState(false); //HOVWER SORTING -->
  const [isSwitchOn, setIsSwitchOn] = useState(false); //TOGGLE SWITCH -->
  const [url, setUrl] = useState("");
  const [editIndex, seteditIndex] = useState(-1); //EDIT -->
  const [totalRecord, setTotalRecord] = useState(); //TOTAL RECORD -->
  const [isAddHandle, setIsAddHandle] = useState(true); //PASS HIDE OR UNHIDE -->
  const [progress, setProgress] = useState(0);
  const [togglesubmit, setToggle] = useState(false);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchSuppliar, setsearchSuppliar] = useState("");

  const [childModule, setChildModule] = useState("");
  const [searchChild, setSearchChild] = useState("");

  const [showModule, setShowModule] = useState([]);

  return (
    <>
      <PrivillageMasterContext.Provider
        value={{
          formvalue,
          setformvalue,
          moduleList,
          setModuleList,
          moduleAccessList,
          setModuleAccessList,
          moduleChaidList,
          setModuleChaildList,

          name,
          setName,
          method,
          setMethod,
          perModule,
          setPerModule,
          urls,
          setUrls,

          ahoeDataModel,
          setahoeDataModel,
          modal,
          setModal,
          addbel,
          setAddBel,
          pageNumber,
          setpageNumber,
          recordsPerPage,
          setRecordsPerPage,
          sortOrder,
          setSortOrder,
          sortBy,
          setSortBy,
          filter,
          setFilter,
          isHovering,
          setIsHovering,
          isSwitchOn,
          setIsSwitchOn,
          url,
          setUrl,
          editIndex,
          seteditIndex,
          totalRecord,
          setTotalRecord,
          isAddHandle,
          setIsAddHandle,
          progress,
          setProgress,
          togglesubmit,
          setToggle,
          uuid,
          setUuid,

          isMenuOpen,
          setIsMenuOpen,
          searchSuppliar,
          setsearchSuppliar,

          childModule,
          setChildModule,
          searchChild,
          setSearchChild,
          chialdperModule,
          setChialdPerModule,
          moduleAccess,
          setModualAccess,

          showModule,
          setShowModule,
          perModuleName,
          setPerModuleName,
          chiModule,
          setChiModule,
          chiModuleName,
          setChiModuleName,
          modualeName,
          setmodualName,
          modualeId,
          setmodualId,
        }}
      >
        {props.children}
      </PrivillageMasterContext.Provider>
    </>
  );
};

export default PrivillageMasterState;
