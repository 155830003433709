import React, { useContext, useMemo, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
// import OpenPopupBtn from "../../common/OpenPopupBtn";
import { Cases, btnName, modalsName } from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import CommonPopup from "../../../Modals/commonPopup";
import AboutUsPopup from "./aboutUsPopup";
import ByteMiniWebDashboardContext from "../../../context/ByteMiniWeb_Dashboard_Context/ByteMiniWeb_Dashboard_Context";
import { Zoom, toast } from "react-toastify";
import {
  FILE_UPLOAD_API,
  getAboutUs,
  getAboutUsDetails,
  postAboutUs,
  putUpdateAboutUs,
  updateStatus,
} from "../../../apis/AboutApi/AboutUsApi";
import AboutUsContext from "../../../context/AboutUsContext/AboutUsContext";
import { FILE_TYPE, titleUpdateKey } from "../../../../constant";
import { Truncate } from "../../../../utils/helper";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import SimpleReactValidator from "simple-react-validator";

const Aboutus = (props) => {
  const { webMasterDetail, ByteMiniWeb_Dashboard, navbarData, getData } = props;
  const { apiData } = useContext(ByteMiniWebDashboardContext);
  const { setLoaderShow } = useContext(LoaderContext);
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const {
    apiData: abutUsData,
    setApiData,
    fromData,
    setFormData,
    id,
    setId,
    setIsEdit,
    isEdit,
    filter,
    formDataImageParent,
  } = useContext(AboutUsContext);

  /**
   * get slider list
   */
  const getAboutUsList = async (status) => {
    try {
      if (!filter.length && status) {
        setApiData((prev) => {
          return { ...prev, isLoading: true };
        });
      }
      let paramObj = {
        showAll: true,
        search: { title: filter },
      };
      const { data } = await getAboutUs(paramObj);
      if (data.status === 200) {
        setApiData({
          ...abutUsData,
          isLoading: false,
          data: data.payload.data.map((elem) => {
            return {
              id: elem.title + elem.id,
              Id: elem.id,
              name: elem.title,
              image: elem.image,
              webId: elem.webId,
              createdAt: elem.createdAt,
              isActive: elem.isActive,
            };
          }),
        });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  useMemo(() => {
    if (allPopupState?.aboutUsPopup) {
      getAboutUsList();
    }
  }, [filter, allPopupState?.aboutUsPopup]);

  /**
   * enable disable api call
   * @param {slider id} sliderId
   * @param {status} isActive
   */

  const handleChangeStatus = async (id, isActive) => {
    try {
      let body = {
        id,
        isActive: isActive ? false : true,
        navMapId: navbarData[0].navMapId,
      };
      const { data } = await updateStatus(body);
      if (data.status === 200) {
        getAboutUsList(false);
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * image upload
   * @param {file} file
   */
  const imageUpload = async (file) => {
    setLoaderShow(true);
    let formData = new FormData();
    formData.append("type", FILE_TYPE.BYTE_SLIDER);
    formData.append("file", file);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        setFormData((prev) => {
          return {
            ...prev,
            image: data.payload.baseUrl + data.payload.imageUrl,
          };
        });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * create and update about us
   */
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      if (id >= -1 && isEdit) {
        try {
          setLoaderShow(true);
          let newFormData = {
            ...fromData,
          };
          const { data } = await putUpdateAboutUs({
            formDataImageParent: newFormData,
            id,
          });
          if (data.status === 200) {
            getAboutUsList();
            emptyData();
            TostMessage({ message: data, type: "success" });
            ByteMiniWeb_Dashboard();
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      } else {
        try {
          setLoaderShow(true);
          let newData = {
            ...fromData,
            order: abutUsData.data.length + 1,
          };
          const { data } = await postAboutUs(newData);
          if (data.status === 200) {
            getAboutUsList();
            emptyData();
            TostMessage({ message: data, type: "success" });
            ByteMiniWeb_Dashboard();
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      }
    }
  };

  /**
   * get details about us
   * @param {id} id
   */
  const handleEdit = async (id) => {
    try {
      setLoaderShow(true);
      setId(id);
      const { data } = await getAboutUsDetails(id);
      if (data.status === 200) {
        let payload = data.payload.data;
        setFormData({
          title: payload?.title,
          aboutUs: payload?.aboutUs,
          id: id,
          webId: localStorage.getItem("webId"),
        });
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * clear data
   */
  const emptyData = () => {
    setId("");
    setIsEdit(false);
    setFormData({
      title: "",
      aboutUs: "",
      webId: localStorage.getItem("webId"),
    });
    setLoaderShow(false);
  };

  const filterValue = () => {
    let [value] = navbarData.filter(
      (elem) => elem.navbarDetail.label === titleUpdateKey.About_us
    );
    return value;
  };

  const value = useMemo(() => filterValue(), [navbarData]);
  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody className="support-ticket-font box-border-div">
            <CardBuilderHeaderSection
              name={`${apiData?.data?.aboutUs?.length} ${
                value?.label || "About US"
              }`}
            />
            <Row>
              {apiData?.data?.aboutUs?.map((elem) => {
                return (
                  <Col xl="3 xl-50 box-col-6" md="6" key={elem.id}>
                    <Card className="ecommerce-widget">
                      <CardBody className="support-ticket-font">
                        <div className="col-12">
                          <CardBuilderHeaderSection name={elem?.title} />
                          <div
                            style={{ height: "6rem", overflow: "hidden" }}
                            dangerouslySetInnerHTML={{
                              __html:
                                elem?.aboutUs && Truncate(elem?.aboutUs, 100),
                            }}
                          />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
              <OpenPopupBtn
                openPopup={() =>
                  OpenModalCommonFunc(Cases.ABOUT_US, setAllPopupState)
                }
                name={btnName.ABOUT_US}
              />
            </Row>
          </CardBody>
        </Card>
      </Col>
      <CommonPopup
        open={allPopupState.aboutUsPopup}
        close={() =>
          OpenModalCommonFunc(Cases.ABOUT_US, setAllPopupState, false)
        }
        isMini={true}
        modalName={modalsName.ABOUTUS}
        modalCase={Cases.ABOUT_US}
        handleSubmit={handleSubmit}
        emptyData={emptyData}
        inputData={fromData}
        edit={true}
        oldTitle={titleUpdateKey.isAboutUs}
        label={titleUpdateKey.About_us}
        getData={getData}
        content={
          <AboutUsPopup
            handleChangeStatus={handleChangeStatus}
            handleSubmit={handleSubmit}
            getData={getAboutUsList}
            handleEdit={handleEdit}
            imageUpload={imageUpload}
            emptyData={emptyData}
            simpleValidator={simpleValidator}
            webMasterDetail={webMasterDetail}
          />
        }
      />
    </>
  );
};

export default Aboutus;
