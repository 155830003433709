import React, { useRef } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Button } from "reactstrap";
import { useDebounceEffect } from "../utils/useDebounceEffect";
import { canvasPreview } from "../utils/canvasPriview";
import { Cases } from "../constant";
import { OpenModalCommonFunc } from "../utils";

function centerAspectCrop(mediaWidth, mediaHeight, aspect, imageWidth) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: imageWidth,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const ChropeImage = (props) => {
  const {
    imageWidth,
    aspect,
    image,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
    setAllPopupState,
    imageUpload,
    reupload,
  } = props;
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const hiddenAnchorRef = useRef(null);

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect, imageWidth));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  const dataURLtoBlob = (dataURL) => {
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  /**
   * handle crop image and transparent image crop to remove black background
   */
  const handleSetImage = async () => {
    const canvas = previewCanvasRef.current;
    const blobGet = dataURLtoBlob(image);
    const imageUri = canvas.toDataURL(blobGet.type, 0.7);
    try {
      const blobImage = dataURLtoBlob(imageUri);
      const file = new File(
        [blobImage],
        "default." + blobImage.type.split("/")[1],
        {
          // Use correct extension based on file type
          type: blobImage.type,
        }
      );
      await imageUpload(file);
      OpenModalCommonFunc(Cases.PRODUCT_UPLOAD, setAllPopupState, false);
    } catch (error) {
      console.error("Error during image compression", error);
    }
  };

  // Helper function to check if the canvas has transparency
  function canvasHasTransparency(canvas) {
    const ctx = canvas.getContext("2d");
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;

    for (let i = 3; i < data.length; i += 4) {
      if (data[i] < 255) {
        return true;
      }
    }

    return false;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          gap: "40px",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <div style={{ maxWidth: "20rem" }}>
          {!!image && (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={image}
                style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          )}
        </div>
        <div style={{ display: "none" }}>
          {!!completedCrop && (
            <>
              <div>
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    border: "1px solid black",
                    objectFit: "contain",
                    width: completedCrop.width,
                    height: completedCrop.height,
                  }}
                />
              </div>
              <div>
                {/* <button onClick={onDownloadCropClick}>Download Crop</button> */}
                <a
                  ref={hiddenAnchorRef}
                  download
                  style={{
                    position: "absolute",
                    top: "-200vh",
                    visibility: "hidden",
                  }}
                >
                  Hidden download
                </a>
              </div>
            </>
          )}
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          onClick={() => reupload()}
          color="warning"
          style={{ marginTop: "10px", marginBottom: "10px" }}
        >
          Re choose
        </Button>
        <Button
          onClick={() => handleSetImage()}
          style={{ marginTop: "10px", marginBottom: "10px" }}
        >
          Crop
        </Button>
      </div>
    </>
  );
};

export default ChropeImage;
