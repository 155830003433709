import React, { useState } from "react";
import ByteCardBuilderContext from "./ByteCardBuilderContext";
import { randomStr } from "../../../utils/helper";

const ByteCardBuilderState = (props) => {
  const [allPopupState, setAllPopupState] = useState({
    colorPopup: false,
    businessPopup: false,
    subDomainPopup: false,
    companyPopup: false,
    seoPopup: false,
    aboutUsPopup: false,
    aboutUsParentPopup: false,
    productsPopup: false,
    productsParentPopup: false,
    productUploadPopup: false,
    videosPopup: false,
    videosParentPopup: false,
    downloadsPopup: false,
    imagesPopup: false,
    imagesParentPopup: false,
    imagesChildPopup: false,
    videosChildPopup: false,
    paymentdetails_qrPopup: false,
    contactusPopup: false,
    socialmediaPopup: false,
    testimonialPopup: false,
    dragDropPopup: false,
    sliderPopup: false,
    ourteamPopup: false,
    ourteamMemberPopup: false,
    detailsPopup: false,
    servicePopup: false,
    B_W_T_And_Button_Popup: false,
    productFeaturesPopup: false,
    FAQsPopup: false,
    dataCounterPopup: false,
    paymentPopup: false,
    paymentItemsPopup: false,
    settingPopup: false,
    aboutSettingPopup: false,
    sliderSettingPopup: false,
    latestNewsPopup: false,
    latestNewsChildPopup: false,
    itemFeaturePopup: false,
    itemFeatureChildPopup: false,
    itemSettingPopup: false,
    packagesPopup: false,
    packagesChildPopup: false,
    packagesSettingPopup: false,
    latestSettingPopup: false,
    serviceCounterPopup: false,
    serviceCounterChildPopup: false,
    serviceCOunterSettingPopup: false,
    ourClientPopup: false,
    ourClientChildPopup: false,
    imageBannerPopup: false,
    imageBannerChildPopup: false,
    videoBannerPopup: false,
    videoBannerChildPopup: false,
    videoBannerSettingPopup: false,
    faqsPopup: false,
    faqsChildPopup: false,
    stepsPopup: false,
    stepsChildPopup: false,
    sliderMultipagePopup: false,
    sliderMultipageChildPopup: false,
    testimonialMultipagePopup: false,
    testimonialMultipageChildPopup: false,
    downloadMultipagePopup: false,
    downloadMultipageChildPopup: false,
    socialMediaMultipagePopup: false,
    socialMediaMultipageChildPopup: false,
    faqsParentPopup: false,
    careerPopup: false,
    careerChildPopup: false,
    pageTitlePopup: false,
    getWhatsAppLinkPopup: false,
    getWhatsAppLinkChildPopup: false,
    service_: false,
    serviceCategory: false,
    careerSettingPopup: false,
    blogPopup: false,
    blogDetailPopup: false,
    blogSettingPopup: false,
    stepsParenPopup: false,
    serviceAndCategorySettingPopup: false,
    imageMultiPageSettingPopup: false,
    getLinkStartSettingPopup: false,
    downloadSettingPopup: false,
    testimonialSettingPopup: false,
    contactUsSettingParent: false,
    socialMediaSettingPopup: false,
    ourTeamSettingPopup: false,
    contactUsSettingPopup: false,
    socialMediaSettingPopup: false,
    ourTeamSettingPopup: false,
    inquirySettingPopup: false,
    inquiryPopup: false,
    inquiryChildPopup: false,
    detailsSettingPopup: false,
    pageTitleSettingPopup: false,
    videoParentSetting: false,
    popupDialogPopup: false,
    popupDialogChildPopup: false,
    popupDialogSettingPopup: false,
    navbarSettingPopup: false,
    productBuyPopup: false,
    productBuySettingPopup: false,
    productBuySettingPopupStyle: false,
    productBuySettingPopupCheckOutStyle: false,
    productBuySettingPopupMyAccountStyle: false,
    productBuySettingPopupPageStyle: false,
    productBuySettingPopupCartPageStyle: false,
    footerSettingPopup: false,
    footerPopup: false,
    footerTopHeaderStylePopup: false,
    footerTopHeaderPopup: false,
    footerOneStylePopup: false,
    footerTwoStylePopup: false,
    footerOnePopup: false,
    footerTwoPopup: false,
    settingFooter: false,
    footerFormPopup: false,
    commonStyleSettingPopup: false,
    customFormPopup: false,
  });
  const [show, setShow] = useState(false);
  const [image, setImage] = useState("");
  const [ourServices, setOverServices] = useState([
    { id: randomStr(2), name: "" },
  ]);
  const [domain, setDomain] = useState("");
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(16 / 5);
  const [base64, setBase64] = useState("");
  return (
    <ByteCardBuilderContext.Provider
      value={{
        allPopupState,
        setAllPopupState,
        ourServices,
        setOverServices,
        show,
        setShow,
        crop,
        setCrop,
        completedCrop,
        setCompletedCrop,
        scale,
        setScale,
        rotate,
        setRotate,
        aspect,
        setAspect,
        base64,
        setBase64,
        image,
        setImage,
        domain,
        setDomain,
      }}
    >
      {props.children}
    </ByteCardBuilderContext.Provider>
  );
};

export default ByteCardBuilderState;
