import React, { useState } from "react";
import Multipage_OurTeamContext from "./Multipage_OurTeamContext";
import { randomStr } from "../../../utils/helper";

const Multipage_OurTeamState = (props) => {
  const [apiData, setApiData] = useState({ data: [], isLoading: false });
  const [apiDataOurTeam, setApiDataOurTeam] = useState({
    isLoading: false,
    data: [],
  });
  const [collId, setCollId] = useState();
  const [skill, setSkill] = useState([
    { id: randomStr(2), skill: "", parentageOfSkill: "" },
  ]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [fromData, setFormData] = useState({
    title: "",
    subTitle: "",
    isTitleShow: true,
    skill: "",
    image: "",
    description: "",
    slugUrl: "",
    hyperLink: "",
    socialMedia: [{ id: randomStr(2), name: "", link: "" }],
    webId: localStorage.getItem("webId"),
  });
  const [isBackgroundColor, setIsBackgroundColor] = useState(false);
  const [formDataOurTeam, setFormDataOurData] = useState({
    title: "",
    headLine: "",
    image: "",
    itemLimit: "",
    buttonText: "",
    navMapId: "",
    description: "",
    isTitleShow: true,
    slugUrl: "",
    theme: 1,
    webId: localStorage.getItem("webId"),
    style: {
      ".team-section": {
        padding: "100px 0",
        "background-color": "rgb(255 255 255 / 100)",
        width: "",
        margin: "",
        "border-radius": "",
      },
      ".team-section.bg-image": {
        "background-image": "",
      },
      ".team-section.bg-image::before": {
        "background-color": "",
      },
      ".team-section .section-title-area": {
        "margin-bottom": "50px",
      },
      ".team-section .section-title-area .section-subtitle": {
        "font-size": "16px",
        "font-weight": "600",
        color: "rgb(255 73 124 / 100)",
        "background-color": "rgb(255 73 124 / 0.2)",
        padding: "5px 20px",
        p1: "5px",
        p2: "20px",
        "border-radius": "15px",
        "margin-bottom": "15px",
      },
      ".team-section .section-title-area .section-title": {
        "font-size": "44px",
        "font-weight": "600",
        color: "rgb(0 0 0 / 100)",
      },
      ".team-section .member-data": {
        margin: "10px 0",
        "background-color": "rgb(255 255 255 / 100)",
        "box-shadow": "0 5px 10px 0 rgb(7 28 31 / 0.098)",
        bs1: "0",
        bs2: "5px",
        bs3: "10px",
        bs4: "0",
        shadowColor: "rgb(7 28 31 / 0.098)",
        "border-radius": "0px",
      },
      ".team-section .member-data .member-img": {
        "background-color": "rgb(7 28 31 / 0.098)",
      },
      ".team-section .member-data .member-info": {
        padding: "30px 15px",
        p1: "30px",
        p2: "15px",
      },
      ".team-section .member-data .name a": {
        color: "rgb(0 0 0 / 100)",
        "font-size": "20px",
        "font-weight": "500",
      },
      ".team-section .member-data .name a:hover": {
        color: "rgb(255 73 124 / 100)",
      },
      ".team-section .member-data .position": {
        color: "rgb(255 73 124 / 100)",
        "font-size": "18px",
        "font-weight": "600",
        margin: "10px 0",
      },
      ".team-section .member-data .socia-list a": {
        padding: "8px",
        margin: "1px",
        "text-align": "center",
        color: "rgb(102 102 102 / 100)",
        "font-size": "20px",
        border: "2px solid rgb(255 73 124 / 100)",
        borderPx: "2px",
        borderThik: "solid",
        borderColor: "rgb(255 73 124 / 100)",
        "border-radius": "20px",
      },
      ".team-section .member-data .socia-list a:hover": {
        color: "rgb(255 255 255 / 100)",
        "background-color": "rgb(255 73 124 / 100)",
        "border-color": "rgb(255 73 124 / 100)",
      },

      ".team-detail-section": {
        padding: "100px 0",
        "background-color": "rgb(255 255 255 / 100)",
        width: "",
        margin: "",
        "border-radius": "",
      },
      ".team-detail-section.bg-image": {
        "background-image": "",
      },
      ".team-detail-section.bg-image::before": {
        "background-color": "",
      },
      ".team-detail-section .single-team-img": {
        "background-color": "rgb(244 244 245 / 100)",
        "margin-bottom": "20px",
      },
      ".team-detail-section .team-member-info .team-title": {
        "font-weight": 600,
        "font-size": "24px",
      },
      ".team-detail-section .team-member-info .designation": {
        color: "rgb(164 164 170 / 100)",
      },
      ".team-detail-section .team-member-info .social-list a": {
        padding: "9px",
        margin: "1px",
        width: "40px",
        height: "40px",
        "text-align": "center",
        color: "rgb(255 255 255 / 100)",
        "font-size": "20px",
        "border-radius": "20px",
        "background-color": "#1f2732",
      },
      ".team-detail-section .team-member-info .social-list a:hover": {
        color: "rgb(255 255 255 / 100)",
        "background-color": "rgb(255 73 124 / 100)",
      },
      ".personal-details .details-title": {
        "font-weight": 600,
        "font-size": "24px",
      },
      ".personal-details p": {
        "margin-bottom": "20px",
        "line-height": "1.6",
        "font-size": "18px",
      },
      ".personal-details .info-list": {
        "margin-bottom": "30px",
      },
      ".personal-details .info-list li": {
        "margin-bottom": "15px",
        "font-size": "18px",
      },
      ".personal-details .order-list": {
        "margin-bottom": "30px",
      },
      ".personal-details .order-list li": {
        "padding-left": "25px",
        "margin-bottom": "15px",
        "font-size": "18px",
      },
      ".personal-details .order-list li:before": {
        "font-family": "FontAwesome",
        top: "3px",
        color: "rgb(255 73 124 / 100)",
      },
      ".team-section .more-btn": {
        color: "rgb(255 255 255 / 100)",
        "font-size": "16px",
        "font-weight": "normal",
        "border-radius": "15px",
        padding: "5px 20px",
        "background-color": "rgb(255 73 124 / 100)",
      },
      ".team-section .more-btn:hover": {
        "background-color": "rgb(223 42 93 / 100)",
        color: "rgb(255 255 255 / 100)",
      },
      ".pagination-positions": {
        "justify-content": "center",
      },
      ".pagination .page-link": {
        color: "rgb(255 73 124 / 100)",
      },
      ".pagination .page-item.active .page-link": {
        "background-color": "rgb(255 73 124 / 100)",
        "border-color": "rgb(255 73 124 / 100)",
      },
    },
  });

  const [settingFormData, setSettingFormData] = useState({
    ".team-section": {
      padding: "100px 0",
      "background-color": "rgb(255 255 255 / 100)",
      width: "",
      margin: "",
      "border-radius": "",
    },
    ".team-section.bg-image": {
      "background-image": "",
    },
    ".team-section.bg-image::before": {
      "background-color": "",
    },
    ".team-section .section-title-area": {
      "margin-bottom": "50px",
    },
    ".team-section .section-title-area .section-subtitle": {
      "font-size": "16px",
      "font-weight": "600",
      color: "rgb(255 73 124 / 100)",
      "background-color": "rgb(255 73 124 / 0.2)",
      padding: "5px 20px",
      p1: "5px",
      p2: "20px",
      "border-radius": "15px",
      "margin-bottom": "15px",
    },
    ".team-section .section-title-area .section-title": {
      "font-size": "44px",
      "font-weight": "600",
      color: "rgb(0 0 0 / 100)",
    },
    ".team-section .member-data": {
      margin: "10px 0",
      "background-color": "rgb(255 255 255 / 100)",
      "box-shadow": "0 5px 10px 0 rgb(7 28 31 / 0.098)",
      bs1: "0",
      bs2: "5px",
      bs3: "10px",
      bs4: "0",
      shadowColor: "rgb(7 28 31 / 0.098)",
      "border-radius": "0px",
    },
    ".team-section .member-data .member-img": {
      "background-color": "rgb(7 28 31 / 0.098)",
    },
    ".team-section .member-data .member-info": {
      padding: "30px 15px",
      p1: "30px",
      p2: "15px",
    },
    ".team-section .member-data .name a": {
      color: "rgb(0 0 0 / 100)",
      "font-size": "20px",
      "font-weight": "500",
    },
    ".team-section .member-data .name a:hover": {
      color: "rgb(255 73 124 / 100)",
    },
    ".team-section .member-data .position": {
      color: "rgb(255 73 124 / 100)",
      "font-size": "18px",
      "font-weight": "600",
      margin: "10px 0",
    },
    ".team-section .member-data .socia-list a": {
      padding: "8px",
      margin: "1px",
      "text-align": "center",
      color: "rgb(102 102 102 / 100)",
      "font-size": "20px",
      border: "2px solid rgb(255 73 124 / 100)",
      borderPx: "2px",
      borderThik: "solid",
      borderColor: "rgb(255 73 124 / 100)",
      "border-radius": "20px",
    },
    ".team-section .member-data .socia-list a:hover": {
      color: "rgb(255 255 255 / 100)",
      "background-color": "rgb(255 73 124 / 100)",
      "border-color": "rgb(255 73 124 / 100)",
    },

    ".team-detail-section": {
      padding: "100px 0",
      "background-color": "rgb(255 255 255 / 100)",
      width: "",
      margin: "",
      "border-radius": "",
    },
    ".team-detail-section.bg-image": {
      "background-image": "",
    },
    ".team-detail-section.bg-image::before": {
      "background-color": "",
    },
    ".team-detail-section .single-team-img": {
      "background-color": "rgb(244 244 245 / 100)",
      "margin-bottom": "20px",
    },
    ".team-detail-section .team-member-info .team-title": {
      "font-weight": 600,
      "font-size": "24px",
    },
    ".team-detail-section .team-member-info .designation": {
      color: "rgb(164 164 170 / 100)",
    },
    ".team-detail-section .team-member-info .social-list a": {
      padding: "9px",
      margin: "1px",
      width: "40px",
      height: "40px",
      "text-align": "center",
      color: "rgb(255 255 255 / 100)",
      "font-size": "20px",
      "border-radius": "20px",
      "background-color": "#1f2732",
    },
    ".team-detail-section .team-member-info .social-list a:hover": {
      color: "rgb(255 255 255 / 100)",
      "background-color": "rgb(255 73 124 / 100)",
    },
    ".personal-details .details-title": {
      "font-weight": 600,
      "font-size": "24px",
    },
    ".personal-details p": {
      "margin-bottom": "20px",
      "line-height": "1.6",
      "font-size": "18px",
    },
    ".personal-details .info-list": {
      "margin-bottom": "30px",
    },
    ".personal-details .info-list li": {
      "margin-bottom": "15px",
      "font-size": "18px",
    },
    ".personal-details .order-list": {
      "margin-bottom": "30px",
    },
    ".personal-details .order-list li": {
      "padding-left": "25px",
      "margin-bottom": "15px",
      "font-size": "18px",
    },
    ".personal-details .order-list li:before": {
      "font-family": "FontAwesome",
      top: "3px",
      color: "rgb(255 73 124 / 100)",
    },
    ".team-section .more-btn": {
      color: "rgb(255 255 255 / 100)",
      "font-size": "16px",
      "font-weight": "normal",
      "border-radius": "15px",
      padding: "5px 20px",
      "background-color": "rgb(255 73 124 / 100)",
    },
    ".team-section .more-btn:hover": {
      "background-color": "rgb(223 42 93 / 100)",
      color: "rgb(255 255 255 / 100)",
    },
    ".pagination-positions": {
      "justify-content": "center",
    },
    ".pagination .page-link": {
      color: "rgb(255 73 124 / 100)",
    },
    ".pagination .page-item.active .page-link": {
      "background-color": "rgb(255 73 124 / 100)",
      "border-color": "rgb(255 73 124 / 100)",
    },
  });
  const [theme, setTheme] = useState("");
  const [backImageToggle, setBackImageToggle] = useState(false);
  const [backImageToggle1, setBackImageToggle1] = useState(false);
  const [boxSection, setBoxSection] = useState(false);
  const [boxSection1, setBoxSection1] = useState(false);
  const [id, setId] = useState("");
  const [teamMemberId, setTeamMemberId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [filter, setFilter] = useState("");
  const [backImgNo, setBackImgNo] = useState();
  const [isBackgroundColor2, setIsBackgroundColor2] = useState(false);
  const [imgShow, setImgShow] = useState(false);
  const [sectionPageName, setSectionPageName] = useState("");
  return (
    <Multipage_OurTeamContext.Provider
      value={{
        apiData,
        setApiData,
        apiDataOurTeam,
        setApiDataOurTeam,
        fromData,
        setFormData,
        formDataOurTeam,
        setFormDataOurData,
        id,
        setId,
        isEdit,
        setIsEdit,
        filter,
        setFilter,
        skill,
        teamMemberId,
        setTeamMemberId,
        setSkill,
        collId,
        setCollId,
        settingFormData,
        setSettingFormData,
        boxSection,
        setBoxSection,
        backImageToggle,
        setBackImageToggle,
        boxSection1,
        setBoxSection1,
        backImageToggle1,
        setBackImageToggle1,
        backImgNo,
        setBackImgNo,
        selectedOptions,
        setSelectedOptions,
        isBackgroundColor,
        setIsBackgroundColor,
        isBackgroundColor2,
        setIsBackgroundColor2,
        theme,
        setTheme,
        imgShow,
        setImgShow,
        sectionPageName,
        setSectionPageName,
      }}
    >
      {props.children}
    </Multipage_OurTeamContext.Provider>
  );
};

export default Multipage_OurTeamState;
