import axios from "axios";
import {
  ADD_CLONE_CATEGORY_LIST,
  ADD_CLONE_CATEGORY_MULTI_LIST,
  CLONE_CATEGORY_LIST,
  CLONE_CATEGORY_MULTI_LIST,
  DELETE_CLONE_CATEGORY_MULTI_LIST,
  DELETE__CLONE_CATEGORY_LIST,
  DETAILS_CLONE_CATEGORY_LIST,
  DETAILS_CLONE_CATEGORY_MULTI_LIST,
  EDIT_CLONE_CATEGORY_LIST,
  EDIT_CLONE_CATEGORY_MULTI_LIST,
  ORG_CLONE_CATEGORY_PAGE_LIST_ENABLE_DESABLE,
  WEB_PAGE_CONFIG_ALIGN,
  WEB_PAGE_CONFIG_CLONE,
} from "../../constant/API constant";
import { SINGLE_PAGE } from "../../constant";

export const cloneCategory_List = (paramsObj, pageName) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${
      pageName === SINGLE_PAGE ? CLONE_CATEGORY_LIST : CLONE_CATEGORY_MULTI_LIST
    }`,
    {
      headers: { Authorization: jwt_token },
      params: paramsObj,
    }
  );
};

export const Add_cloneCategory_List = (formData, pageName) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${
      pageName === SINGLE_PAGE
        ? ADD_CLONE_CATEGORY_LIST
        : ADD_CLONE_CATEGORY_MULTI_LIST
    }`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Edit_cloneCategory_List = (formData, id, pageName) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${
      pageName === SINGLE_PAGE
        ? EDIT_CLONE_CATEGORY_LIST
        : EDIT_CLONE_CATEGORY_MULTI_LIST
    }`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { cloneCategoryId: id },
    }
  );
};

export const Delete_cloneCategory_List = (cloneCategoryId, pageName) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(
    `${process.env.REACT_APP_API_URL}${
      pageName === SINGLE_PAGE
        ? DELETE__CLONE_CATEGORY_LIST
        : DELETE_CLONE_CATEGORY_MULTI_LIST
    }`,
    {
      headers: { Authorization: jwt_token },
      params: { cloneCategoryId },
    }
  );
};

export const Details_cloneCategory_List = (id, pageName) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${
      pageName === SINGLE_PAGE
        ? DETAILS_CLONE_CATEGORY_LIST
        : DETAILS_CLONE_CATEGORY_MULTI_LIST
    }`,
    {
      headers: { Authorization: jwt_token },
      params: { cloneCategoryId: id },
    }
  );
};

export const cloneCategory_Enable_Disable = (formData, cloneCategoryId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${ORG_CLONE_CATEGORY_PAGE_LIST_ENABLE_DESABLE}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { cloneCategoryId },
    }
  );
};

export const alignSection = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${WEB_PAGE_CONFIG_ALIGN}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const cloneSection = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${WEB_PAGE_CONFIG_CLONE}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};
