import axios from "axios";
import {
  CREATE_PROFILE,
  FORGOT,
  ITEM_VIEW,
  LOGIN,
  LOGIN_WITH_GMAIL,
  ORGANIZATION_DETAILS,
  RESET_PASSWORD,
  SIGNIN,
  THEME_DETAILS,
  UPDATE_ORGANIZATION_DETAIL,
} from "../../constant/API constant";

export const SignIN = (
  email,
  password,
  conformpassword,
  orgUuid,
  orgName,
  fname,
  lname,
  slugUrl,
  hwId
) => {
  return axios.post(`${process.env.REACT_APP_API_URL}${SIGNIN}`, {
    userEmail: email,
    orgName: orgName,
    fname: fname,
    lname: lname,
    password: password,
    confirmPassword: conformpassword,
    orgUuid: orgUuid,
    slugUrl: slugUrl,
    hwId,
  });
};

export const Login_ = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}${LOGIN}`, data);
};

export const LoginWithGmail = (data) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}${LOGIN_WITH_GMAIL}`,
    data
  );
};

export const forgotPassword_ = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}${FORGOT}`, data);
};

export const resetPassword_ = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}${RESET_PASSWORD}`, data);
};

export const GetNfc = (formData) => {
  return axios.get(`${process.env.REACT_APP_API_URL}${ORGANIZATION_DETAILS}`, {
    params: formData,
  });
};

export const theme_Detail = (formData) => {
  return axios.get(`${process.env.REACT_APP_API_URL}${THEME_DETAILS}`, {
    params: formData,
  });
};

export const ItemView = (formData) => {
  return axios.post(`${process.env.REACT_APP_API_URL}${ITEM_VIEW}`, formData, {
    // params: formData,
  });
};

export const CreateProfile = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${CREATE_PROFILE}`,
    formData,
    {
      headers: { token: jwt_token },
    }
  );
};

export const updateOrganizationDetail = ({ formData, orgUuid }) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${UPDATE_ORGANIZATION_DETAIL}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { orgUuid },
    }
  );
};
