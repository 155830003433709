import React, { useContext } from "react";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import { Switch } from "@material-ui/core";
import FaqsContext from "../../../../context/FaqsContext/FaqsContext";
import CommonColor from "../../../../common/CommonColor";
import { SketchPicker } from "react-color";
import CustomStyle from "../../../../common/CustomStyle";

const SettingFooterHeaderPopup = (props) => {
  const { isEdit } = useContext(FaqsContext);
  const {
    settingFormData,
    setSettingFormData,
    otherSetting,
    handleSubmitSetting,
  } = props;

  /**
   * input value change
   * @param {event} e
   */

  const footerBgColorAndPadding = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        Footer: {
          ...prev.Footer,
          style: {
            ...prev.Footer.style,
            ".custom-footer .foot-copright": {
              ...prev.Footer.style[".custom-footer .foot-copright"],
              [name]: value,
            },
          },
        },
      };
    });
  };
  const copyRightColor = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        Footer: {
          ...prev.Footer,
          style: {
            ...prev.Footer.style,
            ".custom-footer .foot-copright p": {
              ...prev.Footer.style[".custom-footer .foot-copright p"],
              [name]: value,
            },
          },
        },
      };
    });
  };
  const developedByColor = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        Footer: {
          ...prev.Footer,
          style: {
            ...prev.Footer.style,
            ".custom-footer .foot-copright a": {
              ...prev.Footer.style[".custom-footer .foot-copright a"],
              [name]: value,
            },
          },
        },
      };
    });
  };
  const handleChangeCustomStyle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        Footer: {
          ...prev.Footer,
          style: {
            ...prev.Footer.style,
            [name]: value,
          },
        },
      };
    });
  };

  const resetHandler = () => {
    setSettingFormData({
      ...otherSetting,
      Footer: {
        copyRight: otherSetting?.Footer?.copyRight || "",
        developedBy: otherSetting?.Footer?.developedBy || "",
        style: {
          ".custom-footer .foot-copright": {
            "background-color": { r: 255, g: 73, b: 124, a: 100 },
            padding: "15px 0",
            p1: "15px",
          },
          ".custom-footer .foot-copright p": {
            color: { r: 255, g: 255, b: 255, a: 100 },
            "font-size": "14px",
            "font-weight": "500",
          },
          ".custom-footer .foot-copright a": {
            "text-decoration": "underline",
            color: { r: 255, g: 255, b: 255, a: 100 },
          },
          custom: "",
        },
      },
    });
  };

  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="12">
            <div className="mt-4 d-flex flex-column justify-content-center ">
              <div className="d-flex justify-content-between">
                <h6>{isEdit ? "Edit" : "Add New"}</h6>
                <div className="d-flex gap-2">
                  <Button onClick={resetHandler}>Reset</Button>
                  <Button
                    onClick={() => handleSubmitSetting(false)}
                    color="success"
                  >
                    Save & View
                  </Button>
                </div>
              </div>
              <Row className="mt-4">
                <h4 className="mb-3">
                  footer background and top bottom spacing
                </h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Background Color"}
                  </Label>
                  <SketchPicker
                    color={
                      settingFormData?.Footer?.style?.[
                        ".custom-footer .foot-copright"
                      ]?.["background-color"]
                    }
                    onChangeComplete={(e) =>
                      setSettingFormData((prev) => {
                        return {
                          ...prev,
                          Footer: {
                            ...prev.Footer,
                            style: {
                              ...prev?.Footer?.style,
                              ".custom-footer .foot-copright": {
                                ...prev?.Footer?.style[
                                  ".custom-footer .foot-copright"
                                ],
                                "background-color": e.rgb,
                              },
                            },
                          },
                        };
                      })
                    }
                    onChange={(e) =>
                      setSettingFormData((prev) => {
                        return {
                          ...prev,
                          Footer: {
                            ...prev.Footer,
                            style: {
                              ...prev?.Footer?.style,
                              ".custom-footer .foot-copright": {
                                ...prev?.Footer?.style[
                                  ".custom-footer .foot-copright"
                                ],
                                "background-color": e.rgb,
                              },
                            },
                          },
                        };
                      })
                    }
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"spacing top and bottom"}
                  </Label>
                  <Input
                    className="form-control"
                    name="p1"
                    type="number"
                    placeholder="spacing top and bottom"
                    value={settingFormData?.Footer?.style?.[
                      ".custom-footer .foot-copright"
                    ]?.["p1"]?.replace(/px/g, "")}
                    onChange={(e) => footerBgColorAndPadding(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">copy right color and font size</h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">{"Color"}</Label>
                  <SketchPicker
                    color={
                      settingFormData?.Footer?.style?.[
                        ".custom-footer .foot-copright p"
                      ]?.["color"]
                    }
                    onChangeComplete={(e) =>
                      setSettingFormData((prev) => {
                        return {
                          ...prev,
                          Footer: {
                            ...prev.Footer,
                            style: {
                              ...prev?.Footer?.style,
                              ".custom-footer .foot-copright p": {
                                ...prev?.Footer?.style[
                                  ".custom-footer .foot-copright p"
                                ],
                                color: e.rgb,
                              },
                            },
                          },
                        };
                      })
                    }
                    onChange={(e) =>
                      setSettingFormData((prev) => {
                        return {
                          ...prev,
                          Footer: {
                            ...prev.Footer,
                            style: {
                              ...prev?.Footer?.style,
                              ".custom-footer .foot-copright p": {
                                ...prev?.Footer?.style[
                                  ".custom-footer .foot-copright p"
                                ],
                                color: e.rgb,
                              },
                            },
                          },
                        };
                      })
                    }
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">{"font size"}</Label>
                  <Input
                    className="form-control"
                    name="font-size"
                    type="number"
                    placeholder="spacing top and bottom"
                    value={settingFormData?.Footer?.style?.[
                      ".custom-footer .foot-copright p"
                    ]?.["font-size"]?.replace(/px/g, "")}
                    onChange={(e) => copyRightColor(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">copy right color and font size</h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">{"Color"}</Label>
                  <SketchPicker
                    color={
                      settingFormData?.Footer?.style?.[
                        ".custom-footer .foot-copright a"
                      ]?.["color"]
                    }
                    onChangeComplete={(e) =>
                      setSettingFormData((prev) => {
                        return {
                          ...prev,
                          Footer: {
                            ...prev.Footer,
                            style: {
                              ...prev?.Footer?.style,
                              ".custom-footer .foot-copright a": {
                                ...prev?.Footer?.style[
                                  ".custom-footer .foot-copright a"
                                ],
                                color: e.rgb,
                              },
                            },
                          },
                        };
                      })
                    }
                    onChange={(e) =>
                      setSettingFormData((prev) => {
                        return {
                          ...prev,
                          Footer: {
                            ...prev.Footer,
                            style: {
                              ...prev?.Footer?.style,
                              ".custom-footer .foot-copright a": {
                                ...prev?.Footer?.style[
                                  ".custom-footer .foot-copright a"
                                ],
                                color: e.rgb,
                              },
                            },
                          },
                        };
                      })
                    }
                  />
                </Col>
              </Row>
              <CustomStyle
                handleChange={handleChangeCustomStyle}
                value={settingFormData?.Footer?.style?.custom}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SettingFooterHeaderPopup;
