import React from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

const SellerForgetPasswordItem = (props) => {
  const {
    modal,
    setModal,
    onSubmit,
    togglePassword,
    setTogglePassword,
    setTogglePassword2,
    setPassword,
    togglePassword2,
    setChangePassword,
    password,
    changePassword,
    togglePassword1,
    setNewPassword,
    newpassword,
    setTogglePassword1,
  } = props;
  return (
    <Modal size="lg" isOpen={modal} toggle={() => setModal(!modal)}>
      <ModalHeader toggle={() => setModal(!modal)}>Change Password</ModalHeader>
      <ModalBody>
        <Container fluid={true} className="p-0">
          <Row>
            <Col xs="12">
              <div className="img">
                <div>
                  <div className="login-main login-tab">
                    <Form className="theme-form" onSubmit={(e) => onSubmit(e)}>
                      <h4>Change Password</h4>
                      <p>{"Enter Password and Conform Password"}</p>

                      <div className="mb-3 position-relative">
                        <Label className="col-form-label">
                          {"Old Password"}
                        </Label>
                        <Input
                          className="form-control"
                          type={togglePassword2 ? "text" : "password"}
                          onChange={(e) => setPassword(e.target.value)}
                          defaultValue={password}
                          required="Enter a Valid Password"
                          placeholder="*********"
                        />
                        <div
                          className="show-hide"
                          onClick={() => setTogglePassword2(!togglePassword2)}
                        >
                          <span
                            className={togglePassword2 ? "" : "show"}
                          ></span>
                        </div>
                      </div>

                      <div className="mb-3 position-relative">
                        <Label className="col-form-label">
                          {"New Password"}
                        </Label>
                        <Input
                          className="form-control"
                          type={togglePassword ? "text" : "password"}
                          onChange={(e) => setChangePassword(e.target.value)}
                          defaultValue={changePassword}
                          required="Enter a Valid Change Password"
                          placeholder="*********"
                        />
                        <div
                          className="show-hide"
                          onClick={() => setTogglePassword(!togglePassword)}
                        >
                          <span className={togglePassword ? "" : "show"}></span>
                        </div>
                      </div>

                      <div className="mb-3 position-relative">
                        <Label className="col-form-label">
                          {"Conform Password"}
                        </Label>
                        <Input
                          className="form-control"
                          type={togglePassword1 ? "text" : "password"}
                          onChange={(e) => setNewPassword(e.target.value)}
                          defaultValue={newpassword}
                          required="Enter a Valid Conform Password"
                          placeholder="*********"
                        />
                        <div
                          className="show-hide"
                          onClick={() => setTogglePassword1(!togglePassword1)}
                        >
                          <span
                            className={togglePassword1 ? "" : "show"}
                          ></span>
                        </div>
                      </div>
                      <div className="login-btn mb-0">
                        <div className="ms-3">
                          <Label className="text-muted" for="checkbox1">
                            {"Please Enter Password and Conform Password"}
                          </Label>
                        </div>
                        <Button color="primary" type="submit" value="submit">
                          {"Submit"}
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </ModalBody>
    </Modal>
  );
};

export default SellerForgetPasswordItem;
