import React, { useContext } from "react";
import { Col, Input, Label, Row } from "reactstrap";
import { Border, TextAlign, fontWeight } from "../../../../constant";
import ServiceAndCategoryContext from "../../../context/ServiceAndCatagoryContext/ServiceAndCategoryContext";
import { Cases, DELETE_CONFIRM } from "../../../constant";
import { delete_Multi_Image_Permanent } from "../apis/SliderApi/SliderApi";
import { Zoom, toast } from "react-toastify";
import Switch from "@material-ui/core/Switch";
import { Close } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { UploadCloud } from "react-feather";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import Dividers from "../../../common/divider";
import { OpenModalCommonFunc } from "../../../utils";
import ServiceCategoryDetailStyle from "./ServiceCategoryDetailStyle";
import PaginationButtonStyle from "../../../common/paginationButtonStyle";
import CommonColor from "../../../common/CommonColor";
import CustomStyle from "../../../common/CustomStyle";

const ServiceThemeOne = (props) => {
  const {
    isEdit,
    settingFormData,
    setSettingFormData,
    backImageToggle,
    setBackImageToggle,
    boxSection,
    setBoxSection,
    setIsImageUpload,
    isBackgroundColor,
    setIsBackgroundColor,
  } = useContext(ServiceAndCategoryContext);
  const { setLoaderShow } = useContext(LoaderContext);
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);

  const { imageUpload } = props;

  const handleChangeServiceSection = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".service-section": {
          ...prev[".service-section"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeSectionTitleArea = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".service-section .section-title-area": {
          ...prev[".service-section .section-title-area"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeSectionTitleAreaSubtitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".service-section .section-title-area .section-subtitle": {
          ...prev[".service-section .section-title-area .section-subtitle"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeSectionTitleAreaSectionTitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".service-section .section-title-area .section-title": {
          ...prev[".service-section .section-title-area .section-title"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeSectionSectionDiv = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".service-section .servicediv": {
          ...prev[".service-section .servicediv"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeSectionDivIcon = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".service-section .servicediv .service-icon": {
          ...prev[".service-section .servicediv .service-icon"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeServiceServiceDivIcon = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".service-section .servicediv h4": {
          ...prev[".service-section .servicediv h4"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeServiceServiceDivP = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".service-section .servicediv p": {
          ...prev[".service-section .servicediv p"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeServiceServiceDivLinks = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".service-section .servicediv .links": {
          ...prev[".service-section .servicediv .links"],
          [name]: value,
        },
      };
    });
  };

  const handelChangeSliderDots = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".service-section .slick-dots li button:before": {
          ...prev[".service-section .slick-dots li button:before"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeCustomStyle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  /**
   * delete image permanently
   */
  const deleteImagePermanent = async () => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData = {
          fileName: settingFormData?.[".service-section.bg-image"]?.[
            "background-image"
          ]
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
            .pop(),
        };
        const { data } = await delete_Multi_Image_Permanent(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            position: "top-center",
          });
          setSettingFormData({
            ...settingFormData,
            ".latest-news-section.bg-image": { "background-image": "" },
          });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };
  /**
   * image reupload
   */
  const reupload = () => {
    setSettingFormData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };

  return (
    <>
      <Row className="mt-4">
        <h4 className="mb-3">Make change in main Service section</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (default : 100)"}
          </Label>
          <Input
            className="form-control"
            name="padding"
            type="number"
            placeholder="Padding"
            value={settingFormData?.[".service-section"]?.["padding"]
              ?.split(" ")[0]
              ?.replace(/px/g, "")}
            onChange={(e) => handleChangeServiceSection(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <div className="d-flex align-items-center">
            <Label htmlFor="validationCustom03">
              {isBackgroundColor
                ? "Add Background Color"
                : "Remove Background Color"}
            </Label>
            <Switch
              checked={isBackgroundColor}
              inputProps={{ "aria-label": "controlled" }}
              onChange={(e) => {
                setIsBackgroundColor(e.target.checked);
                setSettingFormData((prev) => {
                  if (e.target.checked) {
                    if (boxSection) {
                      return {
                        ...prev,
                        ".service-section": {
                          "background-color": "#fafafa",
                          padding:
                            settingFormData?.[".service-section"]?.["padding"],
                          width: settingFormData?.[".service-section"]?.[
                            "width"
                          ]?.replace("rem)", ""),
                          margin: settingFormData?.[".service-section"]?.[
                            "margin"
                          ]
                            ?.split(" ")[0]
                            ?.replace(/px/g, ""),
                          "border-radius": settingFormData?.[
                            ".service-section"
                          ]?.["border-radius"]?.replace(/rem/g, ""),
                        },
                      };
                    } else {
                      return {
                        ...prev,
                        ".service-section": {
                          "background-color": "#fafafa",
                          padding:
                            settingFormData?.[".service-section"]?.["padding"],
                        },
                      };
                    }
                  } else {
                    if (boxSection) {
                      return {
                        ...prev,
                        ".service-section": {
                          padding:
                            settingFormData?.[".service-section"]?.["padding"],
                          width: settingFormData?.[".service-section"]?.[
                            "width"
                          ]?.replace("rem)", ""),
                          margin: settingFormData?.[".service-section"]?.[
                            "margin"
                          ]
                            ?.split(" ")[0]
                            ?.replace(/px/g, ""),
                          "border-radius": settingFormData?.[
                            ".service-section"
                          ]?.["border-radius"]?.replace(/rem/g, ""),
                        },
                      };
                    } else {
                      return {
                        ...prev,
                        ".service-section": {
                          padding:
                            settingFormData?.[".service-section"]?.["padding"],
                        },
                      };
                    }
                  }
                });
              }}
            />
          </div>
          {isBackgroundColor && (
            <CommonColor
              color={
                settingFormData?.[".service-section"]?.["background-color"]
              }
              setSettingFormData={setSettingFormData}
              className={".service-section"}
              classProperty={"background-color"}
              label={"Select Background Color"}
            />
          )}
        </Col>
        <p>{"Box Section : convert your section to Box section"}</p>
        <div>
          <Switch
            checked={boxSection}
            inputProps={{ "aria-label": "controlled" }}
            onChange={(e) => {
              setBoxSection(e.target.checked);
              setSettingFormData((prev) => {
                return {
                  ...prev,
                  ".service-section": {
                    padding: settingFormData?.[".service-section"]?.["padding"]
                      ?.split(" ")[0]
                      ?.replace(/px/g, ""),
                    "background-color":
                      settingFormData?.[".service-section"]?.[
                        "background-color"
                      ],
                    width: e.target.checked ? "10" : "",
                    margin: e.target.checked ? "0" : "",
                    "border-radius": e.target.checked ? "3" : "",
                  },
                };
              });
            }}
          />
        </div>
        {boxSection && (
          <>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Width (Default : 10)"}
              </Label>
              <Input
                className="form-control"
                name="width"
                type="number"
                placeholder="width"
                value={settingFormData?.[".service-section"]?.[
                  "width"
                ]?.replace("rem)", "")}
                onChange={(e) => handleChangeServiceSection(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Margin (Default : 0)"}
              </Label>
              <Input
                className="form-control"
                name="margin"
                type="number"
                placeholder="margin"
                value={settingFormData?.[".service-section"]?.["margin"]
                  ?.split(" ")[0]
                  ?.replace(/px/g, "")}
                onChange={(e) => handleChangeServiceSection(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Border Radius (Default : 3)"}
              </Label>
              <Input
                className="form-control"
                name="border-radius"
                type="number"
                placeholder="Border Radius"
                value={settingFormData?.[".service-section"]?.[
                  "border-radius"
                ]?.replace(/rem/g, "")}
                onChange={(e) => handleChangeServiceSection(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
          </>
        )}
      </Row>
      <hr />
      <Row className="mt-4">
        <Col md="12 mb-3">
          <h4 className="mb-3">Add Background Image in section</h4>

          <p>do you want to upload Background image</p>
          <Switch
            checked={backImageToggle}
            inputProps={{ "aria-label": "controlled" }}
            onChange={(e) => {
              setBackImageToggle(e.target.checked);
              setSettingFormData((prev) => {
                if (e.target.checked) {
                  return {
                    ...prev,
                    image: "",
                    ".service-section.bg-image": {},
                    ".service-section.bg-image::before": {
                      "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
                    },
                  };
                } else {
                  return {
                    ...prev,
                    image: "",
                    ".service-section.bg-image": {},
                    ".service-section.bg-image::before": {},
                  };
                }
              });
            }}
          />
          {backImageToggle && (
            <>
              {" "}
              <Col md="6 mb-3">
                <Dividers divide="IMAGE" />
                <p className="mb-4">
                  File type required JPG, PNG (1905px X 986px)
                </p>
              </Col>
              <Row>
                <Col md="4 mb-3">
                  <div
                    className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    {!settingFormData?.[".service-section.bg-image"]?.[
                      "background-image"
                    ] && (
                      <span
                        onClick={() => {
                          OpenModalCommonFunc(
                            Cases.PRODUCT_UPLOAD,
                            setAllPopupState
                          );
                          setSettingFormData((prev) => {
                            return {
                              ...prev,
                              image:
                                settingFormData?.[
                                  ".service-section.bg-image"
                                ]?.["background-image"],
                            };
                          });
                          setIsImageUpload((prev) => {
                            return {
                              isList: true,
                              isDetails: false,
                            };
                          });
                        }}
                      >
                        <UploadCloud />
                      </span>
                    )}

                    {settingFormData?.[".service-section.bg-image"]?.[
                      "background-image"
                    ] && (
                      <img
                        src={
                          settingFormData?.[".service-section.bg-image"]?.[
                            "background-image"
                          ] || ""
                        }
                        alt=""
                        style={{
                          width: "85%",
                          height: "90px",
                          objectFit: "contain",
                          position: "absolute",
                        }}
                        onClick={() => {
                          deleteImagePermanent();
                        }}
                      />
                    )}
                    {settingFormData?.[".service-section.bg-image"]?.[
                      "background-image"
                    ] && (
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="close"
                        style={{
                          color: "red",
                          position: "absolute",
                          top: "-22px",
                          left: "1px",
                        }}
                        onClick={() => deleteImagePermanent()}
                      >
                        <Close
                          style={{
                            fontSize: "25px",
                            fontWeight: "bold",
                          }}
                        />
                      </IconButton>
                    )}
                  </div>
                </Col>
                <Col md="6 mb-3">
                  <h4 className="mb-3">Add Background color in section</h4>
                  <Col md="6 mb-3">
                    <CommonColor
                      color={
                        settingFormData?.[
                          ".service-section.bg-image::before"
                        ]?.["background-color"]
                      }
                      setSettingFormData={setSettingFormData}
                      className={".service-section.bg-image::before"}
                      classProperty={"background-color"}
                      label={"Select Background Color"}
                    />
                  </Col>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make space in Title bottom</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin Bottom (Default : 50)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin Bottom"
            value={settingFormData?.[".service-section .section-title-area"]?.[
              "margin-bottom"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeSectionTitleArea(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in section subtitle</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 16)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".service-section .section-title-area .section-subtitle"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeSectionTitleAreaSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[
                ".service-section .section-title-area .section-subtitle"
              ]?.["font-weight"]
            }
            onChange={(e) => handleChangeSectionTitleAreaSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".service-section .section-title-area .section-subtitle"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".service-section .section-title-area .section-subtitle"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".service-section .section-title-area .section-subtitle"
              ]?.["background-color"]
            }
            setSettingFormData={setSettingFormData}
            className={".service-section .section-title-area .section-subtitle"}
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 5 - 20)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="p1"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".service-section .section-title-area .section-subtitle"
              ]?.["p1"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeSectionTitleAreaSubtitle(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p2"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".service-section .section-title-area .section-subtitle"
              ]?.["p2"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeSectionTitleAreaSubtitle(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border Radius (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[
              ".service-section .section-title-area .section-subtitle"
            ]?.["border-radius"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeSectionTitleAreaSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin Bottom (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin Bottom"
            value={settingFormData?.[
              ".service-section .section-title-area .section-subtitle"
            ]?.["margin-bottom"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeSectionTitleAreaSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in section Title</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 44)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".service-section .section-title-area .section-title"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeSectionTitleAreaSectionTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[
                ".service-section .section-title-area .section-title"
              ]?.["font-weight"]
            }
            onChange={(e) => handleChangeSectionTitleAreaSectionTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".service-section .section-title-area .section-title"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".service-section .section-title-area .section-title"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in service section div</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Margin (Default : 15)"}</Label>
          <Input
            className="form-control"
            name="margin"
            type="number"
            placeholder="Margin Bottom"
            value={settingFormData?.[".service-section .servicediv"]?.[
              "margin"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeSectionSectionDiv(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Padding (Default : 10)"}</Label>
          <Input
            className="form-control"
            name="padding"
            type="number"
            placeholder="padding"
            value={settingFormData?.[".service-section .servicediv"]?.[
              "padding"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeSectionSectionDiv(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Outline (Default : 1 solid white)"}
          </Label>
          <div className="d-flex">
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="outPx"
                type="number"
                placeholder="px"
                value={settingFormData?.[".service-section .servicediv"]?.[
                  "outPx"
                ]?.replace(/px/g, "")}
                onChange={(e) => handleChangeSectionSectionDiv(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <select
                name="outThik"
                id="outThik"
                className="form-control"
                value={
                  settingFormData?.[".service-section .servicediv"]?.["outThik"]
                }
                onChange={(e) => handleChangeSectionSectionDiv(e)}
                style={{ borderRadius: "0", color: "black" }}
              >
                <option value="">--select--</option>
                {Border.map((elem) => {
                  return <option value={elem.border}>{elem.border}</option>;
                })}
              </select>
            </div>
            <div>
              <CommonColor
                color={
                  settingFormData?.[".service-section .servicediv"]?.[
                    "outColor"
                  ]
                }
                setSettingFormData={setSettingFormData}
                className={".service-section .servicediv"}
                classProperty={"outColor"}
                label={"Color"}
              />
            </div>
          </div>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".service-section .servicediv"]?.[
                "background-color"
              ]
            }
            setSettingFormData={setSettingFormData}
            className={".service-section .servicediv"}
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
        <Col md="6 mb-3">
          <Label htmlFor="validationCustom03">
            {"Box Shadow (Default : 0 5 10 0 black)"}
          </Label>

          <div className="d-flex justify-content-between">
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="bs1"
                type="number"
                placeholder="px"
                value={settingFormData?.[".service-section .servicediv"]?.[
                  "bs1"
                ]?.replace(/px/g, "")}
                onChange={(e) => handleChangeSectionSectionDiv(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="bs2"
                type="number"
                placeholder="px"
                value={settingFormData?.[".service-section .servicediv"]?.[
                  "bs2"
                ]?.replace(/px/g, "")}
                onChange={(e) => handleChangeSectionSectionDiv(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="bs3"
                type="number"
                placeholder="px"
                value={settingFormData?.[".service-section .servicediv"]?.[
                  "bs3"
                ]?.replace(/px/g, "")}
                onChange={(e) => handleChangeSectionSectionDiv(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="bs4"
                type="number"
                placeholder="px"
                value={settingFormData?.[".service-section .servicediv"]?.[
                  "bs4"
                ]?.replace(/px/g, "")}
                onChange={(e) => handleChangeSectionSectionDiv(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div>
              <CommonColor
                color={
                  settingFormData?.[".service-section .servicediv"]?.[
                    "shadowColor"
                  ]
                }
                setSettingFormData={setSettingFormData}
                className={".service-section .servicediv"}
                classProperty={"shadowColor"}
                label={"Shadow Color"}
              />
            </div>
          </div>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border radius (Default : 0)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="border-radius"
            value={settingFormData?.[".service-section .servicediv"]?.[
              "border-radius"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeSectionSectionDiv(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in service div image</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin Bottom (Default : 20)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin Bottom"
            value={settingFormData?.[
              ".service-section .servicediv .service-icon"
            ]?.["margin-bottom"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeSectionDivIcon(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Max Width (Default : 70)"}
          </Label>
          <Input
            className="form-control"
            name="max-width"
            type="number"
            placeholder="Max Width"
            value={settingFormData?.[
              ".service-section .servicediv .service-icon"
            ]?.["max-width"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeSectionDivIcon(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in service div headline</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".service-section .servicediv h4"]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".service-section .servicediv h4"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[".service-section .servicediv h4"]?.[
                "font-weight"
              ]
            }
            onChange={(e) => handleChangeServiceServiceDivIcon(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 20)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[".service-section .servicediv h4"]?.[
              "font-size"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeServiceServiceDivIcon(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin Bottom (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin Bottom"
            value={settingFormData?.[".service-section .servicediv h4"]?.[
              "margin-bottom"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeServiceServiceDivIcon(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Text Align (Default : justify)"}
          </Label>
          <select
            name="text-align"
            id="text-align"
            className="form-control"
            value={
              settingFormData?.[".service-section .servicediv h4"]?.[
                "text-align"
              ]
            }
            onChange={(e) => handleChangeServiceServiceDivIcon(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {TextAlign.map((elem) => {
              return (
                <option value={elem["text-align"]}>{elem["text-align"]}</option>
              );
            })}
          </select>
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in service div paragraph</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".service-section .servicediv p"]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".service-section .servicediv p"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 400)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[".service-section .servicediv p"]?.[
                "font-weight"
              ]
            }
            onChange={(e) => handleChangeServiceServiceDivP(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 14)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[".service-section .servicediv p"]?.[
              "font-size"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeServiceServiceDivP(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Line Height (Default : 1.5)"}
          </Label>
          <Input
            className="form-control"
            name="line-height"
            type="number"
            placeholder="Line Height"
            value={
              settingFormData?.[".service-section .servicediv p"]?.[
                "line-height"
              ]
            }
            onChange={(e) => handleChangeServiceServiceDivP(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin Bottom (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin Bottom"
            value={settingFormData?.[".service-section .servicediv p"]?.[
              "margin-bottom"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeServiceServiceDivP(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Text Align (Default : justify)"}
          </Label>
          <select
            name="text-align"
            id="text-align"
            className="form-control"
            value={
              settingFormData?.[".service-section .servicediv p"]?.[
                "text-align"
              ]
            }
            onChange={(e) => handleChangeServiceServiceDivP(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {TextAlign.map((elem) => {
              return (
                <option value={elem["text-align"]}>{elem["text-align"]}</option>
              );
            })}
          </select>
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in service div links</h4>

        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 16)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[".service-section .servicediv .links"]?.[
              "font-size"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeServiceServiceDivLinks(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 500)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[".service-section .servicediv .links"]?.[
                "font-weight"
              ]
            }
            onChange={(e) => handleChangeServiceServiceDivLinks(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".service-section .servicediv .links"]?.[
                "color"
              ]
            }
            setSettingFormData={setSettingFormData}
            className={".service-section .servicediv .links"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Change color in service slider slick-arrow</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".service-slider .slick-arrow:before"]?.[
                "color"
              ]
            }
            setSettingFormData={setSettingFormData}
            className={".service-slider .slick-arrow:before"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
      </Row>
      <hr />
      <ServiceCategoryDetailStyle />
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in slider dots</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 12)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".service-section .slick-dots li button:before"
            ]?.["font-size"]
              ?.split(" ")[0]
              ?.replace(/px/g, "")}
            onChange={(e) => handelChangeSliderDots(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".service-section .slick-dots li button:before"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".service-section .slick-dots li button:before"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"opacity (Default : 0.25)"}
          </Label>
          <Input
            className="form-control"
            name="opacity"
            type="number"
            placeholder="opacity"
            value={
              settingFormData?.[
                ".service-section .slick-dots li button:before"
              ]?.["opacity"]?.split(" ")[0]
            }
            onChange={(e) => handelChangeSliderDots(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <h4 className="mb-3">Active slider dots</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".service-section .slick-dots li.slick-active button:before"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".service-section .slick-dots li.slick-active button:before"
            }
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
      </Row>
      <PaginationButtonStyle
        mainClass={".service-section"}
        settingFormData={settingFormData}
        setSettingFormData={setSettingFormData}
      />
      <CustomStyle
        handleChange={handleChangeCustomStyle}
        value={settingFormData?.custom}
      />
      {/* <hr />
              <Row className="mt-2">
                <h4 className="mb-3">
                  Make Change in service-slider | service-box
                </h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Position (Default : relative)"}
                  </Label>
                  <select
                    name="position"
                    id="font-weight"
                    className="form-select"
                    value={
                      settingFormData?.[".service-slider .service-box"]?.[
                        "position"
                      ]
                    }
                    onChange={(e) => handleChangeServiceSliderBox(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {Position.map((elem) => {
                      return (
                        <option value={elem["position"]} key={elem["position"]}>
                          {elem["position"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border Radius (Default : 10)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="border-radius"
                    type="number"
                    placeholder="Border Radius"
                    value={settingFormData?.[".service-slider .service-box"][
                      "border-radius"
                    ]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeServiceSliderBox(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Margin (Default : 10)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="margin"
                    type="number"
                    placeholder="Margin"
                    value={settingFormData?.[".service-slider .service-box"][
                      "margin"
                    ]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeServiceSliderBox(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">
                  Make change in service-slider | service-box | servicediv
                </h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Background Color (Default : black)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="background-color"
                    type="color"
                    placeholder="Background Color"
                    value={
                      settingFormData?.[
                        ".service-slider .service-box .servicediv"
                      ]?.["background-color"]
                    }
                    onChange={(e) => handleChangeSectionSliderBoxDiv(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border Radius (Default : 10)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="border-radius"
                    type="number"
                    placeholder="Border Radius"
                    value={settingFormData?.[
                      ".service-slider .service-box .servicediv"
                    ]?.["border-radius"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeSectionSliderBoxDiv(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">
                  Make change in service-slider | service-box | service-img
                </h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border Radius (Default : 10)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="border-radius"
                    type="number"
                    placeholder="Border Radius"
                    value={settingFormData?.[
                      ".service-slider .service-box .service-img"
                    ]?.["border-radius"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeSectionSliderBoxDivImg(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row> */}
      <ProductImageUploadPopup
        allPopupState={allPopupState}
        setAllPopupState={setAllPopupState}
        crop={crop}
        setCrop={setCrop}
        completedCrop={completedCrop}
        setCompletedCrop={setCompletedCrop}
        scale={scale}
        rotate={rotate}
        image={settingFormData?.image}
        setImage={setSettingFormData}
        imageUpload={imageUpload}
        isEdit={isEdit}
        reupload={reupload}
        // aspect={1.93 / 1}
      />
    </>
  );
};

export default ServiceThemeOne;
