import React, { useState } from "react";
import CommonStylesChangesContext from "./CommonStylesChangesContext";

const CommonStylesChangesState = (props) => {
  const [allStyle, setAllStyle] = useState({
    ".section-title-area .section-subtitle": {
      color: {
        r: 255,
        g: 73,
        b: 124,
        a: 1,
      },
      "background-color": {
        r: 191,
        g: 52,
        b: 105,
        a: 0.2,
      },
    },
    ".section-title-area .section-title": {
      color: {
        r: 0,
        g: 0,
        b: 0,
        a: 1,
      },
      "font-size": "44px",
    },
    ".more-btn": {
      color: {
        r: 255,
        g: 255,
        b: 255,
        a: 1,
      },
      "background-color": {
        r: 255,
        g: 73,
        b: 124,
        a: 1,
      },
    },
    ".more-btn:hover": {
      color: {
        r: 255,
        g: 255,
        b: 255,
        a: 1,
      },
      "background-color": {
        r: 226,
        g: 18,
        b: 76,
        a: 100,
      },
    },
    ".slick-arrow::before": {
      color: {
        r: 255,
        g: 73,
        b: 124,
        a: 1,
      },
    },
    ".slick-dots li.slick-active button:before": {
      color: {
        r: 255,
        g: 73,
        b: 124,
        a: 1,
      },
    },
    ".slick-dots li button:before": {
      color: {
        r: 255,
        g: 73,
        b: 124,
        a: 1,
      },
    },
    mainDefValue: {
      colorOne: {
        r: 255,
        g: 73,
        b: 124,
        a: 1,
      },
      colorTwo: {
        r: 255,
        g: 255,
        b: 255,
        a: 1,
      },
      mainPadding: "100px",
      titleBottomMargin: "50px",
    },
  });
  return (
    <CommonStylesChangesContext.Provider value={{ allStyle, setAllStyle }}>
      {props.children}
    </CommonStylesChangesContext.Provider>
  );
};

export default CommonStylesChangesState;
