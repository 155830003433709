export const SERVICES = {
  BYTE_MULTI_WEB: "byte-multi-web",
  BYTE_QR_NFC_PUBLIC: "byte-qr-nfc/public",
  BYTE_MULTI_WEB_PUBLIC: "byte-multi-web/public",
  ADMIN: "/admin",
};

export const V1 = "/api/v1";
export const WEB_MASTER = "/webMaster";
/**Apis endpoints */
export const SLIDER = "/slider";
export const DETAILS_SECTION = "/detailSection";
export const OUR_TEAM = "/ourTeam";
export const MEMBER = "/member";
export const ABOUT_US = "/aboutUs";
export const SEO_DETAILS = "/seoDetail";
export const CONTACT_US = "/contactUs";
export const DOWNLOAD = "/download";
export const COMPANY_LOGO = "/companyLogoTaglineService";
export const VIDEOS = "/video";
export const GALLERY = "/gallery";
export const LATEST_NEWS = "/latestNews";
export const ITEM_FEATURE = "/itemFeature";
export const PACKAGES = "/package";
export const SERVICE_COUNTER = "/serviceCounter";
export const OUR_CLIENT = "/ourClient";
export const IMAGE_BANNER = "/imageBanner";
export const VIDEO_BANNER = "/videoBanner";
export const FAQS = "/faqs";
export const INQUIRY = "/Inquiry";
export const STEPS = "/steps";
export const CAREER = "/career";
export const WHATS_APP_LINK = "/getLinkOnWhatsApp";
export const BLOG = "/blog/category";
export const BLOG_DETAIL = "/blog/detail";
export const SERVICE_CATEGORY = "/service/category";
export const SERVICE_ = "/service";
export const IMAGE = "/image";
export const ITEM = "/item";
export const TESTIMONIAL = "/testimonial";
export const PRODUCT = "/product";
export const PRODUCT_BUY = "/productBuy";
export const FOOTER = "/footer";
export const POPUP_DIALOG = "/popupdialog";
export const CUSTOM_FORM = "/customForm";
export const CUSTOM_FORM_FIELD = "/customForm/field";
export const SOCIAL_MEDIA = "/socialMedia";
export const SOCIAL_MEDIA_MASTER = "/master";
export const PAYMENT = "/payment";
export const WEB_PAGE_CONFIG = "/webPageConfig";
export const NAVBAR = "/navbar";
export const PAGE_TITLE = "/pageTitle";

export const splitPath = "byte-qr/";
export const splitPathWeb = "dev-byte-qr-nfc/";
export const downloadSplit = "/Download";

/**details & status api endpoint */
export const DETAILS = "/detail";
export const ENABLE_DISABLE = "/enableDisable";
export const ORDER = "/order";

export const CHANGE_SUB_MENU = `${SERVICES.BYTE_MULTI_WEB}/admin${V1}/webMaster/webNavBarMap/multipleMenu`;
export const CHANGE_POSITION_CHANGE_ = `${SERVICES.BYTE_MULTI_WEB}/admin${V1}/webMaster/webPageConfig/order`;
export const WEB_NAV_BAR_CREATE = `${SERVICES.BYTE_MULTI_WEB}/admin${V1}/webMaster/webNavBarMap`;
export const WEB_CONTENT_MAP_LIST = `${SERVICES.BYTE_MULTI_WEB}/admin${V1}/webMaster/webContentMap`;
export const WEB_CONTENT_MASTER_CREATE = `${SERVICES.BYTE_MULTI_WEB}/admin${V1}/webMaster/webContentMap`;
export const WEB_NAV_BAR_DETAIL = `${SERVICES.BYTE_MULTI_WEB}/admin${V1}/webMaster/webNavBarMap/detail`;
// export const WEB_CONFIG_PAGE_CREATE = `${SERVICES.BYTE_MULTI_WEB}/admin${V1}/webMaster/webPageConfig`;
export const WEB_CONFIG_PAGE_CREATE = `${SERVICES.BYTE_MULTI_WEB}/admin${V1}/webMaster/webPageConfig/withContentMap`;
export const WEB_NAV_BAR_MAP_LIST = `${SERVICES.BYTE_MULTI_WEB}/admin${V1}/webMaster/webNavBarMap`;
export const WEB_NAV_BAR_MAP_WITH_CONTENT_LIST = `${SERVICES.BYTE_MULTI_WEB}/admin${V1}/webMaster/webNavBarMapWithContent`;
export const WEB_NAV_BAR_CONFIG_LIST = `${SERVICES.BYTE_MULTI_WEB}/admin${V1}/webMaster/webPageConfig`;
export const WEB_CONTENT_MASTER_LIST = `${SERVICES.BYTE_MULTI_WEB}/admin${V1}/webMaster/contentMaster`;
export const WEB_NAV_BAR_MASTER_LIST = `${SERVICES.BYTE_MULTI_WEB}/admin${V1}/webMaster/webNavBarMaster`;

// style back up
export const STYLE_BACK_UP_UPDATE = `${SERVICES.BYTE_MULTI_WEB}/admin${V1}/webMaster/styleBackUp`;
export const STYLE_THEME_UPDATE = `${SERVICES.BYTE_MULTI_WEB}/admin${V1}/webMaster/changeThemeColor`;
export const GET_STYLE_THEME = `${SERVICES.BYTE_MULTI_WEB}${V1}/webMaster/changeThemeColor`;
export const STATUS_CHANGE_NAV = `${SERVICES.BYTE_MULTI_WEB}/admin${V1}/webMaster/webNavBarMap/enableDisable`;

/** check sub domain */
export const CHECK_MULTI_SUB_DOMAIN = `${SERVICES.BYTE_MULTI_WEB}${V1}${WEB_MASTER}/checkDomain`;
export const UPDATE_MULTI_DOMAIN_NAME = `${SERVICES.BYTE_MULTI_WEB}${V1}${WEB_MASTER}/domain`;
export const UPDATE_MULTI_NAV_STYLE = `${SERVICES.BYTE_MULTI_WEB}${V1}${WEB_MASTER}`;

// export const BYTE_MULTI_WEB_DASHBOARD = `${SERVICES.BYTE_MULTI_WEB}${V1}${WEB_MASTER}/dashboard`;
export const BYTE_MULTI_WEB_DASHBOARD = `${SERVICES.BYTE_MULTI_WEB}${V1}${WEB_MASTER}/dashboardNew`;
export const GET_NAVBAR = `${SERVICES.BYTE_MULTI_WEB}${V1}${WEB_MASTER}${NAVBAR}`;
export const BYTE_WEB_FILE_UPLOAD = `${SERVICES.BYTE_QR_NFC_PUBLIC}${V1}/mini-file`;
export const BYTE_MULTI_WEB_FILE_UPLOAD = `${SERVICES.BYTE_MULTI_WEB_PUBLIC}${V1}/file`;
export const BYTE_WEB_DELETE_FILE = `${SERVICES.BYTE_QR_NFC_PUBLIC}${V1}/file`;
export const BYTE_MULTI_WEB_DELETE_FILE = `${SERVICES.BYTE_MULTI_WEB_PUBLIC}${V1}/file`;
export const WEB_NAV_BAR_NAV_MAP_ID = `${SERVICES.BYTE_MULTI_WEB}${V1}/webMaster/navbar`;

/**Slider */
export const GET_SLIDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${SLIDER}`;
export const PUT_STATUS_SLIDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${SLIDER}${ENABLE_DISABLE}`;
export const POST_SLIDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${SLIDER}`;
export const GET_SLIDER_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${SLIDER}${DETAILS}`;
export const PUT_SLIDER_ORDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${SLIDER}${ORDER}`;

/**latest news*/
export const GET_LATEST_NEWS = `${SERVICES.BYTE_MULTI_WEB}${V1}${LATEST_NEWS}`;
export const PUT_STATUS_LATEST_NEWS = `${SERVICES.BYTE_MULTI_WEB}${V1}${LATEST_NEWS}${ENABLE_DISABLE}`;
export const POST_LATEST_NEWS = `${SERVICES.BYTE_MULTI_WEB}${V1}${LATEST_NEWS}`;
export const GET_LATEST_NEWS_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${LATEST_NEWS}${DETAILS}`;
export const PUT_LATEST_NEWS_ORDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${LATEST_NEWS}${ORDER}`;

/**About us */
export const GET_ABOUT_US = `${SERVICES.BYTE_MULTI_WEB}${V1}${ABOUT_US}`;
export const PUT_STATUS_ABOUT_US = `${SERVICES.BYTE_MULTI_WEB}${V1}${ABOUT_US}${ENABLE_DISABLE}`;
export const POST_ABOUT_US = `${SERVICES.BYTE_MULTI_WEB}${V1}${ABOUT_US}`;
export const GET_ABOUT_US_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${ABOUT_US}${DETAILS}`;
export const PUT_ABOUT_US_ORDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${ABOUT_US}${ORDER}`;

export const PUT_LABEL = `${SERVICES.BYTE_MULTI_WEB}${SERVICES.ADMIN}${V1}${WEB_MASTER}${WEB_PAGE_CONFIG}`;

/**service*/
export const GET_SERVICE_ = `${SERVICES.BYTE_MULTI_WEB}${V1}${SERVICE_}`;
export const PUT_STATUS_SERVICE_ = `${SERVICES.BYTE_MULTI_WEB}${V1}${SERVICE_}${ENABLE_DISABLE}`;
export const POST_SERVICE_ = `${SERVICES.BYTE_MULTI_WEB}${V1}${SERVICE_}`;
export const GET_SERVICE__DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${SERVICE_}${DETAILS}`;
export const PUT_SERVICE__ORDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${SERVICE_}${ORDER}`;

//service category

export const GET_SERVICE_CATEGORY = `${SERVICES.BYTE_MULTI_WEB}${V1}${SERVICE_CATEGORY}`;
export const PUT_STATUS_SERVICE_CATEGORY = `${SERVICES.BYTE_MULTI_WEB}${V1}${SERVICE_CATEGORY}${ENABLE_DISABLE}`;
export const POST_SERVICE_CATEGORY = `${SERVICES.BYTE_MULTI_WEB}${V1}${SERVICE_CATEGORY}`;
export const GET_SERVICE_CATEGORY_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${SERVICE_CATEGORY}${DETAILS}`;
export const PUT_SERVICE_CATEGORY_ORDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${SERVICE_CATEGORY}${ORDER}`;

/**page title */
export const GET_PAGE_TITLE = `${SERVICES.BYTE_MULTI_WEB}${V1}${PAGE_TITLE}`;
export const PUT_STATUS_PAGE_TITLE = `${SERVICES.BYTE_MULTI_WEB}${V1}${PAGE_TITLE}${ENABLE_DISABLE}`;
export const POST_PAGE_TITLE = `${SERVICES.BYTE_MULTI_WEB}${V1}${PAGE_TITLE}`;
export const GET_PAGE_TITLE_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${PAGE_TITLE}${DETAILS}`;
export const PUT_PAGE_TITLE_ORDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${PAGE_TITLE}${ORDER}`;

/**item feature*/
export const GET_ITEM_FEATURE = `${SERVICES.BYTE_MULTI_WEB}${V1}${ITEM_FEATURE}`;
export const PUT_STATUS_ITEM_FEATURE = `${SERVICES.BYTE_MULTI_WEB}${V1}${ITEM_FEATURE}${ENABLE_DISABLE}`;
export const POST_ITEM_FEATURE = `${SERVICES.BYTE_MULTI_WEB}${V1}${ITEM_FEATURE}`;
export const GET_ITEM_FEATURE_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${ITEM_FEATURE}${DETAILS}`;
export const PUT_ITEM_FEATURE_ORDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${ITEM_FEATURE}${ORDER}`;

/**Social media  */
export const GET_SOCIAL_MEDIA = `${SERVICES.BYTE_MULTI_WEB}${V1}${SOCIAL_MEDIA}`;
export const PUT_STATUS_SOCIAL_MEDIA = `${SERVICES.BYTE_MULTI_WEB}${V1}${SOCIAL_MEDIA}${ENABLE_DISABLE}`;
export const POST_SOCIAL_MEDIA = `${SERVICES.BYTE_MULTI_WEB}${V1}${SOCIAL_MEDIA}`;
export const GET_SOCIAL_MEDIA_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${SOCIAL_MEDIA}${DETAILS}`;
export const PUT_SOCIAL_MEDIA_ORDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${SOCIAL_MEDIA}${ORDER}`;

//social media master

export const GET_SOCIAL_MEDIA_MASTER = `${SERVICES.BYTE_MULTI_WEB}${V1}${SOCIAL_MEDIA}${SOCIAL_MEDIA_MASTER}`;
export const PUT_STATUS_SOCIAL_MEDIA_MASTER = `${SERVICES.BYTE_MULTI_WEB}${V1}${SOCIAL_MEDIA}${SOCIAL_MEDIA_MASTER}${ENABLE_DISABLE}`;
export const POST_SOCIAL_MEDIA_MASTER = `${SERVICES.BYTE_MULTI_WEB}${V1}${SOCIAL_MEDIA}${SOCIAL_MEDIA_MASTER}`;
export const GET_SOCIAL_MEDIA_MASTER_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${SOCIAL_MEDIA}${SOCIAL_MEDIA_MASTER}${DETAILS}`;
export const PUT_SOCIAL_MEDIA_MASTER_ORDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${SOCIAL_MEDIA}${SOCIAL_MEDIA_MASTER}${ORDER}`;

/**Our Team master*/
export const GET_OUR_TEAM_MEMBER = `${SERVICES.BYTE_MULTI_WEB}${V1}${OUR_TEAM}${MEMBER}`;
export const PUT_STATUS_OUR_TEAM_MEMBER = `${SERVICES.BYTE_MULTI_WEB}${V1}${OUR_TEAM}${MEMBER}${ENABLE_DISABLE}`;
export const POST_OUR_TEAM_MEMBER = `${SERVICES.BYTE_MULTI_WEB}${V1}${OUR_TEAM}${MEMBER}`;
export const GET_OUR_TEAM_MEMBER_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${OUR_TEAM}${MEMBER}${DETAILS}`;
export const PUT_OUR_TEAM_MEMBER_ORDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${OUR_TEAM}${MEMBER}${ORDER}`;
export const CHECK_SLUG_URL_OUR_TEAM_MEMBER = `${SERVICES.BYTE_MULTI_WEB}${V1}${OUR_TEAM}${MEMBER}/checkSlugUrl`;

//our team

export const GET_OUR_TEAM = `${SERVICES.BYTE_MULTI_WEB}${V1}${OUR_TEAM}`;
export const PUT_STATUS_OUR_TEAM = `${SERVICES.BYTE_MULTI_WEB}${V1}${OUR_TEAM}${ENABLE_DISABLE}`;
export const POST_OUR_TEAM = `${SERVICES.BYTE_MULTI_WEB}${V1}${OUR_TEAM}`;
export const GET_OUR_TEAM_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${OUR_TEAM}${DETAILS}`;
export const PUT_OUR_TEAM_ORDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${OUR_TEAM}${ORDER}`;
export const CHECK_SLUG_URL_OUR_TEAM = `${SERVICES.BYTE_MULTI_WEB}${V1}${OUR_TEAM}/checkSlugUrl`;

/**images*/
export const GET_GALLERY = `${SERVICES.BYTE_MULTI_WEB}${V1}${GALLERY}`;
export const PUT_STATUS_GALLERY = `${SERVICES.BYTE_MULTI_WEB}${V1}${GALLERY}${ENABLE_DISABLE}`;
export const POST_GALLERY = `${SERVICES.BYTE_MULTI_WEB}${V1}${GALLERY}`;
export const GET_GALLERY_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${GALLERY}${DETAILS}`;
export const PUT_GALLERY_ORDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${GALLERY}${ORDER}`;

//images items

export const GET_IMAGE = `${SERVICES.BYTE_MULTI_WEB}${V1}${GALLERY}${IMAGE}`;
export const PUT_STATUS_IMAGE = `${SERVICES.BYTE_MULTI_WEB}${V1}${GALLERY}${IMAGE}${ENABLE_DISABLE}`;
export const POST_IMAGE = `${SERVICES.BYTE_MULTI_WEB}${V1}${GALLERY}${IMAGE}`;
export const GET_IMAGE_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${GALLERY}${IMAGE}${DETAILS}`;
export const PUT_IMAGE_ORDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${GALLERY}${IMAGE}${ORDER}`;

/**video banner*/
export const GET_VIDEO_BANNER = `${SERVICES.BYTE_MULTI_WEB}${V1}${VIDEO_BANNER}`;
export const PUT_STATUS_VIDEO_BANNER = `${SERVICES.BYTE_MULTI_WEB}${V1}${VIDEO_BANNER}${ENABLE_DISABLE}`;
export const POST_VIDEO_BANNER = `${SERVICES.BYTE_MULTI_WEB}${V1}${VIDEO_BANNER}`;
export const GET_VIDEO_BANNER_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${VIDEO_BANNER}${DETAILS}`;
export const PUT_VIDEO_BANNER_ORDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${VIDEO_BANNER}${ORDER}`;

/**image banner*/
export const GET_IMAGE_BANNER = `${SERVICES.BYTE_MULTI_WEB}${V1}${IMAGE_BANNER}`;
export const PUT_STATUS_IMAGE_BANNER = `${SERVICES.BYTE_MULTI_WEB}${V1}${IMAGE_BANNER}${ENABLE_DISABLE}`;
export const POST_IMAGE_BANNER = `${SERVICES.BYTE_MULTI_WEB}${V1}${IMAGE_BANNER}`;
export const GET_IMAGE_BANNER_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${IMAGE_BANNER}${DETAILS}`;
export const PUT_IMAGE_BANNER_ORDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${IMAGE_BANNER}${ORDER}`;

/**faqs*/
export const GET_FAQS = `${SERVICES.BYTE_MULTI_WEB}${V1}${FAQS}`;
export const PUT_STATUS_FAQS = `${SERVICES.BYTE_MULTI_WEB}${V1}${FAQS}${ENABLE_DISABLE}`;
export const POST_FAQS = `${SERVICES.BYTE_MULTI_WEB}${V1}${FAQS}`;
export const GET_FAQS_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${FAQS}${DETAILS}`;
export const PUT_FAQS_ORDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${FAQS}${ORDER}`;

/**inquiry*/
export const GET_INQUIRY = `${SERVICES.BYTE_MULTI_WEB}${V1}${INQUIRY}`;
export const PUT_STATUS_INQUIRY = `${SERVICES.BYTE_MULTI_WEB}${V1}${INQUIRY}${ENABLE_DISABLE}`;
export const POST_INQUIRY = `${SERVICES.BYTE_MULTI_WEB}${V1}${INQUIRY}`;
export const GET_INQUIRY_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${INQUIRY}${DETAILS}`;
export const PUT_INQUIRY_ORDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${INQUIRY}${ORDER}`;

/**Details */
export const GET_DOWNLOAD = `${SERVICES.BYTE_MULTI_WEB}${V1}${DOWNLOAD}`;
export const PUT_STATUS_DOWNLOAD = `${SERVICES.BYTE_MULTI_WEB}${V1}${DOWNLOAD}${ENABLE_DISABLE}`;
export const POST_DOWNLOAD = `${SERVICES.BYTE_MULTI_WEB}${V1}${DOWNLOAD}`;
export const GET_DETAILS_DOWNLOAD = `${SERVICES.BYTE_MULTI_WEB}${V1}${DOWNLOAD}${DETAILS}`;
export const PUT_DOWNLOAD_ORDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${DOWNLOAD}${ORDER}`;

/**blog detail*/
export const GET_BLOG_DETAIL = `${SERVICES.BYTE_MULTI_WEB}${V1}${BLOG_DETAIL}`;
export const PUT_STATUS_BLOG_DETAIL = `${SERVICES.BYTE_MULTI_WEB}${V1}${BLOG_DETAIL}${ENABLE_DISABLE}`;
export const POST_BLOG_DETAIL = `${SERVICES.BYTE_MULTI_WEB}${V1}${BLOG_DETAIL}`;
export const GET_BLOG_DETAIL_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${BLOG_DETAIL}${DETAILS}`;
export const PUT_BLOG_DETAIL_ORDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${BLOG_DETAIL}${ORDER}`;

//blog

export const GET_BLOG = `${SERVICES.BYTE_MULTI_WEB}${V1}${BLOG}`;
export const PUT_STATUS_BLOG = `${SERVICES.BYTE_MULTI_WEB}${V1}${BLOG}${ENABLE_DISABLE}`;
export const POST_BLOG = `${SERVICES.BYTE_MULTI_WEB}${V1}${BLOG}`;
export const GET_BLOG_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${BLOG}${DETAILS}`;
export const PUT_BLOG_ORDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${BLOG}${ORDER}`;

/**Testimonial */
export const GET_TESTIMONIAL = `${SERVICES.BYTE_MULTI_WEB}${V1}${TESTIMONIAL}`;
export const PUT_STATUS_TESTIMONIAL = `${SERVICES.BYTE_MULTI_WEB}${V1}${TESTIMONIAL}${ENABLE_DISABLE}`;
export const POST_TESTIMONIAL = `${SERVICES.BYTE_MULTI_WEB}${V1}${TESTIMONIAL}`;
export const GET_TESTIMONIAL_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${TESTIMONIAL}${DETAILS}`;
export const PUT_TESTIMONIAL_ORDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${TESTIMONIAL}${ORDER}`;

/**packages*/
export const GET_PACKAGES = `${SERVICES.BYTE_MULTI_WEB}${V1}${PACKAGES}`;
export const PUT_STATUS_PACKAGES = `${SERVICES.BYTE_MULTI_WEB}${V1}${PACKAGES}${ENABLE_DISABLE}`;
export const POST_PACKAGES = `${SERVICES.BYTE_MULTI_WEB}${V1}${PACKAGES}`;
export const GET_PACKAGES_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${PACKAGES}${DETAILS}`;
export const PUT_PACKAGES_ORDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${PACKAGES}${ORDER}`;

/**Videos */
export const GET_VIDEOS = `${SERVICES.BYTE_MULTI_WEB}${V1}${VIDEOS}`;
export const PUT_STATUS_VIDEOS = `${SERVICES.BYTE_MULTI_WEB}${V1}${VIDEOS}${ENABLE_DISABLE}`;
export const POST_VIDEOS = `${SERVICES.BYTE_MULTI_WEB}${V1}${VIDEOS}`;
export const GET_VIDEOS_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${VIDEOS}${DETAILS}`;
export const PUT_VIDEOS_ORDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${VIDEOS}${ORDER}`;

//videos items

export const GET_VIDEOS_ITEM = `${SERVICES.BYTE_MULTI_WEB}${V1}${VIDEOS}${ITEM}`;
export const PUT_STATUS_VIDEOS_ITEM = `${SERVICES.BYTE_MULTI_WEB}${V1}${VIDEOS}${ITEM}${ENABLE_DISABLE}`;
export const POST_VIDEOS_ITEM = `${SERVICES.BYTE_MULTI_WEB}${V1}${VIDEOS}${ITEM}`;
export const GET_VIDEOS_ITEM_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${VIDEOS}${ITEM}${DETAILS}`;
export const PUT_VIDEOS_ITEM_ORDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${VIDEOS}${ITEM}${ORDER}`;

/**get whats app link*/
export const GET_WHATS_APP_LINK = `${SERVICES.BYTE_MULTI_WEB}${V1}${WHATS_APP_LINK}`;
export const PUT_STATUS_WHATS_APP_LINK = `${SERVICES.BYTE_MULTI_WEB}${V1}${WHATS_APP_LINK}${ENABLE_DISABLE}`;
export const POST_WHATS_APP_LINK = `${SERVICES.BYTE_MULTI_WEB}${V1}${WHATS_APP_LINK}`;
export const GET_WHATS_APP_LINK_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${WHATS_APP_LINK}${DETAILS}`;
export const PUT_WHATS_APP_LINK_ORDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${WHATS_APP_LINK}${ORDER}`;

/**steps*/
export const GET_STEPS = `${SERVICES.BYTE_MULTI_WEB}${V1}${STEPS}`;
export const PUT_STATUS_STEPS = `${SERVICES.BYTE_MULTI_WEB}${V1}${STEPS}${ENABLE_DISABLE}`;
export const POST_STEPS = `${SERVICES.BYTE_MULTI_WEB}${V1}${STEPS}`;
export const GET_STEPS_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${STEPS}${DETAILS}`;
export const PUT_STEPS_ORDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${STEPS}${ORDER}`;

/**service counter*/
export const GET_SERVICE_COUNTER = `${SERVICES.BYTE_MULTI_WEB}${V1}${SERVICE_COUNTER}`;
export const PUT_STATUS_SERVICE_COUNTER = `${SERVICES.BYTE_MULTI_WEB}${V1}${SERVICE_COUNTER}${ENABLE_DISABLE}`;
export const POST_SERVICE_COUNTER = `${SERVICES.BYTE_MULTI_WEB}${V1}${SERVICE_COUNTER}`;
export const GET_SERVICE_COUNTER_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${SERVICE_COUNTER}${DETAILS}`;
export const PUT_SERVICE_COUNTER_ORDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${SERVICE_COUNTER}${ORDER}`;
/**seo details */
export const GET_SEO_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${SEO_DETAILS}`;
export const PUT_STATUS_SEO_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${SEO_DETAILS}${ENABLE_DISABLE}`;
export const POST_SEO_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${SEO_DETAILS}`;
export const GET_SEO_DETAILS_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${SEO_DETAILS}${DETAILS}`;
export const PUT_SEO_DETAILS_ORDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${SEO_DETAILS}${ORDER}`;

/**career*/
export const GET_CAREER = `${SERVICES.BYTE_MULTI_WEB}${V1}${CAREER}`;
export const PUT_STATUS_CAREER = `${SERVICES.BYTE_MULTI_WEB}${V1}${CAREER}${ENABLE_DISABLE}`;
export const POST_CAREER = `${SERVICES.BYTE_MULTI_WEB}${V1}${CAREER}`;
export const GET_CAREER_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${CAREER}${DETAILS}`;
export const PUT_CAREER_ORDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${CAREER}${ORDER}`;

/**Contact us */
export const GET_CONTACT_US = `${SERVICES.BYTE_MULTI_WEB}${V1}${CONTACT_US}`;
export const PUT_STATUS_CONTACT_US = `${SERVICES.BYTE_MULTI_WEB}${V1}${CONTACT_US}${ENABLE_DISABLE}`;
export const POST_CONTACT_US = `${SERVICES.BYTE_MULTI_WEB}${V1}${CONTACT_US}`;
export const GET_DETAILS_CONTACT_US = `${SERVICES.BYTE_MULTI_WEB}${V1}${CONTACT_US}${DETAILS}`;
export const PUT_CONTACT_US_ORDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${CONTACT_US}${ORDER}`;

/**our client*/
export const GET_OUR_CLIENT = `${SERVICES.BYTE_MULTI_WEB}${V1}${OUR_CLIENT}`;
export const PUT_STATUS_OUR_CLIENT = `${SERVICES.BYTE_MULTI_WEB}${V1}${OUR_CLIENT}${ENABLE_DISABLE}`;
export const POST_OUR_CLIENT = `${SERVICES.BYTE_MULTI_WEB}${V1}${OUR_CLIENT}`;
export const GET_OUR_CLIENT_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${OUR_CLIENT}${DETAILS}`;
export const PUT_OUR_CLIENT_ORDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${OUR_CLIENT}${ORDER}`;

/**Details */
export const GET_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${DETAILS_SECTION}`;
export const PUT_STATUS_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${DETAILS_SECTION}${ENABLE_DISABLE}`;
export const POST_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${DETAILS_SECTION}`;
export const GET_DETAILS_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${DETAILS_SECTION}${DETAILS}`;
export const PUT_DETAILS_ORDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${DETAILS_SECTION}${ORDER}`;

/**Product */
export const GET_PRODUCT = `${SERVICES.BYTE_MULTI_WEB}${V1}${PRODUCT}`;
export const PUT_STATUS_PRODUCT = `${SERVICES.BYTE_MULTI_WEB}${V1}${PRODUCT}${ENABLE_DISABLE}`;
export const POST_PRODUCT = `${SERVICES.BYTE_MULTI_WEB}${V1}${PRODUCT}`;
export const GET_PRODUCT_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${PRODUCT}${DETAILS}`;
export const PUT_PRODUCT_ORDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${PRODUCT}${ORDER}`;

/**Popup Dialog */
export const GET_POPUP_DIALOG = `${SERVICES.BYTE_MULTI_WEB}${V1}${POPUP_DIALOG}`;
export const PUT_STATUS_POPUP_DIALOG = `${SERVICES.BYTE_MULTI_WEB}${V1}${POPUP_DIALOG}${ENABLE_DISABLE}`;
export const POST_POPUP_DIALOG = `${SERVICES.BYTE_MULTI_WEB}${V1}${POPUP_DIALOG}`;
export const GET_POPUP_DIALOG_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${POPUP_DIALOG}${DETAILS}`;
export const PUT_POPUP_DIALOG_ORDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${POPUP_DIALOG}${ORDER}`;

/**Custom Form */
export const GET_CUSTOM_FORM = `${SERVICES.BYTE_MULTI_WEB}${V1}${CUSTOM_FORM}`;
export const PUT_STATUS_CUSTOM_FORM = `${SERVICES.BYTE_MULTI_WEB}${V1}${CUSTOM_FORM}${ENABLE_DISABLE}`;
export const POST_CUSTOM_FORM = `${SERVICES.BYTE_MULTI_WEB}${V1}${CUSTOM_FORM}`;
export const GET_CUSTOM_FORM_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${CUSTOM_FORM}${DETAILS}`;
export const PUT_CUSTOM_FORM_ORDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${CUSTOM_FORM}${ORDER}`;
/**Custom Form Field */
export const GET_CUSTOM_FORM_FIELD = `${SERVICES.BYTE_MULTI_WEB}${V1}${CUSTOM_FORM_FIELD}`;
export const PUT_STATUS_CUSTOM_FORM_FIELD = `${SERVICES.BYTE_MULTI_WEB}${V1}${CUSTOM_FORM_FIELD}${ENABLE_DISABLE}`;
export const POST_CUSTOM_FORM_FIELD = `${SERVICES.BYTE_MULTI_WEB}${V1}${CUSTOM_FORM_FIELD}`;
export const GET_CUSTOM_FORM_FIELD_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${CUSTOM_FORM_FIELD}${DETAILS}`;
export const PUT_CUSTOM_FORM_FIELD_ORDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${CUSTOM_FORM_FIELD}${ORDER}`;

/**ProductBuy */
export const GET_PRODUCT_BUY = `${SERVICES.BYTE_MULTI_WEB}${V1}${PRODUCT_BUY}`;
export const PUT_STATUS_PRODUCT_BUY = `${SERVICES.BYTE_MULTI_WEB}${V1}${PRODUCT_BUY}${ENABLE_DISABLE}`;
export const POST_PRODUCT_BUY = `${SERVICES.BYTE_MULTI_WEB}${V1}${PRODUCT_BUY}`;
export const GET_PRODUCT_BUY_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${PRODUCT_BUY}${DETAILS}`;
export const PUT_PRODUCT_BUY_ORDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${PRODUCT_BUY}${ORDER}`;

/**Footer */
export const GET_FOOTER = `${SERVICES.BYTE_MULTI_WEB}${V1}${FOOTER}`;
export const PUT_STATUS_FOOTER = `${SERVICES.BYTE_MULTI_WEB}${V1}${FOOTER}${ENABLE_DISABLE}`;
export const POST_FOOTER = `${SERVICES.BYTE_MULTI_WEB}${V1}${FOOTER}`;
export const GET_FOOTER_DETAILS = `${SERVICES.BYTE_MULTI_WEB}${V1}${FOOTER}${DETAILS}`;
export const PUT_FOOTER_ORDER = `${SERVICES.BYTE_MULTI_WEB}${V1}${FOOTER}${ORDER}`;
