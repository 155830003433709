import React, { useContext, useMemo, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import {
  Cases,
  DELETE_CONFIRM,
  modalsName,
  options,
  socialMediaTypesMultipage,
} from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import CommonPopup from "../../../Modals/commonPopup";
import SocialMediaMultipagePopup from "./SocialMediaMultipagePopup";
import EditIcon from "@mui/icons-material/Edit";
import SocialMediaMultipageParentPopup from "./SocialMediaMultipageParentPopup";
import { FILE_UPLOAD_API } from "../apis/SliderApi/SliderApi";
import {
  addUrlImage,
  convertRGBA,
  isCheckValue,
  isEmptyObject,
  randomStr,
  removeUrlImage,
  removeUrlKeyInPath,
  rgbColorCreate,
} from "../../../../utils/helper";
import { FILE_TYPE, ThemeNo, titleUpdateKey } from "../../../../constant";
import { IconButton } from "@material-ui/core";
import { Settings, Trash } from "react-feather";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import SimpleReactValidator from "simple-react-validator";
import SocialMediaMultipageContext from "../../../context/SocialMediaMultipageContext/SocialMediaMultipageContext";
import {
  deleteSocialMedia,
  getSocialMedia,
  getSocialMediaDetails,
  postSocialMedia,
  putUpdateSocialMedia,
  updateStatus,
} from "../apis/SocialMediaApi/SocialMediaApi";
import SettingSocialMediaPerentPopup from "./SettingSocialMediaPerentPopup";
import DeleteSection from "../../../common/DeleteSection";
import SettingMultiWebContext from "../../../../context/SettingMultiWebContext/SettingMultiWebContext";
import {
  SocialMediaStyleOneSubFun,
  SocialMediaTHmeOne,
  SocialMediaThemeOneFunction,
} from "./SocialMediaStyleFunction";

const SocialMediaMultipage = (props) => {
  const {
    pageConfigDetails,
    webMasterDetail,
    navbarData,
    getData,
    contentMapId,
    socialMediaDetail,
    ByteMiniWeb_Dashboard,
    contentDetail,
  } = props;
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );
  const { setCloneAlignModal, setAlignFormData, setCloneFormData } = useContext(
    SettingMultiWebContext
  );
  const parentValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const childValidator = useRef(new SimpleReactValidator());
  const {
    fromData,
    setFormData,
    formDataChild,
    setFormDataChild,
    id,
    setId,
    setIsEdit,
    isEdit,
    setFilter,
    apiDataOurTeam: apiDataImageParent,
    setApiDataOurTeam: setApiDataImageParent,
    setSkill,
    setGalleryImgId,
    setSettingFormData,
    settingFormData,
    isParent,
    setIsParent,
    childId,
    setChildId,
    backImg,
    collId,
    setCollId,
    isBackgroundColor,
    setIsBackgroundColor,
    theme,
    setTheme,
    imgShow,
    setImgShow,
    sectionPageName,
    setSectionPageName,
  } = useContext(SocialMediaMultipageContext);
  const { setLoaderShow } = useContext(LoaderContext);
  /**
   * get social media list
   * @param {status} status
   */
  const getDownloadParentList = async ({
    parentId = null,
    isClose = false,
    filter,
  }) => {
    try {
      let paramObj = {};
      if (
        ((parentId && parentId !== null) ||
          (id && isClose === false && isParent === false)) &&
        isParent === false
      ) {
        paramObj["search"] = { parentId: parentId || id };
        setId(parentId || id);
        if (filter?.length) {
          paramObj["search"] = { ...paramObj.search, label: filter };
        }
      } else {
        paramObj["search"] = { parentId: null };
      }
      const { data } = await getSocialMedia(paramObj);
      if (isCheckValue({ value: parentId })) {
        setChildId(parentId);
      }
      if (data.status === 200) {
        if (
          isParent === false &&
          ((parentId && parentId !== null) || id) &&
          isClose === false
        ) {
          setApiDataImageParent((prev) => {
            return {
              ...prev,
              isLoading: false,
              data: data.payload.data.map((elem) => {
                return {
                  id: elem?.label + elem?.socialMediaId,
                  Id: elem?.socialMediaId,
                  itemId: elem?.itemId,
                  name: elem?.label,
                  des: elem?.value,
                  image: elem?.customIcon,
                  webId: elem?.webId,
                  createdAt: elem?.createdAt,
                  isActive: elem?.isActive,
                  contentMapId: elem?.contentMapId,
                };
              }),
            };
          });
          OpenModalCommonFunc(Cases.SOCIALMEDIA_MULTIPAGE, setAllPopupState);
        } else if (parentId && parentId !== null) {
          OpenModalCommonFunc(Cases.SOCIALMEDIA_MULTIPAGE, setAllPopupState);
          setApiDataImageParent((prev) => {
            return {
              ...prev,
              isLoading: false,
              data: data.payload.data.map((elem) => {
                return {
                  id: elem?.label + elem?.socialMediaId,
                  Id: elem?.socialMediaId,
                  itemId: elem?.itemId,
                  name: elem?.label,
                  des: elem?.value,
                  image: elem?.customIcon,
                  webId: elem?.webId,
                  createdAt: elem?.createdAt,
                  isActive: elem?.isActive,
                };
              }),
            };
          });
        } else {
          setApiDataImageParent((prev) => {
            return {
              ...prev,
              isLoading: false,
              data: data.payload.data,
            };
          });
        }
        if (isClose && isClose !== false) {
          setIsParent(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * social media enable disable api call
   * @param {socialMediaId} socialMediaId
   * @param {status} isActive
   */

  const handleChangeDownload = async (
    socialMediaId,
    isActive,
    isChild,
    parentId
  ) => {
    try {
      let body = {
        socialMediaId,
        isActive: isActive ? false : true,
        parentId,
      };
      const { data } = await updateStatus(body);
      if (data.status === 200) {
        if (isChild) {
          getDownloadParentList({ parentId: id });
        } else {
          ByteMiniWeb_Dashboard();
        }
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * file upload
   * @param {file} file
   */
  const imageUploadImages = async (file) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.SOCIAL_MEDIA);
    formData.append("file", file);
    setLoaderShow(true);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        setLoaderShow(false);
        setFormDataChild((prev) => {
          return {
            ...prev,
            image: data.payload.baseUrl + data.payload.imageUrl,
          };
        });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };
  const imageUploadImagesParent = async (file) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.SOCIAL_MEDIA);
    formData.append("file", file);
    setLoaderShow(true);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        setLoaderShow(false);
        setFormData((prev) => {
          return {
            ...prev,
            image: data.payload.baseUrl + data.payload.imageUrl,
          };
        });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };
  /**
   * file upload
   * @param {file} file
   */
  const BackImageUploadImages = async (file) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.SOCIAL_MEDIA);
    formData.append("file", file);
    setLoaderShow(true);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        setLoaderShow(false);

        setSettingFormData((prev) => {
          if (backImg === ".custom-footer .foot-social:before") {
            return {
              ...prev,
              ".custom-footer .foot-social:before": {
                "background-image":
                  data.payload.baseUrl + data.payload.imageUrl,
              },
            };
          } else if (backImg === ".custom-footer .foot-social:after") {
            return {
              ...prev,
              ".custom-footer .foot-social:after": {
                "background-image":
                  data.payload.baseUrl + data.payload.imageUrl,
              },
            };
          }
        });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * create and update social media
   */

  const submitForm = async () => {
    if (
      (isParent === false && childId >= -1 && isEdit) ||
      (id >= -1 && isEdit)
    ) {
      setLoaderShow(true);
      try {
        let formData = {};
        if (isParent && isParent !== false) {
          formData = {
            ...fromData,
            socialMediaId: id,
            contentMapId: collId,
            image: fromData.image
              ? fromData.image
                  .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
                  .pop()
              : "",
          };
          setId("");
        } else {
          formData = {
            ...formDataChild,
            parentId: id,
            socialMediaId: childId,
            contentMapId: collId,
          };
          if (formDataChild.type === socialMediaTypesMultipage.custome) {
            formData["customIcon"] = formDataChild.image
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
              .pop();
            setFormDataChild((prev) => {
              return {
                ...prev,
                customIcon: formDataChild.image
                  .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
                  .pop(),
              };
            });
          } else if (
            formDataChild.type === socialMediaTypesMultipage.whatsapp
          ) {
            formData[
              "value"
            ] = `${formDataChild.dialCode.value} ${formDataChild.value}`;
          }
        }
        const { data } = await putUpdateSocialMedia(formData);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            ByteMiniWeb_Dashboard();
          } else {
            getDownloadParentList({ parentId: id });
          }
          emptyData();
          OpenModalCommonFunc(
            Cases.SOCIALMEDIA_MULTIPAGE_PARENT,
            setAllPopupState,
            false
          );
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    } else {
      try {
        setLoaderShow(true);
        let formData = {};
        if (isParent && isParent !== false) {
          formData = {
            ...fromData,
            contentMapId: collId,
            image: fromData.image
              ? fromData.image
                  .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
                  .pop()
              : "",
          };
        } else {
          formData = {
            ...formDataChild,
            parentId: id,
            order: apiDataImageParent.data.length + 1,
            contentMapId: collId,
          };
          if (formDataChild.type === socialMediaTypesMultipage.custome) {
            formData["customIcon"] = formDataChild.image
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
              .pop();
            setFormDataChild((prev) => {
              return {
                ...prev,
                customIcon: formDataChild.image
                  .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
                  .pop(),
              };
            });
          } else if (
            formDataChild.type === socialMediaTypesMultipage.whatsapp
          ) {
            formData[
              "value"
            ] = `${formDataChild.dialCode.value} ${formDataChild.value}`;
          }
        }
        const { data } = await postSocialMedia(formData);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            ByteMiniWeb_Dashboard();
          } else {
            getDownloadParentList({ parentId: id });
          }
          emptyData();
          OpenModalCommonFunc(
            Cases.SOCIALMEDIA_MULTIPAGE_PARENT,
            setAllPopupState,
            false
          );
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  const handleSubmit = async (formValidationCondition) => {
    let validatorFields;
    if (formValidationCondition === "parentForm") {
      validatorFields = parentValidator.current;
    } else if (formValidationCondition === "childForm") {
      validatorFields = childValidator.current;
    }
    validatorFields.showMessages();

    if (validatorFields.allValid()) {
      try {
        // Process form data based on the formType
        if (formValidationCondition === "parentForm") {
          submitForm();
        } else if (formValidationCondition === "childForm") {
          submitForm();
        }
        validatorFields.hideMessages();
      } catch (error) {
        // Handle error for form submission
      }
    } else {
      // Force update to display validation messages
      forceUpdate({});
    }
  };

  /**
   * create and update social media
   */
  const handleSubmitSetting = async (isRestore = false, isClose = true) => {
    if (id >= -1 && isEdit) {
      setLoaderShow(true);
      try {
        let newData;
        if (isRestore) {
          const isConfirm = window.confirm(
            "Are you sure want to restore style ?"
          );
          if (isConfirm) {
            newData = {
              styleRestore: true,
              socialMediaId: id,
            };
          } else {
            setLoaderShow(false);
            return;
          }
        } else {
          if (+theme === ThemeNo.ThemeOne) {
            newData = SocialMediaStyleOneSubFun({
              settingFormData,
            });
            if (isBackgroundColor) {
              newData[".custom-footer"] = {
                ...newData?.[".custom-footer"],
                "background-color": settingFormData[".custom-footer"]?.[
                  "background-color"
                ]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData[".custom-footer"]?.["background-color"],
                    })
                  : "",
              };
            }
          }

          const keysToRemove = [
            "shadowColor",
            "borderPx",
            "borderThik",
            "borderColor",
            "p1",
            "p2",
            "borderBtPx",
            "borderBtThik",
            "borderBtColor",
            "borderLfPx",
            "borderLfThik",
            "borderLfColor",
            "borderRiPx",
            "borderRiThik",
            "borderRiColor",
            "borderPx",
            "borderThik",
            "borderColor",
            "image",
          ];

          function removeKeys(obj, keys) {
            for (const key in obj) {
              if (typeof obj[key] === "object") {
                removeKeys(obj[key], keys);
              } else if (keys.includes(key)) {
                delete obj[key];
              }
            }
          }
          removeKeys(newData, keysToRemove);
          newData = {
            style: newData,
            socialMediaId: id,
          };
        }

        const { data } = await putUpdateSocialMedia(newData);
        if (data.status === 200) {
          // getDownloadParentList(null, true);
          if (isClose) {
            OpenModalCommonFunc(
              Cases.SETTING_SOCIAL_MEDIA_PARENT,
              setAllPopupState,
              false
            );
          }
          setLoaderShow(false);
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  /**
   * get data social media
   * @param {id} socialMediaId
   */
  const handleEditDownload = async (socialMediaId, isParent) => {
    try {
      setLoaderShow(true);
      const { data } = await getSocialMediaDetails(socialMediaId);
      if (data.status === 200) {
        let payload = data.payload.data;
        if (isParent) {
          setSectionPageName(payload?.label);
          setId(socialMediaId);
          setIsParent(true);
          setFormData({
            label: payload?.label || "",
            subTitle: payload?.subTitle || "",
            theme: payload?.theme || 1,
            image: payload?.image || "",
            // itemId: payload?.itemId || "",
            // navMapId: payload?.navMapId || "",
            contentMapId,
            webId: localStorage.getItem("webId"),
            style: payload?.style || "",
          });
          OpenModalCommonFunc(
            Cases.SOCIALMEDIA_MULTIPAGE_PARENT,
            setAllPopupState
          );
        } else {
          setChildId(socialMediaId);
          setIsParent(false);
          let [dialCodeWithNumber] = payload?.value?.split(" ");
          let [filter] = options?.filter(
            (elem) => elem.value === dialCodeWithNumber
          );
          setFormDataChild({
            label: payload?.label,
            value: payload?.value,
            itemId: payload.itemId,
            image: payload?.customIcon,
            type: payload?.itemDetail?.type,
            socialMediaId: payload?.socialMediaId,
            webId: localStorage.getItem("webId"),
            dialCode: filter,
          });
          OpenModalCommonFunc(Cases.SOCIALMEDIA_MULTIPAGE, setAllPopupState);
        }
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * get setting data
   * @param {id} socialMediaId
   */
  const handleChangeSettings = async (socialMediaId, isThemeEdit = false) => {
    try {
      setLoaderShow(true);
      setId(socialMediaId);
      setSettingFormData((prev) => {
        return {
          ...prev,
          socialMediaId,
        };
      });
      const { data } = await getSocialMediaDetails(socialMediaId);
      if (data.status === 200) {
        const themeCount = data?.payload?.data?.theme;
        setTheme(themeCount ? themeCount : 1);
        setImgShow(true);
        let payload = data.payload.data;
        setSectionPageName(payload.title);
        if (
          +themeCount === ThemeNo.ThemeOne &&
          isEmptyObject(data?.payload?.data?.style)
        ) {
          setSettingFormData({ ...SocialMediaTHmeOne });
          setIsBackgroundColor(false);
        } else {
          if (payload.style?.[".custom-footer"]?.["background-color"]) {
            setIsBackgroundColor(true);
          }
          let getStyle;
          if (+themeCount === ThemeNo.ThemeOne) {
            getStyle = SocialMediaThemeOneFunction(payload);
          } else {
            getStyle = SocialMediaThemeOneFunction(payload);
          }
          setSettingFormData((prev) => {
            return {
              ...prev,
              ...getStyle,
            };
          });
        }
        if (!isThemeEdit) {
          OpenModalCommonFunc(
            Cases.SETTING_SOCIAL_MEDIA_PARENT,
            setAllPopupState
          );
          TostMessage({ message: data, type: "success" });
        }
        setIsEdit(true);
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * delete social media
   * @param {id} socialMediaId
   */
  const handleDelete = async (socialMediaId, isParent = false) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await deleteSocialMedia(socialMediaId);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            ByteMiniWeb_Dashboard();
          } else {
            getDownloadParentList({ parentId: childId });
          }
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * clear data
   */
  const emptyData = (fileInput) => {
    setIsBackgroundColor(false);
    setIsEdit(false);
    setIsParent(false);
    setChildId("");
    if (fileInput) {
      fileInput.value = null;
    }
    setFormData({
      label: "",
      webId: localStorage.getItem("webId"),
      subTitle: "",
      theme: 1,
      style: {
        ".custom-footer": {
          "background-color": "rgb(255 255 255 / 100)",
        },
        ".custom-footer .foot-social": {
          padding: "70px 0",
          "background-color": "rgb(31 39 50 / 100)",
        },
        ".custom-footer .foot-social:before": {
          "background-image": "",
        },
        ".custom-footer .foot-social:after": {
          "background-image": "",
        },
        ".custom-footer .foot-social .social_buttons a": {
          "background-color": "rgb(18 119 217 / 0.2)",
          color: "rgb(255 255 255 / 100)",
        },
        socialMediaId: "",
      },
    });
    setSkill([{ id: randomStr(2), skill: "", parentageOfSkill: "" }]);
    setGalleryImgId("");
    setFormDataChild({
      label: "",
      value: "",
      itemId: "",
      image: "",
      type: "",
      dialCode: { value: "+91", label: "+91" },
      webId: localStorage.getItem("webId"),
    });
    setLoaderShow(false);
    setSettingFormData({
      ".custom-footer": {
        "background-color": "#ffffff",
      },
      ".custom-footer .foot-social": {
        padding: "70px 0",
        "background-color": "#1f2732",
      },
      ".custom-footer .foot-social:before": {
        "background-image": "",
      },
      ".custom-footer .foot-social:after": {
        "background-image": "",
      },
      ".custom-footer .foot-social .social_buttons a": {
        "background-color": "#1277D933",
        color: "#ffffff",
      },
      socialMediaId: "",
    });
  };

  const value = useMemo(() => {
    let [label] = navbarData.filter(
      (elem) => elem.navbarDetail.label === titleUpdateKey.Certificates
    );
    return label;
  }, [navbarData]);

  const handleAlignAndCloneSection = ({ isClone = false }) => {
    if (isClone) {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          cloneModal: true,
        };
      });
      setCloneFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          pageConfigDetailsLabel: value?.label || pageConfigDetails[0]?.label,
          contentDetailName: contentDetail.name,
        };
      });
    } else {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          alignModal: true,
        };
      });
      setAlignFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          label: value?.label || pageConfigDetails[0]?.label,
        };
      });
    }
  };

  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody
            className="support-ticket-font box-border-div"
            style={{ padding: "20px" }}
          >
            <Row>
              <CardBuilderHeaderSection
                addBtnTitle={pageConfigDetails[0]?.label}
                name={value?.label || pageConfigDetails[0]?.label}
                isAdd={!socialMediaDetail && true}
                mainEditSection={true}
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
                openModal={() => {
                  OpenModalCommonFunc(
                    Cases.SOCIALMEDIA_MULTIPAGE_PARENT,
                    setAllPopupState
                  );
                  setSectionPageName(modalsName.SOCIALMEDIA_MULTIPAGE_PARENT);
                  emptyData();
                  setIsParent(true);
                  setCollId(contentMapId);
                }}
                isCloneSection={true}
                isAlignSection={true}
                handleAlignAndCloneSection={handleAlignAndCloneSection}
              />
              {socialMediaDetail && (
                <Col xl="12 xl-50 box-col-6" md="6">
                  <Card className="ecommerce-widget mb-0">
                    <CardBody
                      className="support-ticket-font"
                      style={{ padding: "20px" }}
                    >
                      <div className="col-12">
                        <CardBuilderHeaderSection
                          name={socialMediaDetail?.label}
                          checked={socialMediaDetail?.isActive}
                          switchToggle={true}
                          id={socialMediaDetail?.socialMediaId}
                          handleChange={handleChangeDownload}
                        />
                        <OpenPopupBtn
                          openPopup={() => {
                            getDownloadParentList({
                              parentId: socialMediaDetail?.socialMediaId,
                            });
                            setSectionPageName(socialMediaDetail?.label);
                            setFilter("");
                            setCollId(contentMapId);
                          }}
                          name={`Add or View ${socialMediaDetail?.label ?? ""}`}
                        />
                      </div>
                      <div className="d-flex justify-content-end ">
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleEditDownload(
                              socialMediaDetail.socialMediaId,
                              true
                            );
                            setCollId(contentMapId);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() =>
                            handleDelete(socialMediaDetail.socialMediaId, true)
                          }
                        >
                          <Trash />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleChangeSettings(
                              socialMediaDetail.socialMediaId
                            );
                          }}
                        >
                          <Settings />
                        </IconButton>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
              <DeleteSection
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
              />
            </Row>
          </CardBody>
        </Card>
      </Col>
      {allPopupState.socialMediaMultipageChildPopup && (
        <CommonPopup
          open={allPopupState.socialMediaMultipageChildPopup}
          close={() => {
            OpenModalCommonFunc(
              Cases.SOCIALMEDIA_MULTIPAGE,
              setAllPopupState,
              false
            );
            setIsParent(false);
          }}
          modalName={
            sectionPageName ? sectionPageName : modalsName.SOCIALMEDIA_MULTIPAGE
          }
          modalCase={Cases.SOCIALMEDIA_MULTIPAGE}
          handleSubmit={handleSubmit}
          validationForm={"childForm"}
          emptyData={emptyData}
          inputData={fromData}
          edit={false}
          oldTitle={titleUpdateKey.isGallery}
          label={titleUpdateKey.Certificates}
          getData={getData}
          content={
            <SocialMediaMultipagePopup
              getDownloadParentList={getDownloadParentList}
              handleChangeStatus={handleChangeDownload}
              handleEdit={handleEditDownload}
              imageUpload={imageUploadImages}
              emptyData={emptyData}
              simpleValidator={childValidator}
              webMasterDetail={webMasterDetail}
              handleDelete={handleDelete}
              contentMapId={contentMapId}
            />
          }
        />
      )}

      {/* social media popup */}
      <CommonPopup
        open={allPopupState.socialMediaMultipagePopup}
        close={() => {
          OpenModalCommonFunc(
            Cases.SOCIALMEDIA_MULTIPAGE_PARENT,
            setAllPopupState,
            false
          );
          setIsParent(false);
        }}
        modalName={
          sectionPageName
            ? sectionPageName
            : modalsName.SOCIALMEDIA_MULTIPAGE_PARENT
        }
        modalCase={Cases.SOCIALMEDIA_MULTIPAGE_PARENT}
        handleSubmit={handleSubmit}
        validationForm={"parentForm"}
        emptyData={emptyData}
        inputData={formDataChild}
        edit={false}
        content={
          <SocialMediaMultipageParentPopup
            simpleValidator={parentValidator}
            imageUpload={imageUploadImagesParent}
          />
        }
      />

      {/* setting Service Counter parent popup */}
      <CommonPopup
        open={allPopupState.socialMediaSettingPopup}
        close={() =>
          OpenModalCommonFunc(
            Cases.SETTING_SOCIAL_MEDIA_PARENT,
            setAllPopupState,
            false
          )
        }
        modalName={
          sectionPageName
            ? sectionPageName
            : modalsName.SETTING_SOCIAL_MEDIA_PARENT
        }
        modalCase={Cases.SETTING_SOCIAL_MEDIA_PARENT}
        handleSubmit={handleSubmitSetting}
        emptyData={emptyData}
        inputData={formDataChild}
        edit={false}
        content={
          <SettingSocialMediaPerentPopup
            imageUpload={BackImageUploadImages}
            handleSubmitSetting={handleSubmitSetting}
            handleChangeSettings={handleChangeSettings}
          />
        }
      />
    </>
  );
};

export default SocialMediaMultipage;
