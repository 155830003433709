import React, { useContext, useMemo, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import { Cases, DELETE_CONFIRM, modalsName } from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import CommonPopup from "../../../Modals/commonPopup";
import DownloadMultipagePopup from "./DownloadMultipagePopup";
import EditIcon from "@mui/icons-material/Edit";
import DownloadMultipageParentPopup from "./DownloadMultipageParentPopup";
import { FILE_UPLOAD_API } from "../apis/SliderApi/SliderApi";
import {
  convertRGBA,
  generateDynamicStyles,
  generateDynamicStylesSet,
  isCheckValue,
  isEmptyObject,
  randomStr,
  rgbColorCreate,
} from "../../../../utils/helper";
import { FILE_TYPE, ThemeNo, titleUpdateKey } from "../../../../constant";
import { IconButton } from "@material-ui/core";
import { Settings, Trash } from "react-feather";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import SimpleReactValidator from "simple-react-validator";
import {
  deleteDownload,
  getDetailsDownload,
  getDownload,
  postDownload,
  putUpdateDownload,
  updateStatus,
} from "../apis/DownloadsApi/DownloadApi";
import DownloadMultipageContext from "../../../context/DownloadMultipageContext/DownloadMultipageContext";
import SettingDownloadPerentPopup from "./SettingDownloadPerentPopup";
import DeleteSection from "../../../common/DeleteSection";
import SettingMultiWebContext from "../../../../context/SettingMultiWebContext/SettingMultiWebContext";
import {
  DownloadStyleOneReset,
  DownloadStyleOneSubFun,
  DownloadThemeOneFunction,
} from "./DownloadStyleFunction";

const DownloadMultipage = (props) => {
  const {
    pageConfigDetails,
    webMasterDetail,
    navbarData,
    getData,
    downloadDetail,
    contentMapId,
    ByteMiniWeb_Dashboard,
    navMapId: navMapIds,
    contentDetail,
  } = props;
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );
  const { setCloneAlignModal, setAlignFormData, setCloneFormData } = useContext(
    SettingMultiWebContext
  );
  const parentValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const childValidator = useRef(new SimpleReactValidator());
  const {
    fromData,
    setFormData,
    formDataChild,
    setFormDataChild,
    id,
    setId,
    setIsEdit,
    isEdit,
    setFilter,
    apiDataOurTeam: apiDataImageParent,
    setApiDataOurTeam: setApiDataImageParent,
    setSkill,
    setGalleryImgId,
    setSettingFormData,
    settingFormData,
    isParent,
    setIsParent,
    childId,
    setChildId,
    navMapId,
    setNavMapId,
    boxSection,
    setBoxSection,
    collId,
    setCollId,
    isBackgroundColor,
    theme,
    setTheme,
    setIsBackgroundColor,
    setImgShow,
    sectionPageName,
    setSectionPageName,
  } = useContext(DownloadMultipageContext);
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * get download list
   * @param {status} status
   */
  const getDownloadParentList = async ({
    parentId = null,
    isClose = false,
    filter,
  }) => {
    try {
      let paramObj = {};
      if (
        ((parentId && parentId !== null) ||
          (id && isClose === false && isParent === false)) &&
        isParent === false
      ) {
        paramObj["search"] = { parentId: parentId || id };
        setId(parentId || id);
        if (filter?.length) {
          paramObj["search"] = { ...paramObj.search, title: filter };
        }
      } else {
        paramObj["search"] = { parentId: null };
      }
      const { data } = await getDownload(paramObj);
      if (isCheckValue({ value: parentId })) {
        setChildId(parentId);
      }
      if (data.status === 200) {
        if (
          isParent === false &&
          ((parentId && parentId !== null) || id) &&
          isClose === false
        ) {
          setApiDataImageParent({
            ...apiDataImageParent,
            isLoading: false,
            data: data.payload.data.map((elem) => {
              return {
                id: elem?.title + elem?.downloadId,
                Id: elem?.downloadId,
                name: elem?.title,
                image: elem?.image,
                webId: elem?.webId,
                createdAt: elem?.createdAt,
                isActive: elem?.isActive,
                parentId: elem?.parentId,
              };
            }),
          });
          OpenModalCommonFunc(Cases.DOWNLOAD_MULTIPAGE, setAllPopupState);
        } else if (parentId && parentId !== null) {
          OpenModalCommonFunc(Cases.DOWNLOAD_MULTIPAGE, setAllPopupState);
          setApiDataImageParent({
            ...apiDataImageParent,
            isLoading: false,
            data: data.payload.data.map((elem) => {
              return {
                id: elem?.title + elem?.downloadId,
                Id: elem?.downloadId,
                name: elem?.title,
                image: elem?.image,
                webId: elem?.webId,
                createdAt: elem?.createdAt,
                isActive: elem?.isActive,
                parentId: elem?.parentId,
              };
            }),
          });
        } else {
          setApiDataImageParent({
            ...apiDataImageParent,
            isLoading: false,
            data: data.payload.data,
          });
        }
        if (isClose && isClose !== false) {
          setIsParent(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * download enable disable api call
   * @param {downloadId} downloadId
   * @param {status} isActive
   */

  const handleChangeDownload = async (
    downloadId,
    isActive,
    isChild,
    parentId
  ) => {
    try {
      let body = {
        downloadId,
        isActive: isActive ? false : true,
        parentId,
      };
      const { data } = await updateStatus(body);
      if (data.status === 200) {
        if (isChild) {
          getDownloadParentList({ parentId: id });
        } else {
          ByteMiniWeb_Dashboard();
        }
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * file upload
   * @param {file} file
   */
  const imageUploadImages = async (file) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.BYTE_SLIDER);
    formData.append("file", file);
    setLoaderShow(true);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        setLoaderShow(false);
        return {
          imageUrl: data.payload.imageUrl,
        };
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * create and update download
   */
  const submitForm = async () => {
    if (
      (isParent === false && childId >= -1 && isEdit) ||
      (id >= -1 && isEdit)
    ) {
      setLoaderShow(true);
      try {
        let formData = {};
        if (isParent && isParent !== false) {
          formData = {
            ...fromData,
            downloadId: id,
            contentMapId: collId,
            navMapId: +navMapIds,
          };
        } else {
          formData = {
            ...formDataChild,
            parentId: id,
            navMapId,
            downloadId: childId,
            contentMapId: collId,
          };
          delete formData.filePath;
          if (formDataChild.filePath && formDataChild?.filePath?.name) {
            let { imageUrl } = await imageUploadImages(formDataChild.filePath);
            setFormDataChild((prev) => {
              return {
                ...prev,
                filePath: imageUrl,
              };
            });
            formData["filePath"] = imageUrl;
          } else {
            formData["filePath"] = formDataChild.filePath
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
              .pop();
          }
        }
        const { data } = await putUpdateDownload(formData);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            ByteMiniWeb_Dashboard();
          } else {
            getDownloadParentList({ parentId: id });
          }
          emptyData();
          OpenModalCommonFunc(
            Cases.DOWNLOAD_MULTIPAGE_PARENT,
            setAllPopupState,
            false
          );
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    } else {
      try {
        setLoaderShow(true);
        let formData = {};
        if (isParent && isParent !== false) {
          formData = {
            ...fromData,
            contentMapId: collId,
            navMapId: +navMapIds,
          };
        } else {
          formData = {
            ...formDataChild,
            parentId: id,
            navMapId,
            order: apiDataImageParent.data.length + 1,
            contentMapId: collId,
          };
          delete formData.filePath;
          if (formDataChild.filePath) {
            let { imageUrl } = await imageUploadImages(formDataChild.filePath);
            setFormDataChild((prev) => {
              return {
                ...prev,
                filePath: imageUrl,
                order: apiDataImageParent.data.length + 1,
              };
            });
            formData["filePath"] = imageUrl;
          }
        }
        const { data } = await postDownload(formData);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            ByteMiniWeb_Dashboard();
          } else {
            getDownloadParentList({ parentId: id });
          }
          emptyData();
          OpenModalCommonFunc(
            Cases.DOWNLOAD_MULTIPAGE_PARENT,
            setAllPopupState,
            false
          );
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };
  const handleSubmit = async (formValidationCondition) => {
    let validatorFields;
    if (formValidationCondition === "parentForm") {
      validatorFields = parentValidator.current;
    } else if (formValidationCondition === "childForm") {
      validatorFields = childValidator.current;
    }
    validatorFields.showMessages();

    if (validatorFields.allValid()) {
      try {
        // Process form data based on the formType
        if (formValidationCondition === "parentForm") {
          submitForm();
        } else if (formValidationCondition === "childForm") {
          submitForm();
        }
        validatorFields.hideMessages();
      } catch (error) {
        // Handle error for form submission
      }
    } else {
      // Force update to display validation messages
      forceUpdate({});
    }
  };

  /**
   * create and update download
   */
  const handleSubmitSetting = async (isRestore = false, isClose = true) => {
    if (id >= -1 && isEdit) {
      setLoaderShow(true);
      try {
        let newData;
        if (isRestore) {
          const isConfirm = window.confirm(
            "Are you sure want to restore style ?"
          );
          if (isConfirm) {
            newData = {
              styleRestore: true,
              downloadId: id,
            };
          } else {
            setLoaderShow(false);
            return;
          }
        } else {
          if (+theme === 1) {
            newData = DownloadStyleOneSubFun({
              settingFormData,
              boxSection,
            });
            const menuBtnStyle = generateDynamicStyles(
              settingFormData,
              ".brochure-section"
            );
            newData = { ...newData, ...menuBtnStyle };
            if (isBackgroundColor) {
              newData[".brochure-section"] = {
                ...newData?.[".brochure-section"],
                "background-color": settingFormData[".brochure-section"]?.[
                  "background-color"
                ]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData[".brochure-section"]?.[
                          "background-color"
                        ],
                    })
                  : "",
              };
            }
          }

          const keysToRemove = ["p1", "p2"];
          const removeKeys = (obj, keys) => {
            const newObj = { ...obj };
            keys.forEach((key) => {
              if (
                newObj[
                  ".brochure-section .section-title-area .section-subtitle"
                ]
              ) {
                delete newObj[
                  ".brochure-section .section-title-area .section-subtitle"
                ][key];
              }
            });
            return newObj;
          };

          const cleanedStyles = removeKeys(newData, keysToRemove);
          newData = {
            style: { ...cleanedStyles },
            downloadId: id,
          };
        }
        const { data } = await putUpdateDownload(newData);
        if (data.status === 200) {
          if (isClose) {
            OpenModalCommonFunc(
              Cases.SETTING_DOWNLOAD_PARENT,
              setAllPopupState,
              false
            );
          }
          setLoaderShow(false);
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  /**
   * get data download
   * @param {id} downloadId
   */
  const handleEditDownload = async (downloadId, isParent) => {
    try {
      setLoaderShow(true);
      const { data } = await getDetailsDownload(downloadId, navMapIds);
      if (data.status === 200) {
        let payload = data.payload.data;
        if (isParent) {
          setSectionPageName(payload?.title);
          setId(downloadId);
          setIsParent(true);
          setFormData({
            title: payload?.title || "",
            subTitle: payload?.subTitle || "",
            slugUrl: payload?.slugUrl || "",
            buttonText: payload?.buttonText || "",
            itemLimit: payload?.itemLimit || "",
            isTitleShow: payload?.isTitleShow ? payload?.isTitleShow : false,
            theme: payload?.theme || 1,
            navMapId: payload?.navMapId || "",
            webId: localStorage.getItem("webId"),
            style: payload?.style || "",
          });
          OpenModalCommonFunc(
            Cases.DOWNLOAD_MULTIPAGE_PARENT,
            setAllPopupState
          );
        } else {
          setChildId(downloadId);
          setIsParent(false);
          setFormDataChild({
            title: payload?.title || "",
            filePath: payload?.filePath || "",
            downloadId: payload?.downloadId || "",
            isTitleShow: payload?.isTitleShow ? payload?.isTitleShow : false,
            subTitle: payload?.subTitle || "",
            order: payload?.order || 1,
            webId: localStorage.getItem("webId"),
          });
          OpenModalCommonFunc(Cases.DOWNLOAD_MULTIPAGE, setAllPopupState);
        }
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * get setting data
   * @param {id} downloadId
   */
  const handleChangeSettings = async (downloadId, isThemeEdit = false) => {
    try {
      setLoaderShow(true);
      setId(downloadId);
      setSettingFormData((prev) => {
        return {
          ...prev,
          downloadId,
        };
      });
      const { data } = await getDetailsDownload(downloadId);
      if (data.status === 200) {
        const themeCount = data?.payload?.data?.theme;
        setTheme(themeCount);
        setImgShow(true);
        let payload = data.payload.data;
        setSectionPageName(payload.title);
        if (
          +themeCount === ThemeNo.ThemeOne &&
          isEmptyObject(data?.payload?.data?.style)
        ) {
          setSettingFormData({ ...DownloadStyleOneReset });
          setBoxSection(false);
          setIsBackgroundColor(false);
        } else {
          if (
            payload.style?.[".brochure-section"]?.["width"] ||
            payload.style?.[".brochure-section"]?.["margin"] ||
            payload.style?.[".brochure-section"]?.["border-radius"]
          ) {
            setBoxSection(true);
          }
          if (payload.style?.[".brochure-section"]?.["background-color"]) {
            setIsBackgroundColor(true);
          }
          const getMenuBtnStyle = generateDynamicStylesSet(
            payload,
            ".brochure-section"
          );
          let getStyle;
          if (+themeCount === 1) {
            getStyle = DownloadThemeOneFunction(payload);
          } else {
            getStyle = DownloadThemeOneFunction(payload);
          }
          setSettingFormData((prev) => {
            return {
              ...prev,
              ...getStyle,
              ...getMenuBtnStyle,
            };
          });
        }
        if (!isThemeEdit) {
          OpenModalCommonFunc(Cases.SETTING_DOWNLOAD_PARENT, setAllPopupState);
          TostMessage({ message: data, type: "success" });
        }
        setIsEdit(true);
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * delete download
   * @param {id} downloadId
   */
  const handleDelete = async (downloadId, isParent = false) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await deleteDownload(downloadId);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            // getDownloadParentList(null, true);
            ByteMiniWeb_Dashboard();
          } else {
            getDownloadParentList({ parentId: childId });
          }
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * clear data
   */
  const emptyData = (fileInput) => {
    setIsBackgroundColor(false);
    setIsEdit(false);
    setBoxSection(false);
    setIsParent(false);
    setChildId("");
    if (fileInput) {
      fileInput.value = null;
    }
    const fileInput12 = document.getElementById("fileInput");
    if (fileInput12) {
      fileInput12.value = null;
    }
    setFormData({
      title: "",
      slugUrl: "",
      buttonText: "",
      itemLimit: "",
      navMapId: "",
      webId: localStorage.getItem("webId"),
      subTitle: "",
      theme: 1,
      isTitleShow: true,
      style: {
        ".brochure-section": {
          padding: "100px 0",
          "background-color": "rgb(255 255 255 / 100)",
          width: "calc(100% - 10rem)",
          margin: "0px auto",
          "border-radius": "3rem",
        },
        ".brochure-section .section-title-area": {
          "margin-bottom": "50px",
        },
        ".brochure-section .section-title-area .section-subtitle": {
          "font-size": "16px",
          "font-weight": "600",
          color: "rgb(255 73 124 / 100)",
          "background-color": "rgb(255 73 124 / 0.2)",
          padding: "5px 20px",
          p1: "5px",
          p2: "20px",
          "border-radius": "15px",
          "margin-bottom": "15px",
        },
        ".brochure-section .section-title-area .section-title": {
          "font-size": "44px",
          "font-weight": "600",
          color: "rgb(0 0 0 / 100)",
        },
        ".brochure-section .brochure-list .link-text": {
          "background-color": "rgb(255 73 124 / 100)",
          "font-size": "18px",
          "font-weight": "600",
          color: "rgb(255 255 255 / 100)",
          "text-align": "center",
          padding: "15px",
          "margin-bottom": "15px",
        },
        ".brochure-section .more-btn": {
          color: "rgb(255 255 255 / 100)",
          "font-size": "16px",
          "font-weight": "normal",
          "border-radius": "15px",
          padding: "5px 20px",
          "background-color": "rgb(255 73 124 / 100)",
        },
        ".brochure-section .more-btn:hover": {
          "background-color": "#df2a5d",
          color: "rgb(255 255 255 / 100)",
        },
        ".pagination-positions": {
          "justify-content": "center",
        },
        ".pagination .page-link": {
          color: "rgb(255 73 124 / 100)",
        },
        ".pagination .page-item.active .page-link": {
          "background-color": "rgb(255 73 124 / 100)",
          "border-color": "rgb(255 73 124 / 100)",
        },
        downloadId: "",
      },
    });
    setSkill([{ id: randomStr(2), skill: "", parentageOfSkill: "" }]);
    setGalleryImgId("");
    setFormDataChild({
      filePath: "",
      title: "",
      subTitle: "",
      isTitleShow: true,
      order: 1,
      webId: localStorage.getItem("webId"),
    });
    setLoaderShow(false);
    setSettingFormData({
      ".brochure-section": {
        padding: "100px 0",
        "background-color": "rgb(255 255 255 / 100)",
      },
      ".brochure-section .section-title-area": {
        "margin-bottom": "50px",
      },
      ".brochure-section .section-title-area .section-subtitle": {
        "font-size": "16px",
        "font-weight": "600",
        color: "rgb(255 73 124 / 100)",
        "background-color": "rgb(255 73 124 / 0.2)",
        padding: "5px 20px",
        "border-radius": "15px",
        "margin-bottom": "15px",
      },
      ".brochure-section .section-title-area .section-title": {
        "font-size": "44px",
        "font-weight": "600",
        color: "rgb(0 0 0 / 100)",
      },
      ".brochure-section .brochure-list .link-text": {
        "background-color": "rgb(255 73 124 / 100)",
        "font-size": "18px",
        "font-weight": "600",
        color: "rgb(255 255 255 / 100)",
        "text-align": "center",
        padding: "15px",
        "margin-bottom": "15px",
      },
      ".brochure-section .more-btn": {
        color: "rgb(255 255 255 / 100)",
        "font-size": "16px",
        "font-weight": "normal",
        "border-radius": "15px",
        padding: "5px 20px",
        "background-color": "rgb(255 73 124 / 100)",
      },
      ".brochure-section .more-btn:hover": {
        "background-color": "#df2a5d",
        color: "rgb(255 255 255 / 100)",
      },
      ".pagination-positions": {
        "justify-content": "center",
      },
      ".pagination .page-link": {
        color: "rgb(255 73 124 / 100)",
      },
      ".pagination .page-item.active .page-link": {
        "background-color": "rgb(255 73 124 / 100)",
        "border-color": "rgb(255 73 124 / 100)",
      },
      downloadId: "",
    });
  };

  let value = useMemo(() => {
    let [label] = navbarData.filter(
      (elem) => elem.navbarDetail.label === titleUpdateKey.Certificates
    );
    return label;
  }, [navbarData]);

  const handleAlignAndCloneSection = ({ isClone = false }) => {
    if (isClone) {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          cloneModal: true,
        };
      });
      setCloneFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          pageConfigDetailsLabel: value?.label || pageConfigDetails[0]?.label,
          contentDetailName: contentDetail.name,
        };
      });
    } else {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          alignModal: true,
        };
      });
      setAlignFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          label: value?.label || pageConfigDetails[0]?.label,
        };
      });
    }
  };
  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody
            className="support-ticket-font box-border-div"
            style={{ padding: "20px" }}
          >
            <Row>
              <CardBuilderHeaderSection
                addBtnTitle={pageConfigDetails[0]?.label}
                name={value?.label || pageConfigDetails[0]?.label}
                isAdd={!downloadDetail && true}
                mainEditSection={true}
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
                openModal={() => {
                  OpenModalCommonFunc(
                    Cases.DOWNLOAD_MULTIPAGE_PARENT,
                    setAllPopupState
                  );
                  setSectionPageName(modalsName.DOWNLOAD_MULTIPAGE_PARENT);
                  emptyData();
                  setIsParent(true);
                  setCollId(contentMapId);
                }}
                isCloneSection={true}
                isAlignSection={true}
                handleAlignAndCloneSection={handleAlignAndCloneSection}
              />
              {downloadDetail && (
                <Col xl="12 xl-50 box-col-6" md="6">
                  <Card className="ecommerce-widget mb-0">
                    <CardBody
                      className="support-ticket-font"
                      style={{ padding: "20px" }}
                    >
                      <div className="col-12">
                        <CardBuilderHeaderSection
                          name={downloadDetail?.title}
                          checked={downloadDetail?.isActive}
                          switchToggle={true}
                          id={downloadDetail?.downloadId}
                          handleChange={handleChangeDownload}
                        />
                        <OpenPopupBtn
                          openPopup={() => {
                            setSectionPageName(downloadDetail.title);
                            getDownloadParentList({
                              parentId: downloadDetail.downloadId,
                            });
                            setNavMapId(downloadDetail?.navMapId);
                            setFilter("");
                            setCollId(contentMapId);
                          }}
                          name={`Add or View ${downloadDetail?.title}`}
                        />
                      </div>
                      <div className="d-flex justify-content-end ">
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleEditDownload(downloadDetail.downloadId, true);
                            setCollId(contentMapId);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() =>
                            handleDelete(downloadDetail.downloadId, true)
                          }
                        >
                          <Trash />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleChangeSettings(downloadDetail.downloadId);
                          }}
                        >
                          <Settings />
                        </IconButton>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
              <DeleteSection
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
              />
            </Row>
          </CardBody>
        </Card>
      </Col>
      <CommonPopup
        open={allPopupState.downloadMultipageChildPopup}
        close={() => {
          OpenModalCommonFunc(
            Cases.DOWNLOAD_MULTIPAGE,
            setAllPopupState,
            false
          );
          setIsParent(false);
        }}
        // apiCall={getDownloadParentList}
        modalName={
          sectionPageName ? sectionPageName : modalsName.DOWNLOAD_MULTIPAGE
        }
        modalCase={Cases.DOWNLOAD_MULTIPAGE}
        handleSubmit={handleSubmit}
        validationForm={"childForm"}
        emptyData={emptyData}
        inputData={fromData}
        edit={false}
        oldTitle={titleUpdateKey.isGallery}
        label={titleUpdateKey.Certificates}
        getData={getData}
        content={
          <DownloadMultipagePopup
            getDownloadParentList={getDownloadParentList}
            handleChangeStatus={handleChangeDownload}
            handleEdit={handleEditDownload}
            imageUpload={imageUploadImages}
            emptyData={emptyData}
            simpleValidator={childValidator}
            webMasterDetail={webMasterDetail}
            handleDelete={handleDelete}
          />
        }
      />

      {/* slider popup */}
      <CommonPopup
        open={allPopupState.downloadMultipagePopup}
        close={() => {
          OpenModalCommonFunc(
            Cases.DOWNLOAD_MULTIPAGE_PARENT,
            setAllPopupState,
            false
          );
          setIsParent(false);
        }}
        modalName={
          sectionPageName
            ? sectionPageName
            : modalsName.DOWNLOAD_MULTIPAGE_PARENT
        }
        modalCase={Cases.DOWNLOAD_MULTIPAGE_PARENT}
        handleSubmit={handleSubmit}
        validationForm={"parentForm"}
        emptyData={emptyData}
        inputData={formDataChild}
        edit={false}
        content={
          <DownloadMultipageParentPopup simpleValidator={parentValidator} />
        }
      />

      {/* setting Service Counter parent popup */}
      <CommonPopup
        open={allPopupState.downloadSettingPopup}
        close={() =>
          OpenModalCommonFunc(
            Cases.SETTING_DOWNLOAD_PARENT,
            setAllPopupState,
            false
          )
        }
        modalName={
          sectionPageName ? sectionPageName : modalsName.SETTING_DOWNLOAD_PARENT
        }
        modalCase={Cases.SETTING_DOWNLOAD_PARENT}
        handleSubmit={handleSubmitSetting}
        emptyData={emptyData}
        inputData={formDataChild}
        edit={false}
        content={
          <SettingDownloadPerentPopup
            handleSubmitSetting={handleSubmitSetting}
            handleChangeSettings={handleChangeSettings}
          />
        }
      />
    </>
  );
};

export default DownloadMultipage;
