import React, { useContext, useEffect, useRef, useState } from "react";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import { useParams } from "react-router";
import { FILE_TYPE, SORTING_ORDER } from "../../../../constant";
import { Zoom, toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import { FILE_UPLOAD_API } from "../../../../components ByteCard/apis/AboutApi/AboutUsApi";
import { TostMessage } from "../../../../components ByteCard/utils";
import { DELETE_CONFIRM } from "../../../../components ByteCard/constant";
import AddOnProductItem from "./AddOnProductItem";
import AddOnProductAddPopup from "./AddOnProductAddPopup";
import AddOnProductContext from "../../../../context/MenuMasterContext/AddOnProductContext/AddOnProductContext";
import {
  AddOnProductList,
  AddOnProduct_MenuPageList_Enable_Desable,
  Add_AddOnProduct_List,
  Delete_AddOnProduct_List,
  Edit_AddOnProduct_List,
} from "../../../../api/MenuMasterApi/AddOnProductApi/AddOnProductApi";

const AddOnProducts = () => {
  const {
    setFormValue,
    formData,
    pageSize,
    sortOrder,
    setSortOrder,
    page,
    sortBy,
    setSortBy,
    setPageLimit,
    setRecord,
    isSwitchOn,
    setIsSwitchOn,
    addModel,
    setFormData,
    setAddModel,
    isEdit,
    setIsEdit,
    id,
    setId,
  } = useContext(AddOnProductContext);
  const { setLoaderShow } = useContext(LoaderContext);
  const { QpiId } = useParams();
  const { tabelAnimation, setTableAnimation } = useContext(LoaderContext);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();

  const geAddOnProductList = async (desabledStatusLoader) => {
    if (
      sortOrder === "ASC" ||
      sortOrder === "DSC" ||
      desabledStatusLoader === false
    ) {
    } else {
      setTableAnimation(!tabelAnimation);
    }
    try {
      let paramObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
        qpiId: QpiId,
      };
      const { data } = await AddOnProductList(paramObj);

      if (data.status === 200) {
        setFormValue(data.payload.data);
        setPageLimit(data.payload.pageLimit);
        setRecord(data.pager.totalRecords);

        setTableAnimation(false);
      } else {
      }
    } catch (error) {
      setTableAnimation(false);
    }
  };

  useEffect(() => {
    geAddOnProductList();
  }, [page, pageSize, sortBy, sortOrder]);

  /**
   * Enable and Desable add on product
   */
  const switch_onChange_handle = async (addOnProductId, isActive) => {
    let formData = {
      addOnProductId: addOnProductId,
      isActive: isActive ? false : true,
      // type: TYPES.LANDING_PAGE,
    };
    try {
      const { data } = await AddOnProduct_MenuPageList_Enable_Desable(
        formData,
        addOnProductId
      );
      if (data.status === 200) {
        await setIsSwitchOn(!isSwitchOn);
        const desabledStatusLoader = false;
        await geAddOnProductList(desabledStatusLoader);
        isActive
          ? toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            })
          : toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            });
      } else {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * file upload
   * @param {file} file
   */
  const imageUploadImages = async (file) => {
    setLoaderShow(true);
    try {
      const Image = await imageUpload(file);
      setFormData((prev) => {
        if (formData.imageName == "thumbImage") {
          return {
            ...prev,
            image: Image,
            thumbImage: Image,
          };
        }
      });
      setLoaderShow(false);
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const imageUpload = async (file) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.PRODUCT);
    formData.append("file", file);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        return data.payload.baseUrl + data.payload.imageUrl;
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * Sorting add on product Table
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder(SORTING_ORDER.ASC);
    }
    if (sortOrder === SORTING_ORDER.ASC) {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder(SORTING_ORDER.DSC);
    }
    if (sortOrder === SORTING_ORDER.DSC) {
      setSortBy();
      setSortOrder("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formValid = simpleValidator.current.allValid();

    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        if (isEdit && id >= -1) {
          let addFromData = {
            ...formData,
            thumbImage: formData?.thumbImage
              ? formData?.thumbImage
                  .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
                  .pop()
              : "",
            qpiId: QpiId,
          };
          delete addFromData.image;
          delete addFromData.imageName;
          const { data } = await Edit_AddOnProduct_List(addFromData, id);
          if (data.status === 200) {
            geAddOnProductList();
            emptyData();
            TostMessage({ message: data, type: "success" });
          }
        } else {
          let addFromData = {
            ...formData,
            thumbImage: formData.thumbImage
              ? formData.thumbImage
                  .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
                  .pop()
              : "",
            qpiId: QpiId,
          };
          delete addFromData.image;
          delete addFromData.imageName;
          const { data } = await Add_AddOnProduct_List(addFromData);
          if (data.status === 200) {
            geAddOnProductList();
            emptyData();
            TostMessage({ message: data, type: "success" });
          }
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  /**
   * delete Add on add on product
   * @param {id} addOnProductId
   */
  const handleDelete = async (addOnProductId) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await Delete_AddOnProduct_List(addOnProductId);
        if (data.status === 200) {
          geAddOnProductList();
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const emptyData = () => {
    setFormData({
      name: "",
      price: "",
      buttonName: "",
      thumbImage: "",
      menuProductId: "",
      qpiId: "",
    });
    setAddModel(false);
    setIsEdit(false);
    setId("");
  };
  return (
    <>
      {addModel && (
        <AddOnProductAddPopup
          handleSubmit={handleSubmit}
          simpleValidator={simpleValidator}
          imageUpload={imageUploadImages}
          QpiId={QpiId}
          emptyData={emptyData}
        />
      )}

      <AddOnProductItem
        sorting={sorting}
        switch_onChange_handle={switch_onChange_handle}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default AddOnProducts;
