import React, { useContext } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Card, CardHeader, Col, Container, Row } from "reactstrap";
import { IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import DataNotFound from "../../../DataNoteFound/DataNotFound";
import { MuiTabelStyle, headerStyle } from "../../../utils/MuiTabelStyle";
import { MOMENT_SETTING, WEB_CATEGORY_MAP } from "../../../constant";
import { TabelAnimation } from "../../../Spinner";
import WebCategoryMapContext from "../../../context/WebCategoryMapContext/WebCategoryMapContext";

const WebCategoryMapItem = (props) => {
  const { sorting, handleDelete } = props;
  const { formValue, record, pageSize, page, setPageSize, setPage } =
    useContext(WebCategoryMapContext);

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 200,
      field: "webDetail",
      headerName: "Domain",
      renderCell: (params) =>
        params.row.webDetail.domain ? params.row.webDetail.domain : "-",
    },
    {
      flex: 0.1,
      minWidth: 200,
      field: "categoryDetail",
      headerName: "Clone Category",
      renderCell: (params) =>
        params.row.categoryDetail?.name ? params.row.categoryDetail?.name : "-",
    },
    {
      flex: 0.1,
      minWidth: 150,
      field: "createdAt",
      headerName: "Created Date",
      renderCell: (params) =>
        params.row.createdAt
          ? moment.utc(params.row.createdAt).format(MOMENT_SETTING.FORMATE)
          : "-",
    },
    {
      flex: 0.01,
      minWidth: 100,
      field: "delete",
      headerName: "Delete",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Tooltip title="Delete">
          <IconButton
            aria-label="delete"
            style={{ color: "red" }}
            onClick={() => handleDelete(params.row.catWebMapId)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <h3 style={headerStyle}>{WEB_CATEGORY_MAP.THEME_NAME}</h3>
              </CardHeader>
              {TabelAnimation === true ? (
                <TabelAnimation />
              ) : formValue.length ? (
                <DataGrid
                  autoHeight
                  sx={MuiTabelStyle}
                  pagination
                  rows={formValue}
                  getRowId={(row) => row.catWebMapId}
                  columns={defaultColumns}
                  rowsPerPageOptions={[7, 10, 25, 50]}
                  rowCount={record}
                  pageSize={pageSize}
                  page={page - 1}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  onPageChange={(newPageNo) => setPage(newPageNo + 1)}
                  paginationMode="server"
                  disableSelectionOnClick
                  checkboxSelection={false}
                  sortingMode="server"
                  onSortModelChange={sorting}
                />
              ) : (
                <DataNotFound />
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default WebCategoryMapItem;
