import React, { useContext, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import { Cases, Currency, DELETE_CONFIRM, modalsName } from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import CommonPopup from "../../../Modals/commonPopup";
import ProductMultipagePopup from "./ProductMultipagePopup";
import EditIcon from "@mui/icons-material/Edit";
import ProductMultipageParentPopup from "./ProductMultipageParentPopup";
import { FILE_UPLOAD_API } from "../apis/SliderApi/SliderApi";
import {
  generateDynamicStyles,
  generateDynamicStylesSet,
  isCheckValue,
  isEmptyObject,
  randomStr,
  rgbColorCreate,
} from "../../../../utils/helper";
import { FILE_TYPE, ThemeNo, titleUpdateKey } from "../../../../constant";
import { IconButton } from "@material-ui/core";
import { Settings, Trash } from "react-feather";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import SimpleReactValidator from "simple-react-validator";
import SettingProductPerentPopup from "./SettingProductPerentPopup";
import ProductMultipageContext from "../../../context/ProductMultipageContext/ProductMultipageContext";
import {
  deleteProduct,
  getProduct,
  getProductDetails,
  postProduct,
  putUpdateProduct,
  updateStatus,
} from "../apis/ProductApi/ProductApi";
import DeleteSection from "../../../common/DeleteSection";
import SettingMultiWebContext from "../../../../context/SettingMultiWebContext/SettingMultiWebContext";
import {
  ProductStyleOneReset,
  ProductStyleOneSubFun,
  ProductThemeOneFunction,
} from "./ProductStyleFunction";

const ProductMultipage = (props) => {
  const {
    pageConfigDetails,
    webMasterDetail,
    getData,
    productDetail,
    contentMapId,
    ByteMiniWeb_Dashboard,
    navMapId: navMapIds,
    contentDetail,
  } = props;
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );
  const { setCloneAlignModal, setAlignFormData, setCloneFormData } = useContext(
    SettingMultiWebContext
  );
  const parentValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const childValidator = useRef(new SimpleReactValidator());
  const {
    fromData,
    setFormData,
    formDataChild,
    setFormDataChild,
    id,
    setId,
    setIsEdit,
    isEdit,
    setFilter,
    apiDataOurTeam: apiDataImageParent,
    setApiDataOurTeam: setApiDataImageParent,
    setSkill,
    setGalleryImgId,
    setSettingFormData,
    settingFormData,
    isParent,
    setIsParent,
    childId,
    setChildId,
    navMapId,
    setNavMapId,
    boxSection,
    setBoxSection,
    collId,
    setCollId,
    images,
    setImages,
    index,
    setBackImageToggle,
    isBackgroundColor,
    setIsBackgroundColor,
    theme,
    setTheme,
    imgShow,
    setImgShow,
    sectionPageName,
    setSectionPageName,
  } = useContext(ProductMultipageContext);
  const { setLoaderShow } = useContext(LoaderContext);
  /**
   * get download list
   * @param {status} status
   */
  const getDownloadParentList = async ({
    parentId = null,
    isClose = false,
    filter,
  }) => {
    try {
      let paramObj = {};
      if (
        ((parentId && parentId !== null) ||
          (id && isClose === false && isParent === false)) &&
        isParent === false
      ) {
        paramObj["search"] = { parentId: parentId || id };
        setId(parentId || id);
        if (filter?.length) {
          paramObj["search"] = { ...paramObj.search, title: filter };
        }
      } else {
        paramObj["search"] = { parentId: null };
      }
      const { data } = await getProduct(paramObj);
      if (isCheckValue({ value: parentId })) {
        setChildId(parentId);
      }
      if (data.status === 200) {
        if (
          isParent === false &&
          ((parentId && parentId !== null) || id) &&
          isClose === false
        ) {
          setApiDataImageParent({
            ...apiDataImageParent,
            isLoading: false,
            data: data.payload.data.map((elem) => {
              return {
                id: elem?.title + elem?.productId,
                Id: elem?.productId,
                name: elem?.title,
                image: elem?.image,
                webId: elem?.webId,
                createdAt: elem?.createdAt,
                isActive: elem?.isActive,
                parentId: elem?.parentId,
              };
            }),
          });
          OpenModalCommonFunc(Cases.PRODUCTS, setAllPopupState);
        } else if (parentId && parentId !== null) {
          OpenModalCommonFunc(Cases.PRODUCTS, setAllPopupState);
          setApiDataImageParent({
            ...apiDataImageParent,
            isLoading: false,
            data: data.payload.data.map((elem) => {
              return {
                id: elem?.title + elem?.productId,
                Id: elem?.productId,
                name: elem?.title,
                image: elem?.image,
                webId: elem?.webId,
                createdAt: elem?.createdAt,
                isActive: elem?.isActive,
                parentId: elem?.parentId,
              };
            }),
          });
        } else {
          setApiDataImageParent({
            ...apiDataImageParent,
            isLoading: false,
            data: data.payload.data,
          });
        }
        if (isClose && isClose !== false) {
          setIsParent(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * download enable disable api call
   * @param {productId} productId
   * @param {status} isActive
   */

  const handleChangeDownload = async (
    productId,
    isActive,
    isChild,
    parentId
  ) => {
    try {
      let body = {
        productId,
        isActive: isActive ? false : true,
      };
      if (parentId) {
        body["parentId"] = parentId;
      }
      const { data } = await updateStatus(body);
      if (data.status === 200) {
        if (isChild) {
          getDownloadParentList({ parentId });
        } else {
          ByteMiniWeb_Dashboard();
        }
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * file upload
   * @param {file} file
   */
  const imageUploadImages = async (file) => {
    setLoaderShow(true);
    let formData = new FormData();
    formData.append("type", FILE_TYPE.PRODUCT);
    formData.append("file", file);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      const image = data.payload.baseUrl + data.payload.imageUrl;
      if (data.status === 200) {
        setLoaderShow(false);
        setImages((prev) => {
          let newData = [...prev];
          newData[index] = {
            path: image,
            isDefault: prev[index].isDefault,
          };
          return newData;
        });
        setFormDataChild((prev) => {
          return {
            ...prev,
            image: "",
          };
        });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const BackImageSetting = async (file) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.PRODUCT);
    formData.append("file", file);
    setLoaderShow(true);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        setLoaderShow(false);
        setSettingFormData((prev) => {
          return {
            ...prev,
            ".products-section.bg-image": {
              "background-image": data.payload.baseUrl + data.payload.imageUrl,
            },
          };
        });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * create and update download
   */

  const submitForm = async () => {
    if (
      (isParent === false && childId >= -1 && isEdit) ||
      (id >= -1 && isEdit)
    ) {
      setLoaderShow(true);
      try {
        let formData = {};
        if (isParent && isParent !== false) {
          formData = {
            ...fromData,
            productId: id,
            contentMapId: collId,
            navMapId: +navMapIds,
          };
          setId("");
        } else {
          formData = {
            ...formDataChild,
            images: images.map((elem) => {
              return {
                path: elem.path
                  .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
                  .pop(),
                isDefault: elem.isDefault,
              };
            }),
            currencySymbol: formDataChild?.currencySymbol?.value,
            parentId: id,
            navMapId,
            productId: childId,
            contentMapId: collId,
          };
        }
        const { data } = await putUpdateProduct(formData);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            ByteMiniWeb_Dashboard();
          } else {
            getDownloadParentList({ parentId: id });
          }
          emptyData();
          OpenModalCommonFunc(Cases.PRODUCTS_PARENT, setAllPopupState, false);
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    } else {
      try {
        setLoaderShow(true);
        let formData = {};
        if (isParent && isParent !== false) {
          formData = {
            ...fromData,
            contentMapId: collId,
            navMapId: +navMapIds,
          };
        } else {
          formData = {
            ...formDataChild,
            images: images.map((elem, i) => {
              return {
                path: elem.path
                  .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
                  .pop(),
                isDefault: elem.isDefault,
                order: i + 1,
              };
            }),
            currencySymbol: formDataChild?.currencySymbol?.value,
            parentId: id,
            navMapId,
            order: apiDataImageParent.data.length + 1,
            contentMapId: collId,
          };
        }
        const { data } = await postProduct(formData);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            ByteMiniWeb_Dashboard();
          } else {
            getDownloadParentList({ parentId: id });
          }
          emptyData();
          OpenModalCommonFunc(Cases.PRODUCTS_PARENT, setAllPopupState, false);
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  const handleSubmit = async (formValidationCondition) => {
    let validatorFields;
    if (formValidationCondition === "parentForm") {
      validatorFields = parentValidator.current;
    } else if (formValidationCondition === "childForm") {
      validatorFields = childValidator.current;
    }
    validatorFields.showMessages();

    if (validatorFields.allValid()) {
      try {
        // Process form data based on the formType
        if (formValidationCondition === "parentForm") {
          submitForm();
        } else if (formValidationCondition === "childForm") {
          submitForm();
        }
        validatorFields.hideMessages();
      } catch (error) {
        // Handle error for form submission
      }
    } else {
      // Force update to display validation messages
      forceUpdate({});
    }
  };

  /**
   * create and update download
   */
  const handleSubmitSetting = async (isRestore = false, isClose = true) => {
    if (id >= -1 && isEdit) {
      setLoaderShow(true);
      try {
        let newData;
        if (isRestore) {
          const isConfirm = window.confirm(
            "Are you sure want to restore style ?"
          );
          if (isConfirm) {
            newData = {
              styleRestore: true,
              productId: id,
            };
          } else {
            setLoaderShow(false);
            return;
          }
        } else {
          if (+theme === ThemeNo.ThemeOne) {
            newData = ProductStyleOneSubFun({
              settingFormData,
              boxSection,
            });
            const menuBtnStyle = generateDynamicStyles(
              settingFormData,
              ".products-section"
            );
            newData = { ...newData, ...menuBtnStyle };
            if (isBackgroundColor) {
              newData[".products-section"] = {
                ...newData?.[".products-section"],
                "background-color": settingFormData[".products-section"][
                  "background-color"
                ]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData[".products-section"][
                          "background-color"
                        ],
                    })
                  : "",
              };
            }
            if (boxSection) {
              newData[".products-section.bg-image::before"] = {
                ...newData[".products-section.bg-image::before"],
                "border-radius": `${settingFormData[".products-section"][
                  "border-radius"
                ]?.replace("rem", "")}rem`,
              };
            }
          }

          const keysToRemove = [
            "bs1",
            "bs2",
            "bs3",
            "bs4",
            "borderPx",
            "borderThik",
            "p1",
            "p2",
            "p3",
            "m1",
            "m2",
            "m3",
            "m4",
            "image",
          ];
          function removeKeys(obj, keys) {
            for (const key in obj) {
              if (typeof obj[key] === "object") {
                removeKeys(obj[key], keys);
              } else if (keys.includes(key)) {
                delete obj[key];
              }
            }
          }
          removeKeys(newData, keysToRemove);
          newData = {
            style: newData,
            productId: id,
          };
        }

        const { data } = await putUpdateProduct(newData);
        if (data.status === 200) {
          if (isClose) {
            OpenModalCommonFunc(
              Cases.SETTING_PRODUCT_PARENT,
              setAllPopupState,
              false
            );
            emptyData();
            setBoxSection(false);
            setBackImageToggle(false);
          }
          setLoaderShow(false);
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  /**
   * get data download
   * @param {id} productId
   */
  const handleEditDownload = async (productId, isParent) => {
    try {
      setLoaderShow(true);
      const { data } = await getProductDetails(productId, navMapIds);
      if (data.status === 200) {
        let payload = data.payload.data;
        if (isParent) {
          setSectionPageName(payload?.title);
          setId(productId);
          setIsParent(true);
          setFormData({
            title: payload?.title || "",
            slugUrl: payload?.slugUrl || "",
            theme: payload?.theme || "",
            buttonText: payload?.buttonText || "",
            subTitle: payload?.subTitle || "",
            itemLimit: payload?.itemLimit || "",
            isTitleShow: payload?.isTitleShow ? payload?.isTitleShow : false,
            navMapId: payload?.navMapId || "",
            webId: localStorage.getItem("webId"),
            style: payload?.style || "",
          });
          OpenModalCommonFunc(Cases.PRODUCTS_PARENT, setAllPopupState);
        } else {
          let value = Currency.filter(
            (elem) => elem.value === payload.currencySymbol
          );
          setChildId(productId);
          setIsParent(false);
          setFormDataChild({
            title: payload?.title || "",
            slugUrl: payload?.slugUrl || "",
            description: payload.description,
            isTitleShow: payload?.isTitleShow ? payload?.isTitleShow : false,
            image: payload.image,
            price: payload.price,
            productId: productId,
            currencySymbol: value.length && value[0],
            order: payload?.order || 1,
            webId: localStorage.getItem("webId"),
          });
          if (payload.images.length < 4) {
            setImages((prev) => [
              ...payload.images.map((elem) => {
                return {
                  path: elem.path,
                  isDefault: elem.isDefault,
                };
              }),
              ...prev.slice(payload.images.length),
            ]);
          } else {
            setImages(
              payload.images.map((elem) => {
                return {
                  path: elem.path,
                  isDefault: elem.isDefault,
                };
              })
            );
          }
          OpenModalCommonFunc(Cases.PRODUCTS, setAllPopupState);
        }
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * get setting data
   * @param {id} productId
   */
  const handleChangeSettings = async (productId, isThemeEdit = false) => {
    try {
      setLoaderShow(true);
      setId(productId);
      setSettingFormData((prev) => {
        return {
          ...prev,
          productId,
        };
      });
      const { data } = await getProductDetails(productId);
      if (data.status === 200) {
        const themeCount = data?.payload?.data?.theme;
        setTheme(themeCount ? themeCount : 1);
        setImgShow(true);
        let payload = data.payload.data;
        setSectionPageName(payload.title);
        if (
          +themeCount === ThemeNo.ThemeOne &&
          isEmptyObject(data?.payload?.data?.style)
        ) {
          setSettingFormData({ ...ProductStyleOneReset });
          setBoxSection(false);
          setIsBackgroundColor(false);
          setBackImageToggle(false);
        } else {
          if (
            payload.style?.[".products-section"]?.["width"] ||
            payload.style?.[".products-section"]?.["margin"] ||
            payload.style?.[".products-section"]?.["border-radius"]
          ) {
            setBoxSection(true);
          }
          if (
            payload.style?.[".products-section.bg-image"]?.["background-image"]
          ) {
            setBackImageToggle(true);
          }
          if (payload.style?.[".products-section"]?.["background-color"]) {
            setIsBackgroundColor(true);
          }
          const getMenuBtnStyle = generateDynamicStylesSet(
            payload,
            ".products-section"
          );
          let getStyle;
          if (+themeCount === ThemeNo.ThemeOne) {
            getStyle = ProductThemeOneFunction(payload);
          } else {
            getStyle = ProductThemeOneFunction(payload);
          }
          setSettingFormData((prev) => {
            return {
              ...prev,
              ...getStyle,
              ...getMenuBtnStyle,
            };
          });
        }
        if (!isThemeEdit) {
          OpenModalCommonFunc(Cases.SETTING_PRODUCT_PARENT, setAllPopupState);
          TostMessage({ message: data, type: "success" });
        }
        setIsEdit(true);
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * delete download
   * @param {id} productId
   */
  const handleDelete = async (productId, isParent = false) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await deleteProduct(productId);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            // getDownloadParentList(null, true);
            ByteMiniWeb_Dashboard();
          } else {
            getDownloadParentList({ parentId: childId });
          }
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * clear data
   */
  const emptyData = (fileInput) => {
    setIsBackgroundColor(false);
    setBoxSection(false);
    setBackImageToggle(false);
    setIsEdit(false);
    setIsParent(false);
    setChildId("");
    if (fileInput) {
      fileInput.value = null;
    }
    const fileInput12 = document.getElementById("fileInput");
    if (fileInput12) {
      fileInput12.value = null;
    }
    setFormData({
      title: "",
      navMapId: "",
      slugUrl: "",
      buttonText: "",
      itemLimit: "",
      theme: "",
      webId: localStorage.getItem("webId"),
      subTitle: "",
      isTitleShow: true,
      style: {
        ".products-section": {
          padding: "100px 0",
          "background-color": "rgb(255 255 255 / 100)",
        },
        ".products-section.bg-image": {
          "background-image": "",
        },
        ".products-section.bg-image::before": {
          "background-color": "",
        },
        ".products-section .section-title-area": {
          "margin-bottom": "50px",
        },
        ".products-section .section-title-area .section-subtitle": {
          "font-size": "16px",
          "font-weight": "600",
          color: "rgb(255 73 124 / 100)",
          "background-color": "rgb(255 73 124 / 0.2)",
          padding: "5px 20px",
          p1: "5px",
          p2: "20px",
          "border-radius": "15px",
          "margin-bottom": "15px",
        },
        ".products-section .section-title-area .section-title": {
          "font-size": "44px",
          "font-weight": "600",
          color: "rgb(0 0 0 / 100)",
        },
        ".products-section .product-catlist": {
          "text-align": "center",
          "margin-bottom": "30px",
        },
        ".products-section .product-catlist .btn": {
          border: "2px solid rgb(255 73 124 / 100)",
          borderColor: "rgb(255 73 124 / 100)",
          color: "rgb(255 73 124 / 100)",
          padding: "6px 20px",
          p1: "6px",
          p2: "20px",
          margin: "5px",
          "box-shadow": "none",
        },
        ".products-section .product-catlist .active": {
          "background-color": "rgb(255 73 124 / 100)",
          color: "rgb(255 255 255 / 100)",
        },
        ".products-section .product-catdata .box": {
          "margin-bottom": "15px",
          outline: "none",
          "background-color": "rgb(255 255 255 / 100)",
          "box-shadow": "0 5px 10px 0 rgb(7 28 31 / 0.098)",
          bs1: "0px",
          bs2: "5px",
          bs3: "10px",
          bs4: "0px",
          shedowColor: "rgb(7 28 31 / 0.098)",
          "border-radius": "0px",
        },
        ".products-section .product-catdata .product-img .taglabel": {
          "background-color": "rgb(0 138 73 / 100)",
          color: "rgb(255 255 255 / 100)",
          "font-size": "14px",
          "font-weight": "400",
          padding: "8px 15px",
          p1: "8px",
          p2: "15px",
          "border-radius": "5px",
          top: "10px",
          left: "10px",
        },
        ".products-section .product-catdata .product-img .post-date": {
          "background-color": "rgb(255 255 255 / 100)",
          "border-radius": "5px",
          color: "rgb(255 73 124 / 100)",
          "font-size": "14px",
          "font-weight": "400",
          padding: "8px 15px",
          p1: "8px",
          p2: "15px",
          "text-align": "center",
          top: "10px",
          right: "10px",
        },
        ".products-section .product-catdata .product-content": {
          padding: "20px",
          float: "left",
          width: "100%",
          "text-align": "center",
        },
        ".products-section .product-catdata .product-content .shop a": {
          color: "rgb(0 0 0 / 100)",
          "font-size": "20px",
        },
        ".products-section .product-catdata .product-content .shop a:hover": {
          color: "rgb(255 73 124 / 100)",
        },
        ".products-section .product-catdata .product-content .price": {
          color: "rgb(51 51 51 / 100)",
          margin: "15px 5px 10px 5px",
          m1: "15px",
          m2: "5px",
          m3: "10px",
          m4: "5px",
        },
        ".products-section .product-catdata .product-content .price del": {
          color: "rgb(153 153 153 / 100)",
          "margin-right": "15px",
        },
        ".products-section .product-catdata .product-content .price ins": {
          "text-decoration": "none",
          color: "rgb(0 0 0 / 100)",
        },
        ".products-section .product-catdata .product-content .add-cart": {
          border: "2px solid rgb(255 73 124 / 100)",
          borderColor: "rgb(255 73 124 / 100)",
          "background-color": "rgb(255 73 124 / 100)",
          color: "rgb(255 255 255 / 100)",
          "font-size": "14px",
          "font-weight": "500",
          padding: "4px 15px",
          p1: "4px",
          p2: "15px",
          "box-shadow": "none",
        },
        ".products-section .product-catdata .product-content .add-cart:hover": {
          opacity: "0.8",
        },
        ".products-section .more-btn": {
          color: "rgb(255 255 255 / 100)",
          "font-size": "16px",
          "font-weight": "normal",
          "border-radius": "15px",
          padding: "5px 20px",
          "background-color": "rgb(255 73 124 / 100)",
        },
        ".products-section .more-btn:hover": {
          "background-color": "rgb(255 73 124 / 100)",
          color: "rgb(255 255 255 / 100)",
        },
        ".pagination-positions": {
          "justify-content": "center",
        },
        ".pagination .page-link": {
          color: "rgb(255 73 124 / 100)",
        },
        ".pagination .page-item.active .page-link": {
          "background-color": "rgb(255 73 124 / 100)",
          "border-color": "rgb(255 73 124 / 100)",
        },
      },
    });
    setSkill([{ id: randomStr(2), skill: "", parentageOfSkill: "" }]);
    setGalleryImgId("");
    setFormDataChild({
      title: "",
      slugUrl: "",
      description: "",
      isTitleShow: true,
      image: "",
      price: "",
      currencySymbol: "$",
      order: 1,
      webId: localStorage.getItem("webId"),
    });
    setImages([
      { path: "", isDefault: true },
      { path: "", isDefault: false },
      { path: "", isDefault: false },
      { path: "", isDefault: false },
    ]);
    setLoaderShow(false);
    setSettingFormData({
      ".products-section": {
        padding: "100px 0",
        "background-color": "rgb(255 255 255 / 100)",
      },
      ".products-section.bg-image": {
        "background-image": "",
      },
      ".products-section.bg-image::before": {
        "background-color": "",
      },
      ".products-section .section-title-area": {
        "margin-bottom": "50px",
      },
      ".products-section .section-title-area .section-subtitle": {
        "font-size": "16px",
        "font-weight": "600",
        color: "rgb(255 73 124 / 100)",
        "background-color": "rgb(255 73 124 / 0.2)",
        padding: "5px 20px",
        p1: "5px",
        p2: "20px",
        "border-radius": "15px",
        "margin-bottom": "15px",
      },
      ".products-section .section-title-area .section-title": {
        "font-size": "44px",
        "font-weight": "600",
        color: "rgb(0 0 0 / 100)",
      },
      ".products-section .product-catlist": {
        "text-align": "center",
        "margin-bottom": "30px",
      },
      ".products-section .product-catlist .btn": {
        border: "2px solid rgb(255 73 124 / 100)",
        borderColor: "rgb(255 73 124 / 100)",
        color: "rgb(255 73 124 / 100)",
        padding: "6px 20px",
        p1: "6px",
        p2: "20px",
        margin: "5px",
        "box-shadow": "none",
      },
      ".products-section .product-catlist .active": {
        "background-color": "rgb(255 73 124 / 100)",
        color: "rgb(255 255 255 / 100)",
      },
      ".products-section .product-catdata .box": {
        "margin-bottom": "15px",
        outline: "none",
        "background-color": "rgb(255 255 255 / 100)",
        "box-shadow": "0 5px 10px 0 rgb(7 28 31 / 0.098)",
        bs1: "0px",
        bs2: "5px",
        bs3: "10px",
        bs4: "0px",
        shedowColor: "rgb(7 28 31 / 0.098)",
        "border-radius": "0px",
      },
      ".products-section .product-catdata .product-img .taglabel": {
        "background-color": "rgb(0 138 73 / 100)",
        color: "rgb(255 255 255 / 100)",
        "font-size": "14px",
        "font-weight": "400",
        padding: "8px 15px",
        p1: "8px",
        p2: "15px",
        "border-radius": "5px",
        top: "10px",
        left: "10px",
      },
      ".products-section .product-catdata .product-img .post-date": {
        "background-color": "rgb(255 255 255 / 100)",
        "border-radius": "5px",
        color: "rgb(255 73 124 / 100)",
        "font-size": "14px",
        "font-weight": "400",
        padding: "8px 15px",
        p1: "8px",
        p2: "15px",
        "text-align": "center",
        top: "10px",
        right: "10px",
      },
      ".products-section .product-catdata .product-content": {
        padding: "20px",
        float: "left",
        width: "100%",
        "text-align": "center",
      },
      ".products-section .product-catdata .product-content .shop a": {
        color: "rgb(0 0 0 / 100)",
        "font-size": "20px",
      },
      ".products-section .product-catdata .product-content .shop a:hover": {
        color: "rgb(255 73 124 / 100)",
      },
      ".products-section .product-catdata .product-content .price": {
        color: "rgb(51 51 51 / 100)",
        margin: "15px 5px 10px 5px",
        m1: "15px",
        m2: "5px",
        m3: "10px",
        m4: "5px",
      },
      ".products-section .product-catdata .product-content .price del": {
        color: "rgb(153 153 153 / 100)",
        "margin-right": "15px",
      },
      ".products-section .product-catdata .product-content .price ins": {
        "text-decoration": "none",
        color: "rgb(0 0 0 / 100)",
      },
      ".products-section .product-catdata .product-content .add-cart": {
        border: "2px solid rgb(255 73 124 / 100)",
        borderColor: "rgb(255 73 124 / 100)",
        "background-color": "rgb(255 73 124 / 100)",
        color: "rgb(255 255 255 / 100)",
        "font-size": "14px",
        "font-weight": "500",
        padding: "4px 15px",
        p1: "4px",
        p2: "15px",
        "box-shadow": "none",
      },
      ".products-section .product-catdata .product-content .add-cart:hover": {
        opacity: "0.8",
      },
      ".products-section .more-btn": {
        color: "rgb(255 255 255 / 100)",
        "font-size": "16px",
        "font-weight": "normal",
        "border-radius": "15px",
        padding: "5px 20px",
        "background-color": "rgb(255 73 124 / 100)",
      },
      ".products-section .more-btn:hover": {
        "background-color": "rgb(255 73 124 / 100)",
        color: "rgb(255 255 255 / 100)",
      },
      ".pagination-positions": {
        "justify-content": "center",
      },
      ".pagination .page-link": {
        color: "rgb(255 73 124 / 100)",
      },
      ".pagination .page-item.active .page-link": {
        "background-color": "rgb(255 73 124 / 100)",
        "border-color": "rgb(255 73 124 / 100)",
      },
    });
  };

  const handleAlignAndCloneSection = ({ isClone = false }) => {
    if (isClone) {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          cloneModal: true,
        };
      });
      setCloneFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          pageConfigDetailsLabel: pageConfigDetails[0]?.label,
          contentDetailName: contentDetail.name,
        };
      });
    } else {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          alignModal: true,
        };
      });
      setAlignFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          label: pageConfigDetails[0]?.label,
        };
      });
    }
  };

  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody
            className="support-ticket-font box-border-div"
            style={{ padding: "20px" }}
          >
            <Row>
              <CardBuilderHeaderSection
                addBtnTitle={pageConfigDetails[0]?.label}
                name={pageConfigDetails[0]?.label}
                isAdd={!productDetail && true}
                mainEditSection={true}
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
                openModal={() => {
                  OpenModalCommonFunc(Cases.PRODUCTS_PARENT, setAllPopupState);
                  setSectionPageName(modalsName.PRODUCTS);
                  emptyData();
                  setIsParent(true);
                  setCollId(contentMapId);
                }}
                isCloneSection={true}
                isAlignSection={true}
                handleAlignAndCloneSection={handleAlignAndCloneSection}
              />
              {productDetail && (
                <Col xl="12 xl-50 box-col-6" md="6">
                  <Card className="ecommerce-widget mb-0">
                    <CardBody
                      className="support-ticket-font"
                      style={{ padding: "20px" }}
                    >
                      <div className="col-12">
                        <CardBuilderHeaderSection
                          name={productDetail?.title}
                          checked={productDetail?.isActive}
                          switchToggle={true}
                          id={productDetail?.productId}
                          handleChange={handleChangeDownload}
                        />
                        <OpenPopupBtn
                          openPopup={() => {
                            getDownloadParentList({
                              parentId: productDetail.productId,
                            });
                            setSectionPageName(productDetail?.title);
                            setNavMapId(productDetail?.navMapId);
                            setFilter("");
                            setCollId(contentMapId);
                          }}
                          name={`Add or View ${productDetail?.title}`}
                        />
                      </div>
                      <div className="d-flex justify-content-end ">
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleEditDownload(productDetail.productId, true);
                            setCollId(contentMapId);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() =>
                            handleDelete(productDetail.productId, true)
                          }
                        >
                          <Trash />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleChangeSettings(productDetail.productId);
                          }}
                        >
                          <Settings />
                        </IconButton>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
              <DeleteSection
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
              />
            </Row>
          </CardBody>
        </Card>
      </Col>
      <CommonPopup
        open={allPopupState.productsPopup}
        close={() => {
          OpenModalCommonFunc(Cases.PRODUCTS, setAllPopupState, false);
          setIsParent(false);
        }}
        // apiCall={getDownloadParentList}
        modalName={sectionPageName ? sectionPageName : modalsName.PRODUCTS}
        modalCase={Cases.PRODUCTS}
        handleSubmit={handleSubmit}
        validationForm={"childForm"}
        emptyData={emptyData}
        inputData={fromData}
        edit={false}
        oldTitle={titleUpdateKey.isGallery}
        label={titleUpdateKey.Certificates}
        getData={getData}
        content={
          <ProductMultipagePopup
            getDownloadParentList={getDownloadParentList}
            handleChangeStatus={handleChangeDownload}
            handleEdit={handleEditDownload}
            imageUpload={imageUploadImages}
            emptyData={emptyData}
            simpleValidator={childValidator}
            webMasterDetail={webMasterDetail}
            handleDelete={handleDelete}
          />
        }
      />

      {/* slider popup */}
      <CommonPopup
        open={allPopupState.productsParentPopup}
        close={() => {
          OpenModalCommonFunc(Cases.PRODUCTS_PARENT, setAllPopupState, false);
          setIsParent(false);
        }}
        modalName={
          sectionPageName
            ? sectionPageName
            : modalsName.PRODUCT_MULTIPAGE_PARENT
        }
        modalCase={Cases.PRODUCTS_PARENT}
        handleSubmit={handleSubmit}
        validationForm={"parentForm"}
        emptyData={emptyData}
        inputData={formDataChild}
        edit={false}
        content={
          <ProductMultipageParentPopup simpleValidator={parentValidator} />
        }
      />

      {/* setting Service Counter parent popup */}
      <CommonPopup
        open={allPopupState.productSettingPopup}
        close={() =>
          OpenModalCommonFunc(
            Cases.SETTING_PRODUCT_PARENT,
            setAllPopupState,
            false
          )
        }
        modalName={
          sectionPageName ? sectionPageName : modalsName.SETTING_PRODUCT_PARENT
        }
        modalCase={Cases.SETTING_PRODUCT_PARENT}
        handleSubmit={handleSubmitSetting}
        emptyData={emptyData}
        inputData={formDataChild}
        edit={false}
        content={
          <SettingProductPerentPopup
            imageUpload={BackImageSetting}
            handleSubmitSetting={handleSubmitSetting}
            handleChangeSettings={handleChangeSettings}
          />
        }
      />
    </>
  );
};

export default ProductMultipage;
