import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Zoom, toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import ThemeContext from "../../context/ThemeContext/ThemeContext";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import { TostMessage } from "../../components ByteCard/utils";
import { DELETE_CONFIRM } from "../../components ByteCard/constant";
import {
  Add_Theme_List,
  Delete_Theme_List,
  Edit_Theme_List,
  Theme_Enable_Desable,
  Theme_List,
} from "../../api/ThemeApi/ThemeApi";
import { FILE_TYPE, SORTING_ORDER } from "../../constant";
import ThemeItem from "./ThemeItem";
import ThemeAddPopup from "./ThemeAddPopup";
import TopLoadingBar from "../../utils/topLoadingBar";
import Breadcrumbs from "../../layout/breadcrumb";
import { FILE_API } from "../../api/Organization Api/OrganizationApi";

const Theme = () => {
  const {
    setFormValue,
    formData,
    pageSize,
    sortOrder,
    setSortOrder,
    page,
    sortBy,
    setSortBy,
    setPageLimit,
    setRecord,
    isSwitchOn,
    setIsSwitchOn,
    addModel,
    setFormData,
    setAddModel,
    isEdit,
    setIsEdit,
    id,
    setId,
    progress,
    setProgress,
  } = useContext(ThemeContext);
  const { setLoaderShow } = useContext(LoaderContext);
  const { tabelAnimation, setTableAnimation } = useContext(LoaderContext);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();

  const getThemeList = async (desabledStatusLoader) => {
    if (
      sortOrder === "ASC" ||
      sortOrder === "DSC" ||
      desabledStatusLoader === false
    ) {
    } else {
      setTableAnimation(!tabelAnimation);
    }
    try {
      let paramObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
      };
      const { data } = await Theme_List(paramObj);

      if (data.status === 200) {
        setFormValue(data.payload.data);
        setPageLimit(data.payload.pageLimit);
        setRecord(data.pager.totalRecords);

        setTableAnimation(false);
      } else {
      }
    } catch (error) {
      setTableAnimation(false);
    }
  };

  useEffect(() => {
    getThemeList();
  }, [page, pageSize, sortBy, sortOrder]);

  /**
   * Enable and Desable Category
   */
  const switch_onChange_handle = async (templateId, isActive) => {
    let formData = {
      templateId: templateId,
      isActive: isActive ? false : true,
      // type: TYPES.LANDING_PAGE,
    };
    try {
      const { data } = await Theme_Enable_Desable(formData, templateId);
      if (data.status === 200) {
        await setIsSwitchOn(!isSwitchOn);
        const desabledStatusLoader = false;
        await getThemeList(desabledStatusLoader);
        isActive
          ? toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            })
          : toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            });
      } else {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * file upload
   * @param {file} file
   */
  const imageUploadImages = async (file) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.ORGANIZATION_LOGO);
    formData.append("file", file);
    setLoaderShow(true);
    try {
      const { data } = await FILE_API(formData);
      if (data.status === 200) {
        setFormData((prev) => {
          return {
            ...prev,
            image: data.payload.baseUrl + data.payload.imageUrl,
          };
        });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * Sorting category Table
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder(SORTING_ORDER.ASC);
    }
    if (sortOrder === SORTING_ORDER.ASC) {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder(SORTING_ORDER.DSC);
    }
    if (sortOrder === SORTING_ORDER.DSC) {
      setSortBy();
      setSortOrder("");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        if (isEdit && id >= -1) {
          const allItemIds = formData?.itemIds.map((ele) => {
            return {
              itemId: ele.value,
            };
          });
          const systemIs = formData?.isSystem ? 1 : 0;
          let addFromData = {
            ...formData,
            code: formData?.code?.value,
            isSystem: systemIs,
            itemIds: allItemIds,
          };
          if (addFromData?.image?.length) {
            addFromData["image"] = addFromData.image
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
              .pop();
          }
          const { data } = await Edit_Theme_List(addFromData, id);
          if (data.status === 200) {
            getThemeList();
            emptyData();
            TostMessage({ message: data, type: "success" });
          }
        } else {
          const allItemIds = formData?.itemIds.map((ele) => {
            return {
              itemId: ele.value,
            };
          });
          const systemIs = formData?.isSystem ? 1 : 0;
          let addFromData = {
            ...formData,
            isSystem: systemIs,
            code: formData?.code?.value,
            itemIds: allItemIds,
          };
          if (addFromData?.image?.length) {
            addFromData["image"] = addFromData.image
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
              .pop();
          }
          const { data } = await Add_Theme_List(addFromData);
          if (data.status === 200) {
            getThemeList();
            emptyData();
            TostMessage({ message: data, type: "success" });
          }
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  /**
   * delete category
   * @param {id} templateId
   */
  const handleDelete = async (templateId) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await Delete_Theme_List(templateId);
        if (data.status === 200) {
          getThemeList();
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const emptyData = () => {
    setFormData({
      templateName: "",
      code: "",
      itemIds: [],
      isSystem: false,
      demoLink: "",
      image: "",
      title: "",
      videLink: "",
    });
    setAddModel(false);
    setIsEdit(false);
    setId("");
  };
  return (
    <Fragment>
      <Breadcrumbs parent="Theme" />
      <TopLoadingBar progress={progress} setProgress={setProgress} />
      {addModel && (
        <ThemeAddPopup
          handleSubmit={handleSubmit}
          simpleValidator={simpleValidator}
          emptyData={emptyData}
          imageUpload={imageUploadImages}
        />
      )}

      <ThemeItem
        sorting={sorting}
        switch_onChange_handle={switch_onChange_handle}
        handleDelete={handleDelete}
      />
    </Fragment>
  );
};

export default Theme;
