import React, { useContext } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import { Cases, btnName, modalsName } from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc } from "../../../utils";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import CommonPopup from "../../../Modals/commonPopup";
import OurClientPopup from "./OurClientsPopup";

const OurClient = () => {
  const { setAllPopupState, allPopupState } = useContext(
    ByteCardBuilderContext
  );
  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody className="support-ticket-font box-border-div">
            <Row>
              <div className="col-12">
                <CardBuilderHeaderSection name="Our Client" />
              </div>
              <OpenPopupBtn
                openPopup={() =>
                  OpenModalCommonFunc(Cases.OUR_CLIENT, setAllPopupState)
                }
                name={btnName.OUR_CLIENT}
              />
            </Row>
          </CardBody>
        </Card>
      </Col>

      <CommonPopup
        open={allPopupState.ourclientPopup}
        close={() =>
          OpenModalCommonFunc(Cases.OUR_CLIENT, setAllPopupState, false)
        }
        modalName={modalsName.OUR_CLIENT}
        modalCase={Cases.OUR_CLIENT}
        content={<OurClientPopup />}
      />
    </>
  );
};

export default OurClient;
