import React, { useState } from "react";
import InquiryContext from "./InquiryContext";
import { randomStr } from "../../../utils/helper";

const InquiryState = (props) => {
  const [apiData, setApiData] = useState({ data: [], isLoading: false });
  const [apiDataOurTeam, setApiDataOurTeam] = useState({
    isLoading: false,
    data: [],
  });
  const [skill, setSkill] = useState([
    { id: randomStr(2), skill: "", parentageOfSkill: "" },
  ]);
  const [isBackgroundColor, setIsBackgroundColor] = useState(false);
  const [collId, setCollId] = useState();
  const [backImageToggle, setBackImageToggle] = useState(false);
  const [fromData, setFormData] = useState({
    title: "",
    subTitle: "",
    navMapId: "",
    webId: localStorage.getItem("webId"),
    theme: 1,
    isTitleShow: true,
    style: {
      ".inquiry-section": {
        padding: "100px 0",
        "background-color": "rgb(255 255 255 / 100)",
      },
      ".inquiry-section.bg-image": {
        "background-image": "",
      },
      ".inquiry-section.bg-image::before": {
        "background-color": "",
      },
      ".inquiry-section .section-title-area": {
        "margin-bottom": "50px",
      },
      ".inquiry-section .section-title-area .section-subtitle": {
        "font-size": "16px",
        "font-weight": "600",
        color: "rgb(255 73 124 / 100)",
        "background-color": "rgb(255 73 124 / 0.2)",
        padding: "5px 20px",
        p1: "5px",
        p2: "20px",
        "border-radius": "15px",
        "margin-bottom": "15px",
      },
      ".inquiry-section .section-title-area .section-title": {
        "font-size": "44px",
        "font-weight": "600",
        color: "rgb(0 0 0 / 100)",
      },
      ".inquirybox": {
        "background-color": "rgb(255 255 255 / 100)",
        padding: "40px 40px",
        p1: "40px",
        p2: "40px",
        "border-radius": "15px",
        border: "1px solid rgb(255 255 255 / 100)",
        borderPx: "1px",
        borderThik: "solid",
        borderColor: "rgb(255 255 255 / 100)",
        "border-top": "3px solid rgb(255 73 124 / 100)",
        borderTPx: "1px",
        borderTThik: "solid",
        borderTColor: "rgb(255 73 124 / 100)",
        "box-shadow": "5px 5px 15px rgb(0 0 0 / 0.09)",
        bs1: "5px",
        bs2: "5px",
        bs3: "15px",
        shedowColor: "rgb(0 0 0 / 0.09)",
      },
      ".inquirybox .contact_form .form-control": {
        width: "100%",
        "margin-bottom": "10px",
        "min-height": "50px",
        padding: "10px 20px",
        p1: "10px",
        p2: "20px",
        "background-color": "rgb(242 247 255 / 100)",
        border: "1px solid rgb(206 212 218 / 100)",
        borderPx: "1px",
        borderThik: "solid",
        borderColor: "rgb(206 212 218 / 100)",
        "border-radius": "4px",
        "font-size": "16px",
      },
      ".inquirybox .capchabox": {
        "background-color": "rgb(246 246 246 / 100)",
        padding: "15px",
      },
      ".inquirybox .capchabox .capchatitle": {
        "margin-bottom": "10px",
      },
      ".inquirybox .contact_form .btn": {
        color: "rgb(255 255 255 / 100)",
        "background-color": "rgb(255 73 124 / 100)",
      },
    },
  });

  const [formDataChild, setFormDataChild] = useState({
    title: "",
    subTitle: "",
    description: "",
    order: 1,
    isTitleShow: true,
    webId: localStorage.getItem("webId"),
    navMapId: "",
  });

  const [settingFormData, setSettingFormData] = useState({
    ".inquiry-section": {
      padding: "100px 0",
      "background-color": "rgb(255 255 255 / 100)",
    },
    ".inquiry-section.bg-image": {
      "background-image": "",
    },
    ".inquiry-section.bg-image::before": {
      "background-color": "",
    },
    ".inquiry-section .section-title-area": {
      "margin-bottom": "50px",
    },
    ".inquiry-section .section-title-area .section-subtitle": {
      "font-size": "16px",
      "font-weight": "600",
      color: "rgb(255 73 124 / 100)",
      "background-color": "rgb(255 73 124 / 0.2)",
      padding: "5px 20px",
      p1: "5px",
      p2: "20px",
      "border-radius": "15px",
      "margin-bottom": "15px",
    },
    ".inquiry-section .section-title-area .section-title": {
      "font-size": "44px",
      "font-weight": "600",
      color: "rgb(0 0 0 / 100)",
    },
    ".inquirybox": {
      "background-color": "rgb(255 255 255 / 100)",
      padding: "40px 40px",
      p1: "40px",
      p2: "40px",
      "border-radius": "15px",
      border: "1px solid rgb(255 255 255 / 100)",
      borderPx: "1px",
      borderThik: "solid",
      borderColor: "rgb(255 255 255 / 100)",
      "border-top": "3px solid rgb(255 73 124 / 100)",
      borderTPx: "1px",
      borderTThik: "solid",
      borderTColor: "rgb(255 73 124 / 100)",
      "box-shadow": "5px 5px 15px rgb(0 0 0 / 0.09)",
      bs1: "5px",
      bs2: "5px",
      bs3: "15px",
      shedowColor: "rgb(0 0 0 / 0.09)",
    },
    ".inquirybox .contact_form .form-control": {
      width: "100%",
      "margin-bottom": "10px",
      "min-height": "50px",
      padding: "10px 20px",
      p1: "10px",
      p2: "20px",
      "background-color": "rgb(242 247 255 / 100)",
      border: "1px solid rgb(206 212 218 / 100)",
      borderPx: "1px",
      borderThik: "solid",
      borderColor: "rgb(206 212 218 / 100)",
      "border-radius": "4px",
      "font-size": "16px",
    },
    ".inquirybox .capchabox": {
      "background-color": "rgb(246 246 246 / 100)",
      padding: "15px",
    },
    ".inquirybox .capchabox .capchatitle": {
      "margin-bottom": "10px",
    },
    ".inquirybox .contact_form .btn": {
      color: "rgb(255 255 255 / 100)",
      "background-color": "rgb(255 73 124 / 100)",
    },
    inquiryId: "",
  });
  const [boxSection, setBoxSection] = useState(false);
  const [id, setId] = useState("");
  const [childId, setChildId] = useState("");
  const [navMapId, setNavMapId] = useState("");
  const [galleryImgId, setGalleryImgId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isParent, setIsParent] = useState(false);
  const [filter, setFilter] = useState("");
  const [theme, setTheme] = useState(false);
  const [imgShow, setImgShow] = useState(false);
  const [sectionPageName, setSectionPageName] = useState("");
  return (
    <InquiryContext.Provider
      value={{
        apiData,
        setApiData,
        apiDataOurTeam,
        setApiDataOurTeam,
        fromData,
        setFormData,
        id,
        setId,
        isEdit,
        setIsEdit,
        filter,
        setFilter,
        skill,
        galleryImgId,
        setGalleryImgId,
        setSkill,
        settingFormData,
        setSettingFormData,
        formDataChild,
        setFormDataChild,
        isParent,
        setIsParent,
        childId,
        setChildId,
        navMapId,
        setNavMapId,
        backImageToggle,
        setBackImageToggle,
        boxSection,
        setBoxSection,
        collId,
        setCollId,
        isBackgroundColor,
        setIsBackgroundColor,
        theme,
        setTheme,
        imgShow,
        setImgShow,
        sectionPageName,
        setSectionPageName,
      }}
    >
      {props.children}
    </InquiryContext.Provider>
  );
};

export default InquiryState;
