import React from "react";
import { Dialog } from "@material-ui/core";
import ModalNameAndClose from "../common/modalNameAndClose";

const CommonPopup = (props) => {
  const {
    open,
    close,
    modalName,
    modalCase,
    content,
    edit,
    oldTitle,
    label,
    handleSubmit,
    validationForm,
    emptyData,
    inputData,
    dataLength,
    getData,
    apiCall,
    isObjectParams,
    isMini = false,
  } = props;
  return (
    <>
      <Dialog fullScreen open={open} onClose={close}>
        <ModalNameAndClose
          name={modalName}
          modalCase={modalCase}
          isEdit={edit}
          oldTitle={oldTitle}
          handleSubmit={handleSubmit}
          emptyData={emptyData}
          inputData={inputData}
          dataLength={dataLength}
          labelNav={label}
          getDataNavbar={getData}
          apiCall={apiCall}
          validationForm={validationForm}
          isObjectParams={isObjectParams}
          isMini={isMini}
        />
        {content}
      </Dialog>
    </>
  );
};

export default CommonPopup;
