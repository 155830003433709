import React, { useContext } from "react";
import { IconButton } from "@material-ui/core";
import { DELETE_CONFIRM, SinglePage, options } from "../../../constant";
import { TostMessage } from "../../../utils";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import Dividers from "../../../common/divider";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import { ContactUsAddressCart } from "../../../common/contactUSCart";
import { maxLengthCheck, randomStr } from "../../../../utils/helper";
import Select from "react-select";
import { Trash2 } from "react-feather";
import ContactUsContext from "../../../context/ContactUsContaxt/ContactUsContaxt";
import {
  deleteContactUs,
  updateStatus,
} from "../../../apis/ContactUsApi/ContactUsApi";
import AddNewButton from "../../../common/AddNewButton";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import Editor from "../../../common/editor";

const ContactUsPopup = (props) => {
  const {
    getData,
    handleEdit,
    emptyData,
    handleSubmit,
    simpleValidator,
    webMasterDetail,
    navbarData,
  } = props;
  const {
    apiData,
    fromData,
    setFormData,
    addFields,
    setAddFields,
    setDefaultSet,
    isEdit,
  } = useContext(ContactUsContext);
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * add & delete phone and email input
   * @param {condition name} name
   */
  const addMoreInput = (name) => {
    if (name === "phone") {
      setAddFields((prev) => {
        return {
          ...prev,
          phoneInput: [
            ...prev.phoneInput,
            {
              id: randomStr(2),
              phoneValue: "",
              dialCode: { value: "+91", label: "+91" },
            },
          ],
        };
      });
    } else if (name === "email") {
      setAddFields((prev) => {
        return {
          ...prev,
          emailInput: [
            ...prev.emailInput,
            { id: randomStr(2), emailValue: "" },
          ],
        };
      });
    }
  };

  const deleteMoreInput = (id, name) => {
    if (name === "phone") {
      setAddFields((prev) => {
        return {
          ...prev,
          phoneInput: prev.phoneInput.filter((item) => item.id !== id),
        };
      });
    } else if (name === "email") {
      setAddFields((prev) => {
        return {
          ...prev,
          emailInput: prev.emailInput.filter((item) => item.id !== id),
        };
      });
    }
  };

  /**
   * phone and email value change
   * @param {Event} e
   * @param {index} i
   * @param {input name condition name} inputName
   */
  const handleChangePhoneInput = (e, i, inputName) => {
    const { name, value } = e.target;
    if (inputName === "phone") {
      let old = [...addFields.phoneInput];
      old[i][name] = value;
      setAddFields({ ...addFields, phoneInput: old });
    } else if (inputName === "email") {
      let old = [...addFields.emailInput];
      old[i][name] = value;
      setAddFields({ ...addFields, emailInput: old });
    }
  };

  const SelectDrop = (name, e, i) => {
    let old = [...addFields.phoneInput];
    old[i][e.name] = name;
    setAddFields({ ...addFields, phoneInput: old });
  };

  const inputChange = (e) => {
    let { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  /**
   * delete slider
   * @param {Id} contactUsId
   */
  const handleDelete = async (contactUsId) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await deleteContactUs(contactUsId);
        if (data.status === 200) {
          getData();
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * default set value change
   * @param {} isPrimary
   * @param {id} contactUsId
   */
  const defaultStar = async (isPrimary, contactUsId) => {
    setDefaultSet(!isPrimary);
    // setIsEdit(true);
    await handleEdit(contactUsId);
    await handleSubmit({
      contactUsId: contactUsId,
      isPrimary: !isPrimary,
      defaultSetStar: "star",
      isEditStar: true,
    });
  };

  /**
   * enable disable api call
   * @param {contactUs id} contactUsId
   * @param {status} isActive
   */

  const handleChangeStatus = async (contactUsId, isActive) => {
    try {
      let body = {
        contactUsId,
        isActive: isActive ? false : true,
        navMapId: navbarData[0].navMapId,
      };
      const { data } = await updateStatus(body);
      if (data.status === 200) {
        getData(false);
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * add new and auto focus in first field
   */
  const hanldenewCreate = () => {
    const firstInput = document.getElementById("branch");
    firstInput.focus();
    emptyData();
  };

  const handleAboutus = (event) => {
    setFormData((prev) => {
      return {
        ...prev,
        workingHour: event,
      };
    });
  };
  return (
    <>
      <Container className="mb-5">
        <Row className="justify-content-center ">
          {apiData.data.map((elem) => {
            return (
              <Col md={"4 mb-3"}>
                <ContactUsAddressCart
                  name={elem.name}
                  address={`${elem?.addLine1}, ${elem?.addLine1}, ${elem?.area}, ${elem?.city}, ${elem?.state}, ${elem?.country}, ${elem?.pinCode}`}
                  phone={
                    elem.phone &&
                    JSON.parse(elem.phone)?.map((elem) => {
                      return `${elem?.dialCode?.label} ${elem?.phoneValue} , `;
                    })
                  }
                  email={
                    elem?.email &&
                    JSON.parse(elem?.email)?.map((elem) => {
                      return `${elem?.emailValue} ,  `;
                    })
                  }
                  handleEdit={() =>
                    handleEdit(
                      elem?.contactUsId,
                      document.getElementById("branch")
                    )
                  }
                  handleDelete={() => handleDelete(elem?.contactUsId)}
                  defaultStar={() =>
                    defaultStar(elem?.isPrimary, elem?.contactUsId)
                  }
                  defaultSet={elem?.isPrimary}
                  checked={elem?.isActive}
                  handleChangeStatus={() =>
                    handleChangeStatus(elem?.contactUsId, elem?.isActive)
                  }
                />
              </Col>
            );
          })}
        </Row>
        {webMasterDetail?.webCategory?.name === SinglePage.WebCategory &&
          apiData?.data?.length !== webMasterDetail?.itemLimit && (
            <div className="d-flex justify-content-end align-items-center  ">
              <AddNewButton click={() => hanldenewCreate()} />
            </div>
          )}
        <Dividers divide={`${isEdit ? "Edit" : "ADD"} BRANCH`} />
        {(webMasterDetail?.webCategory?.name === SinglePage.WebCategory &&
          apiData?.data?.length === webMasterDetail?.itemLimit &&
          isEdit) ||
        (webMasterDetail?.webCategory?.name === SinglePage.WebCategory &&
          apiData?.data?.length !== webMasterDetail?.itemLimit) ? (
          <>
            <Row>
              <Col md="12 mb-3">
                <Label htmlFor="validationCustom03">{"Branch Name *"}</Label>
                <Input
                  className="form-control"
                  name="name"
                  id="branch"
                  type="text"
                  style={{ borderRadius: "0", color: "black" }}
                  value={fromData.name}
                  onChange={(e) => inputChange(e)}
                />
                <p style={{ color: "red" }}>
                  {simpleValidator.current.message(
                    "Branch Name",
                    fromData.name,
                    "required"
                  )}
                </p>
              </Col>
              <Col md="6 mb-3">
                <Label htmlFor="validationCustom03">{"Address Line 1 *"}</Label>
                <Input
                  className="form-control"
                  name="addLine1"
                  type="text"
                  style={{ borderRadius: "0", color: "black" }}
                  value={fromData.addLine1}
                  onChange={(e) => inputChange(e)}
                />
                <p style={{ color: "red" }}>
                  {simpleValidator.current.message(
                    "Address 1",
                    fromData.addLine1,
                    "required"
                  )}
                </p>
              </Col>
              <Col md="6 mb-3">
                <Label htmlFor="validationCustom03">{"Address Line 2"}</Label>
                <Input
                  className="form-control"
                  name="addLine2"
                  type="text"
                  style={{ borderRadius: "0", color: "black" }}
                  value={fromData.addLine2}
                  onChange={(e) => inputChange(e)}
                />
              </Col>
              <Col md="12 mb-3">
                <Label htmlFor="validationCustom03">{"Area"}</Label>
                <Input
                  className="form-control"
                  name="area"
                  type="text"
                  style={{ borderRadius: "0", color: "black" }}
                  value={fromData.area}
                  onChange={(e) => inputChange(e)}
                />
              </Col>
              <Col md="6 mb-3">
                <Label htmlFor="validationCustom03">{"Country"}</Label>
                <Input
                  className="form-control"
                  name="country"
                  type="text"
                  style={{ borderRadius: "0", color: "black" }}
                  value={fromData.country}
                  onChange={(e) => inputChange(e)}
                />
              </Col>
              <Col md="6 mb-3">
                <Label htmlFor="validationCustom03">{"State"}</Label>
                <Input
                  className="form-control"
                  name="state"
                  type="text"
                  style={{ borderRadius: "0", color: "black" }}
                  value={fromData.state}
                  onChange={(e) => inputChange(e)}
                />
              </Col>
              <Col md="6 mb-3">
                <Label htmlFor="validationCustom03">{"City/Dist"}</Label>
                <Input
                  className="form-control"
                  name="city"
                  type="text"
                  style={{ borderRadius: "0", color: "black" }}
                  value={fromData.city}
                  onChange={(e) => inputChange(e)}
                />
              </Col>
              <Col md="6 mb-3">
                <Label htmlFor="validationCustom03">{"Pincode"}</Label>
                <Input
                  className="form-control"
                  name="pinCode"
                  type="number"
                  style={{ borderRadius: "0", color: "black" }}
                  value={fromData.pinCode}
                  onChange={(e) => inputChange(e)}
                  min={0}
                  maxLength={6}
                  onInput={maxLengthCheck}
                />
              </Col>
              <Col md="6 mb-3">
                <Label htmlFor="validationCustom03">{"Latitude"}</Label>
                <Input
                  className="form-control"
                  name="latitude"
                  type="text"
                  style={{ borderRadius: "0", color: "black" }}
                  value={fromData.latitude}
                  onChange={(e) => inputChange(e)}
                />
              </Col>
              <Col md="6 mb-3">
                <Label htmlFor="validationCustom03">{"Longitude"}</Label>
                <Input
                  className="form-control"
                  name="longitude"
                  type="text"
                  style={{ borderRadius: "0", color: "black" }}
                  value={fromData.longitude}
                  onChange={(e) => inputChange(e)}
                />
              </Col>
            </Row>

            <Row>
              <Col md="6 mb-3">
                {addFields.phoneInput.map((elem, i) => {
                  return (
                    <Row className="mb-3" key={elem.id}>
                      <Label htmlFor="validationCustom03">{"Phone *"}</Label>
                      <div className="d-flex phone-dial-code-contactus">
                        {/* <Input
                      className="form-control"
                      name="title"
                      type="number"
                      value={elem.dialCode}
                      style={{ borderRadius: "0", width: "70px" }}
                    /> */}
                        <Select
                          options={options}
                          name="dialCode"
                          value={elem.dialCode}
                          onChange={(prev, e) => SelectDrop(prev, e, i)}
                        />
                        <Input
                          className="form-control"
                          name="phoneValue"
                          type="number"
                          value={elem.phoneValue}
                          style={{ borderRadius: "0", color: "black" }}
                          onChange={(e) =>
                            handleChangePhoneInput(e, i, "phone")
                          }
                          min={0}
                          maxLength={10}
                          onInput={maxLengthCheck}
                        />

                        {addFields.phoneInput.length >= 2 && (
                          <IconButton
                            edge="start"
                            color="inherit"
                            style={{ marginLeft: "5px", color: "red" }}
                            onClick={() => deleteMoreInput(elem.id, "phone")}
                          >
                            <Trash2 />
                          </IconButton>
                        )}
                      </div>
                      <p style={{ color: "red" }}>
                        {simpleValidator.current.message(
                          "Phone",
                          elem.dialCode && elem.phoneValue,
                          "required"
                        )}
                      </p>
                    </Row>
                  );
                })}
                <div className="d-flex justify-content-center ">
                  <Button onClick={() => addMoreInput("phone")} color="primary">
                    + Add More
                  </Button>
                </div>
              </Col>
              <Col md="6 mb-3">
                {addFields.emailInput.map((elem, i) => {
                  return (
                    <Row className="mb-3" key={elem.id}>
                      <Label htmlFor="validationCustom03">{"Email *"}</Label>
                      <div className="d-flex">
                        <Input
                          className="form-control"
                          name="emailValue"
                          type="email"
                          style={{
                            borderRadius: "0",
                            color: "black",
                            height: "48px",
                          }}
                          value={elem.emailValue}
                          onChange={(e) =>
                            handleChangePhoneInput(e, i, "email")
                          }
                        />
                        {addFields.emailInput.length >= 2 && (
                          <IconButton
                            edge="start"
                            color="inherit"
                            style={{
                              marginLeft: "5px",
                              color: "red",
                            }}
                            onClick={() => deleteMoreInput(elem.id, "email")}
                          >
                            <Trash2 />
                          </IconButton>
                        )}
                      </div>
                      <p style={{ color: "red" }}>
                        {simpleValidator.current.message(
                          "Email",
                          elem.emailValue,
                          "required"
                        )}
                      </p>
                    </Row>
                  );
                })}
                <div className="d-flex justify-content-center ">
                  <Button onClick={() => addMoreInput("email")} color="primary">
                    + Add More
                  </Button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="12 mb-4">
                <Editor value={fromData.workingHour} onChange={handleAboutus} />
              </Col>
            </Row>
          </>
        ) : (
          ""
        )}
        <ProductImageUploadPopup />
      </Container>
    </>
  );
};

export default ContactUsPopup;
