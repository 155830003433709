import axios from "axios";
import {
  GET_VIDEOS,
  GET_VIDEOS_DETAILS,
  GET_VIDEOS_ITEM,
  GET_VIDEOS_ITEM_DETAILS,
  POST_VIDEOS,
  POST_VIDEOS_ITEM,
  PUT_STATUS_VIDEOS,
  PUT_STATUS_VIDEOS_ITEM,
  PUT_VIDEOS_ITEM_ORDER,
  PUT_VIDEOS_ORDER,
} from "..";

/**
 * get videos data
 * @param {Parameters} paramObj
 * @returns
 */
export const getVideos = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_VIDEOS}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * enable-disable videos
 * @param {body} body
 * @returns
 */
export const updateStatus = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_STATUS_VIDEOS}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * create videos
 * @param {body} body
 * @returns
 */
export const postVideos = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(`${process.env.REACT_APP_API_URL}${POST_VIDEOS}`, body, {
    headers: { Authorization: jwt_token },
  });
};

/**
 * update videos
 * @param {body} body
 * @returns
 */
export const putUpdateVideos = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(`${process.env.REACT_APP_API_URL}${POST_VIDEOS}`, body, {
    headers: { Authorization: jwt_token },
    params: { videoId: body.videoId },
  });
};

/**
 * delete videos
 * @param {id} videoId
 * @returns
 */
export const deleteVideos = (videoId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${POST_VIDEOS}`, {
    headers: { Authorization: jwt_token },
    params: { videoId },
  });
};

/**
 * get videos details
 * @param {id} videoId
 * @returns
 */
export const getVideosDetails = (videoId,navMapId) => {
  const jwt_token = localStorage.getItem("token");
  let paramObj = {
    videoId,
  };
  if (navMapId) {
    paramObj["navMapId"] = navMapId;
  }
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_VIDEOS_DETAILS}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * short order videos
 * @param {body} updateOrder
 * @returns
 */
export const sort_order_videos = (updateOrder) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_VIDEOS_ORDER}`,
    { updateOrder: updateOrder },
    {
      headers: { Authorization: jwt_token },
    }
  );
};

//videos items apis

/**
 * get videos items data
 * @param {Parameters} paramObj
 * @returns
 */
export const getVideosItems = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_VIDEOS_ITEM}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * enable-disable
 * @param {body} body
 * @returns
 */
export const updateStatusVideosItems = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_STATUS_VIDEOS_ITEM}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * create videos items
 * @param {body} body
 * @returns
 */
export const postVideosItems = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${POST_VIDEOS_ITEM}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * update videos items
 * @param {body} body
 * @returns
 */
export const putVideosItems = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${POST_VIDEOS_ITEM}`,
    body,
    {
      headers: { Authorization: jwt_token },
      params: { videoItemId: body.videoItemId },
    }
  );
};

/**
 * delete videos items
 * @param {id} videoItemId
 * @returns
 */
export const deleteVideosItems = (videoItemId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${POST_VIDEOS_ITEM}`, {
    headers: { Authorization: jwt_token },
    params: { videoItemId },
  });
};

/**
 * get videos items details
 * @param {id} videoItemId
 * @returns
 */
export const getVideosItemsDetails = (videoItemId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${GET_VIDEOS_ITEM_DETAILS}`,
    {
      headers: { Authorization: jwt_token },
      params: { videoItemId },
    }
  );
};

/**
 * short order videos items
 * @param {body} updateOrder
 * @returns
 */
export const sort_order_videos_items = (updateOrder) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_VIDEOS_ITEM_ORDER}`,
    { updateOrder: updateOrder },
    {
      headers: { Authorization: jwt_token },
    }
  );
};
