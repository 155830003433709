import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import {
  Cases,
  DELETE_CONFIRM,
  SinglePage,
  btnName,
  modalsName,
} from "../../../constant";
import EditIcon from "@mui/icons-material/Edit";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import Lightbox from "react-image-lightbox";
import CommonPopup from "../../../Modals/commonPopup";
import VideosPopup from "./videosPopup";
import VideosParentPopup from "./videosPerentPopup";
import {
  deleteVideos,
  getVideos,
  getVideosDetails,
  getVideosItems,
  getVideosItemsDetails,
  postVideos,
  postVideosItems,
  putUpdateVideos,
  putVideosItems,
  updateStatus,
  updateStatusVideosItems,
} from "../../../apis/VideosApi/VideosApi";
import VideosContext from "../../../context/VideosContext/VideosContext";
import { IconButton } from "@material-ui/core";
import { Trash } from "react-feather";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import SimpleReactValidator from "simple-react-validator";
import { thubnailImage } from "../../../../utils/helper";

const Videos = (props) => {
  const { webMasterDetail, navbarData } = props;
  const { setAllPopupState, allPopupState } = useContext(
    ByteCardBuilderContext
  );
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const simpleValidatorVideoParent = useRef(new SimpleReactValidator());
  const [, forceUpdate1] = useState();
  const { setLoaderShow } = useContext(LoaderContext);
  const [open, setOpen] = useState(false);
  const {
    apiData: videoData,
    setApiData,
    fromData,
    setFormData,
    formDataVideos,
    setFormDataVideos,
    id,
    setId,
    setIsEdit,
    isEdit,
    filter,
    apiDataVideos,
    setApiDataVideos,
    videoId,
    setVideoId,
    videoItemId,
    setVideoItemId,
  } = useContext(VideosContext);

  /**
   * get videos items list
   */
  const getVideosItemsList = async (status) => {
    try {
      if (!filter.length && status) {
        setApiData((prev) => {
          return { ...prev, isLoading: true };
        });
      }
      let paramObj = {
        search: { title: filter, videoId: id },
        showAll: true,
      };
      const { data } = await getVideosItems(paramObj);
      if (data.status === 200) {
        setApiData({
          ...videoData,
          isLoading: false,
          data: data.payload.data.map((elem) => {
            return {
              id: elem?.title + elem?.videoItemId,
              Id: elem?.videoItemId,
              videoId: elem?.videoId,
              name: elem?.title,
              createdAt: elem?.createdAt,
              isActive: elem?.isActive,
            };
          }),
        });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };
  /**
   * get Videos list
   * @param {status} status
   */
  const getVideosList = async (status) => {
    try {
      if (!filter.length && status) {
        setApiDataVideos((prev) => {
          return { ...prev, isLoading: true };
        });
      }

      let paramObj = {
        search: { title: filter },
      };
      const { data } = await getVideos(paramObj);
      if (data.status === 200) {
        setApiDataVideos({
          ...apiDataVideos,
          isLoading: false,
          data: data.payload.data,
        });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  useMemo(() => {
    if (allPopupState?.videosPopup) {
      getVideosItemsList();
    }
  }, [filter, allPopupState?.videosPopup]);

  useEffect(() => {
    getVideosList();
  }, []);

  /**
   * videos enable disable api call
   * @param {video id} videoId
   * @param {status} isActive
   */

  const handleChangeVideoStatus = async (videoId, isActive) => {
    try {
      let body = {
        videoId,
        isActive: isActive ? false : true,
        navMapId: navbarData[0].navMapId,
      };
      const { data } = await updateStatus(body);
      if (data.status === 200) {
        getVideosList(false);
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };
  /**
   * videos items enable disable api call
   * @param {videoItem id} videoItemId
   * @param {status} isActive
   */

  const handleChangeVideosItemsStatus = async (videoItemId, isActive) => {
    try {
      let body = {
        videoItemId,
        isActive: isActive ? false : true,
        navMapId: navbarData[0].navMapId,
      };
      const { data } = await updateStatusVideosItems(body);
      if (data.status === 200) {
        getVideosItemsList();
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * create and update Videos
   */
  const handleSubmit = async () => {
    const formValid = simpleValidatorVideoParent.current.allValid();
    if (!formValid) {
      simpleValidatorVideoParent.current.showMessages();
      forceUpdate1(1);
    } else {
      if (videoId >= -1 && isEdit) {
        setLoaderShow(true);
        let newFormData = {
          ...formDataVideos,
          navMapId: navbarData[0].navMapId,
        };

        try {
          const { data } = await putUpdateVideos(newFormData);
          if (data.status === 200) {
            getVideosList();
            OpenModalCommonFunc(Cases.VIDEOS_PARENT, setAllPopupState, false);
            TostMessage({ message: data, type: "success" });
            emptyData();
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      } else {
        try {
          setLoaderShow(true);
          let newFormData = {
            ...formDataVideos,
            navMapId: navbarData[0].navMapId,
          };
          const { data } = await postVideos(newFormData);
          if (data.status === 200) {
            getVideosList();
            OpenModalCommonFunc(Cases.VIDEOS_PARENT, setAllPopupState, false);
            TostMessage({ message: data, type: "success" });
            emptyData();
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      }
    }
  };

  /**
   * create and update videos items
   */
  const handleSubmitVideosItems = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      if (videoItemId >= -1 && isEdit) {
        setLoaderShow(true);
        let newFormData = {
          ...fromData,
          navMapId: navbarData[0].navMapId,
        };
        try {
          const { data } = await putVideosItems(newFormData);
          if (data.status === 200) {
            getVideosItemsList();
            emptyData();
            TostMessage({ message: data, type: "success" });
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      } else {
        setLoaderShow(true);
        let newObj = {
          ...fromData,
          videoId: id,
          navMapId: navbarData[0].navMapId,
          order: videoData.data.length + 1,
        };
        try {
          const { data } = await postVideosItems(newObj);
          if (data.status === 200) {
            getVideosItemsList();
            emptyData();
            TostMessage({ message: data, type: "success" });
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      }
    }
  };

  /**
   * get videos details
   * @param {id} videoId
   */
  const handleEdit = async (videoId) => {
    try {
      setLoaderShow(true);
      setVideoId(videoId);
      const { data } = await getVideosDetails(videoId);
      if (data.status === 200) {
        setIsEdit(true);
        let payload = data.payload.data;
        setFormDataVideos({
          title: payload.title,
          description: payload.description,
          isYoutube: payload.isYoutube,
          isAlbum: payload.isAlbum,
          videoId: payload.videoId,
          webId: localStorage.getItem("webId"),
        });
        OpenModalCommonFunc(Cases.VIDEOS_PARENT, setAllPopupState);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * get videos items
   * @param {id} videoItemId
   */
  const handleEditOurTeam = async (videoItemId) => {
    try {
      setLoaderShow(true);
      setVideoItemId(videoItemId);
      const { data } = await getVideosItemsDetails(videoItemId);
      if (data.status === 200) {
        let payload = data.payload.data;
        setFormData({
          title: payload.title,
          url: payload.url,
          videoId: payload.videoId,
          videoItemId: videoItemId,
          webId: localStorage.getItem("webId"),
        });
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * delete videos
   * @param {id} videoId
   */
  const handleDelete = async (videoId) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await deleteVideos(videoId);
        if (data.status === 200) {
          getVideosList();
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * clear data
   */
  const emptyData = () => {
    setIsEdit(false);
    setFormData({
      title: "",
      url: "",
      videoId: "",
      webId: localStorage.getItem("webId"),
    });
    setVideoId("");
    setFormDataVideos({
      title: "",
      description: "",
      isYoutube: true,
      isAlbum: false,
      webId: localStorage.getItem("webId"),
    });
    setVideoItemId("");
    setLoaderShow(false);
  };

  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4">
          <CardBody className="support-ticket-font box-border-div">
            <Row>
              <CardBuilderHeaderSection
                name={`${apiDataVideos?.data?.length} Videos`}
                isAdd={
                  webMasterDetail?.webCategory?.name ===
                    SinglePage?.WebCategory &&
                  apiDataVideos?.data?.length !== SinglePage?.Video_Parent_Limit
                    ? true
                    : false
                }
                addBtnTitle={"Video Section"}
                openModal={() =>
                  OpenModalCommonFunc(Cases.VIDEOS_PARENT, setAllPopupState)
                }
              />
              {apiDataVideos?.data?.map((elem) => {
                return (
                  <Col xl="3 xl-50 box-col-6" md="6" key={elem.videoId}>
                    <Card className="ecommerce-widget cardHover">
                      <CardBody className="support-ticket-font">
                        <div className="col-12">
                          <CardBuilderHeaderSection
                            name={elem?.title}
                            checked={elem?.isActive}
                            switchToggle={true}
                            id={elem?.videoId}
                            handleChange={handleChangeVideoStatus}
                            openModal={() =>
                              OpenModalCommonFunc(
                                Cases.VIDEOS_PARENT,
                                setAllPopupState
                              )
                            }
                          />
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => setOpen(!open)}
                          >
                            <div
                              style={{
                                objectFit: "contain",
                                maxWidth: "125px",
                                height: "83px",
                                border: "1px solid #d3d3d3",
                                width: "100%",
                                display: "inline-block",
                                verticalAlign: "middle",
                                overflow: "hidden",
                                position: "relative",
                              }}
                            >
                              <div
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  right: "0",
                                  bottom: "0",
                                  left: "0",
                                  borderRadius: "inherit",
                                }}
                              >
                                <img
                                  src={
                                    elem?.defaultVideo?.url &&
                                    `https://img.youtube.com/vi/${thubnailImage(
                                      elem?.defaultVideo?.url
                                    )}/hqdefault.jpg`
                                  }
                                  alt=""
                                  style={{
                                    objectPosition: "50% 50%",
                                    objectFit: "contain",
                                    opacity: "1",
                                    transition: "opacity 0.28s ease-in",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <OpenPopupBtn
                          openPopup={() => {
                            OpenModalCommonFunc(Cases.VIDEOS, setAllPopupState);
                            setId(elem.videoId);
                          }}
                          name={btnName.VIDEOS}
                        />
                        <div className="d-flex justify-content-end ">
                          <IconButton
                            edge="start"
                            color="inherit"
                            style={{ marginLeft: "5px" }}
                            onClick={() => handleEdit(elem.videoId)}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            edge="start"
                            color="inherit"
                            style={{ marginLeft: "5px" }}
                            onClick={() => handleDelete(elem.videoId)}
                          >
                            <Trash />
                          </IconButton>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </CardBody>
        </Card>
      </Col>

      {open && (
        <Lightbox
          mainSrc={"https://img.youtube.com/vi/wmNyMgrsBLs/hqdefault.jpg"}
          onCloseRequest={() => setOpen(false)}
        />
      )}

      {/* videos items */}
      <CommonPopup
        open={allPopupState.videosPopup}
        close={() => OpenModalCommonFunc(Cases.VIDEOS, setAllPopupState, false)}
        modalName={modalsName.VIDEOS}
        modalCase={Cases.VIDEOS}
        handleSubmit={handleSubmitVideosItems}
        emptyData={emptyData}
        inputData={fromData}
        edit={false}
        content={
          <VideosPopup
            handleChangeStatus={handleChangeVideosItemsStatus}
            getData={getVideosItemsList}
            handleEdit={handleEditOurTeam}
            emptyData={emptyData}
            simpleValidator={simpleValidator}
            webMasterDetail={webMasterDetail}
          />
        }
      />
      {/* videos Parent */}
      <CommonPopup
        open={allPopupState.videosParentPopup}
        close={() =>
          OpenModalCommonFunc(Cases.VIDEOS_PARENT, setAllPopupState, false)
        }
        modalName={modalsName.VIDEOS_PARENT}
        modalCase={Cases.VIDEOS_PARENT}
        handleSubmit={handleSubmit}
        emptyData={emptyData}
        inputData={formDataVideos}
        edit={false}
        content={
          <VideosParentPopup simpleValidator={simpleValidatorVideoParent} />
        }
      />
    </>
  );
};

export default Videos;
