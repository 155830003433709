import React, { useState } from "react";
import ProductContext from "./ProductContext";

const ProductState = (props) => {
  const [apiData, setApiData] = useState({ data: [], isLoading: false });

  const [fromData, setFormData] = useState({
    title: "",
    description: "",
    price: "",
    image: "",
    currencySymbol: "",
    webId: localStorage.getItem("webId"),
  });

  const [images, setImages] = useState([
    { path: "", isDefault: true },
    { path: "", isDefault: false },
    { path: "", isDefault: false },
    { path: "", isDefault: false },
  ]);
  const [index, setIndex] = useState("");
  const [id, setId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [filter, setFilter] = useState("");
  return (
    <ProductContext.Provider
      value={{
        apiData,
        setApiData,
        fromData,
        setFormData,
        images,
        setImages,
        id,
        setId,
        isEdit,
        setIsEdit,
        filter,
        setFilter,
        index,
        setIndex,
      }}
    >
      {props.children}
    </ProductContext.Provider>
  );
};

export default ProductState;
