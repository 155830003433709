import React, { useState } from "react";
import ServiceCounterContext from "./ServiceCounterContext";
import { randomStr } from "../../../utils/helper";

const ServiceCounterState = (props) => {
  const [apiData, setApiData] = useState({ data: [], isLoading: false });
  const [apiDataOurTeam, setApiDataOurTeam] = useState({
    isLoading: false,
    data: [],
  });
  const [skill, setSkill] = useState([
    { id: randomStr(2), skill: "", parentageOfSkill: "" },
  ]);
  const [isBackgroundColor, setIsBackgroundColor] = useState(false);
  const [boxSection, setBoxSection] = useState(false);
  const [backImageToggle, setBackImageToggle] = useState(false);
  const [collId, setCollId] = useState();
  const [fromData, setFormData] = useState({
    title: "",
    subTitle: "",
    navMapId: "",
    webId: localStorage.getItem("webId"),
    theme: 1,
    isTitleShow: true,
    style: {
      ".counter-section": {
        padding: "100px 0",
        "background-color": "rgb(0 0 0 / 100)",
      },
      ".counter-section.bg-image": {
        "background-image": "",
      },
      ".counter-section.bg-image::before": {
        "background-color": "",
      },
      ".counter-section .section-title-area": {
        "margin-bottom": "50px",
      },
      ".counter-section .section-title-area .section-subtitle": {
        "font-size": "16px",
        "font-weight": "600",
        color: "rgb(255 73 124 / 100)",
        "background-color": "rgb(255 73 124 / 0.2)",
        padding: "5px 20px",
        p1: "5px",
        p2: "20px",
        "border-radius": "15px",
        "margin-bottom": "15px",
      },
      ".counter-section .section-title-area .section-title": {
        "font-size": "44px",
        "font-weight": "600",
        color: "rgb(255 255 255 / 100)",
      },
      ".counter-section .countdiv": {
        "text-align": "center",
        "margin-bottom": "30px",
      },
      ".counter-section .countdiv .counter": {
        color: "rgb(255 73 124 / 100)",
        "font-size": "40px",
        "font-weight": "600",
      },
      ".counter-section .countdiv .divider": {
        width: "80px",
        height: "4px",
        margin: "15px auto",
        "border-radius": "3px",
        "background-color": "rgb(255 255 255 / 100)",
      },
      ".counter-section .countdiv .counter-title": {
        color: "rgb(255 255 255 / 100)",
        "font-size": "22px",
        "font-weight": "500",
      },
      serviceCounterId: "",
    },
  });
  const [theme, setTheme] = useState("");
  const [formDataChild, setFormDataChild] = useState({
    title: "",
    subTitle: "",
    value: "",
    onlyValue: "",
    image: "",
    order: 1,
    isTitleShow: true,
    webId: localStorage.getItem("webId"),
    navMapId: "",
  });

  const [settingFormData, setSettingFormData] = useState({
    ".counter-section": {
      padding: "100px 0",
      "background-color": "rgb(0 0 0 / 100)",
    },
    ".counter-section.bg-image": {
      "background-image": "",
    },
    ".counter-section.bg-image::before": {
      "background-color": "",
    },
    ".counter-section .section-title-area": {
      "margin-bottom": "50px",
    },
    ".counter-section .section-title-area .section-subtitle": {
      "font-size": "16px",
      "font-weight": "600",
      color: "rgb(255 73 124 / 100)",
      "background-color": "rgb(255 73 124 / 0.2)",
      padding: "5px 20px",
      p1: "5px",
      p2: "20px",
      "border-radius": "15px",
      "margin-bottom": "15px",
    },
    ".counter-section .section-title-area .section-title": {
      "font-size": "44px",
      "font-weight": "600",
      color: "rgb(255 255 255 / 100)",
    },
    ".counter-section .countdiv": {
      "text-align": "center",
      "margin-bottom": "30px",
    },
    ".counter-section .countdiv .counter": {
      color: "rgb(255 73 124 / 100)",
      "font-size": "40px",
      "font-weight": "600",
    },
    ".counter-section .countdiv .divider": {
      width: "80px",
      height: "4px",
      margin: "15px auto",
      "border-radius": "3px",
      "background-color": "rgb(255 255 255 / 100)",
    },
    ".counter-section .countdiv .counter-title": {
      color: "rgb(255 255 255 / 100)",
      "font-size": "22px",
      "font-weight": "500",
    },
    serviceCounterId: "",
  });

  const [id, setId] = useState("");
  const [childId, setChildId] = useState("");
  const [navMapId, setNavMapId] = useState("");
  const [galleryImgId, setGalleryImgId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isParent, setIsParent] = useState(false);
  const [filter, setFilter] = useState("");
  const [imageIs, setImageIs] = useState("");
  const [imgShow, setImgShow] = useState(false);
  const [sectionPageName, setSectionPageName] = useState("");
  return (
    <ServiceCounterContext.Provider
      value={{
        apiData,
        setApiData,
        apiDataOurTeam,
        setApiDataOurTeam,
        fromData,
        setFormData,
        id,
        setId,
        isEdit,
        setIsEdit,
        filter,
        setFilter,
        skill,
        galleryImgId,
        setGalleryImgId,
        setSkill,
        settingFormData,
        setSettingFormData,
        formDataChild,
        setFormDataChild,
        isParent,
        setIsParent,
        childId,
        setChildId,
        navMapId,
        setNavMapId,
        collId,
        setCollId,
        boxSection,
        setBoxSection,
        backImageToggle,
        setBackImageToggle,
        isBackgroundColor,
        setIsBackgroundColor,
        theme,
        setTheme,
        imageIs,
        setImageIs,
        imgShow,
        setImgShow,
        sectionPageName,
        setSectionPageName,
      }}
    >
      {props.children}
    </ServiceCounterContext.Provider>
  );
};

export default ServiceCounterState;
