import React, { useContext, useEffect, useState } from "react";
import ByteCardBuilderContext from "../context/BytecardBilderContext/ByteCardBuilderContext";
import { Dialog, IconButton, Tooltip } from "@mui/material";
import { Cases, modalsName } from "../constant";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import { Zoom, toast } from "react-toastify";
import {
  Check_Sub_Domain,
  Update_Domain_Name,
} from "../apis/dashboardAPi/DeshboardApi";
import { Check } from "react-feather";
import {
  getDomainWithoutSubdomain,
  isValidDomain,
  maxLengthCheck,
} from "../../utils/helper";
import { OpenModalCommonFunc, TostMessage } from "../utils";
import {
  Check_Multi_Sub_Domain,
  Update_Multi_Domain_Name,
} from "../components/MultipageByteCardBuilder/apis/DomainChangeApi/DomainChangeApi";
import { Close } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router";
import { classes } from "../../data/layouts";

const SetSubDomainPopup = ({
  ByteMiniWeb_Dashboard,
  subDomainData,
  isMulti,
}) => {
  const { allPopupState, setAllPopupState, domain, setDomain } = useContext(
    ByteCardBuilderContext
  );
  const [isBusinessDomain, setIsBusinessDomain] = useState(false);
  let { hwId } = useParams();
  const history = useNavigate();
  const [domainDetails, setDomainDetails] = useState(null);
  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  useEffect(() => {
    if (isMulti) {
      const [domainDetails] = subDomainData?.data?.filter(
        (elem) => elem.webCategory
      );
      setDomainDetails(domainDetails);
    }
  }, [subDomainData]);

  const handleCheckSubDomain = async () => {
    if (domain.length) {
      try {
        if (isMulti) {
          let paramObj = {
            domain:
              domain + `.${getDomainWithoutSubdomain(domainDetails?.domain)}`,
          };
          await Check_Multi_Sub_Domain(paramObj);
        } else {
          let paramObj = {
            domain:
              domain + `.${getDomainWithoutSubdomain(subDomainData?.domain)}`,
          };
          await Check_Sub_Domain(paramObj);
        }
      } catch (error) {
        toast.error(error.response.data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    }
  };

  const emptyData = () => {
    setDomain("");
  };

  const handleSubmit = async () => {
    try {
      debugger;
      let paramObj = {};

      if (isMulti) {
        paramObj = {
          domain: `${getDomainWithoutSubdomain(domainDetails?.domain)}`,
          subDomain: domain,
        };
        if (isBusinessDomain) {
          paramObj["isBusinessDomain"] = true;
          paramObj["domain"] = domain;
          delete paramObj.subDomain;
        } else {
          paramObj["subDomain"] = domain;
        }
      } else {
        paramObj = {
          domain: `${getDomainWithoutSubdomain(subDomainData?.domain)}`,
          subDomain: domain,
        };
      }

      if (!isValidDomain(domain) && isBusinessDomain) {
        TostMessage({
          message: "Please enter your perfect domain.",
          type: "error",
          position: "custome",
        });
        throw new Error("Please enter your perfect domain.");
      }
      const { data } = await changeSingleOrMultiDomain({ paramObj });
      if (data.status === 200) {
        TostMessage({ message: data, type: "success" });
        setAllPopupState((prev) => {
          return {
            ...prev,
            subDomainPopup: false,
          };
        });
        ByteMiniWeb_Dashboard();
        emptyData();
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  const changeSingleOrMultiDomain = async ({ paramObj }) => {
    if (isMulti) {
      const { data } = await Update_Multi_Domain_Name(paramObj);
      return { data };
    } else {
      const { data } = await Update_Domain_Name(paramObj);
      return { data };
    }
  };

  const cancelDomainChange = () => {
    OpenModalCommonFunc(Cases.SUB_DOMAIN, setAllPopupState, false);
    history(`/${hwId}/dashboard/ecommerce/${layout}`);
  };

  return (
    <Dialog fullScreen open={allPopupState?.subDomainPopup}>
      <Container>
        <div
          className="d-flex justify-content-between pt-4 align-items-center position-sticky top-0"
          style={{
            zIndex: "999",
            background: "white",
            paddingBottom: "15px",
            paddingRight: "20px",
            paddingLeft: "20px",
          }}
        >
          <div>
            <h3>{<>{modalsName.SET_SUB_DOMAIN}</>}</h3>
          </div>
          <div className="d-flex gap-4 ">
            <Tooltip title="Submit">
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => cancelDomainChange()}
                aria-label="close"
                style={{ background: "red", color: "white" }}
              >
                <Close />
              </IconButton>
            </Tooltip>
            <Tooltip title="Submit">
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => handleSubmit()}
                aria-label="close"
                style={{ background: "#58b958", color: "white" }}
              >
                <Check />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <Card>
          <CardBody>
            <Form>
              {isMulti && (
                <>
                  <input
                    type="checkbox"
                    id="set_domain"
                    onChange={(e) => setIsBusinessDomain(e.target.checked)}
                    checked={isBusinessDomain}
                  />{" "}
                  <label htmlFor="set_domain">Set business domain</label>
                </>
              )}
              <Row>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">
                    {isBusinessDomain
                      ? "Business Domain Name *"
                      : "Sub Domain Name *"}
                  </Label>
                  <InputGroup className="mt-3">
                    <InputGroupText>{`https://`}</InputGroupText>
                    <Input
                      className="form-control"
                      type="text"
                      value={domain}
                      onChange={(e) =>
                        setDomain(
                          isBusinessDomain
                            ? e.target.value
                                ?.toLocaleLowerCase()
                                ?.replace(/^https?:\/\//, "")
                                .replace(/^www\./, "")
                            : e.target.value
                                ?.toLocaleLowerCase()
                                ?.replace(/[^a-zA-Z0-9]/g, "")
                                ?.replace(/^https?:\/\//, "")
                        )
                      }
                      min={0}
                      maxLength={isBusinessDomain ? 256 : 30}
                      onInput={maxLengthCheck}
                      onBlur={() => handleCheckSubDomain()}
                    />
                    {!isBusinessDomain && (
                      <InputGroupText>{`.${
                        isMulti
                          ? domainDetails?.domain &&
                            getDomainWithoutSubdomain(domainDetails?.domain)
                          : subDomainData?.domain &&
                            getDomainWithoutSubdomain(subDomainData?.domain)
                      }`}</InputGroupText>
                    )}
                  </InputGroup>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </Dialog>
  );
};

export default SetSubDomainPopup;
