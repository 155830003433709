import React, { useContext } from "react";
import { Col, Input, Label, Row } from "reactstrap";
import { fontWeight } from "../../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import { Cases, DELETE_CONFIRM } from "../../../constant";
import { delete_Multi_Image_Permanent } from "../apis/SliderApi/SliderApi";
import { Zoom, toast } from "react-toastify";
import Dividers from "../../../common/divider";
import { UploadCloud } from "react-feather";
import { OpenModalCommonFunc } from "../../../utils";
import { IconButton } from "@mui/material";
import { Close } from "@material-ui/icons";
import { Switch } from "@material-ui/core";
import PageTitleContext from "../../../context/pageTitleContext/PageTitleContext";
import CommonColor from "../../../common/CommonColor";
import CustomStyle from "../../../common/CustomStyle";

const PageTitleThemeOne = (props) => {
  const {
    isEdit,
    settingFormData,
    setSettingFormData,
    backImageToggle,
    setBackImageToggle,
    setBoxSection,
    isBackgroundColor,
    setIsBackgroundColor,
  } = useContext(PageTitleContext);
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const { imageUpload } = props;
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * input value change
   * @param {event} e
   */

  const handleChangePageHeadSection = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".page-head"]: {
          ...prev[".page-head"],
          [name]: value,
        },
      };
    });
  };

  const handleChangePageTitleSectionBgImage = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".page-head.bg-image::before"]: {
          ...prev[".page-head.bg-image::before"],
          [name]: value,
        },
      };
    });
  };

  const handleChangePageHeadTitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".page-head .page-title"]: {
          ...prev[".page-head .page-title"],
          [name]: value,
        },
      };
    });
  };
  const handleChangePageHeadBredCrumbItemA = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".page-head .breadcrumb .breadcrumb-item a"]: {
          ...prev[".page-head .breadcrumb .breadcrumb-item a"],
          [name]: value,
        },
      };
    });
  };
  const handleChangePageHeadBredCrumbItemActive = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".page-head .breadcrumb .breadcrumb-item.active"]: {
          ...prev[".page-head .breadcrumb .breadcrumb-item.active"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeCustomStyle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const resetHandler = () => {
    setSettingFormData({
      ".page-head": {
        padding: "100px 0",
        "clip-path": "polygon(0 0, 100% 0%, 100% 80%, 50% 100%, 0 80%)",
        cp: "100%",
        cp11: "100%",
        cp12: "80%,",
        cp21: "50%",
        cp22: "100%,",
        cp31: "80%)",
        "background-color": { r: 0, g: 0, b: 0, a: 100 },
      },
      ".page-head.bg-image": {},
      ".page-head.bg-image::before": {
        // "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
      },
      ".page-head .page-title": {
        color: { r: 255, g: 255, b: 255, a: 100 },
        "font-size": "40px",
        "font-weight": 500,
        "margin-bottom": "20px",
      },
      ".page-head .breadcrumb .breadcrumb-item a": {
        "font-size": "15px",
        "font-weight": 600,
        color: { r: 255, g: 73, b: 124, a: 100 },
      },
      ".page-head .breadcrumb .breadcrumb-item.active": {
        "font-size": "15px",
        "font-weight": 600,
        color: { r: 255, g: 255, b: 255, a: 100 },
      },
      custom: "",
      pageTitleId: "",
    });
    setBackImageToggle(false);
    setBoxSection(false);
    setIsBackgroundColor(true);
  };

  /**
   * delete image permanently
   */
  const deleteImagePermanent = async () => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData = {
          fileName: settingFormData?.[".page-head.bg-image"]?.[
            "background-image"
          ]
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
            .pop(),
        };
        const { data } = await delete_Multi_Image_Permanent(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            position: "top-center",
          });
          setSettingFormData({
            ...settingFormData,
            ".page-head.bg-image": { "background-image": "" },
          });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };

  /**
   * image reupload
   */
  const reupload = () => {
    setSettingFormData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };

  return (
    <>
      <Row className="mt-4">
        <h4 className="mb-3">Change style in page head</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"padding (Default : 100)"}
          </Label>
          <Input
            className="form-control"
            name="padding"
            type="number"
            placeholder="padding"
            value={settingFormData?.[".page-head"]?.["padding"]
              ?.split(" ")[0]
              ?.replace(/px/g, "")}
            onChange={(e) => handleChangePageHeadSection(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"clip-path (Default : 100 - 100 - 80 - 50 - 100 - 80)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="cp"
              type="number"
              placeholder="clip-path"
              value={settingFormData?.[".page-head"]?.["cp"]?.replace(/%/g, "")}
              onChange={(e) => handleChangePageHeadSection(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="cp11"
              type="number"
              placeholder="clip-path"
              value={settingFormData?.[".page-head"]?.["cp11"]?.replace(
                /%/g,
                ""
              )}
              onChange={(e) => handleChangePageHeadSection(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="cp12"
              type="number"
              placeholder="clip-path"
              value={settingFormData?.[".page-head"]?.["cp12"]?.replace(
                /%,/g,
                ""
              )}
              onChange={(e) => handleChangePageHeadSection(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="cp21"
              type="number"
              placeholder="clip-path"
              value={settingFormData?.[".page-head"]?.["cp21"]?.replace(
                /%/g,
                ""
              )}
              onChange={(e) => handleChangePageHeadSection(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="cp22"
              type="number"
              placeholder="clip-path"
              value={settingFormData?.[".page-head"]?.["cp22"]?.replace(
                /%,/g,
                ""
              )}
              onChange={(e) => handleChangePageHeadSection(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="cp31"
              type="number"
              placeholder="clip-path"
              value={settingFormData?.[".page-head"]?.["cp31"]?.replace(
                "%)",
                ""
              )}
              onChange={(e) => handleChangePageHeadSection(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
        <Col md="4 mb-3">
          <div className="d-flex align-items-center">
            <Label htmlFor="validationCustom03">
              {isBackgroundColor
                ? "Add Background Color"
                : "Remove Background Color"}
            </Label>
            <Switch
              checked={isBackgroundColor}
              inputProps={{ "aria-label": "controlled" }}
              onChange={(e) => {
                setIsBackgroundColor(e.target.checked);
                setSettingFormData((prev) => {
                  if (e.target.checked) {
                    return {
                      ...prev,
                      [".page-head"]: {
                        "background-color": "#000000",
                        padding: settingFormData?.[".page-head"]?.["padding"],
                        cp: settingFormData?.[".page-head"]?.["cp"]?.replace(
                          /%/g,
                          ""
                        ),
                        cp11: settingFormData?.[".page-head"]?.[
                          "cp11"
                        ]?.replace(/%/g, ""),
                        cp12: settingFormData?.[".page-head"]?.[
                          "cp12"
                        ]?.replace(/%,/g, ""),
                        cp21: settingFormData?.[".page-head"]?.[
                          "cp21"
                        ]?.replace(/%/g, ""),
                        cp22: settingFormData?.[".page-head"]?.[
                          "cp22"
                        ]?.replace(/%,/g, ""),
                        cp31: settingFormData?.[".page-head"]?.[
                          "cp31"
                        ]?.replace("%)", ""),
                      },
                    };
                  } else {
                    return {
                      ...prev,
                      [".page-head"]: {
                        padding: settingFormData?.[".page-head"]?.["padding"],
                        cp: settingFormData?.[".page-head"]?.["cp"]?.replace(
                          /%/g,
                          ""
                        ),
                        cp11: settingFormData?.[".page-head"]?.[
                          "cp11"
                        ]?.replace(/%/g, ""),
                        cp12: settingFormData?.[".page-head"]?.[
                          "cp12"
                        ]?.replace(/%,/g, ""),
                        cp21: settingFormData?.[".page-head"]?.[
                          "cp21"
                        ]?.replace(/%/g, ""),
                        cp22: settingFormData?.[".page-head"]?.[
                          "cp22"
                        ]?.replace(/%,/g, ""),
                        cp31: settingFormData?.[".page-head"]?.[
                          "cp31"
                        ]?.replace("%)", ""),
                      },
                    };
                  }
                });
              }}
            />
          </div>
          {isBackgroundColor && (
            <CommonColor
              color={settingFormData?.[".page-head"]?.["background-color"]}
              setSettingFormData={setSettingFormData}
              className={".page-head"}
              classProperty={"background-color"}
              label={"Select Background Color"}
            />
          )}
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <Col md="12 mb-3">
          <h4 className="mb-3">Add Background image in page head</h4>

          <p>do you want to upload image</p>
          <Switch
            checked={backImageToggle}
            inputProps={{ "aria-label": "controlled" }}
            onChange={(e) => {
              setBackImageToggle(e.target.checked);
              setSettingFormData((prev) => {
                if (e.target.checked) {
                  return {
                    ...prev,
                    image: "",
                    ".page-head.bg-image": {},
                    ".page-head.bg-image::before": {
                      "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
                    },
                  };
                } else {
                  return {
                    ...prev,
                    image: "",
                    ".page-head.bg-image": {},
                    ".page-head.bg-image::before": {},
                  };
                }
              });
            }}
          />
          {backImageToggle && (
            <>
              {" "}
              <Col md="6 mb-3">
                <Dividers divide="IMAGE" />
                <p className="mb-4">
                  File type required JPG, PNG (1905px X 303px)
                </p>
              </Col>
              <Row>
                <Col md="4 mb-3">
                  <div
                    className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    {!settingFormData?.[".page-head.bg-image"]?.[
                      "background-image"
                    ] && (
                      <span
                        onClick={() =>
                          OpenModalCommonFunc(
                            Cases.PRODUCT_UPLOAD,
                            setAllPopupState
                          )
                        }
                      >
                        <UploadCloud />
                      </span>
                    )}

                    {settingFormData?.[".page-head.bg-image"]?.[
                      "background-image"
                    ] && (
                      <img
                        src={
                          settingFormData?.[".page-head.bg-image"]?.[
                            "background-image"
                          ] || ""
                        }
                        alt=""
                        style={{
                          width: "85%",
                          height: "90px",
                          objectFit: "contain",
                          position: "absolute",
                        }}
                        onClick={() => deleteImagePermanent()}
                      />
                    )}
                    {settingFormData?.[".page-head.bg-image"]?.[
                      "background-image"
                    ] && (
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="close"
                        style={{
                          color: "red",
                          position: "absolute",
                          top: "-22px",
                          left: "1px",
                        }}
                        onClick={() => deleteImagePermanent()}
                      >
                        <Close
                          style={{
                            fontSize: "25px",
                            fontWeight: "bold",
                          }}
                        />
                      </IconButton>
                    )}
                  </div>
                </Col>
                <Col md="6 mb-3">
                  <h4 className="mb-3">page-head.bg-image::before</h4>
                  <Col md="6 mb-3">
                    <CommonColor
                      color={
                        settingFormData?.[".page-head.bg-image::before"]?.[
                          "background-color"
                        ]
                      }
                      setSettingFormData={setSettingFormData}
                      className={".page-head.bg-image::before"}
                      classProperty={"background-color"}
                      label={"Select Background Color"}
                    />
                    {/* <Input
                              className="form-control"
                              name="background-color"
                              type="color"
                              placeholder="Background Color"
                              value={
                                settingFormData?.[
                                  ".page-head.bg-image::before"
                                ]?.["background-color"]
                              }
                              onChange={(e) =>
                                handleChangePageTitleSectionBgImage(e)
                              }
                              style={{ borderRadius: "0", color: "black" }}
                            /> */}
                  </Col>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in page head title</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={settingFormData?.[".page-head .page-title"]?.["color"]}
            setSettingFormData={setSettingFormData}
            className={".page-head .page-title"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 40)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[".page-head .page-title"]?.[
              "font-size"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangePageHeadTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 500)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={settingFormData?.[".page-head .page-title"]?.["font-weight"]}
            onChange={(e) => handleChangePageHeadTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>

        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin Bottom (Default : 20)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin Bottom"
            value={settingFormData?.[".page-head .page-title"]?.[
              "margin-bottom"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangePageHeadTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in page head breadcrumb item</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".page-head .breadcrumb .breadcrumb-item a"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangePageHeadBredCrumbItemA(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[".page-head .breadcrumb .breadcrumb-item a"]?.[
                "font-weight"
              ]
            }
            onChange={(e) => handleChangePageHeadBredCrumbItemA(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".page-head .breadcrumb .breadcrumb-item a"]?.[
                "color"
              ]
            }
            setSettingFormData={setSettingFormData}
            className={".page-head .breadcrumb .breadcrumb-item a"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">
          Make change in page head breadcrumb item on active
        </h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".page-head .breadcrumb .breadcrumb-item.active"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangePageHeadBredCrumbItemActive(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[
                ".page-head .breadcrumb .breadcrumb-item.active"
              ]?.["font-weight"]
            }
            onChange={(e) => handleChangePageHeadBredCrumbItemActive(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".page-head .breadcrumb .breadcrumb-item.active"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".page-head .breadcrumb .breadcrumb-item.active"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
      </Row>
      <CustomStyle
        handleChange={handleChangeCustomStyle}
        value={settingFormData?.custom}
      />

      <ProductImageUploadPopup
        allPopupState={allPopupState}
        setAllPopupState={setAllPopupState}
        crop={crop}
        setCrop={setCrop}
        completedCrop={completedCrop}
        setCompletedCrop={setCompletedCrop}
        scale={scale}
        rotate={rotate}
        image={settingFormData?.image}
        setImage={setSettingFormData}
        imageUpload={imageUpload}
        isEdit={isEdit}
        reupload={reupload}
        // aspect={6.29 / 1}
      />
    </>
  );
};

export default PageTitleThemeOne;
