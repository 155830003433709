import { IconButton, Tooltip } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Card, CardHeader, Col, Container, Row } from "reactstrap";
import { MOMENT_SETTING, SORTING_ORDER } from "../../constant";
import DataNotFound from "../../DataNoteFound/DataNotFound";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import { TabelAnimation } from "../../Spinner";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { MuiTabelStyle } from "../../utils/MuiTabelStyle";
import moment from "moment-timezone";
import { randomStr, tableId } from "../../utils/helper";
import { ArrowLeft, ArrowRight, Plus, Trash2 } from "react-feather";
import { Zoom, toast } from "react-toastify";
import { DataGridPro } from "@mui/x-data-grid-pro";
import SettingMultiWebContext from "../../context/SettingMultiWebContext/SettingMultiWebContext";
import {
  DetailWebNavBarMap,
  WebNavBarConfigListDelete,
  WebNavBarMapListDelete,
  statusChangeNavMap,
} from "../../components ByteCard/components/MultipageByteCardBuilder/apis/NavApi/NavApi";
import Switch from "react-js-switch";

const SettingMultiWebItemWebNavBarMap = (props) => {
  const {
    pageNumberNav,
    pageSizeNav,
    sortOrderNav,
    setPageNumberNav,
    setPageSizeNav,
    setSortOrderNav,
    setSortByNav,
    formvalueNav,
    recordNav,
    setModalNav,
    setToggleSubmitNav,
    setToggleSubmitConfig,
    setModalConfig,
    setNavMapID,
    EditHandle,
  } = useContext(SettingMultiWebContext);
  const [prevValue, setPrevValue] = useState({});
  const { WebNavBarMap_List, orgId } = props;

  const { tabelAnimation, setLoaderShow } = useContext(LoaderContext);

  /**
   * Add Profile Function call
   */

  const AddProfile = () => {
    setToggleSubmitNav(false);
    setModalNav(true);
  };

  useMemo(() => {
    tableId(pageNumberNav, pageSizeNav, formvalueNav);
  }, [pageNumberNav, pageSizeNav, formvalueNav]);

  const handleDelete = async (navMapId) => {
    let confirmation = window.confirm("Are you sure you want to delete?");
    if (confirmation) {
      try {
        let paramObj = {
          navMapId,
        };
        const { data } = await WebNavBarMapListDelete(paramObj);
        if (data.status === 200) {
          WebNavBarMap_List({ orgId: orgId });
          toast.success(data.message, {
            autoClose: 1000,
            transition: Zoom,
          });
        }
      } catch (error) {
        toast.error(error?.response?.data?.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    }
  };

  const handleNextAndPrevChild = ({ navMapId, parentId, condition }) => {
    if (condition === "prev") {
      let paramObj = {
        orgId: orgId,
      };
      if (Object.keys(prevValue)?.length === 1) {
        paramObj["parentId"] = null;
      } else {
        paramObj["parentId"] = prevValue[parentId];
      }
      WebNavBarMap_List({ orgId, paramObj });
      let convertParentIdNumToString = String(parentId);
      if (prevValue.hasOwnProperty(convertParentIdNumToString)) {
        let removeKey = { ...prevValue };
        delete removeKey[parentId];
        setPrevValue(removeKey);
      }
    } else {
      setPrevValue((prev) => {
        return {
          ...prev,
          [navMapId]: parentId,
        };
      });
      WebNavBarMap_List({
        orgId: orgId,
        parentId: navMapId,
      });
    }
  };

  const handleDeleteSection = async (pageId) => {
    let confirmation = window.confirm("Are you sure you want to delete?");
    if (confirmation) {
      try {
        let paramObj = {
          pageId,
        };
        const { data } = await WebNavBarConfigListDelete(paramObj);
        if (data.status === 200) {
          WebNavBarMap_List({ orgId: orgId });
          toast.success(data.message, {
            autoClose: 1000,
            transition: Zoom,
          });
        }
      } catch (error) {
        toast.error(error?.response?.data?.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    }
  };

  /**
   * Enable and Desable Organization
   */

  const switch_onChange_handle = async (navMapId, isActive) => {
    setLoaderShow(true);
    let formData = {
      navMapId: navMapId,
      isActive: !isActive,
    };
    try {
      const { data } = await statusChangeNavMap(formData);
      if (data.status === 200) {
        WebNavBarMap_List({ orgId: orgId });
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    } finally {
      setLoaderShow(false);
    }
  };

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 100,
      field: "sNo",
      headerName: "S.No.",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      flex: 0.2,
      minWidth: 250,
      field: "label",
      headerName: "Page Name (Menu) | Label",
      renderCell: (params) => (
        <>{params.row?.label ? params.row?.label : "-"}</>
      ),
    },
    {
      flex: 0.2,
      minWidth: 250,
      field: "slugUrl",
      headerName: "Page URL (Slug URL)",
      renderCell: (params) => (
        <>{params.row?.slugUrl ? params.row?.slugUrl : "-"}</>
      ),
    },

    {
      flex: 0.1,
      minWidth: 100,
      field: "createdAt",
      headerName: "Date",
      renderCell: (params) =>
        params.row.createdAt &&
        moment.utc(params.row.createdAt).format(MOMENT_SETTING.FORMATE),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "edit",
      headerName: "Actions",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return params.row?.hierarchy?.length > 1 ? (
          <Tooltip title="Delete">
            <IconButton
              aria-label="edit"
              style={{ color: "red" }}
              onClick={() => handleDeleteSection(params.row.pageId)}
            >
              <Trash2 />
            </IconButton>
          </Tooltip>
        ) : (
          <>
            <Switch
              value={params.row.isActive}
              onChange={() =>
                switch_onChange_handle(params.row.navMapId, params.row.isActive)
              }
            />
            <Tooltip title="Edit">
              <IconButton
                aria-label="edit"
                style={{ color: "green" }}
                onClick={() => EditHandle(params.row.navMapId, orgId)}
              >
                <ModeEditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Add Section">
              <IconButton
                aria-label="edit"
                style={{ color: "green" }}
                onClick={() => {
                  setToggleSubmitConfig(false);
                  setModalConfig(true);
                  setNavMapID(params.row.navMapId);
                }}
              >
                <Plus />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                aria-label="edit"
                style={{ color: "red" }}
                onClick={() => handleDelete(params.row.navMapId)}
              >
                <Trash2 />
              </IconButton>
            </Tooltip>
            {Object.keys(prevValue)?.length ? (
              <Tooltip title="Prev">
                <IconButton
                  aria-label="edit"
                  style={{ color: "red" }}
                  onClick={() =>
                    handleNextAndPrevChild({
                      navMapId: params.row.navMapId,
                      parentId: params.row.parentId,
                      condition: "prev",
                    })
                  }
                >
                  <ArrowLeft />
                </IconButton>
              </Tooltip>
            ) : (
              ""
            )}
            {params?.row?.subMenu?.length ? (
              <Tooltip title="Next">
                <IconButton
                  aria-label="edit"
                  style={{ color: "red" }}
                  onClick={() =>
                    handleNextAndPrevChild({
                      navMapId: params.row.navMapId,
                      parentId: params.row.parentId,
                      condition: "next",
                    })
                  }
                >
                  <ArrowRight />
                </IconButton>
              </Tooltip>
            ) : (
              ""
            )}
          </>
        );
      },
    },
  ];

  const sorting = (col) => {
    if (sortOrderNav === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortByNav(found.field);
      setSortOrderNav(SORTING_ORDER.ASC);
    }
    if (sortOrderNav === SORTING_ORDER.ASC) {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortByNav(found.field);
      setSortOrderNav(SORTING_ORDER.DSC);
    }
    if (sortOrderNav === SORTING_ORDER.DSC) {
      setSortByNav();
      setSortOrderNav("");
    }
  };

  useEffect(() => {
    const mainContainer = document.querySelector(".MuiDataGrid-main");
    if (mainContainer) {
      const unwantedDiv = Array.from(
        mainContainer.querySelectorAll("div")
      ).find((div) => {
        return div.textContent.includes("MUI X: Missing license key");
      });

      if (unwantedDiv) {
        unwantedDiv.remove();
      }
    }
  }, [document.querySelector(".MuiDataGrid-main")]);

  const getTreeDataPath = (row) => row.hierarchy;

  return (
    <Container fluid={true}>
      <Row>
        <Col sm="12">
          <Card>
            <CardHeader
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h3>{"Website Pages"}</h3>
              <div className="d-flex gap-2">
                <div
                  aria-label="add"
                  onClick={() => AddProfile()}
                  className="btn btn-primary"
                >
                  Create New Pages
                </div>
              </div>
            </CardHeader>
            {tabelAnimation === true ? (
              <TabelAnimation />
            ) : formvalueNav.length ? (
              // <DataGrid
              //   sx={MuiTabelStyle}
              //   autoHeight
              //   rows={formvalueNav}
              //   getRowId={(row) => row.navMapId}
              //   columns={defaultColumns}
              //   rowsPerPageOptions={[5, 10, 25, 50]}
              //   pagination
              //   rowCount={recordNav}
              //   pageSize={pageSizeNav}
              //   page={pageNumberNav - 1}
              //   onPageSizeChange={(newPageSize) => setPageSizeNav(newPageSize)}
              //   onPageChange={(newPageNo) => setPageNumberNav(newPageNo + 1)}
              //   paginationMode="server"
              //   disableSelectionOnClick
              //   checkboxSelection={false}
              //   sortingMode="server"
              //   onSortModelChange={sorting}
              // />
              // <div style={{ height: 400, width: "100%" }}>
              <DataGridPro
                sx={MuiTabelStyle}
                treeData
                autoHeight
                rows={formvalueNav}
                getRowId={(row) => (row.navMapId ? row.navMapId : row.pageId)}
                columns={defaultColumns}
                getTreeDataPath={getTreeDataPath}
                rowsPerPageOptions={[5, 10, 25, 50]}
                pagination
                rowCount={recordNav}
                pageSize={pageSizeNav}
                page={pageNumberNav - 1}
                onPageSizeChange={(newPageSize) => setPageSizeNav(newPageSize)}
                onPageChange={(newPageNo) => setPageNumberNav(newPageNo + 1)}
                paginationMode="server"
                disableSelectionOnClick
                checkboxSelection={false}
                sortingMode="server"
                onSortModelChange={sorting}
              />
            ) : (
              // </div>
              <DataNotFound />
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SettingMultiWebItemWebNavBarMap;
