import React, { useContext } from "react";
import { Settings } from "react-feather";
import { Card, CardHeader, Col, Row } from "reactstrap";
import SettingMultiWebContext from "../../context/SettingMultiWebContext/SettingMultiWebContext";
import { Style } from "../../constant";

const PagewiseSectionOrderSetup = () => {
  const { setModalSectionPo } = useContext(SettingMultiWebContext);
  return (
    <Row>
      <Col sm="12">
        <Card>
          <CardHeader
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <h3>{"Pagewise Section Order Setup"}</h3>
            <div
              aria-label="add"
              onClick={() => setModalSectionPo(true)}
              className="btn btn-secondary"
              style={Style}
            >
              <Settings />
            </div>
          </CardHeader>
        </Card>
      </Col>
    </Row>
  );
};

export default PagewiseSectionOrderSetup;
