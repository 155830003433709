import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Input, Label, Row } from "reactstrap";
import { getNavbarList } from "../../../apis/AboutApi/AboutUsApi";
import { TostMessage } from "../../../utils";
import { FaqsTheme, ServiceCounterTheme } from "../../../constant";
import FaqsContext from "../../../context/FaqsContext/FaqsContext";
import IsShowTitleORNot from "../../../common/isShowTitleORNot";
import { slugUrlConverter } from "../../../../utils/helper";
import { checkSlugUrl } from "../apis/OurTeamApi/OurTeamApi";
import { Zoom, toast } from "react-toastify";

const FaqsParentPopup = (props) => {
  const { setFormData, fromData, isEdit } = useContext(FaqsContext);
  const { simpleValidator } = props;

  /**
   * input value change
   * @param {event} e
   */
  const changeFormValue = (e) => {
    const { value, name, checked } = e.target;
    setFormData((prev) => {
      let updatedData =
        name === "isTitleShow"
          ? {
              ...prev,
              [name]: checked,
            }
          : {
              ...prev,
              [name]: value,
            };
      if (name === "title" || name === "slugUrl") {
        updatedData.slugUrl = slugUrlConverter({ value: value });
      }
      return updatedData;
    });
  };
  const handleFocusChange = async () => {
    if (setFormData.slugUrl) {
      try {
        let paramObj = {
          slugUrl: setFormData.slugUrl,
          orgId: localStorage.getItem("orgId"),
        };
        await checkSlugUrl(paramObj);
      } catch (error) {
        toast.error(error.response.data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    }
  };

  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="12">
            <div className="mt-4 d-flex flex-column justify-content-center ">
              <h6>{isEdit ? "Edit" : "Add New"}</h6>
              <Row className="mt-4">
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">
                    Title *{" "}
                    <IsShowTitleORNot
                      checked={fromData?.isTitleShow}
                      onChange={changeFormValue}
                    />{" "}
                  </Label>
                  <Input
                    className="form-control"
                    name="title"
                    type="text"
                    placeholder="Title *"
                    value={fromData?.title}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    required
                  />
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "Title",
                      fromData?.title,
                      "required"
                    )}
                  </p>
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Slug Url *"}</Label>
                  <Input
                    className="form-control"
                    name="slugUrl"
                    type="text"
                    placeholder="Slug Url *"
                    value={fromData.slugUrl}
                    onChange={(e) => changeFormValue(e)}
                    onBlur={() => handleFocusChange()}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "slugUrl",
                      fromData.slugUrl,
                      "required"
                    )}
                  </p>
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Sub Title"}</Label>
                  <Input
                    className="form-control"
                    name="subTitle"
                    type="text"
                    placeholder="Sub Title"
                    value={fromData?.subTitle}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                  {/* <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "Sub Title",
                      fromData?.subTitle,
                      "required"
                    )}
                  </p> */}
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Button text"}</Label>
                  <Input
                    className="form-control"
                    name="buttonText"
                    type="text"
                    placeholder="Button text"
                    value={fromData?.buttonText}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    required
                  />
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Item Limit"}</Label>
                  <Input
                    className="form-control"
                    name="itemLimit"
                    type="number"
                    placeholder="Item Limit"
                    value={fromData?.itemLimit}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    required
                  />
                </Col>

                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Theme"}</Label>
                  <select
                    name="theme"
                    id="theme"
                    className="form-select"
                    onChange={(e) => changeFormValue(e)}
                    value={fromData?.theme}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    {FaqsTheme.map((ele) => {
                      return (
                        <option value={ele.theme} key={ele.theme}>
                          {ele.themeName}
                        </option>
                      );
                    })}
                  </select>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default FaqsParentPopup;
