import {
  addUrlImage,
  convertRGBA,
  removeUrlImage,
  removeUrlKeyInPath,
  rgbColorCreate,
} from "../../../../utils/helper";

export const PageTitleStyleONe = {
  ".page-head": {
    padding: "100px 0",
    "clip-path": "polygon(0 0, 100% 0%, 100% 80%, 50% 100%, 0 80%)",
    cp: "100%",
    cp11: "100%",
    cp12: "80%,",
    cp21: "50%",
    cp22: "100%,",
    cp31: "80%)",
    "background-color": { r: 0, g: 0, b: 0, a: 100 },
  },
  ".page-head.bg-image": {},
  ".page-head.bg-image::before": {
    // "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
  },
  ".page-head .page-title": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "40px",
    "font-weight": 500,
    "margin-bottom": "20px",
  },
  ".page-head .breadcrumb .breadcrumb-item a": {
    "font-size": "15px",
    "font-weight": 600,
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".page-head .breadcrumb .breadcrumb-item.active": {
    "font-size": "15px",
    "font-weight": 600,
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  custom: "",
  pageTitleId: "",
};

export const PageTitleThemeOneFunction = (payload) => {
  return {
    ".page-head": {
      padding: payload.style?.[".page-head"]?.["padding"],
      "clip-path": payload.style?.[".page-head"]?.["clip-path"],
      cp: payload.style?.[".page-head"]?.["clip-path"]
        ?.replace("polygon(0 0, ", "")
        ?.split(" ")[0],
      cp11: payload.style?.[".page-head"]?.["clip-path"]
        ?.replace("polygon(0 0, ", "")
        ?.split(" ")[2],
      cp12: payload.style?.[".page-head"]?.["clip-path"]
        ?.replace("polygon(0 0, ", "")
        ?.split(" ")[3],
      cp21: payload.style?.[".page-head"]?.["clip-path"]
        ?.replace("polygon(0 0, ", "")
        ?.split(" ")[4],
      cp22: payload.style?.[".page-head"]?.["clip-path"]
        ?.replace("polygon(0 0, ", "")
        ?.split(" ")[5],
      cp31: payload.style?.[".page-head"]?.["clip-path"]
        ?.replace("polygon(0 0, ", "")
        ?.split(" ")[7],
      "background-color": payload.style?.[".page-head"]?.["background-color"],
    },
    ".page-head.bg-image": {
      "background-image": payload.style?.[".page-head.bg-image"]?.[
        "background-image"
      ]
        ? removeUrlKeyInPath({
            urlString:
              payload.style?.[".page-head.bg-image"]?.["background-image"],
          })
        : "",
    },
    ".page-head.bg-image::before": {
      "background-color": payload.style?.[".page-head.bg-image::before"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".page-head.bg-image::before"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".page-head .page-title": {
      color: payload.style?.[".page-head .page-title"]?.["color"]
        ? convertRGBA({
            rgbString: payload.style?.[".page-head .page-title"]?.["color"],
          })
        : "",
      "font-size": payload.style?.[".page-head .page-title"]?.["font-size"],
      "font-weight": payload.style?.[".page-head .page-title"]?.["font-weight"],
      "margin-bottom":
        payload.style?.[".page-head .page-title"]?.["margin-bottom"],
    },
    ".page-head .breadcrumb .breadcrumb-item a": {
      "font-size":
        payload.style?.[".page-head .breadcrumb .breadcrumb-item a"]?.[
          "font-size"
        ],
      "font-weight":
        payload.style?.[".page-head .breadcrumb .breadcrumb-item a"]?.[
          "font-weight"
        ],
      color: payload.style?.[".page-head .breadcrumb .breadcrumb-item a"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".page-head .breadcrumb .breadcrumb-item a"]?.[
                "color"
              ],
          })
        : "",
    },
    ".page-head .breadcrumb .breadcrumb-item.active": {
      "font-size":
        payload.style?.[".page-head .breadcrumb .breadcrumb-item.active"]?.[
          "font-size"
        ],
      "font-weight":
        payload.style?.[".page-head .breadcrumb .breadcrumb-item.active"]?.[
          "font-weight"
        ],
      color: payload.style?.[
        ".page-head .breadcrumb .breadcrumb-item.active"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".page-head .breadcrumb .breadcrumb-item.active"
              ]?.["color"],
          })
        : "",
    },
    custom: payload?.style?.custom,
  };
};

export const PageTitleStyleOneSubFun = ({ settingFormData }) => {
  let newData = {
    ...settingFormData,
    ".page-head": {
      ...settingFormData[".page-head"],
      padding: `${settingFormData[".page-head"]["padding"]
        ?.split(" ")[0]
        ?.replace("px", "")}px 0`,
      "clip-path": `polygon(0 0, ${settingFormData[".page-head"]["cp"]
        ?.split(" ")[0]
        ?.replace("%", "")}% 0%, ${settingFormData[".page-head"]["cp11"]
        ?.split(" ")[0]
        ?.replace("%", "")}% ${settingFormData[".page-head"]["cp12"]
        ?.split(" ")[0]
        ?.replace("%,", "")}%, ${settingFormData[".page-head"]["cp21"]
        ?.split(" ")[0]
        ?.replace("%", "")}% ${settingFormData[".page-head"]["cp22"]
        ?.split(" ")[0]
        ?.replace("%,", "")}%, 0 ${settingFormData[".page-head"][
        "cp31"
      ]?.replace("%)", "")}%)`,
    },
    ".page-head.bg-image": settingFormData[".page-head.bg-image"][
      "background-image"
    ]
      ? {
          "background-image": settingFormData[".page-head.bg-image"][
            "background-image"
          ]
            ? addUrlImage({
                urlString: removeUrlImage({
                  urlString:
                    settingFormData?.[".page-head.bg-image"]?.[
                      "background-image"
                    ],
                }),
              })
            : "",
        }
      : {},
    ".page-head.bg-image::before": settingFormData[
      ".page-head.bg-image::before"
    ]["background-color"]
      ? {
          "background-color": settingFormData[".page-head.bg-image::before"][
            "background-color"
          ]
            ? rgbColorCreate({
                rgbString:
                  settingFormData[".page-head.bg-image::before"][
                    "background-color"
                  ],
              })
            : "",
        }
      : {},
    ".page-head .page-title": {
      ...settingFormData[".page-head .page-title"],
      color: settingFormData[".page-head .page-title"]?.["color"]
        ? rgbColorCreate({
            rgbString: settingFormData[".page-head .page-title"]?.["color"],
          })
        : "",
      "font-size": `${settingFormData[".page-head .page-title"][
        "font-size"
      ]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".page-head .page-title"]["font-weight"]}`,
      "margin-bottom": `${settingFormData[".page-head .page-title"][
        "margin-bottom"
      ]?.replace("px", "")}px`,
    },
    ".page-head .breadcrumb .breadcrumb-item a": {
      ...settingFormData[".page-head .breadcrumb .breadcrumb-item a"],
      "font-size": `${settingFormData[
        ".page-head .breadcrumb .breadcrumb-item a"
      ]["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".page-head .breadcrumb .breadcrumb-item a"]["font-weight"]}`,
      color: settingFormData[".page-head .breadcrumb .breadcrumb-item a"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".page-head .breadcrumb .breadcrumb-item a"]?.[
                "color"
              ],
          })
        : "",
    },
    ".page-head .breadcrumb .breadcrumb-item.active": {
      ...settingFormData[".page-head .breadcrumb .breadcrumb-item.active"],
      "font-size": `${settingFormData[
        ".page-head .breadcrumb .breadcrumb-item.active"
      ]["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".page-head .breadcrumb .breadcrumb-item.active"]["font-weight"]}`,
      color: settingFormData[
        ".page-head .breadcrumb .breadcrumb-item.active"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".page-head .breadcrumb .breadcrumb-item.active"
              ]?.["color"],
          })
        : "",
    },
  };
  return newData;
};
