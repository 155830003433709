import React, { useState } from "react";
import ServiceAndCategoryContext from "./ServiceAndCategoryContext";
import { randomStr } from "../../../utils/helper";

const ServiceAndCategoryState = (props) => {
  const [apiData, setApiData] = useState({ data: [], isLoading: false });
  const [apiDataOurTeam, setApiDataOurTeam] = useState({
    isLoading: false,
    data: [],
  });
  const [skill, setSkill] = useState([
    { id: randomStr(2), skill: "", parentageOfSkill: "" },
  ]);
  const [fromData, setFormData] = useState({
    title: "",
    headLine: "",
    slugUrl: "",
    isTitleShow: true,
    shortDescription: "",
    description: "",
    image: "",
    logo: "",
    imageThumbnail: "",
    buttonText: "",
    hyperLink: "",
    webId: localStorage.getItem("webId"),
  });
  const [isBackgroundColor, setIsBackgroundColor] = useState(false);
  const [serviceSearchId, setServiceSearchId] = useState();
  const [collId, setCollId] = useState();
  const [backImageToggle, setBackImageToggle] = useState(false);
  const [backImageToggleDetail, setBackImageToggleDetail] = useState(false);
  const [formDataOurTeam, setFormDataOurData] = useState({
    style: {
      ".service-section": {
        padding: "100px 0",
        "background-color": "#fafafa",
      },
      ".service-section.bg-image": {
        "background-image": "",
      },
      ".service-section.bg-image::before": {
        "background-color": "",
        // "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
      },
      ".service-section .section-title-area": {
        "margin-bottom": "50px",
      },
      ".service-section .section-title-area .section-subtitle": {
        "font-size": "16px",
        "font-weight": "600",
        color: "#ff497c",
        "background-color": { r: 255, g: 73, b: 124, a: 0.2 },
        padding: "5px 20px",
        p1: "5px",
        p2: "20px",
        "border-radius": "15px",
        "margin-bottom": "15px",
      },
      ".service-section .section-title-area .section-title": {
        "font-size": "44px",
        "font-weight": "600",
        color: "#000000",
      },
      ".service-section .servicediv": {
        margin: "15px",
        padding: "20px",
        outline: "none",
        outPx: "1px",
        outThik: "solid",
        outColor: "#f9f9f9",
        "background-color": "#ffffff",
        "box-shadow": "0 5px 10px 0 #071C1A1A",
        bs1: "0px",
        bs2: "5px",
        bs3: "10px",
        bs4: "0px",
        shadowColor: "#071C1A1A",
      },
      ".service-section .servicediv .service-icon": {
        "margin-bottom": "20px",
        "max-width": "70px",
      },
      ".service-section .servicediv h4": {
        color: " #000000",
        "font-weight": "600",
        "font-size": "20px",
        "margin-bottom": "15px",
        "text-align": "justify",
      },
      ".service-section .servicediv p": {
        color: "#000000",
        "font-weight": "400",
        "font-size": "14px",
        "line-height": "1.6",
        "margin-bottom": "15px",
        "text-align": "justify",
      },
      ".service-section .servicediv .links": {
        "font-size": "16px",
        "font-weight": "500",
        color: "#ff497c",
      },
      ".service-slider .slick-arrow:before": {
        color: "#ff497c",
      },
      ".service-slider .service-box": {
        position: "relative",
        "border-radius": "10px",
        margin: "10px",
      },
      ".service-slider .service-box .servicediv": {
        "background-color": "rgba(255, 255, 255, 0.9)",
        "border-radius": "10px",
      },
      ".service-slider .service-box .service-img": {
        "border-radius": "10px",
      },
      ".service-detail-section": {
        padding: "60px 0",
        "background-color": "#ffffff",
      },
      ".service-detail-section.bg-image": {
        "background-image": "",
      },
      ".service-detail-section.bg-image::before": {
        "background-color": "",
      },
      ".detail-sidebar": {
        display: "inline-block",
        width: "100%",
      },
      ".detail-sidebar .other-link li a": {
        "background-color": "#f4f4f5",
        color: "#1f2732",
        padding: "15px 20px",
        p1: "15px",
        p2: "20px",
        "font-size": "16px",
        "font-weight": "normal",
        border: "1px solid #d7e2e6",
        borderPx: "1px",
        borderThik: "solid",
        borderColor: "#d7e2e6",
      },
      ".detail-sidebar .other-link li .fa": {
        "margin-right": "10px",
      },
      ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a":
        {
          color: "#ff497c",
          "border-left": "5px solid #ff497c",
          borderLfPx: "5px",
          borderLfThik: "solid",
          borderLfColor: "#ff497c",
        },
      ".detail-sidebar .widget-form": {
        display: "inline-block",
        width: "100%",
        padding: "25px 35px",
        p1: "25px",
        p2: "35px",
        "background-color": "#1f2732",
      },
      ".detail-sidebar .widget-form .form-control": {
        padding: "13px",
      },
      ".detail-sidebar .widget-form .btn-submit": {
        "background-color": "#ff497c",
        border: "2px solid #ff497c",
        borderPx: "2px",
        borderThik: "solid",
        borderColor: "#ff497c",
        color: "#ffffff",
        "font-size": "16px",
        "font-weight": "600",
        padding: "8px 25px",
        p1: "8px",
        p2: "25px",
        width: "100%",
      },
      serviceId: "",
    },
    title: "",
    subTitle: "",
    image: "",
    slugUrl: "",
    buttonText: "",
    itemLimit: "",
    navMapId: "",
    isTitleShow: true,
    theme: 1,
    navMapId: "",
    webId: localStorage.getItem("webId"),
  });

  const [settingFormData, setSettingFormData] = useState({});
  const [isBackgroundColor2, setIsBackgroundColor2] = useState(false);
  const [theme, setTheme] = useState();
  const [boxSection, setBoxSection] = useState(false);
  const [boxSectionDetail, setBoxSectionDetail] = useState(false);
  const [id, setId] = useState("");
  const [teamMemberId, setTeamMemberId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [filter, setFilter] = useState("");
  const [isImageUpload, setIsImageUpload] = useState({
    isList: false,
    isDetails: false,
  });
  const [imgShow, setImgShow] = useState(false);
  const [sectionPageName, setSectionPageName] = useState("");
  return (
    <ServiceAndCategoryContext.Provider
      value={{
        apiData,
        setApiData,
        apiDataOurTeam,
        setApiDataOurTeam,
        fromData,
        setFormData,
        formDataOurTeam,
        setFormDataOurData,
        id,
        setId,
        isEdit,
        setIsEdit,
        filter,
        setFilter,
        skill,
        teamMemberId,
        setTeamMemberId,
        setSkill,
        settingFormData,
        setSettingFormData,
        backImageToggle,
        setBackImageToggle,
        backImageToggleDetail,
        setBackImageToggleDetail,
        boxSection,
        setBoxSection,
        boxSectionDetail,
        setBoxSectionDetail,
        theme,
        setTheme,
        collId,
        setCollId,
        serviceSearchId,
        setServiceSearchId,
        isImageUpload,
        setIsImageUpload,
        isBackgroundColor,
        setIsBackgroundColor,
        isBackgroundColor2,
        setIsBackgroundColor2,
        imgShow,
        setImgShow,
        sectionPageName,
        setSectionPageName,
      }}
    >
      {props.children}
    </ServiceAndCategoryContext.Provider>
  );
};

export default ServiceAndCategoryState;
