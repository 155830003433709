export const aboutStyleOneReset = {
  ".about-section": {
    padding: "100px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".about-section.bg-image": {},
  ".about-section.bg-image::before": {},
  ".about-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".about-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: { r: 255, g: 73, b: 124, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 0.2 },
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".about-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: { r: 0, g: 0, b: 0, a: 100 },
  },
  ".about-section .about-img": {
    margin: "0 auto",
    display: "block",
  },
  ".about-section .about-details": {
    padding: "20px",
  },
  ".about-section .about-details h5": {
    "font-size": "24px",
    "font-weight": "600",
    color: { r: 0, g: 0, b: 0, a: 100 },
    "line-height": "1.4",
    "margin-bottom": "20px",
    "text-align": "justify",
  },
  ".about-section .about-details p": {
    "font-size": "17px",
    "font-weight": "500",
    color: { r: 0, g: 0, b: 0, a: 100 },
    "line-height": "1.6",
    "margin-bottom": "20px",
    "text-align": "justify",
  },
  ".about-section .about-details ul": {
    "margin-bottom": "30px",
  },
  ".about-section .about-details ul li": {
    "font-size": "16px",
    "font-weight": "500",
    color: { r: 0, g: 0, b: 0, a: 100 },
    margin: "14px 0",
  },
  ".about-section .about-details ul li:before": {
    color: { r: 255, g: 73, b: 124, a: 100 },
    "margin-right": "10px",
  },
  ".about-section .about-details ul li:first-child": {
    "margin-top": "0",
  },
  ".about-section .about-details .btn-section": {
    "justify-content": "start",
  },
  ".about-section .about-details .btn": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    border: "2px solid { r: 255, g: 73, b: 124, a: 100 }",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
    padding: "8px 25px",
    p1: "8px",
    p2: "25px",
  },
  custom: "",
};

export const aboutStyleTwoReset = {
  ".about2-section": {
    padding: "100px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".about2-section.bg-image": {},
  ".about2-section.bg-image::before": {
    // "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
  },
  ".about2-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".about2-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: { r: 255, g: 73, b: 124, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 0.2 },
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".about2-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: { r: 0, g: 0, b: 0, a: 100 },
  },
  ".about2-section .about-img": {
    margin: "0 auto",
    display: "block",
  },
  ".about2-section .about-details": {
    padding: "20px",
  },
  ".about2-section .about-details h5": {
    "font-size": "24px",
    "font-weight": "600",
    color: { r: 0, g: 0, b: 0, a: 100 },
    "line-height": "1.4",
    "margin-bottom": "20px",
    "text-align": "justify",
  },
  ".about2-section .about-details h6": {
    "font-size": "20px",
    "font-weight": "600",
    color: { r: 0, g: 0, b: 0, a: 100 },
    "line-height": "1.4",
    "margin-bottom": "20px",
    "text-align": "justify",
  },
  ".about2-section .about-details p": {
    "font-size": "17px",
    "font-weight": "500",
    color: { r: 0, g: 0, b: 0, a: 100 },
    "line-height": "1.6",
    "margin-bottom": "20px",
    "text-align": "justify",
  },
  ".about2-section .about-details ul": {
    "margin-bottom": "30px",
  },
  ".about2-section .about-details ul li": {
    "font-size": "16px",
    "font-weight": "500",
    color: { r: 0, g: 0, b: 0, a: 100 },
    margin: "14px 0",
  },
  ".about2-section .about-details ul li:before": {
    color: { r: 255, g: 73, b: 124, a: 100 },
    "margin-right": "10px",
  },
  ".about2-section .about-details ul li:first-child": {
    "margin-top": "0",
  },
  ".about2-section .about-details .btn-section": {
    "justify-content": "start",
  },
  ".about2-section .about-details .btn": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    border: "2px solid { r: 255, g: 73, b: 124, a: 100 }",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
    padding: "8px 25px",
    p1: "8px",
    p2: "25px",
  },
  custom: "",
};
