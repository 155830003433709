import React, { useState } from "react";
import ItemFeatureContext from "./ItemFeatureContext";
import { randomStr } from "../../../utils/helper";

const ItemFeatureState = (props) => {
  const [apiData, setApiData] = useState({ data: [], isLoading: false });
  const [apiDataOurTeam, setApiDataOurTeam] = useState({
    isLoading: false,
    data: [],
  });
  const [skill, setSkill] = useState([
    { id: randomStr(2), skill: "", parentageOfSkill: "" },
  ]);
  const [isBackgroundColor, setIsBackgroundColor] = useState(false);
  const [collId, setCollId] = useState();
  const [fromData, setFormData] = useState({
    title: "",
    subTitle: "",
    isTitleShow: true,
    navMapId: "",
    image: "",
    featureImage: "",
    webId: localStorage.getItem("webId"),
    style: {
      ".product-features-section": {
        padding: "100px 0",
        "background-color": "rgb(255 255 255 / 100)",
      },
      ".product-features-section.bg-image": {
        /* "background-image": "url(../images/bg-1.jpg)" */
      },
      ".product-features-section.bg-image::before": {
        /* background-color: "rgba(0, 0, 0, 0.8)" */
      },
      ".product-features-section .section-title-area": {
        "margin-bottom": "50px",
      },
      ".product-features-section .section-title-area .section-subtitle": {
        "font-size": "16px",
        "font-weight": "600",
        color: "rgb(255 73 124 / 100)",
        "background-color": "rgba(255, 73, 124, 0.2)",
        padding: "5px 20px",
        "border-radius": "15px",
        "margin-bottom": "15px",
      },
      ".product-features-section .section-title-area .section-title": {
        "font-size": "44px",
        "font-weight": "600",
        color: "rgb(0 0 0 / 100)",
      },
      ".product-features-section .feature-list": {
        padding: "20px",
        margin: "10px 0",
        "background-color": "rgb(255 255 255 / 100)",
        "box-shadow": "0 0px 15px rgba(0, 0, 0, 0.094)",
        shadowColor: "rgba(0, 0, 0, 0.094)",
        "border-radius": "10px",
      },
      ".product-features-section .feature-list h5": {
        "font-size": "20px",
        "font-weight": "600",
        color: "rgb(255 73 124 / 100)",
        "margin-bottom": "10px",
      },
      ".product-features-section .feature-list p": {
        "font-size": "16px",
        "font-weight": "normal",
        color: "rgb(102 102 102 / 100)",
        "line-height": "1.4",
        "text-align": "justify",
      },
    },
  });

  const [formDataChild, setFormDataChild] = useState({
    featureImage: "",
    image: "",
    isTitleShow: true,
    title: "",
    subTitle: "",
    order: 1,
    webId: localStorage.getItem("webId"),
    navMapId: "",
  });

  const [settingFormData, setSettingFormData] = useState({
    ".product-features-section": {
      padding: "100px 0",
      "background-color": "rgb(255 255 255 / 100)",
    },
    ".product-features-section.bg-image": {
      /* "background-image": "url(../images/bg-1.jpg)" */
    },
    ".product-features-section.bg-image::before": {
      /* background-color: "rgba(0, 0, 0, 0.8)" */
    },
    ".product-features-section .section-title-area": {
      "margin-bottom": "50px",
    },
    ".product-features-section .section-title-area .section-subtitle": {
      "font-size": "16px",
      "font-weight": "600",
      color: "rgb(255 73 124 / 100)",
      "background-color": "rgba(255, 73, 124, 0.2)",
      padding: "5px 20px",
      "border-radius": "15px",
      "margin-bottom": "15px",
    },
    ".product-features-section .section-title-area .section-title": {
      "font-size": "44px",
      "font-weight": "600",
      color: "rgb(0 0 0 / 100)",
    },
    ".product-features-section .feature-list": {
      padding: "20px",
      margin: "10px 0",
      "background-color": "rgb(255 255 255 / 100)",
      "box-shadow": "0 0px 15px rgba(0, 0, 0, 0.094)",
      shadowColor: "rgba(0, 0, 0, 0.094)",
      "border-radius": "10px",
    },
    ".product-features-section .feature-list h5": {
      "font-size": "20px",
      "font-weight": "600",
      color: "rgb(255 73 124 / 100)",
      "margin-bottom": "10px",
    },
    ".product-features-section .feature-list p": {
      "font-size": "16px",
      "font-weight": "normal",
      color: "rgb(102 102 102 / 100)",
      "line-height": "1.4",
      "text-align": "justify",
    },
    image: "",
  });
  const [backImageToggle, setBackImageToggle] = useState(false);
  const [boxSection, setBoxSection] = useState(false);
  const [id, setId] = useState("");
  const [childId, setChildId] = useState("");
  const [navMapId, setNavMapId] = useState("");
  const [galleryImgId, setGalleryImgId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isParent, setIsParent] = useState(false);
  const [filter, setFilter] = useState("");
  const [theme, setTheme] = useState(false);
  const [imgShow, setImgShow] = useState(false);
  const [sectionPageName, setSectionPageName] = useState("");
  return (
    <ItemFeatureContext.Provider
      value={{
        apiData,
        setApiData,
        apiDataOurTeam,
        setApiDataOurTeam,
        fromData,
        setFormData,
        id,
        setId,
        isEdit,
        setIsEdit,
        filter,
        setFilter,
        skill,
        galleryImgId,
        setGalleryImgId,
        setSkill,
        settingFormData,
        setSettingFormData,
        formDataChild,
        setFormDataChild,
        isParent,
        setIsParent,
        childId,
        setChildId,
        navMapId,
        setNavMapId,
        boxSection,
        setBoxSection,
        backImageToggle,
        setBackImageToggle,
        collId,
        setCollId,
        isBackgroundColor,
        setIsBackgroundColor,
        theme,
        setTheme,
        imgShow,
        setImgShow,
        sectionPageName,
        setSectionPageName,
      }}
    >
      {props.children}
    </ItemFeatureContext.Provider>
  );
};

export default ItemFeatureState;
