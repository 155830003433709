import React, { useContext } from "react";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import ProductBuyContext from "../../../context/ProductBuyContext/ProductBuyContext";
import { Border, fontWeight } from "../../../../constant";
import CommonColor from "../../../common/CommonColor";
import CustomStyle from "../../../common/CustomStyle";

const SettingMyAccountStyle = (props) => {
  const {  handleSubmitSetting } = props;
  const { isEdit, settingFormData, setSettingFormData } =
    useContext(ProductBuyContext);

  const handleChangeOrderSection = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".my-order-section"]: {
          ...prev[".my-order-section"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeOrderSectionNavPillsNavLink = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".my-order-section .nav-pills .nav-link"]: {
          ...prev[".my-order-section .nav-pills .nav-link"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeOrderSectionNavPillsNavLinkAc = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".my-order-section .nav-pills .nav-link.active"]: {
          ...prev[".my-order-section .nav-pills .nav-link.active"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeOrderSectionTabContentA = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".my-order-section .tab-content a"]: {
          ...prev[".my-order-section .tab-content a"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeOrderSectionTebFormCon = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".my-order-section .tab-content .form-control"]: {
          ...prev[".my-order-section .tab-content .form-control"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeOrderSectionBtnPri = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".my-order-section .tab-content .btn-primary"]: {
          ...prev[".my-order-section .tab-content .btn-primary"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeCustomStyle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const resetHandler = () => {
    setSettingFormData({
      ".my-order-section": {
        padding: "60px 0",
        "background-color": { r: 255, g: 255, b: 255, a: 100 },
      },
      ".my-order-section .nav-pills .nav-link": {
        color: { r: 255, g: 73, b: 124, a: 100 },
        "font-size": "16px",
        padding: "15px",
        "font-weight": "700",
        border: "1px solid { r: 255, g: 73, b: 124, a: 100 }",
        borderPx: "1px",
        borderThik: "solid",
        borderColor: { r: 255, g: 73, b: 124, a: 100 },
        "border-radius": "5px",
      },
      ".my-order-section .nav-pills .nav-link.active": {
        color: { r: 255, g: 255, b: 255, a: 100 },
        "background-color": { r: 255, g: 73, b: 124, a: 100 },
      },
      ".my-order-section .tab-content a": {
        color: { r: 255, g: 73, b: 124, a: 100 },
      },
      ".my-order-section .tab-content .form-control": {
        border: "1px solid { r: 212, g: 212, b: 212, a: 100 }",
        borderPx: "1px",
        borderThik: "solid",
        borderColor: { r: 212, g: 212, b: 212, a: 100 },
        "border-radius": "4px",
        "font-size": "14px",
      },
      ".my-order-section .tab-content .btn-primary": {
        "border-color": { r: 255, g: 73, b: 124, a: 100 },
        "background-color": { r: 255, g: 73, b: 124, a: 100 },
      },
    });
  };
  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="12">
            <div className="mt-4 d-flex flex-column justify-content-center ">
              <div className="d-flex justify-content-between">
                <h6>{isEdit ? "Edit" : "Add New"}</h6>
                <div className="d-flex gap-2">
                  <Button onClick={resetHandler}>Reset</Button>
                  <Button onClick={() => handleSubmitSetting(true)}>
                    Restore
                  </Button>
                  <Button
                    onClick={() => handleSubmitSetting(false, false)}
                    color="success"
                  >
                    Save & View
                  </Button>
                </div>
              </div>
              <Row className="mt-4">
                <h4 className="mb-3">Make change in Main section</h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding (Default : 60)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="padding"
                    type="number"
                    placeholder="Padding"
                    value={settingFormData?.[".my-order-section"]?.["padding"]
                      ?.split(" ")[0]
                      ?.replace(/px/g, "")}
                    onChange={(e) => handleChangeOrderSection(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[".my-order-section"]?.[
                        "background-color"
                      ]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".my-order-section"}
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">my-order-section nav-pills nav-link</h4>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".my-order-section .nav-pills .nav-link"
                      ]?.["color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".my-order-section .nav-pills .nav-link"}
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Size (Default : 16)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="font-size"
                    type="number"
                    placeholder="Font Size"
                    value={settingFormData?.[
                      ".my-order-section .nav-pills .nav-link"
                    ]?.["font-size"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeOrderSectionNavPillsNavLink(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding (Default : 15)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="padding"
                    type="number"
                    placeholder="Padding"
                    value={settingFormData?.[
                      ".my-order-section .nav-pills .nav-link"
                    ]?.["padding"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeOrderSectionNavPillsNavLink(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Weight (Default : 700)"}
                  </Label>
                  <select
                    name="font-weight"
                    id="font-weight"
                    className="form-select"
                    value={
                      settingFormData?.[
                        ".my-order-section .nav-pills .nav-link"
                      ]?.["font-weight"]
                    }
                    onChange={(e) => handleChangeOrderSectionNavPillsNavLink(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {fontWeight.map((elem) => {
                      return (
                        <option value={elem["font-weight"]}>
                          {elem["font-weight"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border (Default : 1 solid pink)"}
                  </Label>
                  <div className="d-flex">
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="borderPx"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[
                          ".my-order-section .nav-pills .nav-link"
                        ]?.["borderPx"]?.replace(/px/g, "")}
                        onChange={(e) =>
                          handleChangeOrderSectionNavPillsNavLink(e)
                        }
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <select
                        name="borderThik"
                        id="borderThik"
                        className="form-control"
                        value={
                          settingFormData?.[
                            ".my-order-section .nav-pills .nav-link"
                          ]?.["borderThik"]
                        }
                        onChange={(e) =>
                          handleChangeOrderSectionNavPillsNavLink(e)
                        }
                        style={{ borderRadius: "0", color: "black" }}
                      >
                        <option value="">--select--</option>
                        {Border.map((elem) => {
                          return (
                            <option value={elem.border}>{elem.border}</option>
                          );
                        })}
                      </select>
                    </div>
                    <div>
                      <CommonColor
                        color={
                          settingFormData?.[
                            ".my-order-section .nav-pills .nav-link"
                          ]?.["borderColor"]
                        }
                        setSettingFormData={setSettingFormData}
                        className={".my-order-section .nav-pills .nav-link"}
                        classProperty={"borderColor"}
                        label={"Select Color"}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border Radius (Default : 5)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="border-radius"
                    type="number"
                    placeholder="Border Radius"
                    value={settingFormData?.[
                      ".my-order-section .nav-pills .nav-link"
                    ]?.["border-radius"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeOrderSectionNavPillsNavLink(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">
                  my-order-section nav-pills nav-link.active
                </h4>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".my-order-section .nav-pills .nav-link.active"
                      ]?.["color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".my-order-section .nav-pills .nav-link.active"}
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".my-order-section .nav-pills .nav-link.active"
                      ]?.["background-color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".my-order-section .nav-pills .nav-link.active"}
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">my-order-section tab-content a</h4>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[".my-order-section .tab-content a"]?.[
                        "color"
                      ]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".my-order-section .tab-content a"}
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">
                  my-order-section tab-content form-control
                </h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border (Default : 1 solid gray)"}
                  </Label>
                  <div className="d-flex ">
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="borderPx"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[
                          ".my-order-section .tab-content .form-control"
                        ]?.["borderPx"]?.replace(/px/g, "")}
                        onChange={(e) => handleChangeOrderSectionTebFormCon(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <select
                        name="borderThik"
                        id="borderThik"
                        className="form-control"
                        value={
                          settingFormData?.[
                            ".my-order-section .tab-content .form-control"
                          ]?.["borderThik"]
                        }
                        onChange={(e) => handleChangeOrderSectionTebFormCon(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      >
                        <option value="">--select--</option>
                        {Border.map((elem) => {
                          return (
                            <option value={elem.border}>{elem.border}</option>
                          );
                        })}
                      </select>
                    </div>
                    <div>
                      <CommonColor
                        color={
                          settingFormData?.[
                            ".my-order-section .tab-content .form-control"
                          ]?.["borderColor"]
                        }
                        setSettingFormData={setSettingFormData}
                        className={
                          ".my-order-section .tab-content .form-control"
                        }
                        classProperty={"borderColor"}
                        label={"Select Color"}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border Radius (Default : 4)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="border-radius"
                    type="number"
                    placeholder="Border Radius"
                    value={settingFormData?.[
                      ".my-order-section .tab-content .form-control"
                    ]?.["border-radius"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeOrderSectionTebFormCon(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Size (Default : 14)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="font-size"
                    type="number"
                    placeholder="Font Size"
                    value={settingFormData?.[
                      ".my-order-section .tab-content .form-control"
                    ]?.["font-size"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeOrderSectionTebFormCon(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">
                  my-order-section tab-content btn-primary
                </h4>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".my-order-section .tab-content .btn-primary"
                      ]?.["border-color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".my-order-section .tab-content .btn-primary"}
                    classProperty={"border-color"}
                    label={"Select Border Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".my-order-section .tab-content .btn-primary"
                      ]?.["background-color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".my-order-section .tab-content .btn-primary"}
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
              </Row>
              <CustomStyle
                handleChange={handleChangeCustomStyle}
                value={settingFormData?.custom}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SettingMyAccountStyle;
