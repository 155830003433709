import { IconButton, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment-timezone";
import React, { useContext } from "react";
import { Card, CardHeader, Col, Container, Row } from "reactstrap";
import { INQUARY_LIST, MOMENT_SETTING, SORTING_ORDER } from "../../../constant";
import PersonalPageContext from "../../../context/PersonalPageContext/PersonalPageContaxt";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { MuiTabelStyle } from "../../../utils/MuiTabelStyle";

const InquiryPageItem = (props) => {
  const { formvalue, sortOrder, setSortOrder, setSortBy, SHowInquary } = props;
  const { record, pageSize, page, setPageSize, setPage } =
    useContext(PersonalPageContext);

  /**
   * Page item to array
   */

  const defaultColumns = [
    {
      flex: 0.01,
      minWidth: 100,
      field: "sNo",
      headerName: "S.No.",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      // renderCell: () =>
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "createdAt",
      headerName: "Date & Time",
      renderCell: (params) =>
        params.row.createdAt &&
        moment.utc(params.row.createdAt).format(MOMENT_SETTING.FORMATE),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "name",
      headerName: "Name",
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "email",
      headerName: "Email",
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "mobile",
      headerName: "Mobile",
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "topic",
      headerName: "Topic",
      renderCell: (params) => {
        let topic = JSON.parse(params?.row?.other);
        return topic?.topic ? topic?.topic : "-";
      },
    },

    {
      flex: 0.1,
      minWidth: 100,
      field: "message",
      headerName: "Remark / Description",
    },

    {
      flex: 0.1,
      minWidth: 100,
      field: "view",
      headerName: "View",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Tooltip title="View">
          <IconButton
            aria-label="view"
            style={{ color: "yellowgreen" }}
            onClick={() => SHowInquary(params.row.inquiryId)}
          >
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  /**
   * Sorting table to inquary page
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder(SORTING_ORDER.ASC);
    }
    if (sortOrder === SORTING_ORDER.ASC) {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder(SORTING_ORDER.DSC);
    }
    if (sortOrder === SORTING_ORDER.DSC) {
      setSortBy();
      setSortOrder("");
    }
  };

  return (
    <Container fluid={true}>
      <Row>
        <Col sm="12">
          <Card>
            <CardHeader
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h3>{"Web Inquiry Page"}</h3>
            </CardHeader>
            <DataGrid
              autoHeight
              pagination
              sx={MuiTabelStyle}
              rows={formvalue}
              getRowId={(row) => row.inquiryId}
              columns={defaultColumns}
              rowsPerPageOptions={[7, 10, 25, 50]}
              rowCount={record}
              pageSize={pageSize}
              page={page - 1}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              onPageChange={(newPageNo) => setPage(newPageNo + 1)}
              paginationMode="server"
              disableSelectionOnClick
              checkboxSelection={false}
              sortingMode="server"
              onSortModelChange={sorting}
            />
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default InquiryPageItem;
