import axios from "axios";
import {
  GET_STEPS,
  GET_STEPS_DETAILS,
  POST_STEPS,
  PUT_STATUS_STEPS,
  PUT_STEPS_ORDER,
} from "..";

/**
 * get steps
 * @param {Parameters} paramObj
 * @returns
 */
export const getSteps = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_STEPS}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * enable-disable steps
 * @param {body} body
 * @returns
 */
export const updateStatus = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_STATUS_STEPS}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * create steps
 * @param {body} body
 * @returns
 */
export const postSteps = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(`${process.env.REACT_APP_API_URL}${POST_STEPS}`, body, {
    headers: { Authorization: jwt_token },
  });
};

/**
 * update steps
 * @param {body} body
 * @returns
 */
export const putUpdateSteps = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(`${process.env.REACT_APP_API_URL}${POST_STEPS}`, body, {
    headers: { Authorization: jwt_token },
    params: { stepId: body.stepId },
  });
};

/**
 * delete steps
 * @param {id} stepId
 * @returns
 */
export const deleteSteps = (stepId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${POST_STEPS}`, {
    headers: { Authorization: jwt_token },
    params: { stepId },
  });
};

/**
 * get steps
 * @param {id} stepId
 * @returns
 */
export const getStepsDetails = (stepId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_STEPS_DETAILS}`, {
    headers: { Authorization: jwt_token },
    params: { stepId },
  });
};

/**
 * short order steps
 * @param {body} updateOrder
 * @returns
 */
export const sort_steps = (updateOrder) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_STEPS_ORDER}`,
    { updateOrder: updateOrder },
    {
      headers: { Authorization: jwt_token },
    }
  );
};
