import React, { useContext } from "react";
import { Col, Container, Input, Label, Row } from "reactstrap";
import { maxLengthCheck } from "../../../../utils/helper";
import { Switch } from "@material-ui/core";
import VideosContext from "../../../context/VideosContext/VideosContext";

const VideosParentPopup = (props) => {
  const { formDataVideos, setFormDataVideos } = useContext(VideosContext);
  const { simpleValidator } = props;
  /**
   * input value change
   * @param {event} e
   */
  const InputValueChange = (e) => {
    const { value, name, checked } = e.target;
    setFormDataVideos((prev) => {
      if (name === "isYoutube") {
        return {
          ...prev,
          [name]: checked,
        };
      } else {
        return {
          ...prev,
          [name]: value,
        };
      }
    });
  };

  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="12">
            <div className="mt-4 d-flex flex-column justify-content-center ">
              <h6>Add New</h6>
              <Row className="mt-4">
                <Col md="12 mb-1">
                  <Label htmlFor="validationCustom03">{"Title *"}</Label>
                  <Input
                    className="form-control"
                    name="title"
                    type="text"
                    placeholder="Title *"
                    value={formDataVideos.title}
                    onChange={(e) => InputValueChange(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    min={0}
                    maxLength={50}
                    onInput={maxLengthCheck}
                  />
                  <div className="text-end mt-3 ">
                    <span>{formDataVideos.title.length} / 50</span>
                  </div>
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "title",
                      formDataVideos.title,
                      "required"
                    )}
                  </p>
                </Col>
                <Col md="6 mb-3">
                  <Label className="d-block" for="edo-ani">
                    <Switch
                      checked={formDataVideos.isYoutube}
                      inputProps={{
                        "aria-label": "controlled",
                      }}
                      onChange={(e) => InputValueChange(e)}
                      color="primary"
                      name="isYoutube"
                      id="edo-ani"
                      size="medium"
                    />
                    {"Is Youtube URL"}
                  </Label>
                </Col>
                {/* <Col md="6 mb-3">
                  <Label className="d-block" for="edo-ani1">
                    <Switch
                      checked={formDataVideos.isAlbum}
                      inputProps={{
                        "aria-label": "controlled",
                      }}
                      onChange={(e) => InputValueChange(e)}
                      color="primary"
                      name="isAlbum"
                      id="edo-ani1"
                      size="medium"
                    />
                    {"Is Album "}
                  </Label>
                </Col> */}
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Description "}</Label>
                  <textarea
                    className="form-control"
                    name="description"
                    type="text"
                    value={formDataVideos.description}
                    onChange={(e) => InputValueChange(e)}
                    placeholder="Description"
                    rows={5}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default VideosParentPopup;
