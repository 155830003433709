import React, { useContext } from "react";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import { Border, TextAlign, fontWeight } from "../../../../constant";
import GalleryMultipageContext from "../../../context/GalleryMultipageContext/GalleryMultipageContext";
import { OpenModalCommonFunc } from "../../../utils";
import { Cases, DELETE_CONFIRM } from "../../../constant";
import { delete_Multi_Image_Permanent } from "../apis/SliderApi/SliderApi";
import { Zoom, toast } from "react-toastify";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import { Close } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { UploadCloud } from "react-feather";
import Switch from "@material-ui/core/Switch";
import Dividers from "../../../common/divider";
import { SketchPicker } from "react-color";
import PaginationButtonStyle from "../../../common/paginationButtonStyle";
import CommonColor from "../../../common/CommonColor";
import CustomStyle from "../../../common/CustomStyle";

const OurTeamTheme2 = (props) => {
  const {
    isEdit,
    settingFormData,
    setSettingFormData,
    backImageToggle,
    setBackImageToggle,
    boxSection,
    setBoxSection,
    isBackgroundColor,
    setIsBackgroundColor,
  } = useContext(GalleryMultipageContext);
  const { setLoaderShow } = useContext(LoaderContext);
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);

  const { imageUpload, handleSubmitSetting } = props;

  /**
   * input value change
   * @param {event} e
   */

  const handleChangeCertificatesSection = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".certificates-section": {
          ...prev[".certificates-section"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeCertificatesSectionTitleArea = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".certificates-section .section-title-area": {
          ...prev[".certificates-section .section-title-area"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeCertificatesSectionSubtitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".certificates-section .section-title-area .section-subtitle": {
          ...prev[
            ".certificates-section .section-title-area .section-subtitle"
          ],
          [name]: value,
        },
      };
    });
  };
  const handleChangeCertificatesSectionSubtitleTitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".certificates-section .section-title-area .section-title": {
          ...prev[".certificates-section .section-title-area .section-title"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeCertificatesSectionCatList = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".certificates-section .certificates-list .box": {
          ...prev[".certificates-section .certificates-list .box"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeCertificatesSectionCatListBtn = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".certificates-section .certificates-list .certificates-img::before": {
          ...prev[
            ".certificates-section .certificates-list .certificates-img::before"
          ],
          [name]: value,
        },
      };
    });
  };

  const handleChangeCertificatesSectionCatListActive = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".certificates-section .certificates-list .box:hover .certificates-img::before":
          {
            ...prev[
              ".certificates-section .certificates-list .box:hover .certificates-img::before"
            ],
            [name]: value,
          },
      };
    });
  };
  const handleChangeCertificatesSectionCatDataBox = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".certificates-section .certificates-list .title-text": {
          ...prev[".certificates-section .certificates-list .title-text"],
          [name]: value,
        },
      };
    });
  };

  const handleChangePhotoGallerySectionCatList = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".gallery-catlist": {
          ...prev[".gallery-catlist"],
          [name]: value,
        },
      };
    });
  };

  const handleChangePhotoGallerySectionCatListBtn = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".gallery-catlist .btn": {
          ...prev[".gallery-catlist .btn"],
          [name]: value,
        },
      };
    });
  };

  const handleChangePhotoGallerySectionCatListActive = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".gallery-catlist .active": {
          ...prev[".gallery-catlist .active"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeCustomStyle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  /**
   * delete image permanently
   */
  const deleteImagePermanent = async () => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData = {
          fileName: settingFormData?.[".certificates-section.bg-image"]?.[
            "background-image"
          ]
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
            .pop(),
        };
        const { data } = await delete_Multi_Image_Permanent(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            position: "top-center",
          });
          setSettingFormData({
            ...settingFormData,
            ".certificates-section.bg-image": { "background-image": "" },
          });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };
  /**
   * image reupload
   */
  const reupload = () => {
    setSettingFormData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };

  const handleShowItem = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [name]: +value,
      };
    });
  };
  return (
    <>
      <Row className="mt-4">
        <h4 className="mb-3">Gallery Item Show (Default : Item 4)</h4>
        <Col md="4 mb-3">
          {/* <Label htmlFor="validationCustom03">{"Font Weight"}</Label> */}
          <select
            name="showItem"
            id="font-weight"
            className="form-select"
            value={settingFormData?.["showItem"]}
            onChange={(e) => handleShowItem(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            <option value={3}>item 3</option>
            <option value={4}>item 4</option>
          </select>
        </Col>
      </Row>
      <Row className="mt-4">
        <h4 className="mb-3">Make change in main Section</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 100)"}
          </Label>
          <Input
            className="form-control"
            name="padding"
            type="number"
            placeholder="Padding"
            value={settingFormData?.[".certificates-section"]?.["padding"]
              ?.split(" ")[0]
              ?.replace(/px/g, "")}
            onChange={(e) => handleChangeCertificatesSection(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <div className="d-flex align-items-center">
            <Label htmlFor="validationCustom03">
              {isBackgroundColor
                ? "Add Background Color"
                : "Remove Background Color"}
            </Label>
            <Switch
              checked={isBackgroundColor}
              inputProps={{ "aria-label": "controlled" }}
              onChange={(e) => {
                setIsBackgroundColor(e.target.checked);
                setSettingFormData((prev) => {
                  if (e.target.checked) {
                    if (boxSection) {
                      return {
                        ...prev,
                        [".certificates-section"]: {
                          "background-color": {
                            r: 255,
                            g: 255,
                            b: 255,
                            a: 100,
                          },
                          padding:
                            settingFormData?.[".certificates-section"]?.[
                              "padding"
                            ],
                          width: settingFormData?.[".certificates-section"]?.[
                            "width"
                          ]?.replace("rem)", ""),
                          margin: settingFormData?.[".certificates-section"]?.[
                            "margin"
                          ]
                            ?.split(" ")[0]
                            ?.replace(/px/g, ""),
                          "border-radius": settingFormData?.[
                            ".certificates-section"
                          ]?.["border-radius"]?.replace(/rem/g, ""),
                        },
                      };
                    } else {
                      return {
                        ...prev,
                        [".certificates-section"]: {
                          "background-color": {
                            r: 255,
                            g: 255,
                            b: 255,
                            a: 100,
                          },
                          padding:
                            settingFormData?.[".certificates-section"]?.[
                              "padding"
                            ],
                        },
                      };
                    }
                  } else {
                    if (boxSection) {
                      return {
                        ...prev,
                        [".certificates-section"]: {
                          padding:
                            settingFormData?.[".certificates-section"]?.[
                              "padding"
                            ],
                          width: settingFormData?.[".certificates-section"]?.[
                            "width"
                          ]?.replace("rem)", ""),
                          margin: settingFormData?.[".certificates-section"]?.[
                            "margin"
                          ]
                            ?.split(" ")[0]
                            ?.replace(/px/g, ""),
                          "border-radius": settingFormData?.[
                            ".certificates-section"
                          ]?.["border-radius"]?.replace(/rem/g, ""),
                        },
                      };
                    } else {
                      return {
                        ...prev,
                        [".certificates-section"]: {
                          padding:
                            settingFormData?.[".certificates-section"]?.[
                              "padding"
                            ],
                        },
                      };
                    }
                  }
                });
              }}
            />
          </div>
          {isBackgroundColor && (
            <CommonColor
              color={
                settingFormData?.[".certificates-section"]?.["background-color"]
              }
              setSettingFormData={setSettingFormData}
              className={".certificates-section"}
              classProperty={"background-color"}
              label={"Select Background Color"}
            />
          )}
        </Col>
        <p>{"Box Section : convert your section to Box section"}</p>
        <div>
          <Switch
            checked={boxSection}
            inputProps={{ "aria-label": "controlled" }}
            onChange={(e) => {
              setBoxSection(e.target.checked);
              setSettingFormData((prev) => {
                return {
                  ...prev,
                  ".certificates-section": {
                    padding: settingFormData?.[".certificates-section"]?.[
                      "padding"
                    ]
                      ?.split(" ")[0]
                      ?.replace(/px/g, ""),
                    "background-color":
                      settingFormData?.[".certificates-section"]?.[
                        "background-color"
                      ],
                    width: e.target.checked ? "10" : "",
                    margin: e.target.checked ? "0" : "",
                    "border-radius": e.target.checked ? "3" : "",
                  },
                };
              });
            }}
          />
        </div>
        {boxSection && (
          <>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Width (Default : 10)"}
              </Label>
              <Input
                className="form-control"
                name="width"
                type="number"
                placeholder="width"
                value={settingFormData?.[".certificates-section"]?.[
                  "width"
                ]?.replace("rem)", "")}
                onChange={(e) => handleChangeCertificatesSection(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Margin (Default : 0)"}
              </Label>
              <Input
                className="form-control"
                name="margin"
                type="number"
                placeholder="margin"
                value={settingFormData?.[".certificates-section"]?.["margin"]
                  ?.split(" ")[0]
                  ?.replace(/px/g, "")}
                onChange={(e) => handleChangeCertificatesSection(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Border Radius (Default : 3)"}
              </Label>
              <Input
                className="form-control"
                name="border-radius"
                type="number"
                placeholder="Border Radius"
                value={settingFormData?.[".certificates-section"]?.[
                  "border-radius"
                ]?.replace(/rem/g, "")}
                onChange={(e) => handleChangeCertificatesSection(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
          </>
        )}
      </Row>
      <hr />
      <Row className="mt-4">
        <Col md="12 mb-3">
          <h4 className="mb-3">Add Background Image in section</h4>

          <p>do you want to upload Background image</p>
          <Switch
            checked={backImageToggle}
            inputProps={{ "aria-label": "controlled" }}
            onChange={(e) => {
              setBackImageToggle(e.target.checked);
              setSettingFormData((prev) => {
                if (e.target.checked) {
                  return {
                    ...prev,
                    image: "",
                    ".certificates-section.bg-image": {},
                    ".certificates-section.bg-image::before": {
                      "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
                    },
                  };
                } else {
                  return {
                    ...prev,
                    image: "",
                    ".certificates-section.bg-image": {},
                    ".certificates-section.bg-image::before": {},
                  };
                }
              });
            }}
          />
          {backImageToggle && (
            <>
              {" "}
              <Col md="6 mb-3">
                <Dividers divide="IMAGE" />
                <p className="mb-4">
                  File type required JPG, PNG (1905px X 706px)
                </p>
              </Col>
              <Row>
                <Col md="4 mb-3">
                  <div
                    className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    <span
                      onClick={() =>
                        OpenModalCommonFunc(
                          Cases.PRODUCT_UPLOAD,
                          setAllPopupState
                        )
                      }
                    >
                      <UploadCloud />
                    </span>
                    {settingFormData?.[".certificates-section.bg-image"]?.[
                      "background-image"
                    ] && (
                      <img
                        src={
                          settingFormData?.[".certificates-section.bg-image"]?.[
                            "background-image"
                          ] || ""
                        }
                        alt=""
                        style={{
                          width: "85%",
                          height: "90px",
                          objectFit: "contain",
                          position: "absolute",
                        }}
                        onClick={() =>
                          OpenModalCommonFunc(
                            Cases.PRODUCT_UPLOAD,
                            setAllPopupState
                          )
                        }
                      />
                    )}
                    {settingFormData?.[".certificates-section.bg-image"]?.[
                      "background-image"
                    ] && (
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="close"
                        style={{
                          color: "red",
                          position: "absolute",
                          top: "-22px",
                          left: "1px",
                        }}
                        onClick={() => deleteImagePermanent()}
                      >
                        <Close
                          style={{
                            fontSize: "25px",
                            fontWeight: "bold",
                          }}
                        />
                      </IconButton>
                    )}
                  </div>
                </Col>
                <Col md="6 mb-3">
                  <h4 className="mb-3">Add Background color in section</h4>
                  <h4 className="mb-3"></h4>
                  <Col md="6 mb-3">
                    <CommonColor
                      color={
                        settingFormData?.[
                          ".certificates-section.bg-image::before"
                        ]?.["background-color"]
                      }
                      setSettingFormData={setSettingFormData}
                      className={".certificates-section.bg-image::before"}
                      classProperty={"background-color"}
                      label={"Select Background Color"}
                    />
                    {/* <Input
                              className="form-control"
                              name="background-color"
                              type="color"
                              placeholder="Background Color"
                              value={
                                settingFormData?.[
                                  ".certificates-section.bg-image::before"
                                ]?.["background-color"]
                              }
                              onChange={(e) =>
                                handleChangeCertificatesSectionBgImageBefore(e)
                              }
                              style={{ borderRadius: "0", color: "black" }}
                            /> */}
                  </Col>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make space in Title bottom</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin bottom (Default : 50)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin bottom"
            value={settingFormData?.[
              ".certificates-section .section-title-area"
            ]?.["margin-bottom"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeCertificatesSectionTitleArea(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in section subtitle</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 16)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".certificates-section .section-title-area .section-subtitle"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeCertificatesSectionSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-control"
            value={
              settingFormData?.[
                ".certificates-section .section-title-area .section-subtitle"
              ]?.["font-weight"]
            }
            onChange={(e) => handleChangeCertificatesSectionSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".certificates-section .section-title-area .section-subtitle"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".certificates-section .section-title-area .section-subtitle"
            }
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".certificates-section .section-title-area .section-subtitle"
              ]?.["background-color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".certificates-section .section-title-area .section-subtitle"
            }
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
          {/* <Input
                    className="form-control"
                    name="background-color"
                    type="color"
                    placeholder="Background Color"
                    value={
                      settingFormData?.[
                        ".certificates-section .section-title-area .section-subtitle"
                      ]?.["background-color"]
                    }
                    onChange={(e) => handleChangeCertificatesSectionSubtitle(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  /> */}
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 5 - 20)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="p1"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".certificates-section .section-title-area .section-subtitle"
              ]?.["p1"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeCertificatesSectionSubtitle(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p2"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".certificates-section .section-title-area .section-subtitle"
              ]?.["p2"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeCertificatesSectionSubtitle(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border Radius (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[
              ".certificates-section .section-title-area .section-subtitle"
            ]?.["border-radius"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeCertificatesSectionSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin bottom (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin bottom"
            value={settingFormData?.[
              ".certificates-section .section-title-area .section-subtitle"
            ]?.["margin-bottom"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeCertificatesSectionSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in gallery category list</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Text Align (Default : center)"}
          </Label>
          <select
            name="text-align"
            id="text-align"
            className="form-control"
            value={settingFormData?.[".gallery-catlist"]?.["text-align"]}
            onChange={(e) => handleChangePhotoGallerySectionCatList(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {TextAlign.map((elem) => {
              return (
                <option value={elem["text-align"]}>{elem["text-align"]}</option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin bottom (Default : 30)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin bottom"
            value={settingFormData?.[".gallery-catlist"]?.[
              "margin-bottom"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangePhotoGallerySectionCatList(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in gallery category list Button</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border (Default : 2 solid pink)"}
          </Label>
          <div className="d-flex">
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="borderPx"
                type="number"
                placeholder="px"
                value={settingFormData?.[".gallery-catlist .btn"]?.[
                  "borderPx"
                ]?.replace(/px/g, "")}
                onChange={(e) => handleChangePhotoGallerySectionCatListBtn(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <select
                name="borderThik"
                id="borderThik"
                className="form-control"
                value={
                  settingFormData?.[".gallery-catlist .btn"]?.["borderThik"]
                }
                onChange={(e) => handleChangePhotoGallerySectionCatListBtn(e)}
                style={{ borderRadius: "0", color: "black" }}
              >
                <option value="">--select--</option>
                {Border.map((elem) => {
                  return <option value={elem.border}>{elem.border}</option>;
                })}
              </select>
            </div>
            <div>
              <CommonColor
                color={
                  settingFormData?.[".gallery-catlist .btn"]?.["borderColor"]
                }
                setSettingFormData={setSettingFormData}
                className={".gallery-catlist .btn"}
                classProperty={"borderColor"}
                label={"Border Color"}
              />
            </div>
          </div>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={settingFormData?.[".gallery-catlist .btn"]?.["color"]}
            setSettingFormData={setSettingFormData}
            className={".gallery-catlist .btn"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 6 - 20)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="p1"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[".gallery-catlist .btn"]?.[
                "p1"
              ]?.replace(/px/g, "")}
              onChange={(e) => handleChangePhotoGallerySectionCatListBtn(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p2"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[".gallery-catlist .btn"]?.[
                "p2"
              ]?.replace(/px/g, "")}
              onChange={(e) => handleChangePhotoGallerySectionCatListBtn(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Margin (Default : 5)"}</Label>
          <Input
            className="form-control"
            name="margin"
            type="number"
            placeholder="Margin"
            value={settingFormData?.[".gallery-catlist .btn"]?.["margin"]
              ?.split(" ")[0]
              ?.replace(/px/g, "")}
            onChange={(e) => handleChangePhotoGallerySectionCatListBtn(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">
          Make change in gallery category list Button Active
        </h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".gallery-catlist .active"]?.[
                "background-color"
              ]
            }
            setSettingFormData={setSettingFormData}
            className={".gallery-catlist .active"}
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={settingFormData?.[".gallery-catlist .active"]?.["color"]}
            setSettingFormData={setSettingFormData}
            className={".gallery-catlist .active"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in section Title</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 44)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".certificates-section .section-title-area .section-title"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeCertificatesSectionSubtitleTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-control"
            value={
              settingFormData?.[
                ".certificates-section .section-title-area .section-title"
              ]?.["font-weight"]
            }
            onChange={(e) => handleChangeCertificatesSectionSubtitleTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="2 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".certificates-section .section-title-area .section-title"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".certificates-section .section-title-area .section-title"
            }
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in certificates section List Box</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin bottom (Default : 20)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin bottom"
            value={settingFormData?.[
              ".certificates-section .certificates-list .box"
            ]?.["margin-bottom"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeCertificatesSectionCatList(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".certificates-section .certificates-list .box"
              ]?.["background-color"]
            }
            setSettingFormData={setSettingFormData}
            className={".certificates-section .certificates-list .box"}
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Box Shadow (Default : 0 5 10 black)"}
          </Label>
          <div className="d-flex">
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="bxs1"
                type="number"
                placeholder=""
                value={settingFormData?.[
                  ".certificates-section .certificates-list .box"
                ]?.["bxs1"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeCertificatesSectionCatList(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="bxs2"
                type="number"
                placeholder=""
                value={settingFormData?.[
                  ".certificates-section .certificates-list .box"
                ]?.["bxs2"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeCertificatesSectionCatList(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div>
              <CommonColor
                color={
                  settingFormData?.[
                    ".certificates-section .certificates-list .box"
                  ]?.["bxsColor"]
                }
                setSettingFormData={setSettingFormData}
                className={".certificates-section .certificates-list .box"}
                classProperty={"bxsColor"}
                label={"Shadow Color"}
              />
            </div>
          </div>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border radius (Default : 0)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="Border radius"
            value={settingFormData?.[
              ".certificates-section .certificates-list .box"
            ]?.["border-radius"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeCertificatesSectionCatList(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">
          Make change in certificates section List Img::before
        </h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Opacity (Default : 0)"}</Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="opacity"
              type="number"
              placeholder="px"
              value={
                settingFormData?.[
                  ".certificates-section .certificates-list .certificates-img::before"
                ]?.["opacity"]
              }
              onChange={(e) => handleChangeCertificatesSectionCatListBtn(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".certificates-section .certificates-list .certificates-img::before"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".certificates-section .certificates-list .certificates-img::before"
            }
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 30)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="font-size"
              type="number"
              placeholder="font-size"
              value={settingFormData?.[
                ".certificates-section .certificates-list .certificates-img::before"
              ]?.["font-size"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeCertificatesSectionCatListBtn(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">
          Make change in certificates section List box:hover
        </h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Opacity (Default : 1)"}</Label>
          <Input
            className="form-control"
            name="opacity"
            type="number"
            placeholder="opacity"
            value={
              settingFormData?.[
                ".certificates-section .certificates-list .box:hover .certificates-img::before"
              ]?.["opacity"]
            }
            onChange={(e) => handleChangeCertificatesSectionCatListActive(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in gallery category list Title</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 18)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".certificates-section .certificates-list .title-text"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeCertificatesSectionCatDataBox(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Padding (Default : 15)"}</Label>
          <Input
            className="form-control"
            name="padding"
            type="number"
            placeholder="Padding"
            value={settingFormData?.[
              ".certificates-section .certificates-list .title-text"
            ]?.["padding"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeCertificatesSectionCatDataBox(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".certificates-section .certificates-list .title-text"
              ]?.["background-color"]
            }
            setSettingFormData={setSettingFormData}
            className={".certificates-section .certificates-list .title-text"}
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".certificates-section .certificates-list .title-text"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".certificates-section .certificates-list .title-text"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Text Align (Default : center)"}
          </Label>
          <select
            name="text-align"
            id="text-align"
            className="form-select"
            value={
              settingFormData?.[
                ".certificates-section .certificates-list .title-text"
              ]?.["text-align"]
            }
            onChange={(e) => handleChangeCertificatesSectionCatDataBox(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {TextAlign.map((elem) => {
              return (
                <option value={elem["text-align"]} key={elem["text-align"]}>
                  {elem["text-align"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[
                ".certificates-section .certificates-list .title-text"
              ]?.["font-weight"]
            }
            onChange={(e) => handleChangeCertificatesSectionCatDataBox(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
      </Row>
      <PaginationButtonStyle
        mainClass={".certificates-section"}
        settingFormData={settingFormData}
        setSettingFormData={setSettingFormData}
      />
      <CustomStyle
        handleChange={handleChangeCustomStyle}
        value={settingFormData?.custom}
      />
      <ProductImageUploadPopup
        allPopupState={allPopupState}
        setAllPopupState={setAllPopupState}
        crop={crop}
        setCrop={setCrop}
        completedCrop={completedCrop}
        setCompletedCrop={setCompletedCrop}
        scale={scale}
        rotate={rotate}
        image={settingFormData?.image}
        setImage={setSettingFormData}
        imageUpload={imageUpload}
        isEdit={isEdit}
        reupload={reupload}
        // aspect={2.7 / 1}
      />
    </>
  );
};

export default OurTeamTheme2;
