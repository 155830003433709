import { IconButton, Tooltip } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { DataGrid } from "@mui/x-data-grid";
import React, { useContext, useMemo, useState } from "react";
import { Card, CardHeader, Col, Container, Row } from "reactstrap";
import { MOMENT_SETTING, SORTING_ORDER, Style } from "../../constant";
import DataNotFound from "../../DataNoteFound/DataNotFound";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import { TabelAnimation } from "../../Spinner";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { MuiTabelStyle } from "../../utils/MuiTabelStyle";
import moment from "moment-timezone";
import SettingMiniWebContext from "../../context/SettingMiniWebContext/SettingMiniWebContext";
import { tableId } from "../../utils/helper";
import { ArrowLeft, ArrowRight, Trash2 } from "react-feather";
import { Zoom, toast } from "react-toastify";
import {
  DetailWebNavBarMap,
  WebNavBarMapListDelete,
} from "../../api/SupperAdminOrganizationApi/Organization";

const SettingMiniWebItemWebNavBarMap = (props) => {
  const {
    pageNumberNav,
    pageSizeNav,
    sortOrderNav,
    setPageNumberNav,
    setPageSizeNav,
    setSortOrderNav,
    setSortByNav,
    formvalueNav,
    recordNav,
    setModalNav,
    setToggleSubmitNav,
    setformValueWebNavNull,
    setFormDataWebNavbar,
  } = useContext(SettingMiniWebContext);
  const [prevValue, setPrevValue] = useState({});
  let navMapIdArr = [];

  const { WebNavBarMap_List, orgId } = props;

  const { tabelAnimation } = useContext(LoaderContext);

  /**
   * Add Profile Function call
   */

  const AddProfile = () => {
    setToggleSubmitNav(false);
    setModalNav(true);
  };

  useMemo(() => {
    tableId(pageNumberNav, pageSizeNav, formvalueNav);
  }, [pageNumberNav, pageSizeNav, formvalueNav]);

  const handleDelete = async (navMapId) => {
    let confirmation = window.confirm("Are you sure you want to delete?");
    if (confirmation) {
      try {
        let paramObj = {
          navMapId,
        };
        const { data } = await WebNavBarMapListDelete(paramObj);
        if (data.status === 200) {
          WebNavBarMap_List({ orgId: orgId });
          toast.success(data.message, {
            autoClose: 1000,
            transition: Zoom,
          });
        }
      } catch (error) {
        toast.error(error?.response?.data?.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    }
  };

  const NavMapFilter = (parentDetail) => {
    if (
      parentDetail !== undefined &&
      parentDetail !== null &&
      parentDetail?.navMapId !== null &&
      parentDetail?.parentDetail !== null
    ) {
      NavMapFilter(parentDetail.parentDetail);
    }
    if (parentDetail?.navMapId !== null && parentDetail?.navMapId !== undefined)
      navMapIdArr.push({ parentId: parentDetail?.navMapId });
  };

  const EditHandle = async (navMapId) => {
    try {
      setToggleSubmitNav(true);
      let paramObj = {
        navMapId,
      };
      const { data } = await DetailWebNavBarMap(paramObj);
      if (data.status === 200) {
        let payload = data.payload.data;
        const response = await WebNavBarMap_List({
          orgId: orgId,
          isParent: true,
        });
        setFormDataWebNavbar({
          navMapId,
          orgId: orgId,
          navBars: [{ value: payload.navId, label: payload.label }],
        });
        NavMapFilter(payload);
        let newArr = [];
        const createSelectedValue = (i, arr) => {
          if (arr !== undefined && arr?.length) {
            let navMapIdValue = navMapIdArr[i]?.parentId;
            newArr.push({ parentId: navMapIdValue, data: arr });

            let findValue = arr.find((elem) => elem.navMapId === navMapIdValue);
            if (findValue?.subMenu && findValue.subMenu?.length) {
              createSelectedValue(i + 1, findValue.subMenu);
            }
          }
        };
        setformValueWebNavNull(newArr);
        createSelectedValue(0, response);
        setModalNav(true);
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  const handleNextAndPrevChild = ({ navMapId, parentId, condition }) => {
    if (condition === "prev") {
      let paramObj = {
        orgId: orgId,
      };
      if (Object.keys(prevValue)?.length === 1) {
        paramObj["parentId"] = null;
      } else {
        paramObj["parentId"] = prevValue[parentId];
      }
      WebNavBarMap_List(paramObj);
      let convertParentIdNumToString = String(parentId);
      if (prevValue.hasOwnProperty(convertParentIdNumToString)) {
        let removeKey = { ...prevValue };
        delete removeKey[parentId];
        setPrevValue(removeKey);
      }
    } else {
      setPrevValue((prev) => {
        return {
          ...prev,
          [navMapId]: parentId,
        };
      });
      WebNavBarMap_List({
        orgId: orgId,
        parentId: navMapId,
      });
    }
  };

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 100,
      field: "sNo",
      headerName: "S.No.",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      flex: 0.2,
      minWidth: 250,
      field: "label",
      headerName: "Label",
      renderCell: (params) => (
        <>{params.row?.navbarDetail ? params.row?.navbarDetail?.label : "-"}</>
      ),
    },
    {
      flex: 0.2,
      minWidth: 250,
      field: "path",
      headerName: "Path",
      renderCell: (params) => (
        <>{params.row?.navbarDetail ? params.row?.navbarDetail?.path : "-"}</>
      ),
    },

    {
      flex: 0.1,
      minWidth: 100,
      field: "createdAt",
      headerName: "Date",
      renderCell: (params) =>
        params.row.createdAt &&
        moment.utc(params.row.createdAt).format(MOMENT_SETTING.FORMATE),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "edit",
      headerName: "Edit",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <>
          <Tooltip title="Edit">
            <IconButton
              aria-label="edit"
              style={{ color: "green" }}
              onClick={() => EditHandle(params.row.navMapId)}
            >
              <ModeEditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton
              aria-label="edit"
              style={{ color: "red" }}
              onClick={() => handleDelete(params.row.navMapId)}
            >
              <Trash2 />
            </IconButton>
          </Tooltip>
          {Object.keys(prevValue)?.length ? (
            <Tooltip title="Prev">
              <IconButton
                aria-label="edit"
                style={{ color: "red" }}
                onClick={() =>
                  handleNextAndPrevChild({
                    navMapId: params.row.navMapId,
                    parentId: params.row.parentId,
                    condition: "prev",
                  })
                }
              >
                <ArrowLeft />
              </IconButton>
            </Tooltip>
          ) : (
            ""
          )}
          {params?.row?.subMenu?.length ? (
            <Tooltip title="Next">
              <IconButton
                aria-label="edit"
                style={{ color: "red" }}
                onClick={() =>
                  handleNextAndPrevChild({
                    navMapId: params.row.navMapId,
                    parentId: params.row.parentId,
                    condition: "next",
                  })
                }
              >
                <ArrowRight />
              </IconButton>
            </Tooltip>
          ) : (
            ""
          )}
        </>
      ),
    },
  ];

  const sorting = (col) => {
    if (sortOrderNav === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortByNav(found.field);
      setSortOrderNav(SORTING_ORDER.ASC);
    }
    if (sortOrderNav === SORTING_ORDER.ASC) {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortByNav(found.field);
      setSortOrderNav(SORTING_ORDER.DSC);
    }
    if (sortOrderNav === SORTING_ORDER.DSC) {
      setSortByNav();
      setSortOrderNav("");
    }
  };

  return (
    <Container fluid={true}>
      <Row>
        <Col sm="12">
          <Card>
            <CardHeader
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h3>{"Navbar Map"}</h3>
              <div
                aria-label="add"
                onClick={() => AddProfile()}
                className="btn btn-primary"
                style={Style}
              >
                <Add />
              </div>
            </CardHeader>
            {tabelAnimation === true ? (
              <TabelAnimation />
            ) : formvalueNav.length ? (
              <DataGrid
                sx={MuiTabelStyle}
                autoHeight
                rows={formvalueNav}
                getRowId={(row) => row.navMapId}
                columns={defaultColumns}
                rowsPerPageOptions={[5, 10, 25, 50]}
                pagination
                rowCount={recordNav}
                pageSize={pageSizeNav}
                page={pageNumberNav - 1}
                onPageSizeChange={(newPageSize) => setPageSizeNav(newPageSize)}
                onPageChange={(newPageNo) => setPageNumberNav(newPageNo + 1)}
                paginationMode="server"
                disableSelectionOnClick
                checkboxSelection={false}
                sortingMode="server"
                onSortModelChange={sorting}
              />
            ) : (
              <DataNotFound />
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SettingMiniWebItemWebNavBarMap;
