import axios from "axios";
import {
  GET_PACKAGES,
  GET_PACKAGES_DETAILS,
  POST_PACKAGES,
  PUT_PACKAGES_ORDER,
  PUT_STATUS_PACKAGES,
} from "..";

/**
 * get packages
 * @param {Parameters} paramObj
 * @returns
 */
export const getPackages = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_PACKAGES}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * enable-disable packages
 * @param {body} body
 * @returns
 */
export const updateStatus = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_STATUS_PACKAGES}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * create packages
 * @param {body} body
 * @returns
 */
export const postPackages = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(`${process.env.REACT_APP_API_URL}${POST_PACKAGES}`, body, {
    headers: { Authorization: jwt_token },
  });
};

/**
 * update packages
 * @param {body} body
 * @returns
 */
export const putUpdatePackages = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(`${process.env.REACT_APP_API_URL}${POST_PACKAGES}`, body, {
    headers: { Authorization: jwt_token },
    params: { packageId: body.packageId },
  });
};

/**
 * delete packages
 * @param {id} packageId
 * @returns
 */
export const deletePackages = (packageId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${POST_PACKAGES}`, {
    headers: { Authorization: jwt_token },
    params: { packageId },
  });
};

/**
 * get packages
 * @param {id} packageId
 * @returns
 */
export const getPackagesDetails = (packageId, navMapId) => {
  const jwt_token = localStorage.getItem("token");
  let paramObj = {
    packageId,
  };
  if (navMapId) {
    paramObj["navMapId"] = navMapId;
  }
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_PACKAGES_DETAILS}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * short order packages
 * @param {body} updateOrder
 * @returns
 */
export const sort_order_packages = (updateOrder) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_PACKAGES_ORDER}`,
    { updateOrder: updateOrder },
    {
      headers: { Authorization: jwt_token },
    }
  );
};
