import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Input, Label, Row } from "reactstrap";
import { getNavbarList } from "../apis/AboutApi/AboutUsApi";
import {
  delete_Image_Permanent,
  delete_Multi_Image_Permanent,
} from "../apis/SliderApi/SliderApi";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import ItemFeatureContext from "../../../context/ItemFeatureContext/ItemFeatureContext";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import Dividers from "../../../common/divider";
import { Close } from "@material-ui/icons";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { Cases, DELETE_CONFIRM } from "../../../constant";
import { UploadCloud } from "react-feather";
import { IconButton, Switch } from "@material-ui/core";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import IsShowTitleORNot from "../../../common/isShowTitleORNot";

const ItemFeatureParentPopup = (props) => {
  const { setFormData, fromData, isEdit } = useContext(ItemFeatureContext);
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const { setLoaderShow } = useContext(LoaderContext);
  const { simpleValidator, imageUpload } = props;

  /**
   * reupload image
   */
  const reupload = () => {
    setFormData((prev) => {
      return {
        ...prev,
        image: "",
        featureImage: "",
      };
    });
  };

  /**
   * input value change
   * @param {event} e
   */
  const changeFormValue = (e) => {
    const { value, name, checked } = e.target;
    setFormData((prev) => {
      if (name === "isTitleShow") {
        return {
          ...prev,
          [name]: checked,
        };
      } else {
        return {
          ...prev,
          [name]: value,
        };
      }
    });
  };

  const deleteImagePermanent = async () => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData = {
          fileName: fromData.featureImage
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
            .pop(),
        };
        const { data } = await delete_Multi_Image_Permanent(formData);
        if (data.status === 200) {
          TostMessage({ message: data, type: "success", position: "center" });
          setFormData((prev) => {
            return {
              ...prev,
              image: "",
              featureImage: "",
            };
          });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="12">
            <div className="mt-4 d-flex flex-column justify-content-center ">
              <h6>{isEdit ? "Edit" : "Add New"}</h6>
              <Row className="mt-4">
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Title *"}
                    <IsShowTitleORNot
                      checked={fromData?.isTitleShow}
                      onChange={changeFormValue}
                    />
                  </Label>
                  <Input
                    className="form-control"
                    name="title"
                    type="text"
                    placeholder="Title *"
                    value={fromData?.title}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    required
                  />
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "title",
                      fromData?.title,
                      "required"
                    )}
                  </p>
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Sub Title"}</Label>
                  <Input
                    className="form-control"
                    name="subTitle"
                    type="text"
                    placeholder="Sub Title"
                    value={fromData?.subTitle}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    required
                  />
                  {/* <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "title",
                      fromData?.subTitle,
                      "required"
                    )}
                  </p> */}
                </Col>
              </Row>
              <Dividers divide="FEATURE IMAGE" />
              {/* <p className="mb-4">
                File type required JPG, PNG (1080px X 1080px)
              </p> */}
              <Row>
                <Col md="6 mb-3">
                  <div
                    className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    {!fromData.featureImage && (
                      <span
                        onClick={() => {
                          OpenModalCommonFunc(
                            Cases.PRODUCT_UPLOAD,
                            setAllPopupState
                          );
                        }}
                      >
                        <UploadCloud />
                      </span>
                    )}

                    {fromData.featureImage && (
                      <img
                        src={fromData.featureImage}
                        alt=""
                        style={{
                          width: "80%",
                          height: "98%",
                          objectFit: "contain",
                          position: "absolute",
                        }}
                        onClick={() => {
                          deleteImagePermanent();
                        }}
                      />
                    )}
                    {fromData.featureImage && (
                      <>
                        {" "}
                        <IconButton
                          edge="start"
                          color="inherit"
                          aria-label="close"
                          style={{
                            color: "red",
                            position: "absolute",
                            top: "-22px",
                            left: "1px",
                          }}
                          onClick={() => deleteImagePermanent()}
                        >
                          <Close
                            style={{ fontSize: "25px", fontWeight: "bold" }}
                          />
                        </IconButton>
                      </>
                    )}
                  </div>
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "FEATURE IMAGE",
                      fromData.featureImage,
                      "required"
                    )}
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <ProductImageUploadPopup
          allPopupState={allPopupState}
          setAllPopupState={setAllPopupState}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
          image={fromData.image}
          setImage={setFormData}
          imageUpload={imageUpload}
          isEdit={isEdit}
          reupload={reupload}
          // aspect={1 / 1}
        />
      </Container>
    </>
  );
};

export default ItemFeatureParentPopup;
