import * as React from "react";
import { useState } from "react";
import HomePageContext from "./HomePageContext";

const HomePageState = (props) => {
  const [open, setOpen] = useState(false);
  const [follow, setFollw] = useState(false);
  const [about, setAbout] = useState(false);
  const [galleryPopup, setGalleryPopup] = useState(false);
  const [contact, setContact] = useState(false);
  const [orgName, setOrgName] = useState("");
  const [designation, setDesignation] = useState("");
  const [logo, setLogo] = useState("");
  const [formValue, setFormValue] = useState([]);
  const [socialItem, setSocialItem] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [pageUuid, setPageUuid] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [workPhone, setWorkPhone] = useState("");
  const [title, setTitle] = useState("");
  const [address, setAddress] = useState("");
  const [emialOrg, setEmailOrg] = useState("");
  const [orgUuid, setOrgUuid] = useState("");
  const [qrcode, setQrcode] = useState("");
  const [shareValue, setShareValue] = useState("");
  const [pageId, setPageId] = useState(0);
  const [showQr, setShowQr] = useState(false);
  const [sharePopup, setSharePopup] = useState(false);
  const [orgId, setOrgId] = useState("");

  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <HomePageContext.Provider
        value={{
          open,
          setOpen,
          follow,
          setFollw,
          about,
          setAbout,
          galleryPopup,
          setGalleryPopup,
          contact,
          setContact,
          orgName,
          setOrgName,
          designation,
          setDesignation,
          logo,
          setLogo,
          formValue,
          setFormValue,
          socialItem,
          setSocialItem,
          name,
          setName,
          email,
          setEmail,
          mobile,
          setMobile,
          subject,
          setSubject,
          message,
          setMessage,
          pageUuid,
          setPageUuid,
          fname,
          setFname,
          lname,
          setLname,
          workPhone,
          setWorkPhone,
          title,
          setTitle,
          address,
          setAddress,
          emialOrg,
          setEmailOrg,
          orgUuid,
          setOrgUuid,
          pageId,
          setPageId,
          showQr,
          setShowQr,
          qrcode,
          setQrcode,
          sharePopup,
          setSharePopup,
          shareValue,
          setShareValue,
          orgId,
          setOrgId,
          showModal, setShowModal
        }}
      >
        {props.children}
      </HomePageContext.Provider>
    </>
  );
};

export default HomePageState;
