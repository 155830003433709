import React, { useContext } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import { Cases, btnName, modalsName } from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc } from "../../../utils";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import CommonPopup from "../../../Modals/commonPopup";
import BannerWithTitleAndButtonPopup from "./BannerWithTitleAndButtonPopup";

const BannerWithTitleAndButton = () => {
  const { setAllPopupState, allPopupState } = useContext(
    ByteCardBuilderContext
  );
  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody className="support-ticket-font box-border-div">
            <Row>
              <div className="col-12">
                <CardBuilderHeaderSection name="Banner with Title & Button" />
              </div>
              <OpenPopupBtn
                openPopup={() =>
                  OpenModalCommonFunc(Cases.B_W_T_And_BUTTON, setAllPopupState)
                }
                name={btnName.B_W_T_And_BUTTON}
              />
            </Row>
          </CardBody>
        </Card>
      </Col>

      <CommonPopup
        open={allPopupState.B_W_T_And_Button_Popup}
        close={() =>
          OpenModalCommonFunc(Cases.B_W_T_And_BUTTON, setAllPopupState, false)
        }
        modalName={modalsName.B_W_T_And_BUTTON}
        modalCase={Cases.B_W_T_And_BUTTON}
        content={<BannerWithTitleAndButtonPopup />}
      />
    </>
  );
};

export default BannerWithTitleAndButton;
