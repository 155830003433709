import React, { useContext } from "react";
import { Col, Container, Input, Label, Row } from "reactstrap";
import { maxLengthCheck, slugUrlConverter } from "../../../../utils/helper";
import { Switch } from "@material-ui/core";
import VideosContext from "../../../context/VideosContext/VideosContext";
import IsShowTitleORNot from "../../../common/isShowTitleORNot";

const VideosParentPopup = (props) => {
  const { formDataVideos, setFormDataVideos, isVideoParent } =
    useContext(VideosContext);
  const { simpleValidator } = props;
  /**
   * input value change
   * @param {event} e
   */
  const InputValueChange = (e) => {
    const { value, name, checked } = e.target;
    setFormDataVideos((prev) => {
      if (name === "isTitleShow") {
        return {
          ...prev,
          [name]: checked,
        };
      } else if (name === "slugUrl") {
        return {
          ...prev,
          [name]: slugUrlConverter({ value }),
        };
      } else if (name === "title") {
        return {
          ...prev,
          [name]: value,
          ["slugUrl"]: slugUrlConverter({ value }),
        };
      } else if (name === "isYoutube") {
        return {
          ...prev,
          [name]: checked,
        };
      } else {
        return {
          ...prev,
          [name]: value,
        };
      }
    });
  };

  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="12">
            <div className="mt-4 d-flex flex-column justify-content-center ">
              <h6>Add New</h6>
              <Row className="mt-4">
                <Col md="12 mb-1">
                  <Label htmlFor="validationCustom03">
                    Title *{" "}
                    <IsShowTitleORNot
                      checked={formDataVideos?.isTitleShow}
                      onChange={InputValueChange}
                    />{" "}
                  </Label>
                  <Input
                    className="form-control"
                    name="title"
                    type="text"
                    placeholder="Title *"
                    value={formDataVideos.title}
                    onChange={(e) => InputValueChange(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    min={0}
                    maxLength={50}
                    onInput={maxLengthCheck}
                  />
                  <div className="text-end mt-3 ">
                    <span>{formDataVideos.title.length} / 50</span>
                  </div>
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "title",
                      formDataVideos.title,
                      "required"
                    )}
                  </p>
                </Col>
                <Col md="12 mb-1">
                  <Label htmlFor="validationCustom03">{"Sub Title"}</Label>
                  <Input
                    className="form-control"
                    name="description"
                    type="text"
                    placeholder="Sub Title"
                    value={formDataVideos.description}
                    onChange={(e) => InputValueChange(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    min={0}
                    maxLength={30}
                    onInput={maxLengthCheck}
                  />
                  <div className="text-end mt-3 ">
                    <span>{formDataVideos.description.length} / 30</span>
                  </div>
                </Col>
                <Col md="12 mb-1">
                  <Label htmlFor="validationCustom03">{"Slug Url *"}</Label>
                  <Input
                    className="form-control"
                    name="slugUrl"
                    type="text"
                    placeholder="Slug Url *"
                    value={formDataVideos.slugUrl}
                    onChange={(e) => InputValueChange(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "slugUrl",
                      formDataVideos.slugUrl,
                      "required"
                    )}
                  </p>
                </Col>
                {!isVideoParent && (
                  <>
                    <Col md="12 mb-3">
                      <Label htmlFor="validationCustom03">
                        {"Button text"}
                      </Label>
                      <Input
                        className="form-control"
                        name="buttonText"
                        type="text"
                        placeholder="Button text"
                        value={formDataVideos?.buttonText}
                        onChange={(e) => InputValueChange(e)}
                        style={{ borderRadius: "0", color: "black" }}
                        required
                      />
                    </Col>
                    <Col md="12 mb-3">
                      <Label htmlFor="validationCustom03">{"Item Limit"}</Label>
                      <Input
                        className="form-control"
                        name="itemLimit"
                        type="number"
                        placeholder="Item Limit"
                        value={formDataVideos?.itemLimit}
                        onChange={(e) => InputValueChange(e)}
                        style={{ borderRadius: "0", color: "black" }}
                        required
                      />
                    </Col>
                  </>
                )}

                <Col md="6 mb-3">
                  <Label className="d-block" for="edo-ani">
                    <Switch
                      checked={formDataVideos.isYoutube}
                      inputProps={{
                        "aria-label": "controlled",
                      }}
                      onChange={(e) => InputValueChange(e)}
                      color="primary"
                      name="isYoutube"
                      id="edo-ani"
                      size="medium"
                    />
                    {"Is Youtube URL"}
                  </Label>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default VideosParentPopup;
