import axios from "axios";
import {
  BYTE_MULTI_WEB_DASHBOARD,
  GET_SLIDER,
  PUT_STATUS_SLIDER,
  POST_SLIDER,
  GET_SLIDER_DETAILS,
  PUT_SLIDER_ORDER,
  BYTE_MULTI_WEB_FILE_UPLOAD,
  BYTE_WEB_DELETE_FILE,
  BYTE_MULTI_WEB_DELETE_FILE,
} from "..";

export const Byte_Multi_Web_Dashboard = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  let newParmObj = {
    ...paramObj,
  };
  return axios.get(
    `${process.env.REACT_APP_API_URL}${BYTE_MULTI_WEB_DASHBOARD}`,
    {
      headers: { Authorization: jwt_token },
      params: newParmObj,
    }
  );
};

/**
 * get slider data
 * @param {Parameters} paramObj
 * @returns
 */
export const getSlider = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_SLIDER}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * enable-disable
 * @param {body} body
 * @returns
 */
export const updateStatus = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_STATUS_SLIDER}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * create slider
 * @param {body} body
 * @returns
 */
export const postSlider = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(`${process.env.REACT_APP_API_URL}${POST_SLIDER}`, body, {
    headers: { Authorization: jwt_token },
  });
};

/**
 * update slider
 * @param {body} body
 * @returns
 */
export const putUpdateSlider = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(`${process.env.REACT_APP_API_URL}${POST_SLIDER}`, body, {
    headers: { Authorization: jwt_token },
    params: { sliderId: body.sliderId },
  });
};

/**
 * delete slider
 * @param {id} sliderId
 * @returns
 */
export const deleteSlider = (sliderId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${POST_SLIDER}`, {
    headers: { Authorization: jwt_token },
    params: { sliderId },
  });
};

/**
 * get slider details
 * @param {id} sliderId
 * @returns
 */
export const getSliderDetails = (sliderId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_SLIDER_DETAILS}`, {
    headers: { Authorization: jwt_token },
    params: { sliderId },
  });
};

/**
 * upload file
 * @param {body} formData
 * @returns
 */
export const FILE_UPLOAD_API = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${BYTE_MULTI_WEB_FILE_UPLOAD}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * delete image permanently
 * @param {Parameters} formData
 * @returns
 */
export const delete_Image_Permanent = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(
    `${process.env.REACT_APP_API_URL}${BYTE_WEB_DELETE_FILE}`,
    {
      headers: { Authorization: jwt_token },
      params: formData,
    }
  );
};

// multi page image delete
export const delete_Multi_Image_Permanent = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(
    `${process.env.REACT_APP_API_URL}${BYTE_MULTI_WEB_DELETE_FILE}`,
    {
      headers: { Authorization: jwt_token },
      params: formData,
    }
  );
};

/**
 * sort order slider
 * @param {body} updateOrder
 * @returns
 */
export const sort_order_slider = (updateOrder) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_SLIDER_ORDER}`,
    { updateOrder: updateOrder },
    {
      headers: { Authorization: jwt_token },
    }
  );
};
