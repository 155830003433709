import * as React from "react";
import { useContext } from "react";
import { useState } from "react";
import { toast, Zoom } from "react-toastify";
import {
  CreateHwId,
  EditPageLimit,
  Organization_List,
} from "../../api/SupperAdminOrganizationApi/Organization";
import { SORTING_ORDER } from "../../constant";
import LoaderContext from "../LoaderContext/LoaderContext";
import PageItemMasterContext from "./PageItemMasterContext";

const PageItemMasterState = (props) => {
  const { loaderShow, setLoaderShow, tabelAnimation, setTableAnimation } =
    useContext(LoaderContext);

  const [formvalue, setformValue] = useState([]);
  const [sortOrder, setSortOrder] = useState(""); //SORTING -->
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState("createdAt"); //SORTING -->
  const [record, setRecord] = useState(0);
  const [progress, setProgress] = useState(0);
  const [filter, setFilter] = useState(""); //SEARCHING FILTER -->
  const [togglesubmit, setToggleSubmit] = useState(false);
  const [modal, setModal] = useState(false);
  const [check, setCheck] = useState(0);
  const [broker, setBroker] = useState("");
  const [mainPageName, setMainPageName] = useState("");
  const [subPageName, setSubPageName] = useState("");

  const [pageLimit, setPageLimit] = useState(1);
  const [orgName, setOrgName] = useState("");
  const [domain, setDomain] = useState("");

  /**Edit Page Limit state */
  const [orgUuid, setOrgUuid] = useState("");

  /**Organization List Api */

  const OrganizationList = async () => {
    if (sortOrder === "ASC" || sortOrder === "DSC" || filter.length) {
    } else {
      setTableAnimation(!tabelAnimation);
    }
    try {
      let paramObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
      };
      const { data } = await Organization_List(paramObj);

      if (data.status === 200) {
        setformValue(data.payload.data);
        setRecord(data.pager.totalRecords);
        setTableAnimation(false);
      } else {
        setformValue([]);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setTableAnimation(false);
    }
  };

  /**
   * Edit Page Limit
   */

  const EditHandle = (
    orgUuid,
    pageLimit,
    domain,
    isBrokerAllowed,
    brokerLimit
  ) => {
    setOrgUuid(orgUuid);
    setPageLimit(pageLimit);
    setDomain(domain);
    setCheck(isBrokerAllowed);
    setBroker(brokerLimit);
    setToggleSubmit(true);
    setModal(true);
  };

  /**Organization Add Api */

  const createhwId = async () => {
    if (togglesubmit === true) {
      setLoaderShow(!loaderShow);
      let formdata = {
        pageLimit,
        isBrokerAllowed: check,
        brokerLimit: +broker,
        mainPageName,
        subPageName,
      };
      try {
        const { data } = await EditPageLimit(formdata, orgUuid);

        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            transition: Zoom,
          });
          OrganizationList();
          closeModel();
        } else {
          setPageLimit(pageLimit);
        }
      } catch (error) {
        toast.error(error.response.data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        setLoaderShow(false);
      }
    } else {
      setLoaderShow(!loaderShow);
      let formdata = {
        pageLimit: +pageLimit,
        orgName,
        isBrokerAllowed: check,
        brokerLimit: +broker,
        domain: domain,
        mainPageName,
        subPageName,
      };
      try {
        const { data } = await CreateHwId(formdata);

        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            transition: Zoom,
          });
          OrganizationList();
          closeModel();
        } else {
          setPageLimit(pageLimit);
          setOrgName(orgName);
          setBroker(broker);
          setDomain(domain);
        }
      } catch (error) {
        toast.error(error.response.data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        setLoaderShow(false);
      }
    }
  };

  /**Sorting List */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder(SORTING_ORDER.ASC);
    }
    if (sortOrder === SORTING_ORDER.ASC) {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder(SORTING_ORDER.DSC);
    }
    if (sortOrder === SORTING_ORDER.DSC) {
      setSortBy();
      setSortOrder("");
    }
  };

  const closeModel = () => {
    setBroker("");
    setOrgName("");
    setPageLimit("");
    setCheck(0);
    setDomain("");
    setModal(false);
    setLoaderShow(false);
  };

  return (
    <>
      <PageItemMasterContext.Provider
        value={{
          formvalue,
          setformValue,
          sortOrder,
          setSortOrder,
          pageSize,
          setPageSize,
          page,
          setPage,
          sortBy,
          setSortBy,
          record,
          setRecord,
          progress,
          setProgress,
          filter,
          setFilter,
          OrganizationList,
          sorting,
          togglesubmit,
          setToggleSubmit,
          modal,
          setModal,
          closeModel,
          createhwId,
          pageLimit,
          setPageLimit,
          orgName,
          setOrgName,
          EditHandle,
          check,
          setCheck,
          broker,
          setBroker,
          domain,
          setDomain,
          mainPageName,
          setMainPageName,
          subPageName,
          setSubPageName,
        }}
      >
        {props.children}
      </PageItemMasterContext.Provider>
    </>
  );
};

export default PageItemMasterState;
