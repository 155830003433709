import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../../layout/breadcrumb";
import Header from "./header/header";
import { styled } from "@mui/material/styles";
import CardThemeColorSelect from "./cardThemeColorSelect/CardThemeColorSelect";
// import BusinessDetails from "./businessDetails/BusinessDetails";
// import BusinessDetailsPopup from "../Modals/BusinessDetailsPopup";
import CompanyLogo from "./CompanylogoTagline&services/CompanyLogo";
import SeoDetails from "./seoDetails/SeoDetails";
import Aboutus from "./aboutus/Aboutus";
import Products from "./products/Products";
import Videos from "./videos/Videos";
import Downloads from "./downloads/Downloades";
import Images from "./images/Images";
import PaymentDetailsAndQr from "./paymentdetailsAndQr/PaymentDetailsAndQr";
import ContactUs from "./contactUs/ContactUs";
import SocialMedia from "./socialMedia/SocialMedia";
import Testimonial from "./testimonial/Testimonial";
import Slider from "./slider/Slider";
import OurTeam from "./ourTeam/OurTeam";
import Details from "./Details/Details";
import Services from "./services/Services";
import OurClient from "./ourClient/OurClients";
import BannerWithTitleAndButton from "./bannerWithTitleAndButton/BannerWithTitleAndButton";
import ProductFeatures from "./ProductFeatures/ProductFeatures";
import FaqS from "./faq's/FAQ's";
import DataCounter from "./dataCounter/DataCounter";
import Blog from "./blog/Blog";
import Packages from "./packages/Packages";
import { Byte_Mini_Web_Dashboard } from "../../apis/dashboardAPi/DeshboardApi";
import ByteMiniWebDashboardContext from "../../context/ByteMiniWeb_Dashboard_Context/ByteMiniWeb_Dashboard_Context";
import { OpenModalCommonFunc, TostMessage } from "../../utils";
import SetSubDomainPopup from "../../Modals/SubdomainSetPopup";
import { Cases } from "../../constant";
import ByteCardBuilderContext from "../../context/BytecardBilderContext/ByteCardBuilderContext";
import {
  getDomainWithoutSubdomain,
  isValidDomain,
} from "../../../utils/helper";
import Payment from "./payment/Payment";
import { Switch } from "@material-ui/core";
import { putUpdateTheme } from "../../apis/selectColorApi/SelectColorApi";
import LoaderContext from "../../../context/LoaderContext/LoaderContext";
import { getNavbarList } from "../../apis/AboutApi/AboutUsApi";
import Select, { components } from "react-select";
import { cloneCategory_List } from "../../../api/CloneCategoryApi/CloneCategoryApi";
import { WebCategoryMap_List } from "../../../api/WebCategoryMapApi/WebCategoryMapApi";
import { Button } from "react-bootstrap";
import { CreateCloneWebMaster } from "../../../api/SupperAdminOrganizationApi/Organization";
import PageviewIcon from "@mui/icons-material/Pageview";
import { SINGLE_PAGE } from "../../../constant";

const Option = (props) => {
  const handleIconClick = (data) => {
    window.open(`https://${data.label}`, "_blank");
  };
  return (
    <components.Option {...props}>
      <div>
        {" "}
        <div className="d-flex justify-content-between  ">
          <span>{props.children}</span>
          <span
            role="img"
            aria-label="Icon"
            onClick={() => handleIconClick(props.data)}
          >
            <PageviewIcon />
          </span>
        </div>
      </div>
    </components.Option>
  );
};

const ByteCardBuilder = () => {
  const { apiData, setApiData } = useContext(ByteMiniWebDashboardContext);
  const [showDomainChange, setShowDomainChange] = useState(false);
  const { setAllPopupState, allPopupState, setDomain } = useContext(
    ByteCardBuilderContext
  );
  const [navbarData, setNavBarData] = useState([]);
  const { setLoaderShow } = useContext(LoaderContext);
  const [CloneCategoryIs, setCloneCategoryIs] = useState();
  const [allItems, setAllItems] = useState([]);
  const [themeSelect, setThemeSelect] = useState({
    themeColor: "#f07f1b",
    fontColor: "#ffffff",
  });

  const updateThemeSelect = useCallback(() => {
    setThemeSelect((prev) => ({
      ...prev,
      isSlider: apiData?.data?.webMasterDetail?.otherSetting?.isSlider,
      isSideBarShow:
        apiData?.data?.webMasterDetail?.otherSetting?.isSideBarShow,
    }));
  }, [apiData?.data?.webMasterDetail?.otherSetting]);

  useEffect(() => {
    updateThemeSelect();
  }, [apiData?.data?.webMasterDetail?.otherSetting]);

  const Root = styled("div")(({ theme }) => ({
    width: "100%",
    ...theme.typography.body2,
    "& > :not(style) ~ :not(style)": {
      marginTop: theme.spacing(2),
    },
  }));

  const ByteMiniWeb_Dashboard = async () => {
    let paramObj = {
      webId: localStorage.getItem("webId"),
    };
    try {
      setApiData((prev) => {
        return {
          ...prev,
          isLoading: true,
        };
      });
      const { data } = await Byte_Mini_Web_Dashboard(paramObj);
      if (data.status === 200) {
        setApiData((prev) => {
          return {
            ...prev,
            isLoading: false,
            data: data.payload.data,
          };
        });
        await setShowDomainChange(data.payload.data.webMasterDetail.isSample);
        if (data?.payload?.data?.webMasterDetail?.isSample) {
          try {
            let paramObj = {
              showAll: true,
            };
            const { data } = await cloneCategory_List(paramObj, SINGLE_PAGE);

            if (data.status === 200) {
              setAllItems(
                data.payload.data.map((ele) => {
                  return {
                    value: ele.cloneCategoryId,
                    label: ele.name,
                  };
                })
              );
            } else {
            }
          } catch (error) {}
        }
        let value = getDomainWithoutSubdomain(
          data.payload.data.webMasterDetail.domain
        );
        setDomain(
          data.payload.data.webMasterDetail.domain.split("." + value)[0]
        );
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  useEffect(() => {
    ByteMiniWeb_Dashboard();
  }, []);

  useMemo(() => {
    if (apiData?.data?.webMasterDetail?.isDomainChanged === false) {
      OpenModalCommonFunc(Cases.SUB_DOMAIN, setAllPopupState);
    }
  }, [apiData?.data?.webMasterDetail?.isDomainChanged]);

  const handleSubmit = async ({ e, themeColor, fontColor }) => {
    const { name, checked } = e.target;
    try {
      setLoaderShow(true);
      setThemeSelect((prev) => {
        return {
          ...prev,
          themeColor: themeColor,
          fontColor: fontColor,
        };
      });
      let newObj = {
        ...themeSelect,
        themeColor,
        fontColor,
        [name]: checked,
      };
      const { data } = await putUpdateTheme(newObj);
      if (data.status === 200) {
        OpenModalCommonFunc(Cases.THEME, setAllPopupState, false);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
        ByteMiniWeb_Dashboard();
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const getData = async () => {
    let paramObj = {
      showAll: true,
    };
    try {
      const { data } = await getNavbarList(paramObj);
      if (data.status === 200) {
        setNavBarData(data.payload.data);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  const handleChangeSelect = (selectedOption) => {
    setCloneCategoryIs(selectedOption);
  };

  const [CloneCategoryMapIs, setCloneCategoryMapIs] = useState();
  const [allItemsMap, setAllItemsMap] = useState([]);
  const getWebMasterMapList = async () => {
    try {
      let paramObj = {};
      if (CloneCategoryIs) {
        paramObj["search"] = { cloneCategoryId: CloneCategoryIs.value };
      }
      const { data } = await WebCategoryMap_List(paramObj, SINGLE_PAGE);
      if (data.status === 200) {
        setAllItemsMap(
          data.payload.data.map((ele) => {
            return {
              value: ele.catWebMapId,
              webId: ele.webId,
              label: ele.webDetail.domain,
            };
          })
        );
      }
    } catch (error) {}
  };
  const handleChangeSelectMap = (selectedOption) => {
    setCloneCategoryMapIs(selectedOption);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (CloneCategoryIs) {
      setCloneCategoryMapIs("");
      getWebMasterMapList();
    }
  }, [CloneCategoryIs]);

  const handleSelectSubmit = async () => {
    try {
      setLoaderShow(true);
      let formData = {
        toWebId: CloneCategoryMapIs.webId,
        catWebMapId: CloneCategoryMapIs.value,
        // fromWebId: "",
      };
      const { data } = await CreateCloneWebMaster(formData, SINGLE_PAGE);
      if (data.status === 200) {
        setLoaderShow(false);
        ByteMiniWeb_Dashboard();
        setShowDomainChange(false);
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  return (
    <div>
      <Breadcrumbs parent={"Website builder"} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="3 mb-4">
                    {" "}
                    <h3>Website Builder</h3>
                  </Col>
                  {showDomainChange && (
                    <>
                      <Col md="3 mb-4">
                        <Label htmlFor="validationCustom03">
                          {"Select Clone Category"}
                        </Label>
                        <Select
                          // isMulti
                          closeMenuOnSelect={true}
                          options={allItems}
                          value={CloneCategoryIs}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={(e) => handleChangeSelect(e)}
                        />
                      </Col>
                      <Col md="3 mb-4">
                        <Label htmlFor="validationCustom03">
                          {"Select Clone Category Map"}
                        </Label>
                        <Select
                          // isMulti
                          closeMenuOnSelect={true}
                          options={allItemsMap}
                          value={CloneCategoryMapIs}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={(e) => handleChangeSelectMap(e)}
                          components={{ Option }}
                        />
                      </Col>
                      <Col className="d-flex align-items-center   ">
                        {" "}
                        <Button onClick={() => handleSelectSubmit()}>
                          {"Submit"}
                        </Button>
                      </Col>
                    </>
                  )}
                </Row>
              </CardHeader>
              <CardBody>
                <Root>
                  <Header data={apiData?.data?.webMasterDetail} />
                </Root>
                <Row>
                  {/* <SetSectionPosition /> */}
                  <Col
                    md={"3 mb-3"}
                    className="d-flex  align-items-center  justify-content-center "
                  >
                    <Label className="m-0 px-4 ">Card View </Label>
                    <Switch
                      checked={
                        themeSelect.isSlider === true ||
                        apiData?.data?.webMasterDetail?.otherSetting
                          ?.isSlider === true
                      }
                      inputProps={{
                        "aria-label": "controlled",
                      }}
                      name="isSlider"
                      color="primary"
                      onChange={(e) =>
                        handleSubmit({
                          e: e,
                          themeColor:
                            apiData?.data?.webMasterDetail?.otherSetting
                              ?.themeColor,
                          fontColor:
                            apiData?.data?.webMasterDetail?.otherSetting
                              ?.fontColor,
                        })
                      }
                    />
                    <Label className="m-0 px-4">Active Slider View</Label>
                  </Col>
                  <Col md={"1 mb-3"}></Col>
                  <Col
                    md={"3 mb-3"}
                    className="d-flex  align-items-center  justify-content-center "
                  >
                    <Label className="m-0 px-4 ">Side Bar View</Label>
                    <Switch
                      checked={
                        themeSelect.isSideBarShow === true ||
                        apiData?.data?.webMasterDetail?.otherSetting
                          ?.isSideBarShow === true
                      }
                      inputProps={{
                        "aria-label": "controlled",
                      }}
                      name="isSideBarShow"
                      color="primary"
                      onChange={(e) =>
                        handleSubmit({
                          e: e,
                          themeColor:
                            apiData?.data?.webMasterDetail?.otherSetting
                              ?.themeColor,
                          fontColor:
                            apiData?.data?.webMasterDetail?.otherSetting
                              ?.fontColor,
                        })
                      }
                    />
                    <Label className="m-0 px-4 ">Top Bar View</Label>
                  </Col>
                  {(apiData?.data?.webMasterDetail?.otherSetting?.isSlider ===
                    false ||
                    apiData?.data?.webMasterDetail?.otherSetting === "") && (
                    <CardThemeColorSelect
                      dataTheme={apiData?.data?.webMasterDetail}
                      ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                    />
                  )}
                  {apiData?.data?.webMasterDetail?.otherSetting?.isSlider ===
                    true && apiData?.data?.slider ? (
                    <Slider webMasterDetail={apiData?.data?.webMasterDetail} />
                  ) : (
                    ""
                  )}
                  {apiData?.data?.companyLogoTaglineService ? (
                    <CompanyLogo
                      ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                    />
                  ) : (
                    ""
                  )}
                  {apiData?.data?.seoDetails ? (
                    <SeoDetails
                      ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                      navbarData={navbarData}
                      getData={getData}
                    />
                  ) : (
                    ""
                  )}
                  {apiData?.data?.aboutUs ? (
                    <Aboutus
                      webMasterDetail={apiData?.data?.webMasterDetail}
                      ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                      navbarData={navbarData}
                      getData={getData}
                    />
                  ) : (
                    ""
                  )}
                  {apiData?.data?.product ? (
                    <Products
                      dataProduct={apiData?.data?.product}
                      webMasterDetail={apiData?.data?.webMasterDetail}
                      navbarData={navbarData}
                      getData={getData}
                    />
                  ) : (
                    ""
                  )}
                  {apiData?.data?.gallery ? (
                    <Images
                      webMasterDetail={apiData?.data?.webMasterDetail}
                      navbarData={navbarData}
                      getData={getData}
                    />
                  ) : (
                    ""
                  )}
                  {apiData?.data?.videos ? (
                    <Videos
                      webMasterDetail={apiData?.data?.webMasterDetail}
                      navbarData={navbarData}
                      getData={getData}
                    />
                  ) : (
                    ""
                  )}
                  {apiData?.data?.download ? (
                    <Downloads
                      webMasterDetail={apiData?.data?.webMasterDetail}
                      navbarData={navbarData}
                      getData={getData}
                    />
                  ) : (
                    ""
                  )}
                  {apiData?.data?.payment ? (
                    <Payment
                      webMasterDetail={apiData?.data?.webMasterDetail}
                      navbarData={navbarData}
                      getData={getData}
                    />
                  ) : (
                    ""
                  )}
                  {apiData?.data?.contactUs ? (
                    <ContactUs
                      webMasterDetail={apiData?.data?.webMasterDetail}
                      navbarData={navbarData}
                      getData={getData}
                    />
                  ) : (
                    ""
                  )}
                  {apiData?.data?.testimonial ? (
                    <Testimonial
                      webMasterDetail={apiData?.data?.webMasterDetail}
                      navbarData={navbarData}
                      getData={getData}
                    />
                  ) : (
                    ""
                  )}
                  {apiData?.data?.socialMedia ? (
                    <SocialMedia
                      dataSocialMedia={apiData?.data?.socialMedia}
                      navbarData={navbarData}
                      webMasterDetail={apiData?.data?.webMasterDetail}
                    />
                  ) : (
                    ""
                  )}
                  {apiData?.data?.paymentDetailsAndQr ? (
                    <PaymentDetailsAndQr />
                  ) : (
                    ""
                  )}
                  {apiData?.data?.ourTeam ? <OurTeam /> : ""}
                  {apiData?.data?.detailSection ? <Details /> : ""}
                  {apiData?.data?.serviceCategory ? <Services /> : ""}
                  {apiData?.data?.ourClient ? <OurClient /> : ""}
                  {apiData?.data?.bannerWithTitleAndButton ? (
                    <BannerWithTitleAndButton />
                  ) : (
                    ""
                  )}
                  {apiData?.data?.productFeatures ? <ProductFeatures /> : ""}
                  {apiData?.data?.faqs ? <FaqS /> : ""}
                  {apiData?.data?.dataCounter ? <DataCounter /> : ""}
                  {apiData?.data?.blog ? <Blog /> : ""}
                  {apiData?.data?.packages ? <Packages /> : ""}
                  {/* <BusinessDetails /> */}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {allPopupState.subDomainPopup && (
          <SetSubDomainPopup
            ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
            subDomainData={apiData.data.webMasterDetail}
          />
        )}
      </Container>
    </div>
  );
};

export default ByteCardBuilder;
