import { convertRGBA, rgbColorCreate } from "../../../../utils/helper";

export const GetWhatsAppLinkOneReset = {
  ".getlink-section": {
    padding: "50px 0",
    "background-color": { r: 238, g: 238, b: 238, a: 100 },
  },
  ".getlink-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".getlink-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: { r: 255, g: 73, b: 124, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 0.2 },
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    display: "inline-block",
    "margin-bottom": "15px",
  },
  ".getlink-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: { r: 0, g: 0, b: 0, a: 100 },
  },
  ".getlinksbox .section-note": {
    "font-size": "18px",
    "font-weight": "500",
    color: { r: 153, g: 153, b: 153, a: 100 },
  },
  ".getlinksbox": {
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
    padding: "40px 40px",
    p1: "40px",
    p2: "40px",
    "border-radius": "15px",
    border: "1px solid { r: 255, g: 255, b: 255, a: 100 }",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: { r: 255, g: 255, b: 255, a: 100 },
    "border-top": "3px solid { r: 255, g: 73, b: 124, a: 100 }",
    borderTopPx: "3px",
    borderTopThik: "solid",
    borderTopColor: { r: 255, g: 73, b: 124, a: 100 },
    "box-shadow": "5px 5px 15px { r: 0, g: 0, b: 0, a: 0.09 }",
    bs1: "5px",
    bs2: "5px",
    bs3: "15px",
    shadowColor: { r: 0, g: 0, b: 0, a: 0.09 },
  },
  ".getlinksbox .form-control": {
    width: "100%",
    "margin-bottom": "10px",
    "background-color": { r: 242, g: 247, b: 255, a: 100 },
    border: "2px solid { r: 238, g: 238, b: 238, a: 100 }",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: { r: 238, g: 238, b: 238, a: 100 },
    "border-radius": "4px",
    "font-size": "16px",
    appearance: "auto",
    outline: "0px solid { r: 255, g: 255, b: 255, a: 100 }",
    outPx: "0px",
    outThik: "solid",
    outcolor: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".getlink-section .getlinksbox .btn": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  custom: "",
};

export const GetWhatsAppThemeOneFunction = (payload) => {
  return {
    ".getlink-section": {
      padding: payload.style?.[".getlink-section"]?.["padding"],
      "background-color": payload.style?.[".getlink-section"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".getlink-section"]?.["background-color"],
          })
        : "",
      width: payload.style?.[".getlink-section"]?.["width"]?.replace(
        "calc(100% - ",
        ""
      ),
      margin: payload.style?.[".getlink-section"]?.["margin"],
      "border-radius": payload.style?.[".getlink-section"]?.["border-radius"],
    },
    ".getlink-section .section-title-area": {
      "margin-bottom":
        payload.style?.[".getlink-section .section-title-area"]?.[
          "margin-bottom"
        ],
    },
    ".getlink-section .section-title-area .section-subtitle": {
      "font-size":
        payload.style?.[
          ".getlink-section .section-title-area .section-subtitle"
        ]?.["font-size"],
      "font-weight":
        payload.style?.[
          ".getlink-section .section-title-area .section-subtitle"
        ]?.["font-weight"],
      color: payload.style?.[
        ".getlink-section .section-title-area .section-subtitle"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".getlink-section .section-title-area .section-subtitle"
              ]?.["color"],
          })
        : "",
      "background-color": payload.style?.[
        ".getlink-section .section-title-area .section-subtitle"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".getlink-section .section-title-area .section-subtitle"
              ]?.["background-color"],
          })
        : "",
      padding:
        payload.style?.[
          ".getlink-section .section-title-area .section-subtitle"
        ]?.["padding"],
      p1: payload.style?.[
        ".getlink-section .section-title-area .section-subtitle"
      ]?.["padding"]?.split(" ")[0],
      p2: payload.style?.[
        ".getlink-section .section-title-area .section-subtitle"
      ]?.["padding"]?.split(" ")[1],
      "border-radius":
        payload.style?.[
          ".getlink-section .section-title-area .section-subtitle"
        ]?.["border-radius"],
      display:
        payload.style?.[
          ".getlink-section .section-title-area .section-subtitle"
        ]?.["display"],
      "margin-bottom":
        payload.style?.[
          ".getlink-section .section-title-area .section-subtitle"
        ]?.["margin-bottom"],
    },
    ".getlink-section .section-title-area .section-title": {
      "font-size":
        payload.style?.[
          ".getlink-section .section-title-area .section-title"
        ]?.["font-size"],
      "font-weight":
        payload.style?.[
          ".getlink-section .section-title-area .section-title"
        ]?.["font-weight"],
      color: payload.style?.[
        ".getlink-section .section-title-area .section-title"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".getlink-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".getlinksbox .section-note": {
      "font-size": payload.style?.[".getlinksbox .section-note"]?.["font-size"],
      "font-weight":
        payload.style?.[".getlinksbox .section-note"]?.["font-weight"],
      color: payload.style?.[".getlinksbox .section-note"]?.["color"]
        ? convertRGBA({
            rgbString: payload.style?.[".getlinksbox .section-note"]?.["color"],
          })
        : "",
    },
    ".getlinksbox": {
      "background-color": payload.style?.[".getlinksbox"]?.["background-color"]
        ? convertRGBA({
            rgbString: payload.style?.[".getlinksbox"]?.["background-color"],
          })
        : "",
      padding: payload.style?.[".getlinksbox"]?.["padding"],
      p1: payload.style?.[".getlinksbox"]?.["padding"]?.split(" ")[0],
      p2: payload.style?.[".getlinksbox"]?.["padding"]?.split(" ")[1],
      "border-radius": payload.style?.[".getlinksbox"]?.["border-radius"],
      border: payload.style?.[".getlinksbox"]?.["border"],
      borderPx: payload.style?.[".getlinksbox"]?.["border"]?.split(" ")[0],
      borderThik: payload.style?.[".getlinksbox"]?.["border"]?.split(" ")[1],
      borderColor: payload.style?.[".getlinksbox"]?.["borderColor"]
        ? convertRGBA({
            rgbString: payload.style?.[".getlinksbox"]?.["borderColor"],
          })
        : "",
      "border-top": payload.style?.[".getlinksbox"]?.["border-top"],
      borderTopPx:
        payload.style?.[".getlinksbox"]?.["border-top"]?.split(" ")[0],
      borderTopThik:
        payload.style?.[".getlinksbox"]?.["border-top"]?.split(" ")[1],
      borderTopColor: payload.style?.[".getlinksbox"]?.["borderTopColor"]
        ? convertRGBA({
            rgbString: payload.style?.[".getlinksbox"]?.["borderTopColor"],
          })
        : "",
      "box-shadow": payload.style?.[".getlinksbox"]?.["box-shadow"],
      bs1: payload.style?.[".getlinksbox"]?.["box-shadow"]?.split(" ")[0],
      bs2: payload.style?.[".getlinksbox"]?.["box-shadow"]?.split(" ")[1],
      bs3: payload.style?.[".getlinksbox"]?.["box-shadow"]?.split(" ")[2],
      shadowColor: payload.style?.[".getlinksbox"]?.["shadowColor"]
        ? convertRGBA({
            rgbString: payload.style?.[".getlinksbox"]?.["shadowColor"],
          })
        : "",
    },
    ".getlinksbox .form-control": {
      width: payload.style?.[".getlinksbox .form-control"]?.["width"],
      "margin-bottom":
        payload.style?.[".getlinksbox .form-control"]?.["margin-bottom"],
      "background-color":
        payload.style?.[".getlinksbox .form-control"]?.["background-color"],
      border: payload.style?.[".getlinksbox .form-control"]?.["border"],
      borderPx:
        payload.style?.[".getlinksbox .form-control"]?.["border"]?.split(
          " "
        )[0],
      borderThik:
        payload.style?.[".getlinksbox .form-control"]?.["border"]?.split(
          " "
        )[1],
      borderColor: payload.style?.[".getlinksbox .form-control"]?.[
        "borderColor"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".getlinksbox .form-control"]?.["borderColor"],
          })
        : "",
      "border-radius":
        payload.style?.[".getlinksbox .form-control"]?.["border-radius"],
      "font-size": payload.style?.[".getlinksbox .form-control"]?.["font-size"],
      appearance: payload.style?.[".getlinksbox .form-control"]?.["appearance"],
      outline: payload.style?.[".getlinksbox .form-control"]?.["outline"],
      outPx:
        payload.style?.[".getlinksbox .form-control"]?.["outline"]?.split(
          " "
        )[0],
      outThik:
        payload.style?.[".getlinksbox .form-control"]?.["outline"]?.split(
          " "
        )[1],
      outcolor: payload.style?.[".getlinksbox .form-control"]?.["outcolor"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".getlinksbox .form-control"]?.["outcolor"],
          })
        : "",
    },
    ".getlink-section .getlinksbox .btn": {
      color: payload.style?.[".getlink-section .getlinksbox .btn"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".getlink-section .getlinksbox .btn"]?.["color"],
          })
        : "",
      "background-color": payload.style?.[
        ".getlink-section .getlinksbox .btn"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".getlink-section .getlinksbox .btn"]?.[
                "background-color"
              ],
          })
        : "",
    },
    custom: payload.style.custom,
  };
};

export const GetWhatsAppLinkStyleOneSubFun = ({
  settingFormData,
  boxSection,
}) => {
  let newData = {
    ...settingFormData,
    ".getlink-section": boxSection
      ? {
          ...settingFormData[".getlink-section"],
          padding: `${settingFormData[".getlink-section"]["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
          width: `calc(100% - ${settingFormData[".getlink-section"][
            "width"
          ]?.replace("rem)", "")}rem)`,
          margin: `${settingFormData[".getlink-section"]["margin"]
            ?.split(" ")[0]
            ?.replace("px", "")}px auto`,
          "border-radius": `${settingFormData[".getlink-section"][
            "border-radius"
          ]?.replace("rem", "")}rem`,
        }
      : {
          ...settingFormData[".getlink-section"],
          padding: `${settingFormData[".getlink-section"]["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
        },
    ".getlink-section .section-title-area": {
      "margin-bottom": `${settingFormData[
        ".getlink-section .section-title-area"
      ]["margin-bottom"]?.replace("px", "")}px`,
    },
    ".getlink-section .section-title-area .section-subtitle": {
      ...settingFormData[
        ".getlink-section .section-title-area .section-subtitle"
      ],
      "font-size": `${settingFormData[
        ".getlink-section .section-title-area .section-subtitle"
      ]["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".getlink-section .section-title-area .section-subtitle"]["font-weight"]}`,

      color: settingFormData[
        ".getlink-section .section-title-area .section-subtitle"
      ]["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".getlink-section .section-title-area .section-subtitle"
              ]["color"],
          })
        : "",
      "background-color": settingFormData[
        ".getlink-section .section-title-area .section-subtitle"
      ]["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".getlink-section .section-title-area .section-subtitle"
              ]["background-color"],
          })
        : "",
      padding: `${settingFormData[
        ".getlink-section .section-title-area .section-subtitle"
      ]["p1"]?.replace("px", "")}px ${settingFormData[
        ".getlink-section .section-title-area .section-subtitle"
      ]["p2"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData[
        ".getlink-section .section-title-area .section-subtitle"
      ]["border-radius"]?.replace("px", "")}px`,
      display: `${settingFormData[".getlink-section .section-title-area .section-subtitle"]["display"]}`,
      "margin-bottom": `${settingFormData[
        ".getlink-section .section-title-area .section-subtitle"
      ]["margin-bottom"]?.replace("px", "")}px`,
    },
    ".getlink-section .section-title-area .section-title": {
      ...settingFormData[".getlink-section .section-title-area .section-title"],
      "font-size": `${settingFormData[
        ".getlink-section .section-title-area .section-title"
      ]["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".getlink-section .section-title-area .section-title"]["font-weight"]}`,
      color: settingFormData[
        ".getlink-section .section-title-area .section-title"
      ]["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".getlink-section .section-title-area .section-title"
              ]["color"],
          })
        : "",
    },
    ".getlinksbox .section-note": {
      ...settingFormData[".getlinksbox .section-note"],
      "font-size": `${settingFormData[".getlinksbox .section-note"][
        "font-size"
      ]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".getlinksbox .section-note"]["font-weight"]}`,
      color: settingFormData[".getlinksbox .section-note"]["color"]
        ? rgbColorCreate({
            rgbString: settingFormData[".getlinksbox .section-note"]["color"],
          })
        : "",
    },
    ".getlinksbox": {
      ...settingFormData[".getlinksbox"],
      "background-color": settingFormData[".getlinksbox"]["background-color"]
        ? rgbColorCreate({
            rgbString: settingFormData[".getlinksbox"]["background-color"],
          })
        : "",
      padding: `${settingFormData[".getlinksbox"]["p1"]?.replace(
        "px",
        ""
      )}px ${settingFormData[".getlinksbox"]["p2"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData[".getlinksbox"][
        "border-radius"
      ]?.replace("px", "")}px`,
      border: `${settingFormData[".getlinksbox"]["borderPx"]?.replace(
        "px",
        ""
      )}px ${settingFormData[".getlinksbox"]["borderThik"]} ${rgbColorCreate({
        rgbString: settingFormData[".getlinksbox"]["borderColor"],
      })}`,
      "border-top": `${settingFormData[".getlinksbox"]["borderTopPx"]?.replace(
        "px",
        ""
      )}px ${settingFormData[".getlinksbox"]["borderTopThik"]} ${rgbColorCreate(
        {
          rgbString: settingFormData[".getlinksbox"]["borderTopColor"],
        }
      )}`,
      "box-shadow": `${settingFormData[".getlinksbox"]["bs1"]?.replace(
        "px",
        ""
      )}px ${settingFormData[".getlinksbox"]["bs2"]?.replace(
        "px",
        ""
      )}px ${settingFormData[".getlinksbox"]["bs3"]?.replace(
        "px",
        ""
      )}px ${rgbColorCreate({
        rgbString: settingFormData[".getlinksbox"]["shadowColor"],
      })}`,
    },
    ".getlinksbox .form-control": {
      ...settingFormData[".getlinksbox .form-control"],
      width: `${settingFormData[".getlinksbox .form-control"]["width"]?.replace(
        "%",
        ""
      )}%`,
      "margin-bottom": `${settingFormData[".getlinksbox .form-control"][
        "margin-bottom"
      ]?.replace("px", "")}px`,
      "background-color": settingFormData[".getlinksbox .form-control"][
        "background-color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".getlinksbox .form-control"]["background-color"],
          })
        : "",
      border: `${settingFormData[".getlinksbox .form-control"][
        "borderPx"
      ]?.replace("px", "")}px ${
        settingFormData[".getlinksbox .form-control"]["borderThik"]
      } ${rgbColorCreate({
        rgbString: settingFormData[".getlinksbox .form-control"]["borderColor"],
      })}`,
      "border-radius": `${settingFormData[".getlinksbox .form-control"][
        "border-radius"
      ]?.replace("px", "")}px`,
      "font-size": `${settingFormData[".getlinksbox .form-control"][
        "font-size"
      ]?.replace("px", "")}px`,
      appearance: `${settingFormData[".getlinksbox .form-control"]["appearance"]}`,
      outline: `${settingFormData[".getlinksbox .form-control"][
        "outPx"
      ]?.replace("px", "")}px ${
        settingFormData[".getlinksbox .form-control"]["outThik"]
      } ${rgbColorCreate({
        rgbString: settingFormData[".getlinksbox .form-control"]["outcolor"],
      })}`,
    },
    ".getlink-section .getlinksbox .btn": {
      ...settingFormData[".getlink-section .getlinksbox .btn"],
      "background-color": settingFormData[".getlink-section .getlinksbox .btn"][
        "background-color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".getlink-section .getlinksbox .btn"][
                "background-color"
              ],
          })
        : "",
      color: settingFormData[".getlink-section .getlinksbox .btn"]["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".getlink-section .getlinksbox .btn"]["color"],
          })
        : "",
    },
  };
  return newData;
};
