import React, { useContext, useMemo, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import { Cases, btnName, modalsName } from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import Lightbox from "react-image-lightbox";
import CommonPopup from "../../../Modals/commonPopup";
import SliderPopup from "./SliderPopup";
import ByteMiniWebDashboardContext from "../../../context/ByteMiniWeb_Dashboard_Context/ByteMiniWeb_Dashboard_Context";
import SliderContext from "../../../context/SliderContext/SliderContext";
import {
  FILE_UPLOAD_API,
  getSlider,
  getSliderDetails,
  postSlider,
  putUpdateSlider,
  updateStatus,
} from "../../../apis/SliderApi/SliderApi";
import { FILE_TYPE } from "../../../../constant";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import SimpleReactValidator from "simple-react-validator";

const Slider = (props) => {
  const { webMasterDetail } = props;
  const { apiData } = useContext(ByteMiniWebDashboardContext);
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const { setLoaderShow } = useContext(LoaderContext);
  const {
    apiData: sliderData,
    setApiData,
    fromData,
    setFormData,
    id,
    setId,
    setIsEdit,
    isEdit,
    filter,
  } = useContext(SliderContext);
  const [open, setOpen] = useState(false);

  /**
   * get slider list
   */
  const getSliderList = async (status) => {
    try {
      if (!filter.length && status) {
        setApiData((prev) => {
          return { ...prev, isLoading: true };
        });
      }
      let paramObj = {
        search: { title: filter },
        showAll: true,
      };
      const { data } = await getSlider(paramObj);
      if (data.status === 200) {
        setApiData({
          ...sliderData,
          isLoading: false,
          data: data.payload.data.map((elem) => {
            return {
              id: elem.title + elem.sliderId,
              Id: elem.sliderId,
              name: elem.title,
              image: elem.image,
              webId: elem.webId,
              des: elem.description,
              buttonCaption: elem.buttonCaption,
              buttonLink: elem.buttonLink,
              createdAt: elem.createdAt,
              isActive: elem.isActive,
            };
          }),
        });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  useMemo(() => {
    if (allPopupState?.sliderPopup) {
      getSliderList();
    }
  }, [filter, allPopupState?.sliderPopup]);

  /**
   * enable disable api call
   * @param {slider id} sliderId
   * @param {status} isActive
   */

  const handleChangeStatus = async (sliderId, isActive) => {
    try {
      let body = {
        sliderId,
        isActive: isActive ? false : true,
      };
      const { data } = await updateStatus(body);
      if (data.status === 200) {
        getSliderList(false);
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * upload image
   * @param {file} file
   */
  const imageUpload = async (file) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.BYTE_SLIDER);
    formData.append("file", file);
    setLoaderShow(true);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        setFormData((prev) => {
          return {
            ...prev,
            image: data.payload.baseUrl + data.payload.imageUrl,
          };
        });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * slider create and update
   */
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      if (id >= -1 && isEdit) {
        setLoaderShow(true);
        let newData = {};
        if (fromData.image.length) {
          setFormData((prev) => {
            return {
              ...prev,
              image: prev.image
                .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
                .pop(),
            };
          });
          newData = {
            ...fromData,
            image: fromData.image
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
              .pop(),
          };
        }
        try {
          const { data } = await putUpdateSlider(newData ? newData : fromData);
          if (data.status === 200) {
            getSliderList();
            emptyData();
            TostMessage({ message: data, type: "success" });
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      } else {
        try {
          setLoaderShow(true);
          let newData = {};
          if (fromData.image.length) {
            setFormData((prev) => {
              return {
                ...prev,
                image: prev.image
                  .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
                  .pop(),
                order: sliderData.data.length + 1,
              };
            });
            newData = {
              ...fromData,
              image: fromData.image
                .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
                .pop(),
              order: sliderData.data.length + 1,
            };
          }
          const { data } = await postSlider(newData);
          if (data.status === 200) {
            getSliderList();
            emptyData();
            TostMessage({ message: data, type: "success" });
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      }
    }
  };

  /**
   * get slider details
   * @param {id} sliderId
   */
  const handleEdit = async (sliderId) => {
    try {
      setLoaderShow(true);
      setId(sliderId);
      const { data } = await getSliderDetails(sliderId);
      if (data.status === 200) {
        let payload = data.payload.data;
        setFormData({
          title: payload.title,
          description: payload.description,
          image: payload.image,
          sliderId: sliderId,
          webId: localStorage.getItem("webId"),
        });
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * data clear
   */
  const emptyData = () => {
    setId("");
    setIsEdit(false);
    setFormData({
      title: "",
      description: "",
      image: "",
      webId: localStorage.getItem("webId"),
    });
    setLoaderShow(false);
  };

  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody className="support-ticket-font box-border-div">
            <Row>
              <div className="col-12">
                <CardBuilderHeaderSection name={"Slider"} />
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => setOpen(!open)}
                >
                  <div
                    style={{
                      objectFit: "contain",
                      maxWidth: "125px",
                      height: "83px",
                      border: "1px solid #d3d3d3",
                      width: "100%",
                      display: "inline-block",
                      verticalAlign: "middle",
                      overflow: "hidden",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        bottom: "0",
                        left: "0",
                        borderRadius: "inherit",
                      }}
                    >
                      <img
                        src={apiData?.data?.slider?.image}
                        alt=""
                        style={{
                          objectPosition: "50% 50%",
                          objectFit: "contain",
                          opacity: "1",
                          transition: "opacity 0.28s ease-in",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <OpenPopupBtn
                openPopup={() =>
                  OpenModalCommonFunc(Cases.SLIDER, setAllPopupState)
                }
                name={apiData?.data?.slider?.buttonCaption || btnName.SLIDER}
              />
            </Row>
          </CardBody>
        </Card>
      </Col>

      {open && (
        <Lightbox
          mainSrc={apiData?.data?.slider?.image}
          onCloseRequest={() => setOpen(false)}
        />
      )}
      <CommonPopup
        isMini={true}
        open={allPopupState?.sliderPopup}
        close={() => OpenModalCommonFunc(Cases.SLIDER, setAllPopupState, false)}
        modalName={modalsName.SLIDER}
        modalCase={Cases.SLIDER}
        handleSubmit={handleSubmit}
        emptyData={emptyData}
        inputData={fromData}
        dataLength={apiData?.data?.length}
        content={
          <SliderPopup
            handleChangeStatus={handleChangeStatus}
            getData={getSliderList}
            handleEdit={handleEdit}
            imageUpload={imageUpload}
            emptyData={emptyData}
            simpleValidator={simpleValidator}
            webMasterDetail={webMasterDetail}
          />
        }
      />
    </>
  );
};

export default Slider;
