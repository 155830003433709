import React, { useContext, useMemo, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import { Cases, DELETE_CONFIRM, modalsName } from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import CommonPopup from "../../../Modals/commonPopup";
import FaqsPopup from "./Faq'sPopup";
import EditIcon from "@mui/icons-material/Edit";
import FaqsParentPopup from "./Faq'sParentPopup";
import { FILE_UPLOAD_API } from "../apis/SliderApi/SliderApi";
import {
  addUrlImage,
  convertRGBA,
  generateDynamicStyles,
  generateDynamicStylesSet,
  isCheckValue,
  isEmptyObject,
  randomStr,
  removeUrlImage,
  removeUrlKeyInPath,
  rgbColorCreate,
} from "../../../../utils/helper";
import {
  FILE_TYPE,
  FaqsTheme,
  ThemeNo,
  titleUpdateKey,
} from "../../../../constant";
import { IconButton } from "@material-ui/core";
import { Settings, Trash } from "react-feather";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import SimpleReactValidator from "simple-react-validator";
import FaqsContext from "../../../context/FaqsContext/FaqsContext";
import {
  deleteFaqs,
  getFaqs,
  getFaqsDetails,
  postFaqs,
  putUpdateFaqs,
  updateStatus,
} from "../apis/FaqsApi/FaqsApi";
import SettingFaqsPerentPopup from "./SettingFaqsPerentPopup";
import DeleteSection from "../../../common/DeleteSection";
import SettingMultiWebContext from "../../../../context/SettingMultiWebContext/SettingMultiWebContext";

const Faqs = (props) => {
  const {
    pageConfigDetails,
    webMasterDetail,
    navbarData,
    getData,
    faqsDetail,
    contentMapId,
    ByteMiniWeb_Dashboard,
    navMapId: navMapIds,
    contentDetail,
  } = props;
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );
  const { setCloneAlignModal, setAlignFormData, setCloneFormData } = useContext(
    SettingMultiWebContext
  );
  const parentValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const childValidator = useRef(new SimpleReactValidator());
  const {
    fromData,
    setFormData,
    formDataChild,
    setFormDataChild,
    id,
    setId,
    setIsEdit,
    isEdit,
    setFilter,
    apiDataOurTeam: apiDataImageParent,
    setApiDataOurTeam: setApiDataImageParent,
    setSkill,
    setGalleryImgId,
    setSettingFormData,
    settingFormData,
    isParent,
    setIsParent,
    childId,
    setChildId,
    navMapId,
    setNavMapId,
    setBackImageToggle,
    boxSection,
    setBoxSection,
    collId,
    setCollId,
    isBackgroundColor,
    setIsBackgroundColor,
    backImageToggle2,
    setBackImageToggle2,
    setTheme,
    isImageUpload,
    setIsImageUpload,
    backImageToggle,
    setImgShow,
    sectionPageName,
    setSectionPageName,
  } = useContext(FaqsContext);
  const { setLoaderShow } = useContext(LoaderContext);
  /**
   * get faq's parent list
   * @param {status} status
   */
  const getFaqsParentList = async ({
    parentId = null,
    isClose = false,
    filter,
  }) => {
    try {
      let paramObj = {};
      if (
        ((parentId && parentId !== null) ||
          (id && isClose === false && isParent === false)) &&
        isParent === false
      ) {
        paramObj["search"] = { parentId: parentId || id, contentMapId };
        setId(parentId || id);
        if (filter?.length) {
          paramObj["search"] = { ...paramObj.search, title: filter };
        }
      } else {
        paramObj["search"] = { parentId: null, contentMapId };
      }
      const { data } = await getFaqs(paramObj);
      if (isCheckValue({ value: parentId })) {
        setChildId(parentId);
      }
      if (data.status === 200) {
        if (
          isParent === false &&
          ((parentId && parentId !== null) || id) &&
          isClose === false
        ) {
          setApiDataImageParent({
            ...apiDataImageParent,
            isLoading: false,
            data: data.payload.data.map((elem) => {
              return {
                id: elem?.title + elem?.faqsId,
                Id: elem?.faqsId,
                name: elem?.title,
                image: "",
                webId: elem?.webId,
                createdAt: elem?.createdAt,
                isActive: elem?.isActive,
                parentId: elem?.parentId,
              };
            }),
          });
          OpenModalCommonFunc(Cases.FAQS, setAllPopupState);
        } else if (parentId && parentId !== null) {
          OpenModalCommonFunc(Cases.FAQS, setAllPopupState);
          setApiDataImageParent({
            ...apiDataImageParent,
            isLoading: false,
            data: data.payload.data.map((elem) => {
              return {
                id: elem?.title + elem?.faqsId,
                Id: elem?.faqsId,
                name: elem?.title,
                image: elem?.image,
                webId: elem?.webId,
                createdAt: elem?.createdAt,
                isActive: elem?.isActive,
                parentId: elem?.parentId,
              };
            }),
          });
        } else {
          setApiDataImageParent({
            ...apiDataImageParent,
            isLoading: false,
            data: data.payload.data,
          });
        }
        if (isClose && isClose !== false) {
          setIsParent(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * item faq's enable disable api call
   * @param {faqsId} faqsId
   * @param {status} isActive
   */

  const handleChangeFaqs = async (faqsId, isActive, isChild, parentId) => {
    try {
      let body = {
        faqsId,
        isActive: isActive ? false : true,
      };
      if (parentId) {
        body["parentId"] = parentId;
      }
      const { data } = await updateStatus(body);
      if (data.status === 200) {
        if (isChild) {
          getFaqsParentList({ parentId: id });
        } else {
          ByteMiniWeb_Dashboard();
        }
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * file upload
   * @param {file} file
   */
  const imageUploadImages = async (file) => {
    setLoaderShow(true);
    try {
      const Image = await imageUploadFunc(file);
      const ObjForImageSet = {
        theme: () =>
          setSettingFormData((prev) => {
            return {
              ...prev,
              ".faqs-section.bg-image": {
                "background-image": Image,
              },
            };
          }),
        detail: () =>
          setSettingFormData((prev) => {
            return {
              ...prev,
              faqsSideImage: Image,
            };
          }),
      };
      if (
        backImageToggle &&
        isImageUpload?.isList &&
        "isList" in isImageUpload
      ) {
        ObjForImageSet.theme();
      } else if (
        backImageToggle2 &&
        isImageUpload?.isDetails &&
        "isDetails" in isImageUpload
      ) {
        ObjForImageSet.detail();
      }
      setLoaderShow(false);
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const imageUploadFunc = async (file) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.FAQS);
    formData.append("file", file);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        return data.payload.baseUrl + data.payload.imageUrl;
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * create and update faq's
   */
  const submitForm = async () => {
    if (
      (isParent === false && childId >= -1 && isEdit) ||
      (id >= -1 && isEdit)
    ) {
      setLoaderShow(true);
      try {
        let formData = {};
        if (isParent && isParent !== false) {
          formData = {
            ...fromData,
            faqsId: id,
            contentMapId: collId,
            navMapId: +navMapIds,
          };
          delete formData?.style;
        } else {
          formData = {
            ...formDataChild,
            parentId: id,
            navMapId,
            faqsId: childId,
            contentMapId: collId,
          };
        }
        const { data } = await putUpdateFaqs(formData);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            ByteMiniWeb_Dashboard();
          } else {
            getFaqsParentList({ parentId: id });
          }
          emptyData();
          OpenModalCommonFunc(Cases.FAQS_PARENT, setAllPopupState, false);
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    } else {
      try {
        setLoaderShow(true);
        let formData = {};
        if (isParent && isParent !== false) {
          formData = {
            ...fromData,
            contentMapId: collId,
            navMapId: +navMapIds,
          };
        } else {
          formData = {
            ...formDataChild,
            parentId: id,
            navMapId,
            order: apiDataImageParent.data.length + 1,
            contentMapId: collId,
          };
        }
        const { data } = await postFaqs(formData);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            ByteMiniWeb_Dashboard();
          } else {
            getFaqsParentList({ parentId: id });
          }
          emptyData();
          OpenModalCommonFunc(Cases.FAQS_PARENT, setAllPopupState, false);
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };
  const handleSubmit = async (formValidationCondition) => {
    let validatorFields;
    if (formValidationCondition === "parentForm") {
      validatorFields = parentValidator.current;
    } else if (formValidationCondition === "childForm") {
      validatorFields = childValidator.current;
    }
    validatorFields.showMessages();

    if (validatorFields.allValid()) {
      try {
        // Process form data based on the formType
        if (formValidationCondition === "parentForm") {
          submitForm();
        } else if (formValidationCondition === "childForm") {
          submitForm();
        }
        validatorFields.hideMessages();
      } catch (error) {
        // Handle error for form submission
      }
    } else {
      // Force update to display validation messages
      forceUpdate({});
    }
  };

  /**
   * create and update faq's
   */
  const handleSubmitSetting = async (isRestore = false, isClose = true) => {
    if (id >= -1 && isEdit) {
      setLoaderShow(true);
      try {
        let newData;
        if (isRestore) {
          const isConfirm = window.confirm(
            "Are you sure want to restore style ?"
          );
          if (isConfirm) {
            newData = {
              styleRestore: true,
              faqsId: id,
            };
          } else {
            setLoaderShow(false);
            return;
          }
        } else {
          newData = {
            ...settingFormData,
            ".faqs-section": boxSection
              ? {
                  ...settingFormData[".faqs-section"],
                  padding: `${settingFormData[".faqs-section"]["padding"]
                    ?.split(" ")[0]
                    ?.replace("px", "")}px`,
                  width: `calc(100% - ${settingFormData[".faqs-section"][
                    "width"
                  ]?.replace("rem)", "")}rem)`,
                  margin: `${settingFormData[".faqs-section"]["margin"]
                    ?.split(" ")[0]
                    ?.replace("px", "")}px auto`,
                  "border-radius": `${settingFormData[".faqs-section"][
                    "border-radius"
                  ]?.replace("rem", "")}rem`,
                }
              : {
                  ...settingFormData[".faqs-section"],
                  padding: `${settingFormData[".faqs-section"]["padding"]
                    ?.split(" ")[0]
                    ?.replace("px", "")}px`,
                },
            ".faqs-section.bg-image": settingFormData[".faqs-section.bg-image"][
              "background-image"
            ]
              ? {
                  "background-image": settingFormData[".faqs-section.bg-image"][
                    "background-image"
                  ]
                    ? addUrlImage({
                        urlString: removeUrlImage({
                          urlString:
                            settingFormData[".faqs-section.bg-image"][
                              "background-image"
                            ],
                        }),
                      })
                    : "",
                }
              : {},

            ".faqs-section.bg-image::before": settingFormData[
              ".faqs-section.bg-image::before"
            ]["background-color"]
              ? {
                  "background-color": settingFormData[
                    ".faqs-section.bg-image::before"
                  ]["background-color"]
                    ? rgbColorCreate({
                        rgbString:
                          settingFormData[".faqs-section.bg-image::before"][
                            "background-color"
                          ],
                      })
                    : "",
                }
              : {},
            ".faqs-section .section-title-area": {
              ...settingFormData[".faqs-section .section-title-area"],
              "margin-bottom": `${settingFormData[
                ".faqs-section .section-title-area"
              ]["margin-bottom"]?.replace("px", "")}px`,
            },
            ".faqs-section .section-title-area .section-subtitle": {
              ...settingFormData[
                ".faqs-section .section-title-area .section-subtitle"
              ],
              "font-size": `${settingFormData[
                ".faqs-section .section-title-area .section-subtitle"
              ]["font-size"]?.replace("px", "")}px`,
              "font-weight": `${settingFormData[".faqs-section .section-title-area .section-subtitle"]["font-weight"]}`,
              color: settingFormData[
                ".faqs-section .section-title-area .section-subtitle"
              ]["color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[
                        ".faqs-section .section-title-area .section-subtitle"
                      ]["color"],
                  })
                : "",
              "background-color": settingFormData[
                ".faqs-section .section-title-area .section-subtitle"
              ]["background-color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[
                        ".faqs-section .section-title-area .section-subtitle"
                      ]["background-color"],
                  })
                : "",
              padding: `${settingFormData[
                ".faqs-section .section-title-area .section-subtitle"
              ]["p1"]?.replace("px", "")}px ${settingFormData[
                ".faqs-section .section-title-area .section-subtitle"
              ]["p2"]?.replace("px", "")}px`,
              "border-radius": `${settingFormData[
                ".faqs-section .section-title-area .section-subtitle"
              ]["border-radius"]?.replace("px", "")}px`,
              "margin-bottom": `${settingFormData[
                ".faqs-section .section-title-area .section-subtitle"
              ]["border-radius"]?.replace("px", "")}px`,
            },
            ".faqs-section .section-title-area .section-title": {
              ...settingFormData[
                ".faqs-section .section-title-area .section-title"
              ],
              "font-size": `${settingFormData[
                ".faqs-section .section-title-area .section-title"
              ]["font-size"]?.replace("px", "")}px`,
              "font-weight": `${settingFormData[".faqs-section .section-title-area .section-title"]["font-weight"]}`,
              color: settingFormData[
                ".faqs-section .section-title-area .section-title"
              ]["color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[
                        ".faqs-section .section-title-area .section-title"
                      ]["color"],
                  })
                : "",
            },
            ".faq-wrapper": {
              ...settingFormData[".faq-wrapper"],
              "background-color": settingFormData[".faq-wrapper"][
                "background-color"
              ]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[".faq-wrapper"]["background-color"],
                  })
                : "",
              padding: `${settingFormData[".faq-wrapper"]["padding"]?.replace(
                "px",
                ""
              )}px`,
              "border-top": `${settingFormData[".faq-wrapper"][
                "borderPx"
              ]?.replace("px", "")}px ${
                settingFormData[".faq-wrapper"]["borderThik"]
              } ${rgbColorCreate({
                rgbString: settingFormData[".faq-wrapper"]["borderColor"],
              })}`,
              "box-shadow": `${settingFormData[".faq-wrapper"]["bs1"]?.replace(
                "px",
                ""
              )}px ${settingFormData[".faq-wrapper"]["bs2"]?.replace(
                "px",
                ""
              )}px ${settingFormData[".faq-wrapper"]["bs3"]?.replace(
                "px",
                ""
              )}px ${settingFormData[".faq-wrapper"]["bs4"]?.replace(
                "px",
                ""
              )}px ${rgbColorCreate({
                rgbString: settingFormData[".faq-wrapper"]["shadowColor"],
              })}`,
              "border-radius": `${settingFormData[".faq-wrapper"][
                "border-radius"
              ]?.replace("px", "")}px`,
            },
            ".faq-wrapper .accordion-item": {
              ...settingFormData[".faq-wrapper .accordion-item"],
              "padding-top": `${settingFormData[".faq-wrapper .accordion-item"][
                "padding-top"
              ]?.replace("px", "")}px`,
              "padding-bottom": `${settingFormData[
                ".faq-wrapper .accordion-item"
              ]["padding-bottom"]?.replace("px", "")}px`,
              border: `${settingFormData[".faq-wrapper .accordion-item"][
                "borderPx"
              ]?.replace("px", "")}px ${
                settingFormData[".faq-wrapper .accordion-item"]["borderThik"]
              } ${rgbColorCreate({
                rgbString:
                  settingFormData[".faq-wrapper .accordion-item"][
                    "borderColor"
                  ],
              })}`,
              "border-bottom": `${settingFormData[
                ".faq-wrapper .accordion-item"
              ]["borderBtPx"]?.replace("px", "")}px ${
                settingFormData[".faq-wrapper .accordion-item"]["borderBtThik"]
              } ${rgbColorCreate({
                rgbString:
                  settingFormData[".faq-wrapper .accordion-item"][
                    "borderBtColor"
                  ],
              })}`,
            },
            ".faq-wrapper .accordion-item:last-child": {
              ...settingFormData[".faq-wrapper .accordion-item:last-child"],
              border: `${settingFormData[
                ".faq-wrapper .accordion-item:last-child"
              ]["borderPx"]?.replace("px", "")}px ${
                settingFormData[".faq-wrapper .accordion-item:last-child"][
                  "borderThik"
                ]
              } ${rgbColorCreate({
                rgbString:
                  settingFormData[".faq-wrapper .accordion-item:last-child"][
                    "borderColor"
                  ],
              })}`,
            },
            ".faq-wrapper .accordion-button": {
              ...settingFormData[".faq-wrapper .accordion-button"],
              outline: `${settingFormData[".faq-wrapper .accordion-button"][
                "outPx"
              ]?.replace("px", "")}px ${
                settingFormData[".faq-wrapper .accordion-button"]["outThik"]
              } ${rgbColorCreate({
                rgbString:
                  settingFormData[".faq-wrapper .accordion-button"]["outColor"],
              })}`,
              "box-shadow": `${settingFormData[
                ".faq-wrapper .accordion-button"
              ]["bs1"]?.replace("px", "")}px ${settingFormData[
                ".faq-wrapper .accordion-button"
              ]["bs2"]?.replace("px", "")}px ${settingFormData[
                ".faq-wrapper .accordion-button"
              ]["bs3"]?.replace("px", "")}px ${settingFormData[
                ".faq-wrapper .accordion-button"
              ]["bs4"]?.replace("px", "")}px ${rgbColorCreate({
                rgbString:
                  settingFormData[".faq-wrapper .accordion-button"][
                    "shadowColor"
                  ],
              })}`,
              "font-weight": `${settingFormData[".faq-wrapper .accordion-button"]["font-weight"]}`,
            },
            ".faq-wrapper .accordion-button:not(.collapsed)": {
              ...settingFormData[
                ".faq-wrapper .accordion-button:not(.collapsed)"
              ],
              color: settingFormData[
                ".faq-wrapper .accordion-button:not(.collapsed)"
              ]?.["color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[
                        ".faq-wrapper .accordion-button:not(.collapsed)"
                      ]?.["color"],
                  })
                : "",
              "background-color": settingFormData[
                ".faq-wrapper .accordion-button:not(.collapsed)"
              ]?.["background-color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[
                        ".faq-wrapper .accordion-button:not(.collapsed)"
                      ]?.["background-color"],
                  })
                : "",
            },
            ".faq-wrapper .accordion-body": {
              ...settingFormData[".faq-wrapper .accordion-body"],
              "line-height": `${settingFormData[".faq-wrapper .accordion-body"]["line-height"]}`,
            },
            faqsSideImage: settingFormData["faqsSideImage"]
              ? addUrlImage({
                  urlString: removeUrlImage({
                    urlString: settingFormData["faqsSideImage"],
                  }),
                })
              : "",
          };
          const menuBtnStyle = generateDynamicStyles(
            settingFormData,
            ".faqs-section"
          );
          newData = { ...newData, ...menuBtnStyle };
          if (isBackgroundColor) {
            newData[".faqs-section"] = {
              ...newData?.[".faqs-section"],
              "background-color": settingFormData[".faqs-section"]?.[
                "background-color"
              ]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[".faqs-section"]?.["background-color"],
                  })
                : "",
            };
          }
          if (boxSection) {
            newData[".faqs-section.bg-image::before"] = {
              ...newData[".faqs-section.bg-image::before"],
              "border-radius": `${settingFormData[".faqs-section"][
                "border-radius"
              ]?.replace("rem", "")}rem`,
            };
          }

          const keysToRemove = [
            "bs1",
            "bs2",
            "bs3",
            "bs4",
            "bss1",
            "bss2",
            "bss3",
            "bss4",
            "borderPx",
            "borderThik",
            "p1",
            "p2",
            "outPx",
            "outThik",
            "borderBtPx",
            "borderBtThik",
            "image",
          ];
          function removeKeys(obj, keys) {
            for (const key in obj) {
              if (typeof obj[key] === "object") {
                removeKeys(obj[key], keys);
              } else if (keys.includes(key)) {
                delete obj[key];
              }
            }
          }
          removeKeys(newData, keysToRemove);
          newData = {
            style: newData,
            faqsId: id,
          };
        }

        const { data } = await putUpdateFaqs(newData);
        if (data.status === 200) {
          if (isClose) {
            OpenModalCommonFunc(
              Cases.SETTING_FAQS_PARENT,
              setAllPopupState,
              false
            );
            setBackImageToggle(false);
            setBackImageToggle2(false);
            setBoxSection(false);
            setIsBackgroundColor(false);
          }
          setLoaderShow(false);
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };
  /**
   * get data faq's
   * @param {id} faqsId
   */
  const handleEditFaqs = async (faqsId, isParent) => {
    try {
      setLoaderShow(true);
      const { data } = await getFaqsDetails(faqsId);
      if (data.status === 200) {
        let payload = data.payload.data;
        if (isParent) {
          setSectionPageName(payload?.title);
          setId(faqsId);
          setIsParent(true);
          setFormData({
            title: payload?.title || "",
            subTitle: payload?.subTitle || "",
            slugUrl: payload?.slugUrl || "",
            theme: payload?.theme || "",
            buttonText: payload?.buttonText || "",
            itemLimit: payload?.itemLimit || "",
            isTitleShow: payload?.isTitleShow ? payload?.isTitleShow : false,
            navMapId: payload?.navMapId || "",
            webId: localStorage.getItem("webId"),
            style: payload?.style || "",
          });

          OpenModalCommonFunc(Cases.FAQS_PARENT, setAllPopupState);
        } else {
          setChildId(faqsId);
          setIsParent(false);
          setFormDataChild({
            title: payload?.title || "",
            subTitle: payload?.subTitle || "",
            description: payload?.description || "",
            isTitleShow: payload?.isTitleShow ? payload?.isTitleShow : false,
            parentId: payload?.parentId || "",
            order: payload?.order || 1,
            webId: localStorage.getItem("webId"),
          });
          OpenModalCommonFunc(Cases.FAQS, setAllPopupState);
        }
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * get setting data
   * @param {id} faqsId
   */
  const handleChangeSettings = async (faqsId, isThemeEdit = false) => {
    try {
      setLoaderShow(true);
      setId(faqsId);
      setSettingFormData((prev) => {
        return {
          ...prev,
          faqsId,
        };
      });
      const { data } = await getFaqsDetails(faqsId);
      if (data.status === 200) {
        let payload = data.payload.data;
        const themeCount = data?.payload?.data?.theme;
        setSectionPageName(payload.title);
        setTheme(themeCount);
        setImgShow(true);
        if (
          (+themeCount === ThemeNo.ThemeOne ||
            +themeCount === ThemeNo.ThemeTwo ||
            +themeCount === ThemeNo.ThemeThree) &&
          isEmptyObject(payload.style)
        ) {
          setSettingFormData({ ...FaqsTheme });
          setBoxSection(false);
          setBackImageToggle(false);
          setIsBackgroundColor(false);
        } else {
          if (
            payload.style?.[".faqs-section"]?.["width"] ||
            payload.style?.[".faqs-section"]?.["margin"] ||
            payload.style?.[".faqs-section"]?.["border-radius"]
          ) {
            setBoxSection(true);
          }
          if (payload.style?.[".faqs-section.bg-image"]?.["background-image"]) {
            setBackImageToggle(true);
          }
          if (payload.style?.[".faqs-section"]?.["background-color"]) {
            setIsBackgroundColor(true);
          }
          if (payload.style?.["faqsSideImage"]) {
            setBackImageToggle2(true);
          }
          const getMenuBtnStyle = generateDynamicStylesSet(
            payload,
            ".faqs-section"
          );
          setSettingFormData((prev) => {
            return {
              ...prev,
              ".faqs-section": {
                padding: payload.style?.[".faqs-section"]?.["padding"],
                "background-color": payload.style?.[".faqs-section"]?.[
                  "background-color"
                ]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[".faqs-section"]?.["background-color"],
                    })
                  : "",
                width: payload.style?.[".faqs-section"]?.["width"]?.replace(
                  "calc(100% - ",
                  ""
                ),
                margin: payload.style?.[".faqs-section"]?.["margin"],
                "border-radius":
                  payload.style?.[".faqs-section"]?.["border-radius"],
              },

              ".faqs-section.bg-image": {
                "background-image": payload.style?.[".faqs-section.bg-image"]?.[
                  "background-image"
                ]
                  ? removeUrlKeyInPath({
                      urlString:
                        payload.style?.[".faqs-section.bg-image"]?.[
                          "background-image"
                        ],
                    })
                  : "",
              },
              ".faqs-section.bg-image::before": {
                "background-color": payload.style?.[
                  ".faqs-section.bg-image::before"
                ]?.["background-color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[".faqs-section.bg-image::before"]?.[
                          "background-color"
                        ],
                    })
                  : "",
              },
              ".faqs-section .section-title-area": {
                "margin-bottom":
                  payload.style?.[".faqs-section .section-title-area"]?.[
                    "margin-bottom"
                  ],
              },
              ".faqs-section .section-title-area .section-subtitle": {
                "font-size":
                  payload.style?.[
                    ".faqs-section .section-title-area .section-subtitle"
                  ]?.["font-size"],
                "font-weight":
                  payload.style?.[
                    ".faqs-section .section-title-area .section-subtitle"
                  ]?.["font-weight"],
                color: payload.style?.[
                  ".faqs-section .section-title-area .section-subtitle"
                ]?.["color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          ".faqs-section .section-title-area .section-subtitle"
                        ]?.["color"],
                    })
                  : "",
                "background-color": payload.style?.[
                  ".faqs-section .section-title-area .section-subtitle"
                ]?.["background-color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          ".faqs-section .section-title-area .section-subtitle"
                        ]?.["background-color"],
                    })
                  : "",
                padding:
                  payload.style?.[
                    ".faqs-section .section-title-area .section-subtitle"
                  ]?.["padding"],
                p1: payload.style?.[
                  ".faqs-section .section-title-area .section-subtitle"
                ]?.["padding"]?.split(" ")[0],
                p2: payload.style?.[
                  ".faqs-section .section-title-area .section-subtitle"
                ]?.["padding"]?.split(" ")[1],
                "border-radius":
                  payload.style?.[
                    ".faqs-section .section-title-area .section-subtitle"
                  ]?.["border-radius"],
                "margin-bottom":
                  payload.style?.[
                    ".faqs-section .section-title-area .section-subtitle"
                  ]?.["margin-bottom"],
              },
              ".faqs-section .section-title-area .section-title": {
                "font-size":
                  payload.style?.[
                    ".faqs-section .section-title-area .section-title"
                  ]?.["font-size"],
                "font-weight":
                  payload.style?.[
                    ".faqs-section .section-title-area .section-title"
                  ]?.["font-weight"],
                color: payload.style?.[
                  ".faqs-section .section-title-area .section-title"
                ]?.["color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          ".faqs-section .section-title-area .section-title"
                        ]?.["color"],
                    })
                  : "",
              },
              ".faq-wrapper": {
                "background-color": payload.style?.[".faq-wrapper"]?.[
                  "background-color"
                ]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[".faq-wrapper"]?.["background-color"],
                    })
                  : "",
                padding: payload.style?.[".faq-wrapper"]?.["padding"],
                "border-top": payload.style?.[".faq-wrapper"]?.["border-top"],
                borderPx:
                  payload.style?.[".faq-wrapper"]?.["border-top"]?.split(
                    " "
                  )[0],
                borderThik:
                  payload.style?.[".faq-wrapper"]?.["border-top"]?.split(
                    " "
                  )[1],
                borderColor: payload.style?.[".faq-wrapper"]?.["borderColor"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[".faq-wrapper"]?.["borderColor"],
                    })
                  : "",
                "box-shadow": payload.style?.[".faq-wrapper"]?.["box-shadow"],
                bs1: payload.style?.[".faq-wrapper"]?.["box-shadow"]?.split(
                  " "
                )[0],
                bs2: payload.style?.[".faq-wrapper"]?.["box-shadow"]?.split(
                  " "
                )[1],
                bs3: payload.style?.[".faq-wrapper"]?.["box-shadow"]?.split(
                  " "
                )[2],
                bs4: payload.style?.[".faq-wrapper"]?.["box-shadow"]?.split(
                  " "
                )[3],
                shadowColor: payload.style?.[".faq-wrapper"]?.["shadowColor"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[".faq-wrapper"]?.["shadowColor"],
                    })
                  : "",
                "border-radius":
                  payload.style?.[".faq-wrapper"]?.["border-radius"],
              },
              ".faq-wrapper .accordion-item": {
                "padding-top":
                  payload.style?.[".faq-wrapper .accordion-item"]?.[
                    "padding-top"
                  ],
                "padding-bottom":
                  payload.style?.[".faq-wrapper .accordion-item"]?.[
                    "padding-bottom"
                  ],
                border:
                  payload.style?.[".faq-wrapper .accordion-item"]?.["border"],
                borderPx:
                  payload.style?.[".faq-wrapper .accordion-item"]?.[
                    "border"
                  ]?.split(" ")[0],
                borderThik:
                  payload.style?.[".faq-wrapper .accordion-item"]?.[
                    "border"
                  ]?.split(" ")[1],
                borderColor: payload.style?.[".faq-wrapper .accordion-item"]?.[
                  "borderColor"
                ]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[".faq-wrapper .accordion-item"]?.[
                          "borderColor"
                        ],
                    })
                  : "",
                "border-bottom":
                  payload.style?.[".faq-wrapper .accordion-item"]?.[
                    "border-top"
                  ],
                borderBtPx:
                  payload.style?.[".faq-wrapper .accordion-item"]?.[
                    "border-bottom"
                  ]?.split(" ")[0],
                borderBtThik:
                  payload.style?.[".faq-wrapper .accordion-item"]?.[
                    "border-bottom"
                  ]?.split(" ")[1],
                borderBtColor: payload.style?.[
                  ".faq-wrapper .accordion-item"
                ]?.["borderBtColor"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[".faq-wrapper .accordion-item"]?.[
                          "borderBtColor"
                        ],
                    })
                  : "",
              },
              ".faq-wrapper .accordion-item:last-child": {
                border:
                  payload.style?.[".faq-wrapper .accordion-item:last-child"]?.[
                    "border"
                  ],
                borderPx:
                  payload.style?.[".faq-wrapper .accordion-item:last-child"]?.[
                    "border"
                  ]?.split(" ")[0],
                borderThik:
                  payload.style?.[".faq-wrapper .accordion-item:last-child"]?.[
                    "border"
                  ]?.split(" ")[1],
                borderColor: payload.style?.[
                  ".faq-wrapper .accordion-item:last-child"
                ]?.["borderColor"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          ".faq-wrapper .accordion-item:last-child"
                        ]?.["borderColor"],
                    })
                  : "",
              },
              ".faq-wrapper .accordion-button": {
                outline:
                  payload.style?.[".faq-wrapper .accordion-button"]?.[
                    "outline"
                  ],
                outPx:
                  payload.style?.[".faq-wrapper .accordion-button"]?.[
                    "outline"
                  ]?.split(" ")[0],
                outThik:
                  payload.style?.[".faq-wrapper .accordion-button"]?.[
                    "outline"
                  ]?.split(" ")[1],
                outColor: payload.style?.[".faq-wrapper .accordion-button"]?.[
                  "outColor"
                ]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[".faq-wrapper .accordion-button"]?.[
                          "outColor"
                        ],
                    })
                  : "",
                "box-shadow":
                  payload.style?.[".faq-wrapper .accordion-button"]?.[
                    "box-shadow"
                  ],
                bs1: payload.style?.[".faq-wrapper .accordion-button"]?.[
                  "box-shadow"
                ]?.split(" ")[0],
                bs2: payload.style?.[".faq-wrapper .accordion-button"]?.[
                  "box-shadow"
                ]?.split(" ")[1],
                bs3: payload.style?.[".faq-wrapper .accordion-button"]?.[
                  "box-shadow"
                ]?.split(" ")[2],
                bs4: payload.style?.[".faq-wrapper .accordion-button"]?.[
                  "box-shadow"
                ]?.split(" ")[3],
                shadowColor: payload.style?.[
                  ".faq-wrapper .accordion-button"
                ]?.["shadowColor"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[".faq-wrapper .accordion-button"]?.[
                          "shadowColor"
                        ],
                    })
                  : "",
                "font-weight":
                  payload.style?.[".faq-wrapper .accordion-button"]?.[
                    "font-weight"
                  ],
              },
              ".faq-wrapper .accordion-button:not(.collapsed)": {
                color: payload.style?.[
                  ".faq-wrapper .accordion-button:not(.collapsed)"
                ]?.["color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          ".faq-wrapper .accordion-button:not(.collapsed)"
                        ]?.["color"],
                    })
                  : "",
                "background-color": payload.style?.[
                  ".faq-wrapper .accordion-button:not(.collapsed)"
                ]?.["background-color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          ".faq-wrapper .accordion-button:not(.collapsed)"
                        ]?.["background-color"],
                    })
                  : "",
              },
              ".faq-wrapper .accordion-body": {
                "line-height":
                  payload.style?.[".faq-wrapper .accordion-body"]?.[
                    "line-height"
                  ],
              },
              custom: payload?.style?.custom,
              faqsSideImage: payload.style?.["faqsSideImage"]
                ? removeUrlKeyInPath({
                    urlString: payload.style?.["faqsSideImage"],
                  })
                : "",
              ...getMenuBtnStyle,
            };
          });
        }
        if (!isThemeEdit) {
          OpenModalCommonFunc(Cases.SETTING_FAQS_PARENT, setAllPopupState);
          TostMessage({ message: data, type: "success" });
        }
        setIsEdit(true);
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * delete faq's
   * @param {id} faqsId
   */
  const handleDelete = async (faqsId, isParent = false) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await deleteFaqs(faqsId);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            // getFaqsParentList(null, true);
            ByteMiniWeb_Dashboard();
          } else {
            getFaqsParentList({ parentId: childId });
          }
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * clear data
   */
  const emptyData = () => {
    setIsEdit(false);
    setBoxSection(false);
    setBackImageToggle(false);
    setIsBackgroundColor(false);
    setIsParent(false);
    setChildId("");
    setFormData({
      title: "",
      subTitle: "",
      navMapId: "",
      slugUrl: "",
      buttonText: "",
      itemLimit: "",
      theme: 1,
      isTitleShow: true,
      webId: localStorage.getItem("webId"),
      style: {
        ".faqs-section": {
          padding: "100px 0",
          "background-color": "rgb(255 255 255 / 100)",
        },
        ".faqs-section.bg-image": {
          "background-image": "",
        },
        ".faqs-section.bg-image::before": {
          "background-color": "",
        },
        ".faqs-section .section-title-area": {
          "margin-bottom": "50px",
        },
        ".faqs-section .section-title-area .section-subtitle": {
          "font-size": "16px",
          "font-weight": "600",
          color: "rgb(255 73 124 / 100)",
          "background-color": "rgb(255 73 124 / 0.2)",
          padding: "5px 20px",
          p1: "5px",
          p2: "20px",
          "border-radius": "15px",
          "margin-bottom": "15px",
        },
        ".faqs-section .section-title-area .section-title": {
          "font-size": "44px",
          "font-weight": "600",
          color: "rgb(0 0 0 / 100)",
        },
        ".faq-wrapper": {
          "background-color": "rgb(255 255 255 / 100)",
          padding: "35px",
          "border-top": "3px solid rgb(255 73 124 / 100)",
          borderPx: "3px",
          borderThik: "solid",
          borderColor: "rgb(255 73 124 / 100)",
          "box-shadow": "0px 30px 70px 0px rgb(0 0 0 / 0.094)",
          bs1: "0px",
          bs2: "30px",
          bs3: "70px",
          bs4: "0px",
          shadowColor: "rgb(0 0 0 / 0.094)",
          "border-radius": "20px",
        },
        ".faq-wrapper .accordion-item": {
          "padding-top": "20px",
          "padding-bottom": "20px",
          border: "0px solid rgb(255 255 255 / 100)",
          borderPx: "0px",
          borderThik: "solid",
          borderColor: "rgb(255 255 255 / 100)",
          "border-bottom": "1px solid rgb(255 73 124 / 100)",
          borderBtPx: "1px",
          borderBtThik: "solid",
          borderBtColor: "rgb(255 73 124 / 100)",
        },
        ".faq-wrapper .accordion-item:last-child": {
          border: "0px solid rgb(255 255 255 / 100)",
          borderPx: "0px",
          borderThik: "solid",
          borderColor: "rgb(255 255 255 / 100)",
        },
        ".faq-wrapper .accordion-button": {
          outline: "0px solid rgb(255 255 255 / 100)",
          outPx: "0px",
          outThik: "solid",
          outColor: "rgb(255 255 255 / 100)",
          "box-shadow": "0px 0px 0px 0px rgb(0 0 0 / 0.094)",
          bs1: "0px",
          bs2: "0px",
          bs3: "0px",
          bs4: "0px",
          shadowColor: "rgb(0 0 0 / 0.094)",
          "font-weight": "500",
        },
        ".faq-wrapper .accordion-button:not(.collapsed)": {
          color: "rgb(255 255 255 / 100)",
          "background-color": "rgb(255 73 124 / 100)",
        },
        ".faq-wrapper .accordion-body": {
          "line-height": "1.4",
        },
        ".faqs-section .more-btn": {
          color: "rgb(255 255 255 / 100)",
          "font-size": "16px",
          "font-weight": "normal",
          "border-radius": "15px",
          padding: "5px 20px",
          "background-color": "rgb(255 73 124 / 100)",
        },
        ".faqs-section .more-btn:hover": {
          "background-color": "rgb(255 73 124 / 100)",
          color: "rgb(255 255 255 / 100)",
        },
        ".pagination-positions": {
          "justify-content": "center",
        },
        ".pagination .page-link": {
          color: "rgb(255 73 124 / 100)",
        },
        ".pagination .page-item.active .page-link": {
          "background-color": "rgb(255 73 124 / 100)",
          "border-color": "rgb(255 73 124 / 100)",
        },
        faqsId: "",
      },
    });
    setSkill([{ id: randomStr(2), skill: "", parentageOfSkill: "" }]);
    setGalleryImgId("");
    setFormDataChild({
      title: "",
      subTitle: "",
      description: "",
      isTitleShow: true,
      order: 1,
      webId: localStorage.getItem("webId"),
      navMapId: "",
    });
    setLoaderShow(false);
    setSettingFormData({
      ".faqs-section": {
        padding: "100px 0",
        "background-color": "rgb(255 255 255 / 100)",
      },
      ".faqs-section.bg-image": {
        "background-image": "",
      },
      ".faqs-section.bg-image::before": {
        "background-color": "",
      },
      ".faqs-section .section-title-area": {
        "margin-bottom": "50px",
      },
      ".faqs-section .section-title-area .section-subtitle": {
        "font-size": "16px",
        "font-weight": "600",
        color: "rgb(255 73 124 / 100)",
        "background-color": "rgb(255 73 124 / 0.2)",
        padding: "5px 20px",
        p1: "5px",
        p2: "20px",
        "border-radius": "15px",
        "margin-bottom": "15px",
      },
      ".faqs-section .section-title-area .section-title": {
        "font-size": "44px",
        "font-weight": "600",
        color: "rgb(0 0 0 / 100)",
      },
      ".faq-wrapper": {
        "background-color": "rgb(255 255 255 / 100)",
        padding: "35px",
        "border-top": "3px solid rgb(255 73 124 / 100)",
        borderPx: "3px",
        borderThik: "solid",
        borderColor: "rgb(255 73 124 / 100)",
        "box-shadow": "0px 30px 70px 0px rgb(0 0 0 / 0.094)",
        bs1: "0px",
        bs2: "30px",
        bs3: "70px",
        bs4: "0px",
        shadowColor: "rgb(0 0 0 / 0.094)",
        "border-radius": "20px",
      },
      ".faq-wrapper .accordion-item": {
        "padding-top": "20px",
        "padding-bottom": "20px",
        border: "0px solid rgb(255 255 255 / 100)",
        borderPx: "0px",
        borderThik: "solid",
        borderColor: "rgb(255 255 255 / 100)",
        "border-bottom": "1px solid rgb(255 73 124 / 100)",
        borderBtPx: "1px",
        borderBtThik: "solid",
        borderBtColor: "rgb(255 73 124 / 100)",
      },
      ".faq-wrapper .accordion-item:last-child": {
        border: "0px solid rgb(255 255 255 / 100)",
        borderPx: "0px",
        borderThik: "solid",
        borderColor: "rgb(255 255 255 / 100)",
      },
      ".faq-wrapper .accordion-button": {
        outline: "0px solid rgb(255 255 255 / 100)",
        outPx: "0px",
        outThik: "solid",
        outColor: "rgb(255 255 255 / 100)",
        "box-shadow": "0px 0px 0px 0px rgb(0 0 0 / 0.094)",
        bs1: "0px",
        bs2: "0px",
        bs3: "0px",
        bs4: "0px",
        shadowColor: "rgb(0 0 0 / 0.094)",
        "font-weight": "500",
      },
      ".faq-wrapper .accordion-button:not(.collapsed)": {
        color: "rgb(255 255 255 / 100)",
        "background-color": "rgb(255 73 124 / 100)",
      },
      ".faq-wrapper .accordion-body": {
        "line-height": "1.4",
      },
      ".faqs-section .more-btn": {
        color: "rgb(255 255 255 / 100)",
        "font-size": "16px",
        "font-weight": "normal",
        "border-radius": "15px",
        padding: "5px 20px",
        "background-color": "rgb(255 73 124 / 100)",
      },
      ".faqs-section .more-btn:hover": {
        "background-color": "rgb(255 73 124 / 100)",
        color: "rgb(255 255 255 / 100)",
      },
      ".pagination-positions": {
        "justify-content": "center",
      },
      ".pagination .page-link": {
        color: "rgb(255 73 124 / 100)",
      },
      ".pagination .page-item.active .page-link": {
        "background-color": "rgb(255 73 124 / 100)",
        "border-color": "rgb(255 73 124 / 100)",
      },
      faqsId: "",
    });
    setIsImageUpload({ isList: false, isDetails: false });
  };

  const value = useMemo(() => {
    let [label] = navbarData.filter(
      (elem) => elem.navbarDetail.label === titleUpdateKey.Certificates
    );
    return label;
  }, [navbarData]);

  const handleAlignAndCloneSection = ({ isClone = false }) => {
    if (isClone) {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          cloneModal: true,
        };
      });
      setCloneFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          pageConfigDetailsLabel: value?.label || pageConfigDetails[0]?.label,
          contentDetailName: contentDetail.name,
        };
      });
    } else {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          alignModal: true,
        };
      });
      setAlignFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          label: value?.label || pageConfigDetails[0]?.label,
        };
      });
    }
  };

  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody
            className="support-ticket-font box-border-div"
            style={{ padding: "20px" }}
          >
            <Row>
              <CardBuilderHeaderSection
                addBtnTitle={pageConfigDetails[0]?.label}
                name={value?.label || pageConfigDetails[0]?.label}
                isAdd={!faqsDetail && true}
                mainEditSection={true}
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
                openModal={() => {
                  OpenModalCommonFunc(Cases.FAQS_PARENT, setAllPopupState);
                  setSectionPageName(modalsName.FAQS_PARENT);
                  emptyData();
                  setIsParent(true);
                  setCollId(contentMapId);
                }}
                isCloneSection={true}
                isAlignSection={true}
                handleAlignAndCloneSection={handleAlignAndCloneSection}
              />
              {faqsDetail && (
                <Col xl="12 xl-50 box-col-6" md="6">
                  <Card className="ecommerce-widget mb-0">
                    <CardBody
                      className="support-ticket-font"
                      style={{ padding: "20px" }}
                    >
                      <div className="col-12">
                        <CardBuilderHeaderSection
                          name={faqsDetail?.title}
                          checked={faqsDetail?.isActive}
                          switchToggle={true}
                          id={faqsDetail?.faqsId}
                          handleChange={handleChangeFaqs}
                        />
                        <OpenPopupBtn
                          openPopup={() => {
                            setSectionPageName(faqsDetail.title);
                            getFaqsParentList({
                              parentId: faqsDetail.faqsId,
                            });
                            setNavMapId(faqsDetail?.navMapId);
                            setFilter("");
                            setCollId(contentMapId);
                          }}
                          name={`Add or View ${faqsDetail?.title}`}
                        />
                      </div>
                      <div className="d-flex justify-content-end ">
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleEditFaqs(faqsDetail.faqsId, true);
                            setCollId(contentMapId);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => handleDelete(faqsDetail.faqsId, true)}
                        >
                          <Trash />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleChangeSettings(faqsDetail.faqsId);
                            setTheme(faqsDetail?.theme || "");
                          }}
                        >
                          <Settings />
                        </IconButton>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
              <DeleteSection
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
              />
            </Row>
          </CardBody>
        </Card>
      </Col>
      <CommonPopup
        open={allPopupState.faqsChildPopup}
        close={() => {
          OpenModalCommonFunc(Cases.FAQS, setAllPopupState, false);
          setIsParent(false);
        }}
        // apiCall={getFaqsParentList}
        modalName={sectionPageName ? sectionPageName : modalsName.FAQS}
        modalCase={Cases.FAQS}
        handleSubmit={handleSubmit}
        validationForm={"childForm"}
        emptyData={emptyData}
        inputData={fromData}
        edit={false}
        oldTitle={titleUpdateKey.isGallery}
        label={titleUpdateKey.Certificates}
        getData={getData}
        content={
          <FaqsPopup
            getFaqsParentList={getFaqsParentList}
            handleChangeStatus={handleChangeFaqs}
            handleEdit={handleEditFaqs}
            imageUpload={imageUploadImages}
            emptyData={emptyData}
            simpleValidator={childValidator}
            webMasterDetail={webMasterDetail}
            handleDelete={handleDelete}
          />
        }
      />

      {/* item faqs popup */}
      <CommonPopup
        open={allPopupState.faqsPopup}
        close={() => {
          OpenModalCommonFunc(Cases.FAQS_PARENT, setAllPopupState, false);
          setIsParent(false);
        }}
        modalName={sectionPageName ? sectionPageName : modalsName.FAQS_PARENT}
        modalCase={Cases.FAQS_PARENT}
        handleSubmit={handleSubmit}
        validationForm={"parentForm"}
        emptyData={emptyData}
        inputData={formDataChild}
        edit={false}
        content={<FaqsParentPopup simpleValidator={parentValidator} />}
      />

      {/* setting Service Counter parent popup */}
      <CommonPopup
        open={allPopupState.faqsParentPopup}
        close={() =>
          OpenModalCommonFunc(
            Cases.SETTING_FAQS_PARENT,
            setAllPopupState,
            false
          )
        }
        modalName={sectionPageName ? sectionPageName : modalsName.FAQS_PARENT}
        modalCase={Cases.SETTING_FAQS_PARENT}
        handleSubmit={handleSubmitSetting}
        emptyData={emptyData}
        inputData={formDataChild}
        edit={false}
        content={
          <SettingFaqsPerentPopup
            imageUpload={imageUploadImages}
            handleSubmitSetting={handleSubmitSetting}
            handleChangeSettings={handleChangeSettings}
          />
        }
      />
    </>
  );
};

export default Faqs;
