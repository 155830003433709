import React, { useContext } from "react";
import { Col, Container, Input, Label, Row } from "reactstrap";
import { Truncate, maxLengthCheck } from "../../../../utils/helper";
import Dividers from "../../../common/divider";
import SeoDetailsContext from "../../../context/SeoDetailsContext/SeoDetailsContext";

const SeoDetailsPopup = (props) => {
  const { simpleValidator } = props;
  const { fromData, setFormData } = useContext(SeoDetailsContext);

  /**
   * input value change
   * @param {event} e
   */
  const handlevalueChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  return (
    <Container>
      <Row>
        <Col md="12 mb-3">
          <Label htmlFor="validationCustom03">
            {"Company Name | City Name *"}
          </Label>
          <Input
            className="form-control"
            name="name"
            type="text"
            placeholder="Company Name | City Name *"
            min={0}
            maxLength={60}
            onInput={maxLengthCheck}
            value={fromData.name}
            onChange={(e) => handlevalueChange(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
          <div className="text-end mt-3 ">
            <span>{fromData?.name?.length} / 60</span>
          </div>
          <p style={{ color: "red" }}>
            {simpleValidator.current.message(
              "Company Name | City Name",
              fromData?.name,
              "required"
            )}
          </p>
        </Col>
        <Col md="12 mb-3">
          <Label htmlFor="validationCustom03">{"Description *"}</Label>
          <textarea
            className="form-control"
            name="description"
            type="text"
            rows={2}
            placeholder="Description *"
            value={fromData.description}
            onChange={(e) => handlevalueChange(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
          <div className="text-end mt-3">
            <span>{fromData?.description?.length || 0}</span>
          </div>
          <p style={{ color: "red" }}>
            {simpleValidator.current.message(
              "Description",
              fromData?.description,
              "required"
            )}
          </p>
        </Col>
      </Row>
      <div className="mt-4 mb-4 ">
        <Dividers divide="Header Section" />
      </div>
      <Row>
        <Col md="6 mb-3">
          <Label htmlFor="validationCustom03">{"keywords *"}</Label>
          <textarea
            className="form-control"
            name="keywords"
            type="text"
            placeholder="keywords"
            value={fromData.keywords}
            onChange={(e) => handlevalueChange(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
          <p style={{ color: "red" }}>
            {simpleValidator.current.message(
              "keywords",
              fromData?.keywords,
              "required"
            )}
          </p>
        </Col>
        <Col md="6 mb-3">
          <Label htmlFor="validationCustom03">{"Canonical"}</Label>
          <textarea
            className="form-control"
            name="canonical"
            type="text"
            placeholder="Canonical"
            value={fromData.canonical}
            onChange={(e) => handlevalueChange(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="6 mb-3">
          <Label htmlFor="validationCustom03">{"Bing"}</Label>
          <textarea
            className="form-control"
            name="bing"
            type="text"
            placeholder="Bing"
            value={fromData.bing}
            onChange={(e) => handlevalueChange(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="6 mb-3">
          <Label htmlFor="validationCustom03">
            {"Google Site Verification"}
          </Label>
          <textarea
            className="form-control"
            name="googleSiteVerification"
            type="text"
            placeholder="Google Site Verification"
            value={fromData.googleSiteVerification}
            onChange={(e) => handlevalueChange(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="6 mb-3">
          <Label htmlFor="validationCustom03">{"Web Site Schema"}</Label>
          <textarea
            className="form-control"
            name="webSiteSchema"
            type="text"
            placeholder="Web Site Schema"
            value={fromData.webSiteSchema}
            onChange={(e) => handlevalueChange(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="6 mb-3">
          <Label htmlFor="validationCustom03">{"Bread Crumb Schema"}</Label>
          <textarea
            className="form-control"
            name="breadCrumbSchema"
            type="text"
            placeholder="Bread Crumb Schema"
            value={fromData.breadCrumbSchema}
            onChange={(e) => handlevalueChange(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="6 mb-3">
          <Label htmlFor="validationCustom03">{"Facebook Pixel"}</Label>
          <textarea
            className="form-control"
            name="facebookPixel"
            type="text"
            placeholder="Facebook Pixel"
            value={fromData.facebookPixel}
            onChange={(e) => handlevalueChange(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="6 mb-3">
          <Label htmlFor="validationCustom03">{"Author"}</Label>
          <Input
            className="form-control"
            name="author"
            type="text"
            placeholder="Author"
            value={fromData.author}
            onChange={(e) => handlevalueChange(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <div className="mt-4 mb-4 ">
        <Dividers divide="Og Tags" />
      </div>
      <Row>
        <Col md="6 mb-3">
          <Label htmlFor="validationCustom03">{"Title *"}</Label>
          <Input
            className="form-control"
            name="title"
            type="text"
            placeholder="Title"
            value={fromData.title}
            onChange={(e) => handlevalueChange(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
          <p style={{ color: "red" }}>
            {simpleValidator.current.message(
              "Title",
              fromData.title,
              "required"
            )}
          </p>
        </Col>
        <Col md="6 mb-3">
          <Label htmlFor="validationCustom03">{"Url *"}</Label>
          <Input
            className="form-control"
            name="url"
            type="text"
            placeholder="Url"
            value={fromData.url}
            onChange={(e) => handlevalueChange(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
          <p style={{ color: "red" }}>
            {simpleValidator.current.message("url", fromData.url, "required")}
          </p>
        </Col>
        <Col md="6 mb-3">
          <Label htmlFor="validationCustom03">{"Site Name"}</Label>
          <Input
            className="form-control"
            name="siteName"
            type="text"
            placeholder="Site Name"
            value={fromData.siteName}
            onChange={(e) => handlevalueChange(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <div className="mt-4 mb-4 ">
        <Dividers divide="Body" />
      </div>
      <Row>
        <Col md="6 mb-3">
          <Label htmlFor="validationCustom03">{"Google Tag Manage"}</Label>
          <textarea
            className="form-control"
            name="googleTagManage"
            type="text"
            placeholder="Google Tag Manage"
            value={fromData.googleTagManage}
            onChange={(e) => handlevalueChange(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      {/* <div className="mt-4 mb-4 ">
        <Dividers divide="SEO TAGS" />
      </div>

      <p style={{ fontSize: "16px", fontWeight: "500" }} className="mb-3">
        Following meta tags will be generated for SEO
      </p>
      <div className="mb-5">
        <h6 className="mb-2">
          <span style={{ color: "#3f51b5" }}>{"<title>"}</span>
          <span style={{ color: "grey" }}>
            {fromData.name ? Truncate(fromData.name, 30) : ""}
          </span>
          <span style={{ color: "#3f51b5" }}>{"</title>"}</span>
        </h6>
        <h6 className="mb-2">
          <span
            style={{ color: "#3f51b5" }}
          >{`<meta name="description" content="`}</span>
          <span style={{ color: "grey" }}>
            {fromData.description ? Truncate(fromData.description, 30) : ""}
          </span>
          <span style={{ color: "#3f51b5" }}>{'">'}</span>
        </h6>
        <h6 className="mb-2">
          <span
            style={{ color: "#3f51b5" }}
          >{`<meta name="og:title" content="`}</span>
          <span style={{ color: "grey" }}>
            {fromData.name ? Truncate(fromData.name, 30) : ""}
          </span>
          <span style={{ color: "#3f51b5" }}>{'">'}</span>
        </h6>
        <h6 className="mb-2">
          <span
            style={{ color: "#3f51b5" }}
          >{`<meta name="og:description" content="`}</span>
          <span style={{ color: "grey" }}>
            {fromData.description ? Truncate(fromData.description, 30) : ""}
          </span>
          <span style={{ color: "#3f51b5" }}>{'">'}</span>
        </h6>
        <h6 className="mb-2">
          <span
            style={{ color: "#3f51b5" }}
          >{`<meta name="og:image" content="`}</span>
          <span style={{ color: "grey" }}>Your Logo</span>
          <span style={{ color: "#3f51b5" }}>{'">'}</span>
        </h6>
        <h6 className="mb-2">
          <span
            style={{ color: "#3f51b5" }}
          >{`<meta name="og:url" content="`}</span>
          <span style={{ color: "grey" }}>Your ByteCard URL</span>
          <span style={{ color: "#3f51b5" }}>{'">'}</span>
        </h6>
        <h6 className="mb-2">
          <span
            style={{ color: "#3f51b5" }}
          >{`<meta name="og:type" content="`}</span>
          <span style={{ color: "grey" }}>website</span>
          <span style={{ color: "#3f51b5" }}>{'">'}</span>
        </h6>
        <h6 className="mb-2">
          <span
            style={{ color: "#3f51b5" }}
          >{`<meta name="og:site_name" content="`}</span>
          <span style={{ color: "grey" }}>website</span>
          <span style={{ color: "#3f51b5" }}>{'">'}</span>
        </h6>
      </div> */}
    </Container>
  );
};

export default SeoDetailsPopup;
