import React from "react";
import Routers from "./route";
import { Provider } from "react-redux";
import store from "./store";
// import { Auth0Provider } from "@auth0/auth0-react";
// import { auth0 } from "./data/config";
import AdminDeshboardState from "./context/AdminDeshboardContext/AdminDeshboardState";
import PrivillageMasterState from "./context/PrivillageMasterContext/PrivillageMasterState";
import ModualManagerState from "./context/ModuleManagerContext/ModualManagerState";
import ProfileComponentState from "./context/ProfilecomponentContext/PorileComponentState";
import PersonalPageState from "./context/PersonalPageContext/PersonalPAgeState";
import HomePageState from "./context/HomepageContext/HomePageState";
import PageItemState from "./context/PageItemContext/PageItemState";
import OrganizationState from "./context/OrganizationContext/OrganizationState";
import SignState from "./context/signinContext/SignState";
import BrokerComponentState from "./context/BrokercomponentContext/BrokerComponentState";
import BrokerPageState from "./context/BrokerPageContext/BrokerPAgeState";
import BrokerItemState from "./context/BrokertemContext/BrokertemState";
import LoaderState from "./context/LoaderContext/LoaderState";
import { LoaderResponse } from "./Spinner";
import PageItemMasterState from "./context/PageItemMasterContext/PageItemMasterState";
import ByteCardBuilderState from "./components ByteCard/context/BytecardBilderContext/ByteCardBuilderState";
import ByteMiniWebDashboardState from "./components ByteCard/context/ByteMiniWeb_Dashboard_Context/ByteMiniWeb_Dashboard_State";
import SliderState from "./components ByteCard/context/SliderContext/SliderState";
import DetailsState from "./components ByteCard/context/DetailsContext/DetailsState";
import OurTeamState from "./components ByteCard/context/OurTeamContext/OurTeamState";
import AboutUsState from "./components ByteCard/context/AboutUsContext/AboutUsState";
import ContactUsState from "./components ByteCard/context/ContactUsContaxt/ContactUsState";
import DownloadsState from "./components ByteCard/context/DownloadesContext/DownloadsState";
import CompanyLogoState from "./components ByteCard/context/CompanyLogoContext/CompanyLogoState";
import VideosState from "./components ByteCard/context/VideosContext/VideosState";
import GalleryState from "./components ByteCard/context/GalleryContext/GalleryState";
import SeoDetailsState from "./components ByteCard/context/SeoDetailsContext/SeoDetailsState";
import TestimonialState from "./components ByteCard/context/TestimonialContext/TestimonialState";
import ProductState from "./components ByteCard/context/ProductContext/ProductState";
import SocialMediaState from "./components ByteCard/context/SocialMediaContext/SocialMediaState";
import PaymentState from "./components ByteCard/context/PaymentContext/PaymentState";
import SettingMiniWebState from "./context/SettingMiniWebContext/SettingMiniWebState";
import BrokerIncentiveState from "./context/BrokerIncentiveContext/BrokerIncentiveState";
import MultipageByteMiniWebDashboardState from "./components ByteCard/context/Multipage_ByteMiniWeb_Dashboard_Context/Multipage_ByteMiniWeb_Dashboard_State";
import LatestNewsState from "./components ByteCard/context/LatestNewsContext/LatestNewsState";
import ItemFeatureState from "./components ByteCard/context/ItemFeatureContext/ItemFeatureState";
import PackagesState from "./components ByteCard/context/PackagesContext/PackagesState";
import ServiceCounterState from "./components ByteCard/context/ServiceCounterContext/ServiceCounterState";
import OurClientState from "./components ByteCard/context/OurClientContext/OurClientState";
import Multipage_OurTeamState from "./components ByteCard/context/multipage_OurTeamContext/Multipege_OurTeamState";
import ImageBannerState from "./components ByteCard/context/ImageBannerContext/ImageBannerState";
import VideoBannerState from "./components ByteCard/context/VideoBannerContext/VideoBannerState";
import FaqsState from "./components ByteCard/context/FaqsContext/FaqsState";
import StepsState from "./components ByteCard/context/StepsContext/StepsState";
import BlogState from "./components ByteCard/context/BlogContext/BlogState";
import CareerState from "./components ByteCard/context/CareerContext/CareerState";
import ServiceAndCategoryState from "./components ByteCard/context/ServiceAndCatagoryContext/ServiceAndCategoryState";
import SliderMultipageState from "./components ByteCard/context/SliderMultipageContext/SldierMultipageState";
import GalleryMultipageState from "./components ByteCard/context/GalleryMultipageContext/GalleryMultipageState";
import SeoDetailsMultipageState from "./components ByteCard/context/SeoDetailsMultipageContext/SeoDetailsMultipageState";
import DownloadMultipageState from "./components ByteCard/context/DownloadMultipageContext/DownloadMultipageState";
import SocialMediaMultipageState from "./components ByteCard/context/SocialMediaMultipageContext/SocialMediaMultipageState";
import ContactUsMultipageState from "./components ByteCard/context/ContactUsMultipageContaxt/ContactUsMultipageState";
import TestimonialMultipageState from "./components ByteCard/context/TestimonialMultipageContext/TestimonialMultipageState";
import GetWhatsAppLinkState from "./components ByteCard/context/GetWhatsAppLinkContext/GetWhatsAppLinkState";
import PageTitleState from "./components ByteCard/context/pageTitleContext/PageTitleState";
import SettingMultiWebState from "./context/SettingMultiWebContext/SettingMultiWebState";
import InquiryState from "./components ByteCard/context/InquiryContext/InquiryState";
import ProductMultipageState from "./components ByteCard/context/ProductMultipageContext/ProductMultipageState";
import PopupDialogState from "./components ByteCard/context/PopupDialogContext/PopupDialogState";
import PlanMasterState from "./context/PlanMasterContext/PlanMasterState";
import ProductsState from "./context/MenuMasterContext/ProductsContext/ProductsState";
import AddOnProductState from "./context/MenuMasterContext/AddOnProductContext/AddOnProductState";
import CategoryState from "./context/MenuMasterContext/CategoryContext/CategoryState";
import TagsState from "./context/MenuMasterContext/TagsContext/TagsState";
import ThemeState from "./context/ThemeContext/ThemeState";
import ThemeMapState from "./context/ThemeMapContext/ThemeMapState";
import ProductBuyState from "./components ByteCard/context/ProductBuyContext/ProductBuyState";
import FooterState from "./components ByteCard/context/FooterContext/FooterState";
import HeaderState from "./components ByteCard/context/HeaderContext/HeaderState";
import WebCategoryMapState from "./context/WebCategoryMapContext/WebCategoryMapState";
import CloneCategoryState from "./context/CloneCategoryContext/CloneCategoryState";
import OrgPlanState from "./context/OrgPlanContext/OrgPlanState";
import FontListState from "./context/FontListContext/FontListState";
import CommonStylesChangesState from "./components ByteCard/context/CommonStylesChangesContext/CommonStylesChangesState";
import CustomFormState from "./components ByteCard/context/CustomFormContext/CustomFormState";
// import PopupDialogState from "./components ByteCard/context/PopupDialogContext/PopupDialogState";

const App = () => {
  return (
    <div className="App">
      {/* <Auth0Provider
      domain={auth0.domain}
      clientId={auth0.clientId}
      redirectUri={auth0.redirectUri}
    > */}
      <Provider store={store}>
        <PaymentState>
          <SocialMediaState>
            <ProductMultipageState>
              <ProductState>
                <TestimonialState>
                  <SeoDetailsState>
                    <PackagesState>
                      <InquiryState>
                        <PageTitleState>
                          <TestimonialMultipageState>
                            <GetWhatsAppLinkState>
                              <ContactUsMultipageState>
                                <GalleryMultipageState>
                                  <SliderMultipageState>
                                    <CareerState>
                                      <StepsState>
                                        <FaqsState>
                                          <SeoDetailsMultipageState>
                                            <ServiceAndCategoryState>
                                              <BlogState>
                                                <VideoBannerState>
                                                  <ImageBannerState>
                                                    <Multipage_OurTeamState>
                                                      <OurClientState>
                                                        <ServiceCounterState>
                                                          <ItemFeatureState>
                                                            <LatestNewsState>
                                                              <GalleryState>
                                                                <VideosState>
                                                                  <CompanyLogoState>
                                                                    <DownloadsState>
                                                                      <ContactUsState>
                                                                        <AboutUsState>
                                                                          <OurTeamState>
                                                                            <DetailsState>
                                                                              <SliderState>
                                                                                <SocialMediaMultipageState>
                                                                                  <DownloadMultipageState>
                                                                                    <MultipageByteMiniWebDashboardState>
                                                                                      <ByteMiniWebDashboardState>
                                                                                        <SettingMiniWebState>
                                                                                          {/**byte mini web contexts */}
                                                                                          <ByteCardBuilderState>
                                                                                            <LoaderState>
                                                                                              <BrokerItemState>
                                                                                                <BrokerComponentState>
                                                                                                  <BrokerPageState>
                                                                                                    <SignState>
                                                                                                      <PageItemMasterState>
                                                                                                        <OrganizationState>
                                                                                                          <PageItemState>
                                                                                                            <HomePageState>
                                                                                                              <PersonalPageState>
                                                                                                                <ProfileComponentState>
                                                                                                                  <ModualManagerState>
                                                                                                                    <PrivillageMasterState>
                                                                                                                      <AdminDeshboardState>
                                                                                                                        <SettingMultiWebState>
                                                                                                                          <PopupDialogState>
                                                                                                                            <PlanMasterState>
                                                                                                                              <BrokerIncentiveState>
                                                                                                                                <ProductsState>
                                                                                                                                  <AddOnProductState>
                                                                                                                                    <CategoryState>
                                                                                                                                      <TagsState>
                                                                                                                                        <ThemeState>
                                                                                                                                          <ThemeMapState>
                                                                                                                                            <ProductBuyState>
                                                                                                                                              <FooterState>
                                                                                                                                                <HeaderState>
                                                                                                                                                  <WebCategoryMapState>
                                                                                                                                                    <CloneCategoryState>
                                                                                                                                                      <OrgPlanState>
                                                                                                                                                        <FontListState>
                                                                                                                                                          <CommonStylesChangesState>
                                                                                                                                                            <CustomFormState>
                                                                                                                                                              <LoaderResponse />
                                                                                                                                                              <Routers />
                                                                                                                                                            </CustomFormState>
                                                                                                                                                          </CommonStylesChangesState>
                                                                                                                                                        </FontListState>
                                                                                                                                                      </OrgPlanState>
                                                                                                                                                    </CloneCategoryState>
                                                                                                                                                  </WebCategoryMapState>
                                                                                                                                                </HeaderState>
                                                                                                                                              </FooterState>
                                                                                                                                            </ProductBuyState>
                                                                                                                                          </ThemeMapState>
                                                                                                                                        </ThemeState>
                                                                                                                                      </TagsState>
                                                                                                                                    </CategoryState>
                                                                                                                                  </AddOnProductState>
                                                                                                                                </ProductsState>
                                                                                                                              </BrokerIncentiveState>
                                                                                                                            </PlanMasterState>
                                                                                                                          </PopupDialogState>
                                                                                                                        </SettingMultiWebState>
                                                                                                                      </AdminDeshboardState>
                                                                                                                    </PrivillageMasterState>
                                                                                                                  </ModualManagerState>
                                                                                                                </ProfileComponentState>
                                                                                                              </PersonalPageState>
                                                                                                            </HomePageState>
                                                                                                          </PageItemState>
                                                                                                        </OrganizationState>
                                                                                                      </PageItemMasterState>
                                                                                                    </SignState>
                                                                                                  </BrokerPageState>
                                                                                                </BrokerComponentState>
                                                                                              </BrokerItemState>
                                                                                            </LoaderState>
                                                                                          </ByteCardBuilderState>
                                                                                        </SettingMiniWebState>
                                                                                      </ByteMiniWebDashboardState>
                                                                                    </MultipageByteMiniWebDashboardState>
                                                                                  </DownloadMultipageState>
                                                                                </SocialMediaMultipageState>
                                                                              </SliderState>
                                                                            </DetailsState>
                                                                          </OurTeamState>
                                                                        </AboutUsState>
                                                                      </ContactUsState>
                                                                    </DownloadsState>
                                                                  </CompanyLogoState>
                                                                </VideosState>
                                                              </GalleryState>
                                                            </LatestNewsState>
                                                          </ItemFeatureState>
                                                        </ServiceCounterState>
                                                      </OurClientState>
                                                    </Multipage_OurTeamState>
                                                  </ImageBannerState>
                                                </VideoBannerState>
                                              </BlogState>
                                            </ServiceAndCategoryState>
                                          </SeoDetailsMultipageState>
                                        </FaqsState>
                                      </StepsState>
                                    </CareerState>
                                  </SliderMultipageState>
                                </GalleryMultipageState>
                              </ContactUsMultipageState>
                            </GetWhatsAppLinkState>
                          </TestimonialMultipageState>
                        </PageTitleState>
                      </InquiryState>
                    </PackagesState>
                  </SeoDetailsState>
                </TestimonialState>
              </ProductState>
            </ProductMultipageState>
          </SocialMediaState>
        </PaymentState>
      </Provider>
      {/* </Auth0Provider> */}
    </div>
  );
};

export default App;
