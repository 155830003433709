import React, { useState } from "react";
import ContactUsContext from "./ContactUsContaxt";
import { randomStr } from "../../../utils/helper";

const ContactUsState = (props) => {
  const [apiData, setApiData] = useState({ data: [], isLoading: false });
  const [fromData, setFormData] = useState({
    name: "",
    addLine1: "",
    addLine2: "",
    area: "",
    country: "",
    state: "",
    city: "",
    pinCode: "",
    mapLink: "",
    phone: "",
    email: "",
    latitude: "",
    longitude: "",
    workingHour:
      "<p><strong>Working Hours</strong></p>\n\n<p><strong>Sunday</strong> : 09:00 AM - 01:00 PM<br />\n<strong>Monday</strong> : 09:00 AM - 01:00 PM<br />\n<strong>Tuesday</strong> :&nbsp;09:00 AM - 01:00 PM<br />\n<strong>Wednesday</strong> : 09:00 AM - 01:00 PM<br />\n<strong>Thursday</strong> :&nbsp;09:00 AM - 01:00 PM<br />\n<strong>Friday</strong> : 09:00 AM - 01:00 PM<br />\n<strong>Saturday</strong> : 09:00 AM - 01:00 PM</p>\n",
    webId: localStorage.getItem("webId"),
  });
  const [addFields, setAddFields] = useState({
    phoneInput: [
      {
        id: randomStr(2),
        phoneValue: "",
        dialCode: { value: "+91", label: "+91" },
      },
    ],
    emailInput: [{ id: randomStr(2), emailValue: "" }],
  });
  const [defaultSet, setDefaultSet] = useState(false);
  const [id, setId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [filter, setFilter] = useState("");

  return (
    <ContactUsContext.Provider
      value={{
        apiData,
        setApiData,
        fromData,
        setFormData,
        id,
        setId,
        isEdit,
        setIsEdit,
        filter,
        setFilter,
        addFields,
        setAddFields,
        defaultSet,
        setDefaultSet,
      }}
    >
      {props.children}
    </ContactUsContext.Provider>
  );
};

export default ContactUsState;
