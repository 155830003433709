import axios from "axios";
import {
  GET_PRODUCT_BUY,
  GET_PRODUCT_BUY_DETAILS,
  POST_PRODUCT_BUY,
  PUT_PRODUCT_BUY_ORDER,
  PUT_STATUS_PRODUCT_BUY,
} from "..";

/**
 * get product data
 * @param {Parameters} paramObj
 * @returns
 */
export const getProduct = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_PRODUCT_BUY}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * enable-disable
 * @param {body} body
 * @returns
 */
export const updateStatus = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_STATUS_PRODUCT_BUY}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * create product
 * @param {body} body
 * @returns
 */
export const postProductBuy = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${POST_PRODUCT_BUY}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * update product
 * @param {body} body
 * @returns
 */
export const putUpdateProductBuy = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${POST_PRODUCT_BUY}`,
    body,
    {
      headers: { Authorization: jwt_token },
      params: { productBuyId: body.productBuyId },
    }
  );
};

/**
 * delete product
 * @param {id} productBuyId
 * @returns
 */
export const deleteProductBuy = (productBuyId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${POST_PRODUCT_BUY}`, {
    headers: { Authorization: jwt_token },
    params: { productBuyId },
  });
};

/**
 * get product details
 * @param {id} productBuyId
 * @returns
 */
export const getProductBuyDetails = (productBuyId, navMapId) => {
  const jwt_token = localStorage.getItem("token");
  let paramObj = {
    productBuyId,
  };
  if (navMapId) {
    paramObj["navMapId"] = navMapId;
  }
  return axios.get(
    `${process.env.REACT_APP_API_URL}${GET_PRODUCT_BUY_DETAILS}`,
    {
      headers: { Authorization: jwt_token },
      params: paramObj,
    }
  );
};

/**
 * sort order products
 * @param {body} updateOrder
 * @returns
 */
export const sort_order_product = (updateOrder) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_PRODUCT_BUY_ORDER}`,
    { updateOrder: updateOrder },
    {
      headers: { Authorization: jwt_token },
    }
  );
};
