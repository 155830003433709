import React, { useContext } from "react";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import SocialMediaMultipageContext from "../../../context/SocialMediaMultipageContext/SocialMediaMultipageContext";
import { OpenModalCommonFunc } from "../../../utils";
import { Cases, DELETE_CONFIRM } from "../../../constant";
import Dividers from "../../../common/divider";
import { Zoom, toast } from "react-toastify";
import { delete_Multi_Image_Permanent } from "../apis/SliderApi/SliderApi";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { Close } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { UploadCloud } from "react-feather";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import { Switch } from "@material-ui/core";
import CommonColor from "../../../common/CommonColor";
import CustomStyle from "../../../common/CustomStyle";

const SocialMedialThemeOne = (props) => {
  const {
    isEdit,
    settingFormData,
    setSettingFormData,
    setBackImg,
    isBackgroundColor,
    setIsBackgroundColor,
  } = useContext(SocialMediaMultipageContext);
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const { imageUpload, handleSubmitSetting } = props;
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * input value change
   * @param {event} e
   */

  const handleChangeCustomFootSocial = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".custom-footer .foot-social"]: {
          ...prev[".custom-footer .foot-social"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeCustomStyle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  /**
   * delete image permanently
   */
  const deleteImagePermanent = async (whichImage) => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData;
        if (whichImage == "before") {
          formData = {
            fileName: settingFormData?.[".custom-footer .foot-social:before"]?.[
              "background-image"
            ]
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
              .pop(),
          };
        } else {
          formData = {
            fileName: settingFormData?.[".custom-footer .foot-social:after"]?.[
              "background-image"
            ]
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
              .pop(),
          };
        }

        const { data } = await delete_Multi_Image_Permanent(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            position: "top-center",
          });
          if (whichImage == "before") {
            setSettingFormData({
              ...settingFormData,
              ".custom-footer .foot-social:before": { "background-image": "" },
            });
          } else {
            setSettingFormData({
              ...settingFormData,
              ".custom-footer .foot-social:after": { "background-image": "" },
            });
          }
          setLoaderShow(false);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };

  /**
   * image reupload
   */
  const reupload = () => {
    setSettingFormData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };

  return (
    <>
      <Row className="mt-4">
        <h4 className="mb-3">Change background color in main section</h4>
        <Col md="4 mb-3">
          <div className="d-flex align-items-center">
            <Label htmlFor="validationCustom03">
              {isBackgroundColor
                ? "Add Background Color"
                : "Remove Background Color"}
            </Label>
            <Switch
              checked={isBackgroundColor}
              inputProps={{ "aria-label": "controlled" }}
              onChange={(e) => {
                setIsBackgroundColor(e.target.checked);
                setSettingFormData((prev) => {
                  if (e.target.checked) {
                    return {
                      ...prev,
                      [".custom-footer"]: {
                        "background-color": "#ffffff",
                      },
                    };
                  } else {
                    return {
                      ...prev,
                      [".custom-footer"]: {},
                    };
                  }
                });
              }}
            />
          </div>
          {isBackgroundColor && (
            <CommonColor
              color={settingFormData?.[".custom-footer"]?.["background-color"]}
              setSettingFormData={setSettingFormData}
              className={".custom-footer"}
              classProperty={"background-color"}
              label={"Select Background Color"}
            />
          )}
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in custom-footer | social</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Padding (default : 70)"}</Label>
          <Input
            className="form-control"
            name="padding"
            type="number"
            placeholder="padding"
            value={settingFormData[".custom-footer .foot-social"]["padding"]
              ?.split(" ")[0]
              ?.replace(/px/g, "")}
            onChange={(e) => handleChangeCustomFootSocial(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".custom-footer .foot-social"]?.[
                "background-color"
              ]
            }
            setSettingFormData={setSettingFormData}
            className={".custom-footer .foot-social"}
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
      </Row>
      <hr />

      <Row className="mt-4">
        <Col md="12 mb-3">
          <h4 className="mb-3">Add image in custom-footer | social:before</h4>

          <Col md="6 mb-3">
            <Dividers divide="IMAGE" />
            <p className="mb-4">File type required JPG, PNG (512px X 512px)</p>
          </Col>
          <Row>
            <Col md="4 mb-3">
              <div
                className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                style={{ cursor: "pointer" }}
              >
                {!settingFormData?.[".custom-footer .foot-social:before"]?.[
                  "background-image"
                ] && (
                  <span
                    onClick={() => {
                      OpenModalCommonFunc(
                        Cases.PRODUCT_UPLOAD,
                        setAllPopupState
                      );
                      setBackImg(".custom-footer .foot-social:before");
                    }}
                  >
                    <UploadCloud />
                  </span>
                )}

                {settingFormData?.[".custom-footer .foot-social:before"]?.[
                  "background-image"
                ] && (
                  <img
                    src={
                      settingFormData?.[".custom-footer .foot-social:before"]?.[
                        "background-image"
                      ] || ""
                    }
                    alt=""
                    style={{
                      width: "80%",
                      height: "98%",
                      objectFit: "contain",
                      position: "absolute",
                    }}
                    onClick={() => {
                      deleteImagePermanent("before");
                    }}
                  />
                )}
                {settingFormData?.[".custom-footer .foot-social:before"]?.[
                  "background-image"
                ] && (
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="close"
                    style={{
                      color: "red",
                      position: "absolute",
                      top: "-22px",
                      left: "1px",
                    }}
                    onClick={() => deleteImagePermanent("before")}
                  >
                    <Close
                      style={{
                        fontSize: "25px",
                        fontWeight: "bold",
                      }}
                    />
                  </IconButton>
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <Col md="12 mb-3">
          <h4 className="mb-3">Add image in custom-footer | social:after</h4>

          <Col md="6 mb-3">
            <Dividers divide="IMAGE" />
            <p className="mb-4">File type required JPG, PNG (512px X 512px)</p>
          </Col>
          <Row>
            <Col md="4 mb-3">
              <div
                className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                style={{ cursor: "pointer" }}
              >
                {!settingFormData?.[".custom-footer .foot-social:after"]?.[
                  "background-image"
                ] && (
                  <span
                    onClick={() => {
                      OpenModalCommonFunc(
                        Cases.PRODUCT_UPLOAD,
                        setAllPopupState
                      );
                      setBackImg(".custom-footer .foot-social:after");
                    }}
                  >
                    <UploadCloud />
                  </span>
                )}

                {settingFormData?.[".custom-footer .foot-social:after"]?.[
                  "background-image"
                ] && (
                  <img
                    src={
                      settingFormData?.[".custom-footer .foot-social:after"]?.[
                        "background-image"
                      ] || ""
                    }
                    alt=""
                    style={{
                      width: "80%",
                      height: "98%",
                      objectFit: "contain",
                      position: "absolute",
                    }}
                    onClick={() => {
                      deleteImagePermanent("after");
                    }}
                  />
                )}
                {settingFormData?.[".custom-footer .foot-social:after"]?.[
                  "background-image"
                ] && (
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="close"
                    style={{
                      color: "red",
                      position: "absolute",
                      top: "-22px",
                      left: "1px",
                    }}
                    onClick={() => deleteImagePermanent("after")}
                  >
                    <Close
                      style={{
                        fontSize: "25px",
                        fontWeight: "bold",
                      }}
                    />
                  </IconButton>
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">
          Make change in custom-footer | social buttons text
        </h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".custom-footer .foot-social .social_buttons a"
              ]?.["background-color"]
            }
            setSettingFormData={setSettingFormData}
            className={".custom-footer .foot-social .social_buttons a"}
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".custom-footer .foot-social .social_buttons a"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".custom-footer .foot-social .social_buttons a"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <CustomStyle
          handleChange={handleChangeCustomStyle}
          value={settingFormData?.custom}
        />
      </Row>
      <ProductImageUploadPopup
        allPopupState={allPopupState}
        setAllPopupState={setAllPopupState}
        crop={crop}
        setCrop={setCrop}
        completedCrop={completedCrop}
        setCompletedCrop={setCompletedCrop}
        scale={scale}
        rotate={rotate}
        image={settingFormData?.image}
        setImage={setSettingFormData}
        imageUpload={imageUpload}
        isEdit={isEdit}
        reupload={reupload}
        // aspect={1 / 1}
      />
    </>
  );
};

export default SocialMedialThemeOne;
