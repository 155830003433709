import React, { useContext, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import { Cases, DELETE_CONFIRM, modalsName } from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import CommonPopup from "../../../Modals/commonPopup";
import ServicePopup from "./ServicePopup";
import { FILE_TYPE, ThemeNo } from "../../../../constant";
import { FILE_UPLOAD_API } from "../apis/SliderApi/SliderApi";
import ServiceCategoryPopup from "./ServiceCategoryPopup";
import { IconButton } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import { Trash } from "react-feather";
import { Settings } from "react-feather";
import {
  addUrlImage,
  convertRGBA,
  generateDynamicStyles,
  generateDynamicStylesSet,
  isCheckValue,
  isEmptyObject,
  randomStr,
  removeUrlImage,
  removeUrlKeyInPath,
  rgbColorCreate,
} from "../../../../utils/helper";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import SimpleReactValidator from "simple-react-validator";
import {
  deleteServiceCategory,
  getServiceApi,
  getServiceCategoryDetails,
  getServiceDetails,
  postService,
  postServiceCategory,
  putUpdateService,
  putUpdateServiceCategory,
  updateStatus,
  updateStatusServiceCategory,
} from "../apis/ServiceAndCategoryApi/ServiceAndCategoryApi";
import ServiceAndCategoryContext from "../../../context/ServiceAndCatagoryContext/ServiceAndCategoryContext";
import SettingServiceAndCategotyParentPopup from "./SettingServiceAndCategotyParentPopup";
import {
  ServiceCategoryStyleFour,
  ServiceCategoryStyleTwo,
  serviceCategoryStyleEight,
  serviceCategoryStyleEleven,
  serviceCategoryStyleFourteen,
  serviceCategoryStyleNine,
  serviceCategoryStyleOne,
  serviceCategoryStyleSeven,
  serviceCategoryStyleSix,
  serviceCategoryStyleTen,
  serviceCategoryStyleThirteen,
  serviceCategoryStyleThree,
  serviceCategoryStyleTwelve,
  serviceDetailsExtraStyle,
  serviceStyleEighthSet,
  serviceStyleElevenSet,
  serviceStyleFourSet,
  serviceStyleFourteenSet,
  serviceStyleNineSet,
  serviceStyleOneSet,
  serviceStyleSevenSet,
  serviceStyleSixSet,
  serviceStyleTenSet,
  serviceStyleThirteenSet,
  serviceStyleThreeSet,
  serviceStyleTwelveSet,
  serviceStyleTwoSet,
  themEighthFunction,
  themElevenFunction,
  themFourteenFunction,
  themNineFunction,
  themTenFunction,
  themThirteenFunction,
  themTwelveFunction,
  themeFourFunction,
  themeOneFunction,
  themeSevenFunction,
  themeSixFunction,
  themeThreeFunction,
  themeTwoFunction,
} from "./ServiceAndCategoryStyleThemeFunction";
import DeleteSection from "../../../common/DeleteSection";
import SettingMultiWebContext from "../../../../context/SettingMultiWebContext/SettingMultiWebContext";

const ServiceCategory = (props) => {
  const {
    pageConfigDetails,
    serviceCategoryDetail,
    contentMapId,
    ByteMiniWeb_Dashboard,
    navMapId: navMapIds,
    contentDetail,
  } = props;
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );
  const { setCloneAlignModal, setAlignFormData, setCloneFormData } = useContext(
    SettingMultiWebContext
  );
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const simpleValidatorTeamMember = useRef(new SimpleReactValidator());
  const [, forceUpdate1] = useState();
  const { setLoaderShow } = useContext(LoaderContext);
  const {
    apiData: ourTeamData,
    setApiData,
    fromData,
    setFormData,
    formDataOurTeam,
    setFormDataOurData,
    id,
    setId,
    setIsEdit,
    isEdit,
    setSkill,
    teamMemberId,
    setTeamMemberId,
    settingFormData,
    setSettingFormData,
    backImageToggle,
    setBackImageToggle,
    setBackImageToggleDetail,
    boxSection,
    setBoxSection,
    setBoxSectionDetail,
    theme,
    setTheme,
    collId,
    setCollId,
    setServiceSearchId,
    boxSectionDetail,
    backImageToggleDetail,
    isImageUpload,
    setIsImageUpload,
    isBackgroundColor,
    isBackgroundColor2,
    setIsBackgroundColor,
    setIsBackgroundColor2,
    setImgShow,
    sectionPageName,
    setSectionPageName,
  } = useContext(ServiceAndCategoryContext);
  const [isUpload, setIsUpload] = useState(false);

  /**
   * get service list
   */
  const getService = async ({ serviceCategoryId, status, filter }) => {
    try {
      if (!filter?.length && status) {
        setApiData((prev) => {
          return { ...prev, isLoading: true };
        });
      }

      let paramObj = {
        search: { serviceCategoryId },
      };

      if (filter?.length) {
        paramObj.search = {
          ...paramObj.search,
          title: filter,
        };
      }

      const { data } = await getServiceApi(paramObj);
      if (data.status === 200) {
        setServiceSearchId(serviceCategoryId);
        if (isCheckValue({ value: contentMapId })) {
          setCollId(contentMapId);
        }
        setApiData({
          ...ourTeamData,
          isLoading: false,
          data: data.payload.data.map((elem) => {
            return {
              id: elem?.title + elem?.serviceId,
              Id: elem?.serviceId,
              name: elem?.title,
              image: elem?.imageThumbnail,
              webId: elem?.webId,
              headLine: elem?.headLine,
              createdAt: elem?.createdAt,
              isActive: elem?.isActive,
              parentId: elem?.parentId,
            };
          }),
        });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * service enable disable api call
   * @param {teamMember id} serviceId
   * @param {status} isActive
   */

  const handleChangeStatus = async (serviceId, isActive, isChild, parentId) => {
    try {
      let body = {
        serviceId,
        isActive: isActive ? false : true,
        parentId,
      };
      const { data } = await updateStatus(body);
      if (data.status === 200) {
        getService({ serviceCategoryId: id });
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };
  /**
   * service category enable disable api call
   * @param {team id} serviceCategoryId
   * @param {status} isActive
   */

  const handleChangeServiceCategoryStatus = async (
    serviceCategoryId,
    isActive
  ) => {
    try {
      let body = {
        serviceCategoryId,
        isActive: isActive ? false : true,
      };
      const { data } = await updateStatusServiceCategory(body);
      if (data.status === 200) {
        ByteMiniWeb_Dashboard();
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  const filedUploadFunc = async (file) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.SERVICE_CATEGORY);
    formData.append("file", file);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        return data.payload.baseUrl + data.payload.imageUrl;
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * file upload
   * @param {file} file
   */
  const imageUpload = async (file, name) => {
    setLoaderShow(true);
    try {
      const Image = await filedUploadFunc(file);
      setFormDataOurData((prev) => {
        return {
          ...prev,
          image: Image,
        };
      });
      setLoaderShow(false);
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * file upload
   * @param {file} file
   */
  const imageUploadServiceCategorySetting = async (file) => {
    setLoaderShow(true);
    try {
      const Image = await filedUploadFunc(file);
      const ObjForImageSet = {
        1: () =>
          setSettingFormData((prev) => {
            return {
              ...prev,
              ".service-section.bg-image": {
                "background-image": Image,
              },
            };
          }),
        2: () =>
          setSettingFormData((prev) => {
            return {
              ...prev,
              ".service2-section.bg-image": {
                "background-image": Image,
              },
            };
          }),
        3: () =>
          setSettingFormData((prev) => {
            return {
              ...prev,
              ".vision-section.bg-image": {
                "background-image": Image,
              },
            };
          }),
        4: () =>
          setSettingFormData((prev) => {
            return {
              ...prev,
              ".vision2-section.bg-image": {
                "background-image": Image,
              },
            };
          }),
        5: () =>
          setSettingFormData((prev) => {
            return {
              ...prev,
              ".service2-section.bg-image": {
                "background-image": Image,
              },
            };
          }),
        6: () =>
          setSettingFormData((prev) => {
            return {
              ...prev,
              ".vision-section6.bg-image": {
                "background-image": Image,
              },
            };
          }),
        7: () =>
          setSettingFormData((prev) => {
            return {
              ...prev,
              ".boxes1-section.bg-image": {
                "background-image": Image,
              },
            };
          }),
        8: () =>
          setSettingFormData((prev) => {
            return {
              ...prev,
              ".boxes2-section.bg-image": {
                "background-image": Image,
              },
            };
          }),
        9: () =>
          setSettingFormData((prev) => {
            return {
              ...prev,
              ".boxes3-section.bg-image": {
                "background-image": Image,
              },
            };
          }),
        10: () =>
          setSettingFormData((prev) => {
            return {
              ...prev,
              ".boxes4-section.bg-image": {
                "background-image": Image,
              },
            };
          }),
        11: () =>
          setSettingFormData((prev) => {
            return {
              ...prev,
              ".left-boxes1-section.bg-image": {
                "background-image": Image,
              },
            };
          }),
        12: () =>
          setSettingFormData((prev) => {
            return {
              ...prev,
              ".left-boxes2-section.bg-image": {
                "background-image": Image,
              },
            };
          }),
        13: () =>
          setSettingFormData((prev) => {
            return {
              ...prev,
              ".left-boxes3-section.bg-image": {
                "background-image": Image,
              },
            };
          }),
        true: () =>
          setSettingFormData((prev) => {
            return {
              ...prev,
              ".left-boxes2-section": {
                "background-image": Image,
              },
            };
          }),
      };
      if (backImageToggle && isImageUpload?.isList && "isList" in isImageUpload)
        ObjForImageSet[theme]();
      else if (
        backImageToggleDetail &&
        isImageUpload?.isDetails &&
        "isDetails" in isImageUpload
      )
        ObjForImageSet[backImageToggleDetail]();

      setLoaderShow(false);
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * file upload
   * @param {file} file
   */
  const imageUploadServiceCategory = async (file) => {
    setLoaderShow(true);
    try {
      const Image = await filedUploadFunc(file);
      if (isUpload) {
        setFormData((prev) => {
          return {
            ...prev,
            imageThumbnail: Image,
            image: "",
          };
        });
      } else {
        setFormData((prev) => {
          return {
            ...prev,
            logo: Image,
            image: "",
          };
        });
      }
      setLoaderShow(false);
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * create and update service
   */
  const handleSubmit = async () => {
    const formValid = simpleValidatorTeamMember.current.allValid();
    if (!formValid) {
      simpleValidatorTeamMember.current.showMessages();
      forceUpdate1(1);
    } else {
      if (
        isCheckValue({ value: teamMemberId }) &&
        teamMemberId >= -1 &&
        isEdit
      ) {
        setLoaderShow(true);
        let newData = {
          ...fromData,
          serviceCategoryId: id,
          serviceId: teamMemberId,
          contentMapId: collId,
        };
        delete newData.logo;
        delete newData.imageThumbnail;
        if (fromData.logo.length) {
          newData["image"] = fromData.logo
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
            .pop();
        }
        if (fromData.imageThumbnail.length) {
          newData["imageThumbnail"] = fromData.imageThumbnail
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
            .pop();
        }
        try {
          const { data } = await putUpdateService(newData);
          if (data.status === 200) {
            getService({ serviceCategoryId: id });
            emptyData();
            TostMessage({ message: data, type: "success" });
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      } else {
        try {
          setLoaderShow(true);
          let newData = {
            ...fromData,
            serviceCategoryId: id,
            order: ourTeamData.data.length + 1,
            contentMapId: collId,
          };
          delete newData.logo;
          delete newData.imageThumbnail;
          if (fromData.logo.length) {
            newData["image"] = fromData.logo
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
              .pop();
          }
          if (fromData.imageThumbnail.length) {
            newData["imageThumbnail"] = fromData.imageThumbnail
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
              .pop();
          }

          const { data } = await postService(newData);
          if (data.status === 200) {
            getService({ serviceCategoryId: id });
            emptyData();
            TostMessage({ message: data, type: "success" });
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      }
      simpleValidatorTeamMember.current.hideMessages();
      forceUpdate1(2);
    }
  };

  /**
   * create and update service category
   */
  const handleSubmitBlog = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      if (id >= -1 && isEdit) {
        setLoaderShow(true);
        let newData = {
          ...formDataOurTeam,
          contentMapId: collId,
          navMapId: +navMapIds,
        };
        delete newData.image;
        delete newData.style;
        if (formDataOurTeam.image?.length) {
          newData["image"] = formDataOurTeam.image
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
            .pop();
        }
        try {
          const { data } = await putUpdateServiceCategory(newData);
          if (data.status === 200) {
            ByteMiniWeb_Dashboard();
            emptyData();
            OpenModalCommonFunc(
              Cases.SERVICE_CATEGORY,
              setAllPopupState,
              false
            );
            TostMessage({ message: data, type: "success" });
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      } else {
        try {
          setLoaderShow(true);
          let newData = {
            ...formDataOurTeam,
            contentMapId: collId,
            navMapId: +navMapIds,
          };
          delete newData.image;
          if (formDataOurTeam.image?.length) {
            newData["image"] = formDataOurTeam.image
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
              .pop();
          }
          const { data } = await postServiceCategory(newData);
          if (data.status === 200) {
            ByteMiniWeb_Dashboard();
            emptyData();
            OpenModalCommonFunc(
              Cases.SERVICE_CATEGORY,
              setAllPopupState,
              false
            );
            TostMessage({ message: data, type: "success" });
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      }
      simpleValidator.current.hideMessages();
      forceUpdate(2);
    }
  };

  /**
   * get service details
   * @param {id} serviceId
   */
  const handleEdit = async (serviceId) => {
    try {
      setLoaderShow(true);
      setTeamMemberId(serviceId);
      const { data } = await getServiceDetails(serviceId);
      if (data.status === 200) {
        let payload = data.payload.data;
        setFormData({
          title: payload.title,
          isTitleShow: payload?.isTitleShow ? payload?.isTitleShow : false,
          slugUrl: payload.slugUrl,
          shortDescription: payload.shortDescription,
          description: payload.description,
          logo: payload.image,
          imageThumbnail: payload.imageThumbnail,
          headLine: payload.headLine,
          buttonText: payload.buttonText,
          hyperLink: payload.hyperLink,
          serviceCategoryId: payload.serviceCategoryId,
          webId: localStorage.getItem("webId"),
        });
        setSkill(payload?.skill && payload?.skill);
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * get data service category
   * @param {id} serviceCategoryId
   */
  const handleEditServiceCategory = async (serviceCategoryId) => {
    try {
      setLoaderShow(true);
      setId(serviceCategoryId);
      const { data } = await getServiceCategoryDetails(
        serviceCategoryId,
        navMapIds
      );
      if (data.status === 200) {
        let payload = data.payload.data;
        setSectionPageName(payload?.title);
        setFormDataOurData({
          title: payload?.title || "",
          subTitle: payload?.subTitle || "",
          buttonText: payload?.buttonText || "",
          itemLimit: payload?.itemLimit || "",
          theme: payload?.theme || 1,
          isTitleShow: payload?.isTitleShow ? payload?.isTitleShow : false,
          navMapId: payload?.navMapId || "",
          image: payload?.image || "",
          slugUrl: payload?.slugUrl || "",
          serviceCategoryId: serviceCategoryId,
          webId: localStorage.getItem("webId"),
        });
        OpenModalCommonFunc(Cases.SERVICE_CATEGORY, setAllPopupState);
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(true);
    }
  };

  /**
   * create and update images
   */
  const handleSubmitSetting = async (isRestore = false, isClose = true) => {
    if (id >= -1 && isEdit) {
      setLoaderShow(true);
      try {
        let newData;
        if (isRestore) {
          const isConfirm = window.confirm(
            "Are you sure want to restore style ?"
          );
          if (isConfirm) {
            newData = {
              styleRestore: true,
              serviceCategoryId: id,
            };
          } else {
            setLoaderShow(false);
            return;
          }
        } else {
          if (+theme === ThemeNo.ThemeOne) {
            newData = themeOneFunction({
              settingFormData,
              boxSection,
              boxSectionDetail,
            });
            const menuBtnStyle = generateDynamicStyles(
              settingFormData,
              ".service-section"
            );
            newData = { ...newData, ...menuBtnStyle };
            if (isBackgroundColor) {
              newData[".service-section"] = {
                ...newData?.[".service-section"],
                "background-color": settingFormData?.[".service-section"]?.[
                  "background-color"
                ]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[".service-section"]?.[
                          "background-color"
                        ],
                    })
                  : "",
              };
            }
            if (boxSection) {
              newData[".service-section.bg-image::before"] = {
                ...newData[".service-section.bg-image::before"],
                "border-radius": `${settingFormData?.[".service-section"]?.[
                  "border-radius"
                ]?.replace("rem", "")}rem`,
              };
            }
          } else if (+theme === ThemeNo.ThemeTwo) {
            newData = themeTwoFunction({
              settingFormData,
              boxSection,
              boxSectionDetail,
            });
            const menuBtnStyle = generateDynamicStyles(
              settingFormData,
              ".service2-section"
            );
            newData = { ...newData, ...menuBtnStyle };
            if (isBackgroundColor) {
              newData[".service2-section"] = {
                ...newData?.[".service2-section"],
                "background-color": settingFormData?.[".service2-section"]?.[
                  "background-color"
                ]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[".service2-section"]?.[
                          "background-color"
                        ],
                    })
                  : "",
              };
            }
            if (boxSection) {
              newData[".service2-section.bg-image::before"] = {
                ...newData[".service2-section.bg-image::before"],
                "border-radius": `${settingFormData?.[".service2-section"]?.[
                  "border-radius"
                ]?.replace("rem", "")}rem`,
              };
            }
          } else if (+theme === ThemeNo.ThemeThree) {
            newData = themeThreeFunction({
              settingFormData,
              boxSection,
              boxSectionDetail,
            });
            const menuBtnStyle = generateDynamicStyles(
              settingFormData,
              ".vision-section"
            );
            newData = { ...newData, ...menuBtnStyle };
            if (isBackgroundColor) {
              newData[".vision-section"] = {
                ...newData?.[".vision-section"],
                "background-color": settingFormData?.[".vision-section"]?.[
                  "background-color"
                ]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[".vision-section"]?.[
                          "background-color"
                        ],
                    })
                  : "",
              };
            }
            if (boxSection) {
              newData[".vision-section.bg-image::before"] = {
                ...newData[".vision-section.bg-image::before"],
                "border-radius": `${settingFormData?.[".vision-section"]?.[
                  "border-radius"
                ]?.replace("rem", "")}rem`,
              };
            }
          } else if (+theme === ThemeNo.ThemeFour) {
            newData = themeFourFunction({
              settingFormData,
              boxSection,
              boxSectionDetail,
            });
            const menuBtnStyle = generateDynamicStyles(
              settingFormData,
              ".vision2-section"
            );
            newData = { ...newData, ...menuBtnStyle };
            if (isBackgroundColor) {
              newData[".vision2-section"] = {
                ...newData?.[".vision2-section"],
                "background-color": settingFormData?.[".vision2-section"]?.[
                  "background-color"
                ]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[".vision2-section"]?.[
                          "background-color"
                        ],
                    })
                  : "",
              };
            }
            if (boxSection) {
              newData[".vision2-section.bg-image::before"] = {
                ...newData[".vision2-section.bg-image::before"],
                "border-radius": `${settingFormData?.[".vision2-section"]?.[
                  "border-radius"
                ]?.replace("rem", "")}rem`,
              };
            }
          } else if (+theme === ThemeNo.ThemeFive) {
            newData = themeTwoFunction({
              settingFormData,
              boxSection,
              boxSectionDetail,
            });
            const menuBtnStyle = generateDynamicStyles(
              settingFormData,
              ".service2-section"
            );
            newData = { ...newData, ...menuBtnStyle };
            if (isBackgroundColor) {
              newData[".service2-section"] = {
                ...newData?.[".service2-section"],
                "background-color": settingFormData?.[".service2-section"]?.[
                  "background-color"
                ]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[".service2-section"]?.[
                          "background-color"
                        ],
                    })
                  : "",
              };
            }
            if (boxSection) {
              newData[".service2-section.bg-image::before"] = {
                ...newData[".service2-section.bg-image::before"],
                "border-radius": `${settingFormData?.[".service2-section"]?.[
                  "border-radius"
                ]?.replace("rem", "")}rem`,
              };
            }
          } else if (+theme === ThemeNo.ThemeSix) {
            newData = themeSixFunction({
              settingFormData,
              boxSection,
              boxSectionDetail,
            });
            const menuBtnStyle = generateDynamicStyles(
              settingFormData,
              ".vision-section6"
            );
            newData = { ...newData, ...menuBtnStyle };
            if (isBackgroundColor) {
              newData[".vision-section6"] = {
                ...newData?.[".vision-section6"],
                "background-color": settingFormData?.[".vision-section6"]?.[
                  "background-color"
                ]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[".vision-section6"]?.[
                          "background-color"
                        ],
                    })
                  : "",
              };
            }
            if (boxSection) {
              newData[".vision-section6.bg-image::before"] = {
                ...newData[".vision-section6.bg-image::before"],
                "border-radius": `${settingFormData?.[".vision-section6"]?.[
                  "border-radius"
                ]?.replace("rem", "")}rem`,
              };
            }
          } else if (+theme === ThemeNo.ThemeSeven) {
            newData = themeSevenFunction({
              settingFormData,
              boxSection,
              boxSectionDetail,
            });
            const menuBtnStyle = generateDynamicStyles(
              settingFormData,
              ".boxes1-section"
            );
            newData = { ...newData, ...menuBtnStyle };
            if (isBackgroundColor) {
              newData[".boxes1-section"] = {
                ...newData?.[".boxes1-section"],
                "background-color": settingFormData?.[".boxes1-section"]?.[
                  "background-color"
                ]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[".boxes1-section"]?.[
                          "background-color"
                        ],
                    })
                  : "",
              };
            }
            if (boxSection) {
              newData[".boxes1-section.bg-image::before"] = {
                ...newData[".boxes1-section.bg-image::before"],
                "border-radius": `${settingFormData?.[".boxes1-section"]?.[
                  "border-radius"
                ]?.replace("rem", "")}rem`,
              };
            }
          } else if (+theme === ThemeNo.ThemeEight) {
            newData = themEighthFunction({
              settingFormData,
              boxSection,
              boxSectionDetail,
            });
            const menuBtnStyle = generateDynamicStyles(
              settingFormData,
              ".boxes2-section"
            );
            newData = { ...newData, ...menuBtnStyle };
            if (isBackgroundColor) {
              newData[".boxes2-section"] = {
                ...newData?.[".boxes2-section"],
                "background-color": settingFormData?.[".boxes2-section"]?.[
                  "background-color"
                ]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[".boxes2-section"]?.[
                          "background-color"
                        ],
                    })
                  : "",
              };
            }
            if (boxSection) {
              newData[".boxes2-section.bg-image::before"] = {
                ...newData[".boxes2-section.bg-image::before"],
                "border-radius": `${settingFormData?.[".boxes2-section"]?.[
                  "border-radius"
                ]?.replace("rem", "")}rem`,
              };
            }
          } else if (+theme === ThemeNo.ThemeNine) {
            newData = themNineFunction({
              settingFormData,
              boxSection,
              boxSectionDetail,
            });
            const menuBtnStyle = generateDynamicStyles(
              settingFormData,
              ".boxes3-section"
            );
            newData = { ...newData, ...menuBtnStyle };
            if (isBackgroundColor) {
              newData[".boxes3-section"] = {
                ...newData?.[".boxes3-section"],
                "background-color": settingFormData?.[".boxes3-section"]?.[
                  "background-color"
                ]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[".boxes3-section"]?.[
                          "background-color"
                        ],
                    })
                  : "",
              };
            }
            if (boxSection) {
              newData[".boxes3-section.bg-image::before"] = {
                ...newData[".boxes3-section.bg-image::before"],
                "border-radius": `${settingFormData?.[".boxes3-section"]?.[
                  "border-radius"
                ]?.replace("rem", "")}rem`,
              };
            }
          } else if (+theme === ThemeNo.ThemeTen) {
            newData = themTenFunction({
              settingFormData,
              boxSection,
              boxSectionDetail,
            });
            const menuBtnStyle = generateDynamicStyles(
              settingFormData,
              ".boxes4-section"
            );
            newData = { ...newData, ...menuBtnStyle };
            if (isBackgroundColor) {
              newData[".boxes4-section"] = {
                ...newData?.[".boxes4-section"],
                "background-color": settingFormData?.[".boxes4-section"]?.[
                  "background-color"
                ]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[".boxes4-section"]?.[
                          "background-color"
                        ],
                    })
                  : "",
              };
            }
            if (boxSection) {
              newData[".boxes4-section.bg-image::before"] = {
                ...newData[".boxes4-section.bg-image::before"],
                "border-radius": `${settingFormData?.[".boxes4-section"]?.[
                  "border-radius"
                ]?.replace("rem", "")}rem`,
              };
            }
          } else if (+theme === ThemeNo.ThemeEleven) {
            newData = themElevenFunction({
              settingFormData,
              boxSection,
              boxSectionDetail,
            });
            const menuBtnStyle = generateDynamicStyles(
              settingFormData,
              ".left-boxes1-section"
            );
            newData = { ...newData, ...menuBtnStyle };
            if (isBackgroundColor) {
              newData[".left-boxes1-section"] = {
                ...newData?.[".left-boxes1-section"],
                "background-color": settingFormData?.[".left-boxes1-section"]?.[
                  "background-color"
                ]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[".left-boxes1-section"]?.[
                          "background-color"
                        ],
                    })
                  : "",
              };
            }
            if (boxSection) {
              newData[".left-boxes1-section.bg-image::before"] = {
                ...newData[".left-boxes1-section.bg-image::before"],
                "border-radius": `${settingFormData?.[".left-boxes1-section"]?.[
                  "border-radius"
                ]?.replace("rem", "")}rem`,
              };
            }
          } else if (+theme === ThemeNo.ThemeTwelve) {
            newData = themTwelveFunction({
              settingFormData,
              boxSection,
              boxSectionDetail,
            });
            const menuBtnStyle = generateDynamicStyles(
              settingFormData,
              ".left-boxes2-section"
            );
            newData = { ...newData, ...menuBtnStyle };
            if (isBackgroundColor) {
              newData[".left-boxes2-section"] = {
                ...newData?.[".left-boxes2-section"],
                "background-color": settingFormData?.[".left-boxes2-section"]?.[
                  "background-color"
                ]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[".left-boxes2-section"]?.[
                          "background-color"
                        ],
                    })
                  : "",
              };
            }
            if (boxSection) {
              newData[".left-boxes2-section.bg-image::before"] = {
                ...newData[".left-boxes2-section.bg-image::before"],
                "border-radius": `${settingFormData?.[".left-boxes2-section"]?.[
                  "border-radius"
                ]?.replace("rem", "")}rem`,
              };
            }
          } else if (+theme === ThemeNo.ThemeThirteen) {
            newData = themThirteenFunction({
              settingFormData,
              boxSection,
              boxSectionDetail,
            });
            const menuBtnStyle = generateDynamicStyles(
              settingFormData,
              ".left-boxes3-section"
            );
            newData = { ...newData, ...menuBtnStyle };
            if (isBackgroundColor) {
              newData[".left-boxes3-section"] = {
                ...newData?.[".left-boxes3-section"],
                "background-color": settingFormData?.[".left-boxes3-section"]?.[
                  "background-color"
                ]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[".left-boxes3-section"]?.[
                          "background-color"
                        ],
                    })
                  : "",
              };
            }
            if (boxSection) {
              newData[".left-boxes3-section.bg-image::before"] = {
                ...newData[".left-boxes3-section.bg-image::before"],
                "border-radius": `${settingFormData?.[".left-boxes3-section"]?.[
                  "border-radius"
                ]?.replace("rem", "")}rem`,
              };
            }
          } else if (
            +theme === ThemeNo.ThemeFourteen ||
            +theme === ThemeNo.ThemeFifteen
          ) {
            newData = themFourteenFunction({
              settingFormData,
              boxSectionDetail,
            });
            const menuBtnStyle = generateDynamicStyles(
              settingFormData,
              "table-list-wrapper"
            );
            newData = { ...newData, ...menuBtnStyle };
          }
          newData = {
            ...newData,
            ".service-detail-section": boxSectionDetail
              ? {
                  ...settingFormData?.[".service-detail-section"],
                  padding: `${settingFormData?.[".service-detail-section"]?.[
                    "padding"
                  ]
                    ?.split(" ")[0]
                    ?.replace("px", "")}px 0`,
                  width: `calc(100% - ${settingFormData?.[
                    ".service-detail-section"
                  ]?.["width"]?.replace("rem)", "")}rem)`,
                  margin: `${settingFormData?.[".service-detail-section"]?.[
                    "margin"
                  ]
                    ?.split(" ")[0]
                    ?.replace("px", "")}px auto`,
                  "border-radius": `${settingFormData?.[
                    ".service-detail-section"
                  ]?.["border-radius"]?.replace("rem", "")}rem`,
                }
              : {
                  ...settingFormData?.[".service-detail-section"],
                  padding: `${settingFormData?.[".service-detail-section"]?.[
                    "padding"
                  ]
                    ?.split(" ")[0]
                    ?.replace("px", "")}px 0`,
                },
            ".service-detail-section.bg-image": settingFormData?.[
              ".service-detail-section.bg-image"
            ]?.["background-image"]
              ? {
                  ...settingFormData?.[".service-detail-section.bg-image"],
                  "background-image":
                    settingFormData?.[".service-detail-section.bg-image"]?.[
                      "background-image"
                    ] !== ""
                      ? addUrlImage({
                          urlString: removeUrlImage({
                            urlString:
                              settingFormData[
                                ".service-detail-section.bg-image"
                              ]["background-image"],
                          }),
                        })
                      : "",
                  position: "relative",
                  "background-size": "cover",
                  "background-repeat": "no-repeat",
                  "background-position": "center center",
                }
              : {},
            ".service-detail-section.bg-image::before": settingFormData?.[
              ".service-detail-section.bg-image::before"
            ]?.["background-color"]
              ? {
                  ...settingFormData?.[
                    ".service-detail-section.bg-image::before"
                  ],
                  content: "",
                  "background-color": settingFormData?.[
                    ".service-detail-section.bg-image::before"
                  ]?.["background-color"]
                    ? rgbColorCreate({
                        rgbString:
                          settingFormData?.[
                            ".service-detail-section.bg-image::before"
                          ]?.["background-color"],
                      })
                    : "",
                  position: "absolute",
                  top: "0",
                  bottom: "0",
                  right: "0",
                  left: "0",
                }
              : {},
            ".detail-sidebar": {
              ...settingFormData?.[".detail-sidebar"],
              display: "inline-block",
              width: `${settingFormData?.[".detail-sidebar"]?.[
                "width"
              ]?.replace("%", "")}%`,
            },
            ".detail-sidebar .other-link li a": {
              ...settingFormData?.[".detail-sidebar .other-link li a"],
              display: "block",
              "background-color": settingFormData?.[
                ".detail-sidebar .other-link li a"
              ]?.["background-color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData?.[".detail-sidebar .other-link li a"]?.[
                        "background-color"
                      ],
                  })
                : "",
              color: settingFormData?.[".detail-sidebar .other-link li a"]?.[
                "color"
              ]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData?.[".detail-sidebar .other-link li a"]?.[
                        "color"
                      ],
                  })
                : "",
              padding: `${settingFormData?.[
                ".detail-sidebar .other-link li a"
              ]?.["p1"]?.replace("px", "")}px ${settingFormData?.[
                ".detail-sidebar .other-link li a"
              ]?.["p2"]?.replace("px", "")}px`,
              "font-size": `${settingFormData?.[
                ".detail-sidebar .other-link li a"
              ]?.["font-size"]?.replace("px", "")}px`,
              "font-weight": `${settingFormData?.[".detail-sidebar .other-link li a"]?.["font-weight"]}`,
              border: `${settingFormData?.[
                ".detail-sidebar .other-link li a"
              ]?.["borderPx"]?.replace("px", "")}px ${
                settingFormData?.[".detail-sidebar .other-link li a"]?.[
                  "borderThik"
                ]
              } ${rgbColorCreate({
                rgbString:
                  settingFormData?.[".detail-sidebar .other-link li a"]?.[
                    "borderColor"
                  ],
              })}`,
              position: "relative",
              "-moz-transition": "all 300ms ease-out 0s",
              "-webkit-transition": "all 300ms ease-out 0s",
              "-ms-transition": "all 300ms ease-out 0s",
              "-o-transition": "all 300ms ease-out 0s",
              transition: "all 300ms ease-out 0s",
            },
            ".detail-sidebar .other-link li .fa": {
              "margin-right": `${settingFormData?.[
                ".detail-sidebar .other-link li .fa"
              ]?.["margin-right"]?.replace("px", "")}px`,
            },
            ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a":
              {
                ...settingFormData?.[
                  ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a"
                ],
                color: settingFormData?.[
                  ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a"
                ]?.["color"]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[
                          ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a"
                        ]?.["color"],
                    })
                  : "",
                "border-left": `${settingFormData?.[
                  ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a"
                ]?.["borderLfPx"]?.replace("px", "")}px ${
                  settingFormData?.[
                    ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a"
                  ]?.["borderLfThik"]
                } ${rgbColorCreate({
                  rgbString:
                    settingFormData?.[
                      ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a"
                    ]?.["borderLfColor"],
                })}`,
              },
            ".detail-sidebar .widget-form": {
              ...settingFormData?.[".detail-sidebar .widget-form"],
              display: "inline-block",
              width: `${settingFormData?.[".detail-sidebar .widget-form"]?.[
                "width"
              ]?.replace("%", "")}%`,
              padding: `${settingFormData?.[".detail-sidebar .widget-form"]?.[
                "p1"
              ]?.replace("px", "")}px ${settingFormData?.[
                ".detail-sidebar .widget-form"
              ]?.["p2"]?.replace("px", "")}px`,
              "background-color": settingFormData?.[
                ".detail-sidebar .widget-form"
              ]?.["background-color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData?.[".detail-sidebar .widget-form"]?.[
                        "background-color"
                      ],
                  })
                : "",
            },
            ".detail-sidebar .widget-form .form-control": {
              padding: `${settingFormData?.[
                ".detail-sidebar .widget-form .form-control"
              ]?.["padding"]?.replace("px", "")}px`,
            },
            ".detail-sidebar .widget-form .btn-submit": {
              ...settingFormData?.[".detail-sidebar .widget-form .btn-submit"],
              "background-color": settingFormData?.[
                ".detail-sidebar .widget-form .btn-submit"
              ]?.["background-color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData?.[
                        ".detail-sidebar .widget-form .btn-submit"
                      ]?.["background-color"],
                  })
                : "",
              border: `${settingFormData?.[
                ".detail-sidebar .widget-form .btn-submit"
              ]?.["borderPx"]?.replace("px", "")}px ${
                settingFormData?.[".detail-sidebar .widget-form .btn-submit"]?.[
                  "borderThik"
                ]
              } ${rgbColorCreate({
                rgbString:
                  settingFormData?.[
                    ".detail-sidebar .widget-form .btn-submit"
                  ]?.["borderColor"],
              })}`,
              color: settingFormData?.[
                ".detail-sidebar .widget-form .btn-submit"
              ]?.["color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData?.[
                        ".detail-sidebar .widget-form .btn-submit"
                      ]?.["color"],
                  })
                : "",
              "font-size": `${settingFormData?.[
                ".detail-sidebar .widget-form .btn-submit"
              ]?.["font-size"]?.replace("px", "")}px`,
              "font-weight": `${settingFormData?.[".detail-sidebar .widget-form .btn-submit"]?.["font-weight"]}`,
              padding: `${settingFormData?.[
                ".detail-sidebar .widget-form .btn-submit"
              ]?.["p1"]?.replace("px", "")}px ${settingFormData?.[
                ".detail-sidebar .widget-form .btn-submit"
              ]?.["p2"]?.replace("px", "")}px`,
              width: `${settingFormData?.[
                ".detail-sidebar .widget-form .btn-submit"
              ]?.["width"]?.replace("%", "")}%`,
            },
          };
          if (isBackgroundColor2) {
            newData[".service-detail-section"] = {
              ...newData?.[".service-detail-section"],
              "background-color": settingFormData?.[
                ".service-detail-section"
              ]?.["background-color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData?.[".service-detail-section"]?.[
                        "background-color"
                      ],
                  })
                : "",
            };
          }
          const keysToRemove = ["image", "image2"];
          function removeKeys(obj, keys) {
            for (const key in obj) {
              if (typeof obj[key] === "object") {
                removeKeys(obj[key], keys);
              } else if (keys.includes(key)) {
                delete obj[key];
              }
            }
          }
          removeKeys(newData, keysToRemove);
          newData = {
            style: newData,
            serviceCategoryId: id,
          };
        }
        const { data } = await putUpdateServiceCategory(newData);
        if (data.status === 200) {
          // getService({});
          if (isClose) {
            OpenModalCommonFunc(
              Cases.SETTING_SERVICE_CATEGORY_PARENT,
              setAllPopupState,
              false
            );
            setBackImageToggle(false);
          }
          setLoaderShow(false);
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };
  const resetStates = () => {
    setBoxSection(false);
    setBackImageToggle(false);
    setIsBackgroundColor(false);
    setIsBackgroundColor2(false);
    setBoxSectionDetail(false);
    setBackImageToggleDetail(false);
  };

  /**
   * get setting data
   * @param {id} packageId
   */
  const handleChangeSettings = async (
    serviceCategoryId,
    isThemeEdit = false
  ) => {
    try {
      setLoaderShow(true);
      setId(serviceCategoryId);
      setSettingFormData((prev) => {
        return {
          ...prev,
          serviceCategoryId,
        };
      });
      const { data } = await getServiceCategoryDetails(serviceCategoryId);
      if (data.status === 200) {
        const themeCount = data?.payload?.data?.theme;
        let payload = data.payload.data;
        setSectionPageName(payload.title);
        setTheme(themeCount);
        setImgShow(true);
        if (
          +themeCount === ThemeNo.ThemeOne &&
          isEmptyObject(data?.payload?.data?.style)
        ) {
          setSettingFormData({ ...serviceCategoryStyleOne });
          resetStates();
        } else if (
          +themeCount === ThemeNo.ThemeTwo &&
          isEmptyObject(data?.payload?.data?.style)
        ) {
          setSettingFormData({ ...ServiceCategoryStyleTwo });
          resetStates();
        } else if (
          +themeCount === ThemeNo.ThemeThree &&
          isEmptyObject(data?.payload?.data?.style)
        ) {
          setSettingFormData({ ...serviceCategoryStyleThree });
          resetStates();
        } else if (
          +themeCount === ThemeNo.ThemeFour &&
          isEmptyObject(data?.payload?.data?.style)
        ) {
          setSettingFormData({ ...ServiceCategoryStyleFour });
          resetStates();
        } else if (
          +themeCount === ThemeNo.ThemeFive &&
          isEmptyObject(data?.payload?.data?.style)
        ) {
          setSettingFormData({ ...ServiceCategoryStyleTwo });
          resetStates();
        } else if (
          +themeCount === ThemeNo.ThemeSix &&
          isEmptyObject(data?.payload?.data?.style)
        ) {
          setSettingFormData({ ...serviceCategoryStyleSix });
          resetStates();
        } else if (
          +themeCount === ThemeNo.ThemeSeven &&
          isEmptyObject(data?.payload?.data?.style)
        ) {
          setSettingFormData({ ...serviceCategoryStyleSeven });
          resetStates();
        } else if (
          +themeCount === ThemeNo.ThemeEight &&
          isEmptyObject(data?.payload?.data?.style)
        ) {
          setSettingFormData({ ...serviceCategoryStyleEight });
          resetStates();
        } else if (
          +themeCount === ThemeNo.ThemeNine &&
          isEmptyObject(data?.payload?.data?.style)
        ) {
          setSettingFormData({ ...serviceCategoryStyleNine });
          resetStates();
        } else if (
          +themeCount === ThemeNo.ThemeTen &&
          isEmptyObject(data?.payload?.data?.style)
        ) {
          setSettingFormData({ ...serviceCategoryStyleTen });
          resetStates();
        } else if (
          +themeCount === ThemeNo.ThemeEleven &&
          isEmptyObject(data?.payload?.data?.style)
        ) {
          setSettingFormData({ ...serviceCategoryStyleEleven });
          resetStates();
        } else if (
          +themeCount === ThemeNo.ThemeTwelve &&
          isEmptyObject(data?.payload?.data?.style)
        ) {
          setSettingFormData({ ...serviceCategoryStyleTwelve });
          resetStates();
        } else if (
          +themeCount === ThemeNo.ThemeThirteen &&
          isEmptyObject(data?.payload?.data?.style)
        ) {
          setSettingFormData({ ...serviceCategoryStyleThirteen });
          resetStates();
        } else if (
          +themeCount === ThemeNo.ThemeFourteen &&
          isEmptyObject(data?.payload?.data?.style)
        ) {
          setSettingFormData({ ...serviceCategoryStyleFourteen });
          resetStates();
        } else if (
          +themeCount === ThemeNo.ThemeFifteen &&
          isEmptyObject(data?.payload?.data?.style)
        ) {
          setSettingFormData({ ...serviceCategoryStyleFourteen });
          resetStates();
        } else {
          let find = [
            ".vision-section",
            ".service2-section",
            ".vision2-section",
            ".vision-section6",
            ".service-section",
            ".boxes1-section",
            ".boxes2-section",
            ".boxes3-section",
            ".boxes4-section",
            ".left-boxes1-section",
            ".left-boxes2-section",
            ".left-boxes3-section",
          ];
          let section = Object.keys(payload.style);
          let foundItem = null;
          for (const key in find) {
            if (section.includes(find[key])) {
              foundItem = find[key];
              break; // If found, exit the loop
            }
          }
          if (
            payload.style?.[foundItem]?.["width"] ||
            payload.style?.[foundItem]?.["margin"] ||
            payload.style?.[foundItem]?.["border-radius"]
          ) {
            setBoxSection(true);
          } else if (
            payload.style?.[".service-detail-section"]?.["width"] ||
            payload.style?.[".service-detail-section"]?.["margin"] ||
            payload.style?.[".service-detail-section"]?.["border-radius"]
          ) {
            setBoxSectionDetail(true);
          }
          if (
            payload.style?.[`${foundItem}.bg-image`]?.["background-image"] !==
              "" &&
            payload.style?.[`${foundItem}.bg-image`]?.["background-image"] !==
              null &&
            payload.style?.[`${foundItem}.bg-image`]?.["background-image"] !==
              undefined &&
            payload.style?.[`${foundItem}.bg-image`]?.["background-image"] !==
              "url()"
          ) {
            setBackImageToggle(true);
          } else if (
            payload.style?.[".service-detail-section.bg-image"]?.[
              "background-image"
            ] !== "" &&
            payload.style?.[".service-detail-section.bg-image"]?.[
              "background-image"
            ] !== null &&
            payload.style?.[".service-detail-section.bg-image"]?.[
              "background-image"
            ] !== undefined &&
            payload.style?.[".service-detail-section.bg-image"]?.[
              "background-image"
            ] !== "url()"
          ) {
            setBackImageToggleDetail(true);
          }
          if (payload.style?.[foundItem]?.["background-color"]) {
            setIsBackgroundColor(true);
          }
          if (
            payload.style?.[".service-detail-section"]?.["background-color"]
          ) {
            setIsBackgroundColor2(true);
          }
          let getMenuBtnStyle;
          let otherStyle;
          let serviceDetailsStyles;
          if (+data.payload.data.theme === ThemeNo.ThemeOne) {
            getMenuBtnStyle = generateDynamicStylesSet(
              payload,
              ".service-section"
            );
            otherStyle = serviceStyleOneSet(payload);
            serviceDetailsStyles = serviceDetailsExtraStyle(payload);
          } else if (+data.payload.data.theme === ThemeNo.ThemeTwo) {
            getMenuBtnStyle = generateDynamicStylesSet(
              payload,
              ".service2-section"
            );
            otherStyle = serviceStyleTwoSet(payload);
            serviceDetailsStyles = serviceDetailsExtraStyle(payload);
          } else if (+data.payload.data.theme === ThemeNo.ThemeThree) {
            getMenuBtnStyle = generateDynamicStylesSet(
              payload,
              ".vision-section"
            );
            otherStyle = serviceStyleThreeSet(payload);
            serviceDetailsStyles = serviceDetailsExtraStyle(payload);
          } else if (+data.payload.data.theme === ThemeNo.ThemeSix) {
            getMenuBtnStyle = generateDynamicStylesSet(
              payload,
              ".vision-section6"
            );
            otherStyle = serviceStyleSixSet(payload);
            serviceDetailsStyles = serviceDetailsExtraStyle(payload);
          } else if (+data.payload.data.theme === ThemeNo.ThemeFour) {
            getMenuBtnStyle = generateDynamicStylesSet(
              payload,
              ".vision2-section"
            );
            otherStyle = serviceStyleFourSet(payload);
            serviceDetailsStyles = serviceDetailsExtraStyle(payload);
          } else if (+data.payload.data.theme === ThemeNo.ThemeSeven) {
            getMenuBtnStyle = generateDynamicStylesSet(
              payload,
              ".boxes1-section"
            );
            otherStyle = serviceStyleSevenSet(payload);
            serviceDetailsStyles = serviceDetailsExtraStyle(payload);
          } else if (+data.payload.data.theme === ThemeNo.ThemeEight) {
            getMenuBtnStyle = generateDynamicStylesSet(
              payload,
              ".boxes2-section"
            );
            otherStyle = serviceStyleEighthSet(payload);
            serviceDetailsStyles = serviceDetailsExtraStyle(payload);
          } else if (+data.payload.data.theme === ThemeNo.ThemeNine) {
            getMenuBtnStyle = generateDynamicStylesSet(
              payload,
              ".boxes3-section"
            );
            otherStyle = serviceStyleNineSet(payload);
            serviceDetailsStyles = serviceDetailsExtraStyle(payload);
          } else if (+data.payload.data.theme === ThemeNo.ThemeTen) {
            getMenuBtnStyle = generateDynamicStylesSet(
              payload,
              ".boxes4-section"
            );
            otherStyle = serviceStyleTenSet(payload);
            serviceDetailsStyles = serviceDetailsExtraStyle(payload);
          } else if (+data.payload.data.theme === ThemeNo.ThemeEleven) {
            getMenuBtnStyle = generateDynamicStylesSet(
              payload,
              ".left-boxes1-section"
            );
            otherStyle = serviceStyleElevenSet(payload);
            serviceDetailsStyles = serviceDetailsExtraStyle(payload);
          } else if (+data.payload.data.theme === ThemeNo.ThemeTwelve) {
            getMenuBtnStyle = generateDynamicStylesSet(
              payload,
              ".left-boxes2-section"
            );
            otherStyle = serviceStyleTwelveSet(payload);
            serviceDetailsStyles = serviceDetailsExtraStyle(payload);
          } else if (+data.payload.data.theme === ThemeNo.ThemeThirteen) {
            getMenuBtnStyle = generateDynamicStylesSet(
              payload,
              ".left-boxes3-section"
            );
            otherStyle = serviceStyleThirteenSet(payload);
            serviceDetailsStyles = serviceDetailsExtraStyle(payload);
          } else if (
            +data.payload.data.theme === ThemeNo.ThemeFourteen ||
            +data.payload.data.theme === ThemeNo.ThemeFifteen
          ) {
            getMenuBtnStyle = generateDynamicStylesSet(
              payload,
              ".table-list-wrapper"
            );
            otherStyle = serviceStyleFourteenSet(payload);
            serviceDetailsStyles = serviceDetailsExtraStyle(payload);
          }
          setSettingFormData((prev) => {
            return {
              ...prev,
              ...payload.style,
              custom: payload.style.custom,
              [foundItem]: {
                padding: payload.style?.[foundItem]?.["padding"],
                "background-color": payload.style?.[foundItem]?.[
                  "background-color"
                ]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[foundItem]?.["background-color"],
                    })
                  : "",
                width: payload.style?.[foundItem]?.["width"]?.replace(
                  "calc(100% - ",
                  ""
                ),
                margin: payload.style?.[foundItem]?.["margin"],
                "border-radius": payload.style?.[foundItem]?.["border-radius"],
              },
              [`${foundItem}.bg-image`]: {
                "background-image":
                  payload.style?.[`${foundItem}.bg-image`]?.[
                    "background-image"
                  ] !== "" &&
                  payload.style?.[`${foundItem}.bg-image`]?.[
                    "background-image"
                  ] !== null
                    ? removeUrlKeyInPath({
                        urlString:
                          payload.style?.[`${foundItem}.bg-image`]?.[
                            "background-image"
                          ],
                      })
                    : "",
              },
              [`${foundItem}.bg-image::before`]: {
                "background-color": payload.style?.[
                  `${foundItem}.bg-image::before`
                ]?.["background-color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[`${foundItem}.bg-image::before`]?.[
                          "background-color"
                        ],
                    })
                  : "",
              },
              [`${foundItem} .section-title-area .section-subtitle`]: {
                ...payload.style[
                  `${foundItem} .section-title-area .section-subtitle`
                ],
                "background-color": payload.style?.[
                  `${foundItem} .section-title-area .section-subtitle`
                ]?.["background-color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          `${foundItem} .section-title-area .section-subtitle`
                        ]?.["background-color"],
                    })
                  : "",
                color: payload.style?.[
                  `${foundItem} .section-title-area .section-subtitle`
                ]?.["color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          `${foundItem} .section-title-area .section-subtitle`
                        ]?.["color"],
                    })
                  : "",
              },
              ".service-detail-section": {
                padding:
                  payload.style?.[".service-detail-section"]?.["padding"],
                "background-color": payload.style?.[
                  ".service-detail-section"
                ]?.["background-color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[".service-detail-section"]?.[
                          "background-color"
                        ],
                    })
                  : "",
                width: payload.style?.[".service-detail-section"]?.[
                  "width"
                ]?.replace("calc(100% - ", ""),
                margin: payload.style?.[".service-detail-section"]?.["margin"],
                "border-radius":
                  payload.style?.[".service-detail-section"]?.["border-radius"],
              },
              ".service-detail-section.bg-image": {
                "background-image":
                  payload.style?.[".service-detail-section.bg-image"]?.[
                    "background-image"
                  ] !== "" &&
                  payload.style?.[".service-detail-section.bg-image"]?.[
                    "background-image"
                  ] !== null
                    ? removeUrlKeyInPath({
                        urlString:
                          payload.style?.[".service-detail-section.bg-image"]?.[
                            "background-image"
                          ],
                      })
                    : "",
              },
              ".service-detail-section.bg-image::before": {
                "background-color": payload.style?.[
                  ".service-detail-section.bg-image::before"
                ]?.["background-color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          ".service-detail-section.bg-image::before"
                        ]?.["background-color"],
                    })
                  : "",
              },
              ...getMenuBtnStyle,
              ...otherStyle,
              ...serviceDetailsStyles,
            };
          });

          if (foundItem === ".vision2-section") {
            setSettingFormData((prev) => {
              return {
                ...prev,
                ...payload.style,
                custom: payload.style.custom,
                [foundItem]: {
                  padding: payload.style?.[foundItem]?.["padding"],
                  "background-color":
                    payload.style?.[foundItem]?.["background-color"],
                  width: payload.style?.[foundItem]?.["width"]?.replace(
                    "calc(100% - ",
                    ""
                  ),
                  margin: payload.style?.[foundItem]?.["margin"],
                  "border-radius":
                    payload.style?.[foundItem]?.["border-radius"],
                },
                [`${foundItem}.bg-image`]: {
                  "background-image":
                    payload.style?.[`${foundItem}.bg-image`]?.[
                      "background-image"
                    ] !== "" &&
                    payload.style?.[`${foundItem}.bg-image`]?.[
                      "background-image"
                    ] !== null
                      ? removeUrlKeyInPath({
                          urlString:
                            payload.style?.[`${foundItem}.bg-image`]?.[
                              "background-image"
                            ],
                        })
                      : "",
                },
                [`${foundItem}.bg-image::before`]: {
                  "background-color": payload.style?.[
                    `${foundItem}.bg-image::before`
                  ]?.["background-color"]
                    ? convertRGBA({
                        rgbString:
                          payload.style?.[`${foundItem}.bg-image::before`]?.[
                            "background-color"
                          ],
                      })
                    : "",
                },
                [`${foundItem} .section-title-area .section-subtitle`]: {
                  ...payload.style[
                    `${foundItem} .section-title-area .section-subtitle`
                  ],
                  "background-color": payload.style?.[
                    `${foundItem} .section-title-area .section-subtitle`
                  ]?.["background-color"]
                    ? convertRGBA({
                        rgbString:
                          payload.style?.[
                            `${foundItem} .section-title-area .section-subtitle`
                          ]?.["background-color"],
                      })
                    : "",
                  color: payload.style?.[
                    `${foundItem} .section-title-area .section-subtitle`
                  ]?.["color"]
                    ? convertRGBA({
                        rgbString:
                          payload.style?.[
                            `${foundItem} .section-title-area .section-subtitle`
                          ]?.["color"],
                      })
                    : "",
                },
                ".vision2-section .visiondiv2:hover": {
                  transform: payload.style?.[
                    ".vision2-section .visiondiv2:hover"
                  ]?.["transform"]?.replace("translateY(", ""),
                },
                ".service-detail-section": {
                  padding:
                    payload.style?.[".service-detail-section"]?.["padding"],
                  "background-color": payload.style?.[
                    ".service-detail-section"
                  ]?.["background-color"]
                    ? convertRGBA({
                        rgbString:
                          payload.style?.[".service-detail-section"]?.[
                            "background-color"
                          ],
                      })
                    : "",
                  width: payload.style?.[".service-detail-section"]?.[
                    "width"
                  ]?.replace("calc(100% - ", ""),
                  margin:
                    payload.style?.[".service-detail-section"]?.["margin"],
                  "border-radius":
                    payload.style?.[".service-detail-section"]?.[
                      "border-radius"
                    ],
                },
                ".service-detail-section.bg-image": {
                  "background-image":
                    payload.style?.[".service-detail-section.bg-image"]?.[
                      "background-image"
                    ] !== "" &&
                    payload.style?.[".service-detail-section.bg-image"]?.[
                      "background-image"
                    ] !== null
                      ? removeUrlKeyInPath({
                          urlString:
                            payload.style?.[
                              ".service-detail-section.bg-image"
                            ]?.["background-image"],
                        })
                      : "",
                },
                ".service-detail-section.bg-image::before": {
                  "background-color": payload.style?.[
                    ".service-detail-section.bg-image::before"
                  ]?.["background-color"]
                    ? convertRGBA({
                        rgbString:
                          payload.style?.[
                            ".service-detail-section.bg-image::before"
                          ]?.["background-color"],
                      })
                    : "",
                },
              };
            });
          }
        }
        if (!isThemeEdit) {
          OpenModalCommonFunc(
            Cases.SETTING_SERVICE_CATEGORY_PARENT,
            setAllPopupState
          );
          TostMessage({ message: data, type: "success" });
        }
        setIsEdit(true);
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * delete service category
   * @param {id} serviceCategoryId
   */
  const handleDelete = async (serviceCategoryId) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await deleteServiceCategory(serviceCategoryId);
        if (data.status === 200) {
          ByteMiniWeb_Dashboard();
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * clear data
   */
  const emptyData = () => {
    setIsBackgroundColor(false);
    setBoxSection(false);
    setBackImageToggle(false);
    setIsEdit(false);
    setFormData({
      title: "",
      headLine: "",
      slugUrl: "",
      shortDescription: "",
      description: "",
      image: "",
      isTitleShow: true,
      logo: "",
      imageThumbnail: "",
      buttonText: "",
      hyperLink: "",
      webId: localStorage.getItem("webId"),
    });
    setSkill([{ id: randomStr(2), skill: "", parentageOfSkill: "" }]);
    setTeamMemberId("");
    setFormDataOurData({
      title: "",
      subTitle: "",
      image: "",
      slugUrl: "",
      buttonText: "",
      itemLimit: "",
      navMapId: "",
      isTitleShow: true,
      theme: 1,
      style: {
        ".service-section": {
          padding: "100px 0",
          "background-color": "rgb(255 255 255 / 100)",
          width: "",
          margin: "",
          "border-radius": "",
        },
        ".service-section.bg-image": {
          "background-image": "",
        },
        ".service-section.bg-image::before": {
          "background-color": "",
        },
        ".service-section .section-title-area": {
          "margin-bottom": "50px",
        },
        ".service-section .section-title-area .section-subtitle": {
          "font-size": "16px",
          "font-weight": "600",
          color: "rgb(255 73 124 / 100)",
          "background-color": "rgb(255 73 124 / 0.2)",
          padding: "5px 20px",
          p1: "5px",
          p2: "20px",
          "border-radius": "15px",
          "margin-bottom": "15px",
        },
        ".service-section .section-title-area .section-title": {
          "font-size": "44px",
          "font-weight": "600",
          color: "rgb(0 0 0 / 100)",
        },
        ".service-section .servicediv": {
          margin: "15px",
          padding: "20px",
          outline: "none",
          outPx: "1px",
          outThik: "solid",
          outColor: "rgb(255 255 255 / 100)",
          "background-color": "rgb(255 255 255 / 100)",
          "box-shadow": "0 5px 10px 0 rgb(7 28 26 / 0.1)",
          bs1: "0px",
          bs2: "5px",
          bs3: "10px",
          bs4: "0px",
          shadowColor: "rgb(7 28 26 / 0.1)",
          "border-radius": "0px",
        },
        ".service-section .servicediv .service-icon": {
          "margin-bottom": "20px",
          "max-width": "70px",
        },
        ".service-section .servicediv h4": {
          color: "rgb(0 0 0 / 100)",
          "font-weight": "600",
          "font-size": "20px",
          "margin-bottom": "15px",
          "text-align": "justify",
        },
        ".service-section .servicediv p": {
          color: "rgb(0 0 0 / 100)",
          "font-weight": "400",
          "font-size": "14px",
          "line-height": "1.6",
          "margin-bottom": "15px",
          "text-align": "justify",
        },
        ".service-section .servicediv .links": {
          "font-size": "16px",
          "font-weight": "500",
          color: "rgb(255 73 124 / 100)",
        },
        ".service-slider .slick-arrow:before": {
          color: "rgb(255 73 124 / 100)",
        },
        ".service-slider .service-box": {
          position: "relative",
          "border-radius": "10px",
          margin: "10px",
        },
        ".service-slider .service-box .servicediv": {
          "background-color": "rgba(255, 255, 255, 0.9)",
          "border-radius": "10px",
        },
        ".service-slider .service-box .service-img": {
          "border-radius": "10px",
        },
        ".service-detail-section": {
          padding: "60px 0",
          "background-color": "rgb(255 255 255 / 100)",
        },
        ".service-detail-section.bg-image": {
          "background-image": "",
        },
        ".service-detail-section.bg-image::before": {
          "background-color": "",
        },
        ".detail-sidebar": {
          display: "inline-block",
          width: "100%",
        },
        ".detail-sidebar .other-link li a": {
          "background-color": "rgb(244 244 245 / 100)",
          color: "rgb(31 39 50 / 100)",
          padding: "15px 20px",
          p1: "15px",
          p2: "20px",
          "font-size": "16px",
          "font-weight": "normal",
          border: "1px solid rgb(215 226 230 / 100)",
          borderPx: "1px",
          borderThik: "solid",
          borderColor: "rgb(215 226 230 / 100)",
        },
        ".detail-sidebar .other-link li .fa": {
          "margin-right": "10px",
        },
        ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a":
          {
            color: "rgb(255 73 124 / 100)",
            "border-left": "5px solid rgb(255 73 124 / 100)",
            borderLfPx: "5px",
            borderLfThik: "solid",
            borderLfColor: "rgb(255 73 124 / 100)",
          },
        ".detail-sidebar .widget-form": {
          display: "inline-block",
          width: "100%",
          padding: "25px 35px",
          p1: "25px",
          p2: "35px",
          "background-color": "rgb(31 39 50 / 100)",
        },
        ".detail-sidebar .widget-form .form-control": {
          padding: "13px",
        },
        ".detail-sidebar .widget-form .btn-submit": {
          "background-color": "rgb(255 73 124 / 100)",
          border: "2px solid rgb(255 73 124 / 100)",
          borderPx: "2px",
          borderThik: "solid",
          borderColor: "rgb(255 73 124 / 100)",
          color: "rgb(255 255 255 / 100)",
          "font-size": "16px",
          "font-weight": "600",
          padding: "8px 25px",
          p1: "8px",
          p2: "25px",
          width: "100%",
        },
        ".service-section .more-btn": {
          color: "rgb(255 255 255 / 100)",
          "font-size": "16px",
          "font-weight": "normal",
          "border-radius": "15px",
          padding: "5px 20px",
          "background-color": "rgb(255 73 124 / 100)",
        },
        ".service-section .more-btn:hover": {
          "background-color": "rgb(255 73 124 / 100)",
          color: "rgb(255 255 255 / 100)",
        },
        ".pagination-positions": {
          "justify-content": "center",
        },
        ".pagination .page-link": {
          color: "rgb(255 73 124 / 100)",
        },
        ".pagination .page-item.active .page-link": {
          "background-color": "rgb(255 73 124 / 100)",
          "border-color": "rgb(255 73 124 / 100)",
        },
        ".service-section .slick-dots li button:before": {
          "font-size": "12px",
          color: "rgb(255 73 124 / 100)",
          opacity: "0.25",
        },
        ".service-section .slick-dots li.slick-active button:before": {
          color: "rgb(255 73 124 / 100)",
        },
        serviceId: "",
      },
    });
    setIsImageUpload({ isList: false, isDetails: false });
    setLoaderShow(false);
    setSettingFormData({});
    simpleValidator.current.hideMessages();
    forceUpdate(3);
    simpleValidatorTeamMember.current.hideMessages();
    forceUpdate1(3);
    setIsUpload(false);
  };

  const handleAlignAndCloneSection = ({ isClone = false }) => {
    if (isClone) {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          cloneModal: true,
        };
      });
      setCloneFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          pageConfigDetailsLabel: pageConfigDetails[0]?.label,
          contentDetailName: contentDetail.name,
        };
      });
    } else {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          alignModal: true,
        };
      });
      setAlignFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          label: pageConfigDetails[0]?.label,
        };
      });
    }
  };
  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody
            className="support-ticket-font box-border-div"
            style={{ padding: "20px" }}
          >
            <Row>
              <CardBuilderHeaderSection
                addBtnTitle={pageConfigDetails[0]?.label}
                name={pageConfigDetails[0]?.label}
                isAdd={!serviceCategoryDetail && true}
                mainEditSection={true}
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
                openModal={() => {
                  OpenModalCommonFunc(Cases.SERVICE_CATEGORY, setAllPopupState);
                  setSectionPageName(modalsName.SERVICE_CATEGORY);
                  setCollId(contentMapId);
                }}
                isCloneSection={true}
                isAlignSection={true}
                handleAlignAndCloneSection={handleAlignAndCloneSection}
              />
              {serviceCategoryDetail && (
                <Col xl="12 xl-50 box-col-6" md="6">
                  <Card className="ecommerce-widget mb-0">
                    <CardBody
                      className="support-ticket-font"
                      style={{ padding: "20px" }}
                    >
                      <div className="col-12">
                        <CardBuilderHeaderSection
                          name={serviceCategoryDetail?.title}
                          checked={serviceCategoryDetail?.isActive}
                          switchToggle={true}
                          id={serviceCategoryDetail?.serviceCategoryId}
                          handleChange={handleChangeServiceCategoryStatus}
                        />
                        <OpenPopupBtn
                          openPopup={() => {
                            getService({
                              serviceCategoryId:
                                serviceCategoryDetail?.serviceCategoryId,
                            });
                            setSectionPageName(serviceCategoryDetail?.title);
                            OpenModalCommonFunc(
                              Cases.SERVICE_,
                              setAllPopupState
                            );
                            setId(serviceCategoryDetail.serviceCategoryId);
                            setTheme(serviceCategoryDetail.theme);
                          }}
                          name={`Add or View ${serviceCategoryDetail?.title}`}
                        />
                      </div>
                      <div className="d-flex justify-content-end ">
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleEditServiceCategory(
                              serviceCategoryDetail.serviceCategoryId
                            );
                            setCollId(contentMapId);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() =>
                            handleDelete(
                              serviceCategoryDetail.serviceCategoryId
                            )
                          }
                        >
                          <Trash />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleChangeSettings(
                              serviceCategoryDetail.serviceCategoryId
                            );
                          }}
                        >
                          <Settings />
                        </IconButton>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
              <DeleteSection
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
              />
            </Row>
          </CardBody>
        </Card>
      </Col>

      {/* service popup */}
      <CommonPopup
        open={allPopupState.service_}
        close={() =>
          OpenModalCommonFunc(Cases.SERVICE_, setAllPopupState, false)
        }
        modalName={sectionPageName ? sectionPageName : modalsName.SERVICE_}
        modalCase={Cases.SERVICE_}
        handleSubmit={handleSubmit}
        emptyData={emptyData}
        inputData={fromData}
        edit={false}
        content={
          <ServicePopup
            handleChangeStatus={handleChangeStatus}
            getService={getService}
            handleEdit={handleEdit}
            imageUpload={imageUploadServiceCategory}
            emptyData={emptyData}
            simpleValidator={simpleValidatorTeamMember}
            setIsUpload={setIsUpload}
          />
        }
      />

      {/* service category popup */}
      <CommonPopup
        open={allPopupState.serviceCategory}
        close={() =>
          OpenModalCommonFunc(Cases.SERVICE_CATEGORY, setAllPopupState, false)
        }
        modalName={
          sectionPageName ? sectionPageName : modalsName.SERVICE_CATEGORY
        }
        modalCase={Cases.SERVICE_CATEGORY}
        handleSubmit={handleSubmitBlog}
        emptyData={emptyData}
        inputData={formDataOurTeam}
        edit={false}
        content={
          <ServiceCategoryPopup
            imageUpload={imageUpload}
            simpleValidator={simpleValidator}
          />
        }
      />

      {/* setting images parent popup */}
      <CommonPopup
        open={allPopupState.serviceAndCategorySettingPopup}
        close={() =>
          OpenModalCommonFunc(
            Cases.SETTING_SERVICE_CATEGORY_PARENT,
            setAllPopupState,
            false
          )
        }
        modalName={
          sectionPageName
            ? sectionPageName
            : modalsName.SETTING_SERVICE_CATEGORY_PARENT
        }
        modalCase={Cases.SETTING_SERVICE_CATEGORY_PARENT}
        handleSubmit={handleSubmitSetting}
        emptyData={emptyData}
        // inputData={formDataChild}
        edit={false}
        content={
          <SettingServiceAndCategotyParentPopup
            imageUpload={imageUploadServiceCategorySetting}
            handleSubmitSetting={handleSubmitSetting}
            handleChangeSettings={handleChangeSettings}
          />
        }
      />
    </>
  );
};

export default ServiceCategory;
