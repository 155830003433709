import axios from "axios";
import {
  CHANGE_POSITION_CHANGE_,
  CHANGE_SUB_MENU,
  GET_STYLE_THEME,
  STATUS_CHANGE_NAV,
  STYLE_BACK_UP_UPDATE,
  STYLE_THEME_UPDATE,
  WEB_CONFIG_PAGE_CREATE,
  WEB_CONTENT_MAP_LIST,
  WEB_CONTENT_MASTER_CREATE,
  WEB_CONTENT_MASTER_LIST,
  WEB_NAV_BAR_CONFIG_LIST,
  WEB_NAV_BAR_CREATE,
  WEB_NAV_BAR_DETAIL,
  WEB_NAV_BAR_MAP_LIST,
  WEB_NAV_BAR_MAP_WITH_CONTENT_LIST,
  WEB_NAV_BAR_MASTER_LIST,
  WEB_NAV_BAR_NAV_MAP_ID,
} from "..";

export const WebContentMapList = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${WEB_CONTENT_MAP_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};
export const WebContentMapListDelete = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(
    `${process.env.REACT_APP_API_URL}${WEB_CONTENT_MAP_LIST}`,
    {
      headers: { Authorization: jwt_token },
      params: paramObj,
    }
  );
};
export const WebNavBarMapList = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${WEB_NAV_BAR_MAP_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};
export const WebNavBarMapWithContentList = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${WEB_NAV_BAR_MAP_WITH_CONTENT_LIST}`,
    {
      headers: { Authorization: jwt_token },
      params: paramObj,
    }
  );
};
export const WebNavBarMapListDelete = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(
    `${process.env.REACT_APP_API_URL}${WEB_NAV_BAR_MAP_LIST}`,
    {
      headers: { Authorization: jwt_token },
      params: paramObj,
    }
  );
};
export const WebNavBarConfigList = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${WEB_NAV_BAR_CONFIG_LIST}`,
    {
      headers: { Authorization: jwt_token },
      params: paramObj,
    }
  );
};
export const WebNavBarConfigListDelete = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(
    `${process.env.REACT_APP_API_URL}${WEB_NAV_BAR_CONFIG_LIST}`,
    {
      headers: { Authorization: jwt_token },
      params: paramObj,
    }
  );
};
export const WebNavBarConfigListUpdate = (body, pageId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${WEB_NAV_BAR_CONFIG_LIST}`,
    body,
    {
      headers: { Authorization: jwt_token },
      params: { pageId },
    }
  );
};
export const WebContentMasterList = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${WEB_CONTENT_MASTER_LIST}`,
    {
      headers: { Authorization: jwt_token },
      params: paramObj,
    }
  );
};
export const WebNavBarMasterList = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${WEB_NAV_BAR_MASTER_LIST}`,
    {
      headers: { Authorization: jwt_token },
      params: paramObj,
    }
  );
};

export const WebNavBarListNavMapId = () => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${WEB_NAV_BAR_NAV_MAP_ID}`,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const CreateWebContentMaster = (navBars) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${WEB_CONTENT_MASTER_CREATE}`,
    navBars,
    {
      headers: { authorization: jwt_token },
    }
  );
};
export const CreateWebNavBarMap = (formdata) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${WEB_NAV_BAR_CREATE}`,
    formdata,
    {
      headers: { authorization: jwt_token },
    }
  );
};
export const UpdateWebNavBarMap = (navBars) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${WEB_NAV_BAR_CREATE}`,
    navBars,
    {
      headers: { authorization: jwt_token },
      params: { navMapId: navBars.navMapId },
    }
  );
};
export const DetailWebNavBarMap = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${WEB_NAV_BAR_DETAIL}`, {
    headers: { authorization: jwt_token },
    params: paramObj,
  });
};
export const CreateWebConfigPage = (formdata) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${WEB_CONFIG_PAGE_CREATE}`,
    formdata,
    {
      headers: { authorization: jwt_token },
    }
  );
};
export const changeSubMenu = (formdata) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${CHANGE_SUB_MENU}`,
    formdata,
    {
      headers: { authorization: jwt_token },
    }
  );
};

export const changeSectionPositionApi = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${CHANGE_POSITION_CHANGE_}`,
    formData,
    {
      headers: { authorization: jwt_token },
    }
  );
};

export const UpdateStyleBackUp = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${STYLE_BACK_UP_UPDATE}`,
    {},
    {
      headers: { authorization: jwt_token },
      // params: { navMapId: formData.navMapId },
    }
  );
};
export const UpdateStyle = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${STYLE_THEME_UPDATE}`,
    formData,
    {
      headers: { authorization: jwt_token },
      // params: { navMapId: formData.navMapId },
    }
  );
};

export const getCommonStyle = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_STYLE_THEME}`, {
    headers: { authorization: jwt_token },
    params: paramObj,
  });
};

export const statusChangeNavMap = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${STATUS_CHANGE_NAV}`,
    paramObj,
    {
      headers: { Authorization: jwt_token },
    }
  );
};
