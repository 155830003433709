import React, { useContext } from "react";
import { OpenModalCommonFunc } from "../../../../utils";
import { Close } from "@material-ui/icons";
import { UploadCloud } from "react-feather";
import ByteCardBuilderContext from "../../../../context/BytecardBilderContext/ByteCardBuilderContext";
import HeaderContext from "../../../../context/HeaderContext/HeaderContext";
import LoaderContext from "../../../../../context/LoaderContext/LoaderContext";
import { Cases, DELETE_CONFIRM } from "../../../../constant";
import { delete_Multi_Image_Permanent } from "../../apis/SliderApi/SliderApi";
import { Zoom, toast } from "react-toastify";
import { Col, Row } from "reactstrap";
import { IconButton } from "@material-ui/core";
import ProductImageUploadPopup from "../../../../Modals/imageUploade/productImageUploadPopup";

const HeaderImageUpload = (props) => {
  const { imageUpload } = props;
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);

  const { settingFormData, setSettingFormData, isEdit, setCheckImageType } =
    useContext(HeaderContext);
  const { setLoaderShow } = useContext(LoaderContext);
  /**
   * delete image permanently
   */
  const deleteImagePermanent = async (imageType) => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData;
        if (imageType === "logo") {
          formData = {
            fileName: settingFormData?.logo
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
              .pop(),
          };
        } else if (imageType === "favicon") {
          formData = {
            fileName: settingFormData?.favicon
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
              .pop(),
          };
        } else if (imageType === "loader") {
          formData = {
            fileName: settingFormData?.loader
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
              .pop(),
          };
        }
        const { data } = await delete_Multi_Image_Permanent(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            position: "top-center",
          });
          setSettingFormData((prevSettingFormData) => {
            if (imageType === "logo") {
              return {
                ...prevSettingFormData,
                logo: "",
                image: "",
              };
            } else if (imageType === "favicon") {
              return {
                ...prevSettingFormData,
                favicon: "",
                image: "",
              };
            } else if (imageType === "loader") {
              return {
                ...prevSettingFormData,
                loader: "",
                image: "",
              };
            }
          });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };
  /**
   * image reupload
   */
  const reupload = () => {
    setSettingFormData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };
  return (
    <>
      <Row className="pt-2">
        <Col md="4 mb-6">
          <h5 className="mb-2">WebSite Logo</h5>
          <p className="mb-2">File type required JPG, PNG</p>
          <div
            className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
            style={{ cursor: "pointer" }}
          >
            {!settingFormData?.logo && (
              <span
                onClick={() => {
                  OpenModalCommonFunc(Cases.PRODUCT_UPLOAD, setAllPopupState);
                  setCheckImageType("logo");
                }}
              >
                <UploadCloud />
              </span>
            )}

            {settingFormData?.logo && (
              <img
                src={settingFormData?.logo || ""}
                alt=""
                style={{
                  width: "85%",
                  height: "90px",
                  objectFit: "contain",
                  position: "absolute",
                }}
                onClick={() => {
                  deleteImagePermanent("logo");
                }}
              />
            )}
            {settingFormData?.logo && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="close"
                style={{
                  color: "red",
                  position: "absolute",
                  top: "42px",
                  left: "1px",
                }}
                onClick={() => {
                  deleteImagePermanent("logo");
                }}
              >
                <Close
                  style={{
                    fontSize: "25px",
                    fontWeight: "bold",
                  }}
                />
              </IconButton>
            )}
          </div>
        </Col>
        {/* favicon */}
        <Col md="4 mb-6">
          <h5 className="mb-2">WebSite Favicon</h5>
          <p className="mb-2">File type required JPG, PNG</p>
          <div
            className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
            style={{ cursor: "pointer" }}
          >
            {!settingFormData?.favicon && (
              <span
                onClick={() => {
                  OpenModalCommonFunc(Cases.PRODUCT_UPLOAD, setAllPopupState);
                  setCheckImageType("favicon");
                }}
              >
                <UploadCloud />
              </span>
            )}

            {settingFormData?.favicon && (
              <img
                src={settingFormData?.favicon || ""}
                alt=""
                style={{
                  width: "85%",
                  height: "90px",
                  objectFit: "contain",
                  position: "absolute",
                }}
                onClick={() => {
                  deleteImagePermanent("favicon");
                }}
              />
            )}
            {settingFormData?.favicon && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="close"
                style={{
                  color: "red",
                  position: "absolute",
                  top: "42px",
                  left: "1px",
                }}
                onClick={() => {
                  deleteImagePermanent("favicon");
                }}
              >
                <Close
                  style={{
                    fontSize: "25px",
                    fontWeight: "bold",
                  }}
                />
              </IconButton>
            )}
          </div>
        </Col>
        {/* favicon */}
        <Col md="4 mb-6">
          <h5 className="mb-2">WebSite Loader</h5>
          <p className="mb-2">File type required GIF</p>
          <div
            className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
            style={{ cursor: "pointer" }}
          >
            {!settingFormData?.loader && (
              <span
                onClick={() => {
                  OpenModalCommonFunc(Cases.PRODUCT_UPLOAD, setAllPopupState);
                  setCheckImageType("loader");
                }}
              >
                <UploadCloud />
              </span>
            )}

            {settingFormData?.loader && (
              <img
                src={settingFormData?.loader || ""}
                alt=""
                style={{
                  width: "85%",
                  height: "90px",
                  objectFit: "contain",
                  position: "absolute",
                }}
                onClick={() => {
                  deleteImagePermanent("loader");
                }}
              />
            )}
            {settingFormData?.loader && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="close"
                style={{
                  color: "red",
                  position: "absolute",
                  top: "42px",
                  left: "1px",
                }}
                onClick={() => {
                  deleteImagePermanent("loader");
                }}
              >
                <Close
                  style={{
                    fontSize: "25px",
                    fontWeight: "bold",
                  }}
                />
              </IconButton>
            )}
          </div>
        </Col>
      </Row>
      <ProductImageUploadPopup
        allPopupState={allPopupState}
        setAllPopupState={setAllPopupState}
        crop={crop}
        setCrop={setCrop}
        completedCrop={completedCrop}
        setCompletedCrop={setCompletedCrop}
        scale={scale}
        rotate={rotate}
        image={settingFormData?.image}
        setImage={setSettingFormData}
        imageUpload={imageUpload}
        isEdit={isEdit}
        reupload={reupload}
        // aspect={3.32 / 1}
      />
    </>
  );
};

export default HeaderImageUpload;
