import React, { useContext, useMemo, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import {
  Cases,
  btnName,
  modalsName,
  options,
  socialMediaTypes,
} from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import SocialMediaImageSection from "../../../common/SocialMediaImageSection";
import CommonPopup from "../../../Modals/commonPopup";
import SocialMediaPopup from "./socialMediaPopup";
import { FILE_UPLOAD_API } from "../../../apis/SliderApi/SliderApi";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import {
  getSocialMedia,
  getSocialMediaDetails,
  getSocialMediaMaster,
  postSocialMedia,
  putUpdateSocialMedia,
  updateStatus,
} from "../../../apis/SocialMediaApi/SocialMediaApi";
import SocialMediaContext from "../../../context/SocialMediaContext/SocialMediaContext";
import { FILE_TYPE } from "../../../../constant";
import SimpleReactValidator from "simple-react-validator";
import { splitPathWeb } from "../../../apiConstants";

const SocialMedia = (props) => {
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );
  const { dataSocialMedia, webMasterDetail, navbarData } = props;
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const {
    apiData: socialMediaData,
    setApiData,
    fromData,
    setFormData,
    setSocialMediaMaster,
    id,
    setId,
    setIsEdit,
    isEdit,
    filter,
    setImages,
  } = useContext(SocialMediaContext);
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * get social media list
   */
  const getSliderList = async (status) => {
    try {
      if (!filter.length && status) {
        setApiData((prev) => {
          return { ...prev, isLoading: true };
        });
      }
      let paramObj = {
        search: { title: filter },
        showAll: true,
      };
      const { data } = await getSocialMedia(paramObj);
      if (data.status === 200) {
        setApiData({
          ...socialMediaData,
          isLoading: false,
          data: data.payload.data.map((elem) => {
            return {
              id: elem?.label + elem?.socialMediaId,
              Id: elem?.socialMediaId,
              itemId: elem?.itemId,
              name: elem?.label,
              des: elem?.value,
              image: elem?.customIcon,
              webId: elem?.webId,
              createdAt: elem?.createdAt,
              isActive: elem?.isActive,
            };
          }),
        });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };
  useMemo(() => {
    if (allPopupState?.socialmediaPopup) {
      getSliderList();
    }
  }, [filter, allPopupState?.socialmediaPopup]);

  /**
   * get social media master list
   */
  const getSocialMediaMasterApi = async () => {
    try {
      let paramObj = {
        showAll: true,
      };
      const { data } = await getSocialMediaMaster(paramObj);
      if (data.status === 200) {
        setSocialMediaMaster(data.payload.data);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };
  useMemo(() => {
    if (allPopupState?.socialmediaPopup) {
      getSocialMediaMasterApi();
    }
  }, [filter, allPopupState?.socialmediaPopup]);

  /**
   * enable disable api call
   * @param {product id} socialMediaId
   * @param {status} isActive
   */

  const handleChangeStatus = async (socialMediaId, isActive) => {
    try {
      let body = {
        socialMediaId,
        isActive: isActive ? false : true,
      };
      const { data } = await updateStatus(body);
      if (data.status === 200) {
        getSliderList(false);
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * upload image
   * @param {file} file
   */
  const imageUpload = async (file) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.BYTE_SLIDER);
    formData.append("file", file);
    setLoaderShow(true);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        setFormData((prev) => {
          return {
            ...prev,
            image: data.payload.baseUrl + data.payload.imageUrl,
          };
        });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * social media create and update
   */
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      if (id >= -1 && isEdit) {
        setLoaderShow(true);
        let newData = {};
        if (fromData.type === socialMediaTypes.custome) {
          newData = {
            itemId: fromData.itemId,
            label: fromData.label,
            value: fromData.value,
            customIcon: fromData.image
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
              .pop(),
            socialMediaId: fromData.socialMediaId,
            navMapId: navbarData[0].navMapId,
          };
          setFormData((prev) => {
            return {
              ...prev,
              customIcon: fromData.image
                .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
                .pop(),
              navMapId: navbarData[0].navMapId,
            };
          });
        } else if (fromData.type === socialMediaTypes.whatsapp) {
          newData = {
            itemId: fromData.itemId,
            label: fromData.label,
            socialMediaId: fromData.socialMediaId,
            value: `${fromData.dialCode.value} ${fromData.value}`,
            navMapId: navbarData[0].navMapId,
          };
          setFormData((prev) => {
            return {
              itemId: prev.itemId,
              label: prev.label,
              socialMediaId: prev.socialMediaId,
              value: `${fromData.dialCode.value} ${fromData.value}`,
              navMapId: navbarData[0].navMapId,
            };
          });
        } else {
          newData = {
            ...fromData,
            navMapId: navbarData[0].navMapId,
          };
          setFormData((prev) => {
            return {
              ...prev,
              navMapId: navbarData[0].navMapId,
            };
          });
        }
        try {
          const { data } = await putUpdateSocialMedia(newData);
          if (data.status === 200) {
            getSliderList();
            emptyData();
            TostMessage({ message: data, type: "success" });
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      } else {
        try {
          setLoaderShow(true);
          let newData = {};
          if (fromData.type === socialMediaTypes.custome) {
            newData = {
              itemId: fromData.itemId,
              label: fromData.label,
              value: fromData.value,
              customIcon: fromData.image
                .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
                .pop(),
              order: socialMediaData.data.length + 1,
              navMapId: navbarData[0].navMapId,
            };
            setFormData((prev) => {
              return {
                ...prev,
                customIcon: fromData.image
                  .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
                  .pop(),
                order: socialMediaData.data.length + 1,
                navMapId: navbarData[0].navMapId,
              };
            });
          } else if (fromData.type === socialMediaTypes.whatsapp) {
            newData = {
              itemId: fromData.itemId,
              label: fromData.label,
              value: `${fromData.dialCode.value} ${fromData.value}`,
              order: socialMediaData.data.length + 1,
              navMapId: navbarData[0].navMapId,
            };
            setFormData((prev) => {
              return {
                itemId: prev.itemId,
                label: prev.label,
                value: `${fromData.dialCode.value} ${fromData.value}`,
                order: socialMediaData.data.length + 1,
                navMapId: navbarData[0].navMapId,
              };
            });
          } else {
            newData = {
              ...fromData,
              order: socialMediaData.data.length + 1,
              navMapId: navbarData[0].navMapId,
            };
            setFormData((prev) => {
              return {
                ...prev,
                order: socialMediaData.data.length + 1,
                navMapId: navbarData[0].navMapId,
              };
            });
          }
          const { data } = await postSocialMedia(newData);
          if (data.status === 200) {
            getSliderList();
            emptyData();
            TostMessage({ message: data, type: "success" });
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      }
    }
  };

  /**
   * get social media details
   * @param {id} socialMediaId
   */
  const handleEdit = async (socialMediaId) => {
    try {
      setLoaderShow(true);
      setId(socialMediaId);
      const { data } = await getSocialMediaDetails(socialMediaId);
      if (data.status === 200) {
        let payload = data.payload.data;
        let dialCodeWithNumber = payload?.value?.split(" ");
        let [filter] = options.filter(
          (elem) => elem.value === dialCodeWithNumber[0]
        );
        setFormData({
          label: payload?.label,
          value: dialCodeWithNumber[1]
            ? dialCodeWithNumber[1]
            : dialCodeWithNumber[0],
          itemId: payload.itemId,
          image: payload?.customIcon,
          type: payload?.itemDetail?.type,
          dialCode: filter,
          socialMediaId: payload?.socialMediaId,
          webId: localStorage.getItem("webId"),
        });
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * data clear
   */
  const emptyData = () => {
    setId("");
    setIsEdit(false);
    setFormData({
      label: "",
      value: "",
      itemId: "",
      image: "",
      type: "",
      dialCode: "",
      webId: localStorage.getItem("webId"),
    });
    setImages([
      { path: "", isDefault: true },
      { path: "", isDefault: false },
      { path: "", isDefault: false },
      { path: "", isDefault: false },
    ]);
    setLoaderShow(false);
  };
  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody className="support-ticket-font box-border-div">
            <Row>
              <div className="col-12">
                <CardBuilderHeaderSection
                  name="Social Media"
                  subName={`${dataSocialMedia?.length} Social accounts`}
                />
              </div>
              <div className="row">
                {dataSocialMedia.length
                  ? dataSocialMedia?.map((elem) => {
                      return (
                        <SocialMediaImageSection
                          src={
                            elem?.itemDetail?.type === socialMediaTypes.custome
                              ? elem.customIcon
                              : require(`./img/${elem?.itemDetail?.type}.png`)
                          }
                        />
                      );
                    })
                  : null}
              </div>
              <OpenPopupBtn
                openPopup={() =>
                  OpenModalCommonFunc(Cases.SOCIAL_MEDIA, setAllPopupState)
                }
                name={btnName.SOCIAL_MEDIA}
              />
            </Row>
          </CardBody>
        </Card>
      </Col>
      <CommonPopup
        isMini={true}
        open={allPopupState.socialmediaPopup}
        close={() =>
          OpenModalCommonFunc(Cases.SOCIAL_MEDIA, setAllPopupState, false)
        }
        modalName={modalsName.SOCIALMEDIA}
        modalCase={Cases.SOCIAL_MEDIA}
        handleSubmit={handleSubmit}
        emptyData={emptyData}
        inputData={fromData}
        edit={false}
        content={
          <SocialMediaPopup
            handleChangeStatus={handleChangeStatus}
            getData={getSliderList}
            handleEdit={handleEdit}
            imageUpload={imageUpload}
            emptyData={emptyData}
            simpleValidator={simpleValidator}
            webMasterDetail={webMasterDetail}
          />
        }
      />
    </>
  );
};

export default SocialMedia;
