import axios from "axios";
import {
  GET_SERVICE_COUNTER,
  GET_SERVICE_COUNTER_DETAILS,
  POST_SERVICE_COUNTER,
  PUT_SERVICE_COUNTER_ORDER,
  PUT_STATUS_SERVICE_COUNTER,
} from "..";

/**
 * get service counter
 * @param {Parameters} paramObj
 * @returns
 */
export const getServiceCounter = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_SERVICE_COUNTER}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * enable-disable service counter
 * @param {body} body
 * @returns
 */
export const updateStatus = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_STATUS_SERVICE_COUNTER}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * create service counter
 * @param {body} body
 * @returns
 */
export const postServiceCounter = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${POST_SERVICE_COUNTER}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * update service counter
 * @param {body} body
 * @returns
 */
export const putUpdateServiceCounter = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${POST_SERVICE_COUNTER}`,
    body,
    {
      headers: { Authorization: jwt_token },
      params: { serviceCounterId: body.serviceCounterId },
    }
  );
};

/**
 * delete service counter
 * @param {id} serviceCounterId
 * @returns
 */
export const deleteServiceCounter = (serviceCounterId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(
    `${process.env.REACT_APP_API_URL}${POST_SERVICE_COUNTER}`,
    {
      headers: { Authorization: jwt_token },
      params: { serviceCounterId },
    }
  );
};

/**
 * get service counter
 * @param {id} serviceCounterId
 * @returns
 */
export const getServiceCounterDetails = (serviceCounterId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${GET_SERVICE_COUNTER_DETAILS}`,
    {
      headers: { Authorization: jwt_token },
      params: { serviceCounterId },
    }
  );
};

/**
 * short order service counter
 * @param {body} updateOrder
 * @returns
 */
export const sort_service_counter = (updateOrder) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_SERVICE_COUNTER_ORDER}`,
    { updateOrder: updateOrder },
    {
      headers: { Authorization: jwt_token },
    }
  );
};
