import React from "react";

const SocialMediaImageSection = (props) => {
  const { src } = props;
  return (
    <div style={{ width: "50px" }}>
      <img src={src} alt="" style={{ width: "100%" }} />
    </div>
  );
};

export default SocialMediaImageSection;
