import React, { useState } from "react";
import PackagesContext from "./PackagesContext";
import { randomStr } from "../../../utils/helper";

const PackagesState = (props) => {
  const [apiData, setApiData] = useState({ data: [], isLoading: false });
  const [apiDataOurTeam, setApiDataOurTeam] = useState({
    isLoading: false,
    data: [],
  });
  const [skill, setSkill] = useState([
    { id: randomStr(2), skill: "", parentageOfSkill: "" },
  ]);
  const [storeIndex, setStoreIndex] = useState("");
  const [collId, setCollId] = useState();
  const [fromData, setFormData] = useState({
    title: "",
    subTitle: "",
    theme: "",
    slugUrl: "",
    buttonText: "",
    itemLimit: "",
    navMapId: "",
    isTitleShow: true,
    webId: localStorage.getItem("webId"),
    style: {
      ".packages-section": {
        padding: "100px 0",
        "background-color": "rgb(255 255 255 / 100)",
      },
      ".packages-section.bg-image": {
        " background-image": "",
      },
      ".packages-section.bg-image::before": {
        "background-color": "",
      },
      ".packages-section .section-title-area": {
        "margin-bottom": "50px",
      },
      ".packages-section .section-title-area .section-subtitle": {
        "font-size": "16px",
        "font-weight": "600",
        color: "rgb(255 73 124 / 100)",
        "background-color": "rgb(255 73 124 / 20%)",
        padding: "5px 20px",
        "border-radius": "15px",
        "margin-bottom": "15px",
      },
      ".packages-section .section-title-area .section-title": {
        "font-size": "44px",
        "font-weight": "600",
        color: "rgb(0 0 0 / 100)",
      },
      ".planswitch": {
        "align-items": "center",
        "justify-content": "center",
        "margin-bottom": "20px",
      },
      ".planswitch .switch-text": {
        color: "rgb(0 0 0 / 100)",
        "font-size": "16px",
        "font-weight": "600",
      },
      ".switch .slider": {
        "border-radius": "34px",
        "background-color": "rgb(204 204 204 / 100)",
      },
      ".switch .slider:before": {
        bottom: "4px",
        "border-radius": "50%",
        "background-color": "rgb(255 255 255 / 100)",
      },
      ".switch input:checked + .slider": {
        "background-color": "rgb(255 73 124 / 100)",
      },
      ".packagelist-1 .package": {
        border: "1px solid rgb(238 238 238 / 100)",
        borderColor: "rgb(238 238 238 / 100)",
        "border-radius": "5px",
      },
      ".packages-section .packagelist-1 .package .title": {
        color: "rgb(255 255 255 / 100)",
        padding: "30px 10px 20px",
        "text-align": "center",
        "text-transform": "uppercase",
        "background-color": "rgb(255 73 124 / 100)",
      },
      ".packagelist-1 .package .title:before": {
        "background-color": "rgb(238 238 238 / 100)",
      },
      ".packagelist-1 .package .title h3": {
        "font-weight": "700",
        "font-size": "20px",
      },
      ".packagelist-1 .package .title p": {
        "font-weight": "400",
      },
      ".packagelist-1 .package .cost": {
        "background-color": "rgb(238 238 238 / 100)",
        width: "100%",
        "text-align": "center",
        padding: "15px 10px 25px",
        "font-size": "18px",
        "font-weight": "700",
      },
      ".packagelist-1 .package .cost:before": {
        "background-color": "rgb(255 255 255 / 100)",
      },
      ".packagelist-1 .package .cost .price": {
        "font-size": "35px",
      },
      ".packagelist-1 .package .desq": {
        "background-color": "rgb(255 255 255 / 100)",
        padding: "10px 10px 20px",
      },
      ".packagelist-1 .package .desq li": {
        "font-size": "14px",
        "font-weight": "400",
        color: "rgb(102 102 102 / 100)",
        margin: "15px 0",
        padding: "0 5px 0 20px",
      },
      ".packagelist-1 .package .desq li:before": {
        color: "rgb(255 73 124 / 100)",
      },
      ".packages-section .packagelist-1 .package .selectbtn": {
        "background-color": "rgb(255 73 124 / 100)",
        padding: "10px 10px 15px",
        "font-size": "16px",
        color: "rgb(255 255 255 / 100)",
        "font-weight": "400",
        "text-transform": "uppercase",
        "text-align": "center",
      },
      ".packagelist-1 .package .selectbtn:before": {
        "background-color": "rgb(255 73 124 / 100)",
      },
      ".packagelist-2 .package": {
        border: "1px solid rgb(238 238 238 / 100)",
        borderColor: "rgb(238 238 238 / 100)",
        "border-radius": "5px",
      },
      ".packages-section .packagelist-2 .package .title": {
        padding: "15px",
        color: "rgb(255 255 255 / 100)",
        "font-size": "20px",
        "font-weight": "500",
        "background-color": "rgb(255 73 124 / 100)",
        "text-align": "center",
        "text-transform": "uppercase",
      },
      ".packagelist-2 .package .cost": {
        padding: "20px 10px",
        "text-align": "center",
        "background-color": "rgb(31 39 50 / 100)",
      },
      ".packagelist-2 .package .cost span": {
        border: "2px solid rgb(255 255 255 / 100)",
        borderColor: "rgb(255 255 255 / 100)",
        "border-radius": "50%",
        "line-height": "70px",
        "text-align": "center",
        display: "inline-block",
        color: "rgb(255 255 255 / 100)",
        "box-shadow": "0 2px 2px 1px rgb(0 0 0 / 0.188)",
        shadowColor: "rgb(0 0 0 / 0.188)",
      },
      ".packagelist-2 .package .subtitle": {
        padding: "15px 10px",
        color: "rgb(255 255 255 / 100)",
        "background-color": "rgb(117 127 137 / 100)",
        "text-align": "center",
        "font-weight": "400",
        "font-size": "14px",
      },
      ".packagelist-2 .package .desq": {
        "background-color": "rgb(255 255 255 / 100)",
        padding: "13px",
      },
      ".packagelist-2 .package .desq li": {
        margin: "5px 0",
        "padding-left": "22px",
        "font-size": "12px",
        color: "rgb(102 102 102 / 100)",
      },
      ".packagelist-2 .package .desq li:before": {
        color: "rgb(255 192 203 / 100)",
      },
      ".packages-section .packagelist-2 .package .selectbtn": {
        "text-align": "center",
        padding: "10px",
        display: "inline-block",
        width: "100%",
        "background-color": "rgb(255 73 124 / 100)",
      },
      ".packagelist-2 .package .selectbtn a": {
        border: "2px solid rgb(255 255 255 / 100)",
        borderColor: "rgb(255 255 255 / 100)",
        padding: "8px 25px",
        "font-size": "12px",
        "text-transform": "uppercase",
        "letter-spacing": "1px",
        color: "rgb(255 255 255 / 100)",
        "border-radius": "3px",
      },
      ".packagelist-2 .package .selectbtn a:hover": {
        "background-color": "rgb(255 255 255 / 100)",
        color: "rgb(102 102 102 / 100)",
      },
      ".packages-section .more-btn": {
        color: "rgb(255 255 255 / 100)",
        "font-size": "16px",
        "font-weight": "normal",
        "border-radius": "15px",
        padding: "5px 20px",
        "background-color": "rgb(255 73 124 / 100)",
      },
      ".packages-section .more-btn:hover": {
        "background-color": "rgb(255 73 124 / 100)",
        color: "rgb(255 255 255 / 100)",
      },
      ".pagination-positions": {
        "justify-content": "center",
      },
      ".pagination .page-link": {
        color: "rgb(255 73 124 / 100)",
      },
      ".pagination .page-item.active .page-link": {
        "background-color": "rgb(255 73 124 / 100)",
        "border-color": "rgb(255 73 124 / 100)",
      },
    },
  });
  const [backImageToggle, setBackImageToggle] = useState(false);

  const [formDataChild, setFormDataChild] = useState({
    image: "",
    title: "",
    subTitle: "",
    buttonText: "",
    valueText: "",
    planDuration: "",
    isTitleShow: true,
    value: "",
    buttonValue: "",
    featureObject: [
      {
        id: randomStr(2),
        icon: "",
        description: "",
      },
    ],
    order: 1,
    webId: localStorage.getItem("webId"),
    navMapId: "",
  });

  const [settingFormData, setSettingFormData] = useState({
    ".packages-section": {
      padding: "100px 0",
      "background-color": "rgb(255 255 255 / 100)",
    },
    ".packages-section.bg-image": {
      " background-image": "",
    },
    ".packages-section.bg-image::before": {
      "background-color": "",
    },
    ".packages-section .section-title-area": {
      "margin-bottom": "50px",
    },
    ".packages-section .section-title-area .section-subtitle": {
      "font-size": "16px",
      "font-weight": "600",
      color: "rgb(255 73 124 / 100)",
      "background-color": "rgb(255 73 124 / 20%)",
      padding: "5px 20px",
      "border-radius": "15px",
      "margin-bottom": "15px",
    },
    ".packages-section .section-title-area .section-title": {
      "font-size": "44px",
      "font-weight": "600",
      color: "rgb(0 0 0 / 100)",
    },
    ".planswitch": {
      "align-items": "center",
      "justify-content": "center",
      "margin-bottom": "20px",
    },
    ".planswitch .switch-text": {
      color: "rgb(0 0 0 / 100)",
      "font-size": "16px",
      "font-weight": "600",
    },
    ".switch .slider": {
      "border-radius": "34px",
      "background-color": "rgb(204 204 204 / 100)",
    },
    ".switch .slider:before": {
      bottom: "4px",
      "border-radius": "50%",
      "background-color": "rgb(255 255 255 / 100)",
    },
    ".switch input:checked + .slider": {
      "background-color": "rgb(255 73 124 / 100)",
    },
    ".packagelist-1 .package": {
      border: "1px solid rgb(238 238 238 / 100)",
      borderColor: "rgb(238 238 238 / 100)",
      "border-radius": "5px",
    },
    ".packages-section .packagelist-1 .package .title": {
      color: "rgb(255 255 255 / 100)",
      padding: "30px 10px 20px",
      "text-align": "center",
      "text-transform": "uppercase",
      "background-color": "rgb(255 73 124 / 100)",
    },
    ".packagelist-1 .package .title:before": {
      "background-color": "rgb(238 238 238 / 100)",
    },
    ".packagelist-1 .package .title h3": {
      "font-weight": "700",
      "font-size": "20px",
    },
    ".packagelist-1 .package .title p": {
      "font-weight": "400",
    },
    ".packagelist-1 .package .cost": {
      "background-color": "rgb(238 238 238 / 100)",
      width: "100%",
      "text-align": "center",
      padding: "15px 10px 25px",
      "font-size": "18px",
      "font-weight": "700",
    },
    ".packagelist-1 .package .cost:before": {
      "background-color": "rgb(255 255 255 / 100)",
    },
    ".packagelist-1 .package .cost .price": {
      "font-size": "35px",
    },
    ".packagelist-1 .package .desq": {
      "background-color": "rgb(255 255 255 / 100)",
      padding: "10px 10px 20px",
    },
    ".packagelist-1 .package .desq li": {
      "font-size": "14px",
      "font-weight": "400",
      color: "rgb(102 102 102 / 100)",
      margin: "15px 0",
      padding: "0 5px 0 20px",
    },
    ".packagelist-1 .package .desq li:before": {
      color: "rgb(255 73 124 / 100)",
    },
    ".packages-section .packagelist-1 .package .selectbtn": {
      "background-color": "rgb(255 73 124 / 100)",
      padding: "10px 10px 15px",
      "font-size": "16px",
      color: "rgb(255 255 255 / 100)",
      "font-weight": "400",
      "text-transform": "uppercase",
      "text-align": "center",
    },
    ".packagelist-1 .package .selectbtn:before": {
      "background-color": "rgb(255 73 124 / 100)",
    },
    ".packagelist-2 .package": {
      border: "1px solid rgb(238 238 238 / 100)",
      borderColor: "rgb(238 238 238 / 100)",
      "border-radius": "5px",
    },
    ".packages-section .packagelist-2 .package .title": {
      padding: "15px",
      color: "rgb(255 255 255 / 100)",
      "font-size": "20px",
      "font-weight": "500",
      "background-color": "rgb(255 73 124 / 100)",
      "text-align": "center",
      "text-transform": "uppercase",
    },
    ".packagelist-2 .package .cost": {
      padding: "20px 10px",
      "text-align": "center",
      "background-color": "rgb(31 39 50 / 100)",
    },
    ".packagelist-2 .package .cost span": {
      border: "2px solid rgb(255 255 255 / 100)",
      borderColor: "rgb(255 255 255 / 100)",
      "border-radius": "50%",
      "line-height": "70px",
      "text-align": "center",
      display: "inline-block",
      color: "rgb(255 255 255 / 100)",
      "box-shadow": "0 2px 2px 1px rgb(0 0 0 / 0.188)",
      shadowColor: "rgb(0 0 0 / 0.188)",
    },
    ".packagelist-2 .package .subtitle": {
      padding: "15px 10px",
      color: "rgb(255 255 255 / 100)",
      "background-color": "rgb(117 127 137 / 100)",
      "text-align": "center",
      "font-weight": "400",
      "font-size": "14px",
    },
    ".packagelist-2 .package .desq": {
      "background-color": "rgb(255 255 255 / 100)",
      padding: "13px",
    },
    ".packagelist-2 .package .desq li": {
      margin: "5px 0",
      "padding-left": "22px",
      "font-size": "12px",
      color: "rgb(102 102 102 / 100)",
    },
    ".packagelist-2 .package .desq li:before": {
      color: "rgb(255 192 203 / 100)",
    },
    ".packages-section .packagelist-2 .package .selectbtn": {
      "text-align": "center",
      padding: "10px",
      display: "inline-block",
      width: "100%",
      "background-color": "rgb(255 73 124 / 100)",
    },
    ".packagelist-2 .package .selectbtn a": {
      border: "2px solid rgb(255 255 255 / 100)",
      borderColor: "rgb(255 255 255 / 100)",
      padding: "8px 25px",
      "font-size": "12px",
      "text-transform": "uppercase",
      "letter-spacing": "1px",
      color: "rgb(255 255 255 / 100)",
      "border-radius": "3px",
    },
    ".packagelist-2 .package .selectbtn a:hover": {
      "background-color": "rgb(255 255 255 / 100)",
      color: "rgb(102 102 102 / 100)",
    },
    ".packages-section .more-btn": {
      color: "rgb(255 255 255 / 100)",
      "font-size": "16px",
      "font-weight": "normal",
      "border-radius": "15px",
      padding: "5px 20px",
      "background-color": "rgb(255 73 124 / 100)",
    },
    ".packages-section .more-btn:hover": {
      "background-color": "rgb(255 73 124 / 100)",
      color: "rgb(255 255 255 / 100)",
    },
    ".pagination-positions": {
      "justify-content": "center",
    },
    ".pagination .page-link": {
      color: "rgb(255 73 124 / 100)",
    },
    ".pagination .page-item.active .page-link": {
      "background-color": "rgb(255 73 124 / 100)",
      "border-color": "rgb(255 73 124 / 100)",
    },
    packageId: "",
  });
  const [boxSection, setBoxSection] = useState(false);
  const [id, setId] = useState("");
  const [childId, setChildId] = useState("");
  const [navMapId, setNavMapId] = useState("");
  const [galleryImgId, setGalleryImgId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isParent, setIsParent] = useState(false);
  const [filter, setFilter] = useState("");
  const [isBackgroundColor, setIsBackgroundColor] = useState(false);
  const [theme, setTheme] = useState();
  const [imgShow, setImgShow] = useState(false);
  const [sectionPageName, setSectionPageName] = useState("");
  return (
    <PackagesContext.Provider
      value={{
        apiData,
        setApiData,
        apiDataOurTeam,
        setApiDataOurTeam,
        fromData,
        setFormData,
        id,
        setId,
        isEdit,
        setIsEdit,
        filter,
        setFilter,
        skill,
        galleryImgId,
        setGalleryImgId,
        setSkill,
        settingFormData,
        setSettingFormData,
        formDataChild,
        setFormDataChild,
        isParent,
        setIsParent,
        childId,
        setChildId,
        navMapId,
        setNavMapId,
        backImageToggle,
        setBackImageToggle,
        boxSection,
        setBoxSection,
        collId,
        setCollId,
        storeIndex,
        setStoreIndex,
        isBackgroundColor,
        setIsBackgroundColor,
        theme,
        setTheme,
        imgShow,
        setImgShow,
        sectionPageName,
        setSectionPageName,
      }}
    >
      {props.children}
    </PackagesContext.Provider>
  );
};

export default PackagesState;
