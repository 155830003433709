import axios from "axios";
import {
  PASSWORD_CHANGE,
  SEND_OTP,
  SET_PASSWORD,
} from "../../constant/API constant";

export const Password_Change = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PASSWORD_CHANGE}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const setPasswordApi = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${SET_PASSWORD}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const sendOtpApi = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(`${process.env.REACT_APP_API_URL}${SEND_OTP}`, formData, {
    headers: { Authorization: jwt_token },
  });
};
