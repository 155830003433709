import React, { useState } from "react";
import CompanyLogoContext from "./CompanyLogoContext";

const CompanyLogoState = (props) => {
  const [apiData, setApiData] = useState({ data: [], isLoading: false });

  const [fromData, setFormData] = useState({
    logo: "",
    image: "",
    tagLine: "",
    name: "",
    designation: "",
    services: [],
    webId: localStorage.getItem("webId"),
  });
  const [id, setId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [filter, setFilter] = useState("");
  return (
    <CompanyLogoContext.Provider
      value={{
        apiData,
        setApiData,
        fromData,
        setFormData,
        id,
        setId,
        isEdit,
        setIsEdit,
        filter,
        setFilter,
      }}
    >
      {props.children}
    </CompanyLogoContext.Provider>
  );
};

export default CompanyLogoState;
