import React, { useContext, useMemo, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Cases, DELETE_CONFIRM, modalsName } from "../../../constant";
import EditIcon from "@mui/icons-material/Edit";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import CommonPopup from "../../../Modals/commonPopup";
import VideosPopup from "./videosPopup";
import VideosParentPopup from "./videosPerentPopup";
import {
  deleteVideos,
  getVideos,
  getVideosDetails,
  getVideosItems,
  getVideosItemsDetails,
  postVideos,
  postVideosItems,
  putUpdateVideos,
  putVideosItems,
  updateStatus,
  updateStatusVideosItems,
} from "../apis/VideosApi/VideosApi";
import VideosContext from "../../../context/VideosContext/VideosContext";
import { IconButton } from "@material-ui/core";
import { Settings, Trash } from "react-feather";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import SimpleReactValidator from "simple-react-validator";
import {
  generateDynamicStyles,
  generateDynamicStylesSet,
  isEmptyObject,
  rgbColorCreate,
} from "../../../../utils/helper";
import ChildShow from "./ChildShow";
import { Boy } from "@mui/icons-material";
import SettingVideoParentPopup from "./SettingVideoParentPopup";
import { FILE_TYPE, ThemeNo } from "../../../../constant";
import { FILE_UPLOAD_API } from "../apis/SliderApi/SliderApi";
import DeleteSection from "../../../common/DeleteSection";
import SettingMultiWebContext from "../../../../context/SettingMultiWebContext/SettingMultiWebContext";
import {
  VideoStyleOneReset,
  VideoStyleOneSubFun,
  VideoThemeOneFunction,
} from "./VideoStyleFunction";

const Videos = (props) => {
  const {
    pageConfigDetails,
    webMasterDetail,
    videosDetail,
    contentMapId,
    ByteMiniWeb_Dashboard,
    navMapId: navMapIds,
    contentDetail,
  } = props;
  const { setAllPopupState, allPopupState } = useContext(
    ByteCardBuilderContext
  );
  const { setCloneAlignModal, setAlignFormData, setCloneFormData } = useContext(
    SettingMultiWebContext
  );
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const simpleValidatorVideoParent = useRef(new SimpleReactValidator());
  const [, forceUpdate1] = useState();
  const { setLoaderShow } = useContext(LoaderContext);
  const [childId, setChildId] = useState("");
  const [childData, setChildData] = useState([]);
  const [idCopy, setIdCopy] = useState("");
  const [isBack, setIsBack] = useState([]);

  const [CountUp, setCountUp] = useState(0);
  const {
    apiData: videoData,
    setApiData,
    fromData,
    setFormData,
    formDataVideos,
    setFormDataVideos,
    id,
    setId,
    setIsEdit,
    isEdit,
    filter,
    videoId,
    setVideoId,
    videoItemId,
    setVideoItemId,
    collId,
    setCollId,
    setBoxSection,
    setBackImageToggle,
    setSettingFormData,
    settingFormData,
    boxSection,
    isBackgroundColor,
    setIsBackgroundColor,
    setIsVideoParent,
    isChildForFormSubmit,
    setIsChildForFormSubmit,
    ids,
    setIds,
    theme,
    setTheme,
    setImgShow,
    sectionPageName,
    setSectionPageName,
  } = useContext(VideosContext);

  /**
   * get videos items list
   */
  const getVideosItemsList = async (status) => {
    try {
      if (!filter.length && status) {
        setApiData((prev) => {
          return { ...prev, isLoading: true };
        });
      }
      let paramObj = {
        search: { title: filter, videoId: id },
        showAll: true,
      };
      const { data } = await getVideosItems(paramObj);
      if (data.status === 200) {
        setApiData({
          ...videoData,
          isLoading: false,
          data: data.payload.data.map((elem) => {
            return {
              id: elem?.title + elem?.videoItemId,
              Id: elem?.videoItemId,
              videoId: elem?.videoId,
              name: elem?.title,
              createdAt: elem?.createdAt,
              isActive: elem?.isActive,
            };
          }),
        });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };
  /**
   * get Videos list
   * @param {status} status
   */
  const getVideosList = async ({ id, prev, isChild }) => {
    try {
      let paramObj = {
        showAll: true,
      };
      if (isChild) {
        if (prev === "prev") {
          paramObj["search"] = { parentId: ids[id] };
          let obj = { ...ids };
          if (ids[id]) {
            delete obj[id];
          }
          setIds(obj);
        } else {
          paramObj["search"] = { parentId: id || idCopy || childId || null };
        }
      } else {
        paramObj = {
          ...paramObj,
          search: { parentId: null, contentMapId },
        };
      }

      const { data } = await getVideos(paramObj);
      if (data.status === 200) {
        if (isChild) {
          if (data.payload.data.length <= 0) {
            setIsBack([...childData]);
          } else {
            setIsBack([]);
          }
        }
        setChildData(data.payload.data);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };
  useMemo(() => {
    if (allPopupState?.videosPopup) {
      getVideosItemsList();
    }
  }, [filter, allPopupState?.videosPopup]);

  /**
   * videos enable disable api call
   * @param {video id} videoId
   * @param {status} isActive
   */

  const handleChangeVideoStatus = async (
    videoId,
    isActive,
    isChild,
    parentId
  ) => {
    try {
      let body = {
        videoId,
        isActive: isActive ? false : true,
      };
      if (parentId) {
        body["parentId"] = parentId;
      }
      const { data } = await updateStatus(body);
      if (data.status === 200) {
        if (isChild) {
          getVideosList({ isChild });
        } else {
          ByteMiniWeb_Dashboard();
        }

        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };
  /**
   * videos items enable disable api call
   * @param {videoItem id} videoItemId
   * @param {status} isActive
   */

  const handleChangeVideosItemsStatus = async (videoItemId, isActive) => {
    try {
      let body = {
        videoItemId,
        isActive: isActive ? false : true,
      };
      const { data } = await updateStatusVideosItems(body);
      if (data.status === 200) {
        getVideosItemsList();
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  // background image for style
  const imageUpload = async (file) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.VIDEO);
    formData.append("file", file);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        setSettingFormData((prev) => {
          return {
            ...prev,
            ".video-section.bg-image": {
              "background-image": data.payload.baseUrl + data.payload.imageUrl,
            },
          };
        });
        setLoaderShow(false);
        return data.payload.baseUrl + data.payload.imageUrl;
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * create and update Videos
   */
  const handleSubmit = async () => {
    const formValid = simpleValidatorVideoParent.current.allValid();
    if (!formValid) {
      simpleValidatorVideoParent.current.showMessages();
      forceUpdate1(1);
    } else {
      if (videoId >= -1 && isEdit) {
        let formData = {
          ...formDataVideos,
          contentMapId: collId,
          navMapId: +navMapIds,
        };
        if (formData.parentId) {
          delete formData.style;
        }
        setLoaderShow(true);
        try {
          const { data } = await putUpdateVideos(formData);
          if (data.status === 200) {
            if (isChildForFormSubmit) {
              getVideosList({ isChild: isChildForFormSubmit });
            }
            ByteMiniWeb_Dashboard();
            OpenModalCommonFunc(Cases.VIDEOS_PARENT, setAllPopupState, false);
            TostMessage({ message: data, type: "success" });
            emptyData();
            simpleValidatorVideoParent.current.hideMessages();
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      } else {
        try {
          let formData = {
            ...formDataVideos,
            contentMapId: collId,
            navMapId: +navMapIds,
          };
          if (formData.parentId) {
            delete formData.style;
          }
          setLoaderShow(true);
          const { data } = await postVideos(formData);
          if (data.status === 200) {
            if (isChildForFormSubmit) {
              getVideosList({ isChild: isChildForFormSubmit });
            } else {
              ByteMiniWeb_Dashboard();
            }
            OpenModalCommonFunc(Cases.VIDEOS_PARENT, setAllPopupState, false);
            TostMessage({ message: data, type: "success" });
            emptyData();
            simpleValidatorVideoParent.current.hideMessages();
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      }
    }
  };

  /**
   * create and update videos items
   */
  const handleSubmitVideosItems = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      if (videoItemId >= -1 && isEdit) {
        let formData = { ...fromData, contentMapId: collId };
        setLoaderShow(true);
        try {
          const { data } = await putVideosItems(formData);
          if (data.status === 200) {
            getVideosItemsList();
            emptyData();
            TostMessage({ message: data, type: "success" });
            simpleValidator.current.hideMessages();
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      } else {
        setLoaderShow(true);
        let newObj = {
          ...fromData,
          videoId: id,
          order: videoData.data.length + 1,
          contentMapId: collId,
          isDefault: true,
        };
        try {
          const { data } = await postVideosItems(newObj);
          if (data.status === 200) {
            getVideosItemsList();
            emptyData();
            TostMessage({ message: data, type: "success" });
            simpleValidator.current.hideMessages();
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      }
    }
  };

  /**
   * get videos details
   * @param {id} videoId
   */
  const handleEdit = async (videoId, isParent, isChild = false) => {
    setIsVideoParent(isParent);
    try {
      setLoaderShow(true);
      setVideoId(videoId);
      const { data } = await getVideosDetails(videoId, navMapIds);
      if (data.status === 200) {
        setIsEdit(true);
        setIsChildForFormSubmit(true);
        let payload = data.payload.data;
        setSectionPageName(payload.title);
        let videoParent = {
          title: payload.title,
          description: payload.description,
          buttonText: payload?.buttonText || "",
          itemLimit: payload?.itemLimit || "",
          slugUrl: payload.slugUrl,
          isYoutube: payload.isYoutube,
          isAlbum: payload.isAlbum,
          videoId: payload.videoId,
          isTitleShow: payload?.isTitleShow ? payload?.isTitleShow : false,
          webId: localStorage.getItem("webId"),
        };
        if (payload?.parentId) {
          videoParent["parentId"] = payload?.parentId;
        }
        setFormDataVideos({
          ...videoParent,
        });
        OpenModalCommonFunc(Cases.VIDEOS_PARENT, setAllPopupState);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * get videos items
   * @param {id} videoItemId
   */
  const handleEditOurTeam = async (videoItemId) => {
    try {
      setLoaderShow(true);
      setVideoItemId(videoItemId);
      const { data } = await getVideosItemsDetails(videoItemId);
      if (data.status === 200) {
        let payload = data.payload.data;
        setFormData({
          title: payload.title,
          url: payload.url,
          videoId: payload.videoId,
          videoItemId: videoItemId,
          isTitleShow: payload.isTitleShow || true,
          webId: localStorage.getItem("webId"),
        });
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * delete videos
   * @param {id} videoId
   */
  const handleDelete = async (
    videoId,
    isParent = false,
    isLastChild = false
  ) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await deleteVideos(videoId);
        if (data.status === 200) {
          // getVideosList();
          if (isParent && isParent !== false) {
            ByteMiniWeb_Dashboard();
          } else {
            getVideosList({ isChild: true, isLastChild });
          }
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * clear data
   */
  const emptyData = () => {
    setIsBackgroundColor(false);
    setBoxSection(false);
    setIsEdit(false);
    setFormData({
      title: "",
      url: "",
      videoId: "",
      isTitleShow: true,
      webId: localStorage.getItem("webId"),
    });
    setSettingFormData({
      ".video-section": {
        padding: "100px 0",
        "background-color": "rgb(255 255 255 / 100)",
      },
      ".video-section.bg-image": {
        "background-image": "",
      },
      ".video-section.bg-image::before": {
        "background-color": "",
      },
      ".video-section .section-title-area": {
        "margin-bottom": "50px",
      },
      ".video-section .section-title-area .section-subtitle": {
        "font-size": "16px",
        "font-weight": "600",
        color: "rgb(255 73 124 / 100)",
        "background-color": "rgb(255 73 124 / 0.2)",
        padding: "5px 20px",
        p1: "5px",
        p2: "20px",
        "border-radius": "15px",
        "margin-bottom": "15px",
      },
      ".video-section .section-title-area .section-title": {
        "font-size": "44px",
        "font-weight": "600",
        color: "rgb(0 0 0 / 100)",
      },
      ".video-section .gallery-catlist": {
        "text-align": "center",
        "margin-bottom": "30px",
      },
      ".video-section .gallery-catlist .btn": {
        border: "2px solid rgb(255 73 124 / 100)",
        color: "rgb(255 73 124 / 100)",
        padding: "6px 20px",
        margin: "5px",
        "box-shadow": "none",
      },
      ".video-section .gallery-catlist .active": {
        "background-color": "rgb(255 73 124 / 100)",
        color: "rgb(255 255 255 / 100)",
      },
      ".video-section .video-list .box": {
        "margin-bottom": "20px",
        // outline: "none",
        outline: "0px solid rgb(255 255 255 / 100)",
        outPx: "0px",
        outThik: "solid",
        outColor: "rgb(255 255 255 / 100)",
        "background-color": "rgb(255 255 255 / 100)",
        "box-shadow": "0 5px 10px 0 rgb(7 28 31 / 0.039)",
        bs1: "0px",
        bs2: "5px",
        bs3: "10px",
        bs4: "0px",
        shadowColor: "rgb(7 28 31 / 0.039)",
      },
      ".video-section .video-list .video-img:before": {
        color: "rgb(255 255 255 / 100)",
        "font-size": "30px",
      },
      ".video-section .video-list .title-text": {
        "background-color": "rgb(255 73 124 / 100)",
        "font-size": "18px",
        "font-weight": "600",
        color: "rgb(255 255 255 / 100)",
        "text-align": "center",
        padding: "15px",
      },
      ".video-section .more-btn": {
        color: "rgb(255 255 255 / 100)",
        "font-size": "16px",
        "font-weight": "normal",
        "border-radius": "15px",
        padding: "5px 20px",
        "background-color": "rgb(255 73 124 / 100)",
      },
      ".video-section .more-btn:hover": {
        "background-color": "rgb(255 73 124 / 100)",
        color: "rgb(255 255 255 / 100)",
      },
      ".pagination-positions": {
        "justify-content": "center",
      },
      ".pagination .page-link": {
        color: "rgb(255 73 124 / 100)",
      },
      ".pagination .page-item.active .page-link": {
        "background-color": "rgb(255 73 124 / 100)",
        "border-color": "rgb(255 73 124 / 100)",
      },
    });
    setVideoId("");
    setFormDataVideos({
      title: "",
      description: "",
      slugUrl: "",
      isYoutube: true,
      isAlbum: false,
      buttonText: "",
      itemLimit: "",
      isTitleShow: true,
      webId: localStorage.getItem("webId"),
      style: {
        ".video-section": {
          padding: "100px 0",
          "background-color": "rgb(255 255 255 / 100)",
        },
        ".video-section.bg-image": {
          "background-image": "",
        },
        ".video-section.bg-image::before": {
          "background-color": "",
        },
        ".video-section .section-title-area": {
          "margin-bottom": "50px",
        },
        ".video-section .section-title-area .section-subtitle": {
          "font-size": "16px",
          "font-weight": "600",
          color: "rgb(255 73 124 / 100)",
          "background-color": "rgb(255 73 124 / 0.2)",
          padding: "5px 20px",
          p1: "5px",
          p2: "20px",
          "border-radius": "15px",
          "margin-bottom": "15px",
        },
        ".video-section .section-title-area .section-title": {
          "font-size": "44px",
          "font-weight": "600",
          color: "rgb(0 0 0 / 100)",
        },
        ".video-section .gallery-catlist": {
          "text-align": "center",
          "margin-bottom": "30px",
        },
        ".video-section .gallery-catlist .btn": {
          border: "2px solid rgb(255 73 124 / 100)",
          color: "rgb(255 73 124 / 100)",
          padding: "6px 20px",
          margin: "5px",
          "box-shadow": "none",
        },
        ".video-section .gallery-catlist .active": {
          "background-color": "rgb(255 73 124 / 100)",
          color: "rgb(255 255 255 / 100)",
        },
        ".video-section .video-list .box": {
          "margin-bottom": "20px",
          // outline: "none",
          outline: "0px solid rgb(255 255 255 / 100)",
          outPx: "0px",
          outThik: "solid",
          outColor: "rgb(255 255 255 / 100)",
          "background-color": "rgb(255 255 255 / 100)",
          "box-shadow": "0 5px 10px 0 rgb(7 28 31 / 0.039)",
          bs1: "0px",
          bs2: "5px",
          bs3: "10px",
          bs4: "0px",
          shadowColor: "rgb(7 28 31 / 0.039)",
        },
        ".video-section .video-list .video-img:before": {
          color: "rgb(255 255 255 / 100)",
          "font-size": "30px",
        },
        ".video-section .video-list .title-text": {
          "background-color": "rgb(255 73 124 / 100)",
          "font-size": "18px",
          "font-weight": "600",
          color: "rgb(255 255 255 / 100)",
          "text-align": "center",
          padding: "15px",
        },
        ".video-section .more-btn": {
          color: "rgb(255 255 255 / 100)",
          "font-size": "16px",
          "font-weight": "normal",
          "border-radius": "15px",
          padding: "5px 20px",
          "background-color": "rgb(255 73 124 / 100)",
        },
        ".video-section .more-btn:hover": {
          "background-color": "rgb(255 73 124 / 100)",
          color: "rgb(255 255 255 / 100)",
        },
        ".pagination-positions": {
          "justify-content": "center",
        },
        ".pagination .page-link": {
          color: "rgb(255 73 124 / 100)",
        },
        ".pagination .page-item.active .page-link": {
          "background-color": "rgb(255 73 124 / 100)",
          "border-color": "rgb(255 73 124 / 100)",
        },
      },
    });
    setIsChildForFormSubmit(false);
    setVideoItemId("");
    setLoaderShow(false);
  };

  /**
   * get setting data
   * @param {id} videoId
   */

  const handleChangeSettings = async (videoId, isThemeEdit = false) => {
    try {
      setLoaderShow(true);
      setId(videoId);
      setSettingFormData((prev) => {
        return {
          ...prev,
          videoId,
        };
      });
      const { data } = await getVideosDetails(videoId);
      if (data.status === 200) {
        const themeCount = data?.payload?.data?.theme;
        setTheme(themeCount ? themeCount : 1);
        setImgShow(true);
        let payload = data.payload.data;
        console.log("payload >> ", payload);
        setSectionPageName(payload.title);
        setIsChildForFormSubmit(true);
        if (
          +themeCount === ThemeNo.ThemeOne &&
          isEmptyObject(data?.payload?.data?.style)
        ) {
          setSettingFormData({ ...VideoStyleOneReset });
          setBoxSection(false);
          setIsBackgroundColor(false);
        } else {
          if (
            payload.style?.[".video-section"]?.["width"] ||
            payload.style?.[".video-section"]?.["margin"] ||
            payload.style?.[".video-section"]?.["border-radius"]
          ) {
            setBoxSection(true);
          }
          if (
            payload.style?.[".video-section.bg-image"]?.["background-image"]
          ) {
            setBackImageToggle(true);
          }
          if (payload.style?.[".video-section"]?.["background-color"]) {
            setIsBackgroundColor(true);
          }
          let getStyle;
          if (+themeCount === ThemeNo.ThemeOne) {
            getStyle = VideoThemeOneFunction(payload);
          } else {
            getStyle = VideoThemeOneFunction(payload);
          }
          const getMenuBtnStyle = generateDynamicStylesSet(
            payload,
            ".video-section"
          );
          setSettingFormData((prev) => {
            return {
              ...prev,
              ...getStyle,
              ...getMenuBtnStyle,
            };
          });
        }
        if (!isThemeEdit) {
          OpenModalCommonFunc(Cases.SETTING_VIDEO_PARENT, setAllPopupState);
          TostMessage({ message: data, type: "success" });
        }
        setIsEdit(true);
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * create and update our client
   */
  const handleSubmitSetting = async (isRestore = false, isClose = true) => {
    if (id >= -1 && isEdit) {
      setLoaderShow(true);
      try {
        let newData;
        if (isRestore) {
          const isConfirm = window.confirm(
            "Are you sure want to restore style ?"
          );
          if (isConfirm) {
            newData = {
              styleRestore: true,
              videoId: id,
            };
          } else {
            setLoaderShow(false);
            return;
          }
        } else {
          setIsChildForFormSubmit(true);
          if (+theme === ThemeNo.ThemeOne) {
            newData = VideoStyleOneSubFun({
              settingFormData,
              boxSection,
              isBackgroundColor,
            });
            const menuBtnStyle = generateDynamicStyles(
              settingFormData,
              ".video-section"
            );
            newData = { ...newData, ...menuBtnStyle };
            if (isBackgroundColor) {
              newData[".video-section"] = {
                ...newData?.[".video-section"],
                "background-color": settingFormData[".video-section"][
                  "background-color"
                ]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData[".video-section"]["background-color"],
                    })
                  : "",
              };
            }
            if (boxSection) {
              newData[".video-section.bg-image::before"] = {
                ...newData[".video-section.bg-image::before"],
                "border-radius": `${settingFormData[".video-section"][
                  "border-radius"
                ]?.replace("rem", "")}rem`,
              };
            }
          }

          const keysToRemove = [
            "p1",
            "p2",
            "image",
            "bs1",
            "bs2",
            "bs3",
            "bs4",
            "shadowColor",
            "outPx",
            "outColor",
            "outThik",
          ];
          function removeKeys(obj, keys) {
            for (const key in obj) {
              if (typeof obj[key] === "object") {
                removeKeys(obj[key], keys);
              } else if (keys.includes(key)) {
                delete obj[key];
              }
            }
          }
          removeKeys(newData, keysToRemove);
          newData = {
            style: newData,
            videoId: id,
          };
        }

        const { data } = await putUpdateVideos(newData);
        if (data.status === 200) {
          if (isClose) {
            OpenModalCommonFunc(
              Cases.SETTING_VIDEO_PARENT,
              setAllPopupState,
              false
            );
            setBackImageToggle(false);
            emptyData();
          }
          setLoaderShow(false);
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  const handleAlignAndCloneSection = ({ isClone = false }) => {
    if (isClone) {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          cloneModal: true,
        };
      });
      setCloneFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          pageConfigDetailsLabel: pageConfigDetails[0]?.label,
          contentDetailName: contentDetail.name,
        };
      });
    } else {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          alignModal: true,
        };
      });
      setAlignFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          label: pageConfigDetails[0]?.label,
        };
      });
    }
  };

  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4">
          <CardBody
            className="support-ticket-font box-border-div"
            style={{ padding: "20px" }}
          >
            <Row>
              <CardBuilderHeaderSection
                addBtnTitle={pageConfigDetails[0]?.label}
                name={pageConfigDetails[0]?.label}
                isAdd={!videosDetail && true}
                mainEditSection={true}
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
                openModal={() => {
                  setCollId(contentMapId);
                  setSectionPageName(modalsName.VIDEOS_PARENT);
                  OpenModalCommonFunc(Cases.VIDEOS_PARENT, setAllPopupState);
                }}
                isCloneSection={true}
                isAlignSection={true}
                handleAlignAndCloneSection={handleAlignAndCloneSection}
              />
              {videosDetail && (
                <Col xl="12 xl-50 box-col-6" md="6">
                  <Card className="ecommerce-widget cardHover mb-0">
                    <CardBody
                      className="support-ticket-font"
                      style={{ padding: "20px" }}
                    >
                      <div className="col-12">
                        <CardBuilderHeaderSection
                          name={videosDetail?.title}
                          checked={videosDetail?.isActive}
                          switchToggle={true}
                          isAdd={true}
                          id={videosDetail?.videoId}
                          handleChange={handleChangeVideoStatus}
                          openModal={() => {
                            OpenModalCommonFunc(
                              Cases.VIDEOS_PARENT,
                              setAllPopupState
                            );
                            setIsVideoParent(true);
                            setCollId(contentMapId);
                            setFormDataVideos((prev) => {
                              return {
                                ...prev,
                                parentId: videosDetail.videoId,
                              };
                            });
                          }}
                        />
                      </div>
                      <div className="d-flex justify-content-end ">
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            OpenModalCommonFunc(
                              Cases.VIDEOS_CHILES,
                              setAllPopupState
                            );
                            setSectionPageName(videosDetail?.title);
                            setChildId(videosDetail.videoId);
                          }}
                        >
                          <Boy />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            setCollId(contentMapId);
                            handleEdit(
                              videosDetail.videoId,
                              videosDetail.parentId ? true : false
                            );
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() =>
                            handleDelete(videosDetail.videoId, true)
                          }
                        >
                          <Trash />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleChangeSettings(videosDetail.videoId);
                          }}
                        >
                          <Settings />
                        </IconButton>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
              <DeleteSection
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
              />
            </Row>
          </CardBody>
        </Card>
      </Col>
      {/* videos items */}
      <CommonPopup
        open={allPopupState.videosPopup}
        close={() => OpenModalCommonFunc(Cases.VIDEOS, setAllPopupState, false)}
        modalName={sectionPageName ? sectionPageName : modalsName.VIDEOS}
        modalCase={Cases.VIDEOS}
        handleSubmit={handleSubmitVideosItems}
        emptyData={emptyData}
        inputData={fromData}
        edit={false}
        content={
          <VideosPopup
            handleChangeStatus={handleChangeVideosItemsStatus}
            getData={getVideosItemsList}
            handleEdit={handleEditOurTeam}
            emptyData={emptyData}
            simpleValidator={simpleValidator}
            webMasterDetail={webMasterDetail}
          />
        }
      />
      {/* videos Parent */}
      <CommonPopup
        open={allPopupState.videosParentPopup}
        close={() =>
          OpenModalCommonFunc(Cases.VIDEOS_PARENT, setAllPopupState, false)
        }
        modalName={sectionPageName ? sectionPageName : modalsName.VIDEOS_PARENT}
        modalCase={Cases.VIDEOS_PARENT}
        handleSubmit={handleSubmit}
        emptyData={emptyData}
        inputData={formDataVideos}
        edit={false}
        content={
          <VideosParentPopup simpleValidator={simpleValidatorVideoParent} />
        }
      />

      {allPopupState.videosChildPopup && (
        <CommonPopup
          open={allPopupState.videosChildPopup}
          close={() =>
            OpenModalCommonFunc(Cases.VIDEOS_CHILES, setAllPopupState, false)
          }
          modalName={
            sectionPageName ? sectionPageName : modalsName.IMAGES_CHILES
          }
          modalCase={Cases.VIDEOS_CHILES}
          handleSubmit={handleSubmit}
          validationForm={"childForm"}
          emptyData={emptyData}
          inputData={formDataVideos}
          edit={false}
          content={
            <ChildShow
              handleChangeImageParent={handleChangeVideoStatus}
              handleEditImagesParent={handleEdit}
              EditIcon={EditIcon}
              handleDelete={handleDelete}
              handleChangeSettings={handleChangeSettings}
              simpleValidator={simpleValidator}
              childId={childId}
              contentMapId={contentMapId}
              setChildId={setChildId}
              childData={childData}
              setChildData={setChildData}
              idCopy={idCopy}
              setIdCopy={setIdCopy}
              ids={ids}
              setIds={setIds}
              CountUp={CountUp}
              setCountUp={setCountUp}
              getVideosList={getVideosList}
              emptyData={emptyData}
              isBack={isBack}
            />
          }
        />
      )}

      {/* setting Service Counter parent popup */}
      <CommonPopup
        open={allPopupState.videoParentSetting}
        close={() =>
          OpenModalCommonFunc(
            Cases.SETTING_VIDEO_PARENT,
            setAllPopupState,
            false
          )
        }
        modalName={
          sectionPageName ? sectionPageName : modalsName.SETTING_VIDEO_PARENT
        }
        modalCase={Cases.SETTING_VIDEO_PARENT}
        handleSubmit={handleSubmitSetting}
        emptyData={emptyData}
        inputData={settingFormData}
        edit={false}
        content={
          <SettingVideoParentPopup
            imageUpload={imageUpload}
            handleSubmitSetting={handleSubmitSetting}
            handleChangeSettings={handleChangeSettings}
          />
        }
      />
    </>
  );
};

export default Videos;
