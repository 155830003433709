import React, { useState } from "react";
import GalleryMultipageContext from "./GalleryMultipageContext";
import { randomStr } from "../../../utils/helper";

const GalleryMultipageState = (props) => {
  const [apiData, setApiData] = useState({ data: [], isLoading: false });
  const [parentData, setParentData] = useState([]);
  const [apiDataOurTeam, setApiDataOurTeam] = useState({
    isLoading: false,
    data: [],
  });
  const [isImageParent, setIsImageParent] = useState(false);
  const [isBackgroundColor, setIsBackgroundColor] = useState(false);
  const [collId, setCollId] = useState();
  const [skill, setSkill] = useState([
    { id: randomStr(2), skill: "", parentageOfSkill: "" },
  ]);
  const [fromData, setFormData] = useState({
    title: "",
    isTitleShow: true,
    path: "",
    image: "",
    webId: localStorage.getItem("webId"),
  });
  const [parentId, setParentId] = useState("");
  const [backImageToggle, setBackImageToggle] = useState(false);
  const [childId, setChildId] = useState("");
  const [formDataImageParent, setFormDataImageParent] = useState({
    title: "",
    subTitle: "",
    description: "",
    isAlbum: false,
    isTitleShow: true,
    theme: 1,
    navMapId: "",
    slugUrl: "",
    style: {
      ".photo-gallery-section": {
        padding: "100px 0",
        "background-color": "#ffffff",
      },
      ".photo-gallery-section.bg-image": {
        "background-image": "",
      },
      ".photo-gallery-section.bg-image::before": {
        "background-color": "",
      },
      ".photo-gallery-section .section-title-area": {
        "margin-bottom": "50px",
      },
      ".photo-gallery-section .section-title-area .section-subtitle": {
        "font-size": "16px",
        "font-weight": "600",
        color: "#ff497c",
        "background-color": "rgb(255 73 124 / 20%)",
        padding: "5px 20px",
        "border-radius": "15px",
        "margin-bottom": "15px",
      },
      ".photo-gallery-section .section-title-area .section-title": {
        "font-size": "44px",
        "font-weight": "600",
        color: "#000000",
      },
      ".photo-gallery-section .gallery-catlist": {
        "text-align": "center",
        "margin-bottom": "30px",
      },
      ".photo-gallery-section .gallery-catlist .btn": {
        border: "2px solid #ff497c",
        color: "#ff497c",
        padding: "6px 20px",
        margin: "5px",
        "box-shadow": "none",
      },
      ".photo-gallery-section .gallery-catlist .active": {
        "background-color": "#ff497c",
        color: "#ffffff",
      },
      ".photo-gallery-section .gallery-catdata .box": {
        "margin-bottom": "15px",
        outline: "none",
        "background-color": "#ffffff",
        "box-shadow": "0 5px 10px 0 #071C1A1A",
      },
      ".photo-gallery-section .gallery-catdata .gallery-img:before": {
        "font-family": "FontAwesome",
        color: "#ffffff",
        "font-size": "30px",
      },
      ".photo-gallery-section .gallery-catdata .box:hover .gallery-img:before":
        {
          opacity: "1",
        },
      ".photo-gallery-section .gallery-catdata .gallery-img .title-text": {
        "background-color": "#ff497c",
        "font-size": "16px",
        "font-weight": "600",
        color: "#ffffff",
        "text-align": "center",
        padding: "14px",
      },
      showItem: 4,
    },
    webId: localStorage.getItem("webId"),
  });

  const [settingFormData, setSettingFormData] = useState({
    ".photo-gallery-section": {
      padding: "100px 0",
      "background-color": "#ffffff",
    },
    image: "",
    ".photo-gallery-section.bg-image": {
      "background-image": "",
    },
    ".photo-gallery-section.bg-image::before": {
      "background-color": "",
    },
    ".photo-gallery-section .section-title-area": {
      "margin-bottom": "50px",
    },
    ".photo-gallery-section .section-title-area .section-subtitle": {
      "font-size": "16px",
      "font-weight": "600",
      color: "#ff497c",
      "background-color": "rgb(255 73 124 / 20%)",
      padding: "5px 20px",
      p1: "5px",
      p2: "20px",
      "border-radius": "15px",
      "margin-bottom": "15px",
    },
    ".photo-gallery-section .section-title-area .section-title": {
      "font-size": "44px",
      "font-weight": "600",
      color: "#000000",
    },
    ".photo-gallery-section .gallery-catlist": {
      "text-align": "center",
      "margin-bottom": "30px",
    },
    ".photo-gallery-section .gallery-catlist .btn": {
      border: "2px solid #ff497c",
      borderPx: "2px",
      borderThik: "solid",
      borderColor: "#ff497c",
      color: "#ff497c",
      padding: "6px 20px",
      p1: "6px",
      p2: "20px",
      margin: "5px",
      "box-shadow": "none",
    },
    ".photo-gallery-section .gallery-catlist .active": {
      "background-color": "#ff497c",
      color: "#ffffff",
    },
    ".photo-gallery-section .gallery-catdata .box": {
      "margin-bottom": "15px",
      outline: "none",
      "background-color": "#ffffff",
      "box-shadow": "0 5px 10px 0 #071C1A1A",
      bs1: "0px",
      bs2: "5px",
      bs3: "10px",
      bs4: "0px",
      shadowColor: "#071C1A1A",
    },
    ".photo-gallery-section .gallery-catdata .gallery-img:before": {
      "font-family": "FontAwesome",
      color: "#ffffff",
      "font-size": "30px",
    },
    ".photo-gallery-section .gallery-catdata .box:hover .gallery-img:before": {
      opacity: "1",
    },
    ".photo-gallery-section .gallery-catdata .gallery-img .title-text": {
      "background-color": "#ff497c",
      "font-size": "16px",
      "font-weight": "600",
      color: "#ffffff",
      "text-align": "center",
      padding: "14px",
    },
    ".photo-gallery-section .more-btn": {
      color: "#ffffff",
      "font-size": "16px",
      "font-weight": "normal",
      "border-radius": "15px",
      padding: "5px 20px",
      "background-color": "#ff497c",
    },
    ".photo-gallery-section .more-btn:hover": {
      "background-color": "#df2a5d",
      color: "#ffffff",
    },
    ".pagination-positions": {
      "justify-content": "center",
    },
    ".pagination .page-link": {
      color: "#ff497c",
    },
    ".pagination .page-item.active .page-link": {
      "background-color": "#ff497c",
      "border-color": "#ff497c",
    },
    showItem: 4,
    galleryImgId: "",
  });
  const [boxSection, setBoxSection] = useState(false);
  const [id, setId] = useState("");
  const [galleryImgId, setGalleryImgId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [filter, setFilter] = useState("");
  const [theme, setTheme] = useState();
  const [isChildForFormSubmit, setIsChildForFormSubmit] = useState(false);
  const [ids, setIds] = useState({});
  const [imgShow, setImgShow] = useState(false);
  const [sectionPageName, setSectionPageName] = useState("");
  return (
    <GalleryMultipageContext.Provider
      value={{
        apiData,
        setApiData,
        apiDataOurTeam,
        setApiDataOurTeam,
        fromData,
        setFormData,
        formDataImageParent,
        setFormDataImageParent,
        id,
        setId,
        isEdit,
        setIsEdit,
        filter,
        setFilter,
        skill,
        galleryImgId,
        setGalleryImgId,
        setSkill,
        settingFormData,
        setSettingFormData,
        formDataImageParent,
        setFormDataImageParent,
        parentData,
        setParentData,
        backImageToggle,
        setBackImageToggle,
        boxSection,
        setBoxSection,
        collId,
        setCollId,
        setParentId,
        parentId,
        theme,
        setTheme,
        isChildForFormSubmit,
        setIsChildForFormSubmit,
        isBackgroundColor,
        setIsBackgroundColor,
        isImageParent,
        setIsImageParent,
        ids,
        setIds,
        imgShow,
        setImgShow,
        childId,
        setChildId,
        sectionPageName,
        setSectionPageName,
      }}
    >
      {props.children}
    </GalleryMultipageContext.Provider>
  );
};

export default GalleryMultipageState;
