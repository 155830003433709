import React, { Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
const Footer = (props) => {
  return (
    <Fragment>
      {/* <footer className="footer" style={{ position: "sticky" }}> */}
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col className="footer-copyright text-center">
              <p className="mb-0">{"All Rights Reserved. © Copyright 2023 "}</p>
            </Col>
          </Row>
        </Container>
      </footer>
    </Fragment>
  );
};

export default Footer;
