import axios from "axios";
import {
  SOLUTION_DETAILS,
  SUPER_ADMIN_DESHBOARD,
} from "../../constant/API constant";

export const AdminDeshboard = () => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${SUPER_ADMIN_DESHBOARD}`, {
    method: "GET",
    headers: { Authorization: jwt_token },
  });
};

export const AdminSolutionDetails = () => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${SOLUTION_DETAILS}`, {
    method: "GET",
    headers: { Authorization: jwt_token },
  });
};
