import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Input, Label, Row } from "reactstrap";
import { maxLengthCheck, randomStr } from "../../../../utils/helper";
import SeoDetailsMultipageContext from "../../../context/SeoDetailsMultipageContext/SeoDetailsMultipageContext";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { DELETE_CONFIRM } from "../../../constant";
import { TostMessage } from "../../../utils";
import { delete_Multi_Image_Permanent } from "../apis/SliderApi/SliderApi";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import { getSocialMediaMaster } from "../apis/SocialMediaApi/SocialMediaApi";

const SeoDetailsPopup = (props) => {
  const { simpleValidator, imageUpload } = props;
  const { fromData, setFormData, isEdit, setIsSectionImage, setCustomeIndex } =
    useContext(SeoDetailsMultipageContext);
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const { setLoaderShow } = useContext(LoaderContext);
  const [socialMediaMaster, setSocialMediaMaster] = useState([]);
  const [imageUploadRatio, setImageUploadRatio] = useState({
    isHeader: false,
    isFooter: false,
  });

  /**
   * get social media master list
   */
  const getSocialMediaMasterApi = async () => {
    try {
      let paramObj = {
        showAll: true,
      };
      const { data } = await getSocialMediaMaster(paramObj);
      if (data.status === 200) {
        setSocialMediaMaster(data.payload.data);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  useEffect(() => {
    getSocialMediaMasterApi();
  }, []);

  /**
   * input value change
   * @param {event} e
   */
  const handlevalueChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleChangeHeaderSection = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        headerSection: {
          ...prev.headerSection,
          [name]: value,
        },
      };
    });
  };
  const handleChangeOgs = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        headerSection: {
          ...prev.headerSection,
          ogTags: {
            ...prev.headerSection.ogTags,
            [name]: value,
          },
        },
      };
    });
  };
  const handleChangeBody = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        body: {
          ...prev.body,
          [name]: value,
        },
      };
    });
  };
  const handleChangeFooter = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        footer: {
          ...prev.footer,
          [name]: value,
        },
      };
    });
  };
  const handleSocialMediaChange = (e, i) => {
    const { name, value, options, selectedIndex } = e.target;
    let old = [...fromData.footer.socialMedia];
    old[i][name] = value;
    if (name == "type") {
      old[i]["name"] = options[selectedIndex].text;
    }
    setFormData((prev) => {
      return {
        ...prev,
        footer: {
          ...prev.footer,
          socialMedia: old,
        },
      };
    });
  };
  /**
   * reupload image
   */
  const reupload = () => {
    setFormData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };

  const deleteImagePermanent = async (name) => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        // let formData = {
        //   fileName: fromData.imageLink
        //     .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
        //     .pop(),
        // };
        let formData;
        if (name === "footer" && name !== null) {
          formData = {
            fileName: (fromData["fileName"] = fromData.footer.image
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
              .pop()),
          };
        }
        const { data } = await delete_Multi_Image_Permanent(formData);
        if (data.status === 200) {
          TostMessage({ message: data, type: "success", position: "center" });
          setFormData({ ...fromData, image: "", imageLink: "" });
          if (name === "footer" && name !== null) {
            setFormData({
              ...fromData,
              image: "",
              footer: { ...fromData.footer, image: "" },
            });
          }
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const addMoreInput = () => {
    setFormData((prev) => {
      return {
        ...prev,
        footer: {
          ...prev.footer,
          socialMedia: [
            ...prev.footer.socialMedia,
            { name: "", value: "", id: randomStr(2) },
          ],
        },
      };
    });
  };
  const deleteMoreInput = (id) => {
    setFormData((prev) => {
      return {
        ...prev,
        footer: {
          ...prev.footer,
          socialMedia: prev.footer.socialMedia.filter((item) => item.id !== id),
        },
      };
    });
  };

  return (
    <Container>
      <Row>
        <Col md="12 mb-3">
          <Label htmlFor="validationCustom03">
            {"Company Name | City Name *"}
          </Label>
          <Input
            className="form-control"
            name="name"
            type="text"
            placeholder="Company Name | City Name *"
            min={0}
            maxLength={60}
            onInput={maxLengthCheck}
            value={fromData.name}
            onChange={(e) => handlevalueChange(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
          <div className="text-end mt-3 ">
            <span>{fromData?.name?.length} / 60</span>
          </div>
          <p style={{ color: "red" }}>
            {simpleValidator.current.message(
              "Company Name | City Name",
              fromData?.name,
              "required"
            )}
          </p>
        </Col>
        <Col md="12 mb-3">
          <Label htmlFor="validationCustom03">{"Description *"}</Label>
          <textarea
            className="form-control"
            name="description"
            type="text"
            placeholder="Description *"
            value={fromData.description}
            onChange={(e) => handlevalueChange(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
          <div className="text-end mt-3">
            <span>{fromData?.description?.length || 0}</span>
          </div>
          <p style={{ color: "red" }}>
            {simpleValidator.current.message(
              "Description",
              fromData?.description,
              "required"
            )}
          </p>
        </Col>
        <br />
        <h2 className="mb-3 d-flex justify-content-center py-2">
          Header Section
        </h2>
        <Row>
          <Col md="6 mb-3">
            <Label htmlFor="validationCustom03">{"keywords *"}</Label>
            <textarea
              className="form-control"
              name="keywords"
              type="text"
              placeholder="keywords"
              value={fromData.headerSection?.keywords}
              onChange={(e) => handleChangeHeaderSection(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <p style={{ color: "red" }}>
              {simpleValidator.current.message(
                "keywords",
                fromData?.headerSection?.keywords,
                "required"
              )}
            </p>
          </Col>
          <Col md="6 mb-3">
            <Label htmlFor="validationCustom03">{"Canonical"}</Label>
            <textarea
              className="form-control"
              name="canonical"
              type="text"
              placeholder="Canonical"
              value={fromData.headerSection?.canonical}
              onChange={(e) => handleChangeHeaderSection(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
          <Col md="6 mb-3">
            <Label htmlFor="validationCustom03">{"Bing"}</Label>
            <textarea
              className="form-control"
              name="Bing"
              type="text"
              placeholder="Bing"
              value={fromData.headerSection?.Bing}
              onChange={(e) => handleChangeHeaderSection(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
          <Col md="6 mb-3">
            <Label htmlFor="validationCustom03">
              {"Google Site Verification"}
            </Label>
            <textarea
              className="form-control"
              name="googleSiteVerification"
              type="text"
              placeholder="Google Site Verification"
              value={fromData.headerSection?.googleSiteVerification}
              onChange={(e) => handleChangeHeaderSection(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
          <Col md="6 mb-3">
            <Label htmlFor="validationCustom03">{"Web Site Schema"}</Label>
            <textarea
              className="form-control"
              name="webSiteSchema"
              type="text"
              placeholder="Web Site Schema"
              value={fromData.headerSection?.webSiteSchema}
              onChange={(e) => handleChangeHeaderSection(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
          <Col md="6 mb-3">
            <Label htmlFor="validationCustom03">{"Bread Crumb Schema"}</Label>
            <textarea
              className="form-control"
              name="breadCrumbSchema"
              type="text"
              placeholder="Bread Crumb Schema"
              value={fromData.headerSection?.breadCrumbSchema}
              onChange={(e) => handleChangeHeaderSection(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
          <Col md="6 mb-3">
            <Label htmlFor="validationCustom03">{"Facebook Pixel"}</Label>
            <textarea
              className="form-control"
              name="facebookPixel"
              type="text"
              placeholder="Facebook Pixel"
              value={fromData.headerSection?.facebookPixel}
              onChange={(e) => handleChangeHeaderSection(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
          <Col md="6 mb-3">
            <Label htmlFor="validationCustom03">{"Author"}</Label>
            <Input
              className="form-control"
              name="author"
              type="text"
              placeholder="Author"
              value={fromData.headerSection?.author}
              onChange={(e) => handleChangeHeaderSection(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
        </Row>
        <br />
        <h2 className="mb-3 d-flex justify-content-center  py-2">Og Tags</h2>
        <Row>
          <Col md="6 mb-3">
            <Label htmlFor="validationCustom03">{"Title *"}</Label>
            <Input
              className="form-control"
              name="title"
              type="text"
              placeholder="Title"
              value={fromData.headerSection?.ogTags?.title}
              onChange={(e) => handleChangeOgs(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <p style={{ color: "red" }}>
              {simpleValidator.current.message(
                "Title",
                fromData.headerSection?.ogTags?.title,
                "required"
              )}
            </p>
          </Col>
          <Col md="6 mb-3">
            <Label htmlFor="validationCustom03">{"Url *"}</Label>
            <Input
              className="form-control"
              name="url"
              type="text"
              placeholder="Url"
              value={fromData.headerSection?.ogTags?.url}
              onChange={(e) => handleChangeOgs(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <p style={{ color: "red" }}>
              {simpleValidator.current.message(
                "url",
                fromData.headerSection?.ogTags?.url,
                "required"
              )}
            </p>
          </Col>
          {/* <Col md="6 mb-3">
            <Label htmlFor="validationCustom03">{"Type"}</Label>
            <Input
              className="form-control"
              name="type"
              type="text"
              placeholder="Type"
              value={fromData.headerSection?.ogTags?.type}
              onChange={(e) => handleChangeOgs(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <p style={{ color: "red" }}>
              {simpleValidator.current.message(
                "Type",
                fromData.headerSection?.ogTags?.Type,
                "required"
              )}
            </p>
          </Col> */}
          <Col md="6 mb-3">
            <Label htmlFor="validationCustom03">{"Site Name"}</Label>
            <Input
              className="form-control"
              name="siteName"
              type="text"
              placeholder="Site Name"
              value={fromData.headerSection?.ogTags?.siteName}
              onChange={(e) => handleChangeOgs(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
          <Col md="6 mb-3">
            <Label htmlFor="validationCustom03">{"Description"}</Label>
            <textarea
              className="form-control"
              name="description"
              type="text"
              placeholder="description"
              value={fromData.headerSection?.ogTags?.description}
              onChange={(e) => handleChangeOgs(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
        </Row>
        <br />
        <h2 className="mb-3 d-flex justify-content-center py-2">Body</h2>
        <Row>
          <Col md="6 mb-3">
            <Label htmlFor="validationCustom03">{"Google Tag Manage"}</Label>
            <textarea
              className="form-control"
              name="googleTagManage"
              type="text"
              placeholder="Google Tag Manage"
              value={fromData.body?.googleTagManage}
              onChange={(e) => handleChangeBody(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
        </Row>
      </Row>
      <ProductImageUploadPopup
        allPopupState={allPopupState}
        setAllPopupState={setAllPopupState}
        crop={crop}
        setCrop={setCrop}
        completedCrop={completedCrop}
        setCompletedCrop={setCompletedCrop}
        scale={scale}
        rotate={rotate}
        image={fromData.image}
        setImage={setFormData}
        imageUpload={imageUpload}
        isEdit={isEdit}
        reupload={reupload}
        // aspect={1 / 1}
      />
    </Container>
  );
};

export default SeoDetailsPopup;
