import axios from "axios";
import {
  GET_WHATS_APP_LINK,
  GET_WHATS_APP_LINK_DETAILS,
  POST_WHATS_APP_LINK,
  PUT_STATUS_WHATS_APP_LINK,
  PUT_WHATS_APP_LINK_ORDER,
} from "../../apiConstants";

/**
 * get whats app link
 * @param {Parameters} paramObj
 * @returns
 */
export const getWhatsAppLink = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_WHATS_APP_LINK}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * enable-disable get whats app link
 * @param {body} body
 * @returns
 */
export const updateStatus = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_STATUS_WHATS_APP_LINK}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * create get whats app link
 * @param {body} body
 * @returns
 */
export const postGetWhatsAppLink = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${POST_WHATS_APP_LINK}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * update get whats app link
 * @param {body} body
 * @returns
 */
export const putUpdateGetWhatsAppLink = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${POST_WHATS_APP_LINK}`,
    body,
    {
      headers: { Authorization: jwt_token },
      params: { getLinkOnWhatsAppId: body.getLinkOnWhatsAppId },
    }
  );
};

/**
 * delete get whats app link
 * @param {id} getLinkOnWhatsAppId
 * @returns
 */
export const deleteGetWhatsAppLink = (getLinkOnWhatsAppId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(
    `${process.env.REACT_APP_API_URL}${POST_WHATS_APP_LINK}`,
    {
      headers: { Authorization: jwt_token },
      params: { getLinkOnWhatsAppId },
    }
  );
};

/**
 * get get whats app link
 * @param {id} getLinkOnWhatsAppId
 * @returns
 */
export const getGetWhatsAppLinkDetails = (getLinkOnWhatsAppId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${GET_WHATS_APP_LINK_DETAILS}`,
    {
      headers: { Authorization: jwt_token },
      params: { getLinkOnWhatsAppId },
    }
  );
};

/**
 * short order get whats app link
 * @param {body} updateOrder
 * @returns
 */
export const sort_get_whatsapp_link = (updateOrder) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_WHATS_APP_LINK_ORDER}`,
    { updateOrder: updateOrder },
    {
      headers: { Authorization: jwt_token },
    }
  );
};
