import React, { useState } from "react";
import DownloadsContext from "./DownloadsContext";

const DownloadsState = (props) => {
  const [apiData, setApiData] = useState({ data: [], isLoading: false });

  const [fromData, setFormData] = useState({
    title: "",
    filePath: "",
    webId: localStorage.getItem("webId"),
  });
  const [id, setId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [filter, setFilter] = useState("");
  return (
    <DownloadsContext.Provider
      value={{
        apiData,
        setApiData,
        fromData,
        setFormData,
        id,
        setId,
        isEdit,
        setIsEdit,
        filter,
        setFilter,
      }}
    >
      {props.children}
    </DownloadsContext.Provider>
  );
};

export default DownloadsState;
