import React from "react";
import { Switch, Tooltip } from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import EditIcon from "@mui/icons-material/Edit";
import { Trash } from "react-feather";
import { Truncate } from "../../utils/helper";
import { Settings } from "react-feather";

const ItemsDragAndDrops = (props) => {
  const {
    handleOnDragEnd,
    characters,
    handleChangeStatus,
    handleDelete,
    handleEdit,
    handleChangeSettings,
    status = true,
    isSetting = false,
  } = props;
  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <div style={{ height: "28rem", overflow: "hidden", overflowY: "auto" }}>
        <Droppable droppableId="characters">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {characters.map((elem, index) => {
                return (
                  <Draggable key={elem.id} draggableId={elem.id} index={index}>
                    {(provided) => (
                      <div
                        className="d-flex justify-content-between   align-items-center p-3 product-list-divs mb-2 "
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {elem?.image && (
                          <div
                            style={{
                              objectFit: "contain",
                              maxWidth: "47px",
                              height: "47px",
                              border: "1px solid #d3d3d3",
                              width: "100%",
                              display: "inline-block",
                              verticalAlign: "middle",
                              overflow: "hidden",
                              position: "relative",
                            }}
                          >
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                right: "0",
                                bottom: "0",
                                left: "0",
                                borderRadius: "inherit",
                              }}
                            >
                              <img
                                src={elem?.image}
                                alt=""
                                style={{
                                  objectPosition: "50% 50%",
                                  objectFit: "contain",
                                  opacity: "1",
                                  transition: "opacity 0.28s ease-in",
                                  width: "100%",
                                  height: "100%",
                                }}
                              />
                            </div>
                          </div>
                        )}
                        <div>
                          <div>
                            <h6>
                              {elem?.name ? Truncate(elem?.name, 15) : "-"}
                            </h6>
                          </div>
                          <div>
                            <p>{elem?.des ? Truncate(elem?.des, 21) : ""}</p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          {status && (
                            <Switch
                              checked={
                                elem?.isActive === true || elem?.isActive === 1
                              }
                              inputProps={{
                                "aria-label": "controlled",
                              }}
                              color="primary"
                              onChange={() =>
                                handleChangeStatus(
                                  elem?.Id,
                                  elem?.isActive,
                                  true,
                                  elem?.parentId
                                )
                              }
                            />
                          )}
                          <Tooltip title="Edit">
                            <EditIcon
                              style={{ color: "#3f51b5", cursor: "pointer" }}
                              onClick={() => handleEdit(elem?.Id, false)}
                            />
                          </Tooltip>
                          <Tooltip title="Delete">
                            <Trash
                              style={{ color: "#3f51b5", cursor: "pointer" }}
                              onClick={() => handleDelete(elem?.Id)}
                            />
                          </Tooltip>
                          {isSetting && (
                            <Tooltip title="Setting">
                              <Settings
                                style={{ color: "#3f51b5", cursor: "pointer" }}
                                onClick={() => handleChangeSettings(elem?.Id)}
                              />
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );
};

export default ItemsDragAndDrops;
