import React, { useContext } from "react";
import { Col, Input, Label, Row } from "reactstrap";
import { TextAlign, fontWeight } from "../../../../constant";
import ServiceAndCategoryContext from "../../../context/ServiceAndCatagoryContext/ServiceAndCategoryContext";
import { Cases, DELETE_CONFIRM } from "../../../constant";
import { delete_Multi_Image_Permanent } from "../apis/SliderApi/SliderApi";
import { Zoom, toast } from "react-toastify";
import Switch from "@material-ui/core/Switch";
import { Close } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { UploadCloud } from "react-feather";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import Dividers from "../../../common/divider";
import { OpenModalCommonFunc } from "../../../utils";
import ServiceCategoryDetailStyle from "./ServiceCategoryDetailStyle";
import PaginationButtonStyle from "../../../common/paginationButtonStyle";
import CommonColor from "../../../common/CommonColor";
import CustomStyle from "../../../common/CustomStyle";

const ServiceThemeFoure = (props) => {
  const {
    isEdit,
    settingFormData,
    setSettingFormData,
    backImageToggle,
    setBackImageToggle,
    boxSection,
    setBoxSection,
    setIsImageUpload,
    isBackgroundColor,
    setIsBackgroundColor,
  } = useContext(ServiceAndCategoryContext);
  const { setLoaderShow } = useContext(LoaderContext);
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);

  const { imageUpload } = props;

  const handleChangeVision2ServiceSection = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".vision2-section": {
          ...prev[".vision2-section"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeVision2SectionTitleArea = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".vision2-section .section-title-area": {
          ...prev[".vision2-section .section-title-area"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeVision2SectionTitleAreaSubtitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".vision2-section .section-title-area .section-subtitle": {
          ...prev[".vision2-section .section-title-area .section-subtitle"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeVision2SectionTitleAreaSectionTitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".vision2-section .section-title-area .section-title": {
          ...prev[".vision2-section .section-title-area .section-title"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeVision2SectionSectionDiv2 = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".vision2-section .visiondiv2": {
          ...prev[".vision2-section .visiondiv2"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeVision2visionDiv2Hover = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".vision2-section .visiondiv2:hover": {
          ...prev[".vision2-section .visiondiv2:hover"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeVision2SectionDiv2Img = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".vision2-section .visiondiv2 img": {
          ...prev[".vision2-section .visiondiv2 img"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeVision2SectionVisionDiv2H4 = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".vision2-section .visiondiv2 h4": {
          ...prev[".vision2-section .visiondiv2 h4"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeVision2SectionVisionDiv2P = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".vision2-section .visiondiv2 p": {
          ...prev[".vision2-section .visiondiv2 p"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeCustomStyle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  /**
   * delete image permanently
   */
  const deleteImagePermanent = async () => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData = {
          fileName: settingFormData?.[".vision2-section.bg-image"]?.[
            "background-image"
          ]
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
            .pop(),
        };
        const { data } = await delete_Multi_Image_Permanent(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            position: "top-center",
          });
          setSettingFormData({
            ...settingFormData,
            ".vision2-section.bg-image": { "background-image": "" },
          });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };
  /**
   * image reupload
   */
  const reupload = () => {
    setSettingFormData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };
  return (
    <>
      <Row className="mt-4">
        <h4 className="mb-3">Make change in main Service section</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (default : 100)"}
          </Label>
          <Input
            className="form-control"
            name="padding"
            type="number"
            placeholder="Padding"
            value={settingFormData?.[".vision2-section"]?.["padding"]
              ?.split(" ")[0]
              ?.replace(/px/g, "")}
            onChange={(e) => handleChangeVision2ServiceSection(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <div className="d-flex align-items-center">
            <Label htmlFor="validationCustom03">
              {isBackgroundColor
                ? "Add Background Color"
                : "Remove Background Color"}
            </Label>
            <Switch
              checked={isBackgroundColor}
              inputProps={{ "aria-label": "controlled" }}
              onChange={(e) => {
                setIsBackgroundColor(e.target.checked);
                setSettingFormData((prev) => {
                  if (e.target.checked) {
                    if (boxSection) {
                      return {
                        ...prev,
                        ".vision2-section": {
                          "background-color": {
                            r: 255,
                            g: 255,
                            b: 255,
                            a: 100,
                          },
                          padding:
                            settingFormData?.[".vision2-section"]?.["padding"],
                          width: settingFormData?.[".vision2-section"]?.[
                            "width"
                          ]?.replace("rem)", ""),
                          margin: settingFormData?.[".vision2-section"]?.[
                            "margin"
                          ]
                            ?.split(" ")[0]
                            ?.replace(/px/g, ""),
                          "border-radius": settingFormData?.[
                            ".vision2-section"
                          ]?.["border-radius"]?.replace(/rem/g, ""),
                        },
                      };
                    } else {
                      return {
                        ...prev,
                        ".vision2-section": {
                          "background-color": {
                            r: 255,
                            g: 255,
                            b: 255,
                            a: 100,
                          },
                          padding:
                            settingFormData?.[".vision2-section"]?.["padding"],
                        },
                      };
                    }
                  } else {
                    if (boxSection) {
                      return {
                        ...prev,
                        ".vision2-section": {
                          padding:
                            settingFormData?.[".vision2-section"]?.["padding"],
                          width: settingFormData?.[".vision2-section"]?.[
                            "width"
                          ]?.replace("rem)", ""),
                          margin: settingFormData?.[".vision2-section"]?.[
                            "margin"
                          ]
                            ?.split(" ")[0]
                            ?.replace(/px/g, ""),
                          "border-radius": settingFormData?.[
                            ".vision2-section"
                          ]?.["border-radius"]?.replace(/rem/g, ""),
                        },
                      };
                    } else {
                      return {
                        ...prev,
                        ".vision2-section": {
                          padding:
                            settingFormData?.[".vision2-section"]?.["padding"],
                        },
                      };
                    }
                  }
                });
              }}
            />
          </div>
          {isBackgroundColor && (
            <CommonColor
              color={
                settingFormData?.[".vision2-section"]?.["background-color"]
              }
              setSettingFormData={setSettingFormData}
              className={".vision2-section"}
              classProperty={"background-color"}
              label={"Select Background Color"}
            />
          )}
        </Col>
        <p>{"Box Section : convert your section to Box section"}</p>
        <div>
          <Switch
            checked={boxSection}
            inputProps={{ "aria-label": "controlled" }}
            onChange={(e) => {
              setBoxSection(e.target.checked);
              setSettingFormData((prev) => {
                return {
                  ...prev,
                  ".vision2-section": {
                    padding: settingFormData?.[".vision2-section"]?.["padding"]
                      ?.split(" ")[0]
                      ?.replace(/px/g, ""),
                    "background-color":
                      settingFormData?.[".vision2-section"]?.[
                        "background-color"
                      ],
                    width: e.target.checked ? "10" : "",
                    margin: e.target.checked ? "0" : "",
                    "border-radius": e.target.checked ? "3" : "",
                  },
                };
              });
            }}
          />
        </div>
        {boxSection && (
          <>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Width (Default : 10)"}
              </Label>
              <Input
                className="form-control"
                name="width"
                type="number"
                placeholder="width"
                value={settingFormData?.[".vision2-section"]?.[
                  "width"
                ]?.replace("rem)", "")}
                onChange={(e) => handleChangeVision2ServiceSection(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Margin (Default : 0)"}
              </Label>
              <Input
                className="form-control"
                name="margin"
                type="number"
                placeholder="margin"
                value={settingFormData?.[".vision2-section"]?.["margin"]
                  ?.split(" ")[0]
                  ?.replace(/px/g, "")}
                onChange={(e) => handleChangeVision2ServiceSection(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Border Radius (Default : 3)"}
              </Label>
              <Input
                className="form-control"
                name="border-radius"
                type="number"
                placeholder="Border Radius"
                value={settingFormData?.[".vision2-section"]?.[
                  "border-radius"
                ]?.replace(/rem/g, "")}
                onChange={(e) => handleChangeVision2ServiceSection(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
          </>
        )}
      </Row>
      <hr />
      <Row className="mt-4">
        <Col md="12 mb-3">
          <h4 className="mb-3">Add Background Image in section</h4>

          <p>do you want to upload Background image</p>
          <Switch
            checked={backImageToggle}
            inputProps={{ "aria-label": "controlled" }}
            onChange={(e) => {
              setBackImageToggle(e.target.checked);
              setSettingFormData((prev) => {
                if (e.target.checked) {
                  return {
                    ...prev,
                    image: "",
                    ".vision2-section.bg-image": {},
                    ".vision2-section.bg-image::before": {
                      "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
                    },
                  };
                } else {
                  return {
                    ...prev,
                    image: "",
                    ".vision2-section.bg-image": {},
                    ".vision2-section.bg-image::before": {},
                  };
                }
              });
            }}
          />
          {backImageToggle && (
            <>
              {" "}
              <Col md="6 mb-3">
                <Dividers divide="IMAGE" />
                <p className="mb-4">
                  File type required JPG, PNG (1905px X 986px)
                </p>
              </Col>
              <Row>
                <Col md="4 mb-3">
                  <div
                    className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    {!settingFormData?.[".vision2-section.bg-image"]?.[
                      "background-image"
                    ] && (
                      <span
                        onClick={() => {
                          OpenModalCommonFunc(
                            Cases.PRODUCT_UPLOAD,
                            setAllPopupState
                          );
                          setSettingFormData((prev) => {
                            return {
                              ...prev,
                              image:
                                settingFormData?.[
                                  ".vision2-section.bg-image"
                                ]?.["background-image"],
                            };
                          });
                          setIsImageUpload((prev) => {
                            return {
                              isList: true,
                              isDetails: false,
                            };
                          });
                        }}
                      >
                        <UploadCloud />
                      </span>
                    )}

                    {settingFormData?.[".vision2-section.bg-image"]?.[
                      "background-image"
                    ] && (
                      <img
                        src={
                          settingFormData?.[".vision2-section.bg-image"]?.[
                            "background-image"
                          ] || ""
                        }
                        alt=""
                        style={{
                          width: "85%",
                          height: "90px",
                          objectFit: "contain",
                          position: "absolute",
                        }}
                        onClick={() => {
                          deleteImagePermanent();
                        }}
                      />
                    )}
                    {settingFormData?.[".vision2-section.bg-image"]?.[
                      "background-image"
                    ] && (
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="close"
                        style={{
                          color: "red",
                          position: "absolute",
                          top: "-22px",
                          left: "1px",
                        }}
                        onClick={() => deleteImagePermanent()}
                      >
                        <Close
                          style={{
                            fontSize: "25px",
                            fontWeight: "bold",
                          }}
                        />
                      </IconButton>
                    )}
                  </div>
                </Col>
                <Col md="6 mb-3">
                  <h4 className="mb-3">Add Background color in section</h4>
                  <Col md="6 mb-3">
                    <CommonColor
                      color={
                        settingFormData?.[
                          ".vision2-section.bg-image::before"
                        ]?.["background-color"]
                      }
                      setSettingFormData={setSettingFormData}
                      className={".vision2-section.bg-image::before"}
                      classProperty={"background-color"}
                      label={"Select Background Color"}
                    />
                  </Col>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make space in Title bottom</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin Bottom (Default : 50)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin Bottom"
            value={settingFormData?.[".vision2-section .section-title-area"]?.[
              "margin-bottom"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeVision2SectionTitleArea(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in section subtitle</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 16)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".vision2-section .section-title-area .section-subtitle"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeVision2SectionTitleAreaSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[
                ".vision2-section .section-title-area .section-subtitle"
              ]?.["font-weight"]
            }
            onChange={(e) => handleChangeVision2SectionTitleAreaSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".vision2-section .section-title-area .section-subtitle"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".vision2-section .section-title-area .section-subtitle"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".vision2-section .section-title-area .section-subtitle"
              ]?.["background-color"]
            }
            setSettingFormData={setSettingFormData}
            className={".vision2-section .section-title-area .section-subtitle"}
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 5 - 20)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="p1"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".vision2-section .section-title-area .section-subtitle"
              ]?.["p1"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeVision2SectionTitleAreaSubtitle(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p2"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".vision2-section .section-title-area .section-subtitle"
              ]?.["p2"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeVision2SectionTitleAreaSubtitle(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border Radius (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[
              ".vision2-section .section-title-area .section-subtitle"
            ]?.["border-radius"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeVision2SectionTitleAreaSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin Bottom (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin Bottom"
            value={settingFormData?.[
              ".vision2-section .section-title-area .section-subtitle"
            ]?.["margin-bottom"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeVision2SectionTitleAreaSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in section Title</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 44)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".vision2-section .section-title-area .section-title"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeVision2SectionTitleAreaSectionTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[
                ".vision2-section .section-title-area .section-title"
              ]?.["font-weight"]
            }
            onChange={(e) => handleChangeVision2SectionTitleAreaSectionTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".vision2-section .section-title-area .section-title"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".vision2-section .section-title-area .section-title"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in section div</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Padding (Default : 25)"}</Label>
          <Input
            className="form-control"
            name="padding"
            type="number"
            placeholder="padding"
            value={settingFormData?.[".vision2-section .visiondiv2"]?.[
              "padding"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeVision2SectionSectionDiv2(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border Radius (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[".vision2-section .visiondiv2"]?.[
              "border-radius"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeVision2SectionSectionDiv2(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Text Align (Default : center)"}
          </Label>
          <select
            name="text-align"
            id="text-align"
            className="form-control"
            value={
              settingFormData?.[".vision2-section .visiondiv2"]?.["text-align"]
            }
            onChange={(e) => handleChangeVision2SectionSectionDiv2(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {TextAlign.map((elem) => {
              return (
                <option value={elem["text-align"]}>{elem["text-align"]}</option>
              );
            })}
          </select>
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in section div at hover</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Transform (Default : -8)"}
          </Label>
          <Input
            className="form-control"
            name="transform"
            type="number"
            placeholder="transform"
            value={settingFormData?.[".vision2-section .visiondiv2:hover"]?.[
              "transform"
            ]?.replace("px)", "")}
            onChange={(e) => handleChangeVision2visionDiv2Hover(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in section div image</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin Bottom (Default : 20)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin Bottom"
            value={settingFormData?.[".vision2-section .visiondiv2 img"]?.[
              "margin-bottom"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeVision2SectionDiv2Img(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Max Width (Default : 70)"}
          </Label>
          <Input
            className="form-control"
            name="max-width"
            type="number"
            placeholder="Max Width"
            value={settingFormData?.[".vision2-section .visiondiv2 img"]?.[
              "max-width"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeVision2SectionDiv2Img(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in section div heading</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".vision2-section .visiondiv2 h4"]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".vision2-section .visiondiv2 h4"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[".vision2-section .visiondiv2 h4"]?.[
                "font-weight"
              ]
            }
            onChange={(e) => handleChangeVision2SectionVisionDiv2H4(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 24)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[".vision2-section .visiondiv2 h4"]?.[
              "font-size"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeVision2SectionVisionDiv2H4(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin Bottom (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin Bottom"
            value={settingFormData?.[".vision2-section .visiondiv2 h4"]?.[
              "margin-bottom"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeVision2SectionVisionDiv2H4(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in section div paragraph</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".vision2-section .visiondiv2 p"]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".vision2-section .visiondiv2 p"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 300)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[".vision2-section .visiondiv2 p"]?.[
                "font-weight"
              ]
            }
            onChange={(e) => handleChangeVision2SectionVisionDiv2P(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 16)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[".vision2-section .visiondiv2 p"]?.[
              "font-size"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeVision2SectionVisionDiv2P(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Line Height (Default : 1.8)"}
          </Label>
          <Input
            className="form-control"
            name="line-height"
            type="number"
            placeholder="Line Height"
            value={
              settingFormData?.[".vision2-section .visiondiv2 p"]?.[
                "line-height"
              ]
            }
            onChange={(e) => handleChangeVision2SectionVisionDiv2P(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <ServiceCategoryDetailStyle />
      <PaginationButtonStyle
        mainClass={".vision2-section"}
        settingFormData={settingFormData}
        setSettingFormData={setSettingFormData}
      />
      <CustomStyle
        handleChange={handleChangeCustomStyle}
        value={settingFormData?.custom}
      />
      <ProductImageUploadPopup
        allPopupState={allPopupState}
        setAllPopupState={setAllPopupState}
        crop={crop}
        setCrop={setCrop}
        completedCrop={completedCrop}
        setCompletedCrop={setCompletedCrop}
        scale={scale}
        rotate={rotate}
        image={settingFormData?.image}
        setImage={setSettingFormData}
        imageUpload={imageUpload}
        isEdit={isEdit}
        reupload={reupload}
        // aspect={1.93 / 1}
      />
    </>
  );
};

export default ServiceThemeFoure;
