import React, { useState } from "react";
import SocialMediaMultipageContext from "./SocialMediaMultipageContext";
import { randomStr } from "../../../utils/helper";

const SocialMediaMultipageState = (props) => {
  const [apiData, setApiData] = useState({ data: [], isLoading: false });
  const [apiDataOurTeam, setApiDataOurTeam] = useState({
    isLoading: false,
    data: [],
  });
  const [isBackgroundColor, setIsBackgroundColor] = useState(false);
  const [skill, setSkill] = useState([
    { id: randomStr(2), skill: "", parentageOfSkill: "" },
  ]);
  const [collId, setCollId] = useState();
  const [fromData, setFormData] = useState({
    label: "",
    contentMapId: "",
    image: "",
    webId: localStorage.getItem("webId"),
    subTitle: "",
    theme: 1,
    style: {
      ".custom-footer": {
        "background-color": "rgb(255 255 255 / 100)",
      },
      ".custom-footer .foot-social": {
        padding: "70px 0",
        "background-color": "rgb(31 39 50 / 100)",
      },
      ".custom-footer .foot-social:before": {
        "background-image": "",
      },
      ".custom-footer .foot-social:after": {
        "background-image": "",
      },
      ".custom-footer .foot-social .social_buttons a": {
        "background-color": "rgb(18 119 217 / 0.2)",
        color: "rgb(255 255 255 / 100)",
      },
    },
  });

  const [formDataChild, setFormDataChild] = useState({
    label: "",
    value: "",
    itemId: "",
    image: "",
    type: "",
    dialCode: { value: "+91", label: "+91" },
    webId: localStorage.getItem("webId"),
  });

  const [settingFormData, setSettingFormData] = useState({
    ".custom-footer": {
      "background-color": "rgb(255 255 255 / 100)",
    },
    // ".custom-footer .corner.corner-inverse:before, .custom-footer .corner.corner-inverse:after":
    //   {
    //     "border-bottom": "50vw solid transparent",
    //     borderBtPx: "50vw",
    //     borderBtThik: "solid",
    //     borderBtColor: "transparent",
    //     "border-left": "50vw solid #fff",
    //     borderLfPx: "50vw",
    //     borderLfThik: "solid",
    //     borderLfColor: "#fff",
    //     "border-right": "50vw solid #fff",
    //     borderRiPx: "50vw",
    //     borderRiThik: "solid",
    //     borderRiColor: "#fff",
    //   },
    // ".custom-footer .foot-contact": {
    //   "background-color": "#f4f4f5",
    //   padding: "100px 0",
    //   "text-align": "center",
    // },
    // ".custom-footer .foot-contact .icons": {
    //   "background-color": "#fff",
    //   border: "5px solid #ff497c",
    //   borderPx: "5px",
    //   borderThik: "solid",
    //   borderColor: "#ff497c",
    //   "border-radius": "50%",
    //   padding: "30px",
    //   height: "120px",
    //   width: "120px",
    //   "margin-bottom": "20px",
    // },
    // ".custom-footer .foot-contact h6": {
    //   color: "#1f2732",
    //   "font-size": "22px",
    //   "font-weight": "600",
    //   "margin-bottom": "10px",
    // },
    // ".custom-footer .foot-contact p": {
    //   color: "#1f2732",
    //   "font-size": "16px",
    //   "font-weight": "500",
    // },
    ".custom-footer .foot-social": {
      padding: "70px 0",
      "background-color": "rgb(31 39 50 / 100)",
    },
    ".custom-footer .foot-social:before": {
      "background-image": "url(../images/texture_dark.jpg)",
    },
    ".custom-footer .foot-social:after": {
      "background-image": "url(../images/texture_dark.jpg)",
    },
    ".custom-footer .foot-social .social_buttons a": {
      "background-color": "rgb(18 119 217 / 0.2)",
      color: "rgb(255 255 255 / 100)",
    },
    ".custom-footer .foot-social-2 a": {
      width: "42px",
      height: "42px",
      border: "1px solid #ffffff",
      "border-radius": "100%",
      display: "flex",
      "justify-content": "center",
      "align-items": "center",
    },
    ".custom-footer .foot-social-2 a i": {
      "font-size": "18px",
      color: "#ffffff",
      "line-height": "0px",
    },
    // ".custom-footer .foot-copright": {
    //   "background-color": "#ff497c",
    //   padding: "15px 0",
    // },
    // ".custom-footer .foot-copright p": {
    //   color: "#ffffff",
    //   "font-size": "14px",
    //   "font-weight": "500",
    // },
    // ".custom-footer .foot-copright a": {
    //   "text-decoration": "underline",
    //   color: "#ffffff",
    // },
    socialMediaId: "",
  });

  const [backImg, setBackImg] = useState();
  const [id, setId] = useState("");
  const [childId, setChildId] = useState("");
  const [navMapId, setNavMapId] = useState("");
  const [galleryImgId, setGalleryImgId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isParent, setIsParent] = useState(false);
  const [filter, setFilter] = useState("");
  const [theme, setTheme] = useState(false);
  const [imgShow, setImgShow] = useState(false);
  const [sectionPageName, setSectionPageName] = useState("");
  return (
    <SocialMediaMultipageContext.Provider
      value={{
        apiData,
        setApiData,
        apiDataOurTeam,
        setApiDataOurTeam,
        fromData,
        setFormData,
        id,
        setId,
        isEdit,
        setIsEdit,
        filter,
        setFilter,
        skill,
        galleryImgId,
        setGalleryImgId,
        setSkill,
        settingFormData,
        setSettingFormData,
        formDataChild,
        setFormDataChild,
        isParent,
        setIsParent,
        childId,
        setChildId,
        navMapId,
        setNavMapId,
        backImg,
        setBackImg,
        collId,
        setCollId,
        isBackgroundColor,
        setIsBackgroundColor,
        theme,
        setTheme,
        imgShow,
        setImgShow,
        sectionPageName,
        setSectionPageName,
      }}
    >
      {props.children}
    </SocialMediaMultipageContext.Provider>
  );
};

export default SocialMediaMultipageState;
