import { IconButton } from "@mui/material";
import React from "react";
import { Copy, Share2 } from "react-feather";
import { Alert } from "reactstrap";
import copy from "copy-to-clipboard";
import { TostMessage } from "../../../utils";

const Header = ({ data }) => {
  const copyButton = (domain) => {
    copy(domain);
    TostMessage({
      message: "copy domain",
      type: "success",
      position: "custome",
    });
  };
  return (
    <Alert
      color=" dark"
      style={{
        backgroundColor: "#e3f6fd",
        borderLeft: "3px solid #00abec",
      }}
      className="mb-5"
    >
      <div
        className="d-flex justify-content-between flex-wrap "
        style={{ padding: "0px 21px 0px 10px " }}
      >
        <div>
          <a
            href={`https://${data?.domain}`}
            className="text-blue"
            target="_black"
          >
            {` https://${data?.domain}`}
          </a>
          <p>Your Website is ready to share with your customers</p>
        </div>
        <div className="d-flex justify-content-center  align-items-center gap-2">
          {/* <div>
            <IconButton aria-label="share">
              <Share2
                style={{
                  width: "20px",
                  height: "20px",
                  position: "inherit",
                  top: "0",
                }}
                className="text-blue"
              />
            </IconButton>
          </div> */}
          <div>
            <IconButton
              aria-label="copy"
              onClick={() => copyButton(data?.domain)}
            >
              <Copy
                style={{
                  width: "20px",
                  height: "20px",
                  position: "inherit",
                  top: "0",
                }}
                className="text-blue"
              />
            </IconButton>
          </div>
        </div>
      </div>
    </Alert>
  );
};

export default Header;
