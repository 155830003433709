import React, { useState } from "react";
import PopupDialogContext from "./PopupDialogContext";
import { randomStr } from "../../../utils/helper";

const PopupDialogState = (props) => {
  const [apiData, setApiData] = useState({ data: [], isLoading: false });
  const [apiDataOurTeam, setApiDataOurTeam] = useState({
    isLoading: false,
    data: [],
  });
  const [skill, setSkill] = useState([
    { id: randomStr(2), skill: "", parentageOfSkill: "" },
  ]);
  const [collId, setCollId] = useState();
  const [backImageToggle, setBackImageToggle] = useState(false);
  const [fromData, setFormData] = useState({
    title: "",
    navMapId: "",
    webId: localStorage.getItem("webId"),
    subTitle: "",
    style: {
      ".popup .modal-content": {
        "background-color": "rgb(255 255 255 / 100)",
      },
      ".popup .modal-header": {
        "justify-content": "space-between",
      },
      ".popup .modal-title": {
        color: "rgb(0 0 0 / 100)",
        "font-size": "20px",
      },
      ".popup .modal-body": {
        padding: "1rem",
      },
      ".popup .modal-btn": {
        "font-size": "16px",
        "font-weight": "600",
        color: "rgb(255 255 255 / 100)",
        "background-color": "rgb(255 73 124 / 100)",
        padding: "5px 20px",
        p1: "5px",
        p2: "20px",
        "border-radius": "15px",
        "margin-bottom": "15px",
      },
      ".popup .modal-shortDescription": {
        "font-size": "16px",
        "font-weight": "600",
        color: "rgb(0 0 0 / 100)",
        "padding-top": "12px",
      },
      ".popup .modal-description": {
        "font-size": "16px",
        "font-weight": "600",
        color: "rgb(0 0 0 / 100)",
        "padding-top": "12px",
      },
    },
  });
  const [images, setImages] = useState([
    { path: "", isDefault: true },
    { path: "", isDefault: false },
    { path: "", isDefault: false },
    { path: "", isDefault: false },
  ]);
  const [formDataChild, setFormDataChild] = useState({
    title: "",
    subTitle: "",
    buttonText: "",
    link: "",
    shortDescription: "",
    description: "",
    image: "",
    style: {
      ".popup .modal-content": {
        "background-color": "rgb(255 255 255 / 100)",
      },
      ".popup .modal-header": {
        "justify-content": "space-between",
      },
      ".popup .modal-title": {
        color: "rgb(0 0 0 / 100)",
        "font-size": "20px",
      },
      ".popup .modal-body": {
        padding: "1rem",
      },
      ".popup .modal-btn": {
        "font-size": "16px",
        "font-weight": "600",
        color: "rgb(255 255 255 / 100)",
        "background-color": "rgb(255 73 124 / 100)",
        padding: "5px 20px",
        p1: "5px",
        p2: "20px",
        "border-radius": "15px",
        "margin-bottom": "15px",
      },
      ".popup .modal-shortDescription": {
        "font-size": "16px",
        "font-weight": "600",
        color: "rgb(0 0 0 / 100)",
        "padding-top": "12px",
      },
      ".popup .modal-description": {
        "font-size": "16px",
        "font-weight": "600",
        color: "rgb(0 0 0 / 100)",
        "padding-top": "12px",
      },
    },
    webId: localStorage.getItem("webId"),
  });
  const [index, setIndex] = useState("");
  const [settingFormData, setSettingFormData] = useState({
    ".popup .modal-content": {
      "background-color": "rgb(255 255 255 / 100)",
    },
    ".popup .modal-header": {
      "justify-content": "space-between",
    },
    ".popup .modal-title": {
      color: "rgb(0 0 0 / 100)",
      "font-size": "20px",
    },
    ".popup .modal-body": {
      padding: "1rem",
    },
    ".popup .modal-btn": {
      "font-size": "16px",
      "font-weight": "600",
      color: "rgb(255 255 255 / 100)",
      "background-color": "rgb(255 73 124 / 100)",
      padding: "5px 20px",
      p1: "5px",
      p2: "20px",
      "border-radius": "15px",
      "margin-bottom": "15px",
    },
    ".popup .modal-shortDescription": {
      "font-size": "16px",
      "font-weight": "600",
      color: "rgb(0 0 0 / 100)",
      "padding-top": "12px",
    },
    ".popup .modal-description": {
      "font-size": "16px",
      "font-weight": "600",
      color: "rgb(0 0 0 / 100)",
      "padding-top": "12px",
    },
  });
  const [boxSection, setBoxSection] = useState(false);
  const [id, setId] = useState("");
  const [childId, setChildId] = useState("");
  const [navMapId, setNavMapId] = useState("");
  const [galleryImgId, setGalleryImgId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isParent, setIsParent] = useState(false);
  const [filter, setFilter] = useState("");
  const [theme, setTheme] = useState(false);
  const [imgShow, setImgShow] = useState(false);
  return (
    <PopupDialogContext.Provider
      value={{
        apiData,
        setApiData,
        apiDataOurTeam,
        setApiDataOurTeam,
        fromData,
        setFormData,
        id,
        setId,
        isEdit,
        setIsEdit,
        filter,
        setFilter,
        skill,
        galleryImgId,
        setGalleryImgId,
        setSkill,
        settingFormData,
        setSettingFormData,
        formDataChild,
        setFormDataChild,
        isParent,
        setIsParent,
        childId,
        setChildId,
        navMapId,
        setNavMapId,
        boxSection,
        setBoxSection,
        collId,
        setCollId,
        images,
        setImages,
        index,
        setIndex,
        backImageToggle,
        setBackImageToggle,
        theme,
        setTheme,
        imgShow,
        setImgShow,
      }}
    >
      {props.children}
    </PopupDialogContext.Provider>
  );
};

export default PopupDialogState;
