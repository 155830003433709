import React, { useContext } from "react";
import { TostMessage } from "../../../utils";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import ItemsDragAndDrops from "../../../common/ItemsDragAndDrops";
import {  randomStr } from "../../../../utils/helper";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { sort_faqs } from "../apis/FaqsApi/FaqsApi";
import Switch from "react-js-switch";
import { IconButton } from "@material-ui/core";
import { Trash2 } from "react-feather";
import {
  CUSTOM_FILED_TYPE,
  CUSTOM_FILED_TYPE_CHECK,
} from "../../../constant";
import CustomFormContext from "../../../context/CustomFormContext/CustomFormContext";

const CustomFormPopup = (props) => {
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const {
    handleChangeStatus,
    imageUpload,
    emptyData,
    handleDelete,
    handleEdit,
    getCustomFormChildList,
  } = props;
  const {
    formDataChild,
    setFormDataChild,
    setFilter,
    filter,
    isEdit,
    setApiDataOurTeam: setApiDataImageParent,
    apiDataOurTeam: apiDataImageParent,
  } = useContext(CustomFormContext);

  /**
   * drag and drop
   * @param {drag data} result
   * @returns
   */
  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;
    const items = Array.from(apiDataImageParent.data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setApiDataImageParent({ ...formDataChild, data: items });
    try {
      let itemOrder = [];
      for (const item in items) {
        itemOrder.push({ faqsId: items[item].Id, order: +item + 1 });
      }
      const { data } = await sort_faqs(itemOrder);
      if (data.status === 200) {
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * create new latent news
   */
  const hanldenewCreate = () => {
    emptyData();
  };

  /**
   * reupload image
   */
  const reupload = () => {
    setFormDataChild((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };

  /**
   * input value change
   * @param {event} e
   */
  const InputValueChange = (e) => {
    const { value, name, checked } = e.target;
    setFormDataChild((prev) => {
      if (name === "isTitleShow") {
        return {
          ...prev,
          [name]: checked,
        };
      } else {
        return {
          ...prev,
          [name]: value,
        };
      }
    });
  };

  const addMoreInput = () => {
    setFormDataChild((prev) => {
      return {
        ...prev,
        options: [
          ...prev?.options,
          {
            id: randomStr(2),
            name: "",
            order: "",
          },
        ],
      };
    });
  };
  const descriptionHandler = (e, i) => {
    const { name, value } = e.target;
    let old = [...formDataChild.options];
    old[i][name] = value;
    setFormDataChild((prev) => {
      return {
        ...prev,
        options: old,
      };
    });
  };
  const deleteMoreInput = (id) => {
    setFormDataChild((prev) => {
      return {
        ...prev,
        options: prev?.options.filter((item) => item.id !== id),
      };
    });
  };
  console.log("formDataChild >> ", formDataChild);

  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="6">
            <div>
              <h6>Total Count : {apiDataImageParent?.data?.length}</h6>
              {/* <p className="pt-2">You can add upto 25 Images</p> */}
              <Row>
                <Col md="6">
                  <Input
                    className="form-control mt-3 "
                    name="search"
                    type="text"
                    placeholder="search"
                    style={{ borderRadius: "0", color: "black" }}
                    value={filter}
                    onChange={(e) => {
                      getCustomFormChildList({ filter: e.target.value });
                      setFilter(e.target.value);
                    }}
                  />
                </Col>

                <Col
                  md="6"
                  className="d-flex justify-content-center align-items-center mt-3"
                >
                  <Button
                    color="primary"
                    className="rounded-0"
                    onClick={() => hanldenewCreate()}
                  >
                    Add New
                  </Button>
                </Col>
              </Row>
              {apiDataImageParent.isLoading ? (
                <div className="mt-4">
                  <h6>Loading ...</h6>
                </div>
              ) : (
                <div className="mt-4">
                  <ItemsDragAndDrops
                    handleOnDragEnd={handleOnDragEnd}
                    characters={apiDataImageParent?.data}
                    handleChangeStatus={handleChangeStatus}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                  />
                </div>
              )}
            </div>
          </Col>
          <Col sm="12" xl="6">
            <div className="mt-4 d-flex flex-column justify-content-center ">
              <h6>{isEdit ? "Edit" : " Add New"}</h6>
              <Row className="mt-4">
                <Col md="12 mb-1">
                  <Label htmlFor="validationCustom03">Name</Label>
                  <Input
                    className="form-control"
                    name="name"
                    type="text"
                    placeholder="name"
                    value={formDataChild?.name}
                    onChange={(e) => InputValueChange(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                  {/* <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "title",
                      formDataChild.title,
                      "required"
                    )}
                  </p> */}
                </Col>
                <Col md="12 mb-1">
                  <Label htmlFor="validationCustom03">{"Order"}</Label>
                  <Input
                    className="form-control"
                    name="order"
                    type="number"
                    placeholder="order"
                    value={formDataChild?.order}
                    onChange={(e) => InputValueChange(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Type"}</Label>
                  <select
                    name="type"
                    id="type"
                    className="form-select"
                    onChange={(e) => InputValueChange(e)}
                    value={formDataChild?.type}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    {CUSTOM_FILED_TYPE.map((ele) => {
                      return (
                        <option value={ele.value} key={ele.value}>
                          {ele.value}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                {CUSTOM_FILED_TYPE_CHECK.includes(formDataChild?.type) && (
                  <>
                    {formDataChild.options?.map((ele, i) => {
                      return (
                        <Row>
                          <Col md="5 mb-3">
                            <Label htmlFor="validationCustom03">
                              {"Name "}
                            </Label>
                            <Input
                              className="form-control"
                              name="name"
                              type="text"
                              placeholder="name"
                              value={ele.name}
                              onChange={(e) => descriptionHandler(e, i)}
                              style={{ borderRadius: "0", color: "black" }}
                            />
                          </Col>
                          <Col md="5 mb-3">
                            <Label htmlFor="validationCustom03">
                              {"Order "}
                            </Label>
                            <Input
                              className="form-control"
                              name="order"
                              type="text"
                              placeholder="order"
                              value={ele.order}
                              onChange={(e) => descriptionHandler(e, i)}
                              style={{ borderRadius: "0", color: "black" }}
                            />
                          </Col>
                          {formDataChild.options.length >= 2 && (
                            <Col md="2 mb-3">
                              <IconButton
                                edge="start"
                                color="inherit"
                                style={{ marginLeft: "5px", color: "red" }}
                                onClick={() => deleteMoreInput(ele.id)}
                              >
                                <Trash2 />
                              </IconButton>
                            </Col>
                          )}
                        </Row>
                      );
                    })}
                    <div className="d-flex justify-content-center ">
                      <Button onClick={() => addMoreInput()} color="primary">
                        + Add More
                      </Button>
                    </div>
                  </>
                )}

                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"isRequired"}</Label>
                  <Switch
                    value={formDataChild?.isRequired}
                    onChange={() =>
                      setFormDataChild((prev) => {
                        return {
                          ...prev,
                          isRequired: prev?.isRequired ? false : true,
                        };
                      })
                    }
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <ProductImageUploadPopup
          allPopupState={allPopupState}
          setAllPopupState={setAllPopupState}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
          image={formDataChild.image}
          setImage={setFormDataChild}
          imageUpload={imageUpload}
          isEdit={isEdit}
          reupload={reupload}
          // aspect={1.41/1}
        />
      </Container>
    </>
  );
};

export default CustomFormPopup;
