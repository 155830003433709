import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Zoom, toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import { TostMessage } from "../../components ByteCard/utils";
import { DELETE_CONFIRM } from "../../components ByteCard/constant";
import { FILE_TYPE, SORTING_ORDER } from "../../constant";
import TopLoadingBar from "../../utils/topLoadingBar";
import Breadcrumbs from "../../layout/breadcrumb";
import { FILE_API } from "../../api/Organization Api/OrganizationApi";
import FontListItem from "./FontListItem";
import FontListAddPopup from "./FontListAddPopup";
import FontListContext from "../../context/FontListContext/FontListContext";
import {
  Add_FontList,
  Delete_FontList,
  Edit_FontList,
  FontList_Enable_Disable,
  FontList_List,
} from "../../api/FontListApi/FontListApi";

const FontList = () => {
  const {
    setFormValue,
    formData,
    pageSize,
    sortOrder,
    setSortOrder,
    page,
    sortBy,
    setSortBy,
    setPageLimit,
    setRecord,
    isSwitchOn,
    setIsSwitchOn,
    addModel,
    setFormData,
    setAddModel,
    isEdit,
    setIsEdit,
    id,
    setId,
    progress,
    setProgress,
  } = useContext(FontListContext);
  const { setLoaderShow } = useContext(LoaderContext);
  const { tabelAnimation, setTableAnimation } = useContext(LoaderContext);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();

  const getCloneCategoryList = async (desabledStatusLoader) => {
    if (
      sortOrder === "ASC" ||
      sortOrder === "DSC" ||
      desabledStatusLoader === false
    ) {
    } else {
      setTableAnimation(!tabelAnimation);
    }
    try {
      let paramObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
      };
      const { data } = await FontList_List(paramObj);

      if (data.status === 200) {
        setFormValue(data.payload.data);
        setPageLimit(data.payload.pageLimit);
        setRecord(data.pager.totalRecords);

        setTableAnimation(false);
      } else {
      }
    } catch (error) {
      setTableAnimation(false);
    }
  };

  useEffect(() => {
    getCloneCategoryList();
  }, [page, pageSize, sortBy, sortOrder]);

  /**
   * Enable and Desable Category
   */
  const switch_onChange_handle = async (fontId, isActive) => {
    let formData = {
      fontId: fontId,
      isActive: isActive ? false : true,
      // type: TYPES.LANDING_PAGE,
    };
    try {
      const { data } = await FontList_Enable_Disable(formData, fontId);
      if (data.status === 200) {
        await setIsSwitchOn(!isSwitchOn);
        const desabledStatusLoader = false;
        await getCloneCategoryList(desabledStatusLoader);
        isActive
          ? toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            })
          : toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            });
      } else {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * Sorting category Table
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder(SORTING_ORDER.ASC);
    }
    if (sortOrder === SORTING_ORDER.ASC) {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder(SORTING_ORDER.DSC);
    }
    if (sortOrder === SORTING_ORDER.DSC) {
      setSortBy();
      setSortOrder("");
    }
  };

  const ImageUploadImages = async (file, name) => {
    let formValue = new FormData();
    formValue.append("type", FILE_TYPE.CLONE_CATEGORY_MINI);
    formValue.append("file", file);
    setLoaderShow(true);
    try {
      const { data } = await FILE_API(formValue);
      const setData = [...formData.allItem];
      const index = formData.imageNo;
      setData[index]["image"] = data.payload.baseUrl + data.payload.imageUrl;
      if (data.status === 200) {
        setFormData((prev) => {
          return {
            ...prev,
            allItem: setData,
            image: "",
          };
        });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        if (isEdit && id >= -1) {
          let addFromData = {
            ...formData,
          };
          const { data } = await Edit_FontList(addFromData, id);
          if (data.status === 200) {
            getCloneCategoryList();
            emptyData();
            TostMessage({ message: data, type: "success" });
          }
        } else {
          let addFromData = {
            ...formData,
          };
          const { data } = await Add_FontList(addFromData);
          if (data.status === 200) {
            getCloneCategoryList();
            emptyData();
            TostMessage({ message: data, type: "success" });
          }
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  /**
   * delete category
   * @param {id} fontId
   */
  const handleDelete = async (fontId) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await Delete_FontList(fontId);
        if (data.status === 200) {
          getCloneCategoryList();
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const emptyData = () => {
    setFormData({
      title: "",
      fontFamily: "",
      googleFontScript: "",
    });
    setAddModel(false);
    setIsEdit(false);
    setId("");
  };
  return (
    <Fragment>
      <Breadcrumbs parent="Font List" />
      <TopLoadingBar progress={progress} setProgress={setProgress} />
      {addModel && (
        <FontListAddPopup
          handleSubmit={handleSubmit}
          simpleValidator={simpleValidator}
          emptyData={emptyData}
          imageUpload={ImageUploadImages}
        />
      )}

      <FontListItem
        sorting={sorting}
        switch_onChange_handle={switch_onChange_handle}
        handleDelete={handleDelete}
      />
    </Fragment>
  );
};

export default FontList;
