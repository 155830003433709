import React, { useContext } from "react";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import ItemsDragAndDrops from "../../../common/ItemsDragAndDrops";
import { maxLengthCheck, randomStr } from "../../../../utils/helper";
import { IconButton } from "@material-ui/core";
import { Cases, Currency, DELETE_CONFIRM } from "../../../constant";
import { UploadCloud } from "react-feather";
import {
  delete_Image_Permanent,
  delete_Multi_Image_Permanent,
} from "../apis/SliderApi/SliderApi";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import { Close } from "@material-ui/icons";
import PackagesContext from "../../../context/PackagesContext/PackagesContext";
import { sort_order_packages } from "../apis/PackagesApi/PackagesApi";
import Select from "react-select";
import { Trash2 } from "react-feather";
import IsShowTitleORNot from "../../../common/isShowTitleORNot";

const PackagesPopup = (props) => {
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const {
    handleChangeStatus,
    imageUpload,
    emptyData,
    simpleValidator,
    handleDelete,
    handleEdit,
    getPackagesParentList,
  } = props;
  const {
    formDataChild,
    setFormDataChild,
    setFilter,
    filter,
    isEdit,
    setApiDataOurTeam: setApiDataImageParent,
    apiDataOurTeam: apiDataImageParent,
    storeIndex,
    setStoreIndex,
  } = useContext(PackagesContext);
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * drag and drop
   * @param {drag data} result
   * @returns
   */
  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;
    const items = Array.from(apiDataImageParent.data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setApiDataImageParent({ ...formDataChild, data: items });
    try {
      let itemOrder = [];
      for (const item in items) {
        itemOrder.push({ packageId: items[item].Id, order: +item + 1 });
      }
      const { data } = await sort_order_packages(itemOrder);
      if (data.status === 200) {
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * create new latent news
   */
  const hanldenewCreate = () => {
    emptyData();
  };

  /**
   * reupload image
   */
  const reupload = () => {
    setFormDataChild((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };

  /**
   * input value change
   * @param {event} e
   */
  const InputValueChange = (e) => {
    const { value, name, checked } = e.target;
    setFormDataChild((prev) => {
      if (name === "isTitleShow") {
        return {
          ...prev,
          [name]: checked,
        };
      } else {
        return {
          ...prev,
          [name]: value,
        };
      }
    });
  };

  const deleteImagePermanent = async (imgName) => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData = {
          fileName: formDataChild.featureObject[`${imgName}`].icon
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
            .pop(),
        };
        const { data } = await delete_Multi_Image_Permanent(formData);
        if (data.status === 200) {
          TostMessage({ message: data, type: "success", position: "center" });
          setFormDataChild({
            ...formDataChild,
            image: "",
            featureObject: formDataChild.featureObject.map((elem, i) => {
              if (i === imgName) {
                return {
                  ...elem,
                  icon: "",
                };
              } else {
                return elem;
              }
            }),
          });

          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const changeCurrency = (name, e) => {
    setFormDataChild((prev) => {
      return {
        ...prev,
        [e.name]: name,
      };
    });
  };

  const addMoreInput = () => {
    setFormDataChild((prev) => {
      return {
        ...prev,
        featureObject: [
          ...prev?.featureObject,
          {
            id: randomStr(2),
            icon: "",
            description: "",
          },
        ],
      };
    });
  };
  const deleteMoreInput = (id) => {
    setFormDataChild((prev) => {
      return {
        ...prev,
        featureObject: prev?.featureObject.filter((item) => item.id !== id),
      };
    });
  };

  const descriptionHandler = (e, i) => {
    const { name, value } = e.target;
    let old = [...formDataChild.featureObject];
    old[i][name] = value;
    setFormDataChild((prev) => {
      return {
        ...prev,
        featureObject: old,
      };
    });
  };

  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="6">
            <div>
              <h6>Total Count : {apiDataImageParent?.data?.length}</h6>
              {/* <p className="pt-2">You can add upto 25 Images</p> */}
              <Row>
                <Col md="6">
                  <Input
                    className="form-control mt-3 "
                    name="search"
                    type="text"
                    placeholder="search"
                    style={{ borderRadius: "0", color: "black" }}
                    value={filter}
                    onChange={(e) => {
                      getPackagesParentList({ filter: e.target.value });
                      setFilter(e.target.value);
                    }}
                  />
                </Col>

                <Col
                  md="6"
                  className="d-flex justify-content-center align-items-center mt-3"
                >
                  <Button
                    color="primary"
                    className="rounded-0"
                    onClick={() => hanldenewCreate()}
                  >
                    Add New
                  </Button>
                </Col>
              </Row>
              {apiDataImageParent.isLoading ? (
                <div className="mt-4">
                  <h6>Loading ...</h6>
                </div>
              ) : (
                <div className="mt-4">
                  <ItemsDragAndDrops
                    handleOnDragEnd={handleOnDragEnd}
                    characters={apiDataImageParent?.data}
                    handleChangeStatus={handleChangeStatus}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                  />
                </div>
              )}
            </div>
          </Col>
          <Col sm="12" xl="6">
            <div className="mt-4 d-flex flex-column justify-content-center ">
              <h6>{isEdit ? "Edit" : " Add New"}</h6>
              <Row className="mt-4">
                <Col md="12 mb-1">
                  <Label htmlFor="validationCustom03">
                    Title *{" "}
                    <IsShowTitleORNot
                      checked={formDataChild?.isTitleShow}
                      onChange={InputValueChange}
                    />
                  </Label>
                  <Input
                    className="form-control"
                    name="title"
                    type="text"
                    placeholder="Title *"
                    value={formDataChild.title}
                    onChange={(e) => InputValueChange(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    min={0}
                    maxLength={50}
                    onInput={maxLengthCheck}
                  />
                  <div className="text-end mt-3 ">
                    <span>{formDataChild.title.length} / 50</span>
                  </div>
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "title",
                      formDataChild.title,
                      "required"
                    )}
                  </p>
                </Col>
                <Col md="12 mb-1">
                  <Label htmlFor="validationCustom03">{"Sub Title"}</Label>
                  <Input
                    className="form-control"
                    name="subTitle"
                    type="text"
                    placeholder="Sub Title"
                    value={formDataChild.subTitle}
                    onChange={(e) => InputValueChange(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                  {/* <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "subTitle",
                      formDataChild.subTitle,
                      "required"
                    )}
                  </p> */}
                </Col>
                <Col md="6 mb-3">
                  <Label htmlFor="validationCustom03">{"Currency"}</Label>
                  <Select
                    options={Currency}
                    name="valueText"
                    value={formDataChild.valueText}
                    onChange={(prev, e) => changeCurrency(prev, e)}
                  />
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "Currency",
                      formDataChild.valueText,
                      "required"
                    )}
                  </p>
                </Col>

                <Col md="6 mb-1">
                  <Label htmlFor="validationCustom03">{"Plan Duration"}</Label>
                  <Input
                    className="form-control"
                    name="planDuration"
                    type="text"
                    placeholder="Plan Duration"
                    value={formDataChild.planDuration}
                    onChange={(e) => InputValueChange(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                  {/* <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "planDuration",
                      formDataChild.planDuration,
                      "required"
                    )}
                  </p> */}
                </Col>
                <Col md="6 mb-1">
                  <Label htmlFor="validationCustom03">{"Value"}</Label>
                  <Input
                    className="form-control"
                    name="value"
                    type="text"
                    placeholder="Value"
                    value={formDataChild.value}
                    onChange={(e) => InputValueChange(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "value",
                      formDataChild.value,
                      "required"
                    )}
                  </p>
                </Col>
                <Col md="6 mb-1">
                  <Label htmlFor="validationCustom03">{"Button Text"}</Label>
                  <Input
                    className="form-control"
                    name="buttonText"
                    type="text"
                    placeholder="Button Text"
                    value={formDataChild.buttonText}
                    onChange={(e) => InputValueChange(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="6 mb-1">
                  <Label htmlFor="validationCustom03">{"Button Value"}</Label>
                  <Input
                    className="form-control"
                    name="buttonValue"
                    type="text"
                    placeholder="Button Value"
                    value={formDataChild.buttonValue}
                    onChange={(e) => InputValueChange(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              {formDataChild.featureObject.map((ele, i) => {
                return (
                  <>
                    <Row className="pt-1">
                      <Col md="4 mb-3">
                        <div
                          className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                          style={{ cursor: "pointer" }}
                        >
                          {!ele.icon && (
                            <span
                              onClick={() => {
                                OpenModalCommonFunc(
                                  Cases.PRODUCT_UPLOAD,
                                  setAllPopupState
                                );
                                setStoreIndex(i);
                              }}
                            >
                              <UploadCloud />
                            </span>
                          )}

                          {ele.icon && (
                            <img
                              src={`${process.env.REACT_APP_BUCKET_URL}/${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/${ele.icon}`}
                              alt=""
                              style={{
                                width: "80%",
                                height: "98%",
                                objectFit: "contain",
                                position: "absolute",
                              }}
                              onClick={() => {
                                deleteImagePermanent(i);
                              }}
                            />
                          )}
                          {ele.icon && (
                            <>
                              {" "}
                              <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="close"
                                style={{
                                  color: "red",
                                  position: "absolute",
                                  top: "-22px",
                                  left: "1px",
                                }}
                                onClick={() => deleteImagePermanent()}
                              >
                                <Close
                                  style={{
                                    fontSize: "25px",
                                    fontWeight: "bold",
                                  }}
                                />
                              </IconButton>
                            </>
                          )}
                        </div>
                      </Col>
                      <Col md="6 mb-3">
                        <Label htmlFor="validationCustom03">
                          {"Title Feature "}
                        </Label>
                        <Input
                          className="form-control"
                          name="description"
                          type="text"
                          placeholder="Title Feature"
                          value={ele.description}
                          onChange={(e) => descriptionHandler(e, i)}
                          style={{ borderRadius: "0", color: "black" }}
                        />
                      </Col>
                      {formDataChild.featureObject.length >= 2 && (
                        <Col md="2 mb-3">
                          <IconButton
                            edge="start"
                            color="inherit"
                            style={{ marginLeft: "5px", color: "red" }}
                            onClick={() => deleteMoreInput(ele.id)}
                          >
                            <Trash2 />
                          </IconButton>
                        </Col>
                      )}
                    </Row>
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "Title Feature",
                        formDataChild.featureObject[i].description,
                        "required"
                      )}
                    </p>
                  </>
                );
              })}

              <div className="d-flex justify-content-center ">
                <Button onClick={() => addMoreInput()} color="primary">
                  + Add More
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <ProductImageUploadPopup
          allPopupState={allPopupState}
          setAllPopupState={setAllPopupState}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
          image={formDataChild.image}
          setImage={setFormDataChild}
          imageUpload={imageUpload}
          isEdit={isEdit}
          reupload={reupload}
          // aspect={1.41/1}
        />
      </Container>
    </>
  );
};

export default PackagesPopup;
