import React, { useContext, useMemo, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import { Cases, btnName, modalsName } from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import Lightbox from "react-image-lightbox";
import CommonPopup from "../../../Modals/commonPopup";
import TestimonialPopup from "./testimonialPopup";
import Testimonialcontext from "../../../context/TestimonialContext/TestimonialContext";
import {
  getTestimonial,
  getTestimonialDetails,
  postTestimonial,
  putUpdateTestimonial,
  updateStatus,
} from "../../../apis/TestimonialApi/TestimonialApi";
import { FILE_UPLOAD_API } from "../../../apis/SliderApi/SliderApi";
import { FILE_TYPE, titleUpdateKey } from "../../../../constant";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import SimpleReactValidator from "simple-react-validator";

const Testimonial = (props) => {
  const { webMasterDetail, navbarData, getData } = props;
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const {
    apiData: testimonialData,
    setApiData,
    fromData,
    setFormData,
    id,
    setId,
    setIsEdit,
    isEdit,
    filter,
  } = useContext(Testimonialcontext);
  const { setLoaderShow } = useContext(LoaderContext);
  const [open, setOpen] = useState(false);

  /**
   * get testimonial list
   */
  const getTestimonialList = async (status) => {
    try {
      if (!filter.length && status) {
        setApiData((prev) => {
          return { ...prev, isLoading: true };
        });
      }
      let paramObj = {
        search: { title: filter },
        showAll: true,
      };
      const { data } = await getTestimonial(paramObj);
      if (data.status === 200) {
        setApiData({
          ...testimonialData,
          isLoading: false,
          data: data.payload.data.map((elem) => {
            return {
              id: elem.title + elem.testimonialId,
              Id: elem.testimonialId,
              name: elem.title,
              image: elem.image,
              webId: elem.webId,
              companyName: elem.companyName,
              designation: elem.designation,
              des: elem.description,
              rating: elem.rating,
              order: elem.order,
              createdAt: elem.createdAt,
              isActive: elem.isActive,
            };
          }),
        });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  useMemo(() => {
    if (allPopupState?.testimonialPopup) {
      getTestimonialList();
    }
  }, [filter, allPopupState?.testimonialPopup]);

  /**
   * enable disable api call
   * @param {testimonial id} testimonialId
   * @param {status} isActive
   */

  const handleChangeStatus = async (testimonialId, isActive) => {
    try {
      let body = {
        testimonialId,
        isActive: isActive ? false : true,
        navMapId: navbarData[0].navMapId,
      };
      const { data } = await updateStatus(body);
      if (data.status === 200) {
        getTestimonialList(false);
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * upload image
   * @param {file} file
   */
  const imageUpload = async (file) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.BYTE_SLIDER);
    formData.append("file", file);
    setLoaderShow(true);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        setFormData((prev) => {
          return {
            ...prev,
            image: data.payload.baseUrl + data.payload.imageUrl,
          };
        });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * slider create and update
   */
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      if (id >= -1 && isEdit) {
        setLoaderShow(true);
        let newData = { ...fromData, navMapId: navbarData[0].navMapId };
        if (fromData.image.length) {
          setFormData((prev) => {
            return {
              ...prev,
              image: prev.image
                .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
                .pop(),
            };
          });
          newData["image"] = fromData.image
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
            .pop();
        }
        try {
          const { data } = await putUpdateTestimonial(newData);
          if (data.status === 200) {
            getTestimonialList();
            emptyData();
            TostMessage({ message: data, type: "success" });
            setLoaderShow(false);
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      } else {
        try {
          setLoaderShow(true);
          let newData = {
            ...fromData,
            order: testimonialData.data.length + 1,
            navMapId: navbarData[0].navMapId,
          };
          if (fromData.image.length) {
            setFormData((prev) => {
              return {
                ...prev,
                image: prev.image
                  .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
                  .pop(),
                order: testimonialData.data.length + 1,
              };
            });
            newData["image"] = fromData.image
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
              .pop();
          }
          const { data } = await postTestimonial(newData);
          if (data.status === 200) {
            getTestimonialList();
            emptyData();
            TostMessage({ message: data, type: "success" });
            setLoaderShow(false);
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      }
    }
  };

  /**
   * get slider details
   * @param {id} testimonialId
   */
  const handleEdit = async (testimonialId) => {
    try {
      setLoaderShow(true);
      setId(testimonialId);
      const { data } = await getTestimonialDetails(testimonialId);
      if (data.status === 200) {
        let payload = data.payload.data;
        setFormData({
          title: payload.title,
          description: payload.description,
          image: payload.image,
          companyName: payload.companyName,
          testimonialId: testimonialId,
          webId: localStorage.getItem("webId"),
        });
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * data clear
   */
  const emptyData = () => {
    setId("");
    setIsEdit(false);
    setFormData({
      title: "",
      description: "",
      companyName: "",
      image: "",
      webId: localStorage.getItem("webId"),
    });
    setLoaderShow(false);
  };

  const filterValue = () => {
    let [value] = navbarData.filter(
      (elem) => elem.navbarDetail.label === titleUpdateKey.Testimonials
    );
    return value;
  };

  const value = useMemo(() => filterValue(), [navbarData]);
  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody className="support-ticket-font box-border-div">
            <Row>
              <div className="col-12">
                <CardBuilderHeaderSection
                  name={value?.label || "Testimonial"}
                />
              </div>
              <OpenPopupBtn
                openPopup={() =>
                  OpenModalCommonFunc(Cases.TESTIMONIAL, setAllPopupState)
                }
                name={btnName.TESTIMONIAL}
              />
            </Row>
          </CardBody>
        </Card>
      </Col>

      {open && (
        <Lightbox
          mainSrc={
            "https://api.bytecard.in/images/uploads/w-200/84ce7bf0be6b3e9d6cfe48f02038aa2e.jpg"
          }
          onCloseRequest={() => setOpen(false)}
        />
      )}
      <CommonPopup isMini={true}
        open={allPopupState.testimonialPopup}
        close={() =>
          OpenModalCommonFunc(Cases.TESTIMONIAL, setAllPopupState, false)
        }
        modalName={modalsName.TESTIMONIAL}
        modalCase={Cases.TESTIMONIAL}
        handleSubmit={handleSubmit}
        emptyData={emptyData}
        inputData={fromData}
        edit={true}
        oldTitle={titleUpdateKey.isTestimonial}
        label={titleUpdateKey.Testimonials}
        getData={getData}
        content={
          <TestimonialPopup
            handleChangeStatus={handleChangeStatus}
            getData={getTestimonialList}
            handleEdit={handleEdit}
            imageUpload={imageUpload}
            emptyData={emptyData}
            simpleValidator={simpleValidator}
            webMasterDetail={webMasterDetail}
          />
        }
      />
    </>
  );
};

export default Testimonial;
