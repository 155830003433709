import React, { useState } from "react";
import Multipage_ByteMiniWeb_Dashboard_Context from "./Multipage_ByteMiniWeb_Dashboard_Context";

const MultipageByteMiniWebDashboardState = (props) => {
  const [apiData, setApiData] = useState({ data: [], isLoading: false });
  return (
    <Multipage_ByteMiniWeb_Dashboard_Context.Provider
      value={{ apiData, setApiData }}
    >
      {props.children}
    </Multipage_ByteMiniWeb_Dashboard_Context.Provider>
  );
};

export default MultipageByteMiniWebDashboardState;
