import axios from "axios";
import {
  GET_SOCIAL_MEDIA,
  GET_SOCIAL_MEDIA_DETAILS,
  GET_SOCIAL_MEDIA_MASTER,
  POST_SOCIAL_MEDIA,
  PUT_SOCIAL_MEDIA_ORDER,
  PUT_STATUS_SOCIAL_MEDIA,
} from "../../apiConstants";

/**
 * get social media data
 * @param {Parameters} paramObj
 * @returns
 */
export const getSocialMedia = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_SOCIAL_MEDIA}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};
/**
 * get social media master data
 * @param {Parameters} paramObj
 * @returns
 */
export const getSocialMediaMaster = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${GET_SOCIAL_MEDIA_MASTER}`,
    {
      headers: { Authorization: jwt_token },
      params: paramObj,
    }
  );
};

/**
 * enable-disable
 * @param {body} body
 * @returns
 */
export const updateStatus = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_STATUS_SOCIAL_MEDIA}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * create social media
 * @param {body} body
 * @returns
 */
export const postSocialMedia = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${POST_SOCIAL_MEDIA}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * update social media
 * @param {body} body
 * @returns
 */
export const putUpdateSocialMedia = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${POST_SOCIAL_MEDIA}`,
    body,
    {
      headers: { Authorization: jwt_token },
      params: { socialMediaId: body.socialMediaId },
    }
  );
};

/**
 * delete social media
 * @param {id} socialMediaId
 * @returns
 */
export const deleteSocialMedia = (socialMediaId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${POST_SOCIAL_MEDIA}`, {
    headers: { Authorization: jwt_token },
    params: { socialMediaId },
  });
};

/**
 * get social media details
 * @param {id} socialMediaId
 * @returns
 */
export const getSocialMediaDetails = (socialMediaId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${GET_SOCIAL_MEDIA_DETAILS}`,
    {
      headers: { Authorization: jwt_token },
      params: { socialMediaId },
    }
  );
};

/**
 * sort order social media
 * @param {body} updateOrder
 * @returns
 */
export const sort_order_social_media = (updateOrder) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_SOCIAL_MEDIA_ORDER}`,
    { updateOrder: updateOrder },
    {
      headers: { Authorization: jwt_token },
    }
  );
};
