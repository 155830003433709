import React, { Fragment, useContext, useEffect, useState } from "react";
import Breadcrumbs from "../../layout/breadcrumb";
import TopLoadingBar from "../../utils/topLoadingBar";
import PageItemContext from "../../context/PageItemContext/PageItmContext";
import PersonalPageAddmodal from "../PersonalPage/PersonalPageAddmodal";
import PersonalPageContext from "../../context/PersonalPageContext/PersonalPageContaxt";
import { toast, Zoom } from "react-toastify";
import {
  Delete_OrgPageItem,
  OrgPageItem_Enable_Desable,
  ShortOrderPageItem,
  Show_OrgPageItem,
} from "../../api/Organization Api/OrganizationApi";
import { PAGE_ITEM_TYPE } from "../../constant";
import { randomStr } from "../../utils/helper";
import PageItemContent from "./PageItemContent";
import LoaderContext from "../../context/LoaderContext/LoaderContext";

const AddPageItem = () => {
  const { formvalue, setformValue, modal, setModal, setTitle, nfcGet } =
    useContext(PageItemContext);
  const {
    setQpiId,
    seteditIndex,
    progress,
    setProgress,
    setChange,
    setLabel,
    setValue,
    setType,
    setLogo,
    setShareValue,
    setDialCode,
    setContactDetail,
    setIsGalleyCustome,
  } = useContext(PersonalPageContext);
  const { loaderShow, setLoaderShow } = useContext(LoaderContext);
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [feilds, setFilds] = useState([
    {
      id1: randomStr(5),
      name: "",
      url: "",
      discription: "",
    },
  ]);

  const [silderFeild, setSliderFilds] = useState([
    {
      id1: randomStr(5),
      name: "",
      url: "",
    },
  ]);

  /**
  useEffect to use of nfcGet() function call to and get function to PageItemContext and TopLoading call
   */

  useEffect(() => {
    nfcGet();
  }, []);

  useEffect(() => {
    setProgress(100);
  }, []);

  /**
   * click to add button ad open to popup to call openModel() functioon
   */

  const openModel = () => {
    setModal(true);
    setTitle(true);
  };

  /**
   * Edit Page Item
   */

  const EditHandle = async (qpiId) => {
    setQpiId(qpiId);
    seteditIndex(qpiId);
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Show_OrgPageItem(qpiId);
      if (data.status === 200) {
        seteditIndex(qpiId);
        setTitle(false);

        setChange(data.payload.data.itemId);
        setLabel(data.payload.data.label);
        let type = data.payload.data.itemMaser.type;
        if (
          type === PAGE_ITEM_TYPE.WHATSAPP ||
          type === PAGE_ITEM_TYPE.WHATSAPP_SOCIAL ||
          type === PAGE_ITEM_TYPE.CALL
        ) {
          let number = data.payload.data.value.split(" ");
          setValue(number.length ? number[1] : "");
          setDialCode(number.length ? number[0] : "+91");
        } else if (
          type === PAGE_ITEM_TYPE.GALLAYRY ||
          type === PAGE_ITEM_TYPE.VIDEO
        ) {
          let parsImg = JSON.parse(data.payload.data.value);
          setValue(parsImg);
          setFilds(parsImg);
          if (data.payload.data.icon) setIsGalleyCustome(true);
        } else if (type === PAGE_ITEM_TYPE.SLIDER) {
          let parsImg = JSON.parse(data.payload.data.value);
          setValue(parsImg);
          setSliderFilds(parsImg);
        } else if (type === PAGE_ITEM_TYPE.SAVE_CONTACT) {
          let parsImg = JSON.parse(data.payload.data.value);
          setContactDetail({
            name: parsImg.name,
            companyName: parsImg.companyName,
            designation: parsImg.designation,
            mobile: parsImg.mobile,
            email: parsImg.email,
            location: parsImg.location,
            webUrl: parsImg.webUrl,
            street: parsImg.street,
            city: parsImg.city,
            state: parsImg.state,
            country: parsImg.country,
            mobile1: parsImg?.mobile1?.split(" ")?.pop(),
            mobile2: parsImg?.mobile2?.split(" ")?.pop(),
            dialCode1: parsImg?.mobile1?.split(" ")?.shift(),
            dialCode2: parsImg?.mobile2?.split(" ")?.shift(),
            mobile3: parsImg?.mobile3,
          });
        } else {
          setValue(data.payload.data.value);
        }
        setType(data.payload.data.itemMaser.type);
        setLogo(data.payload.data.icon);
        setShareValue(data.payload.data.value);
        setLoaderShow(false);
        setModal(true);
      } else {
      }
    } catch (error) {
      toast.error(error.response, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };

  /**
   * Table Row Drag and Drop finctionality to call handleDragEnd() funcation call
   */

  const handleDragEnd = async (results) => {
    let tempUser = [...formvalue];
    let [selectdRow] = tempUser.splice(results.source.index, 1);
    tempUser.splice(results.destination.index, 0, selectdRow);
    setformValue(tempUser);
    try {
      let itemOrder = [];
      for (const item in tempUser) {
        itemOrder.push({ qpiId: tempUser[item].qpiId, sortOrder: +item + 1 });
      }
      const { data } = await ShortOrderPageItem(itemOrder);
      if (data.status === 200) {
        toast.success(data.response, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } catch (error) {
      toast.error(error.response, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * Enable desable page Item to use switch_onChange_handle() function call
   */

  const switch_onChange_handle = async (qpiId, isActive) => {
    let formData = {
      qpiId: qpiId,
      isActive: isActive ? false : true,
    };
    try {
      const { data } = await OrgPageItem_Enable_Desable(formData, qpiId);
      if (data.status === 200) {
        const enableLoader = false;
        await setIsSwitchOn(!isSwitchOn);
        await nfcGet(enableLoader);
        isActive
          ? toast.error(data.message, {
              autoClose: 1000,
              transition: Zoom,
            })
          : toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            });
      } else {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * Delete Page Item Handle
   */

  const DeleteHandle = async (qpiId) => {
    const result = window.confirm(
      "Are you sure you want to delete the broker page?"
    );
    if (result === true) {
      try {
        const { data } = await Delete_OrgPageItem(qpiId);
        if (data.status === 200) {
          await nfcGet();
          toast.success(data.message, {
            autoClose: 1000,
            transition: Zoom,
          });
        }
      } catch (error) {
        toast.error(error.response, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    }
  };

  return (
    <>
      <Fragment>
        <Breadcrumbs parent="Page Item" />
        <TopLoadingBar progress={progress} setProgress={setProgress} />
        <PageItemContent
          openModel={openModel}
          handleDragEnd={handleDragEnd}
          switch_onChange_handle={switch_onChange_handle}
          EditHandle={EditHandle}
          DeleteHandle={DeleteHandle}
        />
        {modal && (
          <PersonalPageAddmodal
            modal={modal}
            setModal={setModal}
            feilds={feilds}
            setFilds={setFilds}
            silderFeild={silderFeild}
            setSliderFilds={setSliderFilds}
            tabelData={formvalue}
          />
        )}
        {/* <PageItemViewPopup /> */}
      </Fragment>
    </>
  );
};

export default AddPageItem;
