import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Col } from "reactstrap";

const Editor = ({ value, onChange }) => {
  return (
    <Col md="12 mb-3">
      {/* <RichTextEditor value={value} onChange={onChange} /> */}
      {/* <CKEditor ed
      itor={ClassicEditor} data={value} onChange={onChange} /> */}
      <ReactQuill theme="snow" value={value} onChange={onChange} />
      {/* <SimpleMDE
        id="editor_container"
        onChange={onChange}
        value={value}
        options={{
          autofocus: true,
          spellChecker: false,
        }}
      /> */}
    </Col>
  );
};

export default Editor;
