import React, { useContext } from "react";
import { IconButton } from "@material-ui/core";
import { Cases, DELETE_CONFIRM, modalsName, options } from "../../../constant";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import Dividers from "../../../common/divider";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import { ContactUsAddressCart } from "../../../common/contactUSCart";
import {
  convertRGBA,
  maxLengthCheck,
  randomStr,
  removeUrlKeyInBGI,
  removeUrlKeyInPath,
} from "../../../../utils/helper";
import Select from "react-select";
import { Trash2 } from "react-feather";
import {
  deleteContactUs,
  getDetailsContactUs,
  putUpdateContactUs,
  updateStatus,
} from "../apis/ContactUsApi/ContactUsApi";
import AddNewButton from "../../../common/AddNewButton";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import Editor from "../../../common/editor";
import ContactUsMultipageContext from "../../../context/ContactUsMultipageContaxt/ContactUsMultipageContaxt";
import CommonPopup from "../../../Modals/commonPopup";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import SettingContactUsParentPopup from "./SettingContactUsParentPopup";
import IsShowTitleORNot from "../../../common/isShowTitleORNot";

const ContactUsPopup = (props) => {
  let {
    getData,
    handleEdit,
    emptyData,
    handleSubmit,
    simpleValidator,
    imageUploadImages,
    contactDetail,
  } = props;
  const {
    apiData,
    setId,
    id,
    fromData,
    setIsEdit,
    setSettingFormData,
    setFormData,
    addFields,
    setAddFields,
    setDefaultSet,
    setBackImageToggle,
    isEdit,
    setBoxSection,
    setIsBackgroundColor,
    submitStyleValue,
    setCountOfList,
  } = useContext(ContactUsMultipageContext);
  const { setLoaderShow } = useContext(LoaderContext);
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );

  /**
   * add & delete phone and email input
   * @param {condition name} name
   */
  const addMoreInput = (name) => {
    if (name === "phone") {
      setAddFields((prev) => {
        return {
          ...prev,
          phoneInput: [
            ...prev.phoneInput,
            {
              id: randomStr(2),
              phoneValue: "",
              dialCode: { value: "+91", label: "+91" },
            },
          ],
        };
      });
    } else if (name === "email") {
      setAddFields((prev) => {
        return {
          ...prev,
          emailInput: [
            ...prev.emailInput,
            { id: randomStr(2), emailValue: "" },
          ],
        };
      });
    }
  };

  const deleteMoreInput = (id, name) => {
    if (name === "phone") {
      setAddFields((prev) => {
        return {
          ...prev,
          phoneInput: prev.phoneInput.filter((item) => item.id !== id),
        };
      });
    } else if (name === "email") {
      setAddFields((prev) => {
        return {
          ...prev,
          emailInput: prev.emailInput.filter((item) => item.id !== id),
        };
      });
    }
  };

  /**
   * phone and email value change
   * @param {Event} e
   * @param {index} i
   * @param {input name condition name} inputName
   */
  const handleChangePhoneInput = (e, i, inputName) => {
    const { name, value } = e.target;
    if (inputName === "phone") {
      let old = [...addFields.phoneInput];
      old[i][name] = value;
      setAddFields({ ...addFields, phoneInput: old });
    } else if (inputName === "email") {
      let old = [...addFields.emailInput];
      old[i][name] = value;
      setAddFields({ ...addFields, emailInput: old });
    }
  };

  const SelectDrop = (name, e, i) => {
    let old = [...addFields.phoneInput];
    old[i][e.name] = name;
    setAddFields({ ...addFields, phoneInput: old });
  };

  const inputChange = (e) => {
    const { value, name, checked } = e.target;
    setFormData((prev) => {
      if (name === "isTitleShow") {
        return {
          ...prev,
          [name]: checked,
        };
      } else {
        return {
          ...prev,
          [name]: value,
        };
      }
    });
  };

  /**
   * delete slider
   * @param {Id} contactUsId
   */
  const handleDelete = async (contactUsId) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await deleteContactUs(contactUsId);
        if (data.status === 200) {
          getData();
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * default set value change
   * @param {} isPrimary
   * @param {id} contactUsId
   */
  const defaultStar = async (isPrimary, contactUsId) => {
    setDefaultSet(!isPrimary);
    // setIsEdit(true);
    await handleEdit(contactUsId);
    await handleSubmit({
      contactUsId: contactUsId,
      isPrimary: !isPrimary,
      defaultSetStar: "star",
      isEditStar: true,
    });
  };

  /**
   * enable disable api call
   * @param {contactUs id} contactUsId
   * @param {status} isActive
   */

  const handleChangeStatus = async (contactUsId, isActive) => {
    try {
      let body = {
        contactUsId,
        isActive: isActive ? false : true,
      };
      const { data } = await updateStatus(body);
      if (data.status === 200) {
        getData(false);
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * create and update images
   */
  const handleSubmitSetting = async (isRestore = false) => {
    if (id >= -1 && isEdit) {
      setLoaderShow(true);
      try {
        let newData;
        if (isRestore) {
          const isConfirm = window.confirm(
            "Are you sure want to restore style ?"
          );
          if (isConfirm) {
            newData = {
              styleRestore: true,
              contactUsId: id,
            };
          } else {
            setLoaderShow(false);
            return;
          }
        } else {
          newData = submitStyleValue();
          const keysToRemove = [
            "shadowColor",
            "borderPx",
            "borderThik",
            "borderColor",
            "borderTopPx",
            "borderTopThik",
            "borderTopColor",
            "p1",
            "p2",
            "bs1",
            "bs2",
            "bs3",
            "shadowColor",
            "image",
          ];

          function removeKeys(obj, keys) {
            for (const key in obj) {
              if (typeof obj[key] === "object") {
                removeKeys(obj[key], keys);
              } else if (keys.includes(key)) {
                delete obj[key];
              }
            }
          }
          removeKeys(newData, keysToRemove);
          newData = {
            style: newData,
            contactUsId: id,
          };
        }

        const { data } = await putUpdateContactUs(newData);
        if (data.status === 200) {
          OpenModalCommonFunc(
            Cases.SETTING_CONTACT_US_PARENT,
            setAllPopupState,
            false
          );
          emptyData();
          setLoaderShow(false);
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  /**
   * get setting data
   * @param {id} contactUsId
   */
  const handleChangeSettings = async (contactUsId) => {
    try {
      setLoaderShow(true);
      setId(contactUsId);
      const { data } = await getDetailsContactUs(contactUsId);
      if (data.status === 200) {
        let payload = data.payload.data;
        // countOfList = payload?.style?.countOfList;
        setCountOfList(payload?.style?.countOfList);
        if (
          payload.style?.[`.contact-section${payload?.style?.countOfList}`]?.[
            "width"
          ] ||
          payload.style?.[`.contact-section${payload?.style?.countOfList}`]?.[
            "margin"
          ] ||
          payload.style?.[`.contact-section${payload?.style?.countOfList}`]?.[
            "border-radius"
          ]
        ) {
          setBoxSection(true);
        }
        if (
          payload.style?.[
            `.contact-section${payload?.style?.countOfList}.bg-image`
          ]?.["background-image"]
        ) {
          setBackImageToggle(true);
        }
        if (
          payload.style?.[`.contact-section${payload?.style?.countOfList}`]?.[
            "background-color"
          ]
        ) {
          setIsBackgroundColor(true);
        }
        setSettingFormData((prev) => {
          return {
            contactUsId,
            countOfList: payload?.style?.countOfList,
            [`.contact-section${payload?.style?.countOfList}`]: {
              padding:
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList}`
                ]?.["padding"],
              "background-color": payload.style?.[
                `.contact-section${payload?.style?.countOfList}`
              ]?.["background-color"]
                ? convertRGBA({
                    rgbString:
                      payload.style?.[
                        `.contact-section${payload?.style?.countOfList}`
                      ]?.["background-color"],
                  })
                : "",
              width: payload.style?.[
                `.contact-section${payload?.style?.countOfList}`
              ]?.["width"]?.replace("calc(100% - ", ""),
              margin:
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList}`
                ]?.["margin"],
              "border-radius":
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList}`
                ]?.["border-radius"],
            },
            [`.contact-section${payload?.style?.countOfList}.bg-image`]: {
              "background-image": payload.style?.[
                `.contact-section${payload?.style?.countOfList}.bg-image`
              ]?.["background-image"]
                ? removeUrlKeyInPath({
                    urlString:
                      payload.style?.[
                        `.contact-section${payload?.style?.countOfList}.bg-image`
                      ]?.["background-image"],
                  })
                : "",
            },
            [`.contact-section${payload?.style?.countOfList}.bg-image::before`]:
              {
                "background-color": payload.style?.[
                  `.contact-section${payload?.style?.countOfList}.bg-image::before`
                ]?.["background-color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          `.contact-section${payload?.style?.countOfList}.bg-image::before`
                        ]?.["background-color"],
                    })
                  : "",
              },
            [`.contact-section${payload?.style?.countOfList} .section-title-area`]:
              {
                "margin-bottom":
                  payload.style?.[
                    `.contact-section${payload?.style?.countOfList} .section-title-area`
                  ]?.["margin-bottom"],
              },
            [`.contact-section${payload?.style?.countOfList} .section-title-area .section-subtitle`]:
              {
                "font-size":
                  payload.style?.[
                    `.contact-section${payload?.style?.countOfList} .section-title-area .section-subtitle`
                  ]?.["font-size"],
                "font-weight":
                  payload.style?.[
                    `.contact-section${payload?.style?.countOfList} .section-title-area .section-subtitle`
                  ]?.["font-weight"],
                color: payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .section-title-area .section-subtitle`
                ]?.["color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          `.contact-section${payload?.style?.countOfList} .section-title-area .section-subtitle`
                        ]?.["color"],
                    })
                  : "",
                "background-color": payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .section-title-area .section-subtitle`
                ]?.["background-color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          `.contact-section${payload?.style?.countOfList} .section-title-area .section-subtitle`
                        ]?.["background-color"],
                    })
                  : "",
                padding:
                  payload.style?.[
                    `.contact-section${payload?.style?.countOfList} .section-title-area .section-subtitle`
                  ]?.["padding"],
                p1: payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .section-title-area .section-subtitle`
                ]?.["padding"]?.split(" ")[0],
                p2: payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .section-title-area .section-subtitle`
                ]?.["padding"]?.split(" ")[1],
                "border-radius":
                  payload.style?.[
                    `.contact-section${payload?.style?.countOfList} .section-title-area .section-subtitle`
                  ]?.["border-radius"],
                "margin-bottom":
                  payload.style?.[
                    `.contact-section${payload?.style?.countOfList} .section-title-area .section-subtitle`
                  ]?.["margin-bottom"],
                display:
                  payload.style?.[
                    `.contact-section${payload?.style?.countOfList} .section-title-area .section-subtitle`
                  ]?.["display"],
              },
            [`.contact-section${payload?.style?.countOfList} .section-title-area .section-title`]:
              {
                "font-size":
                  payload.style?.[
                    `.contact-section${payload?.style?.countOfList} .section-title-area .section-title`
                  ]?.["font-size"],
                "font-weight":
                  payload.style?.[
                    `.contact-section${payload?.style?.countOfList} .section-title-area .section-title`
                  ]?.["font-weight"],
                color: payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .section-title-area .section-title`
                ]?.["color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          `.contact-section${payload?.style?.countOfList} .section-title-area .section-title`
                        ]?.["color"],
                    })
                  : "",
              },
            [`.contact-section${payload?.style?.countOfList} .inquiry-box`]: {
              "background-color": payload.style?.[
                `.contact-section${payload?.style?.countOfList} .inquiry-box`
              ]?.["background-color"]
                ? convertRGBA({
                    rgbString:
                      payload.style?.[
                        `.contact-section${payload?.style?.countOfList} .inquiry-box`
                      ]?.["background-color"],
                  })
                : "",
              padding:
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .inquiry-box`
                ]?.["padding"],
              p1: payload.style?.[
                `.contact-section${payload?.style?.countOfList} .inquiry-box`
              ]?.["padding"]?.split(" ")[0],
              p2: payload.style?.[
                `.contact-section${payload?.style?.countOfList} .inquiry-box`
              ]?.["padding"]?.split(" ")[1],
              margin:
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .inquiry-box`
                ]?.["margin"],
              "border-radius":
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .inquiry-box`
                ]?.["border-radius"],
              border:
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .inquiry-box`
                ]?.["border"],
              borderPx:
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .inquiry-box`
                ]?.["border"]?.split(" ")[0],
              borderThik:
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .inquiry-box`
                ]?.["border"]?.split(" ")[1],
              borderColor: payload.style?.[
                `.contact-section${payload?.style?.countOfList} .inquiry-box`
              ]?.["borderColor"]
                ? convertRGBA({
                    rgbString:
                      payload.style?.[
                        `.contact-section${payload?.style?.countOfList} .inquiry-box`
                      ]?.["borderColor"],
                  })
                : "",
              "border-top":
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .inquiry-box`
                ]?.["border-top"],
              borderTopPx:
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .inquiry-box`
                ]?.["border-top"]?.split(" ")[0],
              borderTopThik:
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .inquiry-box`
                ]?.["border-top"]?.split(" ")[1],
              borderTopColor: payload.style?.[
                `.contact-section${payload?.style?.countOfList} .inquiry-box`
              ]?.["borderTopColor"]
                ? convertRGBA({
                    rgbString:
                      payload.style?.[
                        `.contact-section${payload?.style?.countOfList} .inquiry-box`
                      ]?.["borderTopColor"],
                  })
                : "",
              "box-shadow":
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .inquiry-box`
                ]?.["box-shadow"],
              bs1: payload.style?.[
                `.contact-section${payload?.style?.countOfList} .inquiry-box`
              ]?.["box-shadow"]?.split(" ")[0],
              bs2: payload.style?.[
                `.contact-section${payload?.style?.countOfList} .inquiry-box`
              ]?.["box-shadow"]?.split(" ")[1],
              bs3: payload.style?.[
                `.contact-section${payload?.style?.countOfList} .inquiry-box`
              ]?.["box-shadow"]?.split(" ")[2],
              shadowColor: payload.style?.[
                `.contact-section${payload?.style?.countOfList} .inquiry-box`
              ]?.["shadowColor"]
                ? convertRGBA({
                    rgbString:
                      payload.style?.[
                        `.contact-section${payload?.style?.countOfList} .inquiry-box`
                      ]?.["shadowColor"],
                  })
                : "",
              "text-align":
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .inquiry-box`
                ]?.["text-align"],
            },
            [`.contact-section${payload?.style?.countOfList} .inquiry-box img`]:
              {
                "margin-bottom":
                  payload.style?.[
                    `.contact-section${payload?.style?.countOfList} .inquiry-box img`
                  ]?.["margin-bottom"],
                "max-width":
                  payload.style?.[
                    `.contact-section${payload?.style?.countOfList} .inquiry-box img`
                  ]?.["max-width"],
              },
            [`.contact-section${payload?.style?.countOfList} .inquiry-box h4`]:
              {
                color: payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .inquiry-box h4`
                ]?.["color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          `.contact-section${payload?.style?.countOfList} .inquiry-box h4`
                        ]?.["color"],
                    })
                  : "",
                "font-weight":
                  payload.style?.[
                    `.contact-section${payload?.style?.countOfList} .inquiry-box h4`
                  ]?.["font-weight"],
                "font-size":
                  payload.style?.[
                    `.contact-section${payload?.style?.countOfList} .inquiry-box h4`
                  ]?.["font-size"],
                "margin-bottom":
                  payload.style?.[
                    `.contact-section${payload?.style?.countOfList} .inquiry-box h4`
                  ]?.["margin-bottom"],
              },
            [`.contact-section${payload?.style?.countOfList} .inquiry-box p`]: {
              color: payload.style?.[
                `.contact-section${payload?.style?.countOfList} .inquiry-box p`
              ]?.["color"]
                ? convertRGBA({
                    rgbString:
                      payload.style?.[
                        `.contact-section${payload?.style?.countOfList} .inquiry-box p`
                      ]?.["color"],
                  })
                : "",
              "font-weight":
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .inquiry-box p`
                ]?.["font-weight"],
              "font-size":
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .inquiry-box p`
                ]?.["font-size"],
              "line-height":
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .inquiry-box p`
                ]?.["line-height"],
            },
            [`.contact-section${payload?.style?.countOfList} .contactbox`]: {
              "background-color": payload.style?.[
                `.contact-section${payload?.style?.countOfList} .contactbox`
              ]?.["background-color"]
                ? convertRGBA({
                    rgbString:
                      payload.style?.[
                        `.contact-section${payload?.style?.countOfList} .contactbox`
                      ]?.["background-color"],
                  })
                : "",
              padding:
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .contactbox`
                ]?.["padding"],
              p1: payload.style?.[
                `.contact-section${payload?.style?.countOfList} .contactbox`
              ]?.["padding"]?.split(" ")[0],
              p2: payload.style?.[
                `.contact-section${payload?.style?.countOfList} .contactbox`
              ]?.["padding"]?.split(" ")[1],
              "border-radius":
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .contactbox`
                ]?.["border-radius"],
              border:
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .contactbox`
                ]?.["border"],
              borderPx:
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .contactbox`
                ]?.["border"]?.split(" ")[0],
              borderThik:
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .contactbox`
                ]?.["border"]?.split(" ")[1],
              borderColor: payload.style?.[
                `.contact-section${payload?.style?.countOfList} .contactbox`
              ]?.["borderColor"]
                ? convertRGBA({
                    rgbString:
                      payload.style?.[
                        `.contact-section${payload?.style?.countOfList} .contactbox`
                      ]?.["borderColor"],
                  })
                : "",
              "border-top":
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .contactbox`
                ]?.["border-top"],
              borderTopPx:
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .contactbox`
                ]?.["border-top"]?.split(" ")[0],
              borderTopThik:
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .contactbox`
                ]?.["border-top"]?.split(" ")[1],
              borderTopColor: payload.style?.[
                `.contact-section${payload?.style?.countOfList} .contactbox`
              ]?.["borderTopColor"]
                ? convertRGBA({
                    rgbString:
                      payload.style?.[
                        `.contact-section${payload?.style?.countOfList} .contactbox`
                      ]?.["borderTopColor"],
                  })
                : "",
              "box-shadow":
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .contactbox`
                ]?.["box-shadow"],
              bs1: payload.style?.[
                `.contact-section${payload?.style?.countOfList} .contactbox`
              ]?.["box-shadow"]?.split(" ")[0],
              bs2: payload.style?.[
                `.contact-section${payload?.style?.countOfList} .contactbox`
              ]?.["box-shadow"]?.split(" ")[1],
              bs3: payload.style?.[
                `.contact-section${payload?.style?.countOfList} .contactbox`
              ]?.["box-shadow"]?.split(" ")[2],
              shadowColor: payload.style?.[
                `.contact-section${payload?.style?.countOfList} .contactbox`
              ]?.["shadowColor"]
                ? convertRGBA({
                    rgbString:
                      payload.style?.[
                        `.contact-section${payload?.style?.countOfList} .contactbox`
                      ]?.["shadowColor"],
                  })
                : "",
            },
            [`.contact-section${payload?.style?.countOfList} .contactbox iframe`]:
              {
                border:
                  payload.style?.[
                    `.contact-section${payload?.style?.countOfList} .contactbox iframe`
                  ]?.["border"],
                borderPx:
                  payload.style?.[
                    `.contact-section${payload?.style?.countOfList} .contactbox iframe`
                  ]?.["border"]?.split(" ")[0],
                borderThik:
                  payload.style?.[
                    `.contact-section${payload?.style?.countOfList} .contactbox iframe`
                  ]?.["border"]?.split(" ")[1],
                borderColor: payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .contactbox iframe`
                ]?.["borderColor"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          `.contact-section${payload?.style?.countOfList} .contactbox iframe`
                        ]?.["borderColor"],
                    })
                  : "",
              },
            custom: payload.style?.custom,
          };
        });
        OpenModalCommonFunc(Cases.SETTING_CONTACT_US_PARENT, setAllPopupState);
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * add new and auto focus in first field
   */
  const hanldenewCreate = () => {
    const firstInput = document.getElementById("branch");
    if (firstInput) {
      firstInput.focus();
    }
    emptyData();
  };

  const handleAboutus = (event) => {
    setFormData((prev) => {
      return {
        ...prev,
        workingHour: event,
      };
    });
  };
  return (
    <>
      <Container className="mb-5">
        <Row className="justify-content-center ">
          {apiData.data.map((elem) => {
            return (
              <Col md={"4 mb-3"}>
                <ContactUsAddressCart
                  name={elem.name}
                  address={`${elem?.addLine1}, ${elem?.addLine1}, ${elem?.area}, ${elem?.city}, ${elem?.state}, ${elem?.country}, ${elem?.pinCode}`}
                  phone={
                    elem.phone &&
                    JSON.parse(elem.phone)?.map((elem) => {
                      return `${elem?.dialCode?.label} ${elem?.phoneValue} , `;
                    })
                  }
                  email={
                    elem?.email &&
                    JSON.parse(elem?.email)?.map((elem) => {
                      return `${elem?.emailValue} ,  `;
                    })
                  }
                  handleEdit={() =>
                    handleEdit(
                      elem?.contactUsId,
                      document.getElementById("branch")
                    )
                  }
                  handleDelete={() => handleDelete(elem?.contactUsId)}
                  defaultStar={() =>
                    defaultStar(elem?.isPrimary, elem?.contactUsId)
                  }
                  defaultSet={elem?.isPrimary}
                  checked={elem?.isActive}
                  handleChangeStatus={() =>
                    handleChangeStatus(elem?.contactUsId, elem?.isActive)
                  }
                  handleChangeSettings={() =>
                    handleChangeSettings(elem?.contactUsId)
                  }
                  isMulti={true}
                />
              </Col>
            );
          })}
        </Row>
        {!contactDetail && (
          <div className="d-flex justify-content-end align-items-center  ">
            <AddNewButton click={() => hanldenewCreate()} />
          </div>
        )}
        <Dividers divide={`${isEdit ? "Edit" : "ADD"} BRANCH`} />

        <Row>
          <Col md="12 mb-3">
            <Label htmlFor="validationCustom03">{"Branch Name *"}</Label>
            <Input
              className="form-control"
              name="name"
              id="branch"
              type="text"
              style={{ borderRadius: "0", color: "black" }}
              value={fromData.name}
              onChange={(e) => inputChange(e)}
            />
            <p style={{ color: "red" }}>
              {simpleValidator.current.message(
                "Branch Name",
                fromData.name,
                "required"
              )}
            </p>
          </Col>
          <Col md="12 mb-3">
            <Label htmlFor="validationCustom03">
              Title *{" "}
              <IsShowTitleORNot
                checked={fromData?.isTitleShow}
                onChange={inputChange}
              />{" "}
            </Label>
            <Input
              className="form-control"
              name="title"
              id="branch"
              type="text"
              style={{ borderRadius: "0", color: "black" }}
              value={fromData.title}
              onChange={(e) => inputChange(e)}
            />
            <p style={{ color: "red" }}>
              {simpleValidator.current.message(
                "Title *",
                fromData.title,
                "required"
              )}
            </p>
          </Col>
          <Col md="6 mb-3">
            <Label htmlFor="validationCustom03">{"Sub Title"}</Label>
            <Input
              className="form-control"
              name="subTitle"
              id="branch"
              type="text"
              style={{ borderRadius: "0", color: "black" }}
              value={fromData.subTitle}
              onChange={(e) => inputChange(e)}
            />
          </Col>
          <Col md="6 mb-3">
            <Label htmlFor="validationCustom03">{"Address Line 1 *"}</Label>
            <Input
              className="form-control"
              name="addLine1"
              type="text"
              style={{ borderRadius: "0", color: "black" }}
              value={fromData.addLine1}
              onChange={(e) => inputChange(e)}
            />
            <p style={{ color: "red" }}>
              {simpleValidator.current.message(
                "Address 1",
                fromData.addLine1,
                "required"
              )}
            </p>
          </Col>
          <Col md="6 mb-3">
            <Label htmlFor="validationCustom03">{"Address Line 2"}</Label>
            <Input
              className="form-control"
              name="addLine2"
              type="text"
              style={{ borderRadius: "0", color: "black" }}
              value={fromData.addLine2}
              onChange={(e) => inputChange(e)}
            />
          </Col>
          <Col md="6 mb-3">
            <Label htmlFor="validationCustom03">{"Country"}</Label>
            <Input
              className="form-control"
              name="country"
              type="text"
              style={{ borderRadius: "0", color: "black" }}
              value={fromData.country}
              onChange={(e) => inputChange(e)}
            />
          </Col>
          <Col md="6 mb-3">
            <Label htmlFor="validationCustom03">{"State"}</Label>
            <Input
              className="form-control"
              name="state"
              type="text"
              style={{ borderRadius: "0", color: "black" }}
              value={fromData.state}
              onChange={(e) => inputChange(e)}
            />
          </Col>
          <Col md="6 mb-3">
            <Label htmlFor="validationCustom03">{"City/Dist"}</Label>
            <Input
              className="form-control"
              name="city"
              type="text"
              style={{ borderRadius: "0", color: "black" }}
              value={fromData.city}
              onChange={(e) => inputChange(e)}
            />
          </Col>
          <Col md="6 mb-3">
            <Label htmlFor="validationCustom03">{"Pincode"}</Label>
            <Input
              className="form-control"
              name="pinCode"
              type="number"
              style={{ borderRadius: "0", color: "black" }}
              value={fromData.pinCode}
              onChange={(e) => inputChange(e)}
              min={0}
              maxLength={6}
              onInput={maxLengthCheck}
            />
          </Col>
          <Col md="6 mb-3">
            <Label htmlFor="validationCustom03">{"Area"}</Label>
            <Input
              className="form-control"
              name="area"
              type="text"
              style={{ borderRadius: "0", color: "black" }}
              value={fromData.area}
              onChange={(e) => inputChange(e)}
            />
          </Col>
          <Col md="12 mb-3">
            <Label htmlFor="validationCustom03">
              {"Google Map Location iframe"}
            </Label>
            <textarea
              className="form-control"
              style={{ borderRadius: "0", color: "black" }}
              name="iframe"
              value={fromData.iframe}
              onChange={(e) => inputChange(e)}
            ></textarea>
          </Col>
          {/* <Col md="6 mb-3">
            <Label htmlFor="validationCustom03">{"Latitude"}</Label>
            <Input
              className="form-control"
              name="latitude"
              type="text"
              style={{ borderRadius: "0", color: "black" }}
              value={fromData.latitude}
              onChange={(e) => inputChange(e)}
            />
          </Col>
          <Col md="6 mb-3">
            <Label htmlFor="validationCustom03">{"Longitude"}</Label>
            <Input
              className="form-control"
              name="longitude"
              type="text"
              style={{ borderRadius: "0", color: "black" }}
              value={fromData.longitude}
              onChange={(e) => inputChange(e)}
            />
          </Col> */}
        </Row>
        <Row>
          <Col md="6 mb-3">
            {addFields.phoneInput.map((elem, i) => {
              return (
                <Row className="mb-3" key={elem.id}>
                  <Label htmlFor="validationCustom03">{"Phone *"}</Label>
                  <div className="d-flex phone-dial-code-contactus">
                    <div style={{ width: "130px" }}>
                      <Select
                        options={options}
                        name="dialCode"
                        value={elem.dialCode}
                        onChange={(prev, e) => SelectDrop(prev, e, i)}
                      />
                    </div>
                    <Input
                      className="form-control"
                      name="phoneValue"
                      type="number"
                      value={elem.phoneValue}
                      style={{ borderRadius: "0", color: "black" }}
                      onChange={(e) => handleChangePhoneInput(e, i, "phone")}
                      min={0}
                      maxLength={10}
                      onInput={maxLengthCheck}
                    />

                    {addFields.phoneInput.length >= 2 && (
                      <IconButton
                        edge="start"
                        color="inherit"
                        style={{ marginLeft: "5px", color: "red" }}
                        onClick={() => deleteMoreInput(elem.id, "phone")}
                      >
                        <Trash2 />
                      </IconButton>
                    )}
                  </div>
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "Phone",
                      elem.dialCode && elem.phoneValue,
                      "required"
                    )}
                  </p>
                </Row>
              );
            })}
            <div className="d-flex justify-content-center ">
              <Button onClick={() => addMoreInput("phone")} color="primary">
                + Add More
              </Button>
            </div>
          </Col>
          <Col md="6 mb-3">
            {addFields.emailInput.map((elem, i) => {
              return (
                <Row className="mb-3" key={elem.id}>
                  <Label htmlFor="validationCustom03">{"Email *"}</Label>
                  <div className="d-flex">
                    <Input
                      className="form-control"
                      name="emailValue"
                      type="email"
                      style={{
                        borderRadius: "0",
                        color: "black",
                        height: "48px",
                      }}
                      value={elem.emailValue}
                      onChange={(e) => handleChangePhoneInput(e, i, "email")}
                    />
                    {addFields.emailInput.length >= 2 && (
                      <IconButton
                        edge="start"
                        color="inherit"
                        style={{
                          marginLeft: "5px",
                          color: "red",
                        }}
                        onClick={() => deleteMoreInput(elem.id, "email")}
                      >
                        <Trash2 />
                      </IconButton>
                    )}
                  </div>
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "Email",
                      elem.emailValue,
                      "required"
                    )}
                  </p>
                </Row>
              );
            })}
            <div className="d-flex justify-content-center ">
              <Button onClick={() => addMoreInput("email")} color="primary">
                + Add More
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="12 mb-4">
            <Editor value={fromData.workingHour} onChange={handleAboutus} />
          </Col>
        </Row>
        <ProductImageUploadPopup />
      </Container>

      {/* setting images parent popup */}
      <CommonPopup
        open={allPopupState.contactUsSettingParent}
        close={() =>
          OpenModalCommonFunc(
            Cases.SETTING_CONtACT_US_PARENT,
            setAllPopupState,
            false
          )
        }
        modalName={modalsName.SETTING_CONtACT_US_PARENT}
        modalCase={Cases.SETTING_CONtACT_US_PARENT}
        handleSubmit={handleSubmitSetting}
        emptyData={emptyData}
        // inputData={formDataChild}
        edit={false}
        content={
          <SettingContactUsParentPopup imageUpload={imageUploadImages} />
        }
      />
    </>
  );
};

export default ContactUsPopup;
