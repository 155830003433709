import React, { useContext, useEffect, useState } from "react";
import { PageTitleTheme } from "../../../constant";
import { Col, Container, Input, Label, Row } from "reactstrap";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import PageTitleContext from "../../../context/pageTitleContext/PageTitleContext";
import { getNavbarList } from "../apis/LatestNewsApi/LatestNewsApi";
import { TostMessage } from "../../../utils";

const PageTitlePopup = (props) => {
  const { imageUpload, simpleValidator } = props;
  const { setFormDataOurData, formDataOurTeam, isEdit } =
    useContext(PageTitleContext);
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);

  const [navMapsData, setNavMapsData] = useState([]);

  const getNavMaps = async () => {
    try {
      const { data } = await getNavbarList();
      if (data.status === 200) {
        setNavMapsData(data.payload.data);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * input change
   * @param {event} e
   */
  const changeFormValue = (e) => {
    const { name, value } = e.target;
    setFormDataOurData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  /**
   * image reupload
   */
  const reupload = () => {
    setFormDataOurData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };

  return (
    <>
      <Container className="mb-5">
        <Row>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-around",
              marginBottom: "3rem",
            }}
          >
            <div className="mt-4 d-flex flex-column justify-content-center ">
              <h6>{isEdit ? "Edit" : "Add New"}</h6>
              <Row className="mt-4">
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Title *"}</Label>
                  <Input
                    className="form-control"
                    name="title"
                    type="text"
                    placeholder="Title *"
                    value={formDataOurTeam.title}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "title",
                      formDataOurTeam.title,
                      "required"
                    )}
                  </p>
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Sub Title *"}</Label>
                  <Input
                    className="form-control"
                    name="subTitle"
                    type="text"
                    placeholder="Sub Title *"
                    value={formDataOurTeam.subTitle}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "subTitle",
                      formDataOurTeam.subTitle,
                      "required"
                    )}
                  </p>
                </Col>
                {/* <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Theme"}</Label>
                  <select
                    name="theme"
                    id="theme"
                    className="form-select"
                    style={{ borderRadius: "0", color: "black" }}
                    value={formDataOurTeam.theme}
                    onChange={(e) => changeFormValue(e)}
                  >
                    {PageTitleTheme.map((ele) => {
                      return (
                        <option value={ele.theme} key={ele.theme}>
                          {ele.themeName}
                        </option>
                      );
                    })}
                  </select>
                </Col> */}
              </Row>
            </div>
          </div>
        </Row>
        <ProductImageUploadPopup
          allPopupState={allPopupState}
          setAllPopupState={setAllPopupState}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
          image={formDataOurTeam.image}
          setImage={setFormDataOurData}
          imageUpload={imageUpload}
          isEdit={isEdit}
          reupload={reupload}
          // aspect={1 / 1}
        />
      </Container>
    </>
  );
};

export default PageTitlePopup;
