import React, { useState } from "react";
import BrokerIncentiveContext from "./BrokerIncentiveContext";

const BrokerIncentiveState = (props) => {
  const [modal, setModal] = useState(false);
  const [brokerIncentiveLists, setBrokerIncentiveLists] = useState([]);
  const [projectId, setProjectId] = useState([]);
  const [brokerId, setBrokerId] = useState([]);
  const [BrokerIncentiveValue, setBrokerIncentiveValue] = useState({
    pageId: "",
    brokerId: "",
    customerName: "",
    customerNumber: "",
    propertyDetail: "",
    incentive: "",
  });
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);
  const [progress, setProgress] = useState(0);

  const [BrokerIncentiveIsEdit, setBrokerIncentiveIsEdit] = useState(false);
  const [personalPageIncentiveEdit, setPersonalPageIncentiveEdit] =
    useState(false);

  return (
    <BrokerIncentiveContext.Provider
      value={{
        modal,
        setModal,
        brokerIncentiveLists,
        setBrokerIncentiveLists,
        projectId,
        setProjectId,
        brokerId,
        setBrokerId,
        BrokerIncentiveValue,
        setBrokerIncentiveValue,
        isEdit,
        setIsEdit,
        editId,
        setEditId,
        page,
        setPage,
        pageSize,
        setPageSize,
        record,
        setRecord,
        progress,
        setProgress,
        BrokerIncentiveIsEdit,
        setBrokerIncentiveIsEdit,
        personalPageIncentiveEdit,
        setPersonalPageIncentiveEdit,
      }}
    >
      {props.children}
    </BrokerIncentiveContext.Provider>
  );
};

export default BrokerIncentiveState;
