import React, { useContext, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Cases, DELETE_CONFIRM, modalsName } from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import CommonPopup from "../../../Modals/commonPopup";
import InquiryPopup from "./InquiryPopup";
import EditIcon from "@mui/icons-material/Edit";
import InquiryParentPopup from "./InquiryParentPopup";
import { FILE_UPLOAD_API } from "../apis/SliderApi/SliderApi";
import {
  addUrlImage,
  convertRGBA,
  isCheckValue,
  isEmptyObject,
  randomStr,
  removeUrlImage,
  removeUrlKeyInPath,
  rgbColorCreate,
} from "../../../../utils/helper";
import { FILE_TYPE, ThemeNo, titleUpdateKey } from "../../../../constant";
import { IconButton } from "@material-ui/core";
import { Settings, Trash } from "react-feather";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import SimpleReactValidator from "simple-react-validator";
import SettingInquiryParentPopup from "./SettingInquiryPerentPopup";
import InquiryContext from "../../../context/InquiryContext/InquiryContext";
import {
  deleteInquiry,
  getInquiry,
  getInquiryDetails,
  postInquiry,
  putUpdateInquiry,
  updateStatus,
} from "../apis/InquiryApi/InquiryApi";
import DeleteSection from "../../../common/DeleteSection";
import SettingMultiWebContext from "../../../../context/SettingMultiWebContext/SettingMultiWebContext";
import {
  InquiryStyleOneSubFun,
  InquiryThemeOneFunction,
  InquiryThemeOneReset,
} from "./InquiryStyleFunction";

const Inquiry = (props) => {
  const {
    pageConfigDetails,
    webMasterDetail,
    getData,
    inquiryDetail,
    contentMapId,
    ByteMiniWeb_Dashboard,
    contentDetail,
  } = props;
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );
  const { setCloneAlignModal, setAlignFormData, setCloneFormData } = useContext(
    SettingMultiWebContext
  );
  const parentValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const childValidator = useRef(new SimpleReactValidator());
  const {
    fromData,
    setFormData,
    formDataChild,
    setFormDataChild,
    id,
    setId,
    setIsEdit,
    isEdit,
    apiDataOurTeam: apiDataImageParent,
    setApiDataOurTeam: setApiDataImageParent,
    setSkill,
    setGalleryImgId,
    setSettingFormData,
    settingFormData,
    isParent,
    setIsParent,
    childId,
    setChildId,
    navMapId,
    setBackImageToggle,
    boxSection,
    setBoxSection,
    collId,
    setCollId,
    isBackgroundColor,
    setIsBackgroundColor,
    setTheme,
    setImgShow,
    theme,
    sectionPageName,
    setSectionPageName,
  } = useContext(InquiryContext);
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * get inquiry parent list
   * @param {status} status
   */
  const getFaqsParentList = async ({
    parentId = null,
    isClose = false,
    filter,
  }) => {
    try {
      let paramObj = {};
      if (
        ((parentId && parentId !== null) ||
          (id && isClose === false && isParent === false)) &&
        isParent === false
      ) {
        paramObj["search"] = { parentId: parentId || id, contentMapId };
        setId(parentId || id);
        if (filter.length) {
          paramObj["search"] = { ...paramObj.search, title: filter };
        }
      } else {
        paramObj["search"] = { parentId: null, contentMapId };
      }
      const { data } = await getInquiry(paramObj);
      if (isCheckValue({ value: parentId })) {
        setChildId(parentId);
      }
      if (data.status === 200) {
        if (
          isParent === false &&
          ((parentId && parentId !== null) || id) &&
          isClose === false
        ) {
          setApiDataImageParent({
            ...apiDataImageParent,
            isLoading: false,
            data: data.payload.data.map((elem) => {
              return {
                id: elem?.title + elem?.inquiryId,
                Id: elem?.inquiryId,
                name: elem?.title,
                image: "",
                webId: elem?.webId,
                createdAt: elem?.createdAt,
                isActive: elem?.isActive,
              };
            }),
          });
          OpenModalCommonFunc(Cases.INQUIRY, setAllPopupState);
        } else if (parentId && parentId !== null) {
          OpenModalCommonFunc(Cases.INQUIRY, setAllPopupState);
          setApiDataImageParent({
            ...apiDataImageParent,
            isLoading: false,
            data: data.payload.data.map((elem) => {
              return {
                id: elem?.title + elem?.inquiryId,
                Id: elem?.inquiryId,
                name: elem?.title,
                image: elem?.image,
                webId: elem?.webId,
                createdAt: elem?.createdAt,
                isActive: elem?.isActive,
              };
            }),
          });
        } else {
          setApiDataImageParent({
            ...apiDataImageParent,
            isLoading: false,
            data: data.payload.data,
          });
        }
        if (isClose && isClose !== false) {
          setIsParent(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * item inquiry enable disable api call
   * @param {inquiryId} inquiryId
   * @param {status} isActive
   */

  const handleChangeFaqs = async (inquiryId, isActive) => {
    try {
      let body = {
        inquiryId,
        isActive: isActive ? false : true,
      };
      const { data } = await updateStatus(body);
      if (data.status === 200) {
        getFaqsParentList({});
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * file upload
   * @param {file} file
   */
  const imageUploadImages = async (file) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.INQUIRY);
    formData.append("file", file);
    setLoaderShow(true);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        setSettingFormData((prev) => {
          return {
            ...prev,
            ".inquiry-section.bg-image": {
              "background-image": data.payload.baseUrl + data.payload.imageUrl,
            },
          };
        });

        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * create and update inquiry
   */
  const submitForm = async () => {
    if (
      (isParent === false && childId >= -1 && isEdit) ||
      (id >= -1 && isEdit)
    ) {
      setLoaderShow(true);
      try {
        let formData = {};
        if (isParent && isParent !== false) {
          formData = { ...fromData, inquiryId: id, contentMapId: collId };
        } else {
          formData = {
            ...formDataChild,
            parentId: id,
            navMapId,
            inquiryId: childId,
            contentMapId: collId,
          };
        }
        const { data } = await putUpdateInquiry(formData);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            ByteMiniWeb_Dashboard();
          } else {
            getFaqsParentList({ parentId: id });
          }
          emptyData();
          OpenModalCommonFunc(Cases.INQUIRY_PARENT, setAllPopupState, false);
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    } else {
      try {
        setLoaderShow(true);
        let formData = {};
        if (isParent && isParent !== false) {
          formData = { ...fromData, contentMapId: collId };
        } else {
          formData = {
            ...formDataChild,
            parentId: id,
            navMapId,
            order: apiDataImageParent.data.length + 1,
            contentMapId: collId,
          };
        }
        const { data } = await postInquiry(formData);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            ByteMiniWeb_Dashboard();
          } else {
            getFaqsParentList({ parentId: id });
          }
          emptyData();
          OpenModalCommonFunc(Cases.INQUIRY_PARENT, setAllPopupState, false);
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };
  const handleSubmit = async (formValidationCondition) => {
    let validatorFields;
    if (formValidationCondition === "parentForm") {
      validatorFields = parentValidator.current;
    } else if (formValidationCondition === "childForm") {
      validatorFields = childValidator.current;
    }
    validatorFields.showMessages();

    if (validatorFields.allValid()) {
      try {
        // Process form data based on the formType
        if (formValidationCondition === "parentForm") {
          submitForm();
        } else if (formValidationCondition === "childForm") {
          submitForm();
        }
        validatorFields.hideMessages();
      } catch (error) {
        // Handle error for form submission
      }
    } else {
      // Force update to display validation messages
      forceUpdate({});
    }
  };

  /**
   * create and update inquiry
   */
  const handleSubmitSetting = async (isRestore = false, isClose = true) => {
    if (id >= -1 && isEdit) {
      setLoaderShow(true);
      try {
        let newData;
        if (isRestore) {
          const isConfirm = window.confirm(
            "Are you sure want to restore style ?"
          );
          if (isConfirm) {
            newData = {
              styleRestore: true,
              inquiryId: id,
            };
          } else {
            setLoaderShow(false);
            return;
          }
        } else {
          if (+theme === ThemeNo.ThemeOne) {
            newData = InquiryStyleOneSubFun({
              settingFormData,
              boxSection,
            });
            if (isBackgroundColor) {
              newData[".inquiry-section"] = {
                ...newData?.[".inquiry-section"],
                "background-color": settingFormData[".inquiry-section"][
                  "background-color"
                ]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData[".inquiry-section"]["background-color"],
                    })
                  : "",
              };
            }
            if (boxSection) {
              newData[".inquiry-section.bg-image::before"] = {
                ...newData[".inquiry-section.bg-image::before"],
                "border-radius": `${settingFormData[".inquiry-section"][
                  "border-radius"
                ]?.replace("rem", "")}rem`,
              };
            }
          }

          const keysToRemove = [
            "bs1",
            "bs2",
            "bs3",
            "bs4",
            "bss1",
            "bss2",
            "bss3",
            "bss4",
            "borderPx",
            "borderThik",
            "p1",
            "p2",
            "outPx",
            "outThik",
            "borderBtPx",
            "borderBtThik",
            "borderTPx",
            "borderTThik",
            "image",
          ];
          function removeKeys(obj, keys) {
            for (const key in obj) {
              if (typeof obj[key] === "object") {
                removeKeys(obj[key], keys);
              } else if (keys.includes(key)) {
                delete obj[key];
              }
            }
          }
          removeKeys(newData, keysToRemove);
          newData = {
            style: newData,
            inquiryId: id,
          };
        }

        const { data } = await putUpdateInquiry(newData);
        if (data.status === 200) {
          if (isClose) {
            OpenModalCommonFunc(
              Cases.SETTING_INQUIRY_PARENT,
              setAllPopupState,
              false
            );
            emptyData();
            setBackImageToggle(false);
          }
          setLoaderShow(false);
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };
  /**
   * get data inquiry
   * @param {id} inquiryId
   */
  const handleEditFaqs = async (inquiryId, isParent) => {
    try {
      setLoaderShow(true);
      const { data } = await getInquiryDetails(inquiryId);
      if (data.status === 200) {
        let payload = data.payload.data;
        if (isParent) {
          setSectionPageName(payload?.title);
          setId(inquiryId);
          setIsParent(true);
          setFormData({
            title: payload?.title || "",
            subTitle: payload?.subTitle || "",
            isTitleShow: payload?.isTitleShow || "",
            navMapId: payload?.navMapId || "",
            webId: localStorage.getItem("webId"),
            style: payload?.style || "",
          });
          OpenModalCommonFunc(Cases.INQUIRY_PARENT, setAllPopupState);
        } else {
          setChildId(inquiryId);
          setIsParent(false);
          setFormDataChild({
            title: payload?.title || "",
            subTitle: payload?.subTitle || "",
            isTitleShow: payload?.isTitleShow || "",
            description: payload?.description || "",
            parentId: payload?.parentId || "",
            order: payload?.order || 1,
            webId: localStorage.getItem("webId"),
          });
          OpenModalCommonFunc(Cases.INQUIRY, setAllPopupState);
        }
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * get setting data
   * @param {id} inquiryId
   */
  const handleChangeSettings = async (inquiryId, isThemeEdit = false) => {
    try {
      setLoaderShow(true);
      setId(inquiryId);
      setSettingFormData((prev) => {
        return {
          ...prev,
          inquiryId,
        };
      });
      const { data } = await getInquiryDetails(inquiryId);
      if (data.status === 200) {
        const themeCount = data?.payload?.data?.theme;
        setTheme(themeCount);
        setImgShow(true);
        let payload = data.payload.data;
        setSectionPageName(payload.title);
        if (
          +themeCount === ThemeNo.ThemeOne &&
          isEmptyObject(data?.payload?.data?.style)
        ) {
          setSettingFormData({ ...InquiryThemeOneReset });
          setBoxSection(false);
          setIsBackgroundColor(false);
          setBackImageToggle(false);
        } else {
          if (
            payload.style?.[".inquiry-section"]?.["width"] ||
            payload.style?.[".inquiry-section"]?.["margin"] ||
            payload.style?.[".inquiry-section"]?.["border-radius"]
          ) {
            setBoxSection(true);
          }
          if (
            payload.style?.[".inquiry-section.bg-image"]?.["background-image"]
          ) {
            setBackImageToggle(true);
          }
          if (payload.style?.[".inquiry-section"]?.["background-color"]) {
            setIsBackgroundColor(true);
          }
          let getStyle;
          if (+themeCount === 1) {
            getStyle = InquiryThemeOneFunction(payload);
          } else {
            getStyle = InquiryThemeOneFunction(payload);
          }
          setSettingFormData((prev) => {
            return {
              ...prev,
              ...getStyle,
            };
          });
        }
        if (!isThemeEdit) {
          OpenModalCommonFunc(Cases.SETTING_INQUIRY_PARENT, setAllPopupState);
          TostMessage({ message: data, type: "success" });
        }
        setIsEdit(true);
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * delete inquiry
   * @param {id} inquiryId
   */
  const handleDelete = async (inquiryId, isParent = false) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await deleteInquiry(inquiryId);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            // getFaqsParentList(null, true);
            ByteMiniWeb_Dashboard();
          } else {
            getFaqsParentList({ parentId: childId });
          }
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * clear data
   */
  const emptyData = () => {
    setIsBackgroundColor(false);
    setBoxSection(false);
    setIsEdit(false);
    setIsParent(false);
    setChildId("");
    setFormData({
      title: "",
      subTitle: "",
      navMapId: "",
      isTitleShow: true,
      theme: 1,
      webId: localStorage.getItem("webId"),
      style: {
        ".inquiry-section": {
          padding: "100px 0",
          "background-color": "rgb(255 255 255 / 100)",
        },
        ".inquiry-section.bg-image": {
          "background-image": "",
        },
        ".inquiry-section.bg-image::before": {
          "background-color": "",
        },
        ".inquiry-section .section-title-area": {
          "margin-bottom": "50px",
        },
        ".inquiry-section .section-title-area .section-subtitle": {
          "font-size": "16px",
          "font-weight": "600",
          color: "rgb(255 73 124 / 100)",
          "background-color": "rgb(255 73 124 / 0.2)",
          padding: "5px 20px",
          p1: "5px",
          p2: "20px",
          "border-radius": "15px",
          "margin-bottom": "15px",
        },
        ".inquiry-section .section-title-area .section-title": {
          "font-size": "44px",
          "font-weight": "600",
          color: "rgb(0 0 0 / 100)",
        },
        ".inquirybox": {
          "background-color": "rgb(255 255 255 / 100)",
          padding: "40px 40px",
          p1: "40px",
          p2: "40px",
          "border-radius": "15px",
          border: "1px solid rgb(255 255 255 / 100)",
          borderPx: "1px",
          borderThik: "solid",
          borderColor: "rgb(255 255 255 / 100)",
          "border-top": "3px solid rgb(255 73 124 / 100)",
          borderTPx: "1px",
          borderTThik: "solid",
          borderTColor: "rgb(255 73 124 / 100)",
          "box-shadow": "5px 5px 15px rgb(0 0 0 / 0.09)",
          bs1: "5px",
          bs2: "5px",
          bs3: "15px",
          shedowColor: "rgb(0 0 0 / 0.09)",
        },
        ".inquirybox .contact_form .form-control": {
          width: "100%",
          "margin-bottom": "10px",
          "min-height": "50px",
          padding: "10px 20px",
          p1: "10px",
          p2: "20px",
          "background-color": "rgb(242 247 255 / 100)",
          border: "1px solid rgb(206 212 218 / 100)",
          borderPx: "1px",
          borderThik: "solid",
          borderColor: "rgb(206 212 218 / 100)",
          "border-radius": "4px",
          "font-size": "16px",
        },
        ".inquirybox .capchabox": {
          "background-color": "rgb(246 246 246 / 100)",
          padding: "15px",
        },
        ".inquirybox .capchabox .capchatitle": {
          "margin-bottom": "10px",
        },
        ".inquirybox .contact_form .btn": {
          color: "rgb(255 255 255 / 100)",
          "background-color": "rgb(255 73 124 / 100)",
        },
      },
    });
    setSkill([{ id: randomStr(2), skill: "", parentageOfSkill: "" }]);
    setGalleryImgId("");
    setFormDataChild({
      title: "",
      subTitle: "",
      description: "",
      isTitleShow: true,
      order: 1,
      webId: localStorage.getItem("webId"),
      navMapId: "",
    });
    setLoaderShow(false);
    setSettingFormData({
      ".inquiry-section": {
        padding: "100px 0",
        "background-color": "rgb(255 255 255 / 100)",
      },
      ".inquiry-section.bg-image": {
        "background-image": "",
      },
      ".inquiry-section.bg-image::before": {
        "background-color": "",
      },
      ".inquiry-section .section-title-area": {
        "margin-bottom": "50px",
      },
      ".inquiry-section .section-title-area .section-subtitle": {
        "font-size": "16px",
        "font-weight": "600",
        color: "rgb(255 73 124 / 100)",
        "background-color": "rgb(255 73 124 / 0.2)",
        padding: "5px 20px",
        p1: "5px",
        p2: "20px",
        "border-radius": "15px",
        "margin-bottom": "15px",
      },
      ".inquiry-section .section-title-area .section-title": {
        "font-size": "44px",
        "font-weight": "600",
        color: "rgb(0 0 0 / 100)",
      },
      ".inquirybox": {
        "background-color": "rgb(255 255 255 / 100)",
        padding: "40px 40px",
        p1: "40px",
        p2: "40px",
        "border-radius": "15px",
        border: "1px solid rgb(255 255 255 / 100)",
        borderPx: "1px",
        borderThik: "solid",
        borderColor: "rgb(255 255 255 / 100)",
        "border-top": "3px solid rgb(255 73 124 / 100)",
        borderTPx: "1px",
        borderTThik: "solid",
        borderTColor: "rgb(255 73 124 / 100)",
        "box-shadow": "5px 5px 15px rgb(0 0 0 / 0.09)",
        bs1: "5px",
        bs2: "5px",
        bs3: "15px",
        shedowColor: "rgb(0 0 0 / 0.09)",
      },
      ".inquirybox .contact_form .form-control": {
        width: "100%",
        "margin-bottom": "10px",
        "min-height": "50px",
        padding: "10px 20px",
        p1: "10px",
        p2: "20px",
        "background-color": "rgb(242 247 255 / 100)",
        border: "1px solid rgb(206 212 218 / 100)",
        borderPx: "1px",
        borderThik: "solid",
        borderColor: "rgb(206 212 218 / 100)",
        "border-radius": "4px",
        "font-size": "16px",
      },
      ".inquirybox .capchabox": {
        "background-color": "rgb(246 246 246 / 100)",
        padding: "15px",
      },
      ".inquirybox .capchabox .capchatitle": {
        "margin-bottom": "10px",
      },
      ".inquirybox .contact_form .btn": {
        color: "rgb(255 255 255 / 100)",
        "background-color": "rgb(255 73 124 / 100)",
      },
      inquiryId: "",
    });
  };

  const handleAlignAndCloneSection = ({ isClone = false }) => {
    if (isClone) {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          cloneModal: true,
        };
      });
      setCloneFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          pageConfigDetailsLabel: pageConfigDetails[0]?.label,
          contentDetailName: contentDetail.name,
        };
      });
    } else {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          alignModal: true,
        };
      });
      setAlignFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          label: pageConfigDetails[0]?.label,
        };
      });
    }
  };
  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody
            className="support-ticket-font box-border-div"
            style={{ padding: "20px" }}
          >
            <Row>
              <CardBuilderHeaderSection
                addBtnTitle={pageConfigDetails[0]?.label}
                name={pageConfigDetails[0]?.label}
                isAdd={!inquiryDetail && true}
                mainEditSection={true}
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
                openModal={() => {
                  OpenModalCommonFunc(Cases.INQUIRY_PARENT, setAllPopupState);
                  setSectionPageName(modalsName.INQUIRY_PARENT);
                  emptyData();
                  setIsParent(true);
                  setCollId(contentMapId);
                }}
                isCloneSection={true}
                isAlignSection={true}
                handleAlignAndCloneSection={handleAlignAndCloneSection}
              />
              {inquiryDetail && (
                <Col xl="12 xl-50 box-col-6" md="6">
                  <Card className="ecommerce-widget ">
                    <CardBody
                      className="support-ticket-font"
                      style={{ padding: "20px" }}
                    >
                      <div className="col-12">
                        <CardBuilderHeaderSection
                          name={inquiryDetail?.title}
                          checked={inquiryDetail?.isActive}
                          switchToggle={false}
                          id={inquiryDetail?.inquiryId}
                          handleChange={handleChangeFaqs}
                        />
                      </div>
                      <div className="d-flex justify-content-end mb-0">
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleEditFaqs(inquiryDetail.inquiryId, true);
                            setCollId(contentMapId);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() =>
                            handleDelete(inquiryDetail.inquiryId, true)
                          }
                        >
                          <Trash />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleChangeSettings(inquiryDetail.inquiryId);
                          }}
                        >
                          <Settings />
                        </IconButton>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
              <DeleteSection
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
              />
            </Row>
          </CardBody>
        </Card>
      </Col>
      <CommonPopup
        open={allPopupState.inquiryChildPopup}
        close={() => {
          OpenModalCommonFunc(Cases.INQUIRY, setAllPopupState, false);
          setIsParent(false);
        }}
        // apiCall={getFaqsParentList}
        modalName={sectionPageName ? sectionPageName : modalsName.INQUIRY}
        modalCase={Cases.INQUIRY}
        handleSubmit={handleSubmit}
        validationForm={"childForm"}
        emptyData={emptyData}
        inputData={fromData}
        edit={false}
        oldTitle={titleUpdateKey.isGallery}
        label={titleUpdateKey.Certificates}
        getData={getData}
        content={
          <InquiryPopup
            handleChangeStatus={handleChangeFaqs}
            handleEdit={handleEditFaqs}
            imageUpload={imageUploadImages}
            emptyData={emptyData}
            simpleValidator={childValidator}
            webMasterDetail={webMasterDetail}
            handleDelete={handleDelete}
          />
        }
      />

      {/* item faqs popup */}
      <CommonPopup
        open={allPopupState.inquiryPopup}
        close={() => {
          OpenModalCommonFunc(Cases.INQUIRY_PARENT, setAllPopupState, false);
          setIsParent(false);
        }}
        modalName={
          sectionPageName ? sectionPageName : modalsName.INQUIRY_PARENT
        }
        modalCase={Cases.INQUIRY_PARENT}
        handleSubmit={handleSubmit}
        validationForm={"parentForm"}
        emptyData={emptyData}
        inputData={formDataChild}
        edit={false}
        content={<InquiryParentPopup simpleValidator={parentValidator} />}
      />

      {/* setting Service Counter parent popup */}
      <CommonPopup
        open={allPopupState.inquirySettingPopup}
        close={() =>
          OpenModalCommonFunc(
            Cases.SETTING_INQUIRY_PARENT,
            setAllPopupState,
            false
          )
        }
        modalName={
          sectionPageName
            ? sectionPageName
            : modalsName.SETTING_CONTACT_US_PARENT
        }
        modalCase={Cases.SETTING_INQUIRY_PARENT}
        handleSubmit={handleSubmitSetting}
        emptyData={emptyData}
        inputData={formDataChild}
        edit={false}
        content={
          <SettingInquiryParentPopup
            imageUpload={imageUploadImages}
            handleSubmitSetting={handleSubmitSetting}
            handleChangeSettings={handleChangeSettings}
          />
        }
      />
    </>
  );
};

export default Inquiry;
