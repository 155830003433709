import React, { useContext, useEffect, useState } from "react";
import { getImagesParent } from "../apis/ImageApi/ImageApi";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import { Card, CardBody, Col, Row } from "reactstrap";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import { IconButton } from "@mui/material";
import { Cases, btnName } from "../../../constant";
import GalleryMultipageContext from "../../../context/GalleryMultipageContext/GalleryMultipageContext";
import { Boy } from "@mui/icons-material";
import { ArrowLeft, Settings, Trash } from "react-feather";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { getVideos } from "../apis/VideosApi/VideosApi";
import VideosContext from "../../../context/VideosContext/VideosContext";

const ChildShow = ({
  emptyData,
  handleChangeImageParent,
  handleEditImagesParent,
  EditIcon,
  handleDelete,
  handleChangeSettings,
  childId,
  contentMapId,
  setChildId,
  childData,
  setChildData,
  idCopy,
  setIdCopy,
  CountUp,
  setCountUp,
  getVideosList,
  isBack,
}) => {
  const {
    setId,
    setCollId,
    setParentId,
    id,
    setFormDataVideos,
    setIsVideoParent,
    setIsChildForFormSubmit,
    ids,
    setIds,
  } = useContext(VideosContext);
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );

  useEffect(() => {
    getVideosList({ isChild: true });
  }, [CountUp]);
  return (
    <div>
      <div className="container">
        {Object.keys(ids)?.length > 0 ? (
          <div className="mb-3 ">
            {" "}
            <IconButton
              edge="start"
              color="inherit"
              style={{ marginLeft: "5px" }}
              onClick={() => {
                getVideosList({
                  id: childData?.length
                    ? childData[0]?.parentId
                    : isBack[0]?.parentId,
                  prev: "prev",
                  isChild: true,
                });
                setIdCopy("");
              }}
            >
              <ArrowLeft />
            </IconButton>
          </div>
        ) : (
          ""
        )}
        <Row>
          {childData.length == 0 ? (
            <h2>Child not found</h2>
          ) : (
            childData.map((elem) => {
              return (
                <Col xl="3 xl-50 box-col-6" md="6">
                  <Card className="ecommerce-widget ">
                    <CardBody className="support-ticket-font">
                      <div className="col-12">
                        <CardBuilderHeaderSection
                          name={elem?.title}
                          checked={elem?.isActive}
                          switchToggle={true}
                          isAdd={Object.keys(ids).length == 0 && true}
                          id={elem?.videoId}
                          handleChange={() =>
                            handleChangeImageParent(
                              elem.videoId,
                              elem?.isActive,
                              true,
                              elem?.parentId
                            )
                          }
                          openModal={() => {
                            OpenModalCommonFunc(
                              Cases.VIDEOS_PARENT,
                              setAllPopupState
                            );
                            setIsChildForFormSubmit(true);
                            setIsVideoParent(true);
                            setCollId(contentMapId);
                            setParentId(elem.videoId);
                            setFormDataVideos((prev) => {
                              return {
                                ...prev,
                                parentId: elem.videoId,
                              };
                            });
                          }}
                        />
                        <OpenPopupBtn
                          openPopup={() => {
                            OpenModalCommonFunc(Cases.VIDEOS, setAllPopupState);
                            setId(elem.videoId);
                            emptyData();
                            setIsChildForFormSubmit(true);
                          }}
                          name={btnName.IMAGES}
                        />
                      </div>
                      <div className="d-flex justify-content-end ">
                        {elem?.videoDetails?.length ? (
                          <IconButton
                            edge="start"
                            color="inherit"
                            style={{ marginLeft: "5px" }}
                            onClick={() => {
                              getVideosList({
                                id: elem?.videoId,
                                isChild: true,
                              });
                              setIdCopy(elem?.videoId);
                              setIds((prev) => {
                                return {
                                  ...prev,
                                  [elem?.videoId]: elem?.parentId,
                                };
                              });
                            }}
                          >
                            <Boy />
                          </IconButton>
                        ) : (
                          ""
                        )}
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleEditImagesParent(
                              elem.videoId,
                              elem.parentId ? true : false,
                              true
                            );
                            setIsChildForFormSubmit(true);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() =>
                            handleDelete(
                              elem.videoId,
                              false,
                              childData.length == 1 ? true : false
                            )
                          }
                        >
                          <Trash />
                        </IconButton>
                        {/* <IconButton
                        edge="start"
                        color="inherit"
                        style={{ marginLeft: "5px" }}
                        onClick={() => {
                          handleChangeSettings(elem.videoId);
                        }}
                      >
                        <Settings />
                      </IconButton> */}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              );
            })
          )}
        </Row>
      </div>
    </div>
  );
};

export default ChildShow;
