import React, { useState, useContext } from "react";
// import man from "../assets/images/dashboard/profile.jpg";
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  Label,
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";
import jwtImg from "../assets/images/jwt.svg";

import {
  JWT,
  // LoginWithJWT,
  ENDPOINT_IMAGE,
} from "../constant";
import { useNavigate, useParams } from "react-router";
import { resetPassword_ } from "../api/Login Api/LoginApi";
import { SuccessToast, Toast } from "./signinToast";
import { LoaderResponse } from "../Spinner";
import LoaderContext from "../context/LoaderContext/LoaderContext";

const ResetPassword = () => {
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [selected, setSelected] = useState("jwt");
  const history = useNavigate();
  const { loaderShow, setLoaderShow } = useContext(LoaderContext);

  let { token } = useParams();

  const login = async (e) => {
    e.preventDefault();
    setLoaderShow(!loaderShow);
    let fromData = {
      token: token,
      confirmPassword: confirmPassword,
      password: password,
    };
    try {
      const { data } = await resetPassword_(fromData);
      if (data.status === 200) {
        SuccessToast.fire({
          icon: "success",
          title: data.message,
        });
        history(`/${data?.payload?.hwId}`);
        setLoaderShow(false);
      } else {
        Toast.fire({
          icon: "error",
          title: data.message,
        });
        setLoaderShow(false);
      }
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: error.response.data.message,
      });
      setLoaderShow(false);
    }
  };
  return (
    <>
      <Container fluid={true} className="p-0">
        <div style={{ position: "relative" }}>
          <LoaderResponse />
          <Row>
            <Col xs="12">
              <div className="login-card">
                <div>
                  <div>
                    <a className="logo" href="index.html">
                      <img
                        className="img-fluid for-light"
                        src={`${process.env.REACT_APP_BUCKET_URL}${ENDPOINT_IMAGE}`}
                        style={{ height: "50px" }}
                        alt=""
                      />

                      <img
                        className="img-fluid for-dark"
                        src={`${process.env.REACT_APP_BUCKET_URL}${ENDPOINT_IMAGE}`}
                        style={{ height: "50px" }}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="login-main login-tab">
                    <Nav className="border-tab flex-column" tabs>
                      <NavItem className="d-none ">
                        <NavLink
                          className={selected === "jwt" ? "active" : ""}
                          onClick={() => setSelected("jwt")}
                        >
                          <img src={jwtImg} alt="" />
                          <span>{JWT}</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent
                      activeTab={selected}
                      className="content-login"
                      style={{ height: "auto" }}
                    >
                      <TabPane className="fade show" tabId="jwt">
                        <Form className="theme-form" onSubmit={(e) => login(e)}>
                          <h4 className="text-center ">
                            {selected === "firebase"
                              ? "Reset Password"
                              : "Reset Password"}
                          </h4>

                          <div className="mb-3">
                            <Label className="col-form-label">
                              {"Password"}
                            </Label>
                            <Input
                              className="form-control"
                              type="password"
                              onChange={(e) => setPassword(e.target.value)}
                              defaultValue={password}
                              placeholder="Enter a valid Email"
                            />
                          </div>
                          <div className="mb-5">
                            <Label className="col-form-label">
                              {"Confirm Password"}
                            </Label>
                            <Input
                              className="form-control"
                              type="password"
                              onChange={(e) =>
                                setConfirmPassword(e.target.value)
                              }
                              defaultValue={confirmPassword}
                              placeholder="Enter a valid Email"
                            />
                          </div>

                          <div className="login-btn mb-0 mb-3 position-relative text-center">
                            <Button
                              color="primary"
                              type="submit"
                              value="submit"
                            >
                              {"Send"}
                            </Button>
                          </div>
                        </Form>
                      </TabPane>
                    </TabContent>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

// export default withRouter(Logins);
export default ResetPassword;
