import React, { useContext } from "react";
import { Edit, Plus } from "react-feather";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import ModualManagerContext from "../../context/ModuleManagerContext/ModualManagerContext";
import Select from "react-select";
import { Button } from "@material-ui/core";
import { DeleteForever } from "@material-ui/icons";
import { OPTION, Role } from "../../constant";

const OrganizationAddModel = (props) => {
  const {
    onSubmit,
    onMenuOpen,
    onMenuClose,
    handleChange,
    handleInputChange,
    access,
    hanldeRemove,
    handleAdd,
    handleInputChange1,
    handleChange1,
    roleId,
    close,
  } = props;

  const {
    modal,
    setName,
    name,
    togglesubmit,
    dropDownData,
    perentId,
    perentName,
  } = useContext(ModualManagerContext);

  return (
    <>
      <Modal size="lg" isOpen={modal} toggle={() => close()}>
        <ModalHeader toggle={() => close()}>
          {togglesubmit ? (
            <span>Add Modual Master</span>
          ) : (
            <span>
              Edit Modual Master <span> </span>{" "}
              <Edit
                style={{ width: "50px", height: "20px", color: "#7366ff" }}
              />
            </span>
          )}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <h5> Modual Master Details</h5>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={(e) => onSubmit(e)}
                  >
                    <Row>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom01">{"Name"}</Label>
                        <Input
                          className="form-control"
                          name="fristName"
                          type="text"
                          placeholder="Name"
                          id="validationCustom01"
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                          required
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid ORG Name."}
                        </div>
                      </Col>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom01">{"Perent"}</Label>
                        <Select
                          aria-labelledby="aria-label"
                          inputId="aria-example-input"
                          name="aria-live-color"
                          defaultValue={{ value: +perentId, label: perentName }}
                          onMenuOpen={onMenuOpen}
                          onMenuClose={onMenuClose}
                          onChange={handleChange}
                          onInputChange={handleInputChange}
                          options={dropDownData.map((elem, i) => {
                            return {
                              value: elem.id,
                              label: elem.name,
                            };
                          })}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid ORG Name."}
                        </div>
                      </Col>
                    </Row>
                    {access.map((elem, i) => (
                      <div key={elem.id1}>
                        <Row>
                          <Col md="6 mb-4">
                            <Label htmlFor="validationCustom01">
                              {"Method"}
                            </Label>
                            <select
                              className="form-select"
                              name="id"
                              value={elem.id}
                              onChange={(e) => handleInputChange1(i, e)}
                            >
                              <option value="">--select--</option>
                              {OPTION.map((elem) => {
                                return (
                                  <option value={elem.value}>
                                    {elem.name}
                                  </option>
                                );
                              })}
                            </select>
                            <div className="invalid-feedback">
                              {"Please provide a valid ORG Name."}
                            </div>
                          </Col>
                          <Col md="6 mb-4">
                            <Label htmlFor="validationCustom01">{"role"}</Label>
                            <Select
                              aria-labelledby="aria-label"
                              inputId="aria-example-input"
                              defaultValue={roleId}
                              isMulti
                              onMenuOpen={onMenuOpen}
                              onMenuClose={onMenuClose}
                              onChange={(opt) => {
                                handleChange1(opt, i);
                              }}
                              options={Role.map((elem, i) => {
                                return {
                                  value: elem.id,
                                  label: elem.name,
                                };
                              })}
                            />
                          </Col>
                          <Col md="2 mb-4 " style={{ marginTop: "22px" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "12rem",
                              }}
                            >
                              <Button
                                className="form-control btn btn-primary"
                                style={{ color: "white", width: "5rem" }}
                                onClick={(e) => handleAdd(e)}
                              >
                                <Plus />
                              </Button>
                              <Button
                                className="form-control btn btn-danger"
                                style={{ color: "white", width: "5rem" }}
                                disabled={i === 0}
                                onClick={(e) => hanldeRemove(i, e)}
                              >
                                <DeleteForever />
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ))}
                    <Row style={{ paddingTop: "30px" }}>
                      <button
                        className="btn btn-primary"
                        type="submit"
                        value="Submit"
                      >
                        {"Submit"}
                      </button>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default OrganizationAddModel;
