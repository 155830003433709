import React, { useContext } from "react";
import { Col, Container, Input, Label, Row } from "reactstrap";
import AboutUsContext from "../../../context/AboutUsContext/AboutUsContext";
import { AboutUsTheme, aboutUsTheme1, aboutUsTheme2 } from "../../../constant";
import { WebNavBarListNavMapId } from "../apis/AboutApi/AboutUsApi";
import IsShowTitleORNot from "../../../common/isShowTitleORNot";
import { ThemNo, ThemeNo } from "../../../../constant";

const AboutUsParentPopup = (props) => {
  const { simpleValidator } = props;
  const { isEdit, formDataImageParent, setFormDataImageParent, setNavMenuId } =
    useContext(AboutUsContext);

  const changeFormValue = (e) => {
    const { value, name, checked } = e.target;

    setFormDataImageParent((prev) => {
      if (name === "isTitleShow") {
        return {
          ...prev,
          [name]: checked,
        };
      } else if (name === "theme" && value == ThemeNo.ThemeFour) {
        return {
          ...prev,
          [name]: value,
          style: aboutUsTheme2,
        };
      } else if (name === "theme" && value == ThemeNo.ThemeThree) {
        return {
          ...prev,
          [name]: value,
          style: aboutUsTheme1,
        };
      } else if (name === "theme" && value == ThemeNo.ThemeTwo) {
        return {
          ...prev,
          [name]: value,
          style: aboutUsTheme2,
        };
      } else if (name === "theme" && value == ThemeNo.ThemeOne) {
        return {
          ...prev,
          [name]: value,
          style: aboutUsTheme1,
        };
      } else {
        return {
          ...prev,
          [name]: value,
        };
      }
    });
  };
  return (
    <Container className="mb-5">
      <Row>
        <Col sm="12" xl="12">
          <div className="mt-4 d-flex flex-column justify-content-center ">
            <h6>{isEdit ? "Edit" : "Add New"}</h6>
            <Row className="mt-4">
              <Col md="12 mb-3">
                <Label htmlFor="validationCustom03">
                  Title *{" "}
                  <IsShowTitleORNot
                    checked={formDataImageParent?.isTitleShow}
                    onChange={changeFormValue}
                  />{" "}
                </Label>
                <Input
                  className="form-control"
                  name="title"
                  type="text"
                  placeholder="Title"
                  value={formDataImageParent?.title}
                  onChange={(e) => changeFormValue(e)}
                  style={{ borderRadius: "0", color: "black" }}
                />
              </Col>
              <p style={{ color: "red" }}>
                {simpleValidator.current.message(
                  "title",
                  formDataImageParent.title,
                  "required"
                )}
              </p>
              <Col md="12 mb-3">
                <Label htmlFor="validationCustom03">{"Sub Title"}</Label>
                <Input
                  className="form-control"
                  name="subTitle"
                  type="text"
                  placeholder="Sub Title"
                  value={formDataImageParent?.subTitle}
                  onChange={(e) => changeFormValue(e)}
                  style={{ borderRadius: "0", color: "black" }}
                />
              </Col>{" "}
              <Col md="12 mb-3">
                <Label htmlFor="validationCustom03">{"Theme *"}</Label>
                <select
                  name="theme"
                  id="theme"
                  className="form-select"
                  style={{ borderRadius: "0", color: "black" }}
                  value={formDataImageParent?.theme}
                  onChange={(e) => changeFormValue(e)}
                >
                  <option value={""}>--Select--</option>
                  {AboutUsTheme.map((ele) => {
                    return (
                      <option value={ele.theme} key={ele.theme}>
                        {ele.themeName}
                      </option>
                    );
                  })}
                </select>
              </Col>
              <p style={{ color: "red" }}>
                {simpleValidator.current.message(
                  "theme",
                  formDataImageParent.theme,
                  "required"
                )}
              </p>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutUsParentPopup;
