import React, { useContext } from "react";
import { Col, Input, Label, Row } from "reactstrap";
import PersonalPageContext from "../../context/PersonalPageContext/PersonalPageContaxt";
import { maxLengthCheck } from "../../utils/helper";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const SaveContact = () => {
  const { contactDetail, setContactDetail } = useContext(PersonalPageContext);

  const handleContactDetails = (e, i) => {
    const { name, value } = e.target;

    setContactDetail((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  return (
    <>
      <Row>
        <Col md="6 mb-4">
          <Label htmlFor="validationCustom03">{"Name *"}</Label>
          <Input
            className="form-control text-dark"
            name="name"
            id="validationCustom4"
            type="text"
            placeholder="Name"
            value={contactDetail.name}
            onChange={(e) => handleContactDetails(e)}
            required
          />
        </Col>
        <Col md="6 mb-4">
          <Label htmlFor="validationCustom03">{"Company Name *"}</Label>
          <Input
            className="form-control text-dark"
            name="companyName"
            id="validationCustom4"
            type="text"
            placeholder="Company Name"
            value={contactDetail.companyName}
            onChange={(e) => handleContactDetails(e)}
            required
          />
        </Col>
        <Col md="6 mb-4">
          <Label htmlFor="validationCustom03">{"Designation"}</Label>
          <Input
            className="form-control text-dark"
            name="designation"
            id="validationCustom4"
            type="text"
            placeholder="designation"
            value={contactDetail.designation}
            onChange={(e) => handleContactDetails(e)}
          />
        </Col>
        <Col md="6 mb-4">
          <Label htmlFor="validationCustom03">{"Email"}</Label>
          <Input
            className="form-control text-dark"
            name="email"
            id="validationCustom4"
            type="email"
            placeholder="Email"
            value={contactDetail.email}
            onChange={(e) => handleContactDetails(e)}
          />
        </Col>
        <Col md="6 mb-4">
          <Label htmlFor="validationCustom03">{"WebUrl"}</Label>
          <Input
            className="form-control text-dark"
            name="webUrl"
            id="validationCustom4"
            type="text"
            placeholder="webUrl"
            value={contactDetail.webUrl}
            onChange={(e) => handleContactDetails(e)}
          />
        </Col>
        <Col md="3 mb-4">
          <Label htmlFor="validationCustom03">{"Street"}</Label>
          <Input
            className="form-control text-dark"
            name="street"
            id="validationCustom4"
            type="text"
            placeholder="street"
            value={contactDetail.street}
            onChange={(e) => handleContactDetails(e)}
          />
        </Col>
        <Col md="3 mb-4">
          <Label htmlFor="validationCustom03">{"City"}</Label>
          <Input
            className="form-control text-dark"
            name="city"
            id="validationCustom4"
            type="text"
            placeholder="city"
            value={contactDetail.city}
            onChange={(e) => handleContactDetails(e)}
          />
        </Col>
        <Col md="6 mb-4">
          <Label htmlFor="validationCustom03">{"State"}</Label>
          <Input
            className="form-control text-dark"
            name="state"
            id="validationCustom4"
            type="text"
            placeholder="state"
            value={contactDetail.state}
            onChange={(e) => handleContactDetails(e)}
          />
        </Col>
        <Col md="6 mb-4">
          <Label htmlFor="validationCustom03">{"Country"}</Label>

          <Input
            className="form-control text-dark"
            name="country"
            id="validationCustom4"
            type="text"
            placeholder="country"
            value={contactDetail.country}
            onChange={(e) => handleContactDetails(e)}
          />
        </Col>
        <Col md="4 mb-4">
          <Label htmlFor="validationCustom03">{"Phone No."}</Label>
          <div className="d-flex ">
            <PhoneInput
              className="form-control height"
              international
              defaultCountry="IN"
              placeholder="Phone Number "
              value={contactDetail.dialCode2}
              onChange={(e) => {
                setContactDetail((prev) => {
                  return {
                    ...prev,
                    dialCode2: e,
                  };
                });
              }}
            />
            <Input
              className="form-control text-dark"
              name="mobile2"
              id="validationCustom4"
              type="number"
              placeholder="Home Phone No."
              value={contactDetail.mobile2}
              onChange={(e) => handleContactDetails(e)}
              min={0}
              maxLength={10}
              onInput={maxLengthCheck}
            />
          </div>
        </Col>
        <Col md="4 mb-4">
          <Label htmlFor="validationCustom03">{"Work Phone No. *"}</Label>
          <div className="d-flex ">
            <PhoneInput
              className="form-control height"
              international
              defaultCountry="IN"
              placeholder="Enter Phone Number "
              value={contactDetail.dialCode1}
              onChange={(e) => {
                setContactDetail((prev) => {
                  return {
                    ...prev,
                    dialCode1: e,
                  };
                });
              }}
            />
            <Input
              className="form-control text-dark"
              name="mobile1"
              id="validationCustom4"
              type="number"
              placeholder="Work Phone No. *"
              value={contactDetail.mobile1}
              onChange={(e) => handleContactDetails(e)}
              min={0}
              maxLength={10}
              onInput={maxLengthCheck}
              required
            />
          </div>
        </Col>

        <Col md="4 mb-4">
          <Label htmlFor="validationCustom03">{"Land Line No."}</Label>
          <Input
            className="form-control text-dark"
            name="mobile3"
            id="validationCustom4"
            type="number"
            placeholder="Land Line No."
            value={contactDetail.mobile3}
            onChange={(e) => handleContactDetails(e)}
            min={0}
            maxLength={15}
            onInput={maxLengthCheck}
          />
        </Col>
      </Row>
    </>
  );
};

export default SaveContact;
