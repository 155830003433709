import React, { useContext } from "react";
import {  Col, Input, Label, Row } from "reactstrap";
import { Border, fontWeight } from "../../../../constant";
import SliderMultipageContext from "../../../context/SliderMultipageContext/SliderMultipageContext";
import { Switch } from "@material-ui/core";
import CommonColor from "../../../common/CommonColor";
import CustomStyle from "../../../common/CustomStyle";

const SliderThemeOne = (props) => {
  const {
    settingFormData,
    setSettingFormData,
    isBackgroundColor,
    setIsBackgroundColor,
    boxSection,
    setBoxSection,
  } = useContext(SliderMultipageContext);

  /**
   * input value change
   * @param {event} e
   */

  const handleChangeTopSliderContentTitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".top-slider .slider-content .slide-title"]: {
          ...prev[".top-slider .slider-content .slide-title"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeTopSliderContentDesq = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".top-slider .slider-content .slide-desq"]: {
          ...prev[".top-slider .slider-content .slide-desq"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeTopSliderContentBtn = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".top-slider .slider-content .btn"]: {
          ...prev[".top-slider .slider-content .btn"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeTopSliderArrow = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".top-slider .carousel-control-next-icon, .top-slider .carousel-control-prev-icon"]:
          {
            ...prev[
              ".top-slider .carousel-control-next-icon, .top-slider .carousel-control-prev-icon"
            ],
            [name]: value,
          },
      };
    });
  };

  const handleChangeCustomStyle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  return (
    <>
      <Row className="mt-4">
        <h4 className="mb-3">Change Background color in slider</h4>
        <div className="d-flex align-items-center">
          {isBackgroundColor ? (
            <p>{"(Do you want to Remove Background color)"}</p>
          ) : (
            <p>{"(Do you want to Add Background color)"}</p>
          )}
          <Switch
            checked={isBackgroundColor}
            inputProps={{ "aria-label": "controlled" }}
            onChange={(e) => {
              setIsBackgroundColor(e.target.checked);
              setSettingFormData((prev) => {
                if (e.target.checked) {
                  return {
                    ...prev,
                    ".top-slider": {
                      "background-color": "#ffffff",
                    },
                  };
                } else {
                  return {
                    ...prev,
                    ".top-slider": {},
                  };
                }
              });
            }}
          />
        </div>

        {isBackgroundColor && (
          <Col md="4 mb-3">
            <CommonColor
              color={settingFormData?.[".top-slider"]?.["background-color"]}
              setSettingFormData={setSettingFormData}
              className={".top-slider"}
              classProperty={"background-color"}
              label={"Select Background Color"}
            />
          </Col>
        )}
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Change slider before background color</h4>
        <Col md="4 mb-3">
          <div className="d-flex align-items-center">
            {boxSection ? (
              <p>{"(Remove Image Before Background color)"}</p>
            ) : (
              <p>{"(Add Image Before Background color)"}</p>
            )}
            <Switch
              checked={boxSection}
              inputProps={{ "aria-label": "controlled" }}
              onChange={(e) => {
                setBoxSection(e.target.checked);
                setSettingFormData((prev) => {
                  if (e.target.checked) {
                    return {
                      ...prev,
                      ".top-slider .carousel-item:before": {
                        "background-color": {
                          r: 0,
                          g: 0,
                          b: 0,
                          a: 0.8,
                        },
                      },
                    };
                  } else {
                    return {
                      ...prev,
                      ".top-slider .carousel-item:before": {},
                    };
                  }
                });
              }}
            />
          </div>

          {boxSection && (
            <>
              <CommonColor
                color={
                  settingFormData?.[".top-slider .carousel-item:before"]?.[
                    "background-color"
                  ]
                }
                setSettingFormData={setSettingFormData}
                className={".top-slider .carousel-item:before"}
                classProperty={"background-color"}
                label={"Select Background Color"}
              />
            </>
          )}
        </Col>
      </Row>
      <hr />

      <Row className="mt-4">
        <h4 className="mb-3">Make change in slider title</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".top-slider .slider-content .slide-title"]?.[
                "color"
              ]
            }
            setSettingFormData={setSettingFormData}
            className={".top-slider .slider-content .slide-title"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="2 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 40)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData[".top-slider .slider-content .slide-title"][
              "font-size"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTopSliderContentTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-control"
            value={
              settingFormData[".top-slider .slider-content .slide-title"][
                "font-weight"
              ]
            }
            onChange={(e) => handleChangeTopSliderContentTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin bottom (Default : 20)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin bottom"
            value={settingFormData[".top-slider .slider-content .slide-title"][
              "margin-bottom"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTopSliderContentTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in slider description</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".top-slider .slider-content .slide-desq"]?.[
                "color"
              ]
            }
            setSettingFormData={setSettingFormData}
            className={".top-slider .slider-content .slide-desq"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 16)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData[".top-slider .slider-content .slide-desq"][
              "font-size"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTopSliderContentDesq(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 500)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-control"
            value={
              settingFormData[".top-slider .slider-content .slide-desq"][
                "font-weight"
              ]
            }
            onChange={(e) => handleChangeTopSliderContentDesq(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin bottom (Default : 20)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin bottom"
            value={settingFormData[".top-slider .slider-content .slide-desq"][
              "margin-bottom"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTopSliderContentDesq(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <h4 className="mb-3">Make change in slider content button</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".top-slider .slider-content .btn"]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".top-slider .slider-content .btn"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border (Default : 2 solid pink)"}
          </Label>
          <div className="d-flex">
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="borderPx"
                type="number"
                placeholder="px"
                value={settingFormData[".top-slider .slider-content .btn"][
                  "borderPx"
                ]?.replace(/px/g, "")}
                onChange={(e) => handleChangeTopSliderContentBtn(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <select
                name="borderThik"
                id="borderThik"
                className="form-select"
                value={
                  settingFormData[".top-slider .slider-content .btn"][
                    "borderThik"
                  ]
                }
                onChange={(e) => handleChangeTopSliderContentBtn(e)}
                style={{ borderRadius: "0", color: "black" }}
              >
                <option value="">--select--</option>
                {Border.map((elem) => {
                  return <option value={elem.border}>{elem.border}</option>;
                })}
              </select>
            </div>
            <div>
              <CommonColor
                color={
                  settingFormData?.[".top-slider .slider-content .btn"]?.[
                    "borderColor"
                  ]
                }
                setSettingFormData={setSettingFormData}
                className={".top-slider .slider-content .btn"}
                classProperty={"borderColor"}
                label={"Select Color"}
              />
            </div>
          </div>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 16)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData[".top-slider .slider-content .btn"][
              "font-size"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTopSliderContentBtn(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-control"
            value={
              settingFormData[".top-slider .slider-content .btn"]["font-weight"]
            }
            onChange={(e) => handleChangeTopSliderContentBtn(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 8 - 25)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="p1"
              type="number"
              placeholder="Padding"
              value={settingFormData[".top-slider .slider-content .btn"][
                "p1"
              ]?.replace(/px/g, "")}
              onChange={(e) => handleChangeTopSliderContentBtn(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p2"
              type="number"
              placeholder="Padding"
              value={settingFormData[".top-slider .slider-content .btn"][
                "p2"
              ]?.replace(/px/g, "")}
              onChange={(e) => handleChangeTopSliderContentBtn(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin (Default : 10 10 10)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="m1"
              type="number"
              placeholder="margin"
              value={settingFormData[".top-slider .slider-content .btn"][
                "m1"
              ]?.replace(/px/g, "")}
              onChange={(e) => handleChangeTopSliderContentBtn(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="m2"
              type="number"
              placeholder="margin"
              value={settingFormData[".top-slider .slider-content .btn"][
                "m2"
              ]?.replace(/px/g, "")}
              onChange={(e) => handleChangeTopSliderContentBtn(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="m3"
              type="number"
              placeholder="margin"
              value={settingFormData[".top-slider .slider-content .btn"][
                "m3"
              ]?.replace(/px/g, "")}
              onChange={(e) => handleChangeTopSliderContentBtn(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Change slider button background color</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".top-slider .slider-content .slide-btn1"]?.[
                "background-color"
              ]
            }
            setSettingFormData={setSettingFormData}
            className={".top-slider .slider-content .slide-btn1"}
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
      </Row>
      <Row>
        <h4 className="mb-3">Make change in slider Arrow</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".top-slider .carousel-control-next-icon, .top-slider .carousel-control-prev-icon"
              ]?.["background-color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".top-slider .carousel-control-next-icon, .top-slider .carousel-control-prev-icon"
            }
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"border-radius (Default : 0)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="Font Size"
            value={settingFormData[
              ".top-slider .carousel-control-next-icon, .top-slider .carousel-control-prev-icon"
            ]["border-radius"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTopSliderArrow(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <CustomStyle
        handleChange={handleChangeCustomStyle}
        value={settingFormData?.custom}
      />
    </>
  );
};

export default SliderThemeOne;
