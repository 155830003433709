import React, { useContext } from "react";
import { Add } from "@material-ui/icons";
import { DataGrid } from "@mui/x-data-grid";
import { Card, CardHeader, Col, Container, Row } from "reactstrap";
import { IconButton, Tooltip } from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import Switch from "react-js-switch";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import { TostMessage } from "../../components ByteCard/utils";
import DataNotFound from "../../DataNoteFound/DataNotFound";
import { MuiTabelStyle, headerStyle } from "../../utils/MuiTabelStyle";
import { TabelAnimation } from "../../Spinner";
import PlanMasterContext from "../../context/PlanMasterContext/PlanMasterContext";
import { Details_PlanMaster_List } from "../../api/PlanMaster Api/PlanMasterApi";
import { PLAN_MASTER, Style } from "../../constant";

const PlanMasterItem = (props) => {
  const { sorting, switch_onChange_handle, handleDelete } = props;
  const { setLoaderShow } = useContext(LoaderContext);
  const {
    formValue,
    record,
    pageSize,
    page,
    setPageSize,
    setPage,
    setAddModel,
    setFormData,
    setIsEdit,
    setId,
    setIsMonth,
  } = useContext(PlanMasterContext);

  const EditHandle = async (planMasterId) => {
    try {
      setLoaderShow(true);
      setId(planMasterId);
      const { data } = await Details_PlanMaster_List(planMasterId);
      if (data.status === 200) {
        let payload = data.payload.data;
        // const subscriptionAll = payload?.pageItemThemeMapDetails?.map((ele) => {
        //   return {
        //     value: ele.itemId,
        //     label: ele.itemMasterDetail?.label,
        //   };
        // });
        if (payload?.validityMonth) {
          setIsMonth(true);
        } else if (payload?.validityDay) {
          setIsMonth(false);
        }
        setFormData({
          planName: payload?.planName || "",
          amount: payload?.amount || "",
          solutionIds: payload?.solutionIds || "",
          type: payload?.type || "",
          validityMonth: payload?.validityMonth || "",
          validityDay: payload?.validityDay || "",
          promoCode: payload?.promoCode || "",
        });
        setIsEdit(true);
        setLoaderShow(false);
        setAddModel(true);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 200,
      field: "planName",
      headerName: "Plan Name",
      renderCell: (params) => (params.row.planName ? params.row.planName : "-"),
    },
    {
      flex: 0.1,
      minWidth: 200,
      field: "promoCode",
      headerName: "Promo Code",
      renderCell: (params) =>
        params.row.promoCode ? params.row.promoCode : "-",
    },
    {
      flex: 0.1,
      minWidth: 200,
      field: "validityMonth",
      headerName: "Validity Month",
      renderCell: (params) =>
        params.row.validityMonth ? params.row.validityMonth : "-",
    },
    {
      flex: 0.1,
      minWidth: 200,
      field: "validityDay",
      headerName: "Validity Day",
      renderCell: (params) =>
        params.row.validityDay ? params.row.validityDay : "-",
    },
    {
      flex: 0.1,
      minWidth: 200,
      field: "amount",
      headerName: "Amount",
      renderCell: (params) => (params.row.amount ? params.row.amount : "-"),
    },
    {
      flex: 0.1,
      minWidth: 200,
      field: "type",
      headerName: "Type",
      renderCell: (params) => {
        let typeName;
        let textColor;

        if (params.row.type === 1) {
          typeName = "Organization";
          textColor = "blue";
        } else if (params.row.type === 2) {
          typeName = "Reseller";
          textColor = "red";
        } else {
          typeName = "-";
          textColor = "black";
        }

        return <p style={{ color: textColor }}>{typeName}</p>;
      },
    },
    {
      field: "isActive",
      headerName: "Status",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Switch
          value={params.row.isActive ? true : false}
          onChange={() =>
            switch_onChange_handle(params.row.planMasterId, params.row.isActive)
          }
        />
      ),
    },
    {
      flex: 0.01,
      minWidth: 100,
      field: "edit",
      headerName: "Edit",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Tooltip title="Edit">
          <IconButton
            aria-label="edit"
            style={{ color: "green" }}
            onClick={() => EditHandle(params.row.planMasterId)}
          >
            <ModeEditIcon />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      flex: 0.01,
      minWidth: 100,
      field: "delete",
      headerName: "Delete",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Tooltip title="Delete">
          <IconButton
            aria-label="delete"
            style={{ color: "red" }}
            onClick={() => handleDelete(params.row.planMasterId)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  const AddProducts = () => {
    setAddModel(true);
  };
  return (
    <>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <h3 style={headerStyle}>{PLAN_MASTER?.PLAN_MASTER_NAME}</h3>

                <div
                  aria-label="add"
                  onClick={() => AddProducts()}
                  className="btn btn-primary"
                  style={Style}
                >
                  <Add />
                </div>
              </CardHeader>
              {TabelAnimation === true ? (
                <TabelAnimation />
              ) : formValue.length ? (
                <DataGrid
                  autoHeight
                  sx={MuiTabelStyle}
                  pagination
                  rows={formValue}
                  getRowId={(row) => row.planMasterId}
                  columns={defaultColumns}
                  rowsPerPageOptions={[7, 10, 25, 50]}
                  rowCount={record}
                  pageSize={pageSize}
                  page={page - 1}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  onPageChange={(newPageNo) => setPage(newPageNo + 1)}
                  paginationMode="server"
                  disableSelectionOnClick
                  checkboxSelection={false}
                  sortingMode="server"
                  onSortModelChange={sorting}
                />
              ) : (
                <DataNotFound />
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PlanMasterItem;
