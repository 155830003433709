import {
  addUrlImage,
  convertRGBA,
  removeUrlImage,
  removeUrlKeyInPath,
  rgbColorCreate,
} from "../../../../utils/helper";

export const ProductStyleOneReset = {
  ".products-section": {
    padding: "100px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".products-section.bg-image": {},
  ".products-section.bg-image::before": {
    // "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
  },
  ".products-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".products-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: { r: 255, g: 73, b: 124, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 0.2 },
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".products-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: { r: 0, g: 0, b: 0, a: 100 },
  },
  ".products-section .product-catlist": {
    "text-align": "center",
    "margin-bottom": "30px",
  },
  ".products-section .product-catlist .btn": {
    border: "2px solid { r: 255, g: 73, b: 124, a: 100 }",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 73, b: 124, a: 100 },
    padding: "6px 20px",
    p1: "6px",
    p2: "20px",
    margin: "5px",
    "box-shadow": "none",
  },
  ".products-section .product-catlist .active": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".products-section .product-catdata .box": {
    "margin-bottom": "15px",
    outline: "none",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
    "box-shadow": "0 5px 10px 0 {r: 7, g: 28, b: 31, a: 0.098 }",
    bs1: "0px",
    bs2: "5px",
    bs3: "10px",
    bs4: "0px",
    shedowColor: { r: 7, g: 28, b: 31, a: 0.098 },
    "border-radius": "0px",
  },
  ".products-section .product-catdata .product-img .taglabel": {
    "background-color": { r: 0, g: 138, b: 73, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "14px",
    "font-weight": "400",
    padding: "8px 15px",
    p1: "8px",
    p2: "15px",
    "border-radius": "5px",
    top: "10px",
    left: "10px",
  },
  ".products-section .product-catdata .product-img .post-date": {
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
    "border-radius": "5px",
    color: { r: 255, g: 73, b: 124, a: 100 },
    "font-size": "14px",
    "font-weight": "400",
    padding: "8px 15px",
    p1: "8px",
    p2: "15px",
    "text-align": "center",
    top: "10px",
    right: "10px",
  },
  ".products-section .product-catdata .product-content": {
    padding: "20px",
    float: "left",
    width: "100%",
    "text-align": "center",
  },
  ".products-section .product-catdata .product-content .shop a": {
    color: { r: 0, g: 0, b: 0, a: 100 },
    "font-size": "20px",
  },
  ".products-section .product-catdata .product-content .shop a:hover": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".products-section .product-catdata .product-content .price": {
    color: { r: 51, g: 51, b: 51, a: 100 },
    margin: "15px 5px 10px 5px",
    m1: "15px",
    m2: "5px",
    m3: "10px",
    m4: "5px",
  },
  ".products-section .product-catdata .product-content .price del": {
    color: { r: 153, g: 153, b: 153, a: 100 },
    "margin-right": "15px",
  },
  ".products-section .product-catdata .product-content .price ins": {
    "text-decoration": "none",
    color: { r: 0, g: 0, b: 0, a: 100 },
  },
  ".products-section .product-catdata .product-content .add-cart": {
    border: "2px solid { r: 255, g: 73, b: 124, a: 100 }",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: { r: 255, g: 73, b: 124, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "14px",
    "font-weight": "500",
    padding: "4px 15px",
    p1: "4px",
    p2: "15px",
    "box-shadow": "none",
  },
  ".products-section .product-catdata .product-content .add-cart:hover": {
    opacity: "0.8",
  },
  ".products-section .more-btn": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".products-section .more-btn:hover": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".pagination .page-item.active .page-link": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    "border-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  custom: "",
};

export const ProductThemeOneFunction = (payload) => {
  return {
    ".products-section": {
      padding: payload.style?.[".products-section"]?.["padding"],
      width: payload.style?.[".products-section"]?.["width"]?.replace(
        "calc(100% - ",
        ""
      ),
      "background-color": payload.style?.[".products-section"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".products-section"]?.["background-color"],
          })
        : "",
      margin: payload.style?.[".products-section"]?.["margin"],
      "border-radius": payload.style?.[".products-section"]?.["border-radius"],
    },
    ".products-section.bg-image": {
      "background-image": payload.style?.[".products-section.bg-image"]?.[
        "background-image"
      ]
        ? removeUrlKeyInPath({
            urlString:
              payload.style?.[".products-section.bg-image"]?.[
                "background-image"
              ],
          })
        : "",
    },
    ".products-section.bg-image::before": {
      "background-color": payload.style?.[
        ".products-section.bg-image::before"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".products-section.bg-image::before"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".products-section .section-title-area": {
      "margin-bottom":
        payload.style?.[".products-section .section-title-area"]?.[
          "margin-bottom"
        ],
    },
    ".products-section .section-title-area .section-subtitle": {
      "font-size":
        payload.style?.[
          ".products-section .section-title-area .section-subtitle"
        ]?.["font-size"],
      "font-weight":
        payload.style?.[
          ".products-section .section-title-area .section-subtitle"
        ]?.["font-weight"],
      color: payload.style?.[
        ".products-section .section-title-area .section-subtitle"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".products-section .section-title-area .section-subtitle"
              ]?.["color"],
          })
        : "",
      "background-color": payload.style?.[
        ".products-section .section-title-area .section-subtitle"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".products-section .section-title-area .section-subtitle"
              ]?.["background-color"],
          })
        : "",
      padding:
        payload.style?.[
          ".products-section .section-title-area .section-subtitle"
        ]?.["padding"],
      p1: payload.style?.[
        ".products-section .section-title-area .section-subtitle"
      ]?.["padding"]?.split(" ")[0],
      p2: payload.style?.[
        ".products-section .section-title-area .section-subtitle"
      ]?.["padding"]?.split(" ")[1],
      "border-radius":
        payload.style?.[
          ".products-section .section-title-area .section-subtitle"
        ]?.["border-radius"],
      "margin-bottom":
        payload.style?.[
          ".products-section .section-title-area .section-subtitle"
        ]?.["margin-bottom"],
    },
    ".products-section .section-title-area .section-title": {
      "font-size":
        payload.style?.[
          ".products-section .section-title-area .section-title"
        ]?.["font-size"],
      "font-weight":
        payload.style?.[
          ".products-section .section-title-area .section-title"
        ]?.["font-weight"],
      color: payload.style?.[
        ".products-section .section-title-area .section-title"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".products-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".products-section .product-catlist": {
      "text-align":
        payload.style?.[".products-section .product-catlist"]?.["text-align"],
      "margin-bottom":
        payload.style?.[".products-section .product-catlist"]?.[
          "margin-bottom"
        ],
    },
    ".products-section .product-catlist .btn": {
      border:
        payload.style?.[".products-section .product-catlist .btn"]?.["border"],
      borderPx:
        payload.style?.[".products-section .product-catlist .btn"]?.[
          "border"
        ]?.split(" ")[0],
      borderThik:
        payload.style?.[".products-section .product-catlist .btn"]?.[
          "border"
        ]?.split(" ")[1],
      borderColor: payload.style?.[".products-section .product-catlist .btn"]?.[
        "borderColor"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".products-section .product-catlist .btn"]?.[
                "borderColor"
              ],
          })
        : "",
      color: payload.style?.[".products-section .product-catlist .btn"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".products-section .product-catlist .btn"]?.[
                "color"
              ],
          })
        : "",
      padding:
        payload.style?.[".products-section .product-catlist .btn"]?.["padding"],
      p1: payload.style?.[".products-section .product-catlist .btn"]?.[
        "padding"
      ]?.split(" ")[0],
      p2: payload.style?.[".products-section .product-catlist .btn"]?.[
        "padding"
      ]?.split(" ")[1],
      margin:
        payload.style?.[".products-section .product-catlist .btn"]?.["margin"],
      "box-shadow":
        payload.style?.[".products-section .product-catlist .btn"]?.[
          "box-shadow"
        ],
    },
    ".products-section .product-catlist .active": {
      "background-color": payload.style?.[
        ".products-section .product-catlist .active"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".products-section .product-catlist .active"]?.[
                "background-color"
              ],
          })
        : "",
      color: payload.style?.[".products-section .product-catlist .active"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".products-section .product-catlist .active"]?.[
                "color"
              ],
          })
        : "",
    },
    ".products-section .product-catdata .box": {
      "margin-bottom":
        payload.style?.[".products-section .product-catdata .box"]?.[
          "margin-bottom"
        ],
      outline:
        payload.style?.[".products-section .product-catdata .box"]?.["outline"],
      "background-color": payload.style?.[
        ".products-section .product-catdata .box"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".products-section .product-catdata .box"]?.[
                "background-color"
              ],
          })
        : "",
      "box-shadow":
        payload.style?.[".products-section .product-catdata .box"]?.[
          "box-shadow"
        ],
      bs1: payload.style?.[".products-section .product-catdata .box"]?.[
        "box-shadow"
      ]?.split(" ")[0],
      bs2: payload.style?.[".products-section .product-catdata .box"]?.[
        "box-shadow"
      ]?.split(" ")[1],
      bs3: payload.style?.[".products-section .product-catdata .box"]?.[
        "box-shadow"
      ]?.split(" ")[2],
      bs4: payload.style?.[".products-section .product-catdata .box"]?.[
        "box-shadow"
      ]?.split(" ")[3],
      shedowColor: payload.style?.[".products-section .product-catdata .box"]?.[
        "shedowColor"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".products-section .product-catdata .box"]?.[
                "shedowColor"
              ],
          })
        : "",
      "border-radius":
        payload.style?.[".products-section .product-catdata .box"]?.[
          "border-radius"
        ],
    },
    ".products-section .product-catdata .product-img .taglabel": {
      "background-color": payload.style?.[
        ".products-section .product-catdata .product-img .taglabel"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".products-section .product-catdata .product-img .taglabel"
              ]?.["background-color"],
          })
        : "",
      color: payload.style?.[
        ".products-section .product-catdata .product-img .taglabel"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".products-section .product-catdata .product-img .taglabel"
              ]?.["color"],
          })
        : "",
      "font-size":
        payload.style?.[
          ".products-section .product-catdata .product-img .taglabel"
        ]?.["font-size"],
      "font-weight":
        payload.style?.[
          ".products-section .product-catdata .product-img .taglabel"
        ]?.["font-weight"],
      padding:
        payload.style?.[
          ".products-section .product-catdata .product-img .taglabel"
        ]?.["padding"],
      p1: payload.style?.[
        ".products-section .product-catdata .product-img .taglabel"
      ]?.["padding"]?.split(" ")[0],
      p2: payload.style?.[
        ".products-section .product-catdata .product-img .taglabel"
      ]?.["padding"]?.split(" ")[1],
      "border-radius":
        payload.style?.[
          ".products-section .product-catdata .product-img .taglabel"
        ]?.["border-radius"],
      left: payload.style?.[
        ".products-section .product-catdata .product-img .taglabel"
      ]?.["left"],
    },
    ".products-section .product-catdata .product-img .post-date": {
      "background-color": payload.style?.[
        ".products-section .product-catdata .product-img .post-date"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".products-section .product-catdata .product-img .post-date"
              ]?.["background-color"],
          })
        : "",
      color: payload.style?.[
        ".products-section .product-catdata .product-img .post-date"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".products-section .product-catdata .product-img .post-date"
              ]?.["color"],
          })
        : "",
      "border-radius":
        payload.style?.[
          ".products-section .product-catdata .product-img .post-date"
        ]?.["border-radius"],
      "font-size":
        payload.style?.[
          ".products-section .product-catdata .product-img .post-date"
        ]?.["font-size"],
      "font-weight":
        payload.style?.[
          ".products-section .product-catdata .product-img .post-date"
        ]?.["font-weight"],
      padding:
        payload.style?.[
          ".products-section .product-catdata .product-img .post-date"
        ]?.["padding"],
      p1: payload.style?.[
        ".products-section .product-catdata .product-img .post-date"
      ]?.["padding"]?.split(" ")[0],
      p2: payload.style?.[
        ".products-section .product-catdata .product-img .post-date"
      ]?.["padding"]?.split(" ")[1],
      "text-align":
        payload.style?.[
          ".products-section .product-catdata .product-img .post-date"
        ]?.["text-align"],
      top: payload.style?.[
        ".products-section .product-catdata .product-img .post-date"
      ]?.["top"],
      right:
        payload.style?.[
          ".products-section .product-catdata .product-img .post-date"
        ]?.["right"],
    },
    ".products-section .product-catdata .product-content": {
      padding:
        payload.style?.[
          ".products-section .product-catdata .product-content"
        ]?.["padding"],
      float:
        payload.style?.[
          ".products-section .product-catdata .product-content"
        ]?.["float"],
      width:
        payload.style?.[
          ".products-section .product-catdata .product-content"
        ]?.["width"],
      "text-align":
        payload.style?.[
          ".products-section .product-catdata .product-content"
        ]?.["text-align"],
    },
    ".products-section .product-catdata .product-content .shop a": {
      color: payload.style?.[
        ".products-section .product-catdata .product-content .shop a"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".products-section .product-catdata .product-content .shop a"
              ]?.["color"],
          })
        : "",
      "font-size":
        payload.style?.[
          ".products-section .product-catdata .product-content .shop a"
        ]?.["font-size"],
    },
    ".products-section .product-catdata .product-content .shop a:hover": {
      color: payload.style?.[
        ".products-section .product-catdata .product-content .shop a:hover"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".products-section .product-catdata .product-content .shop a:hover"
              ]?.["color"],
          })
        : "",
    },
    ".products-section .product-catdata .product-content .price": {
      color: payload.style?.[
        ".products-section .product-catdata .product-content .price"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".products-section .product-catdata .product-content .price"
              ]?.["color"],
          })
        : "",
      margin:
        payload.style?.[
          ".products-section .product-catdata .product-content .price"
        ]?.["margin"],
      m1: payload.style?.[
        ".products-section .product-catdata .product-content .price"
      ]?.["margin"]?.split(" ")[0],
      m2: payload.style?.[
        ".products-section .product-catdata .product-content .price"
      ]?.["margin"]?.split(" ")[1],
      m3: payload.style?.[
        ".products-section .product-catdata .product-content .price"
      ]?.["margin"]?.split(" ")[2],
      m4: payload.style?.[
        ".products-section .product-catdata .product-content .price"
      ]?.["margin"]?.split(" ")[3],
    },
    ".products-section .product-catdata .product-content .price del": {
      color: payload.style?.[
        ".products-section .product-catdata .product-content .price del"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".products-section .product-catdata .product-content .price del"
              ]?.["color"],
          })
        : "",
      "margin-right":
        payload.style?.[
          ".products-section .product-catdata .product-content .price del"
        ]?.["margin-right"],
    },
    ".products-section .product-catdata .product-content .price ins": {
      "text-decoration":
        payload.style?.[
          ".products-section .product-catdata .product-content .price ins"
        ]?.["text-decoration"],
      color: payload.style?.[
        ".products-section .product-catdata .product-content .price ins"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".products-section .product-catdata .product-content .price ins"
              ]?.["color"],
          })
        : "",
    },
    ".products-section .product-catdata .product-content .add-cart": {
      border:
        payload.style?.[
          ".products-section .product-catdata .product-content .add-cart"
        ]?.["border"],
      borderPx:
        payload.style?.[
          ".products-section .product-catdata .product-content .add-cart"
        ]?.["border"]?.split(" ")[0],
      borderThik:
        payload.style?.[
          ".products-section .product-catdata .product-content .add-cart"
        ]?.["border"]?.split(" ")[1],
      borderColor: payload.style?.[
        ".products-section .product-catdata .product-content .add-cart"
      ]?.["borderColor"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".products-section .product-catdata .product-content .add-cart"
              ]?.["borderColor"],
          })
        : "",
      "background-color": payload.style?.[
        ".products-section .product-catdata .product-content .add-cart"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".products-section .product-catdata .product-content .add-cart"
              ]?.["background-color"],
          })
        : "",
      color: payload.style?.[
        ".products-section .product-catdata .product-content .add-cart"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".products-section .product-catdata .product-content .add-cart"
              ]?.["color"],
          })
        : "",
      "font-size":
        payload.style?.[
          ".products-section .product-catdata .product-content .add-cart"
        ]?.["font-size"],
      "font-weight":
        payload.style?.[
          ".products-section .product-catdata .product-content .add-cart"
        ]?.["font-weight"],
      padding:
        payload.style?.[
          ".products-section .product-catdata .product-content .add-cart"
        ]?.["padding"],
      p1: payload.style?.[
        ".products-section .product-catdata .product-content .add-cart"
      ]?.["padding"]?.split(" ")[0],
      p2: payload.style?.[
        ".products-section .product-catdata .product-content .add-cart"
      ]?.["padding"]?.split(" ")[0],
      "box-shadow":
        payload.style?.[
          ".products-section .product-catdata .product-content .add-cart"
        ]?.["box-shadow"],
    },
    ".products-section .product-catdata .product-content .add-cart:hover": {
      opacity: "0.8",
    },

    custom: payload.style?.custom,
  };
};

export const ProductStyleOneSubFun = ({ settingFormData, boxSection }) => {
  let newData = {
    ...settingFormData,
    ".products-section": boxSection
      ? {
          ...settingFormData[".products-section"],
          padding: `${settingFormData[".products-section"]["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
          width: `calc(100% - ${settingFormData[".products-section"][
            "width"
          ]?.replace("rem)", "")}rem)`,
          margin: `${settingFormData[".products-section"]["margin"]
            ?.split(" ")[0]
            ?.replace("px", "")}px auto`,
          "border-radius": `${settingFormData[".products-section"][
            "border-radius"
          ]?.replace("rem", "")}rem`,
        }
      : {
          ...settingFormData[".products-section"],
          padding: `${settingFormData[".products-section"]["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
        },
    ".products-section.bg-image": settingFormData[".products-section.bg-image"][
      "background-image"
    ]
      ? {
          "background-image": settingFormData[".products-section.bg-image"][
            "background-image"
          ]
            ? addUrlImage({
                urlString: removeUrlImage({
                  urlString:
                    settingFormData?.[".products-section.bg-image"]?.[
                      "background-image"
                    ],
                }),
              })
            : "",
        }
      : {},
    ".products-section.bg-image::before": settingFormData[
      ".products-section.bg-image::before"
    ]["background-color"]
      ? {
          "background-color": settingFormData[
            ".products-section.bg-image::before"
          ]["background-color"]
            ? rgbColorCreate({
                rgbString:
                  settingFormData[".products-section.bg-image::before"][
                    "background-color"
                  ],
              })
            : "",
        }
      : {},
    ".products-section .section-title-area": {
      ...settingFormData[".products-section .section-title-area"],
      "margin-bottom": `${settingFormData[
        ".products-section .section-title-area"
      ]["margin-bottom"]?.replace("px", "")}px`,
    },
    ".products-section .section-title-area .section-subtitle": {
      ...settingFormData[
        ".products-section .section-title-area .section-subtitle"
      ],
      "font-size": `${settingFormData[
        ".products-section .section-title-area .section-subtitle"
      ]["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".products-section .section-title-area .section-subtitle"]["font-weight"]}`,

      color: settingFormData[
        ".products-section .section-title-area .section-subtitle"
      ]["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".products-section .section-title-area .section-subtitle"
              ]["color"],
          })
        : "",
      "background-color": settingFormData[
        ".products-section .section-title-area .section-subtitle"
      ]["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".products-section .section-title-area .section-subtitle"
              ]["background-color"],
          })
        : "",
      padding: `${settingFormData[
        ".products-section .section-title-area .section-subtitle"
      ]["p1"]?.replace("px", "")}px ${settingFormData[
        ".products-section .section-title-area .section-subtitle"
      ]["p2"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData[
        ".products-section .section-title-area .section-subtitle"
      ]["border-radius"]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData[
        ".products-section .section-title-area .section-subtitle"
      ]["margin-bottom"]?.replace("px", "")}px`,
    },
    ".products-section .section-title-area .section-title": {
      ...settingFormData[
        ".products-section .section-title-area .section-title"
      ],
      "font-size": `${settingFormData[
        ".products-section .section-title-area .section-title"
      ]["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".products-section .section-title-area .section-title"]["font-weight"]}`,
      color: settingFormData[
        ".products-section .section-title-area .section-title"
      ]["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".products-section .section-title-area .section-title"
              ]["color"],
          })
        : "",
    },
    ".products-section .product-catlist": {
      ...settingFormData[".products-section .product-catlist"],
      "text-align": `${settingFormData[".products-section .product-catlist"]["text-align"]}`,
      "margin-bottom": `${settingFormData[".products-section .product-catlist"][
        "margin-bottom"
      ]?.replace("px", "")}px`,
    },
    ".products-section .product-catlist .btn": {
      ...settingFormData[".products-section .product-catlist .btn"],
      border: `${settingFormData[".products-section .product-catlist .btn"][
        "borderPx"
      ]?.replace("px", "")}px ${
        settingFormData[".products-section .product-catlist .btn"]["borderThik"]
      } ${rgbColorCreate({
        rgbString:
          settingFormData[".products-section .product-catlist .btn"][
            "borderColor"
          ],
      })}`,
      color: settingFormData[".products-section .product-catlist .btn"]["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".products-section .product-catlist .btn"][
                "color"
              ],
          })
        : "",
      padding: `${settingFormData[".products-section .product-catlist .btn"][
        "p1"
      ]?.replace("px", "")}px ${settingFormData[
        ".products-section .product-catlist .btn"
      ]["p2"]?.replace("px", "")}px`,
      margin: `${settingFormData[".products-section .product-catlist .btn"][
        "margin"
      ]?.replace("px", "")}px`,
      "box-shadow": `${settingFormData[".products-section .product-catlist .btn"]["box-shadow"]}`,
    },
    ".products-section .product-catlist .active": {
      ...settingFormData[".products-section .product-catlist .active"],
      color: settingFormData[".products-section .product-catlist .active"][
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".products-section .product-catlist .active"][
                "color"
              ],
          })
        : "",
      "background-color": settingFormData[
        ".products-section .product-catlist .active"
      ]["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".products-section .product-catlist .active"][
                "background-color"
              ],
          })
        : "",
    },
    ".products-section .product-catdata .box": {
      ...settingFormData[".products-section .product-catdata .box"],
      "margin-bottom": `${settingFormData[
        ".products-section .product-catdata .box"
      ]["margin-bottom"]?.replace("px", "")}px`,
      outline: `${settingFormData[".products-section .product-catdata .box"]["outline"]}`,
      "background-color": settingFormData[
        ".products-section .product-catdata .box"
      ]["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".products-section .product-catdata .box"][
                "background-color"
              ],
          })
        : "",
      "box-shadow": `${settingFormData[
        ".products-section .product-catdata .box"
      ]["bs1"]?.replace("px", "")}px ${settingFormData[
        ".products-section .product-catdata .box"
      ]["bs2"]?.replace("px", "")}px ${settingFormData[
        ".products-section .product-catdata .box"
      ]["bs3"]?.replace("px", "")}px ${settingFormData[
        ".products-section .product-catdata .box"
      ]["bs4"]?.replace("px", "")}px ${rgbColorCreate({
        rgbString:
          settingFormData[".products-section .product-catdata .box"][
            "shedowColor"
          ],
      })}`,
      "border-radius": `${settingFormData[
        ".products-section .product-catdata .box"
      ]["border-radius"]?.replace("px", "")}px`,
      overflow: "hidden",
    },
    ".products-section .product-catdata .product-img .taglabel": {
      ...settingFormData[
        ".products-section .product-catdata .product-img .taglabel"
      ],
      "background-color": settingFormData[
        ".products-section .product-catdata .product-img .taglabel"
      ]["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".products-section .product-catdata .product-img .taglabel"
              ]["background-color"],
          })
        : "",
      color: settingFormData[
        ".products-section .product-catdata .product-img .taglabel"
      ]["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".products-section .product-catdata .product-img .taglabel"
              ]["color"],
          })
        : "",
      "font-size": `${settingFormData[
        ".products-section .product-catdata .product-img .taglabel"
      ]["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".products-section .product-catdata .product-img .taglabel"]["font-weight"]}`,
      padding: `${settingFormData[
        ".products-section .product-catdata .product-img .taglabel"
      ]["p1"]?.replace("px", "")}px ${settingFormData[
        ".products-section .product-catdata .product-img .taglabel"
      ]["p2"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData[
        ".products-section .product-catdata .product-img .taglabel"
      ]["border-radius"]?.replace("px", "")}px`,
      top: `${settingFormData[
        ".products-section .product-catdata .product-img .taglabel"
      ]["top"]?.replace("px", "")}px`,
      left: `${settingFormData[
        ".products-section .product-catdata .product-img .taglabel"
      ]["left"]?.replace("px", "")}px`,
    },
    ".products-section .product-catdata .product-img .post-date": {
      ...settingFormData[
        ".products-section .product-catdata .product-img .post-date"
      ],
      "background-color": settingFormData[
        ".products-section .product-catdata .product-img .post-date"
      ]["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".products-section .product-catdata .product-img .post-date"
              ]["background-color"],
          })
        : "",
      color: settingFormData[
        ".products-section .product-catdata .product-img .post-date"
      ]["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".products-section .product-catdata .product-img .post-date"
              ]["color"],
          })
        : "",
      "font-size": `${settingFormData[
        ".products-section .product-catdata .product-img .post-date"
      ]["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".products-section .product-catdata .product-img .post-date"]["font-weight"]}`,
      padding: `${settingFormData[
        ".products-section .product-catdata .product-img .post-date"
      ]["p1"]?.replace("px", "")}px ${settingFormData[
        ".products-section .product-catdata .product-img .post-date"
      ]["p2"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData[
        ".products-section .product-catdata .product-img .post-date"
      ]["border-radius"]?.replace("px", "")}px`,
      top: `${settingFormData[
        ".products-section .product-catdata .product-img .post-date"
      ]["top"]?.replace("px", "")}px`,
      right: `${settingFormData[
        ".products-section .product-catdata .product-img .post-date"
      ]["right"]?.replace("px", "")}px`,
    },
    ".products-section .product-catdata .product-content": {
      ...settingFormData[".products-section .product-catdata .product-content"],
      padding: `${settingFormData[
        ".products-section .product-catdata .product-content"
      ]["padding"]?.replace("px", "")}px`,
      float: `${settingFormData[".products-section .product-catdata .product-content"]["float"]}`,
      width: `${settingFormData[
        ".products-section .product-catdata .product-content"
      ]["width"]?.replace("%", "")}%`,
      "text-align": `${settingFormData[".products-section .product-catdata .product-content"]["text-align"]}`,
    },
    ".products-section .product-catdata .product-content .shop a": {
      ...settingFormData[
        ".products-section .product-catdata .product-content .shop a"
      ],
      color: settingFormData[
        ".products-section .product-catdata .product-content .shop a"
      ]["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".products-section .product-catdata .product-content .shop a"
              ]["color"],
          })
        : "",
      "font-size": `${settingFormData[
        ".products-section .product-catdata .product-content .shop a"
      ]["font-size"]?.replace("px", "")}px`,
    },
    ".products-section .product-catdata .product-content .shop a:hover": {
      color: settingFormData[
        ".products-section .product-catdata .product-content .shop a:hover"
      ]["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".products-section .product-catdata .product-content .shop a:hover"
              ]["color"],
          })
        : "",
    },
    ".products-section .product-catdata .product-content .price": {
      ...settingFormData[
        ".products-section .product-catdata .product-content .price"
      ],
      color: settingFormData[
        ".products-section .product-catdata .product-content .price"
      ]["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".products-section .product-catdata .product-content .price"
              ]["color"],
          })
        : "",
      margin: `${settingFormData[
        ".products-section .product-catdata .product-content .price"
      ]["m1"]?.replace("px", "")}px ${settingFormData[
        ".products-section .product-catdata .product-content .price"
      ]["m2"]?.replace("px", "")}px ${settingFormData[
        ".products-section .product-catdata .product-content .price"
      ]["m3"]?.replace("px", "")}px ${settingFormData[
        ".products-section .product-catdata .product-content .price"
      ]["m4"]?.replace("px", "")}px`,
    },
    ".products-section .product-catdata .product-content .price del": {
      ...settingFormData[
        ".products-section .product-catdata .product-content .price del"
      ],
      color: settingFormData[
        ".products-section .product-catdata .product-content .price del"
      ]["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".products-section .product-catdata .product-content .price del"
              ]["color"],
          })
        : "",
      "margin-right": `${settingFormData[
        ".products-section .product-catdata .product-content .price del"
      ]["margin-right"]?.replace("px", "")}px`,
    },
    ".products-section .product-catdata .product-content .price ins": {
      ...settingFormData[
        ".products-section .product-catdata .product-content .price ins"
      ],
      "text-decoration": `${settingFormData[".products-section .product-catdata .product-content .price ins"]["text-decoration"]}`,
      color: settingFormData[
        ".products-section .product-catdata .product-content .price ins"
      ]["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".products-section .product-catdata .product-content .price ins"
              ]["color"],
          })
        : "",
    },
    ".products-section .product-catdata .product-content .add-cart": {
      ...settingFormData[
        ".products-section .product-catdata .product-content .add-cart"
      ],
      border: `${settingFormData[
        ".products-section .product-catdata .product-content .add-cart"
      ]["borderPx"]?.replace("px", "")}px ${
        settingFormData[
          ".products-section .product-catdata .product-content .add-cart"
        ]["borderThik"]
      } ${rgbColorCreate({
        rgbString:
          settingFormData[
            ".products-section .product-catdata .product-content .add-cart"
          ]["borderColor"],
      })}`,
      color: settingFormData[
        ".products-section .product-catdata .product-content .add-cart"
      ]["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".products-section .product-catdata .product-content .add-cart"
              ]["color"],
          })
        : "",
      "background-color": settingFormData[
        ".products-section .product-catdata .product-content .add-cart"
      ]["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".products-section .product-catdata .product-content .add-cart"
              ]["background-color"],
          })
        : "",
      "font-size": `${settingFormData[
        ".products-section .product-catdata .product-content .add-cart"
      ]["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".products-section .product-catdata .product-content .add-cart"]["font-weight"]}`,
      padding: `${settingFormData[
        ".products-section .product-catdata .product-content .add-cart"
      ]["p1"]?.replace("px", "")}px ${settingFormData[
        ".products-section .product-catdata .product-content .add-cart"
      ]["p2"]?.replace("px", "")}px`,
      "box-shadow": `${settingFormData[".products-section .product-catdata .product-content .add-cart"]["box-shadow"]}`,
    },
    ".products-section .product-catdata .product-content .add-cart:hover": {
      opacity: `${settingFormData[".products-section .product-catdata .product-content .add-cart:hover"]["opacity"]}`,
    },
  };
  return newData;
};
