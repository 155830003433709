import React from "react";
import LoadingBar from "react-top-loading-bar";

const TopLoadingBar = (props) => {
  const { progress, setProgress } = props;
  return (
    <LoadingBar
      color="#f11946"
      height={3}
      progress={progress}
      onLoaderFinished={() => setProgress(0)}
    />
  );
};

export default TopLoadingBar;
