import axios from "axios";
import {
  GET_DETAILS_DOWNLOAD,
  GET_DOWNLOAD,
  POST_DOWNLOAD,
  PUT_DOWNLOAD_ORDER,
  PUT_STATUS_DOWNLOAD,
} from "..";

/**
 * get download data
 * @param {Parameters} paramObj
 * @returns
 */
export const getDownload = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_DOWNLOAD}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * enable-disable
 * @param {body} body
 * @returns
 */
export const updateStatus = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_STATUS_DOWNLOAD}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * create download
 * @param {body} body
 * @returns
 */
export const postDownload = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(`${process.env.REACT_APP_API_URL}${POST_DOWNLOAD}`, body, {
    headers: { Authorization: jwt_token },
  });
};

/**
 * update download
 * @param {body} body
 * @returns
 */
export const putUpdateDownload = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(`${process.env.REACT_APP_API_URL}${POST_DOWNLOAD}`, body, {
    headers: { Authorization: jwt_token },
    params: { downloadId: body.downloadId },
  });
};

/**
 * delete download
 * @param {id} downloadId
 * @returns
 */
export const deleteDownload = (downloadId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${POST_DOWNLOAD}`, {
    headers: { Authorization: jwt_token },
    params: { downloadId },
  });
};

/**
 * get details download
 * @param {id} downloadId
 * @returns
 */
export const getDetailsDownload = (downloadId, navMapId) => {
  const jwt_token = localStorage.getItem("token");
  let paramObj = {
    downloadId,
  };
  if (navMapId) {
    paramObj["navMapId"] = navMapId;
  }
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_DETAILS_DOWNLOAD}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * short order download section
 * @param {body} updateOrder
 * @returns
 */
export const sort_order_Download = (updateOrder) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_DOWNLOAD_ORDER}`,
    { updateOrder: updateOrder },
    {
      headers: { Authorization: jwt_token },
    }
  );
};
