import React, { useContext, useEffect, useState } from "react";
import { DELETE_CONFIRM } from "../../../constant";
import { Col, Container, Input, Label, Row } from "reactstrap";
import { Zoom, toast } from "react-toastify";
import {
  delete_Image_Permanent,
  delete_Multi_Image_Permanent,
} from "../apis/SliderApi/SliderApi";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import Dividers from "../../../common/divider";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import BlogContext from "../../../context/BlogContext/BlogContext";
import { getNavbarList } from "../../../apis/AboutApi/AboutUsApi";
import { slugUrlConverter } from "../../../../utils/helper";
import IsShowTitleORNot from "../../../common/isShowTitleORNot";
import IsViewMoreButtonShow from "../../../common/IsViewMoreButtonShow";
// import isMoreBtnShowOrNot from "../../../common/isMoreBtnShowOrNot";

const BlogPopup = (props) => {
  const { imageUpload, simpleValidator } = props;
  const { setFormDataOurData, formDataOurTeam, isEdit } =
    useContext(BlogContext);
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * input change
   * @param {event} e
   */
  const changeFormValue = (e) => {
    const { name, value, checked } = e.target;
    setFormDataOurData((prevData) => {
      let updatedData;
      if (name === "isTitleShow") {
        updatedData = {
          ...prevData,
          [name]: checked,
        };
      } else {
        updatedData = {
          ...prevData,
          [name]: value,
        };
      }
      if (name === "title" || name === "slugUrl") {
        updatedData.slugUrl = slugUrlConverter({ value: value });
      }
      return updatedData;
    });
  };

  /**
   * image reupload
   */
  const reupload = () => {
    setFormDataOurData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };

  /**
   * delete image permanently
   */
  const deleteImagePermanent = async () => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData = {
          fileName: formDataOurTeam.image
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
            .pop(),
        };
        const { data } = await delete_Multi_Image_Permanent(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            position: "top-center",
          });
          setFormDataOurData({ ...formDataOurTeam, image: "" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };
  return (
    <>
      <Container className="mb-5">
        <Row>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-around",
              marginBottom: "3rem",
            }}
          >
            <div className="mt-4 d-flex flex-column justify-content-center ">
              <h6>{isEdit ? "Edit" : "Add New"}</h6>
              <Row className="mt-4">
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">
                    Title *{" "}
                    <IsShowTitleORNot
                      checked={formDataOurTeam.isTitleShow}
                      onChange={changeFormValue}
                    />{" "}
                  </Label>
                  <Input
                    className="form-control"
                    name="title"
                    type="text"
                    placeholder="Title *"
                    value={formDataOurTeam.title}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "title",
                      formDataOurTeam.title,
                      "required"
                    )}
                  </p>
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Sub Title"}</Label>
                  <Input
                    className="form-control"
                    name="subTitle"
                    type="text"
                    placeholder="Sub Title"
                    value={formDataOurTeam.subTitle}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                  {/* <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "headline",
                      formDataOurTeam.subTitle,
                      "required"
                    )}
                  </p> */}
                </Col>
                {/* <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Theme"}</Label>
                  <select
                    name="theme"
                    id="theme"
                    className="form-select"
                    style={{ borderRadius: "0", color: "black" }}
                    value={formDataOurTeam.theme}
                    onChange={(e) => changeFormValue(e)}
                  >
                    {AboutUsTheme.map((ele) => {
                      return (
                        <option value={ele.theme} key={ele.theme}>
                          {ele.themeName}
                        </option>
                      );
                    })}
                  </select>
                </Col> */}
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Slug url"}</Label>
                  <Input
                    type="text"
                    name="slugUrl"
                    value={formDataOurTeam.slugUrl}
                    className="form-control"
                    style={{ borderRadius: "0", color: "black" }}
                    placeholder="Slug url"
                    onChange={(e) => changeFormValue(e)}
                  />
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"view More Button text"}
                  </Label>
                  <Input
                    className="form-control"
                    name="buttonText"
                    type="text"
                    placeholder="Button text"
                    value={formDataOurTeam?.buttonText}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    required
                  />
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Item Limit"}</Label>
                  <Input
                    className="form-control"
                    name="itemLimit"
                    type="number"
                    placeholder="Item Limit"
                    value={formDataOurTeam?.itemLimit}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    required
                  />
                </Col>
                {/* <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"is View More Button Show"}
                  </Label>
                  <IsViewMoreButtonShow
                    checked={formDataOurTeam?.isMoreBtnShow}
                    onChange={changeFormValue}
                  />
                </Col> */}
              </Row>
            </div>
          </div>
        </Row>
        <ProductImageUploadPopup
          allPopupState={allPopupState}
          setAllPopupState={setAllPopupState}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
          image={formDataOurTeam.image}
          setImage={setFormDataOurData}
          imageUpload={imageUpload}
          isEdit={isEdit}
          reupload={reupload}
          // aspect={1 / 1}
        />
      </Container>
    </>
  );
};

export default BlogPopup;
