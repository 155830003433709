import React, { useContext } from "react";
import ServiceAndCategoryContext from "../../../context/ServiceAndCatagoryContext/ServiceAndCategoryContext";
import ServiceThemeOne from "./ServiceThemeOne";
import ServiceThemeTwo from "./ServiceThemeTwo";
import ServiceThemeThree from "./ServiceThemeThree";
import ServiceThemeFour from "./ServiceThemeFoure";
import {
  THEME_UPDATE_MESSAGE,
  ThemeNo,
  serviceTheme,
} from "../../../../constant";
import ServiceThemeSix from "./ServiceThemeSix";
import ServiceThemeSeven from "./ServiceThemeSeven";
import ServiceThemeEighth from "./ServiceThemeEighth";
import ServiceThemeNine from "./ServiceThemeNine";
import ServiceThemeTen from "./ServiceThemeTen";
import ServiceThemeEleven from "./ServiceThemeEleven";
import ServiceThemeTwelve from "./ServiceThemeTwelve";
import ServiceThemeThirteen from "./ServiceThemeThirteen";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import { TostMessage } from "../../../utils";
import { putUpdateServiceCategory } from "../apis/ServiceAndCategoryApi/ServiceAndCategoryApi";
import { Button, Col, Container, Row } from "reactstrap";
import {
  ServiceCategoryStyleFour,
  ServiceCategoryStyleTwo,
  serviceCategoryStyleEight,
  serviceCategoryStyleEleven,
  serviceCategoryStyleFourteen,
  serviceCategoryStyleNine,
  serviceCategoryStyleOne,
  serviceCategoryStyleSeven,
  serviceCategoryStyleSix,
  serviceCategoryStyleTen,
  serviceCategoryStyleThirteen,
  serviceCategoryStyleThree,
  serviceCategoryStyleTwelve,
} from "./ServiceAndCategoryStyleThemeFunction";
import { ServiceAndCategoryTheme } from "../../../constant";
import ThemeImageShow from "../../../common/ThemeImageShow";
import ServiceCategory1 from "../ScreenShorts/ServiceCategory/ServiceCategory1.png";
import ServiceCategory2 from "../ScreenShorts/ServiceCategory/ServiceCategory2.png";
import ServiceCategory3 from "../ScreenShorts/ServiceCategory/ServiceCategory3.png";
import ServiceCategory4 from "../ScreenShorts/ServiceCategory/ServiceCategory4.png";
import ServiceCategory5 from "../ScreenShorts/ServiceCategory/ServiceCategory5.png";
import ServiceCategory6 from "../ScreenShorts/ServiceCategory/ServiceCategory6.png";
import ServiceCategory7 from "../ScreenShorts/ServiceCategory/ServiceCategory7.png";
import ServiceCategory8 from "../ScreenShorts/ServiceCategory/ServiceCategory8.png";
import ServiceCategory9 from "../ScreenShorts/ServiceCategory/ServiceCategory9.png";
import ServiceCategory10 from "../ScreenShorts/ServiceCategory/ServiceCategory10.png";
import ServiceCategory11 from "../ScreenShorts/ServiceCategory/ServiceCategory11.png";
import ServiceCategory12 from "../ScreenShorts/ServiceCategory/ServiceCategory12.png";
import ServiceCategory13 from "../ScreenShorts/ServiceCategory/ServiceCategory13.png";
import ServiceCategory14 from "../ScreenShorts/ServiceCategory/ServiceCategory14.png";
import ServiceThemeFourteen from "./ServiceThemeFourteen";

const SettingServiceAndCategotyParentPopup = (props) => {
  const { imageUpload, handleSubmitSetting, handleChangeSettings } = props;
  const {
    theme,
    id,
    setBoxSection,
    setTheme,
    setSettingFormData,
    setBackImageToggle,
    setBoxSectionDetail,
    setBackImageToggleDetail,
    setIsBackgroundColor,
    setIsBackgroundColor2,
    imgShow,
    setImgShow,
  } = useContext(ServiceAndCategoryContext);
  const { setLoaderShow } = useContext(LoaderContext);

  const resetHandler = () => {
    let setStyle = {};
    const themeStyles = {
      [ThemeNo.ThemeOne]: serviceCategoryStyleOne,
      [ThemeNo.ThemeTwo]: ServiceCategoryStyleTwo,
      [ThemeNo.ThemeThree]: serviceCategoryStyleThree,
      [ThemeNo.ThemeFour]: ServiceCategoryStyleFour,
      [ThemeNo.ThemeFive]: ServiceCategoryStyleTwo,
      [ThemeNo.ThemeSix]: serviceCategoryStyleSix,
      [ThemeNo.ThemeSeven]: serviceCategoryStyleSeven,
      [ThemeNo.ThemeEight]: serviceCategoryStyleEight,
      [ThemeNo.ThemeNine]: serviceCategoryStyleNine,
      [ThemeNo.ThemeTen]: serviceCategoryStyleTen,
      [ThemeNo.ThemeEleven]: serviceCategoryStyleEleven,
      [ThemeNo.ThemeTwelve]: serviceCategoryStyleTwelve,
      [ThemeNo.ThemeThirteen]: serviceCategoryStyleThirteen,
      [ThemeNo.ThemeFourteen]: serviceCategoryStyleFourteen,
      [ThemeNo.ThemeFifteen]: serviceCategoryStyleFourteen,
    };
    setStyle = themeStyles[theme];
    setSettingFormData({ ...setStyle });
    setBackImageToggle(false);
    setBoxSection(false);
    setBoxSectionDetail(false);
    setBackImageToggleDetail(false);
    setIsBackgroundColor(true);
    setIsBackgroundColor2(true);
  };

  const handleTheme = async (e) => {
    setLoaderShow(true);
    const { value } = e.target;
    setTheme(value);
    try {
      let formData = {
        theme: value,
        serviceCategoryId: id,
      };
      const { data } = await putUpdateServiceCategory(formData);
      if (data.status === 200) {
        await handleChangeSettings(id, true);
        TostMessage({
          message: { message: THEME_UPDATE_MESSAGE },
          type: "success",
        });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    } finally {
      setLoaderShow(false);
    }
  };
  const handleClose = () => {
    setImgShow(false);
  };
  const themeImg = {
    1: ServiceCategory1,
    2: ServiceCategory2,
    3: ServiceCategory3,
    4: ServiceCategory4,
    5: ServiceCategory5,
    6: ServiceCategory6,
    7: ServiceCategory7,
    8: ServiceCategory8,
    9: ServiceCategory9,
    10: ServiceCategory10,
    11: ServiceCategory11,
    12: ServiceCategory12,
    13: ServiceCategory13,
    14: ServiceCategory14,
    // 15: ServiceCategory13,
  };

  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="12">
            <div
              className="mt-4 d-flex flex-column justify-content-center position-relative"
              style={{ top: "50px" }}
            >
              <div
                className="d-flex justify-content-between"
                style={{
                  position: "fixed",
                  top: "70px",
                  background: "white",
                  zIndex: 999,
                  width: "70%", // added padding for better visibility
                }}
              >
                <h6>{"Edit"}</h6>
                <div className="d-flex gap-2">
                  <Button onClick={resetHandler}>Reset</Button>
                  <Button onClick={() => handleSubmitSetting(true)}>
                    Restore
                  </Button>
                  <Button
                    onClick={() => handleSubmitSetting(null, false)}
                    color="success"
                  >
                    Save & View
                  </Button>
                </div>
              </div>
            </div>
            <Row className="pt-2">
              <Col>
                <label htmlFor="">Theme</label>
                <select
                  name="theme"
                  id="font-weight"
                  className="form-select"
                  value={theme}
                  onChange={(e) => handleTheme(e)}
                  style={{ borderRadius: "0", color: "black" }}
                >
                  <option value={""}>---Select---</option>
                  {ServiceAndCategoryTheme.map((themeOption) => (
                    <option key={themeOption.theme} value={themeOption.theme}>
                      {themeOption.themeName}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>
            {!imgShow && (
              <div>
                <Button
                  onClick={() => setImgShow(true)}
                  color="primary"
                  className="mt-2"
                >
                  Show Theme Image
                </Button>
              </div>
            )}
            {imgShow && (
              <ThemeImageShow
                themeImg={themeImg[theme]}
                handleClose={handleClose}
              />
            )}
            {+theme === serviceTheme.theme1 && (
              <ServiceThemeOne
                imageUpload={imageUpload}
                handleSubmitSetting={handleSubmitSetting}
              />
            )}
            {+theme === serviceTheme.theme2 && (
              <ServiceThemeTwo
                imageUpload={imageUpload}
                handleSubmitSetting={handleSubmitSetting}
              />
            )}
            {+theme === serviceTheme.theme3 && (
              <ServiceThemeThree
                imageUpload={imageUpload}
                handleSubmitSetting={handleSubmitSetting}
              />
            )}
            {+theme === serviceTheme.theme4 && (
              <ServiceThemeFour
                imageUpload={imageUpload}
                handleSubmitSetting={handleSubmitSetting}
              />
            )}
            {+theme === serviceTheme.theme5 && (
              <ServiceThemeTwo
                imageUpload={imageUpload}
                handleSubmitSetting={handleSubmitSetting}
              />
            )}
            {+theme === serviceTheme.theme6 && (
              <ServiceThemeSix
                imageUpload={imageUpload}
                handleSubmitSetting={handleSubmitSetting}
              />
            )}
            {+theme === serviceTheme.theme7 && (
              <ServiceThemeSeven
                imageUpload={imageUpload}
                handleSubmitSetting={handleSubmitSetting}
              />
            )}
            {+theme === serviceTheme.theme8 && (
              <ServiceThemeEighth
                imageUpload={imageUpload}
                handleSubmitSetting={handleSubmitSetting}
              />
            )}
            {+theme === serviceTheme.theme9 && (
              <ServiceThemeNine
                imageUpload={imageUpload}
                handleSubmitSetting={handleSubmitSetting}
              />
            )}
            {+theme === serviceTheme.theme10 && (
              <ServiceThemeTen
                imageUpload={imageUpload}
                handleSubmitSetting={handleSubmitSetting}
              />
            )}
            {+theme === serviceTheme.theme11 && (
              <ServiceThemeEleven
                imageUpload={imageUpload}
                handleSubmitSetting={handleSubmitSetting}
              />
            )}
            {+theme === serviceTheme.theme12 && (
              <ServiceThemeTwelve
                imageUpload={imageUpload}
                handleSubmitSetting={handleSubmitSetting}
              />
            )}
            {+theme === serviceTheme.theme13 && (
              <ServiceThemeThirteen
                imageUpload={imageUpload}
                handleSubmitSetting={handleSubmitSetting}
              />
            )}
            {+theme === serviceTheme.theme14 && (
              <ServiceThemeFourteen
                imageUpload={imageUpload}
                handleSubmitSetting={handleSubmitSetting}
              />
            )}
            {+theme === serviceTheme.theme15 && (
              <ServiceThemeFourteen
                imageUpload={imageUpload}
                handleSubmitSetting={handleSubmitSetting}
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SettingServiceAndCategotyParentPopup;
