import React, { useContext } from "react";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import {
  Border,
  Display,
  TextAlign,
  TextTransform,
  fontWeight,
} from "../../../../constant";
import PackagesContext from "../../../context/PackagesContext/PackagesContext";
import { Zoom, toast } from "react-toastify";
import { Switch } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { UploadCloud } from "react-feather";
import { split } from "react-ace";
import { OpenModalCommonFunc } from "../../../utils";
import { Cases, DELETE_CONFIRM } from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import {
  delete_Image_Permanent,
  delete_Multi_Image_Permanent,
} from "../apis/SliderApi/SliderApi";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import { IconButton } from "@material-ui/core";
import Dividers from "../../../common/divider";
import ContactUsMultipageContext from "../../../context/ContactUsMultipageContaxt/ContactUsMultipageContaxt";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import { SketchPicker } from "react-color";
import CommonColor from "../../../common/CommonColor";
import CustomStyle from "../../../common/CustomStyle";

const SettingContactUsParentPopup = (props) => {
  const {
    isEdit,
    settingFormData,
    setSettingFormData,
    backImageToggle,
    setBackImageToggle,
    boxSection,
    setBoxSection,
    isBackgroundColor,
    setIsBackgroundColor,
  } = useContext(ContactUsMultipageContext);
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const { imageUpload, handleSubmitSetting, countOfList } = props;
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * input value change
   * @param {event} e
   */

  const handleChangeContactSection = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [`.contact-section${countOfList}`]: {
          ...prev[`.contact-section${countOfList}${countOfList}`],
          [name]: value,
        },
      };
    });
  };
  const handleChangeContactSectionBgImage = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [`.contact-section${countOfList}.bg-image::before`]: {
          ...prev[`.contact-section${countOfList}.bg-image::before`],
          [name]: value,
        },
      };
    });
  };
  const handleChangeContactTitleArea = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [`.contact-section${countOfList} .section-title-area`]: {
          ...prev[`.contact-section${countOfList} .section-title-area`],
          [name]: value,
        },
      };
    });
  };
  const handleChangeContactTitleAreaSubtitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [`.contact-section${countOfList} .section-title-area .section-subtitle`]:
          {
            ...prev[
              `.contact-section${countOfList} .section-title-area .section-subtitle`
            ],
            [name]: value,
          },
      };
    });
  };
  const handleChangeContactTitleAreaSectionTitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [`.contact-section${countOfList} .section-title-area .section-title`]: {
          ...prev[
            `.contact-section${countOfList} .section-title-area .section-title`
          ],
          [name]: value,
        },
      };
    });
  };
  const handleChangeInquireyBox = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [`.contact-section${countOfList} .inquiry-box`]: {
          ...prev[`.contact-section${countOfList} .inquiry-box`],
          [name]: value,
        },
      };
    });
  };
  const handleChangeInquireyBoxImg = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [`.contact-section${countOfList} .inquiry-box img`]: {
          ...prev[`.contact-section${countOfList} .inquiry-box img`],
          [name]: value,
        },
      };
    });
  };
  const handleChangeInquireyBoxH4 = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [`.contact-section${countOfList} .inquiry-box h4`]: {
          ...prev[`.contact-section${countOfList} .inquiry-box h4`],
          [name]: value,
        },
      };
    });
  };
  const handleChangeInquireyBoxP = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [`.contact-section${countOfList} .inquiry-box p`]: {
          ...prev[`.contact-section${countOfList} .inquiry-box p`],
          [name]: value,
        },
      };
    });
  };
  const handleChangeIContactbox = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [`.contact-section${countOfList} .contactbox`]: {
          ...prev[`.contact-section${countOfList} .contactbox`],
          [name]: value,
        },
      };
    });
  };
  const handleChangeIContactboxIframe = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [`.contact-section${countOfList} .contactbox iframe`]: {
          ...prev[`.contact-section${countOfList} .contactbox iframe`],
          [name]: value,
        },
      };
    });
  };
  const handleChangeCustomStyle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const resetHandler = () => {
    setSettingFormData({
      [`.contact-section${countOfList}`]: {
        padding: "100px 0",
        "background-color": { r: 255, g: 255, b: 255, a: 100 },
      },
      [`.contact-section${countOfList}.bg-image`]: { "background-image": "" },
      [`.contact-section${countOfList}.bg-image::before`]: {
        "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
      },
      [`.contact-section${countOfList} .section-title-area`]: {
        "margin-bottom": "50px",
      },
      [`.contact-section${countOfList} .section-title-area .section-subtitle`]:
        {
          "font-size": "16px",
          "font-weight": "600",
          color: { r: 255, g: 73, b: 124, a: 100 },
          "background-color": { r: 255, g: 73, b: 124, a: 0.2 },
          padding: "5px 20px",
          p1: "5px",
          p2: "20px",
          "border-radius": "15px",
          "margin-bottom": "15px",
          display: "inline-block",
        },
      [`.contact-section${countOfList} .section-title-area .section-title`]: {
        "font-size": "44px",
        "font-weight": "600",
        color: { r: 0, g: 0, b: 0, a: 100 },
      },
      [`.contact-section${countOfList} .inquiry-box`]: {
        "background-color": { r: 255, g: 255, b: 255, a: 100 },
        padding: "40px 40px",
        p1: "40px",
        p2: "40px",
        margin: "10px 0",
        "border-radius": "15px",
        border: "1px solid { r: 255, g: 255, b: 255, a: 100 }",
        borderPx: "1px",
        borderThik: "solid",
        borderColor: { r: 255, g: 255, b: 255, a: 100 },
        "border-top": "3px solid { r: 255, g: 73, b: 124, a: 100 }",
        borderTopPx: "3px",
        borderTopThik: "solid",
        borderTopColor: { r: 255, g: 73, b: 124, a: 100 },
        "box-shadow": "5px 5px 15px { r: 0, g: 0, b: 0, a: 0.094 }",
        bs1: "5px",
        bs2: "5px",
        bs3: "15px",
        shadowColor: { r: 0, g: 0, b: 0, a: 0.094 },
        "text-align": "center",
      },
      [`.contact-section${countOfList} .inquiry-box img`]: {
        "margin-bottom": "20px",
        "max-width": "60px",
      },
      [`.contact-section${countOfList} .inquiry-box h4`]: {
        color: { r: 0, g: 0, b: 0, a: 100 },
        "font-weight": "600",
        "font-size": "22px",
        "margin-bottom": "15px",
      },
      [`.contact-section${countOfList} .inquiry-box p`]: {
        color: { r: 0, g: 0, b: 0, a: 100 },
        "font-weight": "400",
        "font-size": "14px",
        "line-height": "1.6",
      },
      [`.contact-section${countOfList} .contactbox`]: {
        "background-color": { r: 255, g: 255, b: 255, a: 100 },
        padding: "40px 40px",
        p1: "40px",
        p2: "40px",
        "border-radius": "15px",
        border: "1px solid { r: 255, g: 255, b: 255, a: 100 }",
        borderPx: "1px",
        borderThik: "solid",
        borderColor: { r: 255, g: 255, b: 255, a: 100 },
        "border-top": "3px solid { r: 255, g: 73, b: 124, a: 100 }",
        borderTopPx: "3px",
        borderTopThik: "solid",
        borderTopColor: { r: 255, g: 73, b: 124, a: 100 },
        "box-shadow": "5px 5px 15px { r: 0, g: 0, b: 0, a: 0.094 }",
        bs1: "5px",
        bs2: "5px",
        bs3: "15px",
        shadowColor: { r: 0, g: 0, b: 0, a: 0.094 },
      },
      [`.contact-section${countOfList} .contactbox iframe`]: {
        border: "2px solid { r: 221, g: 221, b: 221, a: 100 }",
        borderPx: "2px",
        borderThik: "solid",
        borderColor: { r: 221, g: 221, b: 221, a: 100 },
      },
      custom: "",
      contactUsId: "",
    });
    setBackImageToggle(false);
    setBoxSection(false);
    setIsBackgroundColor(true);
  };

  /**
   * delete image permanently
   */
  const deleteImagePermanent = async () => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData = {
          fileName: settingFormData?.[
            `.contact-section${countOfList}.bg-image`
          ]?.["background-image"]
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
            .pop(),
        };
        const { data } = await delete_Multi_Image_Permanent(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            position: "top-center",
          });
          setSettingFormData({
            ...settingFormData,
            [`.contact-section${countOfList}.bg-image`]: {
              "background-image": "",
            },
          });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };

  /**
   * image reupload
   */
  const reupload = () => {
    setSettingFormData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };

  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="12">
            <div className="mt-4 d-flex flex-column justify-content-center position-relative">
              <div
                className="d-flex justify-content-between position-sticky"
                style={{ top: "100px", background: "white", zIndex: 999 }}
              >
                <h6>{isEdit ? "Edit" : "Add New"}</h6>

                <div className="d-flex gap-2">
                  <Button onClick={resetHandler}>Reset</Button>
                  <Button onClick={() => handleSubmitSetting(true)}>
                    Restore
                  </Button>
                  <Button
                    onClick={() => handleSubmitSetting(null, false)}
                    color="success"
                  >
                    Save & View
                  </Button>
                </div>
              </div>
              <Row className="mt-4">
                <h4 className="mb-3">Make change in Main section</h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding (Default : 100)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="padding"
                    type="number"
                    placeholder="Padding"
                    value={settingFormData?.[
                      [`.contact-section${countOfList}`]
                    ]?.["padding"]
                      ?.split(" ")[0]
                      ?.replace(/px/g, "")}
                    onChange={(e) => handleChangeContactSection(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <div className="d-flex align-items-center">
                    <Label htmlFor="validationCustom03">
                      {isBackgroundColor
                        ? "Add Background Color"
                        : "Remove Background Color"}
                    </Label>
                    <Switch
                      checked={isBackgroundColor}
                      inputProps={{ "aria-label": "controlled" }}
                      onChange={(e) => {
                        setIsBackgroundColor(e.target.checked);
                        setSettingFormData((prev) => {
                          if (e.target.checked) {
                            if (boxSection) {
                              return {
                                ...prev,
                                [`.contact-section${countOfList}`]: {
                                  "background-color": "#fdfdfd",
                                  padding:
                                    settingFormData?.[
                                      `.contact-section${countOfList}`
                                    ]?.["padding"],
                                  width: settingFormData?.[
                                    `.contact-section${countOfList}`
                                  ]?.["width"]?.replace("rem)", ""),
                                  margin: settingFormData?.[
                                    `.contact-section${countOfList}`
                                  ]?.["margin"]
                                    ?.split(" ")[0]
                                    ?.replace(/px/g, ""),
                                  "border-radius": settingFormData?.[
                                    `.contact-section${countOfList}`
                                  ]?.["border-radius"]?.replace(/rem/g, ""),
                                },
                              };
                            } else {
                              return {
                                ...prev,
                                [`.contact-section${countOfList}`]: {
                                  "background-color": "#fdfdfd",
                                  padding:
                                    settingFormData?.[
                                      `.contact-section${countOfList}`
                                    ]?.["padding"],
                                },
                              };
                            }
                          } else {
                            if (boxSection) {
                              return {
                                ...prev,
                                [`.contact-section${countOfList}`]: {
                                  padding:
                                    settingFormData?.[
                                      `.contact-section${countOfList}`
                                    ]?.["padding"],
                                  width: settingFormData?.[
                                    `.contact-section${countOfList}`
                                  ]?.["width"]?.replace("rem)", ""),
                                  margin: settingFormData?.[
                                    `.contact-section${countOfList}`
                                  ]?.["margin"]
                                    ?.split(" ")[0]
                                    ?.replace(/px/g, ""),
                                  "border-radius": settingFormData?.[
                                    `.contact-section${countOfList}`
                                  ]?.["border-radius"]?.replace(/rem/g, ""),
                                },
                              };
                            } else {
                              return {
                                ...prev,
                                [`.contact-section${countOfList}`]: {
                                  padding:
                                    settingFormData?.[
                                      `.contact-section${countOfList}`
                                    ]?.["padding"],
                                },
                              };
                            }
                          }
                        });
                      }}
                    />
                  </div>
                  {isBackgroundColor && (
                    <CommonColor
                      color={
                        settingFormData?.[`.contact-section${countOfList}`]?.[
                          "background-color"
                        ]
                      }
                      setSettingFormData={setSettingFormData}
                      className={`.contact-section${countOfList}`}
                      classProperty={"background-color"}
                      label={"Select Background Color"}
                    />
                  )}
                </Col>
                <p>{"Box Section : convert your section to Box section"}</p>
                <div>
                  <Switch
                    checked={boxSection}
                    inputProps={{ "aria-label": "controlled" }}
                    onChange={(e) => {
                      setBoxSection(e.target.checked);
                      setSettingFormData((prev) => {
                        return {
                          ...prev,
                          [`.contact-section${countOfList}`]: {
                            padding: settingFormData?.[
                              `.contact-section${countOfList}`
                            ]?.["padding"]
                              ?.split(" ")[0]
                              ?.replace(/px/g, ""),
                            "background-color":
                              settingFormData?.[
                                `.contact-section${countOfList}`
                              ]?.["background-color"],
                            width: "10",
                            margin: "0",
                            "border-radius": "3",
                          },
                        };
                      });
                    }}
                  />
                </div>
                {boxSection && (
                  <>
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom03">
                        {"Width (Default : 10)"}
                      </Label>
                      <Input
                        className="form-control"
                        name="width"
                        type="number"
                        placeholder="width"
                        value={settingFormData?.[
                          `.contact-section${countOfList}`
                        ]?.["width"]?.replace("rem)", "")}
                        onChange={(e) => handleChangeContactSection(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </Col>
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom03">
                        {"Margin (Default : 0)"}
                      </Label>
                      <Input
                        className="form-control"
                        name="margin"
                        type="number"
                        placeholder="margin"
                        value={settingFormData?.[
                          `.contact-section${countOfList}`
                        ]?.["margin"]
                          ?.split(" ")[0]
                          ?.replace(/px/g, "")}
                        onChange={(e) => handleChangeContactSection(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </Col>
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom03">
                        {"Border Radius (Default : 3)"}
                      </Label>
                      <Input
                        className="form-control"
                        name="border-radius"
                        type="number"
                        placeholder="Border Radius"
                        value={settingFormData?.[
                          `.contact-section${countOfList}`
                        ]?.["border-radius"]?.replace(/rem/g, "")}
                        onChange={(e) => handleChangeContactSection(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </Col>
                  </>
                )}
              </Row>
              <hr />
              <Row className="mt-4">
                <Col md="12 mb-3">
                  <h4 className="mb-3">Add Background Image in section</h4>

                  <p>do you want to upload Background image</p>
                  <Switch
                    checked={backImageToggle}
                    inputProps={{ "aria-label": "controlled" }}
                    onChange={(e) => {
                      setBackImageToggle(e.target.checked);
                      setSettingFormData((prev) => {
                        if (e.target.checked) {
                          return {
                            ...prev,
                            image: "",
                            [`.contact-section${countOfList}.bg-image`]: {},
                            [`.contact-section${countOfList}.bg-image::before`]:
                              {
                                "background-color": {
                                  r: 0,
                                  g: 0,
                                  b: 0,
                                  a: 0.8,
                                },
                              },
                          };
                        } else {
                          return {
                            ...prev,
                            image: "",
                            [`.contact-section${countOfList}.bg-image`]: {},
                            [`.contact-section${countOfList}.bg-image::before`]:
                              {},
                          };
                        }
                      });
                    }}
                  />
                  {backImageToggle && (
                    <>
                      {" "}
                      <Col md="6 mb-3">
                        <Dividers divide="IMAGE" />
                        <p className="mb-4">
                          File type required JPG, PNG (1905px X 1135px)
                        </p>
                      </Col>
                      <Row>
                        <Col md="4 mb-3">
                          <div
                            className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                            style={{ cursor: "pointer" }}
                          >
                            {!settingFormData?.[
                              `.contact-section${countOfList}.bg-image`
                            ]?.["background-image"] && (
                              <span
                                onClick={() =>
                                  OpenModalCommonFunc(
                                    Cases.PRODUCT_UPLOAD,
                                    setAllPopupState
                                  )
                                }
                              >
                                <UploadCloud />
                              </span>
                            )}

                            {settingFormData?.[
                              `.contact-section${countOfList}.bg-image`
                            ]?.["background-image"] && (
                              <img
                                src={
                                  settingFormData?.[
                                    `.contact-section${countOfList}.bg-image`
                                  ]?.["background-image"] || ""
                                }
                                alt=""
                                style={{
                                  width: "85%",
                                  height: "90px",
                                  objectFit: "contain",
                                  position: "absolute",
                                }}
                                onClick={() => deleteImagePermanent()}
                              />
                            )}
                            {settingFormData?.[
                              `.contact-section${countOfList}.bg-image`
                            ]?.["background-image"] && (
                              <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="close"
                                style={{
                                  color: "red",
                                  position: "absolute",
                                  top: "-22px",
                                  left: "1px",
                                }}
                                onClick={() => deleteImagePermanent()}
                              >
                                <Close
                                  style={{
                                    fontSize: "25px",
                                    fontWeight: "bold",
                                  }}
                                />
                              </IconButton>
                            )}
                          </div>
                        </Col>
                        <Col md="6 mb-3">
                          <h4 className="mb-3">
                            Add Background color in section
                          </h4>
                          <Col md="6 mb-3">
                            <CommonColor
                              color={
                                settingFormData?.[
                                  `.contact-section${countOfList}.bg-image::before`
                                ]?.["background-color"]
                              }
                              setSettingFormData={setSettingFormData}
                              className={`.contact-section${countOfList}.bg-image::before`}
                              classProperty={"background-color"}
                              label={"Select Background Color"}
                            />
                          </Col>
                        </Col>
                      </Row>
                    </>
                  )}
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">Make space in Title bottom</h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Margin Bottom (Default : 50)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="margin-bottom"
                    type="number"
                    placeholder="Margin Bottom"
                    value={settingFormData?.[
                      `.contact-section${countOfList} .section-title-area`
                    ]?.["margin-bottom"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeContactTitleArea(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">Make change in section subtitle</h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Size (Default : 16)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="font-size"
                    type="number"
                    placeholder="Font Size"
                    value={settingFormData?.[
                      [
                        `.contact-section${countOfList} .section-title-area .section-subtitle`,
                      ]
                    ]?.["font-size"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeContactTitleAreaSubtitle(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Weight (Default : 600)"}
                  </Label>
                  <select
                    name="font-weight"
                    id="font-weight"
                    className="form-select"
                    value={
                      settingFormData?.[
                        [
                          `.contact-section${countOfList} .section-title-area .section-subtitle`,
                        ]
                      ]?.["font-weight"]
                    }
                    onChange={(e) => handleChangeContactTitleAreaSubtitle(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {fontWeight.map((elem) => {
                      return (
                        <option value={elem["font-weight"]}>
                          {elem["font-weight"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        `.contact-section${countOfList} .section-title-area .section-subtitle`
                      ]?.["color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={`.contact-section${countOfList} .section-title-area .section-subtitle`}
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        `.contact-section${countOfList} .section-title-area .section-subtitle`
                      ]?.["background-color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={`.contact-section${countOfList} .section-title-area .section-subtitle`}
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding (Default : 5 - 20)"}
                  </Label>
                  <div className="d-flex justify-content-between">
                    <Input
                      className="form-control"
                      name="p1"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        [
                          `.contact-section${countOfList} .section-title-area .section-subtitle`,
                        ]
                      ]?.["p1"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangeContactTitleAreaSubtitle(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                    <Input
                      className="form-control"
                      name="p2"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        [
                          `.contact-section${countOfList} .section-title-area .section-subtitle`,
                        ]
                      ]?.["p2"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangeContactTitleAreaSubtitle(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                  </div>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border Radius (Default : 15)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="border-radius"
                    type="number"
                    placeholder="Border Radius"
                    value={settingFormData?.[
                      [
                        `.contact-section${countOfList} .section-title-area .section-subtitle`,
                      ]
                    ]?.["border-radius"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeContactTitleAreaSubtitle(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Margin Bottom (Default : 15)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="margin-bottom"
                    type="number"
                    placeholder="Margin Bottom"
                    value={settingFormData?.[
                      [
                        `.contact-section${countOfList} .section-title-area .section-subtitle`,
                      ]
                    ]?.["margin-bottom"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeContactTitleAreaSubtitle(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Display (Default : inline-block)"}
                  </Label>
                  <select
                    name="display"
                    id="display"
                    className="form-control"
                    value={
                      settingFormData?.[
                        [
                          `.contact-section${countOfList} .section-title-area .section-subtitle`,
                        ]
                      ]?.["display"]
                    }
                    onChange={(e) => handleChangeContactTitleAreaSubtitle(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {Display.map((elem) => {
                      return (
                        <option value={elem["display"]} key={elem["display"]}>
                          {elem["display"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">Make change in section Title</h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Size (Default : 44)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="font-size"
                    type="number"
                    placeholder="Font Size"
                    value={settingFormData?.[
                      `.contact-section${countOfList} .section-title-area .section-title`
                    ]?.["font-size"]?.replace(/px/g, "")}
                    onChange={(e) =>
                      handleChangeContactTitleAreaSectionTitle(e)
                    }
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Weight (Default : 600)"}
                  </Label>
                  <select
                    name="font-weight"
                    id="font-weight"
                    className="form-select"
                    value={
                      settingFormData?.[
                        `.contact-section${countOfList} .section-title-area .section-title`
                      ]?.["font-weight"]
                    }
                    onChange={(e) =>
                      handleChangeContactTitleAreaSectionTitle(e)
                    }
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {fontWeight.map((elem) => {
                      return (
                        <option value={elem["font-weight"]}>
                          {elem["font-weight"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        `.contact-section${countOfList} .section-title-area .section-title`
                      ]?.["color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={`.contact-section${countOfList} .section-title-area .section-title`}
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">Make change in inquiry box</h4>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        `.contact-section${countOfList} .inquiry-box`
                      ]?.["background-color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={`.contact-section${countOfList} .inquiry-box`}
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding (Default : 40 - 40)"}
                  </Label>
                  <div className="d-flex justify-content-between">
                    <Input
                      className="form-control"
                      name="p1"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        `.contact-section${countOfList} .inquiry-box`
                      ]?.["p1"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangeInquireyBox(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                    <Input
                      className="form-control"
                      name="p2"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        `.contact-section${countOfList} .inquiry-box`
                      ]?.["p2"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangeInquireyBox(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                  </div>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Margin (Default : 10)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="margin"
                    type="number"
                    placeholder="Margin"
                    value={settingFormData?.[
                      `.contact-section${countOfList} .inquiry-box`
                    ]?.["margin"]
                      ?.split(" ")[0]
                      ?.replace(/px/g, "")}
                    onChange={(e) => handleChangeInquireyBox(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border Radius (Default : 15)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="border-radius"
                    type="number"
                    placeholder="Border Radius"
                    value={settingFormData?.[
                      `.contact-section${countOfList} .inquiry-box`
                    ]?.["border-radius"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeInquireyBox(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border (Default : 1 solid white)"}
                  </Label>
                  <div className="d-flex">
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="borderPx"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[
                          `.contact-section${countOfList} .inquiry-box`
                        ]?.["borderPx"]?.replace(/px/g, "")}
                        onChange={(e) => handleChangeInquireyBox(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <select
                        name="borderThik"
                        id="borderThik"
                        className="form-control"
                        value={
                          settingFormData?.[
                            `.contact-section${countOfList} .inquiry-box`
                          ]?.["borderThik"]
                        }
                        onChange={(e) => handleChangeInquireyBox(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      >
                        <option value="">--select--</option>
                        {Border.map((elem) => {
                          return (
                            <option value={elem.border}>{elem.border}</option>
                          );
                        })}
                      </select>
                    </div>
                    <div>
                      <CommonColor
                        color={
                          settingFormData?.[
                            `.contact-section${countOfList} .inquiry-box`
                          ]?.["borderColor"]
                        }
                        setSettingFormData={setSettingFormData}
                        className={`.contact-section${countOfList} .inquiry-box`}
                        classProperty={"borderColor"}
                        label={"Select Color"}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border Top (Default : 3 solid pink)"}
                  </Label>
                  <div className="d-flex ">
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="borderTopPx"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[
                          `.contact-section${countOfList} .inquiry-box`
                        ]?.["borderTopPx"]?.replace(/px/g, "")}
                        onChange={(e) => handleChangeInquireyBox(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <select
                        name="borderTopThik"
                        id="borderTopThik"
                        className="form-control"
                        value={
                          settingFormData?.[
                            `.contact-section${countOfList} .inquiry-box`
                          ]?.["borderTopThik"]
                        }
                        onChange={(e) => handleChangeInquireyBox(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      >
                        <option value="">--select--</option>
                        {Border.map((elem) => {
                          return (
                            <option value={elem.border}>{elem.border}</option>
                          );
                        })}
                      </select>
                    </div>
                    <div>
                      <CommonColor
                        color={
                          settingFormData?.[
                            `.contact-section${countOfList} .inquiry-box`
                          ]?.["borderTopColor"]
                        }
                        setSettingFormData={setSettingFormData}
                        className={`.contact-section${countOfList} .inquiry-box`}
                        classProperty={"borderTopColor"}
                        label={"Select Color"}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="6 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Box Shadow (Default : 5 5 15 black)"}
                  </Label>
                  <div className="d-flex ">
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="bs1"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[
                          `.contact-section${countOfList} .inquiry-box`
                        ]?.["bs1"]?.replace(/px/g, "")}
                        onChange={(e) => handleChangeInquireyBox(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="bs2"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[
                          `.contact-section${countOfList} .inquiry-box`
                        ]?.["bs2"]?.replace(/px/g, "")}
                        onChange={(e) => handleChangeInquireyBox(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="bs3"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[
                          `.contact-section${countOfList} .inquiry-box`
                        ]?.["bs3"]?.replace(/px/g, "")}
                        onChange={(e) => handleChangeInquireyBox(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div>
                      <CommonColor
                        color={
                          settingFormData?.[
                            `.contact-section${countOfList} .inquiry-box`
                          ]?.["shadowColor"]
                        }
                        setSettingFormData={setSettingFormData}
                        className={`.contact-section${countOfList} .inquiry-box`}
                        classProperty={"shadowColor"}
                        label={"Select Color"}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Text Align (Default : center)"}
                  </Label>
                  <select
                    name="text-align"
                    id="text-align"
                    className="form-control"
                    value={
                      settingFormData?.[
                        `.contact-section${countOfList} .inquiry-box`
                      ]?.["text-align"]
                    }
                    onChange={(e) => handleChangeInquireyBox(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {TextAlign.map((elem) => {
                      return (
                        <option value={elem["text-align"]}>
                          {elem["text-align"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">Make change in inquiry box image</h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Margin Bottom (Default : 20)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="margin-bottom"
                    type="number"
                    placeholder="Margin Bottom"
                    value={settingFormData?.[
                      `.contact-section${countOfList} .inquiry-box img`
                    ]?.["margin-bottom"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeInquireyBoxImg(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Max Width (Default : 60)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="max-width"
                    type="number"
                    placeholder="max-width"
                    value={settingFormData?.[
                      `.contact-section${countOfList} .inquiry-box img`
                    ]?.["max-width"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeInquireyBoxImg(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">Make change in inquiry box heading</h4>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        `.contact-section${countOfList} .inquiry-box h4`
                      ]?.["color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={`.contact-section${countOfList} .inquiry-box h4`}
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Weight (Default : 600)"}
                  </Label>
                  <select
                    name="font-weight"
                    id="font-weight"
                    className="form-select"
                    value={
                      settingFormData?.[
                        `.contact-section${countOfList} .inquiry-box h4`
                      ]?.["font-weight"]
                    }
                    onChange={(e) => handleChangeInquireyBoxH4(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {fontWeight.map((elem) => {
                      return (
                        <option value={elem["font-weight"]}>
                          {elem["font-weight"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Size (Default : 22)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="font-size"
                    type="number"
                    placeholder="Font Size"
                    value={settingFormData?.[
                      `.contact-section${countOfList} .inquiry-box h4`
                    ]?.["font-size"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeInquireyBoxH4(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Margin Bottom (Default : 15)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="margin-bottom"
                    type="number"
                    placeholder="Margin Bottom"
                    value={settingFormData?.[
                      `.contact-section${countOfList} .inquiry-box h4`
                    ]?.["margin-bottom"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeInquireyBoxH4(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">Make change in inquiry box p</h4>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        `.contact-section${countOfList} .inquiry-box p`
                      ]?.["color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={`.contact-section${countOfList} .inquiry-box p`}
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Weight (Default : 400)"}
                  </Label>
                  <select
                    name="font-weight"
                    id="font-weight"
                    className="form-select"
                    value={
                      settingFormData?.[
                        `.contact-section${countOfList} .inquiry-box p`
                      ]?.["font-weight"]
                    }
                    onChange={(e) => handleChangeInquireyBoxP(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {fontWeight.map((elem) => {
                      return (
                        <option value={elem["font-weight"]}>
                          {elem["font-weight"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Size (Default : 14)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="font-size"
                    type="number"
                    placeholder="Font Size"
                    value={settingFormData?.[
                      `.contact-section${countOfList} .inquiry-box p`
                    ]?.["font-size"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeInquireyBoxP(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Line Height (Default : 1.6)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="line-height"
                    type="number"
                    placeholder="Line Height"
                    value={settingFormData?.[
                      `.contact-section${countOfList} .inquiry-box p`
                    ]?.["line-height"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeInquireyBoxP(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">Make change in contact box</h4>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        `.contact-section${countOfList} .contactbox`
                      ]?.["background-color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={`.contact-section${countOfList} .contactbox`}
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding (Default : 40 - 40)"}
                  </Label>
                  <div className="d-flex justify-content-between">
                    <Input
                      className="form-control"
                      name="p1"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        `.contact-section${countOfList} .contactbox`
                      ]?.["p1"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangeIContactbox(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                    <Input
                      className="form-control"
                      name="p2"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        `.contact-section${countOfList} .contactbox`
                      ]?.["p2"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangeIContactbox(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                  </div>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border Radius (Default : 15)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="border-radius"
                    type="number"
                    placeholder="Border Radius"
                    value={settingFormData?.[
                      `.contact-section${countOfList} .contactbox`
                    ]?.["border-radius"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeIContactbox(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border (Default : 1 solid white)"}
                  </Label>
                  <div className="d-flex ">
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="borderPx"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[
                          `.contact-section${countOfList} .contactbox`
                        ]?.["borderPx"]?.replace(/px/g, "")}
                        onChange={(e) => handleChangeIContactbox(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <select
                        name="borderThik"
                        id="borderThik"
                        className="form-control"
                        value={
                          settingFormData?.[
                            `.contact-section${countOfList} .contactbox`
                          ]?.["borderThik"]
                        }
                        onChange={(e) => handleChangeIContactbox(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      >
                        <option value="">--select--</option>
                        {Border.map((elem) => {
                          return (
                            <option value={elem.border}>{elem.border}</option>
                          );
                        })}
                      </select>
                    </div>
                    <div>
                      <CommonColor
                        color={
                          settingFormData?.[
                            `.contact-section${countOfList} .contactbox`
                          ]?.["borderColor"]
                        }
                        setSettingFormData={setSettingFormData}
                        className={`.contact-section${countOfList} .contactbox`}
                        classProperty={"borderColor"}
                        label={"Select Color"}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border Top (Default : 3 solid pink)"}
                  </Label>
                  <div className="d-flex">
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="borderTopPx"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[
                          `.contact-section${countOfList} .contactbox`
                        ]?.["borderTopPx"]?.replace(/px/g, "")}
                        onChange={(e) => handleChangeIContactbox(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <select
                        name="borderTopThik"
                        id="borderTopThik"
                        className="form-control"
                        value={
                          settingFormData?.[
                            `.contact-section${countOfList} .contactbox`
                          ]?.["borderTopThik"]
                        }
                        onChange={(e) => handleChangeIContactbox(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      >
                        <option value="">--select--</option>
                        {Border.map((elem) => {
                          return (
                            <option value={elem.border}>{elem.border}</option>
                          );
                        })}
                      </select>
                    </div>
                    <div>
                      <CommonColor
                        color={
                          settingFormData?.[
                            `.contact-section${countOfList} .contactbox`
                          ]?.["borderTopColor"]
                        }
                        setSettingFormData={setSettingFormData}
                        className={`.contact-section${countOfList} .contactbox`}
                        classProperty={"borderTopColor"}
                        label={"Select Color"}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="6 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Box Shadow (Default : 5 5 15 black)"}
                  </Label>
                  <div className="d-flex">
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="bs1"
                        type="number"
                        placeholder="Bs"
                        value={settingFormData?.[
                          `.contact-section${countOfList} .contactbox`
                        ]?.["bs1"]?.replace(/px/g, "")}
                        onChange={(e) => handleChangeIContactbox(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="bs2"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[
                          `.contact-section${countOfList} .contactbox`
                        ]?.["bs2"]?.replace(/px/g, "")}
                        onChange={(e) => handleChangeIContactbox(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="bs3"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[
                          `.contact-section${countOfList} .contactbox`
                        ]?.["bs3"]?.replace(/px/g, "")}
                        onChange={(e) => handleChangeIContactbox(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div>
                      <CommonColor
                        color={
                          settingFormData?.[
                            `.contact-section${countOfList} .contactbox`
                          ]?.["shadowColor"]
                        }
                        setSettingFormData={setSettingFormData}
                        className={`.contact-section${countOfList} .contactbox`}
                        classProperty={"shadowColor"}
                        label={"Select Color"}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">Make change in contact box iframe</h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border (Default : 2 solid black)"}
                  </Label>
                  <div className="d-flex">
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="borderPx"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[
                          `.contact-section${countOfList} .contactbox iframe`
                        ]?.["borderPx"]?.replace(/px/g, "")}
                        onChange={(e) => handleChangeIContactboxIframe(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <select
                        name="borderThik"
                        id="borderThik"
                        className="form-control"
                        value={
                          settingFormData?.[
                            `.contact-section${countOfList} .contactbox iframe`
                          ]?.["borderThik"]
                        }
                        onChange={(e) => handleChangeIContactboxIframe(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      >
                        <option value="">--select--</option>
                        {Border.map((elem) => {
                          return (
                            <option value={elem.border}>{elem.border}</option>
                          );
                        })}
                      </select>
                    </div>
                    <div>
                      <CommonColor
                        color={
                          settingFormData?.[
                            `.contact-section${countOfList} .contactbox iframe`
                          ]?.["borderColor"]
                        }
                        setSettingFormData={setSettingFormData}
                        className={`.contact-section${countOfList} .contactbox iframe`}
                        classProperty={"borderColor"}
                        label={"Select Color"}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <CustomStyle
                handleChange={handleChangeCustomStyle}
                value={settingFormData?.custom}
              />
            </div>
          </Col>
        </Row>
        <ProductImageUploadPopup
          allPopupState={allPopupState}
          setAllPopupState={setAllPopupState}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
          image={settingFormData?.image}
          setImage={setSettingFormData}
          imageUpload={imageUpload}
          isEdit={isEdit}
          reupload={reupload}
          // aspect={1.68 / 1}
        />
      </Container>
    </>
  );
};

export default SettingContactUsParentPopup;
