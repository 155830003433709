import React, { useContext, useEffect, useState } from "react";
import { Col, Input, Label, Row } from "reactstrap";
import { Switch } from "@material-ui/core";
import HeaderContext from "../../../../context/HeaderContext/HeaderContext";
import { FontList_List } from "../../../../../api/FontListApi/FontListApi";
import CommonColor from "../../../../common/CommonColor";
import CustomStyle from "../../../../common/CustomStyle";
import HeaderImageUpload from "./HeaderImageUpload";

const NavbarStyleTwo = (props) => {
  const { imageUpload } = props;
  const { settingFormData, setSettingFormData, setIsBackgroundColor } =
    useContext(HeaderContext);
  const [fontListGet, setFontListGet] = useState([]);

  /**
   * input value change
   * @param {event} e
   */

  const handleChangeHeaderStyleOne = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        "header.style-one": {
          ...prev["header.style-one"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeHeaderStyleOneAfter = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        "header.style-one::after": {
          ...prev["header.style-one::after"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeWrapperNavbarBrand = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        "header.style-one .header-wrapper .navbar-brand": {
          ...prev["header.style-one .header-wrapper .navbar-brand"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeWrapperNavbarBrandImg = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        "header.style-one .header-wrapper .navbar-brand img": {
          ...prev["header.style-one .header-wrapper .navbar-brand img"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeHeaderNavbarNav = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        "header.style-one .header-wrapper .navbar-nav": {
          ...prev["header.style-one .header-wrapper .navbar-nav"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeNavItemLink = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        "header.style-one .header-wrapper .navbar-nav .nav-item .nav-link": {
          ...prev[
            "header.style-one .header-wrapper .navbar-nav .nav-item .nav-link"
          ],
          [name]: value,
        },
      };
    });
  };
  const handleChangeHeaderNavSingUp = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        "header.style-one .header-wrapper .navbar-nav .nav-item.sign-up": {
          ...prev[
            "header.style-one .header-wrapper .navbar-nav .nav-item.sign-up"
          ],
          [name]: value,
        },
      };
    });
  };
  const handleChangeNavItemLiNavLink = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        "header.style-one .header-wrapper .navbar-nav .nav-item .sub-menu li .nav-link":
          {
            ...prev[
              "header.style-one .header-wrapper .navbar-nav .nav-item .sub-menu li .nav-link"
            ],
            [name]: value,
          },
      };
    });
  };
  const handleChangeNavItemMegaMenuIcon = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler":
          {
            ...prev[
              "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
            ],
            [name]: value,
          },
      };
    });
  };

  const handleChangeCustomStyle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const fontListData = async () => {
    try {
      const { data } = await FontList_List();
      if (data.status === 200) {
        setFontListGet(data.payload.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const checkActive =
      settingFormData?.[
        ".custom-nav .navbar-nav .nav-link:hover,.custom-nav .navbar-nav .nav-link.active"
      ];
    if (checkActive?.["background-color"]) {
      setIsBackgroundColor(true);
    }
    fontListData();
  }, []);

  const handleChangeFontFamily = (e) => {
    const { name, value } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [name]: value ? JSON?.parse(value) : "",
      };
    });
  };

  const handleChangeIsCenterNavs = (e) => {
    const { name, checked } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [name]: checked,
      };
    });
  };
  return (
    <>
      <HeaderImageUpload imageUpload={imageUpload} />
      <Col sm="12" xl="12">
        <div className="mt-4 d-flex flex-column justify-content-center ">
          <Row className="mt-4">
            <h4 className="mb-3">Make change in Navbar Show Center or not</h4>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Navbar Center (Default : false)"}
              </Label>
              <Switch
                name="isCenterNavs"
                checked={settingFormData?.isCenterNavs}
                inputProps={{ "aria-label": "controlled" }}
                onChange={(e) => {
                  handleChangeIsCenterNavs(e);
                }}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <h4 className="mb-3">
              Make Change in Right Side header Background
            </h4>
            <Col md="4 mb-3">
              <CommonColor
                color={
                  settingFormData?.["header.style-one"]?.["background-color"]
                }
                setSettingFormData={setSettingFormData}
                className={"header.style-one"}
                classProperty={"background-color"}
                label={"Select Background Color"}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Padding (Default : 20 - 30)"}
              </Label>
              <div className="d-flex justify-content-between">
                <Input
                  className="form-control"
                  name="p1"
                  type="number"
                  placeholder="Padding"
                  value={settingFormData?.["header.style-one"]?.["p1"]?.replace(
                    /px/g,
                    ""
                  )}
                  onChange={(e) => handleChangeHeaderStyleOne(e)}
                  style={{ borderRadius: "0", color: "black" }}
                />
                <Input
                  className="form-control"
                  name="p2"
                  type="number"
                  placeholder="Padding"
                  value={settingFormData?.["header.style-one"]?.["p2"]?.replace(
                    /px/g,
                    ""
                  )}
                  onChange={(e) => handleChangeHeaderStyleOne(e)}
                  style={{ borderRadius: "0", color: "black" }}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <h4 className="mb-3">Make Change in Left Side header Background</h4>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Width (Default : 615)"}
              </Label>
              <Input
                className="form-control"
                name="width"
                type="number"
                placeholder="width"
                value={settingFormData?.["header.style-one::after"]?.[
                  "width"
                ]?.replace(/px/g, "")}
                onChange={(e) => handleChangeHeaderStyleOneAfter(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <CommonColor
                color={
                  settingFormData?.["header.style-one::after"]?.["background"]
                }
                setSettingFormData={setSettingFormData}
                className={"header.style-one::after"}
                classProperty={"background"}
                label={"Select Background"}
              />
            </Col>
          </Row>
          <hr />
          <Row className="mt-2">
            <h4 className="mb-3">Make change in header-wrapper place</h4>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Margin-right (Default : 55)"}
              </Label>
              <Input
                className="form-control"
                name="margin-right"
                type="number"
                placeholder="margin-right"
                value={settingFormData?.[
                  "header.style-one .header-wrapper .navbar-brand"
                ]?.["margin-right"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeWrapperNavbarBrand(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Line-height (Default : 0)"}
              </Label>
              <Input
                className="form-control"
                name="line-height"
                type="number"
                placeholder="line-height"
                value={settingFormData?.[
                  "header.style-one .header-wrapper .navbar-brand"
                ]?.["line-height"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeWrapperNavbarBrand(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
          </Row>
          <hr />
          <Row className="mt-2">
            <h4 className="mb-3">Change Logo size</h4>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Max-width (Default : 200)"}
              </Label>
              <Input
                className="form-control"
                name="max-width"
                type="number"
                placeholder="max-width"
                value={settingFormData?.[
                  "header.style-one .header-wrapper .navbar-brand img"
                ]?.["max-width"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeWrapperNavbarBrandImg(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
          </Row>
          <hr />
          <Row className="mt-2">
            <h4 className="mb-3">Make change in Navbar menu</h4>
            <Col md="4 mb-3">
              <CommonColor
                color={
                  settingFormData?.[
                    "header.style-one .header-wrapper .navbar-nav"
                  ]?.["background"]
                }
                setSettingFormData={setSettingFormData}
                className={"header.style-one .header-wrapper .navbar-nav"}
                classProperty={"background"}
                label={"Select background"}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Border-radius (Default : 50)"}
              </Label>
              <Input
                className="form-control"
                name="border-radius"
                type="number"
                placeholder="border-radius"
                value={settingFormData?.[
                  "header.style-one .header-wrapper .navbar-nav"
                ]?.["border-radius"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeHeaderNavbarNav(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Padding-left (Default : 40)"}
              </Label>
              <Input
                className="form-control"
                name="padding-left"
                type="number"
                placeholder="padding-left"
                value={settingFormData?.[
                  "header.style-one .header-wrapper .navbar-nav"
                ]?.["padding-left"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeHeaderNavbarNav(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">{"Gap (Default : 24)"}</Label>
              <Input
                className="form-control"
                name="gap"
                type="number"
                placeholder="gap"
                value={settingFormData?.[
                  "header.style-one .header-wrapper .navbar-nav"
                ]?.["gap"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeHeaderNavbarNav(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="6 mb-3">
              <Label htmlFor="validationCustom03">{"Box Shadow"}</Label>
              <div className="d-flex">
                <div style={{ width: "100px" }}>
                  <Input
                    className="form-control"
                    name="bs1"
                    type="number"
                    placeholder="px"
                    value={settingFormData?.[
                      "header.style-one .header-wrapper .navbar-nav"
                    ]?.["bs1"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeHeaderNavbarNav(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </div>
                <div style={{ width: "100px" }}>
                  <Input
                    className="form-control"
                    name="bs2"
                    type="number"
                    placeholder="px"
                    value={settingFormData?.[
                      "header.style-one .header-wrapper .navbar-nav"
                    ]?.["bs2"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeHeaderNavbarNav(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </div>
                <div style={{ width: "100px" }}>
                  <Input
                    className="form-control"
                    name="bs3"
                    type="number"
                    placeholder="px"
                    value={settingFormData?.[
                      "header.style-one .header-wrapper .navbar-nav"
                    ]?.["bs3"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeHeaderNavbarNav(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </div>
                <div>
                  <CommonColor
                    color={
                      settingFormData?.[
                        "header.style-one .header-wrapper .navbar-nav"
                      ]?.["shadowColor"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={"header.style-one .header-wrapper .navbar-nav"}
                    classProperty={"shadowColor"}
                    label={"Select Color"}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <hr />
          <Row className="mt-2">
            <h4 className="mb-3">Make change in Navbar menu li</h4>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Padding (default : 28)"}
              </Label>
              <Input
                className="form-control"
                name="padding"
                type="number"
                placeholder="Padding"
                value={settingFormData?.[
                  "header.style-one .header-wrapper .navbar-nav .nav-item .nav-link"
                ]?.["padding"]
                  ?.split(" ")[0]
                  ?.replace(/px/g, "")}
                onChange={(e) => handleChangeNavItemLink(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Font Size (Default : 14)"}
              </Label>
              <Input
                className="form-control"
                name="font-size"
                type="number"
                placeholder="Font Size"
                value={settingFormData?.[
                  "header.style-one .header-wrapper .navbar-nav .nav-item .nav-link"
                ]?.["font-size"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeNavItemLink(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <CommonColor
                color={
                  settingFormData?.[
                    "header.style-one .header-wrapper .navbar-nav .nav-item .nav-link"
                  ]?.["color"]
                }
                setSettingFormData={setSettingFormData}
                className={
                  "header.style-one .header-wrapper .navbar-nav .nav-item .nav-link"
                }
                classProperty={"color"}
                label={"Select Color"}
              />
            </Col>
          </Row>
          <hr />
          <Row className="mt-2">
            <h4 className="mb-3">Make change in Navbar Sing up</h4>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Padding (Default :58 - 67)"}
              </Label>
              <div className="d-flex justify-content-between">
                <Input
                  className="form-control"
                  name="p1"
                  type="number"
                  placeholder="Padding"
                  value={settingFormData?.[
                    "header.style-one .header-wrapper .navbar-nav .nav-item.sign-up"
                  ]?.["p1"]?.replace(/px/g, "")}
                  onChange={(e) => handleChangeHeaderNavSingUp(e)}
                  style={{ borderRadius: "0", color: "black" }}
                />
                <Input
                  className="form-control"
                  name="p2"
                  type="number"
                  placeholder="Padding"
                  value={settingFormData?.[
                    "header.style-one .header-wrapper .navbar-nav .nav-item.sign-up"
                  ]?.["p2"]?.replace(/px/g, "")}
                  onChange={(e) => handleChangeHeaderNavSingUp(e)}
                  style={{ borderRadius: "0", color: "black" }}
                />
              </div>
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Border-radius (Default :50 - 50)"}
              </Label>
              <div className="d-flex justify-content-between">
                <Input
                  className="form-control"
                  name="br1"
                  type="number"
                  placeholder="Padding"
                  value={settingFormData?.[
                    "header.style-one .header-wrapper .navbar-nav .nav-item.sign-up"
                  ]?.["br1"]?.replace(/px/g, "")}
                  onChange={(e) => handleChangeHeaderNavSingUp(e)}
                  style={{ borderRadius: "0", color: "black" }}
                />
                <Input
                  className="form-control"
                  name="br2"
                  type="number"
                  placeholder="Padding"
                  value={settingFormData?.[
                    "header.style-one .header-wrapper .navbar-nav .nav-item.sign-up"
                  ]?.["br2"]?.replace(/px/g, "")}
                  onChange={(e) => handleChangeHeaderNavSingUp(e)}
                  style={{ borderRadius: "0", color: "black" }}
                />
              </div>
            </Col>
            <Col md="4 mb-3">
              <CommonColor
                color={
                  settingFormData?.[
                    "header.style-one .header-wrapper .navbar-nav .nav-item.sign-up"
                  ]?.["background"]
                }
                setSettingFormData={setSettingFormData}
                className={
                  "header.style-one .header-wrapper .navbar-nav .nav-item.sign-up"
                }
                classProperty={"background"}
                label={"Select Background"}
              />
            </Col>
          </Row>
          <hr />
          <Row className="mt-2">
            <h4 className="mb-3">Make change in Navbar Sing up li</h4>
            <Col md="4 mb-3">
              <CommonColor
                color={
                  settingFormData?.[
                    "header.style-one .header-wrapper .navbar-nav .nav-item.sign-up .nav-link"
                  ]?.["color"]
                }
                setSettingFormData={setSettingFormData}
                className={
                  "header.style-one .header-wrapper .navbar-nav .nav-item.sign-up .nav-link"
                }
                classProperty={"color"}
                label={"Select color"}
              />
            </Col>
          </Row>
          <hr />
          <Row className="mt-2">
            <h4 className="mb-3">Change Navbar menu li color on hover</h4>
            <Col md="4 mb-3">
              <CommonColor
                color={
                  settingFormData?.[
                    "header.style-one .header-wrapper .navbar-nav li a.nav-link:hover"
                  ]?.["color"]
                }
                setSettingFormData={setSettingFormData}
                className={
                  "header.style-one .header-wrapper .navbar-nav li a.nav-link:hover"
                }
                classProperty={"color"}
                label={"Select color"}
              />
            </Col>
          </Row>
          <hr />
          <Row className="mt-2">
            <h4 className="mb-3">Make change in navbar sub menu</h4>
            <Col md="4 mb-3">
              <CommonColor
                color={
                  settingFormData?.[
                    "header.style-one .header-wrapper .navbar-nav .nav-item .sub-menu, header.style-one .header-wrapper .navbar-nav .nav-item .megamenu"
                  ]?.["background"]
                }
                setSettingFormData={setSettingFormData}
                className={
                  "header.style-one .header-wrapper .navbar-nav .nav-item .sub-menu, header.style-one .header-wrapper .navbar-nav .nav-item .megamenu"
                }
                classProperty={"background"}
                label={"Select Background"}
              />
            </Col>
            {/* <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Padding (Default :6 - 20)"}
              </Label>
              <div className="d-flex justify-content-between">
                <Input
                  className="form-control"
                  name="p1"
                  type="number"
                  placeholder="Padding"
                  value={settingFormData?.[
                    "header.style-one .header-wrapper .navbar-nav .nav-item .sub-menu, header.style-one .header-wrapper .navbar-nav .nav-item .megamenu"
                  ]?.["p1"]?.replace(/px/g, "")}
                  onChange={(e) => handleChangeHeaderNavSubMenuMegaMenu(e)}
                  style={{ borderRadius: "0", color: "black" }}
                />
                <Input
                  className="form-control"
                  name="p2"
                  type="number"
                  placeholder="Padding"
                  value={settingFormData?.[
                    "header.style-one .header-wrapper .navbar-nav .nav-item .sub-menu, header.style-one .header-wrapper .navbar-nav .nav-item .megamenu"
                  ]?.["p2"]?.replace(/px/g, "")}
                  onChange={(e) => handleChangeHeaderNavSubMenuMegaMenu(e)}
                  style={{ borderRadius: "0", color: "black" }}
                />
              </div>
            </Col> */}
          </Row>
          <hr />
          <Row className="mt-2">
            <h4 className="mb-3">Make change in navbar sub menu li</h4>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Padding (default : 8)"}
              </Label>
              <Input
                className="form-control"
                name="padding"
                type="number"
                placeholder="Padding"
                value={settingFormData?.[
                  "header.style-one .header-wrapper .navbar-nav .nav-item .sub-menu li .nav-link"
                ]?.["padding"]
                  ?.split(" ")[0]
                  ?.replace(/px/g, "")}
                onChange={(e) => handleChangeNavItemLiNavLink(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
          </Row>
          {/* <hr />
          <Row className="mt-2">
            <h4 className="mb-3">
              "header.style-one .header-wrapper .navbar-nav .nav-item .megamenu"
            </h4>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Padding (Default : 20)"}
              </Label>
              <Input
                className="form-control"
                name="padding"
                type="number"
                placeholder="padding"
                value={settingFormData?.[
                  "header.style-one .header-wrapper .navbar-nav .nav-item .megamenu"
                ]?.["padding"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeNavItemMegaMenu(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
          </Row>
          <hr />
          <Row className="mt-2">
            <h4 className="mb-3">
              "header.style-one .header-wrapper .navbar-nav .nav-item .megamenu
              a"
            </h4>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Padding (default : 6)"}
              </Label>
              <Input
                className="form-control"
                name="padding"
                type="number"
                placeholder="Padding"
                value={settingFormData?.[
                  "header.style-one .header-wrapper .navbar-nav .nav-item .megamenu a"
                ]?.["padding"]
                  ?.split(" ")[0]
                  ?.replace(/px/g, "")}
                onChange={(e) => handleChangeNavItemMegaMenuA(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Font-size (Default : 14)"}
              </Label>
              <Input
                className="form-control"
                name="font-size"
                type="number"
                placeholder="font-size"
                value={settingFormData?.[
                  "header.style-one .header-wrapper .navbar-nav .nav-item .megamenu a"
                ]?.["font-size"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeNavItemMegaMenuA(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
          </Row>
          <hr />
          <Row className="mt-2">
            <h4 className="mb-3">
              "header.style-one .header-wrapper .navbar-nav .nav-item .megamenu
              h5"
            </h4>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Font-size (Default : 18)"}
              </Label>
              <Input
                className="form-control"
                name="font-size"
                type="number"
                placeholder="font-size"
                value={settingFormData?.[
                  "header.style-one .header-wrapper .navbar-nav .nav-item .megamenu h5"
                ]?.["font-size"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeNavItemMegaMenuH5(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Padding-bottom (Default : 10)"}
              </Label>
              <Input
                className="form-control"
                name="padding-bottom"
                type="number"
                placeholder="padding-bottom"
                value={settingFormData?.[
                  "header.style-one .header-wrapper .navbar-nav .nav-item .megamenu h5"
                ]?.["padding-bottom"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeNavItemMegaMenuH5(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
          </Row> */}
          <hr />
          <Row className="mt-2">
            <h4 className="mb-3">Make change in navbar menu icon</h4>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"width (Default : 50)"}
              </Label>
              <Input
                className="form-control"
                name="width"
                type="number"
                placeholder="width"
                value={settingFormData?.[
                  "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
                ]?.["width"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeNavItemMegaMenuIcon(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"height (Default : 50)"}
              </Label>
              <Input
                className="form-control"
                name="height"
                type="number"
                placeholder="height"
                value={settingFormData?.[
                  "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
                ]?.["height"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeNavItemMegaMenuIcon(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Font-size (Default : 20)"}
              </Label>
              <Input
                className="form-control"
                name="font-size"
                type="number"
                placeholder="font-size"
                value={settingFormData?.[
                  "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
                ]?.["font-size"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeNavItemMegaMenuIcon(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Line-height (Default : 50)"}
              </Label>
              <Input
                className="form-control"
                name="line-height"
                type="number"
                placeholder="line-height"
                value={settingFormData?.[
                  "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
                ]?.["line-height"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeNavItemMegaMenuIcon(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"border-radius (Default : 50)"}
              </Label>
              <Input
                className="form-control"
                name="border-radius"
                type="number"
                placeholder="border-radius"
                value={settingFormData?.[
                  "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
                ]?.["border-radius"]?.replace(/%/g, "")}
                onChange={(e) => handleChangeNavItemMegaMenuIcon(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <CommonColor
                color={
                  settingFormData?.[
                    "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
                  ]?.["color"]
                }
                setSettingFormData={setSettingFormData}
                className={
                  "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
                }
                classProperty={"color"}
                label={"Select Color"}
              />
            </Col>
            <Col md="4 mb-3">
              <CommonColor
                color={
                  settingFormData?.[
                    "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
                  ]?.["background-color"]
                }
                setSettingFormData={setSettingFormData}
                className={
                  "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
                }
                classProperty={"background-color"}
                label={"Select background"}
              />
            </Col>
            <Col md="6 mb-3">
              <Label htmlFor="validationCustom03">{"Box Shadow"}</Label>
              <div className="d-flex">
                <div style={{ width: "100px" }}>
                  <Input
                    className="form-control"
                    name="bs1"
                    type="number"
                    placeholder="px"
                    value={settingFormData?.[
                      "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
                    ]?.["bs1"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeNavItemMegaMenuIcon(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </div>
                <div style={{ width: "100px" }}>
                  <Input
                    className="form-control"
                    name="bs2"
                    type="number"
                    placeholder="px"
                    value={settingFormData?.[
                      "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
                    ]?.["bs2"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeNavItemMegaMenuIcon(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </div>
                <div style={{ width: "100px" }}>
                  <Input
                    className="form-control"
                    name="bs3"
                    type="number"
                    placeholder="px"
                    value={settingFormData?.[
                      "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
                    ]?.["bs3"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeNavItemMegaMenuIcon(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </div>
                <div style={{ width: "100px" }}>
                  <Input
                    className="form-control"
                    name="bs4"
                    type="number"
                    placeholder="px"
                    value={settingFormData?.[
                      "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
                    ]?.["bs4"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeNavItemMegaMenuIcon(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </div>
                <div>
                  <CommonColor
                    color={
                      settingFormData?.[
                        "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
                      ]?.["shadowColor"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={
                      "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
                    }
                    classProperty={"shadowColor"}
                    label={"Select Color"}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-2">
            <h4 className="mb-3">Add Navbar Buttton Color for Small Screen</h4>
            <Col md="4 mb-3">
              <CommonColor
                color={
                  settingFormData?.[
                    "header.style-one .header-wrapper .left-menu-icons .navbar-toggler svg"
                  ]?.["fill"]
                }
                setSettingFormData={setSettingFormData}
                className={
                  "header.style-one .header-wrapper .left-menu-icons .navbar-toggler svg"
                }
                classProperty={"fill"}
                label={"Select Color"}
              />
            </Col>
            <Col md="4 mb-3">
              <CommonColor
                color={
                  settingFormData?.[
                    "header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
                  ]?.["background-color"]
                }
                setSettingFormData={setSettingFormData}
                className={
                  "header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
                }
                classProperty={"background-color"}
                label={"Select Background"}
              />
            </Col>
          </Row>
          <hr />
          <Row className="mt-2">
            <h4 className="mb-3">
              Make Change Menu Background Color at Small Screen
            </h4>
            <Col md="4 mb-3">
              <CommonColor
                color={
                  settingFormData?.[
                    "header.style-one .header-wrapper .navbar-nav .media"
                  ]?.["background"]
                }
                setSettingFormData={setSettingFormData}
                className={
                  "header.style-one .header-wrapper .navbar-nav .media"
                }
                classProperty={"background"}
                label={"Select Background"}
              />
            </Col>
          </Row>
          <hr />
          <CustomStyle
            handleChange={handleChangeCustomStyle}
            value={settingFormData?.custom}
          />
          <Row className="mt-2">
            <div className="d-flex align-items-center gap-4 mb-3">
              {" "}
              <h4>Change Font Family</h4>
            </div>
            <Col md="6 mb-3">
              <Label htmlFor="validationCustom03">{"Font Family"}</Label>
              <select
                name="fontFamily"
                id="fontFamily"
                className="form-select"
                value={JSON.stringify(settingFormData?.fontFamily)}
                onChange={handleChangeFontFamily}
                style={{ borderRadius: "0", color: "black" }}
              >
                <option value="">--select--</option>
                {fontListGet?.map((elem) => {
                  return (
                    <option
                      value={JSON.stringify({
                        fontFamily: elem?.fontFamily,
                        googleFontScript: elem?.googleFontScript,
                      })}
                      key={elem?.fontId}
                    >
                      {elem?.title}
                    </option>
                  );
                })}
              </select>
            </Col>
          </Row>
        </div>
      </Col>
    </>
  );
};

export default NavbarStyleTwo;
