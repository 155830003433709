import React, { useContext } from "react";
import { THEME_UPDATE_MESSAGE, ThemeNo } from "../../../../constant";
import GalleryMultipageContext from "../../../context/GalleryMultipageContext/GalleryMultipageContext";
import ImagesTheme1 from "./ImagesTheme1";
import OurTeamTheme2 from "./ImagesTheme2";
import { Button, Col, Container, Row } from "reactstrap";
import {
  imagesGalleryThemeOneReset,
  imagesGalleryThemeTwoReset,
} from "./ImagesStyleThemeFunctions";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import { putUpdateImagesParent } from "../apis/ImageApi/ImageApi";
import { TostMessage } from "../../../utils";
import ImageGalleryTheme1 from "../ScreenShorts/ImageGallery/ImageGalleryTheme1.png";
import ImageGalleryTheme2 from "../ScreenShorts/ImageGallery/ImageGalleryTheme2.png";
import ImageGalleryTheme3 from "../ScreenShorts/ImageGallery/ImageGalleryTheme3.png";
import ThemeImageShow from "../../../common/ThemeImageShow";

const SettingImageParentPopup = (props) => {
  const {
    theme,
    setTheme,
    setSettingFormData,
    setBoxSection,
    setBackImageToggle,
    setIsBackgroundColor,
    id,
    collId,
    imgShow,
    setImgShow,
  } = useContext(GalleryMultipageContext);
  const { imageUpload, handleSubmitSetting, handleChangeSettings } = props;
  const { setLoaderShow } = useContext(LoaderContext);

  const resetHandler = () => {
    let setStyle;
    if (theme === ThemeNo.ThemeOne) {
      setStyle = imagesGalleryThemeOneReset;
    } else if (theme === ThemeNo.ThemeTwo) {
      setStyle = imagesGalleryThemeTwoReset;
    } else if (theme === ThemeNo.ThemeThree) {
      setStyle = imagesGalleryThemeOneReset;
    }
    setSettingFormData({ ...setStyle });
    setBackImageToggle(false);
    setBoxSection(false);
    setIsBackgroundColor(true);
  };

  const handleTheme = async (e) => {
    setLoaderShow(true);
    const { value } = e.target;
    setTheme(value);
    try {
      let formData = {
        theme: value,
        galleryId: id,
        contentMapId: collId,
      };
      const { data } = await putUpdateImagesParent(formData);
      if (data.status === 200) {
        await handleChangeSettings(id, true);
        TostMessage({
          message: { message: THEME_UPDATE_MESSAGE },
          type: "success",
        });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    } finally {
      setLoaderShow(false);
    }
  };

  const handleClose = () => {
    setImgShow(false);
  };
  const themeImg = {
    1: ImageGalleryTheme1,
    2: ImageGalleryTheme2,
    3: ImageGalleryTheme3,
  };
  return (
    <>
      <Container className="mb-5">
        <Row>
          <div className="mt-4 d-flex flex-column justify-content-center position-relative">
            <div
              className="d-flex justify-content-between position-fixed flex-wrap"
              style={{
                top: "75px",
                background: "white",
                width: "75%",
                zIndex: 999,
              }}
            >
              <h6>{"Edit"}</h6>
              <div className="d-flex gap-2 flex-wrap">
                <Button onClick={resetHandler}>Reset</Button>
                <Button onClick={() => handleSubmitSetting(true)}>
                  Restore
                </Button>
                <Button
                  onClick={() => handleSubmitSetting(null, false)}
                  color="success"
                >
                  Save & View
                </Button>
              </div>
            </div>
          </div>
        </Row>
        <Row>
          <Col>
            <label htmlFor="">Theme</label>
            <select
              name="theme"
              id="font-weight"
              className="form-select"
              value={theme}
              onChange={(e) => handleTheme(e)}
              style={{ borderRadius: "0", color: "black" }}
            >
              <option value={1}>Theme 1</option>
              <option value={2}>Theme 2</option>
              <option value={3}>Theme 3</option>
            </select>
          </Col>
        </Row>
        {!imgShow && (
          <Button
            onClick={() => setImgShow(true)}
            color="primary"
            className="mt-2"
          >
            Show Theme Image
          </Button>
        )}
        {imgShow && (
          <ThemeImageShow
            themeImg={themeImg[theme]}
            handleClose={handleClose}
          />
        )}
        {+theme === ThemeNo.ThemeThree && (
          <ImagesTheme1
            imageUpload={imageUpload}
            handleSubmitSetting={handleSubmitSetting}
          />
        )}
        {+theme === ThemeNo.ThemeTwo && (
          <OurTeamTheme2
            imageUpload={imageUpload}
            handleSubmitSetting={handleSubmitSetting}
          />
        )}
        {+theme === ThemeNo.ThemeOne && (
          <ImagesTheme1
            imageUpload={imageUpload}
            handleSubmitSetting={handleSubmitSetting}
          />
        )}
      </Container>
    </>
  );
};

export default SettingImageParentPopup;
