import React, { useContext } from "react";
import {
  Container,
  Row,
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { PLAN_MASTER } from "../../constant";
import OrgPlanContext from "../../context/OrgPlanContext/OrgPlanContext";

const OrgPlanAddPopup = (props) => {
  const { handleSubmit, emptyData } = props;
  const { addModel, setAddModel, isEdit } = useContext(OrgPlanContext);

  const CloseModel = () => {
    emptyData();
    setAddModel(false);
  };

  return (
    <>
      <Modal size="xl" isOpen={addModel} toggle={() => CloseModel()}>
        <ModalHeader toggle={() => CloseModel()}>
          {isEdit ? `${PLAN_MASTER.EDIT_THEME}` : `${PLAN_MASTER.ADD_THEME}`}
        </ModalHeader>
        <ModalBody>
          <Container fluid={true} className="p-0">
            <Row>
              <Form className="theme-form" onSubmit={(e) => handleSubmit(e)}>
                <div className="login-btn mt-2">
                  <Button color="primary" type="submit" value="submit">
                    {"Submit"}
                  </Button>
                </div>
              </Form>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};

export default OrgPlanAddPopup;
