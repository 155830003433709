import React from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { Cases } from "../../constant";
import { OpenModalCommonFunc, TostMessage } from "../../utils";
import { UploadCloud } from "react-feather";
import ChropeImage from "../../public/CropeImage";
import { Close } from "@material-ui/icons";
import { checkImageType } from "../../../utils/helper";
import { fileUploadSizes } from "../../../constant";

const ProductImageUploadPopup = (props) => {
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
    image,
    setImage,
    imageUpload,
    reupload,
    aspect,
  } = props;

  const allowedExtensions = ["jpg", "jpeg", "png", "gif"];

  const readUrl = async (event) => {
    const file = event.target.files[0];
    if (file.size <= fileUploadSizes.image) {
      const extension = file.name.split(".").pop().toLowerCase();
      if (allowedExtensions.includes(extension)) {
        if (event.target.files && event.target.files.length > 0) {
          setCrop({ unit: "%", width: 50, height: 50 });
          const reader = new FileReader();
          if (allowedExtensions[3] == extension) {
            await imageUpload(event.target.files[0]);
            OpenModalCommonFunc(Cases.PRODUCT_UPLOAD, setAllPopupState, false);
          } else {
            reader.onload = (_event) => {
              setImage((prev) => {
                return { ...prev, image: reader.result?.toString() || "" };
              });
            };
            reader.readAsDataURL(event.target.files[0]);
          }
        }
      } else {
        TostMessage({
          message: "file type required only .jpg , .png , .jpeg",
          type: "error",
          position: "custome",
        });
      }
    } else {
      TostMessage({
        message: "The selected file exceeds the maximum allowed size of 1 MB.",
        type: "error",
        position: "custome",
      });
    }
  };
  return (
    <Dialog
      open={allPopupState?.productUploadPopup}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle className="text-center">
        {"Upload File"}{" "}
        <IconButton
          edge="start"
          color="inherit"
          className="position-absolute top-0"
          style={{ right: "0px", color: "red" }}
          onClick={() =>
            OpenModalCommonFunc(Cases.PRODUCT_UPLOAD, setAllPopupState, false)
          }
        >
          <Close style={{ fontSize: "25px", fontWeight: "bold" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-slide-description"
          className="text-center"
        >
          Upload your file here to share
        </DialogContentText>
        <div>
          <div>
            <div className="mb-3 text-center ">
              {image && checkImageType(image) === "base64" && (
                <ChropeImage
                  imageWidth={66}
                  aspect={aspect}
                  image={image}
                  crop={crop}
                  setCrop={setCrop}
                  completedCrop={completedCrop}
                  setCompletedCrop={setCompletedCrop}
                  scale={scale}
                  rotate={rotate}
                  setImage={setImage}
                  setAllPopupState={setAllPopupState}
                  imageUpload={imageUpload}
                  reupload={reupload}
                />
              )}
              {(checkImageType(image) === "url" || !image) && (
                <>
                  <input
                    type="file"
                    id="file"
                    accept=".jpg, .jpeg, .png"
                    style={{
                      position: "absolute",
                      opacity: 0,
                      right: 0,
                      left: 0,
                      height: "16rem",
                    }}
                    onChange={(e) => readUrl(e)}
                  />
                  <div className="image-upload-product">
                    <UploadCloud />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </DialogContent>
      {/* <DialogActions className="d-flex justify-content-center ">
        <Button
          color="danger"
          onClick={() =>
            OpenModalCommonFunc(Cases.PRODUCT_UPLOAD, setAllPopupState, false)
          }
        >
          Cancel
        </Button>
      </DialogActions> */}
    </Dialog>
  );
};

export default ProductImageUploadPopup;
