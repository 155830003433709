import React, { useContext, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { Trash2 } from "react-feather";
import { IconButton } from "@material-ui/core";
import { ORG_ADD, TYPES, VALIDATION_MESSAGES } from "../../constant";
import { Zoom, toast } from "react-toastify";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import { useParams } from "react-router";
import { randomStr, slugUrlConverter } from "../../utils/helper";
import { checkSlugUrl } from "../../api/Organization Api/OrganizationApi";
import {
  CreateWebNavBarMap,
  UpdateWebNavBarMap,
} from "../../components ByteCard/components/MultipageByteCardBuilder/apis/NavApi/NavApi";
import SettingMultiWebContext from "../../context/SettingMultiWebContext/SettingMultiWebContext";

const SettingMultiWebAddWebNavBarMap = ({
  ByteMiniWeb_Dashboard,
  isDashboardClick = false,
}) => {
  const {
    closeModel,
    modalNav,
    WebNavBarMaster_List,
    WebNavBarMap_List,
    formvalueWebNavMaster,
    addFields,
    setAddFields,
    selectedOptions,
    setSelectedOptions,
    isEdit,
    id,
  } = useContext(SettingMultiWebContext);
  const { setLoaderShow } = useContext(LoaderContext);
  let { orgId } = useParams();

  useEffect(() => {
    WebNavBarMaster_List();
    WebNavBarMap_List({ orgId: orgId, isParent: true });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !addFields.every(
        (elem) =>
          elem?.label?.length > 0 &&
          +elem?.navId > 0 &&
          elem?.slugUrl?.length > 0
      )
    ) {
      toast?.error(VALIDATION_MESSAGES, {
        autoClose: 1000,
        transition: Zoom,
      });
    } else {
      if (isEdit) {
        let navBars = addFields.map(({ id, ...rest }) => rest)[0];
        navBars["navMapId"] = id;
        try {
          const { data } = await UpdateWebNavBarMap(navBars);
          if (data.status === 200) {
            toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            });
            if (isDashboardClick) {
              ByteMiniWeb_Dashboard();
            } else {
              WebNavBarMap_List({ orgId: orgId });
            }
            closeModel();
          }
        } catch (error) {
          setLoaderShow(false);
          toast.error(error.response.data.message, {
            autoClose: 1000,
            transition: Zoom,
          });
        }
      } else {
        let navBars = {
          orgId,
          navBars: addFields.map(({ id, ...rest }) => rest),
        };
        try {
          const { data } = await CreateWebNavBarMap(navBars);
          if (data.status === 200) {
            toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            });
            if (isDashboardClick) {
              ByteMiniWeb_Dashboard();
            } else {
              WebNavBarMap_List({ orgId: orgId });
            }
            closeModel();
          }
        } catch (error) {
          setLoaderShow(false);
          toast.error(error.response.data.message, {
            autoClose: 1000,
            transition: Zoom,
          });
        }
      }
    }
  };

  const removeUnSelectedDrop = ({ index }) => {
    let newSelectedOp = [...selectedOptions];
    newSelectedOp = newSelectedOp.filter((fil) => fil !== +index);
    return newSelectedOp;
  };

  const handleFocuseChange = async (slugUrl) => {
    if (slugUrl) {
      try {
        let paramObj = {
          slugUrl,
          type: TYPES.LANDING_PAGE,
          orgId: localStorage.getItem("orgId"),
        };
        await checkSlugUrl(paramObj);
      } catch (error) {
        toast.error(error.response.data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    }
  };

  const handleChangeParent = async (e, i) => {
    const { name, value, options, selectedIndex } = e.target;
    let old = [...addFields];

    if (name === "navId") {
      let newSelectedOp = removeUnSelectedDrop({
        index: old[i].navId,
      });
      setSelectedOptions((prev) => {
        return prev.filter((fil) => fil !== old[i].navId);
      });
      old[i][name] = +value;
      old[i]["order"] = i + 1;
      old[i]["label"] = options[selectedIndex].text;
      // old[i]["slugUrl"] = slugUrlConverter({
      //   value: options[selectedIndex].text,
      // });

      if (newSelectedOp.includes(value)) {
        alert("This option has already been selected.");
        return;
      }
      setSelectedOptions([...newSelectedOp, +value]);
    } else {
      let old = [...addFields];
      old[i][name] =
        name === "label"
          ? value
          : name === "slugUrl"
          ? slugUrlConverter({ value })
          : "";
      if (name === "label") {
        old[i]["slugUrl"] = slugUrlConverter({ value });
      }
    }
    setAddFields(old);
  };

  const deleteMoreInput = (id, index) => {
    setAddFields((prev) => {
      return prev.filter((item) => item.id !== id);
    });
    let newSelectedOp = removeUnSelectedDrop({
      index,
    });
    setSelectedOptions([...newSelectedOp]);
  };
  const addMoreInput = () => {
    setAddFields((prev) => {
      return [
        ...prev,
        {
          id: randomStr(2),
          order: "",
          navId: "",
          label: "",
          slugUrl: "",
        },
      ];
    });
  };

  return (
    <Modal size="lg" isOpen={modalNav}>
      {/* <LoaderResponse /> */}
      <ModalHeader toggle={() => closeModel()}>
        <span>{isEdit ? "Edit" : "Add"} Pages (Menu)</span>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form onSubmit={(e) => handleSubmit(e)}>
                  <>
                    <Row>
                      {/* {formvalueWebNavNull?.map((parents, i) => {
                        return (
                          <Col md="4 mb-4" key={parents.navId}>
                            <Label htmlFor="validationCustom14">
                              {"Navbar"}
                            </Label>
                            <select
                              name="parentId"
                              id=""
                              className="form-select"
                              value={parents.parentId}
                              onChange={(e) => handleChangeParent(e, i)}
                            >
                              <option value="">--select--</option>
                              {parents?.data?.map((elem) => {
                                return (
                                  <option value={elem.navMapId}>
                                    {elem.navbarDetail?.label}
                                  </option>
                                );
                              })}
                            </select>
                          </Col>
                        );
                      })} */}
                      {addFields.map((ele, i) => {
                        return (
                          <div>
                            <div className="d-flex gap-2 pt-2">
                              <div>
                                <Label htmlFor="validationCustom14">
                                  {"Select Page"}
                                </Label>
                                <select
                                  name="navId"
                                  id=""
                                  className="form-select"
                                  value={ele.navId}
                                  onChange={(e) => handleChangeParent(e, i)}
                                >
                                  <option value="">--select--</option>
                                  {formvalueWebNavMaster.map((elem) => {
                                    return (
                                      <option
                                        value={elem.navId}
                                        key={elem.navId}
                                        // disabled={selectedOptions.includes(
                                        //   elem.navId
                                        // )}
                                      >
                                        {elem.label}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div>
                                <Label htmlFor="validationCustom14">
                                  {"Title"}
                                </Label>
                                <input
                                  type="text"
                                  name="label"
                                  value={ele.label}
                                  className="form-control"
                                  placeholder="Enter Title"
                                  onChange={(e) => handleChangeParent(e, i)}
                                />
                              </div>
                              <div>
                                <Label htmlFor="validationCustom14">
                                  {"Slug url"}
                                </Label>
                                <input
                                  type="text"
                                  name="slugUrl"
                                  value={ele.slugUrl}
                                  className="form-control"
                                  placeholder="Slug url"
                                  onChange={(e) => handleChangeParent(e, i)}
                                  onBlur={() => handleFocuseChange(ele.slugUrl)}
                                />
                              </div>

                              {addFields.length >= 2 && (
                                <IconButton
                                  edge="start"
                                  color="inherit"
                                  style={{ marginLeft: "5px", color: "red" }}
                                  onClick={() =>
                                    deleteMoreInput(ele.id, ele.navId)
                                  }
                                >
                                  <Trash2 />
                                </IconButton>
                              )}
                            </div>
                          </div>
                        );
                      })}
                      {!isEdit && (
                        <div className="d-flex justify-content-center pt-2">
                          <Button
                            onClick={() => addMoreInput()}
                            color="primary"
                          >
                            + Add More
                          </Button>
                        </div>
                      )}
                    </Row>
                  </>
                  <Row style={{ paddingTop: "30px" }}>
                    <button
                      className="btn btn-primary"
                      type="submit"
                      value="Submit"
                      // onClick={handleSubmit}
                    >
                      {ORG_ADD.SUBMIT}
                    </button>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default SettingMultiWebAddWebNavBarMap;
