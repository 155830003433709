import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../../layout/breadcrumb";
import Header from "./header/DomainHeader/header";
import SeoDetails from "./seoDetails/SeoDetails";
import Aboutus from "./aboutus/Aboutus";
import Videos from "./videos/Videos";
import OurTeam from "./ourTeam/OurTeam";
import Details from "./Details/Details";
import Blog from "./blog/Blog";
import Packages from "./packages/Packages";
import { OpenModalCommonFunc, TostMessage } from "../../utils";
import SetSubDomainPopup from "../../Modals/SubdomainSetPopup";
import { Cases } from "../../constant";
import ByteCardBuilderContext from "../../context/BytecardBilderContext/ByteCardBuilderContext";
import { getDomainWithoutSubdomain } from "../../../utils/helper";
import { IconButton } from "@material-ui/core";
import LoaderContext from "../../../context/LoaderContext/LoaderContext";
import Multipage_ByteMiniWebDashboardContext from "../../context/Multipage_ByteMiniWeb_Dashboard_Context/Multipage_ByteMiniWeb_Dashboard_Context";
import LatestNews from "./latestNews/LatestNews";
import ItemFeature from "./itemFeature/ItemFeature";
import OurClient from "./ourClient/OurClient";
import ImageBanner from "./imageBanner/ImageBanner";
import VideoBanner from "./videoBanner/videoBanner";
import Faqs from "./faq's/Faq's";
import Steps from "./staps/Steps";
import Career from "./career/Career";
import ServiceCategory from "./ServiceAndCategory/ServiceCategory";
import SliderMultipage from "./SliderMultipage/SliderMultipage";
import ImagesMultipage from "./imagesMultipage/ImagesMultipage";
import DownloadMultipage from "./DownloadMultipage/DownloadMultipage";
import SocialMediaMultipage from "./SocialMediaMultipage/SocialMediaMultipage";
import { Copy, Edit, Settings } from "react-feather";
import copy from "copy-to-clipboard";
import { useNavigate, useParams } from "react-router";
import { classes } from "../../../data/layouts";
import TestimonialMultipage from "./TestimonialMultipage/TestimonialMultipage";
import GetWhatsAppLink from "./getWhatsAppLink/GetWhatsAppLink";
import { Byte_Multi_Web_Dashboard } from "./apis/SliderApi/SliderApi";
import { isSections } from "../../../constant";
import ServiceCounter from "./serviceCounter/ServiceCounter";
import ContactUs from "./contactUs/ContactUs";
import Inquiry from "./inquirys/Inquiry";
import PageTitle from "./pageTitle/PageTitle";
import ProductMultipage from "./ProductMultipage/ProductMultipage";
import { WebNavBarMapList } from "./apis/NavApi/NavApi";
import PopupDialog from "./PopupDialog/PopupDialog";
import ProductBuy from "./productBuy/ProductBuy";
import Footer from "./Footer/Footer";
import FooterHeader from "./header/FooterHeader/FooterHeader";
import { CreateCloneWebMaster } from "../../../api/SupperAdminOrganizationApi/Organization";
import { WebCategoryMap_List } from "../../../api/WebCategoryMapApi/WebCategoryMapApi";
import Select, { components } from "react-select";
import PageviewIcon from "@mui/icons-material/Pageview";
import { Button } from "react-bootstrap";
import { cloneCategory_List } from "../../../api/CloneCategoryApi/CloneCategoryApi";
import CommonStylesChanges from "./CommonStylesChanges/CommonStylesChanges";
import SettingMultiWebContext from "../../../context/SettingMultiWebContext/SettingMultiWebContext";
import SettingMultiWebAddWebConfigPage from "../../../components/SettingsMultiWeb/SettingMultiWebAddWebConfigPage";
import SettingMultiWebAddWebNavBarMap from "../../../components/SettingsMultiWeb/SettingMultiWebAddWebNavBarMap";
import SectionPositionChange from "../../../components/SettingsMultiWeb/SectionPositionChange";
import AlignSectionModal from "./AlignSectionModal/AlignSectionModal";
import CloneSectionModal from "./CloneSectionModal/CloneSectionModal";
import AddIcon from "@mui/icons-material/Add";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import SettingMultiWebAddChilds from "../../../components/SettingsMultiWeb/SettingMultiWebAddChilds";
import CustomForm from "./CustomForm/CustomForm";

const Option = (props) => {
  const handleIconClick = (data) => {
    window.open(`https://${data.label}`, "_blank");
  };
  return (
    <components.Option {...props}>
      <div>
        {" "}
        <div className="d-flex justify-content-between  ">
          <span>{props.children}</span>
          <span
            role="img"
            aria-label="Icon"
            onClick={() => handleIconClick(props.data)}
          >
            <PageviewIcon />
          </span>
        </div>
      </div>
    </components.Option>
  );
};

const MultipageByteCardBuilder = () => {
  const { apiData, setApiData } = useContext(
    Multipage_ByteMiniWebDashboardContext
  );
  const { setAllPopupState, allPopupState, setDomain } = useContext(
    ByteCardBuilderContext
  );
  let { hwId } = useParams();
  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();
  const history = useNavigate();
  const [navbarData] = useState([]);
  const [navMapListData, setNamMapListData] = useState([]);
  const [navMapId, setNavMapId] = useState("");
  const [selectedNavName, setSelectedNavName] = useState("");
  const [domainShow, setDomainShow] = useState("");
  const { setLoaderShow } = useContext(LoaderContext);
  const [showDomainChange, setShowDomainChange] = useState(false);
  const [CloneCategoryIs, setCloneCategoryIs] = useState();
  const [allItems, setAllItems] = useState([]);
  const [CloneCategoryMapIs, setCloneCategoryMapIs] = useState();
  const [allItemsMap, setAllItemsMap] = useState([]);
  const {
    modalConfig,
    setToggleSubmitConfig,
    setModalConfig,
    setNavMapID,
    modalNav,
    setToggleSubmitNav,
    setModalNav,
    setId,
    modalSectionPo,
    setModalSectionPo,
    setSelectedNavMap,
    cloneAlignModal,
    setModalChild,
    WebNavBarMap_List: navbarMap_List,
    modalChild,
    EditHandle,
  } = useContext(SettingMultiWebContext);

  const ByteMiniWeb_Dashboard = async () => {
    let paramObj = {
      webId: localStorage.getItem("webId"),
    };
    const paramObjForNavMap = {
      showAll: true,
      sortBy: "order",
      sortOrder: "ASC",
      search: { isActive: true },
    };
    try {
      setApiData((prev) => {
        return {
          ...prev,
          isLoading: true,
        };
      });
      const { data: navBarMapList } = await WebNavBarMapList(paramObjForNavMap);
      if (navBarMapList.payload.data?.length) {
        paramObj["navMapId"] =
          navMapId !== "" ? navMapId : navBarMapList.payload.data[0]?.navMapId;
      }
      const { data } = await Byte_Multi_Web_Dashboard(paramObj);
      if (navBarMapList.status === 200) {
        setNamMapListData(navBarMapList.payload.data);
        if (navMapId === "" && navBarMapList.payload.data?.length) {
          setNavMapId(navBarMapList.payload.data[0]?.navMapId);
          setSelectedNavName(
            navBarMapList.payload.data[0]?.label ||
              navBarMapList.payload.data[0]?.navbarDetail.label
          );
        }
      }
      const checkIs = data.payload.data.filter((ele) => ele["otherSetting"])[0]
        .isSample;
      await setShowDomainChange(checkIs);
      if (checkIs) {
        try {
          let paramObj = {
            showAll: true,
          };
          const { data } = await cloneCategory_List(paramObj, "MultiPage");
          if (data.status === 200) {
            setAllItems(
              data.payload.data.map((ele) => {
                return {
                  value: ele.cloneCategoryId,
                  label: ele.name,
                };
              })
            );
          } else {
          }
        } catch (error) {}
      }

      const webCategory = data.payload.data.filter((elem) => elem?.webCategory);
      if (webCategory?.length) {
        const [domainDetails] = webCategory;
        let value = getDomainWithoutSubdomain(domainDetails.domain);
        setDomain(domainDetails.domain.split("." + value)[0]);
        if (domainDetails?.isDomainChanged === false) {
          OpenModalCommonFunc(Cases.SUB_DOMAIN, setAllPopupState);
        }
        setDomainShow(domainDetails.domain);
      }

      if (data.status === 200) {
        setApiData((prev) => {
          return {
            ...prev,
            isLoading: false,
            data: data.payload.data,
          };
        });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  useEffect(() => {
    ByteMiniWeb_Dashboard();
  }, [navMapId]);

  const getData = () => {};

  const SettingMiniWebRedirect = (orgId) => {
    history(`/${hwId}/${orgId}/SettingMulti/${layout}`);
  };

  const handleChangeSelect = (selectedOption) => {
    setCloneCategoryIs(selectedOption);
  };

  const getWebMasterMapList = async () => {
    try {
      let paramObj = {};
      if (CloneCategoryIs) {
        paramObj["search"] = { cloneCategoryId: CloneCategoryIs.value };
      }
      const { data } = await WebCategoryMap_List(paramObj, "MultiPage");
      if (data.status === 200) {
        setAllItemsMap(
          data.payload.data.map((ele) => {
            return {
              value: ele.catWebMapId,
              webId: ele.webId,
              label: ele.webDetail.domain,
            };
          })
        );
      }
    } catch (error) {}
  };
  const handleChangeSelectMap = (selectedOption) => {
    setCloneCategoryMapIs(selectedOption);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (CloneCategoryIs) {
      setCloneCategoryMapIs("");
      getWebMasterMapList();
    }
  }, [CloneCategoryIs]);

  const handleSelectSubmit = async () => {
    try {
      setLoaderShow(true);
      let formData = {
        toWebId: CloneCategoryMapIs.webId,
        catWebMapId: CloneCategoryMapIs.value,
        // fromWebId: "",
      };
      const { data } = await CreateCloneWebMaster(formData, "MultiPage");
      if (data.status === 200) {
        setLoaderShow(false);
        ByteMiniWeb_Dashboard();
        setShowDomainChange(false);
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const AddNewSection = () => {
    setToggleSubmitConfig(false);
    setModalConfig(true);
    setNavMapID(navMapId);
  };
  const copyButton = (domain) => {
    copy(domain);
    TostMessage({
      message: "copy domain",
      type: "success",
      position: "custome",
    });
  };

  const AddChild = () => {
    setModalChild(true);
    navbarMap_List({
      showAll: true,
      isChild: true,
      sortOrder: "ASC",
      sortBy: "order",
    });
  };

  return (
    <div>
      <Container fluid={true}>
        <Row className="d-flex justify-content-between p-3 p-xxl-1">
          <Col md="4 mb-12" style={{ padding: "30px 0" }}>
            <h3>Website Builder</h3>
          </Col>
          <Col style={{ padding: "30px 0" }}>
            <div className="d-flex flex-wrap ">
              <div>
                <a
                  href={`https://${domainShow}`}
                  className="text-blue"
                  target="_black"
                >
                  {` https://${domainShow}`}
                </a>
                <p>Your Website is ready to share with your customers</p>
              </div>
              <div className="d-flex justify-content-center  align-items-center gap-2">
                <div>
                  <IconButton
                    aria-label="copy"
                    onClick={() => copyButton(domainShow)}
                  >
                    <Copy
                      style={{
                        width: "20px",
                        height: "20px",
                        position: "inherit",
                        top: "0",
                      }}
                      className="text-blue"
                    />
                  </IconButton>
                </div>
              </div>
            </div>
          </Col>
          <Col md="4 mb-12">
            <Breadcrumbs parent={"Website builder"} />
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Card>
              {showDomainChange && (
                <CardHeader>
                  <div className="d-flex justify-content-center  flex-wrap align-items-center ">
                    <>
                      <Col md="3 mb-4">
                        {" "}
                        <h3>Clone Website</h3>
                      </Col>
                      <Col md="3 mb-4 px-2">
                        <Label htmlFor="validationCustom03">
                          {"Select Clone Category"}
                        </Label>
                        <Select
                          // isMulti
                          closeMenuOnSelect={true}
                          options={allItems}
                          value={CloneCategoryIs}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={(e) => handleChangeSelect(e)}
                        />
                      </Col>
                      <Col md="3 mb-4 px-2">
                        <Label htmlFor="validationCustom03">
                          {"Select Clone Category Map"}
                        </Label>
                        <Select
                          // isMulti
                          closeMenuOnSelect={true}
                          options={allItemsMap}
                          value={CloneCategoryMapIs}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={(e) => handleChangeSelectMap(e)}
                          components={{ Option }}
                        />
                      </Col>
                      <Col className="d-flex align-items-center  px-2 ">
                        {" "}
                        <Button onClick={() => handleSelectSubmit()}>
                          {"Submit"}
                        </Button>
                      </Col>
                    </>
                  </div>
                </CardHeader>
              )}
              <CardHeader>
                <Row className="d-flex justify-content-between flex-wrap align-items-center ">
                  <Col md="2 mb-12" style={{ marginBottom: "6px" }}>
                    <select
                      className="form-select"
                      onChange={(e) => {
                        const { selectedIndex } = e.target.options;
                        setNavMapId(e.target.value);
                        setSelectedNavName(
                          e.target.options[selectedIndex].text
                        );
                      }}
                      value={navMapId}
                      style={{ height: "43px" }}
                    >
                      <option value="">All</option>
                      {navMapListData?.map((navMap) => {
                        return (
                          <option value={navMap.navMapId} key={navMap.navMapId}>
                            {navMap?.label || navMap.navbarDetail.label}
                          </option>
                        );
                      })}
                    </select>
                  </Col>
                  <Col
                    md="10 mb-12"
                    className="d-flex flex-wrap justify-content-between
                        align-item-center"
                  >
                    <div className="d-flex flex-wrap gap-2">
                      <Button
                        aria-label="edit"
                        onClick={() => {
                          EditHandle(
                            navMapId,
                            localStorage.getItem("orgId"),
                            ByteMiniWeb_Dashboard
                          );
                        }}
                        style={{
                          marginBottom: "5px",
                          padding: "5px 10px",
                        }}
                        className="d-flex align-item-center justify-content-center"
                      >
                        <span
                          style={{ paddingRight: "5px", paddingTop: "2px" }}
                        >
                          <Edit />
                        </span>{" "}
                        <span style={{ paddingTop: "5px" }}>
                          Edit Page Name
                        </span>
                      </Button>
                      <Button
                        aria-label="edit"
                        onClick={() => {
                          setToggleSubmitNav(false);
                          setModalNav(true);
                          setId(navMapId);
                        }}
                        style={{
                          marginBottom: "5px",
                          padding: "5px 10px",
                        }}
                        className="d-flex align-item-center justify-content-center"
                      >
                        <span
                          style={{ paddingRight: "5px", paddingTop: "2px" }}
                        >
                          <AddIcon />
                        </span>{" "}
                        <span style={{ paddingTop: "5px" }}>
                          Create Web Page
                        </span>
                      </Button>
                      <Button
                        aria-label="edit"
                        onClick={() => AddNewSection()}
                        style={{ marginBottom: "5px", padding: "5px 10px" }}
                        className="d-flex align-item-center justify-content-center"
                      >
                        <span
                          style={{ paddingRight: "5px", paddingTop: "2px" }}
                        >
                          <AddIcon />
                        </span>{" "}
                        <span style={{ paddingTop: "5px" }}>Add Section</span>
                      </Button>
                      <Button
                        aria-label="edit"
                        onClick={() => AddChild()}
                        style={{ marginBottom: "5px", padding: "5px 10px" }}
                        className="d-flex align-item-center justify-content-center"
                      >
                        <span
                          style={{ paddingRight: "5px", paddingTop: "2px" }}
                        >
                          <SwapVertIcon />
                        </span>{" "}
                        <span style={{ paddingTop: "5px" }}>Menu Order</span>
                      </Button>
                      <Button
                        aria-label="edit"
                        onClick={() => {
                          setModalSectionPo(true);
                          setSelectedNavMap({ value: navMapId });
                        }}
                        style={{ marginBottom: "5px", padding: "5px 10px" }}
                        className="d-flex align-item-center justify-content-center"
                      >
                        <span
                          style={{ paddingRight: "5px", paddingTop: "2px" }}
                        >
                          <SwapVertIcon />
                        </span>{" "}
                        <span style={{ paddingTop: "5px" }}>Section Order</span>
                      </Button>
                      <CommonStylesChanges />
                      <Header
                        domainShow={domainShow}
                        ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                        webCategoryDetails={apiData?.data.filter(
                          (ele) => ele?.otherSetting
                        )}
                      />
                    </div>
                    <IconButton
                      aria-label="edit"
                      style={{ color: "green" }}
                      onClick={() =>
                        SettingMiniWebRedirect(localStorage.getItem("orgId"))
                      }
                    >
                      <Settings />
                    </IconButton>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  {apiData?.data
                    .filter(
                      (ele) =>
                        ele?.contentDetail?.name === isSections.isSocialMedia
                    )
                    .map((ele, index) => {
                      return (
                        <SocialMediaMultipage
                          index={index}
                          pageConfigDetails={ele?.pageConfigDetails}
                          socialMediaDetail={ele.socialMediaDetail}
                          contentMapId={ele.contentMapId}
                          webMasterDetail={apiData?.data?.webMasterDetail}
                          navbarData={navbarData}
                          getData={getData}
                          contentDetail={ele.contentDetail}
                          ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                        />
                      );
                    })}
                  {apiData?.data
                    .filter(
                      (ele) =>
                        ele?.contentDetail?.name === isSections.isItemFeature
                    )
                    .map((ele, index) => {
                      return (
                        <ItemFeature
                          pageConfigDetails={ele?.pageConfigDetails}
                          itemFeatureDetail={ele.itemFeatureDetail}
                          contentMapId={ele.contentMapId}
                          webMasterDetail={apiData?.data?.webMasterDetail}
                          navbarData={navbarData}
                          getData={getData}
                          contentDetail={ele.contentDetail}
                          ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                        />
                      );
                    })}
                  {apiData?.data
                    .filter(
                      (ele) => ele?.contentDetail?.name === isSections.isGallery
                    )
                    .map((ele, index) => {
                      return (
                        <ImagesMultipage
                          pageConfigDetails={ele?.pageConfigDetails}
                          galleryDetail={ele.galleryDetail}
                          contentMapId={ele.contentMapId}
                          navMapId={navMapId}
                          webMasterDetail={apiData?.data?.webMasterDetail}
                          navbarData={navbarData}
                          getData={getData}
                          contentDetail={ele.contentDetail}
                          ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                        />
                      );
                    })}

                  {apiData?.data
                    ?.filter(
                      (ele) => ele?.contentDetail?.name === isSections.isOurTeam
                    )
                    .map((ele, index) => {
                      return (
                        <OurTeam
                          pageConfigDetails={ele?.pageConfigDetails}
                          ourTeamDetail={ele.ourTeamDetail}
                          contentMapId={ele.contentMapId}
                          navMapId={navMapId}
                          navbarData={navbarData}
                          getData={getData}
                          contentDetail={ele.contentDetail}
                          ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                        />
                      );
                    })}
                  {apiData?.data
                    ?.filter(
                      (ele) =>
                        ele?.contentDetail?.name === isSections.isVideoBanner
                    )
                    .map((ele, index) => {
                      return (
                        <VideoBanner
                          pageConfigDetails={ele?.pageConfigDetails}
                          videoBannerDetail={ele.videoBannerDetail}
                          contentMapId={ele.contentMapId}
                          webMasterDetail={apiData?.data?.webMasterDetail}
                          navbarData={navbarData}
                          getData={getData}
                          contentDetail={ele.contentDetail}
                          ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                        />
                      );
                    })}

                  {apiData?.data
                    ?.filter(
                      (ele) =>
                        ele?.contentDetail?.name === isSections.isImageBanner
                    )
                    .map((ele, index) => {
                      return (
                        <ImageBanner
                          pageConfigDetails={ele?.pageConfigDetails}
                          imageBannerDetail={ele.imageBannerDetail}
                          contentMapId={ele.contentMapId}
                          webMasterDetail={apiData?.data?.webMasterDetail}
                          navbarData={navbarData}
                          getData={getData}
                          contentDetail={ele.contentDetail}
                          ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                        />
                      );
                    })}

                  {apiData?.data
                    ?.filter(
                      (ele) => ele?.contentDetail?.name === isSections.isFaqs
                    )
                    .map((ele, index) => {
                      return (
                        <Faqs
                          pageConfigDetails={ele?.pageConfigDetails}
                          faqsDetail={ele.faqsDetail}
                          contentMapId={ele.contentMapId}
                          webMasterDetail={apiData?.data?.webMasterDetail}
                          navbarData={navbarData}
                          navMapId={navMapId}
                          getData={getData}
                          contentDetail={ele.contentDetail}
                          ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                        />
                      );
                    })}

                  {apiData?.data
                    ?.filter(
                      (ele) =>
                        ele?.contentDetail?.name === isSections.isDownload
                    )
                    .map((ele, index) => {
                      return (
                        <DownloadMultipage
                          pageConfigDetails={ele?.pageConfigDetails}
                          downloadDetail={ele.downloadDetail}
                          contentMapId={ele.contentMapId}
                          navMapId={navMapId}
                          webMasterDetail={apiData?.data?.webMasterDetail}
                          navbarData={navbarData}
                          getData={getData}
                          contentDetail={ele.contentDetail}
                          ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                        />
                      );
                    })}

                  {apiData?.data
                    ?.filter(
                      (ele) => ele?.contentDetail?.name === isSections.isBlog
                    )
                    .map((ele, index) => {
                      return (
                        <Blog
                          pageConfigDetails={ele?.pageConfigDetails}
                          blogCategoryDetail={ele?.blogCategoryDetail}
                          contentMapId={ele?.contentMapId}
                          navMapId={navMapId}
                          webMasterDetail={apiData?.data?.webMasterDetail}
                          navbarData={navbarData}
                          getData={getData}
                          contentDetail={ele.contentDetail}
                          ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                        />
                      );
                    })}
                  {apiData?.data
                    ?.filter(
                      (ele) =>
                        ele?.contentDetail?.name === isSections.isTestimonial
                    )
                    .map((ele, index) => {
                      return (
                        <TestimonialMultipage
                          pageConfigDetails={ele?.pageConfigDetails}
                          testimonialDetail={ele?.testimonialDetail}
                          contentMapId={ele?.contentMapId}
                          navbarData={navbarData}
                          webMasterDetail={apiData?.data?.webMasterDetail}
                          navMapId={navMapId}
                          getData={getData}
                          contentDetail={ele.contentDetail}
                          ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                        />
                      );
                    })}
                  {apiData?.data
                    ?.filter(
                      (ele) =>
                        ele?.contentDetail?.name ===
                        isSections.isServiceCategory
                    )
                    .map((ele, index) => {
                      return (
                        <ServiceCategory
                          pageConfigDetails={ele?.pageConfigDetails}
                          serviceCategoryDetail={ele?.serviceCategoryDetail}
                          contentMapId={ele?.contentMapId}
                          navMapId={navMapId}
                          webMasterDetail={apiData?.data?.webMasterDetail}
                          navbarData={navbarData}
                          getData={getData}
                          contentDetail={ele.contentDetail}
                          ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                        />
                      );
                    })}
                  {apiData?.data
                    ?.filter(
                      (ele) =>
                        ele?.contentDetail?.name === isSections.isPackages
                    )
                    .map((ele, index) => {
                      return (
                        <Packages
                          pageConfigDetails={ele?.pageConfigDetails}
                          packagesDetail={ele?.packagesDetail}
                          contentMapId={ele?.contentMapId}
                          navMapId={navMapId}
                          webMasterDetail={apiData?.data?.webMasterDetail}
                          navbarData={navbarData}
                          getData={getData}
                          contentDetail={ele.contentDetail}
                          ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                        />
                      );
                    })}

                  {apiData?.data
                    ?.filter(
                      (ele) =>
                        ele?.contentDetail?.name ===
                        isSections.isGetLinkOnWhatsApp
                    )
                    .map((ele, index) => {
                      return (
                        <GetWhatsAppLink
                          pageConfigDetails={ele?.pageConfigDetails}
                          getLinkOnWhatsAppDetail={ele?.getLinkOnWhatsAppDetail}
                          contentMapId={ele?.contentMapId}
                          webMasterDetail={apiData?.data?.webMasterDetail}
                          navbarData={navbarData}
                          getData={getData}
                          contentDetail={ele.contentDetail}
                          ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                        />
                      );
                    })}
                  {apiData?.data
                    ?.filter(
                      (ele) => ele?.contentDetail?.name === isSections.isSteps
                    )
                    .map((ele, index) => {
                      return (
                        <Steps
                          pageConfigDetails={ele?.pageConfigDetails}
                          stepsDetail={ele?.stepsDetail}
                          contentMapId={ele?.contentMapId}
                          webMasterDetail={apiData?.data?.webMasterDetail}
                          navbarData={navbarData}
                          getData={getData}
                          contentDetail={ele.contentDetail}
                          ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                        />
                      );
                    })}
                  {apiData?.data
                    ?.filter(
                      (ele) =>
                        ele?.contentDetail?.name === isSections.isServiceCounter
                    )
                    .map((ele, index) => {
                      return (
                        <ServiceCounter
                          pageConfigDetails={ele?.pageConfigDetails}
                          serviceCounterDetail={ele?.serviceCounterDetail}
                          contentMapId={ele?.contentMapId}
                          webMasterDetail={apiData?.data?.webMasterDetail}
                          navbarData={navbarData}
                          getData={getData}
                          contentDetail={ele.contentDetail}
                          ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                        />
                      );
                    })}
                  {apiData?.data
                    ?.filter(
                      (ele) =>
                        ele?.contentDetail?.name === isSections.isSeoDetail
                    )
                    .map((ele, index) => {
                      return (
                        <SeoDetails
                          pageConfigDetails={ele?.pageConfigDetails}
                          seoDetailsDetail={ele?.seoDetailsDetail}
                          contentMapId={ele?.contentMapId}
                          webMasterDetail={apiData?.data?.webMasterDetail}
                          navbarData={navbarData}
                          getData={getData}
                          contentDetail={ele.contentDetail}
                          ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                        />
                      );
                    })}
                  {apiData?.data
                    ?.filter(
                      (ele) =>
                        ele?.contentDetail?.name === isSections.isLatestNews
                    )
                    .map((ele, index) => {
                      return (
                        <LatestNews
                          pageConfigDetails={ele?.pageConfigDetails}
                          latestNewsDetail={ele?.latestNewsDetail}
                          contentMapId={ele?.contentMapId}
                          webMasterDetail={apiData?.data?.webMasterDetail}
                          navbarData={navbarData}
                          getData={getData}
                          contentDetail={ele.contentDetail}
                          ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                        />
                      );
                    })}
                  {apiData?.data
                    ?.filter(
                      (ele) => ele?.contentDetail?.name === isSections.isCareer
                    )
                    .map((ele, index) => {
                      return (
                        <Career
                          pageConfigDetails={ele?.pageConfigDetails}
                          careerDetail={ele?.careerDetail}
                          contentMapId={ele?.contentMapId}
                          webMasterDetail={apiData?.data?.webMasterDetail}
                          navbarData={navbarData}
                          getData={getData}
                          contentDetail={ele.contentDetail}
                          ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                        />
                      );
                    })}
                  {apiData?.data
                    ?.filter(
                      (ele) =>
                        ele?.contentDetail?.name === isSections.isContactUs
                    )
                    .map((ele, index) => {
                      return (
                        <ContactUs
                          pageConfigDetails={ele?.pageConfigDetails}
                          contactDetail={ele?.contactDetail}
                          contentMapId={ele?.contentMapId}
                          webMasterDetail={apiData?.data?.webMasterDetail}
                          navbarData={navbarData}
                          getData={getData}
                          contentDetail={ele.contentDetail}
                          ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                        />
                      );
                    })}
                  {apiData?.data
                    ?.filter(
                      (ele) =>
                        ele?.contentDetail?.name === isSections.isOurClient
                    )
                    .map((ele, index) => {
                      return (
                        <OurClient
                          pageConfigDetails={ele?.pageConfigDetails}
                          ourClientDetail={ele?.ourClientDetail}
                          contentMapId={ele?.contentMapId}
                          webMasterDetail={apiData?.data?.webMasterDetail}
                          navbarData={navbarData}
                          getData={getData}
                          navMapId={navMapId}
                          contentDetail={ele.contentDetail}
                          ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                        />
                      );
                    })}

                  {apiData?.data
                    ?.filter(
                      (ele) => ele?.contentDetail?.name === isSections.isSlider
                    )
                    .map((ele, index) => {
                      return (
                        <SliderMultipage
                          pageConfigDetails={ele?.pageConfigDetails}
                          sliderDetail={ele?.sliderDetail}
                          contentMapId={ele?.contentMapId}
                          webMasterDetail={apiData?.data?.webMasterDetail}
                          navbarData={navbarData}
                          getData={getData}
                          contentDetail={ele.contentDetail}
                          ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                        />
                      );
                    })}
                  {apiData?.data
                    ?.filter(
                      (ele) =>
                        ele?.contentDetail?.name === isSections.isDetailSection
                    )
                    .map((ele, index) => {
                      return (
                        <Details
                          pageConfigDetails={ele?.pageConfigDetails}
                          detailSectionDetail={ele?.detailSectionDetail}
                          contentMapId={ele?.contentMapId}
                          webMasterDetail={apiData?.data?.webMasterDetail}
                          navbarData={navbarData}
                          getData={getData}
                          contentDetail={ele.contentDetail}
                          ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                        />
                      );
                    })}
                  {apiData?.data
                    ?.filter(
                      (ele) =>
                        ele?.contentDetail?.name === isSections.isPageTitle
                    )
                    .map((ele, index) => {
                      return (
                        <PageTitle
                          pageConfigDetails={ele?.pageConfigDetails}
                          pageTitleDetail={ele?.pageTitleDetail}
                          contentMapId={ele?.contentMapId}
                          webMasterDetail={apiData?.data?.webMasterDetail}
                          navbarData={navbarData}
                          getData={getData}
                          contentDetail={ele.contentDetail}
                          ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                        />
                      );
                    })}
                  {apiData?.data
                    ?.filter(
                      (ele) => ele?.contentDetail?.name === isSections.isAboutUs
                    )
                    .map((ele, index) => {
                      return (
                        <Aboutus
                          pageConfigDetails={ele?.pageConfigDetails}
                          aboutUsDetail={ele?.aboutUsDetail}
                          contentMapId={ele?.contentMapId}
                          webMasterDetail={apiData?.data?.webMasterDetail}
                          navbarData={navbarData}
                          getData={getData}
                          contentDetail={ele.contentDetail}
                          ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                        />
                      );
                    })}
                  {apiData?.data
                    ?.filter(
                      (ele) => ele?.contentDetail?.name === isSections.isProduct
                    )
                    .map((ele, index) => {
                      return (
                        <ProductMultipage
                          pageConfigDetails={ele?.pageConfigDetails}
                          productDetail={ele?.productDetail}
                          contentMapId={ele?.contentMapId}
                          navMapId={navMapId}
                          webMasterDetail={apiData?.data?.webMasterDetail}
                          navbarData={navbarData}
                          getData={getData}
                          contentDetail={ele.contentDetail}
                          ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                        />
                      );
                    })}
                  {apiData?.data
                    ?.filter(
                      (ele) => ele?.contentDetail?.name === isSections.isInquiry
                    )
                    .map((ele, index) => {
                      return (
                        <Inquiry
                          pageConfigDetails={ele?.pageConfigDetails}
                          inquiryDetail={ele?.inquiryDetail}
                          contentMapId={ele?.contentMapId}
                          webMasterDetail={apiData?.data?.webMasterDetail}
                          navbarData={navbarData}
                          getData={getData}
                          contentDetail={ele.contentDetail}
                          ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                        />
                      );
                    })}
                  {apiData?.data
                    ?.filter(
                      (ele) => ele?.contentDetail?.name === isSections.isVideo
                    )
                    .map((ele, index) => {
                      return (
                        <Videos
                          pageConfigDetails={ele?.pageConfigDetails}
                          videosDetail={ele?.videosDetail}
                          contentMapId={ele?.contentMapId}
                          navMapId={navMapId}
                          webMasterDetail={apiData?.data?.webMasterDetail}
                          navbarData={navbarData}
                          getData={getData}
                          contentDetail={ele.contentDetail}
                          ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                        />
                      );
                    })}
                  {apiData?.data
                    ?.filter(
                      (ele) =>
                        ele?.contentDetail?.name === isSections.isPopUpDialog
                    )
                    .map((ele, index) => {
                      return (
                        <PopupDialog
                          pageConfigDetails={ele?.pageConfigDetails}
                          videosDetail={ele?.videosDetail}
                          contentMapId={ele?.contentMapId}
                          webMasterDetail={apiData?.data?.webMasterDetail}
                          navbarData={navbarData}
                          getData={getData}
                          contentDetail={ele.contentDetail}
                          ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                        />
                      );
                    })}
                  {apiData?.data
                    ?.filter(
                      (ele) =>
                        ele?.contentDetail?.name === isSections.isProductBuy
                    )
                    .map((ele, index) => {
                      return (
                        <ProductBuy
                          pageConfigDetails={ele?.pageConfigDetails}
                          productBuyDetail={ele?.productBuyDetail}
                          contentMapId={ele?.contentMapId}
                          navMapId={navMapId}
                          webMasterDetail={apiData?.data?.webMasterDetail}
                          navbarData={navbarData}
                          getData={getData}
                          contentDetail={ele.contentDetail}
                          ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                        />
                      );
                    })}
                  {apiData?.data
                    ?.filter(
                      (ele) => ele?.contentDetail?.name === isSections.isFooter
                    )
                    .map((ele, index) => {
                      return (
                        <Footer
                          pageConfigDetails={ele?.pageConfigDetails}
                          footerDetail={ele?.footerDetail}
                          contentMapId={ele?.contentMapId}
                          navMapId={navMapId}
                          webMasterDetail={apiData?.data?.webMasterDetail}
                          navbarData={navbarData}
                          getData={getData}
                          contentDetail={ele.contentDetail}
                          ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                        />
                      );
                    })}
                  {apiData?.data
                    ?.filter(
                      (ele) =>
                        ele?.contentDetail?.name === isSections.isCustomForm
                    )
                    .map((ele, index) => {
                      return (
                        <CustomForm
                          pageConfigDetails={ele?.pageConfigDetails}
                          customFormDetail={ele?.customFormDetail}
                          contentMapId={ele?.contentMapId}
                          navMapId={navMapId}
                          webMasterDetail={apiData?.data?.webMasterDetail}
                          navbarData={navbarData}
                          getData={getData}
                          contentDetail={ele.contentDetail}
                          ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                        />
                      );
                    })}
                </Row>
                <Row>
                  <FooterHeader
                    domainShow={domainShow}
                    ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                    webCategoryDetails={apiData?.data.filter(
                      (ele) => ele?.otherSetting
                    )}
                  />
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {allPopupState.subDomainPopup && (
          <SetSubDomainPopup
            ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
            subDomainData={apiData}
            isMulti={true}
          />
        )}
        {modalConfig && (
          <SettingMultiWebAddWebConfigPage
            ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
            isDashboardClick={true}
            selectedNavName={selectedNavName}
          />
        )}
        {modalNav && (
          <SettingMultiWebAddWebNavBarMap
            ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
            isDashboardClick={true}
          />
        )}
        {modalSectionPo && (
          <SectionPositionChange
            ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
            isDashboardClick={true}
          />
        )}
        {cloneAlignModal.alignModal && (
          <AlignSectionModal ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard} />
        )}
        {cloneAlignModal.cloneModal && (
          <CloneSectionModal ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard} />
        )}
        {modalChild && <SettingMultiWebAddChilds />}
      </Container>
    </div>
  );
};

export default MultipageByteCardBuilder;
