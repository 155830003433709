import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Select from "react-select";
import { PLAN_MASTER, PLAN_MASTER_TYPE } from "../../constant";
import PlanMasterContext from "../../context/PlanMasterContext/PlanMasterContext";
import { Switch } from "@material-ui/core";
import { Subscription_Lists } from "../../api/SupperAdminOrganizationApi/Organization";

const PlanMasterAddPopup = (props) => {
  const { handleSubmit, simpleValidator, emptyData } = props;
  const {
    addModel,
    setAddModel,
    formData,
    setFormData,
    isEdit,
    isMonth,
    setIsMonth,
  } = useContext(PlanMasterContext);

  const CloseModel = () => {
    emptyData();
    setAddModel(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const handleChangeSelect = (selectedOption, name) => {
    setFormData((prev) => ({
      ...prev,
      [name]: selectedOption,
    }));
  };
  const [allItems, setAllItems] = useState([]);
  const getSubscriptionList = async () => {
    try {
      let paramObj = {
        showAll: true,
      };
      const { data } = await Subscription_Lists(paramObj);

      if (data.status === 200) {
        setAllItems(
          data.payload.data.map((ele) => {
            return {
              value: ele.solutionId,
              label: ele.solutionName,
            };
          })
        );
      } else {
      }
    } catch (error) {}
  };
  useEffect(() => {
    getSubscriptionList();
  }, []);

  return (
    <>
      <Modal size="xl" isOpen={addModel} toggle={() => CloseModel()}>
        <ModalHeader toggle={() => CloseModel()}>
          {isEdit ? `${PLAN_MASTER.EDIT_THEME}` : `${PLAN_MASTER.ADD_THEME}`}
        </ModalHeader>
        <ModalBody>
          <Container fluid={true} className="p-0">
            <Row>
              <Form className="theme-form" onSubmit={(e) => handleSubmit(e)}>
                <Row>
                  <Col md="6 mb-4">
                    <Label>{"Plan Name"}</Label>
                    <Input
                      className="form-control"
                      type={"text"}
                      style={{ color: "black" }}
                      name="planName"
                      onChange={(e) => handleChange(e)}
                      value={formData?.planName}
                      placeholder="Plan Name"
                    />
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "Template Name",
                        formData?.planName,
                        "required"
                      )}
                    </p>
                  </Col>
                  <Col md="6 mb-4">
                    <Label>{"Amount"}</Label>
                    <Input
                      className="form-control"
                      type={"number"}
                      name="amount"
                      style={{ color: "black" }}
                      onChange={(e) => handleChange(e)}
                      value={formData?.amount}
                      placeholder="code"
                    />
                  </Col>
                  <Col md="6 mb-4">
                    <Label>
                      {isMonth ? "Validity Month" : "Validity Day"}
                      <Switch
                        checked={isMonth}
                        inputProps={{ "aria-label": "controlled" }}
                        onChange={() => {
                          setIsMonth(!isMonth);
                          setFormData((prev) => {
                            return {
                              ...prev,
                              validityMonth: "",
                              validityDay: "",
                            };
                          });
                        }}
                      />{" "}
                      {isMonth ? "(Unable Day)" : "(Unable Month)"}
                    </Label>
                    {isMonth ? (
                      <Input
                        className="form-control"
                        type={"number"}
                        name="validityMonth"
                        style={{ color: "black" }}
                        onChange={(e) => handleChange(e)}
                        value={formData?.validityMonth}
                        placeholder="Validity Month"
                      />
                    ) : (
                      <Input
                        className="form-control"
                        type={"number"}
                        name="validityDay"
                        style={{ color: "black" }}
                        onChange={(e) => handleChange(e)}
                        value={formData?.validityDay}
                        placeholder="Validity Day"
                      />
                    )}
                  </Col>
                  <Col md="6 mb-4">
                    <Label htmlFor="validationCustom03">
                      {"Select solutionIds"}
                    </Label>
                    <Select
                      isMulti
                      name="solutionIds"
                      closeMenuOnSelect={false}
                      options={allItems}
                      value={formData?.solutionIds}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => handleChangeSelect(e, "solutionIds")}
                    />
                  </Col>
                  <Col md="6 mb-4">
                    <Label htmlFor="validationCustom03">{"Type"}</Label>
                    <select
                      id="type"
                      className="form-select"
                      name="type"
                      value={formData?.type}
                      onChange={(e) => handleChange(e)}
                    >
                      <option value="">-- Select type --</option>
                      {PLAN_MASTER_TYPE.map((ele) => {
                        return (
                          <option value={ele.value} key={ele.value}>
                            {ele.label}
                          </option>
                        );
                      })}
                    </select>
                  </Col>
                  <Col md="6 mb-4">
                    <Label>{"Promo Code"}</Label>
                    <Input
                      className="form-control"
                      type={"text"}
                      style={{ color: "black" }}
                      name="promoCode"
                      onChange={(e) => handleChange(e)}
                      value={formData?.promoCode}
                      placeholder="Promo Code"
                    />
                  </Col>
                </Row>
                <div className="login-btn mt-2">
                  <Button color="primary" type="submit" value="submit">
                    {"Submit"}
                  </Button>
                </div>
              </Form>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};

export default PlanMasterAddPopup;
