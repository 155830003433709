import axios from "axios";
import {
  CREATE_CLONE_WEB_MASTER,
  CREATE_CLONE_WEB_MASTER_MULTI,
  CREATE_HWID,
  ORGANIZATION_DETAIL,
  ORGANIZATION_LIST,
  ORGANIZATION_PUT,
  SUBSCRIPTION_LIST,
  UPDATE_PAGE_LIMIT,
  WEB_CONFIG_PAGE_CREATE,
  WEB_CONTENT_MAP_LIST,
  WEB_CONTENT_MASTER_CREATE,
  WEB_CONTENT_MASTER_LIST,
  WEB_MASTER_LIST,
  WEB_MASTER_LIST_MULTI,
  WEB_NAV_BAR_CONFIG_LIST,
  WEB_NAV_BAR_CREATE,
  WEB_NAV_BAR_DETAIL,
  WEB_NAV_BAR_MAP_LIST,
  WEB_NAV_BAR_MASTER_LIST,
  WEB_NAV_BAR_NAV_MAP_ID,
} from "../../constant/API constant";
import { SINGLE_PAGE } from "../../constant";

export const Organization_List = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${ORGANIZATION_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};
export const Organization_Detail = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${ORGANIZATION_DETAIL}`, {
    headers: { Authorization: jwt_token },
    params: formData,
  });
};
export const Put_Organization_Detail = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${ORGANIZATION_PUT}`,
    { slugUrl: formData.slugUrl },
    {
      headers: { authorization: jwt_token },
      params: { orgUuid: formData.orgUuid },
    }
  );
};
export const WebContentMapList = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${WEB_CONTENT_MAP_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};
export const WebContentMapListDelete = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(
    `${process.env.REACT_APP_API_URL}${WEB_CONTENT_MAP_LIST}`,
    {
      headers: { Authorization: jwt_token },
      params: paramObj,
    }
  );
};
export const WebNavBarMapList = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${WEB_NAV_BAR_MAP_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};
export const WebNavBarMapListDelete = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(
    `${process.env.REACT_APP_API_URL}${WEB_NAV_BAR_MAP_LIST}`,
    {
      headers: { Authorization: jwt_token },
      params: paramObj,
    }
  );
};
export const WebNavBarConfigList = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${WEB_NAV_BAR_CONFIG_LIST}`,
    {
      headers: { Authorization: jwt_token },
      params: paramObj,
    }
  );
};
export const WebNavBarConfigListDelete = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(
    `${process.env.REACT_APP_API_URL}${WEB_NAV_BAR_CONFIG_LIST}`,
    {
      headers: { Authorization: jwt_token },
      params: paramObj,
    }
  );
};
export const WebContentMasterList = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${WEB_CONTENT_MASTER_LIST}`,
    {
      headers: { Authorization: jwt_token },
      params: paramObj,
    }
  );
};
export const WebNavBarMasterList = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${WEB_NAV_BAR_MASTER_LIST}`,
    {
      headers: { Authorization: jwt_token },
      params: paramObj,
    }
  );
};

export const WebNavBarListNavMapId = () => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${WEB_NAV_BAR_NAV_MAP_ID}`,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const CreateWebContentMaster = (formdata) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${WEB_CONTENT_MASTER_CREATE}`,
    formdata,
    {
      headers: { authorization: jwt_token },
    }
  );
};
export const CreateWebNavBarMap = (formdata) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${WEB_NAV_BAR_CREATE}`,
    formdata,
    {
      headers: { authorization: jwt_token },
    }
  );
};
export const UpdateWebNavBarMap = (formdata) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${WEB_NAV_BAR_CREATE}`,
    formdata,
    {
      headers: { authorization: jwt_token },
      params: { navMapId: formdata.navMapId },
    }
  );
};
export const DetailWebNavBarMap = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${WEB_NAV_BAR_DETAIL}`, {
    headers: { authorization: jwt_token },
    params: paramObj,
  });
};
export const CreateWebConfigPage = (formdata) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${WEB_CONFIG_PAGE_CREATE}`,
    formdata,
    {
      headers: { authorization: jwt_token },
    }
  );
};
export const CreateHwId = (formdata) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${CREATE_HWID}`,
    formdata,
    {
      headers: { authorization: jwt_token },
    }
  );
};

export const EditPageLimit = (formdata, orgUuid) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${UPDATE_PAGE_LIMIT}`,
    formdata,
    {
      headers: { authorization: jwt_token },
      params: { orgUuid },
    }
  );
};

export const webMaster_List = (paramObj, pageName) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${
      pageName == SINGLE_PAGE ? WEB_MASTER_LIST : WEB_MASTER_LIST_MULTI
    }`,
    {
      headers: { authorization: jwt_token },
      params: paramObj,
    }
  );
};

export const Subscription_Lists = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${SUBSCRIPTION_LIST}`, {
    headers: { authorization: jwt_token },
    params: paramObj,
  });
};

export const CreateCloneWebMaster = (formdata, pageName) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${
      pageName == SINGLE_PAGE
        ? CREATE_CLONE_WEB_MASTER
        : CREATE_CLONE_WEB_MASTER_MULTI
    }`,
    formdata,
    {
      headers: { authorization: jwt_token },
    }
  );
};
