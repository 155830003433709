import React, { useContext } from "react";
import { Col, Input, Label, Row } from "reactstrap";
import { Border, TextAlign, fontWeight } from "../../../../constant";
import ServiceAndCategoryContext from "../../../context/ServiceAndCatagoryContext/ServiceAndCategoryContext";
import { Cases, DELETE_CONFIRM } from "../../../constant";
import { delete_Multi_Image_Permanent } from "../apis/SliderApi/SliderApi";
import { Zoom, toast } from "react-toastify";
import Switch from "@material-ui/core/Switch";
import { Close } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { UploadCloud } from "react-feather";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import Dividers from "../../../common/divider";
import { OpenModalCommonFunc } from "../../../utils";
import ServiceCategoryDetailStyle from "./ServiceCategoryDetailStyle";
import PaginationButtonStyle from "../../../common/paginationButtonStyle";
import CommonColor from "../../../common/CommonColor";
import CustomStyle from "../../../common/CustomStyle";

const ServiceThemeSix = (props) => {
  const {
    isEdit,
    settingFormData,
    setSettingFormData,
    backImageToggle,
    setBackImageToggle,
    boxSection,
    setBoxSection,
    setIsImageUpload,
    isBackgroundColor,
    setIsBackgroundColor,
  } = useContext(ServiceAndCategoryContext);
  const { setLoaderShow } = useContext(LoaderContext);
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);

  const { imageUpload } = props;

  const handleChangeVisionSection = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".vision-section6": {
          ...prev[".vision-section6"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeVisionSectionTitleArea = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".vision-section6 .section-title-area": {
          ...prev[".vision-section6 .section-title-area"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeVisionSectionTitleAreaSubtitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".vision-section6 .section-title-area .section-subtitle": {
          ...prev[".vision-section6 .section-title-area .section-subtitle"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeVisionSectionTitleAreaSectionTitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".vision-section6 .section-title-area .section-title": {
          ...prev[".vision-section6 .section-title-area .section-title"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeVisionSectionVisionDiv = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".vision-section6 .visiondiv1": {
          ...prev[".vision-section6 .visiondiv1"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeVisionSectionVisionDiv1Hover = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".vision-section6 .visiondiv1:hover": {
          ...prev[".vision-section6 .visiondiv1:hover"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeVisionSectionVisionSub = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".vision-section6 .visiondiv_imgs .visiondiv_sub": {
          ...prev[".vision-section6 .visiondiv_imgs .visiondiv_sub"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeVisionServiceServiceDiv1H4 = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".vision-section6 .visiondiv1 h4": {
          ...prev[".vision-section6 .visiondiv1 h4"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeVisionServiceServiceDivP = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".vision-section6 .visiondiv1 p": {
          ...prev[".vision-section6 .visiondiv1 p"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeVisionServiceServiceDivLink = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".vision-section6 .visiondiv_sub .btn": {
          ...prev[".vision-section6 .visiondiv_sub .btn"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeCustomStyle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  /**
   * delete image permanently
   */
  const deleteImagePermanent = async () => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData = {
          fileName: settingFormData?.[".vision-section6.bg-image"]?.[
            "background-image"
          ]
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
            .pop(),
        };
        const { data } = await delete_Multi_Image_Permanent(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            position: "top-center",
          });
          setSettingFormData({
            ...settingFormData,
            ".vision-section6.bg-image": { "background-image": "" },
          });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };
  /**
   * image reupload
   */
  const reupload = () => {
    setSettingFormData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };

  return (
    <>
      <Row className="mt-4">
        <h4 className="mb-3">Make change in main Service section</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (default : 100)"}
          </Label>
          <Input
            className="form-control"
            name="padding"
            type="number"
            placeholder="Padding"
            value={settingFormData?.[".vision-section6"]?.["padding"]
              ?.split(" ")[0]
              ?.replace(/px/g, "")}
            onChange={(e) => handleChangeVisionSection(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <div className="d-flex align-items-center">
            <Label htmlFor="validationCustom03">
              {isBackgroundColor
                ? "Add Background Color"
                : "Remove Background Color"}
            </Label>
            <Switch
              checked={isBackgroundColor}
              inputProps={{ "aria-label": "controlled" }}
              onChange={(e) => {
                setIsBackgroundColor(e.target.checked);
                setSettingFormData((prev) => {
                  if (e.target.checked) {
                    if (boxSection) {
                      return {
                        ...prev,
                        ".vision-section6": {
                          "background-color": {
                            r: 255,
                            g: 255,
                            b: 255,
                            a: 100,
                          },
                          padding:
                            settingFormData?.[".vision-section6"]?.["padding"],
                          width: settingFormData?.[".vision-section6"]?.[
                            "width"
                          ]?.replace("rem)", ""),
                          margin: settingFormData?.[".vision-section6"]?.[
                            "margin"
                          ]
                            ?.split(" ")[0]
                            ?.replace(/px/g, ""),
                          "border-radius": settingFormData?.[
                            ".vision-section6"
                          ]?.["border-radius"]?.replace(/rem/g, ""),
                        },
                      };
                    } else {
                      return {
                        ...prev,
                        ".vision-section6": {
                          "background-color": {
                            r: 255,
                            g: 255,
                            b: 255,
                            a: 100,
                          },
                          padding:
                            settingFormData?.[".vision-section6"]?.["padding"],
                        },
                      };
                    }
                  } else {
                    if (boxSection) {
                      return {
                        ...prev,
                        ".vision-section6": {
                          padding:
                            settingFormData?.[".vision-section6"]?.["padding"],
                          width: settingFormData?.[".vision-section6"]?.[
                            "width"
                          ]?.replace("rem)", ""),
                          margin: settingFormData?.[".vision-section6"]?.[
                            "margin"
                          ]
                            ?.split(" ")[0]
                            ?.replace(/px/g, ""),
                          "border-radius": settingFormData?.[
                            ".vision-section6"
                          ]?.["border-radius"]?.replace(/rem/g, ""),
                        },
                      };
                    } else {
                      return {
                        ...prev,
                        ".vision-section6": {
                          padding:
                            settingFormData?.[".vision-section6"]?.["padding"],
                        },
                      };
                    }
                  }
                });
              }}
            />
          </div>
          {isBackgroundColor && (
            <CommonColor
              color={
                settingFormData?.[".vision-section6"]?.["background-color"]
              }
              setSettingFormData={setSettingFormData}
              className={".vision-section6"}
              classProperty={"background-color"}
              label={"Select Background Color"}
            />
          )}
        </Col>
        <p>{"Box Section : convert your section to Box section"}</p>
        <div>
          <Switch
            checked={boxSection}
            inputProps={{ "aria-label": "controlled" }}
            onChange={(e) => {
              setBoxSection(e.target.checked);
              setSettingFormData((prev) => {
                return {
                  ...prev,
                  ".vision-section6": {
                    padding: settingFormData?.[".vision-section6"]?.["padding"]
                      ?.split(" ")[0]
                      ?.replace(/px/g, ""),
                    "background-color":
                      settingFormData?.[".vision-section6"]?.[
                        "background-color"
                      ],
                    width: e.target.checked ? "10" : "",
                    margin: e.target.checked ? "0" : "",
                    "border-radius": e.target.checked ? "3" : "",
                  },
                };
              });
            }}
          />
        </div>
        {boxSection && (
          <>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Width (Default : 10)"}
              </Label>
              <Input
                className="form-control"
                name="width"
                type="number"
                placeholder="width"
                value={settingFormData?.[".vision-section6"]?.[
                  "width"
                ]?.replace("rem)", "")}
                onChange={(e) => handleChangeVisionSection(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Margin (Default : 0)"}
              </Label>
              <Input
                className="form-control"
                name="margin"
                type="number"
                placeholder="margin"
                value={settingFormData?.[".vision-section6"]?.["margin"]
                  ?.split(" ")[0]
                  ?.replace(/px/g, "")}
                onChange={(e) => handleChangeVisionSection(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Border Radius (Default : 3)"}
              </Label>
              <Input
                className="form-control"
                name="border-radius"
                type="number"
                placeholder="Border Radius"
                value={settingFormData?.[".vision-section6"]?.[
                  "border-radius"
                ]?.replace(/rem/g, "")}
                onChange={(e) => handleChangeVisionSection(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
          </>
        )}
      </Row>
      <hr />
      <Row className="mt-4">
        <Col md="12 mb-3">
          <h4 className="mb-3">Add Background Image in section</h4>

          <p>do you want to upload Background image</p>
          <Switch
            checked={backImageToggle}
            inputProps={{ "aria-label": "controlled" }}
            onChange={(e) => {
              setBackImageToggle(e.target.checked);
              setSettingFormData((prev) => {
                if (e.target.checked) {
                  return {
                    ...prev,
                    image: "",
                    ".vision-section6.bg-image": {},
                    ".vision-section6.bg-image::before": {
                      "background-color": "#000000CC",
                    },
                  };
                } else {
                  return {
                    ...prev,
                    image: "",
                    ".vision-section6.bg-image": {},
                    ".vision-section6.bg-image::before": {},
                  };
                }
              });
            }}
          />
          {backImageToggle && (
            <>
              {" "}
              <Col md="6 mb-3">
                <Dividers divide="IMAGE" />
                <p className="mb-4">
                  File type required JPG, PNG (1905px X 986px)
                </p>
              </Col>
              <Row>
                <Col md="4 mb-3">
                  <div
                    className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    {!settingFormData?.[".vision-section6.bg-image"]?.[
                      "background-image"
                    ] && (
                      <span
                        onClick={() => {
                          OpenModalCommonFunc(
                            Cases.PRODUCT_UPLOAD,
                            setAllPopupState
                          );
                          setSettingFormData((prev) => {
                            return {
                              ...prev,
                              image:
                                settingFormData?.[
                                  ".vision-section6.bg-image"
                                ]?.["background-image"],
                            };
                          });
                          setIsImageUpload((prev) => {
                            return {
                              isList: true,
                              isDetails: false,
                            };
                          });
                        }}
                      >
                        <UploadCloud />
                      </span>
                    )}

                    {settingFormData?.[".vision-section6.bg-image"]?.[
                      "background-image"
                    ] && (
                      <img
                        src={
                          settingFormData?.[".vision-section6.bg-image"]?.[
                            "background-image"
                          ] || ""
                        }
                        alt=""
                        style={{
                          width: "85%",
                          height: "90px",
                          objectFit: "contain",
                          position: "absolute",
                        }}
                        onClick={() => {
                          deleteImagePermanent();
                        }}
                      />
                    )}
                    {settingFormData?.[".vision-section6.bg-image"]?.[
                      "background-image"
                    ] && (
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="close"
                        style={{
                          color: "red",
                          position: "absolute",
                          top: "-22px",
                          left: "1px",
                        }}
                        onClick={() => deleteImagePermanent()}
                      >
                        <Close
                          style={{
                            fontSize: "25px",
                            fontWeight: "bold",
                          }}
                        />
                      </IconButton>
                    )}
                  </div>
                </Col>
                <Col md="6 mb-3">
                  <h4 className="mb-3">Add Background color in section</h4>
                  <Col md="6 mb-3">
                    <CommonColor
                      color={
                        settingFormData?.[
                          ".vision-section6.bg-image::before"
                        ]?.["background-color"]
                      }
                      setSettingFormData={setSettingFormData}
                      className={".vision-section6.bg-image::before"}
                      classProperty={"background-color"}
                      label={"Select Background Color"}
                    />
                  </Col>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make space in Title bottom</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin Bottom (Default : 50)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin Bottom"
            value={settingFormData?.[".vision-section6 .section-title-area"]?.[
              "margin-bottom"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeVisionSectionTitleArea(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in section subtitle</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 16)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".vision-section6 .section-title-area .section-subtitle"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeVisionSectionTitleAreaSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[
                ".vision-section6 .section-title-area .section-subtitle"
              ]?.["font-weight"]
            }
            onChange={(e) => handleChangeVisionSectionTitleAreaSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]} key={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".vision-section6 .section-title-area .section-subtitle"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".vision-section6 .section-title-area .section-subtitle"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".vision-section6 .section-title-area .section-subtitle"
              ]?.["background-color"]
            }
            setSettingFormData={setSettingFormData}
            className={".vision-section6 .section-title-area .section-subtitle"}
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 5 - 20)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="p1"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".vision-section6 .section-title-area .section-subtitle"
              ]?.["p1"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeVisionSectionTitleAreaSubtitle(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p2"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".vision-section6 .section-title-area .section-subtitle"
              ]?.["p2"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeVisionSectionTitleAreaSubtitle(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border Radius (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[
              ".vision-section6 .section-title-area .section-subtitle"
            ]?.["border-radius"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeVisionSectionTitleAreaSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin Bottom (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin Bottom"
            value={settingFormData?.[
              ".vision-section6 .section-title-area .section-subtitle"
            ]?.["margin-bottom"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeVisionSectionTitleAreaSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in section Title</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 44)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".vision-section6 .section-title-area .section-title"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeVisionSectionTitleAreaSectionTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[
                ".vision-section6 .section-title-area .section-title"
              ]?.["font-weight"]
            }
            onChange={(e) => handleChangeVisionSectionTitleAreaSectionTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]} key={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".vision-section6 .section-title-area .section-title"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".vision-section6 .section-title-area .section-title"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in section div</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".vision-section6 .section-title-area .section-title"
              ]?.["background-color"]
            }
            setSettingFormData={setSettingFormData}
            className={".vision-section6 .section-title-area .section-title"}
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
        {/* <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding (Default : 60 - 40)"}
                  </Label>
                  <div className="d-flex justify-content-between">
                    <Input
                      className="form-control"
                      name="p1"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        ".vision-section6 .visiondiv1"
                      ]?.["p1"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangeVisionSectionVisionDiv(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                    <Input
                      className="form-control"
                      name="p2"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        ".vision-section6 .visiondiv1"
                      ]?.["p2"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangeVisionSectionVisionDiv(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                  </div>
                </Col> */}
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Margin (Default : 15)"}</Label>
          <Input
            className="form-control"
            name="margin"
            type="number"
            placeholder="margin"
            value={settingFormData?.[".vision-section6 .visiondiv1"]?.["margin"]
              ?.split(" ")[0]
              ?.replace(/px/g, "")}
            onChange={(e) => handleChangeVisionSectionVisionDiv(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border Radius (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[".vision-section6 .visiondiv1"]?.[
              "border-radius"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeVisionSectionVisionDiv(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border (Default : 1 solid white)"}
          </Label>
          <div className="d-flex">
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="borderPx"
                type="number"
                placeholder="px"
                value={settingFormData?.[".vision-section6 .visiondiv1"]?.[
                  "borderPx"
                ]?.replace(/px/g, "")}
                onChange={(e) => handleChangeVisionSectionVisionDiv(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <select
                name="borderThik"
                id="borderThik"
                className="form-control"
                value={
                  settingFormData?.[".vision-section6 .visiondiv1"]?.[
                    "borderThik"
                  ]
                }
                onChange={(e) => handleChangeVisionSectionVisionDiv(e)}
                style={{ borderRadius: "0", color: "black" }}
              >
                <option value="">--select--</option>
                {Border.map((elem) => {
                  return (
                    <option value={elem.border} key={elem.border}>
                      {elem.border}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <CommonColor
                color={
                  settingFormData?.[".vision-section6 .visiondiv1"]?.[
                    "borderColor"
                  ]
                }
                setSettingFormData={setSettingFormData}
                className={".vision-section6 .visiondiv1"}
                classProperty={"borderColor"}
                label={"Border Color"}
              />
            </div>
          </div>
        </Col>
        <Col md="6 mb-3">
          <Label htmlFor="validationCustom03">
            {"Box Shadow (Default : 0 5 10 black)"}
          </Label>
          <div className="d-flex">
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="bs1"
                type="number"
                placeholder="px"
                value={settingFormData?.[".vision-section6 .visiondiv1"]?.[
                  "bs1"
                ]?.replace(/px/g, "")}
                onChange={(e) => handleChangeVisionSectionVisionDiv(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="bs2"
                type="number"
                placeholder="px"
                value={settingFormData?.[".vision-section6 .visiondiv1"]?.[
                  "bs2"
                ]?.replace(/px/g, "")}
                onChange={(e) => handleChangeVisionSectionVisionDiv(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="bs3"
                type="number"
                placeholder="px"
                value={settingFormData?.[".vision-section6 .visiondiv1"]?.[
                  "bs3"
                ]?.replace(/px/g, "")}
                onChange={(e) => handleChangeVisionSectionVisionDiv(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div>
              <CommonColor
                color={
                  settingFormData?.[".vision-section6 .visiondiv1"]?.[
                    "shadowColor"
                  ]
                }
                setSettingFormData={setSettingFormData}
                className={".vision-section6 .visiondiv1"}
                classProperty={"shadowColor"}
                label={"Shadow Color"}
              />
            </div>
          </div>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Text Align (Default : center)"}
          </Label>
          <select
            name="text-align"
            id="text-align"
            className="form-control"
            value={
              settingFormData?.[".vision-section6 .visiondiv1"]?.["text-align"]
            }
            onChange={(e) => handleChangeVisionSectionVisionDiv(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {TextAlign.map((elem) => {
              return (
                <option value={elem["text-align"]} key={elem["text-align"]}>
                  {elem["text-align"]}
                </option>
              );
            })}
          </select>
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Change section div shadow color at hover</h4>
        <Col md="6 mb-3">
          <Label htmlFor="validationCustom03">
            {"Box Shadow (Default : 0 10 20 0 black)"}
          </Label>
          <div className="d-flex ">
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="bs1"
                type="number"
                placeholder="px"
                value={settingFormData?.[
                  ".vision-section6 .visiondiv1:hover"
                ]?.["bs1"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeVisionSectionVisionDiv1Hover(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="bs2"
                type="number"
                placeholder="px"
                value={settingFormData?.[
                  ".vision-section6 .visiondiv1:hover"
                ]?.["bs2"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeVisionSectionVisionDiv1Hover(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="bs3"
                type="number"
                placeholder="px"
                value={settingFormData?.[
                  ".vision-section6 .visiondiv1:hover"
                ]?.["bs3"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeVisionSectionVisionDiv1Hover(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="bs4"
                type="number"
                placeholder="px"
                value={settingFormData?.[
                  ".vision-section6 .visiondiv1:hover"
                ]?.["bs4"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeVisionSectionVisionDiv1Hover(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div>
              <CommonColor
                color={
                  settingFormData?.[".vision-section6 .visiondiv1:hover"]?.[
                    "shadowColor"
                  ]
                }
                setSettingFormData={setSettingFormData}
                className={".vision-section6 .visiondiv1:hover"}
                classProperty={"shadowColor"}
                label={"Shadow Color"}
              />
            </div>
          </div>
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make Change in sub Div</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 60 - 40)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="p1"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".vision-section6 .visiondiv_imgs .visiondiv_sub"
              ]?.["p1"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeVisionSectionVisionSub(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p2"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".vision-section6 .visiondiv_imgs .visiondiv_sub"
              ]?.["p2"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeVisionSectionVisionSub(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in section div heading</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".vision-section6 .visiondiv1 h4"]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".vision-section6 .visiondiv1 h4"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[".vision-section6 .visiondiv1 h4"]?.[
                "font-weight"
              ]
            }
            onChange={(e) => handleChangeVisionServiceServiceDiv1H4(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]} key={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 22)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[".vision-section6 .visiondiv1 h4"]?.[
              "font-size"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeVisionServiceServiceDiv1H4(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin Bottom (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin Bottom"
            value={settingFormData?.[".vision-section6 .visiondiv1 h4"]?.[
              "margin-bottom"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeVisionServiceServiceDiv1H4(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in section div paragraph</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".vision-section6 .visiondiv1 p"]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".vision-section6 .visiondiv1 p"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 400)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[".vision-section6 .visiondiv1 p"]?.[
                "font-weight"
              ]
            }
            onChange={(e) => handleChangeVisionServiceServiceDivP(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]} key={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[".vision-section6 .visiondiv1 p"]?.[
              "font-size"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeVisionServiceServiceDivP(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Line Height (Default : 1.6)"}
          </Label>
          <Input
            className="form-control"
            name="line-height"
            type="number"
            placeholder="Line Height"
            value={
              settingFormData?.[".vision-section6 .visiondiv1 p"]?.[
                "line-height"
              ]
            }
            onChange={(e) => handleChangeVisionServiceServiceDivP(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in section div Link</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".vision-section6 .visiondiv_sub .btn"]?.[
                "color"
              ]
            }
            setSettingFormData={setSettingFormData}
            className={".vision-section6 .visiondiv_sub .btn"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 400)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[".vision-section6 .visiondiv_sub .btn"]?.[
                "font-weight"
              ]
            }
            onChange={(e) => handleChangeVisionServiceServiceDivLink(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]} key={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 16)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[".vision-section6 .visiondiv_sub .btn"]?.[
              "font-size"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeVisionServiceServiceDivLink(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <ServiceCategoryDetailStyle />
      <PaginationButtonStyle
        mainClass={".vision-section6"}
        settingFormData={settingFormData}
        setSettingFormData={setSettingFormData}
      />
      <CustomStyle
        handleChange={handleChangeCustomStyle}
        value={settingFormData?.custom}
      />
      <ProductImageUploadPopup
        allPopupState={allPopupState}
        setAllPopupState={setAllPopupState}
        crop={crop}
        setCrop={setCrop}
        completedCrop={completedCrop}
        setCompletedCrop={setCompletedCrop}
        scale={scale}
        rotate={rotate}
        image={settingFormData?.image}
        setImage={setSettingFormData}
        imageUpload={imageUpload}
        isEdit={isEdit}
        reupload={reupload}
        // aspect={1.93 / 1}
      />
    </>
  );
};

export default ServiceThemeSix;
