import React, { useState } from "react";
import VideoBannerContext from "./VideoBannerContext";
import { randomStr } from "../../../utils/helper";

const VideoBannerState = (props) => {
  const [apiData, setApiData] = useState({ data: [], isLoading: false });
  const [apiDataOurTeam, setApiDataOurTeam] = useState({
    isLoading: false,
    data: [],
  });
  const [skill, setSkill] = useState([
    { id: randomStr(2), skill: "", parentageOfSkill: "" },
  ]);
  const [countOfList, setCountOfList] = useState("");
  const [collId, setCollId] = useState();
  const [fromData, setFormData] = useState({
    title: "",
    subTitle: "",
    isTitleShow: true,
    navMapId: "",
    webId: localStorage.getItem("webId"),
    theme: 1,
    style: {
      ".video-banner-section": {
        padding: "200px 0",
        "background-color": "rgb(0 0 0 / 100)",
      },
      ".video-banner-section.bg-image": {
        "background-image": "",
      },
      ".video-banner-section.bg-image::before": {
        "background-color": "rgb(0 0 0 / 0.8)",
      },
      ".video-banner-section .video-play": {
        height: "80px",
        width: "80px",
        "background-color": "rgb(255 255 255 / 100)",
        color: "rgb(255 73 124 / 100)",
        "text-align": "center",
        padding: "25px 5px",
        p1: "25px",
        p2: "5px",
        "font-size": "25px",
        "border-radius": "100%",
        display: "inline-block",
      },
      ".videomodal .framediv iframe": {
        "box-shadow": "0 0 8px rgb(0 0 0 / 0.6)",
        bs1: "0px",
        bs2: "0px",
        bs3: "8px",
        shadowColor: "rgb(0 0 0 / 0.6)",
        "background-color": "rgb(0 0 0 / 100)",
      },
      videoBannerId: "",
    },
  });

  const [formDataChild, setFormDataChild] = useState({
    title: "",
    subTitle: "",
    videLink: "",
    buttonText: "",
    buttonValue: "",
    description: "",
    isTitleShow: true,
    order: 1,
    webId: localStorage.getItem("webId"),
    navMapId: "",
    style: {
      ".video-banner-section": {
        padding: "200px 0",
        "background-color": "rgb(0 0 0 / 100)",
      },
      ".video-banner-section.bg-image": {
        "background-image": "",
      },
      ".video-banner-section.bg-image::before": {
        "background-color": "rgb(0 0 0 / 0.8)",
      },
      ".video-banner-section .video-play": {
        height: "80px",
        width: "80px",
        "background-color": "rgb(255 255 255 / 100)",
        color: "rgb(255 73 124 / 100)",
        "text-align": "center",
        padding: "25px 5px",
        p1: "25px",
        p2: "5px",
        "font-size": "25px",
        "border-radius": "100%",
      },
      ".videomodal .framediv iframe": {
        "box-shadow": "0 0 8px rgb(0 0 0 / 0.6)",
        bs1: "0px",
        bs2: "0px",
        bs3: "8px",
        shadowColor: "rgb(0 0 0 / 0.6)",
        "background-color": "rgb(0 0 0 / 100)",
      },
      videoBannerId: "",
    },
  });

  const [settingFormData, setSettingFormData] = useState({
    ".video-banner-section": {
      padding: "200px 0",
      "background-color": "rgb(0 0 0 / 100)",
      width: "",
      margin: "",
      "border-radius": "",
    },
    image: "",
    ".video-banner-section.bg-image": {
      "background-image": "",
    },
    ".video-banner-section.bg-image::before": {
      "background-color": "rgba(0, 0, 0, 0.8)",
    },
    ".video-banner-section .video-play": {
      height: "80px",
      width: "80px",
      "background-color": "rgb(255 255 255 / 100)",
      color: "rgb(255 73 124 / 100)",
      "text-align": "center",
      padding: "25px 5px",
      p1: "25px",
      p2: "5px",
      "font-size": "25px",
      "border-radius": "100%",
    },
    ".videomodal .framediv iframe": {
      "box-shadow": "0 0 8px rgb(0 0 0 / 0.6)",
      bs1: "0px",
      bs2: "0px",
      bs3: "8px",
      shadowColor: "rgb(0 0 0 / 0.6)",
      "background-color": "rgb(0 0 0 / 100)",
    },
    videoBannerId: "",
  });
  const [boxSection, setBoxSection] = useState(false);
  const [id, setId] = useState("");
  const [childId, setChildId] = useState("");
  const [navMapId, setNavMapId] = useState("");
  const [galleryImgId, setGalleryImgId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isParent, setIsParent] = useState(false);
  const [filter, setFilter] = useState("");
  const [backImageToggle, setBackImageToggle] = useState(false);
  const [isBackgroundColor, setIsBackgroundColor] = useState(false);
  const [sectionPageName, setSectionPageName] = useState("");
  return (
    <VideoBannerContext.Provider
      value={{
        apiData,
        setApiData,
        apiDataOurTeam,
        setApiDataOurTeam,
        fromData,
        setFormData,
        id,
        setId,
        isEdit,
        setIsEdit,
        filter,
        setFilter,
        skill,
        galleryImgId,
        setGalleryImgId,
        setSkill,
        settingFormData,
        setSettingFormData,
        formDataChild,
        setFormDataChild,
        isParent,
        setIsParent,
        childId,
        setChildId,
        navMapId,
        setNavMapId,
        backImageToggle,
        setBackImageToggle,
        boxSection,
        setBoxSection,
        collId,
        setCollId,
        isBackgroundColor,
        setIsBackgroundColor,
        countOfList,
        setCountOfList,
        sectionPageName,
        setSectionPageName,
      }}
    >
      {props.children}
    </VideoBannerContext.Provider>
  );
};

export default VideoBannerState;
