import React from "react";
import { useLocation } from "react-router";
import { FILE_TYPE, fileUploadSizes, https } from "../constant";
import { FILE_UPLOAD_API } from "../components ByteCard/components/MultipageByteCardBuilder/apis/SliderApi/SliderApi";
import { TostMessage } from "../components ByteCard/utils";

export const randomStr = (length) => {
  let randomText = "";
  const randomString =
    "ABCDEFGHIJKLMNPPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890";
  for (let i = 0; i < length; i++) {
    randomText += randomString.charAt(
      Math.floor(Math.random() * randomString.length)
    );
  }
  return randomText;
};

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

/**Hyper link function */

export function HyperLink(value) {
  value = value?.split(https)?.pop();
  return https + value;
}

export const tableId = (page, pageSize, formvalue) => {
  for (let i in formvalue) {
    formvalue[i]["sNo"] = page * pageSize - pageSize + 1 + +i;
  }
  return formvalue;
};

/**
 * Max length for fild number type helper
 */

export const maxLengthCheck = (object) => {
  if (object.target.value.length > object.target.maxLength) {
    object.target.value = object.target.value.slice(0, object.target.maxLength);
  }
};

/**
 * {...} truncate function
 */

export const Truncate = (string, limit) => {
  let dots = "...";
  if (string?.length > limit) {
    string = string.substring(0, limit) + dots;
  }
  return string;
};

/**
 * Given Value is null or ! function
 */

export const isNullish = (value) => {
  if (typeof value == "undefined" || value == null) {
    return true;
  } else {
    return false;
  }
};

export const isEmpty = (value) => {
  let temp = {};
  Object.keys(value).forEach((key) => {
    if (!isNullish(value[key])) {
      temp[key] = value[key];
    }
  });
  return temp;
};

export const thubnailImage = (uri) => {
  let k = uri.split("/");
  let id = 0;
  if (k[2] === "www.youtube.com") {
    let link = uri.split("&");
    let splitId = link[0]?.split("v=");
    id = splitId[1];
  } else if (k[2] === "youtu.be") {
    let link = uri.split("youtu.be/");
    id = link[1];
  }
  return id;
};

/**
 *
 * @param {data url to blog} dataURL
 * @returns
 */
export const dataURLtoBlob = (dataURL) => {
  const byteString = atob(dataURL.split(",")[1]);
  const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
};

export const checkImageType = (variable) => {
  // Regular expressions to match base64 and URL patterns
  const base64Pattern = /^data:image\/(png|jpeg|jpg|gif);base64,/;
  const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

  if (typeof variable === "string") {
    if (variable.match(base64Pattern)) {
      return "base64";
    } else if (variable.match(urlPattern)) {
      return "url";
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const getDomainWithoutSubdomain = (url) => {
  // Remove protocol if present
  url = url.replace(/^(https?:\/\/)?/, "");

  // Remove subdomain and get the domain
  let domain = url.split(".").slice(1).join(".");
  if (!domain) {
    return url; // Return the original URL if there is no subdomain
  }
  return domain;
};

export const slugUrlConverter = ({ value }) => {
  return value.toLowerCase().replace(/\s+/g, "-");
};

export const isCheckValue = ({ value }) => {
  if (value !== null && value !== undefined && value !== "") {
    return true;
  } else {
    return false;
  }
};

export const changeKeyNameOfSubMenu = (arr, subChild) => {
  return arr.map((item) => {
    let newItem = {};

    if (subChild) {
      newItem = {
        label: item.label,
        subChildId: item.parentId,
        navMapId: item.navMapId,
      };
    } else {
      newItem = {
        label: item.label,
        parentId: item.parentId,
        navMapId: item.navMapId,
      };
    }

    if (item.childIds) {
      newItem.childIds = item.childIds.map((child) => {
        if (child.childIds) {
          return {
            childId: child.parentId,
            label: child.label,
            navMapId: child.navMapId,
            subChilds: child.childIds
              ? changeKeyNameOfSubMenu(child.childIds, true)
              : [],
          };
        } else {
          return {
            childId: child.parentId,
            label: child.label,
            navMapId: child.navMapId,
          };
        }
      });
    }

    if (item.subChilds) {
      newItem.subChilds = item.subChilds.map((subChild) => ({
        subChildId: subChild.parentId,
        label: subChild.label,
        navMapId: subChild.navMapId,
      }));
    }

    return newItem;
  });
};

export const removeUrlKeyInBGI = ({ urlString = "" }) => {
  let urlRegex = /url\((.*?)\)/;
  let match = urlString.match(urlRegex);
  if (match) {
    let extractedUrl = match[1];
    return extractedUrl;
  } else {
    return "";
  }
};
export const addEnvInImg = ({ urlString = "" }) => {
  let urlRegex = `${process.env.REACT_APP_BUCKET_URL}/${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`;
  let urlIs = urlString
    ? urlString?.split(process.env.REACT_APP_BASE_URL_IMAGE).pop()
    : "";
  let match = `${urlRegex}${urlIs}`;
  if (match) {
    return match;
  } else {
    return "";
  }
};

export const convertRGBA = ({ rgbString = "" }) => {
  // Check if the input is already in object format
  if (
    typeof rgbString === "object" &&
    rgbString !== null &&
    "r" in rgbString &&
    "g" in rgbString &&
    "b" in rgbString &&
    "a" in rgbString
  ) {
    return rgbString;
  }
  let rgbRegex = /rgb\((\d+) (\d+) (\d+) \/ ([\d.]+)\)/;
  let match = rgbString?.match(rgbRegex);
  if (match) {
    // Convert matched values to the JavaScript object format
    let result = {
      r: parseInt(match[1]),
      g: parseInt(match[2]),
      b: parseInt(match[3]),
      a: parseFloat(match[4]),
    };
    return result;
  } else {
    return "";
  }
};

export const rgbColorCreate = ({ rgbString = {} }) => {
  if (typeof rgbString === "string") {
    return rgbString;
  } else {
    let bgColor = `rgb(${rgbString.r} ${rgbString.g} ${rgbString.b} / ${rgbString.a})`;
    return bgColor;
  }
};
export const addUrlImage = ({ urlString = "" }) => {
  let bgImage = `url(${urlString})`;
  return bgImage;
};
export const removeUrlImage = ({ urlString = "" }) => {
  let bgImage = urlString
    .split(process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI + "/")
    .pop();
  return process.env.REACT_APP_BASE_URL_IMAGE + bgImage;
};

export const removeSpaceWithJoinNum = (...rest) => {
  const [str, num] = rest;
  let text = str + num;
  text = text.replace(/\s/g, "").toLowerCase();
  return text;
};

export const getAllParentIdForMenus = (
  data,
  navId,
  parentIndex = null,
  indices = []
) => {
  data.forEach((item, index) => {
    const currentIndex =
      parentIndex !== null ? `${parentIndex}.${index}` : `${index}`;
    if (item.navId === navId) {
      indices.push(currentIndex);
      if (item.childIds && item.childIds.length > 0) {
        item.childIds.forEach((child, childIndex) => {
          indices.push(`${currentIndex}.${childIndex}`);
        });
      }
    } else if (item.childIds && item.childIds.length > 0) {
      getAllParentIdForMenus(item.childIds, navId, currentIndex, indices);
    }
  });
  return indices;
};

const allowedExtensions = ["jpg", "jpeg", "png"];
export const FileUpload = async (
  e,
  fileAllow = allowedExtensions,
  setLoaderShow
) => {
  const file = e.target.files[0];
  if (file.size <= fileUploadSizes.image) {
    const extension = file.name.split(".").pop().toLowerCase();
    if (fileAllow.includes(extension)) {
      if (e.target.files.length === 0) return;
      // var reader = new FileReader();
      // reader.readAsDataURL(file[0]);
      // reader.onload = (_event) => {
      //   setFormData((prev) => {
      //     return {
      //       ...prev,
      //       imageThumbnail: reader.result,
      //     };
      //   });
      // };
      let formData = new FormData();
      formData.append("type", FILE_TYPE.MULTIPAGE);
      formData.append("file", file);
      try {
        const { data } = await FILE_UPLOAD_API(formData);
        if (data.status === 200) {
          setLoaderShow(false);
          return {
            status: data.status,
            image: data.payload.baseUrl + data.payload.imageUrl,
          };
        }
      } catch (error) {
        setLoaderShow(false);
        TostMessage({
          message: error?.response?.data?.message || error?.message,
          type: "error",
          position: "custome",
        });
      }
    } else {
      setLoaderShow(false);
      TostMessage({
        message: `file type required only ${fileAllow.join(" ")}`,
        type: "error",
        position: "custome",
      });
    }
  } else {
    setLoaderShow(false);
    TostMessage({
      message: "The selected file exceeds the maximum allowed size of 1 MB.",
      type: "error",
      position: "custome",
    });
  }
};

export const generateDynamicStyles = (settingFormData, mainClass) => {
  return {
    [`${mainClass} .more-btn`]: {
      ...settingFormData?.[`${mainClass} .more-btn`],
      color: settingFormData?.[`${mainClass} .more-btn`]?.["color"]
        ? rgbColorCreate({
            rgbString: settingFormData?.[`${mainClass} .more-btn`]?.["color"],
          })
        : "",
      "font-size": `${settingFormData?.[`${mainClass} .more-btn`]?.[
        "font-size"
      ]?.replace("px", "")}px`,
      "font-weight": `${
        settingFormData?.[`${mainClass} .more-btn`]?.["font-weight"]
      }`,
      "border-radius": `${settingFormData?.[`${mainClass} .more-btn`]?.[
        "border-radius"
      ]?.replace("px", "")}px`,
      padding: `${settingFormData?.[`${mainClass} .more-btn`]?.["p1"]?.replace(
        "px",
        ""
      )}px ${settingFormData?.[`${mainClass} .more-btn`]?.["p2"]?.replace(
        "px",
        ""
      )}px`,
      "background-color": settingFormData?.[`${mainClass} .more-btn`]?.[
        "background-color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[`${mainClass} .more-btn`]?.["background-color"],
          })
        : "",
    },
    [`${mainClass} .more-btn:hover`]: {
      "background-color": settingFormData?.[`${mainClass} .more-btn:hover`]?.[
        "background-color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[`${mainClass} .more-btn:hover`]?.[
                "background-color"
              ],
          })
        : "",
      color: settingFormData?.[`${mainClass} .more-btn:hover`]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[`${mainClass} .more-btn:hover`]?.["color"],
          })
        : "",
    },
    ".pagination-positions": {
      "justify-content": `${settingFormData?.[".pagination-positions"]?.["justify-content"]}`,
    },
    ".pagination .page-link": {
      color: settingFormData?.[".pagination .page-link"]?.["color"]
        ? rgbColorCreate({
            rgbString: settingFormData?.[".pagination .page-link"]?.["color"],
          })
        : "",
    },
    ".pagination .page-item.active .page-link": {
      ...settingFormData?.[".pagination .page-item.active .page-link"],
      "background-color": settingFormData?.[
        ".pagination .page-item.active .page-link"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".pagination .page-item.active .page-link"]?.[
                "background-color"
              ],
          })
        : "",
      "border-color": settingFormData?.[
        ".pagination .page-item.active .page-link"
      ]?.["border-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".pagination .page-item.active .page-link"]?.[
                "border-color"
              ],
          })
        : "",
    },
  };
};

export const generateDynamicStylesSet = (payload, mainClass) => {
  return {
    [`${mainClass} .more-btn`]: {
      color: payload.style?.[`${mainClass} .more-btn`]?.["color"]
        ? convertRGBA({
            rgbString: payload.style?.[`${mainClass} .more-btn`]?.["color"],
          })
        : "",
      "font-size": payload.style?.[`${mainClass} .more-btn`]?.["font-size"],
      "font-weight": payload.style?.[`${mainClass} .more-btn`]?.["font-weight"],
      "border-radius":
        payload.style?.[`${mainClass} .more-btn`]?.["border-radius"],
      padding: payload.style?.[`${mainClass} .more-btn`]?.["padding"],
      p1: payload.style?.[`${mainClass} .more-btn`]?.["padding"]?.split(" ")[0],
      p2: payload.style?.[`${mainClass} .more-btn`]?.["padding"]?.split(" ")[1],
      "background-color": payload.style?.[`${mainClass} .more-btn`]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[`${mainClass} .more-btn`]?.["background-color"],
          })
        : "",
    },
    [`${mainClass} .more-btn:hover`]: {
      "background-color": payload.style?.[`${mainClass} .more-btn:hover`]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[`${mainClass} .more-btn:hover`]?.[
                "background-color"
              ],
          })
        : "",
      color: payload.style?.[`${mainClass} .more-btn:hover`]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[`${mainClass} .more-btn:hover`]?.["color"],
          })
        : "",
    },
    ".pagination-positions": {
      "justify-content":
        payload.style?.[".pagination-positions"]?.["justify-content"],
    },
    ".pagination .page-link": {
      color: payload.style?.[".pagination .page-link"]?.["color"]
        ? convertRGBA({
            rgbString: payload.style?.[".pagination .page-link"]?.["color"],
          })
        : "",
    },
    ".pagination .page-item.active .page-link": {
      "background-color": payload.style?.[
        ".pagination .page-item.active .page-link"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".pagination .page-item.active .page-link"]?.[
                "background-color"
              ],
          })
        : "",
      "border-color": payload.style?.[
        ".pagination .page-item.active .page-link"
      ]?.["border-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".pagination .page-item.active .page-link"]?.[
                "border-color"
              ],
          })
        : "",
    },
  };
};

/**
 * replace url
 * @param {} jsonObj
 * @returns
 */
export const replaceValue = (jsonObj) => {
  let oldValue = process.env.REACT_APP_BASE_URL_IMAGE,
    newValue = `${process.env.REACT_APP_BUCKET_URL}/${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}`;
  for (let key in jsonObj) {
    if (typeof jsonObj[key] === "object") {
      replaceValue(jsonObj[key], oldValue, newValue); // Recurse into nested objects
    } else if (typeof jsonObj[key] === "string") {
      jsonObj[key] = jsonObj[key].replace(oldValue, newValue); // Replace the value if it's a string
    }
  }
  return jsonObj;
};

export const removeUrlKeyInPath = ({ urlString = "" }) => {
  let urlRegex = /url\((.*?)\)/;
  let match = urlString.match(urlRegex);
  if (match) {
    let extractedUrl = match[1];
    let mom = extractedUrl.replace(
      process.env.REACT_APP_BASE_URL_IMAGE,
      `${process.env.REACT_APP_BUCKET_URL}/${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`
    );
    return mom;
  } else {
    return "";
  }
};

export const isValidDomain = (domain) => {
  // Regular expression to match valid main domains with "https://" prefix and without subdomains
  const domainRegex = /^[A-Za-z0-9-]+\.[A-Za-z]{2,4}(\.[A-Za-z]{2,6})?(\/)?$/;

  // Check if the domain matches the regular expression
  return domainRegex.test(domain);
};

/**
 * cookie set
 * @param {cookie name} name
 * @param {cookie value} value
 * @param {expire time} days
 */
export const setCookie = (
  name,
  value,
  days,
  domain = ".localhost",
  seconds = 10
) => {
  let expires = "";
  let expirationTime;

  if (days !== "") {
    expirationTime = days * 24 * 60 * 60 * 1000; // Convert days to milliseconds
  } else {
    expirationTime = seconds * 1000; // Set expiration time to 10 seconds in milliseconds
  }

  const date = new Date();
  date.setTime(date.getTime() + expirationTime);
  expires = "; expires=" + date.toUTCString();

  let cookieString = name + "=" + (value || "") + expires + "; path=/";
  if (domain) {
    cookieString += "; domain=" + domain;
  }

  document.cookie = cookieString;
};

/**
 * get cookie
 * @param {cookie name} name
 * @returns
 */
export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

/**
 * delete cookie
 * @param {cookie name} name
 * @returns
 */
export const deleteCookie = (name, domain) => {
  document.cookie =
    name.replace(/^ +/, "") +
    `=; Path=/; Expires=${new Date().toUTCString()}; domain=${domain}`;
  document.cookie =
    name.replace(/^ +/, "") +
    `=; Path=/; Expires=${new Date().toUTCString()}; domain=${domain}`;
};

export const rgbToHex = (rgb) => {
  // Extracting RGB values from the input string
  if (typeof rgb === "string") {
    return rgb;
  }
  var rgbValues = rgb.match(/\d+/g);

  // Converting the RGB values to hexadecimal
  var hexColor =
    "#" +
    Number(rgbValues[0]).toString(16).padStart(2, "0") +
    Number(rgbValues[1]).toString(16).padStart(2, "0") +
    Number(rgbValues[2]).toString(16).padStart(2, "0");

  return hexColor;
};

export const getColorFromString = ({ colorString = "" }) => {
  // Finding the index of "rgb("
  const startIndex = colorString?.indexOf("rgb(");
  // Finding the index of ")"
  const endIndex = colorString?.indexOf(")", startIndex);
  const rgbValue = colorString.substring(startIndex, endIndex + 1);
  return rgbValue;
};

export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
}

export function getRandomNumber() {
  return (
    (Math.floor(Math.random() * 999) + 1) * (Math.floor(Math.random() * 98) + 2)
  );
}

export const encryptNumber = (number, key) => {
  return (number + key) * key;
};

export const decryptNumber = (encryptedNumber, key) => {
  return encryptedNumber / key - key;
};
