import React, { Fragment, useContext, useEffect, useMemo } from "react";

import { useNavigate, useParams } from "react-router";
import { toast, Zoom } from "react-toastify";
import {
  DeleteLandingPage,
  OrgPageList_Enable_Desable,
} from "../../api/Organization Api/OrganizationApi";
import { SORTING_ORDER } from "../../constant";
import PageItemContext from "../../context/PageItemContext/PageItmContext";
import PersonalPageContext from "../../context/PersonalPageContext/PersonalPageContaxt";
import ProfileComponentContext from "../../context/ProfilecomponentContext/ProfilecomponetContext";
import { classes } from "../../data/layouts";
import Breadcrumbs from "../../layout/breadcrumb";
import { tableId } from "../../utils/helper";
import TopLoadingBar from "../../utils/topLoadingBar";
import PersonalPageItem from "./PersonalPageItem";
import DefaultSelectPopup from "./defaultSelectPopup";
const PersonalPage = () => {
  const {
    formvalue,
    setToggle,
    page,
    pageSize,
    sortOrder,
    sortBy,
    setSortBy,
    setSortOrder,
    setProgress,
    progress,
    orgPageList,
    isSwitchOn,
    setIsSwitchOn,
    setOrgId,
  } = useContext(PersonalPageContext);

  const { setPageUuid, setPage } = useContext(PageItemContext);

  const { FillFeild, seteditIndex, filter } = useContext(
    ProfileComponentContext
  );

  const history = useNavigate();
  let { hwId } = useParams();

  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  /**
   * Organization List Function Call and This Function Call to PersonalPageContext
   */

  useEffect(() => {
    orgPageList(null, filter);
    setProgress(100);
  }, [page, pageSize, sortOrder, sortBy, filter]);

  useMemo(() => {
    tableId(page, pageSize, formvalue);
  }, [page, pageSize, formvalue, filter]);

  /**
   * Edit Profile Component
   */

  const EditHandle = async (pageUuid, orgId) => {
    setToggle(true);
    setPageUuid(pageUuid);
    await FillFeild(pageUuid, orgId);
    seteditIndex(orgId);
    history(`/${hwId}/ProfileComponent/${layout}`);
  };

  /**
   * Show Profile Component
   */

  const ShowPersonalPage = (pageUuid) => {
    setOrgId(pageUuid);
    history(`/${hwId}/PersonalPage/PersonalPageShow/${layout}`);
  };

  /**
   * Enable and Desable Organization
   */

  const switch_onChange_handle = async (pageUuid, isActive) => {
    let formData = {
      pageUuid: pageUuid,
      isActive: isActive ? false : true,
      // type: TYPES.LANDING_PAGE,
    };
    try {
      const { data } = await OrgPageList_Enable_Desable(formData, pageUuid);
      if (data.status === 200) {
        await setIsSwitchOn(!isSwitchOn);
        const desabledStatusLoader = false;
        await orgPageList(desabledStatusLoader);
        isActive
          ? toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            })
          : toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            });
      } else {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * Add PAge Item
   */

  const AddPageItem = (pageUuid, page, pageLimit) => {
    setPageUuid(pageUuid);
    setPage(page);
    let uri = `/${hwId}/PageItem/AddPageItem/${layout}`;
    localStorage.setItem("pageUuidForPageItem", pageUuid);
    localStorage.setItem("pageForPageItem", page);
    localStorage.setItem("pageLimit", pageLimit);
    history(`${uri}`);
  };

  /**
   * Delet Personal Page
   */

  const DeleteHandle = async (pageUuid) => {
    const result = window.confirm(
      "Are you sure you want to delete the landing page?"
    );
    if (result === true) {
      try {
        const { data } = await DeleteLandingPage(pageUuid);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
          });
          await orgPageList();
        } else {
          orgPageList();
        }
      } catch (error) {
        toast.error(error.response.data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    }
  };

  /**
   * Sortin Organization Table
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder(SORTING_ORDER.ASC);
    }
    if (sortOrder === SORTING_ORDER.ASC) {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder(SORTING_ORDER.DSC);
    }
    if (sortOrder === SORTING_ORDER.DSC) {
      setSortBy();
      setSortOrder("");
    }
  };

  return (
    <Fragment>
      <Breadcrumbs parent="Landing Page" />
      <TopLoadingBar progress={progress} setProgress={setProgress} />
      <PersonalPageItem
        formvalue={formvalue}
        EditHandle={EditHandle}
        sorting={sorting}
        switch_onChange_handle={switch_onChange_handle}
        ShowPersonalPage={ShowPersonalPage}
        AddPageItem={AddPageItem}
        DeleteHandle={DeleteHandle}
      />
      <DefaultSelectPopup orgPageList={orgPageList} />
      {/* <PreviewPopup /> */}
    </Fragment>
  );
};

export default PersonalPage;
