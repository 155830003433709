import React, { useContext } from "react";
import ServiceCounterContext from "../../../context/ServiceCounterContext/ServiceCounterContext";
import ServiceCounterStyleOne from "./ServiceCounterStyleOne";
import ServiceCounterStyleTwo from "./ServiceCounterStyleTwo";
import { ServiceCounterTheme } from "../../../../constant";

const SettingServicePerentPopup = (props) => {
  const { theme } = useContext(ServiceCounterContext);
  const { imageUpload, handleSubmitSetting, handleChangeSettings } = props;
  return (
    <>
      {+theme === ServiceCounterTheme.theme1 && (
        <ServiceCounterStyleOne
          imageUpload={imageUpload}
          handleSubmitSetting={handleSubmitSetting}
          handleChangeSettings={handleChangeSettings}
        />
      )}
      {+theme === ServiceCounterTheme.theme2 && (
        <ServiceCounterStyleTwo
          imageUpload={imageUpload}
          handleSubmitSetting={handleSubmitSetting}
          handleChangeSettings={handleChangeSettings}
        />
      )}
    </>
  );
};

export default SettingServicePerentPopup;
