import React, { useContext, useEffect, useState } from "react";
import PersonalPageContext from "../context/PersonalPageContext/PersonalPageContaxt";
import ProfileComponentContext from "../context/ProfilecomponentContext/ProfilecomponetContext";
import { X, Check } from "react-feather";
import Select from "react-select";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Media,
  Row,
} from "reactstrap";
import { Style, THEME_SELECT, TITLES, TYPES } from "../constant";
import LoaderContext from "../context/LoaderContext/LoaderContext";
import { Tooltip } from "@material-ui/core";
import { headerStyle } from "../utils/MuiTabelStyle";
import { maxLengthCheck } from "../utils/helper";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Zoom, toast } from "react-toastify";
import {
  checkSlugUrl,
  getThemeList,
} from "../api/Organization Api/OrganizationApi";
import Switch from "react-js-switch";

const ProfileComponentItem = (props) => {
  const { onCheck, readUrl, baseUrl, onSubmit, Previus, simpleValidator } =
    props;
  const {
    email,
    setEmail,
    fname,
    setFname,
    lname,
    setLname,
    pagetitle,
    setPageTitle,
    orgName,
    setOrgName,
    designation,
    setDesignation,
    address,
    setAddress,
    conuntry,
    setConuntry,
    state,
    setState,
    city,
    setCity,
    logo,

    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    userEmail,
    setUserEmail,
    mobile,
    setMobile,
    seoTitle,
    setSeoTitle,
    seoDescription,
    setSeoDescription,
    seoKey,
    setSeoKey,
    isAddHandle,
    check,
    setData,
    data,
    dialCode,
    setDialCode,
    slugUrl,
    setSlugUrl,
    isDialog,
    setIsDialog,
    isFource,
    setIsFource,
    isLabel,
    setIsLabel,

    isShowBranch,
    setIsShowBranch,
    isBranchLabel,
    setIsBranchLabel,
  } = useContext(ProfileComponentContext);
  const { clone, togglesubmit, pageLimit } = useContext(PersonalPageContext);
  const { loaderShow } = useContext(LoaderContext);
  const [themeList, setThemeList] = useState([]);

  const getThemeListData = async () => {
    try {
      let paramObj = {
        isThemeSellerAccess: true,
        showAll: true,
        sortBy: "label",
        sortOrder: "ASC",
      };
      const { data } = await getThemeList(paramObj);
      if (data.status === 200) {
        setThemeList(data.payload.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  useEffect(() => {
    getThemeListData();
  }, []);

  const handleChange = (prev) => {
    setData(prev);
  };

  const handleFocuseChange = async () => {
    if (slugUrl) {
      try {
        let paramObj = {
          slugUrl,
          type: TYPES.LANDING_PAGE,
          orgId: localStorage.getItem("orgId"),
        };
        await checkSlugUrl(paramObj);
      } catch (error) {
        toast.error(error.response.data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    }
  };

  const setPageTitleAndSlug = (e) => {
    setPageTitle(e.target.value);
    setSeoTitle(e.target.value);
    setSeoDescription(e.target.value);
    setSeoKey(e.target.value);
    setSlugUrl(e.target.value.replace(/\s+/g, "-").toLowerCase());
  };

  return (
    <Container fluid={true}>
      <Row>
        <Col sm="12" style={{ backgroundColor: "#f8f8f8" }}>
          <Card className="CardProfileCompo">
            <CardHeader
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {clone === false ? (
                <h5 style={headerStyle}>{TITLES.CLONE_PROFILE}</h5>
              ) : !togglesubmit ? (
                <h5 style={headerStyle}>{TITLES.COMPLITE_YOUR_PROFILE}</h5>
              ) : (
                <h5 style={headerStyle}>{TITLES.Edit_YOUR_PROFILE}</h5>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ paddingRight: "1rem" }}>
                  <Tooltip title="close">
                    <button
                      aria-label="add"
                      className="btn btn-light"
                      onClick={() => Previus()}
                      style={Style}
                    >
                      <X style={{ color: "#968989" }} />
                    </button>
                  </Tooltip>
                </div>
                <div style={{ paddingRight: "1rem" }}>
                  <Tooltip title="Submit">
                    <button
                      aria-label="add"
                      className="btn btn-success"
                      style={Style}
                      onClick={(e) => onSubmit(e)}
                      disabled={loaderShow === true}
                    >
                      <Check />
                    </button>
                  </Tooltip>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <Form className="needs-validation" noValidate="">
                <CardHeader>
                  <h5>{"Company Details & "}</h5>
                </CardHeader>
                <Row>
                  <Col
                    md="6 mb-3"
                    className="d-flex flex-column justify-content-center align-items-center "
                  >
                    <Label htmlFor="validationCustom03">{"Org Logo"}</Label>
                    <div className="user-image">
                      <div className="avatar">
                        <Media
                          body
                          alt=""
                          src={baseUrl + logo}
                          style={{
                            width: "9rem",
                            height: "9rem",
                            borderRadius: "45rem",
                            border: "1px solid ",
                          }}
                          data-intro="This is Profile image"
                        />
                      </div>
                      <div
                        className="icon-wrapper"
                        data-intro="Change Profile image here"
                      >
                        <i className="icofont icofont-pencil-alt-5">
                          <Input
                            className="upload"
                            type="file"
                            onChange={(e) => readUrl(e, "company")}
                            style={{
                              border: "2px solid red",
                              width: "10rem",
                              position: "absolute",
                              bottom: "0",
                              left: "50%",
                              right: "50%",
                              transform: "translate(-50%)",
                              height: "12rem",
                            }}
                          />
                        </i>
                      </div>
                    </div>
                  </Col>
                  {/* <Col
                    md="6 mb-3"
                    className="d-flex flex-column justify-content-center align-items-center "
                  >
                    <Label htmlFor="validationCustom03">{"User Image"}</Label>
                    <div className="user-image">
                      <div className="avatar">
                        <Media
                          body
                          alt=""
                          src={baseUrl + userPhoto}
                          style={{
                            width: "9rem",
                            height: "9rem",
                            borderRadius: "45rem",
                            border: "1px solid ",
                          }}
                          data-intro="This is Profile image"
                        />
                      </div>
                      <div
                        className="icon-wrapper"
                        data-intro="Change Profile image here"
                      >
                        <i className="icofont icofont-pencil-alt-5">
                          <Input
                            className="upload"
                            type="file"
                            onChange={(e) => readUrl(e, "user")}
                            style={{
                              border: "2px solid red",
                              width: "10rem",
                              position: "absolute",
                              bottom: "0",
                              left: "50%",
                              right: "50%",
                              transform: "translate(-50%)",
                              height: "12rem",
                            }}
                          />
                        </i>
                      </div>
                    </div>
                  </Col> */}
                </Row>
                <Row>
                  <Col md="6 mb-3">
                    <Label htmlFor="validationCustom03">{"Page Title*"}</Label>
                    <input
                      className="form-control"
                      name="contactNumber "
                      type="text"
                      placeholder="Page Title"
                      value={pagetitle}
                      // onChange={(e) => setPageTitle(e.target.value)}
                      onChange={(e) => setPageTitleAndSlug(e)}
                    />
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "page Title",
                        pagetitle,
                        "required"
                      )}
                    </p>
                    <div className="invalid-feedback">
                      {"Please provide a valid city."}
                    </div>
                  </Col>
                  <Col md="6 mb-3">
                    <Label htmlFor="validationCustom03">
                      {"Page Url (Slug Url)"}
                    </Label>
                    <input
                      className="form-control"
                      name="personeNane"
                      type="text"
                      placeholder="Page Url"
                      value={slugUrl}
                      onChange={(e) =>
                        setSlugUrl(
                          e.target.value.replace(/\s+/g, "-").toLowerCase()
                        )
                      }
                      onBlur={() => handleFocuseChange()}
                    />
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "Page Url",
                        slugUrl,
                        "required"
                      )}
                    </p>
                    <div className="invalid-feedback">
                      {"Please provide a valid city."}
                    </div>
                  </Col>

                  <Col md="6 mb-3">
                    <Label htmlFor="validationCustom03">
                      {"Select Theme*"}
                    </Label>
                    <Select
                      defaultValue={data}
                      // options={THEME_SELECT.map((elem) => {
                      //   return {
                      //     value: elem.value,
                      //     label: elem.label,
                      //   };
                      // })}
                      options={themeList.map((elem) => {
                        return {
                          value: elem.templateId,
                          label: elem.templateName,
                        };
                      })}
                      onChange={(prev) => handleChange(prev)}
                    />
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "Theme name",
                        data,
                        "required"
                      )}
                    </p>
                    <div className="invalid-feedback">
                      {"Please provide a valid city."}
                    </div>
                  </Col>
                  <Col md="6 mb-3">
                    <Label htmlFor="validationCustom03">
                      {"Company Name*"}
                    </Label>
                    <input
                      className="form-control"
                      name="city"
                      type="text"
                      placeholder="Company Name"
                      value={orgName}
                      onChange={(e) => setOrgName(e.target.value)}
                    />
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "Company name",
                        orgName,
                        "required"
                      )}
                    </p>
                    <div className="invalid-feedback">
                      {"Please provide a valid city."}
                    </div>
                  </Col>

                  {/* <Col md="6 mb-3">
                    <Label htmlFor="validationCustom03">{"Broker Slug"}</Label>
                    <input
                      className="form-control"
                      name="personeNane"
                      type="text"
                      placeholder="Broker Slug"
                      value={blockerSlugUrl}
                      onChange={(e) => setBlockerSlugUrl(e.target.value)}
                    />
                    <div className="invalid-feedback">
                      {"Please provide a valid city."}
                    </div>
                  </Col> */}
                </Row>
                <hr />

                <Row style={{ marginTop: "30px" }}>
                  <Col md="4 mb-3">
                    <Label htmlFor="validationCustom03">{"SEO Title"}</Label>
                    <input
                      className="form-control"
                      name="personeNane"
                      type="text"
                      placeholder="SEO Title"
                      value={seoTitle}
                      onChange={(e) => setSeoTitle(e.target.value)}
                    />
                    <div className="invalid-feedback">
                      {"Please provide a valid city."}
                    </div>
                  </Col>
                  <Col md="4 mb-3">
                    <Label htmlFor="validationCustom03">
                      {"SEO Description"}
                    </Label>
                    <input
                      className="form-control"
                      name="designation"
                      type="text"
                      placeholder="SEO Description"
                      value={seoDescription}
                      onChange={(e) => setSeoDescription(e.target.value)}
                    />
                    <div className="invalid-feedback">
                      {"Please provide a valid city."}
                    </div>
                  </Col>
                  <Col md="4 mb-3">
                    <Label htmlFor="validationCustom03">{"SEO Keyword"}</Label>
                    <input
                      className="form-control"
                      name="email"
                      type="email"
                      placeholder="SEO Keyword"
                      value={seoKey}
                      onChange={(e) => setSeoKey(e.target.value)}
                    />
                    <div className="invalid-feedback">
                      {"Please provide a valid city."}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6 mb-3">
                    <Label htmlFor="validationCustom03">{"First Name*"}</Label>
                    <input
                      className="form-control"
                      name="personeNane"
                      type="text"
                      placeholder="Frist Name"
                      value={fname}
                      onChange={(e) => setFname(e.target.value)}
                    />
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "frist name",
                        fname,
                        "required"
                      )}
                    </p>
                    <div className="invalid-feedback">
                      {"Please provide a valid city."}
                    </div>
                  </Col>
                  <Col md="6 mb-3">
                    <Label htmlFor="validationCustom03">{"Last Name"}</Label>
                    <input
                      className="form-control"
                      name="designation"
                      type="text"
                      placeholder="Last Name"
                      value={lname}
                      onChange={(e) => setLname(e.target.value)}
                    />

                    <div className="invalid-feedback">
                      {"Please provide a valid city."}
                    </div>
                  </Col>
                  <Col md="6 mb-3">
                    <Label htmlFor="validationCustom03">{"Email*"}</Label>
                    <input
                      className="form-control"
                      name="email"
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "email",
                        email,
                        "required"
                      )}
                    </p>
                    <div className="invalid-feedback">
                      {"Please provide a valid city."}
                    </div>
                  </Col>
                  <Col md="6 mb-3">
                    <Label htmlFor="validationCustom03">{"Designation"}</Label>
                    <input
                      className="form-control"
                      name="whatsappNumber"
                      type="text"
                      placeholder="Designation"
                      value={designation}
                      onChange={(e) => setDesignation(e.target.value)}
                    />
                    <div className="invalid-feedback">
                      {"Please provide a valid city."}
                    </div>
                  </Col>
                </Row>
                <hr />

                <Row style={{ marginTop: "30px" }}>
                  <Col md="12 mb-3">
                    <Label htmlFor="validationCustom03">{"Address*"}</Label>
                    <input
                      className="form-control"
                      name="address 1"
                      type="text"
                      placeholder="Address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "address",
                        address,
                        "required"
                      )}
                    </p>
                    <div className="invalid-feedback">
                      {"Please provide a valid city."}
                    </div>
                  </Col>
                  <Col md="4 mb-3">
                    <Label htmlFor="validationCustom03">{"City*"}</Label>
                    <input
                      className="form-control"
                      name="address 2"
                      type="text"
                      placeholder="City"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "city",
                        city,
                        "required"
                      )}
                    </p>
                    <div className="invalid-feedback">
                      {"Please provide a valid city."}
                    </div>
                  </Col>
                  <Col md="4 mb-3">
                    <Label htmlFor="validationCustom03">{"State*"}</Label>
                    <input
                      className="form-control"
                      name="State"
                      type="text"
                      placeholder="State"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                    />
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "state",
                        state,
                        "required"
                      )}
                    </p>
                    <div className="invalid-feedback">
                      {"Please provide a valid city."}
                    </div>
                  </Col>
                  <Col md="4 mb-3">
                    <Label htmlFor="validationCustom03">{"Country*"}</Label>
                    <input
                      className="form-control"
                      name="city"
                      type="text"
                      placeholder="Country"
                      value={conuntry}
                      onChange={(e) => setConuntry(e.target.value)}
                    />
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "Country",
                        conuntry,
                        "required"
                      )}
                    </p>
                    <div className="invalid-feedback">
                      {"Please provide a valid city."}
                    </div>
                  </Col>
                </Row>
                <hr />
                <CardHeader>
                  <h5>{"Page Setting"}</h5>
                </CardHeader>
                <Row className="mt-3">
                  <Col md="4 mb-3">
                    <Label htmlFor="validationCustom03">
                      {"Open Popup Before Showing Landing Page"}
                    </Label>
                    <Switch
                      value={isDialog}
                      onChange={() => setIsDialog(!isDialog)}
                    />
                    <div className="invalid-feedback">
                      {"Please provide a valid city."}
                    </div>
                  </Col>
                  {isDialog && (
                    <>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom03">
                          {"Skip Option"}
                        </Label>
                        <Switch
                          value={isFource}
                          onChange={() => setIsFource(!isFource)}
                          disabled={isDialog === false}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid city."}
                        </div>
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom03">
                          {"Popup Title"}
                        </Label>
                        <input
                          className="form-control"
                          name="city"
                          type="text"
                          placeholder="Popup Title"
                          value={isLabel}
                          onChange={(e) => setIsLabel(e.target.value)}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid city."}
                        </div>
                      </Col>
                    </>
                  )}
                  {pageLimit > 1 && (
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom03">
                        {"Show Branch"}
                      </Label>
                      <Switch
                        value={isShowBranch}
                        onChange={() => setIsShowBranch(!isShowBranch)}
                      />
                      <div className="invalid-feedback">
                        {"Please provide a valid city."}
                      </div>
                    </Col>
                  )}
                  {isShowBranch && (
                    <>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom03">
                          {"Branch Label"}
                        </Label>
                        <input
                          className="form-control"
                          name="city"
                          type="text"
                          placeholder="Branch Label"
                          value={isBranchLabel}
                          onChange={(e) =>
                            setIsBranchLabel(
                              e.target.value.replace(/\s+/g, "-").toLowerCase()
                            )
                          }
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid city."}
                        </div>
                      </Col>
                    </>
                  )}
                </Row>
                <hr />
                {/* <Button color="primary" onClick={onCheck}> */}
                {!togglesubmit || clone === false ? (
                  <div className="checkbox checkbox-primary">
                    <input
                      id="checkbox-primary-1"
                      type="checkbox"
                      onClick={onCheck}
                    />
                    <Label htmlFor="checkbox-primary-1">
                      {"Create User - Password For This Page "}
                    </Label>
                  </div>
                ) : null}
                {!togglesubmit || clone === false ? (
                  check === false ? (
                    <>
                      <CardHeader>
                        <h5>{"User"}</h5>
                      </CardHeader>
                      <Row style={{ marginTop: "30px" }}>
                        <Col md="6 mb-3">
                          <Label htmlFor="validationCustom03">
                            {"User Email*"}
                          </Label>
                          <input
                            className="form-control"
                            name="address 1"
                            type="email"
                            placeholder="User Email"
                            value={userEmail}
                            onChange={(e) => setUserEmail(e.target.value)}
                          />
                          <div className="invalid-feedback">
                            {"Please provide a valid city."}
                          </div>
                        </Col>
                        <Col md="1 mb-3" style={{ width: "118px" }}>
                          <Label htmlFor="validationCustom03">
                            {"Dial Code*"}
                          </Label>
                          <PhoneInput
                            className="form-control height"
                            international
                            defaultCountry="IN"
                            placeholder="Enter Phone Number "
                            value={dialCode}
                            onChange={setDialCode}
                          />
                        </Col>
                        <Col md="5 mb-3">
                          <Label htmlFor="validationCustom03">
                            {"Mobile*"}
                          </Label>
                          <input
                            className="form-control"
                            name="Mobile"
                            type="number"
                            placeholder="Mobile"
                            value={mobile}
                            min={"0"}
                            maxLength={10}
                            onInput={maxLengthCheck}
                            onChange={(e) => setMobile(e.target.value)}
                          />
                          <div className="invalid-feedback">
                            {"Please provide a valid city."}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        {isAddHandle ? (
                          <>
                            <Col md="6 mb-3">
                              <Label htmlFor="validationCustom03">
                                {"Password"}
                              </Label>
                              <input
                                className="form-control"
                                name="area"
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                              <div className="invalid-feedback">
                                {"Please provide a valid city."}
                              </div>
                            </Col>
                            <Col md="6 mb-3">
                              <Label htmlFor="validationCustom03">
                                {"Confirm Password*"}
                              </Label>
                              <input
                                className="form-control"
                                name="COnfirm Password"
                                type="password"
                                placeholder="Confirm Password"
                                value={confirmPassword}
                                onChange={(e) =>
                                  setConfirmPassword(e.target.value)
                                }
                              />
                              <div className="invalid-feedback">
                                {"Please provide a valid city."}
                              </div>
                            </Col>
                          </>
                        ) : null}
                      </Row>
                    </>
                  ) : null
                ) : (
                  <>
                    <>
                      <CardHeader>
                        <h5>{"User"}</h5>
                      </CardHeader>
                      <Row style={{ marginTop: "30px" }}>
                        <Col md="6 mb-3">
                          <Label htmlFor="validationCustom03">
                            {"User Email*"}
                          </Label>
                          <input
                            className="form-control"
                            name="address 1"
                            type="email"
                            placeholder="User Email"
                            value={userEmail}
                            onChange={(e) => setUserEmail(e.target.value)}
                          />
                          <div className="invalid-feedback">
                            {"Please provide a valid city."}
                          </div>
                        </Col>
                        <Col md="2 mb-3" style={{ width: "118px" }}>
                          <Label htmlFor="validationCustom03">
                            {"Dial Code*"}
                          </Label>
                          <PhoneInput
                            className="form-control height"
                            international
                            defaultCountry="IN"
                            placeholder="Enter Phone Number "
                            value={dialCode}
                            onChange={setDialCode}
                          />
                        </Col>
                        <Col md="4 mb-3">
                          <Label htmlFor="validationCustom03">
                            {"Mobile*"}
                          </Label>
                          <input
                            className="form-control"
                            name="Mobile"
                            type="number"
                            placeholder="Mobile"
                            value={mobile}
                            min={"0"}
                            maxLength={10}
                            onInput={maxLengthCheck}
                            onChange={(e) => setMobile(e.target.value)}
                          />
                          <div className="invalid-feedback">
                            {"Please provide a valid city."}
                          </div>
                        </Col>
                      </Row>
                    </>
                    <Row>
                      {isAddHandle ? (
                        <>
                          <Col md="6 mb-3">
                            <Label htmlFor="validationCustom03">
                              {"Password"}
                            </Label>
                            <input
                              className="form-control"
                              name="area"
                              type="password"
                              placeholder="Password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <div className="invalid-feedback">
                              {"Please provide a valid city."}
                            </div>
                          </Col>
                          <Col md="6 mb-3">
                            <Label htmlFor="validationCustom03">
                              {"Confirm Password*"}
                            </Label>
                            <input
                              className="form-control"
                              name="COnfirm Password"
                              type="password"
                              placeholder="Confirm Password"
                              value={confirmPassword}
                              onChange={(e) =>
                                setConfirmPassword(e.target.value)
                              }
                            />
                            <div className="invalid-feedback">
                              {"Please provide a valid city."}
                            </div>
                          </Col>
                        </>
                      ) : null}
                    </Row>
                  </>
                )}
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ProfileComponentItem;
