import React, { useContext, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Cases, DELETE_CONFIRM, modalsName } from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import CommonPopup from "../../../Modals/commonPopup";
import { FILE_TYPE, ThemeNo } from "../../../../constant";
import { FILE_UPLOAD_API } from "../apis/SliderApi/SliderApi";
import PageTitlePopup from "./PageTitlePopup";
import { IconButton } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import { Settings, Trash } from "react-feather";
import { isEmptyObject, rgbColorCreate } from "../../../../utils/helper";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import SimpleReactValidator from "simple-react-validator";
import PageTitleContext from "../../../context/pageTitleContext/PageTitleContext";
import {
  deletePageTitle,
  getPageTitleDetails,
  postPageTitle,
  putUpdatePageTitle,
  updateStatus,
} from "../apis/PageTitleApi/PageTitleApi";
import SettingPageTitleParentPopup from "./SettingPageTitleParentPopup";
import DeleteSection from "../../../common/DeleteSection";
import SettingMultiWebContext from "../../../../context/SettingMultiWebContext/SettingMultiWebContext";
import {
  PageTitleStyleONe,
  PageTitleStyleOneSubFun,
  PageTitleThemeOneFunction,
} from "./PageTitleStyleFunction";

const PageTitle = (props) => {
  const {
    pageConfigDetails,
    pageTitleDetail,
    contentMapId,
    ByteMiniWeb_Dashboard,
    contentDetail,
  } = props;
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );
  const { setCloneAlignModal, setAlignFormData, setCloneFormData } = useContext(
    SettingMultiWebContext
  );
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const { setLoaderShow } = useContext(LoaderContext);
  const {
    formDataOurTeam,
    setFormDataOurData,
    id,
    setId,
    setIsEdit,
    isEdit,
    settingFormData,
    setSettingFormData,
    setBackImageToggle,
    collId,
    setCollId,
    isBackgroundColor,
    setIsBackgroundColor,
    setImgShow,
    theme,
    setTheme,
    sectionPageName,
    setSectionPageName,
  } = useContext(PageTitleContext);

  /**
   * page title enable disable api call
   * @param {team id} pageTitleId
   * @param {status} isActive
   */

  const handleChangePageTitleStatus = async (pageTitleId, isActive) => {
    try {
      let body = {
        pageTitleId,
        isActive: isActive ? false : true,
      };
      const { data } = await updateStatus(body);
      if (data.status === 200) {
        ByteMiniWeb_Dashboard();
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * file upload
   * @param {file} file
   */
  const imageUpload = async (file, name) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.PAGE_TITLE);
    formData.append("file", file);
    setLoaderShow(true);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        setFormDataOurData((prev) => {
          return {
            ...prev,
            image: data.payload.baseUrl + data.payload.imageUrl,
          };
        });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const imageUploadSetting = async (file, name) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.PAGE_TITLE);
    formData.append("file", file);
    setLoaderShow(true);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        setSettingFormData((prev) => {
          return {
            ...prev,
            ".page-head.bg-image": {
              "background-image": data.payload.baseUrl + data.payload.imageUrl,
            },
          };
        });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * create and update our page title
   */
  const handleSubmitSetting = async (isRestore = false, isClose = true) => {
    if (id >= -1 && isEdit) {
      setLoaderShow(true);
      try {
        let newData;
        if (isRestore) {
          const isConfirm = window.confirm(
            "Are you sure want to restore style ?"
          );
          if (isConfirm) {
            newData = {
              styleRestore: true,
              pageTitleId: id,
            };
          } else {
            setLoaderShow(false);
            return;
          }
        } else {
          if (+theme === ThemeNo.ThemeOne) {
            newData = PageTitleStyleOneSubFun({
              settingFormData,
            });
            if (isBackgroundColor) {
              newData[".page-head"] = {
                ...newData?.[".page-head"],
                "background-color": settingFormData[".page-head"]?.[
                  "background-color"
                ]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData[".page-head"]?.["background-color"],
                    })
                  : "",
              };
            }
          }
          const keysToRemove = [
            "p1",
            "p2",
            "image",
            "cp",
            "cp11",
            "cp12",
            "cp21",
            "cp22",
            "cp31",
          ];
          function removeKeys(obj, keys) {
            for (const key in obj) {
              if (typeof obj[key] === "object") {
                removeKeys(obj[key], keys);
              } else if (keys.includes(key)) {
                delete obj[key];
              }
            }
          }
          removeKeys(newData, keysToRemove);
          newData = {
            style: newData,
            pageTitleId: id,
          };
        }

        const { data } = await putUpdatePageTitle(newData);
        if (data.status === 200) {
          if (isClose) {
            OpenModalCommonFunc(
              Cases.SETTING_PAGE_TITLE_PARENT,
              setAllPopupState,
              false
            );
            emptyData();
            setBackImageToggle(false);
          }
          setLoaderShow(false);
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  /**
   * get setting data
   * @param {id} pageTitleId
   */
  const handleChangeSettings = async (pageTitleId, isThemeEdit = false) => {
    try {
      setLoaderShow(true);
      setId(pageTitleId);
      setSettingFormData((prev) => {
        return {
          ...prev,
          pageTitleId,
        };
      });
      const { data } = await getPageTitleDetails(pageTitleId);
      if (data.status === 200) {
        const themeCount = data?.payload?.data?.theme;
        setTheme(themeCount);
        setImgShow(true);
        let payload = data.payload.data;
        setSectionPageName(payload.title);
        if (
          +themeCount === ThemeNo.ThemeOne &&
          isEmptyObject(data?.payload?.data?.style)
        ) {
          setSettingFormData({ ...PageTitleStyleONe });
          setIsBackgroundColor(false);
          setBackImageToggle(false);
        } else {
          if (payload.style?.[".page-head.bg-image"]?.["background-image"]) {
            setBackImageToggle(true);
          }
          if (payload.style?.[".page-head"]?.["background-color"]) {
            setIsBackgroundColor(true);
          }
          let getStyle;
          if (+themeCount === ThemeNo.ThemeOne) {
            getStyle = PageTitleThemeOneFunction(payload);
          } else {
            getStyle = PageTitleThemeOneFunction(payload);
          }
          setSettingFormData((prev) => {
            return {
              ...prev,
              ...getStyle,
            };
          });
        }
        if (!isThemeEdit) {
          OpenModalCommonFunc(
            Cases.SETTING_PAGE_TITLE_PARENT,
            setAllPopupState
          );
          TostMessage({ message: data, type: "success" });
        }
        setIsEdit(true);
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * create and update page title
   */
  const handleSubmitPageTitle = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      if (id >= -1 && isEdit) {
        setLoaderShow(true);
        try {
          const { data } = await putUpdatePageTitle(formDataOurTeam);
          if (data.status === 200) {
            ByteMiniWeb_Dashboard();
            emptyData();
            OpenModalCommonFunc(Cases.PAGE_TITLE, setAllPopupState, false);
            TostMessage({ message: data, type: "success" });
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      } else {
        try {
          setLoaderShow(true);
          let formData = {
            ...formDataOurTeam,
            contentMapId: collId,
          };
          const { data } = await postPageTitle(formData);
          if (data.status === 200) {
            ByteMiniWeb_Dashboard();
            emptyData();
            OpenModalCommonFunc(Cases.PAGE_TITLE, setAllPopupState, false);
            TostMessage({ message: data, type: "success" });
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      }
    }
  };

  /**
   * get data page title
   * @param {id} pageTitleId
   */
  const handleEditPageTitle = async (pageTitleId) => {
    try {
      setLoaderShow(true);
      setId(pageTitleId);
      const { data } = await getPageTitleDetails(pageTitleId);
      if (data.status === 200) {
        let payload = data.payload.data;
        setSectionPageName(payload.title);
        setFormDataOurData({
          title: payload.title || "",
          subTitle: payload.subTitle || "",
          theme: payload.theme || 1,
          pageTitleId: pageTitleId,
          navMapId: payload.navMapId || "",
          webId: localStorage.getItem("webId"),
          style: payload.style || "",
        });
        OpenModalCommonFunc(Cases.PAGE_TITLE, setAllPopupState);
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(true);
    }
  };

  /**
   * delete page title
   * @param {id} pageTitleId
   */
  const handleDelete = async (pageTitleId) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await deletePageTitle(pageTitleId);
        if (data.status === 200) {
          ByteMiniWeb_Dashboard();
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * clear data
   */
  const emptyData = () => {
    setIsBackgroundColor(false);
    setIsEdit(false);
    setFormDataOurData({
      title: "",
      subTitle: "",
      theme: 1,
      navMapId: "",
      webId: localStorage.getItem("webId"),
      style: {
        ".page-head": {
          padding: "100px 0",
          "clip-path": "polygon(0 0, 100% 0%, 100% 80%, 50% 100%, 0 80%)",
          cp: "100%",
          cp11: "100%",
          cp12: "80%,",
          cp21: "50%",
          cp22: "100%,",
          cp31: "80%)",
          "background-color": "rgb(0 0 0 / 100)",
        },
        ".page-head.bg-image": {
          "background-image": "",
        },
        ".page-head.bg-image::before": {
          "background-color": "",
        },
        ".page-head .page-title": {
          color: "rgb(255 255 255 / 100)",
          "font-size": "40px",
          "font-weight": 500,
          "margin-bottom": "20px",
        },
        ".page-head .breadcrumb .breadcrumb-item a": {
          "font-size": "15px",
          "font-weight": 600,
          color: "rgb(255 73 124 / 100)",
        },
        ".page-head .breadcrumb .breadcrumb-item.active": {
          "font-size": "15px",
          "font-weight": 600,
          color: "rgb(255 255 255 / 100)",
        },
        pageTitleId: "",
      },
    });
    setSettingFormData({
      ".page-head": {
        padding: "100px 0",
        "clip-path": "polygon(0 0, 100% 0%, 100% 80%, 50% 100%, 0 80%)",
        cp: "100%",
        cp11: "100%",
        cp12: "80%,",
        cp21: "50%",
        cp22: "100%,",
        cp31: "80%)",
        "background-color": { r: 0, g: 0, b: 0, a: 100 },
      },
      ".page-head.bg-image": {
        "background-image": "",
      },
      ".page-head.bg-image::before": {
        "background-color": "",
      },
      ".page-head .page-title": {
        color: "rgb(255 255 255 / 100)",
        "font-size": "40px",
        "font-weight": 500,
        "margin-bottom": "20px",
      },
      ".page-head .breadcrumb .breadcrumb-item a": {
        "font-size": "15px",
        "font-weight": 600,
        color: "rgb(255 73 124 / 100)",
      },
      ".page-head .breadcrumb .breadcrumb-item.active": {
        "font-size": "15px",
        "font-weight": 600,
        color: "rgb(255 255 255 / 100)",
      },
      pageTitleId: "",
    });
    setLoaderShow(false);
  };

  const handleAlignAndCloneSection = ({ isClone = false }) => {
    if (isClone) {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          cloneModal: true,
        };
      });
      setCloneFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          pageConfigDetailsLabel: pageConfigDetails[0]?.label,
          contentDetailName: contentDetail.name,
        };
      });
    } else {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          alignModal: true,
        };
      });
      setAlignFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          label: pageConfigDetails[0]?.label,
        };
      });
    }
  };

  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody
            className="support-ticket-font box-border-div"
            style={{ padding: "20px" }}
          >
            <Row>
              <CardBuilderHeaderSection
                addBtnTitle={pageConfigDetails[0]?.label}
                name={pageConfigDetails[0]?.label}
                isAdd={!pageTitleDetail && true}
                mainEditSection={true}
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
                openModal={() => {
                  OpenModalCommonFunc(Cases.PAGE_TITLE, setAllPopupState);
                  setSectionPageName(modalsName.PAGE_TITLE);
                  setCollId(contentMapId);
                }}
                isCloneSection={true}
                isAlignSection={true}
                handleAlignAndCloneSection={handleAlignAndCloneSection}
              />
              {pageTitleDetail && (
                <Col xl="12 xl-50 box-col-6" md="6">
                  <Card className="ecommerce-widget mb-0">
                    <CardBody
                      className="support-ticket-font"
                      style={{ padding: "20px" }}
                    >
                      <div className="col-12">
                        <CardBuilderHeaderSection
                          name={pageTitleDetail?.title}
                          checked={pageTitleDetail?.isActive}
                          switchToggle={true}
                          id={pageTitleDetail?.pageTitleId}
                          handleChange={handleChangePageTitleStatus}
                        />
                      </div>
                      <div className="d-flex justify-content-end ">
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleEditPageTitle(pageTitleDetail.pageTitleId);
                            setCollId(contentMapId);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() =>
                            handleDelete(pageTitleDetail.pageTitleId)
                          }
                        >
                          <Trash />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleChangeSettings(pageTitleDetail.pageTitleId);
                          }}
                        >
                          <Settings />
                        </IconButton>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
              <DeleteSection
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
              />
            </Row>
          </CardBody>
        </Card>
      </Col>

      {/* our team popup */}
      <CommonPopup
        open={allPopupState.pageTitlePopup}
        close={() =>
          OpenModalCommonFunc(Cases.PAGE_TITLE, setAllPopupState, false)
        }
        modalName={sectionPageName ? sectionPageName : modalsName.PAGE_TITLE}
        modalCase={Cases.PAGE_TITLE}
        handleSubmit={handleSubmitPageTitle}
        emptyData={emptyData}
        inputData={formDataOurTeam}
        edit={false}
        content={
          <PageTitlePopup
            imageUpload={imageUpload}
            simpleValidator={simpleValidator}
          />
        }
      />

      {/* setting images parent popup */}
      <CommonPopup
        open={allPopupState.pageTitleSettingPopup}
        close={() =>
          OpenModalCommonFunc(
            Cases.SETTING_PAGE_TITLE_PARENT,
            setAllPopupState,
            false
          )
        }
        modalName={
          sectionPageName
            ? sectionPageName
            : modalsName.SETTING_PAGE_TITLE_PARENT
        }
        modalCase={Cases.SETTING_PAGE_TITLE_PARENT}
        handleSubmit={handleSubmitSetting}
        emptyData={emptyData}
        inputData={settingFormData}
        edit={false}
        content={
          <SettingPageTitleParentPopup
            imageUpload={imageUploadSetting}
            handleSubmitSetting={handleSubmitSetting}
            handleChangeSettings={handleChangeSettings}
          />
        }
      />
    </>
  );
};

export default PageTitle;
