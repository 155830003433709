import React, { useContext } from "react";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import { Border, TextAlign, fontWeight } from "../../../../constant";
import VideoBannerContext from "../../../context/VideoBannerContext/VideoBannerContext";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import { IconButton } from "@material-ui/core";
import { Zoom, toast } from "react-toastify";
import { Switch } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { UploadCloud } from "react-feather";
import { Cases, DELETE_CONFIRM } from "../../../constant";
import {
  delete_Image_Permanent,
  delete_Multi_Image_Permanent,
} from "../apis/SliderApi/SliderApi";
import Dividers from "../../../common/divider";
import { OpenModalCommonFunc } from "../../../utils";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import { SketchPicker } from "react-color";
import CommonColor from "../../../common/CommonColor";
import CustomStyle from "../../../common/CustomStyle";

const SettingVideoBannerParentPopup = (props) => {
  const {
    isEdit,
    settingFormData,
    setSettingFormData,
    backImageToggle,
    setBackImageToggle,
    boxSection,
    setBoxSection,
    childId,
    isBackgroundColor,
    setIsBackgroundColor,
    countOfList,
    setCountOfList,
  } = useContext(VideoBannerContext);
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const { imageUpload, handleSubmitSetting } = props;
  const { setLoaderShow } = useContext(LoaderContext);

  // const { simpleValidator } = props;

  /**
   * input value change
   * @param {event} e
   */

  const handleChangeVideoBannerSection = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [`.video-banner-section${countOfList}`]: {
          ...prev[`.video-banner-section${countOfList}`],
          [name]: value,
        },
      };
    });
  };
  const handleChangeSectionPlay = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [`.video-banner-section${countOfList} .video-play`]: {
          ...prev[`.video-banner-section${countOfList} .video-play`],
          [name]: value,
        },
      };
    });
  };
  const handleChangeVideoModelIframe = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".videomodal .framediv iframe"]: {
          ...prev[".videomodal .framediv iframe"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeCustomStyle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const resetHandler = () => {
    setSettingFormData({
      [`.video-banner-section${countOfList}`]: {
        padding: "200px 0",
        "background-color": { r: 0, g: 0, b: 0, a: 100 },
      },
      [`.video-banner-section${countOfList}.bg-image`]: {
        "background-image": "",
      },
      [`.video-banner-section${countOfList}.bg-image::before`]: {
        "background-color": "",
        // "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
      },
      [`.video-banner-section${countOfList} .video-play`]: {
        height: "80px",
        width: "80px",
        "background-color": { r: 255, g: 255, b: 255, a: 100 },
        color: { r: 255, g: 73, b: 124, a: 100 },
        "text-align": "center",
        padding: "25px 5px",
        p1: "25px",
        p2: "5px",
        "font-size": "25px",
        "border-radius": "100%",
        display: "inline-block",
        " -webkit-animation": "pulse11 1s infinite",
        animation: "pulse11 1s infinite",
      },
      ".videomodal .framediv iframe": {
        "box-shadow": "0 0 8px { r: 0, g: 0, b: 0, a: 0.6 }",
        bs1: "0px",
        bs2: "0px",
        bs3: "8px",
        shadowColor: { r: 0, g: 0, b: 0, a: 0.6 },
        "background-color": { r: 0, g: 0, b: 0, a: 100 },
      },
      custom: "",
      videoBannerId: "",
      custom: "",
    });
    setBackImageToggle(false);
    setBoxSection(false);
    setIsBackgroundColor(true);
  };

  /**
   * delete image permanently
   */
  const deleteImagePermanent = async () => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData = {
          fileName: settingFormData?.[
            `.video-banner-section${countOfList}.bg-image`
          ]?.["background-image"]
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
            .pop(),
        };
        const { data } = await delete_Multi_Image_Permanent(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            position: "top-center",
          });
          setSettingFormData({
            ...settingFormData,
            [`.video-banner-section${countOfList}.bg-image`]: {
              "background-image": "",
            },
          });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };

  /**
   * image reupload
   */
  const reupload = () => {
    setSettingFormData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };

  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="12">
            <div className="mt-4 d-flex flex-column justify-content-center position-relative">
              <div
                className="d-flex justify-content-between position-sticky"
                style={{ top: "100px", background: "white", zIndex: 999 }}
              >
                <h6>{isEdit ? "Edit" : "Add New"}</h6>
                <div className="d-flex gap-2">
                  <Button onClick={resetHandler}>Reset</Button>
                  <Button onClick={() => handleSubmitSetting(true)}>
                    Restore
                  </Button>
                  <Button
                    onClick={() => handleSubmitSetting(null, false)}
                    color="success"
                  >
                    Save & View
                  </Button>
                </div>
              </div>
              <Row className="mt-4">
                <h4 className="mb-3">Make change in Main section</h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"padding (Default : 200)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="padding"
                    type="number"
                    placeholder="padding"
                    value={settingFormData?.[
                      `.video-banner-section${countOfList}`
                    ]?.["padding"]
                      ?.split(" ")[0]
                      ?.replace(/px/g, "")}
                    onChange={(e) => handleChangeVideoBannerSection(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>

                <Col md="4 mb-3">
                  <div className="d-flex align-items-center">
                    <Label htmlFor="validationCustom03">
                      {isBackgroundColor
                        ? "Add Background Color"
                        : "Remove Background Color"}
                    </Label>
                    <Switch
                      checked={isBackgroundColor}
                      inputProps={{ "aria-label": "controlled" }}
                      onChange={(e) => {
                        setIsBackgroundColor(e.target.checked);
                        setSettingFormData((prev) => {
                          if (e.target.checked) {
                            if (boxSection) {
                              return {
                                ...prev,
                                [`.video-banner-section${countOfList}`]: {
                                  "background-color": "#000000",
                                  padding:
                                    settingFormData?.[
                                      `.video-banner-section${countOfList}`
                                    ]?.["padding"],
                                  width: settingFormData?.[
                                    `.video-banner-section${countOfList}`
                                  ]?.["width"]?.replace("rem)", ""),
                                  margin: settingFormData?.[
                                    `.video-banner-section${countOfList}`
                                  ]?.["margin"]
                                    ?.split(" ")[0]
                                    ?.replace(/px/g, ""),
                                  "border-radius": settingFormData?.[
                                    `.video-banner-section${countOfList}`
                                  ]?.["border-radius"]?.replace(/rem/g, ""),
                                },
                              };
                            } else {
                              return {
                                ...prev,
                                [`.video-banner-section${countOfList}`]: {
                                  "background-color": "#000000",
                                  padding:
                                    settingFormData?.[
                                      `.video-banner-section${countOfList}`
                                    ]?.["padding"],
                                },
                              };
                            }
                          } else {
                            if (boxSection) {
                              return {
                                ...prev,
                                [`.video-banner-section${countOfList}`]: {
                                  padding:
                                    settingFormData?.[
                                      `.video-banner-section${countOfList}`
                                    ]?.["padding"],
                                  width: settingFormData?.[
                                    `.video-banner-section${countOfList}`
                                  ]?.["width"]?.replace("rem)", ""),
                                  margin: settingFormData?.[
                                    `.video-banner-section${countOfList}`
                                  ]?.["margin"]
                                    ?.split(" ")[0]
                                    ?.replace(/px/g, ""),
                                  "border-radius": settingFormData?.[
                                    `.video-banner-section${countOfList}`
                                  ]?.["border-radius"]?.replace(/rem/g, ""),
                                },
                              };
                            } else {
                              return {
                                ...prev,
                                [`.video-banner-section${countOfList}`]: {
                                  padding:
                                    settingFormData?.[
                                      `.video-banner-section${countOfList}`
                                    ]?.["padding"],
                                },
                              };
                            }
                          }
                        });
                      }}
                    />
                  </div>
                  {isBackgroundColor && (
                    <CommonColor
                      color={
                        settingFormData?.[
                          `.video-banner-section${countOfList}`
                        ]?.["background-color"]
                      }
                      setSettingFormData={setSettingFormData}
                      className={`.video-banner-section${countOfList}`}
                      classProperty={"background-color"}
                      label={"Select Background Color"}
                    />
                  )}
                </Col>
                <p>{"Box Section : convert your section to Box section"}</p>
                <div>
                  <Switch
                    checked={boxSection}
                    inputProps={{ "aria-label": "controlled" }}
                    onChange={(e) => {
                      setBoxSection(e.target.checked);
                      setSettingFormData((prev) => {
                        return {
                          ...prev,
                          [`.video-banner-section${countOfList}`]: {
                            padding: settingFormData?.[
                              [`.video-banner-section${countOfList}`]
                            ]?.["padding"]
                              ?.split(" ")[0]
                              ?.replace(/px/g, ""),
                            "background-color":
                              settingFormData?.[
                                `.video-banner-section${countOfList}`
                              ]?.["background-color"],
                            width: e.target.checked ? "10" : "",
                            margin: e.target.checked ? "0" : "",
                            "border-radius": e.target.checked ? "3" : "",
                          },
                        };
                      });
                    }}
                  />
                </div>
                {boxSection && (
                  <>
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom03">
                        {"Width (Default : 10)"}
                      </Label>
                      <Input
                        className="form-control"
                        name="width"
                        type="number"
                        placeholder="width"
                        value={settingFormData?.[
                          `.video-banner-section${countOfList}`
                        ]?.["width"]?.replace("rem)", "")}
                        onChange={(e) => handleChangeVideoBannerSection(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </Col>
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom03">
                        {"Margin (Default : 0)"}
                      </Label>
                      <Input
                        className="form-control"
                        name="margin"
                        type="number"
                        placeholder="margin"
                        value={settingFormData?.[
                          `.video-banner-section${countOfList}`
                        ]?.["margin"]
                          ?.split(" ")[0]
                          ?.replace(/px/g, "")}
                        onChange={(e) => handleChangeVideoBannerSection(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </Col>
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom03">
                        {"Border Radius (Default : 3)"}
                      </Label>
                      <Input
                        className="form-control"
                        name="border-radius"
                        type="number"
                        placeholder="Border Radius"
                        value={settingFormData?.[
                          `.video-banner-section${countOfList}`
                        ]?.["border-radius"]?.replace(/rem/g, "")}
                        onChange={(e) => handleChangeVideoBannerSection(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </Col>
                  </>
                )}
              </Row>
              <hr />
              <Row className="mt-4">
                <Col md="12 mb-3">
                  <h4 className="mb-3">Add Background Image in section</h4>

                  <p>do you want to upload Background image</p>
                  <Switch
                    checked={backImageToggle}
                    inputProps={{ "aria-label": "controlled" }}
                    onChange={(e) => {
                      setBackImageToggle(e.target.checked);
                      setSettingFormData((prev) => {
                        return {
                          ...prev,
                          image: "",
                          [`.video-banner-section${countOfList}.bg-image`]: {
                            "background-image": "",
                          },
                          [`.video-banner-section${countOfList}.bg-image::before`]:
                            {
                              "background-color": "",
                              // "background-color": "#000000CC",
                            },
                        };
                      });
                    }}
                  />
                  {backImageToggle && (
                    <>
                      {" "}
                      <Col md="6 mb-3">
                        <Dividers divide="IMAGE" />
                        <p className="mb-4">
                          File type required JPG, PNG (1905px X 480px)
                        </p>
                      </Col>
                      <Row>
                        <Col md="4 mb-3">
                          <div
                            className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                            style={{ cursor: "pointer" }}
                          >
                            {!settingFormData?.[
                              `.video-banner-section${countOfList}.bg-image`
                            ]?.["background-image"] && (
                              <span
                                onClick={() =>
                                  OpenModalCommonFunc(
                                    Cases.PRODUCT_UPLOAD,
                                    setAllPopupState
                                  )
                                }
                              >
                                <UploadCloud />
                              </span>
                            )}

                            {settingFormData?.[
                              `.video-banner-section${countOfList}.bg-image`
                            ]?.["background-image"] && (
                              <img
                                src={
                                  settingFormData?.[
                                    `.video-banner-section${countOfList}.bg-image`
                                  ]?.["background-image"] || ""
                                }
                                alt=""
                                style={{
                                  width: "85%",
                                  height: "90px",
                                  objectFit: "contain",
                                  position: "absolute",
                                }}
                                onClick={() => deleteImagePermanent()}
                              />
                            )}
                            {settingFormData?.[
                              `.video-banner-section${countOfList}.bg-image`
                            ]?.["background-image"] && (
                              <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="close"
                                style={{
                                  color: "red",
                                  position: "absolute",
                                  top: "-22px",
                                  left: "1px",
                                }}
                                onClick={() => deleteImagePermanent()}
                              >
                                <Close
                                  style={{
                                    fontSize: "25px",
                                    fontWeight: "bold",
                                  }}
                                />
                              </IconButton>
                            )}
                          </div>
                        </Col>
                        <Col md="6 mb-3">
                          <h4 className="mb-3">
                            Add Background color in section
                          </h4>
                          <Col md="6 mb-3">
                            <CommonColor
                              color={
                                settingFormData?.[
                                  `.video-banner-section${countOfList}.bg-image::before`
                                ]?.["background-color"]
                              }
                              setSettingFormData={setSettingFormData}
                              className={`.video-banner-section${countOfList}.bg-image::before`}
                              classProperty={"background-color"}
                              label={"Select Background Color"}
                            />
                            {/* <Input
                              className="form-control"
                              name="background-color"
                              type="color"
                              placeholder="Background Color"
                              value={
                                settingFormData?.[
                                  ".video-banner-section.bg-image::before"
                                ]?.["background-color"]
                              }
                              onChange={(e) =>
                                handleChangeVideoBannerSectionBgImage(e)
                              }
                              style={{ borderRadius: "0", color: "black" }}
                            /> */}
                          </Col>
                        </Col>
                      </Row>
                    </>
                  )}
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">Make change in video play</h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Height (Default : 80)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="height"
                    type="number"
                    placeholder="Height"
                    value={settingFormData?.[
                      `.video-banner-section${countOfList} .video-play`
                    ]?.["height"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeSectionPlay(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Width (Default : 80)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="width"
                    type="number"
                    placeholder="Width"
                    value={settingFormData?.[
                      `.video-banner-section${countOfList} .video-play`
                    ]?.["width"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeSectionPlay(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        `.video-banner-section${countOfList} .video-play`
                      ]?.["background-color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={`.video-banner-section${countOfList} .video-play`}
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        `.video-banner-section${countOfList} .video-play`
                      ]?.["color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={`.video-banner-section${countOfList} .video-play`}
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Text Align (Default : center)"}
                  </Label>
                  <select
                    name="text-align"
                    id="text-align"
                    className="form-control"
                    value={
                      settingFormData?.[
                        `.video-banner-section${countOfList} .video-play`
                      ]?.["text-align"]
                    }
                    onChange={(e) => handleChangeSectionPlay(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {TextAlign.map((elem) => {
                      return (
                        <option value={elem["text-align"]}>
                          {elem["text-align"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding (Default : 25 - 5)"}
                  </Label>
                  <div className="d-flex justify-content-between">
                    <Input
                      className="form-control"
                      name="p1"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        `.video-banner-section${countOfList} .video-play`
                      ]?.["p1"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangeSectionPlay(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                    <Input
                      className="form-control"
                      name="p2"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        `.video-banner-section${countOfList} .video-play`
                      ]?.["p2"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangeSectionPlay(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                  </div>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Size (Default : 25)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="font-size"
                    type="number"
                    placeholder="Font Size"
                    value={settingFormData?.[
                      `.video-banner-section${countOfList} .video-play`
                    ]?.["font-size"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeSectionPlay(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border Radius (Default : 100)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="border-radius"
                    type="number"
                    placeholder="Border Radius"
                    value={settingFormData?.[
                      `.video-banner-section${countOfList} .video-play`
                    ]?.["border-radius"]?.replace(/%/g, "")}
                    onChange={(e) => handleChangeSectionPlay(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <h4 className="mb-3">Make change in video modal iframe</h4>
                <Col md="6 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Box Shadow (Default : 0 0 8 black)"}
                  </Label>
                  <div className="d-flex">
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="bs1"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[
                          ".videomodal .framediv iframe"
                        ]?.["bs1"]?.replace(/px/g, "")}
                        onChange={(e) => handleChangeVideoModelIframe(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="bs2"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[
                          ".videomodal .framediv iframe"
                        ]?.["bs2"]?.replace(/px/g, "")}
                        onChange={(e) => handleChangeVideoModelIframe(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="bs3"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[
                          ".videomodal .framediv iframe"
                        ]?.["bs3"]?.replace(/px/g, "")}
                        onChange={(e) => handleChangeVideoModelIframe(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div>
                      <CommonColor
                        color={
                          settingFormData?.[".videomodal .framediv iframe"]?.[
                            "shadowColor"
                          ]
                        }
                        setSettingFormData={setSettingFormData}
                        className={".videomodal .framediv iframe"}
                        classProperty={"shadowColor"}
                        label={"Select Color"}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[".videomodal .framediv iframe"]?.[
                        "background-color"
                      ]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".videomodal .framediv iframe"}
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
              </Row>
              <CustomStyle
                handleChange={handleChangeCustomStyle}
                value={settingFormData?.custom}
              />
            </div>
          </Col>
        </Row>
        <ProductImageUploadPopup
          allPopupState={allPopupState}
          setAllPopupState={setAllPopupState}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
          image={settingFormData?.image}
          setImage={setSettingFormData}
          imageUpload={imageUpload}
          isEdit={isEdit}
          reupload={reupload}
          // aspect={3.97 / 1}
        />
      </Container>
    </>
  );
};

export default SettingVideoBannerParentPopup;
