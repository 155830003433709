import axios from "axios";
import {
  GET_POPUP_DIALOG,
  PUT_STATUS_POPUP_DIALOG,
  POST_POPUP_DIALOG,
  GET_POPUP_DIALOG_DETAILS,
  PUT_POPUP_DIALOG_ORDER,
} from "..";
import {
  BYTE_WEB_DELETE_FILE,
  BYTE_WEB_FILE_UPLOAD,
} from "../../../../apiConstants";

/**
 * get popup Dialog  data
 * @param {Parameters} paramObj
 * @returns
 */
export const getPopupDialog = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_POPUP_DIALOG}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * enable-disable packages
 * @param {body} body
 * @returns
 */
export const updateStatus = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_STATUS_POPUP_DIALOG}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * create popup Dialog
 * @param {body} body
 * @returns
 */
export const postPopupDialog = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${POST_POPUP_DIALOG}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * update popup Dialog
 * @param {body} body
 * @returns
 */
export const putUpdatePopupDialog = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${POST_POPUP_DIALOG}`,
    body,
    {
      headers: { Authorization: jwt_token },
      params: { popupDialogId: body.popupDialogId },
    }
  );
};
/**
 * delete popup Dialog
 * @param {id} popupDialogId
 * @returns
 */
export const deletePopupDialog = (popupDialogId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${POST_POPUP_DIALOG}`, {
    headers: { Authorization: jwt_token },
    params: { popupDialogId },
  });
};

/**
 * get popup Dialog
 * @param {id} packageId
 * @returns
 */
export const getPopupDialogDetails = (popupDialogId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${GET_POPUP_DIALOG_DETAILS}`,
    {
      headers: { Authorization: jwt_token },
      params: { popupDialogId },
    }
  );
};

/**
 * short order  popup Dialog
 * @param {body} updateOrder
 * @returns
 */
export const sort_order_PopupDialog = (updateOrder) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_POPUP_DIALOG_ORDER}`,
    { updateOrder: updateOrder },
    {
      headers: { Authorization: jwt_token },
    }
  );
};
