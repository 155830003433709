import { IconButton, Tooltip } from "@material-ui/core";
import React from "react";
import { Trash } from "react-feather";
import { Zoom, toast } from "react-toastify";
import { WebNavBarConfigListDelete } from "../components/MultipageByteCardBuilder/apis/NavApi/NavApi";

const DeleteSection = ({ pageId, ByteMiniWeb_Dashboard }) => {
  const handleDeleteSection = async () => {
    let confirmation = window.confirm("Are you sure you want to delete?");
    if (confirmation) {
      try {
        let paramObj = {
          pageId,
        };
        const { data } = await WebNavBarConfigListDelete(paramObj);
        if (data.status === 200) {
          ByteMiniWeb_Dashboard();
          toast.success(data.message, {
            autoClose: 1000,
            transition: Zoom,
          });
        }
      } catch (error) {
        toast.error(error?.response?.data?.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    }
  };

  return (
    <div>
      <Tooltip title="Delete section">
        <IconButton
          edge="start"
          color="inherit"
          style={{ margin: "5px" }}
          onClick={() => handleDeleteSection()}
        >
          <Trash style={{ color: "red" }} />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default DeleteSection;
