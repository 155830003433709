import React, { useState } from "react";
import TestimonialMultipageContext from "./TestimonialMultipageContext";
import { randomStr } from "../../../utils/helper";

const TestimonialMultipageState = (props) => {
  const [apiData, setApiData] = useState({ data: [], isLoading: false });
  const [apiDataOurTeam, setApiDataOurTeam] = useState({
    isLoading: false,
    data: [],
  });
  const [skill, setSkill] = useState([
    { id: randomStr(2), skill: "", parentageOfSkill: "" },
  ]);
  const [collId, setCollId] = useState();
  const [fromData, setFormData] = useState({
    title: "",
    subTitle: "",
    theme: "1",
    webId: localStorage.getItem("webId"),
    description: "-",
    companyName: "-",
    designation: "-",
    rating: "-",
    isTitleShow: true,
    style: {
      ".testimonial-section": {
        padding: "100px 0",
        "background-color": "rgb(255 255 255 / 100)",
      },
      ".testimonial-section.bg-image": {},
      ".testimonial-section.bg-image::before": {
        "background-color": "",
        // "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
      },
      ".testimonial-section .section-title-area": {
        "margin-bottom": "50px",
      },
      ".testimonial-section .section-title-area .section-subtitle": {
        "font-size": "16px",
        "font-weight": "600",
        color: "rgb(255 73 124 / 100)",
        "background-color": "rgb(255 73 124 / 0.2)",
        padding: "5px 20px",
        p1: "5px",
        p2: "20px",
        "border-radius": "15px",
        "margin-bottom": "15px",
      },
      ".testimonial-section .section-title-area .section-title": {
        "font-size": "44px",
        "font-weight": "600",
        color: "rgb(0 0 0 / 100)",
      },
      ".testimonial-slider .slick-arrow::before": {
        color: "rgb(255 73 124 / 100)",
      },
      ".testimonial-slider .testimonial-content": {
        "box-shadow": "0px 0px 20px 0px rgb(0 0 0 / 0.051)",
        bs1: "0px",
        bs2: "0px",
        bs3: "20px",
        bs4: "0px",
        shadowColor: "rgb(0 0 0 / 0.051)",
        "background-color": "rgb(255 255 255 / 100)",
        margin: "15px",
        padding: "80px 50px 40px 50px",
        p1: "80px",
        p2: "50px",
        p3: "40px",
        p4: "50px",
        "border-radius": "10px",
        "text-align": "center",
      },
      ".testimonial-slider .testimonial-content:before": {
        "font-family": "FontAwesome",
        color: "rgb(255 73 124 / 100)",
        "font-size": "40px",
        opacity: "0.6",
        top: "10px",
      },
      ".testimonial-slider .testimonial-content .avatar-img": {
        width: "110px",
        height: "110px",
        "border-radius": "100%",
        border: "5px solid rgb(255 73 124 / 100)",
        borderPx: "5px",
        borderThik: "solid",
        borderColor: "rgb(255 73 124 / 100)",
      },
      ".testimonial-slider .testimonial-content .comments": {
        color: "rgb(129 136 153 / 100)",
        "font-size": "16px",
        "font-weight": "400",
        "line-height": "1.6",
        margin: "20px 0",
      },
      ".testimonial-slider .testimonial-content .client-name": {
        color: "rgb(31 39 50 / 100)",
        "font-size": "22px",
        "font-weight": "600",
        "margin-bottom": "10px",
      },
      ".testimonial-slider .testimonial-content .client-position": {
        color: "rgb(129 136 153 / 100)",
        "font-size": "16px",
        "font-weight": "400",
        "margin-bottom": "12px",
      },
      ".testimonial-slider .testimonial-content .star-rating": {
        "text-align": "center",
      },
      ".testimonial-slider .testimonial-content .star-rating i": {
        color: "rgb(255 73 124 / 100)",
        "font-size": "20px",
      },
      ".testimonial-section .more-btn": {
        color: "rgb(255 255 255 / 100)",
        "font-size": "16px",
        "font-weight": "normal",
        "border-radius": "15px",
        padding: "5px 20px",
        p1: "5px",
        p2: "20px",
        "background-color": "rgb(255 73 124 / 100)",
      },
      ".testimonial-section .more-btn:hover": {
        "background-color": "rgb(255 73 124 / 100)",
        color: "rgb(255 255 255 / 100)",
      },
      ".pagination-positions": {
        "justify-content": "center",
      },
      ".pagination .page-link": {
        color: "rgb(255 73 124 / 100)",
      },
      ".pagination .page-item.active .page-link": {
        "background-color": "rgb(255 73 124 / 100)",
        "border-color": "rgb(255 73 124 / 100)",
      },
      ".testimonial-slider .slick-dots li button:before": {
        "font-size": "12px",
        color: "rgb(255 73 124 / 100)",
        opacity: "0.25",
      },
      ".testimonial-slider .slick-dots li.slick-active button:before": {
        color: "rgb(255 73 124 / 100)",
      },
      testimonialId: "",
    },
  });

  const [formDataChild, setFormDataChild] = useState({
    title: "",
    subTitle: "",
    rating: "",
    description: "",
    designation: "",
    isTitleShow: true,
    companyName: "",
    image: "",
    order: 1,
    webId: localStorage.getItem("webId"),
  });

  const [settingFormData, setSettingFormData] = useState({
    ".testimonial-section": {
      padding: "100px 0",
      "background-color": "rgb(255 255 255 / 100)",
    },
    ".testimonial-section.bg-image": {},
    ".testimonial-section.bg-image::before": {
      "background-color": "",
      // "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
    },
    ".testimonial-section .section-title-area": {
      "margin-bottom": "50px",
    },
    ".testimonial-section .section-title-area .section-subtitle": {
      "font-size": "16px",
      "font-weight": "600",
      color: "rgb(255 73 124 / 100)",
      "background-color": "rgb(255 73 124 / 0.2)",
      padding: "5px 20px",
      p1: "5px",
      p2: "20px",
      "border-radius": "15px",
      "margin-bottom": "15px",
    },
    ".testimonial-section .section-title-area .section-title": {
      "font-size": "44px",
      "font-weight": "600",
      color: "rgb(0 0 0 / 100)",
    },
    ".testimonial-slider .slick-arrow::before": {
      color: "rgb(255 73 124 / 100)",
    },
    ".testimonial-slider .testimonial-content": {
      "box-shadow": "0px 0px 20px 0px rgb(0 0 0 / 0.051)",
      bs1: "0px",
      bs2: "0px",
      bs3: "20px",
      bs4: "0px",
      shadowColor: "rgb(0 0 0 / 0.051)",
      "background-color": "rgb(255 255 255 / 100)",
      margin: "15px",
      padding: "80px 50px 40px 50px",
      p1: "80px",
      p2: "50px",
      p3: "40px",
      p4: "50px",
      "border-radius": "10px",
      "text-align": "center",
    },
    ".testimonial-slider .testimonial-content:before": {
      "font-family": "FontAwesome",
      color: "rgb(255 73 124 / 100)",
      "font-size": "40px",
      opacity: "0.6",
      top: "10px",
    },
    ".testimonial-slider .testimonial-content .avatar-img": {
      width: "110px",
      height: "110px",
      "border-radius": "100%",
      border: "5px solid rgb(255 73 124 / 100)",
      borderPx: "5px",
      borderThik: "solid",
      borderColor: "rgb(255 73 124 / 100)",
    },
    ".testimonial-slider .testimonial-content .comments": {
      color: "rgb(129 136 153 / 100)",
      "font-size": "16px",
      "font-weight": "400",
      "line-height": "1.6",
      margin: "20px 0",
    },
    ".testimonial-slider .testimonial-content .client-name": {
      color: "rgb(31 39 50 / 100)",
      "font-size": "22px",
      "font-weight": "600",
      "margin-bottom": "10px",
    },
    ".testimonial-slider .testimonial-content .client-position": {
      color: "rgb(129 136 153 / 100)",
      "font-size": "16px",
      "font-weight": "400",
      "margin-bottom": "12px",
    },
    ".testimonial-slider .testimonial-content .star-rating": {
      "text-align": "center",
    },
    ".testimonial-slider .testimonial-content .star-rating i": {
      color: "rgb(255 73 124 / 100)",
      "font-size": "20px",
    },
    ".testimonial-section .more-btn": {
      color: "rgb(255 255 255 / 100)",
      "font-size": "16px",
      "font-weight": "normal",
      "border-radius": "15px",
      padding: "5px 20px",
      p1: "5px",
      p2: "20px",
      "background-color": "rgb(255 73 124 / 100)",
    },
    ".testimonial-section .more-btn:hover": {
      "background-color": "rgb(255 73 124 / 100)",
      color: "rgb(255 255 255 / 100)",
    },
    ".pagination-positions": {
      "justify-content": "center",
    },
    ".pagination .page-link": {
      color: "rgb(255 73 124 / 100)",
    },
    ".pagination .page-item.active .page-link": {
      "background-color": "rgb(255 73 124 / 100)",
      "border-color": "rgb(255 73 124 / 100)",
    },
    ".testimonial-slider .slick-dots li button:before": {
      "font-size": "12px",
      color: "rgb(255 73 124 / 100)",
      opacity: "0.25",
    },
    ".testimonial-slider .slick-dots li.slick-active button:before": {
      color: "rgb(255 73 124 / 100)",
    },
    testimonialId: "",
  });
  const [imageIs, setImageIs] = useState("");
  const [theme, setTheme] = useState("");
  const [boxSection, setBoxSection] = useState(false);
  const [id, setId] = useState("");
  const [childId, setChildId] = useState("");
  const [navMapId, setNavMapId] = useState("");
  const [galleryImgId, setGalleryImgId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isParent, setIsParent] = useState(false);
  const [filter, setFilter] = useState("");
  const [backImageToggle, setBackImageToggle] = useState(false);
  const [isBackgroundColor, setIsBackgroundColor] = useState(false);
  const [imgShow, setImgShow] = useState(false);
  const [sectionPageName, setSectionPageName] = useState("");
  return (
    <TestimonialMultipageContext.Provider
      value={{
        apiData,
        setApiData,
        apiDataOurTeam,
        setApiDataOurTeam,
        fromData,
        setFormData,
        id,
        setId,
        isEdit,
        setIsEdit,
        filter,
        setFilter,
        skill,
        galleryImgId,
        setGalleryImgId,
        setSkill,
        settingFormData,
        setSettingFormData,
        formDataChild,
        setFormDataChild,
        isParent,
        setIsParent,
        childId,
        setChildId,
        navMapId,
        setNavMapId,
        backImageToggle,
        setBackImageToggle,
        boxSection,
        setBoxSection,
        collId,
        setCollId,
        isBackgroundColor,
        setIsBackgroundColor,
        theme,
        setTheme,
        imageIs,
        setImageIs,
        imgShow,
        setImgShow,
        sectionPageName,
        setSectionPageName,
      }}
    >
      {props.children}
    </TestimonialMultipageContext.Provider>
  );
};

export default TestimonialMultipageState;
