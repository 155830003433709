import React, { useState } from "react";
import PageTitleContext from "./PageTitleContext";

const PageTitleState = (props) => {
  const [apiData, setApiData] = useState({ data: [], isLoading: false });
  const [apiDataOurTeam, setApiDataOurTeam] = useState({
    isLoading: false,
    data: [],
  });
  const [isBackgroundColor, setIsBackgroundColor] = useState(false);
  const [collId, setCollId] = useState();
  const [boxSection, setBoxSection] = useState(false);
  const [formDataOurTeam, setFormDataOurData] = useState({
    title: "",
    subTitle: "",
    theme: 1,
    navMapId: "",
    webId: localStorage.getItem("webId"),
    style: {
      ".page-head": {
        padding: "100px 0",
        "clip-path": "polygon(0 0, 100% 0%, 100% 80%, 50% 100%, 0 80%)",
        cp: "100%",
        cp11: "100%",
        cp12: "80%,",
        cp21: "50%",
        cp22: "100%,",
        cp31: "80%)",
        "background-color": "#000000",
      },
      ".page-head.bg-image": {
        "background-image": "",
      },
      ".page-head.bg-image::before": {
        "background-color": "",
      },
      ".page-head .page-title": {
        color: "#ffffff",
        "font-size": "40px",
        "font-weight": 500,
        "margin-bottom": "20px",
      },
      ".page-head .breadcrumb .breadcrumb-item a": {
        "font-size": "15px",
        "font-weight": 600,
        color: "#ff497c",
      },
      ".page-head .breadcrumb .breadcrumb-item.active": {
        "font-size": "15px",
        "font-weight": 600,
        color: "#ffffff",
      },
      pageTitleId: "",
    },
  });
  const [settingFormData, setSettingFormData] = useState({
    ".page-head": {
      padding: "100px 0",
      "clip-path": "polygon(0 0, 100% 0%, 100% 80%, 50% 100%, 0 80%)",
      cp: "100%",
      cp11: "100%",
      cp12: "80%,",
      cp21: "50%",
      cp22: "100%,",
      cp31: "80%)",
      "background-color": "#000000",
    },
    ".page-head.bg-image": {
      "background-image": "",
    },
    ".page-head.bg-image::before": {
      "background-color": "",
    },
    ".page-head .page-title": {
      color: "#ffffff",
      "font-size": "40px",
      "font-weight": 500,
      "margin-bottom": "20px",
    },
    ".page-head .breadcrumb .breadcrumb-item a": {
      "font-size": "15px",
      "font-weight": 600,
      color: "#ff497c",
    },
    ".page-head .breadcrumb .breadcrumb-item.active": {
      "font-size": "15px",
      "font-weight": 600,
      color: "#ffffff",
    },
    pageTitleId: "",
  });
  const [backImageToggle, setBackImageToggle] = useState(false);
  const [id, setId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [filter, setFilter] = useState("");
  const [theme, setTheme] = useState(false);
  const [imgShow, setImgShow] = useState(false);
  const [sectionPageName, setSectionPageName] = useState("");
  return (
    <PageTitleContext.Provider
      value={{
        apiData,
        setApiData,
        apiDataOurTeam,
        setApiDataOurTeam,
        formDataOurTeam,
        setFormDataOurData,
        id,
        setId,
        isEdit,
        setIsEdit,
        filter,
        setFilter,
        settingFormData,
        setSettingFormData,
        backImageToggle,
        setBackImageToggle,
        collId,
        setCollId,
        boxSection,
        setBoxSection,
        isBackgroundColor,
        setIsBackgroundColor,
        theme,
        setTheme,
        imgShow,
        setImgShow,
        sectionPageName,
        setSectionPageName,
      }}
    >
      {props.children}
    </PageTitleContext.Provider>
  );
};

export default PageTitleState;
