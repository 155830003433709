import React, { useContext, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Cases, DELETE_CONFIRM, modalsName } from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import CommonPopup from "../../../Modals/commonPopup";
import SeoDetailsPopup from "./seoDetailsPopup";
import {
  deleteSeoDetails,
  getSeoDetailsDetails,
  postSeoDetails,
  putUpdateSeoDetails,
  updateStatus,
} from "../apis/SeoDetailsApi/SeoDetailsApi";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import SimpleReactValidator from "simple-react-validator";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import SeoDetailsMultipageContext from "../../../context/SeoDetailsMultipageContext/SeoDetailsMultipageContext";
import { FILE_TYPE } from "../../../../constant";
import { FILE_UPLOAD_API } from "../apis/SliderApi/SliderApi";
import { Trash } from "react-feather";
import SocialMediaMultipageContext from "../../../context/SocialMediaMultipageContext/SocialMediaMultipageContext";
import DeleteSection from "../../../common/DeleteSection";
import SettingMultiWebContext from "../../../../context/SettingMultiWebContext/SettingMultiWebContext";

const SeoDetails = (props) => {
  const {
    ByteMiniWeb_Dashboard,
    seoDetailsDetail,
    contentMapId,
    pageConfigDetails,
    contentDetail,
  } = props;

  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );
  const { setCloneAlignModal, setAlignFormData, setCloneFormData } = useContext(
    SettingMultiWebContext
  );
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const {
    fromData,
    setFormData,
    id,
    setId,
    setIsEdit,
    isEdit,
    isSectionImage,
    setIsSectionImage,
    customeIndex,
  } = useContext(SeoDetailsMultipageContext);
  const { setIsBackgroundColor } = useContext(SocialMediaMultipageContext);
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * create and update seo details
   */
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      if (id >= -1 && isEdit && id !== "" && id !== null) {
        try {
          setLoaderShow(true);
          let formDataValue = {
            ...fromData,
            contentMapId,
          };
          delete formDataValue.image;
          delete formDataValue.imageThumbnail;
          const { data } = await putUpdateSeoDetails(formDataValue);
          if (data.status === 200) {
            emptyData();
            TostMessage({ message: data, type: "success" });
            OpenModalCommonFunc(Cases.SEO, setAllPopupState, false);
            ByteMiniWeb_Dashboard();
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      } else {
        try {
          setLoaderShow(true);
          let formDataValue = {
            ...fromData,
            contentMapId,
          };
          delete formDataValue.image;
          delete formDataValue.imageThumbnail;
          const { data } = await postSeoDetails(formDataValue);
          if (data.status === 200) {
            emptyData();
            TostMessage({ message: data, type: "success" });
            OpenModalCommonFunc(Cases.SEO, setAllPopupState, false);
            ByteMiniWeb_Dashboard();
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      }
    }
  };

  /**
   * seo details enable disable api call
   * @param {id} id
   * @param {status} isActive
   */

  const handleChangeSeoDetails = async (id, isActive) => {
    try {
      let body = {
        id,
        isActive: isActive ? false : true,
      };
      const { data } = await updateStatus(body);
      if (data.status === 200) {
        ByteMiniWeb_Dashboard();
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * get data item feature
   * @param {id} id
   */
  const handleEditSeoDetails = async (id, isParent) => {
    try {
      setLoaderShow(true);
      setId(id);
      const { data } = await getSeoDetailsDetails(id);
      if (data.status === 200) {
        let payload = data.payload.data;
        setFormData({
          name: payload?.name || "",
          description: payload?.description || "",
          headerSection: payload?.headerSection || {
            favicon: "",
            keywords: "",
            author: "",
            ogTags: {
              title: "",
              url: "",
              description: "",
              image: "",
              type: "website",
              siteName: "",
            },
            canonical: "",
            Bing: "",
            googleSiteVerification: "",
            webSiteSchema: "",
            breadCrumbSchema: "",
            facebookPixel: "",
          },
          body: payload?.body || {
            googleTagManage: "",
          },
          navMapId: payload?.navMapId || "",
          id: payload?.id || "",
        });
        OpenModalCommonFunc(Cases.SEO, setAllPopupState);
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * file upload
   * @param {file} file
   */
  const imageUploadImages = async (file) => {
    setLoaderShow(true);
    let formData = new FormData();
    formData.append("type", FILE_TYPE.SEO);
    formData.append("file", file);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        const image = data.payload.baseUrl + data.payload.imageUrl;
        if (isSectionImage) {
          setFormData((prev) => {
            return {
              ...prev,
              image: "",
              headerSection: {
                ...prev.headerSection,
                favicon: image,
                ogTags: {
                  ...prev.headerSection.ogTags,
                  image: image,
                },
              },
            };
          });
        } else if (isSectionImage === false) {
          setFormData((prev) => {
            return {
              ...prev,
              image: "",
              footer: {
                ...prev.footer,
                image: image,
              },
            };
          });
        } else if (isSectionImage === null) {
          setFormData((prev) => {
            let social = [...prev.footer.socialMedia];
            social[customeIndex]["image"] = image;
            return {
              ...prev,
              image: "",
              footer: {
                ...prev.footer,
                socialMedia: social,
              },
            };
          });
        }
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * delete seo details
   * @param {id} id
   */
  const handleDelete = async (id) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await deleteSeoDetails(id);
        if (data.status === 200) {
          ByteMiniWeb_Dashboard();
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * empty data
   */
  const emptyData = () => {
    setIsBackgroundColor(false);
    setId("");
    setIsSectionImage(false);
    setIsEdit(false);
    setFormData({
      name: "",
      description: "",
      image: "",
      headerSection: {
        favicon: "",
        keywords: "",
        author: "",
        ogTags: {
          title: "",
          url: "",
          description: "",
          image: "",
          type: "website",
          siteName: "",
        },
        canonical: "",
        Bing: "",
        googleSiteVerification: "",
        webSiteSchema: "",
        breadCrumbSchema: "",
        facebookPixel: "",
      },
      body: {
        googleTagManage: "",
      },

      navMapId: "",
      webId: localStorage.getItem("webId"),
    });
    setLoaderShow(false);
  };

  const handleAlignAndCloneSection = ({ isClone = false }) => {
    if (isClone) {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          cloneModal: true,
        };
      });
      setCloneFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          pageConfigDetailsLabel: pageConfigDetails[0]?.label,
          contentDetailName: contentDetail.name,
        };
      });
    } else {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          alignModal: true,
        };
      });
      setAlignFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          label: pageConfigDetails[0]?.label,
        };
      });
    }
  };
  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody className="support-ticket-font box-border-div">
            <Row>
              <CardBuilderHeaderSection
                addBtnTitle={pageConfigDetails[0]?.label}
                name={pageConfigDetails[0]?.label}
                isAdd={!seoDetailsDetail && true}
                mainEditSection={true}
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
                openModal={() => {
                  OpenModalCommonFunc(Cases.SEO, setAllPopupState);
                  emptyData();
                }}
                isCloneSection={true}
                isAlignSection={true}
                handleAlignAndCloneSection={handleAlignAndCloneSection}
              />
              {seoDetailsDetail !== null && seoDetailsDetail !== undefined && (
                <Col xl="12 xl-50 box-col-6" md="6">
                  <Card className="ecommerce-widget ">
                    <CardBody className="support-ticket-font">
                      <div className="col-12">
                        <CardBuilderHeaderSection
                          name={seoDetailsDetail?.name}
                          checked={seoDetailsDetail?.isActive}
                          switchToggle={true}
                          id={seoDetailsDetail?.id}
                          handleChange={handleChangeSeoDetails}
                        />
                      </div>
                      <div className="d-flex justify-content-end ">
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() =>
                            handleEditSeoDetails(seoDetailsDetail.id, true)
                          }
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => handleDelete(seoDetailsDetail.id)}
                        >
                          <Trash />
                        </IconButton>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
              <DeleteSection
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
              />
            </Row>
          </CardBody>
        </Card>
      </Col>
      <CommonPopup
        open={allPopupState.seoPopup}
        close={() => OpenModalCommonFunc(Cases.SEO, setAllPopupState, false)}
        modalName={modalsName.SEO_DETAILS}
        modalCase={Cases.SEO}
        handleSubmit={handleSubmit}
        emptyData={emptyData}
        inputData={fromData}
        edit={false}
        content={
          <SeoDetailsPopup
            simpleValidator={simpleValidator}
            imageUpload={imageUploadImages}
          />
        }
      />
    </>
  );
};

export default SeoDetails;
