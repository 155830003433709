import { rgbColorCreate } from "../../../../utils/helper";
export const ProductBuyStyleOne = {
  ".ecom-products-section .product-catlist": {
    "text-align": "center",
    "margin-bottom": "30px",
  },
  ".ecom-products-section .product-catlist .btn": {
    border: "2px solid { r: 255, g: 73, b: 124, a: 100 }",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 73, b: 124, a: 100 },
    padding: "6px 20px",
    p1: "6px",
    p2: "20px",
    margin: "5px",
  },
  ".ecom-products-section .product-catlist .active": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".ecom-products-section .product-catdata .box": {
    "margin-bottom": "15px",
    outline: "none",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
    "box-shadow": "0 5px 10px 0 { r: 7, g: 28, b: 31, a: 0.098 }",
    bs1: "0px",
    bs2: "5px",
    bs3: "10px",
    bs4: "0px",
    shedowColor: { r: 7, g: 28, b: 31, a: 0.098 },
    "border-radius": "0px",
  },
  ".ecom-products-section .product-catdata .product-img .taglabel": {
    "background-color": { r: 0, g: 138, b: 73, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "14px",
    "font-weight": "400",
    padding: "8px 15px",
    p1: "8px",
    p2: "15px",
    "border-radius": "5px",
    top: "10px",
    left: "10px",
  },
  ".ecom-products-section .product-catdata .product-img .post-date": {
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
    "border-radius": "5px",
    color: { r: 255, g: 73, b: 124, a: 100 },
    "font-size": "14px",
    "font-weight": "400",
    padding: "8px 15px",
    p1: "8px",
    p2: "15px",
    "text-align": "center",
    top: "10px",
    right: "10px",
  },
  ".ecom-products-section .product-catdata .product-content": {
    padding: "20px",
    float: "left",
    width: "100%",
    "text-align": "center",
  },
  ".ecom-products-section .product-catdata .product-content .shop a": {
    color: { r: 0, g: 0, b: 0, a: 100 },
    "font-size": "20px",
  },
  ".ecom-products-section .product-catdata .product-content .shop a:hover": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".ecom-products-section .product-catdata .product-content .price": {
    color: { r: 51, g: 51, b: 51, a: 100 },
    margin: "15px 5px 10px 5px",
    m1: "15px",
    m2: "5px",
    m3: "10px",
    m4: "5px",
  },
  ".ecom-products-section .product-catdata .product-content .price del": {
    color: { r: 153, g: 153, b: 153, a: 100 },
    "margin-right": "15px",
  },
  ".ecom-products-section .product-catdata .product-content .price ins": {
    "text-decoration": "none",
    color: { r: 0, g: 0, b: 0, a: 100 },
  },
  ".ecom-products-section .product-catdata .product-content .add-cart": {
    border: "2px solid { r: 255, g: 73, b: 124, a: 100 }",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: { r: 255, g: 73, b: 124, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "14px",
    "font-weight": "500",
    padding: "4px 15px",
    p1: "4px",
    p2: "15px",
  },
  ".ecom-products-section .product-catdata .product-content .add-cart:hover": {
    opacity: "0.8",
  },
  ".shop-section": {
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".shop-section .filter-switch": {
    "border-bottom": "1px solid { r: 215, g: 226, b: 230, a: 100 }",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: { r: 215, g: 226, b: 230, a: 100 },
  },
  ".shop-section .filter-switch .switch-text": {
    color: { r: 0, g: 0, b: 0, a: 100 },
    "font-size": "16px",
    "font-weight": "600",
  },
  ".shop-section .filter-switch .switch-toggle": {
    "background-color": { r: 230, g: 230, b: 230, a: 100 },
    "border-radius": "13px",
  },
  ".shop-section .filter-switch .switch-toggle.opened": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".ecom-products-section .more-btn": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".ecom-products-section .more-btn:hover": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
};
export const ProducPageStyle = {
  ".single-product-section": {
    padding: "50px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".single-product-section .subbox-div": {
    "background-color": { r: 249, g: 249, b: 249, a: 100 },
    padding: "2em",
  },
  ".single-product-section .subbox-div .taglabel": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "14px",
    "font-weight": "400",
    padding: "6px 20px",
    p1: "6px",
    p2: "20px",
    "border-radius": "50px",
  },
  ".single-product-section .preview-thumbnail": {
    margin: "10px 0",
  },
  ".single-product-section .preview-thumbnail li .active": {
    border: "2px solid { r: 255, g: 73, b: 124, a: 100 }",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".single-product-section .single-proinfo .product-title": {
    "margin-bottom": "10px",
  },
  ".single-product-section .single-proinfo .product-description": {
    "margin-bottom": "20px",
  },
  ".single-product-section .single-proinfo .price": {
    color: { r: 51, g: 51, b: 51, a: 100 },
    "margin-bottom": "20px",
  },
  ".single-product-section .single-proinfo .add-to-cart": {
    border: "2px solid { r: 255, g: 73, b: 124, a: 100 }",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: { r: 255, g: 73, b: 124, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
    padding: "4px 15px",
    p1: "4px",
    p2: "15px",
  },
  ".single-product-section .single-proinfo .add-inquiry": {
    border: "2px solid { r: 255, g: 73, b: 124, a: 100 }",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: { r: 255, g: 73, b: 124, a: 100 },
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
    color: { r: 255, g: 73, b: 124, a: 100 },
    padding: "4px 15px",
    p1: "4px",
    p2: "15px",
  },
  ".single-product-section .product-info-tab .nav-tabs .active": {
    "background-color": { r: 245, g: 245, b: 245, a: 100 },
    "border-color": { r: 222, g: 226, b: 230, a: 100 },
    color: { r: 73, g: 80, b: 87, a: 100 },
  },
  ".single-product-section .product-info-tab .tab-content": {
    "background-color": { r: 245, g: 245, b: 245, a: 100 },
    padding: "15px",
    color: { r: 73, g: 80, b: 87, a: 100 },
  },
};
export const cartPageStyle = {
  ".sidebar-panel .sidebar-top": {
    "background-color": { r: 31, g: 39, b: 50, a: 100 },
    padding: "15px",
  },
  ".sidebar-panel": {
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".sidebar-panel .sidebar-midd .product-list": {
    padding: "12px",
    "border-bottom": "1px solid rgba(238, 238, 238, 1)",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: { r: 238, g: 238, b: 238, a: 100 },
  },
  ".sidebar-panel .sidebar-midd .product-list .product-img": {
    // width: "90px",
    border: "1px solid rgba(238, 238, 238, 1)",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: { r: 238, g: 238, b: 238, a: 100 },
    "border-radius": "15px",
    padding: "5px",
  },
  ".sidebar-panel .sidebar-midd .product-list .name": {
    color: { r: 0, g: 0, b: 0, a: 100 },
    "font-size": "16px",
    "font-weight": "600",
  },
  ".sidebar-panel .sidebar-bottom": {
    "background-color": { r: 31, g: 39, b: 50, a: 100 },
  },
  ".sidebar-panel .sidebar-bottom .count": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
  },
  ".sidebar-panel .sidebar-bottom .checkout-btn": {
    "background-color": { r: 59, g: 183, b: 126, a: 100 },
    padding: "8px 10px",
    p1: "8px",
    p2: "10px",
    "border-radius": "5px",
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "500",
  },
};
export const addProductStyleOne = (settingFormData) => {
  return {
    ".ecom-products-section .product-catlist": {
      ...settingFormData?.[".ecom-products-section .product-catlist"],
      "text-align": `${settingFormData?.[".ecom-products-section .product-catlist"]?.["text-align"]}`,
      "margin-bottom": `${settingFormData?.[
        ".ecom-products-section .product-catlist"
      ]["margin-bottom"]?.replace("px", "")}px`,
    },
    ".ecom-products-section .product-catlist .btn": {
      ...settingFormData?.[".ecom-products-section .product-catlist .btn"],
      border: `${settingFormData?.[
        ".ecom-products-section .product-catlist .btn"
      ]["borderPx"]?.replace("px", "")}px ${
        settingFormData?.[".ecom-products-section .product-catlist .btn"]?.[
          "borderThik"
        ]
      } ${rgbColorCreate({
        rgbString:
          settingFormData?.[".ecom-products-section .product-catlist .btn"]?.[
            "borderColor"
          ],
      })}`,
      color: settingFormData[".ecom-products-section .product-catlist .btn"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".ecom-products-section .product-catlist .btn"]?.[
                "color"
              ],
          })
        : "",
      padding: `${settingFormData?.[
        ".ecom-products-section .product-catlist .btn"
      ]["p1"]?.replace("px", "")}px ${settingFormData[
        ".ecom-products-section .product-catlist .btn"
      ]?.["p2"]?.replace("px", "")}px`,
      margin: `${settingFormData?.[
        ".ecom-products-section .product-catlist .btn"
      ]["margin"]?.replace("px", "")}px`,
      "box-shadow": `${settingFormData?.[".ecom-products-section .product-catlist .btn"]?.["box-shadow"]}`,
    },
    ".ecom-products-section .product-catlist .active": {
      ...settingFormData?.[".ecom-products-section .product-catlist .active"],
      color: settingFormData[
        ".ecom-products-section .product-catlist .active"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".ecom-products-section .product-catlist .active"
              ]?.["color"],
          })
        : "",
      "background-color": settingFormData[
        ".ecom-products-section .product-catlist .active"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".ecom-products-section .product-catlist .active"
              ]?.["background-color"],
          })
        : "",
    },
    ".ecom-products-section .product-catdata .box": {
      ...settingFormData?.[".ecom-products-section .product-catdata .box"],
      "margin-bottom": `${settingFormData[
        ".ecom-products-section .product-catdata .box"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
      outline: `${settingFormData?.[".ecom-products-section .product-catdata .box"]?.["outline"]}`,
      "background-color": settingFormData[
        ".ecom-products-section .product-catdata .box"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".ecom-products-section .product-catdata .box"]?.[
                "background-color"
              ],
          })
        : "",
      "box-shadow": `${settingFormData[
        ".ecom-products-section .product-catdata .box"
      ]?.["bs1"]?.replace("px", "")}px ${settingFormData[
        ".ecom-products-section .product-catdata .box"
      ]?.["bs2"]?.replace("px", "")}px ${settingFormData[
        ".ecom-products-section .product-catdata .box"
      ]?.["bs3"]?.replace("px", "")}px ${settingFormData[
        ".ecom-products-section .product-catdata .box"
      ]?.["bs4"]?.replace("px", "")}px ${rgbColorCreate({
        rgbString:
          settingFormData[".ecom-products-section .product-catdata .box"]?.[
            "shedowColor"
          ],
      })}`,
      "border-radius": `${settingFormData[
        ".ecom-products-section .product-catdata .box"
      ]?.["border-radius"]?.replace("px", "")}px`,
      overflow: "hidden",
    },
    ".ecom-products-section .product-catdata .product-img .taglabel": {
      ...settingFormData[
        ".ecom-products-section .product-catdata .product-img .taglabel"
      ],
      "background-color": settingFormData[
        ".ecom-products-section .product-catdata .product-img .taglabel"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".ecom-products-section .product-catdata .product-img .taglabel"
              ]?.["background-color"],
          })
        : "",
      color: settingFormData[
        ".ecom-products-section .product-catdata .product-img .taglabel"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".ecom-products-section .product-catdata .product-img .taglabel"
              ]?.["color"],
          })
        : "",
      "font-size": `${settingFormData[
        ".ecom-products-section .product-catdata .product-img .taglabel"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".ecom-products-section .product-catdata .product-img .taglabel"]?.["font-weight"]}`,
      padding: `${settingFormData[
        ".ecom-products-section .product-catdata .product-img .taglabel"
      ]?.["p1"]?.replace("px", "")}px ${settingFormData[
        ".ecom-products-section .product-catdata .product-img .taglabel"
      ]?.["p2"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData[
        ".ecom-products-section .product-catdata .product-img .taglabel"
      ]?.["border-radius"]?.replace("px", "")}px`,
      top: `${settingFormData[
        ".ecom-products-section .product-catdata .product-img .taglabel"
      ]?.["top"]?.replace("px", "")}px`,
      left: `${settingFormData[
        ".ecom-products-section .product-catdata .product-img .taglabel"
      ]?.["left"]?.replace("px", "")}px`,
    },
    ".ecom-products-section .product-catdata .product-img .post-date": {
      ...settingFormData[
        ".ecom-products-section .product-catdata .product-img .post-date"
      ],
      "background-color": settingFormData[
        ".ecom-products-section .product-catdata .product-img .post-date"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".ecom-products-section .product-catdata .product-img .post-date"
              ]?.["background-color"],
          })
        : "",
      color: settingFormData[
        ".ecom-products-section .product-catdata .product-img .post-date"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".ecom-products-section .product-catdata .product-img .post-date"
              ]?.["color"],
          })
        : "",
      "font-size": `${settingFormData[
        ".ecom-products-section .product-catdata .product-img .post-date"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".ecom-products-section .product-catdata .product-img .post-date"]?.["font-weight"]}`,
      padding: `${settingFormData[
        ".ecom-products-section .product-catdata .product-img .post-date"
      ]?.["p1"]?.replace("px", "")}px ${settingFormData[
        ".ecom-products-section .product-catdata .product-img .post-date"
      ]?.["p2"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData[
        ".ecom-products-section .product-catdata .product-img .post-date"
      ]?.["border-radius"]?.replace("px", "")}px`,
      top: `${settingFormData[
        ".ecom-products-section .product-catdata .product-img .post-date"
      ]?.["top"]?.replace("px", "")}px`,
      right: `${settingFormData[
        ".ecom-products-section .product-catdata .product-img .post-date"
      ]?.["right"]?.replace("px", "")}px`,
    },
    ".ecom-products-section .product-catdata .product-content": {
      ...settingFormData?.[
        ".ecom-products-section .product-catdata .product-content"
      ],
      padding: `${settingFormData[
        ".ecom-products-section .product-catdata .product-content"
      ]?.["padding"]?.replace("px", "")}px`,
      float: `${settingFormData?.[".ecom-products-section .product-catdata .product-content"]?.["float"]}`,
      width: `${settingFormData[
        ".ecom-products-section .product-catdata .product-content"
      ]?.["width"]?.replace("%", "")}%`,
      "text-align": `${settingFormData?.[".ecom-products-section .product-catdata .product-content"]?.["text-align"]}`,
    },
    ".ecom-products-section .product-catdata .product-content .shop a": {
      ...settingFormData[
        ".ecom-products-section .product-catdata .product-content .shop a"
      ],
      color: settingFormData[
        ".ecom-products-section .product-catdata .product-content .shop a"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".ecom-products-section .product-catdata .product-content .shop a"
              ]?.["color"],
          })
        : "",
      "font-size": `${settingFormData[
        ".ecom-products-section .product-catdata .product-content .shop a"
      ]?.["font-size"]?.replace("px", "")}px`,
    },
    ".ecom-products-section .product-catdata .product-content .shop a:hover": {
      color: settingFormData[
        ".ecom-products-section .product-catdata .product-content .shop a:hover"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".ecom-products-section .product-catdata .product-content .shop a:hover"
              ]?.["color"],
          })
        : "",
    },
    ".ecom-products-section .product-catdata .product-content .price": {
      ...settingFormData[
        ".ecom-products-section .product-catdata .product-content .price"
      ],
      color: settingFormData[
        ".ecom-products-section .product-catdata .product-content .price"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".ecom-products-section .product-catdata .product-content .price"
              ]?.["color"],
          })
        : "",
      margin: `${settingFormData[
        ".ecom-products-section .product-catdata .product-content .price"
      ]?.["m1"]?.replace("px", "")}px ${settingFormData[
        ".ecom-products-section .product-catdata .product-content .price"
      ]?.["m2"]?.replace("px", "")}px ${settingFormData[
        ".ecom-products-section .product-catdata .product-content .price"
      ]?.["m3"]?.replace("px", "")}px ${settingFormData[
        ".ecom-products-section .product-catdata .product-content .price"
      ]?.["m4"]?.replace("px", "")}px`,
    },
    ".ecom-products-section .product-catdata .product-content .price del": {
      ...settingFormData[
        ".ecom-products-section .product-catdata .product-content .price del"
      ],
      color: settingFormData[
        ".ecom-products-section .product-catdata .product-content .price del"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".ecom-products-section .product-catdata .product-content .price del"
              ]?.["color"],
          })
        : "",
      "margin-right": `${settingFormData[
        ".ecom-products-section .product-catdata .product-content .price del"
      ]?.["margin-right"]?.replace("px", "")}px`,
    },
    ".ecom-products-section .product-catdata .product-content .price ins": {
      ...settingFormData[
        ".ecom-products-section .product-catdata .product-content .price ins"
      ],
      "text-decoration": `${settingFormData?.[".ecom-products-section .product-catdata .product-content .price ins"]?.["text-decoration"]}`,
      color: settingFormData[
        ".ecom-products-section .product-catdata .product-content .price ins"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".ecom-products-section .product-catdata .product-content .price ins"
              ]?.["color"],
          })
        : "",
    },
    ".ecom-products-section .product-catdata .product-content .add-cart": {
      ...settingFormData[
        ".ecom-products-section .product-catdata .product-content .add-cart"
      ],
      border: `${settingFormData[
        ".ecom-products-section .product-catdata .product-content .add-cart"
      ]?.["borderPx"]?.replace("px", "")}px ${
        settingFormData[
          ".ecom-products-section .product-catdata .product-content .add-cart"
        ]?.["borderThik"]
      } ${rgbColorCreate({
        rgbString:
          settingFormData[
            ".ecom-products-section .product-catdata .product-content .add-cart"
          ]?.["borderColor"],
      })}`,
      color: settingFormData[
        ".ecom-products-section .product-catdata .product-content .add-cart"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".ecom-products-section .product-catdata .product-content .add-cart"
              ]?.["color"],
          })
        : "",
      "background-color": settingFormData[
        ".ecom-products-section .product-catdata .product-content .add-cart"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".ecom-products-section .product-catdata .product-content .add-cart"
              ]?.["background-color"],
          })
        : "",
      "font-size": `${settingFormData[
        ".ecom-products-section .product-catdata .product-content .add-cart"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".ecom-products-section .product-catdata .product-content .add-cart"]?.["font-weight"]}`,
      padding: `${settingFormData[
        ".ecom-products-section .product-catdata .product-content .add-cart"
      ]?.["p1"]?.replace("px", "")}px ${settingFormData[
        ".ecom-products-section .product-catdata .product-content .add-cart"
      ]?.["p2"]?.replace("px", "")}px`,
      "box-shadow": `${settingFormData?.[".ecom-products-section .product-catdata .product-content .add-cart"]?.["box-shadow"]}`,
    },
    ".ecom-products-section .product-catdata .product-content .add-cart:hover":
      {
        opacity: `${settingFormData?.[".ecom-products-section .product-catdata .product-content .add-cart:hover"]?.["opacity"]}`,
      },
    ".shop-section": {
      "background-color": settingFormData[".shop-section"]?.["background-color"]
        ? rgbColorCreate({
            rgbString: settingFormData[".shop-section"]?.["background-color"],
          })
        : "",
    },
    ".shop-section .filter-switch": {
      ...settingFormData?.[".shop-section .filter-switch"],
      "border-bottom": `${settingFormData?.[".shop-section .filter-switch"]?.[
        "borderPx"
      ]?.replace("px", "")}px ${
        settingFormData?.[".shop-section .filter-switch"]?.["borderThik"]
      } ${rgbColorCreate({
        rgbString:
          settingFormData[".shop-section .filter-switch"]?.["borderColor"],
      })}`,
    },
    ".shop-section .filter-switch .switch-text": {
      ...settingFormData?.[".shop-section .filter-switch .switch-text"],
      color: settingFormData[".shop-section .filter-switch .switch-text"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".shop-section .filter-switch .switch-text"]?.[
                "color"
              ],
          })
        : "",
      "font-size": `${settingFormData[
        ".shop-section .filter-switch .switch-text"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".shop-section .filter-switch .switch-text"]?.["font-weight"]}`,
    },
    ".shop-section .filter-switch .switch-toggle": {
      ...settingFormData?.[".shop-section .filter-switch .switch-toggle"],
      "background-color": settingFormData[
        ".shop-section .filter-switch .switch-toggle"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".shop-section .filter-switch .switch-toggle"]?.[
                "background-color"
              ],
          })
        : "",
      "border-radius": `${settingFormData[
        ".shop-section .filter-switch .switch-toggle"
      ]?.["border-radius"]?.replace("px", "")}px`,
    },
    ".shop-section .filter-switch .switch-toggle.opened": {
      "background-color": settingFormData[
        ".shop-section .filter-switch .switch-toggle.opened"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".shop-section .filter-switch .switch-toggle.opened"
              ]?.["background-color"],
          })
        : "",
    },
    ".ecom-products-section .more-btn": {
      ...settingFormData?.[".ecom-products-section .more-btn"],
      color: settingFormData[".ecom-products-section .more-btn"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".ecom-products-section .more-btn"]?.["color"],
          })
        : "",
      "font-size": `${settingFormData[".ecom-products-section .more-btn"]?.[
        "font-size"
      ]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".ecom-products-section .more-btn"]?.["font-weight"]}`,
      "border-radius": `${settingFormData[".ecom-products-section .more-btn"]?.[
        "border-radius"
      ]?.replace("px", "")}px`,
      padding: `${settingFormData[".ecom-products-section .more-btn"]?.[
        "p1"
      ]?.replace("px", "")}px ${settingFormData[
        ".ecom-products-section .more-btn"
      ]?.["p2"]?.replace("px", "")}px`,
      "background-color": settingFormData[".ecom-products-section .more-btn"]?.[
        "background-color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".ecom-products-section .more-btn"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".ecom-products-section .more-btn:hover": {
      "background-color": settingFormData[
        ".ecom-products-section .more-btn:hover"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".ecom-products-section .more-btn:hover"]?.[
                "background-color"
              ],
          })
        : "",
      color: settingFormData[".ecom-products-section .more-btn:hover"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".ecom-products-section .more-btn:hover"]?.[
                "color"
              ],
          })
        : "",
    },
  };
};
export const addCartPageStyle = (settingFormData) => {
  return {
    ".sidebar-panel .sidebar-top": {
      ...settingFormData?.[".sidebar-panel .sidebar-top"],
      padding: `${settingFormData?.[".sidebar-panel .sidebar-top"][
        "padding"
      ]?.replace("px", "")}px`,
      "background-color": settingFormData[".sidebar-panel .sidebar-top"]?.[
        "background-color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".sidebar-panel .sidebar-top"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".sidebar-panel": {
      "background-color": settingFormData[".sidebar-panel"]?.[
        "background-color"
      ]
        ? rgbColorCreate({
            rgbString: settingFormData[".sidebar-panel"]?.["background-color"],
          })
        : "",
    },
    ".sidebar-panel .sidebar-midd .product-list": {
      ...settingFormData?.[".sidebar-panel .sidebar-midd .product-list"],
      padding: `${settingFormData?.[
        ".sidebar-panel .sidebar-midd .product-list"
      ]["padding"]?.replace("px", "")}px`,
      "border-bottom": `${settingFormData[
        ".sidebar-panel .sidebar-midd .product-list"
      ]?.["borderPx"]?.replace("px", "")}px ${
        settingFormData[".sidebar-panel .sidebar-midd .product-list"]?.[
          "borderThik"
        ]
      } ${rgbColorCreate({
        rgbString:
          settingFormData[".sidebar-panel .sidebar-midd .product-list"]?.[
            "borderColor"
          ],
      })}`,
    },
    ".sidebar-panel .sidebar-midd .product-list .product-img": {
      ...settingFormData?.[
        ".sidebar-panel .sidebar-midd .product-list .product-img"
      ],
      padding: `${settingFormData?.[
        ".sidebar-panel .sidebar-midd .product-list .product-img"
      ]["padding"]?.replace("px", "")}px`,
      border: `${settingFormData[
        ".sidebar-panel .sidebar-midd .product-list .product-img"
      ]?.["borderPx"]?.replace("px", "")}px ${
        settingFormData[
          ".sidebar-panel .sidebar-midd .product-list .product-img"
        ]?.["borderThik"]
      } ${rgbColorCreate({
        rgbString:
          settingFormData[
            ".sidebar-panel .sidebar-midd .product-list .product-img"
          ]?.["borderColor"],
      })}`,
      "border-radius": `${settingFormData?.[
        ".sidebar-panel .sidebar-midd .product-list .product-img"
      ]["border-radius"]?.replace("px", "")}px`,
    },
    ".sidebar-panel .sidebar-midd .product-list .name": {
      ...settingFormData?.[".sidebar-panel .sidebar-midd .product-list .name"],
      "font-size": `${settingFormData?.[
        ".sidebar-panel .sidebar-midd .product-list .name"
      ]["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".sidebar-panel .sidebar-midd .product-list .name"]["font-weight"]}`,
      color: settingFormData[
        ".sidebar-panel .sidebar-midd .product-list .name"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".sidebar-panel .sidebar-midd .product-list .name"
              ]?.["color"],
          })
        : "",
    },
    ".sidebar-panel .sidebar-bottom": {
      "background-color": settingFormData[".sidebar-panel .sidebar-bottom"]?.[
        "background-color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".sidebar-panel .sidebar-bottom"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".sidebar-panel .sidebar-bottom .count": {
      ...settingFormData?.[".sidebar-panel .sidebar-bottom .count"],
      color: settingFormData[".sidebar-panel .sidebar-bottom .count"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".sidebar-panel .sidebar-bottom .count"]?.[
                "color"
              ],
          })
        : "",
      "font-size": `${settingFormData?.[
        ".sidebar-panel .sidebar-bottom .count"
      ]["font-size"]?.replace("px", "")}px`,
    },
    ".sidebar-panel .sidebar-bottom .checkout-btn": {
      ...settingFormData?.[".sidebar-panel .sidebar-bottom .checkout-btn"],
      "background-color": settingFormData[
        ".sidebar-panel .sidebar-bottom .checkout-btn"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".sidebar-panel .sidebar-bottom .checkout-btn"]?.[
                "background-color"
              ],
          })
        : "",
      "font-size": `${settingFormData?.[
        ".sidebar-panel .sidebar-bottom .checkout-btn"
      ]["font-size"]?.replace("px", "")}px`,
      padding: `${settingFormData?.[
        ".sidebar-panel .sidebar-bottom .checkout-btn"
      ]["p1"]?.replace("px", "")}px ${settingFormData[
        ".sidebar-panel .sidebar-bottom .checkout-btn"
      ]?.["p2"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData?.[
        ".sidebar-panel .sidebar-bottom .checkout-btn"
      ]["border-radius"]?.replace("px", "")}px`,
      color: settingFormData[".sidebar-panel .sidebar-bottom .checkout-btn"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".sidebar-panel .sidebar-bottom .checkout-btn"]?.[
                "color"
              ],
          })
        : "",
      "font-weight": `${settingFormData?.[".sidebar-panel .sidebar-bottom .checkout-btn"]["font-weight"]}`,
    },
  };
};
export const addProductPageStyle = (settingFormData) => {
  return {
    ".single-product-section": {
      ...settingFormData?.[".single-product-section"],
      padding: `${settingFormData?.[".single-product-section"][
        "padding"
      ]?.replace("px", "")}px 0`,
      "background-color": settingFormData[".single-product-section"]?.[
        "background-color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".single-product-section"]?.["background-color"],
          })
        : "",
    },
    ".single-product-section .subbox-div": {
      ...settingFormData?.[".single-product-section .subbox-div"],
      padding: `${settingFormData?.[".single-product-section .subbox-div"][
        "padding"
      ]?.replace("em", "")}em`,
      "background-color": settingFormData[
        ".single-product-section .subbox-div"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".single-product-section .subbox-div"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".single-product-section .subbox-div .taglabel": {
      ...settingFormData?.[".single-product-section .subbox-div .taglabel"],
      "font-size": `${settingFormData?.[
        ".single-product-section .subbox-div .taglabel"
      ]["font-size"]?.replace("px", "")}px`,
      "background-color": settingFormData[
        ".single-product-section .subbox-div .taglabel"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".single-product-section .subbox-div .taglabel"
              ]?.["background-color"],
          })
        : "",
      color: settingFormData[".single-product-section .subbox-div .taglabel"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".single-product-section .subbox-div .taglabel"
              ]?.["color"],
          })
        : "",
      "font-weight": `${settingFormData?.[".single-product-section .subbox-div .taglabel"]?.["font-weight"]}`,
      padding: `${settingFormData?.[
        ".single-product-section .subbox-div .taglabel"
      ]["p1"]?.replace("px", "")}px ${settingFormData[
        ".single-product-section .subbox-div .taglabel"
      ]?.["p2"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData?.[
        ".single-product-section .subbox-div .taglabel"
      ]["border-radius"]?.replace("px", "")}px`,
    },
    ".single-product-section .preview-thumbnail": {
      ...settingFormData?.[".single-product-section .preview-thumbnail"],
      margin: `${settingFormData?.[
        ".single-product-section .preview-thumbnail"
      ]["margin"]
        ?.split(" ")[0]
        ?.replace("px", "")}px 0`,
    },
    ".single-product-section .preview-thumbnail li .active": {
      ...settingFormData?.[
        ".single-product-section .preview-thumbnail li .active"
      ],
      border: `${settingFormData[
        ".single-product-section .preview-thumbnail li .active"
      ]?.["borderPx"]?.replace("px", "")}px ${
        settingFormData[
          ".single-product-section .preview-thumbnail li .active"
        ]?.["borderThik"]
      } ${rgbColorCreate({
        rgbString:
          settingFormData[
            ".single-product-section .preview-thumbnail li .active"
          ]?.["borderColor"],
      })}`,
    },
    ".single-product-section .single-proinfo .product-title": {
      ...settingFormData?.[
        ".single-product-section .single-proinfo .product-title"
      ],
      "margin-bottom": `${settingFormData?.[
        ".single-product-section .single-proinfo .product-title"
      ]["margin-bottom"]?.replace("px", "")}px`,
    },
    ".single-product-section .single-proinfo .product-description": {
      ...settingFormData?.[
        ".single-product-section .single-proinfo .product-description"
      ],
      "margin-bottom": `${settingFormData?.[
        ".single-product-section .single-proinfo .product-description"
      ]["margin-bottom"]?.replace("px", "")}px`,
    },
    ".single-product-section .single-proinfo .price": {
      ...settingFormData?.[".single-product-section .single-proinfo .price"],
      "margin-bottom": `${settingFormData?.[
        ".single-product-section .single-proinfo .price"
      ]["margin-bottom"]?.replace("px", "")}px`,
      color: settingFormData[
        ".single-product-section .single-proinfo .price"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".single-product-section .single-proinfo .price"
              ]?.["color"],
          })
        : "",
    },
    ".single-product-section .single-proinfo .add-to-cart": {
      ...settingFormData?.[
        ".single-product-section .single-proinfo .add-to-cart"
      ],
      border: `${settingFormData[
        ".single-product-section .single-proinfo .add-to-cart"
      ]?.["borderPx"]?.replace("px", "")}px ${
        settingFormData[
          ".single-product-section .single-proinfo .add-to-cart"
        ]?.["borderThik"]
      } ${rgbColorCreate({
        rgbString:
          settingFormData[
            ".single-product-section .single-proinfo .add-to-cart"
          ]?.["borderColor"],
      })}`,
      "background-color": settingFormData[
        ".single-product-section .single-proinfo .add-to-cart"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".single-product-section .single-proinfo .add-to-cart"
              ]?.["background-color"],
          })
        : "",
      color: settingFormData[
        ".single-product-section .single-proinfo .add-to-cart"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".single-product-section .single-proinfo .add-to-cart"
              ]?.["color"],
          })
        : "",
      padding: `${settingFormData?.[
        ".single-product-section .single-proinfo .add-to-cart"
      ]["p1"]?.replace("px", "")}px ${settingFormData[
        ".single-product-section .single-proinfo .add-to-cart"
      ]?.["p2"]?.replace("px", "")}px`,
    },
    ".single-product-section .single-proinfo .add-inquiry": {
      ...settingFormData?.[
        ".single-product-section .single-proinfo .add-inquiry"
      ],
      border: `${settingFormData[
        ".single-product-section .single-proinfo .add-inquiry"
      ]?.["borderPx"]?.replace("px", "")}px ${
        settingFormData[
          ".single-product-section .single-proinfo .add-inquiry"
        ]?.["borderThik"]
      } ${rgbColorCreate({
        rgbString:
          settingFormData[
            ".single-product-section .single-proinfo .add-inquiry"
          ]?.["borderColor"],
      })}`,
      "background-color": settingFormData[
        ".single-product-section .single-proinfo .add-inquiry"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".single-product-section .single-proinfo .add-inquiry"
              ]?.["background-color"],
          })
        : "",
      color: settingFormData[
        ".single-product-section .single-proinfo .add-inquiry"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".single-product-section .single-proinfo .add-inquiry"
              ]?.["color"],
          })
        : "",
      padding: `${settingFormData?.[
        ".single-product-section .single-proinfo .add-inquiry"
      ]["p1"]?.replace("px", "")}px ${settingFormData[
        ".single-product-section .single-proinfo .add-inquiry"
      ]?.["p2"]?.replace("px", "")}px`,
    },
    ".single-product-section .product-info-tab .nav-tabs .active": {
      "background-color": settingFormData[
        ".single-product-section .product-info-tab .nav-tabs .active"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".single-product-section .product-info-tab .nav-tabs .active"
              ]?.["background-color"],
          })
        : "",
      "border-color": settingFormData[
        ".single-product-section .product-info-tab .nav-tabs .active"
      ]?.["border-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".single-product-section .product-info-tab .nav-tabs .active"
              ]?.["border-color"],
          })
        : "",
      color: settingFormData[
        ".single-product-section .product-info-tab .nav-tabs .active"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".single-product-section .product-info-tab .nav-tabs .active"
              ]?.["color"],
          })
        : "",
    },
    ".single-product-section .product-info-tab .tab-content": {
      "background-color": settingFormData[
        ".single-product-section .product-info-tab .tab-content"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".single-product-section .product-info-tab .tab-content"
              ]?.["background-color"],
          })
        : "",
      padding: `${settingFormData?.[
        ".single-product-section .product-info-tab .tab-content"
      ]["padding"]?.replace("px", "")}px`,
      color: settingFormData[
        ".single-product-section .product-info-tab .tab-content"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".single-product-section .product-info-tab .tab-content"
              ]?.["color"],
          })
        : "",
    },
  };
};

export const addChecKOutStyle = (settingFormData) => {
  return {
    ".step-checkout-section": {
      ...settingFormData?.[".step-checkout-section"],
      padding: `${settingFormData[".step-checkout-section"]["padding"]
        ?.split(" ")[0]
        ?.replace("px", "")}px 0`,
      "background-color": settingFormData[".step-checkout-section"]?.[
        "background-color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".step-checkout-section"]?.["background-color"],
          })
        : "",
    },
    ".step-checkout-div .steps .icondiv": {
      ...settingFormData?.[".step-checkout-div .steps .icondiv"],
      "background-color": settingFormData[
        ".step-checkout-div .steps .icondiv"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".step-checkout-div .steps .icondiv"]?.[
                "background-color"
              ],
          })
        : "",
      color: settingFormData[".step-checkout-div .steps .icondiv"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".step-checkout-div .steps .icondiv"]?.["color"],
          })
        : "",
    },
    ".step-checkout-div .steps.activestep .icondiv, .step-checkout-div .steps.donestep .icondiv":
      {
        ...settingFormData?.[
          ".step-checkout-div .steps.activestep .icondiv, .step-checkout-div .steps.donestep .icondiv"
        ],
        color: settingFormData[
          ".step-checkout-div .steps.activestep .icondiv, .step-checkout-div .steps.donestep .icondiv"
        ]?.["color"]
          ? rgbColorCreate({
              rgbString:
                settingFormData[
                  ".step-checkout-div .steps.activestep .icondiv, .step-checkout-div .steps.donestep .icondiv"
                ]?.["color"],
            })
          : "",
        "background-color": settingFormData[
          ".step-checkout-div .steps.activestep .icondiv, .step-checkout-div .steps.donestep .icondiv"
        ]?.["background-color"]
          ? rgbColorCreate({
              rgbString:
                settingFormData[
                  ".step-checkout-div .steps.activestep .icondiv, .step-checkout-div .steps.donestep .icondiv"
                ]?.["background-color"],
            })
          : "",
      },
    ".step-checkout-div .steps .step-ttl": {
      ...settingFormData?.[".step-checkout-div .steps .step-ttl"],
      color: settingFormData[".step-checkout-div .steps .step-ttl"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".step-checkout-div .steps .step-ttl"]?.["color"],
          })
        : "",
      "font-size": `${settingFormData[".step-checkout-div .steps .step-ttl"]?.[
        "font-size"
      ]?.replace("px", "")}px`,
    },
    ".step-checkout-div .steps .btn-login": {
      ...settingFormData?.[".step-checkout-div .steps .btn-login"],
      "background-color": settingFormData[
        ".step-checkout-div .steps .btn-login"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".step-checkout-div .steps .btn-login"]?.[
                "background-color"
              ],
          })
        : "",
      border: `${settingFormData?.[".step-checkout-div .steps .btn-login"]?.[
        "borderPx"
      ]?.replace("px", "")}px ${
        settingFormData?.[".step-checkout-div .steps .btn-login"]?.[
          "borderThik"
        ]
      } ${rgbColorCreate({
        rgbString:
          settingFormData[".step-checkout-div .steps .btn-login"]?.[
            "borderColor"
          ],
      })}`,
      "font-size": `${settingFormData[".step-checkout-div .steps .btn-login"]?.[
        "font-size"
      ]?.replace("px", "")}px`,
      color: settingFormData[".step-checkout-div .steps .btn-login"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".step-checkout-div .steps .btn-login"]?.[
                "color"
              ],
          })
        : "",
      padding: `${settingFormData[".step-checkout-div .steps .btn-login"]?.[
        "p1"
      ]?.replace("px", "")}px ${settingFormData[
        ".step-checkout-div .steps .btn-login"
      ]?.["p2"]?.replace("px", "")}px`,
    },
    ".step-checkout-div .steps .btn-green": {
      ...settingFormData?.[".step-checkout-div .steps .btn-green"],
      "background-color": settingFormData[
        ".step-checkout-div .steps .btn-green"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".step-checkout-div .steps .btn-green"]?.[
                "background-color"
              ],
          })
        : "",
      color: settingFormData[".step-checkout-div .steps .btn-green"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".step-checkout-div .steps .btn-green"]?.[
                "color"
              ],
          })
        : "",
    },
    ".step-checkout-div .steps .addresslist p": {
      ...settingFormData?.[".step-checkout-div .steps .addresslist p"],
      color: settingFormData[".step-checkout-div .steps .addresslist p"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".step-checkout-div .steps .addresslist p"]?.[
                "color"
              ],
          })
        : "",
      "font-size": `${settingFormData[
        ".step-checkout-div .steps .addresslist p"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".step-checkout-div .steps .addresslist p"]?.["font-weight"]}`,
    },
    ".step-checkout-div .steps .addresslist .btn": {
      ...settingFormData?.[".step-checkout-div .steps .addresslist .btn"],
      color: settingFormData[".step-checkout-div .steps .addresslist .btn"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".step-checkout-div .steps .addresslist .btn"]?.[
                "color"
              ],
          })
        : "",
      "background-color": settingFormData[
        ".step-checkout-div .steps .addresslist .btn"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".step-checkout-div .steps .addresslist .btn"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".step-checkout-total .table-responsive .text-brand": {
      color: settingFormData[
        ".step-checkout-total .table-responsive .text-brand"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".step-checkout-total .table-responsive .text-brand"
              ]?.["color"],
          })
        : "",
    },
  };
};

export const addMyAccountStyle = (settingFormData) => {
  return {
    ".my-order-section": {
      ...settingFormData?.[".my-order-section"],
      padding: `${settingFormData[".my-order-section"]["padding"]
        ?.split(" ")[0]
        ?.replace("px", "")}px 0`,
      "background-color": settingFormData[".my-order-section"]?.[
        "background-color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".my-order-section"]?.["background-color"],
          })
        : "",
    },
    ".my-order-section .nav-pills .nav-link": {
      ...settingFormData?.[".my-order-section .nav-pills .nav-link"],
      color: settingFormData[".my-order-section .nav-pills .nav-link"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".my-order-section .nav-pills .nav-link"]?.[
                "color"
              ],
          })
        : "",
      "font-size": `${settingFormData[".my-order-section .nav-pills .nav-link"][
        "font-size"
      ]?.replace("px", "")}px`,
      padding: `${settingFormData[".my-order-section .nav-pills .nav-link"][
        "padding"
      ]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".my-order-section .nav-pills .nav-link"]?.["font-weight"]}`,
      border: `${settingFormData?.[".my-order-section .nav-pills .nav-link"]?.[
        "borderPx"
      ]?.replace("px", "")}px ${
        settingFormData?.[".my-order-section .nav-pills .nav-link"]?.[
          "borderThik"
        ]
      } ${rgbColorCreate({
        rgbString:
          settingFormData[".my-order-section .nav-pills .nav-link"]?.[
            "borderColor"
          ],
      })}`,
      "border-radius": `${settingFormData[
        ".my-order-section .nav-pills .nav-link"
      ]["border-radius"]?.replace("px", "")}px`,
    },
    ".my-order-section .nav-pills .nav-link.active": {
      ...settingFormData?.[".my-order-section .nav-pills .nav-link.active"],
      color: settingFormData[".my-order-section .nav-pills .nav-link.active"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".my-order-section .nav-pills .nav-link.active"
              ]?.["color"],
          })
        : "",
      "background-color": settingFormData[
        ".my-order-section .nav-pills .nav-link.active"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".my-order-section .nav-pills .nav-link.active"
              ]?.["background-color"],
          })
        : "",
    },
    ".my-order-section .tab-content a": {
      color: settingFormData[".my-order-section .tab-content a"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".my-order-section .tab-content a"]?.["color"],
          })
        : "",
    },
    ".my-order-section .tab-content .form-control": {
      ...settingFormData?.[".my-order-section .tab-content .form-control"],
      border: `${settingFormData?.[
        ".my-order-section .tab-content .form-control"
      ]?.["borderPx"]?.replace("px", "")}px ${
        settingFormData?.[".my-order-section .tab-content .form-control"]?.[
          "borderThik"
        ]
      } ${rgbColorCreate({
        rgbString:
          settingFormData?.[".my-order-section .tab-content .form-control"]?.[
            "borderColor"
          ],
      })}`,
      "border-radius": `${settingFormData[
        ".my-order-section .tab-content .form-control"
      ]["border-radius"]?.replace("px", "")}px`,
      "font-size": `${settingFormData[
        ".my-order-section .tab-content .form-control"
      ]["font-size"]?.replace("px", "")}px`,
    },
    ".my-order-section .tab-content .btn-primary": {
      ...settingFormData?.[".my-order-section .tab-content .btn-primary"],
      "background-color": settingFormData[
        ".my-order-section .tab-content .btn-primary"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".my-order-section .tab-content .btn-primary"]?.[
                "background-color"
              ],
          })
        : "",
      "border-color": settingFormData[
        ".my-order-section .tab-content .btn-primary"
      ]?.["border-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".my-order-section .tab-content .btn-primary"]?.[
                "border-color"
              ],
          })
        : "",
    },
  };
};
