import {
  addUrlImage,
  convertRGBA,
  getColorFromString,
  removeUrlImage,
  removeUrlKeyInPath,
  rgbColorCreate,
} from "../../../../utils/helper";

export const VideoStyleOneReset = {
  ".video-section": {
    padding: "100px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".video-section.bg-image": {},
  ".video-section.bg-image::before": {
    // "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
  },
  ".video-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".video-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: { r: 255, g: 73, b: 124, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 0.2 },
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".video-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: { r: 0, g: 0, b: 0, a: 100 },
  },
  ".video-section .gallery-catlist": {
    "text-align": "center",
    "margin-bottom": "30px",
  },
  ".video-section .gallery-catlist .btn": {
    border: "2px solid { r: 255, g: 73, b: 124, a: 100 }",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 73, b: 124, a: 100 },
    padding: "6px 20px",
    p1: "6px",
    p2: "20px",
    margin: "5px",
    "box-shadow": "none",
  },
  ".video-section .gallery-catlist .active": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".video-section .video-list .box": {
    "margin-bottom": "20px",
    outline: "0px solid { r: 255, g: 255, b: 255, a: 100 }",
    outPx: "0px",
    outThik: "solid",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
    "box-shadow": "0 5px 10px { r: 7, g: 28, b: 31, a: 0.039 }",
    bs1: "0px",
    bs2: "5px",
    bs3: "10px",
    bs4: "0px",
    shadowColor: { r: 7, g: 28, b: 31, a: 0.039 },
  },
  ".video-section .video-list .video-img:before": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "30px",
  },
  ".video-section .video-list .title-text": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    "font-size": "18px",
    "font-weight": "600",
    color: { r: 255, g: 255, b: 255, a: 100 },
    "text-align": "center",
    padding: "15px",
  },
  ".video-section .more-btn": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".video-section .more-btn:hover": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".pagination .page-item.active .page-link": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    "border-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  custom: "",
};

export const VideoThemeOneFunction = (payload) => {
  return {
    ".video-section": {
      padding: payload.style?.[".video-section"]?.["padding"],
      "background-color": payload.style?.[".video-section"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString: payload.style?.[".video-section"]?.["background-color"],
          })
        : "",
      width: payload.style?.[".video-section"]?.["width"]?.replace(
        "calc(100% - ",
        ""
      ),
      margin: payload.style?.[".video-section"]?.["margin"],
      "border-radius": payload.style?.[".video-section"]?.["border-radius"],
    },
    ".video-section.bg-image": {
      "background-image": payload.style?.[".video-section.bg-image"]?.[
        "background-image"
      ]
        ? removeUrlKeyInPath({
            urlString:
              payload.style?.[".video-section.bg-image"]?.["background-image"],
          })
        : "",
    },
    ".video-section.bg-image::before": {
      "background-color": payload.style?.[".video-section.bg-image::before"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".video-section.bg-image::before"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".video-section .section-title-area": {
      "margin-bottom":
        payload.style?.[".video-section .section-title-area"]?.[
          "margin-bottom"
        ],
    },
    ".video-section .section-title-area .section-subtitle": {
      "font-size":
        payload.style?.[
          ".video-section .section-title-area .section-subtitle"
        ]?.["font-size"],
      "font-weight":
        payload.style?.[
          ".video-section .section-title-area .section-subtitle"
        ]?.["font-weight"],
      color: payload.style?.[
        ".video-section .section-title-area .section-subtitle"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".video-section .section-title-area .section-subtitle"
              ]?.["color"],
          })
        : "",
      "background-color": payload.style?.[
        ".video-section .section-title-area .section-subtitle"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".video-section .section-title-area .section-subtitle"
              ]?.["background-color"],
          })
        : "",
      padding:
        payload.style?.[
          ".video-section .section-title-area .section-subtitle"
        ]?.["padding"],
      p1: payload.style?.[
        ".video-section .section-title-area .section-subtitle"
      ]?.["padding"]?.split(" ")[0],
      p2: payload.style?.[
        ".video-section .section-title-area .section-subtitle"
      ]?.["padding"]?.split(" ")[1],
      "border-radius":
        payload.style?.[
          ".video-section .section-title-area .section-subtitle"
        ]?.["border-radius"],
      "margin-bottom":
        payload.style?.[
          ".video-section .section-title-area .section-subtitle"
        ]?.["margin-bottom"],
    },
    ".video-section .section-title-area .section-title": {
      "font-size":
        payload.style?.[".video-section .section-title-area .section-title"]?.[
          "font-size"
        ],
      "font-weight":
        payload.style?.[".video-section .section-title-area .section-title"]?.[
          "font-weight"
        ],
      color: payload.style?.[
        ".video-section .section-title-area .section-title"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".video-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".video-section .gallery-catlist": {
      "text-align":
        payload.style?.[".video-section .gallery-catlist"]?.["text-align"],
      "margin-bottom":
        payload.style?.[".video-section .gallery-catlist"]?.["margin-bottom"],
    },
    ".video-section .gallery-catlist .btn": {
      border:
        payload.style?.[".video-section .gallery-catlist .btn"]?.["border"],
      borderPx:
        payload.style?.[".video-section .gallery-catlist .btn"]?.[
          "border"
        ]?.split(" ")[0],
      borderThik:
        payload.style?.[".video-section .gallery-catlist .btn"]?.[
          "border"
        ]?.split(" ")[1],
      borderColor: convertRGBA({
        rgbString: getColorFromString({
          colorString:
            payload.style?.[".video-section .gallery-catlist .btn"]?.["border"],
        }),
      }),
      color: payload.style?.[".video-section .gallery-catlist .btn"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".video-section .gallery-catlist .btn"]?.[
                "color"
              ],
          })
        : "",
      padding:
        payload.style?.[".video-section .gallery-catlist .btn"]?.["padding"],
      p1: payload.style?.[".video-section .gallery-catlist .btn"]?.[
        "padding"
      ]?.split(" ")[0],
      p2: payload.style?.[".video-section .gallery-catlist .btn"]?.[
        "padding"
      ]?.split(" ")[1],
      margin:
        payload.style?.[".video-section .gallery-catlist .btn"]?.["margin"],
      "box-shadow":
        payload.style?.[".video-section .gallery-catlist .btn"]?.["box-shadow"],
    },
    ".video-section .gallery-catlist .active": {
      "background-color": payload.style?.[
        ".video-section .gallery-catlist .active"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".video-section .gallery-catlist .active"]?.[
                "background-color"
              ],
          })
        : "",
      color: payload.style?.[".video-section .gallery-catlist .active"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".video-section .gallery-catlist .active"]?.[
                "color"
              ],
          })
        : "",
    },
    ".video-section .video-list .box": {
      "margin-bottom":
        payload.style?.[".video-section .video-list .box"]?.["margin-bottom"],
      // outline: "none",
      outline: payload.style?.[".video-section .video-list .box"]?.["outline"],
      outPx:
        payload.style?.[".video-section .video-list .box"]?.["outline"]?.split(
          " "
        )[0],
      outThik:
        payload.style?.[".video-section .video-list .box"]?.["outline"]?.split(
          " "
        )[1],
      outColor: convertRGBA({
        rgbString: getColorFromString({
          colorString:
            payload.style?.[".video-section .video-list .box"]?.["outline"],
        }),
      }),
      "background-color": payload.style?.[".video-section .video-list .box"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".video-section .video-list .box"]?.[
                "background-color"
              ],
          })
        : "",
      "box-shadow":
        payload.style?.[".video-section .video-list .box"]?.["box-shadow"],
      bs1: payload.style?.[".video-section .video-list .box"]?.[
        "box-shadow"
      ]?.split(" ")[0],
      bs2: payload.style?.[".video-section .video-list .box"]?.[
        "box-shadow"
      ]?.split(" ")[1],
      bs3: payload.style?.[".video-section .video-list .box"]?.[
        "box-shadow"
      ]?.split(" ")[2],
      bs4: payload.style?.[".video-section .video-list .box"]?.[
        "box-shadow"
      ]?.split(" ")[3],
      shadowColor: convertRGBA({
        rgbString: getColorFromString({
          colorString:
            payload.style?.[".video-section .video-list .box"]?.["box-shadow"],
        }),
      }),
    },
    ".video-section .video-list .video-img:before": {
      color: payload.style?.[".video-section .video-list .video-img:before"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".video-section .video-list .video-img:before"]?.[
                "color"
              ],
          })
        : "",
      "font-size":
        payload.style?.[".video-section .video-list .video-img:before"]?.[
          "font-size"
        ],
    },
    ".video-section .video-list .title-text": {
      "background-color": payload.style?.[
        ".video-section .video-list .title-text"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".video-section .video-list .title-text"]?.[
                "background-color"
              ],
          })
        : "",
      "font-size":
        payload.style?.[".video-section .video-list .title-text"]?.[
          "font-size"
        ],
      "font-weight":
        payload.style?.[".video-section .video-list .title-text"]?.[
          "font-weight"
        ],
      color: payload.style?.[".video-section .video-list .title-text"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".video-section .video-list .title-text"]?.[
                "color"
              ],
          })
        : "",
      "text-align":
        payload.style?.[".video-section .video-list .title-text"]?.[
          "text-align"
        ],
      padding:
        payload.style?.[".video-section .video-list .title-text"]?.["padding"],
    },
    custom: payload.style.custom,
  };
};

export const VideoStyleOneSubFun = ({ settingFormData, boxSection }) => {
  let newData = {
    ...settingFormData,
    ".video-section": boxSection
      ? {
          ...settingFormData[".video-section"],
          padding: `${settingFormData[".video-section"]["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
          width: `calc(100% - ${settingFormData[".video-section"][
            "width"
          ]?.replace("rem)", "")}rem)`,
          margin: `${settingFormData[".video-section"]["margin"]
            ?.split(" ")[0]
            ?.replace("px", "")}px auto`,
          "border-radius": `${settingFormData[".video-section"][
            "border-radius"
          ]?.replace("rem", "")}rem`,
        }
      : {
          ...settingFormData[".video-section"],
          padding: `${settingFormData[".video-section"]["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
        },
    ".video-section.bg-image": settingFormData[".video-section.bg-image"][
      "background-image"
    ]
      ? {
          "background-image": settingFormData[".video-section.bg-image"][
            "background-image"
          ]
            ? addUrlImage({
                urlString: removeUrlImage({
                  urlString:
                    settingFormData?.[".video-section.bg-image"]?.[
                      "background-image"
                    ],
                }),
              })
            : "",
        }
      : {},
    ".video-section.bg-image::before": settingFormData[
      ".video-section.bg-image::before"
    ]["background-color"]
      ? {
          "background-color": settingFormData[
            ".video-section.bg-image::before"
          ]["background-color"]
            ? rgbColorCreate({
                rgbString:
                  settingFormData[".video-section.bg-image::before"][
                    "background-color"
                  ],
              })
            : "",
        }
      : {},
    ".video-section .section-title-area": {
      "margin-bottom": `${settingFormData[".video-section .section-title-area"][
        "margin-bottom"
      ]?.replace("px", "")}px`,
    },
    ".video-section .section-title-area .section-subtitle": {
      ...settingFormData[
        ".video-section .section-title-area .section-subtitle"
      ],
      "font-size": `${settingFormData[
        ".video-section .section-title-area .section-subtitle"
      ]["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".video-section .section-title-area .section-subtitle"]["font-weight"]}`,

      color: settingFormData[
        ".video-section .section-title-area .section-subtitle"
      ]["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".video-section .section-title-area .section-subtitle"
              ]["color"],
          })
        : "",
      "background-color": settingFormData[
        ".video-section .section-title-area .section-subtitle"
      ]["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".video-section .section-title-area .section-subtitle"
              ]["background-color"],
          })
        : "",
      padding: `${settingFormData[
        ".video-section .section-title-area .section-subtitle"
      ]["p1"]?.replace("px", "")}px ${settingFormData[
        ".video-section .section-title-area .section-subtitle"
      ]["p2"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData[
        ".video-section .section-title-area .section-subtitle"
      ]["border-radius"]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData[
        ".video-section .section-title-area .section-subtitle"
      ]["margin-bottom"]?.replace("px", "")}px`,
    },
    ".video-section .section-title-area .section-title": {
      ...settingFormData[".video-section .section-title-area .section-title"],
      "font-size": `${settingFormData[
        ".video-section .section-title-area .section-title"
      ]["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".video-section .section-title-area .section-title"]["font-weight"]}`,
      color: settingFormData[
        ".video-section .section-title-area .section-title"
      ]["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".video-section .section-title-area .section-title"
              ]["color"],
          })
        : "",
    },
    ".video-section .gallery-catlist .btn": {
      ...settingFormData[".video-section .gallery-catlist .btn"],
      border: `${settingFormData[".video-section .gallery-catlist .btn"][
        "borderPx"
      ]?.replace("px", "")}px ${
        settingFormData[".video-section .gallery-catlist .btn"]["borderThik"]
      } ${rgbColorCreate({
        rgbString:
          settingFormData[".video-section .gallery-catlist .btn"][
            "borderColor"
          ],
      })}`,
      color: settingFormData[".video-section .gallery-catlist .btn"]["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".video-section .gallery-catlist .btn"]["color"],
          })
        : "",
      padding: `${settingFormData[".video-section .gallery-catlist .btn"][
        "p1"
      ]?.replace("px", "")}px ${settingFormData[
        ".video-section .gallery-catlist .btn"
      ]["p2"]?.replace("px", "")}px`,
      margin: `${settingFormData[".video-section .gallery-catlist .btn"][
        "margin"
      ]?.replace("px", "")}px`,
      "box-shadow": `${settingFormData[".video-section .gallery-catlist .btn"]["box-shadow"]}`,
    },
    ".video-section .gallery-catlist .active": {
      ...settingFormData[".video-section .gallery-catlist .active"],
      "background-color": settingFormData[
        ".video-section .gallery-catlist .active"
      ]["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".video-section .gallery-catlist .active"][
                "background-color"
              ],
          })
        : "",
      color: settingFormData[".video-section .gallery-catlist .active"]["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".video-section .gallery-catlist .active"][
                "color"
              ],
          })
        : "",
    },
    ".video-section .video-list .box": {
      ...settingFormData[".video-section .video-list .box"],
      "margin-bottom": `${settingFormData[".video-section .video-list .box"][
        "margin-bottom"
      ]?.replace("px", "")}px`,
      // outline: "none",
      outline: `${settingFormData[".video-section .video-list .box"][
        "outPx"
      ]?.replace("px", "")}px ${
        settingFormData[".video-section .video-list .box"]["outThik"]
      } ${rgbColorCreate({
        rgbString:
          settingFormData[".video-section .video-list .box"]["outColor"],
      })}`,
      "background-color": settingFormData[".video-section .video-list .box"][
        "background-color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".video-section .video-list .box"][
                "background-color"
              ],
          })
        : "",
      "box-shadow": `${settingFormData[".video-section .video-list .box"][
        "bs1"
      ]?.replace("px", "")}px ${settingFormData[
        ".video-section .video-list .box"
      ]["bs2"]?.replace("px", "")}px ${settingFormData[
        ".video-section .video-list .box"
      ]["bs3"]?.replace("px", "")}px ${settingFormData[
        ".video-section .video-list .box"
      ]["bs4"]?.replace("px", "")}px ${rgbColorCreate({
        rgbString:
          settingFormData[".video-section .video-list .box"]["shadowColor"],
      })}`,
    },
    ".video-section .video-list .video-img:before": {
      ...settingFormData[".video-section .video-list .video-img:before"],
      color: settingFormData[".video-section .video-list .video-img:before"][
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".video-section .video-list .video-img:before"][
                "color"
              ],
          })
        : "",

      "font-size": `${settingFormData[
        ".video-section .video-list .video-img:before"
      ]["font-size"]?.replace("px", "")}px`,
    },
    ".video-section .video-list .title-text": {
      ...settingFormData[".video-section .video-list .title-text"],
      "background-color": settingFormData[
        ".video-section .video-list .title-text"
      ]["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".video-section .video-list .title-text"][
                "background-color"
              ],
          })
        : "",
      "font-size": `${settingFormData[".video-section .video-list .title-text"][
        "font-size"
      ]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".video-section .video-list .title-text"]["font-weight"]}`,
      color: settingFormData[".video-section .video-list .title-text"]["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".video-section .video-list .title-text"][
                "color"
              ],
          })
        : "",
      "text-align": `${settingFormData[".video-section .video-list .title-text"]["text-align"]}`,
      padding: `${settingFormData[".video-section .video-list .title-text"][
        "padding"
      ]?.replace("px", "")}px`,
    },
  };
  return newData;
};
