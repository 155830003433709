import React, { useState } from "react";
import DetailsContext from "./DetailsContext";

const DetailsState = (props) => {
  const [apiData, setApiData] = useState({ data: [], isLoading: false });
  const [isBackgroundColor, setIsBackgroundColor] = useState(false);
  const [fromData, setFormData] = useState({
    title: "",
    headLine: "",
    shortDescription: "",
    description: "",
    buttonCaption: "",
    image: "",
    displaySetting: "",
    isTitleShow: true,
    webId: localStorage.getItem("webId"),
    style: {
      ".only-text-section": {
        padding: "100px 0",
        "background-color": "rgb(255 255 255 / 100)",
      },
      image: "",
      ".only-text-section.bg-image": {
        "background-image": "",
      },
      ".only-text-section.bg-image::before": {
        "background-color": "",
      },
      ".only-text-section .section-title-area": {
        "margin-bottom": "50px",
      },
      ".only-text-section .section-title-area .section-subtitle": {
        "font-size": "16px",
        "font-weight": "600",
        color: "rgb(255 73 124 / 100)",
        "background-color": "rgb(255 73 124 / 0.2)",
        padding: "5px 20px",
        p1: "5px",
        p2: "20px",
        "border-radius": "15px",
        "margin-bottom": "15px",
      },
      ".only-text-section .section-title-area .section-title": {
        "font-size": "44px",
        "font-weight": "600",
        color: "rgb(0 0 0 / 100)",
      },
      ".only-text-section .only-text-content p": {
        "margin-bottom": "20px",
        "line-height": "1.6",
        "font-size": "18px",
      },
      ".only-text-section .only-text-content.info-list": {
        "margin-bottom": "30px",
      },
      ".only-text-section .only-text-content .info-list li": {
        "margin-bottom": "15px",
        "font-size": "18px",
      },
      ".only-text-section .only-text-content .order-list": {
        "margin-bottom": "30px",
      },
      ".only-text-section .only-text-content .order-list li": {
        "padding-left": "25px",
        "margin-bottom": "15px",
        "font-size": "18px",
      },
      ".only-text-section .only-text-content .order-list li:before": {
        "font-family": "FontAwesome",
        top: "3px",
        color: "rgb(255 73 124 / 100)",
      },
    },
  });
  const [boxSection, setBoxSection] = useState(false);
  const [settingFormData, setSettingFormData] = useState({
    ".only-text-section": {
      padding: "100px 0",
      "background-color": "#fafafc",
    },
    image: "",
    ".only-text-section.bg-image": {
      "background-image": "",
    },
    ".only-text-section.bg-image::before": {
      "background-color": "",
    },
    ".only-text-section .section-title-area": {
      "margin-bottom": "50px",
    },
    ".only-text-section .section-title-area .section-subtitle": {
      "font-size": "16px",
      "font-weight": "600",
      color: "#ff497c",
      "background-color": "#ff497c33",
      padding: "5px 20px",
      p1: "5px",
      p2: "20px",
      "border-radius": "15px",
      "margin-bottom": "15px",
    },
    ".only-text-section .section-title-area .section-title": {
      "font-size": "44px",
      "font-weight": "600",
      color: "#000000",
    },
    ".only-text-section .only-text-content p": {
      "margin-bottom": "20px",
      "line-height": "1.6",
      "font-size": "18px",
    },
    ".only-text-section .only-text-content.info-list": {
      "margin-bottom": "30px",
    },
    ".only-text-section .only-text-content .info-list li": {
      "margin-bottom": "15px",
      "font-size": "18px",
    },
    ".only-text-section .only-text-content .order-list": {
      "margin-bottom": "30px",
    },
    ".only-text-section .only-text-content .order-list li": {
      "padding-left": "25px",
      "margin-bottom": "15px",
      "font-size": "18px",
    },
    ".only-text-section .only-text-content .order-list li:before": {
      "font-family": "FontAwesome",
      top: "3px",
      color: "#ff497c",
    },
  });
  const [collId, setCollId] = useState();
  const [backImageToggle, setBackImageToggle] = useState(false);
  const [id, setId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [filter, setFilter] = useState("");
  return (
    <DetailsContext.Provider
      value={{
        apiData,
        setApiData,
        fromData,
        setFormData,
        id,
        setId,
        isEdit,
        setIsEdit,
        filter,
        setFilter,
        settingFormData,
        setSettingFormData,
        boxSection,
        setBoxSection,
        backImageToggle,
        setBackImageToggle,
        collId,
        setCollId,
        isBackgroundColor,
        setIsBackgroundColor,
      }}
    >
      {props.children}
    </DetailsContext.Provider>
  );
};

export default DetailsState;
