import React, { useContext, useMemo, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import { Cases, btnName, modalsName } from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
// import Lightbox from "react-image-lightbox";
import CommonPopup from "../../../Modals/commonPopup";
import DetailsPopup from "./DetailsPopup";
import ByteMiniWebDashboardContext from "../../../context/ByteMiniWeb_Dashboard_Context/ByteMiniWeb_Dashboard_Context";
import DetailsContext from "../../../context/DetailsContext/DetailsContext";
import {
  getDetails,
  getDetailsDetails,
  postDetails,
  putUpdateDetails,
  updateStatus,
} from "../../../apis/DetailsApi/DetailsApi";
import { FILE_TYPE } from "../../../../constant";
import { FILE_UPLOAD_API } from "../../../apis/SliderApi/SliderApi";
import { splitPathWeb } from "../../../apiConstants";
import { Truncate } from "../../../../utils/helper";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import SimpleReactValidator from "simple-react-validator";

const Details = () => {
  const { setAllPopupState, allPopupState } = useContext(
    ByteCardBuilderContext
  );
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const { apiData } = useContext(ByteMiniWebDashboardContext);
  const {
    apiData: details,
    setApiData,
    fromData,
    setFormData,
    id,
    setId,
    setIsEdit,
    isEdit,
    filter,
  } = useContext(DetailsContext);
  // const [open, setOpen] = useState(false);
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * get details section list
   */
  const getDetailsList = async (status) => {
    try {
      if (status !== false && !filter.length) {
        setApiData((prev) => {
          return { ...prev, isLoading: true };
        });
      }
      let paramObj = {
        search: { title: filter },
        showAll: true,
      };
      const { data } = await getDetails(paramObj);
      if (data.status === 200) {
        setApiData({
          ...details,
          isLoading: false,
          data: data.payload.data.map((elem) => {
            return {
              id: elem.title + elem.detailsSectionId,
              Id: elem.detailsSectionId,
              name: elem.title,
              image: elem.image,
              webId: elem.webId,
              des: elem.description,
              buttonCaption: elem.buttonCaption,
              buttonLink: elem.buttonLink,
              createdAt: elem.createdAt,
              isActive: elem.isActive,
              displaySetting: elem.displaySetting,
              shortDescription: elem.shortDescription,
            };
          }),
        });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  useMemo(() => {
    if (allPopupState.detailsPopup) {
      getDetailsList();
    }
  }, [filter, allPopupState.detailsPopup]);

  /**
   * enable disable api call
   * @param {detailsSection id} detailsSectionId
   * @param {status} isActive
   */
  const handleChangeStatus = async (detailsSectionId, isActive) => {
    try {
      let body = {
        detailsSectionId,
        isActive: isActive ? false : true,
      };
      const { data } = await updateStatus(body);
      if (data.status === 200) {
        getDetailsList(false);
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * upload image
   * @param {file} file
   */
  const imageUpload = async (file) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.BYTE_SLIDER);
    formData.append("file", file);
    setLoaderShow(true);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        setFormData((prev) => {
          return {
            ...prev,
            image: data.payload.baseUrl + data.payload.imageUrl,
          };
        });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * create and update details section
   */
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      if (id >= -1 && isEdit) {
        setLoaderShow(true);
        let newData = {};
        if (fromData?.image?.length) {
          setFormData((prev) => {
            return {
              ...prev,
              image: prev.image
                .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
                .pop(),
            };
          });
          newData = {
            ...fromData,
            image: fromData.image
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
              .pop(),
          };
        } else {
          newData = { ...fromData };
        }
        try {
          const { data } = await putUpdateDetails(newData || fromData);
          if (data.status === 200) {
            getDetailsList();
            emptyData();
            TostMessage({ message: data, type: "success" });
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      } else {
        try {
          setLoaderShow(true);
          let newData = {};
          if (fromData?.image?.length) {
            setFormData((prev) => {
              return {
                ...prev,
                image: prev.image
                  .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
                  .pop(),
              };
            });
            newData = {
              ...fromData,
              image: fromData.image
                .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
                .pop(),
              order: details.data.length + 1,
            };
          } else {
            newData = { ...fromData, order: details.data.length + 1 };
          }
          const { data } = await postDetails(newData);
          if (data.status === 200) {
            getDetailsList();
            emptyData();
            TostMessage({ message: data, type: "success" });
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      }
    }
  };

  /**
   * get details for details section
   * @param {id} detailsSectionId
   */
  const handleEdit = async (detailsSectionId) => {
    setLoaderShow(true);
    try {
      setId(detailsSectionId);
      const { data } = await getDetailsDetails(detailsSectionId);
      if (data.status === 200) {
        let payload = data.payload.data;
        setFormData({
          title: payload?.title,
          headLine: payload?.headLine,
          shortDescription: payload?.shortDescription,
          description: payload?.description,
          buttonCaption: payload?.buttonCaption,
          image: payload?.image,
          displaySetting: payload?.displaySetting,
          detailsSectionId: detailsSectionId,
          webId: localStorage.getItem("webId"),
        });
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * clear data
   */
  const emptyData = () => {
    setId("");
    setIsEdit(false);
    setFormData({
      title: "",
      headLine: "",
      shortDescription: "",
      description: "",
      buttonCaption: "",
      image: "",
      displaySetting: "",
      webId: localStorage.getItem("webId"),
    });
    setLoaderShow(false);
  };
  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody className="support-ticket-font box-border-div">
            <Row>
              <CardBuilderHeaderSection
                name={`${apiData?.data?.detailSection?.length} Details`}
              />
              {apiData?.data?.detailSection?.map((elem) => {
                return (
                  <Col xl="3 xl-50 box-col-6" md="6">
                    <Card className="ecommerce-widget">
                      <CardBody className="support-ticket-font">
                        <div className="col-12">
                          <CardBuilderHeaderSection
                            name={elem?.title && Truncate(elem?.title, 14)}
                          />
                          {/* <div
                            style={{ cursor: "pointer" }}
                            onClick={() => setOpen(!open)}
                          >
                            <div
                              style={{
                                objectFit: "contain",
                                maxWidth: "125px",
                                height: "83px",
                                border: "1px solid #d3d3d3",
                                width: "100%",
                                display: "inline-block",
                                verticalAlign: "middle",
                                overflow: "hidden",
                                position: "relative",
                              }}
                            >
                              <div
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  right: "0",
                                  bottom: "0",
                                  left: "0",
                                  borderRadius: "inherit",
                                }}
                              >
                                <img
                                  src="	https://api.bytecard.in/images/uploads/w-200/84ce7bf0be6b3e9d6cfe48f02038aa2e.jpg"
                                  alt=""
                                  style={{
                                    objectPosition: "50% 50%",
                                    objectFit: "contain",
                                    opacity: "1",
                                    transition: "opacity 0.28s ease-in",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                />
                              </div>
                            </div>
                          </div> */}
                          <div className="pt-4">
                            <p style={{ fontSize: "17px", fontWeight: "500" }}>
                              {elem.headLine && Truncate(elem.headLine, 20)}
                            </p>
                            <p
                              className="pt-1"
                              style={{
                                fontWeight: "400",
                                color: "#666666",
                                fontSize: "15px",
                              }}
                            >
                              {elem?.description &&
                                Truncate(elem?.description, 30)}
                            </p>
                          </div>
                        </div>
                        {/* <OpenPopupBtn
                          openPopup={() =>
                            OpenModalCommonFunc(Cases.DETAILS, setAllPopupState)
                          }
                          name={
                            apiData?.data?.detailSection?.buttonCaption ||
                            btnName.DETAILS
                          }
                        />{" "} */}
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
              <OpenPopupBtn
                openPopup={() =>
                  OpenModalCommonFunc(Cases.DETAILS, setAllPopupState)
                }
                name={btnName.DETAILS}
              />
            </Row>
          </CardBody>
        </Card>
      </Col>

      {/* {open && (
        <Lightbox
          mainSrc={
            "https://api.bytecard.in/images/uploads/w-200/84ce7bf0be6b3e9d6cfe48f02038aa2e.jpg"
          }
          onCloseRequest={() => setOpen(false)}
        />
      )} */}
      <CommonPopup
        open={allPopupState.detailsPopup}
        close={() =>
          OpenModalCommonFunc(Cases.DETAILS, setAllPopupState, false)
        }
        modalName={modalsName.DETAILS}
        modalCase={Cases.DETAILS}
        handleSubmit={handleSubmit}
        emptyData={emptyData}
        inputData={fromData}
        content={
          <DetailsPopup
            getData={getDetailsList}
            handleEdit={handleEdit}
            imageUpload={imageUpload}
            emptyData={emptyData}
            handleChangeStatus={handleChangeStatus}
            handleSubmit={handleSubmit}
            simpleValidator={simpleValidator}
          />
        }
      />
    </>
  );
};

export default Details;
