import React, { Fragment } from "react";
import { Col, Container, Media } from "reactstrap";
import sad from "../assets/images/sad.png";

const DateNotFoundV2 = () => {
  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="error-wrapper">
          <Container>
            <Media body className="img-100" src={sad} alt="" />
            <div className="error-heading">
              <h2 className="headline" style={{ color: "#dc3545" }}>
                {"404"}
              </h2>
            </div>
            <Col md="8 offset-md-2">
              <p className="sub-content">
                {
                  "The page you are attempting to reach is currently not available. This may be because the page does not exist or has been moved."
                }
              </p>
            </Col>
          </Container>
        </div>
      </div>
    </Fragment>
  );
};

export default DateNotFoundV2;
