import React, { useContext, useEffect, useState } from "react";
import SettingMultiWebContext from "../../../../context/SettingMultiWebContext/SettingMultiWebContext";
import { Button, Col, Form, Modal, ModalBody, ModalHeader } from "reactstrap";
import { WebNavBarMapList } from "../apis/NavApi/NavApi";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import { TostMessage } from "../../../utils";
import { cloneSection } from "../../../../api/CloneCategoryApi/CloneCategoryApi";

const CloneSectionModal = ({ ByteMiniWeb_Dashboard }) => {
  const {
    cloneAlignModal,
    setCloneAlignModal,
    cloneFormData,
    setCloneFormData,
  } = useContext(SettingMultiWebContext);
  const { setLoaderShow } = useContext(LoaderContext);
  const [navMapListData, setNamMapListData] = useState([]);

  const getNavBarMap = async () => {
    try {
      const paramObjForNavMap = {
        showAll: true,
        sortBy: "order",
        sortOrder: "ASC",
      };
      const { data } = await WebNavBarMapList(paramObjForNavMap);
      if (data.status === 200) {
        setNamMapListData(data.payload.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getNavBarMap();
  }, []);

  const emptyData = () => {
    setCloneFormData({
      contentMapId: "",
      navMapId: "",
      contentDetailName: "",
      pageConfigDetailsLabel: "",
    });
    setCloneAlignModal((prev) => {
      return {
        ...prev,
        cloneModal: false,
      };
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoaderShow(true);
      const { data } = await cloneSection(cloneFormData);
      if (data.status === 200) {
        ByteMiniWeb_Dashboard();
        setLoaderShow(false);
        emptyData();
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      setLoaderShow(false);
      TostMessage({ message: error, type: "error" });
    }
  };

  return (
    <Modal size="sm" isOpen={cloneAlignModal.cloneModal}>
      <ModalHeader
        toggle={() => {
          emptyData();
        }}
      >
        <span>Clone Section</span>
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          <Col md="12 mb-2">
            <select
              className="form-select"
              onChange={(e) =>
                setCloneFormData({ ...cloneFormData, navMapId: e.target.value })
              }
              value={cloneFormData.navMapId}
            >
              <option value="">--Select--</option>
              {navMapListData?.map((navMap) => {
                return (
                  <option value={navMap.navMapId} key={navMap.navMapId}>
                    {navMap?.label || navMap.navbarDetail.label}
                  </option>
                );
              })}
            </select>
          </Col>
          <Button style={{ marginTop: "12px" }} type="submit">
            Submit
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default CloneSectionModal;
