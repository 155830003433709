import React, { useContext, useEffect, useState } from "react";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import ItemsDragAndDrops from "../../../common/ItemsDragAndDrops";
import { maxLengthCheck } from "../../../../utils/helper";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { sort_slider } from "../../../apis/SliderMultipageApi/SliderMultipageApi";
import {
  Cases,
  DELETE_CONFIRM,
  options,
  socialMediaTypes,
  socialMediaTypesMultipage,
} from "../../../constant";
import {
  delete_Image_Permanent,
  delete_Multi_Image_Permanent,
} from "../apis/SliderApi/SliderApi";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import DownloadMultipageContext from "../../../context/DownloadMultipageContext/DownloadMultipageContext";
import SocialMediaMultipageContext from "../../../context/SocialMediaMultipageContext/SocialMediaMultipageContext";
import Select from "react-select";
import Dividers from "../../../common/divider";
import { UploadCloud } from "react-feather";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import {
  getSocialMediaMaster,
  sort_order_social_media,
} from "../apis/SocialMediaApi/SocialMediaApi";

const SocialMediaPopup = (props) => {
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);

  const {
    handleChangeStatus,
    imageUpload,
    emptyData,
    simpleValidator,
    handleDelete,
    handleEdit,
    contentMapId,
    getDownloadParentList,
  } = props;
  const {
    formDataChild,
    setFormDataChild,
    setFilter,
    filter,
    isEdit,
    setApiDataOurTeam: setApiDataImageParent,
    apiDataOurTeam: apiDataImageParent,
  } = useContext(SocialMediaMultipageContext);
  const { setLoaderShow } = useContext(LoaderContext);
  const [socialMediaMaster, setSocialMediaMaster] = useState([]);

  /**
   * drag and drop
   * @param {drag data} result
   * @returns
   */
  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;
    const items = Array.from(apiDataImageParent.data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setApiDataImageParent({ ...formDataChild, data: items });
    try {
      let itemOrder = [];
      for (const item in items) {
        itemOrder.push({ socialMediaId: items[item].Id, order: +item + 1 });
      }
      const { data } = await sort_order_social_media(itemOrder);
      if (data.status === 200) {
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * get social media master list
   */
  const getSocialMediaMasterApi = async () => {
    try {
      let paramObj = {
        showAll: true,
      };
      const { data } = await getSocialMediaMaster(paramObj);
      if (data.status === 200) {
        setSocialMediaMaster(data.payload.data);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  useEffect(() => {
    getSocialMediaMasterApi();
  }, []);

  /**
   * create new career
   */
  const hanldenewCreate = () => {
    const fileInput = document.getElementById("fileInput");
    emptyData(fileInput);
  };

  /**
   * reupload image
   */
  const reupload = () => {
    setFormDataChild((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };

  /**
   * input value change
   * @param {event} e
   */
  const changeFormValue = (e) => {
    const { name, value } = e.target;
    if (name === "itemId") {
      let [data] = socialMediaMaster.filter((elem) => elem.itemId === +value);
      setFormDataChild((prev) => {
        return {
          ...prev,
          [name]: value,
          type: data.type,
        };
      });
    } else {
      setFormDataChild((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    }
  };

  const deleteImagePermanent = async () => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData = {
          fileName: formDataChild.image
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
            .pop(),
        };
        const { data } = await delete_Multi_Image_Permanent(formData);
        if (data.status === 200) {
          TostMessage({ message: data, type: "success", position: "center" });
          setFormDataChild({ ...formDataChild, image: "", imageLink: "" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const allowedExtensions = ["jpg", "png", "pdf", "doc", "txt", "xls"];
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const extension = file.name.split(".").pop().toLowerCase();
    if (allowedExtensions.includes(extension)) {
      setFormDataChild((prev) => {
        return {
          ...prev,
          filePath: file,
        };
      });
    } else {
      const fileInput = document.getElementById("fileInput");
      if (fileInput) {
        fileInput.value = null;
      }
      TostMessage({
        message: "file type required only .jpg, .png, .pdf, .doc, .txt, .xls",
        type: "error",
        position: "custome",
      });
    }
  };

  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="6">
            <div>
              <h6>Total Count : {apiDataImageParent?.data?.length}</h6>
              {/* <p className="pt-2">You can add upto 25 Images</p> */}
              <Row>
                <Col md="6">
                  <Input
                    className="form-control mt-3 "
                    name="search"
                    type="text"
                    placeholder="search"
                    style={{ borderRadius: "0", color: "black" }}
                    value={filter}
                    onChange={(e) => {
                      getDownloadParentList({ filter: e.target.value });
                      setFilter(e.target.value);
                    }}
                  />
                </Col>

                <Col
                  md="6"
                  className="d-flex justify-content-center align-items-center mt-3"
                >
                  <Button
                    color="primary"
                    className="rounded-0"
                    onClick={() => hanldenewCreate()}
                  >
                    Add New
                  </Button>
                </Col>
              </Row>
              {apiDataImageParent.isLoading ? (
                <div className="mt-4">
                  <h6>Loading ...</h6>
                </div>
              ) : (
                <div className="mt-4">
                  <ItemsDragAndDrops
                    handleOnDragEnd={handleOnDragEnd}
                    characters={apiDataImageParent?.data}
                    handleChangeStatus={handleChangeStatus}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                    status={false}
                  />
                </div>
              )}
            </div>
          </Col>
          <Col sm="12" xl="6">
            <div className="mt-4">
              <h6>{isEdit ? "Edit" : "Add New"}</h6>
              <Row className="mt-4">
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Type *"}</Label>
                  <select
                    className="form-control"
                    style={{ borderRadius: "0" }}
                    value={formDataChild.itemId}
                    onChange={(e) => changeFormValue(e)}
                    name="itemId"
                  >
                    <option value="">--select--</option>
                    {socialMediaMaster.map((elem) => {
                      return <option value={elem.itemId}>{elem.label}</option>;
                    })}
                  </select>
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "type",
                      formDataChild.itemId,
                      "required"
                    )}
                  </p>
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Label *"}</Label>
                  <Input
                    className="form-control"
                    name="label"
                    type="text"
                    placeholder="Label *"
                    style={{ borderRadius: "0", color: "black" }}
                    value={formDataChild.label}
                    onChange={(e) => changeFormValue(e)}
                  />
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "label",
                      formDataChild.label,
                      "required"
                    )}
                  </p>
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Value *"}</Label>
                  <div className="d-flex gap-2 social-whatsapp">
                    {formDataChild.type ==
                      socialMediaTypesMultipage.whatsapp && (
                      <Select
                        style={{ width: "125px" }}
                        options={options}
                        name="dialCode"
                        value={formDataChild.dialCode}
                        onChange={(prev) =>
                          setFormDataChild((prevValue) => {
                            return {
                              ...prevValue,
                              dialCode: prev,
                            };
                          })
                        }
                      />
                    )}
                    <Input
                      className="form-control"
                      name="value"
                      type="text"
                      placeholder="Value *"
                      style={{ borderRadius: "0", color: "black" }}
                      value={formDataChild.value}
                      onChange={(e) => changeFormValue(e)}
                    />
                  </div>
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "value",
                      formDataChild.value,
                      "required"
                    )}
                  </p>
                </Col>
              </Row>
              {formDataChild.type == socialMediaTypesMultipage.custome && (
                <>
                  <Dividers divide="IMAGES" />
                  <p className="mb-4">File type required JPG, PNG (Max 1MB)</p>
                  <Row>
                    <Col md="6 mb-3">
                      <div
                        className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                        style={{ cursor: "pointer" }}
                      >
                        <span
                          onClick={() => {
                            OpenModalCommonFunc(
                              Cases.PRODUCT_UPLOAD,
                              setAllPopupState
                            );
                          }}
                        >
                          <UploadCloud />
                        </span>
                        {formDataChild?.image && (
                          <img
                            src={
                              formDataChild?.image ? formDataChild?.image : ""
                            }
                            alt=""
                            style={{
                              width: "80%",
                              height: "98%",
                              objectFit: "contain",
                              position: "absolute",
                            }}
                            onClick={() => {
                              OpenModalCommonFunc(
                                Cases.PRODUCT_UPLOAD,
                                setAllPopupState
                              );
                            }}
                          />
                        )}
                        {formDataChild.image && (
                          <>
                            {" "}
                            <IconButton
                              edge="start"
                              color="inherit"
                              aria-label="close"
                              style={{
                                color: "red",
                                position: "absolute",
                                top: "-22px",
                                left: "1px",
                              }}
                              onClick={() => deleteImagePermanent()}
                            >
                              <Close
                                style={{
                                  fontSize: "25px",
                                  fontWeight: "bold",
                                }}
                              />
                            </IconButton>
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </Col>
        </Row>
        <ProductImageUploadPopup
          allPopupState={allPopupState}
          setAllPopupState={setAllPopupState}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
          image={formDataChild.image}
          setImage={setFormDataChild}
          imageUpload={imageUpload}
          isEdit={isEdit}
          reupload={reupload}
          // aspect={1.41/1}
        />
      </Container>
    </>
  );
};

export default SocialMediaPopup;
