import React, { useState } from "react";
import { SketchPicker } from "react-color";
import { Button, Label } from "reactstrap";
import BorderColorIcon from "@mui/icons-material/BorderColor";

const CommonColor = ({
  color,
  setSettingFormData,
  className,
  classProperty,
  label,
}) => {
  const [ShowColorPiker, setShowColorPiker] = useState(false);
  const HandleComplete = (e) => {
    setSettingFormData((prev) => {
      return {
        ...prev,
        [className]: {
          ...prev[className],
          [classProperty]: e.rgb,
        },
      };
    });
  };
  const HandleOnChange = (e) => {
    setSettingFormData((prev) => {
      return {
        ...prev,
        [className]: {
          ...prev[[className]],
          [classProperty]: e.rgb,
        },
      };
    });
  };
  return (
    <>
      <div className="d-flex align-items-center ">
        {label && <Label htmlFor="validationCustom03">{label}</Label>}

        <Button
          className="btn p-1 mx-2 border-0 "
          size="sm"
          // style={{ color: "#ec0944" }}
          onClick={(e) => setShowColorPiker(!ShowColorPiker)}
        >
          <BorderColorIcon />
        </Button>
      </div>
      {ShowColorPiker && (
        <SketchPicker
          color={color}
          onChangeComplete={(e) => HandleComplete(e)}
          onChange={(e) => HandleOnChange(e)}
        />
      )}
    </>
  );
};

export default CommonColor;
