import React from "react";
import { Switch } from "@material-ui/core";

const IsShowTitleORNot = ({ checked, onChange }) => {
  return (
    <>
      <Switch
        checked={checked}
        inputProps={{ "aria-label": "controlled" }}
        onChange={(e) => {
          onChange(e);
        }}
        name="isTitleShow"
      />{" "}
      ( Title will show front side )
    </>
  );
};

export default IsShowTitleORNot;
