import axios from "axios";
import {
  ADD_PLAN_MASTER_LIST,
  DELETE_PLAN_MASTER_LIST,
  DETAILS_PLAN_MASTER_LIST,
  EDIT_PLAN_MASTER_LIST,
  PLAN_MASTER_LIST,
  PLAN_MASTER_PAGE_LIST_ENABLE_DESABLE,
} from "../../constant/API constant";

export const PlanMaster_List = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${PLAN_MASTER_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

export const Add_PlanMaster_List = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${ADD_PLAN_MASTER_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Edit_PlanMaster_List = (formData, id) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${EDIT_PLAN_MASTER_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { planMasterId: id },
    }
  );
};

export const Delete_PlanMaster_List = (planMasterId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(
    `${process.env.REACT_APP_API_URL}${DELETE_PLAN_MASTER_LIST}`,
    {
      headers: { Authorization: jwt_token },
      params: { planMasterId },
    }
  );
};

export const Details_PlanMaster_List = (id) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${DETAILS_PLAN_MASTER_LIST}`,
    {
      headers: { Authorization: jwt_token },
      params: { planMasterId: id },
    }
  );
};

export const PlanMaster_Enable_Desable = (formData, planMasterId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PLAN_MASTER_PAGE_LIST_ENABLE_DESABLE}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { planMasterId },
    }
  );
};
