import React, { useContext } from "react";
import { Col, Container, Input, Label, Row } from "reactstrap";
import { Switch } from "@material-ui/core";
import GalleryContext from "../../../context/GalleryContext/GalleryContext";

const ImageParentPopup = (props) => {
  const { setFormDataImageParent, formDataImageParent, isEdit } =
    useContext(GalleryContext);
  const { simpleValidator } = props;

  /**
   * input value change
   * @param {event} e
   */
  const changeFormValue = (e) => {
    const { value, name, checked } = e.target;
    if (checked) {
      setFormDataImageParent((prev) => {
        return {
          ...prev,
          [name]: checked,
        };
      });
    } else {
      setFormDataImageParent((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    }
  };

  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="12">
            <div className="mt-4 d-flex flex-column justify-content-center ">
              <h6>{isEdit ? "Edit" : "Add New"}</h6>
              <Row className="mt-4">
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Title *"}</Label>
                  <Input
                    className="form-control"
                    name="title"
                    type="text"
                    placeholder="Title *"
                    value={formDataImageParent?.title}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                {/* <Col md="12 mb-3">
                  <Label className="d-block" for="edo-ani1">
                    <Switch
                      checked={formDataImageParent.isAlbum}
                      inputProps={{
                        "aria-label": "controlled",
                      }}
                      onChange={(e) => changeFormValue(e)}
                      color="primary"
                      name="isAlbum"
                      id="edo-ani1"
                      size="medium"
                    />
                    {"Is Album "}
                  </Label>
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "isAlbum",
                      formDataImageParent?.isAlbum,
                      "required"
                    )}
                  </p>
                </Col> */}
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Description"}</Label>
                  <textarea
                    className="form-control"
                    name="description"
                    type="text"
                    placeholder="Description"
                    value={formDataImageParent?.description}
                    rows={5}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ImageParentPopup;
