import { convertRGBA, rgbColorCreate } from "../../../../utils/helper";

export const DownloadStyleOneReset = {
  ".brochure-section": {
    padding: "100px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".brochure-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".brochure-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: { r: 255, g: 73, b: 124, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 0.2 },
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".brochure-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: { r: 0, g: 0, b: 0, a: 100 },
  },
  ".brochure-section .brochure-list .link-text": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    "font-size": "18px",
    "font-weight": "600",
    color: { r: 255, g: 255, b: 255, a: 100 },
    "text-align": "center",
    padding: "15px",
    "margin-bottom": "15px",
  },
  ".brochure-section .more-btn": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": { r: 223, g: 42, b: 93, a: 100 },
  },
  ".brochure-section .more-btn:hover": {
    "background-color": { r: 223, g: 42, b: 93, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".pagination .page-item.active .page-link": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    "border-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  downloadId: "",
  custom: "",
};

export const DownloadThemeOneFunction = (payload) => {
  return {
    ".brochure-section": {
      padding: payload.style?.[".brochure-section"]?.["padding"],
      "background-color": payload.style?.[".brochure-section"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".brochure-section"]?.["background-color"],
          })
        : "",
      width: payload.style?.[".brochure-section"]?.["width"]?.replace(
        "calc(100% - ",
        ""
      ),
      margin: payload.style?.[".brochure-section"]?.["margin"],
      "border-radius": payload.style?.[".brochure-section"]?.["border-radius"],
    },
    ".brochure-section .section-title-area": {
      "margin-bottom":
        payload.style?.[".brochure-section .section-title-area"]?.[
          "margin-bottom"
        ],
    },
    ".brochure-section .section-title-area .section-subtitle": {
      "font-size":
        payload.style?.[
          ".brochure-section .section-title-area .section-subtitle"
        ]?.["font-size"],
      "font-weight":
        payload.style?.[
          ".brochure-section .section-title-area .section-subtitle"
        ]?.["font-weight"],
      color: payload.style?.[
        ".brochure-section .section-title-area .section-subtitle"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".brochure-section .section-title-area .section-subtitle"
              ]?.["color"],
          })
        : "",
      "background-color": payload.style?.[
        ".brochure-section .section-title-area .section-subtitle"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".brochure-section .section-title-area .section-subtitle"
              ]?.["background-color"],
          })
        : "",
      padding:
        payload.style?.[
          ".brochure-section .section-title-area .section-subtitle"
        ]?.["padding"],
      p1: payload.style?.[
        ".brochure-section .section-title-area .section-subtitle"
      ]?.["padding"]?.split(" ")[0],
      p2: payload.style?.[
        ".brochure-section .section-title-area .section-subtitle"
      ]?.["padding"]?.split(" ")[1],
      "border-radius":
        payload.style?.[
          ".brochure-section .section-title-area .section-subtitle"
        ]?.["border-radius"],
      "margin-bottom":
        payload.style?.[
          ".brochure-section .section-title-area .section-subtitle"
        ]?.["margin-bottom"],
    },
    ".brochure-section .section-title-area .section-title": {
      "font-size":
        payload.style?.[
          ".brochure-section .section-title-area .section-title"
        ]?.["font-size"],
      "font-weight":
        payload.style?.[
          ".brochure-section .section-title-area .section-title"
        ]?.["font-weight"],
      color: payload.style?.[
        ".brochure-section .section-title-area .section-title"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".brochure-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".brochure-section .brochure-list .link-text": {
      "background-color": payload.style?.[
        ".brochure-section .brochure-list .link-text"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".brochure-section .brochure-list .link-text"]?.[
                "background-color"
              ],
          })
        : "",
      "font-size":
        payload.style?.[".brochure-section .brochure-list .link-text"]?.[
          "font-size"
        ],
      "font-weight":
        payload.style?.[".brochure-section .brochure-list .link-text"]?.[
          "font-weight"
        ],
      color: payload.style?.[".brochure-section .brochure-list .link-text"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".brochure-section .brochure-list .link-text"]?.[
                "color"
              ],
          })
        : "",
      "text-align":
        payload.style?.[".brochure-section .brochure-list .link-text"]?.[
          "text-align"
        ],
      padding:
        payload.style?.[".brochure-section .brochure-list .link-text"]?.[
          "padding"
        ],
      "margin-bottom":
        payload.style?.[".brochure-section .brochure-list .link-text"]?.[
          "margin-bottom"
        ],
    },
    custom: payload.style?.custom,
  };
};

export const DownloadStyleOneSubFun = ({ settingFormData, boxSection }) => {
  let newData = {
    ...settingFormData,
    ".brochure-section": boxSection
      ? {
          ...settingFormData[".brochure-section"],
          padding: `${settingFormData[".brochure-section"]["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
          width: `calc(100% - ${settingFormData[".brochure-section"][
            "width"
          ]?.replace("rem)", "")}rem)`,
          margin: `${settingFormData[".brochure-section"]["margin"]
            ?.split(" ")[0]
            ?.replace("px", "")}px auto`,
          "border-radius": `${settingFormData[".brochure-section"][
            "border-radius"
          ]?.replace("rem", "")}rem`,
        }
      : {
          ...settingFormData[".brochure-section"],
          padding: `${settingFormData[".brochure-section"]["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
        },
    ".brochure-section .section-title-area": {
      ...settingFormData[".brochure-section .section-title-area"],
      "margin-bottom": `${settingFormData[
        ".brochure-section .section-title-area"
      ]["margin-bottom"]?.replace("px", "")}px`,
    },
    ".brochure-section .section-title-area .section-subtitle": {
      ...settingFormData[
        ".brochure-section .section-title-area .section-subtitle"
      ],
      "font-size": `${settingFormData[
        ".brochure-section .section-title-area .section-subtitle"
      ]["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".brochure-section .section-title-area .section-subtitle"]["font-weight"]}`,
      color: settingFormData[
        ".brochure-section .section-title-area .section-subtitle"
      ]["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".brochure-section .section-title-area .section-subtitle"
              ]["color"],
          })
        : "",
      "background-color": settingFormData[
        ".brochure-section .section-title-area .section-subtitle"
      ]["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".brochure-section .section-title-area .section-subtitle"
              ]["background-color"],
          })
        : "",
      padding: `${settingFormData[
        ".brochure-section .section-title-area .section-subtitle"
      ]["p1"]?.replace("px", "")}px ${settingFormData[
        ".brochure-section .section-title-area .section-subtitle"
      ]["p2"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData[
        ".brochure-section .section-title-area .section-subtitle"
      ]["border-radius"]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData[
        ".brochure-section .section-title-area .section-subtitle"
      ]["margin-bottom"]?.replace("px", "")}px`,
    },
    ".brochure-section .section-title-area .section-title": {
      ...settingFormData[
        ".brochure-section .section-title-area .section-title"
      ],
      "font-size": `${settingFormData[
        ".brochure-section .section-title-area .section-title"
      ]["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".brochure-section .section-title-area .section-title"]["font-weight"]}`,
      color: settingFormData[
        ".brochure-section .section-title-area .section-title"
      ]["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".brochure-section .section-title-area .section-title"
              ]["color"],
          })
        : "",
    },
    ".brochure-section .brochure-list .link-text": {
      ...settingFormData[".brochure-section .brochure-list .link-text"],
      "background-color": settingFormData[
        ".brochure-section .brochure-list .link-text"
      ]["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".brochure-section .brochure-list .link-text"][
                "background-color"
              ],
          })
        : "",
      "font-size": `${settingFormData[
        ".brochure-section .brochure-list .link-text"
      ]["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".brochure-section .brochure-list .link-text"]["font-weight"]}`,
      color: settingFormData[".brochure-section .brochure-list .link-text"][
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".brochure-section .brochure-list .link-text"][
                "color"
              ],
          })
        : "",
      "text-align": `${settingFormData[".brochure-section .brochure-list .link-text"]["text-align"]}`,
      padding: `${settingFormData[
        ".brochure-section .brochure-list .link-text"
      ]["padding"]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData[
        ".brochure-section .brochure-list .link-text"
      ]["margin-bottom"]?.replace("px", "")}px`,
    },
  };
  return newData;
};
