export const SERVICES = {
  BYTE_MINI_WEB: "byte-mini-web",
  BYTE_MULTI_WEB: "byte-multi-web",
  BYTE_MULTI_WEB_PUBLIC: "byte-multi-web/public",
  BYTE_QR_NFC_PUBLIC: "byte-qr-nfc/public",
  ADMIN: "/admin",
};

export const V1 = "/api/v1";
export const WEB_MASTER = "/webMaster";
/**Apis endpoints */
export const SLIDER = "/slider";
export const DETAILS_SECTION = "/detailSection";
export const OUR_TEAM = "/ourTeam";
export const MEMBER = "/member";
export const ABOUT_US = "/aboutUs";
export const SEO_DETAILS = "/seoDetail";
export const CONTACT_US = "/contactUs";
export const DOWNLOAD = "/download";
export const COMPANY_LOGO = "/companyLogoTaglineService";
export const VIDEOS = "/video";
export const GALLERY = "/gallery";
export const LATEST_NEWS = "/latestNews";
export const ITEM_FEATURE = "/itemFeature";
export const PACKAGES = "/package";
export const SERVICE_COUNTER = "/serviceCounter";
export const OUR_CLIENT = "/ourClient";
export const IMAGE_BANNER = "/imageBanner";
export const VIDEO_BANNER = "/videoBanner";
export const FAQS = "/faqs";
export const STEPS = "/steps";
export const CAREER = "/career";
export const WHATS_APP_LINK = "/getLinkOnWhatsApp";
export const BLOG = "/blog/category";
export const BLOG_DETAIL = "/blog/detail";
export const SERVICE_CATEGORY = "/service/category";
export const SERVICE_ = "/service";
export const IMAGE = "/image";
export const ITEM = "/item";
export const TESTIMONIAL = "/testimonial";
export const PRODUCT = "/product";
export const SOCIAL_MEDIA = "/socialMedia";
export const SOCIAL_MEDIA_MASTER = "/master";
export const PAYMENT = "/payment";
export const WEB_PAGE_CONFIG = "/webPageConfig";
export const NAVBAR = "/navbar";

export const splitPath = "byte-qr/";
export const splitPathWeb = "dev-byte-qr-nfc/";
export const downloadSplit = "/Download";

/**details & status api endpoint */
export const DETAILS = "/detail";
export const ENABLE_DISABLE = "/enableDisable";
export const ORDER = "/order";

/**public apis */
export const BYTE_WEB_FILE_UPLOAD = `${SERVICES.BYTE_QR_NFC_PUBLIC}${V1}/mini-file`;
export const BYTE_WEB_DELETE_FILE = `${SERVICES.BYTE_QR_NFC_PUBLIC}${V1}/file`;

/** dashboard */
export const BYTE_WEB_DASHBOARD = `${SERVICES.BYTE_MINI_WEB}${V1}${WEB_MASTER}/dashboard`;
export const BYTE_MULTI_WEB_DASHBOARD = `${SERVICES.BYTE_MULTI_WEB}${V1}${WEB_MASTER}/dashboard`;
/** check sub domain */
export const CHECK_SUB_DOMAIN = `${SERVICES.BYTE_MINI_WEB}${V1}${WEB_MASTER}/checkDomain`;
export const UPDATE_DOMAIN_NAME = `${SERVICES.BYTE_MINI_WEB}${V1}${WEB_MASTER}/domain`;

/**Slider */
export const GET_SLIDER = `${SERVICES.BYTE_MINI_WEB}${V1}${SLIDER}`;
export const PUT_STATUS_SLIDER = `${SERVICES.BYTE_MINI_WEB}${V1}${SLIDER}${ENABLE_DISABLE}`;
export const POST_SLIDER = `${SERVICES.BYTE_MINI_WEB}${V1}${SLIDER}`;
export const GET_SLIDER_DETAILS = `${SERVICES.BYTE_MINI_WEB}${V1}${SLIDER}${DETAILS}`;
export const PUT_SLIDER_ORDER = `${SERVICES.BYTE_MINI_WEB}${V1}${SLIDER}${ORDER}`;

/**Details */
export const GET_DETAILS = `${SERVICES.BYTE_MINI_WEB}${V1}${DETAILS_SECTION}`;
export const PUT_STATUS_DETAILS = `${SERVICES.BYTE_MINI_WEB}${V1}${DETAILS_SECTION}${ENABLE_DISABLE}`;
export const POST_DETAILS = `${SERVICES.BYTE_MINI_WEB}${V1}${DETAILS_SECTION}`;
export const GET_DETAILS_DETAILS = `${SERVICES.BYTE_MINI_WEB}${V1}${DETAILS_SECTION}${DETAILS}`;
export const PUT_DETAILS_ORDER = `${SERVICES.BYTE_MINI_WEB}${V1}${DETAILS_SECTION}${ORDER}`;

/**Our Team master*/
export const GET_OUR_TEAM_MEMBER = `${SERVICES.BYTE_MINI_WEB}${V1}${OUR_TEAM}${MEMBER}`;
export const PUT_STATUS_OUR_TEAM_MEMBER = `${SERVICES.BYTE_MINI_WEB}${V1}${OUR_TEAM}${MEMBER}${ENABLE_DISABLE}`;
export const POST_OUR_TEAM_MEMBER = `${SERVICES.BYTE_MINI_WEB}${V1}${OUR_TEAM}${MEMBER}`;
export const GET_OUR_TEAM_MEMBER_DETAILS = `${SERVICES.BYTE_MINI_WEB}${V1}${OUR_TEAM}${MEMBER}${DETAILS}`;
export const PUT_OUR_TEAM_MEMBER_ORDER = `${SERVICES.BYTE_MINI_WEB}${V1}${OUR_TEAM}${MEMBER}${ORDER}`;

//our team

export const GET_OUR_TEAM = `${SERVICES.BYTE_MINI_WEB}${V1}${OUR_TEAM}`;
export const PUT_STATUS_OUR_TEAM = `${SERVICES.BYTE_MINI_WEB}${V1}${OUR_TEAM}${ENABLE_DISABLE}`;
export const POST_OUR_TEAM = `${SERVICES.BYTE_MINI_WEB}${V1}${OUR_TEAM}`;
export const GET_OUR_TEAM_DETAILS = `${SERVICES.BYTE_MINI_WEB}${V1}${OUR_TEAM}${DETAILS}`;
export const PUT_OUR_TEAM_ORDER = `${SERVICES.BYTE_MINI_WEB}${V1}${OUR_TEAM}${ORDER}`;

/**About us */
export const GET_ABOUT_US = `${SERVICES.BYTE_MINI_WEB}${V1}${ABOUT_US}`;
export const PUT_STATUS_ABOUT_US = `${SERVICES.BYTE_MINI_WEB}${V1}${ABOUT_US}${ENABLE_DISABLE}`;
export const POST_ABOUT_US = `${SERVICES.BYTE_MINI_WEB}${V1}${ABOUT_US}`;
export const GET_ABOUT_US_DETAILS = `${SERVICES.BYTE_MINI_WEB}${V1}${ABOUT_US}${DETAILS}`;
export const PUT_ABOUT_US_ORDER = `${SERVICES.BYTE_MINI_WEB}${V1}${ABOUT_US}${ORDER}`;

export const PUT_LABEL = `${SERVICES.BYTE_MINI_WEB}${SERVICES.ADMIN}${V1}${WEB_MASTER}${WEB_PAGE_CONFIG}`;
export const GET_NAVBAR = `${SERVICES.BYTE_MINI_WEB}${V1}${WEB_MASTER}${NAVBAR}`;

/**Contact us */
export const GET_CONTACT_US = `${SERVICES.BYTE_MINI_WEB}${V1}${CONTACT_US}`;
export const PUT_STATUS_CONTACT_US = `${SERVICES.BYTE_MINI_WEB}${V1}${CONTACT_US}${ENABLE_DISABLE}`;
export const POST_CONTACT_US = `${SERVICES.BYTE_MINI_WEB}${V1}${CONTACT_US}`;
export const GET_DETAILS_CONTACT_US = `${SERVICES.BYTE_MINI_WEB}${V1}${CONTACT_US}${DETAILS}`;
export const PUT_CONTACT_US_ORDER = `${SERVICES.BYTE_MINI_WEB}${V1}${CONTACT_US}${ORDER}`;

/**Details */
export const GET_DOWNLOAD = `${SERVICES.BYTE_MINI_WEB}${V1}${DOWNLOAD}`;
export const PUT_STATUS_DOWNLOAD = `${SERVICES.BYTE_MINI_WEB}${V1}${DOWNLOAD}${ENABLE_DISABLE}`;
export const POST_DOWNLOAD = `${SERVICES.BYTE_MINI_WEB}${V1}${DOWNLOAD}`;
export const GET_DETAILS_DOWNLOAD = `${SERVICES.BYTE_MINI_WEB}${V1}${DOWNLOAD}${DETAILS}`;
export const PUT_DOWNLOAD_ORDER = `${SERVICES.BYTE_MINI_WEB}${V1}${DOWNLOAD}${ORDER}`;

/**Company logo , tagline & services  */
export const GET_COMPANY_LOGO = `${SERVICES.BYTE_MINI_WEB}${V1}${COMPANY_LOGO}`;
export const PUT_STATUS_COMPANY_LOGO = `${SERVICES.BYTE_MINI_WEB}${V1}${COMPANY_LOGO}${ENABLE_DISABLE}`;
export const POST_COMPANY_LOGO = `${SERVICES.BYTE_MINI_WEB}${V1}${COMPANY_LOGO}`;
export const GET_DETAILS_COMPANY_LOGO = `${SERVICES.BYTE_MINI_WEB}${V1}${COMPANY_LOGO}${DETAILS}`;
export const PUT_COMPANY_LOGO_ORDER = `${SERVICES.BYTE_MINI_WEB}${V1}${COMPANY_LOGO}${ORDER}`;

/**Videos */
export const GET_VIDEOS = `${SERVICES.BYTE_MINI_WEB}${V1}${VIDEOS}`;
export const PUT_STATUS_VIDEOS = `${SERVICES.BYTE_MINI_WEB}${V1}${VIDEOS}${ENABLE_DISABLE}`;
export const POST_VIDEOS = `${SERVICES.BYTE_MINI_WEB}${V1}${VIDEOS}`;
export const GET_VIDEOS_DETAILS = `${SERVICES.BYTE_MINI_WEB}${V1}${VIDEOS}${DETAILS}`;
export const PUT_VIDEOS_ORDER = `${SERVICES.BYTE_MINI_WEB}${V1}${VIDEOS}${ORDER}`;

//videos items

export const GET_VIDEOS_ITEM = `${SERVICES.BYTE_MINI_WEB}${V1}${VIDEOS}${ITEM}`;
export const PUT_STATUS_VIDEOS_ITEM = `${SERVICES.BYTE_MINI_WEB}${V1}${VIDEOS}${ITEM}${ENABLE_DISABLE}`;
export const POST_VIDEOS_ITEM = `${SERVICES.BYTE_MINI_WEB}${V1}${VIDEOS}${ITEM}`;
export const GET_VIDEOS_ITEM_DETAILS = `${SERVICES.BYTE_MINI_WEB}${V1}${VIDEOS}${ITEM}${DETAILS}`;
export const PUT_VIDEOS_ITEM_ORDER = `${SERVICES.BYTE_MINI_WEB}${V1}${VIDEOS}${ITEM}${ORDER}`;

/**images*/
export const GET_GALLERY = `${SERVICES.BYTE_MINI_WEB}${V1}${GALLERY}`;
export const PUT_STATUS_GALLERY = `${SERVICES.BYTE_MINI_WEB}${V1}${GALLERY}${ENABLE_DISABLE}`;
export const POST_GALLERY = `${SERVICES.BYTE_MINI_WEB}${V1}${GALLERY}`;
export const GET_GALLERY_DETAILS = `${SERVICES.BYTE_MINI_WEB}${V1}${GALLERY}${DETAILS}`;
export const PUT_GALLERY_ORDER = `${SERVICES.BYTE_MINI_WEB}${V1}${GALLERY}${ORDER}`;

//images items

export const GET_IMAGE = `${SERVICES.BYTE_MINI_WEB}${V1}${GALLERY}${IMAGE}`;
export const PUT_STATUS_IMAGE = `${SERVICES.BYTE_MINI_WEB}${V1}${GALLERY}${IMAGE}${ENABLE_DISABLE}`;
export const POST_IMAGE = `${SERVICES.BYTE_MINI_WEB}${V1}${GALLERY}${IMAGE}`;
export const GET_IMAGE_DETAILS = `${SERVICES.BYTE_MINI_WEB}${V1}${GALLERY}${IMAGE}${DETAILS}`;
export const PUT_IMAGE_ORDER = `${SERVICES.BYTE_MINI_WEB}${V1}${GALLERY}${IMAGE}${ORDER}`;

/**latest news*/
export const GET_LATEST_NEWS = `${SERVICES.BYTE_MINI_WEB}${V1}${LATEST_NEWS}`;
export const PUT_STATUS_LATEST_NEWS = `${SERVICES.BYTE_MINI_WEB}${V1}${LATEST_NEWS}${ENABLE_DISABLE}`;
export const POST_LATEST_NEWS = `${SERVICES.BYTE_MINI_WEB}${V1}${LATEST_NEWS}`;
export const GET_LATEST_NEWS_DETAILS = `${SERVICES.BYTE_MINI_WEB}${V1}${LATEST_NEWS}${DETAILS}`;
export const PUT_LATEST_NEWS_ORDER = `${SERVICES.BYTE_MINI_WEB}${V1}${LATEST_NEWS}${ORDER}`;

/**item feature*/
export const GET_ITEM_FEATURE = `${SERVICES.BYTE_MINI_WEB}${V1}${ITEM_FEATURE}`;
export const PUT_STATUS_ITEM_FEATURE = `${SERVICES.BYTE_MINI_WEB}${V1}${ITEM_FEATURE}${ENABLE_DISABLE}`;
export const POST_ITEM_FEATURE = `${SERVICES.BYTE_MINI_WEB}${V1}${ITEM_FEATURE}`;
export const GET_ITEM_FEATURE_DETAILS = `${SERVICES.BYTE_MINI_WEB}${V1}${ITEM_FEATURE}${DETAILS}`;
export const PUT_ITEM_FEATURE_ORDER = `${SERVICES.BYTE_MINI_WEB}${V1}${ITEM_FEATURE}${ORDER}`;

/**packages*/
export const GET_PACKAGES = `${SERVICES.BYTE_MINI_WEB}${V1}${PACKAGES}`;
export const PUT_STATUS_PACKAGES = `${SERVICES.BYTE_MINI_WEB}${V1}${PACKAGES}${ENABLE_DISABLE}`;
export const POST_PACKAGES = `${SERVICES.BYTE_MINI_WEB}${V1}${PACKAGES}`;
export const GET_PACKAGES_DETAILS = `${SERVICES.BYTE_MINI_WEB}${V1}${PACKAGES}${DETAILS}`;
export const PUT_PACKAGES_ORDER = `${SERVICES.BYTE_MINI_WEB}${V1}${PACKAGES}${ORDER}`;

/**service counter*/
export const GET_SERVICE_COUNTER = `${SERVICES.BYTE_MINI_WEB}${V1}${SERVICE_COUNTER}`;
export const PUT_STATUS_SERVICE_COUNTER = `${SERVICES.BYTE_MINI_WEB}${V1}${SERVICE_COUNTER}${ENABLE_DISABLE}`;
export const POST_SERVICE_COUNTER = `${SERVICES.BYTE_MINI_WEB}${V1}${SERVICE_COUNTER}`;
export const GET_SERVICE_COUNTER_DETAILS = `${SERVICES.BYTE_MINI_WEB}${V1}${SERVICE_COUNTER}${DETAILS}`;
export const PUT_SERVICE_COUNTER_ORDER = `${SERVICES.BYTE_MINI_WEB}${V1}${SERVICE_COUNTER}${ORDER}`;

/**our client*/
export const GET_OUR_CLIENT = `${SERVICES.BYTE_MINI_WEB}${V1}${OUR_CLIENT}`;
export const PUT_STATUS_OUR_CLIENT = `${SERVICES.BYTE_MINI_WEB}${V1}${OUR_CLIENT}${ENABLE_DISABLE}`;
export const POST_OUR_CLIENT = `${SERVICES.BYTE_MINI_WEB}${V1}${OUR_CLIENT}`;
export const GET_OUR_CLIENT_DETAILS = `${SERVICES.BYTE_MINI_WEB}${V1}${OUR_CLIENT}${DETAILS}`;
export const PUT_OUR_CLIENT_ORDER = `${SERVICES.BYTE_MINI_WEB}${V1}${OUR_CLIENT}${ORDER}`;

/**image banner*/
export const GET_IMAGE_BANNER = `${SERVICES.BYTE_MINI_WEB}${V1}${IMAGE_BANNER}`;
export const PUT_STATUS_IMAGE_BANNER = `${SERVICES.BYTE_MINI_WEB}${V1}${IMAGE_BANNER}${ENABLE_DISABLE}`;
export const POST_IMAGE_BANNER = `${SERVICES.BYTE_MINI_WEB}${V1}${IMAGE_BANNER}`;
export const GET_IMAGE_BANNER_DETAILS = `${SERVICES.BYTE_MINI_WEB}${V1}${IMAGE_BANNER}${DETAILS}`;
export const PUT_IMAGE_BANNER_ORDER = `${SERVICES.BYTE_MINI_WEB}${V1}${IMAGE_BANNER}${ORDER}`;

/**video banner*/
export const GET_VIDEO_BANNER = `${SERVICES.BYTE_MINI_WEB}${V1}${VIDEO_BANNER}`;
export const PUT_STATUS_VIDEO_BANNER = `${SERVICES.BYTE_MINI_WEB}${V1}${VIDEO_BANNER}${ENABLE_DISABLE}`;
export const POST_VIDEO_BANNER = `${SERVICES.BYTE_MINI_WEB}${V1}${VIDEO_BANNER}`;
export const GET_VIDEO_BANNER_DETAILS = `${SERVICES.BYTE_MINI_WEB}${V1}${VIDEO_BANNER}${DETAILS}`;
export const PUT_VIDEO_BANNER_ORDER = `${SERVICES.BYTE_MINI_WEB}${V1}${VIDEO_BANNER}${ORDER}`;

/**faqs*/
export const GET_FAQS = `${SERVICES.BYTE_MINI_WEB}${V1}${FAQS}`;
export const PUT_STATUS_FAQS = `${SERVICES.BYTE_MINI_WEB}${V1}${FAQS}${ENABLE_DISABLE}`;
export const POST_FAQS = `${SERVICES.BYTE_MINI_WEB}${V1}${FAQS}`;
export const GET_FAQS_DETAILS = `${SERVICES.BYTE_MINI_WEB}${V1}${FAQS}${DETAILS}`;
export const PUT_FAQS_ORDER = `${SERVICES.BYTE_MINI_WEB}${V1}${FAQS}${ORDER}`;

/**steps*/
export const GET_STEPS = `${SERVICES.BYTE_MINI_WEB}${V1}${STEPS}`;
export const PUT_STATUS_STEPS = `${SERVICES.BYTE_MINI_WEB}${V1}${STEPS}${ENABLE_DISABLE}`;
export const POST_STEPS = `${SERVICES.BYTE_MINI_WEB}${V1}${STEPS}`;
export const GET_STEPS_DETAILS = `${SERVICES.BYTE_MINI_WEB}${V1}${STEPS}${DETAILS}`;
export const PUT_STEPS_ORDER = `${SERVICES.BYTE_MINI_WEB}${V1}${STEPS}${ORDER}`;

/**career*/
export const GET_CAREER = `${SERVICES.BYTE_MINI_WEB}${V1}${CAREER}`;
export const PUT_STATUS_CAREER = `${SERVICES.BYTE_MINI_WEB}${V1}${CAREER}${ENABLE_DISABLE}`;
export const POST_CAREER = `${SERVICES.BYTE_MINI_WEB}${V1}${CAREER}`;
export const GET_CAREER_DETAILS = `${SERVICES.BYTE_MINI_WEB}${V1}${CAREER}${DETAILS}`;
export const PUT_CAREER_ORDER = `${SERVICES.BYTE_MINI_WEB}${V1}${CAREER}${ORDER}`;

/**get whats app link*/
export const GET_WHATS_APP_LINK = `${SERVICES.BYTE_MINI_WEB}${V1}${WHATS_APP_LINK}`;
export const PUT_STATUS_WHATS_APP_LINK = `${SERVICES.BYTE_MINI_WEB}${V1}${WHATS_APP_LINK}${ENABLE_DISABLE}`;
export const POST_WHATS_APP_LINK = `${SERVICES.BYTE_MINI_WEB}${V1}${WHATS_APP_LINK}`;
export const GET_WHATS_APP_LINK_DETAILS = `${SERVICES.BYTE_MINI_WEB}${V1}${WHATS_APP_LINK}${DETAILS}`;
export const PUT_WHATS_APP_LINK_ORDER = `${SERVICES.BYTE_MINI_WEB}${V1}${WHATS_APP_LINK}${ORDER}`;

/**slider*/
export const GET_SLIDER_MULTIPAGE = `${SERVICES.BYTE_MINI_WEB}${V1}${SLIDER}`;
export const PUT_STATUS_SLIDER_MULTIPAGE = `${SERVICES.BYTE_MINI_WEB}${V1}${SLIDER}${ENABLE_DISABLE}`;
export const POST_SLIDER_MULTIPAGE = `${SERVICES.BYTE_MINI_WEB}${V1}${SLIDER}`;
export const GET_SLIDER_MULTIPAGE_DETAILS = `${SERVICES.BYTE_MINI_WEB}${V1}${SLIDER}${DETAILS}`;
export const PUT_SLIDER_MULTIPAGE_ORDER = `${SERVICES.BYTE_MINI_WEB}${V1}${SLIDER}${ORDER}`;

/**blog detail*/
export const GET_BLOG_DETAIL = `${SERVICES.BYTE_MINI_WEB}${V1}${BLOG_DETAIL}`;
export const PUT_STATUS_BLOG_DETAIL = `${SERVICES.BYTE_MINI_WEB}${V1}${BLOG_DETAIL}${ENABLE_DISABLE}`;
export const POST_BLOG_DETAIL = `${SERVICES.BYTE_MINI_WEB}${V1}${BLOG_DETAIL}`;
export const GET_BLOG_DETAIL_DETAILS = `${SERVICES.BYTE_MINI_WEB}${V1}${BLOG_DETAIL}${DETAILS}`;
export const PUT_BLOG_DETAIL_ORDER = `${SERVICES.BYTE_MINI_WEB}${V1}${BLOG_DETAIL}${ORDER}`;

//blog

export const GET_BLOG = `${SERVICES.BYTE_MINI_WEB}${V1}${BLOG}`;
export const PUT_STATUS_BLOG = `${SERVICES.BYTE_MINI_WEB}${V1}${BLOG}${ENABLE_DISABLE}`;
export const POST_BLOG = `${SERVICES.BYTE_MINI_WEB}${V1}${BLOG}`;
export const GET_BLOG_DETAILS = `${SERVICES.BYTE_MINI_WEB}${V1}${BLOG}${DETAILS}`;
export const PUT_BLOG_ORDER = `${SERVICES.BYTE_MINI_WEB}${V1}${BLOG}${ORDER}`;

/**service*/
export const GET_SERVICE_ = `${SERVICES.BYTE_MINI_WEB}${V1}${SERVICE_}`;
export const PUT_STATUS_SERVICE_ = `${SERVICES.BYTE_MINI_WEB}${V1}${SERVICE_}${ENABLE_DISABLE}`;
export const POST_SERVICE_ = `${SERVICES.BYTE_MINI_WEB}${V1}${SERVICE_}`;
export const GET_SERVICE__DETAILS = `${SERVICES.BYTE_MINI_WEB}${V1}${SERVICE_}${DETAILS}`;
export const PUT_SERVICE__ORDER = `${SERVICES.BYTE_MINI_WEB}${V1}${SERVICE_}${ORDER}`;

//service category

export const GET_SERVICE_CATEGORY = `${SERVICES.BYTE_MINI_WEB}${V1}${SERVICE_CATEGORY}`;
export const PUT_STATUS_SERVICE_CATEGORY = `${SERVICES.BYTE_MINI_WEB}${V1}${SERVICE_CATEGORY}${ENABLE_DISABLE}`;
export const POST_SERVICE_CATEGORY = `${SERVICES.BYTE_MINI_WEB}${V1}${SERVICE_CATEGORY}`;
export const GET_SERVICE_CATEGORY_DETAILS = `${SERVICES.BYTE_MINI_WEB}${V1}${SERVICE_CATEGORY}${DETAILS}`;
export const PUT_SERVICE_CATEGORY_ORDER = `${SERVICES.BYTE_MINI_WEB}${V1}${SERVICE_CATEGORY}${ORDER}`;

/**seo details */
export const GET_SEO_DETAILS = `${SERVICES.BYTE_MINI_WEB}${V1}${SEO_DETAILS}`;
export const PUT_STATUS_SEO_DETAILS = `${SERVICES.BYTE_MINI_WEB}${V1}${SEO_DETAILS}${ENABLE_DISABLE}`;
export const POST_SEO_DETAILS = `${SERVICES.BYTE_MINI_WEB}${V1}${SEO_DETAILS}`;
export const GET_SEO_DETAILS_DETAILS = `${SERVICES.BYTE_MINI_WEB}${V1}${SEO_DETAILS}${DETAILS}`;
export const PUT_SEO_DETAILS_ORDER = `${SERVICES.BYTE_MINI_WEB}${V1}${SEO_DETAILS}${ORDER}`;

/**Testimonial */
export const GET_TESTIMONIAL = `${SERVICES.BYTE_MINI_WEB}${V1}${TESTIMONIAL}`;
export const PUT_STATUS_TESTIMONIAL = `${SERVICES.BYTE_MINI_WEB}${V1}${TESTIMONIAL}${ENABLE_DISABLE}`;
export const POST_TESTIMONIAL = `${SERVICES.BYTE_MINI_WEB}${V1}${TESTIMONIAL}`;
export const GET_TESTIMONIAL_DETAILS = `${SERVICES.BYTE_MINI_WEB}${V1}${TESTIMONIAL}${DETAILS}`;
export const PUT_TESTIMONIAL_ORDER = `${SERVICES.BYTE_MINI_WEB}${V1}${TESTIMONIAL}${ORDER}`;

/**Product */
export const GET_PRODUCT = `${SERVICES.BYTE_MINI_WEB}${V1}${PRODUCT}`;
export const PUT_STATUS_PRODUCT = `${SERVICES.BYTE_MINI_WEB}${V1}${PRODUCT}${ENABLE_DISABLE}`;
export const POST_PRODUCT = `${SERVICES.BYTE_MINI_WEB}${V1}${PRODUCT}`;
export const GET_PRODUCT_DETAILS = `${SERVICES.BYTE_MINI_WEB}${V1}${PRODUCT}${DETAILS}`;
export const PUT_PRODUCT_ORDER = `${SERVICES.BYTE_MINI_WEB}${V1}${PRODUCT}${ORDER}`;

/**Social media  */
export const GET_SOCIAL_MEDIA = `${SERVICES.BYTE_MINI_WEB}${V1}${SOCIAL_MEDIA}`;
export const PUT_STATUS_SOCIAL_MEDIA = `${SERVICES.BYTE_MINI_WEB}${V1}${SOCIAL_MEDIA}${ENABLE_DISABLE}`;
export const POST_SOCIAL_MEDIA = `${SERVICES.BYTE_MINI_WEB}${V1}${SOCIAL_MEDIA}`;
export const GET_SOCIAL_MEDIA_DETAILS = `${SERVICES.BYTE_MINI_WEB}${V1}${SOCIAL_MEDIA}${DETAILS}`;
export const PUT_SOCIAL_MEDIA_ORDER = `${SERVICES.BYTE_MINI_WEB}${V1}${SOCIAL_MEDIA}${ORDER}`;

//social media master

export const GET_SOCIAL_MEDIA_MASTER = `${SERVICES.BYTE_MINI_WEB}${V1}${SOCIAL_MEDIA}${SOCIAL_MEDIA_MASTER}`;
export const PUT_STATUS_SOCIAL_MEDIA_MASTER = `${SERVICES.BYTE_MINI_WEB}${V1}${SOCIAL_MEDIA}${SOCIAL_MEDIA_MASTER}${ENABLE_DISABLE}`;
export const POST_SOCIAL_MEDIA_MASTER = `${SERVICES.BYTE_MINI_WEB}${V1}${SOCIAL_MEDIA}${SOCIAL_MEDIA_MASTER}`;
export const GET_SOCIAL_MEDIA_MASTER_DETAILS = `${SERVICES.BYTE_MINI_WEB}${V1}${SOCIAL_MEDIA}${SOCIAL_MEDIA_MASTER}${DETAILS}`;
export const PUT_SOCIAL_MEDIA_MASTER_ORDER = `${SERVICES.BYTE_MINI_WEB}${V1}${SOCIAL_MEDIA}${SOCIAL_MEDIA_MASTER}${ORDER}`;

/**payment items*/
export const GET_PAYMENT_ITEMS = `${SERVICES.BYTE_MINI_WEB}${V1}${PAYMENT}${ITEM}`;
export const PUT_STATUS_PAYMENT_ITEMS = `${SERVICES.BYTE_MINI_WEB}${V1}${PAYMENT}${ITEM}${ENABLE_DISABLE}`;
export const POST_PAYMENT_ITEMS = `${SERVICES.BYTE_MINI_WEB}${V1}${PAYMENT}${ITEM}`;
export const GET_PAYMENT_ITEMS_DETAILS = `${SERVICES.BYTE_MINI_WEB}${V1}${PAYMENT}${ITEM}${DETAILS}`;
export const PUT_PAYMENT_ITEMS_ORDER = `${SERVICES.BYTE_MINI_WEB}${V1}${PAYMENT}${ITEM}${ORDER}`;

//payment

export const GET_PAYMENT = `${SERVICES.BYTE_MINI_WEB}${V1}${PAYMENT}`;
export const PUT_STATUS_PAYMENT = `${SERVICES.BYTE_MINI_WEB}${V1}${PAYMENT}${ENABLE_DISABLE}`;
export const POST_PAYMENT = `${SERVICES.BYTE_MINI_WEB}${V1}${PAYMENT}`;
export const GET_PAYMENT_DETAILS = `${SERVICES.BYTE_MINI_WEB}${V1}${PAYMENT}${DETAILS}`;
export const PUT_PAYMENT_ORDER = `${SERVICES.BYTE_MINI_WEB}${V1}${PAYMENT}${ORDER}`;

/** card color change */
export const POST_THEME = `${SERVICES.BYTE_MINI_WEB}${V1}${WEB_MASTER}`;
