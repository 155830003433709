import React, { useContext } from "react";
import { AboutUsTheme, DELETE_CONFIRM, OurTeamTheme } from "../../../constant";
import { Col, Container, Input, Label, Row } from "reactstrap";
import { Zoom, toast } from "react-toastify";
import {
  delete_Image_Permanent,
  delete_Multi_Image_Permanent,
} from "../apis/SliderApi/SliderApi";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import Multipage_OurTeamContext from "../../../context/multipage_OurTeamContext/Multipage_OurTeamContext";
import { slugUrlConverter } from "../../../../utils/helper";
import { checkSlugUrl } from "../apis/OurTeamApi/OurTeamApi";
import IsShowTitleORNot from "../../../common/isShowTitleORNot";
import {
  ThemeNo,
  ourTeamTheme1,
  ourTeamTheme2,
  serviceAndCategoryTheme13,
} from "../../../../constant";

const OurTeamPopup = (props) => {
  const { imageUpload, simpleValidator } = props;
  const { setFormDataOurData, formDataOurTeam, isEdit } = useContext(
    Multipage_OurTeamContext
  );
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * input change
   * @param {event} e
   */
  const changeFormValue = (e) => {
    const { name, value, checked } = e.target;
    setFormDataOurData((prev) => {
      let updatedData =
        name === "isTitleShow"
          ? {
              ...prev,
              [name]: checked,
            }
          : name === "theme" && value == ThemeNo.ThemeOne
          ? { ...prev, [name]: value, style: ourTeamTheme1 }
          : name === "theme" && value == ThemeNo.ThemeTwo
          ? { ...prev, [name]: value, style: ourTeamTheme2 }
          : {
              ...prev,
              [name]: value,
            };
      if (name === "title" || name === "slugUrl") {
        updatedData.slugUrl = slugUrlConverter({ value: value });
      }
      return updatedData;
    });
  };

  /**
   * image reupload
   */
  const reupload = () => {
    setFormDataOurData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };

  /**
   * delete image permanently
   */
  const deleteImagePermanent = async () => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData = {
          fileName: formDataOurTeam.image
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
            .pop(),
        };
        const { data } = await delete_Multi_Image_Permanent(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            position: "top-center",
          });
          setFormDataOurData({ ...formDataOurTeam, image: "" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };

  const handleFocusChange = async () => {
    if (formDataOurTeam.slugUrl) {
      try {
        let paramObj = {
          slugUrl: formDataOurTeam.slugUrl,
          orgId: localStorage.getItem("orgId"),
        };
        await checkSlugUrl(paramObj);
      } catch (error) {
        toast.error(error.response.data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    }
  };

  return (
    <>
      <Container className="mb-5">
        <Row>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-around",
              marginBottom: "3rem",
            }}
          >
            <div className="mt-4 d-flex flex-column justify-content-center ">
              <h6>{isEdit ? "Edit" : "Add New"}</h6>
              <Row className="mt-4">
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">
                    Title *{" "}
                    <IsShowTitleORNot
                      checked={formDataOurTeam.isTitleShow}
                      onChange={changeFormValue}
                    />
                  </Label>
                  <Input
                    className="form-control"
                    name="title"
                    type="text"
                    placeholder="Title *"
                    value={formDataOurTeam.title}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "title",
                      formDataOurTeam.title,
                      "required"
                    )}
                  </p>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">{"Head Line"}</Label>
                  <Input
                    className="form-control"
                    name="headLine"
                    type="text"
                    placeholder="Head Line"
                    value={formDataOurTeam.headLine}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">{"Theme"}</Label>
                  <select
                    name="theme"
                    id="theme"
                    className="form-select"
                    style={{ borderRadius: "0", color: "black" }}
                    value={formDataOurTeam.theme}
                    onChange={(e) => changeFormValue(e)}
                  >
                    {OurTeamTheme.map((ele) => {
                      return (
                        <option value={ele.theme} key={ele.theme}>
                          {ele.themeName}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">{"Slug Url *"}</Label>
                  <Input
                    className="form-control"
                    name="slugUrl"
                    type="text"
                    placeholder="Slug Url *"
                    value={formDataOurTeam.slugUrl}
                    onChange={(e) => changeFormValue(e)}
                    onBlur={() => handleFocusChange()}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "slugUrl",
                      formDataOurTeam.slugUrl,
                      "required"
                    )}
                  </p>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">{"Button text"}</Label>
                  <Input
                    className="form-control"
                    name="buttonText"
                    type="text"
                    placeholder="Button text"
                    value={formDataOurTeam?.buttonText}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    required
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">{"Item Limit"}</Label>
                  <Input
                    className="form-control"
                    name="itemLimit"
                    type="number"
                    placeholder="Item Limit"
                    value={formDataOurTeam?.itemLimit}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    required
                  />
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Description"}</Label>
                  <textarea
                    className="form-control"
                    name="description"
                    type="text"
                    placeholder="Description"
                    value={formDataOurTeam.description}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              {/* <Dividers divide="IMAGE" />
              <p className="mb-4">
                File type required JPG, PNG (512px X 512px)
              </p>
              <Row>
                <Col md="6 mb-3">
                  <div
                    className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    <span
                      onClick={() =>
                        OpenModalCommonFunc(
                          Cases.PRODUCT_UPLOAD,
                          setAllPopupState
                        )
                      }
                    >
                      <UploadCloud />
                    </span>
                    {formDataOurTeam?.image && (
                      <img
                        src={
                          formDataOurTeam?.image ? formDataOurTeam?.image : ""
                        }
                        alt=""
                        style={{
                          width: "80%",
                          height: "98%",
                          objectFit: "contain",
                          position: "absolute",
                        }}
                        onClick={() =>
                          OpenModalCommonFunc(
                            Cases.PRODUCT_UPLOAD,
                            setAllPopupState
                          )
                        }
                      />
                    )}
                    {formDataOurTeam.image && (
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="close"
                        style={{
                          color: "red",
                          position: "absolute",
                          top: "-22px",
                          left: "1px",
                        }}
                        onClick={() => deleteImagePermanent()}
                      >
                        <Close
                          style={{ fontSize: "25px", fontWeight: "bold" }}
                        />
                      </IconButton>
                    )}
                  </div>
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "image",
                      formDataOurTeam?.image,
                      "required"
                    )}
                  </p>
                </Col>
              </Row> */}
            </div>
          </div>
        </Row>
        <ProductImageUploadPopup
          allPopupState={allPopupState}
          setAllPopupState={setAllPopupState}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
          image={formDataOurTeam.image}
          setImage={setFormDataOurData}
          imageUpload={imageUpload}
          isEdit={isEdit}
          reupload={reupload}
          // aspect={1 / 1}
        />
      </Container>
    </>
  );
};

export default OurTeamPopup;
