import React, { useContext } from "react";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import ItemsDragAndDrops from "../../../common/ItemsDragAndDrops";
import { maxLengthCheck, slugUrlConverter } from "../../../../utils/helper";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { sort_slider } from "../../../apis/SliderMultipageApi/SliderMultipageApi";
import Dividers from "../../../common/divider";
import { Cases, Currency, DELETE_CONFIRM } from "../../../constant";
import { UploadCloud } from "react-feather";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import {
  delete_Image_Permanent,
  delete_Multi_Image_Permanent,
} from "../apis/SliderApi/SliderApi";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import DownloadMultipageContext from "../../../context/DownloadMultipageContext/DownloadMultipageContext";
import { sort_order_Download } from "../apis/DownloadsApi/DownloadApi";
import ProductMultipageContext from "../../../context/ProductMultipageContext/ProductMultipageContext";
import { sort_order_product } from "../apis/ProductApi/ProductApi";
import StarIcon from "@mui/icons-material/Star";
import Select from "react-select";
import { MultiFileAllowUpload } from "../../../../constant";
import AllExportsImage from "../../../Modals/imageUploade/InputFileUpload";
import IsShowTitleORNot from "../../../common/isShowTitleORNot";
import { checkSlugUrl } from "../apis/OurTeamApi/OurTeamApi";
import { Zoom, toast } from "react-toastify";

const ProductPopup = (props) => {
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const {
    handleChangeStatus,
    imageUpload,
    emptyData,
    simpleValidator,
    handleDelete,
    handleEdit,
    getDownloadParentList,
  } = props;
  const {
    formDataChild,
    setFormDataChild,
    setFilter,
    filter,
    isEdit,
    setApiDataOurTeam: setApiDataImageParent,
    apiDataOurTeam: apiDataImageParent,
    setImages,
    images,
    index,
    setIndex,
  } = useContext(ProductMultipageContext);
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * drag and drop
   * @param {drag data} result
   * @returns
   */
  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;
    const items = Array.from(apiDataImageParent.data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setApiDataImageParent({ ...formDataChild, data: items });
    try {
      let itemOrder = [];
      for (const item in items) {
        itemOrder.push({ downloadId: items[item].Id, order: +item + 1 });
      }
      const { data } = await sort_order_product(itemOrder);
      if (data.status === 200) {
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * create new career
   */
  const hanldenewCreate = () => {
    const fileInput = document.getElementById("fileInput");
    emptyData(fileInput);
  };

  /**
   * reupload image
   */
  const reupload = () => {
    setFormDataChild((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };

  const deleteImagePermanent = async (img, index) => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData = {
          fileName: img
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
            .pop(),
        };
        const { data } = await delete_Multi_Image_Permanent(formData);
        if (data.status === 200) {
          TostMessage({ message: data, type: "success", position: "center" });
          setImages((prev) => {
            return prev.map((ele, id) => {
              if (id === index) {
                return {
                  ...ele,
                  path: "",
                };
              } else {
                return ele;
              }
            });
          });
          setFormDataChild({ ...formDataChild, image: "", imageLink: "" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * input value change
   * @param {Event} e
   */
  const changeFormValue = (e) => {
    const { name, value, checked } = e.target;
    setFormDataChild((prev) => {
      let updatedData =
        name === "isTitleShow"
          ? {
              ...prev,
              [name]: checked,
            }
          : {
              ...prev,
              [name]: value,
            };
      if (name === "title" || name === "slugUrl") {
        updatedData.slugUrl = slugUrlConverter({ value: value });
      }
      return updatedData;
    });
  };
  const handleFocusChange = async () => {
    if (setFormDataChild.slugUrl) {
      try {
        let paramObj = {
          slugUrl: setFormDataChild.slugUrl,
          orgId: localStorage.getItem("orgId"),
        };
        await checkSlugUrl(paramObj);
      } catch (error) {
        toast.error(error.response.data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    }
  };
  const changeCurrency = (name, e) => {
    setFormDataChild((prev) => {
      return {
        ...prev,
        [e.name]: name,
      };
    });
  };

  /**
   * change default value
   * @param {index} index
   */
  const changeIsDefault = (index) => {
    setImages((prev) => {
      let value = prev.map((elem, i) => {
        if (i === index) {
          return {
            path: elem.path,
            isDefault: true,
          };
        } else {
          return {
            path: elem.path,
            isDefault: false,
          };
        }
      });
      return value;
    });
  };

  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="6">
            <div>
              <h6>Total Count : {apiDataImageParent?.data?.length}</h6>
              {/* <p className="pt-2">You can add upto 25 Images</p> */}
              <Row>
                <Col md="6">
                  <Input
                    className="form-control mt-3 "
                    name="search"
                    type="text"
                    placeholder="search"
                    style={{ borderRadius: "0", color: "black" }}
                    value={filter}
                    onChange={(e) => {
                      getDownloadParentList({ filter: e.target.value });
                      setFilter(e.target.value);
                    }}
                  />
                </Col>

                <Col
                  md="6"
                  className="d-flex justify-content-center align-items-center mt-3"
                >
                  <Button
                    color="primary"
                    className="rounded-0"
                    onClick={() => hanldenewCreate()}
                  >
                    Add New
                  </Button>
                </Col>
              </Row>
              {apiDataImageParent.isLoading ? (
                <div className="mt-4">
                  <h6>Loading ...</h6>
                </div>
              ) : (
                <div className="mt-4">
                  <ItemsDragAndDrops
                    handleOnDragEnd={handleOnDragEnd}
                    characters={apiDataImageParent?.data}
                    handleChangeStatus={handleChangeStatus}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                  />
                </div>
              )}
            </div>
          </Col>
          <Col sm="12" xl="6">
            <div className="mt-4">
              <h6>{isEdit ? "Edit" : "Add New"}</h6>
              <Row className="mt-4">
                <Col md="12 mb-1">
                  <Label htmlFor="validationCustom03">
                    Title *{" "}
                    <IsShowTitleORNot
                      checked={formDataChild?.isTitleShow}
                      onChange={changeFormValue}
                    />{" "}
                  </Label>
                  <Input
                    className="form-control"
                    name="title"
                    type="text"
                    placeholder="Title *"
                    style={{ borderRadius: "0", color: "black" }}
                    min={0}
                    maxLength={50}
                    onInput={maxLengthCheck}
                    value={formDataChild.title}
                    onChange={(e) => changeFormValue(e)}
                  />
                  <div className="text-end mt-3 ">
                    <span>{formDataChild?.title?.length} / 50</span>
                  </div>
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "title",
                      formDataChild.title,
                      "required"
                    )}
                  </p>
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Slug Url *"}</Label>
                  <Input
                    className="form-control"
                    name="slugUrl"
                    type="text"
                    placeholder="Slug Url *"
                    value={formDataChild.slugUrl}
                    onChange={(e) => changeFormValue(e)}
                    onBlur={() => handleFocusChange()}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "slugUrl",
                      formDataChild.slugUrl,
                      "required"
                    )}
                  </p>
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Price"}</Label>
                  <Input
                    className="form-control"
                    name="price"
                    type="number"
                    placeholder="Price"
                    style={{ borderRadius: "0", color: "black" }}
                    value={formDataChild.price}
                    onChange={(e) => changeFormValue(e)}
                  />
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Currency"}</Label>
                  <Select
                    options={Currency}
                    name="currencySymbol"
                    value={formDataChild.currencySymbol}
                    onChange={(prev, e) => changeCurrency(prev, e)}
                  />
                  <p style={{ color: "red" }}>
                    {formDataChild.price
                      ? simpleValidator.current.message(
                          "Currency",
                          formDataChild.currencySymbol,
                          "required"
                        )
                      : ""}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md="12 mb-1">
                  <Label htmlFor="validationCustom03">{"Description"}</Label>
                  <textarea
                    className="form-control"
                    name="description"
                    type="text"
                    rows={5}
                    min={0}
                    maxLength={1000}
                    onInput={maxLengthCheck}
                    placeholder="Description"
                    style={{ borderRadius: "0", color: "black" }}
                    value={formDataChild.description}
                    onChange={(e) => changeFormValue(e)}
                  />
                  <div className="text-end mt-3 ">
                    <span>{formDataChild?.description?.length}</span>
                  </div>
                </Col>
              </Row>
              <Dividers divide="IMAGES" />
              <p className="mb-4">
                {AllExportsImage.fileUploadMessage} (1080px X 1080px)
              </p>
              <Row>
                <Col md="6 mb-3">
                  <div
                    className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    {!images[0]?.path && (
                      <span
                        onClick={() => {
                          OpenModalCommonFunc(
                            Cases.PRODUCT_UPLOAD,
                            setAllPopupState
                          );
                          setIndex(0);
                        }}
                      >
                        <UploadCloud />
                      </span>
                    )}

                    {images?.length && images[0]?.path && (
                      <img
                        src={images?.length ? images[0]?.path : ""}
                        alt=""
                        style={{
                          width: "80%",
                          height: "98%",
                          objectFit: "contain",
                          position: "absolute",
                        }}
                        onClick={() => {
                          deleteImagePermanent(images[0]?.path, 0);
                        }}
                      />
                    )}
                    {images?.length && images[0]?.path && (
                      <>
                        {" "}
                        <IconButton
                          edge="start"
                          color="inherit"
                          aria-label="close"
                          style={{
                            color: "red",
                            position: "absolute",
                            top: "-22px",
                            left: "1px",
                          }}
                          onClick={() =>
                            deleteImagePermanent(images[0]?.path, 0)
                          }
                        >
                          <Close
                            style={{ fontSize: "25px", fontWeight: "bold" }}
                          />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          aria-label="close"
                          style={{
                            color: images[0]?.isDefault ? "#ff9800" : "#c1c1c1",
                            position: "absolute",
                            top: "-22px",
                            right: "-10px",
                          }}
                          onClick={() => changeIsDefault(0)}
                        >
                          <StarIcon
                            style={{ fontSize: "25px", fontWeight: "bold" }}
                          />
                        </IconButton>
                      </>
                    )}
                  </div>
                </Col>
                <Col md="6 mb-3">
                  <div
                    className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center "
                    style={{ cursor: "pointer" }}
                  >
                    {!images[1]?.path && (
                      <span
                        onClick={() => {
                          OpenModalCommonFunc(
                            Cases.PRODUCT_UPLOAD,
                            setAllPopupState
                          );
                          setIndex(1);
                        }}
                      >
                        <UploadCloud />
                      </span>
                    )}{" "}
                    {images?.length && images[1]?.path && (
                      <img
                        src={images?.length ? images[1]?.path : ""}
                        alt=""
                        style={{
                          width: "80%",
                          height: "98%",
                          objectFit: "contain",
                          position: "absolute",
                        }}
                        onClick={() => {
                          deleteImagePermanent(images[1]?.path, 1);
                        }}
                      />
                    )}
                    {images?.length && images[1]?.path && (
                      <>
                        {" "}
                        <IconButton
                          edge="start"
                          color="inherit"
                          aria-label="close"
                          style={{
                            color: "red",
                            position: "absolute",
                            top: "-22px",
                            left: "1px",
                          }}
                          onClick={() =>
                            deleteImagePermanent(images[1]?.path, 1)
                          }
                        >
                          <Close
                            style={{ fontSize: "25px", fontWeight: "bold" }}
                          />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          aria-label="close"
                          style={{
                            color: images[1]?.isDefault ? "#ff9800" : "#c1c1c1",
                            position: "absolute",
                            top: "-22px",
                            right: "-10px",
                          }}
                          onClick={() => changeIsDefault(1)}
                        >
                          <StarIcon
                            style={{ fontSize: "25px", fontWeight: "bold" }}
                          />
                        </IconButton>
                      </>
                    )}
                  </div>
                </Col>
                <Col md="6 mb-3">
                  <div
                    className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center "
                    style={{ cursor: "pointer" }}
                  >
                    {!images[2]?.path && (
                      <span
                        onClick={() => {
                          OpenModalCommonFunc(
                            Cases.PRODUCT_UPLOAD,
                            setAllPopupState
                          );
                          setIndex(2);
                        }}
                      >
                        <UploadCloud />
                      </span>
                    )}{" "}
                    {images?.length && images[2]?.path && (
                      <img
                        src={images?.length ? images[2]?.path : ""}
                        alt=""
                        style={{
                          width: "80%",
                          height: "98%",
                          objectFit: "contain",
                          position: "absolute",
                        }}
                        onClick={() => {
                          deleteImagePermanent(images[2]?.path, 2);
                        }}
                      />
                    )}
                    {images?.length && images[2]?.path && (
                      <>
                        <IconButton
                          edge="start"
                          color="inherit"
                          aria-label="close"
                          style={{
                            color: "red",
                            position: "absolute",
                            top: "-22px",
                            left: "1px",
                          }}
                          onClick={() =>
                            deleteImagePermanent(images[2]?.path, 2)
                          }
                        >
                          <Close
                            style={{ fontSize: "25px", fontWeight: "bold" }}
                          />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          aria-label="close"
                          style={{
                            color: images[2]?.isDefault ? "#ff9800" : "#c1c1c1",
                            position: "absolute",
                            top: "-22px",
                            right: "-10px",
                          }}
                          onClick={() => changeIsDefault(2)}
                        >
                          <StarIcon
                            style={{ fontSize: "25px", fontWeight: "bold" }}
                          />
                        </IconButton>
                      </>
                    )}
                  </div>
                </Col>
                <Col md="6 mb-3">
                  <div
                    className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center "
                    style={{ cursor: "pointer" }}
                  >
                    {!images[3]?.path && (
                      <span
                        onClick={() => {
                          OpenModalCommonFunc(
                            Cases.PRODUCT_UPLOAD,
                            setAllPopupState
                          );
                          setIndex(3);
                        }}
                      >
                        <UploadCloud />
                      </span>
                    )}{" "}
                    {images?.length && images[3]?.path && (
                      <img
                        src={images?.length ? images[3]?.path : ""}
                        alt=""
                        style={{
                          width: "80%",
                          height: "98%",
                          objectFit: "contain",
                          position: "absolute",
                        }}
                        onClick={() => {
                          deleteImagePermanent(images[3]?.path, 3);
                        }}
                      />
                    )}
                    {images?.length && images[3]?.path && (
                      <>
                        <IconButton
                          edge="start"
                          color="inherit"
                          aria-label="close"
                          style={{
                            color: "red",
                            position: "absolute",
                            top: "-22px",
                            left: "1px",
                          }}
                          onClick={() =>
                            deleteImagePermanent(images[3]?.path, 3)
                          }
                        >
                          <Close
                            style={{ fontSize: "25px", fontWeight: "bold" }}
                          />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          aria-label="close"
                          style={{
                            color: images[3]?.isDefault ? "#ff9800" : "#c1c1c1",
                            position: "absolute",
                            top: "-22px",
                            right: "-10px",
                          }}
                          onClick={() => changeIsDefault(3)}
                        >
                          <StarIcon
                            style={{ fontSize: "25px", fontWeight: "bold" }}
                          />
                        </IconButton>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <ProductImageUploadPopup
          allPopupState={allPopupState}
          setAllPopupState={setAllPopupState}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
          image={formDataChild.image}
          setImage={setFormDataChild}
          imageUpload={imageUpload}
          isEdit={isEdit}
          reupload={reupload}
          // aspect={1.41/1}
        />
      </Container>
    </>
  );
};

export default ProductPopup;
