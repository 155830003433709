import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import FooterContext from "../../../context/FooterContext/FooterContext";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import Dividers from "../../../common/divider";
import { SocialTypes } from "../../../../constant";
import { options } from "../../../constant";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import { Cases, DELETE_CONFIRM } from "../../../constant";
import { Trash2, UploadCloud } from "react-feather";
import { IconButton } from "@mui/material";
import { delete_Multi_Image_Permanent } from "../apis/SliderApi/SliderApi";
import { randomStr } from "../../../../utils/helper";
import { getSocialMediaMaster } from "../apis/SocialMediaApi/SocialMediaApi";
import { Close } from "@material-ui/icons";
import Select from "react-select";

const FooterOne = (props) => {
  const { imageUpload } = props;
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const { setLoaderShow } = useContext(LoaderContext);
  const {
    isEdit,
    footerOneForm,
    setIsSectionImage,
    setFooterOneForm,
    setCustomeIndex,
    dialCode,
    setDialCode,
  } = useContext(FooterContext);
  const [socialMediaMaster, setSocialMediaMaster] = useState([]);
  /**
   * get social media master list
   */
  const getSocialMediaMasterApi = async () => {
    try {
      let paramObj = {
        showAll: true,
      };
      const { data } = await getSocialMediaMaster(paramObj);
      if (data.status === 200) {
        setSocialMediaMaster(data.payload.data);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  useEffect(() => {
    getSocialMediaMasterApi();
  }, []);

  /**
   * input value change
   * @param {event} e
   */
  const changeFormValue = (e) => {
    const { value, name } = e.target;
    setFooterOneForm((prev) => {
      let updatedData = {
        ...prev,
        [name]: value,
      };
      return updatedData;
    });
  };

  const handleSocialMediaChange = (e, i) => {
    const { name, value, options, selectedIndex } = e.target;
    let old = [...footerOneForm.socialMedia];
    old[i][name] = value;
    if (name === "type") {
      old[i]["name"] = options[selectedIndex].text;
    }
    setFooterOneForm((prev) => {
      return {
        ...prev,
        socialMedia: old,
      };
    });
  };
  const handleSocialMediaChangeDialCode = (e, i) => {
    let old = [...footerOneForm.socialMedia];
    old[i]["dialCode"] = e;
    setFooterOneForm((prev) => {
      return {
        ...prev,
        socialMedia: old,
      };
    });
  };

  const deleteImagePermanent = async (imgType, index) => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData;
        let social = [...footerOneForm.socialMedia];
        if (imgType === "FooterLogo") {
          formData = {
            fileName: footerOneForm?.footerLogo
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
              .pop(),
          };
        } else if (imgType === "socialImage") {
          let imageName = social[index];
          formData = {
            fileName: imageName.image
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
              .pop(),
          };
        }
        const { data } = await delete_Multi_Image_Permanent(formData);
        if (data.status === 200) {
          TostMessage({ message: data, type: "success", position: "center" });
          setFooterOneForm((prev) => {
            if (imgType === "FooterLogo") {
              return {
                ...prev,
                footerLogo: "",
                image: "",
              };
            } else if (imgType === "socialImage") {
              social[index]["image"] = "";
              return {
                ...prev,
                image: "",
                socialMedia: social,
              };
            }
          });

          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };
  const addMoreInput = () => {
    setFooterOneForm((prev) => {
      return {
        ...prev,
        socialMedia: [
          ...prev.socialMedia,
          { name: "", value: "", id: randomStr(2) },
        ],
      };
    });
  };
  const deleteMoreInput = (id) => {
    setFooterOneForm((prev) => {
      return {
        ...prev,
        socialMedia: prev.socialMedia.filter((item) => item.id !== id),
      };
    });
  };
  /**
   * reupload image
   */
  const reupload = () => {
    setFooterOneForm((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };

  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="12">
            <div className="mt-4 d-flex flex-column justify-content-center ">
              <h6>{isEdit ? "Edit" : "Add New"}</h6>
              <Row className="mt-4">
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">Address</Label>
                  <Input
                    className="form-control"
                    name="address"
                    type="text"
                    placeholder="Address"
                    value={footerOneForm?.address}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    required
                  />
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">
                    Enter Google Map Link
                  </Label>
                  <Input
                    className="form-control"
                    name="locationLink"
                    type="text"
                    placeholder="Google Map Link Link"
                    value={footerOneForm?.locationLink}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    required
                  />
                </Col>
                <Row>
                  <Col md="3 mb-3">
                    <Label htmlFor="validationCustom03">DialCode</Label>
                    <Select
                      style={{ width: "125px" }}
                      options={options}
                      name="dialCode"
                      isSearchable={true}
                      value={footerOneForm.dialCode}
                      onChange={(prev) =>
                        setFooterOneForm((prevValue) => {
                          return {
                            ...prevValue,
                            dialCode: prev,
                          };
                        })
                      }
                    />
                  </Col>
                  <Col md="9 mb-3">
                    <Label htmlFor="validationCustom03">Phone</Label>
                    <Input
                      className="form-control"
                      name="phone"
                      type="number"
                      placeholder="Phone"
                      value={footerOneForm?.phone}
                      onChange={(e) => changeFormValue(e)}
                      style={{ borderRadius: "0", color: "black" }}
                      required
                    />
                  </Col>
                </Row>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">Email</Label>
                  <Input
                    className="form-control"
                    name="email"
                    type="email"
                    placeholder="Email"
                    value={footerOneForm?.email}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    required
                  />
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">Content</Label>
                  <textarea
                    className="form-control"
                    name="content"
                    type="text"
                    placeholder="Content"
                    cols={5}
                    value={footerOneForm?.content}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    required
                  />
                </Col>
                <Col md="6 mb-3">
                  <Label htmlFor="validationCustom03">Footer Logo</Label>
                  <div
                    className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    {!footerOneForm.footerLogo && (
                      <span
                        onClick={() => {
                          OpenModalCommonFunc(
                            Cases.PRODUCT_UPLOAD,
                            setAllPopupState
                          );
                          setIsSectionImage("footerLogo");
                        }}
                      >
                        <UploadCloud />
                      </span>
                    )}

                    {footerOneForm.footerLogo && (
                      <img
                        src={footerOneForm.footerLogo}
                        alt=""
                        style={{
                          width: "85%",
                          height: "90px",
                          objectFit: "contain",
                          position: "absolute",
                        }}
                        onClick={() => {
                          deleteImagePermanent("FooterLogo");
                        }}
                      />
                    )}
                    {footerOneForm.footerLogo && (
                      <>
                        {" "}
                        <IconButton
                          edge="start"
                          color="inherit"
                          aria-label="close"
                          style={{
                            color: "red",
                            position: "absolute",
                            top: "-22px",
                            left: "1px",
                          }}
                          onClick={() => deleteImagePermanent("FooterLogo")}
                        >
                          <Close
                            style={{ fontSize: "25px", fontWeight: "bold" }}
                          />
                        </IconButton>
                      </>
                    )}
                  </div>
                </Col>
                <Dividers divide={"Social Media"} />
                {footerOneForm?.socialMedia?.map((elem, i) => {
                  return (
                    <Row key={elem.id}>
                      {elem.type === SocialTypes.custom && (
                        <Col md="3 mb-3">
                          <div
                            className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                            style={{ cursor: "pointer" }}
                          >
                            <span
                              onClick={() => {
                                OpenModalCommonFunc(
                                  Cases.PRODUCT_UPLOAD,
                                  setAllPopupState
                                );
                                setIsSectionImage(false);
                                setCustomeIndex(i);
                              }}
                            >
                              <UploadCloud />
                            </span>
                            {elem?.image && (
                              <img
                                src={
                                  `${process.env.REACT_APP_BUCKET_URL}/${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/${elem?.image}` ||
                                  ""
                                }
                                alt=""
                                style={{
                                  width: "80%",
                                  height: "60%",
                                  objectFit: "contain",
                                  position: "absolute",
                                }}
                                onClick={() => {
                                  deleteImagePermanent("socialImage", i);
                                }}
                              />
                            )}
                          </div>
                        </Col>
                      )}

                      <Col md="3 mb-3">
                        <Label htmlFor="validationCustom03">{"Type *"}</Label>
                        <select
                          className="form-control"
                          style={{ borderRadius: "0", color: "black" }}
                          value={elem.type}
                          onChange={(e) => handleSocialMediaChange(e, i)}
                          name="type"
                        >
                          <option value="">--select--</option>
                          {socialMediaMaster.map((social) => {
                            return (
                              <option
                                value={social.type}
                                disabled={footerOneForm?.socialMedia.some(
                                  (elem) => elem.type === social.type
                                )}
                              >
                                {social.label}
                              </option>
                            );
                          })}
                        </select>
                      </Col>
                      <Col md="3 mb-3">
                        <Label htmlFor="validationCustom03">{"Name"}</Label>
                        <Input
                          className="form-control"
                          name="name"
                          type="text"
                          placeholder="Name"
                          value={elem.name}
                          onChange={(e) => handleSocialMediaChange(e, i)}
                          style={{ borderRadius: "0", color: "black" }}
                        />
                      </Col>
                      {elem.type === SocialTypes.whatsapp && (
                        <Col md="3 mb-3">
                          <Label htmlFor="validationCustom03">
                            {"Dial Code*"}
                          </Label>
                          <Select
                            style={{ width: "125px" }}
                            options={options}
                            name="dialCode"
                            value={elem.dialCode}
                            onChange={(e) =>
                              handleSocialMediaChangeDialCode(e, i)
                            }
                          />
                        </Col>
                      )}
                      <Col md="3 mb-3">
                        <Label htmlFor="validationCustom03">{"Value"}</Label>
                        <div className="d-flex ">
                          <Input
                            className="form-control"
                            name="value"
                            type="text"
                            placeholder="Value"
                            value={elem.value}
                            onChange={(e) => handleSocialMediaChange(e, i)}
                            style={{ borderRadius: "0", color: "black" }}
                          />
                          {footerOneForm.socialMedia.length >= 2 && (
                            <IconButton
                              edge="start"
                              color="inherit"
                              style={{ marginLeft: "5px", color: "red" }}
                              onClick={() => deleteMoreInput(elem.id)}
                            >
                              <Trash2 />
                            </IconButton>
                          )}
                        </div>
                      </Col>
                    </Row>
                  );
                })}
                <div className="d-flex justify-content-center ">
                  <Button onClick={() => addMoreInput()} color="primary">
                    + Add More
                  </Button>
                </div>
              </Row>
            </div>
          </Col>
        </Row>
        <ProductImageUploadPopup
          allPopupState={allPopupState}
          setAllPopupState={setAllPopupState}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
          image={footerOneForm.image}
          setImage={setFooterOneForm}
          imageUpload={imageUpload}
          isEdit={isEdit}
          reupload={reupload}
          // aspect={1 / 1}
        />
      </Container>
    </>
  );
};

export default FooterOne;
