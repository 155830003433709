import Switch from "@material-ui/core/Switch";
import React from "react";
import { Button } from "react-bootstrap";
import { Create } from "../../constant";
import EditSectionName from "./EditSectionName";

const CardBuilderHeaderSection = (props) => {
  const {
    name,
    subName,
    isAdd,
    openModal,
    handleChange,
    id,
    checked,
    switchToggle,
    addBtnTitle,
    isAlignSection = false,
    isCloneSection = false,
    handleAlignAndCloneSection,
    mainEditSection = false,
    ByteMiniWeb_Dashboard,
    pageId,
  } = props;
  return (
    <div className="d-flex justify-content-between flex-wrap mb-2 align-items-center byte-card-section-head">
      <div className="d-flex align-items-center">
        <div>
          <h5 className="total-num counter mt-1 ">{name}</h5>
          <span className="d-flex align-items-center gap-2 mt-1">
            <div>{subName}</div>
          </span>
        </div>
        <div>
          {mainEditSection && (
            <EditSectionName
              ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
              pageId={pageId}
            />
          )}
        </div>
      </div>
      <div className="byte-card-switch">
        {isAlignSection && (
          <Button
            onClick={() => handleAlignAndCloneSection({})}
            style={{ marginRight: "12px" }}
          >
            Align Section
          </Button>
        )}
        {isCloneSection && (
          <Button
            onClick={() => handleAlignAndCloneSection({ isClone: true })}
            style={{ marginRight: "12px" }}
          >
            Clone Section
          </Button>
        )}
        {switchToggle && (
          <Switch
            checked={checked === true}
            inputProps={{ "aria-label": "controlled" }}
            onChange={() => handleChange(id, checked, false)}
          />
        )}
        {isAdd && (
          <Button onClick={() => openModal()}>
            {Create} {addBtnTitle}
          </Button>
        )}
      </div>
    </div>
  );
};

export default CardBuilderHeaderSection;
