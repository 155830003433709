import React, { Fragment, useContext, useEffect, useMemo } from "react";
import Breadcrumbs from "../../layout/breadcrumb";
import { tableId } from "../../utils/helper";
import TopLoadingBar from "../../utils/topLoadingBar";
import SettingMiniWebAddContentWeb from "./SettingMiniWebAddContentWeb";
import SettingMiniWebItemContentWeb from "./SettingMiniWebItemContentWeb";
import SettingMiniWebContext from "../../context/SettingMiniWebContext/SettingMiniWebContext";
import { useParams } from "react-router";
import SettingMiniWebItemWebNavBarMap from "./SettingMiniWebItemWebNavBarMap";
import SettingMiniWebAddWebNavBarMap from "./SettingMiniWebAddWebNavBarMap";
import SettingMiniWebItemWebConfigPage from "./SettingMiniWebItemWebConfigPage";
import SettingMiniWebAddWebConfigPage from "./SettingMiniWebAddWebConfigPage";

const SettingMiniWeb = () => {
  const {
    sortOrder,
    pageSize,
    page,
    sortBy,
    progress,
    setProgress,
    filter,
    WebContentMap_List,
    formvalue,
    modal,

    modalNav,
    pageNumberNav,
    pageSizeNav,
    sortOrderNav,
    sortByNav,
    WebNavBarMap_List,

    pageNumberConfig,
    pageSizeConfig,
    sortOrderConfig,
    sortByConfig,
    modalConfig,
    WebConfigPage_List,
  } = useContext(SettingMiniWebContext);

  /**useEffect to use of Organization() function call and toploading call to use  */
  let { orgId } = useParams();
  useEffect(() => {
    WebContentMap_List(orgId);
    setProgress(100);
  }, [page, pageSize, sortOrder, sortBy, filter]);

  useEffect(() => {
    WebNavBarMap_List({ orgId: orgId });
  }, [pageNumberNav, pageSizeNav, sortOrderNav, sortByNav]);

  useEffect(() => {
    WebConfigPage_List(orgId);
  }, [pageNumberConfig, pageSizeConfig, sortOrderConfig, sortByConfig]);

  useMemo(() => {
    tableId(page, pageSize, formvalue);
  }, [page, pageSize, formvalue]);

  return (
    <Fragment>
      <Breadcrumbs parent="Settings" />
      <TopLoadingBar progress={progress} setProgress={setProgress} />
      <SettingMiniWebItemContentWeb
        WebContentMap_List={WebContentMap_List}
        orgId={orgId}
      />
      <SettingMiniWebItemWebNavBarMap
        WebNavBarMap_List={WebNavBarMap_List}
        orgId={orgId}
      />
      <SettingMiniWebItemWebConfigPage
        WebConfigPage_List={WebConfigPage_List}
        orgId={orgId}
      />
      {modal && <SettingMiniWebAddContentWeb />}
      {modalNav && <SettingMiniWebAddWebNavBarMap />}
      {modalConfig && <SettingMiniWebAddWebConfigPage />}
    </Fragment>
  );
};

export default SettingMiniWeb;
