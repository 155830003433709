import React, { useContext } from "react";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import { Border, TextAlign, fontWeight } from "../../../../constant";
import FooterContext from "../../../context/FooterContext/FooterContext";
import { footerStyle2Reset } from "../../../constant";
import { SketchPicker } from "react-color";
import CustomStyle from "../../../common/CustomStyle";

const FooterStyleTwoPopup = (props) => {
  const { isEdit, setFormData, formData } = useContext(FooterContext);
  const { submitForm } = props;

  /**
   * input value change
   * @param {event} e
   */

  const handleChangeCustomFooterCornerInverseBeforeAfter = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        footer2Style: {
          ...prev.footer2Style,
          ".custom-footer .corner.corner-inverse:before, .custom-footer .corner.corner-inverse:after":
            {
              ...prev.footer2Style[
                ".custom-footer .corner.corner-inverse:before, .custom-footer .corner.corner-inverse:after"
              ],
              [name]: value,
            },
        },
      };
    });
  };
  const handleChangeCustomFootContact = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        footer2Style: {
          ...prev.footer2Style,
          ".custom-footer .foot-contact": {
            ...prev.footer2Style[".custom-footer .foot-contact"],
            [name]: value,
          },
        },
      };
    });
  };
  const handleChangeCustomFootContactIcons = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        footer2Style: {
          ...prev.footer2Style,
          ".custom-footer .foot-contact .icons": {
            ...prev.footer2Style[".custom-footer .foot-contact .icons"],
            [name]: value,
          },
        },
      };
    });
  };
  const handleChangeCustomFootContactH6 = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        footer2Style: {
          ...prev.footer2Style,
          ".custom-footer .foot-contact h6": {
            ...prev.footer2Style[".custom-footer .foot-contact h6"],
            [name]: value,
          },
        },
      };
    });
  };
  const handleChangeCustomFootContactP = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        footer2Style: {
          ...prev.footer2Style,
          ".custom-footer .foot-contact p": {
            ...prev.footer2Style[".custom-footer .foot-contact p"],
            [name]: value,
          },
        },
      };
    });
  };
  const HandleComplete = ({ event, classIS, className }) => {
    setFormData((prev) => {
      return {
        ...prev,
        footer2Style: {
          ...prev.footer2Style,
          [classIS]: {
            ...prev.footer2Style[classIS],
            [className]: event.rgb,
          },
        },
      };
    });
  };
  const HandleOnChange = ({ event, classIS, className }) => {
    setFormData((prev) => {
      return {
        ...prev,
        footer2Style: {
          ...prev.footer2Style,
          [classIS]: {
            ...prev.footer2Style[classIS],
            [className]: event.rgb,
          },
        },
      };
    });
  };
  const handleChangeCustomStyle = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        footer2Style: {
          ...prev.footer2Style,
          [name]: value,
        },
      };
    });
  };
  const resetHandler = () => {
    setFormData({
      footer2Style: footerStyle2Reset,
    });
  };
  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="12">
            <div className="mt-4 d-flex flex-column justify-content-center position-relative">
              <div
                className="d-flex justify-content-between position-sticky"
                style={{ top: "100px", background: "white", zIndex: 999 }}
              >
                <h6>{isEdit ? "Edit" : "Add New"}</h6>
                <div className="d-flex gap-2">
                  <Button onClick={resetHandler}>Reset</Button>
                  <Button
                    onClick={() =>
                      submitForm({
                        formValidationCondition: "footerTowStyleForm",
                        isRestore: true,
                      })
                    }
                  >
                    Restore
                  </Button>
                  <Button
                    onClick={() =>
                      submitForm({
                        formValidationCondition: "footerTowStyleForm",
                        isClose: false,
                      })
                    }
                    color="success"
                  >
                    Save & View
                  </Button>
                </div>
              </div>
              <Row className="mt-4">
                <h4 className="mb-3">
                  Change custom footer 3 section background color
                </h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border Bottom (default : 50 solid light gray)"}
                  </Label>
                  <div className="d-flex ">
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="borderBtPx"
                        type="number"
                        placeholder="px"
                        value={formData.footer2Style?.[
                          ".custom-footer .corner.corner-inverse:before, .custom-footer .corner.corner-inverse:after"
                        ]?.["borderBtPx"]?.replace(/px/g, "")}
                        onChange={(e) =>
                          handleChangeCustomFooterCornerInverseBeforeAfter(e)
                        }
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <select
                        name="borderBtThik"
                        id="borderBtThik"
                        className="form-select"
                        value={
                          formData.footer2Style?.[
                            ".custom-footer .corner.corner-inverse:before, .custom-footer .corner.corner-inverse:after"
                          ]?.["borderBtThik"]
                        }
                        onChange={(e) =>
                          handleChangeCustomFooterCornerInverseBeforeAfter(e)
                        }
                        style={{ borderRadius: "0", color: "black" }}
                      >
                        <option value="">--select--</option>
                        {Border.map((elem) => {
                          return (
                            <option value={elem.border}>{elem.border}</option>
                          );
                        })}
                      </select>
                    </div>
                    <div>
                      <SketchPicker
                        color={
                          formData?.footer2Style?.[
                            ".custom-footer .corner.corner-inverse:before, .custom-footer .corner.corner-inverse:after"
                          ]?.["borderBtColor"]
                        }
                        onChangeComplete={(e) =>
                          HandleComplete({
                            event: e,
                            classIS:
                              ".custom-footer .corner.corner-inverse:before, .custom-footer .corner.corner-inverse:after",
                            className: "borderBtColor",
                          })
                        }
                        onChange={(e) =>
                          HandleOnChange({
                            event: e,
                            classIS:
                              ".custom-footer .corner.corner-inverse:before, .custom-footer .corner.corner-inverse:after",
                            className: "borderBtColor",
                          })
                        }
                      />
                    </div>
                  </div>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border Left (default : 50 solid white)"}
                  </Label>
                  <div className="d-flex">
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="borderLfPx"
                        type="number"
                        placeholder="px"
                        value={formData.footer2Style?.[
                          ".custom-footer .corner.corner-inverse:before, .custom-footer .corner.corner-inverse:after"
                        ]?.["borderLfPx"]?.replace(/vw/g, "")}
                        onChange={(e) =>
                          handleChangeCustomFooterCornerInverseBeforeAfter(e)
                        }
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <select
                        name="borderLfThik"
                        id="borderLfThik"
                        className="form-select"
                        value={
                          formData.footer2Style?.[
                            ".custom-footer .corner.corner-inverse:before, .custom-footer .corner.corner-inverse:after"
                          ]?.["borderLfThik"]
                        }
                        onChange={(e) =>
                          handleChangeCustomFooterCornerInverseBeforeAfter(e)
                        }
                        style={{ borderRadius: "0", color: "black" }}
                      >
                        <option value="">--select--</option>
                        {Border.map((elem) => {
                          return (
                            <option value={elem.border}>{elem.border}</option>
                          );
                        })}
                      </select>
                    </div>

                    <div>
                      <SketchPicker
                        color={
                          formData?.footer2Style?.[
                            ".custom-footer .corner.corner-inverse:before, .custom-footer .corner.corner-inverse:after"
                          ]?.["borderLfColor"]
                        }
                        onChangeComplete={(e) =>
                          HandleComplete({
                            event: e,
                            classIS:
                              ".custom-footer .corner.corner-inverse:before, .custom-footer .corner.corner-inverse:after",
                            className: "borderLfColor",
                          })
                        }
                        onChange={(e) =>
                          HandleOnChange({
                            event: e,
                            classIS:
                              ".custom-footer .corner.corner-inverse:before, .custom-footer .corner.corner-inverse:after",
                            className: "borderLfColor",
                          })
                        }
                      />
                    </div>
                  </div>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border Right (default : 50 solid white)"}
                  </Label>
                  <div className="d-flex ">
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="borderRiPx"
                        type="number"
                        placeholder="px"
                        value={formData.footer2Style?.[
                          ".custom-footer .corner.corner-inverse:before, .custom-footer .corner.corner-inverse:after"
                        ]?.["borderRiPx"]?.replace(/vw/g, "")}
                        onChange={(e) =>
                          handleChangeCustomFooterCornerInverseBeforeAfter(e)
                        }
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <select
                        name="borderRiThik"
                        id="borderRiThik"
                        className="form-select"
                        value={
                          formData.footer2Style?.[
                            ".custom-footer .corner.corner-inverse:before, .custom-footer .corner.corner-inverse:after"
                          ]?.["borderRiThik"]
                        }
                        onChange={(e) =>
                          handleChangeCustomFooterCornerInverseBeforeAfter(e)
                        }
                        style={{ borderRadius: "0", color: "black" }}
                      >
                        <option value="">--select--</option>
                        {Border.map((elem) => {
                          return (
                            <option value={elem.border}>{elem.border}</option>
                          );
                        })}
                      </select>
                    </div>

                    <div>
                      <SketchPicker
                        color={
                          formData?.footer2Style?.[
                            ".custom-footer .corner.corner-inverse:before, .custom-footer .corner.corner-inverse:after"
                          ]?.["borderRiColor"]
                        }
                        onChangeComplete={(e) =>
                          HandleComplete({
                            event: e,
                            classIS:
                              ".custom-footer .corner.corner-inverse:before, .custom-footer .corner.corner-inverse:after",
                            className: "borderRiColor",
                          })
                        }
                        onChange={(e) =>
                          HandleOnChange({
                            event: e,
                            classIS:
                              ".custom-footer .corner.corner-inverse:before, .custom-footer .corner.corner-inverse:after",
                            className: "borderRiColor",
                          })
                        }
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">Make change in custom footer contact</h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"background Color (default : white)"}
                  </Label>
                  <SketchPicker
                    color={
                      formData?.footer2Style?.[
                        ".custom-footer .foot-contact"
                      ]?.["background-color"]
                    }
                    onChangeComplete={(e) =>
                      HandleComplete({
                        event: e,
                        classIS: ".custom-footer .foot-contact",
                        className: "background-color",
                      })
                    }
                    onChange={(e) =>
                      HandleOnChange({
                        event: e,
                        classIS: ".custom-footer .foot-contact",
                        className: "background-color",
                      })
                    }
                  />
                </Col>
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding (default : 100)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="padding"
                    type="number"
                    placeholder="Padding"
                    value={formData.footer2Style?.[
                      ".custom-footer .foot-contact"
                    ]?.["padding"]
                      ?.split(" ")[0]
                      ?.replace(/px/g, "")}
                    onChange={(e) => handleChangeCustomFootContact(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Text Align (default : center)"}
                  </Label>
                  <select
                    name="text-align"
                    id="text-align"
                    className="form-control"
                    value={
                      formData.footer2Style?.[".custom-footer .foot-contact"]?.[
                        "text-align"
                      ]
                    }
                    onChange={(e) => handleChangeCustomFootContact(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {TextAlign.map((elem) => {
                      return (
                        <option value={elem["text-align"]}>
                          {elem["text-align"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">
                  Make change in custom footer contact | icons
                </h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"background Color (default : black)"}
                  </Label>
                  <SketchPicker
                    color={
                      formData?.footer2Style?.[
                        ".custom-footer .foot-contact .icons"
                      ]?.["background-color"]
                    }
                    onChangeComplete={(e) =>
                      HandleComplete({
                        event: e,
                        classIS: ".custom-footer .foot-contact .icons",
                        className: "background-color",
                      })
                    }
                    onChange={(e) =>
                      HandleOnChange({
                        event: e,
                        classIS: ".custom-footer .foot-contact .icons",
                        className: "background-color",
                      })
                    }
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border (default : 5 solid pink)"}
                  </Label>
                  <div className="d-flex ">
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="borderPx"
                        type="number"
                        placeholder="px"
                        value={formData.footer2Style?.[
                          ".custom-footer .foot-contact .icons"
                        ]?.["borderPx"]?.replace(/px/g, "")}
                        onChange={(e) => handleChangeCustomFootContactIcons(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <select
                        name="borderThik"
                        id="borderThik"
                        className="form-select"
                        value={
                          formData.footer2Style?.[
                            ".custom-footer .foot-contact .icons"
                          ]?.["borderThik"]
                        }
                        onChange={(e) => handleChangeCustomFootContactIcons(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      >
                        <option value="">--select--</option>
                        {Border.map((elem) => {
                          return (
                            <option value={elem.border}>{elem.border}</option>
                          );
                        })}
                      </select>
                    </div>

                    <div>
                      <SketchPicker
                        color={
                          formData?.footer2Style?.[
                            ".custom-footer .foot-contact .icons"
                          ]?.["borderColor"]
                        }
                        onChangeComplete={(e) =>
                          HandleComplete({
                            event: e,
                            classIS: ".custom-footer .foot-contact .icons",
                            className: "borderColor",
                          })
                        }
                        onChange={(e) =>
                          HandleOnChange({
                            event: e,
                            classIS: ".custom-footer .foot-contact .icons",
                            className: "borderColor",
                          })
                        }
                      />
                    </div>
                  </div>
                </Col>
                <Col md="2 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border Radius (default : 50)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="border-radius"
                    type="number"
                    placeholder="Border Radius"
                    value={formData.footer2Style?.[
                      ".custom-footer .foot-contact .icons"
                    ]?.["border-radius"]?.replace(/%/g, "")}
                    onChange={(e) => handleChangeCustomFootContactIcons(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding (default : 30)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="padding"
                    type="number"
                    placeholder="padding"
                    value={formData.footer2Style?.[
                      ".custom-footer .foot-contact .icons"
                    ]?.["padding"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeCustomFootContactIcons(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Height (default : 120)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="height"
                    type="number"
                    placeholder="height"
                    value={formData.footer2Style?.[
                      ".custom-footer .foot-contact .icons"
                    ]?.["height"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeCustomFootContactIcons(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Width (default : 120)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="width"
                    type="number"
                    placeholder="width"
                    value={formData.footer2Style?.[
                      ".custom-footer .foot-contact .icons"
                    ]?.["width"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeCustomFootContactIcons(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Margin Bottom (default : 20)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="margin-bottom"
                    type="number"
                    placeholder="Margin Bottom"
                    value={formData.footer2Style?.[
                      ".custom-footer .foot-contact .icons"
                    ]?.["margin-bottom"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeCustomFootContactIcons(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">
                  Make change in custom footer contact heading
                </h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Color (default : black)"}
                  </Label>
                  <SketchPicker
                    color={
                      formData?.footer2Style?.[
                        ".custom-footer .foot-contact h6"
                      ]?.["color"]
                    }
                    onChangeComplete={(e) =>
                      HandleComplete({
                        event: e,
                        classIS: ".custom-footer .foot-contact h6",
                        className: "color",
                      })
                    }
                    onChange={(e) =>
                      HandleOnChange({
                        event: e,
                        classIS: ".custom-footer .foot-contact h6",
                        className: "color",
                      })
                    }
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Size (default : 22)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="font-size"
                    type="number"
                    placeholder="Font Size"
                    value={formData.footer2Style?.[
                      ".custom-footer .foot-contact h6"
                    ]?.["font-size"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeCustomFootContactH6(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Weight (default : 600)"}
                  </Label>
                  <select
                    name="font-weight"
                    id="font-weight"
                    className="form-control"
                    value={
                      formData.footer2Style?.[
                        ".custom-footer .foot-contact h6"
                      ]?.["font-weight"]
                    }
                    onChange={(e) => handleChangeCustomFootContactH6(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {fontWeight.map((elem) => {
                      return (
                        <option value={elem["font-weight"]}>
                          {elem["font-weight"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Margin Bottom (default : 10)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="margin-bottom"
                    type="number"
                    placeholder="Margin Bottom"
                    value={formData.footer2Style?.[
                      ".custom-footer .foot-contact h6"
                    ]?.["margin-bottom"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeCustomFootContactH6(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">
                  Make change in custom footer contact paragraph
                </h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Color (default : black)"}
                  </Label>
                  <SketchPicker
                    color={
                      formData?.footer2Style?.[
                        ".custom-footer .foot-contact p"
                      ]?.["color"]
                    }
                    onChangeComplete={(e) =>
                      HandleComplete({
                        event: e,
                        classIS: ".custom-footer .foot-contact p",
                        className: "color",
                      })
                    }
                    onChange={(e) =>
                      HandleOnChange({
                        event: e,
                        classIS: ".custom-footer .foot-contact p",
                        className: "color",
                      })
                    }
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Size (default : 16)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="font-size"
                    type="number"
                    placeholder="Font Size"
                    value={formData.footer2Style?.[
                      ".custom-footer .foot-contact p"
                    ]?.["font-size"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeCustomFootContactP(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Weight (default : 600)"}
                  </Label>
                  <select
                    name="font-weight"
                    id="font-weight"
                    className="form-control"
                    value={
                      formData.footer2Style?.[
                        ".custom-footer .foot-contact p"
                      ]?.["font-weight"]
                    }
                    onChange={(e) => handleChangeCustomFootContactP(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {fontWeight.map((elem) => {
                      return (
                        <option value={elem["font-weight"]}>
                          {elem["font-weight"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
              </Row>
              <CustomStyle
                handleChange={handleChangeCustomStyle}
                value={formData?.footer2Style?.custom}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default FooterStyleTwoPopup;
