import React, { useContext } from "react";
import { Col, Container, Input, Label, Row } from "reactstrap";
import { options } from "../../../constant";
import GetWhatsAppLinkContext from "../../../context/GetWhatsAppLinkContext/GetWhatsAppLinkContext";
import Select from "react-select";
import IsShowTitleORNot from "../../../common/isShowTitleORNot";

const GetWhatsAppLinkParentPopup = (props) => {
  const { setFormData, fromData, isEdit } = useContext(GetWhatsAppLinkContext);

  const { simpleValidator } = props;

  /**
   * input value change
   * @param {event} e
   */
  const changeFormValue = (e) => {
    const { value, name, checked } = e.target;
    setFormData((prev) => {
      if (name === "isTitleShow") {
        return {
          ...prev,
          [name]: checked,
        };
      } else {
        return {
          ...prev,
          [name]: value,
        };
      }
    });
  };

  const changeCurrency = (name, e) => {
    setFormData((prev) => {
      return {
        ...prev,
        [e.name]: name,
      };
    });
  };

  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="12">
            <div className="mt-4 d-flex flex-column justify-content-center ">
              <h6>{isEdit ? "Edit" : "Add New"}</h6>
              <Row className="mt-4">
                <Col md="6 mb-3">
                  <Label htmlFor="validationCustom03">
                    Title *{" "}
                    <IsShowTitleORNot
                      checked={fromData?.isTitleShow}
                      onChange={changeFormValue}
                    />{" "}
                  </Label>
                  <Input
                    className="form-control"
                    name="title"
                    type="text"
                    placeholder="Title *"
                    value={fromData?.title}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    required
                  />
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "Title",
                      fromData?.title,
                      "required"
                    )}
                  </p>
                </Col>

                <Col md="6 mb-3">
                  <Label htmlFor="validationCustom03">{"Sub Title"}</Label>
                  <Input
                    className="form-control"
                    name="subTitle"
                    type="text"
                    placeholder="Sub Title "
                    value={fromData?.subTitle}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Short Description (optional)"}
                  </Label>
                  <textarea
                    className="form-control"
                    name="description"
                    type="text"
                    placeholder="Description  "
                    value={fromData?.description}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    required
                  />
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Whatsapp default message"}
                  </Label>
                  <Input
                    className="form-control"
                    name="linkText"
                    type="text"
                    placeholder="whatsapp Default message"
                    value={fromData?.linkText}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    required
                  />
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Whatsapp Submit Button Text"}
                  </Label>
                  <Input
                    className="form-control"
                    name="buttonText"
                    type="text"
                    placeholder="Button Text *"
                    value={fromData?.buttonText}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    required
                  />
                </Col>
                {/* <Col md="6 mb-3">
                  <Label htmlFor="validationCustom03">{"Button Value *"}</Label>
                  <Input
                    className="form-control"
                    name="buttonValue"
                    type="text"
                    placeholder="Button Value *"
                    value={fromData?.buttonValue}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    required
                  />
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "Button Value",
                      fromData?.buttonValue,
                      "required"
                    )}
                  </p>
                </Col> */}
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Dial code *"}</Label>
                  <Select
                    options={options}
                    name="defaultCountry"
                    value={fromData.defaultCountry}
                    onChange={(prev, e) => changeCurrency(prev, e)}
                  />
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "Currency",
                      fromData.defaultCountry,
                      "required"
                    )}
                  </p>
                </Col>

                {/* <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Theme"}</Label>
                  <select
                    name="theme"
                    id="theme"
                    className="form-select"
                    onChange={(e) => changeFormValue(e)}
                    value={fromData?.theme}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    {ServiceCounterTheme.map((ele) => {
                      return (
                        <option value={ele.theme} key={ele.theme}>
                          {ele.themeName}
                        </option>
                      );
                    })}
                  </select>
                </Col> */}
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default GetWhatsAppLinkParentPopup;
