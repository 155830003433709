import React, { useContext } from "react";
import ServiceAndCategoryContext from "../../../context/ServiceAndCatagoryContext/ServiceAndCategoryContext";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import { Cases, DELETE_CONFIRM } from "../../../constant";
import {
  delete_Image_Permanent,
  delete_Multi_Image_Permanent,
} from "../apis/SliderApi/SliderApi";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import { Zoom, toast } from "react-toastify";
import Switch from "@material-ui/core/Switch";
import Dividers from "../../../common/divider";
import { OpenModalCommonFunc } from "../../../utils";
import { Close } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { UploadCloud } from "react-feather";
import { SketchPicker } from "react-color";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import { Border, fontWeight } from "../../../../constant";
import CommonColor from "../../../common/CommonColor";

const ServiceCategoryDetailStyle = (props) => {
  const {
    isEdit,
    settingFormData,
    setSettingFormData,
    backImageToggleDetail,
    setBackImageToggleDetail,
    setBoxSectionDetail,
    boxSectionDetail,
    setIsImageUpload,
    isBackgroundColor2,
    setIsBackgroundColor2,
  } = useContext(ServiceAndCategoryContext);
  const { setLoaderShow } = useContext(LoaderContext);
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const { imageUpload } = props;
  const handleChangeServiceDetailSection = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".service-detail-section"]: {
          ...prev[".service-detail-section"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeSectionDetailSLider = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".detail-sidebar"]: {
          ...prev[".detail-sidebar"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeDetailSliderLinkLiA = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".detail-sidebar .other-link li a"]: {
          ...prev[".detail-sidebar .other-link li a"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeSectionDetailSLiderLinkLiFa = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".detail-sidebar .other-link li .fa"]: {
          ...prev[".detail-sidebar .other-link li .fa"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeDetailSliderLinkLiAHover = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a"]:
          {
            ...prev[
              ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a"
            ],
            [name]: value,
          },
      };
    });
  };
  const handleChangeSectionDetailSLiderWidgetForm = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".detail-sidebar .widget-form"]: {
          ...prev[".detail-sidebar .widget-form"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeSectionDetailWidgetFromControl = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".detail-sidebar .widget-form .form-control"]: {
          ...prev[".detail-sidebar .widget-form .form-control"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeSectionDetailWidgetFromBtnSubmit = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".detail-sidebar .widget-form .btn-submit"]: {
          ...prev[".detail-sidebar .widget-form .btn-submit"],
          [name]: value,
        },
      };
    });
  };

  /**
   * delete image permanently
   */
  const deleteImagePermanent = async () => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData = {
          fileName: settingFormData?.[".service-detail-section.bg-image"]?.[
            "background-image"
          ]
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
            .pop(),
        };
        const { data } = await delete_Multi_Image_Permanent(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            position: "top-center",
          });
          setSettingFormData({
            ...settingFormData,
            ".service-detail-section.bg-image": { "background-image": "" },
          });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };
  /**
   * image reupload
   */
  const reupload = () => {
    setSettingFormData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };
  return (
    <>
      <Row className="mt-4">
        <h4 className="mb-3">Service Category Detail Style :</h4>
        <Row className="mt-4">
          <h4 className="mb-3">Make change in main Service Detail section</h4>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Padding (default : 100)"}
            </Label>
            <Input
              className="form-control"
              name="padding"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[".service-detail-section"]?.["padding"]
                ?.split(" ")[0]
                ?.replace(/px/g, "")}
              onChange={(e) => handleChangeServiceDetailSection(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
          <Col md="4 mb-3">
            <div className="d-flex align-items-center">
              <Label htmlFor="validationCustom03">
                {isBackgroundColor2
                  ? "Add Background Color"
                  : "Remove Background Color"}
              </Label>
              <Switch
                checked={isBackgroundColor2}
                inputProps={{ "aria-label": "controlled" }}
                onChange={(e) => {
                  setIsBackgroundColor2(e.target.checked);
                  setSettingFormData((prev) => {
                    if (e.target.checked) {
                      if (boxSectionDetail) {
                        return {
                          ...prev,
                          [".service-detail-section"]: {
                            "background-color": {
                              r: 255,
                              g: 255,
                              b: 255,
                              a: 100,
                            },
                            padding:
                              settingFormData?.[".service-detail-section"]?.[
                                "padding"
                              ],
                            width: settingFormData?.[
                              ".service-detail-section"
                            ]?.["width"]?.replace("rem)", ""),
                            margin: settingFormData?.[
                              ".service-detail-section"
                            ]?.["margin"]
                              ?.split(" ")[0]
                              ?.replace(/px/g, ""),
                            "border-radius": settingFormData?.[
                              ".service-detail-section"
                            ]?.["border-radius"]?.replace(/rem/g, ""),
                          },
                        };
                      } else {
                        return {
                          ...prev,
                          [".service-detail-section"]: {
                            "background-color": {
                              r: 255,
                              g: 255,
                              b: 255,
                              a: 100,
                            },
                            padding:
                              settingFormData?.[".service-detail-section"]?.[
                                "padding"
                              ],
                          },
                        };
                      }
                    } else {
                      if (boxSectionDetail) {
                        return {
                          ...prev,
                          [".service-detail-section"]: {
                            padding:
                              settingFormData?.[".service-detail-section"]?.[
                                "padding"
                              ],
                            width: settingFormData?.[
                              ".service-detail-section"
                            ]?.["width"]?.replace("rem)", ""),
                            margin: settingFormData?.[
                              ".service-detail-section"
                            ]?.["margin"]
                              ?.split(" ")[0]
                              ?.replace(/px/g, ""),
                            "border-radius": settingFormData?.[
                              ".service-detail-section"
                            ]?.["border-radius"]?.replace(/rem/g, ""),
                          },
                        };
                      } else {
                        return {
                          ...prev,
                          [".service-detail-section"]: {
                            padding:
                              settingFormData?.[".service-detail-section"]?.[
                                "padding"
                              ],
                          },
                        };
                      }
                    }
                  });
                }}
              />
            </div>
            {isBackgroundColor2 && (
              <CommonColor
                color={
                  settingFormData?.[".service-detail-section"]?.[
                    "background-color"
                  ]
                }
                setSettingFormData={setSettingFormData}
                className={".service-detail-section"}
                classProperty={"background-color"}
                label={"Select Background Color"}
              />
            )}
          </Col>
          <p>{"Box Section : convert your Detail section to Box section"}</p>
          <div>
            <Switch
              checked={boxSectionDetail}
              inputProps={{ "aria-label": "controlled" }}
              onChange={(e) => {
                setBoxSectionDetail(e.target.checked);
                setSettingFormData((prev) => {
                  return {
                    ...prev,
                    ".service-detail-section": {
                      padding: settingFormData?.[".service-detail-section"]?.[
                        "padding"
                      ]
                        ?.split(" ")[0]
                        ?.replace(/px/g, ""),
                      "background-color":
                        settingFormData?.[".service-detail-section"]?.[
                          "background-color"
                        ],
                    },
                  };
                });
              }}
            />
          </div>
          {boxSectionDetail && (
            <>
              <Col md="4 mb-3">
                <Label htmlFor="validationCustom03">
                  {"Width (Default : 10)"}
                </Label>
                <Input
                  className="form-control"
                  name="width"
                  type="number"
                  placeholder="width"
                  value={settingFormData?.[".service-detail-section"]?.[
                    "width"
                  ]?.replace("rem)", "")}
                  onChange={(e) => handleChangeServiceDetailSection(e)}
                  style={{ borderRadius: "0", color: "black" }}
                />
              </Col>
              <Col md="4 mb-3">
                <Label htmlFor="validationCustom03">
                  {"Margin (Default : 0)"}
                </Label>
                <Input
                  className="form-control"
                  name="margin"
                  type="number"
                  placeholder="margin"
                  value={settingFormData?.[".service-detail-section"]?.[
                    "margin"
                  ]
                    ?.split(" ")[0]
                    ?.replace(/px/g, "")}
                  onChange={(e) => handleChangeServiceDetailSection(e)}
                  style={{ borderRadius: "0", color: "black" }}
                />
              </Col>
              <Col md="4 mb-3">
                <Label htmlFor="validationCustom03">
                  {"Border Radius (Default : 3)"}
                </Label>
                <Input
                  className="form-control"
                  name="border-radius"
                  type="number"
                  placeholder="Border Radius"
                  value={settingFormData?.[".service-detail-section"]?.[
                    "border-radius"
                  ]?.replace(/rem/g, "")}
                  onChange={(e) => handleChangeServiceDetailSection(e)}
                  style={{ borderRadius: "0", color: "black" }}
                />
              </Col>
            </>
          )}
        </Row>
        <hr />
        <Row className="mt-4">
          <Col md="12 mb-3">
            <h4 className="mb-3">Add Background Image in Detail section </h4>

            <p>do you want to upload Background image</p>
            <Switch
              checked={backImageToggleDetail}
              inputProps={{ "aria-label": "controlled" }}
              onChange={(e) => {
                setBackImageToggleDetail(e.target.checked);
                setSettingFormData((prev) => {
                  if (e.target.checked) {
                    return {
                      ...prev,
                      image: "",
                      ".service-detail-section.bg-image": {},
                      ".service-detail-section.bg-image::before": {
                        "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
                      },
                    };
                  } else {
                    return {
                      ...prev,
                      image: "",
                      ".service-detail-section.bg-image": {},
                      ".service-detail-section.bg-image::before": {},
                    };
                  }
                });
              }}
            />
            {backImageToggleDetail && (
              <>
                {" "}
                <Col md="6 mb-3">
                  <Dividers divide="IMAGE" />
                  <p className="mb-4">
                    File type required JPG, PNG (1905px X 986px)
                  </p>
                </Col>
                <Row>
                  <Col md="4 mb-3">
                    <div
                      className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                      style={{ cursor: "pointer" }}
                    >
                      {!settingFormData?.[".service-detail-section.bg-image"]?.[
                        "background-image"
                      ] && (
                        <span
                          onClick={() => {
                            OpenModalCommonFunc(
                              Cases.PRODUCT_UPLOAD,
                              setAllPopupState
                            );
                            setSettingFormData((prev) => {
                              return {
                                ...prev,
                                image:
                                  settingFormData?.[
                                    ".service-detail-section.bg-image"
                                  ]?.["background-image"],
                              };
                            });
                            setIsImageUpload((prev) => {
                              return {
                                isList: false,
                                isDetails: true,
                              };
                            });
                          }}
                        >
                          <UploadCloud />
                        </span>
                      )}

                      {settingFormData?.[".service-detail-section.bg-image"]?.[
                        "background-image"
                      ] && (
                        <img
                          src={
                            settingFormData?.[
                              ".service-detail-section.bg-image"
                            ]?.["background-image"] || ""
                          }
                          alt=""
                          style={{
                            width: "85%",
                            height: "90px",
                            objectFit: "contain",
                            position: "absolute",
                          }}
                          onClick={() => {
                            deleteImagePermanent();
                          }}
                        />
                      )}
                      {settingFormData?.[".service-detail-section.bg-image"]?.[
                        "background-image"
                      ] && (
                        <IconButton
                          edge="start"
                          color="inherit"
                          aria-label="close"
                          style={{
                            color: "red",
                            position: "absolute",
                            top: "-22px",
                            left: "1px",
                          }}
                          onClick={() => deleteImagePermanent()}
                        >
                          <Close
                            style={{
                              fontSize: "25px",
                              fontWeight: "bold",
                            }}
                          />
                        </IconButton>
                      )}
                    </div>
                  </Col>
                  <Col md="6 mb-3">
                    <h4 className="mb-3">
                      Add Background color in Detail section
                    </h4>
                    <h4 className="mb-3"></h4>
                    <Col md="6 mb-3">
                      <CommonColor
                        color={
                          settingFormData?.[
                            ".service-detail-section.bg-image::before"
                          ]?.["background-color"]
                        }
                        setSettingFormData={setSettingFormData}
                        className={".service-detail-section.bg-image::before"}
                        classProperty={"background-color"}
                        label={"Select Background Color"}
                      />
                    </Col>
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
        <hr />
        <Row className="mt-2">
          <h4 className="mb-3">Change detail sidebar size</h4>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"width (Default : 100)"}
            </Label>
            <Input
              className="form-control"
              name="width"
              type="number"
              placeholder="width"
              value={settingFormData?.[".detail-sidebar"]?.["width"]?.replace(
                /%/g,
                ""
              )}
              onChange={(e) => handleChangeSectionDetailSLider(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
        </Row>
        <hr />
        <Row className="mt-2">
          <h4 className="mb-3">Make change in detail sidebar list</h4>
          <Col md="4 mb-3">
            <CommonColor
              color={
                settingFormData?.[".detail-sidebar .other-link li a"]?.[
                  "background-color"
                ]
              }
              setSettingFormData={setSettingFormData}
              className={".detail-sidebar .other-link li a"}
              classProperty={"background-color"}
              label={"Select Background Color"}
            />
          </Col>
          <Col md="4 mb-3">
            <CommonColor
              color={
                settingFormData?.[".detail-sidebar .other-link li a"]?.["color"]
              }
              setSettingFormData={setSettingFormData}
              className={".detail-sidebar .other-link li a"}
              classProperty={"color"}
              label={"Select Color"}
            />
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Padding (Default : 15 - 20)"}
            </Label>
            <div className="d-flex justify-content-between">
              <Input
                className="form-control"
                name="p1"
                type="number"
                placeholder="Padding"
                value={settingFormData?.[".detail-sidebar .other-link li a"]?.[
                  "p1"
                ]?.replace(/px/g, "")}
                onChange={(e) => handleChangeDetailSliderLinkLiA(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
              <Input
                className="form-control"
                name="p2"
                type="number"
                placeholder="Padding"
                value={settingFormData?.[".detail-sidebar .other-link li a"]?.[
                  "p2"
                ]?.replace(/px/g, "")}
                onChange={(e) => handleChangeDetailSliderLinkLiA(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Font Size (Default : 16)"}
            </Label>
            <Input
              className="form-control"
              name="font-size"
              type="number"
              placeholder="Font Size"
              value={settingFormData?.[".detail-sidebar .other-link li a"]?.[
                "font-size"
              ]?.replace(/px/g, "")}
              onChange={(e) => handleChangeDetailSliderLinkLiA(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Border (Default : 1 solid sky blue)"}
            </Label>
            <div className="d-flex">
              <div style={{ width: "100px" }}>
                <Input
                  className="form-control"
                  name="borderPx"
                  type="number"
                  placeholder="px"
                  value={settingFormData?.[
                    ".detail-sidebar .other-link li a"
                  ]?.["borderPx"]?.replace(/px/g, "")}
                  onChange={(e) => handleChangeDetailSliderLinkLiA(e)}
                  style={{ borderRadius: "0", color: "black" }}
                />
              </div>
              <div style={{ width: "100px" }}>
                <select
                  name="borderThik"
                  id="borderThik"
                  className="form-control"
                  value={
                    settingFormData?.[".detail-sidebar .other-link li a"]?.[
                      "borderThik"
                    ]
                  }
                  onChange={(e) => handleChangeDetailSliderLinkLiA(e)}
                  style={{ borderRadius: "0", color: "black" }}
                >
                  <option value="">--select--</option>
                  {Border.map((elem) => {
                    return (
                      <option value={elem.border} key={elem.border}>
                        {elem.border}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                <CommonColor
                  color={
                    settingFormData?.[".detail-sidebar .other-link li a"]?.[
                      "borderColor"
                    ]
                  }
                  setSettingFormData={setSettingFormData}
                  className={".detail-sidebar .other-link li a"}
                  classProperty={"borderColor"}
                  label={"Border Color"}
                />
              </div>
            </div>
          </Col>
        </Row>
        <hr />
        <Row className="mt-2">
          <h4 className="mb-3">
            Make change in detail sidebar list arrow icon
          </h4>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Margin Right (Default : 10)"}
            </Label>
            <Input
              className="form-control"
              name="margin-right"
              type="number"
              placeholder="Margin Right"
              value={settingFormData?.[".detail-sidebar .other-link li .fa"]?.[
                "margin-right"
              ]?.replace(/px/g, "")}
              onChange={(e) => handleChangeSectionDetailSLiderLinkLiFa(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
        </Row>
        <hr />
        <Row className="mt-2">
          <h4 className="mb-3">Make change in detail sidebar Active list</h4>
          <Col md="4 mb-3">
            <CommonColor
              color={
                settingFormData?.[
                  ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a"
                ]?.["color"]
              }
              setSettingFormData={setSettingFormData}
              className={
                ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a"
              }
              classProperty={"color"}
              label={"Select Color"}
            />
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Border Left (Default : 5 solid pink)"}
            </Label>
            <div className="d-flex">
              <div style={{ width: "100px" }}>
                <Input
                  className="form-control"
                  name="borderLfPx"
                  type="number"
                  placeholder="px"
                  value={settingFormData?.[
                    ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a"
                  ]?.["borderLfPx"]?.replace(/px/g, "")}
                  onChange={(e) => handleChangeDetailSliderLinkLiAHover(e)}
                  style={{ borderRadius: "0", color: "black" }}
                />
              </div>
              <div style={{ width: "100px" }}>
                <select
                  name="borderLfThik"
                  id="borderLfThik"
                  className="form-control"
                  value={
                    settingFormData?.[
                      ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a"
                    ]?.["borderLfThik"]
                  }
                  onChange={(e) => handleChangeDetailSliderLinkLiAHover(e)}
                  style={{ borderRadius: "0", color: "black" }}
                >
                  <option value="">--select--</option>
                  {Border.map((elem) => {
                    return (
                      <option value={elem.border} key={elem.border}>
                        {elem.border}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                <CommonColor
                  color={
                    settingFormData?.[
                      ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a"
                    ]?.["borderLfColor"]
                  }
                  setSettingFormData={setSettingFormData}
                  className={
                    ".detail-sidebar .other-link li a:hover, .detail-sidebar .other-link li.active a"
                  }
                  classProperty={"borderLfColor"}
                  label={"Border Color"}
                />
              </div>
            </div>
          </Col>
        </Row>
        <hr />
        <Row className="mt-2">
          <h4 className="mb-3">Make change in detail sidebar widget-form</h4>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"width (Default : 100)"}
            </Label>
            <Input
              className="form-control"
              name="width"
              type="number"
              placeholder="width"
              value={settingFormData?.[".detail-sidebar .widget-form"]?.[
                "width"
              ]?.replace(/%/g, "")}
              onChange={(e) => handleChangeSectionDetailSLiderWidgetForm(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Padding (Default : 25 - 35)"}
            </Label>
            <div className="d-flex justify-content-between">
              <Input
                className="form-control"
                name="p1"
                type="number"
                placeholder="Padding"
                value={settingFormData?.[".detail-sidebar .widget-form"]?.[
                  "p1"
                ]?.replace(/px/g, "")}
                onChange={(e) => handleChangeSectionDetailSLiderWidgetForm(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
              <Input
                className="form-control"
                name="p2"
                type="number"
                placeholder="Padding"
                value={settingFormData?.[".detail-sidebar .widget-form"]?.[
                  "p2"
                ]?.replace(/px/g, "")}
                onChange={(e) => handleChangeSectionDetailSLiderWidgetForm(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
          </Col>
          <Col md="4 mb-3">
            <CommonColor
              color={
                settingFormData?.[".detail-sidebar .widget-form"]?.[
                  "background-color"
                ]
              }
              setSettingFormData={setSettingFormData}
              className={".detail-sidebar .widget-form"}
              classProperty={"background-color"}
              label={"Select Background Color"}
            />
          </Col>
        </Row>
        <hr />
        <Row className="mt-2">
          <h4 className="mb-3">
            Make change in detail sidebar widget-form form-control
          </h4>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Padding (Default : 13)"}
            </Label>
            <Input
              className="form-control"
              name="padding"
              type="number"
              placeholder="padding"
              value={settingFormData?.[
                ".detail-sidebar .widget-form .form-control"
              ]?.["padding"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeSectionDetailWidgetFromControl(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
        </Row>
        <hr />
        <Row className="mt-2">
          <h4 className="mb-3">
            "Make change in detail sidebar widget-form button submit"
          </h4>
          <Col md="4 mb-3">
            <CommonColor
              color={
                settingFormData?.[".detail-sidebar .widget-form .btn-submit"]?.[
                  "background-color"
                ]
              }
              setSettingFormData={setSettingFormData}
              className={".detail-sidebar .widget-form .btn-submit"}
              classProperty={"background-color"}
              label={"Select Background Color"}
            />
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Border (Default : 2 solid pink)"}
            </Label>
            <div className="d-flex ">
              <div style={{ width: "100px" }}>
                <Input
                  className="form-control"
                  name="borderPx"
                  type="number"
                  placeholder="px"
                  value={settingFormData?.[
                    ".detail-sidebar .widget-form .btn-submit"
                  ]?.["borderPx"]?.replace(/px/g, "")}
                  onChange={(e) =>
                    handleChangeSectionDetailWidgetFromBtnSubmit(e)
                  }
                  style={{ borderRadius: "0", color: "black" }}
                />
              </div>
              <div style={{ width: "100px" }}>
                <select
                  name="borderThik"
                  id="borderThik"
                  className="form-control"
                  value={
                    settingFormData?.[
                      ".detail-sidebar .widget-form .btn-submit"
                    ]?.["borderThik"]
                  }
                  onChange={(e) =>
                    handleChangeSectionDetailWidgetFromBtnSubmit(e)
                  }
                  style={{ borderRadius: "0", color: "black" }}
                >
                  <option value="">--select--</option>
                  {Border.map((elem) => {
                    return (
                      <option value={elem.border} key={elem.border}>
                        {elem.border}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                <CommonColor
                  color={
                    settingFormData?.[
                      ".detail-sidebar .widget-form .btn-submit"
                    ]?.["borderColor"]
                  }
                  setSettingFormData={setSettingFormData}
                  className={".detail-sidebar .widget-form .btn-submit"}
                  classProperty={"borderColor"}
                  label={"Border Color"}
                />
              </div>
            </div>
          </Col>
          <Col md="4 mb-3">
            <CommonColor
              color={
                settingFormData?.[".detail-sidebar .widget-form .btn-submit"]?.[
                  "color"
                ]
              }
              setSettingFormData={setSettingFormData}
              className={".detail-sidebar .widget-form .btn-submit"}
              classProperty={"color"}
              label={"Select Color"}
            />
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Font Size (Default : 16)"}
            </Label>
            <Input
              className="form-control"
              name="font-size"
              type="number"
              placeholder="Font Size"
              value={settingFormData?.[
                ".detail-sidebar .widget-form .btn-submit"
              ]?.["font-size"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeSectionDetailWidgetFromBtnSubmit(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Font Weight (Default : 600)"}
            </Label>
            <select
              name="font-weight"
              id="font-weight"
              className="form-select"
              value={
                settingFormData?.[".detail-sidebar .widget-form .btn-submit"]?.[
                  "font-weight"
                ]
              }
              onChange={(e) => handleChangeSectionDetailWidgetFromBtnSubmit(e)}
              style={{ borderRadius: "0", color: "black" }}
            >
              <option value="">--select--</option>
              {fontWeight.map((elem) => {
                return (
                  <option value={elem["font-weight"]} key={elem["font-weight"]}>
                    {elem["font-weight"]}
                  </option>
                );
              })}
            </select>
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Padding (Default : 8 - 25)"}
            </Label>
            <div className="d-flex justify-content-between">
              <Input
                className="form-control"
                name="p1"
                type="number"
                placeholder="Padding"
                value={settingFormData?.[
                  ".detail-sidebar .widget-form .btn-submit"
                ]?.["p1"]?.replace(/px/g, "")}
                onChange={(e) =>
                  handleChangeSectionDetailWidgetFromBtnSubmit(e)
                }
                style={{ borderRadius: "0", color: "black" }}
              />
              <Input
                className="form-control"
                name="p2"
                type="number"
                placeholder="Padding"
                value={settingFormData?.[
                  ".detail-sidebar .widget-form .btn-submit"
                ]?.["p2"]?.replace(/px/g, "")}
                onChange={(e) =>
                  handleChangeSectionDetailWidgetFromBtnSubmit(e)
                }
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Width (Default : 100)"}
            </Label>
            <Input
              className="form-control"
              name="width"
              type="number"
              placeholder="width"
              value={settingFormData?.[
                ".detail-sidebar .widget-form .btn-submit"
              ]?.["width"]?.replace(/%/g, "")}
              onChange={(e) => handleChangeSectionDetailWidgetFromBtnSubmit(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
        </Row>
      </Row>
      {/* <ProductImageUploadPopup
        allPopupState={allPopupState}
        setAllPopupState={setAllPopupState}
        crop={crop}
        setCrop={setCrop}
        completedCrop={completedCrop}
        setCompletedCrop={setCompletedCrop}
        scale={scale}
        rotate={rotate}
        image={settingFormData?.image}
        setImage={setSettingFormData}
        imageUpload={imageUpload}
        isEdit={isEdit}
        reupload={reupload}
        aspect={1.93 / 1}
      /> */}
    </>
  );
};

export default ServiceCategoryDetailStyle;
