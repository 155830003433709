import moment from "moment-timezone";
import React from "react";
import {
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { MOMENT_SETTING } from "../../../constant";

const InquiaryShowPopup = (props) => {
  const { model, setModel, detail } = props;
  let JsonParseValue = detail?.other && JSON.parse(detail?.other);
  return (
    <Modal size="lg" isOpen={model} toggle={() => setModel(!model)}>
      <ModalHeader toggle={() => setModel(!model)}>Inquary</ModalHeader>
      <ModalBody>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody style={{ padding: "0" }}>
                <Card>
                  <div className="table-responsive">
                    <Table>
                      <tbody>
                        <tr>
                          <th scope="row">{"Date & Time"}</th>
                          <td>
                            {detail?.createdAt &&
                              moment
                                .utc(detail?.createdAt)
                                .format(MOMENT_SETTING.FORMATE_WITH_TIME)}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">{"Name"}</th>
                          <td>{detail?.name ? detail?.name : "-"}</td>
                        </tr>
                        <tr>
                          <th scope="row">{"Email"}</th>
                          <td>{detail?.email ? detail?.email : "-"}</td>
                        </tr>
                        <tr>
                          <th scope="row">{"Contact"}</th>
                          <td>{detail?.mobile ? detail?.mobile : "-"}</td>
                        </tr>
                        <tr>
                          <th scope="row">{"GST In"}</th>
                          <td>
                            <div style={{ width: "27vw" }}>
                              {JsonParseValue?.gstIn
                                ? JsonParseValue?.gstIn
                                : "-"}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">{"Product Quantity"}</th>
                          <td>
                            <div style={{ width: "27vw" }}>
                              {JsonParseValue?.product_inquiry
                                ? JsonParseValue?.product_inquiry
                                : "-"}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">{"Remark"}</th>
                          <td>
                            <div style={{ width: "27vw" }}>
                              {JsonParseValue?.remark
                                ? JsonParseValue?.remark
                                : "-"}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">{"Address"}</th>
                          <td>
                            <div style={{ width: "27vw" }}>
                              {detail?.message ? detail?.message : "-"}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Card>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default InquiaryShowPopup;
