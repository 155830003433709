import axios from "axios";
import {
  GET_LATEST_NEWS,
  GET_LATEST_NEWS_DETAILS,
  POST_LATEST_NEWS,
  PUT_LATEST_NEWS_ORDER,
  PUT_STATUS_LATEST_NEWS,
  GET_NAVBAR,
} from "..";

/**
 * navbar list
 * @param {parameters} paramObj
 * @returns
 */

export const getNavbarList = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_NAVBAR}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * get latest news
 * @param {Parameters} paramObj
 * @returns
 */
export const getLatestNews = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_LATEST_NEWS}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * enable-disable latest news
 * @param {body} body
 * @returns
 */
export const updateStatus = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_STATUS_LATEST_NEWS}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * create latest news
 * @param {body} body
 * @returns
 */
export const postLatestNews = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${POST_LATEST_NEWS}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * update latest news
 * @param {body} body
 * @returns
 */
export const putUpdateLatestNews = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${POST_LATEST_NEWS}`,
    body,
    {
      headers: { Authorization: jwt_token },
      params: { latestNewsId: body.latestNewsId },
    }
  );
};

/**
 * delete latest news
 * @param {id} latestNewsId
 * @returns
 */
export const deleteLatestNews = (latestNewsId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${POST_LATEST_NEWS}`, {
    headers: { Authorization: jwt_token },
    params: { latestNewsId },
  });
};

/**
 * get images latest news
 * @param {id} latestNewsId
 * @returns
 */
export const getLatestNewsDetails = (latestNewsId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${GET_LATEST_NEWS_DETAILS}`,
    {
      headers: { Authorization: jwt_token },
      params: { latestNewsId },
    }
  );
};

/**
 * short order latest news
 * @param {body} updateOrder
 * @returns
 */
export const sort_order_latest_news = (updateOrder) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_LATEST_NEWS_ORDER}`,
    { updateOrder: updateOrder },
    {
      headers: { Authorization: jwt_token },
    }
  );
};
