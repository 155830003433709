import React, { useContext, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import {
  Cases,
  TopHeaderStyle,
  footerStyle1,
  footerStyle2,
  modalsName,
} from "../../../constant";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { IconButton } from "@material-ui/core";
import { Settings } from "react-feather";
import EditIcon from "@mui/icons-material/Edit";
import CommonPopup from "../../../Modals/commonPopup";
import SimpleReactValidator from "simple-react-validator";
import FooterParentPopup from "./FooterParentPopup";
import FooterContext from "../../../context/FooterContext/FooterContext";
import {
  postFooter,
  putUpdateFooter,
  updateStatusFooter,
} from "../apis/FooterApi/FooterApi";
import TopHeaderStylePopup from "./TopHeaderStylePopup";
import FooterStyleOnePopup from "./FooterStyleOnePopup";
import FooterStyleTwoPopup from "./FooterStyleTwoPopup";
import FooterTopHeader from "./FooterTopHeader";
import FooterOne from "./FooterOne";
import FooterTwo from "./FooterTwo";
import {
  convertRGBA,
  randomStr,
  rgbColorCreate,
} from "../../../../utils/helper";
import { FILE_UPLOAD_API } from "../apis/SliderApi/SliderApi";
import { FILE_TYPE, ThemeNo } from "../../../../constant";
import DeleteSection from "../../../common/DeleteSection";
import {
  footerOneStyleOneSet,
  footerOneStyleTwoSet,
  setFooterOneStyleOne,
  setFooterOneStyleTwo,
} from "./FooterOneStyleFunction";
import SettingMultiWebContext from "../../../../context/SettingMultiWebContext/SettingMultiWebContext";

const Footer = (props) => {
  const {
    pageConfigDetails,
    footerDetail,
    contentMapId,
    ByteMiniWeb_Dashboard,
    navMapId: navMapIds,
    contentDetail,
  } = props;

  const { setLoaderShow } = useContext(LoaderContext);
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );
  const { setCloneAlignModal, setAlignFormData, setCloneFormData } = useContext(
    SettingMultiWebContext
  );
  const {
    isEdit,
    formDataChild,
    formData,
    collId,
    setCollId,
    setIsParent,
    id,
    setNameOfStyle,
    setIsEdit,
    setId,
    setFormData,
    topHeaderForm,
    setTopHeaderForm,
    footerOneForm,
    setFooterOneForm,
    footerTwoForm,
    setFooterTwoForm,
    customeIndex,
    setCustomeIndex,
    isSectionImage,
    setIsSectionImage,
    setTheme,
    theme,
    setImgShow,
  } = useContext(FooterContext);

  const parentValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const childValidator = useRef(new SimpleReactValidator());

  const handleChangeFooterTopHeader = async (
    footerId,
    isActive,
    activeName
  ) => {
    try {
      let body = {
        footerId,
        // isActive: isActive ? false : true,
      };
      if (activeName === "isTopHeaderShow") {
        body["isTopHeaderShow"] = isActive ? false : true;
      } else if (activeName === "isFooter1Show") {
        body["isFooter1Show"] = isActive ? false : true;
      } else if (activeName === "isFooter2Show") {
        body["isFooter2Show"] = isActive ? false : true;
      }
      const { data } = await updateStatusFooter(body);
      if (data.status === 200) {
        ByteMiniWeb_Dashboard();
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  const closePopups = () => {
    OpenModalCommonFunc(Cases.FOOTER_TOP_HEADER, setAllPopupState, false);
    OpenModalCommonFunc(
      Cases.SETTING_FOOTER_STYLE_TWO,
      setAllPopupState,
      false
    );
    OpenModalCommonFunc(Cases.FOOTER_ONE, setAllPopupState, false);
    OpenModalCommonFunc(
      Cases.SETTING_FOOTER_TOP_HEADER,
      setAllPopupState,
      false
    );
    OpenModalCommonFunc(Cases.FOOTER_ONE, setAllPopupState, false);
    OpenModalCommonFunc(Cases.FOOTER_TWO, setAllPopupState, false);
    OpenModalCommonFunc(
      Cases.SETTING_FOOTER_STYLE_ONE,
      setAllPopupState,
      false
    );
  };

  /**
   * file upload
   * @param {file} file
   */
  const imageUploadImages = async (file) => {
    setLoaderShow(true);
    let formData = new FormData();
    formData.append("type", FILE_TYPE.SEO);
    formData.append("file", file);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        const image = data.payload.baseUrl + data.payload.imageUrl;
        if (isSectionImage == null) {
          setTopHeaderForm((prev) => {
            let social = [...prev.socialMedia];
            social[customeIndex]["image"] = image;
            return {
              ...prev,
              image: image,
              socialMedia: social,
            };
          });
        } else if (isSectionImage === false) {
          setFooterOneForm((prev) => {
            let social = [...prev.socialMedia];
            social[customeIndex]["image"] = image;
            return {
              ...prev,
              image: image,
              socialMedia: social,
            };
          });
        } else if (isSectionImage === "footerLogo") {
          setFooterOneForm((prev) => {
            return {
              ...prev,
              footerLogo: image,
            };
          });
        } else if (isSectionImage === "footerImage") {
          setFormData((prev) => {
            return {
              ...prev,
              footer1Style: {
                ...prev.footer1Style,
                footerImage: image,
              },
            };
          });
        }

        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const setTopHeaderStyle = () => {
    return {
      ...formData.topHeaderStyle,
      ".top-header-section": {
        ...formData.topHeaderStyle[".top-header-section"],
        "background-color": formData.topHeaderStyle[".top-header-section"]?.[
          "background-color"
        ]
          ? rgbColorCreate({
              rgbString:
                formData.topHeaderStyle[".top-header-section"]?.[
                  "background-color"
                ],
            })
          : "",
      },
      // ".top-header-section .marquee-link a": {
      //   ...formData.topHeaderStyle[".top-header-section .marquee-link a"],
      //   color: formData.topHeaderStyle[".top-header-section .marquee-link a"]?.[
      //     "color"
      //   ]
      //     ? rgbColorCreate({
      //         rgbString:
      //           formData.topHeaderStyle[
      //             ".top-header-section .marquee-link a"
      //           ]?.["color"],
      //       })
      //     : "",
      //   margin: `${
      //     formData.topHeaderStyle[
      //       ".top-header-section .marquee-link a"
      //     ].margin.split(" ")[0]
      //   } ${formData.topHeaderStyle[".top-header-section .marquee-link a"][
      //     "ml"
      //   ]?.replace("px", "")}px`,
      // },
      ".top-header-section .top-left a": {
        ...formData.topHeaderStyle[".top-header-section .top-left a"],
        color: formData.topHeaderStyle[".top-header-section .top-left a"]?.[
          "color"
        ]
          ? rgbColorCreate({
              rgbString:
                formData.topHeaderStyle[".top-header-section .top-left a"]?.[
                  "color"
                ],
            })
          : "",
        margin: `${"2px"} ${formData.topHeaderStyle[
          ".top-header-section .top-left a"
        ]["ml"]?.replace("px", "")}px`,
        "font-size": `${formData.topHeaderStyle[
          ".top-header-section .top-left a"
        ]["font-size"]?.replace("px", "")}px`,
        "letter-spacing": `${formData.topHeaderStyle[
          ".top-header-section .top-left a"
        ]["letter-spacing"]?.replace("px", "")}px`,
      },
      ".top-header-section .top-social a": {
        ...formData.topHeaderStyle[".top-header-section .top-social a"],
        margin: `${
          formData.topHeaderStyle[
            ".top-header-section .top-social a"
          ].margin.split(" ")[0]
        } ${formData.topHeaderStyle[".top-header-section .top-social a"][
          "ml"
        ]?.replace("px", "")}px`,
        "font-size": `${formData.topHeaderStyle[
          ".top-header-section .top-social a"
        ]["font-size"]?.replace("px", "")}px`,
        color: formData.topHeaderStyle[".top-header-section .top-social a"]?.[
          "color"
        ]
          ? rgbColorCreate({
              rgbString:
                formData.topHeaderStyle[".top-header-section .top-social a"]?.[
                  "color"
                ],
            })
          : "",
      },
      ".goog-te-gadget .goog-te-combo": {
        ...formData.topHeaderStyle[".goog-te-gadget .goog-te-combo"],
        "border-color": formData.topHeaderStyle[
          ".goog-te-gadget .goog-te-combo"
        ]?.["background"]
          ? `${rgbColorCreate({
              rgbString:
                formData.topHeaderStyle[".goog-te-gadget .goog-te-combo"]?.[
                  "background"
                ],
            })} !important`
          : "",
        background: formData.topHeaderStyle[".goog-te-gadget .goog-te-combo"]?.[
          "background"
        ]
          ? `${rgbColorCreate({
              rgbString:
                formData.topHeaderStyle[".goog-te-gadget .goog-te-combo"]?.[
                  "background"
                ],
            })} !important`
          : "",
        color: formData.topHeaderStyle[".goog-te-gadget .goog-te-combo"]?.[
          "color"
        ]
          ? `${rgbColorCreate({
              rgbString:
                formData.topHeaderStyle[".goog-te-gadget .goog-te-combo"]?.[
                  "color"
                ],
            })} !important`
          : "",
      },
      ".goog-te-gadget": {
        ...formData.topHeaderStyle[".goog-te-gadget"],
        "border-radius": `${formData.topHeaderStyle[".goog-te-gadget"][
          "border-radius"
        ]?.replace("px", "")}px !important`,
        border: `${formData.topHeaderStyle[".goog-te-gadget"][
          "borderPx"
        ]?.replace("px", "")}px ${
          formData.topHeaderStyle[".goog-te-gadget"]["borderThik"]
        } ${rgbColorCreate({
          rgbString:
            formData.topHeaderStyle[".goog-te-gadget"]?.["borderColor"],
        })} !important`,
      },
    };
  };

  const setFooterTowStyle = () => {
    return {
      ...formData.footer2Style,
      ".custom-footer .corner.corner-inverse:before, .custom-footer .corner.corner-inverse:after":
        {
          ...formData.footer2Style?.[
            ".custom-footer .corner.corner-inverse:before, .custom-footer .corner.corner-inverse:after"
          ],
          "border-bottom": `${formData.footer2Style?.[
            ".custom-footer .corner.corner-inverse:before, .custom-footer .corner.corner-inverse:after"
          ]?.["borderBtPx"]?.replace("px", "")}px ${
            formData.footer2Style?.[
              ".custom-footer .corner.corner-inverse:before, .custom-footer .corner.corner-inverse:after"
            ]?.["borderBtThik"]
          } ${rgbColorCreate({
            rgbString:
              formData.footer2Style[
                ".custom-footer .corner.corner-inverse:before, .custom-footer .corner.corner-inverse:after"
              ]?.["borderBtColor"],
          })}`,
          "border-left": `${formData.footer2Style?.[
            ".custom-footer .corner.corner-inverse:before, .custom-footer .corner.corner-inverse:after"
          ]?.["borderLfPx"]?.replace("vw", "")}vw ${
            formData.footer2Style?.[
              ".custom-footer .corner.corner-inverse:before, .custom-footer .corner.corner-inverse:after"
            ]?.["borderLfThik"]
          } ${rgbColorCreate({
            rgbString:
              formData.footer2Style[
                ".custom-footer .corner.corner-inverse:before, .custom-footer .corner.corner-inverse:after"
              ]?.["borderLfColor"],
          })}`,
          "border-right": `${formData.footer2Style?.[
            ".custom-footer .corner.corner-inverse:before, .custom-footer .corner.corner-inverse:after"
          ]?.["borderRiPx"]?.replace("vw", "")}vw ${
            formData.footer2Style?.[
              ".custom-footer .corner.corner-inverse:before, .custom-footer .corner.corner-inverse:after"
            ]?.["borderRiThik"]
          } ${rgbColorCreate({
            rgbString:
              formData.footer2Style[
                ".custom-footer .corner.corner-inverse:before, .custom-footer .corner.corner-inverse:after"
              ]?.["borderRiColor"],
          })}`,
        },
      ".custom-footer .foot-contact": {
        ...formData.footer2Style?.[".custom-footer .foot-contact"],
        "background-color": formData.footer2Style[
          ".custom-footer .foot-contact"
        ]?.["background-color"]
          ? rgbColorCreate({
              rgbString:
                formData.footer2Style[".custom-footer .foot-contact"]?.[
                  "background-color"
                ],
            })
          : "",
        padding: `${formData.footer2Style?.[".custom-footer .foot-contact"]?.[
          "padding"
        ]
          ?.split(" ")[0]
          ?.replace("px", "")}px 0`,
        "text-align": `${formData.footer2Style?.[".custom-footer .foot-contact"]?.["text-align"]}`,
      },
      ".custom-footer .foot-contact .icons": {
        ...formData.footer2Style?.[".custom-footer .foot-contact .icons"],
        "background-color": formData.footer2Style[
          ".custom-footer .foot-contact .icons"
        ]?.["background-color"]
          ? rgbColorCreate({
              rgbString:
                formData.footer2Style[".custom-footer .foot-contact .icons"]?.[
                  "background-color"
                ],
            })
          : "",
        border: `${formData.footer2Style?.[
          ".custom-footer .foot-contact .icons"
        ]?.["borderPx"]?.replace("px", "")}px ${
          formData.footer2Style?.[".custom-footer .foot-contact .icons"]?.[
            "borderThik"
          ]
        } ${rgbColorCreate({
          rgbString:
            formData.footer2Style[".custom-footer .foot-contact .icons"]?.[
              "borderColor"
            ],
        })}`,
        "border-radius": `${formData.footer2Style?.[
          ".custom-footer .foot-contact .icons"
        ]["border-radius"]?.replace("%", "")}%`,
        padding: `${formData.footer2Style?.[
          ".custom-footer .foot-contact .icons"
        ]?.["padding"]?.replace("px", "")}px`,
        height: `${formData.footer2Style?.[
          ".custom-footer .foot-contact .icons"
        ]?.["height"]?.replace("px", "")}px`,
        width: `${formData.footer2Style?.[
          ".custom-footer .foot-contact .icons"
        ]?.["width"]?.replace("px", "")}px`,
        "margin-bottom": `${formData.footer2Style?.[
          ".custom-footer .foot-contact .icons"
        ]?.["margin-bottom"]?.replace("px", "")}px`,
      },
      ".custom-footer .foot-contact h6": {
        ...formData.footer2Style?.[".custom-footer .foot-contact h6"],
        color: formData.footer2Style[".custom-footer .foot-contact h6"]?.[
          "color"
        ]
          ? rgbColorCreate({
              rgbString:
                formData.footer2Style[".custom-footer .foot-contact h6"]?.[
                  "color"
                ],
            })
          : "",
        "font-size": `${formData.footer2Style?.[
          ".custom-footer .foot-contact h6"
        ]?.["font-size"]?.replace("px", "")}px`,
        "font-weight": `${formData.footer2Style?.[".custom-footer .foot-contact h6"]?.["font-weight"]}`,
        "margin-bottom": `${formData.footer2Style?.[
          ".custom-footer .foot-contact h6"
        ]?.["margin-bottom"]?.replace("px", "")}px`,
      },
      ".custom-footer .foot-contact p": {
        ...formData.footer2Style?.[".custom-footer .foot-contact p"],
        color: formData.footer2Style[".custom-footer .foot-contact p"]?.[
          "color"
        ]
          ? rgbColorCreate({
              rgbString:
                formData.footer2Style[".custom-footer .foot-contact p"]?.[
                  "color"
                ],
            })
          : "",
        "font-size": `${formData.footer2Style?.[
          ".custom-footer .foot-contact p"
        ]?.["font-size"]?.replace("px", "")}px`,
        "font-weight": `${formData.footer2Style?.[".custom-footer .foot-contact p"]?.["font-weight"]}`,
      },
    };
  };

  const submitForm = async ({
    formValidationCondition,
    isRestore,
    isClose = true,
  }) => {
    if (id >= -1 && isEdit) {
      setLoaderShow(true);
      try {
        let allData = {
          // topHeader: { ...topHeaderForm },
          footerId: id,
          contentMapId: collId,
          navMapId: +navMapIds,
        };
        const forms = [
          "topHeaderForm",
          "topHeaderStyleForm",
          "footerOneStyleForm",
          "footerTowStyleForm",
          "footerOneForm",
          "footerTwoForm",
        ];
        if (forms.includes(formValidationCondition)) {
        }

        /**top header */
        if (formValidationCondition === "topHeaderForm") {
          const modifiedArray = topHeaderForm.socialMedia.map((obj) => {
            if (obj.hasOwnProperty("image")) {
              // Remove the specified part of the 'image' URL
              obj.image = obj.image
                .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
                .pop();
            }
            return obj;
          });
          allData["topHeader"] = {
            ...topHeaderForm,
            socialMedia: modifiedArray,
          };
        }
        if (formValidationCondition === "topHeaderStyleForm") {
          allData["topHeaderStyle"] = setTopHeaderStyle();
          if (isRestore) {
            allData = {
              isTopHeaderRestore: isRestore,
              footerId: id,
            };
          }
        }

        /**footer one */
        if (formValidationCondition === "footerOneForm") {
          footerOneForm["dialCode"] = footerOneForm["dialCode"]?.value;
          const newFooterOneSocialMedia = footerOneForm.socialMedia.map(
            (ele) => {
              if (ele.hasOwnProperty("image")) {
                ele.image = ele.image
                  .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
                  .pop();
              }
              return ele;
            }
          );

          allData["footer1"] = {
            ...footerOneForm,
            socialMedia: newFooterOneSocialMedia,
          };
          if (footerOneForm?.footerLogo) {
            allData["footer1"] = {
              ...footerOneForm,
              footerLogo: footerOneForm.footerLogo
                .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
                .pop(),
            };
          }
        }
        if (formValidationCondition === "footerOneStyleForm") {
          let footerOneStyle;
          if (+theme === ThemeNo.ThemeOne) {
            footerOneStyle = setFooterOneStyleOne(formData);
          } else if (+theme === ThemeNo.ThemeTwo) {
            footerOneStyle = setFooterOneStyleTwo(formData);
          }
          allData["footer1Style"] = footerOneStyle;
          if (isRestore) {
            allData = {
              isTopHeaderRestore: isRestore,
              footerId: id,
            };
          }
        }
        /**footer Tow */
        if (formValidationCondition === "footerTwoForm") {
          allData["footer2"] = { ...footerTwoForm };
        }
        if (formValidationCondition === "footerTowStyleForm") {
          allData["footer2Style"] = setFooterTowStyle();
          if (isRestore) {
            allData = {
              isTopHeaderRestore: isRestore,
              footerId: id,
            };
          }
        }
        delete allData?.footer1?.image;
        const { data } = await putUpdateFooter(allData);
        if (data.status === 200) {
          ByteMiniWeb_Dashboard();
          if (forms.includes(formValidationCondition)) {
            if (isClose) {
              emptyData();
              closePopups();
            }
          } else {
            if (formValidationCondition === "footerOneStyleForm") {
              if (isClose) {
                emptyData();
                OpenModalCommonFunc(
                  Cases.SETTING_FOOTER_STYLE_ONE,
                  setAllPopupState,
                  false
                );
              }
            } else {
              OpenModalCommonFunc(Cases.FOOTER, setAllPopupState, false);
            }
          }
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    } else {
      try {
        setLoaderShow(true);
        let allData = {};
        allData = {
          ...formData,
          contentMapId: collId,
          navMapId: +navMapIds,
        };
        const { data } = await postFooter(allData);
        if (data.status === 200) {
          ByteMiniWeb_Dashboard();
          emptyData();
          OpenModalCommonFunc(Cases.FOOTER, setAllPopupState, false);
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  const handleSubmit = async (formValidationCondition) => {
    let validatorFields,
      forms = [
        "childForm",
        "topHeaderForm",
        "topHeaderStyleForm",
        "footerOneForm",
        "footerTwoForm",
        "footerOneStyleForm",
        "footerTowStyleForm",
      ];
    if (formValidationCondition === "parentForm") {
      validatorFields = parentValidator.current;
    } else if (forms.includes(formValidationCondition)) {
      validatorFields = childValidator.current;
    }
    validatorFields?.showMessages();

    if (validatorFields.allValid()) {
      try {
        if (formValidationCondition === "parentForm") {
          submitForm({ formValidationCondition });
        } else if (forms.includes(formValidationCondition)) {
          submitForm({ formValidationCondition });
        }
        validatorFields.hideMessages();
      } catch (error) {}
    } else {
      forceUpdate({});
    }
  };

  /**
   * get data product Buy
   * @param {id} footerId
   */
  const handleEditPackages = async (footerId, openForm, data) => {
    try {
      setLoaderShow(true);
      setId(footerId);
      if (openForm === "topHeader") {
        setTopHeaderForm({
          phone: data?.phone,
          email: data?.email,
          dialCode: data?.dialCode ? data?.dialCode : {},
          socialMedia: data.socialMedia ?? [
            { name: "", value: "", id: randomStr(2) },
          ],
        });
        OpenModalCommonFunc(Cases.FOOTER_TOP_HEADER, setAllPopupState);
      }
      if (openForm === "footer1") {
        setFooterOneForm({
          content: data.content,
          address: data.address,
          locationLink: data.locationLink,
          dialCode: { value: data.dialCode, label: data.dialCode },
          phone: data.phone,
          email: data.email,
          footerLogo: data?.footerLogo
            ? `${process.env.REACT_APP_BUCKET_URL}/${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/${data.footerLogo}`
            : "",
          socialMedia: data.socialMedia ?? [
            { name: "", value: "", id: randomStr(2) },
          ],
        });
        OpenModalCommonFunc(Cases.FOOTER_ONE, setAllPopupState);
      }
      if (openForm === "footer2") {
        setFooterTwoForm({
          phone1: data.phone1,
          phone2: data.phone2,
          email1: data.email1,
          email2: data.email2,
          address: data.address,
        });
        OpenModalCommonFunc(Cases.FOOTER_TWO, setAllPopupState);
      }
      setIsEdit(true);
      setLoaderShow(false);
    } catch (error) {
      setLoaderShow(true);
      TostMessage({ message: error, type: "error" });
    }
  };
  const handleChangeSettings = async (footerName, footerId, data) => {
    setId(footerId);
    setIsEdit(true);
    const myFooterStyle = {
      topHeader: {
        openModal: () =>
          OpenModalCommonFunc(
            Cases.SETTING_FOOTER_TOP_HEADER,
            setAllPopupState
          ),
        setData: () =>
          setFormData({
            topHeaderStyle: {
              ...data,
              ".top-header-section": {
                ...data?.[".top-header-section"],
                "background-color": data?.[".top-header-section"]?.[
                  "background-color"
                ]
                  ? convertRGBA({
                      rgbString:
                        data?.[".top-header-section"]?.["background-color"],
                    })
                  : "",
              },
              ".top-header-section .top-left a": {
                ...data?.[".top-header-section .top-left a"],
                color: data?.[".top-header-section .top-left a"]?.["color"]
                  ? convertRGBA({
                      rgbString:
                        data?.[".top-header-section .top-left a"]?.["color"],
                    })
                  : "",
              },
              ".top-header-section .top-social a": {
                ...data?.[".top-header-section .top-social a"],
                color: data?.[".top-header-section .top-social a"]?.["color"]
                  ? convertRGBA({
                      rgbString:
                        data?.[".top-header-section .top-social a"]?.["color"],
                    })
                  : "",
              },
              // ".goog-te-gadget .goog-te-combo": {
              //   ...data?.[".goog-te-gadget .goog-te-combo"],
              //   background: data?.[".goog-te-gadget .goog-te-combo"]?.[
              //     "background"
              //   ]
              //     ? `${convertRGBA({
              //         rgbString: data?.[".goog-te-gadget .goog-te-combo"]?.[
              //           "background"
              //         ]?.replace(/\s*!important$/, ""),
              //       })}`
              //     : "",
              //   color: data?.[".goog-te-gadget .goog-te-combo"]?.["color"]
              //     ? `${convertRGBA({
              //         rgbString: data?.[".goog-te-gadget .goog-te-combo"]?.[
              //           "color"
              //         ]?.replace(/\s*!important$/, ""),
              //       })}`
              //     : "",
              // },
              ".goog-te-gadget .goog-te-combo": {
                ...data?.[".goog-te-gadget .goog-te-combo"],
                background: data?.[".goog-te-gadget .goog-te-combo"]?.[
                  "background"
                ]?.replace(/\s*!important$/, ""),
                color: data?.[".goog-te-gadget .goog-te-combo"]?.[
                  "color"
                ]?.replace(/\s*!important$/, ""),
              },
              ".goog-te-gadget": {
                ...data?.[".goog-te-gadget"],
                borderColor: data?.[".goog-te-gadget"]?.["borderColor"],
                // borderColor: data?.[".goog-te-gadget"]?.["borderColor"]
                //   ? `${convertRGBA({
                //       rgbString: data?.[".goog-te-gadget"]?.["borderColor"],
                //     })}`
                //   : "",
                "border-radius": data?.[".goog-te-gadget"]?.[
                  "border-radius"
                ]?.replace(/\s*!important$/, ""),
              },
              custom: data?.custom,
            },
          }),
      },

      footer1: {
        openModal: () =>
          OpenModalCommonFunc(Cases.SETTING_FOOTER_STYLE_ONE, setAllPopupState),
        setData: () => {
          let newData;
          if (!Object.keys(data).find((ele) => ele === "theme")) {
            newData = footerOneStyleOneSet(data);
            setTheme(data?.theme);
          }
          setImgShow(true);
          if (data?.theme === "1") {
            newData = footerOneStyleOneSet(data);
            setTheme(data?.theme);
          } else if (data.theme === "2") {
            newData = footerOneStyleTwoSet(data);
            setTheme(data?.theme);
          }
          setFormData({
            footer1Style: newData,
          });
        },
      },
      footer2: {
        openModal: () =>
          OpenModalCommonFunc(Cases.SETTING_FOOTER_STYLE_TWO, setAllPopupState),
        setData: () =>
          setFormData({
            footer2Style: {
              ...data,
              ".custom-footer .corner.corner-inverse:before, .custom-footer .corner.corner-inverse:after":
                {
                  ...data?.[
                    ".custom-footer .corner.corner-inverse:before, .custom-footer .corner.corner-inverse:after"
                  ],
                  borderBtColor: data?.[
                    ".custom-footer .corner.corner-inverse:before, .custom-footer .corner.corner-inverse:after"
                  ]?.["borderBtColor"]
                    ? convertRGBA({
                        rgbString:
                          data?.[
                            ".custom-footer .corner.corner-inverse:before, .custom-footer .corner.corner-inverse:after"
                          ]?.["borderBtColor"],
                      })
                    : "",
                  borderLfColor: data?.[
                    ".custom-footer .corner.corner-inverse:before, .custom-footer .corner.corner-inverse:after"
                  ]?.["borderLfColor"]
                    ? convertRGBA({
                        rgbString:
                          data?.[
                            ".custom-footer .corner.corner-inverse:before, .custom-footer .corner.corner-inverse:after"
                          ]?.["borderLfColor"],
                      })
                    : "",
                  borderRiColor: data?.[
                    ".custom-footer .corner.corner-inverse:before, .custom-footer .corner.corner-inverse:after"
                  ]?.["borderRiColor"]
                    ? convertRGBA({
                        rgbString:
                          data?.[
                            ".custom-footer .corner.corner-inverse:before, .custom-footer .corner.corner-inverse:after"
                          ]?.["borderRiColor"],
                      })
                    : "",
                },
              ".custom-footer .foot-contact": {
                ...data?.[".custom-footer .foot-contact"],
                "background-color": data?.[".custom-footer .foot-contact"]?.[
                  "background-color"
                ]
                  ? convertRGBA({
                      rgbString:
                        data?.[".custom-footer .foot-contact"]?.[
                          "background-color"
                        ],
                    })
                  : "",
              },
              ".custom-footer .foot-contact .icons": {
                ...data?.[".custom-footer .foot-contact .icons"],
                "background-color": data?.[
                  ".custom-footer .foot-contact .icons"
                ]?.["background-color"]
                  ? convertRGBA({
                      rgbString:
                        data?.[".custom-footer .foot-contact .icons"]?.[
                          "background-color"
                        ],
                    })
                  : "",
                borderColor: data?.[".custom-footer .foot-contact .icons"]?.[
                  "borderColor"
                ]
                  ? convertRGBA({
                      rgbString:
                        data?.[".custom-footer .foot-contact .icons"]?.[
                          "borderColor"
                        ],
                    })
                  : "",
              },
              ".custom-footer .foot-contact h6": {
                ...data?.[".custom-footer .foot-contact h6"],
                color: data?.[".custom-footer .foot-contact h6"]?.["color"]
                  ? convertRGBA({
                      rgbString:
                        data?.[".custom-footer .foot-contact h6"]?.["color"],
                    })
                  : "",
              },
              ".custom-footer .foot-contact p": {
                ...data?.[".custom-footer .foot-contact p"],
                color: data?.[".custom-footer .foot-contact p"]?.["color"]
                  ? convertRGBA({
                      rgbString:
                        data?.[".custom-footer .foot-contact p"]?.["color"],
                    })
                  : "",
              },
              custom: data?.custom,
            },
          }),
      },
    };
    myFooterStyle[footerName].openModal();
    myFooterStyle[footerName].setData();
  };

  const emptyData = () => {
    setTheme(1);
    setIsSectionImage("");
    setCustomeIndex("");
    setNameOfStyle("");
    setIsEdit(false);
    setId("");
    setFormData({
      title: "",
      contentMapId: "",
      topHeaderStyle: TopHeaderStyle,
      footer1Style: footerStyle1,
      footer2Style: footerStyle2,
    });
    setTopHeaderForm({
      phone: "",
      email: "",
      image: "",
      socialMedia: [{ name: "", value: "", id: randomStr(2) }],
    });
    setFooterOneForm({
      phone: "",
      email: "",
      address: "",
      content: "",
      image: "",
      socialMedia: [{ name: "", value: "", id: randomStr(2) }],
    });
  };

  const handleAlignAndCloneSection = ({ isClone = false }) => {
    if (isClone) {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          cloneModal: true,
        };
      });
      setCloneFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          pageConfigDetailsLabel: pageConfigDetails[0]?.label,
          contentDetailName: contentDetail.name,
        };
      });
    } else {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          alignModal: true,
        };
      });
      setAlignFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          label: pageConfigDetails[0]?.label,
        };
      });
    }
  };

  return (
    <>
      <Col xl="12 box-col-12" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody
            className="support-ticket-font box-border-div"
            style={{ padding: "20px" }}
          >
            <Row>
              <CardBuilderHeaderSection
                addBtnTitle={pageConfigDetails[0]?.label}
                name={pageConfigDetails[0]?.label}
                isAdd={!footerDetail && true}
                mainEditSection={true}
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
                openModal={() => {
                  OpenModalCommonFunc(Cases.FOOTER, setAllPopupState);
                  emptyData();
                  setIsParent(true);
                  setCollId(contentMapId);
                }}
                isCloneSection={true}
                isAlignSection={true}
                handleAlignAndCloneSection={handleAlignAndCloneSection}
              />
              {footerDetail && (
                <>
                  <Col xl="4 xl-50 box-col-6" md="6">
                    <Card className="ecommerce-widget mb-0">
                      <CardBody
                        className="support-ticket-font"
                        style={{ padding: "20px" }}
                      >
                        <div className="col-12">
                          <CardBuilderHeaderSection
                            name={"Top Header"}
                            checked={footerDetail?.isTopHeaderShow}
                            switchToggle={true}
                            id={footerDetail?.footerId}
                            handleChange={(footerId, isActive, activeName) =>
                              handleChangeFooterTopHeader(
                                footerId,
                                isActive,
                                "isTopHeaderShow"
                              )
                            }
                          />
                        </div>
                        <div className="d-flex justify-content-end ">
                          <IconButton
                            edge="start"
                            color="inherit"
                            style={{ marginLeft: "5px" }}
                            onClick={() => {
                              handleEditPackages(
                                footerDetail.footerId,
                                "topHeader",
                                footerDetail.topHeader
                              );
                              setCollId(contentMapId);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            edge="start"
                            color="inherit"
                            style={{ marginLeft: "5px" }}
                            onClick={() => {
                              handleChangeSettings(
                                "topHeader",
                                footerDetail.footerId,
                                footerDetail.topHeaderStyle
                              );
                              setCollId(contentMapId);
                            }}
                          >
                            <Settings />
                          </IconButton>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>{" "}
                  <Col xl="4 xl-50 box-col-6" md="6">
                    <Card className="ecommerce-widget mb-0">
                      <CardBody
                        className="support-ticket-font"
                        style={{ padding: "20px" }}
                      >
                        <div className="col-12">
                          <CardBuilderHeaderSection
                            name={"Footer 1"}
                            checked={footerDetail?.isFooter1Show}
                            switchToggle={true}
                            id={footerDetail?.footerId}
                            handleChange={(footerId, isActive, activeName) =>
                              handleChangeFooterTopHeader(
                                footerId,
                                isActive,
                                "isFooter1Show"
                              )
                            }
                          />
                        </div>
                        <div className="d-flex justify-content-end ">
                          <IconButton
                            edge="start"
                            color="inherit"
                            style={{ marginLeft: "5px" }}
                            onClick={() => {
                              handleEditPackages(
                                footerDetail.footerId,
                                "footer1",
                                footerDetail.footer1
                              );
                              setCollId(contentMapId);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            edge="start"
                            color="inherit"
                            style={{ marginLeft: "5px" }}
                            onClick={() => {
                              handleChangeSettings(
                                "footer1",
                                footerDetail.footerId,
                                footerDetail.footer1Style
                              );
                            }}
                          >
                            <Settings />
                          </IconButton>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>{" "}
                  <Col xl="4 xl-50 box-col-6" md="6">
                    <Card className="ecommerce-widget mb-0">
                      <CardBody
                        className="support-ticket-font"
                        style={{ padding: "20px" }}
                      >
                        <div className="col-12">
                          <CardBuilderHeaderSection
                            name={"Footer 2"}
                            checked={footerDetail?.isFooter2Show}
                            switchToggle={true}
                            id={footerDetail?.footerId}
                            handleChange={(footerId, isActive, activeName) =>
                              handleChangeFooterTopHeader(
                                footerId,
                                isActive,
                                "isFooter2Show"
                              )
                            }
                          />
                        </div>
                        <div className="d-flex justify-content-end ">
                          <IconButton
                            edge="start"
                            color="inherit"
                            style={{ marginLeft: "5px" }}
                            onClick={() => {
                              handleEditPackages(
                                footerDetail.footerId,
                                "footer2",
                                footerDetail.footer2
                              );
                              setCollId(contentMapId);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            edge="start"
                            color="inherit"
                            style={{ marginLeft: "5px" }}
                            onClick={() => {
                              handleChangeSettings(
                                "footer2",
                                footerDetail.footerId,
                                footerDetail.footer2Style
                              );
                            }}
                          >
                            <Settings />
                          </IconButton>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </>
              )}
              <DeleteSection
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
              />
            </Row>
          </CardBody>
        </Card>
      </Col>

      {/* item feature parent popup */}
      <CommonPopup
        open={allPopupState.footerPopup}
        close={() => {
          OpenModalCommonFunc(Cases.FOOTER, setAllPopupState, false);
          setIsParent(false);
        }}
        modalName={modalsName.FOOTER}
        modalCase={Cases.FOOTER}
        handleSubmit={handleSubmit}
        validationForm={"parentForm"}
        emptyData={emptyData}
        inputData={formData}
        edit={false}
        content={<FooterParentPopup />}
      />

      {/* top header style and form popup */}
      <CommonPopup
        open={allPopupState.footerTopHeaderStylePopup}
        close={() =>
          OpenModalCommonFunc(
            Cases.SETTING_FOOTER_TOP_HEADER,
            setAllPopupState,
            false
          )
        }
        handleSubmit={handleSubmit}
        validationForm={"topHeaderStyleForm"}
        modalName={modalsName.SETTING_FOOTER_TOP_HEADER}
        modalCase={Cases.SETTING_FOOTER_TOP_HEADER}
        emptyData={emptyData}
        inputData={formDataChild}
        edit={false}
        content={
          <TopHeaderStylePopup
            emptyData={emptyData}
            footerId={footerDetail?.footerId}
            footerDetail={footerDetail}
            ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
            submitForm={submitForm}
          />
        }
      />
      <CommonPopup
        open={allPopupState.footerTopHeaderPopup}
        close={() => {
          OpenModalCommonFunc(Cases.FOOTER_TOP_HEADER, setAllPopupState, false);
          setIsParent(false);
        }}
        modalName={modalsName.FOOTER_TOP_HEADER}
        modalCase={Cases.FOOTER_TOP_HEADER}
        handleSubmit={handleSubmit}
        validationForm={"topHeaderForm"}
        emptyData={emptyData}
        inputData={formData}
        edit={false}
        content={<FooterTopHeader imageUpload={imageUploadImages} />}
      />
      {/* footer 1 style and form popup */}
      <CommonPopup
        open={allPopupState.footerOneStylePopup}
        close={() =>
          OpenModalCommonFunc(
            Cases.SETTING_FOOTER_STYLE_ONE,
            setAllPopupState,
            false
          )
        }
        validationForm={"footerOneStyleForm"}
        modalName={modalsName.SETTING_FOOTER_STYLE_ONE}
        modalCase={Cases.SETTING_FOOTER_STYLE_ONE}
        handleSubmit={handleSubmit}
        emptyData={emptyData}
        inputData={formDataChild}
        edit={false}
        content={
          <FooterStyleOnePopup
            emptyData={emptyData}
            footerId={footerDetail?.footerId}
            footerDetail={footerDetail}
            ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
            submitForm={submitForm}
            imageUpload={imageUploadImages}
          />
        }
      />
      <CommonPopup
        open={allPopupState.footerOnePopup}
        close={() => {
          OpenModalCommonFunc(Cases.FOOTER_ONE, setAllPopupState, false);
          setIsParent(false);
        }}
        modalName={modalsName.FOOTER_ONE}
        modalCase={Cases.FOOTER_ONE}
        handleSubmit={handleSubmit}
        validationForm={"footerOneForm"}
        emptyData={emptyData}
        inputData={formData}
        edit={false}
        content={<FooterOne imageUpload={imageUploadImages} />}
      />
      <CommonPopup
        open={allPopupState.footerTwoPopup}
        close={() => {
          OpenModalCommonFunc(Cases.FOOTER_TWO, setAllPopupState, false);
          setIsParent(false);
        }}
        modalName={modalsName.FOOTER_TWO}
        modalCase={Cases.FOOTER_TWO}
        handleSubmit={handleSubmit}
        validationForm={"footerTwoForm"}
        emptyData={emptyData}
        inputData={formData}
        edit={false}
        content={<FooterTwo />}
      />
      <CommonPopup
        open={allPopupState.footerTwoStylePopup}
        close={() =>
          OpenModalCommonFunc(
            Cases.SETTING_FOOTER_STYLE_TWO,
            setAllPopupState,
            false
          )
        }
        validationForm={"footerTowStyleForm"}
        handleSubmit={handleSubmit}
        modalName={modalsName.SETTING_FOOTER_STYLE_TWO}
        modalCase={Cases.SETTING_FOOTER_STYLE_TWO}
        emptyData={emptyData}
        inputData={formDataChild}
        edit={false}
        content={
          <FooterStyleTwoPopup
            emptyData={emptyData}
            footerId={footerDetail?.footerId}
            footerDetail={footerDetail}
            ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
            submitForm={submitForm}
          />
        }
      />
    </>
  );
};

export default Footer;
