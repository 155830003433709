import { IconButton, Tooltip } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { DataGrid } from "@mui/x-data-grid";
import React, { useContext } from "react";
import { Card, CardHeader, Col, Container, Row } from "reactstrap";
import {
  MOMENT_SETTING,
  PAGE_ITEM_MASTER_ADD,
  Style,
} from "../../constant";
import DataNotFound from "../../DataNoteFound/DataNotFound";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import { TabelAnimation } from "../../Spinner";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { MuiTabelStyle } from "../../utils/MuiTabelStyle";
import moment from "moment-timezone";
import noPhoto from "../../assets/images/no-photo.webp";
import PageItemMasterContext from "../../context/PageItemMasterContext/PageItemMasterContext";

const PageItemMasterItem = (props) => {
  const {
    formvalue,
    record,
    pageSize,
    page,
    setPageSize,
    setPage,
    sorting,
    setModal,
    EditHandle,
    setToggleSubmit,
  } = useContext(PageItemMasterContext);

  const { tabelAnimation } = useContext(LoaderContext);

  /**
   * Add Profile Function call
   */

  const AddProfile = () => {
    setToggleSubmit(false);
    setModal(true);
  };

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 100,
      field: "sNo",
      headerName: "S.No.",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "orgLogo",
      headerName: "Logo",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <>
          {params.row.orgLogo ? (
            <img src={params.row.orgLogo} alt="" style={{ width: "60px" }} />
          ) : (
            <img src={noPhoto} alt="" style={{ width: "60px" }} />
          )}
        </>
      ),
    },
    {
      flex: 0.2,
      minWidth: 250,
      field: "orgName",
      headerName: "Org Name",
      renderCell: (params) => (
        <>{params.row.orgName ? params.row.orgName : "-"}</>
      ),
    },
    {
      flex: 0.3,
      minWidth: 300,
      field: "email",
      headerName: "Org Email",
      renderCell: (params) =>
        params.row.orgPages[0] ? params.row.orgPages[0].email : "-",
    },
    {
      flex: 0.3,
      minWidth: 300,
      field: "userEmail",
      headerName: "User Email",
      renderCell: (params) =>
        params.row.orgPages[0] ? params.row.orgPages[0].userEmail : "-",
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "pageCount",
      headerName: "Page Count",
      renderCell: (params) => (
        <>{params.row.pageCount ? params.row.pageCount : "0"}</>
      ),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "pageLimit",
      headerName: "Page Limit",
      renderCell: (params) => (
        <>{params.row.pageLimit ? params.row.pageLimit : "0"}</>
      ),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "isBrokerAllowed",
      headerName: "Permission",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) =>
        params.row.isBrokerAllowed === 1 ? (
          <p style={{ color: "green" }}>Allow</p>
        ) : (
          <p style={{ color: "red" }}>Deny</p>
        ),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "brokerLimit",
      headerName: "Broker Limit",
      sort: false,
      renderCell: (params) => (
        <>{params.row.brokerLimit ? params.row.brokerLimit : "0"}</>
      ),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "hwId",
      headerName: "Hw ID",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <>
          <a
            target={"_blank"}
            rel="noreferrer"
            href={`https://byteqr.com/${params.row.hwId}`}
          >
            {" "}
            {params.row.hwId}
          </a>
        </>
      ),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "createdAt",
      headerName: "Date",
      renderCell: (params) =>
        params.row.createdAt &&
        moment.utc(params.row.createdAt).format(MOMENT_SETTING.FORMATE),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "edit",
      headerName: "Edit",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Tooltip title="Edit">
          <IconButton
            aria-label="edit"
            style={{ color: "green" }}
            onClick={() =>
              EditHandle(
                params.row.orgUuid,
                params.row.pageLimit,
                params.row.domain,
                params.row.isBrokerAllowed,
                params.row.brokerLimit
              )
            }
          >
            <ModeEditIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  return (
    <Container fluid={true}>
      <Row>
        <Col sm="12">
          <Card>
            <CardHeader
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h3>{PAGE_ITEM_MASTER_ADD.PAGE_ITEM}</h3>
              <div
                aria-label="add"
                onClick={() => AddProfile()}
                className="btn btn-primary"
                style={Style}
              >
                <Add />
              </div>
            </CardHeader>
            {tabelAnimation === true ? (
              <TabelAnimation />
            ) : formvalue.length ? (
              <DataGrid
                sx={MuiTabelStyle}
                autoHeight
                rows={formvalue}
                getRowId={(row) => row.orgId}
                columns={defaultColumns}
                rowsPerPageOptions={[7, 10, 25, 50]}
                pagination
                rowCount={record}
                pageSize={pageSize}
                page={page - 1}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                onPageChange={(newPageNo) => setPage(newPageNo + 1)}
                paginationMode="server"
                disableSelectionOnClick
                checkboxSelection={false}
                sortingMode="server"
                onSortModelChange={sorting}
              />
            ) : (
              <DataNotFound />
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PageItemMasterItem;
