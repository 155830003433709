import {
  addUrlImage,
  convertRGBA,
  getColorFromString,
  removeUrlImage,
  removeUrlKeyInPath,
  rgbColorCreate,
} from "../../../../utils/helper";

export const imagesGalleryThemeOneReset = {
  ".photo-gallery-section": {
    padding: "100px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".photo-gallery-section.bg-image": {},
  ".photo-gallery-section.bg-image::before": {
    // "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
  },
  ".photo-gallery-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".photo-gallery-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: { r: 255, g: 73, b: 124, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 0.2 },
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".photo-gallery-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: { r: 0, g: 0, b: 0, a: 100 },
  },
  ".photo-gallery-section .gallery-catlist": {
    "text-align": "center",
    "margin-bottom": "30px",
  },
  ".photo-gallery-section .gallery-catlist .btn": {
    border: "2px solid { r: 255, g: 73, b: 124, a: 100 }",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 73, b: 124, a: 100 },
    padding: "6px 20px",
    p1: "6px",
    p2: "20px",
    margin: "5px",
    "box-shadow": "none",
  },
  ".photo-gallery-section .gallery-catlist .active": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".photo-gallery-section .gallery-catdata .box": {
    "margin-bottom": "15px",
    outline: "none",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
    "box-shadow": "0 5px 10px 0 { r: 7, g: 28, b: 26, a: 0.17 }",
    bs1: "0px",
    bs2: "5px",
    bs3: "10px",
    bs4: "0px",
    shadowColor: { r: 7, g: 28, b: 26, a: 0.17 },
    "border-radius": "0px",
  },
  ".photo-gallery-section .gallery-catdata .gallery-img::before": {
    "font-family": "FontAwesome",
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "30px",
  },
  ".photo-gallery-section .gallery-catdata .box:hover .gallery-img::before": {
    opacity: "1",
  },
  ".photo-gallery-section .gallery-catdata .gallery-img .title-text": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    "font-size": "16px",
    "font-weight": "600",
    color: { r: 255, g: 255, b: 255, a: 100 },
    "text-align": "center",
    padding: "14px",
  },
  ".photo-gallery-section .more-btn": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".photo-gallery-section .more-btn:hover": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".pagination .page-item.active .page-link": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    "border-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  custom: "",
  galleryImgId: "",
  showItem: 4,
};

export const imagesGalleryThemeTwoReset = {
  ".certificates-section": {
    padding: "100px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".certificates-section.bg-image": {
    "background-image": "",
  },
  ".certificates-section.bg-image::before": {
    "background-color": "",
    // "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
  },
  ".certificates-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".certificates-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: { r: 255, g: 73, b: 124, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 0.2 },
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".certificates-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: { r: 0, g: 0, b: 0, a: 100 },
  },
  ".certificates-section .certificates-list .box": {
    "margin-bottom": "20px",
    outline: "none",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
    "box-shadow": "0 5px 10px 0 { r: 7, g: 28, b: 26, a: 0.17 }",
    bxs1: "5px",
    bxs2: "10px",
    bxsColor: "{ r: 7, g: 28, b: 26, a: 0.17 }",
    "border-radius": "0px",
  },
  ".certificates-section .certificates-list .certificates-img::before": {
    opacity: "0",
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "30px",
  },
  ".certificates-section .certificates-list .box:hover .certificates-img::before":
    {
      opacity: "1",
    },
  ".certificates-section .certificates-list .title-text": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    "font-size": "18px",
    "font-weight": "600",
    color: { r: 255, g: 255, b: 255, a: 100 },
    "text-align": "center",
    padding: "15px",
  },
  ".gallery-catlist": {
    "text-align": "center",
    "margin-bottom": "30px",
  },
  ".gallery-catlist .btn": {
    border: "2px solid { r: 255, g: 73, b: 124, a: 100 }",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 73, b: 124, a: 100 },
    padding: "6px 20px",
    p1: "6px",
    p2: "20px",
    margin: "5px",
    "box-shadow": "none",
  },
  ".gallery-catlist .active": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".certificates-section .more-btn": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "16px",
    "font-weight": "normal",
    "border-radius": "15px",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".certificates-section .more-btn:hover": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".pagination-positions": {
    "justify-content": "center",
  },
  ".pagination .page-link": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".pagination .page-item.active .page-link": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    "border-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  galleryImgId: "",
  showItem: 4,
  custom: "",
};
export const themeOneFunction = ({ settingFormData, boxSection }) => {
  let newData = {
    ...settingFormData,
    ".photo-gallery-section": boxSection
      ? {
          ...settingFormData[".photo-gallery-section"],
          padding: `${settingFormData[".photo-gallery-section"]["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px`,
          width: `calc(100% - ${settingFormData[".photo-gallery-section"][
            "width"
          ]?.replace("rem)", "")}rem)`,
          margin: `${settingFormData[".photo-gallery-section"]["margin"]
            ?.split(" ")[0]
            ?.replace("px", "")}px auto`,
          "border-radius": `${settingFormData[".photo-gallery-section"][
            "border-radius"
          ]?.replace("rem", "")}rem`,
        }
      : {
          ...settingFormData[".photo-gallery-section"],
          padding: `${settingFormData[".photo-gallery-section"]["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px`,
        },
    ".photo-gallery-section.bg-image": settingFormData[
      ".photo-gallery-section.bg-image"
    ]["background-image"]
      ? {
          "background-image": settingFormData[
            ".photo-gallery-section.bg-image"
          ]["background-image"]
            ? addUrlImage({
                urlString: removeUrlImage({
                  urlString:
                    settingFormData[".photo-gallery-section.bg-image"][
                      "background-image"
                    ],
                }),
              })
            : "",
        }
      : {},
    ".photo-gallery-section.bg-image::before": {
      ...settingFormData[".photo-gallery-section.bg-image::before"],
      "background-color": settingFormData[
        ".photo-gallery-section.bg-image::before"
      ]["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".photo-gallery-section.bg-image::before"][
                "background-color"
              ],
          })
        : "",
    },
    ".photo-gallery-section .section-title-area": {
      ...settingFormData[".photo-gallery-section .section-title-area"],
      "margin-bottom": `${settingFormData[
        ".photo-gallery-section .section-title-area"
      ]["margin-bottom"]
        ?.split(" ")[0]
        ?.replace("px", "")}px`,
    },
    ".photo-gallery-section .section-title-area .section-subtitle": {
      ...settingFormData[
        ".photo-gallery-section .section-title-area .section-subtitle"
      ],
      "font-size": `${settingFormData[
        ".photo-gallery-section .section-title-area .section-subtitle"
      ]["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".photo-gallery-section .section-title-area .section-subtitle"]["font-weight"]}`,
      color: settingFormData[
        ".photo-gallery-section .section-title-area .section-subtitle"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".photo-gallery-section .section-title-area .section-subtitle"
              ]?.["color"],
          })
        : "",
      "background-color": settingFormData[
        ".photo-gallery-section .section-title-area .section-subtitle"
      ]["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".photo-gallery-section .section-title-area .section-subtitle"
              ]["background-color"],
          })
        : "",
      padding: `${settingFormData[
        ".photo-gallery-section .section-title-area .section-subtitle"
      ]["p1"]?.replace("px", "")}px ${settingFormData[
        ".photo-gallery-section .section-title-area .section-subtitle"
      ]["p2"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData[
        ".photo-gallery-section .section-title-area .section-subtitle"
      ]["border-radius"]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData[
        ".photo-gallery-section .section-title-area .section-subtitle"
      ]["margin-bottom"]?.replace("px", "")}px`,
    },
    ".photo-gallery-section .section-title-area .section-title": {
      ...settingFormData[
        ".photo-gallery-section .section-title-area .section-title"
      ],
      "font-size": `${settingFormData[
        ".photo-gallery-section .section-title-area .section-title"
      ]["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".photo-gallery-section .section-title-area .section-title"]["font-weight"]}`,
      color: settingFormData[
        ".photo-gallery-section .section-title-area .section-title"
      ]["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".photo-gallery-section .section-title-area .section-title"
              ]["color"],
          })
        : "",
    },
    ".photo-gallery-section .gallery-catlist": {
      ...settingFormData[".photo-gallery-section .gallery-catlist"],
      "text-align": `${settingFormData[".photo-gallery-section .gallery-catlist"]["text-align"]}`,
      "margin-bottom": `${settingFormData[
        ".photo-gallery-section .gallery-catlist"
      ]["margin-bottom"]?.replace("px", "")}px`,
    },
    ".photo-gallery-section .gallery-catlist .btn": {
      ...settingFormData[".photo-gallery-section .gallery-catlist .btn"],
      border: `${settingFormData[
        ".photo-gallery-section .gallery-catlist .btn"
      ]["borderPx"]?.replace("px", "")}px ${
        settingFormData[".photo-gallery-section .gallery-catlist .btn"][
          "borderThik"
        ]
      } ${rgbColorCreate({
        rgbString:
          settingFormData[".photo-gallery-section .gallery-catlist .btn"][
            "borderColor"
          ],
      })}`,
      color: settingFormData[".photo-gallery-section .gallery-catlist .btn"][
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".photo-gallery-section .gallery-catlist .btn"][
                "color"
              ],
          })
        : "",
      padding: `${settingFormData[
        ".photo-gallery-section .gallery-catlist .btn"
      ]["p1"]?.replace("px", "")}px ${settingFormData[
        ".photo-gallery-section .gallery-catlist .btn"
      ]["p2"]?.replace("px", "")}px`,
      margin: `${settingFormData[
        ".photo-gallery-section .gallery-catlist .btn"
      ]["margin"]?.replace("px", "")}px`,
      "box-shadow": `${settingFormData[".photo-gallery-section .gallery-catlist .btn"]["box-shadow"]}`,
    },
    ".photo-gallery-section .gallery-catlist .active": {
      ...settingFormData[".photo-gallery-section .gallery-catlist .active"],
      "background-color": settingFormData[
        ".photo-gallery-section .gallery-catlist .active"
      ]["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".photo-gallery-section .gallery-catlist .active"
              ]["background-color"],
          })
        : "",
      color: settingFormData[".photo-gallery-section .gallery-catlist .active"][
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".photo-gallery-section .gallery-catlist .active"
              ]["color"],
          })
        : "",
    },
    ".photo-gallery-section .gallery-catdata .box": {
      ...settingFormData[".photo-gallery-section .gallery-catdata .box"],
      "margin-bottom": `${settingFormData[
        ".photo-gallery-section .gallery-catdata .box"
      ]["margin-bottom"]?.replace("px", "")}px`,
      outline: "none",
      "background-color": settingFormData[
        ".photo-gallery-section .gallery-catdata .box"
      ]["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".photo-gallery-section .gallery-catdata .box"][
                "background-color"
              ],
          })
        : "",
      "box-shadow": `${settingFormData[
        ".photo-gallery-section .gallery-catdata .box"
      ]["bs1"]?.replace("px", "")}px ${settingFormData[
        ".photo-gallery-section .gallery-catdata .box"
      ]["bs2"]?.replace("px", "")}px ${settingFormData[
        ".photo-gallery-section .gallery-catdata .box"
      ]["bs3"]?.replace("px", "")}px ${settingFormData[
        ".photo-gallery-section .gallery-catdata .box"
      ]["bs4"]?.replace("px", "")}px ${rgbColorCreate({
        rgbString:
          settingFormData[".photo-gallery-section .gallery-catdata .box"][
            "shadowColor"
          ],
      })}`,
      "border-radius": `${settingFormData[
        ".photo-gallery-section .gallery-catdata .box"
      ]["border-radius"]?.replace("px", "")}px`,
      overflow: "hidden",
    },
    ".photo-gallery-section .gallery-catdata .gallery-img::before": {
      ...settingFormData[
        ".photo-gallery-section .gallery-catdata .gallery-img::before"
      ],
      "font-family": `${settingFormData?.[".photo-gallery-section .gallery-catdata .gallery-img::before"]?.["font-family"]}`,
      color: settingFormData[
        ".photo-gallery-section .gallery-catdata .gallery-img::before"
      ]["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".photo-gallery-section .gallery-catdata .gallery-img::before"
              ]["color"],
          })
        : "",
      "font-size": `${settingFormData?.[
        ".photo-gallery-section .gallery-catdata .gallery-img::before"
      ]?.["font-size"]?.replace("px", "")}px`,
    },
    ".photo-gallery-section .gallery-catdata .box:hover .gallery-img::before": {
      ...settingFormData[
        ".photo-gallery-section .gallery-catdata .box:hover .gallery-img::before"
      ],
      opacity: `${settingFormData?.[".photo-gallery-section .gallery-catdata .box:hover .gallery-img::before"]?.["opacity"]}`,
    },
    ".photo-gallery-section .gallery-catdata .gallery-img .title-text": {
      ...settingFormData[
        ".photo-gallery-section .gallery-catdata .gallery-img .title-text"
      ],
      "background-color": settingFormData[
        ".photo-gallery-section .gallery-catdata .gallery-img .title-text"
      ]["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".photo-gallery-section .gallery-catdata .gallery-img .title-text"
              ]["background-color"],
          })
        : "",
      "font-size": `${settingFormData?.[
        ".photo-gallery-section .gallery-catdata .gallery-img .title-text"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".photo-gallery-section .gallery-catdata .gallery-img .title-text"]?.["font-weight"]}`,
      color: settingFormData[
        ".photo-gallery-section .gallery-catdata .gallery-img .title-text"
      ]["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".photo-gallery-section .gallery-catdata .gallery-img .title-text"
              ]["color"],
          })
        : "",
      "text-align": `${settingFormData?.[".photo-gallery-section .gallery-catdata .gallery-img .title-text"]?.["text-align"]}`,
      padding: `${settingFormData?.[
        ".photo-gallery-section .gallery-catdata .gallery-img .title-text"
      ]["padding"]?.replace("px", "")}px`,
    },
    showItem: settingFormData["showItem"],
  };
  return newData;
};
export const themeTwoFunction = ({ settingFormData, boxSection }) => {
  let newData = {
    // ...settingFormData,
    ".certificates-section": boxSection
      ? {
          ...settingFormData[".certificates-section"],
          padding: `${settingFormData[".certificates-section"]["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px`,
          width: `calc(100% - ${settingFormData[".certificates-section"][
            "width"
          ]?.replace("rem)", "")}rem)`,
          margin: `${settingFormData[".certificates-section"]["margin"]
            ?.split(" ")[0]
            ?.replace("px", "")}px auto`,
          "border-radius": `${settingFormData[".certificates-section"][
            "border-radius"
          ]?.replace("rem", "")}rem`,
        }
      : {
          ...settingFormData[".certificates-section"],
          padding: `${settingFormData[".certificates-section"]["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px`,
        },
    ".gallery-catlist": {
      ...settingFormData[".gallery-catlist"],
      "text-align": `${settingFormData[".gallery-catlist"]["text-align"]}`,
      "margin-bottom": `${settingFormData[".gallery-catlist"][
        "margin-bottom"
      ]?.replace("px", "")}px`,
    },
    ".gallery-catlist .btn": {
      ...settingFormData[".gallery-catlist .btn"],
      border: `${settingFormData[".gallery-catlist .btn"]["borderPx"]?.replace(
        "px",
        ""
      )}px ${
        settingFormData[".gallery-catlist .btn"]["borderThik"]
      } ${rgbColorCreate({
        rgbString: settingFormData[".gallery-catlist .btn"]["borderColor"],
      })}`,
      color: settingFormData[".gallery-catlist .btn"]["color"]
        ? rgbColorCreate({
            rgbString: settingFormData[".gallery-catlist .btn"]["color"],
          })
        : "",
      padding: `${settingFormData[".gallery-catlist .btn"]["p1"]?.replace(
        "px",
        ""
      )}px ${settingFormData[".gallery-catlist .btn"]["p2"]?.replace(
        "px",
        ""
      )}px`,
      margin: `${settingFormData[".gallery-catlist .btn"]["margin"]?.replace(
        "px",
        ""
      )}px`,
      "box-shadow": `${settingFormData[".gallery-catlist .btn"]["box-shadow"]}`,
    },
    ".gallery-catlist .active": {
      ...settingFormData[".gallery-catlist .active"],
      "background-color": settingFormData[".gallery-catlist .active"][
        "background-color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".gallery-catlist .active"]["background-color"],
          })
        : "",
      color: settingFormData[".gallery-catlist .active"]["color"]
        ? rgbColorCreate({
            rgbString: settingFormData[".gallery-catlist .active"]["color"],
          })
        : "",
    },
    ".certificates-section.bg-image": settingFormData[
      ".certificates-section.bg-image"
    ]["background-image"]
      ? {
          "background-image": settingFormData[".certificates-section.bg-image"][
            "background-image"
          ]
            ? addUrlImage({
                urlString: removeUrlImage({
                  urlString:
                    settingFormData[".certificates-section.bg-image"][
                      "background-image"
                    ],
                }),
              })
            : "",
        }
      : {},
    ".certificates-section.bg-image::before": settingFormData[
      ".certificates-section.bg-image::before"
    ]["background-color"]
      ? {
          ...settingFormData[".certificates-section.bg-image::before"],
          "background-color": settingFormData[
            ".certificates-section.bg-image::before"
          ]["background-color"]
            ? rgbColorCreate({
                rgbString:
                  settingFormData[".certificates-section.bg-image::before"][
                    "background-color"
                  ],
              })
            : "",
        }
      : {},
    ".certificates-section .section-title-area": {
      ...settingFormData[".certificates-section .section-title-area"],
      "margin-bottom": `${settingFormData[
        ".certificates-section .section-title-area"
      ]["margin-bottom"]
        ?.split(" ")[0]
        ?.replace("px", "")}px`,
    },
    ".certificates-section .section-title-area .section-subtitle": {
      ...settingFormData[
        ".certificates-section .section-title-area .section-subtitle"
      ],
      "font-size": `${settingFormData[
        ".certificates-section .section-title-area .section-subtitle"
      ]["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".certificates-section .section-title-area .section-subtitle"]["font-weight"]}`,
      color: settingFormData[
        ".certificates-section .section-title-area .section-subtitle"
      ]["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".certificates-section .section-title-area .section-subtitle"
              ]["color"],
          })
        : "",
      "background-color": settingFormData[
        ".certificates-section .section-title-area .section-subtitle"
      ]["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".certificates-section .section-title-area .section-subtitle"
              ]["background-color"],
          })
        : "",
      padding: `${settingFormData[
        ".certificates-section .section-title-area .section-subtitle"
      ]["p1"]?.replace("px", "")}px ${settingFormData[
        ".certificates-section .section-title-area .section-subtitle"
      ]["p2"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData[
        ".certificates-section .section-title-area .section-subtitle"
      ]["border-radius"]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData[
        ".certificates-section .section-title-area .section-subtitle"
      ]["margin-bottom"]?.replace("px", "")}px`,
    },
    ".certificates-section .section-title-area .section-title": {
      ...settingFormData[
        ".certificates-section .section-title-area .section-title"
      ],
      "font-size": `${settingFormData[
        ".certificates-section .section-title-area .section-title"
      ]["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".certificates-section .section-title-area .section-title"]["font-weight"]}`,
      color: settingFormData[
        ".certificates-section .section-title-area .section-title"
      ]["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".certificates-section .section-title-area .section-title"
              ]["color"],
          })
        : "",
    },
    ".certificates-section .certificates-list .box": {
      ...settingFormData[".certificates-section .certificates-list .box"],
      "margin-bottom": `${settingFormData[
        ".certificates-section .certificates-list .box"
      ]["margin-bottom"]?.replace("px", "")}px`,
      "box-shadow": `0 ${settingFormData[
        ".certificates-section .certificates-list .box"
      ]["bxs1"]?.replace("px", "")}px ${settingFormData[
        ".certificates-section .certificates-list .box"
      ]["bxs2"]?.replace("px", "")}px 0 ${rgbColorCreate({
        rgbString:
          settingFormData[".certificates-section .certificates-list .box"][
            "bxsColor"
          ],
      })}`,
      "border-radius": `${settingFormData[
        ".certificates-section .certificates-list .box"
      ]["border-radius"]?.replace("px", "")}px`,
      overflow: "hidden",
    },
    ".certificates-section .certificates-list .certificates-img::before": {
      ...settingFormData[
        ".certificates-section .certificates-list .certificates-img::before"
      ],
      color: settingFormData[
        ".certificates-section .certificates-list .certificates-img::before"
      ]["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".certificates-section .certificates-list .certificates-img::before"
              ]["color"],
          })
        : "",
      "font-size": `${settingFormData[
        ".certificates-section .certificates-list .certificates-img::before"
      ]["font-size"]?.replace("px", "")}px`,
    },
    ".certificates-section .certificates-list .box:hover .certificates-img::before":
      {
        ...settingFormData[
          ".certificates-section .certificates-list .box:hover .certificates-img::before"
        ],
      },
    ".certificates-section .certificates-list .title-text": {
      ...settingFormData[
        ".certificates-section .certificates-list .title-text"
      ],
      color: settingFormData[
        ".certificates-section .certificates-list .title-text"
      ]["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".certificates-section .certificates-list .title-text"
              ]["color"],
          })
        : "",
      "background-color": settingFormData[
        ".certificates-section .certificates-list .title-text"
      ]["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".certificates-section .certificates-list .title-text"
              ]["background-color"],
          })
        : "",
    },
    showItem: settingFormData["showItem"],
    custom: settingFormData["custom"],
  };
  return newData;
};

export const ThemeOneStyleSet = ({ style, settingFormData }) => {
  return {
    ...settingFormData,
    showItem: style.showItem,
    ".photo-gallery-section": {
      padding: style?.[".photo-gallery-section"]?.["padding"],
      "background-color":
        style?.[".photo-gallery-section"]?.["background-color"],
      width: style?.[".photo-gallery-section"]?.["width"]?.replace(
        "calc(100% - ",
        ""
      ),
      margin: style?.[".photo-gallery-section"]?.["margin"],
      "border-radius": style?.[".photo-gallery-section"]?.["border-radius"],
    },
    ".photo-gallery-section.bg-image": {
      "background-image": style?.[".photo-gallery-section.bg-image"]?.[
        "background-image"
      ]
        ? removeUrlKeyInPath({
            urlString:
              style?.[".photo-gallery-section.bg-image"]?.["background-image"],
          })
        : "",
    },
    ".photo-gallery-section.bg-image::before": {
      "background-color": style?.[".photo-gallery-section.bg-image::before"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              style?.[".photo-gallery-section.bg-image::before"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".photo-gallery-section .section-title-area": {
      "margin-bottom":
        style?.[".photo-gallery-section .section-title-area"]?.[
          "margin-bottom"
        ],
    },
    ".photo-gallery-section .section-title-area .section-subtitle": {
      "font-size":
        style?.[
          ".photo-gallery-section .section-title-area .section-subtitle"
        ]?.["font-size"],
      "font-weight":
        style?.[
          ".photo-gallery-section .section-title-area .section-subtitle"
        ]?.["font-weight"],
      color: style?.[
        ".photo-gallery-section .section-title-area .section-subtitle"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              style?.[
                ".photo-gallery-section .section-title-area .section-subtitle"
              ]?.["color"],
          })
        : "",
      "background-color": style?.[
        ".photo-gallery-section .section-title-area .section-subtitle"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              style?.[
                ".photo-gallery-section .section-title-area .section-subtitle"
              ]?.["background-color"],
          })
        : "",
      padding:
        style?.[
          ".photo-gallery-section .section-title-area .section-subtitle"
        ]?.["padding"],
      p1: style?.[
        ".photo-gallery-section .section-title-area .section-subtitle"
      ]?.["padding"]?.split(" ")[0],
      p2: style?.[
        ".photo-gallery-section .section-title-area .section-subtitle"
      ]?.["padding"]?.split(" ")[1],
      "border-radius":
        style?.[
          ".photo-gallery-section .section-title-area .section-subtitle"
        ]?.["border-radius"],
      "margin-bottom":
        style?.[
          ".photo-gallery-section .section-title-area .section-subtitle"
        ]?.["margin-bottom"],
    },
    ".photo-gallery-section .section-title-area .section-title": {
      "font-size":
        style?.[".photo-gallery-section .section-title-area .section-title"]?.[
          "font-size"
        ],
      "font-weight":
        style?.[".photo-gallery-section .section-title-area .section-title"]?.[
          "font-weight"
        ],
      color: style?.[
        ".photo-gallery-section .section-title-area .section-title"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              style?.[
                ".photo-gallery-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".photo-gallery-section .gallery-catlist": {
      "text-align":
        style?.[".photo-gallery-section .gallery-catlist"]?.["text-align"],
      "margin-bottom":
        style?.[".photo-gallery-section .gallery-catlist"]?.["margin-bottom"],
    },
    ".photo-gallery-section .gallery-catlist .btn": {
      border:
        style?.[".photo-gallery-section .gallery-catlist .btn"]?.["border"],
      borderPx:
        style?.[".photo-gallery-section .gallery-catlist .btn"]?.[
          "border"
        ]?.split(" ")[0],
      borderThik:
        style?.[".photo-gallery-section .gallery-catlist .btn"]?.[
          "border"
        ]?.split(" ")[1],
      borderColor: convertRGBA({
        rgbString: getColorFromString({
          colorString:
            style?.[".photo-gallery-section .gallery-catlist .btn"]?.["border"],
        }),
      }),
      color: style?.[".photo-gallery-section .gallery-catlist .btn"]?.["color"]
        ? convertRGBA({
            rgbString:
              style?.[".photo-gallery-section .gallery-catlist .btn"]?.[
                "color"
              ],
          })
        : "",
      padding:
        style?.[".photo-gallery-section .gallery-catlist .btn"]?.["padding"],
      p1: style?.[".photo-gallery-section .gallery-catlist .btn"]?.[
        "padding"
      ]?.split(" ")[0],
      p2: style?.[".photo-gallery-section .gallery-catlist .btn"]?.[
        "padding"
      ]?.split(" ")[1],
      margin:
        style?.[".photo-gallery-section .gallery-catlist .btn"]?.["margin"],
      "box-shadow":
        style?.[".photo-gallery-section .gallery-catlist .btn"]?.["box-shadow"],
    },
    ".photo-gallery-section .gallery-catlist .active": {
      "background-color": style?.[
        ".photo-gallery-section .gallery-catlist .active"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              style?.[".photo-gallery-section .gallery-catlist .active"]?.[
                "background-color"
              ],
          })
        : "",
      color: style?.[".photo-gallery-section .gallery-catlist .active"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString:
              style?.[".photo-gallery-section .gallery-catlist .active"]?.[
                "color"
              ],
          })
        : "",
    },
    ".photo-gallery-section .gallery-catdata .box": {
      "margin-bottom":
        style?.[".photo-gallery-section .gallery-catdata .box"]?.[
          "margin-bottom"
        ],
      outline: "none",
      "background-color": style?.[
        ".photo-gallery-section .gallery-catdata .box"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              style?.[".photo-gallery-section .gallery-catdata .box"]?.[
                "background-color"
              ],
          })
        : "",
      "box-shadow":
        style?.[".photo-gallery-section .gallery-catdata .box"]?.["box-shadow"],
      bs1: style?.[".photo-gallery-section .gallery-catdata .box"]?.[
        "box-shadow"
      ]?.split(" ")[0],
      bs2: style?.[".photo-gallery-section .gallery-catdata .box"]?.[
        "box-shadow"
      ]?.split(" ")[1],
      bs3: style?.[".photo-gallery-section .gallery-catdata .box"]?.[
        "box-shadow"
      ]?.split(" ")[2],
      bs4: style?.[".photo-gallery-section .gallery-catdata .box"]?.[
        "box-shadow"
      ]?.split(" ")[3],
      shadowColor: convertRGBA({
        rgbString: getColorFromString({
          colorString:
            style?.[".photo-gallery-section .gallery-catdata .box"]?.[
              "box-shadow"
            ],
        }),
      }),
      "border-radius":
        style?.[".photo-gallery-section .gallery-catdata .box"]?.[
          "border-radius"
        ],
    },
    ".photo-gallery-section .gallery-catdata .gallery-img::before": {
      "font-family":
        style?.[
          ".photo-gallery-section .gallery-catdata .gallery-img::before"
        ]?.["font-family"],
      color: style?.[
        ".photo-gallery-section .gallery-catdata .gallery-img::before"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              style?.[
                ".photo-gallery-section .gallery-catdata .gallery-img::before"
              ]?.["color"],
          })
        : "",
      "font-size":
        style?.[
          ".photo-gallery-section .gallery-catdata .gallery-img::before"
        ]?.["font-size"],
    },
    ".photo-gallery-section .gallery-catdata .box:hover .gallery-img::before": {
      opacity:
        style?.[
          ".photo-gallery-section .gallery-catdata .box:hover .gallery-img::before"
        ]?.["opacity"],
    },
    ".photo-gallery-section .gallery-catdata .gallery-img .title-text": {
      "background-color": style?.[
        ".photo-gallery-section .gallery-catdata .gallery-img .title-text"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              style?.[
                ".photo-gallery-section .gallery-catdata .gallery-img .title-text"
              ]?.["background-color"],
          })
        : "",
      "font-size":
        style?.[
          ".photo-gallery-section .gallery-catdata .gallery-img .title-text"
        ]?.["font-size"],
      "font-weight":
        style?.[
          ".photo-gallery-section .gallery-catdata .gallery-img .title-text"
        ]?.["font-weight"],
      color: style?.[
        ".photo-gallery-section .gallery-catdata .gallery-img .title-text"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              style?.[
                ".photo-gallery-section .gallery-catdata .gallery-img .title-text"
              ]?.["color"],
          })
        : "",
      "text-align":
        style?.[
          ".photo-gallery-section .gallery-catdata .gallery-img .title-text"
        ]?.["text-align"],
      padding:
        style?.[
          ".photo-gallery-section .gallery-catdata .gallery-img .title-text"
        ]?.["padding"],
    },
  };
};

export const ThemeTwoStyleSet = ({ style, settingFormData }) => {
  return {
    ...settingFormData,
    showItem: style.showItem,
    ".gallery-catlist": {
      "text-align": style?.[".gallery-catlist"]?.["text-align"],
      "margin-bottom": style?.[".gallery-catlist"]?.["margin-bottom"],
    },
    ".gallery-catlist .btn": {
      border: style?.[".gallery-catlist .btn"]?.["border"],
      borderPx: style?.[".gallery-catlist .btn"]?.["border"]?.split(" ")[0],
      borderThik: style?.[".gallery-catlist .btn"]?.["border"]?.split(" ")[1],
      borderColor: convertRGBA({
        rgbString: getColorFromString({
          colorString: style?.[".gallery-catlist .btn"]?.["border"],
        }),
      }),
      color: style?.[".gallery-catlist .btn"]?.["color"]
        ? convertRGBA({
            rgbString: style?.[".gallery-catlist .btn"]?.["color"],
          })
        : "",
      padding: style?.[".gallery-catlist .btn"]?.["padding"],
      p1: style?.[".gallery-catlist .btn"]?.["padding"]?.split(" ")[0],
      p2: style?.[".gallery-catlist .btn"]?.["padding"]?.split(" ")[1],
      margin: style?.[".gallery-catlist .btn"]?.["margin"],
      "box-shadow": style?.[".gallery-catlist .btn"]?.["box-shadow"],
    },
    ".gallery-catlist .active": {
      "background-color": style?.[".gallery-catlist .active"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              style?.[".gallery-catlist .active"]?.["background-color"],
          })
        : "",
      color: style?.[".gallery-catlist .active"]?.["color"]
        ? convertRGBA({
            rgbString: style?.[".gallery-catlist .active"]?.["color"],
          })
        : "",
    },
    ".certificates-section": {
      padding: style?.[".certificates-section"]?.["padding"],
      "background-color": style?.[".certificates-section"]?.["background-color"]
        ? convertRGBA({
            rgbString: style?.[".certificates-section"]?.["background-color"],
          })
        : "",
      width: style?.[".certificates-section"]?.["width"]?.replace(
        "calc(100% - ",
        ""
      ),
      margin: style?.[".certificates-section"]?.["margin"],
      "border-radius": style?.[".certificates-section"]?.["border-radius"],
    },
    ".certificates-section.bg-image": {
      "background-image": style?.[".certificates-section.bg-image"]?.[
        "background-image"
      ]
        ? removeUrlKeyInPath({
            urlString:
              style?.[".certificates-section.bg-image"]?.["background-image"],
          })
        : "",
    },
    ".certificates-section.bg-image::before": {
      "background-color": style?.[".certificates-section.bg-image::before"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              style?.[".certificates-section.bg-image::before"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".certificates-section .section-title-area": {
      "margin-bottom":
        style?.[".certificates-section .section-title-area"]?.["margin-bottom"],
    },
    ".certificates-section .section-title-area .section-subtitle": {
      "font-size":
        style?.[
          ".certificates-section .section-title-area .section-subtitle"
        ]?.["font-size"],
      "font-weight":
        style?.[
          ".certificates-section .section-title-area .section-subtitle"
        ]?.["font-weight"],
      color: style?.[
        ".certificates-section .section-title-area .section-subtitle"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              style?.[
                ".certificates-section .section-title-area .section-subtitle"
              ]?.["color"],
          })
        : "",
      "background-color": style?.[
        ".certificates-section .section-title-area .section-subtitle"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              style?.[
                ".certificates-section .section-title-area .section-subtitle"
              ]?.["background-color"],
          })
        : "",
      padding:
        style?.[
          ".certificates-section .section-title-area .section-subtitle"
        ]?.["padding"],
      p1: style?.[
        ".certificates-section .section-title-area .section-subtitle"
      ]?.["padding"]?.split(" ")[0],
      p2: style?.[
        ".certificates-section .section-title-area .section-subtitle"
      ]?.["padding"]?.split(" ")[1],
      "border-radius":
        style?.[
          ".certificates-section .section-title-area .section-subtitle"
        ]?.["border-radius"],
      "margin-bottom":
        style?.[
          ".certificates-section .section-title-area .section-subtitle"
        ]?.["margin-bottom"],
    },
    ".certificates-section .section-title-area .section-title": {
      "font-size":
        style?.[".certificates-section .section-title-area .section-title"]?.[
          "font-size"
        ],
      "font-weight":
        style?.[".certificates-section .section-title-area .section-title"]?.[
          "font-weight"
        ],
      color: style?.[
        ".certificates-section .section-title-area .section-title"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              style?.[
                ".certificates-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".certificates-section .certificates-list .box": {
      "margin-bottom":
        style?.[".certificates-section .certificates-list .box"]?.[
          "margin-bottom"
        ],
      "background-color": style?.[
        ".certificates-section .certificates-list .box"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              style?.[".certificates-section .certificates-list .box"]?.[
                "background-color"
              ],
          })
        : "",
      "box-shadow":
        style?.[".certificates-section .certificates-list .box"]?.[
          "box-shadow"
        ],
      bxs1: style?.[".certificates-section .certificates-list .box"]?.[
        "box-shadow"
      ]?.split(" ")[1],
      bxs2: style?.[".certificates-section .certificates-list .box"]?.[
        "box-shadow"
      ]?.split(" ")[2],
      bxsColor: convertRGBA({
        rgbString: getColorFromString({
          colorString:
            style?.[".certificates-section .certificates-list .box"]?.[
              "box-shadow"
            ],
        }),
      }),
      "border-radius":
        style?.[".certificates-section .certificates-list .box"]?.[
          "border-radius"
        ],
    },
    ".certificates-section .certificates-list .certificates-img::before": {
      opacity:
        style?.[
          ".certificates-section .certificates-list .certificates-img::before"
        ]?.["opacity"],
      color: style?.[
        ".certificates-section .certificates-list .certificates-img::before"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              style?.[
                ".certificates-section .certificates-list .certificates-img::before"
              ]?.["color"],
          })
        : "",
      "font-size":
        style?.[
          ".certificates-section .certificates-list .certificates-img::before"
        ]?.["font-size"],
    },
    ".certificates-section .certificates-list .box:hover .certificates-img::before":
      {
        opacity:
          style?.[
            ".certificates-section .certificates-list .box:hover .certificates-img::before"
          ]?.["opacity"],
      },
    ".certificates-section .certificates-list .title-text": {
      "background-color": style?.[
        ".certificates-section .certificates-list .title-text"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              style?.[".certificates-section .certificates-list .title-text"]?.[
                "background-color"
              ],
          })
        : "",
      color: style?.[".certificates-section .certificates-list .title-text"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString:
              style?.[".certificates-section .certificates-list .title-text"]?.[
                "color"
              ],
          })
        : "",
      "font-size":
        style?.[".certificates-section .certificates-list .title-text"]?.[
          "font-size"
        ],
      "font-weight":
        style?.[".certificates-section .certificates-list .title-text"]?.[
          "font-weight"
        ],
      "text-align":
        style?.[".certificates-section .certificates-list .title-text"]?.[
          "text-align"
        ],
      padding:
        style?.[".certificates-section .certificates-list .title-text"]?.[
          "padding"
        ],
    },
  };
};
