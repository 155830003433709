import * as React from "react";
import { useState } from "react";
import { OrgPageList } from "../../api/Organization Api/OrganizationApi";
import { TYPES } from "../../constant";
import BrokerPageContaxt from "./BrokerPageContaxt";

const BrokerPageState = (props) => {
  const [formvalue, setformValue] = useState([]);
  const [pageLimit, setPageLimit] = useState("");
  const [togglesubmit, setToggle] = useState(false);
  const [title, setTitle] = useState(true);
  const [clone, setClone] = useState(true);
  const [priviewPopup, setPreviewPopup] = useState(false);
  const [callToActionPopup, setCalltoActionPopup] = useState(false);
  const [calltoActionData, setCalltoActionData] = useState([]);
  const [landingName, setLandingName] = useState("");
  const [priviewArr, setPreviewArr] = useState([]);
  const [categories, setCategories] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [brokerId, setBrokerId] = useState("");
  const [chartTitle, setChartTitle] = useState("");

  const [change, setChange] = useState("");
  const [label, setLabel] = useState("");
  const [value, setValue] = useState("");
  const [shareValue, setShareValue] = useState("false");
  const [type, setType] = useState("");
  const [logo, setLogo] = useState("");
  const [dialCode, setDialCode] = useState("+91");

  const [orgId, setOrgId] = useState("");

  const [sortOrder, setSortOrder] = useState(""); //SORTING -->
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState("createdAt"); //SORTING -->
  const [record, setRecord] = useState(0);
  const [progress, setProgress] = useState(0);
  const [pageUuid, setPageUuid] = useState("");
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [editIndex, seteditIndex] = useState(-1);

  const [brokerIncentiveListsPre, setBrokerIncentiveListsPre] = useState([]);
  const [incentivePageSize, setIncentivePageSize] = useState(10);
  const [incentivePage, setIncentivePage] = useState(1);
  const [incentiveRecord, setIncentiveRecord] = useState(0);
  const [incentiveId, setIncentiveId] = useState();

  const [incentiveModel, setIncentiveModel] = useState(false);
  const [qpiId, setQpiId] = useState(0);

  /**showDetails State */

  const [allDetails, setAllDetails] = useState("");

  const orgPageList = async (filter) => {
    try {
      let paramObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
        search: { type: TYPES.BROKER },
      };

      if (filter) {
        paramObj.search = { ...paramObj.search, pageTitle: filter };
      }
      const { data } = await OrgPageList(paramObj);

      if (data.status === 200) {
        setformValue(data.payload.data);
        setPageLimit(data.payload.brokerLimit);
        setRecord(data.pager.totalRecords);
      } else {
      }
    } catch (error) {}
  };

  return (
    <>
      <BrokerPageContaxt.Provider
        value={{
          orgPageList,
          formvalue,
          setformValue,
          togglesubmit,
          setToggle,
          sortOrder,
          setSortOrder,
          pageSize,
          setPageSize,
          page,
          setPage,
          sortBy,
          setSortBy,
          record,
          setRecord,
          progress,
          setProgress,
          pageUuid,
          setPageUuid,
          clone,
          setClone,
          isSwitchOn,
          setIsSwitchOn,
          title,
          setTitle,
          qpiId,
          setQpiId,
          editIndex,
          seteditIndex,
          change,
          setChange,
          label,
          setLabel,
          value,
          setValue,
          orgId,
          setOrgId,
          allDetails,
          setAllDetails,
          type,
          setType,
          logo,
          setLogo,
          pageLimit,
          setPageLimit,
          shareValue,
          setShareValue,
          priviewPopup,
          setPreviewPopup,
          priviewArr,
          setPreviewArr,
          brokerId,
          setBrokerId,
          categories,
          setCategories,
          chartData,
          setChartData,
          chartTitle,
          setChartTitle,
          callToActionPopup,
          setCalltoActionPopup,
          calltoActionData,
          setCalltoActionData,
          landingName,
          setLandingName,
          dialCode,
          setDialCode,
          incentiveModel,
          setIncentiveModel,
          incentivePageSize,
          setIncentivePageSize,
          incentivePage,
          setIncentivePage,
          incentiveRecord,
          setIncentiveRecord,
          brokerIncentiveListsPre,
          setBrokerIncentiveListsPre,
          incentiveId,
          setIncentiveId,
        }}
      >
        {props.children}
      </BrokerPageContaxt.Provider>
    </>
  );
};

export default BrokerPageState;
