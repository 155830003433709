import React, { useContext } from "react";
import AboutUsContext from "../../../context/AboutUsContext/AboutUsContext";
import { THEME_UPDATE_MESSAGE, ThemeNo } from "../../../../constant";
import AboutUsThemeOne from "./AboutUsThemeOne";
import AboutUsThemeTwo from "./AboutUsThemeTwo";
import { aboutStyleOneReset, aboutStyleTwoReset } from "./AboutStyleFunction";
import { Button, Col, Container, Row } from "reactstrap";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import { putUpdateAboutUsParent } from "../apis/AboutApi/AboutUsApi";
import { TostMessage } from "../../../utils";
import AboutTheme1 from "../ScreenShorts/AboutUs/AboutTheme1.png";
import AboutTheme2 from "../ScreenShorts/AboutUs/AboutTheme2.png";
import AboutTheme3 from "../ScreenShorts/AboutUs/AboutTheme3.png";
import AboutTheme4 from "../ScreenShorts/AboutUs/AboutTheme4.png";
import ThemeImageShow from "../../../common/ThemeImageShow";

const AboutUsSettingParent = (props) => {
  const { imageUpload, handleSubmitSetting, handleChangeSettings } = props;
  const {
    theme,
    setSettingFormData,
    setBoxSection,
    setBackImageToggle,
    setIsBackgroundColor,
    setTheme,
    id,
    imgShow,
    setImgShow,
  } = useContext(AboutUsContext);
  const { setLoaderShow } = useContext(LoaderContext);
  const resetHandler = () => {
    let setStyle;
    if (theme === ThemeNo.ThemeOne) {
      setStyle = aboutStyleOneReset;
    } else if (theme === ThemeNo.ThemeTwo) {
      setStyle = aboutStyleTwoReset;
    } else if (theme === ThemeNo.ThemeThree) {
      setStyle = aboutStyleOneReset;
    } else if (theme === ThemeNo.ThemeFour) {
      setStyle = aboutStyleTwoReset;
    }
    setSettingFormData({ ...setStyle });
    setBoxSection(false);
    setBackImageToggle(false);
    setIsBackgroundColor(true);
  };
  const handleTheme = async (e) => {
    setLoaderShow(true);
    const { value } = e.target;
    setTheme(value);
    try {
      let formData = {
        theme: value,
        id: id,
      };
      const { data } = await putUpdateAboutUsParent(formData);
      if (data.status === 200) {
        await handleChangeSettings(id, true);
        TostMessage({
          message: { message: THEME_UPDATE_MESSAGE },
          type: "success",
        });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    } finally {
      setLoaderShow(false);
    }
  };
  const handleClose = () => {
    setImgShow(false);
  };
  const themeImg = {
    1: AboutTheme1,
    2: AboutTheme2,
    3: AboutTheme3,
    4: AboutTheme4,
  };
  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="12">
            <div className="mt-4 d-flex flex-column justify-content-center position-relative">
              <div
                className="d-flex justify-content-between position-sticky"
                style={{ top: "87px", background: "white", zIndex: 999 }}
              >
                <h6>{"Edit"}</h6>
                <div className="d-flex gap-2">
                  <Button onClick={resetHandler}>Reset</Button>
                  <Button onClick={() => handleSubmitSetting(true)}>
                    Restore
                  </Button>
                  <Button
                    onClick={() => handleSubmitSetting(null, false)}
                    color="success"
                  >
                    Save & View
                  </Button>
                </div>
              </div>

              <Row>
                <Col>
                  <label htmlFor="">Theme</label>
                  <select
                    name="theme"
                    id="font-weight"
                    className="form-select"
                    value={theme}
                    onChange={(e) => handleTheme(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value={1}>Theme 1</option>
                    <option value={2}>Theme 2</option>
                    <option value={3}>Theme 3</option>
                    <option value={4}>Theme 4</option>
                  </select>
                </Col>
              </Row>
              {!imgShow && (
                <div>
                  <Button
                    onClick={() => setImgShow(true)}
                    color="primary"
                    className="mt-2"
                  >
                    Show Theme Image
                  </Button>
                </div>
              )}
              {imgShow && (
                <ThemeImageShow
                  themeImg={themeImg[theme]}
                  handleClose={handleClose}
                />
              )}
              {+theme === ThemeNo.ThemeOne && (
                <AboutUsThemeOne
                  imageUpload={imageUpload}
                  handleSubmitSetting={handleSubmitSetting}
                />
              )}
              {+theme === ThemeNo.ThemeTwo && (
                <AboutUsThemeTwo
                  imageUpload={imageUpload}
                  handleSubmitSetting={handleSubmitSetting}
                />
              )}
              {+theme === ThemeNo.ThemeThree && (
                <AboutUsThemeOne
                  imageUpload={imageUpload}
                  handleSubmitSetting={handleSubmitSetting}
                />
              )}
              {+theme === ThemeNo.ThemeFour && (
                <AboutUsThemeTwo
                  imageUpload={imageUpload}
                  handleSubmitSetting={handleSubmitSetting}
                />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AboutUsSettingParent;
