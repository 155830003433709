import axios from "axios";
import {
  ADD_WEB_CATEGORY_MAP_LIST,
  DELETE_WEB_CATEGORY_MAP_MULTI_LIST,
  DELETE__WEB_CATEGORY_MAP_LIST,
  DETAILS_WEB_CATEGORY_MAP_LIST,
  EDIT_WEB_CATEGORY_MAP_LIST,
  ORG_WEB_CATEGORY_MAP_PAGE_LIST_ENABLE_DESABLE,
  WEB_CATEGORY_MAP_LIST,
  WEB_CATEGORY_MAP_MULTI_LIST,
} from "../../constant/API constant";
import { SINGLE_PAGE } from "../../constant";

export const WebCategoryMap_List = (paramsObj, pageName) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${
      pageName === SINGLE_PAGE
        ? WEB_CATEGORY_MAP_LIST
        : WEB_CATEGORY_MAP_MULTI_LIST
    }`,
    {
      headers: { Authorization: jwt_token },
      params: paramsObj,
    }
  );
};

export const Add_WebCategoryMap_List = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${ADD_WEB_CATEGORY_MAP_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Edit_WebCategoryMap_List = (formData, id) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${EDIT_WEB_CATEGORY_MAP_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { catWebMapId: id },
    }
  );
};

export const Delete_WebCategoryMap_List = (catWebMapId, pageName) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(
    `${process.env.REACT_APP_API_URL}${
      pageName === SINGLE_PAGE
        ? DELETE__WEB_CATEGORY_MAP_LIST
        : DELETE_WEB_CATEGORY_MAP_MULTI_LIST
    }`,
    {
      headers: { Authorization: jwt_token },
      params: { catWebMapId },
    }
  );
};

export const Details_WebCategoryMap_List = (id) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${DETAILS_WEB_CATEGORY_MAP_LIST}`,
    {
      headers: { Authorization: jwt_token },
      params: { catWebMapId: id },
    }
  );
};

export const WebCategoryMap_Enable_Disable = (formData, catWebMapId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${ORG_WEB_CATEGORY_MAP_PAGE_LIST_ENABLE_DESABLE}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { catWebMapId },
    }
  );
};
