import React, { useContext } from "react";

import {
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import SettingMultiWebContext from "../../context/SettingMultiWebContext/SettingMultiWebContext";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import SettingMultiWebAddChildItem from "./SettingMultiWebAddChildItem";
import { changeSubMenu } from "../../components ByteCard/components/MultipageByteCardBuilder/apis/NavApi/NavApi";
import { Zoom, toast } from "react-toastify";
import {
  changeKeyNameOfSubMenu,
  getAllParentIdForMenus,
} from "../../utils/helper";

const SettingMultiWebAddChilds = () => {
  const { closeModel, modalChild, navMap, setNavMap, setUpBtn } = useContext(
    SettingMultiWebContext
  );
  const { setLoaderShow } = useContext(LoaderContext);

  const handleUp = ({ index, navId, isChild, parentIndex }) => {
    const updatedItems = [...navMap];

    if (isChild) {
      const [navIdIndices] = getAllParentIdForMenus(updatedItems, navId);

      if (index > 0 && navIdIndices?.split(".")?.length === 3) {
        let indexOfParents = navIdIndices?.split(".").shift();
        const currentItem =
          updatedItems[indexOfParents].childIds[parentIndex].childIds[index];
        updatedItems[indexOfParents].childIds[parentIndex].childIds[index] =
          updatedItems[indexOfParents].childIds[parentIndex].childIds[
            index - 1
          ];
        updatedItems[indexOfParents].childIds[parentIndex].childIds[index - 1] =
          currentItem;
        setNavMap(updatedItems);
      } else if (index > 0) {
        const currentItem = updatedItems[parentIndex].childIds[index];
        updatedItems[parentIndex].childIds[index] =
          updatedItems[parentIndex].childIds[index - 1];
        updatedItems[parentIndex].childIds[index - 1] = currentItem;
        setNavMap(updatedItems);
      }
    } else {
      if (index > 0) {
        const currentItem = updatedItems[index];
        updatedItems[index] = updatedItems[index - 1];
        updatedItems[index - 1] = currentItem;
        setNavMap(updatedItems);
      }
    }
  };

  const handleDown = ({ index, navId, isChild, parentIndex }) => {
    const updatedItems = [...navMap];
    if (isChild) {
      const [navIdIndices] = getAllParentIdForMenus(updatedItems, navId);
      const splitValue = navIdIndices?.split(".");
      if (
        index <
          navMap[splitValue[0]].childIds[parentIndex].childIds.length - 1 &&
        splitValue?.length === 3
      ) {
        let indexOfParents = splitValue.shift();
        const currentItem =
          updatedItems[indexOfParents].childIds[parentIndex].childIds[index];
        updatedItems[indexOfParents].childIds[parentIndex].childIds[index] =
          updatedItems[indexOfParents].childIds[parentIndex].childIds[
            index + 1
          ];
        updatedItems[indexOfParents].childIds[parentIndex].childIds[index + 1] =
          currentItem;
        setNavMap(updatedItems);
      } else if (index < navMap[parentIndex].childIds.length - 1) {
        const currentItem = updatedItems[parentIndex].childIds[index];
        updatedItems[parentIndex].childIds[index] =
          updatedItems[parentIndex].childIds[index + 1];
        updatedItems[parentIndex].childIds[index + 1] = currentItem;
        setNavMap(updatedItems);
      }
    } else {
      if (index < navMap.length - 1) {
        const currentItem = updatedItems[index];
        updatedItems[index] = updatedItems[index + 1];
        updatedItems[index + 1] = currentItem;
        setNavMap(updatedItems);
      }
    }
  };

  const intoUpItem = ({ index, navId, isChild, parentIndex, isLast }) => {
    if (index > 0) {
      const updatedItems = [...navMap];
      // navMap?.map((ele) => {
      //   return ele?.childIds.map((elem) => {
      //     return elem?.childIds.map((Element) => {
      //       return Element && setUpBtn(true);
      //     });
      //   });
      // });
      const traverseAndSetBtn = (items) => {
        items.forEach((item) => {
          if (item.navId === navId && item?.childIds) {
            const thirdLevelItem = item?.childIds[index - 1];
            if (thirdLevelItem && thirdLevelItem?.childIds) {
              setUpBtn(false);
            }
          }
          if (item.childIds) {
            traverseAndSetBtn(item.childIds);
          }
        });
      };
      if (isChild) {
        const movedItem = updatedItems[parentIndex]?.childIds.splice(
          index,
          1
        )[0];
        // it stop third child to add
        if (movedItem === undefined) {
          return alert("Third level category can not be Add.");
        }
        // it stop third child to add
        if (movedItem.childIds.length >= 1) {
          updatedItems[parentIndex]?.childIds.push(movedItem);
          return alert("Third level category can not be Add.");
        }
        updatedItems[parentIndex]?.childIds[index - 1]?.childIds.push(
          movedItem
        );
        setNavMap(updatedItems);
        traverseAndSetBtn(updatedItems);
      } else {
        const movedItem = updatedItems.splice(index, 1)[0];
        updatedItems[index - 1]?.childIds.push(movedItem);
        setNavMap(updatedItems);
        traverseAndSetBtn(updatedItems);
      }
    }
  };

  const intoDownItem = ({ index, navId, isChild, parentIndex }) => {
    const updatedItems = [...navMap];
    if (isChild) {
      const [navIdIndices] = getAllParentIdForMenus(updatedItems, navId);
      const splitValue = navIdIndices?.split(".");
      if (splitValue?.length === 3) {
        let indexOfParents = splitValue.shift();
        const currentItem = updatedItems[indexOfParents]?.childIds[parentIndex];
        const movedItem = currentItem?.childIds.splice(index, 1)[0];
        updatedItems[indexOfParents]?.childIds.splice(
          parentIndex + 1,
          0,
          movedItem
        );
        setNavMap(updatedItems);
      } else {
        const currentItem = updatedItems[parentIndex];
        const movedItem = currentItem?.childIds.splice(index, 1)[0];
        updatedItems.splice(parentIndex + 1, 0, movedItem);
        setNavMap(updatedItems);
      }
    } else {
      const currentItem = updatedItems[index];
      const movedItem = currentItem.childIds.splice(index, 1)[0];
      updatedItems[index] = {
        ...currentItem,
        childIds: [...currentItem.childIds],
      };
      updatedItems.splice(index + 1, 0, movedItem);
      setNavMap(updatedItems);
    }
  };

  function addOrder(data, order = 1) {
    data.forEach((item) => {
      item.order = order++;
      if (item.childIds) {
        addOrder(item.childIds, 1);
      }
      if (item.subChilds) {
        addOrder(item.subChilds, 1);
      }
    });
  }

  const handleSubmit = async () => {
    setLoaderShow(true);
    const data = changeKeyNameOfSubMenu(
      navMap?.map((elem) => {
        if (elem?.childIds?.length > 0) {
          return elem;
        } else {
          return {
            label: elem?.label,
            parentId: elem?.parentId,
            navMapId: elem?.navMapId,
          };
        }
      })
    );
    let newData = [];
    data.map((elem) => {
      if (elem?.childIds?.length > 0) {
        return newData.push(elem);
      } else {
        return newData.push({
          label: elem?.label,
          parentId: elem?.parentId,
          navMapId: elem?.navMapId,
        });
      }
    });
    addOrder(newData);
    let formData = {
      data: newData,
    };
    try {
      const { data } = await changeSubMenu(formData);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        setLoaderShow(false);
        closeModel();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };

  return (
    <Modal size="xl" isOpen={modalChild}>
      <ModalHeader toggle={() => closeModel()}>
        <span>Manage Website Menu </span>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <SettingMultiWebAddChildItem
                  navMap={navMap}
                  handleUp={handleUp}
                  handleDown={handleDown}
                  intoUpItem={intoUpItem}
                  intoDownItem={intoDownItem}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div className="d-flex justify-content-center ">
          <Button onClick={() => handleSubmit()}>Submit</Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SettingMultiWebAddChilds;
