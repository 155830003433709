import * as React from "react";
import { useState } from "react";

import LoaderContext from "./LoaderContext";

const LoaderState = (props) => {
  const [loaderShow, setLoaderShow] = useState(false);
  const [tabelAnimation, setTableAnimation] = useState(false);
  const [deshboardAnimation, setDeshboardAnimation] = useState(false);
  return (
    <>
      <LoaderContext.Provider
        value={{
          loaderShow,
          setLoaderShow,
          tabelAnimation,
          setTableAnimation,
          deshboardAnimation,
          setDeshboardAnimation,
        }}
      >
        {props.children}
      </LoaderContext.Provider>
    </>
  );
};

export default LoaderState;
