import React, { useContext } from "react";
import {
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { ORG_ADD, PAGE_ITEM_MASTER_ADD } from "../../constant";
import PageItemMasterContext from "../../context/PageItemMasterContext/PageItemMasterContext";

const PageItemMasterAddPopup = () => {
  const { modal, closeModel, createhwId, orgName, setOrgName } = useContext(
    PageItemMasterContext
  );

  return (
    <Modal size="lg" isOpen={modal} toggle={() => closeModel()}>
      {/* <LoaderResponse /> */}
      <ModalHeader toggle={() => closeModel()}>
        <span>Add Page Item</span>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Row>
                  <Col md="6 mb-4">
                    <Label htmlFor="validationCustom14">
                      {PAGE_ITEM_MASTER_ADD.TYPE}
                    </Label>
                    <Input
                      className="form-control"
                      name="orgName"
                      id="validationCustom14"
                      type="text"
                      placeholder={PAGE_ITEM_MASTER_ADD.TYPE}
                      value={orgName}
                      onChange={(e) => setOrgName(e.target.value)}
                      required
                    />
                    <div className="invalid-feedback">
                      {"Please provide a valid Admin Name."}
                    </div>
                  </Col>
                  <Col md="6 mb-4">
                    <Label htmlFor="validationCustom14">
                      {PAGE_ITEM_MASTER_ADD.LABEL}
                    </Label>
                    <Input
                      className="form-control"
                      name="orgName"
                      id="validationCustom14"
                      type="text"
                      placeholder={PAGE_ITEM_MASTER_ADD.LABEL}
                      value={orgName}
                      onChange={(e) => setOrgName(e.target.value)}
                      required
                    />
                    <div className="invalid-feedback">
                      {"Please provide a valid Admin Name."}
                    </div>
                  </Col>
                  <Col md="6 mb-4">
                    <Label htmlFor="validationCustom14">
                      {PAGE_ITEM_MASTER_ADD.HAYPER_LINK}
                    </Label>
                    <Input
                      className="form-control"
                      name="orgName"
                      id="validationCustom14"
                      type="text"
                      placeholder={PAGE_ITEM_MASTER_ADD.HAYPER_LINK}
                      value={orgName}
                      onChange={(e) => setOrgName(e.target.value)}
                      required
                    />
                    <div className="invalid-feedback">
                      {"Please provide a valid Admin Name."}
                    </div>
                  </Col>
                  <Col md="6 mb-4">
                    <Label htmlFor="validationCustom14">
                      {PAGE_ITEM_MASTER_ADD.CATEGORY}
                    </Label>
                    <Input
                      className="form-control"
                      name="orgName"
                      id="validationCustom14"
                      type="text"
                      placeholder={PAGE_ITEM_MASTER_ADD.CATEGORY}
                      value={orgName}
                      onChange={(e) => setOrgName(e.target.value)}
                      required
                    />
                    <div className="invalid-feedback">
                      {"Please provide a valid Admin Name."}
                    </div>
                  </Col>
                  <Col md="6 mb-4">
                    <Label htmlFor="validationCustom14">
                      {PAGE_ITEM_MASTER_ADD.ACTION}
                    </Label>
                    <Input
                      className="form-control"
                      name="orgName"
                      id="validationCustom14"
                      type="text"
                      placeholder={PAGE_ITEM_MASTER_ADD.ACTION}
                      value={orgName}
                      onChange={(e) => setOrgName(e.target.value)}
                      required
                    />
                    <div className="invalid-feedback">
                      {"Please provide a valid Admin Name."}
                    </div>
                  </Col>
                  <Col md="6 mb-4">
                    <Label htmlFor="validationCustom14">
                      {PAGE_ITEM_MASTER_ADD.IS_CALL_TO_ACTION}
                    </Label>
                    <Input
                      className="form-control"
                      name="orgName"
                      id="validationCustom14"
                      type="text"
                      placeholder={PAGE_ITEM_MASTER_ADD.IS_CALL_TO_ACTION}
                      value={orgName}
                      onChange={(e) => setOrgName(e.target.value)}
                      required
                    />
                    <div className="invalid-feedback">
                      {"Please provide a valid Admin Name."}
                    </div>
                  </Col>
                </Row>
                <Row style={{ paddingTop: "30px" }}>
                  <button
                    className="btn btn-primary"
                    type="button"
                    value="Submit"
                    onClick={createhwId}
                  >
                    {ORG_ADD.SUBMIT}
                  </button>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default PageItemMasterAddPopup;
