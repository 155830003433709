import React, { useContext, useEffect, useRef, useState } from "react";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import { useParams } from "react-router";
import { FILE_TYPE, SORTING_ORDER } from "../../../../constant";
import { Zoom, toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import { FILE_UPLOAD_API } from "../../../../components ByteCard/apis/AboutApi/AboutUsApi";
import { TostMessage } from "../../../../components ByteCard/utils";
import { DELETE_CONFIRM } from "../../../../components ByteCard/constant";
import TagsContext from "../../../../context/MenuMasterContext/TagsContext/TagsContext";
import TagsAddPopup from "./TagsAddPopup";
import TagsItem from "./TagsItem";
import {
  Add_Tags_List,
  Delete_Tags_List,
  Edit_Tags_List,
  TagsList,
  Tags_MenuPageList_Enable_Desable,
} from "../../../../api/MenuMasterApi/TagsApi/TagsApi";

const Tags = () => {
  const {
    setFormValue,
    formData,
    pageSize,
    sortOrder,
    setSortOrder,
    page,
    sortBy,
    setSortBy,
    setPageLimit,
    setRecord,
    isSwitchOn,
    setIsSwitchOn,
    addModel,
    setFormData,
    setAddModel,
    isEdit,
    setIsEdit,
    id,
    setId,
  } = useContext(TagsContext);
  const { setLoaderShow } = useContext(LoaderContext);
  const { QpiId } = useParams();
  const { tabelAnimation, setTableAnimation } = useContext(LoaderContext);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();

  const getTagsList = async (desabledStatusLoader) => {
    if (
      sortOrder === "ASC" ||
      sortOrder === "DSC" ||
      desabledStatusLoader === false
    ) {
    } else {
      setTableAnimation(!tabelAnimation);
    }
    try {
      let paramObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
        qpiId: QpiId,
      };
      const { data } = await TagsList(paramObj);

      if (data.status === 200) {
        setFormValue(data.payload.data);
        setPageLimit(data.payload.pageLimit);
        setRecord(data.pager.totalRecords);

        setTableAnimation(false);
      } else {
      }
    } catch (error) {
      setTableAnimation(false);
    }
  };

  useEffect(() => {
    getTagsList();
  }, [page, pageSize, sortBy, sortOrder]);

  /**
   * Enable and Desable tags
   */
  const switch_onChange_handle = async (tagMasterId, isActive) => {
    let formData = {
      tagMasterId: tagMasterId,
      isActive: isActive ? false : true,
      // type: TYPES.LANDING_PAGE,
    };
    try {
      const { data } = await Tags_MenuPageList_Enable_Desable(
        formData,
        tagMasterId
      );
      if (data.status === 200) {
        await setIsSwitchOn(!isSwitchOn);
        const desabledStatusLoader = false;
        await getTagsList(desabledStatusLoader);
        isActive
          ? toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            })
          : toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            });
      } else {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * file upload
   * @param {file} file
   */
  const imageUploadImages = async (file) => {
    setLoaderShow(true);
    try {
      const Image = await imageUpload(file);
      setFormData((prev) => {
        if (formData.imageName == "imageSquare") {
          return {
            ...prev,
            image: Image,
            imageSquare: Image,
          };
        } else if (formData.imageName == "imageRectangle") {
          return {
            ...prev,
            image: Image,
            imageRectangle: Image,
          };
        }
      });
      setLoaderShow(false);
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const imageUpload = async (file) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.PRODUCT);
    formData.append("file", file);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        return data.payload.baseUrl + data.payload.imageUrl;
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * Sorting tags Table
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder(SORTING_ORDER.ASC);
    }
    if (sortOrder === SORTING_ORDER.ASC) {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder(SORTING_ORDER.DSC);
    }
    if (sortOrder === SORTING_ORDER.DSC) {
      setSortBy();
      setSortOrder("");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        if (isEdit && id >= -1) {
          let addFromData = {
            ...formData,
            qpiId: QpiId,
          };
          const { data } = await Edit_Tags_List(addFromData, id);
          if (data.status === 200) {
            getTagsList();
            emptyData();
            TostMessage({ message: data, type: "success" });
          }
        } else {
          let addFromData = {
            ...formData,
            qpiId: QpiId,
          };
          const { data } = await Add_Tags_List(addFromData);
          if (data.status === 200) {
            getTagsList();
            emptyData();
            TostMessage({ message: data, type: "success" });
          }
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  /**
   * delete tags
   * @param {id} tagMasterId
   */
  const handleDelete = async (tagMasterId) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await Delete_Tags_List(tagMasterId);
        if (data.status === 200) {
          getTagsList();
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const emptyData = () => {
    setFormData({
      name: "",
      price: "",
      type: "",
      tags: "",
      rating: "",
      shortDescription: "",
      longDescription: "",
      imageRectangle: "",
      imageSquare: "",
      qpiId: "",
      productOrder: "",
    });
    setAddModel(false);
    setIsEdit(false);
    setId("");
  };
  return (
    <>
      {addModel && (
        <TagsAddPopup
          handleSubmit={handleSubmit}
          simpleValidator={simpleValidator}
          imageUpload={imageUploadImages}
          emptyData={emptyData}
        />
      )}

      <TagsItem
        sorting={sorting}
        switch_onChange_handle={switch_onChange_handle}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default Tags;
