import React, { useContext, useMemo, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import { Cases, btnName, modalsName } from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import CommonPopup from "../../../Modals/commonPopup";
import SeoDetailsPopup from "./seoDetailsPopup";
import ByteMiniWebDashboardContext from "../../../context/ByteMiniWeb_Dashboard_Context/ByteMiniWeb_Dashboard_Context";
import SeoDetailsContext from "../../../context/SeoDetailsContext/SeoDetailsContext";
import {
  getSeoDetails,
  postSeoDetails,
  putUpdateSeoDetails,
} from "../../../apis/SeoDetailsApi/SeoDetailsApi";
import { Truncate } from "../../../../utils/helper";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import SimpleReactValidator from "simple-react-validator";

const SeoDetails = (props) => {
  const { ByteMiniWeb_Dashboard, navbarData } = props;
  const { apiData } = useContext(ByteMiniWebDashboardContext);
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const {
    apiData: seoDetailsData,
    setApiData,
    fromData,
    setFormData,
    id,
    setId,
    setIsEdit,
    isEdit,
    filter,
  } = useContext(SeoDetailsContext);
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * get slider list
   */
  const seoDetailsList = async (status) => {
    try {
      if (!filter.length && status) {
        setApiData((prev) => {
          return { ...prev, isLoading: true };
        });
      }
      let paramObj = {
        search: { title: filter },
      };
      const { data } = await getSeoDetails(paramObj);
      if (data.status === 200) {
        if (data.payload.data.length) {
          setFormData({
            name: data.payload.data[0].name,
            description: data.payload.data[0].description,
            keywords: data.payload.data[0].keywords,
            canonical: data.payload.data[0].canonical,
            bing: data.payload.data[0].bing,
            googleSiteVerification: data.payload.data[0].googleSiteVerification,
            webSiteSchema: data.payload.data[0].webSiteSchema,
            breadCrumbSchema: data.payload.data[0].breadCrumbSchema,
            facebookPixel: data.payload.data[0].facebookPixel,
            author: data.payload.data[0].author,
            title: data.payload.data[0].title,
            url: data.payload.data[0].url,
            siteName: data.payload.data[0].siteName,
            googleTagManage: data.payload.data[0].googleTagManage,
            id: data.payload.data[0].id,
            webId: localStorage.getItem("webId"),
          });
          setApiData({
            ...apiData,
            isLoading: false,
            data: data.payload.data,
          });
          setId(data.payload.data[0].id);
          setIsEdit(true);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  useMemo(() => {
    if (allPopupState.seoPopup) {
      seoDetailsList();
    }
  }, [filter, allPopupState.seoPopup]);

  /**
   * create and update seo details
   */
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      if (seoDetailsData.data.length && id >= -1 && isEdit) {
        try {
          setLoaderShow(true);
          let newFormData = {
            ...fromData,
            navMapId: navbarData[0].navMapId,
          };
          const { data } = await putUpdateSeoDetails(newFormData);
          if (data.status === 200) {
            seoDetailsList();
            emptyData();
            TostMessage({ message: data, type: "success" });
            ByteMiniWeb_Dashboard();
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      } else {
        try {
          setLoaderShow(true);
          let newFormData = {
            ...fromData,
            navMapId: navbarData[0].navMapId,
          };
          const { data } = await postSeoDetails(newFormData);
          if (data.status === 200) {
            seoDetailsList();
            emptyData();
            TostMessage({ message: data, type: "success" });
            ByteMiniWeb_Dashboard();
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      }
    }
  };

  /**
   * empty data
   */
  const emptyData = () => {
    setId("");
    setFormData({
      name: "",
      description: "",
      keywords: "",
      canonical: "",
      bing: "",
      googleSiteVerification: "",
      webSiteSchema: "",
      breadCrumbSchema: "",
      facebookPixel: "",
      author: "",
      title: "",
      url: "",
      siteName: "",
      googleTagManage: "",
      webId: localStorage.getItem("webId"),
    });
    setLoaderShow(false);
  };
  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody className="support-ticket-font box-border-div">
            <Row>
              <div className="col-12">
                <h5 className="total-num counter mt-1 ">SEO Details</h5>
                <span className="d-flex align-items-center gap-2 mt-1">
                  <div>{apiData?.data?.seoDetails?.name}</div>
                </span>
                <div className="d-flex justify-content-between align-items-center ">
                  <div style={{ width: "70%" }}>
                    {apiData?.data?.seoDetails?.description
                      ? Truncate(apiData?.data?.seoDetails?.description, 500)
                      : "-"}
                  </div>
                  <div>
                    <div className="aj-card-illustration">
                      <img
                        src={require("./SEO.83da56c9.png")}
                        alt=""
                        style={{ width: "100%", borderStyle: "none" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Row>
            <OpenPopupBtn
              openPopup={() => OpenModalCommonFunc(Cases.SEO, setAllPopupState)}
              name={btnName.SEO}
            />
          </CardBody>
        </Card>
      </Col>
      <CommonPopup
        isMini={true}
        open={allPopupState.seoPopup}
        close={() => OpenModalCommonFunc(Cases.SEO, setAllPopupState, false)}
        modalName={modalsName.SEO_DETAILS}
        modalCase={Cases.SEO}
        handleSubmit={handleSubmit}
        emptyData={emptyData}
        inputData={fromData}
        edit={false}
        content={<SeoDetailsPopup simpleValidator={simpleValidator} />}
      />
    </>
  );
};

export default SeoDetails;
