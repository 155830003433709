import React, { useContext } from "react";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import {
  Cases,
  DELETE_CONFIRM,
  SinglePage,
  options,
  socialMediaTypes,
} from "../../../constant";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import { Col, Container, Input, Label, Row } from "reactstrap";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import AddNewButton from "../../../common/AddNewButton";
import ItemsDragAndDrops from "../../../common/ItemsDragAndDrops";
import SocialMediaContext from "../../../context/SocialMediaContext/SocialMediaContext";
import {
  deleteSocialMedia,
  sort_order_social_media,
} from "../../../apis/SocialMediaApi/SocialMediaApi";
import { UploadCloud } from "react-feather";
import { delete_Image_Permanent } from "../../../apis/SliderApi/SliderApi";
import { Close } from "@material-ui/icons";
import { splitPathWeb } from "../../../apiConstants";
import Dividers from "../../../common/divider";
import { IconButton } from "@material-ui/core";
import Select from "react-select";

const SocialMediaPopup = (props) => {
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const {
    handleChangeStatus,
    getData,
    handleEdit,
    imageUpload,
    emptyData,
    simpleValidator,
    webMasterDetail,
  } = props;
  const {
    apiData,
    setApiData,
    socialMediaMaster,
    fromData,
    setFormData,
    isEdit,
    setFilter,
    filter,
  } = useContext(SocialMediaContext);
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * drag and drop
   * @param {drag value} result
   * @returns
   */
  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;
    const items = Array.from(apiData.data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setApiData({ ...apiData, data: items });
    try {
      let itemOrder = [];
      for (const item in items) {
        itemOrder.push({ socialMediaId: items[item].Id, order: +item + 1 });
      }
      const { data } = await sort_order_social_media(itemOrder);
      if (data.status === 200) {
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * input value change
   * @param {Event} e
   */
  const changeFormValue = (e) => {
    const { name, value } = e.target;
    if (name === "itemId") {
      let [data] = socialMediaMaster.filter((elem) => elem.itemId === +value);
      setFormData((prev) => {
        return {
          ...prev,
          [name]: value,
          type: data.type,
        };
      });
    } else {
      setFormData((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    }
  };

  /**
   * delete social media
   * @param {Id} socialMediaId
   */
  const handleDelete = async (socialMediaId) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await deleteSocialMedia(socialMediaId);
        if (data.status === 200) {
          getData();
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * new product create
   */
  const hanldenewCreate = () => {
    emptyData();
  };

  /**
   * image reupload
   */
  const reupload = () => {
    setFormData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };

  /**
   * permanently delete image
   */
  const deleteImagePermanent = async () => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData = {
          fileName: fromData.image
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
            .pop(),
        };
        const { data } = await delete_Image_Permanent(formData);
        if (data.status === 200) {
          TostMessage({ message: data, type: "success", position: "center" });
          setFormData({ ...fromData, image: "" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="6">
            <div>
              <h6>{apiData?.data?.length} Social Media</h6>
              <p className="pt-2">You can add upto 25 Social Media</p>
              <Row>
                <Col md="6">
                  <Input
                    className="form-control mt-3 "
                    name="search"
                    type="text"
                    placeholder="search"
                    style={{ borderRadius: "0", color: "black" }}
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                </Col>
                {webMasterDetail?.webCategory?.name ===
                  SinglePage.WebCategory &&
                  apiData?.data?.length !== webMasterDetail?.itemLimit && (
                    <Col
                      md="6"
                      className="d-flex justify-content-center align-items-center mt-3"
                    >
                      <AddNewButton click={() => hanldenewCreate()} />
                    </Col>
                  )}
              </Row>
              {apiData.isLoading ? (
                <div className="mt-4">
                  <h6>Loading ...</h6>
                </div>
              ) : (
                <div className="mt-4">
                  <ItemsDragAndDrops
                    handleOnDragEnd={handleOnDragEnd}
                    characters={apiData?.data}
                    handleChangeStatus={handleChangeStatus}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                  />
                </div>
              )}
            </div>
          </Col>
          {(webMasterDetail?.webCategory?.name === SinglePage.WebCategory &&
            apiData?.data?.length === webMasterDetail?.itemLimit &&
            isEdit) ||
          (webMasterDetail?.webCategory?.name === SinglePage.WebCategory &&
            apiData?.data?.length !== webMasterDetail?.itemLimit) ? (
            <Col sm="12" xl="6">
              <div className="mt-4">
                <h6>{isEdit ? "Edit" : "Add New"}</h6>
                <Row className="mt-4">
                  <Col md="12 mb-3">
                    <Label htmlFor="validationCustom03">{"Type *"}</Label>
                    <select
                      className="form-control"
                      style={{ borderRadius: "0" }}
                      value={fromData.itemId}
                      onChange={(e) => changeFormValue(e)}
                      name="itemId"
                    >
                      <option value="">--select--</option>
                      {socialMediaMaster.map((elem) => {
                        return (
                          <option value={elem.itemId}>{elem.label}</option>
                        );
                      })}
                    </select>
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "type",
                        fromData.itemId,
                        "required"
                      )}
                    </p>
                  </Col>
                  <Col md="12 mb-3">
                    <Label htmlFor="validationCustom03">{"Label *"}</Label>
                    <Input
                      className="form-control"
                      name="label"
                      type="text"
                      placeholder="Label *"
                      style={{ borderRadius: "0", color: "black" }}
                      value={fromData.label}
                      onChange={(e) => changeFormValue(e)}
                    />
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "label",
                        fromData.label,
                        "required"
                      )}
                    </p>
                  </Col>
                  <Col md="12 mb-3">
                    <Label htmlFor="validationCustom03">{"Value *"}</Label>
                    <div className="d-flex gap-2 social-whatsapp">
                      {fromData.type === socialMediaTypes.whatsapp && (
                        <Select
                          style={{ width: "125px" }}
                          options={options}
                          name="dialCode"
                          value={fromData.dialCode}
                          onChange={(prev) =>
                            setFormData((prevValue) => {
                              return {
                                ...prevValue,
                                dialCode: prev,
                              };
                            })
                          }
                        />
                      )}
                      <Input
                        className="form-control"
                        name="value"
                        type="text"
                        placeholder="Value *"
                        style={{ borderRadius: "0", color: "black" }}
                        value={fromData.value}
                        onChange={(e) => changeFormValue(e)}
                      />
                    </div>
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "value",
                        fromData.value,
                        "required"
                      )}
                    </p>
                  </Col>
                </Row>
                {fromData.type === socialMediaTypes.custome && (
                  <>
                    <Dividers divide="IMAGES" />
                    <p className="mb-4">
                      File type required JPG, PNG (Max 1MB)
                    </p>
                    <Row>
                      <Col md="6 mb-3">
                        <div
                          className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                          style={{ cursor: "pointer" }}
                        >
                          {!fromData?.image && (
                            <span
                              onClick={() => {
                                OpenModalCommonFunc(
                                  Cases.PRODUCT_UPLOAD,
                                  setAllPopupState
                                );
                              }}
                            >
                              <UploadCloud />
                            </span>
                          )}

                          {fromData?.image && (
                            <img
                              src={fromData?.image ? fromData?.image : ""}
                              alt=""
                              style={{
                                width: "80%",
                                height: "98%",
                                objectFit: "contain",
                                position: "absolute",
                              }}
                              onClick={() => {
                                deleteImagePermanent();
                              }}
                            />
                          )}
                          {fromData.image && (
                            <>
                              {" "}
                              <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="close"
                                style={{
                                  color: "red",
                                  position: "absolute",
                                  top: "-22px",
                                  left: "1px",
                                }}
                                onClick={() => deleteImagePermanent()}
                              >
                                <Close
                                  style={{
                                    fontSize: "25px",
                                    fontWeight: "bold",
                                  }}
                                />
                              </IconButton>
                            </>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
              </div>
            </Col>
          ) : (
            ""
          )}
        </Row>
        <ProductImageUploadPopup
          allPopupState={allPopupState}
          setAllPopupState={setAllPopupState}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
          image={fromData.image}
          setImage={setFormData}
          imageUpload={imageUpload}
          isEdit={isEdit}
          reupload={reupload}
          aspect={1 / 1}
        />
      </Container>
    </>
  );
};

export default SocialMediaPopup;
