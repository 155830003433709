export const SERVICES = {
  BYTEQR: "/byte-qr",
  BYTE_QR_NFC: "byte-qr-nfc",
  BYTE_MINI_WEB: "byte-mini-web",
  BYTE_MULTI_WEB: "byte-multi-web",
  _PUBLIC: "byte-qr-nfc/public",
  GETWAY: "gateway/admin",
  API: "/api",
};

export const V1 = "/api/v1";
export const ORGANIZATION = "organization";

/** PUBLIC API */
export const ORGANIZATION_DETAILS = `${SERVICES._PUBLIC}${V1}/organization/details`;
export const ORGANIZATION_DETAIL = `${SERVICES.BYTE_QR_NFC}${V1}/organization/detail`;
export const ORGANIZATION_PUT = `${SERVICES.BYTE_QR_NFC}${V1}/organization`;
export const THEME_DETAILS = `${SERVICES._PUBLIC}${V1}/theme/detail`;
export const ITEM_VIEW = `${SERVICES._PUBLIC}${V1}/activity/itemView`;
export const SIGNIN = `${SERVICES._PUBLIC}${V1}/signup`;
export const LOGIN = `${SERVICES._PUBLIC}${V1}/login`;
export const LOGIN_WITH_GMAIL = `${SERVICES._PUBLIC}${V1}/loginWithEmail`;
export const FORGOT = `${SERVICES._PUBLIC}${V1}/forgotPassword`;
export const RESET_PASSWORD = `${SERVICES._PUBLIC}${V1}/resetPassword`;
export const THEMES = `${SERVICES.BYTE_QR_NFC}${V1}/theme`;
/** PUBLIC API */

/** ORGANIZATION*/
export const ORG_PAGE_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/page`;
export const PAGE_PRIVIEW_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/activity/pageViewList`;
export const CREATE_ORG_PAGE_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/page`;
export const DELETE_ORG_PAGE_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/page`;
export const PAGE_CLONE = `${SERVICES.BYTE_QR_NFC}${V1}/page/clone`;
export const ORG_PAGELIST_DETAILS = `${SERVICES.BYTE_QR_NFC}${V1}/page/detail`;
export const EDIT_ORG_PAGE_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/page`;
export const ORG_PAGE_LIST_ENABLE_DESABLE = `${SERVICES.BYTE_QR_NFC}${V1}/page/enableDisable`;
export const PAGE_ITEM_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/qrPageItem`;
export const DROP_PAGE_ITEM_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/qrItemMaster`;
export const CREATE_ORG_PAGE_ITEM = `${SERVICES.BYTE_QR_NFC}${V1}/qrPageItem`;
export const SHOW_ORG_PAGE_ITEM = `${SERVICES.BYTE_QR_NFC}${V1}/qrPageItem/detail`;
export const DELETE_ORG_PAGE_ITEM = `${SERVICES.BYTE_QR_NFC}${V1}/qrPageItem`;
export const EDIT_ORG_PAGE_ITEM = `${SERVICES.BYTE_QR_NFC}${V1}/qrPageItem`;
export const PAGE_ITEM_PAGE_LIST_ENABLE_DESABLE = `${SERVICES.BYTE_QR_NFC}${V1}/qrPageItem/enableDisable`;
export const UPLOADE = `${SERVICES._PUBLIC}${V1}/file`;
export const ORG_PAGE_DETAILS = `${SERVICES._PUBLIC}${V1}/pageDetail`;
export const INQUIRY_PAGE = `${SERVICES.BYTE_QR_NFC}${V1}/qrPageInquiry`;
export const SHOW_INQUIRY_PAGE = `${SERVICES.BYTE_QR_NFC}${V1}/qrPageInquiry/details`;
export const CREATE_INQUIRY_PAGE = `${SERVICES._PUBLIC}${V1}/organization/qrPageInquiry`;
export const DOWNLOAD_VFC = `${SERVICES._PUBLIC}${V1}/save-contact`;
export const SORT_ORDER_PAGE_ITEM = `${SERVICES.BYTE_QR_NFC}${V1}/shortOrderPageItem`;
export const DESHBOARD = `${SERVICES.BYTE_QR_NFC}${V1}/dashboard`;
export const UPDATE_ORGANIZATION = `${SERVICES.BYTE_QR_NFC}${V1}/organization`;
export const CHECK_SLUG_URL = `${SERVICES.BYTE_QR_NFC}${V1}/page/checkSlugUrl`;
export const CHECK_SLUG_URL_PUBLIC = `${SERVICES._PUBLIC}${V1}/organization/checkSlugUrl`;

export const BROKERID_INCENTIVE_TYPE = `${SERVICES.BYTE_QR_NFC}${V1}/page`;
export const BROKER_INCENTIVE_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/brokerIncentive`;
export const BROKER_INCENTIVE_EDIT = `${SERVICES.BYTE_QR_NFC}${V1}/brokerIncentive/detail`;

export const PAGE_ITEM_MASTER_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/pageItemType`;
export const ADD_PAGE_ITEM_MASTER_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/pageItemType`;

/** ORGANIZATION*/

/**SUPPER ADMIN  */

export const ORGANIZATION_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/organization`;
export const WEB_CONTENT_MAP_LIST = `${SERVICES.BYTE_MINI_WEB}/admin${V1}/webMaster/webContentMap`;
export const WEB_CONTENT_MASTER_CREATE = `${SERVICES.BYTE_MINI_WEB}/admin${V1}/webMaster/webContentMap`;
export const WEB_NAV_BAR_CREATE = `${SERVICES.BYTE_MINI_WEB}/admin${V1}/webMaster/webNavBarMap`;
export const WEB_NAV_BAR_DETAIL = `${SERVICES.BYTE_MINI_WEB}/admin${V1}/webMaster/webNavBarMap/detail`;
export const WEB_CONFIG_PAGE_CREATE = `${SERVICES.BYTE_MINI_WEB}/admin${V1}/webMaster/webPageConfig`;
export const WEB_NAV_BAR_MAP_LIST = `${SERVICES.BYTE_MINI_WEB}/admin${V1}/webMaster/webNavBarMap`;
export const WEB_NAV_BAR_CONFIG_LIST = `${SERVICES.BYTE_MINI_WEB}/admin${V1}/webMaster/webPageConfig`;
export const WEB_CONTENT_MASTER_LIST = `${SERVICES.BYTE_MINI_WEB}/admin${V1}/webMaster/contentMaster`;
export const WEB_NAV_BAR_MASTER_LIST = `${SERVICES.BYTE_MINI_WEB}/admin${V1}/webMaster/webNavBarMaster`;
export const WEB_NAV_BAR_NAV_MAP_ID = `${SERVICES.BYTE_MINI_WEB}${V1}/webMaster/navbar`;
export const CREATE_HWID = `${SERVICES.BYTE_QR_NFC}${V1}/generate/hwId`;
export const UPDATE_PAGE_LIMIT = `${SERVICES.BYTE_QR_NFC}${V1}/organization`;
export const WEB_MASTER_LIST = `${SERVICES.BYTE_MINI_WEB}${V1}/webMaster`;
export const WEB_MASTER_LIST_MULTI = `${SERVICES.BYTE_MULTI_WEB}/admin${V1}/webMaster`;
export const SUBSCRIPTION_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/subscription`;

export const CREATE_CLONE_WEB_MASTER = `${SERVICES.BYTE_MINI_WEB}/admin${V1}/webMaster/clone`;
export const CREATE_CLONE_WEB_MASTER_MULTI = `${SERVICES.BYTE_MULTI_WEB}/admin${V1}/webMaster/clone`;

/**SUPPER ADMIN  */

/** LOGIN */
export const GETNFC = `${SERVICES.API}/nfc/get`;
export const CREATE_PROFILE = `${SERVICES.API}/completeBusinessProfile`;
export const UPDATE_ORGANIZATION_DETAIL = `${SERVICES.BYTE_QR_NFC}${V1}/organization`;
/** LOGIN */

/** USER_LIST */

export const USER_LIST = `${SERVICES.BYTEQR}${V1}/pageDetail`;

/** USER_LIST */

/** DASHBOARD*/
export const SUPER_ADMIN_DESHBOARD = `${SERVICES.BYTEQR}${V1}/super_admin_dashboard/detail`;
export const SELLER_DESHBOARD = `${SERVICES.BYTEQR}${V1}/seller_dashboard/detail`;
export const NFC__LIST_STATUS_UPDATE = `${SERVICES.BYTEQR}${V1}/nfc_order/status`;
export const NFC_LIST_DETAILS = `${SERVICES.BYTEQR}${V1}/nfc_order/generated-nfc-detail`;
export const NFC_ORDER_LIST_DETAILS = `${SERVICES.BYTEQR}${V1}/nfc_order/detail`;
export const NFC_ORDER_LIST_TITLE_UPDATE = `${SERVICES.BYTEQR}${V1}/nfc_order`;
export const SOLUTION_DETAILS = `${SERVICES.BYTE_QR_NFC}${V1}/organization/solutionDetail`;
/** DASHBOARD*/

/** SELLER PASSWORD CHANGE */
export const PASSWORD_CHANGE = `${SERVICES.BYTE_QR_NFC}${V1}/user/change-password`;
export const SET_PASSWORD = `${SERVICES.BYTE_QR_NFC}${V1}/user/set-password`;
export const SEND_OTP = `${SERVICES.BYTE_QR_NFC}${V1}/user/send-otp`;
/** SELLER PASSWORD CHANGE */

/** DOWNLOAD QR  */
export const DOWNLOAD_QR = `${SERVICES.BYTEQR}${V1}/qr_order/qrGeneratedDetails`;
export const DOWNLOAD_NFC = `${SERVICES.BYTEQR}${V1}/nfc_order/nfcGeneratedDetails`;
/** DOWNLOAD QR  */

/** PRIVILLAGE MASTER LIST*/
export const PRIVILLAGE_LIST = `${SERVICES.GETWAY}${V1}/privilege`;
export const ADD_PRIVILLAGE_LIST = `${SERVICES.GETWAY}${V1}/privilege`;
export const EDIT_PRIVILLAGE_LIST = `${SERVICES.GETWAY}${V1}/privilege`;
export const DELETE_PRIVILLAGE_LIST = `${SERVICES.GETWAY}${V1}/privilege`;
export const DETAILS_PRIVILLAGE_LIST = `${SERVICES.GETWAY}${V1}/privilege/detail`;
export const MODULE_ACCESS_LIST = `${SERVICES.GETWAY}${V1}/privilege/module/access`;
/** PRIVILLAGE MASTER LIST*/

/** MODUAL MASTER LIST*/
export const MODULE_LIST = `${SERVICES.GETWAY}${V1}/privilege/module`;
export const ADD_MODULE_LIST = `${SERVICES.GETWAY}${V1}/privilege/module`;
export const DETAILS_MODULE_LIST = `${SERVICES.GETWAY}${V1}/privilege/module/detail`;
export const EDIT_MODULE_LIST = `${SERVICES.GETWAY}${V1}/privilege/module`;
export const DELETE_MODULE_LIST = `${SERVICES.GETWAY}${V1}/privilege/module`;
/** MODUAL MASTER LIST*/

/** PLAN MASTER LIST */
export const PLAN_MASTER_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/planMaster`;
export const ADD_PLAN_MASTER_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/planMaster`;
export const EDIT_PLAN_MASTER_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/planMaster`;
export const DELETE_PLAN_MASTER_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/planMaster`;
export const DETAILS_PLAN_MASTER_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/planMaster/detail`;
export const MODULE_PLAN_MASTER_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/planMaster/module/access`;
export const PLAN_MASTER_PAGE_LIST_ENABLE_DESABLE = `${SERVICES.BYTE_QR_NFC}${V1}/planMaster/enableDisable`;
/** ORG PLAN LIST */
export const ORG_PLAN_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/plan`;
export const ADD_ORG_PLAN_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/plan`;
export const EDIT_ORG_PLAN_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/plan`;
export const DELETE_ORG_PLAN_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/plan`;
export const DETAILS_ORG_PLAN_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/plan/detail`;
export const MODULE_ORG_PLAN_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/plan/module/access`;
export const ORG_PLAN_PAGE_LIST_ENABLE_DESABLE = `${SERVICES.BYTE_QR_NFC}${V1}/plan/enableDisable`;

/** Menu Master */
/** Products List */
export const PRODUCTS_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/menuProduct`;
export const ADD_PRODUCTS_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/menuProduct`;
export const EDIT_PRODUCTS_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/menuProduct`;
export const DELETE_PRODUCTS_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/menuProduct`;
export const DETAILS_PRODUCTS_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/menuProduct/detail`;
export const MODULE_PRODUCTS_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/menuProduct/module/access`;
export const ORG_MENU_PAGE_LIST_ENABLE_DESABLE = `${SERVICES.BYTE_QR_NFC}${V1}/menuProduct/enableDisable`;
/** Tags List */
export const TAGS_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/tagMaster`;
export const ADD_TAGS_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/tagMaster`;
export const EDIT_TAGS_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/tagMaster`;
export const DELETE_TAGS_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/tagMaster`;
export const DETAILS_TAGS_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/tagMaster/detail`;
export const MODULE_TAGS_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/tagMaster/module/access`;
export const ORG_TAGS_MENU_PAGE_LIST_ENABLE_DESABLE = `${SERVICES.BYTE_QR_NFC}${V1}/tagMaster/enableDisable`;
/** Add on Product List */
export const ADD_ON_PRODUCT_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/addOnProduct`;
export const ADD_ADD_ON_PRODUCT_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/addOnProduct`;
export const EDIT_ADD_ON_PRODUCT_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/addOnProduct`;
export const DELETE_ADD_ON_PRODUCT_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/addOnProduct`;
export const DETAILS_ADD_ON_PRODUCT_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/addOnProduct/detail`;
export const MODULE_ADD_ON_PRODUCT_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/addOnProduct/module/access`;
export const ORG_ADD_ON_PRODUCT_MENU_PAGE_LIST_ENABLE_DESABLE = `${SERVICES.BYTE_QR_NFC}${V1}/addOnProduct/enableDisable`;
/** Add on Product List */
/** Add on category List */
export const CATEGORY_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/menuCategory`;
export const ADD_CATEGORY_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/menuCategory`;
export const EDIT_CATEGORY_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/menuCategory`;
export const DELETE_CATEGORY_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/menuCategory`;
export const DETAILS_CATEGORY_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/menuCategory/detail`;
export const MODULE_CATEGORY_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/menuCategory/module/access`;
export const ORG_CATEGORY_MENU_PAGE_LIST_ENABLE_DESABLE = `${SERVICES.BYTE_QR_NFC}${V1}/menuCategory/enableDisable`;

/** Theme Master */
/** Theme */
export const THEME_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/theme`;
export const ADD_THEME_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/theme`;
export const EDIT_THEME_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/theme`;
export const DELETE_THEME_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/theme`;
export const DETAILS_THEME_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/theme/detail`;
export const MODULE_THEME_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/theme/module/access`;
export const ORG_THEME_PAGE_LIST_ENABLE_DESABLE = `${SERVICES.BYTE_QR_NFC}${V1}/theme/enableDisable`;
/** Theme Map */
export const THEME_MAP_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/pageItemThemeMap`;
export const ADD_THEME_MAP_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/pageItemThemeMap`;
export const EDIT_THEME_MAP_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/pageItemThemeMap`;
export const DELETE_THEME_MAP_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/pageItemThemeMap`;
export const DETAILS_THEME_MAP_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/pageItemThemeMap/detail`;
export const MODULE_THEME_MAP_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/pageItemThemeMap/module/access`;
export const ORG_THEME_MAP_PAGE_LIST_ENABLE_DESABLE = `${SERVICES.BYTE_QR_NFC}${V1}/pageItemThemeMap/enableDisable`;

/**Single Webz */
/** Clone category */
export const CLONE_CATEGORY_LIST = `${SERVICES.BYTE_MINI_WEB}${V1}/cloneCategory`;
export const ADD_CLONE_CATEGORY_LIST = `${SERVICES.BYTE_MINI_WEB}${V1}/cloneCategory`;
export const EDIT_CLONE_CATEGORY_LIST = `${SERVICES.BYTE_MINI_WEB}${V1}/cloneCategory`;
export const DELETE__CLONE_CATEGORY_LIST = `${SERVICES.BYTE_MINI_WEB}${V1}/cloneCategory`;
export const DETAILS_CLONE_CATEGORY_LIST = `${SERVICES.BYTE_MINI_WEB}${V1}/cloneCategory/detail`;
export const MODULE_CLONE_CATEGORY_LIST = `${SERVICES.BYTE_MINI_WEB}${V1}/cloneCategory/module/access`;
export const ORG_CLONE_CATEGORY_PAGE_LIST_ENABLE_DESABLE = `${SERVICES.BYTE_MINI_WEB}${V1}/cloneCategory/enableDisable`;
/** Web Category map */
export const WEB_CATEGORY_MAP_LIST = `${SERVICES.BYTE_MINI_WEB}${V1}/cloneCategory/webMap`;
export const ADD_WEB_CATEGORY_MAP_LIST = `${SERVICES.BYTE_MINI_WEB}${V1}/cloneCategory/webMap`;
export const EDIT_WEB_CATEGORY_MAP_LIST = `${SERVICES.BYTE_MINI_WEB}${V1}/cloneCategory/webMap`;
export const DELETE__WEB_CATEGORY_MAP_LIST = `${SERVICES.BYTE_MINI_WEB}${V1}/cloneCategory/webMap`;
export const DETAILS_WEB_CATEGORY_MAP_LIST = `${SERVICES.BYTE_MINI_WEB}${V1}/cloneCategory/webMap/detail`;
export const MODULE_WEB_CATEGORY_MAP_LIST = `${SERVICES.BYTE_MINI_WEB}${V1}/cloneCategory/webMap/module/access`;
export const ORG_WEB_CATEGORY_MAP_PAGE_LIST_ENABLE_DESABLE = `${SERVICES.BYTE_MINI_WEB}${V1}/cloneCategory/webMap/enableDisable`;

/**Multi Webz */
/** Clone category */
export const CLONE_CATEGORY_MULTI_LIST = `${SERVICES.BYTE_MULTI_WEB}${V1}/cloneCategory`;
export const ADD_CLONE_CATEGORY_MULTI_LIST = `${SERVICES.BYTE_MULTI_WEB}${V1}/cloneCategory`;
export const EDIT_CLONE_CATEGORY_MULTI_LIST = `${SERVICES.BYTE_MULTI_WEB}${V1}/cloneCategory`;
export const DELETE_CLONE_CATEGORY_MULTI_LIST = `${SERVICES.BYTE_MULTI_WEB}${V1}/cloneCategory`;
export const DETAILS_CLONE_CATEGORY_MULTI_LIST = `${SERVICES.BYTE_MULTI_WEB}${V1}/cloneCategory/detail`;
export const MODULE_CLONE_CATEGORY_MULTI_LIST = `${SERVICES.BYTE_MULTI_WEB}${V1}/cloneCategory/module/access`;
export const ORG_CLONE_CATEGORY_MULTI_PAGE_LIST_ENABLE_DESABLE = `${SERVICES.BYTE_MULTI_WEB}${V1}/cloneCategory/enableDisable`;
export const WEB_PAGE_CONFIG_ALIGN = `${SERVICES.BYTE_MULTI_WEB}/admin${V1}/webMaster/webPageConfigAlign`;
export const WEB_PAGE_CONFIG_CLONE = `${SERVICES.BYTE_MULTI_WEB}/admin${V1}/webMaster/webPageConfigClone`;
/** Web Category map */
export const WEB_CATEGORY_MAP_MULTI_LIST = `${SERVICES.BYTE_MULTI_WEB}${V1}/cloneCategory/webMap`;
export const ADD_WEB_CATEGORY_MAP_MULTI_LIST = `${SERVICES.BYTE_MULTI_WEB}${V1}/cloneCategory/webMap`;
export const EDIT_WEB_CATEGORY_MAP_MULTI_LIST = `${SERVICES.BYTE_MULTI_WEB}${V1}/cloneCategory/webMap`;
export const DELETE_WEB_CATEGORY_MAP_MULTI_LIST = `${SERVICES.BYTE_MULTI_WEB}${V1}/cloneCategory/webMap`;
export const DETAILS_WEB_CATEGORY_MAP_MULTI_LIST = `${SERVICES.BYTE_MULTI_WEB}${V1}/cloneCategory/webMap/detail`;
export const MODULE_WEB_CATEGORY_MAP_MULTI_LIST = `${SERVICES.BYTE_MULTI_WEB}${V1}/cloneCategory/webMap/module/access`;
export const ORGWEB_CATEGORY_MAP_MULTI_PAGE_LIST_ENABLE_DESABLE = `${SERVICES.BYTE_MULTI_WEB}${V1}/cloneCategory/webMap/enableDisable`;

/** Font List */
export const FONT_LIST_LIST = `${SERVICES.BYTE_MULTI_WEB}${V1}/fontMaster`;
export const ADD_FONT_LIST_LIST = `${SERVICES.BYTE_MULTI_WEB}${V1}/fontMaster`;
export const EDIT_FONT_LIST_LIST = `${SERVICES.BYTE_MULTI_WEB}${V1}/fontMaster`;
export const DELETE__FONT_LIST_LIST = `${SERVICES.BYTE_MULTI_WEB}${V1}/fontMaster`;
export const DETAILS_FONT_LIST_LIST = `${SERVICES.BYTE_MULTI_WEB}${V1}/fontMaster/detail`;
export const MODULE_FONT_LIST_LIST = `${SERVICES.BYTE_MULTI_WEB}${V1}/fontMaster/module/access`;
export const ORG_FONT_LIST_LIST_ENABLE_DESABLE = `${SERVICES.BYTE_MULTI_WEB}${V1}/fontMaster/enableDisable`;
