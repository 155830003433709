import React, { useState } from "react";
import GetWhatsAppLinkContext from "./GetWhatsAppLinkContext";
import { randomStr } from "../../../utils/helper";

const GetWhatsAppLinkState = (props) => {
  const [apiData, setApiData] = useState({ data: [], isLoading: false });
  const [apiDataOurTeam, setApiDataOurTeam] = useState({
    isLoading: false,
    data: [],
  });
  const [skill, setSkill] = useState([
    { id: randomStr(2), skill: "", parentageOfSkill: "" },
  ]);
  const [isBackgroundColor, setIsBackgroundColor] = useState(false);
  const [collId, setCollId] = useState();
  const [fromData, setFormData] = useState({
    title: "",
    subTitle: "",
    navMapId: "",
    description: "",
    linkText: "",
    buttonText: "",
    buttonValue: "",
    isTitleShow: true,
    defaultCountry: "",
    webId: localStorage.getItem("webId"),
    theme: 1,
    style: {
      ".getlink-section": {
        padding: "50px 0",
        "background-color": "rgb(238 238 238 / 100)",
      },
      ".getlink-section .section-title-area": {
        "margin-bottom": "50px",
      },
      ".getlink-section .section-title-area .section-subtitle": {
        "font-size": "16px",
        "font-weight": "600",
        color: "rgb(255 73 124 / 100)",
        "background-color": "rgb(255 73 124 / 0.2)",
        padding: "5px 20px",
        p1: "5px",
        p2: "20px",
        "border-radius": "15px",
        display: "inline-block",
        "margin-bottom": "15px",
      },
      ".getlink-section .section-title-area .section-title": {
        "font-size": "44px",
        "font-weight": "600",
        color: "rgb(0 0 0 / 100)",
      },
      ".getlinksbox .section-note": {
        "font-size": "18px",
        "font-weight": "500",
        color: "rgb(153 153 153 / 100)",
      },
      ".getlinksbox": {
        "background-color": "rgb(255 255 255 / 100)",
        padding: "40px 40px",
        p1: "40px",
        p2: "40px",
        "border-radius": "15px",
        border: "1px solid rgb(255 255 255 / 100)",
        borderPx: "1px",
        borderThik: "solid",
        borderColor: "rgb(255 255 255 / 100)",
        "border-top": "3px solid rgb(255 73 124 / 100)",
        borderTopPx: "3px",
        borderTopThik: "solid",
        borderTopColor: "rgb(255 73 124 / 100)",
        "box-shadow": "5px 5px 15px rgb(0 0 0 / 0.09)",
        bs1: "5px",
        bs2: "5px",
        bs3: "15px",
        shadowColor: "rgb(0 0 0 / 0.09)",
      },
      ".getlinksbox .form-control": {
        width: "100%",
        "margin-bottom": "10px",
        "background-color": "rgb(242 247 255 / 100)",
        border: "2px solid rgb(238 238 238 / 100)",
        borderPx: "2px",
        borderThik: "solid",
        borderColor: "rgb(238 238 238 / 100)",
        "border-radius": "4px",
        "font-size": "16px",
        appearance: "auto",
        outline: "0px solid rgb(255 255 255 / 100)",
        outPx: "0px",
        outThik: "solid",
        outcolor: "rgb(255 255 255 / 100)",
      },
      ".getlink-section .getlinksbox .btn": {
        color: "rgb(255 255 255 / 100)",
        "background-color": "rgb(255 73 124 / 100)",
      },
    },
  });

  const [formDataChild, setFormDataChild] = useState({
    title: "",
    subTitle: "",
    description: "",
    isTitleShow: true,
    order: 1,
    webId: localStorage.getItem("webId"),
    navMapId: "",
  });

  const [settingFormData, setSettingFormData] = useState({
    ".getlink-section": {
      padding: "50px 0",
      "background-color": "rgb(238 238 238 / 100)",
    },
    ".getlink-section .section-title-area": {
      "margin-bottom": "50px",
    },
    ".getlink-section .section-title-area .section-subtitle": {
      "font-size": "16px",
      "font-weight": "600",
      color: "rgb(255 73 124 / 100)",
      "background-color": "rgb(255 73 124 / 0.2)",
      padding: "5px 20px",
      p1: "5px",
      p2: "20px",
      "border-radius": "15px",
      display: "inline-block",
      "margin-bottom": "15px",
    },
    ".getlink-section .section-title-area .section-title": {
      "font-size": "44px",
      "font-weight": "600",
      color: "rgb(0 0 0 / 100)",
    },
    ".getlinksbox .section-note": {
      "font-size": "18px",
      "font-weight": "500",
      color: "rgb(153 153 153 / 100)",
    },
    ".getlinksbox": {
      "background-color": "rgb(255 255 255 / 100)",
      padding: "40px 40px",
      p1: "40px",
      p2: "40px",
      "border-radius": "15px",
      border: "1px solid rgb(255 255 255 / 100)",
      borderPx: "1px",
      borderThik: "solid",
      borderColor: "rgb(255 255 255 / 100)",
      "border-top": "3px solid rgb(255 73 124 / 100)",
      borderTopPx: "3px",
      borderTopThik: "solid",
      borderTopColor: "rgb(255 73 124 / 100)",
      "box-shadow": "5px 5px 15px rgb(0 0 0 / 0.09)",
      bs1: "5px",
      bs2: "5px",
      bs3: "15px",
      shadowColor: "rgb(0 0 0 / 0.09)",
    },
    ".getlinksbox .form-control": {
      width: "100%",
      "margin-bottom": "10px",
      "background-color": "rgb(242 247 255 / 100)",
      border: "2px solid rgb(238 238 238 / 100)",
      borderPx: "2px",
      borderThik: "solid",
      borderColor: "rgb(238 238 238 / 100)",
      "border-radius": "4px",
      "font-size": "16px",
      appearance: "auto",
      outline: "0px solid rgb(255 255 255 / 100)",
      outPx: "0px",
      outThik: "solid",
      outcolor: "rgb(255 255 255 / 100)",
    },
    ".getlink-section .getlinksbox .btn": {
      color: "rgb(255 255 255 / 100)",
      "background-color": "rgb(255 73 124 / 100)",
    },
  });
  const [navMapsData, setNavMapsData] = useState([]);
  const [boxSection, setBoxSection] = useState(false);
  const [id, setId] = useState("");
  const [childId, setChildId] = useState("");
  const [navMapId, setNavMapId] = useState("");
  const [galleryImgId, setGalleryImgId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isParent, setIsParent] = useState(false);
  const [filter, setFilter] = useState("");
  const [theme, setTheme] = useState(1);
  const [imgShow, setImgShow] = useState(false);
  const [sectionPageName, setSectionPageName] = useState("");
  return (
    <GetWhatsAppLinkContext.Provider
      value={{
        apiData,
        setApiData,
        apiDataOurTeam,
        setApiDataOurTeam,
        fromData,
        setFormData,
        id,
        setId,
        isEdit,
        setIsEdit,
        filter,
        setFilter,
        skill,
        galleryImgId,
        setGalleryImgId,
        setSkill,
        settingFormData,
        setSettingFormData,
        formDataChild,
        setFormDataChild,
        isParent,
        setIsParent,
        childId,
        setChildId,
        navMapId,
        setNavMapId,
        boxSection,
        setBoxSection,
        collId,
        setCollId,
        navMapsData,
        setNavMapsData,
        isBackgroundColor,
        setIsBackgroundColor,
        theme,
        setTheme,
        imgShow,
        setImgShow,
        sectionPageName,
        setSectionPageName,
      }}
    >
      {props.children}
    </GetWhatsAppLinkContext.Provider>
  );
};

export default GetWhatsAppLinkState;
