import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import Chart from "react-apexcharts";

const ChartReusable = (props) => {
  const { categories, arr, chartData } = props;
  const columnChart = {
    options: {
      chart: {
        toolbar: {
          show: true,
        },
      },
      legend: {
        show: false,
      },
      colors: ["#7366ff", "#fb2e63"],
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          radius: 10,
          horizontal: false,
          columnWidth: "55%",
        },
      },
      stroke: {
        show: true,
        colors: ["transparent"],
        curve: "smooth",
        lineCap: "butt",
      },
      xaxis: {
        categories: categories,
      },
      yaxis: {
        title: {
          text: localStorage.getItem("title"),
        },
      },
      fill: {
        colors: ["#7366ff"],
      },
      tooltip: {
        y: {
          formatter: function (val) {
            let title = arr.find((elem) =>
              elem.views === val ? elem.pageDetails.pageTitle : ""
            );
            return `count : ${title.views} > page : ${title.pageDetails.pageTitle}`;
          },
        },
      },
      responsive: [
        {
          breakpoint: 1471,
          options: {
            chart: {
              height: 260,
            },
          },
        },
      ],
      grid: {
        show: false,
        padding: {
          left: 0,
          right: 0,
        },
      },
    },
    series: [
      {
        data: chartData,
      },
    ],
  };
  return (
    <Col xl="12" lg="12" className="box-col-12">
      <Card>
        <CardBody>
          <div className="chart-container">
            <div id="columnchart">
              <Chart
                options={columnChart.options}
                series={columnChart.series}
                height="380"
                type="bar"
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ChartReusable;
