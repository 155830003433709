import React, { useContext } from "react";
import {
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import ModualManagerContext from "../../context/ModuleManagerContext/ModualManagerContext";

const OrganizationDetailsModel = (props) => {
  const { ahoeDataModel, setahoeDataModel, viewName, perent, modualAccess } =
    useContext(ModualManagerContext);
  return (
    <>
      <Modal
        size="lg"
        isOpen={ahoeDataModel}
        toggle={() => setahoeDataModel(!ahoeDataModel)}
      >
        <ModalHeader toggle={() => setahoeDataModel(!ahoeDataModel)}>
          View Modual Master
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody style={{ padding: "0" }}>
                  <Card>
                    <div className="table-responsive">
                      <Table>
                        <tbody>
                          <tr>
                            <th scope="row">{"Name"}</th>
                            <td>{viewName}</td>
                          </tr>
                          <tr>
                            <th scope="row">{"Perent Name"}</th>
                            <td>{perent === "" ? "-" : perent}</td>
                          </tr>
                        </tbody>
                      </Table>
                      <CardBody>
                        <h4>Modual Access</h4>
                      </CardBody>
                      <Table>
                        <tbody>
                          {modualAccess.map((elem, index) => {
                            return (
                              <>
                                <tr key={index}>
                                  <th scope="row">{"Access Name"}</th>
                                  <td>{elem.accessName}</td>
                                  <th scope="row">{"Role Name"}</th>
                                  {elem.roleModuleAccess.map((e) => {
                                    return <td> {e.role.name}</td>;
                                  })}
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </Card>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default OrganizationDetailsModel;
