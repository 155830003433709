import React, { useContext, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import { Cases, btnName, modalsName } from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc } from "../../../utils";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import Lightbox from "react-image-lightbox";
import CommonPopup from "../../../Modals/commonPopup";
import PaymentDetailsQrPopup from "./paymentDetailsQrPopup";

const PaymentDetailsAndQr = (props) => {
  const { setAllPopupState, allPopupState } = useContext(
    ByteCardBuilderContext
  );
  const [open, setOpen] = useState(false);
  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody className="support-ticket-font box-border-div">
            <Row>
              <div className="col-12">
                <CardBuilderHeaderSection name="1 Payment Details & QR" />
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => setOpen(!open)}
                >
                  <div
                    style={{
                      objectFit: "contain",
                      maxWidth: "125px",
                      height: "83px",
                      border: "1px solid #d3d3d3",
                      width: "100%",
                      display: "inline-block",
                      verticalAlign: "middle",
                      overflow: "hidden",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        bottom: "0",
                        left: "0",
                        borderRadius: "inherit",
                      }}
                    >
                      <img
                        src="		https://api.bytecard.in/images/uploads/w-200/b6e9134f93f00ebfdc15b029d0dc34e7.jpeg"
                        alt=""
                        style={{
                          objectPosition: "50% 50%",
                          objectFit: "contain",
                          opacity: "1",
                          transition: "opacity 0.28s ease-in",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <OpenPopupBtn
                openPopup={() =>
                  OpenModalCommonFunc(Cases.PAYMETDETAILS_QR, setAllPopupState)
                }
                name={btnName.PAYMETDETAILS_QR}
              />
            </Row>
          </CardBody>
        </Card>
      </Col>

      {open && (
        <Lightbox
          mainSrc={
            "	https://api.bytecard.in/images/uploads/w-200/b6e9134f93f00ebfdc15b029d0dc34e7.jpeg"
          }
          onCloseRequest={() => setOpen(false)}
        />
      )}

      <CommonPopup
        isMini={true}
        open={allPopupState.paymentdetails_qrPopup}
        close={() =>
          OpenModalCommonFunc(Cases.PAYMETDETAILS_QR, setAllPopupState, false)
        }
        modalName={modalsName.PAYMENT_DETAILS}
        modalCase={Cases.PAYMETDETAILS_QR}
        edit={false}
        content={<PaymentDetailsQrPopup />}
      />
    </>
  );
};

export default PaymentDetailsAndQr;
