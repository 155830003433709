import React, { useContext } from "react";
import { Col, Input, Label, Row } from "reactstrap";
import FooterContext from "../../../context/FooterContext/FooterContext";
import { SketchPicker } from "react-color";
import CustomStyle from "../../../common/CustomStyle";
import { Border } from "../../../../constant";

const FooterOneThemeOne = (props) => {
  const { setFormData, formData } = useContext(FooterContext);

  /**
   * input value change
   * @param {event} e
   */

  const handleFooterOneBackground = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        footer1Style: {
          ...prev.footer1Style,
          ".custom-footer .foot-other-info": {
            ...prev.footer1Style[".custom-footer .foot-other-info"],
            [name]: value,
          },
        },
      };
    });
  };
  const logoAndContentChange = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        footer1Style: {
          ...prev.footer1Style,
          ".custom-footer .foot-about": {
            ...prev.footer1Style[".custom-footer .foot-about"],
            [name]: value,
          },
        },
      };
    });
  };
  const logoSpacing = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        footer1Style: {
          ...prev.footer1Style,
          ".custom-footer .foot-about .flogo": {
            ...prev.footer1Style[".custom-footer .foot-about .flogo"],
            [name]: value,
          },
        },
      };
    });
  };
  const logoContentFontSizeAndColor = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        footer1Style: {
          ...prev.footer1Style,
          ".custom-footer .foot-about p": {
            ...prev.footer1Style[".custom-footer .foot-about p"],
            [name]: value,
          },
        },
      };
    });
  };
  const menuSpacing = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        footer1Style: {
          ...prev.footer1Style,
          ".custom-footer .foot-menu": {
            ...prev.footer1Style[".custom-footer .foot-menu"],
            [name]: value,
          },
        },
      };
    });
  };
  const menuTitleColorAndFontSize = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        footer1Style: {
          ...prev.footer1Style,
          ".custom-footer .foot-title": {
            ...prev.footer1Style[".custom-footer .foot-title"],
            [name]: value,
          },
        },
      };
    });
  };
  const menuListSpacing = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        footer1Style: {
          ...prev.footer1Style,
          ".custom-footer .foot-menulist li": {
            ...prev.footer1Style[".custom-footer .foot-menulist li"],
            [name]: value,
          },
        },
      };
    });
  };

  const menuItemColorAndFontSize = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        footer1Style: {
          ...prev.footer1Style,
          ".custom-footer .foot-menulist li a": {
            ...prev.footer1Style[".custom-footer .foot-menulist li a"],
            [name]: value,
          },
        },
      };
    });
  };

  const socialMediaSpacing = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        footer1Style: {
          ...prev.footer1Style,
          ".custom-footer .foot-social-2 a": {
            ...prev.footer1Style[".custom-footer .foot-social-2 a"],
            [name]: value,
          },
        },
      };
    });
  };
  const socialMediaIconHandler = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        footer1Style: {
          ...prev.footer1Style,
          ".custom-footer .foot-social-2 a i": {
            ...prev.footer1Style[".custom-footer .foot-social-2 a i"],
            [name]: value,
          },
        },
      };
    });
  };

  const contactUsContentSpacingInBottom = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        footer1Style: {
          ...prev.footer1Style,
          ".custom-footer .foot-contacts": {
            ...prev.footer1Style[".custom-footer .foot-contacts"],
            [name]: value,
          },
        },
      };
    });
  };
  const contactUsItemSpacing = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        footer1Style: {
          ...prev.footer1Style,
          ".custom-footer .foot-contacts li": {
            ...prev.footer1Style[".custom-footer .foot-contacts li"],
            [name]: value,
          },
        },
      };
    });
  };
  const handleChangeCustomStyle = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        footer1Style: {
          ...prev.footer1Style,
          [name]: value,
        },
      };
    });
  };
  const HandleComplete = ({ event, classIS, className }) => {
    setFormData((prev) => {
      return {
        ...prev,
        footer1Style: {
          ...prev.footer1Style,
          [classIS]: {
            ...prev.footer1Style[classIS],
            [className]: event.rgb,
          },
        },
      };
    });
  };
  const HandleOnChange = ({ event, classIS, className }) => {
    setFormData((prev) => {
      return {
        ...prev,
        footer1Style: {
          ...prev.footer1Style,
          [classIS]: {
            ...prev.footer1Style[classIS],
            [className]: event.rgb,
          },
        },
      };
    });
  };
  return (
    <>
      <div className="mt-4 d-flex flex-column justify-content-center ">
        <Row className="mt-4">
          <h4 className="mb-3">footer background</h4>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">{"Background Color "}</Label>
            <SketchPicker
              color={
                formData?.footer1Style?.[".custom-footer .foot-other-info"]?.[
                  "background-color"
                ]
              }
              onChangeComplete={(e) =>
                HandleComplete({
                  event: e,
                  classIS: ".custom-footer .foot-other-info",
                  className: "background-color",
                })
              }
              onChange={(e) =>
                HandleOnChange({
                  event: e,
                  classIS: ".custom-footer .foot-other-info",
                  className: "background-color",
                })
              }
            />
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Top and bottom spacing (Default : 50)"}
            </Label>
            <Input
              className="form-control"
              name="p1"
              type="number"
              placeholder="Top and bottom spacing"
              value={formData.footer1Style?.[
                ".custom-footer .foot-other-info"
              ]?.["p1"]?.replace(/px/g, "")}
              onChange={(e) => handleFooterOneBackground(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
        </Row>
        <hr />
        <Row className="mt-4">
          <h4 className="mb-3">Logo and content bottom spacing</h4>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Margin Bottom (Default : 20px)"}
            </Label>
            <div className="d-flex justify-content-between">
              <Input
                className="form-control"
                name="margin-bottom"
                type="number"
                placeholder="Margin Bottom"
                value={formData.footer1Style?.[".custom-footer .foot-about"]?.[
                  "margin-bottom"
                ]?.replace(/px/g, "")}
                onChange={(e) => logoAndContentChange(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
          </Col>
        </Row>
        <hr />
        <Row className="mt-4">
          <h4 className="mb-3">Logo spacing in bottom</h4>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Margin Bottom (Default : 10px)"}
            </Label>
            <div className="d-flex justify-content-between">
              <Input
                className="form-control"
                name="margin-bottom"
                type="number"
                placeholder="Margin Bottom"
                value={formData.footer1Style?.[
                  ".custom-footer .foot-about .flogo"
                ]?.["margin-bottom"]?.replace(/px/g, "")}
                onChange={(e) => logoSpacing(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
          </Col>
        </Row>
        <hr />
        <Row className="mt-4">
          <h4 className="mb-3">logo content font size and color</h4>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Color (Default : white)"}
            </Label>
            <SketchPicker
              color={
                formData?.footer1Style?.[".custom-footer .foot-about p"]?.[
                  "color"
                ]
              }
              onChangeComplete={(e) =>
                HandleComplete({
                  event: e,
                  classIS: ".custom-footer .foot-about p",
                  className: "color",
                })
              }
              onChange={(e) =>
                HandleOnChange({
                  event: e,
                  classIS: ".custom-footer .foot-about p",
                  className: "color",
                })
              }
            />
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Font Size (Default : 16)"}
            </Label>
            <Input
              className="form-control"
              name="font-size"
              type="number"
              placeholder="Font Size"
              value={formData.footer1Style?.[".custom-footer .foot-about p"]?.[
                "font-size"
              ]?.replace(/px/g, "")}
              onChange={(e) => logoContentFontSizeAndColor(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
        </Row>
        <hr />
        <Row className="mt-4">
          <h4 className="mb-3">Overview, contact us, follow us spacing</h4>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"left spacing (Default : 10)"}
            </Label>
            <Input
              className="form-control"
              name="margin-left"
              type="number"
              placeholder="left spacing"
              value={formData.footer1Style?.[".custom-footer .foot-menu"]?.[
                "margin-left"
              ]?.replace(/px/g, "")}
              onChange={(e) => menuSpacing(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"bottom spacing (Default : 20)"}
            </Label>
            <Input
              className="form-control"
              name="margin-bottom"
              type="number"
              placeholder="bottom spacing"
              value={formData.footer1Style?.[".custom-footer .foot-menu"]?.[
                "margin-bottom"
              ]?.replace(/px/g, "")}
              onChange={(e) => menuSpacing(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
        </Row>
        <hr />
        <Row className="mt-4">
          <h4 className="mb-3">menu title color and font size</h4>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Color (Default : white)"}
            </Label>
            <SketchPicker
              color={
                formData?.footer1Style?.[".custom-footer .foot-title"]?.[
                  "color"
                ]
              }
              onChangeComplete={(e) =>
                HandleComplete({
                  event: e,
                  classIS: ".custom-footer .foot-title",
                  className: "color",
                })
              }
              onChange={(e) =>
                HandleOnChange({
                  event: e,
                  classIS: ".custom-footer .foot-title",
                  className: "color",
                })
              }
            />
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"font size (Default : 20)"}
            </Label>
            <Input
              className="form-control"
              name="font-size"
              type="number"
              placeholder="font size"
              value={formData.footer1Style?.[".custom-footer .foot-title"]?.[
                "font-size"
              ]?.replace(/px/g, "")}
              onChange={(e) => menuTitleColorAndFontSize(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"bottom spacing (Default : 15)"}
            </Label>
            <Input
              className="form-control"
              name="margin-bottom"
              type="number"
              placeholder="spacing bottom"
              value={formData.footer1Style?.[".custom-footer .foot-title"]?.[
                "margin-bottom"
              ]?.replace(/px/g, "")}
              onChange={(e) => menuTitleColorAndFontSize(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
        </Row>
        <hr />
        <Row className="mt-4">
          <h4 className="mb-3">menu list spacing</h4>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"top and bottom spacing (Default : 5)"}
            </Label>
            <Input
              className="form-control"
              name="m1"
              type="number"
              placeholder="top and bottom spacing"
              value={formData.footer1Style?.[
                ".custom-footer .foot-menulist li"
              ]?.["m1"]?.replace(/px/g, "")}
              onChange={(e) => menuListSpacing(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
        </Row>
        <hr />
        <Row className="mt-4">
          <h4 className="mb-3">menu item color and font size</h4>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Color (Default : white)"}
            </Label>
            <SketchPicker
              color={
                formData?.footer1Style?.[
                  ".custom-footer .foot-menulist li a"
                ]?.["color"]
              }
              onChangeComplete={(e) =>
                HandleComplete({
                  event: e,
                  classIS: ".custom-footer .foot-menulist li a",
                  className: "color",
                })
              }
              onChange={(e) =>
                HandleOnChange({
                  event: e,
                  classIS: ".custom-footer .foot-menulist li a",
                  className: "color",
                })
              }
            />
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"font size (Default : 14)"}
            </Label>
            <Input
              className="form-control"
              name="font-size"
              type="number"
              placeholder="font size"
              value={formData.footer1Style?.[
                ".custom-footer .foot-menulist li a"
              ]?.["font-size"]?.replace(/px/g, "")}
              onChange={(e) => menuItemColorAndFontSize(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
        </Row>
        <hr />
        <Row className="mt-4">
          <h4 className="mb-3">social icons</h4>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"top and bottom spacing (Default : 2)"}
            </Label>
            <Input
              className="form-control"
              name="m1"
              type="number"
              placeholder="top and bottom spacing"
              value={formData.footer1Style?.[
                ".custom-footer .foot-social-2 a"
              ]?.["m1"]?.replace(/px/g, "")}
              onChange={(e) => socialMediaSpacing(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"left and right spacing (Default : 5)"}
            </Label>
            <Input
              className="form-control"
              name="m2"
              type="number"
              placeholder="left and right spacing"
              value={formData.footer1Style?.[
                ".custom-footer .foot-social-2 a"
              ]?.["m2"]?.replace(/px/g, "")}
              onChange={(e) => socialMediaSpacing(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">{"Width (Default : 42)"}</Label>
            <Input
              className="form-control"
              name="width"
              type="number"
              placeholder="width"
              value={formData.footer1Style?.[
                ".custom-footer .foot-social-2 a"
              ]?.["width"]?.replace(/px/g, "")}
              onChange={(e) => socialMediaSpacing(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Height (Default : 42)"}
            </Label>
            <Input
              className="form-control"
              name="height"
              type="number"
              placeholder="height"
              value={formData.footer1Style?.[
                ".custom-footer .foot-social-2 a"
              ]?.["height"]?.replace(/px/g, "")}
              onChange={(e) => socialMediaSpacing(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"border-radius (Default : 100)"}
            </Label>
            <Input
              className="form-control"
              name="border-radius"
              type="number"
              placeholder="border-radius"
              value={formData.footer1Style?.[
                ".custom-footer .foot-social-2 a"
              ]?.["border-radius"]?.replace(/%/g, "")}
              onChange={(e) => socialMediaSpacing(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">{"Border"}</Label>
            <div className="d-flex ">
              <div style={{ width: "100px" }}>
                <Input
                  className="form-control"
                  name="borderPx"
                  type="number"
                  placeholder="px"
                  value={formData.footer1Style?.[
                    ".custom-footer .foot-social-2 a"
                  ]?.["borderPx"]?.replace(/px/g, "")}
                  onChange={(e) => socialMediaSpacing(e)}
                  style={{ borderRadius: "0", color: "black" }}
                />
              </div>
              <div style={{ width: "100px" }}>
                <select
                  name="borderThik"
                  id="borderThik"
                  className="form-control"
                  value={
                    formData.footer1Style?.[
                      ".custom-footer .foot-social-2 a"
                    ]?.["borderThik"]
                  }
                  onChange={(e) => socialMediaSpacing(e)}
                  style={{ borderRadius: "0", color: "black" }}
                >
                  <option value="">--select--</option>
                  {Border.map((elem) => {
                    return <option value={elem.border}>{elem.border}</option>;
                  })}
                </select>
              </div>
              <div>
                <Label htmlFor="validationCustom03">
                  {"BorderColor (Default : white)"}
                </Label>
                <SketchPicker
                  color={
                    formData?.footer1Style?.[
                      ".custom-footer .foot-social-2 a"
                    ]?.["borderColor"]
                  }
                  onChangeComplete={(e) =>
                    HandleComplete({
                      event: e,
                      classIS: ".custom-footer .foot-social-2 a",
                      className: "borderColor",
                    })
                  }
                  onChange={(e) =>
                    HandleOnChange({
                      event: e,
                      classIS: ".custom-footer .foot-social-2 a",
                      className: "borderColor",
                    })
                  }
                />
              </div>
            </div>
          </Col>
        </Row>
        <hr />
        <Row className="mt-4">
          <h4 className="mb-3">social icons i</h4>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"font-size (Default : 18)"}
            </Label>
            <Input
              className="form-control"
              name="Font-size"
              type="number"
              placeholder="font-size"
              value={formData.footer1Style?.[
                ".custom-footer .foot-social-2 a i"
              ]?.["font-size"]?.replace(/px/g, "")}
              onChange={(e) => socialMediaIconHandler(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"line-height (Default : 0)"}
            </Label>
            <Input
              className="form-control"
              name="line-height"
              type="number"
              placeholder="line-height"
              value={formData.footer1Style?.[
                ".custom-footer .foot-social-2 a i"
              ]?.["line-height"]?.replace(/px/g, "")}
              onChange={(e) => socialMediaIconHandler(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">{"Color"}</Label>
            <SketchPicker
              color={
                formData?.footer1Style?.[".custom-footer .foot-social-2 a i"]?.[
                  "color"
                ]
              }
              onChangeComplete={(e) =>
                HandleComplete({
                  event: e,
                  classIS: ".custom-footer .foot-social-2 a i",
                  className: "color",
                })
              }
              onChange={(e) =>
                HandleOnChange({
                  event: e,
                  classIS: ".custom-footer .foot-social-2 a i",
                  className: "color",
                })
              }
            />
          </Col>
        </Row>
        <hr />
        <Row className="mt-4">
          <h4 className="mb-3">contact us spacing in bottom</h4>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"spacing bottom (Default : 20)"}
            </Label>
            <Input
              className="form-control"
              name="margin-bottom"
              type="number"
              placeholder="spacing bottom"
              value={formData.footer1Style?.[".custom-footer .foot-contacts"]?.[
                "margin-bottom"
              ]?.replace(/px/g, "")}
              onChange={(e) => contactUsContentSpacingInBottom(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
        </Row>
        <hr />
        <Row className="mt-4">
          <h4 className="mb-3">contact us item spacing</h4>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"left spacing (Default : 30)"}
            </Label>
            <Input
              className="form-control"
              name="padding-left"
              type="number"
              placeholder="left spacing"
              value={formData.footer1Style?.[
                ".custom-footer .foot-contacts li"
              ]?.["padding-left"]?.replace(/px/g, "")}
              onChange={(e) => contactUsItemSpacing(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"bottom spacing (Default : 30)"}
            </Label>
            <Input
              className="form-control"
              name="m1"
              type="number"
              placeholder="bottom spacing"
              value={formData.footer1Style?.[
                ".custom-footer .foot-contacts li"
              ]?.["m1"]?.replace(/px/g, "")}
              onChange={(e) => contactUsItemSpacing(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
        </Row>
        <hr />
        <Row className="mt-4">
          <h4 className="mb-3">contact us icons color</h4>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Color (Default : white)"}
            </Label>
            <SketchPicker
              color={
                formData?.footer1Style?.[
                  ".custom-footer .foot-contacts li .fa"
                ]?.["color"]
              }
              onChangeComplete={(e) =>
                HandleComplete({
                  event: e,
                  classIS: ".custom-footer .foot-contacts li .fa",
                  className: "color",
                })
              }
              onChange={(e) =>
                HandleOnChange({
                  event: e,
                  classIS: ".custom-footer .foot-contacts li .fa",
                  className: "color",
                })
              }
            />
          </Col>
        </Row>
        <hr />
        <Row className="mt-4">
          <h4 className="mb-3">contact us item color</h4>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Color (Default : white)"}
            </Label>
            <SketchPicker
              color={
                formData?.footer1Style?.[
                  ".custom-footer .foot-contacts li a"
                ]?.["color"]
              }
              onChangeComplete={(e) =>
                HandleComplete({
                  event: e,
                  classIS: ".custom-footer .foot-contacts li a",
                  className: "color",
                })
              }
              onChange={(e) =>
                HandleOnChange({
                  event: e,
                  classIS: ".custom-footer .foot-contacts li a",
                  className: "color",
                })
              }
            />
          </Col>
        </Row>
        <CustomStyle
          handleChange={handleChangeCustomStyle}
          value={formData?.footer1Style?.custom}
        />
      </div>
    </>
  );
};

export default FooterOneThemeOne;
