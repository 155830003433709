import React, { useState } from "react";
import FontListContext from "./FontListContext";

const FontListState = (props) => {
  const [formValue, setFormValue] = useState([]);

  const [formData, setFormData] = useState({
    title: "",
    fontFamily: "",
    googleFontScript: "",
  });
  const [progress, setProgress] = useState(0);
  const [sortOrder, setSortOrder] = useState(""); //SORTING -->
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState("createdAt"); //SORTING -->
  const [pageLimit, setPageLimit] = useState("");
  const [record, setRecord] = useState(0);
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [addModel, setAddModel] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [id, setId] = useState("");
  return (
    <FontListContext.Provider
      value={{
        formValue,
        setFormValue,
        pageSize,
        setPageSize,
        sortOrder,
        setSortOrder,
        page,
        setPage,
        sortBy,
        setSortBy,
        pageLimit,
        setPageLimit,
        record,
        setRecord,
        isSwitchOn,
        setIsSwitchOn,
        addModel,
        setAddModel,
        formData,
        setFormData,
        isEdit,
        setIsEdit,
        id,
        setId,
        progress,
        setProgress,
      }}
    >
      {props.children}
    </FontListContext.Provider>
  );
};

export default FontListState;
