import { Zoom, toast } from "react-toastify";
import { Cases, StateObjectPopup } from "../constant";
/**
 * model open & close common function
 * @param {switch condition name} name
 * @param {state update function} setAllPopupState
 * @param {false for popup close} condition
 */

export const OpenModalCommonFunc = (
  name,
  setAllPopupState,
  condition,
  emptyData,
  inputData
) => {
  const updateStateDainamic = (stateObj) => {
    const newObj = { ...inputData };
    const keys = Object.keys(newObj);
    const lastKey = keys.pop();
    let removeKey = "";
    if (lastKey) {
      delete newObj[lastKey];
      removeKey = newObj;
    }
    let conditionValue = confirmDelete(removeKey);
    if (
      condition === false &&
      stateObj === StateObjectPopup.PRODUCTS_UPLOAD_POPUP
    ) {
      setAllPopupState((prev) => {
        return {
          ...prev,
          [stateObj]: false,
        };
      });
    } else if (condition === false && conditionValue) {
      let confirmation = window.confirm("Are you sure you want to close ? ");
      if (confirmation) {
        setAllPopupState((prev) => {
          return {
            ...prev,
            [stateObj]: false,
          };
        });
        emptyData();
      }
    } else if (condition === false) {
      setAllPopupState((prev) => {
        return {
          ...prev,
          [stateObj]: false,
        };
      });
    } else {
      setAllPopupState((prev) => {
        return {
          ...prev,
          [stateObj]: true,
        };
      });
    }
  };

  switch (name) {
    case Cases.THEME:
      updateStateDainamic(StateObjectPopup.COLOR_POPUP); // state update func call
      break;
    case Cases.BUSINESS:
      updateStateDainamic(StateObjectPopup.BUSINESS_POPUP);
      break;
    case Cases.COMPANY:
      updateStateDainamic(StateObjectPopup.COMPANY_POPUP);
      break;
    case Cases.SEO:
      updateStateDainamic(StateObjectPopup.SEO_POPUP);
      break;
    case Cases.ABOUT_US:
      updateStateDainamic(StateObjectPopup.ABOUT_US_POPUP);
      break;
    case Cases.PRODUCTS:
      updateStateDainamic(StateObjectPopup.PRODUCTS_POPUP);
      break;
    case Cases.PRODUCTS_PARENT:
      updateStateDainamic(StateObjectPopup.PRODUCTS_PARENT_POPUP);
      break;
    case Cases.PRODUCT_UPLOAD:
      updateStateDainamic(StateObjectPopup.PRODUCTS_UPLOAD_POPUP);
      break;
    case Cases.VIDEOS:
      updateStateDainamic(StateObjectPopup.VIDEOS_POPUP);
      break;
    case Cases.VIDEOS_PARENT:
      updateStateDainamic(StateObjectPopup.VIDEOS_PARENT_POPUP);
      break;
    case Cases.DOWNLOADS:
      updateStateDainamic(StateObjectPopup.DOWNLOADS_POPUP);
      break;
    case Cases.IMAGES:
      updateStateDainamic(StateObjectPopup.IMAGES_POPUP);
      break;
    case Cases.IMAGES_PARENT:
      updateStateDainamic(StateObjectPopup.IMAGES_PARENT_POPUP);
      break;
    case Cases.IMAGES_CHILES:
      updateStateDainamic(StateObjectPopup.IMAGES_CHILD_POPUP);
      break;
    case Cases.VIDEOS_CHILES:
      updateStateDainamic(StateObjectPopup.VIDEOS_CHILD_POPUP);
      break;
    case Cases.LATEST_NEWS_PARENT:
      updateStateDainamic(StateObjectPopup.LATEST_NEWS_POPUP);
      break;
    case Cases.LATEST_NEWS:
      updateStateDainamic(StateObjectPopup.LATEST_NEWS_CHILD_POPUP);
      break;
    case Cases.ITEM_FEATURE_PARENT:
      updateStateDainamic(StateObjectPopup.ITEM_FEATURE_POPUP);
      break;
    case Cases.ITEM_FEATURE:
      updateStateDainamic(StateObjectPopup.ITEM_FEATURE_CHILD_POPUP);
      break;
    case Cases.PACKAGES_PARENT:
      updateStateDainamic(StateObjectPopup.PACKAGES_POPUP);
      break;
    case Cases.PACKAGES:
      updateStateDainamic(StateObjectPopup.PACKAGES_CHILD_POPUP);
      break;
    case Cases.SERVICE_COUNTER_PARENT:
      updateStateDainamic(StateObjectPopup.SERVICE_COUNTER_POPUP);
      break;
    case Cases.SERVICE_COUNTER:
      updateStateDainamic(StateObjectPopup.SERVICE_COUNTER_CHILD_POPUP);
      break;
    case Cases.OUR_CLIENT_PARENT:
      updateStateDainamic(StateObjectPopup.OUR_CLIENT_POPUP);
      break;
    case Cases.OUR_CLIENT:
      updateStateDainamic(StateObjectPopup.OUR_CLIENT_CHILD_POPUP);
      break;
    case Cases.PAGE_TITLE:
      updateStateDainamic(StateObjectPopup.PAGE_TITLE_POPUP);
      break;
    case Cases.IMAGE_BANNER_PARENT:
      updateStateDainamic(StateObjectPopup.IMAGE_BANNER_POPUP);
      break;
    case Cases.IMAGE_BANNER:
      updateStateDainamic(StateObjectPopup.IMAGE_BANNER_CHILD_POPUP);
      break;
    case Cases.VIDEO_BANNER_PARENT:
      updateStateDainamic(StateObjectPopup.VIDEO_BANNER_POPUP);
      break;
    case Cases.VIDEO_BANNER:
      updateStateDainamic(StateObjectPopup.VIDEO_BANNER_CHILD_POPUP);
      break;
    case Cases.FAQS_PARENT:
      updateStateDainamic(StateObjectPopup.FAQS_POPUP);
      break;
    case Cases.FAQS:
      updateStateDainamic(StateObjectPopup.FAQS_CHILD_POPUP);
      break;
    case Cases.STEPS_PARENT:
      updateStateDainamic(StateObjectPopup.STEPS_POPUP);
      break;
    case Cases.STEPS:
      updateStateDainamic(StateObjectPopup.STEPS_CHILD_POPUP);
      break;
    case Cases.CAREER_PARENT:
      updateStateDainamic(StateObjectPopup.CAREER_POPUP);
      break;
    case Cases.CAREER:
      updateStateDainamic(StateObjectPopup.CAREER_CHILD_POPUP);
      break;
    case Cases.GET_WHATSAPP_LINK_PARENT:
      updateStateDainamic(StateObjectPopup.GET_WHATSAPP_LINK_POPUP);
      break;
    case Cases.GET_WHATSAPP_LINK:
      updateStateDainamic(StateObjectPopup.GET_WHATSAPP_LINK_CHILD_POPUP);
      break;
    case Cases.SLIDER_MULTIPAGE_PARENT:
      updateStateDainamic(StateObjectPopup.SLIDER_MULTIPAGE_POPUP);
      break;
    case Cases.SLIDER_MULTIPAGE:
      updateStateDainamic(StateObjectPopup.SLIDER_MULTIPAGE_CHILD_POPUP);
      break;
    case Cases.TESTIMONIAL_MULTIPAGE_PARENT:
      updateStateDainamic(StateObjectPopup.TESTIMONIAL_MULTIPAGE_POPUP);
      break;
    case Cases.TESTIMONIAL_MULTIPAGE:
      updateStateDainamic(StateObjectPopup.TESTIMONIAL_MULTIPAGE_CHILD_POPUP);
      break;
    case Cases.INQUIRY:
      updateStateDainamic(StateObjectPopup.INQUIRY_CHILD_POPUP);
      break;
    case Cases.INQUIRY_PARENT:
      updateStateDainamic(StateObjectPopup.INQUIRY_POPUP);
      break;
    case Cases.SETTING_INQUIRY_PARENT:
      updateStateDainamic(StateObjectPopup.INQUIRY_PARENT_POPUP);
      break;
    case Cases.DOWNLOAD_MULTIPAGE_PARENT:
      updateStateDainamic(StateObjectPopup.DOWNLOAD_MULTIPAGE_POPUP);
      break;
    case Cases.DOWNLOAD_MULTIPAGE:
      updateStateDainamic(StateObjectPopup.DOWNLOAD_MULTIPAGE_CHILD_POPUP);
      break;
    case Cases.SOCIALMEDIA_MULTIPAGE_PARENT:
      updateStateDainamic(StateObjectPopup.SOCIALMEDIA_MULTIPAGE_POPUP);
      break;
    case Cases.SOCIALMEDIA_MULTIPAGE:
      updateStateDainamic(StateObjectPopup.SOCIALMEDIA_MULTIPAGE_CHILD_POPUP);
      break;
    case Cases.PAYMETDETAILS_QR:
      updateStateDainamic(StateObjectPopup.PAYMETDETAILS_QR_POPUP);
      break;
    case Cases.CONTACT_US:
      updateStateDainamic(StateObjectPopup.CONTACT_US_POPUP);
      break;
    case Cases.SOCIAL_MEDIA:
      updateStateDainamic(StateObjectPopup.SOCIAL_MEDIA_POPUP);
      break;
    case Cases.TESTIMONIAL:
      updateStateDainamic(StateObjectPopup.TESTIMONIAL_POPUP);
      break;
    case Cases.DRAG_DROP:
      updateStateDainamic(StateObjectPopup.DRAG_DROP_POPUP);
      break;
    case Cases.SLIDER:
      updateStateDainamic(StateObjectPopup.SLIDER_POPUP);
      break;
    case Cases.OUR_TEAM:
      updateStateDainamic(StateObjectPopup.OUR_TEAM_POPUP);
      break;
    case Cases.OUR_TEAM_MEMBER:
      updateStateDainamic(StateObjectPopup.OUR_TEAM_MEMBER_POPUP);
      break;
    case Cases.BLOG:
      updateStateDainamic(StateObjectPopup.BLOG_POPUP);
      break;
    case Cases.BLOG_DETAIL:
      updateStateDainamic(StateObjectPopup.BLOG_DETAIL_POPUP);
      break;
    case Cases.SERVICE_CATEGORY:
      updateStateDainamic(StateObjectPopup.SERVICE_CATEGORY_POPUP);
      break;
    case Cases.SERVICE_:
      updateStateDainamic(StateObjectPopup.SERVICE__POPUP);
      break;
    case Cases.DETAILS:
      updateStateDainamic(StateObjectPopup.DETAILS_POPUP);
      break;
    case Cases.SERVICE:
      updateStateDainamic(StateObjectPopup.SERVICE_POPUP);
      break;
    case Cases.B_W_T_And_BUTTON:
      updateStateDainamic(StateObjectPopup.B_W_T_And_BUTTON_POPUP);
      break;
    case Cases.PRODUCT_FEATURES:
      updateStateDainamic(StateObjectPopup.PRODUCT_FEATURES_POPUP);
      break;
    case Cases.DATA_COUNTER:
      updateStateDainamic(StateObjectPopup.DATA_COUNTER_POPUP);
      break;
    case Cases.SUB_DOMAIN:
      updateStateDainamic(StateObjectPopup.SUB_DOMAIN_POPUP);
      break;
    case Cases.PAYMENT:
      updateStateDainamic(StateObjectPopup.PAYMENT_POPUP);
      break;
    case Cases.PAYMENT_ITEM:
      updateStateDainamic(StateObjectPopup.PAYMENT_ITEMS_POPUP);
      break;
    case Cases.SETTING_IMAGES_PARENT:
      updateStateDainamic(StateObjectPopup.SETTING_POPUP);
      break;
    case Cases.SETTING_ABOUT_PARENT:
      updateStateDainamic(StateObjectPopup.ABOUT_SETTING_POPUP);
      break;
    case Cases.SETTING_SLIDER_PARENT:
      updateStateDainamic(StateObjectPopup.SLIDER_SETTING_POPUP);
      break;
    case Cases.SETTING_PACKAGES_PARENT:
      updateStateDainamic(StateObjectPopup.PACKAGES_SETTING_POPUP);
      break;
    case Cases.SETTING_ITEM_PARENT:
      updateStateDainamic(StateObjectPopup.ITEM_SETTING_POPUP);
      break;
    case Cases.ABOUT_US_PARENT:
      updateStateDainamic(StateObjectPopup.ABOUT_US_PARENT_POPUP);
      break;
    case Cases.SETTING_LATEST_PARENT:
      updateStateDainamic(StateObjectPopup.LATEST_PARENT_POPUP);
      break;
    case Cases.SETTING_SERVICECOUNTER_PARENT:
      updateStateDainamic(StateObjectPopup.SERVICE_COUNTER_PARENT_POPUP);
      break;
    case Cases.SETTING_VIDEO_BANNER:
      updateStateDainamic(StateObjectPopup.VIDEO_BANNER_SETTING_POPUP);
      break;
    case Cases.SETTING_CAREER_PARENT:
      updateStateDainamic(StateObjectPopup.CAREER_SETTING_POPUP);
      break;
    case Cases.SETTING_BLOG_PARENT:
      updateStateDainamic(StateObjectPopup.BLOG_SETTING_POPUP);
      break;
    case Cases.SETTING_STEPS_PARENT:
      updateStateDainamic(StateObjectPopup.STEPS_PARENT_POPUP);
      break;
    case Cases.SETTING_SERVICE_CATEGORY_PARENT:
      updateStateDainamic(StateObjectPopup.SERVICE_CATEGORY_SETTING_POPUP);
      break;
    case Cases.SETTING_IMAGE_MULTI_PAGE_PARENT:
      updateStateDainamic(StateObjectPopup.IMAGE_MULTI_PAGE_SETTING_POPUP);
      break;
    case Cases.SETTING_GET_LINK_START_PARENT:
      updateStateDainamic(StateObjectPopup.GET_LINK_START_SETTING_POPUP);
      break;
    case Cases.SETTING_FAQS_PARENT:
      updateStateDainamic(StateObjectPopup.FAQS_PARENT_POPUP);
      break;
    case Cases.SETTING_DOWNLOAD_PARENT:
      updateStateDainamic(StateObjectPopup.DOWNLOAD_SETTING_POPUP);
      break;
    case Cases.SETTING_TESTIMONIAL_PARENT:
      updateStateDainamic(StateObjectPopup.TESTIMONIAL_SETTING_POPUP);
      break;
    case Cases.SETTING_CONtACT_US_PARENT:
      updateStateDainamic(StateObjectPopup.CONTACT_US_SETTING_POPUP);
      break;
    case Cases.SETTING_SOCIAL_MEDIA_PARENT:
      updateStateDainamic(StateObjectPopup.SOCIAL_MEDIA_SETTING_POPUP);
      break;
    case Cases.SETTING_OUR_TEAM_PARENT:
      updateStateDainamic(StateObjectPopup.OUR_TEAM_SETTING_POPUP);
      break;
    case Cases.SETTING_IMAGE_BANNER_PARENT:
      updateStateDainamic(StateObjectPopup.IMAGE_BANNER_SETTING_POPUP);
      break;
    case Cases.SETTING_CONTACT_US_PARENT:
      updateStateDainamic(StateObjectPopup.CONTACT_US_SETTING_POPUP);
      break;
    case Cases.SETTING_OUR_CLIENT_PARENT:
      updateStateDainamic(StateObjectPopup.OUR_CLIENT_PARENT_POPUP);
      break;
    case Cases.SETTING_DETAILS_PARENT:
      updateStateDainamic(StateObjectPopup.DETAILS_PARENT_POPUP);
      break;
    case Cases.SETTING_PAGE_TITLE_PARENT:
      updateStateDainamic(StateObjectPopup.PAGE_TITLE_PARENT_POPUP);
      break;
    case Cases.SETTING_VIDEO_PARENT:
      updateStateDainamic(StateObjectPopup.VIDEO_PARENT_POPUP);
      break;
    case Cases.SETTING_PRODUCT_PARENT:
      updateStateDainamic(StateObjectPopup.PRODUCT_PARENT_POPUP);
      break;
    case Cases.POPUP_DIALOG_PARENT:
      updateStateDainamic(StateObjectPopup.POPUP_DIALOG_POPUP);
      break;
    case Cases.POPUP_DIALOG:
      updateStateDainamic(StateObjectPopup.POPUP_DIALOG_CHILD_POPUP);
      break;
    case Cases.SETTING_POPUP_DIALOG_PARENT:
      updateStateDainamic(StateObjectPopup.POPUP_DIALOG_CHILD_SETTING_POPUP);
      break;
    case Cases.SETTING_NAVBAR_PARENT:
      updateStateDainamic(StateObjectPopup.NAVBAR_SETTING_POPUP);
      break;
    case Cases.PRODUCT_BUY:
      updateStateDainamic(StateObjectPopup.PRODUCT_BUY_POPUP);
      break;
    case Cases.SETTING_PRODUCT_BUY_PARENT:
      updateStateDainamic(StateObjectPopup.PRODUCT_BUY_SETTING_POPUP);
      break;
    case Cases.SETTING_PRODUCT_BUY_PARENT_STYLE:
      updateStateDainamic(StateObjectPopup.PRODUCT_BUY_SETTING_POPUP_STYLE);
      break;
    case Cases.SETTING_PRODUCT_BUY_PARENT_CHECk_OUT_STYLE:
      updateStateDainamic(
        StateObjectPopup.PRODUCT_BUY_SETTING_POPUP_CHECK_OUT_STYLE
      );
      break;
    case Cases.SETTING_PRODUCT_BUY_PARENT_MY_ACCOUNT_STYLE:
      updateStateDainamic(
        StateObjectPopup.PRODUCT_BUY_SETTING_POPUP_MY_ACCOUNT_STYLE
      );
      break;
    case Cases.SETTING_FOOTER_PARENT:
      updateStateDainamic(StateObjectPopup.FOOTER_SETTING_POPUP);
      break;
    case Cases.FOOTER:
      updateStateDainamic(StateObjectPopup.FOOTER_POPUP);
      break;
    case Cases.SETTING_FOOTER_TOP_HEADER:
      updateStateDainamic(StateObjectPopup.FOOTER_TOP_HEADER_STYLE_POPUP);
      break;
    case Cases.SETTING_FOOTER_STYLE_ONE:
      updateStateDainamic(StateObjectPopup.FOOTER_ONE_STYLE_POPUP);
      break;
    case Cases.SETTING_FOOTER_STYLE_TWO:
      updateStateDainamic(StateObjectPopup.FOOTER_TWO_STYLE_POPUP);
      break;
    case Cases.FOOTER_TOP_HEADER:
      updateStateDainamic(StateObjectPopup.FOOTER_TOP_HEADER_POPUP);
      break;
    case Cases.FOOTER_ONE:
      updateStateDainamic(StateObjectPopup.FOOTER_ONE);
      break;
    case Cases.FOOTER_TWO:
      updateStateDainamic(StateObjectPopup.FOOTER_TWO);
      break;
    case Cases.SETTING_FOOTER:
      updateStateDainamic(StateObjectPopup.SETTING_FOOTER);
      break;
    case Cases.FOOTER_FORM:
      updateStateDainamic(StateObjectPopup.FOOTER_FORM_POPUP);
      break;
    case Cases.SETTING_COMMON_STYLE_PARENT:
      updateStateDainamic(StateObjectPopup.COMMON_STYLE_POPUP);
      break;
    case Cases.CUSTOM_FORM_PARENT:
      updateStateDainamic(StateObjectPopup.CUSTOM_FORM_POPUP);
      break;
    case Cases.CUSTOM_FORM_POPUP:
      updateStateDainamic(StateObjectPopup.CUSTOM_FORM_CHILD_POPUP);
      break;
    case Cases.SETTING_PRODUCT_BUY_PARENT_PAGE:
      updateStateDainamic(
        StateObjectPopup.PRODUCT_BUY_SETTING_POPUP_PAGE_STYLE
      );
      break;
    case Cases.SETTING_PRODUCT_BUY_PARENT_CART_PAGE:
      updateStateDainamic(
        StateObjectPopup.PRODUCT_BUY_SETTING_POPUP_CART_PAGE_STYLE
      );
      break;
    default:
      break;
  }
};

/**
 * toast message return
 * @param {Parameters} param0
 * @returns
 */
export const TostMessage = ({ message, type, position }) => {
  if (type === "error" && position === "custome") {
    return toast.error(message, {
      autoClose: 2000,
      position: "top-center",
    });
  } else if (type === "success" && position === "custome") {
    return toast.success(message, {
      autoClose: 1000,
      position: "top-center",
    });
  } else if (type === "success" && position === "center") {
    return toast.success(message.message, {
      autoClose: 1000,
      position: "top-center",
    });
  } else if (type === "error") {
    return toast.error(message?.response?.data?.message || message?.message, {
      autoClose: 1000,
      transition: Zoom,
    });
  } else if (type === "success") {
    return toast.success(message.message, {
      autoClose: 1000,
      transition: Zoom,
    });
  }
};

export const confirmDelete = (obj) => {
  let hasNonEmptyKeys = false;
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key]?.length > 0) {
      hasNonEmptyKeys = true;
      break;
    }
  }
  return hasNonEmptyKeys;
};

// Function to remove blank keys from an object
export const removeBlankKeys = (obj) => {
  for (const key in obj) {
    if (!obj[key]) {
      delete obj[key];
    }
  }
};
