import React, { useContext, useRef } from "react";
import { DELETE_CONFIRM, SinglePage } from "../../../constant";
import { TostMessage } from "../../../utils";
import { Col, Container, Input, Label, Row } from "reactstrap";
import ItemsDragAndDrops from "../../../common/ItemsDragAndDrops";
import DownloadsContext from "../../../context/DownloadesContext/DownloadsContext";
import {
  deleteDownload,
  sort_order_Download,
} from "../../../apis/DownloadsApi/DownloadApi";
import AddNewButton from "../../../common/AddNewButton";
import { maxLengthCheck } from "../../../../utils/helper";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import { fileUploadSizes } from "../../../../constant";

const DownloadPopup = (props) => {
  const {
    handleChangeStatus,
    getData,
    handleEdit,
    emptyData,
    simpleValidator,
    webMasterDetail,
  } = props;
  const {
    apiData,
    setApiData,
    fromData,
    setFormData,
    isEdit,
    setFilter,
    filter,
  } = useContext(DownloadsContext);
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * drag and drop
   * @param {drag value} result
   * @returns
   */
  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;
    const items = Array.from(apiData.data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setApiData({ ...apiData, data: items });
    try {
      let itemOrder = [];
      for (const item in items) {
        itemOrder.push({ downloadId: items[item].Id, order: +item + 1 });
      }
      const { data } = await sort_order_Download(itemOrder);
      if (data.status === 200) {
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * input value change
   * @param {Event} e
   */
  const changeFormValue = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  /**
   * delete download
   * @param {Id} downloadId
   */
  const handleDelete = async (downloadId) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await deleteDownload(downloadId);
        if (data.status === 200) {
          getData();
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * new download create
   */
  const hanldenewCreate = () => {
    const fileInput = document.getElementById("fileInput");
    emptyData(fileInput);
  };

  const allowedExtensions = ["jpg", "png", "pdf", "doc", "txt", "xls"];
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file.size <= fileUploadSizes.download) {
      const extension = file.name.split(".").pop().toLowerCase();
      if (allowedExtensions.includes(extension)) {
        setFormData((prev) => {
          return {
            ...prev,
            filePath: file,
          };
        });
      } else {
        const fileInput = document.getElementById("fileInput");
        if (fileInput) {
          fileInput.value = null;
        }
        TostMessage({
          message: "file type required only .jpg, .png, .pdf, .doc, .txt, .xls",
          type: "error",
          position: "custome",
        });
      }
    } else {
      const fileInput = document.getElementById("fileInput");
      if (fileInput) {
        fileInput.value = null;
      }
      TostMessage({
        message: "The selected file exceeds the maximum allowed size of 25 MB.",
        type: "error",
        position: "custome",
      });
    }
  };
  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="6">
            <h6>{apiData?.data?.length} Downloads</h6>
            <p className="pt-2">You can add upto 25 Items</p>
            <Row>
              <Col md="6">
                <Input
                  className="form-control mt-3 "
                  name="search"
                  type="text"
                  placeholder="search"
                  style={{ borderRadius: "0", color: "black" }}
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                />
              </Col>
              {webMasterDetail?.webCategory?.name === SinglePage.WebCategory &&
                apiData?.data?.length !== webMasterDetail?.itemLimit && (
                  <Col
                    md="6"
                    className="d-flex justify-content-center align-items-center mt-3"
                  >
                    <AddNewButton click={() => hanldenewCreate()} />
                  </Col>
                )}
            </Row>
            {apiData.isLoading ? (
              <div className="mt-4">
                <h6>Loading ...</h6>
              </div>
            ) : (
              <div className="mt-4">
                <ItemsDragAndDrops
                  handleOnDragEnd={handleOnDragEnd}
                  characters={apiData?.data}
                  handleChangeStatus={handleChangeStatus}
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                />
              </div>
            )}
          </Col>
          {(webMasterDetail?.webCategory?.name === SinglePage.WebCategory &&
            apiData?.data?.length === webMasterDetail?.itemLimit &&
            isEdit) ||
          (webMasterDetail?.webCategory?.name === SinglePage.WebCategory &&
            apiData?.data?.length !== webMasterDetail?.itemLimit) ? (
            <Col sm="12" xl="6">
              <div className="mt-4">
                <h6>{isEdit ? "Edit" : "Add New"}</h6>
                <Row className="mt-4">
                  <Col md="12 mb-1">
                    <Label htmlFor="validationCustom03">{"Title *"}</Label>
                    <Input
                      className="form-control"
                      name="title"
                      type="text"
                      placeholder="Title *"
                      style={{ borderRadius: "0", color: "black" }}
                      value={fromData.title}
                      onChange={(e) => changeFormValue(e)}
                      min={0}
                      maxLength={50}
                      onInput={maxLengthCheck}
                    />
                    <div className="text-end mt-3 ">
                      <span>{fromData.title.length} / 50</span>
                    </div>
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "title",
                        fromData.title,
                        "required"
                      )}
                    </p>
                  </Col>
                  <Col md="12 mb-3">
                    <Label htmlFor="validationCustom03">{"File * "}</Label>
                    <p>File type required JPG, PNG, PDF, DOC, TXT, XLS</p>
                    <Input
                      className="form-control"
                      name="url"
                      type="file"
                      accept=".jpg, .png, .pdf, .doc, .txt, .xls"
                      placeholder="Enter URL *"
                      style={{ borderRadius: "0", color: "black" }}
                      onChange={handleFileChange}
                      id="fileInput"
                    />
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "file",
                        fromData.filePath,
                        "required"
                      )}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          ) : (
            ""
          )}
        </Row>
      </Container>
    </>
  );
};

export default DownloadPopup;
