import React, { useContext } from "react";
import { MOMENT_SETTING, Style, TITLES, image_Not_found } from "../../../../constant";
import { Add } from "@material-ui/icons";
import { DataGrid } from "@mui/x-data-grid";
import { Card, CardHeader, Col, Container, Row } from "reactstrap";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import { TabelAnimation } from "../../../../Spinner";
import DataNotFound from "../../../../DataNoteFound/DataNotFound";
import { MuiTabelStyle, headerStyle } from "../../../../utils/MuiTabelStyle";
import { IconButton, Tooltip } from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";

import Switch from "react-js-switch";
import moment from "moment";
import { TostMessage } from "../../../../components ByteCard/utils";
import AddOnProductContext from "../../../../context/MenuMasterContext/AddOnProductContext/AddOnProductContext";
import { Ditails_AddOnProduct_List } from "../../../../api/MenuMasterApi/AddOnProductApi/AddOnProductApi";

const AddOnProductItem = (props) => {
  const { sorting, switch_onChange_handle, handleDelete } = props;
  const { setLoaderShow, tabelAnimation } = useContext(LoaderContext);
  const {
    formValue,
    record,
    pageSize,
    page,
    setPageSize,
    setPage,
    setAddModel,
    setFormData,
    setIsEdit,
    setId,
  } = useContext(AddOnProductContext);

  const EditHandle = async (addOnProductId) => {
    try {
      setLoaderShow(true);
      setId(addOnProductId);
      const { data } = await Ditails_AddOnProduct_List(addOnProductId);
      if (data.status === 200) {
        let payload = data.payload.data;

        setFormData({
          name: payload?.name || "",
          price: payload?.price || "",
          buttonName: payload?.buttonName || "",
          thumbImage: payload?.thumbImage || "",
          menuProductId: payload?.menuProductId || "",
          qpiId: payload?.qpiId || "",
        });
        setIsEdit(true);
        setLoaderShow(false);
        setAddModel(true);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 100,
      field: "thumbImage",
      headerName: "Thumb Image",
      renderCell: (params) =>
        params.row.thumbImage ? (
          <img src={params.row.thumbImage} alt="" style={{ width: "70px" }} />
        ) : (
          <img
            src={
              image_Not_found
            }
            alt=""
            style={{ width: "70px" }}
          />
        ),
    },
    {
      flex: 0.1,
      minWidth: 200,
      field: "name",
      headerName: "Name",
      renderCell: (params) => (params.row.name ? params.row.name : "-"),
    },
    {
      flex: 0.1,
      minWidth: 140,
      field: "price",
      headerName: "Price",
      renderCell: (params) => (params.row.price ? params.row.price : "-"),
    },
    {
      flex: 0.1,
      minWidth: 140,
      field: "menuProductId",
      headerName: "MenuProductId",
      renderCell: (params) =>
        params.row.menuProductId ? params.row.menuProductId : "-",
    },
    {
      flex: 0.1,
      minWidth: 140,
      field: "buttonName",
      headerName: "Button Name",
      renderCell: (params) =>
        params.row.buttonName ? params.row.buttonName : "-",
    },
    {
      flex: 0.1,
      minWidth: 150,
      field: "createdAt",
      headerName: "Created Date",
      renderCell: (params) =>
        params.row.createdAt
          ? moment.utc(params.row.createdAt).format(MOMENT_SETTING.FORMATE)
          : "-",
    },
    {
      field: "isActive",
      headerName: "Status",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Switch
          value={params.row.isActive ? true : false}
          onChange={() =>
            switch_onChange_handle(
              params.row.addOnProductId,
              params.row.isActive
            )
          }
        />
      ),
    },
    {
      flex: 0.01,
      minWidth: 80,
      field: "edit",
      headerName: "Edit",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Tooltip title="Edit">
          <IconButton
            aria-label="edit"
            style={{ color: "green" }}
            onClick={() => EditHandle(params.row.addOnProductId)}
          >
            <ModeEditIcon />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      flex: 0.01,
      minWidth: 80,
      field: "delete",
      headerName: "Delete",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Tooltip title="Delete">
          <IconButton
            aria-label="delete"
            style={{ color: "red" }}
            onClick={() => handleDelete(params.row.addOnProductId)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  const AddProducts = () => {
    setAddModel(true);
  };
  return (
    <>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <h3 style={headerStyle}>{TITLES.ADD_ON_PRODUCTS_LIST}</h3>

                <div
                  aria-label="add"
                  onClick={() => AddProducts()}
                  className="btn btn-primary"
                  style={Style}
                >
                  <Add />
                </div>
              </CardHeader>
              {tabelAnimation === true ? (
                <TabelAnimation />
              ) : formValue.length ? (
                <DataGrid
                  autoHeight
                  sx={MuiTabelStyle}
                  pagination
                  rows={formValue}
                  getRowId={(row) => row.addOnProductId}
                  columns={defaultColumns}
                  rowsPerPageOptions={[7, 10, 25, 50]}
                  rowCount={record}
                  pageSize={pageSize}
                  page={page - 1}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  onPageChange={(newPageNo) => setPage(newPageNo + 1)}
                  paginationMode="server"
                  disableSelectionOnClick
                  checkboxSelection={false}
                  sortingMode="server"
                  onSortModelChange={sorting}
                />
              ) : (
                <DataNotFound />
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddOnProductItem;
