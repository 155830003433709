import React, { useContext } from "react";
import { DELETE_CONFIRM, SinglePage } from "../../../constant";
import { TostMessage } from "../../../utils";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import Dividers from "../../../common/divider";
import { Video } from "react-feather";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import ItemsDragAndDrops from "../../../common/ItemsDragAndDrops";
import { maxLengthCheck, thubnailImage } from "../../../../utils/helper";
import VideosContext from "../../../context/VideosContext/VideosContext";
import {
  deleteVideosItems,
  sort_order_videos_items,
} from "../../../apis/VideosApi/VideosApi";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";

const VideosPopup = (props) => {
  const {
    handleChangeStatus,
    getData,
    handleEdit,
    emptyData,
    simpleValidator,
    webMasterDetail,
  } = props;
  const {
    apiData,
    setApiData,
    fromData,
    setFormData,
    setFilter,
    filter,
    isEdit,
  } = useContext(VideosContext);
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * drag and drop
   * @param {drag data} result
   * @returns
   */
  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;
    const items = Array.from(apiData.data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setApiData({ ...apiData, data: items });
    try {
      let itemOrder = [];
      for (const item in items) {
        itemOrder.push({ videoItemId: items[item].Id, order: +item + 1 });
      }
      const { data } = await sort_order_videos_items(itemOrder);
      if (data.status === 200) {
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * delete video items
   * @param {id} videoItemId
   */
  const handleDelete = async (videoItemId) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await deleteVideosItems(videoItemId);
        if (data.status === 200) {
          getData();
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * create new videos items
   */
  const hanldenewCreate = () => {
    emptyData();
  };

  /**
   * input value change
   * @param {event} e
   */
  const InputValueChange = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="6">
            <h6>{`${apiData?.data?.length} Videos`}</h6>
            <p className="pt-2">You can add upto 25 Videos</p>
            <Row>
              <Col md="6">
                <Input
                  className="form-control mt-3 "
                  name="search"
                  type="text"
                  placeholder="search"
                  style={{ borderRadius: "0", color: "black" }}
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                />
              </Col>
              {webMasterDetail?.webCategory?.name === SinglePage?.WebCategory &&
                apiData?.data?.length !== webMasterDetail?.itemLimit && (
                  <Col
                    md="3"
                    className="d-flex justify-content-center  align-items-center "
                  >
                    {" "}
                    <Button
                      color="primary"
                      className="rounded-0 mt-3"
                      onClick={() => hanldenewCreate()}
                    >
                      Add New
                    </Button>
                  </Col>
                )}
            </Row>
            {apiData.isLoading ? (
              <div className="mt-4">
                <h6>Loading ...</h6>
              </div>
            ) : (
              <div className="mt-4">
                <ItemsDragAndDrops
                  handleOnDragEnd={handleOnDragEnd}
                  characters={apiData?.data}
                  handleChangeStatus={handleChangeStatus}
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                />
              </div>
            )}
          </Col>
          {(webMasterDetail?.webCategory?.name === SinglePage.WebCategory &&
            apiData?.data?.length === webMasterDetail?.itemLimit &&
            isEdit) ||
          (webMasterDetail?.webCategory?.name === SinglePage.WebCategory &&
            apiData?.data?.length !== webMasterDetail?.itemLimit) ? (
            <Col sm="12" xl="6">
              <div className="mt-4">
                <h6>{isEdit ? "Edit" : "Add New"}</h6>
                <Row className="mt-4">
                  <Col md="12 mb-1">
                    <Label htmlFor="validationCustom03">{"Title *"}</Label>
                    <Input
                      className="form-control"
                      name="title"
                      type="text"
                      placeholder="Title *"
                      value={fromData.title}
                      style={{ borderRadius: "0", color: "black" }}
                      min={0}
                      maxLength={50}
                      onInput={maxLengthCheck}
                      onChange={(e) => InputValueChange(e)}
                    />
                    <div className="text-end mt-3 ">
                      <span>{fromData.title.length} / 50</span>
                    </div>
                  </Col>
                  {/* <Col md="12 mb-3">
                  <Label className="d-block" for="edo-ani">
                   
                    <Switch
                      defaultChecked
                      inputProps={{
                        "aria-label": "controlled",
                      }}
                      color="primary"
                      name="rdo-ani"
                      id="edo-ani"
                      size="medium"
                    />
                    {"Is Youtube URL"}
                  </Label>
                </Col> */}
                  <Col md="12 mb-3">
                    <Label htmlFor="validationCustom03">{"URL *"}</Label>
                    <Input
                      className="form-control"
                      name="url"
                      type="text"
                      placeholder="Enter URL *"
                      style={{ borderRadius: "0", color: "black" }}
                      value={fromData.url}
                      onChange={(e) => InputValueChange(e)}
                    />
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "url",
                        fromData.url,
                        "required"
                      )}
                    </p>
                  </Col>
                </Row>
                <Dividers divide="THUMBNAIL" />
                <Row>
                  {/* <Col md="12 mb-1"> */}
                  <div className="aj-video-preview-container align-items-center  d-flex justify-content-center bg-grey-3">
                    {fromData.url && (
                      <div
                        style={{ width: "100%", height: "100%" }}
                        q-img
                        role="img"
                      >
                        <div
                          style={{
                            position: "absolute",
                            top: "0",
                            right: "0",
                            bottom: "0",
                            left: "0",
                            borderRadius: "inherit",
                          }}
                        >
                          <img
                            src={`https://img.youtube.com/vi/${thubnailImage(
                              fromData.url
                            )}/hqdefault.jpg`}
                            alt=""
                            style={{
                              objectFit: "cover",
                              objectPosition: "50% 50%",
                              opacity: "1",
                              transition: "opacity 0.28s ease-in",
                              width: "100%",
                              height: "100%",
                              borderStyle: "none",
                              borderRadius: "inherit",
                            }}
                          />
                        </div>
                      </div>
                    )}
                    <Video style={{ fontSize: "4em" }} />
                  </div>
                  {/* </Col> */}
                </Row>
              </div>
            </Col>
          ) : (
            ""
          )}
        </Row>
        <ProductImageUploadPopup />
      </Container>
    </>
  );
};

export default VideosPopup;
