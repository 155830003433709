import React, { useState } from "react";
import SliderContext from "./SliderContext";

const SliderState = (props) => {
  const [apiData, setApiData] = useState({ data: [], isLoading: false });

  const [fromData, setFormData] = useState({
    title: "",
    description: "",
    image: "",
    webId: localStorage.getItem("webId"),
  });
  const [id, setId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [filter, setFilter] = useState("");
  const [settingFormData, setSettingFormData] = useState({
    ".top-slider .slider-content": {
      "max-width": "500px",
    },
    ".top-slider .slider-content .slide-title": {
      color: "#fff",
      "font-size": "40px",
      "font-weight": "600",
      "margin-bottom": "20px",
      "line-height": "1.2",
    },
    ".top-slider .slider-content .slide-desq": {
      color: "#fff",
      "font-size": "40px",
      "font-weight": "600",
      "margin-bottom": "20px",
      "line-height": "1.5",
    },
    ".top-slider .slider-content .btn": {
      "background-color": "#ff69b4",
      border: "2px solid #ff69b4",
      borderPx: "2px",
      borderThik: "solid",
      borderColor: "#ff69b4",
      color: "#fff",
      "font-size": "40px",
      "font-weight": "600",
    },
  });
  return (
    <SliderContext.Provider
      value={{
        apiData,
        setApiData,
        fromData,
        setFormData,
        id,
        setId,
        isEdit,
        setIsEdit,
        filter,
        setFilter,
        settingFormData,
        setSettingFormData,
      }}
    >
      {props.children}
    </SliderContext.Provider>
  );
};

export default SliderState;
