import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Zoom, toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import { TostMessage } from "../../components ByteCard/utils";
import { DELETE_CONFIRM } from "../../components ByteCard/constant";
import { FILE_TYPE, SORTING_ORDER } from "../../constant";
import TopLoadingBar from "../../utils/topLoadingBar";
import Breadcrumbs from "../../layout/breadcrumb";
import {
  Add_PlanMaster_List,
  Delete_PlanMaster_List,
  Edit_PlanMaster_List,
  PlanMaster_Enable_Desable,
  PlanMaster_List,
} from "../../api/PlanMaster Api/PlanMasterApi";
import PlanMasterContext from "../../context/PlanMasterContext/PlanMasterContext";
import OrgPlanAddPopup from "./OrgPlanAddPopup";
import OrgPlanItem from "./OrgPlanItem";
import OrgPlanContext from "../../context/OrgPlanContext/OrgPlanContext";
import {
  Add_OrgPlan_List,
  Delete_OrgPlan_List,
  Edit_OrgPlan_List,
  OrgPlan_Enable_Desable,
  OrgPlan_List,
} from "../../api/OrgPlanApi/OrgPlanApi";

const OrgPlan = () => {
  const {
    setFormValue,
    formData,
    pageSize,
    sortOrder,
    setSortOrder,
    page,
    sortBy,
    setSortBy,
    setPageLimit,
    setRecord,
    isSwitchOn,
    setIsSwitchOn,
    addModel,
    setFormData,
    setAddModel,
    isEdit,
    setIsEdit,
    id,
    setId,
    progress,
    setProgress,
  } = useContext(OrgPlanContext);
  const { setLoaderShow } = useContext(LoaderContext);
  const { tabelAnimation, setTableAnimation } = useContext(LoaderContext);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();

  const getOrgPlanList = async (desabledStatusLoader) => {
    if (
      sortOrder === "ASC" ||
      sortOrder === "DSC" ||
      desabledStatusLoader === false
    ) {
    } else {
      setTableAnimation(!tabelAnimation);
    }
    try {
      let paramObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
      };
      const { data } = await OrgPlan_List(paramObj);

      if (data.status === 200) {
        setFormValue(data.payload.data);
        setPageLimit(data.payload.pageLimit);
        setRecord(data.pager.totalRecords);

        setTableAnimation(false);
      } else {
      }
    } catch (error) {
      setTableAnimation(false);
    }
  };

  useEffect(() => {
    getOrgPlanList();
  }, [page, pageSize, sortBy, sortOrder]);

  /**
   * Enable and Disable Org Plan
   */
  const switch_onChange_handle = async (planId, isActive) => {
    let formData = {
      planId: planId,
      isActive: isActive ? false : true,
      // type: TYPES.LANDING_PAGE,
    };
    try {
      const { data } = await OrgPlan_Enable_Desable(formData, planId);
      if (data.status === 200) {
        await setIsSwitchOn(!isSwitchOn);
        const desabledStatusLoader = false;
        await getOrgPlanList(desabledStatusLoader);
        isActive
          ? toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            })
          : toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            });
      } else {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * Sorting Org Plan Table
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder(SORTING_ORDER.ASC);
    }
    if (sortOrder === SORTING_ORDER.ASC) {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder(SORTING_ORDER.DSC);
    }
    if (sortOrder === SORTING_ORDER.DSC) {
      setSortBy();
      setSortOrder("");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        if (isEdit && id >= -1) {
          const allItemIds = formData?.itemIds.map((ele) => {
            return {
              itemId: ele.value,
            };
          });
          const systemIs = formData?.isSystem == true ? 1 : 0;
          let addFromData = {
            ...formData,
            isSystem: systemIs,
            itemIds: allItemIds,
          };
          const { data } = await Edit_OrgPlan_List(addFromData, id);
          if (data.status === 200) {
            getOrgPlanList();
            emptyData();
            TostMessage({ message: data, type: "success" });
          }
        } else {
          const allItemIds = formData?.itemIds.map((ele) => {
            return {
              itemId: ele.value,
            };
          });
          const systemIs = formData?.isSystem == true ? 1 : 0;
          let addFromData = {
            ...formData,
            isSystem: systemIs,
            itemIds: allItemIds,
          };
          const { data } = await Add_OrgPlan_List(addFromData);
          if (data.status === 200) {
            getOrgPlanList();
            emptyData();
            TostMessage({ message: data, type: "success" });
          }
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  /**
   * delete Org Plan
   * @param {id} planId
   */
  const handleDelete = async (planId) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await Delete_OrgPlan_List(planId);
        if (data.status === 200) {
          getOrgPlanList();
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const emptyData = () => {
    setFormData({
      templateName: "",
      code: "",
      itemIds: [],
      isSystem: false,
    });
    setAddModel(false);
    setIsEdit(false);
    setId("");
  };
  return (
    <Fragment>
      <Breadcrumbs parent="Org Plan" />
      <TopLoadingBar progress={progress} setProgress={setProgress} />
      {addModel && (
        <OrgPlanAddPopup
          handleSubmit={handleSubmit}
          simpleValidator={simpleValidator}
          emptyData={emptyData}
        />
      )}

      <OrgPlanItem
        sorting={sorting}
        switch_onChange_handle={switch_onChange_handle}
        handleDelete={handleDelete}
      />
    </Fragment>
  );
};

export default OrgPlan;
