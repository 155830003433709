import React, { useState } from "react";
import VideosContext from "./VideosContext";
import { randomStr } from "../../../utils/helper";

const VideosState = (props) => {
  const [apiData, setApiData] = useState({ data: [], isLoading: false });
  const [apiDataVideos, setApiDataVideos] = useState({
    isLoading: false,
    data: [],
  });
  const [skill, setSkill] = useState([
    { id: randomStr(2), skill: "", parentageOfSkill: "" },
  ]);
  const [collId, setCollId] = useState();
  const [fromData, setFormData] = useState({
    title: "",
    url: "",
    isTitleShow: true,
    videoId: "",
    webId: localStorage.getItem("webId"),
  });
  const [isBackgroundColor, setIsBackgroundColor] = useState(false);
  const [parentId, setParentId] = useState("");

  const [formDataVideos, setFormDataVideos] = useState({
    title: "",
    slugUrl: "",
    description: "",
    isTitleShow: true,
    isYoutube: true,
    isAlbum: false,
    webId: localStorage.getItem("webId"),
    style: {
      ".video-section": {
        padding: "100px 0",
        "background-color": "rgb(255 255 255 / 100)",
      },
      ".video-section.bg-image": {
        "background-image": "",
      },
      ".video-section.bg-image::before": {
        "background-color": "",
      },
      ".video-section .section-title-area": {
        "margin-bottom": "50px",
      },
      ".video-section .section-title-area .section-subtitle": {
        "font-size": "16px",
        "font-weight": "600",
        color: "rgb(255 73 124 / 100)",
        "background-color": "rgb(255 73 124 / 0.2)",
        padding: "5px 20px",
        p1: "5px",
        p2: "20px",
        "border-radius": "15px",
        "margin-bottom": "15px",
      },
      ".video-section .section-title-area .section-title": {
        "font-size": "44px",
        "font-weight": "600",
        color: "rgb(0 0 0 / 100)",
      },
      ".video-section .gallery-catlist": {
        "text-align": "center",
        "margin-bottom": "30px",
      },
      ".video-section .gallery-catlist .btn": {
        border: "2px solid rgb(255 73 124 / 100)",
        color: "rgb(255 73 124 / 100)",
        padding: "6px 20px",
        margin: "5px",
        "box-shadow": "none",
      },
      ".video-section .gallery-catlist .active": {
        "background-color": "rgb(255 73 124 / 100)",
        color: "rgb(255 255 255 / 100)",
      },
      ".video-section .video-list .box": {
        "margin-bottom": "20px",
        // outline: "none",
        outline: "0px solid rgb(255 255 255 / 100)",
        outPx: "0px",
        outThik: "solid",
        outColor: "rgb(255 255 255 / 100)",
        "background-color": "rgb(255 255 255 / 100)",
        "box-shadow": "0 5px 10px 0 rgb(7 28 31 / 0.039)",
        bs1: "0px",
        bs2: "5px",
        bs3: "10px",
        bs4: "0px",
        shadowColor: "rgb(7 28 31 / 0.039)",
      },
      ".video-section .video-list .video-img:before": {
        color: "rgb(255 255 255 / 100)",
        "font-size": "30px",
      },
      ".video-section .video-list .title-text": {
        "background-color": "rgb(255 73 124 / 100)",
        "font-size": "18px",
        "font-weight": "600",
        color: "rgb(255 255 255 / 100)",
        "text-align": "center",
        padding: "15px",
      },
      ".video-section .more-btn": {
        color: "rgb(255 255 255 / 100)",
        "font-size": "16px",
        "font-weight": "normal",
        "border-radius": "15px",
        padding: "5px 20px",
        "background-color": "rgb(255 73 124 / 100)",
      },
      ".video-section .more-btn:hover": {
        "background-color": "rgb(255 73 124 / 100)",
        color: "rgb(255 255 255 / 100)",
      },
      ".pagination-positions": {
        "justify-content": "center",
      },
      ".pagination .page-link": {
        color: "rgb(255 73 124 / 100)",
      },
      ".pagination .page-item.active .page-link": {
        "background-color": "rgb(255 73 124 / 100)",
        "border-color": "rgb(255 73 124 / 100)",
      },
    },
  });
  const [boxSection, setBoxSection] = useState(false);
  const [isVideoParent, setIsVideoParent] = useState(false);
  const [settingFormData, setSettingFormData] = useState({
    ".video-section": {
      padding: "100px 0",
      "background-color": "rgb(255 255 255 / 100)",
    },
    ".video-section.bg-image": {
      "background-image": "",
    },
    ".video-section.bg-image::before": {
      "background-color": "",
    },
    ".video-section .section-title-area": {
      "margin-bottom": "50px",
    },
    ".video-section .section-title-area .section-subtitle": {
      "font-size": "16px",
      "font-weight": "600",
      color: "rgb(255 73 124 / 100)",
      "background-color": "rgb(255 73 124 / 0.2)",
      padding: "5px 20px",
      p1: "5px",
      p2: "20px",
      "border-radius": "15px",
      "margin-bottom": "15px",
    },
    ".video-section .section-title-area .section-title": {
      "font-size": "44px",
      "font-weight": "600",
      color: "rgb(0 0 0 / 100)",
    },
    ".video-section .gallery-catlist": {
      "text-align": "center",
      "margin-bottom": "30px",
    },
    ".video-section .gallery-catlist .btn": {
      border: "2px solid rgb(255 73 124 / 100)",
      color: "rgb(255 73 124 / 100)",
      padding: "6px 20px",
      margin: "5px",
      "box-shadow": "none",
    },
    ".video-section .gallery-catlist .active": {
      "background-color": "rgb(255 73 124 / 100)",
      color: "rgb(255 255 255 / 100)",
    },
    ".video-section .video-list .box": {
      "margin-bottom": "20px",
      // outline: "none",
      outline: "0px solid rgb(255 255 255 / 100)",
      outPx: "0px",
      outThik: "solid",
      outColor: "rgb(255 255 255 / 100)",
      "background-color": "rgb(255 255 255 / 100)",
      "box-shadow": "0 5px 10px 0 rgb(7 28 31 / 0.039)",
      bs1: "0px",
      bs2: "5px",
      bs3: "10px",
      bs4: "0px",
      shadowColor: "rgb(7 28 31 / 0.039)",
    },
    ".video-section .video-list .video-img:before": {
      color: "rgb(255 255 255 / 100)",
      "font-size": "30px",
    },
    ".video-section .video-list .title-text": {
      "background-color": "rgb(255 73 124 / 100)",
      "font-size": "18px",
      "font-weight": "600",
      color: "rgb(255 255 255 / 100)",
      "text-align": "center",
      padding: "15px",
    },
    ".video-section .more-btn": {
      color: "rgb(255 255 255 / 100)",
      "font-size": "16px",
      "font-weight": "normal",
      "border-radius": "15px",
      padding: "5px 20px",
      "background-color": "rgb(255 73 124 / 100)",
    },
    ".video-section .more-btn:hover": {
      "background-color": "rgb(255 73 124 / 100)",
      color: "rgb(255 255 255 / 100)",
    },
    ".pagination-positions": {
      "justify-content": "center",
    },
    ".pagination .page-link": {
      color: "rgb(255 73 124 / 100)",
    },
    ".pagination .page-item.active .page-link": {
      "background-color": "rgb(255 73 124 / 100)",
      "border-color": "rgb(255 73 124 / 100)",
    },
  });
  const [backImageToggle, setBackImageToggle] = useState(false);
  const [id, setId] = useState("");
  const [videoId, setVideoId] = useState("");
  const [videoItemId, setVideoItemId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [filter, setFilter] = useState("");
  const [isChildForFormSubmit, setIsChildForFormSubmit] = useState(false);
  const [ids, setIds] = useState({});
  const [theme, setTheme] = useState(false);
  const [imgShow, setImgShow] = useState(false);
  const [sectionPageName, setSectionPageName] = useState("");
  return (
    <VideosContext.Provider
      value={{
        apiData,
        setApiData,
        apiDataVideos,
        setApiDataVideos,
        fromData,
        setFormData,
        formDataVideos,
        setFormDataVideos,
        id,
        setId,
        isEdit,
        setIsEdit,
        filter,
        setFilter,
        skill,
        videoId,
        setVideoId,
        videoItemId,
        setVideoItemId,
        setSkill,
        collId,
        setCollId,
        parentId,
        setParentId,
        settingFormData,
        setSettingFormData,
        boxSection,
        setBoxSection,
        backImageToggle,
        setBackImageToggle,
        isBackgroundColor,
        setIsBackgroundColor,
        isVideoParent,
        setIsVideoParent,
        isChildForFormSubmit,
        setIsChildForFormSubmit,
        ids,
        setIds,
        theme,
        setTheme,
        imgShow,
        setImgShow,
        sectionPageName,
        setSectionPageName,
      }}
    >
      {props.children}
    </VideosContext.Provider>
  );
};

export default VideosState;
