import React, { useContext, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Card, CardHeader, Col, Container, Row, Table } from "reactstrap";
import {
  ContactEmergency,
  PaymentSharp,
  QrCode,
  Reviews,
  ShopTwo,
} from "@mui/icons-material";
import {
  Save,
  Facebook,
  Twitter,
  Instagram,
  Linkedin,
  Youtube,
  User,
  Download,
  Share,
  Video,
  X,
  Sliders,
  MessageCircle,
} from "react-feather";
import { IconButton, Tooltip } from "@material-ui/core";
import {
  Add,
  Apple,
  Call,
  Email,
  LocationOnTwoTone,
  Payment,
  PhotoAlbum,
  QuestionAnswer,
  Settings,
  Telegram,
  Web,
  WhatsApp,
} from "@material-ui/icons";
import PinterestIcon from "@mui/icons-material/Pinterest";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DialpadIcon from "@mui/icons-material/Dialpad";
import PaymentIcon from "@mui/icons-material/Payment";
import MenuIcon from "@mui/icons-material/Menu";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Switch from "react-js-switch";
import PageItemContext from "../../context/PageItemContext/PageItmContext";
import {
  baseUrl,
  MOMENT_SETTING,
  PAGE_ITEM_TYPE,
  TITLES,
} from "../../constant";
import { HyperLink, Truncate } from "../../utils/helper";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import { TabelAnimation } from "../../Spinner";
import { PagePriviewList } from "../../api/Organization Api/OrganizationApi";
import PageItemViewPopup from "./PageItemView";
import { headerStyle } from "../../utils/MuiTabelStyle";
import { useNavigate, useParams } from "react-router";
import { classes } from "../../data/layouts";
import moment from "moment";

const PageItemContent = (props) => {
  const {
    openModel,
    handleDragEnd,
    switch_onChange_handle,
    EditHandle,
    DeleteHandle,
  } = props;

  const {
    formvalue,
    page,
    setPageItemViewPop,
    setPageItemData,
    setPageId,
    pageId,
    setCategories,
    setChartData,
    setChartTitle,
  } = useContext(PageItemContext);

  let { hwId } = useParams();
  const history = useNavigate();

  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  const { tabelAnimation } = useContext(LoaderContext);

  const [qpiId, setQpiId] = useState("");
  const [startDate, setStartData] = useState("");
  const [endDate, setEndData] = useState("");

  const handlePreview = async (pageId, qpiId, start, end, value, title) => {
    localStorage.setItem("title", title);
    setChartTitle(title);
    setPageId(pageId);
    setQpiId(qpiId);
    setCategories([]);
    setChartData([]);
    try {
      let paramObj = {
        pageId,
        qpiId,
        sortOrder: "ASC",
      };
      if (start !== undefined || end !== undefined) {
        if (start !== undefined) {
          if (start) {
            paramObj["startDate"] = start;
            paramObj["showAll"] = true;
          }
        }
        if (end !== undefined) {
          if (end) {
            paramObj["endDate"] = end;
            paramObj["showAll"] = true;
          }
        }
      }
      if (value === "chart") {
        paramObj["sortOrder"] = "ASC";
        paramObj["startDate"] = startDate;
        paramObj["endDate"] = endDate;
        if (startDate && endDate) {
          paramObj["showAll"] = true;
        }
      } else if (value === "list") {
        paramObj["sortOrder"] = "DESC";
        paramObj["startDate"] = startDate;
        paramObj["endDate"] = endDate;
        if (startDate && endDate) {
          paramObj["showAll"] = true;
        }
      }
      const { data } = await PagePriviewList(paramObj);

      if (data.status === 200) {
        setPageItemViewPop(true);
        setPageItemData(data.payload.data);
        for (let i of data.payload.data) {
          setCategories((oldArray) => [
            ...oldArray,
            moment.utc(i.date).format(MOMENT_SETTING.FORMATE),
          ]);
          setChartData((oldArray) => [...oldArray, i.views]);
        }
        if (!data.payload.data.length) {
          setCategories([]);
          setChartData([]);
        }
      } else {
      }
    } catch (error) {}
  };

  const Privius = () => {
    localStorage.removeItem("pageLimit");
    history(`/${hwId}/PersonalPage/PersonalPage/${layout}`);
  };

  return (
    <Container fluid={true}>
      <Row>
        <Col sm="12">
          <Card>
            <CardHeader
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h5 style={headerStyle}>
                {TITLES.PAGE_ITEMS}{" "}
                {(page !== null && page !== "" && page) ||
                (localStorage.getItem("pageForPageItem") !== null &&
                  localStorage.getItem("pageForPageItem") !== "" &&
                  localStorage.getItem("pageForPageItem"))
                  ? `(${Truncate(
                      page || localStorage.getItem("pageForPageItem"),
                      10
                    )})`
                  : ""}
              </h5>
              <div style={{ display: "flex" }}>
                <div style={{ paddingRight: "20px" }}>
                  <Tooltip title="Close">
                    <div
                      className="btn btn-light"
                      style={{
                        borderRadius: "100%",
                        width: "50px",
                        height: "55px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => Privius()}
                    >
                      <X style={{ color: "#968989" }} />
                    </div>
                  </Tooltip>
                </div>
                <div>
                  <Tooltip title="Add">
                    <div
                      className="btn btn-success"
                      style={{
                        borderRadius: "100%",
                        width: "50px",
                        height: "55px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => openModel()}
                    >
                      <Add />
                    </div>
                  </Tooltip>
                </div>
              </div>
            </CardHeader>
            <div className="table-responsive">
              <DragDropContext onDragEnd={(results) => handleDragEnd(results)}>
                <Table>
                  <thead>
                    <tr>
                      <th scope="col">{"Logo"}</th>
                      <th scope="col">{"Name"}</th>
                      <th scope="col">{"Value"}</th>
                      <th scope="col">{"View"}</th>
                      <th scope="col">{"Status"}</th>
                      <th scope="col">{"Edit"}</th>
                      <th scope="col">{"Actions"}</th>
                    </tr>
                  </thead>
                  <Droppable droppableId="tbody">
                    {(provided) => (
                      <tbody
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {tabelAnimation === true ? (
                          <TabelAnimation />
                        ) : (
                          formvalue?.map((elem, index) => (
                            <Draggable
                              draggableId={elem.label + elem.qpiId}
                              index={index}
                              key={elem.qpiId}
                            >
                              {(provided) => (
                                <tr
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  key={elem.label + elem.qpiId}
                                >
                                  <td>
                                    {elem.itemMaser?.type ===
                                    PAGE_ITEM_TYPE.SLIDER ? (
                                      <Sliders style={{ color: "#25D366" }} />
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.WHATSAPP ? (
                                      <WhatsApp style={{ color: "#25D366" }} />
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.CALL ? (
                                      <Call style={{ color: "#009688" }} />
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.SAVE_CONTACT ? (
                                      <Save style={{ color: "#15a5ee" }} />
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.DIGITALCARD ? (
                                      <PaymentSharp />
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.FACEBOOK ? (
                                      <Facebook style={{ color: "#3b5998" }} />
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.TWITTER ? (
                                      <Twitter style={{ color: "#1DA1F2" }} />
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.INSTAGRAM ? (
                                      <Instagram style={{ color: "#bc2a8d" }} />
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.LINKEDIN ? (
                                      <Linkedin style={{ color: "#0A66C2" }} />
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.YOUTUBE ? (
                                      <Youtube style={{ color: "#FF0000" }} />
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.QR_CODE ? (
                                      <QrCode style={{ color: "blue" }} />
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.LOCATION ? (
                                      <LocationOnTwoTone
                                        style={{ color: "blue" }}
                                      />
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.LOCATION_IFRAME ? (
                                      <LocationOnTwoTone
                                        style={{ color: "blue" }}
                                      />
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.EMAIL ? (
                                      <Email style={{ color: "#15a5ee" }} />
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.WEBSITE ? (
                                      <Web style={{ color: "#00405d" }} />
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.WEBSITE_SOCIAL ? (
                                      <Web style={{ color: "#00405d" }} />
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.WEBSITE_SOCIAL ? (
                                      <Web style={{ color: "#00405d" }} />
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.PINTEREST ? (
                                      <PinterestIcon style={{ color: "red" }} />
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.INDIAMART ? (
                                      <ShoppingCartIcon
                                        style={{ color: "gray" }}
                                      />
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.JUSTDIAL ? (
                                      <DialpadIcon style={{ color: "gray" }} />
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.UPI ? (
                                      <PaymentIcon style={{ color: "gray" }} />
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.MENU ? (
                                      <MenuIcon style={{ color: "black" }} />
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.REVIEW ? (
                                      <Reviews style={{ color: "#c220ff" }} />
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.PAYMENT ? (
                                      <Payment style={{ color: "green" }} />
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.ABOUT_ME ? (
                                      <User style={{ color: "#000000" }} />
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.GALLAYRY ? (
                                      <PhotoAlbum
                                        style={{ color: "#DA483B" }}
                                      />
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.SAVE_CONTACT ? (
                                      <ContactEmergency
                                        style={{ color: "#DA483B" }}
                                      />
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.DOWNLOAD ? (
                                      <Download />
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.SHARE ? (
                                      <Share style={{ color: "#00e461" }} />
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.INQUIRY ? (
                                      <QuestionAnswer
                                        style={{ color: "tomato" }}
                                      />
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.ANDROID ? (
                                      <ShopTwo style={{ color: "#a4c639" }} />
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.APPLE ? (
                                      <Apple style={{ color: "#5ac8fa" }} />
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.VIDEO ? (
                                      <Video style={{ color: "#5ac8fa" }} />
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.CUSTOM ? (
                                      <Settings style={{ color: "#5ac8fa" }} />
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.TELEGRAM ? (
                                      <Telegram style={{ color: "#5ac8fa" }} />
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.WHATSAPP_SOCIAL ? (
                                      <WhatsApp style={{ color: "#25D366" }} />
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.WECHAT ? (
                                      <MessageCircle
                                        style={{ color: "#25D366" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>{elem.label}</td>
                                  <td>
                                    {elem.itemMaser?.type ===
                                    PAGE_ITEM_TYPE.LOCATION ? (
                                      <a
                                        href={elem.value}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {"Location"}
                                      </a>
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.VIDEO ? (
                                      <>
                                        {elem.value &&
                                          JSON.parse(elem.value).map((elem) => (
                                            <a
                                              href={elem.url}
                                              target="_blank"
                                              rel="noreferrer"
                                              style={{ paddingRight: "12px" }}
                                            >
                                              <>
                                                <span> </span>
                                              </>
                                              {elem.name},
                                              <>
                                                <span> </span>
                                              </>
                                            </a>
                                          ))}
                                      </>
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.SAVE_CONTACT ? (
                                      "Save Contact"
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.GALLAYRY ? (
                                      <>
                                        {elem.value &&
                                          JSON.parse(elem.value).map((elem) => (
                                            <a
                                              href={baseUrl + elem.url}
                                              target="_blank"
                                              rel="noreferrer"
                                              style={{ paddingRight: "12px" }}
                                            >
                                              <>
                                                <span> </span>
                                              </>
                                              {elem.name},
                                              <>
                                                <span> </span>
                                              </>
                                            </a>
                                          ))}
                                      </>
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.LOCATION_IFRAME ? (
                                      <>Iframe</>
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.SLIDER ? (
                                      <>
                                        {elem.value &&
                                          JSON.parse(elem.value).map(
                                            (elem, i) => (
                                              <a
                                                href={baseUrl + elem.url}
                                                target="_blank"
                                                rel="noreferrer"
                                                style={{ paddingRight: "12px" }}
                                              >
                                                <>
                                                  <span> </span>
                                                </>
                                                {`img-${i + 1}`},
                                                <>
                                                  <span> </span>
                                                </>
                                              </a>
                                            )
                                          )}
                                      </>
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.DOWNLOAD ? (
                                      <a
                                        href={elem.value}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {"View"}
                                      </a>
                                    ) : elem.itemMaser?.type ===
                                        PAGE_ITEM_TYPE.APPLE ||
                                      elem.itemMaser?.type ===
                                        PAGE_ITEM_TYPE.ANDROID ||
                                      elem.itemMaser?.type ===
                                        PAGE_ITEM_TYPE.YOUTUBE ||
                                      elem.itemMaser?.type ===
                                        PAGE_ITEM_TYPE.LINKEDIN ||
                                      elem.itemMaser?.type ===
                                        PAGE_ITEM_TYPE.INSTAGRAM ||
                                      elem.itemMaser?.type ===
                                        PAGE_ITEM_TYPE.TWITTER ||
                                      elem.itemMaser?.type ===
                                        PAGE_ITEM_TYPE.FACEBOOK ||
                                      elem.itemMaser?.type ===
                                        PAGE_ITEM_TYPE.WEBSITE ? (
                                      <a
                                        href={
                                          elem?.value
                                            ? HyperLink(elem.value)
                                            : ""
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {elem.itemMaser?.type ===
                                        PAGE_ITEM_TYPE.APPLE
                                          ? "IOS"
                                          : elem.itemMaser?.type ===
                                            PAGE_ITEM_TYPE.ANDROID
                                          ? "Android"
                                          : elem.itemMaser?.type ===
                                            PAGE_ITEM_TYPE.YOUTUBE
                                          ? "Youtube"
                                          : elem.itemMaser?.type ===
                                            PAGE_ITEM_TYPE.LINKEDIN
                                          ? "Linkedin"
                                          : elem.itemMaser?.type ===
                                            PAGE_ITEM_TYPE.INSTAGRAM
                                          ? "Instagram"
                                          : elem.itemMaser?.type ===
                                            PAGE_ITEM_TYPE.TWITTER
                                          ? "Twitter"
                                          : elem.itemMaser?.type ===
                                            PAGE_ITEM_TYPE.FACEBOOK
                                          ? "Facebook"
                                          : elem.itemMaser?.type ===
                                            PAGE_ITEM_TYPE.WEBSITE
                                          ? "Website"
                                          : elem.value}
                                      </a>
                                    ) : elem.itemMaser?.type ===
                                        PAGE_ITEM_TYPE.ABOUT_ME ||
                                      elem.itemMaser?.type ===
                                        PAGE_ITEM_TYPE.POLICY ? (
                                      <a
                                        href={elem.value}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {"View"}
                                      </a>
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.CUSTOM ? (
                                      <a
                                        href={
                                          elem?.value
                                            ? HyperLink(elem.value)
                                            : ""
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {"Custome URL"}
                                      </a>
                                    ) : elem.itemMaser?.type ===
                                      PAGE_ITEM_TYPE.CALL ? (
                                      <a
                                        href={`tel:+91${elem.value}`}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {elem.value}
                                      </a>
                                    ) : (
                                      elem.value
                                    )}
                                  </td>
                                  <td>
                                    {elem.itemMaser?.type ===
                                    PAGE_ITEM_TYPE.SLIDER ? (
                                      <p
                                        style={{
                                          color: "blue",
                                          cursor: "pointer",
                                          backgroundColor: "aliceblue",
                                          borderRadius: "12px",
                                          paddingLeft: "20px",
                                          paddingRight: "20px",
                                        }}
                                      >
                                        -
                                      </p>
                                    ) : (
                                      <p
                                        style={{
                                          color: "blue",
                                          cursor: "pointer",
                                          backgroundColor: "aliceblue",
                                          borderRadius: "12px",
                                          paddingLeft: "20px",
                                          paddingRight: "20px",
                                        }}
                                        onClick={() =>
                                          handlePreview(
                                            elem.pageId,
                                            elem.qpiId,
                                            null,
                                            null,
                                            null,
                                            elem.label
                                          )
                                        }
                                      >
                                        {elem.views ? elem.views : "0"}
                                      </p>
                                    )}
                                  </td>
                                  <td>
                                    <div className="d-flex justify-content-center  align-items-center">
                                      {elem.itemMaser?.type === "menu" ? (
                                        <Tooltip title="Add Menu">
                                          <IconButton
                                            aria-label="edit"
                                            style={{ color: "green" }}
                                            onClick={() =>
                                              history(
                                                `/${hwId}/MenuMaster/${elem.qpiId}/${layout}`
                                              )
                                            }
                                          >
                                            <AddIcon />
                                          </IconButton>
                                        </Tooltip>
                                      ) : (
                                        ""
                                      )}
                                      <Switch
                                        value={elem.isActive ? true : false}
                                        onChange={() =>
                                          switch_onChange_handle(
                                            elem.qpiId,
                                            elem.isActive
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <Tooltip title="Edit">
                                      <IconButton
                                        aria-label="edit"
                                        style={{ color: "green" }}
                                        onClick={() => EditHandle(elem.qpiId)}
                                      >
                                        <ModeEditIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </td>
                                  <td>
                                    <Tooltip title="Delete">
                                      <IconButton
                                        aria-label="delete"
                                        style={{ color: "red" }}
                                        onClick={() => DeleteHandle(elem.qpiId)}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </td>
                                </tr>
                              )}
                            </Draggable>
                          ))
                        )}
                        {provided.placeholder}
                      </tbody>
                    )}
                  </Droppable>
                </Table>
              </DragDropContext>
            </div>
          </Card>
        </Col>
      </Row>
      <PageItemViewPopup
        handlePreview={handlePreview}
        pageId={pageId}
        qpiId={qpiId}
        setStartData={setStartData}
        setEndData={setEndData}
      />
    </Container>
  );
};

export default PageItemContent;
