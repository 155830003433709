import React from "react";
import BackupIcon from "@mui/icons-material/Backup";
import { Card, CardHeader, Col, Row } from "reactstrap";
import { Style } from "../../constant";
import { UpdateStyleBackUp } from "../../components ByteCard/components/MultipageByteCardBuilder/apis/NavApi/NavApi";
import { TostMessage } from "../../components ByteCard/utils";

const StyleBackUp = () => {
  const styleBackUpHandler = async () => {
    try {
      const isConfirm = window.confirm(
        "Are You Sure ? Do you want to Back up Style."
      );
      if (isConfirm) {
        const formData = "";
        const { data } = await UpdateStyleBackUp(formData);
        if (data.status == 200) {
          TostMessage({ message: data, type: "success" });
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };
  return (
    <Row>
      <Col sm="12">
        <Card>
          <CardHeader
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <h3>{"Style Back Up "}</h3>
            <div
              aria-label="add"
              onClick={styleBackUpHandler}
              className="btn btn-secondary"
              style={Style}
            >
              <BackupIcon />
            </div>
          </CardHeader>
        </Card>
      </Col>
    </Row>
  );
};

export default StyleBackUp;
