import React, { useState } from "react";
import DownloadMultipageContext from "./DownloadMultipageContext";
import { randomStr } from "../../../utils/helper";

const DownloadMultipageState = (props) => {
  const [apiData, setApiData] = useState({ data: [], isLoading: false });
  const [apiDataOurTeam, setApiDataOurTeam] = useState({
    isLoading: false,
    data: [],
  });
  const [isBackgroundColor, setIsBackgroundColor] = useState(false);
  const [skill, setSkill] = useState([
    { id: randomStr(2), skill: "", parentageOfSkill: "" },
  ]);
  const [collId, setCollId] = useState();
  const [fromData, setFormData] = useState({
    title: "",
    navMapId: "",
    slugUrl: "",
    buttonText: "",
    itemLimit: "",
    isTitleShow: true,
    webId: localStorage.getItem("webId"),
    subTitle: "",
    theme: 1,
    style: {
      ".brochure-section": {
        padding: "100px 0",
        "background-color": "rgb(255 255 255 / 100)",
      },
      ".brochure-section .section-title-area": {
        "margin-bottom": "50px",
      },
      ".brochure-section .section-title-area .section-subtitle": {
        "font-size": "16px",
        "font-weight": "600",
        color: "rgb(255 73 124 / 100)",
        "background-color": "rgb(255 73 124 / 0.2)",
        padding: "5px 20px",
        "border-radius": "15px",
        "margin-bottom": "15px",
      },
      ".brochure-section .section-title-area .section-title": {
        "font-size": "44px",
        "font-weight": "600",
        color: "rgb(0 0 0 / 100)",
      },
      ".brochure-section .brochure-list .link-text": {
        "background-color": "rgb(255 73 124 / 100)",
        "font-size": "18px",
        "font-weight": "600",
        color: "rgb(255 255 255 / 100)",
        "text-align": "center",
        padding: "15px",
        "margin-bottom": "15px",
      },
      ".brochure-section .more-btn": {
        color: "rgb(255 255 255 / 100)",
        "font-size": "16px",
        "font-weight": "normal",
        "border-radius": "15px",
        padding: "5px 20px",
        "background-color": "rgb(255 73 124 / 100)",
      },
      ".brochure-section .more-btn:hover": {
        "background-color": "#df2a5d",
        color: "rgb(255 255 255 / 100)",
      },
      ".pagination-positions": {
        "justify-content": "center",
      },
      ".pagination .page-link": {
        color: "rgb(255 73 124 / 100)",
      },
      ".pagination .page-item.active .page-link": {
        "background-color": "rgb(255 73 124 / 100)",
        "border-color": "rgb(255 73 124 / 100)",
      },
    },
  });

  const [formDataChild, setFormDataChild] = useState({
    filePath: "",
    title: "",
    subTitle: "",
    isTitleShow: true,
    order: 1,
    webId: localStorage.getItem("webId"),
  });

  const [settingFormData, setSettingFormData] = useState({
    ".brochure-section": {
      padding: "100px 0",
      "background-color": "rgb(255 255 255 / 100)",
    },
    ".brochure-section .section-title-area": {
      "margin-bottom": "50px",
    },
    ".brochure-section .section-title-area .section-subtitle": {
      "font-size": "16px",
      "font-weight": "600",
      color: "rgb(255 73 124 / 100)",
      "background-color": "rgb(255 73 124 / 0.2)",
      padding: "5px 20px",
      "border-radius": "15px",
      "margin-bottom": "15px",
    },
    ".brochure-section .section-title-area .section-title": {
      "font-size": "44px",
      "font-weight": "600",
      color: "rgb(0 0 0 / 100)",
    },
    ".brochure-section .brochure-list .link-text": {
      "background-color": "rgb(255 73 124 / 100)",
      "font-size": "18px",
      "font-weight": "600",
      color: "rgb(255 255 255 / 100)",
      "text-align": "center",
      padding: "15px",
      "margin-bottom": "15px",
    },
    ".brochure-section .more-btn": {
      color: "rgb(255 255 255 / 100)",
      "font-size": "16px",
      "font-weight": "normal",
      "border-radius": "15px",
      padding: "5px 20px",
      "background-color": "rgb(255 73 124 / 100)",
    },
    ".brochure-section .more-btn:hover": {
      "background-color": "#df2a5d",
      color: "rgb(255 255 255 / 100)",
    },
    ".pagination-positions": {
      "justify-content": "center",
    },
    ".pagination .page-link": {
      color: "rgb(255 73 124 / 100)",
    },
    ".pagination .page-item.active .page-link": {
      "background-color": "rgb(255 73 124 / 100)",
      "border-color": "rgb(255 73 124 / 100)",
    },
    downloadId: "",
  });
  const [boxSection, setBoxSection] = useState(false);
  const [id, setId] = useState("");
  const [childId, setChildId] = useState("");
  const [navMapId, setNavMapId] = useState("");
  const [galleryImgId, setGalleryImgId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isParent, setIsParent] = useState(false);
  const [filter, setFilter] = useState("");
  const [theme, setTheme] = useState(false);
  const [imgShow, setImgShow] = useState(false);
  const [sectionPageName, setSectionPageName] = useState("");
  return (
    <DownloadMultipageContext.Provider
      value={{
        apiData,
        setApiData,
        apiDataOurTeam,
        setApiDataOurTeam,
        fromData,
        setFormData,
        id,
        setId,
        isEdit,
        setIsEdit,
        filter,
        setFilter,
        skill,
        galleryImgId,
        setGalleryImgId,
        setSkill,
        settingFormData,
        setSettingFormData,
        formDataChild,
        setFormDataChild,
        isParent,
        setIsParent,
        childId,
        setChildId,
        navMapId,
        setNavMapId,
        boxSection,
        setBoxSection,
        collId,
        setCollId,
        isBackgroundColor,
        setIsBackgroundColor,
        theme,
        setTheme,
        imgShow,
        setImgShow,
        sectionPageName,
        setSectionPageName,
      }}
    >
      {props.children}
    </DownloadMultipageContext.Provider>
  );
};

export default DownloadMultipageState;
