import React, { useContext, useMemo, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import { Cases, DELETE_CONFIRM, modalsName } from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import CommonPopup from "../../../Modals/commonPopup";
import LatestNewsPopup from "./LatestNewsPopup";
import EditIcon from "@mui/icons-material/Edit";
import LatestNewsParentPopup from "./LatestNewsParentPopup";
import { FILE_UPLOAD_API } from "../apis/SliderApi/SliderApi";
import {
  isCheckValue,
  isEmptyObject,
  randomStr,
  rgbColorCreate,
} from "../../../../utils/helper";
import { FILE_TYPE, ThemeNo, titleUpdateKey } from "../../../../constant";
import { IconButton } from "@material-ui/core";
import { Settings, Trash } from "react-feather";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import SimpleReactValidator from "simple-react-validator";
import SettingImageParentPopup from "./SettingImagesPerentPopup";
import LatestNewsContext from "../../../context/LatestNewsContext/LatestNewsContext";
import {
  deleteLatestNews,
  getLatestNews,
  getLatestNewsDetails,
  postLatestNews,
  putUpdateLatestNews,
  updateStatus,
} from "../apis/LatestNewsApi/LatestNewsApi";
import moment from "moment";
import DeleteSection from "../../../common/DeleteSection";
import SettingMultiWebContext from "../../../../context/SettingMultiWebContext/SettingMultiWebContext";
import {
  LatesNewsThemeOneFunction,
  LatestNewStyleOneReset,
  LatestNewsStyleOneSubFun,
} from "./LatestNewaStyleFunction";

const LatestNews = (props) => {
  const {
    pageConfigDetails,
    webMasterDetail,
    navbarData,
    getData,
    latestNewsDetail,
    contentMapId,
    ByteMiniWeb_Dashboard,
    contentDetail,
  } = props;
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );
  const { setCloneAlignModal, setAlignFormData, setCloneFormData } = useContext(
    SettingMultiWebContext
  );
  const parentValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const childValidator = useRef(new SimpleReactValidator());
  const {
    fromData,
    setFormData,
    formDataChild,
    setFormDataChild,
    id,
    setId,
    setIsEdit,
    isEdit,
    setFilter,
    apiDataOurTeam: apiDataImageParent,
    setApiDataOurTeam: setApiDataImageParent,
    setSkill,
    setGalleryImgId,
    setSettingFormData,
    settingFormData,
    isParent,
    setIsParent,
    childId,
    setChildId,
    setbackImageToggle,
    boxSection,
    setBoxSection,
    collId,
    setCollId,
    isBackgroundColor,
    setIsBackgroundColor,
    theme,
    setTheme,
    setImgShow,
    sectionPageName,
    setSectionPageName,
  } = useContext(LatestNewsContext);
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * get latest news parent list
   * @param {status} status
   */
  const getLatestNewsParentList = async ({
    parentId = null,
    isClose = false,
    filter,
  }) => {
    try {
      let paramObj = {};
      if (
        ((isParent === false && parentId && parentId !== null) ||
          (id && isClose === false)) &&
        isParent === false
      ) {
        paramObj["search"] = { parentId: parentId || id };
        setId(parentId || id);
        if (filter?.length) {
          paramObj["search"] = { ...paramObj.search, title: filter };
        }
      } else {
        paramObj["search"] = { parentId: null };
      }
      const { data } = await getLatestNews(paramObj);
      if (isCheckValue({ value: parentId })) {
        setChildId(parentId);
      }
      if (data.status === 200) {
        if (
          isParent === false &&
          ((parentId && parentId !== null) || id) &&
          isClose === false
        ) {
          setApiDataImageParent({
            ...apiDataImageParent,
            isLoading: false,
            data: data.payload.data.map((elem) => {
              return {
                id: elem?.title + elem?.latestNewsId,
                Id: elem?.latestNewsId,
                name: elem?.title,
                image: "",
                webId: elem?.webId,
                createdAt: elem?.createdAt,
                isActive: elem?.isActive,
                parentId: elem?.parentId,
              };
            }),
          });
          OpenModalCommonFunc(Cases.LATEST_NEWS, setAllPopupState);
        } else if (parentId && parentId !== null) {
          OpenModalCommonFunc(Cases.LATEST_NEWS, setAllPopupState);
          setApiDataImageParent({
            ...apiDataImageParent,
            isLoading: false,
            data: data.payload.data.map((elem) => {
              return {
                id: elem?.title + elem?.latestNewsId,
                Id: elem?.latestNewsId,
                name: elem?.title,
                image: "",
                webId: elem?.webId,
                createdAt: elem?.createdAt,
                isActive: elem?.isActive,
                parentId: elem?.parentId,
              };
            }),
          });
        } else {
          setApiDataImageParent({
            ...apiDataImageParent,
            isLoading: false,
            data: data.payload.data,
          });
        }
        if (isClose && isClose !== false) {
          setIsParent(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * latest news enable disable api call
   * @param {latestNews Id} latestNewsId
   * @param {status} isActive
   */

  const handleChangeLatestNews = async (
    latestNewsId,
    isActive,
    isChild,
    parentId
  ) => {
    try {
      let body = {
        latestNewsId,
        isActive: isActive ? false : true,
      };
      if (parentId) {
        body["parentId"] = parentId;
      }
      const { data } = await updateStatus(body);
      if (data.status === 200) {
        if (isChild) {
          getLatestNewsParentList({ packageId: parentId });
        } else {
          ByteMiniWeb_Dashboard();
        }
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * file upload
   * @param {file} file
   */
  const imageUploadImages = async (file) => {
    setLoaderShow(true);
    try {
      const Image = await imageUpload(file);
      setFormData((prev) => {
        return {
          ...prev,
          image: Image,
        };
      });
      setLoaderShow(false);
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const imageUploadSetting = async (file) => {
    setLoaderShow(true);
    try {
      const Image = await imageUpload(file);
      setSettingFormData((prev) => {
        return {
          ...prev,
          ".latest-news-section.bg-image": {
            "background-image": Image,
          },
        };
      });
      setLoaderShow(false);
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const imageUpload = async (file) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.LATEST_NEWS);
    formData.append("file", file);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        return data.payload.baseUrl + data.payload.imageUrl;
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * create and update latest news
   */

  const submitForm = async () => {
    if ((childId >= -1 && isEdit) || (id >= -1 && isEdit)) {
      setLoaderShow(true);
      try {
        let formData = {};
        if (isParent && isParent !== false) {
          formData = { ...fromData, latestNewsId: id, contentMapId: collId };
          setId("");
        } else {
          formData = {
            ...formDataChild,
            startDate: moment(formDataChild.startDate)
              .startOf("day")
              .toISOString(),
            endDate: moment(formDataChild.endDate).endOf("day").toISOString(),
            parentId: id,
            latestNewsId: childId,
            contentMapId: collId,
          };
          delete formData.order;
        }
        const { data } = await putUpdateLatestNews(formData);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            ByteMiniWeb_Dashboard();
          } else {
            getLatestNewsParentList({ parentId: id });
          }
          emptyData();
          OpenModalCommonFunc(
            Cases.LATEST_NEWS_PARENT,
            setAllPopupState,
            false
          );
          TostMessage({ message: data, type: "success" });
          setIsParent(false);
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    } else {
      try {
        setLoaderShow(true);
        let formData = {};
        if (isParent && isParent !== false) {
          formData = { ...fromData, contentMapId: collId };
        } else {
          formData = {
            ...formDataChild,
            startDate: moment(formDataChild.startDate)
              .startOf("day")
              .toISOString(),
            endDate: moment(formDataChild.endDate).endOf("day").toISOString(),
            parentId: id,
            // order: apiDataImageParent.data.length + 1,
            order: 0,
            contentMapId: collId,
          };
        }
        const { data } = await postLatestNews(formData);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            ByteMiniWeb_Dashboard();
          } else {
            getLatestNewsParentList({ parentId: id });
          }
          emptyData();
          OpenModalCommonFunc(
            Cases.LATEST_NEWS_PARENT,
            setAllPopupState,
            false
          );
          TostMessage({ message: data, type: "success" });
          setIsParent(false);
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  const handleSubmit = async (formValidationCondition) => {
    let validatorFields;
    if (formValidationCondition === "parentForm") {
      validatorFields = parentValidator.current;
    } else if (formValidationCondition === "childForm") {
      validatorFields = childValidator.current;
    }
    validatorFields.showMessages();

    if (validatorFields.allValid()) {
      try {
        // Process form data based on the formType
        if (formValidationCondition === "parentForm") {
          submitForm();
        } else if (formValidationCondition === "childForm") {
          submitForm();
        }
        validatorFields.hideMessages();
      } catch (error) {
        // Handle error for form submission
      }
    } else {
      // Force update to display validation messages
      forceUpdate({});
    }
  };

  /**
   * create and update images
   */
  const handleSubmitSetting = async (isRestore = false, isClose = true) => {
    if (id >= -1 && isEdit) {
      setLoaderShow(true);
      try {
        let newData;
        if (isRestore) {
          const isConfirm = window.confirm(
            "Are you sure want to restore style ?"
          );
          if (isConfirm) {
            newData = {
              styleRestore: true,
              latestNewsId: id,
            };
          } else {
            setLoaderShow(false);
            return;
          }
        } else {
          if (+theme === ThemeNo.ThemeOne) {
            newData = LatestNewsStyleOneSubFun({
              settingFormData,
              boxSection,
            });
            if (isBackgroundColor) {
              newData[".latest-news-section"] = {
                ...newData?.[".latest-news-section"],
                "background-color": settingFormData?.[".latest-news-section"]?.[
                  "background-color"
                ]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[".latest-news-section"]?.[
                          "background-color"
                        ],
                    })
                  : "",
              };
            }
            if (boxSection) {
              newData[".latest-news-section.bg-image::before"] = {
                ...newData[".latest-news-section.bg-image::before"],
                "border-radius": `${settingFormData[".latest-news-section"][
                  "border-radius"
                ]?.replace("rem", "")}rem`,
              };
            }
          }

          let { image, ...myData } = newData;
          newData = {
            style: myData,
            latestNewsId: id,
          };
        }

        const { data } = await putUpdateLatestNews(newData);

        if (data.status === 200) {
          getLatestNewsParentList({});
          if (isClose) {
            OpenModalCommonFunc(
              Cases.SETTING_LATEST_PARENT,
              setAllPopupState,
              false
            );
            emptyData();
            setbackImageToggle(false);
          }
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  /**
   * get data images
   * @param {id} latestNewsId
   */
  const handleEditLatestNews = async (latestNewsId, isParent) => {
    try {
      setLoaderShow(true);
      const { data } = await getLatestNewsDetails(latestNewsId);
      if (data.status === 200) {
        let payload = data.payload.data;
        if (isParent) {
          setSectionPageName(payload?.title);
          setId(latestNewsId);
          setIsParent(true);
          setFormData({
            title: payload?.title || "",
            subTitle: payload?.subTitle || "",
            navMapId: payload?.navMapId || "",
            isTitleShow: payload?.isTitleShow ? payload?.isTitleShow : false,
            style: payload?.style || {
              ".latest-news-section": {
                padding: "30px 0",
                "background-color": "rgb(33 37 41 / 100)",
              },
              ".latest-news-section.bg-image": {
                "background-image": "",
              },
              ".latest-news-section.bg-image::before": {
                "background-color": "rgb(0 0 0 / 0.8)",
              },
              ".latest-news-section .news-label": {
                color: "rgb(255 255 255 / 100)",
                "font-size": "20px",
                "font-weight": 500,
                "margin-right": "15px",
              },
              ".latest-news-section marquee": {
                color: "rgb(255 255 255 / 100)",
              },
              ".latest-news-section marquee a": {
                color: "rgb(255 73 124 / 100)",
              },
              latestNewsId: "",
            },
          });
          OpenModalCommonFunc(Cases.LATEST_NEWS_PARENT, setAllPopupState);
        } else {
          setChildId(latestNewsId);
          setIsParent(false);
          setFormDataChild({
            title: payload?.title || "",
            subTitle: payload?.subTitle || "",
            isTitleShow: payload?.isTitleShow ? payload?.isTitleShow : false,
            startDate: payload?.startDate
              ? moment(payload.startDate).format("YYYY-MM-DD")
              : "",
            endDate: payload?.endDate
              ? moment(payload.endDate).format("YYYY-MM-DD")
              : "",
            link: payload?.link || "",
            parentId: payload?.parentId || "",
            order: payload?.order || 1,
          });
          OpenModalCommonFunc(Cases.LATEST_NEWS, setAllPopupState);
        }
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * get setting data
   * @param {id} latestNewsId
   */
  const handleChangeSettings = async (latestNewsId, isThemeEdit = false) => {
    try {
      setLoaderShow(true);
      setId(latestNewsId);
      setIsParent(true);
      setSettingFormData((prev) => {
        return {
          ...prev,
          latestNewsId,
        };
      });
      const { data } = await getLatestNewsDetails(latestNewsId);
      if (data.status === 200) {
        const themeCount = data?.payload?.data?.theme;
        setTheme(themeCount);
        setImgShow(true);
        let payload = data.payload.data;
        setSectionPageName(payload.title);
        if (
          +themeCount === ThemeNo.ThemeOne &&
          isEmptyObject(data?.payload?.data?.style)
        ) {
          setSettingFormData({ ...LatestNewStyleOneReset });
          setBoxSection(false);
          setIsBackgroundColor(false);
          setbackImageToggle(false);
        } else {
          if (payload.style?.[".latest-news-section"]?.["background-color"]) {
            setIsBackgroundColor(true);
          }
          if (
            payload.style?.[".latest-news-section"]?.["width"] ||
            payload.style?.[".latest-news-section"]?.["margin"] ||
            payload.style?.[".latest-news-section"]?.["border-radius"]
          ) {
            setBoxSection(true);
          }
          if (
            payload.style?.[".latest-news-section.bg-image"]?.[
              "background-image"
            ]
          ) {
            setbackImageToggle(true);
          }
          let getStyle;
          if (+themeCount === ThemeNo.ThemeOne) {
            getStyle = LatesNewsThemeOneFunction(payload);
          } else {
            getStyle = LatesNewsThemeOneFunction(payload);
          }
          setSettingFormData((prev) => {
            return {
              ...prev,
              ...getStyle,
            };
          });
        }
        if (!isThemeEdit) {
          OpenModalCommonFunc(Cases.SETTING_LATEST_PARENT, setAllPopupState);
          TostMessage({ message: data, type: "success" });
        }
        setIsEdit(true);
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * delete images
   * @param {id} latestNewsId
   */
  const handleDelete = async (latestNewsId, isParent = false) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await deleteLatestNews(latestNewsId);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            ByteMiniWeb_Dashboard();
          } else {
            getLatestNewsParentList({ packageId: childId });
          }
          setIsParent(false);
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * clear data
   */
  const emptyData = () => {
    setIsBackgroundColor(false);
    setBoxSection(false);
    setIsEdit(false);
    setIsParent(false);
    setChildId("");
    setFormData({
      title: "",
      subTitle: "",
      navMapId: "",
      isTitleShow: true,
      style: {
        ".latest-news-section": {
          padding: "30px 0",
          "background-color": "rgb(33 37 41 / 100)",
        },
        ".latest-news-section.bg-image": {
          "background-image": "",
        },
        ".latest-news-section.bg-image::before": {
          "background-color": "",
        },
        ".latest-news-section .news-label": {
          color: "rgb(255 255 255 / 100)",
          "font-size": "20px",
          "font-weight": 500,
          "margin-right": "15px",
        },
        ".latest-news-section marquee": {
          color: "rgb(255 255 255 / 100)",
        },
        ".latest-news-section marquee a": {
          color: "rgb(255 73 124 / 100)",
        },
        latestNewsId: "",
      },
    });
    setSkill([{ id: randomStr(2), skill: "", parentageOfSkill: "" }]);
    setGalleryImgId("");
    setFormDataChild({
      title: "",
      subTitle: "",
      startDate: "",
      endDate: "",
      link: "",
      isTitleShow: true,
      order: 1,
    });
    setLoaderShow(false);
    setSettingFormData({
      ".latest-news-section": {
        padding: "30px 0",
        "background-color": "rgb(33 37 41 / 100)",
      },
      ".latest-news-section.bg-image": {
        "background-image": "",
      },
      ".latest-news-section.bg-image::before": {
        "background-color": "",
      },
      ".latest-news-section .news-label": {
        color: "rgb(255 255 255 / 100)",
        "font-size": "20px",
        "font-weight": 500,
        "margin-right": "15px",
      },
      ".latest-news-section marquee": {
        color: "rgb(255 255 255 / 100)",
      },
      ".latest-news-section marquee a": {
        color: "rgb(255 73 124 / 100)",
      },
      latestNewsId: "",
    });
  };

  const value = useMemo(() => {
    let [label] = navbarData.filter(
      (elem) => elem.navbarDetail.label === titleUpdateKey.Certificates
    );
    return label;
  }, [navbarData]);

  const handleAlignAndCloneSection = ({ isClone = false }) => {
    if (isClone) {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          cloneModal: true,
        };
      });
      setCloneFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          pageConfigDetailsLabel: value?.label || pageConfigDetails[0]?.label,
          contentDetailName: contentDetail.name,
        };
      });
    } else {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          alignModal: true,
        };
      });
      setAlignFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          label: value?.label || pageConfigDetails[0]?.label,
        };
      });
    }
  };
  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody
            className="support-ticket-font box-border-div"
            style={{ padding: "20px" }}
          >
            <Row>
              <CardBuilderHeaderSection
                addBtnTitle={pageConfigDetails[0]?.label}
                name={value?.label || pageConfigDetails[0]?.label}
                isAdd={!latestNewsDetail && true}
                mainEditSection={true}
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
                openModal={() => {
                  OpenModalCommonFunc(
                    Cases.LATEST_NEWS_PARENT,
                    setAllPopupState
                  );
                  setSectionPageName(modalsName.LATEST_NEWS_PARENT);
                  emptyData();
                  setIsParent(true);
                  setCollId(contentMapId);
                }}
                isCloneSection={true}
                isAlignSection={true}
                handleAlignAndCloneSection={handleAlignAndCloneSection}
              />
              {latestNewsDetail && (
                <Col xl="12 xl-50 box-col-6" md="6">
                  <Card className="ecommerce-widget mb-0">
                    <CardBody
                      className="support-ticket-font"
                      style={{ padding: "20px" }}
                    >
                      <div className="col-12">
                        <CardBuilderHeaderSection
                          name={latestNewsDetail?.title}
                          checked={latestNewsDetail?.isActive}
                          switchToggle={true}
                          id={latestNewsDetail?.latestNewsId}
                          handleChange={handleChangeLatestNews}
                        />
                        <OpenPopupBtn
                          openPopup={() => {
                            setSectionPageName(latestNewsDetail?.title);
                            getLatestNewsParentList({
                              parentId: latestNewsDetail.latestNewsId,
                            });
                            setFilter("");
                            setCollId(contentMapId);
                          }}
                          name={`Add or View ${latestNewsDetail?.title}`}
                        />
                      </div>
                      <div className="d-flex justify-content-end ">
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleEditLatestNews(
                              latestNewsDetail.latestNewsId,
                              true
                            );
                            setCollId(contentMapId);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() =>
                            handleDelete(latestNewsDetail.latestNewsId, true)
                          }
                        >
                          <Trash />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleChangeSettings(latestNewsDetail.latestNewsId);
                          }}
                        >
                          <Settings />
                        </IconButton>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
              <DeleteSection
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
              />
            </Row>
          </CardBody>
        </Card>
      </Col>
      <CommonPopup
        open={allPopupState.latestNewsChildPopup}
        close={() => {
          OpenModalCommonFunc(Cases.LATEST_NEWS, setAllPopupState, false);
          setIsParent(false);
          getLatestNewsParentList({ isClose: true });
        }}
        apiCall={getLatestNewsParentList}
        modalName={sectionPageName ? sectionPageName : modalsName.LATEST_NEWS}
        modalCase={Cases.LATEST_NEWS}
        handleSubmit={handleSubmit}
        validationForm={"childForm"}
        emptyData={emptyData}
        inputData={fromData}
        edit={false}
        oldTitle={titleUpdateKey.isGallery}
        label={titleUpdateKey.Certificates}
        getData={getData}
        content={
          <LatestNewsPopup
            handleChangeStatus={handleChangeLatestNews}
            getLatestNewsParentList={getLatestNewsParentList}
            handleEdit={handleEditLatestNews}
            imageUpload={imageUploadImages}
            emptyData={emptyData}
            simpleValidator={childValidator}
            webMasterDetail={webMasterDetail}
            handleDelete={handleDelete}
          />
        }
      />

      {/* latest news parent popup */}
      <CommonPopup
        open={allPopupState.latestNewsPopup}
        close={() => {
          OpenModalCommonFunc(
            Cases.LATEST_NEWS_PARENT,
            setAllPopupState,
            false
          );
          setIsParent(false);
        }}
        modalName={
          sectionPageName ? sectionPageName : modalsName.LATEST_NEWS_PARENT
        }
        modalCase={Cases.LATEST_NEWS_PARENT}
        handleSubmit={handleSubmit}
        validationForm={"parentForm"}
        emptyData={emptyData}
        inputData={formDataChild}
        edit={false}
        content={<LatestNewsParentPopup simpleValidator={parentValidator} />}
      />

      {/* setting images parent popup */}
      <CommonPopup
        open={allPopupState.latestSettingPopup}
        close={() =>
          OpenModalCommonFunc(
            Cases.SETTING_LATEST_PARENT,
            setAllPopupState,
            false
          )
        }
        modalName={
          sectionPageName ? sectionPageName : modalsName.SETTING_LATEST_PARENT
        }
        modalCase={Cases.SETTING_LATEST_PARENT}
        handleSubmit={handleSubmitSetting}
        emptyData={emptyData}
        inputData={formDataChild}
        edit={false}
        content={
          <SettingImageParentPopup
            simpleValidator={parentValidator}
            imageUpload={imageUploadSetting}
            handleSubmitSetting={handleSubmitSetting}
            handleChangeSettings={handleChangeSettings}
          />
        }
      />
    </>
  );
};

export default LatestNews;
