import { Grid, Typography } from "@mui/material";
import React from "react";
import { DATA_NOT_FOUND } from "../constant";

const DataNotFound = () => {
  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justifyContent="center"
      direction="column"
      sx={{ padding: 5, overflowY: "auto" }}
    >
      <Grid item xs={12}>
        <img
          src={DATA_NOT_FOUND}
          alt="No Data Found Avtar"
          style={{ height: 300, width: 390 }}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          spacing={1}
          alignItems="center"
          justifyContent="center"
          direction="column"
          sx={{ mt: 3 }}
        >
          <Typography
            variant="h4"
            sx={{
              fontSize: "28px !important",
              color: "#000",
              fontWeight: 600,
              lineHeight: "44px",
              letterSpacing: "0em",
            }}
          >
            No Data Found
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DataNotFound;
