import * as React from "react";
import { useContext } from "react";
import { useState } from "react";
import { toast, Zoom } from "react-toastify";
import { SORTING_ORDER } from "../../constant";
import LoaderContext from "../LoaderContext/LoaderContext";
import SettingMultiWebContext from "./SettingMultiWebContext";
import { randomStr } from "../../utils/helper";
import {
  DetailWebNavBarMap,
  WebContentMapList,
  WebContentMasterList,
  WebNavBarConfigList,
  WebNavBarMapWithContentList,
  WebNavBarMasterList,
} from "../../components ByteCard/components/MultipageByteCardBuilder/apis/NavApi/NavApi";

const SettingMultiWebState = (props) => {
  const { setLoaderShow, setTableAnimation } = useContext(LoaderContext);
  const [formvalue, setformValue] = useState([]);
  const [formvalueNav, setformValueNav] = useState([]);
  const [navMap, setNavMap] = useState([]);
  const [formvalueConfig, setformValueConfig] = useState([]);
  const [formvalueWebContentMaster, setformValueWebContentMaster] = useState(
    []
  );
  const [formvalueWebNavMaster, setformValueWebNavMaster] = useState([]);
  const [formvalueWebNavNull, setformValueWebNavNull] = useState([]);
  const [formDataWebContent, setFormDataWebContent] = useState({
    orgId: "",
    contents: [],
  });
  const [formDataWebNavbar, setFormDataWebNavbar] = useState({
    orgId: "",
    navBars: [],
  });
  const [formDataWebConfig, setFormDataWebConfig] = useState({
    orgId: "",
    configs: [],
    navBar: "",
  });
  const [sortOrder, setSortOrder] = useState(""); //SORTING -->
  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState("createdAt"); //SORTING -->
  const [record, setRecord] = useState(0);
  const [progress, setProgress] = useState(0);
  const [filter, setFilter] = useState(""); //SEARCHING FILTER -->
  const [togglesubmit, setToggleSubmit] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalChild, setModalChild] = useState(false);
  const [check, setCheck] = useState(0);
  const [broker, setBroker] = useState("");
  const [mainPageName, setMainPageName] = useState("");
  const [subPageName, setSubPageName] = useState("");
  const [pageLimit, setPageLimit] = useState(1);
  const [orgName, setOrgName] = useState("");
  const [domain, setDomain] = useState("");

  /**web content map list */
  const WebContentMap_List = async (orgId, showAll) => {
    if (sortOrder === "ASC" || sortOrder === "DSC" || filter.length) {
    } else {
    }
    try {
      let paramObj = {};
      if (showAll) {
        paramObj = {
          showAll: true,
          orgId,
        };
      } else {
        paramObj = {
          pageNumber: page,
          recordsPerPage: pageSize,
          sortOrder,
          sortBy,
          orgId,
        };
      }
      const { data } = await WebContentMapList(paramObj);
      if (data.status === 200) {
        setformValue(data.payload.data);
        setRecord(data.pager.totalRecords);
      } else {
        setformValue([]);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setTableAnimation(false);
    }
  };
  /**web nav map list */
  const [pageNumberNav, setPageNumberNav] = useState(1);
  const [pageSizeNav, setPageSizeNav] = useState(5);
  const [sortOrderNav, setSortOrderNav] = useState("ASC");
  const [sortByNav, setSortByNav] = useState("order");
  const [recordNav, setRecordNav] = useState(0);
  const [modalNav, setModalNav] = useState(false);
  const [togglesubmitNav, setToggleSubmitNav] = useState(false);
  const [upBtn, setUpBtn] = useState(true);
  const WebNavBarMap_List = async ({
    orgId,
    showAll,
    isParent,
    parentId,
    isChild,
    sortOrder,
    sortBy,
    isSearch = true,
  }) => {
    try {
      let paramObj = {
        pageNumber: pageNumberNav,
        recordsPerPage: pageSizeNav,
        sortOrder: sortOrder || sortOrderNav,
        sortBy: sortBy || sortByNav,
        orgId,
      };
      if (isSearch) {
        paramObj["search"] = { parentId: null };
      }
      if (parentId !== "" && parentId !== undefined) {
        paramObj["search"] = { parentId };
      }
      if (showAll && typeof showAll === "boolean") {
        paramObj["showAll"] = true;
      }
      if (
        isParent &&
        isParent !== null &&
        isParent !== "" &&
        typeof isParent === "boolean"
      ) {
        paramObj["search"] = { ...paramObj.search, paretId: null };
      }
      const { data } = await WebNavBarMapWithContentList(paramObj);
      if (data.status === 200) {
        if (isParent && isParent !== null && isParent !== "") {
          if (togglesubmitNav !== true) {
            setformValueWebNavNull([{ parentId: "", data: data.payload.data }]);
            return data.payload.data;
          }
        } else if (isChild && isChild !== false) {
          const createNavMap = ({ navData }) => {
            return navData?.map((ele) => {
              return {
                parentId: ele.navId,
                label: ele?.label || ele.navbarDetail.label,
                navMapId: ele.navMapId,
                navId: ele.navId,
                childIds: ele?.subMenu
                  ? createNavMap({ navData: ele?.subMenu })
                  : [],
              };
            });
          };
          const initialNavMap = createNavMap({ navData: data?.payload?.data });
          setNavMap(initialNavMap);
        } else {
          let newArr = [];
          for (const iterator of data.payload.data) {
            let { pageConfigDetails, ...rest } = iterator;
            let label = newArr.some((elem) => elem.label === iterator.label)
              ? iterator.label + randomStr(2)
              : iterator.label;
            newArr.push({
              hierarchy: [label],
              id: iterator.navMapId,
              label: iterator.label,
              ...rest,
            });
            if (iterator.pageConfigDetails?.length) {
              for (const i of iterator.pageConfigDetails) {
                let label = newArr.some((elem) => elem.label === i.label)
                  ? i.label + randomStr(2)
                  : i.label;
                newArr.push({
                  hierarchy: [iterator.label, label],
                  id: i.pageId,
                  label: i.label,
                  ...i,
                });
              }
            }
          }
          setformValueNav(newArr);
        }
        setRecordNav(data.pager.totalRecords);
      } else {
        setformValue([]);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setTableAnimation(false);
    }
  };
  /**web config page list */
  const [pageNumberConfig, setPageNumberConfig] = useState(1);
  const [pageSizeConfig, setPageSizeConfig] = useState(5);
  const [sortOrderConfig, setSortOrderConfig] = useState("");
  const [sortByConfig, setSortByConfig] = useState("createdAt");
  const [recordConfig, setRecordConfig] = useState(0);
  const [modalConfig, setModalConfig] = useState(false);
  const [togglesubmitConfig, setToggleSubmitConfig] = useState(false);
  const [positionChangeData, setPositionChangeData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const WebConfigPage_List = async (orgId, showAll, isPosition, navMapId) => {
    try {
      let paramObj = {};
      if (showAll) {
        paramObj = {
          showAll: true,
          orgId,
          sortOrder: "ASC",
          sortBy: "order",
        };
        if (navMapId) {
          paramObj["search"] = { navMapId: navMapId };
        }
      } else {
        paramObj = {
          pageNumber: pageNumberConfig,
          recordsPerPage: pageSizeConfig,
          sortOrder: sortOrderConfig,
          sortBy: sortByConfig,
          orgId,
        };
      }

      if (isPosition) {
        paramObj["search"] = { ...paramObj?.search, isActive: true };
      }
      const { data } = await WebNavBarConfigList(paramObj);
      if (data.status === 200) {
        if (isPosition) {
          setPositionChangeData(data.payload.data);
        } else {
          setformValueConfig(data.payload.data);
          setRecordConfig(data.pager.totalRecords);
        }
      } else {
        setformValue([]);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setTableAnimation(false);
    }
  };

  /**web content master list */
  const WebContentMaster_List = async (orgId) => {
    try {
      let paramObj = {
        showAll: true,
      };
      const { data } = await WebContentMasterList(paramObj);
      if (data.status === 200) {
        setformValueWebContentMaster(data.payload.data);
      } else {
        setformValue([]);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setTableAnimation(false);
    }
  };

  /**web nav bar master list */
  const WebNavBarMaster_List = async (paretId) => {
    try {
      let paramObj = {
        showAll: true,
      };
      const { data } = await WebNavBarMasterList(paramObj);
      if (data.status === 200) {
        setformValueWebNavMaster(data.payload.data);
      } else {
        setformValue([]);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setTableAnimation(false);
    }
  };

  /**Sorting List */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder(SORTING_ORDER.ASC);
    }
    if (sortOrder === SORTING_ORDER.ASC) {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder(SORTING_ORDER.DSC);
    }
    if (sortOrder === SORTING_ORDER.DSC) {
      setSortBy();
      setSortOrder("");
    }
  };

  const closeModel = () => {
    setIsEdit(false);
    setId("");
    setModalChild(false);
    setFormDataWebContent({ orgId: "", contents: [] });
    setFormDataWebNavbar({ orgId: "", navBars: [] });
    setFormDataWebConfig({ orgId: "", configs: [], navBar: "" });
    setformValueWebNavNull([]);
    setModal(false);
    setModalNav(false);
    setModalConfig(false);
    setLoaderShow(false);
    setAddFields([
      { id: randomStr(2), order: "", navId: "", label: "", slugUrl: "" },
    ]);
    setAddField([
      {
        id: randomStr(2),
        order: "",
        navMapId: "",
        label: "",
        itemLimit: "",
        contentId: "",
      },
    ]);
    setSelectedOptions([]);
  };
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [addFields, setAddFields] = useState([
    {
      id: randomStr(2),
      order: "",
      navId: "",
      label: "",
      slugUrl: "",
    },
  ]);
  const [addField, setAddField] = useState([
    {
      id: randomStr(2),
      order: "",
      navMapId: "",
      label: "",
      contentId: "",
    },
  ]);
  const [navMapID, setNavMapID] = useState();

  /** section position change */
  const [modalSectionPo, setModalSectionPo] = useState(false);

  const [id, setId] = useState("");
  const [selectedNavMap, setSelectedNavMap] = useState({});

  /** clone and align section */

  const [cloneAlignModal, setCloneAlignModal] = useState({
    cloneModal: false,
    alignModal: false,
  });
  const [alignFormData, setAlignFormData] = useState({
    contentMapId: "",
    navMapId: "",
    order: 0,
    label: "",
  });
  const [cloneFormData, setCloneFormData] = useState({
    contentMapId: "",
    navMapId: "",
    contentDetailName: "",
    pageConfigDetailsLabel: "",
  });

  let navMapIdArr = [];
  const NavMapFilter = (parentDetail) => {
    if (
      parentDetail !== undefined &&
      parentDetail !== null &&
      parentDetail?.navMapId !== null &&
      parentDetail?.parentDetail !== null
    ) {
      NavMapFilter(parentDetail.parentDetail);
    }
    if (parentDetail?.navMapId !== null && parentDetail?.navMapId !== undefined)
      navMapIdArr.push({ parentId: parentDetail?.navMapId });
  };

  const EditHandle = async (navMapId, orgId, dashboardCallBack = null) => {
    setId(navMapId);
    try {
      setToggleSubmitNav(true);
      let paramObj = {
        navMapId,
      };
      const { data } = await DetailWebNavBarMap(paramObj);
      if (data.status === 200) {
        let payload = data.payload.data;
        const response = await WebNavBarMap_List({
          orgId: orgId,
          isParent: true,
        });
        setFormDataWebNavbar({
          navMapId,
          orgId: orgId,
          navBars: [{ value: payload.navId, label: payload.label }],
        });
        setAddFields([
          {
            id: randomStr(2),
            order: payload?.order,
            navId: payload?.navId,
            label: payload?.label,
            slugUrl: payload?.slugUrl,
            navMapId: payload?.navMapId,
          },
        ]);
        setIsEdit(true);
        NavMapFilter(payload);
        let newArr = [];
        const createSelectedValue = (i, arr) => {
          if (arr !== undefined && arr?.length) {
            let navMapIdValue = navMapIdArr[i]?.parentId;
            newArr.push({ parentId: navMapIdValue, data: arr });

            let findValue = arr.find((elem) => elem.navMapId === navMapIdValue);
            if (findValue?.subMenu && findValue.subMenu?.length) {
              createSelectedValue(i + 1, findValue.subMenu);
            }
          }
        };
        if (dashboardCallBack !== null) {
          dashboardCallBack();
        }
        setformValueWebNavNull(newArr);
        createSelectedValue(0, response);
        setModalNav(true);
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };
  return (
    <>
      <SettingMultiWebContext.Provider
        value={{
          formvalue,
          setformValue,
          sortOrder,
          setSortOrder,
          pageSize,
          setPageSize,
          page,
          setPage,
          sortBy,
          setSortBy,
          record,
          setRecord,
          progress,
          setProgress,
          filter,
          setFilter,
          WebContentMap_List,
          WebContentMaster_List,
          WebNavBarMap_List,
          sorting,
          togglesubmit,
          setToggleSubmit,
          modal,
          setModal,
          closeModel,
          pageLimit,
          setPageLimit,
          orgName,
          setOrgName,
          check,
          setCheck,
          broker,
          setBroker,
          domain,
          setDomain,
          mainPageName,
          setMainPageName,
          subPageName,
          setSubPageName,
          formDataWebContent,
          setFormDataWebContent,
          formvalueWebContentMaster,
          setformValueWebContentMaster,
          formvalueWebNavNull,
          setformValueWebNavNull,

          pageNumberNav,
          pageSizeNav,
          sortOrderNav,
          sortByNav,
          setPageNumberNav,
          setPageSizeNav,
          setSortOrderNav,
          setSortByNav,
          formvalueNav,
          setformValueNav,
          recordNav,
          setRecordNav,
          modalNav,
          setModalNav,
          togglesubmitNav,
          setToggleSubmitNav,

          WebNavBarMaster_List,
          formvalueWebNavMaster,
          setformValueWebNavMaster,
          formDataWebNavbar,
          setFormDataWebNavbar,

          formvalueConfig,
          setformValueConfig,
          pageNumberConfig,
          setPageNumberConfig,
          pageSizeConfig,
          setPageSizeConfig,
          sortOrderConfig,
          setSortOrderConfig,
          sortByConfig,
          setSortByConfig,
          recordConfig,
          setRecordConfig,
          modalConfig,
          setModalConfig,
          togglesubmitConfig,
          setToggleSubmitConfig,
          WebConfigPage_List,
          formDataWebConfig,
          setFormDataWebConfig,
          addFields,
          setAddFields,
          selectedOptions,
          setSelectedOptions,
          addField,
          setAddField,
          navMapID,
          setNavMapID,

          modalChild,
          setModalChild,
          navMap,
          setNavMap,
          upBtn,
          setUpBtn,
          modalSectionPo,
          setModalSectionPo,
          positionChangeData,
          setPositionChangeData,
          isEdit,
          setIsEdit,
          id,
          setId,
          selectedNavMap,
          setSelectedNavMap,
          cloneAlignModal,
          setCloneAlignModal,
          alignFormData,
          setAlignFormData,
          cloneFormData,
          setCloneFormData,
          EditHandle,
          NavMapFilter,
          navMapIdArr,
        }}
      >
        {props.children}
      </SettingMultiWebContext.Provider>
    </>
  );
};

export default SettingMultiWebState;
