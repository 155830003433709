import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import ProductsContext from "../../../../context/MenuMasterContext/ProductsContext/ProductsContext";
import { maxLengthCheck } from "../../../../utils/helper";
import { PRODUCTS_TYPE } from "../../../../constant";
import Select from "react-select";
import { TagsList } from "../../../../api/MenuMasterApi/TagsApi/TagsApi";
import {
  OpenModalCommonFunc,
  TostMessage,
} from "../../../../components ByteCard/utils";
import {
  Cases,
  DELETE_CONFIRM,
} from "../../../../components ByteCard/constant";
import { UploadCloud } from "react-feather";
import { Close } from "@mui/icons-material";
import ByteCardBuilderContext from "../../../../components ByteCard/context/BytecardBilderContext/ByteCardBuilderContext";
import { IconButton } from "@mui/material";
import { delete_Image_Permanent } from "../../../../components ByteCard/apis/AboutApi/AboutUsApi";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import ProductImageUploadPopup from "../../../../components ByteCard/Modals/imageUploade/productImageUploadPopup";
import { CategoryList } from "../../../../api/MenuMasterApi/CategoryApi/CategoryApi";
const ProductsAddPopup = (props) => {
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const { handleSubmit, simpleValidator, imageUpload, QpiId, emptyData } =
    props;
  const { addModel, setAddModel, formData, setFormData, isEdit } =
    useContext(ProductsContext);
  const { setLoaderShow } = useContext(LoaderContext);
  const [tagsData, setTagsData] = useState([]);
  const [allCategory, setAllCategory] = useState([]);

  const CloseModel = () => {
    emptyData();
    setAddModel(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleChangeSelect = (selectedOption, name) => {
    setFormData((prev) => ({
      ...prev,
      [name]: selectedOption,
    }));
  };

  const getTags = async () => {
    try {
      const { data } = await TagsList();

      let allTags = data?.payload?.data?.map((elem) => {
        return {
          value: elem.tag,
          label: elem.tag,
        };
      });
      setTagsData(allTags);
    } catch (error) {}
  };

  const getCategoryList = async () => {
    try {
      let paramObj = {
        showAll: true,
        qpiId: QpiId,
      };
      const { data } = await CategoryList(paramObj);

      if (data.status === 200) {
        setAllCategory(
          data.payload.data.map((ele) => {
            return {
              value: ele.menuCategoryId,
              label: ele.name,
            };
          })
        );
      } else {
      }
    } catch (error) {}
  };

  useEffect(() => {
    getTags();
    getCategoryList();
  }, []);

  /**
   * reupload image
   */
  const reupload = () => {
    setFormData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };

  const deleteImagePermanent = async (imgName) => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formDatas = {
          fileName: formData[`${imgName}`]
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
            .pop(),
        };
        const { data } = await delete_Image_Permanent(formDatas);
        if (data.status === 200) {
          TostMessage({ message: data, type: "success", position: "center" });
          setFormData({ ...formData, image: "" });
          setFormData((prev) => {
            return {
              ...prev,
              [`${imgName}`]: "",
              image: "",
            };
          });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  return (
    <>
      <Modal size="xl" isOpen={addModel} toggle={() => CloseModel()}>
        <ModalHeader toggle={() => CloseModel()}>
          {isEdit ? "Edit" : "Add"} Products
        </ModalHeader>
        <ModalBody>
          <Container fluid={true} className="p-0">
            <Row>
              <Form className="theme-form" onSubmit={(e) => handleSubmit(e)}>
                <Row>
                  <Col md="6 mb-4">
                    <Label>{"Name"}</Label>
                    <Input
                      className="form-control"
                      type={"text"}
                      style={{ color: "black" }}
                      name="name"
                      onChange={(e) => handleChange(e)}
                      value={formData.name}
                      placeholder="Name"
                    />
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "name",
                        formData.name,
                        "required"
                      )}
                    </p>
                  </Col>
                  <Col md="6 mb-4">
                    <Label>{"Price"}</Label>
                    <Input
                      className="form-control"
                      type={"number"}
                      name="price"
                      style={{ color: "black" }}
                      onChange={(e) => handleChange(e)}
                      value={formData.price}
                      placeholder="Price"
                    />
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "price",
                        formData.price,
                        "required"
                      )}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md="6 mb-4">
                    <Label htmlFor="validationCustom03">{"Type"}</Label>
                    <select
                      name="type"
                      id="font-weight"
                      className="form-select"
                      value={formData.type}
                      onChange={(e) => handleChange(e)}
                      style={{ color: "black" }}
                    >
                      <option value="">--select--</option>
                      {PRODUCTS_TYPE.map((elem) => {
                        return (
                          <option value={elem["value"]} key={elem["key"]}>
                            {elem["key"]}
                          </option>
                        );
                      })}
                    </select>
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "Type",
                        formData.type,
                        "required"
                      )}
                    </p>
                  </Col>
                  <Col md="6 mb-4">
                    <Label htmlFor="validationCustom03">{"tags"}</Label>
                    <Select
                      isMulti
                      name="tags"
                      closeMenuOnSelect={false}
                      options={tagsData}
                      value={formData.tags}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => handleChangeSelect(e, "tags")}
                    />
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "Tags",
                        formData.tags,
                        "required"
                      )}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md="6 mb-4">
                    <Label htmlFor="validationCustom03">
                      {"Select Category"}
                    </Label>
                    <Select
                      isMulti
                      name="menuCategoryIds"
                      closeMenuOnSelect={false}
                      options={allCategory}
                      value={formData.menuCategoryIds}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => handleChangeSelect(e, "menuCategoryIds")}
                    />
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "Category",
                        formData.menuCategoryIds,
                        "required"
                      )}
                    </p>
                  </Col>
                  <Col md="3 mb-4">
                    <Label>{"Rating (enter Value : 1 to 5)"}</Label>
                    <Input
                      className="form-control"
                      type={"number"}
                      name="rating"
                      onChange={(e) => handleChange(e)}
                      value={formData.rating}
                      style={{ color: "black" }}
                      placeholder="Rating"
                      min="0"
                      max="5"
                      maxLength={3}
                      onInput={maxLengthCheck}
                    />
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "Rating",
                        formData.rating,
                        "required"
                      )}
                    </p>
                  </Col>
                  <Col md="3 mb-4">
                    <Label>{"Product Order"}</Label>
                    <Input
                      className="form-control"
                      type={"number"}
                      name="productOrder"
                      style={{ color: "black" }}
                      onChange={(e) => handleChange(e)}
                      value={formData.productOrder}
                      placeholder="Product Order"
                    />
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "ProductOrder",
                        formData.productOrder,
                        "required"
                      )}
                    </p>
                  </Col>
                </Row>
                <Col md="12 mb-4">
                  <Label>{"Short Description"}</Label>
                  <Input
                    className="form-control"
                    type={"text"}
                    name="shortDescription"
                    onChange={(e) => handleChange(e)}
                    style={{ color: "black" }}
                    value={formData.shortDescription}
                    placeholder="Short Description"
                  />
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "Short Description",
                      formData.shortDescription,
                      "required"
                    )}
                  </p>
                </Col>
                <Col md="12 mb-1">
                  <Label htmlFor="validationCustom03">{"Description"}</Label>
                  <textarea
                    className="form-control"
                    name="longDescription"
                    type="text"
                    rows={5}
                    min={0}
                    maxLength={1000}
                    onInput={maxLengthCheck}
                    placeholder="Description"
                    style={{ color: "black" }}
                    value={formData.longDescription}
                    onChange={(e) => handleChange(e)}
                  />
                  <div className="text-end mt-3 ">
                    <span>{formData?.description?.length}</span>
                  </div>
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "Description",
                      formData.longDescription,
                      "required"
                    )}
                  </p>
                </Col>
                <Row>
                  <Col md="6 mb-3">
                    <label htmlFor="">{"Image Square"}</label>
                    <div
                      className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                      style={{ cursor: "pointer" }}
                    >
                      <span
                        onClick={() => {
                          OpenModalCommonFunc(
                            Cases.PRODUCT_UPLOAD,
                            setAllPopupState
                          );
                          setFormData((prev) => {
                            return {
                              ...prev,
                              imageName: "imageSquare",
                            };
                          });
                        }}
                      >
                        <UploadCloud />
                      </span>
                      {formData?.imageSquare && (
                        <img
                          src={
                            formData?.imageSquare ? formData?.imageSquare : ""
                          }
                          alt=""
                          style={{
                            width: "85%",
                            height: "90px",
                            objectFit: "contain",
                            position: "absolute",
                          }}
                          onClick={() => {
                            OpenModalCommonFunc(
                              Cases.PRODUCT_UPLOAD,
                              setAllPopupState
                            );
                            setFormData((prev) => {
                              return {
                                ...prev,
                                imageName: "imageSquare",
                              };
                            });
                          }}
                        />
                      )}
                      {formData.imageSquare && (
                        <IconButton
                          edge="start"
                          color="inherit"
                          aria-label="close"
                          style={{
                            color: "red",
                            position: "absolute",
                            top: "5px",
                            left: "1px",
                          }}
                          onClick={() => deleteImagePermanent("imageSquare")}
                        >
                          <Close
                            style={{ fontSize: "25px", fontWeight: "bold" }}
                          />
                        </IconButton>
                      )}
                    </div>
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "image Square",
                        formData.imageSquare,
                        "required"
                      )}
                    </p>
                  </Col>
                  <Col md="6 mb-3">
                    <label htmlFor="">{"Image Rectangle"}</label>
                    <div
                      className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                      style={{ cursor: "pointer" }}
                    >
                      <span
                        onClick={() => {
                          OpenModalCommonFunc(
                            Cases.PRODUCT_UPLOAD,
                            setAllPopupState
                          );
                          setFormData((prev) => {
                            return {
                              ...prev,
                              imageName: "imageRectangle",
                            };
                          });
                        }}
                      >
                        <UploadCloud />
                      </span>
                      {formData?.imageRectangle && (
                        <img
                          src={
                            formData?.imageRectangle
                              ? formData?.imageRectangle
                              : ""
                          }
                          alt=""
                          style={{
                            width: "85%",
                            height: "90px",
                            objectFit: "contain",
                            position: "absolute",
                          }}
                          onClick={() => {
                            OpenModalCommonFunc(
                              Cases.PRODUCT_UPLOAD,
                              setAllPopupState
                            );
                            setFormData((prev) => {
                              return {
                                ...prev,
                                imageName: "imageRectangle",
                              };
                            });
                          }}
                        />
                      )}
                      {formData.imageRectangle && (
                        <IconButton
                          edge="start"
                          color="inherit"
                          aria-label="close"
                          style={{
                            color: "red",
                            position: "absolute",
                            top: "5px",
                            left: "1px",
                          }}
                          onClick={() => deleteImagePermanent("imageRectangle")}
                        >
                          <Close
                            style={{ fontSize: "25px", fontWeight: "bold" }}
                          />
                        </IconButton>
                      )}
                    </div>
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "image Rectangle",
                        formData.imageRectangle,
                        "required"
                      )}
                    </p>
                  </Col>
                </Row>

                <div className="login-btn mt-2">
                  <Button color="primary" type="submit" value="submit">
                    {"Submit"}
                  </Button>
                </div>
              </Form>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
      <ProductImageUploadPopup
        allPopupState={allPopupState}
        setAllPopupState={setAllPopupState}
        crop={crop}
        setCrop={setCrop}
        completedCrop={completedCrop}
        setCompletedCrop={setCompletedCrop}
        scale={scale}
        rotate={rotate}
        image={formData?.image}
        setImage={setFormData}
        imageUpload={imageUpload}
        // isEdit={isEdit}
        reupload={reupload}
        // aspect={22.68 / 1}
      />
    </>
  );
};

export default ProductsAddPopup;
