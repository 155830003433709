import axios from "axios";
import {
  ADD_PRIVILLAGE_LIST,
  DELETE_PRIVILLAGE_LIST,
  DETAILS_PRIVILLAGE_LIST,
  EDIT_PRIVILLAGE_LIST,
  MODULE_ACCESS_LIST,
  MODULE_LIST,
  PRIVILLAGE_LIST,
} from "../../constant/API constant";

export const PrivillageList = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${PRIVILLAGE_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const ModuleList = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${MODULE_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const ModuleAccessList = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${MODULE_ACCESS_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const Add_PrivillageList = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${ADD_PRIVILLAGE_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Edit_PrivillageList = (formData, id) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${EDIT_PRIVILLAGE_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { privilegeId: id },
    }
  );
};

export const Delete_PrivillageList = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(
    `${process.env.REACT_APP_API_URL}${DELETE_PRIVILLAGE_LIST}`,
    {
      headers: { Authorization: jwt_token },
      data: formData,
    }
  );
};

export const Ditails_PrivillageList = (id) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${DETAILS_PRIVILLAGE_LIST}`,
    {
      headers: { Authorization: jwt_token },
      params: { privilegeId: id },
    }
  );
};
