import axios from "axios";
import {
  GET_SEO_DETAILS,
  GET_SEO_DETAILS_DETAILS,
  POST_SEO_DETAILS,
  PUT_SEO_DETAILS_ORDER,
  PUT_STATUS_SEO_DETAILS,
} from "..";

/**
 * get seo details list
 * @param {parameters} paramObj
 * @returns
 */

export const getSeoDetails = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_SEO_DETAILS}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * enable-disable
 * @param {body} body
 * @returns
 */
export const updateStatus = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_STATUS_SEO_DETAILS}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * create seo details
 * @param {body} body
 * @returns
 */
export const postSeoDetails = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${POST_SEO_DETAILS}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * update seo details
 * @param {body} body
 * @returns
 */
export const putUpdateSeoDetails = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${POST_SEO_DETAILS}`,
    body,
    {
      headers: { Authorization: jwt_token },
      params: { id: body.id },
    }
  );
};

/**
 * delete seo details
 * @param {id} id
 * @returns
 */
export const deleteSeoDetails = (id) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${POST_SEO_DETAILS}`, {
    headers: { Authorization: jwt_token },
    params: { id },
  });
};

/**
 * get seo details
 * @param {id} id
 * @returns
 */
export const getSeoDetailsDetails = (id) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${GET_SEO_DETAILS_DETAILS}`,
    {
      headers: { Authorization: jwt_token },
      params: { id },
    }
  );
};

/**
 * short order seo details
 * @param {body} updateOrder
 * @returns
 */
export const sort_order_seo_details = (updateOrder) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_SEO_DETAILS_ORDER}`,
    { updateOrder: updateOrder },
    {
      headers: { Authorization: jwt_token },
    }
  );
};
