import React, { useContext, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Cases, DELETE_CONFIRM, modalsName } from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import CommonPopup from "../../../Modals/commonPopup";
import AboutUsPopup from "./aboutUsPopup";
import { Zoom, toast } from "react-toastify";
import {
  deleteAboutUs,
  getAboutUs,
  getAboutUsDetails,
  postAboutUs,
  putUpdateAboutUs,
  putUpdateAboutUsParent,
  updateStatus,
} from "../apis/AboutApi/AboutUsApi";
import AboutUsContext from "../../../context/AboutUsContext/AboutUsContext";
import { FILE_TYPE, ThemeNo, titleUpdateKey } from "../../../../constant";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import SimpleReactValidator from "simple-react-validator";
import AboutUsParentPopup from "./AboutUsParentPopup";
import { IconButton } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import { Settings, Trash } from "react-feather";
import AboutUsSettingParent from "./AboutUsSettingParent";
import {
  addUrlImage,
  convertRGBA,
  isCheckValue,
  isEmptyObject,
  removeUrlImage,
  removeUrlKeyInPath,
  rgbColorCreate,
} from "../../../../utils/helper";
import { FILE_UPLOAD_API } from "../apis/SliderApi/SliderApi";
import DeleteSection from "../../../common/DeleteSection";
import SettingMultiWebContext from "../../../../context/SettingMultiWebContext/SettingMultiWebContext";
import { aboutStyleOneReset, aboutStyleTwoReset } from "./AboutStyleFunction";

const Aboutus = (props) => {
  const {
    pageConfigDetails,
    webMasterDetail,
    ByteMiniWeb_Dashboard,
    getData,
    aboutUsDetail,
    contentMapId,
    contentDetail,
  } = props;
  const { setLoaderShow } = useContext(LoaderContext);
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );
  const { setCloneAlignModal, setAlignFormData, setCloneFormData } = useContext(
    SettingMultiWebContext
  );
  const parentValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const childValidator = useRef(new SimpleReactValidator());
  const {
    apiData: abutUsData,
    setApiData,
    fromData,
    setFormData,
    id,
    setId,
    setIsEdit,
    isEdit,
    formDataImageParent,
    setFormDataImageParent,
    settingFormData,
    setSettingFormData,
    childId,
    setChildId,
    theme,
    setTheme,
    setBoxSection,
    setBackImageToggle,
    boxSection,
    collId,
    setCollId,
    isParent,
    setIsParent,
    isBackgroundColor,
    setIsBackgroundColor,
    setImgShow,
    sectionPageName,
    setSectionPageName,
  } = useContext(AboutUsContext);

  const getAboutUsList = async ({
    parentId = null,
    isClose = false,
    filter,
    sectionTitle,
  }) => {
    try {
      let paramObj = { search: { showAll: true } };
      if (
        ((parentId && parentId !== null) ||
          (id && isClose === false && isParent === false)) &&
        isParent === false
      ) {
        paramObj["search"] = { parentId: parentId || id };
        setId(parentId || id);
        if (filter?.length) {
          paramObj["search"] = { ...paramObj.search, title: filter };
        }
      } else {
        paramObj["search"] = { parentId: null };
      }
      const { data } = await getAboutUs(paramObj);
      if (isCheckValue({ value: parentId })) {
        setChildId(parentId);
      }
      if (data.status === 200) {
        if (
          isParent === false &&
          ((parentId && parentId !== null) || id) &&
          isClose === false
        ) {
          setApiData({
            ...abutUsData,
            isLoading: false,
            data: data.payload.data.map((elem) => {
              return {
                id: elem?.title + elem?.id,
                Id: elem?.id,
                name: elem?.title,
                image: elem?.image,
                webId: elem?.webId,
                parentId: elem?.parentId,
                createdAt: elem?.createdAt,
                isActive: elem?.isActive,
              };
            }),
          });
          setSectionPageName(sectionTitle);
          OpenModalCommonFunc(Cases.ABOUT_US, setAllPopupState);
        } else if (parentId && parentId !== null) {
          setSectionPageName(sectionTitle);
          OpenModalCommonFunc(Cases.ABOUT_US, setAllPopupState);
          setApiData({
            ...abutUsData,
            isLoading: false,
            data: data.payload.data.map((elem) => {
              return {
                id: elem?.title + elem?.ourClientId,
                Id: elem?.ourClientId,
                name: elem?.title,
                image: elem?.image,
                webId: elem?.webId,
                parentId: elem?.parentId,
                createdAt: elem?.createdAt,
                isActive: elem?.isActive,
              };
            }),
          });
        } else {
          setApiData({
            ...abutUsData,
            isLoading: false,
            data: data.payload.data,
          });
        }
        if (isClose && isClose !== false) {
          setIsParent(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * enable disable api call
   * @param {slider id} sliderId
   * @param {status} isActive
   */

  const handleChangeStatus = async (id, isActive, isChild, parentId) => {
    try {
      let body = {
        id,
        isActive: isActive ? false : true,
        parentId,
      };
      const { data } = await updateStatus(body);
      if (data.status === 200) {
        if (isChild) {
          getAboutUsList({ parentId: parentId });
        } else {
          ByteMiniWeb_Dashboard();
        }
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * image upload
   * @param {file} file
   */
  const imageUpload = async (file) => {
    setLoaderShow(true);
    let formData = new FormData();
    formData.append("type", FILE_TYPE.ABOUT_US);
    formData.append("file", file);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        setFormData((prev) => {
          return {
            ...prev,
            image: data.payload.baseUrl + data.payload.imageUrl,
          };
        });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * bhackground image upload
   * @param {file} file
   */
  const backgroundImageUpload = async (file) => {
    setLoaderShow(true);
    let formData = new FormData();
    formData.append("type", FILE_TYPE.ABOUT_US);
    formData.append("file", file);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        setSettingFormData((prev) => {
          if (+theme === ThemeNo.ThemeOne) {
            return {
              ...prev,
              ".about-section.bg-image": {
                "background-image":
                  data.payload.baseUrl + data.payload.imageUrl,
              },
            };
          } else if (+theme === ThemeNo.ThemeThree) {
            return {
              ...prev,
              ".about-section.bg-image": {
                "background-image":
                  data.payload.baseUrl + data.payload.imageUrl,
              },
            };
          }
          if (+theme === ThemeNo.ThemeTwo) {
            return {
              ...prev,
              ".about2-section.bg-image": {
                "background-image":
                  data.payload.baseUrl + data.payload.imageUrl,
              },
            };
          } else if (+theme === ThemeNo.ThemeFour) {
            return {
              ...prev,
              ".about2-section.bg-image": {
                "background-image":
                  data.payload.baseUrl + data.payload.imageUrl,
              },
            };
          }
        });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };
  /**
   * create and update about us
   */
  const handleSubmit = async () => {
    let newData = {};
    if ((childId >= -1 && isEdit) || (id >= -1 && isEdit)) {
      try {
        newData = {
          ...fromData,
          contentMapId: collId,
          id: childId,
        };
        if (fromData?.image?.length) {
          newData["image"] = fromData.image
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
            .pop();
        }
        setLoaderShow(true);
        const { data } = await putUpdateAboutUs(newData);
        if (data.status === 200) {
          getAboutUsList({ parentId: id });
          TostMessage({ message: data, type: "success" });
          ByteMiniWeb_Dashboard();
          setLoaderShow(false);
          hanldenewCreate();
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    } else {
      try {
        setLoaderShow(true);
        let newData = {
          ...fromData,
          contentMapId: collId,
          parentId: id,
        };
        if (fromData?.image?.length) {
          newData["image"] = fromData.image
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
            .pop();
        }

        const { data } = await postAboutUs(newData);
        if (data.status === 200) {
          getAboutUsList({ parentId: id });
          TostMessage({ message: data, type: "success" });
          ByteMiniWeb_Dashboard();
          setLoaderShow(false);
          emptyDataAboutUs();
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  const handleSubmitSetting = async () => {
    if (id >= -1 && isEdit) {
      try {
        setLoaderShow(true);
        let newData = {
          ...formDataImageParent,
          contentMapId: collId,
          id: id,
        };
        delete newData.style;
        const { data } = await putUpdateAboutUsParent(newData);
        if (data.status === 200) {
          ByteMiniWeb_Dashboard();
          setLoaderShow(false);
          TostMessage({ message: data, type: "success" });
          OpenModalCommonFunc(Cases.ABOUT_US_PARENT, setAllPopupState, false);
          emptyDataAboutUs();
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    } else {
      try {
        setLoaderShow(true);
        let newData = {
          ...formDataImageParent,
          contentMapId: collId,
        };
        // delete newData.style;
        const { data } = await postAboutUs(newData);
        if (data.status === 200) {
          ByteMiniWeb_Dashboard();
          setLoaderShow(false);
          TostMessage({ message: data, type: "success" });
          OpenModalCommonFunc(Cases.ABOUT_US_PARENT, setAllPopupState, false);
          emptyDataAboutUs();
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  const handleSubmitWithValidate = async (formValidationCondition) => {
    let validatorFields;
    if (formValidationCondition === "parentForm") {
      validatorFields = parentValidator.current;
    } else if (formValidationCondition === "childForm") {
      validatorFields = childValidator.current;
    }
    validatorFields.showMessages();

    if (validatorFields.allValid()) {
      try {
        // Process form data based on the formType
        if (formValidationCondition === "parentForm") {
          handleSubmitSetting();
        } else if (formValidationCondition === "childForm") {
          handleSubmit();
        }
        validatorFields.hideMessages();
      } catch (error) {
        // Handle error for form submission
      }
    } else {
      // Force update to display validation messages
      forceUpdate({});
    }
  };

  const handleChangeSettings = async (aboutUsId, isThemeEdit = false) => {
    try {
      setLoaderShow(true);
      setId(aboutUsId);
      setSettingFormData((prev) => {
        return {
          ...prev,
          aboutUsId,
        };
      });
      const { data } = await getAboutUsDetails(aboutUsId);
      if (data.status === 200) {
        const themeCount = data?.payload?.data?.theme;
        let payload = data.payload.data;
        setSectionPageName(payload?.title);
        setTheme(themeCount);
        setImgShow(true);
        let find = [".about-section", ".about2-section"];
        let section = Object.keys(payload.style);
        let foundItem = null;
        for (const key in find) {
          if (section.includes(find[key])) {
            foundItem = find[key];
            break; // If found, exit the loop
          }
        }
        if (
          +themeCount === ThemeNo.ThemeOne &&
          isEmptyObject(data?.payload?.data?.style)
        ) {
          setSettingFormData({ ...aboutStyleOneReset });
          setBoxSection(false);
          setBackImageToggle(false);
          setIsBackgroundColor(false);
        } else if (
          +themeCount === ThemeNo.ThemeTwo &&
          isEmptyObject(data?.payload?.data?.style)
        ) {
          setSettingFormData({ ...aboutStyleTwoReset });
          setBoxSection(false);
          setBackImageToggle(false);
          setIsBackgroundColor(false);
        } else if (
          +themeCount === ThemeNo.ThemeThree &&
          isEmptyObject(data?.payload?.data?.style)
        ) {
          setSettingFormData({ ...aboutStyleOneReset });
          setBoxSection(false);
          setBackImageToggle(false);
          setIsBackgroundColor(false);
        } else if (
          +themeCount === ThemeNo.ThemeFour &&
          isEmptyObject(data?.payload?.data?.style)
        ) {
          setSettingFormData({ ...aboutStyleTwoReset });
          setBoxSection(false);
          setBackImageToggle(false);
          setIsBackgroundColor(false);
        } else {
          if (
            payload.style?.[foundItem]?.["width"] ||
            payload.style?.[foundItem]?.["margin"] ||
            payload.style?.[foundItem]?.["border-radius"]
          ) {
            setBoxSection(true);
          }
          if (payload.style?.[`${foundItem}.bg-image`]?.["background-image"]) {
            setBackImageToggle(true);
          }
          if (payload.style?.[`${foundItem}`]?.["background-color"]) {
            setIsBackgroundColor(true);
          }
          setSettingFormData((prev) => {
            return {
              ...prev,
              ...payload.style,
              [foundItem]: {
                padding: payload.style?.[foundItem]?.["padding"],
                "background-color": payload.style?.[foundItem]?.[
                  "background-color"
                ]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[foundItem]?.["background-color"],
                    })
                  : "",
                width: payload.style?.[foundItem]?.["width"]?.replace(
                  "calc(100% - ",
                  ""
                ),
                margin: payload.style?.[foundItem]?.["margin"],
                "border-radius": payload.style?.[foundItem]?.["border-radius"],
              },
              [`${foundItem}.bg-image`]: {
                "background-image":
                  payload.style?.[`${foundItem}.bg-image`]?.[
                    "background-image"
                  ] !== "" &&
                  payload.style?.[`${foundItem}.bg-image`]?.[
                    "background-image"
                  ] !== null
                    ? removeUrlKeyInPath({
                        urlString:
                          payload.style?.[`${foundItem}.bg-image`]?.[
                            "background-image"
                          ],
                      })
                    : "",
              },
              [`${foundItem}.bg-image::before`]: {
                "background-color": payload.style?.[
                  `${foundItem}.bg-image::before`
                ]?.["background-color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[`${foundItem}.bg-image::before`]?.[
                          "background-color"
                        ],
                    })
                  : "",
              },
              [`${foundItem} .section-title-area .section-subtitle`]: {
                ...payload.style?.[
                  `${foundItem} .section-title-area .section-subtitle`
                ],
                "background-color": payload.style?.[
                  `${foundItem} .section-title-area .section-subtitle`
                ]?.["background-color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          `${foundItem} .section-title-area .section-subtitle`
                        ]?.["background-color"],
                    })
                  : "",
                color: payload.style?.[
                  `${foundItem} .section-title-area .section-subtitle`
                ]?.["color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          `${foundItem} .section-title-area .section-subtitle`
                        ]?.["color"],
                    })
                  : "",
              },
              [`${foundItem} .section-title-area .section-title`]: {
                ...payload.style?.[
                  `${foundItem} .section-title-area .section-title`
                ],
                color: payload.style?.[
                  `${foundItem} .section-title-area .section-title`
                ]?.["color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          `${foundItem} .section-title-area .section-title`
                        ]?.["color"],
                    })
                  : "",
              },
              [`${foundItem} .about-details .btn-section`]: {
                ...payload.style?.[`${foundItem} .about-details .btn-section`],
                "justify-content":
                  payload.style?.[`${foundItem} .about-details .btn-section`]?.[
                    "justify-content"
                  ],
              },
              [`${foundItem} .about-details h5`]: {
                ...payload.style?.[`${foundItem} .about-details h5`],
                color: payload.style?.[`${foundItem} .about-details h5`]?.[
                  "color"
                ]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[`${foundItem} .about-details h5`]?.[
                          "color"
                        ],
                    })
                  : "",
              },
              [`${foundItem} .about-details p`]: {
                ...payload.style?.[`${foundItem} .about-details p`],
                color: payload.style?.[`${foundItem} .about-details p`]?.[
                  "color"
                ]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[`${foundItem} .about-details p`]?.[
                          "color"
                        ],
                    })
                  : "",
              },
              [`${foundItem} .about-details ul li`]: {
                ...payload.style?.[`${foundItem} .about-details ul li`],
                color: payload.style?.[`${foundItem} .about-details ul li`]?.[
                  "color"
                ]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[`${foundItem} .about-details ul li`]?.[
                          "color"
                        ],
                    })
                  : "",
              },
              [`${foundItem} .about-details ul li:before`]: {
                ...payload.style?.[`${foundItem} .about-details ul li:before`],
                color: payload.style?.[
                  `${foundItem} .about-details ul li:before`
                ]?.["color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          `${foundItem} .about-details ul li:before`
                        ]?.["color"],
                    })
                  : "",
              },
              [`${foundItem} .about-details .btn`]: {
                ...payload.style?.[`${foundItem} .about-details .btn`],
                color: payload.style?.[`${foundItem} .about-details .btn`]?.[
                  "color"
                ]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[`${foundItem} .about-details .btn`]?.[
                          "color"
                        ],
                    })
                  : "",
              },
              custom: payload?.style?.custom,
            };
          });
        }

        if (!isThemeEdit) {
          OpenModalCommonFunc(Cases.SETTING_ABOUT_PARENT, setAllPopupState);
          TostMessage({ message: data, type: "success" });
        }

        setIsEdit(true);
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * get details about us
   * @param {id} id
   */
  const handleEdit = async (idAbout, isParent) => {
    try {
      setLoaderShow(true);

      setIsParent(true);
      const { data } = await getAboutUsDetails(idAbout);

      if (data.status === 200) {
        let payload = data.payload.data;
        if (isParent && isParent !== false) {
          setId(idAbout);
          setSectionPageName(payload?.title);
          setFormDataImageParent({
            title: payload?.title,
            subTitle: payload?.subTitle,
            isTitleShow: payload?.isTitleShow ? payload?.isTitleShow : false,
            theme: payload?.theme,
          });
          setIsEdit(true);
          OpenModalCommonFunc(Cases.ABOUT_US_PARENT, setAllPopupState);
        } else {
          setChildId(idAbout);
          setIsParent(false);
          setFormData({
            title: payload.title,
            aboutUs: payload.aboutUs,
            order: payload.order,
            image: payload.image,
            isTitleShow: payload?.isTitleShow ? payload?.isTitleShow : false,
            subTitle: payload.subTitle,
            theme: payload.theme,
            buttonText: payload.buttonText,
            buttonLink: payload.buttonLink,
            parentId: payload.parentId,
          });
          setIsEdit(true);
          setChildId(payload.id);
        }

        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * delete images
   * @param {id} teamId
   */
  const handleDelete = async (teamId, isParent = false) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await deleteAboutUs(teamId);
        if (data.status === 200) {
          ByteMiniWeb_Dashboard();
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const handleAboutUsSubmitSetting = async (
    isRestore = false,
    isClose = true
  ) => {
    if (id >= -1 && isEdit) {
      setLoaderShow(true);
      try {
        let newData;
        if (isRestore) {
          const isConfirm = window.confirm(
            "Are you sure want to restore style ?"
          );
          if (isConfirm) {
            newData = {
              styleRestore: true,
              id: id,
            };
          } else {
            setLoaderShow(false);
            return;
          }
        } else {
          if (+theme === ThemeNo.ThemeOne || +theme === ThemeNo.ThemeThree) {
            newData = {
              ...settingFormData,
              ".about-section": boxSection
                ? {
                    ...settingFormData?.[".about-section"],
                    padding: `${settingFormData?.[".about-section"]?.["padding"]
                      ?.split(" ")[0]
                      ?.replace("px", "")}px 0`,
                    width: `calc(100% - ${settingFormData?.[".about-section"]?.[
                      "width"
                    ]?.replace("rem)", "")}rem)`,
                    margin: `${settingFormData?.[".about-section"]?.["margin"]
                      ?.split(" ")[0]
                      ?.replace("px", "")}px auto`,
                    "border-radius": `${settingFormData?.[".about-section"]?.[
                      "border-radius"
                    ]?.replace("rem", "")}rem`,
                  }
                : {
                    ...settingFormData?.[".about-section"],
                    padding: `${settingFormData?.[".about-section"]?.["padding"]
                      ?.split(" ")[0]
                      ?.replace("px", "")}px 0`,
                  },
              ".about-section.bg-image": settingFormData?.[
                ".about-section.bg-image"
              ]?.["background-image"]
                ? {
                    "background-image": settingFormData?.[
                      ".about-section.bg-image"
                    ]?.["background-image"]
                      ? addUrlImage({
                          urlString: removeUrlImage({
                            urlString:
                              settingFormData?.[".about-section.bg-image"]?.[
                                "background-image"
                              ],
                          }),
                        })
                      : "",
                  }
                : {},
              ".about-section.bg-image::before": settingFormData?.[
                ".about-section.bg-image::before"
              ]?.["background-color"]
                ? {
                    "background-color": settingFormData?.[
                      ".about-section.bg-image::before"
                    ]?.["background-color"]
                      ? rgbColorCreate({
                          rgbString:
                            settingFormData?.[
                              ".about-section.bg-image::before"
                            ]?.["background-color"],
                        })
                      : "",
                  }
                : {},
              ".about-section .section-title-area": {
                "margin-bottom": `${settingFormData[
                  ".about-section .section-title-area"
                ]?.["margin-bottom"]?.replace("px", "")}px`,
              },
              ".about-section .section-title-area .section-subtitle": {
                ...settingFormData?.[
                  ".about-section .section-title-area .section-subtitle"
                ],
                "font-size": `${settingFormData?.[
                  ".about-section .section-title-area .section-subtitle"
                ]?.["font-size"]?.replace("px", "")}px`,
                "font-weight": `${settingFormData?.[".about-section .section-title-area .section-subtitle"]?.["font-weight"]}`,
                color: settingFormData?.[
                  ".about-section .section-title-area .section-subtitle"
                ]?.["color"]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[
                          ".about-section .section-title-area .section-subtitle"
                        ]?.["color"],
                    })
                  : "",
                "background-color": settingFormData?.[
                  ".about-section .section-title-area .section-subtitle"
                ]?.["background-color"]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[
                          ".about-section .section-title-area .section-subtitle"
                        ]?.["background-color"],
                    })
                  : "",
                padding: `${settingFormData?.[
                  ".about-section .section-title-area .section-subtitle"
                ]?.["p1"]?.replace("px", "")}px ${settingFormData[
                  ".about-section .section-title-area .section-subtitle"
                ]?.["p2"]?.replace("px", "")}px`,
                p1: `${settingFormData?.[
                  ".about-section .section-title-area .section-subtitle"
                ]?.["p1"]?.replace("px", "")}px`,
                p2: `${settingFormData?.[
                  ".about-section .section-title-area .section-subtitle"
                ]?.["p2"]?.replace("px", "")}px`,
                "border-radius": `${settingFormData?.[
                  ".about-section .section-title-area .section-subtitle"
                ]?.["border-radius"]?.replace("px", "")}px`,
                "margin-bottom": `${settingFormData[
                  ".about-section .section-title-area .section-subtitle"
                ]?.["margin-bottom"]?.replace("px", "")}px`,
              },
              ".about-section .section-title-area .section-title": {
                ...settingFormData?.[".section-title-area .section-title"],
                "font-size": `${settingFormData?.[
                  ".about-section .section-title-area .section-title"
                ]?.["font-size"]?.replace("px", "")}px`,
                "font-weight": `${settingFormData?.[".about-section .section-title-area .section-title"]?.["font-weight"]}`,
                color: settingFormData?.[
                  ".about-section .section-title-area .section-title"
                ]?.["color"]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[
                          ".about-section .section-title-area .section-title"
                        ]?.["color"],
                    })
                  : "",
              },
              ".about-section .about-img": {
                ...settingFormData?.[".about-section .about-img"],
                margin: `${settingFormData?.[".about-section .about-img"]?.[
                  "margin"
                ]
                  ?.split(" ")[0]
                  ?.replace("px", "")}px auto`,
                display: `${settingFormData?.[".about-section .about-img"]?.["display"]}`,
              },
              ".about-section .about-details": {
                padding: `${settingFormData?.[
                  ".about-section .about-details"
                ]?.["padding"]?.replace("px", "")}px`,
              },
              ".about-section .about-details h5": {
                ...settingFormData?.[".about-section .about-details h5"],
                "font-size": `${settingFormData[
                  ".about-section .about-details h5"
                ]?.["font-size"]?.replace("px", "")}px`,
                "font-weight": `${settingFormData?.[".about-section .about-details h5"]?.["font-weight"]}`,
                color: settingFormData?.[".about-section .about-details h5"]?.[
                  "color"
                ]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[".about-section .about-details h5"]?.[
                          "color"
                        ],
                    })
                  : "",
                "line-height": `${settingFormData?.[".about-section .about-details h5"]?.["line-height"]}`,
                "margin-bottom": `${settingFormData?.[
                  ".about-section .about-details h5"
                ]?.["margin-bottom"]?.replace("px", "")}px`,
                "text-align": `${settingFormData?.[".about-section .about-details h5"]?.["text-align"]}`,
              },
              ".about-section .about-details p": {
                ...settingFormData?.[".about-section .about-details p"],
                "font-size": `${settingFormData?.[
                  ".about-section .about-details p"
                ]?.["font-size"]?.replace("px", "")}px`,
                "font-weight": `${settingFormData?.[".about-section .about-details p"]?.["font-weight"]}`,
                color: settingFormData?.[".about-section .about-details p"]?.[
                  "color"
                ]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[".about-section .about-details p"]?.[
                          "color"
                        ],
                    })
                  : "",
                "line-height": `${settingFormData?.[".about-section .about-details p"]?.["line-height"]}`,
                "margin-bottom": `${settingFormData?.[
                  ".about-section .about-details p"
                ]?.["margin-bottom"]?.replace("px", "")}px`,
                "text-align": `${settingFormData?.[".about-section .about-details p"]?.["text-align"]}`,
              },
              ".about-section .about-details ul": {
                "margin-bottom": `${settingFormData?.[
                  ".about-section .about-details ul"
                ]?.["margin-bottom"]?.replace("px", "")}px`,
              },
              ".about-section .about-details ul li": {
                ...settingFormData?.[".about-section .about-details ul li"],
                "font-size": `${settingFormData?.[
                  ".about-section .about-details ul li"
                ]?.["font-size"]?.replace("px", "")}px`,
                "font-weight": `${settingFormData?.[".about-section .about-details ul li"]?.["font-weight"]}`,
                color: settingFormData?.[
                  ".about-section .about-details ul li"
                ]?.["color"]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[
                          ".about-section .about-details ul li"
                        ]?.["color"],
                    })
                  : "",
                margin: `${settingFormData?.[
                  ".about-section .about-details ul li"
                ]?.["margin"]
                  ?.split(" ")[0]
                  ?.replace("px", "")}px 0`,
              },
              ".about-section .about-details ul li:before": {
                ...settingFormData?.[
                  ".about-section .about-details ul li:before"
                ],
                color: settingFormData?.[
                  ".about-section .about-details ul li:before"
                ]?.["color"]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[
                          ".about-section .about-details ul li:before"
                        ]?.["color"],
                    })
                  : "",
                "margin-right": `${settingFormData?.[
                  ".about-section .about-details ul li:before"
                ]?.["margin-right"]?.replace("px", "")}px`,
              },
              ".about-section .about-details ul li:first-child": {
                "margin-top": `${settingFormData?.[
                  ".about-section .about-details ul li:first-child"
                ]?.["margin-top"]?.replace("px", "")}px`,
              },
              ".about-section .about-details .btn-section": {
                "justify-content": `${settingFormData?.[".about-section .about-details .btn-section"]?.["justify-content"]}`,
              },
              ".about-section .about-details .btn": {
                ...settingFormData?.[".about-section .about-details .btn"],
                "background-color": settingFormData?.[
                  ".about-section .about-details .btn"
                ]?.["background-color"]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[
                          ".about-section .about-details .btn"
                        ]?.["background-color"],
                    })
                  : "",
                border: `${settingFormData?.[
                  ".about-section .about-details .btn"
                ]?.["borderPx"]?.replace("px", "")}px ${
                  settingFormData?.[".about-section .about-details .btn"][
                    "borderThik"
                  ]
                } ${rgbColorCreate({
                  rgbString:
                    settingFormData?.[".about-section .about-details .btn"]?.[
                      "borderColor"
                    ],
                })}`,
                borderPx: `${settingFormData?.[
                  ".about-section .about-details .btn"
                ]?.["borderPx"]?.replace("px", "")}px`,
                borderThik: `${settingFormData?.[".about-section .about-details .btn"]?.["borderThik"]}`,
                borderColor: settingFormData?.[
                  ".about-section .about-details .btn"
                ]?.["borderColor"]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[
                          ".about-section .about-details .btn"
                        ]?.["borderColor"],
                    })
                  : "",
                color: settingFormData?.[
                  ".about-section .about-details .btn"
                ]?.["color"]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[
                          ".about-section .about-details .btn"
                        ]?.["color"],
                    })
                  : "",
                padding: `${settingFormData?.[
                  ".about-section .about-details .btn"
                ]?.["p1"]?.replace("px", "")}px ${settingFormData?.[
                  ".about-section .about-details .btn"
                ]?.["p2"]?.replace("px", "")}px`,
                p1: `${settingFormData?.[
                  ".about-section .about-details .btn"
                ]?.["p1"]?.replace("px", "")}px`,
                p2: `${settingFormData?.[
                  ".about-section .about-details .btn"
                ]?.["p2"]?.replace("px", "")}px`,
              },
            };
            if (isBackgroundColor) {
              newData[".about-section"] = {
                ...newData?.[".about-section"],
                "background-color": settingFormData[".about-section"]?.[
                  "background-color"
                ]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData[".about-section"]?.["background-color"],
                    })
                  : "",
              };
            }
            if (boxSection) {
              newData[".about-section.bg-image::before"] = {
                ...newData[".about-section.bg-image::before"],
                "border-radius": `${settingFormData?.[".about-section"]?.[
                  "border-radius"
                ]?.replace("rem", "")}rem`,
              };
            }
          } else if (
            +theme === ThemeNo.ThemeTwo ||
            +theme === ThemeNo.ThemeFour
          ) {
            newData = {
              ...settingFormData,
              ".about2-section": boxSection
                ? {
                    ...settingFormData?.[".about2-section"],
                    padding: `${settingFormData?.[".about2-section"]?.[
                      "padding"
                    ]
                      ?.split(" ")[0]
                      ?.replace("px", "")}px 0`,
                    width: `calc(100% - ${settingFormData?.[
                      ".about2-section"
                    ]?.["width"]?.replace("rem)", "")}rem)`,
                    margin: `${settingFormData?.[".about2-section"]?.["margin"]
                      ?.split(" ")[0]
                      ?.replace("px", "")}px auto`,
                    "border-radius": `${settingFormData?.[".about2-section"]?.[
                      "border-radius"
                    ]?.replace("rem", "")}rem`,
                  }
                : {
                    ...settingFormData?.[".about2-section"],
                    padding: `${settingFormData?.[".about2-section"]?.[
                      "padding"
                    ]
                      ?.split(" ")[0]
                      ?.replace("px", "")}px 0`,
                  },
              ".about2-section.bg-image": settingFormData?.[
                ".about2-section.bg-image"
              ]?.["background-image"]
                ? {
                    "background-image": settingFormData?.[
                      ".about2-section.bg-image"
                    ]?.["background-image"]
                      ? addUrlImage({
                          urlString: removeUrlImage({
                            urlString:
                              settingFormData?.[".about2-section.bg-image"]?.[
                                "background-image"
                              ],
                          }),
                        })
                      : "",
                  }
                : {},
              ".about2-section.bg-image::before": settingFormData?.[
                ".about2-section.bg-image::before"
              ]?.["background-color"]
                ? {
                    "background-color": settingFormData?.[
                      ".about2-section.bg-image::before"
                    ]?.["background-color"]
                      ? rgbColorCreate({
                          rgbString:
                            settingFormData?.[
                              ".about2-section.bg-image::before"
                            ]?.["background-color"],
                        })
                      : "",
                  }
                : {},
              ".about2-section .section-title-area": {
                "margin-bottom": `${settingFormData?.[
                  ".about2-section .section-title-area"
                ]?.["margin-bottom"]?.replace("px", "")}px`,
              },
              ".about2-section .section-title-area .section-subtitle": {
                ...settingFormData?.[
                  ".about2-section .section-title-area .section-subtitle"
                ],
                "font-size": `${settingFormData?.[
                  ".about2-section .section-title-area .section-subtitle"
                ]?.["font-size"]?.replace("px", "")}px`,
                "font-weight": `${settingFormData?.[".about2-section .section-title-area .section-subtitle"]?.["font-weight"]}`,
                color: settingFormData?.[
                  ".about2-section .section-title-area .section-subtitle"
                ]?.["color"]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[
                          ".about2-section .section-title-area .section-subtitle"
                        ]?.["color"],
                    })
                  : "",
                "background-color": settingFormData?.[
                  ".about2-section .section-title-area .section-subtitle"
                ]?.["background-color"]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[
                          ".about2-section .section-title-area .section-subtitle"
                        ]?.["background-color"],
                    })
                  : "",
                padding: `${settingFormData[
                  ".about2-section .section-title-area .section-subtitle"
                ]?.["p1"]?.replace("px", "")}px ${settingFormData?.[
                  ".about2-section .section-title-area .section-subtitle"
                ]?.["p2"]?.replace("px", "")}px`,
                p1: `${settingFormData?.[
                  ".about2-section .section-title-area .section-subtitle"
                ]?.["p1"]?.replace("px", "")}px`,
                p2: `${settingFormData?.[
                  ".about2-section .section-title-area .section-subtitle"
                ]?.["p2"]?.replace("px", "")}px`,
                "border-radius": `${settingFormData?.[
                  ".about2-section .section-title-area .section-subtitle"
                ]?.["border-radius"]?.replace("px", "")}px`,
                "margin-bottom": `${settingFormData?.[
                  ".about2-section .section-title-area .section-subtitle"
                ]?.["margin-bottom"]?.replace("px", "")}px`,
              },
              ".about2-section .section-title-area .section-title": {
                ...settingFormData?.[".section-title-area .section-title"],
                "font-size": `${settingFormData?.[
                  ".about2-section .section-title-area .section-title"
                ]?.["font-size"]?.replace("px", "")}px`,
                "font-weight": `${settingFormData?.[".about2-section .section-title-area .section-title"]?.["font-weight"]}`,
                color: settingFormData?.[
                  ".about2-section .section-title-area .section-title"
                ]?.["color"]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[
                          ".about2-section .section-title-area .section-title"
                        ]?.["color"],
                    })
                  : "",
              },
              ".about2-section .about-img": {
                ...settingFormData?.[".about2-section .about-img"],
                margin: `${settingFormData?.[".about2-section .about-img"]?.[
                  "margin"
                ]
                  ?.split(" ")[0]
                  ?.replace("px", "")}px auto`,
                display: `${settingFormData?.[".about2-section .about-img"]?.["display"]}`,
              },
              ".about2-section .about-details": {
                padding: `${settingFormData?.[
                  ".about2-section .about-details"
                ]?.["padding"]?.replace("px", "")}px`,
              },
              ".about2-section .about-details h5": {
                ...settingFormData?.[".about2-section .about-details h5"],
                "font-size": `${settingFormData?.[
                  ".about2-section .about-details h5"
                ]?.["font-size"]?.replace("px", "")}px`,
                "font-weight": `${settingFormData?.[".about2-section .about-details h5"]?.["font-weight"]}`,
                color: settingFormData?.[".about2-section .about-details h5"]?.[
                  "color"
                ]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[
                          ".about2-section .about-details h5"
                        ]?.["color"],
                    })
                  : "",
                "line-height": `${settingFormData?.[".about2-section .about-details h5"]?.["line-height"]}`,
                "margin-bottom": `${settingFormData?.[
                  ".about2-section .about-details h5"
                ]?.["margin-bottom"]?.replace("px", "")}px`,
                "text-align": `${settingFormData?.[".about2-section .about-details h5"]?.["text-align"]}`,
              },
              ".about2-section .about-details h6": {
                ...settingFormData?.[".about2-section .about-details h6"],
                "font-size": `${settingFormData?.[
                  ".about2-section .about-details h6"
                ]?.["font-size"]?.replace("px", "")}px`,
                "font-weight": `${settingFormData?.[".about2-section .about-details h6"]?.["font-weight"]}`,
                color: settingFormData?.[".about2-section .about-details h6"]?.[
                  "color"
                ]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[
                          ".about2-section .about-details h6"
                        ]?.["color"],
                    })
                  : "",
                "line-height": `${settingFormData?.[".about2-section .about-details h6"]?.["line-height"]}`,
                "margin-bottom": `${settingFormData?.[
                  ".about2-section .about-details h6"
                ]?.["margin-bottom"]?.replace("px", "")}px`,
                "text-align": `${settingFormData?.[".about2-section .about-details h6"]?.["text-align"]}`,
              },
              ".about2-section .about-details p": {
                ...settingFormData?.[".about2-section .about-details p"],
                "font-size": `${settingFormData?.[
                  ".about2-section .about-details p"
                ]?.["font-size"]?.replace("px", "")}px`,
                "font-weight": `${settingFormData?.[".about2-section .about-details p"]?.["font-weight"]}`,
                color: settingFormData?.[".about2-section .about-details p"]?.[
                  "color"
                ]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[".about2-section .about-details p"]?.[
                          "color"
                        ],
                    })
                  : "",
                "line-height": `${settingFormData?.[".about2-section .about-details p"]?.["line-height"]}`,
                "margin-bottom": `${settingFormData?.[
                  ".about2-section .about-details p"
                ]?.["margin-bottom"]?.replace("px", "")}px`,
                "text-align": `${settingFormData?.[".about2-section .about-details p"]?.["text-align"]}`,
              },
              ".about2-section .about-details ul": {
                "margin-bottom": `${settingFormData[
                  ".about2-section .about-details ul"
                ]?.["margin-bottom"]?.replace("px", "")}px`,
              },
              ".about2-section .about-details ul li": {
                ...settingFormData?.[".about2-section .about-details ul li"],
                "font-size": `${settingFormData?.[
                  ".about2-section .about-details ul li"
                ]?.["font-size"]?.replace("px", "")}px`,
                "font-weight": `${settingFormData?.[".about2-section .about-details ul li"]?.["font-weight"]}`,
                color: settingFormData?.[
                  ".about2-section .about-details ul li"
                ]?.["color"]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[
                          ".about2-section .about-details ul li"
                        ]?.["color"],
                    })
                  : "",
                margin: `${settingFormData?.[
                  ".about2-section .about-details ul li"
                ]?.["margin"]
                  ?.split(" ")[0]
                  ?.replace("px", "")}px 0`,
              },
              ".about2-section .about-details ul li:before": {
                ...settingFormData?.[
                  ".about2-section .about-details ul li:before"
                ],
                color: settingFormData?.[
                  ".about2-section .about-details ul li:before"
                ]?.["color"]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[
                          ".about2-section .about-details ul li:before"
                        ]?.["color"],
                    })
                  : "",
                "margin-right": `${settingFormData?.[
                  ".about2-section .about-details ul li:before"
                ]?.["margin-right"]?.replace("px", "")}px`,
              },
              ".about2-section .about-details ul li:first-child": {
                "margin-top": `${settingFormData?.[
                  ".about2-section .about-details ul li:first-child"
                ]?.["margin-top"]?.replace("px", "")}px`,
              },
              ".about2-section .about-details .btn-section": {
                "justify-content": `${settingFormData?.[".about2-section .about-details .btn-section"]?.["justify-content"]}`,
              },
              ".about2-section .about-details .btn": {
                ...settingFormData?.[".about2-section .about-details .btn"],
                "background-color": settingFormData?.[
                  ".about2-section .about-details .btn"
                ]?.["background-color"]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[
                          ".about2-section .about-details .btn"
                        ]?.["background-color"],
                    })
                  : "",
                border: `${settingFormData?.[
                  ".about2-section .about-details .btn"
                ]?.["borderPx"]?.replace("px", "")}px ${
                  settingFormData?.[".about2-section .about-details .btn"]?.[
                    "borderThik"
                  ]
                } ${rgbColorCreate({
                  rgbString:
                    settingFormData?.[".about2-section .about-details .btn"]?.[
                      "borderColor"
                    ],
                })}`,
                borderPx: `${settingFormData[
                  ".about2-section .about-details .btn"
                ]?.["borderPx"]?.replace("px", "")}px`,
                borderThik: `${settingFormData?.[".about2-section .about-details .btn"]?.["borderThik"]}`,
                borderColor: settingFormData?.[
                  ".about2-section .about-details .btn"
                ]?.["borderColor"]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[
                          ".about2-section .about-details .btn"
                        ]?.["borderColor"],
                    })
                  : "",
                color: settingFormData?.[
                  ".about2-section .about-details .btn"
                ]?.["color"]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[
                          ".about2-section .about-details .btn"
                        ]?.["color"],
                    })
                  : "",
                padding: `${settingFormData?.[
                  ".about2-section .about-details .btn"
                ]?.["p1"]?.replace("px", "")}px ${settingFormData[
                  ".about2-section .about-details .btn"
                ]?.["p2"]?.replace("px", "")}px`,
                p1: `${settingFormData?.[
                  ".about2-section .about-details .btn"
                ]?.["p1"]?.replace("px", "")}px`,
                p2: `${settingFormData?.[
                  ".about2-section .about-details .btn"
                ]?.["p2"]?.replace("px", "")}px`,
              },
            };
            if (isBackgroundColor) {
              newData[".about2-section"] = {
                ...newData?.[".about2-section"],
                "background-color": settingFormData?.[".about2-section"]?.[
                  "background-color"
                ]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData?.[".about2-section"]?.[
                          "background-color"
                        ],
                    })
                  : "",
              };
            }
            if (boxSection) {
              newData[".about2-section.bg-image::before"] = {
                ...newData[".about2-section.bg-image::before"],
                "border-radius": `${settingFormData?.[".about2-section"]?.[
                  "border-radius"
                ]?.replace("rem", "")}rem`,
              };
            }
          }
          const keysToRemove = ["image"];
          function removeKeys(obj, keys) {
            for (const key in obj) {
              if (typeof obj[key] === "object") {
                removeKeys(obj[key], keys);
              } else if (keys.includes(key)) {
                delete obj[key];
              }
            }
          }
          removeKeys(newData, keysToRemove);
          newData = {
            theme,
            style: newData,
            id: id,
          };
        }

        const { data } = await putUpdateAboutUsParent(newData);
        if (data.status === 200) {
          if (isClose) {
            OpenModalCommonFunc(
              Cases.SETTING_ABOUT_PARENT,
              setAllPopupState,
              false
            );
            emptyDataAboutUs();
            setBackImageToggle(false);
            setBoxSection(false);
            setBackImageToggle(false);
          }
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  /**
   * create new about us
   */
  const hanldenewCreate = () => {
    setFormData({
      title: "",
      aboutUs: "",
      isTitleShow: true,
      order: 1,
      subTitle: "",
      theme: 1,
      buttonText: "",
      buttonLink: "",
    });
    setIsEdit(false);
  };

  /**
   * clear data
   */
  const emptyDataAboutUs = () => {
    setIsBackgroundColor(false);
    setBoxSection(false);
    setIsParent(false);
    setBoxSection(false);
    setBackImageToggle(false);
    // setId("");
    setIsEdit(false);
    setChildId("");
    setLoaderShow(false);
    setFormData({
      title: "",
      aboutUs: "",
      order: 1,
      subTitle: "",
      theme: 1,
      isTitleShow: true,
      buttonText: "",
      buttonLink: "",
    });
    setSettingFormData({});
    // setId("");
    setIsEdit(false);
    setFormDataImageParent({
      title: "",
      subTitle: "",
      navMapId: "",
      isTitleShow: true,
      style: {
        ".about-section": {
          padding: "100px 0",
          "background-color": "rgb(255 255 255 / 100)",
        },
        ".about-section.bg-image": {
          "background-image": "",
        },
        ".about-section.bg-image::before": {
          "background-color": "",
        },
        ".about-section .section-title-area": {
          "margin-bottom": "50px",
        },
        ".about-section .section-title-area .section-subtitle": {
          "font-size": "16px",
          "font-weight": "600",
          color: "rgb(255 73 124 / 100)",
          "background-color": "rgb(255 73 124 / 0.2)",
          padding: "5px 20px",
          p1: "5px",
          p2: "20px",
          "border-radius": "15px",
          "margin-bottom": "15px",
        },
        ".about-section .section-title-area .section-title": {
          "font-size": "44px",
          "font-weight": "600",
          color: "rgb(0 0 0 / 100)",
        },
        ".about-section .about-img": {
          margin: "0 auto",
          display: "block",
        },
        ".about-section .about-details": {
          padding: "20px",
        },
        ".about-section .about-details h5": {
          "font-size": "24px",
          "font-weight": "600",
          color: "rgb(0 0 0 / 100)",
          "line-height": "1.4",
          "margin-bottom": "20px",
          "text-align": "justify",
        },
        ".about-section .about-details p": {
          "font-size": "17px",
          "font-weight": "500",
          color: "rgb(31 39 50 / 1)",
          "line-height": "1.6",
          "margin-bottom": "20px",
          "text-align": "justify",
        },
        ".about-section .about-details ul": {
          "margin-bottom": "30px",
        },
        ".about-section .about-details ul li": {
          "font-size": "16px",
          "font-weight": "500",
          color: "rgb(31 39 50 / 1)",
          margin: "14px 0",
        },
        ".about-section .about-details ul li:before": {
          color: "rgb(255 73 124 / 100)",
          "margin-right": "10px",
        },
        ".about-section .about-details ul li:first-child": {
          "margin-top": "0",
        },
        ".about-section .about-details .btn": {
          "background-color": "rgb(255 73 124 / 100)",
          border: "2px solid rgb(255 73 124 / 100)",
          borderPx: "2px",
          borderThik: "solid",
          borderColor: "rgb(255 73 124 / 100)",
          color: "rgb(255 255 255 / 100)",
          padding: "8px 25px",
          p1: "8px",
          p2: "25px",
        },
      },
    });
    forceUpdate({});
  };

  const handleAlignAndCloneSection = ({ isClone = false }) => {
    if (isClone) {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          cloneModal: true,
        };
      });
      setCloneFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          pageConfigDetailsLabel: pageConfigDetails[0]?.label,
          contentDetailName: contentDetail.name,
        };
      });
    } else {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          alignModal: true,
        };
      });
      setAlignFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          label: pageConfigDetails[0]?.label,
        };
      });
    }
  };
  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody
            className="support-ticket-font box-border-div"
            style={{ padding: "20px" }}
          >
            <CardBuilderHeaderSection
              addBtnTitle={pageConfigDetails[0]?.label}
              name={pageConfigDetails[0]?.label}
              isAdd={!aboutUsDetail && true}
              mainEditSection={true}
              ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
              pageId={pageConfigDetails[0]?.pageId}
              openModal={() => {
                setCollId(contentMapId);
                setSectionPageName(modalsName.ABOUT_US_PARENT);
                setIsParent(true);
                OpenModalCommonFunc(Cases.ABOUT_US_PARENT, setAllPopupState);
              }}
              isCloneSection={true}
              isAlignSection={true}
              handleAlignAndCloneSection={handleAlignAndCloneSection}
            />
            <Row>
              {aboutUsDetail && (
                <Col xl="12 xl-50 box-col-6" md="6">
                  <Card className="ecommerce-widget">
                    <CardBody
                      className="support-ticket-font mb-0"
                      style={{ padding: "20px" }}
                    >
                      <div className="col-12">
                        <CardBuilderHeaderSection
                          name={aboutUsDetail?.title}
                          checked={aboutUsDetail?.isActive}
                          switchToggle={true}
                          id={aboutUsDetail?.id}
                          handleChange={handleChangeStatus}
                        />
                        <OpenPopupBtn
                          openPopup={() => {
                            setCollId(contentMapId);
                            getAboutUsList({
                              parentId: aboutUsDetail.id,
                              sectionTitle: aboutUsDetail?.title,
                            });
                          }}
                          name={`Add or View ${aboutUsDetail?.title}`}
                        />
                      </div>
                      <div className="d-flex justify-content-end ">
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            setCollId(contentMapId);
                            handleEdit(aboutUsDetail.id, true);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => handleDelete(aboutUsDetail.id, true)}
                        >
                          <Trash />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleChangeSettings(aboutUsDetail.id);
                          }}
                        >
                          <Settings />
                        </IconButton>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
              <DeleteSection
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
              />
              {/* <OpenPopupBtn
                openPopup={() =>
                  OpenModalCommonFunc(Cases.ABOUT_US, setAllPopupState)
                }
                name={btnName.ABOUT_US}
              /> */}
            </Row>
          </CardBody>
        </Card>
      </Col>

      {/* about us parent popup  */}
      <CommonPopup
        open={allPopupState.aboutUsParentPopup}
        close={() => {
          OpenModalCommonFunc(Cases.ABOUT_US_PARENT, setAllPopupState, false);
          setIsParent(false);
        }}
        modalName={
          sectionPageName ? sectionPageName : modalsName.ABOUT_US_PARENT
        }
        modalCase={Cases.ABOUT_US_PARENT}
        handleSubmit={handleSubmitWithValidate}
        show={false}
        edit={false}
        emptyData={emptyDataAboutUs}
        inputData={formDataImageParent}
        validationForm={"parentForm"}
        content={
          <AboutUsParentPopup
            emptyData={emptyDataAboutUs}
            simpleValidator={parentValidator}
          />
        }
      />

      <CommonPopup
        open={allPopupState.aboutUsPopup}
        close={() => {
          OpenModalCommonFunc(Cases.ABOUT_US, setAllPopupState, false);
          setIsParent(false);
        }}
        // apiCall={getAboutUsList}
        modalName={sectionPageName ? sectionPageName : modalsName.ABOUTUS}
        modalCase={Cases.ABOUT_US}
        handleSubmit={handleSubmitWithValidate}
        emptyData={emptyDataAboutUs}
        inputData={fromData}
        edit={false}
        validationForm={"childForm"}
        oldTitle={titleUpdateKey.isAboutUs}
        label={titleUpdateKey.About_us}
        getData={getData}
        content={
          <AboutUsPopup
            getAboutUsList={getAboutUsList}
            handleChangeStatus={handleChangeStatus}
            handleSubmit={handleSubmitWithValidate}
            getData={getAboutUsList}
            handleEdit={handleEdit}
            imageUpload={imageUpload}
            emptyData={emptyDataAboutUs}
            simpleValidator={childValidator}
            webMasterDetail={webMasterDetail}
          />
        }
      />

      <CommonPopup
        open={allPopupState.aboutSettingPopup}
        close={() =>
          OpenModalCommonFunc(
            Cases.SETTING_ABOUT_PARENT,
            setAllPopupState,
            false
          )
        }
        modalName={
          sectionPageName ? sectionPageName : modalsName.SETTING_ABOUT_PARENT
        }
        modalCase={Cases.SETTING_ABOUT_PARENT}
        handleSubmit={handleAboutUsSubmitSetting}
        emptyData={emptyDataAboutUs}
        inputData={fromData}
        edit={false}
        content={
          <AboutUsSettingParent
            imageUpload={backgroundImageUpload}
            handleSubmitSetting={handleAboutUsSubmitSetting}
            handleChangeSettings={handleChangeSettings}
          />
        }
      />
    </>
  );
};

export default Aboutus;
