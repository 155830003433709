import React, { useContext, useEffect, useState } from "react";
import { Close } from "@material-ui/icons";
import { IconButton, Tooltip } from "@mui/material";
import ByteCardBuilderContext from "../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc, TostMessage } from "../utils";
import EditIcon from "@mui/icons-material/Edit";
import MuiInput from "@mui/material/Input";
import { Check } from "react-feather";
import { getNavbarList, putLabel } from "../apis/AboutApi/AboutUsApi";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const ModalNameAndClose = (props) => {
  const {
    modalCase,
    name,
    isEdit,
    handleSubmit,
    emptyData,
    inputData,
    dataLength,
    oldTitle,
    labelNav,
    getDataNavbar,
    apiCall = null,
    isObjectParams = false,
    validationForm = "",
    isMini,
  } = props;
  const { setAllPopupState, show, setShow } = useContext(
    ByteCardBuilderContext
  );
  const { setLoaderShow } = useContext(LoaderContext);
  const [label, setLabel] = useState("");
  const [navbarData, setNavbarData] = useState([]);

  const submitFuncAndMessage = () => {
    if (dataLength === 25) {
      TostMessage({
        message: "Cannot add more than 25 Products",
        type: "error",
        position: "custome",
      });
    } else {
      if (validationForm !== "") {
        handleSubmit(validationForm);
      } else {
        handleSubmit();
      }
    }
  };

  const handleEdit = async () => {
    try {
      setLoaderShow(true);
      let formData = {
        label: label,
      };
      const { data } = await putLabel(oldTitle, formData);
      if (data.status === 200) {
        TostMessage({ message: data, type: "success" });
        setLabel("");
        setLoaderShow(false);
        setShow(false);
        getData();
        getDataNavbar();
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const getData = async () => {
    let paramObj = {
      showAll: true,
    };
    try {
      const { data } = await getNavbarList(paramObj);
      if (data.status === 200) {
        let [value] = data.payload.data.filter(
          (elem) => elem.navbarDetail.label === labelNav
        );
        setNavbarData(value);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  useEffect(() => {
    if (isMini) {
      getData();
    }
  }, []);
  return (
    <div
      className="d-flex justify-content-between pt-4 align-items-center position-sticky top-0"
      style={{
        zIndex: "999",
        background: "white",
        paddingBottom: "15px",
        paddingRight: "20px",
        paddingLeft: "20px",
      }}
    >
      <div className="d-flex align-items-center">
        <Tooltip title="Back">
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              OpenModalCommonFunc(
                modalCase,
                setAllPopupState,
                false,
                inputData
              );
              emptyData();
              if (apiCall !== null && isObjectParams === false) {
                apiCall({ isClose: true });
              } else if (apiCall !== null) {
                apiCall({ isClose: true });
              }
            }}
            aria-label="close"
            style={{ background: "#ffc7c7", color: "red" }}
          >
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>
        <h3 className="pe-3 px-3">
          {show === false && (
            <>
              {navbarData?.label ? navbarData?.label : name}
              {isEdit && (
                <IconButton
                  edge="start"
                  color="inherit"
                  style={{ marginLeft: "5px" }}
                  onClick={() => setShow(true)}
                >
                  <EditIcon />
                </IconButton>
              )}
            </>
          )}
          {show && (
            <>
              <MuiInput
                placeholder="Label"
                value={label}
                onChange={(e) => setLabel(e.target.value)}
              />{" "}
              <IconButton
                edge="start"
                color="inherit"
                style={{ marginLeft: "5px", height: "40px" }}
                onClick={() => handleEdit()}
              >
                <span style={{ fontSize: "20px" }}>✓</span>
              </IconButton>
              <IconButton
                edge="start"
                color="inherit"
                style={{ marginLeft: "5px", height: "40px" }}
                onClick={() => setShow(false)}
              >
                <span style={{ fontSize: "20px" }}>✕</span>
              </IconButton>
            </>
          )}
        </h3>
      </div>
      <div className="d-flex gap-4 ">
        <Tooltip title="close">
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              OpenModalCommonFunc(
                modalCase,
                setAllPopupState,
                false,
                emptyData,
                inputData
              );
              if (apiCall !== null && isObjectParams === false) {
                apiCall({ isClose: true });
              } else if (apiCall !== null) {
                apiCall({ isClose: true });
              }
            }}
            aria-label="close"
            style={{ background: "#ffc7c7", color: "red" }}
          >
            <Close />
          </IconButton>
        </Tooltip>
        <Tooltip title="Submit">
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => submitFuncAndMessage()}
            aria-label="close"
            style={{ background: "#58b958", color: "white" }}
          >
            <Check />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

export default ModalNameAndClose;
