import React, { useState } from "react";
import ProductBuyContext from "./ProductBuyContext";
import {
  productBuyCheckOutStyle,
  productBuyMyAccountStyle,
  productBuyStyle,
} from "../../constant";

const ProductBuyState = (props) => {
  const [formData, setFormData] = useState({
    title: "",
    contentMapId: "",
    style: productBuyStyle,
    checkOutStyle: productBuyCheckOutStyle,
    myAccountStyle: productBuyMyAccountStyle,
  });
  const [formDataChild, setFormDataChild] = useState({
    title: "",
    webId: localStorage.getItem("webId"),
    navMapId: "",
  });
  const [collId, setCollId] = useState();
  const [settingFormData, setSettingFormData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [isParent, setIsParent] = useState(false);
  const [filter, setFilter] = useState("");
  const [navMapId, setNavMapId] = useState("");
  const [id, setId] = useState("");
  const [nameOfStyle, setNameOfStyle] = useState("");
  const [theme, setTheme] = useState(false);
  const [imgShow, setImgShow] = useState(false);
  const [sectionPageName, setSectionPageName] = useState("");
  return (
    <ProductBuyContext.Provider
      value={{
        isEdit,
        setIsEdit,
        isParent,
        setIsParent,
        filter,
        setFilter,
        settingFormData,
        setSettingFormData,
        formDataChild,
        setFormDataChild,
        formData,
        setFormData,
        collId,
        setCollId,
        navMapId,
        setNavMapId,
        id,
        setId,
        nameOfStyle,
        setNameOfStyle,
        theme,
        setTheme,
        imgShow,
        setImgShow,
        sectionPageName,
        setSectionPageName,
      }}
    >
      {props.children}
    </ProductBuyContext.Provider>
  );
};

export default ProductBuyState;
