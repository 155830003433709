import { Add } from "@mui/icons-material";
// import { DataGrid } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";
import React, { useContext } from "react";
import { Card, CardHeader, Col, Container, Row } from "reactstrap";
import { INCENTIVE, Style } from "../../constant";
import Box from "@mui/material/Box";
import { MuiTabelStyle } from "../../utils/MuiTabelStyle";
import { IconButton, Tooltip } from "@mui/material";

import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import BrokerIncentiveContext from "../../context/BrokerIncentiveContext/BrokerIncentiveContext";
import {
  DeleteBrokerIncentive,
  EditBrokerIncentive,
} from "../../api/Organization Api/OrganizationApi";
import { toast } from "react-toastify";

const BrokerIncentiveItem = (props) => {
  const { getBrokerIncentiveList } = props;
  const {
    modal,
    setModal,
    brokerIncentiveLists,
    setIsEdit,
    setBrokerIncentiveValue,
    setEditId,
    page,
    setPage,
    pageSize,
    setPageSize,
    record,
  } = useContext(BrokerIncentiveContext);

  const AddBrokerIncentive = () => {
    setModal(!modal);
  };

  const defaultColumns = [
    {
      field: "brokerIncentiveId",
      headerName: "ID",
      width: 130,
      editable: true,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "pageTitle",
      headerName: "Broker",
      flex: 0.1,
      minWidth: 100,
      renderCell: (params) =>
        params.row.brokerDetail
          ? params.row.brokerDetail.pageTitle
          : params.row.brokerDetail,
    },
    {
      field: "page",
      headerName: "Project",
      flex: 0.1,
      minWidth: 100,
      renderCell: (params) =>
        params.row.pageDetail
          ? params.row.pageDetail.pageTitle
          : params.row.pageDetail,
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      flex: 0.1,
      minWidth: 100,
      editable: true,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "customerNumber",
      headerName: "Customer Number",
      flex: 0.1,
      minWidth: 100,
      editable: true,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "incentive",
      headerName: "Incentive",
      flex: 0.1,
      minWidth: 100,
      editable: true,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Tooltip title="Edit">
          <IconButton
            aria-label="edit"
            style={{ color: "green" }}
            onClick={() => EditHandle(params.row.brokerIncentiveId)}
          >
            <ModeEditIcon />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Tooltip title="Edit">
          <IconButton
            aria-label="edit"
            style={{ color: "red" }}
            onClick={() => DeleteHandle(params.row.brokerIncentiveId)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  const DeleteHandle = async (ele) => {
    const result = window.confirm(
      "Are you sure you want to delete the broker page?"
    );
    if (result === true) {
      try {
        let formData = {
          brokerIncentiveId: ele,
        };
        const { data } = await DeleteBrokerIncentive(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
          });
          getBrokerIncentiveList();
        }
      } catch (error) {
        toast.error(error.response.data.message, {
          autoClose: 1000,
        });
      }
    }
  };

  const EditHandle = async (element) => {
    let formData = { brokerIncentiveId: element };
    try {
      const { data } = await EditBrokerIncentive(formData);
      if (data.status === 200) {
        const prevData = data.payload.data;
        setBrokerIncentiveValue({
          pageId: prevData.pageId,
          brokerId: prevData.brokerId,
          customerName: prevData.customerName,
          customerNumber: prevData.customerNumber,
          propertyDetail: prevData.propertyDetail,
          incentive: prevData.incentive,
        });
        setEditId(element);

        setIsEdit(true);
        setModal(true);
      }
    } catch (error) {}
  };

  return (
    <>
      <Container fluid={true} style={{ paddingTop: "40px" }}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h3>{INCENTIVE.BROKER_INCENTIVE}</h3>
                <div
                  aria-label="add"
                  onClick={() => AddBrokerIncentive()}
                  className="btn btn-primary"
                  style={Style}
                >
                  <Add />
                </div>
              </CardHeader>

              <Box sx={{ width: "100%" }}>
                {brokerIncentiveLists && (
                  <DataGrid
                    autoHeight
                    pagination
                    sx={MuiTabelStyle}
                    rows={brokerIncentiveLists}
                    getRowId={(rows) => rows.brokerIncentiveId}
                    columns={defaultColumns}
                    rowsPerPageOptions={[7, 10, 25, 50]}
                    rowCount={record}
                    page={page - 1}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    onPageChange={(newPageNo) => setPage(newPageNo + 1)}
                    paginationMode="server"
                    disableSelectionOnClick
                  />
                )}
              </Box>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default BrokerIncentiveItem;
