import React, { useContext } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { PRODUCTS_TYPE } from "../../../../constant";
import TagsContext from "../../../../context/MenuMasterContext/TagsContext/TagsContext";

const TagsAddPopup = (props) => {
  const { handleSubmit, simpleValidator, emptyData } = props;
  const { addModel, setAddModel, formData, setFormData, isEdit } =
    useContext(TagsContext);

  const CloseModel = () => {
    emptyData();
    setAddModel(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  return (
    <>
      <Modal size="xl" isOpen={addModel} toggle={() => CloseModel()}>
        <ModalHeader toggle={() => CloseModel()}>
          {" "}
          {isEdit ? "Edit" : "Add"} Tags
        </ModalHeader>
        <ModalBody>
          <Container fluid={true} className="p-0">
            <Row>
              <Form className="theme-form" onSubmit={(e) => handleSubmit(e)}>
                <Row>
                  <Col md="6 mb-4">
                    <Label>{"Tag"}</Label>
                    <Input
                      className="form-control"
                      type={"text"}
                      style={{ color: "black" }}
                      name="tag"
                      onChange={(e) => handleChange(e)}
                      value={formData.tag}
                      placeholder="Tag"
                    />
                    <p style={{ color: "red" }}>
                      {simpleValidator.current.message(
                        "tag",
                        formData.tag,
                        "required"
                      )}
                    </p>
                  </Col>
                  <Col md="6 mb-4">
                    <Label htmlFor="validationCustom03">{"Type"}</Label>
                    <select
                      name="type"
                      id="font-weight"
                      className="form-select"
                      value={formData.type}
                      onChange={(e) => handleChange(e)}
                      style={{ color: "black" }}
                    >
                      <option value="">--select--</option>
                      {PRODUCTS_TYPE.map((elem) => {
                        return (
                          <option value={elem["value"]} key={elem["key"]}>
                            {elem["key"]}
                          </option>
                        );
                      })}
                    </select>
                  </Col>
                </Row>

                <div className="login-btn mt-2">
                  <Button color="primary" type="submit" value="submit">
                    {"Submit"}
                  </Button>
                </div>
              </Form>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};

export default TagsAddPopup;
