import React, { useState, useContext } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  Label,
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";
import { toast, Zoom } from "react-toastify";
import jwtImg from "../assets/images/jwt.svg";

import {
  EmailAddress,
  JWT,
  // LoginWithJWT,
  ENDPOINT_IMAGE,
} from "../constant";
import { useNavigate, useParams } from "react-router";
import { forgotPassword_ } from "../api/Login Api/LoginApi";
import { Toast } from "./signinToast";
import { LoaderResponse } from "../Spinner";
import LoaderContext from "../context/LoaderContext/LoaderContext";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [selected, setSelected] = useState("jwt");
  const history = useNavigate();

  const { loaderShow, setLoaderShow } = useContext(LoaderContext);

  let { hwId } = useParams();

  const login = async (e) => {
    e.preventDefault();
    setLoaderShow(!loaderShow);
    let fromData = {
      email: email,
    };

    try {
      const { data } = await forgotPassword_(fromData);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        setLoaderShow(false);
        history(`/${hwId}`);
      } else {
        Toast.fire({
          icon: "error",
          title: data.message,
        });
        setLoaderShow(false);
      }
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: error.response.data.message,
      });
      setLoaderShow(false);
    }
  };
  return (
    <>
      <Container fluid={true} className="p-0">
        <div style={{ position: "relative" }}>
          <LoaderResponse />
          <Row>
            <Col xs="12">
              <div className="login-card">
                <div>
                  <div>
                    <a className="logo" href="index.html">
                      <img
                        className="img-fluid for-light"
                        src={`${process.env.REACT_APP_BUCKET_URL}${ENDPOINT_IMAGE}`}
                        style={{ height: "50px" }}
                        alt=""
                      />

                      <img
                        className="img-fluid for-dark"
                        src={`${process.env.REACT_APP_BUCKET_URL}${ENDPOINT_IMAGE}`}
                        style={{ height: "50px" }}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="login-main login-tab">
                    <Nav className="border-tab flex-column" tabs>
                      <NavItem className="d-none ">
                        <NavLink
                          className={selected === "jwt" ? "active" : ""}
                          onClick={() => setSelected("jwt")}
                        >
                          <img src={jwtImg} alt="" />
                          <span>{JWT}</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent
                      activeTab={selected}
                      className="content-login"
                      style={{ height: "auto" }}
                    >
                      <TabPane className="fade show" tabId="jwt">
                        <Form className="theme-form" onSubmit={(e) => login(e)}>
                          <h4 className="text-center ">
                            {selected === "firebase"
                              ? "Forgot Password"
                              : "Forgot Password"}
                          </h4>
                          <p>
                            {
                              "Please enter the registered email address to receive instructions for resetting your password"
                            }
                          </p>
                          <div className="mb-5 mt-5">
                            <Label className="col-form-label">
                              {"Enter Registered Email ID"}
                            </Label>
                            <Input
                              className="form-control"
                              type="email"
                              onChange={(e) => setEmail(e.target.value)}
                              defaultValue={email}
                              placeholder="Enter a valid Email"
                            />
                          </div>
                          <div className="login-btn mb-0 mb-3 position-relative text-center">
                            <Button
                              color="primary"
                              type="submit"
                              value="submit"
                            >
                              {/* <Link
                                to={
                                  "/forgot-password"
                                }
                                style={{ color: "white" }}
                              > */}
                              {"Request Reset Link"}
                              {/* </Link> */}
                            </Button>
                          </div>
                        </Form>
                      </TabPane>
                    </TabContent>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

// export default withRouter(Logins);
export default ForgotPassword;
