import React, { useContext } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import { Cases, modalsName } from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import CommonPopup from "../../../Modals/commonPopup";
import ProductBuyContext from "../../../context/ProductBuyContext/ProductBuyContext";
import SettingStyle from "./SettingStyle";
import SettingCheckOutStyle from "./SettingCheckOutStyle";
import SettingMyAccountStyle from "./SettingMyAccountStyle";
import {
  getCartPageStyle,
  getCheckOutStyle,
  getMyAccountStyle,
  getProductPageStyle,
  getProductStyle,
} from "./GetStyleProductBuy";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import {
  ProducPageStyle,
  ProductBuyStyleOne,
  addCartPageStyle,
  addChecKOutStyle,
  addMyAccountStyle,
  addProductPageStyle,
  addProductStyle,
  addProductStyleOne,
  cartPageStyle,
} from "./AllStyleOfProductBuy";
import {
  getProductBuyDetails,
  putUpdateProductBuy,
} from "../apis/ProductBuyApi/ProductBuyApi";
import { ThemeNo } from "../../../../constant";
import { isEmptyObject } from "../../../../utils/helper";
import SettingProductPage from "./SettingProductPage";
import SettingCartPage from "./SettingCartPage";

const SettingProductBuyPopup = (props) => {
  const { emptyData, productBuyId, ByteMiniWeb_Dashboard } = props;
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );
  const { setLoaderShow } = useContext(LoaderContext);
  const {
    settingFormData,
    setSettingFormData,
    id,
    isEdit,
    setIsEdit,
    setId,
    nameOfStyle,
    setNameOfStyle,
    theme,
    setTheme,
    setImgShow,
  } = useContext(ProductBuyContext);

  const handleSettingStyle = async (styleName) => {
    try {
      setLoaderShow(true);
      setId(productBuyId);
      const { data } = await getProductBuyDetails(productBuyId);
      if (data.status == 200) {
        const themeCount = data?.payload?.data?.theme;
        setTheme(themeCount ? themeCount : 1);
        setImgShow(true);
        let payload = data.payload.data;
        let getStyle;
        const styleObj = {
          style: () => {
            if (
              +themeCount === ThemeNo.ThemeOne &&
              isEmptyObject(payload?.style)
            ) {
              getStyle = { ...ProductBuyStyleOne };
              OpenModalCommonFunc(
                Cases.SETTING_PRODUCT_BUY_PARENT_STYLE,
                setAllPopupState
              );
            } else {
              if (+themeCount === ThemeNo.ThemeOne) {
                getStyle = getProductStyle(payload.style);
              } else {
                getStyle = getProductStyle(payload.style);
              }
              OpenModalCommonFunc(
                Cases.SETTING_PRODUCT_BUY_PARENT_STYLE,
                setAllPopupState
              );
            }
          },
          productPageStyle: () => {
            if (isEmptyObject(payload?.productPageStyle || {})) {
              getStyle = { ...ProducPageStyle };
              OpenModalCommonFunc(
                Cases.SETTING_PRODUCT_BUY_PARENT_PAGE,
                setAllPopupState
              );
            } else {
              getStyle = getProductPageStyle(payload?.productPageStyle);
              OpenModalCommonFunc(
                Cases.SETTING_PRODUCT_BUY_PARENT_PAGE,
                setAllPopupState
              );
            }
          },
          cartPageStyle: () => {
            if (isEmptyObject(payload?.cartPageStyle || {})) {
              getStyle = { ...cartPageStyle };
              OpenModalCommonFunc(
                Cases.SETTING_PRODUCT_BUY_PARENT_CART_PAGE,
                setAllPopupState
              );
            } else {
              getStyle = getCartPageStyle(payload?.cartPageStyle);
              OpenModalCommonFunc(
                Cases.SETTING_PRODUCT_BUY_PARENT_CART_PAGE,
                setAllPopupState
              );
            }
          },
          checkOutStyle: () => {
            getStyle = getCheckOutStyle(payload.checkOutStyle);
            OpenModalCommonFunc(
              Cases.SETTING_PRODUCT_BUY_PARENT_CHECk_OUT_STYLE,
              setAllPopupState
            );
          },
          myAccountStyle: () => {
            getStyle = getMyAccountStyle(payload.myAccountStyle);
            OpenModalCommonFunc(
              Cases.SETTING_PRODUCT_BUY_PARENT_MY_ACCOUNT_STYLE,
              setAllPopupState
            );
          },
        };

        await styleObj[styleName]();
        setNameOfStyle(styleName);
        setSettingFormData({ ...getStyle });
        setIsEdit(true);
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const handleSubmitSetting = async (isRestore = false, isClose = true) => {
    if (id >= -1 && isEdit) {
      setLoaderShow(true);
      try {
        let newData;
        if (isRestore) {
          const isConfirm = window.confirm(
            "Are you sure want to restore style ?"
          );
          if (isConfirm) {
            if (nameOfStyle == "style") {
              newData = {
                isStyleRestore: true,
              };
            } else if (nameOfStyle == "checkOutStyle") {
              newData = {
                isCheckOutStyleRestore: true,
              };
            } else if (nameOfStyle == "myAccountStyle") {
              newData = {
                isMyAccountStyleRestore: true,
              };
            }
            newData["productBuyId"] = id;
          } else {
            setLoaderShow(false);
            return;
          }
        } else {
          if (nameOfStyle == "style") {
            if (+theme === ThemeNo.ThemeOne) {
              newData = await addProductStyleOne(settingFormData);
            }
          } else if (nameOfStyle == "productPageStyle") {
            newData = await addProductPageStyle(settingFormData);
          } else if (nameOfStyle == "cartPageStyle") {
            newData = await addCartPageStyle(settingFormData);
          } else if (nameOfStyle == "checkOutStyle") {
            newData = await addChecKOutStyle(settingFormData);
          } else if (nameOfStyle == "myAccountStyle") {
            newData = await addMyAccountStyle(settingFormData);
          }
          const keysToRemove = [
            "bs1",
            "bs2",
            "bs3",
            "bs4",
            "borderPx",
            "borderThik",
            "p1",
            "p2",
            "m1",
            "m2",
            "m3",
            "m4",
            "image",
          ];
          function removeKeys(obj, keys) {
            for (const key in obj) {
              if (typeof obj[key] === "object") {
                removeKeys(obj[key], keys);
              } else if (keys.includes(key)) {
                delete obj[key];
              }
            }
          }
          removeKeys(newData, keysToRemove);

          if (nameOfStyle == "style") {
            newData = {
              style: newData,
            };
          } else if (nameOfStyle == "productPageStyle") {
            newData = {
              productPageStyle: newData,
            };
          } else if (nameOfStyle == "cartPageStyle") {
            newData = {
              cartPageStyle: newData,
            };
          } else if (nameOfStyle == "checkOutStyle") {
            newData = {
              checkOutStyle: newData,
            };
          } else if (nameOfStyle == "myAccountStyle") {
            newData = {
              myAccountStyle: newData,
            };
          }

          newData["productBuyId"] = id;
        }
        const { data } = await putUpdateProductBuy(newData);
        if (data.status === 200) {
          ByteMiniWeb_Dashboard();
          if (isClose) {
            if (nameOfStyle == "style") {
              OpenModalCommonFunc(
                Cases.SETTING_PRODUCT_BUY_PARENT_STYLE,
                setAllPopupState,
                false
              );
            } else if (nameOfStyle == "productPageStyle") {
              OpenModalCommonFunc(
                Cases.SETTING_PRODUCT_BUY_PARENT_PAGE,
                setAllPopupState,
                false
              );
            } else if (nameOfStyle == "cartPageStyle") {
              OpenModalCommonFunc(
                Cases.SETTING_PRODUCT_BUY_PARENT_CART_PAGE,
                setAllPopupState,
                false
              );
            } else if (nameOfStyle == "checkOutStyle") {
              OpenModalCommonFunc(
                Cases.SETTING_PRODUCT_BUY_PARENT_CHECk_OUT_STYLE,
                setAllPopupState,
                false
              );
            } else if (nameOfStyle == "myAccountStyle") {
              OpenModalCommonFunc(
                Cases.SETTING_PRODUCT_BUY_PARENT_MY_ACCOUNT_STYLE,
                setAllPopupState,
                false
              );
            }
            emptyData();
          }
          setLoaderShow(false);
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        console.log("error >> ", error);

        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="12">
            <div className="mt-4 d-flex flex-column justify-content-center ">
              <div>
                <div className="my-3">
                  <Button onClick={() => handleSettingStyle("style")}>
                    Product Style
                  </Button>
                </div>
                <div className="my-3">
                  <Button
                    onClick={() => handleSettingStyle("productPageStyle")}
                  >
                    Product Page Style
                  </Button>
                </div>
                <div className="my-3">
                  <Button onClick={() => handleSettingStyle("cartPageStyle")}>
                    Cart Page Style
                  </Button>
                </div>
                <div className="my-3">
                  <Button onClick={() => handleSettingStyle("checkOutStyle")}>
                    Check Out Page Style
                  </Button>
                </div>
                <div className="my-3">
                  <Button onClick={() => handleSettingStyle("myAccountStyle")}>
                    My Account Style
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <CommonPopup
        open={allPopupState.productBuySettingPopupStyle}
        close={() =>
          OpenModalCommonFunc(
            Cases.SETTING_PRODUCT_BUY_PARENT_STYLE,
            setAllPopupState,
            false
          )
        }
        modalName={modalsName.SETTING_PRODUCT_BUY_PARENT_STYLE}
        modalCase={Cases.SETTING_PRODUCT_BUY_PARENT_STYLE}
        emptyData={emptyData}
        handleSubmit={handleSubmitSetting}
        inputData={settingFormData}
        edit={false}
        content={
          <SettingStyle
            handleSettingStyle={handleSettingStyle}
            handleSubmitSetting={handleSubmitSetting}
          />
        }
      />
      <CommonPopup
        open={allPopupState.productBuySettingPopupCheckOutStyle}
        close={() =>
          OpenModalCommonFunc(
            Cases.SETTING_PRODUCT_BUY_PARENT_CHECk_OUT_STYLE,
            setAllPopupState,
            false
          )
        }
        modalName={modalsName.SETTING_PRODUCT_BUY_PARENT_CHECk_OUT_STYLE}
        modalCase={Cases.SETTING_PRODUCT_BUY_PARENT_CHECk_OUT_STYLE}
        emptyData={emptyData}
        handleSubmit={handleSubmitSetting}
        inputData={settingFormData}
        edit={false}
        content={
          <SettingCheckOutStyle handleSubmitSetting={handleSubmitSetting} />
        }
      />
      <CommonPopup
        open={allPopupState.productBuySettingPopupMyAccountStyle}
        close={() =>
          OpenModalCommonFunc(
            Cases.SETTING_PRODUCT_BUY_PARENT_MY_ACCOUNT_STYLE,
            setAllPopupState,
            false
          )
        }
        modalName={modalsName.SETTING_PRODUCT_BUY_PARENT_MY_ACCOUNT_STYLE}
        modalCase={Cases.SETTING_PRODUCT_BUY_PARENT_MY_ACCOUNT_STYLE}
        emptyData={emptyData}
        handleSubmit={handleSubmitSetting}
        inputData={settingFormData}
        edit={false}
        content={
          <SettingMyAccountStyle handleSubmitSetting={handleSubmitSetting} />
        }
      />
      <CommonPopup
        open={allPopupState.productBuySettingPopupPageStyle}
        close={() =>
          OpenModalCommonFunc(
            Cases.SETTING_PRODUCT_BUY_PARENT_PAGE,
            setAllPopupState,
            false
          )
        }
        modalName={modalsName.SETTING_PRODUCT_BUY_PARENT_PAGE}
        modalCase={Cases.SETTING_PRODUCT_BUY_PARENT_PAGE}
        emptyData={emptyData}
        handleSubmit={handleSubmitSetting}
        inputData={settingFormData}
        edit={false}
        content={
          <SettingProductPage handleSubmitSetting={handleSubmitSetting} />
        }
      />
      <CommonPopup
        open={allPopupState.productBuySettingPopupCartPageStyle}
        close={() =>
          OpenModalCommonFunc(
            Cases.SETTING_PRODUCT_BUY_PARENT_CART_PAGE,
            setAllPopupState,
            false
          )
        }
        modalName={modalsName.SETTING_PRODUCT_BUY_PARENT_CART_PAGE}
        modalCase={Cases.SETTING_PRODUCT_BUY_PARENT_CART_PAGE}
        emptyData={emptyData}
        handleSubmit={handleSubmitSetting}
        inputData={settingFormData}
        edit={false}
        content={<SettingCartPage handleSubmitSetting={handleSubmitSetting} />}
      />
    </>
  );
};

export default SettingProductBuyPopup;
