import {
  addUrlImage,
  convertRGBA,
  removeUrlImage,
  removeUrlKeyInPath,
  rgbColorCreate,
} from "../../../../utils/helper";

export const SerCountThemeOneFunction = (payload) => {
  return {
    ".counter-section .section-title-area": {
      "margin-bottom":
        payload.style?.[".counter-section .section-title-area"]?.[
          "margin-bottom"
        ],
    },
    ".counter-section .section-title-area .section-subtitle": {
      "font-size":
        payload.style?.[
          ".counter-section .section-title-area .section-subtitle"
        ]?.["font-size"],
      "font-weight":
        payload.style?.[
          ".counter-section .section-title-area .section-subtitle"
        ]?.["font-weight"],
      color: payload.style?.[
        ".counter-section .section-title-area .section-subtitle"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".counter-section .section-title-area .section-subtitle"
              ]?.["color"],
          })
        : "",
      "background-color": payload.style?.[
        ".counter-section .section-title-area .section-subtitle"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".counter-section .section-title-area .section-subtitle"
              ]?.["background-color"],
          })
        : "",
      padding:
        payload.style?.[
          ".counter-section .section-title-area .section-subtitle"
        ]?.["padding"],
      p1: payload.style?.[
        ".counter-section .section-title-area .section-subtitle"
      ]?.["padding"]?.split(" ")[0],
      p2: payload.style?.[
        ".counter-section .section-title-area .section-subtitle"
      ]?.["padding"]?.split(" ")[1],
      "border-radius":
        payload.style?.[
          ".counter-section .section-title-area .section-subtitle"
        ]?.["border-radius"],
      "margin-bottom":
        payload.style?.[
          ".counter-section .section-title-area .section-subtitle"
        ]?.["margin-bottom"],
    },
    ".counter-section .section-title-area .section-title": {
      "font-size":
        payload.style?.[
          ".counter-section .section-title-area .section-title"
        ]?.["font-size"],
      "font-weight":
        payload.style?.[
          ".counter-section .section-title-area .section-title"
        ]?.["font-weight"],
      color: payload.style?.[
        ".counter-section .section-title-area .section-title"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".counter-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".counter-section .countdiv": {
      "text-align":
        payload.style?.[".counter-section .countdiv"]?.["text-align"],
      "margin-bottom":
        payload.style?.[".counter-section .countdiv"]?.["margin-bottom"],
    },
    ".counter-section .countdiv .counter": {
      color: payload.style?.[".counter-section .countdiv .counter"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".counter-section .countdiv .counter"]?.["color"],
          })
        : "",
      "font-size":
        payload.style?.[".counter-section .countdiv .counter"]?.["font-size"],
      "font-weight":
        payload.style?.[".counter-section .countdiv .counter"]?.["font-weight"],
    },
    ".counter-section .countdiv .divider": {
      width: payload.style?.[".counter-section .countdiv .divider"]?.["width"],
      height:
        payload.style?.[".counter-section .countdiv .divider"]?.["height"],
      margin:
        payload.style?.[".counter-section .countdiv .divider"]?.["margin"],
      "border-radius":
        payload.style?.[".counter-section .countdiv .divider"]?.[
          "border-radius"
        ],
      "background-color": payload.style?.[
        ".counter-section .countdiv .divider"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".counter-section .countdiv .divider"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".counter-section .countdiv .counter-title": {
      color: payload.style?.[".counter-section .countdiv .counter-title"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".counter-section .countdiv .counter-title"]?.[
                "color"
              ],
          })
        : "",
      "font-size":
        payload.style?.[".counter-section .countdiv .counter-title"]?.[
          "font-size"
        ],
      "font-weight":
        payload.style?.[".counter-section .countdiv .counter-title"]?.[
          "font-weight"
        ],
    },
  };
};

export const SerCountThemeTwoFunction = (payload) => {
  return {
    ".counter1-section .countdiv": {
      "padding-left":
        payload.style?.[".counter1-section .countdiv"]?.["padding-left"],
      margin: payload.style?.[".counter1-section .countdiv"]?.["margin"],
    },
    ".counter1-section .countdiv .counternumb": {
      "font-size":
        payload.style?.[".counter1-section .countdiv .counternumb"]?.[
          "font-size"
        ],
      "font-weight":
        payload.style?.[".counter1-section .countdiv .counternumb"]?.[
          "font-weight"
        ],
      "line-height":
        payload.style?.[".counter1-section .countdiv .counternumb"]?.[
          "line-height"
        ],
      color: payload.style?.[".counter1-section .countdiv .counternumb"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".counter1-section .countdiv .counternumb"]?.[
                "color"
              ],
          })
        : "",
    },
    ".counter1-section .countdiv .counter-title": {
      "font-size":
        payload.style?.[".counter1-section .countdiv .counter-title"]?.[
          "font-size"
        ],
      "font-weight":
        payload.style?.[".counter1-section .countdiv .counter-title"]?.[
          "font-weight"
        ],
      "line-height":
        payload.style?.[".counter1-section .countdiv .counter-title"]?.[
          "line-height"
        ],
      color: payload.style?.[".counter1-section .countdiv .counter-title"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".counter1-section .countdiv .counter-title"]?.[
                "color"
              ],
          })
        : "",
    },
  };
};

export const SerCountOneFunction = ({ settingFormData, boxSection }) => {
  let newData = {
    ...settingFormData,
    ".counter-section": boxSection
      ? {
          ...settingFormData[".counter-section"],
          padding: `${settingFormData[".counter-section"]["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
          width: `calc(100% - ${settingFormData[".counter-section"][
            "width"
          ]?.replace("rem)", "")}rem)`,
          margin: `${settingFormData[".counter-section"]["margin"]
            ?.split(" ")[0]
            ?.replace("px", "")}px auto`,
          "border-radius": `${settingFormData[".counter-section"][
            "border-radius"
          ]?.replace("rem", "")}rem`,
        }
      : {
          ...settingFormData[".counter-section"],
          padding: `${settingFormData[".counter-section"]["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
        },
    ".counter-section.bg-image": settingFormData[".counter-section.bg-image"][
      "background-image"
    ]
      ? {
          "background-image": settingFormData[".counter-section.bg-image"][
            "background-image"
          ]
            ? addUrlImage({
                urlString: removeUrlImage({
                  urlString:
                    settingFormData[".counter-section.bg-image"][
                      "background-image"
                    ],
                }),
              })
            : "",
        }
      : {},
    ".counter-section.bg-image::before": settingFormData[
      ".counter-section.bg-image::before"
    ]["background-color"]
      ? {
          "background-color": settingFormData[
            ".counter-section.bg-image::before"
          ]["background-color"]
            ? rgbColorCreate({
                rgbString:
                  settingFormData[".counter-section.bg-image::before"][
                    "background-color"
                  ],
              })
            : "",
        }
      : {},
    ".counter-section .section-title-area": {
      ...settingFormData[".counter-section .section-title-area"],
      "margin-bottom": `${settingFormData[
        ".counter-section .section-title-area"
      ]["margin-bottom"]?.replace("px", "")}px`,
    },
    ".counter-section .section-title-area .section-subtitle": {
      ...settingFormData[
        ".counter-section .section-title-area .section-subtitle"
      ],
      "font-size": `${settingFormData[
        ".counter-section .section-title-area .section-subtitle"
      ]["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".counter-section .section-title-area .section-subtitle"]["font-weight"]}`,

      color: settingFormData[
        ".counter-section .section-title-area .section-subtitle"
      ]["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".counter-section .section-title-area .section-subtitle"
              ]["color"],
          })
        : "",
      "background-color": settingFormData[
        ".counter-section .section-title-area .section-subtitle"
      ]["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".counter-section .section-title-area .section-subtitle"
              ]["background-color"],
          })
        : "",
      padding: `${settingFormData[
        ".counter-section .section-title-area .section-subtitle"
      ]["p1"]?.replace("px", "")}px ${settingFormData[
        ".counter-section .section-title-area .section-subtitle"
      ]["p2"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData[
        ".counter-section .section-title-area .section-subtitle"
      ]["border-radius"]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData[
        ".counter-section .section-title-area .section-subtitle"
      ]["margin-bottom"]?.replace("px", "")}px`,
    },
    ".counter-section .section-title-area .section-title": {
      ...settingFormData[".counter-section .section-title-area .section-title"],
      "font-size": `${settingFormData[
        ".counter-section .section-title-area .section-title"
      ]["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".counter-section .section-title-area .section-title"]["font-weight"]}`,
      color: settingFormData[
        ".counter-section .section-title-area .section-title"
      ]["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".counter-section .section-title-area .section-title"
              ]["color"],
          })
        : "",
    },
    ".counter-section .countdiv": {
      ...settingFormData[".counter-section .countdiv"],
      "text-align": `${settingFormData[".counter-section .countdiv"]["text-align"]}`,
      "margin-bottom": `${settingFormData[".counter-section .countdiv"][
        "margin-bottom"
      ]?.replace("px", "")}px`,
    },
    ".counter-section .countdiv .counter": {
      ...settingFormData[".counter-section .countdiv .counter"],
      color: settingFormData[".counter-section .countdiv .counter"]["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".counter-section .countdiv .counter"]["color"],
          })
        : "",
      "font-size": `${settingFormData[".counter-section .countdiv .counter"][
        "font-size"
      ]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".counter-section .countdiv .counter"]["font-weight"]}`,
    },
    ".counter-section .countdiv .divider": {
      ...settingFormData[".counter-section .countdiv .divider"],
      width: `${settingFormData[".counter-section .countdiv .divider"][
        "width"
      ]?.replace("px", "")}px`,
      height: `${settingFormData[".counter-section .countdiv .divider"][
        "height"
      ]?.replace("px", "")}px`,
      margin: `${settingFormData[".counter-section .countdiv .divider"][
        "margin"
      ]
        ?.split(" ")[0]
        ?.replace("px", "")}px auto`,
      "border-radius": `${settingFormData[
        ".counter-section .countdiv .divider"
      ]["border-radius"]?.replace("px", "")}px`,
      "background-color": settingFormData[
        ".counter-section .countdiv .divider"
      ]["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".counter-section .countdiv .divider"][
                "background-color"
              ],
          })
        : "",
    },
    ".counter-section .countdiv .counter-title": {
      ...settingFormData[".counter-section .countdiv .counter-title"],
      color: settingFormData[".counter-section .countdiv .counter-title"][
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".counter-section .countdiv .counter-title"][
                "color"
              ],
          })
        : "",
      "font-size": `${settingFormData[
        ".counter-section .countdiv .counter-title"
      ]["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".counter-section .countdiv .counter-title"]["font-weight"]}`,
    },
  };
  return newData;
};
export const SerCountTwoFunction = ({ settingFormData, boxSection }) => {
  let newData = {
    ...settingFormData,
    ".counter1-section": boxSection
      ? {
          ...settingFormData[".counter1-section"],
          padding: `${settingFormData[".counter1-section"]["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
          width: `calc(100% - ${settingFormData[".counter1-section"][
            "width"
          ]?.replace("rem)", "")}rem)`,
          margin: `${settingFormData[".counter1-section"]["margin"]
            ?.split(" ")[0]
            ?.replace("px", "")}px auto`,
          "border-radius": `${settingFormData[".counter1-section"][
            "border-radius"
          ]?.replace("rem", "")}rem`,
        }
      : {
          ...settingFormData[".counter1-section"],
          padding: `${settingFormData[".counter1-section"]["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
        },
    ".counter1-section.bg-image": settingFormData[".counter1-section.bg-image"][
      "background-image"
    ]
      ? {
          "background-image": settingFormData[".counter1-section.bg-image"][
            "background-image"
          ]
            ? addUrlImage({
                urlString: removeUrlImage({
                  urlString:
                    settingFormData[".counter1-section.bg-image"][
                      "background-image"
                    ],
                }),
              })
            : "",
        }
      : {},
    ".counter1-section.bg-image::before": settingFormData[
      ".counter1-section.bg-image::before"
    ]["background-color"]
      ? {
          "background-color": settingFormData[
            ".counter1-section.bg-image::before"
          ]["background-color"]
            ? rgbColorCreate({
                rgbString:
                  settingFormData[".counter1-section.bg-image::before"][
                    "background-color"
                  ],
              })
            : "",
        }
      : {},
    ".counter1-section .countdiv": {
      ...settingFormData[".counter1-section .countdiv"],
      "padding-left": `${settingFormData[".counter1-section .countdiv"][
        "padding-left"
      ]?.replace("px", "")}px`,
      margin: `${settingFormData[".counter1-section .countdiv"]["margin"]
        ?.split(" ")[0]
        ?.replace("px", "")}px 0`,
    },
    ".counter1-section .countdiv .counternumb": {
      ...settingFormData[".counter1-section .countdiv .counternumb"],
      "font-size": `${settingFormData[
        ".counter1-section .countdiv .counternumb"
      ]["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".counter1-section .countdiv .counternumb"]["font-weight"]}`,
      "line-height": `${settingFormData[".counter1-section .countdiv .counternumb"]["line-height"]}`,
      color: settingFormData[".counter1-section .countdiv .counternumb"][
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".counter1-section .countdiv .counternumb"][
                "color"
              ],
          })
        : "",
    },
    ".counter1-section .countdiv .counter-title": {
      ...settingFormData[".counter1-section .countdiv .counter-title"],
      "font-size": `${settingFormData[
        ".counter1-section .countdiv .counter-title"
      ]["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".counter1-section .countdiv .counter-title"]["font-weight"]}`,
      "line-height": `${settingFormData[".counter1-section .countdiv .counter-title"]["line-height"]}`,
      color: settingFormData[".counter1-section .countdiv .counter-title"][
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".counter1-section .countdiv .counter-title"][
                "color"
              ],
          })
        : "",
    },
  };
  return newData;
};
