import * as React from "react";
import { useState } from "react";
import SignContext from "./SignContext";

const SignState = (props) => {
  const [signinorgName, setSigninOrgName] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [conformpassword, setConformPassword] = useState("");
  const [selected, setSelected] = useState("jwt");
  const [togglePassword, setTogglePassword] = useState(false);
  const [conformtogglePassword, setConformTogglePassword] = useState(false);
  return (
    <>
      <SignContext.Provider
        value={{
          signinorgName,
          setSigninOrgName,
          fname,
          setFname,
          lname,
          setLname,
          email,
          setEmail,
          password,
          setPassword,
          conformpassword,
          setConformPassword,
          selected,
          setSelected,
          togglePassword,
          setTogglePassword,
          conformtogglePassword,
          setConformTogglePassword,
          slugUrl,
          setSlugUrl,
        }}
      >
        {props.children}
      </SignContext.Provider>
    </>
  );
};

export default SignState;
