import {
  addUrlImage,
  convertRGBA,
  removeUrlImage,
  rgbColorCreate,
} from "../../../../utils/helper";

export const OurClientThemeOneFunction = (payload) => {
  return {
    ".clients-section .section-title-area .section-subtitle": {
      "font-size":
        payload.style?.[
          ".clients-section .section-title-area .section-subtitle"
        ]?.["font-size"],
      "font-weight":
        payload.style?.[
          ".clients-section .section-title-area .section-subtitle"
        ]?.["font-weight"],
      color: payload.style?.[
        ".clients-section .section-title-area .section-subtitle"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".clients-section .section-title-area .section-subtitle"
              ]?.["color"],
          })
        : "",
      "background-color": payload.style?.[
        ".clients-section .section-title-area .section-subtitle"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".clients-section .section-title-area .section-subtitle"
              ]?.["background-color"],
          })
        : "",
      padding:
        payload.style?.[
          ".clients-section .section-title-area .section-subtitle"
        ]?.["padding"],
      p1: payload.style?.[
        ".clients-section .section-title-area .section-subtitle"
      ]?.["padding"]?.split(" ")[0],
      p2: payload.style?.[
        ".clients-section .section-title-area .section-subtitle"
      ]?.["padding"]?.split(" ")[1],
      "border-radius":
        payload.style?.[
          ".clients-section .section-title-area .section-subtitle"
        ]?.["border-radius"],
      "margin-bottom":
        payload.style?.[
          ".clients-section .section-title-area .section-subtitle"
        ]?.["margin-bottom"],
    },
    ".clients-section .section-title-area .section-title": {
      "font-size":
        payload.style?.[
          ".clients-section .section-title-area .section-title"
        ]?.["font-size"],
      "font-weight":
        payload.style?.[
          ".clients-section .section-title-area .section-title"
        ]?.["font-weight"],
      color: payload.style?.[
        ".clients-section .section-title-area .section-title"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".clients-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
      "margin-bottom":
        payload.style?.[
          ".clients-section .section-title-area .section-title"
        ]?.["margin-bottom"],
    },
    ".clients-slider .slick-arrow:before": {
      color: payload.style?.[".clients-slider .slick-arrow:before"]?.["color"]
        ? convertRGBA({
            rgbString: payload.style?.[".clients-slider .slick-arrow:before"]?.[
              "color"
            ]?.replace(" !important", ""),
          })
        : "",
    },
    ".clients-slider .client-img": {
      padding: payload.style?.[".clients-slider .client-img"]?.["padding"],
      "max-height":
        payload.style?.[".clients-slider .client-img"]?.["max-height"],
      "max-width":
        payload.style?.[".clients-slider .client-img"]?.["max-width"],
    },
  };
};
export const OurClientThemeTwoFunction = (payload) => {
  return {
    ".partner-section .imgdiv": {
      padding: payload.style?.[".partner-section .imgdiv"]?.["padding"],
      p1: payload.style?.[".partner-section .imgdiv"]?.["p1"],
      p2: payload.style?.[".partner-section .imgdiv"]?.["p2"],
      "border-right":
        payload.style?.[".partner-section .imgdiv"]?.["border-right"],
      borderPx: payload.style?.[".partner-section .imgdiv"]?.["borderPx"],
      borderThik: payload.style?.[".partner-section .imgdiv"]?.["borderThik"],
      borderColor: payload.style?.[".partner-section .imgdiv"]?.["color"]
        ? convertRGBA({
            rgbString: payload.style?.[".partner-section .imgdiv"]?.["color"],
          })
        : "",
    },
  };
};

export const OurClientOneSubFun = ({ settingFormData, boxSection }) => {
  let newData = {
    ...settingFormData,
    ".clients-section": boxSection
      ? {
          ...settingFormData[".clients-section"],
          padding: `${settingFormData?.[".clients-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
          width: `calc(100% - ${settingFormData?.[".clients-section"]?.[
            "width"
          ]?.replace("rem)", "")}rem)`,
          margin: `${settingFormData?.[".clients-section"]?.["margin"]
            ?.split(" ")[0]
            ?.replace("px", "")}px auto`,
          "border-radius": `${settingFormData?.[".clients-section"]?.[
            "border-radius"
          ]?.replace("rem", "")}rem`,
        }
      : {
          ...settingFormData?.[".clients-section"],
          padding: `${settingFormData?.[".clients-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
        },
    ".clients-section.bg-image": settingFormData[".clients-section.bg-image"][
      "background-image"
    ]
      ? {
          "background-image": settingFormData?.[".clients-section.bg-image"]?.[
            "background-image"
          ]
            ? addUrlImage({
                urlString: removeUrlImage({
                  urlString:
                    settingFormData?.[".clients-section.bg-image"]?.[
                      "background-image"
                    ],
                }),
              })
            : "",
        }
      : {},
    ".clients-section.bg-image::before": settingFormData?.[
      ".clients-section.bg-image::before"
    ]?.["background-color"]
      ? {
          "background-color": settingFormData?.[
            ".clients-section.bg-image::before"
          ]["background-color"]
            ? rgbColorCreate({
                rgbString:
                  settingFormData?.[".clients-section.bg-image::before"]?.[
                    "background-color"
                  ],
              })
            : "",
        }
      : {},
    ".clients-section .section-title-area .section-subtitle": {
      ...settingFormData[
        ".clients-section .section-title-area .section-subtitle"
      ],
      "font-size": `${settingFormData?.[
        ".clients-section .section-title-area .section-subtitle"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".clients-section .section-title-area .section-subtitle"]?.["font-weight"]}`,

      color: settingFormData?.[
        ".clients-section .section-title-area .section-subtitle"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".clients-section .section-title-area .section-subtitle"
              ]?.["color"],
          })
        : "",
      "background-color": settingFormData?.[
        ".clients-section .section-title-area .section-subtitle"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".clients-section .section-title-area .section-subtitle"
              ]?.["background-color"],
          })
        : "",
      padding: `${settingFormData?.[
        ".clients-section .section-title-area .section-subtitle"
      ]?.["p1"]?.replace("px", "")}px ${settingFormData[
        ".clients-section .section-title-area .section-subtitle"
      ]?.["p2"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData?.[
        ".clients-section .section-title-area .section-subtitle"
      ]?.["border-radius"]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData[
        ".clients-section .section-title-area .section-subtitle"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".clients-section .section-title-area .section-title": {
      ...settingFormData[".clients-section .section-title-area .section-title"],
      "font-size": `${settingFormData?.[
        ".clients-section .section-title-area .section-title"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".clients-section .section-title-area .section-title"]?.["font-weight"]}`,
      color: settingFormData?.[
        ".clients-section .section-title-area .section-title"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".clients-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
      "margin-bottom": `${settingFormData[
        ".clients-section .section-title-area .section-title"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".clients-slider .slick-arrow:before": {
      ...settingFormData?.[".clients-slider .slick-arrow:before"],
      color: settingFormData?.[".clients-slider .slick-arrow:before"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".clients-slider .slick-arrow:before"]?.[
                "color"
              ],
          })
        : "",
    },
    ".clients-slider .client-img": {
      ...settingFormData?.[".clients-slider .client-img"],
      padding: `${settingFormData?.[".clients-slider .client-img"]?.[
        "padding"
      ]?.replace("px", "")}px`,
      "max-height": `${settingFormData?.[".clients-slider .client-img"]?.[
        "max-height"
      ]?.replace("px", "")}px`,
      "max-width": `${settingFormData?.[".clients-slider .client-img"]?.[
        "max-width"
      ]?.replace("px", "")}px`,
    },
  };
  return newData;
};
export const OurClientTwoSubFun = ({ settingFormData, boxSection }) => {
  let newData = {
    ...settingFormData,
    ".partner-section": boxSection
      ? {
          ...settingFormData[".partner-section"],
          padding: `${settingFormData?.[".partner-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
          width: `calc(100% - ${settingFormData?.[".partner-section"]?.[
            "width"
          ]?.replace("rem)", "")}rem)`,
          margin: `${settingFormData?.[".partner-section"]?.["margin"]
            ?.split(" ")[0]
            ?.replace("px", "")}px auto`,
          "border-radius": `${settingFormData?.[".partner-section"]?.[
            "border-radius"
          ]?.replace("rem", "")}rem`,
        }
      : {
          ...settingFormData?.[".partner-section"],
          padding: `${settingFormData?.[".partner-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
        },
    ".partner-section.bg-image": settingFormData[".partner-section.bg-image"][
      "background-image"
    ]
      ? {
          "background-image": settingFormData?.[".partner-section.bg-image"]?.[
            "background-image"
          ]
            ? addUrlImage({
                urlString: removeUrlImage({
                  urlString:
                    settingFormData?.[".partner-section.bg-image"]?.[
                      "background-image"
                    ],
                }),
              })
            : "",
        }
      : {},
    ".partner-section.bg-image::before": settingFormData?.[
      ".partner-section.bg-image::before"
    ]?.["background-color"]
      ? {
          "background-color": settingFormData?.[
            ".partner-section.bg-image::before"
          ]["background-color"]
            ? rgbColorCreate({
                rgbString:
                  settingFormData?.[".partner-section.bg-image::before"]?.[
                    "background-color"
                  ],
              })
            : "",
        }
      : {},
    ".partner-section .imgdiv": {
      ...settingFormData?.[".partner-section .imgdiv"],
      padding: `${settingFormData?.[".partner-section .imgdiv"]?.[
        "p1"
      ]?.replace("px", "")}px ${settingFormData?.[".partner-section .imgdiv"]?.[
        "p2"
      ]?.replace("px", "")}px`,
      "border-right": `${settingFormData?.[".partner-section .imgdiv"]?.[
        "borderPx"
      ]?.replace("px", "")}px ${
        settingFormData?.[".partner-section .imgdiv"]?.["borderThik"]
      } ${rgbColorCreate({
        rgbString:
          settingFormData?.[".partner-section .imgdiv"]?.["borderColor"],
      })}`,
    },
  };
  return newData;
};

export const ourClientStyleOne = {
  ".clients-section": {
    padding: "100px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".clients-section.bg-image": {},
  ".clients-section.bg-image::before": {
    // { r: 0, g: 0, b: 0, a: 0.8 }
  },
  ".clients-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: { r: 255, g: 73, b: 124, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 0.2 },
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".clients-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: { r: 0, g: 0, b: 0, a: 100 },
    "margin-bottom": "10px",
  },
  ".clients-slider .slick-arrow:before": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".clients-slider .client-img": {
    padding: "10px",
    "max-width": "300px",
    "max-height": "120px",
  },
  ourClientId: "",
};

export const ourClientStyleTwo = {
  ".partner-section": {
    padding: "40px 0",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  ".partner-section.bg-image": {},
  ".partner-section.bg-image::before": {},
  ".partner-section .imgdiv": {
    padding: "30px 15px",
    p1: "30px",
    p2: "15px",
    "border-right": "1px solid rgb(221 221 221 / 100)",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: { r: 221, g: 221, b: 221, a: 100 },
  },
  ourClientId: "",
};
