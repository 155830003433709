import axios from "axios";
import {
  GET_CUSTOM_FORM_FIELD,
  PUT_STATUS_CUSTOM_FORM_FIELD,
  POST_CUSTOM_FORM_FIELD,
  GET_CUSTOM_FORM_FIELD_DETAILS,
  PUT_CUSTOM_FORM_FIELD_ORDER,
} from "..";

/**
 * get popup Dialog  data
 * @param {Parameters} paramObj
 * @returns
 */
export const getCustomFormFiled = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_CUSTOM_FORM_FIELD}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * enable-disable packages
 * @param {body} body
 * @returns
 */
export const updateStatusFormFiled = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_STATUS_CUSTOM_FORM_FIELD}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * create popup Dialog
 * @param {body} body
 * @returns
 */
export const postCustomFormFiled = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${POST_CUSTOM_FORM_FIELD}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * update popup Dialog
 * @param {body} body
 * @returns
 */
export const putUpdateCustomFormFiled = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${POST_CUSTOM_FORM_FIELD}`,
    body,
    {
      headers: { Authorization: jwt_token },
      params: { customFieldId: body.customFieldId },
    }
  );
};
/**
 * delete popup Dialog
 * @param {id} customFieldId
 * @returns
 */
export const deleteCustomFormFiled = (customFieldId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(
    `${process.env.REACT_APP_API_URL}${POST_CUSTOM_FORM_FIELD}`,
    {
      headers: { Authorization: jwt_token },
      params: { customFieldId },
    }
  );
};

/**
 * get popup Dialog
 * @param {id} packageId
 * @returns
 */
export const getCustomFormFiledDetails = (customFieldId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${GET_CUSTOM_FORM_FIELD_DETAILS}`,
    {
      headers: { Authorization: jwt_token },
      params: { customFieldId },
    }
  );
};

/**
 * short order  popup Dialog
 * @param {body} updateOrder
 * @returns
 */
export const sort_order_CustomFormFiled = (updateOrder) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_CUSTOM_FORM_FIELD_ORDER}`,
    { updateOrder: updateOrder },
    {
      headers: { Authorization: jwt_token },
    }
  );
};
