import React, { useContext } from "react";
import { Col, Container, Input, Label, Row } from "reactstrap";
import FooterContext from "../../../context/FooterContext/FooterContext";
import { maxLengthCheck } from "../../../../utils/helper";

const FooterTwo = () => {
  const { isEdit, footerTwoForm, setFooterTwoForm } = useContext(FooterContext);
  /**
   * input value change
   * @param {event} e
   */
  const changeFormValue = (e) => {
    const { value, name } = e.target;
    setFooterTwoForm((prev) => {
      let updatedData = {
        ...prev,
        [name]: value,
      };
      return updatedData;
    });
  };
  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="12">
            <div className="mt-4 d-flex flex-column justify-content-center ">
              <h6>{isEdit ? "Edit" : "Add New"}</h6>
              <Row className="mt-4">
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">Phone 1</Label>
                  <Input
                    className="form-control"
                    name="phone1"
                    type="text"
                    placeholder="Phone 1"
                    value={footerTwoForm?.phone1}
                    // min={"0"}
                    // maxLength={10}
                    // onInput={maxLengthCheck}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    required
                  />
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">Phone 2</Label>
                  <Input
                    className="form-control"
                    name="phone2"
                    type="text"
                    placeholder="Phone 2"
                    value={footerTwoForm?.phone2}
                    // min={"0"}
                    // maxLength={10}
                    // onInput={maxLengthCheck}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    required
                  />
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">Email 1</Label>
                  <Input
                    className="form-control"
                    name="email1"
                    type="email"
                    placeholder="Email 1"
                    value={footerTwoForm?.email1}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    required
                  />
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">Email 2</Label>
                  <Input
                    className="form-control"
                    name="email2"
                    type="email"
                    placeholder="Email 2"
                    value={footerTwoForm?.email2}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    required
                  />
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">Address</Label>
                  <textarea
                    className="form-control"
                    name="address"
                    type="text"
                    placeholder="address"
                    cols={5}
                    value={footerTwoForm?.address}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    required
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default FooterTwo;
