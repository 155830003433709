import React, { useState } from "react";
import SeoDetailsMultipageContext from "./SeoDetailsMultipageContext";
import { randomStr } from "../../../utils/helper";

const SeoDetailsMultipageState = (props) => {
  const [apiData, setApiData] = useState({ data: [], isLoading: false });
  const [isSectionImage, setIsSectionImage] = useState(false);
  const [customeIndex, setCustomeIndex] = useState(null);

  const [fromData, setFormData] = useState({
    name: "",
    description: "",
    image: "",
    headerSection: {
      favicon: "",
      keywords: "",
      author: "",
      ogTags: {
        title: "",
        url: "",
        description: "",
        image: "",
        type: "website",
        siteName: "",
      },
      canonical: "",
      Bing: "",
      googleSiteVerification: "",
      webSiteSchema: "",
      breadCrumbSchema: "",
      facebookPixel: "",
    },
    body: {
      googleTagManage: "",
    },
    footer: {
      chat: "",
      image: "",
      socialMedia: [{ type: "", name: "", value: "", id: randomStr(2) }],
      developedBy: "",
      phone1: "",
      phone2: "",
      email1: "",
      email2: "",
      address: "",
      style: {
        ".custom-footer": {
          "background-color": "#ffffff",
        },
        ".custom-footer .corner.corner-inverse:before, .custom-footer .corner.corner-inverse:after":
          {
            "border-bottom": "50vw solid transparent",
            borderBtPx: "50vw",
            borderBtThik: "solid",
            borderBtColor: "transparent",
            "border-left": "50vw solid #fff",
            borderLfPx: "50vw",
            borderLfThik: "solid",
            borderLfColor: "#fff",
            "border-right": "50vw solid #fff",
            borderRiPx: "50vw",
            borderRiThik: "solid",
            borderRiColor: "#fff",
          },
        ".custom-footer .foot-contact": {
          "background-color": "#f4f4f5",
          padding: "100px 0",
          "text-align": "center",
        },
        ".custom-footer .foot-contact .icons": {
          "background-color": "#fff",
          border: "5px solid #ff497c",
          borderPx: "5px",
          borderThik: "solid",
          borderColor: "#ff497c",
          "border-radius": "50%",
          padding: "30px",
          height: "120px",
          width: "120px",
          "margin-bottom": "20px",
        },
        ".custom-footer .foot-contact h6": {
          color: "#1f2732",
          "font-size": "22px",
          "font-weight": "600",
          "margin-bottom": "10px",
        },
        ".custom-footer .foot-contact p": {
          color: "#1f2732",
          "font-size": "16px",
          "font-weight": "500",
        },
        ".custom-footer .foot-social": {
          padding: "70px 0",
          "background-color": "#1f2732",
        },
        ".custom-footer .foot-social:before": {
          "background-image": "",
        },
        ".custom-footer .foot-social:after": {
          "background-image": "",
        },
        ".custom-footer .foot-social .social_buttons a": {
          "background-color": "#1277D933",
          color: "#ffffff",
        },
        ".custom-footer .foot-copright": {
          "background-color": "#ff497c",
          padding: "15px 0",
        },
        ".custom-footer .foot-copright p": {
          color: "#ffffff",
          "font-size": "14px",
          "font-weight": "500",
        },
        ".custom-footer .foot-copright a": {
          "text-decoration": "underline",
          color: "#ffffff",
        },
      },
    },
    navMapId: "",
    webId: localStorage.getItem("webId"),
  });
  const [id, setId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [filter, setFilter] = useState("");
  const [isBackgroundColor, setIsBackgroundColor] = useState(false);
  return (
    <SeoDetailsMultipageContext.Provider
      value={{
        apiData,
        setApiData,
        fromData,
        setFormData,
        id,
        setId,
        isEdit,
        setIsEdit,
        filter,
        setFilter,
        isSectionImage,
        setIsSectionImage,
        isBackgroundColor,
        setIsBackgroundColor,
        customeIndex,
        setCustomeIndex,
      }}
    >
      {props.children}
    </SeoDetailsMultipageContext.Provider>
  );
};

export default SeoDetailsMultipageState;
