import React, { useContext } from "react";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { Col, Container, Input, Label, Row } from "reactstrap";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import ItemsDragAndDrops from "../../../common/ItemsDragAndDrops";
import { Switch } from "@material-ui/core";
import Settings from "../../../common/setting";
import DetailsContext from "../../../context/DetailsContext/DetailsContext";
import {
  deleteDetails,
  sort_order_Details,
} from "../../../apis/DetailsApi/DetailsApi";
import { TostMessage } from "../../../utils";
import { DELETE_CONFIRM } from "../../../constant";
import AddNewButton from "../../../common/AddNewButton";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";

const DetailsPopup = (props) => {
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const { setLoaderShow } = useContext(LoaderContext);
  const {
    handleChangeStatus,
    getData,
    handleEdit,
    imageUpload,
    emptyData,
    simpleValidator,
  } = props;
  const {
    apiData,
    setApiData,
    fromData,
    setFormData,
    isEdit,
    setFilter,
    filter,
  } = useContext(DetailsContext);
  // const [isFillStar, setIsFillStar] = useState(false);

  /**
   * drag and drop
   * @param {drag data} result
   * @returns
   */
  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;
    const items = Array.from(apiData.data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setApiData({ ...apiData, data: items });
    try {
      let itemOrder = [];
      for (const item in items) {
        itemOrder.push({ detailsSectionId: items[item].Id, order: +item + 1 });
      }
      const { data } = await sort_order_Details(itemOrder);
      if (data.status === 200) {
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * change input value
   * @param {event} e
   * @param {setting value} settingVal
   */
  const changeFormValue = (e, settingVal) => {
    const { name, value } = e.target;
    if (settingVal) {
      setFormData((prev) => {
        return {
          ...prev,
          [name]: JSON.stringify({ [settingVal]: true }),
        };
      });
    } else {
      setFormData((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    }
  };

  /**
   * delete details section
   * @param {id} detailsSectionId
   */
  const handleDelete = async (detailsSectionId) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await deleteDetails(detailsSectionId);
        if (data.status === 200) {
          getData();
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * create new details section
   */
  const hanldenewCreate = () => {
    emptyData();
  };

  /**
   * reupload image
   */
  const reupload = () => {
    setFormData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };

  /**
   * setting section
   * @returns html
   */
  const Setting = () => {
    return (
      <Row>
        <Col
          md="12 mb-3"
          className="d-flex justify-content-between align-items-center flex-wrap  "
        >
          <Label>Image Left – Contain Right</Label>
          <Switch
            checked={
              fromData.displaySetting &&
              JSON.parse(fromData.displaySetting).leftSide === true
            }
            inputProps={{
              "aria-label": "controlled",
            }}
            color="primary"
            name="displaySetting"
            onChange={(e) => changeFormValue(e, "leftSide")}
          />
        </Col>
        <Col
          md="12 mb-3"
          className="d-flex justify-content-between align-items-center flex-wrap  "
        >
          <Label>Contain Left – Image Right Side</Label>
          <Switch
            inputProps={{
              "aria-label": "controlled",
            }}
            color="primary"
            checked={
              fromData.displaySetting &&
              JSON.parse(fromData.displaySetting).rightSide === true
            }
            name="displaySetting"
            onChange={(e) => changeFormValue(e, "rightSide")}
          />
        </Col>
      </Row>
    );
  };
  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="6">
            <div>
              <h6>Details</h6>
              {/* <p className="pt-2">You can add upto 25 Images</p> */}
              <Row>
                <Col md="6">
                  <Input
                    className="form-control mt-3 "
                    name="search"
                    type="text"
                    placeholder="search"
                    style={{ borderRadius: "0", color: "black" }}
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                </Col>
                <Col
                  md="6"
                  className="d-flex justify-content-center align-items-center mt-3"
                >
                  {/* <Button
                    color="primary"
                    className="rounded-0"
                    onClick={() => hanldenewCreate()}
                  >
                    Add New
                  </Button> */}
                  <AddNewButton click={() => hanldenewCreate()} />
                </Col>
              </Row>
              {apiData.isLoading ? (
                <div className="mt-4">
                  <h6>Loading ...</h6>
                </div>
              ) : (
                <div className="mt-4">
                  <ItemsDragAndDrops
                    handleOnDragEnd={handleOnDragEnd}
                    characters={apiData?.data}
                    handleChangeStatus={handleChangeStatus}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                  />
                </div>
              )}
            </div>
          </Col>
          <Col sm="12" xl="6">
            <div className="mt-4">
              <h6>Add New</h6>
              <Row className="mt-4">
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Title *"}</Label>
                  <Input
                    className="form-control"
                    name="title"
                    type="text"
                    placeholder="Title"
                    style={{ borderRadius: "0", color: "black" }}
                    value={fromData.title}
                    onChange={(e) => changeFormValue(e)}
                  />
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "Title",
                      fromData.title,
                      "required"
                    )}
                  </p>
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"HeadLine *"}</Label>
                  <Input
                    className="form-control"
                    name="headLine"
                    type="text"
                    placeholder="HeadLine *"
                    style={{ borderRadius: "0", color: "black" }}
                    value={fromData.headLine}
                    onChange={(e) => changeFormValue(e)}
                  />
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "HeadLine",
                      fromData.headLine,
                      "required"
                    )}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md="6 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Short Description"}
                  </Label>
                  <textarea
                    className="form-control"
                    name="shortDescription"
                    type="text"
                    rows={5}
                    placeholder="Short Description"
                    style={{ borderRadius: "0", color: "black" }}
                    value={fromData.shortDescription}
                    onChange={(e) => changeFormValue(e)}
                  />
                </Col>
                <Col md="6 mb-3">
                  <Label htmlFor="validationCustom03">{"Description"}</Label>
                  <textarea
                    className="form-control"
                    name="description"
                    type="text"
                    rows={5}
                    placeholder="Description"
                    style={{ borderRadius: "0", color: "black" }}
                    value={fromData.description}
                    onChange={(e) => changeFormValue(e)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="12 mb-3">
                  <Settings content={<Setting />} isEdit={isEdit} />
                </Col>
              </Row>
              <Row>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Button Title"}</Label>
                  <Input
                    className="form-control"
                    name="buttonCaption"
                    type="text"
                    placeholder="Button Title"
                    style={{ borderRadius: "0", color: "black" }}
                    value={fromData.buttonCaption}
                    onChange={(e) => changeFormValue(e)}
                  />
                </Col>
              </Row>

              {/* <Dividers divide="IMAGES" />
              <p className="mb-4">File type required JPG, PNG (Max 1MB)</p>
              <Row>
                <Col md="6 mb-3">
                  <div
                    className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    <span
                      onClick={() =>
                        OpenModalCommonFunc(Cases.DETAILS, setAllPopupState)
                      }
                    >
                      <UploadCloud />
                    </span>
                    {fromData?.image && (
                      <img
                        src={fromData?.image ? fromData?.image : ""}
                        alt=""
                        style={{
                          width: "80%",
                          height: "98%",
                          objectFit: "contain",
                          position: "absolute",
                        }}
                      />
                    )}
                    {fromData.image && (
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="close"
                        style={{
                          color: "red",
                          position: "absolute",
                          top: "-22px",
                          left: "1px",
                        }}
                      >
                        <Close
                          style={{ fontSize: "25px", fontWeight: "bold" }}
                        />
                      </IconButton>
                    )}
                    <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="close"
                      style={{
                        color: isFillStar ? "#ff9800" : "#c1c1c1",
                        position: "absolute",
                        top: "-22px",
                        right: "-10px",
                      }}
                      onClick={() => setIsFillStar(!isFillStar)}
                    >
                      <StarIcon
                        style={{ fontSize: "25px", fontWeight: "bold" }}
                      />
                    </IconButton>
                  </div>
                </Col>
              </Row> */}
            </div>
          </Col>
        </Row>
        <ProductImageUploadPopup
          allPopupState={allPopupState}
          setAllPopupState={setAllPopupState}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
          image={fromData.image}
          setImage={setFormData}
          imageUpload={imageUpload}
          isEdit={isEdit}
          reupload={reupload}
        />
      </Container>
    </>
  );
};

export default DetailsPopup;
