import React, { useContext, useMemo, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import { Cases, modalsName } from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import ContactUSCart from "../../../common/contactUSCart";
import ContactUsPopup from "./ContactUsPopup";
import CommonPopup from "../../../Modals/commonPopup";
import {
  getContactUs,
  getDetailsContactUs,
  postContactUs,
  putUpdateContactUs,
} from "../apis/ContactUsApi/ContactUsApi";
import {
  addUrlImage,
  convertRGBA,
  randomStr,
  removeUrlImage,
  removeUrlKeyInPath,
  rgbColorCreate,
} from "../../../../utils/helper";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import SimpleReactValidator from "simple-react-validator";
import { FILE_TYPE, titleUpdateKey } from "../../../../constant";
import ContactUsMultipageContext from "../../../context/ContactUsMultipageContaxt/ContactUsMultipageContaxt";
import SettingContactUsParentPopup from "./SettingContactUsParentPopup";
import { FILE_UPLOAD_API } from "../apis/SliderApi/SliderApi";
import DeleteSection from "../../../common/DeleteSection";
import SettingMultiWebContext from "../../../../context/SettingMultiWebContext/SettingMultiWebContext";

const ContactUs = (props) => {
  const {
    webMasterDetail,
    getData,
    contactDetail,
    contentMapId,
    ByteMiniWeb_Dashboard,
    pageConfigDetails,
    contentDetail,
  } = props;
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );
  const { setCloneAlignModal, setAlignFormData, setCloneFormData } = useContext(
    SettingMultiWebContext
  );
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const {
    apiData: contactUsData,
    setApiData,
    fromData,
    setFormData,
    id,
    setId,
    setIsEdit,
    isEdit,
    filter,
    addFields,
    setAddFields,
    settingFormData,
    setSettingFormData,
    boxSection,
    setBoxSection,
    setBackImageToggle,
    collId,
    setCollId,
    isBackgroundColor,
    setIsBackgroundColor,
    countOfList,
    setCountOfList,
  } = useContext(ContactUsMultipageContext);
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * get contact us
   */
  const getContactUsList = async (status) => {
    try {
      if (!filter.length && status) {
        setApiData((prev) => {
          return { ...prev, isLoading: true };
        });
      }
      let paramObj = {
        showAll: true,
        search: { title: filter },
      };
      const { data } = await getContactUs(paramObj);
      if (data.status === 200) {
        setApiData({
          ...contactUsData,
          isLoading: false,
          data: data.payload.data,
        });
        // countOfList = data.payload.data.length + 1;
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  useMemo(() => {
    if (allPopupState?.contactusPopup) {
      getContactUsList();
    }
  }, [filter, allPopupState?.contactusPopup]);

  useMemo(() => {
    let countOfListValue = contactUsData.data.length + 1;
    setCountOfList(countOfListValue);
    setSettingFormData({
      [`.contact-section${countOfListValue}`]: {
        padding: "100px 0",
        "background-color": "rgb(255 255 255 / 100)",
      },
      image: "",
      [`.contact-section${countOfListValue}.bg-image`]: {
        "background-image": "",
      },
      [`.contact-section${countOfListValue}.bg-image::before`]: {
        "background-color": "",
      },
      [`.contact-section${countOfListValue} .section-title-area`]: {
        "margin-bottom": "50px",
      },
      [`.contact-section${countOfListValue} .section-title-area .section-subtitle`]:
        {
          "font-size": "16px",
          "font-weight": "600",
          color: "rgb(255 73 124 / 100)",
          "background-color": "rgb(255 73 124 / 0.2)",
          padding: "5px 20px",
          p1: "5px",
          p2: "20px",
          "border-radius": "15px",
          "margin-bottom": "15px",
          display: "inline-block",
        },
      [`.contact-section${countOfListValue} .section-title-area .section-title`]:
        {
          "font-size": "44px",
          "font-weight": "600",
          color: "rgb(0 0 0 / 100)",
        },
      [`.contact-section${countOfListValue} .inquiry-box`]: {
        "background-color": "rgb(255 255 255 / 100)",
        padding: "40px 40px",
        p1: "40px",
        p2: "40px",
        margin: "10px 0",
        "border-radius": "15px",
        border: "1px solid rgb(255 255 255 / 100)",
        borderPx: "1px",
        borderThik: "solid",
        borderColor: "rgb(255 255 255 / 100)",
        "border-top": "3px solid rgb(255 73 124 / 100)",
        borderTopPx: "3px",
        borderTopThik: "solid",
        borderTopColor: "rgb(255 73 124 / 100)",
        "box-shadow": "5px 5px 15px rgb(0 0 0 / 0.094)",
        bs1: "5px",
        bs2: "5px",
        bs3: "15px",
        shadowColor: "rgb(0 0 0 / 0.094)",
        "text-align": "center",
      },
      [`.contact-section${countOfListValue} .inquiry-box img`]: {
        "margin-bottom": "20px",
        "max-width": "60px",
      },
      [`.contact-section${countOfListValue} .inquiry-box h4`]: {
        color: "rgb(0 0 0 / 100)",
        "font-weight": "600",
        "font-size": "22px",
        "margin-bottom": "15px",
      },
      [`.contact-section${countOfListValue} .inquiry-box p`]: {
        color: "rgb(0 0 0 / 100)",
        "font-weight": "400",
        "font-size": "14px",
        "line-height": "1.6",
      },
      [`.contact-section${countOfListValue} .contactbox`]: {
        "background-color": "rgb(255 255 255 / 100)",
        padding: "40px 40px",
        p1: "40px",
        p2: "40px",
        "border-radius": "15px",
        border: "1px solid rgb(255 255 255 / 100)",
        borderPx: "1px",
        borderThik: "solid",
        borderColor: "rgb(255 255 255 / 100)",
        "border-top": "3px solid rgb(255 73 124 / 100)",
        borderTopPx: "3px",
        borderTopThik: "solid",
        borderTopColor: "rgb(255 73 124 / 100)",
        "box-shadow": "5px 5px 15px rgb(0 0 0 / 0.094)",
        bs1: "5px",
        bs2: "5px",
        bs3: "15px",
        shadowColor: "rgb(0 0 0 / 0.094)",
      },
      [`.contact-section${countOfListValue} .contactbox iframe`]: {
        border: "2px solid rgb(221 221 221 / 100)",
        borderPx: "2px",
        borderThik: "solid",
        borderColor: "rgb(221 221 221 / 100)",
      },
      contactUsId: "",
    });
    // return contactUsData.data.length + 1;
  }, [contactUsData]);

  const submitStyleValue = () => {
    let countOfListValue = settingFormData?.countOfList || countOfList;
    let newData = {
      ...settingFormData,
      countOfList: countOfListValue,
      [`.contact-section${countOfListValue}`]: boxSection
        ? {
            ...settingFormData[`.contact-section${countOfListValue}`],
            padding: `${settingFormData[
              `.contact-section${countOfListValue}`
            ]?.["padding"]
              ?.split(" ")[0]
              ?.replace("px", "")}px 0`,
            width: `calc(100% - ${settingFormData[
              `.contact-section${countOfListValue}`
            ]?.["width"]?.replace("rem)", "")}rem)`,
            margin: `${settingFormData[`.contact-section${countOfListValue}`]?.[
              "margin"
            ]
              ?.split(" ")[0]
              ?.replace("px", "")}px auto`,
            "border-radius": `${settingFormData[
              `.contact-section${countOfListValue}`
            ]?.["border-radius"]?.replace("rem", "")}rem`,
          }
        : {
            ...settingFormData[`.contact-section${countOfListValue}`],
            padding: `${settingFormData[
              `.contact-section${countOfListValue}`
            ]?.["padding"]
              ?.split(" ")[0]
              ?.replace("px", "")}px 0`,
          },
      [`.contact-section${countOfListValue}.bg-image`]: settingFormData[
        `.contact-section${countOfListValue}.bg-image`
      ]?.["background-image"]
        ? {
            "background-image": settingFormData[
              `.contact-section${countOfListValue}.bg-image`
            ]?.["background-image"]
              ? addUrlImage({
                  urlString: removeUrlImage({
                    urlString:
                      settingFormData[
                        `.contact-section${countOfListValue}.bg-image`
                      ]["background-image"],
                  }),
                })
              : "",
            "background-repeat": "no-repeat",
            "background-size": "cover",
          }
        : {},
      [`.contact-section${countOfListValue}.bg-image::before`]: settingFormData[
        `.contact-section${countOfListValue}.bg-image::before`
      ]?.["background-color"]
        ? {
            "background-color": settingFormData[
              `.contact-section${countOfListValue}.bg-image::before`
            ]?.["background-color"]
              ? rgbColorCreate({
                  rgbString:
                    settingFormData[
                      `.contact-section${countOfListValue}.bg-image::before`
                    ]?.["background-color"],
                })
              : "",
          }
        : {},
      [`.contact-section${countOfListValue} .section-title-area`]: {
        ...settingFormData[
          `.contact-section${countOfListValue} .section-title-area`
        ],
        "margin-bottom": `${settingFormData[
          `.contact-section${countOfListValue} .section-title-area`
        ]?.["margin-bottom"]?.replace("px", "")}px`,
      },
      [`.contact-section${countOfListValue} .section-title-area .section-subtitle`]:
        {
          ...settingFormData[
            `.contact-section${countOfListValue} .section-title-area .section-subtitle`
          ],
          "font-size": `${settingFormData[
            `.contact-section${countOfListValue} .section-title-area .section-subtitle`
          ]?.["font-size"]?.replace("px", "")}px`,
          "font-weight": `${
            settingFormData[
              `.contact-section${countOfListValue} .section-title-area .section-subtitle`
            ]?.["font-weight"]
          }`,
          color: settingFormData[
            `.contact-section${countOfListValue} .section-title-area .section-subtitle`
          ]?.["color"]
            ? rgbColorCreate({
                rgbString:
                  settingFormData[
                    `.contact-section${countOfListValue} .section-title-area .section-subtitle`
                  ]?.["color"],
              })
            : "",
          "background-color": settingFormData[
            `.contact-section${countOfListValue} .section-title-area .section-subtitle`
          ]?.["background-color"]
            ? rgbColorCreate({
                rgbString:
                  settingFormData[
                    `.contact-section${countOfListValue} .section-title-area .section-subtitle`
                  ]?.["background-color"],
              })
            : "",
          padding: `${settingFormData[
            `.contact-section${countOfListValue} .section-title-area .section-subtitle`
          ]?.["p1"]?.replace("px", "")}px ${settingFormData[
            `.contact-section${countOfListValue} .section-title-area .section-subtitle`
          ]?.["p2"]?.replace("px", "")}px`,
          "border-radius": `${settingFormData[
            `.contact-section${countOfListValue} .section-title-area .section-subtitle`
          ]?.["border-radius"]?.replace("px", "")}px`,
          "margin-bottom": `${settingFormData[
            `.contact-section${countOfListValue} .section-title-area .section-subtitle`
          ]?.["margin-bottom"]?.replace("px", "")}px`,
          display: `${
            settingFormData[
              `.contact-section${countOfListValue} .section-title-area .section-subtitle`
            ]?.["display"]
          }`,
        },
      [`.contact-section${countOfListValue} .section-title-area .section-title`]:
        {
          ...settingFormData[
            `.contact-section${countOfListValue} .section-title-area .section-title`
          ],
          "font-size": `${settingFormData[
            `.contact-section${countOfListValue} .section-title-area .section-title`
          ]?.["font-size"]?.replace("px", "")}px`,
          "font-weight": `${
            settingFormData[
              `.contact-section${countOfListValue} .section-title-area .section-title`
            ]?.["font-weight"]
          }`,
          color: settingFormData[
            `.contact-section${countOfListValue} .section-title-area .section-title`
          ]?.["color"]
            ? rgbColorCreate({
                rgbString:
                  settingFormData[
                    `.contact-section${countOfListValue} .section-title-area .section-title`
                  ]?.["color"],
              })
            : "",
        },
      [`.contact-section${countOfListValue} .inquiry-box`]: {
        "background-color": settingFormData[
          `.contact-section${countOfListValue} .inquiry-box`
        ]?.["background-color"]
          ? rgbColorCreate({
              rgbString:
                settingFormData[
                  `.contact-section${countOfListValue} .inquiry-box`
                ]?.["background-color"],
            })
          : "",
        padding: `${settingFormData[
          `.contact-section${countOfListValue} .inquiry-box`
        ]?.["p1"]?.replace("px", "")}px ${settingFormData[
          `.contact-section${countOfListValue} .inquiry-box`
        ]?.["p2"]?.replace("px", "")}px`,
        margin: `${settingFormData[
          `.contact-section${countOfListValue} .inquiry-box`
        ]?.["margin"]
          ?.split(" ")[0]
          ?.replace("px", "")}px 0`,
        "border-radius": `${settingFormData[
          `.contact-section${countOfListValue} .inquiry-box`
        ]?.["border-radius"]?.replace("px", "")}px`,
        border: `${settingFormData?.[
          `.contact-section${countOfListValue} .inquiry-box`
        ]?.["borderPx"]?.replace("px", "")}px ${
          settingFormData?.[
            `.contact-section${countOfListValue} .inquiry-box`
          ]?.["borderThik"]
        } ${rgbColorCreate({
          rgbString:
            settingFormData[
              `.contact-section${countOfListValue} .inquiry-box`
            ]?.["borderColor"],
        })}`,
        borderColor: settingFormData[
          `.contact-section${countOfListValue} .inquiry-box`
        ]?.["borderColor"]
          ? rgbColorCreate({
              rgbString:
                settingFormData[
                  `.contact-section${countOfListValue} .inquiry-box`
                ]?.["borderColor"],
            })
          : "",
        "border-top": `${settingFormData?.[
          `.contact-section${countOfListValue} .inquiry-box`
        ]?.["borderTopPx"]?.replace("px", "")}px ${
          settingFormData?.[
            `.contact-section${countOfListValue} .inquiry-box`
          ]?.["borderTopThik"]
        } ${rgbColorCreate({
          rgbString:
            settingFormData[
              `.contact-section${countOfListValue} .inquiry-box`
            ]?.["borderTopColor"],
        })}`,
        borderTopColor: settingFormData[
          `.contact-section${countOfListValue} .inquiry-box`
        ]?.["borderTopColor"]
          ? rgbColorCreate({
              rgbString:
                settingFormData[
                  `.contact-section${countOfListValue} .inquiry-box`
                ]?.["borderTopColor"],
            })
          : "",
        "box-shadow": `${settingFormData[
          `.contact-section${countOfListValue} .inquiry-box`
        ]?.["bs1"]?.replace("px", "")}px ${settingFormData[
          `.contact-section${countOfListValue} .inquiry-box`
        ]?.["bs2"]?.replace("px", "")}px ${settingFormData[
          `.contact-section${countOfListValue} .inquiry-box`
        ]?.["bs3"]?.replace("px", "")}px ${rgbColorCreate({
          rgbString:
            settingFormData[
              `.contact-section${countOfListValue} .inquiry-box`
            ]?.["shadowColor"],
        })}`,
        shadowColor: settingFormData[
          `.contact-section${countOfListValue} .inquiry-box`
        ]?.["shadowColor"]
          ? rgbColorCreate({
              rgbString:
                settingFormData[
                  `.contact-section${countOfListValue} .inquiry-box`
                ]?.["shadowColor"],
            })
          : "",
        "text-align": `${
          settingFormData[`.contact-section${countOfListValue} .inquiry-box`]?.[
            "text-align"
          ]
        }`,
      },
      [`.contact-section${countOfListValue} .inquiry-box img`]: {
        "margin-bottom": `${settingFormData[
          `.contact-section${countOfListValue} .inquiry-box img`
        ]?.["margin-bottom"]?.replace("px", "")}px`,
        "max-width": `${settingFormData[
          `.contact-section${countOfListValue} .inquiry-box img`
        ]?.["max-width"]?.replace("px", "")}px`,
      },
      [`.contact-section${countOfListValue} .inquiry-box h4`]: {
        color: settingFormData[
          `.contact-section${countOfListValue} .inquiry-box h4`
        ]?.["color"]
          ? rgbColorCreate({
              rgbString:
                settingFormData[
                  `.contact-section${countOfListValue} .inquiry-box h4`
                ]?.["color"],
            })
          : "",
        "font-weight": `${
          settingFormData[
            `.contact-section${countOfListValue} .inquiry-box h4`
          ]?.["font-weight"]
        }`,
        "font-size": `${settingFormData[
          `.contact-section${countOfListValue} .inquiry-box h4`
        ]?.["font-size"]?.replace("px", "")}px`,
        "margin-bottom": `${settingFormData[
          `.contact-section${countOfListValue} .inquiry-box h4`
        ]?.["margin-bottom"]?.replace("px", "")}px`,
      },
      [`.contact-section${countOfListValue} .inquiry-box p`]: {
        color: settingFormData[
          `.contact-section${countOfListValue} .inquiry-box p`
        ]?.["color"]
          ? rgbColorCreate({
              rgbString:
                settingFormData[
                  `.contact-section${countOfListValue} .inquiry-box p`
                ]?.["color"],
            })
          : "",
        "font-weight": `${
          settingFormData[
            `.contact-section${countOfListValue} .inquiry-box p`
          ]?.["font-weight"]
        }`,
        "font-size": `${settingFormData[
          `.contact-section${countOfListValue} .inquiry-box p`
        ]?.["font-size"]?.replace("px", "")}px`,
        "line-height": `${
          settingFormData[
            `.contact-section${countOfListValue} .inquiry-box p`
          ]?.["line-height"]
        }`,
      },
      [`.contact-section${countOfListValue} .contactbox`]: {
        "background-color": settingFormData[
          `.contact-section${countOfListValue} .contactbox`
        ]?.["background-color"]
          ? rgbColorCreate({
              rgbString:
                settingFormData[
                  `.contact-section${countOfListValue} .contactbox`
                ]?.["background-color"],
            })
          : "",
        padding: `${settingFormData[
          `.contact-section${countOfListValue} .contactbox`
        ]?.["p1"]?.replace("px", "")}px ${settingFormData[
          `.contact-section${countOfListValue} .contactbox`
        ]?.["p2"]?.replace("px", "")}px`,
        "border-radius": `${settingFormData[
          `.contact-section${countOfListValue} .contactbox`
        ]?.["border-radius"]?.replace("px", "")}px`,
        border: `${settingFormData?.[
          `.contact-section${countOfListValue} .contactbox`
        ]?.["borderPx"]?.replace("px", "")}px ${
          settingFormData?.[
            `.contact-section${countOfListValue} .contactbox`
          ]?.["borderThik"]
        } ${rgbColorCreate({
          rgbString:
            settingFormData[
              `.contact-section${countOfListValue} .contactbox`
            ]?.["borderColor"],
        })}`,
        borderColor: settingFormData[
          `.contact-section${countOfListValue} .contactbox`
        ]?.["borderColor"]
          ? rgbColorCreate({
              rgbString:
                settingFormData[
                  `.contact-section${countOfListValue} .contactbox`
                ]?.["borderColor"],
            })
          : "",
        "border-top": `${settingFormData?.[
          `.contact-section${countOfListValue} .contactbox`
        ]?.["borderTopPx"]?.replace("px", "")}px ${
          settingFormData?.[
            `.contact-section${countOfListValue} .contactbox`
          ]?.["borderTopThik"]
        } ${rgbColorCreate({
          rgbString:
            settingFormData[
              `.contact-section${countOfListValue} .contactbox`
            ]?.["borderTopColor"],
        })}`,
        borderTopColor: settingFormData[
          `.contact-section${countOfListValue} .contactbox`
        ]?.["borderTopColor"]
          ? rgbColorCreate({
              rgbString:
                settingFormData[
                  `.contact-section${countOfListValue} .contactbox`
                ]?.["borderTopColor"],
            })
          : "",
        "box-shadow": `${settingFormData[
          `.contact-section${countOfListValue} .contactbox`
        ]?.["bs1"]?.replace("px", "")}px ${settingFormData[
          `.contact-section${countOfListValue} .contactbox`
        ]?.["bs2"]?.replace("px", "")}px ${settingFormData[
          `.contact-section${countOfListValue} .contactbox`
        ]?.["bs3"]?.replace("px", "")}px ${rgbColorCreate({
          rgbString:
            settingFormData[
              `.contact-section${countOfListValue} .contactbox`
            ]?.["shadowColor"],
        })}`,
        shadowColor: settingFormData[
          `.contact-section${countOfListValue} .contactbox`
        ]?.["shadowColor"]
          ? rgbColorCreate({
              rgbString:
                settingFormData[
                  `.contact-section${countOfListValue} .contactbox`
                ]?.["shadowColor"],
            })
          : "",
      },
      [`.contact-section${countOfListValue} .contactbox iframe`]: {
        border: `${settingFormData?.[
          `.contact-section${countOfListValue} .contactbox iframe`
        ]?.["borderPx"]?.replace("px", "")}px ${
          settingFormData?.[
            `.contact-section${countOfListValue} .contactbox iframe`
          ]?.["borderThik"]
        } ${rgbColorCreate({
          rgbString:
            settingFormData[
              `.contact-section${countOfListValue} .contactbox iframe`
            ]?.["borderColor"],
        })}`,
        shadowColor: settingFormData[
          `.contact-section${countOfListValue} .contactbox iframe`
        ]?.["shadowColor"]
          ? rgbColorCreate({
              rgbString:
                settingFormData[
                  `.contact-section${countOfListValue} .contactbox iframe`
                ]?.["shadowColor"],
            })
          : "",
      },
    };
    if (isBackgroundColor) {
      newData[`.contact-section${countOfListValue}`] = {
        ...newData?.[`.contact-section${countOfListValue}`],
        "background-color": settingFormData[
          `.contact-section${countOfListValue}`
        ]?.["background-color"]
          ? rgbColorCreate({
              rgbString:
                settingFormData[`.contact-section${countOfListValue}`]?.[
                  "background-color"
                ],
            })
          : "",
      };
    }
    if (boxSection) {
      newData[`.contact-section${countOfListValue}.bg-image::before`] = {
        ...newData[`.contact-section${countOfListValue}.bg-image::before`],
        "border-radius": `${settingFormData[
          `.contact-section${countOfListValue}.bg-image::before`
        ]?.["border-radius"]?.replace("rem", "")}rem`,
      };
    }
    return newData;
  };

  /**
   * contact us create and update
   */
  const handleSubmit = async ({
    contactUsId,
    isPrimary,
    defaultSetStar,
    isEditStar,
  }) => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      if (id > -1 && (isEdit || isEditStar)) {
        let newObj = {};
        if (
          contactUsId > -1 &&
          (isEdit || isEditStar) &&
          defaultSetStar === "star"
        ) {
          newObj = {
            isPrimary,
            contactUsId,
          };
          setFormData((prev) => {
            return {
              isPrimary,
              contactUsId,
            };
          });
        } else {
          newObj = {
            ...fromData,
            phone: addFields.phoneInput,
            email: addFields.emailInput,
            mapLink: fromData.iframe,
            contentMapId: collId,
            // style: await submitStyleValue(),
          };
          delete newObj.iframe;
          setFormData(newObj);
        }
        try {
          const { data } = await putUpdateContactUs(newObj);
          if (data.status === 200) {
            getContactUsList();
            emptyData();
            TostMessage({ message: data, type: "success" });
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      } else {
        try {
          setLoaderShow(true);
          let newObj = {
            ...fromData,
            phone: addFields.phoneInput,
            email: addFields.emailInput,
            mapLink: fromData.iframe,
            isPrimary: contactUsData.data.length === 0 ? true : false,
            contentMapId: collId,
            style: await submitStyleValue(),
          };
          delete newObj.iframe;
          setFormData(newObj);
          const { data } = await postContactUs(newObj);
          if (data.status === 200) {
            getContactUsList();
            emptyData();
            TostMessage({ message: data, type: "success" });
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      }
    }
  };
  /**
   * file upload
   * @param {file} file
   */
  const imageUploadImages = async (file) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.CONTACT_US);
    formData.append("file", file);
    setLoaderShow(true);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        setSettingFormData((prev) => {
          return {
            ...prev,
            [`.contact-section${countOfList}.bg-image`]: {
              "background-image": data.payload.baseUrl + data.payload.imageUrl,
            },
          };
        });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * get contact us details
   * @param {id} contactUsId
   */
  const handleEdit = async (contactUsId, InputfocusId) => {
    try {
      setLoaderShow(true);
      setId(contactUsId);
      const { data } = await getDetailsContactUs(contactUsId);
      if (data.status === 200) {
        setIsEdit(true);
        let payload = data.payload.data;
        setFormData({
          name: payload.name || "",
          title: payload.title || "",
          isTitleShow: payload?.isTitleShow ? payload?.isTitleShow : false,
          subTitle: payload.subTitle || "",
          addLine1: payload.addLine1 || "",
          addLine2: payload.addLine2 || "",
          area: payload.area || "",
          country: payload.country || "",
          state: payload.state || "",
          city: payload.city || "",
          pinCode: payload.pinCode || "",
          mapLink: payload.mapLink || "",
          iframe: payload.mapLink || "",
          workingHour: payload?.workingHour || "",
          contactUsId: contactUsId || "",
          theme: payload?.theme || "",
          style: payload?.style || "",
          navMapId: payload?.navMapId || "",
          webId: localStorage.getItem("webId"),
        });

        setAddFields({
          phoneInput: JSON.parse(payload.phone),
          emailInput: JSON.parse(payload.email),
        });

        InputfocusId.focus();
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };
  /**
   * create and update contact Us
   */
  const handleSubmitSetting = async (isRestore = false, isClose = true) => {
    if (id >= -1 && isEdit) {
      setLoaderShow(true);
      try {
        let newData;
        if (isRestore) {
          const isConfirm = window.confirm(
            "Are you sure want to restore style ?"
          );
          if (isConfirm) {
            newData = {
              styleRestore: true,
              contactUsId: id,
            };
          } else {
            setLoaderShow(false);
            return;
          }
        } else {
          newData = await submitStyleValue();
          const keysToRemove = [
            // "shadowColor",
            "borderPx",
            "borderThik",
            // "borderColor",
            "borderTopPx",
            "borderTopThik",
            // "borderTopColor",
            "p1",
            "p2",
            "bs1",
            "bs2",
            "bs3",
            // "shadowColor",
            "image",
          ];

          function removeKeys(obj, keys) {
            for (const key in obj) {
              if (typeof obj[key] === "object") {
                removeKeys(obj[key], keys);
              } else if (keys.includes(key)) {
                delete obj[key];
              }
            }
          }
          removeKeys(newData, keysToRemove);
          newData = {
            style: newData,
            contactUsId: id,
          };
        }

        const { data } = await putUpdateContactUs(newData);
        if (data.status === 200) {
          if (isClose) {
            OpenModalCommonFunc(
              Cases.SETTING_CONTACT_US_PARENT,
              setAllPopupState,
              false
            );
            emptyData();
          }
          setLoaderShow(false);
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  /**
   * get setting data
   * @param {id} contactUsId

   */
  const handleChangeSettings = async (contactUsId) => {
    try {
      setLoaderShow(true);
      setId(contactUsId);
      const { data } = await getDetailsContactUs(contactUsId);
      if (data.status === 200) {
        let payload = data.payload.data;
        // countOfList = payload?.style?.countOfList;
        setCountOfList(payload?.style?.countOfList);
        if (
          payload.style?.[`.contact-section${payload?.style?.countOfList}`]?.[
            "width"
          ] ||
          payload.style?.[`contact-section${payload?.style?.countOfList}`]?.[
            "margin"
          ] ||
          payload.style?.[`.contact-section${payload?.style?.countOfList}`]?.[
            "border-radius"
          ]
        ) {
          setBoxSection(true);
        }
        if (
          payload.style?.[
            `.contact-section${payload?.style?.countOfList}.bg-image`
          ]?.["background-image"]
        ) {
          setBackImageToggle(true);
        }
        if (
          payload.style?.[`.contact-section${payload?.style?.countOfList}`]?.[
            "background-color"
          ]
        ) {
          setIsBackgroundColor(true);
        }
        setSettingFormData((prev) => {
          return {
            countOfList: payload?.style?.countOfList,
            [`.contact-section${payload?.style?.countOfList}`]: {
              padding:
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList}`
                ]?.["padding"],
              "background-color": payload.style?.[
                `.contact-section${payload?.style?.countOfList}`
              ]?.["background-color"]
                ? convertRGBA({
                    rgbString:
                      payload.style?.[
                        `.contact-section${payload?.style?.countOfList}`
                      ]?.["background-color"],
                  })
                : "",
              width: payload.style?.[
                `.contact-section${payload?.style?.countOfList}`
              ]?.["width"]?.replace("calc(100% - ", ""),
              margin:
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList}`
                ]?.["margin"],
              "border-radius":
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList}`
                ]?.["border-radius"],
            },
            [`.contact-section${payload?.style?.countOfList}.bg-image`]: {
              "background-image": payload.style?.[
                `.contact-section${payload?.style?.countOfList}.bg-image`
              ]?.["background-image"]
                ? removeUrlKeyInPath({
                    urlString:
                      payload.style?.[
                        `.contact-section${payload?.style?.countOfList}.bg-image`
                      ]?.["background-image"],
                  })
                : "",
            },
            [`.contact-section${payload?.style?.countOfList}.bg-image::before`]:
              {
                "background-color": payload.style?.[
                  `.contact-section${payload?.style?.countOfList}.bg-image::before`
                ]?.["background-color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          `.contact-section${payload?.style?.countOfList}.bg-image::before`
                        ]?.["background-color"],
                    })
                  : "",
              },
            [`.contact-section${payload?.style?.countOfList} .section-title-area`]:
              {
                "margin-bottom":
                  payload.style?.[
                    `.contact-section${payload?.style?.countOfList} .section-title-area`
                  ]?.["margin-bottom"],
              },
            [`.contact-section${payload?.style?.countOfList} .section-title-area .section-subtitle`]:
              {
                "font-size":
                  payload.style?.[
                    `.contact-section${payload?.style?.countOfList} .section-title-area .section-subtitle`
                  ]?.["font-size"],
                "font-weight":
                  payload.style?.[
                    `.contact-section${payload?.style?.countOfList} .section-title-area .section-subtitle`
                  ]?.["font-weight"],
                color: payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .section-title-area .section-subtitle`
                ]?.["color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          `.contact-section${payload?.style?.countOfList} .section-title-area .section-subtitle`
                        ]?.["color"],
                    })
                  : "",
                "background-color": payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .section-title-area .section-subtitle`
                ]?.["background-color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          `.contact-section${payload?.style?.countOfList} .section-title-area .section-subtitle`
                        ]?.["background-color"],
                    })
                  : "",
                padding:
                  payload.style?.[
                    `.contact-section${payload?.style?.countOfList} .section-title-area .section-subtitle`
                  ]?.["padding"],
                p1: payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .section-title-area .section-subtitle`
                ]?.["padding"]?.split(" ")[0],
                p2: payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .section-title-area .section-subtitle`
                ]?.["padding"]?.split(" ")[1],
                "border-radius":
                  payload.style?.[
                    `.contact-section${payload?.style?.countOfList} .section-title-area .section-subtitle`
                  ]?.["border-radius"],
                "margin-bottom":
                  payload.style?.[
                    `.contact-section${payload?.style?.countOfList} .section-title-area .section-subtitle`
                  ]?.["margin-bottom"],
                display:
                  payload.style?.[
                    `.contact-section${payload?.style?.countOfList} .section-title-area .section-subtitle`
                  ]?.["display"],
              },
            [`.contact-section${payload?.style?.countOfList} .section-title-area .section-title`]:
              {
                "font-size":
                  payload.style?.[
                    `.contact-section${payload?.style?.countOfList} .section-title-area .section-title`
                  ]?.["font-size"],
                "font-weight":
                  payload.style?.[
                    `.contact-section${payload?.style?.countOfList} .section-title-area .section-title`
                  ]?.["font-weight"],
                color: payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .section-title-area .section-title`
                ]?.["color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          `.contact-section${payload?.style?.countOfList} .section-title-area .section-title`
                        ]?.["color"],
                    })
                  : "",
              },
            [`.contact-section${payload?.style?.countOfList} .inquiry-box`]: {
              "background-color": payload.style?.[
                `.contact-section${payload?.style?.countOfList} .inquiry-box`
              ]?.["background-color"]
                ? convertRGBA({
                    rgbString:
                      payload.style?.[
                        `.contact-section${payload?.style?.countOfList} .inquiry-box`
                      ]?.["background-color"],
                  })
                : "",
              padding:
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .inquiry-box`
                ]?.["padding"],
              p1: payload.style?.[
                `.contact-section${payload?.style?.countOfList} .inquiry-box`
              ]?.["padding"]?.split(" ")[0],
              p2: payload.style?.[
                `.contact-section${payload?.style?.countOfList} .inquiry-box`
              ]?.["padding"]?.split(" ")[1],
              margin:
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .inquiry-box`
                ]?.["margin"],
              "border-radius":
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .inquiry-box`
                ]?.["border-radius"],
              border:
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .inquiry-box`
                ]?.["border"],
              borderPx:
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .inquiry-box`
                ]?.["border"]?.split(" ")[0],
              borderThik:
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .inquiry-box`
                ]?.["border"]?.split(" ")[1],
              borderColor: payload.style?.[
                `.contact-section${payload?.style?.countOfList} .inquiry-box`
              ]?.["borderColor"]
                ? convertRGBA({
                    rgbString:
                      payload.style?.[
                        `.contact-section${payload?.style?.countOfList} .inquiry-box`
                      ]?.["borderColor"],
                  })
                : "",
              "border-top":
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .inquiry-box`
                ]?.["border-top"],
              borderTopPx:
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .inquiry-box`
                ]?.["border-top"]?.split(" ")[0],
              borderTopThik:
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .inquiry-box`
                ]?.["border-top"]?.split(" ")[1],
              borderTopColor: payload.style?.[
                `.contact-section${payload?.style?.countOfList} .inquiry-box`
              ]?.["borderTopColor"]
                ? convertRGBA({
                    rgbString:
                      payload.style?.[
                        `.contact-section${payload?.style?.countOfList} .inquiry-box`
                      ]?.["borderTopColor"],
                  })
                : "",
              "box-shadow":
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .inquiry-box`
                ]?.["box-shadow"],
              bs1: payload.style?.[
                `.contact-section${payload?.style?.countOfList} .inquiry-box`
              ]?.["box-shadow"]?.split(" ")[0],
              bs2: payload.style?.[
                `.contact-section${payload?.style?.countOfList} .inquiry-box`
              ]?.["box-shadow"]?.split(" ")[1],
              bs3: payload.style?.[
                `.contact-section${payload?.style?.countOfList} .inquiry-box`
              ]?.["box-shadow"]?.split(" ")[2],
              shadowColor: payload.style?.[
                `.contact-section${payload?.style?.countOfList} .inquiry-box`
              ]?.["shadowColor"]
                ? convertRGBA({
                    rgbString:
                      payload.style?.[
                        `.contact-section${payload?.style?.countOfList} .inquiry-box`
                      ]?.["shadowColor"],
                  })
                : "",
              "text-align":
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .inquiry-box`
                ]?.["text-align"],
            },
            [`.contact-section${payload?.style?.countOfList} .inquiry-box img`]:
              {
                "margin-bottom":
                  payload.style?.[
                    `.contact-section${payload?.style?.countOfList} .inquiry-box img`
                  ]?.["margin-bottom"],
                "max-width":
                  payload.style?.[
                    `.contact-section${payload?.style?.countOfList} .inquiry-box img`
                  ]?.["max-width"],
              },
            [`.contact-section${payload?.style?.countOfList} .inquiry-box h4`]:
              {
                color: payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .inquiry-box h4`
                ]?.["color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          `.contact-section${payload?.style?.countOfList} .inquiry-box h4`
                        ]?.["color"],
                    })
                  : "",
                "font-weight":
                  payload.style?.[
                    `.contact-section${payload?.style?.countOfList} .inquiry-box h4`
                  ]?.["font-weight"],
                "font-size":
                  payload.style?.[
                    `.contact-section${payload?.style?.countOfList} .inquiry-box h4`
                  ]?.["font-size"],
                "margin-bottom":
                  payload.style?.[
                    `.contact-section${payload?.style?.countOfList} .inquiry-box h4`
                  ]?.["margin-bottom"],
              },
            [`.contact-section${payload?.style?.countOfList} .inquiry-box p`]: {
              color: payload.style?.[
                `.contact-section${payload?.style?.countOfList} .inquiry-box p`
              ]?.["color"]
                ? convertRGBA({
                    rgbString:
                      payload.style?.[
                        `.contact-section${payload?.style?.countOfList} .inquiry-box p`
                      ]?.["color"],
                  })
                : "",
              "font-weight":
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .inquiry-box p`
                ]?.["font-weight"],
              "font-size":
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .inquiry-box p`
                ]?.["font-size"],
              "line-height":
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .inquiry-box p`
                ]?.["line-height"],
            },
            [`.contact-section${payload?.style?.countOfList} .contactbox`]: {
              "background-color": payload.style?.[
                `.contact-section${payload?.style?.countOfList} .contactbox`
              ]?.["background-color"]
                ? convertRGBA({
                    rgbString:
                      payload.style?.[
                        `.contact-section${payload?.style?.countOfList} .contactbox`
                      ]?.["background-color"],
                  })
                : "",
              padding:
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .contactbox`
                ]?.["padding"],
              p1: payload.style?.[
                `.contact-section${payload?.style?.countOfList} .contactbox`
              ]?.["padding"]?.split(" ")[0],
              p2: payload.style?.[
                `.contact-section${payload?.style?.countOfList} .contactbox`
              ]?.["padding"]?.split(" ")[1],
              "border-radius":
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .contactbox`
                ]?.["border-radius"],
              border:
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .contactbox`
                ]?.["border"],
              borderPx:
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .contactbox`
                ]?.["border"]?.split(" ")[0],
              borderThik:
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .contactbox`
                ]?.["border"]?.split(" ")[1],
              borderColor: payload.style?.[
                `.contact-section${payload?.style?.countOfList} .contactbox`
              ]?.["borderColor"]
                ? convertRGBA({
                    rgbString:
                      payload.style?.[
                        `.contact-section${payload?.style?.countOfList} .contactbox`
                      ]?.["borderColor"],
                  })
                : "",
              "border-top":
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .contactbox`
                ]?.["border-top"],
              borderTopPx:
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .contactbox`
                ]?.["border-top"]?.split(" ")[0],
              borderTopThik:
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .contactbox`
                ]?.["border-top"]?.split(" ")[1],
              borderTopColor: payload.style?.[
                `.contact-section${payload?.style?.countOfList} .contactbox`
              ]?.["borderTopColor"]
                ? convertRGBA({
                    rgbString:
                      payload.style?.[
                        `.contact-section${payload?.style?.countOfList} .contactbox`
                      ]?.["borderTopColor"],
                  })
                : "",
              "box-shadow":
                payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .contactbox`
                ]?.["box-shadow"],
              bs1: payload.style?.[
                `.contact-section${payload?.style?.countOfList} .contactbox`
              ]?.["box-shadow"]?.split(" ")[0],
              bs2: payload.style?.[
                `.contact-section${payload?.style?.countOfList} .contactbox`
              ]?.["box-shadow"]?.split(" ")[1],
              bs3: payload.style?.[
                `.contact-section${payload?.style?.countOfList} .contactbox`
              ]?.["box-shadow"]?.split(" ")[2],
              shadowColor: payload.style?.[
                `.contact-section${payload?.style?.countOfList} .contactbox`
              ]?.["shadowColor"]
                ? convertRGBA({
                    rgbString:
                      payload.style?.[
                        `.contact-section${payload?.style?.countOfList} .contactbox`
                      ]?.["shadowColor"],
                  })
                : "",
            },
            [`.contact-section${payload?.style?.countOfList} .contactbox iframe`]:
              {
                border:
                  payload.style?.[
                    `.contact-section${payload?.style?.countOfList} .contactbox iframe`
                  ]?.["border"],
                borderPx:
                  payload.style?.[
                    `.contact-section${payload?.style?.countOfList} .contactbox iframe`
                  ]?.["border"]?.split(" ")[0],
                borderThik:
                  payload.style?.[
                    `.contact-section${payload?.style?.countOfList} .contactbox iframe`
                  ]?.["border"]?.split(" ")[1],
                borderColor: payload.style?.[
                  `.contact-section${payload?.style?.countOfList} .contactbox iframe`
                ]?.["borderColor"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          `.contact-section${payload?.style?.countOfList} .contactbox iframe`
                        ]?.["borderColor"],
                    })
                  : "",
              },
            custom: payload.style?.custom,
          };
        });
        OpenModalCommonFunc(Cases.SETTING_CONTACT_US_PARENT, setAllPopupState);
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * data clear
   */
  const emptyData = () => {
    setIsBackgroundColor(false);
    setBoxSection(false);
    setId("");
    setIsEdit(false);
    setFormData({
      name: "",
      title: "",
      isTitleShow: true,
      subTitle: "",
      addLine1: "",
      addLine2: "",
      area: "",
      country: "",
      state: "",
      city: "",
      pinCode: "",
      mapLink: "",
      phone: "",
      email: "",
      latitude: "",
      longitude: "",
      iframe: "",
      theme: 1,
      navMapId: "",
      style: {
        [`.contact-section${countOfList}`]: {
          padding: "100px 0",
          "background-color": "rgb(255 255 255 / 100)",
        },
        image: "",
        [`.contact-section${countOfList}.bg-image`]: {
          "background-image": "",
        },
        [`.contact-section${countOfList}.bg-image::before`]: {
          "background-color": "",
        },
        [`.contact-section${countOfList} .section-title-area`]: {
          "margin-bottom": "50px",
        },
        [`.contact-section${countOfList} .section-title-area .section-subtitle`]:
          {
            "font-size": "16px",
            "font-weight": "600",
            color: "rgb(255 73 124 / 100)",
            "background-color": "rgb(255 73 124 / 0.2)",
            padding: "5px 20px",
            p1: "5px",
            p2: "20px",
            "border-radius": "15px",
            "margin-bottom": "15px",
            display: "inline-block",
          },
        [`.contact-section${countOfList} .section-title-area .section-title`]: {
          "font-size": "44px",
          "font-weight": "600",
          color: "rgb(0 0 0 / 100)",
        },
        [`.contact-section${countOfList} .inquiry-box`]: {
          "background-color": "rgb(255 255 255 / 100)",
          padding: "40px 40px",
          p1: "40px",
          p2: "40px",
          margin: "10px 0",
          "border-radius": "15px",
          border: "1px solid rgb(255 255 255 / 100)",
          borderPx: "1px",
          borderThik: "solid",
          borderColor: "rgb(255 255 255 / 100)",
          "border-top": "3px solid rgb(255 73 124 / 100)",
          borderTopPx: "3px",
          borderTopThik: "solid",
          borderTopColor: "rgb(255 73 124 / 100)",
          "box-shadow": "5px 5px 15px rgb(0 0 0 / 0.094)",
          bs1: "5px",
          bs2: "5px",
          bs3: "15px",
          shadowColor: "rgb(0 0 0 / 0.094)",
          "text-align": "center",
        },
        [`.contact-section${countOfList} .inquiry-box img`]: {
          "margin-bottom": "20px",
          "max-width": "60px",
        },
        [`.contact-section${countOfList} .inquiry-box h4`]: {
          color: "rgb(0 0 0 / 100)",
          "font-weight": "600",
          "font-size": "22px",
          "margin-bottom": "15px",
        },
        [`.contact-section${countOfList} .inquiry-box p`]: {
          color: "rgb(0 0 0 / 100)",
          "font-weight": "400",
          "font-size": "14px",
          "line-height": "1.6",
        },
        [`.contact-section${countOfList} .contactbox`]: {
          "background-color": "rgb(255 255 255 / 100)",
          padding: "40px 40px",
          p1: "40px",
          p2: "40px",
          "border-radius": "15px",
          border: "1px solid rgb(255 255 255 / 100)",
          borderPx: "1px",
          borderThik: "solid",
          borderColor: "rgb(255 255 255 / 100)",
          "border-top": "3px solid rgb(255 73 124 / 100)",
          borderTopPx: "3px",
          borderTopThik: "solid",
          borderTopColor: "rgb(255 73 124 / 100)",
          "box-shadow": "5px 5px 15px rgb(0 0 0 / 0.094)",
          bs1: "5px",
          bs2: "5px",
          bs3: "15px",
          shadowColor: "rgb(0 0 0 / 0.094)",
        },
        [`.contact-section${countOfList} .contactbox iframe`]: {
          border: "2px solid rgb(221 221 221 / 100)",
          borderPx: "2px",
          borderThik: "solid",
          borderColor: "rgb(221 221 221 / 100)",
        },
      },
      workingHour:
        "<p><strong>Working Hours</strong></p>\n\n<p><strong>Sunday</strong> : 09:00 AM - 01:00 PM<br />\n<strong>Monday</strong> : 09:00 AM - 01:00 PM<br />\n<strong>Tuesday</strong> :&nbsp;09:00 AM - 01:00 PM<br />\n<strong>Wednesday</strong> : 09:00 AM - 01:00 PM<br />\n<strong>Thursday</strong> :&nbsp;09:00 AM - 01:00 PM<br />\n<strong>Friday</strong> : 09:00 AM - 01:00 PM<br />\n<strong>Saturday</strong> : 09:00 AM - 01:00 PM</p>\n",
      webId: localStorage.getItem("webId"),
    });
    setLoaderShow(false);
    setAddFields({
      phoneInput: [
        {
          id: randomStr(2),
          phoneValue: "",
          dialCode: { value: "+91", label: "+91" },
        },
      ],
      emailInput: [{ id: randomStr(2), emailValue: "" }],
    });
    setSettingFormData({
      [`.contact-section${countOfList}`]: {
        padding: "100px 0",
        "background-color": "rgb(255 255 255 / 100)",
      },
      image: "",
      [`.contact-section${countOfList}.bg-image`]: {
        "background-image": "",
      },
      [`.contact-section${countOfList}.bg-image::before`]: {
        "background-color": "",
      },
      [`.contact-section${countOfList} .section-title-area`]: {
        "margin-bottom": "50px",
      },
      [`.contact-section${countOfList} .section-title-area .section-subtitle`]:
        {
          "font-size": "16px",
          "font-weight": "600",
          color: "rgb(255 73 124 / 100)",
          "background-color": "rgb(255 73 124 / 0.2)",
          padding: "5px 20px",
          p1: "5px",
          p2: "20px",
          "border-radius": "15px",
          "margin-bottom": "15px",
          display: "inline-block",
        },
      [`.contact-section${countOfList} .section-title-area .section-title`]: {
        "font-size": "44px",
        "font-weight": "600",
        color: "rgb(0 0 0 / 100)",
      },
      [`.contact-section${countOfList} .inquiry-box`]: {
        "background-color": "rgb(255 255 255 / 100)",
        padding: "40px 40px",
        p1: "40px",
        p2: "40px",
        margin: "10px 0",
        "border-radius": "15px",
        border: "1px solid rgb(255 255 255 / 100)",
        borderPx: "1px",
        borderThik: "solid",
        borderColor: "rgb(255 255 255 / 100)",
        "border-top": "3px solid rgb(255 73 124 / 100)",
        borderTopPx: "3px",
        borderTopThik: "solid",
        borderTopColor: "rgb(255 73 124 / 100)",
        "box-shadow": "5px 5px 15px rgb(0 0 0 / 0.094)",
        bs1: "5px",
        bs2: "5px",
        bs3: "15px",
        shadowColor: "rgb(0 0 0 / 0.094)",
        "text-align": "center",
      },
      [`.contact-section${countOfList} .inquiry-box img`]: {
        "margin-bottom": "20px",
        "max-width": "60px",
      },
      [`.contact-section${countOfList} .inquiry-box h4`]: {
        color: "rgb(0 0 0 / 100)",
        "font-weight": "600",
        "font-size": "22px",
        "margin-bottom": "15px",
      },
      [`.contact-section${countOfList} .inquiry-box p`]: {
        color: "rgb(0 0 0 / 100)",
        "font-weight": "400",
        "font-size": "14px",
        "line-height": "1.6",
      },
      [`.contact-section${countOfList} .contactbox`]: {
        "background-color": "rgb(255 255 255 / 100)",
        padding: "40px 40px",
        p1: "40px",
        p2: "40px",
        "border-radius": "15px",
        border: "1px solid rgb(255 255 255 / 100)",
        borderPx: "1px",
        borderThik: "solid",
        borderColor: "rgb(255 255 255 / 100)",
        "border-top": "3px solid rgb(255 73 124 / 100)",
        borderTopPx: "3px",
        borderTopThik: "solid",
        borderTopColor: "rgb(255 73 124 / 100)",
        "box-shadow": "5px 5px 15px rgb(0 0 0 / 0.094)",
        bs1: "5px",
        bs2: "5px",
        bs3: "15px",
        shadowColor: "rgb(0 0 0 / 0.094)",
      },
      [`.contact-section${countOfList} .contactbox iframe`]: {
        border: "2px solid rgb(221 221 221 / 100)",
        borderPx: "2px",
        borderThik: "solid",
        borderColor: "rgb(221 221 221 / 100)",
      },
    });
  };

  const handleAlignAndCloneSection = ({ isClone = false }) => {
    if (isClone) {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          cloneModal: true,
        };
      });
      setCloneFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          pageConfigDetailsLabel: pageConfigDetails[0]?.label,
          contentDetailName: contentDetail.name,
        };
      });
    } else {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          alignModal: true,
        };
      });
      setAlignFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          label: pageConfigDetails[0]?.label,
        };
      });
    }
  };
  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody
            className="support-ticket-font box-border-div"
            style={{ padding: "20px" }}
          >
            <Row>
              <div className="col-12">
                <CardBuilderHeaderSection
                  name={`Contact Us`}
                  subName="Address"
                  isCloneSection={true}
                  isAlignSection={true}
                  handleAlignAndCloneSection={handleAlignAndCloneSection}
                />
              </div>
              {contactDetail && (
                <>
                  <Col xl="12" md="6">
                    <ContactUSCart
                      name={contactDetail?.name}
                      contactUsId={contactDetail?.contactUsId}
                      handleChangeSettings={handleChangeSettings}
                      address={`${contactDetail?.addLine1}, ${contactDetail?.addLine2}, ${contactDetail?.area}, ${contactDetail?.city}, ${contactDetail?.state}, ${contactDetail?.country}, ${contactDetail?.pinCode} `}
                    />
                  </Col>
                </>
              )}

              <OpenPopupBtn
                openPopup={() => {
                  OpenModalCommonFunc(Cases.CONTACT_US, setAllPopupState);
                  setCollId(contentMapId);
                }}
                // name={btnName.CONTACT_US}
                name={`Add or View ${contactDetail?.name}`}
              />
              <DeleteSection
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
              />
            </Row>
          </CardBody>
        </Card>
      </Col>

      {allPopupState.contactusPopup && (
        <CommonPopup
          open={allPopupState.contactusPopup}
          close={() =>
            OpenModalCommonFunc(Cases.CONTACT_US, setAllPopupState, false)
          }
          handleSubmit={() =>
            handleSubmit({
              contactUsId: -1,
              isPrimary: null,
              defaultSetStar: "",
              isEditStar: false,
            })
          }
          modalName={
            contactDetail?.name ? contactDetail?.name : modalsName.CONATCTUS
          }
          modalCase={Cases.CONTACT_US}
          emptyData={emptyData}
          inputData={fromData}
          edit={false}
          oldTitle={titleUpdateKey.isContactUs}
          label={titleUpdateKey.Contact_Details}
          getData={getData}
          content={
            <ContactUsPopup
              handleEdit={handleEdit}
              getData={getContactUsList}
              handleSubmit={handleSubmit}
              emptyData={emptyData}
              simpleValidator={simpleValidator}
              webMasterDetail={webMasterDetail}
              imageUpload={imageUploadImages}
              getContactUsList={getContactUsList}
              imageUploadImages={imageUploadImages}
              contactDetail={contactDetail}
              submitStyleValue={submitStyleValue}
              countOfList={countOfList}
            />
          }
        />
      )}
      {/* setting Service Counter parent popup */}
      <CommonPopup
        open={allPopupState.contactUsSettingPopup}
        close={() =>
          OpenModalCommonFunc(
            Cases.SETTING_CONTACT_US_PARENT,
            setAllPopupState,
            false
          )
        }
        modalName={
          contactDetail?.name
            ? contactDetail?.name
            : modalsName.SETTING_CONTACT_US_PARENT
        }
        modalCase={Cases.SETTING_CONTACT_US_PARENT}
        handleSubmit={handleSubmitSetting}
        emptyData={emptyData}
        // inputData={formDataChild}
        edit={false}
        content={
          <SettingContactUsParentPopup
            imageUpload={imageUploadImages}
            handleSubmitSetting={handleSubmitSetting}
            countOfList={countOfList}
          />
        }
      />
    </>
  );
};

export default ContactUs;
