import axios from "axios";
import {
  GET_COMPANY_LOGO,
  GET_DETAILS_COMPANY_LOGO,
  POST_COMPANY_LOGO,
  PUT_COMPANY_LOGO_ORDER,
  PUT_STATUS_COMPANY_LOGO,
} from "../../apiConstants";

/**
 * get company logo ,tagline & services data
 * @param {Parameters} paramObj
 * @returns
 */
export const getCompanyLogo = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_COMPANY_LOGO}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * enable-disable
 * @param {body} body
 * @returns
 */
export const updateStatus = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_STATUS_COMPANY_LOGO}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * create company logo ,tagline & services
 * @param {body} body
 * @returns
 */
export const postCompanyLogo = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${POST_COMPANY_LOGO}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * update company logo ,tagline & services
 * @param {body} body
 * @returns
 */
export const putCompanyLogo = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${POST_COMPANY_LOGO}`,
    body,
    {
      headers: { Authorization: jwt_token },
      params: { id: body.id },
    }
  );
};

/**
 * delete company logo ,tagline & services
 * @param {id} downloadId
 * @returns
 */
export const deleteCompanyLogo = (downloadId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${POST_COMPANY_LOGO}`, {
    headers: { Authorization: jwt_token },
    params: { downloadId },
  });
};

/**
 * get details company logo ,tagline & services
 * @param {id} downloadId
 * @returns
 */
export const getDetailsCompanyLogo = (downloadId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${GET_DETAILS_COMPANY_LOGO}`,
    {
      headers: { Authorization: jwt_token },
      params: { downloadId },
    }
  );
};

/**
 * short order company logo ,tagline & services
 * @param {body} updateOrder
 * @returns
 */
export const sort_order_CompanyLogo = (updateOrder) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_COMPANY_LOGO_ORDER}`,
    { updateOrder: updateOrder },
    {
      headers: { Authorization: jwt_token },
    }
  );
};
