import React, { useContext } from "react";
import { Col, Container, Input, Label, Row } from "reactstrap";
import ProductBuyContext from "../../../context/ProductBuyContext/ProductBuyContext";
import IsShowTitleORNot from "../../../common/isShowTitleORNot";

const ProductBuyParentPopup = () => {
  const { isEdit, formData, setFormData } = useContext(ProductBuyContext);
  /**
   * input value change
   * @param {event} e
   */
  const changeFormValue = (e) => {
    const { value, name, checked } = e.target;
    setFormData((prev) => {
      let updatedData =
        name === "isTitleShow"
          ? {
              ...prev,
              [name]: checked,
            }
          : {
              ...prev,
              [name]: value,
            };
      return updatedData;
    });
  };

  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="12">
            <div className="mt-4 d-flex flex-column justify-content-center ">
              <h6>{isEdit ? "Edit" : "Add New"}</h6>
              <Row className="mt-4">
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">
                    Title *{" "}
                    <IsShowTitleORNot
                      checked={formData?.isTitleShow}
                      onChange={changeFormValue}
                    />
                  </Label>
                  <Input
                    className="form-control"
                    name="title"
                    type="text"
                    placeholder="Title *"
                    value={formData?.title}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    required
                  />
                  {/* <p style={{ color: "red" }}>
                {simpleValidator.current.message(
                  "Title",
                  formData?.title,
                  "required"
                )}
              </p> */}
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">Item Limit</Label>
                  <Input
                    className="form-control"
                    name="itemLimit"
                    type="text"
                    placeholder="Title *"
                    value={formData?.itemLimit}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    required
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ProductBuyParentPopup;
