import React, { useContext } from "react";
import { Col, Input, Label, Row } from "reactstrap";
import PopupDialogContext from "../../../context/PopupDialogContext/PopupDialogContext";
import { fontWeight } from "../../../../constant";
import CommonColor from "../../../common/CommonColor";
import CustomStyle from "../../../common/CustomStyle";

const PopupDialogThemeOne = (props) => {
  const { settingFormData, setSettingFormData } =
    useContext(PopupDialogContext);

  /**
   * input value change
   * @param {event} e
   */

  const handleChangePopupModelHeader = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".popup .modal-header": {
          ...prev[".popup .modal-header"],
          [name]: value,
        },
      };
    });
  };
  const handleChangePopupModelTitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".popup .modal-title": {
          ...prev[".popup .modal-title"],
          [name]: value,
        },
      };
    });
  };
  const handleChangePopupModelBody = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".popup .modal-body": {
          ...prev[".popup .modal-body"],
          [name]: value,
        },
      };
    });
  };
  const handleChangePopupModalBtn = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".popup .modal-btn": {
          ...prev[".popup .modal-btn"],
          [name]: value,
        },
      };
    });
  };
  const handleChangePopupModalShotDescription = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".popup .modal-shortDescription": {
          ...prev[".popup .modal-shortDescription"],
          [name]: value,
        },
      };
    });
  };
  const handleChangePopupModalDescription = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".popup .modal-description": {
          ...prev[".popup .modal-description"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeCustomStyle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  return (
    <>
      <Row className="mt-4">
        <h4 className="mb-3">modal-content</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".popup .modal-content"]?.["background-color"]
            }
            setSettingFormData={setSettingFormData}
            className={".popup .modal-content"}
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">modal-header</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Pagination position (Default : Default)"}
          </Label>
          <select
            name="justify-content"
            id="justify-content"
            className="form-select"
            value={
              settingFormData?.[".popup .modal-header"]?.["justify-content"]
            }
            onChange={(e) => handleChangePopupModelHeader(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            <option value="start">{"Start"}</option>
            <option value="space-between">{"Default"}</option>
            <option value="end">{"end"}</option>
            <option value="center">{"center"}</option>
          </select>
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">modal-title</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 20)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[".popup .modal-title"]?.[
              "font-size"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangePopupModelTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={settingFormData?.[".popup .modal-title"]?.["color"]}
            setSettingFormData={setSettingFormData}
            className={".popup .modal-title"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">modal-body</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Padding (Default : 1)"}</Label>
          <Input
            className="form-control"
            name="padding"
            type="number"
            placeholder="Padding"
            value={settingFormData?.[".popup .modal-body"]?.["padding"]
              ?.split(" ")[0]
              ?.replace(/rem/g, "")}
            onChange={(e) => handleChangePopupModelBody(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">modal-btn</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 16)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[".popup .modal-btn"]?.[
              "font-size"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangePopupModalBtn(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={settingFormData?.[".popup .modal-btn"]?.["font-weight"]}
            onChange={(e) => handleChangePopupModalBtn(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={settingFormData?.[".popup .modal-btn"]?.["color"]}
            setSettingFormData={setSettingFormData}
            className={".popup .modal-btn"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={settingFormData?.[".popup .modal-btn"]?.["background-color"]}
            setSettingFormData={setSettingFormData}
            className={".popup .modal-btn"}
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 5 - 20)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="p1"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[".popup .modal-btn"]?.["p1"]?.replace(
                /px/g,
                ""
              )}
              onChange={(e) => handleChangePopupModalBtn(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p2"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[".popup .modal-btn"]?.["p2"]?.replace(
                /px/g,
                ""
              )}
              onChange={(e) => handleChangePopupModalBtn(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border Radius (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[".popup .modal-btn"]?.[
              "border-radius"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangePopupModalBtn(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin Bottom (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin Bottom"
            value={settingFormData?.[".popup .modal-btn"]?.[
              "margin-bottom"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangePopupModalBtn(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">modal-shortDescription</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 20)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[".popup .modal-shortDescription"]?.[
              "font-size"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangePopupModalShotDescription(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[".popup .modal-shortDescription"]?.[
                "font-weight"
              ]
            }
            onChange={(e) => handleChangePopupModalShotDescription(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".popup .modal-shortDescription"]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".popup .modal-shortDescription"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding Top (Default : 12)"}
          </Label>
          <Input
            className="form-control"
            name="padding-top"
            type="number"
            placeholder="Padding Top"
            value={settingFormData?.[".popup .modal-shortDescription"]?.[
              "padding-top"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangePopupModalShotDescription(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">modal-shortDescription</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 20)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[".popup .modal-description"]?.[
              "font-size"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangePopupModalDescription(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[".popup .modal-description"]?.["font-weight"]
            }
            onChange={(e) => handleChangePopupModalDescription(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={settingFormData?.[".popup .modal-description"]?.["color"]}
            setSettingFormData={setSettingFormData}
            className={".popup .modal-description"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding Top (Default : 12)"}
          </Label>
          <Input
            className="form-control"
            name="padding-top"
            type="number"
            placeholder="Padding Top"
            value={settingFormData?.[".popup .modal-description"]?.[
              "padding-top"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangePopupModalDescription(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <CustomStyle
        handleChange={handleChangeCustomStyle}
        value={settingFormData?.custom}
      />
    </>
  );
};

export default PopupDialogThemeOne;
