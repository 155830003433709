import { AlertOctagon, Home, Paperclip } from "react-feather";

export default function getOrgName() {
  return localStorage.getItem("orgName") === null
    ? "Admin"
    : localStorage.getItem("orgName");
}

export function getHwId() {
  let id = localStorage.getItem("hwId");
  return id;
}

export const MENUITEMS = [
  {
    menutitle: `Hello, ${getOrgName()}`,

    Items: [
      {
        path: `${process.env.PUBLIC_URL}/${getHwId()}/dashboard/ecommerce`,
        icon: Home,
        title: "Dashboard",
        type: "link",
      },
      {
        path: `${
          process.env.PUBLIC_URL
        }/${getHwId()}/PersonalPage/PersonalPage1`,
        icon: Paperclip,
        title: "Personal Page",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/${getHwId()}/InquiryPage/InquiryPage`,
        icon: AlertOctagon,
        title: "Page Inquiry",
        type: "link",
      },
      // {
      //   path: `${process.env.PUBLIC_URL}/organization/organization`,
      //   icon: Table,
      //   title: "Organization",
      //   type: "link",
      // },
      // {
      //   path: `${process.env.PUBLIC_URL}/packages/package`,
      //   icon: Package,
      //   title: "Packages",
      //   type: "link",
      // },
      // {
      //   path: `${process.env.PUBLIC_URL}/shipingmaster/shipingmaster`,
      //   icon: ShoppingBag,
      //   title: "Shipping Master",
      //   type: "link",
      // },
      // {
      //   path: `${process.env.PUBLIC_URL}/orderstatusmaster/orderstatusmaster`,
      //   icon: Activity,
      //   title: "Order Status Master",
      //   type: "link",
      // },
      // {
      //   title: "QR",
      //   icon: Info,
      //   type: "sub",
      //   active: false,
      //   children: [
      //     {
      //       path: `${process.env.PUBLIC_URL}/orderList/orderList`,
      //       title: "Order List",
      //       type: "link",
      //     },
      //     {
      //       path: `${process.env.PUBLIC_URL}/qrListAll/qrListAll`,
      //       title: "QR List All",
      //       type: "link",
      //     },
      //   ],
      // },
      // {
      //   title: "NFC",
      //   icon: Info,
      //   type: "sub",
      //   active: false,
      //   children: [
      //     {
      //       path: `${process.env.PUBLIC_URL}/nfcOrderList/orderList`,
      //       title: "Order List",
      //       type: "link",
      //     },
      //     {
      //       path: `${process.env.PUBLIC_URL}/nfcList/nfcList`,
      //       title: "NFC List",
      //       type: "link",
      //     },
      //   ],
      // },

      // {
      //   title: "Roles & Privillages",
      //   icon: Key,
      //   type: "sub",
      //   active: false,
      //   children: [
      //     {
      //       path: `${process.env.PUBLIC_URL}/PrivillageManager/PrivillageManager`,
      //       title: "Privillage Manager",
      //       type: "link",
      //     },
      //     {
      //       path: `${process.env.PUBLIC_URL}/ModualManager/ModualManager`,
      //       title: "Module Manager",
      //       type: "link",
      //     },
      //   ],
      // },
    ],
  },
];

export const SELLER_MENUITEMS = [
  //   {
  //     menutitle: "Hello, " + localStorage.getItem("name"),
  //     Items: [
  //       {
  //         path: `${process.env.PUBLIC_URL}/dashboard/ecommerce`,
  //         icon: Home,
  //         title: "Dashboard",
  //         type: "link",
  //       },
  //       {
  //         title: "QR",
  //         icon: Info,
  //         type: "sub",
  //         active: false,
  //         children: [
  //           {
  //             path: `${process.env.PUBLIC_URL}/orderList/orderList`,
  //             title: "Order List",
  //             type: "link",
  //           },
  //           {
  //             path: `${process.env.PUBLIC_URL}/qrListAll/qrListAll`,
  //             title: "QR List ",
  //             type: "link",
  //           },
  //         ],
  //       },
  //       {
  //         title: "NFC",
  //         icon: Info,
  //         type: "sub",
  //         active: false,
  //         children: [
  //           {
  //             path: `${process.env.PUBLIC_URL}/nfcOrderList/orderList`,
  //             title: "Order List",
  //             type: "link",
  //           },
  //           {
  //             path: `${process.env.PUBLIC_URL}/nfcList/nfcList`,
  //             title: "NFC List",
  //             type: "link",
  //           },
  //         ],
  //       },
  //       {
  //         path: `${process.env.PUBLIC_URL}/userProfile/userProfile`,
  //         icon: User,
  //         title: "Profile",
  //         type: "link",
  //       },
  //     ],
  //   },
];
