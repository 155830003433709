import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Input, Label, Row } from "reactstrap";
import { getNavbarList } from "../../../apis/AboutApi/AboutUsApi";
import { TostMessage } from "../../../utils";
import { AboutUsTheme, ServiceCounterTheme } from "../../../constant";
import PopupDialogContext from "../../../context/PopupDialogContext/PopupDialogContext";

const PopupDialogParentPopup = (props) => {
  const { setFormData, fromData, isEdit } = useContext(PopupDialogContext);

  const [navMapsData, setNavMapsData] = useState([]);
  const { simpleValidator } = props;

  const getNavMaps = async () => {
    try {
      const { data } = await getNavbarList();
      if (data.status === 200) {
        setNavMapsData(data.payload.data);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  // useEffect(() => {
  //   getNavMaps();
  // }, []);

  /**
   * input value change
   * @param {event} e
   */
  const changeFormValue = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="12">
            <div className="mt-4 d-flex flex-column justify-content-center ">
              <h6>{isEdit ? "Edit" : "Add New"}</h6>
              <Row className="mt-4">
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Title *"}</Label>
                  <Input
                    className="form-control"
                    name="title"
                    type="text"
                    placeholder="Title *"
                    value={fromData?.title}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    required
                  />
                  {/* <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "Title",
                      fromData?.title,
                      "required"
                    )}
                  </p> */}
                </Col>

                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Sub Title *"}</Label>
                  <Input
                    className="form-control"
                    name="subTitle"
                    type="text"
                    placeholder="Sub Title *"
                    value={fromData?.subTitle}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    required
                  />
                  {/* <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "Sub Title",
                      fromData?.subTitle,
                      "required"
                    )}
                  </p> */}
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Theme"}</Label>
                  <select
                    name="theme"
                    id="theme"
                    className="form-select"
                    onChange={(e) => changeFormValue(e)}
                    value={fromData?.theme}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    {AboutUsTheme.map((ele) => {
                      return (
                        <option value={ele.theme} key={ele.theme}>
                          {ele.themeName}
                        </option>
                      );
                    })}
                  </select>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PopupDialogParentPopup;
