import React, { useContext, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import { Cases, DELETE_CONFIRM, modalsName } from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import CommonPopup from "../../../Modals/commonPopup";
import CareerPopup from "./CareerPopup";
import EditIcon from "@mui/icons-material/Edit";
import CareerParentPopup from "./CareerParentPopup";
import {
  isCheckValue,
  isEmptyObject,
  randomStr,
  rgbColorCreate,
} from "../../../../utils/helper";
import { FILE_TYPE, ThemeNo, titleUpdateKey } from "../../../../constant";
import { IconButton } from "@material-ui/core";
import { Settings, Trash } from "react-feather";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import SimpleReactValidator from "simple-react-validator";
import CareerContext from "../../../context/CareerContext/CareerContext";
import {
  deleteCareer,
  getCareer,
  getCareerDetails,
  postCareer,
  putUpdateCareer,
  updateStatus,
} from "../apis/CareerApi/CareerApi";

import { FILE_UPLOAD_API } from "../apis/SliderApi/SliderApi";
import SettingCareerParentPopup from "./SettingCareerParentPopup";
import DeleteSection from "../../../common/DeleteSection";
import SettingMultiWebContext from "../../../../context/SettingMultiWebContext/SettingMultiWebContext";
import {
  CareerStyleOneReset,
  CareerStyleOneSubFun,
  CareerThemeOneFunction,
} from "./CareerStyleFunction";

const Career = (props) => {
  const {
    pageConfigDetails,
    webMasterDetail,
    getData,
    careerDetail,
    contentMapId,
    ByteMiniWeb_Dashboard,
    contentDetail,
  } = props;
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );
  const { setCloneAlignModal, setAlignFormData, setCloneFormData } = useContext(
    SettingMultiWebContext
  );
  const parentValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const childValidator = useRef(new SimpleReactValidator());
  const {
    fromData,
    setFormData,
    formDataChild,
    setFormDataChild,
    id,
    setId,
    setIsEdit,
    isEdit,
    setFilter,
    apiDataOurTeam: apiDataImageParent,
    setApiDataOurTeam: setApiDataImageParent,
    setSkill,
    setGalleryImgId,
    setSettingFormData,
    settingFormData,
    isParent,
    setIsParent,
    childId,
    setChildId,
    navMapId,
    setNavMapId,
    setBackImageToggle,
    collId,
    setCollId,
    setBoxSection,
    boxSection,
    isBackgroundColor,
    setIsBackgroundColor,
    setTheme,
    theme,
    setImgShow,
    sectionPageName,
    setSectionPageName,
  } = useContext(CareerContext);
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * get career parent list
   * @param {status} status
   */
  const getCareerParentList = async ({
    parentId = null,
    isClose = false,
    filter,
  }) => {
    try {
      let paramObj = {
        search: { showAll: true },
      };
      if (
        ((parentId && parentId !== null) ||
          (id && isClose === false && isParent === false)) &&
        isParent === false
      ) {
        paramObj["search"] = { parentId: parentId || id };
        setId(parentId || id);
        if (filter?.length) {
          paramObj["search"] = { ...paramObj.search, title: filter };
        }
      } else {
        paramObj["search"] = { parentId: null };
      }
      const { data } = await getCareer(paramObj);
      if (isCheckValue({ value: parentId })) {
        setChildId(parentId);
      }
      if (data.status === 200) {
        if (
          isParent === false &&
          ((parentId && parentId !== null) || id) &&
          isClose === false
        ) {
          setApiDataImageParent({
            ...apiDataImageParent,
            isLoading: false,
            data: data.payload.data.map((elem) => {
              return {
                id: elem?.title + elem?.careerId,
                Id: elem?.careerId,
                name: elem?.title,
                image: "",
                webId: elem?.webId,
                createdAt: elem?.createdAt,
                isActive: elem?.isActive,
                parentId: elem?.parentId,
              };
            }),
          });
          OpenModalCommonFunc(Cases.CAREER, setAllPopupState);
        } else if (parentId && parentId !== null) {
          OpenModalCommonFunc(Cases.CAREER, setAllPopupState);
          setApiDataImageParent({
            ...apiDataImageParent,
            isLoading: false,
            data: data.payload.data.map((elem) => {
              return {
                id: elem?.title + elem?.careerId,
                Id: elem?.careerId,
                name: elem?.title,
                image: elem?.image,
                webId: elem?.webId,
                createdAt: elem?.createdAt,
                isActive: elem?.isActive,
                parentId: elem?.parentId,
              };
            }),
          });
        } else {
          setApiDataImageParent({
            ...apiDataImageParent,
            isLoading: false,
            data: data.payload.data,
          });
        }
        if (isClose && isClose !== false) {
          setIsParent(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * item career enable disable api call
   * @param {careerId} careerId
   * @param {status} isActive
   */

  const handleChangeCareer = async (careerId, isActive, isChild, parentId) => {
    try {
      let body = {
        careerId,
        isActive: isActive ? false : true,
      };
      if (parentId) {
        body["parentId"] = parentId;
      }
      const { data } = await updateStatus(body);
      if (data.status === 200) {
        if (isChild) {
          getCareerParentList({ parentId: parentId });
        } else {
          ByteMiniWeb_Dashboard();
        }
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * file upload
   * @param {file} file
   */
  const imageUploadImages = async (file) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.CAREER);
    formData.append("file", file);
    setLoaderShow(true);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        setSettingFormData((prev) => {
          return {
            ...prev,
            ".career-section.bg-image": {
              "background-image": data.payload.baseUrl + data.payload.imageUrl,
            },
          };
        });

        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * create and update career
   */
  const submitForm = async () => {
    if (
      (isParent === false && childId >= -1 && isEdit) ||
      (id >= -1 && isEdit)
    ) {
      setLoaderShow(true);
      try {
        let formData = {};
        if (isParent && isParent !== false) {
          formData = { ...fromData, careerId: id, contentMapId: collId };
        } else {
          formData = {
            ...formDataChild,
            parentId: id,
            navMapId,
            careerId: childId,
            contentMapId: collId,
          };
        }
        const { data } = await putUpdateCareer(formData);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            ByteMiniWeb_Dashboard();
          } else {
            getCareerParentList({ parentId: id });
          }
          emptyData();
          OpenModalCommonFunc(Cases.CAREER_PARENT, setAllPopupState, false);
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    } else {
      try {
        setLoaderShow(true);
        let formData = {};
        if (isParent && isParent !== false) {
          formData = { ...fromData, contentMapId: collId };
        } else {
          formData = {
            ...formDataChild,
            parentId: id,
            navMapId,
            order: apiDataImageParent.data.length + 1,
            contentMapId: collId,
          };
        }
        const { data } = await postCareer(formData);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            ByteMiniWeb_Dashboard();
          } else {
            getCareerParentList({ parentId: id });
          }
          emptyData();
          OpenModalCommonFunc(Cases.CAREER_PARENT, setAllPopupState, false);
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };
  const handleSubmit = async (formValidationCondition) => {
    let validatorFields;
    if (formValidationCondition === "parentForm") {
      validatorFields = parentValidator.current;
    } else if (formValidationCondition === "childForm") {
      validatorFields = childValidator.current;
    }
    validatorFields.showMessages();

    if (validatorFields.allValid()) {
      try {
        // Process form data based on the formType
        if (formValidationCondition === "parentForm") {
          submitForm();
        } else if (formValidationCondition === "childForm") {
          submitForm();
        }
        validatorFields.hideMessages();
      } catch (error) {
        // Handle error for form submission
      }
    } else {
      // Force update to display validation messages
      forceUpdate({});
    }
  };

  /**
   * create and update career
   */
  const handleSubmitSetting = async (isRestore = false, isClose = true) => {
    if (id >= -1 && isEdit) {
      setLoaderShow(true);
      try {
        let newData;
        if (isRestore) {
          const isConfirm = window.confirm(
            "Are you sure want to restore style ?"
          );
          if (isConfirm) {
            newData = {
              styleRestore: true,
              careerId: id,
            };
          } else {
            setLoaderShow(false);
            return;
          }
        } else {
          if (+theme === 1) {
            newData = CareerStyleOneSubFun({
              settingFormData,
              boxSection,
            });
            if (isBackgroundColor) {
              newData[".career-section"] = {
                ...newData?.[".career-section"],
                "background-color": settingFormData[".career-section"][
                  "background-color"
                ]
                  ? rgbColorCreate({
                      rgbString:
                        settingFormData[".career-section"]["background-color"],
                    })
                  : "",
              };
            }
            if (boxSection) {
              newData[".career-section.bg-image::before"] = {
                ...newData[".career-section.bg-image::before"],
                "border-radius": `${settingFormData[".career-section"][
                  "border-radius"
                ]?.replace("rem", "")}rem`,
              };
            }
          } else {
          }

          const keysToRemove = [
            "bs1",
            "bs2",
            "bs3",
            "borderPx",
            "borderThik",
            "p1",
            "p2",
            "image",
          ];
          function removeKeys(obj, keys) {
            for (const key in obj) {
              if (typeof obj[key] === "object") {
                removeKeys(obj[key], keys);
              } else if (keys.includes(key)) {
                delete obj[key];
              }
            }
          }
          removeKeys(newData, keysToRemove);
          newData = {
            style: newData,
            careerId: id,
          };
        }

        const { data } = await putUpdateCareer(newData);
        if (data.status === 200) {
          getCareerParentList({ isClose: true });
          if (isClose) {
            OpenModalCommonFunc(
              Cases.SETTING_CAREER_PARENT,
              setAllPopupState,
              false
            );
            emptyData();
            setBackImageToggle(false);
          }
          setLoaderShow(false);
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  /**
   * get data career
   * @param {id} careerId
   */
  const handleEditCareer = async (careerId, isParent) => {
    try {
      setLoaderShow(true);
      const { data } = await getCareerDetails(careerId);
      if (data.status === 200) {
        let payload = data.payload.data;
        setSectionPageName(payload?.title);
        if (isParent) {
          setId(careerId);
          setIsParent(true);
          setFormData({
            title: payload?.title || "",
            subTitle: payload?.subTitle || "",
            navMapId: payload?.navMapId || "",
            isTitleShow: payload?.isTitleShow ? payload?.isTitleShow : false,
            webId: localStorage.getItem("webId"),
            style: payload?.style || "",
          });
          OpenModalCommonFunc(Cases.CAREER_PARENT, setAllPopupState);
        } else {
          setChildId(careerId);
          setIsParent(false);
          setFormDataChild({
            title: payload?.title || "",
            subTitle: payload?.subTitle || "",
            description: payload?.description || "",
            isTitleShow: payload?.isTitleShow ? payload?.isTitleShow : false,
            parentId: payload?.parentId || "",
            order: payload?.order || 1,
            webId: localStorage.getItem("webId"),
          });
          OpenModalCommonFunc(Cases.CAREER, setAllPopupState);
        }
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * get setting data
   * @param {id} careerId
   */
  const handleChangeSettings = async (careerId, isThemeEdit = false) => {
    try {
      setLoaderShow(true);
      setId(careerId);
      setSettingFormData((prev) => {
        return {
          ...prev,
          careerId,
        };
      });
      const { data } = await getCareerDetails(careerId);
      if (data.status === 200) {
        const themeCount = data?.payload?.data?.theme;
        setTheme(themeCount);
        setImgShow(true);
        let payload = data.payload.data;
        setSectionPageName(payload.title);
        if (
          +themeCount === ThemeNo.ThemeOne &&
          isEmptyObject(data?.payload?.data?.style)
        ) {
          setSettingFormData({ ...CareerStyleOneReset });
          setBoxSection(false);
          setBackImageToggle(false);
          setIsBackgroundColor(false);
        } else {
          if (
            payload.style?.[".career-section.bg-image"]?.["background-image"]
          ) {
            setBackImageToggle(true);
          } else {
            setBackImageToggle(false);
          }
          if (
            payload.style?.[".career-section"]?.["width"] ||
            payload.style?.[".career-section"]?.["margin"] ||
            payload.style?.[".career-section"]?.["border-radius"]
          ) {
            setBoxSection(true);
          } else {
            setBoxSection(false);
          }
          if (payload.style?.[".career-section"]?.["background-color"]) {
            setIsBackgroundColor(true);
          }
          let getStyle;
          if (+themeCount === 1) {
            getStyle = CareerThemeOneFunction(payload);
          } else {
            getStyle = CareerThemeOneFunction(payload);
          }
          setSettingFormData((prev) => {
            return {
              ...prev,
              ...getStyle,
            };
          });
        }
        if (!isThemeEdit) {
          OpenModalCommonFunc(Cases.SETTING_CAREER_PARENT, setAllPopupState);
          TostMessage({ message: data, type: "success" });
        }
        setIsEdit(true);
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * delete career
   * @param {id} careerId
   */
  const handleDelete = async (careerId, isParent = false) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await deleteCareer(careerId);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            ByteMiniWeb_Dashboard();
          } else {
            getCareerParentList({ packageId: childId });
          }
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * clear data
   */
  const emptyData = () => {
    setIsBackgroundColor(false);
    setBoxSection(false);
    setIsEdit(false);
    setIsParent(false);
    setChildId("");
    setFormData({
      title: "",
      subTitle: "",
      navMapId: "",
      theme: 1,
      isTitleShow: true,
      webId: localStorage.getItem("webId"),
      style: {
        ".career-section": {
          padding: "100px 0",
          "background-color": "rgb(255 255 255 / 100)",
        },
        ".career-section .section-title-area": {
          "margin-bottom": "50px",
        },
        ".career-section.bg-image": {
          "background-image": "",
        },
        ".career-section.bg-image::before": {
          "background-color": "",
        },
        ".career-section .section-title-area .section-subtitle": {
          "font-size": "16px",
          "font-weight": "600",
          color: "rgb(255 73 124 / 100)",
          display: "inline-block",
          "background-color": "rgb(255 73 124 / 0.2)",
          padding: "5px 20px",
          "border-radius": "15px",
          "margin-bottom": "15px",
        },
        ".career-section .section-title-area .section-title": {
          "font-size": "44px",
          "font-weight": "600",
          color: "rgb(0 0 0 / 100)",
        },
        ".career-section .feature-list": {
          padding: "20px",
          margin: "10px 0",
          "background-color": "rgb(255 255 255 / 100)",
          "box-shadow": "0 0px 15px rgb(0 0 0 / 0.188)",
          shadowColor: "rgb(0 0 0 / 0.188)",
          "border-radius": "0px",
        },
        ".career-section .feature-list h5": {
          "font-size": "20px",
          "font-weight": "600",
          color: "rgb(255 73 124 / 100)",
          "margin-bottom": "10px",
        },
        ".career-section .feature-list p": {
          "font-size": "16px",
          "font-weight": "normal",
          color: "rgb(102 102 102 / 100)",
          "line-height": "1.4",
          "text-align": "justify",
        },
        ".contact-form-div .btn.btn-submit": {
          color: "rgb(255 255 255 / 100)",
          "background-color": "rgb(255 73 124 / 100)",
          "border-color": "rgb(255 73 124 / 100)",
        },
      },
    });
    setSkill([{ id: randomStr(2), skill: "", parentageOfSkill: "" }]);
    setGalleryImgId("");
    setFormDataChild({
      title: "",
      subTitle: "",
      description: "",
      order: 1,
      isTitleShow: true,
      webId: localStorage.getItem("webId"),
      navMapId: "",
    });
    setLoaderShow(false);
    setSettingFormData({
      ".career-section": {
        padding: "100px 0",
        "background-color": "rgb(255 255 255 / 100)",
      },
      ".career-section .section-title-area": {
        "margin-bottom": "50px",
      },
      ".career-section.bg-image": {
        "background-image": "",
      },
      ".career-section.bg-image::before": {
        "background-color": "",
      },
      ".career-section .section-title-area .section-subtitle": {
        "font-size": "16px",
        "font-weight": "600",
        color: "rgb(255 73 124 / 100)",
        display: "inline-block",
        "background-color": "rgb(255 73 124 / 0.2)",
        padding: "5px 20px",
        "border-radius": "15px",
        "margin-bottom": "15px",
      },
      ".career-section .section-title-area .section-title": {
        "font-size": "44px",
        "font-weight": "600",
        color: "rgb(0 0 0 / 100)",
      },
      ".career-section .feature-list": {
        padding: "20px",
        margin: "10px 0",
        "background-color": "rgb(255 255 255 / 100)",
        "box-shadow": "0 0px 15px rgb(0 0 0 / 0.188)",
        shadowColor: "rgb(0 0 0 / 0.188)",
        "border-radius": "0px",
      },
      ".career-section .feature-list h5": {
        "font-size": "20px",
        "font-weight": "600",
        color: "rgb(255 73 124 / 100)",
        "margin-bottom": "10px",
      },
      ".career-section .feature-list p": {
        "font-size": "16px",
        "font-weight": "normal",
        color: "rgb(102 102 102 / 100)",
        "line-height": "1.4",
        "text-align": "justify",
      },
      ".contact-form-div .btn.btn-submit": {
        color: "rgb(255 255 255 / 100)",
        "background-color": "rgb(255 73 124 / 100)",
        "border-color": "rgb(255 73 124 / 100)",
      },
      careerId: "",
    });
  };

  const handleAlignAndCloneSection = ({ isClone = false }) => {
    if (isClone) {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          cloneModal: true,
        };
      });
      setCloneFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          pageConfigDetailsLabel: pageConfigDetails[0]?.label,
          contentDetailName: contentDetail.name,
        };
      });
    } else {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          alignModal: true,
        };
      });
      setAlignFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          label: pageConfigDetails[0]?.label,
        };
      });
    }
  };

  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody
            className="support-ticket-font box-border-div"
            style={{ padding: "20px" }}
          >
            <Row>
              <CardBuilderHeaderSection
                addBtnTitle={pageConfigDetails[0]?.label}
                name={pageConfigDetails[0]?.label}
                isAdd={!careerDetail && true}
                mainEditSection={true}
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
                openModal={() => {
                  OpenModalCommonFunc(Cases.CAREER_PARENT, setAllPopupState);
                  setSectionPageName(modalsName.CAREER_PARENT);
                  emptyData();
                  setIsParent(true);
                  setCollId(contentMapId);
                }}
                isCloneSection={true}
                isAlignSection={true}
                handleAlignAndCloneSection={handleAlignAndCloneSection}
              />
              {careerDetail && (
                <Col xl="12 xl-50 box-col-6" md="6">
                  <Card className="ecommerce-widget mb-0">
                    <CardBody
                      className="support-ticket-font"
                      style={{ padding: "20px" }}
                    >
                      <div className="col-12">
                        <CardBuilderHeaderSection
                          name={careerDetail?.title}
                          checked={careerDetail?.isActive}
                          switchToggle={true}
                          id={careerDetail?.careerId}
                          handleChange={handleChangeCareer}
                        />
                        <OpenPopupBtn
                          openPopup={() => {
                            setSectionPageName(careerDetail.title);
                            getCareerParentList({
                              parentId: careerDetail.careerId,
                            });
                            setNavMapId(careerDetail?.navMapId);
                            setFilter("");
                            setCollId(contentMapId);
                          }}
                          name={`Add or View ${careerDetail?.title}`}
                        />
                      </div>
                      <div className="d-flex justify-content-end ">
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            setCollId(contentMapId);
                            handleEditCareer(careerDetail.careerId, true);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() =>
                            handleDelete(careerDetail.careerId, true)
                          }
                        >
                          <Trash />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleChangeSettings(careerDetail.careerId);
                          }}
                        >
                          <Settings />
                        </IconButton>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
              <DeleteSection
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
              />
            </Row>
          </CardBody>
        </Card>
      </Col>
      <CommonPopup
        open={allPopupState.careerChildPopup}
        close={() => {
          OpenModalCommonFunc(Cases.CAREER, setAllPopupState, false);
          setIsParent(false);
          getCareerParentList({ isClose: true });
        }}
        apiCall={getCareerParentList}
        modalName={sectionPageName ? sectionPageName : modalsName.CAREER}
        modalCase={Cases.CAREER}
        handleSubmit={handleSubmit}
        validationForm={"childForm"}
        emptyData={emptyData}
        inputData={fromData}
        edit={false}
        oldTitle={titleUpdateKey.isGallery}
        label={titleUpdateKey.Certificates}
        getData={getData}
        content={
          <CareerPopup
            handleChangeStatus={handleChangeCareer}
            handleEdit={handleEditCareer}
            getCareerParentList={getCareerParentList}
            imageUpload={imageUploadImages}
            emptyData={emptyData}
            simpleValidator={childValidator}
            webMasterDetail={webMasterDetail}
            handleDelete={handleDelete}
          />
        }
      />

      {/* item faqs popup */}
      <CommonPopup
        open={allPopupState.careerPopup}
        close={() => {
          OpenModalCommonFunc(Cases.CAREER_PARENT, setAllPopupState, false);
          setIsParent(false);
        }}
        modalName={sectionPageName ? sectionPageName : modalsName.CAREER_PARENT}
        modalCase={Cases.CAREER_PARENT}
        handleSubmit={handleSubmit}
        validationForm={"parentForm"}
        emptyData={emptyData}
        inputData={formDataChild}
        edit={false}
        content={<CareerParentPopup simpleValidator={parentValidator} />}
      />

      {/* setting Service Counter parent popup */}
      <CommonPopup
        open={allPopupState.careerSettingPopup}
        close={() =>
          OpenModalCommonFunc(
            Cases.SETTING_CAREER_PARENT,
            setAllPopupState,
            false
          )
        }
        modalName={
          sectionPageName ? sectionPageName : modalsName.SETTING_CAREER_PARENT
        }
        modalCase={Cases.SETTING_CAREER_PARENT}
        handleSubmit={handleSubmitSetting}
        emptyData={emptyData}
        inputData={formDataChild}
        edit={false}
        content={
          <SettingCareerParentPopup
            imageUpload={imageUploadImages}
            handleSubmitSetting={handleSubmitSetting}
            handleChangeSettings={handleChangeSettings}
          />
        }
      />
    </>
  );
};

export default Career;
