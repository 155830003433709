import React, { useState } from "react";
import GalleryContext from "./GalleryContext";
import { randomStr } from "../../../utils/helper";

const GalleryState = (props) => {
  const [apiData, setApiData] = useState({ data: [], isLoading: false });
  const [apiDataOurTeam, setApiDataOurTeam] = useState({
    isLoading: false,
    data: [],
  });
  const [skill, setSkill] = useState([
    { id: randomStr(2), skill: "", parentageOfSkill: "" },
  ]);
  const [fromData, setFormData] = useState({
    title: "",
    path: "",
    image: "",
    webId: localStorage.getItem("webId"),
  });

  const [formDataImageParent, setFormDataImageParent] = useState({
    title: "",
    description: "",
    isAlbum: false,
    webId: localStorage.getItem("webId"),
  });

  const [settingFormData, setSettingFormData] = useState({
    ".section-title-area": {
      "margin-bottom": "50px",
    },
    ".section-title-area .section-subtitle": {
      "background-color": "#FFB6C1",
      "border-radius": "15px",
      color: "#ff497c",
      "font-size": "16px",
      "font-weight": "600",
      "margin-bottom": "15px",
    },
    ".section-title-area .section-title": {
      "font-size": "44px",
      "font-weight": "600",
      color: "#000000",
    },
    ".text-center": {
      "text-align": "right",
    },
    ".vision-section": {
      padding: "100px 0",
    },
    ".vision-section .visiondiv1": {
      "background-color": "rgb(255 255 255 / 100)",
      padding: "60px 40px",
      p1: "60px",
      p2: "40px",
      margin: "15px 0",
      "border-radius": "15px",
      border: "1px solid rgb(255 255 255 / 100)",
      borderPx: "1px",
      borderThik: "solid",
      borderColor: "rgb(255 255 255 / 100)",
      "box-shadow": "5px 5px 15px rgb(0 0 0 / 0.08)",
      bs1: "5px",
      ps2: "5px",
      ps3: "15px",
      shadowColor: "rgb(0 0 0 / 0.08)",
      "text-align": "center",
    },
    ".vision-section .visiondiv1 h4": {
      color: "rgb(0 0 0 / 100)",
      "font-weight": "600",
      "font-size": "22px",
      "margin-bottom": "15px",
    },
    ".vision-section .visiondiv1 img": {
      "margin-bottom": "30px",
      "max-width": "70px",
    },
    ".vision-section .visiondiv1 p": {
      color: "rgb(0 0 0 / 100)",
      "font-weight": "400",
      "font-size": "14px",
      "line-height": "1.6",
    },
    galleryId: "",
  });

  const [id, setId] = useState("");
  const [galleryImgId, setGalleryImgId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [filter, setFilter] = useState("");
  return (
    <GalleryContext.Provider
      value={{
        apiData,
        setApiData,
        apiDataOurTeam,
        setApiDataOurTeam,
        fromData,
        setFormData,
        formDataImageParent,
        setFormDataImageParent,
        id,
        setId,
        isEdit,
        setIsEdit,
        filter,
        setFilter,
        skill,
        galleryImgId,
        setGalleryImgId,
        setSkill,
        settingFormData,
        setSettingFormData,
        formDataImageParent,
        setFormDataImageParent,
      }}
    >
      {props.children}
    </GalleryContext.Provider>
  );
};

export default GalleryState;
