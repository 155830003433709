import React, { useContext } from "react";
import { DELETE_CONFIRM, SinglePage } from "../../../constant";
import { TostMessage } from "../../../utils";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import Editor from "../../../common/editor";
import ItemsDragAndDrops from "../../../common/ItemsDragAndDrops";
import AboutUsContext from "../../../context/AboutUsContext/AboutUsContext";
import {
  deleteAboutUs,
  sort_order_About_Us,
} from "../../../apis/AboutApi/AboutUsApi";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";

const AboutUsPopup = (props) => {
  const {
    handleChangeStatus,
    getData,
    handleEdit,
    emptyData,
    simpleValidator,
    webMasterDetail,
  } = props;
  const {
    apiData,
    setApiData,
    fromData,
    setFormData,
    isEdit,
    setFilter,
    filter,
  } = useContext(AboutUsContext);
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * drag and drop
   * @param {drag data} result
   * @returns
   */
  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;
    const items = Array.from(apiData.data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setApiData({ ...apiData, data: items });
    try {
      let itemOrder = [];
      for (const item in items) {
        itemOrder.push({ id: items[item].Id, order: +item + 1 });
      }
      const { data } = await sort_order_About_Us(itemOrder);
      if (data.status === 200) {
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * change input value
   * @param {Event} e
   */
  const changeFormValue = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const handleAboutus = (event, editor) => {
    setFormData((prev) => {
      return {
        ...prev,
        aboutUs: event,
      };
    });
  };

  /**
   * delete about us
   * @param {id} id
   */
  const handleDelete = async (id) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await deleteAboutUs(id);
        if (data.status === 200) {
          getData();
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * create new about us
   */
  const hanldenewCreate = () => {
    emptyData();
  };

  return (
    <Container>
      <Row>
        <Col sm="12" xl="6">
          <div>
            <h6>About Us</h6>
            {/* <p className="pt-2">You can add upto 25</p> */}
            <Row>
              <Col md="6">
                <Input
                  className="form-control mt-3 "
                  name="search"
                  type="text"
                  placeholder="search"
                  style={{ borderRadius: "0", color: "black" }}
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                />
              </Col>
              {webMasterDetail?.webCategory?.name === SinglePage.WebCategory &&
                apiData?.data?.length !== SinglePage.About_Limit && (
                  <Col
                    md="6"
                    className="d-flex justify-content-center align-items-center mt-3"
                  >
                    <Button
                      color="primary"
                      className="rounded-0"
                      onClick={hanldenewCreate}
                    >
                      Add New
                    </Button>
                  </Col>
                )}
            </Row>
            {apiData.isLoading ? (
              <div className="mt-4">
                <h6>Loading ...</h6>
              </div>
            ) : (
              <div className="mt-4">
                <ItemsDragAndDrops
                  handleOnDragEnd={handleOnDragEnd}
                  characters={apiData?.data}
                  handleChangeStatus={handleChangeStatus}
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                />
              </div>
            )}
          </div>
        </Col>
        {(webMasterDetail?.webCategory?.name == SinglePage.WebCategory &&
          apiData?.data?.length == SinglePage.About_Limit &&
          isEdit) ||
        (webMasterDetail?.webCategory?.name == SinglePage.WebCategory &&
          apiData?.data?.length != SinglePage.About_Limit) ? (
          <Col sm="12" xl="6">
            <div className="mt-4">
              <h6>{isEdit ? "Edit" : "Add New"}</h6>
              <Row className="mt-4">
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Title *"}</Label>
                  <Input
                    className="form-control"
                    name="title"
                    type="text"
                    placeholder="Title *"
                    style={{ borderRadius: "0", color: "black" }}
                    value={fromData.title}
                    onChange={(e) => changeFormValue(e)}
                  />
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "Title",
                      fromData.title,
                      "required"
                    )}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md="12 mb-4">
                  <Editor value={fromData.aboutUs} onChange={handleAboutus} />
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "About us",
                      fromData.aboutUs,
                      "required"
                    )}
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
        ) : (
          ""
        )}
      </Row>
    </Container>
  );
};

export default AboutUsPopup;
