import {
  addUrlImage,
  convertRGBA,
  removeUrlImage,
  removeUrlKeyInPath,
  rgbColorCreate,
} from "../../../../utils/helper";

export const LatestNewStyleOneReset = {
  ".latest-news-section": {
    padding: "30px 0",
    "background-color": { r: 33, g: 37, b: 41, a: 100 },
  },
  ".latest-news-section.bg-image": {
    "background-image": "",
  },
  ".latest-news-section.bg-image::before": {
    "background-color": "",
    // "background-color": { r: 255, g: 73, b: 124, a: 0.2 },
  },
  ".latest-news-section .news-label": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "20px",
    "font-weight": 500,
    "margin-right": "15px",
  },
  ".latest-news-section marquee": {
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".latest-news-section marquee a": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  custom: "",
  latestNewsId: "",
};

export const LatesNewsThemeOneFunction = (payload) => {
  return {
    ".latest-news-section": {
      padding: payload.style?.[".latest-news-section"]?.["padding"],
      "background-color": payload.style?.[".latest-news-section"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".latest-news-section"]?.["background-color"],
          })
        : "",
      width: payload.style?.[".latest-news-section"]?.["width"]?.replace(
        "calc(100% - ",
        ""
      ),
      margin: payload.style?.[".latest-news-section"]?.["margin"],
      "border-radius":
        payload.style?.[".latest-news-section"]?.["border-radius"],
    },
    ".latest-news-section.bg-image": {
      "background-image": payload.style?.[".latest-news-section.bg-image"]?.[
        "background-image"
      ]
        ? removeUrlKeyInPath({
            urlString:
              payload.style?.[".latest-news-section.bg-image"]?.[
                "background-image"
              ],
          })
        : "",
    },
    ".latest-news-section.bg-image::before": {
      "background-color": payload.style?.[
        ".latest-news-section.bg-image::before"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".latest-news-section.bg-image::before"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".latest-news-section .news-label": {
      color: payload.style?.[".latest-news-section .news-label"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".latest-news-section .news-label"]?.["color"],
          })
        : "",
      "font-size":
        payload.style?.[".latest-news-section .news-label"]?.["font-size"],
      "font-weight":
        payload.style?.[".latest-news-section .news-label"]?.["font-weight"],
      "margin-right":
        payload.style?.[".latest-news-section .news-label"]?.["margin-right"],
    },
    ".latest-news-section marquee": {
      color: payload.style?.[".latest-news-section marquee"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".latest-news-section marquee"]?.["color"],
          })
        : "",
    },
    ".latest-news-section marquee a": {
      color: payload.style?.[".latest-news-section marquee a"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".latest-news-section marquee a"]?.["color"],
          })
        : "",
    },
    custom: payload?.style?.custom,
  };
};

export const LatestNewsStyleOneSubFun = ({ settingFormData, boxSection }) => {
  let formData = {
    ...settingFormData,
    ".latest-news-section": boxSection
      ? {
          ...settingFormData[".latest-news-section"],
          padding: `${settingFormData[".latest-news-section"]["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
          width: `calc(100% - ${settingFormData[".latest-news-section"][
            "width"
          ]?.replace("rem)", "")}rem)`,
          margin: `${settingFormData[".latest-news-section"]["margin"]
            ?.split(" ")[0]
            ?.replace("px", "")}px auto`,
          "border-radius": `${settingFormData[".latest-news-section"][
            "border-radius"
          ]?.replace("rem", "")}rem`,
        }
      : {
          ...settingFormData[".latest-news-section"],
          padding: `${settingFormData[".latest-news-section"]["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
        },
    ".latest-news-section.bg-image": settingFormData[
      ".latest-news-section.bg-image"
    ]["background-image"]
      ? {
          "background-image": settingFormData[".latest-news-section.bg-image"][
            "background-image"
          ]
            ? addUrlImage({
                urlString: removeUrlImage({
                  urlString:
                    settingFormData[".latest-news-section.bg-image"][
                      "background-image"
                    ],
                }),
              })
            : "",
        }
      : {},
    ".latest-news-section.bg-image::before": settingFormData[
      ".latest-news-section.bg-image::before"
    ]["background-color"]
      ? {
          "background-color": settingFormData[
            ".latest-news-section.bg-image::before"
          ]["background-color"]
            ? rgbColorCreate({
                rgbString:
                  settingFormData[".latest-news-section.bg-image::before"][
                    "background-color"
                  ],
              })
            : "",
        }
      : {},
    ".latest-news-section .news-label": {
      ...settingFormData[".latest-news-section .news-label"],
      color: settingFormData?.[".latest-news-section .news-label"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".latest-news-section .news-label"]?.["color"],
          })
        : "",
      "font-size": `${settingFormData[".latest-news-section .news-label"][
        "font-size"
      ]?.replace("px", "")}px`,
      "font-weight": `${settingFormData[".latest-news-section .news-label"]["font-weight"]}`,
      "margin-right": `${settingFormData[".latest-news-section .news-label"][
        "margin-right"
      ]?.replace("px", "")}px`,
    },
    ".latest-news-section marquee": {
      ...settingFormData[".latest-news-section marquee"],
      color: settingFormData?.[".latest-news-section marquee"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".latest-news-section marquee"]?.["color"],
          })
        : "",
    },
    ".latest-news-section marquee a": {
      ...settingFormData[".latest-news-section marquee a"],
      color: settingFormData?.[".latest-news-section marquee a"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".latest-news-section marquee a"]?.["color"],
          })
        : "",
    },
  };
  return formData;
};
