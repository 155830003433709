import React, { useContext } from "react";
import { Col, Input, Label, Row } from "reactstrap";
import {
  Border,
  TextAlign,
  fontFamily,
  fontWeight,
} from "../../../../constant";
import TestimonialMultipageContext from "../../../context/TestimonialMultipageContext/TestimonialMultipageContext";
import { Close } from "@material-ui/icons";
import { UploadCloud } from "react-feather";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import { Cases, DELETE_CONFIRM } from "../../../constant";
import { Zoom, toast } from "react-toastify";
import { delete_Multi_Image_Permanent } from "../apis/SliderApi/SliderApi";
import { Switch } from "@material-ui/core";
import Dividers from "../../../common/divider";
import { OpenModalCommonFunc } from "../../../utils";
import { IconButton } from "@material-ui/core";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import PaginationButtonStyle from "../../../common/paginationButtonStyle";
import CommonColor from "../../../common/CommonColor";
import CustomStyle from "../../../common/CustomStyle";

const TestimonialTheme1 = (props) => {
  const {
    isEdit,
    settingFormData,
    setSettingFormData,
    backImageToggle,
    setBackImageToggle,
    boxSection,
    setBoxSection,
    isBackgroundColor,
    setIsBackgroundColor,
    theme,
    setImageIs,
  } = useContext(TestimonialMultipageContext);
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const { imageUpload } = props;
  const { setLoaderShow } = useContext(LoaderContext);
  /**
   * input value change
   * @param {event} e
   */

  const handleChangeTestimonialSection = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".testimonial-section"]: {
          ...prev[".testimonial-section"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeTestimonialSectionTitleArea = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".testimonial-section .section-title-area"]: {
          ...prev[".testimonial-section .section-title-area"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeTestimonialSectionTitleAreaSubtitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".testimonial-section .section-title-area .section-subtitle"]: {
          ...prev[".testimonial-section .section-title-area .section-subtitle"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeTestimonialSectionTitleAreaTitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".testimonial-section .section-title-area .section-title"]: {
          ...prev[".testimonial-section .section-title-area .section-title"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeTestimonialSliderContent = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".testimonial-slider .testimonial-content"]: {
          ...prev[".testimonial-slider .testimonial-content"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeTestimonialSliderContentBefore = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".testimonial-slider .testimonial-content:before"]: {
          ...prev[".testimonial-slider .testimonial-content:before"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeTestimonialSliderContentAvatarImg = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".testimonial-slider .testimonial-content .avatar-img"]: {
          ...prev[".testimonial-slider .testimonial-content .avatar-img"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeTestimonialSliderContentAvatarImgMin = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".testimonial-slider .testimonial-content .avatar-img"]: {
          ...prev[".testimonial-slider .testimonial-content .avatar-img"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeTestimonialSliderContentComments = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".testimonial-slider .testimonial-content .comments"]: {
          ...prev[".testimonial-slider .testimonial-content .comments"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeTestimonialSliderContentClientName = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".testimonial-slider .testimonial-content .client-name"]: {
          ...prev[".testimonial-slider .testimonial-content .client-name"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeTestimonialSliderContentClientPosition = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".testimonial-slider .testimonial-content .client-position"]: {
          ...prev[".testimonial-slider .testimonial-content .client-position"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeTestimonialSliderContentStarRating = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".testimonial-slider .testimonial-content .star-rating"]: {
          ...prev[".testimonial-slider .testimonial-content .star-rating"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeTestimonialSectionContentStarRatingI = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".testimonial-slider .testimonial-content .star-rating i"]: {
          ...prev[".testimonial-slider .testimonial-content .star-rating i"],
          [name]: value,
        },
      };
    });
  };
  const handelChangeSliderDots = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".testimonial-slider .slick-dots li button:before"]: {
          ...prev[".testimonial-slider .slick-dots li button:before"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeCustomStyle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  /**
   * delete image permanently
   */
  const deleteImagePermanent = async () => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData = {
          fileName: settingFormData?.[".testimonial-section.bg-image"]?.[
            "background-image"
          ]
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
            .pop(),
        };
        const { data } = await delete_Multi_Image_Permanent(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            position: "top-center",
          });
          setSettingFormData({
            ...settingFormData,
            ".testimonial-section.bg-image": { "background-image": "" },
          });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };

  /**
   * image reupload
   */
  const reupload = () => {
    setSettingFormData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };

  return (
    <>
      <Row className="mt-4">
        <h4 className="mb-3">Make change in main testimonial section</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (default : 100)"}
          </Label>
          <Input
            className="form-control"
            name="padding"
            type="number"
            placeholder="padding"
            value={settingFormData?.[".testimonial-section"]?.["padding"]
              ?.split(" ")[0]
              ?.replace(/px/g, "")}
            onChange={(e) => handleChangeTestimonialSection(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>

        <Col md="4 mb-3">
          <div className="d-flex align-items-center">
            <Label htmlFor="validationCustom03">
              {isBackgroundColor
                ? "Add Background Color"
                : "Remove Background Color"}
            </Label>
            <Switch
              checked={isBackgroundColor}
              inputProps={{ "aria-label": "controlled" }}
              onChange={(e) => {
                setIsBackgroundColor(e.target.checked);
                setSettingFormData((prev) => {
                  if (e.target.checked) {
                    if (boxSection) {
                      return {
                        ...prev,
                        [".testimonial-section"]: {
                          "background-color": "#f9f9f9",
                          padding:
                            settingFormData?.[".testimonial-section"]?.[
                              "padding"
                            ],
                          width: settingFormData?.[".testimonial-section"]?.[
                            "width"
                          ]?.replace("rem)", ""),
                          margin: settingFormData?.[".testimonial-section"]?.[
                            "margin"
                          ]
                            ?.split(" ")[0]
                            ?.replace(/px/g, ""),
                          "border-radius": settingFormData?.[
                            ".testimonial-section"
                          ]?.["border-radius"]?.replace(/rem/g, ""),
                        },
                      };
                    } else {
                      return {
                        ...prev,
                        [".testimonial-section"]: {
                          "background-color": "#fbfcfd",
                          padding:
                            settingFormData?.[".testimonial-section"]?.[
                              "padding"
                            ],
                        },
                      };
                    }
                  } else {
                    if (boxSection) {
                      return {
                        ...prev,
                        [".testimonial-section"]: {
                          padding:
                            settingFormData?.[".testimonial-section"]?.[
                              "padding"
                            ],
                          width: settingFormData?.[".testimonial-section"]?.[
                            "width"
                          ]?.replace("rem)", ""),
                          margin: settingFormData?.[".testimonial-section"]?.[
                            "margin"
                          ]
                            ?.split(" ")[0]
                            ?.replace(/px/g, ""),
                          "border-radius": settingFormData?.[
                            ".testimonial-section"
                          ]?.["border-radius"]?.replace(/rem/g, ""),
                        },
                      };
                    } else {
                      return {
                        ...prev,
                        [".testimonial-section"]: {
                          padding:
                            settingFormData?.[".testimonial-section"]?.[
                              "padding"
                            ],
                        },
                      };
                    }
                  }
                });
              }}
            />
          </div>
          {isBackgroundColor && (
            <CommonColor
              color={
                settingFormData?.[".testimonial-section"]?.["background-color"]
              }
              setSettingFormData={setSettingFormData}
              className={".testimonial-section"}
              classProperty={"background-color"}
              label={"Select Background Color"}
            />
          )}
        </Col>
        <p>{"Box Section : convert your section to Box section"}</p>
        <div>
          <Switch
            checked={boxSection}
            inputProps={{ "aria-label": "controlled" }}
            onChange={(e) => {
              setBoxSection(e.target.checked);
              setSettingFormData((prev) => {
                return {
                  ...prev,
                  ".testimonial-section": {
                    padding: settingFormData?.[".testimonial-section"]?.[
                      "padding"
                    ]
                      ?.split(" ")[0]
                      ?.replace(/px/g, ""),
                    "background-color":
                      settingFormData?.[".testimonial-section"]?.[
                        "background-color"
                      ],
                    width: e.target.checked ? "10" : "",
                    margin: e.target.checked ? "0" : "",
                    "border-radius": e.target.checked ? "3" : "",
                  },
                };
              });
            }}
          />
        </div>
        {boxSection && (
          <>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Width (Default : 10)"}
              </Label>
              <Input
                className="form-control"
                name="width"
                type="number"
                placeholder="width"
                value={settingFormData?.[".testimonial-section"]?.[
                  "width"
                ]?.replace("rem)", "")}
                onChange={(e) => handleChangeTestimonialSection(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Margin (Default : 0)"}
              </Label>
              <Input
                className="form-control"
                name="margin"
                type="number"
                placeholder="margin"
                value={settingFormData?.[".testimonial-section"]?.["margin"]
                  ?.split(" ")[0]
                  ?.replace(/px/g, "")}
                onChange={(e) => handleChangeTestimonialSection(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Border Radius (Default : 3)"}
              </Label>
              <Input
                className="form-control"
                name="border-radius"
                type="number"
                placeholder="Border Radius"
                value={settingFormData?.[".testimonial-section"]?.[
                  "border-radius"
                ]?.replace(/rem/g, "")}
                onChange={(e) => handleChangeTestimonialSection(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
          </>
        )}
      </Row>
      <hr />
      <Row className="mt-4">
        <Col md="12 mb-3">
          <h4 className="mb-3">Add Background Image in section</h4>

          <p>do you want to upload Background image</p>
          <Switch
            checked={backImageToggle}
            inputProps={{ "aria-label": "controlled" }}
            onChange={(e) => {
              setBackImageToggle(e.target.checked);
              setSettingFormData((prev) => {
                if (e.target.checked) {
                  return {
                    ...prev,
                    image: "",
                    ".testimonial-section.bg-image": {},
                    ".testimonial-section.bg-image::before": {
                      "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
                    },
                  };
                } else {
                  return {
                    ...prev,
                    image: "",
                    ".testimonial-section.bg-image": {},
                    ".testimonial-section.bg-image::before": {},
                  };
                }
              });
            }}
          />
          {backImageToggle && (
            <>
              {" "}
              <Col md="6 mb-3">
                <Dividers divide="IMAGE" />
                <p className="mb-4">
                  File type required JPG, PNG (1905px X 744px)
                </p>
              </Col>
              <Row>
                <Col md="4 mb-3">
                  <div
                    className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    {!settingFormData?.[".testimonial-section.bg-image"]?.[
                      "background-image"
                    ] && (
                      <span
                        onClick={() => {
                          OpenModalCommonFunc(
                            Cases.PRODUCT_UPLOAD,
                            setAllPopupState
                          );
                          setImageIs("testimonialImage1");
                        }}
                      >
                        <UploadCloud />
                      </span>
                    )}

                    {settingFormData?.[".testimonial-section.bg-image"]?.[
                      "background-image"
                    ] && (
                      <img
                        src={
                          settingFormData?.[".testimonial-section.bg-image"]?.[
                            "background-image"
                          ] || ""
                        }
                        alt=""
                        style={{
                          width: "85%",
                          height: "90px",
                          objectFit: "contain",
                          position: "absolute",
                        }}
                        onClick={() => deleteImagePermanent()}
                      />
                    )}
                    {settingFormData?.[".testimonial-section.bg-image"]?.[
                      "background-image"
                    ] && (
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="close"
                        style={{
                          color: "red",
                          position: "absolute",
                          top: "-22px",
                          left: "1px",
                        }}
                        onClick={() => deleteImagePermanent()}
                      >
                        <Close
                          style={{
                            fontSize: "25px",
                            fontWeight: "bold",
                          }}
                        />
                      </IconButton>
                    )}
                  </div>
                </Col>
                <Col md="6 mb-3">
                  <h4 className="mb-3">Add Background color in section</h4>
                  <Col md="6 mb-3">
                    <CommonColor
                      color={
                        settingFormData?.[
                          ".testimonial-section.bg-image::before"
                        ]?.["background-color"]
                      }
                      setSettingFormData={setSettingFormData}
                      className={".testimonial-section.bg-image::before"}
                      classProperty={"background-color"}
                      label={"Select Background Color"}
                    />
                  </Col>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>

      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make space in Title bottom</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin bottom (Default : 50)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin bottom"
            value={settingFormData?.[
              ".testimonial-section .section-title-area"
            ]?.["margin-bottom"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTestimonialSectionTitleArea(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in section subtitle</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 16)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".testimonial-section .section-title-area .section-subtitle"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTestimonialSectionTitleAreaSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[
                ".testimonial-section .section-title-area .section-subtitle"
              ]?.["font-weight"]
            }
            onChange={(e) => handleChangeTestimonialSectionTitleAreaSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".testimonial-section .section-title-area .section-subtitle"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".testimonial-section .section-title-area .section-subtitle"
            }
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".testimonial-section .section-title-area .section-subtitle"
              ]?.["background-color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".testimonial-section .section-title-area .section-subtitle"
            }
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 5 - 20)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="p1"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".testimonial-section .section-title-area .section-subtitle"
              ]?.["p1"]?.replace(/px/g, "")}
              onChange={(e) =>
                handleChangeTestimonialSectionTitleAreaSubtitle(e)
              }
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p2"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".testimonial-section .section-title-area .section-subtitle"
              ]?.["p2"]?.replace(/px/g, "")}
              onChange={(e) =>
                handleChangeTestimonialSectionTitleAreaSubtitle(e)
              }
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border Radius (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[
              ".testimonial-section .section-title-area .section-subtitle"
            ]?.["border-radius"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTestimonialSectionTitleAreaSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin bottom (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin bottom"
            value={settingFormData?.[
              ".testimonial-section .section-title-area .section-subtitle"
            ]?.["margin-bottom"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTestimonialSectionTitleAreaSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in section Title</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 44)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".testimonial-section .section-title-area .section-title"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTestimonialSectionTitleAreaTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[
                ".testimonial-section .section-title-area .section-title"
              ]?.["font-weight"]
            }
            onChange={(e) => handleChangeTestimonialSectionTitleAreaTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".testimonial-section .section-title-area .section-title"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".testimonial-section .section-title-area .section-title"
            }
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">
          Change color in testimonial slider | slick-arrow:before
        </h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".testimonial-slider .slick-arrow::before"]?.[
                "color"
              ]
            }
            setSettingFormData={setSettingFormData}
            className={".testimonial-slider .slick-arrow::before"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in testimonial slider content</h4>
        <Col md="6 mb-3">
          <Label htmlFor="validationCustom03">
            {"Box Shadow (Default : 0 0 20 0 black)"}
          </Label>
          <div className="d-flex">
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="bs1"
                type="number"
                placeholder="px"
                value={settingFormData?.[
                  ".testimonial-slider .testimonial-content"
                ]?.["bs1"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeTestimonialSliderContent(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="bs2"
                type="number"
                placeholder="px"
                value={settingFormData?.[
                  ".testimonial-slider .testimonial-content"
                ]?.["bs2"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeTestimonialSliderContent(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="bs3"
                type="number"
                placeholder="px"
                value={settingFormData?.[
                  ".testimonial-slider .testimonial-content"
                ]?.["bs3"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeTestimonialSliderContent(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="bs4"
                type="number"
                placeholder="px"
                value={settingFormData?.[
                  ".testimonial-slider .testimonial-content"
                ]?.["bs4"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeTestimonialSliderContent(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div>
              <CommonColor
                color={
                  settingFormData?.[
                    ".testimonial-slider .testimonial-content"
                  ]?.["shadowColor"]
                }
                setSettingFormData={setSettingFormData}
                className={".testimonial-slider .testimonial-content"}
                classProperty={"shadowColor"}
                label={"Select Color"}
              />
            </div>
          </div>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".testimonial-slider .testimonial-content"]?.[
                "background-color"
              ]
            }
            setSettingFormData={setSettingFormData}
            className={".testimonial-slider .testimonial-content"}
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Margin (Default : 15)"}</Label>
          <Input
            className="form-control"
            name="margin"
            type="number"
            placeholder="Margin"
            value={settingFormData?.[
              ".testimonial-slider .testimonial-content"
            ]?.["margin"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTestimonialSliderContent(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="6 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 40 - 50 - 40 - 50)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="p1"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".testimonial-slider .testimonial-content"
              ]?.["p1"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeTestimonialSliderContent(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p2"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".testimonial-slider .testimonial-content"
              ]?.["p2"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeTestimonialSliderContent(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p3"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".testimonial-slider .testimonial-content"
              ]?.["p3"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeTestimonialSliderContent(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p4"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".testimonial-slider .testimonial-content"
              ]?.["p4"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeTestimonialSliderContent(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border Radius (Default : 10)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[
              ".testimonial-slider .testimonial-content"
            ]?.["border-radius"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTestimonialSliderContent(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Text Align (Default : center)"}
          </Label>
          <select
            name="text-align"
            id="text-align"
            className="form-control"
            value={
              settingFormData?.[".testimonial-slider .testimonial-content"]?.[
                "text-align"
              ]
            }
            onChange={(e) => handleChangeTestimonialSliderContent(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {TextAlign.map((elem) => {
              return (
                <option value={elem["text-align"]}>{elem["text-align"]}</option>
              );
            })}
          </select>
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in testimonial slider Quotes icon</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Family (Default : FontAwesome)"}
          </Label>
          <select
            name="font-family"
            id="font-family"
            className="form-control"
            value={
              settingFormData?.[
                ".testimonial-slider .testimonial-content:before"
              ]?.["font-family"]
            }
            onChange={(e) => handleChangeTestimonialSliderContentBefore(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontFamily.map((elem) => {
              return (
                <option value={elem["font-family"]}>
                  {elem["font-family"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".testimonial-slider .testimonial-content:before"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".testimonial-slider .testimonial-content:before"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 40)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".testimonial-slider .testimonial-content:before"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTestimonialSliderContentBefore(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Opacity (Default : 0.6)"}
          </Label>
          <Input
            className="form-control"
            name="opacity"
            type="number"
            placeholder="Opacity"
            value={
              settingFormData?.[
                ".testimonial-slider .testimonial-content:before"
              ]?.["opacity"]
            }
            onChange={(e) => handleChangeTestimonialSliderContentBefore(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Top (Default : 10)"}</Label>
          <Input
            className="form-control"
            name="top"
            type="number"
            placeholder="Top"
            value={settingFormData?.[
              ".testimonial-slider .testimonial-content:before"
            ]?.["top"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTestimonialSliderContentBefore(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />

      <Row className="mt-4">
        <h4 className="mb-3">
          Make change in testimonial content avatar image
        </h4>
        {theme == "1" ? (
          <>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Width (Default : 110)"}
              </Label>
              <Input
                className="form-control"
                name="width"
                type="number"
                placeholder="Width"
                value={settingFormData?.[
                  ".testimonial-slider .testimonial-content .avatar-img"
                ]?.["width"]?.replace(/px/g, "")}
                onChange={(e) =>
                  handleChangeTestimonialSliderContentAvatarImg(e)
                }
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Height (Default : 110)"}
              </Label>
              <Input
                className="form-control"
                name="height"
                type="number"
                placeholder="Line Height"
                value={settingFormData?.[
                  ".testimonial-slider .testimonial-content .avatar-img"
                ]?.["height"]?.replace(/px/g, "")}
                onChange={(e) =>
                  handleChangeTestimonialSliderContentAvatarImg(e)
                }
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
          </>
        ) : (
          <>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Min Width (Default : 200)"}
              </Label>
              <Input
                className="form-control"
                name="min-width"
                type="number"
                placeholder="min-width"
                value={settingFormData?.[
                  ".testimonial-slider .testimonial-content .avatar-img"
                ]?.["min-width"]?.replace(/px/g, "")}
                onChange={(e) =>
                  handleChangeTestimonialSliderContentAvatarImgMin(e)
                }
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Min Height (Default : 200)"}
              </Label>
              <Input
                className="form-control"
                name="min-height"
                type="number"
                placeholder="min-height"
                value={settingFormData?.[
                  ".testimonial-slider .testimonial-content .avatar-img"
                ]?.["min-height"]?.replace(/px/g, "")}
                onChange={(e) =>
                  handleChangeTestimonialSliderContentAvatarImgMin(e)
                }
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
          </>
        )}

        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {`Border Radius (Default : ${theme == "1" ? "100" : "10"})`}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[
              ".testimonial-slider .testimonial-content .avatar-img"
            ]?.["border-radius"]?.replace(/%/g, "")}
            onChange={(e) => handleChangeTestimonialSliderContentAvatarImg(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border (Default : 5 solid pink)"}
          </Label>
          <div className="d-flex ">
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="borderPx"
                type="number"
                placeholder="px"
                value={settingFormData?.[
                  ".testimonial-slider .testimonial-content .avatar-img"
                ]?.["borderPx"]?.replace(/px/g, "")}
                onChange={(e) =>
                  handleChangeTestimonialSliderContentAvatarImg(e)
                }
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <select
                name="borderThik"
                id="borderThik"
                className="form-select"
                value={
                  settingFormData?.[
                    ".testimonial-slider .testimonial-content .avatar-img"
                  ]?.["borderThik"]
                }
                onChange={(e) =>
                  handleChangeTestimonialSliderContentAvatarImg(e)
                }
                style={{ borderRadius: "0", color: "black" }}
              >
                <option value="">--select--</option>
                {Border.map((elem) => {
                  return <option value={elem.border}>{elem.border}</option>;
                })}
              </select>
            </div>
            <div>
              <CommonColor
                color={
                  settingFormData?.[
                    ".testimonial-slider .testimonial-content .avatar-img"
                  ]?.["borderColor"]
                }
                setSettingFormData={setSettingFormData}
                className={
                  ".testimonial-slider .testimonial-content .avatar-img"
                }
                classProperty={"borderColor"}
                label={"Select Color"}
              />
            </div>
          </div>
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in testimonial content comments</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".testimonial-slider .testimonial-content .comments"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".testimonial-slider .testimonial-content .comments"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 16)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".testimonial-slider .testimonial-content .comments"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTestimonialSliderContentComments(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[
                ".testimonial-slider .testimonial-content .comments"
              ]?.["font-weight"]
            }
            onChange={(e) => handleChangeTestimonialSliderContentComments(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Line Height (Default : 1.6)"}
          </Label>
          <Input
            className="form-control"
            name="line-height"
            type="text"
            placeholder="Line Height"
            value={
              settingFormData?.[
                ".testimonial-slider .testimonial-content .comments"
              ]?.["line-height"]
            }
            onChange={(e) => handleChangeTestimonialSliderContentComments(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Margin (Default : 20)"}</Label>
          <Input
            className="form-control"
            name="margin"
            type="number"
            placeholder="Margin"
            value={settingFormData?.[
              ".testimonial-slider .testimonial-content .comments"
            ]?.["margin"]
              ?.split(" ")[0]
              ?.replace(/px/g, "")}
            onChange={(e) => handleChangeTestimonialSliderContentComments(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in testimonial content client name</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".testimonial-slider .testimonial-content .client-name"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".testimonial-slider .testimonial-content .client-name"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 22)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".testimonial-slider .testimonial-content .client-name"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTestimonialSliderContentClientName(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[
                ".testimonial-slider .testimonial-content .client-name"
              ]?.["font-weight"]
            }
            onChange={(e) => handleChangeTestimonialSliderContentClientName(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin bottom (Default : 10)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin bottom"
            value={settingFormData?.[
              ".testimonial-slider .testimonial-content .client-name"
            ]?.["margin-bottom"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTestimonialSliderContentClientName(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">
          Make change in testimonial content client position
        </h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".testimonial-slider .testimonial-content .client-position"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".testimonial-slider .testimonial-content .client-position"
            }
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 16)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".testimonial-slider .testimonial-content .client-position"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) =>
              handleChangeTestimonialSliderContentClientPosition(e)
            }
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 400)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[
                ".testimonial-slider .testimonial-content .client-position"
              ]?.["font-weight"]
            }
            onChange={(e) =>
              handleChangeTestimonialSliderContentClientPosition(e)
            }
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin bottom (Default : 12)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin bottom"
            value={settingFormData?.[
              ".testimonial-slider .testimonial-content .client-position"
            ]?.["margin-bottom"]?.replace(/px/g, "")}
            onChange={(e) =>
              handleChangeTestimonialSliderContentClientPosition(e)
            }
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">
          Change position in testimonial content start rating
        </h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Text Align (Default : center)"}
          </Label>
          <select
            name="text-align"
            id="text-align"
            className="form-control"
            value={
              settingFormData?.[
                ".testimonial-slider .testimonial-content .star-rating"
              ]?.["text-align"]
            }
            onChange={(e) => handleChangeTestimonialSliderContentStarRating(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {TextAlign.map((elem) => {
              return (
                <option value={elem["text-align"]}>{elem["text-align"]}</option>
              );
            })}
          </select>
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">
          Make change in testimonial content start rating icon
        </h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".testimonial-slider .testimonial-content .star-rating i"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".testimonial-slider .testimonial-content .star-rating i"
            }
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 20)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".testimonial-slider .testimonial-content .star-rating i"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) =>
              handleChangeTestimonialSectionContentStarRatingI(e)
            }
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in slider dots</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 12)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".testimonial-slider .slick-dots li button:before"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handelChangeSliderDots(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".testimonial-slider .slick-dots li button:before"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".testimonial-slider .slick-dots li button:before"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"opacity (Default : 0.25)"}
          </Label>
          <Input
            className="form-control"
            name="opacity"
            type="number"
            placeholder="opacity"
            value={settingFormData?.[
              ".testimonial-slider .slick-dots li button:before"
            ]?.["opacity"]?.replace(/px/g, "")}
            onChange={(e) => handelChangeSliderDots(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <h4 className="mb-3">Active slider dots</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".testimonial-slider .slick-dots li.slick-active button:before"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".testimonial-slider .slick-dots li.slick-active button:before"
            }
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
      </Row>
      <PaginationButtonStyle
        mainClass={".testimonial-section"}
        settingFormData={settingFormData}
        setSettingFormData={setSettingFormData}
      />
      <CustomStyle
        handleChange={handleChangeCustomStyle}
        value={settingFormData?.custom}
      />
      <ProductImageUploadPopup
        allPopupState={allPopupState}
        setAllPopupState={setAllPopupState}
        crop={crop}
        setCrop={setCrop}
        completedCrop={completedCrop}
        setCompletedCrop={setCompletedCrop}
        scale={scale}
        rotate={rotate}
        image={settingFormData?.image}
        setImage={setSettingFormData}
        imageUpload={imageUpload}
        isEdit={isEdit}
        reupload={reupload}
        // aspect={2.56 / 1}
      />
    </>
  );
};

export default TestimonialTheme1;
