import React, { useContext } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import FooterContext from "../../../context/FooterContext/FooterContext";
import {
  footerStyle1,
  footerStyle1Foot2,
  footerStyle1Foot2Reset,
  footerStyle1Reset,
} from "../../../constant";
import FooterOneThemeOne from "./FooterOneThemeOne";
import FooterOneThemeTwo from "./FooterOneThemeTwo";
import { ThemeNo } from "../../../../constant";
import FooterTheme1 from "../ScreenShorts/Footer/FooterTheme1.png";
import FooterTheme2 from "../ScreenShorts/Footer/FooterTheme2.png";
import ThemeImageShow from "../../../common/ThemeImageShow";

const FooterStyleOnePopup = (props) => {
  const {
    isEdit,
    formData,
    setFormData,
    theme,
    setTheme,
    imgShow,
    setImgShow,
  } = useContext(FooterContext);
  const { submitForm, imageUpload } = props;

  /**
   * input value change
   * @param {event} e
   */

  const resetHandler = () => {
    let footerStyle;
    if (+theme === ThemeNo.ThemeOne) {
      footerStyle = footerStyle1Reset;
    } else if (+theme === ThemeNo.ThemeTwo) {
      footerStyle = {
        ...footerStyle1Foot2Reset,
        footerImage: formData?.footer1Style?.footerImage,
      };
    }
    setFormData({
      footer1Style: footerStyle,
    });
  };
  const handleTheme = (e) => {
    const { value } = e.target;
    setTheme(value);
    setFormData((prev) => {
      if (+value === ThemeNo.ThemeOne) {
        return {
          footer1Style: footerStyle1,
        };
      } else if (+value === ThemeNo.ThemeTwo) {
        return {
          footer1Style: footerStyle1Foot2,
        };
      }
    });
  };

  const handleClose = () => {
    setImgShow(false);
  };
  const themeImg = {
    1: FooterTheme1,
    2: FooterTheme2,
  };

  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="12">
            <div className="mt-4 d-flex flex-column justify-content-center position-relative">
              <div
                className="d-flex justify-content-between position-sticky"
                style={{ top: "87px", background: "white", zIndex: 999 }}
              >
                <h6>{isEdit ? "Edit" : "Add New"}</h6>
                <div className="d-flex gap-2">
                  <Button onClick={resetHandler}>Reset</Button>
                  <Button
                    onClick={() =>
                      submitForm({
                        formValidationCondition: "footerOneStyleForm",
                        isRestore: true,
                      })
                    }
                  >
                    Restore
                  </Button>
                  <Button
                    onClick={() =>
                      submitForm({
                        formValidationCondition: "footerOneStyleForm",
                        isClose: false,
                      })
                    }
                    color="success"
                  >
                    Save & View
                  </Button>
                </div>
              </div>
              <Row>
                <Col>
                  <label htmlFor="">Theme</label>
                  <select
                    name="theme"
                    id="font-weight"
                    className="form-select"
                    value={theme}
                    onChange={(e) => handleTheme(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value={""}>--Select--</option>
                    <option value={1}>Theme 1</option>
                    <option value={2}>Theme 2</option>
                  </select>
                </Col>
              </Row>
              {!imgShow && (
                <div>
                  <Button
                    onClick={() => setImgShow(true)}
                    color="primary"
                    className="mt-2"
                  >
                    Show Theme Image
                  </Button>
                </div>
              )}
              {imgShow && (
                <ThemeImageShow
                  themeImg={themeImg[theme]}
                  handleClose={handleClose}
                />
              )}
              {+theme === ThemeNo.ThemeOne && <FooterOneThemeOne />}
              {+theme === ThemeNo.ThemeTwo && (
                <FooterOneThemeTwo imageUpload={imageUpload} />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default FooterStyleOnePopup;
