import React, { useContext } from "react";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import { Border, TextAlign, fontWeight } from "../../../../constant";
import { Switch } from "@material-ui/core";
import Dividers from "../../../common/divider";
import { OpenModalCommonFunc } from "../../../utils";
import { Cases, DELETE_CONFIRM } from "../../../constant";
import { Close } from "@material-ui/icons";
import { UploadCloud } from "react-feather";
import { IconButton } from "@material-ui/core";
import { delete_Multi_Image_Permanent } from "../apis/SliderApi/SliderApi";
import { Zoom, toast } from "react-toastify";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import Multipage_OurTeamContext from "../../../context/multipage_OurTeamContext/Multipage_OurTeamContext";
import PaginationButtonStyle from "../../../common/paginationButtonStyle";
import CommonColor from "../../../common/CommonColor";
import CustomStyle from "../../../common/CustomStyle";

const OurTeamStyleTwo = (props) => {
  const {
    isEdit,
    settingFormData,
    setSettingFormData,
    boxSection,
    setBoxSection,
    backImageToggle,
    setBackImageToggle,
    boxSection1,
    setBoxSection1,
    backImageToggle1,
    setBackImageToggle1,
    setBackImgNo,
    isBackgroundColor,
    setIsBackgroundColor,
    isBackgroundColor2,
    setIsBackgroundColor2,
  } = useContext(Multipage_OurTeamContext);
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const { imageUpload, handleSubmitSetting } = props;
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * input value change
   * @param {event} e
   */

  const handleChangeTeamSectionSection = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".ourteam-section": {
          ...prev[".ourteam-section"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeProductFeaturesTitleArea = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".ourteam-section .section-title-area": {
          ...prev[".ourteam-section .section-title-area"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeProductFeaturesTitleAreaSubtitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".ourteam-section .section-title-area .section-subtitle": {
          ...prev[".ourteam-section .section-title-area .section-subtitle"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeProductFeaturesTitleAreaSectionTitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".ourteam-section .section-title-area .section-title": {
          ...prev[".ourteam-section .section-title-area .section-title"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeTeamSectionMemberData = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".ourteam-section .member-data": {
          ...prev[".ourteam-section .member-data"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeTeamSectionMemberInfo = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".ourteam-section .member-data .member-info": {
          ...prev[".ourteam-section .member-data .member-info"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeTeamSectionMemberDataNameA = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".ourteam-section .member-data .name a": {
          ...prev[".ourteam-section .member-data .name a"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeTeamSectionMemberDataImage = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".ourteam-section .member-data .member-img": {
          ...prev[".ourteam-section .member-data .member-img"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeTeamSectionMemberDataPosition = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".ourteam-section .member-data .position": {
          ...prev[".ourteam-section .member-data .position"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeTeamSectionMemberSocialListA = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".ourteam-section .member-data .socia-list a": {
          ...prev[".ourteam-section .member-data .socia-list a"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeTeamSectionSectionDetail = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".team-detail-section": {
          ...prev[".team-detail-section"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeTeamSectionDetailImg = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".team-detail-section .single-team-img": {
          ...prev[".team-detail-section .single-team-img"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeTeamSectionDetailMemberInfoTitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".team-detail-section .team-member-info .team-title": {
          ...prev[".team-detail-section .team-member-info .team-title"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeTeamDetailSectionMemberSocialListA = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".team-detail-section .team-member-info .social-list a": {
          ...prev[".team-detail-section .team-member-info .social-list a"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeTeamSectionDetailTitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".personal-details .details-title": {
          ...prev[".personal-details .details-title"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeProductDetailsP = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".personal-details p": {
          ...prev[".personal-details p"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeCustomStyle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  /**
   * delete image permanently
   */
  const deleteImagePermanent = async () => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData = {
          fileName: settingFormData?.[".ourteam-section.bg-image"]?.[
            "background-image"
          ]
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
            .pop(),
        };
        const { data } = await delete_Multi_Image_Permanent(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            position: "top-center",
          });
          setSettingFormData({
            ...settingFormData,
            ".ourteam-section.bg-image": { "background-image": "" },
          });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };

  /**
   * image reupload
   */
  const reupload = () => {
    setSettingFormData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };
  return (
    <>
      <Row className="mt-4">
        <h4 className="mb-3">Make change in main Section</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 100)"}
          </Label>
          <Input
            className="form-control"
            name="padding"
            type="number"
            placeholder="Padding"
            value={settingFormData?.[".ourteam-section"]?.["padding"]
              ?.split(" ")[0]
              ?.replace(/px/g, "")}
            onChange={(e) => handleChangeTeamSectionSection(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <div className="d-flex align-items-center">
            {isBackgroundColor ? (
              <Label htmlFor="validationCustom03">
                {"Add Background Color"}
              </Label>
            ) : (
              <Label htmlFor="validationCustom03">
                {"Remove Background Color"}
              </Label>
            )}
            <Switch
              checked={isBackgroundColor}
              inputProps={{ "aria-label": "controlled" }}
              onChange={(e) => {
                setIsBackgroundColor(e.target.checked);
                setSettingFormData((prev) => {
                  if (e.target.checked) {
                    if (boxSection) {
                      return {
                        ...prev,
                        ".ourteam-section": {
                          "background-color": "#f9f9f9",
                          padding:
                            settingFormData?.[".ourteam-section"]?.["padding"],
                          width: settingFormData?.[".ourteam-section"]?.[
                            "width"
                          ]?.replace("rem)", ""),
                          margin: settingFormData?.[".ourteam-section"]?.[
                            "margin"
                          ]
                            ?.split(" ")[0]
                            ?.replace(/px/g, ""),
                          "border-radius": settingFormData?.[
                            ".ourteam-section"
                          ]?.["border-radius"]?.replace(/rem/g, ""),
                        },
                      };
                    } else {
                      return {
                        ...prev,
                        ".ourteam-section": {
                          "background-color": "#f9f9f9",
                          padding:
                            settingFormData?.[".ourteam-section"]?.["padding"],
                        },
                      };
                    }
                  } else {
                    if (boxSection) {
                      return {
                        ...prev,
                        ".ourteam-section": {
                          padding:
                            settingFormData?.[".ourteam-section"]?.["padding"],
                          width: settingFormData?.[".ourteam-section"]?.[
                            "width"
                          ]?.replace("rem)", ""),
                          margin: settingFormData?.[".ourteam-section"]?.[
                            "margin"
                          ]
                            ?.split(" ")[0]
                            ?.replace(/px/g, ""),
                          "border-radius": settingFormData?.[
                            ".ourteam-section"
                          ]?.["border-radius"]?.replace(/rem/g, ""),
                        },
                      };
                    } else {
                      return {
                        ...prev,
                        ".ourteam-section": {
                          padding:
                            settingFormData?.[".ourteam-section"]?.["padding"],
                        },
                      };
                    }
                  }
                });
              }}
            />
          </div>
          {isBackgroundColor && (
            <CommonColor
              color={
                settingFormData?.[".ourteam-section"]?.["background-color"]
              }
              setSettingFormData={setSettingFormData}
              className={".ourteam-section"}
              classProperty={"background-color"}
              label={"Select Background Color"}
            />
          )}
        </Col>
        <p>{"Box Section : convert your section to Box section"}</p>
        <div>
          <Switch
            checked={boxSection}
            inputProps={{ "aria-label": "controlled" }}
            onChange={(e) => {
              setBoxSection(e.target.checked);
              setSettingFormData((prev) => {
                return {
                  ...prev,
                  ".ourteam-section": {
                    padding: settingFormData?.[".ourteam-section"]?.["padding"]
                      ?.split(" ")[0]
                      ?.replace(/px/g, ""),
                    "background-color":
                      settingFormData?.[".ourteam-section"]?.[
                        "background-color"
                      ],
                    width: e.target.checked ? "10" : "",
                    margin: e.target.checked ? "0" : "",
                    "border-radius": e.target.checked ? "3" : "",
                  },
                };
              });
            }}
          />
        </div>
        {boxSection && (
          <>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Width (Default : 10)"}
              </Label>
              <Input
                className="form-control"
                name="width"
                type="number"
                placeholder="width"
                value={settingFormData?.[".ourteam-section"]?.[
                  "width"
                ]?.replace("rem)", "")}
                onChange={(e) => handleChangeTeamSectionSection(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Margin (Default : 0)"}
              </Label>
              <Input
                className="form-control"
                name="margin"
                type="number"
                placeholder="margin"
                value={settingFormData?.[".ourteam-section"]?.["margin"]
                  ?.split(" ")[0]
                  ?.replace(/px/g, "")}
                onChange={(e) => handleChangeTeamSectionSection(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Border Radius (Default : 3)"}
              </Label>
              <Input
                className="form-control"
                name="border-radius"
                type="number"
                placeholder="Border Radius"
                value={settingFormData?.[".ourteam-section"]?.[
                  "border-radius"
                ]?.replace(/rem/g, "")}
                onChange={(e) => handleChangeTeamSectionSection(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
          </>
        )}
      </Row>
      <hr />
      <Row className="mt-4">
        <Col md="12 mb-3">
          <h4 className="mb-3">Add Background Image in section</h4>

          <p>do you want to upload Background image</p>
          <Switch
            checked={backImageToggle}
            inputProps={{ "aria-label": "controlled" }}
            onChange={(e) => {
              setBackImageToggle(e.target.checked);
              setSettingFormData((prev) => {
                return {
                  ...prev,
                  image: "",
                  ".ourteam-section.bg-image": {},
                  ".ourteam-section.bg-image::before": {
                    // "background-color": "#000000CC",
                  },
                };
              });
            }}
          />
          {backImageToggle && (
            <>
              {" "}
              <Col md="6 mb-3">
                <Dividers divide="IMAGE" />
                <p className="mb-4">
                  File type required JPG, PNG (1905px X 845px)
                </p>
              </Col>
              <Row>
                <Col md="4 mb-3">
                  <div
                    className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    {!settingFormData?.[".ourteam-section.bg-image"]?.[
                      "background-image"
                    ] && (
                      <span
                        onClick={() => {
                          OpenModalCommonFunc(
                            Cases.PRODUCT_UPLOAD,
                            setAllPopupState
                          );
                          setBackImgNo(".ourteam-section.bg-image");
                        }}
                      >
                        <UploadCloud />
                      </span>
                    )}

                    {settingFormData?.[".ourteam-section.bg-image"]?.[
                      "background-image"
                    ] && (
                      <img
                        src={
                          settingFormData?.[".ourteam-section.bg-image"]?.[
                            "background-image"
                          ] || ""
                        }
                        alt=""
                        style={{
                          width: "85%",
                          height: "90px",
                          objectFit: "contain",
                          position: "absolute",
                        }}
                        onClick={() => {
                          deleteImagePermanent();
                        }}
                      />
                    )}
                    {settingFormData?.[".ourteam-section.bg-image"]?.[
                      "background-image"
                    ] && (
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="close"
                        style={{
                          color: "red",
                          position: "absolute",
                          top: "-22px",
                          left: "1px",
                        }}
                        onClick={() => deleteImagePermanent()}
                      >
                        <Close
                          style={{
                            fontSize: "25px",
                            fontWeight: "bold",
                          }}
                        />
                      </IconButton>
                    )}
                  </div>
                </Col>
                <Col md="6 mb-3">
                  <h4 className="mb-3">Add Background color in section</h4>
                  <Col md="6 mb-3">
                    <CommonColor
                      color={
                        settingFormData?.[
                          ".ourteam-section.bg-image::before"
                        ]?.["background-color"]
                      }
                      setSettingFormData={setSettingFormData}
                      className={".ourteam-section.bg-image::before"}
                      classProperty={"background-color"}
                      label={"Select Background Color"}
                    />
                    {/* <Input
                              className="form-control"
                              name="background-color"
                              type="color"
                              placeholder="Background Color"
                              value={
                                settingFormData?.[
                                  ".ourteam-section.bg-image::before"
                                ]?.["background-color"]
                              }
                              onChange={(e) =>
                                handleChangeProductFeaturesSectionBgImage(e)
                              }
                              style={{ borderRadius: "0", color: "black" }}
                            /> */}
                  </Col>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make space in Title bottom</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin Bottom (Default : 50)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin Bottom"
            value={settingFormData?.[".ourteam-section .section-title-area"]?.[
              "margin-bottom"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductFeaturesTitleArea(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in section subtitle</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 16)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".ourteam-section .section-title-area .section-subtitle"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductFeaturesTitleAreaSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[
                ".ourteam-section .section-title-area .section-subtitle"
              ]?.["font-weight"]
            }
            onChange={(e) => handleChangeProductFeaturesTitleAreaSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".ourteam-section .section-title-area .section-subtitle"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".ourteam-section .section-title-area .section-subtitle"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".ourteam-section .section-title-area .section-subtitle"
              ]?.["background-color"]
            }
            setSettingFormData={setSettingFormData}
            className={".ourteam-section .section-title-area .section-subtitle"}
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 5 - 20)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="p1"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".ourteam-section .section-title-area .section-subtitle"
              ]?.["p1"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeProductFeaturesTitleAreaSubtitle(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p2"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".ourteam-section .section-title-area .section-subtitle"
              ]?.["p2"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeProductFeaturesTitleAreaSubtitle(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border Radius (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[
              ".ourteam-section .section-title-area .section-subtitle"
            ]?.["border-radius"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductFeaturesTitleAreaSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin Bottom (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin Bottom"
            value={settingFormData?.[
              ".ourteam-section .section-title-area .section-subtitle"
            ]?.["margin-bottom"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductFeaturesTitleAreaSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in section Title</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 44)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".ourteam-section .section-title-area .section-title"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) =>
              handleChangeProductFeaturesTitleAreaSectionTitle(e)
            }
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[
                ".ourteam-section .section-title-area .section-title"
              ]?.["font-weight"]
            }
            onChange={(e) =>
              handleChangeProductFeaturesTitleAreaSectionTitle(e)
            }
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".ourteam-section .section-title-area .section-title"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".ourteam-section .section-title-area .section-title"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
      </Row>
      <hr />{" "}
      <Row className="mt-2">
        <h4 className="mb-3">Make change in member data</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Margin (Default : 10)"}</Label>
          <Input
            className="form-control"
            name="margin"
            type="number"
            placeholder="margin"
            value={settingFormData?.[".ourteam-section .member-data"]?.[
              "margin"
            ]
              ?.split(" ")[0]
              ?.replace(/px/g, "")}
            onChange={(e) => handleChangeTeamSectionMemberData(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".ourteam-section .member-data"]?.[
                "background-color"
              ]
            }
            setSettingFormData={setSettingFormData}
            className={".ourteam-section .member-data"}
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
        <Col md="6 mb-3">
          <Label htmlFor="validationCustom03">
            {"Box Shadow (Default : 0 5 10 black)"}
          </Label>
          <div className="d-flex ">
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="bs1"
                type="number"
                placeholder="px"
                value={settingFormData?.[".ourteam-section .member-data"]?.[
                  "bs1"
                ]?.replace(/px/g, "")}
                onChange={(e) => handleChangeTeamSectionMemberData(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="bs2"
                type="number"
                placeholder="px"
                value={settingFormData?.[".ourteam-section .member-data"]?.[
                  "bs2"
                ]?.replace(/px/g, "")}
                onChange={(e) => handleChangeTeamSectionMemberData(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="bs3"
                type="number"
                placeholder="px"
                value={settingFormData?.[".ourteam-section .member-data"]?.[
                  "bs3"
                ]?.replace(/px/g, "")}
                onChange={(e) => handleChangeTeamSectionMemberData(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="bs4"
                type="number"
                placeholder="px"
                value={settingFormData?.[".ourteam-section .member-data"]?.[
                  "bs4"
                ]?.replace(/px/g, "")}
                onChange={(e) => handleChangeTeamSectionMemberData(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div>
              <CommonColor
                color={
                  settingFormData?.[".ourteam-section .member-data"]?.[
                    "shadowColor"
                  ]
                }
                setSettingFormData={setSettingFormData}
                className={".ourteam-section .member-data"}
                classProperty={"shadowColor"}
                label={"Select Color"}
              />
            </div>
          </div>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border (Default : 2px solid black)"}
          </Label>
          <div className="d-flex">
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="borderPx"
                type="number"
                placeholder="px"
                value={settingFormData?.[".ourteam-section .member-data"]?.[
                  "borderPx"
                ]?.replace(/px/g, "")}
                onChange={(e) => handleChangeTeamSectionMemberData(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <select
                name="borderThik"
                id="borderThik"
                className="form-control"
                value={
                  settingFormData?.[".ourteam-section .member-data"]?.[
                    "borderThik"
                  ]
                }
                onChange={(e) => handleChangeTeamSectionMemberData(e)}
                style={{ borderRadius: "0", color: "black" }}
              >
                <option value="">--select--</option>
                {Border.map((elem) => {
                  return <option value={elem.border}>{elem.border}</option>;
                })}
              </select>
            </div>

            <div>
              <CommonColor
                color={
                  settingFormData?.[".ourteam-section .member-data"]?.[
                    "borderColor"
                  ]
                }
                setSettingFormData={setSettingFormData}
                className={".ourteam-section .member-data"}
                classProperty={"borderColor"}
                label={"Border Color"}
              />
            </div>
          </div>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border radius (Default : 0)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="border-radius"
            value={settingFormData?.[".ourteam-section .member-data"]?.[
              "border-radius"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTeamSectionMemberData(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Change in member data : Hover</h4>

        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".ourteam-section .member-data:hover"]?.[
                "border-color"
              ]
            }
            setSettingFormData={setSettingFormData}
            className={".ourteam-section .member-data:hover"}
            classProperty={"border-color"}
            label={"Select Border Color"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Change Background Color in member Image</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border Radius (Default : 5)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[
              ".ourteam-section .member-data .member-img"
            ]?.["border-radius"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTeamSectionMemberDataImage(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".ourteam-section .member-data .member-img"]?.[
                "background-color"
              ]
            }
            setSettingFormData={setSettingFormData}
            className={".ourteam-section .member-data .member-img"}
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">make space 4 side in member data information</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 30 - 15)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="p1"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".ourteam-section .member-data .member-info"
              ]?.["p1"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeTeamSectionMemberInfo(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p2"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".ourteam-section .member-data .member-info"
              ]?.["p2"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeTeamSectionMemberInfo(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in member data name</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".ourteam-section .member-data .name a"]?.[
                "color"
              ]
            }
            setSettingFormData={setSettingFormData}
            className={".ourteam-section .member-data .name a"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 20)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[".ourteam-section .member-data .name a"]?.[
              "font-size"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTeamSectionMemberDataNameA(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 500)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[".ourteam-section .member-data .name a"]?.[
                "font-weight"
              ]
            }
            onChange={(e) => handleChangeTeamSectionMemberDataNameA(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in member Position</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".ourteam-section .member-data .position"]?.[
                "color"
              ]
            }
            setSettingFormData={setSettingFormData}
            className={".ourteam-section .member-data .position"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 18)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".ourteam-section .member-data .position"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTeamSectionMemberDataPosition(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[".ourteam-section .member-data .position"]?.[
                "font-weight"
              ]
            }
            onChange={(e) => handleChangeTeamSectionMemberDataPosition(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Margin (Default : 10)"}</Label>
          <Input
            className="form-control"
            name="margin"
            type="number"
            placeholder="margin"
            value={settingFormData?.[
              ".ourteam-section .member-data .position"
            ]?.["margin"]
              ?.split(" ")[0]
              ?.replace(/px/g, "")}
            onChange={(e) => handleChangeTeamSectionMemberDataPosition(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in member data Social Icon List</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Width (Default : 40)"}</Label>
          <Input
            className="form-control"
            name="width"
            type="number"
            placeholder="width"
            value={settingFormData?.[
              ".ourteam-section .member-data .socia-list a"
            ]?.["width"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTeamSectionMemberSocialListA(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Height (Default : 40)"}</Label>
          <Input
            className="form-control"
            name="height"
            type="number"
            placeholder="height"
            value={settingFormData?.[
              ".ourteam-section .member-data .socia-list a"
            ]?.["height"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTeamSectionMemberSocialListA(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Padding (Default : 8)"}</Label>
          <Input
            className="form-control"
            name="padding"
            type="number"
            placeholder="padding"
            value={settingFormData?.[
              ".ourteam-section .member-data .socia-list a"
            ]?.["padding"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTeamSectionMemberSocialListA(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Margin (Default : 1)"}</Label>
          <Input
            className="form-control"
            name="margin"
            type="number"
            placeholder="margin"
            value={settingFormData?.[
              ".ourteam-section .member-data .socia-list a"
            ]?.["margin"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTeamSectionMemberSocialListA(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".ourteam-section .member-data .socia-list a"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".ourteam-section .member-data .socia-list a"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 20)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".ourteam-section .member-data .socia-list a"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTeamSectionMemberSocialListA(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border (Default : 2px solid black)"}
          </Label>
          <div className="d-flex">
            <div style={{ width: "100px" }}>
              <Input
                className="form-control"
                name="borderPx"
                type="number"
                placeholder="px"
                value={settingFormData?.[
                  ".ourteam-section .member-data .socia-list a"
                ]?.["borderPx"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeTeamSectionMemberSocialListA(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
            <div style={{ width: "100px" }}>
              <select
                name="borderThik"
                id="borderThik"
                className="form-control"
                value={
                  settingFormData?.[
                    ".ourteam-section .member-data .socia-list a"
                  ]?.["borderThik"]
                }
                onChange={(e) => handleChangeTeamSectionMemberSocialListA(e)}
                style={{ borderRadius: "0", color: "black" }}
              >
                <option value="">--select--</option>
                {Border.map((elem) => {
                  return <option value={elem.border}>{elem.border}</option>;
                })}
              </select>
            </div>

            <div>
              <CommonColor
                color={
                  settingFormData?.[
                    ".ourteam-section .member-data .socia-list a"
                  ]?.["borderColor"]
                }
                setSettingFormData={setSettingFormData}
                className={".ourteam-section .member-data .socia-list a"}
                classProperty={"borderColor"}
                label={"Border Color"}
              />
            </div>
          </div>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border Radius (Default : 20)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[
              ".ourteam-section .member-data .socia-list a"
            ]?.["border-radius"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTeamSectionMemberSocialListA(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">
          Make change in member data Social Icon List on hover
        </h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".ourteam-section .member-data .socia-list a:hover"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".ourteam-section .member-data .socia-list a:hover"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".ourteam-section .member-data .socia-list a:hover"
              ]?.["background-color"]
            }
            setSettingFormData={setSettingFormData}
            className={".ourteam-section .member-data .socia-list a:hover"}
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".ourteam-section .member-data .socia-list a:hover"
              ]?.["border-color"]
            }
            setSettingFormData={setSettingFormData}
            className={".ourteam-section .member-data .socia-list a:hover"}
            classProperty={"border-color"}
            label={"Border Color"}
          />
        </Col>
      </Row>
      {/* ----------------------------------------------------------
              ---------------------------------------------------------- */}
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in main Team Detail Section</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 100)"}
          </Label>
          <Input
            className="form-control"
            name="padding"
            type="number"
            placeholder="Padding"
            value={settingFormData?.[".team-detail-section"]?.["padding"]
              ?.split(" ")[0]
              ?.replace(/px/g, "")}
            onChange={(e) => handleChangeTeamSectionSectionDetail(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <div className="d-flex align-items-center">
            {isBackgroundColor2 ? (
              <Label htmlFor="validationCustom03">
                {"Add Background Color"}
              </Label>
            ) : (
              <Label htmlFor="validationCustom03">
                {"Remove Background Color"}
              </Label>
            )}
            <Switch
              checked={isBackgroundColor2}
              inputProps={{ "aria-label": "controlled" }}
              onChange={(e) => {
                setIsBackgroundColor2(e.target.checked);
                setSettingFormData((prev) => {
                  if (e.target.checked) {
                    if (boxSection1) {
                      return {
                        ...prev,
                        ".team-detail-section": {
                          "background-color": "#f9f9f9",
                          padding:
                            settingFormData?.[".team-detail-section"]?.[
                              "padding"
                            ],
                          width: settingFormData?.[".team-detail-section"]?.[
                            "width"
                          ]?.replace("rem)", ""),
                          margin: settingFormData?.[".team-detail-section"]?.[
                            "margin"
                          ]
                            ?.split(" ")[0]
                            ?.replace(/px/g, ""),
                          "border-radius": settingFormData?.[
                            ".team-detail-section"
                          ]?.["border-radius"]?.replace(/rem/g, ""),
                        },
                      };
                    } else {
                      return {
                        ...prev,
                        ".team-detail-section": {
                          "background-color": "#f9f9f9",
                          padding:
                            settingFormData?.[".team-detail-section"]?.[
                              "padding"
                            ],
                        },
                      };
                    }
                  } else {
                    if (boxSection1) {
                      return {
                        ...prev,
                        ".team-detail-section": {
                          padding:
                            settingFormData?.[".team-detail-section"]?.[
                              "padding"
                            ],
                          width: settingFormData?.[".team-detail-section"]?.[
                            "width"
                          ]?.replace("rem)", ""),
                          margin: settingFormData?.[".team-detail-section"]?.[
                            "margin"
                          ]
                            ?.split(" ")[0]
                            ?.replace(/px/g, ""),
                          "border-radius": settingFormData?.[
                            ".team-detail-section"
                          ]?.["border-radius"]?.replace(/rem/g, ""),
                        },
                      };
                    } else {
                      return {
                        ...prev,
                        ".team-detail-section": {
                          padding:
                            settingFormData?.[".team-detail-section"]?.[
                              "padding"
                            ],
                        },
                      };
                    }
                  }
                });
              }}
            />
          </div>
          {isBackgroundColor2 && (
            <CommonColor
              color={
                settingFormData?.[".team-detail-section"]?.["background-color"]
              }
              setSettingFormData={setSettingFormData}
              className={".team-detail-section"}
              classProperty={"background-color"}
              label={"Select Background  Color"}
            />
          )}
        </Col>
        <p>{"Box Section : convert your section to Box section"}</p>
        <div>
          <Switch
            checked={boxSection1}
            inputProps={{ "aria-label": "controlled" }}
            onChange={(e) => {
              setBoxSection1(e.target.checked);
              setSettingFormData((prev) => {
                return {
                  ...prev,
                  ".team-detail-section": {
                    padding: settingFormData?.[".team-detail-section"]?.[
                      "padding"
                    ]
                      ?.split(" ")[0]
                      ?.replace(/px/g, ""),
                    "background-color":
                      settingFormData?.[".team-detail-section"]?.[
                        "background-color"
                      ],
                  },
                };
              });
            }}
          />
        </div>
        {boxSection1 && (
          <>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Width (Default : 10)"}
              </Label>
              <Input
                className="form-control"
                name="width"
                type="number"
                placeholder="width"
                value={settingFormData?.[".team-detail-section"]?.[
                  "width"
                ]?.replace("rem)", "")}
                onChange={(e) => handleChangeTeamSectionSectionDetail(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Margin (Default : 0)"}
              </Label>
              <Input
                className="form-control"
                name="margin"
                type="number"
                placeholder="margin"
                value={settingFormData?.[".team-detail-section"]?.["margin"]
                  ?.split(" ")[0]
                  ?.replace(/px/g, "")}
                onChange={(e) => handleChangeTeamSectionSectionDetail(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Border Radius (Default : 3)"}
              </Label>
              <Input
                className="form-control"
                name="border-radius"
                type="number"
                placeholder="Border Radius"
                value={settingFormData?.[".team-detail-section"]?.[
                  "border-radius"
                ]?.replace(/rem/g, "")}
                onChange={(e) => handleChangeTeamSectionSectionDetail(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
          </>
        )}
      </Row>
      <hr />
      <Row className="mt-4">
        <Col md="12 mb-3">
          <h4 className="mb-3">Add Background Image in Detail section</h4>

          <p>do you want to upload Background image</p>
          <Switch
            checked={backImageToggle1}
            inputProps={{ "aria-label": "controlled" }}
            onChange={(e) => {
              setBackImageToggle1(e.target.checked);
              setSettingFormData((prev) => {
                return {
                  ...prev,
                  image: "",
                  ".team-detail-section.bg-image": {
                    "background-image": "",
                  },
                  ".team-detail-section.bg-image::before": {
                    "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
                  },
                };
              });
            }}
          />
          {backImageToggle1 && (
            <>
              {" "}
              <Col md="6 mb-3">
                <Dividers divide="IMAGE" />
                <p className="mb-4">
                  File type required JPG, PNG (512px X 512px)
                </p>
              </Col>
              <Row>
                <Col md="4 mb-3">
                  <div
                    className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    <span
                      onClick={() => {
                        OpenModalCommonFunc(
                          Cases.PRODUCT_UPLOAD,
                          setAllPopupState
                        );
                        setBackImgNo(".team-detail-section.bg-image");
                      }}
                    >
                      <UploadCloud />
                    </span>
                    {settingFormData?.[".team-detail-section.bg-image"]?.[
                      "background-image"
                    ] && (
                      <img
                        src={
                          settingFormData?.[".team-detail-section.bg-image"]?.[
                            "background-image"
                          ] || ""
                        }
                        alt=""
                        style={{
                          width: "85%",
                          height: "90px",
                          objectFit: "contain",
                          position: "absolute",
                        }}
                        onClick={() => {
                          OpenModalCommonFunc(
                            Cases.PRODUCT_UPLOAD,
                            setAllPopupState
                          );
                          setBackImgNo(".team-detail-section.bg-image");
                        }}
                      />
                    )}
                    {settingFormData?.[".team-detail-section.bg-image"]?.[
                      "background-image"
                    ] && (
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="close"
                        style={{
                          color: "red",
                          position: "absolute",
                          top: "-22px",
                          left: "1px",
                        }}
                        onClick={() => deleteImagePermanent()}
                      >
                        <Close
                          style={{
                            fontSize: "25px",
                            fontWeight: "bold",
                          }}
                        />
                      </IconButton>
                    )}
                  </div>
                </Col>
                <Col md="6 mb-3">
                  <h4 className="mb-3">
                    Add Background color in Detail section
                  </h4>
                  <Col md="6 mb-3">
                    <CommonColor
                      color={
                        settingFormData?.[
                          ".team-detail-section.bg-image::before"
                        ]?.["background-color"]
                      }
                      setSettingFormData={setSettingFormData}
                      className={".team-detail-section.bg-image::before"}
                      classProperty={"background-color"}
                      label={"Select Background  Color"}
                    />
                  </Col>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Change Background Color in Detail Section Img</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[".team-detail-section .single-team-img"]?.[
                "background-color"
              ]
            }
            setSettingFormData={setSettingFormData}
            className={".team-detail-section .single-team-img"}
            classProperty={"background-color"}
            label={"Select Background  Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin Bottom (Default : 20)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin Bottom"
            value={settingFormData?.[".team-detail-section .single-team-img"]?.[
              "margin-bottom"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTeamSectionDetailImg(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">
          Make change in Detail Section Team Member info Title
        </h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 24)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".team-detail-section .team-member-info .team-title"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTeamSectionDetailMemberInfoTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[
                ".team-detail-section .team-member-info .team-title"
              ]?.["font-weight"]
            }
            onChange={(e) => handleChangeTeamSectionDetailMemberInfoTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">
          Make change in Detail Section Team Member info designation
        </h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".team-detail-section .team-member-info .designation"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".team-detail-section .team-member-info .designation"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">
          Make change in Detail Section Team Member Social List
        </h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Padding (Default : 9)"}</Label>
          <Input
            className="form-control"
            name="padding"
            type="number"
            placeholder="padding"
            value={settingFormData?.[
              ".team-detail-section .team-member-info .social-list a"
            ]?.["padding"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTeamDetailSectionMemberSocialListA(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Margin (Default : 1)"}</Label>
          <Input
            className="form-control"
            name="margin"
            type="number"
            placeholder="margin"
            value={settingFormData?.[
              ".team-detail-section .team-member-info .social-list a"
            ]?.["margin"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTeamDetailSectionMemberSocialListA(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Width (Default : 40)"}</Label>
          <Input
            className="form-control"
            name="width"
            type="number"
            placeholder="width"
            value={settingFormData?.[
              ".team-detail-section .team-member-info .social-list a"
            ]?.["width"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTeamDetailSectionMemberSocialListA(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Height (Default : 40)"}</Label>
          <Input
            className="form-control"
            name="height"
            type="number"
            placeholder="height"
            value={settingFormData?.[
              ".team-detail-section .team-member-info .social-list a"
            ]?.["height"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTeamDetailSectionMemberSocialListA(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Text Align (Default : center)"}
          </Label>
          <select
            name="text-align"
            id="text-align"
            className="form-control"
            value={
              settingFormData?.[
                ".team-detail-section .team-member-info .social-list a"
              ]?.["text-align"]
            }
            onChange={(e) => handleChangeTeamDetailSectionMemberSocialListA(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {TextAlign.map((elem) => {
              return (
                <option value={elem["text-align"]}>{elem["text-align"]}</option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".team-detail-section .team-member-info .social-list a"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".team-detail-section .team-member-info .social-list a"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 20)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".team-detail-section .team-member-info .social-list a"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTeamDetailSectionMemberSocialListA(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border Radius (Default : 20)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[
              ".team-detail-section .team-member-info .social-list a"
            ]?.["border-radius"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTeamDetailSectionMemberSocialListA(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".team-detail-section .team-member-info .social-list a"
              ]?.["background-color"]
            }
            setSettingFormData={setSettingFormData}
            className={".team-detail-section .team-member-info .social-list a"}
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">
          Make change in Detail Section Team Member Social List hover
        </h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".team-detail-section .team-member-info .social-list a:hover"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".team-detail-section .team-member-info .social-list a:hover"
            }
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".team-detail-section .team-member-info .social-list a:hover"
              ]?.["background-color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".team-detail-section .team-member-info .social-list a:hover"
            }
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in personal details Title</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 24)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[".personal-details .details-title"]?.[
              "font-size"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeTeamSectionDetailTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[".personal-details .details-title"]?.[
                "font-weight"
              ]
            }
            onChange={(e) => handleChangeTeamSectionDetailTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in personal details Paragraph</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin Bottom (Default : 20)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin Bottom"
            value={settingFormData?.[".personal-details p"]?.[
              "margin-bottom"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductDetailsP(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Line Height (Default : 1.6)"}
          </Label>
          <Input
            className="form-control"
            name="line-height"
            type="number"
            placeholder="Line Height"
            value={settingFormData?.[".personal-details p"]?.["line-height"]}
            onChange={(e) => handleChangeProductDetailsP(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 18)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[".personal-details p"]?.[
              "font-size"
            ]?.replace(/px/g, "")}
            onChange={(e) => handleChangeProductDetailsP(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <PaginationButtonStyle
        mainClass={".ourteam-section"}
        settingFormData={settingFormData}
        setSettingFormData={setSettingFormData}
      />
      <CustomStyle
        handleChange={handleChangeCustomStyle}
        value={settingFormData?.custom}
      />
      {/* <Row className="mt-4">
                <h4 className="mb-3">personal-details | info-list</h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">{"Margin Bottom"}</Label>
                  <Input
                    className="form-control"
                    name="margin-bottom"
                    type="number"
                    placeholder="Margin Bottom"
                    value={settingFormData?.[".personal-details .info-list"]?.[
                      "margin-bottom"
                    ]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeProductDetailsListInfo(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">personal-details | info-list li</h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">{"Margin Bottom"}</Label>
                  <Input
                    className="form-control"
                    name="margin-bottom"
                    type="number"
                    placeholder="Margin Bottom"
                    value={settingFormData?.[
                      ".personal-details .info-list li"
                    ]?.["margin-bottom"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeProductDetailsListInfoLi(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">{"Font Size"}</Label>
                  <Input
                    className="form-control"
                    name="font-size"
                    type="number"
                    placeholder="Font Size"
                    value={settingFormData?.[
                      ".personal-details .info-list li"
                    ]?.["font-size"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeProductDetailsListInfoLi(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">personal-details | order-list</h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">{"Margin Bottom"}</Label>
                  <Input
                    className="form-control"
                    name="margin-bottom"
                    type="number"
                    placeholder="Margin Bottom"
                    value={settingFormData?.[".personal-details .order-list"]?.[
                      "margin-bottom"
                    ]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeProductDetailsOrderList(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">personal-details | order-list li</h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">{"Padding Left"}</Label>
                  <Input
                    className="form-control"
                    name="padding-left"
                    type="number"
                    placeholder="Padding Left"
                    value={settingFormData?.[
                      ".personal-details .order-list li"
                    ]?.["padding-left"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeProductDetailsOrderListLi(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">{"Margin Bottom"}</Label>
                  <Input
                    className="form-control"
                    name="margin-bottom"
                    type="number"
                    placeholder="Margin Bottom"
                    value={settingFormData?.[
                      ".personal-details .order-list li"
                    ]?.["margin-bottom"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeProductDetailsOrderListLi(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">{"Font Size"}</Label>
                  <Input
                    className="form-control"
                    name="font-size"
                    type="number"
                    placeholder="Font Size"
                    value={settingFormData?.[
                      ".personal-details .order-list li"
                    ]?.["font-size"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeProductDetailsOrderListLi(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">
                  personal-details | order-list li:before
                </h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">{"Font Family"}</Label>
                  <select
                    name="font-family"
                    id="font-family"
                    className="form-select"
                    value={
                      settingFormData?.[
                        ".personal-details .order-list li:before"
                      ]?.["font-family"]
                    }
                    onChange={(e) =>
                      handleChangeProductDetailsOrderListLiBefore(e)
                    }
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {fontFamily.map((elem) => {
                      return (
                        <option value={elem["font-family"]}>
                          {elem["font-family"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">{"Top"}</Label>
                  <Input
                    className="form-control"
                    name="top"
                    type="number"
                    placeholder="Top"
                    value={settingFormData?.[
                      ".personal-details .order-list li:before"
                    ]?.["top"]?.replace(/px/g, "")}
                    onChange={(e) =>
                      handleChangeProductDetailsOrderListLiBefore(e)
                    }
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row> */}
      <ProductImageUploadPopup
        allPopupState={allPopupState}
        setAllPopupState={setAllPopupState}
        crop={crop}
        setCrop={setCrop}
        completedCrop={completedCrop}
        setCompletedCrop={setCompletedCrop}
        scale={scale}
        rotate={rotate}
        image={settingFormData?.image}
        setImage={setSettingFormData}
        imageUpload={imageUpload}
        isEdit={isEdit}
        reupload={reupload}
        // aspect={2.25 / 1}
      />
    </>
  );
};

export default OurTeamStyleTwo;
