import React, { useContext } from "react";
import { MOMENT_SETTING, Style, TITLES } from "../../../../constant";
import { Add } from "@material-ui/icons";
import { DataGrid } from "@mui/x-data-grid";
import { Card, CardHeader, Col, Container, Row } from "reactstrap";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import { TabelAnimation } from "../../../../Spinner";
import DataNotFound from "../../../../DataNoteFound/DataNotFound";
import { MuiTabelStyle, headerStyle } from "../../../../utils/MuiTabelStyle";
import { IconButton, Tooltip } from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";

import Switch from "react-js-switch";
import moment from "moment";
import { TostMessage } from "../../../../components ByteCard/utils";
import TagsContext from "../../../../context/MenuMasterContext/TagsContext/TagsContext";
import { Ditails_Tags_List } from "../../../../api/MenuMasterApi/TagsApi/TagsApi";

const TagsItem = (props) => {
  const { sorting, switch_onChange_handle, handleDelete } = props;
  const { setLoaderShow, tabelAnimation } = useContext(LoaderContext);
  const {
    formValue,
    record,
    pageSize,
    page,
    setPageSize,
    setPage,
    setAddModel,
    setFormData,
    setIsEdit,
    setId,
  } = useContext(TagsContext);

  const EditHandle = async (tagMasterId) => {
    try {
      setLoaderShow(true);
      setId(tagMasterId);
      const { data } = await Ditails_Tags_List(tagMasterId);
      if (data.status === 200) {
        let payload = data.payload.data;

        setFormData({
          tag: payload?.tag || "",
          type: payload?.type || "",
          qpiId: payload?.qpiId || "",
        });
        setIsEdit(true);
        setLoaderShow(false);
        setAddModel(true);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 200,
      field: "tag",
      headerName: "Tag",
      renderCell: (params) => (params.row.tag ? params.row.tag : "-"),
    },
    {
      flex: 0.1,
      minWidth: 200,
      field: "type",
      headerName: "Type",
      renderCell: (params) => (params.row.type ? params.row.type : "-"),
    },
    {
      flex: 0.1,
      minWidth: 150,
      field: "createdAt",
      headerName: "Created Date",
      renderCell: (params) =>
        params.row.createdAt
          ? moment.utc(params.row.createdAt).format(MOMENT_SETTING.FORMATE)
          : "-",
    },
    {
      field: "isActive",
      headerName: "Status",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Switch
          value={params.row.isActive ? true : false}
          onChange={() =>
            switch_onChange_handle(params.row.tagMasterId, params.row.isActive)
          }
        />
      ),
    },
    {
      flex: 0.01,
      minWidth: 80,
      field: "edit",
      headerName: "Edit",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Tooltip title="Edit">
          <IconButton
            aria-label="edit"
            style={{ color: "green" }}
            onClick={() => EditHandle(params.row.tagMasterId)}
          >
            <ModeEditIcon />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      flex: 0.01,
      minWidth: 80,
      field: "delete",
      headerName: "Delete",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Tooltip title="Delete">
          <IconButton
            aria-label="delete"
            style={{ color: "red" }}
            onClick={() => handleDelete(params.row.tagMasterId)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  const AddProducts = () => {
    setAddModel(true);
  };
  return (
    <>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <h3 style={headerStyle}>{TITLES.TAGS_LIST}</h3>

                <div
                  aria-label="add"
                  onClick={() => AddProducts()}
                  className="btn btn-primary"
                  style={Style}
                >
                  <Add />
                </div>
              </CardHeader>
              {tabelAnimation === true ? (
                <TabelAnimation />
              ) : formValue.length ? (
                <DataGrid
                  autoHeight
                  sx={MuiTabelStyle}
                  pagination
                  rows={formValue}
                  getRowId={(row) => row.tagMasterId}
                  columns={defaultColumns}
                  rowsPerPageOptions={[7, 10, 25, 50]}
                  rowCount={record}
                  pageSize={pageSize}
                  page={page - 1}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  onPageChange={(newPageNo) => setPage(newPageNo + 1)}
                  paginationMode="server"
                  disableSelectionOnClick
                  checkboxSelection={false}
                  sortingMode="server"
                  onSortModelChange={sorting}
                />
              ) : (
                <DataNotFound />
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default TagsItem;
