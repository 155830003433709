import React from "react";
import { useLocation } from "react-router";
import Login from "../auth/login";
import Signin from "../auth/signin";
import ProfileComponent from "../components/ProfileComponent";
import DataNotFound from "../DataNoteFound/DataNotFound";
import DateNotFoundV2 from "../DataNoteFound/DateNotFoundV2";
import ForgotPassword from "../auth/ForgotPsaaword";
import ResetPassword from "../auth/ResetPassword";
// Authentication

export default function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
export const authRoutes = [
  { path: `${process.env.PUBLIC_URL}/:hwId`, Component: <Login /> },
  { path: `${process.env.PUBLIC_URL}/:hwId/sign-in`, Component: <Signin /> },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/Profile`,
    Component: <ProfileComponent />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/send-mail`,
    Component: <ForgotPassword />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/forgot-password/:token`,
    Component: <ResetPassword />,
  },

  // {
  //   path: `${process.env.PUBLIC_URL}/:hwId`,
  //   Component: <LandingPage />,
  // },
  // {
  //   path: `${process.env.PUBLIC_URL}/:hwId/:brokerId`,
  //   Component: <BrokerPage />,
  // },
  {
    path: `${process.env.PUBLIC_URL}/`,
    Component: <DataNotFound />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/404`,
    Component: <DateNotFoundV2 />,
  },
];
