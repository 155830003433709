export const MuiTabelStyle = {
  "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
    outline: "none !important",
  },
  ".MuiDataGrid-columnHeader:focus": {
    outline: "none !important",
    border: "none !important",
  },
};

export const headerStyle = {
  display: "flex",
  flexDirection: "colums",
  justifyContent: "center",
  alignItems: "center",
};
