import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Inquiry_Page,
  Show_Inquiry_Page,
} from "../../../api/Organization Api/OrganizationApi";
import { TYPES } from "../../../constant";
import PersonalPageContext from "../../../context/PersonalPageContext/PersonalPageContaxt";
import Breadcrumbs from "../../../layout/breadcrumb";
import { tableId } from "../../../utils/helper";
import TopLoadingBar from "../../../utils/topLoadingBar";
import InquiaryShowPopup from "./InquiaryShowPopup";
import InquiryPageItem from "./InquiryPageItem";

const WebInquiryPage = () => {
  const [formvalue, setformValue] = useState([]);
  // const [pageUuid, setPageUuid] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [model, setModel] = useState(false);
  const [detail, setDetail] = useState("");
  const [sortBy, setSortBy] = useState("createdAt");
  const { setRecord, pageSize, page, progress, setProgress } =
    useContext(PersonalPageContext);

  /**
   * Inquary Page Detils Get FUntion to call and useEffect to use of inquiryPage() function cll and toploading call
   */

  const inquiryPage = async () => {
    const paramObj = {
      pageUuid: localStorage.getItem("pageUuid"),
      pageNumber: page,
      recordsPerPage: pageSize,
      sortOrder,
      sortBy,
      search: { inquiryType: TYPES.WEB_INQUIRY },
    };
    try {
      const { data } = await Inquiry_Page(paramObj);

      if (data.status === 200) {
        setformValue(data.payload.data);
        setRecord(data.pager.totalRecords);
      } else {
      }
    } catch (error) {}
  };

  useEffect(() => {
    inquiryPage();
  }, [page, pageSize, sortOrder, sortBy]);

  useMemo(() => {
    tableId(page, pageSize, formvalue);
  }, [page, pageSize, formvalue]);

  useEffect(() => {
    setProgress(100);
  }, []);

  /**
   * Show Inquary Function Call
   * @param {get inquaryId} inquiryId
   */
  const SHowInquary = async (inquiryId) => {
    try {
      const { data } = await Show_Inquiry_Page(inquiryId);
      if (data.status === 200) {
        setModel(!model);
        setDetail(data.payload.data);
      } else {
      }
    } catch (error) {}
  };
  return (
    <Fragment>
      <Breadcrumbs parent="Web InquiryPage" />
      <TopLoadingBar progress={progress} setProgress={setProgress} />
      <InquiryPageItem
        formvalue={formvalue}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        sortBy={sortBy}
        setSortBy={setSortBy}
        SHowInquary={SHowInquary}
      />
      <InquiaryShowPopup model={model} setModel={setModel} detail={detail} />
    </Fragment>
  );
};

export default WebInquiryPage;
