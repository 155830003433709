import axios from "axios";
import {
  GET_LATEST_NEWS,
  GET_LATEST_NEWS_DETAILS,
  GET_IMAGE,
  GET_IMAGE_DETAILS,
  POST_LATEST_NEWS,
  POST_IMAGE,
  PUT_LATEST_NEWS_ORDER,
  PUT_IMAGE_ORDER,
  PUT_STATUS_LATEST_NEWS,
  PUT_STATUS_IMAGE,
  GET_GALLERY,
  PUT_STATUS_GALLERY,
  POST_GALLERY,
  GET_GALLERY_DETAILS,
  PUT_GALLERY_ORDER,
} from "../../apiConstants";

/**
 * get images parent
 * @param {Parameters} paramObj
 * @returns
 */
export const getImagesParent = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_GALLERY}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * enable-disable images parent
 * @param {body} body
 * @returns
 */
export const updateStatus = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_STATUS_GALLERY}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * create images parent
 * @param {body} body
 * @returns
 */
export const postImagesParent = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(`${process.env.REACT_APP_API_URL}${POST_GALLERY}`, body, {
    headers: { Authorization: jwt_token },
  });
};

/**
 * update images parent
 * @param {body} body
 * @returns
 */
export const putUpdateImagesParent = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(`${process.env.REACT_APP_API_URL}${POST_GALLERY}`, body, {
    headers: { Authorization: jwt_token },
    params: { galleryId: body.galleryId },
  });
};

/**
 * delete images parent
 * @param {id} galleryId
 * @returns
 */
export const deleteImagesParent = (galleryId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${POST_GALLERY}`, {
    headers: { Authorization: jwt_token },
    params: { galleryId },
  });
};

/**
 * get images parent details
 * @param {id} galleryId
 * @returns
 */
export const getImagesParentDetails = (galleryId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_GALLERY_DETAILS}`, {
    headers: { Authorization: jwt_token },
    params: { galleryId },
  });
};

/**
 * short order images parent
 * @param {body} updateOrder
 * @returns
 */
export const sort_order_images_parent = (updateOrder) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_GALLERY_ORDER}`,
    { updateOrder: updateOrder },
    {
      headers: { Authorization: jwt_token },
    }
  );
};

//images items

/**
 * get images items data
 * @param {Parameters} paramObj
 * @returns
 */
export const getImages = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_IMAGE}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * enable-disable images
 * @param {body} body
 * @returns
 */
export const updateStatusImages = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_STATUS_IMAGE}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * create images
 * @param {body} body
 * @returns
 */
export const postImages = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(`${process.env.REACT_APP_API_URL}${POST_IMAGE}`, body, {
    headers: { Authorization: jwt_token },
  });
};

/**
 * update images
 * @param {body} body
 * @returns
 */
export const putUpdateImages = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(`${process.env.REACT_APP_API_URL}${POST_IMAGE}`, body, {
    headers: { Authorization: jwt_token },
    params: { galleryImgId: body.galleryImgId },
  });
};

/**
 * delete images
 * @param {id} galleryImgId
 * @returns
 */
export const deleteImages = (galleryImgId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${POST_IMAGE}`, {
    headers: { Authorization: jwt_token },
    params: { galleryImgId },
  });
};

/**
 * get images details
 * @param {id} galleryImgId
 * @returns
 */
export const getImagesDetails = (galleryImgId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_IMAGE_DETAILS}`, {
    headers: { Authorization: jwt_token },
    params: { galleryImgId },
  });
};

/**
 * short order images
 * @param {body} updateOrder
 * @returns
 */
export const sort_order_images = (updateOrder) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_IMAGE_ORDER}`,
    { updateOrder: updateOrder },
    {
      headers: { Authorization: jwt_token },
    }
  );
};
