import { Tooltip } from "@mui/material";
import React from "react";
import { Info } from "react-feather";
import { Button } from "reactstrap";

const OpenPopupBtn = (props) => {
  const { openPopup, name, btnInfo } = props;
  return (
    <div
      className="progress-showcase d-flex justify-content-between align-items-center "
      style={{ marginTop: "5px" }}
    >
      <div>
        <Button
          className="btn-square button-color"
          outline
          size="sm"
          onClick={() => openPopup()}
          style={{
            borderRadius: "5px",
            border: "2px solid #dad6ff",
            background: "#dad6ff",
            fontWeight: 600,
          }}
        >
          {name}
        </Button>
      </div>
      {btnInfo && (
        <Tooltip title="This includes your business details that will be used on ByteCard View. Some of the business details are fetched from the details you entered for your business Profile. You can also edit these details if you want, which will be also psated in your profile">
          <div className="info-btn-show">
            <Info />
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default OpenPopupBtn;
