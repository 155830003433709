import * as React from "react";
import { useContext } from "react";
import { useState } from "react";
import { toast, Zoom } from "react-toastify";
import {
  WebContentMapList,
  WebContentMasterList,
  WebNavBarConfigList,
  WebNavBarMapList,
  WebNavBarMasterList,
} from "../../api/SupperAdminOrganizationApi/Organization";
import { SORTING_ORDER } from "../../constant";
import LoaderContext from "../LoaderContext/LoaderContext";
import SettingMiniWebContext from "./SettingMiniWebContext";
import { randomStr } from "../../utils/helper";

const SettingMiniWebState = (props) => {
  const { setLoaderShow, setTableAnimation } = useContext(LoaderContext);
  const [formvalue, setformValue] = useState([]);
  const [formvalueNav, setformValueNav] = useState([]);
  const [formvalueConfig, setformValueConfig] = useState([]);
  const [formvalueWebContentMaster, setformValueWebContentMaster] = useState(
    []
  );
  const [formvalueWebNavMaster, setformValueWebNavMaster] = useState([]);
  const [formvalueWebNavNull, setformValueWebNavNull] = useState([]);
  const [formDataWebContent, setFormDataWebContent] = useState({
    orgId: "",
    contents: [],
  });
  const [formDataWebNavbar, setFormDataWebNavbar] = useState({
    orgId: "",
    navBars: [],
  });
  const [formDataWebConfig, setFormDataWebConfig] = useState({
    orgId: "",
    configs: [],
    navBar: "",
  });
  const [sortOrder, setSortOrder] = useState(""); //SORTING -->
  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState("createdAt"); //SORTING -->
  const [record, setRecord] = useState(0);
  const [progress, setProgress] = useState(0);
  const [filter, setFilter] = useState(""); //SEARCHING FILTER -->
  const [togglesubmit, setToggleSubmit] = useState(false);
  const [modal, setModal] = useState(false);
  const [check, setCheck] = useState(0);
  const [broker, setBroker] = useState("");
  const [mainPageName, setMainPageName] = useState("");
  const [subPageName, setSubPageName] = useState("");
  const [pageLimit, setPageLimit] = useState(1);
  const [orgName, setOrgName] = useState("");
  const [domain, setDomain] = useState("");

  /**web content map list */
  const WebContentMap_List = async (orgId, showAll) => {
    if (sortOrder === "ASC" || sortOrder === "DSC" || filter.length) {
    } else {
    }
    try {
      let paramObj = {};
      if (showAll) {
        paramObj = {
          showAll: true,
          orgId,
        };
      } else {
        paramObj = {
          pageNumber: page,
          recordsPerPage: pageSize,
          sortOrder,
          sortBy,
          orgId,
        };
      }
      const { data } = await WebContentMapList(paramObj);
      if (data.status === 200) {
        setformValue(data.payload.data);
        setRecord(data.pager.totalRecords);
      } else {
        setformValue([]);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setTableAnimation(false);
    }
  };
  /**web nav map list */
  const [pageNumberNav, setPageNumberNav] = useState(1);
  const [pageSizeNav, setPageSizeNav] = useState(5);
  const [sortOrderNav, setSortOrderNav] = useState("");
  const [sortByNav, setSortByNav] = useState("createdAt");
  const [recordNav, setRecordNav] = useState(0);
  const [modalNav, setModalNav] = useState(false);
  const [togglesubmitNav, setToggleSubmitNav] = useState(false);

  const WebNavBarMap_List = async ({ orgId, showAll, isParent, parentId }) => {
    try {
      let paramObj = {
        pageNumber: pageNumberNav,
        recordsPerPage: pageSizeNav,
        sortOrder: sortOrderNav,
        sortBy: sortByNav,
        orgId,
      };
      if (parentId !== "" && parentId !== undefined) {
        paramObj["search"] = { parentId };
      }
      if (showAll && typeof showAll === "boolean") {
        paramObj["showAll"] = true;
      }
      if (
        isParent &&
        isParent !== null &&
        isParent !== "" &&
        typeof isParent === "boolean"
      ) {
        paramObj = { showAll: true, orgId, search: { parentId: null } };
      }
      const { data } = await WebNavBarMapList(paramObj);
      if (data.status === 200) {
        if (isParent && isParent !== null && isParent !== "") {
          if (togglesubmitNav !== true) {
            setformValueWebNavNull([{ parentId: "", data: data.payload.data }]);
            return data.payload.data;
          }
        } else {
          setformValueNav(data.payload.data);
        }
        setRecordNav(data.pager.totalRecords);
      } else {
        setformValue([]);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setTableAnimation(false);
    }
  };
  /**web config page list */
  const [pageNumberConfig, setPageNumberConfig] = useState(1);
  const [pageSizeConfig, setPageSizeConfig] = useState(5);
  const [sortOrderConfig, setSortOrderConfig] = useState("");
  const [sortByConfig, setSortByConfig] = useState("createdAt");
  const [recordConfig, setRecordConfig] = useState(0);
  const [modalConfig, setModalConfig] = useState(false);
  const [togglesubmitConfig, setToggleSubmitConfig] = useState(false);
  const WebConfigPage_List = async (orgId, showAll) => {
    try {
      let paramObj = {};
      if (showAll) {
        paramObj = {
          showAll: true,
          orgId,
        };
      } else {
        paramObj = {
          pageNumber: pageNumberConfig,
          recordsPerPage: pageSizeConfig,
          sortOrder: sortOrderConfig,
          sortBy: sortByConfig,
          orgId,
        };
      }
      const { data } = await WebNavBarConfigList(paramObj);
      if (data.status === 200) {
        setformValueConfig(data.payload.data);
        setRecordConfig(data.pager.totalRecords);
      } else {
        setformValue([]);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setTableAnimation(false);
    }
  };

  /**web content master list */
  const WebContentMaster_List = async (orgId) => {
    try {
      let paramObj = {
        showAll: true,
      };
      const { data } = await WebContentMasterList(paramObj);
      if (data.status === 200) {
        setformValueWebContentMaster(data.payload.data);
      } else {
        setformValue([]);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setTableAnimation(false);
    }
  };

  /**web nav bar master list */
  const WebNavBarMaster_List = async (paretId) => {
    try {
      let paramObj = {
        showAll: true,
      };
      const { data } = await WebNavBarMasterList(paramObj);
      if (data.status === 200) {
        setformValueWebNavMaster(data.payload.data);
      } else {
        setformValue([]);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setTableAnimation(false);
    }
  };

  /**Sorting List */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder(SORTING_ORDER.ASC);
    }
    if (sortOrder === SORTING_ORDER.ASC) {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder(SORTING_ORDER.DSC);
    }
    if (sortOrder === SORTING_ORDER.DSC) {
      setSortBy();
      setSortOrder("");
    }
  };

  const closeModel = () => {
    setFormDataWebContent({ orgId: "", contents: [] });
    setFormDataWebNavbar({ orgId: "", navBars: [] });
    setFormDataWebConfig({ orgId: "", configs: [], navBar: "" });
    setformValueWebNavNull([]);
    setModal(false);
    setModalNav(false);
    setModalConfig(false);
    setLoaderShow(false);
  };
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [addFields, setAddFields] = useState([
    {
      id: randomStr(2),
      order: "",
      navId: "",
      label: "",
      slugUrl: "",
    },
  ]);

  return (
    <>
      <SettingMiniWebContext.Provider
        value={{
          formvalue,
          setformValue,
          sortOrder,
          setSortOrder,
          pageSize,
          setPageSize,
          page,
          setPage,
          sortBy,
          setSortBy,
          record,
          setRecord,
          progress,
          setProgress,
          filter,
          setFilter,
          WebContentMap_List,
          WebContentMaster_List,
          WebNavBarMap_List,
          sorting,
          togglesubmit,
          setToggleSubmit,
          modal,
          setModal,
          closeModel,
          pageLimit,
          setPageLimit,
          orgName,
          setOrgName,
          check,
          setCheck,
          broker,
          setBroker,
          domain,
          setDomain,
          mainPageName,
          setMainPageName,
          subPageName,
          setSubPageName,
          formDataWebContent,
          setFormDataWebContent,
          formvalueWebContentMaster,
          setformValueWebContentMaster,
          formvalueWebNavNull,
          setformValueWebNavNull,

          pageNumberNav,
          pageSizeNav,
          sortOrderNav,
          sortByNav,
          setPageNumberNav,
          setPageSizeNav,
          setSortOrderNav,
          setSortByNav,
          formvalueNav,
          setformValueNav,
          recordNav,
          setRecordNav,
          modalNav,
          setModalNav,
          togglesubmitNav,
          setToggleSubmitNav,

          WebNavBarMaster_List,
          formvalueWebNavMaster,
          setformValueWebNavMaster,
          formDataWebNavbar,
          setFormDataWebNavbar,

          formvalueConfig,
          setformValueConfig,
          pageNumberConfig,
          setPageNumberConfig,
          pageSizeConfig,
          setPageSizeConfig,
          sortOrderConfig,
          setSortOrderConfig,
          sortByConfig,
          setSortByConfig,
          recordConfig,
          setRecordConfig,
          modalConfig,
          setModalConfig,
          togglesubmitConfig,
          setToggleSubmitConfig,
          WebConfigPage_List,
          formDataWebConfig,
          setFormDataWebConfig,
          addFields,
          setAddFields,
          selectedOptions,
          setSelectedOptions,
        }}
      >
        {props.children}
      </SettingMiniWebContext.Provider>
    </>
  );
};

export default SettingMiniWebState;
