import { IconButton } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { Settings } from "react-feather";
import { Alert } from "reactstrap";
import SettingCommonStylesChanges from "./SettingCommonStylesChanges";
import CommonStylesChangesContext from "../../../context/CommonStylesChangesContext/CommonStylesChangesContext";
import { Cases, modalsName } from "../../../constant";
import { UpdateStyle, getCommonStyle } from "../apis/NavApi/NavApi";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import CommonPopup from "../../../Modals/commonPopup";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import { rgbColorCreate } from "../../../../utils/helper";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import { Button } from "react-bootstrap";

const CommonStylesChanges = () => {
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );
  const { setLoaderShow } = useContext(LoaderContext);
  const { allStyle, setAllStyle } = useContext(CommonStylesChangesContext);

  const getCommonStyleData = async () => {
    try {
      const paramObj = {};
      const data = await getCommonStyle(paramObj);
      if (data.status === 200) {
        const payload = data.data.payload.data.commonThemeColor;
        setAllStyle((prev) => {
          return {
            ...prev,
            ".section-title-area .section-subtitle": {
              "background-color":
                payload?.[".section-title-area .section-subtitle"]?.[
                  "background-color"
                ],
              color:
                payload?.[".section-title-area .section-subtitle"]?.["color"],
            },
            ".section-title-area .section-title": {
              "font-size":
                payload?.[".section-title-area .section-title"]?.["font-size"],
              color: payload?.[".section-title-area .section-title"]?.["color"],
            },
            ".more-btn": {
              "background-color": payload?.[".more-btn"]?.["background-color"],
              color: payload?.[".more-btn"]?.["color"],
            },
            ".more-btn:hover": {
              "background-color":
                payload?.[".more-btn:hover"]?.["background-color"],
              color: payload?.[".more-btn:hover"]?.["color"],
            },
            ".slick-arrow::before": {
              color: payload?.[".slick-arrow::before"]?.["color"]?.replace(
                " !important",
                ""
              ),
            },
            ".slick-arrow:before": {
              color: payload?.[".slick-arrow:before"]?.["color"]?.replace(
                " !important",
                ""
              ),
            },
            ".slick-dots li.slick-active button:before": {
              color: payload?.[".slick-dots li.slick-active button:before"]?.[
                "color"
              ]?.replace(" !important", ""),
            },
            ".slick-dots li button:before": {
              color: payload?.[".slick-dots li button:before"]?.[
                "color"
              ]?.replace(" !important", ""),
            },
            mainDefValue: {
              colorOne:
                payload?.[".gallery-catlist .active"]?.["background-color"],
              colorTwo: payload?.[".gallery-catlist .active"]?.["color"],
              mainPadding:
                payload?.[".faqs-section"]?.["padding"]?.split(" ")[0],
              titleBottomMargin:
                payload?.[".section-title-area"]?.["margin-bottom"],
            },
          };
        });
      }
    } catch (error) {}
  };
  useEffect(() => {
    getCommonStyleData();
  }, []);

  const handleSubmitSetting = async (isClose = true) => {
    setLoaderShow(true);
    try {
      const setColorOne = rgbColorCreate({
        rgbString: allStyle?.["mainDefValue"]?.["colorOne"],
      });
      const setColorTwo = rgbColorCreate({
        rgbString: allStyle?.["mainDefValue"]?.["colorTwo"],
      });
      const mainPadding = allStyle?.["mainDefValue"]?.mainPadding;
      const titleBottomMargin = allStyle?.["mainDefValue"]?.titleBottomMargin;
      const formData = {
        ...allStyle,
        ".section-title-area .section-subtitle": {
          ...allStyle?.[".section-title-area .section-subtitle"],
          "background-color": allStyle?.[
            ".section-title-area .section-subtitle"
          ]?.["background-color"]
            ? rgbColorCreate({
                rgbString:
                  allStyle?.[".section-title-area .section-subtitle"]?.[
                    "background-color"
                  ],
              })
            : "",
          color: allStyle?.[".section-title-area .section-subtitle"]?.["color"]
            ? rgbColorCreate({
                rgbString:
                  allStyle?.[".section-title-area .section-subtitle"]?.[
                    "color"
                  ],
              })
            : "",
        },
        ".section-title-area .section-title": {
          ...allStyle?.[".section-title-area .section-title"],
          color: allStyle?.[".section-title-area .section-title"]?.["color"]
            ? rgbColorCreate({
                rgbString:
                  allStyle?.[".section-title-area .section-title"]?.["color"],
              })
            : "",
          "font-size": `${allStyle?.[".section-title-area .section-title"]?.[
            "font-size"
          ]?.replace("px", "")}px`,
        },
        ".more-btn": {
          ...allStyle?.[".more-btn"],
          "background-color": allStyle?.[".more-btn"]?.["background-color"]
            ? rgbColorCreate({
                rgbString: allStyle?.[".more-btn"]?.["background-color"],
              })
            : "",
          color: allStyle?.[".more-btn"]?.["color"]
            ? rgbColorCreate({
                rgbString: allStyle?.[".more-btn"]?.["color"],
              })
            : "",
        },
        ".more-btn:hover": {
          ...allStyle?.[".more-btn:hover"],
          "background-color": allStyle?.[".more-btn:hover"]?.[
            "background-color"
          ]
            ? rgbColorCreate({
                rgbString: allStyle?.[".more-btn:hover"]?.["background-color"],
              })
            : "",
          color: allStyle?.[".more-btn:hover"]?.["color"]
            ? rgbColorCreate({
                rgbString: allStyle?.[".more-btn:hover"]?.["color"],
              })
            : "",
        },
        ".slick-arrow::before": {
          ...allStyle?.[".slick-arrow::before"],
          color: allStyle?.[".slick-arrow::before"]?.["color"]
            ? `${rgbColorCreate({
                rgbString: allStyle?.[".slick-arrow::before"]?.["color"],
              })} !important`
            : "",
        },
        ".slick-arrow:before": {
          color: allStyle?.[".slick-arrow::before"]?.["color"]
            ? `${rgbColorCreate({
                rgbString: allStyle?.[".slick-arrow::before"]?.["color"],
              })} !important`
            : "",
        },
        ".slick-dots li.slick-active button:before": {
          ...allStyle?.[".slick-dots li.slick-active button:before"],
          color: allStyle?.[".slick-dots li.slick-active button:before"]?.[
            "color"
          ]
            ? `${rgbColorCreate({
                rgbString:
                  allStyle?.[".slick-dots li.slick-active button:before"]?.[
                    "color"
                  ],
              })} !important`
            : "",
        },
        ".testimonial-content:before": {
          color: setColorOne,
        },
        ".slick-dots li button:before": {
          ...allStyle?.[".slick-dots li button:before"],
          color: allStyle?.[".slick-dots li button:before"]?.["color"]
            ? `${rgbColorCreate({
                rgbString:
                  allStyle?.[".slick-dots li button:before"]?.["color"],
              })} !important`
            : "",
        },
        ".page-item.active .page-link": {
          "background-color": setColorOne,
          "border-color": setColorOne,
        },
        ".page-link": {
          color: setColorOne,
        },
        ".gallery-catdata .gallery-img .title-text": {
          "background-color": setColorOne,
          color: setColorTwo,
        },
        ".gallery-catlist .active": {
          "background-color": setColorOne,
          color: setColorTwo,
        },
        ".brochure-list .link-text": {
          "background-color": setColorOne,
          color: setColorTwo,
        },
        ".package .selectbtn:before": {
          "background-color": setColorOne,
        },
        ".stepdiv .step-count": {
          "background-color": setColorOne,
          color: setColorTwo,
        },
        ".blog_content .link-btn": {
          "background-color": setColorOne,
          color: setColorTwo,
        },
        ".about-details ul li:before": {
          "background-color": setColorOne,
          color: setColorOne,
        },
        ".blog_content .blog_title a:hover": {
          color: setColorOne,
        },
        ".packagelist-1 .package .title": {
          "background-color": setColorOne,
        },
        ".packagelist-2 .package .title": {
          "background-color": setColorOne,
        },
        ".packagelist-1 .package .selectbtn": {
          "background-color": setColorOne,
        },
        ".packagelist-2 .package .selectbtn": {
          "background-color": setColorOne,
        },
        ".slider-content .slide-btn1": {
          "background-color": setColorOne,
        },
        ".foot-copright": {
          "background-color": setColorOne,
        },
        ".navbar-nav .nav-link:hover,.custom-nav .navbar-nav .nav-link.active":
          { color: setColorOne },
        ".breadcrumb .breadcrumb-item a": { color: setColorOne },
        ".product-catdata .product-img .post-date": {
          color: setColorOne,
        },
        ".gallery-catlist .btn": {
          color: setColorOne,
          border: `2px solid ${setColorOne}`,
        },
        ".feature-list h5": { color: setColorOne },
        ".member-data .name a:hover": { color: setColorOne },
        ".member-data .position": { color: setColorOne },
        ".countdiv .counter": { color: setColorOne },
        ".testimonial-content .star-rating i": {
          color: setColorOne,
        },
        ".servicediv .links": { color: setColorOne },
        ".blog_content .blog_meta .posted": { color: setColorOne },
        ".visiondiv_sub .btn": { color: setColorOne },
        ".top-header-section": {
          color: setColorOne,
          "background-color": setColorOne,
        },
        "marquee a": { color: setColorOne },
        ".product-catdata .product-content .shop a:hover": {
          color: setColorOne,
        },
        ".about-details .btn": {
          border: `2px solid ${setColorOne}`,
          color: setColorTwo,
          "background-color": setColorOne,
        },
        ".getlinksbox": { "border-top": `3px solid ${setColorOne}` },
        ".faq-wrapper": { "border-top": `3px solid ${setColorOne}` },
        ".accordion-item": {
          "border-bottom": `1px solid ${setColorOne}`,
        },
        ".btn.btn-submit": {
          "border-color": setColorOne,
          color: setColorTwo,
          "background-color": setColorOne,
        },
        ".testimonial-content .avatar-img": {
          border: `2px solid ${setColorOne}`,
        },
        ".btn-pink": {
          border: `2px solid ${setColorOne}`,
          "background-color": setColorOne,
          color: setColorTwo,
        },
        ".foot-contact .icons": {
          border: `5px solid ${setColorOne}`,
          "background-color": setColorTwo,
        },
        ".inquiry-box": {
          "border-top": `3px solid ${setColorOne}`,
        },
        ".contactbox": {
          "border-top": `3px solid ${setColorOne}`,
        },
        ".slider-content .btn": {
          border: `2px solid ${setColorOne}`,
          color: setColorTwo,
        },
        ".cus-boxes:before": {
          "background-color": setColorOne,
        },
        ".subdiv h4": {
          color: setColorOne,
        },

        ".flaticon": {
          border: `1px solid ${setColorOne}`,
        },
        ".testimonial-divs .avatar-img": {
          border: `2px solid ${setColorOne}`,
        },
        ".marquee-link a": {
          color: setColorTwo,
        },
        ".star-rating i": {
          color: setColorOne,
        },
        ".boxdiv .links": {
          color: setColorOne,
        },
        ".countdiv .divider": {
          "background-color": setColorTwo,
        },
        ".video-play": {
          "background-color": setColorTwo,
          color: setColorOne,
        },
        ".top-left a": { color: setColorTwo },
        ".navbar-nav .nav-link": { color: setColorTwo },
        ".page-title": { color: setColorTwo },
        ".breadcrumb .breadcrumb-item.active": {
          color: setColorTwo,
        },
        ".login-user .dropdown-toggle": {
          color: setColorTwo,
          "background-color": setColorOne,
        },
        ".slider-content .slide-title": { color: setColorTwo },
        ".contact_form .btn": {
          color: setColorTwo,
          "background-color": setColorOne,
        },
        ".member-data .socia-list a": {
          border: `2px solid ${setColorOne}`,
        },
        ".member-data .socia-list a:hover": {
          color: setColorTwo,
          "border-color": setColorOne,
          "background-color": setColorOne,
        },
        ".getlinksbox .btn": {
          color: setColorTwo,
          "background-color": setColorOne,
        },
        ".countdiv .counter-title": { color: setColorTwo },
        ".accordion-button:not(.collapsed)": {
          color: setColorTwo,
          "background-color": setColorOne,
        },
        ".certificates-list .title-text": {
          color: setColorTwo,
          "background-color": setColorOne,
        },
        ".package .cost span": {
          color: setColorTwo,
          border: `2px solid ${setColorTwo}`,
        },
        ".package .subtitle": { color: setColorTwo },
        ".package .selectbtn a": {
          color: setColorTwo,
          border: `2px solid ${setColorTwo}`,
        },
        ".inquirybox": {
          "border-top": `3px solid ${setColorOne}`,
        },
        ".call-action-section2 h3": { color: setColorTwo },
        ".call-action-section2 h5": { color: setColorTwo },
        ".foot-copright p": { color: setColorTwo },
        ".product-catdata .product-content .add-cart": {
          color: setColorTwo,
          "background-color": setColorOne,
          border: `2px solid ${setColorOne}`,
        },
        ".video-list .title-text": {
          "background-color": setColorOne,
          color: setColorTwo,
        },
        ".news-label": { color: setColorTwo },
        ".member-data:hover": {
          "border-color": setColorOne,
        },
        ".faqs-section": {
          padding: `${mainPadding?.replace("px", "")}px 0`,
        },
        ".vision-section6": {
          padding: `${mainPadding?.replace("px", "")}px 0`,
        },
        ".about-section": {
          padding: `${mainPadding?.replace("px", "")}px 0`,
        },
        ".clients-section": {
          padding: `${mainPadding?.replace("px", "")}px 0`,
        },
        ".about2-section": {
          padding: `${mainPadding?.replace("px", "")}px 0`,
        },
        ".certificates-section": {
          padding: `${mainPadding?.replace("px", "")}px 0`,
        },
        ".only-text-section": {
          padding: `${mainPadding?.replace("px", "")}px 0`,
        },
        ".products-section": {
          padding: `${mainPadding?.replace("px", "")}px 0`,
        },
        ".service2-section": {
          padding: `${mainPadding?.replace("px", "")}px 0`,
        },
        ".vision-section": {
          padding: `${mainPadding?.replace("px", "")}px 0`,
        },
        ".contact-sectionundefined": {
          padding: `${mainPadding?.replace("px", "")}px 0`,
        },
        ".packages-section": {
          padding: `${mainPadding?.replace("px", "")}px 0`,
        },
        ".video-section": {
          padding: `${mainPadding?.replace("px", "")}px 0`,
        },
        ".vision2-section": {
          padding: `${mainPadding?.replace("px", "")}px 0`,
        },
        ".inquiry-section": {
          padding: `${mainPadding?.replace("px", "")}px 0`,
        },
        ".photo-gallery-section": {
          padding: `${mainPadding?.replace("px", "")}px 0`,
        },
        ".brochure-section": {
          padding: `${mainPadding?.replace("px", "")}px 0`,
        },
        ".getlink-section": {
          padding: `${mainPadding?.replace("px", "")}px 0`,
        },
        ".product-features-section": {
          padding: `${mainPadding?.replace("px", "")}px 0`,
        },
        ".team-section": {
          padding: `${mainPadding?.replace("px", "")}px 0`,
        },
        ".career-section": {
          padding: `${mainPadding?.replace("px", "")}px 0`,
        },
        ".counter-section": {
          padding: `${mainPadding?.replace("px", "")}px 0`,
        },
        ".step-by-step-section": {
          padding: `${mainPadding?.replace("px", "")}px 0`,
        },
        ".service-section": {
          padding: `${mainPadding?.replace("px", "")}px 0`,
        },
        ".blog-section": {
          padding: `${mainPadding?.replace("px", "")}px 0`,
        },
        ".testimonial-section": {
          padding: `${mainPadding?.replace("px", "")}px 0`,
        },

        // make space in title bottom
        ".section-title-area": {
          "margin-bottom": `${titleBottomMargin?.replace("px", "")}px`,
        },
      };
      delete formData?.mainDefValue;
      const formAddData = { data: { ...formData } };
      const { data } = await UpdateStyle(formAddData);
      if (data.status === 200) {
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
        if (isClose) {
          OpenModalCommonFunc(
            Cases.SETTING_COMMON_STYLE_PARENT,
            setAllPopupState,
            false
          );
        }
        getCommonStyleData();
      }
    } catch (error) {
      setLoaderShow(false);
      TostMessage({ message: error, type: "error" });
    }
  };

  const emptyData = () => {};

  return (
    <>
      <Button
        aria-label="edit"
        onClick={() => {
          OpenModalCommonFunc(
            Cases.SETTING_COMMON_STYLE_PARENT,
            setAllPopupState
          );
        }}
        style={{ marginBottom: "5px", padding: "5px 10px" }}
        className="d-flex align-item-center justify-content-center"
      >
        <span style={{ paddingRight: "5px", paddingTop: "2px" }}>
          <SettingsSuggestIcon />
        </span>{" "}
        <span style={{ paddingTop: "5px" }}>Common Style</span>
      </Button>
      <CommonPopup
        open={allPopupState.commonStyleSettingPopup}
        close={() =>
          OpenModalCommonFunc(
            Cases.SETTING_COMMON_STYLE_PARENT,
            setAllPopupState,
            false
          )
        }
        modalName={modalsName.SETTING_POPUP_DIALOG_PARENT}
        modalCase={Cases.SETTING_COMMON_STYLE_PARENT}
        handleSubmit={handleSubmitSetting}
        emptyData={emptyData}
        inputData={allStyle}
        edit={false}
        content={
          <SettingCommonStylesChanges
            handleSubmitSetting={handleSubmitSetting}
          />
        }
      />
    </>
  );
};

export default CommonStylesChanges;
