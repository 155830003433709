import React, { useContext, useEffect, useRef, useState } from "react";
import ProductsContext from "../../../../context/MenuMasterContext/ProductsContext/ProductsContext";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import {
  Add_Products_List,
  Delete_Products_List,
  Edit_Products_List,
  MenuPageList_Enable_Desable,
  ProductsList,
} from "../../../../api/MenuMasterApi/ProductsApi/ProductsApi";
import { useParams } from "react-router";
import ProductsItem from "./ProductsItem";
import { FILE_TYPE, SORTING_ORDER } from "../../../../constant";
import { Zoom, toast } from "react-toastify";
import ProductsAddPopup from "./ProductsAddPopup";
import SimpleReactValidator from "simple-react-validator";
import { FILE_UPLOAD_API } from "../../../../components ByteCard/apis/AboutApi/AboutUsApi";
import { TostMessage } from "../../../../components ByteCard/utils";
import { DELETE_CONFIRM } from "../../../../components ByteCard/constant";

const Products = () => {
  const {
    setFormValue,
    formData,
    pageSize,
    sortOrder,
    setSortOrder,
    page,
    sortBy,
    setSortBy,
    setPageLimit,
    setRecord,
    isSwitchOn,
    setIsSwitchOn,
    addModel,
    setFormData,
    setAddModel,
    isEdit,
    setIsEdit,
    id,
    setId,
  } = useContext(ProductsContext);
  const { setLoaderShow } = useContext(LoaderContext);
  const { QpiId } = useParams();
  const { tabelAnimation, setTableAnimation } = useContext(LoaderContext);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();

  const getProductsList = async (desabledStatusLoader) => {
    if (
      sortOrder === "ASC" ||
      sortOrder === "DSC" ||
      desabledStatusLoader === false
    ) {
    } else {
      setTableAnimation(!tabelAnimation);
    }
    try {
      let paramObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
        qpiId: QpiId,
      };
      const { data } = await ProductsList(paramObj);

      if (data.status === 200) {
        setFormValue(data.payload.data);
        setPageLimit(data.payload.pageLimit);
        setRecord(data.pager.totalRecords);

        setTableAnimation(false);
      } else {
      }
    } catch (error) {
      setTableAnimation(false);
    }
  };

  useEffect(() => {
    getProductsList();
  }, [page, pageSize, sortBy, sortOrder]);

  /**
   * Enable and Desable product
   */
  const switch_onChange_handle = async (menuProductId, isActive) => {
    let formData = {
      menuProductId: menuProductId,
      isActive: isActive ? false : true,
      // type: TYPES.LANDING_PAGE,
    };
    try {
      const { data } = await MenuPageList_Enable_Desable(
        formData,
        menuProductId
      );
      if (data.status === 200) {
        await setIsSwitchOn(!isSwitchOn);
        const desabledStatusLoader = false;
        await getProductsList(desabledStatusLoader);
        isActive
          ? toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            })
          : toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            });
      } else {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * file upload
   * @param {file} file
   */
  const imageUploadImages = async (file) => {
    setLoaderShow(true);
    try {
      const Image = await imageUpload(file);
      setFormData((prev) => {
        if (formData.imageName == "imageSquare") {
          return {
            ...prev,
            image: Image,
            imageSquare: Image,
          };
        } else if (formData.imageName == "imageRectangle") {
          return {
            ...prev,
            image: Image,
            imageRectangle: Image,
          };
        }
      });
      setLoaderShow(false);
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const imageUpload = async (file) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.PRODUCT);
    formData.append("file", file);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        return data.payload.baseUrl + data.payload.imageUrl;
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * Sorting Products Table
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder(SORTING_ORDER.ASC);
    }
    if (sortOrder === SORTING_ORDER.ASC) {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder(SORTING_ORDER.DSC);
    }
    if (sortOrder === SORTING_ORDER.DSC) {
      setSortBy();
      setSortOrder("");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        if (isEdit && id >= -1) {
          const stringValue = formData.tags.map((item) => item.value).join(",");
          const menuCategory = formData.menuCategoryIds.map((ele) => {
            return {
              menuCategoryId: ele.value,
            };
          });
          let addFromData = {
            ...formData,
            imageSquare: formData?.imageSquare
              ? formData?.imageSquare
                  .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
                  .pop()
              : "",
            imageRectangle: formData?.imageRectangle
              ? formData?.imageRectangle
                  .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
                  .pop()
              : "",
            menuCategoryIds: menuCategory,
            tags: stringValue,
            qpiId: QpiId,
          };
          delete addFromData.image;
          delete addFromData.imageName;
          const { data } = await Edit_Products_List(addFromData, id);
          if (data.status === 200) {
            getProductsList();
            emptyData();
            TostMessage({ message: data, type: "success" });
          }
        } else {
          const stringValue = formData.tags.map((item) => item.value).join(",");
          const menuCategory = formData.menuCategoryIds.map((ele) => {
            return {
              menuCategoryId: ele.value,
            };
          });
          let addFromData = {
            ...formData,
            imageSquare: formData.imageSquare
              ? formData.imageSquare
                  .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
                  .pop()
              : "",
            imageRectangle: formData.imageRectangle
              ? formData.imageRectangle
                  .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
                  .pop()
              : "",
            menuCategoryIds: menuCategory,
            tags: stringValue,
            qpiId: QpiId,
          };
          delete addFromData.image;
          delete addFromData.imageName;
          const { data } = await Add_Products_List(addFromData);
          if (data.status === 200) {
            getProductsList();
            emptyData();
            TostMessage({ message: data, type: "success" });
          }
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  /**
   * delete products
   * @param {id} menuProductId
   */
  const handleDelete = async (menuProductId) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await Delete_Products_List(menuProductId);
        if (data.status === 200) {
          getProductsList();
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const emptyData = () => {
    setFormData({
      name: "",
      price: "",
      type: "",
      tags: "",
      rating: "",
      shortDescription: "",
      longDescription: "",
      imageRectangle: "",
      imageSquare: "",
      qpiId: "",
      productOrder: "",
      menuCategoryId: "",
    });
    setAddModel(false);
    setIsEdit(false);
    setId("");
  };
  return (
    <>
      {addModel && (
        <ProductsAddPopup
          handleSubmit={handleSubmit}
          simpleValidator={simpleValidator}
          imageUpload={imageUploadImages}
          QpiId={QpiId}
          emptyData={emptyData}
        />
      )}

      <ProductsItem
        sorting={sorting}
        switch_onChange_handle={switch_onChange_handle}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default Products;
