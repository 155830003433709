import React, { Fragment, useContext, useEffect, useMemo } from "react";
import Breadcrumbs from "../../layout/breadcrumb";
import { tableId } from "../../utils/helper";
import TopLoadingBar from "../../utils/topLoadingBar";
import PageItemMasterAddPopup from "./PageItemMasterAddPopup";
import PageItemMasterItem from "./PageItemMasterItem";
import PageItemMasterContext from "../../context/PageItemMasterContext/PageItemMasterContext";

const PageItemMaster = () => {
  const {
    sortOrder,
    pageSize,
    page,
    sortBy,
    progress,
    setProgress,
    filter,
    OrganizationList,
    formvalue,
  } = useContext(PageItemMasterContext);

  /**useEffect to use of Organization() function call and toploading call to use  */

  useEffect(() => {
    OrganizationList();
    setProgress(100);
  }, [page, pageSize, sortOrder, sortBy, filter]);

  useMemo(() => {
    tableId(page, pageSize, formvalue);
  }, [page, pageSize, formvalue]);

  return (
    <Fragment>
      <Breadcrumbs parent="Page Item" />
      <TopLoadingBar progress={progress} setProgress={setProgress} />
      <PageItemMasterItem />
      <PageItemMasterAddPopup />
    </Fragment>
  );
};

export default PageItemMaster;
