import React, { useContext } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { toast, Zoom } from "react-toastify";
import { updateOrganizationDetail } from "../api/Login Api/LoginApi";
import { Toast } from "./signinToast";
import LoaderContext from "../context/LoaderContext/LoaderContext";
import { TYPES } from "../constant";
import { checkSlugUrl } from "../api/Organization Api/OrganizationApi";
import PersonalPageContext from "../context/PersonalPageContext/PersonalPageContaxt";

const MainOrganizationUpdate = ({ orgUpdateModal, setOrgUpdateModal }) => {
  const { formDataOrg, setFormDataOrg } = useContext(PersonalPageContext);

  const { loaderShow, setLoaderShow } = useContext(LoaderContext);
  const emptyData = () => {
    setFormDataOrg({ orgName: "", slugUrl: "" });
    setOrgUpdateModal(false);
  };

  const OrganizationUpdate = async (e) => {
    e.preventDefault();
    setLoaderShow(!loaderShow);
    try {
      const { data } = await updateOrganizationDetail({
        formData: formDataOrg,
        orgUuid: localStorage.getItem("orgUuid"),
      });
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        emptyData();
        setLoaderShow(false);
      } else {
        Toast.fire({
          icon: "error",
          title: data.message,
        });
        setLoaderShow(false);
      }
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: error.response.data.message,
      });
      setLoaderShow(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormDataOrg((prev) => {
      return {
        ...prev,
        [name]:
          name === "slugUrl" ? value.replace(/\s+/g, "-").toLowerCase() : value,
      };
    });
  };

  const handleFocusChange = async () => {
    if (formDataOrg.slugUrl) {
      try {
        let paramObj = {
          slugUrl: formDataOrg.slugUrl,
          type: TYPES.LANDING_PAGE,
          orgId: localStorage.getItem("orgId"),
        };
        await checkSlugUrl(paramObj);
      } catch (error) {
        toast.error(error.response.data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    }
  };
  return (
    <>
      <Modal size="md" isOpen={orgUpdateModal} toggle={() => emptyData()}>
        <ModalHeader toggle={() => emptyData()}>
          Organization Update
        </ModalHeader>
        <ModalBody>
          <Container fluid={true} className="p-0">
            <Row>
              <Form
                className="theme-form"
                onSubmit={(e) => OrganizationUpdate(e)}
              >
                <Col md="12 mb-4">
                  <Label>{"Organization Name"}</Label>
                  <Input
                    className="form-control"
                    type={"text"}
                    name="orgName"
                    onChange={(e) => handleChange(e)}
                    value={formDataOrg.orgName}
                    placeholder="Organization Name"
                  />
                </Col>
                <Col>
                  <Label>{"Slug Url"}</Label>
                  <Input
                    className="form-control"
                    type={"text"}
                    name="slugUrl"
                    onChange={(e) => handleChange(e)}
                    onBlur={() => handleFocusChange()}
                    value={formDataOrg.slugUrl}
                    placeholder="Slug Url"
                  />
                </Col>
                <div className="login-btn mt-2">
                  <Button color="primary" type="submit" value="submit">
                    {"Submit"}
                  </Button>
                </div>
              </Form>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};

// export default withRouter(Logins);
export default MainOrganizationUpdate;
