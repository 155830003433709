import axios from "axios";
import {
  GET_VIDEO_BANNER,
  GET_VIDEO_BANNER_DETAILS,
  POST_VIDEO_BANNER,
  PUT_STATUS_VIDEO_BANNER,
  PUT_VIDEO_BANNER_ORDER,
} from "..";

/**
 * get video banner
 * @param {Parameters} paramObj
 * @returns
 */
export const getVideoBanner = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_VIDEO_BANNER}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * enable-disable video banner
 * @param {body} body
 * @returns
 */
export const updateStatus = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_STATUS_VIDEO_BANNER}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * create video banner
 * @param {body} body
 * @returns
 */
export const postVideoBanner = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${POST_VIDEO_BANNER}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * update video banner
 * @param {body} body
 * @returns
 */
export const putUpdateVideoBanner = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${POST_VIDEO_BANNER}`,
    body,
    {
      headers: { Authorization: jwt_token },
      params: { videoBannerId: body.videoBannerId },
    }
  );
};

/**
 * delete video banner
 * @param {id} videoBannerId
 * @returns
 */
export const deleteVideoBanner = (videoBannerId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${POST_VIDEO_BANNER}`, {
    headers: { Authorization: jwt_token },
    params: { videoBannerId },
  });
};

/**
 * get video banner
 * @param {id} videoBannerId
 * @returns
 */
export const getVideoBannerDetails = (videoBannerId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${GET_VIDEO_BANNER_DETAILS}`,
    {
      headers: { Authorization: jwt_token },
      params: { videoBannerId },
    }
  );
};

/**
 * short order video banner
 * @param {body} updateOrder
 * @returns
 */
export const sort_video_banner = (updateOrder) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_VIDEO_BANNER_ORDER}`,
    { updateOrder: updateOrder },
    {
      headers: { Authorization: jwt_token },
    }
  );
};
