import { convertRGBA, rgbColorCreate } from "../../../../utils/helper";

export const setFooterOneStyleOne = (formData) => {
  return {
    ...formData.footer1Style,
    ".custom-footer .foot-other-info": {
      ...formData.footer1Style?.[".custom-footer .foot-other-info"],
      padding: `${formData.footer1Style?.[".custom-footer .foot-other-info"]?.[
        "p1"
      ]?.replace("px", "")}px 0`,
      "background-color": formData.footer1Style[
        ".custom-footer .foot-other-info"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              formData.footer1Style[".custom-footer .foot-other-info"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".custom-footer .foot-about": {
      ...formData.footer1Style?.[".custom-footer .foot-about"],
      "margin-bottom": `${formData.footer1Style?.[
        ".custom-footer .foot-about"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".custom-footer .foot-about .flogo": {
      ...formData.footer1Style?.[".custom-footer .foot-about .flogo"],
      "margin-bottom": `${formData.footer1Style?.[
        ".custom-footer .foot-about .flogo"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".custom-footer .foot-about p": {
      ...formData.footer1Style?.[".custom-footer .foot-about p"],
      "font-size": `${formData.footer1Style?.[".custom-footer .foot-about p"]?.[
        "font-size"
      ]?.replace("px", "")}px`,

      color: formData.footer1Style[".custom-footer .foot-about p"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              formData.footer1Style[".custom-footer .foot-about p"]?.["color"],
          })
        : "",
    },
    ".custom-footer .foot-menu": {
      ...formData.footer1Style?.[".custom-footer .foot-menu"],
      "margin-left": `${formData.footer1Style?.[".custom-footer .foot-menu"]?.[
        "margin-left"
      ]?.replace("px", "")}px`,
      "margin-bottom": `${formData.footer1Style?.[
        ".custom-footer .foot-menu"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".custom-footer .foot-title": {
      ...formData.footer1Style?.[".custom-footer .foot-title"],
      "font-size": `${formData.footer1Style?.[".custom-footer .foot-title"]?.[
        "font-size"
      ]?.replace("px", "")}px`,
      "margin-bottom": `${formData.footer1Style?.[
        ".custom-footer .foot-title"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
      color: formData.footer1Style[".custom-footer .foot-title"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              formData.footer1Style[".custom-footer .foot-title"]?.["color"],
          })
        : "",
    },
    ".custom-footer .foot-menulist li": {
      ...formData.footer1Style?.[".custom-footer .foot-menulist li"],
      margin: `${formData.footer1Style?.[".custom-footer .foot-menulist li"]?.[
        "m1"
      ]?.replace("px", "")}px ${formData.footer1Style?.[
        ".custom-footer .foot-menulist li"
      ]?.["margin"]
        .split(" ")[1]
        ?.replace("px", "")}px`,
    },
    ".custom-footer .foot-menulist li a": {
      ...formData.footer1Style?.[".custom-footer .foot-menulist li a"],
      "font-size": `${formData.footer1Style?.[
        ".custom-footer .foot-menulist li a"
      ]?.["font-size"]?.replace("px", "")}px`,
      color: formData.footer1Style[".custom-footer .foot-menulist li a"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              formData.footer1Style[".custom-footer .foot-menulist li a"]?.[
                "color"
              ],
          })
        : "",
    },
    ".custom-footer .foot-social-2 a": {
      ...formData.footer1Style?.[".custom-footer .foot-social-2 a"],
      margin: `${formData.footer1Style?.[".custom-footer .foot-social-2 a"]?.[
        "m1"
      ]?.replace("px", "")}px ${formData.footer1Style?.[
        ".custom-footer .foot-social-2 a"
      ]?.["m2"]?.replace("px", "")}px`,
      width: `${formData.footer1Style?.[".custom-footer .foot-social-2 a"]?.[
        "width"
      ]?.replace("px", "")}px`,
      height: `${formData.footer1Style?.[".custom-footer .foot-social-2 a"]?.[
        "height"
      ]?.replace("px", "")}px`,
      "border-radius": `${formData.footer1Style?.[
        ".custom-footer .foot-social-2 a"
      ]?.["border-radius"]?.replace("%", "")}%`,
      border: `${formData.footer1Style[".custom-footer .foot-social-2 a"]?.[
        "borderPx"
      ]?.replace("px", "")}px ${
        formData.footer1Style?.[".custom-footer .foot-social-2 a"]?.[
          "borderThik"
        ]
      } ${rgbColorCreate({
        rgbString:
          formData.footer1Style[".custom-footer .foot-social-2 a"]?.[
            "borderColor"
          ],
      })}`,
    },
    ".custom-footer .foot-social-2 a i": {
      ...formData.footer1Style?.[".custom-footer .foot-social-2 a i"],
      "font-size": `${formData.footer1Style?.[
        ".custom-footer .foot-social-2 a i"
      ]?.["font-size"]?.replace("px", "")}px`,
      "line-height": `${formData.footer1Style?.[
        ".custom-footer .foot-social-2 a i"
      ]?.["line-height"]?.replace("px", "")}px`,
      color: formData.footer1Style[".custom-footer .foot-social-2 a i"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              formData.footer1Style[".custom-footer .foot-social-2 a i"]?.[
                "color"
              ],
          })
        : "",
    },
    ".custom-footer .foot-contacts": {
      ...formData.footer1Style?.[".custom-footer .foot-contacts"],
      "margin-bottom": `${formData.footer1Style?.[
        ".custom-footer .foot-contacts"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".custom-footer .foot-contacts li": {
      ...formData.footer1Style?.[".custom-footer .foot-contacts li"],
      "padding-left": `${formData.footer1Style?.[
        ".custom-footer .foot-contacts li"
      ]?.["padding-left"]?.replace("px", "")}px`,
      margin: `${formData.footer1Style?.[".custom-footer .foot-contacts li"]?.[
        "m1"
      ]?.replace("px", "")}px ${formData.footer1Style?.[
        ".custom-footer .foot-contacts li"
      ]?.["margin"]
        .split(" ")[0]
        ?.replace("px", "")}px`,
    },
    ".custom-footer .foot-contacts li .fa": {
      ...formData.footer1Style?.[".custom-footer .foot-contacts li .fa"],
      color: formData.footer1Style[".custom-footer .foot-contacts li .fa"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              formData.footer1Style[".custom-footer .foot-contacts li .fa"]?.[
                "color"
              ],
          })
        : "",
    },
    ".custom-footer .foot-contacts li a": {
      ...formData.footer1Style?.[".custom-footer .foot-contacts li a"],
      color: formData.footer1Style[".custom-footer .foot-contacts li a"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              formData.footer1Style[".custom-footer .foot-contacts li a"]?.[
                "color"
              ],
          })
        : "",
    },
    ".custom-footer .foot-contacts li p": {
      ...formData.footer1Style?.[".custom-footer .foot-contacts li a"],
      color: formData.footer1Style[".custom-footer .foot-contacts li a"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              formData.footer1Style[".custom-footer .foot-contacts li a"]?.[
                "color"
              ],
          })
        : "",
    },
    theme: "1",
  };
};
export const setFooterOneStyleTwo = (formData) => {
  return {
    ...formData.footer1Style,
    "footer.style-one::before": {
      ...formData.footer1Style?.["footer.style-one::before"],
      height: `${formData.footer1Style?.["footer.style-one::before"]?.[
        "height"
      ]?.replace("px", "")}px`,
      "background-color": formData.footer1Style["footer.style-one::before"]?.[
        "background-color"
      ]
        ? rgbColorCreate({
            rgbString:
              formData.footer1Style["footer.style-one::before"]?.[
                "background-color"
              ],
          })
        : "",
      "border-radius": `${formData.footer1Style?.["footer.style-one::before"]?.[
        "br1"
      ]?.replace("%", "")}% ${formData.footer1Style?.[
        "footer.style-one::before"
      ]?.["br2"]?.replace("%", "")}% 0 0`,
    },
    "footer.style-one::after": {
      // height: "calc(100% - (125px + 200px))",
      "background-color": formData.footer1Style["footer.style-one::after"]?.[
        "background-color"
      ]
        ? rgbColorCreate({
            rgbString:
              formData.footer1Style["footer.style-one::after"]?.[
                "background-color"
              ],
          })
        : "",
    },
    "footer.style-one .top-footer": {
      "padding-bottom": `${formData.footer1Style?.[
        "footer.style-one .top-footer"
      ]?.["padding-bottom"]?.replace("px", "")}px`,
    },
    "footer.style-one .top-footer .logo": {
      ...formData.footer1Style?.["footer.style-one .top-footer .logo"],
      width: `${formData.footer1Style?.["footer.style-one .top-footer .logo"]?.[
        "width"
      ]?.replace("px", "")}px`,
      height: `${formData.footer1Style?.[
        "footer.style-one .top-footer .logo"
      ]?.["height"]?.replace("px", "")}px`,
      "background-color": formData.footer1Style[
        "footer.style-one .top-footer .logo"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              formData.footer1Style["footer.style-one .top-footer .logo"]?.[
                "background-color"
              ],
          })
        : "",
      "border-radius": `${formData.footer1Style?.[
        "footer.style-one .top-footer .logo"
      ]?.["border-radius"]?.replace("%", "")}%`,
    },
    "footer.style-one .top-footer .logo img": {
      ...formData.footer1Style?.["footer.style-one .top-footer .logo img"],
      "max-width": `${formData.footer1Style?.[
        "footer.style-one .top-footer .logo img"
      ]?.["max-width"]?.replace("px", "")}px`,
      "max-height": `${formData.footer1Style?.[
        "footer.style-one .top-footer .logo img"
      ]?.["max-height"]?.replace("px", "")}px`,
    },
    "footer.style-one .top-footer p": {
      ...formData.footer1Style?.["footer.style-one .top-footer p"],
      "font-size": `${formData.footer1Style?.[
        "footer.style-one .top-footer p"
      ]?.["font-size"]?.replace("px", "")}px`,
      color: formData.footer1Style["footer.style-one .top-footer p"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              formData.footer1Style["footer.style-one .top-footer p"]?.[
                "color"
              ],
          })
        : "",
    },
    "footer.style-one .top-footer .social-list": {
      ...formData.footer1Style?.["footer.style-one .top-footer .social-list"],
      "font-size": `${formData.footer1Style?.[
        "footer.style-one .top-footer .social-list"
      ]?.["font-size"]?.replace("px", "")}px`,
      color: formData.footer1Style[
        "footer.style-one .top-footer .social-list"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              formData.footer1Style[
                "footer.style-one .top-footer .social-list"
              ]?.["color"],
          })
        : "",
      padding: `${formData.footer1Style?.[
        "footer.style-one .top-footer .social-list"
      ]?.["p1"]?.replace("px", "")}px 0  ${formData.footer1Style?.[
        "footer.style-one .top-footer .social-list"
      ]?.["p2"]?.replace("px", "")}px`,
    },
    "footer.style-one .top-footer .social-list a": {
      ...formData.footer1Style?.["footer.style-one .top-footer .social-list a"],
      width: `${formData.footer1Style?.[
        "footer.style-one .top-footer .social-list a"
      ]?.["width"]?.replace("px", "")}px`,
      height: `${formData.footer1Style?.[
        "footer.style-one .top-footer .social-list a"
      ]?.["height"]?.replace("px", "")}px`,
      border: `${formData.footer1Style[
        "footer.style-one .top-footer .social-list a"
      ]?.["borderPx"]?.replace("px", "")}px ${
        formData.footer1Style?.[
          "footer.style-one .top-footer .social-list a"
        ]?.["borderThik"]
      } ${rgbColorCreate({
        rgbString:
          formData.footer1Style[
            "footer.style-one .top-footer .social-list a"
          ]?.["borderColor"],
      })}`,
      "border-radius": `${formData.footer1Style?.[
        "footer.style-one .top-footer .social-list a"
      ]?.["border-radius"]?.replace("%", "")}%`,
    },
    "footer.style-one .top-footer .social-list a i": {
      ...formData.footer1Style?.[
        "footer.style-one .top-footer .social-list a i"
      ],
      "font-size": `${formData.footer1Style?.[
        "footer.style-one .top-footer .social-list a i"
      ]?.["font-size"]?.replace("px", "")}px`,
      "line-height": `${formData.footer1Style?.[
        "footer.style-one .top-footer .social-list a i"
      ]?.["line-height"]?.replace("px", "")}px`,
      color: formData.footer1Style[
        "footer.style-one .top-footer .social-list a i"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              formData.footer1Style[
                "footer.style-one .top-footer .social-list a i"
              ]?.["color"],
          })
        : "",
    },
    "footer.style-one .top-footer .social-list a:first-child": {
      ...formData.footer1Style?.[
        "footer.style-one .top-footer .social-list a:first-child"
      ],
      "margin-right": `${formData.footer1Style?.[
        "footer.style-one .top-footer .social-list a:first-child"
      ]?.["margin-right"]?.replace("px", "")}px`,
    },
    "footer.style-one .top-footer .social-list a:nth-of-type(2)": {
      ...formData.footer1Style?.[
        "footer.style-one .top-footer .social-list a:nth-of-type(2)"
      ],
      "margin-left": `${formData.footer1Style?.[
        "footer.style-one .top-footer .social-list a:nth-of-type(2)"
      ]?.["margin-left"]?.replace("px", "")}px`,
    },
    "footer.style-one .bottom-footer": {
      ...formData.footer1Style?.["footer.style-one .bottom-footer"],
      "border-radius": `${formData.footer1Style?.[
        "footer.style-one .bottom-footer"
      ]?.["border-radius"]?.replace("px", "")}px`,
      "max-width": `${formData.footer1Style?.[
        "footer.style-one .bottom-footer"
      ]?.["max-width"]?.replace("px", "")}px`,
      border: `${formData.footer1Style["footer.style-one .bottom-footer"]?.[
        "borderPx"
      ]?.replace("px", "")}px ${
        formData.footer1Style?.["footer.style-one .bottom-footer"]?.[
          "borderThik"
        ]
      } ${rgbColorCreate({
        rgbString:
          formData.footer1Style["footer.style-one .bottom-footer"]?.[
            "borderColor"
          ],
      })}`,
    },
    "footer.style-one .bottom-footer .foot-icons": {
      ...formData.footer1Style?.["footer.style-one .bottom-footer .foot-icons"],
      "border-radius": `${formData.footer1Style?.[
        "footer.style-one .bottom-footer .foot-icons"
      ]?.["border-radius"]?.replace("%", "")}%`,
      border: `${formData.footer1Style[
        "footer.style-one .bottom-footer .foot-icons"
      ]?.["borderPx"]?.replace("px", "")}px ${
        formData.footer1Style?.[
          "footer.style-one .bottom-footer .foot-icons"
        ]?.["borderThik"]
      } ${rgbColorCreate({
        rgbString:
          formData.footer1Style[
            "footer.style-one .bottom-footer .foot-icons"
          ]?.["borderColor"],
      })}`,
      width: `${formData.footer1Style?.[
        "footer.style-one .bottom-footer .foot-icons"
      ]?.["width"]?.replace("px", "")}px`,
      height: `${formData.footer1Style?.[
        "footer.style-one .bottom-footer .foot-icons"
      ]?.["height"]?.replace("px", "")}px`,
    },
    "footer.style-one .bottom-footer .foot-icons i": {
      ...formData.footer1Style?.[
        "footer.style-one .bottom-footer .foot-icons i"
      ],
      "font-size": `${formData.footer1Style?.[
        "footer.style-one .bottom-footer .foot-icons i"
      ]?.["font-size"]?.replace("px", "")}px`,
      color: formData.footer1Style[
        "footer.style-one .bottom-footer .foot-icons i"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              formData.footer1Style[
                "footer.style-one .bottom-footer .foot-icons i"
              ]?.["color"],
          })
        : "",
    },
    "footer.style-one .bottom-footer table, footer.style-one .bottom-footer table tr td a":
      {
        ...formData.footer1Style?.[
          "footer.style-one .bottom-footer table, footer.style-one .bottom-footer table tr td a"
        ],
        "font-size": `${formData.footer1Style?.[
          "footer.style-one .bottom-footer table, footer.style-one .bottom-footer table tr td a"
        ]?.["font-size"]?.replace("px", "")}px`,
        "line-height": `${formData.footer1Style?.["footer.style-one .bottom-footer table, footer.style-one .bottom-footer table tr td a"]?.["line-height"]}`,
        "font-weight": `${formData.footer1Style?.["footer.style-one .bottom-footer table, footer.style-one .bottom-footer table tr td a"]?.["font-weight"]}`,
        color: formData.footer1Style[
          "footer.style-one .bottom-footer table, footer.style-one .bottom-footer table tr td a"
        ]?.["color"]
          ? rgbColorCreate({
              rgbString:
                formData.footer1Style[
                  "footer.style-one .bottom-footer table, footer.style-one .bottom-footer table tr td a"
                ]?.["color"],
            })
          : "",
      },
    "footer.style-one .bottom-footer table tr td": {
      ...formData.footer1Style?.["footer.style-one .bottom-footer table tr td"],
      width: `${formData.footer1Style?.[
        "footer.style-one .bottom-footer table tr td"
      ]?.["width"]?.replace("%", "")}%`,
      padding: `${formData.footer1Style?.[
        "footer.style-one .bottom-footer table tr td"
      ]?.["padding"]?.replace("px", "")}px`,
      "border-right": `${formData.footer1Style[
        "footer.style-one .bottom-footer table tr td"
      ]?.["borderPx"]?.replace("px", "")}px ${
        formData.footer1Style?.[
          "footer.style-one .bottom-footer table tr td"
        ]?.["borderThik"]
      } ${rgbColorCreate({
        rgbString:
          formData.footer1Style[
            "footer.style-one .bottom-footer table tr td"
          ]?.["borderColor"],
      })}`,
    },
    "footer.style-one .bottom-footer table tr td:last-child": {
      "line-height": `${formData.footer1Style?.["footer.style-one .bottom-footer table tr td:last-child"]?.["line-height"]}`,
    },
    "footer.style-one .bottom-footer table tr td img": {
      "max-width": `${formData.footer1Style?.[
        "footer.style-one .bottom-footer table tr td img"
      ]?.["max-width"]?.replace("px", "")}px`,
    },
    "footer.style-one .copyright p": {
      ...formData.footer1Style?.["footer.style-one .copyright p"],
      "font-weight": `${formData.footer1Style?.["footer.style-one .copyright p"]?.["font-weight"]}`,
      "font-size": `${formData.footer1Style?.[
        "footer.style-one .copyright p"
      ]?.["font-size"]?.replace("px", "")}px`,
      color: formData.footer1Style["footer.style-one .copyright p"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              formData.footer1Style["footer.style-one .copyright p"]?.["color"],
          })
        : "",
      padding: `${formData.footer1Style["footer.style-one .copyright p"][
        "padding"
      ]
        ?.split(" ")[0]
        ?.replace("px", "")}px 0`,
    },
    theme: "2",
    footerImage: formData?.footer1Style?.footerImage
      ? formData?.footer1Style?.footerImage
          ?.split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
          ?.pop()
      : "",
  };
};

export const footerOneStyleOneSet = (data) => {
  return {
    ...data,
    ".custom-footer .foot-other-info": {
      ...data?.[".custom-footer .foot-other-info"],
      "background-color": data?.[".custom-footer .foot-other-info"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              data?.[".custom-footer .foot-other-info"]?.["background-color"],
          })
        : "",
    },
    ".custom-footer .foot-about p": {
      ...data?.[".custom-footer .foot-about p"],
      color: data?.[".custom-footer .foot-about p"]?.["color"]
        ? convertRGBA({
            rgbString: data?.[".custom-footer .foot-about p"]?.["color"],
          })
        : "",
    },
    ".custom-footer .foot-title": {
      ...data?.[".custom-footer .foot-title"],
      color: data?.[".custom-footer .foot-title"]?.["color"]
        ? convertRGBA({
            rgbString: data?.[".custom-footer .foot-title"]?.["color"],
          })
        : "",
    },
    ".custom-footer .foot-menulist li a": {
      ...data?.[".custom-footer .foot-menulist li a"],
      color: data?.[".custom-footer .foot-menulist li a"]?.["color"]
        ? convertRGBA({
            rgbString: data?.[".custom-footer .foot-menulist li a"]?.["color"],
          })
        : "",
    },
    ".custom-footer .foot-contacts li .fa": {
      ...data?.[".custom-footer .foot-contacts li .fa"],
      color: data?.[".custom-footer .foot-contacts li .fa"]?.["color"]
        ? convertRGBA({
            rgbString:
              data?.[".custom-footer .foot-contacts li .fa"]?.["color"],
          })
        : "",
    },
    ".custom-footer .foot-contacts li a": {
      ...data?.[".custom-footer .foot-contacts li a"],
      color: data?.[".custom-footer .foot-contacts li a"]?.["color"]
        ? convertRGBA({
            rgbString: data?.[".custom-footer .foot-contacts li a"]?.["color"],
          })
        : "",
    },
    ".custom-footer .foot-social-2 a": {
      ...data?.[".custom-footer .foot-social-2 a"],
      borderColor: data?.[".custom-footer .foot-social-2 a"]?.["borderColor"]
        ? convertRGBA({
            rgbString:
              data?.[".custom-footer .foot-social-2 a"]?.["borderColor"],
          })
        : "",
    },
    ".custom-footer .foot-social-2 a i": {
      ...data?.[".custom-footer .foot-social-2 a i"],
      color: data?.[".custom-footer .foot-social-2 a i"]?.["color"]
        ? convertRGBA({
            rgbString: data?.[".custom-footer .foot-social-2 a i"]?.["color"],
          })
        : "",
    },
    custom: data.custom,
  };
};
export const footerOneStyleTwoSet = (data) => {
  return {
    ...data,
    "footer.style-one::before": {
      ...data?.["footer.style-one::before"],
      "background-color": data?.["footer.style-one::before"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString: data?.["footer.style-one::before"]?.["background-color"],
          })
        : "",
    },
    "footer.style-one::after": {
      ...data?.["footer.style-one::after"],
      "background-color": data?.["footer.style-one::after"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString: data?.["footer.style-one::after"]?.["background-color"],
          })
        : "",
    },
    "footer.style-one .top-footer .logo": {
      ...data?.["footer.style-one .top-footer .logo"],
      "background-color": data?.["footer.style-one .top-footer .logo"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              data?.["footer.style-one .top-footer .logo"]?.[
                "background-color"
              ],
          })
        : "",
    },
    "footer.style-one .top-footer p": {
      ...data?.["footer.style-one .top-footer p"],
      color: data?.["footer.style-one .top-footer p"]?.["color"]
        ? convertRGBA({
            rgbString: data?.["footer.style-one .top-footer p"]?.["color"],
          })
        : "",
    },
    "footer.style-one .top-footer .social-list": {
      ...data?.["footer.style-one .top-footer .social-list"],
      color: data?.["footer.style-one .top-footer .social-list"]?.["color"]
        ? convertRGBA({
            rgbString:
              data?.["footer.style-one .top-footer .social-list"]?.["color"],
          })
        : "",
    },
    "footer.style-one .top-footer .social-list a": {
      ...data?.["footer.style-one .top-footer .social-list a"],
      borderColor: data?.["footer.style-one .top-footer .social-list a"]?.[
        "borderColor"
      ]
        ? convertRGBA({
            rgbString:
              data?.["footer.style-one .top-footer .social-list a"]?.[
                "borderColor"
              ],
          })
        : "",
    },
    "footer.style-one .top-footer .social-list a i": {
      ...data?.["footer.style-one .top-footer .social-list a i"],
      color: data?.["footer.style-one .top-footer .social-list a i"]?.["color"]
        ? convertRGBA({
            rgbString:
              data?.["footer.style-one .top-footer .social-list a i"]?.[
                "color"
              ],
          })
        : "",
    },
    "footer.style-one .bottom-footer": {
      ...data?.["footer.style-one .bottom-footer"],
      borderColor: data?.["footer.style-one .bottom-footer"]?.["borderColor"]
        ? convertRGBA({
            rgbString:
              data?.["footer.style-one .bottom-footer"]?.["borderColor"],
          })
        : "",
    },
    "footer.style-one .bottom-footer .foot-icons": {
      ...data?.["footer.style-one .bottom-footer .foot-icons"],
      borderColor: data?.["footer.style-one .bottom-footer .foot-icons"]?.[
        "borderColor"
      ]
        ? convertRGBA({
            rgbString:
              data?.["footer.style-one .bottom-footer .foot-icons"]?.[
                "borderColor"
              ],
          })
        : "",
    },
    "footer.style-one .bottom-footer .foot-icons i": {
      ...data?.["footer.style-one .bottom-footer .foot-icons i"],
      color: data?.["footer.style-one .bottom-footer .foot-icons i"]?.["color"]
        ? convertRGBA({
            rgbString:
              data?.["footer.style-one .bottom-footer .foot-icons i"]?.[
                "color"
              ],
          })
        : "",
    },
    "footer.style-one .bottom-footer table, footer.style-one .bottom-footer table tr td a":
      {
        ...data?.[
          "footer.style-one .bottom-footer table, footer.style-one .bottom-footer table tr td a"
        ],
        color: data?.[
          "footer.style-one .bottom-footer table, footer.style-one .bottom-footer table tr td a"
        ]?.["color"]
          ? convertRGBA({
              rgbString:
                data?.[
                  "footer.style-one .bottom-footer table, footer.style-one .bottom-footer table tr td a"
                ]?.["color"],
            })
          : "",
      },
    "footer.style-one .copyright p": {
      ...data?.["footer.style-one .copyright p"],
      color: data?.["footer.style-one .copyright p"]?.["color"]
        ? convertRGBA({
            rgbString: data?.["footer.style-one .copyright p"]?.["color"],
          })
        : "",
    },
    "footer.style-one .bottom-footer table tr td": {
      ...data?.["footer.style-one .bottom-footer table tr td"],
      borderColor: data?.["footer.style-one .bottom-footer table tr td"]?.[
        "borderColor"
      ]
        ? convertRGBA({
            rgbString:
              data?.["footer.style-one .bottom-footer table tr td"]?.[
                "borderColor"
              ],
          })
        : "",
    },
    footerImage: data?.footerImage
      ? `${process.env.REACT_APP_BUCKET_URL}/${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/${data?.footerImage}`
      : "",
    custom: data.custom,
  };
};
