import React, { useState } from "react";
import PaymentContext from "./PaymentContext";
import { randomStr } from "../../../utils/helper";

const PaymentState = (props) => {
  const [apiData, setApiData] = useState({ data: [], isLoading: false });
  const [apiDataPayment, setApiDataPayment] = useState({
    isLoading: false,
    data: [],
  });
  const [skill, setSkill] = useState([
    { id: randomStr(2), skill: "", parentageOfSkill: "" },
  ]);
  const [fromData, setFormData] = useState({
    title: "",
    subTitle: "",
    image: "",
    webId: localStorage.getItem("webId"),
  });

  const [formDataPayment, setFormDataPayment] = useState({
    title: "",
    // description: "",
    image: "",
    webId: localStorage.getItem("webId"),
  });
  const [id, setId] = useState("");
  const [paymentItemId, setPaymentItemId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [filter, setFilter] = useState("");
  return (
    <PaymentContext.Provider
      value={{
        apiData,
        setApiData,
        apiDataPayment,
        setApiDataPayment,
        fromData,
        setFormData,
        formDataPayment,
        setFormDataPayment,
        id,
        setId,
        isEdit,
        setIsEdit,
        filter,
        setFilter,
        skill,
        paymentItemId,
        setPaymentItemId,
        setSkill,
      }}
    >
      {props.children}
    </PaymentContext.Provider>
  );
};

export default PaymentState;
