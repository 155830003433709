import React, { useContext } from "react";
import { Col, Input, Label, Row } from "reactstrap";
import FooterContext from "../../../context/FooterContext/FooterContext";
import { SketchPicker } from "react-color";
import CustomStyle from "../../../common/CustomStyle";
import { Border, fontWeight } from "../../../../constant";
import { Close } from "@material-ui/icons";
import { UploadCloud } from "react-feather";
import { OpenModalCommonFunc } from "../../../utils";
import { Cases, DELETE_CONFIRM } from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { delete_Multi_Image_Permanent } from "../apis/SliderApi/SliderApi";
import { Zoom, toast } from "react-toastify";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import { IconButton } from "@material-ui/core";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";

const FooterOneThemeTwo = (props) => {
  const { imageUpload } = props;
  const { setFormData, formData, setIsSectionImage } =
    useContext(FooterContext);
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * input value change
   * @param {event} e
   */

  const handleFooterOneBefore = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        footer1Style: {
          ...prev.footer1Style,
          "footer.style-one::before": {
            ...prev.footer1Style["footer.style-one::before"],
            [name]: value,
          },
        },
      };
    });
  };
  const FooterStyleTopFooter = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        footer1Style: {
          ...prev.footer1Style,
          "footer.style-one .top-footer": {
            ...prev.footer1Style["footer.style-one .top-footer"],
            [name]: value,
          },
        },
      };
    });
  };
  const FooterStyleTopFooterLogo = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        footer1Style: {
          ...prev.footer1Style,
          "footer.style-one .top-footer .logo": {
            ...prev.footer1Style["footer.style-one .top-footer .logo"],
            [name]: value,
          },
        },
      };
    });
  };
  const FooterStyleTopFooterLogoImg = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        footer1Style: {
          ...prev.footer1Style,
          "footer.style-one .top-footer .logo img": {
            ...prev.footer1Style["footer.style-one .top-footer .logo img"],
            [name]: value,
          },
        },
      };
    });
  };
  const FooterStyleTopFooterP = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        footer1Style: {
          ...prev.footer1Style,
          "footer.style-one .top-footer p": {
            ...prev.footer1Style["footer.style-one .top-footer p"],
            [name]: value,
          },
        },
      };
    });
  };
  const FooterStyleTopFooterSocialList = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        footer1Style: {
          ...prev.footer1Style,
          "footer.style-one .top-footer .social-list": {
            ...prev.footer1Style["footer.style-one .top-footer .social-list"],
            [name]: value,
          },
        },
      };
    });
  };
  const FooterStyleTopFooterSocialListA = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        footer1Style: {
          ...prev.footer1Style,
          "footer.style-one .top-footer .social-list a": {
            ...prev.footer1Style["footer.style-one .top-footer .social-list a"],
            [name]: value,
          },
        },
      };
    });
  };
  const FooterStyleTopFooterSocialListAI = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        footer1Style: {
          ...prev.footer1Style,
          "footer.style-one .top-footer .social-list a i": {
            ...prev.footer1Style[
              "footer.style-one .top-footer .social-list a i"
            ],
            [name]: value,
          },
        },
      };
    });
  };
  const FooterStyleTopFooterSocialListAFirstC = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        footer1Style: {
          ...prev.footer1Style,
          "footer.style-one .top-footer .social-list a:first-child": {
            ...prev.footer1Style[
              "footer.style-one .top-footer .social-list a:first-child"
            ],
            [name]: value,
          },
        },
      };
    });
  };
  const FooterStyleTopFooterSocialListNth = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        footer1Style: {
          ...prev.footer1Style,
          "footer.style-one .top-footer .social-list a:nth-of-type(2)": {
            ...prev.footer1Style[
              "footer.style-one .top-footer .social-list a:nth-of-type(2)"
            ],
            [name]: value,
          },
        },
      };
    });
  };
  const FooterStyleTopFooterBottom = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        footer1Style: {
          ...prev.footer1Style,
          "footer.style-one .bottom-footer": {
            ...prev.footer1Style["footer.style-one .bottom-footer"],
            [name]: value,
          },
        },
      };
    });
  };
  const FooterStyleTopFooterBottomIcons = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        footer1Style: {
          ...prev.footer1Style,
          "footer.style-one .bottom-footer .foot-icons": {
            ...prev.footer1Style["footer.style-one .bottom-footer .foot-icons"],
            [name]: value,
          },
        },
      };
    });
  };
  const FooterStyleTopFooterBottomIconI = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        footer1Style: {
          ...prev.footer1Style,
          "footer.style-one .bottom-footer .foot-icons i": {
            ...prev.footer1Style[
              "footer.style-one .bottom-footer .foot-icons i"
            ],
            [name]: value,
          },
        },
      };
    });
  };
  const FooterStyleTopFooterBottomTableTd = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        footer1Style: {
          ...prev.footer1Style,
          "footer.style-one .bottom-footer table tr td": {
            ...prev.footer1Style["footer.style-one .bottom-footer table tr td"],
            [name]: value,
          },
        },
      };
    });
  };
  const FooterStyleTopFooterBottomTableTdLastC = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        footer1Style: {
          ...prev.footer1Style,
          "footer.style-one .bottom-footer table tr td:last-child": {
            ...prev.footer1Style[
              "footer.style-one .bottom-footer table tr td:last-child"
            ],
            [name]: value,
          },
        },
      };
    });
  };
  const FooterStyleTopFooterBottomTableImg = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        footer1Style: {
          ...prev.footer1Style,
          "footer.style-one .bottom-footer table tr td img": {
            ...prev.footer1Style[
              "footer.style-one .bottom-footer table tr td img"
            ],
            [name]: value,
          },
        },
      };
    });
  };
  const FooterStyleTopFooterCopyP = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        footer1Style: {
          ...prev.footer1Style,
          "footer.style-one .copyright p": {
            ...prev.footer1Style["footer.style-one .copyright p"],
            [name]: value,
          },
        },
      };
    });
  };
  const FooterStyleTopFooterBottomTable = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        footer1Style: {
          ...prev.footer1Style,
          "footer.style-one .bottom-footer table": {
            ...prev.footer1Style["footer.style-one .bottom-footer table"],
            [name]: value,
          },
        },
      };
    });
  };
  const handleChangeCustomStyle = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        footer1Style: {
          ...prev.footer1Style,
          [name]: value,
        },
      };
    });
  };
  const HandleComplete = ({ event, classIS, className }) => {
    setFormData((prev) => {
      return {
        ...prev,
        footer1Style: {
          ...prev.footer1Style,
          [classIS]: {
            ...prev.footer1Style[classIS],
            [className]: event.rgb,
          },
        },
      };
    });
  };
  const HandleOnChange = ({ event, classIS, className }) => {
    setFormData((prev) => {
      return {
        ...prev,
        footer1Style: {
          ...prev.footer1Style,
          [classIS]: {
            ...prev.footer1Style[classIS],
            [className]: event.rgb,
          },
        },
      };
    });
  };
  /**
   * delete image permanently
   */
  const deleteImagePermanent = async () => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        console.log("formData?.footer1Style >> ", formData?.footer1Style);
        let myData = {
          fileName: formData?.footer1Style?.footerImage
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
            .pop(),
        };
        const { data } = await delete_Multi_Image_Permanent(myData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            position: "top-center",
          });
          setFormData((prev) => {
            return {
              ...prev,
              image: "",
              footer1Style: {
                ...prev.footer1Style,
                footerImage: "",
              },
            };
          });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };

  /**
   * reupload image
   */
  const reupload = () => {
    setFormData((prev) => {
      return {
        ...prev,
        footer1Style: {
          ...prev.footer1Style,
          footerImage: "",
        },
      };
    });
  };
  return (
    <>
      <div className="mt-4 d-flex flex-column justify-content-center ">
        <Row className="mt-4">
          <h4 className="mb-3">Make change in footer style-one::before</h4>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">{"Background Color "}</Label>
            <SketchPicker
              color={
                formData?.footer1Style?.["footer.style-one::before"]?.[
                  "background-color"
                ]
              }
              onChangeComplete={(e) =>
                HandleComplete({
                  event: e,
                  classIS: "footer.style-one::before",
                  className: "background-color",
                })
              }
              onChange={(e) =>
                HandleOnChange({
                  event: e,
                  classIS: "footer.style-one::before",
                  className: "background-color",
                })
              }
            />
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"height (Default : 200)"}
            </Label>
            <Input
              className="form-control"
              name="height"
              type="number"
              placeholder="height"
              value={formData.footer1Style?.["footer.style-one::before"]?.[
                "height"
              ]?.replace(/px/g, "")}
              onChange={(e) => handleFooterOneBefore(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Border-radius (Default : 100 - 100)"}
            </Label>
            <div className="d-flex justify-content-between">
              <Input
                className="form-control"
                name="br1"
                type="number"
                placeholder="Padding"
                value={formData.footer1Style?.["footer.style-one::before"]?.[
                  "br1"
                ]?.replace(/%/g, "")}
                onChange={(e) => handleFooterOneBefore(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
              <Input
                className="form-control"
                name="br2"
                type="number"
                placeholder="Padding"
                value={formData.footer1Style?.["footer.style-one::before"]?.[
                  "br2"
                ]?.replace(/%/g, "")}
                onChange={(e) => handleFooterOneBefore(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
          </Col>
        </Row>
        <hr />
        <Row className="mt-4">
          <h4 className="mb-3">Make change in footer.style-one::after</h4>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">{"Background Color "}</Label>
            <SketchPicker
              color={
                formData?.footer1Style?.["footer.style-one::after"]?.[
                  "background-color"
                ]
              }
              onChangeComplete={(e) =>
                HandleComplete({
                  event: e,
                  classIS: "footer.style-one::after",
                  className: "background-color",
                })
              }
              onChange={(e) =>
                HandleOnChange({
                  event: e,
                  classIS: "footer.style-one::after",
                  className: "background-color",
                })
              }
            />
          </Col>
        </Row>
        <hr />
        <Row className="mt-4">
          <h4 className="mb-3">Make change in footer top-footer padding</h4>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Padding-bottom (Default : 45)"}
            </Label>
            <div className="d-flex justify-content-between">
              <Input
                className="form-control"
                name="padding-bottom"
                type="number"
                placeholder="padding-bottom"
                value={formData.footer1Style?.[
                  "footer.style-one .top-footer"
                ]?.["padding-bottom"]?.replace(/px/g, "")}
                onChange={(e) => FooterStyleTopFooter(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
          </Col>
        </Row>
        <hr />
        <Row className="mt-4">
          <h4 className="mb-3">Make change in footer top-footer logo</h4>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Width (Default : 250)"}
            </Label>
            <div className="d-flex justify-content-between">
              <Input
                className="form-control"
                name="width"
                type="number"
                placeholder="width"
                value={formData.footer1Style?.[
                  "footer.style-one .top-footer .logo"
                ]?.["width"]?.replace(/px/g, "")}
                onChange={(e) => FooterStyleTopFooterLogo(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Height (Default : 250)"}
            </Label>
            <div className="d-flex justify-content-between">
              <Input
                className="form-control"
                name="height"
                type="number"
                placeholder="height"
                value={formData.footer1Style?.[
                  "footer.style-one .top-footer .logo"
                ]?.["height"]?.replace(/px/g, "")}
                onChange={(e) => FooterStyleTopFooterLogo(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Border-radius (Default : 100)"}
            </Label>
            <div className="d-flex justify-content-between">
              <Input
                className="form-control"
                name="border-radius"
                type="number"
                placeholder="border-radius"
                value={formData.footer1Style?.[
                  "footer.style-one .top-footer .logo"
                ]?.["border-radius"]?.replace(/%/g, "")}
                onChange={(e) => FooterStyleTopFooterLogo(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">{"Background Color "}</Label>
            <SketchPicker
              color={
                formData?.footer1Style?.[
                  "footer.style-one .top-footer .logo"
                ]?.["background-color"]
              }
              onChangeComplete={(e) =>
                HandleComplete({
                  event: e,
                  classIS: "footer.style-one .top-footer .logo",
                  className: "background-color",
                })
              }
              onChange={(e) =>
                HandleOnChange({
                  event: e,
                  classIS: "footer.style-one .top-footer .logo",
                  className: "background-color",
                })
              }
            />
          </Col>
        </Row>
        <hr />
        <Row className="mt-4">
          <h4 className="mb-3">Make change in footer top-footer logo image</h4>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Max-width (Default : 200)"}
            </Label>
            <div className="d-flex justify-content-between">
              <Input
                className="form-control"
                name="max-width"
                type="number"
                placeholder="max-width"
                value={formData.footer1Style?.[
                  "footer.style-one .top-footer .logo img"
                ]?.["max-width"]?.replace(/px/g, "")}
                onChange={(e) => FooterStyleTopFooterLogoImg(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"max-height (Default : 200)"}
            </Label>
            <div className="d-flex justify-content-between">
              <Input
                className="form-control"
                name="max-height"
                type="number"
                placeholder="max-height"
                value={formData.footer1Style?.[
                  "footer.style-one .top-footer .logo img"
                ]?.["max-height"]?.replace(/px/g, "")}
                onChange={(e) => FooterStyleTopFooterLogoImg(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
          </Col>
        </Row>
        <hr />
        <Row className="mt-4">
          <h4 className="mb-3">Make change in footer top-footer p</h4>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"font-size (Default : 18)"}
            </Label>
            <div className="d-flex justify-content-between">
              <Input
                className="form-control"
                name="font-size"
                type="number"
                placeholder="font-size"
                value={formData.footer1Style?.[
                  "footer.style-one .top-footer p"
                ]?.["font-size"]?.replace(/px/g, "")}
                onChange={(e) => FooterStyleTopFooterP(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">{"Color "}</Label>
            <SketchPicker
              color={
                formData?.footer1Style?.["footer.style-one .top-footer p"]?.[
                  "color"
                ]
              }
              onChangeComplete={(e) =>
                HandleComplete({
                  event: e,
                  classIS: "footer.style-one .top-footer p",
                  className: "color",
                })
              }
              onChange={(e) =>
                HandleOnChange({
                  event: e,
                  classIS: "footer.style-one .top-footer p",
                  className: "color",
                })
              }
            />
          </Col>
        </Row>
        <hr />
        <Row className="mt-4">
          <h4 className="mb-3">Make change in footer top-footer social-list</h4>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">{"Color"}</Label>
            <SketchPicker
              color={
                formData?.footer1Style?.[
                  "footer.style-one .top-footer .social-list"
                ]?.["color"]
              }
              onChangeComplete={(e) =>
                HandleComplete({
                  event: e,
                  classIS: "footer.style-one .top-footer .social-list",
                  className: "color",
                })
              }
              onChange={(e) =>
                HandleOnChange({
                  event: e,
                  classIS: "footer.style-one .top-footer .social-list",
                  className: "color",
                })
              }
            />
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Font Size (Default : 22)"}
            </Label>
            <Input
              className="form-control"
              name="font-size"
              type="number"
              placeholder="Font Size"
              value={formData.footer1Style?.[
                "footer.style-one .top-footer .social-list"
              ]?.["font-size"]?.replace(/px/g, "")}
              onChange={(e) => FooterStyleTopFooterSocialList(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"padding (Default : 20 - 50)"}
            </Label>
            <div className="d-flex justify-content-between">
              <Input
                className="form-control"
                name="p1"
                type="number"
                placeholder="Padding"
                value={formData.footer1Style?.[
                  "footer.style-one .top-footer .social-list"
                ]?.["p1"]?.replace(/px/g, "")}
                onChange={(e) => FooterStyleTopFooterSocialList(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
              <Input
                className="form-control"
                name="p2"
                type="number"
                placeholder="Padding"
                value={formData.footer1Style?.[
                  "footer.style-one .top-footer .social-list"
                ]?.["p2"]?.replace(/px/g, "")}
                onChange={(e) => FooterStyleTopFooterSocialList(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </div>
          </Col>
        </Row>
        <hr />
        <Row className="mt-4">
          <h4 className="mb-3">
            Make change in footer top-footer social-list a
          </h4>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">{"Width (Default : 42)"}</Label>
            <Input
              className="form-control"
              name="width"
              type="number"
              placeholder="width"
              value={formData.footer1Style?.[
                "footer.style-one .top-footer .social-list a"
              ]?.["width"]?.replace(/px/g, "")}
              onChange={(e) => FooterStyleTopFooterSocialListA(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"height (Default : 42)"}
            </Label>
            <Input
              className="form-control"
              name="height"
              type="number"
              placeholder="height"
              value={formData.footer1Style?.[
                "footer.style-one .top-footer .social-list a"
              ]?.["height"]?.replace(/px/g, "")}
              onChange={(e) => FooterStyleTopFooterSocialListA(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Border-radius (Default : 100)"}
            </Label>
            <Input
              className="form-control"
              name="border-radius"
              type="number"
              placeholder="border-radius"
              value={formData.footer1Style?.[
                "footer.style-one .top-footer .social-list a"
              ]?.["border-radius"]?.replace(/%/g, "")}
              onChange={(e) => FooterStyleTopFooterSocialListA(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">{"Border"}</Label>
            <div className="d-flex ">
              <div style={{ width: "100px" }}>
                <Input
                  className="form-control"
                  name="borderPx"
                  type="number"
                  placeholder="px"
                  value={formData.footer1Style?.[
                    "footer.style-one .top-footer .social-list a"
                  ]?.["borderPx"]?.replace(/px/g, "")}
                  onChange={(e) => FooterStyleTopFooterSocialListA(e)}
                  style={{ borderRadius: "0", color: "black" }}
                />
              </div>
              <div style={{ width: "100px" }}>
                <select
                  name="borderThik"
                  id="borderThik"
                  className="form-control"
                  value={
                    formData.footer1Style?.[
                      "footer.style-one .top-footer .social-list a"
                    ]?.["borderThik"]
                  }
                  onChange={(e) => FooterStyleTopFooterSocialListA(e)}
                  style={{ borderRadius: "0", color: "black" }}
                >
                  <option value="">--select--</option>
                  {Border.map((elem) => {
                    return <option value={elem.border}>{elem.border}</option>;
                  })}
                </select>
              </div>
              <div>
                <Label htmlFor="validationCustom03">
                  {"BorderColor (Default : white)"}
                </Label>
                <SketchPicker
                  color={
                    formData?.footer1Style?.[
                      "footer.style-one .top-footer .social-list a"
                    ]?.["borderColor"]
                  }
                  onChangeComplete={(e) =>
                    HandleComplete({
                      event: e,
                      classIS: "footer.style-one .top-footer .social-list a",
                      className: "borderColor",
                    })
                  }
                  onChange={(e) =>
                    HandleOnChange({
                      event: e,
                      classIS: "footer.style-one .top-footer .social-list a",
                      className: "borderColor",
                    })
                  }
                />
              </div>
            </div>
          </Col>
        </Row>
        <hr />
        <Row className="mt-4">
          <h4 className="mb-3">
            Make change in footer top-footer social-list a icon
          </h4>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">{"Color"}</Label>
            <SketchPicker
              color={
                formData?.footer1Style?.[
                  "footer.style-one .top-footer .social-list a i"
                ]?.["color"]
              }
              onChangeComplete={(e) =>
                HandleComplete({
                  event: e,
                  classIS: "footer.style-one .top-footer .social-list a i",
                  className: "color",
                })
              }
              onChange={(e) =>
                HandleOnChange({
                  event: e,
                  classIS: "footer.style-one .top-footer .social-list a i",
                  className: "color",
                })
              }
            />
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"font size (Default : 18)"}
            </Label>
            <Input
              className="form-control"
              name="font-size"
              type="number"
              placeholder="font size"
              value={formData.footer1Style?.[
                "footer.style-one .top-footer .social-list a i"
              ]?.["font-size"]?.replace(/px/g, "")}
              onChange={(e) => FooterStyleTopFooterSocialListAI(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Line-height (Default : 0)"}
            </Label>
            <Input
              className="form-control"
              name="line-height"
              type="number"
              placeholder="line-height"
              value={formData.footer1Style?.[
                "footer.style-one .top-footer .social-list a i"
              ]?.["line-height"]?.replace(/px/g, "")}
              onChange={(e) => FooterStyleTopFooterSocialListAI(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
        </Row>
        <hr />
        <Row className="mt-4">
          <h4 className="mb-3">
            Make change in footer top-footer social-list a:first-child
          </h4>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"margin-right (Default : 10)"}
            </Label>
            <Input
              className="form-control"
              name="margin-right"
              type="number"
              placeholder="margin-right"
              value={formData.footer1Style?.[
                "footer.style-one .top-footer .social-list a:first-child"
              ]?.["margin-right"]?.replace(/px/g, "")}
              onChange={(e) => FooterStyleTopFooterSocialListAFirstC(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
        </Row>
        <hr />
        <Row className="mt-4">
          <h4 className="mb-3">
            Make change in footer top-footer social-list a:nth-of-type(2)
          </h4>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Margin-left (Default : 10)"}
            </Label>
            <Input
              className="form-control"
              name="margin-left"
              type="number"
              placeholder="margin-left"
              value={formData.footer1Style?.[
                "footer.style-one .top-footer .social-list a:nth-of-type(2)"
              ]?.["margin-left"]?.replace(/px/g, "")}
              onChange={(e) => FooterStyleTopFooterSocialListNth(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
        </Row>
        <hr />
        <Row className="mt-4">
          <h4 className="mb-3">Make change in bottom-footer</h4>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">{"Border"}</Label>
            <div className="d-flex ">
              <div style={{ width: "100px" }}>
                <Input
                  className="form-control"
                  name="borderPx"
                  type="number"
                  placeholder="px"
                  value={formData.footer1Style?.[
                    "footer.style-one .bottom-footer"
                  ]?.["borderPx"]?.replace(/px/g, "")}
                  onChange={(e) => FooterStyleTopFooterBottom(e)}
                  style={{ borderRadius: "0", color: "black" }}
                />
              </div>
              <div style={{ width: "100px" }}>
                <select
                  name="borderThik"
                  id="borderThik"
                  className="form-control"
                  value={
                    formData.footer1Style?.[
                      "footer.style-one .bottom-footer"
                    ]?.["borderThik"]
                  }
                  onChange={(e) => FooterStyleTopFooterBottom(e)}
                  style={{ borderRadius: "0", color: "black" }}
                >
                  <option value="">--select--</option>
                  {Border.map((elem) => {
                    return <option value={elem.border}>{elem.border}</option>;
                  })}
                </select>
              </div>
              <div>
                <Label htmlFor="validationCustom03">
                  {"BorderColor (Default : white)"}
                </Label>
                <SketchPicker
                  color={
                    formData?.footer1Style?.[
                      "footer.style-one .bottom-footer"
                    ]?.["borderColor"]
                  }
                  onChangeComplete={(e) =>
                    HandleComplete({
                      event: e,
                      classIS: "footer.style-one .bottom-footer",
                      className: "borderColor",
                    })
                  }
                  onChange={(e) =>
                    HandleOnChange({
                      event: e,
                      classIS: "footer.style-one .bottom-footer",
                      className: "borderColor",
                    })
                  }
                />
              </div>
            </div>
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Border-radius (Default : 10)"}
            </Label>
            <Input
              className="form-control"
              name="border-radius"
              type="number"
              placeholder="border-radius"
              value={formData.footer1Style?.[
                "footer.style-one .bottom-footer"
              ]?.["border-radius"]?.replace(/px/g, "")}
              onChange={(e) => FooterStyleTopFooterBottom(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Max-width (Default : 1111)"}
            </Label>
            <Input
              className="form-control"
              name="max-width"
              type="number"
              placeholder="max-width"
              value={formData.footer1Style?.[
                "footer.style-one .bottom-footer"
              ]?.["max-width"]?.replace(/px/g, "")}
              onChange={(e) => FooterStyleTopFooterBottom(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
        </Row>
        <hr />
        <Row className="mt-4">
          <h4 className="mb-3">Make change in bottom-footer Icons</h4>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">{"Border"}</Label>
            <div className="d-flex ">
              <div style={{ width: "100px" }}>
                <Input
                  className="form-control"
                  name="borderPx"
                  type="number"
                  placeholder="px"
                  value={formData.footer1Style?.[
                    "footer.style-one .bottom-footer .foot-icons"
                  ]?.["borderPx"]?.replace(/px/g, "")}
                  onChange={(e) => FooterStyleTopFooterBottomIcons(e)}
                  style={{ borderRadius: "0", color: "black" }}
                />
              </div>
              <div style={{ width: "100px" }}>
                <select
                  name="borderThik"
                  id="borderThik"
                  className="form-control"
                  value={
                    formData.footer1Style?.[
                      "footer.style-one .bottom-footer .foot-icons"
                    ]?.["borderThik"]
                  }
                  onChange={(e) => FooterStyleTopFooterBottomIcons(e)}
                  style={{ borderRadius: "0", color: "black" }}
                >
                  <option value="">--select--</option>
                  {Border.map((elem) => {
                    return <option value={elem.border}>{elem.border}</option>;
                  })}
                </select>
              </div>
              <div>
                <SketchPicker
                  color={
                    formData?.footer1Style?.[
                      "footer.style-one .bottom-footer .foot-icons"
                    ]?.["borderColor"]
                  }
                  onChangeComplete={(e) =>
                    HandleComplete({
                      event: e,
                      classIS: "footer.style-one .bottom-footer .foot-icons",
                      className: "borderColor",
                    })
                  }
                  onChange={(e) =>
                    HandleOnChange({
                      event: e,
                      classIS: "footer.style-one .bottom-footer .foot-icons",
                      className: "borderColor",
                    })
                  }
                />
              </div>
            </div>
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Border-radius (Default : 10)"}
            </Label>
            <Input
              className="form-control"
              name="border-radius"
              type="number"
              placeholder="border-radius"
              value={formData.footer1Style?.[
                "footer.style-one .bottom-footer .foot-icons"
              ]?.["border-radius"]?.replace(/%/g, "")}
              onChange={(e) => FooterStyleTopFooterBottomIcons(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
        </Row>
        <hr />
        <Row className="mt-4">
          <h4 className="mb-3">Make change in bottom-footer Icons i</h4>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Color (Default : white)"}
            </Label>
            <SketchPicker
              color={
                formData?.footer1Style?.[
                  "footer.style-one .bottom-footer .foot-icons i"
                ]?.["color"]
              }
              onChangeComplete={(e) =>
                HandleComplete({
                  event: e,
                  classIS: "footer.style-one .bottom-footer .foot-icons i",
                  className: "color",
                })
              }
              onChange={(e) =>
                HandleOnChange({
                  event: e,
                  classIS: "footer.style-one .bottom-footer .foot-icons i",
                  className: "color",
                })
              }
            />
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"font size (Default : 16)"}
            </Label>
            <Input
              className="form-control"
              name="font-size"
              type="number"
              placeholder="font size"
              value={formData.footer1Style?.[
                "footer.style-one .bottom-footer .foot-icons i"
              ]?.["font-size"]?.replace(/px/g, "")}
              onChange={(e) => FooterStyleTopFooterBottomIconI(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
        </Row>
        <hr />
        <Row className="mt-4">
          <h4 className="mb-3">
            Make change in bottom-footer table, tr, td, a
          </h4>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Color (Default : white)"}
            </Label>
            <SketchPicker
              color={
                formData?.footer1Style?.[
                  "footer.style-one .bottom-footer table, footer.style-one .bottom-footer table tr td a"
                ]?.["color"]
              }
              onChangeComplete={(e) =>
                HandleComplete({
                  event: e,
                  classIS:
                    "footer.style-one .bottom-footer table, footer.style-one .bottom-footer table tr td a",
                  className: "color",
                })
              }
              onChange={(e) =>
                HandleOnChange({
                  event: e,
                  classIS:
                    "footer.style-one .bottom-footer table, footer.style-one .bottom-footer table tr td a",
                  className: "color",
                })
              }
            />
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"font size (Default : 16)"}
            </Label>
            <Input
              className="form-control"
              name="font-size"
              type="number"
              placeholder="font size"
              value={formData.footer1Style?.[
                "footer.style-one .bottom-footer table, footer.style-one .bottom-footer table tr td a"
              ]?.["font-size"]?.replace(/px/g, "")}
              onChange={(e) => FooterStyleTopFooterBottomTable(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Line-height (Default : 2)"}
            </Label>
            <Input
              className="form-control"
              name="line-height"
              type="number"
              placeholder="line-height"
              value={formData.footer1Style?.[
                "footer.style-one .bottom-footer table, footer.style-one .bottom-footer table tr td a"
              ]?.["line-height"]?.replace(/px/g, "")}
              onChange={(e) => FooterStyleTopFooterBottomTable(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Font Weight (Default : 500)"}
            </Label>
            <select
              name="font-weight"
              id="font-weight"
              className="form-select"
              value={
                formData.footer1Style?.[
                  "footer.style-one .bottom-footer table, footer.style-one .bottom-footer table tr td a"
                ]?.["font-weight"]
              }
              onChange={(e) => FooterStyleTopFooterBottomTable(e)}
              style={{ borderRadius: "0", color: "black" }}
            >
              <option value="">--select--</option>
              {fontWeight.map((elem) => {
                return (
                  <option value={elem["font-weight"]}>
                    {elem["font-weight"]}
                  </option>
                );
              })}
            </select>
          </Col>
        </Row>
        <hr />
        <Row className="mt-4">
          <h4 className="mb-3">Make change in bottom-footer table tr td</h4>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">{"Border"}</Label>
            <div className="d-flex ">
              <div style={{ width: "100px" }}>
                <Input
                  className="form-control"
                  name="borderPx"
                  type="number"
                  placeholder="px"
                  value={formData.footer1Style?.[
                    "footer.style-one .bottom-footer table tr td"
                  ]?.["borderPx"]?.replace(/px/g, "")}
                  onChange={(e) => FooterStyleTopFooterBottomTableTd(e)}
                  style={{ borderRadius: "0", color: "black" }}
                />
              </div>
              <div style={{ width: "100px" }}>
                <select
                  name="borderThik"
                  id="borderThik"
                  className="form-control"
                  value={
                    formData.footer1Style?.[
                      "footer.style-one .bottom-footer table tr td"
                    ]?.["borderThik"]
                  }
                  onChange={(e) => FooterStyleTopFooterBottomTableTd(e)}
                  style={{ borderRadius: "0", color: "black" }}
                >
                  <option value="">--select--</option>
                  {Border.map((elem) => {
                    return <option value={elem.border}>{elem.border}</option>;
                  })}
                </select>
              </div>
              <div>
                <Label htmlFor="validationCustom03">
                  {"BorderColor (Default : white)"}
                </Label>
                <SketchPicker
                  color={
                    formData?.footer1Style?.[
                      "footer.style-one .bottom-footer table tr td"
                    ]?.["borderColor"]
                  }
                  onChangeComplete={(e) =>
                    HandleComplete({
                      event: e,
                      classIS: "footer.style-one .bottom-footer table tr td",
                      className: "borderColor",
                    })
                  }
                  onChange={(e) =>
                    HandleOnChange({
                      event: e,
                      classIS: "footer.style-one .bottom-footer table tr td",
                      className: "borderColor",
                    })
                  }
                />
              </div>
            </div>
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">{"width (Default : 25)"}</Label>
            <Input
              className="form-control"
              name="width"
              type="number"
              placeholder="width"
              value={formData.footer1Style?.[
                "footer.style-one .bottom-footer table tr td"
              ]?.["width"]?.replace(/%/g, "")}
              onChange={(e) => FooterStyleTopFooterBottomTableTd(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"padding (Default : 10)"}
            </Label>
            <Input
              className="form-control"
              name="padding"
              type="number"
              placeholder="padding"
              value={formData.footer1Style?.[
                "footer.style-one .bottom-footer table tr td"
              ]?.["padding"]?.replace(/px/g, "")}
              onChange={(e) => FooterStyleTopFooterBottomTableTd(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
        </Row>
        <hr />
        <Row className="mt-4">
          <h4 className="mb-3">
            Make change in bottom-footer table tr td:last-child
          </h4>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Line-height (Default : 1)"}
            </Label>
            <Input
              className="form-control"
              name="line-height"
              type="number"
              placeholder="line-height"
              value={
                formData.footer1Style?.[
                  "footer.style-one .bottom-footer table tr td:last-child"
                ]?.["line-height"]
              }
              onChange={(e) => FooterStyleTopFooterBottomTableTdLastC(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
        </Row>
        <hr />
        <Row className="mt-4">
          <h4 className="mb-3">Make change in bottom-footer table tr td img</h4>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"max-width (Default : 200)"}
            </Label>
            <Input
              className="form-control"
              name="max-width"
              type="number"
              placeholder="max-width"
              value={formData.footer1Style?.[
                "footer.style-one .bottom-footer table tr td img"
              ]?.["max-width"]?.replace(/px/g, "")}
              onChange={(e) => FooterStyleTopFooterBottomTableImg(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
        </Row>
        <hr />
        <Row className="mt-4">
          <h4 className="mb-3">Make change in copyright p</h4>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"font-size (Default : 18)"}
            </Label>
            <Input
              className="form-control"
              name="font-size"
              type="number"
              placeholder="font-size"
              value={formData.footer1Style?.["footer.style-one .copyright p"]?.[
                "font-size"
              ]?.replace(/px/g, "")}
              onChange={(e) => FooterStyleTopFooterCopyP(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Font Weight (Default : 500)"}
            </Label>
            <select
              name="font-weight"
              id="font-weight"
              className="form-select"
              value={
                formData.footer1Style?.["footer.style-one .copyright p"]?.[
                  "font-weight"
                ]
              }
              onChange={(e) => FooterStyleTopFooterCopyP(e)}
              style={{ borderRadius: "0", color: "black" }}
            >
              <option value="">--select--</option>
              {fontWeight.map((elem) => {
                return (
                  <option value={elem["font-weight"]}>
                    {elem["font-weight"]}
                  </option>
                );
              })}
            </select>
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Padding (Default : 40)"}
            </Label>
            <Input
              className="form-control"
              name="padding"
              type="number"
              placeholder="Padding"
              value={formData.footer1Style?.["footer.style-one .copyright p"]?.[
                "padding"
              ]
                ?.split(" ")[0]
                ?.replace(/px/g, "")}
              onChange={(e) => FooterStyleTopFooterCopyP(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </Col>
          <Col md="4 mb-3">
            <Label htmlFor="validationCustom03">
              {"Color (Default : white)"}
            </Label>
            <SketchPicker
              color={
                formData?.footer1Style?.["footer.style-one .copyright p"]?.[
                  "color"
                ]
              }
              onChangeComplete={(e) =>
                HandleComplete({
                  event: e,
                  classIS: "footer.style-one .copyright p",
                  className: "color",
                })
              }
              onChange={(e) =>
                HandleOnChange({
                  event: e,
                  classIS: "footer.style-one .copyright p",
                  className: "color",
                })
              }
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md="4 mb-3">
            <label htmlFor="">Footer Image</label>
            <div
              className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
              style={{ cursor: "pointer" }}
            >
              {!formData?.footer1Style?.footerImage && (
                <span
                  onClick={() => {
                    OpenModalCommonFunc(Cases.PRODUCT_UPLOAD, setAllPopupState);
                    setIsSectionImage("footerImage");
                  }}
                >
                  <UploadCloud />
                </span>
              )}

              {formData?.footer1Style?.footerImage && (
                <img
                  src={
                    `${process.env.REACT_APP_BUCKET_URL}/${process.env.REACT_APP_BUCKET_PRO_FOLDER}/${formData?.footer1Style?.footerImage}` ||
                    ""
                  }
                  alt=""
                  style={{
                    width: "85%",
                    height: "90px",
                    objectFit: "contain",
                    position: "absolute",
                  }}
                  onClick={() => deleteImagePermanent()}
                />
              )}
              {formData?.footer1Style?.footerImage && (
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="close"
                  style={{
                    color: "red",
                    position: "absolute",
                    top: "-22px",
                    left: "1px",
                  }}
                  onClick={() => deleteImagePermanent()}
                >
                  <Close
                    style={{
                      fontSize: "25px",
                      fontWeight: "bold",
                    }}
                  />
                </IconButton>
              )}
            </div>
          </Col>
        </Row>
        <hr />
        <CustomStyle
          handleChange={handleChangeCustomStyle}
          value={formData?.footer1Style?.custom}
        />
      </div>
      <ProductImageUploadPopup
        allPopupState={allPopupState}
        setAllPopupState={setAllPopupState}
        crop={crop}
        setCrop={setCrop}
        completedCrop={completedCrop}
        setCompletedCrop={setCompletedCrop}
        scale={scale}
        rotate={rotate}
        image={formData?.image}
        setImage={setFormData}
        imageUpload={imageUpload}
        // isEdit={isEdit}
        reupload={reupload}
        // aspect={1 / 1}
      />
    </>
  );
};

export default FooterOneThemeTwo;
