import React, { useContext } from "react";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import ProductBuyContext from "../../../context/ProductBuyContext/ProductBuyContext";
import { Border, fontWeight } from "../../../../constant";
import CommonColor from "../../../common/CommonColor";
import CustomStyle from "../../../common/CustomStyle";

const SettingProductPage = (props) => {
  const { handleSubmitSetting } = props;
  const { isEdit, settingFormData, setSettingFormData } =
    useContext(ProductBuyContext);

  const handleChangeSingleProduct = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".single-product-section"]: {
          ...prev[".single-product-section"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeproductSubBoxDiv = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".single-product-section .subbox-div"]: {
          ...prev[".single-product-section .subbox-div"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeProductSubBoxTagLable = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".single-product-section .subbox-div .taglabel"]: {
          ...prev[".single-product-section .subbox-div .taglabel"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeSingleProductPreThumb = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".single-product-section .preview-thumbnail"]: {
          ...prev[".single-product-section .preview-thumbnail"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeOrderSectionTebFormCon = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".single-product-section .preview-thumbnail li .active"]: {
          ...prev[".single-product-section .preview-thumbnail li .active"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeSingleProTitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".single-product-section .single-proinfo .product-title"]: {
          ...prev[".single-product-section .single-proinfo .product-title"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeSingleProDescription = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".single-product-section .single-proinfo .product-description"]: {
          ...prev[
            ".single-product-section .single-proinfo .product-description"
          ],
          [name]: value,
        },
      };
    });
  };
  const handleChangeSingleProPrice = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".single-product-section .single-proinfo .price"]: {
          ...prev[".single-product-section .single-proinfo .price"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeSingleProIntoTabContent = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".single-product-section .product-info-tab .tab-content"]: {
          ...prev[".single-product-section .product-info-tab .tab-content"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeSingleProPriceAddCart = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".single-product-section .single-proinfo .add-to-cart"]: {
          ...prev[".single-product-section .single-proinfo .add-to-cart"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeSingleProPriceAddInquiry = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".single-product-section .single-proinfo .add-inquiry"]: {
          ...prev[".single-product-section .single-proinfo .add-inquiry"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeCustomStyle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const resetHandler = () => {
    setSettingFormData({
      ".single-product-section": {
        padding: "50px 0",
        "background-color": { r: 255, g: 255, b: 255, a: 100 },
      },
      ".single-product-section .subbox-div": {
        "background-color": { r: 249, g: 249, b: 249, a: 100 },
        padding: "2em",
      },
      ".single-product-section .subbox-div .taglabel": {
        "background-color": { r: 255, g: 73, b: 124, a: 100 },
        color: { r: 255, g: 255, b: 255, a: 100 },
        "font-size": "14px",
        "font-weight": "400",
        padding: "6px 20px",
        p1: "6px",
        p2: "20px",
        "border-radius": "50px",
      },
      ".single-product-section .preview-thumbnail": {
        margin: "10px 0",
      },
      ".single-product-section .preview-thumbnail li .active": {
        border: "2px solid { r: 255, g: 73, b: 124, a: 100 }",
        borderPx: "2px",
        borderThik: "solid",
        borderColor: { r: 255, g: 73, b: 124, a: 100 },
      },
      ".single-product-section .single-proinfo .product-title": {
        "margin-bottom": "10px",
      },
      ".single-product-section .single-proinfo .product-description": {
        "margin-bottom": "20px",
      },
      ".single-product-section .single-proinfo .price": {
        color: { r: 51, g: 51, b: 51, a: 100 },
        "margin-bottom": "20px",
      },
      ".single-product-section .single-proinfo .add-to-cart": {
        border: "2px solid { r: 255, g: 73, b: 124, a: 100 }",
        borderPx: "2px",
        borderThik: "solid",
        borderColor: { r: 255, g: 73, b: 124, a: 100 },
        "background-color": { r: 255, g: 73, b: 124, a: 100 },
        color: { r: 255, g: 255, b: 255, a: 100 },
        padding: "4px 15px",
        p1: "4px",
        p2: "15px",
      },
      ".single-product-section .single-proinfo .add-inquiry": {
        border: "2px solid { r: 255, g: 73, b: 124, a: 100 }",
        borderPx: "2px",
        borderThik: "solid",
        borderColor: { r: 255, g: 73, b: 124, a: 100 },
        "background-color": { r: 255, g: 255, b: 255, a: 100 },
        color: { r: 255, g: 73, b: 124, a: 100 },
        padding: "4px 15px",
        p1: "4px",
        p2: "15px",
      },
      ".single-product-section .product-info-tab .nav-tabs .active": {
        "background-color": { r: 245, g: 245, b: 245, a: 100 },
        "border-color": { r: 222, g: 226, b: 230, a: 100 },
        color: { r: 73, g: 80, b: 87, a: 100 },
      },
      ".single-product-section .product-info-tab .tab-content": {
        "background-color": { r: 245, g: 245, b: 245, a: 100 },
        padding: "15px",
        color: { r: 73, g: 80, b: 87, a: 100 },
      },
    });
  };

  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="12">
            <div className="mt-4 d-flex flex-column justify-content-center ">
              <div className="d-flex justify-content-between">
                <h6>{isEdit ? "Edit" : "Add New"}</h6>
                <div className="d-flex gap-2">
                  <Button onClick={resetHandler}>Reset</Button>
                  <Button onClick={() => handleSubmitSetting(true)}>
                    Restore
                  </Button>
                  <Button
                    onClick={() => handleSubmitSetting(false, false)}
                    color="success"
                  >
                    Save & View
                  </Button>
                </div>
              </div>
              <Row className="mt-4">
                <h4 className="mb-3">.single-product-section</h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding (Default : 50)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="padding"
                    type="number"
                    placeholder="Padding"
                    value={settingFormData?.[".single-product-section"]?.[
                      "padding"
                    ]
                      ?.split(" ")[0]
                      ?.replace(/px/g, "")}
                    onChange={(e) => handleChangeSingleProduct(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[".single-product-section"]?.[
                        "background-color"
                      ]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".single-product-section"}
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">.single-product-section .subbox-div</h4>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".single-product-section .subbox-div"
                      ]?.["background-color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".single-product-section .subbox-div"}
                    classProperty={"background-color"}
                    label={"Select Background color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"padding (Default : 2)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="padding"
                    type="number"
                    placeholder="padding"
                    value={settingFormData?.[
                      ".single-product-section .subbox-div"
                    ]?.["padding"]?.replace(/em/g, "")}
                    onChange={(e) => handleChangeproductSubBoxDiv(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">
                  .single-product-section .subbox-div .taglabel
                </h4>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".single-product-section .subbox-div .taglabel"
                      ]?.["color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".single-product-section .subbox-div .taglabel"}
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".single-product-section .subbox-div .taglabel"
                      ]?.["background-color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".single-product-section .subbox-div .taglabel"}
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Size (Default : 14)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="font-size"
                    type="number"
                    placeholder="font-size"
                    value={settingFormData?.[
                      ".single-product-section .subbox-div .taglabel"
                    ]?.["font-size"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeProductSubBoxTagLable(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Weight (Default : 400)"}
                  </Label>
                  <select
                    name="font-weight"
                    id="font-weight"
                    className="form-select"
                    value={
                      settingFormData?.[
                        ".single-product-section .subbox-div .taglabel"
                      ]?.["font-weight"]
                    }
                    onChange={(e) => handleChangeProductSubBoxTagLable(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {fontWeight.map((elem) => {
                      return (
                        <option value={elem["font-weight"]}>
                          {elem["font-weight"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding (Default : 6 - 20)"}
                  </Label>
                  <div className="d-flex justify-content-between">
                    <Input
                      className="form-control"
                      name="p1"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        ".single-product-section .subbox-div .taglabel"
                      ]?.["p1"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangeProductSubBoxTagLable(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                    <Input
                      className="form-control"
                      name="p2"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        ".single-product-section .subbox-div .taglabel"
                      ]?.["p2"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangeProductSubBoxTagLable(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                  </div>
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border Radius (Default : 50)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="border-radius"
                    type="number"
                    placeholder="Border Radius"
                    value={settingFormData?.[
                      ".single-product-section .subbox-div .taglabel"
                    ]?.["border-radius"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeProductSubBoxTagLable(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">
                  .single-product-section .preview-thumbnail
                </h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"margin (Default : 10)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="margin"
                    type="number"
                    placeholder="margin"
                    value={settingFormData?.[
                      ".single-product-section .preview-thumbnail"
                    ]?.["margin"]
                      ?.split(" ")[0]
                      ?.replace(/px/g, "")}
                    onChange={(e) => handleChangeSingleProductPreThumb(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">
                  .single-product-section .preview-thumbnail li .active
                </h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border (Default : 2 solid pink)"}
                  </Label>
                  <div className="d-flex ">
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="borderPx"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[
                          ".single-product-section .preview-thumbnail li .active"
                        ]?.["borderPx"]?.replace(/px/g, "")}
                        onChange={(e) => handleChangeOrderSectionTebFormCon(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <select
                        name="borderThik"
                        id="borderThik"
                        className="form-control"
                        value={
                          settingFormData?.[
                            ".single-product-section .preview-thumbnail li .active"
                          ]?.["borderThik"]
                        }
                        onChange={(e) => handleChangeOrderSectionTebFormCon(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      >
                        <option value="">--select--</option>
                        {Border.map((elem) => {
                          return (
                            <option value={elem.border}>{elem.border}</option>
                          );
                        })}
                      </select>
                    </div>
                    <div>
                      <CommonColor
                        color={
                          settingFormData?.[
                            ".single-product-section .preview-thumbnail li .active"
                          ]?.["borderColor"]
                        }
                        setSettingFormData={setSettingFormData}
                        className={
                          ".single-product-section .preview-thumbnail li .active"
                        }
                        classProperty={"borderColor"}
                        label={"Select Color"}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">
                  .single-product-section .single-proinfo .product-title
                </h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Margin Bottom (Default : 10)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="margin-bottom"
                    type="number"
                    placeholder="margin-bottom"
                    value={settingFormData?.[
                      ".single-product-section .single-proinfo .product-title"
                    ]?.["margin-bottom"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeSingleProTitle(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">
                  .single-product-section .single-proinfo .product-description
                </h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Margin Bottom (Default : 20)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="margin-bottom"
                    type="number"
                    placeholder="margin-bottom"
                    value={settingFormData?.[
                      ".single-product-section .single-proinfo .product-description"
                    ]?.["margin-bottom"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeSingleProDescription(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">
                  .single-product-section .single-proinfo .price
                </h4>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".single-product-section .single-proinfo .price"
                      ]?.["color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".single-product-section .single-proinfo .price"}
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Margin Bottom (Default : 20)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="margin-bottom"
                    type="number"
                    placeholder="margin-bottom"
                    value={settingFormData?.[
                      ".single-product-section .single-proinfo .price"
                    ]?.["margin-bottom"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeSingleProPrice(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">
                  .single-product-section .single-proinfo .add-to-cart
                </h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border (Default : 2 solid pink)"}
                  </Label>
                  <div className="d-flex ">
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="borderPx"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[
                          ".single-product-section .single-proinfo .add-to-cart"
                        ]?.["borderPx"]?.replace(/px/g, "")}
                        onChange={(e) => handleChangeSingleProPriceAddCart(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <select
                        name="borderThik"
                        id="borderThik"
                        className="form-control"
                        value={
                          settingFormData?.[
                            ".single-product-section .single-proinfo .add-to-cart"
                          ]?.["borderThik"]
                        }
                        onChange={(e) => handleChangeSingleProPriceAddCart(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      >
                        <option value="">--select--</option>
                        {Border.map((elem) => {
                          return (
                            <option value={elem.border}>{elem.border}</option>
                          );
                        })}
                      </select>
                    </div>
                    <div>
                      <CommonColor
                        color={
                          settingFormData?.[
                            ".single-product-section .single-proinfo .add-to-cart"
                          ]?.["borderColor"]
                        }
                        setSettingFormData={setSettingFormData}
                        className={
                          ".single-product-section .single-proinfo .add-to-cart"
                        }
                        classProperty={"borderColor"}
                        label={"Select Color"}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".single-product-section .single-proinfo .add-to-cart"
                      ]?.["background-color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={
                      ".single-product-section .single-proinfo .add-to-cart"
                    }
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".single-product-section .single-proinfo .add-to-cart"
                      ]?.["color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={
                      ".single-product-section .single-proinfo .add-to-cart"
                    }
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding (Default : 4 - 15)"}
                  </Label>
                  <div className="d-flex justify-content-between">
                    <Input
                      className="form-control"
                      name="p1"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        ".single-product-section .single-proinfo .add-to-cart"
                      ]?.["p1"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangeSingleProPriceAddCart(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                    <Input
                      className="form-control"
                      name="p2"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        ".single-product-section .single-proinfo .add-to-cart"
                      ]?.["p2"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangeSingleProPriceAddCart(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                  </div>
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">
                  .single-product-section .single-proinfo .add-inquiry
                </h4>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Border (Default : 2 solid pink)"}
                  </Label>
                  <div className="d-flex ">
                    <div style={{ width: "100px" }}>
                      <Input
                        className="form-control"
                        name="borderPx"
                        type="number"
                        placeholder="px"
                        value={settingFormData?.[
                          ".single-product-section .single-proinfo .add-inquiry"
                        ]?.["borderPx"]?.replace(/px/g, "")}
                        onChange={(e) =>
                          handleChangeSingleProPriceAddInquiry(e)
                        }
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </div>
                    <div style={{ width: "100px" }}>
                      <select
                        name="borderThik"
                        id="borderThik"
                        className="form-control"
                        value={
                          settingFormData?.[
                            ".single-product-section .single-proinfo .add-inquiry"
                          ]?.["borderThik"]
                        }
                        onChange={(e) =>
                          handleChangeSingleProPriceAddInquiry(e)
                        }
                        style={{ borderRadius: "0", color: "black" }}
                      >
                        <option value="">--select--</option>
                        {Border.map((elem) => {
                          return (
                            <option value={elem.border}>{elem.border}</option>
                          );
                        })}
                      </select>
                    </div>
                    <div>
                      <CommonColor
                        color={
                          settingFormData?.[
                            ".single-product-section .single-proinfo .add-inquiry"
                          ]?.["borderColor"]
                        }
                        setSettingFormData={setSettingFormData}
                        className={
                          ".single-product-section .single-proinfo .add-inquiry"
                        }
                        classProperty={"borderColor"}
                        label={"Select Color"}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".single-product-section .single-proinfo .add-inquiry"
                      ]?.["background-color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={
                      ".single-product-section .single-proinfo .add-inquiry"
                    }
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".single-product-section .single-proinfo .add-inquiry"
                      ]?.["color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={
                      ".single-product-section .single-proinfo .add-inquiry"
                    }
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding (Default : 4 - 15)"}
                  </Label>
                  <div className="d-flex justify-content-between">
                    <Input
                      className="form-control"
                      name="p1"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        ".single-product-section .single-proinfo .add-inquiry"
                      ]?.["p1"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangeSingleProPriceAddInquiry(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                    <Input
                      className="form-control"
                      name="p2"
                      type="number"
                      placeholder="Padding"
                      value={settingFormData?.[
                        ".single-product-section .single-proinfo .add-inquiry"
                      ]?.["p2"]?.replace(/px/g, "")}
                      onChange={(e) => handleChangeSingleProPriceAddInquiry(e)}
                      style={{ borderRadius: "0", color: "black" }}
                    />
                  </div>
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">
                  .single-product-section .product-info-tab .nav-tabs .active
                </h4>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".single-product-section .product-info-tab .nav-tabs .active"
                      ]?.["background-color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={
                      ".single-product-section .product-info-tab .nav-tabs .active"
                    }
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".single-product-section .product-info-tab .nav-tabs .active"
                      ]?.["color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={
                      ".single-product-section .product-info-tab .nav-tabs .active"
                    }
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".single-product-section .product-info-tab .nav-tabs .active"
                      ]?.["border-color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={
                      ".single-product-section .product-info-tab .nav-tabs .active"
                    }
                    classProperty={"border-color"}
                    label={"Select Border Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Margin Bottom (Default : 20)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="margin-bottom"
                    type="number"
                    placeholder="margin-bottom"
                    value={settingFormData?.[
                      ".single-product-section .single-proinfo .price"
                    ]?.["margin-bottom"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeSingleProPrice(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">
                  .single-product-section .product-info-tab .tab-content
                </h4>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".single-product-section .product-info-tab .tab-content"
                      ]?.["background-color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={
                      ".single-product-section .product-info-tab .tab-content"
                    }
                    classProperty={"background-color"}
                    label={"Select Background Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".single-product-section .product-info-tab .tab-content"
                      ]?.["color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={
                      ".single-product-section .product-info-tab .tab-content"
                    }
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"padding (Default : 15)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="padding"
                    type="number"
                    placeholder="padding"
                    value={settingFormData?.[
                      ".single-product-section .product-info-tab .tab-content"
                    ]?.["padding"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeSingleProIntoTabContent(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <CustomStyle
                handleChange={handleChangeCustomStyle}
                value={settingFormData?.custom}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SettingProductPage;
