import axios from "axios";
import {
  GET_FOOTER,
  GET_FOOTER_DETAILS,
  POST_FOOTER,
  PUT_FOOTER_ORDER,
  PUT_STATUS_FOOTER,
} from "..";

/**
 * get product data
 * @param {Parameters} paramObj
 * @returns
 */
export const getFooter = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_FOOTER}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * enable-disable
 * @param {body} body
 * @returns
 */
export const updateStatusFooter = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_STATUS_FOOTER}`,
    body,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

/**
 * create product
 * @param {body} body
 * @returns
 */
export const postFooter = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(`${process.env.REACT_APP_API_URL}${POST_FOOTER}`, body, {
    headers: { Authorization: jwt_token },
  });
};

/**
 * update product
 * @param {body} body
 * @returns
 */
export const putUpdateFooter = (body) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(`${process.env.REACT_APP_API_URL}${POST_FOOTER}`, body, {
    headers: { Authorization: jwt_token },
    params: { footerId: body.footerId },
  });
};

/**
 * delete product
 * @param {id} footerId
 * @returns
 */
export const deleteFooter = (footerId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${POST_FOOTER}`, {
    headers: { Authorization: jwt_token },
    params: { footerId },
  });
};

/**
 * get product details
 * @param {id} footerId
 * @returns
 */
export const getFooterDetails = (footerId, navMapId) => {
  const jwt_token = localStorage.getItem("token");
  let paramObj = {
    footerId,
  };
  if (navMapId) {
    paramObj["navMapId"] = navMapId;
  }
  return axios.get(`${process.env.REACT_APP_API_URL}${GET_FOOTER_DETAILS}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * sort order products
 * @param {body} updateOrder
 * @returns
 */
export const sort_order_Footer = (updateOrder) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PUT_FOOTER_ORDER}`,
    { updateOrder: updateOrder },
    {
      headers: { Authorization: jwt_token },
    }
  );
};
