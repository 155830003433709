import React, { Fragment, useContext, useEffect } from "react";
import LoaderContext from "../../../context/LoaderContext/LoaderContext";
import { TostMessage } from "../../../components ByteCard/utils";
import { DELETE_CONFIRM } from "../../../components ByteCard/constant";
import { SORTING_ORDER } from "../../../constant";
import TopLoadingBar from "../../../utils/topLoadingBar";
import Breadcrumbs from "../../../layout/breadcrumb";
import WebCategoryMapItem from "./WebCategoryMapItem";
import {
  Delete_WebCategoryMap_List,
  WebCategoryMap_List,
} from "../../../api/WebCategoryMapApi/WebCategoryMapApi";
import WebCategoryMapContext from "../../../context/WebCategoryMapContext/WebCategoryMapContext";
import { useParams } from "react-router";

const WebCategoryMap = () => {
  let { pageName } = useParams();
  const {
    setFormValue,
    pageSize,
    sortOrder,
    setSortOrder,
    page,
    sortBy,
    setSortBy,
    setPageLimit,
    setRecord,
    progress,
    setProgress,
  } = useContext(WebCategoryMapContext);
  const { setLoaderShow } = useContext(LoaderContext);
  const { tabelAnimation, setTableAnimation } = useContext(LoaderContext);

  const getCloneCategoryList = async (desabledStatusLoader) => {
    if (
      sortOrder === "ASC" ||
      sortOrder === "DSC" ||
      desabledStatusLoader === false
    ) {
    } else {
      setTableAnimation(!tabelAnimation);
    }
    try {
      let paramObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
      };
      const { data } = await WebCategoryMap_List(paramObj, pageName);

      if (data.status === 200) {
        setFormValue(data.payload.data);
        setPageLimit(data.payload.pageLimit);
        setRecord(data.pager.totalRecords);

        setTableAnimation(false);
      } else {
      }
    } catch (error) {
      setTableAnimation(false);
    }
  };

  useEffect(() => {
    getCloneCategoryList();
  }, [page, pageSize, sortBy, sortOrder, pageName]);

  /**
   * Sorting category Table
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder(SORTING_ORDER.ASC);
    }
    if (sortOrder === SORTING_ORDER.ASC) {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder(SORTING_ORDER.DSC);
    }
    if (sortOrder === SORTING_ORDER.DSC) {
      setSortBy();
      setSortOrder("");
    }
  };

  /**
   * delete category
   * @param {id} catWebMapId
   */
  const handleDelete = async (catWebMapId) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await Delete_WebCategoryMap_List(
          catWebMapId,
          pageName
        );
        if (data.status === 200) {
          getCloneCategoryList();
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  return (
    <Fragment>
      <Breadcrumbs parent="Web Category Map" />
      <TopLoadingBar progress={progress} setProgress={setProgress} />
      <WebCategoryMapItem sorting={sorting} handleDelete={handleDelete} />
    </Fragment>
  );
};

export default WebCategoryMap;
