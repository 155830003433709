import React, { useContext, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import { Button } from "react-bootstrap";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import {
  Cases,
  DELETE_CONFIRM,
  modalsName,
  productBuyCheckOutStyle,
  productBuyMyAccountStyle,
  productBuyStyle,
} from "../../../constant";
import ProductBuyContext from "../../../context/ProductBuyContext/ProductBuyContext";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { IconButton } from "@material-ui/core";
import { Settings, Trash, Send } from "react-feather";
import EditIcon from "@mui/icons-material/Edit";
import ProductBuyParentPopup from "./ProductBuyParentPopup";
import CommonPopup from "../../../Modals/commonPopup";
import SimpleReactValidator from "simple-react-validator";
import {
  deleteProductBuy,
  getProductBuyDetails,
  postProductBuy,
  putUpdateProductBuy,
} from "../apis/ProductBuyApi/ProductBuyApi";
import SettingProductBuyPopup from "./SettingProductBuyPopup";
import { setCookie } from "../../../../utils/helper";
import DeleteSection from "../../../common/DeleteSection";
import SettingMultiWebContext from "../../../../context/SettingMultiWebContext/SettingMultiWebContext";

const ProductBuy = (props) => {
  const {
    pageConfigDetails,
    productBuyDetail,
    contentMapId,
    ByteMiniWeb_Dashboard,
    navMapId: navMapIds,
    contentDetail,
  } = props;
  const { setLoaderShow } = useContext(LoaderContext);
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );
  const { setCloneAlignModal, setAlignFormData, setCloneFormData } = useContext(
    SettingMultiWebContext
  );
  const {
    isEdit,
    formDataChild,
    formData,
    collId,
    setCollId,
    setIsParent,
    id,
    setNameOfStyle,
    setIsEdit,
    setId,
    setFormData,
    sectionPageName,
    setSectionPageName,
  } = useContext(ProductBuyContext);

  const parentValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const childValidator = useRef(new SimpleReactValidator());

  const handleChangeProductBuy = () => {};

  const submitForm = async () => {
    if (id >= -1 && isEdit) {
      setLoaderShow(true);
      try {
        let allData = {};
        allData = {
          ...formData,
          // productBuyId: id,
          contentMapId: collId,
          navMapId: +navMapIds,
        };
        const { data } = await putUpdateProductBuy(allData);
        if (data.status === 200) {
          ByteMiniWeb_Dashboard();
          emptyData();
          OpenModalCommonFunc(Cases.PRODUCT_BUY, setAllPopupState, false);
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    } else {
      try {
        setLoaderShow(true);
        let allData = {};
        allData = {
          ...formData,
          contentMapId: collId,
          navMapId: +navMapIds,
        };
        const { data } = await postProductBuy(allData);
        if (data.status === 200) {
          ByteMiniWeb_Dashboard();
          emptyData();
          OpenModalCommonFunc(Cases.PRODUCT_BUY, setAllPopupState, false);
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  const handleSubmit = async (formValidationCondition) => {
    let validatorFields;
    if (formValidationCondition === "parentForm") {
      validatorFields = parentValidator.current;
    } else if (formValidationCondition === "childForm") {
      validatorFields = childValidator.current;
    }
    validatorFields.showMessages();

    if (validatorFields.allValid()) {
      try {
        if (formValidationCondition === "parentForm") {
          submitForm();
        } else if (formValidationCondition === "childForm") {
          submitForm();
        }
        validatorFields.hideMessages();
      } catch (error) {}
    } else {
      forceUpdate({});
    }
  };

  /**
   * get data product Buy
   * @param {id} productBuyId
   */
  const handleEditPackages = async (productBuyId) => {
    try {
      setLoaderShow(true);
      setId(productBuyId);
      const { data } = await getProductBuyDetails(productBuyId);
      if (data.status === 200) {
        let payload = data.payload.data;
        setSectionPageName(payload.title);
        setFormData({
          title: payload.title || "",
          isTitleShow: payload?.isTitleShow ? payload?.isTitleShow : false,
          subTitle: payload.subTitle || "",
          itemLimit: payload.itemLimit || "",
          productBuyId: payload.productBuyId || "",
          checkOutStyle: payload?.checkOutStyle || "",
          myAccountStyle: payload?.myAccountStyle || "",
          style: payload?.style || "",
          webId: localStorage.getItem("webId"),
        });
        OpenModalCommonFunc(Cases.PRODUCT_BUY, setAllPopupState);
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(true);
    }
  };

  const handleDelete = async (productBuyId) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await deleteProductBuy(productBuyId);
        if (data.status === 200) {
          ByteMiniWeb_Dashboard();
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const handleChangeSettings = (productBuyId) => {
    OpenModalCommonFunc(Cases.SETTING_PRODUCT_BUY_PARENT, setAllPopupState);
  };

  const emptyData = () => {
    setNameOfStyle("");
    setIsEdit(false);
    setId("");
    setFormData({
      title: "",
      isTitleShow: false,
      subTitle: "",
      itemLimit: "",
      productBuyId: "",
      checkOutStyle: productBuyCheckOutStyle,
      myAccountStyle: productBuyMyAccountStyle,
      style: productBuyStyle,
      webId: localStorage.getItem("webId"),
    });
  };

  const handleRedirectEcomm = () => {
    setCookie(
      "token",
      localStorage.getItem("token"),
      7,
      +process.env.REACT_APP_IS_LOCAL_URL
        ? "localhost"
        : `.${localStorage.getItem("domain")}`
    );
    setCookie(
      "roleId",
      localStorage.getItem("roleId"),
      7,
      +process.env.REACT_APP_IS_LOCAL_URL
        ? "localhost"
        : `.${localStorage.getItem("domain")}`
    );
    setCookie(
      "hwId",
      localStorage.getItem("hwId"),
      7,
      +process.env.REACT_APP_IS_LOCAL_URL
        ? "localhost"
        : `.${localStorage.getItem("domain")}`
    );
    let menuDomain = process.env.REACT_APP_ECOMM_ADMIN;
    window.open(
      +process.env.REACT_APP_IS_LOCAL_URL === 1
        ? `http://${window.location.hostname}:3001`
        : `${menuDomain}${localStorage.getItem("domain")}`,
      "_blank"
    );
  };

  const handleAlignAndCloneSection = ({ isClone = false }) => {
    if (isClone) {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          cloneModal: true,
        };
      });
      setCloneFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          pageConfigDetailsLabel: pageConfigDetails[0]?.label,
          contentDetailName: contentDetail.name,
        };
      });
    } else {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          alignModal: true,
        };
      });
      setAlignFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          label: pageConfigDetails[0]?.label,
        };
      });
    }
  };
  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody className="support-ticket-font box-border-div">
            <Row>
              <CardBuilderHeaderSection
                addBtnTitle={pageConfigDetails[0]?.label}
                name={pageConfigDetails[0]?.label}
                isAdd={!productBuyDetail && true}
                mainEditSection={true}
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
                openModal={() => {
                  OpenModalCommonFunc(Cases.PRODUCT_BUY, setAllPopupState);
                  setSectionPageName(modalsName.PRODUCT_BUY);
                  emptyData();
                  setIsParent(true);
                  setCollId(contentMapId);
                }}
                isCloneSection={true}
                isAlignSection={true}
                handleAlignAndCloneSection={handleAlignAndCloneSection}
              />
              {productBuyDetail && (
                <Col xl="12 xl-50 box-col-6" md="6">
                  <Card className="ecommerce-widget ">
                    <CardBody className="support-ticket-font">
                      <div className="col-12">
                        <CardBuilderHeaderSection
                          name={productBuyDetail?.title}
                          checked={productBuyDetail?.isActive}
                          // switchToggle={true}
                          id={productBuyDetail?.productBuyId}
                          handleChange={handleChangeProductBuy}
                        />
                      </div>

                      <Button>Update Product</Button>
                      <div className="d-flex justify-content-end ">
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleRedirectEcomm();
                          }}
                        >
                          <Send />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleEditPackages(
                              productBuyDetail.productBuyId,
                              true
                            );
                            setCollId(contentMapId);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() =>
                            handleDelete(productBuyDetail.productBuyId, true)
                          }
                        >
                          <Trash />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            setSectionPageName(productBuyDetail.title);
                            handleChangeSettings(productBuyDetail.productBuyId);
                          }}
                        >
                          <Settings />
                        </IconButton>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
              <DeleteSection
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
              />
            </Row>
          </CardBody>
        </Card>
      </Col>

      {/* item feature parent popup */}
      <CommonPopup
        open={allPopupState.productBuyPopup}
        close={() => {
          OpenModalCommonFunc(Cases.PRODUCT_BUY, setAllPopupState, false);
          setIsParent(false);
        }}
        modalName={sectionPageName ? sectionPageName : modalsName.PRODUCT_BUY}
        modalCase={Cases.PRODUCT_BUY}
        handleSubmit={handleSubmit}
        validationForm={"parentForm"}
        emptyData={emptyData}
        inputData={formData}
        edit={false}
        content={<ProductBuyParentPopup />}
      />
      <CommonPopup
        open={allPopupState.productBuySettingPopup}
        close={() =>
          OpenModalCommonFunc(
            Cases.SETTING_PRODUCT_BUY_PARENT,
            setAllPopupState,
            false
          )
        }
        modalName={
          sectionPageName
            ? sectionPageName
            : modalsName.SETTING_PRODUCT_BUY_PARENT
        }
        modalCase={Cases.SETTING_PRODUCT_BUY_PARENT}
        emptyData={emptyData}
        inputData={formDataChild}
        edit={false}
        content={
          <SettingProductBuyPopup
            emptyData={emptyData}
            productBuyId={productBuyDetail?.productBuyId}
            productBuyDetail={productBuyDetail}
            ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
          />
        }
      />
    </>
  );
};

export default ProductBuy;
