import React, { useContext, useState } from "react";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { IconButton, Switch } from "@material-ui/core";
import { Cases } from "../../../constant";
import { OpenModalCommonFunc } from "../../../utils";
import { Close } from "@material-ui/icons";
import { Col, Container, Input, Label, Row } from "reactstrap";
import Dividers from "../../../common/divider";
import { UploadCloud } from "react-feather";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import StarIcon from "@mui/icons-material/Star";
import ItemsDragAndDrops from "../../../common/ItemsDragAndDrops";
import Settings from "../../../common/setting";

const BannerWithTitleAndButtonPopup = () => {
  const { setAllPopupState, image } = useContext(ByteCardBuilderContext);
  const dragAndDropData = [
    {
      id: "img-1",
      name: "Image 1",
    },
  ];
  const [characters, updateCharacters] = useState(dragAndDropData);
  const [isFillStar, setIsFillStar] = useState(false);
  const [setting, setSetting] = useState("color");

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(characters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    updateCharacters(items);
  }

  const handleSettingChange = (value) => {
    if (value === "color") {
      setSetting(value);
    } else if (value === "background-img") {
      setSetting(value);
    }
  };

  const Setting = () => {
    return (
      <Row>
        <Col
          md="12 mb-3"
          className="d-flex justify-content-between align-items-center flex-wrap  "
        >
          <Label>Color</Label>
          <Switch
            checked={setting === "color"}
            inputProps={{
              "aria-label": "controlled",
            }}
            color="primary"
            onChange={() => handleSettingChange("color")}
          />
        </Col>
        <Col
          md="12 mb-3"
          className="d-flex justify-content-between align-items-center flex-wrap  "
        >
          <Label>Background Image</Label>
          <Switch
            inputProps={{
              "aria-label": "controlled",
            }}
            checked={setting === "background-img"}
            color="primary"
            onChange={() => handleSettingChange("background-img")}
          />
        </Col>
      </Row>
    );
  };
  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="6">
            <div>
              <h6>Banner with Title & Button</h6>
              <Row>
                <Col md="6">
                  <Input
                    className="form-control mt-3 "
                    name="search"
                    type="text"
                    placeholder="search"
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <div className="mt-4">
                <ItemsDragAndDrops
                  handleOnDragEnd={handleOnDragEnd}
                  characters={characters}
                />
              </div>
            </div>
          </Col>
          <Col sm="12" xl="6">
            <div className="mt-4">
              <h6>Add New</h6>
              <Row className="mt-4">
                <Col md="6 mb-3">
                  <Label htmlFor="validationCustom03">{"Title *"}</Label>
                  <Input
                    className="form-control"
                    name="title"
                    type="text"
                    placeholder="Title *"
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="6 mb-3">
                  <Label htmlFor="validationCustom03">{"Headline"}</Label>
                  <Input
                    className="form-control"
                    name="title"
                    type="text"
                    placeholder="Headline"
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Button Name"}</Label>
                  <Input
                    className="form-control"
                    name="title"
                    type="text"
                    placeholder="Button Name"
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Settings content={<Setting />} />
              </Row>
              {setting === "color" && (
                <Row>
                  <Col md={"3 mb-3"}>
                    <Label>Theme Color</Label>
                    <Input
                      className="form-control-color"
                      type="color"
                      name="theme"
                    />
                  </Col>
                </Row>
              )}
              {setting === "background-img" && (
                <>
                  <Dividers divide="IMAGE *" />
                  <p className="mb-4">File type required JPG, PNG (Max 1MB)</p>
                  <Row>
                    <Col md="6 mb-3">
                      <div
                        className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                        style={{ cursor: "pointer" }}
                      >
                        <span>
                          <UploadCloud />
                        </span>
                        <img
                          src={
                            image
                              ? image
                              : "https://api.bytecard.in/images/uploads/w-200/84ce7bf0be6b3e9d6cfe48f02038aa2e.jpg"
                          }
                          alt=""
                          style={{
                            width: "80%",
                            height: "98%",
                            objectFit: "contain",
                            position: "absolute",
                          }}
                          onClick={() =>
                            OpenModalCommonFunc(
                              Cases.PRODUCT_UPLOAD,
                              setAllPopupState
                            )
                          }
                        />
                        <IconButton
                          edge="start"
                          color="inherit"
                          aria-label="close"
                          style={{
                            color: "red",
                            position: "absolute",
                            top: "-22px",
                            left: "1px",
                          }}
                        >
                          <Close
                            style={{ fontSize: "25px", fontWeight: "bold" }}
                          />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          aria-label="close"
                          style={{
                            color: isFillStar ? "#ff9800" : "#c1c1c1",
                            position: "absolute",
                            top: "-22px",
                            right: "-10px",
                          }}
                          onClick={() => setIsFillStar(!isFillStar)}
                        >
                          <StarIcon
                            style={{ fontSize: "25px", fontWeight: "bold" }}
                          />
                        </IconButton>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </Col>
        </Row>
        <ProductImageUploadPopup />
      </Container>
    </>
  );
};

export default BannerWithTitleAndButtonPopup;
